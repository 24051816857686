import { MiterAPI } from "../../miter";
import Notifier from "../..//utils/notifier";
import React, { useEffect, useState } from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import { useNavigate } from "react-router-dom";
import styles from "./auth.module.css";
import { Loader } from "ui";
import { Helmet } from "react-helmet";
import { sleep, useQuery } from "dashboard/utils";
import { useSetAuthenticatedUserData, useSetUserFetched } from "dashboard/hooks/atom-hooks";

const Impersonate: React.FC = () => {
  const navigate = useNavigate();
  const user = useQuery().get("user");
  const authToken = useQuery().get("auth_token");
  const setUserFetched = useSetUserFetched();
  const setAuthenticatedUserData = useSetAuthenticatedUserData();

  const [failed, setFailed] = useState<boolean>(false);

  useEffect((): void => {
    if (!user || !authToken) {
      Notifier.error("Invalid impersonation link");
      setAuthenticatedUserData(null);
      navigate("/login", { replace: true });
      return;
    }

    authenticateUser(user, authToken);
  }, [user, authToken]);

  const authenticateUser = async (user: string, authToken: string): Promise<void> => {
    try {
      const res = await MiterAPI.mitos.impersonate_user(user, authToken);
      setUserFetched(true);

      if (res.error) throw new Error(res.error);
      if (!res.auth_token || typeof res.auth_token !== "string") throw new Error("Invalid auth token");

      setAuthenticatedUserData(res);
      await sleep(1);
      navigate("/home", { replace: true });
    } catch (e: $TSFixMe) {
      setFailed(true);
      setAuthenticatedUserData(null);
      Notifier.error(e.message);
      navigate("/login", { replace: true });
    }
  };

  return (
    <div className={styles["login-wrapper"]}>
      <Helmet>
        <title>Impersonate | Miter</title>
      </Helmet>
      <div className={styles["login-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Impersonating into Miter</h1>
            <p>Please wait as we redirect you.</p>
          </div>
          {!failed && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Impersonate;
