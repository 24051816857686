import { ICellEditorParams } from "ag-grid-community";
import { DateTime } from "luxon";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { DateTimePicker } from "ui";

type DateEditorProps = ICellEditorParams & {
  min?: string;
  max?: string;
  dateType: "iso" | "timestamp" | "time";
  stopEditing?: () => void;
};

export const AgGridDateEditor = React.forwardRef(function RawDateEditor(props: DateEditorProps, ref) {
  const [value, setValue] = useState<string | number | undefined>(props.value);
  const refInput = useRef<ReactDatePicker>(null);
  const { min, max, dateType } = props;

  useEffect(() => {
    // focus on the input
    refInput.current?.setFocus();
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return min && max && value && (value < min || value > max);
      },
    };
  });

  const buildValue = (dt: DateTime | undefined) => {
    if (dateType === "timestamp") {
      return dt?.toSeconds();
    } else if (dateType === "time") {
      return dt?.toFormat(DEFAULT_TIME_FORMAT);
    } else {
      return dt?.toISODate();
    }
  };

  const getValue = (value: string | number | undefined) => {
    if (!value) return;

    if (dateType === "timestamp" && typeof value === "number") {
      return DateTime.fromSeconds(value);
    } else if (dateType === "time" && typeof value === "string") {
      return DateTime.fromFormat(value + "", DEFAULT_TIME_FORMAT);
    } else {
      return DateTime.fromISO(value + "");
    }
  };

  const onChange = (dt) => {
    setValue(buildValue(dt));
    setTimeout(() => props.stopEditing(), 0);
  };

  return (
    <DateTimePicker
      dateRef={refInput}
      min={min ? DateTime.fromISO(min) : undefined}
      max={max ? DateTime.fromISO(max) : undefined}
      dateOnly={dateType !== "time"}
      timeOnly={dateType === "time"}
      value={getValue(value)}
      onChange={onChange}
      isClearable={true}
    />
  );
});

export const DEFAULT_TIME_FORMAT = "hh:mm a";
