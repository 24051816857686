import { AggregatedCustomField } from "dashboard/types/onboarding-types";
import React, { useCallback, useEffect } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { useForm } from "react-hook-form";

import {
  buildFieldSpecificProps,
  buildValidationRuleSet,
  cleanCustomFieldValuesBeforeSaving,
  renderCustomFieldDefaultString,
} from "miter-utils";
import { MiterAPI } from "dashboard/miter";
import { Props } from "./types";
import { Option } from "ui/form/Input";
import useWizard from "ui/modal/useWizard";
import { pick } from "lodash";

type CustomFieldsWizardScreenProps = Props & {
  customFields: AggregatedCustomField[];
};

export const CustomFieldsWizardScreen: React.FC<CustomFieldsWizardScreenProps> = ({
  task,
  name,
  onboardingChecklistItem,
  customFields,
}) => {
  const { new_hire } = onboardingChecklistItem;

  const form = useForm({
    mode: "all",
  });
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();

  const {
    errors,
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors },
    trigger,
  } = form;

  useEffect(() => {
    if (Object.keys(errors).length) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formErrors]);

  useEffect(() => {
    trigger();
  }, []);

  const saveCustomFieldValues = useCallback(
    async (data) => {
      const dirtyFields = Object.keys(form.formState.dirtyFields);

      const params = cleanCustomFieldValuesBeforeSaving(
        pick(data, dirtyFields),
        new_hire.company,
        new_hire._id,
        "team_member",
        customFields.filter((cf) => cf.custom_field_value).map((cf) => cf.custom_field_value!)
      );

      const res = await MiterAPI.custom_field_values.save(params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Custom field values saved successfully");

      if (curIndex === screens.length - 1) {
        handleComplete();
      }
    },
    [form.formState.dirtyFields]
  );

  const onNext = async () => {
    handleSubmit(async (data: { [x: string]: Option<string> | null }) => {
      saveCustomFieldValues(data);
    })();
  };

  const renderCustomFields = () => {
    return customFields.map((customField) => {
      const { custom_field_value } = customField;
      const defaultString = renderCustomFieldDefaultString(customField, custom_field_value || undefined);
      const defaultValue = custom_field_value?.value;
      const validations = buildValidationRuleSet(customField);
      const fieldSpecificProps = buildFieldSpecificProps(customField);
      const type = customField.type === "select" && customField.multiple ? "multiselect" : customField.type;
      return (
        <Formblock
          key={customField._id}
          type={type as $TSFixMe}
          label={customField.name}
          labelInfo={customField.description}
          text={customField.name}
          name={customField._id}
          errors={errors}
          register={register({
            ...validations,
            required: customField.type === "checkbox" ? undefined : "This field is required",
          })}
          control={control}
          defaultValue={defaultValue}
          defaultString={defaultString}
          editing={true}
          className={"modal"}
          placeholder={customField.placeholder}
          rules={validations}
          onChange={(_e) => {
            trigger();
          }}
          {...fieldSpecificProps}
        />
      );
    });
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        {renderCustomFields()}
      </div>
    </WizardScreen>
  );
};
