import { AggregatedBill, MiterAPI, PlaidBankAccount } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasicModal, DeleteModal, Formblock, TableV2, usdString } from "ui";
import { ColumnConfig, TableActionLink, TableTogglerConfig } from "ui/table-v2/Table";
import { Copy, Money, Plus, Trash } from "phosphor-react";
import { useActiveCompanyId, useLookupVendor } from "dashboard/hooks/atom-hooks";
import { ForageRequest } from "backend/utils/forage/forage-types";
import { FullPageBillModal } from "./FullPageBillModal";

import pluralize from "pluralize";
import { useFetchBankAccounts } from "miter-components/bank-accounts/utils";
import { isPlaidAccountVerified } from "dashboard/pages/expenses/expenseUtils";
import { Option } from "ui/form/Input";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { useQuery } from "miter-utils";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { useBillAbilities } from "dashboard/hooks/abilities-hooks/useBillAbilities";

export type BillTableRow = AggregatedBill & {
  amount_formatted: string;
};

type Props = {
  billId?: string;
  showToggler?: boolean;
};

const BillsTable: FC<Props> = ({ billId, showToggler }) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const query = useQuery();
  const paymentStatus = query.get("payment_status");
  const { payroll } = useContext(PayrollContext);
  const billAbilities = useBillAbilities();
  const lookupVendor = useLookupVendor();
  const { result: companyBankAccounts } = useFetchBankAccounts({
    companyId: activeCompanyId || "",
    refreshCount: 0,
  });

  const bankAccountOptions: Option<string>[] = useMemo(
    () =>
      (companyBankAccounts as PlaidBankAccount[])
        .filter((bankAccount) => isPlaidAccountVerified(bankAccount.external_raw_data))
        .map((account: PlaidBankAccount) => {
          return {
            label: `${account.external_raw_data.name} - ${account.account_number_last_4}`,
            value: account._id,
          };
        }),
    [companyBankAccounts]
  );

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<BillTableRow[]>([]);
  const [refreshCount, setRefreshCount] = useState<number>(0);

  // States related to table actions
  const [error, _setError] = useState<string | boolean>(false);

  // states related to modals
  const [selectedBillId, setSelectedBillId] = useState<string>();
  const [showBillFullPageModal, setShowBillFullPageModal] = useState<boolean>(false);
  const [paymentConfirmation, setPaymentConfirmation] = useState<boolean>(false);
  const [retryConfirmation, setRetryConfirmation] = useState<boolean>(false);

  const [companyBankAccountIdToPayFrom, setCompanyBankAccountIdToPayFrom] = useState<string>();
  const [archiving, setArchiving] = useState<boolean>(false);

  /*********************************************************
   *  Call useEffect hooks
   **********************************************************/
  useEffect(() => {
    setSelectedBillId(billId);
  }, [billId]);

  useEffect(() => {
    const defaultCompanyBankAccount = bankAccountOptions[0]?.value;

    if (defaultCompanyBankAccount) {
      setCompanyBankAccountIdToPayFrom(defaultCompanyBankAccount);
    }
  }, [bankAccountOptions]);

  /*********************************************************
   *  Helper functions
   **********************************************************/

  const getData = useCallback(async (query: ForageRequest) => {
    const filter = (query.filter || []).concat([
      { field: "company_id", value: activeCompanyId },
      { field: "archived", value: false },
    ]);

    if (payroll) {
      filter.push({ field: "payroll_id", value: payroll._id });
    }

    const sort = query.sort || [];
    const select = query.select || [];

    query.select?.push({ field: "line_items", show: true });
    query.select?.push({ field: "vendor_id", show: true });

    // always get payment information too
    query.select?.push({ field: "ach_debit_id", show: true });
    query.select?.push({ field: "ach_credit_id", show: true });
    query.select?.push({ field: "job_hierarchy_ids", show: true });

    const res = await MiterAPI.bills.forage({ ...query, filter, sort, select });

    return res;
  }, []);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/

  const handleAdd = () => {
    setShowBillFullPageModal(true);
  };

  const handleSubmitForPayment = async () => {
    if (!companyBankAccountIdToPayFrom) {
      Notifier.error("Please select a bank account.");
      return;
    }

    try {
      setLoading(true);
      const response = await MiterAPI.bills.update_payment_status(
        selectedRows.map((row) => row._id),
        "processing",
        companyBankAccountIdToPayFrom
      );
      if (response.error) throw new Error(response.error);

      setPaymentConfirmation(false);
      setSelectedRows([]);
      setRefreshCount(refreshCount + 1);

      const errors = response.errors;

      if (selectedRows.every((row) => row.payment_method === "ach")) {
        if (errors.length) {
          Notifier.error(
            `${pluralize("Payment", errors.length)} ${errors.length > 1 ? "were" : "was"} not created for ${
              errors.length
            } ${pluralize("bill", errors.length)}.`
          );
        } else {
          Notifier.success(
            `${selectedRows.length} ${pluralize(
              "bill",
              selectedRows.length
            )} are being processed. The vendor will receive payment in 2-3 business days.`
          );
        }
      } else if (selectedRows.every((row) => row.payment_method === "check")) {
        if (errors.length) {
          Notifier.error(
            `${pluralize("Check", errors.length)} ${errors.length > 1 ? "were" : "was"} not created for ${
              errors.length
            } ${pluralize("bill", errors.length)}.`
          );
        } else {
          Notifier.success(
            `${selectedRows.length} ${pluralize(
              "bill",
              selectedRows.length
            )} are being processed. ${pluralize(
              "Check",
              selectedRows.length
            )} will be printed and mailed to the ${pluralize("vendor", selectedRows.length)}.`
          );
        }
      }
    } catch (err: $TSFixMe) {
      Notifier.error(`There was an error processing the payment. Error: ${err.message}`);
    }
    setLoading(false);
  };

  const handleSubmitForRetry = async () => {
    try {
      setLoading(true);
      const response = await MiterAPI.bills.retry_payment(
        selectedRows.map((row) => row._id),
        companyBankAccountIdToPayFrom
      );
      if (response.error) throw new Error(response.error);

      setRetryConfirmation(false);
      setSelectedRows([]);
      setRefreshCount(refreshCount + 1);
      Notifier.success(
        `${selectedRows.length} ${pluralize(
          "bill",
          selectedRows.length
        )} are being processed. The vendor will receive payment in 2-3 business days.`
      );
    } catch (err: $TSFixMe) {
      Notifier.error(`There was an error processing the payment. Error: ${err.message}`);
    }
    setLoading(false);
  };

  const bulkDuplicateBills = async (selectedIds: string[]) => {
    try {
      const response = await MiterAPI.bills.duplicate(selectedIds);
      if (response.error) {
        throw new Error(response.error);
      }

      const { successes, errors } = response;
      if (errors.length) {
        setFailures(
          response.errors.map((f) => ({
            label: f._id,
            message: f.message,
          }))
        );
      } else {
        Notifier.success(`Duplicated ${successes.length} ${pluralize("bill", successes.length)}.`);
      }

      setSelectedRows([]);
      setRefreshCount(refreshCount + 1);
    } catch (err) {
      Notifier.error("There was an error duplicating. We're looking into it.");
    }
  };

  const handleArchive = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.bills.archive(selectedRows.map((row) => row._id));
      if (response.error) throw new Error(response.error);

      const { successes, errors } = response;
      if (errors.length) {
        setFailures(
          errors.map((f) => ({
            label: f._id,
            message: f.message,
          }))
        );
      } else {
        Notifier.success(`Deleted ${successes.length} ${pluralize("bill", successes.length)}.`);
      }

      setArchiving(false);
      setSelectedRows([]);
      setRefreshCount(refreshCount + 1);
    } catch (e) {
      Notifier.error("There was an error deleting one or more bills. We're looking into it.");
    }
    setLoading(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const dynamicActions: TableActionLink[] = [
    ...(selectedRows.every((row) => row.payment_status === "unpaid")
      ? [
          {
            label: "Delete",
            action: () => setArchiving(true),
            className: "button-3 table-button",
            icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
            shouldShow: () => billAbilities.can("delete"),
          },
          {
            label: "Duplicate",
            className: "button-1 table-button",
            action: () => bulkDuplicateBills(selectedRows.map((r) => r._id)),
            icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
            shouldShow: () => billAbilities.can("create"),
          },
          {
            label: `Pay ${pluralize("bill", selectedRows.length)}`,
            action: () => setPaymentConfirmation(true),
            className: "button-2 table-button",
            icon: <Money weight="bold" style={{ marginRight: 3 }} />,
            shouldShow: () =>
              billAbilities.can("pay") &&
              (selectedRows.every((row) => row.payment_method === "ach") ||
                selectedRows.every((row) => row.payment_method === "check")),
          },
        ]
      : []),
    ...(selectedRows.every((row) => row.payment_status === "failed")
      ? [
          {
            label: "Retry payment",
            action: () => setRetryConfirmation(true),
            className: "button-2 table-button",
            icon: <Money weight="bold" style={{ marginRight: 3 }} />,
            shouldShow: () => billAbilities.can("pay"),
          },
        ]
      : []),
  ];

  const staticActions: TableActionLink[] =
    !paymentStatus || paymentStatus === "unpaid"
      ? [
          {
            label: "New",
            icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
            action: handleAdd,
            className: "button-2",
            important: true,
            shouldShow: () => billAbilities.can("create"),
          },
        ]
      : [];

  const colors = {
    unpaid: "yellow",
    scheduled: "purple",
    processing: "light-green",
    paid: "green",
    failed: "red",
    reversed: "grey",
  };

  // Dynamically set columns based on bill status
  const columns: ColumnConfig<BillTableRow>[] = [
    {
      headerName: "ID",
      field: "external_id",
      dataType: "string",
      maxWidth: 70,
    },
    {
      headerName: "Vendor",
      field: "vendor.name",
      dataType: "string",
    },
    {
      headerName: "Description",
      field: "description",
      dataType: "string",
    },
    {
      headerName: "Bill amount",
      field: "line_items",
      valueFormatter: (params) => {
        return usdString(params.value.reduce((acc, lineItem) => acc + lineItem.amount, 0));
      },
      dataType: "string",
      maxWidth: 150,
    },
    {
      headerName: "Invoice date",
      field: "invoiced_on_date",
      dataType: "date",
      dateType: "iso",
      sort: "desc",
    },
    {
      headerName: "Payment status",
      field: "payment_status",
      dataType: "string",
      displayType: "badge",
      colors,
      maxWidth: 150,
    },
    {
      headerName: "Payment method",
      field: "payment_method",
      dataType: "string",
      colors,
      valueFormatter: (params) => {
        if (params.value === "ach") {
          return "Bank transfer (ACH)";
        } else if (params.value === "check") {
          return "Mail paper check";
        }

        return "";
      },
    },
  ];

  /*********************************************************
    Toggler configs
  **********************************************************/
  const togglerConfig: TableTogglerConfig<BillTableRow> = {
    config: [
      { path: "unpaid", label: "Unpaid" },
      { path: "processing", label: "Processing" },
      { path: "paid", label: "Paid" },
      { path: "failed", label: "Failed" },
      { path: "all", label: "All" },
    ],
    field: "payment_status",
    secondary: true,
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderBillPaymentModal = () => {
    const totalAmount = selectedRows?.reduce(
      (acc, bill) => acc + bill.line_items?.reduce((acc, lineItem) => acc + lineItem.amount, 0),
      0
    );

    const vendorsList = selectedRows.map((row) => lookupVendor(row.vendor_id)?.name);
    const uniqueVendors = Array.from(new Set(vendorsList));

    const achPaymentText = (
      <>
        A total of <b>{usdString(totalAmount)}</b> will be withdrawn from the selected bank account and paid
        to the following {pluralize("vendor", uniqueVendors.length)}:
      </>
    );

    const checkPaymentText = (
      <>
        A total of <b>{usdString(totalAmount)}</b> will be paid to the following{" "}
        {pluralize("vendor", uniqueVendors.length)} by mailed {pluralize("check", uniqueVendors.length)}. Once
        deposited, each check will withdraw funds from the selected bank account.
      </>
    );

    return (
      <div>
        <Formblock
          label="Bank Account to pay from:"
          type="select"
          name="bank_account_id"
          className="modal"
          options={bankAccountOptions}
          value={bankAccountOptions.find((option) => option.value === companyBankAccountIdToPayFrom)}
          onChange={(data) => setCompanyBankAccountIdToPayFrom(data.value)}
          editing={true}
        />
        <p className="margin-top-15">
          {
            // only show this text if all bills are paid by ACH
            selectedRows.every((row) => row.payment_method === "ach") ? achPaymentText : checkPaymentText
          }
          <br />
          <br />
          {uniqueVendors.join(", ")}
        </p>
      </div>
    );
  };

  const renderRetryPaymentModal = () => {
    // segment bills into first leg failed and second leg failed

    const firstLegFailedSelectedBills = selectedRows.filter((row) => !row.ach_debit_id);
    const secondLegFailedSelectedBills = selectedRows.filter((row) => row.ach_debit_id && !row.ach_credit_id); // first leg succeeded, so second leg failed by definition

    const renderFirstLegFailed = () => {
      if (firstLegFailedSelectedBills.length === 0) {
        return null;
      }

      const totalFirstLegAmount = firstLegFailedSelectedBills?.reduce(
        (acc, bill) => acc + bill.line_items?.reduce((acc, lineItem) => acc + lineItem.amount, 0),
        0
      );

      const vendorsList = firstLegFailedSelectedBills.map((row) => lookupVendor(row.vendor_id)?.name);
      const uniqueVendors = Array.from(new Set(vendorsList));

      return (
        <div>
          <p>
            For bills where the company bank account was not successfully debited, the following account will
            be used:
          </p>
          <Formblock
            type="select"
            name="bank_account_id"
            className="modal"
            options={bankAccountOptions}
            value={bankAccountOptions.find((option) => option.value === companyBankAccountIdToPayFrom)}
            onChange={(data) => setCompanyBankAccountIdToPayFrom(data.value)}
            editing={true}
          />
          <p className="margin-top-15">
            A total of <b>{usdString(totalFirstLegAmount)}</b> will be withdrawn from the bank account
            selected and paid to the following vendors:
            <br />
            <br />
            {uniqueVendors.join(", ")}
          </p>
        </div>
      );
    };

    const renderSecondLegFailed = () => {
      if (secondLegFailedSelectedBills.length === 0) return null;

      const totalSecondLegAmount = secondLegFailedSelectedBills?.reduce(
        (acc, bill) => acc + bill.line_items?.reduce((acc, lineItem) => acc + lineItem.amount, 0),
        0
      );

      return (
        <div>
          <p>
            For some selected bills (totalling <b>{usdString(totalSecondLegAmount)}</b>), funds were already
            withdrawn from your company&apos;s bank account but the payment to the vendor failed. These funds
            are currently held in escrow by Miter, and will be used for subsequent retried payments here.
          </p>
          <p>
            If you updated the payment method on the vendor after a failed bill payment, Miter will use the
            new data.
          </p>
        </div>
      );
    };

    return (
      <div>
        {renderSecondLegFailed()}
        {renderFirstLegFailed()}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableV2
        id="bills-table"
        resource="bills"
        getData={getData}
        ssr={true}
        refreshCount={refreshCount}
        columns={columns}
        onSelect={setSelectedRows}
        toggler={showToggler ? togglerConfig : undefined}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={true}
        defaultSelectedRows={selectedRows}
        wrapperClassName="base-ssr-table"
        containerClassName={"expenses-table-container"}
        rowLinkBuilder={(row) =>
          `/expenses/bill-pay/${row?._id}${paymentStatus ? `/?payment_status=${paymentStatus}` : ""}`
        }
      />
    );
  };

  return (
    <div className="bills-table-wrapper">
      {!error && (
        <div>
          {archiving && (
            <DeleteModal
              header={"Are you sure?"}
              body={`${selectedRows.length > 1 ? "These bills" : "This bill"} will be deleted.`}
              cancelText={"Cancel"}
              onHide={() => setArchiving(false)}
              deleteText={"Yes"}
              onDelete={handleArchive}
              loading={loading}
            />
          )}
          {paymentConfirmation && (
            <BasicModal
              headerText="Payment confirmation"
              button1Text={"Cancel"}
              button1Action={() => setPaymentConfirmation(false)}
              button2Text={`Pay ${pluralize("bill", selectedRows.length)}`}
              button2Action={handleSubmitForPayment}
              button2Loading={loading}
              showCloseX={true}
              onHide={() => setPaymentConfirmation(false)}
            >
              {renderBillPaymentModal()}
            </BasicModal>
          )}
          {retryConfirmation && (
            <BasicModal
              headerText="Payment retry confirmation"
              button1Text={"Cancel"}
              button1Action={() => setRetryConfirmation(false)}
              button2Text={`Retry ${pluralize("payment", selectedRows.length)}`}
              button2Action={handleSubmitForRetry}
              button2Loading={loading}
              showCloseX={true}
              onHide={() => setRetryConfirmation(false)}
            >
              {renderRetryPaymentModal()}
            </BasicModal>
          )}
          {(showBillFullPageModal || selectedBillId) && (
            <FullPageBillModal
              billId={billId}
              onHide={() => {
                setShowBillFullPageModal(false);
                setSelectedBillId(undefined);
                // refresh
                setRefreshCount(refreshCount + 1);
                navigate(`/expenses/bill-pay${paymentStatus ? `/?payment_status=${paymentStatus}` : ""}`);
              }}
            />
          )}
          {renderTable()}
          {renderFailuresModal()}
        </div>
      )}
    </div>
  );
};

export default BillsTable;
