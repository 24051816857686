import InfoButton from "dashboard/components/information/information";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { AggregatedPayRateGroup, PayRateGroup } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, TableV2 } from "ui";
import { MissedBreakRule } from "backend/models/pay-rate-group";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import { TimeType } from "backend/utils/time-type-utils";
import ObjectID from "bson-objectid";

type MissedBreakRuleTableEntry = MissedBreakRule & { _id: string };

type MissedBreakRulesProps = {
  rateGroup: AggregatedPayRateGroup;
  updateGroup: (group: Partial<PayRateGroup>) => Promise<void>;
};

export const timeTypeOptions = [
  { label: "REG", value: "reg" },
  { label: "OT", value: "ot" },
  { label: "DOT", value: "dot" },
];

export const MissedBreakRules: React.FC<MissedBreakRulesProps> = ({ rateGroup, updateGroup }) => {
  const activeCompany = useActiveCompany();
  const hasAdvancedBreakTime = activeCompany?.settings.timesheets.advanced_break_time;
  const [showModal, setShowModal] = useState<MissedBreakRuleTableEntry | undefined>(undefined);
  const [creating, setCreating] = useState(false);
  const [selectedRows, setSelectedRows] = useState<MissedBreakRuleTableEntry[]>([]);
  const [archiving, setArchiving] = useState(false);

  const archive = async () => {
    setArchiving(true);
    try {
      const selectedRuleIds = new Set(selectedRows.map((s) => s._id));
      const newRules = (rateGroup.missed_break_rules || []).filter((r) => !selectedRuleIds.has(r._id));
      await updateGroup({ missed_break_rules: newRules });
      setSelectedRows([]);
    } catch (e: $TSFixMe) {
      Notifier.error(`There was an error deleting the rule(s): ${e.message}`);
    }
    setArchiving(false);
  };

  const tableData = useMemo(() => {
    const rules = rateGroup.missed_break_rules || [];
    return rules.map((rule) => {
      return {
        ...rule,
      };
    });
  }, [rateGroup]);

  const columns: ColumnConfig<MissedBreakRuleTableEntry>[] = [
    {
      headerName: "Break type",
      field: "break_type_id",
      dataType: "string",
      valueGetter: (params) => {
        const bt = activeCompany?.settings.timesheets.break_types?.[params.data?.break_type_id || ""];
        return bt?.label || "Unknown";
      },
    },
    {
      headerName: "Must start after",
      field: "break_must_start_after_hour",
      dataType: "string",
      valueFormatter: (params) => {
        if (!params.data?.break_must_start_after_hour) return "N/A";
        return `Hour ${params.data?.break_must_start_after_hour}`;
      },
    },
    {
      headerName: "Must end before",
      field: "break_must_end_before_hour",
      dataType: "string",
      valueFormatter: (params) => {
        if (!params.data?.break_must_end_before_hour) return "N/A";
        return `Hour ${params.data?.break_must_end_before_hour}`;
      },
    },
    {
      headerName: "Warning?",
      field: "create_warning",
      dataType: "boolean",
    },
    {
      headerName: "Penalty hours",
      field: "penalty.hours",
      dataType: "number",
    },
    {
      headerName: "Penalty time type",
      field: "penalty.time_type",
      valueFormatter: (params) => params.data?.penalty?.time_type.toUpperCase() || "-",
    },
  ];

  const staticActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "New",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, []);
  const dynamicActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => archive(),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        loading: archiving,
      },
    ];
  }, [archiving, selectedRows, setSelectedRows]);

  return hasAdvancedBreakTime ? (
    <div className="billing-card-wrapper" style={{ height: "auto" }}>
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Missed break rules</div>
        <InfoButton text="Missed break rules describe how the system should behave when breaks are missed." />
        <div className="flex-1"></div>
      </div>
      <TableV2
        id="missed_break_rules"
        resource="rules"
        columns={columns}
        onSelect={setSelectedRows}
        data={tableData}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onClick={setShowModal}
      />
      ;
      {(showModal || creating) && (
        <MissedBreakRuleModal
          ruleToUpdate={showModal}
          rateGroup={rateGroup}
          updateGroup={updateGroup}
          hide={() => {
            setShowModal(undefined);
            setCreating(false);
          }}
        />
      )}
    </div>
  ) : null;
};

type MissedBreakRuleModalProps = {
  ruleToUpdate?: MissedBreakRuleTableEntry;
  rateGroup: AggregatedPayRateGroup;
  updateGroup: (group: Partial<PayRateGroup>) => Promise<void>;
  hide: () => void;
};

const MissedBreakRuleModal: React.FC<MissedBreakRuleModalProps> = ({
  ruleToUpdate,
  rateGroup,
  hide,
  updateGroup,
}) => {
  const activeCompany = useActiveCompany();
  const breakTypeOptions = useMemo(() => {
    const breakTypes = Object.entries(activeCompany?.settings.timesheets.break_types || {});
    return breakTypes
      .filter(([k]) => {
        return !!ruleToUpdate || !rateGroup.missed_break_rules?.find((r) => r.break_type_id === k);
      })
      .map(([k, v]) => ({ value: k, label: v.label }));
  }, [activeCompany, rateGroup]);
  const [loading, setLoading] = useState(false);
  const [addPenalty, setAddPenalty] = useState(!!ruleToUpdate?.penalty);

  type FormFields = {
    break_must_start_after_hour: string;
    break_must_end_before_hour: string;
    break_type_id: { value: string; label: string };
    create_warning: boolean;
    penalty: {
      hours: string;
      time_type: { value: TimeType; label: string };
    };
  };
  const form = useForm<FormFields>();
  const save = async (data: FormFields) => {
    const startAfterHour = Number(data.break_must_start_after_hour);
    const endBeforeHour = Number(data.break_must_end_before_hour);
    if (startAfterHour >= endBeforeHour) {
      Notifier.error("Break must start after hour must be before break must end before hour.");
      return;
    }
    if (data.penalty && !data.penalty.hours) {
      Notifier.error("Please enter a valid penalty hours.");
      return;
    }
    if (data.penalty && !data.penalty.time_type) {
      Notifier.error("Please select a valid penalty time type.");
      return;
    }
    setLoading(true);
    try {
      const cleanedRule: MissedBreakRule = {
        _id: ruleToUpdate?._id || ObjectID().toString(),
        break_type_id: data.break_type_id?.value,
        break_must_start_after_hour: startAfterHour,
        break_must_end_before_hour: endBeforeHour,
        create_warning: data.create_warning,
        penalty: addPenalty
          ? {
              hours: Number(data.penalty.hours),
              time_type: data.penalty.time_type?.value,
            }
          : null,
      };
      const newRules =
        rateGroup.missed_break_rules?.map((r) => {
          if (r.break_type_id === ruleToUpdate?.break_type_id) return cleanedRule;
          return r;
        }) || [];
      if (!ruleToUpdate) newRules?.push(cleanedRule);
      await updateGroup({ missed_break_rules: newRules });
      hide();
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`There was an error saving the rule: ${e.message}`);
    }
    setLoading(false);
  };

  return (
    <ActionModal
      onSubmit={form.handleSubmit(save)}
      onHide={hide}
      headerText={`${ruleToUpdate ? `Update` : "Create"} rule`}
      submitText={ruleToUpdate ? "Update" : "Create"}
      loading={loading}
      showSubmit={true}
    >
      <div>
        <div style={{ height: 15 }}></div>
        <Formblock
          label="Break type"
          className="modal"
          type="select"
          form={form}
          options={breakTypeOptions}
          defaultValue={ruleToUpdate?.break_type_id}
          name="break_type_id"
          editing={true}
          disabled={!!ruleToUpdate}
        />
        <Formblock
          label="Break must start after hour"
          className="modal"
          type="text"
          form={form}
          defaultValue={ruleToUpdate?.break_must_start_after_hour?.toString() || ""}
          name="break_must_start_after_hour"
          editing={true}
        />
        <Formblock
          label="Break must end before hour"
          className="modal"
          type="text"
          form={form}
          defaultValue={ruleToUpdate?.break_must_end_before_hour?.toString() || ""}
          name="break_must_end_before_hour"
          editing={true}
        />
        <Formblock
          text="Create a payroll warning when this rule is violated"
          className="modal"
          type="checkbox"
          form={form}
          defaultValue={ruleToUpdate?.create_warning}
          name="create_warning"
          editing={true}
        />
        <Formblock
          text="Add penalty hours when this rule is violated"
          className="modal"
          type="checkbox"
          form={form}
          defaultValue={ruleToUpdate?.create_warning}
          checked={addPenalty}
          onChange={() => setAddPenalty(!addPenalty)}
          name="add_penalty"
          editing={true}
        />
        {addPenalty && (
          <>
            <Formblock
              label="Penalty hours"
              text="Penalty hours"
              className="modal"
              type="text"
              form={form}
              defaultValue={ruleToUpdate?.penalty?.hours?.toString() || ""}
              name="penalty.hours"
              editing={true}
            />
            <Formblock
              label="Penalty time type"
              text="Penalty time type"
              className="modal"
              type="select"
              form={form}
              options={timeTypeOptions}
              defaultValue={ruleToUpdate?.penalty?.time_type || "ot"}
              name="penalty.time_type"
              editing={true}
            />
          </>
        )}
        <div className="vertical-spacer"></div>
      </div>
    </ActionModal>
  );
};
