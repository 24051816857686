import React, { useEffect, useState, FC, useContext, useMemo } from "react";
import { reportList } from "../../reportList";
import { useForm } from "react-hook-form";
import { Button, Formblock, TableV2 } from "ui";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI, TeamMember } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { outputTypeOptions, usePayrollJournalColumnDefs } from "./payrollJournalColumnDefs";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { downloadCsvFromBlob } from "dashboard/utils";
import { useActiveCompany, useActiveCompanyId, useLookupTeam } from "dashboard/hooks/atom-hooks";

import * as vals from "dashboard/utils/validators";
import { usePayrollAbilities } from "dashboard/hooks/abilities-hooks/usePayrollAbilities";
import { ContractorPaymentEntry, EmployeePaymentEntry } from "backend/utils/reports/payrollJournal";

export type PayrollJournalOutputType = "payrolls" | "employee_payments" | "contractor_payments";
export type PayrollJournalEntry = (EmployeePaymentEntry | ContractorPaymentEntry) & {
  team_member: TeamMember;
};

const reportObject = reportList.find((report) => report.slug === "payroll_journal");

const PayrollJournal: FC = () => {
  // Hooks
  const { register, control, clearErrors, setError, errors } = useForm();
  const navigate = useNavigate();
  const { payroll } = useContext(PayrollContext);
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const payrollAbilities = usePayrollAbilities();
  const lookupTeam = useLookupTeam();
  const payrollJournalColumnDefs = usePayrollJournalColumnDefs();

  // State
  const [data, setData] = useState<PayrollJournalEntry[]>([]);
  const [startDate, setStartDate] = useState(DateTime.now().minus({ months: 1 }));
  const [endDate, setEndDate] = useState(DateTime.now());
  const [outputType, setOutputType] = useState<PayrollJournalOutputType>("employee_payments");
  const [loading, setLoading] = useState(false);

  const getData = async (csv = false, summary = false) => {
    if (!activeCompanyId || !activeCompany) return;
    setLoading(true);
    try {
      const payload = {
        type: "payroll_journal",
        params: {
          start_date: payroll?.check_payroll.payday || startDate?.toISODate(),
          end_date: payroll?.check_payroll.payday || endDate?.toISODate(),
          company: activeCompanyId,
          summary_level: outputType,
          payrollId: payroll?._id,
          summary,
        },
        format: csv ? "csv" : "json",
        company: activeCompanyId,
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      if (csv) {
        if (!response.csv) throw new Error(`Error retrieving CSV data.`);
        const fileName = `${activeCompany.check_company.trade_name} ${
          summary ? "Employee summary" : "Employee payments"
        } ${startDate.toISODate()} to ${endDate.toISODate()}.csv`;
        downloadCsvFromBlob(response.csv, fileName);
      } else {
        const finalData: PayrollJournalEntry[] = response
          .filter((r: PayrollJournalEntry) => {
            const teamMember = lookupTeam(r.tm_id);
            return !teamMember || payrollAbilities.teamPredicate("read")(teamMember);
          })
          .map((r, i) => ({ ...r, _id: i, team_member: lookupTeam(r.tm_id) }));

        setData(finalData);
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    setLoading(false);
  };

  const onStartDateChange = (dt) => {
    setStartDate(dt);
    if (dt.toISODate() > endDate.toISODate()) {
      setError("end_date", { message: "End date cannot be before start date." });
    } else {
      clearErrors("end_date");
    }
  };

  const onEndDateChange = (dt) => {
    setEndDate(dt);
    if (startDate.toISODate() > dt.toISODate()) {
      setError("end_date", { message: "End date cannot be before start date." });
    } else {
      clearErrors("end_date");
    }
  };

  const columnDefs = payrollJournalColumnDefs[outputType];

  useEffect(() => {
    getData();
  }, [!!activeCompanyId, startDate, endDate, outputType]);

  const maxAllowableDate = DateTime.now().plus({ days: 10 });

  const HeaderTag = payroll ? "h2" : "h1";

  const staticActions: TableActionLink[] = useMemo(() => {
    return outputType === "employee_payments"
      ? [
          {
            action: () => getData(true),
            label: "",
            className: "button-1",
            important: true,
            component: (
              <Button
                text="Download detail"
                dropdownItems={[
                  { text: "Individual payments", onClick: () => getData(true, false) },
                  { text: "Employee summary", onClick: () => getData(true, true) },
                ]}
              />
            ),
          },
        ]
      : [];
  }, [outputType, payroll, startDate, endDate]); // need payroll and the dates here in order to get the latest version of `getData`

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      {!payroll && (
        <Helmet>
          <title>Payroll Journal | Miter</title>
        </Helmet>
      )}
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <HeaderTag style={{ marginTop: 0, marginBottom: payroll ? 5 : undefined }}>
          {reportObject!.label}
        </HeaderTag>
      </div>
      <div className="report-page-description">
        {reportObject?.description}
        {!payroll && "*"}
      </div>
      <div style={{ maxWidth: 400 }}>
        {!payroll && (
          <>
            <Formblock
              label="Starting payday"
              register={register}
              defaultValue={startDate}
              type="datetime"
              control={control}
              dateOnly={true}
              name="start_date"
              max={endDate}
              errors={errors}
              editing={true}
              onChange={onStartDateChange}
              rules={vals.required}
            />
            <Formblock
              label="Ending payday"
              register={register}
              defaultValue={endDate}
              type="datetime"
              control={control}
              dateOnly={true}
              min={startDate}
              name="end_date"
              max={maxAllowableDate}
              errors={errors}
              editing={true}
              onChange={onEndDateChange}
              rules={vals.required}
            />
          </>
        )}
        <Formblock
          label="Output type"
          type="select"
          name="format"
          register={register}
          errors={errors}
          editing={true}
          control={control}
          options={outputTypeOptions}
          onChange={(o) => setOutputType(o.value)}
          defaultValue={"employee_payments"}
        />
      </div>
      <TableV2
        id="payroll-journal"
        resource="payroll journals"
        data={data}
        staticActions={staticActions}
        isLoading={loading}
        columns={columnDefs as ColumnConfig<PayrollJournalEntry>[]}
        showTotals={true}
        showReportViews={true}
        gridWrapperStyle={{ height: 500 }}
        wrapperClassName="base-ssr-table"
        containerStyle={{ marginBottom: 25 }}
        exportFileName={`Payroll Journal ${startDate.toISODate()} to ${endDate.toISODate()}`}
        showExpandAll={true}
      />
      {!payroll && (
        <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
          * Includes all pending, processing, and paid payrolls.
        </div>
      )}
      <div className="vertical-spacer"></div>
    </div>
  );
};

export default PayrollJournal;
