import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { AggregatedTeamMember, MiterAPI, TeamMember } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { TableV2 } from "ui";
import { CleanedCompanyBenefit } from "./benefitsUtils";
import { Pencil } from "phosphor-react";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { useActiveEmployees, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { baseSensitiveCompare } from "miter-utils";
import FailuresModal, { FailureItem } from "dashboard/components/shared/FailuresModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useEmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";
import BulkTeamMemberSelect, {
  BulkTeamMemberSelectTeamMember,
} from "dashboard/components/team-members/BulkTeamMemberSelect";

type EnrollModalProps = {
  enrolledEmployees: TeamMember[];
  benefitId: string;
  getEnrolledEmployees: () => void;
  hide: () => void;
};

const EnrollModal: React.FC<EnrollModalProps> = ({
  enrolledEmployees,
  hide,
  getEnrolledEmployees,
  benefitId,
}) => {
  const lookupTeam = useLookupTeam();
  const activeEmployees = useActiveEmployees();
  const payrollEnrolledActiveEmps = useMemo(() => {
    return activeEmployees.filter((tm) => tm.check_id && tm.employment_type === "employee");
  }, [activeEmployees]);
  const [saving, setSaving] = useState(false);
  const [failures, setFailures] = useState<FailureItem[]>([]);
  const save = async (newlySelectedTeamMembers: BulkTeamMemberSelectTeamMember[]) => {
    setSaving(true);
    try {
      const response = await MiterAPI.benefits.company.update_enrollment(
        benefitId,
        newlySelectedTeamMembers.map((tm) => tm._id)
      );
      if (response.error) throw new Error(response.error);

      if (response.failures.length) {
        setFailures(
          response.failures.map((r) => {
            return {
              label: lookupTeam(r.tmId)?.full_name || "",
              message: r.error,
            };
          })
        );
        Notifier.warning(`Error fully updating enrollment`);
      } else {
        getEnrolledEmployees();
        hide();
        Notifier.success("Enrollment updated successfully.");
      }
    } catch (e) {
      Notifier.error("There was an error updating enrollment. We're looking into it!");
    }
    setSaving(false);
  };

  const renderUpdateFailuresModal = () => {
    if (!failures.length) return null;

    return (
      <FailuresModal
        headerText={"Enrollment failures"}
        onClose={() => {
          setFailures([]);
          hide();
          getEnrolledEmployees();
        }}
        failures={failures}
      />
    );
  };

  return (
    <div>
      {renderUpdateFailuresModal()}
      {!failures.length && (
        <BulkTeamMemberSelect
          title="Update enrollment"
          // @ts-expect-error enrolledEmployees is TeamMember[], not AggregatedTeamMember[]
          defaultTeamMembers={enrolledEmployees}
          teamMembersPool={payrollEnrolledActiveEmps}
          onHide={hide}
          submitText="Update"
          onSubmit={save}
          submitting={saving}
        />
      )}
    </div>
  );
};

type EnrolledEmployeesProps = {
  benefitId: string;
  benefitData: CleanedCompanyBenefit;
};

export const EnrolledEmployees: React.FC<EnrolledEmployeesProps> = ({ benefitId, benefitData }) => {
  const { can } = useMiterAbilities();

  const [updatingEnrollment, setUpdatingEnrollment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enrolledEmployees, setEnrolledEmployees] = useState<TeamMember[]>();
  const [canAccessAllEmployees, setCanAccessAllEmployees] = useState(false);

  const benefitAbilities = useEmployeeBenefitAbilities();

  useEffect(() => {
    getEnrolledEmployees();
  }, []);

  const getEnrolledEmployees = async () => {
    try {
      const filter = [{ field: "company_benefit", value: benefitId }];
      setLoading(true);
      const response = await MiterAPI.benefits.employee.search(filter);
      if (response.error) throw new Error(response.error);

      const employees: TeamMember[] = [];
      const eIds: string[] = [];
      const nowISO = DateTime.now().toISODate();

      for (const b of response) {
        const { start_date, end_date } = b.employee;
        if (start_date && b.employee.archived !== true && start_date <= nowISO) {
          if (!end_date || end_date >= nowISO) {
            employees.push(b.employee);
            eIds.push(b.employee._id);
          }
        }
      }

      const unaccessibleEmployees = employees.filter(
        (tm) => !benefitAbilities.teamPredicate("update")(tm as unknown as AggregatedTeamMember)
      );
      if (unaccessibleEmployees.length) {
        Notifier.warning(
          "Some employees are not accessible to you. They will not be shown in the list and you will not be able to update the enrollees."
        );
      } else {
        setCanAccessAllEmployees(true);
      }

      setEnrolledEmployees(employees.sort((a, b) => baseSensitiveCompare(a.full_name, b.full_name)));
      setLoading(false);
    } catch (e) {
      Notifier.error("There was an error retrieving enrolled employees. We're looking into it.");
      setLoading(false);
    }
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Update enrollment",
        className: "button-2 no-margin",
        action: () => setUpdatingEnrollment(true),
        important: true,
        icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("benefits:update") && canAccessAllEmployees,
        loading: loading,
      },
    ],
    [can, loading, canAccessAllEmployees]
  );

  return (
    <>
      <TableV2
        id="enrolled-employees-in-benefits-table"
        resource="employees"
        data={enrolledEmployees}
        columns={[
          { headerName: "Employee", field: "full_name", dataType: "string" } as ColumnConfig<TeamMember>,
        ]}
        staticActions={staticActions}
      />
      {updatingEnrollment && (
        <EnrollModal
          benefitId={benefitData._id}
          enrolledEmployees={enrolledEmployees || []}
          hide={() => setUpdatingEnrollment(false)}
          getEnrolledEmployees={getEnrolledEmployees}
        />
      )}
    </>
  );
};
