import DataBox from "dashboard/components/dataBox/DataBox";
import { AggregatedJob, Job } from "dashboard/miter";
import { addressString } from "dashboard/utils";
import React from "react";
import { JobLocationModal } from "./JobLocationModal";
import checkmark from "dashboard/assets/check-mark.png";
import yellowWarning from "dashboard/assets/yellow-warning.png";
import { ImageWithTooltip, TextWithTooltip } from "ui";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";

type Props = {
  data: AggregatedJob;
  updateJob: (update: Partial<Job>) => Promise<void>;
  updatingJob: boolean;
};

export const JobLocation: React.FC<Props> = ({ updateJob, data, updatingJob }) => {
  const [editing, setEditing] = React.useState(false);
  const activeCompany = useActiveCompany();
  const jobAbilities = useJobAbilities();

  const multiWorkplacePayroll = activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;

  return (
    <DataBox
      title="Job location"
      className="no-margin"
      editText={undefined}
      rows={undefined}
      onEdit={jobAbilities.can("update", data) ? () => setEditing(true) : undefined}
    >
      <div className={"data-box-section"}>
        <span className={"data-box-section-title font-14"}>Address</span>
        <span className={"data-box-section-value font-14"}>
          {data.address ? addressString(data.address) : "-"}
        </span>
      </div>
      {editing && (
        <JobLocationModal
          job={data}
          updateJob={updateJob}
          updatingJob={updatingJob}
          hide={() => setEditing(false)}
        />
      )}
      <div className={"data-box-section"}>
        <span className={"data-box-section-title font-14"}>
          {" "}
          <TextWithTooltip
            id="geolocation"
            text="Geolocation"
            spanStyle={{ fontSize: 14 }}
            tooltip="Whether geolocation coordinates have been established for this job"
          />
        </span>
        <span className={"data-box-section-value font-14"}>
          {data.geolocation ? <img src={checkmark} style={{ height: 15 }} /> : "-"}
        </span>
      </div>
      {multiWorkplacePayroll ? (
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>
            <TextWithTooltip
              id="tax_jurisdiction"
              text="Tax jurisdiction"
              spanStyle={{ fontSize: 14 }}
              tooltip="Whether a tax jurisdiction has been established for this job"
            />
          </span>
          <span className={"data-box-section-value font-14"}>
            {data.ignore_workplace ? (
              "Disabled"
            ) : (
              <ImageWithTooltip
                src={data.workplace_id ? checkmark : yellowWarning}
                height={15}
                width={15}
                tooltip={
                  data.address
                    ? "Double check the address using Google Maps and save again. Reach out to support if the issue persists."
                    : "Add an address in order to set the tax jurisdiction."
                }
              />
            )}
          </span>
        </div>
      ) : null}
    </DataBox>
  );
};
