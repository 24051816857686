import React from "react";
import PayrollContext from "./payrollContext";
import { ExpenseReimbursementsTable } from "dashboard/pages/expenses/ExpenseReimbursementsTable";

type Props = {
  viewOnly?: boolean;
  emptyHeader?: string;
};

const ReviewExpenses: React.FC<Props> = ({ viewOnly }) => {
  const payrollContext = React.useContext(PayrollContext);
  const { expensesLoading, getExpenseReimbursementEntries, expenseReimbursementEntries } = payrollContext;

  return (
    <div>
      <ExpenseReimbursementsTable
        showToggler={false}
        viewOnly={viewOnly}
        customDataFetch={getExpenseReimbursementEntries}
        customData={expenseReimbursementEntries}
        customLoading={expensesLoading}
        shouldRedirectURLWhenOpening={false}
      />
    </div>
  );
};

export default ReviewExpenses;
