import { MiterAPI, Note } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useEffect, useState } from "react";
import NoteItem, { NewNote } from "./NoteItem";
import styles from "./Notes.module.css";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { NoteParentType } from "backend/models/note";

type Props = {
  parentId: string;
  parentType: NoteParentType;
  readonly?: boolean;
};

const Notes: React.FC<Props> = ({ parentId, parentType, readonly }) => {
  const activeCompanyId = useActiveCompanyId();
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    getNotes();
  }, [parentId, parentType]);

  const getNotes = async () => {
    if (!activeCompanyId) return;
    try {
      const filter = [
        { field: "parent_id", value: parentId },
        { field: "parent_type", value: parentType },
        { field: "company_id", value: activeCompanyId! },
      ];

      const notes = await MiterAPI.notes.search(filter);

      setNotes(notes);
    } catch (e: $TSFixMe) {
      console.error("Unable to get notes", e);
      Notifier.error("We are unable to get notes at this time. Please try again later.");
    }
  };

  return (
    <div className={styles["notes-container"]}>
      {!readonly && <NewNote parentId={parentId} parentType={parentType} onCreate={getNotes} />}

      <div className={styles["notes-list"]}>
        {notes.map((note) => (
          <NoteItem
            key={note._id}
            note={note}
            parentId={parentId}
            parentType={parentType}
            onSave={getNotes}
            readonly={readonly}
          />
        ))}
      </div>
    </div>
  );
};

export default Notes;
