import React from "react";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { QBDConnectionMetadata } from "backend/services/qbd/qbd-types";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { DeepPartial } from "utility-types";
import { SettingsCard } from "ui/settings/SettingsCard";
import { Option } from "ui/form/Input";

const customerAddressFieldOptions: Option<QBDConnectionMetadata["customer_address_field"]>[] = [
  { label: "Shipping", value: "shipping" },
  { label: "Billing", value: "billing" },
];

export const QbdIntegrationConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();
  const defaultPullCustomersAsJobs = integration.connection?.metadata?.qbd?.pull_customers_as_jobs ?? false;
  const defaultUseFullName = integration.connection?.metadata?.qbd?.use_full_name_as_job ?? false;
  const defaultAddressConfiguration = integration.connection?.metadata?.qbd?.customer_address_field;
  const onlyPullActiveJobs = integration.connection?.metadata?.qbd?.pull_only_active_jobs;
  const addQBMetadataToLineItems = integration.connection?.metadata?.qbd?.add_qb_metadata_to_line_items;

  const handleChange = async (update: DeepPartial<Omit<QBDConnectionMetadata, "conductor_end_user_id">>) => {
    const rawUpdate = {
      metadata: {
        qbd: update,
      },
    };
    await updateIntegrationConnection(buildAtomicMongoUpdateFromNested(rawUpdate));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Jobs Configuration">
        <Formblock
          form={form}
          underlineTooltip={true}
          name="pull_customers_as_jobs"
          type="checkbox"
          label="Import top-level QB customers as Miter jobs"
          defaultValue={defaultPullCustomersAsJobs}
          onChange={(e) => handleChange({ pull_customers_as_jobs: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="By default, Miter only imports QuickBooks sub-jobs and projects as Miter Jobs. Set this setting to true to import top-level customers as Miter Jobs."
        />
        <div className="vertical-spacer-small"></div>
        <Formblock
          form={form}
          underlineTooltip={true}
          name="use_full_name_as_job"
          type="checkbox"
          label="Use QuickBooks Full Name as Miter Job Name"
          defaultValue={defaultUseFullName}
          onChange={(e) => handleChange({ use_full_name_as_job: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="If selected, the job name will be of the format 'Customer:Job Name'."
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          options={customerAddressFieldOptions}
          name="customer_address_field"
          type="select"
          label="Customer address field"
          labelInfo="Which Customer field Miter should look at to determine the Miter job address"
          defaultValue={defaultAddressConfiguration}
          onChange={(o) => handleChange({ customer_address_field: o.value })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          style={{
            maxWidth: 350,
          }}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          name="pull_only_active_jobs"
          type="checkbox"
          label="Only pull Active Jobs"
          defaultValue={onlyPullActiveJobs}
          onChange={(e) => handleChange({ pull_only_active_jobs: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="If selected, Miter will only sync Jobs that are Active in QuickBooks."
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          name="add_qb_metadata_all_line_items"
          type="checkbox"
          label="Add Quickbooks metadata to all line items"
          defaultValue={addQBMetadataToLineItems}
          onChange={(e) => handleChange({ add_qb_metadata_to_line_items: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="If selected, Class, Customer, and/or Item will be tagged to all applicable GL line items, not just expense lines."
        />
        <div className="vertical-spacer-small"></div>
      </SettingsCard>
    </div>
  );
};
