import { Plus, X } from "phosphor-react";
import { FC, useEffect, useRef, useState } from "react";
import Button from "ui/button/Button";
import Label from "ui/form/Label";

import styles from "./OptionsList.module.css";
import { cloneDeep } from "lodash";

/*
    This component renders a UI so that user can specify a list of options for a
    select/multiselect form field.
    1. Need to create a button to add a new option
    2. Need a row component with english option, spanish option, and an X button to delete the row
    3. Need validation to ensure user cannot delete an option that is in use anywhere
  */

type OptionsListProps = {
  optionsList: { value: string; translations: { es: string } }[];
  updateOptionsList: (optionsList: { value: string; translations: { es: string } }[]) => void;
};

const OptionsList: FC<OptionsListProps> = ({ optionsList, updateOptionsList }) => {
  const isMounted = useRef(false);

  const [optionRows, setOptionRows] = useState(optionsList);

  useEffect(() => {
    // If creating a new custom select field, add an option row by default
    if (optionsList.length === 0) addOptionRow();
  }, []);

  useEffect(() => {
    // Update options and optionsList in the parent component whenever the optionRows state changes except for the first render
    if (isMounted.current) {
      updateOptionsList(optionRows);
    } else {
      isMounted.current = true;
    }
  }, [optionRows]);

  const addOptionRow = () => {
    setOptionRows((prevRows) => [...prevRows, { value: "", translations: { es: "" } }]);
  };

  const updateOptionRow = (index: number, language: "english" | "spanish", value: string) => {
    const newRows = cloneDeep(optionRows);
    const newRow = newRows[index];
    if (newRow) {
      if (language === "english") {
        newRow.value = value;
      } else if (language === "spanish") {
        newRow.translations.es = value;
      }
      setOptionRows(newRows);
    }
  };

  const deleteOptionRow = (index: number) => {
    const newRows = optionRows.filter((_row, i) => i !== index);
    setOptionRows(newRows);
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["title-container"]}>
        <div className={styles["title-text"]}>
          <Label
            label="Options*"
            labelInfo="These are the options that will be available for this select field."
          />
        </div>
        <Button className="button-2" onClick={addOptionRow}>
          <Plus className={styles["plus-icon"]} />
          Add
        </Button>
      </div>
      <div className={styles["header"]}>
        <div className={`${styles["column-label"]} label-line`}>Label*</div>
        <div className={`${styles["column-label"]} label-line`}>Spanish label</div>
        <div className={styles["column-label-actions"]}></div>
      </div>
      {optionRows.map((row, index) => (
        <div className={styles["row"]} key={index}>
          <input
            className="form2-text"
            value={row.value}
            onChange={(e) => updateOptionRow(index, "english", e.target.value)}
            placeholder="English option"
          />
          <input
            className="form2-text"
            value={row.translations.es}
            onChange={(e) => updateOptionRow(index, "spanish", e.target.value)}
            placeholder="Spanish option"
          />
          <Button className="button-text" style={{ margin: 0 }} onClick={() => deleteOptionRow(index)}>
            <X />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default OptionsList;
