import React, { useState, useEffect, useContext } from "react";
import TimesheetContext from "./timesheetContext";

import Notifier from "dashboard/utils/notifier";
import { convertHeicToPng } from "dashboard/utils/utils";
import { FileWithUrl, MiterAPI } from "dashboard/miter";
import { Loader } from "ui";
import PhotoExplorer, { Photo } from "dashboard/components/photo-explorer/PhotoExplorer";
import { useNavigate } from "react-router-dom";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

// This component is only shown if the company has enabled clock in photos.
const TimesheetClockInPhoto: React.FC = () => {
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();

  const companyName = activeCompany?.check_company.trade_name;

  const { timesheet } = useContext(TimesheetContext);

  const [fetching, setFetching] = useState(false);
  const [images, setImages] = useState<Photo[]>([]);

  const fetchImages = async () => {
    setFetching(true);

    const promises: Promise<{ urls: FileWithUrl[]; error?: string }>[] = [];

    try {
      promises.push(
        timesheet?.clock_in_photo
          ? MiterAPI.files.get_urls({
              filter: [{ field: "_id", value: timesheet.clock_in_photo }],
            })
          : Promise.resolve({ urls: [], error: "" })
      );

      promises.push(
        timesheet?.clock_out_photo
          ? MiterAPI.files.get_urls({
              filter: [{ field: "_id", value: timesheet.clock_out_photo }],
            })
          : Promise.resolve({ urls: [], error: "" })
      );

      const [clockInResponse, clockOutResponse] = await Promise.all(promises);

      if (clockInResponse?.error) {
        throw new Error(clockInResponse.error);
      }
      if (clockOutResponse?.error) {
        throw new Error(clockOutResponse.error);
      }

      const tmSelfies: $TSFixMe[] = [];
      if (clockInResponse?.urls?.length || 0 > 0) {
        tmSelfies.push({ ...clockInResponse?.urls[0], photo_id_type: "clock_in" });
      }
      if (clockOutResponse?.urls?.length || 0 > 0) {
        tmSelfies.push({ ...clockOutResponse?.urls[0], photo_id_type: "clock_out" });
      }

      const imageUrls: Photo[] = [];

      for (const image of tmSelfies as $TSFixMe) {
        const fileId = image.value.key.split("-")[0];

        let url = image.value.url;
        if (image.value.url.toLowerCase().includes(".heic")) {
          url = await convertHeicToPng(image.value.url);
        }

        imageUrls.push({
          url: url,
          id: fileId,
          photo_id_type: image.photo_id_type,
        });
      }
      setImages(imageUrls);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving the clock in photo.");
    }
    setFetching(false);
  };

  const goToTimesheetSettings = () => {
    navigate("/timesheet-settings");
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div>
      {fetching ? (
        <Loader />
      ) : (
        <>
          <div className="payment-active-view-header">
            <div className="">Clock in/out photos</div>
          </div>
          {timesheet?.creation_method !== "app_clock_in" &&
          timesheet?.creation_method !== "kiosk_clock_in" ? (
            <div className="yellow-text-container">
              This timesheet was created manually or via the dashboard. Thus, it does not have a clock in or
              clock out photo.
            </div>
          ) : (
            <>
              <div className="yellow-text-container">
                <span>{`${companyName}'s `}</span>
                <span className="blue-link" onClick={goToTimesheetSettings}>
                  Timesheet Settings
                </span>
                <span>{` require team members to snap a photo when clocking in and/or clocking out via the mobile app.`}</span>
              </div>{" "}
              {!fetching && (
                <PhotoExplorer hideActionBar={true} photos={images} version="modal" hideCaption={false} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TimesheetClockInPhoto;
