import { ESignatureItem, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { downloadFiles, viewFile } from "miter-utils";
import { DateTime } from "luxon";
import { Eye, PaperPlaneTilt, Pencil } from "phosphor-react";
import React, { FC, useState, useMemo, ReactElement } from "react";
import { Button, Loader } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import { styles } from "ui/form/styles";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Assign } from "utility-types";
import { useLookupActiveTeam } from "dashboard/hooks/atom-hooks";

type Props = {
  esignatureItems: ESignatureItem[];
  document: FilePickerFile;
  setESignatureItems: (updatedESignatureItems: ESignatureItem[]) => void;
  openSignersModal: () => void;
};

type ESignatureItemRow = Assign<
  ESignatureItem,
  { timestamp: string; signer_name: string; actions: ReactElement }
>;

const ESignatureItemsTable: FC<Props> = ({
  esignatureItems,
  document,
  setESignatureItems,
  openSignersModal,
}) => {
  const [resending, setResending] = useState<string | undefined>();
  const [selectedRows, setSelectedRows] = useState<ESignatureItemRow[]>([]);
  const [downloadingSignedDocuments, setDownloadingSignedDocuments] = useState(false);
  const [bulkResending, setBulkResending] = useState(false);

  const lookupActiveTeam = useLookupActiveTeam();

  // Updates the signature request list based on whether a signature request was updated or deleted
  const buildUpdatedSignatureRequests = (signatureRequest: ESignatureItem, action: "update" | "delete") => {
    if (action === "delete") {
      return esignatureItems.filter((esr) => esr._id !== signatureRequest._id);
    } else if (action === "update") {
      return esignatureItems.map((esr) => (esr._id === signatureRequest._id ? signatureRequest : esr));
    } else {
      return esignatureItems;
    }
  };

  // Helper function to merge the updated esignature requests onto the current document without requiring a refetch
  const updateSignatureRequestInDocument = async (
    signatureRequest: ESignatureItem,
    mode: "update" | "delete"
  ) => {
    if (!document?.data) {
      return console.log("We are trying to update signature request in a document that doesn't exist.");
    }

    const updatedESignatureItems = buildUpdatedSignatureRequests(signatureRequest, mode);
    setESignatureItems(updatedESignatureItems);
  };

  const resendSignatureRequest = async (esignatureItem: ESignatureItem, ignoreLoader?: boolean) => {
    if (!ignoreLoader) {
      setResending(esignatureItem._id);
    }

    try {
      if (esignatureItem.status === "signed") {
        throw new Error("Cannot resend a signed request.");
      }

      const response = await MiterAPI.esignature_items.requests.resend(esignatureItem._id);
      if (response.error) throw Error(response.error);

      updateSignatureRequestInDocument(response, "update");
      Notifier.success("Successfully resent signature request");
    } catch (e: $TSFixMe) {
      console.error("Error resending signature request:", e);
      Notifier.error(e.message);
    }
    setResending(undefined);
  };

  const resendSelectedSignatureRequests = async () => {
    setBulkResending(true);
    await Promise.all(
      selectedRows.map((sr) =>
        resendSignatureRequest(esignatureItems.find((esr) => esr._id === sr._id)!, true)
      )
    );
    setBulkResending(false);
  };

  const viewSignedDocument = async (esignatureItem: ESignatureItem) => {
    if (!esignatureItem.signed_document_id) {
      console.error("Unable to find signed document id for esignature item:", esignatureItem);
      return Notifier.error("There was an issue viewing the signed document. Please contact support.");
    }

    viewFile(esignatureItem.signed_document_id);
  };

  const buildResendButton = (esignatureItem: ESignatureItem) => {
    return (
      <div style={{ display: "flex" }}>
        <Button
          className="button-1 no-margin"
          onClick={() => resendSignatureRequest(esignatureItem)}
          style={{ marginRight: 5, height: 30 }}
        >
          {resending === esignatureItem._id ? (
            <Loader className="small-text" />
          ) : (
            <>
              <PaperPlaneTilt style={{ marginRight: 5 }} />
              Resend
            </>
          )}
        </Button>
      </div>
    );
  };

  const buildViewButton = (esignatureItem: ESignatureItem) => {
    return (
      <div style={{ display: "flex" }}>
        <Button
          className="button-1 no-margin"
          onClick={() => viewSignedDocument(esignatureItem)}
          style={{ marginRight: 5, height: 30 }}
        >
          <Eye style={{ marginRight: 5 }} />
          View
        </Button>
      </div>
    );
  };

  const buildSignatureRequestActions = (esignatureItem: ESignatureItem) => {
    if (esignatureItem.status === "sent" || esignatureItem.status === "failed") {
      return buildResendButton(esignatureItem);
    } else {
      return buildViewButton(esignatureItem);
    }
  };

  const data: ESignatureItemRow[] = useMemo(
    () =>
      esignatureItems
        .filter((esignatureItem) => {
          // Filter out any esignature items that don't have a valid active team member
          const teamMember = lookupActiveTeam(esignatureItem.signer.team_member_id);
          return !!teamMember;
        })
        .map((esignatureItem) => {
          const lastEventTimestamp = esignatureItem.events[esignatureItem.events.length - 1]?.timestamp;
          const timestamp = DateTime.fromSeconds(lastEventTimestamp || esignatureItem.created_at).toFormat(
            "fff"
          );

          return {
            ...esignatureItem,
            timestamp,
            signer_name: esignatureItem.signer.name,
            actions: buildSignatureRequestActions(esignatureItem),
          };
        }),
    [esignatureItems, resending, lookupActiveTeam]
  );

  const downloadSignedDocuments = () => {
    const fileIds = esignatureItems
      .filter((e) => e.status === "signed" && !!e.signed_document_id)
      .map((e) => e.signed_document_id!);

    downloadFiles(fileIds, setDownloadingSignedDocuments);
  };

  const selectedItemsAreRequests = useMemo(
    () =>
      selectedRows.every((row) => {
        const esignatureItem = esignatureItems.find((e) => e._id === row._id);
        return esignatureItem?.status === "sent" || esignatureItem?.status === "failed";
      }),
    [selectedRows]
  );

  const hasSignedDocuments = esignatureItems.some((e) => e.status === "signed" && !!e.signed_document_id);

  const staticActions: TableActionLink[] = [
    {
      label: "Manage signers",
      className: "button-2 no-margin table-button",
      action: () => openSignersModal(),
      important: true,
      icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
    },
    ...(hasSignedDocuments
      ? [
          {
            label: "Download signed docs",
            className: "button-1 table-button",
            action: downloadSignedDocuments,
            loading: downloadingSignedDocuments,
          },
        ]
      : []),
  ];

  const dynamicActions: TableActionLink[] = [
    ...(selectedItemsAreRequests
      ? [
          {
            label: "Resend",
            className: "button-1 table-button",
            action: resendSelectedSignatureRequests,
            loading: bulkResending,
            icon: <PaperPlaneTilt weight="bold" style={{ marginRight: 3 }} />,
          },
        ]
      : []),
  ];

  const columns: ColumnConfig<ESignatureItemRow>[] = [
    {
      field: "signer_name",
      headerName: "Signer name",
      dataType: "string",
    },
    {
      field: "status",
      headerName: "Status",
      dataType: "string",
      displayType: "badge",
      colors: {
        sent: "yellow",
        failed: "red",
        signed: "green",
      },
    },
    {
      field: "timestamp",
      headerName: "Sent/Signed at",
      dataType: "string",
    },
    {
      field: "actions",
      headerName: "Actions",
      dataType: "component",
    },
  ];

  return (
    <div className={styles["signature-requests"]}>
      <TableV2
        id={"esignature-items-table"}
        resource="signers"
        data={data}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
      />
    </div>
  );
};

export default ESignatureItemsTable;
