import { Message, Conversation as TwilioConversation } from "@twilio/conversations";
import { AggregatedTeamMember, Conversation, MiterAPI } from "dashboard/miter";
import { getUserLabel, Notifier, truncateFilename } from "dashboard/utils";
import React, { FC, useEffect, useState } from "react";
import { FaFileAlt, FaFolderOpen, FaTimesCircle } from "react-icons/fa";
import { ActionModal } from "ui";
import Banner from "../../shared/Banner";
import FileInput from "../../shared/FileInput";
import TeamMemberSelect from "../../team-members/TeamMemberSelect";
import styles from "../Chat.module.css";
import {
  useActiveCompanyId,
  useSetTeamChatRestart,
  useTeamChatConversations,
  useTeamTwilioClient,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { useChatAbilities } from "dashboard/hooks/abilities-hooks/useChatAbilities";
import { handleFileUploadHelper } from "dashboard/utils/chat";

type Props = {
  onCancel: () => void;
  onCreate: () => void;
  defaultTeamMembers?: AggregatedTeamMember[];
};

const TeamCreateBroadcastModal: FC<Props> = ({ onCancel, onCreate, defaultTeamMembers }) => {
  /**********************************************************************************
   *  Hooks and states
   **********************************************************************************/

  // Chat atom hooks
  const teamConversations = useTeamChatConversations();
  const teamTwilioClient = useTeamTwilioClient();
  const setTeamChatRestart = useSetTeamChatRestart();

  const user = useUser();
  const chatAbilities = useChatAbilities();
  const activeCompanyId = useActiveCompanyId();
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<AggregatedTeamMember[]>(
    defaultTeamMembers || []
  );

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [files, setFiles] = useState<File[]>([]);

  const [page, setPage] = useState<"team-members" | "message">("team-members");

  useEffect(() => {
    setSelectedTeamMembers(defaultTeamMembers || []);
  }, [JSON.stringify(defaultTeamMembers)]);

  /**********************************************************************************
   *  Backend functions
   **********************************************************************************/
  const createConversation = async (teamMember: AggregatedTeamMember): Promise<Conversation | undefined> => {
    try {
      if (!teamMember) throw new Error("Please select a team member");
      if (!activeCompanyId) throw new Error("Please select a company");

      const res = await MiterAPI.chat.create(activeCompanyId, teamMember?._id);
      if (res.error) throw new Error(res.error);

      return res;
    } catch (e: $TSFixMe) {
      console.log(e);
    }
  };

  /**********************************************************************************
   *  Input / data handler functions
   **********************************************************************************/

  const handleRemoveFile = (file: File) => {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
  };

  const handleSendFileMessage = async (twilioConversation: TwilioConversation) => {
    for (const file of files) {
      const formData = new FormData();
      formData.append(file.name, file as Blob);

      const sentMessageIndex = await twilioConversation?.sendMessage(formData, {
        sender: { label: getUserLabel(user!) },
      });

      if (sentMessageIndex === null || sentMessageIndex === undefined) {
        return Notifier.error("We are unable to send a message at this time. Please try again later.");
      }

      await twilioConversation?.updateLastReadMessageIndex(sentMessageIndex);
    }

    setFiles([]);
  };

  const handleSendMessage = async () => {
    if (!selectedTeamMembers.length) {
      Notifier.error("Please select at least one team member.");
      return;
    }

    if (!message || !message.length) {
      Notifier.error("Please enter a message.");
      return;
    }

    setLoading(true);

    const failedTMs: { tmName: string; reason: string }[] = [];
    let createdAConversation = false;

    for (const tm of selectedTeamMembers) {
      // Get the conversation based on the team member's conversation ID
      let conversation = teamConversations.find((c) => c._id === tm.conversation);

      // If there is no conversation already
      if (!conversation) {
        // Create a new conversation via the backend
        const miterConversation = await createConversation(tm);

        // If there is no conversation created, add it to the failures and skip
        if (!miterConversation) {
          failedTMs.push({ tmName: tm.full_name, reason: "Unable to create a conversation" });
          continue;
        }

        // Set to true if we have to create a new conversation
        createdAConversation = true;

        // Get the twilio conversation from Twilio
        const twilioConversation = await teamTwilioClient?.getConversationBySid(
          miterConversation.conversation_sid
        );

        // Merge them into the one chat conversation and set it to conversation
        conversation = {
          ...miterConversation,
          twilio_conversation: twilioConversation,
          unread: false,
          last_message: {
            body: "New conversation",
            dateCreated: twilioConversation?.dateCreated as Date,
          } as Message,
        };
      }

      if (!conversation?.twilio_conversation) {
        failedTMs.push({ tmName: tm.full_name, reason: "No twilio conversation" });
        continue;
      }

      // If there are files attached, send the files
      if (files.length > 0 && conversation?.twilio_conversation) {
        await handleSendFileMessage(conversation.twilio_conversation);
      }

      // Send the message
      const hasPreviousMessage = !!conversation?.twilio_conversation.lastMessage;

      const sentMessageIndex = await conversation?.twilio_conversation.sendMessage(message, {
        sender: { label: getUserLabel(user!) },
      });

      const blankMessageIndex = sentMessageIndex === null || sentMessageIndex === undefined;

      if (blankMessageIndex && hasPreviousMessage) {
        failedTMs.push({ tmName: tm.full_name, reason: "Not able to set to read" });
      }

      // Update the conversation to be set to read
      await conversation?.twilio_conversation?.updateLastReadMessageIndex(sentMessageIndex || null);
    }

    // If there are any failed team members, notify us via Sentry
    if (failedTMs.length > 0) {
      console.log("Unable to send chat messages to: ", JSON.stringify(failedTMs, null, 2));
    }

    // Clear the inputs and run onCreate
    setMessage("");
    setLoading(false);
    onCreate();
    MiterAPI.chat.send_broadcast_notification(activeCompanyId!);

    /// Restart chat if we have to create a new conversation in the Miter backend
    if (createdAConversation) {
      setTeamChatRestart((prev) => prev + 1);
    }
  };

  const handleNext = () => {
    setPage("message");
  };

  const handleBack = () => {
    setPage("team-members");
  };

  // Upload files into the application (not to Twilio yet)
  const handleFileUpload = (files: FileList | null) => {
    if (!files) return;
    const error = handleFileUploadHelper(files);
    switch (error) {
      case "file-size":
        return Notifier.error("No file can be larger than 1MB due to SMS rules.");
      case "file-type":
        return Notifier.error("You can only send PDFs, images, videos, and audio messages over chat.");
      case "total-size":
        return Notifier.error("The total size of the files you send must be less than 5MB.");
      case "file-count":
        return Notifier.error("You can only upload up to 10 files at a time");
      default:
        setFiles(Array.from(files));
    }
  };

  const renderNewMessageInput = () => {
    return (
      <textarea
        className={"form2-paragraph " + styles["conversation-new-message-input"]}
        style={{ paddingTop: 8.5 }}
        placeholder="Enter message"
        value={message}
        maxLength={1000}
        onChange={(e) => {
          e.target.style.height = "inherit";
          e.target.style.height = `${e.target.scrollHeight}px`;
          setMessage(e.target.value);
        }}
      ></textarea>
    );
  };

  const renderSelectedFiles = () => {
    if (files.length === 0) return;

    const fileElements = files.map((file) => {
      return (
        <div className={styles["selected-file"]} key={file.name + " " + file.lastModified + Math.random()}>
          <p className={styles["selected-file-name"]}>
            <FaFileAlt style={{ marginBottom: -2, marginRight: 7, color: "#4d54b6" }} />
            {truncateFilename(file.name, 30)}
          </p>
          <button className={styles["selected-file-remove"]} onClick={() => handleRemoveFile(file)}>
            <FaTimesCircle />
          </button>
        </div>
      );
    });

    return (
      <div className={styles["conversation-files-for-upload"] + " " + styles["broadcast-files"]}>
        {fileElements}
      </div>
    );
  };

  const renderNewMessage = () => {
    return (
      <form onSubmit={handleSendMessage}>
        {renderSelectedFiles()}

        <div className={styles["conversation-new-message"] + " " + styles["broadcast-new-message"]}>
          <FileInput
            label={<FaFolderOpen />}
            onChange={handleFileUpload}
            multiple={true}
            className={"button-1 no-margin " + styles["conversation-upload-file-button"]}
          />
          <div className={styles["new-message-container"]}>{renderNewMessageInput()}</div>
        </div>
      </form>
    );
  };

  // Helper variables for rendering the UI
  const submitText = page === "team-members" ? "Next" : "Send broadcast";
  const onSubmit = page === "team-members" ? handleNext : handleSendMessage;
  const onBack = page === "team-members" ? onCancel : handleBack;
  const cancelText = page === "team-members" ? "Cancel" : "Back";

  return (
    <>
      <ActionModal
        headerText={"Send a broadcast"}
        submitText={submitText}
        onSubmit={onSubmit}
        showSubmit={true}
        showCancel={true}
        cancelText={cancelText}
        onCancel={onBack}
        onHide={onCancel}
        loading={loading}
        wrapperStyle={{ width: "50%" }}
      >
        <Banner
          type="modal"
          content="Broadcasts allow you to send the same message to multiple members of your team."
          style={{ marginTop: 15, marginBottom: 15 }}
        />
        {page === "team-members" && (
          <TeamMemberSelect
            title="Recipients"
            handleSelect={setSelectedTeamMembers}
            preSelectedTeamMembers={defaultTeamMembers}
            readOnly={defaultTeamMembers ? true : false}
            selectableTeamMemberPredicate={chatAbilities.teamPredicate("send")}
          />
        )}
        {page === "message" && renderNewMessage()}
      </ActionModal>
    </>
  );
};

export default TeamCreateBroadcastModal;
