import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { JobApplication } from "dashboard/types/ats";
import { useMiterAbilities } from "../abilities-hooks/useMiterAbilities";
import { useAbilitiesBackendFilter } from "../abilities-hooks/useAbilitiesBackendFilter";
import { FilterBuilder } from "../abilities-hooks/useAbilitiesTeamPredicate";

/*
 * JOB APPLICATIONS
 */
type JobApplicationParams = JobApplication | JobApplication[] | undefined;
type JobApplicationAction = "create" | "hire" | "read" | "update" | "delete";

type JobApplicationAbilities = {
  can: (action: JobApplicationAction, items: JobApplicationParams) => boolean;
  cannot: (action: JobApplicationAction, items: JobApplicationParams) => boolean;
  filter: FilterBuilder;
};

export const useJobApplicationAbilities = (): JobApplicationAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: JobApplicationAction, items?: JobApplicationParams) => {
      if (!items) return true;

      const jobApplications = Array.isArray(items) ? items : [items];

      return jobApplications.every((ja) => {
        const opts = { moduleParams: { recruiting: { jobPosting: ja.job_posting_id } } };
        switch (action) {
          case "create":
            return can_("recruiting:job_applications:hire", opts);
          case "read":
            return can_("recruiting:job_applications:read", opts);
          case "update":
            return can_("recruiting:job_applications:update", opts);
          case "delete":
            return can_("recruiting:job_applications:delete", opts);
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: JobApplicationAction, items: JobApplicationParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "recruiting:job_applications",
    modules: { jobPostingField: { fieldName: "job_posting_id", fieldType: "string" } },
  });

  return useMemo(() => ({ can, cannot, filter }), [can, cannot, filter]);
};
