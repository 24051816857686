import React, { useMemo } from "react";
import { useFetchCustomFieldDefinitions } from "dashboard/hooks/integrations/procore/useFetchCutomFieldDefinitions";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { Option } from "ui/form/Input";
import { Formblock, Loader } from "ui";
import { DeepPartial } from "utility-types";
import { ProcoreConfigObject, ProcoreConnectionMetadata } from "backend/services/procore/procore-types";
import { DEFAULT_PROCORE_LABEL_STYLE, PROCORE_CONFIG_INPUT_LENGTH } from "./ProcoreConfig";
import { UseFormMethods, FieldValues } from "react-hook-form";

interface CustomFieldDefinitionsFormBlockProps extends IntegrationConfigProps {
  updateProcoreMetadata: (
    update: DeepPartial<ProcoreConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => Promise<void>;
  procoreConfigOptions?: ProcoreConfigObject;
  form: UseFormMethods<FieldValues>;
}

export const CustomFieldDefinitionsFormBlock: React.FC<CustomFieldDefinitionsFormBlockProps> = ({
  integration,
  updateProcoreMetadata,
  procoreConfigOptions,
  form,
}) => {
  const {
    result: customFieldDefinitions,
    loading,
    error,
  } = useFetchCustomFieldDefinitions(integration.connection?._id);

  const customFieldDefinitionOptions = useMemo(() => {
    return customFieldDefinitions
      ? customFieldDefinitions?.map((field) => ({ label: field.label, value: field.id }))
      : [];
  }, [customFieldDefinitions]);

  const updateCustomFieldDefinition = async (customFieldDefinitionOption: Option<number> | null) => {
    const customFieldDefinition = customFieldDefinitions?.find(
      (field) => field.id === customFieldDefinitionOption?.value
    );

    await updateProcoreMetadata(
      { configObject: { timesheetsSync: { costCustomFieldDefinition: customFieldDefinition || null } } },
      { collapseCount: 2 }
    );
  };

  if (loading) return <Loader />;

  if (error) return null;

  return (
    <Formblock
      form={form}
      options={customFieldDefinitionOptions}
      name="customFieldDefinitions"
      type="select"
      defaultValue={procoreConfigOptions?.timesheetsSync?.costCustomFieldDefinition?.id}
      onChange={(option: Option<number> | null) => updateCustomFieldDefinition(option)}
      editing={true}
      labelStyle={DEFAULT_PROCORE_LABEL_STYLE}
      label="Cost custom field"
      labelInfo="The custom field Miter use to record cost for the timesheet on Procore"
      underlineTooltip
      isClearable
      inputProps={{ style: { width: PROCORE_CONFIG_INPUT_LENGTH } }}
    />
  );
};
