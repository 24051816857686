import { NetSuiteQueryTypes, SuiteAppOptions } from "backend/services/netsuite/types/netsuite-types";
import { Option } from "ui/form/Input";

export const NETSUITE_INPUT_LENGTH = 250;
export const DEFAULT_NETSUITE_LABEL_STYLE = { minWidth: 250 };

export const SUITEAPP_CONFIGURATION_MAP: Record<SuiteAppOptions, NetSuiteQueryTypes> = {
  none: {
    costType: {
      recordName: "costCategory",
    },
    costCode: {
      standard: {
        recordName: "projectTask",
      },
      recordName: "projectTask",
    },
  },
  rabb_it: {
    job: {
      fields: {
        jobCodeName: "entitynumber",
      },
    },
    ledgerEntry: {
      fields: {
        jobField: "custcol_r_it_reporting_project",
        standardCostCodeListField: "custcol_r_it_standard_cost_code_list",
        standardCostCodeField: "custcol_r_it_std_cost_code",
        costTypeField: "custcol_hrc_cost_type",
        costCodeField: "custrecord_hrc_pci_procoreid4",
      },
    },
    costType: {
      recordName: "customrecord_hrc_cost_type",
    },
    costCode: {
      standard: {
        recordName: "customrecord_r_it_list_code",
      },
      recordName: "customrecord_hrc_pci_costcode",
    },
  },
  appficiency: {
    job: {
      fields: {
        jobCodeName: "entitynumber",
      },
    },
    costType: {
      recordName: "customrecord_appf_constr_smel",
    },
    costCode: {
      recordName: "customrecord_appf_sov",
    },
    ledgerEntry: {
      fields: {
        jobField: "custcol_jobcosting_project",
        costTypeField: "custcol_appf_constr_smel_trans",
        costCodeField: "custcol_ce2p_sov",
      },
    },
  },
};

export const suiteAppOptions: Option<SuiteAppOptions>[] = [
  { label: "Miter Default", value: "none" },
  { label: "Rabb-IT", value: "rabb_it" },
  { label: "Appficiency", value: "appficiency" },
];
