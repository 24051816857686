import { MiterAPI } from "dashboard/miter";
import { useState, useCallback, useEffect } from "react";
import { Notifier } from "ui";
import { AcumaticaNonStockItemSourceData } from "backend/services/acumatica/types/acumatica-non-stock-item-types";

type FetchExpenseItems = {
  expenseItems: AcumaticaNonStockItemSourceData[] | undefined;
  loading: boolean;
  error: unknown | null;
  fetchExpenseItems: () => void;
};

export const useFetchAcumaticaExpenseItems = (integrationConnectionId?: string): FetchExpenseItems => {
  const [expenseItems, setExpenseItems] = useState<AcumaticaNonStockItemSourceData[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchExpenseItems = useCallback(async () => {
    if (!integrationConnectionId) return;

    setLoading(true);
    try {
      const result = await MiterAPI.integrations.acumatica.retrieve_expense_items(integrationConnectionId);

      if (result.error) throw Error(result.error);

      setExpenseItems(result);
    } catch (e: $TSFixMe) {
      Notifier.error("Error fetching Acumatica Expense Items.");
      setError(e);
    }
    setLoading(false);
  }, [integrationConnectionId]);

  useEffect(() => {
    fetchExpenseItems();
  }, []);

  return {
    expenseItems,
    loading,
    error,
    fetchExpenseItems,
  };
};
