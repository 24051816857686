import React, { useState, useEffect, useMemo } from "react";
import { AggregatedTeamMember, MiterAPI } from "../../miter";
import { Toggler } from "ui";
import Notifier from "dashboard/utils/notifier";
import { Helmet } from "react-helmet";
import "./benefits.css";
import { BenefitModal } from "./BenefitModal";
import {
  cleanEmployeeBenefit,
  CleanedEmployeeBenefit,
  useBenefitColDefs,
  DeleteBenefitModal,
} from "./benefitsUtils";
import { Plus, X } from "phosphor-react";
import { TableActionLink, TableV2 } from "ui/table-v2/Table";
import { AllowancesTable } from "./AllowancesTable";
import { useEmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";
import { useAllowanceAbilities } from "dashboard/hooks/abilities-hooks/useAllowanceAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {
  teamMember: AggregatedTeamMember;
};

const TeamMemberBenefitsAndAllowances: React.FC<Props> = ({ teamMember }) => {
  const miterAbilities = useMiterAbilities();
  const benefitAbilities = useEmployeeBenefitAbilities();
  const allowanceAbilities = useAllowanceAbilities();

  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);

  const [employeeBenefits, setEmployeeBenefits] = useState<CleanedEmployeeBenefit[]>();
  const [selectedBenefits, setSelectedBenefits] = useState<CleanedEmployeeBenefit[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editing, setEditing] = useState<CleanedEmployeeBenefit>();
  const [deleting, setDeleting] = useState(false);

  const isContractor = teamMember.employment_type === "contractor";
  const [togglerState, setTogglerState] = useState(isContractor ? "allowances" : "benefits");

  const togglerConfig = useMemo(() => {
    const config: { label: string; path: string }[] = [];

    if (!isContractor && benefitAbilities.teamPredicate("read")(teamMember)) {
      config.push({ label: "Benefits", path: "benefits" });
    }

    if (allowanceAbilities.teamPredicate("read")(teamMember)) {
      config.push({ label: "Allowances", path: "allowances" });
    }

    return config;
  }, [allowanceAbilities, benefitAbilities, teamMember, isContractor]);

  const handleToggle = (path) => {
    setTogglerState(path);
  };

  useEffect(() => {
    getEmployeeBenefits();
  }, [teamMember]);

  /*****************************************
                    Backend
   *****************************************/
  const getEmployeeBenefits = async () => {
    setLoading(true);
    try {
      const filter = [{ field: "employee", type: "string" as const, value: teamMember._id.toString() }];
      const response = await MiterAPI.benefits.employee.search(filter);

      if (response.error) throw Error(response.error);
      const cleanedBenefits = response
        .filter((b) => !b.check_benefit.metadata?.fringe_use)
        .map((b) => cleanEmployeeBenefit(b, benefitAbilities));
      setEmployeeBenefits(cleanedBenefits);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving benefits. We're looking into it!");
    }
    setLoading(false);
  };

  const deleteEmployeeBenefits = async () => {
    setDeleting(true);
    try {
      const response = await MiterAPI.benefits.employee.delete({ ids: selectedBenefits.map((b) => b._id) });
      if (response.error) throw Error(response.error);
      Notifier.success("Unenrollment successful.");
      setDeleteModal(false);
      setSelectedBenefits([]);
      getEmployeeBenefits();
    } catch (e) {
      Notifier.error(
        "There was an error during the unenrolling process. Please contact support@miter.com if the problem persists."
      );
    }
    setDeleting(false);
  };

  const staticActions: TableActionLink[] = [
    {
      label: "Add benefit",
      className: "button-2 no-margin",
      action: () => setAdding(true),
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => miterAbilities.can("benefits:create"),
    },
  ];

  const dynamicActions: TableActionLink[] = [
    {
      label: "Delete",
      className: "button-3 no-margin table-button",
      action: () => setDeleteModal(true),
      icon: <X weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => benefitAbilities.can("delete", selectedBenefits),
    },
  ];

  const colDefs = useBenefitColDefs({ type: "employee", showEmployeeField: false });

  return (
    <>
      <Helmet>
        <title>Employee Benefits Plans | Miter</title>
      </Helmet>
      <Toggler
        secondary={true}
        active={togglerState}
        config={togglerConfig}
        toggle={handleToggle}
        wrapperStyle={{ marginTop: 0 }}
      />
      {togglerState === "benefits" ? (
        <>
          <div>
            {editing && (
              <BenefitModal
                hide={() => setEditing(undefined)}
                isEmployeeBenefit={true}
                onSuccess={getEmployeeBenefits}
                benefitToUpdate={editing}
                employee={teamMember}
              />
            )}
            {adding && (
              <BenefitModal
                onSuccess={getEmployeeBenefits}
                hide={() => setAdding(false)}
                employee={teamMember}
                isEmployeeBenefit={true}
              />
            )}
            {deleteModal && (
              <DeleteBenefitModal
                onDelete={deleteEmployeeBenefits}
                onCancel={() => setDeleteModal(false)}
                multi={selectedBenefits.length > 1}
                loading={deleting}
              />
            )}
            <TableV2
              id={"employee-benefits-table"}
              resource="benefits"
              data={employeeBenefits}
              columns={colDefs}
              dynamicActions={dynamicActions}
              staticActions={staticActions}
              onSelect={setSelectedBenefits}
              defaultSelectedRows={selectedBenefits}
              onClick={(entry) => setEditing(entry)}
              isLoading={loading}
            />
          </div>
        </>
      ) : (
        <AllowancesTable tm={teamMember} />
      )}
    </>
  );
};

export default TeamMemberBenefitsAndAllowances;
