import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";

import { Button, Toggler } from "ui";
import TimeOffPolicies from "./TimeOffPolicies";
import { TimeOffRequestsTable } from "./TimeOffRequestsTable";
import { Helmet } from "react-helmet";
import { HolidaySchedulesTable } from "../holidays/HolidaySchedulesTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import TimeOffUpdatesTable from "./TimeOffUpdatesTable";
import { LeaveTypesTable } from "./LeaveTypesTable";
import { useLeaveTypes } from "dashboard/hooks/atom-hooks";

type Params = {
  view: string;
  id: string;
  action: string;
  filter: string;
};

const TimeOff: React.FC = () => {
  /*********************************************************
   *  Use initial hooks
   **********************************************************/
  const { view, id } = useParams<Params>();
  const navigate = useNavigate();
  const location = useLocation();
  const { can, cannot } = useMiterAbilities();
  const leaveTypes = useLeaveTypes();

  // Figure out the active path for the
  const activePath = location.pathname.includes("requests") ? "requests" : view;
  const canReadRequests = can("time_off:requests:personal:read") || can("time_off:requests:others:read");
  const canReadPolicies = can("time_off:policies:read");

  /*********************************************************
   *  Configure toggler
   **********************************************************/

  const togglerConfig = useMemo(() => {
    const list: TogglerConfigItem[] = [];

    if (canReadRequests) {
      list.push({ path: "requests", label: "Requests" });
    }

    if (canReadPolicies) {
      list.push({ path: "policies", label: "Policies" });
      list.push({ path: "updates", label: "Updates" });
    }

    if (can("time_off:holidays:read")) {
      list.push({ path: "holidays", label: "Holidays" });
    }

    if (can("time_off:leave:manage")) {
      list.push({ path: "leave-types", label: "Leave types" });
    }

    return list;
  }, [can, cannot]);

  const toggle = (page) => navigate("/time-off/" + page, { replace: true });

  /*********************************************************
   *  Rendering functions
   **********************************************************/
  const renderToggler = () => <Toggler config={togglerConfig} active={activePath} toggle={toggle} />;

  const renderView = () => {
    if (view === "requests" && canReadRequests) {
      return <TimeOffRequestsTable showToggler={true} />;
    } else if (view === "policies" && canReadPolicies) {
      if (!id) {
        return <TimeOffPolicies />;
      }
    } else if (location.pathname.includes("requests") && canReadRequests) {
      return <TimeOffRequestsTable showToggler={true} />;
    } else if (location.pathname.includes("updates") && canReadPolicies) {
      return <TimeOffUpdatesTable showAllUpdates={true} />;
    } else if (location.pathname.includes("holidays") && can("time_off:holidays:read")) {
      return <HolidaySchedulesTable />;
    } else if (location.pathname.includes("leave-types") && can("time_off:leave:manage")) {
      return <LeaveTypesTable leaveTypes={leaveTypes} />;
    }
  };

  /*********************************************************
   *  useEffect Hooks
   **********************************************************/

  useEffect(() => {
    const defaultPath = togglerConfig[0]?.path || "requests";
    const defaultQuery = defaultPath === "requests" ? "?status=unapproved" : "";

    if (!view) {
      navigate(`/time-off/${defaultPath}${defaultQuery}`, { replace: true });
    }
  }, [view]);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Time Off | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Time Off</h1>
          <div className="flex-1"></div>
          {can("time_off:settings") && (
            <Button className="button-1" text="Settings" onClick={() => navigate("/time-off/settings")} />
          )}
        </div>
        {renderToggler()}
        {renderView()}
      </div>
    </div>
  );
};

export default TimeOff;
