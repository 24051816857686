import { useMemo, useCallback, useContext } from "react";
import { ColumnConfig } from "ui/table-v2/Table";
import {
  TableReadyTm,
  getTeamMemberActiveStatus,
  convertAnnualRateToDisplayRate,
  convertDisplayRateToAnnualRate,
  employmentCategoryOptions,
  employmentTermOptions,
  employmentCategoryFormatter,
} from "../TeamUtils";
import { isValidEmail } from "dashboard/utils/validators";
import { capitalize, last } from "lodash";
import {
  EditableCallbackParams,
  ValueFormatterParams,
  ValueGetterParams,
  ICellEditorParams,
} from "ag-grid-community";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useHasAccessToChecklists } from "dashboard/gating";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { isClaspCompany, isClaspMember } from "dashboard/utils/clasp-utils";
import {
  useActiveCompany,
  useLookupCompanyUsers,
  useLookupTeamMemberCrews,
  useLookupCrew,
  useHasEnabledWcGroups,
  useWcCodeOptions,
  useWcCodeFormatter,
  useWcGroupOptions,
  useLookupWcGroup,
  useDepartmentNameFormatter,
  useDepartmentOptions,
  useLocationNameFormatter,
  useLocationOptions,
  useOtRuleFormatter,
  useOtRuleOptions,
  usePayScheduleFormatter,
  usePayScheduleOptions,
  useRateDifferentialFormatter,
  useRateDifferentialOptions,
  useTeamMemberNameFormatter,
  useTeamOptions,
  useWorkplaceFormatter,
  useWorkplaceOptions,
  useExpensePolicyOptions,
  useReimbursementPolicyOptions,
  useTimesheetPolicyOptions,
  useTimeOffRequestPolicyOptions,
  useTeamMemberChangeRequestPolicyOptions,
  usePolicyFormatter,
  useJobNameFormatter,
  useJobOptions,
  useActivityOptionsMap,
  useActivityLabelFormatter,
  useStandardClassificationOptions,
  useStandardClassificationFormatter,
  useCostTypes,
  useCostTypeOptions,
  useCostTypeFormatter,
  useLedgerMappingOptions,
  useLedgerMappingFormatter,
  usePrgs,
  usePrgOptions,
  usePrgFormatter,
  useGetClassificationOptions,
  useLookupRateClassification,
  useClassificationFormatter,
  useTeam,
  useLookupBenefitsEligibilityGroups,
  useBenefitsEligibilityGroupOptions,
} from "dashboard/hooks/atom-hooks";
import {
  BENEFITS_ELIGIBILITY_STATUS_OPTIONS,
  NON_UNION_PAY_TYPE_OPTIONS_MAP,
  UNION_PAY_TYPE_OPTIONS_MAP,
  SALARY_RATE_OPTIONS,
  SALARY_RATE_OPTIONS_MAP,
  EEO_ETHNICITY_OPTIONS,
  EEO_GENDER_OPTIONS,
  EEO_VETERAN_STATUS_OPTIONS,
  EEO_MARITAL_STATUS_OPTIONS,
  EEO_DISABILITY_STATUS_OPTIONS,
  EEO_JOB_CATEGORY_OPTIONS,
} from "dashboard/pages/team-members/forms/options";
import { standardizePhoneFormat, titleCase } from "dashboard/utils";
import { buildCompanyUserName } from "miter-utils";
import { usdString } from "ui";
import { useEnabledDemographicQuestions } from "dashboard/utils";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import AppContext from "dashboard/contexts/app-context";
import { generateCustomFieldColumns } from "dashboard/utils/custom-fields";
import { Option } from "ui/form/Input";

export const useTeamColumns = (view?: string | null): ColumnConfig<TableReadyTm>[] => {
  const { can } = useMiterAbilities();
  const teamAbilities = useTeamAbilities();
  const activeCompany = useActiveCompany();
  const lookupUser = useLookupCompanyUsers();
  const team = useTeam();

  // Crews
  const lookupTeamMemberCrews = useLookupTeamMemberCrews();
  const lookupCrew = useLookupCrew();

  const hasAccessToChecklists = useHasAccessToChecklists();

  // Workers comp options
  const wcCodeOptions = useWcCodeOptions();
  const wcCodeFormatter = useWcCodeFormatter();

  // Workers comp groups
  const hasEnabledWcGroups = useHasEnabledWcGroups();
  const lookupWcGroup = useLookupWcGroup();
  const wcGroupOptions = useWcGroupOptions();

  const hasEmployeeNavigator = useHasIntegration().has("employee_navigator");
  const hasClasp = isClaspCompany(activeCompany);
  const benefitsEligibilityGroupOptions = useBenefitsEligibilityGroupOptions();
  const lookupBenefitsEligibilityGroups = useLookupBenefitsEligibilityGroups();

  /** Get options for in table editing select fields */
  const departmentOptions = useDepartmentOptions();
  const departmentNameFormatter = useDepartmentNameFormatter();

  const locationOptions = useLocationOptions();
  const locationNameFormatter = useLocationNameFormatter();

  const workplaceOptions = useWorkplaceOptions();
  const workplaceFormatter = useWorkplaceFormatter();

  const teamOptions = useTeamOptions();
  const teamMemberNameFormatter = useTeamMemberNameFormatter();

  const payScheduleOptions = usePayScheduleOptions();
  const payScheduleFormatter = usePayScheduleFormatter();

  const otRuleOptions = useOtRuleOptions();
  const otRuleFormatter = useOtRuleFormatter();

  const rateDifferentialOptions = useRateDifferentialOptions();
  const rateDifferentialFormatter = useRateDifferentialFormatter();
  const hasRateDifferentials = rateDifferentialOptions.length > 0;

  const friendlyIdSorter = useMemo(() => {
    // If all the friendly_ids are numbers, sort them as numbers
    if (team.every((tm) => !isNaN(Number(tm.friendly_id)))) {
      return (a: string | undefined | null, b: string | undefined | null) => {
        if (!a && !b) return 0;
        return Number(a) - Number(b);
      };
    }
  }, [team]);

  const teamColumns: ColumnConfig<TableReadyTm>[] = useMemo(() => {
    const teamCols: ColumnConfig<TableReadyTm>[] = [
      {
        field: "friendly_id",
        headerName: "ID",
        headerTooltip: "The team member's unique identifier. Drag this column to pin/unpin it.",
        dataType: "string",
        maxWidth: 100,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "text",
        validations: (value: string) => (value ? true : "Friendly ID cannot be empty."),
        comparator: friendlyIdSorter,
        pinned: "left",
      },
      {
        field: "first_name",
        headerName: "First name",
        headerTooltip: "Drag this column to pin/unpin it.",
        dataType: "string",
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "text",
        validations: (value: string) => (value ? true : "First name cannot be empty."),
        pinned: "left",
      },
      {
        field: "last_name",
        headerName: "Last name",
        headerTooltip: "Drag this column to pin/unpin it.",
        dataType: "string",
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "text",
        validations: (value: string) => (value ? true : "Last name cannot be empty."),
        pinned: "left",
      },
      {
        field: "employment_type",
        headerName: "Employment type",
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params: ValueFormatterParams<TableReadyTm>) => {
            return params.value === "employee" ? "Employee" : "Contractor";
          },
        },
        dataType: "string",
        displayType: "badge",
        colors: {
          contractor: "orange",
          employee: "blue",
        },
        minWidth: 200,
      },
      {
        field: "title",
        headerName: "Title",
        dataType: "string",
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "text",
      },
      {
        field: "type",
        dataType: "string",
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        field: "active_status",
        headerName: "Active status",
        valueGetter: (params) => {
          return params.data ? getTeamMemberActiveStatus(params.data) : undefined;
        },
        dataType: "string",
        displayType: "badge",
        colors: {
          active: "light-green",
          dismissed: "lightgray",
          upcoming_dismissal: "lightgray",
          on_leave: "lightgray",
          not_started: "light-blue",
        },
        minWidth: 200,
      },
    ];
    if (hasAccessToChecklists) {
      teamCols.push({
        field: "onboarded",
        headerName: "Onboarded",
        dataType: "boolean",
        minWidth: 200,
      });
    }
    teamCols.push(
      {
        field: "has_payroll",
        headerName: "Has payroll",
        dataType: "boolean",
        valueGetter: (params: ValueGetterParams<TableReadyTm>) =>
          params.data?.payroll_status !== "non_payroll",
        minWidth: 200,
      },
      {
        field: "payroll_status",
        headerName: "Payroll status",
        dataType: "string",
        displayType: "badge",
        colors: {
          completed: "light-green",
          blocking: "light-red",
          needs_attention: "orange",
          non_payroll: "light-blue",
        },
        minWidth: 200,
      }
    );
    if (hasClasp) {
      teamCols.push({
        field: "integrations.clasp",
        headerName: "Benefits admin connected",
        dataType: "boolean",
        valueGetter: (params: ValueGetterParams<TableReadyTm>) => params.data && isClaspMember(params.data),
      });
      teamCols.push({
        field: "benefits_eligibility_status",
        headerName: "Benefits eligible",
        dataType: "string",
        displayType: "badge",
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string => {
          const benefitsEligibility = params.data?.benefits_eligibility_status;
          if (!benefitsEligibility) {
            return "Default";
          } else if (benefitsEligibility === "eligible") {
            return "Eligible";
          } else {
            return "Ineligible";
          }
        },
        colors: {
          Default: "blue",
          Eligible: "green",
          Ineligible: "red",
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: BENEFITS_ELIGIBILITY_STATUS_OPTIONS,
          isClearable: true,
        },
      });
      if (!!benefitsEligibilityGroupOptions.length) {
        teamCols.push({
          field: "benefits_eligibility_groups",
          headerName: "Benefits eligibility groups",
          dataType: "string",
          filter: "agSetColumnFilter",
          valueFormatter: (params: ValueFormatterParams<TableReadyTm>): string => {
            const benefitsEligibilityGroups = params.data?.benefits_eligibility_groups || [];
            return benefitsEligibilityGroups
              .map(
                (group) =>
                  lookupBenefitsEligibilityGroups(group?.value)?.integrations?.clasp?.clasp_subclass?.name
              )
              .join(", ");
          },
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return teamAbilities.can("update", params.data);
          },
          editorType: "multiselect",
          cellEditorParams: {
            options: benefitsEligibilityGroupOptions,
            isClearable: true,
          },
        });
      }
    }
    if (hasEmployeeNavigator) {
      teamCols.push({
        field: "integrations.employee_navigator",
        headerName: "Employee Navigator connected",
        dataType: "boolean",
        valueGetter: (params: ValueGetterParams<TableReadyTm>) =>
          !!params.data?.integrations?.employee_navigator,
      });
    }
    teamCols.push(
      {
        field: "departmentName",
        headerName: "Department",
        dataType: "string",
        initialHide: true,
        filter: true,
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.department_id;
          if (!id) return null;
          return departmentNameFormatter(id);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorField: "department_id",
        editorType: "select",
        cellEditorParams: {
          options: departmentOptions,
          isClearable: true,
        },
      },
      {
        field: "employment_category",
        headerName: "Employment category",
        dataType: "string",
        initialHide: true,
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const employmentCategory = params.data?.employment_category;
          if (!employmentCategory) return null;
          return employmentCategoryFormatter(employmentCategory);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorField: "employment_category",
        editorType: "select",
        cellEditorParams: {
          options: employmentCategoryOptions,
          isClearable: true,
        },
      },
      {
        field: "employment_term",
        headerName: "Employment term",
        dataType: "string",
        initialHide: true,
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const employmentTerm = params.data?.employment_term;
          if (!employmentTerm) return null;

          return titleCase(employmentTerm);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorField: "employment_term",
        editorType: "select",
        cellEditorParams: {
          options: employmentTermOptions,
          isClearable: true,
        },
      },
      {
        field: "locationName",
        headerName: "Location",
        dataType: "string",
        initialHide: true,
        filter: true,
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.location_id;
          if (!id) return null;
          return locationNameFormatter(id);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorField: "location_id",
        editorType: "select",
        cellEditorParams: {
          options: locationOptions,
          isClearable: true,
        },
      },
      {
        field: "workplace",
        headerName: "Workplace",
        dataType: "string",
        initialHide: true,
        filter: true,
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.workplace?._id || params.data?.workplace;
          if (!id) return null;
          return workplaceFormatter(id);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorField: "workplace._id",
        editorType: "select",
        cellEditorParams: {
          options: workplaceOptions,
          isClearable: true,
        },
      },
      {
        field: "is_universal_supervisor",
        headerName: "Is universal supervisor",
        initialHide: true,
        filter: true,
        dataType: "boolean",
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "checkbox",
      },
      {
        field: "reports_to.full_name",
        headerName: "Reports to",
        headerTooltip: "The team member's direct manager.",
        dataType: "string",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.reports_to?._id;
          if (!id) return null;
          return teamMemberNameFormatter(id);
        },
        enableRowGroup: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorField: "reports_to._id",
        editorType: "select",
        cellEditorParams: {
          options: teamOptions,
          isClearable: true,
        },
      },
      {
        field: "email",
        headerName: "Email",
        dataType: "string",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "text",
        validations: (value: string) => (value && !isValidEmail(value) ? "Invalid email." : true),
      },
      {
        field: "phone",
        headerName: "Phone",
        dataType: "string",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "text",
        valueEditor: (newValue: string) => {
          const cleanedPhone = standardizePhoneFormat(newValue);
          return cleanedPhone ? cleanedPhone : newValue;
        },
        validations: (value: string) => {
          return value && !standardizePhoneFormat(value) ? "Invalid phone number." : true;
        },
      },
      {
        field: "I9s",
        headerName: "I-9 status",
        dataType: "string",
        initialHide: true,
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string => {
          const lastI9 = last(params.data?.I9s);

          if (lastI9?.status) {
            if (lastI9.status === "draft") {
              if (lastI9.section_1.employee_esignature_id) {
                return "Employee submitted";
              } else {
                return "Draft";
              }
            }

            return capitalize(lastI9.status);
          }

          return "N/A";
        },
        filter: "agSetColumnFilter",
        displayType: "badge",
        colors: {
          "N/A": "light-gray",
          Draft: "light-yellow",
          "Employee submitted": "light-blue",
          complete: "light-green",
        },
      },
      {
        field: "I9_authorization_type",
        headerName: "I-9 authorization type",
        dataType: "string",
        initialHide: true,
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string => {
          const lastI9 = last(params.data?.I9s);
          if (lastI9?.section_1?.employee_authorization_type) {
            return capitalize(lastI9.section_1.employee_authorization_type);
          }
          return "N/A";
        },
        filter: "agSetColumnFilter",
        displayType: "badge",
        colors: {
          "N/A": "light-gray",
          Citizen: "light-blue",
          "Lawful permanent resident": "light-yellow",
          "Noncitizen national": "orange",
          "Alien authorized to work": "light-green",
        },
      },
      {
        field: "crews",
        headerName: "Crews",
        dataType: "string",
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            const crew = lookupCrew(params.value);
            return crew?.name;
          },
        },
        filterValueGetter: (params) => {
          const teamMemberId = params.data?._id;
          const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
          const crewIds = teamMemberCrews?.map((crew) => crew._id) || [];

          return crewIds;
        },
        valueGetter: (params) => {
          const teamMemberId = params.data?._id;
          const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
          return teamMemberCrews?.map((crew) => crew.name);
        },
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          return params.value?.join(", ");
        },
      },
      {
        field: "check_tm.payment_method_preference",
        headerName: "Payment method",
        dataType: "string",
        displayType: "badge",
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string => {
          const checkTm = params.data?.check_tm;
          if (checkTm) {
            if (checkTm.payment_method_preference === "manual") {
              return "Paper check";
            } else if (checkTm.payment_method_preference === "direct_deposit") {
              return "Direct deposit";
            } else {
              return "Not set";
            }
          } else {
            return "N/A";
          }
        },
        colors: {
          "Direct deposit": "light-purple",
          "Paper check": "orange",
          "Not set": "red",
          "N/A": "light-gray",
        },
      },
      {
        field: "start_date",
        headerName: "Start date",
        dataType: "date",
        dateType: "iso",
        hide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "date",
        editorDateType: "iso",
      },
      {
        field: "end_date",
        headerName: "End date",
        dataType: "date",
        dateType: "iso",
        minWidth: 200,
        initialHide: view === "active",
      },
      {
        field: "wc_code._id",
        headerName: "Workers comp code",
        dataType: "string",
        initialHide: true,
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.wc_code?._id;
          if (!id) return null;
          return wcCodeFormatter(id);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: wcCodeOptions,
          isClearable: true,
        },
      },
      {
        field: "pay_schedule_id",
        headerName: "Pay schedule",
        dataType: "string",
        initialHide: true,
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.pay_schedule_id;
          if (!id) return null;
          return payScheduleFormatter(id);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: payScheduleOptions,
          isClearable: true,
        },
      },
      {
        field: "default_ot_rule_id",
        headerName: "Default OT rule",
        dataType: "string",
        initialHide: true,
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.default_ot_rule_id;
          if (!id) return null;
          return otRuleFormatter(id);
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: otRuleOptions,
          isClearable: true,
        },
      },
      {
        field: "disable_multi_workplace_payrolls",
        headerName: "Disable multi-workplace payrolls",
        dataType: "string",
        displayType: "badge",
        colors: {
          Disabled: "red",
          Enabled: "green",
        },
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const disableMultiWorkplacePayrolls = params.data?.disable_multi_workplace_payrolls;
          if (disableMultiWorkplacePayrolls == null) return null;
          return disableMultiWorkplacePayrolls ? "Disabled" : "Enabled";
        },
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: [
            { label: "Disabled", value: true },
            { label: "Enabled", value: false },
          ],
          isClearable: true,
        },
      }
    );
    if (hasRateDifferentials) {
      teamCols.push({
        field: "rate_differential_id",
        headerName: "Rate differential",
        dataType: "string",
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.rate_differential_id;
          if (!id) return null;
          return rateDifferentialFormatter(id);
        },
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: rateDifferentialOptions,
          isClearable: true,
        },
      });
    }
    teamCols.push(
      {
        field: "kiosk_pin",
        headerName: "Kiosk PIN",
        dataType: "string",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "text",
        validations: (value: string) => (value ? true : "Kiosk PIN cannot be empty."),
      },
      {
        field: "opt_out_of_connected_kiosks",
        headerName: "Opted out of connected kiosk",
        dataType: "boolean",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "checkbox",
      },
      {
        field: "created_by",
        headerName: "Created by",
        initialHide: true,
        dataType: "string",
        filter: "agSetColumnFilter",
        valueGetter: (params) => {
          if (!params.data) return;

          // If the created_by is an object id, lookup the user name
          if (params.data.creation_method === "user") {
            const user = lookupUser(params.data.created_by);
            if (user) return buildCompanyUserName(user);
          }

          // Otherwise, just return the created_by value
          return capitalize(params.data.creation_method);
        },
      },
      {
        field: "created_at",
        headerName: "Created at",
        initialHide: true,
        dataType: "date",
        dateType: "timestamp",
        dateFormat: "LLL d, yyyy h:mm a",
      },
      {
        field: "bank_accounts",
        headerName: "# bank accounts",
        initialHide: true,
        headerTooltip: "Number of bank accounts that the team member has connected.",
        filter: true,
        valueGetter: (params) => {
          if (!params.data) return;

          return params.data.bank_accounts.length;
        },
      }
    );

    if (hasEnabledWcGroups) {
      teamCols.push({
        field: "wc_group_id",
        headerName: "Workers comp group",
        dataType: "string",
        initialHide: true,
        filter: "agSetColumnFilter",
        valueFormatter: (params) => {
          const wcGroup = lookupWcGroup(params.data?.wc_group_id);
          return wcGroup?.name || "-";
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: wcGroupOptions,
          isClearable: true,
        },
      });
    }

    return teamCols;
  }, [
    can,
    teamAbilities,
    hasAccessToChecklists,
    hasClasp,
    hasEmployeeNavigator,
    departmentNameFormatter,
    departmentOptions,
    locationNameFormatter,
    locationOptions,
    workplaceFormatter,
    workplaceOptions,
    teamMemberNameFormatter,
    teamOptions,
    lookupCrew,
    lookupTeamMemberCrews,
    wcCodeFormatter,
    wcCodeOptions,
    payScheduleFormatter,
    payScheduleOptions,
    otRuleFormatter,
    otRuleOptions,
    hasRateDifferentials,
    rateDifferentialFormatter,
    rateDifferentialOptions,
    lookupUser,
    hasEnabledWcGroups,
    lookupWcGroup,
    wcGroupOptions,
    view,
    activeCompany,
    friendlyIdSorter,
  ]);

  return teamColumns;
};

export const usePolicyColumns = (): ColumnConfig<TableReadyTm>[] => {
  const teamAbilities = useTeamAbilities();
  const policyFormatter = usePolicyFormatter();
  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const expensePolicyOptions = useExpensePolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();
  const timeOffRequestPolicyOptions = useTimeOffRequestPolicyOptions();
  const teamMemberChangeRequestPolicyOptions = useTeamMemberChangeRequestPolicyOptions();

  const policyColumns = useMemo((): ColumnConfig<TableReadyTm>[] => {
    return [
      {
        field: "expense_policy_id",
        headerName: "Expense policy",
        dataType: "string",
        filter: "agSetColumnFilter",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.expense_policy_id;
          if (!id) return null;
          return policyFormatter(id);
        },
        cellEditorParams: {
          options: expensePolicyOptions,
          isClearable: true,
        },
      },
      {
        field: "reimbursement_policy_id",
        headerName: "Reimbursement policy",
        dataType: "string",
        filter: "agSetColumnFilter",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.reimbursement_policy_id;
          if (!id) return null;
          return policyFormatter(id);
        },
        cellEditorParams: {
          options: reimbursementPolicyOptions,
          isClearable: true,
        },
      },
      {
        field: "timesheet_policy_id",
        headerName: "Timesheet policy",
        dataType: "string",
        filter: "agSetColumnFilter",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.timesheet_policy_id;
          if (!id) return null;
          return policyFormatter(id);
        },
        cellEditorParams: {
          options: timesheetPolicyOptions,
          isClearable: true,
        },
      },
      {
        field: "time_off_request_policy_id",
        headerName: "Time off request policy",
        dataType: "string",
        filter: "agSetColumnFilter",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.time_off_request_policy_id;
          if (!id) return null;
          return policyFormatter(id);
        },
        cellEditorParams: {
          options: timeOffRequestPolicyOptions,
          isClearable: true,
        },
      },
      {
        field: "team_member_change_request_policy_id",
        headerName: "Team member change request policy",
        dataType: "string",
        filter: "agSetColumnFilter",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.team_member_change_request_policy_id;
          if (!id) return null;
          return policyFormatter(id);
        },
        cellEditorParams: {
          options: teamMemberChangeRequestPolicyOptions,
          isClearable: true,
        },
      },
    ];
  }, [
    teamAbilities,
    policyFormatter,
    timesheetPolicyOptions,
    expensePolicyOptions,
    reimbursementPolicyOptions,
    timeOffRequestPolicyOptions,
    teamMemberChangeRequestPolicyOptions,
  ]);

  return policyColumns;
};

export const usePayColumns = (): ColumnConfig<TableReadyTm>[] => {
  const activeCompany = useActiveCompany();
  const teamAbilities = useTeamAbilities();

  const prgs = usePrgs();
  const hasPrgs = prgs.length > 0;
  const prgFormatter = usePrgFormatter();
  const prgOptions = usePrgOptions();
  /** Get the pay type options (including union rate if applicable) */
  const getPayTypeCellEditorParams = useCallback(
    (params: ICellEditorParams<TableReadyTm>): { options: Option<string>[]; isClearable: boolean } => {
      const unionRate = params?.data?.union_rate;
      const hourlyLabel = hasPrgs ? "Hourly (non-union)" : "Hourly";
      const unionRateOption = hasPrgs || unionRate ? [{ value: "union_rate", label: "Hourly (union)" }] : [];

      const payTypeOptions = [
        { value: "salary", label: "Salary" },
        { value: "hourly", label: hourlyLabel },
        ...unionRateOption,
      ];

      return {
        options: payTypeOptions,
        isClearable: true,
      };
    },
    [hasPrgs]
  );

  const classificationFormatter = useClassificationFormatter();
  const getClassificationOptions = useGetClassificationOptions();
  const lookupRateClassification = useLookupRateClassification();
  /** Get the classification options from the row's pay rate group */
  const getClassificationCellEditorParams = useCallback(
    (params: ICellEditorParams<TableReadyTm>): { options: Option<string>[]; isClearable: boolean } => {
      // params is undefined when bulk editing -> if so, show all possible classifications
      const prgId = params?.data?.pay_rate_group || undefined;
      const classificationOptions = getClassificationOptions({ prgId });

      return {
        options: classificationOptions,
        isClearable: true,
      };
    },
    [getClassificationOptions]
  );

  const payColumns = useMemo((): ColumnConfig<TableReadyTm>[] => {
    const columns: ColumnConfig<TableReadyTm>[] = [
      /** Pay rate fields */
      {
        field: "pay_type",
        headerName: "Pay type",
        dataType: "string",
        displayType: "badge",
        colors: {
          Hourly: "yellow",
          "Hourly (non-union)": "yellow",
          Salary: "green",
          "Hourly (union)": "blue",
        },
        bulkEditPriority: 2, // Prevent bulk edit edge case where pay_type is set to hourly and salary_rate_display is set to weekly or monthly
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "select",
        cellEditorParams: getPayTypeCellEditorParams,
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const payType = params.data?.pay_type;
          if (!payType) return null;

          if (hasPrgs) {
            return UNION_PAY_TYPE_OPTIONS_MAP[payType];
          }
          return NON_UNION_PAY_TYPE_OPTIONS_MAP[payType];
        },
        valueEditor: (newValue: string, rowData: TableReadyTm) => {
          // If the pay type is changed, then set the pay rate value to null
          if (newValue !== rowData.pay_type) {
            rowData.pay_rate = null;
          }
          // If the pay type is changed to hourly or union rate, then set the salary rate display to null
          if (newValue === "hourly" || newValue === "union_rate") {
            rowData.salary_rate_display = null;
          }
          // If the pay type is changed to hourly or salary, then clear the pay rate group and union rate
          if (newValue !== "union_rate") {
            rowData.pay_rate_group = null;
            rowData.union_rate = null;
          }

          return newValue;
        },
      },
      {
        field: "pay_rate",
        headerName: "Pay rate",
        dataType: "number",
        unit: "dollar",
        initialHide: true,
        editableHide: true,
        editorType: "number",
      },
      // Need to show pay rate in the correct salary display format (per year/month/week)
      {
        field: "pay_rate",
        headerName: "Pay rate",
        dataType: "number",
        unit: "dollar",
        editableOnly: true,
        editorType: "number",
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data) && params.data?.pay_type !== "union_rate";
        },
        bulkEditPriority: 3,
        valueGetter: (params: ValueGetterParams<TableReadyTm>) => {
          if (params.data?.pay_rate == null) return null;

          return convertAnnualRateToDisplayRate(
            activeCompany,
            params.data?.pay_type,
            params.data?.salary_rate_display,
            params.data?.pay_rate
          );
        },
        valueFormatter: (params: ValueFormatterParams<TableReadyTm>): string => {
          if (params.data?.pay_rate == null) {
            // If the pay type is not union rate, then the pay rate needs to be set by the user
            if (params.data?.pay_type !== "union_rate") {
              return "-";
            }
            // If the pay type is union rate, then we either indicate the pay rate is set by the union rate
            // or if available we show the base rate for the classification
            const classification =
              params.data?.union_rate && lookupRateClassification(params.data.union_rate);
            return classification ? `${usdString(classification.base_rate)} per hour` : "Set by union rate";
          }

          const payRate = convertAnnualRateToDisplayRate(
            activeCompany,
            params.data?.pay_type,
            params.data?.salary_rate_display,
            params.data?.pay_rate
          );

          if (params.data?.pay_type === "salary") {
            return `${usdString(payRate)} per ${params.data?.salary_rate_display}`;
          }

          return `${usdString(payRate)} per hour`;
        },
        valueEditor: (newValue: number | null, rowData: TableReadyTm): number | null => {
          if (newValue == null) return null;

          const annualRate = convertDisplayRateToAnnualRate(
            activeCompany,
            rowData.salary_rate_display || "",
            newValue
          );
          return annualRate;
        },
      },
    ];

    if (hasPrgs) {
      columns.push(
        {
          field: "pay_rate_group",
          headerName: "Pay rate group",
          valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
            const id = params.data?.pay_rate_group;
            if (!id) return null;
            return prgFormatter(id);
          },
          valueEditor: (newValue: string, rowData: TableReadyTm) => {
            if (rowData.pay_rate_group !== newValue) {
              rowData.union_rate = null;
            }
            return newValue;
          },
          dataType: "string",
          initialHide: true,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return (
              teamAbilities.can("update_sensitive", params.data) && params.data?.pay_type === "union_rate"
            );
          },
          editorType: "select",
          cellEditorParams: {
            options: prgOptions,
            isClearable: true,
          },
          isEditableRequired: (rowData: TableReadyTm) => {
            return rowData.pay_type === "union_rate";
          },
        },
        {
          field: "union_rate",
          headerName: "Classification",
          valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
            const id = params.data?.union_rate;
            if (!id) return null;
            return classificationFormatter(id);
          },
          dataType: "string",
          initialHide: true,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return (
              teamAbilities.can("update_sensitive", params.data) && params.data?.pay_type === "union_rate"
            );
          },
          editorType: "select",
          cellEditorParams: getClassificationCellEditorParams,
          isEditableRequired: (rowData: TableReadyTm) => {
            return rowData.pay_type === "union_rate";
          },
        }
      );
    }

    columns.push(
      {
        field: "salary_rate_display",
        headerName: "Pay frequency",
        dataType: "string",
        editableOnly: true,
        bulkEditPriority: 1,
        valueGetter: (params: ValueGetterParams<TableReadyTm>) => {
          if (params.data?.pay_type === "hourly" || params.data?.pay_type === "union_rate") {
            return "Per hour";
          }

          if (params.data?.salary_rate_display == null) {
            params.data!.salary_rate_display = "year"; // Default to displaying salary per year
          }

          const value = params.data!.salary_rate_display;

          return SALARY_RATE_OPTIONS_MAP[value];
        },
        valueFormatter: (params: ValueFormatterParams<TableReadyTm>) => {
          return params.value;
        },
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data) && params?.data?.pay_type === "salary";
        },
        editorType: "select",
        cellEditorParams: {
          options: SALARY_RATE_OPTIONS,
          isClearable: true,
        },
      },
      {
        field: "overtime_exempt",
        headerName: "Overtime exempt",
        dataType: "boolean",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        editorType: "checkbox",
      }
    );

    return columns;
  }, [activeCompany, getPayTypeCellEditorParams, hasPrgs, teamAbilities]);

  return payColumns;
};

export const useEeoColumns = (): ColumnConfig<TableReadyTm>[] => {
  const enabledDemographicQuestions = useEnabledDemographicQuestions();
  const miterAbilities = useMiterAbilities();
  const teamAbilities = useTeamAbilities();

  const eeoColumns = useMemo((): ColumnConfig<TableReadyTm>[] => {
    const columns: ColumnConfig<TableReadyTm>[] = [];

    if (miterAbilities.can("team:read_sensitive")) {
      if (enabledDemographicQuestions["ethnicity"]) {
        columns.push({
          field: "demographics.ethnicity",
          headerName: "Ethnicity",
          dataType: "string",
          filter: "agSetColumnFilter",
          valueGetter: (params) =>
            params.data && teamAbilities.can("read_sensitive", params.data)
              ? params.data?.demographics?.ethnicity
              : null,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return teamAbilities.can("update_sensitive", params.data);
          },
          editorType: "select",
          cellEditorParams: {
            options: EEO_ETHNICITY_OPTIONS,
            isClearable: true,
          },
        });
      }

      if (enabledDemographicQuestions["gender"]) {
        columns.push({
          field: "demographics.gender",
          headerName: "Gender",
          dataType: "string",
          displayType: "badge",
          filter: "agSetColumnFilter",
          colors: {
            Male: "light-gray",
            Female: "blue",
          },
          valueGetter: (params) =>
            params.data && teamAbilities.can("read_sensitive", params.data)
              ? params.data?.demographics?.gender
              : null,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return teamAbilities.can("update_sensitive", params.data);
          },
          editorType: "select",
          cellEditorParams: {
            options: EEO_GENDER_OPTIONS,
            isClearable: true,
          },
        });
      }

      if (enabledDemographicQuestions["veteran_status"]) {
        columns.push({
          field: "demographics.veteran_status",
          headerName: "Veteran status",
          dataType: "string",
          filter: "agSetColumnFilter",
          valueGetter: (params) =>
            params.data && teamAbilities.can("read_sensitive", params.data)
              ? params.data?.demographics?.veteran_status
              : null,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return teamAbilities.can("update_sensitive", params.data);
          },
          editorType: "select",
          cellEditorParams: {
            options: EEO_VETERAN_STATUS_OPTIONS,
            isClearable: true,
          },
        });
      }

      if (enabledDemographicQuestions["marital_status"]) {
        columns.push({
          field: "demographics.marital_status",
          headerName: "Marital status",
          dataType: "string",
          displayType: "badge",
          filter: "agSetColumnFilter",
          colors: {
            Single: "blue",
            Married: "green",
            Separated: "light-gray",
            Divorced: "yellow",
            Widowed: "light-blue",
            "Prefer Not To Disclose": "gray",
          },
          valueGetter: (params) =>
            params.data && teamAbilities.can("read_sensitive", params.data)
              ? params.data?.demographics?.marital_status
              : null,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return teamAbilities.can("update_sensitive", params.data);
          },
          editorType: "select",
          cellEditorParams: {
            options: EEO_MARITAL_STATUS_OPTIONS,
            isClearable: true,
          },
        });
      }

      if (enabledDemographicQuestions["disability_status"]) {
        columns.push({
          field: "demographics.disability_status",
          headerName: "Disability status",
          dataType: "string",
          displayType: "badge",
          filter: "agSetColumnFilter",
          colors: {
            "Yes, I have a disability (or previously had a disability)": "red",
            "No, I do not have a disability and have not had one in the past": "green",
            "Prefer Not To Disclose": "gray",
          },
          valueGetter: (params) =>
            params.data && teamAbilities.can("read_sensitive", params.data)
              ? params.data?.demographics?.disability_status
              : null,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return teamAbilities.can("update_sensitive", params.data);
          },
          editorType: "select",
          cellEditorParams: {
            options: EEO_DISABILITY_STATUS_OPTIONS,
            isClearable: true,
          },
        });
      }

      if (enabledDemographicQuestions["job_category"]) {
        columns.push({
          field: "demographics.job_category",
          headerName: "Job category",
          dataType: "string",
          filter: "agSetColumnFilter",
          valueGetter: (params) =>
            params.data && teamAbilities.can("read_sensitive", params.data)
              ? params.data?.demographics?.job_category
              : null,
          editable: (params: EditableCallbackParams<TableReadyTm>) => {
            return teamAbilities.can("update_sensitive", params.data);
          },
          editorType: "select",
          cellEditorParams: {
            options: EEO_JOB_CATEGORY_OPTIONS,
            isClearable: true,
          },
        });
      }
    }

    return columns;
  }, [enabledDemographicQuestions, miterAbilities, teamAbilities]);

  return eeoColumns;
};

export const useDefaultAssociationsColumns = (): ColumnConfig<TableReadyTm>[] => {
  const miterAbilities = useMiterAbilities();
  const teamAbilities = useTeamAbilities();

  /** Job hooks */
  const jobNameFormatter = useJobNameFormatter();
  const jobOptions = useJobOptions();

  /** Activity hooks */
  const activityOptionsMap = useActivityOptionsMap();
  const getActivityOptions = useCallback(
    (params: ICellEditorParams<TableReadyTm>) => {
      const activityOptions = activityOptionsMap.get(params?.data?.default_job_id || "");
      return {
        options: activityOptions,
        isClearable: true,
      };
    },
    [activityOptionsMap]
  );
  const activityLabelFormatter = useActivityLabelFormatter();

  /** Standard classification hooks */
  const standardClassificationOptions = useStandardClassificationOptions();
  const hasStandardClassifications = standardClassificationOptions.length > 0;
  const standardClassificationFormatter = useStandardClassificationFormatter();

  /** Cost type hooks */
  const costTypes = useCostTypes();
  const hasCostTypes = costTypes.length > 0;
  const costTypeOptions = useCostTypeOptions();
  const costTypeFormatter = useCostTypeFormatter();

  /** Ledger mapping hooks */
  const ledgerMappingOptions = useLedgerMappingOptions();
  const ledgerMappingFormatter = useLedgerMappingFormatter();

  const defaultAssociationsColumns = useMemo(() => {
    const columns: ColumnConfig<TableReadyTm>[] = [
      {
        field: "default_job_id",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.default_job_id;
          if (!id) return null;
          return jobNameFormatter(id);
        },
        headerName: "Default job",
        dataType: "string",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: jobOptions,
          isClearable: true,
        },
      },
      {
        field: "default_activity_id",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.default_activity_id;
          if (!id) return null;
          return activityLabelFormatter(id);
        },
        headerName: "Default activity",
        dataType: "string",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        cellEditorParams: getActivityOptions,
      },
    ];

    if (hasStandardClassifications) {
      columns.push({
        field: "standard_classification_id",
        headerName: "Standard classification",
        dataType: "string",
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.standard_classification_id;
          if (!id) return null;
          return standardClassificationFormatter(id);
        },
        initialHide: true,
        editorType: "select",
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update_sensitive", params.data);
        },
        cellEditorParams: {
          options: standardClassificationOptions,
          isClearable: true,
        },
      });
    }

    if (hasCostTypes) {
      columns.push({
        field: "default_cost_type_id",
        valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
          const id = params.data?.default_cost_type_id;
          if (!id) return null;
          return costTypeFormatter(id);
        },
        headerName: "Default cost type",
        dataType: "string",
        initialHide: true,
        editable: (params: EditableCallbackParams<TableReadyTm>) => {
          return teamAbilities.can("update", params.data);
        },
        editorType: "select",
        cellEditorParams: {
          options: costTypeOptions,
          isClearable: true,
        },
      });
    }

    columns.push({
      field: "ledger_mapping_id",
      valueGetter: (params: ValueGetterParams<TableReadyTm>): string | null => {
        const id = params.data?.ledger_mapping_id;
        if (!id) return null;
        return ledgerMappingFormatter(id);
      },
      headerName: "GL mapping",
      dataType: "string",
      editable: (params: EditableCallbackParams<TableReadyTm>) => {
        return teamAbilities.can("update", params.data) && miterAbilities.can("accounting:settings");
      },
      editorType: "select",
      cellEditorParams: {
        options: ledgerMappingOptions,
        isClearable: true,
      },
      initialHide: true,
    });
    return columns;
  }, [
    miterAbilities,
    teamAbilities,
    jobNameFormatter,
    jobOptions,
    getActivityOptions,
    activityLabelFormatter,
    hasStandardClassifications,
    standardClassificationFormatter,
    standardClassificationOptions,
    hasCostTypes,
    costTypeFormatter,
    costTypeOptions,
    ledgerMappingFormatter,
    ledgerMappingOptions,
  ]);

  return defaultAssociationsColumns;
};

export const useCustomFieldColumns = (): ColumnConfig<TableReadyTm>[] => {
  const { customFields } = useContext(AppContext);
  const teamAbilities = useTeamAbilities();

  const editableFunction = useCallback(
    (params: EditableCallbackParams<TableReadyTm>) => {
      return teamAbilities.can("update", params.data);
    },
    [teamAbilities]
  );

  const customFieldColumns = useMemo(() => {
    const columns = generateCustomFieldColumns<TableReadyTm>(customFields, "team_member", editableFunction);

    return columns;
  }, [customFields, editableFunction]);

  return customFieldColumns;
};
