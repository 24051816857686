import { AggregatedTeamMember, MiterAPI, MiterError, TeamMember, TeamMemberOnLeave } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import { Option } from "ui/form/Input";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Label } from "ui";
import * as vals from "dashboard/utils/validators";
import { Notifier } from "dashboard/utils";

import { DateTime } from "luxon";
import { useLeaveTypeOptions, useLookupLeaveType, useRefetchLeaveTypes } from "dashboard/hooks/atom-hooks";
import { PlusCircle } from "phosphor-react";
import { LeaveTypeModal } from "../time-off/LeaveTypeModal";

type TmOnLeaveForm = {
  start_date: DateTime;
  end_date?: DateTime;
  type: Option<string>;
  reason: string;
  config: {
    pause_notifications: boolean;
    pause_payroll: boolean;
    time_off?: {
      pause_accruals: boolean;
      pause_level_changes: boolean;
      pause_applying_carryover_limits: boolean;
    };
  };
};

type Props = {
  tm: AggregatedTeamMember;
  hide: () => void;
  leave?: TeamMemberOnLeave;
};

export const LeaveOfAbsenceModal: React.FC<Props> = ({ tm, hide, leave }) => {
  const [loading, setLoading] = useState(false);
  const [showLeaveTypeModal, setShowLeaveTypeModal] = useState(false);

  const refetchLeaveTypes = useRefetchLeaveTypes();
  const leaveTypeLookup = useLookupLeaveType();

  const isLatestLeave = useMemo(() => {
    return !leave || leave._id === tm.on_leave?._id;
  }, [leave, tm.on_leave]);

  const leaveTypeOptions = useLeaveTypeOptions();

  const validateData = (data: TmOnLeaveForm): boolean => {
    const onLeaveStartDateIso = data.start_date?.toISODate();
    const onLeaveEndDateIso = data.end_date?.toISODate();
    if (!tm.start_date) {
      Notifier.error("Please set a start date for the employee before setting them on leave.");
    } else if (!!onLeaveEndDateIso && onLeaveStartDateIso >= onLeaveEndDateIso) {
      Notifier.error("The start date must be before the end date of the on leave period.");
    } else {
      return true;
    }
    return false;
  };

  const defaultValues = useMemo(() => {
    if (!leave) return;
    return {
      ...leave,
      type: leaveTypeOptions.find((type) => type.value === leave.type),
      start_date: DateTime.fromISO(leave.start_date),
      end_date: leave.end_date ? DateTime.fromISO(leave.end_date) : undefined,
    };
  }, [leave]);

  const { register, errors, control, handleSubmit, reset, getValues } = useForm({ defaultValues });

  const handleTypeChange = (e: Option<string>) => {
    const type = leaveTypeLookup(e.value);
    if (!type) return;
    reset({ ...getValues(), config: type.config });
  };

  const handleDelete = async () => {
    if (!leave) return;
    setLoading(true);
    try {
      const response = await MiterAPI.team_member.delete_leave_of_absence(tm._id, leave._id);
      if (response.error) throw new Error(response.error);
      Notifier.success("Leave of absence successfully deleted.");
      hide();
    } catch (e: $TSFixMe) {
      Notifier.error("There was an error deleting leave of absence. " + e.message);
    }
    setLoading(false);
  };

  const save = async (data: TmOnLeaveForm) => {
    if (!validateData(data)) return;
    setLoading(true);
    try {
      let response: TeamMember & MiterError;

      if (leave) {
        response = await MiterAPI.team_member.update_leave_of_absence(tm._id, {
          ...data,
          _id: leave._id,
          type: data.type.value,
          start_date: data.start_date.toISODate(),
          end_date: data.end_date?.toISODate(),
        });
      } else {
        response = await MiterAPI.team_member.schedule_leave_of_absence(tm._id, {
          ...data,
          type: data.type.value,
          start_date: data.start_date.toISODate(),
          end_date: data.end_date?.toISODate(),
        });
      }
      if (response.error) throw new Error(response.error);
      Notifier.success("On leave of absence information was successfully saved.");
      hide();
    } catch (e) {
      Notifier.error("There was a problem scheduling on leave for the employee. We're looking into it!");
    }
    setLoading(false);
  };
  const hideModal = async () => {
    await refetchLeaveTypes();
    setShowLeaveTypeModal(false);
  };

  return (
    <>
      {showLeaveTypeModal && <LeaveTypeModal hide={hideModal} />}
      <ActionModal
        onHide={hide}
        onCancel={hide}
        onSubmit={handleSubmit(save)}
        submitText={leave ? "Update" : "Save"}
        headerText={`Set ${tm.full_name} on leave`}
        loading={loading}
        showCancel={true}
        showSubmit={true}
        wrapperStyle={{ width: 800 }}
        bodyStyle={{ maxHeight: 580 }}
        showDelete={!!leave && isLatestLeave}
        onDelete={handleDelete}
      >
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ padding: 15, width: 350 }}>
            <div className="flex">
              <Formblock
                label="Type*"
                type="select"
                control={control}
                requiredSelect={true}
                name="type"
                className={"modal "}
                options={leaveTypeOptions}
                onChange={handleTypeChange}
                errors={errors}
                editing={true}
                style={{ width: 220 }}
              />
              <PlusCircle
                onClick={() => setShowLeaveTypeModal(true)}
                size={18}
                style={{ cursor: "pointer", marginLeft: 5, marginTop: 14, color: "gray" }}
              />
            </div>
            <Formblock
              label="Start Date*"
              labelInfo="Please select the start date of the team member's on leave of absence period."
              type="datetime"
              control={control}
              dateOnly={true}
              name="start_date"
              className={"modal"}
              rules={{
                validate: (startDate: DateTime) => {
                  const onLeaveStartDateIso = startDate?.toISODate();
                  if (!onLeaveStartDateIso) {
                    return "This field is required.";
                  } else if (tm.start_date && onLeaveStartDateIso < tm.start_date) {
                    return "Must be after employment start date.";
                  } else if (!!tm.end_date && onLeaveStartDateIso >= tm.end_date) {
                    return "Must be before employment end date.";
                  }
                },
              }}
              errors={errors}
              editing={true}
              disabled={leave && leave.start_date < DateTime.now().toISODate()}
              isClearable={true}
            />
            <Formblock
              label="End Date"
              labelInfo="Please select the end date of the team member's on leave of absence period."
              type="datetime"
              control={control}
              dateOnly={true}
              name="end_date"
              className={"modal"}
              errors={errors}
              rules={{
                validate: (endDate: DateTime) => {
                  const onLeaveEndDateIso = endDate?.toISODate();
                  if (!!onLeaveEndDateIso && tm.end_date && onLeaveEndDateIso >= tm.end_date) {
                    return "Must be before employment end date";
                  }
                },
              }}
              editing={true}
              disabled={!isLatestLeave} // disable if this isn't the latest leave to avoid edge cases with overlapping leaves
              isClearable={true}
            />
            <Formblock
              label="Reason for going on leave*"
              type="paragraph"
              register={register(vals.required)}
              name="reason"
              className={"modal"}
              errors={errors}
              editing={true}
            />
          </div>
          <div style={{ padding: 15, width: 350 }}>
            <Label style={{ height: "auto", marginBottom: 10 }} label="Configurations"></Label>
            <Formblock
              name="config.pause_payroll"
              type="checkbox"
              text="Pause payroll"
              errors={errors}
              defaultValue={false}
              editing={true}
              register={register()}
              style={{ marginBottom: 0 }}
            />
            <Formblock
              name="config.pause_notifications"
              type="checkbox"
              text="Pause notifications"
              errors={errors}
              defaultValue={false}
              editing={true}
              style={{ marginBottom: 0 }}
              register={register()}
            />
            <Formblock
              name="config.time_off.pause_accruals"
              type="checkbox"
              text="Pause fixed time off accruals"
              errors={errors}
              defaultValue={false}
              editing={true}
              register={register()}
              style={{ marginBottom: 0 }}
            />
            <Formblock
              name="config.time_off.pause_level_changes"
              type="checkbox"
              text="Pause time off policy level changes"
              errors={errors}
              defaultValue={false}
              editing={true}
              register={register()}
              style={{ marginBottom: 0 }}
            />
            <Formblock
              name="config.time_off.pause_applying_carryover_limits"
              type="checkbox"
              text="Pause applying any time off carry over limits"
              errors={errors}
              defaultValue={false}
              editing={true}
              register={register()}
              style={{ marginBottom: 0 }}
            />{" "}
            <div className="vertical-spacer"></div>
          </div>
        </div>
      </ActionModal>
    </>
  );
};
