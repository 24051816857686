export type CPRFormat =
  | "unsigned_pdf"
  | "save_signed"
  | "wh347_pdf"
  | "lcp_upload"
  | "emars_upload"
  | "dir_xml_upload"
  | "il_upload"
  | "wa_xml_upload"
  | "nj_pdf"
  | "nj_csv"
  | "nj_csv_fringes"
  | "nyc_pdf"
  | "nyc_labor_law_pdf"
  | "nyc_weekly_pdf"
  | "or_pdf"
  | "pa_pdf";

export const canAddExceptionsToFormat = (format: CPRFormat): boolean => {
  return format === "unsigned_pdf" || format === "save_signed";
};

export const canAddRemarksToFormat = (format: CPRFormat): boolean => {
  return format === "unsigned_pdf" || format === "save_signed" || format === "or_pdf";
};
