import { Formblock, Loader } from "ui";
import React, { useMemo } from "react";
import { IntegrationConnection, IntegrationSync, MiterIntegrationForCompany } from "dashboard/miter";
import { DateTime } from "luxon";
import { Navigate } from "react-router-dom";
import { IntegrationConnectionStatus } from "./IntegrationConnectionStatus";

export type IntegrationSummaryProps = {
  integration: MiterIntegrationForCompany;
  lastSync?: IntegrationSync;
  updateIntegrationConnection: (
    update: Partial<IntegrationConnection> | Record<string, $TSFixMe>
  ) => Promise<void>;
  connectionStatus?: $TSFixMe;
};

export const IntegrationSummary: React.FC<IntegrationSummaryProps> = (props) => {
  const { integration, lastSync, connectionStatus } = props;
  const connectedByString = useMemo(() => {
    let connectedByString = "Unknown";
    if (integration?.connection?.user) {
      const { first_name, last_name, email, miter_admin } = integration?.connection?.user;
      const emailDomain = email?.split("@")?.[1];
      if (miter_admin || emailDomain === "miter.com") {
        connectedByString = "Miter";
      } else if (first_name && last_name) {
        connectedByString = first_name + " " + last_name;
      } else if (email) {
        connectedByString = email;
      }
    }
    return connectedByString;
  }, [integration, connectionStatus]);

  const lastSyncString = useMemo(() => {
    let lastSyncString = "N/A";
    if (lastSync) {
      lastSyncString = DateTime.fromSeconds(lastSync.created_at).toFormat("DD");
    }
    return lastSyncString;
  }, [lastSync]);

  if (!integration.connection) return <Navigate to="/integrations" />;

  const intacctCompanyId = integration.connection.metadata?.sage_intacct?.loginCreds?.companyId;
  const jonasCompanyName = integration.connection.metadata?.jonas?.company?.CompanyName;

  return (
    <div>
      <Formblock label="Status" type="text" locked={true}>
        {connectionStatus ? (
          <IntegrationConnectionStatus connectionStatus={connectionStatus} integration={integration} />
        ) : (
          <Loader small={true} />
        )}
      </Formblock>
      {intacctCompanyId && (
        <Formblock label="Intacct company ID" type="text" locked={true} defaultValue={intacctCompanyId} />
      )}
      {jonasCompanyName && (
        <Formblock label="Jonas company name" type="text" locked={true} defaultValue={jonasCompanyName} />
      )}
      <Formblock label="Connected by" type="text" locked={true} defaultValue={connectedByString} />
      {!integration.is_reports_only && (
        <>
          {lastSyncString ? (
            <Formblock label="Last synced" type="text" locked={true} defaultValue={lastSyncString} />
          ) : (
            <>
              <Loader small={true} />
            </>
          )}
        </>
      )}
    </div>
  );
};
