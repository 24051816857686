import { ArrowsClockwise, Check, X } from "phosphor-react";
import { Notifier, TableV2 } from "ui";
import { TableActionLink, TableTogglerConfig } from "ui/table-v2/Table";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CardTransactionsModal } from "./modals/CardTransactionsModal";
import { AggregatedExpense, BulkUpdateResult, MiterAPI } from "../../miter";
import { ForageRequest, ForageResponse } from "backend/utils/forage/forage-types";
import { useQuery } from "miter-utils";
import { UpdateExpenseParams } from "backend/services/expenses/expense-service";
import { Params, useNavigate, useParams } from "react-router-dom";
import { BulkEditedExpense, useCardTransactionsTableColDefs } from "./expenseUtils";
import pluralize from "pluralize";
import { BulkUpdateError, BulkUpdateParam, BulkUpdateParams, MiterFilterArray } from "backend/utils";
import {
  useActiveCompanyId,
  useActiveTeamMember,
  useRefetchActionableItems,
} from "dashboard/hooks/atom-hooks";
import { CardTransactionImporter } from "dashboard/components/expenses/CardTransactionImporter";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useExpenseAbilities } from "dashboard/hooks/abilities-hooks/useExpenseAbilities";
import FailuresModal from "dashboard/components/shared/FailuresModal";
import { useKickBackItemsAction } from "dashboard/components/approvals/KickBackItemsAction";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { ApprovalStatusEnum } from "backend/services/approvals-service";
import { InboxMode } from "../approvals/inboxUtils";

export type ExpensesTableRow = AggregatedExpense;

export type DirectSyncableIntegrationsEnum = "sage_intacct" | "qbo" | "acumatica";

type Props = {
  hideToggle?: boolean;
  shouldRedirectURLWhenOpening: boolean;
  fetchActionableExpenses?: (params: ForageRequest) => Promise<ForageResponse<AggregatedExpense>>;
  inboxMode?: InboxMode;
};

export const CardTransactionsTable: FC<Props> = ({
  hideToggle,
  shouldRedirectURLWhenOpening,
  fetchActionableExpenses,
  inboxMode,
}) => {
  const columns = useCardTransactionsTableColDefs();
  const approvalStatus = useQuery().get("approval_status");
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const activeTeamMember = useActiveTeamMember();
  const refetchActionableItems = useRefetchActionableItems();
  const miterAbilities = useMiterAbilities();
  const expenseAbilities = useExpenseAbilities({ inboxMode });

  const integrationKeyToIntegrationMap = useHasIntegration();

  const sageIntacctConnectionId = integrationKeyToIntegrationMap.get("sage_intacct")?._id;
  const qboConnectionId = integrationKeyToIntegrationMap.get("qbo")?._id;
  const acumaticaConnectionId = integrationKeyToIntegrationMap.get("acumatica")?._id;

  const [selectedExpenseId, setSelectedExpenseId] = useState<string>();
  const [selectedRows, setSelectedRows] = useState<ExpensesTableRow[]>([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [updateFailures, setUpdateFailures] = useState<BulkUpdateError[]>([]);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<ApprovalStatusEnum>();
  const [isSyncing, setIsSyncing] = useState<DirectSyncableIntegrationsEnum>();
  const [isArchiving, setIsArchiving] = useState(false);

  /*********************************************************
   *  replace
   **********************************************************/
  const { id: idFromUrl } = useParams<Params>();
  useEffect(() => {
    setSelectedExpenseId(idFromUrl);
  }, [idFromUrl]);

  // const approvalStatus = new URLSearchParams(search).get("approval_status");
  const isArchivedView = approvalStatus === "archived";

  /** Builds the table action link for kicking back items */
  const kickBackItemAction = useKickBackItemsAction(selectedRows, "expense", () => {
    setSelectedRows([]);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  });

  const getData = useCallback(
    async (query: ForageRequest) => {
      let filter = (query.filter || []).concat([
        { field: "company_id", value: activeCompanyId },
        { field: "archived", value: false },
      ]);

      const sort = query.sort || [];
      const select = query.select || [];

      // If we can't read others' reimbursements, then we should only show the current user's reimbursements
      const abilitiesFilter = expenseAbilities.filter("read");
      if (abilitiesFilter) filter.push(abilitiesFilter);

      query.select?.push({ field: "team_member", show: true });
      query.select?.push({ field: "team_member_id", show: true });
      query.select?.push({ field: "job_hierarchy_ids", show: true });

      const forageFunction = fetchActionableExpenses || MiterAPI.expenses.forage;

      // override togger if archived
      if (isArchivedView) {
        // remove archived filter
        filter = filter.filter((f) => f.field !== "archived");

        // remove approval status filter
        filter = filter.filter((f) => f.field !== "approval_status");

        // add new archived filter
        filter.push({ field: "archived", comparisonType: "include_archived" as const });
        filter.push({ field: "archived", value: true });

        // also filter out split expenses
        filter.push({ field: "parent_id", comparisonType: "not.exists", value: true });
      }

      const res = await forageFunction({ ...query, filter, sort, select });

      // Add readonly field to each expense if the user doesn't have permission to update it
      res.data = (res.data ?? []).map((expense) => {
        const readonly = expenseAbilities.cannot("update", expense);

        return { ...expense, readonly };
      });

      return res;
    },
    [
      approvalStatus,
      fetchActionableExpenses,
      expenseAbilities.filter,
      activeTeamMember,
      expenseAbilities.cannot,
    ]
  );

  const refreshExpenses = () => {
    setRefreshCount((refreshCount) => refreshCount + 1);
    refetchActionableItems();
  };

  const bulkUpdateExpenses = async (
    expenseIds: string[],
    expenseUpdateParams: UpdateExpenseParams,
    shouldRefreshTable = true
  ): Promise<void> => {
    if (expenseAbilities.cannot("update", selectedRows)) {
      Notifier.error("You do not have permission to update these card transactions.");
      return;
    }

    try {
      const updateParamsList: BulkUpdateParams<UpdateExpenseParams> = expenseIds.map((id) => {
        return { _id: id, params: expenseUpdateParams };
      });

      const updateExpensesResponse = await MiterAPI.expenses.update(updateParamsList);
      if (updateExpensesResponse.error) throw Error(updateExpensesResponse.error);

      if (updateExpensesResponse.errors?.length > 0) {
        setUpdateFailures(updateExpensesResponse.errors);
      }

      const updatedRows = updateExpensesResponse.successes;

      // if any rows are changed
      if (updatedRows?.length > 0) {
        // if any selected rows are in the updated list, remove
        const newSelectedRows = selectedRows.filter(
          (row) => !updatedRows.some((update) => update?._id.toString() === row._id)
        );

        if (newSelectedRows.length !== selectedRows.length) {
          setSelectedRows(newSelectedRows);
        }

        if (shouldRefreshTable) refreshExpenses();
        Notifier.success(`Updated ${pluralize("card transaction", updatedRows.length)}.`);
      }
    } catch (e: $TSFixMe) {
      const pluralExpense = pluralize("card transaction", expenseIds.length);
      console.error(`Error updating ${pluralExpense} from dashboard for company ${activeCompanyId}`, e);
      Notifier.error(`There was a problem updating the ${pluralExpense}. We're looking into it.`);
    }
  };

  const renderUpdateFailuresModal = () => {
    if (!updateFailures.length) return;

    return (
      <FailuresModal
        headerText={"Error updating card transactions"}
        onClose={() => setUpdateFailures([])}
        failures={updateFailures.map((failure) => {
          return {
            label: failure._id,
            message: failure.message,
          };
        })}
      />
    );
  };

  const bulkUpdateExpenseStatuses = async (
    expenseIds: string[],
    newStatus: ApprovalStatusEnum,
    shouldRefreshTable = true
  ): Promise<void> => {
    if (expenseAbilities.cannot("approve", selectedRows)) {
      Notifier.error("You do not have permission to update these card transactions.");
      return;
    }

    try {
      setIsUpdatingStatus(newStatus);
      const updateParamsList: BulkUpdateParams<UpdateExpenseParams> = expenseIds.map((id) => {
        return {
          _id: id,
          params: {
            approval_status: newStatus,
          },
        };
      });

      const updateExpensesResponse = await MiterAPI.expenses.update_approval_status(updateParamsList);
      if (updateExpensesResponse.error) throw Error(updateExpensesResponse.error);

      if (updateExpensesResponse.errors?.length > 0) {
        for (const failure of updateExpensesResponse.errors) {
          Notifier.error(`Failed to update status of card transaction. Message: ${failure.message}`);
        }
      }

      const updatedRows = updateExpensesResponse.successes;

      // if any rows are changed
      if (updatedRows?.length > 0) {
        // if any selected rows are in the updated list, remove
        const newSelectedRows = selectedRows.filter(
          (row) => !updatedRows.some((update) => update?._id.toString() === row._id)
        );
        setSelectedRows(newSelectedRows);
        if (shouldRefreshTable) refreshExpenses();
        Notifier.success(`Updated ${pluralize("card transaction", updatedRows.length)}.`);
      }
    } catch (e: $TSFixMe) {
      const pluralExpense = pluralize("card transaction", expenseIds.length);
      console.error(`Error updating ${pluralExpense} from dashboard for company ${activeCompanyId}`, e);
      Notifier.error(`There was a problem updating the ${pluralExpense}. We're looking into it.`);
    }
    setIsUpdatingStatus(undefined);
  };

  // helper for TableV2 built in bulk editing only
  const bulkEditExpenses = async (bulkEditedExpenses: BulkEditedExpense[]): Promise<BulkUpdateResult> => {
    // if selectedRows is empty, that means this handler is called from inline editing (one row at a time)
    const attemptedEditingRows = selectedRows.length ? selectedRows : bulkEditedExpenses;

    if (expenseAbilities.cannot("update", attemptedEditingRows)) {
      Notifier.error("You do not have permission to update these card transactions.");
      return { successes: [], errors: [] };
    }

    try {
      const updateParamsList: BulkUpdateParams<UpdateExpenseParams> = bulkEditedExpenses.map(
        (bulkEditedExpense) => {
          /**
           * team_member, department, job, activity, ledger_account usually refer to the actual objects (returned from AggregatedExpense),
           * but the bulk editing flow will override these fields with id strings. Change these back to the real _id fields to override the base Expense object.
           */
          const { _id, team_member, department, job, activity, ledger_account, cost_type } =
            bulkEditedExpense;
          const params: BulkUpdateParam<UpdateExpenseParams> = {
            _id,
            params: {
              team_member_id: typeof team_member === "string" ? team_member : team_member?._id,
              department_id: typeof department === "string" ? department : department?._id ?? null,
              job_id: typeof job === "string" ? job : job?._id ?? null,
              activity_id: typeof activity === "string" ? activity : activity?._id ?? null,
              cost_type_id: typeof cost_type === "string" ? cost_type : cost_type?._id ?? null,
              expense_account_id:
                typeof ledger_account === "string" ? ledger_account : ledger_account?._id ?? null,
            },
          };

          return params;
        }
      );
      const res = await MiterAPI.expenses.update(updateParamsList);
      if (res.error) throw Error(res.error);

      if (res.errors.length > 0) {
        const successes = res.successes;
        const errors = res.errors;

        return { successes, errors };
      }

      return res;
    } catch (e: $TSFixMe) {
      const pluralExpense = pluralize("card transaction", bulkEditedExpenses.length);
      console.error(`Error updating ${pluralExpense} from dashboard for company ${activeCompanyId}`, e);
      Notifier.error(`There was a problem updating the ${pluralExpense}. We're looking into it.`);
      return { successes: [], errors: [] };
    }
  };

  const bulkUpdateApprovalStatus =
    (newApprovalStatus: ApprovalStatusEnum) => (selectedRows: ExpensesTableRow[]) =>
      bulkUpdateExpenseStatuses(
        selectedRows.map((row) => row._id.toString()),
        newApprovalStatus
      );

  const bulkArchive = async (selectedRows: ExpensesTableRow[]) => {
    setIsArchiving(true);

    await bulkUpdateExpenses(
      selectedRows.map((row) => row._id.toString()),
      { archived: true }
    );
    setIsArchiving(false);
  };

  const bulkUnarchive = async (selectedRows: ExpensesTableRow[]) => {
    setIsArchiving(true);

    await bulkUpdateExpenses(
      selectedRows.map((row) => row._id.toString()),
      { archived: false }
    );
    setIsArchiving(false);
  };

  const bulkSyncToIntegration =
    (integrationId: string, integrationKey: DirectSyncableIntegrationsEnum) =>
    async (selectedRows: ExpensesTableRow[]) => {
      setIsSyncing(integrationKey);
      // "expenses" for SIC and Acumatica, GL for QBO
      const entity = integrationKey === "qbo" ? "ledger_entries" : "expenses";

      let idsToSync = selectedRows.map((e) => e._id);

      // get corresponding ledger entries of the card transactions first
      if (entity === "ledger_entries") {
        const filter: MiterFilterArray = [
          {
            field: "source_objects.expense_id",
            value: selectedRows.map((e) => e._id),
            comparisonType: "in",
            type: "string",
          },
        ];
        const correspondingLedgerEntries = await MiterAPI.ledger_entries.retrieve({
          queryObject: { filter },
        });
        if (correspondingLedgerEntries.error) {
          Notifier.error(
            "There was an error syncing card transactions. Our team is aware and looking into this."
          );
          setIsSyncing(undefined);
          return;
        }

        idsToSync = correspondingLedgerEntries.map((e) => e._id);
      }

      const topLevelSyncResult = await MiterAPI.integrations.run_manual_sync({
        id: integrationId,
        operation: {
          entity,
          direction: "push",
          idsToSync,
        },
        trigger: "manual",
      });

      // entire API call failed, no sync result
      if (topLevelSyncResult.error || !topLevelSyncResult[0]) {
        Notifier.error(`There was an error syncing card transactions. Error: ${topLevelSyncResult.error}`);
        setIsSyncing(undefined);
        return;
      }

      const result = topLevelSyncResult[0];

      const syncResults = await MiterAPI.integrations.get_sync_results(integrationId, result._id);

      // if empty sync, try to get the error message from the sync result
      if (!syncResults.length) {
        Notifier.error(
          `There was an error syncing card transactions.${
            result.error_message ? ` Error: ${result.error_message}` : ""
          }`
        );
        setIsSyncing(undefined);
        return;
      }

      const failures = syncResults.filter((r) => r.status === "error");
      setUpdateFailures(failures.map((r) => ({ _id: r.label || r._id, message: r.message || "" })));

      if (failures.length === 0) {
        Notifier.success("Synced.");
        setSelectedRows([]);
      }

      setIsSyncing(undefined);
      refreshExpenses();
    };

  const allUnapproved = useMemo(
    () => selectedRows.every((row) => row.approval_status === "unapproved"),
    [selectedRows]
  );

  const staticActions: TableActionLink[] = [
    {
      label: "Refresh",
      className: "button-1 no-margin",
      action: refreshExpenses,
      important: true,
      icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => !inboxMode,
    },
    {
      key: "import",
      component: <CardTransactionImporter onFinish={() => refreshExpenses()} />,
      shouldShow: () =>
        !inboxMode &&
        (miterAbilities.can("card_transactions:personal:create") ||
          miterAbilities.can("card_transactions:others:create")),
    },
    {
      label: "Approve",
      className: "button-2 table-button",
      icon: <Check weight="bold" style={{ marginRight: 3 }} />,
      action: () => {
        Notifier.warning("Please select the expenses you want to approve.");
      },
      shouldShow: () => inboxMode === "approval",
      important: true,
    },
    {
      label: "Deny",
      className: "button-1 table-button",
      icon: <X weight="bold" style={{ marginRight: 3 }} />,
      action: () => {
        Notifier.warning("Please select the expenses you want to deny.");
      },
      shouldShow: () => inboxMode === "approval",
      important: true,
    },
  ];

  // programmatically add sync to integration actions based on what the user has connected
  const directSyncToIntegrationActions: TableActionLink[] = useMemo(() => {
    const connectedIntegrationsList: TableActionLink[] = [];

    if (sageIntacctConnectionId) {
      connectedIntegrationsList.push({
        label: "Sync to Sage Intacct",
        className: "button-1 table-button",
        icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
        action: bulkSyncToIntegration(sageIntacctConnectionId, "sage_intacct"),
        loading: isSyncing && isSyncing === "sage_intacct",
        disabled: isSyncing && isSyncing !== "sage_intacct",
        shouldShow: (selectedRows: ExpensesTableRow[]) =>
          !inboxMode &&
          expenseAbilities.can("approve", selectedRows) && // TODO: new permission for syncing? ask AY
          selectedRows.every((row) => row.approval_status === "approved"),
      });
    }

    if (qboConnectionId) {
      connectedIntegrationsList.push({
        label: "Sync to Quickbooks Online",
        className: "button-1 table-button",
        icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
        action: bulkSyncToIntegration(qboConnectionId, "qbo"),
        loading: isSyncing && isSyncing === "qbo",
        disabled: isSyncing && isSyncing !== "qbo",
        shouldShow: (selectedRows: ExpensesTableRow[]) =>
          !inboxMode &&
          expenseAbilities.can("approve", selectedRows) &&
          selectedRows.every((row) => row.approval_status === "approved"),
      });
    }

    if (acumaticaConnectionId) {
      connectedIntegrationsList.push({
        label: "Sync to Acumatica",
        className: "button-1 table-button",
        icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
        action: bulkSyncToIntegration(acumaticaConnectionId, "acumatica"),
        loading: isSyncing && isSyncing === "acumatica",
        disabled: isSyncing && isSyncing !== "acumatica",
        shouldShow: (selectedRows: ExpensesTableRow[]) =>
          !inboxMode &&
          expenseAbilities.can("approve", selectedRows) &&
          selectedRows.every((row) => row.approval_status === "approved"),
      });
    }

    return connectedIntegrationsList;
  }, [isSyncing, integrationKeyToIntegrationMap]);

  const archivedDynamicActions: TableActionLink[] = [
    {
      label: "Unarchive",
      className: "button-1 table-button",
      action: bulkUnarchive,
      loading: isArchiving,
      shouldShow: (selectedRows: ExpensesTableRow[]) =>
        !inboxMode && expenseAbilities.can("delete", selectedRows),
      disabled: isArchiving,
      icon: <X weight="bold" style={{ marginRight: 3 }} />,
      important: true,
    },
  ];
  const dynamicActions: TableActionLink[] = [
    {
      label: "Archive",
      className: "button-1 table-button",
      action: bulkArchive,
      loading: isArchiving,
      shouldShow: (selectedRows: ExpensesTableRow[]) =>
        !inboxMode &&
        expenseAbilities.can("delete", selectedRows) &&
        selectedRows.every((row) => row.approval_status !== "approved"),
    },
    ...directSyncToIntegrationActions,
    ...(inboxMode === "approval" || (expenseAbilities.can("approve", selectedRows) && allUnapproved)
      ? kickBackItemAction
      : []),
    {
      label: "Approve",
      className: "button-2 table-button",
      icon: <Check weight="bold" style={{ marginRight: 3 }} />,
      action: bulkUpdateApprovalStatus("approved"),
      loading: isUpdatingStatus && isUpdatingStatus === "approved",
      disabled: isUpdatingStatus && isUpdatingStatus !== "approved",
      shouldShow: (selectedRows: ExpensesTableRow[]) =>
        inboxMode === "approval" || (expenseAbilities.can("approve", selectedRows) && allUnapproved),
    },
    {
      label: "Deny",
      className: "button-3 table-button",
      icon: <X weight="bold" style={{ marginRight: 3 }} />,
      action: bulkUpdateApprovalStatus("denied"),
      loading: isUpdatingStatus && isUpdatingStatus === "denied",
      disabled: isUpdatingStatus && isUpdatingStatus !== "denied",
      shouldShow: (selectedRows: ExpensesTableRow[]) =>
        inboxMode === "approval" || (expenseAbilities.can("approve", selectedRows) && allUnapproved),
    },
    {
      label: "Unapprove",
      className: "button-1 table-button",
      icon: <X weight="bold" style={{ marginRight: 3 }} />,
      action: bulkUpdateApprovalStatus("unapproved"),
      loading: isUpdatingStatus && isUpdatingStatus === "unapproved",
      disabled: isUpdatingStatus && isUpdatingStatus !== "unapproved",
      shouldShow: (selectedRows: ExpensesTableRow[]) =>
        !inboxMode && // don't show in inbox mode
        expenseAbilities.can("approve", selectedRows) &&
        !allUnapproved,
    },
  ];

  const togglerConfig: TableTogglerConfig<ExpensesTableRow> = {
    config: [
      { path: "unapproved", label: "Unapproved" },
      { path: "approved", label: "Approved" },
      { path: "denied", label: "Denied" },
      { path: "all", label: "All" },
      { path: "archived", label: "Archived" },
    ],
    field: "approval_status",
    secondary: true,
  };

  const hideSidebarModal = useCallback(
    (isExpenseUpdated: boolean) => {
      setSelectedExpenseId(undefined);
      if (isExpenseUpdated) refreshExpenses();

      if (shouldRedirectURLWhenOpening) {
        navigate(`/expenses/card-transactions/${approvalStatus ? `?approval_status=${approvalStatus}` : ""}`);
      }
    },
    [approvalStatus]
  );

  return (
    <div>
      <TableV2
        id="card-transactions-table"
        resource="card transactions"
        columns={columns}
        onSelect={setSelectedRows}
        staticActions={staticActions}
        dynamicActions={isArchivedView ? archivedDynamicActions : dynamicActions}
        onClick={(expense) => {
          if (isArchivedView) return;

          setSelectedExpenseId(expense._id);
          let sidebarDetailsUrl = `/expenses/card-transactions/${expense._id}`;

          // so opening modal doesn't change table toggle
          if (approvalStatus) {
            sidebarDetailsUrl += `?approval_status=${approvalStatus}`;
          }
          if (shouldRedirectURLWhenOpening) {
            navigate(sidebarDetailsUrl);
          }
        }}
        toggler={!hideToggle ? togglerConfig : undefined}
        defaultSelectedRows={selectedRows}
        ssr={true}
        getData={getData}
        refreshCount={refreshCount}
        wrapperClassName="base-ssr-table"
        containerClassName={"expenses-table-container"}
        showReportViews={true}
        editable={
          !inboxMode &&
          !isArchivedView &&
          (miterAbilities.can("card_transactions:personal:update") ||
            miterAbilities.can("card_transactions:others:update"))
        }
        onSave={bulkEditExpenses}
      />
      {selectedExpenseId && (
        <CardTransactionsModal
          expenseId={selectedExpenseId}
          onHide={hideSidebarModal}
          inboxMode={inboxMode}
        />
      )}
      {renderUpdateFailuresModal()}
    </div>
  );
};
