import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import React, { useEffect, useState } from "react";
import { Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { ENDemographicAuditRow } from "backend/services/employee-navigator-service";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type Props = {};

type ENDemographicAuditRowTableEntry = ENDemographicAuditRow & {
  _id: string;
};

export const ENDemographicAuditReport: React.FC<Props> = () => {
  const { setReverifyUser, setOnReverifyUser } = React.useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<ENDemographicAuditRowTableEntry[] | undefined>();

  const getData = async () => {
    setLoading(true);
    try {
      if (!activeCompanyId) throw new Error("No company found");

      const company = activeCompanyId;
      const response = await MiterAPI.integrations.employee_navigator.get_demographic_audit_report(company);
      if (response.error) throw new Error(response.error);
      setTableData(response.map((r) => ({ ...r, _id: r["Payroll ID"] })));
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error retrieving employee data.`);
    }
    setLoading(false);
  };

  useEffect(() => {
    setReverifyUser(true);
    setOnReverifyUser(() => getData);
  }, []);

  return (
    <div>
      <TableV2
        id="en-demographic-audit-report"
        resource="team members"
        data={tableData}
        columns={columns}
        isLoading={loading}
        gridWrapperStyle={{ height: 500 }}
      />
    </div>
  );
};

export const columns: ColumnConfig<ENDemographicAuditRowTableEntry>[] = [
  { field: "Payroll ID", headerName: "Payroll ID", valueFormatter: (params) => params.value },
  { field: "Employee SSN", headerName: "Employee SSN", valueFormatter: (params) => params.value },
  { field: "First Name", headerName: "First Name", valueFormatter: (params) => params.value },
  { field: "Middle Name", headerName: "Middle Name", valueFormatter: (params) => params.value },
  { field: "Last Name", headerName: "Last Name", valueFormatter: (params) => params.value },
  { field: "Date of Birth", headerName: "Date of Birth", valueFormatter: (params) => params.value },
  { field: "Hire Date", headerName: "Hire Date", valueFormatter: (params) => params.value },
  { field: "Termination Date", headerName: "Termination Date", valueFormatter: (params) => params.value },
  {
    field: "Salary Effective Date",
    headerName: "Salary Effective Date",
    valueFormatter: (params) => params.value,
  },
  { field: "Compensation Basis", headerName: "Compensation Basis", valueFormatter: (params) => params.value },
  { field: "Annual Base Salary", headerName: "Annual Base Salary", valueFormatter: (params) => params.value },
  { field: "Hourly Rate", headerName: "Hourly Rate", valueFormatter: (params) => params.value },
  { field: "Address 1", headerName: "Address 1", valueFormatter: (params) => params.value },
  { field: "Address 2", headerName: "Address 2", valueFormatter: (params) => params.value },
  { field: "City", headerName: "City", valueFormatter: (params) => params.value },
  { field: "State", headerName: "State", valueFormatter: (params) => params.value },
  { field: "ZIP", headerName: "ZIP", valueFormatter: (params) => params.value },
  { field: "Job Title", headerName: "Job Title", valueFormatter: (params) => params.value },
  { field: "Phone", headerName: "Phone", valueFormatter: (params) => params.value },
  { field: "Email", headerName: "Email", valueFormatter: (params) => params.value },
  { field: "Sex", headerName: "Sex", valueFormatter: (params) => params.value },
];
