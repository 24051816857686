import React, { useMemo, useState } from "react";
import { Badge, Button, Toggler } from "ui";
import { Formblock } from "ui";
import PaymentContext from "./paymentContext";

import EditIcon from "dashboard/assets/edit.png";
import { formatDate, getSupplementalTaxCalculationMethod } from "dashboard/utils/utils";
import PaymentSettingsModal from "./PaymentSettingsModal";

import person from "dashboard/assets/user.png";
import PaymentTotals from "./Totals";
import { getPaymentPayMethod, Notifier } from "dashboard/utils";
import { MiterAPI } from "dashboard/miter";
import { AutoCalculateBadge } from "../AutoCalculateBadge";
import { capitalize } from "lodash";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { ReviewCheckPayments } from "../ReviewCheckPayments";
import { CheckItem } from "backend/utils/check/check-types";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

const PaymentSummary: React.FC<{ status: CheckItem["status"] }> = (props) => {
  const { status } = props;
  const { payment, editing, tm, payroll } = React.useContext(PaymentContext);

  const company = useActiveCompany();

  const tmHasBankAccount = tm.check_tm?.bank_accounts?.length ? true : false;
  const checkItmCp = payment.check_item || payment.check_cp;

  const paymentMethod = getPaymentPayMethod(payment);
  const supplementalTaxMethod = getSupplementalTaxCalculationMethod(payment, company);

  const [isChangingPaymentSettings, setIsChangingPaymentSettings] = useState(false);
  const [toggleState, setToggleState] = useState("totals");
  const [loading, setLoading] = useState(false);
  const [isEditingPaperCheckNumber, setIsEditingPaperCheckNumber] = useState(false);
  const [paperCheckNumberInput, setPapercheckNumberInput] = useState<string | undefined>(
    checkItmCp?.paper_check_number || undefined
  );

  const checkPayroll = payroll!.check_payroll;

  const handleEmployeeClick = () => {
    window.open(window.location.origin + "/team-members/" + payment!.team_member._id, "_blank");
  };

  const togglerConfig = useMemo(() => {
    const config: TogglerConfigItem[] = [
      { label: "Totals", path: "totals" },
      { label: "Details", path: "details" },
    ];

    // Post-refund, the payment_method is set to "manual" by Check so need to check for that
    const hasRefund = !!payment?.refunds?.length;
    const showDirectDepositsToggle =
      status !== "draft" &&
      !!checkItmCp &&
      !checkItmCp.void_of &&
      (paymentMethod === "direct_deposit" || hasRefund);

    if (showDirectDepositsToggle) {
      const paymentsConfigItem: TogglerConfigItem = { label: "Direct deposits", path: "direct_deposits" };
      if (["failed", "partially_paid"].includes(status)) {
        paymentsConfigItem.alert = true;
      }
      config.push(paymentsConfigItem);
    }
    return config;
  }, [status, paymentMethod, payroll, payment]);

  const savePaperCheckNum = async () => {
    if (!payroll || !checkItmCp) return;
    setLoading(true);
    try {
      const finalPaperCheckNumber = paperCheckNumberInput || null;
      if (finalPaperCheckNumber && finalPaperCheckNumber.length > 10) {
        return Notifier.error("Check number must be 10 characters or less.");
      }
      const response = await MiterAPI.payrolls.add_paper_check_num(payroll._id, {
        check_num: finalPaperCheckNumber,
        payroll_item: payment.payment_type === "employee" ? checkItmCp?.id : undefined,
        contractor_payment: payment.payment_type === "contractor" ? checkItmCp?.id : undefined,
      });
      if (response.error) throw new Error(response.error);
      checkItmCp.paper_check_number = finalPaperCheckNumber;
      setPapercheckNumberInput(finalPaperCheckNumber || undefined);
      Notifier.success("Payment updated successfully.");
    } catch (e) {
      console.log("Error updating paper check number", e);
      setPapercheckNumberInput(checkItmCp.paper_check_number || undefined);
      Notifier.error("There was an error updating the payment.");
    } finally {
      setLoading(false);
    }
    setIsEditingPaperCheckNumber(false);
  };

  const renderPaymentMethodChanger = () => {
    return (
      <Formblock
        type="text"
        label="Payment method"
        defaultValue={paymentMethod === "manual" ? "Paper check" : "Direct deposit"}
        locked={true}
      >
        <div className="flex">
          <div>{paymentMethod === "manual" ? "Paper check" : "Direct deposit"}</div>
          {checkPayroll.status === "draft" && editing && tmHasBankAccount && (
            <img
              className="formblock-edit-icon"
              src={EditIcon}
              onClick={() => setIsChangingPaymentSettings(true)}
            />
          )}
        </div>
      </Formblock>
    );
  };

  const renderTaxMethodChanger = () => {
    return (
      payment.payment_type === "employee" && (
        <Formblock
          type="text"
          label="Supp. tax method"
          labelInfo="Default method used to calculate taxes on supplemental earnings. Either flat 22% or earnings are aggregated and taxed normally."
          defaultValue={supplementalTaxMethod}
          locked={true}
        >
          <div className="flex">
            <div>{capitalize(supplementalTaxMethod)}</div>
            {checkPayroll.status === "draft" && editing && (
              <img
                className="formblock-edit-icon"
                src={EditIcon}
                onClick={() => setIsChangingPaymentSettings(true)}
              />
            )}
          </div>
        </Formblock>
      )
    );
  };

  const renderAutoCalcChanger = () => {
    return (
      <Formblock
        type="text"
        label="Auto-Calculate"
        labelInfo="Miter can calculate earnings automatically based on an employee's timesheets or salary."
        locked={true}
      >
        {payment && (
          <div className="flex">
            <AutoCalculateBadge payment={payment} useStateAsText={true} />
            {checkPayroll.status === "draft" && editing && (
              <img
                className="formblock-edit-icon"
                src={EditIcon}
                onClick={() => setIsChangingPaymentSettings(true)}
              />
            )}
          </div>
        )}
      </Formblock>
    );
  };

  const renderPaperCheckNumChanger = () => {
    return (
      <>
        {paymentMethod === "manual" && status !== "draft" && checkItmCp && (
          <Formblock
            type="text"
            label="Paper check number"
            defaultValue={checkItmCp?.paper_check_number}
            locked={true}
          >
            {isEditingPaperCheckNumber ? (
              <div className="flex" style={{ maxWidth: 300 }}>
                <input
                  style={{ marginBottom: 0, paddingRight: 50 }}
                  className={"form2-text"}
                  value={paperCheckNumberInput}
                  onChange={(e) => setPapercheckNumberInput(e.target.value)}
                  name="paper_check_number"
                />

                <Button loading={loading} text="Save" onClick={savePaperCheckNum} className="button-2" />
              </div>
            ) : (
              <div className="flex">
                <div>{checkItmCp?.paper_check_number || "-"}</div>
                <img
                  className="formblock-edit-icon"
                  src={EditIcon}
                  onClick={() => setIsEditingPaperCheckNumber(true)}
                />
              </div>
            )}
          </Formblock>
        )}
        {isEditingPaperCheckNumber && <div style={{ height: 10 }}></div>}
      </>
    );
  };

  return (
    <div>
      <div>
        <div className="payment-active-view-header">Summary</div>
        {isChangingPaymentSettings && (
          <PaymentSettingsModal
            selectedPayments={[payment]}
            hide={() => setIsChangingPaymentSettings(false)}
          />
        )}
        <Toggler
          className="modal-with-tabs"
          config={togglerConfig}
          active={toggleState}
          toggle={setToggleState}
        />
        {toggleState === "totals" && <PaymentTotals />}
        {toggleState === "details" && (
          <div>
            <div className="vertical-spacer"></div>
            <Formblock type="text" label="Team member" locked={true}>
              <div className="tm-link" onClick={handleEmployeeClick}>
                <img className="tm-link-img" src={person} />
                <span>{payment.team_member.full_name}</span>
              </div>
            </Formblock>
            <Formblock
              type="text"
              label="Employment type"
              defaultValue={capitalize(payment.team_member.employment_type)}
              locked={true}
            />
            <Formblock type="text" label="Payment status" defaultValue={checkPayroll.payday} locked={true}>
              <Badge className="no-margin" text={status.replace("_", " ")} />
            </Formblock>
            {renderPaymentMethodChanger()}
            {renderTaxMethodChanger()}
            <Formblock
              type="text"
              label="Pay period"
              defaultValue={formatDate(checkPayroll.period_start, checkPayroll.period_end, true)}
              locked={true}
            />
            <Formblock
              label="Payday"
              type="text"
              defaultValue={formatDate(checkPayroll.payday, undefined, true)}
              locked={true}
            />
            {renderAutoCalcChanger()}
            {paymentMethod === "direct_deposit" && status === "paid" && (
              <Formblock type="text" label="Unique ID" defaultValue={payment.short_id} locked={true} />
            )}
            {renderPaperCheckNumChanger()}
          </div>
        )}
        {toggleState === "direct_deposits" && checkItmCp && <ReviewCheckPayments entity={checkItmCp} />}
      </div>
    </div>
  );
};

export default PaymentSummary;
