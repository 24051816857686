import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TableV2 } from "ui";

import {
  AggregatedTeamMember,
  TimeOffEnrolleesTableEntry,
  TimeOffPolicy,
  TimeOffPolicyLevelConfig,
} from "dashboard/miter";
import { useActiveTeam, useLookupActiveTeam } from "dashboard/hooks/atom-hooks";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { ClockCounterClockwise, UserMinus, UserPlus } from "phosphor-react";
import BulkTeamMemberSelect from "../team-members/BulkTeamMemberSelect";
import { keyBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TimeOffPolicyEnrolleeImporter } from "./TimeOffPolicyEnrolleeImporter";
import { ImportHistory } from "../importer/ImportHistory";
import { RefetchInput } from "dashboard/atoms";
import { assignDefaultLevel, levelComputedStartDates } from "dashboard/utils/policies/time-off-policy-utils";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

type Props = {
  timeOffPolicy: TimeOffPolicy | undefined;
  reloadEnrollees: (ids?: RefetchInput) => Promise<void>;
  onEmployeesChanged: (employees: TimeOffEnrolleesTableEntry[]) => void;
};

export type EnrollmentFailureTeamMember = AggregatedTeamMember & { failure_reason: string };

const TimeOffPolicyEnrolleesTable: React.FC<Props> = ({
  timeOffPolicy,
  reloadEnrollees,
  onEmployeesChanged,
}) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const teamMembers = useActiveTeam();
  const navigate = useNavigate();
  const { can } = useMiterAbilities();
  const teamAbilities = useTeamAbilities();
  const teamMemberLookup = useLookupActiveTeam();

  const [employees, setEmployees] = useState<TimeOffEnrolleesTableEntry[]>([]);
  const [selectedEnrollees, setSelectedEnrollees] = useState<TimeOffEnrolleesTableEntry[]>([]);
  const [showEmployeeSelector, setShowEmployeeSelector] = useState(false);

  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);

  const accessibleTeamMembers = useMemo(
    () => teamMembers.filter((tm) => teamAbilities.can("read", tm)),
    [teamMembers, teamAbilities]
  );

  const enrollees = useMemo(() => {
    return employees.filter((employee) => employee.enrollment_status === "enroll");
  }, [employees]);

  const levelLookup = useMemo(() => {
    const lookup = Object.fromEntries(
      timeOffPolicy?.levels?.map((level) => {
        return [level._id, level];
      }) || []
    );

    return (id: string | null | undefined) => (id ? lookup[id] : undefined);
  }, [timeOffPolicy]);

  const levelEditParams = useMemo(() => {
    const options = timeOffPolicy?.levels?.map((level) => {
      return {
        value: level._id,
        label: level.name,
      };
    });

    return { options: options };
  }, [timeOffPolicy]);

  const policyLevelComputedStartDates = useMemo(() => {
    return levelComputedStartDates(timeOffPolicy);
  }, [timeOffPolicy]);

  const handleSetEmployees = useCallback(
    (employees: TimeOffEnrolleesTableEntry[]) => {
      setEmployees(employees);
      onEmployeesChanged(employees);
    },
    [onEmployeesChanged]
  );

  useEffect(() => {
    const timeOffEmployees = accessibleTeamMembers.map((member) =>
      buildTimeOffEnrolleesTableEntry(member, timeOffPolicy)
    );
    setEmployees(timeOffEmployees);
  }, [timeOffPolicy, accessibleTeamMembers]);

  const columns = useMemo(() => {
    const cols: ColumnConfig<TimeOffEnrolleesTableEntry>[] = [
      {
        headerName: "Name",
        field: "full_name",
        dataType: "string",
        cellRenderer: (params) => {
          return params.data?.full_name;
        },
        onCellClicked: (params) => {
          if (params.data?._id) {
            navigate(`/team-members/${params.data._id}`);
          }
        },
      },
      {
        field: "friendly_id",
        headerName: "ID",
        dataType: "string",
      },
      {
        headerName: "Title",
        field: "title",
        dataType: "string",
      },
      {
        headerName: "Department",
        field: "department.name",
        dataType: "string",
      },
      {
        field: "level_id",
        headerName: "Level",
        valueFormatter: (params) => levelLookup(params?.data?.level_id)?.name || "Level not assigned",
        editable: (params) =>
          teamAbilities.can("update", teamMemberLookup(params.data?._id)) && can("time_off:policies:update"),
        editorType: "select",
        cellEditorParams: () => {
          return { isClearable: true, ...levelEditParams };
        },
        enableRowGroup: true,
      },
      {
        headerName: "Balance",
        field: "balance",
        dataType: "number",
        editorType: "number",
        validations: (value, params) => validateBalance(value, levelLookup(params?.data?.level_id)),
        editable: (params) =>
          teamAbilities.can("update", teamMemberLookup(params.data?._id)) && can("time_off:policies:update"),
        hide: !timeOffPolicy?.levels?.find((level) => !level.unlimited),
      },
    ];

    return cols;
  }, [employees, timeOffPolicy, can]);

  const handleEnroll = () => {
    setShowEmployeeSelector(true);
  };

  const handleUnenroll = () => {
    const unenrolleesMap = keyBy(selectedEnrollees, "_id");

    const updatedEmployees = employees.map((employee): TimeOffEnrolleesTableEntry => {
      const unenrollee = unenrolleesMap[employee._id];

      if (unenrollee) {
        return { ...employee, enrollment_status: "unenroll", updated: true };
      } else {
        return employee;
      }
    });

    handleSetEmployees(updatedEmployees);
  };

  const handleEmployeeSelectorSelection = (selectorEmployees: AggregatedTeamMember[]) => {
    const selectorEmployeesMap = keyBy(selectorEmployees, "_id");
    const updatedEmployees = employees.map((employee): TimeOffEnrolleesTableEntry => {
      const selectorEmployee = selectorEmployeesMap[employee._id];

      if (selectorEmployee && employee.enrollment_status === "unenroll") {
        const levelId = assignDefaultLevel(employee?.start_date, policyLevelComputedStartDates);
        return {
          ...employee,
          enrollment_status: "enroll",
          level_id: levelId,
          updated: true,
        };
      } else if (!selectorEmployee && employee.enrollment_status === "enroll") {
        return {
          ...employee,
          enrollment_status: "unenroll",
          updated: true,
        };
      } else {
        return employee;
      }
    });

    handleSetEmployees(updatedEmployees);
    setShowEmployeeSelector(false);
  };

  const handleTableSave = useCallback(
    async (editedData: TimeOffEnrolleesTableEntry[]) => {
      const lookupData = keyBy(editedData, "_id");
      const updatedEmployees = employees.map((employee): TimeOffEnrolleesTableEntry => {
        const updatedEmployee = lookupData[employee._id];
        if (updatedEmployee) {
          return {
            ...employee,
            balance: updatedEmployee.balance,
            level_id: updatedEmployee.level_id ?? null,
            updated: true,
          };
        } else {
          return employee;
        }
      });

      handleSetEmployees(updatedEmployees);
      return { successes: [], errors: [] };
    },
    [employees, handleSetEmployees]
  );

  const handleImportFinished = () => {
    setShowImportHistory(true);
    setShowLatestImportResult(true);
    reloadEnrollees();
  };

  const validateBalance = (balance: string | null, level?: TimeOffPolicyLevelConfig) => {
    const balanceNum = Number(balance);
    if (isNaN(balanceNum)) {
      return "Balance must be a number";
    }

    // Ensure that the balance isn't larger than the time off policies max balance
    const hasMaxBalance = level && level.accrual_config?.max_balance;
    if (hasMaxBalance && balanceNum > level.accrual_config!.max_balance!) {
      const maxBalance = level.accrual_config!.max_balance;
      return `Balance cannot be larger than the maximum balance of ${maxBalance}`;
    }

    // Ensure that we don't have a negative balance if the time off policy doesn't allow negative balances
    const disallowsNegativeBalance = level && level.disable_negative_balances;
    if (disallowsNegativeBalance && balanceNum < 0) {
      return "Balance cannot be negative for this time off policy level";
    }

    return true;
  };

  const buildTimeOffEnrolleesTableEntry = (
    employee: AggregatedTeamMember,
    timeOffPolicy: TimeOffPolicy | undefined
  ): TimeOffEnrolleesTableEntry => {
    const employeePolicy = employee.time_off.policies.find(
      (policy) => policy.policy_id === timeOffPolicy?._id
    );

    if (!employeePolicy) {
      return {
        ...employee,
        _id: employee._id,
        full_name: employee.full_name,
        title: employee.title,
        enrollment_status: "unenroll",
        updated: false,
      };
    } else {
      const level = levelLookup(employeePolicy.level_id);
      const balance = !level?.unlimited
        ? employeePolicy.balance ?? level?.default_starting_balance ?? 0
        : null;

      return {
        ...employee,
        _id: employee._id,
        full_name: employee.full_name,
        title: employee.title,
        balance,
        enrollment_status: "enroll",
        updated: false,
        level_id: employeePolicy.level_id,
      };
    }
  };

  const dynamicActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "Unenroll",
        action: handleUnenroll,
        className: "button-1 table-button",
        icon: <UserMinus weight="bold" style={{ marginRight: 5 }} />,
        showInEditMode: true,
        shouldShow: () => can("time_off:policies:update"),
      },
    ];
  }, [selectedEnrollees, can]);

  const staticActions: TableActionLink[] = useMemo(() => {
    return [
      {
        key: "import",
        component: (
          <TimeOffPolicyEnrolleeImporter policyId={timeOffPolicy?._id} onFinish={handleImportFinished} />
        ),
        showInEditMode: true,
        shouldShow: () => can("time_off:policies:update"),
      },
      {
        key: "import-history",
        className: "button-1",
        action: () => setShowImportHistory(true),
        label: "Import history",
        showInEditMode: true,
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("time_off:policies:update"),
      },
      {
        label: "Manage enrollees",
        action: handleEnroll,
        important: true,
        className: "button-2 table-button",
        icon: <UserPlus weight="bold" style={{ marginRight: 5 }} />,
        showInEditMode: true,
        shouldShow: () => can("time_off:policies:update"),
      },
    ];
  }, [can]);

  const renderEmployeesTable = () => {
    return (
      <TableV2
        id={"time-off-enrollees-table"}
        resource="enrollees"
        data={enrollees}
        alwaysShowSecondaryActions={true}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedEnrollees}
        defaultSelectedRows={selectedEnrollees}
        disablePagination={true}
        alwaysEditable={true}
        editable={can("time_off:policies:update")}
        autoSave={true}
        onSave={handleTableSave}
        hideRowEditingStatus={true}
        suppressEditableGreyOut={true}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"time-off-enrollees-table-container"}
      />
    );
  };

  const renderEmployeesSelector = () => {
    const enrolleesMap = keyBy(enrollees, "_id");
    const defaultSelectedEmployees = accessibleTeamMembers.filter((e) => enrolleesMap[e._id]);

    return (
      <BulkTeamMemberSelect
        title={`Manage Enrollees`}
        defaultTeamMembers={defaultSelectedEmployees}
        teamMembersPool={employees}
        onHide={() => setShowEmployeeSelector(false)}
        submitText="Submit"
        onSubmit={handleEmployeeSelectorSelection}
        nonRemovableDescriptor="Signed"
      />
    );
  };

  return (
    <>
      {showImportHistory && (
        <ImportHistory
          id={"time_off_policy_enrollees"}
          resource={"enrollees"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
      {renderEmployeesTable()}
      {showEmployeeSelector && renderEmployeesSelector()}
    </>
  );
};

export default TimeOffPolicyEnrolleesTable;
