import { FlatfileResults } from "@flatfile/react";
import {
  useActiveCompanyId,
  useActiveTeam,
  useCostTypeOptions,
  useLedgerAccountOptions,
} from "dashboard/hooks/atom-hooks";
import React, { useMemo } from "react";
import { Notifier } from "ui";
import { keyBy } from "lodash";
import { Allowance, CreateAllowanceParams, MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../../components/importer/Importer";
import { buildFlatfileMessage, normalizeDate } from "dashboard/utils/flatfile";
import { allowanceTypeOptions } from "./AllowanceModal";

type PrelimAllowanceImportRow = {
  teamMemberId: string;
  description: string;
  amount: string;
  calculationMethod: Allowance["calculation_method"];
  ignoreBenefitContributions: "true" | "false";
  ignoreMiscPtds: "true" | "false";
  startDate: string;
  endDate?: string;
  type: Allowance["type"];
  accountId?: string;
  costTypeId?: string;
};

type Props = {
  onFinish: () => void;
};

export const AllowanceImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeTeam = useActiveTeam();
  const lookupTeamFriendlyID = useMemo(() => keyBy(activeTeam, "friendly_id"), [activeTeam]);
  const ledgerAccounts = useLedgerAccountOptions();
  const costTypes = useCostTypeOptions();

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/
  const buildAllowanceParams = (row: PrelimAllowanceImportRow): CreateAllowanceParams => {
    if (!activeCompanyId) throw new Error("No active role");

    const {
      teamMemberId,
      description,
      amount,
      calculationMethod,
      ignoreBenefitContributions,
      ignoreMiscPtds,
      startDate,
      endDate,
      type,
      accountId,
      costTypeId,
    } = row;

    const teamMember = lookupTeamFriendlyID[teamMemberId];
    if (!teamMember) throw new Error("Team member not found");

    const params: CreateAllowanceParams = {
      company: activeCompanyId,
      team_member: teamMember._id,
      description,
      amount: Number(amount),
      calculation_method: calculationMethod,
      ignore_benefit_contributions: ignoreBenefitContributions === "true",
      ignore_misc_ptds: ignoreMiscPtds === "true",
      start_date: startDate,
      end_date: endDate,
      type,
      ledger_account_id: accountId || null,
      cost_type_id: costTypeId || null,
    };

    return params;
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedAllowances = results.validData.map(buildAllowanceParams);

      const response = await MiterAPI.allowances.import({
        clean_inputs: preppedAllowances,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} allowances with ${errors} errors.`);
        } else {
          Notifier.success(`Imported ${successes} allowances.`);
        }
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the allowances.");
    }
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  /** If there is a team member ID, validate that the team member exists */
  const validateTeamMemberID = (employeeId: string) => {
    const teamMember = lookupTeamFriendlyID[employeeId];
    if (!teamMember) {
      return buildFlatfileMessage("Team member not found", employeeId, "error");
    }
    return { value: employeeId };
  };

  /** Ensure that the amount must be greater than 0 */
  const validateAmount = (amount: string) => {
    const amountNum = Number(amount);
    if (isNaN(amountNum)) {
      return buildFlatfileMessage("Amount must be a number", amount, "error");
    }

    if (amountNum < 0) {
      return buildFlatfileMessage("Amount must be greater than 0", amount, "error");
    }

    return { value: amount };
  };

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Team member ID",
        type: "string",
        key: "teamMemberId",
        description: "Unique identifer for an team member (must be same in source system and Miter)",
        validators: [{ validate: "required" }],
        hook: (row) =>
          typeof row === "string" ? validateTeamMemberID(row) : validateTeamMemberID(row.teamMemberId),
      },
      {
        label: "Description",
        type: "string",
        key: "description",
        description: "Description of the allowance",
        validators: [{ validate: "required" }],
      },
      {
        label: "Amount",
        type: "string",
        key: "amount",
        description: "Amount of the allowance",
        validators: [{ validate: "required" }],
        hook: (data) => (typeof data === "string" ? validateAmount(data) : validateAmount(data.amount)),
      },
      {
        label: "Calculation method",
        type: "select",
        key: "calculationMethod",
        description: "How the allowance is calculated (per month or per week)",
        validators: [{ validate: "required" }],
        options: [
          { label: "Monthly", value: "per_month" },
          { label: "Weekly", value: "per_week" },
        ],
      },
      {
        label: "GL account",
        type: "select",
        key: "accountId",
        description: "The ledger account to track this allowance.",
        options: ledgerAccounts,
      },
      {
        label: "Cost type",
        type: "select",
        key: "costTypeId",
        description: "The cost type to associate to this allowance.",
        options: costTypes,
      },
      {
        label: "Ignore benefit contributions",
        type: "select",
        key: "ignoreBenefitContributions",
        description: "Whether to ignore this allowance when calculating benefit contributions",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      {
        label: "Ignore misc post-tax deductions",
        type: "select",
        key: "ignoreMiscPtds",
        description: "Whether to ignore this allowance when calculating misc post-tax deductions",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      {
        label: "Type",
        type: "select",
        key: "type",
        description: "The allowance type",
        options: allowanceTypeOptions,
        validators: [{ validate: "required" }],
      },
      {
        label: "Start date",
        type: "string",
        key: "startDate",
        description: "Date the allowance starts",
        validators: [{ validate: "required" }],
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.startDate)),
      },
      {
        label: "End date",
        type: "string",
        key: "endDate",
        description: "Date the allowance ends",
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.endDate)),
      },
    ];

    return fieldList;
  }, [ledgerAccounts, costTypes]);

  return <Importer id="allowances" resource="allowances" onSave={handleSubmit} fields={fields} />;
};
