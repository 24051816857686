import React, { useEffect, useMemo, useState } from "react";
import { Plus } from "phosphor-react";
import { TableV2, ColumnConfig } from "ui/table-v2/Table";
import { usePolicies } from "dashboard/hooks/atom-hooks";
import { Policy } from "dashboard/miter";
import { PolicyType } from "backend/services/approvals/types";
import { deparameterize } from "miter-utils";
import { PolicyModal } from "./PolicyModal";
import { useParams, Params, useNavigate } from "react-router-dom";

type Props = {
  policyId?: string;
  policyType: PolicyType;
};

export const PoliciesTable: React.FC<Props> = ({ policyType }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const allPolicies = usePolicies();

  const [addingPolicy, setAddingPolicy] = useState(false);
  const [currentlyOpenPolicy, setCurrentlyOpenPolicy] = useState<Policy | undefined>();
  const { id: idFromUrl } = useParams<Params>();
  const navigate = useNavigate();

  const isExpensePolicy = policyType === "expense" || policyType === "expense_reimbursement";

  const policies = useMemo(() => {
    return allPolicies.filter((policy) => policy.type === policyType);
  }, [allPolicies, policyType]);

  /*********************************************************
   *  Deeplink to policyId
   **********************************************************/
  useEffect(() => {
    setCurrentlyOpenPolicy(policies.find((policy) => policy._id === idFromUrl));
  }, [idFromUrl, policies]);

  /*********************************************************
   * Table/modal handler functions
   **********************************************************/
  const handleModalHide = () => {
    setCurrentlyOpenPolicy(undefined);
    setAddingPolicy(false);

    if (isExpensePolicy) {
      navigate(`/expenses/settings/policies`, { replace: true });
    }
  };

  /*********************************************************
   *  Table config variables
   **********************************************************/
  const staticActions = useMemo(() => {
    return [
      {
        label: "Add Policy",
        className: "button-2 no-margin",
        action: () => setAddingPolicy(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, [policies]);

  const columns: ColumnConfig<Policy>[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        dataType: "string",
      },
      {
        headerName: "Number of Rules",
        field: "rules",
        dataType: "number",
        valueFormatter: (params) => params.value.length,
      },
    ],
    []
  );

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderModal = () => {
    if (!addingPolicy && !currentlyOpenPolicy) return;

    return <PolicyModal policy={currentlyOpenPolicy} type={policyType} onHide={handleModalHide} />;
  };

  return (
    <>
      {renderModal()}
      <TableV2
        id={`${policyType}-policies-table`}
        resource={`${deparameterize(policyType).toLocaleLowerCase()} policies`}
        data={policies}
        columns={columns}
        rowLinkBuilder={
          isExpensePolicy ? (policy) => `/expenses/settings/policies/${policy?._id}` : undefined
        }
        onClick={isExpensePolicy ? undefined : (policy) => setCurrentlyOpenPolicy(policy)}
        staticActions={staticActions}
        hideSearch={true}
        hideSecondaryActions={true}
        showTotals={false}
        hideFooter={true}
        containerStyle={{ marginTop: -20, paddingBottom: 0 }}
      />
    </>
  );
};
