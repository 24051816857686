import { FrontendModel, MiterAPI } from "dashboard/miter";
import React, { FC, useEffect, useState } from "react";
import { AggregatedFillableDocument } from "dashboard/types/fillable-template-types";
import { Notifier, Wizard } from "ui";
import { FillFillableDocumentScreen } from "./FillFillableDocumentsScreen";
import { Role as Role_ } from "../../../backend/models";
import { ConfirmCompletedDocumentScreen } from "./ConfirmCompletedDocumentScreen";

type Role = FrontendModel<Role_>;

type Props = {
  teamMember?: {
    full_name: string;
    _id: string;
    title: string;
  };
  role?: Role;
  userId: string;
  fillableDocumentId: string;
  onComplete: () => void;
  onExit: () => void;
  isSuperAdmin?: boolean;
  application: Application;
};

type Application = "dashboard" | "team-portal" | "mobile-app";

export type FillableDocumentScreenProps = {
  fillableDocument: AggregatedFillableDocument;
  accountType: "admin" | "team_member";
  teamMember?: {
    full_name: string;
    _id: string;
    title: string;
  };
  role?: Role;
  userId: string;
  /** Name of the Wizard Screen */
  name: string;
  setFillableDocument: (fillableDocument: AggregatedFillableDocument) => void;
  isSuperAdmin?: boolean;
  application: Application;
};

const FillableDocumentWizard: FC<Props> = ({
  teamMember,
  role,
  userId,
  fillableDocumentId,
  onComplete,
  onExit,
  isSuperAdmin,
  application,
}) => {
  const accountType = teamMember ? "team_member" : "admin";
  const [fillableDocument, setFillableDocument] = useState<AggregatedFillableDocument>();

  useEffect(() => {
    const fetchFillableDocument = async () => {
      try {
        const fillableDocument = await MiterAPI.files.fillable_documents.retrieve(fillableDocumentId, {
          team_member_id: teamMember?._id,
          role_id: role?._id,
        });
        if (fillableDocument.error) {
          throw new Error(fillableDocument.error);
        }
        setFillableDocument(fillableDocument);
      } catch (error: $TSFixMe) {
        Notifier.error(`Error getting fillable document: ${error.message}`);
      }
    };
    fetchFillableDocument();
  }, [teamMember?._id, fillableDocumentId]);

  if (!fillableDocument) {
    return null;
  }

  return (
    <Wizard onComplete={onComplete} onExit={onExit}>
      <FillFillableDocumentScreen
        fillableDocument={fillableDocument}
        name={"Fill document fields"}
        accountType={accountType}
        teamMember={teamMember}
        role={role}
        userId={userId}
        setFillableDocument={setFillableDocument}
        isSuperAdmin={isSuperAdmin}
        application={application}
      />
      <ConfirmCompletedDocumentScreen fillableDocument={fillableDocument} name={"Confirm entries"} />
    </Wizard>
  );
};

export default FillableDocumentWizard;
