import { addressString, shorten } from ".";
import { useCallback, useContext, useMemo } from "react";
import {
  Job,
  AggregatedJob,
  AggregatedTeamMember,
  TeamMember,
  MiterIntegrationForCompany,
} from "dashboard/miter";
import { unwindCustomFieldValuesAndPrependPrefix } from "miter-utils";
import {
  useActiveJobs,
  useJobNameFormatter,
  useJobOptions,
  useJobs,
  useLookupJob,
  useLookupPerDiemRates,
  useSubJobsConfig,
} from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import { states } from "miter-utils/lists";
import { Option } from "ui/form/Input";
import { ColumnConfig, TData } from "ui/table-v2/Table";
import { KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";

export type CleanedJobWithHierarchy = CleanedJob<AggregatedJob> & { jobHierarchy?: string[] };

export const useJobImportSettings = (): $TSFixMe => {
  const jobOptions = useJobOptions();
  const subJobsConfig = useSubJobsConfig();
  const { customers } = useContext(AppContext);

  const customerOptions = useMemo(() => {
    return customers.map((customer) => ({ label: customer.name, value: customer._id }));
  }, [customers]);

  const jobImportSettings = {
    type: "job",
    fields: [
      {
        label: "Name",
        key: "name",
        validators: [{ validate: "required" }],
      },
      {
        label: "Code",
        key: "code",
        description: "Optional code for the job",
      },
      {
        label: "Customer",
        key: "customer",
        type: "select",
        options: customerOptions,
      },
      {
        label: "Start date",
        key: "start_date",
        description: "Optional start date for the job, format as YYYY-MM-DD",
      },
      {
        label: "Description",
        key: "description",
      },
      ...(subJobsConfig.enabled
        ? [
            {
              label: "Parent job",
              key: "parent_job_id",
              type: "select",
              options: jobOptions,
            },
          ]
        : []),
      {
        label: "Address - line 1",
        description: "Street address",
        key: "address.line1",
      },
      {
        label: "Address - line 2",
        description: "Apt, suite, etc.",
        key: "address.line2",
      },
      {
        label: "Address - city",
        description: "City",
        key: "address.city",
      },
      {
        label: "Address - state",
        description: "State",
        type: "select",
        key: "address.state",
        options: states.map((state) => ({ label: state.abbreviation, value: state.abbreviation })),
      },
      {
        label: "Address - zipcode",
        description: "Postal code",
        key: "address.postal_code",
      },
      {
        label: "External account ID",
        description: "Optional ID for the job in your accounting system",
        key: "external_accounting_id",
      },
      {
        label: "Supervisor IDs",
        description: "Optional comma-separated list of supervisor IDs",
        key: "supervisor_ids",
      },
    ],
  };

  return jobImportSettings;
};

export type CleanedJob<T extends Job | AggregatedJob> = T & {
  address_string: string | undefined;
  supervisor_options: Option<string>[];
  superintendent_options: Option<string>[];
  per_diem_rate_options: Option<string>[];
};

type CleanJobsFunction = <T extends Job | AggregatedJob>(rawJobs: T[]) => CleanedJob<T>[];

export const useCleanJobsFromBackend = (): CleanJobsFunction => {
  const lookupPerDiemRate = useLookupPerDiemRates();
  return (rawJobs) => {
    return rawJobs.map((job) => {
      return {
        ...job,
        name: job.name,
        is_ocip: !!job.is_ocip,
        code: job.code || "-",
        description: shorten(job.description || "-", 30),
        address_string: addressString(job.address)?.trim(),
        custom_activities: !!job.custom_activities,
        ...("custom_field_values" in job ? unwindCustomFieldValuesAndPrependPrefix(job) : {}),
        cpr_enabled: !!job.cpr_info,
        supervisor_options: job.supervisors?.map((s) => ({ label: s.full_name, value: s._id })) || [],
        superintendent_options:
          "superintendents" in job
            ? job.superintendents?.map((s) => ({ label: s.full_name, value: s._id })) || []
            : [],
        per_diem_rate_options:
          job.per_diem_rate_ids?.map((id) => {
            // use lookup
            const perDiemRate = lookupPerDiemRate(id);
            return { label: perDiemRate?.name || "", value: id };
          }) || [],
      };
    });
  };
};

export const requiredJobAccess = (
  job: AggregatedJob,
  teamMember: TeamMember | AggregatedTeamMember
): boolean => {
  // If they are a universal supervisor, they can see everything automatically
  const isUniversalSupervisor = teamMember.is_universal_supervisor;
  if (isUniversalSupervisor) return true;

  // If they are a supervisor on the job, they can always see the job as well
  const isJobSupervisor = job.supervisors.some((s) => s._id === teamMember._id);
  if (isJobSupervisor) return true;

  return false;
};

export const useTeamMemberAccessibleJobs = (
  teamMember: TeamMember | AggregatedTeamMember,
  options?: { clean?: boolean }
): {
  accessibleJobsPool: AggregatedJob[] | CleanedJob<AggregatedJob>[];
  accessibleJobs: AggregatedJob[] | CleanedJob<AggregatedJob>[];
} => {
  const cleanJobs = useCleanJobsFromBackend();
  const jobs = useActiveJobs();

  // Filter down jobs by the job's team member_ids
  const accessibleJobsPool = useMemo(() => {
    const filtered = jobs.filter((job) => {
      if (requiredJobAccess(job, teamMember)) return true;

      return true;
    });

    if (options?.clean) {
      return cleanJobs(filtered);
    }

    return filtered;
  }, [jobs, teamMember]);

  // Now, filter down the jobs that are actually accessible to the team member after narrowing down to due to team member settings
  const accessibleJobs = useMemo(() => {
    return accessibleJobsPool.filter((job) => {
      if (requiredJobAccess(job, teamMember)) return true;
      if (!teamMember.limit_accessible_jobs) return true;

      return teamMember.accessible_job_ids?.includes(job._id);
    });
  }, [accessibleJobsPool]);

  return { accessibleJobsPool, accessibleJobs };
};

export const baseJobOptionsFilterPredictate = (j: AggregatedJob | Job): boolean =>
  j.status === "active" && !j.archived;

export const cprJobsFilterPredicate = (j: AggregatedJob | Job): boolean => !!j.cpr_info;

export const useJobSourceSystem = (job?: AggregatedJob | Job): MiterIntegrationForCompany | undefined => {
  const { integrations } = useContext(AppContext);

  let sourceSystem: MiterIntegrationForCompany | undefined;
  if (job?.integrations) {
    const key = Object.keys(job.integrations)[0];
    if (key) sourceSystem = integrations.find((i) => i.key === key);
  }

  return sourceSystem;
};

/** If job is from Procore or Intacct, then `custom_activities` is controlled by the integration and we shouldn't let the custom override it */
export const shouldDisableJobActivityControl = (job?: AggregatedJob): boolean => {
  return !!job?.integrations?.procore?.project_id || !!job?.integrations?.sage_intacct?.intacctProject;
};

/**
 * Convert the jobs array to have a property called jobHierarchy that includes all their ancestor ids with the following format: id1.id2.id3
 * - Note that parent_job_id is the id of the parent job
 * - Not all jobs have a parent_job_id
 * - Do it as efficently as possible, bottom up
 * - Include self in the hierarchy
 *
 *  */
export const useBuildJobHierarchies = (): ((
  jobs: CleanedJob<AggregatedJob>[]
) => CleanedJobWithHierarchy[]) => {
  const lookupJob = useLookupJob();

  return useCallback(
    (jobs: CleanedJob<AggregatedJob>[]): CleanedJobWithHierarchy[] => {
      return jobs.map((job) => {
        const hierarchy: string[] = [job._id];

        let currentJob: AggregatedJob | undefined = job;
        while (currentJob?.parent_job_id) {
          hierarchy.unshift(currentJob.parent_job_id);
          currentJob = lookupJob(currentJob.parent_job_id);
        }
        return { ...job, jobHierarchy: hierarchy };
      });
    },
    [lookupJob]
  );
};

/**
 * Set version of useBuildJobHierarchies that takes in a job a job id and returns the hierarchy of job ids for
 */
export const useGetJobHierarchy = (): ((jobId: string) => string[]) => {
  const jobs = useJobs();
  const cleanJobs = useCleanJobsFromBackend();
  const hasEnabledSubJobs = useSubJobsConfig().enabled;
  const buildJobHierarchies = useBuildJobHierarchies();

  const jobHierarchy = useMemo(() => {
    const cleanedJobs = cleanJobs(jobs);
    const jobsWithHierarchies = buildJobHierarchies(cleanedJobs);

    return new Map(jobsWithHierarchies.map((j) => [j._id, j.jobHierarchy || []]));
  }, [buildJobHierarchies, jobs, cleanJobs]);

  return useCallback(
    (jobId: string): string[] => {
      if (!hasEnabledSubJobs) return [];
      return jobHierarchy.get(jobId) || [];
    },
    [jobHierarchy, hasEnabledSubJobs]
  );
};

/**
 * Reusable hook to build job hierarchy columns in the SSR tables
 */
export const useJobHierarchyTableColumns = <T extends TData>(opts?: {
  ssr?: boolean;
  field?: string;
  initialRowGroup?: boolean;
}): ColumnConfig<T>[] => {
  const subJobsConfig = useSubJobsConfig();
  const jobOptions = useJobOptions();
  const jobNameFormatter = useJobNameFormatter({ disableParentJobPrefixing: true });

  return useMemo(() => {
    if (!subJobsConfig.enabled) return [];

    const columns: ColumnConfig<T>[] = [];
    for (let i = 0; i <= subJobsConfig.depth; i++) {
      const field = opts?.field || `job_hierarchy_ids`;
      const baseColumn: ColumnConfig<T> = {
        field: `${field}.${i}`,
        headerName: `Job level ${i + 1}`,
        dataType: "string",
        minWidth: 200,
        initialRowGroup: opts?.initialRowGroup,
        initialRowGroupIndex: opts?.initialRowGroup ? i : undefined,
        valueGetter: (params) => {
          return jobNameFormatter(params.data?.[field]?.[i]);
        },
      };

      if (opts?.ssr) {
        columns.push({
          ...baseColumn,
          filter: "agSetColumnFilter", // filterParam added in TimesheetsTable component
          filterParams: {
            values: jobOptions,
            keyCreator: (option: KeyCreatorParams<T>) => option.value?.value,
            valueFormatter: (params: ValueFormatterParams) => params.value?.label,
          },
          enableRowGroup: false,
        });
      } else {
        columns.push({
          ...baseColumn,
          enableRowGroup: true,
        });
      }
    }

    return columns;
  }, [subJobsConfig, jobNameFormatter, jobOptions]);
};
