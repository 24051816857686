import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";

import styles from "./modalWithSidebar.module.css";
import { ActionMenuRelative } from "../action-menu";
import { ModalSidebar } from "../toggler";

import x from "dashboard/assets/x.png";

export type MenuItem = {
  label: string;
  path: string;
  component: React.ReactElement;
  overflow?: "scroll" | "visible";
};

export type MenuItemWithoutComponent = Omit<MenuItem, "component">;

type Action = {
  label: string;
  action: () => void;
};

type Props = {
  menuItems: MenuItem[];
  header: React.ReactElement | string;
  actions?: Action[];
  hide: () => void;
  height?: number;
  width?: number;
  badges?: React.ReactElement[];
  banner?: React.ReactElement;
};

const ModalWithSidebar: React.FC<Props> = ({
  menuItems,
  header,
  actions,
  hide,
  height,
  width,
  badges,
  banner,
}) => {
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItem | undefined>();

  useEffect(() => {
    if (!activeMenuItem) {
      setActiveMenuItem(menuItems[0]);
    } else {
      setActiveMenuItem(menuItems.find((item) => item.path === activeMenuItem.path));
    }
  }, [menuItems]);

  const handleToggle = (path) => {
    const activeItem = menuItems.find((item) => item.path === path);
    setActiveMenuItem(activeItem);
  };

  const usedHeight = height ? Math.max(height, 400) : 400;
  const usedWidth = width ? Math.max(width, 600) : 600;

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className={styles["modal-wrapper"]} style={{ minHeight: usedHeight, width: usedWidth }}>
          <div className={styles["header"]}>
            {header}
            {badges && React.Children.toArray(badges)}
            <div className="flex-1"></div>
            {actions ? (
              <ActionMenuRelative links={actions} />
            ) : (
              <img src={x} style={{ height: 12, cursor: "pointer" }} onClick={hide} />
            )}
          </div>
          {banner}
          <div className={styles["below"]}>
            <ModalSidebar config={menuItems} active={activeMenuItem?.path} toggle={handleToggle} />
            <div
              className={styles["active-view"]}
              style={{ overflowY: activeMenuItem?.overflow || "scroll" }}
            >
              {activeMenuItem?.component}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ModalWithSidebar;
