import {
  useActiveCompanyId,
  usePrgs,
  useRefetchActivities,
  useRefetchJobs,
  useRefetchPrgs,
  useRefetchTeam,
} from "dashboard/hooks/atom-hooks";
import { AggregatedPayRateGroup, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { Copy, Plus, TrashSimple } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { BasicModal, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { CreatePayRateGroupModal } from "./PayRateGroupPage/CreatePayRateGroupModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { ClassificationsImporter } from "./ClassificationsImporter";
import { ImportResultModal } from "dashboard/components/importer/ImportHistory";
import { PayRateGroupFringeImporter } from "./PayRateGroupPage/PayRateGroupFringeImporter";
import { FringeAmountImporter } from "./FringeAmountImporter";

type RateGroupEntry = {
  _id: string;
  label: string;
  num_classifications: number;
};

export const PayRateGroups: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const refetchPrgs = useRefetchPrgs();
  const prgs = usePrgs();
  const refetchTeam = useRefetchTeam();
  const refetchJobs = useRefetchJobs();
  const refetchActivities = useRefetchActivities();
  const { can, cannot } = useMiterAbilities();

  const [creating, setCreating] = useState(false);
  const [selectedRows, setSelectedRows] = useState<RateGroupEntry[]>([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [importResults, setImportResults] = useState(null);

  const navigate = useNavigate();

  const payRateGroupEntries = useMemo(() => {
    return prgs.map((prg): RateGroupEntry => {
      return {
        _id: prg._id,
        label: prg.label,
        num_classifications: prg.union_rates.length,
      };
    });
  }, [prgs]);

  const duplicatePrgs = async () => {
    if (!activeCompanyId || cannot("lists:pay_rate_groups:manage")) return;

    setDuplicating(true);
    try {
      // This should be successive, not parallel, since the duplicate function changes the TM and we want a clean audit log
      const dupIds: string[] = [];
      for (const row of selectedRows) {
        const id = row._id;
        const res = await MiterAPI.pay_rate_groups.duplicate({ id, company_id: activeCompanyId });
        if (res.error) throw new Error(res.error);
        dupIds.push(res._id);
      }
      await Promise.all([refetchPrgs(dupIds), refetchTeam()]);
      Notifier.success("Pay rate group(s) duplicated successfully.");
      setDuplicating(false);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error duplicating one or more pay rate groups. We're looking into it!");
    }

    setSelectedRows([]);
  };

  const importFinished = async (results) => {
    setImportResults(results);
    refetchPrgs();
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add pay rate group",
        className: "button-2 no-margin table-button",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
      {
        key: "import",
        component: <ClassificationsImporter onFinish={importFinished} />,
        important: false,
        showInEditMode: true,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
      {
        key: "importFringes",
        component: <PayRateGroupFringeImporter onFinish={importFinished} />,
        important: false,
        showInEditMode: true,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
      {
        key: "importFringeAmounts",
        component: <FringeAmountImporter onFinish={importFinished} />,
        important: false,
        showInEditMode: true,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
    ],
    [can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Duplicate",
        className: "button-1 table-button",
        action: duplicatePrgs,
        loading: duplicating,
        icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiveModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
    ],
    [can, duplicating, selectedRows, activeCompanyId]
  );

  const handleHide = () => {
    setCreating(false);
  };

  const archiveGroups = async () => {
    if (!activeCompanyId || cannot("lists:pay_rate_groups:manage")) return;

    setArchiving(true);
    const ids = selectedRows.map((row) => row._id);
    const response = await MiterAPI.pay_rate_groups.archive(ids, activeCompanyId);
    if (response.error) {
      Notifier.error("There was an error deleting one or more pay rate groups.");
      console.error(response.error);
    } else if (response.failed.length) {
      const failure = response.failed[0]!;
      const failedPrg = payRateGroupEntries?.find((prg) => prg._id.toString() === failure.id);
      Notifier.error(`Error deleting ${failedPrg?.label || "pay rate group"}: ${response.failed[0]!.reason}`);
    }
    refetchJobs();
    refetchActivities();
    refetchTeam();
    await refetchPrgs(ids);
    setArchiveModal(false);
    setSelectedRows([]);
    setArchiving(false);
  };

  const onPayRateGroupCreation = async (prg: AggregatedPayRateGroup) => {
    setCreating(false);
    await refetchPrgs(prg._id);
    navigate(`/settings/pay-rate-groups/${prg._id}`);
  };

  return (
    <>
      {creating && <CreatePayRateGroupModal hide={handleHide} onSubmit={onPayRateGroupCreation} />}
      {archiveModal && (
        <BasicModal
          headerText="Delete pay rate group(s)?"
          yellowBodyText={true}
          loading={archiving}
          bodyText={`Are you sure you want to delete ${
            selectedRows.length > 1 ? "these groups" : "this group"
          }? This action will permanently remove all associations to jobs, activities, and team members. These associations cannot be restored.`}
          button2Action={archiveGroups}
          button2Text="Delete"
          button1Action={() => setArchiveModal(false)}
        />
      )}
      {importResults && <ImportResultModal result={importResults} onClose={() => setImportResults(null)} />}

      <TableV2
        id={"pay-rate-groups-table"}
        resource="pay rate groups"
        data={payRateGroupEntries}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        onClick={(r) => navigate(`/settings/pay-rate-groups/${r._id}`)}
        defaultSelectedRows={selectedRows}
      />
      {/* Need to include the tooltip out here (it's used in TmPrgClassificationMapper) to fix issue of tooltip not showing up in the proper location when scrolling within the modal
          Source: https://github.com/wwayne/react-tooltip/blob/064249c2d344f5fa639d97994311e484a73156b4/README.md */}
      <ReactTooltip id="tm-prg-mapper" place="right" effect={"solid"} className="tooltip" />
    </>
  );
};

const columns: ColumnConfig<RateGroupEntry>[] = [
  { headerName: "Pay rate group", field: "label", dataType: "string" },
  { headerName: "# classifications", field: "num_classifications", dataType: "string" },
];
