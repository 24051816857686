import React, { useEffect, useMemo, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { timezoneOptions } from "miter-utils";
import Select from "react-select";
import { styles as SelectStyles } from "ui/form/styles";
import { BasicModal, Button, Input, Label, Loader, Toggler } from "ui";
import editIcon from "dashboard/assets/edit.png";
import styles from "./OvertimeRuleModal.module.css";
import checkmark from "dashboard/assets/check-mark.png";
import yellow_warning from "dashboard/assets/yellow-warning.png";
import useDebounce from "dashboard/utils/useDebounce";
import { Notifier } from "dashboard/utils";
import { OvertimeRuleItemForm } from "./OvertimeRuleItemForm";
import { OvertimeRuleItem } from "backend/models/overtime-rule";
import { MiterAPI, OvertimeRule } from "dashboard/miter";
import { useForm } from "react-hook-form";
import { useActiveCompanyId, useSetOtRules } from "dashboard/hooks/atom-hooks";
import { WORK_HOURS_IN_WEEK } from "dashboard/utils/constants";

type Props = {
  overtimeRuleToEdit: OvertimeRule;
  hide: () => void;
};

const emptyItem: OvertimeRuleItem = {
  days_of_week: [],
  thresholds: [],
};

export const OvertimeRuleModal: React.FC<Props> = ({ overtimeRuleToEdit, hide }) => {
  const activeCompanyId = useActiveCompanyId();
  const setOtRules = useSetOtRules();

  const [overtimeRule, setOvertimeRule] = useState<OvertimeRule>(overtimeRuleToEdit);
  const [editingLabel, setEditingLabel] = useState(false);
  const [editingTimezone, setEditingTimezone] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [togglerState, setTogglerState] = useState("daily");
  const [stagedForArchive, setStagedForArchive] = useState<OvertimeRule>();
  const [deleting, setDeleting] = useState(false);

  const form = useForm();

  const [isValid, setIsValid] = useState(true);
  const overtimeRuleDebounced = useDebounce(overtimeRule, 1000);

  const currZoneOption = useMemo(() => {
    return timezoneOptions.find((tz) => tz.value === overtimeRule?.timezone);
  }, [overtimeRule, timezoneOptions]);

  const handleLabelChange = (e) => {
    setOvertimeRule((prev) => {
      return { ...prev, label: e.target.value };
    });
  };

  const handleTimezoneChange = (e) => {
    setOvertimeRule((prev) => {
      return { ...prev, timezone: e.value };
    });
  };

  const handleWeeklyOtThresholdChange = (e) => {
    let numValue = Number(e.target.value);
    if (isNaN(numValue) || numValue <= 0) {
      Notifier.error("Weekly OT threshold must be a positive number.");
      numValue = WORK_HOURS_IN_WEEK;
    }
    setOvertimeRule((prev) => {
      return { ...prev, weekly_ot_threshold: numValue };
    });
  };

  const handleWeeklyDotThresholdChange = (e) => {
    let numValue: number | null = Number(e.target.value);
    if (isNaN(numValue) || numValue < 0) {
      Notifier.error("Weekly DOT threshold must be a positive number.");
      numValue = null;
    } else if (numValue === 0 && e.target.value === "") {
      numValue = null;
    }
    setOvertimeRule((prev) => {
      return { ...prev, weekly_dot_threshold: numValue };
    });
  };

  const archiveRule = async () => {
    if (!activeCompanyId || !stagedForArchive) return;
    setDeleting(true);
    try {
      const response = await MiterAPI.overtime_rules.archive([stagedForArchive._id], activeCompanyId);
      if (response.error) throw new Error(response.error);
      if (response.failed.length) {
        Notifier.error(response.failed[0]!.reason);
      } else {
        Notifier.success("Overtime rule deleted successfully");
        setStagedForArchive(undefined);
        setOtRules((otRules) => otRules.filter((r) => r._id !== stagedForArchive._id));
        hide();
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the overtime rule. We're looking into it!");
    }
    setDeleting(false);
  };

  const saveOvertimeRule = async () => {
    if (!activeCompanyId) return;
    // An hours-worked threshold must have a value > 0 hours
    const invalidHwThresholds = overtimeRuleDebounced.items
      .flatMap((item) => item.thresholds)
      .filter((t) => t.threshold_type !== "time_of_day")
      .some((t) => Number(t.value) <= 0);

    if (invalidHwThresholds) {
      setIsValid(false);
      Notifier.error("A threshold that kicks in after a # of hours worked must have a positive value.");
      return;
    }

    setIsSaving(true);
    try {
      if (!activeCompanyId) throw new Error("No active company id");

      const response = await MiterAPI.overtime_rules.update(
        [overtimeRule._id],
        activeCompanyId,
        overtimeRuleDebounced
      );
      if (response.error) {
        if (response.fields && response.fields[0]) {
          Notifier.error("Input error: " + response.fields[0].error, { duration: 5000 });
          setIsValid(false);
        } else {
          throw new Error(response.error);
        }
      } else {
        setOtRules((otRules) => otRules.concat(response));
        setIsValid(true);
      }
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error saving the overtime rule.");
    }
    setIsSaving(false);
  };

  useEffect(() => {
    saveOvertimeRule();
  }, [overtimeRuleDebounced]);

  const addItem = () => {
    setOvertimeRule((prev) => {
      const newItems = prev.items.slice();
      newItems.push(emptyItem);
      return { ...prev, items: newItems };
    });
  };

  return (
    <div className="modal-background">
      <div
        className={styles["modal-wrapper"] + " modal-wrapper"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div>
          <div style={{ fontSize: 13, marginTop: 10, marginBottom: 5, fontWeight: 600, color: "#4d55bb" }}>
            OVERTIME RULE
          </div>
          <div className="flex">
            {editingLabel && (
              <div className="flex">
                <input
                  className={`${styles["label-input"]}`}
                  value={overtimeRule?.label}
                  onChange={handleLabelChange}
                  placeholder={"Add a name for overtime rule"}
                  style={{ marginRight: 10 }}
                />
                <Button text="Done" className="button-2" onClick={() => setEditingLabel(false)} />
              </div>
            )}
            {editingTimezone && (
              <div>
                <h1 style={{ margin: 0 }}>{overtimeRule?.label}</h1>
                <div className="flex margin-top-12">
                  <Select
                    value={currZoneOption}
                    onChange={handleTimezoneChange}
                    options={timezoneOptions}
                    width={"240px"}
                    height={"32px"}
                    styles={SelectStyles}
                  />
                  <Button
                    text="Done"
                    style={{ marginLeft: 10 }}
                    className="button-2"
                    onClick={() => setEditingTimezone(false)}
                  />
                </div>
              </div>
            )}
            {!editingTimezone && !editingLabel && (
              <div>
                <div className="flex">
                  <h1 style={{ margin: 0 }}>{overtimeRule?.label}</h1>
                  <img
                    src={editIcon}
                    style={{ marginLeft: 15, height: 10, cursor: "pointer" }}
                    onClick={() => setEditingLabel(true)}
                  />
                </div>
                <div className="flex">
                  <p style={{ margin: 0 }}>Timezone: {currZoneOption?.label}</p>
                  <img
                    src={editIcon}
                    style={{ marginLeft: 15, height: 10, cursor: "pointer" }}
                    onClick={() => setEditingTimezone(true)}
                  />
                </div>
              </div>
            )}
            <div className="flex-1"></div>
            {isSaving || overtimeRule !== overtimeRuleDebounced ? (
              <Loader className="small-text" />
            ) : (
              <img src={isValid ? checkmark : yellow_warning} style={{ height: 15 }} />
            )}
            <button className={styles["close-button"]} onClick={hide}>
              <IoIosClose />
            </button>
          </div>
        </div>

        <Toggler config={togglerConfig} toggle={setTogglerState} active={togglerState} />
        <div className="vertical-spacer"></div>
        {togglerState === "daily" && (
          <>
            {overtimeRule.items.map((item, index) => {
              return (
                <OvertimeRuleItemForm
                  key={index}
                  itemIndex={index}
                  overtimeRule={overtimeRule}
                  setOvertimeRule={setOvertimeRule}
                />
              );
            })}
            <Button text="+ New item" className="button-1" onClick={addItem} />
          </>
        )}
        {stagedForArchive && (
          <BasicModal
            headerText={`Delete ${stagedForArchive.label}?`}
            button2Action={archiveRule}
            button2Text="Yes, delete"
            loading={deleting}
            button1Text="Cancel"
            button1Action={() => setStagedForArchive(undefined)}
          >
            <div className="red-text-container">{`Are you sure you want to delete ${stagedForArchive?.label}?`}</div>
          </BasicModal>
        )}
        {togglerState === "weekly" && (
          <>
            <div className="flex">
              <Label label="Weekly OT threshold" labelInfo="The # of hours after which weekly OT kicks in." />
              <div className="flex" style={{ position: "relative" }}>
                <Input
                  type="text"
                  onChange={handleWeeklyOtThresholdChange}
                  defaultValue={overtimeRule.weekly_ot_threshold?.toString() || WORK_HOURS_IN_WEEK.toString()}
                  value={overtimeRule.weekly_ot_threshold?.toString()}
                  errors={form.errors}
                />
                <span style={{ position: "absolute", right: 15, marginLeft: 15 }}>hours</span>
              </div>
            </div>
            <div className="vertical-spacer"></div>
            <div className="flex">
              <Label
                label="Weekly DOT threshold"
                labelInfo="The # of hours after which weekly DOT kicks in."
              />
              <div className="flex" style={{ position: "relative" }}>
                <Input
                  type="text"
                  onChange={handleWeeklyDotThresholdChange}
                  defaultValue={overtimeRule.weekly_dot_threshold?.toString()}
                  value={overtimeRule.weekly_dot_threshold?.toString()}
                  errors={form.errors}
                />
                <span style={{ position: "absolute", right: 15, marginLeft: 15 }}>hours</span>
              </div>
            </div>
          </>
        )}
        <div className="flex-1"></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => setStagedForArchive(overtimeRuleToEdit)}
          >
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};

const togglerConfig = [
  { label: "Daily overtime", path: "daily" },
  { label: "Weekly overtime", path: "weekly" },
];
