import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { Formblock } from "ui";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import styles from "../TeamMemberChecklistBuilder.module.css";
import { Option } from "packages/ui/form/Input";
import { useContext, useMemo } from "react";
import AppContext from "dashboard/contexts/app-context";
import { CustomFieldOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const CustomFieldTaskContent: React.FC<ChecklistTaskContentProps<CustomFieldOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  const { customFields } = useContext(AppContext);

  const teamMemberCustomFields = useMemo(
    () => customFields.filter((field) => field.parent_type === "team_member"),
    [customFields]
  );

  const teamMemberCustomFieldOptions = teamMemberCustomFields.map((field) => ({
    value: field._id,
    label: field.name,
  }));

  const handleCustomFieldChange = (option: Option<string> | null) => {
    setTask({ ...task, data: { ...task.data, custom_field_id: option?.value } });
  };

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      <Formblock
        type="select"
        name="custom_field"
        label="Select custom field"
        editing={true}
        defaultValue={task.data.custom_field_id}
        options={teamMemberCustomFieldOptions}
        className="modal"
        onChange={(option) => handleCustomFieldChange(option)}
        style={{ marginBottom: 0 }}
      />
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
