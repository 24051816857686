import React, { useMemo, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";

import AppContext from "../../contexts/app-context";
import { shorten } from "../../utils/utils";

import checkMark from "dashboard/assets/checking-mark.png";
import downArrow from "dashboard/assets/down-arrow.png";

import "./role-toggler.css";
import styles from "./role-toggler.module.css";
import { matchSorter } from "match-sorter";
import { Badge, Loader, Notifier } from "ui";
import {
  useActiveCompany,
  useActiveAccount,
  useUser,
  useUserAccounts,
  useToggleAccount,
} from "dashboard/hooks/atom-hooks";
import { DateTime } from "luxon";
import { useFetchCompanyLogoUrl } from "dashboard/hooks/useFetchCompanyLogoUrl";
import { useHasCompanyCreationAccess } from "dashboard/gating";
import { Account } from "dashboard/miter";

const RoleToggler: React.FC = () => {
  // Hooks
  const navigate = useNavigate();
  const { setShowUserSettingsModal } = React.useContext(AppContext);
  const user = useUser();
  const userAccounts = useUserAccounts();
  const activeCompany = useActiveCompany();
  const activeAccount = useActiveAccount();
  const toggleAccount = useToggleAccount();
  const companyLogoUrl = useFetchCompanyLogoUrl(activeCompany);
  const hasCompanyCreationAccess = useHasCompanyCreationAccess();

  // State functions
  const [switchingTo, setSwitchingTo] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>("");

  // Memoized functions and variables
  const filteredAccounts = useMemo(() => {
    const filteredTMs = matchSorter(userAccounts, searchFilter, {
      keys: ["company_name", "company_object.check_company.legal_name", "company_object._id"],
      // Sort alphabetically with the exception of the active team member which should be first
      baseSort: (a, b) => {
        if (a.item._id === activeAccount?._id) return -1;
        if (b.item._id === activeAccount?._id) return 1;
        return a.item?.company_name.localeCompare(b.item?.company_name);
      },
    });

    return filteredTMs;
  }, [userAccounts, searchFilter]);

  const companyName = useMemo(() => {
    return activeCompany
      ? activeCompany?.check_company.trade_name || activeCompany?.check_company.legal_name
      : "";
  }, [activeCompany]);

  // Event handlers
  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
      setSearchFilter("");
    } else {
      setIsOpen(true);
    }
  };

  const handleActiveAccountToggle = async (account: Account) => {
    setSwitchingTo(account._id);
    await toggleAccount({ new_account_id: account._id });
    Notifier.success("You have successfully switched accounts.");
    setSwitchingTo("");
    navigate("/home");
  };

  const handleSearch = (searchTerm: string | undefined) => {
    setSearchFilter(searchTerm || "");
  };

  return (
    <>
      <div className={`${styles["role-toggler-wrapper"]} ${companyLogoUrl ? styles["with-logo"] : ""}`}>
        <div className="pointer current-role flex" onClick={() => toggle()}>
          <div className="current-role-text flex">
            <div className={`active-company-name-wrapper ${styles["active-company-name-wrapper"]} flex`}>
              {companyLogoUrl ? (
                <img src={companyLogoUrl} alt="Company logo" className={styles["company-logo-img"]} />
              ) : null}
              <span className="active-company-name">{shorten(companyName, 17)}</span>
            </div>
            <img className="down-arrow" alt="Down arrow" src={downArrow} />
          </div>
        </div>
        {isOpen && (
          <ClickAwayListener onClickAway={() => toggle()}>
            <div className="company-selector flex-column">
              <div className="company-selector-top-wrapper">
                <div className="role-list-email flex">{<span>{user?.email || ""}</span>}</div>
                {userAccounts.length > 5 && (
                  <div className="company-roles-search">
                    <input
                      className={`form2-text ${styles["roles-filter"]}`}
                      type="text"
                      placeholder="Search accounts"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                )}
                <div className="company-selector-list">
                  {filteredAccounts.map((account) => {
                    return (
                      <div
                        className={
                          "role-toggle-option pointer " +
                          (account._id === activeAccount?._id ? "selected-role" : "")
                        }
                        onClick={() => handleActiveAccountToggle(account)}
                        key={account._id}
                      >
                        <div className="role-company-name flex align-items-center">
                          {account.company_name}
                          {account._id === activeAccount?._id && (
                            <img className="check-mark" alt="Check mark" src={checkMark} />
                          )}

                          {account._id === switchingTo && (
                            <Loader className="small-text" style={{ marginLeft: 5 }} />
                          )}
                        </div>
                        <div className="flex align-items-center">
                          {user?.miter_admin && (
                            <Badge
                              className="no-margin role-badge "
                              color="blue"
                              text={account.type === "role" ? "External Role" : "Team Member"}
                              style={{ marginTop: 3, marginBottom: 3 }}
                            />
                          )}
                          {account.team_member?.end_date &&
                            account.team_member?.end_date < DateTime.now().toISODate() && (
                              <Badge className="role-badge " color="yellow" text={"Dismissed"} />
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="company-selector-bottom-wrapper">
                <a className="bottom-link pointer flex" onClick={() => setShowUserSettingsModal(true)}>
                  Manage account
                </a>
              </div>
              {hasCompanyCreationAccess && (
                <div className="company-selector-bottom-wrapper">
                  <Link className="bottom-link pointer flex" to="/companies/new">
                    Create a new company
                  </Link>
                </div>
              )}
              <div className="company-selector-bottom-wrapper">
                <div className="bottom-link pointer flex" onClick={() => navigate("/logout")}>
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  );
};

export default RoleToggler;
