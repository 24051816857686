import React, { useMemo, useState } from "react";
import PayrollContext from "../payrollContext";
import { formatDate, getPayrollTypeString } from "dashboard/utils/utils";
import { Badge, Formblock } from "ui";
import PayrollTotals from "../PayrollTotals";
import { DateTime } from "luxon";
import { ReviewCheckPayments } from "../ReviewCheckPayments";
import EditIcon from "dashboard/assets/edit.png";
import { EditPayrollLabelModal } from "../EditPayrollLabelModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { cleanPayScheduleLabel } from "../viewPayrollUtils";
import Banner from "dashboard/components/shared/Banner";
import { CheckPayrollStatus } from "backend/utils/check/check-types";

const NonDraftPayrollSummary: React.FC<{ status: CheckPayrollStatus }> = ({ status }) => {
  const { payroll } = React.useContext(PayrollContext);
  const { can } = useMiterAbilities();
  const [editingLabel, setEditingLabel] = useState(false);

  const nextStatusBanner = useMemo(() => {
    const checkPayroll = payroll?.check_payroll;
    if (checkPayroll?.status !== "pending" || !payroll?.check_payroll?.approval_deadline) return null;
    const changeToProcessing = DateTime.fromISO(payroll.check_payroll.approval_deadline).toFormat(
      "t ZZZZ 'on' DD"
    );
    return (
      <div>
        <Banner type="info" style={{ marginBottom: -10 }}>
          This payroll will automatically move to <b>Processing</b> at {changeToProcessing}.
        </Banner>
        <div className="vertical-spacer" />
      </div>
    );
  }, [payroll?.check_payroll]);

  const keyInfo = useMemo(() => {
    if (!payroll) return null;
    const checkPayroll = payroll.check_payroll;

    const approvalTime = checkPayroll?.approved_at
      ? DateTime.fromISO(checkPayroll.approved_at).toLocaleString({
          hour: "numeric",
          minute: "2-digit",
          timeZoneName: "short",
        })
      : "";

    const statusBadge = (
      <>
        <div className="flex">
          <Badge style={{ marginLeft: 0 }} text={status} />
          {checkPayroll.is_void && <Badge text={"void"} className="no-margin" color="light-gray" />}
          {checkPayroll.status === "partially_paid" && (
            <Badge text={"partial"} className="no-margin" color="yellow" />
          )}
        </div>
      </>
    );

    return (
      <div className="flex">
        <div className="width-100">
          <Formblock
            type="text"
            className="tiny-margin"
            label="Pay period"
            defaultString={formatDate(checkPayroll.period_start, checkPayroll.period_end, true)}
          />
          <Formblock
            type="text"
            className="tiny-margin"
            label="Type"
            defaultString={getPayrollTypeString(payroll)}
          />
          <Formblock type="text" className="tiny-margin" label="Status" defaultString={statusBadge} />
        </div>
        <div style={{ width: "75px" }} />
        <div className="width-100">
          <Formblock
            type="text"
            className="tiny-margin"
            label="Payday"
            defaultString={formatDate(checkPayroll.payday, undefined, true)}
          />
          <Formblock
            type="text"
            className="tiny-margin"
            label="Approved at"
            defaultString={approvalTime + " on " + formatDate(checkPayroll.approved_at, undefined, true)}
          />
          {payroll.pay_schedule && (
            <Formblock
              type="text"
              className="tiny-margin"
              label="Pay schedule"
              defaultString={cleanPayScheduleLabel(payroll.pay_schedule.label)}
            />
          )}
          {payroll.type !== "regular" && (
            <Formblock type="text" className="tiny-margin" label="Label">
              <div className="flex">
                {payroll.label || "(blank)"}
                {can("payrolls:update") && (
                  <img src={EditIcon} className="formblock-edit-icon" onClick={() => setEditingLabel(true)} />
                )}
              </div>
            </Formblock>
          )}
        </div>
      </div>
    );
  }, [payroll]);

  return (
    <div>
      <div className="mini-sidebar-page-active-view-header">Payroll summary</div>
      {nextStatusBanner}
      {keyInfo}
      <div className="vertical-spacer-large" />
      <div className="mini-sidebar-page-active-view-header">Liability summary</div>
      <PayrollTotals />
      {editingLabel && <EditPayrollLabelModal setEditingLabel={setEditingLabel} />}
      {(payroll?.has_ever_failed ||
        payroll?.check_payroll.status === "failed" ||
        payroll?.check_payroll.funding_payment_method === "wire") && (
        <>
          <div className="vertical-spacer-large" />
          <div className="mini-sidebar-page-active-view-header" style={{ marginBottom: "-5px" }}>
            Funding
          </div>
          <ReviewCheckPayments entity={payroll.check_payroll} />
          <div className="vertical-spacer-large" />
        </>
      )}
    </div>
  );
};

export default NonDraftPayrollSummary;
