/* eslint-enable */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React, { useEffect, useMemo, useState } from "react";
import { Button, Formblock, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { Props } from "./types";
import useWizard from "ui/modal/useWizard";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { capitalize } from "lodash";
import { CreateRawBankAccountParams } from "../../../backend/services/bank-accounts/bank-accounts-service";
import { MiterAPI } from "dashboard/miter";
import * as vals from "dashboard/utils/validators";
import PlaidLink from "dashboard/components/banking/PlaidLink";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { getPlaidLinkToken } from "dashboard/utils/expenses";
import { Option } from "ui/form/Input";

type PaymentWizardScreenProps = Props & {
  isPayroll?: boolean;
};

type PaymentInfoForm = {
  account_number: string;
  routing_number: string;
  account_subtype: Option<"checking" | "savings">;
  payment_preference?: Option<string>;
};

export const PaymentWizardScreen: React.FC<PaymentWizardScreenProps> = ({
  task,
  name,
  onboardingChecklistItem,
  isPayroll,
}) => {
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation<$TSFixMe>();
  const { control, errors, register, handleSubmit, watch, trigger } = useForm<PaymentInfoForm>();
  const formData = watch();

  const [loadingPlaid, setLoadingPlaid] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState<string>();

  const [plaidSuccessData, setPlaidSuccessData] = useState<{
    public_token: string;
    metadata: PlaidLinkOnSuccessMetadata;
  }>();

  const accountSubtypeOptions = useMemo(
    () => [
      { label: capitalize(t("checking")), value: "checking" },
      { label: capitalize(t("savings")), value: "savings" },
    ],
    [t]
  );

  useEffect(() => {
    trigger();
  }, [isPayroll]);

  const taskComplete =
    onboardingChecklistItem.statuses.find((status) => status.task_id === task._id)?.status === "complete";

  // TODO: change taskComplete
  useEffect(() => {
    const isFormComplete = !!(formData.account_number && formData.routing_number && formData.account_subtype);
    setCanNext(!!plaidSuccessData || (isFormComplete && !Object.keys(errors).length) || taskComplete);
  }, [plaidSuccessData, errors, formData, taskComplete]);

  const companyId = onboardingChecklistItem.company_id;
  const teamMemberId = onboardingChecklistItem.new_hire?._id;

  const createNewRawBankAccount = async (data: PaymentInfoForm) => {
    const cleanedData: CreateRawBankAccountParams = {
      full_account_number: data.account_number,
      routing_number: data.routing_number?.padStart(9, "0"),
      account_subtype: data.account_subtype.value as "checking" | "savings",
      company_id: onboardingChecklistItem.company_id,
      team_member_id: onboardingChecklistItem.new_hire._id,
      external_financial_account_type: "team_member",
    };

    try {
      const response = await MiterAPI.bank_accounts.create(cleanedData);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success(t("Bank account created."));
    } catch (err) {
      Notifier.error(t("Could not create bank account."));
      throw err;
    }
  };

  // saves new Plaid account(s) to backend
  const createNewPlaidbankAccount = async () => {
    if (!plaidSuccessData) return;

    try {
      const { public_token, metadata } = plaidSuccessData;

      const res = await MiterAPI.banking.plaid.connect_accounts({
        company: companyId,
        team_member_id: onboardingChecklistItem.new_hire._id,
        external_financial_account_type: onboardingChecklistItem.new_hire._id ? "team_member" : "company",
        public_token,
        metadata,
      });

      // // this will throw if there was an error for the Plaid item or earlier steps, not for individual accounts
      if (res.error) throw new Error(res.error);
      for (const connectAccountResult of res) {
        const last4 = connectAccountResult.account?.mask;
        if (connectAccountResult.error) {
          Notifier.error(`Account ending in ${last4} wasn't connected to Miter.`);
        } else {
          Notifier.success(`Account ending in ${last4} connected to Miter.`);
        }
      }
    } catch (err) {
      Notifier.error("Could not connect account to Miter.");
      throw err;
    }
  };

  const renderPlaidSuccessData = () => {
    if (!plaidSuccessData) return;

    return (
      <div>
        <h4>Linked accounts from {plaidSuccessData.metadata.institution?.name}</h4>
        {/* can link multiple accounts from the same item! ex. one checking, one savings */}
        {plaidSuccessData.metadata.accounts.map((account) => (
          <p key={account.id}>
            {account.name} ····{account.mask}
          </p>
        ))}
      </div>
    );
  };

  const renderManualEntryForm = () => {
    return (
      <div className="margin-top-15">
        <Formblock
          label={t("Account number")}
          type="text"
          name="account_number"
          className="modal"
          editing={true}
          control={control}
          errors={errors}
          register={register(vals.isValidBankAccountNumber)}
          onChange={(_e) => {
            trigger();
          }}
        />
        <Formblock
          label={t("Routing number")}
          type="text"
          name="routing_number"
          className="modal"
          editing={true}
          control={control}
          errors={errors}
          register={register(vals.isValidBankRoutingNumber)}
          onChange={(_e) => {
            trigger();
          }}
        />
        <Formblock
          label={t("Type")}
          type="select"
          name="account_subtype"
          className="modal"
          options={accountSubtypeOptions}
          editing={true}
          control={control}
          errors={errors}
          onChange={(_e) => {
            trigger();
          }}
          requiredSelect
        />
      </div>
    );
  };

  const createAccount = async () => {
    plaidSuccessData ? createNewPlaidbankAccount() : handleSubmit(createNewRawBankAccount)();

    if (formData.payment_preference?.value) {
      try {
        const res = await MiterAPI.team_member.update(onboardingChecklistItem.new_hire._id, {
          payment_method_preference: formData.payment_preference.value as "manual" | "direct_deposit",
        });
        if (res.error) {
          throw new Error(res.error);
        }
      } catch (err) {
        Notifier.error("Could not update payment preference.");
        throw err;
      }
    }

    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  const generateCompletionMessage = () => {
    return isPayroll ? "Payment information is already complete." : "Bank account is already created.";
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={createAccount}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        {taskComplete ? (
          generateCompletionMessage()
        ) : (
          <div>
            {isPayroll && (
              <Formblock
                label={"Payment preference"}
                type="select"
                options={[
                  {
                    label: "Direct deposit",
                    value: "direct_deposit",
                  },
                  {
                    label: "Paper check",
                    value: "manual",
                  },
                ]}
                name="payment_preference"
                className="modal"
                editing={true}
                control={control}
                errors={errors}
                onChange={(_e) => {
                  trigger();
                }}
                requiredSelect={true}
              />
            )}
            <>
              {plaidSuccessData ? (
                renderPlaidSuccessData()
              ) : (
                <div style={{ marginLeft: -5 }} className="margin-top-25">
                  <Button
                    onClick={() =>
                      getPlaidLinkToken({
                        company: companyId,
                        external_financial_account_type: teamMemberId ? "team_member" : "company",
                        setLoading: setLoadingPlaid,
                        setPlaidLinkToken,
                        products: ["auth"],
                      })
                    }
                    text={t("Connect with Plaid")}
                    loading={loadingPlaid}
                    className="button-2"
                  />
                </div>
              )}
              {!plaidSuccessData && (
                <div className="pointer margin-top-15 color-gray" onClick={() => setOpen(!open)}>
                  <div className="flex space-between width-100-percent margin-right-15">
                    <p>{t("Or add manually")}</p>
                    {open ? (
                      <FaChevronUp className="font-size-12" />
                    ) : (
                      <FaChevronDown className="font-size-12" />
                    )}
                  </div>
                </div>
              )}
              {open && !plaidSuccessData && <>{renderManualEntryForm()}</>}
            </>
            {plaidLinkToken && (
              <PlaidLink
                token={plaidLinkToken}
                onSuccess={(public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
                  // save this data for later, to be saved when user presses "submit"
                  setPlaidSuccessData({ public_token, metadata });
                }}
                onExit={() => setPlaidLinkToken(undefined)}
              />
            )}
          </div>
        )}
      </div>
    </WizardScreen>
  );
};
