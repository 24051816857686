import AppContext from "dashboard/contexts/app-context";
import { Company, MiterAPI } from "dashboard/miter";
import { Notifier, buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Formblock, SettingsCard } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { CompanyLedgerEntrySettings } from "backend/models/company/company-settings";
import {
  useActiveCompany,
  useDepartmentOptions,
  useLedgerAccountOptions,
  useLocationOptions,
} from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { MappingHierarchyLevel } from "backend/utils/accounting";
import { DeepPartial } from "utility-types";

export const AccountingConfiguration: React.FC = () => {
  // HOOKS
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id || null;
  const ledgerAccountOptions = useLedgerAccountOptions();
  const deptOptions = useDepartmentOptions();
  const locationOptions = useLocationOptions();
  const form = useForm();
  const settings = activeCompany?.settings.ledger_entries || {};

  const defaultMappingHierarchy = useMemo(() => {
    const arr: MappingHierarchyLevel[] = activeCompany?.settings.ledger_entries?.mapping_hierarchy || [];
    if (!arr.includes("company")) arr.push("company");
    return arr;
  }, [activeCompany]);

  // State
  const [mappingHierarchy, setMappingHierarchy] = useState<MappingHierarchyLevel[]>(defaultMappingHierarchy);

  const hierarchyOptionsSorted = useMemo(() => {
    const opts = hierarchyOptions.slice();
    const positionMap = new Map(mappingHierarchy.map((v, i) => [v, i]));
    opts.sort((a, b) => {
      const aIndex = positionMap.get(a.value as MappingHierarchyLevel) ?? -1;
      const bIndex = positionMap.get(b.value as MappingHierarchyLevel) ?? -1;
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
    return opts.map((o, i) => ({ ...o, label: `${i + 1}. ${o.label}` }));
  }, [mappingHierarchy]);

  const sortedHierarchy = useMemo(() => {
    return hierarchyOptionsSorted.filter((o) => mappingHierarchy.includes(o.value as MappingHierarchyLevel));
  }, [hierarchyOptionsSorted, mappingHierarchy]);

  // Effects
  useEffect(() => {
    setMappingHierarchy(defaultMappingHierarchy);
  }, [defaultMappingHierarchy]);

  // Change handlers
  const updateCompanySettings = async (params: Record<string, $TSFixMe>) => {
    if (!activeCompanyId) return;
    try {
      const response = await MiterAPI.companies.update(activeCompanyId, params);
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating your settings. Our engineers are looking into it!");
    }
  };

  const updateLedgerSettings = async (update: DeepPartial<CompanyLedgerEntrySettings>) => {
    const raw: DeepPartial<Company> = { settings: { ledger_entries: update } };
    const flattened = buildAtomicMongoUpdateFromNested(raw);
    await updateCompanySettings(flattened);
  };

  // DON'T USE THIS DEBOUNCED CALLBACK FOR MORE THAN ONE SETTING! THIS ONE IS ONLY FOR THE HIERARCHY SETTING.
  const updateLedgerSettingsForHierarchy = useDebouncedCallback(updateLedgerSettings, 1500);

  const handleHierarchyChange = async (newHierarchy: Option<string>[] | null) => {
    const newHierarchyValues = newHierarchy?.map((o) => o.value as MappingHierarchyLevel) || [];
    setMappingHierarchy(newHierarchyValues.slice());
    if (!newHierarchyValues.includes("company")) newHierarchyValues.push("company");
    await updateLedgerSettingsForHierarchy({ mapping_hierarchy: newHierarchyValues });
  };

  const fieldHeight = 36;
  const fieldWidth = 300;

  return (
    <>
      <SettingsCard title="General">
        <div>
          <Formblock
            labelStyle={{ minWidth: fieldWidth }}
            style={{ height: fieldHeight }}
            label="Mapping hierarchy"
            labelInfo="In what order should Miter determine the GL account for a line item of a GL entry? The 'Company' level is required."
            name="hierarchy"
            type="multiselect"
            height="auto"
            defaultValue={defaultMappingHierarchy}
            options={hierarchyOptionsSorted}
            editing={true}
            form={form}
            value={sortedHierarchy}
            onChange={handleHierarchyChange}
          />
        </div>
      </SettingsCard>
      <SettingsCard title="Payroll">
        <div style={{ maxWidth: 500 }}>
          <Formblock
            labelStyle={{ minWidth: fieldWidth }}
            style={{ height: fieldHeight }}
            type="checkbox"
            label="Include workers' comp"
            labelInfo={"Whether GL entries should include expenses and liabilities for workers' comp."}
            form={form}
            editing={false}
            locked={true}
            inputProps={{ style: { alignItems: "start" } }}
          >
            <input
              type="checkbox"
              onChange={(e) => updateLedgerSettings({ payrolls: { ignore_workers_comp: !e.target.checked } })}
              defaultChecked={!settings.payrolls?.ignore_workers_comp}
            />
          </Formblock>
          <Formblock
            labelStyle={{ minWidth: fieldWidth }}
            style={{ height: fieldHeight }}
            type="checkbox"
            label="Include imputed earnings"
            labelInfo={
              "Whether GL entries should include lines for imputed earnings. These lines always come in pairs and cancel each other out because they are non-cash earnings and deducted from gross pay."
            }
            form={form}
            editing={false}
            locked={true}
            inputProps={{ style: { alignItems: "start" } }}
          >
            <input
              type="checkbox"
              onChange={(e) =>
                updateLedgerSettings({ payrolls: { include_imputed_earnings: e.target.checked } })
              }
              defaultChecked={settings.payrolls?.include_imputed_earnings}
            />
          </Formblock>
          <Formblock
            labelStyle={{ minWidth: fieldWidth }}
            style={{ height: fieldHeight }}
            type="checkbox"
            label="Use pay period end for posted date"
            labelInfo={
              "If unchecked, Miter will use the payroll payday, or the created date for voids, as the post date."
            }
            form={form}
            editing={false}
            locked={true}
            inputProps={{ style: { alignItems: "start" } }}
          >
            <input
              type="checkbox"
              onChange={(e) =>
                updateLedgerSettings({
                  payrolls: { use_pay_period_end_date_for_posted_at: e.target.checked },
                })
              }
              defaultChecked={!!settings.payrolls?.use_pay_period_end_date_for_posted_at}
            />
          </Formblock>
          <Formblock
            labelStyle={{ minWidth: fieldWidth }}
            name="bank_account_department"
            style={{ height: fieldHeight }}
            label="Bank account department"
            labelInfo="Optional. Choose a default department for the cash requirement and receivables (e.g., tax refunds)."
            type="select"
            options={deptOptions}
            form={form}
            editing={true}
            isClearable
            defaultValue={settings.payrolls?.bank_account_department}
            onChange={(o) =>
              updateLedgerSettings({ payrolls: { bank_account_department: o?.value || null } })
            }
          />
          <Formblock
            labelStyle={{ minWidth: fieldWidth }}
            name="bank_account_location"
            style={{ height: fieldHeight }}
            label="Bank account location"
            labelInfo="Optional. Choose a default location for the cash requirement and receivables (e.g., tax refunds)."
            type="select"
            options={locationOptions}
            form={form}
            editing={true}
            isClearable
            defaultValue={settings.payrolls?.bank_account_location}
            onChange={(o) => updateLedgerSettings({ payrolls: { bank_account_location: o?.value || null } })}
          />
          <Formblock
            labelStyle={{ minWidth: fieldWidth }}
            style={{ height: fieldHeight }}
            label="Fallback benefit account"
            labelInfo="If any benefits have the 'include with earning' account option, Miter requires a fallback account to use for contributions that cannot be tied back to a specific earning."
            type="select"
            options={ledgerAccountOptions}
            form={form}
            editing={true}
            isClearable
            defaultValue={settings.payrolls?.fallback_benefit_account}
            onChange={(o) =>
              updateLedgerSettings({ payrolls: { fallback_benefit_account: o?.value || null } })
            }
          />
        </div>
      </SettingsCard>
    </>
  );
};

const hierarchyOptions: Option<string>[] = [
  { label: "Company", value: "company" },
  { label: "Department", value: "department" },
  { label: "Team member", value: "team_member" },
  { label: "Job", value: "job" },
  { label: "Activity", value: "activity" },
  { label: "Location", value: "location" },
];
