import { MiterAPI } from "../../miter";
import Notifier from "../..//utils/notifier";
import React, { useEffect, useState } from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import { useNavigate } from "react-router-dom";
import styles from "./auth.module.css";
import { Loader } from "ui";
import { Helmet } from "react-helmet";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import { useQuery } from "dashboard/utils";
import {
  useAuthToken,
  useSetAuthenticatedUserData,
  useSetUserFetched,
  useStoredActiveAccountId,
} from "dashboard/hooks/atom-hooks";

const Authenticate: React.FC = () => {
  const navigate = useNavigate();
  const token = useQuery().get("token");

  const setUserFetched = useSetUserFetched();
  const setAuthenticatedUserData = useSetAuthenticatedUserData();

  const storedActiveAccountId = useStoredActiveAccountId();

  const authToken = useAuthToken();

  const [failed, setFailed] = useState<boolean>(false);

  useEffect((): void => {
    if (authToken) return;

    if (!token) {
      Notifier.error("Magic link invalid. Please try to login again");
      setAuthenticatedUserData(null);
      navigate("/login", { replace: true });
      return;
    }

    authenticateUser(token);
  }, [token]);

  const authenticateUser = async (token: string): Promise<void> => {
    try {
      const res = await MiterAPI.users.authenticate(token, storedActiveAccountId || undefined);
      setUserFetched(true);

      if (res.error) throw new Error(res.error);
      if (!res.auth_token || typeof res.auth_token !== "string") throw new Error("Invalid auth token");

      setAuthenticatedUserData(res);

      const guidesRedirectPath = localStorage.getItem("guidesRedirect");

      // need this in a setTimeout because we need authToken to get set first, before we can go to the guide
      setTimeout(() => {
        if (guidesRedirectPath != null) {
          localStorage.removeItem("guidesRedirect");
          goToMiterGuide(guidesRedirectPath, true);
        } else {
          // Reload the page so we know they're getting the latest version of Miter
          window.location.reload();
        }
      }, 1);

      // We have to use local storage instead of AppContext because magic links open in a new tab
      const loginRedirectPath = localStorage.getItem("redirect");

      if (loginRedirectPath) {
        localStorage.removeItem("redirect");
        navigate(loginRedirectPath, { replace: true });
      } else {
        navigate("/home", { replace: true });
      }
    } catch (e: $TSFixMe) {
      setFailed(true);
      setAuthenticatedUserData(null);
      Notifier.error(e.message);
      navigate("/login", { replace: true });
    }
  };

  return (
    <div className={styles["login-wrapper"]}>
      <Helmet>
        <title>Login | Miter</title>
      </Helmet>
      <div className={styles["login-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Logging into Miter</h1>
            <p>Please wait as we redirect you.</p>
          </div>
          {!failed && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Authenticate;
