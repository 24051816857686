import { useActiveTeam, useCrews, useDepartments } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, TeamMemberGroupType } from "dashboard/miter";
import { capitalize } from "lodash";
import { isMiterRep } from "miter-utils";
import { useMemo } from "react";
import { Option } from "ui/form/Input";

type Props = {
  baseFilterPredicate?: (tm: AggregatedTeamMember) => boolean;
  selectedGroupOptions?: Option<TeamMemberGroupSelectValue>[];
  selectedGroups?: TeamMemberGroupSelectValue[];
  excludedGroups?: TeamMemberGroupType[];
  excludedTypes?: TeamMemberGroupType[];
  hideMitosaurs?: boolean;
  labelOverrides?: Record<string, string>;
};

export type TeamMemberOptionGroup = {
  label: string;
  options: Option<TeamMemberGroupSelectValue>[];
};

export type TeamMemberGroupSelectValue = { type: TeamMemberGroupType; value?: string };

export const allTeamMembersGroup: TeamMemberGroupSelectValue = {
  type: "all_team_members" as const,
  value: "all_team_members",
};

export const useTeamMemberGroupOptions = ({
  baseFilterPredicate,
  selectedGroupOptions,
  excludedGroups = [],
  excludedTypes = [],
  selectedGroups,
  hideMitosaurs,
  labelOverrides,
}: Props): TeamMemberOptionGroup[] => {
  const activeTeamMembers = useActiveTeam();
  const departments = useDepartments();
  const crews = useCrews();

  const selectableTeam = useMemo(() => {
    const filteredTeamMembers = activeTeamMembers.filter((tm) => {
      if (hideMitosaurs) return !isMiterRep(tm);
      return true;
    });

    return filteredTeamMembers;
  }, [activeTeamMembers, hideMitosaurs]);

  // Build a set of selected groups for quick lookup to determine if a group is available to be selected
  const selectedGroupsSet = useMemo(() => {
    const set = new Set<string>();
    if (selectedGroups) {
      selectedGroups.forEach((group) => {
        set.add(buildSetKey(group.type, group.value));
      });
    } else if (selectedGroupOptions) {
      selectedGroupOptions.forEach((group) => {
        set.add(buildSetKey(group.value.type, group.value.value));
      });
    }

    return set;
  }, [selectedGroupOptions, selectedGroups, labelOverrides]);

  // Check if a group is available to be selected (i.e. hasn't already been selected)
  const isAvailable = (type: TeamMemberGroupType, group: Option<TeamMemberGroupSelectValue>) => {
    const key = buildSetKey(type, group.value?.value);
    return !selectedGroupsSet.has(key);
  };

  // Check if a group is available to be selected (i.e. hasn't already been selected) - used for direct manager, crew lead, and job supervisor
  const isOtherAvailable = (type: TeamMemberGroupType) => {
    const key = buildSetKey(type, type);
    return !selectedGroupsSet.has(key) && !excludedGroups.includes(type);
  };

  const teamOptions = useMemo(() => {
    const filteredTeamMembers = baseFilterPredicate
      ? selectableTeam.filter(baseFilterPredicate)
      : selectableTeam;

    const departmentOptions = departments
      .map((department) => ({
        label: department.name,
        value: { type: "department" as const, value: department._id },
      }))
      .filter((department) => isAvailable("department", department));

    const teamMemberOptions = filteredTeamMembers
      ?.map((tm) => ({
        label: tm.full_name,
        value: { type: "team_member" as const, value: tm._id },
      }))
      .filter((tm) => isAvailable("team_member", tm));

    const selfOptions = [
      ...(isOtherAvailable("self") && !excludedTypes.includes("self")
        ? [
            {
              label: labelOverrides?.["self"] || "Recipient",
              value: { type: "self" as const, value: "self" },
            },
          ]
        : []),
      ...(isOtherAvailable("direct_manager") && !excludedTypes.includes("direct_manager")
        ? [
            {
              label: labelOverrides?.["direct_manager"] || "Recipient's manager",
              value: { type: "direct_manager" as const, value: "direct_manager" },
            },
          ]
        : []),
    ];

    const titles = filteredTeamMembers.reduce((acc, tm) => {
      const title = tm.title;
      if (!acc.includes(title)) {
        acc.push(title);
      }
      return acc;
    }, [] as string[]);

    const titleOptions = titles
      .map((title) => ({
        label: capitalize(title),
        value: { type: "title" as const, value: title },
      }))
      .filter((title) => isAvailable("title", title));

    const employementTypes = filteredTeamMembers.reduce((acc, tm) => {
      const type = tm.employment_type;
      if (!acc.includes(type)) {
        acc.push(type);
      }
      return acc;
    }, [] as string[]);

    const employmentTypeOptions = employementTypes
      .map((type) => ({
        label: capitalize(type),
        value: { type: "employment_type" as const, value: type },
      }))
      .filter((employmentType) => isAvailable("employment_type", employmentType));

    const payTypes = [
      { label: "Hourly (non-union)", value: { type: "pay_type" as const, value: "hourly_non_union" } },
      { label: "Hourly (union)", value: { type: "pay_type" as const, value: "hourly_union" } },
      { label: "Hourly (all)", value: { type: "pay_type" as const, value: "hourly_all" } },
      { label: "Salary", value: { type: "pay_type" as const, value: "salary" } },
    ];

    const payTypeOptions = payTypes.filter((type) => isAvailable("pay_type", type));

    const otherGroupOptions = [
      ...(isOtherAvailable("job_supervisor")
        ? [{ label: "Job Supervisors", value: { type: "job_supervisor" as const, value: "job_supervisor" } }]
        : []),
      ...(isOtherAvailable("job_superintendent")
        ? [
            {
              label: "Job Superintendents",
              value: { type: "job_superintendent" as const, value: "job_superintendent" },
            },
          ]
        : []),
      ...(isOtherAvailable("direct_managers")
        ? [
            {
              label: "Direct Managers",
              value: { type: "direct_managers" as const, value: "direct_managers" },
            },
          ]
        : []),
      ...(isOtherAvailable("crew_lead")
        ? [{ label: "Crew Leads", value: { type: "crew_lead" as const, value: "crew_lead" } }]
        : []),
    ];

    const allTeamMembersOptions = [{ label: "All Team Members", value: allTeamMembersGroup }].filter(
      (group) => isAvailable("all_team_members", group)
    );

    const groups: TeamMemberOptionGroup[] = [];

    if (!excludedGroups.includes("all_team_members")) {
      groups.push({ label: "All Team Members", options: allTeamMembersOptions });
    }

    if (!excludedGroups.includes("self")) {
      groups.push({
        label: labelOverrides?.["self"] || "Recipient",
        options: selfOptions || [],
      });
    }

    groups.push({ label: "General Groups", options: otherGroupOptions });

    if (!excludedGroups.includes("department")) {
      groups.push({ label: "Departments", options: departmentOptions });
    }

    if (!excludedGroups.includes("employment_type")) {
      groups.push({ label: "Employment Types", options: employmentTypeOptions });
    }

    if (!excludedGroups.includes("title")) {
      groups.push({ label: "Titles", options: titleOptions });
    }

    if (!excludedGroups.includes("pay_type")) {
      groups.push({ label: "Pay Types", options: payTypeOptions });
    }

    if (!excludedGroups.includes("team_member")) {
      groups.push({ label: "Team Members", options: teamMemberOptions || [] });
    }

    return groups;
  }, [selectableTeam, departments, selectedGroupsSet, crews, excludedGroups, labelOverrides]);

  return teamOptions;
};

const buildSetKey = (type: TeamMemberGroupType, value?: string) => {
  return type + "-" + (value || "");
};
