import duplicateIcon from "dashboard/assets/duplicate.png";
import trashIcon from "dashboard/assets/trash.png";
import {
  useLookupActivity,
  useActivityOptions,
  useLookupTeam,
  useClassificationOptions,
  useActiveCompany,
  useWcCodeOptions,
  usePolicies,
  useLookupTimeOffPolicy,
  useEquipmentOptions,
} from "dashboard/hooks/atom-hooks";
import { checkTmAndActivityLicenseMatch, roundTo } from "dashboard/utils";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useMemo, useRef } from "react";
import Select, { ActionMeta, components, ValueContainerProps, ValueType } from "react-select";
import ReactTooltip from "react-tooltip";
import { DateTimeInput, ImageWithTooltip, SelectOption } from "ui";
import { Option } from "ui/form/Input";
import { DraftTimesheet } from "./BulkCreateTimesheets";
import styles from "./BulkCreateTimesheets.module.css";
import { bulkTimesheetSelectStyles, equipmentSelectStyles } from "./SelectStyles";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { useTimesheetPolicy } from "dashboard/utils/policies/timesheet-policy-utils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import ObjectID from "bson-objectid";
import { PayRateItem } from "backend/utils/payroll/types";
import { getEarningTypeOptionsForTmFlattened } from "../TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { TimesheetEarningType } from "backend/models/timesheet";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

type Props = {
  index: number;
  draftTimesheets: DraftTimesheet[];
  setDraftTimesheets: (drafts: DraftTimesheet[]) => void;
  clockOutNotHours: boolean;
  defaultClockInTime: string;
  defaultClockOutTime: string;
  teamOptions: Option<string>[];
  jobOptions: Option<string>[];
  costTypeOptions: Option<string>[];
  payRatesMap: Record<string, PayRateItem>;
};

export const BulkCreateTimesheetsRow: React.FC<Props> = ({
  index,
  draftTimesheets,
  setDraftTimesheets,
  clockOutNotHours,
  defaultClockInTime,
  defaultClockOutTime,
  teamOptions,
  jobOptions,
  costTypeOptions,
  payRatesMap,
}) => {
  const timesheet = draftTimesheets[index];
  /****************************************************************
   * Important hooks
   ****************************************************************/
  const ref = useRef(null);
  const activeCompany = useActiveCompany();
  const lookupActivity = useLookupActivity();
  const lookupTeam = useLookupTeam();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const policies = usePolicies();
  const equipmentOptions = useEquipmentOptions();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const enableAdminWcCode = activeCompany?.settings?.timesheets.enable_admin_wc_code;
  const showPayRate = activeCompany?.settings?.timesheets?.show_pay_rate_in_bulk_create;
  const showEarningType = activeCompany?.settings.timesheets.always_display_earning_types;

  const abilities = useMiterAbilities();
  const canReadWcCode = abilities.can("lists:workers_comp_codes:read");

  const hasAdvancedBreakTime = activeCompany?.settings.timesheets.advanced_break_time;
  const timesheetDashboardFieldSettings = activeCompany?.settings.timesheets.field_requirements.dashboard;

  const hasTimesheetPoliciesEnabled = useMemo(
    () => policies.filter((policy) => policy.type === "timesheet").length > 0,
    [policies]
  );

  const {
    job: jobFieldRequirement,
    activity: activityFieldRequirement,
    classification: classificationFieldRequirement,
    cost_type_id: costTypeFieldRequirement,
    notes: notesFieldRequirement,
    equipment_ids: equipmentFieldRequirement,
  } = hasTimesheetPoliciesEnabled
    ? BULK_ENTRY_COLUMNS
    : timesheetDashboardFieldSettings || BULK_ENTRY_COLUMNS;

  const { isFieldRequired, isFieldHidden } = useTimesheetPolicy(timesheet);

  const wcCodeOptions = useWcCodeOptions();
  const activityOptions = useActivityOptions(timesheet?.job, {
    predicate: isTimesheetScoped,
  });
  const classificationOptions = useClassificationOptions({
    tmId: timesheet?.team_member,
    activityId: timesheet?.activity || undefined,
    jobId: timesheet?.job,
    opts: {
      includeCustomOption: { label: "Team member's default classification", value: "" },
    },
  });

  const selectedTm = lookupTeam(timesheet?.team_member);

  const earningTypeOptions = useMemo(() => {
    return getEarningTypeOptionsForTmFlattened(lookupTimeOffPolicy, "timesheet", selectedTm);
  }, [selectedTm]);

  const selectTmOption = useMemo(
    () => (timesheet?.team_member ? teamOptions.find((t) => t.value === timesheet.team_member) : undefined),
    [teamOptions, timesheet?.team_member]
  );
  const selectJobOption = useMemo(
    () => (timesheet?.job ? jobOptions.find((t) => t.value === timesheet.job) : undefined),
    [jobOptions, timesheet?.job]
  );
  const selectActivityOption = useMemo(
    () => (timesheet?.activity ? activityOptions.find((t) => t.value === timesheet.activity) : undefined),
    [activityOptions, timesheet?.activity]
  );
  const selectCostTypeOption = useMemo(
    () =>
      timesheet?.cost_type_id ? costTypeOptions.find((t) => t.value === timesheet.cost_type_id) : undefined,
    [costTypeOptions, timesheet?.cost_type_id]
  );
  const selectedClassificationOption = useMemo(
    () =>
      timesheet?.classification_override
        ? classificationOptions.find((t) => t.value === timesheet.classification_override)
        : undefined,
    [classificationOptions, timesheet?.classification_override]
  );

  const selectedEarningTypeOption = useMemo(
    () =>
      timesheet?.earning_type
        ? earningTypeOptions.find((t) => t.value === timesheet.earning_type)
        : undefined,
    [earningTypeOptions, timesheet?.earning_type]
  );

  const selectedEquipmentOptions = useMemo(
    () =>
      timesheet?.equipment_ids
        ? equipmentOptions.filter((t) => timesheet.equipment_ids?.includes(t.value))
        : [],
    [equipmentOptions, timesheet?.equipment_ids]
  );

  /****************************************************************
   *  Helper variables
   ****************************************************************/
  const totalHoursForTeamMember = useMemo(() => {
    const tm = draftTimesheets[index]?.team_member;
    if (!tm) return 0;

    return draftTimesheets.reduce((acc, curr) => {
      if (curr.team_member === tm && curr.date?.toISODate() === timesheet?.date?.toISODate()) {
        return acc + (curr?.hours || 0);
      }
      return acc;
    }, 0);
  }, [JSON.stringify(draftTimesheets)]);

  const hoursAreInvalid =
    !timesheet?.hours ||
    isNaN(timesheet.hours) ||
    (!isNaN(timesheet.hours) && (timesheet?.hours > 24 || timesheet?.hours <= 0));

  const cumulativeHoursAreInvalid = totalHoursForTeamMember > 24;
  const blankTeamMember = !timesheet?.team_member;

  /****************************************************************
   *  Misc helper functions
   ****************************************************************/

  const getHoursErrorText = () => {
    if (hoursAreInvalid) {
      return "Hours must be between 0 and 24";
    } else if (cumulativeHoursAreInvalid) {
      return "This team member is working more than 24 hours on this date. Please fix this before continuing.";
    } else {
      return "";
    }
  };

  /****************************************************************
   *  Input field handlers
   ****************************************************************/

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!timesheet) return null;
    const value =
      e.target.name === "hours" || e.target.name === "break_minutes"
        ? Number(e.target.value || "0")
        : e.target.value;

    const newTimesheet = { ...timesheet, editing: true };
    newTimesheet[e.target.name] = value;

    if (timesheet.date) {
      const clockInTimeString =
        (e.target.name === "clock_in_time" && typeof value === "string" ? value : timesheet.clock_in_time) ||
        defaultClockInTime;

      const clockInHour = Number(clockInTimeString.split(":")[0]);
      const clockInMinute = Number(clockInTimeString.split(":")[1]);

      const clockInDt = DateTime.fromObject({
        ...timesheet.date.toObject(),
        hour: clockInHour,
        minute: clockInMinute,
        second: 0,
        millisecond: 0,
      });

      let break_minutes = 0;
      let clockOutDt: DateTime;
      if (clockOutNotHours) {
        const clockOutTimeString =
          (e.target.name === "clock_out_time" && typeof value === "string"
            ? value
            : timesheet.clock_out_time) || defaultClockOutTime;

        const clockOutHour = Number(clockOutTimeString.split(":")[0]);
        const clockOutMinute = Number(clockOutTimeString.split(":")[1]);

        clockOutDt = DateTime.fromObject({
          ...timesheet.date.toObject(),
          hour: clockOutHour,
          minute: clockOutMinute,
          second: 0,
          millisecond: 0,
        });
        break_minutes =
          e.target.name === "break_minutes" && typeof value === "number"
            ? value
            : timesheet.break_minutes || 0;
      } else {
        clockOutDt = clockInDt.plus({
          hours: e.target.name === "hours" && typeof value === "number" ? value : timesheet.hours,
        });
      }

      if (clockOutDt < clockInDt) clockOutDt = clockOutDt.plus({ days: 1 });

      newTimesheet.clock_out_time = clockOutDt.toLocaleString(DateTime.TIME_24_SIMPLE);
      newTimesheet.break_minutes = break_minutes;
      newTimesheet.hours = roundTo(clockOutDt.diff(clockInDt, "hours").hours - break_minutes / 60);
    }

    const newDraftTimesheets = [...draftTimesheets];
    newDraftTimesheets.splice(index, 1, newTimesheet);
    setDraftTimesheets(newDraftTimesheets);
  };

  const handleCheckboxChange = (e) => {
    if (!timesheet) return null;
    const newTimesheet = { ...timesheet, selected: e.target.checked };

    const newDraftTimesheets = [...draftTimesheets];
    newDraftTimesheets.splice(index, 1, newTimesheet);
    setDraftTimesheets(newDraftTimesheets);
  };

  const handleSelectChange = (
    option: Option<string | null> | null | undefined,
    element: ActionMeta<Option<string | null>>
  ): void => {
    if (!timesheet) return;
    const newTimesheet = { ...timesheet, editing: true };
    const value = option?.value || undefined;
    newTimesheet[element.name!] = value;

    // If the user is changing the job, we need to reset the activity field
    if (element.name === "job") {
      const relActivities = activityOptions.map((a) => a.value);
      if (timesheet.activity && !relActivities.includes(timesheet.activity)) {
        newTimesheet.activity = null;
      }
      if (timesheet.classification_override) {
        newTimesheet.classification_override = null;
      }
    } else if (element.name === "activity") {
      const selectedActivity = lookupActivity(value);
      const selectedTm = lookupTeam(newTimesheet.team_member);
      checkTmAndActivityLicenseMatch(selectedActivity, selectedTm);
    } else if (element.name === "team_member") {
      const selectedActivity = lookupActivity(newTimesheet.activity);
      const selectedTm = lookupTeam(value);
      checkTmAndActivityLicenseMatch(selectedActivity, selectedTm);
    } else if (element.name === "earning_type") {
      newTimesheet.earning_type = value as TimesheetEarningType;
    }

    const newDraftTimesheets = [...draftTimesheets];
    newDraftTimesheets.splice(index, 1, newTimesheet);
    setDraftTimesheets(newDraftTimesheets);
  };

  const handleMultiSelectChange = (options: Option<string>[], element: ActionMeta<Option<string | null>>) => {
    if (!timesheet) return;
    const newTimesheet = { ...timesheet, editing: true };
    const value = options?.map((o) => o.value) || [];
    newTimesheet[element.name!] = value;
    const newDraftTimesheets = [...draftTimesheets];
    newDraftTimesheets.splice(index, 1, newTimesheet);
    setDraftTimesheets(newDraftTimesheets);
  };

  const handleDateChange = (dt) => {
    const newTimesheet = { ...timesheet };
    newTimesheet.date = dt;
    newTimesheet._id = ObjectID().toHexString();

    const newDraftTimesheets = [...draftTimesheets];
    newDraftTimesheets.splice(index, 1, newTimesheet as DraftTimesheet);
    setDraftTimesheets(newDraftTimesheets);
  };

  /****************************************************************
   *  Timesheet action functions
   ****************************************************************/

  const duplicate = () => {
    const newTimesheets = _.cloneDeep(draftTimesheets);
    const newTimesheet = {
      ...timesheet,
      _id: ObjectID().toHexString(),
    };

    newTimesheets.splice(index, 0, newTimesheet);
    setDraftTimesheets(newTimesheets);
  };

  const trash = () => {
    const newTimesheets = _.cloneDeep(draftTimesheets);
    newTimesheets.splice(index, 1);

    // Set editing to false so that the row is not re-rendered
    if (newTimesheets.length > 0) {
      newTimesheets[newTimesheets.length - 1]!.editing = false;
    }
    setDraftTimesheets(newTimesheets);
  };

  /****************************************************************
   *  Render functions
   ****************************************************************/

  const renderTooltipContainer = () => {
    if (!hoursAreInvalid && !blankTeamMember && !cumulativeHoursAreInvalid) return <></>;

    return (
      <ReactTooltip
        place="top"
        type="error"
        effect="solid"
        className="tooltip errortip"
        arrowColor="red"
        eventOff="mouseleave mouseout scroll mousewheel blur"
      />
    );
  };

  const renderCheckbox = () => {
    return (
      <td className={styles["input-cell"] + " " + styles["select-column"]}>
        <input
          type="checkbox"
          key={"timesheet-select-" + index}
          checked={timesheet?.selected}
          onChange={handleCheckboxChange}
        />
      </td>
    );
  };

  const renderTeamMemberCell = () => {
    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles["tm-column"] +
          " " +
          (blankTeamMember ? styles["input-error"] : "")
        }
        data-tip={blankTeamMember ? "Team member is required" : ""}
      >
        <div
          style={{
            marginLeft: "-8px",
            width: "calc(100% + 18px)",
            marginRight: "-8px",
            overflow: "visible",
          }}
        >
          <Select
            key={"timesheet-team-member-" + index}
            name="team_member"
            options={teamOptions}
            defaultValue={selectTmOption}
            width="99%"
            height="10px"
            onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
              handleSelectChange(value, actionMeta)
            }
            value={selectTmOption || null}
            styles={bulkTimesheetSelectStyles}
            isClearable={true}
            menuPlacement={index >= 8 ? "top" : "bottom"}
            menuPortalTarget={document.body}
          />
        </div>
      </td>
    );
  };

  const renderDateCell = () => {
    return (
      <td className={styles["input-cell"] + " " + styles["date-column"]}>
        <DateTimeInput
          className={styles["select-input"]}
          defaultValue={timesheet?.date || DateTime.now().startOf("day")}
          onChange={handleDateChange}
          disableDefaultClass={true}
        />
      </td>
    );
  };

  const renderClockInCell = () => {
    return (
      <td className={styles["input-cell"] + " " + styles["clock-in-column"]}>
        <input
          type="time"
          value={timesheet!.clock_in_time}
          defaultValue={timesheet?.clock_in_time}
          name="clock_in_time"
          className={styles["select-input"]}
          onChange={handleChange}
        />
      </td>
    );
  };

  const renderHoursCell = () => {
    const hoursErrorText = getHoursErrorText();

    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles[clockOutNotHours ? "clock-in-column" : "hours-column"] +
          " " +
          (hoursAreInvalid || cumulativeHoursAreInvalid ? styles["input-error"] : "")
        }
      >
        {clockOutNotHours ? (
          <input
            data-tip={hoursErrorText}
            type="time"
            className={styles["select-input"]}
            onChange={handleChange}
            value={timesheet!.clock_out_time}
            defaultValue={timesheet?.clock_out_time}
            name="clock_out_time"
          />
        ) : (
          <input
            data-tip={hoursErrorText}
            name="hours"
            type="number"
            className={styles["select-input"]}
            defaultValue={8}
            value={timesheet!.hours || ""}
            onChange={handleChange}
            min={0}
            max={24}
          />
        )}
      </td>
    );
  };

  const renderBreakMinutesCell = () => {
    if (hasAdvancedBreakTime) return;

    return (
      <td className={styles["input-cell"] + " " + styles["break-min-column"]}>
        <input
          type="number"
          value={timesheet!.break_minutes || ""}
          name="break_minutes"
          className={styles["select-input"]}
          onChange={handleChange}
          min={0}
          max={timesheet?.hours && timesheet.hours * 60}
        />
      </td>
    );
  };

  const renderJobCell = () => {
    const emptyAndRequiredJob = !timesheet?.job && isFieldRequired("job");
    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles["job-column"] +
          " " +
          (emptyAndRequiredJob ? styles["input-error"] : "")
        }
        data-tip={emptyAndRequiredJob ? "Job is required" : ""}
      >
        <div style={{ marginLeft: "-8px", width: "calc(100% + 18px)", marginRight: "-8px" }}>
          <Select
            key={"timesheet-job-" + index}
            name="job"
            options={jobOptions}
            defaultValue={selectJobOption}
            value={selectJobOption || null}
            width="100%"
            height="32px"
            isClearable={true}
            styles={bulkTimesheetSelectStyles}
            onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
              handleSelectChange(value, actionMeta)
            }
            menuPlacement={index >= 8 ? "top" : "bottom"}
            isDisabled={isFieldHidden("job")}
            placeholder={isFieldHidden("job") ? "Disabled" : "Select..."}
            menuPortalTarget={document.body}
            components={
              isFieldHidden("job")
                ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
                : undefined
            }
          />
        </div>
      </td>
    );
  };

  const renderActivityCell = () => {
    const ind = activityOptions.findIndex((a) => !a.value);
    if (ind > -1) activityOptions.splice(ind, 1);
    const emptyAndRequiredActivity = !timesheet?.activity && isFieldRequired("activity");
    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles["activity-column"] +
          " " +
          (emptyAndRequiredActivity ? styles["input-error"] : "")
        }
        data-tip={emptyAndRequiredActivity ? "Activity is required" : ""}
      >
        <Select
          key={"timesheet-activity-" + index}
          name="activity"
          options={activityOptions.map((activity) => ({ value: activity.value, label: activity.label }))}
          defaultValue={selectActivityOption}
          value={selectActivityOption || null}
          width="100%"
          height="32px"
          styles={bulkTimesheetSelectStyles}
          isClearable={true}
          onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
            handleSelectChange(value, actionMeta)
          }
          menuPortalTarget={document.body}
          menuPlacement={index >= 8 ? "top" : "bottom"}
          isDisabled={isFieldHidden("activity")}
          placeholder={isFieldHidden("activity") ? "Disabled" : "Select..."}
          components={
            isFieldHidden("activity")
              ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
              : undefined
          }
        />
      </td>
    );
  };

  const renderCostTypeCell = () => {
    const ind = costTypeOptions.findIndex((a) => !a.value);
    if (ind > -1) costTypeOptions.splice(ind, 1);
    const emptyAndRequiredCostType = !timesheet?.cost_type_id && isFieldRequired("cost_type_id");
    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles["cost-type-column"] +
          " " +
          (emptyAndRequiredCostType ? styles["input-error"] : "")
        }
        data-tip={emptyAndRequiredCostType ? "Cost type is required" : ""}
      >
        <Select
          key={"cost-type-" + index}
          name="cost_type_id"
          options={costTypeOptions.map((costTypeOption) => ({
            value: costTypeOption.value,
            label: costTypeOption.label,
          }))}
          defaultValue={selectCostTypeOption}
          value={selectCostTypeOption || null}
          width="100%"
          height="32px"
          styles={bulkTimesheetSelectStyles}
          isClearable={true}
          onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
            handleSelectChange(value, actionMeta)
          }
          menuPortalTarget={document.body}
          menuPlacement={index >= 8 ? "top" : "bottom"}
          isDisabled={isFieldHidden("cost_type_id")}
          placeholder={isFieldHidden("cost_type_id") ? "Disabled" : "Select..."}
          components={
            isFieldHidden("cost_type_id")
              ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
              : undefined
          }
        />
      </td>
    );
  };

  const renderClassificationCell = () => {
    const ind = classificationOptions.findIndex((a) => !a.value);
    if (ind > -1) classificationOptions.splice(ind, 1);
    const emptyAndRequiredClassification =
      !timesheet?.classification_override && isFieldRequired("classification_override");

    const teamMember = lookupTeam(timesheet?.team_member);
    const classificationLabel = teamMember?.union_rate?.classification;

    const placeholderOverride = showPayRate && classificationLabel ? classificationLabel : null;

    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles["classification-column"] +
          " " +
          (emptyAndRequiredClassification ? styles["input-error"] : "")
        }
        data-tip={emptyAndRequiredClassification ? "Classification specification is required" : ""}
      >
        <Select
          key={"timesheet-classification-" + index}
          name="classification_override"
          options={classificationOptions}
          defaultValue={selectedClassificationOption}
          value={selectedClassificationOption || null}
          width="100%"
          height="32px"
          styles={bulkTimesheetSelectStyles}
          isClearable={true}
          onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
            handleSelectChange(value, actionMeta)
          }
          menuPortalTarget={document.body}
          menuPlacement={index >= 8 ? "top" : "bottom"}
          isDisabled={isFieldHidden("classification_override")}
          placeholder={
            placeholderOverride || (isFieldHidden("classification_override") ? "Disabled" : "Select...")
          }
          components={
            isFieldHidden("classification_override")
              ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
              : undefined
          }
        />
      </td>
    );
  };

  const renderNotesCell = () => {
    const emptyAndRequiredNotes = !timesheet?.notes && isFieldRequired("notes");
    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles["notes-column"] +
          " " +
          (emptyAndRequiredNotes ? styles["input-error"] : "")
        }
        data-tip={emptyAndRequiredNotes ? "Note is required" : ""}
      >
        <input
          type="notes"
          value={timesheet!.notes}
          defaultValue={timesheet?.notes}
          name="notes"
          className={styles["select-input"]}
          onChange={handleChange}
          disabled={isFieldHidden("notes")}
        />
      </td>
    );
  };

  const renderEarningTypeCell = () => {
    return (
      <td className={styles["input-cell"] + " " + styles["earning-type-column"]}>
        {/** @ts-expect-error Select types are not great */}
        <Select
          key={"timesheet-earning-type-alias-" + index}
          name="earning_type"
          options={earningTypeOptions}
          defaultValue={selectedEarningTypeOption}
          width="100%"
          height="32px"
          styles={bulkTimesheetSelectStyles}
          isClearable={true}
          onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
            handleSelectChange(value, actionMeta)
          }
          menuPortalTarget={document.body}
          menuPlacement={index >= 8 ? "top" : "bottom"}
        />
      </td>
    );
  };

  const renderWcCodeCell = () => {
    return (
      <td className={styles["input-cell"] + " " + styles["wc-column"]}>
        <Select
          key={"timesheet-wc-code-" + index}
          name="wc_code"
          options={wcCodeOptions}
          defaultValue={timesheet?.wc_code ? { value: timesheet.wc_code, label: timesheet.wc_code } : null}
          width="100%"
          height="32px"
          styles={bulkTimesheetSelectStyles}
          isClearable={true}
          onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
            handleSelectChange(value, actionMeta)
          }
          menuPortalTarget={document.body}
          menuPlacement={index >= 8 ? "top" : "bottom"}
        />
      </td>
    );
  };

  const MultiValueContainer = () => {
    return null; // This removes the default chips
  };
  const ValueContainer = ({ children, ...props }: ValueContainerProps<Option<string>, true>) => {
    const { getValue, hasValue } = props;
    if (!hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }
    const values = getValue();
    return <div style={{ minWidth: "75%" }}>{values.map((v) => v.label).join(", ")}</div>;
  };

  const renderEquipmentCell = () => {
    const emptyAndRequiredEquipment = !timesheet?.equipment_ids?.length && isFieldRequired("equipment_ids");
    return (
      <td
        className={
          styles["input-cell"] +
          " " +
          styles["equipment-column"] +
          " " +
          (emptyAndRequiredEquipment ? styles["input-error"] : "")
        }
        data-tip={emptyAndRequiredEquipment ? "Equipment is required" : ""}
      >
        <Select
          key={"timesheet-equipment-" + index}
          name="equipment_ids"
          options={equipmentOptions}
          defaultValue={selectedEquipmentOptions}
          width="100%"
          height="32px"
          styles={equipmentSelectStyles}
          isClearable={true}
          menuPortalTarget={document.body}
          menuPlacement={index >= 8 ? "top" : "bottom"}
          components={{ MultiValueContainer, ValueContainer }}
          isMulti
          onChange={(
            selectedOptions: ValueType<Option<string>, true>,
            actionMeta: ActionMeta<Option<string>>
          ) => {
            handleMultiSelectChange(selectedOptions as Option<string>[], actionMeta);
          }}
        />
      </td>
    );
  };

  const renderPayRateCell = () => {
    // Use the regular pay rate from the pay rate item
    const payRate = timesheet?._id ? roundTo(payRatesMap[timesheet._id]?.rates?.reg || 0) : 0;

    return (
      <td className={styles["input-cell"] + " " + styles["wc-column"] + " " + styles["pay-rate-column"]}>
        ${payRate}/hr
      </td>
    );
  };

  const renderActionsCell = () => {
    return (
      <td className={styles["input-cell"] + " " + styles["options-column"] + " " + styles["justify-center"]}>
        <div className={styles["action-options-container"]}>
          <ImageWithTooltip
            src={duplicateIcon}
            height={16}
            width={16}
            onClick={duplicate}
            tooltip="Duplicate timesheet"
          />
          <div className="flex-1"></div>
          &nbsp;
          <ImageWithTooltip
            src={trashIcon}
            height={16}
            width={16}
            onClick={trash}
            tooltip="Delete timesheet"
          />
        </div>
      </td>
    );
  };

  return (
    <tr className={styles["columns-wrapper"]} ref={ref}>
      {renderCheckbox()}
      {renderTeamMemberCell()}
      {renderDateCell()}
      {renderClockInCell()}
      {renderHoursCell()}
      {clockOutNotHours && renderBreakMinutesCell()}
      {jobFieldRequirement === "hidden" ? null : renderJobCell()}
      {activityFieldRequirement === "hidden" ? null : renderActivityCell()}
      {costTypeFieldRequirement === "hidden" ? null : renderCostTypeCell()}
      {classificationFieldRequirement === "hidden" ? null : renderClassificationCell()}
      {notesFieldRequirement === "hidden" ? null : renderNotesCell()}
      {showEarningType ? renderEarningTypeCell() : null}
      {!(enableAdminWcCode && canReadWcCode) ? null : renderWcCodeCell()}
      {!hasAccessToEquipmentTracking || equipmentFieldRequirement === "hidden" ? null : renderEquipmentCell()}
      {!showPayRate ? null : renderPayRateCell()}
      {renderActionsCell()}
      {renderTooltipContainer()}
    </tr>
  );
};

export const BULK_ENTRY_COLUMNS = {
  job: "optional",
  activity: "optional",
  classification: "optional",
  notes: "optional",
  cost_type_id: "optional",
  equipment_ids: "optional",
};
