import { useState, useEffect, FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, TableV2 } from "ui";
import { CleanedJob, useCleanJobsFromBackend } from "../../utils/jobUtils";
import { useCleanTeamFromBackend } from "../../pages/team-members/TeamUtils";
import { AggregatedJob, AggregatedTeamMember, Conversation, Job, MiterAPI } from "dashboard/miter";
import ErrorCard from "../error/errorCard";
import { useTeamColumns } from "dashboard/pages/team-members/table-columns/useTeamMembersTableColumns";
import { useQuery } from "miter-utils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useJobColumns } from "../tables/JobsTable";

const SearchResults: FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const query = useQuery().get("query");
  const jobsColumns = useJobColumns();
  const teamColumns = useTeamColumns();

  const [jobs, setJobs] = useState<CleanedJob<AggregatedJob | Job>[] | null>(null);
  const [teamMembers, setTeamMembers] = useState<AggregatedTeamMember[] | null>(null);
  const [chats, setChats] = useState<Conversation[] | null>(null);
  const cleanJobsFromBackend = useCleanJobsFromBackend();
  const cleanTeamFromBackend = useCleanTeamFromBackend();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getSearchResults();
  }, [query, !!activeCompanyId]);

  /*******************************************
   * Backend interactions
   *******************************************/

  const getSearchResults = async () => {
    if (query && activeCompanyId) {
      setLoading(true);

      try {
        const responseData = await MiterAPI.search(activeCompanyId, query);
        if (responseData.error) {
          throw Error(responseData.error);
        }

        saveSearchResults(responseData);
      } catch (e: $TSFixMe) {
        console.error("Error in handling search:", e.message);
        setError("Sorry, we can't complete your search right now. If the problem persists, let us know!");
      }
      setLoading(false);
    }
  };

  const saveSearchResults = (searchResults) => {
    if (searchResults) {
      setJobs(cleanJobsFromBackend(searchResults.jobs || []));
      setTeamMembers(searchResults.teamMembers || []);
      setChats(searchResults.chats || []);
    }
  };

  /*******************************************
   * Action handlers
   *******************************************/
  const handleJobClick = (row) => {
    navigate("/jobs/" + row._id);
  };

  const handleTeamMemberClick = (row) => {
    navigate("/team-members/" + row._id);
  };

  const handleChatClick = (row) => {
    navigate("/chat/" + row.conversation_sid);
  };

  /*******************************************
   * Memoized table helpers
   *******************************************/
  const jobsData = useMemo(() => cleanJobsFromBackend(jobs || []), [jobs]);
  const teamData = useMemo(() => cleanTeamFromBackend(teamMembers || []), [teamMembers]);

  /*******************************************
   * Render helpers
   *******************************************/

  const renderJobs = () => {
    return (
      <>
        {jobs && (
          <>
            <h3>Jobs</h3>
          </>
        )}
        {jobs && (
          <>
            <TableV2
              id="jobs-table-search"
              resource="jobs"
              data={jobsData}
              // @ts-expect-error Fix later
              columns={jobsColumns}
              onClick={handleJobClick}
              isLoading={loading}
              hideSecondaryActions={true}
              hideFilters={true}
              hideSearch={true}
            />
          </>
        )}
      </>
    );
  };

  const renderTeamMembers = () => {
    return (
      <>
        {teamMembers && (
          <>
            <h3>Team members</h3>
          </>
        )}
        {teamMembers && (
          <>
            <TableV2
              id="team-members-table-search"
              resource="team members"
              data={teamData}
              columns={teamColumns}
              onClick={handleTeamMemberClick}
              isLoading={loading}
              hideSecondaryActions={true}
              hideFilters={true}
              hideSearch={true}
            />
          </>
        )}
      </>
    );
  };

  const renderChats = () => {
    return (
      <>
        {chats && (
          <div>
            <h3>Chats</h3>
          </div>
        )}
        {chats && (
          <div>
            <TableV2
              id={"chat-search-table"}
              resource={"conversations"}
              data={chats}
              columns={[
                { field: "friendly_name", headerName: "Chat", dataType: "string" },
                { field: "created_at", headerName: "Date created", dataType: "string" },
                { field: "updated_at", headerName: "Last updated", dataType: "string" },
              ]}
              onClick={handleChatClick}
              hideFooter={true}
              hideSecondaryActions={true}
              hideFilters={true}
              hideSearch={true}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="page-content search-page">
      <div className="page-content-header flex">
        <h1>Search results</h1>
      </div>
      <div className="vertical-spacer-small" />
      {loading && <Loader />}
      {!loading && error && <ErrorCard errorMessage={error} />}
      {!loading && !error && (
        <>
          {!query && <div className="results-text">Use the search bar to search Miter.</div>}
          {query && (
            <>
              {renderJobs()}
              <div className="vertical-spacer" />
              {renderTeamMembers()}
              <div className="vertical-spacer" />
              {/* {renderTimesheets()} */}
              <div className="vertical-spacer" />
              {renderChats()}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchResults;
