import React, { useMemo } from "react";
import { TableV2 } from "ui";
import PaymentContext from "./paymentContext";
import { shorten } from "dashboard/utils/utils";
import { MiterReimbursement } from "../../payrollTypes";
import { ColumnConfig } from "ui/table-v2/Table";
import { useActivityLabelFormatter, useJobNameFormatter } from "dashboard/hooks/atom-hooks";

export type ReimbursementTableEntry = MiterReimbursement & {
  original: MiterReimbursement;
  descriptionWithBadge: React.ReactNode;
};

type Props = {
  setIsAdding: (isAdding: boolean) => void;
  setViewingExpenseReimbursement: (expenseReimbursementId: string) => void;
};

const Reimbursements: React.FC<Props> = ({ setIsAdding, setViewingExpenseReimbursement }) => {
  // Hooks
  const { payment, editing, isLoading } = React.useContext(PaymentContext);
  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();

  const createDescription = (r: MiterReimbursement) => {
    const shortenedDescription = r.description ? shorten(r.description, 50) : undefined;
    return (
      <div className="flex">
        <div>{shortenedDescription}</div>
      </div>
    );
  };

  const columns: ColumnConfig<ReimbursementTableEntry>[] = useMemo(() => {
    const columns: ColumnConfig<ReimbursementTableEntry>[] = [
      {
        field: "amount",
        headerName: "Amount",
        maxWidth: 100,
        dataType: "number",
        unit: "dollar",
        sumRow: true,
      },
      { field: "descriptionWithBadge", headerName: "Description", dataType: "component" },
      { field: "code", headerName: "Code", dataType: "string" as const, maxWidth: 100 },
      {
        field: "job",
        headerName: "Job",
        dataType: "string" as const,
        maxWidth: 200,
        valueGetter: (params) => jobNameFormatter(params.data?.job),
      },
      {
        field: "activity",
        headerName: "Activity",
        dataType: "string" as const,
        maxWidth: 200,
        valueGetter: (params) => activityLabelFormatter(params.data?.activity),
      },
    ];
    return columns;
  }, [jobNameFormatter, activityLabelFormatter]);

  // Initialize table data

  const tableReadyReimbursements = useMemo(() => {
    const rs: ReimbursementTableEntry[] = [];
    for (const r of payment.miter_reimbursements) {
      rs.push({
        ...r,
        descriptionWithBadge: createDescription(r),
        original: r,
      });
    }
    return rs;
  }, [payment]);

  return (
    <>
      <div>
        <div className="payment-active-view-header">
          <div className="">Reimbursements</div>
          <div className="flex-1"></div>
          {!isLoading && editing && (
            <button
              onClick={() => {
                setIsAdding(true);
              }}
              className="button-2 no-margin"
            >
              + New
            </button>
          )}
        </div>

        <div>
          <TableV2
            id={"payroll-reimbursments-table"}
            resource="reimbursements"
            data={tableReadyReimbursements}
            columns={columns}
            onClick={(entry) => {
              // old reimbursements may not have an expense reimbursement id
              if (entry.expense_reimbursement_id) {
                setViewingExpenseReimbursement(entry.expense_reimbursement_id);
              }
            }}
            hideSearch={true}
            hideSecondaryActions={true}
            containerStyle={{ paddingTop: 10, height: 400 }}
            customEmptyStateMessage="This payment has no reimbursements."
            rowClickDisabled={disableRowClick}
            hideSelectColumn
            showTotals
          />
        </div>
      </div>
    </>
  );
};

export default Reimbursements;

const disableRowClick = (r: ReimbursementTableEntry): boolean => {
  // can only view if the reimbursement came from an expense reimbursement
  return !r.expense_reimbursement_id;
};
