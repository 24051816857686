import React from "react";
import { Button } from "..";

export type FooterProps = {
  loading?: boolean;
  className?: string;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  hideCancel?: boolean;
  cancelText?: React.ReactNode;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  submitDisabled?: boolean;
  submitText?: React.ReactNode;
  hideSubmit?: boolean;
  showDelete?: boolean;
  deleteDisabled?: boolean;
  editDisabled?: boolean;
  deleteText?: React.ReactNode;
  showEdit?: boolean;
  editText?: React.ReactNode;
  deleting?: boolean;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  editing?: boolean;
  hide?: boolean;
};

const ModalFooter: React.FC<FooterProps> = (props: FooterProps) => {
  const {
    loading,
    className,
    onCancel,
    hideCancel,
    cancelText,
    onSubmit,
    submitDisabled,
    deleteDisabled,
    editDisabled,
    deleting,
    submitText,
    hideSubmit,
    showDelete,
    deleteText,
    showEdit,
    editText,
    onEdit,
    editing,
    onDelete,
    hide,
  } = props;

  const submitCleaned = submitText ? submitText : "Submit";
  const cancelCleaned = cancelText ? cancelText : "Cancel";
  const deleteCleaned = deleteText ? deleteText : "Delete";
  const editCleaned = editText ? editText : "Edit";

  if (hide) return <></>;

  return (
    <>
      <div className={"modal-footer " + className}>
        {showDelete && (
          <Button
            className="button-3 no-margin"
            onClick={onDelete}
            text={deleteCleaned}
            loading={deleting}
            disabled={deleteDisabled || loading}
          />
        )}
        {showEdit && (
          <Button
            className="button-1"
            onClick={onEdit}
            style={{ marginRight: "auto" }}
            text={editCleaned}
            loading={editing}
            disabled={editDisabled}
          />
        )}
        <div className="flex-1"></div>
        {!hideCancel && (
          <Button
            className="button-1"
            onClick={onCancel}
            text={cancelCleaned}
            disabled={loading || deleting || editing}
          />
        )}
        {!hideSubmit && (
          <Button
            className="button-2 no-margin"
            disabled={submitDisabled || deleting}
            onClick={onSubmit}
            text={submitCleaned}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export default ModalFooter;
