/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggregatedFile, TeamMember } from "dashboard/miter";
import { timeAgo } from "dashboard/utils";
import { truncate } from "lodash";
import { DateTime } from "luxon";
import { Pencil } from "phosphor-react";
import React, { useCallback, useMemo, useState } from "react";
import { ActionModal } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Assign } from "utility-types";
import DocumentWizard from "./wizard/DocumentWizard";
import { useLookupDepartment } from "dashboard/hooks/atom-hooks";
import { useDocumentAbilities } from "dashboard/hooks/abilities-hooks/useDocumentAbilities";

type TeamMemberRow = Assign<
  TeamMember,
  {
    department: string;
    title: string;
  }
>;

type EventRow = Assign<
  AggregatedFile["events"][number],
  { formatted_timestamp: string; action: string; ip_address: string }
>;

type Props = {
  document: AggregatedFile & { url: string };
  getDocument: () => void;
};

const DocumentTeamMembersTable: React.FC<Props> = ({ document, getDocument }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const lookupDept = useLookupDepartment();
  const { can } = useDocumentAbilities();

  /*********************************************************
   * State functions
   **********************************************************/
  const [selectedTeamMember, setSelectedTeamMember] = useState<TeamMember>();
  const [showEditTeamAccess, setShowEditTeamAccess] = useState(false);

  /*********************************************************
   *  Table helper functions
   **********************************************************/
  const buildTableRow = (tm: TeamMember): TeamMemberRow => {
    return {
      ...tm,
      department: lookupDept(tm.department_id)?.name || "-",
      title: tm.title,
    };
  };

  const tableData = useMemo(() => document.team_members_with_access?.map(buildTableRow), [document]);

  const eventsData: EventRow[] = useMemo(() => {
    if (!selectedTeamMember) return [];

    return (
      (document.events?.filter((h) => h.team_member_ids?.includes(selectedTeamMember._id)) || []).map(
        (e) => ({
          ...e,
          formatted_timestamp: timeAgo(DateTime.fromSeconds(e.timestamp)),
          action: e.action.replace("_", " "),
          ip_address: e.ip_address || "-",
        })
      ) || []
    ).sort((a, b) => b.timestamp - a.timestamp);
  }, [document, selectedTeamMember]);

  /*********************************************************
    Handler functions
  **********************************************************/
  const handleEditTeamAccessHide = () => {
    setShowEditTeamAccess(false);
  };

  const handleEditTeamAccessFinish = () => {
    handleEditTeamAccessHide();
    getDocument();
  };

  const staticActions: TableActionLink[] = [
    {
      label: "Manage access",
      className: "button-2 no-margin",
      action: () => setShowEditTeamAccess(true),
      important: true,
      icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => can("update", document, document.parent_type),
    },
  ];

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderHistoryModal = useCallback(() => {
    if (!selectedTeamMember) return null;

    return (
      <ActionModal
        headerText={`Document History for ${truncate(selectedTeamMember.full_name, { length: 20 })}.`}
        showCancel={true}
        cancelText="Close"
        onCancel={() => setSelectedTeamMember(undefined)}
        onHide={() => setSelectedTeamMember(undefined)}
        wrapperStyle={{ width: "80%" }}
      >
        <TableV2
          id={"document-team-member-events-table"}
          resource="events"
          data={eventsData}
          columns={eventsColumns}
        />
      </ActionModal>
    );
  }, [selectedTeamMember, document]);

  const renderTable = () => {
    return (
      <TableV2
        id={"document-team-members-table"}
        resource="team members"
        data={tableData}
        columns={columns}
        staticActions={staticActions}
        onClick={setSelectedTeamMember}
      />
    );
  };

  return (
    <div className="team-members-table-wrapper">
      <div>{renderTable()}</div>
      {selectedTeamMember && renderHistoryModal()}
      {showEditTeamAccess && document && document.parent_id && (
        <DocumentWizard
          document={{ data: document, url: document.url }}
          onExit={handleEditTeamAccessHide}
          onComplete={handleEditTeamAccessFinish}
          parentId={document.parent_id}
          parentType={document.parent_type}
          mode="edit-team-access"
        />
      )}
    </div>
  );
};

export default DocumentTeamMembersTable;

const columns: ColumnConfig<TeamMemberRow>[] = [
  {
    headerName: "Name",
    field: "full_name",
    dataType: "string",
    minWidth: 250,
  },
  {
    headerName: "Title",
    field: "title",
    dataType: "string",
  },
  {
    headerName: "Department",
    field: "department",
    dataType: "string",
  },
];

const eventsColumns: ColumnConfig<EventRow>[] = [
  {
    headerName: "Action",
    field: "action",
    dataType: "string",
    displayType: "badge",
    colors: {
      created: "blue",
      shared: "green",
      unshared: "red",
      viewed: "blue",
      "esign requested": "yellow",
      "esign canceled": "red",
      "esign signed": "green",
    },
  },
  {
    headerName: "IP Address",
    field: "ip_address",
    dataType: "string",
  },
  {
    headerName: "Timestamp",
    field: "formatted_timestamp",
    dataType: "string",
  },
];
