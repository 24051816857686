import { PartialTemplateInput } from "dashboard/utils/fillable-templates";
import React, { FC, useEffect, useState } from "react";
import styles from "./InputMapper.module.css";
import { Badge, Formblock } from "ui";
import { useFormContext } from "react-hook-form";
import {
  TeamMemberGroupSelectValue,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import { getFormFieldTypeTitle, mapFieldToBadgeColor } from "miter-utils";

type Props = {
  setSelectedInput: (selectedInput: PartialTemplateInput) => void;
  selectedInput: PartialTemplateInput | undefined;
  inputs: PartialTemplateInput[];
};

export const InputMapperForm: FC<Props> = ({ setSelectedInput, inputs, selectedInput }) => {
  const form = useFormContext();
  const { trigger } = form;

  useEffect(() => {
    trigger();
  }, []);

  return (
    <div>
      {inputs.map((input) => (
        <FillableInputComponent
          key={input._id}
          input={input}
          selected={input._id === selectedInput?._id}
          onSelect={() => {
            setSelectedInput(input);
          }}
        />
      ))}
    </div>
  );
};

type FillableInputComponentProps = {
  input: PartialTemplateInput;
  selected: boolean;
  onSelect: () => void;
};

const FillableInputComponent: FC<FillableInputComponentProps> = ({ input, selected, onSelect }) => {
  const key = `inputs.${input._id}`;
  const nameKey = `${key}.name`;
  const descriptionKey = `${key}.description`;
  // Intentionally missing the period, multiselect (array) fields cannot be dot-notation nested
  const assigneeKey = `${input._id}-assignee`;
  const requiredKey = `${key}.validations.required`;
  const form = useFormContext();
  const { register, errors, trigger } = form;
  const [selectedTmGroups, setSelectedTmGroups] = useState<TeamMemberGroupSelectValue[]>([]);

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    // @ts-expect-error fix me
    setSelectedTmGroups(input.assignee || []);
  }, [input.assignee]);

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    selectedGroups: selectedTmGroups,
    excludedGroups: ["all_team_members", "employment_type", "pay_type", "title", "department", "team_member"],
    hideMitosaurs: true,
  });

  return (
    <div
      className={styles["input-component"]}
      style={{
        ...(selected ? { borderLeft: "5px solid #4d55bb", paddingLeft: 16 } : {}),
      }}
      onClick={onSelect}
    >
      <div className={styles["header"]}>
        <Formblock
          className={"modal " + styles["title"]}
          placeholder={"Untitled input field"}
          type="text"
          name={nameKey}
          register={register({ required: "Input name is required" })}
          errors={errors}
          editing={true}
          defaultValue={input?.name}
        />
        <Badge
          color={mapFieldToBadgeColor({
            formFieldType: input.type!,
            multiple: input.multiple,
          })}
          text={getFormFieldTypeTitle({ formFieldType: input.type! })}
        ></Badge>
      </div>

      <Formblock
        className={"modal "}
        placeholder={"Empty description"}
        type="paragraph"
        name={descriptionKey}
        register={register({ required: "Input description is required" })}
        errors={errors}
        editing={true}
        rows={1}
        disableResize={true}
        label="Description of input*"
        defaultValue={input?.description}
      />

      <Formblock
        type="multiselect"
        name={assigneeKey}
        label={"Assignee"}
        labelInfo={
          "Select the people who can complete this field. Any field can always be completed by a super admin."
        }
        form={form}
        editing={true}
        className="modal wizard"
        placeholder={"Select assignee(s)"}
        options={teamMemberGroupOptions}
        height="unset"
        labelButtonText="View team member list"
        onChange={(options) => {
          setSelectedTmGroups((options || []).map((option) => option.value!));
        }}
      />

      <Formblock
        className={"modal "}
        text="This input is required"
        type="checkbox"
        name={requiredKey}
        editing={true}
        form={form}
        defaultValue={input?.validations?.required}
        style={{ marginBottom: 0 }}
      />
    </div>
  );
};
