import { ClickAwayListener } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";

import ThreeDotMenu from "dashboard/assets/menu.png";
import useWindowDimensions from "dashboard/utils/useWindowDimensions";
import { ActionLink } from "./ActionMenu";

import "./actionMenu.css";

type Props = {
  links: ActionLink[];
  className?: string;
};

type DropdownPosition = {
  top: number;
  right: number;
};

const ActionMenuRelative: React.FC<Props> = ({ className = "", links }: Props) => {
  const { width } = useWindowDimensions();
  const inputRef = useRef<HTMLImageElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [_dropdownPosition, setDropdownPosition] = useState<DropdownPosition>();

  const setNewDropdownPosition = () => {
    const domRect = inputRef.current?.getBoundingClientRect();
    if (!domRect) return;
    const right = width - domRect.right;
    setDropdownPosition({
      top: domRect.top + 15,
      right: right,
    });
  };

  useEffect(() => {
    setNewDropdownPosition();
    window.addEventListener("scroll", setNewDropdownPosition, true);
    return () => {
      window.removeEventListener("scroll", setNewDropdownPosition, true);
    };
  }, []);

  return (
    <div style={{ position: "relative" }} className={"action-menu-wrapper " + className}>
      <div>
        <img
          src={ThreeDotMenu}
          className={"action-menu-icon " + className}
          onClick={() => setIsOpen(true)}
          id={"image"}
          ref={inputRef}
        />
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div
            style={{
              position: "absolute",
              top: 28,
              right: 0,
              zIndex: 10,
            }}
            className={"action-menu-dropdown " + className}
          >
            {links.map((link, index) => {
              const action = () => {
                link.action();
                setIsOpen(false);
              };

              return (
                <div onClick={action} className={"action-menu-link " + className} key={index}>
                  {link.label}
                </div>
              );
            })}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default ActionMenuRelative;
