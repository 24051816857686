import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Plus } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { TableV2, Toggler } from "ui";
import { TableActionLink } from "ui/table-v2/Table";
import { BenefitModal } from "./BenefitModal";
import { cleanCompanyBenefit, CleanedCompanyBenefit, useBenefitColDefs } from "./benefitsUtils";
import { AllEmployeeBenefitsTable } from "./AllEmployeeBenefitsTable";
import { Integrations } from "./Integrations";
import { Params, useParams } from "react-router-dom";
import PostTaxDeductions from "../post-tax-deductions/PostTaxDeductions";
import { AllowancesTable } from "./AllowancesTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import BenefitsAdmin from "../benefits-admin/BenefitsAdmin";
import { useHasAccessToBenAdmin } from "dashboard/gating";

const Benefits: React.FC = () => {
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();
  const [companyBenefits, setCompanyBenefits] = useState<CleanedCompanyBenefit[]>();
  const [isAdding, setIsAdding] = useState(false);
  const { view } = useParams<Params>();
  const { can, cannot } = useMiterAbilities();

  const getCompanyBenefits = async () => {
    try {
      const response = await MiterAPI.benefits.company.retrieve_for_company(activeCompany!._id);
      if (response.error) throw Error(response.error);

      const cleanedBenefits = response.map((b) => cleanCompanyBenefit(b));
      setCompanyBenefits(cleanedBenefits);
    } catch (e) {
      Notifier.error("There was a problem retrieving company plan data. We're looking into it.");
    }
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add benefit",
        className: "button-2 no-margin",
        action: () => setIsAdding(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => view === "company-plans" && can("benefits:create"),
      },
    ],
    [can, view]
  );

  const isGuidelineCompany = useMemo(() => activeCompany?.guideline_co, [activeCompany]);
  const hasBenAdminAccess = useHasAccessToBenAdmin();

  const togglerConfig = useMemo(
    () => [
      {
        label: "Benefits administration",
        path: "benefits-admin",
        hide: !hasBenAdminAccess || cannot("benefits:update"),
      },
      { label: "Employee benefits", path: "employee-benefits", hide: cannot("benefits:read") },
      { label: "Company plans", path: "company-plans", hide: cannot("benefits:read") },
      { label: "Post tax deductions", path: "post-tax-deductions", hide: cannot("post_tax_deductions:read") },
      { label: "Allowances", path: "allowances", hide: cannot("allowances:read") },
      {
        label: "Integrations",
        path: "integrations",
        hide: !isGuidelineCompany || cannot("integrations:manage"),
      },
    ],
    [cannot, hasBenAdminAccess, isGuidelineCompany]
  );

  const handleToggle = (path) => {
    navigate(`/benefits/${path}`, { replace: true });
  };

  const companyPlanColumns = useBenefitColDefs({ type: "company" });

  useEffect(() => {
    const defaultPath = togglerConfig.find((option) => !option.hide)?.path || "employee-benefits";
    if (!view) {
      navigate(`/benefits/${defaultPath}`, { replace: true });
    }
  }, [view]);

  useEffect(() => {
    getCompanyBenefits();
  }, []);

  return (
    <div className="page-content">
      <Helmet>
        <title>{getHelmetTitleFromView(view)} | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <h1>Benefits</h1>
      </div>
      <Toggler
        secondary={false}
        active={view || "employee-benefits"}
        config={togglerConfig}
        toggle={handleToggle}
      />
      {view === "company-plans" && isAdding && (
        <BenefitModal
          onSuccess={(id) => navigate("/benefits/company-plan/" + id)}
          hide={() => setIsAdding(false)}
        />
      )}
      {view === "company-plans" && (
        <TableV2
          id="company-benefits-table"
          resource="company benefits"
          data={companyBenefits}
          columns={companyPlanColumns}
          onClick={(b) => navigate("/benefits/company-plan/" + b._id)}
          staticActions={staticActions}
          showReportViews={true}
          toggler={TOGGLER_CONFIG}
        />
      )}
      {view === "benefits-admin" && <BenefitsAdmin />}
      {view === "employee-benefits" && <AllEmployeeBenefitsTable />}
      {view === "post-tax-deductions" && <PostTaxDeductions />}
      {view === "allowances" && <AllowancesTable />}
      {view === "integrations" && <Integrations />}
    </div>
  );
};

export default Benefits;

const getHelmetTitleFromView = (view: string | undefined) => {
  switch (view) {
    case "company-plans":
      return "Company Benefit Plans";

    case "post-tax-deductions":
      return "Post Tax Deductions";

    case "allowances":
      return "Allowances";

    case "integrations":
      return "Benefit Integrations";

    default:
      return "Employee Benefit Plans";
  }
};

const TOGGLER_CONFIG = {
  config: [
    { path: "active", label: "Active" },
    { path: "inactive", label: "Inactive" },
    { path: "all", label: "All" },
  ],
  path: "active",
  secondary: true,
  field: "status",
};
