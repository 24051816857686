import React, { useEffect, useMemo, useState } from "react";
import { reportList } from "../../reportList";
import AppContext from "../../../../contexts/app-context";
import { useForm } from "react-hook-form";
import { BasicModal, Button, ClickableText, Formblock } from "ui";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { AggregatedJob, MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Option } from "ui/form/Input";
import { downloadPdfFromBase64String, states } from "dashboard/utils";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import AnvilSignatureModal from "@anvilco/react-signature-modal";
import checkmark from "dashboard/assets/purpleCheckMark.png";
import { Megaphone } from "phosphor-react";
import QueryString from "qs";
import { SignCPRModal } from "./SignCPRModal";
import { Exception, Exceptions } from "./Exceptions";
import { Remarks } from "./Remarks";
import { Helmet } from "react-helmet";
import { AddOtherJobs } from "./AddOtherJobs";
import { AddCompanyNameOverride } from "./AddCompanyNameOverride";
import { AddFeinOverride } from "./AddFeinOverride";
import {
  useActiveCompanyId,
  useActiveCprJobOptions,
  useActiveTeam,
  useActiveTeamMember,
  useLookupJob,
  useUser,
} from "dashboard/hooks/atom-hooks";
import * as XLSX from "xlsx/xlsx.mjs";
import { getPayPeriodsOfPaySchedule, isWeeklyOrBiweekly } from "dashboard/utils/paySchedules";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { baseSensitiveCompare, buildCompanyUserName, stateOptions } from "miter-utils";
import { JobInput } from "dashboard/components/shared/JobInput";
import { usePayScheduleAccessor } from "dashboard/hooks/usePayScheduleAccessor";
import { CPRFormat, canAddExceptionsToFormat, canAddRemarksToFormat } from "dashboard/utils/cprUtils";
import { AddAddressOverride } from "./AddAddressOverride";

export const CreateCPR: React.FC = () => {
  // Hooks
  const { setReverifyUser } = React.useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const { canAccessPaySchedule } = usePayScheduleAccessor();
  const activeTeamMember = useActiveTeamMember();

  const { can } = useMiterAbilities();

  const form = useForm({ shouldUnregister: false });
  const { register, control, watch, errors, handleSubmit } = form;
  const navigate = useNavigate();
  const formData = watch();
  const lookupJob = useLookupJob();
  const jobOptions = useActiveCprJobOptions();

  const team = useActiveTeam();

  // State
  const [success, setSuccess] = useState(false);

  // We need to filter out payrolls covering periods before the selected job's start date
  const [allWeekEndingOptions, setAllWeekEndingOptions] = useState<Option<string>[]>([]);
  const [filteredWeekEndingOptions, setFilteredWeekEndingOptions] = useState<Option<string>[]>([]);

  const formatOptions: Option<{ format: CPRFormat; restrictedTo?: string[] }>[] = [
    { label: "Miter PDF", value: { format: "unsigned_pdf" } },
    { label: "WH-347 PDF", value: { format: "wh347_pdf" } },
    { label: "LCP Tracker upload", value: { format: "lcp_upload" } },
    { label: "eMars upload", value: { format: "emars_upload" } },
    { label: "CA DIR upload", value: { format: "dir_xml_upload", restrictedTo: ["CA"] } },
    { label: "IL DOL upload", value: { format: "il_upload", restrictedTo: ["IL"] } },
    { label: "WA LNI upload", value: { format: "wa_xml_upload", restrictedTo: ["WA"] } },
    { label: "NJ DOL PDF", value: { format: "nj_pdf", restrictedTo: ["NJ"] } },
    { label: "NJ CSV", value: { format: "nj_csv", restrictedTo: ["NJ"] } },
    { label: "NJ CSV Fringe Benefits", value: { format: "nj_csv_fringes", restrictedTo: ["NJ"] } },
    { label: "NYC PDF", value: { format: "nyc_pdf", restrictedTo: ["NY"] } },
    {
      label: "NYC Labor Law Payroll Report PDF",
      value: { format: "nyc_labor_law_pdf", restrictedTo: ["NY"] },
    },
    {
      label: "NYC Weekly Payroll Report PDF",
      value: { format: "nyc_weekly_pdf", restrictedTo: ["NY"] },
    },
    { label: "OR BOLI PDF", value: { format: "or_pdf", restrictedTo: ["OR"] } },
    { label: "PA DLI PDF", value: { format: "pa_pdf", restrictedTo: ["PA"] } },
  ];

  const [selectedWeekEnding, setSelectedWeekEnding] = useState<Option<string> | null>(null);
  const [selectedJob, setSelectedJob] = useState<AggregatedJob | undefined>();
  const [selectedState, setSelectedState] = useState<{ name: string; abbreviation: string } | undefined>();
  const [enteringSignParams, setEnteringSignParams] = useState<$TSFixMe | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitFormat, setSubmitFormat] = useState<CPRFormat>("unsigned_pdf");
  const [signUrl, setSignUrl] = useState<string | undefined>();
  const [exceptions, setExceptions] = useState<Exception[]>([]);
  const [showRawSSNs, setShowRawSSNs] = useState(false);
  const [onlyIncludeClassificationFringeContributions, setOnlyIncludeClassificationFringeContributions] =
    useState(false);
  const [signerName, setSignerName] = useState<string>();
  const [signerTitle, setSignerTitle] = useState<string>();
  const [companyNameOverride, setCompanyNameOverride] = useState<string | undefined>();
  const [feinOverride, setFeinOverride] = useState<string | undefined>();

  const addressOverride = form.watch().otherAddress;

  const defaultSignerName = useMemo(() => {
    if (!activeUser) return;
    return buildCompanyUserName(activeUser);
  }, [activeUser]);

  const defaultSignerTitle = useMemo(() => {
    return activeTeamMember?.title;
  }, [team, activeTeamMember?.title]);

  useEffect(() => {
    if (showRawSSNs) {
      setReverifyUser(true);
    }
  }, [showRawSSNs]);

  useEffect(() => {
    setSignerName(defaultSignerName);
  }, [defaultSignerName]);

  useEffect(() => {
    setSignerTitle(defaultSignerTitle);
  }, [defaultSignerTitle]);

  const getWeekEndingOptions = async () => {
    try {
      const response = await MiterAPI.pay_schedules.retrieve({
        filter: [
          {
            field: "company_id",
            value: activeCompanyId,
          },
          {
            field: "inactive",
            value: { $in: [null, false] },
          },
        ],
      });

      if (response.error) throw new Error(response.error);

      const paySchedules = response.filter((ps) => canAccessPaySchedule(ps));

      const weeks = new Set<string>();
      for (const ps of paySchedules) {
        const payFrequency = ps.check_pay_schedule.pay_frequency;
        if (!isWeeklyOrBiweekly(payFrequency)) continue;

        const threeYearsAgo = DateTime.now().minus({ years: 3 });
        const periods = getPayPeriodsOfPaySchedule(ps, threeYearsAgo, DateTime.now());

        for (const period of periods) {
          if (payFrequency === "biweekly") {
            weeks.add(DateTime.fromISO(period.periodEnd).minus({ weeks: 1 }).toISODate());
          }
          weeks.add(period.periodEnd);
        }
      }

      const options = [...weeks]
        .map((week): Option<string> => ({ label: DateTime.fromISO(week).toFormat("DD"), value: week }))
        .sort((a, b) => baseSensitiveCompare(b.value, a.value));

      setAllWeekEndingOptions(options);
      setFilteredWeekEndingOptions(options);
    } catch (e) {
      Notifier.error("There was an error. We're looking into it!");
    }
  };

  const filteredFormatOptions: Option<CPRFormat>[] = useMemo(
    () =>
      formatOptions
        .filter(
          (option) =>
            !option.value.restrictedTo ||
            (selectedState?.abbreviation && option.value.restrictedTo.includes(selectedState.abbreviation))
        )
        .map((option) => {
          return {
            label: option.label,
            value: option.value.format,
          };
        }),
    [selectedState]
  );

  const handleJobSelect = (option: Option<string> | null | undefined) => {
    const newlySelectedJob = lookupJob(option?.value);
    setSelectedJob(newlySelectedJob);
    const newState = newlySelectedJob?.address?.state
      ? states.find((s) => s.abbreviation === newlySelectedJob?.address?.state)
      : undefined;
    setSelectedState(newState);
    if (newState) {
      const selectedFormat = formatOptions.find((o) => o.value.format === submitFormat);
      const shouldResetFormat =
        selectedFormat?.value?.restrictedTo &&
        !selectedFormat.value.restrictedTo.includes(newState.abbreviation);
      if (shouldResetFormat) {
        setSubmitFormat("unsigned_pdf");
        setOnlyIncludeClassificationFringeContributions(false);
      }
    }
    const startDate = newlySelectedJob?.cpr_info?.payroll_start_date || newlySelectedJob?.start_date;
    setSelectedWeekEnding(null);
    const newWeekEndingOptions = allWeekEndingOptions.filter((o) => !startDate || o.value >= startDate);
    setFilteredWeekEndingOptions(newWeekEndingOptions);
  };

  const buildFilename = (base: string, extension: string) => {
    let filename = "";
    if (selectedWeekEnding?.value) {
      const weekEndingDate = DateTime.fromISO(selectedWeekEnding.value).toFormat("yyyy-MM-dd");
      filename += `${weekEndingDate} - `;
    }
    filename += base;
    if (selectedJob?.name) {
      let jobCodeAndName = selectedJob.name.trim();
      const jobCode = selectedJob.code?.trim();
      if (jobCode && !jobCodeAndName.startsWith(jobCode)) {
        jobCodeAndName = `${jobCode} ${jobCodeAndName}`;
      }
      filename += ` - ${jobCodeAndName}`;
    }
    filename += `.${extension}`;
    return filename;
  };

  const downloadUnsigned = async (payload) => {
    try {
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      const filename = buildFilename("Unsigned Certified Payroll Report", "pdf");
      downloadPdfFromBase64String(response.data, filename);
      Notifier.success("Unsigned form downloaded successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error downloading the form.");
    }
  };

  const downloadLcpUpload = async (payload) => {
    try {
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      const unprependedCsv = response.csv.split("set=utf-8,")[1];
      const workbook = XLSX.read(unprependedCsv, { type: "string", raw: true });
      const filename = buildFilename("LCP Tracker upload", "xls");
      XLSX.writeFile(workbook, filename);
    } catch (e) {
      Notifier.error("There was an error downloading the report.");
    }
  };

  const downloadCSV = async (payload, fileName: string) => {
    try {
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      const unprependedCsv = response.csv.split("set=utf-8,")[1];
      const workbook = XLSX.read(unprependedCsv, { type: "string", raw: true });
      const filename = buildFilename(fileName, "csv");
      XLSX.writeFile(workbook, filename);
    } catch (e) {
      Notifier.error("There was an error downloading the report.");
    }
  };

  const downloadIlUpload = async (payload) => {
    try {
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      const unprependedCsv = response.csv.split("set=utf-8,")[1];
      const workbook = XLSX.read(unprependedCsv, { type: "string", raw: true });
      const filename = buildFilename("IL IDOL", "xls");
      XLSX.writeFile(workbook, filename);
    } catch (e) {
      Notifier.error("There was an error downloading the report.");
    }
  };

  const downloadPdf = async (payload, formName) => {
    try {
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      const filename = buildFilename(formName, "pdf");
      downloadPdfFromBase64String(response.data, filename);
      Notifier.success(`${formName} form downloaded successfully.`);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error downloading the form.");
    }
  };

  const downloadXmlUpload = async (payload) => {
    try {
      const response = await MiterAPI.reports.create(payload);
      if (response.error) {
        throw new Error(response.error);
      }

      const pom = document.createElement("a");
      const bb = new Blob([response.xml], { type: "text/plain" });

      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", response.filename);

      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":");
      pom.draggable = true;
      pom.classList.add("dragout");

      pom.click();
      Notifier.success("XML file successfully downloaded.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  const submit = async (data) => {
    setSubmitting(true);

    if (!activeUser?.email) {
      return Notifier.error("Please add an email address for the signer.");
    }

    const payload = {
      type: "cpr",
      params: {
        job: data.job?.value,
        weekEnding: data.week_ending?.value,
        company: activeCompanyId!,
        exceptions: exceptions,
        remarks: data.remarks,
        otherJobs: data.otherJobs?.map((option) => option.value) || [],
        signerName,
        signerTitle,
        signerEmail: activeUser.email,
        showRawSSNs,
        onlyIncludeClassificationFringeContributions,
        feinOverride: feinOverride,
        companyNameOverride: companyNameOverride,
        addressOverride: addressOverride,
      },
      format: submitFormat,
    };

    if (submitFormat === "save_signed") {
      setEnteringSignParams(payload);
    } else if (submitFormat === "unsigned_pdf") {
      await downloadUnsigned(payload);
    } else if (submitFormat === "wh347_pdf") {
      await downloadPdf(payload, "WH-347");
    } else if (submitFormat === "lcp_upload") {
      await downloadLcpUpload(payload);
    } else if (submitFormat === "emars_upload") {
      await downloadCSV(payload, "eMars upload");
    } else if (submitFormat === "dir_xml_upload") {
      await downloadXmlUpload(payload);
    } else if (submitFormat === "il_upload") {
      await downloadIlUpload(payload);
    } else if (submitFormat === "wa_xml_upload") {
      await downloadXmlUpload(payload);
    } else if (submitFormat === "nj_pdf") {
      await downloadPdf(payload, "NJ MW-562");
    } else if (submitFormat === "nj_csv") {
      await downloadCSV(payload, "NJ CPR");
    } else if (submitFormat === "nj_csv_fringes") {
      await downloadCSV(payload, "NJ CPR Fringe Benefits");
    } else if (submitFormat === "nyc_pdf") {
      await downloadPdf(payload, "NYC");
    } else if (submitFormat === "nyc_labor_law_pdf") {
      await downloadPdf(payload, "NYC Labor");
    } else if (submitFormat === "nyc_weekly_pdf") {
      await downloadPdf(payload, "NYC Weekly");
    } else if (submitFormat === "or_pdf") {
      await downloadPdf(payload, "OR WH-38");
    } else if (submitFormat === "pa_pdf") {
      await downloadPdf(payload, "PA LLC-25");
    }
    setSubmitting(false);
  };

  const reportObject = reportList.find((report) => report.slug === "cpr");

  const handleSignFinish = async (redirectUrl: string): Promise<void> => {
    setSignUrl(undefined);
    setSubmitting(true);
    const paramsFromUrl = redirectUrl.split("?")[1];
    if (!paramsFromUrl) throw new Error("");
    const finishParams = QueryString.parse(paramsFromUrl);
    try {
      const payload = {
        type: "cpr",
        params: {
          documentGroupEid: finishParams.documentGroupEid,
          company: activeCompanyId!,
          jobId: formData.job?.value,
          weekEnding: formData.week_ending?.value,
          showRawSSNs,
          onlyIncludeClassificationFringeContributions,
        },
        format: "save_signed",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      downloadPdfFromBase64String(response.data, response.file.originalname);
      setSuccess(true);
    } catch (e) {
      Notifier.error("There was an error handling the e-signature. We're looking into it!");
    }
    setSubmitting(false);
  };

  const handleNewSignUrl = (url) => {
    setEnteringSignParams(false);
    setSignUrl(url);
  };

  useEffect(() => {
    getWeekEndingOptions();
  }, []);

  useEffect(() => {
    setCanSubmit(formData.job?.value && formData.week_ending?.value && submitFormat ? true : false);
  }, [formData]);

  return (
    <div className="page-content">
      <Helmet>
        <title>Certified Payroll Report | Miter</title>
      </Helmet>
      <div className="vertical-spacer" />
      {success && (
        <BasicModal
          headerText="Success!"
          button2Text="Done"
          button1Action={() => setSuccess(false)}
          button2Action={() => setSuccess(false)}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={checkmark} style={{ width: 25, marginBottom: 15, marginTop: 0 }} />
            <div className="blue-text-wrapper">
              {`Congrats! You successfully signed your report. You can find your signed form in two places: (1) your Downloads folder and (2) the Files folder for the job.`}
            </div>
          </div>
        </BasicModal>
      )}
      {enteringSignParams && (
        <SignCPRModal
          payload={enteringSignParams}
          handleNewSignUrl={handleNewSignUrl}
          hide={() => setEnteringSignParams(false)}
          headerText={`Sign your Certified Payroll Report`}
        />
      )}
      <AnvilSignatureModal
        signURL={signUrl}
        isOpen={signUrl}
        onClose={() => setSignUrl(undefined)}
        onFinish={(params) => handleSignFinish(params)}
      />
      <div className="page-content-header">
        <div className="flex space-between">
          <div>
            <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
              REPORTS
            </div>
            <h1 style={{ marginTop: 0 }}>{`Create a Certified Payroll Report`}</h1>
          </div>
          {can("reports:cpr") && (
            <Button className="button-1" text="Settings" onClick={() => navigate("/reports/cpr/settings")} />
          )}
        </div>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <div className="vertical-spacer-small"></div>
      <div className="yellow-text-container flex" style={{ maxWidth: 1000 }}>
        <Megaphone weight="duotone" color="#ead053" size={25} />
        <div style={{ marginLeft: 10 }}>
          Fun fact: Miter&apos;s Certified Payroll Reports are designed to comply with most federal and state
          requirements (WH-347).
          <div className="vertical-spacer-small"></div>
          To see if your state accepts Miter reports,
          <ClickableText
            text=" check out our Miter Guide."
            onClick={() => goToMiterGuide("reports/certified-payroll")}
          />{" "}
        </div>
      </div>
      <div style={{ maxWidth: 500 }}>
        <div className="vertical-spacer"></div>
        <JobInput
          label="Job"
          labelInfo="Showing all jobs where Certified Payroll Reporting is enabled."
          control={control}
          type="select"
          underlineTooltip={true}
          name="job"
          form={form}
          errors={errors}
          onChange={handleJobSelect}
          value={jobOptions.find((option) => option.value === selectedJob?._id.toString())}
          editing={true}
          options={jobOptions}
        />
        <Formblock
          label="Week ending"
          labelInfo="Showing all period end dates of paid payrolls on or after the job start date."
          control={control}
          type="select"
          underlineTooltip={true}
          value={selectedWeekEnding}
          onChange={setSelectedWeekEnding}
          name="week_ending"
          errors={errors}
          editing={true}
          options={filteredWeekEndingOptions}
        />
        <Formblock
          label="State"
          labelInfo="Showing all formats available for the selected state."
          control={control}
          type="select"
          value={stateOptions.find((o) => o.value === selectedState?.abbreviation)}
          onChange={(o) => setSelectedState(states.find((s) => s.abbreviation === o.value))}
          name="state"
          errors={errors}
          editing={true}
          options={stateOptions}
        />
        <Formblock
          register={register}
          label="Report format"
          control={control}
          options={filteredFormatOptions}
          type="select"
          onChange={(o) => setSubmitFormat(o.value)}
          value={filteredFormatOptions.find((o) => o.value === submitFormat)}
          name="submit_format"
          errors={errors}
          editing={true}
        />
        <Formblock
          register={register}
          label="Signer name"
          type="text"
          onChange={(e) => setSignerName(e.target.value)}
          name="signer_name"
          errors={errors}
          editing={true}
          defaultValue={defaultSignerName}
        />
        <Formblock
          register={register}
          label="Signer title"
          type="text"
          onChange={(e) => setSignerTitle(e.target.value)}
          name="signer_name"
          errors={errors}
          editing={true}
          defaultValue={defaultSignerTitle}
        />
        <Formblock
          label="Show sensitive employee data"
          labelInfo={`If selected, employees' full SSNs and filing status will be shown on the report. Otherwise, just the last 4 digits of each SSN will be shown.`}
          control={control}
          type="checkbox"
          onChange={(e) => setShowRawSSNs(e.target.checked)}
          name="show_full_ssns"
          underlineTooltip={true}
          errors={errors}
          editing={true}
          style={{ alignItems: "flex-start" }}
        />
        <Formblock
          label="Only include classification fringe contributions"
          labelInfo={`If selected, only fringe contributions will be used to calculate hourly contributions. By default, Miter uses all employee benefits and fringes to calculate this number.`}
          control={control}
          type="checkbox"
          onChange={(e) => setOnlyIncludeClassificationFringeContributions(e.target.checked)}
          name="only_include_classification_fringe_contributions"
          underlineTooltip={true}
          errors={errors}
          editing={true}
          style={{ alignItems: "flex-start" }}
        />
        <div className="vertical-spacer-small"></div>
        <AddCompanyNameOverride
          form={form}
          companyNameOverride={companyNameOverride}
          setCompanyNameOverride={setCompanyNameOverride}
        />
        <AddAddressOverride form={form} />
        <AddFeinOverride form={form} feinOverride={feinOverride} setFeinOverride={setFeinOverride} />
        <AddOtherJobs
          form={form}
          jobOptions={jobOptions.filter((option) => option.value !== selectedJob?._id.toString())}
        />
        {canAddExceptionsToFormat(submitFormat) && (
          <Exceptions exceptions={exceptions} setExceptions={setExceptions} />
        )}
        {canAddRemarksToFormat(submitFormat) && <Remarks form={form} />}
        <div className="vertical-spacer-large"></div>

        <div className="vertical-spacer"></div>
        <Button
          className="button-2 no-margin"
          style={{ minWidth: 150 }}
          onClick={handleSubmit(submit)}
          text="Submit"
          loading={submitting}
          disabled={!canSubmit}
        />
        <div className="vertical-spacer-large"></div>
      </div>

      <div className="vertical-spacer-large"></div>
    </div>
  );
};
