import {
  AggregatedOnboardingChecklistItem,
  OnboardingChecklistCompletionData,
  OnboardingTask,
} from "dashboard/types/onboarding-types";
import React, { FC, useEffect, useState } from "react";
import { PersonalInformationWizardScreen } from "./PersonalInformationWizardScreen";
import { Notifier, Wizard } from "ui";
import { EEOWizardScreen } from "./EEOWizardScreen";
import { CustomFieldsWizardScreen } from "./CustomFieldsWizardScreen";
import { filterTasksByAssignee, filterTasksByCompletableStatus, getChecklistCompletionData } from "./utils";
import { DocumentsWizardScreen } from "./DocumentsWizardScreen";
import { notNullish } from "miter-utils";
import { FormsWizardScreen } from "./FormsWizardScreen";
import { EmployeeI9WizardScreen } from "./EmployeeI9WizardScreen";
import { WitholdInformationWizardScreen } from "./WitholdingInformationWizardScreen";
import { PaymentWizardScreen } from "./PaymentWizardScreen";
import { EmployerI9WizardScreen } from "./EmployerI9WizardScreen";
import { PhoneWizardScreen } from "./PhoneWizardScreen";
import { EmploymentInformationScreen } from "./EmploymentInformationScreen";
import { Company, MiterAPI } from "dashboard/miter";
import { CustomTaskWizardScreen } from "./CustomTaskWizardScreen";
import { TeamPortalUser } from "team-portal/utils/miter";
import { UploadCertificationWizardScreen } from "..";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  company: Company | TeamPortalUser["company"] | null;
  checklistItem: AggregatedOnboardingChecklistItem;
  getOnboardingChecklistItem: () => void;
  assigneeUserId: string;
  startIndex?: number;
};

export const OnboardingChecklistWizard: FC<Props> = ({
  company,
  checklistItem,
  getOnboardingChecklistItem,
  onExit,
  onComplete,
  assigneeUserId,
  startIndex,
}) => {
  const {
    cached_onboarding_checklist: { tasks },
  } = checklistItem;
  const [completionData, setCompletionData] = useState<OnboardingChecklistCompletionData | null>(null);

  const statusUpdate = async () => {
    try {
      const res = await MiterAPI.onboarding_checklist_items.update_team_member({
        tm_id: checklistItem.new_hire._id,
        checklist_item_id: checklistItem._id,
      });
      if (res.error) {
        throw new Error(res.error);
      }
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  const onExitWithStatusUpdate = async () => {
    await statusUpdate();
    onExit();
  };

  const onCompleteWithStatusUpdate = async () => {
    await statusUpdate();
    onComplete();
  };

  useEffect(() => {
    const asyncFunction = async () => {
      const res = await getChecklistCompletionData({
        new_hire_id: checklistItem.new_hire._id,
        checklist_id: checklistItem.cached_onboarding_checklist._id,
        assignee_user_id: assigneeUserId,
      });
      setCompletionData(res);
    };
    asyncFunction();
  }, [checklistItem.new_hire.company]);

  const buildScreens = () => {
    const tasksFilteredByAssignee = filterTasksByAssignee(tasks, assigneeUserId, checklistItem.new_hire.user);
    const finalFilteredTasks = filterTasksByCompletableStatus(
      tasksFilteredByAssignee,
      checklistItem.statuses
    );
    return finalFilteredTasks.map(mapScreen).filter(notNullish);
  };

  const mapScreen = (task: OnboardingTask) => {
    if (!completionData) return null;
    switch (task.type) {
      case "personal_information":
        return (
          <PersonalInformationWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
          />
        );
      case "eeo_information":
        return (
          <EEOWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
          />
        );
      case "documents":
        const document = completionData?.documents.find((doc) => doc.task_id === task._id);
        if (!document) return null;
        return (
          <DocumentsWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            document={document}
            assignee={completionData.assignee}
          />
        );
      case "custom_fields":
        return (
          <CustomFieldsWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            customFields={completionData?.custom_field_values || []}
            assignee={completionData.assignee}
          />
        );

      case "forms":
        const form = completionData?.forms.find((form) => form.task_id === task._id);

        if (!form) return null;
        return (
          <FormsWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            formToFill={form}
            assignee={completionData.assignee}
          />
        );

      case "certifications":
        const certification = completionData?.certifications.find((cert) => cert.task_id === task._id);

        if (!certification) return null;
        return (
          <UploadCertificationWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            certificationOwner={checklistItem.new_hire}
            certificationType={certification.certification.certification_type}
            certification={certification.certification}
            companyId={checklistItem.new_hire.company}
            name={checklistItem.cached_onboarding_checklist.title}
            onClose={() => {}}
            readOnly={false}
          />
        );

      case "i9_employee":
        if (!completionData?.i9) return null;
        return (
          <EmployeeI9WizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            i9={completionData.i9}
            assignee={completionData.assignee}
          />
        );

      case "i9_employer":
        if (!completionData?.i9) return null;
        return (
          <EmployerI9WizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            i9={completionData.i9}
            assignee={completionData.assignee}
          />
        );

      case "payment_method":
        return (
          <PaymentWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
            isPayroll={true}
          />
        );

      case "withholding_information":
        return (
          <WitholdInformationWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
          />
        );

      case "bank_account":
        return (
          <PaymentWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
            isPayroll={false}
          />
        );

      case "phone_number":
        return (
          <PhoneWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
          />
        );

      case "employment_information":
        return (
          <EmploymentInformationScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            company={company}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
            pay={completionData.pay}
          />
        );

      case "custom":
        return (
          <CustomTaskWizardScreen
            getOnboardingChecklistItem={getOnboardingChecklistItem}
            onboardingChecklistItem={checklistItem}
            name={task.title}
            task={task}
            assignee={completionData.assignee}
          />
        );

      default:
        Notifier.error("Unknown task type: " + task.type);
        return null;
    }
  };

  if (!completionData) return null;

  const screens = buildScreens();

  const defaultIndex = Math.min(startIndex || 0, screens.length - 1);

  return (
    <Wizard
      onExit={onExitWithStatusUpdate}
      onComplete={onCompleteWithStatusUpdate}
      defaultCanGoNext={true}
      startIndex={defaultIndex}
    >
      {screens}
    </Wizard>
  );
};
