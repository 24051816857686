import { RequestTableEntry } from "dashboard/pages/time-off/TimeOffRequestsTable";
import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import {
  AggregatedJob,
  AggregatedMiterEarning,
  AggregatedTeamMember,
  AggregatedTimeOffRequest,
  Job,
  TimeOffRequest,
} from "dashboard/miter";
import { EditPayPeriodTimesheetRow } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/TimesheetsByPayPeriodEditor";
import { PayPeriodHoursRow } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/TimesheetsByPayPeriod";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

/*
 * TIME OFF REQUESTS
 */

type TimeOffRequestAbilitiesOpts = { inboxMode?: InboxMode };

type TimeOffRequestParams =
  | AggregatedTimeOffRequest
  | AggregatedTimeOffRequest[]
  | TimeOffRequest
  | TimeOffRequest[]
  | EditPayPeriodTimesheetRow
  | PayPeriodHoursRow
  | PayPeriodHoursRow[]
  | AggregatedMiterEarning
  | AggregatedMiterEarning[]
  | RequestTableEntry
  | RequestTableEntry[]
  | undefined;

type TimeOffRequestAction = "create" | "read" | "update" | "approve" | "delete";
export type TimeOffRequestAbilities = {
  can: (action: TimeOffRequestAction, items: TimeOffRequestParams) => boolean;
  cannot: (action: TimeOffRequestAction, items: TimeOffRequestParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: TimeOffRequestAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: TimeOffRequestAction) => (job: AggregatedJob | Job) => boolean;
};

export const useTimeOffRequestAbilities = (opts?: TimeOffRequestAbilitiesOpts): TimeOffRequestAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: TimeOffRequestAction, items?: TimeOffRequestParams) => {
      if (!items) return false;

      // If we are in inbox mode, we can always read, approve, and update anything in the inbox
      const isApprover = opts?.inboxMode === "approval";

      // either approval or needs attention mode
      const isInboxMode = opts?.inboxMode != undefined;

      const timeOffRequests = Array.isArray(items) ? items : [items];

      return timeOffRequests.every((timeOffRequest) => {
        const teamMemberId = getTeamMemberId(timeOffRequest);
        const isPersonal = teamMemberId === activeTeamMember?._id;

        if (isPersonal) {
          switch (action) {
            case "create":
              return can_("time_off:requests:personal:create");
            case "read":
              return can_("time_off:requests:personal:read") || isInboxMode;
            case "approve":
              return can_("time_off:requests:personal:approve") || isApprover;
            case "update":
              return can_("time_off:requests:personal:update") || isInboxMode;
            case "delete":
              return can_("time_off:requests:personal:delete");
            default:
              return false;
          }
        } else {
          switch (action) {
            case "create":
              return can_("time_off:requests:others:create", { teamMember: teamMemberId });
            case "read":
              return can_("time_off:requests:others:read", { teamMember: teamMemberId }) || isApprover;
            case "approve":
              return can_("time_off:requests:others:approve", { teamMember: teamMemberId }) || isApprover;
            case "update":
              return can_("time_off:requests:others:update", { teamMember: teamMemberId }) || isApprover;
            case "delete":
              return can_("time_off:requests:others:delete", { teamMember: teamMemberId });
            default:
              return false;
          }
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: TimeOffRequestAction, items: TimeOffRequestParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    personalPermissionPath: "time_off:requests:personal",
    othersPermissionPath: "time_off:requests:others",
    teamMemberField: { fieldName: "employee._id", fieldType: "_id" },
    appModule: "human_resources",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<TimeOffRequestAction>("time_off:requests");

  /** Job predicate */
  const jobPredicate = useAbilitiesJobPredicate<TimeOffRequestAction>("time_off:requests");

  return useMemo(
    () => ({ can, cannot, filter, teamPredicate, jobPredicate }),
    [can, cannot, filter, teamPredicate, jobPredicate]
  );
};

const getTeamMemberId = (
  item:
    | AggregatedTimeOffRequest
    | RequestTableEntry
    | TimeOffRequest
    | EditPayPeriodTimesheetRow
    | PayPeriodHoursRow
    | AggregatedMiterEarning
): string | undefined => {
  if ("team_member_id" in item) {
    return item.team_member_id;
  } else if ("team_member" in item) {
    return typeof item.team_member === "string" ? item.team_member : item.team_member?._id;
  } else if ("employee" in item) {
    return typeof item.employee === "string" ? item.employee : item.employee?._id;
  } else {
    return undefined;
  }
};
