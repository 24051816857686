import React, { useEffect } from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import styles from "./auth.module.css";
import { Loader } from "ui";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Notifier } from "dashboard/utils";
import { useSetAuthenticatedUserData } from "dashboard/hooks/atom-hooks";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const setAuthenticatedUserData = useSetAuthenticatedUserData();

  useEffect(() => {
    try {
      setAuthenticatedUserData(null);
      localStorage.removeItem("guidesToken");
      navigate("/login", { replace: true });
    } catch (e: $TSFixMe) {
      navigate("/home", { replace: true });
      Notifier.error(e.message);
    }
  }, []);

  return (
    <div className={styles["login-wrapper"]}>
      <Helmet>
        <title>Log out | Miter</title>
      </Helmet>
      <div className={styles["login-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Logging out of Miter</h1>
            <p>Please wait as we redirect you.</p>
          </div>
          <Loader />
        </div>
      </div>
    </div>
  );
};

export default Logout;
