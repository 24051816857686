import AppContext from "dashboard/contexts/app-context";
import React, { useContext } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui";
import { CompanyPayrollSettings } from "backend/models/company/company-settings";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { TRUEBECK_COMPANY_ID } from "dashboard/utils";

export const SalaryPayrollSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);

  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const [holdPayConstant, setHoldPayConstant] = React.useState(
    !!activeCompany?.settings.payroll.hold_exempt_salary_pay_constant
  );
  const [allocateSalary, setAllocateSalary] = React.useState(
    !!activeCompany?.settings.payroll.allocate_salary_based_on_timesheets
  );
  const [disableAutoSalaryEarnings, setDisableAutoSalaryEarnings] = React.useState(
    !!activeCompany?.settings.payroll.disable_automatic_salary_earnings
  );

  const handleChange = async (newSetting: boolean, settingName: keyof CompanyPayrollSettings) => {
    try {
      if (!activeCompanyId) throw new Error("No active company id");

      const response = await MiterAPI.companies.update(activeCompanyId, {
        ["settings.payroll." + settingName]: newSetting,
      });

      if (response.error) throw new Error(response.error);
      Notifier.success("Payroll settings updated successfully.");
      fetchUserData();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
  };

  const handleHoldPayConstantChange = (newSetting: boolean) => {
    setHoldPayConstant(newSetting);
    handleChange(newSetting, "hold_exempt_salary_pay_constant");
  };

  const handleAllocateSalaryChange = (newSetting: boolean) => {
    setAllocateSalary(newSetting);
    handleChange(newSetting, "allocate_salary_based_on_timesheets");
  };

  const handleDisableAutoSalaryEarnings = (newSetting: boolean) => {
    setDisableAutoSalaryEarnings(newSetting);
    handleChange(newSetting, "disable_automatic_salary_earnings");
  };

  return (
    <>
      {(!IS_PRODUCTION || activeCompanyId === TRUEBECK_COMPANY_ID) && (
        <SettingsCard title="Disable salary earnings" contentStyle={{ color: "rgb(51,51,51)" }}>
          <div className="flex">
            <input
              type="checkbox"
              style={{ margin: "0px 12px" }}
              onChange={(e) => handleDisableAutoSalaryEarnings(e.target.checked)}
              checked={disableAutoSalaryEarnings}
            />
            <label>
              Disable the auto-creation of salary earnings for salaried employees. Earnings will need to be
              input via timesheets or manually during payroll processing.
            </label>
          </div>
        </SettingsCard>
      )}

      {!disableAutoSalaryEarnings && (
        <>
          <SettingsCard
            title="Timesheets for exempt salaried team members"
            contentStyle={{ color: "rgb(51,51,51)" }}
          >
            <div className="flex">
              <input
                type="radio"
                style={{ margin: "0px 12px" }}
                onChange={() => handleHoldPayConstantChange(true)}
                checked={holdPayConstant}
              />
              <label>
                Hold salary pay constant: Ensure overtime-exempt salaried team members always receive their
                normal salary pay, regardless of hours worked. Ignore prevailing wages, union rates, and other
                timesheet-specific pay rates.
              </label>
            </div>
            <div className="flex" style={{ marginTop: 10 }}>
              <input
                type="radio"
                style={{ margin: "0px 12px" }}
                onChange={() => handleHoldPayConstantChange(false)}
                checked={!holdPayConstant}
              />
              <label>
                Use timesheet-specific rates: When a timesheet has a specific rate, use it to calculate the
                employee&apos;s earnings.
              </label>
            </div>
          </SettingsCard>
          <SettingsCard title="Salary earnings job-costing" contentStyle={{ color: "rgb(51,51,51)" }}>
            <div className="flex">
              <input
                type="checkbox"
                style={{ margin: "0px 12px" }}
                onChange={(e) => handleAllocateSalaryChange(e.target.checked)}
                checked={allocateSalary}
              />
              <label>
                Allocate based on timesheets: if a salaried team member&apos;s timesheets do not cover the
                full pay period, allocate their remaining salary earnings to jobs and activities in proportion
                to their timesheet hours.
              </label>
            </div>
          </SettingsCard>
        </>
      )}
    </>
  );
};
