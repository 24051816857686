import { Notifier } from "dashboard/utils";
import React, { useMemo, useState } from "react";
import { ConfirmModal, TableV2 } from "ui";
import { LedgerMappingModal } from "./LedgerMappingModal";
import { LedgerMapping, MiterAPI } from "dashboard/miter";
import { useActiveCompanyId, useLedgerMappingsAtom } from "dashboard/hooks/atom-hooks";
import { ColumnConfig } from "ui/table-v2/Table";
import { useEnhancedSearchParams } from "miter-utils";

type LedgerMappingTableEntry = LedgerMapping;

export const LedgerMappings: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const [ledgerMappings, setLedgerMappings] = useLedgerMappingsAtom();
  const { searchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const showMappingModalId = searchParams.get("lmid");
  const [creating, setCreating] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [selectedRows, setSelectedRows] = useState<LedgerMappingTableEntry[]>([]);
  const [archiveConfirmation, setArchiveConfirmation] = useState(false);

  const columns: ColumnConfig<LedgerMappingTableEntry>[] = [
    { field: "name", headerName: "Name", minWidth: 300, sortable: false },
    {
      field: "is_company_default",
      headerName: "Company level",
      minWidth: 200,
      dataType: "boolean",
      sortable: false,
    },
  ];

  const tableEntries: LedgerMappingTableEntry[] = useMemo(() => {
    const rows: LedgerMappingTableEntry[] = ledgerMappings;
    rows.sort((a, _b) => {
      if (a.is_company_default) return -1;
      else return 1;
    });
    return rows;
  }, [ledgerMappings]);

  const onShowMappingModal = (id?: string) => {
    setSearchParams({ lmid: id || undefined });
  };

  const handleNewClick = async () => {
    if (!activeCompanyId) return;
    setCreating(true);
    try {
      const response = await MiterAPI.ledger_mappings.create({
        company_id: activeCompanyId,
        name: "New mapping",
      });
      if (response.error) throw new Error(response.error);
      setLedgerMappings((prev) => prev.concat(response));
      onShowMappingModal(response._id);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the ledger mapping. We're looking into it!");
    }
    setCreating(false);
  };

  const handleArchive = async () => {
    if (!activeCompanyId) return;
    setArchiving(true);
    try {
      const response = await MiterAPI.ledger_mappings.archive(
        selectedRows.map((row) => row._id),
        activeCompanyId
      );
      if (response.error) throw new Error(response.error);
      if (response.errors.length) {
        Notifier.warning(`There were ${response.errors.length} errors deleting the ledger mapping(s).`);
      } else {
        Notifier.success("Successfully deleted the ledger mapping(s).");
      }
      setLedgerMappings((prev) =>
        prev.filter(
          (mapping) =>
            !selectedRows.find((row) => row._id === mapping._id) &&
            !response.errors.find((error) => error._id !== mapping._id)
        )
      );
      setArchiveConfirmation(false);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the ledger mapping(s). We're looking into it!");
    }
    setArchiving(false);
  };

  const duplicateSelectedMappings = async () => {
    setDuplicating(true);
    const errors: string[] = [];
    const successes: LedgerMapping[] = [];
    await Promise.all(
      selectedRows.map(async (row) => {
        try {
          const { name, _id, created_at, updated_at, archived, is_company_default, ...rest } = row;
          const response = await MiterAPI.ledger_mappings.create({
            name: `Copy of ${row.name}`,
            ...rest,
          });
          if (response.error) throw new Error(response.error);
          successes.push(response);
        } catch (e) {
          errors.push(row.name);
        }
      })
    );
    setLedgerMappings((prev) => prev.concat(...successes));
    if (errors.length) {
      const errorString = errors.join(", ");
      Notifier.warning(`Errors duplicating the ledger mapping(s): ${errorString}.`);
    } else {
      Notifier.success("Successfully duplicated the ledger mapping(s).");
    }
    setDuplicating(false);
  };

  const dynamicActions = useMemo(() => {
    const defaultIsSelected = selectedRows.some((row) => row.is_company_default);
    return [
      {
        label: "Duplicate",
        className: "button-1",
        action: () => duplicateSelectedMappings(),
        loading: duplicating,
        important: true,
      },
      ...(defaultIsSelected
        ? []
        : [
            {
              label: "Delete",
              className: "button-3",
              action: () => setArchiveConfirmation(true),
              loading: archiving,
              important: true,
            },
          ]),
    ];
  }, [archiving, selectedRows]);

  const staticButtons = useMemo(() => {
    return [
      { label: "+ New", className: "button-2", action: handleNewClick, loading: creating, important: true },
    ];
  }, [creating, activeCompanyId]);

  return (
    <div>
      <div className="vertical-spacer-small"></div>
      <TableV2
        resource="ledger mappings"
        id="ledger-mappings"
        columns={columns}
        data={tableEntries}
        containerStyle={{ marginBottom: 10 }}
        onClick={(r) => onShowMappingModal(r?._id)}
        staticActions={staticButtons}
        onSelect={setSelectedRows}
        dynamicActions={dynamicActions}
      />
      {showMappingModalId && (
        <LedgerMappingModal ledgerMappingId={showMappingModalId} hide={() => onShowMappingModal(undefined)} />
      )}
      {archiveConfirmation && (
        <ConfirmModal
          yellowBodyText={true}
          body="Are you sure you want to delete the selected ledger mapping(s)?"
          onYes={handleArchive}
          loading={archiving}
          onNo={() => setArchiveConfirmation(false)}
        />
      )}
    </div>
  );
};
