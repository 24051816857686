import { motion, Reorder } from "framer-motion";
import React, { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { Button, DropdownButton, Formblock, Label, Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";

import { useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";
import { Form, FormParentType, FrontendModel, MiterAPI } from "dashboard/miter";
import { styles } from "miter-components/forms";

import ObjectID from "bson-objectid";
import { cloneDeep, delay, findIndex, findLastIndex, isEmpty, set } from "lodash";
import {
  ArrowDown,
  ArrowUp,
  Calendar,
  Camera,
  CaretDown,
  CaretUp,
  CheckSquare,
  Copy,
  Divide,
  DotsSixVertical,
  Eye,
  EyeSlash,
  ListBullets,
  ListDashes,
  NumberCircleOne,
  Plus,
  ScribbleLoop,
  TextAa,
  TextAlignJustify,
  Trash,
  X,
} from "phosphor-react";
import { FormComponent as FormComponent_, FormSection as FormSection_ } from "backend/models/form";
import { CreateFormParams } from "backend/services/form-service";
import { DateTime } from "luxon";
import { notNullish } from "miter-utils";
import { Assign, FormProvider, useForm, useFormContext } from "react-hook-form";
import { PerformanceTemplate, performanceTemplates } from "./templates/performance";
import { AckPerformanceTemplateChange } from "../performance/AckPerformanceTemplateChange";
import { FormField } from "dashboard/types/form-types";

export type FormSection = FrontendModel<FormSection_>;
export type FormComponent = FrontendModel<FormComponent_>;

const DEFAULT_MAX_FILES = Infinity;
const DEFAULT_MIN_FILES = 0;

export type FormComponentFormField = Assign<
  FormComponent,
  {
    showMoreValidations?: boolean;
    type: FormComponent["type"];
    validations?: Assign<NonNullable<FormField["validations"]>, { min_date?: DateTime; max_date?: DateTime }>;
  }
>;

type FormBuilderForm = {
  name: string;
  description?: string;
  components: FormComponentFormField[];
};

export type FormFieldOptions = {
  multiple?: boolean;
};

type Props = {
  formItem: Form | undefined;
  setFormItem?: Dispatch<SetStateAction<Form | undefined>>;
  parentType?: FormParentType;
  parentId?: string;
  name: string;
  readonly?: boolean;
  disableAutoSave?: boolean;
  template?: PerformanceTemplate;
  templateEnabled?: boolean;
  setTemplateEnabled?: Dispatch<SetStateAction<boolean>>;
};

export const FormBuilderScreen: React.FC<Props> = ({
  name,
  formItem,
  setFormItem,
  parentType,
  parentId,
  readonly,
  disableAutoSave,
  template,
  templateEnabled,
  setTemplateEnabled,
}) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const sectionsRef = useRef<HTMLDivElement>(null);
  const componentRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const form = useForm({
    mode: "all",
  });
  const { formState, clearErrors, register, errors: formErrors, setValue } = form;

  /*********************************************************
   * High level form states
   **********************************************************/
  const [wizardScreenRef, setWizardScreenRef] = useState<RefObject<HTMLDivElement> | undefined>();
  const [draggable, setDraggable] = useState(false);
  const [formData, setFormData] = useState<FormBuilderForm>(
    buildDefaultValues(formItem, template, !!templateEnabled, false)
  );

  const [activeFieldY, setActiveFieldY] = useState<number | undefined>(undefined);
  const [lastAutoSavedAt, setLastAutoSavedAt] = useState<DateTime>();
  // some validations are not controlled by react-hook-form, so we need to manually validate them
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  /*********************************************************
   * Field states
   **********************************************************/
  const [activeComponent, setActiveComponent] = useState<FormComponentFormField>();
  const [lastDraggedComponent, setLastDraggedComponent] = useState<FormComponentFormField>();

  /*********************************************************
   * useEffect's
   **********************************************************/
  useEffect(() => {
    if (curIndex === screens.length - 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on manual and react-hook-form validations
  useEffect(() => {
    if (Object.values(errors).filter(notNullish).length > 0 || !isEmpty(formState.errors)) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formState]);

  // Wizard handlers
  const onNext = async () => {
    await saveForm(formData);

    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  // Sync form
  useEffect(() => {
    if (!formItem) return;
    setFormData(buildDefaultValues(formItem, template, !!templateEnabled, false));
    setValue("name", formItem.name);
  }, [formItem, template]);

  // Autosave the form every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (disableAutoSave) return;
      saveForm(formData, true);
    }, 60000);

    return () => clearInterval(interval);
  }, [formData, disableAutoSave]);

  /*********************************************************
   *  Backend functions
   **********************************************************/
  const buildParams = (params: FormBuilderForm) => {
    if (!activeCompanyId || !activeUser) throw new Error("No active user");

    const finalComponents = params.components.map((c) => {
      const cleanedComponent = { ...c };

      if (c.type === "date") {
        const minDate = c.validations?.min_date
          ? typeof c.validations?.min_date === "string"
            ? DateTime.fromISO(c.validations?.min_date)
            : c.validations?.min_date
          : undefined;

        const maxDate = c.validations?.max_date
          ? typeof c.validations?.max_date === "string"
            ? DateTime.fromISO(c.validations?.max_date)
            : c.validations?.max_date
          : undefined;

        cleanedComponent.validations = {
          ...cleanedComponent.validations,
          min_date: minDate?.toISODate(),
          max_date: maxDate?.toISODate(),
        };
      }

      return cleanedComponent;
    });

    const data: CreateFormParams = {
      ...params,
      parent_type: parentType || "company",
      parent_id: parentId || activeCompanyId,
      company_id: activeCompanyId,
      last_updated_by: activeUser._id,
      // @ts-expect-error _id of each component is string, not ObjectId
      components: finalComponents,
    };

    return data;
  };

  const saveForm = async (params: FormBuilderForm, autosaving?: boolean) => {
    if (!setFormItem) return;

    // Don't autosave if there are errors
    if (Object.values(errors).filter(notNullish).length > 0 || !isEmpty(formState.errors)) return;

    try {
      const cleanedParams = buildParams(params);
      const res = formItem?._id
        ? await MiterAPI.forms.update(formItem._id, cleanedParams)
        : await MiterAPI.forms.create(cleanedParams);

      if (res.error) throw new Error(res.error);

      setFormItem(res);

      if (!autosaving) {
        Notifier.success("Form successfully saved!");
      } else {
        setLastAutoSavedAt(DateTime.now());
      }
    } catch (e: $TSFixMe) {
      console.error("Error saving form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   * Form component movement handlers
   **********************************************************

  /** Handle's any drag events that changes the order of the fields */
  const handleFieldsReorder = (newlyOrderedFields: FormComponentFormField[]) => {
    const updatedComponents = [...newlyOrderedFields];

    // Get the field that was moved by comparing with the last dragged component
    const movedFieldIndex = newlyOrderedFields.findIndex((field) => field._id === lastDraggedComponent?._id);

    // If the field that was moved, doesn't exist, return
    if (movedFieldIndex === -1) return;

    // Save the field that was moved
    const movedField = updatedComponents[movedFieldIndex];
    if (!movedField) return;

    // Get the field above the moved field
    const fieldAbove = updatedComponents[movedFieldIndex - 1];

    // If there is no field above this field, set the field's form section id to null (meaning it's not in a section)
    if (!fieldAbove) {
      movedField.form_section_id = null;
    } else {
      // If the field above is a section field, set the field's form section id to the field above's id
      if (fieldAbove.type === "section") {
        movedField.form_section_id = fieldAbove._id;
      } else {
        // If the field above is not a section field, set the field's form section id to the field above's form section id
        movedField.form_section_id = fieldAbove.form_section_id;
      }
    }

    setFormData({ ...formData, components: updatedComponents });
    setActiveComponent(updatedComponents[movedFieldIndex]);
  };

  /** Moves the section up, when the up arrow is clicked and sets the section being moved as the active section */
  const handleSectionMoveUp = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    section: FormComponentFormField
  ) => {
    // We need to stop event propogation so that the click event on the section doesn't fire,
    // which would cause the section to be set to active and the form manager would be moved
    // prematurely, before we know it's moved coordinates
    e.stopPropagation();

    const { components } = formData;
    const sectionIndex = components.findIndex((field) => field._id === section._id);
    if (sectionIndex === 0) return;

    // Get the index of the last field in this section
    let lastFieldInSectionIndex = findLastIndex(components, (f) => f.form_section_id === section._id);
    if (lastFieldInSectionIndex === -1) {
      lastFieldInSectionIndex = sectionIndex;
    }

    // Get the index of the previous section by finding the last section before this section
    const previousSectionIndex = findLastIndex(
      components.slice(0, sectionIndex),
      (f) => f.type === "section"
    );

    // If we didn't find a section above this section, do nothing because we can't move it up
    if (previousSectionIndex === -1) return;

    // Create a new array of fields with the section and it's fields moved to the previous section
    const newFields = [
      ...components.slice(0, previousSectionIndex),
      ...components.slice(sectionIndex, lastFieldInSectionIndex + 1),
      ...components.slice(previousSectionIndex, sectionIndex),
      ...(components.slice(lastFieldInSectionIndex + 1) || []),
    ];

    setFormData({ ...formData, components: newFields });
    setActiveComponent(section);
  };

  /** Moves the section down, when the down arrow is clicked and sets the section being moved as the active section */
  const handleSectionMoveDown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    section: FormComponentFormField
  ) => {
    // We need to stop event propogation so that the click event on the section doesn't fire,
    // which would cause the section to be set to active and the form manager would be moved
    // prematurely, before we know it's moved coordinates
    e.stopPropagation();

    const sectionIndex = formData.components.findIndex((field) => field._id === section._id);
    if (sectionIndex === formData.components.length - 1) return;

    // Get the index of the last field in this section
    let lastFieldInSectionIndex = -1;
    for (let i = sectionIndex + 1; i < formData.components.length; i++) {
      if (formData.components[i]?.form_section_id === section._id) {
        lastFieldInSectionIndex = i;
      }
    }

    if (lastFieldInSectionIndex === -1) {
      lastFieldInSectionIndex = sectionIndex;
    }

    // Walk forwards through the fields until we find the section below this section
    let nextSectionIndex = -1;
    for (let i = lastFieldInSectionIndex + 1; i < formData.components.length; i++) {
      if (formData.components[i]?.type === "section") {
        nextSectionIndex = i;
        break;
      }
    }

    // If we didn't find a section below this section, do nothing
    if (nextSectionIndex === -1) return;

    // Get the last field in the next section
    let lastFieldInNextSectionIndex = -1;
    for (let i = nextSectionIndex + 1; i < formData.components.length; i++) {
      if (formData.components[i]?.form_section_id === formData.components[nextSectionIndex]?._id) {
        lastFieldInNextSectionIndex = i;
      }
    }

    if (lastFieldInNextSectionIndex === -1) {
      lastFieldInNextSectionIndex = nextSectionIndex;
    }

    // Create a new array of fields with the section and it's fields moved to the next section
    const newFields = [
      ...formData.components.slice(0, sectionIndex),
      ...formData.components.slice(nextSectionIndex, lastFieldInNextSectionIndex + 1),
      ...formData.components.slice(sectionIndex, nextSectionIndex),
      ...(formData.components.slice(lastFieldInNextSectionIndex + 1) || []),
    ];

    setFormData({ ...formData, components: newFields });
    setActiveComponent(section);
  };

  /**  Sets the clicked component as the active component and moves the form manager to that component */
  const handleSetActiveFormComponent = (e, component: FormComponentFormField) => {
    // Don't set the active component if the parent component is hidden - this prevents a field from being changed when it's section is hidden
    if (component.hide) {
      const parent = formData.components.find((field) => field._id === component.form_section_id);
      if (parent?.hide) return;
    }

    setActiveComponent(component);
    handleMoveFormManager(component);
  };

  /** Move the form manager to the top of the component that is specified */
  const handleMoveFormManager = (
    component: FormComponentFormField | undefined,
    options?: { scroll?: boolean }
  ): void => {
    if (!component) return;
    const { scroll } = options || {};

    // Get the ref for the component
    const newFieldRef = componentRefs.current[component._id];
    if (!newFieldRef) return;

    // Get the top of the newly added field
    const listTop = sectionsRef.current?.getBoundingClientRect().top;
    const topIsSection = formData.components[0]?.type === "section";

    const topOffset = (topIsSection ? 75 : 0) + 229.5 - 90;
    const newFieldTop = newFieldRef.getBoundingClientRect().top - (listTop || 0) + topOffset;

    // Set's the y position of the form manager so that we can animate it to the new position
    setActiveFieldY(newFieldTop);

    // Scroll to the field if specified
    if (scroll) wizardScreenRef?.current?.scrollTo({ top: newFieldTop, behavior: "smooth" });
  };

  /*********************************************************
   *  Form field handlers
   **********************************************************/
  const handleParseValue = (value: string | boolean) => {
    // If it's only digits, parse it as a number
    if (/^\d+$/.test(value.toString())) return Number(value.toString());
    return value;
  };

  /** Handles the change of a field's value */
  const handleFieldChange = (key: string, value: string | boolean, index: number) => {
    const updatedComponents = [...formData.components];
    if (!updatedComponents[index]) return;

    set(updatedComponents[index]!, key, handleParseValue(value));
    setFormData({ ...formData, components: updatedComponents });
  };

  /** Handles the change of a select field's options */
  const handleFieldOptionsChange = (value: string | boolean | null, index: number, optionIndex: number) => {
    const updatedComponents = [...formData.components];
    const component = updatedComponents[index];
    if (!component || !("options" in component)) return;
    if (typeof value !== "string") return;

    const options = "options" in component ? component.options || [] : [];
    if (!options[optionIndex]) return;

    component.options![optionIndex]!.value = value;
    updatedComponents[index] = component;

    setFormData({ ...formData, components: updatedComponents });
  };

  /** Adds a new option to a select field */
  const handleFieldOptionsAdd = (index: number) => {
    const updatedComponents = [...formData.components];
    const component = updatedComponents[index];
    if (!component || !("options" in component)) return;

    const optionsLength = component.options.length;

    const options = ("options" in component ? component.options || [] : []).concat([
      { _id: ObjectID().toHexString(), value: `Option ${optionsLength}` },
    ]);

    component.options = options;

    setErrors((errors) => ({
      ...errors,
      [`components.${component._id}.no_option`]:
        component.options.filter((o) => !o.archived).length === 0 ? "At least one option is required." : null,
    }));

    setFormData({ ...formData, components: updatedComponents });
  };

  /** Deletes an option from a select field */
  const handleFieldOptionsDelete = (index: number, optionIndex: number) => {
    const updatedComponents = [...formData.components];
    const component = updatedComponents[index];
    if (!component || !("options" in component)) return;

    const options = "options" in component ? component.options || [] : [];
    component.options = options
      .map((o, i) => (i === optionIndex ? { ...o, archived: true } : o))
      .filter(notNullish);

    setErrors((errors) => ({
      ...errors,
      [`components.${component._id}.no_option`]:
        component.options.filter((o) => !o.archived).length === 0 ? "At least one option is required." : null,
    }));

    setFormData({ ...formData, components: updatedComponents });
  };

  /*********************************************************
   *  Form manager functions
   **********************************************************/

  /** Add a new field to the form */
  const handleAddField = (type: FormComponentFormField["type"], opts?: FormFieldOptions) => {
    const newField: FormComponentFormField = buildDefaultField(opts, type, getDefaultFormSectionId());

    const components = formData.components;
    const index = components.findIndex((c) => activeComponent && c._id === activeComponent._id);

    let updatedComponents: FormComponentFormField[] = [];
    if (type == "section") {
      let indexOfNextSection = findIndex(components, (c) => c.type === "section", index + 1);
      if (indexOfNextSection === -1) indexOfNextSection = components.length;

      const componentsAddedToSection = components
        .slice(index + 1, indexOfNextSection)
        .map((c) => ({ ...c, form_section_id: newField._id }));

      updatedComponents = [
        ...components.slice(0, index + 1),
        newField,
        ...componentsAddedToSection,
        ...components.slice(indexOfNextSection),
      ];
    } else {
      updatedComponents = [...components.slice(0, index + 1), newField, ...components.slice(index + 1)];
    }

    setFormData({ ...formData, components: updatedComponents });
    setActiveComponent(newField);

    // If this is the last section, we will need manually move the form manager as this won't trigger a layout change
    if (index === updatedComponents.length - 2) {
      delay(() => handleMoveFormManager(newField, { scroll: true }), 100);
    }
  };

  /** Hides a field and it's children  */
  const handleHideField = () => {
    if (!activeComponent) return;

    const updatedComponents = formData.components.map((c) => {
      const shouldToggleHide = c._id === activeComponent._id || c.form_section_id === activeComponent._id;
      return shouldToggleHide ? { ...c, hide: !c.hide } : c;
    });

    setFormData({ ...formData, components: updatedComponents });
  };

  /** Deletes a field and it's children */
  const handleDeleteField = () => {
    if (!activeComponent) return;
    const errorKey = `components.${activeComponent._id}`;

    const index = formData.components.findIndex((c) => c._id === activeComponent._id);
    const updatedComponents = formData.components.filter((c) => {
      const shouldDelete = c._id === activeComponent._id || c.form_section_id === activeComponent._id;
      return !shouldDelete;
    });

    // clear out errors from deleted fields
    clearErrors(errorKey);
    setErrors((errors) => {
      const newErrors = { ...errors };
      Object.keys(newErrors).forEach((key) => {
        if (key.includes(errorKey)) {
          delete newErrors[key];
        }
      });

      return newErrors;
    });
    setActiveComponent(formData.components[index - 1]);
    setFormData({ ...formData, components: updatedComponents });
  };

  /** Duplicates a field and it's children */
  const handleDuplicateField = () => {
    if (!activeComponent) return;

    const components = formData.components;
    const newField = cloneDeep(activeComponent);
    newField._id = ObjectID().toHexString();

    const index = components.findIndex((c) => c._id === activeComponent._id);

    let updatedComponents: FormComponentFormField[] = [];
    if (newField.type == "section") {
      let indexOfNextSection = findIndex(components, (c) => c.type === "section", index + 1);
      if (indexOfNextSection === -1) indexOfNextSection = components.length;

      const componentsAddedToSection = components
        .slice(index + 1, indexOfNextSection)
        .map((c) => ({ ...c, _id: ObjectID().toHexString(), form_section_id: newField._id }));

      updatedComponents = [
        ...components.slice(0, indexOfNextSection),
        newField,
        ...componentsAddedToSection,
        ...components.slice(indexOfNextSection),
      ];
    } else {
      updatedComponents = [...components.slice(0, index + 1), newField, ...components.slice(index + 1)];
    }

    setFormData({ ...formData, components: updatedComponents });
    setActiveComponent(newField);

    // If this is the last section, we will need manually move the form manager as this won't trigger a layout change
    if (index === updatedComponents.length - 2) {
      delay(() => handleMoveFormManager(newField, { scroll: true }), 100);
    }
  };

  /*********************************************************
   *  Other helper functions
   *********************************************************/
  const getDefaultFormSectionId = () => {
    if (!activeComponent) return;

    if (activeComponent.type === "section") {
      return activeComponent._id;
    } else {
      return activeComponent.form_section_id;
    }
  };

  const handleSetRef = (ref: RefObject<HTMLDivElement>) => {
    setWizardScreenRef(ref);
  };

  /*********************************************************
   *  General render functions
   **********************************************************/

  /** Header form that allows the user to edit the form name and form description */
  const renderHeaderForm = () => {
    return (
      <div className={styles["miter-form-header"]}>
        <Formblock
          className={styles["miter-form-title"]}
          placeholder="Form name"
          type="text"
          name="name"
          register={register({ required: "Form name is required" })}
          errors={formErrors}
          editing={true}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          defaultValue={formData.name}
          disabled={readonly}
        />
        <Formblock
          className={styles["miter-form-description"]}
          placeholder="Description..."
          type="paragraph"
          name="description"
          editing={true}
          rows={1}
          disableResize={true}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          defaultValue={formData.description}
          disabled={readonly}
        />
      </div>
    );
  };

  /** The form manager allow the user to add new component and manage the active component */
  const renderFormManager = () => {
    const fieldOptions = [
      {
        label: "Text",
        icon: <TextAa style={{ marginBottom: -3, marginRight: 15 }} />,
        action: () => handleAddField("text"),
      },
      {
        label: "Paragraph",
        icon: <TextAlignJustify style={{ marginBottom: -3, marginRight: 15 }} />,
        action: () => handleAddField("paragraph"),
      },
      ...(parentType !== "job_posting"
        ? [
            {
              label: "Number",
              icon: <NumberCircleOne style={{ marginBottom: -3, marginRight: 15 }} />,
              action: () => handleAddField("number"),
            },
          ]
        : []),
      {
        label: "Select",
        icon: <ListBullets style={{ marginBottom: -3, marginRight: 15 }} />,
        action: () => handleAddField("select", { multiple: false }),
      },
      {
        label: "Multi select",
        icon: <ListDashes style={{ marginBottom: -3, marginRight: 15 }} />,
        action: () => handleAddField("select", { multiple: true }),
      },
      {
        label: "Date",
        icon: <Calendar style={{ marginBottom: -3, marginRight: 15 }} />,
        action: () => handleAddField("date"),
      },
      ...(parentType !== "job_posting"
        ? [
            {
              label: "Photo upload",
              icon: <Camera style={{ marginBottom: -2, marginRight: 15 }} />,
              action: () => handleAddField("photo"),
            },
          ]
        : []),
      ...(parentType !== "job_posting"
        ? [
            {
              label: "Checkbox",
              icon: <CheckSquare style={{ marginBottom: -3, marginRight: 15 }} />,
              action: () => handleAddField("checkbox"),
            },
          ]
        : []),
      ...(parentType !== "job_posting"
        ? [
            {
              label: "E-Signature",
              icon: <ScribbleLoop style={{ marginBottom: -3, marginRight: 15 }} />,
              action: () => handleAddField("esignature"),
            },
          ]
        : []),
      ...(parentType !== "job_posting"
        ? [
            {
              label: "Section",
              icon: <Divide style={{ marginBottom: -3, marginRight: 15 }} />,
              action: () => handleAddField("section"),
            },
          ]
        : []),
    ];
    if (readonly) return;
    // Make div slide when y changes
    return (
      <motion.div
        className={styles["form-builder-manager"]}
        animate={{ y: activeFieldY ? activeFieldY : 0 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      >
        <div className={styles["form-builder-manager-components"]}>
          <DropdownButton
            className="button-1 no-margin"
            options={fieldOptions}
            wrapperStyle={{ width: 36, height: 36 }}
            buttonStyle={{ width: 36, height: 36 }}
            closeOnClick={true}
            optionStyle={{ padding: "10px 15px", paddingRight: 40 }}
          >
            <Plus style={{ marginBottom: -2, marginLeft: -1 }} size={18} />
          </DropdownButton>
          {activeComponent && (
            <>
              <Button
                className="button-1 no-margin"
                onClick={handleHideField}
                style={{ height: 36, width: 36, marginTop: 10 }}
              >
                {activeComponent.hide ? (
                  <Eye style={{ marginBottom: -2, marginLeft: -2 }} size={18} />
                ) : (
                  <EyeSlash style={{ marginBottom: -2, marginLeft: -2 }} size={18} />
                )}
              </Button>
              <Button
                className="button-1 no-margin"
                onClick={handleDuplicateField}
                style={{ height: 36, width: 36, marginTop: 10 }}
              >
                <Copy style={{ marginBottom: -2, marginLeft: -2 }} size={18} />
              </Button>
              {!activeComponent.readonly && (
                <Button
                  className="button-1 no-margin"
                  onClick={handleDeleteField}
                  style={{ height: 36, width: 36, marginTop: 10 }}
                >
                  <Trash style={{ marginBottom: -2, marginLeft: 0 }} size={18} />
                </Button>
              )}
            </>
          )}
        </div>
      </motion.div>
    );
  };

  const renderDraggable = () => {
    if (readonly) return;

    return (
      <div
        className={styles["form-component-draggable"]}
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
        onTouchStart={() => setDraggable(true)}
      >
        <DotsSixVertical style={{ fontSize: "24px", backgroundColor: "#fafafa" }} />
      </div>
    );
  };

  /*********************************************************
   *  Render form components
   **********************************************************/
  const renderSection = (component: FormComponentFormField, index: number) => {
    return (
      <>
        <p className={styles["miter-form-component-section-header"]}></p>
        <MiterFormComponent
          component={component}
          onClick={handleSetActiveFormComponent}
          index={index}
          onFieldChange={handleFieldChange}
        >
          {renderSectionArrows(component)}
        </MiterFormComponent>
      </>
    );
  };

  const renderTextOrParagraphField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;
    const placeholderKey = `${key}.placeholder`;
    const requiredKey = `${key}.validations.required`;

    return (
      <MiterFormComponent
        component={component}
        onClick={handleSetActiveFormComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        <div className={styles["miter-form-component-body"]}>
          {"placeholder" in component && (
            <Formblock
              className={"modal "}
              placeholder="Placeholder"
              type="text"
              name={placeholderKey}
              editing={true}
              onChange={(e) => handleFieldChange("placeholder", e.target.value, index)}
              defaultValue={component?.placeholder}
              disabled={readonly || component.readonly}
            />
          )}
          {"validations" in component && (
            <Formblock
              className={"modal "}
              text="This field is required"
              type="checkbox"
              name={requiredKey}
              editing={true}
              onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
              defaultValue={component?.validations?.required}
              style={{ marginBottom: 0 }}
              disabled={readonly || component.readonly}
            />
          )}
        </div>
      </MiterFormComponent>
    );
  };

  /** Number field with mix/max validation as well */
  const renderNumberField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;

    const formMax = formData.components[index]?.validations?.max;
    const formMin = formData.components[index]?.validations?.min;

    const currentMaximum = formMax != null ? formMax : Infinity;
    const currentMinimum = formMin != null ? formMin : -Infinity;

    return (
      <MiterFormComponent
        component={component}
        onClick={handleSetActiveFormComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        <div className={styles["miter-form-component-body"]}>
          {"placeholder" in component && (
            <Formblock
              className={"modal "}
              placeholder="Placeholder"
              type="text"
              name={`${key}.placeholder`}
              editing={true}
              onChange={(e) => handleFieldChange("placeholder", e.target.value, index)}
              defaultValue={component?.placeholder}
              disabled={readonly}
            />
          )}
          {"validations" in component && (
            <>
              <Formblock
                className={"modal "}
                text="This field is required"
                type="checkbox"
                name={`${key}.validations.required`}
                editing={true}
                onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
                defaultValue={component?.validations?.required}
                disabled={readonly}
                style={{ marginBottom: 20 }}
              />
              <Label
                className="modal strong-label"
                label={
                  <>
                    {component.showMoreValidations ? (
                      <>
                        <CaretUp style={{ marginBottom: -2, marginRight: 5 }} /> Hide more validations
                      </>
                    ) : (
                      <>
                        <CaretDown style={{ marginBottom: -2, marginRight: 5 }} /> Show more validations
                      </>
                    )}
                  </>
                }
                onClick={() =>
                  handleFieldChange("showMoreValidations", !component?.showMoreValidations, index)
                }
                style={{
                  cursor: "pointer",
                  borderBottom: component.showMoreValidations ? "1px solid #eee" : "none",
                  marginBottom: component.showMoreValidations ? 20 : 0,
                  paddingBottom: component.showMoreValidations ? 5 : 0,
                  opacity: 0.7,
                }}
              />
              {component.showMoreValidations && (
                <div className="flex">
                  <Formblock
                    label="Min"
                    className={"modal "}
                    placeholder="Min"
                    type="number"
                    name={`${key}.validations.min`}
                    editing={true}
                    onChange={(e) => handleFieldChange("validations.min", e.target.value, index)}
                    defaultValue={component?.validations?.min}
                    disabled={readonly}
                    style={{ marginBottom: 0, marginRight: 10 }}
                    register={register({
                      max: {
                        value: currentMaximum,
                        message: `Cannot be greater than ${currentMaximum}`,
                      },
                    })}
                    errors={formErrors}
                  />

                  <Formblock
                    label="Max"
                    className={"modal "}
                    placeholder="Max"
                    type="number"
                    name={`${key}.validations.max`}
                    editing={true}
                    onChange={(e) => handleFieldChange("validations.max", e.target.value, index)}
                    defaultValue={component?.validations?.max}
                    disabled={readonly}
                    style={{ marginBottom: 0, marginLeft: 10 }}
                    register={register({
                      min: {
                        value: currentMinimum,
                        message: `Cannot be less than ${currentMinimum}`,
                      },
                    })}
                    errors={formErrors}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </MiterFormComponent>
    );
  };

  const renderCheckboxField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;

    return (
      <MiterFormComponent
        component={component}
        onClick={handleSetActiveFormComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        {"validations" in component && (
          <Formblock
            className={"modal "}
            text="This field is required"
            type="checkbox"
            name={`${key}.validations.required`}
            editing={true}
            onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
            defaultValue={component?.validations?.required}
            disabled={readonly}
            style={{ marginBottom: 20 }}
          />
        )}
      </MiterFormComponent>
    );
  };

  const renderSelectField = (component: FormComponentFormField, index: number) => {
    if (!("options" in component)) return;
    const key = `components.${component._id}`;

    return (
      <MiterFormComponent
        component={component}
        onClick={handleSetActiveFormComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        {component.options?.map((option, optionIndex) => {
          if (option.archived) return;

          return (
            <div className={styles["miter-form-component-option"]} key={option._id}>
              <Formblock
                className={"modal width-100-percent"}
                placeholder={`Option ${optionIndex + 1}`}
                type="text"
                name={`${key}.options.${optionIndex}`}
                register={register({ required: "Option name is required" })}
                errors={formErrors}
                editing={true}
                onChange={(e) => handleFieldOptionsChange(e.target.value, index, optionIndex)}
                defaultValue={option.value}
                disabled={readonly}
              />
              {!readonly && (
                <div className={styles["miter-form-component-option-actions"]}>
                  <Button
                    className="button-text"
                    onClick={() => handleFieldOptionsDelete(index, optionIndex)}
                  >
                    <X style={{ marginLeft: 10 }} />
                  </Button>
                </div>
              )}
            </div>
          );
        })}
        {!component.options?.length && (
          <div style={{ marginBottom: 25, opacity: 0.7 }}>No options selected</div>
        )}

        {!readonly && (
          <Button
            className="button-1 no-margin"
            text="Add option"
            onClick={() => handleFieldOptionsAdd(index)}
          />
        )}

        {errors && errors[`${key}.no_option`] && (
          <div style={{ marginTop: 10 }} className="error-msg">
            {errors[`${key}.no_option`]}
          </div>
        )}

        <div className={styles["miter-form-component-footer"]}>
          {"validations" in component && (
            <Formblock
              className={"modal "}
              text="This field is required"
              type="checkbox"
              name={`${key}.validations.required`}
              editing={true}
              onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
              defaultValue={component?.validations?.required}
              style={{ marginTop: 20, marginBottom: 0 }}
              disabled={readonly}
            />
          )}
        </div>
      </MiterFormComponent>
    );
  };

  /** Renders the e-signature field */
  const renderSignatureField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;

    return (
      <MiterFormComponent
        component={component}
        onClick={handleSetActiveFormComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        <div className={styles["miter-form-component-footer"]}>
          {"validations" in component && (
            <Formblock
              className={"modal "}
              text="This field is required"
              type="checkbox"
              name={`${key}.validations.required`}
              editing={true}
              onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
              defaultValue={component?.validations?.required}
              style={{ marginTop: 20, marginBottom: 0 }}
              disabled={readonly}
            />
          )}
        </div>
      </MiterFormComponent>
    );
  };

  /** Renders the photo field */
  const renderPhotoField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;

    const currentMaximum = formData.components[index]?.validations?.max || DEFAULT_MAX_FILES;
    const currentMinimum = formData.components[index]?.validations?.min || DEFAULT_MIN_FILES;

    return (
      <MiterFormComponent
        component={component}
        onClick={handleSetActiveFormComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        <div className={styles["miter-form-component-body"]}>
          {"validations" in component && (
            <>
              <Formblock
                className={"modal "}
                text="This field is required"
                type="checkbox"
                name={`${key}.validations.required`}
                editing={true}
                onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
                defaultValue={component?.validations?.required}
                disabled={readonly}
                style={{ marginBottom: 20 }}
              />
              <Label
                className="modal strong-label"
                label={
                  <>
                    {component.showMoreValidations ? (
                      <>
                        <CaretUp style={{ marginBottom: -2, marginRight: 5 }} /> Hide more validations
                      </>
                    ) : (
                      <>
                        <CaretDown style={{ marginBottom: -2, marginRight: 5 }} /> Show more validations
                      </>
                    )}
                  </>
                }
                onClick={() =>
                  handleFieldChange("showMoreValidations", !component?.showMoreValidations, index)
                }
                style={{
                  cursor: "pointer",
                  borderBottom: component.showMoreValidations ? "1px solid #eee" : "none",
                  marginBottom: component.showMoreValidations ? 20 : 0,
                  paddingBottom: component.showMoreValidations ? 5 : 0,
                  opacity: 0.7,
                }}
              />
              {component.showMoreValidations && (
                <div className="flex">
                  <Formblock
                    label={`Minimum photos`}
                    className={"modal "}
                    placeholder="Min"
                    type="number"
                    name={`${key}.validations.min`}
                    editing={true}
                    onChange={(e) => {
                      handleFieldChange("validations.min", e.target.value, index);
                      form.trigger();
                    }}
                    defaultValue={component?.validations?.min}
                    disabled={readonly}
                    register={register({
                      max: {
                        value: currentMaximum,
                        message: `Cannot be greater than ${currentMaximum}`,
                      },
                      min: {
                        value: DEFAULT_MIN_FILES,
                        message: `Cannot be less than ${DEFAULT_MIN_FILES}`,
                      },
                    })}
                    style={{ marginBottom: 0, marginRight: 10 }}
                    errors={formErrors}
                  />
                  <Formblock
                    label={`Maximum photos`}
                    className={"modal "}
                    placeholder="Max"
                    type="number"
                    name={`${key}.validations.max`}
                    editing={true}
                    onChange={(e) => {
                      handleFieldChange("validations.max", e.target.value, index);
                      form.trigger();
                    }}
                    defaultValue={component?.validations?.max}
                    disabled={readonly}
                    register={register({
                      max: {
                        value: DEFAULT_MAX_FILES,
                        message: `Cannot be greater than ${DEFAULT_MAX_FILES}`,
                      },
                      min: {
                        value: currentMinimum,
                        message: `Cannot be less than ${currentMinimum}`,
                      },
                    })}
                    style={{ marginBottom: 0, marginLeft: 10 }}
                    errors={formErrors}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </MiterFormComponent>
    );
  };

  /** Date field field with mix/max validation as well */
  const renderDateField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;

    const minDate = component?.validations?.min_date
      ? DateTime.fromISO(component.validations.min_date)
      : undefined;

    const maxDate = component?.validations?.max_date
      ? DateTime.fromISO(component.validations.max_date)
      : undefined;

    return (
      <MiterFormComponent
        component={component}
        onClick={handleSetActiveFormComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        <div className={styles["miter-form-component-body"]}>
          {"placeholder" in component && (
            <Formblock
              className={"modal "}
              placeholder="Placeholder"
              type="text"
              name={`${key}.placeholder`}
              editing={true}
              onChange={(e) => handleFieldChange("placeholder", e.target.value, index)}
              defaultValue={component?.placeholder}
              disabled={readonly}
            />
          )}
          {"validations" in component && (
            <>
              <Formblock
                className={"modal "}
                text="This field is required"
                type="checkbox"
                name={`${key}.validations.required`}
                editing={true}
                onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
                defaultValue={component?.validations?.required}
                disabled={readonly}
                style={{ marginBottom: 20 }}
              />
              <Label
                className="modal strong-label"
                label={
                  <>
                    {component.showMoreValidations ? (
                      <>
                        <CaretUp style={{ marginBottom: -2, marginRight: 5 }} /> Hide more validations
                      </>
                    ) : (
                      <>
                        <CaretDown style={{ marginBottom: -2, marginRight: 5 }} /> Show more validations
                      </>
                    )}
                  </>
                }
                onClick={() =>
                  handleFieldChange("showMoreValidations", !component?.showMoreValidations, index)
                }
                style={{
                  cursor: "pointer",
                  borderBottom: component.showMoreValidations ? "1px solid #eee" : "none",
                  marginBottom: component.showMoreValidations ? 20 : 0,
                  paddingBottom: component.showMoreValidations ? 5 : 0,
                  opacity: 0.7,
                }}
              />
              {component.showMoreValidations && (
                <div className="flex">
                  <Formblock
                    label="Min"
                    className={"modal "}
                    placeholder="Min"
                    type="datetime"
                    dateOnly={true}
                    name={`${key}.validations.min_date`}
                    editing={true}
                    onChange={(value) => handleFieldChange("validations.min_date", value, index)}
                    defaultValue={minDate}
                    max={maxDate}
                    value={minDate}
                    disabled={readonly}
                    style={{ marginBottom: 0, marginRight: 10 }}
                  />
                  <Formblock
                    label="Max"
                    className={"modal "}
                    placeholder="Max"
                    type="datetime"
                    dateOnly={true}
                    name={`${key}.validations.max_date`}
                    editing={true}
                    onChange={(value) => handleFieldChange("validations.max_date", value, index)}
                    defaultValue={maxDate}
                    value={maxDate}
                    min={minDate}
                    disabled={readonly}
                    style={{ marginBottom: 0, marginLeft: 10 }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </MiterFormComponent>
    );
  };

  /** Render section arrows that when clicked will move sections around */
  const renderSectionArrows = (section: FormComponentFormField) => {
    if (readonly) return;
    return (
      <div className={styles["miter-form-component-section-arrows"]} onClick={(e) => e.stopPropagation()}>
        <Button className="button-text" onClick={(e) => handleSectionMoveUp(e, section)}>
          <ArrowUp />
        </Button>
        <Button className="button-text" onClick={(e) => handleSectionMoveDown(e, section)}>
          <ArrowDown />
        </Button>
      </div>
    );
  };

  const renderComponent = (component: FormComponentFormField, index: number) => {
    return (
      <Reorder.Item
        key={"section-" + component._id}
        value={component}
        dragListener={draggable}
        className={styles["form-builder-section-container"]}
        onDragStart={() => setLastDraggedComponent(component)}
        onDragEnd={() => setDraggable(false)}
        onDragTransitionEnd={() => {
          handleMoveFormManager(component);
          setActiveComponent(component);
        }}
        onLayoutAnimationComplete={() => handleMoveFormManager(activeComponent)}
        layout="position"
        ref={(r) => (componentRefs.current[component._id] = r)}
        id={component._id}
      >
        <div style={{ position: "relative" }}>
          {component.type !== "section" && renderDraggable()}
          {component.type === "section" && renderSection(component, index)}
          {component.type === "text" && renderTextOrParagraphField(component, index)}
          {component.type === "paragraph" && renderTextOrParagraphField(component, index)}
          {component.type === "number" && renderNumberField(component, index)}
          {component.type === "checkbox" && renderCheckboxField(component, index)}
          {component.type === "select" && renderSelectField(component, index)}
          {component.type === "esignature" && renderSignatureField(component, index)}
          {component.type === "date" && renderDateField(component, index)}
          {component.type === "photo" && renderPhotoField(component, index)}
        </div>
      </Reorder.Item>
    );
  };

  const renderLastAutoSaved = () => {
    if (!lastAutoSavedAt) return;

    return (
      <div className={styles["form-builder-last-auto-saved"]}>
        <span>Last auto saved at {lastAutoSavedAt.toFormat("h:mm:ss a")}</span>
      </div>
    );
  };

  const templateButtonText = templateEnabled ? "Switch to empty form" : "Switch to template";

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const switchTemplateMode = () => {
    setFormData(buildDefaultValues(formItem, template, !templateEnabled, true));
    setTemplateEnabled?.(!templateEnabled);
    closeModal();
  };

  return (
    <FormProvider {...form}>
      <WizardScreen name={name} onNext={onNext} setRef={handleSetRef}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginBottom: 20 }}
          className={styles["form-builder"]}
        >
          {template ? <Button className="button-1" text={templateButtonText} onClick={openModal} /> : null}
        </div>
        <div className={styles["form-builder"]}>
          {renderHeaderForm()}
          <div className={styles["form-builder-sections"]}>
            <Reorder.Group
              className={styles["form-builder-sections-container"]}
              axis="y"
              values={formData.components}
              onReorder={handleFieldsReorder}
              ref={sectionsRef}
            >
              {formData.components.map((component, index) => renderComponent(component, index))}
            </Reorder.Group>
          </div>
          {renderFormManager()}
          {modalOpen && <AckPerformanceTemplateChange onExit={closeModal} onSubmit={switchTemplateMode} />}
        </div>
      </WizardScreen>
      {renderLastAutoSaved()}
    </FormProvider>
  );
};

type MiterFormComponentProps = {
  children: React.ReactNode;
  component: FormComponentFormField;
  index: number;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, component: FormComponentFormField) => void;
  onFieldChange: (key: string, value: string | boolean, index: number) => void;
  fieldNamePlaceholder?: string;
};

export const MiterFormComponent: React.FC<MiterFormComponentProps> = ({
  children,
  component,
  index,
  onClick,
  onFieldChange,
  fieldNamePlaceholder = "Field name",
}) => {
  const key = `components.${component._id}`;
  const nameKey = `${key}.name`;
  const descriptionKey = `${key}.description`;
  const { type, hide } = component;
  const additionalClass = type === "section" ? styles["miter-form-component-section"] : "";

  const form = useFormContext();

  const { register, errors } = form;

  useEffect(() => {
    form.trigger();
  }, []);

  return (
    <div
      className={styles["miter-form-component"] + " " + additionalClass}
      onClick={(e) => onClick(e, component)}
    >
      {hide && (
        <div className={styles["miter-form-component-hide-overlay"]}>
          <EyeSlash style={{ marginBottom: -2, marginRight: 10 }} />
          Hidden
        </div>
      )}
      <div className={styles["miter-form-component-header"]}>
        <Formblock
          className={"modal " + styles["miter-form-component-label-input"]}
          placeholder={type === "section" ? "Section name" : fieldNamePlaceholder}
          type="text"
          name={nameKey}
          register={register({ required: "Name is required" })}
          errors={errors}
          editing={!component.readonly}
          onChange={(e) => onFieldChange("name", e.target.value, index)}
          defaultValue={component?.name}
        />
        <Formblock
          className={"modal " + styles["miter-form-component-sublabel-input"]}
          placeholder={type === "section" ? "Section description" : "Field description"}
          type="paragraph"
          name={descriptionKey}
          register={register()}
          errors={errors}
          editing={true}
          rows={1}
          disableResize={true}
          onChange={(e) => onFieldChange("description", e.target.value, index)}
          defaultValue={component?.description}
        />
      </div>
      {children}
    </div>
  );
};

const buildDefaultValues = (
  formItem: Form | null | undefined,
  template: PerformanceTemplate | undefined,
  templateEnabled: boolean,
  override?: boolean
): FormBuilderForm => {
  if (formItem && formItem.components.length > 0 && !override) return formItem;
  if (templateEnabled && template) {
    return {
      ...formItem,
      name: formItem?.name || "Untitled Form",
      components: [...performanceTemplates[template]()],
    };
  }
  return { name: "Untitled Form", components: [buildDefaultField()] };
};

const buildDefaultField = (
  opts?: FormFieldOptions,
  type?: FormComponentFormField["type"],
  formSectionId?: string | null
): FormComponentFormField => {
  return {
    _id: ObjectID().toHexString(),
    type: type || "text",
    name: "Untitled Field",
    description: "",
    validations: { required: false },
    placeholder: "",
    form_section_id: type !== "section" ? formSectionId : undefined,
    options: type === "select" ? [{ _id: ObjectID().toHexString(), value: "Option 1" }] : [],
    multiple: type === "select" ? !!opts?.multiple : undefined,
  };
};
