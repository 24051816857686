import DailyReportsTable from "dashboard/components/daily-reports/DailyReportsTable";
import { useQuery } from "miter-utils";
import { capitalize } from "lodash";

import React, { useEffect, FC } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Button } from "ui";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

const DailyReports: FC = () => {
  const query = useQuery();
  const status = query.has("status") ? capitalize(query.get("status")!) : undefined;
  const navigate = useNavigate();

  const { can } = useMiterAbilities();

  useEffect(() => {
    if (!status) {
      navigate("/daily-reports?status=unapproved", { replace: true });
    }
  }, []);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Daily Reports | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1 className="view-title title-with-toggler">Daily Reports</h1>
          <div className="flex-1"></div>
          {can("daily_reports:settings") && (
            <Button
              className="button-1"
              text="Settings"
              onClick={() => navigate("/daily-reports/settings")}
            />
          )}
        </div>

        <DailyReportsTable />
      </div>
    </div>
  );
};

export default DailyReports;
