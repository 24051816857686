import React, { FC, useEffect, useMemo, useState } from "react";
import {
  useExpenseReimbursementCategories,
  useRefetchExpenseReimbursementCategories,
} from "dashboard/hooks/atom-hooks";
import { Plus, Trash } from "phosphor-react";
import { TableActionLink, TableV2, ColumnConfig } from "ui/table-v2/Table";
import { ExpenseReimbursementCategory, MiterAPI } from "dashboard/miter";
import ExpenseReimbursementCategoryModal from "./modals/ExpenseReimbursementCategoryModal";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { FailureItem } from "dashboard/components/shared/FailuresModal";
import { Notifier } from "ui";
import pluralize from "pluralize";
import Banner from "dashboard/components/shared/Banner";
import { goToMiterGuide } from "dashboard/utils/miterGuides";

const ExpenseReimbursementCategorySettings: FC = () => {
  // hooks
  const categories = useExpenseReimbursementCategories();
  const refetchCategories = useRefetchExpenseReimbursementCategories();
  const { setFailures, renderFailuresModal } = useFailuresModal();

  // state
  const [selectedCategories, setSelectedCategories] = useState<ExpenseReimbursementCategory[]>([]);
  const [clickedCategory, setClickedCategory] = useState<ExpenseReimbursementCategory>();
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);

  const [archiving, setArchiving] = useState<boolean>(false);

  useEffect(() => {
    // no need to refetch on initial load
    if (refreshCounter > 0) {
      refetchCategories();
    }
  }, [refreshCounter]);

  const columns: ColumnConfig<ExpenseReimbursementCategory>[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        dataType: "string",
      },
      {
        headerName: "Default?",
        field: "is_default",
        dataType: "boolean",
      },
      {
        headerName: "Taxable?",
        field: "is_taxable",
        dataType: "boolean",
      },
    ],
    []
  );

  const staticActions: TableActionLink[] = [
    {
      label: "Create new category",
      className: "button-2 no-margin",
      action: () => setShowCategoryModal(true),
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      important: true,
    },
  ];

  const handleBulkArchive = async () => {
    if (selectedCategories.length === 0) return;

    setArchiving(true);
    const failures: FailureItem[] = [];
    const successes: string[] = [];
    await Promise.all(
      selectedCategories.map(async (category) => {
        try {
          const response = await MiterAPI.expense_reimbursements.categories.archive(category._id);
          if (response.error) throw new Error(response.error);
          successes.push(category._id);
        } catch (error: $TSFixMe) {
          failures.push({
            label: category._id,
            message: error.message,
          });
        }
      })
    );

    if (successes.length > 0) {
      Notifier.success(
        `Deleted ${successes.length} reimbursement ${pluralize("category", successes.length)}.`
      );
      setRefreshCounter(refreshCounter + 1);
    }

    if (failures.length > 0) {
      setFailures(failures);
    }

    setArchiving(false);
  };

  const dynamicActions: TableActionLink[] = [
    {
      label: "Delete",
      className: "button-3",
      action: handleBulkArchive,
      icon: <Trash weight="bold" />,
      important: true,
      loading: archiving,
    },
  ];

  return (
    <>
      <>
        <div className="vertical-spacer-small"></div>
        <Banner
          type="info"
          onClick={() => goToMiterGuide("/expense-management/reimbursements/categories")}
          content="Click here for more documentation about expense reimbursement categories!"
        />
      </>
      <TableV2
        id={"expense-reimbursement-categories-table"}
        resource="categories"
        data={categories}
        columns={columns}
        staticActions={staticActions}
        onClick={(category) => {
          setClickedCategory(category);
          setShowCategoryModal(true);
        }}
        dynamicActions={dynamicActions}
        onSelect={(selected) => setSelectedCategories(selected)}
        isLoading={archiving}
      />
      {showCategoryModal && (
        <ExpenseReimbursementCategoryModal
          onHide={() => {
            setShowCategoryModal(false);
            setClickedCategory(undefined);
          }}
          onSave={() => setRefreshCounter(refreshCounter + 1)}
          selectedCategory={clickedCategory}
        />
      )}
      {renderFailuresModal()}
    </>
  );
};

export default ExpenseReimbursementCategorySettings;
