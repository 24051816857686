import { useActiveCompanyId, useActiveTeam, useSetTeamChatRestart } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useMemo, useState } from "react";
import Select, { ValueType } from "react-select";
import { ActionModal, SelectOption } from "ui";
import { styles as SelectStyles } from "ui/form/styles";
import styles from "../Chat.module.css";
import { useChatAbilities } from "dashboard/hooks/abilities-hooks/useChatAbilities";

type Props = {
  onCancel: () => void;
  onCreate: () => void;
};

const TeamCreateConversationModal: React.FC<Props> = ({ onCancel, onCreate }) => {
  const teamMembers = useActiveTeam();
  const activeCompanyId = useActiveCompanyId();
  const [teamMember, setTeamMember] = useState<AggregatedTeamMember>();
  const [loading, setLoading] = useState(false);
  const setTeamChatRestart = useSetTeamChatRestart();

  const chatAbilities = useChatAbilities();

  const teamMemberOptions = useMemo(() => {
    return teamMembers
      .filter((tm) => chatAbilities.teamPredicate("send")(tm))
      .filter((tm) => !tm.conversation || !tm.conversation.length)
      .map((tm) => ({ value: tm._id, label: tm.full_name }));
  }, [teamMembers]);

  const createConversation = async () => {
    setLoading(true);
    try {
      if (!teamMember) throw new Error("Please select a team member");

      const res = await MiterAPI.chat.create(activeCompanyId!, teamMember?._id);
      if (res.error) throw new Error(res.error);

      onCreate();
      setTeamChatRestart((prev) => prev + 1);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.log(e);
    }
    setLoading(false);
  };

  const handleTeamMemberSelect = (value?: string | null) => {
    const tm = teamMembers.find((teamMember) => teamMember._id === value);
    setTeamMember(tm);
  };

  return (
    <>
      <ActionModal
        headerText={"Create a conversation"}
        submitText={"Create conversation"}
        onSubmit={createConversation}
        showSubmit={true}
        showCancel={true}
        cancelText={"Cancel"}
        onCancel={onCancel}
        onHide={onCancel}
        loading={loading}
      >
        <div className={styles["select-team-members"]}>
          <p className={styles["select-info-text"]}>Select a team member</p>
          <Select
            name="team_member"
            options={teamMemberOptions}
            width={"100%"}
            zIndex={10}
            onChange={(option: ValueType<SelectOption, false>) => handleTeamMemberSelect(option?.value)}
            menuPortalTarget={document.body}
            menuPlacement="auto"
            height="40px"
            // @ts-expect-error - react-select types are not up to date
            styles={SelectStyles}
          />
        </div>
      </ActionModal>
    </>
  );
};

export default TeamCreateConversationModal;
