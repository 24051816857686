import { useActiveCompany, useActiveTeam, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { buildTenureString } from "miter-utils";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Formblock, TableV2 } from "ui";
import { DateRange } from "ui/form/DateRangePicker";
import { ColumnConfig } from "ui/table-v2/Table";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

type TableEntry = {
  _id: string;
  team_member: string;
  type: "birthday" | "tenure";
  date: string;
  info: string;
};

const CreateBirthdaysAndTenuresReports: React.FC = () => {
  // Important hooks
  const activeCompany = useActiveCompany();
  const activeTeam = useActiveTeam();
  const lookupTeam = useLookupTeam();
  const teamAbilities = useTeamAbilities();
  const navigate = useNavigate();
  const form = useForm();

  // States related to the table
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().startOf("week"),
    end: DateTime.now().endOf("week"),
  });

  // Non state variables
  const tenureAlerts = activeCompany?.settings.team.tenure_alerts || [];

  const handleDateRangeChange = (dateRange: DateRange) => {
    setDateRange(dateRange);
  };

  // Table data / columns
  const tableData = useMemo(() => {
    if (!dateRange.start || !dateRange.end) return [];

    const startOfRange = dateRange.start.startOf("day");
    const endOfRange = dateRange.end.endOf("day");

    const data = activeTeam
      .filter((tm) => teamAbilities.can("read", tm))
      .reduce((acc, tm) => {
        const birthday = tm.dob;
        if (birthday) {
          const yearsInRange = endOfRange.diff(startOfRange, "years").years;

          // Turns years into an array of years
          const yearsInRangeArray = Array.from({ length: yearsInRange + 1 }, (_, i) => i + startOfRange.year);

          // For each year in the range, check if the birthday falls within that year
          for (const year of yearsInRangeArray) {
            const birthdayLuxon = DateTime.fromISO(birthday);
            const birthdayThisYear = birthdayLuxon.set({ year });

            const age = birthdayThisYear.diff(birthdayLuxon, "years").years;
            const info = age + " years old";

            // If the birthday falls between the start and end of the week, add it to the alerts array
            if (birthdayThisYear >= startOfRange && birthdayThisYear <= endOfRange) {
              acc.push({
                _id: tm._id + year + "-birthday",
                team_member: tm.full_name,
                type: "birthday",
                date: birthdayThisYear.toISODate(),
                info,
              });
            }
          }
        }

        if (tm.start_date) {
          tenureAlerts.forEach((alert, index) => {
            if (!tm.start_date) return;

            const tenureMilestoneDate = DateTime.fromISO(tm.start_date).plus({
              years: alert.years,
              months: alert.months,
              days: alert.days,
            });

            if (tenureMilestoneDate >= startOfRange && tenureMilestoneDate <= endOfRange) {
              acc.push({
                _id: `${tm._id}-tenure-${index}`,
                team_member: tm.full_name,
                type: "tenure",
                date: tenureMilestoneDate.toISODate(),
                info: buildTenureString(alert),
              });
            }
          });
        }

        return acc;
      }, [] as TableEntry[]);

    return data.sort((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    });
  }, [activeTeam, dateRange]);

  const columns: ColumnConfig<TableEntry>[] = useMemo(() => {
    const cols: ColumnConfig<TableEntry>[] = [
      {
        headerName: "Team member",
        field: "team_member",
        dataType: "string",
        maxWidth: 300,
      },
      {
        headerName: "Team member ID",
        field: "team_member_id",
        dataType: "string",
        maxWidth: 300,
        valueGetter: (params) => lookupTeam(params?.data?.team_member)?.friendly_id,
      },
      {
        headerName: "Type",
        field: "type",
        dataType: "string",
        displayType: "badge",
        colors: {
          birthday: "blue",
          tenure: "green",
        },
        width: 200,
      },
      {
        headerName: "Date",
        field: "date",
        dataType: "date",
        dateType: "iso",
        width: 200,
        dateFormat: "ccc, LLL d, yyyy",
      },
      {
        headerName: "Info",
        field: "info",
        dataType: "string",
      },
    ];

    return cols;
  }, []);

  return (
    <div className="page-content">
      <Helmet>
        <title>Birthdays and Tenure Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Birthdays and Tenure Milestones</h1>
      </div>
      <div className="page-content-body">
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Formblock
            value={dateRange}
            control={form.control}
            type="daterange"
            name="range"
            max={DateTime.now()}
            editing={true}
            onChange={handleDateRangeChange}
            style={{ alignItems: "center" }}
            inputProps={{ style: { maxWidth: 250, fontFamily: "Karla" } }}
            labelStyle={{ marginBottom: 10, maxWidth: 100, fontFamily: "Karla", fontSize: 15 }}
          />
        </div>
        <TableV2
          id={"birthdays-and-tenure-table"}
          resource="birthdays or milestones"
          data={tableData}
          columns={columns}
          hideSearch={true}
          showReportViews={true}
          containerStyle={{ marginTop: -75 }}
        />
        <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
          * You must set up tenure alerts in{" "}
          <Link className="purple-link" to="/team-members/settings">
            team member settings
          </Link>{" "}
          to see tenure milestones.
        </div>
      </div>
    </div>
  );
};

export default CreateBirthdaysAndTenuresReports;
