import AppContext from "dashboard/contexts/app-context";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Toggler } from "ui";
import { matchSorter } from "match-sorter";
import { IntegrationCategory } from "backend/models/integrations/integrations";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { Notifier } from "dashboard/utils";
import { useNavigate } from "react-router-dom";
import { baseSensitiveCompare, useQuery } from "miter-utils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { IntegrationListItem } from "./IntegrationListItem";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useAddFromUrl } from "dashboard/hooks/integrations/useAddFromUrl";

const togglerItems: (IntegrationCategory | "All")[] = [
  "All",
  "Accounting",
  "Benefits",
  "Project Management",
  "Time-Tracking",
  "HR",
];

const togglerConfig: TogglerConfigItem[] = togglerItems.map((ti) => {
  return { label: ti, path: ti };
});

export const Integrations: React.FC = () => {
  const { integrations } = useContext(AppContext);
  const query = useQuery();
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const { cannot } = useMiterAbilities();
  const { addFromUrl } = useAddFromUrl(activeCompanyId);

  useEffect(() => {
    if (cannot("integrations:manage")) {
      Notifier.error("You do not have permission to manage integrations.");
      navigate("/home");
    }
  }, [cannot]);

  useEffect(() => {
    if (query.get("state") === "miter_qbo_redirect") {
      addFromUrl("qbo");
    } else if (query.get("state") === "miter_xero_redirect") {
      addFromUrl("xero");
    } else if (query.get("state") === "miter_procore_redirect") {
      addFromUrl("procore");
    } else if (query.get("state")?.includes("checkr-")) {
      addFromUrl("checkr");
    } else if (query.get("state") === "miter_qbd_redirect") {
      addFromUrl("qbd");
    }
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState("All");

  const sortedIntegrations = useMemo(() => {
    return integrations.slice().sort((a, b) => {
      if (a.connection && !b.connection) return -1;
      if (!a.connection && b.connection) return 1;
      if (a.connection && b.connection) {
        if (a.connection.pending_setup && !b.connection.pending_setup) return 1;
        if (!a.connection.pending_setup && b.connection.pending_setup) return -1;
      }
      return baseSensitiveCompare(a.label, b.label);
    });
  }, [integrations]);

  const filteredIntegrations = useMemo(() => {
    let results = sortedIntegrations.filter((i) => {
      // filter out astrada
      if (i.key === "astrada") return false;
      return selectedTab === "All" || i.categories.includes(selectedTab as IntegrationCategory);
    });

    if (searchTerm) {
      results = matchSorter(results, searchTerm, {
        keys: ["label", "description", "categories"],
      });
    }

    return results;
  }, [searchTerm, selectedTab, sortedIntegrations, activeCompanyId]);

  return (
    <div className="page-content">
      <Helmet>
        <title>Integrations | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <h1>Integrations</h1>
        <div className="flex-1"></div>
        <input
          type="text"
          className="table-search-input pw-input"
          style={{ width: 250, fontSize: 14, fontFamily: "Karla" }}
          placeholder={"Search"}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          value={searchTerm}
        ></input>
      </div>
      <Toggler config={togglerConfig} toggle={setSelectedTab} active={selectedTab} />
      <div>
        <div className="vertical-spacer"></div>
      </div>
      <div style={{ maxHeight: "100%", overflowY: "scroll", maxWidth: 1300 }}>
        {filteredIntegrations.map((i) => {
          return <IntegrationListItem key={i.key} integration={i} />;
        })}
      </div>
    </div>
  );
};
