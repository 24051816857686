import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { reportList } from "../reportList";
import AppContext from "../../../contexts/app-context";
import { Formblock, Loader, Notifier } from "ui";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { AggregatedTeamMember, Allowance, Company, MiterAPI } from "dashboard/miter";
import {
  baseSensitiveCompare,
  buildAgGridColumns,
  buildAgGridRow,
  convertSecondsToYearsMonthsDays,
  roundTo,
} from "miter-utils";
import { DateTime } from "luxon";
import {
  capitalize,
  getTenureInSeconds,
  getWorkHoursInYear,
  getWorkWeeksInYear,
  useEnabledDemographicQuestions,
} from "dashboard/utils";
import {
  useActiveCompany,
  useActiveCompanyId,
  useActivityLabelFormatter,
  useCostTypes,
  useJobNameFormatter,
  useLookupCostType,
  useLookupCrew,
  useLookupOtRule,
  useLookupPaySchedule,
  useLookupTeamMemberCrews,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { CleanedEmployeeBenefit, cleanEmployeeBenefit } from "dashboard/pages/benefits/benefitsUtils";
import { Assign } from "utility-types";
import { Dictionary, groupBy } from "lodash";

import { getPayFrequencyHours } from "dashboard/utils/paySchedules";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { useEmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";

type TeamSummaryTableEntry = Assign<
  AggregatedTeamMember,
  {
    annualCashComp: number;
    annualBenefitsComp: number;
    annualAllowancesComp: number;
    annualTotalComp: number;
  }
>;

type BenefitsLookup = Dictionary<[CleanedEmployeeBenefit, ...CleanedEmployeeBenefit[]]>;
type AllowancesLookup = Dictionary<[Allowance, ...Allowance[]]>;

const CreateTeamSummary: React.FC = () => {
  const { customFields, setReverifyUser } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const teamAbilities = useTeamAbilities();
  const benefitAbilities = useEmployeeBenefitAbilities();
  const { can } = useMiterAbilities();

  const navigate = useNavigate();
  const company = useActiveCompany();
  const user = useUser();
  const isMounted = useRef(false);
  const lookupOtRule = useLookupOtRule();
  const lookupCostType = useLookupCostType();
  const jobNameFormatter = useJobNameFormatter();
  const activiyLabelFormatter = useActivityLabelFormatter();
  const lookupPaySchedule = useLookupPaySchedule();
  const hasCostTypes = useCostTypes().length > 0;

  const lookupCrew = useLookupCrew();
  const lookupTeamMemberCrews = useLookupTeamMemberCrews();
  const enabledDemographicQuestions = useEnabledDemographicQuestions();

  const [rawTeamMembers, setRawTeamMembers] = useState<AggregatedTeamMember[]>();
  const [loading, setLoading] = useState(false);
  const [bensAndAllowsLoading, setBensAndAllowsLoading] = useState(false);
  const [allowancesLookup, setAllowancesLookup] = useState<AllowancesLookup>();
  const [benefitsLookup, setBenefitsLookup] = useState<BenefitsLookup>();

  const [showRawSSNs, setShowRawSSNs] = useState(false);
  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const isReverified = (user?.reverified_at || 0) > DateTime.now().minus({ days: 1 }).toSeconds();

  useEffect(() => {
    if (showReverificationModal) {
      setReverifyUser(showReverificationModal);
    }
  }, [showReverificationModal]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (showRawSSNs && !isReverified) {
      setShowReverificationModal(true);
      return;
    }

    getTeamMembers(showRawSSNs);
  }, [showRawSSNs, isReverified]);

  useEffect(() => {
    getTeamMembers();
    getBenefitsAndAllowances();
  }, [!!activeCompanyId]);

  const reportObject = reportList.find((report) => report.slug === "team_summary");

  const TeamSummaryColumns: ColDef[] = [
    {
      field: "first_name",
      headerName: "First name",
      sortable: true,
      filter: true,
      width: 100,
    },
    {
      field: "last_name",
      headerName: "Last name",
      sortable: true,
      filter: true,
      width: 100,
    },
    {
      field: "full_name",
      headerName: "Full name",
      sortable: true,
      filter: true,
      width: 100,
      initialHide: true,
    },
    {
      field: "friendly_id",
      headerName: "ID",
      sortable: true,
      filter: true,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      filter: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      filter: true,
    },
    {
      field: "employment_type",
      headerName: "Employment type",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      valueFormatter: (params) => capitalize(params.value),
    },
    {
      field: "dob",
      headerName: "Date of birth",
      sortable: true,
      filter: "agDateColumnFilter",
      useValueFormatterForExport: true,
      valueGetter: (params) => (params.data?.dob ? DateTime.fromISO(params.data?.dob).toJSDate() : undefined),
      valueFormatter: (params) =>
        params.data?.dob ? DateTime.fromISO(params.data?.dob).toFormat("MM/dd/yyyy") : "-",
    },
    {
      field: "title",
      headerName: "Title",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      valueGetter: (params) => params.data?.title || "-",
    },
    {
      field: "department.name",
      headerName: "Department",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      valueGetter: (params) => params.data?.department?.name || "-",
    },
    {
      field: "location.name",
      headerName: "Location",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      valueGetter: (params) => params.data?.location?.name || "-",
    },
    {
      field: "reports_to.full_name",
      headerName: "Reports to",
      sortable: true,
      filter: true,
      enableRowGroup: true,
    },
    {
      field: "is_universal_supervisor",
      headerName: "Is universal supervisor",
      sortable: true,
      filter: true,
    },
    {
      field: "tenure",
      headerName: "Tenure",
      sortable: true,
      filter: true,
      valueFormatter: (params) => {
        if (!params.value) return "-";
        return convertSecondsToYearsMonthsDays(params.value);
      },
      filterParams: {
        keyCreator: (params) => {
          if (!params.value) return "-";
          return convertSecondsToYearsMonthsDays(params.value);
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) return "-";
          return convertSecondsToYearsMonthsDays(params.value);
        },
      },
      useValueFormatterForExport: true,
    },
    {
      field: "start_date",
      headerName: "Start date",
      sortable: true,
      filter: "agDateColumnFilter",
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data?.start_date ? DateTime.fromISO(params.data?.start_date).toJSDate() : undefined,
      valueFormatter: (params) =>
        params.data?.start_date ? DateTime.fromISO(params.data?.start_date).toFormat("MM/dd/yyyy") : "-",
      useValueFormatterForExport: true,
    },
    {
      field: "end_date",
      headerName: "End date",
      sortable: true,
      filter: "agDateColumnFilter",
      valueGetter: (params) =>
        params.data?.end_date ? DateTime.fromISO(params.data?.end_date).toJSDate() : undefined,
      valueFormatter: (params) =>
        params.data?.end_date ? DateTime.fromISO(params.data?.end_date).toFormat("MM/dd/yyyy") : "-",
      useValueFormatterForExport: true,
    },
    {
      field: "crews",
      headerName: "Crews",
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          const crew = lookupCrew(params.value);
          return crew?.name;
        },
      },
      filterValueGetter: (params) => {
        const teamMemberId = params.data?._id;
        const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
        const crewIds = teamMemberCrews?.map((crew) => crew._id) || [];

        return crewIds;
      },
      valueGetter: (params) => {
        const teamMemberId = params.data?._id;
        const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
        return teamMemberCrews?.map((crew) => crew.name);
      },
      useValueFormatterForExport: true,
      valueFormatter: (params) => {
        return params.value?.join(", ");
      },
    },
    {
      field: "wc_code.label",
      headerName: "Workers comp code",
      sortable: true,
      filter: true,
      enableRowGroup: true,
    },
    {
      field: "pay_type",
      headerName: "Pay type",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      valueFormatter: (params) => capitalize(params.value),
    },
    {
      field: "union_rate.pay_rate_group.label",
      headerName: "Pay rate group",
      sortable: true,
      filter: true,
      enableRowGroup: true,
    },
    {
      field: "union_rate.classification",
      headerName: "Classification",
      sortable: true,
      filter: true,
      enableRowGroup: true,
    },
    {
      field: "pay_rate",
      headerName: "Pay rate",
      sortable: true,
      filter: true,
    },
    {
      field: "overtime_exempt",
      headerName: "Overtime exempt",
      sortable: true,
      filter: true,
    },
    {
      field: "annualCashComp",
      headerName: "Annual cash comp",
      sortable: true,
      filter: true,
      valueFormatter: compValueFormatter,
      aggFunc: "avg",
    },
    {
      field: "annualBenefitsComp",
      headerName: "Annual benefits comp",
      sortable: true,
      filter: true,
      aggFunc: "avg",
      valueFormatter: compValueFormatter,
    },
    {
      field: "annualAllowancesComp",
      headerName: "Annual allowances comp",
      sortable: true,
      filter: true,
      aggFunc: "avg",
      valueFormatter: compValueFormatter,
    },
    {
      field: "annualTotalComp",
      headerName: "Annual total comp",
      sortable: true,
      filter: true,
      aggFunc: "avg",
      valueFormatter: compValueFormatter,
    },
    {
      field: "license_status",
      headerName: "License status",
      sortable: true,
      filter: true,
    },
    {
      field: "ssn",
      headerName: "SSN",
      sortable: true,
      filter: true,
    },
    {
      field: "address.line1",
      headerName: "Address line 1",
      sortable: true,
    },
    {
      field: "address.line2",
      headerName: "Address line 2",
      sortable: true,
    },
    {
      field: "address.city",
      headerName: "Address city",
      sortable: true,
      filter: true,
    },
    {
      field: "address.state",
      headerName: "Address state",
      sortable: true,
      filter: true,
    },
    {
      field: "address.postal_code",
      headerName: "Address postal code",
      sortable: true,
      filter: true,
    },
    {
      field: "workplace.check_workplace.address.line1",
      headerName: "Workplace line 1",
      sortable: true,
    },
    {
      field: "workplace.check_workplace.address.line2",
      headerName: "Workplace line 2",
      sortable: true,
    },
    {
      field: "workplace.check_workplace.address.city",
      headerName: "Workplace city",
      sortable: true,
      filter: true,
    },
    {
      field: "workplace.check_workplace.address.state",
      headerName: "Workplace state",
      sortable: true,
      filter: true,
    },
    {
      field: "workplace.check_workplace.address.postal_code",
      headerName: "Workplace postal code",
      sortable: true,
      filter: true,
    },
    {
      field: "demographics.ethnicity",
      headerName: "Ethnicity",
      filter: "agSetColumnFilter",
      hide: !enabledDemographicQuestions["ethnicity"],
      suppressColumnsToolPanel: !enabledDemographicQuestions["ethnicity"],
      suppressFiltersToolPanel: !enabledDemographicQuestions["ethnicity"],
    },
    {
      field: "demographics.gender",
      headerName: "Gender",
      filter: "agSetColumnFilter",
      hide: !enabledDemographicQuestions["gender"],
      suppressColumnsToolPanel: !enabledDemographicQuestions["gender"],
      suppressFiltersToolPanel: !enabledDemographicQuestions["gender"],
    },
    {
      field: "demographics.veteran_status",
      headerName: "Veteran status",
      filter: "agSetColumnFilter",
      hide: !enabledDemographicQuestions["veteran_status"],
      suppressColumnsToolPanel: !enabledDemographicQuestions["veteran_status"],
      suppressFiltersToolPanel: !enabledDemographicQuestions["veteran_status"],
    },
    {
      field: "demographics.marital_status",
      headerName: "Marital status",
      filter: "agSetColumnFilter",
      hide: !enabledDemographicQuestions["marital_status"],
      suppressColumnsToolPanel: !enabledDemographicQuestions["marital_status"],
      suppressFiltersToolPanel: !enabledDemographicQuestions["marital_status"],
    },
    {
      field: "demographics.disability_status",
      headerName: "Disability status",
      filter: "agSetColumnFilter",
      hide: !enabledDemographicQuestions["disability_status"],
      suppressColumnsToolPanel: !enabledDemographicQuestions["disability_status"],
      suppressFiltersToolPanel: !enabledDemographicQuestions["disability_status"],
    },
    {
      field: "demographics.job_category",
      headerName: "Job category",
      filter: "agSetColumnFilter",
      hide: !enabledDemographicQuestions["job_category"],
      suppressColumnsToolPanel: !enabledDemographicQuestions["job_category"],
      suppressFiltersToolPanel: !enabledDemographicQuestions["job_category"],
    },
    {
      field: "defaultOtRule",
      headerName: "Default OT rule",
      sortable: true,
      filter: true,
    },
    {
      field: "defaultJob",
      headerName: "Default job",
      sortable: true,
      filter: true,
      hide: true,
    },
    {
      field: "defaultActivity",
      headerName: "Default activity",
      sortable: true,
      filter: true,
      hide: true,
    },
    {
      field: "defaultCostType",
      headerName: "Default cost type",
      sortable: true,
      filter: true,
      hide: true,
    },

    {
      field: "archived",
      headerName: "Deleted",
      sortable: true,
      filter: true,
      initialHide: true,
      valueFormatter: (params) => (params.value ? "True" : "False"),
    },
  ];

  const columns = useMemo(() => {
    const tmCustomFields = customFields.filter((cf) => cf.parent_type === "team_member");
    return TeamSummaryColumns.filter((c) => hasCostTypes || c.field !== "defaultCostType").concat(
      buildAgGridColumns(tmCustomFields)
    );
  }, [customFields, hasCostTypes]);

  const buildCompensationProperties = (company: Company | null, tm: AggregatedTeamMember) => {
    // If active company has not been loaded yet, don't return anything until it is available
    if (!company) {
      return {};
    }

    const annualCashComp = roundTo(buildAnnualCashComp(company, tm));
    const annualBenefitsComp = roundTo(buildAnnualBenefitsComp(company, tm));
    const annualAllowancesComp = roundTo(buildAnnualAllowancesComp(company, tm));
    const annualTotalComp = annualCashComp + annualBenefitsComp + annualAllowancesComp;

    return { annualCashComp, annualBenefitsComp, annualAllowancesComp, annualTotalComp };
  };

  const cleanBenefitAmount = (amount: number, benefit: CleanedEmployeeBenefit): number => {
    const limit = BENEFIT_LIMITS[benefit.benefit_type]?.["employer_limit"];
    if (limit == null) return amount;

    return Math.min(amount, Number(limit));
  };

  const buildAnnualBenefitsComp = (company: Company, tm: AggregatedTeamMember) => {
    if (!benefitsLookup) return 0;

    const tmBenefits = benefitsLookup[tm._id];
    if (!tmBenefits) return 0;

    const tmPaySchedule = lookupPaySchedule(tm.pay_schedule_id);
    if (!tmPaySchedule) return 0;

    if (!tm.pay_type) return 0;

    const workHoursInYear = getWorkHoursInYear(company);
    return tmBenefits.reduce((acc, b) => {
      let amount = 0;

      if (b.company_contribution_type === "percentage") {
        const multiplier = tm.pay_type === "salary" ? 1 : workHoursInYear;
        const annualPay = (tm.pay_rate || 0) * multiplier;
        amount = annualPay * (Number(b.company_contribution_percent) / 100);
      } else if (b.company_contribution_amount) {
        if (b.per_hour_company_contribution) {
          amount = Number(b.per_hour_company_contribution) * workHoursInYear;
        } else {
          const payFrequency = tmPaySchedule.check_pay_schedule.pay_frequency;
          const hoursPerPeriod = getPayFrequencyHours(company, payFrequency);
          const periodCount = workHoursInYear / hoursPerPeriod;
          amount = Number(b.company_contribution_amount) * periodCount;
        }
      } else if (b.company_period_amount) {
        amount = Number(b.company_period_amount) * 12;
      }

      const cleanAmount = cleanBenefitAmount(amount, b);
      return acc + cleanAmount;
    }, 0);
  };

  const buildAnnualAllowancesComp = (company: Company, tm: AggregatedTeamMember) => {
    if (!allowancesLookup) return 0;

    const tmAllowances = allowancesLookup[tm._id];
    if (!tmAllowances) return 0;

    return tmAllowances.reduce((acc, a) => {
      if (a.calculation_method === "per_month") {
        return acc + a.amount * 12;
      } else if (a.calculation_method === "per_week") {
        return acc + a.amount * getWorkWeeksInYear(company);
      } else {
        return acc;
      }
    }, 0);
  };

  const buildAnnualCashComp = (company: Company, tm: AggregatedTeamMember) => {
    if (tm.pay_type === "salary") {
      return tm.pay_rate || 0;
    } else {
      return (tm.pay_rate || 0) * getWorkHoursInYear(company);
    }
  };

  const getTeamMembers = async (include_raw_ssns?: boolean) => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const payload = {
        type: "team_summary",
        params: {
          company: activeCompanyId,
          include_raw_ssns,
        },
        format: "json",
      };

      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      setRawTeamMembers(response);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const getAllowances = async () => {
    if (!activeCompanyId) return;
    try {
      const filter = [{ field: "company", value: activeCompanyId }];

      const response = await MiterAPI.allowances.retrieve({ filter });
      if (response.error) throw new Error("Error retrieving allowances");

      const lookup = groupBy(response, (a) => a.team_member);
      setAllowancesLookup(lookup);
    } catch (e) {
      console.log("Error retrieving allowances for TM summary:", e);
      Notifier.error("There was an error retrieving allowances");
    }
  };

  const getEmployeeBenefits = async () => {
    if (!activeCompanyId) return;
    try {
      const filter = [{ field: "company", value: activeCompanyId }];
      const response = await MiterAPI.benefits.employee.search(filter);

      if (response.error) throw Error(response.error);
      const cleanedBenefits = response.map((b) => cleanEmployeeBenefit(b, benefitAbilities));

      const lookup = groupBy(cleanedBenefits, (b) => b.miter_tm._id);
      setBenefitsLookup(lookup);
    } catch (e) {
      console.log("Error retrieving benefits for TM summary:", e);
      Notifier.error("There was an error retrieving benefits");
    }
  };

  const getBenefitsAndAllowances = async () => {
    setBensAndAllowsLoading(true);
    await Promise.all([getAllowances(), getEmployeeBenefits()]);
    setBensAndAllowsLoading(false);
  };

  const teamMembers = useMemo(() => {
    return rawTeamMembers
      ?.filter((tm) => !tm.archived && teamAbilities.can("read_sensitive", tm))
      .sort((a, b) => baseSensitiveCompare(a.friendly_id, b.friendly_id))
      .map((tm): TeamSummaryTableEntry => {
        let tenure: number | null = null;
        if (tm?.start_date) {
          if (tm.end_date) {
            tenure = getTenureInSeconds(DateTime.fromISO(tm.start_date), DateTime.fromISO(tm.end_date)) * -1;
          } else {
            tenure = getTenureInSeconds(DateTime.fromISO(tm.start_date), DateTime.now()) * -1;
          }
        }
        return {
          ...tm,
          defaultOtRule: lookupOtRule(tm.default_ot_rule_id)?.label || "-",
          defaultJob: (tm.default_job_id && jobNameFormatter(tm.default_job_id)) || "-",
          defaultActivity: (tm.default_activity_id && activiyLabelFormatter(tm.default_activity_id)) || "-",
          defaultCostType: lookupCostType(tm.default_cost_type_id)?.label || "-",
          ...buildAgGridRow(tm.custom_field_values, customFields),
          ssn: tm.ssn || tm?.check_tm?.ssn_last_four,
          tenure,
          ...buildCompensationProperties(company, tm),
        };
      });
  }, [
    company,
    rawTeamMembers,
    lookupOtRule,
    jobNameFormatter,
    activiyLabelFormatter,
    lookupCostType,
    allowancesLookup,
    benefitsLookup,
    customFields,
  ]);

  return (
    <div className="page-content">
      <Helmet>
        <title>Team Summary | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Create a team member summary report</h1>
      </div>
      <div className="report-page-description flex">
        {reportObject?.description}
        <div onClick={() => navigate("/team")} className="blue-link" style={{ marginLeft: 5 }}>
          View team members
        </div>
        .
      </div>
      {can("team:read_sensitive") && (
        <Formblock
          label="Include raw SSNs"
          labelInfo="Show full SSNs on the report."
          type="checkbox"
          onChange={(e) => setShowRawSSNs(e.target.checked)}
          name="show_full_ssns"
          editing={true}
          style={{ alignItems: "flex-start" }}
          className="report-checkbox"
        />
      )}

      {bensAndAllowsLoading || loading ? (
        <Loader />
      ) : (
        <AgGridTable
          reportId="team-summary"
          data={teamMembers}
          columnDefs={columns}
          csvExportParams={{ fileName: "Team members summary" }}
          gridOptions={{
            autoGroupColumnDef: { minWidth: 200 },
            enableRangeSelection: true,
            animateRows: true,
            groupDisplayType: "multipleColumns",
            suppressAggFuncInHeader: true,
          }}
        />
      )}
      <div className="vertical-spacer-large"></div>
    </div>
  );
};

export default CreateTeamSummary;

const compValueFormatter = (params): string => {
  if (!params.value) return "$0.00";

  let value = 0;
  if (typeof params.value === "string") {
    value = Number(params.value);
  } else if (typeof params.value === "number") {
    value = params.value;
  } else if (typeof params.value === "object") {
    value = Number(params.value.value);
  }

  return "$" + value.toFixed(2);
};

const BENEFIT_LIMITS = {
  hsa: {
    employee_limit: {
      single: 3850.0,
      family: 7750.0,
    },
    employer_limit: {
      single: 0,
      family: 0,
    },
  },
  fsa_dependent_care: {
    employee_limit: 5000.0,
    employer_limit: 0,
  },
  simple_ira: {
    employee_limit: 15500,
    employer_limit: 0,
  },
  "401k": {
    employee_limit: 22500.0,
    employer_limit: 43500.0,
  },
  "457": {
    employee_limit: 22500.0,
    employer_limit: 0,
  },
  "403b": {
    employee_limit: 22500.0,
    employer_limit: 43500.0,
  },
  roth_401k: {
    employee_limit: 22500.0,
    employer_limit: 38500.0,
  },
  roth_457: {
    employee_limit: 22500.0,
    employer_limit: 0,
  },
  roth_403b: {
    employee_limit: 22500.0,
    employer_limit: 43500.0,
  },
};
