import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { Company } from "backend/models";
import { SettingsCard } from "ui/settings/SettingsCard";
import { ExpensesAccountingSettings } from "../accounting/AccountingSettings/ExpensesAccountingSettings";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import ExpenseReimbursementCategorySettings from "./ExpenseReimbursementCategorySettings";
import CardTransactionCategorySettings from "./CardTransactionCategorySettings";

type ExpenseSettingUpdateParams = Company["settings"]["expense_reimbursements"];

export const ExpenseSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const expenseSetting = activeCompany?.settings?.expense_reimbursements || null;
  const form = useForm({
    defaultValues: {
      default_unapproved_from_dashboard: !!expenseSetting?.default_unapproved_from_dashboard,
    },
  });
  const { handleSubmit } = form;

  const updateSettings = async (data: ExpenseSettingUpdateParams): Promise<void> => {
    try {
      if (!activeCompanyId) throw Error(`Could not update settings for company.`);
      const response = await MiterAPI.companies.update(activeCompanyId, {
        $set: { "settings.expense_reimbursements": data },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Expense settings updated successfully.");
      fetchUserData();
    } catch (e) {
      console.error(`Error updating expense settings for company: ${activeCompanyId!}`, e);
      Notifier.error("There was an error updating expense settings. Our engineers are looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(() => {
    handleSubmit(updateSettings)();
  }, 500);

  /* Form */

  return (
    <div>
      <Helmet>
        <title>Expense settings | Miter</title>
      </Helmet>

      <div>
        <div className="vertical-spacer-large"></div>
        <div>Configure settings for expense purchases and mileage on personal vehicles.</div>
        <div className="vertical-spacer-small"></div>

        <SettingsCard title={"Approvals"}>
          <Formblock
            type="checkbox"
            name="default_unapproved_from_dashboard"
            className="align-items-center"
            form={form}
            sideBySideInput={false}
            editing={true}
            onChange={updateSettingsDebounced}
            text='Set admin-created reimbursements to "unapproved" by default'
          />
        </SettingsCard>
        <ExpensesAccountingSettings />

        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Card transaction settings</div>
          <div className="vertical-spacer-small"></div>
          <>
            <h3 className="settings-subheader">Categories</h3>
            <CardTransactionCategorySettings />
          </>
        </div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Reimbursement settings</div>
          <div className="vertical-spacer-small"></div>
          <>
            <h3 className="settings-subheader">Categories</h3>
            <ExpenseReimbursementCategorySettings />
          </>
        </div>
      </div>
    </div>
  );
};
