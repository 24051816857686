import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { capitalize, formatDate, useQuery, isPastDue, payrollTypeLookup } from "dashboard/utils/utils";
import { Toggler, Button, Loader, usdString } from "ui";
import { Badge } from "ui";
import CreateOffCyclePayrollModal from "./OffCycleSettingsModal";
import emptyVault from "dashboard/assets/empty-vault.svg";
import HighlightBox from "dashboard/components/shared/HighlightBox";
import { FaEnvelopeSquare, FaPhoneSquareAlt } from "react-icons/fa";
import { MiterAPI } from "dashboard/miter";
import { DeleteModal } from "ui";
import Notifier from "dashboard/utils/notifier";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import PayrollBanner from "./PayrollBanner";
import { TablePayroll } from "backend/utils/aggregations/payrollAggregations";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import { useActiveCompany, useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { usePayScheduleAccessor } from "dashboard/hooks/usePayScheduleAccessor";
import EmptyState from "ui/empty-state/EmptyState";

export type PayrollTableEntry = TablePayroll & {
  deadline_string: string;
  full_date: string;
  debit: number;
  debit_string: string;
  pay_period: string;
  payday_string: string;
  pay_period_unformatted: string;
};

const Payrolls: React.FC = () => {
  // Hooks
  const activeCompany = useActiveCompany();
  const path = useQuery().get("status") || (activeCompany?.has_payroll ? "draft" : "all");
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();

  const { can, cannot } = useMiterAbilities();
  const { canAccessPayroll } = usePayScheduleAccessor();

  // State
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<PayrollTableEntry[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [rawData, setRawData] = useState<TablePayroll[]>();

  // Calling backend
  const getData = async () => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const payrolls = await MiterAPI.payrolls.get_table({ company: activeCompanyId });
      if (payrolls.error) throw new Error(payrolls.error);
      setRawData(payrolls);
    } catch (e: $TSFixMe) {
      console.log("Error loading payrolls table:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const preparedData = useMemo(() => {
    return rawData?.map((payroll) => {
      const deadline_string = payroll.approval_deadline
        ? DateTime.fromISO(payroll.approval_deadline).toFormat("MMM d 'at' ha ZZZZ")
        : "N/A";

      // If payroll is fully manual and switches to processing, it's considered paid
      const status = payroll.is_fully_manual && payroll.status === "processing" ? "paid" : payroll.status;

      const entry: PayrollTableEntry = {
        ...payroll,
        status,
        deadline_string,
        full_date: DateTime.fromISO(payroll.payday).toLocaleString(DateTime.DATE_FULL),
        debit: payroll.totals ? Number(payroll.totals.cash_requirement) : 0,
        debit_string: payroll.totals ? usdString(payroll.totals.cash_requirement) : "-",
        pay_period: formatDate(payroll.period_start, payroll.period_end),
        pay_period_unformatted: payroll.period_start + " " + payroll.period_end,
        payday_string: formatDate(payroll.payday, undefined),
      };
      return entry;
    });
  }, [rawData]);

  // Action handlers
  const handleToggle = (option: string) => {
    navigate("/payrolls?status=" + option, { replace: true });
  };

  const handleRowClick = (row: PayrollTableEntry) => {
    navigate("/payrolls/" + row._id);
  };

  const handleArchive = async () => {
    if (cannot("payrolls:delete")) {
      Notifier.error("You do not have permission to delete payrolls.");
      return;
    }

    setArchiving(true);
    const results = await Promise.all(selectedRows.map(async (r) => await MiterAPI.payrolls.archive(r._id)));
    const fails = results.filter((res) => res.error);
    if (fails.length) {
      if (fails.length === results.length) {
        Notifier.error(
          "There was an error deleting payrolls. If the error persists, please contact Miter support."
        );
      } else {
        Notifier.warning(
          "Some payrolls failed to deleted. If the error persists, please contact Miter support."
        );
      }
      console.error("Payroll failed to delete:", fails[0]!.error);
    } else {
      Notifier.success(`Payroll${results.length > 1 ? "s" : ""} successfully deleted.`);
    }
    getData();
    setSelectedRows([]);
    setShowArchiveModal(false);
    setArchiving(false);
  };

  // Toggler setup
  const togglerConfig = useMemo(() => {
    const hasFailedPayrolls = preparedData?.some((p) => p.status === "failed");
    return [
      { path: "draft", label: "Draft" },
      { path: "pending", label: "Pending" },
      { path: "processing", label: "Processing" },
      { path: "paid", label: "Paid" },
      ...(hasFailedPayrolls ? [{ path: "failed", label: "Failed" }] : []),
      { path: "all", label: "All" },
    ];
  }, [preparedData]);

  const staticActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "New Off-Cycle",
        className: "button-2 no-margin table-button",
        action: () => setIsCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => !!activeCompany?.has_payroll && can("payrolls:create"),
      },
    ];

    return acts;
  }, [activeCompany]);

  const dynamicActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setShowArchiveModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("payrolls:delete") && path === "draft",
      },
    ];

    return acts;
  }, [path]);

  // Rendering functions
  const payrollEnabled = !!activeCompany?.has_payroll || !!rawData?.length;

  const renderPayrollNotEnabled = () => {
    return (
      <EmptyState
        title={"To run payroll, please set up a plan."}
        description={"Contact our sales team to get started."}
        image={emptyVault}
      >
        <div className={"sales-contact-options"}>
          <HighlightBox title={"Give us a call"} className={"sales-contact-option"}>
            <FaPhoneSquareAlt style={{ marginBottom: -3 }} /> (323) 576-4166
          </HighlightBox>
          <HighlightBox title={"Send us an email"} className={"sales-contact-option"}>
            <FaEnvelopeSquare style={{ marginBottom: -3 }} /> support@miter.com
          </HighlightBox>
        </div>
      </EmptyState>
    );
  };

  const permissionedData = useMemo(() => {
    return preparedData?.filter((p) => canAccessPayroll(p));
  }, [preparedData, canAccessPayroll]);

  const tableData = useMemo(() => {
    return path === "all" ? permissionedData : permissionedData?.filter((p) => p.status.includes(path));
  }, [permissionedData, path, canAccessPayroll]);

  const renderPayrollsTable = () => {
    return (
      <div>
        {activeCompany?.has_payroll && <Toggler config={togglerConfig} active={path} toggle={handleToggle} />}
        <TableV2
          id={path + "-payrolls-table"}
          resource="payrolls"
          data={tableData}
          columns={path === "draft" ? payrollColumnsDraft : payrollColumnsNonDraft}
          dynamicActions={dynamicActions}
          staticActions={staticActions}
          onSelect={path === "draft" ? setSelectedRows : undefined}
          defaultSelectedRows={selectedRows}
          onClick={handleRowClick}
          isLoading={loading}
          rowLinkBuilder={(p) => (p ? `/payrolls/${p._id}` : undefined)}
        />
      </div>
    );
  };

  const renderPayrolls = () => {
    return (
      <>
        <PayrollBanner />
        {renderPayrollsTable()}
        {isCreating && <CreateOffCyclePayrollModal hide={() => setIsCreating(false)} />}
      </>
    );
  };

  const renderArchiveModal = () => {
    const ending = selectedRows.length > 1 ? "s" : "";
    const numPayrollsString = `the${
      selectedRows.length > 1 ? " " + selectedRows.length.toString() : ""
    } selected payroll${ending}`;
    return (
      <DeleteModal
        onHide={() => setShowArchiveModal(false)}
        cancelText="Cancel"
        deleteText="Delete"
        body={`Are you sure you want to delete ${numPayrollsString}?`}
        loading={archiving}
        header={`Delete payroll${ending}`}
        onDelete={handleArchive}
      />
    );
  };

  // UseEffect
  useEffect(() => {
    setSelectedRows([]);
  }, [path]);

  useEffect(() => {
    getData();
  }, [activeCompanyId]);

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <Helmet>
          <title>{capitalize(path)} Payrolls | Miter</title>
        </Helmet>
        <div className="flex">
          <h1>Payrolls</h1>
          <div className="flex-1"></div>
          {can("payrolls:settings") && (
            <Button text="Settings" className="button-1" onClick={() => navigate("/payrolls/settings")} />
          )}
        </div>
        {loading && !activeCompany?.has_payroll ? (
          <Loader />
        ) : payrollEnabled ? (
          renderPayrolls()
        ) : (
          renderPayrollNotEnabled()
        )}
      </div>
      {showArchiveModal && renderArchiveModal()}
      <div className="footer"></div>
    </div>
  );
};

export default Payrolls;

const payrollColumns: ColumnConfig<PayrollTableEntry>[] = [
  {
    field: "type",
    headerName: "Type",
    dataType: "string",
    valueGetter: (params) => {
      if (!params.data) return null;
      return payrollTypeLookup[params.data.type];
    },
    maxWidth: 150,
  },
  {
    field: "label",
    headerName: "Label",
    dataType: "string",
    valueGetter: (params) => {
      return params.data?.pay_schedule || params.data?.label;
    },
  },
  { field: "pay_period_unformatted", headerName: "Pay period", dataType: "date", isDateRange: true },
  { field: "payday", headerName: "Payday", dataType: "date", dateFormat: "MMM d" },
  {
    field: "deadline_string",
    headerName: "Approve by",
    dataType: "string",
  },
  { field: "debit_string", headerName: "Total debit", dataType: "string" },
  {
    headerName: "Status",
    dataType: "string",
    cellRenderer: (params) => createStatusBadge(params.data),
    valueGetter: (params) => createStatusValue(params.data),
  },
];

const payrollColumnsDraft = payrollColumns.filter((c) => c.field !== "debit_string");
const payrollColumnsNonDraft = payrollColumns.filter((c) => c.field !== "deadline_string");

const createStatusBadge = (payroll: TablePayroll) => {
  return (
    <div className="flex">
      <Badge className="no-margin" text={payroll.status.includes("paid") ? "paid" : payroll.status} />
      {isPastDue(payroll) && <Badge text="past due" color="red" />}
      {payroll.void_of && <Badge text="void" color="light-gray" />}
      {payroll.status === "partially_paid" && <Badge text="partial" color="yellow" />}
    </div>
  );
};

const createStatusValue = (payroll: TablePayroll | undefined) => {
  if (!payroll) return "";

  if (payroll.void_of) return "void";
  if (payroll.status === "partially_paid") return "partial";
  if (payroll.status.includes("paid")) return "paid";
  if (isPastDue(payroll)) return "past due";
  return payroll.status;
};
