/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggregatedVendor, MiterAPI, Vendor } from "dashboard/miter";
import { displayFieldErrors } from "dashboard/utils/errors";
import Notifier from "dashboard/utils/notifier";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ConfirmModal, ModalFooter, SliderModal } from "ui";
import { ErrorField } from "backend/services/error-service";
import VendorForm, { VendorFormValues } from "./VendorForm";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { CreateOrUpdateVendorParams } from "backend/services/vendor-service";

type Props = {
  vendorId?: string;
  onFinish: (vendor?: Vendor) => void;
  onDelete?: () => void;
  onHide: () => void;
};

const VendorModal: FC<Props> = ({ vendorId, onFinish, onHide, onDelete }) => {
  const activeCompanyId = useActiveCompanyId();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [vendor, setVendor] = useState<AggregatedVendor>();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const mode = vendorId ? "update" : "create";

  /*********************************************************
   *
   * Initialize form
   *
   **********************************************************/
  const buildDefaults = (vendor: AggregatedVendor | undefined): VendorFormValues | {} => {
    if (!vendor) return {};

    return {
      name: vendor.name,
      description: vendor.description,
      external_id: vendor.external_id,
    };
  };

  const form = useForm<VendorFormValues>({
    defaultValues: buildDefaults(vendor),
    shouldUnregister: false,
  });

  const { handleSubmit, setError, reset } = form;
  /*********************************************************
   *  API Functions
   **********************************************************/
  const getVendor = async () => {
    if (!vendorId) return;
    setLoading(true);
    try {
      const vendor = await MiterAPI.vendors.retrieve(vendorId);
      if (vendor.error) throw Error(vendor.error);

      setVendor(vendor);
      reset(buildDefaults(vendor));
    } catch (e: any) {
      Notifier.error(e.message);
    }

    setLoading(false);
  };

  const formatFieldErrors = (errors: ErrorField[] | undefined | null): ErrorField[] | undefined => {
    return errors
      ?.filter((error) => !!error.name)
      .map((error: ErrorField) => {
        const formattedError = { ...error };

        if (error.name === "account_number") {
          formattedError.name = "ach_params.account_number";
        }

        if (error.name === "routing_number") {
          formattedError.name = "ach_params.routing_number";
        }

        return formattedError;
      });
  };

  // Creates the vendor
  const createVendor = async (data: VendorFormValues) => {
    setLoading(true);
    try {
      const payload: CreateOrUpdateVendorParams = {
        ...data,
        company_id: activeCompanyId!,
        default_payment_method: data.default_payment_method?.value,
      };

      // Make sure to pull the value out of state
      if (data.paper_check_config) {
        // @ts-expect-error form type is not correct
        payload.paper_check_config!["address"]["state"] = data.paper_check_config.address?.state?.value;
      }

      if (data.bank_account) {
        payload.bank_account_params = {
          account_number: data.bank_account.account_number,
          routing_number: data.bank_account.routing_number,
        };
      }

      const response = await MiterAPI.vendors.create(payload);
      if (response.error) {
        const formattedErrors = formatFieldErrors(response.fields);
        if (formattedErrors) {
          displayFieldErrors(formattedErrors, setError);
        }

        throw Error(response.error);
      }

      Notifier.success("Vendor created.");
      setVendor(response);
      onFinish(response);
    } catch (e: any) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  // Updates the vendor
  const updateVendor = async (data: VendorFormValues) => {
    if (!vendorId) return;
    setLoading(true);

    try {
      const payload: CreateOrUpdateVendorParams = {
        ...data,
        default_payment_method: data.default_payment_method?.value,
      };

      // Make sure to pull the value out of state
      if (data.paper_check_config) {
        // @ts-expect-error form type is not correct
        payload.paper_check_config!["address"]["state"] = data.paper_check_config.address?.state?.value;
      }

      if (data.bank_account) {
        payload.bank_account_params = {
          account_number: data.bank_account.account_number,
          routing_number: data.bank_account.routing_number,
        };
      }

      const response = await MiterAPI.vendors.update(vendorId, payload);
      if (response.error) {
        const formattedErrors = formatFieldErrors(response.fields);
        if (formattedErrors) {
          displayFieldErrors(formattedErrors, setError);
        }

        throw Error(response.error);
      }

      setVendor(response);
      Notifier.success("Vendor updated.");
      onFinish();
    } catch (e: any) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  // Deletes the vendor
  const deleteVendor = async () => {
    if (!vendorId) return;

    setLoading(true);
    try {
      const response = await MiterAPI.vendors.delete(vendorId);
      if (response.error) throw Error(response.error);

      Notifier.success("Deleted vendor.");

      if (onDelete) {
        onDelete();
      } else {
        onFinish();
      }
    } catch (e: any) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const onSubmit = () => {
    if (mode === "create") {
      handleSubmit(createVendor)();
    } else if (mode === "update") {
      handleSubmit(updateVendor)();
    }
  };

  const handleCancel = () => {
    onHide();
  };

  const handleDelete = () => {
    setDeleting(true);
  };

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const renderSubmitText = () => {
    if (mode === "update") return "Update";
    if (mode === "create") return "Save";
  };

  const renderDeleteText = () => {
    if (mode === "update") {
      return "Delete";
    }
  };

  const renderCancelText = () => {
    return "Cancel";
  };

  useEffect(() => {
    getVendor();
  }, []);

  return (
    <SliderModal
      title={`${mode === "create" ? "Create" : "Update"} vendor`}
      onCancel={onHide}
      animate={true}
      shouldOnclickAwayClose={true}
      footer={
        <ModalFooter
          loading={loading}
          className={"form"}
          cancelText={renderCancelText()}
          onCancel={handleCancel}
          showDelete={mode === "update"}
          submitText={renderSubmitText()}
          deleteText={renderDeleteText()}
          onDelete={handleDelete}
          onSubmit={onSubmit}
        />
      }
      loading={loading}
    >
      <div className={"modal-body"} style={{ width: "500px" }}>
        <VendorForm vendor={vendor} form={form} />
      </div>
      {deleting && (
        <ConfirmModal
          title={"Delete Vendor"}
          body={"Are you sure you want to delete this vendor?"}
          onYes={deleteVendor}
          onNo={() => setDeleting(false)}
        />
      )}
    </SliderModal>
  );
};
export default VendorModal;
