import { useActiveCompanyId, useLedgerAccountOptions, usePrgOptions } from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage } from "dashboard/utils/flatfile";
import React, { useContext, useMemo } from "react";
import { FringeRecord, ImportResult, MiterAPI, UnionRateFringe } from "dashboard/miter";
import { Notifier } from "ui";
import { FlatfileResults } from "@flatfile/react";
import { ImportField, Importer } from "dashboard/components/importer/Importer";
import {
  benefitTypeOptions,
  categoryOptions,
  isFringeDeduction,
  typeOptions,
} from "./PayRateGroupFringeModal";
import { includeWithEarningOption } from "dashboard/pages/accounting/accountingUtils";
import AppContext from "dashboard/contexts/app-context";
import ObjectID from "bson-objectid";
import { UnionRateFringeCategory } from "backend/models/union-rate";
import { Option } from "packages/ui/form/Input";
import { getOtMultiplierTooltip } from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";

type Props = {
  onFinish: (importResult?: ImportResult) => void;
};

export const PayRateGroupFringeImporter: React.FC<Props> = ({ onFinish }) => {
  const { integrations } = useContext(AppContext);
  const companyId = useActiveCompanyId();
  const payRateGroupOptions = usePrgOptions();

  const ledgerAccountOptions = useLedgerAccountOptions();
  const expenseAccountOptions = useLedgerAccountOptions({ includeCustomOption: includeWithEarningOption });

  const contributionTypeOptions: Option<UnionRateFringe["calculation_method"] | "dynamic">[] = [
    { label: "$/hour", value: "per_hour" },
    { label: "$/week", value: "per_week" },
    { label: "$/month (lump sum)", value: "per_month_lump_sum" },
    { label: "% of earnings", value: "percent" },
    { label: "Dynamic offset", value: "dynamic" },
  ];

  const hasContractorsPlan = useMemo(
    () =>
      integrations.find((i) => i.key === "contractors_plan" && i.connection && !i.connection.pending_setup),
    [integrations]
  );

  const validateIsBonafide = (record: FringeRecord) => {
    const isDeduction = isFringeDeduction(record.fringeType);
    const isBonafide = record.isBonafide;

    const canBeNonBonafide =
      (record.calcMethod === "per_hour" || record.calcMethod === "percent") && !isDeduction;

    if (!isBonafide && !canBeNonBonafide) {
      return buildFlatfileMessage("Cannot set to non-bonafide", record, "error");
    }

    return { value: isBonafide };
  };

  const validateMaximums = (record: FringeRecord, val: string) => {
    const canHaveMaximums =
      record.fringeType === "non_taxable_contribution" && record.calcMethod === "per_hour";

    if (!canHaveMaximums && !!val) {
      return buildFlatfileMessage("Must be non-taxable and per hour to allow maximums", val, "error");
    }

    validateNumber(val);
    return { value: val };
  };

  const validateNumber = (val: string) => {
    if (val && isNaN(Number(val))) {
      return buildFlatfileMessage("Must be a number", val, "error");
    }
    return { value: val };
  };

  const validateOT = (record: FringeRecord, val: string) => {
    if (!!val && record.calcMethod !== "per_hour" && record.calcMethod !== "percent") {
      return buildFlatfileMessage("Must be a per hour or percent calc method", val, "error");
    }

    validateNumber(val);
  };

  const validateCalcMethod = (record: FringeRecord) => {
    if (record.calcMethod === "dynamic") {
      if (record.fringeType !== "non_taxable_contribution") {
        return buildFlatfileMessage(
          "Must be a non-taxable fringe type to be dynamic",
          record.calcMethod,
          "error"
        );
      }
    }
    return { value: record.calcMethod };
  };

  const validateBenType = (record: FringeRecord) => {
    if (record.fringeType !== "benefit_type_deduction" && record.benefitType) {
      return buildFlatfileMessage(
        "Must be a pre-tax fringe deduction to have a benefit type",
        record.benefitType,
        "error"
      );
    }

    return { value: record.benefitType };
  };

  const validateExpenseAccount = (record: FringeRecord) => {
    if (record.expenseAccountId && isFringeDeduction(record.fringeType)) {
      return buildFlatfileMessage("Not available for deductions", record.expenseAccountId, "error");
    }
    return { value: record.expenseAccountId };
  };

  const validateLiabilityAccount = (record: FringeRecord) => {
    if (record.liabilityAccountId && record.fringeType === "taxable_earning") {
      return buildFlatfileMessage("Not available for taxable earnings", record.liabilityAccountId, "error");
    }
    return { value: record.liabilityAccountId };
  };

  const validateContractorPlan = (record: FringeRecord) => {
    if (record.isContractorsPlan) {
      if (isFringeDeduction(record.fringeType) || record.fringeType === "taxable_earning") {
        return buildFlatfileMessage(
          "Not available for deductions or taxable earnings",
          record.isContractorsPlan,
          "error"
        );
      } else if (!hasContractorsPlan) {
        return buildFlatfileMessage(
          "Not currently integrated with the Contractors Plan",
          record.isContractorsPlan,
          "error"
        );
      } else if (record.category !== "pension") {
        return buildFlatfileMessage("Only available for pensions", record.isContractorsPlan, "error");
      }
    }

    return { value: record.liabilityAccountId };
  };

  const handleSubmit = async (results: FlatfileResults) => {
    try {
      const cleanInput: (UnionRateFringe & { payRateGroupId: string })[] = results.validData.map(
        (input: FringeRecord) => {
          const cleanedOtMult =
            input.otMultiplier && !isNaN(Number(input.otMultiplier)) ? Number(input.otMultiplier) : undefined;
          const cleanedDotMult =
            input.dotMultiplier && !isNaN(Number(input.dotMultiplier))
              ? Number(input.dotMultiplier)
              : undefined;

          const cleanedOtMultipliers =
            cleanedOtMult != null || cleanedDotMult != null
              ? { dot: cleanedDotMult, ot: cleanedOtMult }
              : undefined;

          const perHourMaximums =
            !!input.hoursPerDay || !!input.hoursPerPayPeriod
              ? {
                  hours_per_day: Number(input.hoursPerDay),
                  hours_per_pay_period: Number(input.hoursPerPayPeriod),
                }
              : undefined;

          const dynamicOffset = input.calcMethod === "dynamic";
          const calcMethod = dynamicOffset ? "per_hour" : input.calcMethod || "per_hour";

          const perHourOrPercent = calcMethod === "per_hour" || calcMethod === "percent";

          const eligibleHourlyEarnings = !perHourOrPercent
            ? null
            : input.forHoursPaid
            ? "hours_paid"
            : "hours_worked";

          const checkBenType =
            input.fringeType === "benefit_type_deduction"
              ? (input.benefitType as UnionRateFringe["check_benefit_type"])
              : undefined;

          const unionRateFringe: UnionRateFringe & { payRateGroupId: string } = {
            payRateGroupId: input.payRateGroupId,
            _id: ObjectID().toString(),
            fringe_group_id: ObjectID().toString(),
            label: input.description.trim(),
            category: input.category as UnionRateFringeCategory,
            amount: 0,
            type: input.fringeType as UnionRateFringe["type"],
            check_benefit_type: checkBenType,
            expense_account_id: !!input.expenseAccountId ? input.expenseAccountId : undefined,
            liability_account_id: !!input.liabilityAccountId ? input.liabilityAccountId : undefined,
            calculation_method: calcMethod as UnionRateFringe["calculation_method"],
            eligible_hourly_earnings: eligibleHourlyEarnings,
            dynamic_offset_eligible: dynamicOffset,
            ot_multipliers: cleanedOtMultipliers,
            integrations: { contractors_plan: { is_cp_benefit: !!input.isContractorsPlan } },
            per_hour_maximums: perHourMaximums,
            tenure_days_until_eligible: !!input.tenureDaysUntilEligible
              ? Number(input.tenureDaysUntilEligible)
              : undefined,
            non_bonafide: !input.isBonafide,
          };
          return unionRateFringe;
        }
      );

      const response = await MiterAPI.union_rates.import_fringes({
        company: companyId || "",
        raw_inputs: results.validData,
        clean_inputs: cleanInput,
      });

      if (response.error) throw new Error(response.error);
      onFinish(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating fringes.");
    }

    return;
  };

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Pay Rate Group",
        type: "select",
        key: "payRateGroupId",
        options: payRateGroupOptions,
        validators: [{ validate: "required" }],
      },
      {
        label: "Description",
        type: "string",
        key: "description",
        validators: [{ validate: "required" }],
      },
      {
        label: "Fringe Type",
        type: "select",
        key: "fringeType",
        options: typeOptions,
        validators: [{ validate: "required" }],
      },
      {
        label: "Associated benefit type",
        description: "Only required for pre-tax deductions",
        type: "select",
        key: "benefitType",
        options: benefitTypeOptions,
        validators: [
          {
            validate: "required_with_values",
            fieldValues: { fringeType: "benefit_type_deduction" },
            error: "Pre-tax deductions must have a valid benefit type",
          },
        ],
        hook: (record) => (typeof record === "string" ? { value: record } : validateBenType(record)),
      },
      {
        label: "Category",
        type: "select",
        options: categoryOptions,
        key: "category",
        validators: [{ validate: "required" }],
      },
      {
        label: "Calculation Method",
        type: "select",
        options: contributionTypeOptions,
        key: "calcMethod",
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record === "string" ? { value: record } : validateCalcMethod(record)),
      },
      {
        label: "Applies to all paid hours",
        description: "If unchecked, will only apply to hours worked",
        type: "checkbox",
        key: "forHoursPaid",
        validators: [{ validate: "required" }],
      },
      {
        label: "Bonafide",
        description: "If unchecked, will not offset any minimum fringe rate requirements",
        type: "checkbox",
        key: "isBonafide",
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record === "string" ? { value: record } : validateIsBonafide(record)),
      },
      {
        label: "OT multiplier",
        description: getOtMultiplierTooltip("ot", "fringes"),
        type: "string",
        key: "otMultiplier",
        hook: (record) =>
          typeof record === "string" ? validateNumber(record) : validateOT(record, record.otMultiplier),
      },
      {
        label: "DOT multiplier",
        description: getOtMultiplierTooltip("dot", "fringes"),
        type: "string",
        key: "dotMultiplier",
        hook: (record) =>
          typeof record === "string" ? validateNumber(record) : validateOT(record, record.dotMultiplier),
      },
      {
        label: "Maximum contribution hours/day",
        type: "string",
        key: "hoursPerDay",
        hook: (record) =>
          typeof record === "string" ? { value: record } : validateMaximums(record, record.hoursPerDay),
      },
      {
        label: "Maximum contribution hours/pay period",
        type: "string",
        key: "hoursPerPayPeriod",
        hook: (record) =>
          typeof record === "string" ? { value: record } : validateMaximums(record, record.hoursPerPayPeriod),
      },
      {
        label: "# of days until employee is eligible",
        type: "string",
        key: "tenureDaysUntilEligible",
        hook: (val) =>
          typeof val === "string" ? validateNumber(val) : validateNumber(val.tenureDaysUntilEligible),
      },
      {
        label: "GL expense account (optional)",
        type: "select",
        key: "expenseAccountId",
        options: expenseAccountOptions,
        hook: (record) => (typeof record === "string" ? { value: record } : validateExpenseAccount(record)),
      },
      {
        label: "GL liability account (optional)",
        type: "select",
        key: "liabilityAccountId",
        options: ledgerAccountOptions,
        hook: (record) => (typeof record === "string" ? { value: record } : validateLiabilityAccount(record)),
      },
      {
        label: "Report contributions to The Contractors' Plan",
        type: "checkbox",
        key: "isContractorsPlan",
        hook: (record) => (typeof record === "string" ? { value: record } : validateContractorPlan(record)),
      },
    ];

    return fieldList;
  }, [payRateGroupOptions, ledgerAccountOptions, expenseAccountOptions]);

  return (
    <Importer title="Import fringes" id="fringes" resource="fringes" onSave={handleSubmit} fields={fields} />
  );
};
