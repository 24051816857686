import { TeamMemberDocumentRow } from "dashboard/components/documents/TeamDocumentsTable";
import { FilesTableRow } from "dashboard/components/tables/FilesTable";
import { useCallback, useMemo } from "react";
import { CheckDocument } from "backend/utils/check/check-types";
import { useSessionPermissionGroups, useIsSuperAdmin, useTeam } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import {
  File,
  AggregatedFile,
  AggregatedTeamMember,
  PermissionPaths,
  MiterFilterField,
} from "dashboard/miter";
import { useTeamMemberScopesHelpers } from "./useTeamMemberScopes";
import { useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * DOCUMENTS
 */
type DocumentParams =
  | File
  | File[]
  | AggregatedFile
  | AggregatedFile[]
  | CheckDocument
  | CheckDocument[]
  | FilesTableRow
  | FilesTableRow[]
  | TeamMemberDocumentRow
  | TeamMemberDocumentRow[];

type DocumentAction = "create" | "read" | "update" | "delete";
type DocumentActionWithSensitive =
  | DocumentAction
  | "create_sensitive"
  | "read_sensitive"
  | "update_sensitive"
  | "delete_sensitive";

type DocumentAbilities = {
  can: (action: DocumentAction, items: DocumentParams, parentType?: File["parent_type"]) => boolean;
  cannot: (action: DocumentAction, items: DocumentParams, parentType?: File["parent_type"]) => boolean;
  filter: (action: DocumentAction, type: "company" | "team") => MiterFilterField | undefined;
  teamPredicate: (action?: DocumentActionWithSensitive) => (tm: AggregatedTeamMember) => boolean;
};

export const useDocumentAbilities = (): DocumentAbilities => {
  const teamMembers = useTeam();
  const sessionPermissionGroups = useSessionPermissionGroups();
  const { can: can_ } = useMiterAbilities();
  const isSuperAdmin = useIsSuperAdmin();
  const { getTeamMemberIdsForPermission } = useTeamMemberScopesHelpers();

  const can = useCallback(
    (action: DocumentAction, items: DocumentParams, parentType?: File["parent_type"]) => {
      if (isSuperAdmin) return true;

      const documents = Array.isArray(items) ? items : [items];

      return documents.every((document) => {
        const isCheckDocument = "id" in document || "filed_on" in document;
        const isSensitive = "sensitive" in document ? document.sensitive : isCheckDocument;

        const teamMemberId = getTeamMemberId(document);

        const isTeamDocument =
          "parent_type" in document ? document.parent_type === "team_member" : parentType === "team_member";

        const hasAdvancedPermissions =
          "advanced_permissions" in document && document.advanced_permissions?.length;

        // Only run advanced permissions if there are advanced permissions for this document
        if (hasAdvancedPermissions && action !== "create") {
          // If at least one of the permission groups has the action available, return true
          return sessionPermissionGroups.some((pg) => {
            // Get the advanced permissions for this permission group
            const advancedPermission = document.advanced_permissions?.find((ap) => {
              return ap.permission_group_id === pg._id;
            });

            // If no permissions setup for this permission group, return false
            if (!advancedPermission) return false;

            // Check if the action is available for this permission group
            const actionAvailable = advancedPermission.can.includes(action);
            return actionAvailable;
          });
        }

        if (isSensitive) {
          if (isTeamDocument) {
            switch (action) {
              case "create":
                return can_("documents:team_member:create_sensitive", { teamMember: teamMemberId });
              case "read":
                return can_("documents:team_member:read_sensitive", { teamMember: teamMemberId });
              case "update":
                return can_("documents:team_member:update_sensitive", { teamMember: teamMemberId });
              case "delete":
                return can_("documents:team_member:delete_sensitive", { teamMember: teamMemberId });
              default:
                return false;
            }
          } else {
            switch (action) {
              case "create":
                return can_("documents:company:create_sensitive");
              case "read":
                return can_("documents:company:read_sensitive");
              case "update":
                return can_("documents:company:update_sensitive");
              case "delete":
                return can_("documents:company:delete_sensitive");
              default:
                return false;
            }
          }
        } else {
          if (isTeamDocument) {
            switch (action) {
              case "create":
                return can_("documents:team_member:create", { teamMember: teamMemberId });
              case "read":
                return can_("documents:team_member:read", { teamMember: teamMemberId });
              case "update":
                return can_("documents:team_member:update", { teamMember: teamMemberId });
              case "delete":
                return can_("documents:team_member:delete", { teamMember: teamMemberId });
              default:
                return false;
            }
          } else {
            switch (action) {
              case "create":
                return can_("documents:company:create");
              case "read":
                return can_("documents:company:read");
              case "update":
                return can_("documents:company:update");
              case "delete":
                return can_("documents:company:delete");
              default:
                return false;
            }
          }
        }
      });
    },
    [can_]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useCallback(
    (action: DocumentAction, type: "company" | "team"): MiterFilterField | undefined => {
      // If this is a company document filter, then we don't need to filter by team member because it's not a team document
      if (type === "company") return;

      // If the user is a super admin, then they can see all team members
      if (isSuperAdmin) return;

      const permission = `documents:team_member:${action}` as PermissionPaths;
      const teamMemberIds = getTeamMemberIdsForPermission(permission, "human_resources");

      // If the user can see all team members, then we don't need to filter by team member
      if (teamMemberIds.length === teamMembers.length) return;

      return {
        field: "team_member",
        comparisonType: "in" as const,
        value: teamMemberIds,
      };
    },
    [can_, isSuperAdmin, teamMembers]
  );

  const cannot = useCallback(
    (action: DocumentAction, items: DocumentParams, parentType?: File["parent_type"]) => {
      return !can(action, items, parentType);
    },
    [can]
  );

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<DocumentActionWithSensitive>("documents:team_member", true);

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, filter, teamPredicate]);
};

const getTeamMemberId = (
  item: File | AggregatedFile | CheckDocument | FilesTableRow | TeamMemberDocumentRow
): string | null | undefined => {
  if ("parent_type" in item && item.parent_type === "team_member") {
    return item.parent_id;
  }

  if ("team_member" in item) {
    return item.team_member;
  }
};
