// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck everything in this file needs to be fixed
import _ from "lodash";
import { DateTime } from "luxon";
import { ScheduleEvent } from "backend/services/schedule-service";
import { addressIsBlank, addressString, textColorSelector, truncateTeamMemberFullNameList } from "./utils";
import { useJobNameFormatter, useLookupTeam, useLookupEquipment } from "dashboard/hooks/atom-hooks";
import { useCallback } from "react";
import { ResourceType, ViewType } from "dashboard/pages/scheduling/SchedulingCalendar";

export type FullCalendarEvent = $TSFixMe;

export const useFullCalendarEventBuilder = (
  activeView?: ViewType,
  resourceType?: ResourceType
): FullCalendarEvent => {
  const jobNameFormatter = useJobNameFormatter();
  const lookupTeam = useLookupTeam();
  const lookupEquipment = useLookupEquipment();

  const buildEventFromTimeOffRequest = useCallback(
    (scheduleEvent: ScheduleEvent): FullCalendarEvent => {
      if (scheduleEvent.type !== "time_off_request") return;

      const showTeamMemberInTitle = activeView === "customListMonth" || activeView === "dayGridMonth";

      const title = showTeamMemberInTitle
        ? `Time Off - ${scheduleEvent.employee.first_name} ${scheduleEvent.employee.last_name} - ${scheduleEvent.hours} hours`
        : "Time Off - " + scheduleEvent.hours + " hours";

      return {
        _id: scheduleEvent._id.toString(),
        id: scheduleEvent._id.toString(),
        mocked_id: scheduleEvent.mocked_id?.toString() || scheduleEvent._id.toString(),
        title,
        start: DateTime.fromISO(scheduleEvent.date).toJSDate(),
        end: DateTime.fromISO(scheduleEvent.date).toJSDate(),
        allDay: true,
        backgroundColor: "#333",
        textColor: "#fff",
        resourceIds: [scheduleEvent.employee._id],
        etype: "time_off_request",
        assignee_count: 1,
      };
    },
    [activeView]
  );

  const buildAssignmentTitle = useCallback(
    (scheduleEvent: ScheduleEvent): string => {
      // Show the job in the title if the view is not customListMonth and the job name is not the same as the assignment title
      const showJobInTitle =
        activeView !== "customListMonth" &&
        scheduleEvent.job_object &&
        !scheduleEvent.title.includes(jobNameFormatter(scheduleEvent.job));
      const showEquipmentInTitle =
        activeView !== "customListMonth" && activeView !== "customResourceTimeGridDay";

      // Show the team members in the title if the resource type is job (i.e. the view is the job view)
      const showTeamMembersInTitle = resourceType === "job";

      const equipmentNames = scheduleEvent.equipment_ids?.map((id) => lookupEquipment(id)?.name).join(", ");

      let title = scheduleEvent.title;
      if (showTeamMembersInTitle) {
        title +=
          "\n\n" + truncateTeamMemberFullNameList(scheduleEvent.team_members.map((id) => lookupTeam(id)));
      } else if (showJobInTitle) {
        title += scheduleEvent.job ? "\n\n" + jobNameFormatter(scheduleEvent.job) : "";
      }

      if (equipmentNames && showEquipmentInTitle) {
        if (!showJobInTitle) title += "\n";
        title += "\n" + equipmentNames;
      }

      return title;
    },
    [activeView, resourceType]
  );

  const buildEventFromAssignment = useCallback(
    (scheduleEvent: ScheduleEvent, colorsMap: {}): FullCalendarEvent => {
      if (scheduleEvent.type !== "assignment") return;

      const resourceIds = _.cloneDeep(scheduleEvent.team_members);
      if (scheduleEvent.job) resourceIds.push(scheduleEvent.job);

      const title = buildAssignmentTitle(scheduleEvent);

      return {
        id: scheduleEvent._id.toString(),
        mocked_id: scheduleEvent.mocked_id?.toString() || scheduleEvent._id.toString(),
        title,
        start: DateTime.fromSeconds(scheduleEvent.starts_at).toJSDate(),
        end: DateTime.fromSeconds(scheduleEvent.ends_at).toJSDate(),
        backgroundColor: scheduleEvent.custom_color || colorsMap[scheduleEvent.job || 0] || colors[0],
        borderColor: scheduleEvent.custom_color || colorsMap[scheduleEvent.job || 0] || colors[0],
        textColor: scheduleEvent.custom_color ? textColorSelector(scheduleEvent.custom_color) : "#fff",
        resourceIds: resourceIds,
        etype: "assignment",
        assignee_count: scheduleEvent.team_members?.length || 0,
        job: scheduleEvent.job,
        job_object: scheduleEvent.job_object,
        job_name: jobNameFormatter(scheduleEvent.job),
        address: !addressIsBlank(scheduleEvent.address) ? addressString(scheduleEvent.address) : undefined,
        equipment_names: scheduleEvent.equipment_ids?.map((id) => lookupEquipment(id)?.name).join(", "),
      };
    },
    [activeView, buildAssignmentTitle]
  );

  const buildEvent = useCallback(
    (scheduleEvent: ScheduleEvent, colorsMap: {}): FullCalendarEvent => {
      if (scheduleEvent.type === "assignment") {
        return buildEventFromAssignment(scheduleEvent, colorsMap);
      } else {
        return buildEventFromTimeOffRequest(scheduleEvent);
      }
    },
    [buildEventFromAssignment, buildEventFromTimeOffRequest]
  );

  return buildEvent;
};

export const colors = [
  "#795548",
  "#e67c73",
  "#d50000",
  "#f4511e",
  "#ef6c00",
  "#f09300",
  "#009688",
  "#0b8043",
  "#7cb342",
  "#c0ca33",
  "#e4c441",
  "#f6bf26",
  "#33b679",
  "#039be5",
  "#4285f4",
  "#3f51b5",
  "#7986cb",
  "#b39ddb",
  "#616161",
  "#a79b8e",
  "#ad1457",
  "#d81b60",
  "#8e24aa",
  "#9e69af",
];
