import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ActionMenu, Badge, Button, Formblock, Loader, Toggler } from "ui";
import { benefitMap, CleanedCompanyBenefit, cleanCompanyBenefit, DeleteBenefitModal } from "./benefitsUtils";
import { BenefitModal } from "./BenefitModal";
import { EnrolledEmployees } from "./EnrolledEmployees";

import { Helmet } from "react-helmet";
import {
  useActiveCompanyId,
  useCostTypeFormatter,
  useIsMiterAdmin,
  useLedgerAccountLabeler,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { AuditLogHistory } from "dashboard/components/audit-logs/AuditLogHistory";

export const CompanyBenefitPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const { can } = useMiterAbilities();
  const accountLabeler = useLedgerAccountLabeler();
  const costTypeFormatter = useCostTypeFormatter();
  const isMiterAdmin = useIsMiterAdmin();

  const [benefitData, setBenefitData] = useState<CleanedCompanyBenefit>();
  const [fetching, setFetching] = useState(false);
  const [editing, setEditing] = useState(false);
  const [togglerState, setTogglerState] = useState("summary");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const getBenefitData = async () => {
    if (!activeCompanyId || !id) return;
    setFetching(true);
    try {
      const response = await MiterAPI.benefits.company.retrieve_for_company(activeCompanyId!);
      if (response.error) throw new Error(response.error);
      const benefit = response.find((b) => b._id.toString() === id);
      if (!benefit) throw new Error("Could not find benefit.");
      setBenefitData(cleanCompanyBenefit(benefit));
    } catch (e) {
      console.error(`Error retrieving company benefit plan`, e);
      Notifier.error("There was an error retrieving the benefit plan. We're looking into it.");
    }
    setFetching(false);
  };

  const togglerConfig = [
    { label: "Summary", path: "summary" },
    { label: "Enrolled employees", path: "enrolled_employees" },
    ...(isMiterAdmin ? [{ label: "Audit log (Mitosaur only)", path: "audit_log" }] : []),
  ];

  const handleToggle = (path) => setTogglerState(path);

  useEffect(() => {
    getBenefitData();
  }, [!!activeCompanyId, id]);

  const actionMenuLinks = [
    {
      action: () => setDeleteModal(true),
      label: "Delete benefit",
      shouldShow: () => can("benefits:delete"),
    },
  ];

  const handleDelete = async () => {
    if (!id) return;
    setDeleting(true);
    try {
      const response = await MiterAPI.benefits.company.delete(id);
      if (response.error) throw new Error(response.error);
      Notifier.success("Benefit plan successfully deleted.");
      navigate("/benefits/company-plans");
    } catch (e) {
      Notifier.error("There was an error deleting the benefit plan. We're looking into it!");
    }
    setDeleting(false);
  };

  const handleEditButtonClick = () => {
    if (benefitData?.guideline_config) {
      window.open("https://my.guideline.com/sponsor/dashboard", "_blank");
    } else {
      setEditing(true);
    }
  };

  const renderGuidelineBenefitSummary = () => {
    if (!benefitData) return <></>;

    return (
      <div>
        <div className="vertical-spacer-large"></div>
        <Formblock type="text" label="Description" defaultValue={benefitData.description!} />
        <Formblock type="text" label="Benefit type">
          <Badge
            color={benefitMap[benefitData.benefit]?.color}
            text={benefitMap[benefitData.benefit]?.label}
            table={true}
          />
        </Formblock>
        <Formblock
          type="text"
          label="Match configuration"
          defaultValue={benefitData.guideline_config?.match}
        />
        <Formblock
          type="text"
          label="Dollar contributions enabled"
          defaultValue={benefitData.guideline_config?.dollar_contributions_enabled ? "Yes" : "No"}
        />
        <Formblock
          type="text"
          label="Enabled for all payrolls"
          defaultValue={benefitData.guideline_config?.enabled_for_all_payrolls ? "Yes" : "No"}
        />

        <div className="vertical-spacer-large"></div>
        <Button className="button-2 no-margin" text="Manage in Guideline" onClick={handleEditButtonClick} />
      </div>
    );
  };

  const renderBenefitSummary = () => {
    if (!benefitData) return <></>;

    if (benefitData.guideline_config) {
      return renderGuidelineBenefitSummary();
    }

    const perMonth = !!benefitData.company_period_amount;

    return (
      <div>
        <div className="vertical-spacer-large"></div>
        <Formblock
          type="text"
          label="Employee contribution"
          defaultValue={benefitData.employee_contribution_label}
        />
        <Formblock
          type="text"
          label="Company contribution"
          defaultValue={benefitData.company_contribution_label}
        />
        <Formblock
          type="text"
          label="Start date"
          defaultValue={
            benefitData.effective_start
              ? DateTime.fromISO(benefitData.effective_start).toLocaleString(DateTime.DATE_MED)
              : "-"
          }
        />
        <Formblock
          type="text"
          label="End date"
          defaultValue={
            benefitData.effective_end
              ? DateTime.fromISO(benefitData.effective_end).toLocaleString(DateTime.DATE_MED)
              : "-"
          }
        />
        <Formblock
          type="text"
          label="Emp. liability account"
          labelInfo="Miter will credit this account for the employee contribution when creating payroll GL entries."
          defaultValue={accountLabeler(benefitData.emp_liab_account_id) || "Default"}
        />
        <Formblock
          type="text"
          label="Comp. liability account"
          labelInfo="Miter will credit this account for the company contribution when creating payroll GL entries."
          defaultValue={accountLabeler(benefitData.com_liab_account_id) || "Default"}
        />
        <Formblock
          type="text"
          label="Comp. expense account"
          labelInfo="Miter will debit this account for the company contribution when creating payroll GL entries."
          defaultValue={accountLabeler(benefitData.expense_account_id) || "Default"}
        />
        <Formblock
          type="text"
          label="Cost type"
          labelInfo="The cost type for the company contribution."
          defaultValue={costTypeFormatter(benefitData.cost_type_id) || "Default"}
        />
        {benefitData.group_term_life_amount && (
          <Formblock
            type="text"
            label="Taxable portion"
            labelInfo="Imputed earning amount created during regular payrolls"
            tooltipPlace="top"
            defaultValue={`$${benefitData.group_term_life_amount.toFixed(2)}${
              perMonth ? " (monthly)" : "/payroll"
            }`}
          />
        )}
        {benefitData.integrations?.employee_navigator?.deduction_code && (
          <Formblock
            type="text"
            label="Deduction code"
            labelInfo="The deduction code used to identify this benefit in Employee Navigator."
            defaultValue={benefitData.integrations.employee_navigator.deduction_code}
          />
        )}
        <div className="vertical-spacer-large"></div>
        {can("benefits:update") && (
          <Button className="button-2 no-margin" text="Edit plan" onClick={() => setEditing(true)} />
        )}
      </div>
    );
  };

  const renderEnrolledEmployees = () => {
    if (!id || !benefitData) return null;
    return <EnrolledEmployees benefitId={id} benefitData={benefitData} />;
  };

  const renderAuditLog = () => {
    if (!id) return;
    return (
      <div className="margin-top-25">
        <AuditLogHistory itemId={id} type="company_benefit" />
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Company Benefits Plans | Miter</title>
      </Helmet>
      {fetching && <Loader />}
      {editing && !benefitData?.guideline_config && (
        <BenefitModal
          onSuccess={getBenefitData}
          hide={() => setEditing(false)}
          benefitToUpdate={benefitData}
        />
      )}
      {deleteModal && (
        <DeleteBenefitModal
          onDelete={handleDelete}
          onCancel={() => setDeleteModal(false)}
          loading={deleting}
          companyBen
        />
      )}
      {!fetching && benefitData && (
        <div className="page-content">
          <div onClick={() => navigate("/benefits/company-plans")} className="reports-header-badge pointer">
            COMPANY BENEFIT PLANS
          </div>
          <div className="flex">
            <h1>{benefitData.description}</h1>
            <Badge
              color={benefitMap[benefitData.benefit]?.color}
              text={benefitMap[benefitData.benefit]?.label}
            />
            <div style={{ width: 5 }}></div>
            <ActionMenu links={actionMenuLinks} />
          </div>
          <Toggler config={togglerConfig} toggle={handleToggle} active={togglerState} />
          {togglerState === "summary" && renderBenefitSummary()}
          {togglerState === "enrolled_employees" && renderEnrolledEmployees()}
          {togglerState === "audit_log" && id && renderAuditLog()}
        </div>
      )}
    </div>
  );
};
