import React, { useEffect, useRef, useState, FC, useContext, useMemo } from "react";
import { reportList } from "../../reportList";
import AppContext from "../../../../contexts/app-context";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, TableV2 } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { Option } from "ui/form/Input";
import { DateTime } from "luxon";
import { BANNER_NOTIFICATION, SLACK_CHANNEL, getPayrollTypeString } from "dashboard/utils";
import {
  useActiveCompany,
  useBannerNotification,
  useLookupDepartment,
  useLookupJob,
  useLookupTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { toDollarFormat, roundToTwoDecimals } from "../../reportUtils";
import { ColumnConfig } from "ui/table-v2/Table";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import * as vals from "dashboard/utils/validators";
import Banner from "dashboard/components/shared/Banner";
import { EntryFor401KReport } from "backend/utils/reports/401kreport";

export const Create401KReport: FC = () => {
  // Hooks
  const { setReverifyUser, customFields } = React.useContext(AppContext);
  const activeCompany = useActiveCompany();
  const lookupDepartment = useLookupDepartment();
  const lookupJob = useLookupJob();
  const { can } = useMiterAbilities();
  const form = useForm();
  const waitlistForm = useForm();
  const user = useUser();
  const navigate = useNavigate();
  const isMounted = useRef(false);

  const lookupTeam = useLookupTeam();
  const teamAbilities = useTeamAbilities();

  const { payroll } = useContext(PayrollContext);

  // State
  const [data, setData] = useState<EntryFor401KReport[]>([]);
  const [payrollOptions, setPayrollOptions] = useState<Option<string>[]>([]);
  const [selectedPayroll, setSelectedPayroll] = useState<string | undefined>();
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [selectMultiplePayrolls, setSelectMultiplePayrolls] = useState(false);
  const [combineMultiplePayrolls, setCombineMultiplePayrolls] = useState(false);
  const [startDate, setStartDate] = useState<DateTime | undefined>();
  const [endDate, setEndDate] = useState<DateTime | undefined>();
  const [fetchingData, setFetchingData] = useState(false);

  const [showRawSSNs, setShowRawSSNs] = useState(false);
  const [showTeamMembersWithoutContributions, setShowTeamMembersWithoutContributions] = useState(false);
  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const isReverified = (user?.reverified_at || 0) > DateTime.now().minus({ days: 1 }).toSeconds();

  const [hideWaitlistBanner, setHideWaitlistBanner] = useBannerNotification(
    BANNER_NOTIFICATION._401K_INTEGRATION_WAITLIST
  );
  const [isSigningUpForWaitlist, setIsSigningUpForWaitlist] = useState(false);
  const [loadingWaitlist, setLoadingWaitlist] = useState(false);

  useEffect(() => {
    if (showReverificationModal) {
      setReverifyUser(showReverificationModal);
    }
  }, [showReverificationModal]);

  useEffect(() => {
    getData(
      selectedPayroll,
      startDate,
      endDate,
      showRawSSNs,
      showTeamMembersWithoutContributions,
      combineMultiplePayrolls
    );
  }, [selectedPayroll, startDate, endDate, showTeamMembersWithoutContributions, combineMultiplePayrolls]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (showRawSSNs && !isReverified) {
      setShowReverificationModal(true);
      return;
    }

    getData(
      selectedPayroll,
      startDate,
      endDate,
      showRawSSNs,
      showTeamMembersWithoutContributions,
      combineMultiplePayrolls
    );
  }, [showRawSSNs, isReverified]);

  const columnDefsFor401kReport: ColumnConfig<EntryFor401KReport>[] = useMemo(() => {
    const customFieldColumns: ColumnConfig<EntryFor401KReport>[] = customFields
      .filter((cf) => cf.parent_type === "team_member")
      .map((cf) => {
        return {
          field: `employeeCustomFields.${cf._id.toString()}`,
          headerName: cf.name,
          dataType: "string",
        };
      });

    return [
      {
        field: "planNumber",
        headerName: "Plan Number",
        dataType: "string",
      },
      {
        field: "employeeSsn",
        headerName: "Employee SSN (last 4)",
        dataType: "string",
      },
      {
        field: "employeeId",
        headerName: "Employee ID",
        initialHide: true,
        dataType: "string",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        dataType: "string",
      },
      {
        field: "firstName",
        headerName: "First Name",
        dataType: "string",
      },
      {
        field: "middleName",
        headerName: "Middle Name",
        dataType: "string",
      },
      {
        field: "suffix",
        headerName: "Suffix",
        dataType: "string",
      },
      {
        field: "dob",
        headerName: "Birth Date",
        dataType: "date",
        dateFormat: "MM/dd/yyyy",
      },
      {
        field: "gender",
        headerName: "Gender",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        field: "maritalStatus",
        headerName: "Marital Status",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        field: "addressLine1",
        headerName: "Address Line 1",
        dataType: "string",
      },
      {
        field: "addressLine2",
        headerName: "Address Line 2",
        dataType: "string",
      },
      {
        field: "city",
        headerName: "City",
        dataType: "string",
      },
      {
        field: "state",
        headerName: "State",
        dataType: "string",
      },
      {
        field: "zipCode",
        headerName: "Zip Code",
        dataType: "string",
      },
      {
        field: "homePhone",
        headerName: "Home Phone Number",
        dataType: "string",
      },
      {
        field: "workPhone",
        headerName: "Work Phone Number",
        dataType: "string",
      },
      {
        field: "workEmail",
        headerName: "Work E-Mail Address",
        dataType: "string",
      },
      {
        field: "hireDate",
        headerName: "Hire Date",
        dataType: "date",
        dateFormat: "MM/dd/yyyy",
      },
      {
        field: "terminationDate",
        headerName: "Termination Date",
        dataType: "date",
        dateFormat: "MM/dd/yyyy",
      },
      {
        field: "rehireDate",
        headerName: "Rehire Date",
        dataType: "date",
        dateFormat: "MM/dd/yyyy",
      },
      {
        field: "department",
        headerName: "Employee department",
        valueGetter: (params) => lookupDepartment(params.data?.department)?.name,
        initialHide: true,
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        field: "defaultJobId",
        headerName: "Default job",
        valueGetter: (params) => lookupJob(params.data?.defaultJobId)?.name,
        initialHide: true,
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        field: "payrollDate",
        headerName: "Payroll Date",
        dataType: "date",
        dateFormat: "MM/dd/yyyy",
      },
      {
        field: "preTaxContributions",
        headerName: "Employee pre-tax contributions",
        valueFormatter: toDollarFormat,
        useValueFormatterForExport: false,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "postTaxContributions",
        headerName: "Employee post-tax contributions",
        valueFormatter: toDollarFormat,
        useValueFormatterForExport: false,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "employerContributions",
        headerName: "Employer contributions",
        valueFormatter: toDollarFormat,
        useValueFormatterForExport: false,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "loanRepayments",
        headerName: "Loans",
        valueFormatter: toDollarFormat,
        useValueFormatterForExport: false,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "paymentHours",
        headerName: "Payment Hours",
        valueFormatter: roundToTwoDecimals,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "ytdHours",
        headerName: "YTD Hours",
        valueFormatter: roundToTwoDecimals,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "paymentEarnings",
        headerName: "Payment gross pay",
        valueFormatter: toDollarFormat,
        useValueFormatterForExport: false,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "ytdGrossPay",
        headerName: "YTD Total Compensation",
        valueFormatter: toDollarFormat,
        useValueFormatterForExport: false,
        sumRow: true,
        dataType: "number",
      },
      {
        field: "payRate",
        headerName: "Pay rate",
        valueFormatter: toDollarFormat,
        useValueFormatterForExport: false,
        dataType: "number",
      },
      {
        field: "payRateType",
        headerName: "Pay rate frequency",
      },
      {
        field: "salaryAmountQualifier",
        headerName: "Salary amount qualifier",
        hide: true,
      },
      ...customFieldColumns,
    ];
  }, [lookupDepartment, customFields]);

  const getPayrolls = async () => {
    if (!activeCompany) return;
    setFetchingOptions(true);
    try {
      const response = await MiterAPI.payrolls.get_table({ company: activeCompany._id });
      if (response.error) throw new Error(response.error);
      const options = response
        .filter((p) => p.status !== "draft")
        .map((payroll) => {
          return {
            label: DateTime.fromISO(payroll.payday).toFormat("DD") + ` (${getPayrollTypeString(payroll)})`,
            value: payroll._id.toString(),
          };
        });
      setPayrollOptions(options);
    } catch (e: $TSFixMe) {
      console.log("Error fetching payrolls for 401K report.");
      Notifier.error(e.message);
    }
    setFetchingOptions(false);
  };

  const handleSignUpForWaitlist = async (data: { provider_name: string }) => {
    setLoadingWaitlist(true);
    try {
      await MiterAPI.slack.send_message(
        `User ${user?.email || user?._id} from ${
          activeCompany?.check_company.trade_name
        } signed up for the 401(k) integration waitlist for provider ${data.provider_name}.`,
        SLACK_CHANNEL.BENEFITS_FEEDBACK
      );
      setIsSigningUpForWaitlist(false);
      setHideWaitlistBanner("hide");
      Notifier.success("You've been added to the 401(k) integration waitlist.");
    } catch (e: $TSFixMe) {
      console.log("Error signing up for 401k waitlist", e);
      Notifier.error(e.message);
    }
    setLoadingWaitlist(false);
  };

  // Need to clear out all previous parameters when switching between single and multiple payrolls
  useEffect(() => {
    setSelectedPayroll(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
  }, [selectMultiplePayrolls]);

  useEffect(() => {
    getPayrolls();
  }, [activeCompany]);

  const getData = async (
    selectedPayroll,
    startDate,
    endDate,
    showRawSSNs,
    showTeamMembersWithoutContributions,
    combineMultiplePayrolls
  ) => {
    if (!payroll && !selectedPayroll && (!startDate || !endDate)) return;
    if (!activeCompany) return;
    setFetchingData(true);
    try {
      const cleanedData = {
        payrollId: selectedPayroll || payroll?._id.toString(),
        start: startDate?.toISODate(),
        end: endDate?.toISODate(),
        combineMultiplePayrolls,
        companyId: activeCompany._id,
        showRawSSNs,
        draftPayroll: payroll?.check_payroll.status === "draft" ? payroll : undefined,
        showTeamMembersWithoutContributions,
      };
      const payload = {
        type: "401k",
        params: cleanedData,
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const filteredData = response.filter((entry: EntryFor401KReport) => {
        return teamAbilities.can("read_sensitive", lookupTeam(entry.employee));
      });

      setData(filteredData);
    } catch (e: $TSFixMe) {
      console.log("Error fetching 401k report", e);
      Notifier.error(e.message);
    }
    setFetchingData(false);
  };

  const minAllowableDate = endDate ? endDate.startOf("year") : undefined;
  const maxAllowableDate = startDate ? DateTime.min(startDate.endOf("year"), DateTime.now()) : DateTime.now();
  const reportObject = reportList.find((report) => report.slug === "401k_report");

  const fileName = "Miter 401K report";

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      {!payroll && (
        <Helmet>
          <title>401K Report | Miter</title>
        </Helmet>
      )}
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <h1 style={{ marginTop: 0 }}>401K contributions report</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      {!hideWaitlistBanner && (
        <>
          <Banner
            type="info"
            onClick={() => setIsSigningUpForWaitlist(true)}
            content="Interested in having 401(k) contributions automatically synced? Sign up for the integration waitlist by clicking this banner."
          />
          <div className="vertical-spacer-small"></div>
        </>
      )}
      {isSigningUpForWaitlist && (
        <ActionModal
          headerText="Sign up for waitlist"
          showSubmit={true}
          submitText="Submit"
          onSubmit={waitlistForm.handleSubmit(handleSignUpForWaitlist)}
          onHide={() => setIsSigningUpForWaitlist(false)}
          loading={loadingWaitlist}
        >
          <Formblock
            label={`401(k) provider name`}
            type="text"
            form={waitlistForm}
            name="provider_name"
            editing={true}
            style={{ marginTop: 20 }}
            className="modal"
          />
        </ActionModal>
      )}
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 400 }}>
        {!payroll && selectMultiplePayrolls && (
          <>
            <Formblock
              label="Starting payday"
              form={form}
              defaultValue={startDate}
              type="datetime"
              control={form.control}
              dateOnly={true}
              name="start_date"
              min={minAllowableDate}
              max={endDate}
              editing={true}
              onChange={setStartDate}
              rules={vals.required}
            />
            <Formblock
              label="Ending payday"
              form={form}
              defaultValue={endDate}
              type="datetime"
              control={form.control}
              dateOnly={true}
              min={startDate}
              name="end_date"
              max={maxAllowableDate}
              editing={true}
              onChange={setEndDate}
              rules={vals.required}
            />
          </>
        )}
        {!payroll && !selectMultiplePayrolls && !fetchingOptions && (
          <Formblock
            label="Payroll payday"
            register={form.register}
            type="select"
            control={form.control}
            options={payrollOptions}
            name="payroll"
            errors={form.errors}
            editing={true}
            onChange={(o) => setSelectedPayroll(o?.value)}
            rules={vals.required}
          />
        )}
      </div>
      <div className="vertical-spacer-small"></div>
      {!payroll && (
        <Formblock
          label="Include multiple payrolls"
          control={form.control}
          type="checkbox"
          onChange={(e) => setSelectMultiplePayrolls(e.target.checked)}
          name="select_multiple_payrolls"
          errors={form.errors}
          editing={true}
          style={{ alignItems: "center" }}
          labelStyle={{ width: 600, flexDirection: "unset" }}
        />
      )}
      {selectMultiplePayrolls && (
        <Formblock
          label="Combine payroll contributions"
          control={form.control}
          type="checkbox"
          onChange={(e) => setCombineMultiplePayrolls(e.target.checked)}
          name="combine_multiple_payrolls"
          labelInfo="Combine all contributions for a team member in the selected period into a single row."
          errors={form.errors}
          editing={true}
          style={{ alignItems: "center" }}
          labelStyle={{ width: 600, flexDirection: "unset" }}
        />
      )}
      {can("team:read_sensitive") && (
        <Formblock
          label="Include raw SSNs"
          labelInfo="Show full SSNs on the report."
          control={form.control}
          type="checkbox"
          onChange={(e) => setShowRawSSNs(e.target.checked)}
          name="show_full_ssns"
          errors={form.errors}
          editing={true}
          style={{ alignItems: "center" }}
          labelStyle={{ width: 600, flexDirection: "unset" }}
        />
      )}
      {can("team:read_sensitive") && (
        <Formblock
          label="Include team members without contributions"
          labelInfo="Show team members who don't have any contributions in the payroll."
          control={form.control}
          type="checkbox"
          onChange={(e) => setShowTeamMembersWithoutContributions(e.target.checked)}
          name="show_team_members_without_contributions"
          errors={form.errors}
          editing={true}
          style={{ alignItems: "center" }}
          labelStyle={{ width: 600, flexDirection: "unset" }}
        />
      )}
      <TableV2
        id="401k"
        resource="rows"
        data={data}
        showTotals={true}
        paginationPageSize={50}
        columns={columnDefsFor401kReport}
        exportFileName={fileName}
        isLoading={fetchingData || fetchingOptions}
        showReportViews={true}
        showExpandAll={true}
      />
    </div>
  );
};
