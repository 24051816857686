import { AggregatedFile, File as MiterFile } from "dashboard/miter";
import { Notifier, truncateFilename } from "dashboard/utils";
import { Files, FileText, XCircle } from "phosphor-react";
import React, { FC, ReactElement, useEffect, useRef, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Loader } from "../loader";
import { DownloadSimple } from "phosphor-react";
import "./FilePicker.css";
import { ActionModal, ConfirmModal } from "../modal";
import { FileViewer } from "../file-viewer";
import { markFilesAsViewed } from "miter-utils";
import { useFileDownload } from "dashboard/hooks/useFileDownload";
import styles from "./FilePicker.module.css";

export type FilePickerFile = {
  blob?: File;
  data?: MiterFile | AggregatedFile;
  url?: string;
  deleted?: boolean;
};

type Props = {
  type: "dropzone" | "button" | "dropzone-button";
  label?: ReactElement | string;
  className?: string;
  sectionClassName?: string;
  multiple?: boolean;
  maxFilesAllowed?: number;
  files: FilePickerFile[] | null;
  maxFileSize?: number;
  acceptedFileTypes?: string[];
  openPicker?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
  compact?: boolean;
  setOpenPicker?: (open: boolean) => void;
  onChange: (files: FilePickerFile[] | null) => void;
  customRenderSelectedFiles?: (files: FilePickerFile[] | null) => ReactElement;
  showButton?: boolean;
  maxLabelLength?: number;
  hideUploadIcon?: boolean;
  iconSize?: string;
  confirmBeforeRemovingFile?: boolean;
  showPreviewModal?: boolean;
  noMargin?: boolean;
};

const FilePicker: FC<Props> = ({
  type,
  label,
  className,
  sectionClassName,
  multiple,
  maxFilesAllowed,
  onChange,
  files,
  maxFileSize,
  acceptedFileTypes,
  customRenderSelectedFiles,
  openPicker,
  setOpenPicker,
  hidden,
  readOnly,
  compact,
  showButton,
  maxLabelLength,
  hideUploadIcon,
  iconSize,
  confirmBeforeRemovingFile,
  showPreviewModal,
  noMargin,
}) => {
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    noClick: type !== "dropzone",
    noKeyboard: true,
    maxSize: maxFileSize || 25 * 1024 * 1024,
    accept: acceptedFileTypes,
    maxFiles: maxFilesAllowed,
    onDropRejected(fileRejections) {
      const hasFileTooLarge = fileRejections.find((file) =>
        file.errors.find((error) => error.code === "file-too-large")
      );

      if (hasFileTooLarge) {
        if (fileRejections.length === 1) {
          Notifier.error(
            `File is too large. Max size is ${maxFileSize ? `${maxFileSize / 1024 / 1024}MB` : "25MB"}.`
          );
        } else {
          Notifier.error(
            `One or more files are too large. Max size is ${
              maxFileSize ? `${maxFileSize / 1024 / 1024}MB` : "25MB"
            }.`
          );
        }

        return;
      }

      const hasInvalidFileType = fileRejections.find((file) =>
        file.errors.find((error) => error.code === "file-invalid-type")
      );

      if (hasInvalidFileType) {
        if (fileRejections.length === 1) {
          Notifier.error("File type is not allowed.");
        } else {
          Notifier.error("One or more files are not allowed.");
        }

        return;
      }

      const hasTooManyFiles = fileRejections.find((file) =>
        file.errors.find((error) => error.code === "too-many-files")
      );

      if (hasTooManyFiles) {
        Notifier.error(`You can only upload ${maxFilesAllowed} ${maxFilesAllowed === 1 ? "file" : "files"}.`);
        return;
      }
    },
  });

  const isMounted = useRef<boolean>(false);

  const [localFiles, setLocalFiles] = useState<FilePickerFile[]>([]);

  // Has maxed out files
  const maxFileCountReached = (files || []).filter((f) => !f.deleted).length === maxFilesAllowed;

  // We need this use effect to pass back the files to react form hook
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    // Add new dropzone files to the local files and send it to the onchange listener
    if (multiple) {
      // Attach the new files to the original file list
      const updatedFiles = localFiles.concat(acceptedFiles.map((file) => ({ blob: file })));

      // Make sure that the non deleted files don't go over the limit
      const newNonDeletedFiles = maxFilesAllowed
        ? updatedFiles.filter((file) => !file.deleted).slice(0, maxFilesAllowed)
        : updatedFiles;

      // Save the deleted files separately
      const deletedFiles = updatedFiles.filter((file) => file.deleted);

      // Combine the files into one
      const totalFiles = newNonDeletedFiles.concat(deletedFiles);

      onChange(totalFiles);
    } else if (acceptedFiles.length > 0) {
      const newAcceptedFiles = [acceptedFiles[0]];

      onChange(
        newAcceptedFiles
          .map((file) => ({ blob: file } as FilePickerFile))
          .concat(localFiles.filter((lf) => lf.deleted))
      );
    }
  }, [acceptedFiles]);

  // We need this use effect to keep the local file state in sync with the react hook form file state
  useEffect(() => {
    // Don't update the local files if the files are the same
    if (JSON.stringify(localFiles) === JSON.stringify(files)) return;

    setLocalFiles(files || []);
  }, [files]);

  useEffect(() => {
    if (openPicker && setOpenPicker) {
      open();
      setOpenPicker(false);
    }
  }, [openPicker]);

  //
  const handleRemoveFile = (file: FilePickerFile) => {
    // If the file is a file that already exists on the server, mark it as deleted
    if (file.data) {
      const updatedFiles = localFiles.map((f) => {
        if (f.data && f.data._id === file!.data!._id) {
          return { ...f, deleted: true };
        }
        return f;
      });

      onChange(updatedFiles);
    } else {
      // Otherwise, just remove it from the local files
      const updatedFiles = localFiles.filter((f) => f.blob !== file.blob);
      onChange(updatedFiles);
    }
  };

  // Render the list of selected files
  const renderFiles = (readOnly: boolean | undefined) => {
    return (
      <FilePickerFiles
        files={localFiles}
        handleRemove={handleRemoveFile}
        readOnly={readOnly}
        maxLabelLength={maxLabelLength}
        hideMargin={maxFileCountReached || noMargin}
        confirmBeforeRemovingFile={confirmBeforeRemovingFile}
        showPreviewModal={showPreviewModal}
      />
    );
  };

  const numFilesNotDeleted = files?.filter((file) => !file.deleted)?.length || 0;

  const showFilePickerButton = useMemo(() => {
    return (
      (showButton ||
        (multiple && ((maxFilesAllowed && numFilesNotDeleted < maxFilesAllowed) || !maxFilesAllowed)) ||
        numFilesNotDeleted === 0) &&
      type !== "dropzone"
    );
  }, [showButton, multiple, maxFilesAllowed, numFilesNotDeleted, type]);

  const showDropZone = !maxFilesAllowed || numFilesNotDeleted < maxFilesAllowed;

  return (
    <>
      <section
        className={"container " + (sectionClassName ? sectionClassName : "")}
        style={hidden ? { display: "none" } : {}}
      >
        {!readOnly && !maxFileCountReached && (
          <div
            {...getRootProps({
              className:
                type === "dropzone" || type === "dropzone-button"
                  ? "dropzone " + (className ? className : "")
                  : "file-upload-btn-container " + (className ? className : ""),
            })}
          >
            <input {...getInputProps()} multiple={multiple} />
            {!compact && (type === "dropzone" || type === "dropzone-button") && showDropZone && (
              <div style={{ textAlign: "center" }}>
                {!hideUploadIcon && (
                  <Files
                    fontSize={iconSize || "3rem"}
                    color="#4D54B6"
                    weight="thin"
                    style={{ marginTop: 10 }}
                  />
                )}
                <p>{label || "Drag 'n' drop some files here, or click to select files"}</p>
              </div>
            )}
            {compact && (type === "dropzone" || type === "dropzone-button") && showDropZone && (
              <div style={{ textAlign: "center", display: "flex" }}>
                {!hideUploadIcon && (
                  <Files
                    fontSize={iconSize || "1.5rem"}
                    color="#4D54B6"
                    weight="thin"
                    style={{ marginTop: -3, marginRight: 15 }}
                  />
                )}
                <p style={{ margin: 0 }}>
                  {label || "Drag 'n' drop some files here, or click to select files"}
                </p>
              </div>
            )}

            {showFilePickerButton && (
              <button
                type="button"
                onClick={open}
                className={`button-2 ${styles["file-picker-button"]} ${noMargin ? styles["no-margin"] : ""}`}
              >
                Open file explorer
              </button>
            )}
          </div>
        )}

        {customRenderSelectedFiles ? customRenderSelectedFiles(files) : renderFiles(readOnly)}
      </section>
    </>
  );
};

export default FilePicker;

type FilePickerPreviewModalProps = {
  allFiles: FilePickerFile[];
  selectedFile: FilePickerFile;
  maxLabelLength: number | undefined;
  onHide: () => void;
};
export const FilePickerPreviewModal: FC<FilePickerPreviewModalProps> = ({
  allFiles,
  selectedFile,
  maxLabelLength,
  onHide,
}) => {
  const fileName = truncateFilename(
    (selectedFile?.data?.label || selectedFile?.data?.originalname || selectedFile?.blob?.name)!,
    maxLabelLength || 50
  );
  return (
    <>
      <ActionModal
        headerText={fileName}
        onHide={onHide}
        wrapperStyle={{
          width: "auto",
          minWidth: 400,
          maxHeight: 600,
        }}
        hideFooter={true}
        actionsType={"button"}
      >
        <FileViewer files={allFiles} selectedFile={selectedFile} mode="read" />
      </ActionModal>
    </>
  );
};

type FilePickerFilesProps = {
  files: FilePickerFile[];
  handleRemove?: (file: FilePickerFile) => void;
  readOnly?: boolean;
  className?: string;
  showDownloadIcon?: boolean;
  maxLabelLength?: number;
  hideMargin?: boolean;
  confirmBeforeRemovingFile?: boolean;
  showPreviewModal?: boolean;
};

export const FilePickerFiles: FC<FilePickerFilesProps> = ({
  files,
  handleRemove,
  readOnly,
  className,
  showDownloadIcon,
  maxLabelLength,
  hideMargin,
  confirmBeforeRemovingFile,
  showPreviewModal,
}) => {
  const [loading, setLoading] = useState<FilePickerFile | null | undefined>();
  const [fileToRemove, setFileToRemove] = useState<FilePickerFile | null>(null);
  const [fileToPreview, setFileToPreview] = useState<FilePickerFile | null>(null);
  const handleFileClick = useFileDownload();
  // Redirect the user to the file URL
  const handleLinkClick = async (file: FilePickerFile) => {
    setLoading(file);
    if (showPreviewModal) {
      setFileToPreview(file);
      setLoading(null);
      return;
    }

    if (file.data?._id) {
      markFilesAsViewed([file.data._id]);
    }

    handleFileClick({ file });

    setLoading(null);
  };

  const handleRemoveFileConfirmation = (file: FilePickerFile) => {
    if (handleRemove) {
      // show confirmation modal first
      if (confirmBeforeRemovingFile) {
        setFileToRemove(file);
      } else {
        handleRemove(file);
      }
    }
  };

  if (!files || files?.length === 0) return <></>;

  const fileElements = files
    .filter((file) => !file?.deleted)
    .map((file) => {
      const key = file?.data?._id || file?.blob?.name + " " + file?.blob?.lastModified + Math.random();
      const fileName = truncateFilename(
        (file?.data?.label || file?.data?.originalname || file?.blob?.name || "File download")!,
        maxLabelLength || 50
      );

      return (
        <div className={"file-picker-selected-file"} key={key}>
          {loading === file ? (
            <Loader className="button link" />
          ) : (
            <FileText
              fontSize={"1.3rem"}
              color="#4D54B6"
              weight="duotone"
              style={{ marginBottom: -2, marginRight: 7, minWidth: 20 }}
            />
          )}
          <p className={"file-picker-selected-file-name"}>
            <a
              onClick={() => handleLinkClick(file)}
              className="black-link"
              target={"_blank"}
              rel="noreferrer"
            >
              {fileName}
            </a>
          </p>
          {showDownloadIcon && (
            <DownloadSimple
              weight="duotone"
              color="#4D54B6"
              className={"file-picker-download-icon"}
              onClick={() => handleLinkClick(file)}
              target={"_blank"}
            />
          )}
          {handleRemove && !readOnly && (
            <button
              className={"file-picker-remove-selected-file-btn"}
              onClick={() => handleRemoveFileConfirmation(file)}
            >
              <XCircle weight="duotone" color="#4D54B6" />
            </button>
          )}
        </div>
      );
    });

  return (
    <>
      {confirmBeforeRemovingFile && handleRemove && fileToRemove && (
        <ConfirmModal
          title="Delete file?"
          body="Are you sure you want to delete this attachment?"
          onYes={() => {
            handleRemove(fileToRemove);
            setFileToRemove(null);
          }}
          onNo={() => setFileToRemove(null)}
        />
      )}
      {fileToPreview && (
        <FilePickerPreviewModal
          allFiles={files}
          selectedFile={fileToPreview}
          maxLabelLength={maxLabelLength}
          onHide={() => setFileToPreview(null)}
        />
      )}
      <div
        className={"file-picker-selected-files " + (className ?? "")}
        style={hideMargin ? { marginTop: 0 } : {}}
      >
        {fileElements}
      </div>
    </>
  );
};
