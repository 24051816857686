import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Formblock } from "ui";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { AggregatedJob, Job, MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import "../../reports.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Option } from "ui/form/Input";
import { jobCostColumnDefs } from "./jobCostColumnDefs";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { useActiveCompanyId, useJobOptions, useLookupJob, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { CsvExportParams, ExcelExportParams, GridApi } from "ag-grid-community";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { reportList } from "../../reportList";
import { JobCostEntry } from "backend/utils/reports/jobCosting";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

import * as vals from "dashboard/utils/validators";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { JobInput } from "dashboard/components/shared/JobInput";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";

const reportObject = reportList.find((report) => report.slug === "job_costing");

export const JobCostReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { can } = useMiterAbilities();

  const form = useForm();
  const navigate = useNavigate();
  const { payroll } = useContext(PayrollContext);

  const teamAbilities = useTeamAbilities();
  const jobAbilities = useJobAbilities();
  const lookupJob = useLookupJob();
  const lookupTeam = useLookupTeam();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns<JobCostEntry>();

  // State
  const [startDate, setStartDate] = useState(DateTime.now().minus({ months: 1 }));
  const [endDate, setEndDate] = useState(DateTime.now());
  const [selectedJob, setSelectedJob] = useState<Option<string> | null>(null);
  const [allData, setAllData] = useState<JobCostEntry[]>([]);
  const jobsInData = useMemo(() => new Set(allData?.map((d) => d.job_id) || []), [allData]);

  const jobsFilter = useCallback(
    (j: AggregatedJob | Job) => jobsInData.has(j._id) && jobAbilities.can("read", j),
    [jobsInData, jobAbilities.can]
  );

  const jobOptions = useJobOptions({
    predicate: jobsFilter,
    overrideBasePredicate: true,
    defaultValue: selectedJob?.value,
  });

  const [gridApi, setGridApi] = useState<GridApi<JobCostEntry>>();

  const data = useMemo(() => {
    if (!selectedJob?.value) return allData;
    return allData?.filter((d) => d.job_id === selectedJob.value);
  }, [allData, selectedJob?.value]);

  const columns = useMemo(() => {
    if (can("team:read_sensitive")) return jobCostColumnDefs.concat(jobHierarchyTableColumns);

    return jobCostColumnDefs
      .concat(jobHierarchyTableColumns)
      .filter((c) => !c.field || !["tm_name", "tm_department"].includes(c.field));
  }, [can, jobHierarchyTableColumns]);

  const getData = async () => {
    if (!gridApi || !activeCompanyId) return;
    gridApi.showLoadingOverlay();
    try {
      // Put start/end in local browser timezone and send to backend as such
      let start: string, end: string;
      if (payroll) {
        start = DateTime.fromISO(payroll.check_payroll.period_start).toISODate();
        end = DateTime.fromISO(payroll.check_payroll.period_end).toISODate();
      } else {
        start = startDate.toISODate();
        end = endDate.toISODate();
      }
      const payload = {
        type: "job_cost",
        params: {
          start,
          end,
          company: activeCompanyId,
          payroll: payroll?._id,
        },
        format: "json",
        company: activeCompanyId,
      };

      const miterEarnings = await MiterAPI.reports.create(payload);
      if (miterEarnings.error) throw new Error(miterEarnings.error);

      const cleanData: JobCostEntry[] = [];
      for (const earning of miterEarnings as JobCostEntry[]) {
        const job = lookupJob(earning.job_id);
        const teamMember = lookupTeam(earning.tm_id);

        // Make sure we have the ability to read the job
        if (job && jobAbilities.cannot("read", job)) continue;

        // Make sure we have the ability to read the team
        if (teamMember && teamAbilities.cannot("read_sensitive", teamMember)) continue;

        cleanData.push({
          ...earning,
          activity_name: earning.activity_name || "N/A",
          tm_name: earning.tm_name || "N/A",
          job_name: earning.job_name || "N/A",
        });
      }

      setAllData(cleanData);
    } catch (e) {
      console.error("Job cost report error", e);
      Notifier.error("There was an error retrieving data.");
    }
    gridApi.hideOverlay();
  };

  useEffect(() => {
    getData();
  }, [!!gridApi, activeCompanyId, startDate.toISODate(), endDate.toISODate()]);

  let fileName = "Miter Job Cost Report ";
  if (payroll) {
    fileName += payroll.check_payroll.payday;
  } else {
    fileName += startDate.toISODate() + " - " + endDate.toISODate();
  }

  const exportParams: CsvExportParams & ExcelExportParams = {
    fileName: `${fileName}.csv`,
    allColumns: true,
    skipRowGroups: true,
  };

  const exportCSV = () => {
    gridApi?.exportDataAsCsv(exportParams);
  };

  const maxAllowableDate = DateTime.now().plus({ days: 10 });

  const HeaderTag = payroll ? "h2" : "h1";

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      {!payroll && (
        <Helmet>
          <title>Job Cost Report | Miter</title>
        </Helmet>
      )}
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <HeaderTag style={{ marginTop: 0, marginBottom: payroll ? 5 : undefined }}>
          {reportObject!.label}
        </HeaderTag>
      </div>
      <div className="report-page-description">
        {reportObject!.description}
        {!payroll && "*"}
      </div>
      <div style={{ maxWidth: 400 }}>
        {!payroll && (
          <>
            <Formblock
              label="Starting payday"
              form={form}
              defaultValue={startDate}
              type="datetime"
              control={form.control}
              dateOnly={true}
              name="start_date"
              max={endDate}
              editing={true}
              onChange={setStartDate}
              rules={vals.required}
            />
            <Formblock
              label="Ending payday"
              form={form}
              defaultValue={endDate}
              type="datetime"
              control={form.control}
              dateOnly={true}
              min={startDate}
              name="end_date"
              max={maxAllowableDate}
              editing={true}
              onChange={setEndDate}
              rules={vals.required}
            />
          </>
        )}
        <JobInput
          type="select"
          name="job"
          form={form}
          onChange={setSelectedJob}
          label="Job"
          editing={true}
          options={jobOptions}
          isClearable
          noOptionsMessage={"No earnings have associated jobs in this time frame."}
        />
      </div>
      <div className="flex" style={{ marginTop: 10, marginBottom: -33 }}>
        <Button text="Download CSV" onClick={exportCSV} className="button-2 no-margin" />
      </div>
      <AgGridTable
        reportId="job-cost"
        data={data}
        columnDefs={columns}
        setGridApi={setGridApi}
        gridOptions={{
          defaultCsvExportParams: exportParams,
          defaultExcelExportParams: exportParams,
          groupIncludeTotalFooter: true,
        }}
      />
      {!payroll && (
        <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
          * Includes hours and labor costs on paid, processing, and pending payrolls.
        </div>
      )}
      <div className="vertical-spacer-large"></div>
    </div>
  );
};
