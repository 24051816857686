import { FlatfileResults } from "@flatfile/react";
import { useActiveCompanyId, useActiveTeam, useLedgerAccountOptions } from "dashboard/hooks/atom-hooks";
import React, { useMemo } from "react";
import { Notifier } from "ui";
import { keyBy } from "lodash";
import { MiterAPI, CreatePostTaxDeductionParams } from "dashboard/miter";
import { ImportField, Importer } from "../importer/Importer";
import { CheckPostTaxDeduction } from "backend/utils/check/check-types";
import { buildFlatfileMessage, normalizeDate } from "dashboard/utils/flatfile";
import { isEmptyString, removeEmptyStringProps, stateOptions } from "miter-utils";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";

type PrelimPostTaxDeductionImportRow = {
  employeeId: string;
  type: CheckPostTaxDeduction["type"];
  description: string;
  effectiveStart: string;
  effectiveEnd?: string;
  managed?: "true" | "false";
  amount: string;

  // Miscellanous specific fields
  percent?: string;
  totalAmount?: string;

  // Ledger account
  accountId?: string;

  // Child support specific fields
  externalId?: string;
  agency?: string;
  fipsCode?: string;
  issueDate?: string;
  maxPercent?: string;

  // Integration specific fields
  deductionCode?: string;
};

type Props = {
  onFinish: () => void;
};

export const PTDImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeTeam = useActiveTeam();
  const lookupTeamFriendlyID = useMemo(() => keyBy(activeTeam, "friendly_id"), [activeTeam]);
  const { enIntegration } = useEmployeeNavigator();
  const ledgerAccounts = useLedgerAccountOptions();

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/
  const buildPostTaxDeductionParams = (
    row: PrelimPostTaxDeductionImportRow
  ): CreatePostTaxDeductionParams => {
    if (!activeCompanyId) throw new Error("No active role");

    const {
      employeeId,
      type,
      description,
      effectiveStart,
      effectiveEnd,
      managed,
      amount,
      percent,
      totalAmount,
      externalId,
      agency,
      fipsCode,
      issueDate,
      maxPercent,
      deductionCode,
      accountId,
    } = row;

    const teamMember = lookupTeamFriendlyID[employeeId];

    let params: CreatePostTaxDeductionParams = {
      company: activeCompanyId,
      employee: teamMember?._id,
      type,
      description,
      effective_start: effectiveStart,
      effective_end: effectiveEnd,
      managed: managed === "true",
      gl_account_id: accountId,
    };

    if (type === "miscellaneous") {
      params = {
        ...params,
        miscellaneous: {
          amount: isEmptyString(percent) ? amount : undefined,
          percent: isEmptyString(percent) ? undefined : Number(percent),
          total_amount: totalAmount,
        },
      };
    } else {
      params = {
        ...params,
        child_support: {
          amount,
          external_id: externalId!,
          agency: agency!,
          fips_code: fipsCode,
          issue_date: issueDate!,
          max_percent: Number(maxPercent),
        },
      };
    }

    if (enIntegration && enIntegration.connection && deductionCode) {
      params.integrations = {
        employee_navigator: {
          integration_connection_id: enIntegration.connection._id,
          deduction_code: deductionCode,
          connected: false,
        },
      };
    }

    // Deep Remove any properties that are empty strings
    params = removeEmptyStringProps(params);
    return params;
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedPostTaxDeductions = results.validData.map(buildPostTaxDeductionParams);

      const response = await MiterAPI.post_tax_deductions.import({
        clean_inputs: preppedPostTaxDeductions,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} post tax deductions with ${errors} errors.`);
        } else {
          Notifier.success(`Imported ${successes} post tax deductions.`);
        }
      } else if (errors > 0) {
        Notifier.error(`There were ${errors} errors importing the activities.`);
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the post tax deductions.");
    }
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  /** If there is a team member ID, validate that the team member exists */
  const validateTeamMemberID = (employeeId: string) => {
    const teamMember = lookupTeamFriendlyID[employeeId];
    if (!teamMember) {
      return buildFlatfileMessage("Team member not found", employeeId, "error");
    }

    return { value: employeeId };
  };

  /** Ensure that the amount must be greater than 0 */
  const validateAmount = (amount: string) => {
    const amountNum = Number(amount);
    if (isNaN(amountNum)) {
      return buildFlatfileMessage("Amount must be a number", amount, "error");
    }

    if (amountNum < 0) {
      return buildFlatfileMessage("Amount must be greater than 0", amount, "error");
    }

    return { value: amount };
  };

  /** Ensure that the percentage is between 0 and 100 */
  const validatePercent = (percent: string) => {
    const percentNum = Number(percent);
    if (isNaN(percentNum)) {
      return buildFlatfileMessage("Percent must be a number", percent, "error");
    }

    if (percentNum < 0 || percentNum > 100) {
      return buildFlatfileMessage("Percent must be between 0 and 100", percent, "error");
    }

    return { value: percent };
  };

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Employee ID",
        type: "string",
        key: "employeeId",
        description: "Unique identifer for an employee (must be same in source system and Miter)",
        validators: [{ validate: "required" }],
        hook: (row) =>
          typeof row === "string" ? validateTeamMemberID(row) : validateTeamMemberID(row.employeeId),
      },
      {
        label: "Type",
        type: "select",
        key: "type",
        description: "Type of post tax deduction",
        validators: [{ validate: "required" }],
        options: [
          { label: "Miscellaneous", value: "miscellaneous" },
          { label: "Child support", value: "child_support" },
        ],
      },
      {
        label: "Description",
        type: "string",
        key: "description",
        description: "Description of the post tax deduction",
        validators: [{ validate: "required" }],
      },
      {
        label: "Effective Start",
        type: "string",
        key: "effectiveStart",
        description: "Date the post tax deduction is effective",
        validators: [{ validate: "required" }],
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.effectiveStart)),
      },
      {
        label: "Effective End",
        type: "string",
        key: "effectiveEnd",
        description: "Date the post tax deduction is no longer effective",
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.effectiveEnd)),
      },
      {
        label: "Managed",
        type: "select",
        key: "managed",
        description: "Whether Miter should manage (direct remit) the post tax deduction to the right agency.",
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      {
        label: "Amount",
        type: "string",
        key: "amount",
        description: "Amount of the post tax deduction",
        validators: [
          { validate: "required_with_values", fieldValues: { type: "child_support" } },
          { validate: "required_without", fields: ["percent"] },
        ],
        hook: (data) => (typeof data === "string" ? validateAmount(data) : validateAmount(data.amount)),
      },
      {
        label: "Percent",
        type: "string",
        key: "percent",
        description: "Percent of the post tax deduction",
        validators: [{ validate: "required_without", fields: ["amount"] }],
        hook: (data) => (typeof data === "string" ? validatePercent(data) : validatePercent(data.percent)),
      },
      {
        label: "Total Amount",
        type: "string",
        key: "totalAmount",
        description: "Total amount of the post tax deduction",
        hook: (data) => (typeof data === "string" ? validateAmount(data) : validateAmount(data.totalAmount)),
      },

      {
        label: "GL account",
        type: "select",
        key: "accountId",
        description: "The ledger account to track this deduction.",
        options: ledgerAccounts,
      },
      {
        label: "External ID",
        type: "string",
        key: "externalId",
        description: "External ID of the post tax deduction",
        validators: [
          {
            validate: "required_with_all_values",
            fieldValues: { type: "child_support" },
          },
        ],
      },
      {
        label: "Agency",
        type: "select",
        key: "agency",
        description: "Agency of the post tax deduction",
        validators: [
          {
            validate: "required_with_all_values",
            fieldValues: { type: "child_support" },
          },
        ],
        options: stateOptions,
      },
      {
        label: "FIPS Code",
        type: "string",
        key: "fipsCode",
        description: "FIPS code of the post tax deduction",
      },
      {
        label: "Issue Date",
        type: "string",
        key: "issueDate",
        description: "Issue date of the post tax deduction",
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.issueDate)),
        validators: [
          {
            validate: "required_with_all_values",
            fieldValues: { type: "child_support" },
          },
        ],
      },
      {
        label: "Max Percent",
        type: "string",
        key: "maxPercent",
        description: "Max percent of the post tax deduction",
        hook: (data) => (typeof data === "string" ? validatePercent(data) : validatePercent(data.maxPercent)),
        validators: [
          {
            validate: "required_with_all_values",
            fieldValues: { type: "child_support" },
          },
        ],
      },
      {
        label: "Deduction code",
        type: "string",
        key: "deductionCode",
        description: "Employee Navigator deduction code",
      },
    ];

    return fieldList;
  }, [ledgerAccounts]);

  return (
    <Importer id="post tax deductions" resource="post tax deductions" onSave={handleSubmit} fields={fields} />
  );
};
