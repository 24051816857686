import AppContext from "dashboard/contexts/app-context";
import { MiterAPI, Activity, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Loader, ModalSidebar } from "ui";
import Banner from "../shared/Banner";
import { ActivityBasics } from "./ActivityBasics";
import { ActivityContributions } from "./ActivityContributions";
import { ActivityPayRate } from "./ActivityPayRate";
import checkmark from "dashboard/assets/check-mark.png";
import yellow_warning from "dashboard/assets/yellow-warning.png";
import styles from "ui/modal/modalWithSidebar.module.css";
import x from "dashboard/assets/x.png";
import { MenuItemWithoutComponent } from "ui/modal/ModalWithSidebar";
import { ActivityAdvancedOptions } from "./ActivityAdvancedOptions";
import { ActivityQuickbooksMapping } from "./ActivityQuickbooksMapping";
import { useSetActivities } from "dashboard/hooks/atom-hooks";
import { ActivityScopes } from "./ActivityScopes";
import useDebounce from "dashboard/utils/useDebounce";

type Props = {
  selectedActivity: Activity;
  hide: () => void;
  readonly?: boolean;
};

export type ActivityModalTabProps = {
  selectedActivity: Activity;
  handleFieldsChange: (newFields: Partial<Activity>) => void;
  readonly?: boolean;
};

export const TabbedActivityModal: React.FC<Props> = ({ selectedActivity, hide, readonly }) => {
  const { integrations } = useContext(AppContext);
  const setActivities = useSetActivities();
  const [activitySyncStatus, setActivitySyncStatus] = useState<"synced" | "loading" | "not_synced">("synced");
  const [showContributionsTab, setShowContributionsTab] = useState(
    !!selectedActivity.benefit_contributions?.length
  );

  const connectedSystems = integrations.filter((i) => i.connection).map((i) => i.key);
  const hasQbIntegration = connectedSystems.includes("qbo") || connectedSystems.includes("qbd");
  const hasNetSuiteIntegration = connectedSystems.includes("netsuite");

  const sourceSystem = useMemo(() => {
    let system: MiterIntegrationForCompany | undefined;
    if (selectedActivity?.integrations) {
      const key = Object.keys(selectedActivity.integrations)[0];
      if (key) return integrations.find((i) => i.key === key);
    }
    return system;
  }, [selectedActivity, integrations]);

  const menuItems = useMemo(
    () => [
      {
        label: "Basics",
        path: "basics",
      },
      {
        label: "Pay rate",
        path: "pay_rate",
      },
      {
        label: "Advanced",
        path: "advanced",
      },
      {
        label: "Scopes",
        path: "scopes",
      },
      ...(showContributionsTab
        ? [
            {
              label: "Contributions",
              path: "contributions",
            },
          ]
        : []),
      ...(hasQbIntegration
        ? [
            {
              label: "Quickbooks",
              path: "quickbooks",
            },
          ]
        : []),
      ...(hasNetSuiteIntegration
        ? [
            {
              label: "NetSuite",
              path: "netsuite",
            },
          ]
        : []),
    ],
    [showContributionsTab, hasQbIntegration]
  );

  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemWithoutComponent | undefined>(menuItems[0]);
  const [paramsToUpdate, setParamsToUpdate] = useState<Partial<Activity>>({});
  const debouncedUpdate = useDebounce(paramsToUpdate, 1000);

  const handleToggle = (path) => {
    const activeItem = menuItems.find((item) => item.path === path);
    setActiveMenuItem(activeItem);
  };

  const updateActivity = async (updateParams: Partial<Activity>): Promise<void> => {
    try {
      if (Object.keys(updateParams).length > 0) {
        const response = await MiterAPI.activities.update(selectedActivity._id, updateParams);
        setParamsToUpdate({}); // Clear out the staged updates to make
        if (response.error) throw new Error(response.error);
        setActivities((prev) => prev.concat(response));
      }
      setActivitySyncStatus("synced");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the activity");
      setActivitySyncStatus("not_synced");
    }
  };

  const handleFieldsChange = (updateParams: Partial<Activity>) => {
    setActivitySyncStatus("loading");
    setParamsToUpdate((prev) => ({ ...prev, ...updateParams })); // In case they make changes across different fields quickly
  };

  useEffect(() => {
    updateActivity(debouncedUpdate);
  }, [debouncedUpdate]);

  const renderBanner = () => {
    if (sourceSystem) {
      return (
        <Banner style={{ borderBottom: "1px solid #d6d6d6" }} content="" type="modal">
          <span className="flex">
            Note: this activity is based on a cost code imported from&nbsp;{sourceSystem.label}
          </span>
        </Banner>
      );
    }
    return null;
  };

  return (
    <div className="modal-background">
      <div className={styles["modal-wrapper"]} style={{ minHeight: 400, width: 800 }}>
        <div className={styles["header"]}>
          <div className="width-100 flex">
            <div>{selectedActivity.label}</div>
            <div className="flex-1"></div>
            {activitySyncStatus !== "loading" ? (
              <img
                src={activitySyncStatus === "synced" ? checkmark : yellow_warning}
                style={{ height: 15, marginTop: 2 }}
              />
            ) : (
              <Loader className="small-text" />
            )}
            <div style={{ width: 10 }}></div>
          </div>
          <div className="flex-1"></div>

          <img src={x} style={{ height: 12, cursor: "pointer" }} onClick={hide} />
        </div>
        {renderBanner()}
        <div className={styles["below"]}>
          <ModalSidebar config={menuItems} active={activeMenuItem?.path} toggle={handleToggle} />
          <div className={styles["active-view"]} style={{ overflowY: activeMenuItem?.overflow || "scroll" }}>
            {activeMenuItem?.path === "basics" && (
              <ActivityBasics
                selectedActivity={selectedActivity}
                handleFieldsChange={handleFieldsChange}
                readonly={readonly}
              />
            )}
            {activeMenuItem?.path === "pay_rate" && (
              <ActivityPayRate
                selectedActivity={selectedActivity}
                handleFieldsChange={handleFieldsChange}
                readonly={readonly}
              />
            )}
            {activeMenuItem?.path === "advanced" && (
              <ActivityAdvancedOptions
                selectedActivity={selectedActivity}
                handleFieldsChange={handleFieldsChange}
                showContributionsTab={showContributionsTab}
                setShowContributionsTab={setShowContributionsTab}
                readonly={readonly}
              />
            )}
            {activeMenuItem?.path === "contributions" && (
              <ActivityContributions
                selectedActivity={selectedActivity}
                handleFieldsChange={handleFieldsChange}
                readonly={readonly}
              />
            )}
            {activeMenuItem?.path === "scopes" && (
              <ActivityScopes
                selectedActivity={selectedActivity}
                handleFieldsChange={handleFieldsChange}
                readonly={readonly}
              />
            )}
            {activeMenuItem?.path === "quickbooks" && (
              <ActivityQuickbooksMapping
                selectedActivity={selectedActivity}
                handleFieldsChange={handleFieldsChange}
                readonly={readonly}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
