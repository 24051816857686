/* eslint-disable unused-imports/no-unused-vars */
import React, { FC, useEffect, useState } from "react";
import { Wizard } from "ui";
import { AggregatedI9, MiterAPI } from "dashboard/miter";
import SelectDocumentsForm from "./SelectDocumentsForm";

import ResidencyForm from "./ResidencyForm";
import { UploadDocumentsForm } from "miter-components";
import PreparerForm from "./PreparerForm";
import ReviewAndSignForm from "./ReviewAndSignForm";
import { TeamPortalUser } from "team-portal/utils/miter";

export type I9FormProps = {
  I9: AggregatedI9;
  setI9: (I9: AggregatedI9) => void;
  name:
    | "Personal Information"
    | "Residency"
    | "Select Documents"
    | "Upload Documents"
    | "Preparer/Translator"
    | "Review and Sign";
  getI9: () => Promise<AggregatedI9 | undefined>;
  activeTM: TeamPortalUser;
};

type Props = {
  onExit: () => void;
  onComplete: () => void;
  I9: AggregatedI9;
  activeTM: TeamPortalUser;
};

export const I9Wizard: FC<Props> = ({ onExit, onComplete, activeTM, ...props }) => {
  const [I9, setI9] = useState<AggregatedI9 | null>(null);

  const getI9 = async (): Promise<AggregatedI9 | undefined> => {
    try {
      const res = await MiterAPI.i_9s.retrieve(props.I9._id);
      if (res.error) throw new Error(res.error);
      setI9(res);
    } catch (e: $TSFixMe) {
      return props.I9;
    }
  };

  useEffect(() => {
    getI9();
  }, []);

  if (!I9) return <div></div>;

  return (
    <Wizard onExit={onExit} onComplete={onComplete}>
      <ResidencyForm I9={I9} setI9={setI9} name="Residency" activeTM={activeTM} getI9={getI9} />
      <SelectDocumentsForm I9={I9} setI9={setI9} name="Select Documents" activeTM={activeTM} getI9={getI9} />
      <UploadDocumentsForm I9={I9} setI9={setI9} name="Upload Documents" getI9={getI9} />
      <PreparerForm I9={I9} setI9={setI9} name="Preparer/Translator" activeTM={activeTM} getI9={getI9} />
      <ReviewAndSignForm I9={I9} setI9={setI9} name="Review and Sign" activeTM={activeTM} getI9={getI9} />
    </Wizard>
  );
};
