/** This hook returns whether a team member is assigned to at least one input of the document. */

import { useEffect, useState } from "react";
import { MiterAPI } from "dashboard/miter";
import { AggregatedFileWithUrl } from "dashboard/pages/documents/Document";

type Props = {
  teamMemberId?: string;
  miterDocument?: AggregatedFileWithUrl;
};

type Output = {
  accessible: boolean;
};

export const useAccessFillableDocument = (input: Props): Output => {
  const { teamMemberId, miterDocument } = input;
  const [accessible, setAccessible] = useState(false);

  const fetchFillableDocument = async () => {
    try {
      const fillableDocument = await MiterAPI.files.fillable_documents.retrieve(miterDocument?._id || "", {
        team_member_id: teamMemberId,
      });
      if (fillableDocument.error) {
        throw new Error(fillableDocument.error);
      }
      const hasAccess = fillableDocument.fillable_template.inputs.some((input) => input.accessible);
      setAccessible(hasAccess);
      // Purposefully not doing anything with the catch block
    } catch (error: $TSFixMe) {}
  };

  useEffect(() => {
    if (!miterDocument || !miterDocument.fillable_template_id) return;
    fetchFillableDocument();
  }, [teamMemberId, miterDocument]);

  return {
    accessible,
  };
};
