import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { fetchCurrentNetPaySplitFromCheck } from "./utils";
import { Reorder } from "framer-motion";
import { DotsSixVertical, Plus, X } from "phosphor-react";
import ObjectID from "bson-objectid";
import { Button, Formblock } from "ui";
import { Option } from "ui/form/Input";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import InfoButton from "dashboard/components/information/information";
import styles from "./ManageNetPaySplits.module.css";
import { BankAccount } from "dashboard/miter";

export const MAX_BANK_ACCOUTS_FOR_NET_PAY_SPLIT = 7;

type Props = {
  allValidPayrollBankAccounts: BankAccount[];
  teamMemberDefaultNetPaySplitId?: string;
  editing: boolean;
  onSplitUpdate: (splits: NetPaySplit[]) => void;
};

export type NetPaySplit = {
  _id: string;
  bankAccountId?: string;
  percentage?: number;
  dollarAmount?: number;
};

export const ManageNetPaySplits: FC<Props> = ({
  allValidPayrollBankAccounts,
  teamMemberDefaultNetPaySplitId,
  editing,
  onSplitUpdate,
}) => {
  const { t } = useTranslation<$TSFixMe>();

  // for dragging
  const splitRef = useRef<HTMLDivElement>(null);
  const splitRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  const [splits, setSplits] = useState<NetPaySplit[]>([]);
  const [splitType, setSplitType] = useState<"amount" | "percent">("amount");
  const [draggable, setDraggable] = useState(false);

  // initial load
  useEffect(() => {
    const fetchExistingSplits = async () => {
      if (teamMemberDefaultNetPaySplitId) {
        const existingSplits = await fetchCurrentNetPaySplitFromCheck(
          teamMemberDefaultNetPaySplitId,
          allValidPayrollBankAccounts
        );
        if (existingSplits.every((split) => split.percentage != null)) {
          setSplitType("percent");
        }
        setSplits(existingSplits);
      }
    };
    fetchExistingSplits();
  }, [editing, allValidPayrollBankAccounts]);

  // every time splits is updated, call onSplitUpdate
  useEffect(() => {
    if (!splits || !splits.length) return;
    const tempSplits = [...splits];

    // clean up splits before propagating the stack
    if (splitType === "amount") {
      tempSplits.forEach((split) => {
        split.percentage = undefined;
      });
    } else {
      tempSplits.forEach((split) => {
        split.dollarAmount = undefined;
      });
    }

    if (!tempSplits?.length) return;

    const last = tempSplits[tempSplits.length - 1];

    // sanity check to make sure we don't send an empty split
    if (!last) return;

    last.dollarAmount = splitType === "amount" ? 0 : undefined;
    last.percentage = splitType === "percent" ? 0 : undefined;

    onSplitUpdate(tempSplits);
  }, [splits, onSplitUpdate]);

  const splitTypeOptions = useMemo(
    () => [
      {
        label: t("Amount"),
        value: "amount",
      },
      {
        label: t("Percentage"),
        value: "percent",
      },
    ],
    [t]
  );

  const bankAccountOptions: Option<string>[] = useMemo(() => {
    const currentlySelectedBankAccounts = splits.map((split) => split.bankAccountId);
    return allValidPayrollBankAccounts.map((bankAccount) => {
      const isAlreadySelectedElsewhere = currentlySelectedBankAccounts.includes(bankAccount._id);
      const option: Option<string> = {
        label: `${capitalize(t(bankAccount.account_subtype))} - ${bankAccount.account_number_last_4}`,
        value: bankAccount._id,
        isDisabled: isAlreadySelectedElsewhere,
      };

      return option;
    });
  }, [allValidPayrollBankAccounts, splits]);

  const addSplit = () => {
    // add to top of list, so current "remainder" account continues
    setSplits([{ _id: new ObjectID().toHexString() }, ...splits]);
  };

  const deleteSplit = (split: NetPaySplit) => {
    // cannot delete last account
    if (splits.length === 1) {
      return;
    }

    setSplits(splits.filter((s) => s._id !== split._id));
  };

  const clearExistingSplitValues = () => {
    const newSplits = splits.map((split) => {
      return { ...split, dollarAmount: undefined, percentage: undefined };
    });

    setSplits(newSplits);
  };

  const onBankAccountChangeCurried = (splitId: string) => (newBankAccountId: string) => {
    // on paper calling trip would work here, but for some reason the value is cached. use the ref instead
    const newSplits = splits.map((split) => {
      if (split._id === splitId) {
        return { ...split, bankAccountId: newBankAccountId };
      }
      return split;
    });

    setSplits(newSplits);
  };

  const onSplitByChangedCurried = (splitId: string) => (updatedSplitBy: string) => {
    const newSplits = splits.map((split) => {
      if (split._id === splitId) {
        if (splitType === "percent") {
          return { ...split, percentage: parseFloat(updatedSplitBy) };
        } else {
          return { ...split, dollarAmount: parseFloat(updatedSplitBy) };
        }
      }
      return split;
    });

    setSplits(newSplits);
  };

  const renderDraggable = () => {
    return (
      <div
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
        onTouchStart={() => setDraggable(true)}
        style={{ marginTop: 4, marginRight: 5, marginLeft: -5 }}
      >
        <DotsSixVertical style={{ fontSize: "24px", color: "#aaa" }} />
      </div>
    );
  };

  const renderComponent = (split: NetPaySplit) => {
    const index = splits.findIndex((s) => s._id === split._id);
    const isLastItem = splits[splits.length - 1]?._id === split._id;
    const hideDelete = isLastItem || !editing;

    const amountSelected = splitType === "amount";
    return (
      <Reorder.Item
        key={"section-" + split._id}
        value={split}
        dragListener={draggable}
        onDragEnd={() => setDraggable(false)}
        layout="position"
        ref={(r) => (splitRefs.current[split._id] = r)}
        id={split._id}
        dragConstraints={splitRef}
      >
        <div className={styles["draggable-row"]}>
          {editing && renderDraggable()}
          {/* white background */}
          <div className={styles["pay-split-container"]}>
            {/*  */}
            <div className={styles["selection-body"]}>
              <div className={styles["bank-account-selection"]}>
                <Formblock
                  type="select"
                  label={`${t("Priority")}: ${index + 1}`}
                  options={bankAccountOptions}
                  name={`bank-account-${split._id}`}
                  editing={editing}
                  onChange={(option) => {
                    onBankAccountChangeCurried(split._id)(option.value);
                  }}
                  className={styles["row-formblock"]}
                  placeholder={t("Select bank account")}
                  defaultStringClassname="flex align-items-center"
                  defaultValue={split.bankAccountId}
                />
              </div>
              <div className={styles["amount-or-percentage-selection"]}>
                {isLastItem && (
                  <div className={styles["deposit-remainder-string"]}>
                    {t("Deposit remainder of paycheck")}
                  </div>
                )}
                {!isLastItem && (
                  <Formblock
                    label={t("Deposit up to")}
                    type="unit"
                    unit={amountSelected ? "$" : "%"}
                    name="amount"
                    editing={true}
                    readOnly={!editing}
                    className={(amountSelected ? "" : "percent-unit ") + styles["row-formblock"]}
                    onChange={(e) => {
                      onSplitByChangedCurried(split._id)(e.target.value);
                    }}
                    value={
                      (amountSelected ? split.dollarAmount?.toString() : split.percentage?.toString()) ?? ""
                    }
                  />
                )}
              </div>{" "}
            </div>

            <X
              size={18}
              color="#aaa"
              style={hideDelete ? { opacity: 0 } : { marginRight: 10 }}
              className={hideDelete ? "" : "pointer"}
              onClick={() => {
                if (!hideDelete) {
                  deleteSplit(split);
                }
              }}
            />
          </div>
        </div>
      </Reorder.Item>
    );
  };

  const renderHeader = () => {
    const addSplitDisabled =
      splits.length > MAX_BANK_ACCOUTS_FOR_NET_PAY_SPLIT ||
      bankAccountOptions?.length === 0 ||
      splits.length >= allValidPayrollBankAccounts.length;

    return (
      <div className="flex align-items-center space-between width-100-percent">
        <Formblock
          label={t("Split By:")}
          type="select"
          onChange={(selectedOption) => {
            // clear all existing values
            clearExistingSplitValues();
            setSplitType(selectedOption.value);
          }}
          editing={true}
          options={splitTypeOptions}
          value={splitTypeOptions.find((o) => o.value === splitType)}
          className="align-items-center"
          style={{ maxWidth: "250px" }}
        />

        <Button
          onClick={addSplit}
          className={"button-1 no-margin"}
          style={{ marginTop: "5px", marginBottom: "15px", marginLeft: 23 }}
          disabled={addSplitDisabled}
        >
          <Plus weight="bold" style={{ marginRight: 3 }} />
          {t("Split")}
          {addSplitDisabled && (
            <InfoButton
              text={t("You can only add as many splits as you have valid bank accounts.")}
              place="left"
            />
          )}
        </Button>
      </div>
    );
  };

  const renderSplit = () => {
    return (
      <div className={styles["net-pay-split-body"]}>
        {editing && renderHeader()}
        <Reorder.Group axis="y" values={splits} onReorder={setSplits} ref={splitRef} className="no-margin">
          <>{splits.map((stop) => renderComponent(stop))}</>
        </Reorder.Group>
      </div>
    );
  };

  return <>{renderSplit()}</>;
};
