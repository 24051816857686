import { useActiveCompanyId, useCertificationTypes, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import { FC, useEffect, useMemo, useState } from "react";
import { BulkSelectorModal, Notifier } from "ui";
import FailuresModal from "../shared/FailuresModal";

type Props = {
  teamMemberId: string;
  onClose: () => void;
};

export const RequestCertificationsForTM: FC<Props> = ({ teamMemberId, onClose }) => {
  const certificationTypes = useCertificationTypes();
  const [unexpiredCertificationTypeIds, setUnexpiredCertificationTypeIds] = useState<string[]>([]);
  const [failures, setFailures] = useState<{ label: string; message: string }[]>([]);
  const activeCompanyId = useActiveCompanyId();
  const lookupTeam = useLookupTeam();

  useEffect(() => {
    const getUnexpiredCertifications = async () => {
      MiterAPI.certifications
        .forage({
          filter: [
            { field: "team_member_id", value: teamMemberId, type: "string" },
            {
              type: "or",
              value: [
                {
                  field: "expires_at",
                  value: DateTime.now().toISODate(),
                  type: "string",
                  comparisonType: ">=",
                },
                { field: "expires_at", value: null, type: "string" },
              ],
            },
          ],
        })
        .then((res) => {
          setUnexpiredCertificationTypeIds(
            res.data.map((certification) => certification.certification_type_id) || []
          );
        });
    };
    getUnexpiredCertifications();
  }, []);

  const options = useMemo(() => {
    return certificationTypes
      .filter((cert) => !unexpiredCertificationTypeIds.includes(cert._id))
      .map((cert) => ({ label: cert.title, value: cert._id }));
  }, [certificationTypes, unexpiredCertificationTypeIds]);

  const onSubmit = async (selections: string[]) => {
    if (!activeCompanyId) return Notifier.error("No active company found");
    try {
      const params = selections.map((certification_type_id) => ({
        certification_type_id,
        team_member_id: teamMemberId,
        company_id: activeCompanyId,
        submitted: false,
        expires_at: undefined,
        custom_field_values: [],
        file_uploads: {},
        archived: false,
      }));
      const res = await MiterAPI.certifications.create(params);
      if (res.error) throw res.error;
      setFailures(
        res.failures.map((f) => ({
          label: lookupTeam(f._id)?.full_name || "Team member",
          message: f.message,
        }))
      );
      Notifier.success("Certification requests successfully sent!");
    } catch (e: $TSFixMe) {
      Notifier.error("Failed to request certifications: " + e.message);
    }
    onClose();
  };

  const renderUpdateFailuresModal = () => {
    if (!failures.length) return null;

    return (
      <FailuresModal
        headerText={"Certification request failures"}
        onClose={() => {
          setFailures([]);
        }}
        failures={failures}
      />
    );
  };

  return (
    <div>
      <BulkSelectorModal
        resource={"certifications"}
        onSubmit={onSubmit}
        onCancel={onClose}
        options={options}
      />
      {renderUpdateFailuresModal()}
    </div>
  );
};
