import React, { useMemo } from "react";
import { Option } from "ui/form/Input";
import { Formblock, Loader } from "ui";
import { DeepPartial } from "utility-types";
import { UseFormMethods, FieldValues } from "react-hook-form";
import {
  AcumaticaConfigObject,
  AcumaticaConnectionMetadata,
} from "backend/services/acumatica/acumatica-types";
import { DEFAULT_ACUMATICA_LABEL_STYLE, ACUMATICA_INPUT_LENGTH } from "./AcumaticaConfig";
import { useFetchAcumaticaExpenseItems } from "dashboard/hooks/integrations/acumatica/useFetchAcumaticaExpenseItems";
import { MiterIntegrationForCompany } from "dashboard/miter";

interface DefaultExpenseItemSelectionFormBlockProps {
  integration: MiterIntegrationForCompany;
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => Promise<void>;
  configOptions?: AcumaticaConfigObject;
  form: UseFormMethods<FieldValues>;
}

export const DefaultExpenseItemSelection: React.FC<DefaultExpenseItemSelectionFormBlockProps> = ({
  integration,
  updateAcumaticaMetadata,
  configOptions,
  form,
}) => {
  const { expenseItems, loading, error } = useFetchAcumaticaExpenseItems(integration.connection?._id);

  const expenseItemOptions = useMemo(() => {
    return expenseItems
      ? expenseItems?.map((field) => ({ label: field.Description.value, value: field.id }))
      : [];
  }, [expenseItems]);

  const updateDefaultBranch = async (chosenBranch: Option<string> | null) => {
    const expenseItem = expenseItems?.find((field) => field.id === chosenBranch?.value);

    await updateAcumaticaMetadata(
      { configObject: { expenses: { defaultExpenseItem: expenseItem } } },
      { collapseCount: 2 }
    );
  };

  if (loading) return <Loader />;

  if (error) return null;

  const defaultExpenseItem = configOptions?.expenses?.defaultExpenseItem?.id;

  return (
    <Formblock
      form={form}
      options={expenseItemOptions}
      name="defaultExpenseItem"
      type="select"
      defaultValue={defaultExpenseItem}
      onChange={updateDefaultBranch}
      editing={true}
      labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
      label="Default Expense Item"
      labelInfo="The Expense Item Miter will use when pushing expense claims."
      underlineTooltip
      inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
    />
  );
};
