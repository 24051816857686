import { DocumentTaskData } from "dashboard/types/onboarding-types";
import React, { useEffect } from "react";
import { Button, Formblock, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { FormProvider, useForm } from "react-hook-form";

import { getBrowser, getOS, markFilesAsViewed } from "miter-utils";
import { MiterAPI } from "dashboard/miter";
import { Props } from "./types";
import { Option } from "ui/form/Input";
import useWizard from "ui/modal/useWizard";
import { ESignatureInputValue } from "ui/form/ESignatureInput";
import { SignESignatureRequestParams } from "../../../backend/services/esignature-item-service";
import { esignatureRequired } from "dashboard/utils/validators";

type DocumentsWizardScreenProps = Props & {
  document: DocumentTaskData;
};

export const DocumentsWizardScreen: React.FC<DocumentsWizardScreenProps> = ({ task, name, document }) => {
  const form = useForm({
    mode: "all",
  });
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();

  const action = task?.metadata && "document" in task?.metadata ? task.metadata.document.action : "view";

  const {
    errors,

    handleSubmit,
    formState: { errors: formErrors },
    trigger,
  } = form;

  useEffect(() => {
    if (Object.keys(errors).length) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formErrors]);

  useEffect(() => {
    trigger();
  }, []);

  const buildESignatureParams = async (data: ESignatureInputValue): Promise<SignESignatureRequestParams> => {
    if (!data) {
      throw new Error(`Please enter your full name to sign this document.`);
    }

    return {
      signature: {
        // @ts-expect-error fix me
        image: data.data,
        device: {
          type: "desktop",
          // @ts-expect-error fix me
          os: getOS(),
        },
        application: {
          name: "team-portal",
        },
      },
    };
  };

  const saveSignature = async (data) => {
    try {
      const formattedESignatureParams = await buildESignatureParams(data);
      const res = await MiterAPI.esignature_items.requests.sign(
        document.esignature!._id,
        formattedESignatureParams
      );
      if (res.error) throw new Error(res.error);

      Notifier.success("Document signed successfully");
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      console.log(`Error signing document: ${err.message}`);
    }
  };

  const viewDocument = async () => {
    try {
      if (!document?.document?._id) throw new Error("Error getting document. Please contact support.");

      const responseData = await MiterAPI.files.retrieve(document.document._id);
      if (responseData.error) throw responseData.error;

      const browser = getBrowser();

      if (browser?.includes("Safari")) {
        window.location.href = responseData.url;
      } else {
        window.open(responseData.url, "_blank");
      }
      markFilesAsViewed([document.document._id]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an getting the link to the signed document. Please contact support.");
    }
  };

  const onNext = async () => {
    if (action === "sign" && document.esignature?.status !== "signed") {
      handleSubmit(async (data: { [x: string]: Option<string> | null }) => {
        saveSignature(data.esignature);
        if (curIndex === screens.length - 1) {
          handleComplete();
        }
      })();
    } else {
      if (curIndex === screens.length - 1) {
        handleComplete();
      }
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        <div className={styles["modal-content"]}>
          <Button
            className={"button-2"}
            onClick={viewDocument}
            style={{ margin: 0, marginBottom: 30, marginTop: 10, padding: "20px 35px", fontSize: 16 }}
          >
            View Document
          </Button>
          {action === "sign" ? (
            document.esignature?.status === "signed" ? (
              <p className={styles["signed"]}>Document signature already complete!</p>
            ) : (
              <FormProvider {...form}>
                <Formblock
                  type="esignature"
                  name="esignature"
                  label="Signature*"
                  form={form}
                  editing={true}
                  className="modal wizard"
                  rules={{ validate: esignatureRequired }}
                  onError={Notifier.error}
                  onChange={(_e) => {
                    trigger();
                  }}
                />
              </FormProvider>
            )
          ) : null}
        </div>
      </div>
    </WizardScreen>
  );
};
