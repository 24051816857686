import React, { useEffect, useMemo, useState } from "react";
import { Button, ConfirmModal, Formblock, Label, Notifier, PageModal } from "ui";
import ObjectID from "bson-objectid";
import { useNavigate } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useForm, useWatch } from "react-hook-form";
import { TRUEBECK_COMPANY_ID } from "dashboard/utils";
import { IS_PRODUCTION } from "dashboard/utils/environment";

import { TimeOffPolicyLevelConfigForm } from "./TimeOffPolicyLevelConfigForm";
import { useActiveCompanyId, useRefetchTimeOffPolicies } from "dashboard/hooks/atom-hooks";
import {
  MiterAPI,
  TimeOffPolicyLevelConfig,
  TimeOffPolicyParams,
  TimeOffPolicy as TimeOffPolicy_,
} from "dashboard/miter";
import { PageModalActionLink } from "ui/modal/PageModal";
import { usePolicyLevelsWithEnrollees } from "dashboard/hooks/useLevelHasEnrollees";
import { PureFrontendError } from "miter-utils";

type Props = {
  timeOffPolicy?: TimeOffPolicy_;
  onComplete?: () => {};
  onHide?: () => void;
};

type TimeOffPolicyFormFields = {
  type: { label: string; value: "vacation" | "sick" };
  name: string;
  custom_earning_code: string;
  accrue_on_davis_bacon_jobs: boolean;
};

const POLICY_TYPE_OPTIONS: { label: string; value: "vacation" | "sick" }[] = [
  { label: "Vacation", value: "vacation" },
  { label: "Sick", value: "sick" },
];

export const TimeOffPolicyForm: React.FC<Props> = ({ timeOffPolicy, onComplete, onHide }) => {
  const [levels, setLevels] = useState<TimeOffPolicyLevelConfig[]>(
    timeOffPolicy?.levels || [
      {
        _id: ObjectID().toHexString(),
        unlimited: true,
        name: "Level 1",
      },
    ]
  );

  const [levelsEditStatus] = useState<{ [key: string]: boolean }>({});
  const [showCancel, setShowCancel] = useState(false);

  const navigate = useNavigate();
  const { cannot } = useMiterAbilities();
  const [closeAllLevels, setCloseAllLevels] = useState(0);

  const refetchTimeOffPolicies = useRefetchTimeOffPolicies();

  useEffect(() => {
    if (timeOffPolicy?._id === null && cannot("time_off:policies:create")) {
      Notifier.error("You do not have permission to create time off policies");
      navigate("/home");
    }
    if (!!timeOffPolicy?._id && cannot("time_off:policies:update")) {
      Notifier.error("You do not have permission to update time off policies");
      navigate("/home");
    }
  }, [cannot, navigate]);

  const activeCompanyId = useActiveCompanyId();
  const levelHasEnrollees = usePolicyLevelsWithEnrollees(timeOffPolicy?._id);

  const form = useForm<TimeOffPolicyFormFields>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: {
      name: timeOffPolicy?.name,
      custom_earning_code: timeOffPolicy?.custom_earning_code,
      accrue_on_davis_bacon_jobs: timeOffPolicy?.accrue_on_davis_bacon_jobs,
      type: POLICY_TYPE_OPTIONS.find((type) => type.value === timeOffPolicy?.type),
    },
  });

  useWatch({ control: form.control });

  const { handleSubmit } = form;

  const editingLevel = (levelId: string, isEditing: boolean) => {
    levelsEditStatus[levelId] = isEditing;
  };

  const onDeleteLevel = (id: string) => {
    if (timeOffPolicy?._id && levelHasEnrollees.has(id)) {
      Notifier.error("Cannot delete level that has enrollees assigned to it");
      return;
    }
    setLevels(levels.filter((level) => level._id !== id));
  };

  const savePolicy = async (params: TimeOffPolicyFormFields) => {
    try {
      if (Object.values(levelsEditStatus)?.find((isEditingLevel) => isEditingLevel)) {
        throw new PureFrontendError(`Cannot save while editing a level.`);
      }
      if (!params.name) {
        throw new PureFrontendError("Please enter a name for this time off policy");
      }
      if (!params.type) {
        throw new PureFrontendError("Type is required");
      }

      if (!activeCompanyId) {
        throw new PureFrontendError("Active company id is required");
      }
      if (levels.length === 0) {
        throw new PureFrontendError("Must have atleast one tenure level");
      }

      const requestBody: TimeOffPolicyParams = {
        type: params.type.value,
        name: params.name,
        custom_earning_code: params.custom_earning_code || undefined,
        accrue_on_davis_bacon_jobs: params.accrue_on_davis_bacon_jobs,

        levels: levels,
        company: activeCompanyId,

        // DEPRECATED FIELDS keeping for mobile
        // todo: remove after 6 months
        unpaid: levels[0]?.unpaid,
        unlimited: levels[0]?.unlimited,
        accrual_config: levels[0]?.accrual_config,

        disable_negative_balances: levels[0]?.disable_negative_balances,
        min_tenure_for_requests: levels[0]?.min_tenure_for_requests || 0,
        min_tenure_for_accruals: levels[0]?.min_tenure_for_accruals || 0,
        default_starting_balance: levels[0]?.default_starting_balance || 0,

        enable_fringe_offset: levels[0]?.enable_fringe_offset,
      };

      const res = timeOffPolicy?._id
        ? await MiterAPI.time_off.policies.update(timeOffPolicy._id.toString(), requestBody)
        : await MiterAPI.time_off.policies.create(requestBody);

      if (res.error) throw new Error(res.error);
      if (timeOffPolicy?._id) {
        Notifier.success("Successfully updated policy");
        onComplete?.();
        onHide?.();
      } else {
        Notifier.success(
          "Successfully created policy. \n Be sure to enroll employees into this policy by selecting manage enrollees",
          { duration: 5000 }
        );
        navigate("/time-off/policies/" + res._id);
      }
      refetchTimeOffPolicies();
    } catch (e: $TSFixMe) {
      if (!(e instanceof PureFrontendError)) {
        console.error("Error saving time off policy", e);
      }
      Notifier.error(e.message);
    }
  };

  const closeForm = () => {
    if (!timeOffPolicy?._id) {
      navigate("/time-off/policies");
    } else {
      onHide?.();
    }
  };

  const addLevel = async () => {
    setLevels([
      ...levels,
      {
        _id: ObjectID().toHexString(),
        unlimited: true,
        name: "Level " + (levels.length + 1),
      },
    ]);
    setCloseAllLevels(closeAllLevels + 1);
    Notifier.success("New level added");
  };

  const modalActions: PageModalActionLink[] = useMemo(() => {
    const actions: PageModalActionLink[] = [
      {
        label: "Cancel",
        action: () => setShowCancel(true),
        position: "right",
        className: "button-1 no-margin",
      },
      {
        label: "Submit",
        action: handleSubmit(savePolicy),
        position: "right",
        className: "button-2 no-margin",
      },
    ];
    return actions;
  }, [handleSubmit, savePolicy]);

  return (
    <>
      <PageModal
        header={!timeOffPolicy?._id ? `Create time off policy` : `Update time off policy`}
        onClose={() => setShowCancel(true)}
        footerActions={modalActions}
      >
        <div>
          <div className="form-section" style={{ marginBottom: 30 }}>
            {/* <PolicyHeader label="Policy name" /> */}
            <h3>Basic Policy Information</h3>
            <Formblock
              label="Category*"
              labelInfo={"Choose the type of time off policy that you would like to create."}
              type="select"
              form={form}
              name="type"
              options={POLICY_TYPE_OPTIONS}
              className="modal wizard"
              editing={true}
              requiredSelect={true}
              disabled={!!timeOffPolicy?._id}
            />
            <Formblock
              label="Policy name*"
              placeholder="Enter a policy name"
              type="text"
              form={form}
              className="modal wizard"
              name="name"
              editing={true}
            />
            {(activeCompanyId === TRUEBECK_COMPANY_ID || !IS_PRODUCTION) && (
              <Formblock
                label="Custom earning code"
                className="modal wizard"
                type="text"
                name="custom_earning_code"
                placeholder="e.g. JURY"
                form={form}
                editing={true}
              />
            )}
            {levels.find((level) => !!level.accrual_config?.hourly) && (
              <Formblock
                type="checkbox"
                name="accrue_on_davis_bacon_jobs"
                text={"Accrue time off on hours worked on Davis-Bacon jobs only."}
                form={form}
                editing={true}
                placeholder="0"
              />
            )}
          </div>
          <div
            className="form-section"
            style={{ marginBottom: 0, display: "flex", justifyContent: "space-between" }}
          >
            <Label
              label={<strong>{"Levels"}</strong>}
              style={{ width: "auto" }}
              sublabel={"Create different accrual configurations / settings for different tenure levels"}
            ></Label>
            <Button className="button-2" text="Add level" onClick={() => addLevel()}></Button>
          </div>
          {levels.map((level, i) => (
            <div style={{ marginBottom: 16 }}>
              <TimeOffPolicyLevelConfigForm
                policyLevel={level}
                editingLevel={editingLevel}
                timeOffPolicyId={timeOffPolicy?._id}
                onSave={(levelConfig) => {
                  setLevels((levels) => {
                    levels.splice(i, 1, levelConfig);
                    return [...levels];
                  });
                }}
                onDelete={onDeleteLevel}
                closeLevel={closeAllLevels}
              ></TimeOffPolicyLevelConfigForm>
            </div>
          ))}
        </div>
        {showCancel && (
          <ConfirmModal
            body={"Are you sure you want to lose all of your changes"}
            onNo={() => {
              setShowCancel(false);
            }}
            onYes={closeForm}
          />
        )}
      </PageModal>
    </>
  );
};
