import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Badge } from "ui";
import { Toggler } from "ui";
import JobDetails from "./jobDetails";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import { ActionMenuRelative } from "ui";
import { BasicModal } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import JobActivities from "./activities/JobActivities";
import CustomActivitiesModal from "./activities/CustomActivitiesModal";
import JobPhotos from "./JobPhotos";
import { SchedulingWrapper } from "dashboard/pages/scheduling/Scheduling";
import Outbox from "./Outbox";
import FilesTable from "dashboard/components/tables/FilesTable";
import { Helmet } from "react-helmet";
import { capitalize } from "dashboard/utils";
import Banner from "dashboard/components/shared/Banner";
import { shouldDisableJobActivityControl, useJobSourceSystem } from "dashboard/utils/jobUtils";
import DailyReportsTable from "dashboard/components/daily-reports/DailyReportsTable";
import { truncate } from "lodash";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";
import {
  useLookupJob,
  useRefetchJobs,
  useSelectableSubJobsMap,
  useSubJobsConfig,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { AuditLogHistory } from "dashboard/components/audit-logs/AuditLogHistory";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import JobSubJobs from "./JobSubJobs";
import { PerDiemRatesTable } from "../expenses/PerDiemRatesTable";

// This component renders the Job page where users can view/edit a job's details, timesheets, etc

const Job: React.FC = () => {
  // Hooks
  const { id, view } = useParams<{ id: string; view: string }>();
  const navigate = useNavigate();
  const lookupJob = useLookupJob();
  const refetchJobs = useRefetchJobs();
  const jobData = lookupJob(id);
  const jobAbilities = useJobAbilities();
  const activeUser = useUser();
  const showAuditLogHistory = !!activeUser?.miter_admin || !IS_PRODUCTION;
  const subJobsConfig = useSubJobsConfig();
  const selectableSubJobsMap = useSelectableSubJobsMap();

  // State
  const [activeView, setActiveView] = useState(view ? view : "details");
  const [loading, setLoading] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [activitySettingsModal, setActivitySettingsModal] = useState(false);

  // Procore or Intacct
  const disableActivityControl = shouldDisableJobActivityControl(jobData);

  // Determine the job's source system
  const sourceSystem = useJobSourceSystem(jobData);

  // Status
  const statusActive = jobData?.status === "active";

  const [updatingJob, setUpdatingJob] = useState(false);
  const [errorUpdatingJob, setErrorUpdatingJob] = useState(false);

  const childJobs = useMemo(
    () => (subJobsConfig.enabled && id && selectableSubJobsMap ? selectableSubJobsMap.get(id) || [] : []),
    [selectableSubJobsMap, id]
  );

  useEffect(() => {
    if (!jobData) return;

    if (jobAbilities.cannot("read", jobData)) {
      Notifier.error("You do not have permission to view this job");
      navigate("/home");
    }
  }, [jobData, jobAbilities]);

  const updateJob = async (data: $TSFixMe) => {
    if (!jobData) return;
    setUpdatingJob(true);
    setErrorUpdatingJob(false);
    try {
      const response = await MiterAPI.jobs.update(jobData._id, data);
      if (response.error) throw new Error(response.error);
      await refetchJobs(id);
      Notifier.success("Job successfully updated");
    } catch (e: $TSFixMe) {
      setErrorUpdatingJob(true);
      console.log("Error updating job", e.message);
      Notifier.error("There was an error updating the job");
    }
    setUpdatingJob(false);
  };

  const handleToggle = (option: string) => {
    setActiveView(option);
    navigate("/jobs/" + id + "/" + option);
  };

  // Action handlers
  const handleArchive = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.jobs.update(jobData!._id, { archived: true });
      if (response.error) {
        throw new Error(response.error);
      }
      await refetchJobs(response._id);
      Notifier.success("Job successfully deleted");
      navigate("/jobs");
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was an error deleting the job. We're looking into it.");
    }
    setLoading(false);
  };

  const handleStatusChange = async () => {
    setLoading(true);
    try {
      const newStatus = statusActive ? "inactive" : "active";
      const response = await MiterAPI.jobs.update(jobData!._id, {
        status: newStatus,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      await refetchJobs(response._id);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was an error changing the job's status. We're looking into it.");
    }
    setLoading(false);
  };

  const getTitleTag = () => {
    const title = jobData ? jobData.name : "";
    if (activeView === "daily-reports") {
      return title + " " + "Daily Reports | Miter";
    } else if (activeView === "per-diems") {
      return title + " " + "Per Diems | Miter";
    } else if (activeView === "audit-log") {
      return title + " " + "Audit Log | Miter";
    } else return title + " " + `${capitalize(activeView)} | Miter`;
  };

  // Action menu
  const actionMenuLinks = [
    ...(sourceSystem
      ? []
      : [
          {
            label: statusActive ? "Set as inactive" : "Set as active",
            action: handleStatusChange,
          },
        ]),
    ...(jobAbilities.can("delete", jobData)
      ? [{ label: "Delete job", action: () => setArchiving(true) }]
      : []),
  ];

  useEffect(() => {
    if (!view) {
      navigate("/jobs/" + id + "/details", { replace: true });
    }
  }, [view]);

  useEffect(() => {
    refetchJobs(id);
  }, []);

  if (!id) return <Navigate to="/404" replace />;
  if (!jobData) return <></>;

  // Toggler details
  const togglerConfig = [
    { path: "details", label: "Details" },
    ...(subJobsConfig.enabled && childJobs.length > 0 ? [{ path: "sub-jobs", label: "Sub jobs" }] : []),
    { path: "daily-reports", label: "Daily reports" },
    { path: "timesheets", label: "Timesheets" },
    { path: "per-diems", label: "Per diems" },
    { path: "documents", label: "Documents" },
    { path: "photos", label: "Photos" },
    { path: "activities", label: "Activities" },
    { path: "outbox", label: "Outbox" },
    ...(showAuditLogHistory ? [{ path: "audit-log", label: "Audit log (Mitosaur only)" }] : []),
  ];

  return (
    <div>
      <Helmet>
        <title>{getTitleTag()}</title>
      </Helmet>
      {jobData && (
        <div className="page-content">
          <div className="flex">
            <h1>{truncate(jobData.name, { length: 50 })}</h1>
            <Badge text={jobData.status} />
            <div style={{ width: "10px" }}></div>
            <ActionMenuRelative links={actionMenuLinks} />
            <div className="flex-1"></div>
            {view === "activities" && !disableActivityControl && (
              <button className="button-1 no-margin" onClick={() => setActivitySettingsModal(true)}>
                Activity settings
              </button>
            )}
          </div>
          {sourceSystem && (
            <>
              <div className="vertical-spacer-small"></div>
              <Banner content="">
                <span className="flex">
                  Note: This job was created from a job in&nbsp;
                  <span onClick={() => navigate("/integrations/" + sourceSystem.key)} className="blue-link">
                    {sourceSystem.label}
                  </span>
                  .
                </span>
              </Banner>
            </>
          )}
          {archiving && (
            <BasicModal
              button1Text="Cancel"
              button1Action={() => setArchiving(false)}
              button2Text="Delete"
              button2Action={handleArchive}
              loading={loading}
              headerText="Delete job"
              bodyText={
                "Are you sure you want to delete " +
                jobData.name +
                "? To restore a deleted job, you'll have to contact Miter Support."
              }
            />
          )}
          {activitySettingsModal && (
            <CustomActivitiesModal job={jobData} hide={() => setActivitySettingsModal(false)} />
          )}
          <Toggler config={togglerConfig} toggle={handleToggle} active={view} />
          <div className="vertical-spacer"></div>
          <div>
            {view === "details" && (
              <JobDetails
                job={jobData}
                updateJob={updateJob}
                updatingJob={updatingJob}
                errorUpdatingJob={errorUpdatingJob}
              />
            )}
            {view === "sub-jobs" && jobData && <JobSubJobs job={jobData} />}
            {view === "daily-reports" && (
              <DailyReportsTable
                defaultFilters={[
                  { field: "company", value: jobData.company, type: "string" },
                  { field: "job._id", value: jobData._id, type: "string" },
                ]}
                job={jobData}
              />
            )}
            {view === "per-diems" && <PerDiemRatesTable job={jobData} />}
            {view === "timesheets" && (
              <TimesheetsTable
                defaultFilters={[{ field: "job._id", type: "_id", value: id }]}
                showToggler={true}
                showMiniTitle={false}
                activeJob={jobData}
              />
            )}
            {view === "schedule" && <SchedulingWrapper job={jobData} />}
            {view === "documents" && (
              <FilesTable
                parentType={"job"}
                parentId={id}
                defaultFilters={[
                  {
                    field: "parent_id",
                    type: "string",
                    value: id.toString(),
                  },
                  {
                    field: "parent_type",
                    type: "string",
                    value: "job",
                  },
                ]}
              />
            )}
            {view === "activities" && <JobActivities job={jobData} />}
            {view === "photos" && <JobPhotos job={jobData} />}
            {view === "outbox" && <Outbox job_id={id} />}
            {view === "audit-log" && <AuditLogHistory itemId={id} type="job" />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Job;
