import { useEffect } from "react";
import { useActiveCompanyId, useRefetchActionableItems } from "./atom-hooks";

export const useActionableItemsInterval = (): void => {
  const activeCompanyId = useActiveCompanyId();
  const refetchActionableItems = useRefetchActionableItems();

  // Get actionable items on load
  useEffect(() => {
    if (!activeCompanyId) return;
    refetchActionableItems();
  }, [activeCompanyId]);

  // Every 30 minutes, refetch actionable items
  useEffect(() => {
    if (!activeCompanyId) return;
    const interval = setInterval(() => {
      refetchActionableItems();
    }, 30 * 60 * 1000);
    return () => clearInterval(interval);
  }, [activeCompanyId]);
};
