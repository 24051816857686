import { useActiveCompany, useWorkplaces, useActiveTeam, useActiveJobs } from "dashboard/hooks/atom-hooks";
import { Address, AggregatedJob, AggregatedTeamMember, Workplace } from "dashboard/miter";
import { Plus } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { WorkplaceModal } from "./WorkplaceModal";
import { createObjectMapToArray } from "dashboard/utils";
import { baseSensitiveCompare, useEnhancedSearchParams } from "miter-utils";
import { Assign } from "utility-types";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

export type WorkplaceTableEntry = Assign<
  Workplace,
  {
    active: boolean | undefined;
    team_members: AggregatedTeamMember[];
    jobs: AggregatedJob[];
    address: Address;
  }
>;

export const WorkplacesTable: React.FC = () => {
  // Hooks
  const workplaces = useWorkplaces();
  const company = useActiveCompany();
  const teamMembers = useActiveTeam();
  const jobs = useActiveJobs();
  const { setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const { can } = useMiterAbilities();

  // States related to the table
  const tableData = useMemo(() => {
    const teamMembersMap = createObjectMapToArray(teamMembers, (teamMember) => teamMember.workplace?._id);
    const jobsMap = createObjectMapToArray(jobs, (job) => job.workplace_id);

    return workplaces
      .map((workplace: Workplace): WorkplaceTableEntry => {
        return {
          ...workplace,
          address: workplace.check_workplace.address,
          active: workplace.check_workplace.active,
          team_members: teamMembersMap[workplace._id] || [],
          jobs: jobsMap[workplace._id] || [],
        };
      })
      .filter((w) => {
        if (w.duplicate_of) return false;
        return w.keep_active || w.check_workplace.active || w.team_members.length || w.jobs.length;
      })
      .sort(sorter);
  }, [workplaces, jobs, teamMembers]);

  const [selectedRows, setSelectedRows] = useState<WorkplaceTableEntry[]>([]);

  // States related to table actions
  const [adding, setAdding] = useState<boolean>(false);
  const [selectedWorkplace, setSelectedWorkplace] = useState<WorkplaceTableEntry>();

  const handleModalClose = () => {
    setAdding(false);
    setSelectedWorkplace(undefined);
  };

  const handleModalSubmit = () => {
    setAdding(false);
  };

  useEffect(() => {
    setSearchParams({ wid: selectedWorkplace?._id });
  }, [selectedWorkplace]);

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = [
    {
      label: "Add workplace",
      className: "button-2 no-margin",
      action: () => setAdding(true),
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => can("lists:workplaces:manage"),
    },
  ];

  const noNamedWorkplaces = tableData.every((workplace) => !workplace.name);
  const noWorkplacesKeptActive = tableData.every((workplace) => !workplace.keep_active);
  const hasJobBasedWorkplacesEnabled = company?.settings.payroll.multi_workplace_payrolls_enabled || false;
  const columns: ColumnConfig<WorkplaceTableEntry>[] = [
    {
      field: "name",
      headerName: "Name",
      dataType: "string",
      editableHide: true,
      minWidth: 200,
      hide: noNamedWorkplaces,
    },
    {
      field: "address.line1",
      headerName: "Line 1",
      dataType: "string",
      editableHide: true,
      minWidth: 150,
    },
    {
      field: "address.line2",
      headerName: "Line 2",
      dataType: "string",
      editableHide: true,
      minWidth: 150,
    },
    {
      field: "address.city",
      headerName: "City",
      dataType: "string",
      editableHide: true,
      minWidth: 150,
    },
    {
      field: "address.state",
      headerName: "State",
      dataType: "string",
      editableHide: true,
      maxWidth: 100,
    },
    {
      field: "active",
      headerName: "Active",
      dataType: "boolean",
      editableHide: true,
      maxWidth: 100,
    },
    {
      field: "keep_active",
      headerName: "Force active",
      headerTooltip:
        "If unchecked, Miter will auto-deactivate unused workplaces each night to minimize your tax jurisdiction complexity. This box should only be checked in rare circumstances.",
      dataType: "boolean",
      editableHide: true,
      hide: noWorkplacesKeptActive,
    },
    {
      field: "tm_count",
      headerName: "Team members",
      dataType: "number",
      editableHide: true,
      valueGetter: (params) => params.data?.team_members?.length || 0,
    },
    {
      field: "job_count",
      headerName: "Jobs",
      dataType: "number",
      editableHide: true,
      valueGetter: (params) => params.data?.jobs?.length || 0,
      hide: !hasJobBasedWorkplacesEnabled,
    },
  ];

  return (
    <div>
      {can("lists:workplaces:manage") && (adding || selectedWorkplace) && (
        <WorkplaceModal
          onHide={handleModalClose}
          onFinish={handleModalSubmit}
          workplace={selectedWorkplace}
        />
      )}
      <TableV2
        id={"workplaces-table"}
        resource="workplaces"
        data={tableData}
        columns={columns}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={setSelectedWorkplace}
        gridWrapperStyle={{ height: "60vh" }}
        paginationPageSize={1000}
      />
    </div>
  );
};

const sorter = (a: WorkplaceTableEntry, b: WorkplaceTableEntry): number => {
  if (a.is_company_default) return -1;
  else if (b.is_company_default) return 1;
  else if (a.active && !b.active) return -1;
  else if (!a.active && b.active) return 1;
  else if (a.team_members.length > b.team_members.length) return -1;
  else if (a.team_members.length < b.team_members.length) return 1;
  else if (a.jobs.length > b.jobs.length) return -1;
  else if (a.jobs.length < b.jobs.length) return 1;
  return baseSensitiveCompare(a?.address_key, b?.address_key);
};
