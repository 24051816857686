import React, { useEffect, useState } from "react";
import ActivitiesTable from "dashboard/components/tables/ActivitiesTable";
import CustomActivitiesModal from "./CustomActivitiesModal";
import { useNavigate } from "react-router";
import { AggregatedJob } from "dashboard/miter";
import { BasicModal } from "ui";
import { useActiveCompany, useRefetchActivities } from "dashboard/hooks/atom-hooks";
import { shouldDisableJobActivityControl } from "dashboard/utils/jobUtils";

type Props = {
  job: AggregatedJob;
};

// This job is a sub-module of the Job page and enables users to view the Job's custom activities.
const JobActivities: React.FC<Props> = ({ job }) => {
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();
  const refetchActivities = useRefetchActivities();
  const companyName = activeCompany!.check_company.trade_name;

  const [infoModal, setInfoModal] = useState(false);

  const goToCompanyActivities = () => {
    navigate("/activities");
  };

  // Procore or Intacct
  const disableActivityControl = shouldDisableJobActivityControl(job);

  // Keep job's activities up to date by refreshing them when the custom activities screen loads
  useEffect(() => {
    if (job.activities) refetchActivities(job.activities);
  }, []);

  return (
    <div>
      {infoModal &&
        (disableActivityControl ? (
          <BasicModal
            headerText={"Sorry!"}
            bodyText={`As this job is imported from another system, you cannot toggle custom activities directly. It is controlled by the source data and the integration.`}
            button2Text={"Okay"}
            button2Action={() => setInfoModal(false)}
          />
        ) : (
          <CustomActivitiesModal job={job} hide={() => setInfoModal(false)} />
        ))}
      {job.custom_activities ? (
        <div>
          <div style={{ marginBottom: 15 }}>
            <span>Custom activities for this job are</span>&nbsp;
            <span className="blue-link bold" onClick={() => setInfoModal(true)}>
              enabled
            </span>
            .
          </div>
          <ActivitiesTable job={job} />
        </div>
      ) : (
        <div className="custom-activities-disabled-wrapper">
          <div style={{ fontSize: "17px" }}>
            <span>{`Custom activities for this job are disabled. This job will use `}</span>
            <span
              className="blue-link"
              onClick={goToCompanyActivities}
            >{`${companyName}'s company-level activities`}</span>
            .
          </div>
          <div style={{ marginTop: 30 }}>
            <button className="button-2" onClick={() => setInfoModal(true)}>
              Edit activities settings
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobActivities;
