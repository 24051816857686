import { ApprovalHistoryItem, ApprovalItem, ApprovalStage, ApproverGroup, PolicyRule } from "dashboard/miter";
import React, { useMemo } from "react";

import styles from "./ApprovalTimeline.module.css";
import { Assign } from "utility-types";
import { CheckCircle, Question, WarningCircle, XCircle } from "phosphor-react";
import {
  useLookupCompanyRoles,
  useLookupJob,
  useLookupPolicy,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { isTruthy, theme } from "miter-utils";
import { getStatusField, useTeamMemberGroupLabeler } from "dashboard/utils/approvals";
import ObjectID from "bson-objectid";
import { DateTime } from "luxon";
import { ApproverGroupValue } from "backend/services/approvals/types";

type ApprovalTimelineItem = Assign<
  ApprovalHistoryItem,
  {
    action: ApprovalHistoryItem["action"] | "future";
    pendingApproverGroups?: ApproverGroupValue[];
    condition?: "and" | "or";
    auto_approved?: boolean;
    policy_id?: string;
    policy_rule_id?: string;
    approver?: ApprovalHistoryItem["approver"];
    timestamp?: ApprovalHistoryItem["timestamp"];
  }
>;

type Props = { item: ApprovalItem };

export const ApprovalTimeline: React.FC<Props> = ({ item }) => {
  const lookupPolicy = useLookupPolicy();

  // Build the approval timeline
  const timeline = useMemo(() => {
    const timeline: ApprovalTimelineItem[] = [];
    if (item.approval_history) timeline.push(...item.approval_history);

    // If the item is not unapproved, return the timeline as is
    const statusField = getStatusField(item);
    if (item[statusField] !== "unapproved") return timeline;

    // Add a future timeline item for the pending approvers in the current stage of the item
    const pendingApproverGroups: ApproverGroupValue[] = item.approval_stage?.approvers?.pending || [];
    if (pendingApproverGroups.length) {
      timeline.push({
        _id: ObjectID().toHexString(),
        action: "future",
        pendingApproverGroups,
        condition: item.approval_stage?.condition,
        policy_id: item.approval_stage?.policy_id,
        policy_rule_id: item.approval_stage?.policy_rule_id,
      });
    }

    // Get the current stage of the item
    const curStage: ApprovalStage | null | undefined = item.approval_stage;
    if (!curStage) return timeline;

    const policy = lookupPolicy(curStage.policy_id);
    if (!policy) return timeline;

    const policyRule = (policy.rules as PolicyRule[]).find((rule) => rule._id === curStage.policy_rule_id);
    if (!policyRule) return timeline;

    // For each future stage, add a future timeline item for the pending approvers in that stage
    for (let i = curStage.index; i < policyRule.approval_flow.length; i++) {
      const currentStage = policyRule.approval_flow[i];

      const pendingApproverGroups: ApproverGroup[] = currentStage?.approvers || [];
      if (pendingApproverGroups.length) {
        timeline.push({
          _id: ObjectID().toHexString(),
          action: "future",
          pendingApproverGroups,
          condition: currentStage?.condition,
          policy_id: policy._id,
          policy_rule_id: policyRule._id,
        });
      }
    }

    return timeline;
  }, [item]);

  if (!timeline.length) return <></>;

  return (
    <div className={styles["approval-timeline"]}>
      {timeline
        .slice()
        .reverse()
        .map((timelineItem, index) => (
          <ApprovalTimelineItem
            key={timelineItem._id}
            timelineItem={timelineItem}
            index={index}
            item={item}
          />
        ))}
    </div>
  );
};

type ApprovalTimelineItemProps = {
  timelineItem: ApprovalTimelineItem;
  index: number;
  item: ApprovalItem;
};

/** Renders a single item in the approval timeline */
const ApprovalTimelineItem: React.FC<ApprovalTimelineItemProps> = ({ timelineItem, index, item }) => {
  const lookupTeam = useLookupTeam();
  const lookupCompanyRole = useLookupCompanyRoles();
  const lookupJob = useLookupJob();
  const { groupLabeler } = useTeamMemberGroupLabeler();

  const renderIcon = () => {
    switch (timelineItem.action) {
      case "approved":
        return <CheckCircle size={36} color={theme.colors.green} weight="fill" />;
      case "unapproved":
        return <XCircle size={36} color={theme.colors.yellow} weight="fill" />;
      case "denied":
        return <XCircle size={36} color={theme.colors.red} weight="fill" />;
      case "kick_back":
        return <WarningCircle size={36} color={theme.colors.yellow} weight="fill" />;
      case "future":
        return <Question size={36} color={theme.colors.grey} weight="fill" />;
    }
  };

  const renderTitle = () => {
    const approver = timelineItem.approver?.team_member_id
      ? lookupTeam(timelineItem.approver?.team_member_id)
      : lookupCompanyRole(timelineItem.approver?.role_id);

    const timestamp = timelineItem.timestamp;

    switch (timelineItem.action) {
      case "approved":
        if (!approver || !timestamp) return <>Approved</>;
        return `Approved by ${approver.full_name || approver.email}`;
      case "unapproved":
        if (!approver || !timestamp) return <>Unapproved</>;
        return `Unapproved by ${approver.full_name || approver.email}`;
      case "denied":
        if (!approver || !timestamp) return <>Denied</>;
        return `Denied by ${approver.full_name || approver.email}`;
      case "kick_back":
        if (!approver || !timestamp) return <>Kicked back</>;
        return `Kicked back by ${approver.full_name || approver.email}`;
      case "future":
        return "Awaiting approval";
      default:
        return <></>;
    }
  };

  const renderSubtitle = () => {
    if (timelineItem.action === "future") {
      const approverGroups = timelineItem.pendingApproverGroups;
      const approverGroupLabels =
        approverGroups
          ?.map((approver) => {
            const teamMemberId = getTeamMemberId(item);

            const teamMember = lookupTeam(teamMemberId);
            const job = "job" in item ? item.job : "job_id" in item ? lookupJob(item.job_id) : undefined;
            const department = "department" in item ? item.department : undefined;

            return groupLabeler(approver, {
              teamMember,
              job,
              department,
            });
          })
          .filter(isTruthy) || [];
      return approverGroupLabels.join(` ${timelineItem.condition} `);
    } else {
      const approverGroup = timelineItem.approver?.group;

      const timestamp = timelineItem.timestamp;
      if (!timestamp) return groupLabeler(approverGroup, { isAutoApproved: timelineItem.auto_approved });

      return (
        <>
          {groupLabeler(approverGroup, { isAutoApproved: timelineItem.auto_approved })}
          &nbsp;&nbsp;·&nbsp;&nbsp;
          {DateTime.fromSeconds(timestamp).toFormat("ff")}
        </>
      );
    }
  };

  return (
    <div className={styles["approval-timeline-item-container"]}>
      {index !== 0 && <div className={styles["approval-timeline-item-line"]} />}
      <div className={styles["approval-timeline-item"]}>
        <div className={styles["approval-timeline-item-icon"]}>{renderIcon()}</div>
        <div className={styles["approval-timeline-item-content"]}>
          <div className={styles["approval-timeline-item-content-title"]}>{renderTitle()}</div>
          <div className={styles["approval-timeline-item-content-subtitle"]}>{renderSubtitle()}</div>
        </div>
      </div>
    </div>
  );
};

const getTeamMemberId = (item: ApprovalItem) => {
  if ("team_member" in item) {
    if (typeof item.team_member === "string") return item.team_member;
    return item.team_member?._id;
  }

  if ("employee" in item) {
    if (typeof item.employee === "string") return item.employee;
    return item.employee?._id;
  }

  if ("team_member_id" in item) return item.team_member_id;
  return undefined;
};
