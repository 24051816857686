import React, { useState, useMemo } from "react";

import PayrollContext from "./payrollContext";
import { Badge, Button, ConfirmModal, TableV2, usdString } from "ui";
import {
  createObjectMap,
  getPaymentPayMethod,
  getPaymentTotals,
  getSupplementalTaxCalculationMethod,
  getWorkHoursInYear,
} from "dashboard/utils/utils";
import PaymentModal from "./PaymentModal/PaymentModal";
import Notifier from "dashboard/utils/notifier";
import { createNotOnboardedNotification } from "./copy";
import DeletePaymentsModal from "./DraftPayroll/DeletePaymentsModal";
import AddOffCyclePayments from "./DraftPayroll/AddOffCyclePayments";
import { convertAnnualRateToDisplayRateString } from "../../team-members/TeamUtils";
import { AutoCalculateBadge, buildAutoCalculateLabel, buildAutoCalculateTooltip } from "./AutoCalculateBadge";
import { BulkEarnings } from "./BulkEarnings";
import { EnhancedMiterPayment } from "../payrollTypes";

import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Eye, FileMinus, Pencil, PencilSimple, Plus, TrashSimple, X } from "phosphor-react";
import { VoidPaymentsModal } from "../VoidPaymentsModal";
import { IRowNode } from "ag-grid-community";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import PaymentSettingsModal from "./PaymentModal/PaymentSettingsModal";
import { useActiveCompany, useRefetchVendors } from "dashboard/hooks/atom-hooks";
import { capitalize } from "lodash";
import { baseSensitiveCompare, notNullish, useEnhancedSearchParams } from "miter-utils";
import { DEFAULT_WORK_HOURS_IN_YEAR } from "dashboard/utils/constants";
import { DateTime } from "luxon";
import { BulkPayrollOverrides } from "./BulkPayrollOverrides";
import { DropdownItem } from "ui/button/Button";
import { MiterAPI } from "dashboard/miter";
import { BulkUpdateParams } from "backend/utils";
import { UpdatePaperCheckNumberParams } from "backend/services/payroll-service";
import { CheckPayrollStatus } from "backend/utils/check/check-types";
import { useHasAccessToBillPay } from "dashboard/gating";
import pluralize from "pluralize";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";

export type PaymentsTableRow = {
  _id: string;
  tmId: string;
  first_name: string;
  last_name: string;
  pay_rate: string;
  payRateSorter: number;
  payment_method: string;
  hours: number;
  earnings: number;
  reimbursements: number;
  auto_earnings: string;
  net_pay: number;
  employment_type: "employee" | "contractor";
  pay_type: "hourly" | "salary";
  paper_check_number?: string | null;
  supplemental_tax_calc_method?: string | null;
  onboarded?: boolean;
  void_requested?: boolean | null;
  voided?: boolean | null;
  check_id?: string;
  payment_type: "employee" | "contractor";
};

type Props = {
  editing: boolean;
};

const PaymentsTable: React.FC<Props> = ({ editing }) => {
  const payrollContext = React.useContext(PayrollContext);
  const { payroll, isLoading, getPayroll } = payrollContext;
  const company = useActiveCompany();
  const check_payroll = payroll?.check_payroll;
  const { can } = useMiterAbilities();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const activePaymentTmId = parsedSearchParams.tmid;

  const hasAccessToBillPay = useHasAccessToBillPay();
  const refreshVendors = useRefetchVendors();

  // State
  const [isAddingPayments, setIsAddingPayments] = useState(false);
  const [selected, setSelected] = useState<PaymentsTableRow[]>([]);
  const [isDeletingPayments, setIsDeletingPayments] = useState(false);
  const [showBulkEarnings, setShowBulkEarnings] = useState(false);
  const [showBulkPayrollOverrides, setShowBulkPayrollOverrides] = useState(false);
  const [isChangingPaymentSettings, setIsChangingPaymentSettings] = useState(false);

  const [isVoidingPayments, setIsVoidingPayments] = useState(false);
  const [showVoidingPayments, setShowVoidingPayments] = useState(false);

  const [isMailingChecksModalOpen, setIsMailingChecksModalOpen] = useState(false);
  const { setFailures, renderFailuresModal } = useFailuresModal();

  const [isMailingChecksLoading, setIsMailingChecksLoading] = useState(false);

  const paymentLookup = useMemo(() => {
    return createObjectMap(payroll?.miter_payments || [], (mp) => mp._id) || {};
  }, [payroll]);

  const selectedPayments = useMemo(() => {
    return paymentLookup
      ? selected
          .map((s) => {
            return paymentLookup[s._id];
          })
          .filter(notNullish)
      : [];
  }, [selected, paymentLookup]);

  const tmsSelected = useMemo(() => {
    const selectedIds = selected.map((p) => p._id);
    return (
      payroll?.miter_payments.filter((mp) => selectedIds.includes(mp._id)).map((mp) => mp.team_member._id) ||
      []
    );
  }, [payroll, selected]);

  const createTooltipText = (payment: EnhancedMiterPayment) => {
    const checkItemStatus = (payment.check_item || payment.check_cp)?.status;
    const unacknowledgedWarnings = !!payment.warnings?.filter((w) => !w.acknowledged).length;
    const checkWarnings = !!payment.check_item?.warnings?.length;

    let tooltipText: string | undefined;
    let dotClass = "orange-muted";

    if (payment.voided) {
      tooltipText = "This payment was voided";
    } else if (checkItemStatus === "failed" || checkItemStatus === "partially_paid") {
      const statusText = checkItemStatus === "partially_paid" ? " partially " : " ";
      tooltipText = `This payment${statusText}failed. Click into the payment's summary tab to take action.`;
    } else if ((unacknowledgedWarnings && check_payroll?.status === "draft") || checkWarnings) {
      tooltipText = "This payment has warnings.";
      dotClass = "yellow";
    } else if (!payment.onboarded) {
      tooltipText = `${payment.team_member.first_name} is not onboarded`;
      dotClass = "red-muted";
    }

    return { tooltipText, dotClass };
  };

  const formatName = (payment: EnhancedMiterPayment) => {
    const checkItemStatus = (payment.check_item || payment.check_cp)?.status;
    const unacknowledgedWarnings = !!payment.warnings?.filter((w) => !w.acknowledged).length;
    const checkWarnings = !!payment.check_item?.warnings?.length;

    let tooltipText: string | undefined;
    let dotClass = "orange-muted";

    // We didn't always have `first_name` and `last_name` on our team member objects, so we backstop to the Check versions
    const firstName = payment.team_member.first_name || payment.team_member.check_tm?.first_name;

    if (checkItemStatus === "failed" || checkItemStatus === "partially_paid") {
      const statusText = checkItemStatus === "partially_paid" ? " partially " : " ";
      tooltipText = `This payment${statusText}failed. Click into the payment's summary tab to take action.`;
    } else if ((unacknowledgedWarnings && check_payroll?.status === "draft") || checkWarnings) {
      tooltipText = "This payment has warnings.";
      dotClass = "yellow";
    } else if (!payment.onboarded) {
      tooltipText = `${firstName} is not onboarded`;
      dotClass = "red-muted";
    }
    const { void_requested: voidRequested, check_cp, check_item } = payment;
    const hasBeenVoided = check_cp?.voided_by || check_item?.voided_by;

    return (
      <div className="flex">
        {firstName}
        {tooltipText && <div className={dotClass + " dot"} style={{ marginLeft: 8 }} />}
        {(voidRequested || hasBeenVoided) && (
          <>
            {hasBeenVoided ? (
              <Badge color="orange" text="Voided" />
            ) : (
              <Badge color="yellow" text="Void requested" />
            )}
          </>
        )}
      </div>
    );
  };

  const createRate = (payment: EnhancedMiterPayment) => {
    if (!payment.team_member.pay_type || payment.team_member.pay_rate == null) {
      return "N/A";
    }
    let payRateString: string;
    if (payment.team_member.pay_type === "hourly") {
      payRateString = usdString(payment.team_member.pay_rate);
      if (payRateString.slice(-2) === "00") payRateString = payRateString.slice(0, -3);
      payRateString += "/hour";
      return payRateString;
    } else {
      payRateString = convertAnnualRateToDisplayRateString(
        company,
        payment.team_member.salary_rate_display || undefined,
        payment.team_member.pay_rate
      );

      return payRateString;
    }
  };

  const moveTmId = (direction: "previous" | "next") => {
    if (!payroll) return;
    const currentTmIndex = payroll.miter_payments.findIndex(
      (payment) => payment.team_member._id === activePaymentTmId
    );
    const indexDifference = direction === "previous" ? -1 : 1;

    let indexToMove = currentTmIndex + indexDifference;
    let payment = payroll.miter_payments[indexToMove];
    while (!payment?.onboarded && indexToMove > 0 && indexToMove < payroll.miter_payments.length - 1) {
      indexToMove = indexToMove + indexDifference;
      payment = payroll.miter_payments[indexToMove];
    }

    if (indexToMove < 0 || indexToMove >= payroll.miter_payments.length || !payment?.onboarded) {
      if (payment && !payment?.onboarded) {
        const errorNotice = createNotOnboardedNotification(
          payment.team_member,
          check_payroll?.status === "draft"
        );
        Notifier.error(errorNotice, { duration: 4000 });
      }
      setSearchParams({ tmid: undefined });
    } else {
      setSearchParams({ tmid: payment?.team_member._id });
    }
  };

  const getPaymentsTablePosition = (): "first" | "last" | undefined => {
    if (!payroll) return;
    const currentTmIndex = payroll?.miter_payments.findIndex(
      (payment) => payment.team_member._id === activePaymentTmId
    );
    if (currentTmIndex === 0) {
      return "first";
    } else if (currentTmIndex >= payroll.miter_payments.length - 1) {
      return "last";
    }
  };

  const tableData: PaymentsTableRow[] = useMemo(() => {
    return (payroll?.miter_payments || []).map((payment) => {
      const paymentMethod = getPaymentPayMethod(payment);
      const totals = getPaymentTotals(payment);
      const workHoursInYear = company ? getWorkHoursInYear(company) : DEFAULT_WORK_HOURS_IN_YEAR;
      const payRateSorter = payment.team_member.pay_rate
        ? payment.team_member.pay_rate / (payment.team_member.pay_type === "salary" ? workHoursInYear : 1)
        : 0;

      // In old payrolls, the cached team member objects don't have `first_name` or `last_name`
      // directly on our model,so we backstop with the Check versions, which have always existed
      const firstName = payment.team_member.first_name || payment.team_member.check_tm?.first_name || "";
      const lastName = payment.team_member.last_name || payment.team_member.check_tm?.last_name || "";

      let paperCheckNumber;

      if (payment.check_item) {
        paperCheckNumber = payment.check_item.paper_check_number;
      } else if (payment.check_cp) {
        paperCheckNumber = payment.check_cp.paper_check_number;
      }

      const method = getSupplementalTaxCalculationMethod(payment, company);
      const supplementalTaxCalcMethod = capitalize(method);

      return {
        _id: payment._id,
        tmId: payment.team_member._id,
        first_name: firstName,
        last_name: lastName,
        pay_rate: createRate(payment),
        payRateSorter,
        payment_method: paymentMethod === "manual" ? "Paper check" : "Default",
        hours: totals.hours,
        earnings: totals.earnings,
        reimbursements: totals.reimbursements,
        auto_earnings: payment.onboarded ? buildAutoCalculateLabel(payment) : "-",
        net_pay: totals.net_pay,
        employment_type: payment.team_member.employment_type,
        pay_type: payment.team_member.pay_type || "hourly",
        paper_check_number: paperCheckNumber,
        short_id: payment.short_id,
        supplemental_tax_calc_method: supplementalTaxCalcMethod,
        friendly_id: payment.team_member?.friendly_id,
        onboarded: payment.onboarded,
        void_requested: payment.void_requested,
        voided: payment.voided,
        check_id: payment.check_item?.id || payment.check_cp?.id,
        payment_type: payment.payment_type,
      };
    });
  }, [payroll]);

  const columns = useMemo(() => {
    const cols: ColumnConfig<PaymentsTableRow>[] = [
      {
        field: "first_name",
        headerName: "First name",
        dataType: "string",
        minWidth: 100,
        tooltipValueGetter: (params) => {
          const paymentId = params.data?._id;
          if (!paymentId || paymentId === "totals_row" || !paymentLookup[paymentId]) return;
          return createTooltipText(paymentLookup[paymentId]!)?.tooltipText;
        },
        cellRenderer: (params) => {
          const paymentId = params.data?._id;
          if (!paymentId || paymentId === "totals_row" || !paymentLookup[paymentId]) return;
          return formatName(paymentLookup[paymentId]!);
        },
        valueFormatter: (params) => params.value,
        useValueFormatterForExport: true,
        comparator: (valueA, valueB) => baseSensitiveCompare(valueA, valueB),
      },
      {
        field: "last_name",
        headerName: "Last name",
        dataType: "string",
        minWidth: 100,
        comparator: (valueA, valueB) => baseSensitiveCompare(valueA, valueB),
      },
      {
        field: "auto_earnings",
        headerName: "Auto-Calculate",
        dataType: "component",
        maxWidth: 140,

        cellRenderer: (params) => {
          const paymentId = params.data?._id;
          if (!paymentId || paymentId === "totals_row" || !paymentLookup[paymentId]) return;
          if (!params.data.onboarded) return "-";

          return (
            <AutoCalculateBadge
              payment={paymentLookup[paymentId]!}
              useStateAsText={true}
              hideTooltip={true}
            />
          );
        },
        tooltipValueGetter: (params) => {
          const paymentId = params.data?._id;
          if (!paymentId || paymentId === "totals_row" || !paymentLookup[paymentId]) return;
          if (!params.data?.onboarded) return;

          return buildAutoCalculateTooltip(paymentLookup[paymentId]!);
        },
      },
      {
        field: "pay_rate",
        headerName: "Pay rate",
        dataType: "string",
        comparator: (v1, v2, node1, node2) => {
          const a = paymentLookup[node1.data?._id || ""];
          const b = paymentLookup[node2.data?._id || ""];
          const workHoursInYear = company ? getWorkHoursInYear(company) : DEFAULT_WORK_HOURS_IN_YEAR;
          return payRateComparator(workHoursInYear, a, b);
        },
        maxWidth: 120,
        cellRenderer: (params) => {
          const paymentId = params.data?._id;
          if (!paymentId || paymentId === "totals_row" || !paymentLookup[paymentId]) return;
          return createRate(paymentLookup[paymentId]!);
        },
      },
      {
        field: "payment_method",
        headerName: "Payment method",
        dataType: "string",
        minWidth: 120,
      },
      {
        field: "hours",
        headerName: "Hours",
        dataType: "number",
        maxWidth: 100,
        sumRow: true,
        valueFormatter: (params) =>
          params?.value != null ? new Intl.NumberFormat("en-US").format(params.value) : "-",
      },
      {
        field: "earnings",
        headerName: "Earnings",
        dataType: "number",
        unit: "dollar",
        maxWidth: 150,
        sumRow: true,
      },
      {
        field: "reimbursements",
        headerName: "Reimbursements",
        dataType: "number",
        unit: "dollar",
        maxWidth: 150,
        sumRow: true,
      },
      {
        field: "employment_type",
        headerName: "Employment type",
        initialHide: true,
        filter: true,
        valueGetter: (params) => {
          if (!params.data?.employment_type) return "";
          return params.data.employment_type === "employee" ? "Employee" : "Contractor";
        },
      },
      {
        field: "pay_type",
        headerName: "Pay type",
        dataType: "string",
        enableRowGroup: true,
        valueGetter: (params) => {
          if (!params.data || params.data?._id === "totals_row") return "";
          return params.data?.pay_type === "salary" ? "Salary" : "Hourly";
        },
        hide: true,
      },
      {
        field: "supplemental_tax_calc_method",
        headerName: "Supplemental tax method",
        dataType: "string",
        minWidth: 120,
        hide: true,
        enableRowGroup: true,
        headerTooltip:
          "Method used to calculate taxes on supplemental earnings. Either flat 22% or earnings are aggregated and taxed normally",
      },
      {
        field: "friendly_id",
        headerName: "Team member ID",
        dataType: "string",
        enableRowGroup: true,
        hide: true,
      },
    ];
    if (!editing) {
      cols.push(
        {
          field: "net_pay",
          headerName: "Net pay",
          dataType: "number",
          unit: "dollar",
          sumRow: true,
        },
        {
          field: "short_id",
          headerName: "Payment ID",
          dataType: "string",
          hide: true,
        },
        {
          field: "paper_check_number",
          headerName: "Paper check number",
          hide: false,
          editable: (params) => {
            return params.data?.payment_method === "Paper check" && !!params.data?.check_id;
          },
          editorType: "text",
          validations: (value) => validateCheckNumber(value),
        }
      );
    }
    return cols;
  }, [payroll?.type, paymentLookup, company, editing]);

  const validateCheckNumber = (checkNum: string | null) => {
    if (!!checkNum && checkNum.length > 10) {
      return "Check number must be 10 characters or less";
    }

    return true;
  };

  // Handlers
  const handleRowClick = (payment: PaymentsTableRow) => {
    if (!payment.onboarded && paymentLookup[payment._id]) {
      const errorNotice = createNotOnboardedNotification(
        paymentLookup[payment._id]!.team_member,
        check_payroll?.status === "draft"
      );
      Notifier.error(errorNotice, { duration: 4000 });
    } else {
      setSearchParams({ tmid: payment.tmId });
    }
  };

  const hide = () => {
    setSearchParams({}, { replaceParams: true });
  };

  const hideDeletePaymentsModal = () => {
    setSelected([]);
    setIsDeletingPayments(false);
  };

  const handleVoidPayments = async () => {
    // Check doesn't want us to auto-request voids for payrolls in prior quarters anymore. Instead we have to go through the corrections process
    const payday = payroll?.check_payroll.payday;
    let voidDisabled = false;
    if (payday) {
      const endOfPayrollQtr = DateTime.fromISO(payday, { zone: "America/New_York" }).endOf("quarter");
      voidDisabled = DateTime.now() > endOfPayrollQtr;
    }
    if (voidDisabled) {
      Notifier.error(
        "Voiding payments for payrolls in prior quarters is not allowed. Please contact support for assistance."
      );
    } else {
      setIsVoidingPayments((p) => !p);
    }
  };

  const handlePaperCheckMailingBillCreation = async () => {
    setIsMailingChecksLoading(true);
    const selectedPaymentIds = selected.map((p) => p._id);
    try {
      const response = await MiterAPI.bills.mail_paychecks(selectedPaymentIds);
      if (response.error) throw new Error(response.error);

      // if any of the bills failed to create, show an error
      if (response.failures) {
        const mappedFailureItems = response.failures.map((failure) => {
          const failureLabel = failure._id;
          return {
            label: failureLabel || "",
            message: failure.message,
          };
        });
        setFailures(mappedFailureItems);
      }

      // if there are any successes, notify the user
      const numSuccesses = response.successes.length;
      if (numSuccesses > 0) {
        Notifier.success(
          `Created ${numSuccesses} unpaid ${pluralize("bill", numSuccesses)} for the selected ${pluralize(
            "payment",
            selected.length
          )}. Navigate to the Bills page to view and process them.`
        );

        // refresh vendors list, new ones could be created
        refreshVendors();
        setIsMailingChecksModalOpen(false);
      }
    } catch (err) {
      Notifier.error("There was an error mailing checks for the selected payments.");
    }
    setIsMailingChecksLoading(false);
  };

  const handleSave = async (edits: PaymentsTableRow[]) => {
    // currently only support editing paper check numbers on the payment table
    const results = { successes: [], errors: [] };

    try {
      if (!payroll) {
        Notifier.error("Error saving. Cannot find payroll.");
        return results;
      }

      const updates: BulkUpdateParams<UpdatePaperCheckNumberParams> = edits
        .filter((payment) => payment.check_id)
        .map((paymentEdit) => {
          const paperCheckNumber = paymentEdit.paper_check_number;
          return {
            _id: paymentEdit._id,
            params: {
              check_num: paperCheckNumber || "",
              check_id: paymentEdit.check_id!,
              payment_type: paymentEdit.payment_type,
            },
          };
        });

      const response = await MiterAPI.payrolls.bulk_add_paper_check_num(payroll._id, updates);
      if (response.error) throw new Error(response.error);

      await getPayroll();
      return response || results;
    } catch (e: $TSFixMe) {
      Notifier.error(`There was an error updating paper check numbers`);
    }
  };

  const hideVoidPaymentsModal = () => {
    setIsVoidingPayments(false);
    setSelected([]);
    setShowVoidingPayments(false);
  };

  const staticActions = useMemo(() => {
    const canSeeOverrides =
      payroll?.type === "regular" ||
      payroll?.check_payroll.off_cycle_options?.apply_benefits ||
      payroll?.check_payroll.off_cycle_options?.apply_post_tax_deductions;

    const dropdownItems: DropdownItem[] = [
      {
        key: "earnings",
        text: "Manual earnings",
        onClick: () => setShowBulkEarnings(true),
      },
      {
        key: "overrides",
        text: "Payroll overrides",
        onClick: () => setShowBulkPayrollOverrides(true),
      },
    ];

    const bulkEditAction: TableActionLink = canSeeOverrides
      ? {
          action: () => {},
          label: "",
          key: "bulk-edit",
          className: "button-1",
          important: true,
          shouldShow: () => can("payrolls:update") && !isVoidingPayments,
          component: (
            <Button
              text={editing ? "Bulk edit" : "Show bulk edits"}
              style={{ height: 32, marginRight: 0 }}
              dropdownItems={dropdownItems}
            />
          ),
        }
      : {
          label: (editing ? "Edit" : "Show") + " manual earnings",
          className: "button-1 table-button",
          action: () => setShowBulkEarnings(true),
          icon: editing ? (
            <Pencil weight="bold" style={{ marginRight: 3 }} />
          ) : (
            <Eye weight="bold" style={{ marginRight: 5 }} />
          ),
          important: true,
          shouldShow: () => can("payrolls:update") && !isVoidingPayments,
        };

    const actions: TableActionLink[] = [
      bulkEditAction,
      {
        label: isVoidingPayments ? "Cancel" : "Void payments",
        className: "button-1 table-button",
        action: handleVoidPayments,
        icon: isVoidingPayments ? (
          <X weight="bold" style={{ marginRight: 3 }} />
        ) : (
          <FileMinus weight="bold" style={{ marginRight: 3 }} />
        ),
        important: true,
        shouldShow: () =>
          can("payrolls:void") && !!payroll?.check_payroll?.status?.includes("paid") && !payroll.void_of,
      },
      {
        label: "Add",
        className: "button-2 table-button",
        action: () => setIsAddingPayments(true),
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        important: true,
        shouldShow: () =>
          can("payrolls:update") &&
          payroll?.check_payroll?.status === "draft" &&
          payroll.type === "off_cycle" &&
          !!editing,
      },
    ];
    return actions;
  }, [payroll, can, isVoidingPayments]);

  const dynamicActions: TableActionLink[] = useMemo(() => {
    if (can("payrolls:void") && isVoidingPayments) {
      return [
        {
          label: "Void",
          className: "button-2 table-button",
          action: () => setShowVoidingPayments(true),
          icon: <FileMinus weight="bold" style={{ marginRight: 3 }} />,
        },
        {
          label: "Cancel",
          className: "button-1 table-button",
          action: hideVoidPaymentsModal,
          icon: <X weight="bold" style={{ marginRight: 3 }} />,
        },
      ];
    }

    const paymentActions =
      payroll?.check_payroll?.status === "draft" && editing
        ? [
            {
              label: "Edit payment settings",
              className: "button-1 table-button",
              action: () => setIsChangingPaymentSettings(true),
              icon: <PencilSimple weight="bold" style={{ marginRight: 5 }} />,
              shouldShow: () => can("payrolls:update"),
            },
            {
              label: "Delete",
              className: "button-3 no-margin table-button",
              action: () => setIsDeletingPayments(true),
              icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
              shouldShow: () => can("payrolls:update") && payroll?.type === "off_cycle",
            },
          ]
        : [];

    const mailPaperCheckAction = {
      label: "Mail paper check",
      className: "button-1 table-button",
      action: () => setIsMailingChecksModalOpen(true),
      icon: <FileMinus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => {
        return (
          can("payrolls:update") &&
          !["draft", "failed"].includes(check_payroll?.status as CheckPayrollStatus) &&
          hasAccessToBillPay
        );
      },
    };

    return [...paymentActions, mailPaperCheckAction];
  }, [payroll, can, isVoidingPayments, editing]);

  const renderVoidingPaymentsModal = () => {
    if (!payroll || !selected.length) return;

    return (
      <VoidPaymentsModal
        payroll={payroll}
        paymentIds={selected.map((s) => s._id)}
        onHide={hideVoidPaymentsModal}
      />
    );
  };
  const rowSelectDisabled = useMemo(() => {
    if (isVoidingPayments) return shouldRowSelectBeDisabledForVoids;

    // only allow selecting payments if they are paper checks
    if (check_payroll?.status !== "draft") return shouldRowSelectBeDisabledForCheckMailing;
  }, [isVoidingPayments, payroll]);

  return (
    <>
      {isDeletingPayments && <DeletePaymentsModal hide={hideDeletePaymentsModal} selected={tmsSelected} />}
      {isAddingPayments && <AddOffCyclePayments hide={() => setIsAddingPayments(false)} />}
      {isChangingPaymentSettings && (
        <PaymentSettingsModal
          selectedPayments={selectedPayments}
          hide={() => setIsChangingPaymentSettings(false)}
        />
      )}
      <TableV2
        id={"payroll-payments-table"}
        resource="payments"
        customEmptyStateMessage={
          "No payments: Add payments to this payroll by clicking the 'Add' button above."
        }
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onClick={handleRowClick}
        isLoading={isLoading}
        onSelect={setSelected}
        defaultSelectedRows={selected}
        showTotals={true}
        rowSelectDisabled={rowSelectDisabled}
        gridWrapperStyle={{ height: "60vh" }}
        containerStyle={{ paddingTop: 0 }}
        paginationPageSize={500}
        editable={check_payroll?.status !== "draft"}
        onSave={handleSave}
      />
      {activePaymentTmId && (
        <PaymentModal
          tmId={activePaymentTmId}
          moveToTeamMember={moveTmId}
          paymentsTablePosition={getPaymentsTablePosition()}
          editing={editing}
          hide={hide}
        />
      )}
      {showBulkEarnings && <BulkEarnings readOnly={!editing} onFinish={() => setShowBulkEarnings(false)} />}
      {showBulkPayrollOverrides && (
        <BulkPayrollOverrides editing={editing} onFinish={() => setShowBulkPayrollOverrides(false)} />
      )}
      {showVoidingPayments && renderVoidingPaymentsModal()}
      {isMailingChecksModalOpen && (
        <ConfirmModal
          title={`Mail paper ${pluralize("check", selected.length)}?`}
          yellowBodyText={true}
          body={
            <div>
              A new bill will be created in Bill Pay for each payment selected. Each team member will be
              created as a vendor (if they do not exist already).
              <br />
              <br />
              If you added a check number to the payment, it will be used. If not, one will be generated for
              you.
              <br />
              <br />
              Navigate to Expense Management to process the newly created bills.
            </div>
          }
          onYes={handlePaperCheckMailingBillCreation}
          onNo={() => setIsMailingChecksModalOpen(false)}
          loading={isMailingChecksLoading}
        />
      )}
      {renderFailuresModal()}
    </>
  );
};

export default PaymentsTable;

const payRateComparator = (workHoursInYear: number, a?: EnhancedMiterPayment, b?: EnhancedMiterPayment) => {
  if (!a || !b) return 0;
  const aRate = (a.team_member.pay_rate || 0) / (a.team_member.pay_type === "salary" ? workHoursInYear : 1);
  const bRate = (b.team_member.pay_rate || 0) / (b.team_member.pay_type === "salary" ? workHoursInYear : 1);
  return aRate - bRate;
};

const shouldRowSelectBeDisabledForVoids = (row: IRowNode<PaymentsTableRow>) => {
  return !!row.data?.void_requested || !!row.data?.voided;
};

const shouldRowSelectBeDisabledForCheckMailing = (row: IRowNode<PaymentsTableRow>) => {
  return row.data?.payment_method !== "Paper check";
};
