import { buildFlatfileMessage, normalizeDate } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { ImportField, Importer } from "../importer/Importer";
import { useImportValidators } from "dashboard/hooks/flatfile-import/useImportValidators";
import { useActiveCompanyId, useJobPostings } from "dashboard/hooks/atom-hooks";
import { FlatfileResults } from "@flatfile/react";
import { Notifier } from "ui";
import { BulkCreateSingleJobApplicationParams, JobApplication } from "dashboard/types/ats";
import { MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";

type JobApplicationImportParams = {
  /** Candidate */
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;

  /** Job Application */
  applyDate: string;
  jobPostingTitle?: string;
  requisitionId?: string;
  status: Omit<JobApplication["status"], "in_review"> | "in review";
  currentJobTitle?: string;
};

type Props = {
  onFinish: () => void;
};

export const JobApplicationImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/

  const activeCompanyId = useActiveCompanyId();
  const { validatePhone, validateEmail } = useImportValidators();
  const jobPostings = useJobPostings();

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/

  const buildSubmitParams = (row: JobApplicationImportParams): BulkCreateSingleJobApplicationParams => {
    if (!activeCompanyId) throw new Error("No active company ID");

    // Use job posting requisition id first
    const jobPostingId =
      (row.requisitionId && jobPostings.find((jp) => jp.requisition_id === row.requisitionId)?._id) ||
      (row.jobPostingTitle && jobPostings.find((jp) => jp.title === row.jobPostingTitle)?._id);

    if (!jobPostingId) {
      throw new Error(
        `No job posting found with title ${row.jobPostingTitle} or requisition ID "${row.requisitionId}".`
      );
    }

    return {
      first_name: row.firstName,
      last_name: row.lastName,
      phone: row.phone,
      email: row.email,
      applied_on: DateTime.fromISO(row.applyDate).toSeconds(),
      job_posting_id: jobPostingId,
      status: row.status === "in review" ? "in_review" : (row.status as JobApplication["status"]),
      current_job_title: row.currentJobTitle || "No job title",
      company_id: activeCompanyId,
    };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedJobApplications = results.validData.map(buildSubmitParams);

      const response = await MiterAPI.job_applications.import({
        clean_inputs: preppedJobApplications,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;
      const warnings = response.results.warnings.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(
            `Imported ${successes} job applications with ${errors} errors and ${warnings} warnings.`
          );
        } else {
          Notifier.success(`Imported ${successes} job applications with ${warnings} warnings.`);
        }
      } else {
        Notifier.error(`There were ${errors} errors and ${warnings} warnings.`);
      }

      onFinish();
    } catch (e: $TSFixMe) {
      Notifier.error("There was an error creating the job applications, ", e.message);
    }
  };

  const validateStatus = (status: string) => {
    const statusLower = status.toLowerCase();
    if (!["applied", "in_review", "interviewing", "offer", "hired", "rejected"].includes(statusLower)) {
      return buildFlatfileMessage("Please enter a valid status.", status, "error");
    }
    return { value: statusLower };
  };

  const validateJobPostingTitle = (jpTitle?: string) => {
    if (jpTitle) {
      const numMatchingTitles = jobPostings.filter((jp) => jp.title === jpTitle).length;
      if (numMatchingTitles !== 1) {
        return buildFlatfileMessage(
          `${numMatchingTitles} jobs with this title were found.`,
          jpTitle,
          "error"
        );
      }
    }

    return { value: jpTitle };
  };

  const validateJobPostingReqId = (reqId?: string) => {
    if (reqId) {
      if (!jobPostings.some((jp) => jp.requisition_id === reqId)) {
        return buildFlatfileMessage("Requisition ID not found.", reqId, "error");
      }
    }

    return { value: reqId };
  };

  validateJobPostingTitle;

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "First name",
        type: "string",
        key: "firstName",
        description: "First name of the candidate.",
        validators: [{ validate: "required" }],
      },
      {
        label: "Last name",
        type: "string",
        key: "lastName",
        description: "Last name of the candidate.",
        validators: [{ validate: "required" }],
      },
      {
        label: "Phone",
        type: "string",
        key: "phone",
        validators: [
          { validate: "required" },
          {
            validate: "regex_matches" as const,
            regex: "^\\+?[1-9]\\d{1,14}$",
            error: "Must be a valid phone number.",
          },
        ],
        hook: (val) =>
          typeof val === "string" ? validatePhone(val, false) : validatePhone(val.phone, false),
      },
      {
        label: "Email",
        type: "string",
        key: "email",
        validators: [
          { validate: "required" },
          {
            validate: "regex_matches" as const,
            regex:
              "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])",
            error: "Must be a valid email address.",
          },
        ],
        hook: (val) =>
          typeof val === "string" ? validateEmail(val, false) : validateEmail(val.email, false),
      },
      {
        label: "Current title",
        type: "string",
        key: "currentJobTitle",
        description: "Job title of the applicant.",
      },
      {
        label: "Application date",
        type: "string",
        key: "applyDate",
        description: "The date the application was submitted.",
        validators: [{ validate: "required" }],
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.date)),
      },
      {
        label: "Application job title",
        type: "string",
        key: "jobPostingTitle",
        description: "Title of job posting. Required if requisition ID is not provided.",
        validators: [
          {
            validate: "required_without" as const,
            fields: ["requisitionId"],
          },
        ],
        hook: (row) =>
          typeof row === "string"
            ? validateJobPostingTitle(row)
            : validateJobPostingTitle(row.jobPostingTitle),
      },
      {
        label: "Requisition ID",
        type: "string",
        key: "requisitionId",
        description: "Requisition ID of the posting. Required if job title is not provided.",
        validators: [
          {
            validate: "required_without" as const,
            fields: ["jobPostingTitle"],
          },
        ],
        hook: (row) =>
          typeof row === "string" ? validateJobPostingReqId(row) : validateJobPostingReqId(row.requisitionId),
      },
      {
        label: "Application status",
        type: "select",
        options: [
          { value: "applied", label: "Applied" },
          { value: "interviewing", label: "Interviewing" },
          { value: "in_review", label: "In review", alternates: ["in_review"] },
          { value: "offer", label: "Offer" },
          { value: "hired", label: "Hired" },
          { value: "rejected", label: "Rejected" },
        ],
        key: "status",
        description: "Options: applied, in review, interviewing, offer, hired, rejected.",
        hook: (row) => (typeof row === "string" ? validateStatus(row) : validateStatus(row.status)),
      },
    ];

    return fieldList;
  }, []);

  return (
    <Importer
      id="job applications"
      resource="job applications"
      onSave={handleSubmit}
      fields={fields}
      title="Bulk import job applications"
    />
  );
};
