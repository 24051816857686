import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "backend/models";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { NetSuiteConnectionMetadata, SuiteAppOptions } from "backend/services/netsuite/types/netsuite-types";
import { useGetSubsidiaries } from "dashboard/hooks/integrations/netsuite/useGetSubsidiaries";
import { SettingsCard, Formblock, Loader } from "ui";
import { baseSensitiveCompare } from "miter-utils";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import {
  NETSUITE_INPUT_LENGTH,
  DEFAULT_NETSUITE_LABEL_STYLE,
  SUITEAPP_CONFIGURATION_MAP,
  suiteAppOptions,
} from "./constants";

export const NetSuiteConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const { loading, subsidiaries } = useGetSubsidiaries(integration);
  const { defaultSubsidiary, suiteApp } = integration.connection?.metadata?.netsuite || {};

  const updateSubsidiary = async (selectedId: string | undefined) => {
    const subsidiary = subsidiaries.find((subsidiary) => subsidiary.id === selectedId);

    await updateNetSuiteMetadata({ defaultSubsidiary: subsidiary });
  };

  const updateNetSuiteMetadata = async (
    update: DeepPartial<NetSuiteConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { netsuite: update } };
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(raw, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateIntegrationConnection(flattened);
  };

  const setSuiteAppOption = async (value?: SuiteAppOptions): Promise<void> => {
    const configuration = value ? SUITEAPP_CONFIGURATION_MAP[value] : undefined;

    await updateNetSuiteMetadata({ queryTypes: configuration, suiteApp: value });
  };

  const subsidiaryOptions = useMemo(() => {
    return subsidiaries
      .map((subsidiary) => ({ label: subsidiary.fullname, value: subsidiary.id }))
      .sort((a, b) => baseSensitiveCompare(a.label, b.label));
  }, [subsidiaries]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Journal Entries">
        {loading ? (
          <Loader />
        ) : (
          <Formblock
            form={form}
            underlineTooltip={true}
            inputProps={{ style: { width: NETSUITE_INPUT_LENGTH } }}
            name="defaultSubsidiary"
            type="select"
            labelInfo="The subsidiary you are using on NetSuite for this Miter company."
            label="Subsidiary"
            options={subsidiaryOptions}
            defaultValue={defaultSubsidiary?.id}
            onChange={(o) => updateSubsidiary(o.value)}
            labelStyle={DEFAULT_NETSUITE_LABEL_STYLE}
            editing={true}
          />
        )}
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: NETSUITE_INPUT_LENGTH } }}
          name="suiteAppSelection"
          type="select"
          labelInfo="The Suite App you are using on NetSuite (if any)."
          label="SuiteApp"
          isClearable
          options={suiteAppOptions}
          defaultValue={suiteApp || "none"}
          onChange={(o) => setSuiteAppOption(o?.value || null)}
          labelStyle={DEFAULT_NETSUITE_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
    </div>
  );
};
