import React, { useMemo, useState } from "react";
import { AggregatedTeamMember, TeamMemberOnLeave } from "dashboard/miter";
import { Helmet } from "react-helmet";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { LeaveOfAbsenceModal } from "../team-members/LeaveOfAbsenceModal";
import { useRefetchTeam, useLookupLeaveType } from "dashboard/hooks/atom-hooks";

type Props = {
  employee: AggregatedTeamMember;
};

const LeaveOfAbsenceTable: React.FC<Props> = ({ employee }) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState<TeamMemberOnLeave>();

  const refetchTeam = useRefetchTeam();
  const leaveTypeLookup = useLookupLeaveType();

  const data = useMemo(() => {
    return employee.on_leave_history?.sort((a, b) => {
      return b.start_date.localeCompare(a.start_date);
    });
  }, [employee]);

  const leaveColumns = useMemo(() => {
    const cols: ColumnConfig<TeamMemberOnLeave>[] = [
      {
        field: "start_date",
        headerName: "Start date",
        dataType: "date",
        dateType: "iso",
      },
      {
        field: "end_date",
        headerName: "End date",
        dataType: "date",
        dateType: "iso",
      },
      {
        field: "type",
        headerName: "Type",
        dataType: "string",
        valueFormatter: (params) => {
          if (!params?.data?.type) return "";
          return leaveTypeLookup(params.data.type)?.label || "";
        },
      },
      {
        field: "config.pause_payroll",
        headerName: "Pause payroll",
        dataType: "boolean",
      },
      {
        field: "config.pause_notifications",
        headerName: "Pause notifications",
        dataType: "boolean",
        width: 200,
      },
      {
        field: "config.time_off.pause_accruals",
        headerName: "Pause fixed accruals",
        dataType: "boolean",
        width: 200,
      },
      {
        field: "config.time_off.pause_applying_carryover_limits",
        headerName: "Pause carryover limits",
        dataType: "boolean",
        width: 220,
      },
      {
        field: "config.time_off.pause_level_changes",
        headerName: "Pause level changes",
        dataType: "boolean",
        width: 200,
      },
    ];
    return cols;
  }, [employee]);

  const handleRowClick = (row: TeamMemberOnLeave) => {
    setSelected(row);
    setShowModal(true);
  };
  const hideModal = () => {
    setSelected(undefined);
    setShowModal(false);
    refetchTeam(employee._id);
  };

  const renderTable = () => {
    return (
      <>
        {showModal && <LeaveOfAbsenceModal tm={employee} hide={hideModal} leave={selected} />}
        <TableV2
          id={"leave-of-absence-table"}
          resource="leave of absence"
          data={data}
          showReportViews={true}
          columns={leaveColumns}
          onClick={handleRowClick}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Leave of absence | Miter</title>
      </Helmet>
      <div>{renderTable()}</div>
    </>
  );
};

export default LeaveOfAbsenceTable;
