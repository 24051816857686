import { MiterIntegrationForCompany } from "dashboard/miter";
import { NETSUITE_ENABLED_SYNCS_BY_SUITEAPP } from "./constants";
import { IntegrationEntity } from "backend/models/integrations/integrations";

export const filterSupportedEntities = (
  integration: MiterIntegrationForCompany,
  integrationEntity: string
): boolean => {
  if (integration.key !== "netsuite") {
    return isSupportedEntity(integration.supported_operations, integrationEntity);
  }

  const { suiteApp } = integration.connection?.metadata?.netsuite || {};

  if (!suiteApp) {
    throw new Error("Please set your SuiteApp on the Configuration Page");
  }

  const suiteAppEnabledOperations = NETSUITE_ENABLED_SYNCS_BY_SUITEAPP[suiteApp];

  return isSupportedEntity(suiteAppEnabledOperations, integrationEntity);
};

const isSupportedEntity = (
  supportedOperations: Partial<
    Record<IntegrationEntity, { pull?: { enabled: boolean }; push?: { enabled: boolean } }>
  >,
  integrationEntity: string
): boolean => {
  return (
    supportedOperations[integrationEntity]?.push?.enabled ||
    supportedOperations[integrationEntity]?.pull?.enabled
  );
};
