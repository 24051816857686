import { MiterAPI, Timesheet } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import "ag-grid-enterprise";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { DateTime } from "luxon";
import { Badge, Button } from "ui";
import TimesheetModal from "dashboard/pages/timesheets/ViewTimesheet/TimesheetModal";
import { RowStyle } from "ag-grid-enterprise";
import { AgGridTable } from "../agGridTable/AgGridTable";

import styles from "./DailyReports.module.css";
import { Pencil } from "phosphor-react";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { Notifier } from "dashboard/utils";
import { cleanTimesheetsFromBackend, TimesheetTableEntry } from "dashboard/utils/timesheetUtils";
import { verticalCenteredCellStyle } from "../agGridTable/agGridUtils";
import { AggregatedDailyReport } from "dashboard/miter";
import { useActiveCompany, useActiveRole, useActiveTeamMember } from "dashboard/hooks/atom-hooks";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";

type Props = {
  report?: AggregatedDailyReport;
  timesheets: TimesheetTableEntry[];
  readOnly?: boolean;
  setTimesheets: (timesheets: TimesheetTableEntry[]) => void;
};

type DailyReportTimesheetsEntry = Omit<TimesheetTableEntry, "date"> & {
  check?: boolean;
  date: string;
  activity: string;
  tm_name: string;
  quantity: number;
  hours: number;
  clock_in: number;
  clock_out: number;
  missed_lunch?: boolean;
  _id: string;
  status: Timesheet["status"];
  injury: boolean;
};

const DailyReportTimesheets: React.FC<Props> = ({ timesheets, readOnly, setTimesheets, report }) => {
  const activeCompany = useActiveCompany();
  const activeTeamMember = useActiveTeamMember();
  const activeRole = useActiveRole();

  const timesheetAbilities = useTimesheetAbilities();

  const [isViewingTimesheet, setIsViewingTimesheet] = useState<string | undefined>();
  const [selectedTimesheetIds, setSelectedTimesheetIds] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<DailyReportTimesheetsEntry[]>([]);
  const [approving, setApproving] = useState(false);
  const [unapproving, setUnapproving] = useState(false);

  const data: DailyReportTimesheetsEntry[] = useMemo(() => {
    return timesheets.map((ts) => {
      const hoursType = activeCompany?.settings?.daily_reports?.display_hours_type;
      const hours = hoursType === "billable" ? ts.hours + (ts.unpaid_break_time || 0) / 3600 : ts.hours;

      return {
        ...ts,
        date: DateTime.fromSeconds(ts.clock_in).toISODate(),
        activity: ts.activity ? ts.activity.label : "N/A",
        tm_name: ts.team_member ? ts.team_member.full_name : "N/A",
        hours: hours,
        _id: ts._id,
        [DateTime.fromSeconds(ts.clock_in).toISODate()]: hours,
        quantity: 1,
        clock_in: ts.clock_in,
        clock_out: ts.clock_out,
        missed_lunch: !!ts.pay_rate?.union_rate_reference?.missed_lunch_rule,
        status: ts.status,
        injury: ts.injury,
      } as DailyReportTimesheetsEntry;
    });
  }, [timesheets]);

  const showMissedLunch = useMemo(
    () => activeCompany?.settings?.daily_reports?.show_missed_lunch || data.some((ts) => !!ts.missed_lunch),

    [JSON.stringify(data)]
  );

  const totalRowData = {
    tm_name: "Total",
    activity: "",
    hours: 0,
    suppressRowClickSelection: true,
  };

  data.forEach((ts) => {
    totalRowData[ts.date] = totalRowData[ts.date] ? totalRowData[ts.date] + ts.hours : ts.hours;
    totalRowData.hours = totalRowData.hours + ts.hours;
  });

  const hoursFormatter = (params) => {
    return params?.value?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  };

  const handleTimesheetHide = () => {
    setIsViewingTimesheet(undefined);
  };

  const handleTimesheetSelect = (event: SelectionChangedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    const ids = selectedNodes.map((node) => node?.data?._id).filter((id) => id);
    setSelectedTimesheetIds(ids);
    setSelectedRows(selectedNodes.map((node) => node?.data).filter((data) => data));
  };

  const handleTimesheetsApprove = async () => {
    if (timesheetAbilities.cannot("approve", selectedRows)) {
      Notifier.error("You do not have permission to approve these timesheets.");
      return;
    }

    setApproving(true);

    try {
      const payload = {
        ids: [...selectedTimesheetIds],
        team_member_id: activeTeamMember?._id,
        role_id: activeRole?._id,
      };

      const responseData = await MiterAPI.timesheets.approve(payload);
      if (responseData.error) throw responseData.error;

      const updatedTimesheets = timesheets.map((ts) =>
        selectedTimesheetIds.includes(ts._id) ? { ...ts, status: "approved" as const } : ts
      );
      setTimesheets(updatedTimesheets);
      setSelectedTimesheetIds([]);
      Notifier.success("Timesheets successfully approved.");
    } catch (e: $TSFixMe) {
      console.log(e.message);
      Notifier.error("There was an error approving timesheets. We're looking into it.");
    }
    setApproving(false);
  };

  const handleTimesheetsUnapprove = async () => {
    if (timesheetAbilities.cannot("approve", selectedRows)) {
      Notifier.error("You do not have permission to unapprove these timesheets.");
      return;
    }

    setUnapproving(true);

    try {
      const payload = {
        ids: [...selectedTimesheetIds],
        team_member_id: activeTeamMember?._id,
      };

      const responseData = await MiterAPI.timesheets.unapprove(payload);
      if (responseData.error) throw responseData.error;

      const updatedTimesheets = timesheets.map((ts) =>
        selectedTimesheetIds.includes(ts._id) ? { ...ts, status: "unapproved" as const } : ts
      );
      setTimesheets(updatedTimesheets);
      setSelectedTimesheetIds([]);
      Notifier.success("Timesheets successfully unapproved.");
    } catch (e: $TSFixMe) {
      console.log(e.message);
      Notifier.error("There was an error unapproving timesheets. We're looking into it.");
    }
    setUnapproving(false);
  };

  const handleTimesheetChange = async () => {
    try {
      const response = await MiterAPI.timesheets.list({
        filter: [{ field: "_id", comparisonType: "in", value: timesheets.map((ts) => ts._id) }],
        pagination: false,
      });

      if (response.error) throw new Error(response.error);
      const radiusMiles = activeCompany?.settings.timesheets.geofence_radius;
      const cleanedTimesheets = cleanTimesheetsFromBackend(
        response.data,
        activeCompany?.timezone,
        radiusMiles
      );
      setTimesheets(cleanedTimesheets);
    } catch (e) {
      console.log(e);
      Notifier.error("Error retrieving timesheets.");
    }
  };

  const showApproveButton = useMemo(() => {
    if (selectedTimesheetIds.length === 0) return false;
    if (report?.status === "approved") return false;
    if (timesheetAbilities.cannot("approve", selectedRows)) return false;

    // Get the timesheets that are currently selected
    const selectedTimesheets = data.filter((ts) => selectedTimesheetIds.includes(ts._id));
    // If all timesheets are unapproved, show the approve button
    return selectedTimesheets.every((ts) => ts.status === "unapproved");
  }, [selectedTimesheetIds]);

  const showUnapproveButton = useMemo(() => {
    if (selectedTimesheetIds.length === 0) return false;
    if (report?.status === "approved") return false;
    if (timesheetAbilities.cannot("approve", selectedRows)) return false;

    // Get the timesheets that are currently selected
    const selectedTimesheets = data.filter((ts) => selectedTimesheetIds.includes(ts._id));
    // If all timesheets are approved, show the unapprove button
    return selectedTimesheets.every((ts) => ts.status === "approved");
  }, [selectedTimesheetIds]);

  const columnsDefs: ColDef<$TSFixMe>[] = useMemo(
    () => [
      {
        checkboxSelection: (params) => {
          if (!params.node.allChildrenCount) {
            return params.data?.tm_name !== "Total";
          } else {
            return false;
          }
        },
        field: "check",
        headerName: "",
        pinned: "left",
        maxWidth: 50,
        suppressMenu: true,
        headerCheckboxSelection: true,
      },
      {
        field: "tm_name",
        headerName: "Team Member",
        minWidth: 180,
        enableRowGroup: true,
      },
      {
        field: "activity",
        minWidth: 120,
        enableRowGroup: true,
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 150,
        enableRowGroup: true,
        cellRenderer: (params) => {
          if (!params.node.allChildrenCount) {
            console.log("params", params.value);
            return <Badge className="no-margin" text={params.value} />;
          } else {
            return null;
          }
        },
        cellStyle: verticalCenteredCellStyle,
      },
      {
        field: "date",
        headerName: "Date",
        cellRenderer: (params) => {
          if (!params.node.allChildrenCount) {
            // Show day/month
            return params.value ? DateTime.fromISO(params.value).toFormat("MMM dd") : null;
          } else {
            return null;
          }
        },
      },
      {
        field: "clock_in",
        headerName: "Clock In",
        cellRenderer: (params) => {
          if (!params.node.allChildrenCount) {
            return params.value
              ? DateTime.fromSeconds(params.value).toLocaleString(DateTime.TIME_SIMPLE)
              : null;
          } else {
            return null;
          }
        },
        minWidth: 110,
      },
      {
        field: "clock_out",
        headerName: "Clock Out",
        cellRenderer: (params) => {
          if (!params.node.allChildrenCount) {
            return params.value
              ? DateTime.fromSeconds(params.value).toLocaleString(DateTime.TIME_SIMPLE)
              : null;
          } else {
            return null;
          }
        },
        minWidth: 120,
      },
      {
        field: "injury",
        headerName: "Injured",
        cellRenderer: (params) => {
          if (!params.node.allChildrenCount) {
            return params.value ? <Badge text="Yes" /> : <Badge text="No" />;
          } else {
            return null;
          }
        },
        maxWidth: 150,
        cellStyle: verticalCenteredCellStyle,
      },
      ...(showMissedLunch
        ? [
            {
              field: "missed_lunch",
              headerName: "Missed Lunch",
              cellRenderer: (params) => {
                if (!params.node.allChildrenCount) {
                  return params.value ? <Badge text="Yes" /> : <Badge text="No" />;
                } else {
                  return null;
                }
              },
              cellStyle: verticalCenteredCellStyle,
            },
          ]
        : []),

      {
        field: "hours",
        aggFunc: "sumValues",
        valueFormatter: hoursFormatter,
        headerName: "Hrs",
        maxWidth: 75,
        type: "numericColumn",
      },
      {
        field: "_id",
        headerName: "",
        pinned: "right",
        maxWidth: 70,
        cellRenderer: (params) => {
          if (!params.node.allChildrenCount) {
            return (
              <button
                onClick={() => setIsViewingTimesheet(params.value)}
                className="button-1 no-margin"
                style={{ height: 32, width: 32 }}
              >
                <Pencil style={{ marginTop: 3 }} />
              </button>
            );
          } else {
            return null;
          }
        },
        suppressMenu: true,
      },
    ],
    [showMissedLunch]
  );

  const gridOptions = useMemo(
    () => ({
      animateRows: true,
      domLayout: "autoHeight" as const,
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        sortable: true,
        resizable: true,
      },
      suppressAggFuncInHeader: true,
      groupIncludeTotalFooter: true,
      isGroupOpenByDefault: () => !readOnly,
      getRowStyle: (params) => {
        if (params.node.footer) {
          return { fontWeight: "bold", background: "#f3f3f3" } as RowStyle;
        } else {
          return {};
        }
      },
      onSelectionChanged: handleTimesheetSelect,
      rowSelection: "multiple" as const,
    }),
    [readOnly]
  );

  const renderSelectionButtons = () => {
    if (report?.status === "approved") return <></>;

    return (
      <div style={{ display: "flex" }}>
        <Button
          text="Approve"
          onClick={handleTimesheetsApprove}
          className="button-1"
          disabled={!showApproveButton || selectedTimesheetIds.length === 0 || approving}
          loading={approving}
        />

        <Button
          text="Unapprove"
          onClick={handleTimesheetsUnapprove}
          className="button-1 no-margin"
          disabled={!showUnapproveButton || selectedTimesheetIds.length === 0 || unapproving}
          loading={unapproving}
        />
      </div>
    );
  };

  return (
    <div>
      {isViewingTimesheet && (
        <TimesheetModal
          id={isViewingTimesheet}
          hide={handleTimesheetHide}
          handleChange={handleTimesheetChange}
          readOnly={report?.status === "approved"}
        />
      )}
      <div>
        <div className="flex" style={{ marginBottom: 10 }}></div>
        <AgGridTable
          columnDefs={columnsDefs}
          data={data}
          gridOptions={gridOptions}
          hideDownloadCSV={true}
          wrapperClassName={styles["ag-grid-daily-report-timesheets-wrapper"]}
          showSelectedActionButtons={true}
          selectedActionButtons={renderSelectionButtons}
        />
      </div>
    </div>
  );
};

export default DailyReportTimesheets;
export const MemoizedDailyReportTimesheets = React.memo(DailyReportTimesheets);
