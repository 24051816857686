import React from "react";
import { TimesheetSettings } from "./TimesheetSettings";

type Props = {
  updateTimesheetSettings: (newFields: Partial<TimesheetSettings>) => void;
  timesheetSettings: $TSFixMe;
};

export const NonSupervisorEditing: React.FC<Props> = ({ updateTimesheetSettings, timesheetSettings }) => {
  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Mobile timesheet editing</div>
      </div>
      <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
        <div className="vertical-spacer-small"></div>
        <div className="flex">
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => updateTimesheetSettings({ mobile_timesheet_editing: "all" })}
            checked={timesheetSettings.mobile_timesheet_editing === "all"}
          />
          <label>
            <span>All team members can edit timesheets from the mobile app.</span>
          </label>
        </div>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => updateTimesheetSettings({ mobile_timesheet_editing: "supervisors" })}
            checked={timesheetSettings.mobile_timesheet_editing === "supervisors"}
          />
          <label>
            <span>Only supervisors and crew leads can edit timesheets from the mobile app.</span>
          </label>
        </div>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => updateTimesheetSettings({ mobile_timesheet_editing: "none" })}
            checked={timesheetSettings.mobile_timesheet_editing === "none"}
          />
          <label>
            <span>Timesheets cannot be edited from the mobile app.</span>
          </label>
        </div>
      </div>
    </div>
  );
};
