import { Assign } from "utility-types";
import { ColDef } from "ag-grid-community";
import {
  AggregatedJob,
  AggregatedTeamMember,
  CustomField,
  CustomFieldValue,
  FrontendModel,
} from "dashboard/miter";
import { SaveCustomFieldValueParams } from "../../backend/services/custom-field-value-service";
import { FormAnswer as FormAnswer_, FormField as FormField_ } from "../../backend/models/form";
import { ValidationRuleset } from "ui/form/Input";
import { esignatureBaseValidation, esignatureRequired } from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { buildCustomFieldValue } from "dashboard/components/custom-fields/CustomFieldValuesForm";
import { getCustomFieldTypeForFormBlock } from "./form-fields";
import { CustomFieldParentType } from "backend/models/custom-field";

type CustomFieldValueAgGridCell = { [custom_field_id: string]: CustomFieldValue["value"] };
type CustomFieldExportRow = { [custom_field_id: string]: CustomFieldValue };
export type FormAnswer = FrontendModel<FormAnswer_>;
export type FormField = FrontendModel<FormField_>;

/****************************************************************************************
 * Render a custom field's value
 *****************************************************************************************/
export const renderCustomFieldDefaultString = (
  cf: CustomField | FormField,
  cfv?: CustomFieldValue | FormAnswer
): string => {
  if (!cfv) return "-";

  const value = cfv.value;

  if (cf.type === "checkbox") {
    if ((value !== undefined && value !== null && value === false) || value === "false") {
      return "No";
    } else if (cfv) {
      return "Yes";
    }
  }

  if (cf.type === "date") {
    if (value) {
      return DateTime.fromISO(value as string).toLocaleString(DateTime.DATE_MED);
    } else {
      return "-";
    }
  }

  if (cf.type === "select" && !cf.multiple && "_id" in cfv) {
    const option = (cf as FormField).options.find((o) => {
      if (typeof o === "string" && o === cfv.value?.toString()) return true;
      if (typeof o === "object" && "_id" in o && o._id === cfv.value) return true;
      return false;
    });
    if (typeof option === "string") {
      return option;
    } else if (typeof option === "object" && "value" in option) {
      return option.value;
    } else {
      return "-";
    }
  }

  if (cf.type === "select" && cf.multiple && Array.isArray(cfv.value)) {
    const options = (cf as FormField).options.filter((o) => {
      if (typeof o === "string" && (cfv.value as Array<string>)?.find((ans) => ans === o)) return true;
      if (typeof o === "object" && "_id" in o && (cfv.value as Array<string>)?.find((ans) => ans === o._id))
        return true;
      return false;
    });

    const cleanedOptions = options.map((option) => {
      if (typeof option === "string") {
        return option;
      } else if (typeof option === "object" && "value" in option) {
        return option.value;
      } else {
        return "-";
      }
    });

    return cleanedOptions.join(", ");
  }

  // Set to an empty string if esignature because we will save the actual esignature data in a separate call
  if (cf.type === "esignature" && value) {
    return "";
  }

  return (value as unknown as string) || "-";
};

type FormblockValue =
  | FormAnswer["value"]
  | {
      data: { _id: string };
    }[]
  | DateTime
  | undefined;

export const renderCustomDefaultValue = (
  formField: FormField,
  value?: FormAnswer["value"]
): FormblockValue => {
  const customFieldType = getCustomFieldTypeForFormBlock(formField);
  if (customFieldType === "file") {
    if (Array.isArray(value)) {
      return value.map((v) => ({ data: { _id: v } }));
    }
    return [];
  }
  if (customFieldType === "datetime" && value) {
    return DateTime.fromISO(value as string);
  }
  return value;
};

/****************************************************************************************
 * Clean custom field values before saving them
 *****************************************************************************************/
export const cleanCustomFieldValuesBeforeSaving = (
  data: $TSFixMe,
  companyID: string,
  parentID: string,
  parentType: CustomFieldParentType,
  defaultValues: CustomFieldValue[]
): SaveCustomFieldValueParams[] => {
  const params: SaveCustomFieldValueParams[] = [];
  for (const key in data) {
    // If select option, get the value otherwise get the raw value
    const defaultValue = defaultValues.find((df) => df.custom_field_id === key) || {};
    const value = cleanCustomFieldValueParams(data[key]);

    params.push({
      ...defaultValue,
      company_id: companyID,
      parent_id: parentID,
      parent_type: parentType,
      custom_field_id: key,
      value,
    });
  }

  return params;
};

export const cleanCustomFieldValueParams = (value: $TSFixMe): $TSFixMe => {
  // If the custom field value is an array, convert into an array of strings

  if (Array.isArray(value)) {
    return value.map((v) => {
      if ("value" in v) {
        return v.value?.toString();
      }
      return v;
    });
  }

  // If the value is a select object, return value
  if (value && typeof value === "object" && "value" in value) {
    return value.value;
  }

  // If the value is a number, convert it to a number
  if (value && typeof value === "string" && !isNaN(Number(value))) {
    return Number(value);
  }

  // If the value is an esignature, set value to the esignature data
  if (value && typeof value === "object" && "data" in value) {
    return value.data;
  }

  if (value && DateTime.isDateTime(value)) {
    return value.toISODate();
  }

  // Otherwise, return the value as is
  return value;
};

/****************************************************************************************
 * Build AgGrid columns from custom fields
 *****************************************************************************************/
export const buildAgGridColumns = (customFields: CustomField[]): ColDef[] => {
  const columns = customFields.map(buildAgGridColumn);
  return columns;
};

export const buildAgGridCell = (customFieldValue: CustomFieldValue): CustomFieldValueAgGridCell => {
  return {
    [customFieldValue.custom_field_id]: customFieldValue.value,
  };
};

export const buildAgGridRow = (
  customFieldValues: CustomFieldValue[],
  customFields: CustomField[]
): $TSFixMe => {
  const lookupCustomFields = customFields.reduce((acc, cf) => {
    acc[cf._id] = cf;
    return acc;
  }, {});

  return customFieldValues.reduce((row, cfv) => {
    const cf = lookupCustomFields[cfv.custom_field_id];
    if (!cf) return row;

    row[cfv.custom_field_id] = buildCustomFieldValue(cf, cfv);
    return row;
  }, {});
};

export const buildExportRow = (customFieldValues: CustomFieldValue[]): CustomFieldExportRow => {
  return customFieldValues.reduce((row, cfv) => {
    row[cfv.custom_field_id] = cfv;
    return row;
  }, {});
};

type CustomFieldParent = AggregatedJob | AggregatedTeamMember;
type CustomFieldExportRowData = Assign<
  CustomFieldParent,
  {
    custom_field_values: CustomFieldExportRow;
  }
>;

export const buildExportData = (data: CustomFieldParent[]): CustomFieldExportRowData[] => {
  return data.map((item) => {
    const exportable: CustomFieldExportRowData = { ...item, custom_field_values: {} };

    // Turn custom field array into an object
    if (exportable.custom_field_values?.length) {
      exportable.custom_field_values = buildExportRow(item.custom_field_values);
    }

    return exportable;
  });
};

const buildAggFunc = (cf: CustomField): string | null => {
  if (cf.type === "number") {
    return "sumValues";
  }

  return null;
};

const buildAgGridColumn = (cf: CustomField): ColDef => {
  const aggFunc = buildAggFunc(cf);
  const enableRowGroup = cf.type === "select" || cf.type === "checkbox" || cf.type === "text";

  const colDef: ColDef = {
    field: cf._id,
    headerName: cf.name + " (Custom)",
    initialHide: true,
    enableRowGroup,
    enableValue: cf.type === "number",
    sortable: true,
    filter: true,
    valueGetter: (params) => {
      if (cf.type === "number") return params.data?.[cf._id];
      if (cf.type === "checkbox") {
        if (typeof params.data?.[cf._id] === "boolean") return params.data?.[cf._id] ? "Yes" : "No";
        if (typeof params.data?.[cf._id] === "string") return params.data?.[cf._id] === "true" ? "Yes" : "No";
      }
      return params.data?.[cf._id];
    },
    valueFormatter: (params) => {
      if (params.node?.group) {
        const data = params.node?.allLeafChildren?.[0]?.data;
        return data?.[cf._id] || "-";
      } else {
        return params.value || "-";
      }
    },
  };

  // Need to make this a separate function or it breaks
  if (aggFunc) colDef.aggFunc = aggFunc;

  return colDef;
};

// TODO: down the road use CustomFieldRenderer to get the value
export const getAnswerValue = (
  answer: string | { label: string; value: string } | { label: string; value: string }[]
): string | string[] => {
  if (Array.isArray(answer)) {
    return answer.map((a) => a.value);
  }
  if (typeof answer === "object") {
    return answer.value;
  }
  // answer is a string
  return answer;
};

export const buildValidationRuleSet = (cf: CustomField): ValidationRuleset => {
  const { required, min, max } = cf.validations || {};

  const rules: ValidationRuleset = {};

  if (required) {
    rules.required = "This field is required";
  }

  if (cf.type === "number") {
    if (min !== null && min !== undefined) {
      rules.min = {
        value: min,
        message: `Must be at least ${min}`,
      };
    }

    if (max !== null && max !== undefined) {
      rules.max = {
        value: max,
        message: `Must be at most ${max}`,
      };
    }
  }
  if (cf.type === "esignature") {
    if (required) {
      rules.validate = esignatureRequired;
    } else {
      rules.validate = esignatureBaseValidation;
    }
  }

  return rules;
};

export const buildFieldSpecificProps = (cf: CustomField): $TSFixMe => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = {} as unknown as any;

  if (cf.type === "select") {
    props.options = cf.options?.map((o) => ({ label: o, value: o }));
  }

  if (cf.type === "number") {
    if (cf.validations?.min !== null && cf.validations?.min !== undefined) {
      props.min = cf.validations.min;
    }

    if (cf.validations?.max !== null && cf.validations?.max !== undefined) {
      props.max = cf.validations.max;
    }
  }

  return props;
};
