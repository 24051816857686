import { ValueFormatterParams } from "ag-grid-community";
import { DraftBillLineItem } from "dashboard/components/bills/FullPageBillModal";
import {
  getOptions,
  ledgerAccountOptionsMapCallback,
  useActiveCompanyId,
  useActivityOptionsMap,
  useCostTypeOptions,
  useDepartmentOptions,
  useJobOptions,
  useLedgerAccountLabeler,
  useLedgerAccounts,
  useLookupActivity,
  useLookupCostType,
  useLookupDepartment,
  useLookupJob,
  useLookupTeam,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { toDollarFormat } from "miter-utils";
import { useCallback, useMemo } from "react";
import { ColumnConfig } from "ui/table-v2/Table";
import { glAccountSelectionOptions } from "../expenses/expenseUtils";
import { BillPaymentStatusEnum } from "backend/models/bill";
import { isCostTypeExpenseManagementScoped } from "dashboard/components/cost-types/costTypeUtils";

export function useBillLineItemsTableColDefs(
  editing: boolean,
  paymentStatus?: BillPaymentStatusEnum
): ColumnConfig<DraftBillLineItem>[] {
  const teamMemberOptions = useTeamOptions();
  const lookupTeam = useLookupTeam();
  const activeCompanyId = useActiveCompanyId();

  const jobOptions = useJobOptions();
  const lookupJob = useLookupJob();

  const departmentOptions = useDepartmentOptions();
  const lookupDepartment = useLookupDepartment();

  // TODO: add new scope for bill pay
  const activityOptionsMappedByJob = useActivityOptionsMap();

  const lookupActivity = useLookupActivity();

  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });
  const lookupCostType = useLookupCostType();

  const accountLabeler = useLedgerAccountLabeler();

  const ledgerAccounts = useLedgerAccounts();

  const renderTeamMemberName = useCallback(
    (params: ValueFormatterParams<DraftBillLineItem>) => lookupTeam(params.value)?.full_name || "",
    [lookupTeam]
  );

  const renderDepartmentName = useCallback(
    (params: ValueFormatterParams<DraftBillLineItem>) => lookupDepartment(params.value)?.name || "",
    [lookupDepartment]
  );

  const renderJobName = useCallback(
    (params: ValueFormatterParams<DraftBillLineItem>) => lookupJob(params.value)?.name || "",
    [lookupJob]
  );

  const renderActivityLabel = useCallback(
    (params: ValueFormatterParams<DraftBillLineItem>) => lookupActivity(params.value)?.label || "",
    [lookupActivity]
  );

  const renderCostTypeLabel = useCallback(
    (params: ValueFormatterParams<DraftBillLineItem>) => lookupCostType(params.value)?.label || "",
    [lookupCostType]
  );

  return useMemo(() => {
    const columns: ColumnConfig<DraftBillLineItem>[] = [
      {
        headerName: "Amount",
        field: "amount",
        dataType: "number",
        valueFormatter: toDollarFormat,
        editable: editing && paymentStatus ? paymentStatus === "unpaid" : true,
        isEditableRequired: () => true,
        editorType: "number",
        maxWidth: 100,
      },
      {
        headerName: "Description",
        field: "description",
        dataType: "string",
        editable: true,
        editorType: "text",
        minWidth: 200,
      },
      {
        headerName: "Team member",
        field: "team_member_id",
        maxWidth: 150,
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderTeamMemberName,
        cellEditorParams: () => ({
          options: teamMemberOptions,
          isClearable: true,
        }),
      },
      {
        headerName: "Department",
        field: "department_id",
        dataType: "string",
        maxWidth: 150,
        editable: true,
        editorType: "select",
        valueFormatter: renderDepartmentName,
        cellEditorParams: () => ({ options: departmentOptions, isClearable: true }),
      },
      {
        headerName: "Job",
        field: "job_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderJobName,
        cellEditorParams: () => ({ options: jobOptions, isClearable: true }),
      },
      {
        headerName: "Activity",
        field: "activity_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderActivityLabel,
        cellEditorParams: (row) => {
          const jobId = row?.data?.job_id;
          return {
            options: activityOptionsMappedByJob.get(jobId),
            isClearable: true,
          };
        },
      },
      {
        headerName: "Cost type",
        field: "cost_type_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderCostTypeLabel,
        cellEditorParams: () => ({
          options: costTypeOptions,
          isClearable: true,
        }),
      },
      {
        headerName: "GL account",
        field: "ledger_account_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: (params) => {
          // params.value should always be of type LedgerAccount
          return accountLabeler(params.data?.ledger_account_id) || "Default";
        },
        cellEditorParams: (params) => {
          const ledgerAccountOptions = getOptions(
            ledgerAccounts,
            {
              mapFunc: ledgerAccountOptionsMapCallback,
            },
            glAccountSelectionOptions({
              activeCompanyId,
              departmentId: params?.data?.department_id,
              lookupDepartment,
            })
          );
          return {
            options: ledgerAccountOptions,
            isClearable: true,
          };
        },
      },
    ];

    return columns;
  }, [editing, paymentStatus]);
}
