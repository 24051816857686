/* eslint-disable @typescript-eslint/no-explicit-any */
import AppContext from "dashboard/contexts/app-context";
import { Tag } from "dashboard/miter";
import { Plus } from "phosphor-react";
import React, { useContext, useMemo, useState } from "react";
import { Badge } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import TagModal from "./TagModal";
import { singular } from "pluralize";

type Props = {
  parentType: Tag["parent_type"];
  resource?: string;
};

const TagsTable: React.FC<Props> = ({ parentType, resource }) => {
  const { tags, getTags } = useContext(AppContext);

  const [adding, setAdding] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  const handleRowClick = (tag) => {
    handleEdit(tag);
  };

  const handleAdd = () => {
    setAdding(true);
  };

  const handleEdit = (tag) => {
    setSelectedTag(tag);
  };

  const handleModalClose = () => {
    setAdding(false);
    setSelectedTag(null);
  };

  const handleModalSubmit = () => {
    getTags();
    setAdding(false);
  };

  const tableData = useMemo(() => {
    return tags.filter((tag) => tag.parent_type === parentType);
  }, [tags]);

  /*********************************************************
    Config variables for the table
  **********************************************************/

  const staticActions: TableActionLink[] = [
    {
      label: "New",
      className: "button-2",
      action: handleAdd,
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  // Dynamically set columns based on whether any tag have work classifications
  const columns: ColumnConfig<Tag>[] = [
    {
      headerName: "Label",
      field: "label",
      dataType: "string",
      filter: {
        field: "name",
        type: "string",
      },
    },
    {
      headerName: "Color",
      field: "color",
      dataType: "component",
      cellRenderer: (params) => <Badge backgroundColor={params.value} text={params.value} />,
    },
  ];
  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderTable = () => {
    return (
      <TableV2
        id={parentType + "-tags"}
        resource={resource || "tags"}
        data={tableData}
        columns={columns}
        staticActions={staticActions}
        onClick={handleRowClick}
        containerStyle={{ paddingBottom: 0 }}
        hideFooter={true}
      />
    );
  };

  return (
    <div className="tag-table-wrapper">
      <div>
        {adding && (
          <TagModal
            parentType={parentType}
            onHide={handleModalClose}
            onFinish={handleModalSubmit}
            tagNickname={resource ? singular(resource) : "tag"}
          />
        )}
        {selectedTag && (
          <TagModal
            onHide={handleModalClose}
            onFinish={handleModalSubmit}
            tagID={selectedTag._id}
            parentType={parentType}
            tagNickname={resource ? singular(resource) : "tag"}
          />
        )}

        {renderTable()}
      </div>
    </div>
  );
};

export default TagsTable;
