import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MiterAPI } from "../../miter";

import "./search.css";
import { Loader } from "ui";
import { Badge } from "ui";
import { useDebouncedCallback } from "use-debounce";
import RoleToggler from "../sidebar/role-toggler";

import searchPurplePng from "dashboard/assets/search-purple.png";
import searchPng from "dashboard/assets/search.png";

import qs from "qs";
import { useIntercom } from "react-use-intercom";
import { FaQuestionCircle } from "react-icons/fa";
import { DateTime } from "luxon";
import { useActiveCompany, useUser } from "dashboard/hooks/atom-hooks";

type Props = {
  defaultValue?: string;
  shift?: boolean;
};

type CleanedSearchResult = {
  type: string;
  primary: string;
  secondary: string;
  key: string;
  path: string;
  state?: {
    [key: string]: $TSFixMe;
  };
};

type SearchResult = {
  path?: string;
  state?: {
    [key: string]: $TSFixMe;
  };
};

const SearchBar: FC<Props> = ({ defaultValue, shift }) => {
  const { boot, show } = useIntercom();
  const activeCompany = useActiveCompany();
  const activeUser = useUser();

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [dropdownFocus, setDropdownFocus] = useState(0);
  const [searchResults, setSearchResults] = useState<SearchResult[] | null>(null);
  const [intercomIsOpen, setIntercomIsOpen] = useState(false);

  const getSearchResultsForSearchBarDebounced = useDebouncedCallback((e) => {
    getSearchResultsForSearchBar(e);
  }, 200);

  /*********************************************************
                    Intercom Logic
  **********************************************************/

  const bootIntercom = () => {
    if (!intercomIsOpen && activeCompany && activeUser) {
      const companyId = activeCompany._id;

      const name = activeCompany.check_company.trade_name || activeCompany.check_company.legal_name;

      // TODO: calculate monthly spend from billing info based on PEPM * active users, or maybe just the previous month's bill?
      const monthlySpend = 0;

      const company = {
        companyId,
        name,
        checkId: activeCompany.check_company.id,
        monthlySpend: Number(monthlySpend || "0"),
      };

      setIntercomIsOpen(true);

      boot({
        // @ts-expect-error intercom has bad types
        appId: "a9b5sbj0",
        hideDefaultLauncher: true,
        email: activeUser.email || undefined,
        company: company,
      });
    }
  };

  useEffect(() => {
    bootIntercom();
  }, [intercomIsOpen, activeCompany?._id]);

  /*********************************************************
                    Search Logic
  **********************************************************/

  const getSearchResultsForSearchBar = async (searchTerm) => {
    setLoading(true);
    const results = await getSearchResults(searchTerm);
    if (results) {
      const cleanedResults = cleanSearchResultsForDropdown(results);
      setSearchResults(cleanedResults);
    } else {
      setSearchResults(null);
    }
    setLoading(false);
  };

  const getSearchResults = async (searchTerm) => {
    try {
      if (!activeCompany) throw new Error("No active company");

      const responseData = await MiterAPI.search(activeCompany._id, searchTerm);

      if (responseData.error) {
        console.error("Error searching in SearchBar", responseData.error);
        // Show nothing if an error. The SearchResults page has an error screen if the search fails.
        return null;
      }

      return responseData;
    } catch (e: $TSFixMe) {
      console.log("Error in handling search:", e.message);
    }
    return;
  };

  // Method to extract minimum amount of data for each entry.
  // Declares what type it is, provides information to display, and information to support onClick navigation elsewhere.
  const cleanSearchResultsForDropdown = (results) => {
    const jobs = results.jobs || [];
    const teamMembers = results.teamMembers || [];
    const chats = results.chats || [];

    const cleanedResults: CleanedSearchResult[] = [];
    // Takes first three for each results. We aren't sorting by relevance yet though should be easy to add.
    for (let i = 0; i < 3 && i < jobs.length; i++) {
      const job = jobs[i];
      cleanedResults.push({
        type: "job",
        primary: job.name,
        secondary: job.code,
        key: job.name + " " + job.code,
        path: "/jobs/" + job._id,
      });
    }
    for (let i = 0; i < 3 && i < teamMembers.length; i++) {
      const tm = teamMembers[i];

      cleanedResults.push({
        type: "teamMember",
        primary: tm.full_name,
        secondary: tm.title,
        key: tm.name + " " + tm.title,
        path: "/team-members/" + tm._id,
      });
    }

    for (let i = 0; i < 3 && i < chats.length; i++) {
      const c = chats[i];
      cleanedResults.push({
        type: "chat",
        primary: c.friendly_name,
        secondary: DateTime.fromSeconds(c.updated_at).toISODate(),
        key: c.friendly_name + " " + c.updated_at.toString(),
        path: "/chat/" + c._id,
      });
    }

    return cleanedResults;
  };

  /*********************************************************
              SEARCH BAR INTERACTION HANDLERS
  **********************************************************/
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      if (dropdownFocus === 0) {
        submitToSearchResults();
      } else if (!loading) {
        const path = searchResults?.[dropdownFocus - 1]?.path;
        const state = searchResults?.[dropdownFocus - 1]?.state;
        if (path) {
          setShowDropdown(false);
          navigate(path, { state });
        }
      }
      return;
    }

    if (!loading) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setDropdownFocus(Math.min(searchResults ? searchResults.length : 0, dropdownFocus + 1));
      } else if (event.key === "ArrowUp") {
        setDropdownFocus(Math.max(0, dropdownFocus - 1));
        event.preventDefault();
      }
    }
  };

  const submitToSearchResults = async () => {
    setShowDropdown(false);
    navigate("/search?" + qs.stringify({ query: searchInput }));
  };

  const handleInputChange = (event) => {
    setShowDropdown(true);
    setDropdownFocus(0);
    setSearchInput(event.target.value);

    if (event.target.value) {
      getSearchResultsForSearchBarDebounced(event.target.value);
      // getSearchResultsForSearchBar(event.target.value);
    }
  };

  const handleClick = () => {
    submitToSearchResults();
  };

  const handleDropdownFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    setShowDropdown(false);
  };

  /*********************************************************
              SEARCH BAR UI HELPERS
  **********************************************************/

  const renderIndividualSearchResult = (result, index) => {
    let badgeText: string | null = null;
    let badgeColor: string | null = null;
    switch (result.type) {
      case "job":
        badgeText = "JOB";
        badgeColor = "green";
        break;
      case "teamMember":
        badgeText = "TEAM MEMBER";
        badgeColor = "blue";
        break;
      case "timesheet":
        badgeText = "TIMESHEET";
        badgeColor = "gray";
        break;
      case "chat":
        badgeText = "CHAT";
        badgeColor = "orange";
        break;
      default:
        badgeText = null;
        badgeColor = null;
        break;
    }
    return (
      <div
        className={dropdownFocus === index + 1 ? "search-dropdown-text hover" : "search-dropdown-text"}
        onMouseEnter={() => setDropdownFocus(index + 1)}
        onMouseDown={() => navigate(result.path, { state: result.state })}
        key={result.key}
      >
        {badgeText && <Badge className="no-margin" text={badgeText} color={badgeColor || undefined} />}
        <span>
          &nbsp;
          <b>{result.primary + " "}</b>
          {result.secondary}
        </span>
      </div>
    );
  };

  const getSearchDropdownHeight = () => {
    if (loading) {
      return "75px";
    }
    let height = 44;
    if (searchResults) {
      height = 44 + searchResults.length * 28;
    }
    return height.toString() + "px";
  };

  const searchInputTruncated = (input) => {
    if (input.length > 30) {
      return input.substring(0, 30) + "...";
    }
    return input;
  };

  const renderSupportButton = () => {
    return (
      <button className={"search-bar-button"} onClick={() => show()}>
        <FaQuestionCircle style={{ marginBottom: "-2px" }} />
      </button>
    );
  };

  return (
    <div>
      {showDropdown && (
        <>
          <div className={"search-background content " + (shift && " shifted")} />
          <div className={"search-background sidebar " + (shift && " shifted")} />
        </>
      )}
      <div className={"search-wrapper " + (shift && " shifted-search-wrapper")}>
        {searchInput && <img className="search-icon" src={searchPurplePng} />}
        {!searchInput && <img className="search-icon" src={searchPng} />}
        <input
          className="search-input"
          type="text"
          placeholder="Search Miter"
          defaultValue={defaultValue ? defaultValue : undefined}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          onFocus={handleDropdownFocus}
          onBlur={handleBlur}
        />
        {renderSupportButton()}
        <RoleToggler />
        {searchInput && showDropdown && (
          <div className="search-dropdown" style={{ height: getSearchDropdownHeight() }}>
            <div
              className={dropdownFocus === 0 ? "search-dropdown-header hover" : "search-dropdown-header"}
              onMouseDown={handleClick}
              onMouseEnter={() => setDropdownFocus(0)}
            >
              <span>
                See all results for&nbsp;
                <b>{searchInputTruncated(searchInput)}</b>
              </span>
            </div>
            {!loading && searchResults && <div className="header-spacer-tiny" />}
            {!loading &&
              searchResults &&
              searchResults.map((result, index) => renderIndividualSearchResult(result, index))}
            {loading && (
              <div className="search-dropdown-text" style={{ height: "25px" }}>
                <Loader />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
