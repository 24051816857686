import { ClickAwayListener } from "@material-ui/core";
import { File, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BasicModal, Button, Formblock, ModalFormToggler, ModalHeader } from "ui";
import { Attachments } from "./Attachments";
import * as vals from "dashboard/utils/validators";
import { SendMessageSuccessModal } from "./SendMessageSuccessModal";
import { Link } from "react-router-dom";
import { AggregatedDailyReport } from "dashboard/miter";
import { useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";

type Props = {
  hide: () => void;
  file_ids?: string[];
  daily_reports?: AggregatedDailyReport[];
  header?: string;
  job_id?: string;
  email_params?: {
    to?: string | null;
    subject?: string;
    text?: string;
  };
  onSend?: () => void;
  content_type?: string;
  mail_params?: {
    invoice_description?: string;
    invoice_metadata?: string;
    toAddress: {
      postal_name: string;
      line1: string;
      line2?: string;
      city: string;
      state: string;
      postal_code: string;
    };
  };
  fax_params?: {
    to: string;
  };
  hideEmail?: boolean;
  hideMail?: boolean;
  hideFax?: boolean;
};

const sendFunctionLookup = {
  email: MiterAPI.messages.send_email,
  mail: MiterAPI.messages.send_mail,
  fax: MiterAPI.messages.send_fax,
};

export const SendMessageModal: React.FC<Props> = ({
  hide,
  file_ids,
  daily_reports,
  content_type,
  job_id,
  header,
  onSend,
  email_params,
  mail_params,
  fax_params,
  hideEmail,
  hideFax,
}) => {
  const form = useForm();
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();

  const { register, control, watch, errors, handleSubmit, setValue, setError } = form;
  const watchedData = watch();

  const [sending, setSending] = useState(false);
  const [activeTab, setActiveTab] = useState("Message");
  const [mode, setMode] = useState("email");
  const [gettingFiles, setGettingFiles] = useState(false);
  const [files, setFiles] = useState<File[] | undefined>();
  const [showPendingMessageModal, setShowPendingMessageModal] = useState(false);
  const [showSentMessageModal, setShowSentMessageModal] = useState(false);
  // There are certain cases where we will disable sending. In these cases we want to (1) setSendDisabled(true) and...
  // (2) add a message explaining why send is disabled via setSendDisabledMessagge({your message})
  const [sendDisabled, setSendDisabled] = useState(false);
  const [sendDisabledMessage, setSendDisabledMessage] = useState<string | undefined>();
  const [formData, setFormData] = useState<$TSFixMe>({
    email_params,
    mail_params,
    fax_params,
  });
  const [sentMessageId, setSentMessageId] = useState<string | undefined>();

  const cleanEmails = (string) => {
    if (!string) return [];
    const arr = string.split(",");

    const trimmedArr: string[] = [];
    for (const email of arr) {
      const trimmedEmail = email.trim();
      if (vals.isValidEmail(trimmedEmail)) {
        trimmedArr.push(trimmedEmail);
      } else {
        setError("email_params.to", { message: "Please enter only valid emails." });
        throw new Error("Invalid email");
      }
    }
    return trimmedArr;
  };

  const send = async (data) => {
    setSending(true);
    try {
      if (!activeUser?.email) throw new Error("No active user email");
      const payload = {
        company: activeCompanyId,
        job: job_id,
        file_ids,
        type: mode,
        content_type,
        daily_report_ids: daily_reports?.map((dr) => dr._id),
        ...formData,
        ...data,
      };
      if (payload.email_params?.to) {
        payload.email_params.reply_to = activeUser.email;
        payload.email_params.to = cleanEmails(payload.email_params?.to);
      }
      const response = await sendFunctionLookup[mode](payload);
      if (response.error) throw new Error(response.error);

      if (!response.sent_fax || response.sent_fax.status === "success") {
        Notifier.success("Message sent!");
        setShowSentMessageModal(true);
        setSentMessageId(response._id);
        onSend?.();
      } else if (response.sent_fax.status === "failure") {
        Notifier.error("We were unable to send your fax. Please check your fax number and try again.");
      } else {
        setShowPendingMessageModal(true);
        setSentMessageId(response._id);
        onSend?.();
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error sending your message.");
    }
    setSending(false);
  };

  const getFiles = async () => {
    setGettingFiles(true);
    try {
      const filter = [
        { field: "_id", comparisonType: "in", value: file_ids },
        { field: "company_id", value: activeCompanyId },
      ];
      // @ts-expect-error filter error
      const response = await MiterAPI.files.list(filter);
      if (response.error) throw new Error(response.error);
      setFiles(response as File[]);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error fetching this message's attachments.");
    }
    setGettingFiles(false);
  };

  const sendOptions = [
    ...(!hideEmail ? [{ label: "Email", value: "email" }] : []),
    ...(!hideFax ? [{ label: "Fax", value: "fax" }] : []),
  ];

  const handleToggle = (t) => {
    setFormData({ ...formData, ...watchedData });
    setActiveTab(t);
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    if (newMode !== "email" && file_ids && file_ids.length > 1) {
      setSendDisabled(true);
      setSendDisabledMessage(`You can only ${newMode} one attachment at a time.`);
    } else {
      setSendDisabled(false);
      setSendDisabledMessage(undefined);
    }
  };

  const renderMessageTab = () => {
    return (
      <div>
        <Formblock
          type="select"
          label="Send via"
          options={sendOptions}
          defaultValue={mode}
          control={control}
          onChange={(option) => handleModeChange(option.value)}
          editing={true}
          errors={errors}
          className="modal"
          requiredSelect={true}
          name="mode"
        />
        {sendDisabled ? (
          <div className="payroll-approval-text-wrapper">{sendDisabledMessage}</div>
        ) : (
          <div>
            {mode === "email" && (
              <div>
                <Formblock
                  type="text"
                  label="Email addresses (comma separated)*"
                  defaultValue={formData.email_params?.to ? formData.email_params.to : undefined}
                  register={register(vals.required)}
                  editing={true}
                  errors={errors}
                  className="modal"
                  name="email_params.to"
                />
                <Formblock
                  type="text"
                  label="Subject*"
                  defaultValue={formData.email_params?.subject}
                  register={register(vals.required)}
                  editing={true}
                  errors={errors}
                  className="modal"
                  name="email_params.subject"
                />
                <Formblock
                  type="paragraph"
                  label="Body"
                  defaultValue={formData.email_params?.text}
                  register={register}
                  errors={errors}
                  editing={true}
                  className="modal"
                  name="email_params.text"
                />
              </div>
            )}
            {mode === "fax" && (
              <div>
                <Formblock
                  type="phone"
                  label="Fax number*"
                  defaultValue={formData.fax_params?.to ? formData.fax_params.to : undefined}
                  val={vals.phone}
                  errors={errors}
                  control={control}
                  editing={true}
                  className="modal"
                  name="fax_params.to"
                />
                <Formblock
                  type="paragraph"
                  label="Cover letter"
                  register={register}
                  editing={true}
                  errors={errors}
                  className="modal"
                  name="fax_params.cover_letter_text"
                />
              </div>
            )}
            {mode === "mail" && (
              <div>
                <Formblock
                  type="address"
                  label="Recipient address*"
                  defaultValue={formData.mail_params?.toAddress}
                  register={register}
                  showPostalName={true}
                  editing={true}
                  errors={errors}
                  control={control}
                  className="modal"
                  name="mail_params.toAddress"
                  autocomplete={true}
                  setValue={setValue}
                />
                <Formblock
                  type="paragraph"
                  label="Cover letter"
                  register={register}
                  editing={true}
                  errors={errors}
                  className="modal"
                  name="mail_params.cover_letter_text"
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderPendingModal = () => {
    return (
      <BasicModal
        headerText="Message pending"
        button2Text="Ok"
        button2Action={() => setShowPendingMessageModal(false)}
      >
        Your fax has been queued for sending. We will send you an email when it has been sent.
        {job_id && (
          <span>
            To view the status of the fax, please check the{" "}
            <Link className="blue-link" to={`/jobs/${job_id}/outbox`}>
              job outbox.
            </Link>
          </span>
        )}
      </BasicModal>
    );
  };

  const renderSendMessageModal = () => {
    return (
      <div className="modal-background">
        <ClickAwayListener onClickAway={() => {}}>
          <div className="modal-wrapper form">
            <ModalHeader heading={header || "Send a message"} onHide={hide} />
            <div className="modal-body form" style={{ minHeight: 400 }}>
              <ModalFormToggler
                tabs={["Message", "Attachments"]}
                activeTab={activeTab}
                onToggle={handleToggle}
              />
              <div className="vertical-spacer"></div>
              {activeTab === "Message" ? (
                <div>{renderMessageTab()}</div>
              ) : (
                <Attachments files={files} daily_reports={daily_reports} loading={gettingFiles} />
              )}

              <div className="vertical-spacer" />
            </div>
            <div className="modal-footer form">
              <Button className="button-1" loading={false} text="Cancel" onClick={hide} />
              <Button
                className="button-2"
                loading={sending}
                text="Send"
                disabled={sendDisabled}
                onClick={handleSubmit(send)}
              />
            </div>
          </div>
        </ClickAwayListener>
      </div>
    );
  };

  useEffect(() => {
    getFiles();
  }, []);

  return (
    <>
      {showSentMessageModal ? (
        <SendMessageSuccessModal hide={hide} job={job_id} messageId={sentMessageId!} />
      ) : showPendingMessageModal ? (
        renderPendingModal()
      ) : !sentMessageId ? (
        renderSendMessageModal()
      ) : (
        <></>
      )}
    </>
  );
};
