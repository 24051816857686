import { MiterAPI, AggregatedFile } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Breadcrumbs, Button, ConfirmModal, DropdownButton, Toggler } from "ui";
import styles from "./Documents.module.css";
import {
  CaretDown,
  EnvelopeOpen,
  FileArrowDown,
  Pencil,
  ScribbleLoop,
  Trash,
  Envelope,
} from "phosphor-react";
import { SendMessageModal } from "dashboard/components/sendMessageModal/SendMessageModal";
import BulkTeamMemberSelect, {
  BulkTeamMemberSelectTeamMember,
} from "dashboard/components/team-members/BulkTeamMemberSelect";
import { updateSignatureRequests } from "dashboard/utils/document";
import ESignatureItemsTable from "dashboard/components/esignatures/ESignatureItemsTable";
import DocumentTeamMembersTable from "dashboard/components/documents/DocumentTeamMembersTable";
import Notes from "dashboard/components/notes/Notes";
import { Crumb } from "ui/breadcrumbs/Breadcrumbs";
import DocumentWizard from "dashboard/components/documents/wizard/DocumentWizard";

import {
  useActiveAccount,
  useActiveCompanyId,
  useIsSuperAdmin,
  useLookupTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { useDocumentAbilities } from "dashboard/hooks/abilities-hooks/useDocumentAbilities";
import { markFilesAsViewed } from "miter-utils";
import { FillableDocumentWizard, DocumentDisplay } from "miter-components";
import { DocumentDetails } from "./DocumentDetails";
import { useAccessFillableDocument } from "../../hooks/documents/useAccessFillableDocument";

export type AggregatedFileWithUrl = AggregatedFile & { url: string };

const Document: React.FC = () => {
  /*********************************************************
   *  Hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const lookupTeam = useLookupTeam();
  const navigate = useNavigate();
  const { id, view } = useParams<{ id: string; view: string; filter?: string }>();
  const documentAbilities = useDocumentAbilities();
  const activeAccount = useActiveAccount();
  const isSuperAdmin = useIsSuperAdmin();

  /*********************************************************
   * States
   **********************************************************/
  const [miterDocument, setDocument] = useState<AggregatedFileWithUrl>();
  const [showEditInfo, setShowEditInfo] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showRequestSignaturesModal, setShowRequestSignaturesModal] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [requestingSignatures, setRequestingSignatures] = useState(false);
  const [completeFillableDocument, setCompleteFillableDocument] = useState(false);
  const [markedAsViewed, setMarkedAsViewed] = useState(false);

  const { accessible: hasTeamMemberAccess } = useAccessFillableDocument({
    teamMemberId: activeAccount?.team_member?._id,
    miterDocument,
  });

  useEffect(() => {
    if (miterDocument && documentAbilities.cannot("read", miterDocument)) {
      Notifier.error("You do not have permission to view this document");
      navigate("/home", { replace: true });
    }
  }, [documentAbilities.cannot]);

  const teamMembersWithAccessMap = useMemo(() => {
    if (!miterDocument) return {};

    return (
      miterDocument.team_members_with_access?.reduce((acc, tm) => {
        acc[tm._id] = true;
        return acc;
      }, {}) || {}
    );
  }, [miterDocument]);

  /*********************************************************
   *  Important conts
   **********************************************************/
  const activeSigners = useMemo(() => {
    if (!miterDocument || !("esignature_items" in miterDocument)) return [];

    return miterDocument.esignature_items
      .filter((esr) => !!esr.signer.team_member_id)
      .map((esr) => {
        const tm = lookupTeam(esr.signer.team_member_id)!;
        return { ...tm, non_removable: esr.status === "signed" };
      });
  }, [miterDocument, lookupTeam]);

  const documentUrl = useMemo(() => miterDocument && miterDocument.url, [miterDocument]);

  useEffect(() => {
    if (!completeFillableDocument) {
      getDocument();
    }
  }, [completeFillableDocument]);

  useEffect(() => {
    if (!view) {
      navigate(`/documents/${id}/document`, { replace: true });
    }
  }, [miterDocument]);

  /*********************************************************
   *  API Functions
   **********************************************************/
  const getDocument = async () => {
    if (!id) return;

    try {
      const document = await MiterAPI.files.retrieve(id, { aggregated: true });
      if (document.error) throw Error(document.error);
      if (!("esignature_items" in document)) throw Error("We are unable to load this document");
      if (!markedAsViewed && document.fill_status !== "pending") {
        await markFilesAsViewed([document._id]);
        setMarkedAsViewed(true);
      }
      setDocument(document);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
  };

  const archiveDocument = async () => {
    if (!miterDocument || documentAbilities.cannot("delete", miterDocument)) return;
    setArchiving(true);

    try {
      const response = await MiterAPI.files.delete([miterDocument._id]);
      if (response.error) throw Error(response.error);

      Notifier.success("Document deleted");
      navigate("/documents");
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }

    setArchiving(false);
  };

  const giveSignersAccess = async (signersWithoutAccess: BulkTeamMemberSelectTeamMember[]): Promise<void> => {
    if (!miterDocument || documentAbilities.cannot("update", miterDocument)) return;

    const additionalPermissions = signersWithoutAccess.map((tm) => ({
      group: { type: "team_member", value: tm._id },
      can: ["view"],
    })) as NonNullable<AggregatedFile["team_permissions"]>;

    const docRes = await MiterAPI.files.update_many({
      files: [
        {
          _id: miterDocument._id,
          team_permissions: (miterDocument.team_permissions || []).concat(additionalPermissions),
          existing_tm_document: true,
          onboarding_document: true,
        },
      ],
    });

    if (docRes.error) throw Error(docRes.error);
    if (!docRes[0]) throw Error("Failed to update document permissions");
  };

  const saveSignatureRequests = async (signers: BulkTeamMemberSelectTeamMember[]) => {
    if (
      !activeCompanyId ||
      !activeUser ||
      !miterDocument ||
      documentAbilities.cannot("update", miterDocument)
    )
      return;
    setRequestingSignatures(true);

    try {
      const res = await updateSignatureRequests({
        signers,
        document: miterDocument,
        companyId: activeCompanyId,
        userId: activeUser._id,
      });

      if (!res) throw Error("Failed to update signature requests");

      // Update the document to make sure that any team members selected, that don't have access, are added to the document permissiosn
      const signersWithoutAccess = signers.filter((tm) => !teamMembersWithAccessMap[tm._id]);
      if (signersWithoutAccess.length) await giveSignersAccess(signersWithoutAccess);

      await getDocument();

      Notifier.success("Successfully updated signers");
      setShowRequestSignaturesModal(false);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }

    setRequestingSignatures(false);
  };

  /*********************************************************
   *  Handler Functions
   **********************************************************/
  const handleEditInfoHide = () => {
    setShowEditInfo(false);
  };

  const handleEditInfoFinish = () => {
    handleEditInfoHide();
    getDocument();
  };

  const openCompleteFillableDocument = async () => {
    setCompleteFillableDocument(true);
  };

  const closeCompleteFillableDocument = () => {
    setCompleteFillableDocument(false);
  };

  /*********************************************************
   *  Toggler config
   **********************************************************/
  const handleToggle = (option) => {
    if (!miterDocument) return;
    navigate("/documents/" + miterDocument._id + "/" + option);
  };

  /*********************************************************
   *  Render Functions
   **********************************************************/

  const isFillableDocument = !!miterDocument?.fillable_template_id;
  const togglerConfig = [
    {
      path: "document",
      label: "Document",
    },
    ...(isFillableDocument
      ? []
      : [
          {
            path: "team-access",
            label: "Team Access",
          },
          {
            path: "signature-requests",
            label: "Signature Requests",
            hide: miterDocument?.type !== "application/pdf" || miterDocument?.signed_copy,
          },
          {
            path: "notes",
            label: "Notes",
            hide: !miterDocument || documentAbilities.cannot("update", miterDocument),
          },
        ]),
  ];

  const renderBreadcrumbs = () => {
    if (!miterDocument) return;

    if (isFillableDocument) {
      return (
        <Breadcrumbs
          crumbs={[
            {
              label: "Templates",
              path: `/documents?view=fillable-templates`,
            },
            {
              label: "Fillable template",
              path: `/fillable-templates/${miterDocument.fillable_template_id}/requests`,
            },
            { label: miterDocument.label!, path: "/documents/" + miterDocument._id + "/document" },
          ]}
        />
      );
    }

    const parentCrumb: Crumb[] = [];
    if (miterDocument.parent_type === "job" && miterDocument.parent_name) {
      parentCrumb.push({ label: miterDocument.parent_name, path: "/jobs/" + miterDocument.parent_id });
    } else if (miterDocument.parent_type === "team_member" && miterDocument.parent_name) {
      parentCrumb.push({
        label: miterDocument.parent_name,
        path: "/team-members/" + miterDocument.parent_id,
      });
    }

    return (
      <Breadcrumbs
        crumbs={[
          ...parentCrumb,
          { label: "Documents", path: (parentCrumb[0]?.path || "") + "/documents" },
          {
            label: miterDocument.label || "Untitled Document",
            path: "/documents/" + miterDocument._id + "/document",
          },
        ]}
      />
    );
  };

  const isIncompleteFillableDoc = miterDocument?.fill_status === "pending";
  const canCompleteDoc = isIncompleteFillableDoc && (isSuperAdmin || hasTeamMemberAccess);

  const remindTeamMembers = async () => {
    try {
      if (!miterDocument) throw new Error("Document not found");
      const response = await MiterAPI.files.fillable_documents.remind(miterDocument._id);
      if (response.error) throw Error(response.error);
      Notifier.success("Team members notified");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  const renderActions = () => {
    if (!miterDocument) return;

    const actions = [
      {
        label: "Send document",
        action: () => setShowSendModal(true),
        icon: <EnvelopeOpen style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => !isFillableDocument,
      },
      {
        label: "Request signatures",
        action: () => setShowRequestSignaturesModal(true),
        icon: <ScribbleLoop style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () =>
          miterDocument.type === "application/pdf" && !miterDocument.signed_copy && !isFillableDocument,
      },
      {
        label: "Remind assignees",
        action: remindTeamMembers,
        icon: <Envelope style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => isIncompleteFillableDoc,
      },
      {
        label: "Edit document",
        action: () => setShowEditInfo(true),
        icon: <Pencil style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => documentAbilities.can("update", miterDocument) && !isFillableDocument,
      },
      {
        label: `Download`,
        action: () => {
          window.open(miterDocument.url, "_blank");
        },
        icon: <FileArrowDown style={{ marginRight: 7 }} size={15} />,
        shouldShow: () => true,
      },
      {
        label: "Delete",
        action: () => setShowArchiveModal(true),
        icon: <Trash style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => documentAbilities.can("delete", miterDocument),
      },
    ];

    return (
      <div className={styles["actions"]}>
        {canCompleteDoc ? (
          <Button
            onClick={openCompleteFillableDocument}
            className="button-2 no-margin"
            style={{ marginLeft: 5 }}
          >
            Complete document
          </Button>
        ) : null}
        <DropdownButton className={"button-1"} options={actions} closeOnClick={true}>
          Actions
          <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
        </DropdownButton>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className={styles["header-tags"]}>
        {miterDocument?.tags?.map((tag) => {
          return (
            <Badge key={tag._id} backgroundColor={tag.color} text={tag.label} style={{ marginLeft: 0 }} />
          );
        })}
      </div>
    );
  };

  const renderNotes = () => {
    if (!miterDocument) return;

    return (
      <>
        <div className="vertical-spacer" />
        <Notes parentId={miterDocument._id} parentType={"file"} />
      </>
    );
  };

  const renderContent = () => {
    if (!miterDocument) return;

    return (
      <>
        <div className={"page-content-header " + styles["header"]} style={{ marginTop: 5 }}>
          {renderBreadcrumbs()}
          <h1 className="view-title" style={{ marginTop: 10 }}>
            {miterDocument.label || miterDocument.originalname || "Untitled Document"}
          </h1>
          {renderTags()}
          {renderActions()}
        </div>
        <Toggler config={togglerConfig} toggle={handleToggle} active={view} />
        {view === "document" && documentUrl && (
          <div className={styles["split-view"]}>
            <DocumentDisplay uri={documentUrl} wrapperStyle={{ marginRight: 30 }} />
            <DocumentDetails file={miterDocument} />
          </div>
        )}
        {view === "team-access" && (
          <DocumentTeamMembersTable document={miterDocument} getDocument={getDocument} />
        )}
        {view === "signature-requests" &&
          miterDocument.type === "application/pdf" &&
          !miterDocument?.signed_copy && (
            <ESignatureItemsTable
              document={miterDocument}
              esignatureItems={miterDocument.esignature_items}
              setESignatureItems={(esignature_items) => setDocument({ ...miterDocument, esignature_items })}
              openSignersModal={() => setShowRequestSignaturesModal(true)}
            />
          )}
        {view === "notes" && renderNotes()}
      </>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{miterDocument?.label || "Documents"} | Miter</title>
      </Helmet>
      <div className="page-content">{renderContent()}</div>

      {showArchiveModal && (
        <ConfirmModal
          onYes={archiveDocument}
          onNo={() => setShowArchiveModal(false)}
          body={"Are you sure you want to delete this document?"}
          loading={archiving}
        />
      )}
      {showSendModal && miterDocument && (
        <SendMessageModal
          file_ids={[miterDocument._id]}
          hide={() => setShowSendModal(false)}
          hideMail={true}
          header={"Send document"}
          onSend={() => {
            setShowSendModal(false);
            markFilesAsViewed([miterDocument._id]);
          }}
        />
      )}
      {showEditInfo && miterDocument && miterDocument.parent_id && (
        <DocumentWizard
          document={{ data: miterDocument, url: miterDocument.url }}
          onExit={handleEditInfoHide}
          onComplete={handleEditInfoFinish}
          parentId={miterDocument.parent_id}
          parentType={miterDocument.parent_type}
          mode="edit-info"
        />
      )}

      {showRequestSignaturesModal && miterDocument && (
        <BulkTeamMemberSelect
          title={`Manage Signers for ${miterDocument.label}`}
          defaultTeamMembers={activeSigners}
          onHide={() => setShowRequestSignaturesModal(false)}
          onSubmit={saveSignatureRequests}
          submitting={requestingSignatures}
          nonRemovableDescriptor="Signed"
          predicate={(tm) =>
            miterDocument.parent_type !== "team_member" ||
            (miterDocument.parent_type === "team_member" && tm._id === miterDocument.parent_id)
          }
        />
      )}
      {completeFillableDocument && miterDocument && activeUser && (
        <FillableDocumentWizard
          teamMember={activeAccount?.team_member}
          role={activeAccount?.role}
          fillableDocumentId={miterDocument._id}
          onExit={closeCompleteFillableDocument}
          onComplete={closeCompleteFillableDocument}
          userId={activeUser?._id}
          isSuperAdmin={isSuperAdmin}
          application="dashboard"
        />
      )}
    </div>
  );
};

export default Document;
