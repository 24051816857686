import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";

import * as vals from "dashboard/utils/validators";
import { Notifier } from "dashboard/utils";
import { AggregatedTeamMember, Crew, MiterAPI } from "dashboard/miter";
import {
  useActiveCompanyId,
  useActiveTeam,
  useCrews,
  useLookupTeam,
  useRefetchCrews,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Pencil } from "phosphor-react";
import { notNullish } from "miter-utils";
import BulkTeamMemberSelect, { BulkTeamMemberSelectTeamMember } from "../team-members/BulkTeamMemberSelect";

type CrewFormParams = {
  name?: string;
  lead_team_member_id?: Option<string>;
  team_member_ids?: string[];
};

type Props = {
  crewID?: string;
  onFinish: (crew: Crew) => void;
  onHide: () => void;
};

const CrewModal: React.FC<Props> = ({ crewID, onFinish, onHide }) => {
  const crews = useCrews();
  const refetchCrews = useRefetchCrews();
  const activeCompanyId = useActiveCompanyId();
  const teamOptions = useTeamOptions();
  const lookupTeam = useLookupTeam();
  const activeTeam = useActiveTeam();
  const selectedCrew = useMemo(() => crews.find((crew) => crew._id === crewID), [crews]);

  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      name: selectedCrew?.name,
      lead_team_member_id: teamOptions.find((t) => t.value === selectedCrew?.lead_team_member_id),
      team_member_ids: selectedCrew?.team_member_ids || [],
    },
  });

  const formData = form.watch();

  const [loading, setLoading] = useState(false);
  const [showTeamMemberSelector, setShowTeamMemberSelector] = useState(false);

  const { control, register, errors, handleSubmit } = form;

  const crewMembers = useMemo(() => {
    return formData.team_member_ids?.map((_id) => lookupTeam(_id)).filter(notNullish) || [];
  }, [formData.team_member_ids]);

  // Remove properties that equal what exists in the user object
  const cleanParams = (data: CrewFormParams) => {
    if (!data.name) throw new Error("Name is required");

    if (!data.lead_team_member_id?.value) throw new Error("Lead is required");

    return {
      ...data,
      name: data.name,
      company_id: activeCompanyId!,
      lead_team_member_id: data.lead_team_member_id?.value,
      team_member_ids: data.team_member_ids || [],
    };
  };

  const createCrew = async (data) => {
    if (selectedCrew) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.crews.create(params);
      if (res.error) throw new Error(res.error);

      await refetchCrews();
      onFinish(res);

      Notifier.success("Crew created successfully");
    } catch (e: $TSFixMe) {
      console.error("Error creating crew:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateCrew = async (data) => {
    if (!selectedCrew) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.crews.update(selectedCrew._id, params);
      if (res.error) throw new Error(res.error);

      await refetchCrews();
      onFinish(res);

      Notifier.success("Crew updated successfully");
    } catch (e: $TSFixMe) {
      console.error("Error updating crew:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const handleTeamMemberSelection = (teamMembers: BulkTeamMemberSelectTeamMember[]) => {
    const ids = teamMembers.map((tm) => tm._id);
    form.setValue("team_member_ids", ids);
    setShowTeamMemberSelector(false);
  };

  const submit = () => {
    if (selectedCrew) {
      handleSubmit(updateCrew)();
    } else {
      handleSubmit(createCrew)();
    }
  };

  const hide = () => {
    onHide();
  };

  const columns = useMemo(() => {
    const cols: ColumnConfig<AggregatedTeamMember>[] = [
      {
        headerName: "Name",
        field: "full_name",
        dataType: "string",
      },
      {
        headerName: "Title",
        field: "title",
        dataType: "string",
      },
      {
        headerName: "Department",
        field: "department.name",
        dataType: "string",
      },
    ];

    return cols;
  }, [formData.team_member_ids]);

  const staticActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Edit members",
        className: "button-2 no-margin",
        action: () => setShowTeamMemberSelector(true),
        important: true,
        icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
    return acts;
  }, []);

  const renderMembersSelector = () => {
    return (
      <BulkTeamMemberSelect
        title={`Manage Crew Members`}
        defaultTeamMembers={crewMembers}
        teamMembersPool={activeTeam}
        onHide={() => setShowTeamMemberSelector(false)}
        submitText="Submit"
        onSubmit={handleTeamMemberSelection}
        nonRemovableDescriptor="Signed"
      />
    );
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          className="modal"
        />
        <Formblock
          type="select"
          name="lead_team_member_id"
          label="Lead"
          labelInfo="The lead of the crew"
          control={control}
          register={register}
          editing={true}
          errors={errors}
          className="modal"
          options={teamOptions}
          requiredSelect={true}
        />
        <TableV2
          id={"crew-members-table"}
          resource="crew members"
          data={crewMembers}
          columns={columns}
          staticActions={staticActions}
          gridWrapperStyle={{ paddingBottom: 0, height: 330 }}
          containerStyle={{ paddingBottom: 0 }}
        />
      </div>
    );
  };

  const wrapperStyle = showTeamMemberSelector ? { display: "none" } : { minWidth: 600, width: "70%" };

  return (
    <>
      <ActionModal
        headerText={selectedCrew ? "Edit crew" : "Create crew"}
        showSubmit={true}
        showCancel={true}
        cancelText={"Close"}
        onCancel={hide}
        submitText={"Save"}
        onHide={hide}
        onSubmit={submit}
        loading={loading}
        wrapperStyle={wrapperStyle}
        bodyStyle={{ maxHeight: 580 }}
      >
        {renderForm()}
      </ActionModal>
      {showTeamMemberSelector && renderMembersSelector()}
    </>
  );
};

export default CrewModal;
