import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import React, { useEffect, useState } from "react";
import { Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { ENDeductionAuditRow } from "backend/services/employee-navigator-service";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type Props = {};

export const ENDeductionAuditReport: React.FC<Props> = () => {
  const { setReverifyUser, setOnReverifyUser } = React.useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<ENDeductionAuditRow[] | undefined>();

  const getData = async () => {
    setLoading(true);
    try {
      if (!activeCompanyId) throw new Error("No company found");

      const company = activeCompanyId;
      const response = await MiterAPI.integrations.employee_navigator.get_deductions_audit_report(company);
      if (response.error) throw new Error(response.error);
      setTableData(response);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error retrieving deduction data.`);
    }
    setLoading(false);
  };

  useEffect(() => {
    setReverifyUser(true);
    setOnReverifyUser(() => getData);
  }, []);

  return (
    <div>
      <TableV2
        id="en-deduction-audit-report"
        resource="benefits"
        data={tableData}
        columns={columns}
        isLoading={loading}
      />
    </div>
  );
};

export const columns: ColumnConfig<ENDeductionAuditRow>[] = [
  { field: "Employee SSN", headerName: "Employee SSN" },
  { field: "Payroll ID", headerName: "Employee Payroll ID" },
  { field: "First Name", headerName: "First Name" },
  { field: "Last Name", headerName: "Last Name" },
  { field: "Deduction Code", headerName: "Deduction Code" },
  { field: "Pay Period Deduction Amount", headerName: "Pay Period Deduction Amount" },
];
