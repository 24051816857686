import { motion, Reorder } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Button, Formblock, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";

import { useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";
import { FrontendModel } from "dashboard/miter";
import { styles } from "miter-components/forms";

import ObjectID from "bson-objectid";
import { isEmpty, set } from "lodash";
import { Trash } from "phosphor-react";
import { FormComponent as FormComponent_, FormSection as FormSection_ } from "backend/models/form";
import { DateTime } from "luxon";
import { notNullish } from "miter-utils";
import { FormProvider, useForm } from "react-hook-form";
import { FormField } from "dashboard/types/form-types";
import { FormComponentFormField, MiterFormComponent } from "../FormBuilderScreen";

export type FormSection = FrontendModel<FormSection_>;
export type FormComponent = FrontendModel<FormComponent_>;

type Configurations = {
  validations: {
    minFields?: number;
  };
};

type Props = {
  fields: FormField[];
  name: string;
  onSave: (fields: FormField[]) => Promise<void>;
  acceptableFieldTypes?: FormComponentFormField["type"][];
  parentType?: "certification_type";
  description?: string;
  configurations?: Configurations;
};

export const FormlessFormBuilderScreen: React.FC<Props> = ({
  name,
  fields,
  onSave,
  acceptableFieldTypes = ["text"],
  parentType,
  description,
  configurations,
}) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const sectionsRef = useRef<HTMLDivElement>(null);
  const componentRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const form = useForm({
    mode: "all",
  });
  const { formState, clearErrors } = form;

  const defaultFieldType = acceptableFieldTypes[0] || "text";

  const buildDefaultFields = (): FormComponentFormField[] => {
    if (!fields.length) return [buildDefaultField(defaultFieldType, false)];
    return fields;
  };

  /*********************************************************
   * High level form states
   **********************************************************/
  const [draggable, setDraggable] = useState(false);
  const [formFields, setFormFields] = useState<FormComponentFormField[]>(buildDefaultFields());
  // some validations are not controlled by react-hook-form, so we need to manually validate them
  const [errors, setErrors] = useState({});

  /*********************************************************
   * Field states
   **********************************************************/
  const [lastDraggedComponent, setLastDraggedComponent] = useState<FormComponentFormField>();

  /*********************************************************
   * useEffect's
   **********************************************************/

  useEffect(() => {
    if (curIndex === screens.length - 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on manual and react-hook-form validations
  useEffect(() => {
    if (
      Object.values(errors).filter(notNullish).length > 0 ||
      !isEmpty(formState.errors) ||
      formFields.length < (configurations?.validations?.minFields || 0)
    ) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formState, formFields.length]);

  // Wizard handlers
  const onNext = async () => {
    await onSave(buildParams(formFields));

    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  /*********************************************************
   *  Backend functions
   **********************************************************/
  const buildParams = (fields: FormComponentFormField[]): FormField[] => {
    if (!activeCompanyId || !activeUser) throw new Error("No active user");

    return fields.map((c) => {
      const cleanedComponent = {
        ...c,
        max_files_allowed:
          parentType === "certification_type"
            ? 1
            : "max_files_allowed" in c
            ? c.max_files_allowed
            : undefined,
      };

      if (c.type === "date") {
        const minDate = c.validations?.min_date
          ? typeof c.validations?.min_date === "string"
            ? DateTime.fromISO(c.validations?.min_date)
            : c.validations?.min_date
          : undefined;

        const maxDate = c.validations?.max_date
          ? typeof c.validations?.max_date === "string"
            ? DateTime.fromISO(c.validations?.max_date)
            : c.validations?.max_date
          : undefined;

        cleanedComponent.validations = {
          ...cleanedComponent.validations,
          min_date: minDate?.toISODate(),
          max_date: maxDate?.toISODate(),
        };
      }

      return cleanedComponent as FormField;
    });
  };

  /*********************************************************
   * Form component movement handlers
   **********************************************************

  /** Handle's any drag events that changes the order of the fields */
  const handleFieldsReorder = (newlyOrderedFields: FormComponentFormField[]) => {
    const updatedComponents = [...newlyOrderedFields];

    // Get the field that was moved by comparing with the last dragged component
    const movedFieldIndex = newlyOrderedFields.findIndex((field) => field._id === lastDraggedComponent?._id);

    // If the field that was moved, doesn't exist, return
    if (movedFieldIndex === -1) return;

    // Save the field that was moved
    const movedField = updatedComponents[movedFieldIndex];
    if (!movedField) return;

    // Get the field above the moved field
    const fieldAbove = updatedComponents[movedFieldIndex - 1];

    // If there is no field above this field, set the field's form section id to null (meaning it's not in a section)
    if (!fieldAbove) {
      movedField.form_section_id = null;
    } else {
      // If the field above is a section field, set the field's form section id to the field above's id
      if (fieldAbove.type === "section") {
        movedField.form_section_id = fieldAbove._id;
      } else {
        // If the field above is not a section field, set the field's form section id to the field above's form section id
        movedField.form_section_id = fieldAbove.form_section_id;
      }
    }

    setFormFields(updatedComponents);
  };

  /*********************************************************
   *  Form field handlers
   **********************************************************/
  const handleParseValue = (value: string | boolean) => {
    // If it's only digits, parse it as a number
    if (/^\d+$/.test(value.toString())) return Number(value.toString());
    return value;
  };

  /** Handles the change of a field's value */
  const handleFieldChange = (key: string, value: string | boolean, index: number) => {
    const updatedComponents = [...formFields];
    if (!updatedComponents[index]) return;

    set(updatedComponents[index]!, key, handleParseValue(value));
    setFormFields(updatedComponents);
  };

  /*********************************************************
   *  Form manager functions
   **********************************************************/

  /** Add a new field to the form */
  const handleAddField = (type: FormComponentFormField["type"]) => {
    const newField: FormComponentFormField = buildDefaultField(type, false);
    const index = formFields.length - 1;
    setFormFields([...formFields.slice(0, index + 1), newField, ...formFields.slice(index + 1)]);
  };

  /** Deletes a field and it's children */
  const handleDeleteField = (formFieldId: string) => {
    const errorKey = `components.${formFieldId}`;

    const updatedComponents = formFields.filter((c) => {
      return c._id !== formFieldId;
    });

    // clear out errors from deleted fields
    clearErrors(errorKey);
    setErrors((errors) => {
      const newErrors = { ...errors };
      Object.keys(newErrors).forEach((key) => {
        if (key.includes(errorKey)) {
          delete newErrors[key];
        }
      });

      return newErrors;
    });

    setFormFields(updatedComponents);
  };

  /*********************************************************
   *  General render functions
   **********************************************************/

  /** The form manager allow the user to add new component and manage the active component */
  const renderAddFieldButton = () => {
    return (
      <motion.div transition={{ type: "spring", stiffness: 500, damping: 30 }}>
        <div className={styles["form-builder-manager-components"]}>
          {acceptableFieldTypes.map((type) => (
            <Button
              key={type}
              className="button-1 tall-button"
              onClick={() => handleAddField(type)}
              style={{ marginLeft: 0 }}
            >
              {`Add ${type} field`}
            </Button>
          ))}
        </div>
      </motion.div>
    );
  };

  const renderTextOrParagraphField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;
    const placeholderKey = `${key}.placeholder`;
    const requiredKey = `${key}.validations.required`;

    return (
      <MiterFormComponent
        component={component}
        onClick={() => {}}
        index={index}
        onFieldChange={handleFieldChange}
        fieldNamePlaceholder={calculatePlaceholderName(component.type, parentType)}
      >
        <div className={styles["miter-form-component-body"]}>
          {"placeholder" in component && (
            <Formblock
              className={"modal "}
              placeholder="Placeholder"
              type="text"
              name={placeholderKey}
              editing={true}
              onChange={(e) => handleFieldChange("placeholder", e.target.value, index)}
              defaultValue={component?.placeholder}
              disabled={component.readonly}
            />
          )}
          <div className="flex space-between align-items-center">
            {"validations" in component && (
              <Formblock
                className={"modal "}
                text="This field is required"
                type="checkbox"
                name={requiredKey}
                editing={true}
                onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
                defaultValue={component?.validations?.required}
                style={{ marginBottom: 0 }}
                disabled={component.readonly}
              />
            )}
            <Button
              className="button-1 no-margin"
              onClick={() => handleDeleteField(component._id)}
              style={{ height: 36, width: 36, marginTop: 10 }}
            >
              <Trash style={{ marginBottom: -2, marginLeft: 0 }} size={18} />
            </Button>
          </div>
        </div>
      </MiterFormComponent>
    );
  };

  const renderFileField = (component: FormComponentFormField, index: number) => {
    const key = `components.${component._id}`;
    const requiredKey = `${key}.validations.required`;

    return (
      <MiterFormComponent
        component={component}
        onClick={() => {}}
        index={index}
        onFieldChange={handleFieldChange}
        fieldNamePlaceholder={calculatePlaceholderName(component.type, parentType)}
      >
        <div className={styles["miter-form-component-body"]}>
          <div className="flex space-between align-items-center">
            {"validations" in component && (
              <Formblock
                className={"modal "}
                text="This field is required"
                type="checkbox"
                name={requiredKey}
                editing={true}
                onChange={(e) => handleFieldChange("validations.required", e.target.checked, index)}
                defaultValue={component?.validations?.required}
                style={{ marginBottom: 0 }}
                disabled={component.readonly}
              />
            )}
            <Button
              className="button-1 no-margin"
              onClick={() => handleDeleteField(component._id)}
              style={{ height: 36, width: 36, marginTop: 10 }}
            >
              <Trash style={{ marginBottom: -2, marginLeft: 0 }} size={18} />
            </Button>
          </div>
        </div>
      </MiterFormComponent>
    );
  };

  const renderComponent = (component: FormComponentFormField, index: number) => {
    return (
      <Reorder.Item
        key={"section-" + component._id}
        value={component}
        dragListener={draggable}
        className={styles["form-builder-section-container"]}
        onDragStart={() => setLastDraggedComponent(component)}
        onDragEnd={() => setDraggable(false)}
        layout="position"
        ref={(r) => (componentRefs.current[component._id] = r)}
        id={component._id}
      >
        <div style={{ position: "relative" }}>
          {component.type === "text" && renderTextOrParagraphField(component, index)}
          {component.type === "file" && renderFileField(component, index)}
        </div>
      </Reorder.Item>
    );
  };

  return (
    <FormProvider {...form}>
      <WizardScreen name={name} onNext={onNext}>
        <div className={styles["formless-content"]}>
          {description && <p className={styles["subheader-description"]}>{description}</p>}
          <div className={styles["form-builder-sections"]}>
            <Reorder.Group
              className={styles["form-builder-sections-container"]}
              axis="y"
              values={formFields}
              onReorder={handleFieldsReorder}
              ref={sectionsRef}
            >
              {formFields.map((component, index) => renderComponent(component, index))}
            </Reorder.Group>
          </div>
          {renderAddFieldButton()}
        </div>
      </WizardScreen>
    </FormProvider>
  );
};

const buildDefaultField = (
  type: FormComponentFormField["type"],
  isMultiple: boolean
): FormComponentFormField => {
  return {
    _id: ObjectID().toHexString(),
    type: type,
    name: "",
    description: "",
    validations: { required: false },
    placeholder: "",

    options: type === "select" ? [{ _id: ObjectID().toHexString(), value: "Option 1" }] : [],
    multiple: type === "select" ? !!isMultiple : undefined,
  };
};

const calculatePlaceholderName = (
  type: FormComponentFormField["type"],
  parentType?: "certification_type"
) => {
  if (parentType === "certification_type") {
    switch (type) {
      case "file":
        return "E.g. Front photo of license";
      default:
        return "E.g. License number";
    }
  }

  return type === "text" ? "Text field" : "File upload";
};
