import { Conversation } from "dashboard/miter";
import { useQuery } from "miter-utils";
import React, { useEffect, useState } from "react";
import { FaBullhorn, FaComments, FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { Button, Loader } from "ui";
import EmptyConversations from "../../../assets/empty-conversations.svg";
import styles from "../Chat.module.css";
import TeamCreateBroadcastModal from "./TeamCreateBroadcastModal";
import TeamCreateConversationModal from "./TeamCreateConversationModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import {
  useActiveCompany,
  useActiveTeamChatConversation,
  useIsTeamChatInitialized,
  useLookupTeam,
  useTeamChatConversations,
} from "dashboard/hooks/atom-hooks";
import { ConversationItem } from "../ConversationItem";
import { useTeamChatUtilities } from "dashboard/hooks/chat/useTeamChatUtilities";

const TeamConversationList: React.FC = () => {
  /**********************************************************************************
   *  Hooks and states
   **********************************************************************************/
  // Chat atom hooks
  const activeTeamConversation = useActiveTeamChatConversation();
  const teamConversations = useTeamChatConversations();
  const isTeamChatInitialized = useIsTeamChatInitialized();

  const navigate = useNavigate();
  const query = useQuery();
  const miterAbilities = useMiterAbilities();
  const activeCompany = useActiveCompany();
  const hasChat = !!activeCompany?.chat?.conversations_service_sid;
  const lookupTeam = useLookupTeam();

  const [search, setSearch] = useState("");
  const [showCreateDropdown, setShowCreateDropdown] = useState(false);
  const [showCreateConversationModal, setShowCreateConversationModal] = useState(false);
  const [showCreateBroadcastModal, setShowCreateBroadcastModal] = useState(false);
  const [filter, setFilter] = useState<"unread" | "all">("all");

  const { filterConversations } = useTeamChatUtilities();

  const filteredConversations = filterConversations(search, filter);

  // Use effect function to open the broadcast modal for the quick action
  useEffect(() => {
    const showBroadcast = query.get("show") === "broadcast";

    if (showBroadcast && isTeamChatInitialized) {
      setShowCreateBroadcastModal(true);
    }
  }, [isTeamChatInitialized]);

  /**********************************************************************************
   *  Handler functions
   **********************************************************************************/

  const handleOpenCreateConversationModal = () => {
    setShowCreateConversationModal(true);
    setShowCreateDropdown(false);
  };

  const handleOpenCreateBroadcastModal = () => {
    setShowCreateBroadcastModal(true);
    setShowCreateDropdown(false);
  };

  const handleSubmitModal = () => {
    setShowCreateConversationModal(false);
    setShowCreateBroadcastModal(false);
  };

  const handleConversationClick = (conversation: Conversation) => {
    navigate("/chat/" + conversation._id);
  };

  const renderConversations = () => {
    if (!isTeamChatInitialized && hasChat) {
      return (
        <div className={styles["no-conversations"]}>
          <Loader />
        </div>
      );
    }

    if (teamConversations.length === 0) {
      return (
        <div className={styles["no-conversations"]}>
          <img src={EmptyConversations} alt="No conversations" />
        </div>
      );
    }

    if (filteredConversations.length === 0) {
      return (
        <div className={styles["no-conversations"]}>
          <img src={EmptyConversations} alt="No conversations" />
          <p>No conversations found.</p>
        </div>
      );
    }

    return filteredConversations.map((conversation) => {
      const id = conversation._id;
      const unread = conversation.unread;
      const lastMessage = conversation.last_message?.body || conversation.last_message?.media?.filename || "";
      const dateCreated =
        conversation.last_message?.dateCreated || conversation.twilio_conversation?.dateCreated;
      const name = lookupTeam(conversation?.team_member)?.full_name || "Unknown";
      const active = conversation._id === activeTeamConversation?._id;
      return (
        <ConversationItem
          key={id}
          id={id}
          lastMessage={lastMessage}
          dateCreated={dateCreated}
          name={name}
          unread={unread}
          active={active}
          handleClick={() => handleConversationClick(conversation)}
        />
      );
    });
  };

  /**********************************************************************************
   *  Render functions
   **********************************************************************************/
  const renderConversationSearchAndCreate = () => {
    return (
      <div className={styles["conversation-search-and-add-container"]}>
        <input
          className={"form2-text " + styles["conversations-search"]}
          type="text"
          placeholder="Search conversations"
          onChange={(e) => setSearch(e.target.value)}
        />
        {miterAbilities.can("chat:send") && renderCreateDropdown()}
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className={styles["conversations-filters"]}>
        <Button
          className={"no-margin " + (filter === "all" ? "button-2" : "button-1")}
          onClick={() => setFilter("all")}
        >
          All
        </Button>
        <Button
          className={"" + (filter === "unread" ? "button-2" : "button-1")}
          onClick={() => setFilter("unread")}
        >
          Unread
        </Button>
      </div>
    );
  };

  const renderCreateDropdown = () => {
    const dropdown = (
      <div className={styles["create-dropdown"]}>
        <div className={styles["dropdown-item"]} onClick={handleOpenCreateConversationModal}>
          <FaComments className={styles["dropdown-icon"]} />
          Create a conversation
        </div>
        <div className={styles["dropdown-item"]} onClick={handleOpenCreateBroadcastModal}>
          <FaBullhorn className={styles["dropdown-icon"]} />
          Send a broadcast
        </div>
      </div>
    );

    return (
      <Popover isOpen={showCreateDropdown} positions={["bottom"]} align="end" content={dropdown}>
        <button
          className={"button-2 no-margin " + styles["new-chat-button"]}
          onClick={() => setShowCreateDropdown(!showCreateDropdown)}
        >
          {showCreateDropdown ? <FaTimes style={{ marginTop: 3 }} /> : <FaPlus style={{ marginTop: 3 }} />}
        </button>
      </Popover>
    );
  };

  return (
    <>
      <div className={styles["conversation-list-container"]}>
        <div className={styles["chat-subheader"] + " " + styles["conversations-list-subheader"]}>
          <h2 className={styles["chat-subheader-title"]}>Conversations</h2>
          {renderConversationSearchAndCreate()}
          {renderFilters()}
        </div>
        <div className={styles["conversations-list"]}>{renderConversations()}</div>
        <div className="chat-list-footer" />
      </div>
      {showCreateConversationModal && miterAbilities.can("chat:send") && (
        <TeamCreateConversationModal
          onCancel={() => setShowCreateConversationModal(false)}
          onCreate={() => handleSubmitModal()}
        />
      )}

      {showCreateBroadcastModal && miterAbilities.can("chat:send") && (
        <TeamCreateBroadcastModal
          onCancel={() => setShowCreateBroadcastModal(false)}
          onCreate={() => handleSubmitModal()}
        />
      )}
    </>
  );
};

export default TeamConversationList;
