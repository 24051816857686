import { Eraser, PencilLine } from "phosphor-react";
import React, { FC, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";

export type ESignatureInputValue = {
  /** The method used to sign: drawn means using signature page and typed means typed out */
  type?: "canvas" | "text";
  /** Base64 image of the signature */
  data?: string;
  /** Whether or not electronic signature consent was given */
  agreed_to_disclosure?: boolean;
};

type Props = {
  disclosure?: string;
  agreement?: string;
  agreementAffirmationText?: string;
  value?: ESignatureInputValue | null | undefined;
  onChange: (value: ESignatureInputValue | null | undefined) => void;
};

const ESignatureInput: FC<Props> = ({ disclosure, agreement, agreementAffirmationText, value, onChange }) => {
  const canvasRef = useRef<SignatureCanvas>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const form = useFormContext();

  const handleOnChange = (e) => {
    onChange(e);
    form?.trigger();
  };

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  /*********************************************************
   * useEffect functions
   **********************************************************/
  /** Make sure canvas takes full width of parent */
  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current.getCanvas();

    canvas.style.width = "100%";
    canvas.style.height = "200px";
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  }, [canvasRef.current]);

  /*********************************************************
   * Placholder functions
   **********************************************************/
  const handleSigningStart = () => {
    setShowPlaceholder(false);
  };

  /*********************************************************
   * Clearing functions
   **********************************************************/
  const clearCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
    }

    setShowPlaceholder(true);
    handleOnChange({ type: "canvas", agreed_to_disclosure: value?.agreed_to_disclosure });
  };

  const clearTextInput = () => {
    if (textInputRef.current) {
      textInputRef.current.value = "";
    }
  };

  /*********************************************************
   * Signature input handlers
   **********************************************************/
  const handleCanvasSign = () => {
    if (!canvasRef.current) return;
    clearTextInput();

    handleOnChange({
      type: "canvas",
      data: canvasRef.current.getTrimmedCanvas().toDataURL(),
      agreed_to_disclosure: value?.agreed_to_disclosure,
    });
  };

  const handleTextSign = (e) => {
    // Make sure that the canvas isn't currently active
    clearCanvas();

    const signature = e.target.value;

    // If the signature is empty, return data as undefined
    if (!signature || signature.length === 0) {
      handleOnChange({ type: "text", data: undefined, agreed_to_disclosure: value?.agreed_to_disclosure });
      return;
    }

    const canvas = window.document.createElement("canvas");

    // Add the signature to the canvas
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.font = "50px Dancing Script";
      ctx.fillStyle = "black";
      ctx.fillText(signature, 0, 50);

      canvas.width = ctx.measureText(signature).width;
      canvas.height = 70;

      ctx.font = "50px Dancing Script";
      ctx.fillStyle = "black";
      ctx.fillText(signature, 0, 50);
    }

    handleOnChange({
      type: "text",
      data: canvas.toDataURL(),
      agreed_to_disclosure: value?.agreed_to_disclosure,
    });
  };

  const handleDisclosureChange = (e) => {
    handleOnChange({ ...value, agreed_to_disclosure: e.target.checked });
  };

  const renderCanvasPlaceholder = () => {
    if (!showPlaceholder) return;
    return (
      <p className={"esignature-canvas-placeholder"}>
        <PencilLine style={{ marginBottom: -3, marginRight: 5 }} /> Draw your signature in this box
      </p>
    );
  };

  return (
    <div className="esignature-input-container">
      <div className="esignature-canvas-container">
        <button className="button-1 clear-esignature-input" onClick={clearCanvas}>
          <Eraser style={{ marginTop: 3 }} />
        </button>
        <SignatureCanvas
          ref={canvasRef}
          onBegin={handleSigningStart}
          onEnd={handleCanvasSign}
          clearOnResize={true}
          canvasProps={{ style: { background: "#fff", border: "1px solid #ddd", borderRadius: 4 } }}
        />
        {renderCanvasPlaceholder()}
      </div>
      <input
        ref={textInputRef}
        className="form2-text esignature-text-input"
        type="text"
        onChange={handleTextSign}
        placeholder="Or type your full name here"
      />

      <div className="checkbox-input-wrapper" style={{ marginBottom: 10 }}>
        <input type="checkbox" checked={value?.agreed_to_disclosure} onChange={handleDisclosureChange} />
        <div className="checkbox-text">
          <span style={{ opacity: 0.8, fontSize: 15 }}>
            {agreementAffirmationText
              ? agreementAffirmationText
              : "I agree to electronically sign this document"}
          </span>
        </div>
      </div>
      {agreement && (
        <div className="esignature-agreement">{"By signing, you agree to the following: " + agreement}</div>
      )}
      <div className={"esignature-input-disclosure"}>
        {disclosure ? (
          disclosure
        ) : (
          <>
            By selecting the &quot;I agree to electronically sign this document&quot; checkbox, you are
            signing this Document electronically. You agree your electronic signature is the legal equivalent
            of your manual/handwritten signature on this Document. <br />
            <br />
            By selecting &quot;I agree to electronically sign this document&quot; using any device, means or
            action, you consent to the legally binding terms and conditions of this Agreement. You further
            agree that your signature on this document (hereafter referred to as your &quot;E-Signature&quot;)
            is as valid as if you signed the document in writing. You also agree that no certification
            authority or other third party verification is necessary to validate your E-Signature, and that
            the lack of such certification or third party verification will not in any way affect the
            enforceability of your E-Signature.
          </>
        )}
      </div>
    </div>
  );
};

export default ESignatureInput;
