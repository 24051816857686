import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { ImportResult, MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../../../components/importer/Importer";
import { Notifier } from "ui";
import { FlatfileResults } from "@flatfile/react";
import { changeToTitleCasing } from "dashboard/utils";
import { UnionRateImportRecord } from "backend/services/union-rate-service";

type Props = {
  payRateGroup?: string;
  onFinish: (importResult?: ImportResult) => void;
};

export const ClassificationsImporter: React.FC<Props> = ({ payRateGroup, onFinish }) => {
  const companyId = useActiveCompanyId();
  const validateAmount = (value: string) => {
    const amount = Number(value);

    // invalid if there is a value but its not a number or its a negative number
    if (value && (isNaN(amount) || amount < 0)) {
      return buildFlatfileMessage("Must be a positive number or empty", value, "error");
    }
    return { value };
  };

  const handleSubmit = async (results: FlatfileResults) => {
    try {
      const cleanInput = results.validData.map((input: UnionRateImportRecord) => {
        const cleanPayRateGroup = input.payRateGroup
          ? changeToTitleCasing(input.payRateGroup?.trim())
          : undefined;

        const cleanClassification = input.classification.trim();

        return {
          ...input,
          payRateGroup: cleanPayRateGroup,
          classification: cleanClassification,
          alwaysPay: input.alwaysPay,
        };
      });

      const response = await MiterAPI.union_rates.import_classifications({
        company: companyId || "",
        clean_inputs: cleanInput,
        pay_rate_group_id: payRateGroup,
      });

      if (response.error) throw new Error(response.error);
      onFinish(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating classifications.");
    }

    return;
  };
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Classification",
        type: "string",
        key: "classification",
        validators: [{ validate: "required" }],
      },
      {
        label: "Base rate $/hour",
        type: "string",
        key: "baseRate",
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record === "string" ? { value: record } : validateAmount(record.baseRate)),
      },
      {
        label: "Fringe rate $/hour",
        type: "string",
        key: "fringeRate",
        hook: (record) =>
          typeof record === "string" ? { value: record } : validateAmount(record.fringeRate),
      },
      {
        label: "Always use base rate",
        type: "checkbox",
        key: "alwaysPay",
        validators: [{ validate: "required" }],
      },
    ];

    if (!payRateGroup) {
      fieldList.push({
        label: "Pay rate group",
        type: "string",
        key: "payRateGroup",
        description: "Name of pay rate group",
        validators: [{ validate: "required" }],
      });
    }
    return fieldList;
  }, [payRateGroup]);

  return (
    <Importer
      title="Import classifications"
      id="classifications"
      resource="classifications"
      onSave={handleSubmit}
      fields={fields}
    />
  );
};
