/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { FormFields } from "../forms/FormSubmissionWizard";
import { Link, WizardScreen } from "ui";
import { FormSubmission, UpdateFileParams } from "dashboard/miter";
import { FormProvider, useForm } from "react-hook-form";
import { cleanCustomFieldValueParams, downloadFiles } from "miter-utils";
import { Notifier } from "ui";
import { isEmpty } from "lodash";
import useWizard from "ui/modal/useWizard";
import { saveFillableDocumentAnswers } from "./utils";
import ObjectID from "bson-objectid";
import styles from "./FillFillableDocument.module.css";
import { FillableDocumentScreenProps } from "./FillableDocumentWizard";

export const FillFillableDocumentScreen: FC<FillableDocumentScreenProps> = ({
  fillableDocument,
  name,
  teamMember,
  role,
  userId,
  setFillableDocument,
  isSuperAdmin,
  application,
}) => {
  const { fillable_template: fillableTemplate, recipient } = fillableDocument;
  const { setCanNext, handleComplete, screens, curIndex } = useWizard();
  const [_, setDownloadingPreview] = useState(false);

  const form = useForm<{ [_id: string]: any }>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
  });

  const { trigger, errors: formErrors, formState } = form;

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    if (!isEmpty(formErrors) && !isEmpty(formState.errors)) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [formState.errors, formErrors]);

  const handlePreviewClick = async () => {
    setDownloadingPreview(true);
    downloadFiles([fillableDocument._id], setDownloadingPreview);
    setDownloadingPreview(false);
  };

  const fillableDocAnswers = fillableDocument?.answers || [];

  const prepareFormSubmissionParams = (): UpdateFileParams => {
    const formData = form.getValues();

    const answers = Object.keys(formData)
      // Sort into the correct order based on index
      .sort((a, b) => {
        const fieldA = fillableTemplate.inputs.findIndex((f) => f._id === a);
        const fieldB = fillableTemplate.inputs.findIndex((f) => f._id === b);

        return fieldA - fieldB;
      })
      // use string regex to determine key is a valid mongo id
      .filter(ObjectID.isValid)
      .map((key) => ({
        _id: ObjectID().toHexString(),
        form_field_id: key,
        value: cleanCustomFieldValueParams(formData[key]),
      }))
      // only keep responses that have a value
      .filter((answer) => answer.value !== undefined && answer.value !== "");

    const combinedAnswers = answers.concat(
      fillableDocAnswers.filter((a) => !answers.some((ans) => ans.form_field_id === a.form_field_id))
    );

    const allRequiredFilled = fillableTemplate.inputs.every((input) => {
      if (!input?.validations?.required) return true;
      return combinedAnswers.some((answer) => answer.form_field_id === input._id);
    });

    return {
      _id: fillableDocument._id,
      fill_status: allRequiredFilled ? "complete" : "pending",
      answers: combinedAnswers,
    };
  };

  /** Save the form submission */
  const saveFormSubmission = async () => {
    try {
      const params = prepareFormSubmissionParams();
      const res = await saveFillableDocumentAnswers({
        fillableDocumentId: fillableDocument._id,
        params,
        fillableTemplate,
        account: {
          company_id: fillableTemplate.company_id,
          full_name: teamMember?.full_name || role?.email || "Role",
          roleId: role?._id,
          userId,
          accountType: role ? "admin" : "team_member",
          title: teamMember?.title || "Administrator",
        },
        deviceType: "desktop",
      });
      setFillableDocument({ ...fillableDocument, answers: res.answers });
      if (curIndex == screens.length - 1) handleComplete();
      Notifier.success("Fillable document saved.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      // Re-throw the error so the wizard doesn't continue
      throw e;
    }
  };

  const setAnswers = (answers: FormSubmission["answers"]) => {
    setFillableDocument({
      ...fillableDocument,
      answers,
    });
  };

  return (
    <WizardScreen name={name} onNext={saveFormSubmission}>
      <div className={styles["main-wizard-container"]}>
        <div className={styles["header"]}>
          <h2 className={styles["subheader-title"]}>{fillableTemplate.name}</h2>
          <div className={styles["subheader"]}>
            {application === "dashboard" && (
              <div className={styles["subheader-description"]}>
                <div style={{ marginRight: 4 }}>Document owner:</div>
                <div>{recipient.full_name}</div>
              </div>
            )}
            <Link
              style={{
                fontSize: "0.9rem",
                opacity: 0.7,
              }}
              onClick={handlePreviewClick}
            >
              Open PDF form
            </Link>
          </div>
        </div>
        <FormProvider {...form}>
          <FormFields
            groupedFormComponents={{ fields: fillableTemplate.inputs }}
            answers={fillableDocAnswers}
            overrideRequired={isSuperAdmin}
            setAnswers={setAnswers}
          />
        </FormProvider>
      </div>
    </WizardScreen>
  );
};
