import React from "react";
import { ConfirmModal } from "ui";
import {
  Address,
  AggregatedEmployeeBenefit,
  CompanyBenefit,
  EmployeeBenefit,
  PayrollPayment,
  TeamMember,
} from "dashboard/miter";
import { CheckBenefit, CheckBenefitType } from "backend/utils/check/check-types";
import { Assign } from "utility-types";
import { ColumnConfig } from "ui/table-v2/Table";
import { useContext, useMemo } from "react";
import AppContext from "dashboard/contexts/app-context";
import { EditableCallbackParams, IRowNode, ValueGetterParams } from "ag-grid-community";
import {
  useActiveCompanyId,
  useCostTypeFormatter,
  useCostTypeOptions,
  useLedgerAccountLabeler,
  useLedgerAccountOptions,
  useVendorNameFormatter,
  useVendorOptions,
} from "dashboard/hooks/atom-hooks";
import { CONTRIBUTION_TYPE_LABEL_LOOKUP, CONTRIBUTION_TYPE_OPTIONS } from "./BenefitFormUtils";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";
import { includeWithEarningOption } from "../accounting/accountingUtils";
import { isActive, isValidAddress } from "miter-utils";
import { EmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";
import { AMERICAN_ROOFING_METAL_COMPANY_IDS, GREEN_MECHANICAL_COMPANY_ID } from "dashboard/utils/constants";
import { TableReadyTm, isTmActive } from "../team-members/TeamUtils";
import { usdString } from "ui";
import { PtdTableEntry } from "../post-tax-deductions/PostTaxDeductions";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { Option } from "backend/utils";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { EnhancedMiterPayment } from "../payrolls/payrollTypes";
import { checkBenefitIsPreTax } from "../payrolls/viewPayroll/viewPayrollUtils";

export const benefitTypes: Option<CheckBenefitType>[] = [
  { value: "125_medical", label: "Medical" },
  { value: "125_dental", label: "Dental" },
  { value: "125_disability", label: "Disability" },
  { value: "125_accident", label: "Accident" },
  { value: "125_vision", label: "Vision" },
  { value: "125_life", label: "Life" },
  { value: "125_cancer", label: "Cancer" },
  { value: "125_critical_illness", label: "Critical Illness" },
  { value: "125_hospital", label: "Hospital" },
  { value: "125_medical_other", label: "125 Medical other" },
  { value: "401k", label: "401k" },
  { value: "roth_401k", label: "Roth 401k" },
  { value: "fsa_medical", label: "FSA Medical" },
  { value: "fsa_dependent_care", label: "FSA Dependent Care" },
  { value: "hsa", label: "HSA" },
  { value: "simple_ira", label: "SIMPLE IRA" },
];

export const benefitLabelLookup = Object.fromEntries(benefitTypes.map((b) => [b.value, b.label]));

export const contributionTypes = [
  { value: "amount", label: "$ per payroll" },
  { value: "percent", label: "% of employee earnings" },
];

type BenefitConfig = {
  type: CheckBenefitType | "Guideline";
  label: string;
  color: string;
  post_tax?: boolean;
};

const benefitList: BenefitConfig[] = [
  {
    type: "125_medical",
    label: "Medical",
    color: "green",
  },
  {
    type: "125_vision",
    label: "Vision",
    color: "light-purple",
  },
  {
    type: "125_dental",
    label: "Dental",
    color: "light-blue",
  },
  {
    type: "401k",
    label: "401k",
    color: "red",
  },
  {
    type: "125_disability",
    label: "Disability",
    color: "bright-orange",
  },
  {
    type: "125_accident",
    label: "Accident",
    color: "gray",
  },
  {
    type: "125_life",
    label: "Life",
    color: "yellow",
  },
  {
    type: "125_cancer",
    label: "Cancer",
    color: "light-gray",
  },
  {
    type: "125_critical_illness",
    label: "Critical illness",
    color: "light-gray",
  },
  {
    type: "125_hospital",
    label: "Hospital",
    color: "gray",
  },
  {
    type: "125_medical_other",
    label: "Medical supplemental",
    color: "green",
  },
  {
    type: "403b",
    label: "403b",
    color: "red",
    post_tax: true,
  },
  {
    type: "457",
    label: "457",
    color: "red",
    post_tax: true,
  },
  {
    type: "roth_401k",
    label: "Roth 401k",
    color: "red",
    post_tax: true,
  },
  {
    type: "roth_403b",
    label: "Roth 403b",
    color: "red",
  },
  {
    type: "roth_457",
    label: "Roth 457",
    color: "red",
  },
  {
    type: "fsa_medical",
    label: "FSA medical",
    color: "blue",
  },
  {
    type: "fsa_dependent_care",
    label: "FSA dependent care",
    color: "blue",
  },
  {
    type: "hsa",
    label: "HSA",
    color: "light-green",
  },
  {
    type: "simple_ira",
    label: "SIMPLE IRA",
    color: "red",
  },
  {
    type: "Guideline",
    label: "Guideline",
    color: "red",
  },
];

export const benefitMap = Object.fromEntries(benefitList.map((o) => [o.type, o])) as Record<
  CheckBenefitType | "Guideline",
  BenefitConfig
>;

export const benefitSelections = benefitList
  .filter((o) => o.type !== "Guideline")
  .map((o) => ({
    value: o.type,
    label: `${o.label} (${o.post_tax ? "post-tax" : "pre-tax"})`,
  }));

export const benefitTypeColors = benefitList.reduce(
  (acc, cur) => ({ ...acc, [cur.label]: cur.color }),
  {} as Record<string, string>
);

const getCoContributionType = (benefit: CheckBenefit | CompanyBenefit, hourly: boolean | undefined) => {
  if (hourly) {
    return "per_hour";
  } else if (benefit.company_contribution_amount) {
    return "contribution_amount";
  } else if (benefit.company_contribution_percent != null) {
    return "contribution_percent";
  } else if (benefit.company_period_amount) {
    return "period_amount";
  } else {
    return "-";
  }
};

const getEmpContributionType = (benefit: CheckBenefit | CompanyBenefit, hourly: boolean | undefined) => {
  if (hourly) {
    return "per_hour";
  } else if (benefit.employee_contribution_amount) {
    return "contribution_amount";
  } else if (benefit.employee_contribution_percent != null) {
    return "contribution_percent";
  } else if (benefit.employee_period_amount) {
    return "period_amount";
  } else {
    return "-";
  }
};

const getCompanyContributionText = (benefit: CheckBenefit | CompanyBenefit, hourly: boolean | undefined) => {
  const contributionAmt = usdString(Number(benefit.company_contribution_amount));
  if (hourly) {
    return contributionAmt + "/hour";
  } else if (benefit.company_contribution_amount) {
    return contributionAmt + "/payroll";
  } else if (benefit.company_contribution_percent != null) {
    return benefit.company_contribution_percent + "%";
  } else if (benefit.company_period_amount) {
    return usdString(Number(benefit.company_period_amount)) + " (monthly)";
  } else {
    return "-";
  }
};

const getEmployeeContributionText = (benefit: CheckBenefit | CompanyBenefit, hourly: boolean | undefined) => {
  const contributionAmt = usdString(Number(benefit.employee_contribution_amount));
  if (hourly) {
    return contributionAmt + "/hour";
  } else if (benefit.employee_contribution_amount) {
    return contributionAmt + "/payroll";
  } else if (benefit.employee_contribution_percent != null) {
    return benefit.employee_contribution_percent + "%";
  } else if (benefit.employee_period_amount) {
    return usdString(Number(benefit.employee_period_amount)) + " (monthly)";
  } else {
    return "-";
  }
};

type UnifiedBenefitCore = {
  _id: string;
  benefit_type: CheckBenefitType | "Guideline";
  description: string | null | undefined;
  per_hour_employee_contribution: boolean | undefined;
  per_hour_company_contribution: boolean | undefined;
  employee_contribution_amount: string | null | undefined;
  company_contribution_amount: string | null | undefined;
  employee_contribution_percent: number | null | undefined;
  company_contribution_percent: number | null | undefined;
  employee_period_amount: string | null | undefined;
  company_period_amount: string | null | undefined;
  effective_start: string | null | undefined;
  effective_end: string | null | undefined;
  hsa_contribution_limit: CheckBenefit["hsa_contribution_limit"] | undefined;
  guideline_id: string | undefined;
  non_bonafide: boolean | undefined;
  ot_multipliers: { ot?: number; dot?: number } | null | undefined;
  plan_number: string | undefined;
  integrations: EmployeeBenefit["integrations"];
  emp_liab_account_id: string | null | undefined;
  com_liab_account_id: string | null | undefined;
  expense_account_id: string | null | undefined;
  cost_type_id: string | null | undefined;
  group_term_life_amount: number | null | undefined;
  status: "active" | "inactive";
  vendor_id?: string;
};

export type UnifiedBenefit = UnifiedBenefitCore & {
  benefit_type_label: string;
  employee_contribution_label: string;
  company_contribution_label: string;
  source: string | null | undefined;
};

export type CleanedEmployeeBenefit = Assign<
  UnifiedBenefit,
  {
    company_benefit: string | null | undefined;
    miter_tm: TeamMember;
    employee_check_id: string;
    source: string | null | undefined;

    /** Editing props */
    readonly?: boolean;
    company_contribution_type: string;
    company_contribution_value: number;
    employee_contribution_type: string;
    employee_contribution_value: number;
  }
>;

export const cleanEmployeeBenefit = (
  b: AggregatedEmployeeBenefit,
  benefitAbilities: EmployeeBenefitAbilities
): CleanedEmployeeBenefit => {
  const checkBenefit = b.check_benefit as CheckBenefit;

  return {
    _id: b._id,
    description: checkBenefit.description,
    benefit_type: checkBenefit.benefit,
    benefit_type_label: b.guideline_id ? "Guideline" : benefitMap[checkBenefit.benefit]?.label || "Other",
    per_hour_company_contribution: b.per_hour_company_contribution,
    per_hour_employee_contribution: b.per_hour_employee_contribution,
    employee_contribution_amount: checkBenefit.employee_contribution_amount,
    company_contribution_amount: checkBenefit.company_contribution_amount,
    employee_contribution_percent: checkBenefit.employee_contribution_percent,
    company_contribution_percent: checkBenefit.company_contribution_percent,
    employee_period_amount: checkBenefit.employee_period_amount,
    company_period_amount: checkBenefit.company_period_amount,
    employee_contribution_label: getEmployeeContributionText(checkBenefit, b.per_hour_employee_contribution),
    company_contribution_label: getCompanyContributionText(checkBenefit, b.per_hour_company_contribution),
    company_benefit: b.company_benefit,
    miter_tm: b.employee,
    employee_check_id: checkBenefit.employee,
    effective_start: checkBenefit.effective_start,
    effective_end: checkBenefit.effective_end,
    integrations: b.integrations,
    guideline_id: b.guideline_id,
    hsa_contribution_limit: checkBenefit.hsa_contribution_limit,
    non_bonafide: b.non_bonafide,
    ot_multipliers: b.ot_multipliers,
    plan_number: b.plan_number,
    source: checkBenefit.source,
    emp_liab_account_id: b.emp_liab_account_id,
    com_liab_account_id: b.com_liab_account_id,
    expense_account_id: b.expense_account_id,
    cost_type_id: b.cost_type_id,
    group_term_life_amount: b.group_term_life_amount,
    status:
      isTmActive(b.employee) && isActive(checkBenefit.effective_start, checkBenefit.effective_end)
        ? "active"
        : "inactive",

    /** Editor fields for table */
    readonly: !!(b.guideline_id || b.company_benefit) || benefitAbilities.cannot("update", b),
    company_contribution_type: getCoContributionType(checkBenefit, b.per_hour_company_contribution),
    company_contribution_value: getCoContributionValue(checkBenefit, b.per_hour_company_contribution) || 0,
    employee_contribution_type: getEmpContributionType(checkBenefit, b.per_hour_employee_contribution),
    employee_contribution_value: getEmpContributionValue(checkBenefit, b.per_hour_employee_contribution) || 0,
    vendor_id: b.vendor_id,
  };
};

export type CleanedCompanyBenefit = Assign<CompanyBenefit, UnifiedBenefit>;

export const cleanCompanyBenefit = (b: CompanyBenefit): CleanedCompanyBenefit => {
  return {
    ...b,
    description: b.description,
    benefit_type: b.benefit,
    benefit_type_label: benefitMap[b.benefit]?.label || "Other",
    per_hour_company_contribution: b.per_hour_company_contribution,
    per_hour_employee_contribution: b.per_hour_employee_contribution,
    employee_contribution_amount: b.employee_contribution_amount,
    company_contribution_amount: b.company_contribution_amount,
    employee_contribution_percent: b.employee_contribution_percent,
    company_contribution_percent: b.company_contribution_percent,
    employee_period_amount: b.employee_period_amount,
    company_period_amount: b.company_period_amount,
    employee_contribution_label: getEmployeeContributionText(b, b.per_hour_employee_contribution),
    company_contribution_label: getCompanyContributionText(b, b.per_hour_company_contribution),
    effective_start: b.effective_start,
    effective_end: b.effective_end,
    hsa_contribution_limit: b.hsa_contribution_limit,
    non_bonafide: b.non_bonafide,
    ot_multipliers: b.ot_multipliers,
    plan_number: b.plan_number,
    emp_liab_account_id: b.emp_liab_account_id,
    com_liab_account_id: b.com_liab_account_id,
    expense_account_id: b.expense_account_id,
    cost_type_id: b.cost_type_id,
    guideline_id: undefined,
    integrations: b.integrations,
    source: b.source,
    group_term_life_amount: b.group_term_life_amount,
    status: isActive(b.effective_start, b.effective_end) ? "active" : "inactive",
  };
};

const getCoContributionValue = (data: CheckBenefit, per_hour_company_contribution: boolean | undefined) => {
  if (!data) return 0;

  if (per_hour_company_contribution || data.company_contribution_amount) {
    return Number(data.company_contribution_amount);
  } else if (data.company_contribution_percent != null) {
    return Number(data.company_contribution_percent);
  } else if (data.company_period_amount) {
    return Number(data.company_period_amount);
  } else {
    return 0;
  }
};

const getEmpContributionValue = (data: CheckBenefit, per_hour_employee_contribution: boolean | undefined) => {
  if (!data) return 0;

  if (per_hour_employee_contribution || data.employee_contribution_amount) {
    return Number(data.employee_contribution_amount);
  } else if (data.employee_contribution_percent != null) {
    return Number(data.employee_contribution_percent);
  } else if (data.employee_period_amount) {
    return Number(data.employee_period_amount);
  } else {
    return 0;
  }
};

export function useBenefitColDefs(params: {
  type: "employee";
  showEmployeeField: boolean;
}): ColumnConfig<CleanedEmployeeBenefit>[];
export function useBenefitColDefs(params: { type: "company" }): ColumnConfig<CleanedCompanyBenefit>[];
export function useBenefitColDefs(params: {
  type: "employee" | "company";
  showEmployeeField?: boolean;
}): (ColumnConfig<CleanedEmployeeBenefit> | ColumnConfig<CleanedCompanyBenefit>)[] {
  const { integrations } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const accountLabeler = useLedgerAccountLabeler();

  const vendorOptions = useVendorOptions();
  const vendorNamer = useVendorNameFormatter();

  const ledgerAccountOptions = useLedgerAccountOptions({
    includeCustomOption: { label: "Default", value: "" },
  });
  const expenseAccountOptions = useLedgerAccountOptions({
    includeCustomOption: [{ label: "Default", value: "" }, includeWithEarningOption],
  });
  const costTypeOptions = useCostTypeOptions({
    includeCustomOption: { label: "Default", value: "" },
  });
  const costTypeFormatter = useCostTypeFormatter();

  const integrationLookup = useHasIntegration();
  const hasContractorsPlan = integrationLookup.has("contractors_plan");
  const hasEmployeeNavigator = integrationLookup.has("employee_navigator");
  const has401kIntegration = integrationLookup.has("payroll_integrations");

  const { enBenefitDeductionCodeOptions, enDeductionCodesOptionsMap } = useEmployeeNavigator();

  return useMemo(() => {
    const isEditableColumn = (
      params: EditableCallbackParams<CleanedEmployeeBenefit | CleanedCompanyBenefit>
    ) => {
      return !isBenefitConnectedToExternalSource(params.data, activeCompanyId);
    };

    // We need to use $TSFixMe as we conditionally return an array of either
    // ColumnConfig<CleanedEmployeeBenefit> or
    // ColumnConfig<CleanedCompanyBenefit>, but TS isn't smart enough to tell if
    // we declare cols as a union type of these.
    const cols: ColumnConfig<$TSFixMe>[] = [
      {
        field: "description",
        headerName: "Description",
        minWidth: 200,
        filter: "agTextColumnFilter",
        editorType: "text",
        editable: true,
        pinned: "left",
        enableRowGroup: true,
      },
      {
        field: "benefit_type_label",
        headerName: "Type",
        dataType: "string",
        displayType: "badge",
        colors: benefitTypeColors,
        enableRowGroup: true,
      },
      {
        field: "company_contribution_label",
        headerName: "Company contribution",
        editableHide: true,
        comparator: getBenefitContributionLabelComparator("company"),
      },
      {
        field: "employee_contribution_label",
        headerName: "Employee contribution",
        editableHide: true,
        comparator: getBenefitContributionLabelComparator("employee"),
      },
      {
        field: "company_contribution_value",
        headerName: "Company contribution value",
        editorType: "number",
        editable: isEditableColumn,
        editableOnly: true,
        suppressColumnsToolPanel: true,
        cellEditorParams: () => ({ min: 0 }),
        minWidth: 250,
      },
      {
        field: "company_contribution_type",
        headerName: "Company contribution type",
        editorField: "company_contribution_type",
        editorType: "select",
        editable: isEditableColumn,
        editableOnly: true,
        suppressColumnsToolPanel: true,
        cellEditorParams: () => ({ options: CONTRIBUTION_TYPE_OPTIONS }),
        valueGetter: (params) => CONTRIBUTION_TYPE_LABEL_LOOKUP[params.data?.company_contribution_type],
        minWidth: 250,
      },
      {
        field: "employee_contribution_value",
        headerName: "Employee contribution value",
        editorField: "employee_contribution_value",
        editorType: "number",
        editable: isEditableColumn,
        editableOnly: true,
        suppressColumnsToolPanel: true,
        cellEditorParams: () => ({ min: 0 }),
        minWidth: 250,
      },
      {
        field: "employee_contribution_type",
        headerName: "Employee contribution type",
        editorField: "employee_contribution_type",
        editorType: "select",
        editable: isEditableColumn,
        editableOnly: true,
        suppressColumnsToolPanel: true,
        cellEditorParams: () => ({ options: CONTRIBUTION_TYPE_OPTIONS }),
        valueGetter: (params) => CONTRIBUTION_TYPE_LABEL_LOOKUP[params.data?.employee_contribution_type],
        minWidth: 250,
      },
      {
        field: "effective_start",
        headerName: "Start",
        dataType: "date",
        editorType: "date",
        editorDateType: "iso",
        editable: isEditableColumn,
      },
      {
        field: "effective_end",
        headerName: "End",
        dataType: "date",
        editorType: "date",
        editorDateType: "iso",
        editable: isEditableColumn,
      },
      {
        field: "emp_liab_account_id",
        headerName: "Emp. liability acct.",
        headerTooltip: "The GL account for this benefit's employee liability",
        valueGetter: (params) => accountLabeler(params.data?.emp_liab_account_id) || "Default",
        dataType: "string",
        initialHide: true,
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: ledgerAccountOptions,
        }),
      },
      {
        field: "com_liab_account_id",
        headerName: "Com. liability acct.",
        headerTooltip: "The GL account for this benefit's company liability",
        valueGetter: (params) => accountLabeler(params.data?.com_liab_account_id) || "Default",
        dataType: "string",
        initialHide: true,
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: ledgerAccountOptions,
        }),
      },
      {
        field: "expense_account_id",
        headerName: "Com. expense acct.",
        headerTooltip: "The GL account for this benefit's company expense",
        valueGetter: (params) => accountLabeler(params.data?.expense_account_id) || "Default",
        dataType: "string",
        initialHide: true,
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: expenseAccountOptions,
        }),
      },
      {
        field: "cost_type_id",
        headerName: "Cost type",
        headerTooltip: "The cost type for this benefit's company expense",
        valueGetter: (params) => costTypeFormatter(params.data?.cost_type_id) || "Default",
        dataType: "string",
        initialHide: true,
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: costTypeOptions,
        }),
      },
      {
        field: "group_term_life_amount",
        headerName: "Life imputed earning",
        dataType: "number",
        unit: "dollar",
        initialHide: true,
        editable: (params: EditableCallbackParams<CleanedEmployeeBenefit>) =>
          params.data?.benefit_type === "125_life",
        editorType: "number",
        cellEditorParams: () => ({ min: 0 }),
        minWidth: 250,
      },
      {
        field: "plan_number",
        headerName: "Plan number",
        dataType: "string",
        initialHide: true,
        editable: true,
        editorType: "text",
      },
      {
        field: "non_bonafide",
        headerName: "Is non-bonafide",
        dataType: "boolean",
        editorType: "checkbox",
        initialHide: true,
        editable: true,
      },
      {
        field: "vendor_id",
        headerName: "Bill pay vendor",
        dataType: "string",
        width: 300,
        valueFormatter: (params) => vendorNamer(params.value),
        useValueFormatterForExport: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: vendorOptions,
        }),
        initialHide: true,
        editable: true,
      },
    ];

    if (hasContractorsPlan) {
      cols.push({
        field: "integrations.contractors_plan.is_cp_benefit",
        headerName: "Contractors' Plan",
        dataType: "boolean",
        initialHide: true,
      });
    }
    if (hasEmployeeNavigator) {
      cols.push({
        field: "integrations.employee_navigator.deduction_code",
        headerName: "Deduction Code",
        dataType: "string",
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({ options: enBenefitDeductionCodeOptions }),
        valueGetter: (params) => {
          const deductionCode = params.data?.integrations?.employee_navigator?.deduction_code || "";
          return enDeductionCodesOptionsMap?.[deductionCode]?.label || "-";
        },
      });
    }
    if (!IS_PRODUCTION && has401kIntegration) {
      cols.push({
        field: "integrations.payroll_integrations.external_id",
        headerName: "401(k) external ID",
        dataType: "string",
        initialHide: true,
      });
    }

    if (params.type === "company") {
      const companyCols: ColumnConfig<CleanedCompanyBenefit>[] =
        cols as ColumnConfig<CleanedCompanyBenefit>[];
      companyCols.splice(1, 0, {
        valueGetter: (params: ValueGetterParams<CleanedCompanyBenefit>): string => {
          if (params.data?.benefit_type === "Guideline") return "Mixed";
          if (!params.data?.benefit_type) return "N/A";
          return benefitMap[params.data.benefit_type]?.post_tax ? "Post-tax" : "Pre-tax";
        },
        field: "tax_treatment",
        headerName: "Tax treatment",
        dataType: "string",
        displayType: "badge",
        colors: { Mixed: "gray", "Post-tax": "red", "Pre-tax": "green" },
        enableRowGroup: true,
      });
      return companyCols;
    } else {
      const employeeCols: ColumnConfig<CleanedEmployeeBenefit>[] = cols;
      employeeCols.splice(
        1,
        0,
        {
          field: "pre_tax",
          valueGetter: (params: ValueGetterParams<CleanedEmployeeBenefit>) =>
            params.data?.benefit_type ? !benefitMap[params.data.benefit_type]?.post_tax : undefined,
          headerName: "Pre-tax",
          dataType: "boolean",
          enableRowGroup: true,
        },
        {
          valueGetter: (params: ValueGetterParams<CleanedEmployeeBenefit>) => !!params.data?.company_benefit,
          headerName: "Company plan",
          field: "company_benefit",
          dataType: "boolean",
          enableRowGroup: true,
        }
      );
      if (params.showEmployeeField) {
        employeeCols.unshift(
          {
            field: "miter_tm.full_name",
            headerName: "Employee Name",
            dataType: "string",
            enableRowGroup: true,
            pinned: "left",
          },
          {
            field: "miter_tm.friendly_id",
            headerName: "Employee ID",
            dataType: "string",
            initialHide: true,
            enableRowGroup: true,
            pinned: "left",
          }
        );
      }
      return employeeCols;
    }
  }, [
    vendorNamer,
    integrations,
    params.showEmployeeField,
    params.type,
    hasContractorsPlan,
    hasEmployeeNavigator,
    enBenefitDeductionCodeOptions,
    costTypeOptions,
    costTypeFormatter,
    accountLabeler,
  ]);
}

const generateDeleteBenefitDescription = (multi: boolean | undefined, companyBen: boolean | undefined) => {
  return (
    <>
      Are you sure you want to delete {multi ? "these" : "this"} benefit{multi ? "s" : ""}?
      {companyBen ? " All employees will be unenrolled. " : " "}
      <strong>This action is permanent.</strong>
      <br />
      <br />
      Consider setting the end date to be yesterday instead. Benefits should only be deleted if they should
      not have been created in the first place.
    </>
  );
};

export const DeleteBenefitModal: React.FC<{
  onDelete: () => Promise<void> | void;
  onCancel: () => void;
  loading?: boolean;
  multi?: boolean;
  companyBen?: boolean;
}> = ({ multi, onDelete, onCancel, loading, companyBen }) => {
  return (
    <ConfirmModal
      title={`Delete ${multi ? "benefits" : "benefit"}`}
      body={generateDeleteBenefitDescription(multi, companyBen)}
      yesText="Delete"
      onYes={onDelete}
      onNo={onCancel}
      loading={loading}
      yellowBodyText
    />
  );
};

const getValForComparator = (
  data: CleanedCompanyBenefit | CleanedEmployeeBenefit | undefined,
  type: "company" | "employee"
) => {
  return (
    Number(
      data?.[`${type}_contribution_amount`] ||
        data?.[`${type}_contribution_percent`] ||
        data?.[`${type}_period_amount`]
    ) || 0
  );
};

const getBenefitContributionLabelComparator = (type: "company" | "employee") => {
  const benefitContributionLabelComparator = (
    _valueA: string | null | undefined,
    _valueB: string | null | undefined,
    nodeA: IRowNode<CleanedCompanyBenefit | CleanedEmployeeBenefit>,
    nodeB: IRowNode<CleanedCompanyBenefit | CleanedEmployeeBenefit>
  ): number => {
    const valA = getValForComparator(nodeA.data, type);
    const valB = getValForComparator(nodeB.data, type);
    return valA - valB;
  };
  return benefitContributionLabelComparator;
};

// GMCI and AR&M are two of the earliest EN customers, and therefore have some
// unique issues. In the future, we should definitely lock this down, but for
// now, allow them to make edits to their benefits with the disclaimer that they
// should make sure to reflect any changes in EN as well.
export const canCompanyEditEmployeeNavigatorBenefits = (company_id: string | null): boolean => {
  if (!company_id) return false;
  return (
    company_id === GREEN_MECHANICAL_COMPANY_ID || AMERICAN_ROOFING_METAL_COMPANY_IDS.includes(company_id)
  );
};

export const isBenefitConnectedToExternalSource = (
  benefitToUpdate: UnifiedBenefit | undefined,
  activeCompanyId: string | null
): boolean => {
  const isConnectedToGuideline = !!benefitToUpdate?.guideline_id;
  const isConnectedToSource = !!benefitToUpdate?.source;
  const isConnectedTo401KPayrollIntegrations =
    !!benefitToUpdate?.integrations?.payroll_integrations?.external_id;
  const isConnectedToEn =
    benefitToUpdate?.integrations?.employee_navigator?.connected &&
    !canCompanyEditEmployeeNavigatorBenefits(activeCompanyId);
  const isConnectedToClasp = !!benefitToUpdate?.integrations?.clasp?.clasp_benefit?.id;

  return (
    isConnectedToGuideline ||
    isConnectedToSource ||
    isConnectedToEn ||
    isConnectedTo401KPayrollIntegrations ||
    isConnectedToClasp
  );
};

export const isPtdConnectedToExternalSource = (
  ptd: PtdTableEntry | null | undefined,
  activeCompanyId: string | null
): boolean => {
  const isConnectedToGuideline = !!ptd?.guideline_id;
  const isConnectedTo401KPayrollIntegrations = !!ptd?.integrations?.payroll_integrations?.external_id;
  const isConnectedToEn =
    ptd?.integrations?.employee_navigator?.connected &&
    !canCompanyEditEmployeeNavigatorBenefits(activeCompanyId);
  const isConnectedToClasp = !!ptd?.integrations?.clasp?.clasp_benefit?.id;

  return (
    isConnectedToGuideline || isConnectedToEn || isConnectedTo401KPayrollIntegrations || isConnectedToClasp
  );
};

export type BenefitSyncableTeamMember = Assign<
  TeamMember,
  {
    check_id: string;
    ssn_last_four: string;
    address: Address;
    start_date: string;
    dob: string;
  }
>;

export const isBenefitSyncableTeamMember = (
  teamMember: TeamMember | TableReadyTm
): teamMember is BenefitSyncableTeamMember => {
  if (teamMember.benefits_eligibility_status === "eligible") {
    return true;
  } else if (teamMember.benefits_eligibility_status === "ineligible") {
    return false;
  }
  return !!(
    teamMember.employment_type === "employee" &&
    !teamMember.archived &&
    teamMember.check_id &&
    teamMember.ssn_last_four &&
    teamMember.start_date &&
    teamMember.dob &&
    isValidAddress(teamMember.address)
  );
};

export function getBenefitDescription<T extends { check_benefit: CheckBenefit }>(
  currMiterBenefit: T
): string {
  const benSpecificDescRaw =
    currMiterBenefit.check_benefit.description || benefitLabelLookup[currMiterBenefit.check_benefit.benefit];
  return benSpecificDescRaw ? `${benSpecificDescRaw} benefit` : "Benefit";
}

export type UnifiedPrgFringeBenefit = {
  _id: string;
  type: string;
  description: string;
  employee_contribution: string;
  company_contribution: string;
  pre_tax: boolean;
};

/** Takes the naive list of `benefits` on the Check item and splits out the PRG fringes associated with benefits, but cleanly formats the final list.
 * Necessary since all pre-tax PRG fringes get rolled up by benefit type across all PRGs, which means we needed to build and show the broken-out version */
export const getBenefitsListWithPrgFringesBrokenOut = (
  payment: PayrollPayment | EnhancedMiterPayment
): UnifiedPrgFringeBenefit[] => {
  const fringes: UnifiedPrgFringeBenefit[] = [];
  const trueBens: UnifiedPrgFringeBenefit[] = [];
  const benFringeIds = new Set<string>();

  for (const f of payment.fringes || []) {
    if (!f.check_benefit_type) continue;
    if (f.check_id) benFringeIds.add(f.check_id);
    fringes.push({
      _id: f.fringe_group_id,
      description: `Fringe deduction: ${f.label} (${f.prg_label})`,
      type: benefitMap[f.check_benefit_type].label,
      employee_contribution: usdString(f.amount),
      company_contribution: usdString(0),
      pre_tax: true,
    });
  }

  for (const checkBenefit of payment.check_item?.benefits || []) {
    if (benFringeIds.has(checkBenefit.id)) continue;

    trueBens.push({
      _id: checkBenefit.id,
      description: checkBenefit.description,
      type: benefitMap[checkBenefit.benefit].label,
      employee_contribution: usdString(checkBenefit.employee_contribution_amount),
      company_contribution: usdString(checkBenefit.company_contribution_amount),
      pre_tax: checkBenefitIsPreTax(checkBenefit),
    });
  }

  return trueBens.concat(fringes);
};
