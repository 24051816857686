import React, { useState, useMemo, useEffect } from "react";
import PayrollContext from "./payrollContext";
import PaymentModal from "./PaymentModal/PaymentModal";
import { keyBy } from "lodash";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { AggregatedEmployeeBenefit, MiterAPI, MiterFilterArray, PostTaxDeduction } from "dashboard/miter";
import { useActiveCompanyId, useLookupTeam, useVendorNameFormatter } from "dashboard/hooks/atom-hooks";

type BillTableEntry = {
  _id: string;
  tmId: string;
  tmName: string;
  amount: number;
  description: string;
  type: "employee" | "company";
  vendorName: string;
  contributionType: "benefit" | "deduction";
};

export const PayrollBills: React.FC = () => {
  /*********************************************************
   *  Hooks
   **********************************************************/

  const activeCompanyId = useActiveCompanyId();
  const { payroll, isLoading } = React.useContext(PayrollContext);
  const lookupTeamMember = useLookupTeam();
  const vendorNamer = useVendorNameFormatter();

  /*********************************************************
   *  State
   **********************************************************/
  const [employeeBenefitsMap, setEmployeeBenefitsMap] = useState<Record<string, AggregatedEmployeeBenefit>>(
    {}
  );
  const [postTaxDeductionsMap, setPostTaxDeductionsMap] = useState<Record<string, PostTaxDeduction>>({});
  /*********************************************************
   *  useMemos and useEffects
   **********************************************************/

  // get all the actual Miter benefit objects
  useEffect(() => {
    const getBenefits = async () => {
      const allEmployeeBenefitFromThisPayrollCheckIds = payroll?.miter_payments.flatMap(
        (mp) => mp.check_item?.benefits?.map((benefit) => benefit.id) || []
      );

      const filter: MiterFilterArray = [
        {
          field: "company",
          type: "string" as const,
          value: activeCompanyId,
        },
        {
          field: "check_id",
          comparisonType: "in",
          value: allEmployeeBenefitFromThisPayrollCheckIds,
        },
      ];

      const employeeBenefit = await MiterAPI.benefits.employee.search(filter);
      setEmployeeBenefitsMap(keyBy(employeeBenefit, "check_id"));
    };

    const getPostTaxDeductions = async () => {
      const allPTDsFromThisPayrollCheckIds = payroll?.miter_payments.flatMap(
        (mp) => mp.check_item?.post_tax_deductions?.map((ptd) => ptd.post_tax_deduction) || []
      );

      const filter: MiterFilterArray = [
        {
          field: "company",
          type: "string" as const,
          value: activeCompanyId,
        },
        {
          field: "check_id",
          comparisonType: "in",
          value: allPTDsFromThisPayrollCheckIds,
        },
      ];

      const postTaxDeductions: PostTaxDeduction[] = await MiterAPI.post_tax_deductions.retrieve_many({
        filter,
      });
      setPostTaxDeductionsMap(keyBy(postTaxDeductions, "check_id"));
    };

    getBenefits();
    getPostTaxDeductions();
  }, [payroll]);

  // State
  const [activePayment, setActivePayment] = useState<BillTableEntry>();

  const bills: BillTableEntry[] = useMemo(() => {
    const createdTableEntries: BillTableEntry[] = [];
    for (const mp of payroll?.miter_payments || []) {
      // iterate over all benefits
      for (const checkBenefit of mp.check_item?.benefits || []) {
        // get the benefit object
        const employeeBenefitObject = employeeBenefitsMap[checkBenefit.id];
        // skip if no vendor on the benefit
        if (!employeeBenefitObject?.vendor_id) continue;

        // create one entry for both the employee and company contribution
        if (Number(checkBenefit.employee_contribution_amount) > 0) {
          createdTableEntries.push({
            _id: "employee_benefit" + checkBenefit.id,
            tmId: employeeBenefitObject.employee._id,
            tmName: employeeBenefitObject.employee.full_name,
            amount: Number(checkBenefit.employee_contribution_amount),
            description: "Employee contribution for " + checkBenefit.description,
            type: "employee",
            vendorName: vendorNamer(employeeBenefitObject.vendor_id),
            contributionType: "benefit",
          });
        }

        if (Number(checkBenefit.company_contribution_amount) > 0) {
          createdTableEntries.push({
            _id: "company_benefit_" + checkBenefit.id,
            tmId: employeeBenefitObject.employee._id,
            tmName: employeeBenefitObject.employee.full_name,
            amount: Number(checkBenefit.company_contribution_amount),
            description: "Company contribution for " + checkBenefit.description,
            type: "company",
            vendorName: vendorNamer(employeeBenefitObject.vendor_id),
            contributionType: "benefit",
          });
        }
      }

      // iterate over all post tax deductions
      for (const checkPostTaxDeduction of mp.check_item?.post_tax_deductions || []) {
        // get the ptd object
        const ptdObject = postTaxDeductionsMap[checkPostTaxDeduction.post_tax_deduction];

        // skip if no vendor on the benefit
        if (!ptdObject?.vendor_id) continue;

        // create one entry for both the employee and company contribution
        if (Number(checkPostTaxDeduction.amount) > 0) {
          createdTableEntries.push({
            _id: "employee_benefit" + checkPostTaxDeduction.post_tax_deduction,
            tmId: ptdObject.employee,
            tmName: lookupTeamMember(ptdObject.employee)?.full_name || "N/A",
            amount: Number(checkPostTaxDeduction.amount), // this will be the overriden amount if coming from override
            description: "Employee contribution for " + ptdObject.check_post_tax_deduction.description,
            type: "employee",
            vendorName: vendorNamer(ptdObject.vendor_id),
            contributionType: "deduction",
          });
        }
      }
    }

    return createdTableEntries;
  }, [payroll, employeeBenefitsMap, postTaxDeductionsMap, vendorNamer]);

  return (
    <div>
      <span className="run-payroll-subheader">{`The following bills will be created for this payroll.`}</span>
      <TableV2
        id="payroll-bills-table"
        resource="bills"
        data={bills}
        columns={billColumns}
        onClick={(e) => setActivePayment(e)}
        isLoading={isLoading}
        hideSecondaryActions
        hideSearch
        hideSelectColumn
        hideFooter
      />
      {activePayment && (
        <PaymentModal
          tmId={activePayment.tmId}
          editing={false}
          hide={() => setActivePayment(undefined)}
          initialView={activePayment.contributionType} // benefits or deductions
        />
      )}
      <div className="vertical-spacer-small" />
    </div>
  );
};

const billColumns: ColumnConfig<BillTableEntry>[] = [
  { field: "tmName", headerName: "Team member", dataType: "string" },
  {
    field: "amount",
    dataType: "number",
    headerName: "Amount",
    aggFunc: "sumValues",
    unit: "dollar",
    maxWidth: 125,
  },
  {
    field: "type",
    headerName: "Type",
    displayType: "badge",
    maxWidth: 125,
    colors: { employee: "orange", company: "blue" },
  },
  { field: "description", headerName: "Description", dataType: "string", minWidth: 400 },
  { field: "vendorName", headerName: "Vendor", dataType: "string" },
];
