import { AggregatedPayRateGroup, AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import { unionRateSorter } from "./PayRateGroupModalUtils";
import { useActiveEmployees, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";

type Props = {
  prg: AggregatedPayRateGroup;
  readonly?: boolean;
};

type TableEntry = {
  _id: string;
  employee: string;
  fullEmployee: AggregatedTeamMember;
  title: string;
  classification: string;
};

export const TeamClassificationMapping: React.FC<Props> = ({ prg, readonly }) => {
  const NO_MAP = "no_map";
  const activeEmployees = useActiveEmployees();
  const refetchTeam = useRefetchTeam();

  const [selectedEmployees, setSelectedEmployees] = useState<TableEntry[]>([]);
  const [loading, setLoading] = useState(false);

  const prgDefaultRate = useMemo(() => {
    return prg?.union_rates.find((rate) => rate.is_default);
  }, [prg]);

  const classificationOptions = useMemo(() => {
    const noClassificationLabel = prgDefaultRate
      ? `Group default (${prgDefaultRate.classification})`
      : "No classification";
    const options = [{ label: noClassificationLabel, value: NO_MAP }];
    prg?.union_rates.sort(unionRateSorter).forEach((unionRate) => {
      options.push({ label: unionRate.classification, value: unionRate._id });
    });
    return options;
  }, [prg, prgDefaultRate]);

  const data = useMemo(() => {
    return activeEmployees.map((e) => {
      return {
        _id: e._id,
        employee: e.full_name,
        fullEmployee: e,
        title: e.title,
        classification: (e.prg_classifications && e.prg_classifications[prg._id]) || NO_MAP,
      };
    });
  }, [activeEmployees, prg, refetchTeam]);

  const cols: ColumnConfig<TableEntry>[] = [
    {
      field: "employee",
      headerName: "Employee",
      dataType: "string",
    },
    {
      field: "title",
      headerName: "Title",
      dataType: "string",
    },
    {
      field: "classification",
      headerName: "Pay rate group classification",
      editable: (params) => {
        return (
          !readonly &&
          // uneditable if prg is tm's default
          !prg?.union_rates.some((rate) => rate._id === params.data?.fullEmployee.union_rate?._id)
        );
      },
      editorType: "select",
      cellEditorParams: () => {
        return { options: classificationOptions };
      },
      valueFormatter: (params) =>
        classificationOptions.find((option) => option.value === params?.data?.classification)?.label || "",
    },
  ];

  if (!prg) return null;

  const handleSubmit = async (updates: TableEntry[]) => {
    if (readonly) return;
    setLoading(true);
    let errorCount = 0;
    await Promise.all(
      updates.map(async (update) => {
        try {
          const newPrgClassifications = { ...update.fullEmployee.prg_classifications };
          if (update.classification === NO_MAP) {
            delete newPrgClassifications[prg._id];
          } else {
            newPrgClassifications[prg._id] = update.classification;
          }
          const response = await MiterAPI.team_member.update(update.fullEmployee._id, {
            prg_classifications: newPrgClassifications,
          });
          if (response.error) throw new Error(response.error);
        } catch (e) {
          console.error("Error updating team member classifications", e);
          errorCount = errorCount + 1;
        }
      })
    );

    await refetchTeam(updates.map((u) => u.fullEmployee._id));
    setLoading(false);
    setSelectedEmployees([]);

    if (errorCount > 0) {
      Notifier.error(
        `There was an error updating ${errorCount} employee classifications. Our engineers have been notified and are looking into it!`
      );
    } else {
      Notifier.success("Team members successfully updated.");
    }
  };

  return (
    <TableV2
      id={"tm-classifications"}
      resource="employee classifications"
      data={data}
      alwaysEditable={!readonly}
      editable={!readonly}
      autoSave={true}
      showReportViews={true}
      isLoading={loading}
      columns={cols}
      onSave={handleSubmit}
      onSelect={setSelectedEmployees}
      defaultSelectedRows={selectedEmployees}
    />
  );
};
