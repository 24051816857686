import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Formblock, Notifier, WizardScreen } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import useWizard from "ui/modal/useWizard";
import { File, MiterAPI, UpdateManyFilesParams } from "dashboard/miter";
import * as vals from "dashboard/utils/validators";

import styles from "./DocumentWizard.module.css";
import { capitalize, isEqual } from "lodash";
import Banner from "dashboard/components/shared/Banner";
import { useActiveCompanyId, useActiveTeam, useDepartments, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { useHasAccessToChecklists } from "dashboard/gating";

type Props = {
  name: string;
  document: FilePickerFile | undefined;
  setDocument: Dispatch<SetStateAction<FilePickerFile | undefined>>;
  mode: "create" | "edit-info" | "edit-team-access";
};

type DocumentTeamAccessScreenFields = {
  team_members_have_access: "true" | "false" | undefined;
  applies_to: "new_hires" | "current_team_members" | "new_hires_and_current_team_members" | null;
  team_permissions: { label: string; value: NonNullable<File["team_permissions"]>[number] }[];
  autosend_esignature_request: "true" | "false" | undefined;
};

const DocumentTeamAccessScreen: FC<Props> = ({ name, document, setDocument, mode }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeTeamMembers = useActiveTeam();
  const lookupTeam = useLookupTeam();
  const departments = useDepartments();
  const { setCanNext, setNextButtonText, handleComplete } = useWizard();
  const hasAccessToChecklists = useHasAccessToChecklists();

  // Default values

  const defaultTeamMemberAccess =
    !!document?.data?.team_permissions?.length || !!document?.data?.onboarding_document ? "true" : "false";

  let defaultAppliesTo: DocumentTeamAccessScreenFields["applies_to"] | undefined = undefined;

  if (document?.data?.existing_tm_document && document?.data?.onboarding_document) {
    defaultAppliesTo = "new_hires_and_current_team_members";
  } else if (document?.data?.existing_tm_document) {
    defaultAppliesTo = "current_team_members";
  } else if (document?.data?.onboarding_document) {
    defaultAppliesTo = "new_hires";
  }

  const defaultAutosendESignRequest =
    document?.data?.autosend_esignature_request === undefined
      ? undefined
      : document?.data?.autosend_esignature_request
      ? "true"
      : "false";

  const form = useForm<DocumentTeamAccessScreenFields>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
    defaultValues: {
      team_members_have_access: defaultTeamMemberAccess,
      applies_to: defaultAppliesTo,
      team_permissions: [],
      autosend_esignature_request: defaultAutosendESignRequest,
    },
  });

  const isPDF = document?.data?.type === "application/pdf";

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, watch, formState, errors, reset, setValue } = form;
  const { dirtyFields, isValid } = formState;

  const formData = watch();

  /******************************************************************
   * useEffect's
   * - Trigger form validation on change
   * - Set the next button text to be "Complete"
   * - Make sure that when team options change, we sync permissions
   *******************************************************************/
  useEffect(() => {
    form.trigger();

    if (!formData.team_permissions) {
      setValue("team_permissions", []);
    }
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    setNextButtonText("Save and exit");
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveDocument)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    handleComplete();
  };

  const resetAllInputs = () => {
    reset({
      applies_to: null,
      team_permissions: [],
      autosend_esignature_request: defaultAutosendESignRequest,
    });
  };

  const syncTeamPermissionsWithOptions = () => {
    if (formData.applies_to !== "new_hires" && formData.applies_to !== "current_team_members") return;

    const teamPermissions = formData.team_permissions || [];
    const availableSelectedPermissions = teamPermissions.filter((permission) => {
      if (permission.value.group.type !== "team_member") return true;

      const isOnboarded = lookupTeam(permission.value.group.value)?.onboarded;
      return formData.applies_to === "new_hires" ? !isOnboarded : isOnboarded;
    });

    setValue("team_permissions", availableSelectedPermissions);
  };

  const buildParams = (params: DocumentTeamAccessScreenFields) => {
    if (!activeCompanyId) return;
    if (!document?.data) throw new Error("Document is missing.");

    const builtParams = {
      _id: document.data._id,
      team_permissions: params.team_permissions.map((permission) => permission.value),
      onboarding_document: params.applies_to?.includes("new_hire") || null,
      existing_tm_document: params.applies_to?.includes("current_team_member") || null,
      autosend_esignature_request: params.autosend_esignature_request === "true" ? true : false,
      parent_id: document.data.parent_id,
      parent_type: document.data.parent_type,
    };

    // If this is a team member's document but team permissions are being added, turn it into a company document
    if (document.data.parent_type === "team_member" && params.team_members_have_access === "true") {
      builtParams.parent_id = activeCompanyId;
      builtParams.parent_type = "company";
    }

    return builtParams;
  };

  const buildFilter = (key: string, option: DocumentTeamAccessScreenFields["team_permissions"][number]) => {
    return (
      formData.team_permissions?.findIndex(
        (permission) =>
          permission.value.group.type === key && permission.value.group.value === option.value.group.value
      ) === -1
    );
  };

  const teamOptions = useMemo(() => {
    const filteredTeamMembers = activeTeamMembers.filter((tm) => {
      if (formData.applies_to === "new_hires") {
        return !tm.onboarded;
      } else if (formData.applies_to === "current_team_members") {
        return tm.onboarded;
      } else {
        return true;
      }
    });

    const departmentOptions = departments
      .map((department) => ({
        label: department.name,
        value: {
          group: { type: "department", value: department._id },
          can: ["view"],
        } as NonNullable<File["team_permissions"]>[number],
      }))
      .filter((department) => buildFilter("department", department));

    const teamMemberOptions = filteredTeamMembers
      ?.map((tm) => ({
        label: tm.full_name,
        value: {
          group: { type: "team_member", value: tm._id },
          can: ["view"],
        } as NonNullable<File["team_permissions"]>[number],
      }))
      .filter((tm) => buildFilter("team_member", tm));

    const titles = filteredTeamMembers.reduce((acc, tm) => {
      const title = tm.title;
      if (!acc.includes(title)) {
        acc.push(title);
      }
      return acc;
    }, [] as string[]);

    const titleOptions = titles
      .map((title) => ({
        label: capitalize(title),
        value: {
          group: { type: "title", value: title },
          can: ["view"],
        } as NonNullable<File["team_permissions"]>[number],
      }))
      .filter((title) => buildFilter("title", title));

    const employementTypes = filteredTeamMembers.reduce((acc, tm) => {
      const type = tm.employment_type;
      if (!acc.includes(type)) {
        acc.push(type);
      }
      return acc;
    }, [] as string[]);

    const employmentTypeOptions = employementTypes
      .map((type) => ({
        label: capitalize(type),
        value: {
          group: { type: "employment_type", value: type },
          can: ["view"],
        } as NonNullable<File["team_permissions"]>[number],
      }))
      .filter((employmentType) => buildFilter("employment_type", employmentType));

    const payTypes = filteredTeamMembers.reduce((acc, tm) => {
      const type = tm.pay_type;
      if (type && !acc.includes(type)) {
        acc.push(type);
      }
      return acc;
    }, [] as string[]);

    const payTypeOptions = payTypes
      .map((type) => ({
        label: capitalize(type),
        value: {
          group: { type: "pay_type", value: type },
          can: ["view"],
        } as NonNullable<File["team_permissions"]>[number],
      }))
      .filter((type) => buildFilter("pay_type", type));

    const allTeamMembersOptions = [
      {
        label: "All Team Members",
        value: {
          group: { type: "all_team_members", value: "all_team_members" },
          can: ["view"],
        } as NonNullable<File["team_permissions"]>[number],
      },
    ].filter((option) => buildFilter("all_team_members", option));

    return [
      { label: "All Team Members", options: allTeamMembersOptions },
      { label: "Departments", options: departmentOptions },
      { label: "Team Members", options: teamMemberOptions },
      { label: "Titles", options: titleOptions },
      { label: "Employment Types", options: employmentTypeOptions },
      { label: "Pay Types", options: payTypeOptions },
    ];
  }, [activeTeamMembers, departments, formData.team_permissions, formData.applies_to]);

  const saveDocument = async (params: DocumentTeamAccessScreenFields) => {
    try {
      const cleanedParams = buildParams(params);
      const res = await MiterAPI.files.update_many({ files: [cleanedParams] } as UpdateManyFilesParams);
      if (res.error) throw new Error(res.error);
      if (!res[0]) throw new Error("Unable to update file.");

      setDocument({ data: res[0] });
      Notifier.success("Document saved");
    } catch (e: $TSFixMe) {
      console.error("Error saving document", e);
      Notifier.error("We were unable to save your document. Please try again.");
      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  const buildTeamPermissionsLabel = () => {
    const permission = formData.autosend_esignature_request === "true" ? "required to sign" : "able to view";
    if (hasAccessToChecklists) return `Which team members should be ${permission} this document?`;
    if (formData.applies_to === "current_team_members") {
      return `Which current team members should be ${permission} this document?`;
    } else if (formData.applies_to === "new_hires") {
      return `Which new hires should be ${permission} this document?`;
    } else {
      return `Which new hires and current team members should be ${permission} this document?`;
    }
  };

  const renderTeamAccessFields = () => {
    if (formData.team_members_have_access !== "true") return;

    return (
      <>
        {formData.team_members_have_access && !hasAccessToChecklists && (
          <div className="form-section">
            <Formblock
              label="Who does this document apply to?"
              type="radio"
              name="applies_to"
              form={form}
              className="modal wizard"
              editing={true}
              options={[
                { label: "New Hires", value: "new_hires" },
                { label: "Current Team Members", value: "current_team_members" },
                { label: "New Hires and Current Team Members", value: "new_hires_and_current_team_members" },
              ]}
              val={vals.required}
            />
          </div>
        )}

        {formData.applies_to || (hasAccessToChecklists && formData.team_members_have_access) ? (
          <div className="form-section">
            <Formblock
              type="multiselect"
              name="team_permissions"
              label={buildTeamPermissionsLabel()}
              form={form}
              editing={true}
              className="modal wizard"
              placeholder={"Select team members"}
              options={teamOptions}
              val={vals.required}
              height="unset"
              // defaultValue={document?.data?.team_permissions}
            />
          </div>
        ) : null}

        {formData.applies_to && formData.team_permissions?.length && isPDF ? (
          <div className="form-section">
            <Formblock
              label="Do you want to automatically send esignature requests to anyone who is added to this document?"
              type="radio"
              name="autosend_esignature_request"
              form={form}
              className="modal wizard"
              editing={true}
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              val={vals.required}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderTeamMembersHaveAccessField = () => {
    const parentName =
      document?.data && "parent_name" in document.data ? document.data.parent_name : "this team member";

    const label =
      mode === "create" || document?.data?.parent_type !== "team_member"
        ? "Can team members access this document in the team portal?"
        : `Can team members other than ${parentName} access this document?`;

    return (
      <div className="form-section">
        <Formblock
          label={label}
          type="radio"
          name="team_members_have_access"
          form={form}
          className="modal wizard"
          editing={true}
          options={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
          sublabel="This setting only applies in the Miter team portal. This document will still be available to users with access to the Miter dashboard and the view documents permissions."
          val={vals.required}
          onChange={(e) => {
            if (e.target.value === "false") {
              resetAllInputs();
            }
          }}
        />
      </div>
    );
  };

  const renderTeamDocumentConversionBanner = () => {
    if (mode === "create" || document?.data?.parent_type !== "team_member") return;
    const parentName =
      document?.data && "parent_name" in document.data ? document.data.parent_name : "this team member";

    return (
      <Banner
        type="warning"
        content={`If you share this document with team members other than ${parentName}, it will be automatically converted into a company document.`}
        style={{ marginBottom: "25px" }}
      />
    );
  };

  // Set default values for team permissions
  useEffect(() => {
    const documentPermissionsWithoutIds =
      document?.data?.team_permissions?.map((permission) => {
        const { _id, ...rest } = permission;
        return rest;
      }) || [];

    const defaultTeamPermissions = teamOptions
      .flatMap((group) => group.options)
      .filter((option) => {
        return documentPermissionsWithoutIds.find((permission) => {
          return isEqual(permission, option.value);
        });
      });

    setValue("team_permissions", defaultTeamPermissions);
  }, []);

  useEffect(() => {
    if (!formData.team_permissions?.length) return;
    syncTeamPermissionsWithOptions();
  }, [JSON.stringify(teamOptions), JSON.stringify(formData.team_permissions)]);

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        {renderTeamDocumentConversionBanner()}
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Who should have access to this document?</h2>
        </div>
        {renderTeamMembersHaveAccessField()}
        {renderTeamAccessFields()}
      </div>
    </WizardScreen>
  );
};

export default DocumentTeamAccessScreen;
