import { randString } from "miter-utils";
import { Plus } from "phosphor-react";
import React, { FC, useMemo, useState } from "react";
import { ActionModal, Button, Formblock, Notifier, TableV2 } from "ui";
import { BreakType, CompanySettings } from "backend/models/company/company-settings";
import InfoButton from "dashboard/components/information/information";
type Props = {
  timesheetSettings: CompanySettings["timesheets"];
  handleChange: (newTimesheetSettings: Partial<CompanySettings["timesheets"]>) => Promise<void>;
};

export const AdvancedBreakTime: FC<Props> = ({ timesheetSettings, handleChange }) => {
  const [breakType, setBreakType] = useState<{ [id: string]: BreakType }>();
  const [saving, setSaving] = useState(false);

  const hasAdvancedBreakTime = timesheetSettings.advanced_break_time;

  const breakTypesObject = timesheetSettings.break_types || {};
  const breakTypes = useMemo(() => {
    return Object.entries(breakTypesObject)
      .filter(([_id, breakType]) => !breakType.archived)
      .map(([id, breakType]) => ({ _id: id, ...breakType }));
  }, [timesheetSettings]);

  const updateBreakTypes = async (newBreakTypes: { [id: string]: BreakType }) => {
    const curBreakTypes = timesheetSettings.break_types || {};
    const newSettings = { ...timesheetSettings, break_types: { ...curBreakTypes, ...newBreakTypes } };
    await handleChange(newSettings);
  };

  const updateAdvancedBreakTime = async (newAdvancedBreakTime: boolean) => {
    const newSettings = { advanced_break_time: newAdvancedBreakTime };
    handleChange(newSettings);
  };

  const handleAdd = () => {
    const id = randString(16) + "-adding";
    setBreakType({ [id]: { label: "", paid: false } });
  };

  const handleEdit = (id: string) => {
    if (!breakTypesObject[id]) return;
    setBreakType({ [id]: breakTypesObject[id]! });
  };

  const handleHide = () => {
    setBreakType(undefined);
  };

  const handleBreakTypeChange = (id: string, newBreakType: BreakType) => {
    setBreakType({ [id]: newBreakType });
  };

  const renderBreakTypeModal = () => {
    if (!breakType) return;

    const id = Object.keys(breakType)[0]!;
    const breakTypeValue = breakType[id]!;

    const isAdding = id.includes("-adding");
    const title = isAdding ? "Add Break Type" : "Edit Break Type";

    return (
      <ActionModal
        headerText={title}
        onHide={handleHide}
        onSubmit={handleSave}
        showSubmit={true}
        loading={saving}
        showDelete={!isAdding}
        onDelete={() => handleArchive(id)}
      >
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Formblock
            label="Label"
            type="text"
            value={breakTypeValue.label}
            onChange={(e) => handleBreakTypeChange(id, { ...breakTypeValue, label: e.target.value })}
            className="modal"
            editing={true}
            placeholder="Lunch Break"
            style={{ marginBottom: 15 }}
          />
          <Formblock
            label="Label (Spanish)"
            type="text"
            value={breakTypeValue.label_es}
            onChange={(e) => handleBreakTypeChange(id, { ...breakTypeValue, label_es: e.target.value })}
            className="modal"
            editing={true}
            placeholder="Almuerzo"
            style={{ marginBottom: 15 }}
          />
          <Formblock
            label="Minimum break time (minutes)"
            labelInfo="Minimum break time is the minimum amount of time an employee must take on the break before clocking out."
            type="number"
            value={breakTypeValue.minimum_time}
            onChange={(e) => handleBreakTypeChange(id, { ...breakTypeValue, minimum_time: e.target.value })}
            className="modal"
            editing={true}
            defaultValue={0}
            style={{ marginBottom: 15 }}
          />
          <Formblock
            label=""
            text={`This is a paid break`}
            type="checkbox"
            checked={breakTypeValue.paid}
            onChange={(e) => handleBreakTypeChange(id, { ...breakTypeValue, paid: e.target.checked })}
            className="modal"
            editing={true}
            disabled={!isAdding}
          />
        </div>
      </ActionModal>
    );
  };

  const handleSave = async () => {
    if (!breakType) {
      return Notifier.error("Break type not found");
    }

    setSaving(true);

    const id = Object.keys(breakType)[0]!;
    const cleanedId = id.replaceAll("-adding", "");
    const breakTypeValue = breakType[id]!;

    if (!breakTypeValue.label || breakTypeValue.label === "") {
      setSaving(false);
      return Notifier.error("Break type label is required");
    }

    if (breakTypeValue.minimum_time && breakTypeValue.minimum_time < 0) {
      setSaving(false);
      return Notifier.error("Minimum break time must be greater than 0 minutes");
    }

    if (breakTypeValue.minimum_time && breakTypeValue.minimum_time > 1439) {
      setSaving(false);
      return Notifier.error("Minimum break time must less than 24 hours");
    }

    await updateBreakTypes({ [cleanedId]: breakTypeValue });
    setSaving(false);
    setBreakType(undefined);
  };

  const handleArchive = async (id: string) => {
    const breakTypeValue = breakTypesObject[id]!;
    await updateBreakTypes({ [id]: { ...breakTypeValue, archived: true } });
    setBreakType(undefined);
  };

  return (
    <div className="billing-card-wrapper">
      <div style={{ fontWeight: 600, fontSize: 18, display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          Advanced break time
          <InfoButton text="Select yes if you want to track break time with start and end timestamps and paid break time. If no, break time will be unpaid and will be tracked with a basic duration." />
        </div>
        {hasAdvancedBreakTime && (
          <Button className="button-1 no-margin icon-button" onClick={handleAdd}>
            <Plus />
          </Button>
        )}
      </div>
      <div className="flex" style={{ marginTop: 15 }}>
        <input
          type="radio"
          style={{ margin: "0px 12px" }}
          onChange={() => updateAdvancedBreakTime(true)}
          checked={hasAdvancedBreakTime}
        />
        <label>
          <span className="bold">Advanced</span>: Track break time start/end, paid breaks, and create custom
          break types.
        </label>
      </div>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="radio"
          style={{ margin: "0px 12px" }}
          onChange={() => updateAdvancedBreakTime(false)}
          checked={!hasAdvancedBreakTime}
        />
        <label className="flex">
          <span className="bold flex">
            Simple{" "}
            <InfoButton
              text="All break time will be added at the end of the timesheet"
              style={{ marginLeft: 5 }}
            />
          </span>
          : Track unpaid break time with a simple number field
        </label>
      </div>

      {hasAdvancedBreakTime && (
        <TableV2
          id="break-types-table"
          resource="break types"
          data={breakTypes}
          hideSearch={true}
          hideSecondaryActions={true}
          containerStyle={{ paddingBottom: 0 }}
          onClick={(row) => handleEdit(row._id)}
          columns={[
            {
              headerName: "Label",
              field: "label",
              dataType: "string",
            },
            {
              headerName: "Paid?",
              field: "paid",
              dataType: "boolean",
            },
          ]}
          hideFooter={true}
        />
      )}

      {renderBreakTypeModal()}
    </div>
  );
};
