import { Notifier } from "dashboard/utils";
import React, { useCallback, useMemo, useState } from "react";
import { ConfirmModal, TableV2 } from "ui";
import { LedgerAccount, MiterAPI } from "dashboard/miter";
import { LedgerAccountSource, getLedgerAccountSource } from "../accountingUtils";
import {
  useLedgerAccounts,
  useLookupLedgerAccount,
  useRefetchLedgerAccounts,
  useSetLedgerAccounts,
} from "dashboard/hooks/atom-hooks";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { ClockCounterClockwise, Plus, TrashSimple } from "phosphor-react";
import { LedgerAccountModal } from "./LedgerAccountModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useEnhancedSearchParams } from "miter-utils";
import { ImportHistory } from "dashboard/components/importer/ImportHistory";
import { LedgerAccountImporter } from "./LedgerAccountImporter";

type LedgerAccountTableEntry = LedgerAccount & { source: LedgerAccountSource };

export const LedgerAccounts: React.FC = () => {
  const ledgerAccounts = useLedgerAccounts();
  const setLedgerAccounts = useSetLedgerAccounts();
  const refetchLedgerAccounts = useRefetchLedgerAccounts();
  const lookupLedgerAccount = useLookupLedgerAccount();
  const { can, cannot } = useMiterAbilities();

  const { searchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const updating = lookupLedgerAccount(searchParams.get("anid"));
  const [creating, setCreating] = useState(false);
  const [selectedRows, setSelectedRows] = useState<LedgerAccountTableEntry[]>([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const [showImportHistory, setShowImportHistory] = useState<boolean>(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState<boolean>(false);

  const data = useMemo(() => {
    return ledgerAccounts.map((la): LedgerAccountTableEntry => {
      return {
        ...la,
        source: getLedgerAccountSource(la),
      };
    });
  }, [ledgerAccounts]);

  const handleBulkCreateFinish = async () => {
    await refetchLedgerAccounts();
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  const archiveSelected = async () => {
    if (cannot("accounting:ledger_accounts:delete")) {
      Notifier.error("You do not have permission to delete ledger accounts.");
      return;
    }

    setArchiving(true);
    try {
      const res = await MiterAPI.ledger_accounts.bulk_update(
        selectedRows.map((la) => ({ _id: la._id, params: { archived: true } }))
      );
      if (res.error) throw new Error(res.error);
      if (res.errors.length) {
        Notifier.warning(`At least one account failed to delete: ${res.errors[0]!.message}`);
      } else {
        Notifier.success(`Account(s) deleted successfully.`);
      }
      setLedgerAccounts((prev) => prev.concat(res.successes));
      setArchiveModal(false);
      setSelectedRows([]);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setArchiving(false);
  };

  const staticActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Add account",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("accounting:ledger_accounts:create"),
      },
      {
        key: "import",
        component: <LedgerAccountImporter onFinish={handleBulkCreateFinish} />,
        shouldShow: () => can("accounting:ledger_accounts:create"),
      },
      {
        key: "import-history",
        className: "button-1",
        action: () => setShowImportHistory(true),
        label: "Import history",
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("accounting:ledger_accounts:create"),
      },
    ];
    return acts;
  }, [can]);

  const dynamicActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiveModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("accounting:ledger_accounts:delete"),
      },
    ];
    return acts;
  }, [can]);

  const onHideModal = useCallback(() => {
    setCreating(false);
    setSearchParams({ anid: undefined });
  }, []);

  return (
    <>
      <TableV2
        id={"ledger-accounts"}
        resource="accounts"
        data={data}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={(e) => setSearchParams({ anid: e._id })}
        showReportViews={true}
        rowClickDisabled={() => cannot("accounting:ledger_accounts:update")}
      />
      {(creating || updating) && <LedgerAccountModal hide={onHideModal} ledgerAccountToUpdate={updating} />}
      {archiveModal && (
        <ConfirmModal
          title="Delete account(s)?"
          body="Are you sure you want to delete the selected account(s)? Doing so will automatically remove the account(s) from all mappings, fringes, post tax deductions, allowances, etc."
          yellowBodyText
          yesText="Delete"
          onYes={archiveSelected}
          loading={archiving}
          noText="Cancel"
          onNo={() => setArchiveModal(false)}
        />
      )}
      {showImportHistory && (
        <ImportHistory
          id={"ledger_accounts"}
          resource={"ledger accounts"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
    </>
  );
};

const columns: ColumnConfig<LedgerAccountTableEntry>[] = [
  {
    field: "label",
    headerName: "Label",
    dataType: "string",
  },
  {
    field: "external_id",
    headerName: "ID",
    dataType: "string",
  },
  {
    field: "classification",
    filter: true,
    headerName: "Type",
    enableRowGroup: true,
    dataType: "string",
  },
  {
    field: "active",
    filter: true,
    headerName: "Active",
    enableRowGroup: true,
    dataType: "boolean",
  },
  {
    field: "source",
    filter: true,
    headerName: "Source",
    enableRowGroup: true,
    dataType: "string",
  },
];
