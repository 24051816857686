import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Button, Formblock, Loader, SelectOption } from "ui";
import { AggregatedDailyReport } from "dashboard/miter";
import FormContentBox from "../shared/FormContentBox";
import styles from "./DailyReports.module.css";
import { Clock, Notebook, Paperclip, Plus, Wrench, X } from "phosphor-react";
import { useNavigate, useParams } from "react-router-dom";
import TimesheetsTable from "../tables/TimesheetsTable";
import { capitalize, Notifier } from "dashboard/utils";
import { baseSensitiveCompare, sleep, useQuery } from "miter-utils";
import * as vals from "dashboard/utils/validators";
import { MemoizedDailyReportTimesheets } from "./DailyReportTimesheets";
import {
  cleanTimesheetsFromBackend,
  excludeLiveTimesheetsToggle,
  TimesheetTableEntry,
} from "dashboard/utils/timesheetUtils";
import { MiterAPI, AggregatedEquipmentLog } from "dashboard/miter";
import { formatFilesForUpload } from "miter-utils";
import { CreateDailyReportParams } from "dashboard/miter";
import { FilePickerFile } from "ui/form/FilePicker";
import { displayFieldErrors } from "dashboard/utils/errors";
import EquipmentLogsTable from "../equipment/EquipmentLogsTable";
import { Helmet } from "react-helmet";
import { MemoizedDailyReportEquipmentLogs } from "./DailyReportEquipmentLogs";

import {
  useActiveCompany,
  useActiveCompanyId,
  useActiveTeam,
  useJobOptions,
  useJobs,
  useLookupJob,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import TagModal from "../tags/TagModal";
import { useDailyReportAbilities } from "dashboard/hooks/abilities-hooks/useDailyReportAbilities";
import { JobInput } from "../shared/JobInput";

const DailyReportForm: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const lookupTeam = useLookupTeam();
  const activeTeamMembers = useActiveTeam();
  const lookupJob = useLookupJob();
  const form = useForm({ shouldUnregister: false });
  const navigate = useNavigate();
  const dailyReportAbilities = useDailyReportAbilities();
  const jobs = useJobs();

  const { tags, getTags } = useContext(AppContext);

  const dailyReportTags = useMemo(() => tags.filter((tag) => tag.parent_type === "daily_report"), [tags]);

  const [dailyReport, setDailyReport] = useState<AggregatedDailyReport>();

  const jobOptions = useJobOptions({
    defaultValue: dailyReport?.job?._id,
    predicate: dailyReportAbilities.jobPredicate(dailyReport ? "update" : "create"),
  });

  const [showTimesheetsModal, setShowTimesheetsModal] = useState(false);
  const [openNewTagModal, setOpenNewTagModal] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const [selectedTimesheets, setSelectedTimesheets] = useState<TimesheetTableEntry[]>([]);
  const [tenativelySelectedTimesheets, setTenativelySelectedTimesheets] = useState<TimesheetTableEntry[]>([]);

  const [showEquipmentLogsModal, setShowEquipmentLogsModal] = useState(false);
  const [selectedEquipmentLogs, setSelectedEquipmentLogs] = useState<AggregatedEquipmentLog[]>([]);
  const [tenativelySelectedEquipmentLogs, setTenativelySelectedEquipmentLogs] = useState<
    AggregatedEquipmentLog[]
  >([]);
  const [showNewEquipmentLogModal, setShowNewEquipmentLogModal] = useState(false);

  // We need this b/c we can't use the form's setValue function to set the notes by itself b/c react-hook-form only works with inputs that already exist.
  const [notes, setNotes] = useState<{ date: string; type: string; content: string }[]>([]);
  const [workItems, setWorkItems] = useState<{ date: string; name: string; description: string }[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const { register, control, errors, watch, setValue, setError, handleSubmit: submitHandler } = form;
  const formData = watch();
  const selectedJob = lookupJob(formData.job?.value);

  const dailyReportID = useParams<{ id: string }>().id;
  const defaultFiles = dailyReport?.files?.map((file) => ({ data: file })) || [];
  const enableMultiDay = !!activeCompany?.settings?.daily_reports?.enable_multi_day_reports;

  const query = useQuery();
  const urlJobID = query.get("job");

  const creatorOptions = useMemo(() => {
    const nonSupervisorsCanBeCreators = !!activeCompany?.settings.daily_reports.enable_non_supervisor_access;
    const jobSupervisors = selectedJob?.supervisors || [];
    const options: SelectOption[] = [];

    for (const tm of activeTeamMembers) {
      if (
        (nonSupervisorsCanBeCreators ||
          tm.is_universal_supervisor ||
          jobSupervisors.some((s) => s._id === tm._id)) &&
        (dailyReportAbilities.teamPredicate("create")(tm) || dailyReportAbilities.teamPredicate("update")(tm))
      ) {
        options.push({ label: tm.full_name, value: tm._id });
      }
    }

    const existingCreatorId = dailyReport?.creator?._id;
    if (existingCreatorId) {
      if (options.every((o) => o.value !== existingCreatorId)) {
        const creator = lookupTeam(existingCreatorId);
        if (creator) options.push({ label: creator.full_name, value: creator._id });
      }
    }
    return options.sort((a, b) => baseSensitiveCompare(a.label, b.label));
  }, [activeTeamMembers, selectedJob, dailyReport?.creator?._id]);

  useEffect(() => {
    if (dailyReport?.status === "approved") {
      Notifier.error("This daily report has already been approved. Please unapprove it before editing.");
      navigate("/daily-reports");
    }
  }, []);

  useEffect(() => {
    if (dailyReport) {
      setNotes(dailyReport.notes);
      setWorkItems(dailyReport.work_items);
    }
  }, [dailyReport]);

  useEffect(() => {
    sleep(500).then(() => {
      setValue("notes", notes);
    });
  }, [notes]);

  useEffect(() => {
    sleep(500).then(() => {
      setValue("workItems", workItems);
    });
  }, [workItems]);

  useEffect(() => {
    if (dailyReportID && jobs.length && activeTeamMembers.length) getDailyReport();
  }, [dailyReportID, jobs, activeTeamMembers]);

  useEffect(() => {
    if (!dailyReport?.creator) return;
    setValue("creator", {
      value: dailyReport?.creator?._id?.toString(),
      label: dailyReport?.creator?.full_name,
    });
  }, [activeTeamMembers, selectedJob, dailyReport?.creator]);

  useEffect(() => {
    if (urlJobID) {
      const jobOption = jobOptions.find((o) => o.value === urlJobID);
      setValue("job", jobOption);
    }
  }, [urlJobID, lookupJob]);

  const cleanDate = (date: string | DateTime | null | undefined) => {
    return date ? (typeof date === "string" ? DateTime.fromISO(date) : date) : null;
  };

  /*********************************************************
   *  API functions
   **********************************************************/
  const formatNoteParams = (note) => {
    const isMultiDay = enableMultiDay && formData.end_date && formData.start_date !== formData.end_date;

    return {
      date: isMultiDay
        ? cleanDate(note.date)?.toISODate() || cleanDate(formData.start_date)?.toISODate()
        : cleanDate(formData.start_date)?.toISODate(),
      type: note.type?.value || null,
      content: note.content,
    };
  };

  const formatWorkItemParams = (workItem) => {
    const isMultiDay = enableMultiDay && formData.end_date && formData.start_date !== formData.end_date;

    return {
      date: isMultiDay
        ? cleanDate(workItem.date)?.toISODate() || cleanDate(formData.start_date)?.toISODate()
        : cleanDate(formData.start_date)?.toISODate(),
      name: workItem.name,
      description: workItem.description,
    };
  };

  const formatDailyReportParams = (): CreateDailyReportParams["daily_report_params"] => {
    if (!formData.creator?.value) {
      throw new Error("Please select a creator.");
    }

    return {
      company: activeCompanyId!,
      job: formData.job.value,
      start_date: formData.start_date.toISODate(),
      end_date: formData.end_date?.toISODate() || formData.start_date.toISODate(),
      creator: formData.creator.value,
      notes: (formData.notes || []).map((note) => formatNoteParams(note)),
      work_items: (formData.workItems || []).map((workItem) => formatWorkItemParams(workItem)),
      timesheets: selectedTimesheets.map((timesheet) => timesheet._id),
      equipment_logs: selectedEquipmentLogs.map((equipmentLog) => equipmentLog._id.toString()),
      tag_ids: formData.tag_ids?.map((tag) => tag.value),
    };
  };

  const getDailyReport = async () => {
    if (!dailyReportID) return;
    setLoadingReport(true);

    try {
      const res = await MiterAPI.daily_reports.retrieve(dailyReportID);
      if (res.error) {
        throw new Error(res.error);
      }

      setDailyReport(res);
      const radiusMiles = activeCompany?.settings.timesheets.geofence_radius;
      setSelectedTimesheets(cleanTimesheetsFromBackend(res.timesheets, activeCompany?.timezone, radiusMiles));
      setSelectedEquipmentLogs(res.equipment_logs);
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      console.error("Unable to get daily report", err);
    }
    setLoadingReport(false);
  };

  const createDailyReport = async () => {
    setSubmitting(true);
    try {
      const cleanedParams = {
        daily_report_params: formatDailyReportParams(),
      };

      // create daily report first so we can get the ID for files
      const res = await MiterAPI.daily_reports.create(cleanedParams);
      if (res.error) {
        displayFieldErrors(res.fields!, setError);
        throw new Error(res.error);
      }

      const createFiles = formatFilesForUpload(formData.files || [], activeCompanyId!, {
        parent_id: res.daily_report._id,
        parent_type: "DailyReport",
      });

      // directly upload files to S3 and create in DB, don't pass through backend
      await MiterAPI.files.upload({
        files: createFiles,
      });

      setDailyReport(res.daily_report);
      navigate(`/daily-reports`);

      Notifier.success("Daily report created successfully");
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      console.error("Unable to create daily report", err);
    }
    setSubmitting(false);
  };

  const updateDailyReport = async () => {
    if (!dailyReportID) return;

    setSubmitting(true);
    try {
      const createFiles = formatFilesForUpload(formData.files || [], activeCompanyId!, {
        parent_id: dailyReportID,
        parent_type: "DailyReport",
      });

      const deleteFiles = (formData.files || [])
        .filter((file: FilePickerFile) => file.deleted && file.data)
        .map((file: FilePickerFile) => file!.data!._id);

      const cleanedParams = {
        daily_report_params: formatDailyReportParams(),
      };

      // directly upload files to S3 and create in DB, don't pass through backend
      await MiterAPI.files.upload({
        files: createFiles,
      });
      await MiterAPI.files.delete(deleteFiles);

      const res = await MiterAPI.daily_reports.update(dailyReportID, cleanedParams);
      if (res.error) {
        displayFieldErrors(res.fields!, setError);
        throw new Error(res.error);
      }

      setDailyReport(res.daily_report);

      navigate(`/daily-reports`);
      Notifier.success("Daily report updated successfully");
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      console.error("Unable to create daily report", err);
    }
    setSubmitting(false);
  };

  /*********************************************************
   *  Handler functions
   **********************************************************/
  const handleShowTimesheetSelect = () => {
    if (!selectedJob || !formData.start_date || (enableMultiDay && !formData.end_date)) {
      Notifier.error("Please select a job and date");
    } else {
      setShowTimesheetsModal(true);
    }
  };

  const handleTimesheetSelectSubmit = () => {
    setSelectedTimesheets(tenativelySelectedTimesheets);
    setTenativelySelectedTimesheets([]);
    setShowTimesheetsModal(false);
  };

  const handleTimesheetSelectHide = () => {
    setTenativelySelectedTimesheets([]);
    setShowTimesheetsModal(false);
  };

  const handleShowEquipmentLogSelect = () => {
    if (!selectedJob || !formData.start_date || (enableMultiDay && !formData.end_date)) {
      Notifier.error("Please select a job and date");
    } else {
      setShowEquipmentLogsModal(true);
    }
  };

  const handleEquipmentLogSelectSubmit = () => {
    setSelectedEquipmentLogs([...tenativelySelectedEquipmentLogs]);
    setTenativelySelectedEquipmentLogs([]);
    setShowEquipmentLogsModal(false);
  };

  const handleEquipmentLogSelectHide = () => {
    setTenativelySelectedEquipmentLogs([]);
    setShowEquipmentLogsModal(false);
  };

  const handleEquipmentLogSaved = useCallback(
    (log: AggregatedEquipmentLog, action: "create" | "update" | "delete") => {
      if (action === "create") {
        setSelectedEquipmentLogs([...selectedEquipmentLogs, log]);
      } else if (action === "update") {
        setSelectedEquipmentLogs(
          selectedEquipmentLogs.map((curLog) => (curLog._id === log._id ? log : curLog))
        );
      } else if (action === "delete") {
        setSelectedEquipmentLogs(selectedEquipmentLogs.filter((curLog) => curLog._id !== log._id));
      }

      setShowNewEquipmentLogModal(false);
    },
    [selectedEquipmentLogs]
  );

  const handleEquipmentLogModalHide = useCallback(() => {
    setShowNewEquipmentLogModal(false);
  }, []);

  const handleAddNote = () => {
    const newNote = { date: formData.start_date, content: "", type: "general" };
    const newNotes = [...notes, newNote];

    setNotes(newNotes);
  };

  const handleRemoveNote = (index) => {
    const newNotes = formData?.notes?.filter((note, i) => i !== index);
    setNotes(newNotes);
  };

  const handleAddWorkItem = () => {
    const newWorkItem = { date: formData.start_date, name: "", description: "" };
    const newWorkItems = [...workItems, newWorkItem];

    setWorkItems(newWorkItems);
  };

  const handleRemoveWorkItem = (index) => {
    const newWorkItems = formData?.workItems?.filter((workItem, i) => i !== index);
    setWorkItems(newWorkItems);
  };

  const handleSubmit = async () => {
    if (Object.values(errors).length > 0) {
      return Notifier.error("Please fix the required fields");
    }

    if (!dailyReportID) {
      submitHandler(createDailyReport)();
    } else {
      submitHandler(updateDailyReport)();
    }
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderHeader = () => {
    return (
      <div className={styles["header"]}>
        <h1 className={styles["header-title"]}>{(dailyReportID ? "Edit" : "New") + " daily report"}</h1>
        <div className={styles["close-button"]} onClick={() => navigate(-1)}>
          <X />
        </div>
      </div>
    );
  };

  const renderBasicInfo = () => {
    return (
      <FormContentBox title="Basic info">
        <div className={styles["basic-info-fields"]}>
          <JobInput
            label="Job*"
            type="select"
            className="modal"
            defaultValue={dailyReport?.job?._id}
            options={jobOptions}
            form={form}
            requiredSelect={true}
            name="job"
            editing={true}
            control={control}
            errors={errors}
            onChange={() => {
              setSelectedTimesheets([]);
              setSelectedEquipmentLogs([]);
              setValue("creator", null);
            }}
          />
          <Formblock
            label={enableMultiDay ? "Start date*" : "Date*"}
            type="datetime"
            className="modal"
            defaultValue={dailyReport?.start_date ? DateTime.fromISO(dailyReport.start_date) : undefined}
            name="start_date"
            editing={true}
            control={control}
            register={register(vals.required)}
            errors={errors}
            dateOnly={true}
            rules={vals.required}
            sideEffect={() => {
              setSelectedTimesheets([]);
              setSelectedEquipmentLogs([]);
            }}
          />
          {enableMultiDay && (
            <Formblock
              label="End date*"
              type="datetime"
              className="modal"
              defaultValue={dailyReport?.end_date ? DateTime.fromISO(dailyReport.end_date) : undefined}
              name="end_date"
              editing={true}
              control={control}
              register={register(vals.required)}
              errors={errors}
              dateOnly={true}
              rules={vals.required}
              sideEffect={() => {
                setSelectedTimesheets([]);
                setSelectedEquipmentLogs([]);
              }}
            />
          )}
          <Formblock
            label="Creator*"
            type="select"
            className="modal"
            defaultValue={dailyReport?.creator?._id}
            options={creatorOptions}
            requiredSelect={true}
            name="creator"
            editing={true}
            control={control}
            errors={errors}
            placeholder="Select a creator"
          />
          <Formblock
            type="multiselect"
            name="tag_ids"
            label="Tags"
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Select tags"}
            defaultValue={dailyReport?.tag_ids}
            options={dailyReportTags.map((tag) => ({ label: capitalize(tag.label), value: tag._id }))}
            val={vals.required}
            labelButtonText="+ Add tag"
            labelButtonClick={() => setOpenNewTagModal(true)}
          />
        </div>
      </FormContentBox>
    );
  };

  const renderTimesheetsEmptyState = () => {
    return (
      <>
        <div className={styles["work-log-empty-state"]}>
          <Clock weight="duotone" color="#4d55bb" className={styles["work-log-empty-state-clock"]} />
          <p>Select timesheets for this daily</p>
          <Button className={"button-2  " + styles["empy-state-button"]} onClick={handleShowTimesheetSelect}>
            Select timesheets
          </Button>
        </div>
      </>
    );
  };

  const renderWorklogsTable = () => {
    return (
      <MemoizedDailyReportTimesheets
        timesheets={selectedTimesheets}
        readOnly={false}
        setTimesheets={setSelectedTimesheets}
      />
    );
  };

  const renderTimesheets = () => {
    const hasTimesheets = selectedTimesheets.length > 0;
    const actions = hasTimesheets ? (
      <Button
        className={"button-2 no-margin " + styles["empy-state-button"]}
        onClick={handleShowTimesheetSelect}
      >
        Select timesheets
      </Button>
    ) : undefined;

    return (
      <FormContentBox title="Timesheets" actions={actions}>
        <>
          {!hasTimesheets && renderTimesheetsEmptyState()}
          {hasTimesheets && renderWorklogsTable()}
        </>
      </FormContentBox>
    );
  };

  const renderWorkItemsEmptyState = () => {
    return (
      <>
        <div className={styles["work-log-empty-state"]}>
          <Notebook weight="duotone" color="#4d55bb" className={styles["work-log-empty-state-clock"]} />
          <p>Add work items to keep track of work being done on the job site</p>
          <Button className={"button-2  " + styles["empy-state-button"]} onClick={handleAddWorkItem}>
            Add a work item
          </Button>
        </div>
      </>
    );
  };

  const renderWorkItems = () => {
    const hasWorkItems = workItems.length > 0;
    const actions = hasWorkItems ? (
      <Button className={"button-2 no-margin " + styles["empy-state-button"]} onClick={handleAddWorkItem}>
        <Plus />
      </Button>
    ) : undefined;

    const workItemElements = workItems.map((workItem, i) => (
      <div className={styles["note-container"]} key={"workItem-" + i}>
        <Formblock
          label="Name*"
          type="text"
          className="modal"
          defaultValue={workItem.name}
          name={`workItems.${i}.name`}
          editing={true}
          control={control}
          register={register(vals.required)}
          errors={errors}
        />
        {enableMultiDay && (
          <Formblock
            label="Date*"
            type="datetime"
            className="modal"
            defaultValue={workItem.date ? DateTime.fromISO(workItem.date) : undefined}
            name={`workItems.${i}.date`}
            editing={true}
            control={control}
            register={register(vals.required)}
            errors={errors}
            dateOnly={true}
            min={formData.start_date}
            max={formData.end_date}
          />
        )}
        <Formblock
          label="Description*"
          type="paragraph"
          className="modal"
          defaultValue={workItem.description}
          name={`workItems.${i}.description`}
          editing={true}
          control={control}
          register={register(vals.required)}
          errors={errors}
        />
        <div className={styles["remove-note-button"]} onClick={() => handleRemoveWorkItem(i)}>
          <X />
        </div>
      </div>
    ));

    return (
      <FormContentBox title="Work Items" actions={actions}>
        <>
          {!hasWorkItems && renderWorkItemsEmptyState()}
          {hasWorkItems && workItemElements}
        </>
      </FormContentBox>
    );
  };

  const renderNotesEmptyState = () => {
    return (
      <>
        <div className={styles["work-log-empty-state"]}>
          <Paperclip weight="duotone" color="#4d55bb" className={styles["work-log-empty-state-clock"]} />
          <p>Add notes to keep track of job site incidents, deliveries, delays, etc.</p>
          <Button className={"button-2  " + styles["empy-state-button"]} onClick={handleAddNote}>
            Add a note
          </Button>
        </div>
      </>
    );
  };

  const renderEquipmentLogsEmptyState = () => {
    return (
      <>
        <div className={styles["work-log-empty-state"]}>
          <Wrench weight="duotone" color="#4d55bb" className={styles["work-log-empty-state-clock"]} />
          <p>Select or create equipment logs to attach to this daily report</p>
          <Button
            className={"button-2 " + styles["empy-state-button"]}
            onClick={handleShowEquipmentLogSelect}
          >
            Add equipment logs
          </Button>
        </div>
      </>
    );
  };

  const renderEquipmentLogs = () => {
    const hasEquipmentLogs = selectedEquipmentLogs.length > 0;
    const actions = hasEquipmentLogs ? (
      <>
        <Button className={"button-1 " + styles["empy-state-button"]} onClick={handleShowEquipmentLogSelect}>
          Manage equipment logs
        </Button>
        <Button
          className={"button-2 " + styles["empy-state-button"]}
          onClick={() => setShowNewEquipmentLogModal(true)}
        >
          + New log
        </Button>
      </>
    ) : undefined;

    return (
      <FormContentBox title="Equipment logs" actions={actions}>
        <>
          {!hasEquipmentLogs && renderEquipmentLogsEmptyState()}
          {hasEquipmentLogs && (
            <MemoizedDailyReportEquipmentLogs
              equipmentLogs={selectedEquipmentLogs}
              setEquipmentLogs={setSelectedEquipmentLogs}
              startDate={formData.start_date}
              job={selectedJob}
              createNewEquipmentLog={showNewEquipmentLogModal}
              onEquipmentLogModalSubmit={handleEquipmentLogSaved}
              onEquipmentLogModalHide={handleEquipmentLogModalHide}
              hideDelete={true}
            />
          )}
        </>
      </FormContentBox>
    );
  };

  const renderNotes = () => {
    const hasNotes = notes.length > 0;
    const actions = hasNotes ? (
      <Button className={"button-2 no-margin " + styles["empy-state-button"]} onClick={handleAddNote}>
        <Plus />
      </Button>
    ) : undefined;

    const noteElements = notes.map((note, i) => (
      <div className={styles["note-container"]} key={"note-" + i}>
        <Formblock
          label="Type*"
          type="select"
          className="modal"
          defaultValue={note.type}
          options={["general", "incident", "safety", "delivery", "delay"].map((type) => ({
            label: capitalize(type),
            value: type,
          }))}
          requiredSelect={true}
          name={`notes.${i}.type`}
          editing={true}
          control={control}
          errors={errors}
        />
        {enableMultiDay && (
          <Formblock
            label="Date*"
            type="datetime"
            className="modal"
            defaultValue={note.date ? DateTime.fromISO(note.date) : undefined}
            name={`notes.${i}.date`}
            editing={true}
            control={control}
            register={register(vals.required)}
            errors={errors}
            dateOnly={true}
            min={formData.start_date}
            max={formData.end_date}
          />
        )}
        <Formblock
          label="Content*"
          type="paragraph"
          className="modal"
          defaultValue={note.content}
          name={`notes.${i}.content`}
          editing={true}
          control={control}
          register={register(vals.required)}
          errors={errors}
        />
        <div className={styles["remove-note-button"]} onClick={() => handleRemoveNote(i)}>
          <X />
        </div>
      </div>
    ));

    return (
      <FormContentBox title="Notes" actions={actions}>
        <>
          {!hasNotes && renderNotesEmptyState()}
          {hasNotes && noteElements}
        </>
      </FormContentBox>
    );
  };

  const renderAttachments = () => {
    return (
      <FormContentBox title="Photos & documents">
        <Formblock
          type="file"
          className="modal"
          defaultValue={defaultFiles || []}
          name={"files"}
          editing={true}
          control={control}
          variant={"dropzone-button"}
          multiple={true}
          // Max file size of 20MB
          maxFileSize={20 * 1024 * 1024}
          showButton={true}
        />
      </FormContentBox>
    );
  };

  const renderFooter = () => {
    return (
      <div className={styles["footer"]}>
        <Button
          loading={submitting}
          className={"button-2 no-margin " + styles["footer-button"]}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button className={"button-1 no-margin " + styles["footer-button"]} onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </div>
    );
  };

  const renderTimesheetsModal = () => {
    if (!selectedJob || !formData.start_date || (enableMultiDay && !formData.end_date)) return <></>;

    const startOfSelectedDay = formData.start_date?.startOf("day");
    const endOfSelectedDay = (formData.end_date || formData.start_date)?.endOf("day");

    return (
      <ActionModal
        headerText={"Select timesheets"}
        submitText={"Submit"}
        showSubmit={true}
        cancelText={"Cancel"}
        showCancel={true}
        onHide={handleTimesheetSelectHide}
        onSubmit={handleTimesheetSelectSubmit}
        onCancel={handleTimesheetSelectHide}
        wrapperStyle={{ width: "80%", minWidth: 1000 }}
      >
        <p>
          Select the timesheets that you would like to use on this daily report. All of the timesheets
          displayed are from the specified job and day.
        </p>
        <TimesheetsTable
          defaultFilters={[
            {
              type: "or",
              value: [
                {
                  field: "clock_in",
                  type: "timestamp",
                  comparisonType: "<+>e",
                  value: [startOfSelectedDay.toSeconds(), endOfSelectedDay.toSeconds()],
                },
                {
                  field: "_id",
                  type: "_id",
                  comparisonType: "in",
                  value: selectedTimesheets.map((t) => t._id),
                },
              ],
            },
            {
              type: "or",
              value: [
                {
                  field: "job._id",
                  type: "_id",
                  value: selectedJob?._id,
                },
                {
                  field: "_id",
                  type: "_id",
                  comparisonType: "in",
                  value: selectedTimesheets.map((t) => t._id),
                },
              ],
            },
          ]}
          togglerConfigFilter={excludeLiveTimesheetsToggle}
          onSelect={(timesheets) => setTenativelySelectedTimesheets(timesheets as TimesheetTableEntry[])}
          defaultSelectedRows={selectedTimesheets}
          activeJob={selectedJob}
          activeDate={formData.start_date}
          hasDailyReport={true}
        />
      </ActionModal>
    );
  };

  const renderEquipmentLogsModal = () => {
    if (!selectedJob || !formData.start_date || (enableMultiDay && !formData.end_date)) return <></>;

    return (
      <ActionModal
        headerText={"Select equipment logs"}
        submitText={"Submit"}
        showSubmit={true}
        cancelText={"Cancel"}
        showCancel={true}
        onHide={handleEquipmentLogSelectHide}
        onSubmit={handleEquipmentLogSelectSubmit}
        onCancel={handleEquipmentLogSelectHide}
        wrapperClassName={"medium-width-modal-wrapper"}
      >
        <p>Select or create the equipment logs that you would like to attach to this daily report.</p>
        <EquipmentLogsTable
          onRowSelect={(logs) => setTenativelySelectedEquipmentLogs(logs)}
          defaultSelectedRows={selectedEquipmentLogs}
          activeJob={selectedJob}
          activeStartDate={formData.start_date}
          activeEndDate={formData.end_date}
          version="daily_report_form"
        />
      </ActionModal>
    );
  };

  const renderTagModal = () => {
    return (
      <TagModal
        parentType="daily_report"
        onFinish={() => {
          getTags();
          setOpenNewTagModal(false);
        }}
        onHide={() => setOpenNewTagModal(false)}
      />
    );
  };

  return (
    <div className={styles["daily-report-form-wrapper"]}>
      <Helmet>
        <title>New Daily Report | Miter</title>
      </Helmet>
      {loadingReport && <Loader />}
      {(!dailyReportID || (dailyReportID && dailyReport)) && (
        <div className={styles["daily-report-form"]}>
          {renderHeader()}
          {renderBasicInfo()}
          {renderTimesheets()}
          {renderWorkItems()}
          {renderEquipmentLogs()}
          {renderNotes()}
          {renderAttachments()}
          {renderFooter()}
        </div>
      )}

      {showTimesheetsModal && renderTimesheetsModal()}
      {showEquipmentLogsModal && renderEquipmentLogsModal()}
      {openNewTagModal && renderTagModal()}
    </div>
  );
};

export default DailyReportForm;
