import {
  AggregatedTeamMember,
  AggregatedTimeOffRequest,
  Company,
  Department,
  Policy,
  PolicyRule,
  TeamMember,
  TimeOffRequest,
} from "dashboard/miter";
import { useCallback } from "react";
import { TimeOffRequestPolicyField, TimeOffRequestPolicyFields } from "../../../backend/models/policy";
import { findItemPolicyRule } from "dashboard/utils/approvals";
import { TeamPortalUser } from "team-portal/utils/miter";

type UseTimeOffRequestPolicy = {
  policy: Policy | undefined;
  meetsPolicyRequirements: () => boolean;
  matchingPolicyRule: PolicyRule | null | undefined;
  isFieldHidden: (field: TimeOffRequestPolicyField) => boolean;
  isFieldVisible: (field: TimeOffRequestPolicyField) => boolean;
  isFieldRequired: (field: TimeOffRequestPolicyField) => boolean;
  needsAttentionMessages: string[];
};

export type TimeOffRequestPolicyItem = AggregatedTimeOffRequest | Partial<TimeOffRequest> | null | undefined;

type UseTimeOffRequestPolicies = {
  buildPolicy: (item: TimeOffRequestPolicyItem) => UseTimeOffRequestPolicy;
};

export type TimeOffRequestPolicyParams = {
  item?: TimeOffRequestPolicyItem;
  company?: Partial<Company> | null;
  lookupPolicy?: (id: string | null | undefined) => Policy | undefined;
  lookupDepartment?: (id: string | null | undefined) => Department | undefined;
  lookupTeam?: (id: string | null | undefined) => AggregatedTeamMember | TeamPortalUser | undefined;
};

/** Reusable hook to get the policy and/or policy rule for an item */
// TODO remove all these extra parameters to clean up when we remove portal and move this to dashboard
// as we directly call hooks in useTimeOffRequestPolicies for these fields when in dashboard
export const useTimeOffRequestPolicy = (
  params: TimeOffRequestPolicyParams | null
): UseTimeOffRequestPolicy => {
  const { buildPolicy } = useTimeOffRequestPolicies(params || {});
  return buildPolicy(params?.item);
};

export const useTimeOffRequestPolicies = (params: TimeOffRequestPolicyParams): UseTimeOffRequestPolicies => {
  /*********************************************************
   * Lookup functions to get the team, and department
   **********************************************************/
  const { company, lookupPolicy, lookupDepartment, lookupTeam } = params;
  const getTeamMember = useCallback(
    (item: TimeOffRequestPolicyItem): AggregatedTeamMember | TeamMember | TeamPortalUser | undefined => {
      if (!item || !("employee" in item)) return;

      if (typeof item.employee === "string") {
        if (lookupTeam) {
          return lookupTeam(item.employee);
        }
      } else {
        return item.employee;
      }
    },
    [lookupTeam]
  );

  const getDepartment = useCallback(
    (item: TimeOffRequestPolicyItem): Department | undefined => {
      if (!item || !lookupDepartment) return;
      return lookupDepartment(item.department_id);
    },
    [getTeamMember, lookupDepartment]
  );

  /*********************************************************
   * Policy getters
   **********************************************************/

  const getTeamMemberPolicy = useCallback(
    (item: TimeOffRequestPolicyItem): Policy | undefined => {
      const teamMember = getTeamMember(item);
      if (!lookupPolicy) return;
      return lookupPolicy(teamMember?.time_off_request_policy_id);
    },
    [getTeamMember, lookupPolicy]
  );

  const getDepartmentPolicy = useCallback(
    (item: TimeOffRequestPolicyItem): Policy | undefined => {
      if (!lookupPolicy) return;
      const department = getDepartment(item);
      return lookupPolicy(department?.time_off_request_policy_id);
    },
    [getDepartment, lookupPolicy]
  );

  const getCompanyPolicy = useCallback((): Policy | undefined => {
    if (!lookupPolicy) return;
    return lookupPolicy(company?.settings?.time_off?.default_policy_id);
  }, [company?.settings?.time_off?.default_policy_id, lookupPolicy]);

  const getPolicy = useCallback(
    (item: TimeOffRequestPolicyItem): Policy | undefined => {
      const teamMemberPolicy = getTeamMemberPolicy(item);
      const departmentPolicy = getDepartmentPolicy(item);
      const companyPolicy = getCompanyPolicy();
      return teamMemberPolicy || departmentPolicy || companyPolicy;
    },
    [getDepartmentPolicy, getCompanyPolicy]
  );

  const buildPolicy = useCallback(
    (item: TimeOffRequestPolicyItem): UseTimeOffRequestPolicy => {
      const policy = getPolicy(item);
      const matchingPolicyRule = findItemPolicyRule(item, policy);

      // Helper function to determine the field's policy status based on the new and old policy settings.
      const getFieldPolicyStatus = (
        field: TimeOffRequestPolicyField,
        status: "hidden" | "required"
      ): boolean => {
        if (!policy || !matchingPolicyRule?.fields) return false;
        const fields = matchingPolicyRule.fields as TimeOffRequestPolicyFields;
        return fields[field] === status;
      };

      // Use the helper function to check if the field is hidden.
      const isFieldHidden = (field: TimeOffRequestPolicyField): boolean => {
        return getFieldPolicyStatus(field, "hidden");
      };

      // Use the helper function to check if the field is required.
      const isFieldRequired = (field: TimeOffRequestPolicyField): boolean => {
        return getFieldPolicyStatus(field, "required");
      };

      // Use the isFieldHidden function to check if the field is visible.
      const isFieldVisible = (field: TimeOffRequestPolicyField): boolean => {
        return !isFieldHidden(field);
      };

      const meetsPolicyRequirements = (): boolean => {
        const { employee_note, company_note } = item || {};

        if (isFieldRequired("employee_note") && !employee_note) return false;
        if (isFieldRequired("company_note") && !company_note) return false;
        if (item && "approval_stage" in item && item.approval_stage?.kick_back) return false;

        return true;
      };

      const needsAttentionMessages = (() => {
        const reasons: string[] = [];

        const { employee_note, company_note } = item || {};

        const activityFailed = isFieldRequired("employee_note") && !employee_note;
        if (activityFailed) reasons.push("Employee note is required but missing.");

        const jobFailed = isFieldRequired("company_note") && !company_note;
        if (jobFailed) reasons.push("Company note is required but missing.");

        // If this item has been kicked back, it doesn't meet the policy requirements
        if (item && "approval_stage" in item && item.approval_stage?.kick_back) {
          reasons.push(
            "Item kicked back: " +
              (item.approval_stage.kick_back.notes
                ? item.approval_stage.kick_back.notes
                : " please fix or contact the your approver.")
          );
        }
        return reasons;
      })();

      return {
        meetsPolicyRequirements,
        needsAttentionMessages,
        matchingPolicyRule,
        isFieldHidden,
        isFieldVisible,
        isFieldRequired,
        policy,
      };
    },
    [getDepartment, getDepartmentPolicy, getPolicy]
  );

  return { buildPolicy };
};
