import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useActiveCompany, useLookupLocation } from "dashboard/hooks/atom-hooks";
import { MiterAPI, Location } from "dashboard/miter";
import { Gear, Plus, TrashSimple } from "phosphor-react";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import pluralize from "pluralize";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteModal, Notifier, TableV2 } from "ui";
import { TableActionLink } from "ui/table-v2/Table";
import { MiterFilterArray } from "backend/utils";
import { ForageRequest } from "backend/utils/forage/forage-types";
import LocationModal from "./LocationModal";
import { useLocationsTableColDef } from "./locationsUtils";

export const LocationsTable: React.FC = () => {
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;
  const lookupLocation = useLookupLocation();
  const columns = useLocationsTableColDef();
  const navigate = useNavigate();
  const { can } = useMiterAbilities();
  const { setFailures, renderFailuresModal } = useFailuresModal();

  const [selectFilter, setSelectFilter] = useState<MiterFilterArray>();
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const getData = useCallback(
    async (query: ForageRequest) => {
      if (!activeCompany) return { data: [], next_page: null, prev_page: null };

      const filter = (query.filter || []).concat([
        { field: "company_id", value: activeCompanyId },
      ]) as MiterFilterArray;

      query.select?.push({ field: "parent_location_id", show: true });
      query.select?.push({ field: "status", show: true });
      query.select?.push({ field: "supervisor_ids", show: true });
      query.select?.push({ field: "superintendent_ids", show: true });

      const forageFunction = MiterAPI.locations.forage;
      const res = await forageFunction({ ...query, filter });

      res.data = (res.data ?? []).map((d) => ({
        ...d,
        parent_location_name: d.parent_location_id ? lookupLocation(d.parent_location_id)?.name : "-",
        active: d.status === "active",
      }));

      return res;
    },
    [!!activeCompany]
  );

  const shouldUseSelectAllFilter = useMemo(() => {
    if (!selectFilter) return false;
    if (!Array.isArray(selectFilter)) return false;

    // If select filter is an empty array but exists, this is a select all and we should use
    if (selectFilter.length === 0) return true;

    // If we don't have a select filter value, then we shouldn't use it
    const selectFilterValue = selectFilter[0]?.value;
    if (!selectFilterValue) return false;

    const hasNonIDFilter = selectFilterValue.length > 1 || selectFilterValue[0]?.field !== "_id";
    return hasNonIDFilter;
  }, [selectFilter]);

  const handleOnSelect = (selections) => {
    setSelectedLocations(selections);
  };

  const buildBulkActionParams = () => {
    const finalFilter: MiterFilterArray = [{ field: "company_id", value: activeCompanyId }];
    if (shouldUseSelectAllFilter) {
      // Add table select filter
      if (selectFilter) finalFilter.push(...selectFilter);
    } else {
      const ids = selectedLocations.map((r) => r._id);
      finalFilter.push({ field: "_id", value: ids, comparisonType: "in" });
    }

    return finalFilter;
  };

  const staticActions = useMemo(() => {
    const actions: TableActionLink[] = [
      {
        label: "Add location",
        className: "button-2",
        action: () => setAdding(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:locations:manage"),
      },
      {
        label: "Settings",
        className: "button-1 ",
        action: () => navigate("settings"),
        important: true,
        icon: <Gear weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:locations:manage"),
      },
    ];
    return actions;
  }, [can]);

  const handleModalOnFinish = () => {
    setAdding(false);
    setRefreshCount(refreshCount + 1);
  };

  const handleModalClose = () => {
    setAdding(false);
  };
  const dynamicActions = useMemo(() => {
    const actions = [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setDeleteModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:locations:manage"),
      },
    ];

    return actions;
  }, [selectedLocations, selectFilter]);

  const handleArchive = async () => {
    setDeleting(true);

    try {
      const params = buildBulkActionParams();

      const response = await MiterAPI.locations.archive({ filter: params });
      if (response.error) throw new Error(response.error);

      const { successes, errors } = response;
      if (errors.length) {
        setFailures(
          errors.map((f) => ({
            label: f._id,
            message: f.message,
          }))
        );
      } else {
        Notifier.success(`Deleted ${successes.length} ${pluralize("location", successes.length)}.`);
      }

      setSelectedLocations([]);
      setRefreshCount(refreshCount + 1);
    } catch (e) {
      Notifier.error("There was an error deleting one or more locations. We're looking into it!");
    } finally {
      setDeleting(false);
      setDeleteModal(false);
    }
  };

  return (
    <>
      {adding && <LocationModal onFinish={handleModalOnFinish} onHide={handleModalClose} />}
      {deleteModal && (
        <DeleteModal
          header={"Are you sure?"}
          body={`${
            shouldUseSelectAllFilter || selectedLocations.length > 1 ? "These locations" : "This location"
          } will be deleted.`}
          cancelText={"Cancel"}
          onHide={() => setDeleteModal(false)}
          deleteText={"Yes"}
          onDelete={handleArchive}
          loading={deleting}
        />
      )}
      <TableV2
        id="location-entries-table"
        resource="locations"
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onClick={(location) => navigate(`/settings/locations/${location._id}`)}
        columns={columns}
        getData={getData}
        ssr={true}
        refreshCount={refreshCount}
        editable={false}
        onSelect={handleOnSelect}
        onSelectFilter={setSelectFilter}
        hideSelectedCount={shouldUseSelectAllFilter}
      />
      {renderFailuresModal()}
    </>
  );
};
