import { UseFormMethods, FieldValues } from "react-hook-form";
import { Formblock } from "ui";
import { DeepPartial } from "utility-types";
import { Option } from "ui/form/Input";
import {
  AcumaticaConnectionMetadata,
  AcumaticaCostCodesFields,
} from "backend/services/acumatica/acumatica-types";
import { ACUMATICA_INPUT_LENGTH, DEFAULT_ACUMATICA_LABEL_STYLE } from "./AcumaticaConfig";

interface CostCodeFieldSelectionProps {
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => Promise<void>;
  costCodeField?: AcumaticaCostCodesFields;
  form: UseFormMethods<FieldValues>;
}

const costCodeFieldOptions: Option<AcumaticaCostCodesFields>[] = [
  { label: "Cost Code", value: "cost_code" },
  { label: "Project Tasks", value: "project_task" },
];

export const CostCodeFieldSelection: React.FC<CostCodeFieldSelectionProps> = ({
  updateAcumaticaMetadata,
  costCodeField,
  form,
}) => {
  return (
    <Formblock
      form={form}
      underlineTooltip={true}
      inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      name="activityField"
      type="select"
      labelInfo="The field in Acumatica to use as Activities in Miter."
      label="Activity Field"
      options={costCodeFieldOptions}
      defaultValue={costCodeField}
      onChange={(option: Option<AcumaticaCostCodesFields>) =>
        updateAcumaticaMetadata({ configObject: { activities: { costCodeField: option.value } } })
      }
      labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
      editing={true}
    />
  );
};
