import React, { useCallback, useEffect, useRef } from "react";
import { Badge, Formblock, Label, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { MiterAPI, TMTimeOffPolicy, UpdateTeamMemberParams } from "dashboard/miter";
import {
  useActiveCompanyId,
  useHolidayScheduleOptions,
  useLookupTimeOffPolicy,
  usePermissionGroupOptions,
  useRefetchTeam,
  useTimeOffPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { sleep } from "dashboard/utils";
import { Option } from "ui/form/Input";
import { WizardTeamMember } from "./TeamMemberWizard";
import { useTeamMemberPermissionGroups } from "dashboard/hooks/useTeamMemberPermissionGroups";
import { assignDefaultLevel, levelComputedStartDates } from "dashboard/utils/policies/time-off-policy-utils";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberPoliciesAndPermissionsForm = {
  time_off_policies?: Option<string>[];
  holiday_schedule_id?: Option<string> | null;
  permission_groups?: Option<string>[];
};

export const TeamMemberPoliciesAndPermissionsScreen: React.FC<Props> = ({
  name,
  teamMember,
  setTeamMember,
}) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const refetchTeam = useRefetchTeam();
  const isMounted = useRef(true);

  const timeOffPolicyOptions = useTimeOffPolicyOptions();
  const holidayScheduleOptions = useHolidayScheduleOptions();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const form = useForm<TeamMemberPoliciesAndPermissionsForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(teamMember, {
      timeOffPolicyOptions,
      holidayScheduleOptions,
    }),
  });

  const formData = useWatch<TeamMemberPoliciesAndPermissionsForm>({ control: form.control });

  /*********************************************************
   * Get's the permission groups for this team member
   **********************************************************/
  const teamMemberPermissionGroups = useTeamMemberPermissionGroups(teamMember?._id);

  const selectablePermissionGroupsPredicate = useCallback(
    (pg) => !teamMemberPermissionGroups?.find((tpg) => tpg._id === pg._id),
    [teamMemberPermissionGroups]
  );
  const selectablePermissionGroupOptions = usePermissionGroupOptions({
    predicate: selectablePermissionGroupsPredicate,
  });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildFinalTimeOffBalancesAndLevels = (
    selectedTimeoffIds: string[],
    startDate?: string | null
  ): TMTimeOffPolicy[] => {
    const originalTimeOffPolicies = teamMember?.time_off?.policies || [];
    return selectedTimeoffIds.map((policyId) => {
      const existingPolicy = originalTimeOffPolicies.find((policy) => policy.policy_id === policyId);
      if (existingPolicy) return existingPolicy;

      const newPolicy = lookupTimeOffPolicy(policyId);
      const levelId = assignDefaultLevel(startDate, levelComputedStartDates(newPolicy));
      const levelConfig = newPolicy?.levels.find((level) => level._id.toString() === levelId);
      const defaultBalance = levelConfig?.default_starting_balance || 0;

      return { policy_id: policyId, balance: defaultBalance, level_id: levelId };
    });
  };

  const buildParams = (params: TeamMemberPoliciesAndPermissionsForm) => {
    if (!activeCompanyId) throw new Error("No active company");

    const timeOffPolicyIds = (params?.time_off_policies || []).map((policy) => policy.value);
    const timeOffPolicies = buildFinalTimeOffBalancesAndLevels(timeOffPolicyIds, teamMember?.start_date);

    const data: UpdateTeamMemberParams = {
      time_off: { policies: timeOffPolicies },
      holiday_schedule_id: params.holiday_schedule_id?.value,
      permission_group_ids: (params.permission_groups || []).map((pg) => pg.value),

      resume_wizard_index: curIndex + 1,
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberPoliciesAndPermissionsForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");
      if (!activeCompanyId) throw new Error("No active company");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }

      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /** Renders the permission groups that this person is automatically a part of due to their attributes */
  const renderAutomaticPermissionGroups = () => {
    return (
      <>
        <Label
          className="modal wizard"
          label="Automatically added permission groups"
          labelInfo="These permission groups are automatically added based on the team member's attributes"
        />
        <div className={styles["automatically-added-permission-groups"]}>
          {teamMemberPermissionGroups?.map((pg) => (
            <Badge key={pg._id} className="modal wizard no-margin" text={pg.name} color="light-blue" />
          ))}
        </div>
      </>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Policies and permissions</h2>
          <p className={styles["subheader-description"]}>
            Set the team member&apos;s policies and permissions.
          </p>
        </div>
        <div className="form-section">
          <Formblock
            label="Time off policies"
            type="multiselect"
            name="time_off_policies"
            placeholder="Select time off policies"
            form={form}
            className="modal wizard"
            editing={true}
            options={timeOffPolicyOptions}
            height="unset"
          />
          <Formblock
            label="Holiday schedule"
            type="select"
            name="holiday_schedule_id"
            placeholder="Select a holiday schedule"
            form={form}
            className="modal wizard"
            editing={true}
            options={holidayScheduleOptions}
            defaultValue={teamMember?.holiday_schedule_id}
            isClearable={true}
          />
          {renderAutomaticPermissionGroups()}
          <Formblock
            type="multiselect"
            name="permission_groups"
            label="Additional permission groups"
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Select permission groups"}
            options={selectablePermissionGroupOptions}
            height={"unset"}
          />
        </div>
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  teamMember: WizardTeamMember | undefined,
  params: { timeOffPolicyOptions: Option<string>[]; holidayScheduleOptions: Option<string>[] }
): TeamMemberPoliciesAndPermissionsForm => {
  if (!teamMember) return {};

  const { timeOffPolicyOptions, holidayScheduleOptions } = params;
  const defaultTimeOffPolicyIds = teamMember?.time_off?.policies.map((policy) => policy.policy_id) || [];

  return {
    time_off_policies: timeOffPolicyOptions.filter((p) => defaultTimeOffPolicyIds.includes(p.value)),
    holiday_schedule_id: holidayScheduleOptions.find((hs) => hs.value === teamMember?.holiday_schedule_id),
  };
};
