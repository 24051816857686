import { FormTaskData } from "dashboard/types/onboarding-types";
import React, { useEffect } from "react";
import { Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { FormProvider, useForm } from "react-hook-form";
import { Props } from "./types";
import useWizard from "ui/modal/useWizard";
import { SinglePageFormWizardScreen } from "../forms/SinglePageFormWizardScreen";
import { cleanCustomFieldValueParams } from "miter-utils";
import { DateTime } from "luxon";
import ObjectID from "bson-objectid";
import { MiterAPI, CreateFormSubmissionParams, UpdateFormSubmissionParams } from "dashboard/miter";
import { saveFormsAndUpdateAnswers } from "../forms/utils";

type FormsWizardScreenProps = Props & {
  formToFill: FormTaskData;
};

export const FormsWizardScreen: React.FC<FormsWizardScreenProps> = ({ task, name, formToFill, assignee }) => {
  const form = useForm({
    mode: "all",
  });
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();

  const readonly = formToFill.submission?.status === "completed" && !formToFill.allow_editable_submissions;

  const {
    errors,
    handleSubmit,
    formState: { errors: formErrors },
    trigger,
  } = form;

  useEffect(() => {
    if (Object.keys(errors).length) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formErrors]);

  useEffect(() => {
    trigger();
  }, []);

  /** Clean the form data for saving it in the backend */
  const prepareFormSubmissionParams = (formData): CreateFormSubmissionParams | UpdateFormSubmissionParams => {
    const answers = Object.keys(formData)
      // Sort into the correct order based on index
      .sort((a, b) => {
        const fieldA = formToFill.components.findIndex((f) => f._id === a);
        const fieldB = formToFill.components.findIndex((f) => f._id === b);

        return fieldA - fieldB;
      })
      // use string regex to determine key is a valid mongo id
      .filter((key) => key.match(/^[0-9a-fA-F]{24}$/))
      .map((key) => ({
        _id: ObjectID().toHexString(),
        form_field_id: key,
        value: cleanCustomFieldValueParams(formData[key]),
      }));

    return {
      ...form.getValues(),
      company_id: formToFill.company_id,
      form_id: formToFill._id,
      ...(assignee.team_member
        ? { team_member_id: assignee.team_member._id }
        : { role_id: assignee.role?._id }),
      user_id: assignee.user._id,
      completed_at: DateTime.now().toSeconds(),
      status: "completed",
      answers,
    };
  };

  /** Save the form submission */
  const saveFormSubmission = async (data) => {
    try {
      const params = prepareFormSubmissionParams(data);

      const res = formToFill.submission
        ? await MiterAPI.form_submissions.update(
            formToFill.submission._id,
            params as UpdateFormSubmissionParams
          )
        : await MiterAPI.form_submissions.create(params as CreateFormSubmissionParams);

      if (res.error) throw new Error(res.error);

      const fullName = assignee.team_member?.full_name || assignee.role?.full_name || "Miter user";
      const title = assignee.team_member?.title || assignee?.role?.email || "Team member";
      // Save any esignature fields
      await saveFormsAndUpdateAnswers({
        formSubmissionId: res._id,
        params,
        formItem: formToFill,
        account: {
          company_id: formToFill.company_id,
          full_name: fullName,
          roleId: assignee?.role?._id,
          userId: assignee.user._id,
          accountType: "team_member",
          title,
        },

        deviceType: "desktop",
      });

      Notifier.success("Form submission saved");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error("Error saving form submission", e);

      // Re-throw the error so the wizard doesn't continue
      throw e;
    }
  };

  const onNext = async () => {
    handleSubmit(async (data) => {
      if (!readonly) {
        saveFormSubmission(data);
      }
      if (curIndex === screens.length - 1) {
        handleComplete();
      }
    })();
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>

        <FormProvider {...form}>
          <SinglePageFormWizardScreen
            formItem={formToFill}
            formSubmission={formToFill.submission}
            readonly={readonly}
          />
        </FormProvider>
      </div>
    </WizardScreen>
  );
};
