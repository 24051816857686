import AppContext from "dashboard/contexts/app-context";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

export const PerformanceSettings: React.FC = () => {
  const navigate = useNavigate();

  const ref = useRef<{ isMounted: boolean }>({ isMounted: false });
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();

  const { settings } = activeCompany!;
  const [performanceSettings, setPerformanceSettings] = useState(settings.performance);

  useEffect(() => {
    if (!ref.current?.isMounted) {
      ref.current.isMounted = true;
      return;
    }

    updateSettingsDebounced(performanceSettings);
  }, [performanceSettings]);

  const updateSettings = async (newSettings) => {
    try {
      if (!activeCompany?._id) throw new Error("No active company id");

      const response = await MiterAPI.companies.update(activeCompany._id, {
        $set: { "settings.performance": newSettings },
      });

      if (response.error) throw new Error(response.error);
      Notifier.success("Performance settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating performance settings. Our engineers are looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(updateSettings, 500);

  const handleChange = async (fieldName: string, newValue) => {
    const newSettings = { ...performanceSettings };
    newSettings[fieldName] = newValue;
    setPerformanceSettings(newSettings);
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Performance settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/activities")}>
          Back to performance
        </div>
        <div className="flex">
          <h1>Performance settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure performance related settings</div>
        <div className="vertical-spacer-small"></div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }}>Notifications</div>
          <div className="flex">
            <input
              type="checkbox"
              checked={performanceSettings?.send_weekly_upcoming_reviews_notification}
              onChange={(e) => handleChange("send_weekly_upcoming_reviews_notification", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>
              Send a weekly email to administrators of performance reviews that are due in the next 7 days.
            </span>
          </div>
          <div className="vertical-spacer-small" />
        </div>
      </div>
    </div>
  );
};
