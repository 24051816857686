import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BasicModal, Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { useNavigate } from "react-router-dom";
import { AggregatedJobApplication, Candidate, JobApplication, JobPosting } from "dashboard/types/ats";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { BulkUpdateResult, Form, MiterAPI } from "dashboard/miter";
import { ValueFormatterParams } from "ag-grid-community";
import { ArrowSquareRight, TrashSimple } from "phosphor-react";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useFormColumns } from "dashboard/hooks/table/useFormColumns";
import { FormAnswer, useQuery } from "miter-utils";
import { ESignatureAnswerModal } from "../forms/FormSubmissionAnswersTable";
import { useJobApplicationAbilities } from "dashboard/hooks/recruiting/useJobApplicationAbilities";
import { APPLICATION_STATUS_VALUE_LABEL, CANDIDATE_STATUSES } from "dashboard/utils/ats";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";

export const CURRENT_JOB_TITLE_QUESTION_ID = "651c4e8a5233bb79425332d5";
type Props = {
  jobPosting?: JobPosting;
  candidate?: Candidate;
  secondary?: boolean;
};

export const JobApplicationsTable: React.FC<Props> = ({ jobPosting, candidate, secondary }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const status = query.get("status");
  const [selectedRows, setSelectedRows] = useState<AggregatedJobApplication[]>([]);
  const activeCompanyId = useActiveCompanyId();
  const [deleteModal, setDeleteModal] = useState(false);
  const { can, cannot } = useMiterAbilities();
  const jobApplicationAbilities = useJobApplicationAbilities();
  const [form, setForm] = useState<Form>();
  const [data, setData] = useState<AggregatedJobApplication[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<FormAnswer>();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const { formColumns } = useFormColumns({
    form,
    setSelectedAnswer: setSelectedAnswer as React.Dispatch<React.SetStateAction<FormAnswer>>,
    pathToAnswers: "response_submission.answers",
  });

  const getForm = async () => {
    if (!jobPosting) return;
    try {
      const res = await MiterAPI.forms.retrieve(jobPosting.question_form_id);
      if (res.error) throw new Error(res.error);
      const { submissions, ...rest } = res;
      setForm(rest);
    } catch (e) {
      Notifier.error("Failed to get job application questions.");
    }
  };

  useEffect(() => {
    getForm();
  }, [jobPosting]);

  const getData = useCallback(async () => {
    const scopesFilter = jobApplicationAbilities.filter("read");

    const filter = [
      { field: "company_id", value: activeCompanyId },
      ...(jobPosting ? [{ field: "job_posting_id", value: jobPosting._id }] : []),
      ...(candidate ? [{ field: "candidate_id", value: candidate._id }] : []),
      ...(scopesFilter ? [scopesFilter] : []),
    ];
    const res = await MiterAPI.job_applications.forage({
      filter,
      select: undefined,
      limit: 1000,
      sort: [
        {
          field: "applied_on",
          direction: -1,
        },
      ],
    });

    setData(res.data);
  }, [activeCompanyId, jobPosting?._id, candidate?._id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const togglerConfig = useMemo(() => {
    return {
      config: [
        { path: "applied", label: "Applied" },
        { path: "in_review", label: "In review" },
        { path: "interviewing", label: "Interviewing" },
        { path: "offer", label: "Offer" },
        { path: "hired", label: "Hired" },
        { path: "rejected", label: "Rejected" },
      ],
      path: "applied",
      secondary: secondary,
      field: "status",
    };
  }, [jobApplicationAbilities]);

  const columns: ColumnConfig<AggregatedJobApplication>[] = useMemo(() => {
    // We already show this in the current job title column
    const castedFormColumns = (formColumns as ColumnConfig<JobApplication>[]).filter(
      (col) => col.field !== CURRENT_JOB_TITLE_QUESTION_ID
    );
    const statusOptions = CANDIDATE_STATUSES.filter(
      (applicationStatus) =>
        applicationStatus !== status &&
        !(applicationStatus === "hired" && jobApplicationAbilities.cannot("hire", undefined))
    ).map((status) => ({
      value: status,
      label: APPLICATION_STATUS_VALUE_LABEL[status],
    }));

    return [
      ...(!candidate
        ? [
            {
              headerName: "Candidate",
              valueFormatter: (params: ValueFormatterParams<AggregatedJobApplication>) => {
                const { first_name, last_name } = params?.data?.candidate || {
                  first_name: "Unknown",
                  last_name: "Candidate",
                };
                return `${first_name} ${last_name}`;
              },
              pinned: "left" as const,
            },
          ]
        : []),
      ...(!jobPosting
        ? [
            {
              headerName: "Job posting",
              field: "job_posting.title",
              pinned: "left" as const,
            },
          ]
        : []),
      ...(!candidate
        ? [
            {
              headerName: "Job title",
              field: "current_job_title",
              dataType: "string" as const,
            },
          ]
        : []),
      {
        headerName: "Applied on",
        field: "applied_on",
        dataType: "date" as const,
        dateType: "timestamp" as const,
      },

      {
        headerName: "Resume",
        dataType: "boolean" as const,
        valueGetter: (params) => !!params.data?.resume_id,
        width: 50,
      },
      {
        headerName: "Source",
        dataType: "string" as const,
        field: "source",
        displayType: "badge" as const,
        colors: {
          indeed: "light-blue",
          linkedin: "blue",
          careers_page: "light-purple",
          bulk_import: "gray",
        },
      },
      {
        headerName: "Status",
        dataType: "string" as const,
        field: "status",
        displayType: "badge",
        colors: {
          applied: "yellow",
          in_review: "orange",
          interview: "blue",
          offer: "light-green",
          hired: "green",
          rejected: "red",
        },
        cellEditorParams: {
          options: statusOptions,
        },
        editable: true,
        editorType: "select",
      },

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - TS doesn't like the fact that we're using a custom displayType
    ].concat(castedFormColumns) as ColumnConfig<AggregatedJobApplication>[];
  }, [formColumns, candidate, jobPosting, status]);

  const handleRowClick = (jobApplication) => {
    navigate(`/recruiting/job-applications/${jobApplication._id}${candidate ? `?path=candidate` : ""}`);
  };

  const dynamicActions = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 table-button",
        action: () => setDeleteModal(true),
        icon: <TrashSimple size={16} weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("recruiting:job_applications:delete"),
      },
    ],
    [setDeleteModal, can, status]
  );

  const refreshPage = () => {
    setDeleteModal(false);
    setSelectedRows([]);
    getData();
  };

  const updateJobs = async (
    applicationParamsArray: AggregatedJobApplication[]
  ): Promise<BulkUpdateResult> => {
    try {
      const params = applicationParamsArray.map((application) => ({
        _id: application._id,
        params: { status: application.status },
      }));
      const res = await MiterAPI.job_applications.update(params);
      if (res.errors.length) {
        setFailures(
          res.errors.map((error) => ({
            label: error.label || "Candidate",
            message: error.message,
          }))
        );
      }

      refreshPage();
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem updating the job applications.");
      return { successes: [], errors: [] };
    }
  };

  const archiveJobApplications = async () => {
    if (selectedRows.length === 0 || !activeCompanyId || cannot("recruiting:job_applications:delete")) return;
    try {
      const jobApplications = selectedRows.map((row) => row._id);
      const res = await MiterAPI.job_applications.archive({
        ids: jobApplications,
        company_id: activeCompanyId,
      });
      if (res.failures.length || res.error) throw new Error(res.error + res.failures.join(", "));
      Notifier.success("Successfully deleted job applications.");
    } catch (e) {
      Notifier.error("Failed to delete job applications.");
    }
    refreshPage();
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/

  return (
    <div>
      <TableV2
        id={"job-applications-table"}
        resource="job applications"
        columns={columns}
        dynamicActions={dynamicActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
        toggler={togglerConfig}
        data={data}
        editable={status !== "hired" && can("recruiting:job_applications:update")}
        onSave={updateJobs}
        editableLabel="Update status"
        editableIcon={<ArrowSquareRight size={18} style={{ marginRight: 3 }} />}
      />
      {deleteModal && (
        <BasicModal
          headerText={"Are you sure?"}
          bodyText={"Are you sure about permanently deleting the selected job applications?"}
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button1Action={() => setDeleteModal(false)}
          button2Action={archiveJobApplications}
          button2ClassName={"button-3"}
        />
      )}

      {selectedAnswer && (
        <ESignatureAnswerModal answer={selectedAnswer} onHide={() => setSelectedAnswer(undefined)} />
      )}
      {renderFailuresModal()}
    </div>
  );
};
