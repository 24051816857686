import { useCallback, useMemo } from "react";
import {
  useIsSuperAdmin,
  useLookupPaySchedule,
  useLookupTeam,
  useSessionPermissionGroups,
} from "./atom-hooks";
import { AggregatedPayroll, AggregatedTeamMember, PaySchedule, TeamMember } from "dashboard/miter";
import { TablePayroll } from "backend/utils/aggregations/payrollAggregations";

type PayScheduleAccessor = {
  canAccessPaySchedule: (paySchedule: PaySchedule | undefined) => boolean;
  canAccessTeamMember: (tm: string | AggregatedTeamMember | TeamMember | undefined | null) => boolean;
  canAccessPayroll: (payroll: TablePayroll | AggregatedPayroll) => boolean;
};

export const usePayScheduleAccessor = (): PayScheduleAccessor => {
  const isSuperAdmin = useIsSuperAdmin();
  const lookupPaySchedule = useLookupPaySchedule();
  const sessionPermissionGroups = useSessionPermissionGroups();
  const lookupTeamMember = useLookupTeam();

  const sessionPermissionGroupIds = useMemo(
    () => new Set<string>(sessionPermissionGroups.map((pg) => pg._id)),
    [sessionPermissionGroups]
  );

  const canAccessPaySchedule = useCallback(
    (paySchedule: PaySchedule | undefined): boolean => {
      if (!paySchedule) return false;

      // If the pay schedule doesn't have limited access, it is always accessible
      const hasLimitedAccess = paySchedule?.permission_groups_with_access;
      if (!hasLimitedAccess) return true;

      return paySchedule.permission_groups_with_access!.some((pgId) => sessionPermissionGroupIds.has(pgId));
    },
    [sessionPermissionGroupIds]
  );

  const canAccessTeamMember = useCallback(
    (tm: string | AggregatedTeamMember | TeamMember | undefined | null) => {
      if (isSuperAdmin) return true;
      if (!tm) return false;

      const teamMemberId = typeof tm === "string" ? tm : tm._id;
      const teamMember = lookupTeamMember(teamMemberId);
      if (!teamMember) return false;

      // If the team member isn't in a pay schedule, they are accessible (we need this so that team members without a pay schedule are accessible by default)
      if (!teamMember.pay_schedule_id) return true;

      // If the team member is in a pay schedule, check if the user can access it
      const paySchedule = lookupPaySchedule(teamMember.pay_schedule_id);
      return canAccessPaySchedule(paySchedule);
    },
    [canAccessPaySchedule, lookupTeamMember, isSuperAdmin]
  );

  const canAccessPayroll = useCallback(
    (payroll: TablePayroll | AggregatedPayroll) => {
      // If the user is a super admin, they can access all payrolls
      if (isSuperAdmin) return true;

      // If there is a pay schedule for the payroll, check if the user can access it
      const paySchedule = lookupPaySchedule(payroll.pay_schedule_id);
      if (paySchedule) return canAccessPaySchedule(paySchedule);

      // If there is no pay schedule, make sure that all team members on the payroll are accessible
      if ("adjustments" in payroll) {
        return payroll.adjustments.every((adj) => canAccessTeamMember(adj.team_member));
      } else {
        return payroll.team_member_ids.every((tmId) => canAccessTeamMember(tmId));
      }
    },
    [isSuperAdmin, lookupPaySchedule, canAccessPaySchedule, canAccessTeamMember]
  );

  return useMemo(
    () => ({ canAccessPaySchedule, canAccessTeamMember, canAccessPayroll }),
    [canAccessPaySchedule, canAccessTeamMember, canAccessPayroll]
  );
};
