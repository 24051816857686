import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconWithTooltip, Notifier, PageModal, TableV2 } from "ui";
import { PageModalActionLink } from "ui/modal/PageModal";
import { Copy, FloppyDisk, Plus, Trash } from "phosphor-react";
import {
  useActiveCompany,
  useActiveTeam,
  useActiveTeamMember,
  useDefaultExpenseReimbursementCategory,
  useIsSuperAdmin,
  useLookupActivity,
  useLookupCostType,
  useLookupExpenseReimbursementCategories,
} from "dashboard/hooks/atom-hooks";
import {
  cleanDraftReimbursementForCreation,
  useBulkExpenseReimbursementTableColDefs,
} from "dashboard/pages/expenses/expenseUtils";
import { ICellRendererParams } from "ag-grid-community";
import ObjectID from "bson-objectid";
import { FilePickerFile, FilePickerPreviewModal } from "ui/form/FilePicker";
import { MileageLocation, MiterAPI } from "dashboard/miter";
import { FileUploadParams, updateFiles } from "miter-utils";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { useExpenseReimbursementPolicies } from "dashboard/utils/policies/expense-reimbursement-policy-utils";
import { TableActionLink } from "ui/table-v2/Table";
import { StopBasedMileageEditorModal } from "./StopBasedMileageEditorModal";
import { isEqual } from "lodash";

export type DraftReimbursement = {
  _id: string;
  team_member_id?: string;
  department_id?: string | null;
  date: string;
  expense_reimbursement_category_id?: string | null;

  // out of pocket fields
  amount?: string; // string because input is unit
  vendor_name?: string | null;
  attachments?: FilePickerFile[];

  // mileage fields
  mileage?: number | null;
  trip?: MileageLocation[];

  // job costing
  job_id?: string | null;
  activity_id?: string | null;
  cost_type_id?: string | null;
  expense_account_id?: string | null;

  memo?: string;
};

type Props = {
  onHide: () => void;
};

export const BulkCreateExpenseReimbursementPageModal: React.FC<Props> = ({ onHide }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeTeamMember = useActiveTeamMember();
  const lookupCategory = useLookupExpenseReimbursementCategories();
  const lookupActivity = useLookupActivity();
  const lookupCostType = useLookupCostType();
  const activeCompany = useActiveCompany();
  const isSuperAdmin = useIsSuperAdmin();
  const { buildPolicy } = useExpenseReimbursementPolicies();
  const teamMembers = useActiveTeam();

  // initialize to pass into column defs
  const [reimbursements, setReimbursements] = useState<DraftReimbursement[]>([]);

  const uploadFilesCurried = useCallback(
    (rowId: string) => {
      return (files: FilePickerFile[]) => {
        const updatedReimbursements = reimbursements.map((reimbursement) => {
          if (reimbursement._id === rowId) {
            return { ...reimbursement, attachments: files };
          } else {
            return reimbursement;
          }
        });

        if (updatedReimbursements.length > 0) {
          setReimbursements(updatedReimbursements);
        }
      };
    },
    [reimbursements]
  );

  const columnDefs = useBulkExpenseReimbursementTableColDefs({
    reimbursements,
    uploadFilesCurried,
    openFilePreview: (attachments: FilePickerFile[]) => {
      setFilesToPreview(attachments);
    },
    openTripEditor: (rowId: string) => {
      setTripEditingRowId(rowId);
    },
  });

  columnDefs.push({
    headerName: " ",
    field: "actions",
    maxWidth: 75,
    pinned: "right",
    cellRenderer: (params: ICellRendererParams<DraftReimbursement>) => {
      return (
        <div className="flex">
          <IconWithTooltip
            PhosphorIcon={Copy}
            tooltip={`Make a copy of this reimbursement.`}
            style={{ marginRight: 10, cursor: "pointer" }} // TODO: fix opacity
            onClick={() => duplicateRows(params.data ? [params.data] : [])}
          />
          <IconWithTooltip
            PhosphorIcon={Trash}
            tooltip={`Delete this reimbursement.`}
            style={{ cursor: "pointer" }} // TODO: fix opacity
            onClick={() => deleteRows(params.data ? [params.data] : [])}
          />
        </div>
      );
    },
  });
  const defaultCategory = useDefaultExpenseReimbursementCategory();

  /*********************************************************
   *  State
   **********************************************************/
  const [loading, setLoading] = useState(false);

  // rows
  const [selectedRows, setSelectedRows] = useState<DraftReimbursement[]>([]);
  const [filesToPreview, setFilesToPreview] = useState<FilePickerFile[]>([]);
  const [tripEditingRowId, setTripEditingRowId] = useState<string>();

  const emptyDraftReimbursement = {
    _id: ObjectID().toString(),
    team_member_id: activeTeamMember?._id,
    department_id: activeTeamMember?.department_id,
    date: DateTime.now().toISODate(),
    expense_reimbursement_category_id: defaultCategory?._id,

    amount: defaultCategory?.amount?.value.toString(),
    vendor_name: defaultCategory?.merchant_name?.id,

    job_id: defaultCategory?.job?.id,
    activity_id: defaultCategory?.activity?.id,
    cost_type_id: defaultCategory?.cost_type?.id,
    expense_account_id: defaultCategory?.expense_account?.id,
  };

  useEffect(() => {
    if (!emptyDraftReimbursement) return;

    // set initial draft reimbursement when activeTeamMember initializes
    setReimbursements([emptyDraftReimbursement]);
  }, [activeTeamMember]);

  /*********************************************************
   *  Handler functions
   **********************************************************/

  // checks if each row fuflills all policy requirements
  const validateAllRows = (): { allValid: boolean; missingRequirements: string[] } => {
    const validatedRows: { meetsRequirements: boolean; missingRequirements?: string[] }[] =
      reimbursements.map((reimbursement) => {
        const { meetsPolicyRequirements, needsAttentionMessages } = buildPolicy(reimbursement);
        let rowMeetsRequirements = meetsPolicyRequirements();

        const selectedCategory = lookupCategory(reimbursement.expense_reimbursement_category_id);

        // must have team member
        if (!reimbursement.team_member_id) {
          needsAttentionMessages.push("Team Member is required");
          rowMeetsRequirements = false;
        }

        // if out of pocket, confirm amount and merchant are filled in
        if (!selectedCategory?.mileage_rate) {
          if (!reimbursement.amount) {
            needsAttentionMessages.push("Amount is required");
            rowMeetsRequirements = false;
          }

          // confirm amount is positive
          if (parseFloat(reimbursement.amount || "0") <= 0) {
            needsAttentionMessages.push("Amount must be positive");
            rowMeetsRequirements = false;
          }

          if (!reimbursement.vendor_name) {
            needsAttentionMessages.push("Merchant Name is required");
            rowMeetsRequirements = false;
          }
        } else {
          // if mileage, confirm mileage is filled in
          if (!reimbursement.mileage) {
            needsAttentionMessages.push("Mileage is required");
            rowMeetsRequirements = false;
          }
        }

        return {
          meetsRequirements: rowMeetsRequirements,
          missingRequirements: needsAttentionMessages,
        };
      });

    const allValid = validatedRows.every((row) => row.meetsRequirements);

    const missingRequirements = new Set<string>();
    validatedRows.forEach((row) => {
      if (row.meetsRequirements === false) {
        row.missingRequirements?.forEach((requirement) => {
          missingRequirements.add(requirement);
        });
      }
    });

    return {
      allValid,
      missingRequirements: Array.from(missingRequirements),
    };
  };

  // setting these up to support multiple rows - reuse for both single and bulk actions
  const duplicateRows = (rows: DraftReimbursement[]) => {
    const newRows = rows.map((row) => {
      const newRow = { ...row, _id: ObjectID().toString() };
      return newRow;
    });

    setReimbursements([...reimbursements, ...newRows]);
  };

  const deleteRows = (rows: DraftReimbursement[]) => {
    const rowIdsToDelete = rows.map((row) => row._id);
    const newRows = reimbursements.filter((reimbursement) => !rowIdsToDelete.includes(reimbursement._id));
    setReimbursements(newRows);
  };

  // adds new row to the table
  const handleAddDraftReimbursementRow = () => {
    if (emptyDraftReimbursement) {
      setReimbursements([...reimbursements, emptyDraftReimbursement]);
    }
  };

  const handleSubmit = async () => {
    if (!activeCompany) return;

    const rowPolicyValidation = validateAllRows();
    if (!rowPolicyValidation.allValid) {
      Notifier.error(
        `Some reimbursements do not meet policy requirements. Reasons: ${rowPolicyValidation.missingRequirements.join(
          ", "
        )}`
      );
      return;
    }

    setLoading(true);
    const creationResponse = await Promise.allSettled(
      reimbursements.map(async (reimbursement) => {
        const cleanData = cleanDraftReimbursementForCreation({
          draftReimbursement: reimbursement,
          activeCompany,
          mileageDetail: reimbursement.mileage
            ? {
                mileage: reimbursement.mileage,
                trip: reimbursement.trip,
              }
            : undefined,
          selectedCategory: lookupCategory(reimbursement.expense_reimbursement_category_id),
          statusToCreate:
            isSuperAdmin && !activeCompany?.settings.expense_reimbursements?.default_unapproved_from_dashboard
              ? "approved"
              : "unapproved",

          isCreation: true,
          creationMethod: "dashboard_bulk",
        });
        // @ts-expect-error _id is string, not ObjectId
        const res = await MiterAPI.expense_reimbursements.create(cleanData);
        if (res.error) {
          throw new Error(res.error);
        }

        const params: FileUploadParams = {
          parent_id: res.expense_reimbursement._id,
          parent_type: "expensereimbursement",
          company_id: activeCompany._id,
        };

        await updateFiles(reimbursement.attachments || null, params);
      })
    );
    setLoading(false);

    const numSuccessful = creationResponse.filter((res) => res.status === "fulfilled").length;
    const numFailed = creationResponse.length - numSuccessful;

    // if any failed
    if (numFailed > 0) {
      // some successful, some failed
      if (numSuccessful > 0) {
        Notifier.info(
          `Successfully created ${numSuccessful} ${pluralize(
            "reimbursement",
            numSuccessful
          )}, but ${numFailed} failed.`
        );
        onHide();
      } else {
        // all failed
        Notifier.error(`Failed to create ${numFailed} ${pluralize("reimbursement", numFailed)}.`);
      }
    } else {
      // all successful
      Notifier.success(`Successfully created expense ${pluralize("reimbursement", reimbursements.length)}.`);
      onHide();
    }
  };

  /*********************************************************
   *  Modal config variables
   **********************************************************/
  const modalActions: PageModalActionLink[] = useMemo(() => {
    const actions: PageModalActionLink[] = [];

    actions.push({
      label: "Cancel",
      action: onHide,
      position: "left",
      className: "button-1 no-margin",
    });

    actions.push({
      label: "Submit",
      action: handleSubmit,
      position: "right",
      icon: <FloppyDisk style={{ marginRight: 7 }} />,
      className: "button-2 no-margin",
      loading,
      disabled: reimbursements?.length === 0,
    });

    return actions;
  }, [onHide, handleSubmit, loading]);

  const dynamicActions: TableActionLink[] = [
    {
      label: "Delete",
      action: () => deleteRows(selectedRows),
      className: "button-3 table-button",
      showInEditMode: true,
      icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
    },
    {
      label: "Duplicate",
      action: () => duplicateRows(selectedRows),
      className: "button-1 table-button",
      showInEditMode: true,
      icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const staticActions: TableActionLink[] = [
    {
      label: "New reimbursement",
      action: handleAddDraftReimbursementRow,
      className: "button-2 table-button",
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
      important: true,
    },
  ];

  const handleRowUpdate = async (updatedRows: DraftReimbursement[]) => {
    const updatedReimbursements = reimbursements.map((existingReimbursement) => {
      const updatedReimbursement = updatedRows.find(
        (updatedRow) => updatedRow._id === existingReimbursement._id
      );

      // there's a bug with AGGrid where updatedRows also includes previous updates. check if a row is actually updated by comparing all values
      const isNotUpdated = !updatedReimbursement || isEqual(updatedReimbursement, existingReimbursement);

      if (isNotUpdated) return existingReimbursement;
      else {
        // if mileage is updated, also update the amount
        if (updatedReimbursement.mileage !== existingReimbursement.mileage) {
          const currentCategory = lookupCategory(updatedReimbursement.expense_reimbursement_category_id);

          // amount is type unit, not number - cast to string for this to work

          updatedReimbursement.amount = (
            (currentCategory?.mileage_rate || 0) * (updatedReimbursement.mileage || 0)
          ).toString();
        }

        // if category is updated, also update other fields
        if (
          updatedReimbursement.expense_reimbursement_category_id !==
          existingReimbursement.expense_reimbursement_category_id
        ) {
          const newCategory = lookupCategory(updatedReimbursement.expense_reimbursement_category_id);
          updatedReimbursement.amount = newCategory?.amount?.value.toString() ?? undefined;

          updatedReimbursement.vendor_name = newCategory?.merchant_name?.id ?? null;
          updatedReimbursement.job_id = newCategory?.job?.id ?? null;
          updatedReimbursement.activity_id = newCategory?.activity?.id ?? null;
          updatedReimbursement.cost_type_id = newCategory?.cost_type?.id ?? null;
          updatedReimbursement.expense_account_id = newCategory?.expense_account?.id ?? null;

          // if swapping to non-mileage category, clear mileage
          if (!newCategory?.mileage_rate) {
            updatedReimbursement.mileage = null;
          }
        }

        // if team member is updated, also update department
        if (updatedReimbursement.team_member_id !== existingReimbursement.team_member_id) {
          const newTeamMember = teamMembers.find(
            (teamMember) => teamMember._id === updatedReimbursement.team_member_id
          );
          updatedReimbursement.department_id = newTeamMember?.department_id;
        }

        // if activity is updated, also update cost type
        if (updatedReimbursement.activity_id !== existingReimbursement.activity_id) {
          const newActivity = lookupActivity(updatedReimbursement.activity_id);
          const existingActivity = lookupActivity(existingReimbursement.activity_id);

          if (newActivity?.default_cost_type_id) {
            updatedReimbursement.cost_type_id = newActivity.default_cost_type_id;
          } else if (
            existingReimbursement.cost_type_id &&
            existingReimbursement.cost_type_id === existingActivity?.default_cost_type_id
          ) {
            // if unsetting or changing activity and the current cost type is set by the activity, unset the cost type
            updatedReimbursement.cost_type_id = null;
          }
        }

        // if cost type is updated, also update expense account
        if (updatedReimbursement.cost_type_id !== existingReimbursement.cost_type_id) {
          const newCostType = lookupCostType(updatedReimbursement.cost_type_id);
          const existingCostType = lookupCostType(existingReimbursement.cost_type_id);

          if (newCostType?.ledger_account_id) {
            updatedReimbursement.expense_account_id = newCostType.ledger_account_id;
          } else if (
            existingReimbursement.expense_account_id &&
            existingReimbursement.expense_account_id === existingCostType?.ledger_account_id
          ) {
            // if unsetting or changing cost type and the current ledger account is set by the cost type, unset the ledger account

            updatedReimbursement.expense_account_id = null;
          }
        }

        return updatedReimbursement;
      }
    });

    setReimbursements(updatedReimbursements);
  };

  const handleSaveTrip = (trip: MileageLocation[], mileage: number) => {
    const updatedReimbursements = reimbursements.map((reimbursement) => {
      if (reimbursement._id === tripEditingRowId) {
        const newAmount =
          (lookupCategory(reimbursement.expense_reimbursement_category_id)?.mileage_rate || 0) * mileage;
        return { ...reimbursement, amount: newAmount.toString(), trip, mileage };
      } else {
        return reimbursement;
      }
    });
    setReimbursements(updatedReimbursements);
  };

  return (
    <PageModal
      header={`Bulk create reimbursements`}
      onClose={onHide}
      footerActions={modalActions}
      bodyContentStyle={{ maxWidth: "unset", height: "100%" }} // PageModal default is 800px maxWdith, unset to override
    >
      <TableV2
        id={"bulk-create-reimbursements"}
        resource="reimbursements"
        customEmptyStateMessage='Click "+ New reimbursement" to create a new draft.'
        data={reimbursements}
        columns={columnDefs}
        alwaysShowBulkEdit
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={false}
        onSelect={setSelectedRows}
        isLoading={loading}
        hideSearch
        disablePagination
        hideFilters
        alwaysEditable
        editable
        hideRowEditingStatus
        onSave={handleRowUpdate}
        autoSave
        gridWrapperStyle={{ height: "100%" }}
        containerStyle={{ height: "80%" }}
      />
      {filesToPreview.length > 0 && (
        <>
          <FilePickerPreviewModal
            allFiles={filesToPreview}
            selectedFile={filesToPreview[0]!}
            maxLabelLength={10}
            onHide={() => setFilesToPreview([])}
          />
        </>
      )}
      {tripEditingRowId && (
        <StopBasedMileageEditorModal
          trip={reimbursements.find((reimbursement) => reimbursement._id === tripEditingRowId)?.trip}
          onHide={() => setTripEditingRowId(undefined)}
          onSave={handleSaveTrip}
        />
      )}
    </PageModal>
  );
};
