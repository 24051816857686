/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggregatedJob, Job, MiterAPI } from "dashboard/miter";
import { base64toBlob } from "dashboard/utils";
import { useQuery } from "miter-utils";
import { Check, Download, Plus, TrashSimple, X } from "phosphor-react";
import Notifier from "dashboard/utils/notifier";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasicModal, LoadingModal } from "ui";
import { AggregatedDailyReport } from "dashboard/miter";
import { MiterFilterArray } from "backend/utils/utils";
import { saveAs } from "file-saver";
import DailyReportModal from "./DailyReportModal";
import { ForageRequest } from "backend/utils/forage/forage-types";
import { Assign } from "utility-types";
import { ColumnConfig, TableActionLink, TableTogglerConfig, TableV2 } from "ui/table-v2/Table";
import { TagBadges } from "../shared/TagBadge";
import { useActiveCompany, useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useDailyReportAbilities } from "dashboard/hooks/abilities-hooks/useDailyReportAbilities";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";

export type DailyReportTableRow = Assign<
  AggregatedDailyReport,
  { signature_status: "not_signed" | "partially_signed" | "signed" }
>;

type Props = {
  defaultFilters?: MiterFilterArray;
  job?: Job | AggregatedJob;
};

const DailyReportsTable: React.FC<Props> = ({ defaultFilters, job }) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const navigate = useNavigate();
  const query = useQuery();
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const miterAbilities = useMiterAbilities();
  const dailyReportAbilities = useDailyReportAbilities();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns({ ssr: true });

  const status = query.get("status");

  const enableMultiDayReports = !!activeCompany?.settings.daily_reports.enable_multi_day_reports;

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState<boolean>(false);

  // States related to the table
  const [selectedRows, setSelectedRows] = useState<DailyReportTableRow[]>([]);
  const [refreshCount, setRefreshCount] = useState(0);

  // States related to table actions
  const [archiving, setArchiving] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedDailyReportId, setSelectedDailyReportId] = useState<string | null>(null);

  /*********************************************************
   *  Helper functions
   **********************************************************/
  const getData = useCallback(
    async (query: ForageRequest) => {
      const filter = (query.filter || []).concat([{ field: "company", value: activeCompanyId }]);

      if (defaultFilters) filter.push(...defaultFilters);

      const abilitiesFilter = dailyReportAbilities.filter("read");
      if (abilitiesFilter) filter.push(abilitiesFilter);

      if (status && status !== "all") {
        filter.push({ field: "status", value: status });
      }

      const sort = query.sort || [];
      if (!query.sort?.find((s) => s.field === "start_date")) {
        sort.push({ field: "start_date", direction: -1 });
      }

      const select = query.select || [];
      if (!query.group?.length) {
        select.push({ field: "job_hierarchy_ids", show: true });
      }

      const res = await MiterAPI.daily_reports.forage({ ...query, filter, sort, select });

      const finalData = !query.group?.length ? res.data.map(buildTableRow) : res.data;

      return { ...res, data: finalData };
    },
    [JSON.stringify(defaultFilters), status, activeCompanyId, dailyReportAbilities.filter]
  );

  const refreshDailyReports = async () => {
    setRefreshCount(refreshCount + 1);
  };

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleRowClick = (dailyReport: DailyReportTableRow) => {
    setSelectedDailyReportId(dailyReport._id);
  };

  const handleAdd = () => {
    navigate("/daily-reports/new" + (job ? `?job=${job._id}` : ""));
  };

  const handleArchive = async () => {
    if (dailyReportAbilities.cannot("delete", selectedRows)) {
      Notifier.error("You do not have permission to delete one or more of these daily reports.");
      return;
    }

    setLoading(true);
    try {
      for (const dailyReport of selectedRows) {
        const response = await MiterAPI.daily_reports.delete(dailyReport._id);
        if (response.error) throw new Error(response.error);
      }

      const singPlur = selectedRows.length > 1 ? "Daily reports" : "Daily Report";
      Notifier.success(singPlur + " successfully deleted.");

      setArchiving(false);
      setSelectedRows([]);
      refreshDailyReports();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting one or more daily reports. We're looking into it.");
    }
    setLoading(false);
  };

  const handleStatusChange = async () => {
    if (dailyReportAbilities.cannot("approve", selectedRows)) {
      Notifier.error("You do not have permission to update one or more of these daily reports.");
      return;
    }

    setLoading(true);
    try {
      await Promise.all(
        selectedRows.map(async (dailyReport) => {
          const response =
            status === "approved"
              ? await MiterAPI.daily_reports.unapprove(dailyReport._id)
              : await MiterAPI.daily_reports.approve(dailyReport._id);

          if (response.error) throw new Error(response.error);
        })
      );

      const singPlur = selectedRows.length > 1 ? "Daily reports" : "Daily report";
      Notifier.success(singPlur + " successfully updated.");

      setArchiving(false);
      setSelectedRows([]);
      refreshDailyReports();
    } catch (e) {
      console.error(e);
      Notifier.error(
        "There was an error changing the status one or more daily reports. We're looking into it."
      );
    }
    setLoading(false);
  };

  const handleDailyReportUpdate = () => {
    refreshDailyReports();
  };

  const handleSelect = (rows: DailyReportTableRow[]) => {
    setSelectedRows(rows);
  };

  /*********************************************************
   *  Data fetching functions
   **********************************************************/
  const buildTableRow = (dailyReport: AggregatedDailyReport): DailyReportTableRow => {
    return {
      ...dailyReport,
      signature_status: dailyReport.signature_status?.replaceAll(
        "_",
        " "
      ) as DailyReportTableRow["signature_status"],
    };
  };

  const getDailyReportPDFs = async () => {
    if (!selectedRows.length) return;
    setDownloading(true);

    try {
      const res = await MiterAPI.daily_reports.retrieve_pdfs(
        selectedRows.map((r) => r._id),
        { timezone: DateTime.local().zoneName }
      );

      if (res.error) throw new Error(res.error);

      let mimeType = "";
      if (res.filename.endsWith(".pdf")) {
        mimeType = "application/pdf";
      } else if (res.filename.endsWith(".zip")) {
        mimeType = "application/zip";
      } else {
        throw new Error("Unknown file type");
      }

      const blobFile = base64toBlob(res.data, mimeType);
      saveAs(blobFile, res.filename);

      if (selectedRows.length === 1) {
        Notifier.success("Daily report successfully downloaded.");
      } else {
        Notifier.success(`${selectedRows.length} daily reports successfully downloaded.`);
      }
    } catch (e) {
      console.error("Error getting daily report PDFS", e);
      Notifier.error("There was an error getting the daily report PDFs. Please contact support.");
      setDownloading(false);
    }
    setDownloading(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const tableActions = useMemo((): {
    dynamicButtons: TableActionLink[];
    staticButtons: TableActionLink[];
  } => {
    let dynamicButtons: TableActionLink[] = [];
    let staticButtons: TableActionLink[] = [];

    const allUnapproved = selectedRows.every((row) => row.status === "unapproved");
    const allApproved = selectedRows.every((row) => row.status === "approved");

    if (allUnapproved) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Delete",
          className: "button-3 table-button",
          action: () => setArchiving(true),
          icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => dailyReportAbilities.can("delete", selectedRows),
        },
        {
          label: "Approve",
          className: "button-2 table-button",
          action: handleStatusChange,
          icon: <Check weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => dailyReportAbilities.can("approve", selectedRows),
        },
      ]);
    }

    if (allApproved) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Unapprove",
          className: "button-3 table-button",
          action: handleStatusChange,
          icon: <X weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => dailyReportAbilities.can("approve", selectedRows),
        },
      ]);
    }

    dynamicButtons = dynamicButtons.concat([
      {
        label: "Download",
        className: "button-1 table-button",
        action: () => getDailyReportPDFs(),
        icon: <Download weight="bold" style={{ marginRight: 3 }} />,
      },
    ]);

    staticButtons = staticButtons.concat([
      {
        label: "Add daily report",
        className: "button-2 no-margin",
        action: handleAdd,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        important: true,
        shouldShow: () =>
          miterAbilities.can("daily_reports:personal:create") ||
          miterAbilities.can("daily_reports:others:create"),
      },
    ]);

    return { dynamicButtons, staticButtons };
  }, [status, selectedRows, miterAbilities.can, dailyReportAbilities.can]);

  // Dynamically set columns based on whether any dailyReports have work classifications
  const columns: ColumnConfig<$TSFixMe>[] = useMemo(
    () =>
      [
        {
          headerName: enableMultiDayReports ? "Start date" : "Date",
          field: "start_date",
          dataType: "date",
          dateType: "iso",
          enableRowGroup: true,
        },
        ...(enableMultiDayReports
          ? [
              {
                headerName: enableMultiDayReports ? "End date" : "Date",
                field: "end_date",
                dataType: "date",
                dateType: "iso",
                enableRowGroup: true,
              },
            ]
          : []),
        {
          headerName: "ID",
          field: "identifier",
          dataType: "number",
          maxWidth: 80,
        },
        ...(!job
          ? [
              {
                headerName: "Job",
                field: "job.name",
                dataType: "string",
                enableRowGroup: true,
              },
            ]
          : []),
        {
          headerName: "Status",
          field: "status",
          dataType: "string",
          displayType: "badge",
        },
        {
          headerName: "Creator",
          field: "creator.full_name",
          dataType: "string",
          enableRowGroup: true,
        },
        {
          headerName: "Creator ID",
          field: "creator.friendly_id",
          dataType: "string",
          enableRowGroup: true,
        },
        {
          headerName: "Signed",
          field: "signature_status",
          dataType: "string",
          displayType: "badge",
          colors: {
            "not signed": "red",
            "partially signed": "yellow",
            signed: "green",
          },
        },
        {
          field: "tags",
          filterField: "tags.label",
          headerName: "Tags",
          dataType: "string",
          valueGetter: ({ data }) => data?.tags?.map((tag) => tag.label).join(", ") || "-",
          cellRenderer: ({ data }) => <TagBadges tags={data.tags} />,
          minWidth: 200,
        },
        {
          headerName: "Has photos",
          field: "has_photos",
          dataType: "boolean",
          hide: true,
        },
        ...jobHierarchyTableColumns,
      ] as ColumnConfig<$TSFixMe>[],
    [job, status, enableMultiDayReports, jobHierarchyTableColumns]
  );

  /*********************************************************
    Toggler configs
  **********************************************************/
  const togglerConfig: TableTogglerConfig<DailyReportTableRow> = {
    config: [
      { path: "unapproved", label: "Unapproved" },
      { path: "approved", label: "Approved" },
      { path: "all", label: "All" },
    ],
    field: "status",
    secondary: !!job,
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    if (!activeUser) return;

    return (
      <TableV2
        id="daily-reports-table"
        resource="daily reports"
        columns={columns}
        onSelect={handleSelect}
        staticActions={tableActions.staticButtons}
        dynamicActions={tableActions.dynamicButtons}
        showReportViews={true}
        onClick={handleRowClick}
        defaultSelectedRows={selectedRows}
        ssr={true}
        getData={getData}
        refreshCount={refreshCount}
        toggler={togglerConfig}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
      />
    );
  };

  return (
    <div className="daily-reports-table-wrapper">
      <div>
        {archiving && (
          <BasicModal
            loading={loading}
            button2Text="Delete"
            button1Action={() => setArchiving(false)}
            button1Text="Cancel"
            button2Action={handleArchive}
            headerText={"Delete daily report" + (selectedRows.length > 1 ? "s" : "")}
            bodyText={
              "Are you sure you want to delete the selected daily report" +
              (selectedRows.length > 1 ? "s" : "") +
              "? To restore a deleted daily report, you must contact Miter Support."
            }
          />
        )}
        {downloading && <LoadingModal text="Building reports" />}
        {renderTable()}
        {selectedDailyReportId && (
          <DailyReportModal
            dailyReportId={selectedDailyReportId}
            onHide={() => setSelectedDailyReportId(null)}
            onChange={handleDailyReportUpdate}
          />
        )}
      </div>
    </div>
  );
};

export default DailyReportsTable;
