import React, { useEffect, useMemo, useState } from "react";
import Notifier from "dashboard/utils/notifier";
import { TeamMember, MiterAPI, AggregatedTimeOffRequest } from "dashboard/miter";
import {
  TimeOffRequestsTable,
  RequestTableEntry,
  cleanTimeOffRequests,
} from "../../time-off/TimeOffRequestsTable";
import PaymentContext from "./PaymentModal/paymentContext";
import PayrollContext from "./payrollContext";
import { ColumnConfig } from "ui/table-v2/Table";
import { ValueGetterParams } from "ag-grid-community";

type Props = {
  viewOnly?: boolean;
  tm?: TeamMember;
  emptyHeader?: string;
};

const ReviewTimeOff: React.FC<Props> = ({ viewOnly, tm, emptyHeader }) => {
  const payrollContext = React.useContext(PayrollContext);
  const paymentContext = React.useContext(PaymentContext);
  const { payroll } = tm ? paymentContext : payrollContext;

  const [allTimeOffRequests, setAllTimeOffRequests] = useState<AggregatedTimeOffRequest[]>();

  const timeOffRequests = useMemo(() => {
    if (!allTimeOffRequests) return;
    return cleanTimeOffRequests(allTimeOffRequests, tm, payroll?.check_payroll);
  }, [allTimeOffRequests, tm?._id]);

  const getTimeOffRequests = async () => {
    if (!payroll?.pay_schedule_id) return;
    try {
      const response = await MiterAPI.time_off.requests.retrieve_for_pay_period({ payrollId: payroll._id });
      if (response.error) throw new Error(response.error);
      setAllTimeOffRequests(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving time off requests. We're looking into it!");
    }
  };

  const paidValueGetter = (params: ValueGetterParams<RequestTableEntry>) => {
    const employee = params.data?.employee;
    const policy = params.data?.time_off_policy;
    const levelId = employee?.time_off?.policies.find((p) => p.policy_id === policy?._id)?.level_id;
    const levelConfig = policy?.levels.find((level) => level._id === levelId);
    return !levelConfig?.unpaid;
  };

  const onDataChange = async (tmIds?: string[]) => {
    await payrollContext.recalculatePayroll({ tms: tmIds });
  };

  const tableColumns: ColumnConfig<RequestTableEntry>[] = [
    {
      field: "employee.full_name",
      headerName: "Employee",
      dataType: "string",
    },
    {
      field: "status",
      headerName: "Status",
      dataType: "string",
      displayType: "badge",
      colors: {
        unapproved: "yellow",
        approved: "blue",
        processing: "light-green",
        denied: "red",
        paid: "green",
      },
    },
    {
      field: "time_off_policy.name",
      headerName: "Policy",
      dataType: "string",
    },
    {
      field: "time_off_policy.unpaid",
      headerName: "Paid policy",
      dataType: "boolean",
      valueGetter: (params) => {
        return paidValueGetter(params);
      },
    },
    ...(tm
      ? []
      : [
          {
            field: "dates",
            headerName: "Dates",
            dataType: "date" as const,
            isDateRange: true,
            minWidth: 200,
          },
        ]),
    {
      field: "total_hours",
      headerName: "Total hours",
      dataType: "number",
      valueFormatter: (params) => (params.value != null ? `${params.value} hours` : ""),
      sumRow: true,
    },
    {
      field: "payroll_hours",
      headerName: "Hours applying to this payroll",
      dataType: "number",
      tooltipValueGetter: (params) => {
        if (params.data?.status === "unapproved" && !!params.data?.payroll_hours) {
          return "Unapproved time off requests won't get processed in this payroll.";
        }
      },
      sumRow: true,
    },
  ];

  useEffect(() => {
    getTimeOffRequests();
  }, [payroll?._id]);

  return (
    <TimeOffRequestsTable
      showToggler={false}
      alternateColumns={tableColumns}
      viewOnly={viewOnly}
      providedTimeOffRequests={timeOffRequests}
      onRefreshTimeOffRequests={getTimeOffRequests}
      onDataChange={onDataChange}
      employee={tm}
      emptyHeader={emptyHeader}
    />
  );
};

export default ReviewTimeOff;
