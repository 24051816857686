/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AggregatedForm,
  ESignatureItem,
  Form,
  FormSubmission,
  FrontendModel,
  MiterAPI,
} from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { renderCustomFieldDefaultString } from "miter-utils";
import { FileArrowDown, Pencil } from "phosphor-react";
import React, { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { FormAnswer as FormAnswer_ } from "backend/models/form";
import { ESignatureModal } from "../esignatures/ESignatureModal";
import { BasicModal, Button, LoadingModal } from "ui";
import { base64toBlob } from "dashboard/utils";
import { saveAs } from "file-saver";
import { useFormSubmissionAbilities } from "dashboard/hooks/abilities-hooks/useFormSubmissionAbilities";
import { FormComponent } from "./FormBuilderScreen";
import { FilesModal } from "../files/FilesModal";

type FormAnswer = FrontendModel<FormAnswer_>;

export type FormSubmissionAnswerTableRow = {};

type Props = {
  form: AggregatedForm | Form;
  formSubmission: FormSubmission;
  resource?: string;
};

const FormSubmissionAnswerTable: React.FC<Props> = ({ form, formSubmission, resource }) => {
  const navigate = useNavigate();
  const submissionAbilities = useFormSubmissionAbilities();
  const [selectedRow, setSelectedRow] = useState<FormSubmissionAnswerTableRow>();
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  const handleEdit = (formSubmission) => {
    if (!form.allow_editable_submissions) {
      Notifier.error("This form does not allow editing of submissions.");
      return;
    }

    if (submissionAbilities.cannot("update", formSubmission)) {
      Notifier.error("You do not have permission to edit this submission.");
      return;
    }

    navigate("/forms/" + form._id + "/submissions/" + formSubmission._id);
  };

  const downloadPDF = async (formSubmission) => {
    setDownloadingPDF(true);
    try {
      const res = await MiterAPI.form_submissions.download_pdf(formSubmission._id);
      if (res.error) {
        throw new Error(res.error);
      }
      const blobFile = base64toBlob(res.data, "application/pdf");
      saveAs(blobFile, res.filename);
    } catch (e: $TSFixMe) {
      Notifier.error("Failed to download PDF, please try again another time.");
      console.error(e);
    }
    setDownloadingPDF(false);
  };
  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Edit submission",
        className: "button-2 no-margin",
        action: () => handleEdit(formSubmission),
        important: true,
        icon: <Pencil weight="bold" style={{ marginRight: 7 }} />,
        shouldShow: () => submissionAbilities.can("update", formSubmission),
      },
      {
        label: "Download PDF",
        className: "button-1 no-margin",
        action: () => downloadPDF(formSubmission),
        important: false,
        icon: <FileArrowDown weight="bold" style={{ marginRight: 7 }} />,
        style: { marginLeft: 10 },
      },
    ],
    [submissionAbilities.can, formSubmission]
  );

  const columns: ColumnConfig<FormAnswer>[] = [
    {
      headerName: "Question",
      field: "form_field_id",
      valueGetter: (params) => {
        const formField = form.components.find((field) => field._id === params.data?.form_field_id);
        return formField?.name || "-";
      },
    },
    {
      headerName: "Answer",
      field: "value",
      cellRenderer: (params) => {
        const formField = form.components.find((field) => field._id === params.data?.form_field_id);
        if (!formField) return "-";

        if (formField.type === "esignature" && params.data?.value?.signature) {
          return (
            <Button className="button-1 no-margin" onClick={() => setSelectedRow(params.data)}>
              View
            </Button>
          );
        }

        if (formField.type === "file" && Array.isArray(params.data?.value)) {
          return (
            <div>
              {params.data?.value.length} file{`${params.data?.value.length > 1 ? "s" : ""}`} uploaded
            </div>
          );
        }

        if (formField.type === "photo" && Array.isArray(params.data?.value)) {
          return (
            <div>
              {params.data?.value.length} photo{`${params.data?.value.length > 1 ? "s" : ""}`} uploaded
            </div>
          );
        }

        return renderCustomFieldDefaultString(formField as $TSFixMe, params.data);
      },
      valueGetter: (params) => {
        const formField = form.components.find((field) => field._id === params.data?.form_field_id);
        if (!formField) return "-";

        return renderCustomFieldDefaultString(formField as $TSFixMe, params.data);
      },
    },
  ];

  const activeQuestion = useMemo(() => {
    if (!selectedRow) return undefined;
    return form.components.find((field) => field._id === (selectedRow as FormAnswer)?.form_field_id);
  }, [selectedRow, form]);

  /*********************************************************
    Functions to render table components
  **********************************************************/

  return (
    <div className="form-table-wrapper">
      <TableV2
        id={"form-submission-answers-table"}
        resource={resource || "form answers"}
        data={formSubmission.answers}
        columns={columns}
        staticActions={form.allow_editable_submissions ? staticActions : []}
        onClick={setSelectedRow}
      />
      {downloadingPDF && <LoadingModal text="Building PDF" />}
      {selectedRow && activeQuestion && (
        <GenericAnswerModal
          answer={selectedRow as FormAnswer}
          question={activeQuestion}
          onHide={() => setSelectedRow(undefined)}
        />
      )}
    </div>
  );
};

export const ESignatureAnswerModal: FC<{ answer: FormAnswer; onHide: () => void }> = ({ answer, onHide }) => {
  const isESignatureAnswer =
    answer && answer.value && typeof answer.value === "object" && "signature" in answer.value;

  return (
    <>
      {isESignatureAnswer && (
        <ESignatureModal esignatureItem={answer.value as ESignatureItem} onHide={onHide} />
      )}
    </>
  );
};

export const GenericAnswerModal: FC<{ question: FormComponent; answer: FormAnswer; onHide: () => void }> = ({
  question,
  answer,
  onHide,
}) => {
  const isESignatureAnswer =
    answer && answer.value && typeof answer.value === "object" && "signature" in answer.value;

  const isFileAnswerModal = answer && answer.value && Array.isArray(answer.value);

  if (isESignatureAnswer) {
    return (
      <>
        {isESignatureAnswer && (
          <ESignatureModal esignatureItem={answer.value as ESignatureItem} onHide={onHide} />
        )}
      </>
    );
  }

  if (isFileAnswerModal) {
    return <>{isFileAnswerModal && <FilesModal fileIds={answer.value as string[]} onHide={onHide} />}</>;
  }
  return (
    <BasicModal
      headerText={"Form response"}
      button2Text={"Close"}
      button2Action={onHide}
      wrapperStyle={{
        width: 600,
      }}
    >
      <b>Question</b>
      <div style={{ marginBottom: 20, marginTop: 5 }}>{question.name}</div>
      {question.description && (
        <div>
          <b>Description</b>
          <div style={{ marginBottom: 20, marginTop: 5 }}>{question.description}</div>
        </div>
      )}
      <div>
        <b>Answer</b>
        <div style={{ marginTop: 5 }}>{renderCustomFieldDefaultString(question as $TSFixMe, answer)}</div>
      </div>
    </BasicModal>
  );
};

export default FormSubmissionAnswerTable;
