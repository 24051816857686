import { useCallback, useMemo } from "react";
import { AggregatedTeamMember, TeamMember } from "dashboard/miter";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TableReadyTm } from "dashboard/pages/team-members/TeamUtils";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type TeamMemberAbilitiesOpts = { inboxMode?: InboxMode };

type TeamMemberParams =
  | AggregatedTeamMember
  | AggregatedTeamMember[]
  | TeamMember
  | TeamMember[]
  | TableReadyTm
  | TableReadyTm[]
  | undefined;

export type TeamMemberAction =
  | "create"
  | "read"
  | "update"
  | "terminate"
  | "delete"
  | "read_sensitive"
  | "update_sensitive"
  | "delete_sensitive"
  | "request_change";

type TeamMemberAbilities = {
  can: (action: TeamMemberAction, items: TeamMemberParams) => boolean;
  cannot: (action: TeamMemberAction, items: TeamMemberParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: TeamMemberAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useTeamAbilities = (opts?: TeamMemberAbilitiesOpts): TeamMemberAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: TeamMemberAction, items: TeamMemberParams) => {
      if (!items) return false;
      const teamMembers = Array.isArray(items) ? items : [items];

      return teamMembers.every((teamMember) => {
        const opts = { teamMember: teamMember._id };

        switch (action) {
          case "create":
            return can_("team:create");
          case "read":
            return can_("team:read", opts);
          case "update":
            return can_("team:update", opts);
          case "terminate":
            return can_("team:terminate", opts);
          case "delete":
            return can_("team:delete", opts);
          case "read_sensitive":
            return can_("team:read_sensitive", opts);
          case "update_sensitive":
            return can_("team:update_sensitive", opts);
          case "request_change":
            return can_("team:request_change", opts);
          default:
            return false;
        }
      });
    },
    [activeTeamMember, opts?.inboxMode, can_]
  );

  const cannot = useCallback(
    (action: TeamMemberAction, items: TeamMemberParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "team",
    teamMemberField: { fieldName: "_id", fieldType: "_id" },
    appModule: "human_resources",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<TeamMemberAction>("team", true);

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, filter, teamPredicate]);
};
