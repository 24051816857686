import { useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";
import { AggregatedPerformanceReviewCycle, MiterAPI } from "dashboard/miter";
import {
  cleanReviewCyclesFromBackend,
  formatCadence,
  PerformanceReviewCycleTableEntry,
} from "dashboard/utils/performance";
import { Plus, TrashSimple } from "phosphor-react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { BasicModal, Notifier, TableV2 } from "ui";
import { ForageRequest } from "backend/utils/forage/forage-types";
import { useNavigate } from "react-router-dom";
import PerformanceReviewCycleWizard from "dashboard/components/performance/PerformanceReviewCycleWizard";
import { DateTime } from "luxon";
import { ColumnConfig } from "ui/table-v2/Table";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

export const PerformanceReviewCyclesTable: FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const { can, cannot } = useMiterAbilities();

  const [selectedRows, setSelectedRows] = useState<PerformanceReviewCycleTableEntry[]>([]);
  const [performanceWizard, setPerformanceWizard] = useState<AggregatedPerformanceReviewCycle | null>(null);
  const [confirmModal, setConfirmModal] = useState<null | "delete">(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const navigate = useNavigate();

  // Archive a review cycle if user exits during initial setup
  const onExit = async () => {
    if (performanceWizard && activeCompanyId) {
      await MiterAPI.performance_review_cycles.archive({
        ids: [performanceWizard._id],
        company_id: activeCompanyId,
      });
    }
    setPerformanceWizard(null);
  };

  const onComplete = () => {
    setPerformanceWizard(null);
    refreshReviewCycles();
  };

  const refreshReviewCycles = async () => {
    setRefreshCount((prev) => prev + 1);
  };

  const handleSelect = (selections: $TSFixMe[]) => {
    setSelectedRows(selections);
  };

  const createReviewCycle = async () => {
    if (cannot("performance:schedules:create")) {
      Notifier.error("You do not have permission to create a review schedule.");
      return;
    }

    try {
      if (!activeUser) throw new Error("No active user");

      const res = await MiterAPI.performance_review_cycles.create({
        company_id: activeCompanyId!,
        name: "New review schedule",
        team_member_ids: [],
        review_period: "2_weeks",
        automated_reminders_enabled: false,
        next_start_date: DateTime.now().toISODate(),
        cadence: "quarterly",
        last_updated_by: activeUser._id,
        request_style: "start_of_cycle",
        days_offset_request: 0,
      });
      if (res.error) throw new Error(res.error);
      setPerformanceWizard(res);
    } catch (e) {
      Notifier.error("Failed to create a review schedule.");
      console.error(e);
    }
  };

  const archiveReviewCycles = async () => {
    if (selectedRows.length === 0 || !activeCompanyId || cannot("performance:schedules:delete")) return;
    try {
      const reviewCycleIds = selectedRows.map((row) => row._id);
      const res = await MiterAPI.performance_review_cycles.archive({
        ids: reviewCycleIds,
        company_id: activeCompanyId,
      });
      if (res.failures.length || res.error) throw new Error(res.error + res.failures.join(", "));
      Notifier.success("Successfully deleted review schedules.");
    } catch (e) {
      console.error(e);
      Notifier.error("Failed to delete review schedule(s)");
    }
    setConfirmModal(null);
    setSelectedRows([]);
    refreshReviewCycles();
  };

  const staticActions = useMemo(
    () => [
      {
        label: "Create review schedule",
        className: "button-2 no-margin",
        action: () => createReviewCycle(),
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        important: true,
        shouldShow: () => can("performance:schedules:create"),
      },
    ],
    [can]
  );

  const dynamicActions = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 table-button",
        action: () => setConfirmModal("delete"),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("performance:schedules:delete"),
      },
    ],
    [setConfirmModal, can]
  );

  const reviewCycleColumns: ColumnConfig<PerformanceReviewCycleTableEntry>[] = [
    {
      field: "name",
      headerName: "Name",
      dataType: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 550,
      dataType: "string",
    },
    {
      field: "reviewees",
      headerName: "Reviewees",
      dataType: "number" as const,
      valueFormatter: (params: $TSFixMe) => params.value,
    },

    {
      field: "cadence",
      headerName: "Cadence",
      displayType: "badge" as const,
      valueFormatter: formatCadence,
      colors: {
        monthly: "orange",
        quarterly: "green",
        semi_annually: "blue",
        annually: "light-purple",
        no_cycle: "gray",
      },
    },

    {
      field: "next_start_date",
      headerName: "Next cycle start",
      dataType: "date",
      dateType: "iso",
    },
  ];

  const getData = useCallback(
    async (query: ForageRequest) => {
      const filter = [
        { field: "company_id", value: activeCompanyId },
        { field: "archived", value: false },
      ];
      const res = await MiterAPI.performance_review_cycles.forage({ ...query, filter, select: undefined });
      const finalData = cleanReviewCyclesFromBackend(res.data);
      return { ...res, data: finalData };
    },
    [activeUser]
  );

  const handleRowClick = (row: PerformanceReviewCycleTableEntry) => {
    navigate("/performance/review-schedules/" + row._id);
  };

  return (
    <div>
      <TableV2
        id="review-cycles-table"
        title="Review schedules"
        resource="review schedules"
        columns={reviewCycleColumns}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onSelect={handleSelect}
        ssr={true}
        getData={getData}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        refreshCount={refreshCount}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
      />
      {performanceWizard && (
        <PerformanceReviewCycleWizard
          performance_review_cycle={performanceWizard}
          self_review_form={performanceWizard.self_review_form}
          direct_report_review_form={performanceWizard.direct_report_review_form}
          onExit={onExit}
          onComplete={onComplete}
        />
      )}
      {confirmModal && (
        <BasicModal
          headerText={"Are you sure?"}
          bodyText={
            "Deleting a review schedule will permanently delete its settings, evaluation forms, and historical performance reviews. If you still want access to this data, do not delete this schedule."
          }
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button1Action={() => setConfirmModal(null)}
          button2Action={archiveReviewCycles}
          button2ClassName={"button-3"}
        />
      )}
    </div>
  );
};
