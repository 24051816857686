import { useLookupLocation, useRefetchLocations } from "dashboard/hooks/atom-hooks";
import { Notifier } from "dashboard/utils";
import { capitalize, truncate } from "lodash";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Badge } from "ui/badge";
import { Toggler } from "ui/toggler";
import Banner from "../shared/Banner";
import { LocationDetails } from "./LocationDetails";
import { useLocationSourceSystem } from "./locationsUtils";

type Props = {};

export const Location: FC<Props> = () => {
  const { id, view } = useParams<{ id: string; view: string }>();
  const navigate = useNavigate();
  const lookupLocation = useLookupLocation();
  const refetchLocations = useRefetchLocations();
  const location = lookupLocation(id);
  const sourceSystem = useLocationSourceSystem(location);

  const [activeView, setActiveView] = useState(view ? view : "details");

  const handleToggle = (option: string) => {
    setActiveView(option);
    navigate("/settings/locations/" + id + "/" + option);
  };

  useEffect(() => {
    if (!id) Notifier.error("No ID given for location");
    if (!view) {
      navigate("/settings/locations/" + id + "/details", { replace: true });
    }
  }, [id, view]);

  useEffect(() => {
    refetchLocations(id);
  }, []);

  const getTitleTag = () => {
    const title = location ? location.name : "";
    return title + " " + `${capitalize(activeView)} | Miter`;
  };

  if (!id) return <Navigate to="/settings/locations" replace />;
  if (!location) return <></>;

  // Toggler details
  const togglerConfig = [{ path: "details", label: "Details" }];

  return (
    <div>
      <Helmet>
        <title>{getTitleTag()}</title>
      </Helmet>
      {location && (
        <div className="page-content">
          <div className="flex">
            <h1>{truncate(location.name, { length: 50 })}</h1>
            <Badge text={location.status} />
            <div style={{ width: "10px" }}></div>
          </div>
          {sourceSystem && (
            <>
              <div className="vertical-spacer-small"></div>
              <Banner content="">
                <span className="flex">
                  Note: This location was created from a job in&nbsp;
                  <span onClick={() => navigate("/integrations/" + sourceSystem.key)} className="blue-link">
                    {sourceSystem.label}
                  </span>
                  .
                </span>
              </Banner>
            </>
          )}
          <Toggler config={togglerConfig} toggle={handleToggle} active={view}></Toggler>
          <div className="vertical-spacer"></div>
          <div>{view === "details" && <LocationDetails location={location} />}</div>
        </div>
      )}
    </div>
  );
};
