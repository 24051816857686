import { useState, useEffect } from "react";
import { Notifier } from "ui";
import { QBOClass, QBOItem } from "backend/services/qbo/qbo-types";
import { QBDClass, QBDServiceItem } from "backend/services/qbd/qbd-types";
import { ActivityMappingOptionsResponse } from "backend/services/quickbooks/quickbooks-types";
import { Option } from "ui/form/Input";
import { Activity, AggregatedJob, MiterAPI } from "dashboard/miter";
import { ActivityTableEntry } from "dashboard/components/tables/ActivitiesTable";
import { DeepPartial } from "utility-types";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";

export type UseClassAndItemMappingOptions = {
  classOptions: Option<string>[] | undefined;
  serviceItemOptions: Option<string>[] | undefined;
  connectionError: boolean;
  getQbClassIdForActivity: (
    activity: ActivityTableEntry,
    connectedSystem: "qbd" | "qbo"
  ) => string | undefined;
  getQbItemIdForActivity: (
    activity: ActivityTableEntry,
    connectedSystem: "qbd" | "qbo"
  ) => string | undefined;
  getQbClassIdForJob: (job: AggregatedJob, connectedSystem: "qbd" | "qbo") => string | undefined;
  getQbItemIdForJob: (job: AggregatedJob, connectedSystem: "qbd" | "qbo") => string | undefined;
  handleItemChange: (option: Option<string> | null) => void;
  handleClassChange: (option: Option<string> | null) => void;
  connectedSystem: "qbd" | "qbo" | undefined;
  loading: boolean;
};

export type QbClass = QBOClass | QBDClass;
export type QbItem = QBOItem | QBDServiceItem;

export const useClassAndItemMappingOptions = (
  connectionKeys: string[],
  handleFieldsChange: <T>(update: Partial<T>) => void,
  activeCompanyId: string | null,
  includedObjects?: ("classes" | "service_items")[]
): UseClassAndItemMappingOptions => {
  const [loading, setLoading] = useState(false);

  let connectedSystem: "qbo" | "qbd" | undefined;
  if (connectionKeys.includes("qbo")) {
    connectedSystem = "qbo";
  } else if (connectionKeys.includes("qbd")) {
    connectedSystem = "qbd";
  } else {
    Notifier.error("There was an error retrieving QB mapping options");
  }

  // Initialize object options and default values
  const [qbClasses, setQbClasses] = useState<QbClass[]>([]);
  const [qbItems, setQbItems] = useState<QbItem[]>([]);
  const [classOptions, setClassOptions] = useState<Option<string>[]>();
  const [serviceItemOptions, setServiceItemOptions] = useState<Option<string>[]>();
  const [connectionError, setConnectionError] = useState(false);

  const getActivityMappingOptions = async () => {
    if (!activeCompanyId || !connectedSystem) return;
    setLoading(true);
    try {
      const response = await MiterAPI.integrations.quickbooks.get_activity_mapping_options(
        activeCompanyId,
        includedObjects?.join(",")
      );
      if (response.error) throw new Error(response.error);
      const initialization = initializeQbOptions(connectedSystem, response);
      setClassOptions(initialization.classOptions);
      setServiceItemOptions(initialization.serviceItemOptions);
      setQbClasses(initialization.classes);
      setQbItems(initialization.serviceItems);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error("Error retrieving QB classes and items: " + e.message);
      setConnectionError(true);
    }
    setLoading(false);
  };

  const handleEitherChange = (
    option: Option<string> | null,
    objs: (QbClass | QbItem)[],
    property: "class" | "service_item",
    readonly = false
  ): void => {
    if (readonly || !connectedSystem) return;

    const selectedObj =
      (option && objs.find((c) => ("ListID" in c ? c.ListID : c.Id) === option.value)) || null;

    // Return early if we didn't find the class since that means something is wrong
    if (!selectedObj && option) return;

    const params = getUpdateParams(connectedSystem, property, selectedObj);

    handleFieldsChange(buildAtomicMongoUpdateFromNested(params, { collapseCount: 2 }));
  };

  const handleClassChange = (option: Option<string> | null): void => {
    handleEitherChange(option, qbClasses, "class");
  };

  const handleItemChange = (option: Option<string> | null): void => {
    handleEitherChange(option, qbItems, "service_item");
  };

  const initializeQbOptions = (
    connectedSystem: "qbo" | "qbd",
    response: ActivityMappingOptionsResponse
  ): {
    classes: QbClass[];
    serviceItems: QbItem[];
    classOptions: Option<string>[];
    serviceItemOptions: Option<string>[];
  } => {
    // Initialize values
    const classes = response[connectedSystem]?.classes || [];
    const serviceItems = response[connectedSystem]?.service_items || [];
    const classOptions: Option<string>[] = [];
    const serviceItemOptions: Option<string>[] = [];

    for (const cls of classes) {
      classOptions.push(getOptionFromQbClassOrItem(cls));
    }

    for (const item of serviceItems) {
      serviceItemOptions.push(getOptionFromQbClassOrItem(item));
    }

    return { classOptions, serviceItemOptions, classes, serviceItems };
  };

  const getUpdateParams = (
    connectedSystem: "qbd" | "qbo",
    prop: "class" | "service_item",
    optionValue: QbClass | QbItem | null | undefined
  ): DeepPartial<Activity> => {
    return {
      integrations: {
        [connectedSystem]: {
          [prop]: optionValue,
        },
      },
    };
  };

  const getOptionFromQbClassOrItem = (obj: QbClass | QbItem): Option<string> => {
    let value: string, label: string;
    if ("ListID" in obj) {
      value = obj.ListID;
      label = String(obj.FullName);
    } else {
      value = obj.Id;
      label = String(obj.Name);
    }
    return { value, label };
  };

  const getQbClassIdForActivity = (
    activity: ActivityTableEntry,
    connectedSystem: "qbd" | "qbo"
  ): string | undefined => {
    if (connectedSystem === "qbo") return activity.integrations?.qbo?.class?.Id;
    return activity.integrations?.qbd?.class?.ListID;
  };

  const getQbItemIdForActivity = (
    activity: ActivityTableEntry,
    connectedSystem: "qbd" | "qbo"
  ): string | undefined => {
    if (connectedSystem === "qbo") return activity.integrations?.qbo?.service_item?.Id;
    return activity.integrations?.qbd?.service_item?.ListID;
  };

  const getQbClassIdForJob = (job: AggregatedJob, connectedSystem: "qbd" | "qbo"): string | undefined => {
    if (connectedSystem === "qbo") return;
    return job.integrations?.qbd?.class?.ListID;
  };

  const getQbItemIdForJob = (job: AggregatedJob, connectedSystem: "qbd" | "qbo"): string | undefined => {
    if (connectedSystem === "qbo") return;
    return job.integrations?.qbd?.service_item?.ListID;
  };

  useEffect(() => {
    getActivityMappingOptions();
  }, []);

  return {
    loading,
    classOptions,
    serviceItemOptions,
    connectionError,
    getQbClassIdForActivity,
    getQbItemIdForActivity,
    getQbClassIdForJob,
    getQbItemIdForJob,
    handleItemChange,
    handleClassChange,
    connectedSystem,
  };
};
