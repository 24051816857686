import { buildFlatfileMessage, normalizeDate } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { ImportField, Importer } from "../importer/Importer";
import { useImportValidators } from "dashboard/hooks/flatfile-import/useImportValidators";
import { useActiveCompanyId, useActiveTeam } from "dashboard/hooks/atom-hooks";
import { keyBy } from "lodash";
import { CreateImportedCardTransactionParams, MiterAPI } from "dashboard/miter";
import { FlatfileResults } from "@flatfile/react";
import { Notifier } from "ui";
import { generateUUID } from "miter-utils";

type PrelimExpenseImportRow = {
  date: string;
  merchant_name: string;
  amount: number | string;
  external_id?: string;
  card_last_4?: string;
  team_member_id?: string;
};

type Props = {
  onFinish: () => void;
};

export const CardTransactionImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/

  const activeCompanyId = useActiveCompanyId();
  const teamMembers = useActiveTeam();
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);
  const { validateTeamMemberID } = useImportValidators();

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/

  const validateAmount = (amount: string | number | undefined) => {
    if (amount) {
      let parsedAmount: number;

      // remove leading "$" signs
      if (typeof amount === "string") {
        if (amount.startsWith("$")) amount = amount.replaceAll("$", "");
        parsedAmount = parseFloat(amount);
      } else {
        parsedAmount = amount;
      }

      if (!/^-?[0-9]+\.?[0-9]?[0-9]?$/.test(parsedAmount + "")) {
        return buildFlatfileMessage("Amount must be a valid dollar amount", amount, "error");
      }
      return { value: parsedAmount };
    } else {
      return buildFlatfileMessage("Amount required for all purchases", amount, "error");
    }
  };

  const buildExpenseParams = (row: PrelimExpenseImportRow): CreateImportedCardTransactionParams => {
    if (!activeCompanyId) throw new Error("No active company ID");
    const { date, amount, merchant_name, external_id, card_last_4, team_member_id: friendlyTMId } = row;

    let cleanedAmount: number;
    if (typeof amount === "string") {
      cleanedAmount = parseFloat(amount.replaceAll("$", ""));
    } else {
      cleanedAmount = amount;
    }

    const externalId: string = external_id || generateUUID();

    let cardLast4 = card_last_4;
    if (card_last_4 && card_last_4.length > 4) {
      // truncate
      cardLast4 = card_last_4.slice(-4);
    }

    const output: CreateImportedCardTransactionParams = {
      company_id: activeCompanyId,
      date,
      amount: cleanedAmount,
      external_id: externalId,
      merchant_name,
      card_last_4: cardLast4,
    };

    if (friendlyTMId) {
      const teamMember = lookupTeamID[friendlyTMId];
      if (!teamMember) throw new Error(`Team member ${friendlyTMId} not found`);
      output.team_member_id = teamMember._id;
    }

    return output;
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedReimbursements = results.validData.map(buildExpenseParams);

      const response = await MiterAPI.expenses.import({
        clean_inputs: preppedReimbursements,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;
      const warnings = response.results.warnings.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(
            `Imported ${successes} card transactions with ${errors} errors and ${warnings} warnings.`
          );
        } else {
          Notifier.success(`Imported ${successes} card transactions with ${warnings} warnings.`);
        }
      } else {
        Notifier.error(`There were ${errors} errors and ${warnings} warnings.`);
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the card transactions.");
    }
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Date",
        type: "string",
        key: "date",
        description: "The date the expense was incurred.",
        validators: [{ validate: "required" }],
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.date)),
      },
      {
        label: "Amount",
        type: "string",
        key: "amount",
        validators: [{ validate: "required" }],
        description: "Amount of purchase",
        hook: (val) =>
          typeof val === "string" || typeof val === "number"
            ? validateAmount(val)
            : validateAmount(val.amount),
      },
      {
        label: "Merchant Name",
        type: "string",
        key: "merchant_name",
        validators: [{ validate: "required" }],
        description: "Name of merchant",
      },
      {
        label: "Card number last 4",
        type: "string",
        key: "card_last_4",
        description:
          "Optional last 4 of the card used. This can be more than 4 digits - our system will only look at the last 4 to match the card used.",
      },
      {
        label: "Purchase ID",
        type: "string",
        key: "external_id",
        description:
          "Optional unique id of this purchase. This field will be used to deduplicate imported purchases. If not provided, Miter will generate one behind the scenes.",
      },
      {
        label: "Team Member ID",
        type: "string",
        key: "team_member_id",
        description: "Unique identifer for an team member (must be same in source system and Miter)",
        hook: (row) =>
          typeof row === "string"
            ? validateTeamMemberID(row, false)
            : validateTeamMemberID(row.team_member_id, false),
      },
    ];

    return fieldList;
  }, []);

  return (
    <Importer
      id="cardtransactions"
      resource="card transactions"
      onSave={handleSubmit}
      fields={fields}
      title="Card transactions"
    />
  );
};
