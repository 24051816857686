import { MiterAPI, PermissionGroup } from "dashboard/miter";
import { useEffect, useState } from "react";
import { TeamMemberOverrideAttributes } from "backend/services/permission-group-service";

export const useTeamMemberPermissionGroups = (
  teamMemberId: string | undefined,
  overrideAttributes?: TeamMemberOverrideAttributes
): PermissionGroup[] | undefined => {
  const [teamMemberPermissionGroups, setTeamMemberPermissionGroups] = useState<PermissionGroup[]>();

  const getPermissionGroups = async () => {
    if (!teamMemberId) return;
    try {
      const res = await MiterAPI.team_member.retrieve_permission_groups(teamMemberId, overrideAttributes);
      if (res.error) throw new Error(res.error);
      setTeamMemberPermissionGroups(res);
    } catch (e: $TSFixMe) {
      console.error("Error getting team member permission groups", e);
    }
  };

  useEffect(() => {
    getPermissionGroups();
  }, [teamMemberId, overrideAttributes]);

  return teamMemberPermissionGroups;
};
