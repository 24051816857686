import { FlatfileButton, FlatfileResults } from "@flatfile/react";
import { AggregatedJob, BulkUpdateResult, ImportJobsResponse, Job, MiterAPI } from "dashboard/miter";
import { addressIsBlank, capitalize } from "dashboard/utils";
import { notNullish, stateOptions, useEnhancedSearchParams } from "miter-utils";
import {
  buildFlatfileMessage,
  bulkFlatfileValidate,
  bulkNormalizeDates,
  normalizeDate,
} from "dashboard/utils/flatfile";
import {
  CleanedJobWithHierarchy,
  useBuildJobHierarchies,
  useCleanJobsFromBackend,
  useJobImportSettings,
} from "dashboard/utils/jobUtils";
import Notifier from "dashboard/utils/notifier";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { BasicModal, Button, ConfirmModal, Loader, TableV2 } from "ui";
import AppContext from "../../contexts/app-context";
import JobModalForm from "../../pages/jobs/forms/JobModalForm";
import { CleanedJob } from "../../utils/jobUtils";
import styles from "./JobsTable.module.css";
import { ArrowsClockwise, Plus, Stack } from "phosphor-react";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import {
  useActiveCompany,
  useActiveCompanyId,
  useActiveTeam,
  useExpensePolicyOptions,
  useJobs,
  useActivities,
  useLookupPolicy,
  useLedgerMappingOptions,
  useLookupDepartment,
  useLookupLedgerMapping,
  useLookupPrg,
  usePrgOptions,
  useRefetchJobs,
  useReimbursementPolicyOptions,
  useTeamOptions,
  useTimesheetPolicyOptions,
  useJobNameFormatter,
  useDepartmentOptions,
  useSubJobsConfig,
  useLookupJob,
  useLocationOptions,
  useLookupLocation,
  usePerDiemRateOptions,
  useHasEnabledWcGroups,
  useWcGroupOptions,
  useLookupWcGroup,
  useIsMiterAdmin,
  useOtRuleOptions,
  useLookupOtRule,
} from "dashboard/hooks/atom-hooks";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { keyBy } from "lodash";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import FailuresModal, { FailureItem } from "../shared/FailuresModal";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";
import { Link } from "react-router-dom";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { parseCustomFieldValueParams, generateCustomFieldColumns } from "dashboard/utils/custom-fields";
import { MiterJobStatus } from "backend/models/job";
import { CreateJobParams } from "dashboard/miter";

type Props = {
  filteredJobs?: AggregatedJob[];
  subJobView?: boolean;
};

const JobsTable: React.FC<Props> = ({ filteredJobs, subJobView }) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const navigate = useNavigate();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const { action, status } = parsedSearchParams;
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const activeTeam = useActiveTeam();
  const prgOptions = usePrgOptions();
  const lookupJob = useLookupJob();
  const jobAbilities = useJobAbilities();
  const subJobsConfig = useSubJobsConfig();
  const buildJobHierarchies = useBuildJobHierarchies();
  const autoGroupColumnDef = useBuildAutoGroupColumnDef(status);
  const hasEnabledWcGroups = useHasEnabledWcGroups();
  const lookupWcGroup = useLookupWcGroup();
  const wcGroupOptions = useWcGroupOptions();

  const multiWorkplacePayroll = !!activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;
  const { integrations, customFields } = React.useContext(AppContext);
  const initialJobs = useJobs();
  const jobFormatter = useJobNameFormatter();
  const refetchJobs = useRefetchJobs();
  const lookupPrg = useLookupPrg();
  const [statusToggleModal, setStatusToggleModal] = useState(false);
  const [bulkActionLoading, setBulkActionLoading] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState<CleanedJobWithHierarchy[]>([]);
  const cleanJobsFromBackend = useCleanJobsFromBackend();
  const jobsColumns = useJobColumns({ subJobView });
  const { can } = useMiterAbilities();
  const [failures, setFailures] = useState<FailureItem[]>([]);
  const isMiterAdmin = useIsMiterAdmin();

  const jobs = useMemo(() => (filteredJobs ? filteredJobs : initialJobs), [filteredJobs, initialJobs]);

  // States related to table actions
  const [adding, setAdding] = useState(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [importRes, setImportRes] = useState<ImportJobsResponse | undefined>();

  const lookupTeam = useMemo(() => keyBy(activeTeam, "friendly_id"), [activeTeam]);
  const jobImportSettings = useJobImportSettings();

  const finalCols: ColumnConfig<CleanedJobWithHierarchy>[] = useMemo(() => {
    const customFieldColumns = generateCustomFieldColumns<CleanedJob<AggregatedJob>>(
      customFields,
      "job",
      true
    );

    const prgCol: ColumnConfig<CleanedJob<AggregatedJob>> = {
      field: "pay_rate_group",
      headerName: "Pay rate group",
      dataType: "string",
      valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
        const id = params.data?.pay_rate_group;
        const prg = lookupPrg(id);
        return prg?.label || "-";
      },
      editable: true,
      editorType: "select",
      cellEditorParams: () => ({ options: prgOptions, isClearable: true }),
      minWidth: 250,
    };

    const cols = jobsColumns.slice();
    cols.push(prgCol);

    if (multiWorkplacePayroll) {
      const taxCol: ColumnConfig<CleanedJob<AggregatedJob>> = {
        headerName: "Tax jurisdiction",
        dataType: "boolean",
        initialHide: true,
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): boolean => {
          return !!params.data?.workplace_id;
        },
      };
      cols.push(taxCol);
    }

    if (hasEnabledWcGroups) {
      cols.push({
        field: "wc_group_id",
        headerName: "Workers comp group",
        dataType: "string",
        initialHide: true,
        filter: "agSetColumnFilter",
        valueFormatter: (params) => {
          const wcGroup = lookupWcGroup(params.data?.wc_group_id);
          return wcGroup?.name || "-";
        },
        editable: can("team:update"),
        editorType: "select",
        cellEditorParams: {
          options: wcGroupOptions,
          isClearable: true,
        },
      });
    }

    cols.push(...customFieldColumns);

    return cols;
  }, [customFields, lookupPrg, multiWorkplacePayroll, prgOptions, hasEnabledWcGroups]);

  const doneJobs = useMemo(() => {
    const cleanedJobs = cleanJobsFromBackend(jobs)
      .filter((job) => jobAbilities.can("read", job))
      .map((job) => ({ ...job, readonly: jobAbilities.cannot("update", job) }));

    if (subJobsConfig.enabled) {
      return buildJobHierarchies(cleanedJobs);
    } else {
      return cleanedJobs;
    }
  }, [jobs, jobAbilities, lookupJob, buildJobHierarchies, subJobsConfig.enabled]);

  const anySelectedAreInactive = !!jobs
    .filter((j) => selectedJobs.find((s) => s._id === j._id))
    .some((j) => j.status === "inactive");

  useEffect(() => {
    setAdding(action === "create");
  }, [action]);

  useEffect(() => {
    if (!status) setSearchParams({ status: "active" });
  }, [status]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/

  const handleCreateModalHide = () => {
    setAdding(false);
    setSearchParams({ action: undefined });
  };

  const handleAdd = () => {
    setAdding(true);
  };

  /*********************************************************
    Flatfile import
  **********************************************************/

  const handleFlatfileSubmit = async (results: FlatfileResults): Promise<string | void | null> => {
    handleImport(results.allData);
    return "Congrats! Your job import is in progress!";
  };

  const handleValidateSupervisorIds = (data: string | { supervisor_ids: string }) => {
    const ids = typeof data === "string" ? data : data.supervisor_ids;
    if (ids.length === 0) return { value: ids };

    const supervisorIds = ids.split(",").map((id) => id.trim());
    const invalidIds = supervisorIds.filter((id) => !lookupTeam[id]);

    if (invalidIds.length > 0) {
      const error = `Invalid supervisor ids: ${invalidIds.join(", ")}`;
      return buildFlatfileMessage(error, ids, "error");
    }

    return { value: ids };
  };

  const handelFlatfileRecordChange = (data, _index) => {
    const formattedData = { ...data };

    if (data.start_date) {
      formattedData.start_date = normalizeDate(data.start_date);
    }

    if (data.supervisor_ids) {
      formattedData.supervisor_ids = handleValidateSupervisorIds(data);
    }

    return formattedData;
  };

  const handleFlatfileButtonRender = (importer, launch) => {
    return (
      <Button className="button-1" onClick={launch} style={{ height: 32, marginRight: 0 }}>
        <Stack weight="bold" style={{ marginBottom: -3, marginRight: 4 }} />
        Bulk import
      </Button>
    );
  };

  const handleImport = async (params: Partial<Job>[]): Promise<void> => {
    setImporting(true);
    try {
      const formattedParams = formatImportParams(params);
      const response = await MiterAPI.jobs.import(formattedParams);
      setImportRes(response);
      if (response.error) throw new Error(response.error);

      refetchJobs(response.success);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
  };

  const formatImportParams = (params: Partial<Job>[]): CreateJobParams[] => {
    return params.map((row) => {
      // Remove all properties in the row that have empty strings
      const formattedRow = Object.keys(row).reduce((acc, key) => {
        if (row[key] !== "") {
          acc[key] = row[key];
        }
        return acc;
      }, {});

      const hasAddress = !!formattedRow["city"] && !!formattedRow["state"] && !!formattedRow["postal_code"];
      const supervisorFriendlyIds = formattedRow["supervisor_ids"]?.split(",")?.map((id) => id.trim());
      const supervisors = supervisorFriendlyIds?.map((id) => lookupTeam[id]?._id)?.filter(notNullish);

      return {
        ...formattedRow,
        name: formattedRow["name"],
        company: activeCompanyId!,
        supervisors,
        customer: formattedRow["customer"],

        //Combine address into object from dot notation
        address: hasAddress
          ? {
              line1: formattedRow["address.line1"],
              line2: formattedRow["address.line2"],
              city: formattedRow["address.city"],
              state: formattedRow["address.state"],
              postal_code: formattedRow["address.postal_code"],
            }
          : undefined,
        status: "active",
      };
    });
  };

  const buildFlatfileButton = () => {
    const fieldHooks = {
      start_date: bulkNormalizeDates,
      supervisor_ids: (values) => bulkFlatfileValidate(values, handleValidateSupervisorIds),
    };

    return (
      <FlatfileButton
        licenseKey={process.env.REACT_APP_FLATFILE_LICENSE_KEY!}
        customer={{ companyId: activeCompanyId!, userId: activeCompanyId! }}
        settings={jobImportSettings}
        // @ts-expect-error Flatfile has bad typescript types
        fieldHooks={fieldHooks}
        onRecordChange={handelFlatfileRecordChange}
        render={handleFlatfileButtonRender}
        title="Import jobs"
        resource="jobs"
        onData={handleFlatfileSubmit}
      >
        Import jobs
      </FlatfileButton>
    );
  };

  const cleanUpdateParams = (jobParamsArray: CleanedJob<AggregatedJob>[]) => {
    return jobParamsArray.map((d) => {
      const hasAddress = !addressIsBlank(d.address);
      const address = hasAddress ? d.address : null;
      const customFieldValues = parseCustomFieldValueParams(d);

      return {
        _id: d._id,
        params: {
          name: d.name,
          status: d.status,
          code: d.code,
          description: d.description,
          "cpr_info.project_number": d.cpr_info?.project_number,
          department_id: d.department_id || null,
          location_id: d.location_id || null,
          start_date: d.start_date || null,
          supervisors: d.supervisor_options.map((s) => s.value),
          superintendent_ids: d.superintendent_options.map((s) => s.value),
          pay_rate_group: d.pay_rate_group || null,
          timesheet_policy_id: d.timesheet_policy_id || null,
          expense_policy_id: d.expense_policy_id || null,
          reimbursement_policy_id: d.reimbursement_policy_id || null,
          address,
          ledger_mapping_id: d.ledger_mapping_id || null,
          is_davis_bacon: d.is_davis_bacon,
          is_ocip: d.is_ocip,
          per_diem_rate_ids: d.per_diem_rate_options ? d.per_diem_rate_options?.map((p) => p.value) : [],
          wc_group_id: d.wc_group_id || null,
          custom_field_values: customFieldValues,
          overtime_rule_id: d.overtime_rule_id,
        },
      };
    });
  };

  const updateJobs = async (jobParamsArray: CleanedJob<AggregatedJob>[]): Promise<BulkUpdateResult> => {
    try {
      const params = cleanUpdateParams(jobParamsArray);
      const res = await MiterAPI.jobs.bulk_update(params);
      if (res.error) throw Error(res.error);

      if (res.errors.length > 0) {
        const successes = res.successes;
        const errors = res.errors;

        return { successes, errors };
      }

      await refetchJobs();
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem updating the jobs. We're looking into it.");
      return { successes: [], errors: [] };
    }
  };

  const handleDuplicate = async () => {
    setBulkActionLoading(true);
    try {
      const newJobs = await Promise.all(
        selectedJobs.map(async (job) => await MiterAPI.jobs.duplicate(job._id))
      );

      Notifier.success("Job(s) successfully duplicated.");
      await refetchJobs(newJobs.map((j) => j._id));
      setSelectedJobs([]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error duplicating job(s). Our engineers are investigating!");
      await refetchJobs();
    }
    setBulkActionLoading(false);
  };

  const handleArchive = async () => {
    setBulkActionLoading(true);
    try {
      const res = await MiterAPI.jobs.bulk_update(
        selectedJobs.map((j) => ({ _id: j._id, params: { archived: true } }))
      );

      if (res.error) throw new Error(res.error);

      if (res.errors.length) {
        Notifier.warning("Some jobs were not deleted.");
        setFailures(
          res.errors.map((e) => {
            const label = jobFormatter(e._id) || `Job ${e._id}`;
            return { label, message: e.message };
          })
        );
      } else {
        Notifier.success("Job(s) successfully deleted.");
      }

      await refetchJobs(res.successes.map((s) => s._id));
      setSelectedJobs([]);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error("Error deleting job(s). " + e.message);
    }
    setBulkActionLoading(false);
  };

  const handleStatusChange = async () => {
    setBulkActionLoading(true);
    try {
      const newStatus: MiterJobStatus = anySelectedAreInactive ? "active" : "inactive";
      const res = await MiterAPI.jobs.bulk_update(
        selectedJobs.map((j) => ({ _id: j._id, params: { status: newStatus } }))
      );
      if (res.error) throw new Error(res.error);
      if (res.errors.length) {
        Notifier.warning("Some jobs were not updated.");
        setFailures(
          res.errors.map((e) => {
            const label = jobFormatter(e._id) || `Job ${e._id}`;
            return { label, message: e.message };
          })
        );
      } else {
        Notifier.success(`Job(s) successfully set to ${newStatus}.`);
      }
      await refetchJobs(res.successes.map((s) => s._id));
      setSelectedJobs([]);
      setStatusToggleModal(false);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error("Error updating job(s). " + e.message);
    }
    setBulkActionLoading(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const canDeleteJob = isMiterAdmin || !IS_PRODUCTION;
  const syncButtonText = useMemo(() => {
    let text;
    const connectedSystems = integrations.filter((i) => i.connection).map((i) => i.key);

    if (connectedSystems.includes("qbd") || connectedSystems.includes("qbo")) {
      text = "Run QuickBooks sync";
    }
    return text;
  }, [integrations]);

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Duplicate",
        action: handleDuplicate,
        loading: bulkActionLoading,
        className: "button-1",
        shouldShow: () => can("jobs:create") && selectedJobs.every((j) => jobAbilities.can("create", j)),
      },
      {
        label: anySelectedAreInactive ? "Activate" : "Deactivate",
        action: () => setStatusToggleModal(true),
        loading: bulkActionLoading,
        className: anySelectedAreInactive ? "button-2" : "button-3",
        shouldShow: () => can("jobs:update") && selectedJobs.every((j) => jobAbilities.can("update", j)),
      },
      {
        label: "Delete (Mitosaur Only)",
        action: handleArchive,
        loading: bulkActionLoading,
        shouldShow: () => canDeleteJob,
        className: "button-3",
      },
    ],
    [anySelectedAreInactive, bulkActionLoading, selectedJobs, jobAbilities, canDeleteJob]
  );

  const staticActions: TableActionLink[] = [
    {
      label: "New job",
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      action: handleAdd,
      className: "button-2",
      important: true,
      shouldShow: () => can("jobs:create"),
    },
    { key: "bulk-import", component: buildFlatfileButton(), shouldShow: () => can("jobs:create") },
    ...(syncButtonText
      ? [
          {
            label: syncButtonText,
            className: "button-1",
            icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
            action: () => navigate("/integrations"),
            shouldShow: () => can("jobs:create"),
          },
        ]
      : []),
  ];

  /*********************************************************
    Toggler configs
  **********************************************************/
  const togglerConfig = {
    config: [
      { path: "active", label: "Active" },
      { path: "inactive", label: "Inactive" },
      { path: "all", label: "All" },
    ],
    path: status,
    secondary: !!subJobView,
    field: "status",
  };

  /*********************************************************
    Colujmns
  **********************************************************/

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderJobImportResults = () => {
    if (!importRes) {
      return <Loader />;
    } else if (importRes?.error) {
      return "Error: " + importRes.error;
    } else if ((importRes as ImportJobsResponse)?.failures.length > 0) {
      const { success, failures } = importRes as ImportJobsResponse;

      const header =
        success.length > 0
          ? "The following jobs were not able to be imported"
          : "We were unable to import your jobs.";

      const failedParams = failures.map((job) => (
        <div>
          <p className={styles["failed-import-name"]}>{job.name}</p>
          <p className={styles["failed-import-error"]}>
            {job.error}
            {job.fields?.map((field) => {
              return (
                <p className={styles["failed-import-field"]}>
                  <strong>{capitalize(field.name)}</strong>
                  <br></br>
                  {field.error}
                </p>
              );
            })}
          </p>
        </div>
      ));

      return (
        <>
          <p className={styles["failed-subtitle"]}>{header}</p>
          <div className={styles["failures"]}>{failedParams}</div>
        </>
      );
    } else {
      return <p className={styles["success-subtitle"]}>All the jobs were successfully imported!</p>;
    }
  };

  const renderTable = () => {
    return (
      <TableV2
        id="jobs-table"
        treeData={subJobsConfig.enabled && !subJobView}
        resource="jobs"
        data={doneJobs}
        columns={finalCols}
        onSelect={setSelectedJobs}
        toggler={togglerConfig}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={true}
        onClick={(job) => navigate(`/jobs/${job._id}`)}
        defaultSelectedRows={selectedJobs}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"jobs-table-container"}
        editable={can("jobs:update")}
        onSave={updateJobs}
        rowLinkBuilder={(job) => `/jobs/${job?._id}`}
        getDataPath={(data) => data.jobHierarchy || []}
        autoGroupColumnDef={autoGroupColumnDef}
        groupDisplayType="singleColumn"
        groupHideOpenParents={false}
        groupDefaultExpanded={0}
        groupAllowUnbalanced={true}
      />
    );
  };

  return (
    <>
      {adding && <JobModalForm hide={handleCreateModalHide} />}
      {renderTable()}
      {statusToggleModal && (
        <ConfirmModal
          title={"Are you sure?"}
          body={`Are you sure you want to ${
            anySelectedAreInactive ? "activate" : "deactivate"
          } the selected job${selectedJobs.length > 1 ? "s" : ""}?`}
          onYes={handleStatusChange}
          onNo={() => setStatusToggleModal(false)}
          loading={bulkActionLoading}
        />
      )}
      {importing && (
        <BasicModal
          button2Text="Close"
          button2Action={() => setImporting(false)}
          headerText={"Job import results"}
          className={"job-import-modal"}
        >
          {renderJobImportResults()}
        </BasicModal>
      )}
      {failures.length > 0 && (
        <FailuresModal headerText="Job update failures" failures={failures} onClose={() => setFailures([])} />
      )}
    </>
  );
};

export default JobsTable;

export const useJobColumns = (opts?: { subJobView?: boolean }): ColumnConfig<CleanedJob<AggregatedJob>>[] => {
  const teamOptions = useTeamOptions();
  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const expensePolicyOptions = useExpensePolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();
  const lookupPolicy = useLookupPolicy();
  const lookupDepartment = useLookupDepartment();
  const lookupLocation = useLookupLocation();
  const ledgerMappingOptions = useLedgerMappingOptions();
  const lookupLedgerMapping = useLookupLedgerMapping();
  const departmentOptions = useDepartmentOptions();
  const locationOptions = useLocationOptions();
  const subJobsConfig = useSubJobsConfig();
  const perDiemRateOptions = usePerDiemRateOptions();
  const otRuleOptions = useOtRuleOptions();
  const lookupOtRule = useLookupOtRule();

  const allActivities = useActivities();
  const companyActivities = useMemo(() => allActivities.filter((a) => a.company_activity), [allActivities]);

  const { subJobView } = opts || {};
  const { integrations } = useContext(AppContext);

  const cols = useMemo(() => {
    const jobsColumns: ColumnConfig<CleanedJob<AggregatedJob>>[] = [
      {
        field: "name",
        headerName: "Name",
        dataType: "string",
        minWidth: 350,
        filter: true,
        editable: true,
        pinned: "left",
        editorType: "text",
        hide: subJobsConfig.enabled && !subJobView,
        suppressColumnsToolPanel: subJobsConfig.enabled && !subJobView,
        suppressFiltersToolPanel: subJobsConfig.enabled && !subJobView,
      },
      {
        field: "status",
        headerName: "Status",
        dataType: "string",
        displayType: "badge",
        colors: {
          active: "green",
          inactive: "yellow",
        },
        enableRowGroup: true,
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: [
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ],
        }),
      },
      {
        field: "code",
        headerName: "Code",
        dataType: "string",
        editable: true,
        editorType: "text",
      },
      {
        field: "address_string",
        headerName: "Address",
        dataType: "string",
        editableHide: true,
      },
      {
        field: "address.line1",
        headerName: "Address Line 1",
        dataType: "string",
        editable: true,
        editorType: "text",
        editableOnly: true,
      },
      {
        field: "address.line2",
        headerName: "Address Line 2",
        dataType: "string",
        editable: true,
        editorType: "text",
        editableOnly: true,
      },
      {
        field: "address.city",
        headerName: "City",
        dataType: "string",
        editable: true,
        editorType: "text",
        editableOnly: true,
      },
      {
        field: "address.state",
        headerName: "State",
        dataType: "string",
        editable: true,
        editorType: "select",
        editableOnly: true,
        cellEditorParams: () => ({ options: stateOptions }),
      },
      {
        field: "address.postal_code",
        headerName: "Postal Code",
        dataType: "string",
        editable: true,
        editorType: "text",
        editableOnly: true,
      },
      {
        field: "description",
        headerName: "Description",
        dataType: "string",
        editable: true,
        editorType: "text",
      },
      {
        field: "start_date",
        headerName: "Start date",
        dataType: "date",
        dateType: "iso",
        editable: true,
        editorType: "date",
        editorDateType: "iso",
      },
      {
        field: "activities_count",
        headerName: "Activities",
        headerTooltip: "Count of activities available for use on this job",
        dataType: "number",
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): number | undefined => {
          // If custom activities are enabled on the job, show the count of activities from the job object itself
          // Otherwise, all company activities will be available and we should show the count of company activities instead
          return params.data?.activities?.length ?? companyActivities.length;
        },
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          return params.value != null ? params.value.toString() : "-";
        },
      },
      {
        field: "custom_activities_count",
        headerName: "Custom activities",
        headerTooltip: "Count of custom activities available for use on this job",
        dataType: "number",
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): number | undefined => {
          return params.data?.activities?.length;
        },
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          return params.value != null ? params.value.toString() : "-";
        },
        initialHide: true,
      },
      {
        field: "cpr_enabled",
        headerName: "Certified payroll",
        dataType: "boolean",
        colors: { true: "green", false: "gray" },
        enableRowGroup: true,
      },
      {
        field: "is_davis_bacon",
        headerName: "Davis-Bacon",
        dataType: "boolean",
        colors: { true: "green", false: "gray" },
        enableRowGroup: true,
        editable: true,
        editorType: "checkbox",
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): boolean => {
          return !!params.data?.is_davis_bacon;
        },
      },
      {
        field: "cpr_info.project_number",
        headerName: "Certified payroll project number",
        dataType: "string",
        editableOnly: true,
        editable: true,
        editorType: "text",
        validations: (value: CleanedJob<AggregatedJob>, params: $TSFixMe): string | boolean => {
          if (!value && !!params?.data.cpr_info) return "Project number is required when CPRs are enabled";
          return true;
        },
      },
      {
        field: "per_diem_rate_options",
        headerName: "Per diems",
        dataType: "string",
        editable: true,
        editableOnly: true,
        editorType: "multiselect",
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          return params.data?.per_diem_rate_options?.map((option) => option.label).join(", ") || "-";
        },
        cellEditorParams: {
          options: perDiemRateOptions,
          isClearable: true,
        },
      },
      // Field to show per diems in the table as a string and allow sorting, hide this field when in edit mode
      {
        field: "per_diem_rate_options",
        headerName: "Per diems",
        dataType: "string",
        editable: false,
        editableHide: true,
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): string => {
          return params.data?.per_diem_rate_options?.map((option) => option.label).join(", ") || "-";
        },
      },
      {
        field: "is_ocip",
        headerName: "OCIP",
        dataType: "boolean",
        filter: "agSetColumnFilter",
        enableRowGroup: true,
        initialHide: true,
        editable: true,
        editorType: "checkbox",
      },
      {
        field: "ledger_mapping_id",
        headerName: "GL account mapping",
        editorType: "select",
        initialHide: true,
        cellEditorParams: {
          options: ledgerMappingOptions,
          isClearable: true,
        },
        editable: true,
        valueGetter: (params) => {
          const mapping = lookupLedgerMapping(params.data?.ledger_mapping_id);
          return mapping?.name;
        },
      },
      {
        field: "supervisors",
        headerName: "Supervisors",
        dataType: "string",
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): string => {
          return params.data?.supervisors?.map((supervisor) => supervisor.full_name).join(", ") || "-";
        },
        editableHide: true,
      },
      {
        field: "superintendents",
        headerName: "Superintendents",
        dataType: "string",
        initialHide: true,
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): string => {
          return (
            params.data?.superintendents?.map((superintendent) => superintendent.full_name).join(", ") || "-"
          );
        },
        editableHide: true,
      },
      {
        field: "department_id",
        headerName: "Department",
        dataType: "string",
        initialHide: true,
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>) => {
          return lookupDepartment(params.data?.department_id)?.name;
        },
        cellEditorParams: {
          options: departmentOptions,
          isClearable: true,
        },
        editable: true,
        editorType: "select",
      },
      {
        field: "location_id",
        headerName: "Location",
        dataType: "string",
        initialHide: true,
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>) => {
          return lookupLocation(params.data?.location_id)?.name;
        },
        cellEditorParams: {
          options: locationOptions,
          isClearable: true,
        },
        editable: true,
        editorType: "select",
      },
      {
        field: "supervisor_options",
        headerName: "Supervisors",
        dataType: "string",
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          return params.data?.supervisor_options?.map((supervisor) => supervisor.label).join(", ") || "-";
        },
        editableOnly: true,
        editable: true,
        editorType: "multiselect",
        cellEditorParams: () => ({ options: teamOptions }),
        minWidth: 250,
      },
      {
        field: "superintendent_options",
        headerName: "Superintendents",
        dataType: "string",
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          return (
            params.data?.superintendent_options?.map((superintendent) => superintendent.label).join(", ") ||
            "-"
          );
        },
        editableOnly: true,
        editable: true,
        editorType: "multiselect",
        cellEditorParams: () => ({ options: teamOptions }),
        minWidth: 250,
      },
      {
        field: "timesheet_policy_id",
        headerName: "Timesheet policy",
        dataType: "string",
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          const policy = lookupPolicy(params.data?.timesheet_policy_id);
          return policy?.name || "-";
        },
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({ options: timesheetPolicyOptions, isClearable: true }),
        minWidth: 250,
        initialHide: true,
      },
      {
        field: "expense_policy_id",
        headerName: "Card transaction policy",
        dataType: "string",
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          const policy = lookupPolicy(params.data?.expense_policy_id);
          return policy?.name || "-";
        },
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({ options: expensePolicyOptions, isClearable: true }),
        minWidth: 250,
        initialHide: true,
      },
      {
        field: "reimbursement_policy_id",
        headerName: "Reimbursement policy",
        dataType: "string",
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          const policy = lookupPolicy(params.data?.reimbursement_policy_id);
          return policy?.name || "-";
        },
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({ options: reimbursementPolicyOptions, isClearable: true }),
        minWidth: 250,
        initialHide: true,
      },
      {
        field: "overtime_rule_id",
        headerName: "Overtime rule",
        dataType: "string",
        valueFormatter: (params: ValueFormatterParams<CleanedJob<AggregatedJob>>): string => {
          const rule = lookupOtRule(params.data?.overtime_rule_id);
          return rule?.label || "-";
        },
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({ options: otRuleOptions, isClearable: true }),
        minWidth: 250,
        initialHide: true,
      },
      {
        field: "integrations",
        headerName: "Integrations",
        dataType: "string",
        valueGetter: (params: ValueGetterParams<CleanedJob<AggregatedJob>>): string => {
          if (!params.data?.integrations) return "-";
          const integrationLabels = Object.keys(params.data?.integrations)
            .map((key) => {
              return integrations.find((i) => i.key === key)?.label;
            })
            .filter(notNullish);
          return integrationLabels.join(", ");
        },
        initialHide: true,
        minWidth: 250,
      },
    ];

    return jobsColumns;
  }, [
    teamOptions,
    lookupDepartment,
    lookupLocation,
    lookupOtRule,
    subJobsConfig.enabled,
    subJobView,
    otRuleOptions,
    perDiemRateOptions,
    ledgerMappingOptions,
    lookupLedgerMapping,
    departmentOptions,
    locationOptions,
    timesheetPolicyOptions,
    expensePolicyOptions,
    reimbursementPolicyOptions,
    lookupPolicy,
    integrations,
    companyActivities,
  ]);

  return cols;
};

const useBuildAutoGroupColumnDef = (view?: string) => {
  const jobNameFormatter = useJobNameFormatter({ disableParentJobPrefixing: true });

  return {
    valueFormatter: (params) => params.data?.name,
    filterValueGetter: (params) => params.data?.name,
    cellRendererParams: {
      suppressCount: false,
      checkbox: false,
      innerRenderer: (params) => {
        const data = params.data;
        if (!data) {
          if (view === "inactive") {
            return "[Active] " + jobNameFormatter(params.node?.key);
          } else {
            return "[Inactive] " + jobNameFormatter(params.node?.key);
          }
        }
        return (
          <Link className="black-link hover-purple-link" to={`/jobs/${data?._id}`}>
            {data?.name}
          </Link>
        );
      },
    },
    useValueFormatterForExport: true,
    headerName: "Name",
    comparator: (_valueA, _valueB, nodeA, nodeB, _isInverted) => {
      if (nodeA.data && nodeB.data) {
        return nodeA.data.name.localeCompare(nodeB.data.name);
      }
      return 0;
    },
  };
};
