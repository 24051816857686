import React, { LegacyRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import FullCalendar, {
  DateSelectArg,
  DatesSetArg,
  EventChangeArg,
  EventClickArg,
  EventDropArg,
  CustomButtonInput,
  EventContentArg,
} from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import listPlugin from "@fullcalendar/list";

import interactionPlugin, { EventResizeDoneArg } from "@fullcalendar/interaction"; // for selectable
import adaptivePlugin from "@fullcalendar/adaptive";
import dayGridPlugin from "@fullcalendar/daygrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import SchedulingContext from "dashboard/contexts/scheduling-context";
import "./schedulingCalendar.css";
import AssignmentModal from "../../components/assignments/AssignmentModal";
import styles from "./Scheduling.module.css";
import { DateTime } from "luxon";
import {
  Assignment,
  MiterAPI,
  AggregatedJob,
  AggregatedTeamMember,
  AggregatedTimeOffRequest,
} from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { truncate } from "lodash";
import { returnAssignmentFromArray, useSchedulingResources } from "../../components/assignments/utils";
import { FaPrint, FaTasks, FaUsers } from "react-icons/fa";
import html2canvas from "html2canvas";
import { Loader, BasicModal, Formblock, Label, Button } from "ui";
import { saveAs } from "file-saver";
import pluralize from "pluralize";
import { colors, useFullCalendarEventBuilder } from "dashboard/utils/schedule";

import { jsPDF } from "jspdf";
import { Funnel, MapPin, Users, Wrench, X } from "phosphor-react";
import { useTimeOffRequestFormItems } from "dashboard/hooks/useTimeOffRequestFormItems";
import {
  useActiveJobs,
  useActiveTeam,
  useCrews,
  useDepartmentOptions,
  useLookupJob,
} from "dashboard/hooks/atom-hooks";
import { useNavigate } from "react-router-dom";
import { matchSorter } from "match-sorter";
import { Popover } from "react-tiny-popover";
import Select from "react-select";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import TimeOffRequestModal, { TimeOffRequestFormItems } from "../time-off/TimeOffRequestModal";

type Props = {
  defaultJob?: AggregatedJob;
  defaultTeamMember?: AggregatedTeamMember;
};

export type RecurrenceTypes = "this" | "this_and_future" | "all" | undefined;
type SchedulingUpdateParams = {
  data: Partial<Assignment>;
  update_recurrence?: RecurrenceTypes;
  previous_starts_at: number;
  previous_ends_at: number;
};
export const initialEditRecurrenceOptions = [
  { label: "This and following events", value: "this_and_future" },
  { label: "All events", value: "all" },
];

export type ViewType =
  | "customTimelineWeek"
  | "dayGridMonth"
  | "resourceTimelineMonth"
  | "customResourceTimeGridDay"
  | "customListMonth";

export type ResourceType = "job" | "team_member";

const SchedulingCalendar: React.FC<Props> = ({ defaultJob, defaultTeamMember }) => {
  const DEFAULT_VIEW = "customTimelineWeek";

  const calendarContainerRef = useRef<HTMLElement | null>(null);
  const calendarRef = useRef<FullCalendar | null>(null);

  const { getResources } = useSchedulingResources();

  const {
    scheduleEvents,
    fetchScheduleEvents,
    lastQueriedEnd,
    setLastQueriedEnd,
    lastQueriedStart,
    setLastQueriedStart,
    isCompanyWide,
  } = useContext(SchedulingContext);

  const jobs = useActiveJobs();

  const teamMembers = useActiveTeam();
  const crews = useCrews();
  const navigate = useNavigate();
  const { can, cannot } = useMiterAbilities();

  const [resourceType, setResourceType] = useState<ResourceType>("team_member");
  const [activeView, setActiveView] = useState<ViewType>("customTimelineWeek");
  const [activeViewStart, setActiveViewStart] = useState<DateTime>();
  const [activeViewEnd, setActiveViewEnd] = useState<DateTime>();
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const [showTimeOffRequestModal, setShowTimeOffRequestModal] = useState(false);
  const [activeAssignmentID, setActiveAssignmentID] = useState<string | undefined>();
  const [activeTimeOffRequest, setActiveTimeOffRequest] = useState<AggregatedTimeOffRequest | undefined>();
  const [initialStartsAt, setInitialStartsAt] = useState<DateTime | undefined>();
  const [initialEndsAt, setInitialEndsAt] = useState<DateTime | undefined>();
  const [initialJob, setInitialJob] = useState<AggregatedJob | undefined>(defaultJob);
  const [info, setInfo] = useState<EventResizeDoneArg | EventDropArg | undefined>(undefined);
  const [initialTeamMember, setInitialTeamMember] = useState<AggregatedTeamMember | undefined>(
    defaultTeamMember
  );
  // this is sent both when updating and deleting recurring assignments
  const [editRecurrenceType, setEditRecurrenceType] = useState<RecurrenceTypes>(undefined);
  const [showRecurringTypeModal, setShowRecurringTypeModal] = useState(false);

  const [showDownloadScheduleModal, setShowDownloadScheduleModal] = useState(false);
  const [showJobsWithAssignments, setShowJobsWithAssignments] = useState<undefined | boolean>(true);
  const [showTMsWithAssignments, setShowTMsWithAssignments] = useState<undefined | boolean>(true);
  const [downloadFormat, setDownloadFormat] = useState<"pdf" | "png">("pdf");
  const [searchField, setSearchField] = useState<React.ReactChild | undefined>();
  const [showFilters, setShowFilters] = useState(false);

  const [crewFilter, setCrewFilter] = useState<string[]>([]);
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([]);

  const crewOptions = useMemo(() => crews.map((c) => ({ label: c.name, value: c._id })), [crews]);
  const departmentOptions = useDepartmentOptions();

  const [resourceFilter, setResourceFilter] = useState<string>();
  const [eventsFilter, setEventsFilter] = useState<string>();
  const efLower = eventsFilter?.toLowerCase();

  const buildFullCalendarEvent = useFullCalendarEventBuilder(activeView, resourceType);

  // when the recurring type modal opens, we want to set the correct
  // edit recurrence type. If the recurrence type is undefined,
  // we don't send the parameters to the backend. So we need
  // to reset the recurrence type to be undefined when closed
  const setShowRecurringTypeModalWrapper = (open: boolean, defaultOption?: "this" | "this_and_future") => {
    if (open) {
      setEditRecurrenceType(defaultOption || "this_and_future");
    } else {
      setEditRecurrenceType(undefined);
    }
    setShowRecurringTypeModal(open);
  };

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    handleDownloadSchedule();
  }, [downloading, calendarContainerRef.current]);

  /*********************************************************
   * Memoized variables for displaying assignments and
   * resources (jobs / team members) on the calendar.
   **********************************************************/

  const colorsMap = useMemo(() => {
    return jobs.reduce((colorsMap1, job, idx) => {
      colorsMap1[job._id] = colors[idx % colors.length]!;
      return colorsMap1;
    }, {} as Record<string, string>);
  }, [jobs]);

  const lookupJob = useLookupJob();

  const jobIdsForFilter = useMemo(() => {
    return jobs.filter((job) => efLower && job.name.toLowerCase().includes(efLower)).map((job) => job._id);
  }, [efLower, jobs]);

  const teamMemberIdsForFilter = useMemo(() => {
    return teamMembers
      .filter((tm) => efLower && tm.full_name.toLowerCase().includes(efLower))
      .map((tm) => tm._id);
  }, [efLower, JSON.stringify(teamMembers)]);

  // Build the events for full calendar
  const events = useMemo(() => {
    const finalizedEvents = scheduleEvents
      .map((scheduleEvent) => {
        return buildFullCalendarEvent(
          {
            ...scheduleEvent,
            // TODO: remove when we convert to using the assignment aggregation
            // @ts-expect-error fix me
            job_object: lookupJob(scheduleEvent.job),
          },
          colorsMap
        );
      })
      .filter((event) => {
        if (!eventsFilter || eventsFilter.length === 0) {
          return true;
        }

        if (event.resourceIds.some((id) => jobIdsForFilter.includes(id))) {
          return true;
        }

        if (event.resourceIds.some((id) => teamMemberIdsForFilter.includes(id))) {
          return true;
        }

        if (event.title.toLowerCase().includes(eventsFilter.toLowerCase())) {
          return true;
        }

        if (event.address && event.address.toLowerCase().includes(eventsFilter.toLowerCase())) {
          return true;
        }

        return false;
      });

    return finalizedEvents;
  }, [
    JSON.stringify(scheduleEvents),
    jobs,
    JSON.stringify(jobIdsForFilter),
    JSON.stringify(teamMemberIdsForFilter),
    buildFullCalendarEvent,
  ]);

  const filteredResources = useMemo(() => {
    const resources = getResources({
      jobs,
      teamMembers,
      crews,
      // @ts-expect-error fix me
      assignments: scheduleEvents,
      activeView,
      resourceType,
    });

    // Get current start and end of the calendar view with calendarRef
    const calendarApi = calendarRef.current?.getApi();
    const currentStart = calendarApi?.view.activeStart;
    const currentEnd = calendarApi?.view.activeEnd;

    // Map of resource ids to their event counts
    const resourceCountsMap = {};
    resources.forEach((resource) => {
      resourceCountsMap[resource.id] = {
        count: events.filter((event) => event.resourceIds.includes(resource.id)).length,
        // Get total hours for the resource this week
        totalHours: events.reduce((totalHours, event) => {
          if (
            event.resourceIds.includes(resource.id) &&
            currentStart &&
            currentEnd &&
            event.start >= currentStart &&
            event.end <= currentEnd
          ) {
            // Get the hours difference between the start and end of the event with luxon
            const duration = DateTime.fromJSDate(event.end).diff(
              DateTime.fromJSDate(event.start),
              "hours"
            ).hours;

            return totalHours + duration;
          }
          return totalHours;
        }, 0),
      };
    });

    const filteredResources = resources
      .map((resource) => {
        if (activeView === "customResourceTimeGridDay") {
          return {
            ...resource,
            title: truncate(resource.title, { length: 40 }),
            order: resourceCountsMap[resource.id].count,
            totalHours: resourceCountsMap[resource.id].totalHours,
          };
        } else {
          return {
            ...resource,
            title: truncate(resource.title, { length: 40 }),
            order: resourceCountsMap[resource.id].count,
            totalHours: resourceCountsMap[resource.id].totalHours,
          };
        }
      })
      .filter((resource) => {
        if ((showJobsWithAssignments || showTMsWithAssignments) && showDownloadScheduleModal) {
          const start = calendarRef.current?.getApi().view.activeStart || new Date();
          const end = calendarRef.current?.getApi().view.activeEnd || new Date();

          return events.some(
            (event) => event.resourceIds.includes(resource.id) && event.start >= start && event.end <= end
          );
        }

        if (resourceType === "team_member") {
          const failedDepartmentFilter =
            departmentFilter?.length && !departmentFilter.includes(resource.departmentId || "");

          if (failedDepartmentFilter) return false;

          const failedCrewFilter =
            crewFilter?.length && crewFilter.every((crew) => !resource.crewList?.includes(crew));

          if (failedCrewFilter) return false;
        }

        if (resourceFilter === "" || !resourceFilter) return true;

        return true;
      });

    // Sort by order and then title
    return matchSorter(filteredResources, resourceFilter || "", {
      keys: ["full_name", "order", "title", "subhead", "crews"],
    }).sort((a, b) => {
      if (a.totalHours > 0 && b.totalHours > 0) return a.title.localeCompare(b.title);
      else if (a.totalHours > 0) return -1;
      else if (b.totalHours > 0) return 1;
      else return a.title.localeCompare(b.title);
    });
  }, [
    jobs,
    activeView,
    teamMembers,
    resourceType,
    scheduleEvents,
    resourceFilter,
    events,
    showJobsWithAssignments,
    showDownloadScheduleModal,
    downloading,
    activeViewStart,
    activeViewEnd,
    crews,
    departmentFilter,
    crewFilter,
  ]);

  const formItems: TimeOffRequestFormItems = useTimeOffRequestFormItems();
  /*********************************************************
   *  Handler functions for full calendar listeners
   **********************************************************/

  const handleEventClick = async (event: EventClickArg) => {
    if (event.event.extendedProps.etype === "assignment") {
      setActiveAssignmentID(event.event.extendedProps.mocked_id);
      setShowAssignmentModal(true);
    }

    if (event.event.extendedProps.etype === "time_off_request") {
      try {
        const response = await MiterAPI.time_off.requests.retrieve(event.event.extendedProps._id);

        if (response.error) {
          throw new Error(response.error);
        }
        setActiveTimeOffRequest(response);
        setShowTimeOffRequestModal(true);
      } catch (e) {
        console.log("Error", e);
        Notifier.error("There was an error fetching the time off request. We're looking into it.");
      }
    }
  };

  // Called when you shift forward or backward in the calendar
  // Always ensure there are 45 days of assignments on either side of the active view
  const handleDatesSet = (info: DatesSetArg) => {
    // Get the user-facing date range
    const viewStartDt = DateTime.fromJSDate(info.start);
    const viewEndDt = DateTime.fromJSDate(info.end);

    setActiveViewStart(viewStartDt);
    setActiveViewEnd(viewEndDt);

    // Determine whether we've queried for a similar date range
    const startDiff = viewStartDt.diff(lastQueriedStart, "days").toObject().days;
    const endDiff = viewEndDt.diff(lastQueriedEnd, "days").toObject().days;

    // If there are fewer than 15 days of assignments on either side of the view range, requery
    if ((startDiff && startDiff < 15) || (endDiff && endDiff > -15)) {
      const s = viewStartDt.minus({ days: 45 });
      const e = viewEndDt.plus({ days: 45 });
      setLastQueriedStart(s);
      setLastQueriedEnd(e);
      const arr = [s.toSeconds(), e.toSeconds()];
      fetchScheduleEvents({ starts_at: arr, ends_at: arr });
    }
  };

  // Called for any time an empty time block is selected
  const handleTimeblockSelect = (info: DateSelectArg) => {
    let start = DateTime.fromJSDate(info.start);
    let end = DateTime.fromJSDate(info.end);

    if (["customTimelineWeek", "dayGridMonth"].includes(activeView)) {
      start = start.plus({ hours: 9 });
      end = start.plus({ hours: 1 });
    }

    const id = info.resource?._resource.id;

    const teamMember = teamMembers.find((tm) => tm._id === id);
    const job = lookupJob(id);

    setInitialStartsAt(start);
    setInitialEndsAt(end);

    if (teamMember) setInitialTeamMember(teamMember);
    if (job) setInitialJob(job);

    setShowAssignmentModal(true);
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const handleEventAdjustment = async (info: EventResizeDoneArg | EventDropArg) => {
    setInfo(info);
    // @ts-expect-error fix me
    const found = returnAssignmentFromArray(scheduleEvents, info.event.id);
    if (found?.recurrence_config) {
      setShowRecurringTypeModalWrapper(true);
    } else {
      await submitInfo();
    }
  };

  function isEventDropArg(eventType: EventResizeDoneArg | EventDropArg): eventType is EventDropArg {
    return (eventType as EventDropArg).newResource !== undefined;
  }

  const schedulingPrepareForBackend = async () => {
    if (!info) return;
    // @ts-expect-error fix me
    const found = returnAssignmentFromArray(scheduleEvents, info.event.id);
    const params: Partial<Assignment> = {
      ...found,
    };

    if (isEventDropArg(info) && info.newResource) {
      const newResources = info.event.getResources();

      if (resourceType === "team_member") {
        params.team_members = newResources
          .filter((r) => r && r.extendedProps.type === "team_member")
          .map((r) => r._resource.id);
      } else if (info.newResource._resource.extendedProps.type === "job") {
        params.job = info.newResource._resource.id;
      }
    }

    if (info.event.start) {
      params.starts_at = DateTime.fromJSDate(info.event.start).toSeconds();
    }
    if (info.event.end) {
      params.ends_at = DateTime.fromJSDate(info.event.end).toSeconds();
    }
    const toSend: SchedulingUpdateParams = {
      data: params,
      previous_starts_at: DateTime.fromJSDate(info.oldEvent.start!).toSeconds(),
      previous_ends_at: DateTime.fromJSDate(info.oldEvent.end!).toSeconds(),
    };

    if (found?.recurrence_config) {
      setShowRecurringTypeModalWrapper(true);
      toSend.data.recurrence_config = found.recurrence_config;
      toSend.data.description = found.description;
      toSend.data.title = found.title;
      toSend.update_recurrence = editRecurrenceType;
    }
    return toSend;
  };

  const submitInfo = async () => {
    const toSend = await schedulingPrepareForBackend();

    // Update the assignment
    if (info && toSend) {
      await updateAssignment(info.event.id, toSend, info.revert);
    }
  };

  const handleDownloadSchedule = () => {
    if (!downloading) return;

    // Wait for the calendar to expand
    setTimeout(() => {
      // If the calendar is in the day/week view, don't show the header

      const item =
        activeView === "dayGridMonth"
          ? calendarContainerRef.current
          : activeView === "customListMonth"
          ? (calendarContainerRef.current as HTMLElement)?.getElementsByClassName("fc-scroller")[0]
          : (calendarContainerRef.current as HTMLElement)?.getElementsByClassName("fc-scrollgrid")[0];

      // Generate the screenshot
      html2canvas(item as HTMLElement, {
        // Clone the element so that we can modify the element without affecting the UI
        onclone: (clonedDoc) => {
          if (activeView === "dayGridMonth") {
            const clonedItem = clonedDoc.getElementsByClassName("fc")[0];
            (clonedItem as HTMLElement).style["max-height"] = "unset";
          } else if (activeView === "customListMonth") {
            const clonedItem = clonedDoc.getElementsByClassName("fc-scroller")[0];
            (clonedItem as HTMLElement).style["max-height"] = "unset";
          } else {
            const clonedItem = clonedDoc.getElementsByClassName("fc-scrollgrid")[0];

            // Removes the scroll bar so that we can take a screenshot of the expanded page
            (clonedItem as HTMLElement).style["table-layout"] = "unset";
          }
        },
      }).then((canvas) => {
        const today = DateTime.fromJSDate(calendarRef.current?.getApi!().view!.currentStart || new Date());
        const start = DateTime.fromJSDate(calendarRef.current?.getApi().view.activeStart || new Date());
        const end = DateTime.fromJSDate(calendarRef.current?.getApi().view.activeEnd || new Date()).minus({
          days: 1,
        });

        let filename = "";

        if (activeView === "dayGridMonth") {
          filename = `Schedule (${today.toFormat("LLL yyyy")}).${downloadFormat}`;
        } else if (activeView === "customTimelineWeek") {
          filename = `Schedule (${start.toFormat("LLL dd")} - ${end.toFormat(
            "LLL dd, yyyy"
          )}).${downloadFormat}`;
        } else if (activeView === "customResourceTimeGridDay") {
          filename = `Schedule (${today.toFormat("LLL dd, yyyy")}).${downloadFormat}`;
        } else if (activeView === "resourceTimelineMonth") {
          filename = `Schedule (${today.toFormat("LLL dd, yyyy")}).${downloadFormat}`;
        }

        if (downloadFormat === "pdf") {
          let pdf = new jsPDF();

          let width = canvas.width;
          let height = canvas.height;

          // I have to divide the height and width by 2, otherwise the generated image/pdf get's squished
          if (activeView === "customListMonth") {
            width = width / 2;
            height = height / 2;
          }

          //set the orientation
          if (width > height) {
            pdf = new jsPDF("l", "px", [width, height]);
          } else {
            pdf = new jsPDF("p", "px", [height, width]);
          }
          //then we get the dimensions from the 'pdf' file itself
          width = pdf.internal.pageSize.getWidth();
          height = pdf.internal.pageSize.getHeight();

          pdf.addImage(canvas, "PNG", 0, 0, width, height);
          pdf.save(filename);
        }

        if (downloadFormat === "png") {
          saveAs(canvas.toDataURL(), filename);
        }
      });

      setDownloading(false);
      setShowDownloadScheduleModal(false);
      Notifier.success("Schedule downloaded!");
    }, 1000);
  };

  const handleHideModal = () => {
    setShowAssignmentModal(false);
    setActiveAssignmentID(undefined);
    setInitialStartsAt(undefined);
    setInitialEndsAt(undefined);
    setInitialTeamMember(undefined);
    setInitialJob(undefined);

    setActiveTimeOffRequest(undefined);
    setShowTimeOffRequestModal(false);
  };

  const handleAssignmentSubmit = () => {
    handleHideModal();
    fetchScheduleEvents();
  };

  const handleUpdateResourceType = async (resourceType: "job" | "team_member") => {
    setResourceType(resourceType);
  };

  /*********************************************************
   *  API/Backend functions
   **********************************************************/
  const updateAssignment = async (
    id: string,
    data: SchedulingUpdateParams,
    revert: EventChangeArg["revert"]
  ): Promise<void> => {
    if (!id) return;

    try {
      // @ts-expect-error fix me
      const response = await MiterAPI.assignments.update(id, data);
      if (response.error) throw Error(response.error);

      handleAssignmentSubmit();
      setShowRecurringTypeModalWrapper(false);
      Notifier.success("Assignment successfully updated.");
    } catch (e: $TSFixMe) {
      revert();
      console.log("Error updating the assignment:", e);
      Notifier.error(e.message);
    }
    setInfo(undefined);
  };

  /*********************************************************
   *  Render functions
   **********************************************************/

  const renderResourceLabel = (resourceLabel) => {
    const hasCrews = !!resourceLabel.resource._resource.extendedProps.crews;
    return (
      <div className={styles["scheduling-resource"]}>
        <h3 className="no-margin">{resourceLabel.resource._resource.title}</h3>
        {resourceType === "team_member" && (
          <>
            <p style={{ marginTop: hasCrews ? 7 : 0 }}>
              {resourceLabel.resource._resource.extendedProps.subhead}
            </p>
            <p className="color-gray" style={{ marginTop: 0, marginBottom: 10 }}>
              {resourceLabel.resource._resource.extendedProps.crews}
            </p>
            {resourceLabel.resource._resource.extendedProps.totalHours != null && (
              <div style={{ marginTop: 7, opacity: 0.7 }}>
                {resourceLabel.resource._resource.extendedProps.totalHours} hours
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderSlotLabel = (resourceLabel) => {
    return <div className={`resource-select ${activeView}`}>{resourceLabel.text}</div>;
  };

  const renderFilters = () => {
    return (
      <div className={styles["filters-bar"]}>
        Filters
        <Button
          className={"button-text"}
          onClick={handleFilterClick}
          style={{ position: "absolute", top: -20, right: 5 }}
        >
          <X size={16} />
        </Button>
        <div className="vertical-spacer-small" />
        <div style={{ marginBottom: 10 }}>
          <Select
            name="department"
            options={departmentOptions}
            width="100%"
            height="32px"
            onChange={(options) => {
              if (!options) setDepartmentFilter([]);
              setDepartmentFilter(options?.filter((o) => "value" in o)?.map((option) => option.value) || []);
            }}
            isClearable={true}
            menuPlacement={"bottom"}
            placeholder={"Department"}
            isMulti={true}
            defaultValue={departmentOptions.filter((option) => departmentFilter.includes(option.value))}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <Select
            name="crew"
            options={crewOptions}
            width="100%"
            height="32px"
            onChange={(options) => {
              if (!options) setCrewFilter([]);
              setCrewFilter(options?.filter((o) => "value" in o)?.map((option) => option.value) || []);
            }}
            isClearable={true}
            menuPlacement={"bottom"}
            placeholder={"Crew"}
            isMulti={true}
            defaultValue={crewOptions.filter((option) => crewFilter.includes(option.value))}
          />
        </div>
      </div>
    );
  };

  const renderSearchField = () => {
    if (!isCompanyWide) return <></>;

    const placeholder = `Filter ${resourceType === "team_member" ? "team members" : "jobs"}`;

    return (
      <div id="filters-container" className="flex space-between width-100-percent">
        <input
          type="text"
          className="form2-text"
          placeholder={placeholder}
          onChange={(e) => setResourceFilter(e.target.value)}
        />
        <Popover
          isOpen={showFilters}
          positions={["right"]}
          containerStyle={{ zIndex: "5", top: "50px", left: "32px" }}
          content={renderFilters()}
          // onClickOutside={() => setShowFilters(false)}
          parentElement={document.getElementById("filters-container") || undefined}
        >
          <button
            className="button-1"
            onClick={handleFilterClick}
            style={{ height: 32, marginTop: 0, marginBottom: 0 }}
          >
            <Funnel weight="bold" style={{ marginBottom: -2 }} />
          </button>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    setSearchField(renderSearchField());
  }, [resourceType, activeView, showFilters, crewFilter, departmentFilter]);

  const renderJobButton = () => {
    return (
      <>
        <FaTasks className={styles["scheduling-resource-change-icon"]} /> Jobs
      </>
    );
  };

  const renderTeamMemberButton = () => {
    return (
      <>
        <FaUsers className={styles["scheduling-resource-change-icon"]} /> Team
      </>
    );
  };

  const customButtons: { [x: string]: CustomButtonInput } = {
    tmView: {
      // @ts-expect-error full calendar string type
      text: renderTeamMemberButton(),
      click: () => handleUpdateResourceType("team_member"),
      hint: "Team view",
    },
    jobView: {
      // @ts-expect-error full calendar string type
      text: renderJobButton(),
      click: () => handleUpdateResourceType("job"),
      hint: "Job view",
    },
    timelineView: {
      text: "Timeline",
      click: () => calendarRef.current?.getApi().changeView("resourceTimelineMonth"),
    },
  };

  const buildHeaderToolbar = () => {
    const canCreate = can("assignments:create");
    const canSettings = can("assignments:settings");

    if (activeView === "dayGridMonth") {
      return {
        start: "today,prev,next,title",
        end: `customResourceTimeGridDay,customTimelineWeek${
          isCompanyWide ? ",dayGridMonth,customListMonth" : ",customListMonth"
        } downloadSchedule${canSettings ? " settings" : ""}${canCreate ? " createAssignment" : ""}`,
      };
    } else if (activeView === "customListMonth") {
      return {
        start: "today,prev,next,title",
        end: `customResourceTimeGridDay,customTimelineWeek${
          isCompanyWide ? ",dayGridMonth,customListMonth" : ",customListMonth"
        } downloadSchedule${canSettings ? " settings" : ""}${canCreate ? " createAssignment" : ""}`,
      };
    } else {
      return {
        start: "today,prev,next,title",
        end: `changeResource${resourceType === "team_member" ? "Active" : ""}TeamMember,changeResource${
          resourceType === "job" ? "Active" : ""
        }Job customResourceTimeGridDay,customTimelineWeek${
          isCompanyWide ? ",dayGridMonth,customListMonth" : ",customListMonth"
        } downloadSchedule${canSettings ? " settings" : ""}${canCreate ? " createAssignment" : ""}`,
      };
    }
  };

  const cancelModal = () => {
    info?.revert();
    setShowRecurringTypeModalWrapper(false);
  };

  const handleViewChange = (view) => {
    setActiveView(view?.view.type);
  };

  const handleFilterClick = () => {
    setShowFilters(!showFilters);
  };

  const buildCustomButtons = () => {
    const buttons: Record<string, $TSFixMe> = {
      changeResourceJob: customButtons.tmView!,
      changeResourceActiveJob: customButtons.tmView!,
      changeResourceActiveTeamMember: customButtons.jobView!,
      changeResourceTeamMember: customButtons.jobView!,
      downloadSchedule: {
        text: renderDownloadScheduleButton() as $TSFixMe,
        hint: "Download schedule",
        click: () => setShowDownloadScheduleModal(true),
      },
      settings: {
        text: (<Wrench style={{ marginBottom: -2 }} weight="bold" size={16} />) as $TSFixMe,
        hint: "Settings",
        click: () => navigate("/scheduling/settings"),
      },
    };

    if (can("assignments:create")) {
      buttons.createAssignment = {
        text: "+ new",
        click: () => setShowAssignmentModal(true),
        hint: "Create new assignment",
      };
    }

    return buttons;
  };

  const renderDownloadScheduleButton = () => {
    return (
      <>
        {downloading ? (
          <Loader className="button button-fc" />
        ) : (
          <FaPrint className={styles["scheduling-resource-change-icon"]} />
        )}{" "}
        Download
      </>
    );
  };

  const renderDownloadScheduleModal = () => {
    const resourceName = pluralize(resourceType.replace("_", " "));

    return (
      <BasicModal
        headerText={"Download schedule"}
        button1Text={"Cancel"}
        button1Action={() => setShowDownloadScheduleModal(false)}
        button2Text={"Download"}
        button2Action={() => setDownloading(true)}
        loading={downloading}
      >
        <Label label={"Customize schedule"} style={{ marginTop: 0, marginBottom: 7 }} />
        <div
          className="checkbox-input-wrapper flex radio"
          style={{ alignItems: "flex-start", margin: 0, padding: 0 }}
        >
          <input
            type="checkbox"
            defaultChecked={true}
            style={{ marginRight: 10, marginTop: 4 }}
            onChange={(e) => {
              setShowJobsWithAssignments(e.target.checked);
              setShowTMsWithAssignments(e.target.checked);
            }}
          />
          <span>Only show {resourceName} with assignments in the seleted range.</span>
        </div>
        <div className="format-select-wrapper">
          <Label label={"Download format"} style={{ marginTop: 20, marginBottom: 7 }} />
          <div
            className="checkbox-input-wrapper flex radio"
            style={{ alignItems: "flex-start", margin: 0, padding: 0 }}
          >
            <input
              name="format"
              type="radio"
              value="pdf"
              style={{ marginRight: 10, marginTop: 4 }}
              onChange={(e) => setDownloadFormat(e.target.value as "pdf")}
              defaultChecked={downloadFormat === "pdf"}
            />
            <div className="checkbox-text radio-text">
              <span>PDF</span>
            </div>
          </div>
          <div
            className="checkbox-input-wrapper flex radio"
            style={{ alignItems: "flex-start", margin: 0, padding: 0 }}
          >
            <input
              name="format"
              type="radio"
              value="png"
              style={{ marginRight: 10, marginTop: 4 }}
              onChange={(e) => setDownloadFormat(e.target.value as "png")}
              defaultChecked={downloadFormat === "png"}
            />
            <div className="checkbox-text radio-text">
              <span>PNG</span>
            </div>
          </div>
        </div>
      </BasicModal>
    );
  };

  const renderListEventContent = ({ event }: EventContentArg) => {
    const noAddressOrJobOrEquipment =
      !event.extendedProps.address && !event.extendedProps.job_object && !event.extendedProps.equipment_names;

    return (
      <div
        className={styles["list-event"]}
        style={
          noAddressOrJobOrEquipment
            ? { marginTop: -7, marginBottom: -12 }
            : { marginTop: -7, marginBottom: -6 }
        }
      >
        <div>
          <div className={styles["list-event-title"]}>{event.title}</div>
          <div className={styles["list-event-job"]}>{event.extendedProps?.job_name}</div>
          <div className={styles["list-event-equipment"]}>{event.extendedProps?.equipment_names}</div>
        </div>
        <div className={styles["list-event-attributes"]}>
          <div className={styles["list-event-assignees"]}>
            <Users weight="fill" color="#AAA" style={{ marginRight: 5, marginBottom: -3 }} />
            <span className={styles["list-event-attribute-text"]}>
              {event.extendedProps?.assignee_count || 0}
            </span>
          </div>
          {event.extendedProps?.address && (
            <div className={styles["list-event-address"]}>
              <MapPin weight="fill" color="#AAA" style={{ marginRight: 5, marginBottom: -3 }} />
              <span className={styles["list-event-attribute-text"]}>{event.extendedProps.address}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {showDownloadScheduleModal && renderDownloadScheduleModal()}
      {showAssignmentModal && (
        <AssignmentModal
          initialMode={activeAssignmentID ? "read" : "create"}
          onFinish={handleAssignmentSubmit}
          onHide={handleHideModal}
          assignmentID={activeAssignmentID}
          initialStartsAt={initialStartsAt}
          initialEndsAt={initialEndsAt}
          initialTeamMember={initialTeamMember}
          initialJob={initialJob}
          editRecurrenceType={editRecurrenceType}
          setEditRecurrenceType={setEditRecurrenceType}
          showRecurringTypeModal={showRecurringTypeModal}
          setShowRecurringTypeModalWrapper={setShowRecurringTypeModalWrapper}
          scheduleView={activeView}
        />
      )}
      {!showAssignmentModal && showRecurringTypeModal && (
        <BasicModal
          headerText={`Edit recurring event`}
          button2Action={submitInfo}
          button2Text={"Update"}
          button1Action={cancelModal}
          button1Text="Cancel"
        >
          <Formblock
            defaultValue={editRecurrenceType}
            onChange={(e) => {
              setEditRecurrenceType(e.target.value);
            }}
            type="radio"
            className="flow"
            name="edit_recurrence_type"
            editing={true}
            options={initialEditRecurrenceOptions}
          />
        </BasicModal>
      )}
      {showTimeOffRequestModal && activeTimeOffRequest && (
        <TimeOffRequestModal
          timeOffRequest={activeTimeOffRequest || undefined}
          formItems={formItems}
          // @ts-expect-error fix me
          can={can}
          cannot={cannot}
          onHide={handleHideModal}
          onFinish={handleAssignmentSubmit}
        ></TimeOffRequestModal>
      )}
      <div ref={calendarContainerRef as LegacyRef<HTMLDivElement> | undefined}>
        <FullCalendar
          ref={calendarRef}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          customButtons={buildCustomButtons()}
          // @ts-expect-error Not sure
          viewClassNames={handleViewChange}
          height={downloading ? "auto" : undefined}
          selectable={true}
          editable={false}
          plugins={[
            listPlugin,
            resourceTimelinePlugin,
            resourceTimeGridPlugin,
            scrollGridPlugin,
            dayGridPlugin,
            interactionPlugin,
            adaptivePlugin,
          ]}
          resources={filteredResources}
          resourceAreaWidth={250}
          initialView={DEFAULT_VIEW}
          //  Typescript throws an error but this works fine (https://github.com/fullcalendar/fullcalendar/issues/5482)
          resourceOrder={null as $TSFixMe}
          resourceLabelContent={renderResourceLabel}
          events={events}
          scrollTimeReset={false}
          eventBorderColor="#F7F8FD"
          headerToolbar={buildHeaderToolbar()}
          slotLabelContent={renderSlotLabel}
          views={{
            customTimelineWeek: {
              type: "resourceTimeline",
              duration: { week: 1 },
              slotDuration: { days: 1 },
              dateAlignment: "week",
              dayCount: 7,
              slotLabelFormat: [{ weekday: "short", day: "numeric", month: "short" }],
              buttonText: "week",
              selectable: true,
              eventTimeFormat: {
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
            },
            customResourceTimeGridDay: {
              type: "resourceTimeGrid",
              dayMinWidth: 200,
              slotEventOverlap: false,
              allDaySlot: true,
              buttonText: "day",
              nowIndicator: true,
              selectable: true,
            },
            resourceTimelineMonth: {
              type: "resourceTimelineMonth",
              resourceGroupField: resourceType,
              slotEventOverlap: false,
              slotLabelClassNames: "testingthis",
              allDaySlot: false,
              buttonText: "timeline",
              nowIndicator: true,
              slotLabelFormat: {
                weekday: "short",
                month: "short",
                day: "2-digit",
              },
              selectable: true,
            },
            customListMonth: {
              type: "list",
              duration: { month: 1 },
              slotDuration: { days: 1 },
              dateAlignment: "month",
              dayCount: 1,
              buttonText: "List",
              selectable: true,
              eventTimeFormat: {
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              listDaySideFormat: false,
              listDayFormat: {
                month: "short",
                year: "numeric",
                day: "numeric",
                weekday: "short",
              },
              eventContent: renderListEventContent,
            },
          }}
          viewDidMount={({ el, view }) => {
            const topLeftCorner = el.querySelector(".fc-timegrid-axis-frame");
            if (topLeftCorner) {
              const topLeftRoot = createRoot(topLeftCorner);
              topLeftRoot.render(
                <div className="fc-datagrid-cell-cushion fc-scrollgrid-sync-inner">
                  <div className="fc-datagrid-cell-main">{searchField}</div>
                </div>
              );
            }

            const searchFieldContainer =
              calendarContainerRef?.current?.querySelectorAll(".fc-toolbar-chunk")?.[1];
            const searchRoot = searchFieldContainer ? createRoot(searchFieldContainer) : undefined;

            if (view.type === "customListMonth") {
              // Scroll to current day
              calendarContainerRef
                .current!.querySelector(`[data-date='${DateTime.now().toISODate()}'`)
                ?.scrollIntoView();

              // Add a search field to the header
              searchRoot!.render(
                <div>
                  <input
                    type="text"
                    className="form2-text"
                    placeholder={"Search"}
                    onChange={(e) => setEventsFilter(e.target.value)}
                    style={{ width: 280, marginRight: -50, height: 28 }}
                  />
                </div>
              );
            } else if (searchRoot) {
              // Remove the search field from the header
              searchRoot.unmount();
            }
          }}
          resourceAreaColumns={[
            {
              field: "title",
              width: 200,
              headerContent: searchField,
            },
          ]}
          eventClick={handleEventClick}
          select={handleTimeblockSelect}
          datesSet={handleDatesSet}
        />
      </div>
    </>
  );
};

export default SchedulingCalendar;
