import { AggregatedTeamMember, BankAccount, MiterAPI, MiterFilterArray } from "dashboard/miter";
import { useEffect, useState } from "react";
import { Notifier } from "ui";
import { NetPaySplit } from "./ManageNetPaySplits";
import { CheckNetPaySplit, CheckNetPaySplitCreationParams } from "../../../backend/utils/check/check-types";
import ObjectID from "bson-objectid";
import { TeamPortalUser } from "team-portal/utils/miter";

type FetchBankAccounts = {
  result: BankAccount[];
  loading: boolean;
  bankAccountsLoaded: boolean;
  error: unknown | null;
  fetchBankAccounts: () => void;
};

export const useFetchBankAccounts = (params: {
  companyId: string;
  refreshCount: number;
  teamMemberId?: string;
  includeBankAccountsAddedToCheckOnly?: boolean;
}): FetchBankAccounts => {
  const { companyId, refreshCount, teamMemberId, includeBankAccountsAddedToCheckOnly } = params;
  const [result, setResult] = useState<BankAccount[]>([]);
  const [loading, setLoading] = useState(false);
  const [bankAccountsLoaded, setBankAccountsLoaded] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchBankAccounts = async () => {
    const filter: MiterFilterArray = [
      { field: "company_id", value: companyId },
      { field: "archived", type: "boolean", value: false },
    ];

    if (teamMemberId) {
      filter.push({ field: "team_member_id", value: teamMemberId });
      filter.push({ field: "external_financial_account_type", value: "team_member" });
    } else {
      filter.push({ field: "external_financial_account_type", value: "company" });

      // remove bank accounts added through Check, which are payroll only
      filter.push({ field: "type", comparisonType: "ne", value: "check_originated_bank_account" });
    }

    if (includeBankAccountsAddedToCheckOnly) {
      filter.push({ field: "check_id", comparisonType: "exists", value: true });
    }

    setLoading(true);
    try {
      const res = await MiterAPI.bank_accounts.forage({
        filter,
      });
      setResult(res.data);
    } catch (e: $TSFixMe) {
      Notifier.error("Error getting bank accounts.");
      setResult([]);
      setError(e);
    }

    setLoading(false);
    setBankAccountsLoaded(true);
  };

  useEffect(() => {
    fetchBankAccounts();
  }, [refreshCount]);

  return {
    result,
    loading,
    bankAccountsLoaded,
    error,
    fetchBankAccounts,
  };
};

const convertCheckNetPaySplit = (
  checkNetPaySplit: CheckNetPaySplit,
  existingBankAccounts: BankAccount[]
): NetPaySplit[] => {
  return checkNetPaySplit.splits.map((split) => {
    const matchingBankAccount = existingBankAccounts.find(
      (bankAccount) => bankAccount.check_id === split.bank_account
    );

    const { percentage, amount } = split;
    return {
      percentage: percentage ? parseFloat(percentage) : undefined,
      dollarAmount: amount ? parseFloat(amount) : undefined,
      bankAccountId: matchingBankAccount?._id,
      _id: new ObjectID().toHexString(),
    };
  });
};

export const convertMiterNetPaySplitToCheckCreationParams = (
  netPaySplits: NetPaySplit[],
  teamMember: AggregatedTeamMember | TeamPortalUser,
  existingBankAccounts: BankAccount[] // required for lookup
): CheckNetPaySplitCreationParams => {
  const checkEmployeeId = teamMember.employment_type === "employee" ? teamMember.check_id : undefined;
  const checkContractorId = teamMember.employment_type === "contractor" ? teamMember.check_id : undefined;

  const params: CheckNetPaySplitCreationParams = {
    employee: checkEmployeeId ?? undefined,
    contractor: checkContractorId ?? undefined,
    splits: netPaySplits.map((split, index) => {
      return {
        bank_account: existingBankAccounts.find((bank) => bank._id === split.bankAccountId)?.check_id || "",
        amount: split.dollarAmount ?? null,
        percentage: split.percentage ?? null,
        priority: index + 1,
      };
    }),
    is_default: true, // always new default
  };

  return params;
};

export const validateMiterNetPaySplitForCheckSubmission = (netPaySplits: NetPaySplit[]): boolean => {
  if (netPaySplits.length === 0) return false; // must have at least one split

  // every split must have a bank account
  const hasBankAccount = netPaySplits.every((split) => split.bankAccountId);

  // every split must either have a percentage or a dollar amount
  const hasPercentage = netPaySplits.every(
    (split) =>
      (split.percentage !== undefined && split.percentage > 0 && split.percentage < 100) ||
      (!split.percentage && !split.dollarAmount)
  );

  // confirm that all percentages are not greater than 100
  if (hasPercentage) {
    const totalPercentage = netPaySplits.reduce((acc, split) => {
      return acc + (split.percentage || 0);
    }, 0);

    if (totalPercentage > 100) return false;
  }

  const hasDollarAmount = netPaySplits.every(
    (split) =>
      (split.dollarAmount !== undefined && split.dollarAmount > 0) ||
      (!split.percentage && !split.dollarAmount)
  );

  return hasBankAccount && (hasPercentage || hasDollarAmount);
};

export const fetchCurrentNetPaySplitFromCheck = async (
  checkNetPaySplitId: string,
  existingBankAccounts: BankAccount[] // required for lookup
): Promise<NetPaySplit[]> => {
  try {
    const response = await MiterAPI.check.net_pay_splits.retrieve(checkNetPaySplitId);
    if (response.error) throw new Error(response.error);

    return convertCheckNetPaySplit(response, existingBankAccounts);
  } catch (err: $TSFixMe) {
    Notifier.error("Error fetching net pay splits");
    return [];
  }
};
