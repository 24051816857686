import { MiterAPI } from "dashboard/miter";
import { useState, useEffect } from "react";
import { Notifier } from "ui";
import { IntacctConfigObjectType } from "backend/services/intacct/intacct-types";
import {
  IntacctConfigObjects,
  GetIntacctObjectsResponse,
} from "backend/controllers/integrations/integrations-controller";

type FetchIntacctConfigData = {
  intacctData: IntacctConfigObjects | undefined;
  loading: boolean;
  error: unknown | null;
};

export const useFetchIntacctObjects = (p: {
  integrationConnectionId: string | undefined;
  objectTypes: IntacctConfigObjectType[];
  disabled?: boolean;
  onData?: (data: IntacctConfigObjects) => void | Promise<void>;
}): FetchIntacctConfigData => {
  const { integrationConnectionId, objectTypes } = p;
  const [intacctData, setIntacctData] = useState<IntacctConfigObjects>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    if (!integrationConnectionId || p.disabled) return;
    const getData = async () => {
      setLoading(true);
      try {
        const res = await MiterAPI.integrations.get_intacct_objects(integrationConnectionId, objectTypes);
        if (res.error) throw new Error(res.error);

        const cleaned = Object.fromEntries(
          Object.entries(res as GetIntacctObjectsResponse).filter(([key, value]) => {
            if ("error" in value) {
              Notifier.error(`Error fetching Intacct ${key}: ${value.error}`);
              return false;
            }
            return true;
          })
        ) as IntacctConfigObjects;

        setIntacctData(cleaned);
        p.onData?.(cleaned);
      } catch (e: $TSFixMe) {
        Notifier.error("Error fetching data from Intacct.");
        console.log(e);
        setError(e);
      }
      setLoading(false);
    };
    getData();
  }, [integrationConnectionId, JSON.stringify(objectTypes), p.disabled]);

  return {
    intacctData,
    loading,
    error,
  };
};
