import { Option } from "ui/form/Input";
import { CreateOrUpdateExpenseReimbursementParam } from "backend/services/expenses/expense-reimbursement-service";
import { Notifier, STILES_COMPANY_ID, toPercent, withValue } from "dashboard/utils";
import {
  ExpenseReimbursementCreationMethod,
  ExpenseReimbursementPayoutMethod,
  ExpenseReimbursementStatus,
  MileageDetail,
} from "backend/models/expense-reimbursement";
import { capitalize } from "lodash";
import {
  Activity,
  AggregatedExpenseReimbursement,
  BankAccount,
  Company,
  CostType,
  Department,
  Expense,
  ExpenseReimbursement,
  ExpenseReimbursementCategory,
  Job,
  LedgerAccount,
  MiterAPI,
  MiterCardTransaction,
  PlaidBankAccount,
  RawBankAccount,
  CheckOriginatedBankAccount,
  Stripe,
  StripeAccountResponse,
  TeamMember,
  PlaidBackendAccountBase,
} from "dashboard/miter";
import { BulkSelectOptionValue } from "ui/bulk-actions/BulkSelector";
import pluralize from "pluralize";
import { ExpenseTypeEnum } from "backend/models/expense";
import {
  UseOptionOpts,
  getOptions,
  ledgerAccountOptionsMapCallback,
  useActiveCompanyId,
  useActivityOptionsMap,
  useCardTransactionCategoryName,
  useCostTypeOptions,
  useDepartmentOptions,
  useExpenseReimbursementCategoryName,
  useExpenseReimbursementCategoryOptions,
  useJobOptions,
  useLedgerAccountLabeler,
  useLedgerAccounts,
  useLookupActivity,
  useLookupCostType,
  useLookupDepartment,
  useLookupExpenseReimbursementCategories,
  useLookupJob,
  useLookupLedgerAccount,
  useLookupTeam,
  useStripeConnectedAccount,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { toDollarFormat } from "miter-utils";
import React, { useCallback, useEffect, useMemo } from "react";
import { ExpensesTableRow } from "./CardTransactionsTable";
import { ColumnConfig } from "ui/table-v2/Table";
import { Assign } from "utility-types";
import { useApprovalGroupColumns } from "dashboard/utils/approvals";
import { isExpenseScoped, isReimbursementScoped } from "../activities/activityUtils";
import { SplitCardTransactionTableRow } from "./modals/SplitCardTransactionModal";
import { EditableCallbackParams, ValueFormatterParams } from "ag-grid-community";
import { MileageLocation } from "../../miter";
import { Button } from "ui";
import { PlaidAccount } from "react-plaid-link";
import { ExpenseReimbursementModalFormData } from "./modals/ExpenseReimbursementModalForm";
import { useExpenseAbilities } from "dashboard/hooks/abilities-hooks/useExpenseAbilities";
import { DraftReimbursement } from "dashboard/components/expense-reimbursements/BulkCreateExpenseReimbursementPageModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useExpenseReimbursementPolicies } from "dashboard/utils/policies/expense-reimbursement-policy-utils";
import { useDropzone } from "react-dropzone";
import { FilePickerFile } from "ui/form/FilePicker";
import { MapPin, Receipt } from "phosphor-react";
import { BPI_ONEROOF_COMPANY_ID } from "dashboard/utils/constants";
import { LookupAtomFunction } from "dashboard/atoms";
import { DraftLocation } from "dashboard/components/expense-reimbursements/StopBasedMileageEditor";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";
import { getPayrollStatus } from "../team-members/TeamUtils";
import { DateTime } from "luxon";
import { AbstractExpenseReimbursementRequest } from "backend/controllers/expenses/expense-reimbursement-controller";
import { isCostTypeExpenseManagementScoped } from "dashboard/components/cost-types/costTypeUtils";

export type SplitType = "amount" | "pct";

export type ACHFailureReason = {
  direction: "inbound" | "outbound"; // assumption: only one leg can be failed
  failureReason: string;
  bankAccountLast4: string | null;
  achStatus: Stripe.Treasury.InboundTransfer.Status | Stripe.Treasury.OutboundPayment.Status;
};

export const TaxableOptions = [
  {
    label: "Not taxable",
    value: false,
  },
  {
    label: "Is taxable",
    value: true,
  },
];

export const ONLY_PAYMENT_INFO_AND_JOB_COSTING_FIELDS_EDITABLE_STATUSES: ExpenseReimbursementStatus[] = [
  "approved",
  "processing",
  "paid",
];

export const ONLY_JOB_COSTING_FIELDS_EDITABLE_STATUSES: ExpenseReimbursementStatus[] = ["processing", "paid"];

export type BulkEditedExpense = Assign<
  Expense,
  {
    /** Editing props */
    team_member?: TeamMember | string;
    department?: Department | string;
    job?: Job | string;
    activity?: Activity | string;
    cost_type?: CostType | string;
    ledger_account?: LedgerAccount | string;
  }
>;

export type BulkEditedReimbursement = Assign<
  ExpenseReimbursement,
  {
    /** Editing props */
    team_member?: TeamMember | string;
    department?: Department | string;
    job?: Job | string;
    activity?: Activity | string;
    cost_type?: CostType | string;
    ledger_account?: LedgerAccount | string;
  }
>;

export const EXPENSE_ATTACHMENT_ACCEPTED_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

export const EXPENSE_ATTACHMENT_MAX_FILE_SIZE = 5242880;
export const EXPENSE_ATTACHMENT_MAX_NUMBER_OF_FILES_ALLOWED = 3;

const BulkCreateExpenseReimbursementFileUpload: React.FC<{
  localFiles: FilePickerFile[];
  uploadFiles: (files: FilePickerFile[]) => void;
  openFilePreview: (attachments: FilePickerFile[]) => void;
}> = ({ localFiles, uploadFiles, openFilePreview }) => {
  const { acceptedFiles, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxSize: EXPENSE_ATTACHMENT_MAX_FILE_SIZE,
    accept: EXPENSE_ATTACHMENT_ACCEPTED_FILE_TYPES,
    maxFiles: EXPENSE_ATTACHMENT_MAX_NUMBER_OF_FILES_ALLOWED,
    onDropRejected(fileRejections) {
      const hasFileTooLarge = fileRejections.find((file) =>
        file.errors.find((error) => error.code === "file-too-large")
      );

      if (hasFileTooLarge) {
        if (fileRejections.length === 1) {
          Notifier.error(
            `File is too large. Max size is ${`${EXPENSE_ATTACHMENT_MAX_FILE_SIZE / 1024 / 1024}MB`}.`
          );
        } else {
          Notifier.error(
            `One or more files are too large. Max size is ${`${
              EXPENSE_ATTACHMENT_MAX_FILE_SIZE / 1024 / 1024
            }MB`}.`
          );
        }

        return;
      }

      const hasInvalidFileType = fileRejections.find((file) =>
        file.errors.find((error) => error.code === "file-invalid-type")
      );

      if (hasInvalidFileType) {
        if (fileRejections.length === 1) {
          Notifier.error("File type is not allowed.");
        } else {
          Notifier.error("One or more files are not allowed.");
        }

        return;
      }

      const hasTooManyFiles = fileRejections.find((file) =>
        file.errors.find((error) => error.code === "too-many-files")
      );

      if (hasTooManyFiles) {
        Notifier.error(`You can only upload ${EXPENSE_ATTACHMENT_MAX_NUMBER_OF_FILES_ALLOWED} files.`);
        return;
      }
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const updatedFiles = localFiles.concat(acceptedFiles.map((file) => ({ blob: file })));
      uploadFiles(updatedFiles);
    }
  }, [acceptedFiles]);

  if (localFiles.length > 0)
    return (
      <div style={{ display: "flex", alignItems: "center" }} onClick={() => openFilePreview(localFiles)}>
        {<Receipt color="green" weight="duotone" size={20} />}
        <div style={{ marginLeft: 5 }}>({localFiles.length})</div>
      </div>
    );
  else {
    return (
      <div style={{ display: "flex", alignItems: "center" }} onClick={open}>
        Upload&nbsp;
        <Receipt color="red" weight="duotone" size={20} />
      </div>
    );
  }
};

export const cleanExpenseReimbursementFormData = (params: {
  formData: ExpenseReimbursementModalFormData;
  activeCompany: Company;
  mileageDetail?: MileageDetail;
  selectedCategory?: ExpenseReimbursementCategory;
  isCreation: boolean;
  statusToCreate?: "approved" | "unapproved"; // should be set if isCreation is true
  creationMethod?: ExpenseReimbursementCreationMethod; // should be set if isCreation is true
}): CreateOrUpdateExpenseReimbursementParam => {
  const {
    formData,
    activeCompany,
    mileageDetail,
    selectedCategory,
    statusToCreate,
    isCreation,
    creationMethod,
  } = params;

  const isMileage = (mileageDetail?.trip && mileageDetail.trip.length > 0) || mileageDetail?.mileage;

  if (!isMileage) {
    // there exists a FormBlock bug where disabled fields are not included in the formData object. This doesn't affect <Select/> elements.
    // Overwrite to the original. The only way these fields are locked is via category, so use the current value from the selected category.

    if (!formData.amount && selectedCategory?.amount?.is_locked) {
      formData.amount = selectedCategory?.amount?.value;
    }

    if (!formData.vendor_name && selectedCategory?.merchant_name?.is_locked) {
      formData.vendor_name = selectedCategory?.merchant_name?.id;
    }
  } else {
    // validate mileage information

    if (mileageDetail.trip) {
      // if mileage was manually entered, ignore
      if (
        !mileageDetail.mileage &&
        mileageDetail.trip.filter((stop) => stop.place_id != null && stop.description != null).length < 2
      ) {
        // error below will be caught + notified to user
        throw new Error("Trip must have at least two valid stops.");
      }
    }
  }

  const updateOrCreationParams: CreateOrUpdateExpenseReimbursementParam = {
    company_id: activeCompany._id,
    team_member_id: formData.team_member_id?.value,
    date: formData.date?.toISODate(),
    memo: formData.memo,
    vendor_name: formData.vendor_name,
    amount: Number(formData.amount),
    mileage_detail: isMileage ? mileageDetail : undefined,
    activity_id: formData?.activity_id === null ? null : formData?.activity_id?.value,
    job_id: formData?.job_id === null ? null : formData?.job_id?.value,
    cost_type_id: formData?.cost_type_id === null ? null : formData?.cost_type_id?.value,
    expense_account_id: formData.expense_account_id?.value || null,
    payout_method: formData.payout_method?.value,
    is_taxable: formData.is_taxable?.value || false,
    department_id: formData.department_id?.value || null,
    expense_reimbursement_category_id: formData.expense_reimbursement_category_id?.value || null,
    type: isMileage ? "mileage" : "out_of_pocket",
  };

  if (isCreation) {
    // if creation, set status to either unapproved or approved. TODO: move this logic to backend
    updateOrCreationParams.status = statusToCreate;
    updateOrCreationParams.creation_method = creationMethod;
  }

  return updateOrCreationParams;
};

export const cleanDraftReimbursementForCreation = (params: {
  draftReimbursement: DraftReimbursement;
  activeCompany: Company;
  mileageDetail?: MileageDetail;
  selectedCategory?: ExpenseReimbursementCategory;
  statusToCreate?: "approved" | "unapproved";
  isCreation: boolean;
  creationMethod?: ExpenseReimbursementCreationMethod; // should be set if isCreation is true
}): CreateOrUpdateExpenseReimbursementParam => {
  const {
    draftReimbursement,
    activeCompany,
    mileageDetail,
    statusToCreate,
    isCreation,
    creationMethod,
    selectedCategory,
  } = params;

  const isMileage = (mileageDetail?.trip && mileageDetail.trip.length > 0) || mileageDetail?.mileage;

  const updateOrCreationParams: CreateOrUpdateExpenseReimbursementParam = {
    company_id: activeCompany._id,
    team_member_id: draftReimbursement.team_member_id!, // non-null, enforced by form validation
    department_id: draftReimbursement.department_id,
    date: draftReimbursement.date,
    memo: draftReimbursement.memo,
    vendor_name: draftReimbursement.vendor_name || undefined,
    amount: Number(draftReimbursement.amount),
    mileage_detail: isMileage ? mileageDetail : undefined,
    activity_id: draftReimbursement.activity_id,
    job_id: draftReimbursement.job_id,
    cost_type_id: draftReimbursement.cost_type_id,
    expense_account_id: draftReimbursement.expense_account_id,
    payout_method: selectedCategory?.payout_method ?? undefined,
    is_taxable: selectedCategory?.is_taxable ?? false,
    expense_reimbursement_category_id: selectedCategory?._id,
    type: isMileage ? "mileage" : "out_of_pocket",
  };

  if (isCreation) {
    // if creation, set status to either unapproved or approved. TODO: move this logic to backend
    updateOrCreationParams.status = statusToCreate;
    updateOrCreationParams.creation_method = creationMethod;
  }

  return updateOrCreationParams;
};

export const archiveExpenseReimbursementRequests = async (selectedIds: string[]): Promise<void> => {
  const numSelectedIds = selectedIds.length;
  if (numSelectedIds === 0) return;

  try {
    const formattedSelected = selectedIds.map((selectedId) => {
      return {
        id: selectedId,
      };
    });

    const response = await MiterAPI.expense_reimbursements.archive(formattedSelected);
    if (response.error) {
      throw Error(response.error);
    }

    const numFailures = response.failures.length;
    if (numFailures >= numSelectedIds) {
      throw Error(response.failures[0]?.message);
    } else if (numFailures > 0) {
      Notifier.warning(
        `${numFailures > 1 ? "Some" : "An"} reimbursement${numFailures > 1 ? "s were" : " was"} not deleted.`
      );
    }

    Notifier.success(`Deleted ${pluralize("reimbursement", numSelectedIds)}.`);
  } catch (e: $TSFixMe) {
    Notifier.error(
      `There was an issue deleting the ${pluralize("reimbursement", numSelectedIds)}. ${e.message}`
    );
  }
};

export const updateExpenseReimbursementsStatuses = async (
  selectedIds: string[],
  status: ExpenseReimbursementStatus
): Promise<void> => {
  const numSelectedIds = selectedIds.length;
  if (numSelectedIds === 0) return;
  try {
    const formattedSelected = selectedIds.map((selectedId) => {
      return {
        id: selectedId,
        new_status: status,
      };
    });
    const response = await MiterAPI.expense_reimbursements.update_status(formattedSelected);
    if (response.error) {
      throw Error(response.error);
    }
    const numFailures = response.failures.length;
    if (numFailures > 0) {
      // TODO: implement FailureModal
      Notifier.warning(
        `${numFailures > 1 ? "Some" : "A"} reimbursement${numFailures > 1 ? "s were" : " was"} not ${status}.`
      );
    } else {
      Notifier.success(`${capitalize(status as string)} ${pluralize("reimbursement", numSelectedIds)}.`);
    }
  } catch (e) {
    console.error(e);
    Notifier.error(
      `There was an issue changing the status of the reimbursement${
        numSelectedIds > 1 ? "s." : "."
      } We're looking into it!`
    );
  }
};

export const submitExpensesForReimbursement = async (
  selectedIds: string[]
): Promise<{ successes: number }> => {
  const numSelectedIds = selectedIds.length;
  if (numSelectedIds === 0) return { successes: 0 };

  const formattedSelected: AbstractExpenseReimbursementRequest[] = selectedIds.map((selectedId) => {
    return {
      id: selectedId,
      new_status: "processing",
    };
  });

  const response = await MiterAPI.expense_reimbursements.update_status(formattedSelected);
  if (response.error) {
    throw Error(response.error);
  }
  const numFailures = response.failures.length;

  const numSuccessful = numSelectedIds - numFailures;

  const teamMemberFailureSet = new Set<string>();
  for (const failure of response.failures) {
    // make a list of all team members missing bank accounts
    if (failure.error_type === "team_member_no_bank_account_for_reimbursements") {
      // @ts-expect-error this is hacky - I'm reusing the error fields model architecture to pass the team member full name here
      const teamMemberName: string = failure.error_fields[0].name;
      teamMemberFailureSet.add(teamMemberName);
    } else {
      Notifier.warning(failure.message);
    }
  }

  if (teamMemberFailureSet.size > 0) {
    Notifier.warning(
      `${Array.from(teamMemberFailureSet).join(", ")} ${
        teamMemberFailureSet.size > 1 ? "have" : "has"
      } not set up a bank account for reimbursements yet.`
    );
  }

  if (numSuccessful > 0) {
    Notifier.success(
      `${numSuccessful} ${pluralize("reimbursement", numSuccessful)} now processing. ${
        numSuccessful > 1 ? "They" : "It"
      } will be sent to the ${pluralize("employee", numSuccessful)} in 2 business days over ACH.`
    );
  }

  return { successes: numSuccessful };
};

export const isMiterCardsAccountFullyActive = (account: StripeAccountResponse | null): boolean => {
  const miterCardsAccount = getMiterCardsAccount(account);
  return (
    miterCardsAccount != null &&
    Object.values(account?.account?.capabilities || {}).every((status) => status === "active") &&
    miterCardsAccount.financial_addresses.length > 0
  );
};

export const setSelectableGLAccountsForExpenses = async (
  newSelections: BulkSelectOptionValue[],
  removedSelections: BulkSelectOptionValue[]
): Promise<void> => {
  const promises = newSelections
    .map(async (id) => {
      try {
        await MiterAPI.ledger_accounts.update(id, { is_selectable_by_team_members: true });
      } catch (err) {
        console.error(`error setting is_selectable_by_team_members for ledger account ${id}`, err);
      }
    })
    .concat(
      removedSelections.map(async (id) => {
        try {
          await MiterAPI.ledger_accounts.update(id, { is_selectable_by_team_members: false });
        } catch (err) {
          console.error(`error setting is_selectable_by_team_members for ledger account ${id}`, err);
        }
      })
    );

  await Promise.allSettled(promises);
  Notifier.success(`Expense settings updated successfully.`);
};

export const getMiterCardsAccount = (
  account?: StripeAccountResponse | null
): Stripe.Treasury.FinancialAccount | undefined => {
  // these are typed in backend but are too deep on the file structure to import onto dashboard
  const miterCardsNicknameEvolve = "miter_card_funding";

  return account?.financial_accounts.find(
    // @ts-expect-error nickname not typed in Stripe library
    (fa: Stripe.Treasury.FinancialAccount) => fa.nickname === miterCardsNicknameEvolve
  );
};

export const getACHTransferFailureReasonFromStripeEnum = (
  failureCode:
    | Stripe.Treasury.InboundTransfer.FailureDetails.Code
    | Stripe.Treasury.OutboundPayment.ReturnedDetails.Code
    | undefined
): string => {
  if (!failureCode) return "";

  switch (failureCode) {
    case "account_closed":
      return "account closed";
    case "account_frozen":
      return "account frozen";
    case "bank_account_restricted":
      return "bank account restricted";
    case "bank_ownership_changed":
      return "bank ownership changed";
    case "debit_not_authorized":
      return "debit not authorized";
    case "declined":
      return "declined";
    case "incorrect_account_holder_address":
      return "incorrect account holder address";
    case "incorrect_account_holder_name":
      return "incorrect account holder name";
    case "incorrect_account_holder_tax_id":
      return "incorrect account holder tax id";
    case "insufficient_funds":
      return "insufficient funds";
    case "invalid_account_number":
      return "invalid account number";
    case "invalid_currency":
      return "invalid currency";
    case "no_account":
      return "account not found";
    case "other":
      return "";
  }
};

export const getCleanedExpenseType = (type: ExpenseTypeEnum | undefined): string | undefined => {
  if (!type) return;
  switch (type) {
    case "miter_card_transaction":
      return "Miter Card Purchase";
    case "third_party_card_transaction":
      return "Third Party Card Purchase";
    case "reimbursement":
      return "Reimbursement";
    case "imported_card_transaction":
      return "Imported Card Purchase";
  }
};

export const getACHFailureReasons = async (
  reimbursement: AggregatedExpenseReimbursement
): Promise<ACHFailureReason | undefined> => {
  if (!reimbursement?.ach_payout_information) return;

  const { ach_debit_id: inboundACHTransferId, ach_credit_id: outboundACHTransferId } =
    reimbursement.ach_payout_information;

  // inbound transfer was initiated
  if (inboundACHTransferId) {
    const inboundACHTransfer = await MiterAPI.banking.ach_transfers.get(inboundACHTransferId);

    // if the inbound failed, by definition the outbound hasn't failed (b/c it didn't happen). return reason here directly
    if (inboundACHTransfer.status === "failed" || inboundACHTransfer.status === "returned") {
      const inboundTransferObj = inboundACHTransfer.external_raw_data as Stripe.Treasury.InboundTransfer;
      const failureReason = inboundTransferObj.failure_details?.code;

      return {
        direction: inboundACHTransfer.direction,
        failureReason: getACHTransferFailureReasonFromStripeEnum(failureReason),
        bankAccountLast4: inboundTransferObj.origin_payment_method_details?.us_bank_account?.last4 ?? null,
        achStatus: inboundTransferObj.status,
      };
    }
  }

  // outbound transfer was initiated
  if (outboundACHTransferId) {
    const outboundACHTransfer = await MiterAPI.banking.ach_transfers.get(outboundACHTransferId);

    if (outboundACHTransfer.status === "failed" || outboundACHTransfer.status === "returned") {
      const ouboundPaymentObj = outboundACHTransfer.external_raw_data as Stripe.Treasury.OutboundPayment;
      const failureReason = ouboundPaymentObj.returned_details?.code;

      return {
        direction: outboundACHTransfer.direction,
        failureReason: getACHTransferFailureReasonFromStripeEnum(failureReason),
        bankAccountLast4:
          ouboundPaymentObj.destination_payment_method_details?.us_bank_account?.last4 ?? null,
        achStatus: ouboundPaymentObj.status,
      };
    }
  }
};

export const expenseTableColors = {
  // approval status
  approved: "green",
  unapproved: "yellow",
  denied: "red",

  // reimbursements only
  paid: "green",
  out_of_pocket: "light-blue",
  mileage: "light-purple",

  // purchase status
  pending: "yellow",
  closed: "light-blue",
  reversed: "grey", // Miter cards only
  declined: "red", // Miter cards only
};

export const glAccountSelectionOptions = (params: {
  activeCompanyId: string | null;
  departmentId?: string;
  lookupDepartment: LookupAtomFunction<Department>;
}): UseOptionOpts<LedgerAccount> => {
  const { activeCompanyId, departmentId, lookupDepartment } = params;
  // For Stiles and BPi: need to dedupe GL accounts based on the department
  if (departmentId && [STILES_COMPANY_ID, BPI_ONEROOF_COMPANY_ID].includes(activeCompanyId || "")) {
    const department = lookupDepartment(departmentId);

    let departmentIdentifier = department?.identifier;
    if (activeCompanyId === BPI_ONEROOF_COMPANY_ID) {
      // for BPi - don't use identifier, use first characters of the dept name
      departmentIdentifier = department?.name?.substring(0, 5);
    }

    if (!departmentIdentifier) {
      return {
        predicate: (la: LedgerAccount) => la.active && la.is_selectable_by_team_members,
      };
    }

    const departmentMatchesGLAccountExternalIdentifier = (la: LedgerAccount) => {
      return (
        la.active &&
        la.is_selectable_by_team_members &&
        (la.external_id ? la.external_id.startsWith(departmentIdentifier!) : true)
      );
    };

    return {
      predicate: departmentMatchesGLAccountExternalIdentifier,
    };
  }

  return {
    predicate: (la: LedgerAccount) => la.active && la.is_selectable_by_team_members,
  };
};

export function useCardTransactionsTableColDefs(): ColumnConfig<ExpensesTableRow>[] {
  const expenseAbilities = useExpenseAbilities();
  const teamMemberOptions = useTeamOptions({ predicate: expenseAbilities.teamPredicate("update") });
  const jobOptions = useJobOptions({ predicate: expenseAbilities.jobPredicate("update") });
  const jobHierarchyTableColumns = useJobHierarchyTableColumns<ExpensesTableRow>();

  const lookupTeam = useLookupTeam();
  const lookupJob = useLookupJob();

  const departmentOptions = useDepartmentOptions();
  const lookupDepartment = useLookupDepartment();

  const activityOptionsMappedByJob = useActivityOptionsMap({
    predicate: isExpenseScoped,
  });
  const lookupActivity = useLookupActivity();

  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });
  const lookupCostType = useLookupCostType();

  const accountLabeler = useLedgerAccountLabeler();

  const activeCompanyId = useActiveCompanyId();

  const ledgerAccounts = useLedgerAccounts();

  const lookupLedgerAccount = useLookupLedgerAccount();
  const approvalGroupColumns = useApprovalGroupColumns("expense");

  const categoryNamer = useCardTransactionCategoryName();

  return useMemo(() => {
    const columns: ColumnConfig<ExpensesTableRow>[] = [
      {
        headerName: "id",
        field: "_id",
        dataType: "string",
        hide: true,
        lockVisible: true,
      },
      {
        headerName: "Date",
        field: "date",
        dataType: "date",
        dateType: "iso",
        sort: "desc",
        enableRowGroup: true,
      },
      {
        headerName: "Amount",
        field: "amount",
        dataType: "number",
        valueFormatter: toDollarFormat,
        enableRowGroup: true,
        aggFunc: "sum",
      },
      {
        headerName: "Merchant name",
        field: "merchant_name",
        dataType: "string",
        enableRowGroup: true,
        minWidth: 200,
      },
      {
        headerName: "Approval status",
        field: "approval_status",
        dataType: "string",
        displayType: "badge",
        minWidth: 150,
        colors: expenseTableColors,
      },
      ...approvalGroupColumns,
      {
        headerName: "Purchase status",
        field: "purchase_status",
        dataType: "string",
        displayType: "badge",
        minWidth: 175,
        colors: expenseTableColors,
        headerTooltip: "A purchase may be pending for up to 5 days (longer for hotel and rental car holds).",
      },
      {
        headerName: "Purchase category",
        field: "category",
        dataType: "string",
        enableRowGroup: true,
      },
      {
        headerName: "Team member",
        field: "team_member",
        filterField: "team_member.full_name",
        sortField: "team_member.full_name",
        groupField: "team_member.full_name",
        dataType: "string",
        editable: (params) => expenseAbilities.can("update", params.data),
        editorType: "select",
        useValueFormatterForExport: true,
        enableRowGroup: true,
        groupFilterValueGetter: (value) => {
          return value?.full_name;
        },
        cellRenderer: (params: { value: { full_name?: string } }) => {
          return params.value?.full_name || "-";
        },
        valueFormatter: (params) => {
          // params.value should always be of type TeamMember
          return params.value?.full_name || "";
        },
        valueGetter: (params) => {
          const teamMember = params.data?.team_member;
          // teamMember will be an id if selecting from the bulk edit flow
          if (typeof teamMember === "string") return lookupTeam(teamMember);
          return teamMember;
        },
        cellEditorParams: () => ({
          options: teamMemberOptions,
          isClearable: true,
        }),
      },
      {
        field: "team_member.friendly_id",
        headerName: "Team member ID",
        dataType: "string",
        minWidth: 200,
      },
      {
        headerName: "Department",
        field: "department",
        filterField: "department.name",
        sortField: "department.name",
        groupField: "department.name",
        groupFilterValueGetter: (value) => {
          return value?.name;
        },
        cellRenderer: (params: { value: { name?: string } }) => {
          return params.value?.name || "-";
        },
        dataType: "string",
        enableRowGroup: true,
        editable: (params) => expenseAbilities.can("update", params.data),
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          // params.value should always be of type Department
          return params.value?.name || "";
        },
        valueGetter: (params) => {
          const department = params.data?.department;

          // department.name will be an id if selecting from the bulk edit flow
          if (typeof department === "string") return lookupDepartment(department);
          return department;
        },
        cellEditorParams: () => ({ options: departmentOptions, isClearable: true }),
      },
      {
        headerName: "Job cost category",
        field: "card_transaction_category_id",
        dataType: "string",
        enableRowGroup: true,
        valueGetter: (params) => {
          return categoryNamer(params.data?.card_transaction_category_id ?? undefined);
        },
      },
      {
        headerName: "Job",
        field: "job",
        filterField: "job.name",
        sortField: "job.name",
        groupField: "job.name",
        groupFilterValueGetter: (value) => {
          return value?.name;
        },
        cellRenderer: (params: { value: { name?: string } }) => {
          return params.value?.name || "-";
        },
        dataType: "string",
        editable: (params) => expenseAbilities.can("update", params.data),
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          // params.value should always be of type Job
          return params.value?.name || "";
        },
        valueGetter: (params) => {
          const job = params.data?.job;

          // job will be an id if selecting from the bulk edit flow
          if (typeof job === "string") return lookupJob(job);
          return job;
        },
        cellEditorParams: () => ({ options: jobOptions, isClearable: true }),
      },
      // this column is required for searching by job code to work
      {
        field: "job.code",
        headerName: "Job code",
        dataType: "string",
        minWidth: 200,
      },
      {
        headerName: "Activity",
        field: "activity",
        filterField: "activity.label",
        sortField: "activity.label",
        groupField: "activity.label",
        enableRowGroup: true,
        groupFilterValueGetter: (value) => {
          return value?.label;
        },
        cellRenderer: (params: { value: { label?: string } }) => {
          return params.value?.label || "-";
        },
        dataType: "string",
        editable: (params) => expenseAbilities.can("update", params.data),
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          // params.value should always be of type Activity
          return params.value?.label || "";
        },
        valueGetter: (params) => {
          const activity = params.data?.activity;

          // activity will be an id if selecting from the bulk edit flow
          if (typeof activity === "string") return lookupActivity(activity);
          return activity;
        },
        cellEditorParams: (row: { data: { job_id?: string } }) => {
          const jobId = row?.data?.job_id;
          return {
            options: activityOptionsMappedByJob.get(jobId),
            isClearable: true,
          };
        },
      },
      {
        field: "activity.cost_code",
        headerName: "Activity code",
        dataType: "string",
        minWidth: 200,
      },
      {
        headerName: "Cost type",
        field: "cost_type",
        filterField: "cost_type.label",
        sortField: "cost_type.label",
        groupField: "cost_type.label",
        groupFilterValueGetter: (value) => {
          return value?.label;
        },
        cellRenderer: (params: { value: { label?: string } }) => {
          return params.value?.label || "-";
        },
        dataType: "string",
        editable: (params) => expenseAbilities.can("update", params.data),
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          // params.value should always be of type CostType
          return params.value?.label || "";
        },
        valueGetter: (params) => {
          const costType = params.data?.cost_type;

          // cost type will be an id if selecting from the bulk edit flow
          if (typeof costType === "string") return lookupCostType(costType);
          return costType;
        },
        cellEditorParams: () => ({
          options: costTypeOptions,
          isClearable: true,
        }),
      },

      {
        headerName: "GL account",
        field: "ledger_account",
        filterField: "ledger_account.label",
        sortField: "ledger_account.label",
        groupField: "ledger_account.label",
        groupFilterValueGetter: (value) => {
          return value?.label;
        },
        cellRenderer: (params: { value: { label?: string } }) => {
          return params.value?.label || "-";
        },
        dataType: "string",
        editable: (params) => expenseAbilities.can("update", params.data),
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          // params.value should always be of type LedgerAccount
          return accountLabeler(params.data?.ledger_account) || "Default";
        },
        valueGetter: (params) => {
          const ledgerAccount = params.data?.ledger_account;

          // ledgerAccount will be an id if selecting from the bulk edit flow
          if (typeof ledgerAccount === "string") return lookupLedgerAccount(ledgerAccount);
          return ledgerAccount;
        },
        cellEditorParams: (params: { data: { department_id?: string } }) => {
          const ledgerAccountOptions = getOptions(
            ledgerAccounts,
            {
              mapFunc: ledgerAccountOptionsMapCallback,
            },
            glAccountSelectionOptions({
              activeCompanyId,
              departmentId: params?.data?.department_id,
              lookupDepartment,
            })
          );
          return {
            options: ledgerAccountOptions,
            isClearable: true,
          };
        },
      },
      {
        headerName: "GL account ID",
        field: "ledger_account.external_id",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
        useValueFormatterForExport: true,
      },
      {
        headerName: "Team member memo",
        field: "submitter_note",
        dataType: "string",
        minWidth: 200,
        initialHide: true,
      },
      {
        headerName: "Type",
        field: "type",
        dataType: "string",
        valueFormatter: (row) => {
          return getCleanedExpenseType(row.data?.type) || "";
        },
        enableRowGroup: true,
        hide: true,
      },
      {
        headerName: "Card name",
        field: "cardName",
        dataType: "string",
        enableRowGroup: true,
      },
      {
        headerName: "Source",
        field: "source",
        dataType: "string",
        valueFormatter: (row) => {
          if (row.value === "Imported") return row.value;
          return `· · · · ${row.value}`;
        },
        enableRowGroup: true,
      },
      {
        headerName: "Refund",
        field: "is_refund",
        dataType: "boolean",
        minWidth: 125,
      },
      {
        headerName: "Receipt attached?",
        field: "has_receipt",
        dataType: "boolean",
      },
      {
        headerName: "Synced",
        field: "is_synced",
        dataType: "boolean",
      },
      {
        headerName: "Posted date",
        field: "closed_transactions",
        dataType: "date",
        dateType: "iso",
        initialHide: true,
        valueGetter: (row) => {
          // @ts-expect-error closed_transactions is not typed
          const closedTxns = row.data?.closed_transactions;

          if (!closedTxns?.length) return null;

          // Plaid
          if ("date" in closedTxns[0]) {
            // already in ISO format
            return closedTxns[0].date;
          }

          // Stripe
          if ("created" in closedTxns[0]) {
            return DateTime.fromSeconds(closedTxns[0].created).toISODate();
          }
        },
      },
    ];
    columns.push(...jobHierarchyTableColumns);

    return columns;
  }, [approvalGroupColumns]);
}

export function useSplitCardTransactionsTableColDefs(params: {
  handleDeleteSplit: (string) => void;
  splitType: SplitType;
}): ColumnConfig<SplitCardTransactionTableRow>[] {
  const { handleDeleteSplit, splitType } = params;
  const teamMemberOptions = useTeamOptions();
  const lookupTeam = useLookupTeam();
  const activeCompanyId = useActiveCompanyId();

  const jobOptions = useJobOptions();
  const lookupJob = useLookupJob();

  const departmentOptions = useDepartmentOptions();
  const lookupDepartment = useLookupDepartment();

  const activityOptionsMappedByJob = useActivityOptionsMap({
    predicate: isExpenseScoped,
  });

  const lookupActivity = useLookupActivity();

  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });
  const lookupCostType = useLookupCostType();

  const accountLabeler = useLedgerAccountLabeler();

  const ledgerAccounts = useLedgerAccounts();

  const renderTeamMemberName = useCallback(
    (params: ValueFormatterParams<SplitCardTransactionTableRow>) => lookupTeam(params.value)?.full_name || "",
    [lookupTeam]
  );

  const renderDepartmentName = useCallback(
    (params: ValueFormatterParams<SplitCardTransactionTableRow>) =>
      lookupDepartment(params.value)?.name || "",
    [lookupDepartment]
  );

  const renderJobName = useCallback(
    (params: ValueFormatterParams<SplitCardTransactionTableRow>) => lookupJob(params.value)?.name || "",
    [lookupJob]
  );

  const renderActivityLabel = useCallback(
    (params: ValueFormatterParams<SplitCardTransactionTableRow>) => lookupActivity(params.value)?.label || "",
    [lookupActivity]
  );

  const renderCostTypeLabel = useCallback(
    (params: ValueFormatterParams<SplitCardTransactionTableRow>) => lookupCostType(params.value)?.label || "",
    [lookupCostType]
  );

  return useMemo(() => {
    const columns: ColumnConfig<SplitCardTransactionTableRow>[] = [
      {
        headerName: "",
        field: "",
        dataType: "component",
        lockPosition: "left",
        lockPinned: true,
        allowClickInEditMode: true,
        cellRenderer: (params: { rowIndex: number; data: { _id: string } }) => {
          const isFirstRow = params.rowIndex === 0;

          return isFirstRow ? (
            <>Original Expense</>
          ) : (
            <Button
              onClick={() => {
                handleDeleteSplit(params.data._id);
              }}
              className={"button-1"}
            >
              Delete Split
            </Button>
          );
        },
      },
      {
        headerName: "Team Member",
        field: "team_member_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderTeamMemberName,
        cellEditorParams: () => ({
          options: teamMemberOptions,
        }),
      },
      {
        headerName: "Department",
        field: "department_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderDepartmentName,
        cellEditorParams: () => ({ options: departmentOptions, isClearable: true }),
      },
      {
        headerName: "Job",
        field: "job_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderJobName,
        cellEditorParams: () => ({ options: jobOptions, isClearable: true }),
      },
      {
        headerName: "Activity",
        field: "activity_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderActivityLabel,
        cellEditorParams: (row: { data: { job_id?: string } }) => {
          const jobId = row?.data?.job_id;
          return {
            options: activityOptionsMappedByJob.get(jobId),
            isClearable: true,
          };
        },
      },
      {
        headerName: "Cost Type",
        field: "cost_type_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderCostTypeLabel,
        cellEditorParams: () => ({
          options: costTypeOptions,
          isClearable: true,
        }),
      },
      {
        headerName: "GL Account",
        field: "expense_account_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          return accountLabeler(params.data?.expense_account_id) || "Default";
        },
        cellEditorParams: (params: { data: { department_id?: string } }) => {
          const ledgerAccountOptions = getOptions(
            ledgerAccounts,
            {
              mapFunc: ledgerAccountOptionsMapCallback,
            },
            glAccountSelectionOptions({
              activeCompanyId,
              departmentId: params?.data?.department_id,
              lookupDepartment,
            })
          );
          return {
            options: ledgerAccountOptions,
            isClearable: true,
          };
        },
      },
    ];

    if (splitType === "amount") {
      columns.push({
        headerName: "Amount",
        field: "amount",
        // headerTooltip: "Amounts will be auto-adjusted if there are only two total splits.",
        dataType: "number",
        valueFormatter: toDollarFormat,
        editable: true,
        editorType: "number",
        maxWidth: 120,
      });
    } else {
      // percent
      columns.push({
        headerName: "% of Total",
        field: "percent",
        // headerTooltip: "Percents will be auto-adjusted if there are only two total splits.",
        dataType: "string",
        editable: true,
        valueFormatter: (params) => toPercent(params.value, 2),
        editorType: "number",
        maxWidth: 120,
      });
    }
    return columns;
  }, [params]);
}

export function useBulkExpenseReimbursementTableColDefs(params: {
  reimbursements: DraftReimbursement[];
  uploadFilesCurried: (rowId: string) => (files: FilePickerFile[]) => void;
  openFilePreview: (attachments: FilePickerFile[]) => void;
  openTripEditor: (rowId: string) => void;
}): ColumnConfig<DraftReimbursement>[] {
  const { reimbursements, uploadFilesCurried, openFilePreview, openTripEditor } = params;

  const teamMemberOptions = useTeamOptions();
  const lookupTeam = useLookupTeam();
  const activeCompanyId = useActiveCompanyId();
  const { can } = useMiterAbilities();

  const reimbursementCategoryOptions = useExpenseReimbursementCategoryOptions();
  const lookupReimbursementCategory = useLookupExpenseReimbursementCategories();

  const jobOptions = useJobOptions();
  const lookupJob = useLookupJob();

  const departmentOptions = useDepartmentOptions();
  const lookupDepartment = useLookupDepartment();

  const activityOptionsMappedByJob = useActivityOptionsMap({
    predicate: isReimbursementScoped,
  });

  const lookupActivity = useLookupActivity();

  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });
  const lookupCostType = useLookupCostType();

  const accountLabeler = useLedgerAccountLabeler();

  const ledgerAccounts = useLedgerAccounts();
  const categoryNamer = useExpenseReimbursementCategoryName();

  const { buildPolicy } = useExpenseReimbursementPolicies();

  const renderTeamMemberName = useCallback(
    (params: ValueFormatterParams<DraftReimbursement>) => lookupTeam(params.value)?.full_name || "",
    [lookupTeam]
  );

  const renderDepartmentName = useCallback(
    (params: ValueFormatterParams<DraftReimbursement>) => lookupDepartment(params.value)?.name || "",
    [lookupDepartment]
  );

  const renderJobName = useCallback(
    (params: ValueFormatterParams<DraftReimbursement>) => lookupJob(params.value)?.name || "",
    [lookupJob]
  );

  const renderActivityLabel = useCallback(
    (params: ValueFormatterParams<DraftReimbursement>) => lookupActivity(params.value)?.label || "",
    [lookupActivity]
  );

  const renderCostTypeLabel = useCallback(
    (params: ValueFormatterParams<DraftReimbursement>) => lookupCostType(params.value)?.label || "",
    [lookupCostType]
  );

  const isFieldEditable =
    (categoryLockedFieldParam: keyof ExpenseReimbursementCategory) =>
    (params: EditableCallbackParams<DraftReimbursement>) => {
      const row = params.data;

      const currentlySelectedCategory = lookupReimbursementCategory(row?.expense_reimbursement_category_id);

      const isLocked =
        // @ts-expect-error categoryLockedFieldParam will only be one of the keys that has is_locked
        currentlySelectedCategory && currentlySelectedCategory[categoryLockedFieldParam]?.is_locked;

      return !isLocked;
    };

  const isRowMileage = (params: EditableCallbackParams<DraftReimbursement>) => {
    const row = params.data;
    if (!row) return false;

    return isDraftReimbursementMileage(row);
  };

  const isDraftReimbursementMileage = (reimbursement: DraftReimbursement) => {
    const currentlySelectedCategory = lookupReimbursementCategory(
      reimbursement?.expense_reimbursement_category_id
    );
    return currentlySelectedCategory?.mileage_rate != null;
  };

  // // if none are mileage (or vice versa), only show one group of columns
  let allRowsOutOfPocket = true;
  let allRowsMileage = true;

  reimbursements.forEach((reimbursement) => {
    // get current category
    const currentCategory = lookupReimbursementCategory(reimbursement?.expense_reimbursement_category_id);
    if (currentCategory?.mileage_rate != null) {
      allRowsOutOfPocket = false;
    } else {
      allRowsMileage = false;
    }
  });

  return useMemo(() => {
    const columns: ColumnConfig<DraftReimbursement>[] = [
      {
        headerName: "Date",
        field: "date",
        dataType: "date",
        editorType: "date",
        editorDateType: "iso",
        editable: true,
        dateFormat: "MMM dd",
        pinned: "left",
        maxWidth: 80,
      },
      {
        headerName: "Category",
        field: "expense_reimbursement_category_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        pinned: "left",
        valueFormatter: (params) =>
          categoryNamer(params.data?.expense_reimbursement_category_id ?? undefined),
        cellEditorParams: () => ({
          options: reimbursementCategoryOptions,
          isClearable: true,
        }),
      },
      // always show amount. If mileage, amount should be locked, but auto-updated to the final payout based on the mileage rate.
      {
        headerName: "Amount",
        field: "amount",
        dataType: "number",
        valueFormatter: toDollarFormat,
        editable: (params) => !isRowMileage(params) && isFieldEditable("amount")(params),
        editorType: "number",
        isEditableRequired: (params) => {
          // always required if not mileage
          const currentCategory = lookupReimbursementCategory(params?.expense_reimbursement_category_id);
          return !currentCategory?.mileage_rate;
        },
        tooltipValueGetter: (params) => {
          if (params.data) {
            const currentCategory = lookupReimbursementCategory(
              params.data.expense_reimbursement_category_id
            );

            if (!currentCategory?.mileage_rate && !params.data.amount) {
              return `Please add the amount.`;
            } else if (currentCategory?.mileage_rate && params.data.amount) {
              return `Calculated using $${currentCategory?.mileage_rate} / mile mileage rate.`;
            }
          }
        },
        maxWidth: 100,
      },
      // out of pocket only
      {
        headerName: "Merchant name",
        field: "vendor_name",
        dataType: "string",
        editable: (params) => !isRowMileage(params) && isFieldEditable("merchant_name")(params),
        editorType: "text",
        hide: allRowsMileage,
        isEditableRequired: (params) => !isDraftReimbursementMileage(params),
        tooltipValueGetter: (params) => {
          if (params.data) {
            const currentCategory = lookupReimbursementCategory(
              params.data.expense_reimbursement_category_id
            );

            if (!currentCategory?.mileage_rate && !params.data.vendor_name) {
              return `Please add the merchant.`;
            }
          }
        },
      },
      {
        headerName: "Receipt",
        field: "attachments",
        maxWidth: 120,
        dataType: "component",
        isEditableRequired: (params) => {
          const { isFieldRequired } = buildPolicy(params);
          return isFieldRequired("file_ids");
        },
        tooltipValueGetter: (params) => {
          if (params.data) {
            const currentCategory = lookupReimbursementCategory(
              params.data.expense_reimbursement_category_id
            );
            const { isFieldRequired } = buildPolicy(params.data);

            if (currentCategory?.mileage_rate && isFieldRequired("file_ids") && !params.data.attachments) {
              return `Please add a receipt.`;
            }
          }
        },
        hide: allRowsMileage,
        allowClickInEditMode: true,
        cellRenderer: (params: { data: DraftReimbursement }) => {
          const isRowMileage = isDraftReimbursementMileage(params.data);

          if (isRowMileage) return <></>;

          return (
            <BulkCreateExpenseReimbursementFileUpload
              localFiles={params.data.attachments ?? []}
              uploadFiles={uploadFilesCurried(params.data._id)}
              openFilePreview={openFilePreview}
            />
          );
        },
      },
      // mileage only
      {
        headerName: "Miles Driven",
        field: "mileage",
        dataType: "number",
        editorType: "number",
        editable: (params) => {
          return !params.data?.trip?.length && isRowMileage(params);
        },
        isEditableRequired: isDraftReimbursementMileage,
        tooltipValueGetter: (params) => {
          if (params.data) {
            const currentCategory = lookupReimbursementCategory(
              params.data.expense_reimbursement_category_id
            );

            if (currentCategory?.mileage_rate && !params.data.mileage) {
              return `Please add the mileage driven.`;
            }

            // if trip exists, explain why mileage is autocalculated
            if (params.data?.trip?.length) {
              return `Calculated using the distance you drove between locations on the trip.`;
            }
          }
        },
        hide: allRowsOutOfPocket,
      },
      {
        headerName: "Add Trip",
        field: "trip",
        dataType: "number",
        editorType: "number",
        tooltipValueGetter: () => {
          return "(Optional) Add a trip with stops to calculate the mileage driven automatically.";
        },
        allowClickInEditMode: true,
        cellRenderer: (params: { data: DraftReimbursement }) => {
          return (
            <>
              <div className="flex align-items-center" onClick={() => openTripEditor(params.data._id)}>
                {params.data.trip?.length ? "Edit Trip" : "Add Trip"}&nbsp;
                <MapPin color="green" weight="duotone" size={20} />
              </div>
            </>
          );
        },
        hide: allRowsOutOfPocket,
      },
      {
        headerName: "Job",
        field: "job_id",
        dataType: "string",
        editable: isFieldEditable("job"),
        isEditableRequired: (params) => {
          const { isFieldRequired } = buildPolicy(params);
          return isFieldRequired("job_id");
        },
        tooltipValueGetter: (params) => {
          if (params.data) {
            const { isFieldRequired } = buildPolicy(params.data);

            if (isFieldRequired("job_id") && !params.data.job_id) return `Please set a job.`;
          }
        },
        editorType: "select",
        valueFormatter: renderJobName,
        cellEditorParams: () => ({ options: jobOptions, isClearable: true }),
      },
      {
        headerName: "Activity",
        field: "activity_id",
        dataType: "string",
        editable: isFieldEditable("activity"),
        editorType: "select",
        isEditableRequired: (params) => {
          const { isFieldRequired } = buildPolicy(params);
          return isFieldRequired("activity_id");
        },
        tooltipValueGetter: (params) => {
          if (params.data) {
            const { isFieldRequired } = buildPolicy(params.data);

            if (isFieldRequired("activity_id") && !params.data.activity_id) return `Please set an activity.`;
          }
        },
        valueFormatter: renderActivityLabel,
        cellEditorParams: (row: { data: { job_id?: string } }) => {
          const jobId = row?.data?.job_id;
          return {
            options: activityOptionsMappedByJob.get(jobId),
            isClearable: true,
          };
        },
      },
      {
        headerName: "Cost type",
        field: "cost_type_id",
        dataType: "string",
        editable: isFieldEditable("cost_type"),
        editorType: "select",
        valueFormatter: renderCostTypeLabel,
        cellEditorParams: () => ({
          options: costTypeOptions,
          isClearable: true,
        }),
      },
      {
        headerName: "GL account",
        field: "expense_account_id",
        dataType: "string",
        editable: isFieldEditable("expense_account"),
        editorType: "select",
        isEditableRequired: (params) => {
          const { isFieldRequired } = buildPolicy(params);
          return isFieldRequired("expense_account_id");
        },
        tooltipValueGetter: (params) => {
          if (params.data) {
            const { isFieldRequired } = buildPolicy(params.data);

            if (isFieldRequired("expense_account_id") && !params.data.expense_account_id)
              return `Please set a GL account.`;
          }
        },
        valueFormatter: (params) => {
          // params.value should always be of type LedgerAccount
          return accountLabeler(params.data?.expense_account_id) || "Default";
        },
        cellEditorParams: (params: { data: { department_id?: string } }) => {
          const ledgerAccountOptions = getOptions(
            ledgerAccounts,
            {
              mapFunc: ledgerAccountOptionsMapCallback,
            },
            glAccountSelectionOptions({
              activeCompanyId,
              departmentId: params?.data?.department_id,
              lookupDepartment,
            })
          );
          return {
            options: ledgerAccountOptions,
            isClearable: true,
          };
        },
      },
      {
        headerName: "Memo",
        field: "memo",
        dataType: "string",
        editable: true,
        editorType: "text",
        pinned: "right",
        isEditableRequired: (params) => {
          const { isFieldRequired } = buildPolicy(params);
          return isFieldRequired("memo");
        },
        tooltipValueGetter: (params) => {
          if (params.data) {
            const { isFieldRequired } = buildPolicy(params.data);

            if (isFieldRequired("memo") && !params.data.memo) return `Please add a memo.`;
          }
        },
      },
    ];

    // if you can create reimbursements for others, add team member and department columns to start
    if (can("reimbursements:others:create")) {
      columns.unshift({
        headerName: "Department",
        field: "department_id",
        dataType: "string",
        maxWidth: 150,
        editable: true,
        editorType: "select",
        pinned: "left",
        valueFormatter: renderDepartmentName,
        cellEditorParams: () => ({ options: departmentOptions, isClearable: true }),
      });

      columns.unshift({
        headerName: "Team member",
        field: "team_member_id",
        maxWidth: 150,
        dataType: "string",
        editable: true,
        isEditableRequired: () => true,
        tooltipValueGetter: (params) => {
          if (params.data) {
            if (!params.data.team_member_id) {
              return `Please set a team member.`;
            }
          }
        },
        editorType: "select",
        pinned: "left",
        valueFormatter: renderTeamMemberName,
        cellEditorParams: () => ({
          options: teamMemberOptions,
        }),
      });
    }

    return columns;
  }, [reimbursements]);
}

export const isValidTrip = (trip: MileageLocation[] | DraftLocation[] | undefined): boolean => {
  // @ts-expect-error fix me
  return !!trip && Array.isArray(trip) && trip.length >= 2 && trip.every((location) => !!location.place_id);
};

export const generateDeclineReasonString = (miterCardTransaction: MiterCardTransaction): string => {
  const requestHistory: Stripe.Issuing.Authorization.RequestHistory | undefined = (
    miterCardTransaction.external_raw_data as Stripe.Issuing.Authorization
  )?.request_history
    .slice(-1)
    .pop();

  if (!requestHistory) return "";

  const declineReason = requestHistory.reason;
  switch (declineReason) {
    case "verification_failed":
      return "Verification Failed";
    case "card_inactive":
      return "Card Inactive";
    case "insufficient_funds":
      return "Insufficient Funds";
    case "suspected_fraud":
      return "Suspected Fraud";
    case "cardholder_verification_required":
      return "Verification Required";
    case "spending_controls":
      return "Cardholder Spending Controls";
    default:
      return "Contact Miter for more details."; // don't surface
  }
};

export const isPlaidAccountVerified = (account: PlaidAccount | PlaidBackendAccountBase): boolean => {
  // if undefined, it was immediately verified
  return ["automatically_verified", "manually_verified", undefined].includes(account.verification_status);
};

export const isPlaidBankAccount = (bankAccount: BankAccount): bankAccount is PlaidBankAccount => {
  return bankAccount.type === "plaid_bank_account";
};

export const isRawBankAccount = (bankAccount: BankAccount): bankAccount is RawBankAccount => {
  return bankAccount.type === "raw_bank_account";
};
export const isCheckOriginatedBankAccount = (
  bankAccount: BankAccount
): bankAccount is CheckOriginatedBankAccount => {
  return bankAccount.type === "check_originated_bank_account";
};

// this hook returns a function, which allows dynamically recalculating the list of options based on the team member
export const useGetReimbursementPayoutMethodOptions = (
  alwaysShowPayroll?: boolean
): ((teamMemberId?: string) => Option<ExpenseReimbursementPayoutMethod>[]) => {
  const lookupTeamMember = useLookupTeam();
  const stripeAccount = useStripeConnectedAccount();

  const isStripePayoutOptionEnabled = isMiterCardsAccountFullyActive(stripeAccount);

  return useCallback(
    (teamMemberId?: string): Option<ExpenseReimbursementPayoutMethod>[] => {
      const teamMember = lookupTeamMember(teamMemberId);
      const payrollStatus = getPayrollStatus(teamMember);

      let isPayrollOptionEnabled = teamMember
        ? payrollStatus === "completed" || payrollStatus === "needs_attention"
        : false;

      if (alwaysShowPayroll) {
        isPayrollOptionEnabled = true;
      }

      return [
        { label: "Payroll", value: "payroll", isDisabled: !isPayrollOptionEnabled },
        { label: "ACH to employee", value: "ach", isDisabled: !isStripePayoutOptionEnabled },
        { label: "Manual Payment", value: "manual" },
      ];
    },
    [lookupTeamMember, getPayrollStatus, isStripePayoutOptionEnabled]
  );
};

// returns all 3 options, regardless of team member
export const useReimbursementPayoutMethodOptions = (
  alwaysShowPayroll?: boolean
): Option<ExpenseReimbursementPayoutMethod>[] => {
  const payoutMethodOptions = useGetReimbursementPayoutMethodOptions(alwaysShowPayroll);
  return payoutMethodOptions();
};

export const useDefaultReimbursementPayoutMethodOption = (
  teamMemberId?: string
): Option<ExpenseReimbursementPayoutMethod> => {
  const payoutMethodOptions = useGetReimbursementPayoutMethodOptions();
  const lookupTeamMember = useLookupTeam();
  const stripeAccount = useStripeConnectedAccount();

  return useMemo(() => {
    const options = payoutMethodOptions(teamMemberId);
    const teamMember = lookupTeamMember(teamMemberId);

    const isStripePayoutOptionEnabled = isMiterCardsAccountFullyActive(stripeAccount);
    const payrollStatus = getPayrollStatus(teamMember);
    const isPayrollOptionEnabled = payrollStatus === "completed" || payrollStatus === "needs_attention";

    if (isPayrollOptionEnabled) {
      return options.find(withValue("payroll"))!;
    } else if (isStripePayoutOptionEnabled) {
      return options.find(withValue("ach"))!;
    } else {
      return options.find(withValue("manual"))!;
    }
  }, [payoutMethodOptions, lookupTeamMember, stripeAccount]);
};

export const useGetDefaultReimbursementPayoutMethodOption = (): ((
  teamMemberId?: string
) => Option<ExpenseReimbursementPayoutMethod>) => {
  const getReimbursementPayoutOptions = useGetReimbursementPayoutMethodOptions();
  return useCallback(
    (teamMemberId) => {
      const options = getReimbursementPayoutOptions(teamMemberId);
      return options.filter((option) => !option.isDisabled)[0]!;
    },
    [getReimbursementPayoutOptions]
  );
};

export const useValidateTeamMemberHasReimbursementPayoutMethod = (): ((
  teamMemberId: string,
  payoutMethod: ExpenseReimbursementPayoutMethod
) => boolean) => {
  const getPayoutMethodOptions = useGetReimbursementPayoutMethodOptions();

  return useCallback(
    (teamMemberId, payoutMethod) => {
      const payoutMethodOption = getPayoutMethodOptions(teamMemberId).find(withValue(payoutMethod));
      return !!payoutMethodOption && !payoutMethodOption?.isDisabled;
    },
    [getPayoutMethodOptions]
  );
};
