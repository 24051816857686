import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import AppContext from "dashboard/contexts/app-context";
import { MiterAPI, TableLedgerEntry } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useEffect, useMemo, useState } from "react";
import {
  ColDef,
  GridApi,
  ICellRendererParams,
  RowClickedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { DateTime } from "luxon";
import { Badge, Button, ConfirmModal, LargeModal } from "ui";
import { LedgerEntry } from "./LedgerEntry";
import { verticalCenteredCellStyle } from "dashboard/components/agGridTable/agGridUtils";
import { toDollarFormat } from "dashboard/pages/reports/reportUtils";
import { PostEntriesModal } from "./PostEntriesModal";
import { downloadFoundationCsv, downloadJonasJournalEntriesCsv } from "dashboard/utils/csvDownloadFunctions";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useSageIntacctCsv } from "dashboard/utils/useSageIntacctCsv";
import FailuresModal, { FailureItem } from "dashboard/components/shared/FailuresModal";
import { ExportWarningsModal } from "dashboard/components/shared/ExportWarnings";
import { LedgerEntryType } from "backend/models/ledger-entry";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useSage100PayrollExports } from "dashboard/utils/useSage100PayrollExports";
import { useSage300LedgerEntriesCsv } from "dashboard/utils/useSage300LedgerEntriesCsv";
import { SyncEntriesButton } from "./SyncEntriesButton";

type LedgerEntrySyncStatus = "not_synced" | "synced";
export type LedgerEntryTableData = TableLedgerEntry & {
  datePosted: string;
  entryTypeFormatted: string;
  syncStatus?: LedgerEntrySyncStatus;
  syncable?: boolean;
};

const ledgerEntryTypeLookup: Record<LedgerEntryType, string> = {
  bill_payment: "Bill payment",
  payroll_run: "Payroll",
  payroll_void: "Void payroll",
  payroll_net_pay_failure_refund: "Net pay failure",
  manual: "Manual",
  card_transaction: "Card transaction",
  third_party_card_transaction: "External card transaction",
  stripe_inbound_transfer: "Bank transfer",
  reimbursement_payout: "Paid reimbursement",
  timesheet_recoding: "Timesheet recoding",
};

export const LedgerEntries: React.FC = () => {
  const { integrations } = React.useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const { can } = useMiterAbilities();

  const [gridApi, setGridApi] = useState<GridApi<LedgerEntryTableData>>();
  const [cleanEntries, setCleanEntries] = useState<TableLedgerEntry[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [modalEntry, setModalEntry] = useState<LedgerEntryTableData>();
  const [entriesSelected, setEntriesSelected] = useState<LedgerEntryTableData[]>([]);
  const [showPostEntriesModal, setShowPostEntriesModal] = useState(false);
  const [showConfirmRefresh, setShowConfirmRefresh] = useState(false);
  const [refreshFailures, setRefreshFailures] = useState<FailureItem[]>();

  const [
    hasIntegrationWithLeSyncEnabled,
    sage300IcId,
    intacctIcId,
    jonasIcId,
    foundationIcId,
    sage100IcId,
    acumaticaIcId,
  ] = useMemo(() => {
    let hasSyncable = false,
      sage300: string | undefined,
      jonas: string | undefined,
      foundation: string | undefined,
      intacct: string | undefined,
      sage100: string | undefined,
      acumatica: string | undefined;

    for (const i of integrations) {
      const c = i.connection;
      if (!c || c.pending_setup) continue;
      if (i.key === "sage_300") {
        sage300 = c._id;
      } else if (i.key === "sage_intacct") {
        intacct = c._id;
      } else if (i.key === "jonas") {
        jonas = c._id;
      } else if (i.key === "foundation") {
        foundation = c._id;
      } else if (i.key === "sage_100") {
        sage100 = c._id;
      } else if (i.key === "acumatica") {
        acumatica = c._id;
      }
      if (i.supported_operations.ledger_entries?.push?.enabled) {
        hasSyncable = true;
      }
    }

    return [hasSyncable, sage300, intacct, jonas, foundation, sage100, acumatica];
  }, [integrations]);

  const sage300Jcd = useSage300LedgerEntriesCsv(sage300IcId);
  const sageIntacctCsv = useSageIntacctCsv(intacctIcId);
  const sage100Csv = useSage100PayrollExports(activeCompanyId, sage100IcId);

  const getData = async () => {
    if (!activeCompanyId || !gridApi) return;
    gridApi.showLoadingOverlay();
    try {
      const filter = [{ field: "company", value: activeCompanyId }];
      const response = await MiterAPI.ledger_entries.table({ queryObject: { filter } });

      if (response.error) throw new Error(response.error);
      const cleaned = response.map((le): LedgerEntryTableData => {
        const syncStatus: LedgerEntrySyncStatus = getLedgerEntrySyncStatus(le);
        return {
          ...le,
          datePosted: DateTime.fromSeconds(le.posted_at).toISODate(),
          syncStatus,
          entryTypeFormatted: ledgerEntryTypeLookup[le.entry_type],
        };
      });
      setCleanEntries(cleaned);
    } catch (e) {
      console.error(e);
      Notifier.error("Error getting your ledger entries. We're looking into it!");
    }
    gridApi.hideOverlay();
  };

  useEffect(() => {
    getData();
  }, [!!activeCompanyId, gridApi]);

  const refreshEntries = async () => {
    if (!activeCompanyId) return;
    gridApi?.showLoadingOverlay();
    setShowConfirmRefresh(false);
    try {
      const response = await MiterAPI.ledger_entries.refresh(
        activeCompanyId,
        entriesSelected.map((e) => e._id.toString())
      );
      if (response.error) throw new Error(response.error);
      if (response.failures.length > 0) {
        Notifier.warning(`Failed to refresh ${response.failures.length} entries.`);
        setRefreshFailures(
          response.failures.map((f) => {
            const label = cleanEntries.find((e) => e._id === f.id)?.description || f.id;
            return { label, message: f.message };
          })
        );
      } else {
        Notifier.success("Ledger entries refreshed successfully.");
      }
      getData();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error refreshing ledger entries.");
    }
    gridApi?.hideOverlay();
  };

  const actionButtons = () => {
    return <div className="flex"></div>;
  };

  const onRowClicked = (event: RowClickedEvent<LedgerEntryTableData>) => {
    setModalEntry(event.node.data);
  };

  const onSelectionChanged = (event: SelectionChangedEvent<LedgerEntryTableData>) => {
    const selectedData: LedgerEntryTableData[] = [];

    event.api.forEachNodeAfterFilterAndSort((node) => {
      if (node.isSelected() && node.data) {
        selectedData.push(node.data);
      }
    });

    setEntriesSelected(selectedData);
  };

  const [bulkActionLoading, setBulkActionLoading] = useState(false);

  const toggleManuallySynced = async (newStatus: boolean) => {
    setBulkActionLoading(true);
    try {
      const ids = entriesSelected.map((e) => e._id.toString());
      // @ts-expect-error updating nested field
      const response = await MiterAPI.ledger_entries.update(ids, { "sync.manually_synced": newStatus });
      if (response.error) throw Error(response.error);
      if (response.failures.length === ids.length) {
        throw Error("Entry sync error");
      } else if (response.failures.length) {
        Notifier.warning("One or more entries couldn't be updated.");
      } else {
        Notifier.success("Entries updated successfully.");
      }
      await getData();
      setEntriesSelected([]);
      gridApi?.deselectAll();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating entries. We're looking into it!");
    }
    setBulkActionLoading(false);
  };

  const handleFoundationDownloadClick = async () => {
    if (!activeCompanyId) return Notifier.error("No company selected");
    await downloadFoundationCsv({
      ledgerEntryIds: entriesSelected.map((e) => e._id),
      companyId: activeCompanyId,
    });
  };

  const handleJonasDownloadClick = async () => {
    if (!activeCompanyId) return Notifier.error("No company selected");
    if (!jonasIcId) return Notifier.error("No Jonas integration found");

    await downloadJonasJournalEntriesCsv({
      ledgerEntryIds: entriesSelected.map((e) => e._id),
      icId: jonasIcId,
    });
  };

  const selectedActionButtons = () => {
    const selectedAreAllUnsynced = entriesSelected.every((e) => !e.sync);
    const selectedAreAllManuallySynced = entriesSelected.every((e) => e.sync?.manually_synced);
    const allSelectableAreSyncableToIntacct = entriesSelected.every(
      (e) =>
        e.entry_type === "payroll_run" ||
        e.entry_type === "payroll_void" ||
        e.entry_type === "payroll_net_pay_failure_refund" ||
        e.entry_type === "timesheet_recoding" ||
        e.entry_type === "reimbursement_payout" ||
        e.entry_type === "stripe_inbound_transfer"
    );

    return (
      <div className="flex">
        {can("accounting:ledger_entries:sync") && (
          <SyncEntriesButton
            onClick={() => setShowPostEntriesModal(true)}
            allSelectableAreSyncableToIntacct={allSelectableAreSyncableToIntacct}
            intacctIcId={intacctIcId}
            hasIntegrationWithLeSyncEnabled={hasIntegrationWithLeSyncEnabled}
            acumaticaIcId={acumaticaIcId}
          />
        )}
        {foundationIcId && (
          <Button text="Download Foundation CSV" onClick={() => handleFoundationDownloadClick()} />
        )}
        {jonasIcId && <Button text="Download Jonas CSV" onClick={() => handleJonasDownloadClick()} />}
        {sage300IcId && (
          <Button
            text="Build Sage 300 JCD File"
            onClick={() => sage300Jcd.build({ ledgerEntryIds: entriesSelected.map((e) => e._id) })}
            loading={sage300Jcd.loading}
          />
        )}
        {sage100IcId && (
          <Button
            text="Download Sage 100 CSV files"
            onClick={() => sage100Csv.build(entriesSelected.map((e) => e._id))}
            loading={sage100Csv.loading}
          />
        )}
        {intacctIcId && allSelectableAreSyncableToIntacct && (
          <Button
            text="Download Intacct CSV"
            onClick={() => sageIntacctCsv.build(entriesSelected.map((e) => e._id))}
            loading={sageIntacctCsv.loading}
          />
        )}
        {selectedAreAllUnsynced && can("accounting:ledger_entries:sync") && (
          <Button
            loading={bulkActionLoading}
            text="Manually mark as synced"
            onClick={() => toggleManuallySynced(true)}
          />
        )}
        {selectedAreAllManuallySynced && can("accounting:ledger_entries:sync") && (
          <Button
            text="Mark as unsynced"
            loading={bulkActionLoading}
            onClick={() => toggleManuallySynced(false)}
          />
        )}
        {can("accounting:ledger_entries:generate") && (
          <Button text="Regenerate entries" onClick={() => setShowConfirmRefresh(true)} />
        )}
      </div>
    );
  };

  return (
    <div className="height-100">
      <div style={{ display: "flex", flexDirection: "column", height: "100%", maxWidth: 1400 }}>
        <div className="vertical-spacer-large"></div>
        <AgGridTable
          columnDefs={ledgerEntryTableColDefs}
          data={cleanEntries}
          defaultActionButtons={actionButtons}
          actionButtonsLocation="left"
          hideDownloadCSV={true}
          onSearch={(e) => setSearchTerm(e)}
          searchValue={searchTerm}
          searchPlaceholder="Search entries"
          setGridApi={setGridApi}
          selectedActionButtons={selectedActionButtons}
          showSelectedActionButtons={!!entriesSelected.length}
          gridOptions={{
            getRowId: (params) => params.data._id,
            quickFilterText: searchTerm,
            rowSelection: "multiple",
            suppressClickEdit: true,
            suppressMultiRangeSelection: true,
            suppressRowClickSelection: true,
            onRowClicked,
            onSelectionChanged,
            tooltipShowDelay: 0,
          }}
        />
        {modalEntry && (
          <LargeModal headerText={modalEntry.description} onClose={() => setModalEntry(undefined)}>
            <div className="height-100">
              <div className="vertical-spacer-small"></div>
              <LedgerEntry ledgerEntryId={modalEntry._id.toString()} getLedgerEntries={getData} />
            </div>
          </LargeModal>
        )}
        {showPostEntriesModal && (
          <PostEntriesModal
            hide={() => setShowPostEntriesModal(false)}
            entriesToPost={entriesSelected}
            getLedgerEntries={getData}
          />
        )}
        {showConfirmRefresh && (
          <ConfirmModal
            title="Warning! Are you sure?"
            body={
              "This action will regenerate the selected ledger entries according to your current account mapping settings. It may take 1-2 minutes."
            }
            onYes={refreshEntries}
            onNo={() => setShowConfirmRefresh(false)}
            yellowBodyText
          />
        )}
        <ExportWarningsModal
          downloadSage300ExportFile={sage300Jcd.downloadSage300ExportFile}
          warnings={sage300Jcd.warnings}
          setWarnings={sage300Jcd.setWarnings}
          data={sage300Jcd.data}
          loading={sage300Jcd.loading}
          fileName={sage300Jcd.timeEntriesFileName}
          headerText="Export Warning For Sage300 Ledger Entries"
        />
        {refreshFailures && (
          <FailuresModal
            headerText="Ledger entry regeneration failures"
            failures={refreshFailures}
            onClose={() => setRefreshFailures(undefined)}
          />
        )}
      </div>
    </div>
  );
};

const ledgerEntryTableColDefs: ColDef<LedgerEntryTableData>[] = [
  // @ts-expect-error circular column defs
  {
    field: "description",
    filter: "agTextColumnFilter",
    headerName: "Description",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    minWidth: 400,
    lockPosition: "left",
    cellStyle: { overflow: "hidden" },
  },
  {
    field: "entryTypeFormatted",
    filter: "agTextColumnFilter",
    headerName: "Type",
    maxWidth: 190,
  },
  {
    field: "syncStatus",
    filter: "agSetColumnFilter",
    headerName: "Sync",
    maxWidth: 120,
    cellRenderer: (
      params: ICellRendererParams<LedgerEntryTableData, LedgerEntrySyncStatus>
    ): React.ReactElement => {
      let text = "✕",
        color = "grey";
      if (params.value === "synced") {
        text = "✓";
        color = "green";
      }
      return (
        <div>
          <Badge text={text} color={color} className="table" />
        </div>
      );
    },
    cellStyle: verticalCenteredCellStyle,
  },
  {
    field: "datePosted",
    headerName: "Date posted",
    maxWidth: 160,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
        return (
          DateTime.fromISO(cellValue).toSeconds() - DateTime.fromJSDate(filterLocalDateAtMidnight).toSeconds()
        );
      },
    },
  },
  {
    field: "debit_amount",
    headerName: "Debits/Credits",
    maxWidth: 220,
    aggFunc: "sumValues",
    type: "rightAligned",
    valueFormatter: toDollarFormat,
  },
];

const getLedgerEntrySyncStatus = (ledgerEntry: TableLedgerEntry): LedgerEntrySyncStatus => {
  if (ledgerEntry?.expense_integrations?.sage_intacct?.intacctCreditCardTxnKey) {
    return "synced";
  }

  return ledgerEntry.sync ? "synced" : "not_synced";
};
