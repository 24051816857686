import {
  AggregatedFillableTemplate,
  CreateFillableTemplateParams,
} from "dashboard/types/fillable-template-types";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Formblock, Label, Notifier, WizardScreen } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import * as vals from "dashboard/utils/validators";
import styles from "./FillableTemplateWizard.module.css";
import useWizard from "ui/modal/useWizard";
import { updateFiles } from "miter-utils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { DocumentDisplay } from "miter-components";

type Props = {
  fillableTemplate?: AggregatedFillableTemplate;
  name: string;
  setFillableTemplate: (fillableTemplate: AggregatedFillableTemplate) => void;
};

type InitialDocumentProps = {
  file: FilePickerFile[];
} & Pick<AggregatedFillableTemplate, "name" | "tag_ids">;

export const UploadTemplateWizardScreen: FC<Props> = ({ fillableTemplate, name, setFillableTemplate }) => {
  const activeCompanyId = useActiveCompanyId();
  const { setCanNext } = useWizard();
  const defaultFiles = fillableTemplate?.original_file ? [{ data: fillableTemplate.original_file }] : [];
  const defaultName = fillableTemplate?.name || "";
  const form = useForm<InitialDocumentProps>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      // Intentionally passed in array format to match FilePickerFile validation format
      file: defaultFiles,
      name: defaultName,
      tag_ids: fillableTemplate?.tag_ids || [],
    },
  });

  const [localFile, setLocalFile] = useState<FilePickerFile>();

  const fileURI = useMemo(() => {
    if (localFile?.blob) {
      return URL.createObjectURL(localFile.blob);
    }
    return null;
  }, [localFile]);

  const fillableTemplateTemplateId = useMemo(() => {
    return fillableTemplate?.original_file_id;
  }, [fillableTemplate]);

  const {
    handleSubmit,
    formState: { errors: formErrors, isValid },
    trigger,
    register,
  } = form;

  const canEdit = !fillableTemplate?.original_file_id;

  useEffect(() => {
    if (Object.keys(formErrors).length > 0 || !isValid) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [formErrors, isValid]);

  useEffect(() => {
    trigger();
  }, []);

  const upsertFillableTemplate = async (data: InitialDocumentProps) => {
    try {
      const { file, name } = data;

      let fileId = fillableTemplate?.original_file_id;

      if (!fillableTemplate) {
        const response = await updateFiles(file, {
          parent_type: "fillable",
          company_id: activeCompanyId!,
          parent_id: "",
        });

        fileId = response.created_ids[0];
      }

      if (!fileId) {
        throw new Error("Failed to create fillable template");
      }

      const createFillableTemplateParams: CreateFillableTemplateParams = {
        company_id: activeCompanyId!,
        name,
        tag_ids: [],
        inputs: [],
        original_file_id: fileId,
        fillable_file_id: fileId,
      };

      const upsertedFillableTemplate = !!fillableTemplate
        ? await MiterAPI.fillable_templates.update({
            id: fillableTemplate._id,
            update: { name },
          })
        : await MiterAPI.fillable_templates.create(createFillableTemplateParams);

      if (upsertedFillableTemplate.error) {
        throw new Error(upsertedFillableTemplate.error);
      }

      setFillableTemplate(upsertedFillableTemplate);
    } catch (error: $TSFixMe) {
      Notifier.error(error.message);
      throw error;
    }
  };

  const onNext = async () => {
    if (!activeCompanyId) return;
    await handleSubmit(upsertFillableTemplate)();
  };

  const handleFileUpload = (files: FilePickerFile[]) => {
    trigger();
    setLocalFile(files[0]);
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["wizard-screen"]}>
        <h2 className={styles["subheader-title"]}>Upload a fillable template</h2>
        <p className={styles["subheader-description"]}>
          In the next step, you will add inputs to create a fillable form!
        </p>

        <div className={styles["upload-template-first-column"]}>
          <Formblock
            label={"Name*"}
            type="text"
            name={"name"}
            form={form}
            editing={true}
            register={register(vals.required)}
            className="modal wizard"
            defaultValue={defaultName}
          />

          <Formblock
            label={"Upload PDF*"}
            type="file"
            name={"file"}
            form={form}
            dropzoneLabel="Upload or drop a file in PDF format"
            multiple={false}
            editing={canEdit}
            acceptedFileTypes={["application/pdf"]}
            val={vals.required}
            variant="dropzone-button"
            className="modal wizard"
            onChange={handleFileUpload}
            defaultValue={defaultFiles}
            maxFilesAllowed={1}
            showFilePreview={true}
          />
        </div>
        <div className={styles["upload-template-second-column"]}>
          {fileURI || fillableTemplateTemplateId ? (
            <div>
              <Label
                style={{
                  marginBottom: 5,
                }}
                label={"Template preview"}
              ></Label>
              <DocumentDisplay
                uri={fileURI || ""}
                hideHeader={true}
                documentHeightOverride={600}
                fileId={fillableTemplate?.original_file_id}
              />
            </div>
          ) : null}
        </div>
      </div>
    </WizardScreen>
  );
};
