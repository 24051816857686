import React, { useMemo, useState } from "react";
import { AggregatedTeamMember } from "dashboard/miter";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import {
  useActiveCompany,
  useLookupCompanyUsers,
  useLookupPrg,
  useLookupRateClassification,
  useTeam,
} from "dashboard/hooks/atom-hooks";

import ObjectID from "bson-objectid";
import { DateRange } from "ui/form/DateRangePicker";
import { isTmActive } from "./TeamUtils";

import { capitalize } from "lodash";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { getWorkHoursInYear, getWorkWeeksInYear } from "dashboard/utils";
import { DEFAULT_WORK_HOURS_IN_YEAR, DEFAULT_WORK_WEEKS_IN_YEAR } from "dashboard/utils/constants";
import { isMiterRep } from "miter-utils";
import { DateTime } from "luxon";

type Props = {
  teamMember?: AggregatedTeamMember;
};

type RateHistoryTableEntry = AggregatedTeamMember["rate_title_history"][0] & {
  tm: AggregatedTeamMember;
  tmActive: boolean;
  payTypeFinal: string;
  payRateFinal: number;
  payRateUnit: string;
  changedFormatted: string;
  changedBy: string;
};

export const TeamRateHistory: React.FC<Props> = (props) => {
  const { teamMember } = props;
  const lookupPrg = useLookupPrg();
  const lookupRateClassification = useLookupRateClassification();
  const lookupCompanyUser = useLookupCompanyUsers();
  const company = useActiveCompany();
  const team = useTeam();
  const teamAbilities = useTeamAbilities();

  const [dateRange, setDateRange] = useState<DateRange>();

  const fullTeam = useTeam();
  const teamByUserId = useMemo(() => {
    return Object.fromEntries(fullTeam.map((t) => [t.user, t]));
  }, [fullTeam]);

  const tableData = useMemo(() => {
    const tms = teamMember ? [teamMember] : team;
    const start = dateRange?.start?.startOf("day").toSeconds() || 0;
    const end = dateRange?.end?.endOf("day").toSeconds() || Number.POSITIVE_INFINITY;
    const workWeeksInYear = company ? getWorkWeeksInYear(company) : DEFAULT_WORK_WEEKS_IN_YEAR;
    return tms
      .filter((tm) => teamAbilities.can("read_sensitive", tm))
      .flatMap((tm) => {
        const filteredHistory: AggregatedTeamMember["rate_title_history"] = [];
        if (dateRange) {
          let hitFirstChange = false;
          for (let i = 0; i < tm.rate_title_history.length; i++) {
            const item = tm.rate_title_history[i]!;
            if (item.changed_at > start && item.changed_at <= end) {
              filteredHistory.push(item);
              if (!hitFirstChange) {
                hitFirstChange = true;
                if (i > 0) {
                  // Add the rate right before the first change in the range so that we have a baseline
                  filteredHistory.push(tm.rate_title_history[i - 1]!);
                }
              }
            }
          }
          // If we never found any rate changes in the date range, add the one that comes right before the start and end
          if (!hitFirstChange) {
            for (let k = tm.rate_title_history.length - 1; k >= 0; k--) {
              const item = tm.rate_title_history[k]!;
              if ((!start || item.changed_at < start) && item.changed_at < end) {
                filteredHistory.push(item);
                break;
              }
            }
          }
        } else {
          filteredHistory.push(
            ...tm.rate_title_history.filter((item) => item.changed_at <= DateTime.now().toSeconds())
          );
        }

        return filteredHistory.map((rate): RateHistoryTableEntry => {
          let hourlyText = "non-union";
          if (rate.union_rate_id) {
            hourlyText = "union";
            const unionRate = lookupRateClassification(rate.union_rate_id);
            const prg = lookupPrg(unionRate?.pay_rate_group);
            if (unionRate && prg) {
              hourlyText = `${prg.label} - ${unionRate.classification}`;
            }
          }

          let changedBy = "Miter";
          if (ObjectID.isValid(rate.author)) {
            const user = lookupCompanyUser(rate.author);
            if (user) {
              if (!isMiterRep(user) && user.email) {
                changedBy = user.email;
              }
            } else {
              const tm = teamByUserId[rate.author];
              if (tm) {
                changedBy = tm.email || tm.full_name;
              } else {
                changedBy = "Deleted user";
              }
            }
          }

          let divisor = 1;
          if (rate.pay_type === "salary") {
            if (rate.salary_rate_display === "month") {
              divisor = 12;
            } else if (rate.salary_rate_display === "week") {
              divisor = workWeeksInYear;
            }
          }

          return {
            ...rate,
            tm: tm,
            tmActive: isTmActive(tm),
            payTypeFinal:
              rate.pay_type === "salary"
                ? "Salary"
                : rate.pay_type === "hourly"
                ? `Hourly (${hourlyText})`
                : "N/A",
            payRateFinal: (rate.pay_rate || 0) / divisor,
            payRateUnit:
              rate.pay_type === "salary"
                ? `per ${rate.salary_rate_display || "year"}`
                : rate.pay_type === "hourly"
                ? `per hour`
                : "N/A",
            changedBy,
            changedFormatted:
              rate.changed === "pay_rate"
                ? "Pay rate"
                : rate.changed === "initial"
                ? "N/A (initial setup)"
                : capitalize(rate.changed),
          };
        });
      })
      .sort((a, b) => b.changed_at - a.changed_at);
  }, [
    company,
    teamMember,
    dateRange,
    team,
    lookupCompanyUser,
    lookupPrg,
    lookupRateClassification,
    teamByUserId,
  ]);

  const columns = useMemo(() => {
    const cols: ColumnConfig<RateHistoryTableEntry>[] = [
      {
        field: "payTypeFinal",
        headerName: "Pay type",
        dataType: "string",
        width: 300,
        filter: "agSetColumnFilter",
      },
      {
        field: "payRateFinal",
        headerName: "Pay rate",
        dataType: "number",
        unit: "dollar",
        type: "rightAligned",
        width: 50,
        comparator: (v1, v2, node1, node2) => {
          const a = node1.data;
          const b = node2.data;
          if (!a || !b) return 0;
          const workHoursInYear = company ? getWorkHoursInYear(company) : DEFAULT_WORK_HOURS_IN_YEAR;
          const aRate = (a.pay_rate || 0) / (a.pay_type === "salary" ? workHoursInYear : 1);
          const bRate = (b.pay_rate || 0) / (b.pay_type === "salary" ? workHoursInYear : 1);
          return aRate - bRate;
        },
      },
      {
        field: "payRateUnit",
        headerName: "Unit",
        dataType: "string",
        width: 50,
        filter: "agSetColumnFilter",
      },
      {
        field: "title",
        headerName: "Title",
        dataType: "string",
      },
      {
        field: "changedFormatted",
        headerName: "Changed",
        dataType: "string",
        width: 50,
        filter: "agSetColumnFilter",
      },
      {
        field: "changed_at",
        headerName: "Changed at",
        dataType: "date",
        dateFormat: "ff",
      },
      {
        field: "changedBy",
        headerName: "Changed by",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
    ];
    if (!teamMember) {
      cols.splice(
        0,
        0,
        {
          field: "tm.full_name",
          headerName: "Team member",
          dataType: "string",
        },
        {
          field: "tm.friendly_id",
          headerName: "Team member ID",
          dataType: "string",
        },
        {
          field: "tmActive",
          headerName: "Active member",
          dataType: "boolean",
          initialHide: true,
        }
      );
    }
    return cols;
  }, [company, !!teamMember]);

  return (
    <TableV2
      data={tableData}
      columns={columns}
      id="team-member-rate-history"
      resource="history"
      showReportViews={true}
      displayDateRange={!teamMember}
      onDateRangeChange={setDateRange}
      containerStyle={{ height: "75%" }}
    />
  );
};
