import React, { useEffect, useState } from "react";
import { BasicModal, Formblock, Loader } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { reportList } from "../reportList";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import {
  EEOEthnicityCategory,
  EEOGenderCategory,
  EEOJobCategory,
} from "backend/models/teamMember/teamMemberTypes";
import { ColDef, NestedFieldPaths } from "ag-grid-community";
import { formatDate } from "dashboard/utils";
import { useActiveCompanyId, useActiveCompany, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { eeoEthnicityCategories } from "miter-utils";

export type EEOReportEntry = {
  jobCategory: EEOJobCategory | "N/A";
  payBand: string;
  gender: EEOGenderCategory | "N/A";
  ethnicity: EEOEthnicityCategory | "N/A";
  count: number;
};

export const EEOReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const lookupTeam = useLookupTeam();
  const teamAbilities = useTeamAbilities();

  const companyName = activeCompany?.check_company.trade_name;
  const demoReportingEnabled = activeCompany?.settings.team?.enable_demographic_reporting;
  const navigate = useNavigate();

  const lastYear = DateTime.now().year - 1;
  const yearOptions: Option<string>[] = [];
  for (let i = 0; i < 5; i++) {
    const yearToAdd = (lastYear - i).toString();
    yearOptions.push({ label: yearToAdd, value: yearToAdd });
  }

  // State
  const [data, setData] = useState<EEOReportEntry[] | undefined>();
  const [fetchingData, setFetchingData] = useState(false);
  const [payrollOptions, setPayrollOptions] = useState<Option<string>[]>([]);
  const [selectedPayrollOptions, setSelectedPayrollOptions] = useState<Option<string>[]>();

  const getPayrolls = async () => {
    if (!demoReportingEnabled || !activeCompanyId) return;
    setFetchingData(true);
    try {
      const response = await MiterAPI.payrolls.get_table({ company: activeCompanyId });
      if (response.error) throw new Error(response.error);

      const options = response
        .filter((p) => p.status !== "draft" && p.type === "regular")
        .map((payroll) => {
          return {
            label:
              (payroll.pay_schedule ? `(${payroll.pay_schedule}) ` : "") +
              formatDate(payroll.period_start, payroll.period_end, true),
            value: payroll._id.toString(),
          };
        });
      setPayrollOptions(options);
    } catch (e) {
      console.error("Error fetching payrolls for 401K report.");
      Notifier.error(
        "There was an error preparing the report. Our engineers have been notified and are investigating."
      );
    }
    setFetchingData(false);
  };

  const getData = async () => {
    if (!activeCompanyId) return;
    if (!selectedPayrollOptions) {
      setData([]);
      return;
    }
    setFetchingData(true);
    try {
      const payload = {
        type: "eeo",
        params: {
          payrollIds: selectedPayrollOptions.map((p) => p.value),
          company: activeCompanyId,
        },
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const filteredData = response.filter((e) => {
        const teamMember = lookupTeam(e.teamMemberId);
        return teamAbilities.can("read_sensitive", teamMember);
      });

      setData(filteredData);
    } catch (e) {
      console.error("Error fetching EEO report data.");
      Notifier.error(
        "There was an error preparing the report. Our engineers have been notified and are investigating."
      );
    }
    setFetchingData(false);
  };

  useEffect(() => {
    getPayrolls();
  }, []);

  useEffect(() => {
    getData();
  }, [selectedPayrollOptions]);

  const reportObject = reportList.find((report) => report.slug === "eeo_1");

  const fileName = "Miter EEO Report";

  return (
    <div className="page-content">
      <Helmet>
        <title>EEO-1 Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>EEO-1 report</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 600 }}>
        <Formblock
          label="Pay period"
          type="multiselect"
          options={payrollOptions}
          name="payroll"
          form={useForm()}
          editing={true}
          onChange={setSelectedPayrollOptions}
          height="unset"
        />
      </div>
      {!demoReportingEnabled && (
        <BasicModal
          yellowBodyText={true}
          headerText="EEO Reporting not yet enabled"
          bodyText={`${companyName} has not yet enabled EEO Reporting. You can enable EEO Reporting within Team Settings.`}
          button2Action={() => navigate("/team-members/settings")}
          button2Text="Continue"
          button1Action={() => navigate("/reports")}
          button1Text="Cancel"
        />
      )}
      {fetchingData && !data && <Loader />}
      {data && (
        <>
          <AgGridTable
            reportId="eeo_1"
            data={data}
            columnDefs={colDefs}
            fileName={fileName}
            gridOptions={{
              pivotMode: true,
              autoGroupColumnDef: {
                cellRendererParams: {
                  suppressCount: true,
                },
                headerName: "Job category",
                minWidth: 250,
              },
            }}
          />
          <div className="vertical-spacer"></div>
        </>
      )}
    </div>
  );
};

const ethnicityColDefs = eeoEthnicityCategories.concat("N/A").map((c) => {
  return {
    headerName: c,
    field: ("counts." + c) as NestedFieldPaths<EEOReportEntry, unknown>,
    aggFunc: "sumValues",
  };
});

export const colDefs: ColDef<EEOReportEntry>[] = [
  {
    headerName: "Job category",
    field: "jobCategory",
    rowGroup: true,
    initialHide: true,
    filter: true,
  },
  {
    headerName: "Gender",
    field: "gender",
    initialHide: true,
    pivot: true,
  },

  ...ethnicityColDefs,
];
