import React, { FC, ReactElement, ReactNode, RefObject, useMemo, useRef, useState, Children } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import useWizard from "./useWizard";
import WizardContext from "./WizardContext";
import { List, SignOut, Check } from "phosphor-react";
import { Button } from "../button";
import { motion } from "framer-motion";
import styles from "./Wizard.module.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  DEFAULT_BACK_BUTTON_TEXT,
  DEFAULT_EARLY_EXIT_BUTTON_TEXT,
  DEFAULT_NEXT_BUTTON_TEXT,
} from "../utils/wizard-utils";
import { use100vh } from "react-div-100vh";

type WizardProps = {
  /** The index of the screen to start on. Defaults to 0 */
  startIndex?: number;
  /** The screens to display in the wizard */
  children: ReactNode;
  /**  Exit handler for the wizard */
  onExit: () => void;
  /** Complete handler for the wizard */
  onComplete?: () => void;
  /** Default can go next - if true, the next button will be enabled by default */
  defaultCanGoNext?: boolean;
  /** Special configurations to the implementation of the Wizard */
  configurations?: {
    initiallyShowSidebar?: boolean;
  };
};

/****************************************************************************************************
 * Wizard
 *
 * - This component is responsible for rendering the wizard
 *   and managing the state of the wizard
 *
 * - It takes in a list of screens and renders them in order
 *   based on the current index
 *
 * - Each of the screens is wrapped in a WizardScreen component
 *   which is responsible for rendering the screen and managing
 *   the state of the screen
 *****************************************************************************************************/
const Wizard: FC<WizardProps> = ({ startIndex, children, defaultCanGoNext, ...props }) => {
  const isMounted = useRef<boolean>(false);

  // The index of the current screen
  const [curIndex, setCurIndex] = useState(startIndex || 0);

  // Variables to keep track of the loading state
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [loadingEarlyExit, setLoadingEarlyExit] = useState(false);

  // Variables to customize the back and next buttons
  const [backButtonText, setBackButtonText] = useState<ReactElement | string>(DEFAULT_BACK_BUTTON_TEXT);
  const [nextButtonText, setNextButtonText] = useState<ReactElement | string>(DEFAULT_NEXT_BUTTON_TEXT);
  const [earlyExitButtonText, setEarlyExitButtonText] = useState<ReactElement | string>(
    DEFAULT_EARLY_EXIT_BUTTON_TEXT
  );

  // Show sidebar
  const [showSidebar, setShowSidebar] = useState(!!props?.configurations?.initiallyShowSidebar ?? false);

  // TODO: Wizard handler functions need to be callBacks to consider state changes to the passed function.

  // Next, back, exit actions to be called when the buttons are clicked
  const onNext = useRef<(() => Promise<void> | void) | null>(null);
  const onBack = useRef<(() => Promise<void> | void) | null>(null);
  const onEarlyExit = useRef<(() => Promise<void> | void) | null>(null);
  const onExit = useRef<(() => Promise<void> | void) | null>(props.onExit);
  const onComplete = useRef<(() => Promise<void> | void) | null | undefined>(props?.onComplete);

  useEffect(() => {
    if (Children.count(children) <= curIndex) {
      console.log("Wizard: The current start index is greater than the number of screens.");
      setCurIndex(0);
    }
  }, [children]);

  // Unbind any event listeners when the current screen changes
  useEffect(() => {
    return () => {
      onNext.current = null;
      onBack.current = null;
      onEarlyExit.current = null;
      onExit.current = props.onExit;
      onComplete.current = props.onComplete;
    };
  }, [curIndex]);

  /** Array of screen names in the wizard */
  const screens = useMemo(() => {
    const screenList = React.Children.map(children, (child) => {
      return (child as ReactElement)?.props?.name;
    })?.filter((name) => name) as string[];

    return screenList;
  }, [children]);

  // Number of steps in the wizard
  const stepCount = React.Children.toArray(children).length;

  // Functions to bind the custom handler navigation functions to (passed in via each screen)
  const setNext = useRef((handler: () => Promise<void> | void) => (onNext.current = handler));
  const setBack = useRef((handler: () => Promise<void> | void) => (onBack.current = handler));
  const setEarlyExit = useRef((handler: () => Promise<void> | void) => (onEarlyExit.current = handler));
  const setExit = useRef((handler: () => Promise<void> | void) => (onExit.current = handler));
  const setComplete = useRef((handler: () => Promise<void> | void) => (onComplete.current = handler));

  // Variables to allow the wizard to go forward / backward
  const [canNext, setCanNext] = useState(defaultCanGoNext || curIndex < stepCount - 1);
  const [canBack, setCanBack] = useState(curIndex > 0);
  const [canEarlyExit, setCanEarlyExit] = useState(false);

  // @ts-expect-error Makes sure the body is not scrollable when the wizard is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  // Update can next to defaults when we move pages
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    setCanNext(defaultCanGoNext || curIndex < stepCount - 1);
    setCanBack(curIndex > 0);
    setCanEarlyExit(false);
  }, [curIndex]);

  // Handler for the next button click
  const handleNext = () => {
    if (!canNext) return;

    if (onNext.current) {
      setLoadingNext(true);

      // Call the next handler and move forward if there is no error
      Promise.resolve(onNext.current())
        .then(() => {
          setCurIndex(curIndex + 1);
          setLoadingNext(false);
        })
        .catch(() => setLoadingNext(false));
    } else {
      setCurIndex(curIndex + 1);
    }
  };

  // Handler for the back button click
  const handleBack = () => {
    if (!canBack) return;

    if (onBack.current) {
      setLoadingBack(true);

      // Call the back handler and move back if there is no error
      Promise.resolve(onBack.current())
        .then(() => {
          setCurIndex(curIndex - 1);
          setLoadingBack(false);
        })
        .catch(() => setLoadingBack(false));
    } else {
      setCurIndex(curIndex - 1);
    }
  };

  // Handler for the early exit button click
  const handleEarlyExit = () => {
    if (!canEarlyExit) return;

    if (onEarlyExit.current) {
      setLoadingEarlyExit(true);

      // Call the early exit handler and exit if there is no error
      Promise.resolve(onEarlyExit.current())
        .then(() => {
          handleExit();
          setLoadingEarlyExit(false);
        })
        .catch(() => setLoadingEarlyExit(false));
    } else {
      handleExit();
    }
  };

  // Handler for when a user wants to go to a specific screen
  const handleGoTo = (screen: number) => {
    if (typeof screen === "string") {
      const index = React.Children.toArray(children).findIndex(
        (child) => (child as unknown as typeof WizardScreen)?.defaultProps?.name === screen
      );
      if (index === -1) return;

      setCurIndex(index);
    } else if (typeof screen === "number") {
      if (screen < 0 || screen > stepCount - 1) return;

      setCurIndex(screen);
    }
  };

  // Handler for when a user wants to exit the wizard
  const handleExit = () => {
    if (onExit.current) {
      onExit.current();
    }
  };

  // Handler for when a user wants to complete the wizard
  const handleComplete = () => {
    if (onComplete.current) {
      onComplete.current();
    }
  };

  // Gets the active screen
  const activeScreen = useMemo(() => {
    const reactChildren = React.Children.toArray(children);

    return reactChildren[curIndex];
  }, [curIndex, children]);

  return (
    <WizardContext.Provider
      value={{
        curIndex,
        setCurIndex,
        handleNext,
        handleBack,
        handleGoTo,
        handleExit,
        handleEarlyExit,
        handleComplete,
        loadingNext,
        loadingBack,
        loadingEarlyExit,
        setNext: setNext.current,
        setBack: setBack.current,
        setExit: setExit.current,
        setEarlyExit: setEarlyExit.current,
        setComplete: setComplete.current,
        canNext,
        canBack,
        canEarlyExit,
        setCanNext,
        setCanBack,
        setCanEarlyExit,
        backButtonText,
        setBackButtonText,
        nextButtonText,
        setNextButtonText,
        earlyExitButtonText,
        setEarlyExitButtonText,
        screens,
        showSidebar,
        setShowSidebar,
      }}
    >
      <motion.div
        className={styles["wizard"]}
        // Modal pop up animation
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
      >
        <WizardHeader />
        {activeScreen}
        <WizardFooter />
      </motion.div>
    </WizardContext.Provider>
  );
};

export default Wizard;

type WizardScreenProps = {
  /** Unique name + title for the screen */
  name: string;
  /** The content of the screen */
  children: ReactNode;
  /** Function to call when the back button is clicked */
  onBack?: () => void | Promise<void>;
  /** Function to call when the next button is clicked */
  onNext?: () => void | Promise<void>;
  /** Function to call when the early exit button is clicked */
  onEarlyExit?: () => void | Promise<void>;
  /** Custom text for the back button */
  backButtonText?: string | ReactElement;
  /** Custom text for the next button */
  nextButtonText?: string | ReactElement;
  /** Custom text for the early exit button */
  earlyExitButtonText?: string | ReactElement;
  /** Ref to the screen */
  setRef?: (ref: RefObject<HTMLDivElement>) => void;
  /** Override styes */
  style?: React.CSSProperties;
};

export const WizardScreen: FC<WizardScreenProps> = ({
  name,
  children,
  onBack,
  onNext,
  onEarlyExit,
  backButtonText,
  nextButtonText,
  earlyExitButtonText,
  style,
  setRef,
}) => {
  // Consume wizard context
  const { setNext, setBack, setEarlyExit, setNextButtonText, setBackButtonText, setEarlyExitButtonText } =
    useWizard();

  const screenRef = useRef<HTMLDivElement>(null);

  // Attach the ref to the parent
  useEffect(() => {
    setRef?.(screenRef);
  }, []);

  // Needed to due to safari/mobile navigation bar
  // https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
  const height = use100vh();

  // Setup the next/back handlers w/ use callback to prevent re-renders
  const nextFunc = useCallback(() => Promise.resolve(onNext ? onNext() : Promise.resolve()), [onNext]);
  const backFunc = useCallback(() => Promise.resolve(onBack ? onBack() : Promise.resolve()), [onBack]);
  const earlyExitFunc = useCallback(
    () => Promise.resolve(onEarlyExit ? onEarlyExit() : Promise.resolve()),
    [onEarlyExit]
  );

  // Throw an error if the name is not defined
  useEffect(() => {
    if (!name) throw new Error("Wizard screen name is not defined");
  }, [name]);

  // Attach the next/back handlers to the wizard context
  useEffect(() => {
    if (onNext) setNext(onNext);
  }, [nextFunc]);

  useEffect(() => {
    if (onBack) setBack(onBack);
  }, [backFunc]);

  useEffect(() => {
    if (onEarlyExit) setEarlyExit(onEarlyExit);
  }, [earlyExitFunc]);

  // Set the back/next button text
  useEffect(() => {
    if (backButtonText) setBackButtonText(backButtonText);
    else setBackButtonText(DEFAULT_BACK_BUTTON_TEXT);
  }, [backButtonText]);

  useEffect(() => {
    if (nextButtonText) setNextButtonText(nextButtonText);
    else setNextButtonText(DEFAULT_NEXT_BUTTON_TEXT);
  }, [nextButtonText]);

  useEffect(() => {
    if (earlyExitButtonText) setEarlyExitButtonText(earlyExitButtonText);
    else setEarlyExitButtonText(DEFAULT_EARLY_EXIT_BUTTON_TEXT);
  }, [earlyExitButtonText]);

  return (
    <div
      className={styles["wizard-screen"] + " " + "wizard-screen-" + name}
      style={{ height: `calc(${height}px - 125px)`, ...style }}
      ref={screenRef}
    >
      <div className={styles["wizard-screen-content"]}>{children}</div>
    </div>
  );
};

const WizardFooter: FC = () => {
  // Consume wizard context
  const {
    handleNext,
    handleBack,
    handleEarlyExit,
    canBack,
    loadingBack,
    loadingNext,
    loadingEarlyExit,
    canNext,
    nextButtonText,
    backButtonText,
    earlyExitButtonText,
    canEarlyExit,
  } = useWizard();

  return (
    <div className={styles["wizard-footer"]}>
      {canBack && (
        <Button
          onClick={handleBack}
          disabled={!canBack}
          loading={loadingBack}
          className={"button-1 " + styles["wizard-back-btn"]}
        >
          {backButtonText}
        </Button>
      )}
      <div className="flex margin-left-auto">
        {handleEarlyExit && canEarlyExit && (
          <Button
            onClick={handleEarlyExit}
            disabled={!canEarlyExit}
            loading={loadingEarlyExit}
            className={"button-1 " + styles["wizard-next-btn"]}
            wrapperClassName={styles["wizard-next-btn-wrapper"]}
          >
            {earlyExitButtonText}
          </Button>
        )}
        <Button
          onClick={handleNext}
          disabled={!canNext}
          loading={loadingNext}
          className={"button-2 " + styles["wizard-next-btn"]}
          wrapperClassName={styles["wizard-next-btn-wrapper"]}
        >
          {nextButtonText}
        </Button>
      </div>
    </div>
  );
};

const WizardHeader: FC = () => {
  const { screens, curIndex, handleGoTo, handleExit, showSidebar, setShowSidebar } = useWizard();
  const { width } = useWindowDimensions();
  const [sidebarWasShown, setSidebarWasShown] = useState(showSidebar);

  useEffect(() => {
    if (showSidebar) setSidebarWasShown(true);
  }, [showSidebar]);

  const goToScreen = (index: number) => {
    if (index > screens.length - 1 || index < 0 || index > curIndex) return;
    handleGoTo(index);
  };

  const renderSidebarItem = (screen: string, index: number) => {
    const buttonClassName =
      styles["wizard-header-steps-list-btn"] + " " + (index === curIndex ? styles["active"] : "");

    const buttonStyles = { color: index < curIndex ? "#4d55bb" : undefined };
    const stepNumberStyles = {
      backgroundColor: index < curIndex ? "#4d55bb" : undefined,
      color: index < curIndex ? "#fff" : undefined,
    };

    return (
      <div key={screen} className={styles["wizard-header-steps-list-item"]}>
        <Button className={buttonClassName} style={buttonStyles} onClick={() => goToScreen(index)}>
          <span className={styles["wizard-header-steps-list-step-number"]} style={stepNumberStyles}>
            {curIndex > index ? <Check weight="bold" /> : index + 1}
          </span>

          {screen}
        </Button>
      </div>
    );
  };

  const renderSidebar = () => {
    const stepClass =
      styles["wizard-header-steps-list"] +
      " " +
      (showSidebar ? styles["slide-in"] : !sidebarWasShown ? "" : styles["slide-out"]);

    return (
      <div className={stepClass}>{screens.map((screen, index) => renderSidebarItem(screen, index))}</div>
    );
  };

  const renderMenuButton = () => {
    if (screens.length === 1) return <></>;

    const showStepStatus = width > 768;

    const stepStatus = (
      <span className={styles["wizard-header-step-text"]}>
        {curIndex + 1} of {screens.length}
      </span>
    );

    return (
      <Button
        onClick={() => setShowSidebar(!showSidebar)}
        className={"button-text " + styles["wizard-header-menu-button"]}
      >
        <List weight="bold" />
        {showStepStatus ? stepStatus : <></>}
      </Button>
    );
  };

  return (
    <>
      <div className={styles["wizard-header"]}>
        <div className={styles["wizard-header-steps"]}>{renderMenuButton()}</div>
        <div className={styles["wizard-header-title"]}>
          <h1 className={styles["wizard-header-title-text"]}>{screens[curIndex]}</h1>
        </div>

        <div className={styles["wizard-header-exit"]}>
          <Button onClick={handleExit} className={"button-text " + styles["wizard-header-exit-button"]}>
            <span style={{ marginRight: 7, fontSize: "1.1rem" }}>Exit</span>
            <SignOut />
          </Button>
        </div>
      </div>
      <WizardProgressBar />
      {renderSidebar()}
    </>
  );
};

const WizardProgressBar: FC = () => {
  const { curIndex, screens } = useWizard();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const newProgress = (curIndex / screens.length) * 100;
    setProgress(newProgress);
  });

  return (
    <div className={styles["wizard-progress-bar"]}>
      <div className={styles["wizard-progress-bar-progress"]} style={{ width: `${progress}%` }} />
    </div>
  );
};
