import React, { useCallback, useContext, useMemo } from "react";
import { DateTime } from "luxon";
import {
  liveTimesheetLocation,
  TimesheetGpsComponent,
} from "dashboard/pages/timesheets/TimesheetGeofenceIndicator";
import {
  Activity,
  AggregatedJob,
  AggregatedLiveTimesheet,
  AggregatedPayRateGroup,
  AggregatedTeamMember,
  AggregatedTimesheet,
  Job,
  TeamMember,
  UnionRate,
  MiterIntegrationForCompany,
  ApprovalHistory,
  MiterAPI,
  LiveTimesheet,
  MiterError,
} from "dashboard/miter";
import { cloneDeep, keyBy, truncate } from "lodash";
import { Assign } from "utility-types";
import {
  useActiveCompany,
  useActiveJobs,
  useActiveTeamMember,
  useActivities,
  useCostTypes,
  useEquipment,
  useGetClassificationOptions,
  useLookupCompanyUsers,
  useLookupPrg,
  useLookupRateClassification,
  useLookupTeam,
  useLookupTimeOffPolicy,
  useSelectableActivitiesMap,
  useTeam,
} from "dashboard/hooks/atom-hooks";
import { LookupAtomFunction } from "dashboard/atoms";
import { PayRateItem } from "backend/utils/payroll/types";
import { metersToMiles, lookupTimezoneLabel, notNullish, buildCompanyUserName, getOS } from "miter-utils";
import { Timesheet } from "dashboard/miter";
import { getTimesheetGeolocationMessage } from "./geolocation";
import AppContext from "dashboard/contexts/app-context";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TimesheetSignOff } from "backend/models/timesheet";
import { Notifier } from "ui";
import { ESignatureInputValue } from "ui/form/ESignatureInput";
import { buildFlatfileMessage } from "./flatfile";
import { isFullRow, PrelimTimesheetImportRow } from "dashboard/components/timesheets/TimesheetImporter";
import { IDataHookRecord } from "@flatfile/adapter/build/main/interfaces";
import { parseClockInTime } from "miter-utils";
import { TimesheetPolicyField } from "backend/models/policy";

export type TimesheetTableEntry = Assign<
  AggregatedTimesheet,
  {
    date: number;
    geofence_status: React.ReactElement;
    clock_in_object: DateTime;
    clock_in_string: string;
    clock_out_string: string;
    clock_in_local_string: string;
    clock_out_local_string: string;
    status: AggregatedTimesheet["status"] | "archived";
    distance_traveled_miles: number;
    geofence_status_string: string;
  }
>;

export type SearchTimesheetTableEntry = Assign<
  AggregatedTimesheet,
  {
    date: string;
    team_member_name: string;
    activity_name: string;
    job_name: string;
    has_notes: boolean | null;
    has_pics: boolean | null;
    geofence_status: React.ReactElement;
    clock_in_string: string;
    clock_out_string: string;
    clock_in_local_string: string;
    clock_out_local_string: string;
    status: AggregatedTimesheet["status"] | "archived";
  }
>;

export const formatTimesheetTime = (timestamp: number, zone?: string): string => {
  return DateTime.fromSeconds(timestamp).setZone(zone).toLocaleString(DateTime.TIME_SIMPLE);
};

export const useCleanTimesheets = (): ((
  timesheets: AggregatedTimesheet[],
  timezone?: string
) => TimesheetTableEntry[]) => {
  const lookupRateClassification = useLookupRateClassification();
  const lookupPrg = useLookupPrg();
  const activeCompany = useActiveCompany();
  const radiusMiles = activeCompany?.settings.timesheets.geofence_radius;

  return (timesheets, timezone) =>
    timesheets.map((ts) => {
      const clock_in_dt = DateTime.fromSeconds(ts.clock_in).setZone(timezone);

      const classificationId = ts.classification_override || ts.pay_rate?.classification?.source_id;
      const classification = lookupRateClassification(classificationId);
      const prg = classification ? lookupPrg(classification.pay_rate_group) : undefined;

      const finalTz = ts.timezone || ts.job?.timezone || activeCompany?.timezone;

      return {
        ...ts,
        date: clock_in_dt.toSeconds(),
        clock_in_object: clock_in_dt,
        clock_in_string: formatTimesheetTime(ts.clock_in, timezone),
        clock_out_string: formatTimesheetTime(ts.clock_out, timezone),
        clock_in_local_string:
          formatTimesheetTime(ts.clock_in, finalTz) + ` (${lookupTimezoneLabel(finalTz)})`,
        clock_out_local_string:
          formatTimesheetTime(ts.clock_out, finalTz) + ` (${lookupTimezoneLabel(finalTz)})`,
        geofence_status: <TimesheetGpsComponent ts={ts} />,
        geofence_status_string: getTimesheetGeolocationMessage(ts, radiusMiles),
        status: ts.archived ? "archived" : ts.status,
        classification: classification?.classification,
        prg: prg?.label,
        distance_traveled_miles: metersToMiles(ts.geolocation?.distance_traveled),
      };
    });
};

export const cleanTimesheetsFromBackend = (
  rawTimesheets: AggregatedTimesheet[],
  companyTimezone?: string,
  radiusMiles?: number
): TimesheetTableEntry[] => {
  const cleanedTimesheets: TimesheetTableEntry[] = rawTimesheets.map((ts) => {
    const clock_in_dt = DateTime.fromSeconds(ts.clock_in);
    const finalTz = ts.timezone || ts.job?.timezone || companyTimezone;

    return {
      ...ts,
      date: clock_in_dt.toSeconds(),
      clock_in_object: clock_in_dt,
      clock_in_string: formatTimesheetTime(ts.clock_in),
      clock_out_string: formatTimesheetTime(ts.clock_out),
      clock_in_local_string: formatTimesheetTime(ts.clock_in, finalTz) + ` (${lookupTimezoneLabel(finalTz)})`,
      clock_out_local_string:
        formatTimesheetTime(ts.clock_out, finalTz) + ` (${lookupTimezoneLabel(finalTz)})`,
      geofence_status: <TimesheetGpsComponent ts={ts} />,
      geofence_status_string: getTimesheetGeolocationMessage(ts, radiusMiles),
      status: ts.archived ? "archived" : ts.status,
      distance_traveled_miles: metersToMiles(ts.geolocation?.distance_traveled),
    };
  });

  return cleanedTimesheets;
};

export const cleanLiveTimesheetsFromBackend = (
  rawTimesheets: AggregatedLiveTimesheet[],
  radiusMiles?: number
): LiveTimesheetTableEntry[] => {
  const cleanedLiveTimesheets: LiveTimesheetTableEntry[] = rawTimesheets.map((ts) => {
    return {
      ...ts,
      team_member_name: ts.team_member.full_name,
      first_name: ts.team_member ? ts.team_member.first_name : "",
      last_name: ts.team_member ? ts.team_member.last_name : "",
      activity_name: ts.activity ? ts.activity.label : "-",
      job_name: ts.job ? truncate(ts.job.name, { length: 50 }) : "-",
      clock_in_string: DateTime.fromSeconds(ts.clock_in).toFormat("ccc, MMMM dd, h:mm a"),
      geofence_status: liveTimesheetLocation(ts, radiusMiles),
    };
  });
  return cleanedLiveTimesheets;
};

export type LiveTimesheetTableEntry = Assign<
  AggregatedLiveTimesheet,
  {
    team_member_name: string;
    activity_name: string;
    job_name: string;
    geofence_status: React.ReactElement;
    clock_in_string: string;
  }
>;

export const getTooltipForTimesheetPayRate = (
  ts: AggregatedTimesheet,
  lookupPrg: LookupAtomFunction<AggregatedPayRateGroup>,
  lookupClassification: LookupAtomFunction<UnionRate>
): string => {
  return getTooltipForPayRate({
    pri: ts.pay_rate,
    teamMember: ts.team_member,
    lookupPrg,
    lookupClassification,
    job: ts.job,
    activity: ts.activity,
  });
};

export const getTooltipForPayRate = (params: {
  pri: PayRateItem;
  teamMember: AggregatedTeamMember | TeamMember;
  lookupPrg: LookupAtomFunction<AggregatedPayRateGroup>;
  lookupClassification: LookupAtomFunction<UnionRate>;
  job?: Job | AggregatedJob;
  activity?: Activity;
  isEarning?: boolean;
}): string => {
  const { pri, teamMember, lookupPrg, lookupClassification, job, activity, isEarning } = params;
  let tooltip = "";
  const firstName = teamMember.first_name;
  const urr = pri.union_rate_reference;

  if (pri.method === "custom") {
    tooltip = "The custom pay rate specified for this timesheet";
  } else if (pri.method === "activity") {
    // If the prevailing wage rate is higher
    if (pri.method_reference.activity_pay_rate_type === "custom") {
      tooltip = `The custom pay rate for ${activity?.label}`;
    } else if (pri.method_reference.activity_pay_rate_type === "pw") {
      tooltip = `The prevailing wage for ${activity?.label}, plus cash in lieu of fringes`;
    }
  } else if (pri.method === "team_member") {
    if (teamMember.pay_type === "salary") {
      tooltip = `${firstName}'s default salary`;
    } else {
      tooltip = `${firstName}'s default pay rate`;
      if (pri.method_reference.compared_base && pri.pw_reference?.fringe_paid_as_cash?.reg) {
        tooltip += " plus cash in lieu of fringes";
      }
    }
  } else if (pri.method === "union_rate" && urr?.rate_id) {
    const refType = urr.type;
    if (refType === "ts_classification_override") {
      tooltip = `The specified classification for this ${isEarning ? "earning" : "timesheet"}`;
    } else {
      const prg = lookupPrg(urr?.group_id_specified);
      const prgLabel = prg?.label || "the rate group";
      const rate = lookupClassification(urr.rate_id);
      const rateLabel =
        rate?.classification ||
        (pri.classification?.source === "union_rate" ? pri.classification.label : undefined);
      if (refType === "classification_specific") {
        tooltip = `The rate for ${rateLabel}, the classification assigned to ${activity?.label}`;
      } else if (refType === "group_specific") {
        if (urr.matched_on === "no_match") {
          tooltip = `${firstName}'s default hourly rate, since they don't have an assigned classification within ${prgLabel}`;
        } else {
          let prgString = `${firstName}'s hourly rate`;
          if (urr.matched_on === "prg_default") {
            prgString += " based on the group's default classification";
          } else if (urr.matched_on === "standard_classification") {
            prgString += ` based on ${
              urr.standard_classification_source === "team_member" ? firstName : "the timesheet"
            }'s standard classification`;
          }
          prgString += ` for ${prgLabel}, the pay rate group assigned to `;
          if (urr.group_source === "activity") {
            prgString += `${activity?.label}`;
          } else if (urr.group_source === "job") {
            prgString += `${job?.name}`;
          }
          tooltip = prgString;
        }
      } else if (refType === "default") {
        tooltip = `${firstName}'s default classification hourly rate`;
      }
    }
  } else if (pri.method === "constant_salary") {
    tooltip = `The adjusted hourly rate to hold ${firstName}'s salary constant.`;
  }
  return tooltip;
};

export const getHeaderText = (ts: AggregatedTimesheet): string | null => {
  if (!ts) return null;
  const clockInDt = DateTime.fromSeconds(ts.clock_in);
  if (!clockInDt) return null;
  let dateString = clockInDt.toLocaleString({ month: "long", day: "numeric" });
  const now = DateTime.now();
  const diffInYears = now?.diff(clockInDt, "years").toObject()?.years;
  if (diffInYears && diffInYears > 1) {
    dateString = clockInDt.toLocaleString(DateTime.DATE_MED);
  }
  const name = ts.team_member.first_name?.slice(0, 1) + ". " + ts.team_member.last_name;
  return dateString + " timesheet for " + name;
};

export const handleTmClick = (tmId?: string): void => {
  if (!tmId) return;
  window.open(window.location.origin + "/team-members/" + tmId, "_blank");
};

export const creationMethodBadgeLookup = {
  dashboard: {
    label: "Admin entry",
    color: "green",
  },
  app_manual: {
    label: "App manual entry",
    color: "gray",
  },
  app_clock_in: {
    label: "App clock in",
    color: "blue",
  },
  kiosk_clock_in: {
    label: "Kiosk",
    color: "light-purple",
  },
  integration: {
    label: "Integration",
    color: "orange",
  },
  bulk_import: {
    label: "Bulk import",
    color: "light-green",
  },
  dashboard_clock_in: {
    label: "Dashboard clock in",
    color: "light-blue",
  },
};

export const excludeLiveTimesheetsToggle = (path: string): boolean => {
  return path !== "live";
};

export const useTimesheetSourceSystem = (
  timesheet?: AggregatedTimesheet
): MiterIntegrationForCompany | undefined => {
  const { integrations } = useContext(AppContext);

  let sourceSystem: MiterIntegrationForCompany | undefined;
  if (timesheet?.integrations) {
    const key = Object.keys(timesheet.integrations)[0];
    if (key) sourceSystem = integrations.find((i) => i.key === key);
  }

  return sourceSystem;
};

type GetApproverNames = (timesheet: AggregatedTimesheet | TimesheetTableEntry | undefined) => string;

export const useTimesheetApprover = (): GetApproverNames => {
  const lookupTeam = useLookupTeam();
  const lookupCompanyUser = useLookupCompanyUsers();

  const getApprover = useCallback(
    (timesheet: AggregatedTimesheet | TimesheetTableEntry | undefined): string => {
      // If there is no timesheet, return a dash
      if (!timesheet) return "-";

      // If this is a legacy timesheet and has an approved_by field, use that
      if (timesheet.approved_by) return getTimesheetApprovalString(timesheet, true);

      // Otherwise, use the approval history to build a list of approvers
      const history = (timesheet.approval_history || []) as ApprovalHistory;
      const approvers = history.filter((t) => t.action === "approved").map((t) => t.approver);

      const approverNames = approvers.map((a) => {
        if (a?.team_member_id) {
          return lookupTeam(a.team_member_id)?.full_name;
        } else {
          const user = lookupCompanyUser(a?.user_id);
          return buildCompanyUserName(user);
        }
      });

      return approverNames.filter(notNullish).join(", ");
    },
    [lookupTeam, lookupCompanyUser]
  );

  return getApprover;
};

export const getTimesheetApprovalString = (
  timesheet: AggregatedTimesheet | Timesheet | TimesheetTableEntry,
  hideTime?: boolean
): string => {
  if (!timesheet!.approved_at || !timesheet!.approved_by) return "-";
  const timeText = DateTime.fromSeconds(timesheet!.approved_at).toLocaleString({
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "short",
  });

  const { first_name, last_name, email } =
    typeof timesheet.approved_by === "object"
      ? timesheet.approved_by
      : { first_name: "", last_name: "", email: "" };

  if (first_name && last_name) {
    const userText = first_name + " " + last_name;
    if (hideTime) return userText;

    return timeText + " by " + userText;
  } else {
    if (hideTime) return email || "";
    return timeText + " by " + email;
  }
};

export const TIMESHEET_DT_FORMAT = "MMM d, yyyy 'at' h:mm:ss a ZZZZ";

export const useCanClockInFromDashboard = (): boolean => {
  const company = useActiveCompany();
  const teamMember = useActiveTeamMember();
  const abilities = useMiterAbilities();

  if (!teamMember || !company || !abilities.can("timesheets:personal:dashboard_clock_in")) return false;
  return true;
};

export type ActiveTimesheetButtonState = "no-live-timesheet" | "clocked-in" | "on-break";

const fillDigits = (digits: number): string => `0${digits}`.slice(-2);

const getElapsedTime = (startTime: DateTime, endTime: DateTime) => {
  const total = endTime.toSeconds() - startTime.toSeconds(); // seconds
  const seconds = Math.floor(total % 60);
  const minutes = Math.floor((total / 60) % 60);
  const hours = Math.floor(total / (60 * 60));
  return {
    total,
    hours,
    minutes,
    seconds,
  };
};

// use new Date for endtime if you need to 'now'
export const getFormattedElapsedTime = (
  startTime: DateTime | undefined,
  endTime: DateTime | undefined,
  noSeconds = false
): string => {
  if (!startTime || !endTime) return "";
  const { hours, minutes, seconds } = getElapsedTime(startTime, endTime);
  const elapsedHours = hours === 0 ? "" : hours + "h ";
  const elapsedMinutes = minutes === 0 ? "" : fillDigits(minutes) + "m ";
  if (noSeconds)
    return hours === 0 && minutes === 0
      ? seconds + "s"
      : hours === 0
      ? minutes + "m"
      : elapsedHours + elapsedMinutes;
  else return elapsedHours + elapsedMinutes + fillDigits(seconds) + "s";
};

export const prepareTimesheetSignOff = async (
  signOffData: ESignatureInputValue,
  companyId: string,
  teamMember: TeamMember,
  timesheetId: string
): Promise<TimesheetSignOff> => {
  const esignParams = {
    company_id: companyId,
    document_type: "timesheet" as const,
    parent_document_id: timesheetId,
    signature: {
      image: signOffData.data || "",
      device: { type: "desktop" as const, os: getOS() || undefined },
      application: { name: "dashboard" },
      ip_address: "::1", // will be stripped out by the backend
    },
    signer: {
      name: `${teamMember.full_name}`,
      type: "team_member" as const,
      title: teamMember.title || "None",
    },
  };

  try {
    const res = await MiterAPI.esignature_items.signatures.create([esignParams]);
    if (res.error) throw new Error(res.error);
    return {
      data: signOffData.data,
      status: "completed",
      timestamp: DateTime.now().toSeconds(),
      esignature_item_id: res[0]?._id,
    };
  } catch (e: $TSFixMe) {
    Notifier.error("Failed to save signature.");
  }

  return {
    data: signOffData.data,
    status: "completed",
    timestamp: DateTime.now().toSeconds(),
  };
};

export const bulkTimesheetSignOff: (
  signOffData: ESignatureInputValue,
  companyId: string,
  teamMember: TeamMember,
  timesheetIds: string[]
) => Promise<
  (AggregatedTimesheet &
    MiterError & {
      error_status: number;
    })[]
> = async (
  signOffData: ESignatureInputValue,
  companyId: string,
  teamMember: TeamMember,
  timesheetIds: string[]
) => {
  return await Promise.all(
    timesheetIds.map(async (timesheetId) => {
      const timesheetSignOff = await prepareTimesheetSignOff(signOffData, companyId, teamMember, timesheetId);
      return await MiterAPI.timesheets.update_one(timesheetId, { sign_off: timesheetSignOff });
    })
  );
};

/*
  Live timesheet break validation functions
*/
export type LiveTimesheetErrorMessage = {
  type: "error" | "warning";
  message: string;
};

/*
  validates that clock out time is compatible with clock in and break times
  - if clock out time is before or more than 24 hours after clock in, display an error
  - if breaks have to be terminated or removed, call cropBreaks and display a warning
*/
export const validateClockTimes = (
  clockIn: DateTime,
  clockOut: DateTime,
  breaks: LiveTimesheet["breaks"]
): LiveTimesheetErrorMessage | null => {
  const allBreaksStartAndEndWithinClockTimes =
    breaks?.[0] &&
    !breaks.every((b) => b.start < clockOut.toSeconds() && b.end && b.end < clockOut.toSeconds());

  if (clockOut.toSeconds() < clockIn.toSeconds()) {
    return { message: "Clock out must be after clock in.", type: "error" };
  } else if (clockOut.toSeconds() - clockIn.toSeconds() > 86400) {
    return { message: "Clock out must be within 24 hours of clock in.", type: "error" };
  } else if (allBreaksStartAndEndWithinClockTimes) {
    let message = "Breaks will be terminated or removed to be within the clock in and clock out times. ";
    const lastBreak = breaks[breaks.length - 1];

    if (!!lastBreak && !lastBreak?.break_type_id && lastBreak?.start < clockOut.toSeconds()) {
      // if the ongoing break starts before the clock out time but has no break type
      message += "Ongoing break will be removed because there is no break type.";
    }

    return {
      message: message,
      type: "warning",
    };
  } else {
    return null;
  }
};

/*
  crops breaks to be within the clock in and clock out times:
  - terminates breaks that end past the clock out time
  - terminates ongoing breaks at the clock out time
  - removes breaks that start after the clock out time
  - removes breaks with no break type
 */
export const cropBreaks = (clockOut: DateTime, breaks: LiveTimesheet["breaks"]): LiveTimesheet["breaks"] => {
  if (!breaks) return;

  const updatedLiveTimesheetBreaks: LiveTimesheet["breaks"] = [];
  for (let i = 0; i < breaks.length; i++) {
    const b = breaks[i]!;
    const updatedBreak = cloneDeep(b)!;

    if (b.break_type_id === undefined || b.start >= clockOut.toSeconds()) {
      // if ongoing break does not have a break type, remove the break
      // if break starts after clock out, remove the break
    } else if (b.end && b.end > clockOut.toSeconds()) {
      // if the break ends after clock out, terminate the break
      updatedBreak.end = clockOut.toSeconds();
      updatedBreak.duration = updatedBreak.end - updatedBreak.start;

      if (updatedBreak.duration > 0) updatedLiveTimesheetBreaks.push(updatedBreak);
    } else if (!b.end) {
      // if break is ongoing, and has a break type id, then terminate at clock out
      updatedBreak.end = clockOut.toSeconds();
      updatedBreak.duration = updatedBreak.end - b.start;

      updatedLiveTimesheetBreaks.push(updatedBreak);
    } else {
      // if there are no issues with the break, keep it as is
      updatedLiveTimesheetBreaks.push(updatedBreak);
    }
  }
  return updatedLiveTimesheetBreaks;
};

// Flatfile validators
/** If there is a team member ID, validate that the team member exists */
export const useValidateTeamMemberID = (): ((
  row: { teamMemberId: string | undefined } | PrelimTimesheetImportRow | undefined,
  teamMemberIdOptional?: boolean
) => IDataHookRecord) => {
  const teamMembers = useTeam();
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);

  const validateTeamMemberID = (
    row: { teamMemberId: string | undefined } | PrelimTimesheetImportRow | undefined,
    teamMemberIdOptional?: boolean
  ) => {
    const { teamMemberId } = row || {};
    if (!teamMemberIdOptional && !teamMemberId) {
      return buildFlatfileMessage("Team member ID required", teamMemberId, "error");
    } else if (teamMemberId) {
      const idWithoutWhiteSpace = teamMemberId.trim();

      const teamMember = lookupTeamID[idWithoutWhiteSpace];
      if (!teamMember) {
        return buildFlatfileMessage("Team member not found", idWithoutWhiteSpace, "error");
      }

      return { value: idWithoutWhiteSpace };
    }
    return { value: undefined };
  };
  return validateTeamMemberID;
};

/** If there is a job code, validate that the job exists */
export const useValidateJobCode = (): ((
  row: { jobCode: string | undefined; jobId?: string | undefined } | PrelimTimesheetImportRow | undefined,
  isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
) => IDataHookRecord) => {
  const activeJobs = useActiveJobs();
  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);

  const validateJobCode = (
    row: { jobCode: string | undefined; jobId?: string | undefined } | PrelimTimesheetImportRow | undefined,
    isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
  ): IDataHookRecord => {
    const { jobCode, jobId } = row || {};

    const isRequired = isFieldRequired && isFieldRequired(row, "job");
    if (isRequired && (!jobCode || jobCode.trim() === "") && !jobId) {
      return buildFlatfileMessage("Job code required", jobCode, "error");
    }

    if (jobCode) {
      const job = lookupJobCode[jobCode];
      if (!job) {
        return buildFlatfileMessage("Job code not found", jobCode, "error");
      }
    }

    return { value: jobCode };
  };
  return validateJobCode;
};

/** If there is a cost type code, validate that the cost type exists */
export const useValidateCostTypeCode = (): ((
  row: { costTypeCode: string | undefined } | PrelimTimesheetImportRow | undefined
) => IDataHookRecord) => {
  const costTypes = useCostTypes();
  const lookupCostTypeCode = useMemo(() => keyBy(costTypes, "code"), [costTypes]);

  const validateCostTypeCode = (
    row: { costTypeCode: string | undefined } | PrelimTimesheetImportRow | undefined
  ) => {
    const { costTypeCode } = row || {};

    if (costTypeCode) {
      const costType = lookupCostTypeCode[costTypeCode];
      if (!costType) {
        return buildFlatfileMessage("Cost type not found", costTypeCode, "error");
      }
    }

    return { value: costTypeCode };
  };
  return validateCostTypeCode;
};

/**  If there is a job code and there is a job and the job has custom activities, validate that the cost code is one of the custom activities */
export const useValidateCostCode = (): ((
  row: { costCode: string | undefined; jobCode?: string | undefined } | PrelimTimesheetImportRow | undefined,
  isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
) => IDataHookRecord) => {
  const activities = useActivities();
  const activeJobs = useActiveJobs();
  const lookupActivity = useSelectableActivitiesMap();
  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);
  const lookupActivityCode = useMemo(() => keyBy(activities, "cost_code"), [activities]);

  const validateCostCode = (
    row: { costCode: string | undefined; jobCode?: string } | PrelimTimesheetImportRow | undefined,
    isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
  ) => {
    const { costCode, jobCode } = row || {};

    const isRequired = isFieldRequired && isFieldRequired(row, "activity");
    if (isRequired && (!costCode || costCode.trim() === "")) {
      return buildFlatfileMessage("Cost code required", costCode, "error");
    }

    if (costCode) {
      const job = jobCode ? lookupJobCode[jobCode] : undefined;
      if (job && job.custom_activities && job.activities) {
        const selectableActivities = lookupActivity.get(job._id);
        const activity = selectableActivities?.find((activity) => activity.cost_code === costCode);

        if (!activity) {
          return buildFlatfileMessage("Cost code not found on this job", costCode, "error");
        }
      } else {
        const activity = lookupActivityCode[costCode];
        if (!activity) {
          return buildFlatfileMessage("Cost code not found", costCode, "error");
        }
      }
    }

    return { value: costCode };
  };
  return validateCostCode;
};

/** Ensure that the hours are a number between 0 and 24 */
export const useValidateHours = (): ((
  row: { hours: string | undefined } | PrelimTimesheetImportRow | undefined
) => IDataHookRecord) => {
  const validateHours = (row: { hours: string | undefined } | PrelimTimesheetImportRow | undefined) => {
    const { hours } = row || {};
    const hoursNumber = Number(hours);
    if (isNaN(hoursNumber)) {
      return buildFlatfileMessage("Hours must be a number", hours, "error");
    }

    if (hoursNumber < 0 || hoursNumber > 24) {
      return buildFlatfileMessage("Hours must be between 0 and 24", hours, "error");
    }

    return { value: hours };
  };
  return validateHours;
};

/** Ensure clock in time is a valid time */
export const useValidateClockInTime = (): ((
  row: { clockIn: string | undefined } | PrelimTimesheetImportRow | undefined
) => IDataHookRecord) => {
  const activeCompany = useActiveCompany();

  const validateClockInTime = (
    row: { clockIn: string | undefined } | PrelimTimesheetImportRow | undefined
  ) => {
    const { clockIn } = row || {};

    try {
      parseClockInTime(clockIn, activeCompany);
    } catch (e) {
      console.log("e", e);
      return buildFlatfileMessage("Invalid clock in time. Format is: h:mma (9:00 AM)", clockIn, "error");
    }

    return { value: clockIn };
  };
  return validateClockInTime;
};

/** Ensure notes follow policy */
export const useValidateNotes = (): ((
  row: { notes: string | undefined } | PrelimTimesheetImportRow | undefined,
  isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
) => IDataHookRecord) => {
  const validateNotes = (
    row: { notes: string | undefined } | PrelimTimesheetImportRow | undefined,
    isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
  ) => {
    const { notes } = row || {};

    const isRequired = isFieldRequired && isFieldRequired(row, "notes");
    if (isRequired && (!notes || notes.trim() === "")) {
      return buildFlatfileMessage("Notes required", notes, "error");
    }

    return { value: notes };
  };
  return validateNotes;
};

/** Ensure time off policy exists for this team member */
export const useValidateTimeOffPolicy = (): ((
  row: { timeOffPolicyId: string | undefined } | PrelimTimesheetImportRow | undefined
) => IDataHookRecord) => {
  const teamMembers = useTeam();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);

  const validateTimeOffPolicy = (
    row: { timeOffPolicyId: string | undefined } | PrelimTimesheetImportRow | undefined
  ) => {
    const { timeOffPolicyId } = row || {};

    // If we are validating the full row, check if the team member has this time off policy
    if (timeOffPolicyId && isFullRow(row)) {
      const teamMember = lookupTeamID[row.teamMemberId];
      if (!teamMember) {
        return buildFlatfileMessage("Team member not found", row.teamMemberId, "error");
      }

      const hasPolicy = teamMember.time_off?.policies.find((policy) => policy.policy_id === timeOffPolicyId);
      if (!hasPolicy) {
        const availablePolicies = teamMember.time_off?.policies
          .map((policy) => lookupTimeOffPolicy(policy.policy_id)?.name)
          .join(", ");

        return buildFlatfileMessage(
          "This team member is not enrolled in this time off policy. They following policies are available: " +
            availablePolicies,
          timeOffPolicyId,
          "error"
        );
      }
    }

    return { value: timeOffPolicyId };
  };
  return validateTimeOffPolicy;
};

/** Ensure classification override is valid */
export const useValidateClassificationOverride = (): ((
  row: { classificationOverride: string | undefined } | PrelimTimesheetImportRow | undefined
) => IDataHookRecord) => {
  const getClassificationOptions = useGetClassificationOptions();
  const activeJobs = useActiveJobs();
  const teamMembers = useTeam();
  const activities = useActivities();
  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);
  const lookupActivityCode = useMemo(() => keyBy(activities, "cost_code"), [activities]);

  const validateClassificationOverride = (
    row: { classificationOverride: string | undefined } | PrelimTimesheetImportRow | undefined
  ) => {
    const { classificationOverride } = row || {};

    // If we are validating the full row, check if the classification override is valid
    if (classificationOverride && isFullRow(row)) {
      const teamMember = lookupTeamID[row.teamMemberId];
      if (!teamMember) return buildFlatfileMessage("Team member not found", row.teamMemberId, "error");

      const jobId = row.jobCode ? lookupJobCode[row.jobCode]?._id : null;
      const activityId = row.costCode ? lookupActivityCode[row.costCode]?._id : undefined;

      const classificationOptions = getClassificationOptions({
        tmId: teamMember._id,
        jobId: jobId,
        activityId: activityId,
      });

      if (!classificationOptions.some((option) => option.value === classificationOverride)) {
        return buildFlatfileMessage("Invalid classification", classificationOverride, "error");
      }
    }

    return { value: classificationOverride };
  };
  return validateClassificationOverride;
};

export const useValidateEquipmentCodes = (): ((
  row: { equipmentCodes: string | undefined } | PrelimTimesheetImportRow | undefined,
  isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
) => IDataHookRecord) => {
  const equipment = useEquipment();
  const lookupEquipmentCode = useMemo(() => keyBy(equipment, "code"), [equipment]);

  const validateEquipmentCodes = (
    row: { equipmentCodes: string | undefined } | PrelimTimesheetImportRow | undefined,
    isFieldRequired?: (row, field: TimesheetPolicyField) => boolean
  ) => {
    const { equipmentCodes } = row || {};

    if (!equipmentCodes) return { value: undefined };

    const equipmentCodeArray = equipmentCodes.split(",").map((code) => code.trim());

    const isRequired = isFieldRequired && isFieldRequired(row, "equipment_ids");
    if (isRequired && (!equipmentCodes || equipmentCodeArray.length === 0)) {
      return buildFlatfileMessage("Equipment codes required", equipmentCodes, "error");
    }

    const invalidEquipmentCodes = equipmentCodeArray.filter((code) => !lookupEquipmentCode[code]);
    if (invalidEquipmentCodes.length > 0) {
      return buildFlatfileMessage(
        "Invalid equipment codes: " + invalidEquipmentCodes.join(", "),
        equipmentCodes,
        "error"
      );
    }

    return { value: equipmentCodeArray.join(",") };
  };

  return validateEquipmentCodes;
};

export const useValidateQuantity = (): ((
  row: { quantity: string | undefined } | PrelimTimesheetImportRow | undefined
) => IDataHookRecord) => {
  const validateQuantity = (
    row: { quantity: string | undefined } | PrelimTimesheetImportRow | undefined
  ): IDataHookRecord => {
    const { quantity } = row || {};
    const quantityNumber = Number(quantity);
    if (isNaN(quantityNumber)) {
      return buildFlatfileMessage("Quantity must be a number", quantity, "error");
    }

    if (quantityNumber < 0) {
      return buildFlatfileMessage("Quantity must be greater than 0", quantity, "error");
    }

    return { value: quantityNumber };
  };

  return validateQuantity;
};
