import { AuditLog, MiterAPI } from "dashboard/miter";
import { useEffect, useState } from "react";

import styles from "./AuditLogHistory.module.css";
import { DateTime } from "luxon";
import {
  AuditLogType,
  UserFacingAuditLog,
  cleanFieldName,
  useCleanAuditLogs,
} from "dashboard/utils/audit-log-utils";
import { Circle } from "phosphor-react";
import { Loader, Notifier } from "ui";

export type AuditLogHistoryProps = {
  itemId: string;
  type: AuditLogType;
  refreshCounter?: number;
  fieldsStyle?: React.CSSProperties;
};

export const AuditLogHistory: React.FC<AuditLogHistoryProps> = ({
  itemId,
  type,
  refreshCounter,
  fieldsStyle,
}) => {
  // state
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [loading, setLoading] = useState(false);

  // hooks
  const cleanedAuditLogs = useCleanAuditLogs(auditLogs, type);

  const getAuditLogs = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.audit_logs.get_for_item(itemId);
      if (response.error) throw new Error(response.error);

      setAuditLogs(response);
    } catch (err) {
      Notifier.error("Failed to fetch audit logs. Please contact support.");
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAuditLogs();
  }, [refreshCounter]);

  return (
    <>
      {cleanedAuditLogs.map((auditLog) => (
        <AuditLogHistoryItem auditLog={auditLog} key={auditLog.timestamp} fieldsStyle={fieldsStyle} />
      ))}
      {loading && <Loader />}
    </>
  );
};

type AuditLogHistoryItemProps = {
  auditLog: UserFacingAuditLog;
  fieldsStyle?: React.CSSProperties;
};

const AuditLogHistoryItem: React.FC<AuditLogHistoryItemProps> = ({ auditLog, fieldsStyle }) => {
  const renderTitle = () => {
    return auditLog.operationLabel + " by " + auditLog.authorLabel;
  };

  const renderSubtitle = () => {
    return "On " + DateTime.fromSeconds(auditLog.timestamp).toFormat("MMM d, yyyy 'at' h:mm:ss a ZZZZ");
  };

  return (
    <div className={styles["audit-log-history-item-container"]} key={auditLog.timestamp}>
      <div className={styles["audit-log-history-item"]}>
        <div className={styles["audit-log-history-item-icon"]}>
          <Circle weight="fill" color="#ddd" style={{ marginLeft: -8.5 }} />
        </div>
        <div className={styles["audit-log-history-item-content"]}>
          <div className={styles["audit-log-history-item-content-title"]}>{renderTitle()}</div>
          <div className={styles["audit-log-history-item-content-subtitle"]}>{renderSubtitle()}</div>
          {auditLog.operationLabel !== "Created" && (
            <div className={styles["audit-log-history-item-content-fields"]} style={fieldsStyle}>
              {auditLog.fields.map((field, index) => (
                <AuditLogHistoryItemField key={`audit-log-history-item-field-${index}`} field={field} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type AuditLogHistoryItemFieldProps = {
  field: UserFacingAuditLog["fields"][number];
};

const AuditLogHistoryItemField: React.FC<AuditLogHistoryItemFieldProps> = ({ field }) => {
  const { previousValueLabel, newValueLabel, operationLabel, addedIds, removedIds } = field;
  const cleanedName = cleanFieldName(field);

  const hasBothValues = () => {
    if (!previousValueLabel || !newValueLabel) return false;

    // If both values are strings, check if they are not empty
    let hasPreviousValue = false;
    if (typeof previousValueLabel === "string") {
      hasPreviousValue = previousValueLabel.trim() !== "";
    }

    let hasNewValue = false;
    if (typeof newValueLabel === "string") {
      hasNewValue = newValueLabel.trim() !== "";
    }

    return hasPreviousValue && hasNewValue;
  };

  const renderDivider = () => {
    if (operationLabel !== "Updated") return;
    return <>&nbsp;→&nbsp;&nbsp;</>;
  };

  // if either addedIds or removedIds are present, show "added" vs "removed"
  if (addedIds?.length || removedIds?.length) {
    return (
      <div className={styles["audit-log-history-item-field"]}>
        <div className={styles["audit-log-history-item-field-name"]}>{cleanedName}</div>
        <div className={styles["audit-log-history-item-field-values"]} style={{ flexDirection: "column" }}>
          {addedIds && <div>Added: {addedIds}</div>}
          {removedIds && <div>Removed: {removedIds}</div>}
        </div>
      </div>
    );
  }

  return (
    <div className={styles["audit-log-history-item-field"]}>
      <div className={styles["audit-log-history-item-field-name"]}>
        {cleanedName + " " + operationLabel.toLocaleLowerCase()}
      </div>
      <div className={styles["audit-log-history-item-field-values"]}>
        {previousValueLabel && <div>{previousValueLabel}</div>}
        {hasBothValues() && renderDivider()}
        <div>{newValueLabel}</div>
      </div>
    </div>
  );
};
