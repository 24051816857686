import React, { useMemo } from "react";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "ui";
import { downloadFromCsvObj } from "./csvDownloadFunctions";

export const useSage100PayrollExports = (
  companyId: string | undefined | null,
  icId: string | undefined
): {
  loading: boolean;
  build: (ledgerEntryIds: string[]) => Promise<void>;
} => {
  const [loading, setLoading] = React.useState(false);

  const build = async (ledgerEntryIds: string[]) => {
    if (!companyId || !icId) return;
    setLoading(true);
    try {
      const response = await MiterAPI.integrations.sage_100.get_payroll_exports({
        companyId: companyId,
        ledgerEntryIds,
        integrationConnectionId: icId,
      });
      if (response.error) throw new Error(response.error);
      response.forEach((r) => downloadFromCsvObj(r));
    } catch (e: $TSFixMe) {
      Notifier.error(`Error building Sage 100 csv file: ${e.message}`);
    }
    setLoading(false);
  };

  return useMemo(() => ({ loading, build }), [companyId, icId, loading]);
};
