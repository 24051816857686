/* eslint-disable @typescript-eslint/no-explicit-any */
import SchedulingContext from "dashboard/contexts/scheduling-context";
import { AggregatedJob, AggregatedTeamMember, Assignment, MiterAPI } from "dashboard/miter";
import { displayFieldErrors } from "dashboard/utils/errors";
import Notifier from "dashboard/utils/notifier";
import { DateTime } from "luxon";
import React, { FC, useContext, useEffect, useState, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { ConfirmModal, Loader, ModalFooter, ModalHeader } from "ui";
import AssignmentForm from "./AssignmentForm";
import { BasicModal } from "ui";
import { Formblock } from "ui";
import { prepareDataForBackend } from "./utils";
import {
  RecurrenceTypes,
  ViewType,
  initialEditRecurrenceOptions,
} from "dashboard/pages/scheduling/SchedulingCalendar";
import { returnAssignmentFromArray } from "./utils";
import { FileUploadParams, updateFiles, uploadFiles, deleteFiles } from "miter-utils";
import { RecurrenceConfig } from "backend/models/assignment";
import { FilePickerFile } from "ui/form/FilePicker";
import { urlToFile } from "dashboard/utils";
import styles from "./AssignmentModal.module.css";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useAssignmentAbilities } from "dashboard/hooks/abilities-hooks/useAssignmentAbilities";

type Props = {
  assignmentID?: string;
  initialMode: "create" | "read" | "update";
  initialTeamMember?: AggregatedTeamMember;
  initialJob?: AggregatedJob;
  initialStartsAt?: DateTime;
  initialEndsAt?: DateTime;

  onFinish: () => void;
  onHide: () => void;

  showRecurringTypeModal: boolean;
  editRecurrenceType: RecurrenceTypes;
  setEditRecurrenceType: (RecurrenceTypes) => void;
  setShowRecurringTypeModalWrapper: (open, defaultOption?: "this" | "this_and_future") => void;

  scheduleView: ViewType;
};

const AssignmentModal: FC<Props> = ({
  assignmentID,
  initialMode,
  initialJob,
  initialTeamMember,
  initialStartsAt,
  initialEndsAt,
  onFinish,
  onHide,
  showRecurringTypeModal,
  editRecurrenceType,
  setEditRecurrenceType,
  setShowRecurringTypeModalWrapper,
  scheduleView,
}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const form = useForm({ shouldUnregister: false });
  const { register, control, watch, errors, handleSubmit, setError } = form;
  const { fetchScheduleEvents, scheduleEvents } = useContext(SchedulingContext);
  const { fields } = useFieldArray({ control, name: "assignments" });
  const assignmentAbilities = useAssignmentAbilities();
  const formData = watch();

  const activeCompanyId = useActiveCompanyId();

  // needed to update value of radio button
  watch("edit_recurrence_type");

  /*********************************************************
   *  Initialize states
   **********************************************************/
  // Keep track of the modal mode: "create", "read", "update"
  const [mode, setMode] = useState<"create" | "read" | "update">(initialMode);

  const [assignment, setAssignment] = useState<Assignment | undefined>();
  const [fetchedAssignment, setFetchedAssignment] = useState(!assignmentID);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [files, setFiles] = useState<FilePickerFile[] | null>([]);
  const [duplicating, setDuplicating] = useState(false);

  const [draftRecurrenceConfig, setDraftRecurrenceConfig] = useState<RecurrenceConfig | null>();

  useEffect(() => {
    setDraftRecurrenceConfig(assignment?.recurrence_config);
  }, [assignment?.recurrence_config, mode]);

  // Get assignment for the modal based on the ID passed into the component
  const getAssignment = async () => {
    if (!assignmentID) return;
    setLoading(true);
    // @ts-expect-error fix me
    const found = returnAssignmentFromArray(scheduleEvents, assignmentID);

    setAssignment(found);
    setLoading(false);
    setFetchedAssignment(true);
  };

  /*********************************************************
   *  Functions for CRUD'ing assignments
   **********************************************************/
  const validateAssignment = (): boolean => {
    if (formData.starts_at >= formData.ends_at) {
      setError("starts_at", { message: "Must be earlier than the end value" });
      return false;
    }

    return true;
  };

  //  take state date and put it into form data structure

  // Creates the assignment
  const createAssignment = async (data) => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const payload = prepareDataForBackend({
        ...data,
        mode,
        recurrence_config: draftRecurrenceConfig,
        company: activeCompanyId,
      });

      // @ts-expect-error fix me
      const response = await MiterAPI.assignments.create(payload);
      if (response.error) {
        displayFieldErrors(response.fields!, setError, fields);
        throw Error(response.error);
      }

      setAssignment(response);

      const params: FileUploadParams = {
        parent_id: String(response._id),
        parent_type: "assignment",
        company_id: response.company,
      };
      await uploadFiles(files, params);

      Notifier.success("Assignment successfully created.");
      setMode("read");

      onFinish();
    } catch (e: any) {
      console.log("Error creating the assignment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  // Updates the assignment
  const updateAssignment = async (data) => {
    if (!assignmentID || !assignment) return;
    setLoading(true);

    try {
      const payload = {
        data: prepareDataForBackend({
          ...assignment,
          ...data,
          mode,
          recurrence_config: draftRecurrenceConfig,
        }),
        update_recurrence: editRecurrenceType,
        previous_starts_at: assignment.starts_at,
        previous_ends_at: assignment.ends_at,
      };

      // @ts-expect-error fix me
      const response = await MiterAPI.assignments.update(assignment._id.toString(), payload);
      if (response.error) {
        displayFieldErrors(response.fields!, setError, fields);
        throw Error(response.error);
      }

      const params: FileUploadParams = {
        parent_id: String(response._id),
        parent_type: "assignment",
        company_id: response.company,
      };
      await updateFiles(files, params);

      onFinish();
      Notifier.success("Assignment successfully updated.");
      setMode("read");
      setAssignment(response);

      onFinish();
    } catch (e: any) {
      console.log("Error updating the assignment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  // Deletes the assignment
  const deleteAssignment = async () => {
    if (!assignmentID || !assignment) return;

    setLoading(true);
    try {
      // date needed to identify which date
      // to exclude / end the ancestor assignment on
      const params = !!editRecurrenceType
        ? {
            delete_recurrence: editRecurrenceType,
            occurrence_starts_at: assignment.starts_at,
          }
        : undefined;
      const response = await MiterAPI.assignments.delete(assignment._id + "", params);
      if (response.error) throw Error(response.error);
      const idsToDelete: string[] = [];
      files?.map((file: FilePickerFile) => {
        file.data?._id ? idsToDelete.push(file.data?._id) : null;
      });
      await deleteFiles(idsToDelete);

      Notifier.success("Deleted assignment.");
      fetchScheduleEvents();
      onFinish();
    } catch (e: any) {
      console.log("Error deleting assignment:", e);
      Notifier.error(e.message);
    }
    resetDeleting();
    setLoading(false);
    onHide();
  };

  const onDuplicate = async () => {
    // Keeps all the current fields except for the file attachments
    setMode("create");
    setDuplicating(true);

    const duplicatedFiles = await Promise.all(
      (files || [])
        .filter((file) => !file.deleted)
        .map(async (file) => {
          let blob = file.blob;
          if (file.url) {
            blob = await urlToFile(file.url, file.data?.originalname || "assignment attachment");
          }

          return { blob };
        })
    );

    setFiles(duplicatedFiles);
  };

  const onViewWeather = () => {
    if (!assignment?.address) {
      Notifier.error("You must add an address to the assignment to view the weather.");
      return;
    }

    // Stringify address into city state and zip
    const { city, state, postal_code } = assignment.address;
    const address = `${city}, ${state} ${postal_code}`;

    window.open("https://www.weatherbug.com/weather-forecast/now/" + address, "_blank");
  };

  const onSubmit = () => {
    if (mode === "update" && draftRecurrenceConfig && assignment?.recurrence_config) {
      setShowRecurringTypeModalWrapper(true, editRecurrenceOptions.length === 3 ? "this" : "this_and_future");
    } else {
      validateAndSubmit();
    }
  };

  const validateAndSubmit = () => {
    const valid = validateAssignment();
    if (!valid) {
      Notifier.error("We are unable to save your assignment. Please fix the errors and try again.");
      return;
    }

    if (mode === "create") {
      handleSubmit((data) => createAssignment(data))();
    } else if (mode === "update") {
      handleSubmit(updateAssignment)();
    }
    setShowRecurringTypeModalWrapper(false);
  };

  const onCancel = () => {
    if (mode === "update") {
      setMode("read");
    } else {
      onHide();
    }
  };

  const onDelete = () => {
    setDeleting(true);
    if (draftRecurrenceConfig) {
      setShowRecurringTypeModalWrapper(true, "this");
    }
  };

  const readOnly = mode === "read";

  // Show/Hide the delete confirmation button
  const resetDeleting = () => {
    setShowRecurringTypeModalWrapper(false);
    setDeleting(false);
  };

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const modalTitle = (() => {
    if (duplicating) {
      return "Duplicate assignment";
    } else if (mode === "create") {
      return "Create an assignment";
    } else if (mode === "update") {
      return "Update assignment";
    } else if (mode === "read") {
      return assignment?.title;
    }
  })();

  const renderForm = () => {
    return (
      <>
        <AssignmentForm
          mode={mode}
          assignment={assignment}
          draftRecurrenceConfig={draftRecurrenceConfig}
          setDraftRecurrenceConfig={setDraftRecurrenceConfig}
          form={form}
          initialTeamMember={initialTeamMember}
          initialJob={initialJob}
          initialStartsAt={initialStartsAt}
          initialEndsAt={initialEndsAt}
          files={files}
          setFiles={setFiles}
          scheduleView={scheduleView}
        />
      </>
    );
  };

  const renderSubmitText = () => {
    if (mode === "update") {
      return "Update";
    } else if (mode === "create") {
      return "Save";
    }
  };

  const renderDeleteText = () => {
    if (mode === "read" || mode === "update") {
      return "Delete";
    }
  };

  const renderCancelText = () => {
    if (mode === "read") {
      return "Close";
    } else {
      return "Cancel";
    }
  };

  useEffect(() => {
    getAssignment();
    register("ends");
    register("end_on_date");
  }, []);

  const editRecurrenceOptions = useMemo(() => {
    let initialArray = initialEditRecurrenceOptions;

    // if it is deleting, then use this
    if (initialArray.length < 3 && deleting) {
      initialArray.unshift({ label: "This event", value: "this" });
      return initialArray;
    }

    // we check the assignment with the updated recurrence_config.
    // if the recurrence_config is the same
    //  we add "this" to the editRecurrenceOptions
    // the form has to be initialized to get the recurrence_config

    if (assignment?.recurrence_config && draftRecurrenceConfig) {
      const allProperties = Object.keys(assignment.recurrence_config).concat(
        Object.keys(draftRecurrenceConfig)
      );
      const recurrenceConfigEqual = allProperties.every((property) => {
        return assignment.recurrence_config![property] === draftRecurrenceConfig[property];
      });

      // const recurrenceConfigEqual = _.isEqual(assignment.recurrence_config, draftRecurrenceConfig);
      if (initialArray.length < 3 && recurrenceConfigEqual) {
        initialArray.unshift({ label: "This event", value: "this" });
      } else if (initialArray.length >= 3 && !recurrenceConfigEqual) {
        initialArray = initialArray.filter((option) => option.value !== "this");
      }

      // Prevent user from editing "All" assignments of a recurrence if they're trying to move the assignment
      // by a whole day, because that doesn't make sense and is covered by "This and future"
      if (formData?.starts_at) {
        const previous_start_day = DateTime.fromSeconds(assignment.starts_at).toISODate();
        const new_start_day = formData.starts_at.toISODate();
        if (previous_start_day !== new_start_day) {
          initialArray = initialArray.filter((option) => option.value !== "all");
        }
      }
    }

    return initialArray;
  }, [
    deleting,
    JSON.stringify(assignment),
    formData?.recurrence?.value,
    draftRecurrenceConfig,
    formData?.starts_at,
  ]);

  return (
    <div className="modal-background">
      <div className={"modal-wrapper form " + styles["assignment-modal-wrapper"]}>
        <ModalHeader
          heading={modalTitle}
          onHide={onHide}
          className={"time-off-request"}
          actions={[
            {
              action: onViewWeather,
              label: "View weather",
            },
            {
              action: onDuplicate,
              label: "Duplicate",
              shouldShow: () => assignmentAbilities.can("create", assignment),
            },
            {
              action: () => setMode("update"),
              label: "Edit",
              important: true,
              shouldShow: () => assignmentAbilities.can("update", assignment),
            },
            {
              action: onDelete,
              label: "Delete",
              shouldShow: () => assignmentAbilities.can("delete", assignment),
            },
          ]}
          actionsType={"button"}
          hideActions={mode !== "read"}
        />
        {fetchedAssignment ? (
          <>
            <div className={"modal-body form two-column " + styles["assignment-modal-body"]}>
              {renderForm()}
            </div>
            <ModalFooter
              loading={loading}
              className={"form"}
              cancelText={renderCancelText()}
              onCancel={onCancel}
              showDelete={readOnly}
              showEdit={readOnly}
              onEdit={() => setMode("update")}
              submitText={renderSubmitText()}
              deleteText={renderDeleteText()}
              onDelete={onDelete}
              onSubmit={onSubmit}
              hideSubmit={readOnly}
              hide={mode === "read"}
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
      {deleting && !showRecurringTypeModal && (
        <ConfirmModal
          title={"Delete Assignment"}
          body={"Are you sure you want to delete this assignment?"}
          onYes={deleteAssignment}
          onNo={resetDeleting}
        />
      )}
      {showRecurringTypeModal && (
        <BasicModal
          headerText={`${deleting ? "Delete" : "Edit"} recurring event`}
          button2Action={deleting ? deleteAssignment : validateAndSubmit}
          button2Text={`${deleting ? "Delete" : "Update"}`}
          button1Action={deleting ? () => resetDeleting() : () => setShowRecurringTypeModalWrapper(false)}
          button1Text="Cancel"
        >
          <Formblock
            register={register}
            defaultValue={editRecurrenceType}
            onChange={(e) => {
              setEditRecurrenceType(e.target.value);
            }}
            type="radio"
            className="flow"
            name="edit_recurrence_type"
            errors={errors}
            editing={true}
            options={editRecurrenceOptions}
          />
        </BasicModal>
      )}
    </div>
  );
};
export default AssignmentModal;
