import { useActiveCompany, useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import React, { useEffect, useState, FC, useMemo } from "react";
import { reportList } from "../reportList";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { MiterAPI } from "dashboard/miter";
import { changeFromSnakeCasingToSentenceCasing, changeToSentenceCasing, Notifier } from "dashboard/utils";
import { EnrollmentCensusType } from "backend/utils/clasp/clasp-types";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";

const reportObject = reportList.find((report) => report.slug === "enrollment_census");

const EnrollmentCensus: FC = () => {
  // Hooks
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();

  // State
  const [data, setData] = useState<EnrollmentCensusType[]>([]);
  const [loading, setLoading] = useState(false);

  const useEnrollmentCensusTableColDefs = (): ColumnConfig<EnrollmentCensusType>[] => {
    return useMemo(() => {
      return [
        {
          field: "friendly_id",
          headerName: "Employee ID",
        },
        {
          field: "first_name",
          headerName: "First name",
        },
        {
          field: "middle_name",
          headerName: "Middle name",
          initialHide: true,
        },
        {
          field: "last_name",
          headerName: "Last name",
        },
        {
          field: "enrollment_status",
          headerName: "Enrollment status",
        },
        {
          field: "member_relationship",
          headerName: "Member relationship",
          valueFormatter: (params) => {
            const memberRelationship = params?.data?.member_relationship;
            if (!memberRelationship) return "-";
            return changeToSentenceCasing(memberRelationship);
          },
        },
        {
          field: "pay_frequency",
          headerName: "Pay frequency",
          valueFormatter: (params) => {
            const payFrequency = params?.data?.pay_frequency;
            if (!payFrequency) return "-";
            return changeToSentenceCasing(payFrequency);
          },
        },
        {
          field: "line_of_coverage",
          headerName: "Line of coverage",
          valueFormatter: (params) => {
            const lineOfCoverage = params?.data?.line_of_coverage;
            if (!lineOfCoverage) return "-";
            return changeFromSnakeCasingToSentenceCasing(lineOfCoverage);
          },
        },
        {
          field: "plan_name",
          headerName: "Plan name",
        },
        {
          field: "effective_start",
          headerName: "Effective start",
        },
        {
          field: "effective_end",
          headerName: "Effective end",
        },
        {
          field: "waive_reason",
          headerName: "Waive reason",
        },
        {
          field: "premium",
          headerName: "Premium",
        },
        {
          field: "member_contribution",
          headerName: "Member contribution",
        },
        {
          field: "employer_contribution",
          headerName: "Employer contribution",
        },
        {
          field: "date_of_birth",
          headerName: "Date of birth",
          initialHide: true,
        },
        {
          field: "address_line_1",
          headerName: "Address line 1",
          initialHide: true,
        },
        {
          field: "address_line_2",
          headerName: "Address line 2",
          initialHide: true,
        },
        {
          field: "city",
          headerName: "City",
          initialHide: true,
        },
        {
          field: "state",
          headerName: "State",
          initialHide: true,
        },
        {
          field: "zip_code",
          headerName: "Zip code",
          initialHide: true,
        },
        {
          field: "phone_number",
          headerName: "Phone number",
          initialHide: true,
        },
        {
          field: "email",
          headerName: "Email",
          initialHide: true,
        },
        {
          field: "sex",
          headerName: "Sex",
          valueFormatter: (params) => {
            const sex = params?.data?.sex;
            if (!sex) return "-";
            return changeToSentenceCasing(sex);
          },
          initialHide: true,
        },
        {
          field: "hire_date",
          headerName: "Hire date",
          initialHide: true,
        },
        {
          field: "termination_date",
          headerName: "Termination date",
          initialHide: true,
        },
        {
          field: "hours_worked",
          headerName: "Hours worked",
          initialHide: true,
        },
      ];
    }, []);
  };

  const columns = useEnrollmentCensusTableColDefs();

  const getData = async () => {
    if (!activeCompanyId || !activeCompany) return;
    setLoading(true);
    try {
      const payload = {
        type: "enrollment_census",
        params: {},
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      setData(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [!!activeCompanyId]);

  return (
    <div className="page-content">
      <Helmet>
        <title>Enrollment Census | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>{reportObject!.label}</h1>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <TableV2
        id="enrollment-census-table"
        resource="enrollment census"
        columns={columns}
        isLoading={loading}
        gridWrapperStyle={{ height: "60vh" }}
        wrapperClassName="base-ssr-table"
        containerStyle={{ marginBottom: 25 }}
        data={data}
        showReportViews={true}
        showUndefinedValuesAs={"-"}
      />
    </div>
  );
};

export default EnrollmentCensus;
