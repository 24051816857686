import {
  useGetSelectableSubJobOptions,
  useJobNameFormatter,
  useLookupJob,
  useSubJobsConfig,
} from "dashboard/hooks/atom-hooks";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock, SetValueFn } from "ui";
import { FormblockProps } from "ui/form/Formblock";
import { Option, SelectProps } from "ui/form/Input";

type Props = FormblockProps &
  SelectProps &
  ({ setValue: SetValueFn } | { form?: UseFormMethods<$TSFixMe> }) & { customOption?: Option<string> };
type JobAndSubJobOptions = { jobId: string; subJobOptions: Option<string>[]; options: Option<string>[] };

export const JobInput: React.FC<Props> = (props) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const defaultJobOptions = props.options as Option<string>[] | undefined;

  const subJobsConfig = useSubJobsConfig();
  const lookupJob = useLookupJob();
  const formatter = useJobNameFormatter({ disableParentJobPrefixing: true });
  const getSelectableSubJobOptions = useGetSelectableSubJobOptions({ defaultJobOptions });
  const buildDefaultJobOptions = useBuildDefaultJobOptions({ defaultJobOptions });

  // Watch the form (if it exists)
  props?.form?.watch?.();

  /**********************************************************************************************************
   * States
   **********************************************************************************************************/
  const [selectedJobs, setSelectedJobs] = useState<JobAndSubJobOptions[]>([]);

  /**********************************************************************************************************
   * Important variables
   **********************************************************************************************************/
  // Get the job value from the props
  const jobValue = buildJobValue(props);
  const leafJob = selectedJobs[selectedJobs.length - 1] || null;

  /**********************************************************************************************************
   * useEffects
   **********************************************************************************************************/

  // When the job value changes, update the selected jobs
  useEffect(() => {
    if (!subJobsConfig.enabled) return;

    // If the job value hasn't changed, don't do anything
    const leafJobValue = selectedJobs[selectedJobs.length - 1]?.jobId;
    if (jobValue === leafJobValue) return;

    setSelectedJobs(buildDefaultJobOptions(jobValue as string | null | undefined));

    if ("setValue" in props && props.setValue) {
      props.setValue(jobValue);
    } else if ("form" in props && props.form && props.name) {
      props.form.setValue(
        props.name,
        jobValue ? { label: formatter(lookupJob(jobValue)), value: jobValue } : null
      );
    }
  }, [jobValue]);

  // Register the job input for react hook form because there is no input with that name
  useEffect(() => {
    if (!props.name || !subJobsConfig.enabled) return;
    props.form?.register({ name: props.name });
    props.register?.({ name: props.name });
  }, []);

  /**********************************************************************************************************
   * Handle job change
   * - Updates the internal and external job states when a job is selected
   **********************************************************************************************************/
  const handleJobChange = (option: Option<string> | null | undefined, index: number) => {
    // Get the value
    const selectedValue = option?.value || null;
    const selectedValueParentJob = lookupJob(selectedValue)?.parent_job_id || null;

    /*
     * Update the parent job list
     * - If the selected value is null, remove all parent jobs after this one
     * - If the selected value is not null, add it to the parent job list
     */
    let updatedJobs: JobAndSubJobOptions[];
    if (selectedValue == null) {
      updatedJobs = cloneDeep(selectedJobs.slice(0, index));
    } else {
      const newParentJob = {
        jobId: selectedValue,
        options: getSelectableSubJobOptions(selectedValueParentJob),
        subJobOptions: getSelectableSubJobOptions(selectedValue),
      };

      updatedJobs = cloneDeep(selectedJobs.slice(0, index));
      updatedJobs.push(newParentJob);
    }

    // Update the selected jobs (local state)
    setSelectedJobs(updatedJobs);

    // Set the leaf job
    const leafJob = updatedJobs[updatedJobs.length - 1] || null;
    const leafOption = leafJob
      ? { label: formatter(lookupJob(leafJob?.jobId)), value: leafJob?.jobId }
      : null;

    // Update the form value (non-local state)
    if ("setValue" in props && props.setValue) {
      props.setValue(leafOption);
    } else if ("form" in props && props.form && props.name) {
      props.form.setValue(props.name, leafOption);
    }

    // Update the onChange (non-local state)
    props.onChange?.(leafOption);

    // If this is a requiredSelect and the value is null, set the error
    if (props.requiredSelect && props.name && !leafOption) {
      props.form?.setError(props.name, { type: "required" });
    }
  };

  /**********************************************************************************************************
   * Render functions
   **********************************************************************************************************/
  const renderLeafJobInput = () => {
    if (!leafJob || !props.editing) return;
    const { form, control, register, name, defaultValue, value, sublabel, labelInfo, ...usedProps } = props;

    return (
      <Formblock
        {...usedProps}
        label={" "}
        options={leafJob?.subJobOptions}
        onChange={(option) => handleJobChange(option, selectedJobs.length)}
        placeholder="Select a sub job"
        defaultValue={null}
        value={null}
      />
    );
  };

  const renderInitialJobInput = () => {
    const options = [...getSelectableSubJobOptions("" as string | null | undefined)];
    if (props.customOption) options.unshift(props.customOption);

    return <Formblock {...props} options={options} onChange={(option) => handleJobChange(option, 0)} />;
  };

  const renderJobInput = (job: JobAndSubJobOptions, index: number) => {
    const key = `parent_job_${index}_${props.name}`;
    const label = index === 0 ? "Job" : " ";
    const selectedOption =
      job.jobId && lookupJob(job.jobId)?.name
        ? { label: formatter(lookupJob(job.jobId)), value: job.jobId }
        : null;

    // Ignore form, control, and register props
    const { form, control, register, defaultValue, name, sublabel, value, labelInfo, ...usedProps } = props;

    return (
      <Formblock
        {...usedProps}
        key={key}
        label={label}
        options={job.options}
        value={selectedOption}
        defaultValue={selectedOption?.value}
        onChange={(option) => handleJobChange(option, index)}
      />
    );
  };

  // If sub jobs are disabled, render the normal formblock by passing all props
  if (!subJobsConfig.enabled) return <Formblock {...props} />;

  return (
    <>
      {selectedJobs.map(renderJobInput)}
      {/* If there are no selected jobs, show a job input with all options */}
      {selectedJobs.length === 0 && renderInitialJobInput()}
      {/* If the last job has sub jobs, show a job input with all sub job options */}
      {leafJob && leafJob.subJobOptions.length > 0 && renderLeafJobInput()}
    </>
  );
};

const useBuildDefaultJobOptions = (opts: { defaultJobOptions?: Option<string>[] }) => {
  const lookupJob = useLookupJob();
  const getSelectableSubJobOptions = useGetSelectableSubJobOptions(opts);

  return useCallback(
    (jobId: string | null | undefined) => {
      const selectedJobs: JobAndSubJobOptions[] = [];

      let currentJobId = jobId;
      while (currentJobId) {
        const job = lookupJob(currentJobId);
        if (!job) break;

        selectedJobs.unshift({
          jobId: currentJobId,
          options: getSelectableSubJobOptions(job.parent_job_id),
          subJobOptions: getSelectableSubJobOptions(currentJobId),
        });

        currentJobId = job.parent_job_id;
      }

      return selectedJobs;
    },
    [lookupJob, getSelectableSubJobOptions]
  );
};

export const EMPTY_JOB_KEY = "";

const buildJobValue = (props: Props): string | null | undefined => {
  if ("value" in props) return props.value?.value as string | null | undefined;
  return props.defaultValue as string | null | undefined;
};
