import React, { ReactElement, useMemo, useState } from "react";
import { TimesheetSection } from "backend/models/timesheet";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { AggregatedMiterEarning, UnionRate } from "dashboard/miter";
import ObjectID from "bson-objectid";
import { useActiveCompany, useLookupRateClassification } from "dashboard/hooks/atom-hooks";
import { Assign } from "utility-types";
import { roundTo } from "miter-utils";
import TimesheetModal from "../ViewTimesheet/TimesheetModal";
import { ValueGetterParams } from "ag-grid-community";
import { createObjectMapToArray } from "dashboard/utils";
import {
  TimesheetSectionTimeType,
  timeTypeFromTimesheetSection,
  timesheetSectionTimeTypeColors,
} from "./timesheetsByPayPeriodUtils";
import { DateTime } from "luxon";
import Banner from "dashboard/components/shared/Banner";
import { useNavigate } from "react-router-dom";
import { PaymentWarning } from "backend/utils/payroll/types";
import { TimesheetWarningsTooltip } from "./TimesheetWarningsTooltip";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";

type TimesheetSectionTableEntry = TimesheetSection & {
  _id: string;
  job?: AggregatedMiterEarning["job"];
  activity?: AggregatedMiterEarning["activity"];
  classification_override?: UnionRate;
  team_member?: AggregatedMiterEarning["team_member"];
};

type JobDataLookup = Assign<
  Pick<AggregatedMiterEarning, "job" | "activity" | "classification_override" | "team_member">,
  { classification_override?: UnionRate }
>;

type Props = {
  timesheetSections: TimesheetSection[];
  loading: boolean;
  earnings: AggregatedMiterEarning[];
  warnings?: PaymentWarning[];
  payPeriodSelector?: () => ReactElement;
  containerClassName?: string;
  refreshTimesheets?: () => void;
};

export const TimesheetSectionsTable: React.FC<Props> = ({
  timesheetSections,
  loading,
  earnings,
  warnings,
  payPeriodSelector,
  containerClassName,
  refreshTimesheets,
}) => {
  const navigate = useNavigate();
  const lookupClassifications = useLookupRateClassification();
  const activeCompany = useActiveCompany();
  const timesheetAbilities = useTimesheetAbilities();

  const [selectedTimesheet, setSelectedTimesheet] = useState<string>();

  const earningLookup = useMemo(
    () => createObjectMapToArray(earnings, (earning) => earning.timesheet),
    [earnings]
  );

  const otHourAllocation = useMemo(() => {
    return activeCompany?.settings?.payroll?.ot_configuration?.ot_hour_allocation;
  }, [activeCompany]);

  const lookupJobData = useMemo(() => {
    return earnings.reduce((acc, earning) => {
      if (!earning.timesheet) return acc;

      acc.set(earning.timesheet, {
        job: earning.job,
        activity: earning.activity,
        classification_override: lookupClassifications(earning.classification_override),
        team_member: earning.team_member,
      });

      return acc;
    }, new Map<string, JobDataLookup>());
  }, [earnings]);

  const data: TimesheetSectionTableEntry[] = useMemo(() => {
    return timesheetSections
      .filter((section) => {
        const earning = earningLookup[section.timesheet];
        return timesheetAbilities.can("read", earning);
      })
      .map((section) => {
        const jobData = lookupJobData.get(section.timesheet);

        return {
          ...section,
          ...jobData,
          _id: ObjectID().toHexString(),
        };
      });
  }, [timesheetSections, lookupJobData]);

  const sectionsByTimesheet = useMemo(() => {
    return createObjectMapToArray(timesheetSections, (tss) => tss.timesheet);
  }, [data]);

  const timesheetSectionsTableCols: ColumnConfig<TimesheetSectionTableEntry>[] = [
    {
      field: "date",
      headerName: "Date",
      dataType: "date",
      dateType: "iso",
      dateFormat: "EEE LLL d, yyyy",
      enableRowGroup: true,
      valueGetter: (params: ValueGetterParams<TimesheetSectionTableEntry>): string | undefined => {
        if (!params.data) return;
        return DateTime.fromSeconds(params.data.clock_in, { zone: activeCompany?.timezone }).toISODate();
      },
    },
    {
      field: "clock_in",
      headerName: "Start",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "h:mm a",
      aggFunc: "min",
    },
    {
      field: "clock_out",
      headerName: "End",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "h:mm a",
      aggFunc: "max",
    },
    {
      field: "hours",
      headerName: "Hours",
      dataType: "number",
      sumRow: true,
      aggFunc: "sumValues",
      valueGetter: (params: ValueGetterParams<TimesheetSectionTableEntry>): number => {
        const hours = params?.data?.hours;
        if (!hours) {
          return 0;
        }
        // Handle values close to 0 without ever rounding down exactly to 0.
        return roundTo(Math.max(hours, 0.01));
      },
    },
    {
      field: "time_type",
      headerName: "Time type",
      dataType: "string",
      displayType: "badge",
      filter: "agSetColumnFilter",
      colors: timesheetSectionTimeTypeColors,
      enableRowGroup: true,
      valueGetter: (
        params: ValueGetterParams<TimesheetSectionTableEntry>
      ): TimesheetSectionTimeType | undefined => {
        return timeTypeFromTimesheetSection(params.data);
      },
    },
    {
      headerName: "Break type",
      dataType: "string",
      valueGetter: (params: ValueGetterParams<TimesheetSectionTableEntry>): string | undefined => {
        if (params.data?.break_label) {
          return `${params.data.break_label} ${params.data.paid ? "(paid)" : "(unpaid)"}`;
        }
      },
    },
    {
      field: "team_member.full_name",
      headerName: "Team member",
      dataType: "string",
      enableRowGroup: true,
    },
    {
      field: "team_member.friendly_id",
      headerName: "Team member ID",
      dataType: "string",
      enableRowGroup: true,
    },
    {
      field: "job.name",
      headerName: "Job",
      dataType: "string",
      enableRowGroup: true,
      filter: "agSetColumnFilter",
    },
    {
      field: "job.code",
      headerName: "Job code",
      dataType: "string",
      enableRowGroup: true,
    },
    {
      field: "activity.label",
      useValueFormatterForExport: true,
      headerName: "Activity",
      dataType: "string",
      enableRowGroup: true,
      filter: "agSetColumnFilter",
    },
    {
      field: "activity.cost_code",
      useValueFormatterForExport: true,
      headerName: "Activity code",
      dataType: "string",
      enableRowGroup: true,
    },
    {
      field: "classification_override.label",
      headerName: "Classification",
      dataType: "string",
      initialHide: true,
      enableRowGroup: true,
      filter: "agSetColumnFilter",
    },
  ];

  return (
    <div className="modal-body">
      {otHourAllocation === "spread_evenly" && (
        <Banner
          type="info"
          content={
            "You are using evenly spread overtime calculation, so these timesheet sections may not exactly match yours when running payroll. To view your payroll settings, click here."
          }
          onClick={() => navigate("/payrolls/settings")}
        />
      )}
      {!!warnings?.length && (
        <TimesheetWarningsTooltip warnings={warnings} style={{ paddingTop: 15, fontSize: "0.9em" }} />
      )}
      {selectedTimesheet && (
        <TimesheetModal
          hide={() => setSelectedTimesheet(undefined)}
          id={selectedTimesheet}
          handleChange={() => {
            refreshTimesheets && refreshTimesheets();
          }}
          timesheetSections={sectionsByTimesheet[selectedTimesheet]}
        />
      )}
      <TableV2
        id={`timesheet-sections-table`}
        resource={`timesheet sections`}
        isLoading={loading}
        data={data}
        columns={timesheetSectionsTableCols}
        wrapperClassName="base-ssr-table"
        containerClassName={containerClassName}
        onClick={(row) => setSelectedTimesheet(row?.timesheet)}
        showReportViews={true}
        groupDefaultExpanded={1}
        hideSearch={!!payPeriodSelector}
        showTotals={false}
        renderLeftActionBar={payPeriodSelector}
        paginationPageSize={50}
      />
    </div>
  );
};
