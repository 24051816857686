import { useState, useEffect, FC, useMemo } from "react";
import { Notifier, TableV2 } from "ui";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { ColumnConfig } from "ui/table-v2/Table";
import { ScreeningRow, mapScreeningsToTableRow } from "dashboard/utils/screening";
import { Plus } from "phosphor-react";
import { AddScreeningModal } from "./AddScreeningModal";
import { ViewScreeningModal } from "./ViewScreeningModal";
import { useCheckrCompanyPackages } from "./useCheckrPackages";

const columns: ColumnConfig<ScreeningRow>[] = [
  {
    field: "checkr_package",
    headerName: "Package",
    dataType: "string",
    displayType: "badge",
    colors: {
      miter_basic_plus: "orange",
      miter_essential: "blue",
      miter_basic_plus_with_mvr: "light-purple",
      miter_essential_with_mvr: "green",
    },
  },
  {
    field: "created_at",
    headerName: "Ordered on",
    dataType: "date",
    dateType: "timestamp",
  },
  {
    field: "invitation_status",
    headerName: "Invitation status",
    dataType: "string",
    displayType: "badge",
    minWidth: 155,
    colors: {
      Pending: "blue",
      Completed: "green",
      Expired: "light-red",
    },
    enableRowGroup: true,
  },
];

type Props = {
  teamMember: AggregatedTeamMember;
};

export const ScreeningsTable: FC<Props> = ({ teamMember }) => {
  const [data, setData] = useState<ScreeningRow[]>([]);
  const [addScreeningModal, setAddScreeningModal] = useState(false);
  const [selectedScreening, setSelectedScreening] = useState<ScreeningRow | null>(null);

  const { loading, checkrEnabled, companyPackages, mapOfNodesToPackages } = useCheckrCompanyPackages();

  /* Handlers */
  const openAddScreeningModal = () => {
    setAddScreeningModal(true);
  };

  const closeAddScreeningModal = () => {
    getScreenings();
    setAddScreeningModal(false);
  };

  const openViewScreeningModal = (screening: ScreeningRow) => {
    setSelectedScreening(screening);
  };

  const closeViewScreeningModal = () => {
    setSelectedScreening(null);
  };

  const staticActions = useMemo(
    () => [
      {
        label: "Create new background check",
        className: "button-2 no-margin",
        action: openAddScreeningModal,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        important: true,
        disabled: loading,
        shouldShow: () => checkrEnabled,
      },
    ],
    [checkrEnabled, loading]
  );

  const getScreenings = async () => {
    try {
      const res = await MiterAPI.screenings.forage({
        filter: [
          { field: "team_member_id", value: teamMember._id },
          { field: "company_id", value: teamMember.company._id },
        ],
        select: undefined,
      });

      const formattedScreenings = mapScreeningsToTableRow(res.data);

      setData(formattedScreenings);
    } catch (e: $TSFixMe) {
      Notifier.error(`Error getting screenings for team member: ${e.message}`);
    }
  };

  useEffect(() => {
    getScreenings();
  }, []);

  return (
    <div>
      <TableV2
        id={"screenings-table"}
        title="Background Checks"
        resource="background checks"
        columns={columns}
        data={data}
        staticActions={staticActions}
        onClick={openViewScreeningModal}
        ssr={false}
      />
      {addScreeningModal && (
        <AddScreeningModal
          companyPackages={companyPackages}
          mapOfNodesToPackages={mapOfNodesToPackages}
          onExit={closeAddScreeningModal}
          teamMember={teamMember}
        />
      )}
      {selectedScreening && (
        <ViewScreeningModal
          teamMember={teamMember}
          onExit={closeViewScreeningModal}
          screening={selectedScreening}
          companyPackages={companyPackages}
        />
      )}
    </div>
  );
};
