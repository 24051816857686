// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ExpenseReimbursementPayoutMethod } from "backend/models/expense-reimbursement";
import { useExpenseReimbursementAbilities } from "dashboard/hooks/abilities-hooks/useExpenseReimbursementAbilities";
import {
  useTeamOptions,
  useLookupTeam,
  useActiveCompanyId,
  useJobOptions,
  useLookupJob,
  useDepartmentOptions,
  useLookupDepartment,
  useActivityOptionsMap,
  useLookupActivity,
  useCostTypeOptions,
  useLookupCostType,
  useJobNameFormatter,
  useActivityLabelFormatter,
  useLedgerAccountLabeler,
  useLedgerAccounts,
  useLookupLedgerAccount,
  useLookupPerDiemRates,
  getOptions,
  ledgerAccountOptionsMapCallback,
} from "dashboard/hooks/atom-hooks";
import { useApprovalGroupColumns } from "dashboard/utils/approvals";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";
import { useMemo } from "react";
import { isExpenseScoped } from "../activities/activityUtils";
import { ExpenseReimbursementEntry } from "./ExpenseReimbursementsTable";
import {
  useGetReimbursementPayoutMethodOptions,
  ONLY_PAYMENT_INFO_AND_JOB_COSTING_FIELDS_EDITABLE_STATUSES,
  glAccountSelectionOptions,
  ONLY_JOB_COSTING_FIELDS_EDITABLE_STATUSES,
} from "./expenseUtils";
import { ColumnConfig } from "ui/table-v2/Table";
import { toDollarFormat } from "miter-utils";
import { isCostTypeExpenseManagementScoped } from "dashboard/components/cost-types/costTypeUtils";

export function useReimbursementsTableColDefs(): ColumnConfig<ExpenseReimbursementEntry>[] {
  const expenseReimbursementAbilities = useExpenseReimbursementAbilities();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns({ ssr: true });

  const teamMemberOptions = useTeamOptions({
    predicate: (teamMember) =>
      expenseReimbursementAbilities.teamPredicate("update")(teamMember) ||
      expenseReimbursementAbilities.teamPredicate("create")(teamMember),
  });

  const lookupTeam = useLookupTeam();
  const activeCompanyId = useActiveCompanyId();

  const jobOptions = useJobOptions({
    predicate: (job) =>
      expenseReimbursementAbilities.jobPredicate("update")(job) ||
      expenseReimbursementAbilities.jobPredicate("create")(job),
  });

  const lookupJob = useLookupJob();

  const departmentOptions = useDepartmentOptions();
  const lookupDepartment = useLookupDepartment();

  const activityOptionsMappedByJob = useActivityOptionsMap({
    predicate: isExpenseScoped,
  });

  const lookupActivity = useLookupActivity();

  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });
  const lookupCostType = useLookupCostType();

  // these lookups prepend the job/GL account name to the displayed string
  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();
  const accountLabeler = useLedgerAccountLabeler();

  const ledgerAccounts = useLedgerAccounts();
  const lookupLedgerAccount = useLookupLedgerAccount();
  const lookupPerDiemRate = useLookupPerDiemRates();
  const approvalGroupColumn = useApprovalGroupColumns("expense_reimbursement");

  const getPayoutMethodOptions = useGetReimbursementPayoutMethodOptions();

  return useMemo(() => {
    const colors = {
      unapproved: "yellow",
      approved: "blue",
      processing: "light-green",
      denied: "red",
      paid: "green",
      mileage: "light-purple",
      out_of_pocket: "light-blue",
    };
    return [
      {
        /* eslint-disable */
        /* @ts-ignore incorrect circular dependency error */
        /* eslint-enable */
        field: "date",
        headerName: "Date",
        dataType: "date",
        sort: "desc",
        dateType: "iso",
        enableRowGroup: true,
        editorType: "date",
        editable: (params) => {
          return (
            params?.data?.status &&
            !ONLY_PAYMENT_INFO_AND_JOB_COSTING_FIELDS_EDITABLE_STATUSES.includes(params?.data?.status) &&
            params?.data?.type === "out_of_pocket" &&
            expenseReimbursementAbilities.can("update", params.data)
          );
        },
        editorDateType: "iso",
      },
      {
        field: "amount",
        headerName: "Amount",
        dataType: "number",
        valueFormatter: toDollarFormat,
        enableRowGroup: true,
        aggFunc: "sum",
        sumRow: true,
        editable: (params) => {
          return (
            params?.data?.status &&
            !ONLY_PAYMENT_INFO_AND_JOB_COSTING_FIELDS_EDITABLE_STATUSES.includes(params?.data?.status) &&
            params?.data?.type === "out_of_pocket" &&
            expenseReimbursementAbilities.can("update", params.data)
          );
        },
        validations: (value) => {
          if (value <= 0) {
            return "Amount must be positive.";
          }

          return true;
        },
        editorType: "number",
      },
      {
        field: "vendor_name",
        headerName: "Vendor",
        dataType: "string",
        enableRowGroup: true,
        editable: (params) => {
          return (
            params?.data?.status &&
            !ONLY_PAYMENT_INFO_AND_JOB_COSTING_FIELDS_EDITABLE_STATUSES.includes(params?.data?.status) &&
            params?.data?.type === "out_of_pocket" &&
            expenseReimbursementAbilities.can("update", params.data)
          );
        },
        editorType: "text",
      },
      {
        field: "status",
        headerName: "Status",
        dataType: "string",
        displayType: "badge",
        colors: colors,
        enableRowGroup: true,
        editable: false,
      },
      ...approvalGroupColumn,
      {
        headerName: "Team member",
        field: "team_member.full_name",
        dataType: "string",
        editableHide: true,
        enableRowGroup: true,
      },
      {
        headerName: "Team member",
        field: "team_member_id",
        dataType: "string",
        editable: (params) => {
          return (
            params?.data?.status &&
            !ONLY_PAYMENT_INFO_AND_JOB_COSTING_FIELDS_EDITABLE_STATUSES.includes(params?.data?.status) &&
            expenseReimbursementAbilities.can("update", params.data)
          );
        },
        editorType: "select",
        editableOnly: true,
        valueFormatter: (params) => {
          return lookupTeam(params.data?.team_member_id)?.full_name;
        },
        cellEditorParams: () => ({
          options: teamMemberOptions,
        }),
      },
      {
        field: "team_member.friendly_id",
        headerName: "Team member ID",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
      },
      {
        field: "department.name",
        headerName: "Department",
        dataType: "string",
        enableRowGroup: true,
        editableHide: true,
      },
      {
        field: "department_id",
        headerName: "Department",
        dataType: "string",
        editableOnly: true,
        editable: (params) => {
          return expenseReimbursementAbilities.can("update", params.data);
        },
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          return lookupDepartment(params.data?.department_id)?.name;
        },
        cellEditorParams: () => ({
          options: departmentOptions,
          isClearable: true,
        }),
      },
      {
        field: "type",
        headerName: "Type",
        dataType: "string",
        displayType: "badge",
        colors: colors,
        enableRowGroup: true,
      },
      {
        field: "distance",
        headerName: "Miles",
        dataType: "number",
      },
      {
        field: "expense_reimbursement_category",
        filterField: "expense_reimbursement_category.name",
        sortField: "expense_reimbursement_category.name",
        groupField: "expense_reimbursement_category.name",
        headerName: "Category",
        useValueFormatterForExport: true,
        valueFormatter: (params) => params.data?.expense_reimbursement_category?.name || "",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
      },
      {
        headerName: "Job",
        field: "job.name",
        filterField: "job.name",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
      },
      {
        headerName: "Job",
        field: "job_id",
        dataType: "string",
        minWidth: 200,
        editableOnly: true,
        editable: (params) => {
          return expenseReimbursementAbilities.can("update", params.data);
        },
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          return jobNameFormatter(lookupJob(params.data?.job_id));
        },
        cellEditorParams: () => ({
          options: jobOptions,
          isClearable: true,
        }),
      },
      {
        field: "job.code",
        headerName: "Job code",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
      },
      {
        field: "activity.label",
        headerName: "Activity",
        dataType: "string",
        minWidth: 220,
        enableRowGroup: true,
        editableHide: true,
        useValueFormatterForExport: true,
      },
      {
        headerName: "Activity",
        field: "activity_id",
        dataType: "string",
        minWidth: 220,
        enableRowGroup: true,
        editableOnly: true,
        editable: (params) => {
          return expenseReimbursementAbilities.can("update", params.data);
        },
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          return activityLabelFormatter(lookupActivity(params.data?.activity_id));
        },
        cellEditorParams: (row: { data: { job_id?: string } }) => {
          const jobId = row?.data?.job_id;
          return {
            options: activityOptionsMappedByJob.get(jobId),
            isClearable: true,
          };
        },
      },
      // required column for searching by cost code
      {
        field: "activity.cost_code",
        headerName: "Activity code",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
      },
      {
        headerName: "Cost type",
        field: "cost_type.label",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
      },
      {
        headerName: "Cost type",
        field: "cost_type_id",
        dataType: "string",
        minWidth: 200,
        editableOnly: true,
        editable: (params) => {
          return expenseReimbursementAbilities.can("update", params.data);
        },
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          return lookupCostType(params.value)?.label || "";
        },
        cellEditorParams: () => ({
          options: costTypeOptions,
          isClearable: true,
        }),
      },
      {
        headerName: "GL account",
        field: "ledger_account.label",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
        useValueFormatterForExport: true,
      },
      {
        headerName: "GL account ID",
        field: "ledger_account.external_id",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        editableHide: true,
        useValueFormatterForExport: true,
      },
      {
        headerName: "GL account",
        field: "expense_account_id",
        dataType: "string",
        minWidth: 200,
        editableOnly: true,
        editable: (params) => {
          return expenseReimbursementAbilities.can("update", params.data);
        },
        editorType: "select",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          // params.value should always be of type LedgerAccount
          return accountLabeler(lookupLedgerAccount(params.value)) || "";
        },
        cellEditorParams: (params: { data: { department_id?: string } }) => {
          const ledgerAccountOptions = getOptions(
            ledgerAccounts,
            {
              mapFunc: ledgerAccountOptionsMapCallback,
            },
            glAccountSelectionOptions({
              activeCompanyId,
              departmentId: params?.data?.department_id,
              lookupDepartment,
            })
          );
          return {
            options: ledgerAccountOptions,
            isClearable: true,
          };
        },
      },
      {
        headerName: "Team member memo",
        field: "memo",
        dataType: "string",
        minWidth: 200,
        editable: (params) => {
          return (
            params?.data?.status &&
            !ONLY_PAYMENT_INFO_AND_JOB_COSTING_FIELDS_EDITABLE_STATUSES.includes(params?.data?.status) &&
            expenseReimbursementAbilities.can("update", params.data)
          );
        },
      },
      {
        field: "payout_method",
        headerName: "Payout method",
        valueFormatter: (params) => {
          const payoutMethod: ExpenseReimbursementPayoutMethod = params.value;
          if (payoutMethod === "ach") {
            return "ACH to employee";
          } else if (payoutMethod === "payroll") {
            return "Payroll";
          } else if (payoutMethod === "manual") {
            return "Manual Payment";
          }
        },
        dataType: "string",
        minWidth: 200,
        alwaysShow: true, // always include payout_method on the forage request
        enableRowGroup: true,
        editable: (params) => {
          return (
            params?.data?.status &&
            !ONLY_JOB_COSTING_FIELDS_EDITABLE_STATUSES.includes(params?.data?.status) &&
            expenseReimbursementAbilities.can("update", params.data)
          );
        },
        editorType: "select",
        cellEditorParams: (params: { data: { team_member_id?: string } }) => {
          const payoutMethodOptions = getPayoutMethodOptions(params?.data?.team_member_id);

          // if there isn't a team member, we can assume the user is in bulk edit mode considering you can't create a reimbursement without a TM in the first place
          // in that case, always show payroll as an option and let the backend validate
          payoutMethodOptions[0].isDisabled = false;
          return {
            options: payoutMethodOptions,
          };
        },
      },
      {
        field: "is_taxable",
        headerName: "Taxable",
        dataType: "boolean",
        hide: true,
        editable: (params) => {
          return (
            params?.data?.status &&
            !ONLY_JOB_COSTING_FIELDS_EDITABLE_STATUSES.includes(params?.data?.status) &&
            expenseReimbursementAbilities.can("update", params.data)
          );
        },
        enableRowGroup: true,
      },
      {
        headerName: "Receipt attached?",
        field: "has_receipt",
        dataType: "boolean",
      },
      {
        field: "created_at",
        headerName: "Submitted at",
        dataType: "date",
        dateType: "timestamp",
        dateFormat: "LLL d, yyyy h:mm a",
        minWidth: 150,
      },
      {
        field: "per_diem_rate_id",
        headerName: "Per diem rate",
        headerTooltip:
          "If this is set, this reimbursement was automatically created by Miter from a per diem rate applied to a job.",
        dataType: "string",
        editableHide: true,
        valueFormatter: (params) => {
          return lookupPerDiemRate(params.value)?.name || "";
        },
      },
      ...jobHierarchyTableColumns,
    ] as ColumnConfig<ExpenseReimbursementEntry>[];
  }, [expenseReimbursementAbilities.can, getPayoutMethodOptions, jobHierarchyTableColumns]);
}
