import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { FileArrowDown, FileDotted, PlusCircle, Trash } from "phosphor-react";
import React, { FC, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { FilePicker } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import { styles as SelectStyles } from "ui/form/styles";
import styles from "./FileViewer.module.css";
import { FilePreviewRender } from "./FilePreviewRenderer";
import { downloadFiles } from "miter-utils";

type FileViewerProps = {
  files: FilePickerFile[];
  selectedFile?: FilePickerFile;
  onChange?: (files: FilePickerFile[] | null) => void;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
  mode: "create" | "update" | "read";
  multiple?: boolean;
  emptyStateText?: string;
  animate?: boolean;
  hideDelete?: boolean;
};

export const FileViewer: FC<FileViewerProps> = ({
  files,
  selectedFile,
  onChange,
  acceptedFileTypes,
  maxFileSize,
  mode,
  multiple = false,
  emptyStateText = "No files",
  hideDelete,
}) => {
  const [activeFile, setActiveFile] = useState<FilePickerFile | null | undefined>(selectedFile);
  const [activeFileURL, setActiveFileURL] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState(false);

  const [addFile, setAddFile] = useState(false);
  const filteredFiles = (files || [])?.filter((file) => !file.deleted);

  useEffect(() => {
    if (!activeFile && files && filteredFiles.length > 0) {
      setActiveFile(filteredFiles[0]);
    } else if (activeFile && filteredFiles.length === 0) {
      setActiveFile(null);
    }

    onChange?.(files);
  }, [files]);

  useEffect(() => {
    if (activeFile) {
      getFileURL();
    } else {
      setActiveFileURL(null);
    }
  }, [activeFile]);

  /*********************************************************
   *  Helper functions
   **********************************************************/

  const fileOptions = useMemo(
    () =>
      filteredFiles
        .map((a) => ({
          label: a?.blob?.name || a?.data?.label || a?.data?.originalname || "",
          value: a,
        }))
        .filter((a) => !!a.label && a.label.length),
    [files]
  );

  const getFileURL = async () => {
    setLoading(true);

    let url: string | undefined;

    if (activeFile?.url) {
      url = activeFile.url;
    } else if (activeFile?.blob) {
      url = URL.createObjectURL(activeFile.blob);
    } else if (activeFile?.data) {
      const res = await MiterAPI.files.get_urls({
        filter: [{ field: "_id", value: activeFile.data._id, type: "_id" }],
      });

      if (res.error) {
        Notifier.error("We are unable to open this file. Please try again later.");
        console.error(res.error);
      } else if (res.urls.length) {
        url = res.urls[0]!["value"].url;
      }
    } else {
      return "";
    }

    setActiveFileURL(url);
    setLoading(false);
  };

  /*********************************************************
   *  Handler functions
   **********************************************************/
  const handleFilesChange = (newFiles: FilePickerFile[] | null) => {
    // Find the new file
    if (newFiles) {
      const latestFile = newFiles?.slice()?.reverse()[0] || null;
      setActiveFile(latestFile);
    }

    onChange?.(newFiles);
  };

  const handleRemove = () => {
    if (!activeFile) return;

    // If the file is a file that already exists on the server, mark it as deleted
    if (activeFile.data) {
      const updatedFiles = (files || []).map((f) => {
        if (f.data && f.data._id === activeFile!.data!._id) {
          return { ...f, deleted: true };
        }
        return f;
      });

      handleFilesChange(updatedFiles);
    } else {
      // Otherwise, just remove it from the local files
      const updatedFiles = (files || []).filter((f) => f.blob !== activeFile.blob);
      handleFilesChange(updatedFiles);
    }
  };

  const handleAdd = () => {
    setAddFile(true);
  };

  const handleDownload = async () => {
    if (!activeFile?.data) return;
    downloadFiles([activeFile.data._id], () => {});
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderFileDropzone = () => {
    return (
      <FilePicker
        multiple={multiple}
        onChange={handleFilesChange}
        files={files}
        type={"dropzone"}
        maxFileSize={maxFileSize}
        acceptedFileTypes={acceptedFileTypes}
        customRenderSelectedFiles={() => <></>}
        className={styles["file-dropzone"]}
        sectionClassName={styles["file-dropzone-section"]}
        openPicker={addFile}
        setOpenPicker={setAddFile}
        hidden={!!activeFile || mode === "read"}
      />
    );
  };

  const renderActiveFileHeader = () => {
    return (
      <div className={styles["active-file-header"]}>
        <div className={styles["active-file-header-left"]}>
          <div className="formblock-wrapper modal">
            <div className="input-wrapper modal">
              <Select
                name="active_file"
                options={fileOptions}
                value={
                  fileOptions.find((a) => a.value?.data?.originalname === activeFile?.data?.originalname) ||
                  null
                }
                width={"100%"}
                zIndex={10}
                onChange={(option: $TSFixMe) => {
                  setActiveFile(option?.value || undefined);
                }}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                height="32px"
                // @ts-expect-error - react-select types are wrong
                styles={SelectStyles}
              />
            </div>
          </div>
        </div>
        <div className={styles["active-file-header-right"]}>
          {mode !== "read" && !hideDelete && (
            <button className={"button-1 no-margin " + styles["remove-file"]} onClick={handleRemove}>
              <Trash fontSize={"1.1rem"} />
            </button>
          )}
          <button className={"button-1 no-margin " + styles["download-file"]} onClick={handleDownload}>
            <FileArrowDown fontSize={"1.1rem"} />
          </button>
          {mode !== "read" && (multiple || filteredFiles.length === 0) && (
            <button className={"button-2 no-margin " + styles["add-file"]} onClick={handleAdd}>
              <PlusCircle fontSize={"1.1rem"} />
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderEmptyFiles = () => {
    if (mode !== "read") return;

    return (
      <div key={activeFileURL} className={styles["file-embed"] + " " + styles["files-empty"]}>
        <FileDotted fontSize={"4rem"} color="#4D54B6" weight="thin" style={{ marginBottom: 10 }} />
        <p>{emptyStateText}</p>
      </div>
    );
  };

  const renderActiveFile = () => {
    if (!activeFile || !activeFileURL) return <></>;

    return (
      <>
        {renderActiveFileHeader()}
        <div className={styles["active-file"]}>
          <FilePreviewRender file={activeFile} fileURL={activeFileURL} />
        </div>
      </>
    );
  };

  return (
    <div className={styles["files-center"]}>
      {activeFile && renderActiveFile()}
      {!loading && (!files || files?.length === 0) && renderEmptyFiles()}
      {renderFileDropzone()}
    </div>
  );
};

export default FileViewer;
