import React, { useMemo, useState } from "react";
import {
  ChangeRequestFieldsChangedType,
  ChangeRequestParentType,
  TeamMemberChangeCategory,
} from "backend/models/change-request";
import { CreateChangeRequestParams, UpdateTeamMemberParams } from "dashboard/miter";
import { Formblock, PageModal } from "ui";
import { PageModalActionLink } from "ui/modal/PageModal";
import {
  useActiveCompanyId,
  useActiveRole,
  useActiveTeamMember,
  useLookupTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { AggregatedTeamMemberWithI9 } from "dashboard/miter";
import FieldsChangedTable from "./FieldsChangedTable";
import { useChangeRequestPolicy } from "dashboard/utils/policies/change-request-policy-utils";
import styles from "./createChangeRequestModal.module.css";
import { DateTime } from "luxon";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

type CreateChangeRequestModalProps = {
  parentType: ChangeRequestParentType;
  parentObject: AggregatedTeamMemberWithI9;
  newData: UpdateTeamMemberParams;
  category: TeamMemberChangeCategory;
  onHide: () => void;
  hideEditModal: () => void;
};

const CreateChangeRequestModal: React.FC<CreateChangeRequestModalProps> = ({
  parentType,
  parentObject,
  newData,
  category,
  onHide,
  hideEditModal,
}) => {
  // hooks
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const activeRole = useActiveRole();
  const activeTeamMember = useActiveTeamMember();
  const teamAbilities = useTeamAbilities();
  const lookupTeam = useLookupTeam();
  const deptId = lookupTeam(activeTeamMember?._id)?.department_id;
  const canUpdate = teamAbilities.can("update", parentObject);

  if (!activeCompanyId) throw new Error("No active company id found");
  if (!activeUser) throw new Error("No active user found");

  // build change request data
  const formatPreviousValue = (key: string, parentObject: AggregatedTeamMemberWithI9) => {
    if (key in parentObject) {
      if (key !== "union_rate") {
        return parentObject[key];
      } else {
        return parentObject[key]?._id;
      }
    }
    return null;
  };
  const fieldsChanged: ChangeRequestFieldsChangedType = useMemo(() => {
    return Object.keys(newData).map((key) => {
      return {
        key: key,
        value: {
          previous_value: formatPreviousValue(key, parentObject),
          new_value: newData[key],
        },
      };
    });
  }, [newData, parentObject]);

  // state variables
  const [notes, setNotes] = useState("");
  const [effectiveDate, setEffectiveDate] = useState<DateTime>();
  const [notesError, setNotesError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const changeRequestData: CreateChangeRequestParams = {
    parent_type: parentType,
    parent_id: parentObject._id,
    company_id: activeCompanyId,
    author_id: activeUser?._id,
    author_team_member_id: activeTeamMember?._id,
    author_role_id: activeRole?._id,
    department_id: deptId ? deptId : undefined,
    fields_changed: fieldsChanged,
    child_fields: { category: category },
    status: canUpdate ? "scheduled" : "unapproved",
  };

  const { isFieldRequired, isFieldVisible } = useChangeRequestPolicy(changeRequestData);

  const handleSubmit = async () => {
    if (!canUpdate && isFieldRequired("notes") && !notes) {
      setNotesError(true);
      return;
    }

    if ((canUpdate || isFieldRequired("effective_date")) && !effectiveDate) {
      setDateError(true);
      return;
    }

    try {
      const response = await MiterAPI.change_requests.create({
        ...changeRequestData,
        notes: notes,
        effective_date: effectiveDate?.toISODate(),
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Change request created successfully");
      hideEditModal();
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  const modalActions: PageModalActionLink[] = useMemo(() => {
    const actions: PageModalActionLink[] = [
      {
        label: "Cancel",
        action: onHide,
        position: "right",
        className: "button-1 no-margin",
      },
      {
        label: canUpdate ? "Schedule changes" : "Submit changes",
        action: handleSubmit,
        position: "right",
        className: "button-2 no-margin",
      },
    ];
    return actions;
  }, [onHide, handleSubmit]);

  return (
    <PageModal
      header={canUpdate ? "Create scheduled change request" : "Create change request"}
      footerActions={modalActions}
      onClose={onHide}
    >
      <h2 className={styles["heading"]}>Your changes</h2>
      <FieldsChangedTable parentId={parentObject._id} fieldsChanged={fieldsChanged} />
      {(isFieldVisible("notes") || isFieldVisible("effective_date")) && (
        <h2 className={[styles["heading"], styles["first-heading"]].join(" ")}>Additional info</h2>
      )}
      {(isFieldVisible("effective_date") || canUpdate) && (
        <>
          <Formblock
            className="modal wizard"
            label="Scheduled date"
            type="datetime"
            defaultValue={effectiveDate}
            editing={true}
            onChange={(e) => setEffectiveDate(e)}
            min={DateTime.now().plus({ days: 1 })}
            labelInfo="These changes will affect payrolls approved after the scheduled date."
            dateOnly
          />
          {dateError && <p className={styles["error-msg"]}>Scheduled date is required</p>}
        </>
      )}
      {(isFieldVisible("notes") || canUpdate) && (
        <>
          <Formblock
            className="modal wizard"
            label="Notes"
            type="paragraph"
            defaultValue={notes}
            editing={true}
            onChange={(e) => setNotes(e.target.value)}
          />
          {notesError && <p className={styles["error-msg"]}>Notes are required</p>}
        </>
      )}
    </PageModal>
  );
};

export default CreateChangeRequestModal;
