import React, { useEffect, useMemo, useState } from "react";
import { ActionModal, Formblock, Notifier } from "ui";
import { ActiveTimesheetButtonState, prepareTimesheetSignOff } from "dashboard/utils/timesheetUtils";
import {
  AutomaticBreakTimeSettings,
  CreateTimesheetParams,
  LiveTimesheet,
  MiterAPI,
  Timesheet,
} from "dashboard/miter";
import {
  useActiveCompany,
  useActiveTeamMember,
  useJobOptions,
  useClassificationOptions,
  useLookupTimeOffPolicy,
  useLookupTeam,
  useActivityOptionsMap,
  useEquipmentOptions,
} from "dashboard/hooks/atom-hooks";
import { useForm } from "react-hook-form";
import {
  useAutomaticBreakTimeSettings,
  useTimesheetPolicy,
} from "dashboard/utils/policies/timesheet-policy-utils";
import { required } from "dashboard/utils/validators";
import {
  getEarningTypeAliasForTs,
  getEarningTypeOptionsForTmFlattened,
  getEarningTypeParamsFromAlias,
  useEarningTypeLabeler,
} from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import styles from "./ClockInModal.module.css";
import {
  CreateLiveTimesheetParams,
  UpdateLiveTimesheetParams,
} from "backend/services/live-timesheet-service";
import { DateTime } from "luxon";
import ClockOutForm from "./ClockOutForm";
import { cleanCustomFieldValueParams } from "miter-utils";
import { PartialCustomFieldValue } from "backend/services/custom-field-value-service";
import { JobInput } from "../shared/JobInput";
import { Option } from "ui/form/Input";
import { DraftTimesheet } from "dashboard/pages/timesheets/BulkCreateTimesheets/BulkCreateTimesheets";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { ESignatureInputValue } from "packages/ui/form/ESignatureInput";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

type ClockInModalProps = {
  onHide: () => void;
  buttonState: ActiveTimesheetButtonState;
  liveTimesheet: LiveTimesheet | undefined | null;
  setLiveTimesheet: (timesheet: LiveTimesheet | null) => void;
  clockTime?: string;
};

type ClockInModalState = ActiveTimesheetButtonState | "clock-out";

export type LiveTimesheetFormFields = Omit<
  CreateLiveTimesheetParams,
  | "earning_type"
  | "job_id"
  | "activity_id"
  | "time_off_policy_id"
  | "classification_override"
  | "equipment_ids"
> & {
  job?: Option<string>;
  activity?: Option<string>;
  classification_override?: Option<string>;
  earning_type_alias: Option<string | null>;
  break_type?: Option<string>;
  injury?: Option<boolean>;
  sign_off?: ESignatureInputValue;
  equipment_ids?: Option<string>[];
};

const getModalHeader = (modalState: ClockInModalState): string => {
  switch (modalState) {
    case "on-break":
    case "clocked-in":
      return "Active timesheet";
    case "clock-out":
      return "Clock out";
    default:
      return "Clock in";
  }
};

const getBannerText = (modalState: ClockInModalState): string => {
  switch (modalState) {
    case "clocked-in":
      return "ACTIVE TIME CLOCK";
    case "on-break":
      return "ON BREAK";
    default:
      return "";
  }
};

const getSubmitText = (modalState: ClockInModalState, shouldSwitchTimesheet: boolean): string => {
  switch (modalState) {
    case "no-live-timesheet":
      return "Clock in";
    case "clocked-in":
    case "on-break":
      return "Clock out";
    case "clock-out":
      return shouldSwitchTimesheet ? "Save and switch" : "Save and clock out";
    default:
      return "Submit";
  }
};

const getEditText = (modalState: ClockInModalState): string => {
  switch (modalState) {
    case "clocked-in":
      return "Take break";
    case "on-break":
      return "End break";
    case "clock-out":
      return "Back";
    default:
      return "";
  }
};

const ClockInModal: React.FC<ClockInModalProps> = ({
  onHide,
  buttonState,
  liveTimesheet,
  setLiveTimesheet,
  clockTime,
}) => {
  // hooks
  const company = useActiveCompany();
  const teamMember = useActiveTeamMember();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const earningTypeLabeler = useEarningTypeLabeler();
  const activityOptionsMap = useActivityOptionsMap({
    predicate: isTimesheetScoped,
  });
  const jobOptions = useJobOptions();
  const classificationOptions = useClassificationOptions({
    tmId: teamMember?._id,
    activityId: liveTimesheet?.activity_id,
    jobId: liveTimesheet?.job_id,
  });
  const lookupTeam = useLookupTeam();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const equipmentOptions = useEquipmentOptions();
  // states
  const [modalState, setModalState] = useState<ClockInModalState>(buttonState);
  const [modalHeader, setModalHeader] = useState(getModalHeader(modalState));
  const [lastClockOutTime, setLastClockOutTime] = useState<number | undefined>(undefined);
  const [shouldSwitchTimesheet, setShouldSwitchTimesheet] = useState<boolean>(false);
  const [bannerText, setBannerText] = useState<string>(getBannerText(modalState));
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [activityOptions, setActivityOptions] = useState<Option<string>[] | undefined>(() =>
    activityOptionsMap.get(liveTimesheet?.job_id)
  );

  // static variables
  const timesheetSettings = company?.settings.timesheets;
  const aggregatedTm = lookupTeam(teamMember?._id);
  const earningTypeAlias = useMemo(() => getEarningTypeAliasForTs(liveTimesheet), [liveTimesheet]);
  const earningTypeOptions = useMemo(() => {
    return getEarningTypeOptionsForTmFlattened(lookupTimeOffPolicy, "timesheet", aggregatedTm || undefined);
  }, [teamMember, earningTypeLabeler]);
  const breakTypesObject = timesheetSettings?.break_types || {};
  const breakTypeOptions = useMemo(() => {
    return Object.entries(breakTypesObject)
      .filter(([_id, breakType]) => !breakType.archived)
      .map(([id, breakType]) => ({ label: breakType.label, value: id }));
  }, [company]);

  // useEffects
  useEffect(() => {
    setModalHeader(getModalHeader(modalState));
    setBannerText(getBannerText(modalState));
  }, [modalState]);

  useEffect(() => {
    if (modalState !== "clock-out") {
      setModalState(buttonState);
    }
  }, [buttonState]);

  // form setup
  const buildDefaultLiveTimesheetValues = (
    liveTimesheet: LiveTimesheet | undefined | null
  ): Partial<LiveTimesheetFormFields> | undefined => {
    return liveTimesheet
      ? {
          job: jobOptions?.find((option) => option.value === liveTimesheet.job_id),
          activity: activityOptions?.find((option) => option.value === liveTimesheet.activity_id),
          classification_override: classificationOptions?.find(
            (option) => option.value === liveTimesheet.classification_override
          ),
          earning_type_alias: earningTypeOptions?.find(
            (option) => option.value === getEarningTypeAliasForTs(liveTimesheet)
          ),
          equipment_ids: equipmentOptions?.filter((option) =>
            (liveTimesheet.equipment_ids || []).includes(option.value)
          ),
          notes: liveTimesheet.notes,
        }
      : undefined;
  };
  const form = useForm<LiveTimesheetFormFields>({
    defaultValues: buildDefaultLiveTimesheetValues(liveTimesheet),
    shouldUnregister: false,
  });
  const formData = form.watch();
  const { register, handleSubmit, setValue } = form;

  // policies
  const draftTimesheet: DraftTimesheet = useMemo(() => {
    return {
      team_member: teamMember?._id,
      job: formData.job?.value,
      activity: formData.activity?.value,
      classification_override: formData.classification_override?.value,
      ...(formData.earning_type_alias?.value
        ? {
            earning_type:
              getEarningTypeParamsFromAlias(formData.earning_type_alias?.value).earning_type || undefined,
            time_off_policy_id: getEarningTypeParamsFromAlias(formData.earning_type_alias?.value)
              .time_off_policy_id,
          }
        : { earning_type: undefined }),
      notes: formData.notes,
    };
  }, [formData]);
  const automaticBreakTimeSettings: AutomaticBreakTimeSettings =
    useAutomaticBreakTimeSettings(draftTimesheet);
  const { isFieldRequired, isFieldVisible, signOff } = useTimesheetPolicy(draftTimesheet);
  const shouldShowAdvancedBreak =
    timesheetSettings?.advanced_break_time && !automaticBreakTimeSettings?.auto_add_break;

  // build params functions
  const getParamsFromFormFields = (data: LiveTimesheetFormFields) => {
    return {
      notes: data.notes,
      job_id: data.job?.value,
      activity_id: data.activity?.value,
      classification_override: data.classification_override?.value,
      equipment_ids: data.equipment_ids?.map((option) => option.value),
      ...getEarningTypeParamsFromAlias(data.earning_type_alias?.value),
    };
  };

  const getTimesheetCreationParamsFromLiveTimesheet = (liveTimesheet: LiveTimesheet) => {
    return {
      team_member: liveTimesheet.team_member_id,
      company: liveTimesheet.company_id,
      clock_in: liveTimesheet.clock_in,
      creation_method: "dashboard_clock_in",
      live_timesheet_id: liveTimesheet._id,
      job: liveTimesheet.job_id,
      activity: liveTimesheet.activity_id,
      classification_override: liveTimesheet.classification_override,
      earning_type: liveTimesheet.earning_type,
      time_off_policy_id: liveTimesheet.time_off_policy_id,
      equipment_ids: liveTimesheet.equipment_ids,
      notes: liveTimesheet.notes,
    };
  };

  const buildTimesheetCreationParams = (
    data: LiveTimesheetFormFields,
    liveTimesheet: LiveTimesheet
  ): CreateTimesheetParams | null => {
    if (!company || !teamMember || !liveTimesheet) return null;

    const clockOutAtSave = timesheetSettings?.record_clock_out_time_as === "after_clock_out_form";
    const clockOutSeconds =
      clockOutAtSave || !lastClockOutTime ? DateTime.now().toSeconds() : lastClockOutTime;
    const breaks = (liveTimesheet.breaks as Timesheet["breaks"]) || []; // breaks from dashboard clock in live timesheet should always be completed

    let totalBreakTime = liveTimesheet.total_break_time || 0;
    if (liveTimesheet.active_break_start) {
      if (shouldShowAdvancedBreak) {
        if (!data.break_type?.value) {
          Notifier.error("Please select a break type.");
          return null;
        }
        breaks.push({
          start: liveTimesheet.active_break_start,
          end: clockOutSeconds,
          duration: clockOutSeconds - liveTimesheet.active_break_start,
          break_type_id: data.break_type?.value,
          paid: breakTypesObject[data.break_type?.value]?.paid || false,
        });
      } else {
        totalBreakTime += clockOutSeconds - liveTimesheet.active_break_start;
      }
    }

    // prepare injury, check for sign off params
    const injury = !!data.injury?.value;
    if (signOff && !data.sign_off) {
      Notifier.error("You must sign off on your timesheet to clock out.");
      return null;
    }
    if (signOff && !data.sign_off?.agreed_to_disclosure) {
      Notifier.error("You must agree to the disclosure to sign off on the timesheet.");
      return null;
    }

    // remove fields so only custom fields are left
    [
      "job",
      "activity",
      "classification_override",
      "earning_type_alias",
      "notes",
      "injury",
      "sign_off",
      "break_type",
      "equipment_ids",
    ].forEach((key) => delete data[key]);

    const customFieldValues: PartialCustomFieldValue[] = [];
    for (const key in data) {
      const value = cleanCustomFieldValueParams(data[key]);

      customFieldValues.push({
        custom_field_id: key,
        value,
      });
    }

    const timesheetCreationParams: CreateTimesheetParams = {
      ...getTimesheetCreationParamsFromLiveTimesheet(liveTimesheet),
      injury: injury,
      custom_field_values: customFieldValues || [],
      clock_out: clockOutSeconds,
      breaks: breaks,
      unpaid_break_time: totalBreakTime,
    };

    return timesheetCreationParams;
  };

  // onSubmit functions
  const clockIn = async (data: LiveTimesheetFormFields) => {
    if (!company || !teamMember) return;
    const filter = [
      { field: "company_id", value: company._id },
      { field: "team_member_id", value: teamMember._id },
      { field: "archived", value: false },
      { field: "creation_method", value: "dashboard_clock_in" },
    ];

    try {
      const res = await MiterAPI.live_timesheets.list({ filter });
      if (res.error) throw new Error(res.error);
      if (res?.[0]) {
        Notifier.error("You already have an active timesheet. Please refresh to see your running timesheet.");
        return;
      }
    } catch (e: $TSFixMe) {
      Notifier.error(e);
    }

    const creationParams: CreateLiveTimesheetParams = {
      clock_in: DateTime.now().toSeconds(),
      company_id: company?._id,
      team_member_id: teamMember?._id,
      creation_method: "dashboard_clock_in",
      ...getParamsFromFormFields(data),
    };

    try {
      const res = await MiterAPI.live_timesheets.create(creationParams);
      if (res.error) throw new Error(res.error);
      setLiveTimesheet(res);
    } catch (e: $TSFixMe) {
      Notifier.error(e);
    }
    setModalState("clocked-in");
  };

  const clockOut = async (data: LiveTimesheetFormFields) => {
    // save timesheet form fields onto live timesheet
    if (!liveTimesheet) return;
    const isMissingBreakType = !data.break_type?.value && shouldShowAdvancedBreak;
    if (modalState === "on-break" && isMissingBreakType) {
      Notifier.error("Please select a break type.");
      return;
    }

    const timeNow = DateTime.now().toSeconds();
    if (timeNow - liveTimesheet.clock_in > 24 * 60 * 60) {
      Notifier.error(
        "Timesheet cannot be over 24 hours. The clock out time of this timesheet will be set to 24 hours after clock in."
      );
      setLastClockOutTime(liveTimesheet.clock_in + 24 * 60 * 60);
    } else {
      setLastClockOutTime(timeNow);
    }

    const updateParams: UpdateLiveTimesheetParams = {
      ...getParamsFromFormFields(data),
    };
    try {
      const res = await MiterAPI.live_timesheets.update(liveTimesheet._id, updateParams);
      if (res.error) throw new Error(res.error);
      setLiveTimesheet(res);
    } catch (e: $TSFixMe) {
      Notifier.error(e);
    }

    setModalState("clock-out");
  };

  const saveAndClockOut = async (data: LiveTimesheetFormFields) => {
    if (!liveTimesheet || !company || !teamMember) return;
    const signOffData = data.sign_off;
    const timesheetCreationParams = buildTimesheetCreationParams(data, liveTimesheet);
    if (!timesheetCreationParams) return;
    setIsSaving(true);

    try {
      const res = await MiterAPI.timesheets.create(timesheetCreationParams);
      if (res.error) throw new Error(res.error);

      const liveTimesheetRes = await MiterAPI.live_timesheets.archive({ id: liveTimesheet._id });
      if (liveTimesheetRes.error) throw new Error(liveTimesheetRes.error);

      if (signOff && signOffData) {
        const timesheetSignOff = await prepareTimesheetSignOff(signOffData, company._id, teamMember, res._id);
        const updateRes = await MiterAPI.timesheets.update_one(res._id, { sign_off: timesheetSignOff });
        if (updateRes.error) throw new Error(updateRes.error);
      }

      if (shouldSwitchTimesheet) {
        await clockIn(data);
        setShouldSwitchTimesheet(false);
      } else {
        setModalState("no-live-timesheet");
        setLiveTimesheet(null);
        onHide();
      }
      Notifier.success("Timesheet submitted");
    } catch (e: $TSFixMe) {
      Notifier.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const getSubmitFunction = (modalState: ClockInModalState) => {
    switch (modalState) {
      case "no-live-timesheet":
        return clockIn;
      case "clocked-in":
      case "on-break":
        return clockOut;
      case "clock-out":
        return saveAndClockOut;
      default:
        return () => {};
    }
  };

  // onEdit functions
  const goBackFromClockOutForm = () => {
    if (!liveTimesheet) {
      setModalState("no-live-timesheet");
    } else if (liveTimesheet.active_break_start) {
      setModalState("on-break");
    } else {
      setModalState("clocked-in");
    }
  };

  const startBreak = handleSubmit(async (data: LiveTimesheetFormFields) => {
    if (!liveTimesheet) return;
    const updateParams: UpdateLiveTimesheetParams = {
      active_break_start: DateTime.now().toSeconds(),
      ...getParamsFromFormFields(data),
    };
    try {
      const res = await MiterAPI.live_timesheets.update(liveTimesheet._id, updateParams);
      if (res.error) throw new Error(res.error);
      setLiveTimesheet(res);
      setModalState("on-break");
    } catch (e: $TSFixMe) {
      Notifier.error(e);
    }
  });

  const endBreak = handleSubmit(async (data: LiveTimesheetFormFields) => {
    if (!liveTimesheet || !liveTimesheet.active_break_start) return;
    const isMissingBreakType = !formData.break_type?.value && shouldShowAdvancedBreak;
    if (isMissingBreakType) {
      Notifier.error("Please select a break type.");
      return;
    }
    const breaks = liveTimesheet.breaks || [];
    const currentTotalBreakTime = liveTimesheet.total_break_time || 0;
    const timeNow = DateTime.now().toSeconds();
    if (timesheetSettings?.advanced_break_time) {
      if (!formData.break_type?.value) return;

      breaks.push({
        start: liveTimesheet.active_break_start,
        end: timeNow,
        duration: timeNow - liveTimesheet.active_break_start,
        break_type_id: formData.break_type?.value,
        paid: breakTypesObject[formData.break_type?.value]?.paid || false,
      });
    }
    const updateParams: UpdateLiveTimesheetParams = {
      active_break_time: 0,
      total_break_time: currentTotalBreakTime + DateTime.now().toSeconds() - liveTimesheet.active_break_start,
      active_break_start: null,
      breaks: breaks,
      ...getParamsFromFormFields(data),
    };
    try {
      const res = await MiterAPI.live_timesheets.update(liveTimesheet._id, updateParams);
      if (res.error) throw new Error(res.error);
      setLiveTimesheet(res);
      setModalState("clocked-in");
    } catch (e: $TSFixMe) {
      Notifier.error(e);
    }
  });

  const getOnEditFunction = () => {
    switch (modalState) {
      case "clock-out":
        return goBackFromClockOutForm;
      case "clocked-in":
        return startBreak;
      case "on-break":
        return endBreak;
      default:
        return () => {};
    }
  };

  // onCancel function
  const switchTimesheet = () => {
    if (!liveTimesheet) return;
    setLastClockOutTime(DateTime.now().toSeconds());
    setShouldSwitchTimesheet(true);
    setModalState("clock-out");
  };

  // rendering different form fields on the modal
  const renderTimesheetFields = () => {
    return (
      <div>
        {isFieldVisible("job") && (
          <JobInput
            name="job"
            label="Job"
            type="select"
            form={form}
            options={jobOptions}
            maxMenuHeight={200}
            editing={true}
            requiredSelect={isFieldRequired("job")}
            defaultValue={liveTimesheet?.job_id}
            onChange={(jobOption: Option<string>) => {
              const newActivityOptions = activityOptionsMap.get(jobOption?.value);
              setActivityOptions(newActivityOptions);
              if (
                formData?.activity?.value &&
                !newActivityOptions.some((option) => option.value === formData?.activity?.value)
              ) {
                setValue("activity", null);
              }
            }}
            isClearable
          />
        )}
        {isFieldVisible("activity") && (
          <Formblock
            name="activity"
            form={form}
            type="select"
            label="Activity"
            options={activityOptions || []}
            requiredSelect={isFieldRequired("activity")}
            editing={true}
            defaultValue={liveTimesheet?.activity_id}
            isClearable
          />
        )}
        {hasAccessToEquipmentTracking && isFieldVisible("equipment_ids") && (
          <Formblock
            name="equipment_ids"
            label="Equipment"
            type="multiselect"
            options={equipmentOptions}
            form={form}
            editing={true}
            style={{
              control: (base) => ({
                ...base,
                height: "fit-content",
              }),
            }}
            defaultValue={liveTimesheet?.equipment_ids}
            requiredSelect={isFieldRequired("equipment_ids")}
            isClearable
          />
        )}
        {isFieldVisible("classification_override") && (
          <Formblock
            name="classification_override"
            label="Classification"
            type="select"
            options={classificationOptions}
            form={form}
            editing={true}
            requiredSelect={isFieldRequired("classification_override")}
            defaultValue={liveTimesheet?.classification_override}
            isClearable
          />
        )}
        {(timesheetSettings?.always_display_earning_types || (earningTypeAlias && earningTypeOptions)) && (
          <Formblock
            name="earning_type_alias"
            options={earningTypeOptions}
            form={form}
            label="Earning type"
            type="select"
            editing={true}
            defaultValue={earningTypeAlias}
            isClearable
          />
        )}
        {isFieldVisible("notes") && (
          <Formblock
            name="notes"
            form={form}
            type="paragraph"
            label="Notes"
            register={isFieldRequired("notes") ? register(required) : undefined}
            editing={true}
          />
        )}
      </div>
    );
  };

  const renderClockInField = () => {
    return (
      <div>
        {liveTimesheet?.clock_in && (
          <Formblock
            type="text"
            label="Clock in"
            editing={false}
            defaultValue={DateTime.fromSeconds(liveTimesheet.clock_in).toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS
            )}
          />
        )}
      </div>
    );
  };

  const renderBreakFields = () => {
    return (
      <div className={styles["break-fields"]}>
        {liveTimesheet?.active_break_start && (
          <Formblock
            type="text"
            label="Break started at"
            editing={false}
            defaultValue={DateTime.fromSeconds(liveTimesheet.active_break_start).toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS
            )}
          />
        )}
        {shouldShowAdvancedBreak && (
          <Formblock
            type="select"
            name="break_type"
            label="Break type"
            options={breakTypeOptions}
            form={form}
            editing={true}
            requiredSelect={false}
          />
        )}
      </div>
    );
  };

  // render banner based on the modal state
  const renderBanner = () => {
    return (
      <div className={`${styles["active-banner"]} ${styles[buttonState as string]}`}>
        <div className="flex space-between">
          <div>{bannerText}</div>
          <div>{clockTime}</div>
        </div>
      </div>
    );
  };

  // clock out screen
  const renderClockOutForm = () => {
    return (
      <ClockOutForm
        form={form}
        isFieldRequired={isFieldRequired}
        isFieldVisible={isFieldVisible}
        signOff={signOff}
      />
    );
  };

  return (
    <ActionModal
      wrapperStyle={modalState === "clock-out" ? { width: "60%" } : { width: "40%" }}
      bodyStyle={{ paddingTop: "10px", paddingBottom: "10px" }}
      headerText={modalHeader}
      onHide={onHide}
      showSubmit={true}
      showCancel={modalState !== "clock-out"}
      showEdit={modalState !== "no-live-timesheet"}
      editText={getEditText(modalState)}
      submitText={getSubmitText(modalState, shouldSwitchTimesheet)}
      cancelText={modalState === "no-live-timesheet" ? "Cancel" : "Switch"}
      onSubmit={handleSubmit(getSubmitFunction(modalState))}
      onCancel={modalState === "no-live-timesheet" ? onHide : switchTimesheet}
      onEdit={getOnEditFunction()}
      submitDisabled={isSaving}
    >
      {(modalState === "clocked-in" || modalState === "on-break") && renderBanner()}
      {modalState === "on-break" && renderBreakFields()}
      {(modalState === "clocked-in" || modalState === "on-break") && renderClockInField()}
      {modalState !== "clock-out" && renderTimesheetFields()}
      {modalState === "clock-out" && renderClockOutForm()}
    </ActionModal>
  );
};

export default ClockInModal;
