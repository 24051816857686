import React, { FC, useEffect, useMemo, useState } from "react";
import { WizardScreen, Notifier } from "ui";
import { I9FormProps } from "./I9Wizard";

import { deleteFiles, I9Document, I9Documents } from "miter-utils";
import styles from "./I9Wizard.module.css";
import { isEqual } from "lodash";
import useWizard from "ui/modal/useWizard";
import { MiterAPI } from "dashboard/miter";

type DocumentsFormFields = {
  listA: string | undefined;
  listB: string | undefined;
  listC: string | undefined;
};

const SelectDocumentsForm: FC<I9FormProps> = ({ I9, setI9, name = "Select Documents" }) => {
  const { setCanNext } = useWizard();

  // Only include the documents that available for this user's authorization type
  const filteredI9Documents = useMemo(() => {
    return I9Documents.filter((doc) =>
      doc.employee_authorization_types.includes(I9.section_1?.employee_authorization_type)
    );
  }, [I9Documents]);

  /*********************************************************
   *  States
   **********************************************************/
  const [documents, setDocuments] = useState<DocumentsFormFields>({
    listA: I9?.section_2?.list_a?.[0]?.type,
    listB: I9?.section_2?.list_b?.type,
    listC: I9?.section_2?.list_c?.type,
  });

  /*********************************************************
   * Variables
   **********************************************************/
  const isDirty = useMemo(() => {
    return (
      documents?.listA !== I9?.section_2?.list_a?.[0]?.type ||
      documents?.listB !== I9?.section_2?.list_b?.type ||
      documents?.listC !== I9?.section_2?.list_c?.type
    );
  }, [I9, documents]);

  /*********************************************************
   * useEffect's
   **********************************************************/
  useEffect(() => {
    setDocuments({
      listA: I9?.section_2?.list_a?.[0]?.type,
      listB: I9?.section_2?.list_b?.type,
      listC: I9?.section_2?.list_c?.type,
    });
  }, [I9]);

  useEffect(() => {
    if (documents.listA || (documents.listB && documents.listC)) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [documents]);

  /*********************************************************
   *  Wizard handlers
   **********************************************************/
  const onNext = async () => {
    await save(documents);
  };

  /*********************************************************
   *  Backend submission + cleaning functions
   **********************************************************/
  const cleanParams = (params: DocumentsFormFields) => {
    // Make sure that only listA has a value or both listB and listC have values
    if (!params.listA && !params.listB && !params.listC) {
      throw new Error(
        "Please select at least one document form List A or select a document, each, from List B and List C"
      );
    }

    if (params.listA && (params.listB || params.listC)) {
      throw new Error(
        "Please select a document, each, from List B and List C or select a document from List A"
      );
    }

    if (params.listB && !params.listC) {
      throw new Error("Please select a document from List C as well");
    }

    if (params.listC && !params.listB) {
      throw new Error("Please select a document from List B as well");
    }

    return {
      "section_2.list_a": params.listA ? [{ type: params.listA }] : [],
      "section_2.list_b": params.listB ? { type: params.listB } : { file_ids: [] },
      "section_2.list_c": params.listC ? { type: params.listC } : { file_ids: [] },
    };
  };

  const deleteOldDocuments = async (params: DocumentsFormFields) => {
    const { listA, listB, listC } = params;

    const oldListA = I9?.section_2?.list_a?.[0]?.type;
    const oldListB = I9?.section_2?.list_b?.type;
    const oldListC = I9?.section_2?.list_c?.type;

    const filesToDelete: string[] = [];

    if (oldListA && oldListA !== listA) {
      filesToDelete.push(...(I9?.section_2?.list_a?.[0]?.file_ids || []));
    }

    if (oldListB && oldListB !== listB) {
      filesToDelete.push(...(I9?.section_2?.list_b?.file_ids || []));
    }

    if (oldListC && oldListC !== listC) {
      filesToDelete.push(...(I9?.section_2?.list_c?.file_ids || []));
    }

    if (filesToDelete.length > 0) {
      await deleteFiles(filesToDelete);
    }
  };

  const save = async (data: DocumentsFormFields) => {
    if (!isDirty) return;

    try {
      const params = cleanParams(data);

      // Update the I-9
      // @ts-expect-error fix me
      const res = await MiterAPI.i_9s.update(I9._id, params);
      if (res.error) throw new Error(res.error);

      // Delete old files if needed
      await deleteOldDocuments(data);

      // Refetch user data
      setI9(res);
    } catch (e: $TSFixMe) {
      console.log("Error submitting I-9 personal information form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   *  Form input handler functions
   **********************************************************/
  const handleDocumentChange = (document: I9Document) => {
    const selectedDoc = filteredI9Documents.find((doc) => isEqual(doc, document));
    if (!selectedDoc) return;

    if (selectedDoc.category === "list_a") {
      setDocuments({
        listA: selectedDoc.name,
        listB: undefined,
        listC: undefined,
      });
    } else if (selectedDoc.category === "list_b") {
      setDocuments({
        ...documents,
        listB: selectedDoc.name,
        listA: undefined,
      });
    } else if (selectedDoc.category === "list_c") {
      setDocuments({
        ...documents,
        listC: selectedDoc.name,
        listA: undefined,
      });
    }
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderDocument = (document: I9Document, list: "list_a" | "list_b" | "list_c") => {
    const selected =
      (list === "list_a" && documents.listA === document.name) ||
      (list === "list_b" && documents.listB === document.name) ||
      (list === "list_c" && documents.listC === document.name);

    return (
      <div
        className={styles["i-9-document"] + " " + (selected ? styles["selected"] : "")}
        key={document.name}
        onClick={() => handleDocumentChange(document)}
      >
        <p className={styles["i-9-document-description"]}>{document.description}</p>
      </div>
    );
  };

  const renderDocuments = () => {
    return (
      <>
        <div className={styles["i-9-documents"]}>
          {renderListA()}
          <div className={styles["i-9-list-b-c"]}>
            {renderListB()}
            {renderListC()}
          </div>
        </div>
      </>
    );
  };

  const renderListA = () => {
    return (
      <div className={styles["i-9-list-a"]}>
        <h3 className={styles["i-9-list-title"]}>List A</h3>
        <p className={styles["i-9-list-description"]}>Identity + employment authorization docs</p>
        <div className={styles["i-9-list"]}>
          {filteredI9Documents
            .filter((doc) => doc.category === "list_a")
            .map((doc) => renderDocument(doc, "list_a"))}
        </div>
      </div>
    );
  };

  const renderListB = () => {
    return (
      <div className={styles["i-9-list-b"]}>
        <h3 className={styles["i-9-list-title"]}>List B</h3>
        <p className={styles["i-9-list-description"]}>Identity documents</p>
        <div className={styles["i-9-list"]}>
          {filteredI9Documents
            .filter((doc) => doc.category === "list_b")
            .map((doc) => renderDocument(doc, "list_b"))}
        </div>
      </div>
    );
  };

  const renderListC = () => {
    return (
      <div className={styles["i-9-list-c"]}>
        <h3 className={styles["i-9-list-title"]}>List C</h3>
        <p className={styles["i-9-list-description"]}>Employment authorization documents</p>
        <div className={styles["i-9-list"]}>
          {filteredI9Documents
            .filter((doc) => doc.category === "list_c")
            .map((doc) => renderDocument(doc, "list_c"))}
        </div>
      </div>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["documents-form"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Select the documents you would like to use</h2>
          <p className={styles["subheader-description"]}>
            Your employer needs these documents to verify that you are eligible to work in the US. Select one
            document in List A or select a document in List B + a document in List C.
            <br />
            <br />
          </p>
        </div>
        {renderDocuments()}
      </div>
    </WizardScreen>
  );
};

export default SelectDocumentsForm;
