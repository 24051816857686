import {
  AggregatedMiterEarning,
  AggregatedTeamMember,
  CheckBenefit,
  CheckItemBenefit,
  Company,
  TeamMember,
} from "dashboard/miter";
import React, { useCallback, useContext, useMemo } from "react";
import {
  AutoCalculatedMiterEarningType,
  AutoCalculatedMiterReimbursementType,
  EnhancedMiterPayment,
} from "../payrollTypes";
import { useActiveCompany, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { ALMENDARIZ_COMPANY_ID } from "dashboard/utils";
import { CheckEarningType } from "backend/utils/check/check-types";
import { TimeType } from "backend/utils/time-type-utils";
import AppContext from "dashboard/contexts/app-context";
import { buildAgGridRow } from "miter-utils";

export const MAX_PAYROLL_PAYMENTS = 500;

export type AggEarningEntry = {
  _id: string;
  tm_name: string;
  tm_pay_type: "hourly" | "salary";
  tm_friendly_id: string;
  job_name?: string;
  date: string;
  reg_hours: number;
  ot_hours: number;
  dot_hours: number;
  total_hours: number;
  earnings: number;
  activity_id?: string;
  miter_type?: string;
  activity_name?: string;
  job_id?: string;
  department_name?: string;
};

export const useReduceAggEarnings = (): ((earnings: AggregatedMiterEarning[]) => AggEarningEntry[]) => {
  const lookupTeam = useLookupTeam();
  const { customFields } = useContext(AppContext);

  const teamMemberCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "team_member"),
    [customFields]
  );

  return useCallback(
    (earnings: AggregatedMiterEarning[]) => {
      const entries: AggEarningEntry[] = [
        ...earnings
          .reduce((r, earning) => {
            const dateString = earning.date || "no_date";

            const key =
              earning.team_member._id +
              "-" +
              dateString +
              "-" +
              earning.check_type +
              earning.job?._id +
              earning.activity?._id +
              earning.miter_type;

            const teamMember = lookupTeam(earning.team_member._id);
            if (!teamMember) return r;

            const item = r.get(key) || {
              _id: key,
              tm_id: earning.team_member._id.toString(),
              department_name: earning.department?.name,
              tm_name: earning.team_member.full_name,
              tm_pay_type: earning.team_member.pay_type,
              tm_friendly_id: earning.team_member.friendly_id,
              date: earning.date || "N/A",
              hours_type: earning.earning_label,
              total_hours: 0,
              earnings: 0,
              department: earning.department,
              activity_id: earning.activity?._id,
              job_id: earning.job?._id,
              job_hierarchy_ids: earning.job_hierarchy_ids,
              miter_type: earning.miter_type,
              [dateString]: 0,
              ...buildAgGridRow(teamMember.custom_field_values, teamMemberCustomFields),
            };

            item.earnings += Number(earning.amount);
            item.total_hours += Number(earning.hours);
            item[dateString] += Number(earning.hours);

            return r.set(key, item);
          }, new Map())
          .values(),
      ];

      return entries;
    },
    [lookupTeam, teamMemberCustomFields]
  );
};

export const checkBenPtdTaxAmountCellRenderer = (
  id: string,
  amount: string,
  payment: EnhancedMiterPayment | undefined
): JSX.Element => {
  const warning = payment?.check_item?.warnings?.find((w) => w.deduction === id);

  return (
    <div className="flex">
      <span>{amount}</span>
      {warning && <div className="orange-muted dot" style={{ marginLeft: 8 }}></div>}
    </div>
  );
};

export const checkBenPtdTaxAmountTooltip = (
  id: string,
  amount: string,
  payment: EnhancedMiterPayment | undefined
): string | undefined => {
  const warning = payment?.check_item?.warnings?.find((w) => w.deduction === id);
  if (!warning) return;

  const isBenefit = id.slice(0, 3) === "ben";

  return `This ${isBenefit ? "employee contribution" : "deduction"} was supposed to be $${
    warning.expected_deduction_amount
  }, but it could not be${
    warning.code === "partially_applied" ? " fully " : " "
  }applied because it would have ${
    warning.reason === "negative_net_pay"
      ? "caused net pay to be negative"
      : "exceeded the deduction's max percentage setting"
  }`;
};

export const earningTypeLookup = {
  regular: "Regular",
  hourly: "Hourly regular",
  salaried: "Salary",
  overtime: "Overtime",
  double_overtime: "Double overtime",
  paid_holiday: "Paid holiday",
  pto: "Vacation",
  sick: "Sick",
  non_hourly_regular: "Non-hourly regular",
  bonus: "Bonus",
  commission: "Commission",
  severance: "Severance",
  allowance: "Allowance",
  reported_allowance: "Reported (imputed) allowance",
  group_term_life: "Group term life",
  other_imputed: "Imputed income",
  "2_percent_shareholder_benefits": "2% shareholder benefits",
  "2_percent_shareholder_hsa": "2% shareholder HSA",
  taxable_short_term_disability: "Taxable short-term disability",
  non_taxable_short_term_disability: "Non-taxable short-term disability",
  long_term_disability: "Long-term disability",
};

export const useGetEarningTypeOptions = (): ((
  tm?: TeamMember | AggregatedTeamMember
) => Option<string>[]) => {
  const activeCompany = useActiveCompany();

  return useMemo(() => {
    const businessType = activeCompany?.check_company.business_type;
    const isSCorpOrLLC = businessType === "s_corporation" || businessType === "llc";

    const func = (tm?: TeamMember | AggregatedTeamMember) => {
      const isContractor = tm?.employment_type === "contractor";
      return Object.entries(earningTypeLookup)
        .filter(([check_type]) => {
          if (check_type === "regular" || check_type.endsWith("term_disability")) return false;
          if ((!isSCorpOrLLC || isContractor) && check_type.startsWith("2_percent_shareholder")) return false;
          if ((!activeCompany?.is_non_profit || isContractor) && check_type.endsWith("allowance")) {
            return false;
          }
          if (isContractor) {
            if (!activeCompany?.settings.payroll.ot_applies_to_1099s && check_type.includes("overtime")) {
              return false;
            }
            if (["severance", "group_term_life", "other_imputed"].includes(check_type)) return false;
          }
          return true;
        })
        .map(([value, label]) => ({ value, label }));
    };
    return func;
  }, [activeCompany]);
};

export const useBaseEarningTypeOptions = (): Option<string>[] => {
  const getEarningTypeOptions = useGetEarningTypeOptions();
  return useMemo(getEarningTypeOptions, [getEarningTypeOptions]);
};

export const miterEarningTypeLookup: Record<
  AutoCalculatedMiterEarningType | AutoCalculatedMiterReimbursementType | "all" | "none",
  string
> = {
  all: "Disable all categories",
  none: "Enable all categories",
  hourly: "Regular timesheets",
  overtime: "Overtime",
  salary: "Salary",
  holiday: "Holiday",
  allowance: "Allowance",
  time_off: "Time off (non-holiday)",
  taxable_union_fringe: "Fringe imputed income",
  benefit: "Group term life imputed income",
  missed_lunch: "Missed lunch",
  missed_break: "Missed break",
  piece_rate: "Piece rate",
  per_diem: "Per diem",
  expense_reimbursement: "Expense reimbursement",
};

export const usePayrollAutoCalculateCategoryOptions = (company: Company | null): Option<string>[] => {
  return Object.entries(miterEarningTypeLookup)
    .filter(([value, _]) => company?._id === ALMENDARIZ_COMPANY_ID || value !== "missed_lunch")
    .map(([value, label]) => ({ value, label }));
};

export const cleanPayScheduleLabel = (payScheduleLabel: string): string => {
  const lower = payScheduleLabel.toLowerCase();
  let offset: number | undefined;
  if (lower.endsWith("pay schedule") || lower.endsWith("pay-schedule")) {
    offset = -12;
  } else if (lower.endsWith("payschedule")) {
    offset = -11;
  } else if (lower.endsWith("schedule") || lower.endsWith("pay roll")) {
    offset = -8;
  } else if (lower.endsWith("payroll")) {
    offset = -7;
  }
  return payScheduleLabel.slice(0, offset).trim();
};

const CHECK_POSTTAX_BENEFIT_TYPES = new Set(["roth_401k", "roth_403b", "roth_457"]);
export const checkBenefitIsPreTax = (cb: CheckBenefit | CheckItemBenefit): boolean => {
  return !CHECK_POSTTAX_BENEFIT_TYPES.has(cb.benefit);
};

const CHECK_RETIREMENT_BENEFIT_TYPES = new Set([
  "401k",
  "403b",
  "457",
  "roth_401k",
  "roth_403b",
  "roth_457",
  "simple_ira",
]);
export const checkBenefitIsRetirement = (cb: CheckBenefit | CheckItemBenefit): boolean => {
  return CHECK_RETIREMENT_BENEFIT_TYPES.has(cb.benefit);
};

export const checkTypeToTimeType = (checkType: CheckEarningType): TimeType => {
  if (checkType === "overtime") return "ot";
  if (checkType === "double_overtime") return "dot";
  return "reg";
};

export const getOtMultiplierTooltip = (t: "ot" | "dot", s: "fringes" | "rate_diffs"): string =>
  `When ${t === "ot" ? "OT" : "DOT"} is worked, Miter will multiply ${
    s === "fringes" ? "eligible contributions" : "the rate differential impact"
  } by this multiplier. The value here should typically be 1 or ${t === "ot" ? "1.5" : "2"} .`;
