import React from "react";
import { Helmet } from "react-helmet";
import { JobPostingsTable } from "dashboard/components/ats/JobPostingsTable";
import { useActiveCompany, useUnreadRecruitingConversationsCount } from "dashboard/hooks/atom-hooks";
import { Button, Toggler, Badge } from "ui";
import { useNavigate, useParams } from "react-router-dom";
import { CandidatesTable } from "dashboard/components/ats/CandidatesTable";
import { RecruitingChatContainer } from "../../components/chat/recruiting/RecruitingChatContainer";
import { useHasAccessToRecruitingChat } from "dashboard/gating";

type Params = {
  view: string;
};

export const Recruiting: React.FC = () => {
  const { view } = useParams<Params>();
  const activeCompany = useActiveCompany();
  const unreadConversationCount = useUnreadRecruitingConversationsCount();
  const navigate = useNavigate();
  const hasChatAccess = useHasAccessToRecruitingChat();

  const renderChatLabel = () => {
    if (unreadConversationCount) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Chat</span>
          <Badge
            style={{
              marginBottom: 1,
              marginLeft: 0,
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 0,
              paddingBottom: 0,
              height: 20,
              width: 20,
              borderRadius: 10,
              fontSize: 10,
            }}
            text={unreadConversationCount + " "}
            className={"unread-count "}
          />
        </div>
      );
    }
    return "Chat";
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Recruiting | Miter</title>
      </Helmet>
      <div style={{ alignItems: "flex-end" }} className="page-content-header flex">
        <h1>Recruiting</h1>
        <div className="flex-1"></div>
        <Button
          onClick={() => window.open(`${process.env.REACT_APP_CAREER_PAGE_URL}/${activeCompany?.slug}`)}
          className="button-2 no-margin"
          style={{ marginRight: 5 }}
        >
          Career page
        </Button>
        <Button onClick={() => navigate(`/recruiting-settings`)} className="button-1 no-margin">
          Settings
        </Button>
      </div>

      <Toggler
        secondary={false}
        active={view}
        config={[
          { path: "job-postings", label: "Job postings" },
          { path: "candidates", label: "Candidates" },
          ...(hasChatAccess ? [{ path: "chat", label: renderChatLabel() }] : []),
        ]}
        toggle={(v) => {
          navigate(`/recruiting/${v}`);
        }}
      />

      {view === "job-postings" && <JobPostingsTable />}
      {view === "candidates" && <CandidatesTable />}
      {view === "chat" && (
        <div style={{ marginTop: 20, height: 700 }}>
          <RecruitingChatContainer />
        </div>
      )}
    </div>
  );
};
