import React, { useEffect, useMemo, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { DateTime } from "luxon";
import xPng from "dashboard/assets/x.png";

import AppContext from "../../../contexts/app-context";
import TimesheetContext from "./timesheetContext";
import Notifier from "dashboard/utils/notifier";
import { AggregatedTimesheet, MiterAPI, TimesheetSection } from "dashboard/miter";
import { ModalSidebar, Badge, ActionMenuRelative, BasicModal } from "ui";
import TimesheetDetails from "./TimesheetDetails";
import TimesheetPayRate from "./TimesheetPayRate";
import TimesheetPictures from "./TimesheetPictures";
import "./timesheetModal.css";
import { TimesheetGeolocation } from "./TimesheetGeolocation";
import { determineGeofenceStatusOfTimesheet } from "dashboard/utils/geolocation";
import TimesheetClockInPhoto from "./TimesheetClockInOutPhoto";
import { TimesheetBenefitContributions } from "./TimesheetBenefitContributions";
import { TimesheetCustomFields } from "./TimesheetCustomFields";
import { getHeaderText, useTimesheetSourceSystem } from "dashboard/utils/timesheetUtils";
import TimesheetBreakTime from "./TimesheetBreakTime";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { TimesheetSignOff } from "./TimesheetSignOff";
import { TimesheetSourceSystemBanner } from "./TimesheetSourceSystemBanner";
import { TimesheetApprovals } from "./TimesheetApprovals";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { TimesheetSections } from "./TimesheetSections";
import { TimesheetAuditLog } from "./TimesheetAuditLog";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type Props = {
  id: string;
  hide: () => void;
  handleChange?: () => void;
  doNotAddPayRates?: boolean;
  readOnly?: boolean;
  setShowSplitModal?: (show: boolean) => void;
  inboxMode?: InboxMode;
  timesheetSections?: TimesheetSection[];
};

// This component is a modal for viewing and updating a timesheet

const TimesheetModal: React.FC<Props> = ({
  id,
  hide,
  handleChange,
  readOnly,
  doNotAddPayRates,
  setShowSplitModal,
  inboxMode,
  timesheetSections,
}) => {
  // Hooks

  const { customFields } = React.useContext(AppContext);
  const activeCompany = useActiveCompany();
  const timesheetAbilities = useTimesheetAbilities({ inboxMode });

  const radiusMiles = activeCompany?.settings.timesheets.geofence_radius;

  // State
  const [timesheet, setTimesheet] = useState<AggregatedTimesheet>();
  const [activeView, setActiveView] = useState("details");
  const [loading, setLoading] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [editingTimesheet, setEditingTimesheet] = useState(false);

  const sourceSystem = useTimesheetSourceSystem(timesheet);

  const getTimesheet = async () => {
    try {
      const miterQueryObject = {
        filter: [
          { field: "_id", value: id },
          { field: "archived", comparisonType: "include_archived" as const },
        ],
        pagination: false,
      };

      const response = await MiterAPI.timesheets.list(miterQueryObject, !doNotAddPayRates);

      if (response.error) {
        throw new Error(response.error);
      } else if (response.data.length === 0) {
        throw new Error(`getTimesheet returned no timesheets for id ${id}`);
      } else if (response.data.length > 1) {
        console.error("getTimesheet returned more than one timesheet:", JSON.stringify(response, null, 2));
      }
      const timesheet = response.data[0];

      // TODO: Eventually, we could cache this information on the timesheet
      // itself, but for now only include when looking from the pay period view.
      if (timesheetSections && timesheet) {
        timesheet.sections = timesheetSections;
      }

      setTimesheet(timesheet);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message || "There was an error retrieving the timesheet. We're looking into it.");
      hide();
    }
  };

  const updateTimesheet = async (payload): Promise<boolean> => {
    try {
      const response = await MiterAPI.timesheets.update_one(id, payload);
      if (response.error) {
        throw new Error(response.error);
      }
      setTimesheet(response);
      handleChange?.();
      Notifier.success("Timesheet successfully updated.");
      return true;
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message || "There was an error updating the timesheet. We're looking into it.");
      return false;
    }
  };

  const geofenceStatus = timesheet ? determineGeofenceStatusOfTimesheet(timesheet, radiusMiles) : undefined;

  const togglerConfig = useMemo(() => {
    const config: TogglerConfigItem[] = [];

    // Hide the contributions tab if the timesheet is not a prevailing wage timesheet OR if the pay rate item was calculated before July 1 2022
    const payRateItemIso =
      (timesheet &&
        timesheet.pay_rate?.timestamp &&
        DateTime.fromSeconds(timesheet?.pay_rate?.timestamp).toISODate()) ||
      "";
    const showContributionsTab =
      timesheet?.pay_rate?.hourly_employer_benefit_contributions && payRateItemIso >= "2022-07-01";

    config.push({ label: "Details", path: "details" });

    if (timesheetAbilities.can("read_sensitive", timesheet)) {
      config.push({ label: "Pay rate", path: "pay_rate" });
    }

    config.push({ label: "Break time", path: "break_time" });

    if (showContributionsTab && timesheetAbilities.can("read_sensitive", timesheet)) {
      config.push({ label: "Contributions", path: "benefit_contributions" });
    }

    if (timesheet?.creation_method !== "kiosk_clock_in") {
      config.push({ label: "Pictures", path: "pictures" });
    }

    config.push({ label: "Geolocation", path: "geolocation", alert: geofenceStatus === "outside_fence" });

    if (timesheet?.clock_in_photo || timesheet?.clock_out_photo) {
      config.push({ label: "Clock in/out photos", path: "clock_in_out_photos" });
    }

    if (customFields.filter((cf) => cf.parent_type === "timesheet").length > 0) {
      config.push({ label: "Custom fields", path: "custom_fields" });
    }

    if (activeCompany?.settings.timesheets.enable_timesheet_policies) {
      config.push({ label: "Approvals", path: "approvals" });
    }

    config.push({ label: "Audit Log", path: "audit-log" });

    if (timesheet?.sign_off) {
      config.push({ label: "Sign-off", path: "sign_off", alert: timesheet.sign_off.status === "missing" });
    }

    if (timesheet?.sections) {
      config.push({ label: "Sections", path: "sections" });
    }

    return config;
  }, [timesheet, customFields, activeCompany, timesheetAbilities.can]);

  const handleToggle = (path) => {
    setActiveView(path);
  };

  const badgeText = timesheet?.archived ? "archived" : timesheet?.status;
  const badgeColorLookup = {
    unapproved: "light-gray",
    approved: "light-blue",
    processing: "light-green",
    paid: "green",
    archived: "red",
  };

  const handleArchive = async () => {
    if (!timesheet?._id) return;

    if (timesheetAbilities.cannot("delete", timesheet)) {
      Notifier.error("You do not have permission to archive this timesheet");
      return;
    }

    setArchiving(true);
    try {
      const response = await MiterAPI.timesheets.archive({ ids: [timesheet._id] });
      if (response.error) throw new Error(response.error);
      Notifier.success("Timesheet successfully archived.");
      handleChange?.();
      hide();
    } catch (e) {
      Notifier.error("There was an error archiving the timesheet. We're looking into it!");
      setArchiveModal(false);
    }
    setArchiving(false);
  };

  const actionMenuLinks = [
    ...(timesheet &&
    timesheet.status === "unapproved" &&
    !readOnly &&
    timesheetAbilities.can("delete", timesheet)
      ? [
          {
            label: "Archive timesheet",
            action: () => setArchiveModal(true),
          },
        ]
      : []),
    ...(timesheet &&
    timesheet.status === "unapproved" &&
    !readOnly &&
    timesheetAbilities.can("update", timesheet) &&
    setShowSplitModal
      ? [
          {
            label: "Split timesheet",
            action: () => setShowSplitModal(true),
          },
        ]
      : []),
    {
      label: "Exit",
      action: hide,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getTimesheet();
      setLoading(false);
    })();
  }, []);

  const handleClickAway = () => {
    if (editingTimesheet) {
      Notifier.error("You must save or cancel your changes before exiting.");
      return;
    }

    hide();
  };

  return (
    <div className="modal-background">
      {timesheet && (
        <TimesheetContext.Provider
          value={{
            timesheet: timesheet,
            getTimesheet: getTimesheet,
            updateTimesheet: updateTimesheet,
            loading: loading,
            changeTimesheetEditStatus: setEditingTimesheet,
            handleChange: handleChange,
            readOnly: readOnly,
            inboxMode,
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="modal-wrapper payment" style={{ width: "70%", maxWidth: 900 }}>
              {archiveModal && (
                <BasicModal
                  headerText="Archive timesheet"
                  button2Text="Archive"
                  button1Text="Cancel"
                  button2Action={handleArchive}
                  loading={archiving}
                  button1Action={() => setArchiveModal(false)}
                  bodyText="Are you sure you want to archive this timesheet? To restore a archived timesheet, you'll have to contact Miter support."
                />
              )}
              <div className="modal-header payment">
                <div>{getHeaderText(timesheet)}</div>
                {badgeText && <Badge text={badgeText} color={badgeColorLookup[badgeText]} />}
                <div className="flex-1"></div>
                <ActionMenuRelative links={actionMenuLinks} />
                <img
                  className="exit-icon"
                  src={xPng}
                  onClick={handleClickAway}
                  style={{ marginLeft: 15, height: 12, width: 12 }}
                />
              </div>
              <TimesheetSourceSystemBanner sourceSystem={sourceSystem} />
              <div className="payment-wrapper" style={{ width: "100%" }}>
                <ModalSidebar
                  config={togglerConfig}
                  wrapperClassName={"timesheet-sidebar-wrapper"}
                  active={activeView}
                  toggle={handleToggle}
                />
                <div className="payment-active-view">
                  <div>
                    {activeView === "details" && <TimesheetDetails />}
                    {activeView === "pay_rate" && <TimesheetPayRate />}
                    {activeView === "break_time" && <TimesheetBreakTime />}
                    {activeView === "pictures" && <TimesheetPictures />}
                    {activeView === "audit-log" && <TimesheetAuditLog />}
                    {activeView === "geolocation" && <TimesheetGeolocation />}
                    {activeView === "clock_in_out_photos" && <TimesheetClockInPhoto />}
                    {activeView === "benefit_contributions" && <TimesheetBenefitContributions />}
                    {activeView === "custom_fields" && <TimesheetCustomFields />}
                    {activeView === "sign_off" && <TimesheetSignOff />}
                    {activeView === "approvals" && <TimesheetApprovals />}
                    {activeView === "sections" && <TimesheetSections />}
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </TimesheetContext.Provider>
      )}
    </div>
  );
};

export default TimesheetModal;
