import React, { useEffect, useState } from "react";
import {
  useTimesheetPolicyOptions,
  useExpensePolicyOptions,
  useReimbursementPolicyOptions,
  useRefetchActionableItems,
  useTeamMemberChangeRequestPolicyOptions,
  useTimeOffRequestPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { ActionModal, Formblock } from "ui";
import { AggregatedTeamMemberWithI9 } from "dashboard/miter";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import { PolicyModal } from "dashboard/components/policies/PolicyModal";
import { PolicyType } from "backend/services/approvals/types";

type Props = {
  data: AggregatedTeamMemberWithI9;
  onHide: () => void;
  onSubmit: (data) => void;
  submitting: boolean;
};

type TeamMemberPolicies = {
  timesheet_policy_id?: Option<string>;
  expense_policy_id?: Option<string>;
  reimbursement_policy_id?: Option<string>;
  time_off_request_policy_id?: Option<string>;
  team_member_change_request_policy_id?: Option<string>;
};

export const TeamMemberPoliciesForm: React.FC<Props> = ({ data, onHide, onSubmit, submitting }) => {
  const { cannot, can } = useMiterAbilities();

  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const expensePolicyOptions = useExpensePolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();
  const timeOffRequestPolicyOptions = useTimeOffRequestPolicyOptions();
  const teamMemberChangeRequestPolicyOptions = useTeamMemberChangeRequestPolicyOptions();

  const refetchActionableItems = useRefetchActionableItems();

  const [creatingPolicy, setCreatingPolicy] = useState<PolicyType>();

  const buildDefaults = () => {
    return {
      timesheet_policy_id: timesheetPolicyOptions.find((o) => o.value === data.timesheet_policy_id),
      expense_policy_id: expensePolicyOptions.find((o) => o.value === data.expense_policy_id),
      reimbursement_policy_id: reimbursementPolicyOptions.find(
        (o) => o.value === data.reimbursement_policy_id
      ),
      time_off_request_policy_id: timeOffRequestPolicyOptions.find(
        (o) => o.value === data.time_off_request_policy_id
      ),
      team_member_change_request_policy_id: teamMemberChangeRequestPolicyOptions.find(
        (o) => o.value === data.team_member_change_request_policy_id
      ),
    };
  };

  const form = useForm<TeamMemberPolicies>({ defaultValues: buildDefaults() });

  useEffect(() => {
    form.reset(buildDefaults());
  }, [data]);

  const handleSave = async (data: TeamMemberPolicies) => {
    // map to values
    const values = {
      timesheet_policy_id: data.timesheet_policy_id?.value || null,
      expense_policy_id: data.expense_policy_id?.value || null,
      reimbursement_policy_id: data.reimbursement_policy_id?.value || null,
      time_off_request_policy_id: data.time_off_request_policy_id?.value || null,
      team_member_change_request_policy_id: data.team_member_change_request_policy_id?.value || null,
    };

    onSubmit(values);

    refetchActionableItems();
  };

  const renderPolicyModal = () => {
    return (
      <ActionModal
        headerText={"Edit approval policies"}
        onHide={onHide}
        showSubmit={can("team:update")}
        submitText={"Save"}
        onSubmit={form.handleSubmit(handleSave)}
        onCancel={onHide}
        showCancel={true}
        loading={submitting}
      >
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Formblock
            type="select"
            name="timesheet_policy_id"
            label="Timesheet policy"
            placeholder="Select a timesheet policy"
            options={timesheetPolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("team:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("timesheet")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="expense_policy_id"
            label="Card transaction policy"
            placeholder="Select a card transaction policy"
            options={expensePolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("team:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("expense")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="reimbursement_policy_id"
            label="Reimbursement policy"
            placeholder="Select a reimbursement policy"
            options={reimbursementPolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("team:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("expense_reimbursement")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="time_off_request_policy_id"
            label="Time off request policy"
            placeholder="Select a time off request policy"
            options={timeOffRequestPolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("team:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("time_off_request")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="team_member_change_request_policy_id"
            label="Team member change request policy"
            placeholder="Select a team member change request policy"
            options={teamMemberChangeRequestPolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("team:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("team_member_change_request")}
            isClearable={true}
          />
        </div>
      </ActionModal>
    );
  };

  return (
    <>
      {renderPolicyModal()}
      {creatingPolicy && <PolicyModal type={creatingPolicy} onHide={() => setCreatingPolicy(undefined)} />}
    </>
  );
};
