import { FC, useContext, useState, useMemo, useCallback, useEffect } from "react";
import {
  MiterAPI,
  AggregatedExpenseReimbursement,
  MiterFilterArray,
  BulkUpdateResult,
} from "dashboard/miter";
import { ConfirmModal, Notifier } from "ui";
import { Helmet } from "react-helmet";
import {
  BulkEditedReimbursement,
  archiveExpenseReimbursementRequests,
  submitExpensesForReimbursement,
  updateExpenseReimbursementsStatuses,
} from "./expenseUtils";
import PayrollContext from "../payrolls/viewPayroll/payrollContext";
import { TableActionLink, TableTogglerConfig, TableV2 } from "ui/table-v2/Table";
import { useQuery } from "miter-utils";
import { ForageRequest, ForageResponse } from "backend/utils/forage/forage-types";
import { ArrowsClockwise, Check, Copy, Plus, Stack, TrashSimple, X, CurrencyDollar } from "phosphor-react";
import { useActiveCompany, useRefetchActionableItems } from "dashboard/hooks/atom-hooks";
import { ExpenseReimbursementImporter } from "dashboard/components/expense-reimbursements/ExpenseReimbursementImporter";
import { ImportHistory } from "dashboard/components/importer/ImportHistory";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useExpenseReimbursementAbilities } from "dashboard/hooks/abilities-hooks/useExpenseReimbursementAbilities";
import FailuresModal from "dashboard/components/shared/FailuresModal";
import { BulkOpFailure, BulkUpdateError } from "backend/utils";
import pluralize from "pluralize";
import { useKickBackItemsAction } from "dashboard/components/approvals/KickBackItemsAction";
import {
  AbstractExpenseReimbursementRequest,
  SingleExpenseReimbursementUpdateResponse,
} from "backend/controllers/expenses/expense-reimbursement-controller";
import CreateExpenseReimbursementModal from "./modals/CreateExpenseReimbursementModal";
import { EditExpenseReimbursementModal } from "./modals/EditExpenseReimbursementModal";
import { useNavigate } from "react-router-dom";
import { BulkCreateExpenseReimbursementPageModal } from "dashboard/components/expense-reimbursements/BulkCreateExpenseReimbursementPageModal";
import { useReimbursementsTableColDefs } from "./expenseReimbursementTableColUtils";
import { InboxMode } from "../approvals/inboxUtils";

export type ExpenseReimbursementEntry = AggregatedExpenseReimbursement;

type Props = {
  expenseReimbursementId?: string;
  showToggler?: boolean;
  viewOnly?: boolean;
  fetchActionableReimbursements?: (
    params: ForageRequest
  ) => Promise<ForageResponse<AggregatedExpenseReimbursement>>;
  customDataFetch?: () => Promise<void>;
  customData?: ExpenseReimbursementEntry[];
  customLoading?: boolean;
  inboxMode?: InboxMode;
  shouldRedirectURLWhenOpening: boolean;
};

export const ExpenseReimbursementsTable: FC<Props> = ({
  expenseReimbursementId,
  showToggler,
  viewOnly,
  fetchActionableReimbursements,
  customDataFetch,
  customData,
  customLoading,
  inboxMode,
  shouldRedirectURLWhenOpening,
}) => {
  const { recalculatePayroll } = useContext(PayrollContext);
  const query = useQuery();
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();
  const refetchActionableItems = useRefetchActionableItems();
  const miterAbilities = useMiterAbilities();
  const reimbursementAbilities = useExpenseReimbursementAbilities({ inboxMode });

  const [selectedRows, setSelectedRows] = useState<ExpenseReimbursementEntry[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showBulkCreateView, setShowBulkCreateView] = useState(false);
  const [selectedExpenseReimbursementId, setSelectedExpenseReimbursementId] = useState<string>();
  const [updateFailures, setUpdateFailures] = useState<(BulkUpdateError | BulkOpFailure)[]>([]);

  const [refreshCount, setRefreshCount] = useState(0);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [bulkSubmitLoading, setBulkSubmitLoading] = useState(false);
  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [approvalLoadingStatus, setApprovalLoadingStatus] = useState<
    "unapproved" | "denied" | "approved" | "processing" | "paid"
  >();
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);

  const columns = useReimbursementsTableColDefs();

  /*********************************************************
   *  replace
   **********************************************************/
  useEffect(() => {
    setSelectedExpenseReimbursementId(expenseReimbursementId);
  }, [expenseReimbursementId]);

  /** Builds the table action link for kicking back items */
  const kickBackItemAction = useKickBackItemsAction(selectedRows, "expense_reimbursement", () => {
    setSelectedRows([]);
    refreshExpenses();
  });

  /*********************************************************
   *  Functions for fetching, updating, and cleaning data
   **********************************************************/
  const status = query.get("status");

  const refreshExpenses = () => {
    if (customData) {
      customDataFetch?.();
    } else {
      setRefreshCount(refreshCount + 1);
    }
    refetchActionableItems();
  };

  const getData = useCallback(
    async (query: ForageRequest) => {
      if (!activeCompany) return { data: [], next_page: null, prev_page: null };
      const filter = (query.filter || []).concat([
        { field: "company_id", value: activeCompany._id },
      ]) as MiterFilterArray;

      if (status && status !== "all") {
        filter.push({ field: "status", value: status });
      }

      const sort = query.sort || [];
      if (!query.sort?.find((s) => s.field === "date")) {
        sort.push({ field: "date", direction: -1 });
      }

      const abilitiesFilter = reimbursementAbilities.filter("read");
      if (abilitiesFilter) filter.push(abilitiesFilter);

      query.select?.push({ field: "team_member", show: true });

      // always fetch original IDs
      query.select?.push({ field: "team_member_id", show: true });
      query.select?.push({ field: "department_id", show: true });
      query.select?.push({ field: "job_id", show: true });
      query.select?.push({ field: "activity_id", show: true });
      query.select?.push({ field: "cost_type_id", show: true });
      query.select?.push({ field: "expense_account_id", show: true });
      query.select?.push({ field: "job_hierarchy_ids", show: true });

      const forageFunction = fetchActionableReimbursements || MiterAPI.expense_reimbursements.forage;
      const res = await forageFunction({ ...query, filter, sort });

      res.data = (res.data ?? []).map((reimbursement) => {
        const readonly = reimbursementAbilities.cannot("update", reimbursement);
        return { ...reimbursement, readonly };
      });

      return res;
    },
    [
      status,
      !!activeCompany,
      fetchActionableReimbursements,
      reimbursementAbilities.filter,
      reimbursementAbilities.cannot,
    ]
  );

  /*********************************************************
   *  Debounce expense reimbursement requests retrieval function
   **********************************************************/
  const yesArchive = async () => {
    await archiveExpenseReimbursementRequests(selectedRows.map((r) => r._id));
    refreshExpenses();
    setShowArchiveConfirmation(false);
    setSelectedRows([]);
  };

  const noArchive = () => {
    setShowArchiveConfirmation(false);
    setSelectedRows([]);
  };

  /*********************************************************
   *  Handler functions for the modal and table
   **********************************************************/
  const handleModalSubmit = (tmId: string) => {
    refreshExpenses(); // refreshes table, also calls refetchActionableItems()
    recalculatePayroll({ tms: [tmId] });
  };

  const handleModalHide = () => {
    setShowCreateModal(false);
    setSelectedExpenseReimbursementId(undefined);
    if (shouldRedirectURLWhenOpening) {
      navigate(`/expenses/reimbursements/${status ? `?status=${status}` : ""}`);
    }
  };

  const handleRowClick = (row) => {
    setSelectedExpenseReimbursementId(row._id);
    let sidebarDetailsUrl = `/expenses/reimbursements/${row._id}`;

    // so opening modal doesn't change table toggle
    if (status) {
      sidebarDetailsUrl += `?status=${status}`;
    }

    if (shouldRedirectURLWhenOpening) {
      navigate(sidebarDetailsUrl);
    }
  };

  const handleSelect = (selections) => {
    setSelectedRows(selections);
  };

  const bulkDuplicateReimbursements = async (selectedIds: string[]) => {
    setIsDuplicateLoading(true);
    try {
      const response = await MiterAPI.expense_reimbursements.duplicate(selectedIds.map((id) => ({ id })));
      if (response.error) {
        console.error(response.error);
      }

      if (response.failures.length) {
        setUpdateFailures(response.failures);
      } else {
        Notifier.success(
          `Successfully duplicated ${selectedIds.length} ${pluralize("reimbursement", selectedIds.length)}.`
        );
        refreshExpenses();
        setSelectedRows([]);
      }
    } catch (err) {
      console.error("Error duplicating reimbursements", err);
      Notifier.error("There was an error duplicating reimbursements. We're looking into it.");
    }
    setIsDuplicateLoading(false);
  };

  const bulkUpdateStatuses = async (
    selectedReimbursements: AggregatedExpenseReimbursement[],
    status: "unapproved" | "denied" | "approved" | "processing" | "paid"
  ) => {
    if (reimbursementAbilities.cannot("approve", selectedRows)) {
      Notifier.error("You do not have permission to approve this expense reimbursement request.");
      return;
    }
    setApprovalLoadingStatus(status);

    await updateExpenseReimbursementsStatuses(
      selectedReimbursements.map((reimbursement) => reimbursement._id),
      status
    );
    refreshExpenses();
    recalculatePayroll({
      tms: [...new Set(selectedReimbursements.map((reimbursement) => reimbursement.team_member._id))],
    });
    setSelectedRows([]);
    setApprovalLoadingStatus(undefined);
  };

  // helper for TableV2 edit mode
  const bulkEditReimbursements = async (
    bulkEditedReimbursements: BulkEditedReimbursement[]
  ): Promise<BulkUpdateResult | undefined> => {
    // if selectedRows is empty, that means this handler is called from inline editing (one row at a time)
    const attemptedEditingRows = selectedRows.length ? selectedRows : bulkEditedReimbursements;

    if (reimbursementAbilities.cannot("update", attemptedEditingRows)) {
      Notifier.error("You do not have permission to update these reimbursements.");
      // return undefined to prevent TableV2 from exiting edit mode
      return;
    }

    try {
      const updateParamsList: AbstractExpenseReimbursementRequest[] = bulkEditedReimbursements.map(
        (reimbursement) => {
          const {
            _id,
            date,
            amount,
            vendor_name,
            team_member_id,
            department_id,
            job_id,
            activity_id,
            expense_account_id,
            cost_type_id,
            memo,
            payout_method,
          } = reimbursement;

          // validate - amount is positive
          if (amount <= 0) throw Error("Amount must be positive.");

          const params: AbstractExpenseReimbursementRequest = {
            id: _id,
            update: {
              date,
              amount,
              vendor_name,
              team_member_id,
              department_id: department_id || null,
              job_id: job_id || null,
              activity_id: activity_id || null,
              cost_type_id: cost_type_id || null,
              expense_account_id: expense_account_id || null,
              memo,
              payout_method,
            },
          };

          return params;
        }
      );

      const res = await MiterAPI.expense_reimbursements.update(updateParamsList);
      if (res.error) throw Error(res.error);
      refreshExpenses();
      return {
        successes: res.updates.map(
          (update: SingleExpenseReimbursementUpdateResponse) => update.expense_reimbursement
        ),
        errors: res.failures.map((failure: BulkOpFailure) => ({
          _id: failure.id,
          message: failure.message,
          fieldErrors: failure.error_fields?.map((fieldError) => {
            return {
              field: fieldError.name,
              message: fieldError.error,
            };
          }),
        })),
      };
    } catch (e: $TSFixMe) {
      const pluralExpense = pluralize("reimbursement", bulkEditedReimbursements.length);
      console.error(`Error updating ${pluralExpense} from dashboard for company ${activeCompany?._id}`, e);
      Notifier.error(`There was a problem updating the ${pluralExpense}. ${e.message}`);
      return;
    }
  };

  const bulkSubmitForReimbursement = async (selectedIds: string[]) => {
    setBulkSubmitLoading(true);
    const { successes } = await submitExpensesForReimbursement(selectedIds);
    setBulkSubmitLoading(false);
    if (successes > 0) {
      refreshExpenses();
      setSelectedRows([]);
    }
  };

  const onExpenseReimbursementImport = () => {
    refreshExpenses();
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  /*********************************************************
    Configs for the table
  **********************************************************/

  const tableActions = useMemo((): {
    dynamicButtons: TableActionLink[];
    staticButtons: TableActionLink[];
  } => {
    if (inboxMode === "approval") {
      return {
        staticButtons: [
          {
            label: "Approve",
            className: "button-2 table-button",
            action: () => {
              Notifier.warning("Please select the reimbursement requests you want to approve.");
            },
            icon: <Check weight="bold" style={{ marginRight: 3 }} />,
            important: true,
          },
          {
            label: "Deny",
            className: "button-1 table-button",
            action: () => {
              Notifier.warning("Please select the reimbursement requests you want to deny.");
            },
            icon: <X weight="bold" style={{ marginRight: 3 }} />,
            important: true,
          },
        ],
        dynamicButtons: [
          ...kickBackItemAction,
          {
            label: "Approve",
            className: "button-2 table-button",
            action: () => bulkUpdateStatuses(selectedRows, "approved"),
            icon: <Check weight="bold" style={{ marginRight: 3 }} />,
            loading: approvalLoadingStatus === "approved",
          },
          {
            label: "Deny",
            className: "button-3 table-button",
            action: () => bulkUpdateStatuses(selectedRows, "denied"),
            icon: <X weight="bold" style={{ marginRight: 3 }} />,
            loading: approvalLoadingStatus === "denied",
          },
        ],
      };
    }

    if (inboxMode === "needs_attention") {
      return {
        staticButtons: [],
        dynamicButtons: [],
      };
    }

    let dynamicButtons: TableActionLink[] = [];
    let staticButtons: TableActionLink[] = [];

    const allUnapproved = selectedRows.every((row) => row.status === "unapproved");
    const allUnapprovable = selectedRows.every((row) => row.status === "approved" || row.status === "denied");

    // every row can be triggered for ACH processing
    const allACHProcessable = selectedRows.every(
      (row) => row.status === "approved" && row.payout_method === "ach"
    );

    // every row can be triggered for manual payout
    const allManualAndApproved = selectedRows.every(
      (row) => row.status === "approved" && row.payout_method === "manual"
    );

    if (allUnapproved) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Delete",
          className: "button-3 table-button",
          action: () => setShowArchiveConfirmation(true),
          icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => reimbursementAbilities.can("delete", selectedRows),
          important: true,
        },
        {
          label: "Duplicate",
          className: "button-1 table-button",
          action: () => bulkDuplicateReimbursements(selectedRows.map((r) => r._id)),
          icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => reimbursementAbilities.can("create", selectedRows),
          important: true,
          loading: isDuplicateLoading,
        },
        ...(reimbursementAbilities.can("approve", selectedRows) ? kickBackItemAction : []),
        {
          label: "Approve",
          className: "button-2 table-button",
          action: () => bulkUpdateStatuses(selectedRows, "approved"),
          icon: <Check weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => reimbursementAbilities.can("approve", selectedRows),
          important: true,
          loading: approvalLoadingStatus === "approved",
        },
        {
          label: "Deny",
          className: "button-3 table-button",
          action: () => bulkUpdateStatuses(selectedRows, "denied"),
          icon: <X weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => reimbursementAbilities.can("approve", selectedRows),
          important: true,
          loading: approvalLoadingStatus === "denied",
        },
      ]);
    }

    if (allUnapprovable) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Unapprove",
          className: "button-1 table-button",
          action: () => bulkUpdateStatuses(selectedRows, "unapproved"),
          icon: <X weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => reimbursementAbilities.can("approve", selectedRows),
          loading: approvalLoadingStatus === "unapproved",
        },
      ]);
    }

    if (allACHProcessable) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Submit for ACH payout",
          className: "button-2 table-button",
          action: () => bulkSubmitForReimbursement(selectedRows.map((r) => r._id)),
          icon: <X weight="bold" style={{ marginRight: 3 }} />,
          loading: bulkSubmitLoading,
          shouldShow: () => reimbursementAbilities.can("update", selectedRows),
        },
      ]);
    }

    if (allManualAndApproved) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Mark as Paid",
          className: "button-2 table-button",
          action: () => bulkUpdateStatuses(selectedRows, "paid"),
          icon: <CurrencyDollar weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => reimbursementAbilities.can("process_payment", selectedRows),
          loading: approvalLoadingStatus === "paid",
        },
      ]);
    }

    staticButtons = staticButtons.concat([
      {
        label: "Refresh",
        className: "button-1 no-margin",
        action: refreshExpenses,
        important: true,
        icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => !inboxMode,
      },
      {
        label: "Create",
        className: "button-2",
        action: () => setShowCreateModal(true),
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        important: true,
        shouldShow: () =>
          miterAbilities.can("reimbursements:others:create") ||
          miterAbilities.can("reimbursements:personal:create"),
      },
      {
        label: "Create many",
        className: "button-1",
        action: () => setShowBulkCreateView(true),
        icon: <Stack weight="bold" style={{ marginRight: 3 }} />,
        important: true,
        shouldShow: () =>
          miterAbilities.can("reimbursements:others:create") ||
          miterAbilities.can("reimbursements:personal:create"),
      },
      {
        key: "import",
        component: <ExpenseReimbursementImporter onFinish={onExpenseReimbursementImport} />,
        shouldShow: () =>
          miterAbilities.can("reimbursements:others:create") ||
          miterAbilities.can("reimbursements:personal:create"),
      },
    ]);

    return { dynamicButtons, staticButtons };
  }, [
    status,
    selectedRows,
    viewOnly,
    miterAbilities.can,
    miterAbilities.cannot,
    reimbursementAbilities,
    bulkSubmitLoading,
    inboxMode,
    kickBackItemAction,
    isDuplicateLoading,
    approvalLoadingStatus,
  ]);

  /*********************************************************
    Toggler configs
  **********************************************************/
  const togglerConfig: TableTogglerConfig<ExpenseReimbursementEntry> = {
    config: [
      { path: "unapproved", label: "Unapproved" },
      { path: "approved", label: "Approved" },
      { path: "processing", label: "Processing" },
      { path: "paid", label: "Paid" },
      { path: "failed", label: "Failed" },
      { path: "denied", label: "Denied" },
      { path: "all", label: "All" },
    ],
    field: "status",
    secondary: true,
  };

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const renderTable = () => {
    const editing =
      !inboxMode &&
      (miterAbilities.can("reimbursements:personal:update") ||
        miterAbilities.can("reimbursements:others:update"));

    return (
      <>
        {/* @ts-expect-error Table issue */}
        <TableV2
          id="reimbursements-table"
          resource="reimbursements"
          columns={columns}
          onSelect={!viewOnly ? handleSelect : undefined}
          staticActions={tableActions.staticButtons}
          dynamicActions={tableActions.dynamicButtons}
          showReportViews={true}
          onClick={handleRowClick}
          onEditMode={setInEditMode}
          defaultSelectedRows={selectedRows}
          ssr={!customData}
          data={customData}
          getData={getData}
          refreshCount={refreshCount}
          toggler={showToggler ? togglerConfig : undefined}
          isLoading={customLoading || bulkSubmitLoading}
          wrapperClassName="base-ssr-table"
          containerClassName={"timesheets-table-container"}
          editable={editing}
          onSave={bulkEditReimbursements}
          rowSelectDisabled={
            inEditMode ? (row) => reimbursementAbilities.cannot("update", row.data) : undefined
          }
          rowClickDisabled={inEditMode ? (data) => reimbursementAbilities.cannot("update", data) : undefined}
        />
      </>
    );
  };

  const renderCreateModal = () => {
    return <CreateExpenseReimbursementModal onHide={handleModalHide} onSubmit={handleModalSubmit} />;
  };

  const renderBulkCreateFullScreenView = () => {
    return (
      <BulkCreateExpenseReimbursementPageModal
        onHide={() => {
          setShowBulkCreateView(false);
          refreshExpenses();
        }}
      />
    );
  };

  const renderArchiveConfirmation = () => {
    const title = `Delete expense reimbursement request${selectedRows.length === 1 ? "" : "s"}`;
    const body =
      selectedRows.length === 1
        ? `Are you sure you want to delete this expense reimbursement request?`
        : `Are you sure you want to delete these ${selectedRows.length} expense reimbursement requests?`;

    return <ConfirmModal title={title} body={body} onYes={yesArchive} onNo={noArchive} />;
  };

  const renderUpdateFailuresModal = () => {
    if (!updateFailures.length) return;

    return (
      <FailuresModal
        headerText={"Error updating Reimbursements"}
        onClose={() => setUpdateFailures([])}
        failures={updateFailures.map((failure) => {
          return {
            // @ts-expect-error convert either type to label
            label: failure._id || failure.id,
            message: failure.message,
          };
        })}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Expense Reimbursement Requests | Miter</title>
      </Helmet>
      {showCreateModal && renderCreateModal()}
      {showBulkCreateView && renderBulkCreateFullScreenView()}
      {selectedExpenseReimbursementId && (
        <EditExpenseReimbursementModal
          expenseReimbursementId={selectedExpenseReimbursementId}
          onHide={handleModalHide}
          onSubmit={handleModalSubmit}
          inboxMode={inboxMode}
        />
      )}

      {showArchiveConfirmation && renderArchiveConfirmation()}
      {renderTable()}
      {showImportHistory && (
        <ImportHistory
          id={"expensereimbursements"}
          resource={"expense reimbursements"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
      {renderUpdateFailuresModal()}
    </>
  );
};
