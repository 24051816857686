import React, { useMemo } from "react";
import Home from "./pages/home/home";
import Jobs from "./pages/jobs/jobs";
import Job from "./pages/jobs/job";
import Expenses from "./pages/expenses/Expenses";
import Scheduling from "./pages/scheduling/Scheduling";
import TeamMember from "./pages/team-members/TeamMember";
import NewTeamMember from "./pages/team-members/newTeamMember";
import Timesheets from "./pages/timesheets/timesheets";
import Payrolls from "./pages/payrolls/payrolls";
import Payroll from "./pages/payrolls/viewPayroll/Payroll";
import Reports from "./pages/reports/reports";
import Benefits from "./pages/benefits/Benefits";
import Settings from "./pages/settings/settings";
import SearchResults from "./components/search/searchResults";
import Chat from "./pages/chat/chat";
import TimeOff from "./pages/time-off/TimeOff";
import Document from "./pages/documents/Document";
import {
  FaHome,
  FaHardHat,
  FaUserFriends,
  FaCog,
  FaComments,
  FaRocket,
  FaCreditCard,
  FaChartBar,
  FaCommentAlt,
  FaMoneyCheckAlt,
  FaInbox,
  FaBook,
} from "react-icons/fa";
import { useReportRoutes, useVisibleReports } from "./pages/reports/reportHooks";
import { CompanyBenefitPage } from "./pages/benefits/CompanyBenefitPage";
import { OnboardingCard } from "./components/onboarding/OnboardingCard";
import Documents from "./pages/documents/Documents";
import { TimesheetSettings } from "./pages/timesheets/TimesheetSettings/TimesheetSettings";
import { AccountingPage } from "./pages/accounting/AccountingPage";
import DailyReports from "./pages/daily-reports/DailyReports";
import DailyReportForm from "./components/daily-reports/DailyReportsForm";
import Assets from "./pages/assets/Assets";
import { AssetSettings } from "./pages/assets/AssetSettings";
import { DailyReportsSettings } from "./components/daily-reports/DailyReportsSettings";
import { Customers } from "./pages/customers/Customers";
import { ActivitiesPage } from "./pages/activities/ActivitiesPage";
import { PayrollSettingsPage } from "./pages/payrolls/PayrollSettingsPage";
import TeamSettingsSelector from "./pages/team-members/teamSettings/TeamSettingsSelector";
import { JobSettings } from "./pages/jobs/JobSettings";
import { ActivitySettings } from "./pages/activities/ActivitySettings";
import { DocumentsSettings } from "./pages/documents/DocumentsSettings";
import { Integrations } from "./pages/integrations/Integrations";
import { IntegrationPage } from "./pages/integrations/IntegrationPage";
import { FeatureRequests } from "./pages/feature-requests/FeatureRequests";
import { Badge } from "ui";
import TimeOffPolicyPage from "./pages/time-off/TimeOffPolicy";
import { TimeOffPolicyForm } from "./pages/time-off/TimeOffPolicyForm";
import { TimeOffSettings } from "./pages/time-off/TimeOffSettings";
import { Forms } from "./pages/forms/Forms";
import { NewForm } from "./pages/forms/NewForm";
import { Form } from "./pages/forms/Form";
import { FormSubmissionView } from "./pages/forms/FormSubmission";
import { SchedulingSettings } from "./pages/scheduling/SchedulingSettings";
import { PerformanceManagement } from "./pages/performance/Performance";
import { PerformanceReviewCycle } from "./pages/performance/PerformanceReviewCycle";
import { PayRateGroupPage } from "./pages/settings/payRateGroups/PayRateGroupPage";
import ExpenseSettingsV2 from "./pages/expenses/ExpenseSettingsV2";

import ReportSettings from "./pages/reports/ReportSettings";
import { PerformanceOnboarding } from "./components/performance/PerformanceOnboarding";
import { usePerformanceLink } from "./hooks/usePerformanceLink";
import { PerformanceQuestionReport } from "./pages/performance/PerformanceQuestionReport";
import { PerformanceSettings } from "./pages/performance/PerformanceSettings";
import { Approvals } from "./pages/approvals/Approvals";
import { Recruiting } from "./pages/ats-real/Recruiting";
import { RecruitingSettings } from "./pages/ats-real/RecruitingSettings";
import {
  useActionableItems,
  useActiveCompany,
  useActiveTeamMember,
  usePolicies,
  useUnreadRecruitingConversationsCount,
} from "./hooks/atom-hooks";
import { JobPosting } from "./pages/ats-real/JobPosting";
import { JobApplication } from "./pages/ats-real/JobApplication";
import { ApprovalSettings } from "./pages/approvals/ApprovalsSettings";
import { useMiterAbilities } from "./hooks/abilities-hooks/useMiterAbilities";
import { DepartmentSettings } from "./components/departments/DepartmentSettings";
import { NeedsAttention } from "./pages/approvals/NeedsAttention";
import CardManagementTable from "./pages/expenses/CardManagementTable";
import CardProgramSettings from "./pages/expenses/CardProgramSettings";
import TeamMembers from "./pages/team-members/TeamMembers";
import { IS_PRODUCTION } from "./utils/environment";
import { Certification } from "./pages/certifications/Certification";
import { OrgChart } from "./pages/team-members/OrgChart";
import CreateChangeRequestReport from "./pages/reports/reportPages/CreateChangeRequestReport";
import { goToMiterGuide } from "./utils/miterGuides";
import { Candidate } from "./pages/ats-real/Candidate";
import { FillableTemplate } from "./components/fillable-templates/FillableTemplate";
import WcGroup from "./pages/settings/workers-comp/WcGroup";
import { WorkersCompSettings } from "./pages/settings/workers-comp/WorkersCompSettings";
import { Location } from "./components/locations/Location";
import { LocationSettings } from "./pages/settings/locations/LocationSettings";
import Equipment from "./pages/equipment/Equipment";
import { useHasAccessToEquipmentTracking } from "./gating";

export type RouteConfig = {
  label: string;
  icon?: React.ReactElement;
  link?: string;
  onClick?: () => void;
  margin?: boolean;
  badge?: React.ReactElement;
  subItems?: RouteConfigSubItem[];
  isActive?: (path?: string) => boolean;
  bottom?: boolean;
  accessible?: boolean;
  notificationCount?: number;
};

export type RouteConfigSubItem = RouteConfig & { link: string };

export const useMenu = (): RouteConfig[] => {
  const performanceLink = usePerformanceLink();
  const policies = usePolicies();
  const visibleReports = useVisibleReports();
  const { can } = useMiterAbilities();
  const activeCompany = useActiveCompany();
  const activeTeamMember = useActiveTeamMember();
  const [actionableItems] = useActionableItems();
  const unreadRecruitingConversationCount = useUnreadRecruitingConversationsCount();

  const hasPolicies = policies.length > 0;

  const hasTimesheetPoliciesEnabled = !!activeCompany?.settings.timesheets.enable_timesheet_policies;
  const hasOnlyTimesheetPolicies = useMemo(
    () => policies.every((policy) => policy.type === "timesheet"),
    [policies]
  );

  const showPolicies = hasPolicies && !(!hasTimesheetPoliciesEnabled && hasOnlyTimesheetPolicies);

  const hasSettingsAccess =
    can("company:settings:manage") ||
    can("company:permission_groups:manage") ||
    can("company:billing:manage") ||
    can("lists:pay_rate_groups:read") ||
    can("lists:departments:read") ||
    can("lists:workers_comp_codes:read") ||
    can("lists:cost_types:read") ||
    can("lists:equipment:read") ||
    can("lists:workplaces:read") ||
    can("lists:locations:read");

  const hasReportsAccess = visibleReports.length > 0;

  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();

  return [
    {
      label: "Home",
      link: "/home",
      icon: <FaHome className={"sidebar-icon"} />,
      accessible: true,
    },
    {
      label: "Inbox",
      icon: <FaInbox className={"sidebar-icon"} />,
      accessible: !!activeTeamMember,
      notificationCount: actionableItems?.total_count || 0,
      isActive: (path) => {
        return !!path?.includes("inbox");
      },
      subItems: [
        {
          label: "Approvals",
          link: "/inbox/approvals",
          accessible: showPolicies && !!activeTeamMember,
          notificationCount: actionableItems?.total_approvable_count,
        },
        {
          label: "Needs Attention",
          link: "/inbox/needs-attention",
          accessible: !!activeTeamMember,
          notificationCount: actionableItems?.total_requires_action_count,
        },
      ],
    },
    {
      label: "Onboarding",
      link: "/onboarding",
      icon: <FaRocket className="sidebar-icon" />,
      accessible: can("company:settings:manage"),
    },
    {
      label: "Work",
      icon: <FaHardHat className={"sidebar-icon"} />,
      subItems: [
        {
          label: "Jobs",
          link: "/jobs",
          accessible: can("jobs:read"),
        },
        {
          label: "Activities",
          link: "/activities",
          accessible: can("lists:activities:read"),
        },
        {
          label: "Timesheets",
          link: "/timesheets",
          isActive: (path?: string): boolean => {
            return !!path?.includes("/timesheet-settings");
          },
          accessible: can("timesheets:personal:read") || can("timesheets:others:read"),
        },
        {
          label: "Equipment",
          link: "/equipment",
          accessible:
            (can("lists:equipment:read") ||
              can("equipment:inventory:read") ||
              can("equipment:timesheets:read")) &&
            hasAccessToEquipmentTracking,
        },
        {
          label: "Schedule",
          link: "/scheduling",
          accessible: can("assignments:read"),
        },
        {
          label: "Daily Reports",
          link: "/daily-reports",
          accessible: can("daily_reports:personal:read") || can("daily_reports:others:read"),
        },
      ],
    },
    {
      label: "HR",
      icon: <FaUserFriends className={"sidebar-icon"} />,
      subItems: [
        {
          label: "Team",
          link: "/team-members",
          accessible: can("team:read") || can("team:read_sensitive"),
          isActive: (path?: string): boolean => !!path?.includes("/team-members"),
        },
        {
          label: "Benefits",
          link: "/benefits",
          accessible: can("benefits:read") || can("allowances:read") || can("post_tax_deductions:read"),
        },
        {
          label: "Time Off",
          link: "/time-off",
          isActive: (path?: string): boolean => {
            return !!path?.includes("/time-off") && !path?.includes("/team-members");
          },
          accessible:
            can("time_off:policies:read") ||
            can("time_off:requests:personal:read") ||
            can("time_off:requests:others:read") ||
            can("time_off:holidays:read"),
        },

        {
          label: "Recruiting",
          link: "/recruiting/job-postings",
          isActive: (path?: string): boolean => {
            return !!path?.includes("/job-postings");
          },
          badge: IS_PRODUCTION ? <Badge text="Beta" color="green" /> : undefined,
          accessible: can("recruiting:job_postings:read") || can("recruiting:job_applications:read"),
          notificationCount: unreadRecruitingConversationCount,
        },
        {
          label: "Performance",
          link: performanceLink,
          isActive: (path?: string): boolean => {
            return !!path?.includes("/performance");
          },
          accessible:
            can("performance:reviews:others:read") ||
            can("performance:reviews:personal:read") ||
            can("performance:schedules:read"),
        },
      ],
    },
    {
      label: "Payrolls",
      link: "/payrolls",

      icon: <FaMoneyCheckAlt className={"sidebar-icon"} />,
      accessible: can("payrolls:read"),
    },
    {
      label: "Expenses",
      link: "/expenses",
      icon: <FaCreditCard className={"sidebar-icon"} />,
      accessible:
        can("card_transactions:personal:read") ||
        can("card_transactions:others:read") ||
        can("reimbursements:personal:read") ||
        can("reimbursements:others:read") ||
        can("miter_cards:read") ||
        can("third_party_cards:read") ||
        can("bill_pay:bills:read"),
    },
    {
      label: "Reports",
      link: "/reports",
      icon: <FaChartBar className={"sidebar-icon"} />,
      accessible: hasReportsAccess,
    },
    {
      label: "Chat",
      link: "/chat",
      icon: <FaComments className={"sidebar-icon"} />,
      accessible: can("chat:read"),
    },
    {
      label: "Company",
      icon: <FaCog className={"sidebar-icon"} />,
      subItems: [
        {
          label: "Settings",
          link: "/settings",
          accessible: hasSettingsAccess,
        },
        {
          label: "Integrations",
          link: "/integrations",
          accessible: can("integrations:manage"),
        },
        {
          label: "Accounting",
          link: "/accounting",
          accessible:
            can("accounting:ledger_accounts:read") ||
            can("accounting:ledger_entries:read") ||
            can("accounting:settings"),
        },
        {
          label: "Documents",
          link: "/documents",

          isActive: (path?: string): boolean => {
            return !!path?.includes("/documents") || !!path?.includes("/fillable-templates");
          },
          accessible:
            can("documents:company:read") ||
            can("documents:company:read_sensitive") ||
            can("documents:team_member:read") ||
            can("documents:team_member:read_sensitive"),
        },

        {
          label: "Forms",
          link: "/forms",
          accessible:
            can("forms:forms:manage") ||
            can("forms:submissions:personal:create") ||
            can("forms:submissions:others:create"),
        },
      ],
    },
    {
      label: "Miter Guides",
      icon: <FaBook className={"sidebar-icon"} />,
      bottom: true,
      badge: <Badge text="New" color="green" />,
      accessible: true,
      onClick: () => goToMiterGuide(null),
    },
    {
      label: "Feature Requests",
      icon: <FaCommentAlt className={"sidebar-icon"} />,
      link: "/forums",
      bottom: true,
      badge: <Badge text="New" color="green" />,
      accessible: process.env.REACT_APP_ENVIRONMENT === "production" && can("company:forums:manage"),
    },
  ];
};

export type RouteItem = {
  component: React.FC;
  path: string;
  accessible: () => boolean;
};

export const useRoutes = (): RouteItem[] => {
  const { can } = useMiterAbilities();
  const reportRoutes = useReportRoutes();
  const hasReportsAccess = useVisibleReports().length > 0;
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();

  const routes = [
    {
      component: Home,
      path: "/home",
      accessible: () => true,
    },
    ...(process.env.REACT_APP_ENVIRONMENT === "production"
      ? [
          {
            component: FeatureRequests,
            path: "/forums",
            accessible: () => can("company:forums:manage"),
          },
          {
            component: FeatureRequests,
            path: "/forums/:p/:post?",
            accessible: () => can("company:forums:manage"),
          },
        ]
      : []),
    {
      component: OnboardingCard,
      path: "/onboarding",
      accessible: () => can("company:settings:manage"),
    },
    {
      component: ApprovalSettings,
      path: "/action-center/approvals/settings",
      accessible: () => true,
    },
    {
      component: Approvals,
      path: "/action-center/approvals/:view?",
      accessible: () => true,
    },
    {
      component: NeedsAttention,
      path: "/action-center/needs-attention/:view?",
      accessible: () => true,
    },
    {
      component: ApprovalSettings,
      path: "/inbox/approvals/settings",
      accessible: () => true,
    },
    {
      component: Approvals,
      path: "/inbox/approvals/:view?",
      accessible: () => true,
    },
    {
      component: NeedsAttention,
      path: "/inbox/needs-attention/:view?",
      accessible: () => true,
    },
    {
      component: Jobs,
      path: "/jobs",
      accessible: () => can("jobs:read"),
    },
    {
      component: JobSettings,
      path: "/jobs/settings",

      accessible: () => can("jobs:settings"),
    },
    {
      component: Job,
      path: "/jobs/:id/:view?/:filter?",
      accessible: () => can("jobs:read"),
    },
    {
      component: TeamMember,
      path: "/team",
      accessible: () => can("team:read") || can("team:read_sensitive"),
    },
    {
      component: OrgChart,
      path: "/team/org-chart",
      accessible: () => can("team:read") || can("team:read_sensitive"),
    },
    {
      component: NewTeamMember,
      path: "/team-members/new",
      accessible: () => can("team:create"),
    },

    /* Soon to be deprecated routes */
    {
      component: TeamMembers,
      path: "/team-members",
      accessible: () => can("team:read") || can("team:read_sensitive"),
    },
    {
      component: TeamSettingsSelector,
      path: "/team-members/settings/:view?",
      accessible: () => can("team:settings"),
    },
    {
      component: TeamMember,
      path: "/team-members/:id/:view?",
      accessible: () => {
        return can("team:read") || can("team:read_sensitive");
      },
    },
    {
      component: PayrollSettingsPage,
      path: "/payrolls/settings",
      accessible: () => can("payrolls:settings"),
    },
    {
      component: TeamMember,
      path: "/team-members/:id/:view?/:filter",
      accessible: () => {
        return can("team:read") || can("team:read_sensitive");
      },
    },

    {
      component: Timesheets,
      path: "/timesheets",
      accessible: () => can("timesheets:personal:read") || can("timesheets:others:read"),
    },
    {
      component: Equipment,
      path: "/equipment/:view?/:id?",
      accessible: () =>
        (can("lists:equipment:read") ||
          can("equipment:inventory:read") ||
          can("equipment:timesheets:read")) &&
        hasAccessToEquipmentTracking,
    },
    {
      component: Payrolls,
      path: "/payrolls",
      accessible: () => can("payrolls:read"),
    },
    {
      component: Payroll,
      path: "/payrolls/:id/:view?",
      accessible: () => can("payrolls:read"),
    },
    {
      component: SchedulingSettings,
      path: "/scheduling/settings",
      accessible: () => can("assignments:settings"),
    },
    {
      component: Scheduling,
      path: "/scheduling/:view?",
      accessible: () => can("assignments:read"),
    },
    {
      component: Customers,
      path: "/customers",
      accessible: () => can("lists:customers:read"),
    },
    {
      component: ActivitiesPage,
      path: "/activities",
      accessible: () => can("lists:activities:read"),
    },
    {
      component: ActivitySettings,
      path: "/activities/settings",
      accessible: () => can("lists:activities:manage"),
    },
    {
      component: TimeOff,
      path: "/time-off",
      accessible: () =>
        can("time_off:policies:read") ||
        can("time_off:requests:personal:read") ||
        can("time_off:requests:others:read") ||
        can("time_off:holidays:read"),
    },

    {
      component: TimeOffSettings,
      path: "/time-off/settings",
      accessible: () => can("time_off:settings"),
    },

    {
      component: TimeOffPolicyForm,
      path: "/time-off/policies/new",
      accessible: () => can("time_off:policies:create"),
    },
    {
      component: TimeOffPolicyPage,
      path: "/time-off/policies/:id/:view?",
      accessible: () => can("time_off:policies:read"),
    },
    {
      component: TimeOff,
      path: "/time-off/:view",
      accessible: () =>
        can("time_off:policies:read") ||
        can("time_off:requests:personal:read") ||
        can("time_off:requests:others:read") ||
        can("time_off:holidays:read"),
    },
    {
      component: TimesheetSettings,
      path: "/timesheet-settings",
      accessible: () => can("timesheets:settings"),
    },
    {
      component: Benefits,
      path: "/benefits/:view?",
      accessible: () => can("benefits:read") || can("allowances:read") || can("post_tax_deductions:read"),
    },
    {
      component: Benefits,
      path: "/benefits/:view?/:benefit_id",
      accessible: () => can("benefits:read"),
    },
    {
      component: CompanyBenefitPage,
      path: "/benefits/company-plan/:id",
      accessible: () => can("benefits:read"),
    },
    {
      component: Expenses,
      path: "/expenses/:view?/:id?",
      accessible: () =>
        can("card_transactions:personal:read") ||
        can("card_transactions:others:read") ||
        can("reimbursements:personal:read") ||
        can("reimbursements:others:read") ||
        can("miter_cards:read") ||
        can("third_party_cards:read") ||
        can("bill_pay:bills:read"),
    },
    {
      component: CardManagementTable,
      path: "/expenses/card-programs/:id",
      accessible: () => can("miter_cards:read") || can("third_party_cards:read"),
    },
    {
      component: CardProgramSettings,
      path: "/expenses/card-programs/:id/settings",
      accessible: () => can("miter_cards:update") || can("third_party_cards:update"),
    },
    {
      component: Reports,
      path: "/reports",
      accessible: () => hasReportsAccess,
    },
    {
      component: CreateChangeRequestReport,
      path: "/reports/change-requests/:id?",
      accessible: () => hasReportsAccess && can("team:read") && can("team:read_sensitive"),
    },
    {
      component: ExpenseSettingsV2,
      path: "/expenses/settings/:view/:id?",
      accessible: () => can("expenses:settings"),
    },
    {
      component: Chat,
      path: "/chat/:id?",
      accessible: () => can("chat:read"),
    },
    {
      component: Documents,
      path: "/documents",
      accessible: () => can("documents:company:read") || can("documents:company:read_sensitive"),
    },
    {
      component: DocumentsSettings,
      path: "/documents/settings",
      accessible: () => can("documents:settings"),
    },
    {
      component: Document,
      path: "/documents/:id/:view?",
      accessible: () => {
        return (
          can("documents:company:read") ||
          can("documents:company:read_sensitive") ||
          can("documents:team_member:read") ||
          can("documents:team_member:read_sensitive")
        );
      },
    },
    {
      component: FillableTemplate,
      path: "/fillable-templates/:id/:view?",
      accessible: () => {
        return (
          can("documents:company:read") ||
          can("documents:company:read_sensitive") ||
          can("documents:team_member:read") ||
          can("documents:team_member:read_sensitive")
        );
      },
    },
    {
      component: PayRateGroupPage,
      path: "/settings/pay-rate-groups/:id",
      accessible: () => can("lists:pay_rate_groups:read"),
    },
    {
      component: WcGroup,
      path: "/settings/workers-comp/groups/:id",
      accessible: () => can("lists:workers_comp_codes:read"),
    },
    {
      component: Settings,
      path: "/settings/:view?/:id?",
      accessible: () =>
        can("company:settings:manage") ||
        can("company:permission_groups:manage") ||
        can("company:billing:manage") ||
        can("lists:equipment:read") ||
        can("lists:cost_types:read") ||
        can("lists:departments:read") ||
        can("lists:workers_comp_codes:read") ||
        can("lists:pay_rate_groups:read") ||
        can("lists:workplaces:read") ||
        can("lists:locations:read"),
    },

    {
      component: SearchResults,
      path: "/search",
      accessible: () => true,
    },
    {
      component: Integrations,
      path: "/integrations",
      accessible: () => can("integrations:manage"),
    },
    {
      component: IntegrationPage,
      path: "/integrations/:key",
      accessible: () => can("integrations:manage"),
    },
    {
      component: AccountingPage,
      path: "/accounting",
      accessible: () =>
        can("accounting:ledger_accounts:read") ||
        can("accounting:ledger_entries:read") ||
        can("accounting:settings"),
    },
    {
      component: DailyReports,
      path: "/daily-reports",
      accessible: () => can("daily_reports:personal:read") || can("daily_reports:others:read"),
    },
    {
      component: DailyReportForm,
      path: "/daily-reports/new",
      accessible: () => can("daily_reports:personal:create") || can("daily_reports:others:create"),
    },
    {
      component: DailyReportsSettings,
      path: "/daily-reports/settings",
      accessible: () => can("daily_reports:settings"),
    },
    {
      component: DailyReportForm,
      path: "/daily-reports/:id/edit",
      accessible: () => can("daily_reports:personal:update") || can("daily_reports:others:update"),
    },
    {
      component: Assets,
      path: "/assets/:view?/:id?",
      accessible: () => can("lists:equipment:manage"),
    },
    {
      component: AssetSettings,
      path: "/asset-settings",
      accessible: () => can("lists:equipment:manage"),
    },
    {
      component: Forms,
      path: "/forms",
      accessible: () => can("forms:forms:manage"),
    },
    {
      component: NewForm,
      path: "/forms/new",
      accessible: () => can("forms:forms:manage"),
    },
    {
      component: Form,
      path: "/forms/:id",
      accessible: () => can("forms:forms:manage"),
    },
    {
      component: FormSubmissionView,
      path: "/forms/:id/submissions/new",
      accessible: () =>
        can("forms:forms:manage") &&
        (can("forms:submissions:personal:create") || can("forms:submissions:others:create")),
    },
    {
      component: FormSubmissionView,
      path: "/forms/:id/submissions/:submission_id",
      accessible: () =>
        can("forms:forms:manage") &&
        (can("forms:submissions:personal:read") || can("forms:submissions:others:read")),
    },
    {
      component: Form,
      path: "/forms/:id/:view?",
      accessible: () =>
        can("forms:forms:manage") &&
        (can("forms:submissions:personal:read") || can("forms:submissions:others:read")),
    },

    {
      component: Recruiting,
      path: "/recruiting/:view?/:id?",
      accessible: () => can("recruiting:job_postings:read"),
    },
    {
      component: RecruitingSettings,
      path: "/recruiting-settings",
      accessible: () => can("recruiting:job_postings:read"),
    },

    {
      component: JobPosting,
      path: "/recruiting/job-postings/:id/:view?",
      accessible: () => can("recruiting:job_postings:read"),
    },
    {
      component: Candidate,
      path: "/recruiting/candidates/:id",
      accessible: () => can("recruiting:job_postings:read"),
    },
    {
      component: JobApplication,
      path: "/recruiting/job-applications/:id",
      accessible: () => can("recruiting:job_applications:read"),
    },

    // Performance Management
    {
      component: PerformanceManagement,
      path: "/performance/:view?",
      accessible: () =>
        can("performance:reviews:others:read") ||
        can("performance:reviews:personal:read") ||
        can("performance:schedules:read"),
    },
    {
      component: PerformanceSettings,
      path: "/performance/settings",
      accessible: () => can("performance:settings"),
    },
    {
      component: PerformanceReviewCycle,
      path: "/performance/review-schedules/:id/:view?",
      accessible: () => can("performance:schedules:read"),
    },
    {
      component: PerformanceOnboarding,
      path: "/performance/onboarding",
      accessible: () => can("performance:schedules:read"),
    },
    ...(process.env.REACT_APP_ENVIRONMENT !== "production"
      ? [
          {
            component: PerformanceQuestionReport,
            path: "/performance/review-schedules/:id/report/question/:question_id",
            accessible: () =>
              can("performance:reviews:others:read") || can("performance:reviews:personal:read"),
          },
        ]
      : []),
    ...reportRoutes,
    {
      component: ReportSettings,
      path: "/reports/:report_id/settings",
      accessible: () => can("reports:settings"),
    },
    {
      component: DepartmentSettings,
      path: "/settings/departments/settings",
      accessible: () => can("lists:departments:manage"),
    },
    {
      component: WorkersCompSettings,
      path: "/settings/workers-comp/settings",
      accessible: () => can("lists:workers_comp_codes:manage"),
    },
    // Certifications
    {
      component: Certification,
      path: "/certification-types/:id/:view?",
      accessible: () => can("certifications:certification_types:read"),
    },
    // Locations
    {
      component: Location,
      path: "/settings/locations/:id/:view?/:filter?",
      accessible: () => can("lists:locations:read"),
    },
    {
      component: LocationSettings,
      path: "/settings/locations/settings",
      accessible: () => can("lists:locations:manage"),
    },
  ];

  return routes;
};
