import { MiterAPI, WorkersCompGroupMapping, Activity } from "dashboard/miter";
import { Notifier, createObjectMapToArray } from "dashboard/utils";
import React, { useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ActionModal, Badge, Breadcrumbs, DropdownButton, TableV2 } from "ui";

import {
  useActivities,
  useActivityLabelFormatter,
  useLookupWcCode,
  useLookupWcGroup,
  useRefetchWcGroups,
  useWcCodeOptions,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { CaretDown, Copy, X } from "phosphor-react";
import { WcGroupModal } from "./WcGroupModal";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import ActivitiesTable from "dashboard/components/tables/ActivitiesTable";
import ObjectID from "bson-objectid";
import { deparameterize, notNullish, stateOptions } from "miter-utils";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { capitalize } from "lodash";

const WcGroup: React.FC = () => {
  /*********************************************************
   *  Hooks
   **********************************************************/
  const { can, cannot } = useMiterAbilities();
  const { id } = useParams<{ id: string }>();

  const activities = useActivities();

  const refetchWcGroups = useRefetchWcGroups();
  const lookupWcGroup = useLookupWcGroup();
  const wcGroup = lookupWcGroup(id);

  const lookupWcCode = useLookupWcCode();
  const wcCodeOptions = useWcCodeOptions();
  const activityLabelFormatter = useActivityLabelFormatter();
  const { renderFailuresModal, setFailures } = useFailuresModal();

  const [tableData, setTableData] = useState<WorkersCompGroupMapping[]>(wcGroup?.mappings || []);

  const costCodeOptions = useMemo(() => {
    const foundCostCodesSet = new Set<string>();

    return activities
      .filter((activity) => {
        if (!activity.cost_code) return false;

        if (foundCostCodesSet.has(activity.cost_code)) {
          return false;
        }
        foundCostCodesSet.add(activity.cost_code);
        return true;
      })
      .map((activity) => ({ value: activity.cost_code!, label: activity.cost_code! }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [activities, activityLabelFormatter]);

  const lookupActivityCode = useMemo(
    () => createObjectMapToArray(activities, (activity) => activity.cost_code),
    [activities]
  );

  const existingActivitiesSet = useMemo(
    () =>
      new Set(
        tableData
          .flatMap((mapping) => (mapping.cost_code ? lookupActivityCode[mapping.cost_code] : null))
          .filter(notNullish)
      ),
    [tableData, lookupActivityCode]
  );

  /*********************************************************
   * States
   **********************************************************/
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [selectedRows, setSelectedRows] = useState<WorkersCompGroupMapping[]>([]);
  const [showImportModal, setShowImportModal] = useState(false);

  /*********************************************************
   *  API Functions
   **********************************************************/
  const hasMissingWcCode = (data: WorkersCompGroupMapping[]) => {
    return data.some((mapping) => !mapping.wc_code_id);
  };

  const hasDuplicateMappings = (data: WorkersCompGroupMapping[]) => {
    const keySet = new Set<string>();
    const errors: string[] = [];

    for (const mapping of data) {
      const key = `${mapping.cost_code}-${mapping.state}-${mapping.min_pay_rate}-${mapping.wc_code_id}`;

      if (keySet.has(key)) {
        let message = `Duplicate mapping for: `;

        if (mapping.cost_code) message += `cost code: ${mapping.cost_code}, `;
        if (mapping.state) message += `state: ${mapping.state}, `;
        if (mapping.min_pay_rate) message += `min pay rate: ${mapping.min_pay_rate}, `;
        if (mapping.wc_code_id) message += `workers' comp code: ${lookupWcCode(mapping.wc_code_id)?.label}, `;

        errors.push(message);
      }

      keySet.add(key);
    }

    return errors.map((error) => ({ label: "Validation error", message: error }));
  };

  const cleanupMappings = (data: WorkersCompGroupMapping[]) => {
    return data.map((mapping) => {
      // If this is state only, remove the min pay rate and cost code
      if (wcGroup?.type === "state") {
        delete mapping.min_pay_rate;
        delete mapping.cost_code;
      }

      // If this is pay rate only, remove the cost code and state
      if (wcGroup?.type === "pay_rate") {
        delete mapping.cost_code;
        delete mapping.state;
      }

      // If this is state and pay rate, remove the cost code
      if (wcGroup?.type === "state_and_pay_rate") {
        delete mapping.cost_code;
      }

      // If this is job and cost code, remove the state and min pay rate
      if (wcGroup?.type === "job_and_cost_code") {
        delete mapping.state;
        delete mapping.min_pay_rate;
      }

      return mapping;
    });
  };

  const saveWorkersCompGroup = async (data: WorkersCompGroupMapping[]) => {
    if (!wcGroup) return;

    if (cannot("lists:workers_comp_codes:manage")) {
      Notifier.error("You do not have permission to edit workers' comp groups.");
      return;
    }

    // Validate the data
    const isMissingWcCode = hasMissingWcCode(data);
    if (isMissingWcCode) {
      Notifier.error("All mappings must have a workers' comp code");
      return;
    }

    const duplicateMappings = hasDuplicateMappings(data);
    if (duplicateMappings.length) {
      setFailures(duplicateMappings);
      Notifier.error("There are duplicate mappings that need to be fixed");
      return;
    }

    setSaving(true);
    try {
      const cleanedMappings = cleanupMappings(data);
      const response = await MiterAPI.wc_groups.update(wcGroup?._id, { mappings: cleanedMappings });
      if (response.error) throw Error(response.error);

      await refetchWcGroups();
      Notifier.success("Workers' comp group mappings saved");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setSaving(false);
  };

  const archiveWorkersCompGroup = async () => {
    if (!wcGroup) return;

    if (cannot("lists:workers_comp_codes:manage")) {
      Notifier.error("You do not have permission to delete workers' comp groups.");
      return;
    }

    setArchiving(true);
    try {
      const response = await MiterAPI.wc_groups.delete(wcGroup._id);
      if (response.error) throw Error(response.error);

      await refetchWcGroups();
      Notifier.success("Workers' comp group deleted");
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }

    setArchiving(false);
  };

  /*********************************************************
   *  Handler Functions
   **********************************************************/
  const handleAddCostCodes = (newCostCodes: string[]) => {
    // Deduplicate the cost codes
    const newCostCodesSet = new Set(newCostCodes);

    // Get the existing cost codes
    const existingCostCodesSet = new Set(tableData.map((mapping) => mapping.cost_code));

    // Filter out the cost codes that already exist so we don't add duplicates and any repeated cost codes
    const newMappings = [...newCostCodesSet]
      .filter((costCode) => !existingCostCodesSet.has(costCode))
      .map((costCode) => ({
        _id: ObjectID().toHexString(),
        cost_code: costCode,
        wc_code_id: "",
        edited: true,
      }));

    // Add the new mappings to the table
    setTableData([...tableData, ...newMappings]);
  };

  const handleRemoveRows = (rows: WorkersCompGroupMapping[]) => {
    // Filter out the cost codes that are not in the list
    const newMappings = tableData.filter((mapping) => !rows.includes(mapping));

    // Set the new mappings
    setTableData(newMappings);
  };

  const handleAddRow = useCallback(() => {
    const newMappings = [
      ...tableData,
      {
        _id: ObjectID().toHexString(),
        edited: true,
        wc_code_id: "",
      },
    ];

    setTableData(newMappings);
  }, [tableData]);

  const handleDuplicateRow = useCallback(
    (row: WorkersCompGroupMapping, index: number) => {
      // Add the new row to the table after the current row
      const newMappings = [
        ...tableData.slice(0, index + 1),
        {
          ...row,
          _id: ObjectID().toHexString(),
          edited: true,
        },
        ...tableData.slice(index + 1),
      ];

      setTableData(newMappings);
    },
    [tableData]
  );

  const handleDeleteRow = useCallback(
    (row: WorkersCompGroupMapping) => {
      // Filter out the row that needs to be deleted
      const newMappings = tableData.filter((mapping) => mapping._id !== row._id);
      setTableData(newMappings);
    },
    [tableData]
  );

  const handleRowUpdate = async (updatedRows: WorkersCompGroupMapping[]) => {
    setTableData(updatedRows);
  };

  /*********************************************************
   *  Table helpers
   **********************************************************/
  const staticActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "Import cost codes",
        action: () => setShowImportModal(true),
        shouldShow: () => can("lists:workers_comp_codes:manage") && wcGroup?.type === "job_and_cost_code",
        loading: archiving,
        showInEditMode: true,
        className: "button-1 tall-button",
      },
      {
        label: "+ Add mapping",
        action: () => handleAddRow(),
        shouldShow: () => can("lists:workers_comp_codes:manage"),
        showInEditMode: true,
        important: true,
        className: "button-1 tall-button",
      },
      {
        label: "Save",
        action: () => saveWorkersCompGroup(tableData),
        shouldShow: () => can("lists:workers_comp_codes:manage"),
        showInEditMode: true,
        important: true,
        className: "button-2 tall-button",
        loading: saving,
      },
    ];
  }, [activities, handleAddRow, saving]);

  const dynamicActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "Remove rows",
        action: () => handleRemoveRows(selectedRows),
        shouldShow: () => can("lists:workers_comp_codes:manage"),
        loading: saving,
        showInEditMode: true,
        important: true,
        className: "button-1",
      },
    ];
  }, [tableData, selectedRows]);

  const columns = useMemo(() => {
    const cols: ColumnConfig<WorkersCompGroupMapping>[] = [];

    if (wcGroup?.type === "job_and_cost_code") {
      cols.push({
        headerName: "Activity cost code",
        field: "cost_code",
        editable: true,
        dataType: "string",
        editorType: "select",
        valueFormatter: (params: ValueFormatterParams<WorkersCompGroupMapping>): string => {
          return params.data?.cost_code || "-";
        },
        cellEditorParams: () => ({ options: costCodeOptions, isClearable: true }),
        maxWidth: 250,
      });
    }

    if (wcGroup?.type === "state_and_pay_rate" || wcGroup?.type === "state") {
      cols.push({
        headerName: "State",
        field: "state",
        editable: true,
        dataType: "string",
        editorType: "select",
        valueFormatter: (params: ValueFormatterParams<WorkersCompGroupMapping>): string => {
          return params.data?.state || "-";
        },
        cellEditorParams: () => ({ options: stateOptions, isClearable: true }),
        maxWidth: 250,
      });
    }

    if (wcGroup?.type === "state_and_pay_rate" || wcGroup?.type === "pay_rate") {
      cols.push({
        headerName: "Min pay rate",
        field: "min_pay_rate",
        editable: true,
        dataType: "number",
        editorType: "number",
        maxWidth: 250,
        validations: (value) => {
          if (value != null && value < 0) return "Min pay rate cannot be negative";
          return true;
        },
      });
    }

    cols.push({
      field: "_id",
      headerName: "",
      pinned: "right",
      maxWidth: 80,
      cellRenderer: (params) => {
        if (!params.node.allChildrenCount) {
          return (
            <div className="flex justify-content-center width-100-percent">
              <button
                onClick={() => handleDuplicateRow(params.data, params.node.rowIndex)}
                className="black-link no-margin button-text hover-purple-link"
                style={{ marginRight: 10, fontSize: "1.2rem", opacity: 1 }}
              >
                <Copy style={{ marginTop: 3 }} />
              </button>
              <button
                onClick={() => handleDeleteRow(params.data)}
                className="black-link no-margin button-text hover-purple-link"
                style={{ marginRight: 5, fontSize: "1.2rem", opacity: 1 }}
              >
                <X style={{ marginTop: 3 }} />
              </button>
            </div>
          );
        } else {
          return null;
        }
      },
      suppressMenu: true,
    });

    cols.push({
      headerName: "Workers' comp code",
      field: "wc_code_id",
      editable: true,
      dataType: "string",
      editorType: "select",
      valueFormatter: (params: ValueFormatterParams<WorkersCompGroupMapping>): string => {
        return lookupWcCode(params.data?.wc_code_id)?.label || "";
      },
      filterValueGetter: (params: ValueGetterParams<WorkersCompGroupMapping>): string => {
        return lookupWcCode(params.data?.wc_code_id)?.label || "";
      },
      cellEditorParams: () => ({ options: wcCodeOptions, isClearable: true }),
      minWidth: 250,
      validations: (value) => {
        if (!value) return "Workers' comp code is required";
        return true;
      },
    });

    return cols;
  }, [wcGroup, tableData, costCodeOptions, wcCodeOptions]);

  /*********************************************************
   *  Render Functions
   **********************************************************/
  const renderBreadcrumbs = () => {
    if (!wcGroup) return;

    return (
      <Breadcrumbs
        crumbs={[
          {
            label: "Settings",
            path: "/settings",
          },
          {
            label: "Workers' comp groups",
            path: "/settings/workers-comp/groups",
          },
          {
            label: wcGroup.name,
            path: `/settings/workers-comp/groups/${wcGroup._id}`,
          },
        ]}
      />
    );
  };

  const renderActivityCostCodeImportModal = () => {
    return (
      <ActivityCostCodeImportModal
        onHide={() => setShowImportModal(false)}
        onSubmit={(costCodes) => {
          handleAddCostCodes(costCodes);
          setShowImportModal(false);
        }}
        existingActivitiesSet={existingActivitiesSet}
        mappings={tableData}
      />
    );
  };

  const renderTable = () => {
    return (
      <TableV2
        id={"wc-group-mappings-table"}
        onSave={handleRowUpdate}
        resource="mappings"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        editable={can("lists:workers_comp_codes:manage")}
        alwaysEditable={can("lists:workers_comp_codes:manage")}
        isLoading={saving}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        gridWrapperStyle={{ height: "56vh" }}
        containerStyle={{ paddingTop: 0, paddingBottom: 0 }}
        paginationPageSize={500}
        alwaysShowSecondaryActions={true}
        alwaysShowBulkEdit={true}
        autoSave={true}
        editableControlled={true}
      />
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{wcGroup?.name || "Workers comp group"} | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className={"page-content-header "} style={{ marginTop: 5 }}>
          {renderBreadcrumbs()}
          <div className="flex space-between">
            <h1 className="view-title" style={{ marginTop: 10 }}>
              {wcGroup?.name || ""}
            </h1>
            <DropdownButton
              className={"button-1 tall-button"}
              options={[
                {
                  label: "Edit group",
                  action: () => setEditing(true),
                  shouldShow: () => can("lists:workers_comp_codes:manage"),
                },
                {
                  label: "Delete",
                  action: archiveWorkersCompGroup,
                  shouldShow: () => can("lists:workers_comp_codes:manage"),
                  loading: archiving,
                },
              ]}
              closeOnClick={true}
            >
              Actions
              <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
            </DropdownButton>
          </div>
          <div className={"flex"} style={{ marginTop: 5 }}>
            <Badge
              className="no-margin"
              text={(capitalize(deparameterize(wcGroup?.type || "")) || "Unknown") + " group"}
              color="blue"
            />
            {wcGroup?.default_wc_code_id && (
              <Badge
                text={"Default code: " + lookupWcCode(wcGroup.default_wc_code_id)?.label || ""}
                color="blue"
              />
            )}
          </div>
          <div className="vertical-spacer-large" />
          {renderTable()}
          {renderFailuresModal()}
        </div>
      </div>

      {editing && (
        <WcGroupModal wcGroup={wcGroup} onHide={() => setEditing(false)} onSubmit={() => setEditing(false)} />
      )}
      {showImportModal && renderActivityCostCodeImportModal()}
    </div>
  );
};

type ActivityCostCodeImportModalProps = {
  onHide: () => void;
  onSubmit: (costCodes: string[]) => void;
  mappings: WorkersCompGroupMapping[];
  existingActivitiesSet: Set<Activity>;
};

const ActivityCostCodeImportModal: React.FC<ActivityCostCodeImportModalProps> = ({
  onHide,
  onSubmit,
  existingActivitiesSet,
}) => {
  const [selectedActivities, setSelectedActivities] = useState<Activity[]>([]);

  return (
    <ActionModal
      headerText={"Select cost codes to import"}
      submitText={"Submit"}
      showSubmit={true}
      cancelText={"Cancel"}
      showCancel={true}
      onHide={onHide}
      onSubmit={() => {
        const costCodes = selectedActivities
          .map((activity) => activity.cost_code)
          .filter((a) => a && a?.length);

        onSubmit(costCodes as string[]);
      }}
      onCancel={onHide}
      wrapperClassName={"medium-width-modal-wrapper"}
    >
      <p>Select the cost codes to import into this group.</p>
      <ActivitiesTable
        onSelect={(activities) => setSelectedActivities(activities)}
        hideActions={true}
        hiddenActivitiesSet={existingActivitiesSet}
      />
    </ActionModal>
  );
};

export default WcGroup;
