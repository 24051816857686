import { reportList } from "dashboard/pages/reports/reportList";
import { ReportComponentLookup } from "dashboard/pages/reports/reportHooks";
import { ReportItem, ReportItemProps } from "dashboard/pages/reports/reports";
import React, { useMemo, useState } from "react";
import PayrollContext from "../payrollContext";
import { ALLAN_BRITEWAY_COMPANY_IDS } from "dashboard/utils/constants";

type Props = {
  reportSlugs?: string[];
};

export const NonDraftPayrollReports: React.FC<Props> = ({ reportSlugs }) => {
  const { payroll } = React.useContext(PayrollContext);
  const [activeReport, setActiveReport] = useState<ReportItemProps | undefined>();

  const filteredReportList = useMemo(() => {
    return reportList.filter((rc) => {
      if (rc.slug === "ledger_entry" && payroll?.type === "amendment") {
        return false;
      } else if (rc.slug === "tax_deposits" && payroll?.type === "historical") {
        return false;
      }

      if (rc.slug === "health_cues" && !ALLAN_BRITEWAY_COMPANY_IDS.includes(payroll?.company._id || "")) {
        return false;
      }

      return (
        reportSlugs || [
          "payroll_register",
          "payroll_journal",
          "ledger_entry",
          "deductions_contributions",
          "workers_comp",
          "job_costing",
          "tax_deposits",
          "earnings_summary",
          "401k_report",
          "fringe_report",
          "health_cues",
        ]
      ).includes(rc.slug);
    });
  }, [payroll?.void_of, reportSlugs]);

  const handleReportIconClick = (r: ReportItemProps) => {
    setActiveReport(r);
  };

  const ReportComponent = (activeReport?.slug && ReportComponentLookup[activeReport?.slug]) || null;
  if (ReportComponent) {
    return (
      <>
        <span className="blue-link" onClick={() => setActiveReport(undefined)}>
          Back
        </span>
        <div className="vertical-spacer-small" />
        <ReportComponent />
      </>
    );
  }

  return (
    <div className="reports-list" style={{ marginTop: 0 }}>
      {filteredReportList.map((report, index) => {
        return <ReportItem handleClick={handleReportIconClick} key={report.slug} {...report} index={index} />;
      })}
    </div>
  );
};
