import { MiterAPI } from "dashboard/miter";
import { useState, useCallback, useEffect } from "react";
import { Notifier } from "ui";
import { AcumaticaBranch } from "backend/services/acumatica/types/acumatica-location-types";

type FetchBranches = {
  result: AcumaticaBranch[] | undefined;
  loading: boolean;
  error: unknown | null;
  fetchBranches: () => void;
};

export const useFetchAcumaticaBranches = (integrationConnectionId?: string): FetchBranches => {
  const [result, setResult] = useState<AcumaticaBranch[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchBranches = useCallback(async () => {
    if (!integrationConnectionId) return;

    setLoading(true);
    try {
      const result = await MiterAPI.integrations.acumatica.retrieve_branches(integrationConnectionId);

      if (result.error) throw Error(result.error);

      setResult(result);
    } catch (e: $TSFixMe) {
      Notifier.error("Error fetching Acumatica branches.");
      setError(e);
    }
    setLoading(false);
  }, [integrationConnectionId]);

  useEffect(() => {
    fetchBranches();
  }, []);

  return {
    result,
    loading,
    error,
    fetchBranches,
  };
};
