import React, { useCallback } from "react";

import FailuresModal, { FailureItem } from "dashboard/components/shared/FailuresModal";
import { useState } from "react";

type UseFailuresModal = {
  setFailures: (failures: Array<FailureItem>) => void;
  clearFailures: () => void;
  renderFailuresModal: () => JSX.Element;
};

export const useFailuresModal = (p?: { title?: string; onClose?: () => void }): UseFailuresModal => {
  const [failures, setFailures] = useState<Array<FailureItem>>([]);

  const clearFailures = useCallback(() => {
    setFailures([]);
  }, [setFailures]);

  const onClose = useCallback(() => {
    clearFailures();
    p?.onClose?.();
  }, [clearFailures, p?.onClose]);

  const renderFailuresModal = () => {
    if (!failures.length) return <></>;

    return <FailuresModal headerText={p?.title || "Failures"} failures={failures} onClose={onClose} />;
  };

  return {
    setFailures,
    clearFailures,
    renderFailuresModal,
  };
};
