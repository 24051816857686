import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, AggregatedEmployeeBenefit } from "dashboard/miter";
import { CleanedEmployeeBenefit } from "dashboard/pages/benefits/benefitsUtils";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * EMPLOYEE BENEFITS
 */
type EmployeeBenefitParams =
  | AggregatedEmployeeBenefit
  | AggregatedEmployeeBenefit[]
  | CleanedEmployeeBenefit
  | CleanedEmployeeBenefit[]
  | undefined;

type EmployeeBenefitAction = "create" | "read" | "update" | "delete";
export type EmployeeBenefitAbilities = {
  can: (action: EmployeeBenefitAction, items?: EmployeeBenefitParams) => boolean;
  cannot: (action: EmployeeBenefitAction, items?: EmployeeBenefitParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: EmployeeBenefitAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useEmployeeBenefitAbilities = (): EmployeeBenefitAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: EmployeeBenefitAction, items?: EmployeeBenefitParams) => {
      if (!items) return false;
      const benefits = Array.isArray(items) ? items : [items];

      return benefits.every((benefit) => {
        const teamMemberId = getTeamMemberId(benefit);

        switch (action) {
          case "create":
            return can_("benefits:create", { teamMember: teamMemberId });
          case "read":
            return can_("benefits:read", { teamMember: teamMemberId });
          case "update":
            return can_("benefits:update", { teamMember: teamMemberId });
          case "delete":
            return can_("benefits:delete", { teamMember: teamMemberId });
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: EmployeeBenefitAction, items: EmployeeBenefitParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "benefits",
    teamMemberField: { fieldName: "employee._id", fieldType: "_id" },
    appModule: "payroll_and_compliance",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<EmployeeBenefitAction>("benefits", true);

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, filter, teamPredicate]);
};

const getTeamMemberId = (item: AggregatedEmployeeBenefit | CleanedEmployeeBenefit | undefined) => {
  if (!item) return;

  if ("miter_tm" in item) {
    return item.miter_tm._id;
  } else {
    return item.employee._id;
  }
};
