import {
  useActiveCompany,
  useLookupPaySchedule,
  useLookupTimeOffPolicy,
  usePaySchedules,
} from "dashboard/hooks/atom-hooks";
import { getPayPeriodsForHoursByPayPeriod } from "dashboard/pages/reports/reportPages/HoursByPayPeriod";
import { DateTime } from "luxon";
import { hoursFormatter, roundTo } from "miter-utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formblock } from "ui";
import {
  AggregatedLiveTimesheet,
  AggregatedMiterEarning,
  AggregatedTeamMember,
  AggregatedTimeOffRequest,
  AggregatedTimesheet,
  Company,
  LiveTimesheet,
  MiterAPI,
  MiterFilterArray,
  MiterFilterField,
  TimeOffPolicy,
  TimesheetSection,
} from "dashboard/miter";
import { EditPayPeriodTimesheetRow } from "./TimesheetsByPayPeriodEditor";
import { ColDef, EditableCallbackParams } from "ag-grid-community";
import { ColumnConfig } from "ui/table-v2/Table";
import { Option } from "ui/form/Input";
import { TimesheetTableEntry } from "dashboard/utils/timesheetUtils";
import { earningTypeLookup } from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";
import { LookupAtomFunction } from "dashboard/atoms";
import { TimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { TimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";
import { TimesheetEarningType } from "backend/models/timesheet";

export type DateRangeOption = {
  label: string;
  value: string | null;
  periodStart?: string;
  periodEnd?: string;
};

export type PayPeriodSelectorProps = {
  selectedPayScheduleId: string | undefined;
  setSelectedPayScheduleId: (id: string | undefined) => void;
  setPeriod: (period: { periodStart: string; periodEnd: string }) => void;
};

export const DAY_COL_PREFIX = "dayCol+";

export const PayPeriodSelector: React.FC<PayPeriodSelectorProps> = ({
  selectedPayScheduleId,
  setSelectedPayScheduleId,
  setPeriod,
}) => {
  const paySchedules = usePaySchedules();
  const activeCompany = useActiveCompany();
  const lookupPaySchedule = useLookupPaySchedule();
  const ps = lookupPaySchedule(selectedPayScheduleId);

  const [selectedPayPeriod, setSelectedPayPeriod] = useState<DateRangeOption | undefined>();
  const [selectedWorkweek, setSelectedWorkweek] = useState<DateRangeOption | undefined>();

  useEffect(() => {
    const defaultPaySchedule = paySchedules.find((ps) => ps.default);
    setSelectedPayScheduleId(defaultPaySchedule?._id);
  }, [paySchedules]);

  const payScheduleOptions = useMemo(() => {
    if (!paySchedules) return [];
    return paySchedules.map((ps) => {
      return { label: ps.label, value: ps._id };
    });
  }, [paySchedules]);

  const payPeriods = useMemo(() => {
    if (!ps || !activeCompany) return [];
    return getPayPeriodsForHoursByPayPeriod(ps, activeCompany);
  }, [activeCompany, ps]);

  const payPeriodOptions = useMemo(() => {
    const options = payPeriods.map((p) => {
      const startString = DateTime.fromISO(p.periodStart).toFormat("MMM dd");
      const endString = DateTime.fromISO(p.periodEnd).toFormat("MMM dd, yyyy");
      return {
        label: `${startString} to ${endString}`,
        value: p.periodEnd,
        periodStart: p.periodStart,
        periodEnd: p.periodEnd,
      };
    });
    setSelectedPayPeriod(options[0]);
    return options;
  }, [payPeriods]);

  const workweekOptions = useMemo(() => {
    if (!selectedPayPeriod?.value || !ps) return [];
    const { periodStart, periodEnd } = selectedPayPeriod;
    if (!periodStart || !periodEnd) return [];

    // Get the workweek start date that is closest to the period start date
    const weekdayOfPeriodStart = DateTime.fromISO(periodStart).weekday;
    const daysToSubtract = (weekdayOfPeriodStart - (ps?.workweek_end_day || 7) + 6) % 7;
    let workweekStartIso = DateTime.fromISO(periodStart).minus({ days: daysToSubtract }).toISODate();

    const options: DateRangeOption[] = [{ label: "All weeks", value: null }];
    let week = 1;

    while (workweekStartIso <= periodEnd) {
      const workweekStartString = DateTime.fromISO(workweekStartIso).toFormat("LLL d");
      const workweekEndDt = DateTime.fromISO(workweekStartIso).plus({ days: 6 });
      const workweekEndString = workweekEndDt.toFormat("LLL d");
      options.push({
        value: workweekStartIso,
        label: `Week ${week}: ${workweekStartString} - ${workweekEndString}`,
        periodStart: workweekStartIso,
        periodEnd: workweekEndDt.toISODate(),
      });
      workweekStartIso = DateTime.fromISO(workweekStartIso).plus({ days: 7 }).toISODate();
      week += 1;
    }
    setSelectedWorkweek(options[0]);
    return options;
  }, [selectedPayPeriod, ps]);

  useEffect(() => {
    if (selectedWorkweek?.periodEnd && selectedWorkweek?.periodStart) {
      setPeriod({ periodStart: selectedWorkweek.periodStart, periodEnd: selectedWorkweek.periodEnd });
    } else if (selectedPayPeriod?.periodStart && selectedPayPeriod?.periodEnd) {
      setPeriod({ periodStart: selectedPayPeriod.periodStart, periodEnd: selectedPayPeriod.periodEnd });
    }
  }, [selectedPayPeriod, selectedWorkweek]);

  return (
    <div className="flex" style={{ alignItems: "center" }}>
      {payScheduleOptions.length > 1 && (
        <>
          <Formblock
            name="pay_schedule"
            type="select"
            options={payScheduleOptions}
            style={{ width: 210, marginBottom: 0, height: 32 }}
            inputProps={{
              style: { marginBottom: 0, height: 32, display: "flex", alignItems: "center" },
            }}
            editing={true}
            onChange={(o) => {
              setSelectedPayScheduleId(o?.value);
            }}
            value={payScheduleOptions.find((option) => option.value === selectedPayScheduleId)}
          />
          <div style={{ width: 20 }}></div>
        </>
      )}
      <Formblock
        name="pay_period"
        type="select"
        options={payPeriodOptions}
        style={{ width: 210, marginBottom: 0, height: 32 }}
        inputProps={{
          style: { marginBottom: 0, height: 32, display: "flex", alignItems: "center" },
        }}
        editing={true}
        onChange={setSelectedPayPeriod}
        value={payPeriodOptions.find((option) => option.value === selectedPayPeriod?.value)}
      />
      <div style={{ width: 20 }}></div>
      {workweekOptions && workweekOptions.length > 2 && (
        <Formblock
          name="workweek"
          type="select"
          options={workweekOptions}
          style={{ width: 210, marginBottom: 0, height: 32 }}
          inputProps={{
            style: { marginBottom: 0, height: 32, display: "flex", alignItems: "center" },
          }}
          editing={true}
          onChange={(option) => setSelectedWorkweek(option)}
          value={workweekOptions.find((option) => option.value === selectedWorkweek?.value)}
        />
      )}
    </div>
  );
};

export type PrepTimesheetChangeOutput = {
  creates: {
    team_member: string;
    job?: string;
    activity?: string;
    clock_in: number;
    clock_out: number;
    classification_override?: string | null;
    rate_differential_id?: string | null;
  }[];
  updates: {
    timesheetId: string;
    job?: string;
    activity?: string;
    clock_in: number;
    clock_out: number;
    classification_override?: string | null;
    rate_differential_id?: string | null;
  }[];
  idsToArchive: string[];
};

type ReducedData = {
  _id: string;
  date: string;
  tmId: string;
  jobId?: string;
  activityId?: string;
  hours: number;
  earningTypeAlias?: string;
  classificationOverride?: string;
  rateDifferentialId?: string;
};

// This function takes the table data and compares it to the timesheets in the database
// It returns an object with creates, updates, and idsToArchive
export const prepTimesheetChanges = (
  tableData: EditPayPeriodTimesheetRow[],
  timesheets: AggregatedTimesheet[],
  activeCompany: Company | null
): PrepTimesheetChangeOutput => {
  const creates: PrepTimesheetChangeOutput["creates"] = [];
  const updates: PrepTimesheetChangeOutput["updates"] = [];
  const unmatchedTimesheets = [...timesheets].filter((ts) => ts.status === "unapproved");
  const idsToArchiveSet: Set<string> = new Set();

  const zone = activeCompany?.timezone;

  // Get hours for each unique job-activity-date combination
  const reducedDataMap: Map<string, ReducedData> = tableData
    .filter((row) => row.source === "timesheet")
    .reduce((acc, row) => {
      if (row.status !== "unapproved") return acc;
      const tmId = row.team_member_id;
      const jobId = row.job_id || undefined;
      const activityId = row.activity_id || undefined;
      const earningTypeAlias = row.earning_type_alias;
      const classificationOverride = row.classification_override || undefined;
      const rateDifferentialId = row.rate_differential_id || undefined;
      const dayColStrings = Object.keys(row).filter((prop) => prop.includes(DAY_COL_PREFIX));
      dayColStrings.forEach((dayColString) => {
        const isoDay = dayColString.replace(DAY_COL_PREFIX, "");
        const dayHours = Number(row[dayColString] || 0) as number;
        const key = `${row.team_member_id}${jobId}${activityId}${isoDay}${earningTypeAlias}${classificationOverride}${rateDifferentialId}`;
        const item = acc.get(key) || {
          _id: key,
          date: isoDay,
          tmId,
          jobId,
          activityId,
          hours: 0,
          earningTypeAlias,
          classificationOverride,
          rateDifferentialId,
        };
        item.hours += dayHours;
        acc.set(key, item);
      });
      return acc;
    }, new Map());

  const reducedData = Array.from(reducedDataMap.values());

  // For each row in the reduced data, find the matching timesheet(s)
  // Determine if timesheets should be updated, created, or archived
  const elapsedHoursByDayAndTeamMember: Record<string, Record<string, number>> = {};
  for (const row of reducedData) {
    const {
      date,
      jobId,
      activityId,
      hours,
      tmId,
      earningTypeAlias,
      classificationOverride,
      rateDifferentialId,
    } = row;

    if (!elapsedHoursByDayAndTeamMember[date]) elapsedHoursByDayAndTeamMember[date] = {};
    if (!elapsedHoursByDayAndTeamMember[date]![tmId]) elapsedHoursByDayAndTeamMember[date]![tmId] = 0;

    const dayElapsedHours = elapsedHoursByDayAndTeamMember[date]?.[tmId] || 0;
    elapsedHoursByDayAndTeamMember[date]![tmId] = roundTo(dayElapsedHours + hours);

    const matchingTimesheetsForRow: AggregatedTimesheet[] = [];
    unmatchedTimesheets.forEach((ts) => {
      const tsEarningTypeAlias = getEarningTypeAliasForTs(ts);
      const tsDate = DateTime.fromSeconds(ts.clock_in, { zone }).toISODate();
      if (
        ts.status === "unapproved" &&
        ts.team_member?._id === tmId &&
        date === tsDate &&
        jobId === ts.job?._id &&
        activityId === ts.activity?._id &&
        tsEarningTypeAlias === earningTypeAlias &&
        classificationOverride === (ts.classification_override || undefined) &&
        rateDifferentialId === (ts.rate_differential_id || undefined)
      ) {
        matchingTimesheetsForRow.push(ts);
        unmatchedTimesheets.splice(unmatchedTimesheets.indexOf(ts), 1);
      }
    });

    const earningTypeParams = getEarningTypeParamsFromAlias(earningTypeAlias || "");

    // If the table data has no hours, archive any matching timesheets
    if (hours === 0) {
      matchingTimesheetsForRow.forEach((ts) => idsToArchiveSet.add(ts._id));
      // If there is just one matching timesheet, update it
    } else if (matchingTimesheetsForRow.length === 1) {
      const ts = matchingTimesheetsForRow[0];
      if (ts && hours && hours !== ts.hours) {
        updates.push({
          job: jobId,
          activity: activityId,
          timesheetId: ts._id,
          classification_override: classificationOverride,
          rate_differential_id: rateDifferentialId,
          ...earningTypeParams,
          ...getClockInAndOut({
            hours,
            date,
            company: activeCompany,
            unpaidBreakSeconds: ts.unpaid_break_time,
            dayElapsedHours,
          }),
        });
      }
    } else {
      // If there are multiple matching timesheets, archive them
      if (matchingTimesheetsForRow.length > 1) {
        matchingTimesheetsForRow.forEach((ts) => idsToArchiveSet.add(ts._id));
      }
      // We're only here if there are no matching timesheets or if we've archived multiple timesheets
      // ...so we can create a new one
      creates.push({
        ...earningTypeParams,
        team_member: tmId,
        job: jobId,
        activity: activityId,
        classification_override: classificationOverride,
        rate_differential_id: rateDifferentialId,
        ...getClockInAndOut({ hours, date, company: activeCompany, dayElapsedHours }),
      });
    }
  }

  // Find timesheets that don't match any of the reduced data rows
  unmatchedTimesheets.forEach((ts) => {
    idsToArchiveSet.add(ts._id);
  });

  return { creates, updates, idsToArchive: Array.from(idsToArchiveSet) };
};

export const getClockInAndOut = (inputs: {
  hours: number;
  date: string;
  company: Company | null;
  unpaidBreakSeconds?: number | null;
  dayElapsedHours?: number;
}): { clock_in: number; clock_out: number } => {
  const { hours, date, company, unpaidBreakSeconds, dayElapsedHours } = inputs;
  const zone = company?.timezone;
  const [year, month, day] = date.split("-");
  if (!year || !month || !day) throw Error(`There was an error saving the timesheets.`);
  const clockInTime = company?.settings.timesheets.default_clock_in_time || "09:00";
  const [hour, minute] = clockInTime.split(":");
  if (!hour || !minute) throw Error(`There was an error saving the timesheets.`);
  const clockInDt = DateTime.fromObject(
    {
      year: Number(year),
      month: Number(month),
      day: Number(day),
      hour: Number(hour),
      minute: Number(minute),
    },
    { zone }
  ).plus({ hours: dayElapsedHours });
  return {
    clock_in: clockInDt.toSeconds(),
    clock_out: clockInDt.plus({ hours }).toSeconds() + (unpaidBreakSeconds || 0),
  };
};

export const buildPayPeriodTimesheetsFilter = (inputs: {
  periodStart: string;
  periodEnd: string;
  tmIds?: string[];
  jobId?: string;
  activeCompany: Company | null;
  authFilter?: MiterFilterField | undefined;
}): MiterFilterArray => {
  const { periodStart, periodEnd, tmIds, activeCompany, jobId, authFilter } = inputs;

  const zone = activeCompany?.timezone;
  const start = DateTime.fromISO(periodStart, { zone }).startOf("day");
  const end = DateTime.fromISO(periodEnd, { zone }).endOf("day");

  const baseFilters: MiterFilterArray = [
    {
      field: "company",
      value: activeCompany?._id,
      type: "string",
    },
    {
      field: "clock_in",
      value: [start.toSeconds(), end.toSeconds()],
      comparisonType: "<+>",
      type: "number",
    },
  ];

  if (jobId) {
    baseFilters.push({
      field: "job",
      value: jobId,
      type: "string",
    });
  }

  if (tmIds?.length) {
    baseFilters.push({
      field: "team_member",
      value: tmIds,
      comparisonType: "in" as const,
    });
  }

  if (authFilter) {
    baseFilters.push(authFilter);
  }

  return baseFilters;
};

export const getTimesheetsForPeriod = async (inputs: {
  periodStart: string;
  periodEnd: string;
  tmIds?: string[];
  jobId?: string;
  activeCompany: Company | null;
  authFilter?: MiterFilterField | undefined;
}): Promise<AggregatedTimesheet[]> => {
  const { activeCompany } = inputs;
  if (!activeCompany) return [];

  const filters = buildPayPeriodTimesheetsFilter(inputs);
  let resultsRemaining = true;
  let nextPage: string | undefined;
  const timesheets: AggregatedTimesheet[] = [];
  while (resultsRemaining) {
    const response = await MiterAPI.timesheets.forage({
      filter: filters,
      limit: 1000,
      starting_after: nextPage,
    });
    timesheets.push(...response.data);
    if (response.next_page) nextPage = response.next_page;
    if (!response.next_page) resultsRemaining = false;
  }
  return timesheets;
};

export const getTimeOffRequestsForPeriod = async (inputs: {
  periodStart: string;
  periodEnd: string;
  companyId: string;
  payScheduleId: string;
  tmIds?: string[];
}): Promise<AggregatedTimeOffRequest[]> => {
  const { payScheduleId, periodStart, periodEnd, companyId, tmIds } = inputs;
  return await MiterAPI.time_off.requests.retrieve_for_pay_period({
    payScheduleId,
    companyId,
    periodStart,
    periodEnd,
    tmIds,
  });
};

export const getDayColDefs = (inputs: {
  periodStart: string;
  periodEnd: string;
  usingTableV2: boolean;
  timesheetAbilities?: TimesheetAbilities;
  timeOffRequestAbilities?: TimeOffRequestAbilities;
}): ColumnConfig<$TSFixMe>[] => {
  const dayColumns: string[] = [];
  const { periodStart, periodEnd, timesheetAbilities, timeOffRequestAbilities } = inputs;
  let day = periodStart;
  let periodLength = 0;
  while (day <= periodEnd) {
    dayColumns.push(day);
    day = DateTime.fromISO(day).plus({ days: 1 }).toISODate();
    periodLength += 1;
  }
  const shortDayNames = periodLength > 7;
  const dayColDefs: ColumnConfig<$TSFixMe>[] = dayColumns.map((day) => {
    const dt = DateTime.fromISO(day);
    const dayOfWeek = shortDayNames ? dt.weekdayShort.slice(0, 1) : dt.weekdayShort;
    const headerName = `${dayOfWeek} ${dt.toFormat("LLL dd")}`;

    const def: ColDef = {
      field: `${DAY_COL_PREFIX}${day}`,
      aggFunc: "sumValues",
      cellClass: "less-padding",
      headerClass: "less-padding",
      suppressMenu: true,
      editable: (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) => {
        if (!params.data) return false;

        const source = params.data.source;

        // Only allow editing of unapproved timesheets
        const isEditableTimesheet =
          timesheetAbilities?.can("update", params.data) && params.data.status === "unapproved";

        if (source === "timesheet" && !isEditableTimesheet) {
          return false;
        }

        // Only allow editing of approved or unapproved time off requests
        const isEditableTimeOffRequest =
          timeOffRequestAbilities?.can("update", params.data) &&
          ["approved", "unapproved"].includes(params.data.status);

        if (source === "time_off_request" && !isEditableTimeOffRequest) {
          return false;
        }

        return true;
      },
      valueFormatter: hoursFormatter,
      useValueFormatterForExport: true,
      headerName,
      minWidth: shortDayNames ? 85 : 100,
    };
    if (!inputs.usingTableV2) return def;

    const config: ColumnConfig<$TSFixMe> = { ...def, editorType: "number", sumRow: true };
    return config;
  });
  return dayColDefs;
};

type DatePeriod = { periodStart: string; periodEnd: string };

export const getPriorPayPeriod = (
  inputs: DatePeriod
): DatePeriod & { rangeString: string; periodLength: number } => {
  const { periodStart, periodEnd } = inputs;
  const periodStartDt = DateTime.fromISO(periodStart);
  const periodEndDt = DateTime.fromISO(periodEnd);
  const periodLength = periodEndDt.diff(periodStartDt, "days").days + 1;
  const priorPeriodStart = periodStartDt.minus({ days: periodLength });
  const priorPeriodEnd = periodEndDt.minus({ days: periodLength });
  const rangeString = `${priorPeriodStart.toFormat("MMM dd")} - ${priorPeriodEnd.toFormat("MMM dd")}`;
  return {
    periodStart: priorPeriodStart.toISODate(),
    periodEnd: priorPeriodEnd.toISODate(),
    rangeString,
    periodLength,
  };
};

export const getEarningTypeAliasForTs = (
  ts: AggregatedTimesheet | AggregatedLiveTimesheet | LiveTimesheet | undefined | null
): string => {
  if (!ts) return "";
  if (!ts.time_off_policy_id) return ts.earning_type || "";

  return `${ts.earning_type}${EARNING_TYPE_ALIAS_SPLITTER}${ts.time_off_policy_id}`;
};

export const getEarningTypeAliasForTor = (tor: AggregatedTimeOffRequest): string => {
  const earnType = tor.time_off_policy.type === "vacation" ? "pto" : "sick";
  return `${earnType}${EARNING_TYPE_ALIAS_SPLITTER}${tor.time_off_policy._id}`;
};

export const getEarningTypeAliasForHoliday = (he: AggregatedMiterEarning): string => {
  const earnType = he.miter_type;
  return `${earnType}${EARNING_TYPE_ALIAS_SPLITTER}${he.holiday_id}`;
};

export const getEarningTypeAliasForMissedBreak = (e: AggregatedMiterEarning): string => {
  const earnType = e.miter_type;
  return `${earnType}${EARNING_TYPE_ALIAS_SPLITTER}${e._id}`;
};

export const EARNING_TYPE_ALIAS_SPLITTER = "++";

const timesheetEarningTypes = ["pto", "sick", "paid_holiday", "hourly", "overtime", "double_overtime"];

export const getEarningTypeParamsFromAlias = (
  alias: string | null
): Pick<AggregatedTimesheet, "earning_type" | "time_off_policy_id"> => {
  if (!alias) return { earning_type: null, time_off_policy_id: null };
  const [earningType, time_off_policy_id] = alias.split(EARNING_TYPE_ALIAS_SPLITTER);
  let finalEarningType: string | undefined = earningType;
  if (!earningType || !timesheetEarningTypes.includes(earningType)) finalEarningType = undefined;
  return {
    earning_type: finalEarningType as TimesheetEarningType,
    time_off_policy_id: time_off_policy_id || null,
  };
};

export type GroupedSelectOptions<T = string> = (
  | {
      label: string;
      options: Option<T>[];
    }
  | Option<T>
)[];

export const flattenGroupedEarningTypeOptions = (options: GroupedSelectOptions): Option<string>[] => {
  return options.reduce((acc, curr) => {
    if (!("options" in curr)) return acc;

    // Flatten all options but append the vacation and sick options with vacation and sick
    if (curr.label === "Vacation" || curr.label === "Sick") {
      return [...acc, ...curr.options.map((o) => ({ ...o, label: `${curr.label} (${o.label})` }))];
    } else {
      return [...acc, ...curr.options];
    }
  }, [] as Option<string>[]);
};

export const getFlattenedEarningTypeOptionsForTm = (
  lookupTimeOffPolicy: LookupAtomFunction<TimeOffPolicy>,
  source: "timesheet" | "time_off_request" | "holiday" | "missed_break" | undefined,
  tm: AggregatedTeamMember | undefined
): Option<string>[] => {
  const groupedOptions = getEarningTypeOptionsForTm(lookupTimeOffPolicy, source, tm);
  return flattenGroupedEarningTypeOptions(groupedOptions);
};

export const getEarningTypeOptionsForTm = (
  lookupTimeOffPolicy: LookupAtomFunction<TimeOffPolicy>,
  source: "timesheet" | "time_off_request" | "holiday" | "missed_break" | undefined,
  tm: AggregatedTeamMember | undefined
): GroupedSelectOptions<string> => {
  if (!tm) return [];
  const timeOffPolicyOptions: Option<string>[] = [];
  tm.time_off.policies.forEach((p) => {
    const policy = lookupTimeOffPolicy(p.policy_id);
    if (!policy) return;
    const { type, name } = policy;
    timeOffPolicyOptions.push({
      label: `${name}`,
      value: `${type === "sick" ? "sick" : "pto"}${EARNING_TYPE_ALIAS_SPLITTER}${policy._id}`,
    });
  });

  if (timeOffPolicyOptions.length === 0) {
    timeOffPolicyOptions.push({ label: "Not enrolled", value: "", isDisabled: true });
  }

  if (source === "time_off_request") {
    return timeOffPolicyOptions;
  }

  const holidayOptions = [{ label: "Holiday", value: "paid_holiday" }];
  const hourlyOptions = [
    { label: "Auto (recommended)", value: "" },
    { label: "Hourly regular", value: "hourly" },
    { label: "Overtime", value: "overtime" },
    { label: "Double overtime", value: "double_overtime" },
  ];
  return [
    { label: "Hourly", options: hourlyOptions },
    { label: "Time off", options: timeOffPolicyOptions },
    { label: "Holiday", options: holidayOptions },
  ];
};

export const getEarningTypeOptionsForTmFlattened = (
  lookupTimeOffPolicy: LookupAtomFunction<TimeOffPolicy>,
  source: "timesheet" | "time_off_request" | "holiday" | "missed_break" | undefined,
  tm: AggregatedTeamMember | undefined
): Option<string | null>[] => {
  const groupedOptions = getEarningTypeOptionsForTm(lookupTimeOffPolicy, "timesheet", tm);
  // Flatten all options but append the vacation and sick options with vacation and sick
  const flattenedOptions = groupedOptions.reduce((acc, curr) => {
    if (!("options" in curr)) return acc;

    if (curr.label === "Vacation" || curr.label === "Sick") {
      return [...acc, ...curr.options.map((o) => ({ ...o, label: `${curr.label} (${o.label})` }))];
    } else {
      return [...acc, ...curr.options];
    }
  }, [] as Option<string | null>[]);

  return flattenedOptions;
};

export const baseEarningTypeOptions = [
  { label: "Auto (recommended)", value: "" },
  { label: "Hourly regular", value: "hourly" },
  { label: "Overtime", value: "overtime" },
  { label: "Double overtime", value: "double_overtime" },
  { label: "Holiday", value: "paid_holiday" },
];

export const expandedEarningTypeOptions = [
  ...baseEarningTypeOptions,
  { label: "Sick", value: "sick" },
  { label: "Vacation", value: "pto" },
];

type EarningTypeLabeler = (
  timesheet: TimesheetTableEntry | AggregatedTimesheet | undefined | null
) => string | undefined;

export const useEarningTypeLabeler = (): EarningTypeLabeler => {
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();

  return useCallback((timesheet: TimesheetTableEntry | AggregatedTimesheet | undefined | null) => {
    if (!timesheet) return;

    if (!timesheet.earning_type) return;

    const earningTypeLabel = earningTypeLookup[timesheet.earning_type];

    if (timesheet.time_off_policy_id) {
      const policyName = lookupTimeOffPolicy(timesheet.time_off_policy_id)?.name;
      return `${earningTypeLabel} (${policyName})`;
    }

    return earningTypeLabel;
  }, []);
};

export const timesheetsByPayPeriodEarnTypeLookup = (checkType?: string, miterType?: string): string => {
  if (miterType === "holiday") return "Paid holiday";
  if (miterType === "missed_break") return "Missed break";
  return earningTypeLookup[checkType || ""];
};

export type TimesheetSectionTimeType =
  | "reg"
  | "ot"
  | "dot"
  | "weekly_ot"
  | "weekly_dot"
  | "paid_reg_break"
  | "paid_ot_break"
  | "paid_dot_break"
  | "unpaid_break";

export const timesheetSectionTimeTypeColors: Record<TimesheetSectionTimeType, string> = {
  reg: "blue",
  ot: "yellow",
  dot: "orange",
  weekly_ot: "yellow",
  weekly_dot: "orange",
  paid_reg_break: "green",
  paid_ot_break: "green",
  paid_dot_break: "green",
  unpaid_break: "red",
};

export const timeTypeFromTimesheetSection = (
  section: TimesheetSection | undefined
): TimesheetSectionTimeType | undefined => {
  if (!section) {
    return undefined;
  }
  if (section.break_label && section.paid) {
    if (section.hours_type === "reg") {
      return "paid_reg_break";
    } else if (section.hours_type === "daily_ot" || section.hours_type === "weekly_ot") {
      return "paid_ot_break";
    } else if (section.hours_type === "daily_dot" || section.hours_type === "weekly_dot") {
      return "paid_dot_break";
    }
  }
  if ((section.break_label && !section.paid) || section.hours_type === "unpaid") {
    return "unpaid_break";
  }
  if (section.hours_type === "daily_dot") {
    return "dot";
  }
  if (section.hours_type === "daily_ot") {
    return "ot";
  }
  return section.hours_type;
};
