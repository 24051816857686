import React, { useMemo } from "react";
import { Importer, ImportField } from "../importer/Importer";
import { buildFlatfileMessage } from "dashboard/utils/flatfile";
import { Notifier } from "dashboard/utils";
import { FlatfileResults } from "@flatfile/react";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";
import { MiterAPI } from "dashboard/miter";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { CreateEquipmentParams } from "backend/services/equipment-service";
import { IDataHookRecord } from "@flatfile/adapter/build/main/interfaces";

type Props = {
  onFinish: () => void;
};

export const EquipmentImporter: React.FC<Props> = ({ onFinish }) => {
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const activeCompanyId = useActiveCompanyId();

  /*********************************************************
   *  Helper functions
   **********************************************************/
  const formatImportParams = (params: CreateEquipmentParams[]) => {
    return params.map((row) => {
      return {
        ...row,

        // Add the role's company id to the params
        company_id: activeCompanyId || "",
      };
    });
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const formattedParams = formatImportParams(results.validData);
      const response = await MiterAPI.equipment.import({
        clean_inputs: formattedParams,
        raw_inputs: results.validData,
      });

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;
      const warnings = response.results.warnings.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} equipment items with ${errors} errors.`);
        } else {
          Notifier.success(`Imported ${successes} equipment items.`);
        }
      } else {
        Notifier.error(`There were ${errors} errors and ${warnings} warnings.`);
      }
      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the equipment items.");
    }
  };

  // flatfile configuration
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Name",
        key: "name",
        validators: [{ validate: "required" }],
      },
      {
        label: "Code / Identifier",
        key: "code",
      },
      {
        label: "Description",
        key: "description",
      },
    ];
    if (hasAccessToEquipmentTracking) {
      fieldList.push({
        label: "Status",
        key: "status",
      });
      fieldList.push({
        label: "Cost rate",
        type: "string",
        key: "cost_rate",
        description: "Cost rate of the equipment",
        hook: (val) => (typeof val === "string" ? validateAmount(val) : validateAmount(val.amount)),
      });
    }
    return fieldList;
  }, [hasAccessToEquipmentTracking]);

  return (
    <Importer id="equipment" resource="equipment" onSave={handleSubmit} fields={fields} title="Bulk import" />
  );
};

const validateAmount = (amount: string | number | undefined): IDataHookRecord | undefined => {
  let parsedAmount: number | undefined;
  if (amount) {
    // remove leading "$" signs
    if (typeof amount === "string") {
      if (amount.startsWith("$")) amount = amount.replaceAll("$", "");
      parsedAmount = parseFloat(amount);
    } else {
      parsedAmount = amount;
    }

    // checks if amount is positive and has 2 decimal places
    if (!/^[0-9]+\.?[0-9]?[0-9]?$/.test(parsedAmount + "")) {
      return buildFlatfileMessage("Amount must be a valid dollar amount", amount, "error");
    }
  }
  return { value: parsedAmount };
};
