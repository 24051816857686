import React, { FC, useEffect } from "react";
import { MiterAPI } from "dashboard/miter";
import { Formblock, WizardScreen, Notifier } from "ui";

import * as vals from "dashboard/utils/validators";

import {
  deleteFiles,
  formatFilesForUpload,
  buildUploadFormDefaultValues,
  DocumentUploadFields,
  I9Document,
  I9Documents,
  FileToUpload,
} from "miter-utils";

import styles from "./UploadDocumentsForm.module.css";
import useWizard from "ui/modal/useWizard";
import { useForm, useWatch } from "react-hook-form";
import { DateTime } from "luxon";
import { AggregatedI9 } from "dashboard/miter";
import { FilePickerFile } from "ui/form/FilePicker";

type Props = {
  I9: AggregatedI9;
  setI9: (I9: AggregatedI9) => void;
  name: "Upload Documents" | "Review Documents";
  getI9?: () => Promise<AggregatedI9 | undefined>;
};

export const UploadDocumentsForm: FC<Props> = ({ I9, name, getI9 }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { setCanNext } = useWizard();
  const [selectedAdditionalDocs, setSelectedAdditionalDocs] = React.useState<I9Document[]>([]);

  /*********************************************************
   *  Form setup
   **********************************************************/
  const form = useForm({
    mode: "all",
    defaultValues: buildUploadFormDefaultValues(I9),
    // Need this so default values still appear regardless of if they are visible on screen or not
    shouldUnregister: false,
  });

  const { errors, handleSubmit, watch, reset, formState, trigger } = form;
  const { dirtyFields } = formState;

  // Keep track of form data
  const formData = watch();
  useWatch({ control: form.control });

  /*********************************************************
   * useEffect's
   **********************************************************/

  // When the I-9 updates, make sure to keep the default values updated
  useEffect(() => {
    reset({ ...buildUploadFormDefaultValues(I9) });

    // Prefill the additional docs with the ones that are already uploaded
    if (I9.section_2.list_a.length > 1) {
      const defaultAdditionalDocuments = I9Documents.filter(
        (doc) =>
          doc.name === I9.section_2.list_a?.[1]?.type ||
          (doc.name === I9.section_2.list_a?.[2]?.type && doc.category === "no_list")
      );

      setSelectedAdditionalDocs(defaultAdditionalDocuments);
    }
  }, [I9]);

  // Trigger form validation when form data changes
  useEffect(() => {
    trigger();
  }, [JSON.stringify(formData)]);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors]);

  /*********************************************************
   *  Wizard handlers
   **********************************************************/
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveI9)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }
  };

  /*********************************************************
   *  Backend submission + cleaning functions
   **********************************************************/

  /** Saves the file in Miter and upload's it to S3 */
  const uploadFiles = async (files: FileToUpload[]) => {
    const filesWithCompany = files.map((file) => ({ ...file, company: I9.company_id }));
    const miterFiles = await MiterAPI.files.upload({ files: filesWithCompany });

    return miterFiles;
  };

  /** Returns the file's _id (uploads the file if it's not yet uploaded) */
  const buildFileID = async (
    file: FilePickerFile,
    doc: DocumentUploadFields["list_a"][0] | DocumentUploadFields["list_b"] | DocumentUploadFields["list_c"],
    index: number
  ): Promise<string> => {
    // If it's a miter file, return it's _id
    if (file.data) return file.data._id;

    const label = `${doc.document_title || doc.type || "I-9 Document"} - Page ${index + 1} - I-9`;

    // Upload file to Miter / S3
    const uploadableFile = formatFilesForUpload([file], I9.company_id, {
      label,
      parent_type: "team_member" as const,
      parent_id: I9.team_member_id,
      sensitive: true,
    });

    const uploadedFile = await uploadFiles(uploadableFile);
    if (!uploadedFile[0]) throw new Error("Unable to upload file");

    return uploadedFile[0].file._id;
  };

  /** Build the parameters to update an embedded I-9 doc - we upload new files here */
  const buildDocumentParams = async (doc?: DocumentUploadFields["list_b"]) => {
    if (!doc || Object.keys(doc).length === 0 || !doc.type) return;

    // Get the configuration used for this document
    const config = I9Documents.find((d) => d.name === doc.type);
    if (!config) throw new Error("There was an error finding the document's template");

    // Get the updated list of file_ids for this I-9 document (we upload new files this way as well)
    const updatedFileIds = await Promise.all(
      doc.files.map(async (files, index) => {
        const selectedFiles = files.filter((file) => !file.deleted);
        return await Promise.all(selectedFiles.map(async (file) => buildFileID(file, doc || config, index)));
      })
    );

    // If the doc type is employment authorization, make sure document_number is max 10 characters
    if (doc.type === "Employment Authorization Document (Form I-766)") {
      if (doc.document_number && doc.document_number.length > 9) {
        throw new Error("USCIS document numbers are a maximum of 9 characters");
      }
    }

    return {
      document_title: config.title,
      issuing_authority: doc.issuing_authority,
      document_number: doc.document_number,
      expiration_date: doc.expiration_date?.toISODate(),
      file_ids: updatedFileIds.flat(),
      type: doc.type,
    };
  };

  /** Build the parameters for all of the I-9 docuemnts */
  const buildParams = async (params: DocumentUploadFields) => {
    const [list_a, list_b, list_c] = await Promise.all([
      Promise.all(params.list_a.map(async (doc) => buildDocumentParams(doc))),
      buildDocumentParams(params.list_b),
      buildDocumentParams(params.list_c),
    ]);

    return {
      "section_2.list_a": list_a,
      "section_2.list_b": list_b,
      "section_2.list_c": list_c,
    };
  };

  /** Deletes the files that have been removed from the I-9, in the DB + S3, once the I-9 has been updatd  */
  const deleteOldFiles = async (oldI9: AggregatedI9, newI9: AggregatedI9) => {
    let filesToDelete: string[] = [];

    const oldListAFiles = oldI9.section_2.list_a.map((doc) => doc.file_ids).flat();
    const oldListBFiles = oldI9.section_2.list_b.file_ids;
    const oldListCFiles = oldI9.section_2.list_c.file_ids;

    const newListAFiles = newI9.section_2.list_a.map((doc) => doc.file_ids).flat();
    const newListBFiles = newI9.section_2.list_b.file_ids;
    const newListCFiles = newI9.section_2.list_c.file_ids;

    filesToDelete = filesToDelete.concat(oldListAFiles.filter((f) => !newListAFiles.includes(f)));
    filesToDelete = filesToDelete.concat(oldListBFiles.filter((f) => !newListBFiles.includes(f)));
    filesToDelete = filesToDelete.concat(oldListCFiles.filter((f) => !newListCFiles.includes(f)));

    // Delete the files from the backend
    if (filesToDelete.length > 0) {
      await deleteFiles(filesToDelete);
    }
  };

  const saveI9 = async (data: DocumentUploadFields) => {
    try {
      // Uploads the files + builds the parameters
      const params = await buildParams(data);

      // Save the I-9
      // @ts-expect-error fix me
      const res = await MiterAPI.i_9s.update(I9._id, params);
      if (res.error) throw new Error(res.error);

      // Delete the old I-9 files
      await deleteOldFiles(I9, res);

      // Refetch I9 data
      getI9?.();
    } catch (e: $TSFixMe) {
      console.error("Error uploading I-9 documents", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   * Form handlers
   **********************************************************/

  /** Used for only specific documents that need additional forms */
  const handleAdditionalDocSelect = (doc: I9Document) => {
    if (selectedAdditionalDocs.find((d) => d.name === doc.name)) return;

    // Only allow 2 additional documents to be selected - replace the first one if there are already 2
    if (selectedAdditionalDocs.length < 2) {
      setSelectedAdditionalDocs([...selectedAdditionalDocs, doc]);
    } else if (selectedAdditionalDocs.length === 2) {
      setSelectedAdditionalDocs([doc, selectedAdditionalDocs[0]!]);
    }
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const buildFieldName = (doc: I9Document, field: string) => {
    if (doc.category === "list_a") {
      return `list_a.0.${field}`;
    } else if (doc.category === "no_list") {
      const index = selectedAdditionalDocs.findIndex((d) => d.name === doc.name);
      return `list_a.${index + 1}.${field}`;
    } else {
      return `${doc.category}.${field}`;
    }
  };

  const renderDocumentFileInputs = (doc: I9Document) => {
    return (
      <div className="form-section">
        {doc.uploads.map((desc, index) => {
          const fieldName = buildFieldName(doc, `files.${index}`);

          return (
            <Formblock
              key={desc}
              label={desc}
              name={fieldName}
              className="modal wizard"
              type="file"
              form={form}
              multiple={true}
              maxFilesAllowed={1}
              variant="dropzone"
              editing={true}
              compact={true}
              labelStyle={{ marginBottom: 10, marginTop: 10 }}
              dropzoneLabel="Drag and drop or click to upload a document"
              val={vals.required}
              acceptedFileTypes={["image/jpeg", "image/png", "application/pdf"]}
            />
          );
        })}
      </div>
    );
  };

  const renderAdditionalDocumentButtons = (doc: I9Document) => {
    if (!doc.additional_docs?.length) return;

    return (
      <div className="form-section">
        <h3 className="form-section-subheader">
          Which of the additional docs would you like to upload (max 2)
        </h3>
        <div className={styles["i-9-upload-additional-docs"]}>
          {doc.additional_docs.map((desc) => {
            const config = I9Documents.find((d) => d.name === desc)!;
            const selected = selectedAdditionalDocs.find((d) => d.name === desc);

            return (
              <div
                className={styles["i-9-additional-document"] + " " + (selected ? styles["selected"] : "")}
                onClick={() => handleAdditionalDocSelect(config)}
              >
                <p className={styles["i-9-additional-document-description"]}>{desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDocumentInformationInputs = (doc: I9Document) => {
    return (
      <>
        <div className={styles["document-inputs"]}>
          <Formblock
            name={buildFieldName(doc, "type")}
            className="modal wizard"
            type="text"
            form={form}
            val={vals.required}
            value={doc.name}
            style={{ visibility: "hidden", display: "none" }}
            editing={true}
          />
          <Formblock
            name={buildFieldName(doc, "document_title")}
            className="modal wizard"
            type="text"
            form={form}
            val={vals.required}
            value={doc.title}
            style={{ visibility: "hidden", display: "none" }}
          />
          <Formblock
            label={" Issuing Authority"}
            name={buildFieldName(doc, "issuing_authority")}
            className="modal wizard"
            type="text"
            form={form}
            editing={true}
            val={vals.required}
            placeholder="Issuing Authority"
            labelInfo="The country, state, or government agency that issued the document"
          />
          <Formblock
            label={" Document Number"}
            name={buildFieldName(doc, "document_number")}
            className="modal wizard"
            type="text"
            form={form}
            editing={true}
            val={vals.required}
            placeholder="Document number"
          />
          <Formblock
            label={" Expiration Date"}
            name={buildFieldName(doc, "expiration_date")}
            className="modal wizard"
            type="datetime"
            form={form}
            editing={true}
            dateOnly={true}
            customFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            labelInfo="The date the document expires (if applicable)"
            min={DateTime.now()}
          />
        </div>
        {renderAdditionalDocumentButtons(doc)}
      </>
    );
  };

  const renderAdditionalDocumentInputs = () => {
    return (
      <>
        {selectedAdditionalDocs.map((d) => {
          return (
            <div className="form-section">
              <h3 className="form-section-subheader" style={{ marginBottom: 0 }}>
                {d.title}
              </h3>
              {renderDocumentFileInputs(d)}
              {renderDocumentInformationInputs(d)}
            </div>
          );
        })}
      </>
    );
  };

  const renderDocumentForm = (category: "list_a" | "list_b" | "list_c") => {
    const categoryIsNotInUse = !(category === "list_a"
      ? I9.section_2[category].length
      : Object.keys(I9.section_2[category]).length);

    if (categoryIsNotInUse) return null;

    const doc =
      category === "list_a"
        ? I9Documents.find((d) => d.name === I9.section_2.list_a[0]!.type)
        : I9Documents.find((d) => d.name === I9.section_2[category].type)!;

    if (!doc) return null;

    return (
      <div className={styles["i-9-doc-upload-form"]}>
        <h3 className={styles["i-9-doc-upload-title"]}>{doc.name}</h3>
        <div className={styles["i-9-doc-upload"]}>
          {renderDocumentFileInputs(doc)}
          {renderDocumentInformationInputs(doc)}
          {category === "list_a" && renderAdditionalDocumentInputs()}
        </div>
      </div>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["uploads-form"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>
            {name === "Upload Documents" ? "Let's upload your documents" : "Review the documents"}
          </h2>
          <p className={styles["subheader-description"]}>
            {name === "Upload Documents"
              ? "Fill out the form(s) below and upload your documents."
              : "Please review the documents that the employee uploaded. Remember, you are legally responsible for reviewing the physical version of these documents as well."}
          </p>
        </div>
        {renderDocumentForm("list_a")}
        {renderDocumentForm("list_b")}
        {renderDocumentForm("list_c")}
      </div>
    </WizardScreen>
  );
};
