import { ClickAwayListener } from "@material-ui/core";
import { X } from "phosphor-react";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import Notifier from "./notifier";

export const useCheckScript = (resourceUrl: string): void => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);
};

type CheckComponentHook = {
  renderComponent: () => ReactElement;
  componentURL?: string;
  setComponentURL: (url: string) => void;
  loadingComponent: boolean;
  setLoadingComponent: (loading: boolean) => void;
};

type CheckComponentHandler = {
  handler: {
    close: () => void;
  };
};

export const useCheckComponent = (onHide?: () => void): CheckComponentHook => {
  const [componentURL, setComponentURL] = useState<string>();
  const [loadingComponent, setLoadingComponent] = useState<boolean>(false);

  const checkComponentHandlerRef = useRef<CheckComponentHandler>();

  const handleHideComponent = async () => {
    checkComponentHandlerRef.current?.handler?.close();
    updateCheckComponentHandler(undefined);
    setComponentURL(undefined);
    onHide?.();
  };

  const handleCheckModalEvent = (event: string, _data: $TSFixMe) => {
    if (event === "check-component-employee-payment-setup-complete") {
      handleHideComponent();
      Notifier.success("Payment method setup complete!");
    }
  };

  const updateCheckComponentHandler = (handler?: CheckComponentHandler) => {
    checkComponentHandlerRef.current = handler;
  };

  useEffect(() => {
    if (componentURL) {
      loadCheckComponent(componentURL, "check-modal", updateCheckComponentHandler, handleCheckModalEvent);
    }
  }, [componentURL]);

  const renderComponent = (): ReactElement => {
    if (!componentURL) return <></>;

    return (
      <div className={"modal-background "}>
        <ClickAwayListener onClickAway={handleHideComponent}>
          <div className={"modal-wrapper check-modal-wrapper"} style={{ width: "80%", height: "80%" }}>
            <div className={"check-modal-close"} onClick={handleHideComponent}>
              <X />
            </div>
            <div
              id="check-modal"
              className={"modal-body check-modal-body"}
              style={{ height: "100%", width: "100%", overflowY: "scroll" }}
            />
            <div className="modal-footer"></div>
          </div>
        </ClickAwayListener>
      </div>
    );
  };

  return { componentURL, setComponentURL, renderComponent, loadingComponent, setLoadingComponent };
};

export const loadCheckComponent = (
  componentLink: string,
  componentDivId: string,
  setCheckComponentHandler: (params: CheckComponentHandler) => void,
  handlerFunc: (event: string, data: $TSFixMe) => void
): void => {
  try {
    const handler = window.CheckComponent.create({
      link: componentLink,
      onEvent: (event, data) => {
        handlerFunc?.(event, data);
      },
    });
    handler.open();
    const componentElement = document.getElementById("check-component-embedded-div");
    if (componentElement) {
      const componentHolder = document.getElementById(componentDivId);
      componentHolder?.appendChild(componentElement);
    }

    setCheckComponentHandler({ handler });
  } catch (err) {
    console.error("Error loading Check component", err);
  }
};
