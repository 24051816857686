import { Message, Paginator } from "@twilio/conversations";
import { useChatAbilities } from "dashboard/hooks/abilities-hooks/useChatAbilities";
import { useActiveRecruitingConversation, useUser } from "dashboard/hooks/atom-hooks";
import {
  FILE_UPLOAD_ERRORS,
  GENERIC_MESSAGE_ERROR,
  handleFileUploadHelper,
  sendChatFileHelper,
  sendMessageHelper,
} from "dashboard/utils/chat";
import { PAGE_SIZE } from "dashboard/utils/constants";
import React, { FC, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../Chat.module.css";
import { Button, Loader, Notifier } from "ui";
import NoMessages from "../../../assets/empty-messages.svg";
import { cloneDeep } from "lodash";
import FileInput from "dashboard/components/shared/FileInput";
import { FaFileAlt, FaFolderOpen, FaPaperPlane, FaTimesCircle } from "react-icons/fa";
import { getUserLabel, truncateFilename } from "dashboard/utils";
import { useOrderedMessages } from "dashboard/hooks/chat/useOrderedMessages";
import { useRecruitingChatUtilities } from "dashboard/hooks/chat/useRecruitingChatUtilities";

type Props = {
  isInvidualChat?: boolean;
};

export const RecruitingConversationContainer: FC<Props> = ({ isInvidualChat }) => {
  /** Atom hooks */
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const messagesStateRef = useRef<Message[]>([]);
  const chatAbilities = useChatAbilities();
  const user = useUser();

  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messagePaginator, setMessagePaginator] = useState<Paginator<Message>>();
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const messageElements = useOrderedMessages({
    messages,
    participantSid: activeRecruitingConversation?.chat_participant_sid,
  });
  const { markConversationAsRead } = useRecruitingChatUtilities();

  const [loading, setLoading] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);

  const twilioConversation = activeRecruitingConversation?.twilio_conversation;

  /** Logical helpers */
  const updateMessagesState = (newMessages: Message[]) => {
    setMessages(newMessages);
    messagesStateRef.current = newMessages;
  };

  const handleMessageAdded = (message: Message) => {
    const localMessages = messagesStateRef.current;
    const isDuplicate = localMessages.some((m) => m.sid === message.sid);

    if (!isDuplicate) {
      const newMessages = cloneDeep([...localMessages, message]);
      updateMessagesState(newMessages);
    }
  };

  const handleMarkingConversationAsRead = () => {
    if (!activeRecruitingConversation) return;
    markConversationAsRead(activeRecruitingConversation);
  };

  const handleFileUpload = (files: FileList | null) => {
    if (!files) return;
    const error = handleFileUploadHelper(files);
    if (error) {
      return Notifier.error(FILE_UPLOAD_ERRORS[error] || "An error occurred while uploading files.");
    }
    setFiles(Array.from(files));
  };

  const handleRemoveFile = (file: File) => {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
  };

  // Appends the file data to a formData object and send it as a message via Twilio
  const handleSendFileMessage = async () => {
    if (!twilioConversation || !user) return;
    const { success } = await sendChatFileHelper({
      files,
      twilioConversation: twilioConversation!,
      userLabel: getUserLabel(user),
    });
    if (!success) {
      return Notifier.error(GENERIC_MESSAGE_ERROR);
    }

    setFiles([]);
  };

  const handleScrollToBottom = () => {
    if (!messagesContainerRef || !messagesContainerRef.current) return;
    messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!twilioConversation || !user) return;
    if (message.trim() === "" && files.length === 0) return;

    setSendingMessage(true);

    // Send files in the first message if there are files selected
    if (files.length > 0) {
      await handleSendFileMessage();
    }

    const { success } = await sendMessageHelper({
      message,
      twilioConversation: twilioConversation,
      userLabel: getUserLabel(user),
    });

    if (!success) {
      return Notifier.error(GENERIC_MESSAGE_ERROR);
    }

    setMessage("");
    handleScrollToBottom();
    setSendingMessage(false);
  };

  /** Twilio helpers */
  const getMessages = async () => {
    if (!twilioConversation) return;

    const response = await twilioConversation.getMessages(PAGE_SIZE);

    updateMessagesState(response.items);
    setMessagePaginator(response);
    setHasPrevPage(response.hasPrevPage);

    setLoading(false);
  };

  const getPrevMessages = async () => {
    if (!messagePaginator || !hasPrevPage) return;

    const response = await messagePaginator.prevPage();

    updateMessagesState([...response.items, ...messages]);
    setMessagePaginator(response);
    setHasPrevPage(response.hasPrevPage);
  };

  useEffect(() => {
    setLoading(true);
    updateMessagesState([]);
    getMessages();
  }, [activeRecruitingConversation?._id]);

  useEffect(() => {
    if (!twilioConversation) return;

    twilioConversation?.on("messageAdded", handleMessageAdded);
    return () => {
      twilioConversation.off("messageAdded", handleMessageAdded);
    };
  }, [activeRecruitingConversation?._id]);

  useEffect(() => {
    handleScrollToBottom();
  }, [activeRecruitingConversation?._id, twilioConversation?.sid, messagesContainerRef.current]);

  /** Render functions */

  const renderHeader = () => {
    return (
      <div className={styles["chat-subheader"] + " " + styles["conversation-subheader"]}>
        <h2 className={styles["chat-subheader-title"]}>Messages</h2>
      </div>
    );
  };

  const renderMessagesEmptyState = () => {
    return (
      <div className={styles["no-messages"]}>
        <img src={NoMessages} className={styles["no-messages-img"]} alt="Empty Messages" />
        <p>
          No messages yet. <br></br>Send a message below to get started.
        </p>
      </div>
    );
  };

  const renderMessages = () => {
    const heightClass = isInvidualChat ? styles["individual-recruiting-conversation-messages"] : "";
    return (
      <div
        ref={messagesContainerRef}
        id="scrollable"
        className={styles["conversation-messages"] + " " + heightClass}
        onClick={handleMarkingConversationAsRead}
      >
        <InfiniteScroll
          dataLength={messageElements.length}
          scrollableTarget={"scrollable"}
          hasMore={hasPrevPage}
          next={getPrevMessages}
          loader={!loading ? <Loader /> : <></>}
          inverse={true}
          style={{ display: "flex", flexDirection: "column-reverse" }}
        >
          {messageElements}
        </InfiniteScroll>
        {loading && <Loader style={{ marginTop: "auto", marginBottom: "auto" }} />}
        {!loading && messages.length === 0 && activeRecruitingConversation && renderMessagesEmptyState()}
      </div>
    );
  };

  const renderSelectedFiles = () => {
    if (files.length === 0) return;

    const fileElements = files.map((file) => {
      return (
        <div className={styles["selected-file"]} key={file.name + " " + file.lastModified}>
          <p className={styles["selected-file-name"]}>
            <FaFileAlt style={{ marginBottom: -2, marginRight: 7, color: "#4d54b6" }} />
            {truncateFilename(file.name, 30)}
          </p>
          <button className={styles["selected-file-remove"]} onClick={() => handleRemoveFile(file)}>
            <FaTimesCircle />
          </button>
        </div>
      );
    });

    return <div className={styles["conversation-files-for-upload"]}>{fileElements}</div>;
  };

  const renderNewMessageInput = () => {
    return (
      <textarea
        className={"form2-text " + styles["conversation-new-message-input"]}
        placeholder="Enter message"
        value={message}
        onChange={(e) => {
          e.target.style.height = "inherit";
          e.target.style.height = `${e.target.scrollHeight}px`;
          setMessage(e.target.value);
        }}
        maxLength={1000}
      ></textarea>
    );
  };

  const renderNewMessageContainer = () => {
    return (
      <form onClick={handleMarkingConversationAsRead} onSubmit={handleSendMessage}>
        <div className={styles["conversation-new-message"]}>
          <FileInput
            label={<FaFolderOpen />}
            onChange={handleFileUpload}
            multiple={true}
            className={"button-1 no-margin " + styles["conversation-upload-file-button"]}
          />
          <div className={styles["new-message-container"]}>
            {renderSelectedFiles()}
            {renderNewMessageInput()}
          </div>
          <Button
            className={
              "button-2 no-margin " +
              styles["conversation-new-message-button"] +
              " " +
              (sendingMessage ? styles["send-loader"] : "")
            }
            submit={true}
            loading={sendingMessage}
          >
            {!sendingMessage && <FaPaperPlane />}
          </Button>
        </div>
      </form>
    );
  };

  return (
    <div className={styles["conversation-container"] + " " + styles["recruiting-conversation-width"]}>
      {isInvidualChat ? null : renderHeader()}
      {renderMessages()}
      {chatAbilities.can("send", activeRecruitingConversation) && renderNewMessageContainer()}
    </div>
  );
};
