import { MiterAPI, MiterFilterArray, PayRateGroup, UnionReciprocityRule } from "dashboard/miter";
import { Plus, TrashSimple } from "phosphor-react";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { useActiveCompanyId, useLookupPrg, useLookupRateDifferential } from "dashboard/hooks/atom-hooks";
import { capitalize, Notifier } from "dashboard/utils";
import { UnionReciprocityRuleModal } from "./UnionReciprocityRuleModal";

type Props = {
  readonly: boolean;
  payRateGroup: PayRateGroup;
};

export const UnionReciprocityRulesTable: React.FC<Props> = ({ readonly, payRateGroup }) => {
  const [data, setData] = useState<UnionReciprocityRule[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [clickedRule, setClickedRule] = useState<UnionReciprocityRule | undefined>(undefined);
  const [selectedRules, setSelectedRules] = useState<UnionReciprocityRule[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const activeCompanyId = useActiveCompanyId();
  const prgLookup = useLookupPrg();
  const rateDifferentialLookup = useLookupRateDifferential();

  const staticActions: TableActionLink[] = [
    {
      label: "Add rule",
      className: "button-2 no-margin",
      action: () => setShowModal(true),
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => !readonly,
    },
  ];

  const getData = useCallback(async () => {
    const filter: MiterFilterArray = [
      { field: "company_id", value: activeCompanyId },
      { field: "home_pay_rate_group_id", value: payRateGroup._id },
    ];
    const res = await MiterAPI.union_reciprocity_rules.retrieve(filter);

    setData(res);
  }, [payRateGroup]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleRowClick = (row: UnionReciprocityRule) => {
    setClickedRule(row);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
    setClickedRule(undefined);
    getData();
  };

  const handleDelete = async () => {
    setLoading(true);
    await Promise.all(
      selectedRules.map(async (rule) => {
        try {
          const response = await MiterAPI.union_reciprocity_rules.archive(rule._id);
          if (response.error) {
            throw new Error(response.error);
          }
          Notifier.success("Successfully deleted rules.");
        } catch (e: $TSFixMe) {
          Notifier.error(e.message);
        }
      })
    );

    setLoading(false);
    setSelectedRules([]);
    getData();
  };

  const handleSelect = (selections: UnionReciprocityRule[]) => {
    setSelectedRules(selections);
  };

  const dynamicActions = [
    {
      label: "Delete",
      action: () => handleDelete(),
      className: "button-1 table-button",
      icon: <TrashSimple weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />,
      shouldShow: () => !readonly,
    },
  ];

  const ruleColumns: ColumnConfig<UnionReciprocityRule>[] = useMemo(
    () => [
      {
        field: "work_pay_rate_group_id",
        headerName: "Work pay rate group",
        dataType: "string",
        valueFormatter: (params) => {
          return prgLookup(params.data?.work_pay_rate_group_id)?.label || "-";
        },
      },
      {
        field: "pay_rate_selection",
        headerName: "Pay rate source",
        dataType: "string",
        width: 220,
        valueFormatter: (params) => {
          return capitalize(params.data?.pay_rate_selection) || "-";
        },
      },
      {
        field: "fringe_selection",
        headerName: "Fringe source",
        dataType: "string",
        valueFormatter: (params) => {
          return capitalize(params.data?.fringe_selection) || "-";
        },
      },
      {
        field: "rate_differential_id",
        headerName: "Rate differential",
        dataType: "string",
        width: 220,
        valueFormatter: (params) => {
          return rateDifferentialLookup(params.data?.rate_differential_id)?.label || "-";
        },
      },
    ],
    [prgLookup]
  );

  const renderTable = () => {
    return (
      <>
        {showModal && (
          <UnionReciprocityRuleModal
            hide={hideModal}
            homePrg={payRateGroup}
            rule={clickedRule}
            readonly={readonly}
          />
        )}
        <TableV2
          id={"reciprocity-rules-table"}
          resource="reciprocity rules"
          data={data}
          dynamicActions={dynamicActions}
          onSelect={handleSelect}
          defaultSelectedRows={selectedRules}
          columns={ruleColumns}
          staticActions={staticActions}
          onClick={handleRowClick}
          isLoading={loading}
        />
      </>
    );
  };

  return <div>{renderTable()}</div>;
};
