/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { AggregatedForm, Form, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Plus, TrashSimple } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasicModal } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";

export type FormTableRow = {};

const FormTable: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const { can, cannot } = useMiterAbilities();

  // States related to the table
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Form[]>([]);

  // States related to table actions
  const [archiving, setArchiving] = useState<boolean>(false);
  const [forms, setForms] = useState<AggregatedForm[]>();

  useEffect(() => {
    getForms();
  }, []);

  const getForms = async () => {
    try {
      if (!activeCompanyId) throw new Error("No active role found. Please refresh the page.");
      const forms = await MiterAPI.forms.search([
        { field: "company_id", value: activeCompanyId },
        { field: "parent_type", value: "company" },
      ]);
      if (forms.error) throw new Error(forms.error);

      // Filter out forms based on permissions
      const filteredForms = forms.filter((form) =>
        form.sensitive ? can("forms:forms:manage_sensitive") : can("forms:forms:manage")
      );

      setForms(filteredForms);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error fetching forms. We're looking into it.");
    }
  };

  const handleRowClick = (form) => {
    handleEdit(form);
  };

  const handleAdd = () => {
    navigate("/forms/new");
  };

  const handleEdit = (form) => {
    navigate("/forms/" + form._id);
  };

  const handleArchive = async () => {
    if (cannot("forms:forms:manage")) {
      Notifier.error("You do not have permission to delete forms.");
      return;
    }

    setLoading(true);
    try {
      for (const form of selectedRows) {
        const response = await MiterAPI.forms.delete(form._id);
        if (response.error) throw new Error(response.error);
      }

      const singPlur = selectedRows.length > 1 ? "Forms" : "Form";
      Notifier.success(singPlur + " successfully deleted.");

      setArchiving(false);
      setSelectedRows([]);
      getForms();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting one or more form. We're looking into it.");
    }
    setLoading(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add form",
        className: "button-2 no-margin",
        action: handleAdd,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("forms:forms:manage"),
      },
    ],
    [can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("forms:forms:manage"),
      },
    ],
    [can]
  );

  // Dynamically set columns based on whether any form have work classifications
  const columns: ColumnConfig<Form>[] = [
    {
      headerName: "Name",
      field: "name",
      dataType: "string",
    },
    {
      headerName: "Submissions",
      field: "submissions",
      dataType: "string",
      valueFormatter: (params) => {
        return params.value ? params.value.length : 0;
      },
    },
    {
      headerName: "Editable submissions",
      field: "allow_editable_submissions",
      dataType: "boolean",
    },
    {
      headerName: "Allow multiple submissions",
      field: "allow_multiple_submissions",
      dataType: "boolean",
    },
  ];

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={"forms-table"}
        resource="forms"
        data={forms}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
      />
    );
  };

  return (
    <div className="form-table-wrapper">
      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={handleArchive}
          headerText={"Delete form" + (selectedRows.length > 1 ? "s" : "")}
          bodyText={
            "Are you sure you want to delete the selected form" +
            (selectedRows.length > 1 ? "s" : "") +
            "? Deleting the form will remove it from all team members. To restore a deleted form, you must contact Miter Support."
          }
        />
      )}

      {renderTable()}
    </div>
  );
};

export default FormTable;
