import React, { useMemo } from "react";
import { ValueFormatterParams } from "ag-grid-community";
import { OnboardingItemsTable } from "./OnboardingItemsTable";
import { ColumnConfig } from "ui/table-v2/Table";
import { OnboardingItemTableRow } from "./OnboardingItemsTable";
import OnboardingCustomTaskModal from "./OnboardingCustomTaskModal";
import { OnboardingCustomTaskFormParams } from "./OnboardingCustomTaskModal";
import { CustomModalProps } from "./OnboardingCustomTaskModal";
import { Option } from "ui/form/Input";
import pluralize from "pluralize";

export type OnboardingCustomTaskTableRow = Pick<
  OnboardingItemTableRow,
  "_id" | "default_team_member_checklists" | "default_assignee"
> & {
  title: string;
  description?: string;
  due_days_from_start?: number;
};

export type OnboardingCustomTasksTableProps = {
  tableId: string;
  objectOptions: Option<string>[];
  resourceName: string;
  handleRemove: (selectedRows: (OnboardingItemTableRow | OnboardingCustomTaskTableRow)[]) => Promise<void>;
  handleUpdate: (row: OnboardingCustomTaskFormParams) => Promise<void>;
  handleCreate: (row: OnboardingCustomTaskFormParams) => Promise<void>;
  tableData: OnboardingCustomTaskTableRow[];
  columns?: ColumnConfig<OnboardingCustomTaskTableRow>[];
  modalComponent?: React.ComponentType<CustomModalProps>;
};

export const OnboardingCustomTasksTable: React.FC<OnboardingCustomTasksTableProps> = (props) => {
  const columns: ColumnConfig<OnboardingCustomTaskTableRow>[] = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        dataType: "string",
      },
      {
        field: "description",
        headerName: "Description",
        dataType: "string",
      },
      {
        field: "due_days_from_start",
        headerName: "Due (days from start)",
        dataType: "number",
        valueFormatter: (params: ValueFormatterParams<OnboardingCustomTaskTableRow>): string => {
          if (params.value == null) {
            return "-";
          }

          const value = params.value as number;
          if (value > 0) {
            return `${value} ${pluralize("day", value)} after start`;
          } else {
            const absValue = Math.abs(value);
            return `${absValue} ${pluralize("day", absValue)} before start`;
          }
        },
      },
    ],
    []
  );

  return <OnboardingItemsTable {...props} columns={columns} modalComponent={OnboardingCustomTaskModal} />;
};

export default OnboardingCustomTasksTable;
