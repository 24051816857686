import { AggregatedForm, Form } from "dashboard/miter";
import React, { useMemo } from "react";
import { ActionModal, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import FormQuestionAnswersTable from "./FormQuestionAnswersTable";

type FormQuestionTableEntry = Form["components"][number] & { index: number };

type Props = {
  form: AggregatedForm;
};

export const FormQuestionsTable: React.FC<Props> = ({ form }) => {
  const [activeFormQuestion, setActiveFormQuestion] = React.useState<FormQuestionTableEntry>();

  const fields = useMemo(
    () => form.components.filter((c) => c.type !== "section").map((c, i) => ({ ...c, index: i + 1 })),
    [form]
  );

  const columns = useMemo(() => {
    const cols: ColumnConfig<FormQuestionTableEntry>[] = [
      {
        field: "index",
        headerName: "#",
        dataType: "number",
        maxWidth: 100,
      },
      {
        field: "name",
        dataType: "string",
        headerName: "Question",
      },
      {
        field: "type",
        headerName: "Type",
        dataType: "string",
        displayType: "badge",
        colors: {
          text: "blue",
          number: "green",
          checkbox: "yellow",
          select: "purple",
          paragraph: "red",
          esignature: "orange",
        },
      },
      {
        field: "validations.required",
        headerName: "Required",
        dataType: "boolean",
      },
    ];

    return cols;
  }, [form]);

  const renderFormQuestionAnswersModal = () => {
    if (!activeFormQuestion) return;

    return (
      <ActionModal
        headerText={`Answers for question "${activeFormQuestion.name}"`}
        showCancel={true}
        cancelText="Close"
        onCancel={() => setActiveFormQuestion(undefined)}
        onHide={() => setActiveFormQuestion(undefined)}
        wrapperStyle={{ width: "80%" }}
        bodyStyle={{ minHeight: "60vh" }}
      >
        <FormQuestionAnswersTable form={form} formFieldId={activeFormQuestion._id} />
      </ActionModal>
    );
  };

  return (
    <>
      <TableV2
        id={"form-submission-questions-table"}
        resource="form questions"
        data={fields}
        columns={columns}
        staticActions={[]}
        dynamicActions={[]}
        onClick={setActiveFormQuestion}
      />
      {renderFormQuestionAnswersModal()}
    </>
  );
};
