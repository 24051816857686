import React, { useEffect, useState, useMemo } from "react";
import { Plus, Trash } from "phosphor-react";
import { Notifier, TableV2 } from "ui";
import { TableActionLink, ColumnConfig } from "ui/table-v2/Table";
import { useNavigate } from "react-router-dom";
import { JobPostingTableData, formatJobPostingsTableData } from "dashboard/utils/ats";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI, MiterFilterField } from "dashboard/miter";
import { ValueFormatterParams } from "ag-grid-community";
import CreateJobPosting from "./CreateJobPostingWizard";
import { useQuery } from "miter-utils";
import { useJobPostingAbilities } from "dashboard/hooks/recruiting/useJobPostingAbilities";
import { JobApplicationImporter } from "./JobApplicationImporter";
import { ImportHistory } from "../importer/ImportHistory";
import { useJobApplicationAbilities } from "dashboard/hooks/recruiting/useJobApplicationAbilities";

const columns: ColumnConfig<JobPostingTableData>[] = [
  {
    headerName: "Title",
    field: "title",
    dataType: "string",
  },
  {
    headerName: "Location",
    field: "workplace",
    valueFormatter: (params: ValueFormatterParams<JobPostingTableData>) => {
      const workplace = params?.data?.workplace;
      if (!workplace || workplace.work_type === "remote") {
        return "Remote";
      }
      return workplace.address?.city + ", " + workplace.address?.state;
    },
    dataType: "string",
  },
  {
    headerName: "Employment Type",
    field: "employment_type",
    dataType: "string",
    displayType: "badge",
    colors: {
      full_time: "green",
      part_time: "yellow",
      contract: "blue",
      intern: "orange",
    },
  },
  {
    headerName: "Posted At",
    field: "created_at",
    dataType: "date",
    dateType: "timestamp",
  },
  {
    headerName: "Active",
    field: "isActive",
    dataType: "boolean",
  },
];

export const JobPostingsTable: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const view = query.get("view");

  const jobPostingAbilities = useJobPostingAbilities();
  const jobApplicationAbilities = useJobApplicationAbilities();

  const [selectedRows, setSelectedRows] = useState<JobPostingTableData[]>([]);
  const [createJobPosting, setCreateJobPosting] = useState(false);
  const activeCompanyId = useActiveCompanyId();
  const [data, setData] = useState<JobPostingTableData[]>([]);
  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);

  const getData = async () => {
    const jobPostingFilters = jobPostingAbilities.filter("read");
    const allFilters = [{ field: "company_id", value: activeCompanyId }] as MiterFilterField[];
    if (jobPostingFilters) {
      allFilters.push(jobPostingFilters);
    }
    const res = await MiterAPI.job_postings.forage({
      filter: allFilters,
    });
    setData(formatJobPostingsTableData(res.data));
  };

  useEffect(() => {
    if (!createJobPosting) {
      getData();
    }
  }, [createJobPosting]);

  const togglerConfig = useMemo(() => {
    return {
      config: [
        { path: "active", label: "Active" },
        { path: "inactive", label: "Inactive" },
      ],
      path: view || "active",
      secondary: true,
      field: "status",
    };
  }, [view]);

  const handleRowClick = (jobPosting) => {
    navigate(`/recruiting/job-postings/${jobPosting._id}`);
  };

  const openJobPosting = () => {
    setCreateJobPosting(true);
  };

  const closeJobPosting = () => {
    setCreateJobPosting(false);
  };

  const handleArchive = async () => {
    if (!activeCompanyId) {
      Notifier.error("Unable to delete job postings.");
      return;
    }
    try {
      const responseData = await MiterAPI.job_postings.archive({
        ids: selectedRows.map((ts) => ts._id),
        company_id: activeCompanyId,
      });
      if (responseData.error) throw responseData.error;

      Notifier.success("Job postings successfully deleted.");
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was an error deleting job postings. We're looking into it.");
    }
    setSelectedRows([]);
    getData();
  };

  const onJobApplicationImport = () => {
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add job posting",
        className: "button-2 table-button",
        action: openJobPosting,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => jobPostingAbilities.can("create", undefined),
      },
      {
        key: "import",
        label: "Bulk import job applications",
        component: <JobApplicationImporter onFinish={onJobApplicationImport} />,
        shouldShow: () => jobApplicationAbilities.can("create", undefined),
      },
    ],
    [jobApplicationAbilities, jobApplicationAbilities]
  );

  const dynamicActions: TableActionLink[] = [
    {
      label: "Delete",
      className: "button-3 table-button",
      action: handleArchive,
      important: true,
      icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  /*********************************************************
    Functions to render table components
  **********************************************************/

  return (
    <div>
      <TableV2
        id={"job-postings-table"}
        resource="job postings"
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        columns={columns}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
        toggler={togglerConfig}
        ssr={false}
        data={data}
      />
      {createJobPosting && jobPostingAbilities.can("create", undefined) && (
        <CreateJobPosting onExit={closeJobPosting} />
      )}
      {showImportHistory && (
        <ImportHistory
          id={"job_application"}
          resource={"job applications"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
    </div>
  );
};
