import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, ConfirmModal, Notifier, Toggler, BasicModal, Formblock, usdString } from "ui";
import { MiterAPI } from "dashboard/miter";
import {
  CheckBankAccount,
  CheckContractorPayment,
  CheckItem,
  CheckPayment,
  CheckPaymentAttempt,
  CheckPayroll,
} from "backend/utils/check/check-types";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { capitalize } from "lodash";
import { sleep } from "dashboard/utils";
import PayrollContext from "./payrollContext";
import PaymentContext from "./PaymentModal/paymentContext";
import { Option } from "ui/form/Input";
import { useForm } from "react-hook-form";
import { RetryCheckPaymentBodyParams } from "backend/utils/check/check-types";
import { WireDetailsModal } from "./WireDetailsModal";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

type PaymentAttemptEntry = { _id: string; attemptNum: number } & CheckPaymentAttempt;

type CheckPaymentWithIndex = CheckPayment & { index: number };

export const ReviewCheckPayments: React.FC<{ entity: CheckPayroll | CheckItem | CheckContractorPayment }> = (
  props
) => {
  const { entity } = props;

  // CONTEXT
  const payrollContext = useContext(PayrollContext);
  const paymentContext = useContext(PaymentContext);

  const activeCompanyId = useActiveCompanyId();

  // STATE
  const [loading, setLoading] = useState(true);
  const [bankAccounts, setBankAccounts] = useState<CheckBankAccount[]>([]);
  const [payments, setPayments] = useState<CheckPaymentWithIndex[]>([]);
  const [payment, setPayment] = useState<CheckPaymentWithIndex>();
  const [toggleState, setToggleState] = useState<string | null>(null);
  const [refunding, setRefunding] = useState(false);
  const [showRefundConfirm, setShowRefundConfirm] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<string>();
  const [retryMethod, setRetryMethod] = useState<"wire" | "ach">();

  // FORM
  const form = useForm();

  // HELPERS
  const payroll = paymentContext.payroll || payrollContext.payroll;
  const { wireDetails, setWireDetails, showWireDetails, setShowWireDetails } = payrollContext;
  const entityIsPayroll = entity.id.startsWith("pay");
  const paymentStringForButton = payments.length > 1 && payment ? `payment ${payment.index}` : `payment`;
  const anyAch = !!payment?.payment_attempts.some((pa) => pa.payment_method === "ach");
  const lastestPaymentMethod = payment?.payment_attempts[0]?.payment_method;

  // TOGGLER
  const togglerConfig = useMemo(() => {
    return payments.map((p, i): TogglerConfigItem => {
      return {
        label: `Payment ${i + 1} - ${usdString(Number(p.amount))} - ${p.status}`,
        path: p.id,
      };
    });
  }, [payments]);

  const handleToggle = (newPayment: string | null) => {
    setPayment(payments.find((p) => p.id === newPayment));
    setToggleState(newPayment);
  };

  // TABLE DATA AND COLUMNS
  const tableData = useMemo(() => {
    if (!payment) return [];
    return payment.payment_attempts.map((pa, i, arr): PaymentAttemptEntry => {
      return {
        ...pa,
        _id: pa.id,
        attemptNum: arr.length - i,
      };
    });
  }, [payment]);

  const anyDirectDepositsFailed = useMemo(() => {
    for (const payment of payroll?.miter_payments || []) {
      const checkItemCp = payment.check_item || payment.check_cp;
      if (checkItemCp?.payment_method === "direct_deposit" && checkItemCp.status === "failed") {
        return true;
      }
    }
    return false;
  }, [payroll]);

  const columns = useMemo(() => {
    let expectedDateTooltip = `This is the date on which the payment attempt is expected to land or "settle." For ACH payments, this is the projected date in which the payment should land.`;
    if (entityIsPayroll) {
      expectedDateTooltip += ` For wire payments, this is the earliest date Miter is expected to receive the incoming wire. Note that for re-debit attempts via ACH, you will be blocked from running payroll until 3 banking days after this date to ensure that the re-debited payment does not fail.`;
    }

    const ccs: ColumnConfig<PaymentAttemptEntry>[] = [
      { field: "attemptNum", headerName: "Attempt", dataType: "number", maxWidth: 120 },
      {
        field: "payment_method",
        headerName: "Method",
        valueGetter: (params) => {
          return params.data?.payment_method === "ach" ? "ACH" : capitalize(params.data?.payment_method);
        },
        maxWidth: 100,
      },
      {
        field: "ach_details.ach_trace_id",
        headerName: "Trace ID",
        dataType: "string",
        width: 100,
      },
      {
        field: "expected_completion_date",
        headerName: "Expected date",
        headerTooltip: expectedDateTooltip,
        dataType: "date",
      },
      {
        field: "payment_instrument",
        headerName: "Bank Account",
        valueGetter: (params) => {
          const acct = bankAccounts.find((a) => a.id === params.data?.payment_instrument);
          return params.data?.payment_method === "ach" ? formatBankAccountLabel(acct) : "N/A";
        },
      },
      {
        field: "status",
        headerName: "Status",
        valueGetter: (params) => {
          return params.data?.payment_method === "wire" && params.data?.status === "processing"
            ? "Not received"
            : params.data?.status;
        },
        displayType: "badge",
        colors: { "Not received": "yellow" },
        maxWidth: 120,
      },
      {
        field: "failure_code",
        headerName: "Failure reason",
        valueGetter: (params) => {
          const pa = params.data;
          let failureCodeClean = "N/A";
          if (pa?.status === "failed") {
            if (pa?.failure_code && pa.payment_method !== "manual") {
              failureCodeClean = capitalize(pa.failure_code.replaceAll("_", " "));
            } else if (!pa.failure_code) {
              failureCodeClean = payroll?.has_ever_failed && !entityIsPayroll ? "Payroll funding" : "Unknown";
            }
          }
          return failureCodeClean;
        },
      },
    ];
    return ccs;
  }, [entityIsPayroll, bankAccounts, payroll]);

  // API CALLS
  const getCheckPayments = async () => {
    setLoading(true);
    try {
      let bankAcctEntityId: string;
      if (entityIsPayroll && "company" in entity) {
        bankAcctEntityId = entity.company;
      } else if ("employee" in entity) {
        bankAcctEntityId = entity.employee;
      } else if ("contractor" in entity) {
        bankAcctEntityId = entity.contractor;
      } else {
        console.log("Entity:", entity);
        throw new Error("No valid bank account entity");
      }
      const [paymentsRes, bankAcctRes] = await Promise.all([
        MiterAPI.check.payments.list(entity.id),
        MiterAPI.check.bank_accounts.list(bankAcctEntityId),
      ]);
      if (paymentsRes.error) throw new Error(paymentsRes.error);
      if (bankAcctRes.error) throw new Error(bankAcctRes.error);
      const nonDraftPayments = paymentsRes
        .filter((p) => p.status !== "draft")
        .map((p, i) => ({ ...p, index: i + 1 }));
      setPayments(nonDraftPayments);
      setPayment(nonDraftPayments[0]);
      setToggleState(nonDraftPayments[0]?.id || null);
      setBankAccounts(bankAcctRes);
    } catch (err) {
      console.error(err);
      Notifier.error("Error retrieving funds transfer data");
    }
    setLoading(false);
  };

  const handleRefund = async (paymentId: string) => {
    setRefunding(true);
    try {
      const res = await MiterAPI.check.payments.refund(paymentId);
      if (res.error) throw new Error(res.error);
      await sleep(7000); // wait for webhook to come that changes payroll
      getCheckPayments();
      payrollContext.getPayroll();
    } catch (err) {
      console.error(err);
      Notifier.error("Error refunding this payment");
    }
    setRefunding(false);
    setShowRefundConfirm(false);
  };

  const handleRetry = async (paymentId: string) => {
    if (!activeCompanyId) {
      Notifier.error("Error refunding this payment");
      return;
    }
    const params: RetryCheckPaymentBodyParams = { company_id: activeCompanyId };
    if (retryMethod === "wire") {
      params.use_wire = true;
    } else if (selectedAccount) {
      params.bank_account = selectedAccount;
    } else {
      return;
    }
    setRetrying(true);
    try {
      const res = await MiterAPI.check.payments.retry(paymentId, params);
      if (res.error) throw new Error(res.error);
      getCheckPayments();
      const wd = res.payment_attempts[0]?.wire_details;
      if (wd) {
        setWireDetails(wd);
        setShowWireDetails(true);
      }
    } catch (err) {
      console.error(err);
      Notifier.error("Error retrying this payment");
    }
    setShowRetryModal(false);
    setRetrying(false);
  };

  // EFFECTS
  useEffect(() => {
    if (!payroll || ["draft", "pending"].includes(payroll.check_payroll.status)) {
      setLoading(false);
    } else {
      getCheckPayments();
    }
  }, [paymentContext.payment]);

  useEffect(() => {
    if (!payment || !entityIsPayroll) return;
    const wdPa = payment.payment_attempts.find((pa) => pa.wire_details);
    if (wdPa) {
      setWireDetails(wdPa.wire_details);
      if (wdPa.status !== "paid") setShowWireDetails(true);
    }
  }, [payroll, payment, entityIsPayroll]);

  // OTHER
  const hideRetryModal = () => {
    setShowRetryModal(false);
    setSelectedAccount(undefined);
    setRetryMethod(undefined);
  };

  const bankAccountOptions = useMemo(() => {
    return bankAccounts
      .filter((acct) => {
        if (entityIsPayroll) {
          return acct.status === "ownership_verified";
        } else {
          return !acct.status.includes("disabled");
        }
      })
      .map((acct): Option<string> => {
        return { label: formatBankAccountLabel(acct), value: acct.id };
      });
  }, [bankAccounts]);

  // HELPER RENDERERS
  const renderBankAccountSelector = () => (
    <Formblock
      type="select"
      options={bankAccountOptions}
      editing
      form={form}
      name="bank_account"
      onChange={(o) => setSelectedAccount(o.value)}
      defaultValue={selectedAccount}
    />
  );

  const renderNonPayrollRetryModalBody = () => (
    <>
      <div className="yellow-text-container">
        <span>
          {"Select one of the team member's bank accounts below to retry this payment via direct deposit."}
          <br />
          <br />
          {"If no options appear below, please add a new bank account to the team member's profile."}
          <br />
          <br />
          {"Please note this method can take 3-5 business days to complete and will likely delay the payday."}
        </span>
      </div>
      <div className="vertical-spacer" />
      {renderBankAccountSelector()}
    </>
  );

  const renderPayrollRetryModalBody = () => (
    <>
      <div className="yellow-text-container">
        Choose whether to fund this payroll by sending a wire or retrying an ACH payment. Until the new
        payment lands,{" "}
        <strong>
          you will not be able to run payroll
          {anyDirectDepositsFailed
            ? ", and no failed team member direct deposits will be paid for this payroll."
            : "."}
        </strong>
        <br />
        <br />
        We <strong>strongly recommend sending a wire</strong> as it will land in a matter of hours, while
        another ACH attempt will take a minimum of 3 banking days to land and may fail again.
      </div>
      <div className="vertical-spacer-small" />
      <Formblock
        type="select"
        options={[
          { label: "Send a wire (recommended)", value: "wire" },
          { label: "Retry ACH payment", value: "ach" },
        ]}
        editing
        form={form}
        name="retry_method"
        onChange={(o) => setRetryMethod(o.value)}
        defaultValue={retryMethod}
      />
      {retryMethod === "ach" && (
        <>
          <div className="vertical-spacer-medium" />
          <div className="yellow-text-container">
            Select one of the company bank accounts below to retry this payment via ACH. If no options appear
            below, please add a new bank account to your company profile via Plaid.
            <br />
            <br />
            Again, please note this method will take at least 3 banking days to complete and{" "}
            <strong>will block payroll in the interim.</strong>
          </div>
          <div className="vertical-spacer-small" />
          {renderBankAccountSelector()}
        </>
      )}
      {retryMethod === "wire" && (
        <>
          <div className="vertical-spacer-medium" />
          <div className="yellow-text-container">
            This method takes less than one business day but your bank may charge a small wire fee.{" "}
            <strong>It is your responsibility to authorize the wire.</strong> Once you click {`"Retry"`}, you
            will see account and routing number information that you should use when directing your bank to
            send the wire.
          </div>
        </>
      )}
    </>
  );

  const renderRetryModal = () => {
    if (!payment) return null;
    const disableFinalRetryButton = entityIsPayroll
      ? !retryMethod || (retryMethod === "ach" && !selectedAccount)
      : !selectedAccount;

    return (
      <BasicModal
        headerText={`Retry ${paymentStringForButton} - ${usdString(Number(payment.amount))}`}
        button1Text="Cancel"
        button1Action={hideRetryModal}
        button2Text="Retry"
        button2Disabled={disableFinalRetryButton}
        button2Action={() => handleRetry(payment.id)}
        button2Loading={retrying}
        wrapperStyle={{ width: "400px" }}
      >
        {entityIsPayroll ? renderPayrollRetryModalBody() : renderNonPayrollRetryModalBody()}
      </BasicModal>
    );
  };

  const handleRowClick = (entry: PaymentAttemptEntry) => {
    if (entry.wire_details && entry.status !== "paid") {
      setWireDetails(entry.wire_details);
      setShowWireDetails(true);
    }
  };

  const renderRefundBody = () => (
    <span>
      Refunding this payment will return the funds to the company bank account so that the team member can be
      paid by paper check.{" "}
      <strong>It may take up to 5 banking days for the refund to hit your account.</strong>
    </span>
  );

  return (
    <div>
      {payments.length > 1 && (
        <Toggler
          className="modal-with-tabs"
          config={togglerConfig}
          active={toggleState}
          toggle={handleToggle}
          secondary
        />
      )}
      <TableV2
        id="check-payments"
        resource={entityIsPayroll ? "fundings" : "direct deposits"}
        data={tableData}
        columns={columns}
        isLoading={loading}
        onClick={handleRowClick}
        rowClickDisabled={(entry) => !entry.wire_details || entry.status === "paid"}
        hideSearch
        hideFooter
        hideSecondaryActions
        containerStyle={{ paddingBottom: 30 }}
      />
      {!loading && payment && (
        <div className="flex">
          {anyAch && (
            <Button
              className="button-2"
              text={`Retry ${paymentStringForButton}`}
              disabled={
                !payment.can_retry ||
                refunding ||
                (payment.status === "processing" && lastestPaymentMethod === "wire")
              }
              onClick={() => setShowRetryModal(true)}
              loading={retrying}
            />
          )}
          {!entityIsPayroll && (
            <Button
              className="button-1"
              text={`Refund ${paymentStringForButton}`}
              disabled={!payment.can_refund || retrying}
              onClick={() => setShowRefundConfirm(true)}
              loading={refunding}
            />
          )}
        </div>
      )}
      {payment && showRefundConfirm && (
        <ConfirmModal
          body={renderRefundBody()}
          yellowBodyText
          onNo={() => setShowRefundConfirm(false)}
          onYes={() => handleRefund(payment.id)}
          loading={refunding}
        />
      )}
      {showRetryModal && renderRetryModal()}
      {showWireDetails && (
        <WireDetailsModal wireDetails={wireDetails} hide={() => setShowWireDetails(false)} />
      )}
    </div>
  );
};

const formatBankAccountLabel = (bankAcct: CheckBankAccount | undefined): string => {
  if (bankAcct?.raw_bank_account) {
    const ba = bankAcct.raw_bank_account;
    return `${ba.institution_name} - ${ba.account_number_last_four}`;
  } else if (bankAcct?.plaid_bank_account) {
    const ba = bankAcct.plaid_bank_account;
    return `${ba.institution_name} - ${ba.mask}`;
  }
  return "Unknown";
};
