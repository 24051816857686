import AppContext from "dashboard/contexts/app-context";
import { MiterIntegrationForCompany } from "dashboard/miter";
import { partition } from "lodash";
import { useContext, useMemo } from "react";
import { Option } from "ui/form/Input";
import { createObjectMap } from "./utils";

type UseEmployeeNavigator = {
  enIntegration?: MiterIntegrationForCompany;
  hasEnIntegration: boolean;
  enBenefitDeductionCodeOptions: Option<string>[];
  enPTDDeductionCodeOptions: Option<string>[];
  enDeductionCodesOptionsMap?: Record<string, Option<string>>;
};

export const EN_POST_TAX_DEDUCTION_OPTIONS = [
  {
    value: "miscellaneous",
    label: "Miscellaneous (post-tax)",
  },
];

export const useEmployeeNavigator = (): UseEmployeeNavigator => {
  const { integrations } = useContext(AppContext);

  const enIntegration = useMemo(() => {
    return integrations.find(
      (i) => i.key === "employee_navigator" && i.connection && !i.connection.pending_setup
    );
  }, [integrations]);

  const enDeductionCodeOptions = useMemo(() => {
    const deductionCodeMap = enIntegration?.connection?.metadata?.employee_navigator?.deductionCodesMap;
    return Object.keys(deductionCodeMap || {}).map((key) => ({
      label: key,
      value: key,
    }));
  }, [enIntegration]);

  const ptdValues = EN_POST_TAX_DEDUCTION_OPTIONS.map((ptd) => ptd.value);

  const [enPTDDeductionCodeOptions, enBenefitDeductionCodeOptions] = useMemo(() => {
    const deductionCodeMap = enIntegration?.connection?.metadata?.employee_navigator?.deductionCodesMap;
    return partition(enDeductionCodeOptions, (dc) => {
      const ptdType = deductionCodeMap?.[dc.value];
      return ptdType && ptdValues.includes(ptdType);
    });
  }, [enIntegration]);

  const enDeductionCodesOptionsMap = useMemo(
    () => createObjectMap(enDeductionCodeOptions, (dc) => dc.value),
    [enDeductionCodeOptions]
  );

  return {
    enIntegration,
    hasEnIntegration: !!enIntegration,
    enBenefitDeductionCodeOptions,
    enPTDDeductionCodeOptions,
    enDeductionCodesOptionsMap,
  };
};
