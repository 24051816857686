import { useActiveCompany, useRefetchTeam, useUser } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { AggregatedOnboardingChecklistItem } from "dashboard/types/onboarding-types";
import { OnboardingTaskRowWithAssignee, useFormatToDashboardTasks } from "dashboard/utils/onboarding";
import { OnboardingChecklistWizard } from "miter-components";
import { convertOnboardingChecklistItem } from "miter-utils";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, DeleteModal, Label, Loader, Notifier, TableV2 } from "ui";
import ChecklistSelectionModal from "./ChecklistSelectionModal";
import { ColumnConfig } from "ui/table-v2/Table";
import { filterTasksByAssignee, filterTasksByCompletableStatus } from "miter-components/onboarding/utils";
import { ChecklistTeamMemberLander } from "./ChecklistTeamMemberLander";

type Props = {
  teamMember: AggregatedTeamMember;
};

export const TeamMemberOnboarding: FC<Props> = ({ teamMember }) => {
  const [onboardingChecklist, setOnboardingChecklist] = useState<AggregatedOnboardingChecklistItem | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const activeUser = useUser();
  const company = useActiveCompany();
  const [showWizard, setShowWizard] = useState(false);
  const [changeChecklistModal, setChangeChecklistModal] = useState(false);
  const [archiveChecklistModal, setArchiveChecklistModal] = useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState<number>(0);
  const { formatToDashboardTasks } = useFormatToDashboardTasks();
  const refetchTeamMember = useRefetchTeam();

  const openChecklistWizard = () => {
    if (convertOnboardingChecklistItem(onboardingChecklist, activeUser?._id, true).length === 0) {
      Notifier.error("You are not assigned to any tasks in this checklist.");
      return;
    }
    setShowWizard(true);
  };

  const onExitChecklistWizard = () => {
    setShowWizard(false);
    getOnboardingChecklist();
    setSelectedTaskIndex(0);
    refetchTeamMember(teamMember._id);
  };

  const goToTask = (task: OnboardingTaskRowWithAssignee) => {
    if (task.assignee.user_id !== activeUser?._id) {
      Notifier.error("You are not assigned to complete this task");
      return;
    }
    if (task.prerequisites.length > 0) {
      Notifier.error(
        "The following must be completed before starting this task: " + task.prerequisites.join(", ") + "."
      );
      return;
    }
    // In the OnboardingChecklistWizard, we filter by both the assignee and also do NOT show tasks that can't be completed.
    // So when deciding the index, we need to use a similarly filtered array.
    const tasksFilteredByAssigneeAndCompletability = filterTasksByCompletableStatus(
      filterTasksByAssignee(tasks, activeUser?._id || "", onboardingChecklist?.new_hire.user || ""),
      onboardingChecklist?.statuses || []
    );

    const taskIndex = tasksFilteredByAssigneeAndCompletability.findIndex((t) => t._id === task._id);

    setSelectedTaskIndex(Math.max(taskIndex, 0));
    openChecklistWizard();
  };

  const openChangeChecklistModal = () => {
    setChangeChecklistModal(true);
  };

  const closeChangeChecklistModal = () => {
    setChangeChecklistModal(false);
  };

  const openArchiveChecklistModal = () => {
    setArchiveChecklistModal(true);
  };

  const closeArchiveChecklistModal = () => {
    setArchiveChecklistModal(false);
  };

  const getOnboardingChecklist = async () => {
    try {
      setLoading(true);
      const res = await MiterAPI.onboarding_checklist_items.list([
        {
          field: "team_member_id",
          value: teamMember._id,
        },
        {
          field: "archived",
          value: false,
        },
      ]);

      if (res.error) throw new Error(res.error);

      setOnboardingChecklist(res[0] || null);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error fetching the onboarding checklist. We're looking into it.");
    }
    setLoading(false);
  };

  const onExit = () => {
    getOnboardingChecklist();
    closeChangeChecklistModal();
    closeArchiveChecklistModal();
  };

  useEffect(() => {
    getOnboardingChecklist();
  }, []);

  const tasks = useMemo(() => {
    if (!activeUser || !onboardingChecklist) return [];

    const tasksWithoutAssignee = convertOnboardingChecklistItem(onboardingChecklist, activeUser?._id, false);

    // TODO: this needs fixing, role assignee's names are no longer being displayed
    return formatToDashboardTasks(tasksWithoutAssignee, onboardingChecklist.new_hire.user);
  }, [onboardingChecklist, activeUser]);

  const primaryActions = [
    {
      label: "Switch checklist",
      action: openChangeChecklistModal,
      important: false,
      className: "button-1 table-button",
    },
    {
      label: "Delete checklist",
      action: openArchiveChecklistModal,
      important: true,
      className: "button-3 table-button",
    },
    ...(tasks.length > 0
      ? [
          {
            label: "Complete tasks",
            action: openChecklistWizard,
            important: true,
            className: "button-2 table-button",
          },
        ]
      : []),
  ];

  const handleSendReminder = async (task: OnboardingTaskRowWithAssignee) => {
    try {
      if (!onboardingChecklist?._id) throw new Error("No checklist found");
      const assigneeId =
        task.assignee.type === "new_hire" ? onboardingChecklist.new_hire.user : task.assignee.user_id;
      if (!assigneeId) throw new Error("No assignee found");
      const res = await MiterAPI.onboarding_checklist_items.remind_of_task({
        assignee_user_id: assigneeId,
        checklist_item_id: onboardingChecklist._id,
        task_id: task._id,
        new_hire_user_id: onboardingChecklist.new_hire.user,
      });
      if (res.error) throw new Error(res.error);
      Notifier.success("Reminder sent!");
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error sending the reminder. We are looking into it!");
    }
  };

  const handleArchive = async () => {
    try {
      if (!onboardingChecklist?._id) throw new Error("No checklist found");
      const res = await MiterAPI.onboarding_checklist_items.archive(onboardingChecklist._id);
      if (res.error) throw new Error(res.error);
    } catch (e: $TSFixMe) {
      Notifier.error("There was an error deleting the checklist: " + e.message);
    }
    onExit();
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      dataType: "component",
      cellRenderer: (params) =>
        params.data.prerequisites.length ? (
          <Label
            labelInfo={
              "Not completable because following tasks are incomplete: " +
              params.data.prerequisites.join(", ") +
              "."
            }
            label={params.data.title}
            underlineTooltip={true}
            tooltipPlace="right"
          ></Label>
        ) : (
          params.data.title
        ),
    },
    {
      field: "status",
      headerName: "Status",
      displayType: "badge" as const,
      colors: {
        complete: "green",
        incomplete: "red",
      },
    },

    { field: "assigneeName", headerName: "Assignee" },

    {
      field: "due_date",
      headerName: "Due",
      dataType: "date" as const,
      dateType: "iso" as const,
    },

    {
      field: "actions",
      headerName: "Actions",
      dataType: "component",
      disableCellClick: true,
      cellRenderer: (params) => {
        if (params.data.status === "complete") return null;
        return <Button text={"Send reminder"} onClick={() => handleSendReminder(params.data)} />;
      },
    },
  ] as ColumnConfig<OnboardingTaskRowWithAssignee>[];

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {onboardingChecklist && teamMember ? (
        <TableV2
          title="Tasks"
          resource="onboarding tasks"
          id="onboarding-checklist-table"
          ssr={false}
          data={tasks}
          columns={columns}
          staticActions={primaryActions}
          onClick={goToTask}
        />
      ) : teamMember ? (
        <ChecklistTeamMemberLander
          onExit={onExit}
          setChangeChecklistModal={setChangeChecklistModal}
          teamMember={teamMember}
        />
      ) : null}
      {showWizard && activeUser && onboardingChecklist && (
        <OnboardingChecklistWizard
          company={company}
          checklistItem={onboardingChecklist}
          getOnboardingChecklistItem={getOnboardingChecklist}
          onComplete={onExitChecklistWizard}
          onExit={onExitChecklistWizard}
          assigneeUserId={activeUser?._id}
          startIndex={selectedTaskIndex}
        />
      )}

      {changeChecklistModal && (
        <ChecklistSelectionModal
          onboardingChecklistItem={onboardingChecklist}
          onExit={onExit}
          teamMember={teamMember}
        />
      )}

      {archiveChecklistModal && (
        <DeleteModal
          header={"Are you sure?"}
          body={
            <div>This team member&apos;s copy of the onboarding checklist will be permanently erased.</div>
          }
          cancelText={"Cancel"}
          onHide={onExit}
          deleteText={"Yes, delete checklist"}
          onDelete={handleArchive}
          loading={loading}
        />
      )}
    </div>
  );
};
