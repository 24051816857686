import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useEffect, useMemo, useState } from "react";
import Select, { ValueType } from "react-select";
import { ActionModal, SelectOption } from "ui";
import { styles as SelectStyles } from "ui/form/styles";
import styles from "../Chat.module.css";
import { useActiveCompanyId, useSetRecruitingChatRestart } from "dashboard/hooks/atom-hooks";
import { Candidate } from "dashboard/types/ats";

type Props = {
  onExit: () => void;
};

export const RecruitingCreateConversationModal: React.FC<Props> = ({ onExit }) => {
  const setRecruitingChatRestart = useSetRecruitingChatRestart();
  const activeCompanyId = useActiveCompanyId();
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const forageCandidates = async () => {
    try {
      setLoading(true);
      const res = await MiterAPI.candidates.forage({
        filter: [
          {
            field: "company_id",
            value: activeCompanyId,
          },
        ],
      });
      setCandidates(res.data);
    } catch (e: $TSFixMe) {}
    setLoading(false);
  };

  useEffect(() => {
    if (!activeCompanyId) return;
    forageCandidates();
  }, [activeCompanyId]);

  const createConversation = async () => {
    setLoading(true);
    try {
      if (!selectedCandidate) throw new Error("Please select candidate");

      const res = await MiterAPI.chat.recruiting.create(activeCompanyId!, selectedCandidate);
      if (res.error) throw new Error(res.error);

      onExit();
      setRecruitingChatRestart((prev) => prev + 1);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const candidateOptions = useMemo(() => {
    return candidates.map((candidate) => ({
      value: candidate._id,
      label: candidate.first_name + " " + candidate.last_name,
    }));
  }, [candidates.length]);

  const handleCandidateSelect = (value?: string | null) => {
    setSelectedCandidate(value || null);
  };

  return (
    <>
      <ActionModal
        headerText={"Create a conversation"}
        submitText={"Create conversation"}
        onSubmit={createConversation}
        showSubmit={true}
        showCancel={true}
        cancelText={"Cancel"}
        onCancel={onExit}
        onHide={onExit}
        loading={loading}
      >
        <div className={styles["select-team-members"]}>
          <p className={styles["select-info-text"]}>Select a candidate</p>
          <Select
            name="team_member"
            options={candidateOptions}
            width={"100%"}
            zIndex={10}
            onChange={(option: ValueType<SelectOption, false>) => handleCandidateSelect(option?.value)}
            menuPortalTarget={document.body}
            menuPlacement="auto"
            height="40px"
            // @ts-expect-error - react-select types are not up to date
            styles={SelectStyles}
          />
        </div>
      </ActionModal>
    </>
  );
};
