import { CaretDown, CaretUp, Pencil, Trash, X } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Formblock, Label } from "ui";
import styles from "./TimeOffPolicies.module.css";

import * as vals from "dashboard/utils/validators";
import { AnimatePresence, motion } from "framer-motion";
import { useQuery } from "dashboard/utils";
import { useForm, useWatch } from "react-hook-form";
import { Assign } from "utility-types";
import { cloneDeep } from "lodash";
import { TimeOffPolicyLevelConfig } from "dashboard/miter";
import { useHasAccessToTimeOffMaxUsageLimits } from "dashboard/gating";

type TimeOffPolicyAccrualType = "unlimited" | "fixed" | "hourly";

const ACCRUAL_CONFIG_OPTIONS: { label: string; value: TimeOffPolicyAccrualType }[] = [
  { label: "Unlimited", value: "unlimited" },
  { label: "Fixed", value: "fixed" },
  { label: "Hourly", value: "hourly" },
];

const AUTO_ENROLLMENT_CARRYOVER_OPTIONS: { label: string; value: "lose" | "replace" | "add" }[] = [
  {
    value: "lose",
    label: "Lose existing balance and use default starting balance",
  },
  {
    value: "replace",
    label: "Keep existing balance",
  },
  {
    value: "add",
    label: "Add existing balance to default starting balance",
  },
];

const ACCRUAL_CADENCE_OPTIONS = [
  { label: "split up weekly", value: "weekly" },
  { label: "as a lump sum", value: "yearly" },
];

const ACCRUAL_DATE_TYPE_OPTIONS = [
  { label: "Employee work anniversary", value: "start_date" },
  { label: "Custom date", value: "custom" },
];

const ACCRUAL_BASIS_OPTIONS = [
  { label: "Hours worked  - only includes hours worked", value: "hours_worked" },
  { label: "All hours paid - includes all hours worked, time off, and holidays", value: "hours_paid" },
];

const OVERTIME_HOURS_OPTIONS = [
  { label: "Include overtime hours", value: "yes" },
  { label: "Don't include overtime hours", value: "no" },
];

type LevelForm = {
  name?: string;
  unpaid?: boolean;
  accrualType?: { label: string; value: "unlimited" | "fixed" | "hourly" };
  accrual_config?: Assign<
    NonNullable<TimeOffPolicyLevelConfig["accrual_config"]>,
    {
      fixed?: Assign<
        NonNullable<NonNullable<TimeOffPolicyLevelConfig["accrual_config"]>["fixed"]>,
        {
          cadence: {
            label: string;
            value: NonNullable<
              NonNullable<NonNullable<TimeOffPolicyLevelConfig["accrual_config"]>["fixed"]>["cadence"]
            >;
          };
          accrual_date_type?: {
            label: string;
            value: NonNullable<
              NonNullable<
                NonNullable<TimeOffPolicyLevelConfig["accrual_config"]>["fixed"]
              >["accrual_date_type"]
            >;
          };
        }
      >;
      hourly?: Assign<
        NonNullable<NonNullable<TimeOffPolicyLevelConfig["accrual_config"]>["hourly"]>,
        {
          count_hours_paid?: "hours_worked" | "hours_paid";
          count_overtime: "yes" | "no";
        }
      >;
      carryover_date_type?: {
        label: string;
        value: NonNullable<
          NonNullable<NonNullable<TimeOffPolicyLevelConfig["accrual_config"]>>["carryover_date_type"]
        >;
      };
    }
  >;

  min_tenure_for_requests?: number | null;
  min_tenure_for_accruals?: number | null;
  max_usage_yearly_limit?: number | null;
  disable_negative_balances?: boolean;
  auto_enrollment?: Assign<
    NonNullable<TimeOffPolicyLevelConfig["auto_enrollment"]>,
    {
      carryover_type?: {
        label: string;
        value: NonNullable<
          NonNullable<NonNullable<TimeOffPolicyLevelConfig["auto_enrollment"]>>["carryover_type"]
        >;
      };
    }
  > | null;

  enable_fringe_offset?: boolean;

  default_starting_balance?: number | null;

  max_accruable_balance?: number | null;
  max_accrued_hours_per_year?: number | null;
};

type Props = {
  policyLevel: TimeOffPolicyLevelConfig;
  timeOffPolicyId?: string;
  editingLevel: (levelId: string, isEditing: boolean) => void;
  onSave: (levelConfig: TimeOffPolicyLevelConfig) => void;
  onDelete: (id: string) => void;
  closeLevel?: number;
};

export const TimeOffPolicyLevelConfigForm: React.FC<Props> = ({
  policyLevel,
  timeOffPolicyId,
  editingLevel,
  onSave,
  onDelete,
  closeLevel,
}) => {
  const hasAccessToTimeOffMaxUsageLimits = useHasAccessToTimeOffMaxUsageLimits();
  const query = useQuery();
  const queryLevel = query.get("levelId");
  const section = query.get("section");

  const [expanded, setExpanded] = useState(!timeOffPolicyId);
  const [editing, setEditing] = useState(queryLevel === policyLevel._id);

  const basicSettings = useRef<HTMLDivElement>(null);
  const accrualSettings = useRef<HTMLDivElement>(null);
  const balanceSettings = useRef<HTMLDivElement>(null);
  const carryOverSettings = useRef<HTMLDivElement>(null);
  const advancedSettings = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (queryLevel === policyLevel._id) {
      handleEdit();
      if (section === "basicSettings") {
        basicSettings.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (section === "accrualSettings") {
        accrualSettings.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (section === "balanceSettings") {
        balanceSettings.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (section === "carryoverSettings") {
        carryOverSettings.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (section === "advancedSettings") {
        advancedSettings.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [
    section,
    basicSettings.current,
    accrualSettings.current,
    carryOverSettings.current,
    advancedSettings.current,
  ]);

  useEffect(() => {
    closeLevel && setExpanded(false);
  }, [closeLevel]);

  const buildForm = (policyLevel?: TimeOffPolicyLevelConfig): LevelForm => {
    if (!policyLevel) return { auto_enrollment: null };

    let accrualType: TimeOffPolicyAccrualType;
    if (policyLevel.accrual_config?.fixed) {
      accrualType = "fixed";
    } else if (policyLevel.accrual_config?.hourly) {
      accrualType = "hourly";
    } else if (policyLevel.unlimited) {
      accrualType = "unlimited";
    } else {
      console.log("Could not determine accrual type for time off policy");
    }

    let accrualConfig: $TSFixMe = cloneDeep(policyLevel.accrual_config);
    if (accrualConfig?.fixed?.cadence) {
      accrualConfig.fixed.cadence = ACCRUAL_CADENCE_OPTIONS.find(
        (o) => o.value === accrualConfig.fixed.cadence
      );
    }
    if (accrualConfig?.carryover_date_type) {
      accrualConfig.carryover_date_type = ACCRUAL_DATE_TYPE_OPTIONS.find(
        (option) => option.value === accrualConfig.carryover_date_type
      );
    }

    if (accrualConfig?.fixed?.accrual_date_type) {
      accrualConfig.fixed.accrual_date_type = ACCRUAL_DATE_TYPE_OPTIONS.find(
        (option) => option.value === accrualConfig.fixed.accrual_date_type
      );
    }
    if (accrualConfig?.hourly?.count_hours_paid != null) {
      accrualConfig["hourly"]["count_hours_paid" + policyLevel._id] = accrualConfig.hourly.count_hours_paid
        ? "hours_paid"
        : "hours_worked";
    }
    if (accrualConfig?.hourly?.count_overtime != null) {
      accrualConfig["hourly"]["count_overtime" + policyLevel._id] = accrualConfig.hourly.count_overtime
        ? "yes"
        : "no";
    }

    accrualConfig = {
      ...accrualConfig,
      yearly_limit: policyLevel.accrual_config?.yearly_limit,
      max_balance: policyLevel.accrual_config?.max_balance,
    };

    return {
      name: policyLevel.name,
      unpaid: policyLevel.unpaid,
      accrualType: ACCRUAL_CONFIG_OPTIONS.find((option) => option.value === accrualType),
      accrual_config: accrualConfig,
      default_starting_balance: policyLevel.default_starting_balance,

      min_tenure_for_requests: policyLevel.min_tenure_for_requests,
      min_tenure_for_accruals: policyLevel.min_tenure_for_accruals,
      max_usage_yearly_limit: policyLevel.max_usage_yearly_limit,
      disable_negative_balances: policyLevel.disable_negative_balances,

      auto_enrollment: policyLevel.auto_enrollment
        ? {
            ...policyLevel.auto_enrollment,
            carryover_type:
              AUTO_ENROLLMENT_CARRYOVER_OPTIONS.find(
                (option) => option.value === policyLevel.auto_enrollment?.carryover_type
              ) || AUTO_ENROLLMENT_CARRYOVER_OPTIONS[0],
          }
        : null,
      enable_fringe_offset: policyLevel.enable_fringe_offset,
    };
  };

  const form = useForm<LevelForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildForm(policyLevel),
  });

  const formData = form.watch();
  useWatch({ control: form.control });

  useEffect(() => {
    form.reset(buildForm(policyLevel));
  }, [policyLevel]);

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, errors } = form;

  const accrualType = formData.accrualType?.value;

  const handleEdit = () => {
    setEditing(true);
    editingLevel(policyLevel?._id, true);
    setExpanded(true);
  };

  const handleCancel = () => {
    form.reset(buildForm(policyLevel));
    setEditing(false);
    editingLevel(policyLevel?._id, false);
    setExpanded(false);
  };

  const saveLevel = (params) => {
    setEditing(false);
    onSave(buildParams(params));
    editingLevel(policyLevel?._id, false);
    setExpanded(false);
  };

  const convertThresholdsToNumbers = (thresholds) => {
    if (!thresholds || (thresholds.years === "" && thresholds.months === "" && thresholds.days === ""))
      return;

    return {
      years: thresholds.years ? parseInt(thresholds.years) : undefined,
      months: thresholds.months ? parseInt(thresholds.months) : undefined,
      days: thresholds.days ? parseInt(thresholds.days) : undefined,
    };
  };

  const buildParams = (params: LevelForm): TimeOffPolicyLevelConfig => {
    const cleanedAccrualConfig: $TSFixMe = cloneDeep(params.accrual_config);

    if (params.accrual_config) {
      if (params.accrual_config.fixed?.cadence?.value) {
        cleanedAccrualConfig.fixed.cadence = params.accrual_config.fixed.cadence.value;
      }
      if (params.accrual_config.fixed?.accrual_date_type?.value) {
        cleanedAccrualConfig.fixed.accrual_date_type = params.accrual_config.fixed.accrual_date_type.value;
      }

      if (params.accrual_config.carryover_date_type?.value) {
        cleanedAccrualConfig.carryover_date_type = params.accrual_config.carryover_date_type.value;
      }

      const carryOverDate = params.accrual_config.carryover_date;
      if (carryOverDate && cleanedAccrualConfig.carryover_date_type === "custom") {
        cleanedAccrualConfig.carryover_date = {
          day: Number(carryOverDate.day),
          month: Number(carryOverDate.month),
        };
      } else {
        cleanedAccrualConfig.carryover_date = null;
      }

      if (params.accrual_config.fixed?.accrual_date) {
        cleanedAccrualConfig.fixed.accrual_date = {
          day: Number(params.accrual_config.fixed.accrual_date.day),
          month: Number(params.accrual_config.fixed.accrual_date.month),
        };
      }
      if (
        params.accrual_config.hourly &&
        params.accrual_config.hourly["count_hours_paid" + policyLevel._id]
      ) {
        cleanedAccrualConfig.hourly.count_hours_paid =
          params["accrual_config"]["hourly"]["count_hours_paid" + policyLevel._id] === "hours_paid";
      }
      if (params.accrual_config.hourly && params.accrual_config.hourly["count_overtime" + policyLevel._id]) {
        cleanedAccrualConfig.hourly.count_overtime =
          params["accrual_config"]["hourly"]["count_overtime" + policyLevel._id] === "yes";
      }
    }

    const tenureThreshold = convertThresholdsToNumbers(params.auto_enrollment?.tenure_threshold);
    const autoEnrollment = params.auto_enrollment
      ? {
          ...params.auto_enrollment,
          tenure_threshold: tenureThreshold || null,
          carryover_type: params.auto_enrollment?.carryover_type?.value || "lose",
        }
      : null;

    const data: TimeOffPolicyLevelConfig = {
      _id: policyLevel._id,
      name: params.name!,
      unpaid: params.unpaid,
      unlimited: params.accrualType?.value === "unlimited",
      accrual_config: cleanedAccrualConfig,
      min_tenure_for_requests: params.min_tenure_for_requests,
      min_tenure_for_accruals: params.min_tenure_for_accruals,
      max_usage_yearly_limit: params.max_usage_yearly_limit,
      disable_negative_balances: params.disable_negative_balances,
      auto_enrollment: autoEnrollment,
      enable_fringe_offset: params.enable_fringe_offset,
      default_starting_balance: params.default_starting_balance,
    };
    return data;
  };

  const renderLevelActions = () => {
    return (
      <div className={styles["level-header-right"]}>
        <div className={styles["level-actions"]}>
          {!editing && (
            <Button className="button-1 no-margin square-button" onClick={() => onDelete(policyLevel._id)}>
              <Trash />
            </Button>
          )}
          {!editing && (
            <Button className="button-1 no-margin square-button" onClick={handleEdit}>
              <Pencil />
            </Button>
          )}
          {editing && (
            <Button
              text="Save level"
              className="button-2"
              style={{ height: 32 }}
              onClick={handleSubmit(saveLevel)}
            ></Button>
          )}
          {editing && (
            <Button className="button-1 no-margin square-button" onClick={handleCancel}>
              <X />
            </Button>
          )}
        </div>
        {expanded ? (
          <CaretUp height={32} size={20} onClick={() => setExpanded(!expanded)} />
        ) : (
          <CaretDown height={32} size={20} onClick={() => setExpanded(!expanded)} />
        )}
      </div>
    );
  };

  const renderCommonFields = () => {
    return (
      <div style={{ paddingLeft: 10 }}>
        <Formblock
          label="Name"
          type="text"
          name="name"
          placeholder="e.g. Year 1-2"
          className="modal wizard"
          form={form}
          editing={true}
          disabled={!editing}
          val={vals.required}
        />
        <Formblock
          text="This is an unpaid time off policy?"
          type="checkbox"
          className="modal wizard"
          name="unpaid"
          form={form}
          editing={true}
          disabled={!editing}
        />
      </div>
    );
  };

  const renderFixedFields = () => {
    return (
      <div style={{ gap: 10, marginBottom: 20 }} className={styles["time-off-policy-inputs-row"]}>
        <Formblock
          type="number"
          label="Accrue"
          name="accrual_config.fixed.total_hours"
          form={form}
          editing={true}
          className="modal wizard"
          style={{ width: 70 }}
          disabled={!editing}
          placeholder={"hours"}
          val={vals.required}
        />
        <Label label="hours per year" style={{ width: "auto", marginTop: 30 }}></Label>
        <Formblock
          type="select"
          form={form}
          name="accrual_config.fixed.cadence"
          options={ACCRUAL_CADENCE_OPTIONS}
          editing={true}
          disabled={!editing}
          requiredSelect={true}
          style={{ width: 150, marginTop: 23 }}
          placeholder="cadence"
        />

        {formData.accrual_config?.fixed?.cadence?.value === "yearly" && (
          <>
            <Label label="on" style={{ width: 10, marginTop: 30 }}></Label>
            <Formblock
              type="select"
              form={form}
              name="accrual_config.fixed.accrual_date_type"
              options={ACCRUAL_DATE_TYPE_OPTIONS}
              editing={true}
              disabled={!editing}
              requiredSelect={true}
              style={
                formData.accrual_config?.fixed?.accrual_date_type?.value === "custom"
                  ? { marginTop: 23, width: 150 }
                  : { marginTop: 23, width: 225 }
              }
            />
          </>
        )}
        {formData.accrual_config?.fixed?.accrual_date_type?.value === "custom" && (
          <>
            <Formblock
              type="number"
              form={form}
              name="accrual_config.fixed.accrual_date.month"
              editing={true}
              disabled={!editing}
              errors={errors}
              val={vals.numberValidator({ required: true, min: 1, max: 12 })}
              min={1}
              max={12}
              style={{ width: 80, marginTop: 23 }}
              placeholder="Month"
            />
            <Formblock
              type="number"
              form={form}
              name="accrual_config.fixed.accrual_date.day"
              editing={true}
              disabled={!editing}
              errors={errors}
              val={vals.numberValidator({ required: true, min: 1, max: 31 })}
              min={1}
              max={31}
              style={{ width: 70, marginTop: 23 }}
              placeholder="Day"
            />
          </>
        )}
      </div>
    );
  };

  const renderHourlyFields = () => {
    const hoursBasedOnText =
      formData.accrual_config?.hourly?.count_hours_paid === "hours_worked" ? "worked" : "paid";

    return (
      <div className="form-section">
        <Formblock
          label="Accrual basis*"
          type="radio"
          name={"accrual_config.hourly.count_hours_paid" + policyLevel._id}
          form={form}
          className="modal wizard"
          editing={true}
          disabled={!editing}
          options={ACCRUAL_BASIS_OPTIONS}
          val={vals.required}
        />
        <Formblock
          label="Accrual overtime hours*"
          type="radio"
          name={"accrual_config.hourly.count_overtime" + policyLevel._id}
          form={form}
          className="modal wizard"
          editing={true}
          disabled={!editing}
          options={OVERTIME_HOURS_OPTIONS}
          val={vals.required}
        />
        <div style={{ gap: 10, marginBottom: 20 }} className={styles["time-off-policy-inputs-row"]}>
          <Formblock
            label="Earn"
            style={{ width: 70 }}
            className="modal wizard"
            placeholder="hours"
            type="number"
            name="accrual_config.hourly.rate.hours_earned"
            form={form}
            editing={true}
            disabled={!editing}
            val={vals.numberValidator({ required: true, min: 1 })}
          />

          <Label label="for every" style={{ width: "auto", marginTop: 30 }}></Label>
          <div className={styles["time-off-policy-input-field"]}>
            <Formblock
              type="number"
              style={{ width: 70, marginTop: 23 }}
              name="accrual_config.hourly.rate.hours_based_on"
              form={form}
              editing={true}
              disabled={!editing}
              placeholder="hours"
              min={1}
              val={vals.numberValidator({ required: true, min: 1 })}
            />
            <Label label={`hour(s) ${hoursBasedOnText}`} style={{ width: "auto", marginTop: 30 }}></Label>
          </div>
        </div>
      </div>
    );
  };

  const renderBalanceSettings = () => {
    return (
      <div ref={balanceSettings}>
        {" "}
        <Formblock
          label="Default starting balance"
          type="append"
          name="default_starting_balance"
          form={form}
          editing={true}
          disabled={!editing}
          appendText={"hours"}
          style={{ width: 400 }}
          className="modal wizard"
        />
        <Formblock
          label="Max accruable hours per year"
          type="append"
          name="accrual_config.yearly_limit"
          form={form}
          editing={true}
          disabled={!editing}
          appendText={"hours"}
          style={{ width: 400 }}
          className="modal wizard"
        />
        <Formblock
          label="Max balance at anytime"
          type="append"
          name="accrual_config.max_balance"
          form={form}
          editing={true}
          disabled={!editing}
          appendText={"hours"}
          style={{ width: 400 }}
          className="modal wizard"
        />
      </div>
    );
  };

  const renderCarryOverFields = () => {
    return (
      <div ref={carryOverSettings}>
        <div className={styles["level-section"]}>
          <h3 style={{ marginTop: 0 }}>Carryover Settings</h3>

          <Formblock
            label="Max hours that can be carried over every year in this level"
            sublabel="Leave blank if there is no limit to how much can be carried over"
            type="number"
            name="accrual_config.carryover_limit"
            form={form}
            editing={true}
            disabled={!editing}
            placeholder={"hours"}
            style={{ marginBottom: 20, marginTop: 20, width: 430 }}
            className="modal wizard"
          />
          {(!!formData?.accrual_config?.carryover_limit ||
            formData?.accrual_config?.carryover_limit === 0) && (
            <div style={{ gap: 10 }} className={styles["time-off-policy-inputs-row"]}>
              <div className={styles["time-off-policy-input-field"]}>
                <Label label="applied on" style={{ width: 80.38 }}></Label>
                <Formblock
                  type="select"
                  form={form}
                  name="accrual_config.carryover_date_type"
                  options={ACCRUAL_DATE_TYPE_OPTIONS}
                  style={
                    formData.accrual_config?.carryover_date_type?.value === "custom"
                      ? { marginBottom: 15, width: 115 }
                      : { marginBottom: 15, width: 240 }
                  }
                  editing={true}
                  disabled={!editing}
                  requiredSelect={
                    !!formData?.accrual_config?.carryover_limit ||
                    formData?.accrual_config?.carryover_limit === 0
                  }
                />
                {formData.accrual_config?.carryover_date_type?.value === "custom" && (
                  <>
                    <div
                      className={styles["time-off-policy-inputs-row"]}
                      style={{ gap: 10, marginBottom: 15 }}
                    >
                      <Formblock
                        type="number"
                        form={form}
                        name="accrual_config.carryover_date.month"
                        editing={true}
                        disabled={!editing}
                        errors={errors}
                        val={vals.numberValidator({ required: true, min: 1, max: 12 })}
                        min={1}
                        max={12}
                        style={{ width: 80 }}
                        placeholder="Month"
                      />
                      <Formblock
                        type="number"
                        form={form}
                        name="accrual_config.carryover_date.day"
                        editing={true}
                        disabled={!editing}
                        errors={errors}
                        val={vals.numberValidator({ required: true, min: 1, max: 31 })}
                        min={1}
                        max={31}
                        style={{ width: 70 }}
                        placeholder="Day"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <Formblock
            label="Auto enrollment carryover"
            sublabel="If an employee is moved to this level automatically, what should happen to their balance?"
            text="Auto enrollment carryover"
            type="select"
            form={form}
            name="auto_enrollment.carryover_type"
            className="modal wizard"
            options={AUTO_ENROLLMENT_CARRYOVER_OPTIONS}
            style={{ marginTop: 20, width: 430 }}
            editing={true}
            disabled={!editing}
            requiredSelect={false}
            defaultValue={AUTO_ENROLLMENT_CARRYOVER_OPTIONS[0]?.value}
          />
        </div>
      </div>
    );
  };

  const renderAdditionalSettings = () => {
    return (
      <div ref={advancedSettings} className={styles["level-section"]}>
        <h3 style={{ marginTop: 0 }}>Advanced Settings</h3>
        <Formblock
          label="Days until time off balance starts accruing"
          type="number"
          name="min_tenure_for_accruals"
          form={form}
          editing={true}
          disabled={!editing}
          className="modal wizard"
          placeholder={"days"}
          style={{ width: 430 }}
        />
        <Formblock
          label="Days until employee can start requesting time off"
          type="number"
          className="modal wizard"
          name="min_tenure_for_requests"
          form={form}
          editing={true}
          disabled={!editing}
          placeholder={"days"}
          style={{ width: 430 }}
        />
        {hasAccessToTimeOffMaxUsageLimits && (
          <>
            <Formblock
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Max amount of usage hours per calendar year
                  <Badge
                    style={{ marginLeft: 5, marginRight: 0, marginBottom: -1 }}
                    text="Beta"
                    color="green"
                  />
                </div>
              }
              sublabel="Leave blank if there is no usage limit"
              type="number"
              className="modal wizard"
              name="max_usage_yearly_limit"
              form={form}
              editing={true}
              disabled={!editing}
              placeholder={"hours"}
              style={{ width: 430 }}
            />
          </>
        )}
        <Formblock
          text="Disable negative balances"
          labelInfo="If disabled, employees use more time off than they have accrued."
          type="checkbox"
          name="disable_negative_balances"
          form={form}
          editing={true}
          disabled={!editing}
        />
        <Formblock
          type="checkbox"
          text="Enable fringe offset"
          name="enable_fringe_offset"
          form={form}
          editing={true}
          disabled={!editing}
          placeholder="0"
        />
      </div>
    );
  };

  return (
    <>
      <div className={"relative"}>
        <div className={styles["level-container"] + " " + (expanded ? styles["expanded"] : "")}>
          <motion.header initial={false} animate="open" transition={{ duration: 0.3 }}>
            <div className={styles["level-container-contents"] + " " + (expanded ? styles["expanded"] : "")}>
              <div style={{ display: "flex" }} ref={basicSettings}>
                <Label label={<strong>Enroll</strong>} className={styles["tenure_threshold_label"]}></Label>
                <Formblock
                  type="number"
                  form={form}
                  onClick={handleEdit}
                  name="auto_enrollment.tenure_threshold.years"
                  className={styles["tenure_threshold"]}
                  errors={errors}
                  val={vals.numberValidator({ required: false, min: 0, max: 100 })}
                  editing={true}
                />
                <Label label={<strong>years</strong>} className={styles["tenure_threshold_label"]}></Label>
                <Formblock
                  type="number"
                  form={form}
                  onClick={handleEdit}
                  errors={errors}
                  val={vals.numberValidator({ required: false, min: 0, max: 12 })}
                  name="auto_enrollment.tenure_threshold.months"
                  className={styles["tenure_threshold"]}
                  editing={true}
                />
                <Label label={<strong>months</strong>} className={styles["tenure_threshold_label"]}></Label>
                <Formblock
                  type="number"
                  form={form}
                  errors={errors}
                  val={vals.numberValidator({ required: false, min: 0, max: 31 })}
                  onClick={handleEdit}
                  name="auto_enrollment.tenure_threshold.days"
                  className={styles["tenure_threshold"]}
                  editing={true}
                />
                <Label label={<strong>days</strong>} className={styles["tenure_threshold_label"]}></Label>
                <Label
                  label={<strong>after start date</strong>}
                  className={styles["tenure_threshold_label"]}
                ></Label>
              </div>

              {renderLevelActions()}
            </div>
          </motion.header>
          <AnimatePresence initial={false}>
            {
              <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                style={{ display: expanded ? "block" : "none" }}
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <div className={styles["level-config"]}>
                  {renderCommonFields()}
                  <div className={styles["level-section"]}>
                    <h3 style={{ marginTop: 0 }} ref={accrualSettings}>
                      Accrual and balance settings
                    </h3>
                    <Label
                      labelStyle={{ width: "100%", marginTop: 10 }}
                      label="How does an employee accrue time off"
                    ></Label>
                    <Formblock
                      type="select"
                      label="Type"
                      form={form}
                      className="modal wizard"
                      name="accrualType"
                      options={ACCRUAL_CONFIG_OPTIONS}
                      editing={true}
                      disabled={!editing}
                      requiredSelect={true}
                      style={{ width: 225, marginTop: 16 }}
                    />
                    {accrualType && accrualType === "hourly" && renderHourlyFields()}
                    {accrualType && accrualType === "fixed" && renderFixedFields()}
                    {accrualType && accrualType !== "unlimited" && renderBalanceSettings()}
                  </div>
                  {accrualType && accrualType !== "unlimited" && renderCarryOverFields()}
                  {accrualType && accrualType !== "unlimited" && renderAdditionalSettings()}
                </div>
              </motion.section>
            }
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};
