import { FlatfileResults } from "@flatfile/react";
import { useActiveCompanyId, useActiveTeam, useTimeOffPolicyOptions } from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { keyBy } from "lodash";
import { ImportResult, MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../../components/importer/Importer";

import { Notifier } from "dashboard/utils";

type Props = {
  onFinish: (params: ImportResult) => void;
  policyId?: string;
};

export type EnrolleeRecord = {
  timeOffPolicyId: string;
  balance: string;
  teamMemberId: string;
};

export const TimeOffPolicyEnrolleeImporter: React.FC<Props> = ({ onFinish, policyId }) => {
  const teamMembers = useActiveTeam();
  const activeCompanyId = useActiveCompanyId();

  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);

  const timeOffPoliciesOptions = useTimeOffPolicyOptions();

  // validations
  const validateTeamMemberID = (teamMemberId: string | undefined) => {
    if (teamMemberId) {
      const idWithoutWhiteSpace = teamMemberId.trim();

      const teamMember = lookupTeamID[idWithoutWhiteSpace];
      if (!teamMember) {
        return buildFlatfileMessage("Team member not found", idWithoutWhiteSpace, "error");
      }

      return { value: idWithoutWhiteSpace };
    }
  };

  const validateBalance = (record: EnrolleeRecord) => {
    const balance = record.balance;

    if (!!balance) {
      const balanceNumber = Number(balance);
      if (isNaN(balanceNumber)) {
        return buildFlatfileMessage("Balance must be a number", balance, "error");
      }
    }

    return { value: balance };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      if (!activeCompanyId) return;

      // replace friendly id with true id & make balance a number
      const cleanInput = results.validData.map((input) => {
        return {
          ...input,
          balance: input.balance?.length > 0 ? Number(input.balance) : undefined,
          teamMemberId: lookupTeamID[input.teamMemberId]?._id,
        };
      });

      const response = await MiterAPI.time_off.policies.import_enrollees({
        clean_inputs: cleanInput,
        raw_inputs: results.validData,
        policyId,
        companyId: activeCompanyId,
      });

      if (response.error) throw new Error(response.error);
      onFinish(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error enrolling the employees.");
    }
  };

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Employee ID number",
        type: "string",
        key: "teamMemberId",
        description: "Unique identifer for team member (must be same in source system and Miter).",
        validators: [{ validate: "required" }],
        hook: (val) =>
          typeof val === "string" ? validateTeamMemberID(val) : validateTeamMemberID(val.teamMemberId),
      },
      {
        label: "Balance",
        type: "string",
        key: "balance",
        hook: (record) => (typeof record !== "string" ? validateBalance(record) : { value: record }),
      },
    ];

    if (!policyId) {
      fieldList.push({
        label: "Time off policy",
        type: "select",
        key: "timeOffPolicyId",
        validators: [{ validate: "required" }],
        options: timeOffPoliciesOptions,
      });
    }
    return fieldList;
  }, [timeOffPoliciesOptions]);

  return <Importer id="enrollees" resource="enrollees" onSave={handleSubmit} fields={fields} />;
};
