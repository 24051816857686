import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import React, { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Params, useNavigate, useParams } from "react-router-dom";
import { Toggler } from "ui";
import { CardTransactionsTable } from "../expenses/CardTransactionsTable";
import { ExpenseReimbursementsTable } from "../expenses/ExpenseReimbursementsTable";
import { ForageRequest, ForageResponse } from "backend/utils/forage/forage-types";
import { MiterAPI } from "dashboard/miter";
import { useActionableItems, useActiveTeamMember, usePolicies } from "dashboard/hooks/atom-hooks";
import { TimeOffRequestsTable } from "../time-off/TimeOffRequestsTable";
import { FillableDocumentsTable } from "dashboard/components/fillable-templates/FillableDocumentsTable";
import { AggregatedFile } from "dashboard/miter";
import { useHasAccessToFillableDocuments } from "dashboard/gating";
type VIEW_STRING =
  | null
  | "expenses"
  | "reimbursements"
  | "timesheets"
  | "time-off-requests"
  | "fillable-documents";

export const NeedsAttention: React.FC = () => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeTeamMember = useActiveTeamMember();
  const policies = usePolicies();

  const [actionableItems] = useActionableItems();
  const navigate = useNavigate();
  const { view } = useParams<Params>();
  const canAccessFillableDocuments = useHasAccessToFillableDocuments();

  /**********************************************************************************************************
   * useEffects
   **********************************************************************************************************/
  useEffect(() => {
    if (!view && (policies.length > 0 || canAccessFillableDocuments)) {
      const hasTimesheetPolicy = policies.some((policy) => policy.type === "timesheet");
      const hasExpensePolicy = policies.some((policy) => policy.type === "expense");
      const hasReimbursementPolicy = policies.some((policy) => policy.type === "expense_reimbursement");
      const hasTimeOffRequestPolicy = policies.some((policy) => policy.type === "time_off_request");

      let viewString: VIEW_STRING = null;
      if (hasTimesheetPolicy) {
        viewString = "timesheets";
      } else if (hasExpensePolicy) {
        viewString = "expenses";
      } else if (hasReimbursementPolicy) {
        viewString = "reimbursements";
      } else if (hasTimeOffRequestPolicy) {
        viewString = "time-off-requests";
      } else if (canAccessFillableDocuments) {
        viewString = "fillable-documents";
      } else {
        navigate("/home");
        return;
      }

      navigate(`/inbox/needs-attention/${viewString}`, { replace: true });
    }
  }, [view, canAccessFillableDocuments]);

  /**********************************************************************************************************
   * Handler functions
   **********************************************************************************************************/
  const handleToggle = (path: string) => {
    navigate(`/inbox/needs-attention/${path}`, { replace: true });
  };

  const togglerConfig = useMemo(() => {
    const hasTimesheetPolicies = policies.filter((policy) => policy.type === "timesheet").length > 0;
    const hasExpensePolicies = policies.filter((policy) => policy.type === "expense").length > 0;
    const hasReimbursementPolicies =
      policies.filter((policy) => policy.type === "expense_reimbursement").length > 0;
    const hasTimeOffRequestPolicies =
      policies.filter((policy) => policy.type === "time_off_request").length > 0;

    return [
      {
        label: "Timesheets",
        path: "timesheets",
        count: actionableItems?.counts.timesheets?.own_requires_actions || 0,
        hide: !hasTimesheetPolicies,
      },
      {
        label: "Card transactions",
        path: "expenses",
        count: actionableItems?.counts.expenses?.own_requires_actions || 0,
        hide: !hasExpensePolicies,
      },
      {
        label: "Reimbursements",
        path: "reimbursements",
        count: actionableItems?.counts.reimbursements?.own_requires_actions || 0,
        hide: !hasReimbursementPolicies,
      },
      {
        label: "Time off requests",
        path: "time-off-requests",
        count: actionableItems?.counts.time_off_requests?.own_requires_actions || 0,
        hide: !hasTimeOffRequestPolicies,
      },

      {
        label: "Fillable documents",
        path: "fillable-documents",
        count: actionableItems?.counts.fillable_documents?.own_requires_actions || 0,
        hide: !canAccessFillableDocuments,
      },
    ];
  }, [actionableItems?.counts]);

  /**********************************************************************************************************
   * Fetching functions
   **********************************************************************************************************/
  const getTimesheets = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.needs_attention.timesheets(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getExpenses = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.needs_attention.expenses(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getReimbursements = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.needs_attention.reimbursements(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getTimeOffRequests = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.needs_attention.timeOffRequests(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getFillableDocuments = useCallback(async (): Promise<AggregatedFile[]> => {
    return MiterAPI.action_center.needs_attention.fillableDocuments({
      teamMemberId: activeTeamMember!._id,
    });
  }, [activeTeamMember?._id]);

  /**********************************************************************************************************
   * Render functions
   **********************************************************************************************************/
  const renderTimesheets = () => {
    if (!activeTeamMember) return <></>;
    return (
      <TimesheetsTable
        defaultFilters={[]}
        fetchActionableTimesheets={getTimesheets}
        inboxMode={"needs_attention"}
        id={"needs-attention-timesheets-table"}
      />
    );
  };

  const renderExpenses = () => {
    if (!activeTeamMember) return <></>;
    return (
      <CardTransactionsTable
        fetchActionableExpenses={getExpenses}
        hideToggle={true}
        shouldRedirectURLWhenOpening={false}
        inboxMode={"needs_attention"}
      />
    );
  };

  const renderReimbursements = () => {
    if (!activeTeamMember) return <></>;
    return (
      <ExpenseReimbursementsTable
        fetchActionableReimbursements={getReimbursements}
        inboxMode={"needs_attention"}
        shouldRedirectURLWhenOpening={false}
      />
    );
  };

  const renderTimeOffRequests = () => {
    if (!activeTeamMember) return <></>;
    return (
      <TimeOffRequestsTable
        fetchActionableTimeOffRequests={getTimeOffRequests}
        inboxMode={"needs_attention"}
      />
    );
  };

  const renderFillableDocuments = () => {
    if (!activeTeamMember) return <></>;
    return <FillableDocumentsTable fetchActionableFillableDocuments={getFillableDocuments} />;
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Needs Attention | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Needs Attention</h1>

          <div className="flex-1"></div>
        </div>
        <p className={"header-subtitle"}>
          Resolve issues on your timesheets, expenses, reimbursements, and documents.
        </p>
        <Toggler secondary={false} active={view} config={togglerConfig} toggle={handleToggle} />
        <div className="view">
          {view === "timesheets" && renderTimesheets()}
          {view === "expenses" && renderExpenses()}
          {view === "reimbursements" && renderReimbursements()}
          {view === "time-off-requests" && renderTimeOffRequests()}
          {view === "fillable-documents" && canAccessFillableDocuments && renderFillableDocuments()}
        </div>
      </div>
    </div>
  );
};
