import { MiterAPI, MiterError, PayRateGroup, UnionReciprocityRule } from "dashboard/miter";
import React, { useCallback, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import { useLookupPrg, usePrgOptions, useRateDifferentialOptions } from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";

type Props = {
  hide: () => void;
  readonly: boolean;
  homePrg: PayRateGroup;
  rule?: UnionReciprocityRule;
};

type RuleModalForm = {
  home_pay_rate_group_id: Option<string>;
  work_pay_rate_group_id: Option<string>;
  pay_rate_selection: Option<"home" | "work">;
  fringe_selection: Option<"home" | "work">;
  disabled_fringes?: Option<string>[];
  enabled_fringes?: Option<string>[];
  rate_differential_id?: Option<string>;
};

const HOME_WORK_SELECTION: Option<"home" | "work">[] = [
  { label: "Work", value: "work" },
  { label: "Home", value: "home" },
];

export const UnionReciprocityRuleModal: React.FC<Props> = ({ hide, readonly, homePrg, rule }) => {
  const [loading, setLoading] = useState(false);

  const prgOptions = usePrgOptions();
  const lookupPrg = useLookupPrg();
  const rateDifferentialOptions = useRateDifferentialOptions();

  const prgAvailableWorkOptions = useMemo(() => {
    return prgOptions.filter((option) => option.value !== homePrg._id);
  }, [prgOptions]);

  // helper function for getting all fringes in a prg
  const getPrgFringeOptions = useCallback(
    (prgId?: string): Option<string>[] => {
      if (!prgId) return [];
      const workPrg = lookupPrg(prgId);

      const rates = workPrg?.union_rates;
      const fringes = rates?.flatMap((rates) => rates.fringes) || [];
      const fringeOptionsMap = new Map<string, Option<string>>();

      // todo clean this up when we make fringes their own model
      for (const f of fringes) {
        //removing duplicates
        fringeOptionsMap.set(f.fringe_group_id, {
          label: f.label,
          value: f.fringe_group_id,
        });
      }

      return [...fringeOptionsMap.values()];
    },
    [lookupPrg]
  );

  const homeFringeOptions = useMemo(() => {
    return getPrgFringeOptions(homePrg._id);
  }, [homePrg]);

  const buildDefaultValues = () => {
    let disabledFringes: Option<string>[] = [];
    let enabledFringes: Option<string>[] = [];

    if (rule) {
      const workFringeOptions = getPrgFringeOptions(rule?.work_pay_rate_group_id);

      disabledFringes = (rule.fringe_selection === "work" ? workFringeOptions : homeFringeOptions).filter(
        (option) => rule?.disabled_fringes?.includes(option.value)
      );

      enabledFringes = (rule.fringe_selection === "work" ? homeFringeOptions : workFringeOptions).filter(
        (option) => rule?.enabled_fringes?.includes(option.value)
      );
    }
    return {
      work_pay_rate_group_id: prgOptions.find((option) => option.value === rule?.work_pay_rate_group_id),
      fringe_selection: HOME_WORK_SELECTION.find((option) => option.value === rule?.fringe_selection),
      pay_rate_selection: HOME_WORK_SELECTION.find((option) => option.value === rule?.pay_rate_selection),
      disabled_fringes: disabledFringes,
      enabled_fringes: enabledFringes,
      rate_differential_id: rateDifferentialOptions.find(
        (option) => option.value === rule?.rate_differential_id
      ),
    };
  };

  const { register, errors, control, handleSubmit, reset, getValues } = useForm({
    defaultValues: buildDefaultValues(),
  });

  const formData = useWatch<RuleModalForm>({
    control: control,
  });

  const workFringeOptions = useMemo(() => {
    return getPrgFringeOptions(formData.work_pay_rate_group_id?.value);
  }, [formData.work_pay_rate_group_id]);

  // fringe options available to enable and fringe options available to disable
  // dependent on whether fringe selection is home or work
  const allFringeOptions: { enableOptions: Option<string>[]; disableOptions: Option<string>[] } =
    useMemo(() => {
      const fringeSelection = formData.fringe_selection?.value;

      if (fringeSelection === "home") {
        return { enableOptions: workFringeOptions, disableOptions: homeFringeOptions };
      } else if (fringeSelection === "work") {
        return { enableOptions: homeFringeOptions, disableOptions: workFringeOptions };
      } else return { enableOptions: [], disableOptions: [] };
    }, [workFringeOptions, homeFringeOptions, formData.fringe_selection]);

  const resetFringes = () => {
    reset({ ...getValues(), disabled_fringes: [], enabled_fringes: [] });
  };

  const saveRule = async (data: RuleModalForm) => {
    setLoading(true);
    try {
      let response: UnionReciprocityRule & MiterError;

      const workPrgId = data.work_pay_rate_group_id.value;
      const requestBody: Partial<UnionReciprocityRule> = {
        home_pay_rate_group_id: homePrg._id,
        work_pay_rate_group_id: workPrgId,
        fringe_selection: data.fringe_selection.value,
        pay_rate_selection: data.pay_rate_selection.value,
        disabled_fringes: data.disabled_fringes?.map((fringe) => fringe.value) || [],
        enabled_fringes: data.enabled_fringes?.map((fringe) => fringe.value) || [],
        rate_differential_id: data.rate_differential_id?.value || null,
      };

      if (rule) {
        response = await MiterAPI.union_reciprocity_rules.update(rule._id, requestBody);
      } else {
        response = await MiterAPI.union_reciprocity_rules.create(requestBody);
      }
      if (response.error) throw new Error(response.error);
      Notifier.success("Reciprocity rule was successfully saved.");
      hide();
    } catch (e) {
      Notifier.error("There was a problem saving the reciprocity rule. We're looking into it!");
    }
    setLoading(false);
  };
  return (
    <div>
      <ActionModal
        onHide={hide}
        onCancel={hide}
        onSubmit={handleSubmit(saveRule)}
        submitText={rule ? "Update" : "Create"}
        headerText={rule ? "Update rule" : "Create a reciprocity rule"}
        loading={loading}
        showCancel={true}
        showSubmit={true}
        wrapperStyle={{ width: 500 }}
        bodyStyle={{ maxHeight: 600 }}
      >
        <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
          <div className="yellow-text-container" style={{ fontSize: 14 }}>
            This Reciprocity Rule describes how members of {homePrg.label || "this pay rate group"} should be
            paid when they work in another local.
          </div>
          <h4>Basics</h4>
          <Formblock
            label="Work local"
            type="select"
            control={control}
            name="work_pay_rate_group_id"
            options={prgAvailableWorkOptions}
            register={register(vals.required)}
            className={"modal "}
            errors={errors}
            editing={!readonly}
            onChange={() => resetFringes()}
          />
          <Formblock
            label="Base rate"
            type="select"
            control={control}
            name="pay_rate_selection"
            options={HOME_WORK_SELECTION}
            register={register(vals.required)}
            className={"modal "}
            errors={errors}
            editing={!readonly}
          />
          <Formblock
            label="Fringes"
            type="select"
            control={control}
            name="fringe_selection"
            options={HOME_WORK_SELECTION}
            register={register(vals.required)}
            className={"modal "}
            errors={errors}
            editing={!readonly}
            onChange={() => resetFringes()}
          />
          <Formblock
            label="Rate differential"
            type="select"
            control={control}
            name="rate_differential_id"
            options={rateDifferentialOptions}
            register={register()}
            className={"modal "}
            errors={errors}
            isClearable={true}
            editing={!readonly}
          />
          {formData.fringe_selection?.value && formData.work_pay_rate_group_id?.value && (
            <>
              <h4>Fringe overrides</h4>
              <Formblock
                label={
                  <>
                    Fringes that <strong>should</strong> be applied from{" "}
                    {formData.fringe_selection?.value === "work"
                      ? lookupPrg(homePrg._id)?.label
                      : lookupPrg(formData.work_pay_rate_group_id?.value)?.label}
                  </>
                }
                type="multiselect"
                control={control}
                name="enabled_fringes"
                options={allFringeOptions.enableOptions}
                register={register()}
                className={"modal "}
                errors={errors}
                editing={!readonly}
                height="fit-content"
              />

              <Formblock
                label={
                  <>
                    Fringes that <strong>should not</strong> be applied from{" "}
                    {formData.fringe_selection?.value === "home"
                      ? lookupPrg(homePrg._id)?.label
                      : lookupPrg(formData.work_pay_rate_group_id?.value)?.label}
                  </>
                }
                type="multiselect"
                control={control}
                name="disabled_fringes"
                options={allFringeOptions.disableOptions}
                register={register()}
                className={"modal "}
                errors={errors}
                editing={!readonly}
                height="fit-content"
              />
            </>
          )}
        </div>
      </ActionModal>
    </div>
  );
};
