import { useMemo } from "react";
import {
  useActiveJobs,
  useActiveTeam,
  useActivities,
  useCostTypes,
  useLedgerAccounts,
  useSelectableActivitiesMap,
} from "../atom-hooks";
import { keyBy } from "lodash";
import { buildFlatfileMessage } from "dashboard/utils/flatfile";
import { IDataHookRecord } from "@flatfile/adapter/build/main/interfaces";
import { standardizePhoneFormat } from "dashboard/utils";
import { validatePhoneAreaCode } from "miter-utils";

type ImportValidators = {
  validateTeamMemberID: (teamMemberId: string | undefined, isRequired?: boolean) => IDataHookRecord;
  validateJobCode: (jobCode: string | undefined) => IDataHookRecord;
  validateCostCode: (costCode: string | undefined, jobCode: string | undefined) => IDataHookRecord;
  validateCostTypeId: (costType: string | undefined) => IDataHookRecord;
  validateGLAccountCode: (glAccountCode: string | undefined) => IDataHookRecord;
  validateOtherFieldsEmpty: <T extends Record<string, string>>(
    row: T,
    field: { key: keyof T; label: string },
    fieldsToCheck: { key: keyof T; label: string }[]
  ) => IDataHookRecord;
  validatePhone: (phone: string | null | undefined, upsert?: boolean) => IDataHookRecord;
  validateEmail: (email: string | null | undefined, upsert?: boolean) => IDataHookRecord;
};

// Returns flatfile validator functions for general fields across expenses, timesheets, etc.
// Fields include the team member, job, and activity
export const useImportValidators = (): ImportValidators => {
  const teamMembers = useActiveTeam();
  const activeJobs = useActiveJobs();
  const activities = useActivities();
  const costTypes = useCostTypes();
  const glAccounts = useLedgerAccounts();
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);
  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);
  const lookupActivityCode = useMemo(() => keyBy(activities, "cost_code"), [activities]);
  const lookupCostTypeId = useMemo(() => keyBy(costTypes, "code"), [costTypes]);
  const lookupActivity = useSelectableActivitiesMap();
  const lookupGLAccount = useMemo(() => keyBy(glAccounts, "external_id"), [glAccounts]);

  const validateTeamMemberID = (teamMemberId: string | undefined, isRequired = true) => {
    if (isRequired && !teamMemberId) {
      return buildFlatfileMessage("Team member ID required", teamMemberId, "error");
    }

    // if a team member id is optionally passed in, confirm it maps to a real team member
    if (teamMemberId) {
      const teamMember = lookupTeamID[teamMemberId];
      if (!teamMember) {
        return buildFlatfileMessage("Team member not found", teamMemberId, "error");
      }
    }

    return { value: teamMemberId };
  };

  const validateJobCode = (jobCode: string | undefined) => {
    if (jobCode) {
      const job = lookupJobCode[jobCode];
      if (!job) {
        return buildFlatfileMessage("Job code not found", jobCode, "error");
      }
    }

    return { value: jobCode };
  };

  const validateCostCode = (costCode: string | undefined, jobCode: string | undefined) => {
    if (costCode) {
      const job = jobCode ? lookupJobCode[jobCode] : undefined;
      if (job && job.custom_activities && job.activities) {
        const selectableActivities = lookupActivity.get(job._id);
        const activity = selectableActivities?.find((activity) => activity.cost_code === costCode);

        if (!activity) {
          return buildFlatfileMessage("Cost code not found on this job", costCode, "error");
        }
      } else {
        const activity = lookupActivityCode[costCode];
        if (!activity) {
          return buildFlatfileMessage("Cost code not found", costCode, "error");
        }
      }
    }

    return { value: costCode };
  };

  const validateCostTypeId = (costType: string | undefined) => {
    if (costType) {
      const costTypeObj = lookupCostTypeId[costType];
      if (!costTypeObj) {
        return buildFlatfileMessage("Cost type not found", costType, "error");
      }
    }

    return { value: costType };
  };

  const validateGLAccountCode = (glAccountCode: string | undefined) => {
    if (glAccountCode) {
      const glAccount = lookupGLAccount[glAccountCode];
      if (!glAccount) {
        return buildFlatfileMessage("GL Account not found", glAccountCode, "error");
      }
    }

    return { value: glAccountCode };
  };

  const validateOtherFieldsEmpty = <T extends Record<string, string>>(
    row: T,
    field: { key: keyof T; label: string },
    fieldsToCheck: { key: keyof T; label: string }[]
  ): IDataHookRecord => {
    if (typeof row === "string") return { value: row };
    if (!row[field.key]) return { value: "" };

    for (const checkedField of fieldsToCheck) {
      if (row[checkedField.key]) {
        return buildFlatfileMessage(
          `If "${field.label}" is filled, the field "${checkedField.label}" must be blank.`,
          row[field.key],
          "error"
        );
      }
    }
    return { value: row[field.key] };
  };

  const validatePhone = (phone: string | null | undefined, upsert?: boolean) => {
    if (!phone) return { value: "" };

    // If upserting, allow null values
    if (upsert && phone === "null") return { value: "null" };

    const cleanedPhone = standardizePhoneFormat(phone);
    if (!cleanedPhone) {
      return buildFlatfileMessage("Please enter a valid phone number.", cleanedPhone, "error");
    }

    const isValidAreaCode = validatePhoneAreaCode(cleanedPhone);
    if (!isValidAreaCode) {
      return buildFlatfileMessage("Please enter a valid phone number.", cleanedPhone, "error");
    }

    return { value: cleanedPhone };
  };

  const validateEmail = (email: string | null | undefined, upsert?: boolean) => {
    if (!email) return { value: "" };

    // If upserting, allow null values
    if (upsert && email === "null") return { value: "null" };

    // Make sure it's a valid email address.
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) || email.length > 255) {
      return buildFlatfileMessage("Please enter a valid email address.", email, "error");
    }

    const cleanedEmail = email.trim().toLowerCase();

    return { value: cleanedEmail };
  };

  return {
    validateTeamMemberID,
    validateJobCode,
    validateCostCode,
    validateCostTypeId,
    validateGLAccountCode,
    validateOtherFieldsEmpty,
    validatePhone,
    validateEmail,
  };
};
