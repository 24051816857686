import { AggregatedJob, AggregatedTeamMember, MiterAPI, MiterFilterArray } from "dashboard/miter";

import React, { FC, useEffect, useState } from "react";

import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { Controller, UseFormMethods } from "react-hook-form";
import { FaBullhorn } from "react-icons/fa";
import { Button, Formblock, Label, FilePicker, Loader } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import TeamCreateBroadcastModal from "../chat/team/TeamCreateBroadcastModal";
import styles from "./AssignmentForm.module.css";
import { findMatchingRepeatOption } from "./utils";
import { Assignment } from "dashboard/miter";
import { Option } from "ui/form/Input";
import { CustomRecurrenceModal } from "./CustomRecurrenceModal";
import { RecurrenceConfig } from "backend/models/assignment";
import { truncate } from "lodash";
import TeamMemberSelect from "../team-members/TeamMemberSelect";
import {
  useActiveCompany,
  useActiveTeam,
  useEquipmentOptions,
  useJobOptions,
  useLookupJob,
} from "dashboard/hooks/atom-hooks";
import { ViewType } from "dashboard/pages/scheduling/SchedulingCalendar";
import { useAssignmentAbilities } from "dashboard/hooks/abilities-hooks/useAssignmentAbilities";
import { JobInput } from "../shared/JobInput";

type Props = {
  mode?: "create" | "read" | "update";
  form: UseFormMethods;
  assignment?: Assignment;
  draftRecurrenceConfig: RecurrenceConfig | null | undefined;
  setDraftRecurrenceConfig: (config: RecurrenceConfig | undefined) => void;
  initialTeamMember?: AggregatedTeamMember;
  initialJob?: AggregatedJob;
  initialStartsAt?: DateTime;
  initialEndsAt?: DateTime;
  files: FilePickerFile[] | null;
  setFiles: React.Dispatch<React.SetStateAction<FilePickerFile[] | null>>;
  scheduleView: ViewType;
};

const AssignmentForm: FC<Props> = ({
  mode,
  assignment,
  initialTeamMember,
  draftRecurrenceConfig,
  setDraftRecurrenceConfig,
  initialJob,
  initialEndsAt,
  initialStartsAt,
  form,
  files,
  setFiles,
  scheduleView,
}) => {
  const { register, control, watch, errors, setValue } = form;
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const formData = watch();
  watch("recurrence");
  const [showBroadcastModal, setShowBroadcastModal] = useState(false);

  const assignmentAbilities = useAssignmentAbilities();

  const editing = mode === "create" || mode === "update";
  const teamMembers = useActiveTeam();

  const jobOptions = useJobOptions({
    defaultValue: assignment?.job || initialJob?._id,
    predicate: (job) => job.status === "active" && assignmentAbilities.jobPredicate(mode)(job),
  });

  const lookupJob = useLookupJob();
  const selectedJob = editing ? lookupJob(formData.job?.value) : lookupJob(assignment?.job);

  const activeCompany = useActiveCompany();

  const equipmentOptions = useEquipmentOptions();

  const enableJobBasedTimezones = !!activeCompany?.settings.scheduling?.enable_job_based_timezones;

  const defaultStartTime = activeCompany?.settings.scheduling?.default_start_time
    ? DateTime.fromFormat(activeCompany.settings.scheduling.default_start_time, "T")
    : undefined;

  const defaultDuration = activeCompany?.settings.scheduling?.default_duration;
  const defaultTitleIsJobName = activeCompany?.settings.scheduling?.default_title_is_job_name;

  const [repeatOptions, setRepeatOptions] = useState<Option<string>[]>([]);
  const [selectedRepeatOption, setSelectedRepeatOption] = useState<Option<string> | undefined | null>();
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [filesLoading, setFilesLoading] = useState<boolean>(false);
  const [startsAt, setStartsAt] = useState<DateTime | undefined>(
    formData.starts_at ||
      (assignment?.starts_at
        ? DateTime.fromSeconds(assignment.starts_at, {
            zone: enableJobBasedTimezones ? selectedJob?.timezone || undefined : undefined,
          })
        : undefined)
  );
  const [endsAt, setEndsAt] = useState<DateTime | undefined>(
    formData.ends_at ||
      (assignment?.ends_at
        ? DateTime.fromSeconds(assignment.ends_at, {
            zone: enableJobBasedTimezones ? selectedJob?.timezone || undefined : undefined,
          })
        : undefined)
  );

  const [teamMemberWarnings, setTeamMemberWarnings] = useState<{ [tmID: string]: string }>({});

  useEffect(() => {
    if (!formData.title?.length && defaultTitleIsJobName && selectedJob?.name) {
      setValue("title", selectedJob.name);
    }
  }, [defaultTitleIsJobName, selectedJob, formData.title]);

  useEffect(() => {
    getOverlappingAssigments();
  }, [JSON.stringify(formData.team_members), initialTeamMember, startsAt, endsAt, activeCompany]);

  const getFiles = async (id: string) => {
    if (!id) return;
    setFilesLoading(true);
    try {
      const filter: MiterFilterArray = [
        { field: "parent_type", value: "assignment" },
        { field: "parent_id", value: id },
      ];
      const response = await MiterAPI.files.list(filter, true);

      if (response.error) throw new Error(response.error);

      // @ts-expect-error, ts expects to receive a standard File type but it will not
      setFiles(response);
    } catch (err: $TSFixMe) {
      const message = err.message || "Error fetching files.";
      Notifier.error(message);
    }
    setFilesLoading(false);
  };

  const getOverlappingAssigments = async () => {
    if (!activeCompany || !startsAt || !endsAt || !formData?.team_members?.length) return;

    try {
      const filter: MiterFilterArray = [
        {
          field: "team_members",
          type: "string",
          value: formData.team_members.map((tm) => tm._id),
          comparisonType: "in",
        },
        {
          type: "or",
          value: [
            {
              field: "starts_at",
              type: "number",
              comparisonType: "<+>e",
              value: [startsAt.toSeconds(), endsAt.toSeconds()],
            },
            {
              field: "ends_at",
              type: "number",
              comparisonType: "<+>e",
              value: [startsAt.toSeconds(), endsAt.toSeconds()],
            },
          ],
        },
      ];
      const response = await MiterAPI.companies.schedule.events(activeCompany._id, filter);
      if (response.error) throw new Error(response.error);

      const updatedTeamMemberWarnings = formData.team_members.reduce((acc, tm) => {
        // @ts-expect-error fix me
        const overlappingEventsForTm = response.filter((event) => event.team_members.includes(tm._id));
        // If there are no overlapping events, no warning is needed
        if (!overlappingEventsForTm.length) return acc;

        // Add a warning for the first overlapping event
        const firstDifferentEvent = overlappingEventsForTm.find(
          (event) => event._id.toString() !== assignment?._id
        );
        if (!firstDifferentEvent) return acc;

        // @ts-expect-error fix me
        const warning = `${tm.first_name} has an overlapping event: ${firstDifferentEvent.title} at this time.`;
        acc[tm._id] = warning;

        return acc;
      }, {});

      setTeamMemberWarnings(updatedTeamMemberWarnings);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving the events for the schedule. We're looking into it!");
    }
  };

  useEffect(() => {
    if (selectedJob?.address) {
      const address = selectedJob.address;

      setValue("address.line1", address.line1);
      setValue("address.line2", address.line2);
      setValue("address.city", address.city);
      setValue("address.state", { label: selectedJob.address.state, value: selectedJob.address.state });
      setValue("address.postal_code", address.postal_code);
    }
  }, [lookupJob, formData.job?.value]);

  useEffect(() => {
    if (!assignment) return;
    getFiles(String(assignment._id));
  }, [assignment]);

  const tmIds = assignment?.team_members || [];

  // @ts-expect-error TS Filter issue
  const assignmentTmObjects: AggregatedTeamMember[] =
    tmIds.map((tmId) => teamMembers.find((aTm) => aTm._id.toString() === tmId)).filter((tm) => tm) || [];

  const handleShowBroadcastModal = () => setShowBroadcastModal(true);
  const handleHideBroadcastModal = () => setShowBroadcastModal(false);

  const handleBroadcastSubmission = () => {
    if (assignmentTmObjects?.length === 1) {
      Notifier.success("Your text was successfully sent!");
    } else {
      Notifier.success("Your texts were successfully sent!");
    }

    setShowBroadcastModal(false);
  };

  const updateRepeatOptions = () => {
    let options: Option<string>[] = [
      { value: "daily", label: "Daily" },
      { value: "every_weekday", label: "Every weekday (Monday to Friday)" },
    ];

    if (!assignment?.recurrence_config) {
      options.unshift({ value: "does_not_repeat", label: "Does not repeat" });
    }

    let selectedOption;

    if (startsAt) {
      options = options.concat([
        { value: "weekly", label: `Weekly on ${startsAt.weekdayLong}s` },
        {
          value: "monthly_default",
          label: `Monthly on day ${startsAt.day}`,
        },
        { value: "annually", label: `Annually on ${startsAt.monthLong} ${startsAt.day}` },
      ]);
    }

    if (draftRecurrenceConfig) {
      const { matchingOption, isNewOption } = findMatchingRepeatOption(
        draftRecurrenceConfig,
        options,
        startsAt
      );
      selectedOption = matchingOption;
      if (isNewOption && matchingOption) {
        options = options.concat(matchingOption);
      }
    } else {
      selectedOption = null;
    }

    // Remove options with duplicate value property
    const optionsSet = new Set();
    const newOptions = options.filter((option) => {
      if (optionsSet.has(option.value)) {
        return false;
      }
      optionsSet.add(option.value);
      return true;
    });

    setRepeatOptions(newOptions.concat({ value: "new_custom", label: "Custom..." }));
    setSelectedRepeatOption(selectedOption);
  };

  useEffect(() => {
    updateRepeatOptions();
  }, [draftRecurrenceConfig, assignment, formData.starts_at, editing, startsAt]);

  useEffect(() => {
    if (!formData.starts_at) return;
    if (!startsAt && !endsAt) {
      setStartsAt(formData.starts_at);
      setEndsAt(formData.ends_at);
      return;
    }
    const duration = (endsAt?.toSeconds() || 0) - (startsAt?.toSeconds() || 0);
    const newEndsAt = formData.starts_at.plus({ seconds: duration });
    setStartsAt(formData.starts_at);
    setEndsAt(newEndsAt);
    setValue("ends_at", newEndsAt);
  }, [formData.starts_at]);

  useEffect(() => {
    if (!formData.ends_at) return;
    setEndsAt(formData.ends_at);
  }, [formData.ends_at]);

  const handleRepeatsChange = (option) => {
    let time_period = "day";
    const interval = 1;
    let days_of_the_week;
    let day_of_the_month;

    if (option.value === "new_custom" || option.value === "custom_config") {
      setShowRecurringModal(true);
      return;
    } else if (option.value === "weekly") {
      time_period = "week";
      days_of_the_week = startsAt ? [startsAt?.weekday] : undefined;
    } else if (option.value === "annually") {
      time_period = "year";
    } else if (option.value === "monthly_default") {
      time_period = "month";
      day_of_the_month = startsAt ? [startsAt?.day] : undefined;
    } else if (option.value === "does_not_repeat") {
      setDraftRecurrenceConfig(undefined);
      return;
    } else if (option.value === "daily") {
      time_period = "day";
    } else if (option.value === "every_weekday") {
      time_period = "week";
      days_of_the_week = [1, 2, 3, 4, 5];
    }

    // @ts-expect-error Time period
    setDraftRecurrenceConfig({ interval, time_period, day_of_the_month, days_of_the_week });
  };

  const renderFirstColumn = () => {
    let formattedStartsAt = initialStartsAt;

    if (!formattedStartsAt) {
      if (defaultStartTime) {
        formattedStartsAt = DateTime.now().set({
          hour: defaultStartTime.hour,
          minute: defaultStartTime.minute,
        });
      } else {
        formattedStartsAt = DateTime.now().startOf("hour");
      }
    } else {
      // If the schedule view isn't the week view where you can choose a time, use the default time regardless of the day
      if (scheduleView !== "customResourceTimeGridDay" && defaultStartTime) {
        formattedStartsAt = formattedStartsAt.set({
          hour: defaultStartTime.hour,
          minute: defaultStartTime.minute,
        });
      }
    }

    if (assignment?.starts_at) {
      formattedStartsAt = DateTime.fromSeconds(assignment.starts_at);
    }

    let formattedEndsAt = initialEndsAt;
    if (!formattedEndsAt) {
      formattedEndsAt = defaultDuration
        ? formattedStartsAt.plus({ minutes: defaultDuration })
        : formattedStartsAt.plus({ hours: 1 });
    } else {
      if (scheduleView !== "customResourceTimeGridDay" && defaultStartTime) {
        formattedEndsAt = defaultDuration
          ? formattedStartsAt.plus({ minutes: defaultDuration })
          : formattedStartsAt.plus({ hours: 1 });
      }
    }

    if (assignment?.ends_at) {
      formattedEndsAt = DateTime.fromSeconds(assignment.ends_at);
    }

    const defaultJob = jobOptions.find(
      (job) => job.value === assignment?.job || job.value === initialJob?._id
    );

    const finalJobOptions =
      mode === "read"
        ? jobOptions.map((j) => ({ ...j, label: truncate(j.label, { length: 35 }) }))
        : jobOptions;

    const defaultEquipment = equipmentOptions
      .filter((equipment) => assignment?.equipment_ids?.includes(equipment.value))
      .map((equipment) => equipment.value);

    return (
      <>
        {mode !== "read" ? (
          <Formblock
            label="Title*"
            type="text"
            defaultValue={assignment?.title}
            name="title"
            editing={editing}
            register={register(vals.required)}
            errors={errors}
            placeholder="A short descriptor for the assignment"
          />
        ) : (
          <></>
        )}
        <JobInput
          label="Job"
          labelInfo={"Select the job that you want to create this assignment for."}
          type="select"
          form={form}
          control={control}
          name="job"
          options={finalJobOptions}
          defaultValue={defaultJob?.value}
          errors={errors}
          editing={editing}
          isClearable={true}
          link={`/jobs/{{}}/details`}
        />
        <Formblock
          label="Address"
          // @ts-expect-error fix me
          defaultValue={assignment?.address || initialJob?.address || null}
          type="address"
          name="address"
          register={register}
          notRequiredRegister={register}
          control={control}
          // className="modal"
          errors={errors}
          editing={editing}
          autocomplete={true}
          setValue={setValue}
          link={"google-maps"}
          removeAddressLineBreak={true}
        />

        <br />
        {equipmentOptions.length > 0 && (
          <Formblock
            label="Equipment"
            labelInfo={"Select the equipment that you want to include in this assignment."}
            type="multiselect"
            name="equipment_ids"
            control={control}
            errors={errors}
            editing={editing}
            options={equipmentOptions}
            defaultValue={defaultEquipment}
            height="fit-content"
            isClearable={true}
          />
        )}
        <Formblock
          label="Start time*"
          labelInfo={"When does this assignment start."}
          type="datetime"
          name="starts_at"
          control={control}
          errors={errors}
          editing={editing}
          defaultValue={formattedStartsAt}
          timezone={enableJobBasedTimezones ? selectedJob?.timezone || undefined : undefined}
          rules={vals.required}
        />
        <Formblock
          label="End time*"
          labelInfo={"When will the assignment be finished."}
          type="datetime"
          name="ends_at"
          control={control}
          errors={errors}
          editing={editing}
          defaultValue={formattedEndsAt}
          rules={vals.required}
          timezone={enableJobBasedTimezones ? selectedJob?.timezone || undefined : undefined}
        />
        <Formblock
          label="Repeats"
          type="select"
          control={control}
          name="recurrence"
          onChange={handleRepeatsChange}
          value={selectedRepeatOption}
          defaultValue={selectedRepeatOption?.value}
          options={repeatOptions}
          maxMenuHeight={250}
          errors={errors}
          editing={editing}
          placeholder="Does not repeat"
        />
        <br />
        <Formblock
          label="Description"
          type="paragraph"
          defaultValue={assignment?.description || (mode === "read" ? "-" : "")}
          name="description"
          editing={editing}
          register={register}
          errors={errors}
          maxLength={1000}
          placeholder={"A longer description of the assignment"}
          defaultStringClassname={styles["assignment-description-string"]}
        />
        <div className="formblock-wrapper ">
          {mode !== "read" || files?.length ? <Label label="Attachments" style={{ marginTop: 10 }} /> : <></>}
          <div className="input-wrapper">
            {filesLoading && !(mode === "create") && <Loader />}
            <FilePicker
              type="button"
              files={files}
              onChange={(files) => setFiles(files)}
              readOnly={mode === "read"}
              label={<span>Drag & drop assignment attachments</span>}
              maxLabelLength={30}
              multiple
            />
          </div>
        </div>
        <br />
        {(mode !== "read" || assignment?.custom_color) && (
          <Formblock
            label="Custom color"
            defaultValue={assignment?.custom_color || null}
            type="color"
            name="custom_color"
            control={control}
            errors={errors}
            editing={editing}
          />
        )}
      </>
    );
  };

  const handleCustomRecurrenceModalHide = () => {
    updateRepeatOptions();
    setShowRecurringModal(false);
  };
  const renderTeamColumn = () => {
    const preSelectedTeamMembers = teamMembers.filter((teamMember) => {
      return assignment?.team_members?.includes(teamMember._id) || teamMember._id === initialTeamMember?._id;
    });

    return (
      <>
        <Controller
          name={"team_members"}
          control={control}
          render={({ onChange }) => (
            <TeamMemberSelect
              title="Assignees"
              handleSelect={onChange}
              formblock={true}
              version="assignment-team-members"
              preSelectedTeamMembers={preSelectedTeamMembers}
              readOnly={!editing}
              maxHeightOfTMBox={300}
              emptyMessage={!editing ? "No team members assigned" : undefined}
              warnings={mode === "create" || mode === "update" ? teamMemberWarnings : undefined}
            />
          )}
        />
        {mode === "read" && (
          <div className="formblock-wrapper modal">
            <Label className={"modal " + styles["send-broadcast"]} label="Message assignees" />
            <Button
              className={"button-1 no-margin " + styles["send-broadcast-btn"]}
              onClick={handleShowBroadcastModal}
            >
              <FaBullhorn />
              &nbsp; Send a broadcast
            </Button>
          </div>
        )}{" "}
      </>
    );
  };

  return (
    <>
      <div className={"modal-form-column " + styles["assignment-form-column"]}>{renderFirstColumn()}</div>
      <div
        className={"modal-form-column " + styles["assignment-form-column"]}
        style={{ borderLeft: "1px solid #ddd" }}
      >
        {renderTeamColumn()}
      </div>

      {showRecurringModal && (
        <CustomRecurrenceModal
          form={form}
          assignment={assignment}
          hide={handleCustomRecurrenceModalHide}
          draftRecurrenceConfig={draftRecurrenceConfig}
          setDraftRecurrenceConfig={setDraftRecurrenceConfig}
        />
      )}
      {showBroadcastModal && (
        <TeamCreateBroadcastModal
          onCancel={handleHideBroadcastModal}
          onCreate={handleBroadcastSubmission}
          defaultTeamMembers={assignmentTmObjects}
        />
      )}
    </>
  );
};

export default AssignmentForm;
