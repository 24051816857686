import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Notifier } from "ui/notifier";
import { Formblock, WizardScreen } from "ui";
import { I9FormProps } from "./I9Wizard";

import styles from "./I9Wizard.module.css";

import * as vals from "dashboard/utils/validators";
import {
  PERMANENT_RESIDENT_NUMBER_TYPE_OPTIONS,
  ALIEN_AUTHORIZATION_NUMBER_TYPE_OPTIONS,
  EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS,
  buildResidencyFormDefaults,
  ResidencyFormFields,
} from "miter-utils/i-9";
import { MiterAPI } from "dashboard/miter";

const ResidencyForm: React.FC<I9FormProps> = ({ I9, setI9, name = "Residency" }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/

  const form = useForm<ResidencyFormFields>({
    mode: "all",
    defaultValues: buildResidencyFormDefaults(I9),
    shouldUnregister: false,
  });

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, watch, formState, errors, setValue } = form;
  const { dirtyFields } = formState;

  const formData = watch();

  /*********************************************************
   * useEffect's
   * - Trigger form validation on change
   **********************************************************/
  useEffect(() => {
    form.trigger();
  }, [JSON.stringify(formData)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(save)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }
  };

  /*********************************************************
   *  Backend submission + cleaning functions
   **********************************************************/
  const cleanParams = (params: ResidencyFormFields) => {
    return {
      "section_1.employee_authorization_type": params.employee_authorization_type?.value || null,
      "section_1.permanent_resident_number_type": params.permanent_resident_number_type?.value || null,
      "section_1.alien_registration_number": params.alien_registration_number || null,
      "section_1.uscis_number": params.uscis_number || null,
      "section_1.alien_work_authorization_expiration_date":
        params.alien_work_authorization_expiration_date?.toISODate() || null,
      "section_1.alien_authorization_number_type": params.alien_authorization_number_type?.value || null,
      "section_1.foreign_passport_number": params.foreign_passport_number || null,
      "section_1.foreign_passport_country": params.foreign_passport_country || null,
      "section_1.form_i_94_number": params.form_i_94_number || null,

      // We do this to reset the preparer/employee signature if any of section 1's fields change
      "section_1.preparer_esignature_id": null,
      "section_1.preparer_esignature_date": null,
      "section_1.employee_esignature_id": null,
      "section_1.employee_esignature_date": null,

      // Need to keep section_2 employee_citizenship_status value in sync
      "section_2.employee_citizenship_status": params.employee_authorization_type?.value || null,
    };
  };

  const save = async (data: ResidencyFormFields) => {
    try {
      const params = cleanParams(data);
      // @ts-expect-error fix me
      const res = await MiterAPI.i_9s.update(I9._id, params);
      if (res.error) throw new Error(res.error);

      setI9(res);
    } catch (e: $TSFixMe) {
      console.log("Error submitting I-9 personal information form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   *  Form input handler functions
   **********************************************************/
  const clearLawfulPermanentResidentFields = () => {
    setValue("alien_registration_number", null, { shouldDirty: true });
    setValue("uscis_number", null, { shouldDirty: true });
  };

  const clearAlienAuthorizedToWorkFields = () => {
    setValue("alien_authorization_number_type", null, { shouldDirty: true });
    setValue("alien_work_authorization_expiration_date", null, { shouldDirty: true });

    clearAlientAuthorizationNumberTypeFields();
  };

  const clearAlientAuthorizationNumberTypeFields = () => {
    setValue("foreign_passport_number", null, { shouldDirty: true });
    setValue("foreign_passport_country", null, { shouldDirty: true });
    setValue("form_i_94_number", null, { shouldDirty: true });
    setValue("alien_registration_number", null, { shouldDirty: true });
    setValue("uscis_number", null, { shouldDirty: true });
  };

  const clearAllFields = () => {
    clearLawfulPermanentResidentFields();
    clearAlienAuthorizedToWorkFields();
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderLawfulPermanentResidentFields = () => {
    if (formData.employee_authorization_type?.value !== "lawful_permanent_resident") return;
    const permanentResidentNumberType = formData.permanent_resident_number_type?.value;

    return (
      <>
        <Formblock
          type="select"
          name="permanent_resident_number_type"
          label="Permanent Resident Number Type*"
          form={form}
          editing={true}
          className="modal wizard"
          rules={vals.required}
          options={PERMANENT_RESIDENT_NUMBER_TYPE_OPTIONS}
          isClearable={true}
          onChange={clearLawfulPermanentResidentFields}
        />
        {permanentResidentNumberType === "alien_registration_number" && (
          <Formblock
            type="text"
            name="alien_registration_number"
            placeholder="Enter Noncitizen Registration Number*"
            form={form}
            editing={true}
            className="modal wizard"
            val={vals.required}
          />
        )}
        {permanentResidentNumberType === "uscis_number" && (
          <Formblock
            type="text"
            name="uscis_number"
            placeholder="USCIS Number* (without hyphen)"
            form={form}
            editing={true}
            className="modal wizard"
            val={vals.isWithinLengthRange(0, 10)}
          />
        )}
      </>
    );
  };

  const renderAlienAuthorizedToWorkFields = () => {
    if (formData.employee_authorization_type?.value !== "alien_authorized_to_work") return;

    const alienAuthNumberType = formData.alien_authorization_number_type?.value;

    return (
      <div className="form-section">
        <Formblock
          type="datetime"
          name="alien_work_authorization_expiration_date"
          label="Work Authorization Expiration Date"
          form={form}
          editing={true}
          className="modal wizard"
          dateOnly={true}
          placeholder={"Select a date or leave blank if not applicable"}
          onChange={clearAlienAuthorizedToWorkFields}
        />

        <div className={styles["callout"]} style={{ marginTop: 25, marginBottom: 25 }}>
          <strong>
            Noncitizens authorized to work must provide only one of the following document numbers to complete
            Form I-9
          </strong>
          <div style={{ marginTop: 15 }} />
          <div>
            <ul>
              <li>A Noncitizen Registration Number/USCIS Number</li>
              <li>Form I-94 Admission Number</li>
              <li>Foreign Passport Number</li>
            </ul>
          </div>
        </div>
        <Formblock
          type="select"
          name="alien_authorization_number_type"
          label="Document number type*"
          form={form}
          editing={true}
          className="modal wizard"
          rules={vals.required}
          options={ALIEN_AUTHORIZATION_NUMBER_TYPE_OPTIONS}
          isClearable={true}
          onChange={clearAlientAuthorizationNumberTypeFields}
        />

        {alienAuthNumberType === "alien_registration_number" && (
          <Formblock
            type="text"
            name="alien_registration_number"
            placeholder="Enter Noncitizen Registration Number*"
            form={form}
            editing={true}
            className="modal wizard"
            val={vals.required}
          />
        )}
        {alienAuthNumberType === "uscis_number" && (
          <Formblock
            type="text"
            name="uscis_number"
            placeholder="USCIS Number* (without hyphen)"
            form={form}
            editing={true}
            className="modal wizard"
            val={vals.isWithinLengthRange(0, 10)}
          />
        )}

        {alienAuthNumberType === "foreign_passport_number" && (
          <>
            <Formblock
              type="text"
              name="foreign_passport_number"
              placeholder="Enter Foreign Passport Number*"
              form={form}
              editing={true}
              className="modal wizard"
              val={vals.required}
            />
            <Formblock
              type="text"
              name="foreign_passport_country"
              placeholder="Enter Country of Issuance*"
              form={form}
              editing={true}
              className="modal wizard"
              val={vals.required}
            />
          </>
        )}
        {alienAuthNumberType === "form_i_94_number" && (
          <Formblock
            type="text"
            name="form_i_94_number"
            placeholder="Enter Form I-94 Admission Number*"
            form={form}
            editing={true}
            className="modal wizard"
            val={vals.required}
          />
        )}
      </div>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["residency-form"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Enter your residency information</h2>
        </div>
        <div className="form-section">
          <Formblock
            type="select"
            name="employee_authorization_type"
            label="Citizenship/residency status"
            sublabel="I attest, under penalty of perjury, that I am a:"
            form={form}
            editing={true}
            className="modal wizard"
            rules={vals.required}
            options={EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS}
            labelStyle={{ marginBottom: "15px" }}
            sublabelStyle={{ marginTop: 5 }}
            isClearable={true}
            onChange={clearAllFields}
          />
        </div>
        {renderLawfulPermanentResidentFields()}
        {renderAlienAuthorizedToWorkFields()}
      </div>
    </WizardScreen>
  );
};

export default ResidencyForm;
