/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlatfileResults } from "@flatfile/react";
import {
  useActiveCompanyId,
  useCostTypeOptions,
  useLedgerAccountOptions,
  useTeam,
} from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage, normalizeDate, validateFlatfileNumber } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { Notifier } from "ui";
import { keyBy } from "lodash";
import { MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../importer/Importer";
import { CleanedBenefitFormData } from "backend/services/benefits-service";
import { CheckBenefitType, CheckHSALimit } from "backend/utils/check/check-types";
import { benefitSelections } from "dashboard/pages/benefits/benefitsUtils";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";
import { IDataHookRecord } from "@flatfile/adapter/build/main/interfaces";

type EmployeeBenefitContributionType =
  | "contribution_amount"
  | "contribution_percent"
  | "period_amount"
  | "per_hour";

type PrelimEmployeeBenefitImportRow = {
  teamMemberId: string;
  benefitType: CheckBenefitType;
  employeeContributionType: EmployeeBenefitContributionType;
  employeeContributionValue?: string;
  companyContributionType: EmployeeBenefitContributionType;
  companyContributionValue?: string;
  effectiveStart?: string;
  effectiveEnd?: string;
  hsaContributionLimit?: CheckHSALimit | null;
  empLiabAccountId?: string;
  comLiabAccountId?: string;
  expenseAccountId?: string;
  costTypeId?: string;
  description?: string;
  planNumber?: string;

  /** "true" or "false" */
  nonBonafide?: "true" | "false";

  /** "true" or "false" */
  isContractorsPlan?: "true" | "false";

  deductionCode?: string;

  groupTermLifeAmount?: number;
};

type Props = {
  onFinish: () => void;
};

export const EmployeeBenefitImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeCompanyId = useActiveCompanyId();
  const teamMembers = useTeam();
  const ledgerAccounts = useLedgerAccountOptions();
  const costTypeOptions = useCostTypeOptions();
  const { enIntegration } = useEmployeeNavigator();

  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);
  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/
  const buildEmployeeBenefitParams = (row: PrelimEmployeeBenefitImportRow): CleanedBenefitFormData => {
    if (!activeCompanyId) throw new Error("No active role");

    const {
      teamMemberId,
      benefitType,
      employeeContributionType,
      employeeContributionValue,
      companyContributionType,
      companyContributionValue,
      effectiveStart,
      effectiveEnd,
      hsaContributionLimit,
      empLiabAccountId,
      comLiabAccountId,
      expenseAccountId,
      costTypeId,
      description,
      nonBonafide,
      planNumber,
      isContractorsPlan,
      deductionCode,
      groupTermLifeAmount,
    } = row;

    const teamMember = lookupTeamID[teamMemberId];
    if (!teamMember) throw new Error(`Team member ${teamMemberId} not found`);

    const params: CleanedBenefitFormData = {
      company: activeCompanyId,
      employee: teamMember._id,
      benefit: benefitType,
      description: description || null,
      effective_start: effectiveStart || null,
      effective_end: effectiveEnd || null,
      emp_liab_account_id: empLiabAccountId || null,
      com_liab_account_id: comLiabAccountId || null,
      expense_account_id: expenseAccountId || null,
      cost_type_id: costTypeId || null,
      non_bonafide: nonBonafide === "true" ? true : false,
      plan_number: planNumber,
      hsa_contribution_limit: hsaContributionLimit || null,
      group_term_life_amount:
        (benefitType === "125_life" &&
          ["contribution_amount", "period_amount"].includes(companyContributionType) &&
          Number(groupTermLifeAmount)) ||
        null,
    };

    if (isContractorsPlan === "true") {
      params.integrations = { ...params.integrations, contractors_plan: { is_cp_benefit: true } };
    }

    if (enIntegration && enIntegration.connection && deductionCode) {
      params.integrations = {
        ...params.integrations,
        employee_navigator: {
          integration_connection_id: enIntegration.connection._id,
          deduction_code: deductionCode,
          connected: false,
        },
      };
    }

    // Set the employee contribution
    if (employeeContributionType === "contribution_amount") {
      params.employee_contribution_amount = employeeContributionValue || null;
    } else if (employeeContributionType === "contribution_percent") {
      params.employee_contribution_percent = Number(employeeContributionValue) || null;
    } else if (employeeContributionType === "period_amount") {
      params.employee_period_amount = employeeContributionValue || null;
      params.period = "monthly";
    } else if (employeeContributionType === "per_hour") {
      params.employee_contribution_amount = employeeContributionValue || null;
      params.per_hour_employee_contribution = true;
    }

    // Set the company contribution
    if (companyContributionType === "contribution_amount") {
      params.company_contribution_amount = companyContributionValue || null;
    } else if (companyContributionType === "contribution_percent") {
      params.company_contribution_percent = Number(companyContributionValue) || null;
    } else if (companyContributionType === "period_amount") {
      params.company_period_amount = companyContributionValue || null;
      params.period = "monthly";
    } else if (companyContributionType === "per_hour") {
      params.company_contribution_amount = companyContributionValue || null;
      params.per_hour_company_contribution = true;
    }

    return params;
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedEmployeeBenefits = results.validData.map(buildEmployeeBenefitParams);

      const response = await MiterAPI.benefits.employee.import({
        clean_inputs: preppedEmployeeBenefits,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;
      const warnings = response.results.warnings.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(
            `Imported ${successes} employee benefits with ${errors} errors and ${warnings} warnings.`
          );
        } else {
          Notifier.success(`Imported ${successes} employee benefits with ${warnings} warnings.`);
        }
      } else {
        Notifier.error(`There were ${errors} errors and ${warnings} warnings.`);
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the employee benefits.");
    }
  };

  /** If there is a team member ID, validate that the team member exists */
  const validateTeamMemberID = (teamMemberId: string) => {
    const teamMember = lookupTeamID[teamMemberId];
    if (!teamMember) {
      return buildFlatfileMessage("Team member not found", teamMemberId, "error");
    }

    return { value: teamMemberId };
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  const contributionTypeOptions = [
    { label: "$ per payroll", value: "contribution_amount" },
    { label: "% of earnings", value: "contribution_percent" },
    { label: "$ per month", value: "period_amount" },
    { label: "$ per hour", value: "per_hour" },
  ];

  const validateGroupTermLifeAmount = (value: any): IDataHookRecord | undefined => {
    if (
      (value.benefitType !== "125_life" ||
        !["contribution_amount", "period_amount"].includes(value.companyContributionType)) &&
      !!value.groupTermLifeAmount
    ) {
      return buildFlatfileMessage(
        "Imputed amounts are only applicable to life benefits with per-month or per-payroll company contributions - this value will not be applied.",
        value.groupTermLifeAmount,
        "warning"
      );
    }
    return validateFlatfileNumber(value.groupTermLifeAmount);
  };

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Employee ID number",
        type: "string",
        key: "teamMemberId",
        description: "Unique identifer for team member (must be same in source system and Miter).",
        validators: [{ validate: "required" }],
        hook: (val) =>
          typeof val === "string" ? validateTeamMemberID(val) : validateTeamMemberID(val.teamMemberId),
      },
      {
        label: "Benefit type",
        type: "select",
        key: "benefitType",
        description: "The type of benefit you would like to create.",
        validators: [{ validate: "required" }],
        options: benefitSelections,
      },
      {
        label: "Description",
        type: "string",
        key: "description",
        description: "A description of the benefit.",
      },
      {
        label: "Employee contribution type",
        type: "select",
        key: "employeeContributionType",
        description: "The type of employee contribution.",
        validators: [{ validate: "required" }],
        options: contributionTypeOptions,
      },
      {
        label: "Employee contribution value",
        type: "string",
        key: "employeeContributionValue",
        description: "The amount the employee will contribute.",
        validators: [{ validate: "required" }],
        hook: (val) => {
          return typeof val === "string"
            ? validateFlatfileNumber(val)
            : validateFlatfileNumber(val.employeeContributionValue);
        },
      },
      {
        label: "Company contribution type",
        type: "select",
        key: "companyContributionType",
        description: "The type of company contribution.",
        validators: [{ validate: "required" }],
        options: contributionTypeOptions,
      },
      {
        label: "Company contribution value",
        type: "string",
        key: "companyContributionValue",
        description: "The amount the company will contribute.",
        validators: [{ validate: "required" }],
        hook: (val) => {
          return typeof val === "string"
            ? validateFlatfileNumber(val)
            : validateFlatfileNumber(val.companyContributionValue);
        },
      },
      {
        label: "Effective start date",
        type: "string",
        key: "effectiveStart",
        description: "The date the benefit will go into effect.",
        hook: (val) => (typeof val === "string" ? normalizeDate(val) : normalizeDate(val.effectiveStart)),
      },
      {
        label: "Effective end date",
        type: "string",
        key: "effectiveEnd",
        description: "The date the benefit will end.",
        hook: (val) => (typeof val === "string" ? normalizeDate(val) : normalizeDate(val.effectiveEnd)),
      },
      {
        label: "HSA contribution limit",
        type: "select",
        key: "hsaContributionLimit",
        description: "The HSA contribution limit.",
        options: [
          { label: "single", value: "single" },
          { label: "family", value: "family" },
        ],
      },
      {
        label: "Employee liability account",
        type: "select",
        key: "empLiabAccountId",
        description: "The account ID for the employee liability associated with this benefit.",
        options: ledgerAccounts,
      },
      {
        label: "Company liability account",
        type: "select",
        key: "comLiabAccountId",
        description: "The account ID for the company liability associated with this benefit.",
        options: ledgerAccounts,
      },
      {
        label: "Company expense account",
        type: "select",
        key: "expenseAccountId",
        description: "The account ID for the company expense associated with this benefit.",
        options: ledgerAccounts,
      },
      {
        label: "Cost type",
        type: "select",
        key: "costTypeId",
        description: "An optional cost type for the company expense associated with this benefit.",
        options: costTypeOptions,
      },
      {
        label: "Plan number",
        type: "string",
        key: "planNumber",
        description: "Plan number associated with this benefit",
      },
      {
        label: "Non-bonafide",
        type: "select",
        key: "nonBonafide",
        description: "Is this a non-bonafide plan?",
        options: [
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ],
      },
      {
        label: "Contractors plan",
        type: "select",
        key: "isContractorsPlan",
        description: "Is this a contractors plan?",
        options: [
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ],
      },
      {
        label: "Deduction code",
        type: "string",
        key: "deductionCode",
        description: "Employee Navigator deduction code",
      },
      {
        label: "Imputed earning amount",
        type: "string",
        key: "groupTermLifeAmount",
        description:
          "Only for Life (pre-tax) with per-payroll or per-monthly company contributions, you can specify an earning amount to represent imputed income. This additional imputed income will follow the cadence of the company contribution.",
        hook: (val) => {
          return typeof val === "string" ? validateFlatfileNumber(val) : validateGroupTermLifeAmount(val);
        },
      },
    ];

    return fieldList;
  }, []);

  return (
    <Importer id="employee_benefits" resource="employee benefits" onSave={handleSubmit} fields={fields} />
  );
};
