import { useMemo, useState } from "react";
import { AggregatedPayroll, MiterAPI } from "dashboard/miter";
import { Notifier } from "ui";
import { downloadPlainTextFromBlob } from ".";
import { Sage300JcdWarning } from "backend/services/sage300/sage300-types";

const timeEntriesFileName = "Miter_Sage_Job_Cost_Upload.jcd";

export type UseSage300LedgerEntriesCSVReturnType = {
  loading: boolean;
  build: (p: { ledgerEntryIds: string[]; draftPayroll?: AggregatedPayroll }) => Promise<void>;
  warnings: Sage300JcdWarning[] | undefined;
  setWarnings: (warnings: Sage300JcdWarning[] | undefined) => void;
  downloadSage300ExportFile: (jcd: string, fileName: string) => void;
  data: string | undefined;
  timeEntriesFileName: string;
};

export const useSage300LedgerEntriesCsv = (
  icId: string | undefined
): UseSage300LedgerEntriesCSVReturnType => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string>();
  const [warnings, setWarnings] = useState<Sage300JcdWarning[]>();

  const downloadSage300ExportFile = (jcd: string, fileName: string) => {
    downloadPlainTextFromBlob(jcd, fileName);
  };

  const build = async (p: { ledgerEntryIds: string[]; draftPayroll?: AggregatedPayroll }) => {
    if (!icId) return;
    const { ledgerEntryIds, draftPayroll } = p;
    setLoading(true);
    try {
      const response = await MiterAPI.ledger_entries.get_sage_300_jcd({
        ledgerEntryIds,
        draftPayroll,
        icId: icId,
      });
      if (response.error) throw new Error(response.error);
      if (response.warnings.length) {
        setWarnings(response.warnings);
        setData(response.jcd);
      } else {
        downloadSage300ExportFile(response.jcd, timeEntriesFileName);
      }
    } catch (e: $TSFixMe) {
      Notifier.error(`Error building Sage 300 file: ${e.message}`);
    }
    setLoading(false);
  };

  return useMemo(
    () => ({
      loading,
      build,
      warnings,
      setWarnings,
      downloadSage300ExportFile,
      data,
      timeEntriesFileName,
    }),
    [icId, warnings, data, loading]
  );
};
