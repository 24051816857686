import { uniqueFlatMap } from "miter-utils";
import { useCallback, useMemo } from "react";
import { JobPostingScopeGroup } from "backend/models/permission-group";
import { useActiveTeamMember, useHydratedPermissionGroups, useJobPostings } from "../atom-hooks";
import { AggregatedJobPosting } from "dashboard/types/ats";
import { useTeamMemberGroupUnfurler } from "./useTeamMemberScopes";
import { PermissionPaths } from "dashboard/miter";

type JobPostingScopesHelperData = {
  isHiringManagerPostings: AggregatedJobPosting[];
  onHiringTeamPostings: AggregatedJobPosting[];
};

type UseJobPostingScopeUnfurler = {
  (jobPostingScopes: JobPostingScopeGroup[] | null): AggregatedJobPosting[];
};

/**
 *  This hook returns a function that takes a job posting scope group and returns a list of job postings that match the scope.
 *  It is used to get the list of job postings that a user can take an action on based on their scope.
 */
export const useJobPostingGroupUnfurler = (): UseJobPostingScopeUnfurler => {
  const activeTeamMember = useActiveTeamMember();
  const jobPostings = useJobPostings();
  const unfurlTeamMembers = useTeamMemberGroupUnfurler();

  const helperData: JobPostingScopesHelperData = useMemo(() => {
    const isHiringManagerPostings: AggregatedJobPosting[] = [];
    const onHiringTeamPostings: AggregatedJobPosting[] = [];

    const jobPostingToHiringTeamLookup = new Map<string, string[]>();

    for (const jobPosting of jobPostings) {
      const hiringTeamSpecified = !!jobPosting.hiring_team?.length;
      jobPostingToHiringTeamLookup.set(
        jobPosting._id,
        (hiringTeamSpecified ? unfurlTeamMembers(jobPosting.hiring_team!) : []).map((tm) => tm._id)
      );
    }

    // Iterate through each job to build each of the helper job lists
    jobPostings.forEach((jobPosting) => {
      if (!activeTeamMember) return;

      // Add the job to the list of jobs that the user is managing
      const isHiringManager = jobPosting.hiring_manager_id === activeTeamMember?._id;
      const isOnHiringTeam = jobPostingToHiringTeamLookup.get(jobPosting._id)?.includes(activeTeamMember._id);

      if (isHiringManager) {
        isHiringManagerPostings.push(jobPosting);
      }
      if (isOnHiringTeam) {
        onHiringTeamPostings.push(jobPosting);
      }
    });

    return {
      isHiringManagerPostings,
      onHiringTeamPostings,
    };
  }, [activeTeamMember, jobPostings, unfurlTeamMembers]);

  const unfurlJobPostingGroups = useCallback(
    (jobPostingScopes: JobPostingScopeGroup[] | null): AggregatedJobPosting[] => {
      // If no scopes are provided, return all job postings
      if (!jobPostingScopes?.length) return jobPostings;

      const { isHiringManagerPostings, onHiringTeamPostings } = helperData;

      const jobPostingArrays = jobPostingScopes.map((jobPostingScope) => {
        switch (jobPostingScope.type) {
          case "hiring_manager":
            return isHiringManagerPostings;
          case "hiring_team":
            return onHiringTeamPostings;
          default:
            return [];
        }
      });

      return uniqueFlatMap(jobPostingArrays, (jp) => jp._id);
    },
    [jobPostings, helperData, activeTeamMember]
  );

  return unfurlJobPostingGroups;
};

type JobPostingScopesHelpers = {
  getJobPostingPermissions: (jobPostingId: string) => {
    can: (action: PermissionPaths) => boolean;
    cannot: (action: PermissionPaths) => boolean;
  };
  getJobPostingIdsForPermission: (permission: PermissionPaths) => string[];
};

/**
 *  This hook returns a set of functions that help build the job posting scopes for the user.
 */
export const useJobPostingScopesHelpers = (): JobPostingScopesHelpers => {
  const hydratedPermissionGroups = useHydratedPermissionGroups();

  const getJobPostingPermissions = useCallback(
    (jobPostingId: string) => {
      const can = (action: PermissionPaths): boolean => {
        if (!action) return false;
        return hydratedPermissionGroups.some((pg) =>
          pg.moduleHelpers.recruiting.hasScopedJobPosting(jobPostingId)
        );
      };

      const cannot = (action: PermissionPaths): boolean => {
        return !can(action);
      };

      return { can, cannot };
    },
    [hydratedPermissionGroups]
  );

  /**
   * Get's the list of jobs that we can take an action on
   *
   * @param permission - The permission we want to take
   * @param mod - The module we want to take the action in (e.g. human_resources, workforce_management, etc)
   * @returns {string[]}
   */
  const getJobPostingIdsForPermission = useCallback(
    (permission: PermissionPaths) => {
      const jobPostingIds = hydratedPermissionGroups.flatMap((item) => {
        if (item.abilities.can(permission || "")) {
          return item.moduleHelpers.recruiting.scopedJobPostings.ids || [];
        } else {
          return [];
        }
      });

      return jobPostingIds;
    },
    [hydratedPermissionGroups]
  );

  return {
    getJobPostingPermissions,
    getJobPostingIdsForPermission,
  };
};
