import { ApprenticeshipProgram, AggregatedJob } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import { Button, Formblock } from "ui";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { isValidAddress } from "dashboard/utils";

type Props = {
  form: $TSFixMe;
  programs: ApprenticeshipProgram[];
  selectedJob: AggregatedJob;
  onSubmit: (d) => void;
  setSelectedApprenticeProgram: (v) => void;
  setReportDate: (d) => void;
  reportDate: DateTime;
  loading: boolean;
};

export const ApprenticeInfo: React.FC<Props> = ({
  form,
  programs,
  selectedJob,
  onSubmit,
  loading,
  reportDate,
  setReportDate,
  setSelectedApprenticeProgram,
}) => {
  const [county, setCounty] = useState();
  const [occupation, setOccupation] = useState();
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [reportDateReminder, setReportDateReminder] = useState(false);

  const counties = programs.map((p) => p.county);
  const countyOptions = [...new Set(counties)].map((o) => {
    return { label: o, value: o };
  });

  const occupations = programs.map((p) => p.occupation);
  const occupationOptions = [...new Set(occupations)].map((o) => {
    return { label: o, value: o };
  });

  const apprenticeshipProgramOptions = useMemo(() => {
    if (!county || !occupation) return undefined;
    setSelectedProgram(null);
    setSelectedApprenticeProgram(undefined);
    return programs
      .filter((p) => p.county === county && p.occupation === occupation)
      .map((p) => {
        return { label: p.committee, value: p._id };
      });
  }, [county, occupation]);

  const handleReportDateChange = (dt) => {
    const now = DateTime.now();
    if (dt.diff(now, "days").toObject().days > 3) {
      setReportDateReminder(true);
    } else {
      setReportDateReminder(false);
    }
    setReportDate(dt);
  };

  return (
    <div>
      <div className="bold">Apprenticeship committee</div>
      <div className="vertical-spacer"></div>
      <Formblock
        type="select"
        name="county"
        register={form.register(vals.required)}
        control={form.control}
        label="County"
        errors={form.errors}
        onChange={(option) => setCounty(option.value)}
        editing={true}
        options={countyOptions}
      />
      <Formblock
        type="select"
        name="occupation"
        register={form.register(vals.required)}
        control={form.control}
        label="Occupation"
        onChange={(o) => setOccupation(o.value)}
        errors={form.errors}
        editing={true}
        options={occupationOptions}
      />
      {apprenticeshipProgramOptions && (
        <Formblock
          type="select"
          name="apprenticeship_committee"
          register={form.register(vals.required)}
          control={form.control}
          label="Apprenticeship committee"
          requiredSelect={true}
          errors={form.errors}
          value={selectedProgram}
          onChange={(option) => {
            setSelectedProgram(option);
            setSelectedApprenticeProgram(programs.find((p) => p._id.toString() === option.value));
          }}
          editing={true}
          options={apprenticeshipProgramOptions}
        />
      )}
      <div className="vertical-spacer"></div>

      <div className="bold">Dispatch request information</div>
      <div className="vertical-spacer"></div>

      <Formblock
        label="# of apprentices needed"
        register={form.register(vals.required)}
        type="text"
        name="number_apprentices"
        errors={form.errors}
        editing={true}
      />
      <Formblock
        type="datetime"
        name="report_date"
        control={form.control}
        rules={vals.required}
        label="Apprentices to report at"
        errors={form.errors}
        min={DateTime.now()}
        defaultValue={reportDate}
        onChange={handleReportDateChange}
        editing={true}
      />
      {reportDateReminder && (
        <div style={{ color: "red", fontSize: 13, marginBottom: 15 }}>
          As a reminder, you must submit the DAS form within 3 business days of the apprentice&apos;s start
          date.
        </div>
      )}
      <Formblock
        label="Name of person to report to"
        register={form.register(vals.required)}
        type="text"
        name="report_person_name"
        errors={form.errors}
        editing={true}
      />
      <Formblock
        label="Address to report to"
        register={form.register}
        control={form.control}
        type="address"
        name="report_address"
        errors={form.errors}
        defaultValue={isValidAddress(selectedJob?.address) ? selectedJob.address : undefined}
        editing={true}
      />
      <div className="flex" style={{ marginTop: 40 }}>
        <div className="flex-1"></div>
        <Button
          text="Next"
          onClick={form.handleSubmit(onSubmit)}
          className="button-2 no-margin"
          loading={loading}
        />
      </div>
    </div>
  );
};
