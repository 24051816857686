import React, { useRef, useState } from "react";
import { ApprovalFlowForm, RuleBuilderSection } from "./PolicyRuleBuilder";
import { Reorder } from "framer-motion";

import styles from "./PolicyRules.module.css";
import { Button } from "ui";
import { DotsSix, Plus, X } from "phosphor-react";
import { GroupTypeOptions } from "dashboard/hooks/useTeamMemberGroups";
import ObjectID from "bson-objectid";
import { ApprovalStage } from "./ApprovalStage";
import { OrOption, getApprovalItemType } from "./PolicyConstants";
import { PolicyType } from "backend/services/approvals/types";
import pluralize from "pluralize";

export type ApprovalFlowStageForm = ApprovalFlowForm[number];

type ApprovalFlowBuilderProps = {
  policyType: PolicyType;
  autoApprove: boolean;
  approvalFlow: ApprovalFlowForm;
  setApprovalFlow: React.Dispatch<React.SetStateAction<ApprovalFlowForm>>;
  setAutoApprove: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ApprovalFlowBuilder: React.FC<ApprovalFlowBuilderProps> = ({
  policyType,
  autoApprove,
  approvalFlow,
  setApprovalFlow,
  setAutoApprove,
}) => {
  /*********************************************************
   * Important hooks
   **********************************************************/
  const stagesRef = useRef<HTMLDivElement>(null);
  const stageRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const [draggable, setDraggable] = useState(false);

  const showApprovalForm = autoApprove || approvalFlow.length > 0;
  const itemType = getApprovalItemType(policyType);

  /*********************************************************
   *  Handler functions
   **********************************************************/
  const handleAddApprovalFlow = () => {
    const newFlow = {
      _id: ObjectID().toHexString(),
      approvers: [{ _id: ObjectID().toHexString(), type: GroupTypeOptions[0], value: undefined }],
      condition: OrOption,
    };

    setApprovalFlow([newFlow]);
    setAutoApprove(false);
  };

  const handleAddAutoApproval = () => {
    setAutoApprove(true);
    setApprovalFlow([]);
  };

  const handleRemoveAutoApproval = () => {
    setAutoApprove(false);
  };

  const handleAddStage = () => {
    const newStage: ApprovalFlowStageForm = {
      _id: ObjectID().toHexString(),
      approvers: [{ _id: ObjectID().toHexString(), type: GroupTypeOptions[0], value: undefined }],
      condition: OrOption,
    };

    const newApprovalFlow = [...approvalFlow, newStage];
    setApprovalFlow(newApprovalFlow);
  };

  const handleReorderStages = (newApprovalFlow: ApprovalFlowForm) => {
    setApprovalFlow(newApprovalFlow);
  };

  const handleAddApprover = (stage: ApprovalFlowStageForm) => {
    const newApprover: ApprovalFlowForm[number]["approvers"][number] = {
      _id: ObjectID().toHexString(),
      type: GroupTypeOptions[0],
      value: undefined,
    };

    const newApprovers = [...stage.approvers, newApprover];
    const newStage = { ...stage, approvers: newApprovers };

    const newApprovalFlow = approvalFlow.map((s) => (s._id === stage._id ? newStage : s));
    setApprovalFlow(newApprovalFlow);
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderDraggable = () => {
    return (
      <div
        className={styles["draggable"]}
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
        onTouchStart={() => setDraggable(true)}
      >
        <DotsSix className="draggable-item" />
      </div>
    );
  };

  const renderAddStage = () => {
    return (
      <Button className="button-1 no-margin" onClick={handleAddStage}>
        <Plus style={{ marginRight: 5 }} />
        Add stage
      </Button>
    );
  };

  const renderStage = (stage: ApprovalFlowForm[number], index: number) => {
    return (
      <Reorder.Item
        key={"stage-" + stage._id}
        value={stage}
        dragListener={draggable}
        className={styles["approval-stage-container"]}
        onDragEnd={() => setDraggable(false)}
        layout="position"
        ref={(r) => (stageRefs.current[stage._id] = r)}
        id={stage._id}
        dragConstraints={stagesRef}
      >
        <div className="relative flex no-select-children">
          {renderDraggable()}
          <ApprovalStage
            approvalFlow={approvalFlow}
            setApprovalFlow={setApprovalFlow}
            stage={stage}
            index={index}
            removeJobApprovers={policyType === "time_off_request"}
          />
        </div>
        <Button
          className="button-text purple-link"
          wrapperStyle={{ marginLeft: 54, marginTop: 15, marginBottom: 5 }}
          style={{ fontSize: "0.9rem" }}
          onClick={() => handleAddApprover(stage)}
        >
          <Plus style={{ marginRight: 5 }} />
          Add approver
        </Button>
      </Reorder.Item>
    );
  };

  const renderStages = () => {
    if (autoApprove) return;

    return (
      <>
        <Reorder.Group
          className={styles["approval-stages"]}
          axis="y"
          values={approvalFlow}
          onReorder={handleReorderStages}
          ref={stagesRef}
        >
          {approvalFlow.map((stage, index) => renderStage(stage, index))}
        </Reorder.Group>
        {renderAddStage()}
      </>
    );
  };

  const renderAutoApproval = () => {
    if (!autoApprove) return;

    return (
      <div className={styles["auto-approval"]}>
        <div className={styles["auto-approval-text"]}>Auto-approve this item</div>
        <Button
          className="button-text"
          style={{ marginLeft: -5, color: "#777" }}
          onClick={handleRemoveAutoApproval}
        >
          <X />
        </Button>
      </div>
    );
  };

  const renderApprovalForm = () => {
    return (
      <div className={styles["rule-builder-light-grey-section"]}>
        {renderAutoApproval()}
        {renderStages()}
      </div>
    );
  };

  const renderAddButton = () => {
    return (
      <div className="flex" style={{ gap: 10 }}>
        <Button className="button-1 tall-button no-margin" onClick={handleAddApprovalFlow}>
          <Plus style={{ marginRight: 7 }} />
          Add approval flow
        </Button>
        {policyType !== "team_member_change_request" && (
          <Button className="button-1 tall-button no-margin" onClick={handleAddAutoApproval}>
            <Plus style={{ marginRight: 7 }} />
            Add auto approve
          </Button>
        )}
      </div>
    );
  };

  return (
    <RuleBuilderSection
      title={"Use this approval flow"}
      subtitle={`Setup and customize the approval workflow for all ${pluralize(
        itemType
      )} that fall under this policy rule.`}
    >
      {showApprovalForm && renderApprovalForm()}
      {!showApprovalForm && renderAddButton()}
    </RuleBuilderSection>
  );
};
