import { ActionModal, Loader } from "ui";
import React, { useEffect, useState } from "react";
import { IntegrationConnection, MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Option } from "ui/form/Input";
import { Notifier } from "dashboard/utils";
import checkmark from "dashboard/assets/check-mark.png";
import { Navigate, useNavigate } from "react-router-dom";
import { JonasCompany } from "backend/services/jonas/jonas-types";

export const JonasCompanySelector: React.FC<{
  integration: MiterIntegrationForCompany;
  updateIntegrationConnection: (
    update: Partial<IntegrationConnection> | Record<string, $TSFixMe>
  ) => Promise<void>;
}> = ({ integration, updateIntegrationConnection }) => {
  const navigate = useNavigate();
  const [companyOptions, setCompanyOptions] = useState<
    (Option<string> & { obj: JonasCompany })[] | undefined
  >();
  const [connecting, setConnecting] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();

  const getCompanyOptions = async () => {
    if (!integration.connection?._id) return;
    try {
      const result = await MiterAPI.integrations.get_jonas_companies(integration.connection?._id);
      if (result.error) throw new Error(result.error);
      const optionList = result.map((jc) => {
        return { value: jc.CompanyId, label: jc.CompanyName, obj: jc };
      });
      setSelectedCompany(optionList[0]?.value);
      setCompanyOptions(optionList);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving the Jonas company list.");
    }
  };

  const connectJonasCompany = async () => {
    if (!integration.connection?.metadata?.jonas) return;
    setConnecting(true);
    try {
      if (!selectedCompany) throw new Error("Company ID not found.");
      const company = companyOptions?.find((co) => co.value === selectedCompany)?.obj;
      if (!company) throw new Error("Company not found.");
      await updateIntegrationConnection({ "metadata.jonas.company": company });
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error connecting to Jonas. Our engineers have been notified.");
    }
    setConnecting(false);
  };

  useEffect(() => {
    getCompanyOptions();
  }, []);

  if (!integration.connection?._id) return <Navigate to="/integrations" />;

  return (
    <ActionModal
      headerText="Connect a Jonas company"
      onSubmit={connectJonasCompany}
      submitText="Connect"
      onHide={() => navigate("/integrations")}
      cancelText="Cancel"
      showSubmit={true}
      loading={connecting}
    >
      {!companyOptions ? (
        <Loader />
      ) : (
        <>
          <div className="vertical-spacer"></div>
          <div className="yellow-text-container">{`Showing all companies associated with your Jonas account.`}</div>
          <div className="vertical-spacer"></div>
          <div style={{ maxHeight: 300, overflowY: "auto" }}>
            {companyOptions.map((c) => {
              const selected = selectedCompany === c.value;
              return (
                <div
                  onClick={() => setSelectedCompany(c.value)}
                  className={`${selected ? "selected" : ""} calculation_option_wrapper flex`}
                >
                  <div>
                    <div style={{ fontSize: 17, fontWeight: selected ? "bold" : "" }}>{c.label}</div>
                    <div style={{ fontSize: 14, color: "darkslategray" }}>Company ID: {c.value}</div>
                  </div>

                  <div className="flex-1"></div>
                  {selected && <img src={checkmark} style={{ height: 15 }} />}
                </div>
              );
            })}
          </div>

          <div className="vertical-spacer"></div>
        </>
      )}
    </ActionModal>
  );
};
