import React, { useEffect } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { useForm } from "react-hook-form";
import { MiterAPI, TeamMember } from "dashboard/miter";
import { Props } from "./types";
import useWizard from "ui/modal/useWizard";
import * as vals from "dashboard/utils/validators";

export const PhoneWizardScreen: React.FC<Props> = ({ task, name, onboardingChecklistItem }) => {
  const { new_hire } = onboardingChecklistItem;
  const form = useForm<{ phone: TeamMember["phone"] }>({
    mode: "all",
    defaultValues: {
      phone: new_hire.phone,
    },
  });
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();
  const {
    errors,
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors: formErrors },
  } = form;

  const values = watch();

  useEffect(() => {
    trigger();
  }, [values.phone]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [formErrors, errors]);

  const onNext = async () => {
    handleSubmit(async (data) => {
      const phone = data.phone;

      try {
        const res = await MiterAPI.team_member.update(new_hire._id, {
          phone,
        });

        if (res.error) {
          throw new Error(res.error);
        }

        if (curIndex === screens.length - 1) {
          handleComplete();
        }
      } catch (e: $TSFixMe) {
        Notifier.error(e.message);
      }
    })();
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        <Formblock
          label="Phone"
          val={vals.phone}
          defaultValue={onboardingChecklistItem.new_hire.phone}
          control={control}
          type="phone"
          name="phone"
          className="modal small-margin"
          errors={errors}
          editing={true}
        />
      </div>
    </WizardScreen>
  );
};
