import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { useState } from "react";
import { Notifier } from "ui";

type ExchangeClientCredentialsLookHook = {
  submitClientSecret: (data: ClientCredentials, callback?: () => Promise<void>) => Promise<void>;
  submitting: boolean;
};

export type ClientCredentials = {
  clientId: string;
  clientSecret: string;
  tenantId: string;
};

export const useExchangeClientCredentials = (
  integration: MiterIntegrationForCompany
): ExchangeClientCredentialsLookHook => {
  const [submitting, isSubmitting] = useState(false);

  const submitClientSecret = async (data: ClientCredentials, callback?: () => Promise<void>) => {
    if (!integration?.connection) return;
    isSubmitting(true);
    try {
      const { clientId, clientSecret, tenantId } = data;

      const response = await MiterAPI.integrations.exchange_client_credentials({
        integrationConnectionId: integration.connection._id,
        clientId,
        clientSecret,
        tenantId,
      });
      if (response.error) throw new Error(response.error);

      if (callback) {
        await callback();
      }
      Notifier.success("Successfully saved Client ID and Secret.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`Error saving client credentials in: ${e.message}`);
    }
    isSubmitting(false);
  };

  return {
    submitClientSecret,
    submitting,
  };
};
