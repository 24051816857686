import React, { FC } from "react";
import { WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import styles from "./FillFillableDocument.module.css";

import { DocumentDisplay } from "../documents";
import { AggregatedFillableDocument } from "dashboard/types/fillable-template-types";

type Props = {
  fillableDocument: AggregatedFillableDocument;
  name: string;
};

export const ConfirmCompletedDocumentScreen: FC<Props> = ({ fillableDocument, name }) => {
  const { setCanNext, handleComplete, setNextButtonText } = useWizard();

  setNextButtonText("Complete");
  setCanNext(true);

  return (
    <WizardScreen name={name} onNext={handleComplete}>
      <div className={styles["main-wizard-container"]}>
        <h2 className={styles["subheader-title"]}>Confirm your section of the document</h2>
        <DocumentDisplay uri={fillableDocument.url} />
      </div>
    </WizardScreen>
  );
};
