import React, { useCallback, useEffect, useState } from "react";
import { MiterAPI, MiterFilterArray, AggregatedChangeRequest } from "dashboard/miter";
import { useMemo } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import {
  ChangeRequestParentType,
  ChangeRequestStatus,
  TeamMemberChangeCategory,
} from "backend/models/change-request";
import { NotFound } from "http-errors";
import { ForageRequest, ForageResponse } from "backend/utils/forage/forage-types";
import ViewChangeRequestModal from "./ViewChangeRequestModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { ValueGetterParams } from "ag-grid-community";
import {
  getUserFriendlyTmChangeCategory,
  getUserFriendlyTmFieldName,
} from "dashboard/pages/team-members/TeamUtils";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type ChangeRequestTableProps = {
  parentType?: ChangeRequestParentType;
  parentId?: string;
  inboxMode?: InboxMode;
  fetchActionableChangeRequests?: (params: ForageRequest) => Promise<ForageResponse<AggregatedChangeRequest>>;
  shouldRedirectURLWhenOpening?: boolean;
};

const useChangeRequestsTableColDefs = (
  parentType?: ChangeRequestParentType
): ColumnConfig<AggregatedChangeRequest>[] => {
  return useMemo(() => {
    const colors = {
      unapproved: "yellow",
      approved: "green",
      denied: "red",
      scheduled: "light-blue",
    };
    return [
      {
        field: "created_at",
        headerName: "Submitted at",
        dataType: "date" as const,
        sort: "desc" as const,
        dateType: "iso" as const,
        enableRowGroup: true,
      },
      {
        field: "author",
        headerName: "Submitted by",
        dataType: "string" as const,
        enableRowGroup: true,
        valueFormatter: (params) => {
          // params.value should always be of type TeamMember
          return params.value?.first_name + " " + params.value?.last_name || "Deleted user";
        },
      },
      {
        field: "parent",
        headerName: parentType === "team_member" ? "Team Member" : "Changed Item",
        filterField: "parent.full_name",
        sortField: "parent.full_name",
        groupField: "parent.full_name",
        dataType: "string" as const,
        enableRowGroup: true,
        valueFormatter: (params) => {
          // params.value should always be of type TeamMember for now
          return params.value?.full_name || "-";
        },
      },
      {
        field: "fields_changed",
        headerName: "Fields Changed",
        minWidth: 160,
        dataType: "string" as const,
        valueFormatter: (params) => {
          return params.value?.map((field) => getUserFriendlyTmFieldName(field.key)).join(", ");
        },
      },
      {
        field: "status",
        headerName: "Status",
        dataType: "string" as const,
        displayType: "badge" as const,
        colors: colors,
        enableRowGroup: true,
      },
      {
        field: "effective_date",
        headerName: "Scheduled date",
        minWidth: 160,
        dataType: "string" as const,
      },
      {
        field: "notes",
        headerName: "Notes",
        dataType: "string" as const,
      },
      {
        field: "child_fields.category",
        headerName: "Category",
        dataType: "string" as const,
        displayType: "badge" as const,
        valueGetter: (params: ValueGetterParams<AggregatedChangeRequest>): string => {
          const category: TeamMemberChangeCategory | undefined = params.data?.child_fields?.category;
          return getUserFriendlyTmChangeCategory(category);
        },
        colors: {
          Employment: "light-purple",
          "Trade qualifications": "orange",
          Pay: "green",
          Defaults: "light-orange",
          Classifications: "yellow",
          Personal: "blue",
          Demographics: "light-blue",
          "Emergency contacts": "light-red",
          All: "gray",
          "Not set": "light-gray",
        },
      },
      {
        field: "team_member_id",
        headerName: "Submitter team member id",
        dataType: "string" as const,
      },
    ].filter(Boolean);
  }, [parentType]);
};

const ChangeRequestsTable: React.FC<ChangeRequestTableProps> = ({
  parentType,
  parentId,
  inboxMode,
  fetchActionableChangeRequests,
  shouldRedirectURLWhenOpening,
}) => {
  // hooks
  const companyId = useActiveCompanyId();
  if (!companyId) throw new NotFound("Company not found");
  const { can } = useMiterAbilities();
  const teamAbilities = useTeamAbilities({ inboxMode });
  const navigate = useNavigate();

  // useState
  const [selectedRows, setSelectedRows] = useState<AggregatedChangeRequest[]>([]);
  const [selectedChangeRequestId, setSelectedChangeRequestId] = useState<string>();
  const [refreshCount, setRefreshCount] = useState(0);
  const columns = useChangeRequestsTableColDefs(parentType);

  // URL deeplink
  const { id: idFromUrl } = useParams<Params>();
  useEffect(() => {
    if (shouldRedirectURLWhenOpening) setSelectedChangeRequestId(idFromUrl);
  }, [idFromUrl]);

  // constant
  const canChangeStatus = can("team:update") || can("team:update_sensitive") || !!inboxMode;

  // callback
  const getData = useCallback(
    async (query: ForageRequest) => {
      // filters
      const filter: MiterFilterArray = [];
      if (parentType) {
        filter.push({ field: "parent_type", type: "string", value: parentType });
      }
      if (parentId) {
        filter.push({ field: "parent_id", type: "string", value: parentId });
      }
      if (query.filter) {
        filter.push(...query.filter);
      }

      if (!inboxMode && parentType === "team_member") {
        const abilitiesFilter = teamAbilities.filter("read");
        if (abilitiesFilter) filter.push(abilitiesFilter);
      }

      const sort = query.sort || [];

      const forageFunction = fetchActionableChangeRequests || MiterAPI.change_requests.forage;

      return await forageFunction({ ...query, filter, sort });
    },
    [companyId, parentType, parentId]
  );

  // handlers
  const handleSelect = (selections) => {
    setSelectedRows(selections);
  };

  const handleRowClick = (row) => {
    setSelectedChangeRequestId(row._id);
    if (shouldRedirectURLWhenOpening) {
      navigate(`/reports/change-requests/${row._id}`);
    }
  };

  // config
  const handleStatusChange = async (status: ChangeRequestStatus) => {
    if (!selectedRows.length) return;
    try {
      const response = await MiterAPI.change_requests.update_many({
        ids: selectedRows.map((row) => row._id),
        update: { status: status },
      });
      if (response.error) {
        throw new Error(response.error);
      }
      setSelectedRows([]);
      setRefreshCount(refreshCount + 1);
    } catch (e: $TSFixMe) {
      console.error(e);
    }
  };

  const deleteChangeRequests = async () => {
    if (!selectedRows.length) return;
    try {
      const response = await MiterAPI.change_requests.delete_many(selectedRows.map((row) => row._id));
      if (response.error) {
        throw new Error(response.error);
      }
      setSelectedRows([]);
      setRefreshCount(refreshCount + 1);
    } catch (e: $TSFixMe) {
      console.error(e);
    }
  };

  const hideModal = async () => {
    setSelectedChangeRequestId(undefined);
    if (shouldRedirectURLWhenOpening) {
      navigate("/reports/change-requests");
    }
  };

  const dynamicActions: TableActionLink[] = useMemo(() => {
    const allUnapproved = selectedRows.every((row) => row.status === "unapproved");
    const allDeniedOrScheduled = selectedRows.every(
      (row) => row.status === "denied" || row.status === "scheduled"
    );
    const anyApproved = selectedRows.some((row) => row.status === "approved");
    const actions: TableActionLink[] = [];

    // show actions only if user has permissions to update team member info
    if (allUnapproved) {
      actions.push({
        label: "Approve",
        action: () => handleStatusChange("approved"),
        className: "button-2 table-button",
        important: true,
        shouldShow: () => canChangeStatus,
      });
      actions.push({
        label: "Deny",
        action: () => handleStatusChange("denied"),
        className: "button-1 table-button",
        secondary: true,
        shouldShow: () => canChangeStatus,
      });
    }
    if (allDeniedOrScheduled) {
      actions.push({
        label: "Move to unapproved",
        action: () => handleStatusChange("unapproved"),
        className: "button-1 table-button",
        secondary: true,
        shouldShow: () => canChangeStatus,
      });
    }
    if (!anyApproved) {
      actions.push({
        label: "Delete",
        action: deleteChangeRequests,
        className: "button-3 table-button",
        secondary: true,
        shouldShow: () => canChangeStatus,
      });
    }
    return actions;
  }, [selectedRows]);

  const renderTable = () => {
    return (
      <TableV2
        id="change-requests-table"
        resource="change requests"
        columns={columns}
        onSelect={handleSelect}
        dynamicActions={dynamicActions}
        onClick={handleRowClick}
        defaultSelectedRows={selectedRows}
        ssr={true}
        refreshCount={refreshCount}
        getData={getData}
        containerClassName={"timesheets-table-container"}
        editable={true}
      />
    );
  };

  return (
    <div>
      {renderTable()}
      {selectedChangeRequestId && (
        <ViewChangeRequestModal
          changeRequestId={selectedChangeRequestId}
          onHide={hideModal}
          onSubmit={() => setRefreshCount(refreshCount + 1)}
          canUpdate={canChangeStatus}
        />
      )}
    </div>
  );
};

export default ChangeRequestsTable;
