import React, { useEffect, useState } from "react";
import { ActionModal, Formblock, Loader, Notifier } from "ui";
import { AggregatedEquipmentTimesheet, EquipmentTimesheet, MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import {
  useActiveCompany,
  useActivityOptionsMap,
  useEquipmentOptions,
  useJobOptions,
} from "dashboard/hooks/atom-hooks";
import { useForm } from "react-hook-form";
import { JobInput } from "../shared/JobInput";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { Option } from "ui/form/Input";
import Banner from "../shared/Banner";
import { Link } from "react-router-dom";
import styles from "./EquipmentTimesheetModal.module.css";
import { useEquipmentTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useEquipmentTimesheetAbilities";

type EquipmentTimesheetModalProps = {
  equipmentTimesheetId: string;
  onHide: () => void;
  onSubmit: () => void;
};

type EquipmentTimesheetForm = {
  equipment: Option<string>;
  clock_in: DateTime;
  clock_out: DateTime;
  job: Option<string>;
  activity: Option<string>;
  notes: string;
  quantity: number;
};

const EquipmentTimesheetModal: React.FC<EquipmentTimesheetModalProps> = ({
  equipmentTimesheetId,
  onHide,
  onSubmit,
}) => {
  // atom hooks
  const activeCompany = useActiveCompany();
  const jobOptions = useJobOptions();
  const activityOptionsMap = useActivityOptionsMap({
    predicate: isTimesheetScoped,
  });
  const equipmentOptions = useEquipmentOptions();
  const equipmentTimesheetAbilities = useEquipmentTimesheetAbilities();

  // useState hooks
  const [equipmentTimesheet, setEquipmentTimesheet] = useState<AggregatedEquipmentTimesheet>();
  const [refreshModal, setRefreshModal] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activityOptions, setActivityOptions] = useState<Option<string>[] | undefined>(() =>
    activityOptionsMap.get(equipmentTimesheet?.job_id)
  );

  // useEffect hooks
  const getEquipmentTimesheetData = async () => {
    setLoading(true);
    const response = await MiterAPI.equipment_timesheets.retrieve(equipmentTimesheetId);
    if (response.error) {
      Notifier.error("Failed to fetch equipment timesheet.");
    } else {
      setEquipmentTimesheet(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEquipmentTimesheetData();
  }, [equipmentTimesheetId, refreshModal]);

  // form
  const form = useForm<EquipmentTimesheetForm>({ shouldUnregister: false });
  const formData = form.watch();
  const { handleSubmit, setValue } = form;

  // handlers
  const handleStatusChange = async (status: EquipmentTimesheet["status"]) => {
    try {
      const response = await MiterAPI.equipment_timesheets.update(equipmentTimesheetId, { status: status });
      if (response.error) {
        throw new Error(response.error);
      } else if (status === "approved") {
        Notifier.success("Equipment timesheet approved");
      } else {
        Notifier.success("Equipment timesheet moved to unapproved");
      }
      setRefreshModal(refreshModal + 1);
      onSubmit();
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await MiterAPI.equipment_timesheets.delete(equipmentTimesheetId);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success("Equipment timesheet deleted successfully");
      onSubmit();
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
    onHide();
  };

  const handleSaveEquipmentTimesheet = handleSubmit(async (data: EquipmentTimesheetForm) => {
    setLoading(true);
    const params = {
      equipment_id: data.equipment?.value,
      clock_in: data.clock_in.toSeconds(),
      clock_out: data.clock_out.toSeconds(),
      job_id: data.job?.value,
      activity_id: data.activity?.value,
      notes: data.notes,
      quantity: data.quantity,
    };
    try {
      const response = await MiterAPI.equipment_timesheets.update(equipmentTimesheetId, params);
      if (response.error) {
        throw new Error(response.error);
      }
      setIsEditing(false);
      Notifier.success("Equipment timesheet updated successfully");
      setRefreshModal(refreshModal + 1);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
    setLoading(false);
  });

  const handleSubmitModal = () => {
    if (isEditing) {
      handleSaveEquipmentTimesheet();
    } else if (equipmentTimesheet?.status === "unapproved") {
      handleStatusChange("approved");
    } else {
      handleStatusChange("unapproved");
    }
  };

  // constants
  const canEdit =
    equipmentTimesheet?.status === "unapproved" &&
    !equipmentTimesheet?.timesheet_id &&
    equipmentTimesheetAbilities.can("update", equipmentTimesheet);
  const canDelete =
    equipmentTimesheet?.status === "unapproved" &&
    !equipmentTimesheet?.timesheet_id &&
    equipmentTimesheetAbilities.can("delete", equipmentTimesheet);
  const canApprove = equipmentTimesheet && equipmentTimesheetAbilities.can("approve", equipmentTimesheet);
  const shouldShowDelete = !isEditing && canDelete;
  const shouldShowEdit = !isEditing && canEdit;
  const shouldShowSave = isEditing && canEdit;
  const shouldShowStatusChange = canApprove && !equipmentTimesheet?.timesheet_id;

  // components
  const renderTimesheetBanner = () => {
    if (!equipmentTimesheet?.timesheet_id) return;
    const teamMemberName = equipmentTimesheet?.team_member?.first_name;
    const timesheetURL = `/timesheets?timesheetId=${equipmentTimesheet?.timesheet_id}`;
    const timesheetBannerContent = (
      <>
        This equipment timesheet was created via an employee timesheet. To edit, please edit{" "}
        <Link to={timesheetURL}>{teamMemberName}&apos;s original timesheet.</Link>
      </>
    );
    return <Banner type={"warning"} content={timesheetBannerContent} />;
  };

  const renderSubmitText = () => {
    if (isEditing) {
      return "Save";
    }
    if (equipmentTimesheet?.status === "unapproved") {
      return "Approve";
    }
    return "Move to unapproved";
  };

  return (
    <ActionModal
      headerText="Equipment Timesheet"
      wrapperClassName={styles["modal-wrapper"]}
      bodyClassName={styles["modal-body"]}
      footerClassName={styles["modal-footer"]}
      onHide={onHide}
      actionsType="button"
      showDelete={shouldShowDelete}
      showEdit={shouldShowEdit}
      showCancel={isEditing}
      showSubmit={shouldShowSave || shouldShowStatusChange}
      onCancel={() => setIsEditing(false)}
      onEdit={() => setIsEditing(true)}
      onSubmit={handleSubmitModal}
      onDelete={handleDelete}
      submitText={renderSubmitText()}
      loading={loading}
    >
      {equipmentTimesheet ? (
        <div>
          {renderTimesheetBanner()}
          <div className={styles["modal-form"]}>
            <Formblock
              name="equipment"
              label="Equipment"
              type="select"
              form={form}
              defaultValue={equipmentTimesheet.equipment_id}
              requiredSelect={true}
              options={equipmentOptions}
              editing={isEditing}
            />
            <Formblock
              name="Team member"
              label="Team member"
              type="text"
              defaultValue={equipmentTimesheet.team_member?.full_name || "-"}
              editing={false}
            />
            <Formblock
              name="quantity"
              label="Quantity"
              type="number"
              form={form}
              defaultValue={equipmentTimesheet.quantity || 1}
              editing={isEditing}
              min={1}
            />
            <Formblock
              name="clock_in"
              label="Clock in"
              type="datetime"
              form={form}
              defaultValue={DateTime.fromSeconds(equipmentTimesheet.clock_in)}
              timezone={equipmentTimesheet.timezone || activeCompany?.timezone}
              editing={isEditing}
              customFormat={"MMM d, yyyy 'at' h:mm:ss a ZZZZ"}
            />
            <Formblock
              name="clock_out"
              label="Clock out"
              type="datetime"
              form={form}
              defaultValue={DateTime.fromSeconds(equipmentTimesheet.clock_out)}
              timezone={equipmentTimesheet.timezone || activeCompany?.timezone}
              editing={isEditing}
              customFormat={"MMM d, yyyy 'at' h:mm:ss a ZZZZ"}
            />
            <Formblock
              name="hours"
              label="Hours"
              type="number"
              defaultValue={equipmentTimesheet.hours}
              editing={false}
            />
            <JobInput
              name="job"
              label="Job"
              type="select"
              form={form}
              options={jobOptions}
              editing={isEditing}
              defaultValue={equipmentTimesheet.job_id}
              onChange={(jobOption: Option<string>) => {
                const newActivityOptions = activityOptionsMap.get(jobOption?.value);
                setActivityOptions(newActivityOptions);
                if (
                  formData?.activity?.value &&
                  !newActivityOptions.some((option) => option.value === formData?.activity?.value)
                ) {
                  setValue("activity", null);
                }
              }}
              isClearable
            />
            <Formblock
              name="activity"
              label="Activity"
              type="select"
              form={form}
              options={activityOptions || []}
              editing={isEditing}
              defaultValue={equipmentTimesheet.activity_id}
              isClearable
            />
            <Formblock
              name="notes"
              label="Notes"
              type="text"
              form={form}
              defaultValue={equipmentTimesheet.notes || "-"}
              editing={isEditing}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </ActionModal>
  );
};

export default EquipmentTimesheetModal;
