import React, { useMemo, useState } from "react";
import { BulkUpdateResult, MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { TableReadyTm, useCleanTeamFromBackend } from "./TeamUtils";
import Notifier from "dashboard/utils/notifier";
import { BasicModal, DeleteModal, DropdownButton, TableV2 } from "ui";
import { TogglerConfig } from "ui/table/Table";
import { MiterFilterArray } from "backend/utils/utils";
import { TableActionLink, TableTogglerConfig } from "ui/table-v2/Table";
import {
  ArrowSquareIn,
  Buildings,
  ClockCounterClockwise,
  FileArrowUp,
  Pencil,
  PencilSimpleLine,
  Plus,
  User,
  UserPlus,
  UserCircleGear,
} from "phosphor-react";
import { useActiveEmployees, useRefetchTeam, useTeam } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TeamMemberImporter } from "dashboard/components/team-members/TeamMemberImporter";
import { ImportHistory } from "dashboard/components/importer/ImportHistory";
import { useEnhancedSearchParams } from "miter-utils";
import { DropdownItem } from "ui/button/Button";
import { CompanyDefinedAttributesModal } from "./teamCompanyDefinedAttributes";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { FlatfileImportDropdownItem } from "dashboard/components/shared/FlatfileImportDropdownItem";
import { parseCustomFieldValueParams } from "dashboard/utils/custom-fields";
import { BulkUpdateParams } from "../../../../backend/utils/utils";
import { UpdateTeamMembersParams } from "../../../../backend/utils/team-members/updateTeamMember";

import {
  useTeamColumns,
  usePolicyColumns,
  usePayColumns,
  useEeoColumns,
  useCustomFieldColumns,
  useDefaultAssociationsColumns,
} from "./table-columns/useTeamMembersTableColumns";

type Props = {
  defaultFilters: MiterFilterArray;
  showToggler: boolean;
  showMiniTitle: boolean;
  showButtons: boolean;
  showSearch: boolean;
  searchFields?: string[];
  defaultTogglerConfig?: TogglerConfig;
};

type BulkEditQuery = "pay-rates" | "cda";

/** Define wrapper components with different icons
 */
const ImportButtonCreate = (props) => (
  <FlatfileImportDropdownItem {...props}>
    <UserPlus style={{ marginRight: "8px" }} />
  </FlatfileImportDropdownItem>
);

const ImportButtonUpsert = (props) => (
  <FlatfileImportDropdownItem {...props}>
    <UserCircleGear style={{ marginRight: "8px" }} />
  </FlatfileImportDropdownItem>
);

const ImportButtonRehire = (props) => (
  <FlatfileImportDropdownItem {...props}>
    <ArrowSquareIn style={{ marginRight: "8px" }} />
  </FlatfileImportDropdownItem>
);

const ImportButtonOverwriteRehire = (props) => (
  <FlatfileImportDropdownItem {...props}>
    <PencilSimpleLine style={{ marginRight: "8px" }} />
  </FlatfileImportDropdownItem>
);

const TeamMembersTable: React.FC<Props> = ({}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const [activeView, setActiveView] = useState("active");

  const activeEmployees = useActiveEmployees();
  const refetchTeam = useRefetchTeam();
  const navigate = useNavigate();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams();
  const editingQuery = parsedSearchParams.editing as BulkEditQuery | undefined;
  const teamMembers = useTeam();
  const miterAbilities = useMiterAbilities();
  const teamAbilities = useTeamAbilities();

  /** Get the columns for the Team Members table */
  const teamColumns = useTeamColumns(activeView);
  const policyColumns = usePolicyColumns();
  const payColumns = usePayColumns();
  const eeoColumns = useEeoColumns();
  const customFieldColumns = useCustomFieldColumns();
  const defaultAssociationsColumns = useDefaultAssociationsColumns();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [submitting, setSubmitting] = useState(false);
  const [selectedRows, setSelectedRows] = useState<TableReadyTm[]>([]);
  const [loading] = useState(false);

  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);
  const [showRehireImportHistory, setShowRehireImportHistory] = useState(false);
  const [showLatestRehireImportResult, setShowLatestRehireImportResult] = useState(false);

  const cleanTeamFromBackend = useCleanTeamFromBackend();

  const tableData = useMemo(
    () =>
      cleanTeamFromBackend(teamMembers).filter((tm) => {
        const canRead = teamAbilities.can("read", tm);
        return canRead;
      }),
    [teamMembers, cleanTeamFromBackend, teamAbilities.can]
  );

  const hasCheckEmployees = useMemo(() => activeEmployees.some((tm) => !!tm.check_id), [activeEmployees]);

  // States related to table actions
  const [deleting, setDeleting] = useState(false);

  const finalColumns = useMemo(() => {
    // Filter out columns that this person shouldn't have access to
    // - We need to do this this way so that if there is a saved template with a column that the user no longer has access to, we don't accidentally show it
    const sensitiveFields = [
      "pay_type",

      // Payroll
      "I9s",
      "I9_authorization_type",
      "bank_accounts",
      "employment_category",
      "employment_term",
      "has_payroll",
      "payroll_status",
      "check_tm.payment_method_preference",

      "start_date",
      "end_date",

      "kiosk_pin",
      "pay_schedule_id",
      "default_ot_rule_id",
      "disable_multi_workplace_payrolls",
      "wc_code._id",
      "wc_group_id",
      "rate_differential_id",
      "standard_classification_id",
      "salary_rate_display",
      "pay_rate_group",
      "pay_rate",
      "union_rate",
      "overtime_exempt",

      "workplace",
    ];

    const finalColumns = [
      ...teamColumns,
      ...payColumns,
      ...policyColumns,
      ...defaultAssociationsColumns,
      ...eeoColumns,
      ...customFieldColumns,
    ];

    const filteredColumns = finalColumns.filter(
      (col) => !col.field || miterAbilities.can("team:read_sensitive") || !sensitiveFields.includes(col.field)
    );

    return filteredColumns;
  }, [
    teamColumns,
    policyColumns,
    payColumns,
    eeoColumns,
    customFieldColumns,
    defaultAssociationsColumns,
    miterAbilities.can,
  ]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleSelect = (selections: TableReadyTm[]) => {
    setSelectedRows(selections);
  };

  const handleArchive = async () => {
    setDeleting(true);
  };

  const canArchiveSelectedTms = useMemo(() => selectedRows.every((tm) => tm.end_date), [selectedRows]);

  /*********************************************************
   *  Data fetching functions
   **********************************************************/
  const archiveTeamMembers = async () => {
    if (teamAbilities.cannot("delete", selectedRows)) {
      Notifier.error("You do not have permission to delete team members.");
      return;
    }

    setSubmitting(true);
    try {
      const checkTms = selectedRows.filter((tm) => tm.check_id);
      const idsToArchive = selectedRows.filter((tm) => !tm.check_id).map((tm) => tm._id);
      const response = await MiterAPI.team_member.delete_many({ ids: idsToArchive });
      if (response.error) throw response.error;

      if (checkTms.length > 0) {
        Notifier.warning("Team members enrolled in payroll cannot be deleted");
        return;
      }

      if (response && response.length === 0) {
        Notifier.success("Deleted team member(s)");
      } else {
        Notifier.warning(
          "Failed to delete " + response.length + "/" + selectedRows.length + " team members."
        );
      }

      setSelectedRows([]);
      refetchTeam(idsToArchive);
    } catch (e: $TSFixMe) {
      console.log("Error deleting team members", e.message);
      Notifier.error("Failed to deleting team member(s). Contact support if the problem persists.");
    }
    setDeleting(false);
    setSubmitting(false);
    return;
  };

  const cleanTeamMemberUpdateParams = (
    teamMemberParamsArray: TableReadyTm[]
  ): BulkUpdateParams<UpdateTeamMembersParams> => {
    return teamMemberParamsArray.map((tmData) => {
      const customFieldValues = parseCustomFieldValueParams(tmData);

      // If the pay type is union_rate, we need to set the pay type to hourly before saving
      const payType = tmData.pay_type === "union_rate" ? "hourly" : tmData.pay_type;

      return {
        _id: tmData._id,
        params: {
          friendly_id: tmData.friendly_id,
          first_name: tmData.first_name,
          last_name: tmData.last_name,
          employment_type: tmData.employment_type,
          title: tmData.title || "", // title must be a string, not null
          pay_type: payType,
          pay_rate: tmData.pay_rate,
          salary_rate_display: tmData.salary_rate_display,
          union_rate: tmData.union_rate,
          overtime_exempt: tmData.overtime_exempt,
          start_date: tmData.start_date,
          benefits_eligibility_status: tmData.benefits_eligibility_status,
          benefits_eligibility_groups: tmData.benefits_eligibility_groups?.map((group) => group.value),
          department_id: tmData.department_id,
          employment_category: tmData.employment_category,
          employment_term: tmData.employment_term,
          location_id: tmData.location_id,
          is_universal_supervisor: tmData.is_universal_supervisor,
          reports_to: tmData.reports_to?._id,
          email: tmData.email,
          phone: tmData.phone,
          end_date: tmData.end_date,
          kiosk_pin: tmData.kiosk_pin,
          opt_out_of_connected_kiosks: tmData.opt_out_of_connected_kiosks,
          default_job_id: tmData.default_job_id,
          default_activity_id: tmData.default_activity_id,
          default_cost_type_id: tmData.default_cost_type_id,
          ledger_mapping_id: tmData.ledger_mapping_id,
          demographics: tmData.demographics,
          workplace: tmData.workplace?._id,
          wc_code: tmData.wc_code?._id,
          wc_group_id: tmData.wc_group_id,
          pay_schedule_id: tmData.pay_schedule_id,
          default_ot_rule_id: tmData.default_ot_rule_id,
          disable_multi_workplace_payrolls: tmData.disable_multi_workplace_payrolls,
          rate_differential_id: tmData.rate_differential_id,
          standard_classification_id: tmData.standard_classification_id,
          custom_field_values: customFieldValues,
          expense_policy_id: tmData.expense_policy_id,
          reimbursement_policy_id: tmData.reimbursement_policy_id,
          timesheet_policy_id: tmData.timesheet_policy_id,
          time_off_request_policy_id: tmData.time_off_request_policy_id,
          team_member_change_request_policy_id: tmData.team_member_change_request_policy_id,
        },
      };
    });
  };

  const handleSave = async (teamMemberParamsArray: TableReadyTm[]): Promise<BulkUpdateResult> => {
    try {
      const params = cleanTeamMemberUpdateParams(teamMemberParamsArray);
      const res = await MiterAPI.team_member.bulk_update(params);
      if (res.error) throw Error(res.error);

      if (res.errors.length > 0) {
        const successes = res.successes;
        const errors = res.errors;

        return { successes, errors };
      }

      await refetchTeam();
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("Failed to save team member(s). Contact support if the problem persists.");
      return { successes: [], errors: [] };
    }
  };

  const handleBulkCreateFinish = async () => {
    await refetchTeam();
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  const handleRehireImportFinish = async () => {
    await refetchTeam();
    setShowRehireImportHistory(true);
    setShowLatestRehireImportResult(true);
  };

  const handleBulkEditClick = (type: BulkEditQuery) => {
    setSearchParams({ editing: type });
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        action: handleArchive,
        className: "button-3 table-button",
        shouldShow: () => teamAbilities.can("delete", selectedRows),
      },
    ],
    [teamAbilities.can, selectedRows]
  );

  const staticActions: TableActionLink[] = useMemo(() => {
    const actions: TableActionLink[] = [];

    if (miterAbilities.can("team:create")) {
      actions.push({ key: "new-team-member", component: <AddTeamMemberDropdown />, important: true });
    }

    /**
     * Import actions dropdown
     */
    const bulkImportDropdownItems: DropdownItem[] = [];
    if (miterAbilities.can("team:create")) {
      bulkImportDropdownItems.push({
        key: "import",
        component: (
          <TeamMemberImporter
            onFinish={handleBulkCreateFinish}
            upsert={false}
            ImportButtonComponent={ImportButtonCreate}
          />
        ),
      });
      if (miterAbilities.can("team:create") && miterAbilities.can("team:update_sensitive")) {
        bulkImportDropdownItems.push(
          {
            key: "upsert",
            component: (
              <TeamMemberImporter
                onFinish={handleBulkCreateFinish}
                upsert={true}
                ImportButtonComponent={ImportButtonUpsert}
              />
            ),
          },
          {
            key: "employment-history",
            component: (
              <TeamMemberImporter
                onFinish={handleRehireImportFinish}
                upsert={true}
                importEmploymentHistory={true}
                ImportButtonComponent={ImportButtonRehire}
              />
            ),
          },
          {
            key: "overwrite-employment-history",
            component: (
              <TeamMemberImporter
                onFinish={handleRehireImportFinish}
                upsert={true}
                importEmploymentHistory={true}
                overwriteHistory={true}
                ImportButtonComponent={ImportButtonOverwriteRehire}
              />
            ),
          }
        );
      }
    }

    if (bulkImportDropdownItems.length > 0) {
      actions.push({
        label: "Import data",
        icon: <FileArrowUp weight="bold" style={{ marginRight: 5 }} />,
        action: () => {},
        className: "button-1 table-button",
        dropdownItems: bulkImportDropdownItems,
      });
    }

    /**
     * Import history dropdown (team member imports, rehire/dismissal imports)
     */
    if (miterAbilities.can("team:create")) {
      const importHistoryDropdownItems: DropdownItem[] = [];

      importHistoryDropdownItems.push({
        key: "import-history",
        text: "Team members",
        onClick: () => setShowImportHistory(true),
      });

      importHistoryDropdownItems.push({
        key: "employment-history",
        text: "Rehires/dismissals",
        onClick: () => setShowRehireImportHistory(true),
      });

      actions.push({
        label: "Import history",
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        action: () => setShowImportHistory(true),
        className: "button-1",
        dropdownItems: importHistoryDropdownItems,
      });
    }

    /**
     * Bulk edit dropdown
     */
    if (miterAbilities.can("team:update") && miterAbilities.can("team:update_sensitive")) {
      const bulkEditDropdownItems: DropdownItem[] = [];
      if (miterAbilities.can("team:update_sensitive") && hasCheckEmployees) {
        bulkEditDropdownItems.push({
          text: "Company-defined attributes",
          onClick: () => handleBulkEditClick("cda"),
        });
      }
      actions.push({
        label: "Bulk edit",
        icon: <Pencil weight="bold" style={{ marginRight: 5 }} />,
        action: () => {},
        className: "button-1 table-button",
        dropdownItems: bulkEditDropdownItems,
      });
    }

    return actions;
  }, [miterAbilities.can, refetchTeam, hasCheckEmployees]);

  /*********************************************************
    Toggler configs
  **********************************************************/
  const togglerConfig: TableTogglerConfig<TableReadyTm> = {
    config: [
      { path: "active", label: "Active" },
      { path: "dismissed", label: "Dismissed", hide: miterAbilities.cannot("team:read_sensitive") },
      {
        path: "on_leave",
        label: "On leave",
        hide: miterAbilities.cannot("team:read_sensitive"),
      },
      { path: "all", label: "All", hide: miterAbilities.cannot("team:read_sensitive") },
    ],
    field: "type",
    customFilter: (row: TableReadyTm, value: string) => {
      if (value === "all") return true;
      if (value === "active") {
        return row.active_status === "active" || row.active_status === "upcoming_dismissal";
      }
      if (value === "dismissed") {
        return row.active_status === "dismissed";
      }
      if (value === "on_leave") {
        return row.active_status === "on_leave";
      }
      return false;
    },
    onChange: (value: string) => {
      setActiveView(value);
    },
    secondary: true,
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const prettyPrintTeamMembers = () => {
    const tms: string[] = [];
    // Get names from IDs
    for (const row of selectedRows) {
      const matchedTeamMember = tableData?.filter((x) => x._id === row._id);
      if (!matchedTeamMember) continue;
      // Should only get one match, but loop through return array.
      for (const m of matchedTeamMember) {
        tms.push(m.full_name);
      }
    }
    // Format string
    let str = "";
    if (tms.length === 1) {
      str = tms[0] || "";
    } else if (tms.length === 2) {
      str = tms[0] + " and " + tms[1];
    } else {
      for (let i = 0; i < tms.length - 1; i++) {
        str += tms[i] + ", ";
      }
      str += "and " + tms[tms.length - 1];
    }

    return str;
  };

  const renderTable = () => {
    return (
      <TableV2
        id="team-members-table"
        resource="team members"
        data={tableData}
        columns={finalColumns}
        onSelect={handleSelect}
        toggler={togglerConfig}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={true}
        onClick={(tm) => navigate(`/team-members/${tm._id}`)}
        isLoading={loading}
        defaultSelectedRows={selectedRows}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        rowLinkBuilder={(tm) => `/team-members/${tm?._id}`}
        editable={miterAbilities.can("team:update")}
        onSave={handleSave}
      />
    );
  };

  const renderArchiveModalBody = () => {
    return (
      <span>
        This is an <b>irreversible</b> action and you will no longer have access to the profile(s) of{" "}
        {prettyPrintTeamMembers()}.{" "}
      </span>
    );
  };

  const renderArchiveModal = () => {
    return (
      <DeleteModal
        header={"Are you sure?"}
        body={renderArchiveModalBody()}
        cancelText={"Cancel"}
        onHide={() => setDeleting(false)}
        deleteText={"Yes, delete team member" + (selectedRows.length > 1 ? "s" : "")}
        onDelete={archiveTeamMembers}
        loading={submitting}
      />
    );
  };

  const renderCannotDeleteModalBody = () => {
    return (
      <span>
        Team member(s) must be dismissed before being deleted.{" "}
        <span>
          <br />
          <br />
          If you would like to dismiss {selectedRows.length > 1 ? "these team members" : "this team member"},
          click into their {selectedRows.length > 1 ? "profiles" : "profile"} and select &quot;Dismiss&quot;.
          This will allow you to re-hire them more easily.
        </span>
      </span>
    );
  };

  const renderCannotDeleteModal = () => {
    return (
      <BasicModal
        headerText={`Please dismiss team member(s)`}
        bodyText={renderCannotDeleteModalBody()}
        button2Text="Ok"
        button2Action={() => setDeleting(false)}
        loading={submitting}
      />
    );
  };

  const handleBulkEditHide = () => {
    setSearchParams({ editing: undefined });
  };

  const renderBulkEditing = () => {
    switch (editingQuery) {
      case "cda":
        return <CompanyDefinedAttributesModal hide={handleBulkEditHide} />;
      default:
        return null;
    }
  };

  return (
    <div className="timesheets-table-wrapper">
      {renderTable()}
      {renderBulkEditing()}
      {deleting && (canArchiveSelectedTms ? renderArchiveModal() : renderCannotDeleteModal())}
      {showImportHistory && (
        <ImportHistory
          id={"team_member"}
          resource={"team member"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
      {showRehireImportHistory && (
        <ImportHistory
          id={"team_member_rehire_history"}
          resource={"team member rehire/dismissal"}
          onClose={() => {
            setShowRehireImportHistory(false);
            setShowLatestRehireImportResult(false);
          }}
          openLastResult={showLatestRehireImportResult}
        />
      )}
    </div>
  );
};

const AddTeamMemberDropdown = () => {
  const navigate = useNavigate();

  return (
    <DropdownButton
      className="button-2 tall-button"
      options={[
        {
          label: "Add employee",
          icon: <User style={{ marginRight: 7, marginBottom: -2 }} />,
          action: () => navigate("/team-members/new?type=employee"),
        },
        {
          label: "Add contractor",
          icon: <Buildings style={{ marginRight: 7, marginBottom: -2 }} />,
          action: () => navigate("/team-members/new?type=contractor"),
        },
      ]}
    >
      <Plus weight="bold" style={{ marginRight: 7, marginBottom: -2.5 }} />
      Add team member
    </DropdownButton>
  );
};

export default TeamMembersTable;
