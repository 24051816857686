import React, { FC, useMemo, useState } from "react";
import { ManageNetPaySplits, NetPaySplit } from "./ManageNetPaySplits";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { TeamPortalUser } from "team-portal/utils/miter";
import { useTranslation } from "react-i18next";
import { Button, Notifier } from "ui";
import {
  convertMiterNetPaySplitToCheckCreationParams,
  useFetchBankAccounts,
  validateMiterNetPaySplitForCheckSubmission,
} from "./utils";

type Props = {
  teamMember: AggregatedTeamMember | TeamPortalUser;
  refreshCount: number;
};

export const InlineNetPaySplits: FC<Props> = ({ teamMember, refreshCount }) => {
  const { t } = useTranslation<$TSFixMe>();
  const [isEditing, setIsEditing] = useState(false);
  const [splits, setSplits] = useState<NetPaySplit[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkNetPaySplitId, setCheckNetPaySplitId] = useState<string | undefined>(
    teamMember.check_tm?.default_net_pay_split ?? undefined
  );

  const { result: validPayrollBankAccounts } = useFetchBankAccounts({
    companyId: teamMember.company._id,
    teamMemberId: teamMember._id,
    refreshCount,
    includeBankAccountsAddedToCheckOnly: true, // only return bank accounts eligible for payroll
  });
  const areSplitsValidForCheckSubmmission = useMemo(() => {
    return validateMiterNetPaySplitForCheckSubmission(splits);
  }, [splits]);

  const handleSubmitToCheck = async () => {
    setSubmitLoading(true);

    // convert Miter splits to Check splits
    const params = convertMiterNetPaySplitToCheckCreationParams(splits, teamMember, validPayrollBankAccounts);

    // call Check API behind the scenes
    try {
      const response = await MiterAPI.check.net_pay_splits.create(params);
      if (response.error) throw new Error(response.error);

      const newNetPaySplitId = response.id;
      setCheckNetPaySplitId(newNetPaySplitId);

      Notifier.success(
        t("Successfully updated your paycheck split. This will take into effect in 1-2 pay periods.")
      );

      setIsEditing(false);

      // Check does not send a employee/contractor updated webhook when new default NPS is updated (coming later in Q2). Update the check_tm info directly.
      // https://linear.app/miter/issue/MIT-5832/remove-miter-team-member-update-api-call-net-pay-split
      const teamMemberUpdateResponse = await MiterAPI.team_member.update(teamMember._id, {
        default_net_pay_split: newNetPaySplitId,
      });
      if (teamMemberUpdateResponse.error) throw new Error(teamMemberUpdateResponse.error);
    } catch (err) {
      console.error(err, {
        params,
      });
      Notifier.error(t("Could not update your paycheck split."));
    }
    setSubmitLoading(false);
  };

  return (
    <div>
      <div className="margin-bottom-25">
        <h2>{t("Direct Deposit Distribution")}</h2>
        <p>
          {t(
            "Distribute your paycheck by percentage or amount between bank accounts. Drag and drop each account to change the order in which funds will be distributed."
          )}
        </p>
        {!isEditing && (
          <Button className="button-1 no-margin" text={t("Edit")} onClick={() => setIsEditing(true)} />
        )}
        {isEditing && (
          <div className="flex">
            <div className="margin-right-15">
              <Button
                className="button-1 no-margin"
                text={t("Cancel")}
                onClick={() => {
                  setIsEditing(false);
                }}
              />
            </div>
            <Button
              className="button-2 no-margin"
              text={t("Save")}
              onClick={handleSubmitToCheck}
              loading={submitLoading}
              disabled={!areSplitsValidForCheckSubmmission}
            />
          </div>
        )}
      </div>
      <ManageNetPaySplits
        allValidPayrollBankAccounts={validPayrollBankAccounts}
        teamMemberDefaultNetPaySplitId={checkNetPaySplitId}
        editing={isEditing}
        onSplitUpdate={setSplits}
      />
    </div>
  );
};
