import React, { useEffect, useMemo, useState } from "react";
import { useActiveCompanyId, useTeamOptions } from "../../hooks/atom-hooks";
import { TableV2, ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import {
  ExpenseCard,
  AggregatedThirdPartyCard,
  MiterAPI,
  Stripe,
  AggregatedCardProgram,
  MiterFilterArray,
  BulkUpdateResult,
} from "../../miter";
import Select from "react-select";
import { styles as SelectStyles } from "ui/form/styles";
import { Option } from "ui/form/Input";
import { Notifier } from "ui/notifier";
import ExpenseCardModal from "../cards/ExpenseCardsModal";
import AddSpendingLimitsModal from "../cards/AddSpendingLimitsModal";
import { Plus, TrashSimple } from "phosphor-react";
import { titleCase } from "dashboard/utils";
import { notNullish } from "miter-utils";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { connectExternalCards, getPlaidLinkToken } from "dashboard/utils/expenses";
import PlaidLink from "dashboard/components/banking/PlaidLink";
import pluralize from "pluralize";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Params, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Button, ConfirmModal, DeleteModal, usdString } from "ui";
import { Helmet } from "react-helmet";
import { useThirdPartyCardAbilities } from "dashboard/hooks/abilities-hooks/useThirdPartyCardAbilities";
import { useMiterCardAbilities } from "dashboard/hooks/abilities-hooks/useMiterCardAbilities";
import IssueMiterCardModal from "./modals/IssueMiterCardModal";
import { useAstradaModal } from "dashboard/hooks/useAstradaModal";

type UnifiedExpenseCard = AggregatedThirdPartyCard | ExpenseCard;
export type CardManagementTableRow = {
  _id: string;
  dateAdded: number;
  financialInstitutionName: string | undefined;
  cardName: string | undefined;
  lastFour: string | undefined;
  assignedTeamMemberId: string | undefined | null;
  assignedTeamMemberFullName: string | undefined | null;
  type: "third_party_card" | "miter_card";
  cardStatus?: "connected" | "disconnected" | "active" | "canceled" | "inactive" | "pending";
  rawData: UnifiedExpenseCard;

  // miter card specific. columns will be hidden by default
  miterCardType?: "individual" | "company";
  miterCardExpiration?: string;
  miterCardSpendingLimitsDescription?: string;
};

const TeamMemberDropdown = (props: {
  onSelect: (option: Option<string>) => void;
  defaultTeamMemberId: string | undefined | null;
  teamOptions: Option<string>[];
  cardId: string;
  disabled?: boolean;
}) => {
  const currentTeamMember = props.teamOptions.find((option) => option.value === props.defaultTeamMemberId);

  return (
    <Select
      key={props.cardId}
      name="team_member"
      options={props.teamOptions}
      width={"250px"}
      // @ts-expect-error - react-select types are wrong
      onChange={props.onSelect}
      isClearable={true}
      menuPortalTarget={document.body}
      styles={SelectStyles}
      height="32px"
      value={currentTeamMember}
      isDisabled={props.disabled}
    />
  );
};

const CardManagementTable: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const { id: cardProgramId } = useParams<Params>();
  const { openAstradaModal, isAstradaModalLoading } = useAstradaModal({
    cardProgramId,
    onSuccess: async () => {
      // refresh the table
      getAllCards();
    },
  });

  const miterAbilities = useMiterAbilities();
  const thirdPartyCardAbilities = useThirdPartyCardAbilities();
  const miterCardAbilities = useMiterCardAbilities();

  const navigate = useNavigate();

  const thirdPartyCardTeamOptions = useTeamOptions({
    predicate: thirdPartyCardAbilities.teamPredicate("update"),
  });

  const [allCards, setAllCards] = useState<CardManagementTableRow[]>();
  const [cardProgram, setCardProgram] = useState<AggregatedCardProgram>();
  const [isAllCardsMiterCards, setIsAllCardsMiterCards] = useState<boolean>(false);
  const [areAnyCardsMiterCards, setAreAnyCardsMiterCards] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<CardManagementTableRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnlinkButtonLoading, setIsUnlinkButtonLoading] = useState<boolean>(false);
  const [isArchiveButtonLoading, setIsArchiveButtonLoading] = useState<boolean>(false);
  const [isMovingToNewCardProgramButtonLoading, setIsMovingToNewCardProgramButtonLoading] =
    useState<boolean>(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState<null | string>(null);

  // modals
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isMovingToNewCardProgram, setIsMovingToNewCardProgram] = useState<boolean>(false);

  // miter card only - legacy logic for now, to be update
  const [showAddExpenseLimitsModal, setShowAddExpenseLimitsModal] = useState<boolean>(false);
  const [activeExpenseCard, setActiveExpenseCard] = useState<ExpenseCard | null>(null);
  const [issueCardsModalOpen, setIssueCardsModalOpen] = useState<boolean>(false);

  const isMiterCard = (card: UnifiedExpenseCard): card is ExpenseCard => {
    return (card as ExpenseCard)?.stripe_acct !== undefined;
  };
  const isThirdPartyCard = (card: UnifiedExpenseCard): card is AggregatedThirdPartyCard => {
    return "external_vendor" in card;
  };

  // @ts-expect-error unified type error
  const selectedMiterCards: ExpenseCard[] = selectedRows
    .filter((card) => card.type === "miter_card")
    .filter((card) => isMiterCard(card.rawData))
    .map((row) => row.rawData)
    .filter(notNullish);

  const handleTeamMemberSelect = async (
    currentRow: CardManagementTableRow,
    selectedTeamMember: Option<string> | null,
    shouldNotify = true
  ): Promise<boolean> => {
    if (currentRow.type === "miter_card") return false;
    try {
      const res = await MiterAPI.expenses.third_party_cards.update(currentRow._id, {
        team_member_id: selectedTeamMember?.value ?? null,
      });
      if (res.error) throw new Error(res.error);

      if (shouldNotify) {
        Notifier.success(
          `Card ending in ${currentRow.lastFour} now ${
            selectedTeamMember?.label ? "assigned to " + selectedTeamMember?.label : "unassigned"
          }`
        );
      }
      await getAllCards();
      return true;
    } catch (e: $TSFixMe) {
      console.error("Error updating team member of card:", e);
      Notifier.error(e.message);
      return false;
    }
  };

  // if 4 or more cards selected, don't send individual notification
  const NUMBER_OF_CARDS_TO_BULK_NOTIFY = 4;

  const handleBulkUnlink = async (rows: CardManagementTableRow[]) => {
    setIsUnlinkButtonLoading(true);
    await Promise.all(
      rows.map(async (row) => {
        await handleTeamMemberSelect(row, null, rows.length < NUMBER_OF_CARDS_TO_BULK_NOTIFY);
      })
    );

    if (rows.length >= NUMBER_OF_CARDS_TO_BULK_NOTIFY) {
      Notifier.success(`Successfuly unassigned ${rows.length} cards from team members`);
    }
    setSelectedRows([]);
    setIsUnlinkButtonLoading(false);
    await getAllCards();
  };

  const handleBulkReconnect = async (rows: CardManagementTableRow[]) => {
    const firstRowPlaidId = (rows[0]?.rawData as AggregatedThirdPartyCard).plaid_item_id;
    if (!rows.every((row) => (row.rawData as AggregatedThirdPartyCard).plaid_item_id === firstRowPlaidId)) {
      Notifier.error(
        `All cards selected must be from the same login. This can happen if you have multiple bank logins connected to Miter. Feel free to reach out to Miter support to clear things up!`
      );
      return;
    }

    getPlaidLinkToken({
      company: activeCompanyId,
      external_financial_account_type: "company",
      updateMode: true,
      itemId: firstRowPlaidId,
      setLoading: () => {},
      setPlaidLinkToken,
      products: ["transactions"],
    });
  };

  const handleBulkArchive = async (rows: CardManagementTableRow[]) => {
    setIsArchiveButtonLoading(true);
    const archiveResults = await Promise.all(
      rows.map(async (row) => {
        try {
          const res = await MiterAPI.expenses.third_party_cards.update(row._id, {
            archived: true,
          });

          if (res.error) {
            throw new Error(res.error);
          }

          if (rows.length < NUMBER_OF_CARDS_TO_BULK_NOTIFY) {
            Notifier.success(
              `Card ending in ${row.lastFour} now deleted. Future purchases on deleted cards will no longer be synced to Miter.`
            );
          }

          // return 1 for successful
          return 1;
        } catch (err) {
          const errMsg = `Error deleting card ending in ${row.lastFour}.`;
          console.error(errMsg, err);
          Notifier.error(errMsg);

          // return 0 for failed
          return 0;
        }
      })
    );

    if (rows.length >= NUMBER_OF_CARDS_TO_BULK_NOTIFY) {
      const numSuccessful = archiveResults.reduce((acc: number, result) => {
        return acc + result;
      }, 0);

      Notifier.success(
        `Successfuly deleted ${numSuccessful} ${pluralize(
          "card",
          numSuccessful
        )}. Future purchases on deleted cards will no longer be synced to Miter.`
      );
    }

    setIsDeleting(false);
    setSelectedRows([]);
    setIsArchiveButtonLoading(false);
    await getAllCards();
  };

  // creates a new card program and moves the selected cards to it
  const handleBulkMoveToNewCardProgram = async (rows: CardManagementTableRow[]) => {
    setIsMovingToNewCardProgramButtonLoading(true);
    try {
      const response = await MiterAPI.expenses.card_programs.split({
        selected_card_ids: rows.map((row) => row._id),
      });
      if (response.error) throw new Error(response.error);
      Notifier.success(`Moved ${rows.length} ${pluralize("card", rows.length)} to a new card program.`);
      await getAllCards();
      setIsMovingToNewCardProgram(false);
    } catch (err) {
      Notifier.error("Error moving cards to a new card program.");
    }
    setIsMovingToNewCardProgramButtonLoading(false);
  };

  const handleMiterCardRowClick = async (row: CardManagementTableRow) => {
    // if third party card, noop
    if (row.type === "third_party_card") return;

    const miterCardObj = await MiterAPI.expense_cards.retrieve(row._id);
    if (!miterCardObj) {
      const err = `Miter card ending in ${row.lastFour} not found.`;
      console.error(err);
      Notifier.error(err);
    } else if (miterCardAbilities.can("update", miterCardObj)) {
      setActiveExpenseCard(miterCardObj);
    }
  };

  const onCardInfoSave = async (data: CardManagementTableRow[]): Promise<BulkUpdateResult | void> => {
    // only allow saving if all selected rows are not Miter cards
    if (data.every((row) => row.type === "miter_card")) {
      Notifier.error("Only third party cards can be edited.");
      return;
    }

    setIsLoading(true);
    const updateResult = await Promise.all(
      data.map(async (card) => {
        try {
          const res = await MiterAPI.expenses.third_party_cards.update(card._id, {
            // only field that can be edited is cardName
            card_name: card.cardName,
          });

          if (res.error) {
            throw new Error(res.error);
          }

          // return 1 for successful
          return 1;
        } catch (err) {
          const errMsg = `Error updating card.`;
          Notifier.error(errMsg);

          // return 0 for failed
          return 0;
        }
      })
    );

    const numSuccessful = updateResult.reduce((acc: number, result) => {
      return acc + result;
    }, 0);

    Notifier.success(`Updated ${numSuccessful} ${pluralize("card", numSuccessful)}.`);

    setIsLoading(false);
    await getAllCards();
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      ...(cardProgram?.type === "miter_card"
        ? [
            {
              label: "Issue a Miter card",
              className: "button-1",
              action: () => setIssueCardsModalOpen(true),
              important: true,
              icon: <Plus weight="bold" style={{ marginRight: 5 }} />,
              shouldShow: () => miterAbilities.can("miter_cards:create"),
            },
          ]
        : []),
    ],
    [cardProgram, miterAbilities.can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Move to new card program",
        className: "button-1",
        action: () => setIsMovingToNewCardProgram(true),
        shouldShow: (selectedRows: CardManagementTableRow[]) =>
          thirdPartyCardAbilities.can("update", selectedRows) &&
          selectedRows.every((row) => row.type === "third_party_card") &&
          selectedRows.length !== allCards?.length,
      },
      {
        label: "Unassign From team member",
        className: "button-1",
        action: handleBulkUnlink,
        loading: isUnlinkButtonLoading,
        shouldShow: (selectedRows: CardManagementTableRow[]) =>
          thirdPartyCardAbilities.can("update", selectedRows) &&
          selectedRows.every((row) => row.type === "third_party_card"),
      },
      {
        label: "Reconnect to bank",
        className: "button-1",
        action: handleBulkReconnect,
        loading: isUnlinkButtonLoading,
        shouldShow: (selectedRows: CardManagementTableRow[]) =>
          thirdPartyCardAbilities.can("update", selectedRows) &&
          selectedRows.every((row) => row.type === "third_party_card" && row.cardStatus === "disconnected"),
      },
      {
        label: "Delete",
        className: "button-3 no-margin",
        action: () => setIsDeleting(true),
        important: true,
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: (selectedRows: CardManagementTableRow[]) =>
          thirdPartyCardAbilities.can("delete", selectedRows) &&
          selectedRows.every((row) => row.type === "third_party_card"),
      },
      {
        label: "Add spending limits",
        className: "button-1 table-button",
        action: () => setShowAddExpenseLimitsModal(true),
        shouldShow: (selectedRows: CardManagementTableRow[]) =>
          miterCardAbilities.can("update", selectedRows) &&
          selectedRows.every((row) => row.type === "miter_card"),
      },
    ],
    [selectedRows, isUnlinkButtonLoading, miterCardAbilities.can, thirdPartyCardAbilities.can]
  );

  const getCardProgram = async () => {
    if (!activeCompanyId || !cardProgramId) return;
    try {
      const cardProgram = await MiterAPI.expenses.card_programs.list({
        filter: [
          { field: "company_id", value: activeCompanyId },
          { field: "_id", value: cardProgramId },
        ],
      });
      if (cardProgram.error) throw new Error(cardProgram.error);
      setCardProgram(cardProgram[0]);
    } catch (err) {
      console.error(`Error getting card program ${cardProgramId}`, err);
      Notifier.error(`Could not get this card program.`);
    }
  };

  const getAllCards = async () => {
    if (!activeCompanyId) return;

    setIsLoading(true);
    try {
      const thirdPartyCardFilter: MiterFilterArray = [{ field: "company_id", value: activeCompanyId }];
      const miterCardsFilter: MiterFilterArray = [{ field: "company", value: activeCompanyId }];

      if (cardProgramId) {
        thirdPartyCardFilter.push({ field: "card_program_id", value: cardProgramId });
        miterCardsFilter.push({ field: "card_program_id", value: cardProgramId });
      }

      // if user has no permission, result will be: { type: "or", value: [{ field: "team_member", type: "string", value: [], comparisonType: "in" }], }
      // explicitly check to see if value is an empty array
      const thirdPartyCardAbilitiesFilter = thirdPartyCardAbilities.filter("read");
      if (thirdPartyCardAbilitiesFilter) {
        thirdPartyCardFilter.push(thirdPartyCardAbilitiesFilter);
      }

      const miterCardAbilitiesFilter = miterCardAbilities.filter("read");
      if (miterCardAbilitiesFilter) {
        miterCardsFilter.push(miterCardAbilitiesFilter);
      }

      let allCards: UnifiedExpenseCard[] = [];

      try {
        const [thirdPartyCards, miterCards] = await Promise.all([
          MiterAPI.expenses.third_party_cards.list({ filter: thirdPartyCardFilter }),
          MiterAPI.expense_cards.list({ filter: miterCardsFilter }),
        ]);

        if (thirdPartyCards.error) throw new Error(thirdPartyCards.error);
        if (miterCards.error) throw new Error(miterCards.error);

        allCards = [...thirdPartyCards, ...miterCards];
      } catch (err: $TSFixMe) {
        console.error(
          `Error getting cards for card program ${cardProgramId}`,
          err.message,
          thirdPartyCardFilter,
          miterCardsFilter
        );
        Notifier.error(`Could not get the cards for this program.`);
      }

      const tableRows: CardManagementTableRow[] = allCards.map((card: UnifiedExpenseCard) => {
        if (isThirdPartyCard(card)) {
          // third party card
          return {
            _id: card._id,
            dateAdded: card.created_at,
            financialInstitutionName: card.financial_institution,
            cardName: card.card_name,
            lastFour: card.last_four,
            assignedTeamMemberId: card.team_member_id,
            assignedTeamMemberFullName: card.team_member?.full_name,
            type: "third_party_card",
            rawData: card,
            cardStatus: card.plaid_item?.reconnect_required ? "disconnected" : "connected",
          };
        } else {
          // miter card
          const {
            last4,
            status: cardStatus,
            exp_month,
            exp_year,
            shipping,
          } = card.card as Stripe.Issuing.Card;
          const { name, type, spending_controls, status: cardholderStatus } = card.cardholder;

          const getComputedMiterCardStatus = () => {
            if (cardStatus === "canceled") return "canceled";

            const isCardNotDeliveredYet =
              shipping?.status !== "delivered" && cardholderStatus === "active" && cardStatus === "inactive";

            if (isCardNotDeliveredYet) return "pending";

            const isCardholderInactive = cardholderStatus === "inactive";
            const computedCardStatus = isCardholderInactive ? "inactive" : cardStatus;

            return computedCardStatus;
          };

          return {
            _id: card._id,
            dateAdded: card.created_at,
            financialInstitutionName: "Miter",
            cardName: undefined,
            lastFour: last4,
            assignedTeamMemberId: card.team_member,
            assignedTeamMemberFullName: name,
            type: "miter_card",
            rawData: card,
            cardStatus: getComputedMiterCardStatus(),
            miterCardType: type,
            miterCardExpiration: `${exp_month}/${exp_year}`,
            miterCardSpendingLimitsDescription:
              spending_controls?.spending_limits
                ?.map(({ interval, amount }) => `${usdString(amount / 100)} ${titleCase(interval)}`)
                .join(", ") || "",
          };
        }
      });

      setIsAllCardsMiterCards(allCards.every(isMiterCard));
      setAreAnyCardsMiterCards(allCards.some(isMiterCard));
      setAllCards(tableRows);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error(e.message);
    }
    setIsLoading(false);
  };

  const initialGetAllCards = async () => {
    await Promise.all([getCardProgram(), getAllCards()]);
  };

  useEffect(() => {
    initialGetAllCards();
  }, []);

  const columns: ColumnConfig<CardManagementTableRow>[] = useMemo(() => {
    let initialColumns: ColumnConfig<CardManagementTableRow>[] = [
      {
        headerName: "Date added",
        field: "dateAdded",
        dataType: "date",
        dateType: "iso",
        sort: "desc",
      },
      {
        headerName: "Issuer",
        field: "financialInstitutionName",
        dataType: "string",
        hide: isAllCardsMiterCards,
      },
      {
        headerName: "Card name",
        field: "cardName",
        dataType: "string",
        hide: isAllCardsMiterCards,
        editable: true,
        editorType: "text",
      },
      {
        headerName: "Last 4",
        field: "lastFour",
        dataType: "string",
      },
      {
        headerName: "Team member",
        field: "assignedTeamMemberId",
        dataType: "component",
        valueGetter: (params) => {
          return params.data?.assignedTeamMemberFullName;
        },
        cellRenderer: (params) => {
          const currentRow: CardManagementTableRow = params.data;
          const handleTeamMemberSelectCurried =
            (currentRow: CardManagementTableRow) => async (option: Option<string>) => {
              await handleTeamMemberSelect(currentRow, option);
            };

          return (
            <>
              {currentRow.type === "third_party_card" ? (
                <TeamMemberDropdown
                  onSelect={handleTeamMemberSelectCurried(currentRow)}
                  // can't use currentRow.assignedTeamMember due to react-select caching bug
                  defaultTeamMemberId={currentRow.assignedTeamMemberId}
                  teamOptions={thirdPartyCardTeamOptions}
                  cardId={currentRow._id}
                  disabled={thirdPartyCardAbilities.cannot("update", currentRow)}
                />
              ) : (
                <>{currentRow.assignedTeamMemberFullName}</>
              )}
            </>
          );
        },

        minWidth: 300,
      },
      {
        field: "cardStatus",
        headerName: "Status",
        dataType: "string",
        displayType: "badge",
        colors: {
          active: "green",
          connected: "green",
          disconnected: "light-red",
          inactive: "light-gray",
          canceled: "light-red",
          pending: "light-blue",
        },
      },
    ];

    if (areAnyCardsMiterCards) {
      // all below are hidden by default if no Stripe account linked
      initialColumns = initialColumns.concat([
        {
          field: "miterCardExpiration",
          headerName: "Expiration",
          dataType: "string",
        },
        {
          field: "miterCardType",
          headerName: "Type",
          displayType: "badge",
          colors: {
            individual: "light-blue",
            company: "yellow",
          },
        },
        {
          field: "miterCardSpendingLimitsDescription",
          headerName: "Spending limits",
          dataType: "string",
        },
      ]);
    }

    return initialColumns;
  }, [areAnyCardsMiterCards, thirdPartyCardAbilities.cannot, isAllCardsMiterCards, isLoading]);

  const renderBreadcrumbs = () => {
    if (!cardProgram) return;

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Card Programs", path: "/expenses/card-programs" },
          { label: cardProgram?.name, path: `/expenses/card-programs/${cardProgram?._id}` },
        ]}
      />
    );
  };

  const renderBody = () => (
    <>
      <TableV2
        id="card-management-table"
        resource="cards"
        data={allCards}
        columns={columns}
        paginationPageSize={50}
        isLoading={isLoading}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleMiterCardRowClick}
        showReportViews={true}
        editable={!isAllCardsMiterCards}
        onSave={onCardInfoSave}
      />
      {activeExpenseCard && (
        <ExpenseCardModal
          onHide={() => setActiveExpenseCard(null)}
          card={activeExpenseCard}
          onSuccess={getAllCards}
        />
      )}
      {showAddExpenseLimitsModal && (
        <AddSpendingLimitsModal
          onHide={() => setShowAddExpenseLimitsModal(false)}
          expenseCards={selectedMiterCards}
          onSuccess={getAllCards}
        />
      )}
      {issueCardsModalOpen && (
        <IssueMiterCardModal
          onHide={() => setIssueCardsModalOpen(false)}
          onSubmit={getAllCards}
          miterCards={allCards?.filter((card) => card.type === "miter_card") || []}
        />
      )}
      {plaidLinkToken && (
        <PlaidLink
          token={plaidLinkToken}
          onSuccess={(public_token: string, metadata: PlaidLinkOnSuccessMetadata) =>
            connectExternalCards({
              company: activeCompanyId || undefined,
              public_token,
              metadata,
              updateMode: true,
              callback: getAllCards,
            })
          }
          onExit={() => setPlaidLinkToken(null)}
        />
      )}
      {isDeleting && (
        <DeleteModal
          header={`Delete card`}
          body={`Transactions from the selected ${pluralize(
            "card",
            selectedRows.length
          )} will no longer be monitored. Previous transactions will not be affected; you can archive those in the "Card transactions" page.`}
          cancelText={"Cancel"}
          onHide={() => setIsDeleting(false)}
          deleteText={"Yes, delete"}
          onDelete={() => handleBulkArchive(selectedRows)}
          loading={isArchiveButtonLoading}
        />
      )}
      {isMovingToNewCardProgram && (
        <ConfirmModal
          title="Move to new card program"
          body="The selected cards will be moved to a new card program with the same name and settings (integration configurations, etc). You can update those settings once this action is completed."
          onNo={() => setIsMovingToNewCardProgram(false)}
          onYes={() => handleBulkMoveToNewCardProgram(selectedRows)}
          loading={isMovingToNewCardProgramButtonLoading}
        />
      )}
    </>
  );

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{`${cardProgram?.name} | Miter`}</title>
      </Helmet>
      <div className="page-content">
        {renderBreadcrumbs()}
        <div className="flex">
          <h1>{cardProgram?.name}</h1>
          <div className="flex-1"></div>
          {/* only show this if the card program is for Astrada cards. */}
          {!cardProgram?.external_account_id && cardProgram?.type === "third_party_card" && (
            <Button
              className="button-1"
              text="Link another card"
              onClick={openAstradaModal}
              loading={isAstradaModalLoading}
            />
          )}
          <Button
            className="button-1"
            text="Card program settings"
            onClick={() => navigate(`/expenses/card-programs/${cardProgram?._id}/settings`)}
          />
        </div>
        {renderBody()}
      </div>
    </div>
  );
};

export default CardManagementTable;
