import { useEarningTypeArray } from "dashboard/hooks/integrations/useEarningTypeArray";
import { X } from "phosphor-react";
import React from "react";
import { Formblock, Button } from "ui";
import { Option } from "ui/form/Input";
import { TimesheetEarningType } from "backend/models/timesheet";

interface IntegrationEarningTypeConfigProps {
  earningTypeMapping?: Record<string, TimesheetEarningType>;
  saveEarningTypeCallback: (newEarningTypeMap: Record<string, TimesheetEarningType>) => Promise<void>;
  earningTypeOptions: Option<string>[];
}

export const IntegrationEarningTypeConfig: React.FC<IntegrationEarningTypeConfigProps> = ({
  earningTypeMapping,
  saveEarningTypeCallback,
  earningTypeOptions,
}) => {
  const {
    loading,
    currentEarningTypeArray,
    handleAddEarningTypeMapping,
    handleChangeTimeType,
    handleChangeEarningType,
    handleDeleteEarningTypeMapping,
    handleEarningTypeMapSave,
  } = useEarningTypeArray(earningTypeMapping);

  return (
    <div style={{ maxWidth: 600 }}>
      {currentEarningTypeArray.map(({ timeType, earningType }, index) => {
        return (
          <div className="flex align-items-center" key={index}>
            <Formblock
              className={"modal width-100-percent"}
              placeholder={`Time Type`}
              type="text"
              name={`timeType-${index}-${timeType}`}
              value={timeType}
              editing={true}
              onChange={(e) => handleChangeTimeType(index, e.target.value?.trim())}
            />
            <Formblock
              className={"modal width-100-percent"}
              placeholder={`Earning type`}
              type="select"
              options={earningTypeOptions}
              value={earningTypeOptions.find((b) => b.value === earningType)}
              editing={true}
              onChange={(o) => handleChangeEarningType(index, o.value)}
              style={{ marginLeft: 10 }}
            />

            <div>
              <Button className="button-text" onClick={() => handleDeleteEarningTypeMapping(index)}>
                <X style={{ marginLeft: 10, marginTop: -10 }} />
              </Button>
            </div>
          </div>
        );
      })}
      {!currentEarningTypeArray.length && (
        <div style={{ marginBottom: 25, opacity: 0.7 }}>No options selected</div>
      )}

      <div className="flex" style={{ marginTop: 20 }}>
        <Button className="button-1" text="Add option" onClick={() => handleAddEarningTypeMapping()} />
        <Button
          className="button-2 no-margin"
          text="Save changes"
          onClick={() => handleEarningTypeMapSave(saveEarningTypeCallback)}
          loading={loading}
        />
      </div>
    </div>
  );
};
