import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  useActiveCompany,
  useActiveTeam,
  useLookupTeam,
  useProfilePictureUrlsMap,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { OrgChart as D3OrgChart } from "d3-org-chart";
import { AggregatedTeamMember } from "dashboard/miter";
import { Helmet } from "react-helmet";
import { Badge, Breadcrumbs, Button, Formblock } from "ui";
import ReactDOMServer from "react-dom/server";
import { TeamMemberProfilePicture } from "miter-components";
import { getColorPickerColor } from "ui/form/ColorPicker";

import styles from "./OrgChart.module.css";
import {
  ArrowsIn,
  ArrowsOut,
  CornersOut,
  Download,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from "phosphor-react";
import { createObjectMap } from "dashboard/utils";
import { Link } from "react-router-dom";

type OrgChartTeamMember = {
  _id?: string;
  full_name: string;
  title: string;
  department?: { name: string };
  reports_to?: { _id: string };
  imageURL?: string | undefined;
};

type AggregatedTeamMemberWithImage = AggregatedTeamMember & { imageURL: string | undefined };

type IOrgChartNode = {
  nodeId: string;
  parentNodeId: string | null;
  teamMember: OrgChartTeamMember;
};

/**
 * Main component for displaying the org chart. Utilizes custom hooks for fetching
 * active team and company data, and leverages D3 for rendering the org chart.
 */
export const OrgChart: React.FC = () => {
  const activeTeam = useActiveTeam();
  const activeCompany = useActiveCompany();
  const profilePictureUrlsMap = useProfilePictureUrlsMap();
  const nodes = useGenerateD30OrgChartNodes();

  const cycles = useMemo(() => getCycles(nodes), [nodes]);

  /**
   * Memoized data construction for the org chart, including nodes
   * for team members and the root node for the company.
   */
  const data: IOrgChartNode[] = useMemo(() => {
    if (cycles.length > 0) return [];
    const nodesWithoutParent = nodes.filter((node) => node.parentNodeId === "company");

    const companyNode = {
      nodeId: "company",
      teamMember: {
        full_name: activeCompany?.check_company?.trade_name || "Company",
        title: "Company",
        department: { name: "Company" },
      },
      childrenCount: nodesWithoutParent.length,
      parentNodeId: null,
    };

    return [companyNode, ...nodes];
  }, [activeTeam, profilePictureUrlsMap, nodes, cycles]);

  const onNodeClick = (nodeId: string) => {
    window.open(`/team-members/${nodeId}`, "_blank");
  };

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs
        crumbs={[
          { label: "Team", path: "/team-members/" },
          { label: "Org chart", path: "/team/org-chart" },
        ]}
      />
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Team | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className={"page-content-header "} style={{ marginBottom: 50 }}>
          {renderBreadcrumbs()}
          <h1 className="view-title" style={{ marginTop: 10 }}>
            Org chart
          </h1>
          <p className={"header-subtitle"}>View your team&apos;s org chart and reporting structure.</p>
        </div>
        <OrgChartBuilder onNodeClick={onNodeClick} data={data} cycles={cycles} />
      </div>
    </div>
  );
};

type OrgChartBuilderProps = {
  onNodeClick: (nodeId: string) => void;
  data: IOrgChartNode[];
  cycles: string[][];
};

/**
 * Component responsible for building and managing the D3 org chart.
 * @param onNodeClick - Handler for when a node is clicked.
 * @param data - Data for the org chart including nodes and connections.
 */
const OrgChartBuilder: React.FC<OrgChartBuilderProps> = ({ onNodeClick, data, cycles }) => {
  const lookupTeam = useLookupTeam();
  const d3Container = useRef(null);
  const [chart] = useState(new D3OrgChart<IOrgChartNode>());

  useLayoutEffect(() => {
    if (d3Container.current && chart.firstDraw()) {
      chart
        ?.container(d3Container.current)
        .data(data)
        .nodeContent((node) => {
          return ReactDOMServer.renderToString(
            <OrgChartNode teamMember={(node.data as $TSFixMe)?.teamMember} />
          );
        })
        .buttonContent((node) => {
          return ReactDOMServer.renderToString(<OrgChartButton node={node} />);
        })
        .onNodeClick((node) => {
          onNodeClick(node.data?.nodeId);
        })
        .nodeHeight((_node) => 200)
        .nodeButtonHeight((_node) => 150)
        .render();
    }
  }, [data, d3Container.current, chart.firstDraw()]);

  const renderCircularReportingStructureWarning = () => {
    return (
      <div className={styles["circular-reporting-structure-warning"]}>
        <h4 className={styles["circular-reporting-structure-warning-header"]}>
          Circular reporting structure detected
        </h4>
        <p className={styles["circular-reporting-structure-warning-subheader"]}>
          This means that there are team members who report to themselves or to someone who reports to them.
          An org chart cannot be generated when this is the case To fix this, ensure that each team member
          reports to someone that does not report to them.
        </p>
        {cycles
          .filter((cycle) => cycle.length)
          .map((cycle, index) => (
            <p key={"cycle-" + index} className={styles["circular-reporting-structure-warning-cycle"]}>
              {cycle.map((nodeId, index) => {
                const teamMember = lookupTeam(nodeId);
                return (
                  <span key={"cycle-node-" + index}>
                    <Link to={`/team-members/${teamMember?._id}`} className="purple-link" target="_blank">
                      {teamMember?.full_name}
                    </Link>
                    {index < cycle.length - 1 && " -> "}
                  </span>
                );
              })}
            </p>
          ))}
      </div>
    );
  };

  const renderChart = () => {
    return (
      <>
        <div ref={d3Container} />
        <OrgChartLegend chart={chart} />
        <OrgChartEmployeeDropdown chart={chart} />
      </>
    );
  };

  return (
    <div className={"org-chart " + styles["org-chart"]}>
      {cycles.length === 0 && renderChart()}
      {cycles.length > 0 && renderCircularReportingStructureWarning()}
    </div>
  );
};

type OrgChartLegendProps = {
  chart: D3OrgChart<IOrgChartNode>;
};

/**
 * Component for displaying the org chart's legend, including
 * controls for zooming in/out, fitting the chart, expanding/collapsing nodes, and exporting.
 * @param chart - The D3OrgChart instance.
 */
const OrgChartLegend: React.FC<OrgChartLegendProps> = ({ chart }) => {
  return (
    <div className={styles["org-chart-legend-container"]}>
      <div className={styles["org-chart-legend-item"]} onClick={() => chart.zoomIn()}>
        <MagnifyingGlassPlus />
      </div>
      <div className={styles["org-chart-legend-item"]} onClick={() => chart.zoomOut()}>
        <MagnifyingGlassMinus />
      </div>
      <div className={styles["org-chart-legend-item"]} onClick={() => chart.fit({ animate: true })}>
        <CornersOut />
      </div>
      <div className={styles["org-chart-legend-item"]} onClick={() => chart.expandAll()}>
        <ArrowsOut />
      </div>
      <div className={styles["org-chart-legend-item"]} onClick={() => chart.collapseAll()}>
        <ArrowsIn />
      </div>
      <div className={styles["org-chart-legend-item"]} onClick={() => chart.exportSvg()}>
        <Download />
      </div>
    </div>
  );
};

type OrgChartEmployeeDropdownProps = {
  chart: D3OrgChart<IOrgChartNode>;
};

/**
 * Dropdown component for selecting an employee to focus on in the org chart.
 * @param chart - The D3OrgChart instance.
 */
const OrgChartEmployeeDropdown: React.FC<OrgChartEmployeeDropdownProps> = ({ chart }) => {
  const teamOptions = useTeamOptions();

  const onEmployeeSelect = (option) => {
    if (option?.value) {
      chart.setCentered(option.value);
      chart.render();
    }
  };

  return (
    <div className={styles["org-chart-employee-dropdown-container"]}>
      <Formblock
        name="employe-dropdown"
        type="select"
        options={teamOptions}
        editing={true}
        isClearable={true}
        placeholder="Find an employee"
        onChange={onEmployeeSelect}
        style={{ width: 300, marginBottom: 0 }}
      />
    </div>
  );
};

type OrgChartNodeProps = {
  teamMember: AggregatedTeamMemberWithImage;
};

/**
 * Component for rendering an individual node in the org chart, including the team member's profile picture,
 * name, title, and department.
 * @param teamMember - The team member data associated with this node.
 */
const OrgChartNode: React.FC<OrgChartNodeProps> = ({ teamMember }) => {
  if (!teamMember) return <></>;

  const highlightColor = teamMember?.department?.name
    ? getColorPickerColor(teamMember?.department?.name)
    : "#eeeeee";

  return (
    <div className={styles["org-chart-node-container"]} style={{ borderTop: `5px solid ${highlightColor}` }}>
      <div className={styles["org-chart-node-container-profile"]}>
        <TeamMemberProfilePicture
          teamMember={teamMember}
          readonly={true}
          directPictureURL={teamMember.imageURL}
          size={50}
          rounded={true}
        />
      </div>

      <div className={styles["org-chart-node-name"]}>{teamMember?.full_name}</div>
      <div className={styles["org-chart-node-title"]}>{teamMember?.title || "No title"}</div>
      <Badge
        className="no-margin"
        text={teamMember?.department?.name || "No department"}
        style={{ fontSize: "0.65rem" }}
        backgroundColor={highlightColor}
      />
    </div>
  );
};

type OrgChartButtonProps = {
  node: $TSFixMe;
};

/**
 * Button component for org chart nodes, displaying the number of children
 * and allowing for expanding/collapsing of the node's children.
 * @param node - The current org chart node.
 */
const OrgChartButton: React.FC<OrgChartButtonProps> = ({ node }) => {
  return (
    <div>
      <Button className="button-1 ">
        <span>
          {node.node.data.childrenCount}&nbsp;
          {node.node.children === null ? "+" : "-"}
        </span>
      </Button>
    </div>
  );
};

/**
 * Generates the nodes for the org chart
 */

const useGenerateD30OrgChartNodes = () => {
  const activeTeam = useActiveTeam();
  const lookupActiveTeam = useMemo(() => createObjectMap(activeTeam, (tm) => tm._id), [activeTeam]);
  const profilePictureUrlsMap = useProfilePictureUrlsMap();

  // Map of team members to their direct reports for easy access
  const teamMemberReportsToMap = activeTeam.reduce((acc, teamMember) => {
    if (teamMember.reports_to?._id) {
      if (!acc[teamMember.reports_to._id]) {
        acc[teamMember.reports_to._id] = [];
      }
      acc[teamMember.reports_to._id]!.push(teamMember);
    }
    return acc;
  }, {} as Record<string, AggregatedTeamMember[]>);

  // Generate the nodes for the org chart and make sure that every parent is either an active team member or the company
  return useMemo(
    () =>
      activeTeam.map((teamMember) => {
        const tm: AggregatedTeamMemberWithImage = {
          ...teamMember,
          imageURL: profilePictureUrlsMap[teamMember._id],
        };

        const directManager = lookupActiveTeam[teamMember.reports_to?._id || ""] || null;

        return {
          nodeId: teamMember._id,
          teamMember: tm,
          childrenCount: teamMemberReportsToMap[teamMember._id]?.length || 0,
          parentNodeId: directManager?._id || "company",
        };
      }),
    [activeTeam, profilePictureUrlsMap, teamMemberReportsToMap, lookupActiveTeam]
  );
};

/**
 * Get a list of the cycles in a set of nodes and return an array of the cycle paths
 */
const getCycles = (nodes: IOrgChartNode[]) => {
  const cycles: string[][] = [];
  const visited = new Set();
  const recStack = new Set(); // To keep track of nodes currently in recursion stack

  const dfs = (node: IOrgChartNode, path: string[]) => {
    if (recStack.has(node.nodeId)) {
      // If node is in recursion stack, we found a cycle
      const cycleStartIndex = path.indexOf(node.nodeId);
      if (cycleStartIndex !== -1) {
        // Ensure cycle start is in the current path
        cycles.push(path.slice(cycleStartIndex));
      }
      return;
    }

    if (visited.has(node.nodeId)) {
      // If we've already visited this node and it's not part of the current recursion stack, skip it
      return;
    }

    visited.add(node.nodeId); // Mark the node as visited
    recStack.add(node.nodeId); // Add to recursion stack
    path.push(node.nodeId); // Add to current path

    for (const child of nodes.filter((n) => n.parentNodeId === node.nodeId)) {
      dfs(child, [...path]); // Use a copy of the path for each child to prevent path pollution
    }

    recStack.delete(node.nodeId); // Remove from recursion stack after exploring all children
  };

  for (const node of nodes) {
    if (!visited.has(node.nodeId)) {
      dfs(node, []);
    }
  }

  return cycles;
};
