import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PayrollContext from "./payrollContext";
import { Loader } from "ui";
import "../payroll.css";
import { AggregatedMiterEarning, AggregatedPayroll, MiterAPI, MiterError } from "../../../miter";
import Notifier from "../../../utils/notifier";
import DraftPayroll from "./DraftPayroll/DraftPayroll";
import NonDraftPayroll from "./nonDraftPayroll/NonDraftPayroll";
import PayrollBanner from "../PayrollBanner";
import { PayrollAdjustment } from "../payrollTypes";
import { GetPayrollQuery } from "backend/controllers/payrolls-controller";
import { ExpenseReimbursementEntry } from "dashboard/pages/expenses/ExpenseReimbursementsTable";
import Banner from "dashboard/components/shared/Banner";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { DateTime } from "luxon";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { CheckPaymentWireDetails } from "backend/utils/check/check-types";
import { usePayScheduleAccessor } from "dashboard/hooks/usePayScheduleAccessor";
import { useHasAccessToBillPay } from "dashboard/gating";

const PayrollPage: React.FC = () => {
  // Hooks

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { can } = useMiterAbilities();
  const { canAccessPayroll } = usePayScheduleAccessor();
  const hasAccessToBillPay = useHasAccessToBillPay();

  // State
  const [payroll, setPayroll] = useState<AggregatedPayroll | undefined>();
  const [loading, setLoading] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [aggEarnings, setAggEarnings] = useState<AggregatedMiterEarning[]>();
  const [expenseReimbursementEntries, setExpenseReimbursementEntries] = useState<ExpenseReimbursementEntry[]>(
    []
  );
  const [expensesLoading, setExpensesLoading] = useState(false);
  const [showWireDetails, setShowWireDetails] = useState(false);
  const [wireDetails, setWireDetails] = useState<CheckPaymentWireDetails | null>(null);

  useEffect(() => {
    if (payroll && !canAccessPayroll(payroll)) {
      Notifier.error("You do not have access to this payroll");
      navigate("/payrolls");
    }
  }, [payroll, canAccessPayroll]);

  const hasMultipleWorkweeks = useMemo(() => {
    if (!payroll?.first_workweek_start) return false;
    const firstWorkweekStart = DateTime.fromISO(payroll.first_workweek_start);
    const endOfFirstWorkweek = firstWorkweekStart.plus({ days: 6 }).toISODate();
    if (endOfFirstWorkweek < payroll.check_payroll.period_end) return true;
    return false;
  }, [payroll]);

  const stdTogglerItems = useMemo(() => {
    if (!payroll) return [];

    const canViewTimesheets = can("timesheets:others:read") || can("timesheets:personal:read");
    const canViewTimeOff = can("time_off:requests:others:read") || can("time_off:requests:personal:read");
    const canViewReimbursements = can("reimbursements:others:read") || can("reimbursements:personal:read");

    const items: TogglerConfigItem[] = [
      { path: "payments", label: "Payments" },
      { path: "workweek", label: `Workweek${hasMultipleWorkweeks ? "s" : ""}` },
      { path: "timesheets", label: "Timesheets", hide: !canViewTimesheets },
      { path: "reimbursements", label: "Reimbursements", hide: !canViewReimbursements },
    ];
    if (payroll?.type === "off_cycle") {
      items.push({ path: "settings", label: "Settings" });
    } else if (payroll?.type === "regular") {
      items.push(...[{ path: "time_off", label: "Time off", hide: !canViewTimeOff }]);
    }

    // only show if an existing payroll
    if (hasAccessToBillPay && payroll.check_payroll.status !== "draft") {
      items.push({ path: "bills", label: "Bills" });
    }

    return items;
  }, [payroll, hasMultipleWorkweeks]);

  const handlePayrollErrorResponse = (err: MiterError): boolean => {
    if (
      err.error_type === "net_pay_negative" ||
      err.error_type === "non_empty_payroll_required" ||
      err.error_type === "payroll_approval_deadline_expired"
    ) {
      Notifier.error(err.error);
    } else if (
      err.error_type === "archived_payroll" ||
      err.error_type === "payroll_deactivated" ||
      err.error_status === 404
    ) {
      Notifier.error(err.error);
      navigate("/payrolls");
    } else if (err.error_type === "payroll_already_approved") {
      Notifier.warning(err.error);
      getPayroll();
      return true;
    } else if (err.error_type === "validation_error" && err.fields?.length) {
      for (const f of err.fields) {
        Notifier.error(f.error);
      }
    } else {
      throw new Error(err.error);
    }
    return false;
  };

  // Helper functions
  const getPayroll = async (options?: GetPayrollQuery) => {
    if (!id) return;

    setLoading(true);
    try {
      const response = await MiterAPI.payrolls.retrieve(id, options);
      if (response.error) {
        const returnEarly = handlePayrollErrorResponse(response);
        if (returnEarly) return;
      } else {
        setPayroll(response);
        setIsPreviewing(options?.preview === "true");
      }
      setLoading(false);
    } catch (e: $TSFixMe) {
      console.error("Payroll retrieval error:", e);
      Notifier.error(e.message);
      setLoading(false);
    }
  };

  const recalculatePayroll = async (params?: {
    adjustments?: PayrollAdjustment[];
    tms?: string[] | "all";
  }) => {
    if (!id) return;
    const { adjustments, tms } = params || {};
    setLoading(true);
    try {
      const update: { adjustments?: PayrollAdjustment[]; tms?: string[] } = {};
      if (tms && tms !== "all") update.tms = tms;
      if (adjustments) update.adjustments = adjustments;

      const response = await MiterAPI.payrolls.recalculate(id, update);
      if (response.error) {
        const returnEarly = handlePayrollErrorResponse(response);
        if (returnEarly) return;
      } else {
        setPayroll(response);
      }
    } catch (e: $TSFixMe) {
      console.error("Payroll recalculation error:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const getAggEarnings = async () => {
    if (!payroll) return;
    try {
      const response = await MiterAPI.payrolls.get_earnings(payroll._id);
      if (response.error) throw new Error(response.error);
      setAggEarnings(response);
    } catch (e: $TSFixMe) {
      console.log("Error getting payroll workweek summary", e);
      Notifier.error("Error retrieving the workweek summary: " + e.message);
    }
  };

  const getPayrollExpenseReimbursementEntries = async (): Promise<void> => {
    if (!payroll) return;
    setExpensesLoading(true);

    try {
      const response = await MiterAPI.payrolls.retrieve_expense_reimbursements(payroll._id);
      if (response.error) throw new Error(response.error);
      setExpenseReimbursementEntries(response.expense_reimbursements);
    } catch (e: $TSFixMe) {
      console.log("Error getting expense reimbursements for payroll", e);
      Notifier.error("There was an error retrieving expense reimbursement requests: " + e.message);
    }
    setExpensesLoading(false);
  };

  const renderBanner = () => {
    if (!payroll) return null;
    return (
      <div className="payroll-banner">
        {payroll.check_payroll.status === "failed" ? (
          <Banner
            content={`We were unable to withdraw the necessary funds for this payroll. Please retry the payment via wire or ACH in the "Funding" section below.`}
            type="error"
            className="top-20"
          />
        ) : (
          <PayrollBanner />
        )}
      </div>
    );
  };

  // Effects
  useEffect(() => {
    getPayroll();
  }, []);

  useEffect(() => {
    getAggEarnings();
    getPayrollExpenseReimbursementEntries();
  }, [payroll]);

  return (
    <div className="height-100">
      <PayrollContext.Provider
        value={{
          payroll: payroll,
          aggEarnings: aggEarnings,
          getPayroll: getPayroll,
          setPayroll: setPayroll,
          recalculatePayroll: recalculatePayroll,
          isLoading: loading,
          stdTogglerItems: stdTogglerItems,
          expenseReimbursementEntries: expenseReimbursementEntries,
          getExpenseReimbursementEntries: getPayrollExpenseReimbursementEntries,
          expensesLoading: expensesLoading,
          isPreviewing,
          setIsPreviewing,
          showWireDetails,
          setShowWireDetails,
          wireDetails,
          setWireDetails,
        }}
      >
        {renderBanner()}

        {loading && !payroll && (
          <div
            className="height-100"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
            <div style={{ fontWeight: 500 }}>Preparing your payroll...</div>
          </div>
        )}
        {payroll && payroll.check_payroll.status !== "draft" && <NonDraftPayroll />}
        {payroll && payroll.check_payroll.status === "draft" && <DraftPayroll />}
      </PayrollContext.Provider>
    </div>
  );
};

export default PayrollPage;
