import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button, Loader } from "ui";
import { ColDef, GridApi, ValueGetterParams } from "ag-grid-community";

import "ag-grid-enterprise";
// import "../reports.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { DateTime } from "luxon";
import { hoursFormatter, toDollarFormat } from "dashboard/pages/reports/reportUtils";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { AggregatedMiterEarning, AggregatedPayroll } from "dashboard/miter";
import PaymentModal from "dashboard/pages/payrolls/viewPayroll/PaymentModal/PaymentModal";
import { AggEarningEntry, useReduceAggEarnings } from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";
import { buildAgGridColumns, buildAgGridRow, roundTo } from "miter-utils";
import {
  useActiveCompanyId,
  useActivityLabelFormatter,
  useJobNameFormatter,
  useLookupCrew,
  useLookupDepartment,
  useLookupTeam,
  useLookupTeamMemberCrews,
} from "dashboard/hooks/atom-hooks";
import {
  downloadJonasTimesheetCsv,
  downloadVistaCsv,
  downloadWbsCsv,
} from "dashboard/utils/csvDownloadFunctions";
import { useSage300TimeEntriesCSV } from "dashboard/utils/useSage300TimeEntriesCsv";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { ExportWarningsModal } from "dashboard/components/shared/ExportWarnings";
import { shorten } from "dashboard/utils";
import { Option } from "ui/form/Input";
import Select from "react-select";
import { selectStyles } from "ui/form/styles";
import AppContext from "dashboard/contexts/app-context";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";

type ViewType = "byDay" | "summary";

type Props = {
  gridApi?: GridApi;
  setGridApi?: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
  editing?: boolean;
  earnings?: AggregatedMiterEarning[];
  payroll?: AggregatedPayroll;
  periodStart: string;
  periodEnd: string;
  initialViewType?: ViewType;
  payScheduleId?: string | null;
};

export const HoursByPayPeriodTable: React.FC<Props> = ({
  gridApi,
  setGridApi,
  editing,
  earnings: initialEarnings,
  payroll,
  periodStart,
  periodEnd,
  initialViewType,
  payScheduleId,
}) => {
  const lookupDept = useLookupDepartment();
  const lookupCrew = useLookupCrew();
  const lookupTeamMember = useLookupTeam();
  const lookupTeamMemberCrews = useLookupTeamMemberCrews();
  const [rowsAreExpanded, setRowsAreExpanded] = useState<boolean>(false);
  const [paymentModal, setPaymentModal] = useState<string | undefined>(undefined);
  const [viewType, setViewType] = useState<ViewType>(initialViewType || "summary");
  const [selectedWorkweek, setSelectedWorkweek] = useState<{ start: string; end: string } | undefined>();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const reduceAggEarnings = useReduceAggEarnings();

  const timesheetAbilities = useTimesheetAbilities();
  const timeOffRequestAbilities = useTimeOffRequestAbilities();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns();

  const { customFields } = useContext(AppContext);

  const earnings = useMemo(() => {
    if (!initialEarnings) return [];
    return initialEarnings.filter((e) => {
      if (e.miter_type === "time_off" || e.miter_type === "holiday") {
        return timeOffRequestAbilities.can("read", e);
      } else {
        return timesheetAbilities.can("read", e);
      }
    });
  }, [initialEarnings, timeOffRequestAbilities, timesheetAbilities]);

  const teamMemberCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "team_member"),
    [customFields]
  );

  const firstWorkweekStartIso = useMemo(() => payroll?.first_workweek_start, [payroll]);

  const workweekOptions = useMemo(() => {
    if (!firstWorkweekStartIso || !payroll) return;
    let workweekStartIso = firstWorkweekStartIso;
    const options: Option<string>[] = [];
    let week = 1;
    while (workweekStartIso <= periodEnd) {
      const workweekStartString = DateTime.fromISO(workweekStartIso).toFormat("LLL d");
      const workweekEndString = DateTime.fromISO(workweekStartIso).plus({ days: 6 }).toFormat("LLL d");
      options.push({
        value: workweekStartIso,
        label: `Week ${week}: ${workweekStartString} - ${workweekEndString}`,
      });
      workweekStartIso = DateTime.fromISO(workweekStartIso).plus({ days: 7 }).toISODate();
      week += 1;
    }
    if (options[0]?.value) {
      const start = options[0].value;
      const end = DateTime.fromISO(start).plus({ days: 6 }).toISODate();
      setSelectedWorkweek({ start, end });
    }

    return options;
  }, [firstWorkweekStartIso]);

  const activeCompanyId = useActiveCompanyId();
  const companyId = activeCompanyId;
  const activityFormatter = useActivityLabelFormatter();
  const jobFormatter = useJobNameFormatter();

  const integrationKeyToIntegrationMap = useHasIntegration();

  const vistaIntegrationConnectionId = integrationKeyToIntegrationMap.get("vista")?._id;
  const wbsIntegrationConnectionId = integrationKeyToIntegrationMap.get("wbs")?._id;
  const jonasIntegrationConnectionId = integrationKeyToIntegrationMap.get("jonas")?._id;
  const sage300IntegrationConnectionId = integrationKeyToIntegrationMap.get("sage_300")?._id;

  const sage300CsvUtils = useSage300TimeEntriesCSV(companyId, periodStart, periodEnd);
  const {
    loading,
    warnings,
    setWarnings,
    downloadSage300ExportFile,
    data: timeEntriesData,
    timeEntriesFileName,
  } = sage300CsvUtils;

  const jobNameValueGetter = useCallback(
    (params: ValueGetterParams<Pick<AggEarningEntry, "job_id">>) => {
      return jobFormatter(params.data?.job_id);
    },
    [jobFormatter]
  );

  const activityNameValueGetter = useCallback(
    (params: ValueGetterParams<Pick<AggEarningEntry, "activity_id" | "miter_type">>) => {
      let label = activityFormatter(params.data?.activity_id);
      if (params.data?.miter_type === "missed_lunch") {
        if (label) {
          label += " (missed lunch)";
        } else {
          label = "Missed lunch";
        }
      }
      return label;
    },
    [activityFormatter]
  );

  const data: AggEarningEntry[] | AggregatedMiterEarning[] = useMemo(() => {
    if (!earnings) return [];
    const workweekEarnings = earnings.filter((e) => {
      if (!selectedWorkweek) return true;

      const earningDate = e.date || periodEnd;
      return earningDate >= selectedWorkweek.start && earningDate <= selectedWorkweek.end;
    });
    if (viewType === "summary") {
      return workweekEarnings.map((e) => {
        const reg_hours = (["hourly", "salaried", "regular"].includes(e.check_type) && e.hours) || 0;
        const ot_hours = (e.check_type === "overtime" && e.hours) || 0;
        const dot_hours = (e.check_type === "double_overtime" && e.hours) || 0;
        const otherHours = roundTo((e.hours || 0) - reg_hours - ot_hours - dot_hours, 2);

        const tm = lookupTeamMember(e.team_member._id);
        if (!tm) return e;

        return {
          ...e,
          reg_hours,
          ot_hours,
          dot_hours,
          otherHours,
          job_id: e.job?._id,
          activity_id: e.activity?._id,
          job_hierarchy_ids: e.job_hierarchy_ids,
          ...buildAgGridRow(tm.custom_field_values, teamMemberCustomFields),
        };
      });
    }
    // Ensure each TM shows up in the table
    payroll?.miter_payments.forEach((mp) => {
      const dept = lookupDept(mp.team_member.department_id);
      const tm = lookupTeamMember(mp.team_member._id);
      if (!tm) return;

      workweekEarnings.push({
        _id: mp._id,
        team_member: mp.team_member,
        department: dept,
        date: selectedWorkweek?.end || periodEnd,
        miter_type: "hourly",
        hours: 0,
        amount: 0,
        check_type: "hourly",
        earning_label: "-",

        ...buildAgGridRow(tm.custom_field_values, teamMemberCustomFields),
      });
    });
    return reduceAggEarnings(workweekEarnings);
  }, [earnings, viewType, selectedWorkweek, lookupDept]);

  const columnDefs = useMemo(() => {
    let colDefs: ColDef[] = [];

    if (viewType === "summary") {
      colDefs = [
        {
          field: "team_member.full_name",
          headerName: "Team member",
          filter: true,
          initialRowGroup: true,
          initialHide: true,
          enableRowGroup: true,
        },
        {
          field: "team_member.first_name",
          headerName: "First name",
          filter: true,
          hide: true,
          enableRowGroup: true,
        },
        {
          field: "team_member.last_name",
          headerName: "Last name",
          filter: true,
          hide: true,
          enableRowGroup: true,
        },
        {
          field: "team_member.friendly_id",
          headerName: "Team member ID",
          filter: true,
          initialHide: true,
          enableRowGroup: true,
        },
        ...buildAgGridColumns(teamMemberCustomFields),
        {
          field: "crews",
          headerName: "Crews",
          enableRowGroup: true,
          initialHide: true,
          valueGetter: (params) => {
            const teamMemberId = params.data?.team_member?._id;
            const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
            return teamMemberCrews?.map((crew) => crew.name).join(", ");
          },
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              const crew = lookupCrew(params.value);
              return crew?.name;
            },
          },
          filterValueGetter: (params) => {
            const teamMemberId = params.data?._id;
            const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
            const crewIds = teamMemberCrews?.map((crew) => crew._id) || [];
            return crewIds;
          },
        },
        {
          valueGetter: jobNameValueGetter,
          headerName: "Job",
          filter: true,
          enableRowGroup: true,
          initialHide: true,
          initialRowGroup: true,
        },
        {
          valueGetter: activityNameValueGetter,
          headerName: "Activity",
          filter: true,
          enableRowGroup: true,
          initialHide: true,
          initialRowGroup: true,
        },
        {
          field: "earning_label",
          headerName: "Earning type",
          filter: true,
          enableRowGroup: true,
          initialHide: true,
        },
        {
          field: "reg_hours",
          headerName: "REG hours",
          aggFunc: "sumValues",
          filter: "agNumberColumnFilter",
        },
        {
          field: "ot_hours",
          headerName: "OT hours",
          aggFunc: "sumValues",
          filter: "agNumberColumnFilter",
        },
        {
          field: "dot_hours",
          headerName: "DOT hours",
          aggFunc: "sumValues",
          filter: "agNumberColumnFilter",
        },
        {
          field: "otherHours",
          headerName: "Time off hours",
          aggFunc: "sumValues",
          filter: "agNumberColumnFilter",
        },
        {
          field: "hours",
          headerName: "Total hours",
          aggFunc: "sumValues",
          filter: "agNumberColumnFilter",
        },
        {
          field: "amount",
          headerName: "Earnings",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          initialHide: true,
          filter: "agNumberColumnFilter",
        },
        {
          field: "department_name",
          headerName: "Department",
          enableRowGroup: true,
          initialHide: true,
          filter: true,
          sortable: true,
          valueGetter: (params) => {
            if (!params.data) return "";
            return params.data?.department_name || params.data?.department?.name || "";
          },
        },
      ];
    } else {
      const dayColumns: string[] = [];
      if (selectedWorkweek) {
        let day = selectedWorkweek.start;
        while (day <= selectedWorkweek.end) {
          dayColumns.push(day);
          day = DateTime.fromISO(day).plus({ days: 1 }).toISODate();
        }
      } else {
        let day = periodStart;
        while (day <= periodEnd) {
          dayColumns.push(day);
          day = DateTime.fromISO(day).plus({ days: 1 }).toISODate();
        }
      }
      const dayColDefs: ColDef[] = dayColumns.map((day) => {
        return {
          field: day,
          aggFunc: "sumValues",
          cellClass: "less-padding",
          headerClass: "less-padding",
          valueFormatter: hoursFormatter,
          headerName: DateTime.fromISO(day).toFormat("ccc"),
          minWidth: 70,
        };
      });

      colDefs = [
        {
          field: "tm_name",
          headerName: "Team member",
          filter: true,
          enableRowGroup: true,
          rowGroup: true,
          pinned: "left",
          hide: true,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            suppressCount: true,
            innerRenderer: (params) => {
              const tmId = params.node.allLeafChildren?.[0]?.data?.tm_id;
              return (
                <div className="flex" style={{ justifyContent: "space-between" }}>
                  <div style={{ width: 155 }}>{shorten(params.value, 19)}</div>
                  <div>
                    {params.node.field === "tm_name" && payroll && (
                      <button
                        className="button-1 no-margin"
                        onClick={() => {
                          setPaymentModal(tmId);
                        }}
                      >
                        <span>View</span>
                      </button>
                    )}
                  </div>
                </div>
              );
            },
          },
        },
        ...buildAgGridColumns(teamMemberCustomFields),
        {
          field: "hours_type",
          headerName: "Earning type",
          filter: true,
          enableRowGroup: true,
          rowGroup: true,
          hide: true,
        },
        {
          valueGetter: jobNameValueGetter,
          filter: true,
          headerName: "Job",
          enableRowGroup: true,
          rowGroup: true,
          initialHide: true,
        },
        {
          valueGetter: activityNameValueGetter,
          filter: true,
          headerName: "Activity",
          enableRowGroup: true,
          rowGroup: true,
          initialHide: true,
        },
        {
          field: "department_name",
          headerName: "Department",
          enableRowGroup: true,
          filter: true,
          sortable: true,
          initialHide: true,
          cellRenderer: (params) => {
            if (params.node.field !== "tm_name") return null;
            const deptName = params.node.allLeafChildren[0]?.data?.department_name;
            return deptName;
          },
        },
        ...dayColDefs,
        {
          field: "no_date",
          aggFunc: "sumValues",
          valueFormatter: hoursFormatter,
          headerName: "No date",
          pinned: "right" as const,
          maxWidth: 110,
        },
        {
          field: "total_hours",
          aggFunc: "sumValues",
          valueFormatter: hoursFormatter,
          headerName: "Week total",
          pinned: "right",
          maxWidth: 130,
          filter: "agNumberColumnFilter",
        },
        {
          field: "earnings",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          headerName: "Earnings",
          initialHide: true,
          pinned: "right",
          filter: "agNumberColumnFilter",
        },
        {
          field: "tm_pay_type",
          headerName: "Pay type",
          enableRowGroup: true,
          valueGetter: (params: ValueGetterParams<Pick<AggEarningEntry, "tm_pay_type">>) => {
            if (!params.data) return "";
            return params.data?.tm_pay_type === "salary" ? "Salary" : "Hourly";
          },
          hide: true,
        },
      ];
    }

    return colDefs.concat(jobHierarchyTableColumns);
  }, [
    viewType,
    selectedWorkweek,
    jobNameValueGetter,
    activityNameValueGetter,
    lookupCrew,
    lookupTeamMemberCrews,
    lookupDept,
    lookupTeamMember,
    teamMemberCustomFields,
    jobHierarchyTableColumns,
  ]);

  const renderTable = () => {
    const onGridReady = (params) => {
      if (!params.api) return;
      setGridApi?.(() => params.api);
    };

    const handleDownload = (detail?: boolean) => {
      if (detail) {
        gridApi?.exportDataAsCsv({ skipRowGroups: true, allColumns: true });
      } else {
        gridApi?.exportDataAsCsv();
      }
    };

    const toggleRowExpansion = () => {
      if (rowsAreExpanded) {
        gridApi?.collapseAll();
        setRowsAreExpanded(false);
      } else {
        gridApi?.expandAll();
        setRowsAreExpanded(true);
      }
    };

    const actionButtons = () => {
      return (
        <div className="flex">
          <Button
            text="Actions"
            className="button-1"
            dropdownItems={[
              { text: "Download all data", onClick: () => handleDownload(true) },
              { text: "Download summary", onClick: () => handleDownload(false) },
              {
                text: viewType === "summary" ? "Switch to day-by-day view" : "Switch to summary view",
                onClick: () => setViewType(viewType === "summary" ? "byDay" : "summary"),
              },
              {
                text: rowsAreExpanded ? "Collapse rows" : "Expand rows",
                onClick: toggleRowExpansion,
              },
              ...(vistaIntegrationConnectionId && earnings
                ? [
                    {
                      text: "Download Vista CSV",
                      onClick: () => downloadVistaCsv(earnings, periodEnd),
                    },
                  ]
                : []),
              ...(wbsIntegrationConnectionId && earnings && companyId && payScheduleId
                ? [
                    {
                      text: "Download WBS CSV",
                      onClick: () => downloadWbsCsv({ companyId, periodStart, periodEnd, payScheduleId }),
                    },
                  ]
                : []),
              ...(jonasIntegrationConnectionId && earnings
                ? [
                    {
                      text: "Download Jonas CSV",
                      onClick: () => downloadJonasTimesheetCsv(earnings, periodEnd),
                    },
                  ]
                : []),
              ...(shouldAllowSage300Export(sage300IntegrationConnectionId, earnings, activeCompanyId)
                ? [
                    {
                      text: "Download Sage300 CSV",
                      onClick: () => sage300CsvUtils.build({ earnings: earnings || [] }),
                    },
                  ]
                : []),
            ]}
          />
        </div>
      );
    };

    const renderWorkweekSelect = () => {
      if (!payroll || !workweekOptions || workweekOptions.length < 2) return <></>;

      const onChange = (o) => {
        if (!o) return;
        const start = o.value;
        const end = DateTime.fromISO(start).plus({ days: 6 }).toISODate();
        setSelectedWorkweek({ start, end });
      };
      return (
        <Select
          name="workweek_select"
          options={workweekOptions}
          width="250px"
          height="32px"
          onChange={onChange}
          styles={selectStyles}
          isClearable={true}
          menuPlacement={"bottom"}
          placeholder={"Workweek"}
          value={workweekOptions.find((o) => o.value === selectedWorkweek?.start)}
        />
      );
    };

    if (!earnings) return <Loader />;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <AgGridTable
            reportId="payroll-workweek"
            wrapperClassName="hours-by-pay-period"
            renderActionBarLeft={renderWorkweekSelect}
            actionButtonsLocation="left"
            data={data}
            onSearch={(e) => setSearchTerm(e)}
            searchValue={searchTerm}
            searchPlaceholder="Search entries"
            columnDefs={columnDefs}
            defaultActionButtons={actionButtons}
            supplementalTableActions={[
              { text: rowsAreExpanded ? "Collapse" : "Expand", onClick: toggleRowExpansion },
              { text: rowsAreExpanded ? "Collapse" : "Expand", onClick: toggleRowExpansion },
            ]}
            defaultColDef={{ enableRowGroup: true, enablePivot: true, resizable: true }}
            hideDownloadCSV={true}
            gridOptions={{
              suppressAggFuncInHeader: true,
              groupIncludeTotalFooter: true,
              onGridReady: onGridReady,
              quickFilterText: searchTerm,
              maintainColumnOrder: true,
              groupAggFiltering: true,
              autoGroupColumnDef: {
                minWidth: 260,
                pinned: "left",
                cellRendererParams: {
                  suppressCount: true,
                },
              },
              groupRowRendererParams: { suppressCount: true },
            }}
          />
          {shouldAllowSage300Export(sage300IntegrationConnectionId, earnings, activeCompanyId) && (
            <ExportWarningsModal
              downloadSage300ExportFile={downloadSage300ExportFile}
              warnings={warnings}
              setWarnings={setWarnings}
              data={timeEntriesData}
              loading={loading}
              fileName={timeEntriesFileName}
              headerText="Export Warning For Sage300 Time Entries"
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="height-100">
      <div className="vertical-spacer"></div>
      {paymentModal && (
        <PaymentModal tmId={paymentModal} editing={!!editing} hide={() => setPaymentModal(undefined)} />
      )}
      {renderTable()}
    </div>
  );
};

export const shouldAllowSage300Export = (
  sage300IntegrationConnectionId: string | undefined,
  earnings: AggregatedMiterEarning[] | undefined,
  activeCompanyId: string | null
): boolean => {
  return sage300IntegrationConnectionId !== undefined && earnings !== undefined && activeCompanyId !== null;
};
