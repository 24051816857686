import DataBox from "dashboard/components/dataBox/DataBox";
import AppContext from "dashboard/contexts/app-context";
import {
  AggregatedJob,
  IntegrationConnectionForQB,
  Job,
  MiterIntegrationForCompanyWithConnection,
} from "dashboard/miter";
import React, { useContext, useMemo, useState } from "react";
import checkmark from "dashboard/assets/check-mark.png";
import { useLookupDepartment } from "dashboard/hooks/atom-hooks";
import { JobQuickBooksMappingModal } from "./JobQuickBooksMappingModal";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";

export type JobIntegrationsProps = {
  job: AggregatedJob;
  updateJob: (update: Partial<Job> & Record<string, $TSFixMe>) => Promise<void>;
  updatingJob: boolean;
  errorUpdatingJob: boolean;
};

export const JobIntegrations: React.FC<JobIntegrationsProps> = ({
  job,
  updateJob,
  updatingJob,
  errorUpdatingJob,
}) => {
  const { integrations } = useContext(AppContext);
  const jobAbilities = useJobAbilities();

  const [showEditQuickbooksModal, setShowEditQuickbooksModal] = useState(false);

  const integrationsWithJobPullEnabled = integrations.filter((i) => {
    return i.connection && i?.supported_operations.jobs?.pull?.enabled;
  });

  const quickbooksIntegrations = integrationsWithJobPullEnabled.filter((integration) => {
    const key = integration.connection?.integration_key;
    return key === "qbd" || key === "qbo";
  }) as MiterIntegrationForCompanyWithConnection<IntegrationConnectionForQB>[];

  const anyIntegrationsQuickBooks = quickbooksIntegrations.length > 0;

  const connectedObjectsForJob = Object.keys(job.integrations || {});

  return (
    <>
      {integrationsWithJobPullEnabled.length ? (
        <DataBox
          title="Integrations"
          className="no-margin"
          editText={anyIntegrationsQuickBooks ? "Edit" : undefined}
          rows={undefined}
          onEdit={
            jobAbilities.can("update", job) && anyIntegrationsQuickBooks
              ? () => setShowEditQuickbooksModal(true)
              : undefined
          }
        >
          <>
            {integrationsWithJobPullEnabled.map((i) => {
              const connected = connectedObjectsForJob.includes(i.key);
              return (
                <div key={i.key} className={"data-box-section"}>
                  <span className={"data-box-section-title font-14"}>{`${i.label}`}</span>
                  <span className={"data-box-section-value font-14"}>
                    {connected ? <img src={checkmark} style={{ height: 15 }} /> : "-"}
                  </span>
                </div>
              );
            })}
            {anyIntegrationsQuickBooks && showEditQuickbooksModal && (
              <JobQuickBooksMappingModal
                hide={() => setShowEditQuickbooksModal(false)}
                job={job}
                updateJob={updateJob}
                updatingJob={updatingJob}
                connectedQBIntegrations={quickbooksIntegrations}
                errorUpdatingJob={errorUpdatingJob}
              />
            )}
          </>
        </DataBox>
      ) : (
        <></>
      )}
      <IntacctJobFields job={job} />
    </>
  );
};

const IntacctJobFields: React.FC<{ job: AggregatedJob }> = (props) => {
  const { job } = props;
  const { integrations } = useContext(AppContext);

  const lookupDept = useLookupDepartment();

  const intacctProject = job.integrations?.sage_intacct?.intacctProject;
  const intacctIc = integrations.find((i) => i.key === "sage_intacct")?.connection;

  const { classLabel } = useMemo(() => {
    if (!intacctIc || !intacctProject) return {};
    const classLabel = intacctProject.CLASSNAME;
    return { classLabel };
  }, [job, lookupDept, intacctIc, intacctProject]);

  return (
    <>
      {intacctProject ? (
        <DataBox title="Intacct fields" className="no-margin" editText={undefined} rows={undefined}>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>{`Class`}</span>
            <span className={"data-box-section-value font-14"}>{classLabel || "-"}</span>
          </div>
        </DataBox>
      ) : (
        <></>
      )}
    </>
  );
};
