import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import PaymentContext from "./paymentContext";
import { Formblock, ModalHeader, Button, Loader, usdString } from "ui";
import * as vals from "dashboard/utils/validators";
import Notifier from "dashboard/utils/notifier";
import { ClickAwayListener } from "@material-ui/core";
import { Option } from "ui/form/Input";
import { earningsThatRequireHours, getWorkHoursInYear, imputedEarningTypes } from "dashboard/utils/utils";
import { ManualEarning, PayRateItem } from "backend/utils/payroll/types";
import { DateTime } from "luxon";
import { checkTmAndActivityLicenseMatch, roundTo } from "dashboard/utils";
import { PureFrontendError, cleanFloatingPointErrors } from "miter-utils";
import InfoButton from "dashboard/components/information/information";
import { Company, MiterAPI } from "dashboard/miter";
import { PayRateModal } from "dashboard/components/payRates/PayRateOverview";
import ObjectID from "bson-objectid";
import PayrollContext from "../payrollContext";
import { GroupedEarningForCheck, PayrollAdjustment } from "../../payrollTypes";
import { useDebouncedCallback } from "use-debounce";

import { CheckPayroll } from "backend/utils/check/check-types";
import { useGetEarningTypeOptions } from "../viewPayrollUtils";
import {
  useLookupActivity,
  useActivityOptionsMap,
  useJobOptions,
  useLookupJob,
  useLookupPrg,
  useLookupTeam,
  useLookupRateClassification,
  useLedgerAccountOptions,
  useClassificationOptions,
  useActiveCompanyId,
} from "dashboard/hooks/atom-hooks";
import { getTooltipForPayRate } from "dashboard/utils/timesheetUtils";
import { JobInput } from "dashboard/components/shared/JobInput";

type Props = {
  earningToUpdate?: GroupedEarningForCheck;
  hide: () => void;
  deleteEarning?: () => Promise<void>;
};

const hoursValidator = vals.numberValidator({
  excludeNegatives: false,
  excludeZero: true,
  maxDecimals: 2,
});

const nonHourlyAmountValidator = vals.numberValidator({
  excludeNegatives: false,
  excludeZero: false,
  maxDecimals: 2,
});

const EarningModal: React.FC<Props> = ({ hide, earningToUpdate }) => {
  const activeCompanyId = useActiveCompanyId();
  const ledgerAccountOptions = useLedgerAccountOptions();

  // Hooks
  const { payment, tm, payroll } = React.useContext(PaymentContext);
  const { recalculatePayroll } = React.useContext(PayrollContext);
  const form = useForm({ shouldUnregister: false });
  const { register, control, watch, errors, handleSubmit, setValue } = form;
  const lookupPrg = useLookupPrg();
  const lookupJob = useLookupJob();
  const lookupTeam = useLookupTeam();
  const lookupActivity = useLookupActivity();
  const lookupClassification = useLookupRateClassification();

  const jobOptions = useJobOptions({ defaultValue: earningToUpdate?.job });
  const activityOptionsMap = useActivityOptionsMap({
    defaultValue: earningToUpdate?.activity,
  });

  // State

  const [earningsAmount, setEarningsAmount] = useState<number | null>(null);
  const [gettingPayRate, setGettingPayRate] = useState(false);

  const teamMember = lookupTeam(tm._id);
  const timeOffPolicies = teamMember?.time_off_policies;

  const [selectedJobOption, setSelectedJobOption] = useState<null | Option<string>>(() => {
    return jobOptions.find((o) => o.value === earningToUpdate?.job) || null;
  });

  const [activityOptions, setActivityOptions] = useState<Option<string>[]>(() =>
    activityOptionsMap.get(earningToUpdate?.job)
  );
  const [selectedActivityOption, setSelectedActivityOption] = useState<null | Option<string>>(() => {
    return activityOptions.find((o) => o.value === earningToUpdate?.activity) || null;
  });

  const classificationOptions = useClassificationOptions({});

  const [selectedClassificationOption, setSelectedClassificationOption] = useState<null | Option<string>>(
    () => {
      return classificationOptions.find((o) => o.value === earningToUpdate?.classification_override) || null;
    }
  );

  const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(
    earningToUpdate?.date ? DateTime.fromISO(earningToUpdate.date) : undefined
  );
  const [loading, setLoading] = useState(false);
  const [payRateItem, setPayRateItem] = useState<PayRateItem>();
  const [isViewingPayRate, setIsViewingPayRate] = useState(false);
  const [isStandardRate, setIsStandardRate] = useState(() => {
    const earningId = earningToUpdate?._id;
    if (!earningId) return true;

    const me = payment.miter_earnings.find((e) => e._id === earningId);
    if (!me) return true;

    if (me.pay_rate_item?.method === "custom") return false;

    return true;
  });

  const checkPayroll: CheckPayroll | undefined = payroll?.check_payroll;
  const benefitsDontApply =
    checkPayroll?.type === "off_cycle"
      ? !checkPayroll.off_cycle_options?.apply_benefits
      : checkPayroll?.type !== "regular";

  const [ignoreBenefitContributions, setIgnoreBenefitContributions] = useState(
    earningToUpdate?.ignore_benefit_contributions
  );

  const getEarningTypeOptions = useGetEarningTypeOptions();
  const earningTypeOptions = useMemo(() => {
    return getEarningTypeOptions(teamMember);
  }, [teamMember, getEarningTypeOptions]);

  const earningType = watch("type", { value: earningToUpdate?.check_type });
  const hours = Number(watch("hours", earningToUpdate?.hours)) || undefined;
  const manualAmount = Number(watch("amount", earningToUpdate?.amount));
  const manualEarning = useMemo(
    () => payment.adjustment?.manual_earnings?.find((me) => me.manual_id === earningToUpdate?._id),
    [payment.adjustment?.manual_earnings, earningToUpdate?._id]
  );

  const earningTypeNeedsHours = earningsThatRequireHours.includes(earningType?.value);
  const earningTypeIsImputed = imputedEarningTypes.includes(earningType?.value);

  // Basic function to auto-calculate earning amount from hours.
  const calculateEarningsAmountFromHours = (
    company: Company | undefined,
    hours: number,
    earning_type: string | undefined
  ): number => {
    const { pay_rate, pay_type } = tm;
    if (!hours || !pay_type || pay_rate == null || !earning_type || !company) return 0;
    const workHoursInYear = getWorkHoursInYear(company);
    if (payRateItem) {
      if (earning_type === "overtime") {
        return hours * payRateItem.rates.ot;
      } else if (earning_type === "double_overtime") {
        return hours * payRateItem.rates.dot;
      } else {
        return hours * payRateItem.rates.reg;
      }
    } else {
      if (pay_type === "hourly" || pay_type === "salary") {
        let hourlyPayRate = pay_rate;
        if (pay_type === "salary") {
          hourlyPayRate = pay_rate / workHoursInYear;
        }
        if (earning_type === "overtime") {
          return cleanFloatingPointErrors(hourlyPayRate * hours * 1.5);
        } else if (earning_type === "double_overtime") {
          return cleanFloatingPointErrors(hourlyPayRate * hours * 2);
        } else {
          return hourlyPayRate * hours;
        }
      }
      return 0;
    }
  };

  const hourlyCustomAmountValidator = vals.numberValidator({
    excludeNegatives: false,
    excludeZero: false,
    maxDecimals: 2,
    otherValidators: {
      checkHoursSign: (amt) => {
        const amtSign = Number(amt) < 0;
        const hourSign = Number(watch("hours")) < 0;
        if (amtSign !== hourSign) return "Implied custom rate cannot be negative";
      },
    },
  });

  const save = async (data) => {
    if (gettingPayRate) return;
    setLoading(true);
    let amount = Number(data.amount);
    const earningType = data.type.value;
    const hourlyType = earningsThatRequireHours.includes(earningType);
    if (isStandardRate && hourlyType) {
      amount = Number(earningsAmount);
    }
    try {
      const newEarning: ManualEarning = {
        manual_id: ObjectID().toString(),
        hours: (hourlyType && roundTo(Number(data.hours))) || undefined,
        amount: roundTo(amount),
        description: data.description?.trim() || undefined, // Description cannot be pure whitespace
        type: earningType,
        job: data.job?.value || undefined,
        activity: data.activity?.value || undefined,
        classification_override: (hourlyType && data.classification_override?.value) || undefined,
        pay_rate_item: hourlyType ? payRateItem : undefined,
        date: selectedDate ? selectedDate.toISODate() : undefined,
        ignore_benefit_contributions: ignoreBenefitContributions,
        time_off_policy_id: data.time_off_policy?.value || undefined,
        ledger_account_id: data.ledger_account?.value,
      };

      // Find team member's existing adjustment
      const existing_tm_adjustment = payment.adjustment;

      // Initialize a new array containing all manually added earnings
      const newManuallyAddedEarnings: ManualEarning[] = [newEarning];

      // Ensure that the user has enough balance to add this earning if its a time off earning
      if (earningType === "sick" || earningType === "pto") {
        const timeOffPolicy = timeOffPolicies?.find((p) => p._id === data.time_off_policy?.value);
        if (!timeOffPolicy) throw new Error("Could not find time off policy");

        const tmPolicy = tm.time_off.policies.find((p) => p.policy_id === timeOffPolicy._id);
        const levelConfig = timeOffPolicy.levels.find((level) => level._id === tmPolicy?.level_id);

        if (!levelConfig) {
          throw new PureFrontendError(
            "This team member has not been assigned a level in the time off policy"
          );
        }

        if (tmPolicy && levelConfig?.disable_negative_balances) {
          const balance = tmPolicy.balance;
          if (balance != null && newEarning.hours && balance < newEarning.hours) {
            throw new PureFrontendError(
              "This team member's time off balance is too low to add this earning."
            );
          }
        }
      }

      // Add other manually adding earnings to the array, except the one being updated
      for (const a of existing_tm_adjustment?.manual_earnings || []) {
        if (a.manual_id !== earningToUpdate?._id) {
          newManuallyAddedEarnings.push(a);
        }
      }

      // Get the list of all payroll adjustments except for the active TM's
      const newPayrollAdjustments: PayrollAdjustment[] =
        payroll!.adjustments?.filter((a) => a.team_member !== tm._id.toString()) || [];

      // If tm has an existing adjustment, overwrite existing manually added earnings and add it to the array
      newPayrollAdjustments.push({
        ...existing_tm_adjustment,
        team_member: tm._id,
        manual_earnings: newManuallyAddedEarnings,
      });
      await recalculatePayroll({ adjustments: newPayrollAdjustments, tms: [tm._id.toString()] });
      Notifier.success(`Earning ${earningToUpdate ? "updated" : "added"} successfully.`);

      hide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      if (!(e instanceof PureFrontendError)) {
        console.error("Error with earning modal", e);
      }
    }
    setLoading(false);
  };

  const getAutocalculateTooltip = () => {
    if (!payRateItem || !teamMember) return null;
    const job = lookupJob(selectedJobOption?.value);
    const activity = lookupActivity(selectedActivityOption?.value);
    return getTooltipForPayRate({
      pri: payRateItem,
      teamMember,
      lookupPrg,
      lookupClassification,
      job,
      activity,
      isEarning: true,
    });
  };

  const handleJobChange = async (jobOption: Option<string> | null) => {
    setSelectedJobOption(jobOption);
    const newActivityOptions = activityOptionsMap.get(jobOption?.value);
    setActivityOptions(newActivityOptions);
    if (newActivityOptions.every((o) => o.value !== selectedActivityOption?.value)) {
      setSelectedActivityOption(null);
    }
  };

  const handleActivityChange = async (activityOption: Option<string> | null) => {
    setSelectedActivityOption(activityOption);
    const activity = lookupActivity(activityOption?.value);
    checkTmAndActivityLicenseMatch(activity, tm);
  };

  const getPayRateItem = async () => {
    if (!activeCompanyId) return;
    // If the earning to update has an activity or a job with a pay rate group, get the PayRateItem
    try {
      if (!earningType?.value || !earningTypeNeedsHours || !hours) {
        setPayRateItem(undefined);
        setGettingPayRate(false);
        return;
      }

      let customRate: number | undefined;
      if (!isStandardRate) {
        customRate = manualAmount / hours;
        if (isNaN(customRate)) customRate = undefined;
        if (customRate != null) {
          if (earningType.value === "overtime") {
            customRate /= 1.5;
          } else if (earningType.value === "double_overtime") {
            customRate /= 2;
          }

          // Don't let the custom rate be negative!
          if (customRate < 0) return;
        }
      }

      // Use the true pay frequency only if it's a regular payroll since payFrequency is used for calculating fringe offset, and the user can control the "pay frequency" setting of an off-cycle payroll
      const payFrequency = checkPayroll?.type === "regular" ? checkPayroll.pay_frequency : undefined;

      setGettingPayRate(true);
      const pri = await MiterAPI.team_member.get_pay_rate(tm._id, {
        payday: checkPayroll?.payday || DateTime.now().toISODate(),
        companyId: activeCompanyId,
        activityId: selectedActivityOption?.value || undefined,
        jobId: selectedJobOption?.value || undefined,
        customPayRate: customRate,
        ignoreBenefits: ignoreBenefitContributions || benefitsDontApply,
        payFrequency,
        classificationOverride: selectedClassificationOption?.value || undefined,
        earningType: earningType.value,
      });
      if (pri.error) throw new Error(pri.error);

      setPayRateItem(pri);
    } catch (e) {
      console.error("There was an error getting the pay rate item.", e);
      Notifier.error(
        "There was a problem calculating the correct pay rate for this earning. We're looking into it. In the meantime, double check this earning's calculated pay rate."
      );
    }
    setGettingPayRate(false);
  };

  const getPayRateItemDebounced = useDebouncedCallback(() => {
    getPayRateItem();
  }, 500);

  useEffect(() => {
    getPayRateItemDebounced();
  }, [
    isStandardRate,
    hours,
    manualAmount,
    ignoreBenefitContributions,
    earningType?.value,
    selectedActivityOption,
    selectedJobOption,
    selectedClassificationOption,
    payroll,
  ]);

  useEffect(() => {
    const amount = hours
      ? calculateEarningsAmountFromHours(payroll?.company, roundTo(hours), earningType?.value)
      : 0;
    setEarningsAmount(roundTo(amount));
  }, [hours, earningType?.value, payRateItem]);

  // Reset time off policy if earning type changes
  useEffect(() => {
    setValue("time_off_policy", null);
  }, [earningType?.value]);

  // Set the default time off policy based on the earning
  useEffect(() => {
    const timeOffPolicy = timeOffPolicies?.find((policy) => policy._id === manualEarning?.time_off_policy_id);
    if (!timeOffPolicy) return;

    setValue("time_off_policy", { value: timeOffPolicy?._id, label: timeOffPolicy?.name });
  }, [manualEarning?.time_off_policy_id, timeOffPolicies]);

  const renderMoreOptions = () => {
    let ignoreBenefitContributionsText = "This earning should not accrue benefit contributions";
    if (payRateItem?.pw_reference) {
      ignoreBenefitContributionsText += " or use benefits to offset the fringe rate";
    }
    return (
      <>
        <JobInput
          label="Job"
          type="select"
          form={form}
          control={control}
          errors={errors}
          onChange={handleJobChange}
          className="modal"
          options={jobOptions}
          name="job"
          defaultValue={earningToUpdate?.job}
          editing
          isClearable
        />
        <Formblock
          label="Activity"
          type="select"
          control={control}
          errors={errors}
          onChange={handleActivityChange}
          value={selectedActivityOption}
          className="modal"
          defaultValue={earningToUpdate?.activity}
          options={activityOptions}
          name="activity"
          editing
          isClearable
        />
        {earningTypeNeedsHours && (
          <Formblock
            label="Classification override"
            labelInfo="This classification will override"
            type="select"
            control={control}
            errors={errors}
            onChange={setSelectedClassificationOption}
            className="modal"
            options={classificationOptions}
            name="classification_override"
            defaultValue={earningToUpdate?.classification_override}
            editing
            isClearable
          />
        )}
        <Formblock
          label="GL account override"
          type="select"
          control={control}
          errors={errors}
          className="modal"
          options={ledgerAccountOptions}
          name="ledger_account"
          defaultValue={earningToUpdate?.ledger_account_id}
          editing
          isClearable
        />
        {!benefitsDontApply && !earningTypeIsImputed && (
          <Formblock
            text={ignoreBenefitContributionsText}
            type="checkbox"
            errors={errors}
            onChange={(e) => setIgnoreBenefitContributions(e.target.checked)}
            className="modal"
            checked={!!ignoreBenefitContributions}
            name="ignore_benefit_contributions"
            editing
          />
        )}
      </>
    );
  };

  const renderHoursandEarningsInputForHourlyEarningTypes = () => {
    const timeOffPolicyOptions = (timeOffPolicies || [])
      .filter((p) => {
        return (
          ((p.type === "vacation" && earningType?.value === "pto") ||
            (p.type === "sick" && earningType?.value === "sick")) &&
          tm.time_off.policies.some((tp) => tp.policy_id === p._id && !!tp.level_id)
        );
      })
      .map((p) => ({ value: p._id, label: p.name }));

    return (
      <>
        <Formblock
          label="Hours*"
          type="text"
          defaultValue={earningToUpdate?.hours?.toString()}
          register={register(hoursValidator)}
          errors={errors}
          className="modal"
          name="hours"
          editing
        />

        {isStandardRate ? (
          <>
            <div className="info-line modal">
              <span>Earning amount:</span>&nbsp;
              <span className="info-line modal strong">
                {gettingPayRate ? <Loader className="small-text" /> : usdString(earningsAmount)}
              </span>
              <InfoButton text={getAutocalculateTooltip()} />
              {}
            </div>
            <div className="vertical-spacer-small" />
          </>
        ) : (
          <Formblock
            label="Amount*"
            type="unit"
            unit="$"
            register={register(hourlyCustomAmountValidator)}
            errors={errors}
            defaultValue={earningToUpdate?.amount}
            className="modal"
            name="amount"
            editing
          />
        )}
        <Formblock
          type="checkbox"
          name="use_default_pay_rate"
          text={"Use the default pay rate to calculate earnings"}
          className="modal"
          register={register}
          control={control}
          editing={true}
          errors={errors}
          defaultValue={isStandardRate}
          onChange={(e) => setIsStandardRate(e.target.checked)}
        />
        {payRateItem?.pw_reference && isStandardRate && (
          <>
            <div className="yellow-text-container">
              {`Miter is auto-calculating earnings for `}
              <span className="blue-link" onClick={() => setIsViewingPayRate(true)}>
                {payRateItem.classification?.label}
              </span>
              .
            </div>
          </>
        )}
        {(earningType?.value === "pto" || earningType?.value === "sick") && (
          <Formblock
            label="Time Off Policy*"
            labelInfo={"Select the time off policy that you want to use for this earning."}
            type="select"
            control={control}
            name="time_off_policy"
            options={timeOffPolicyOptions}
            register={register(vals.required)}
            className="modal "
            defaultValue={manualEarning?.time_off_policy_id}
            errors={errors}
            editing={true}
            requiredSelect={true}
            isClearable={true}
          />
        )}

        <div className="vertical-spacer-small" />
      </>
    );
  };

  const renderLeftColumn = () => {
    return (
      <div className="modal-form-column">
        <>
          <Formblock
            label="Earning type*"
            type="select"
            control={control}
            defaultValue={earningToUpdate?.check_type}
            errors={errors}
            className="modal"
            name="type"
            editing
            requiredSelect
            options={earningTypeOptions}
            maxMenuHeight={130}
          />
          <Formblock
            label="Description"
            type="text"
            register={register}
            errors={errors}
            className="modal"
            defaultValue={earningToUpdate?.description}
            name="description"
            editing
          />
          <Formblock
            label="Date"
            type="datetime"
            control={control}
            dateOnly={true}
            errors={errors}
            onChange={setSelectedDate}
            className="modal"
            defaultValue={selectedDate}
            min={checkPayroll ? DateTime.fromISO(checkPayroll.period_start) : undefined}
            max={checkPayroll ? DateTime.fromISO(checkPayroll.period_end) : undefined}
            name="date"
            topOfInput={true}
            editing
          />
          {earningType?.value && (
            <>
              {earningTypeNeedsHours ? (
                <>{renderHoursandEarningsInputForHourlyEarningTypes()}</>
              ) : (
                <Formblock
                  label="Amount*"
                  type="unit"
                  unit="$"
                  register={register(nonHourlyAmountValidator)}
                  errors={errors}
                  defaultValue={earningToUpdate?.amount}
                  className="modal"
                  name="amount"
                  editing
                />
              )}
            </>
          )}
        </>
      </div>
    );
  };

  const renderRightColumn = () => {
    return <div className="modal-form-column">{earningType?.value && <>{renderMoreOptions()}</>}</div>;
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form two-column">
          {isViewingPayRate && payRateItem && (
            <PayRateModal payRateItem={payRateItem} hide={() => setIsViewingPayRate(false)} />
          )}
          <ModalHeader heading={earningToUpdate ? "Update earning" : "Add earning"} onHide={hide} />
          <div className="modal-body form two-column" style={{ minHeight: 100 }}>
            {renderLeftColumn()}
            {renderRightColumn()}
          </div>
          <div className="modal-footer form">
            <button className="button-1" onClick={hide}>
              Cancel
            </button>
            <Button className="button-2" loading={loading} text="Save" onClick={handleSubmit(save)} />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default EarningModal;
