import { saveAs } from "file-saver";
import { FilePickerFile } from "ui/form/FilePicker";
import {
  AggregatedFile,
  CreateFilesParams,
  ESignatureItem,
  FileEvent,
  MiterAPI,
  CreateFileRes,
  S3UrlCreationResponse,
  File,
} from "dashboard/miter";
import { createObjectMap, extensionToMimeType, Notifier } from "dashboard/utils";
import { notNullish } from "./misc";

export type FileUploadParams = {
  parent_type: File["parent_type"];
  parent_id: string;
  company_id: string;
};

export type FileToCreateWithoutBlob = Omit<File, "_id" | "created_at" | "updated_at">;
export type FileToUpload = FileToCreateWithoutBlob & { fileBlob: $TSFixMe };

export const formatFilesForUpload = (
  files: FilePickerFile[],
  company_id: string,
  params?: {
    parent_type?: File["parent_type"];
    parent_id?: string;
    label?: string | null;
    sensitive?: boolean;
    expires_at?: string;
  }
): CreateFilesParams["files"] => {
  const fileMap = new Map<string, number>();

  return files
    .filter((file) => !!file.blob)
    .map((file) => {
      const count = fileMap.get(file.blob!.name) || 0;
      let fileName = file.blob!.name;

      // Increment the file name if it already exists
      if (count > 0) {
        const name = fileName.split(".")[0];
        const ending = fileName.split(".").slice(1).join(".");
        fileName = `${name} (${count}).${ending}`;
      }

      // Update the map
      fileMap.set(file.blob!.name, count + 1);

      return {
        originalname: fileName,
        type: file.blob!.type,
        fileBlob: file.blob, // keep this for AWS direct upload from the client
        label: params?.label || fileName,
        size: file.blob!.size,
        company_id,
        tag_ids: [],
        archived: false,
        ...params,
      };
    });
};

// Can only be used by the frontend because this file refers to dashboard/miter
// Don't know how to resolve it, but for now making a copy of this function in backend
export const uploadFilesToS3 = async (
  miterFileObjects: CreateFileRes[],
  localFileObjects: FileToUpload[]
): Promise<void> => {
  for (const fileRes of miterFileObjects) {
    const fileData = localFileObjects.find((f) => f.originalname === fileRes.file.originalname);

    if (!fileData) {
      throw new Error("We are unable to upload one or more of your files");
    }

    try {
      const awsRes = await fetch(fileRes.url, {
        method: "PUT",
        headers: { "Content-Type": fileData.fileBlob.type },
        body: fileData.fileBlob,
      });

      if (!awsRes.ok) throw new Error("There was an error uploading the file to AWS");
    } catch (e) {
      // Delete the file from Miter if it fails to upload to S3
      await deleteFiles([fileRes.file._id.toString()]);
      throw e;
    }
  }
};

export const uploadToS3WithSignedUrl = async (
  localFilesWithSignedS3Urls: S3UrlCreationResponse[],
  localFileObjects: FileToUpload[]
): Promise<void> => {
  // create map of file name -> file
  const localFilesByOriginalname = createObjectMap(localFileObjects, (f) => f.originalname);

  for (const localFileWithUrl of localFilesWithSignedS3Urls) {
    const fileData = localFilesByOriginalname[localFileWithUrl.file_name || ""];

    if (!fileData) {
      throw new Error("We are unable to upload one or more of your files");
    }

    const awsRes = await fetch(localFileWithUrl.signed_url, {
      method: "PUT",
      headers: { "Content-Type": fileData.fileBlob.type },
      body: fileData.fileBlob,
    });

    if (!awsRes.ok) throw new Error("There was an error uploading the file to AWS");
  }
};

export const uploadFiles = async (
  files: FilePickerFile[] | null,
  params: FileUploadParams
): Promise<string[]> => {
  if (!files || !files[0]) return [];

  try {
    const formattedFiles = formatFilesForUpload(files, params.company_id, params);
    const res = await MiterAPI.files.upload({ files: formattedFiles });
    if ("error" in res) {
      throw new Error(res.error);
    }

    return res.map((file) => file.file._id).filter(notNullish);
  } catch (err: $TSFixMe) {
    const message = err.message || "Error uploading files";
    Notifier.error(message);
  }
  return [];
};

export const deleteFiles = async (ids: string[], options?: { showToast?: boolean }): Promise<string[]> => {
  if (ids.length) {
    try {
      const response = await MiterAPI.files.delete(ids);
      if (response.error) throw new Error(response.error);

      if (options?.showToast) Notifier.success("File(s) deleted successfully");
      return response.success.map((file) => file._id.toString());
    } catch (err: $TSFixMe) {
      const message: string = err.message || "There was an error in deleting the files.";
      Notifier.error(message);
    }
  }
  return [];
};

export type FileUploadResponse = { deleted_ids: string[]; created_ids: string[] };

// The updateFiles function will delete removed files and add new files
export const updateFiles = async (
  files: FilePickerFile[] | null,
  params: FileUploadParams
): Promise<FileUploadResponse> => {
  // Figure out which files were deleted
  const idsToDelete: string[] = [];
  files?.map((file: FilePickerFile) => {
    if (file.deleted && file.data?._id) idsToDelete.push(file.data._id);
  });
  let deleted_ids: string[] = [];
  let created_ids: string[] = [];

  // Delete those files
  if (idsToDelete.length) {
    deleted_ids = await deleteFiles(idsToDelete);
  }

  // Add the new files, this can be done by filtering out for new files, and running the uploadFiles function
  const newFiles = files?.filter((file: FilePickerFile) => Boolean(!file.data));

  if (Array.isArray(newFiles) && newFiles[0]) {
    created_ids = await uploadFiles(newFiles, params);
  }

  return { deleted_ids, created_ids };
};

export const downloadFiles = async (
  ids: string[],
  setDownloading: (boolean) => void,
  downloadName?: string
): Promise<void> => {
  if (!ids.length) return;
  setDownloading(true);
  try {
    const files = ids;
    const responseData = await MiterAPI.files.download(files);
    if (responseData.error) throw responseData.error;

    // Get extension from filename allow for multiple dots in filename
    const ext = responseData.originalname.split(".").slice(-1)[0];
    // Download file
    saveAs(
      // @ts-expect-error weird response data error
      new Blob([Uint8Array.from(responseData.data.data).buffer], {
        type: extensionToMimeType(responseData.originalname) || undefined,
      }),
      downloadName || (responseData.label ? responseData.label + "." + ext : responseData.originalname)
    );

    if (files.length > 1) {
      Notifier.success(`The files downloaded successfully`);
    } else {
      Notifier.success(`The file downloaded successfully`);
    }
  } catch (e) {
    console.error(e);

    Notifier.error(
      `There was an error downloading the file${ids.length > 1 ? "s" : ""}. We're looking into it.`
    );
  }
  setDownloading(false);
};

export const viewFile = async (id: string): Promise<void> => {
  try {
    const responseData = await MiterAPI.files.retrieve(id);
    if (responseData.error) throw responseData.error;

    window.open(responseData.url, "_blank");
  } catch (e) {
    console.error(e);
    Notifier.error("There was an getting the link to the signed document. Please contact support.");
  }
};

export const getIdsOfFilesToDelete = (files: FilePickerFile[]): string[] => {
  // okay to use ! here because we explicitly filter for files that have their mongo id
  return files.filter((item) => item?.deleted && item?.data?._id).map((item) => item?.data!._id);
};

export const openBlobThisTab = (blob: Blob): void => {
  const url = URL.createObjectURL(blob);
  window.location.replace(url);
  // DO NOT REVOKE THE URL OR ELSE THIS BREAKS FOR SAFARI
};

export const openBlob = (blob: Blob): void => {
  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
  // DO NOT REVOKE THE URL OR ELSE THIS BREAKS FOR SAFARI
};

export const getTeamMemberESignatureItem = (
  file: AggregatedFile,
  teamMemberId: string
): ESignatureItem | undefined => {
  if ("esignature_items" in file) {
    return file.esignature_items.find((ei) => ei.signer.team_member_id === teamMemberId);
  }
};

export const getTeamMemberESignatureRequest = (
  file: AggregatedFile,
  teamMemberId: string
): ESignatureItem | undefined => {
  if ("esignature_items" in file) {
    const item = getTeamMemberESignatureItem(file, teamMemberId);
    if (item?.status !== "signed") return item;
  }
};

export const awaitingSignature = (file: AggregatedFile, teamMemberId: string): boolean => {
  return Boolean(getTeamMemberESignatureRequest(file, teamMemberId));
};

/** Marks the file as viewed via the API */
export const markFilesAsViewed = async (fileIds: string[]): Promise<FileEvent[]> => {
  try {
    const res = await MiterAPI.files.viewed(fileIds);
    if (res.error) throw new Error(res.error);

    return res;
  } catch (e) {
    console.log("Error marking file as viewed", e);
    return [];
  }
};
