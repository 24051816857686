import { RouteConfig } from "dashboard/routes";
import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "ui";
import AppContext from "../../contexts/app-context";
import "./sidebar.css";
import { Circle } from "phosphor-react";
import { useCanClockInFromDashboard } from "dashboard/utils/timesheetUtils";
import ClockInButton from "./ClockInButton";
import {
  useActiveTeamChatConversation,
  useIsTeamChatInitialized,
  useTeamChatConversations,
} from "dashboard/hooks/atom-hooks";

type SubMenuItem = {
  label: string;
  link: string;
  isActive?: (path: string) => boolean;
};

type SidebarProps = {
  menuItems: RouteConfig[];
  shiftPage: boolean;
  setShiftPage: (shiftPage: boolean) => void;
};

type SidebarLinkProps = {
  label: string;
  link: string;
  className?: string;
  icon?: React.ReactElement;
  badge?: ReactElement;
  nested?: boolean;
  accessible?: boolean;
  isActive?: (path: string) => boolean;
  setOpenDropdowns: (openDropdowns: string[]) => void;
  showWarningCircle?: boolean;
  onClick?: () => void;
};

const SidebarLink: React.FC<SidebarLinkProps> = ({
  label,
  link,
  icon,
  badge,
  nested,
  className,
  accessible,
  isActive: customIsActive,
  setOpenDropdowns,
  showWarningCircle,
  onClick,
}) => {
  const location = useLocation();
  const linkClass = "menu-item-wrapper " + (className || "") + (nested ? " nested" : "");

  if (!accessible) return <></>;

  const linkContent = (
    <>
      <div className={"menu-item"}>
        {icon}
        <span className={"menu-item-label"}>
          {label}
          {showWarningCircle && (
            <Circle weight="fill" color="#cc2553" size={10} style={{ marginRight: -5, marginLeft: 5 }} />
          )}
        </span>
      </div>
      {badge}
    </>
  );

  if (onClick) {
    return (
      <div className={linkClass} onClick={onClick}>
        {linkContent}
      </div>
    );
  }

  if (link.includes("https://")) {
    return (
      <a href={link} className={linkClass} target="_blank" rel="noreferrer">
        {linkContent}
      </a>
    );
  }

  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        linkClass + (isActive || customIsActive?.(location.pathname) ? " active-link" : "")
      }
      key={link}
      onClick={() => {
        if (!nested) setOpenDropdowns([]);
      }}
    >
      {linkContent}
    </NavLink>
  );
};

type SidebarItemProps = RouteConfig & {
  label: string;
  icon: React.ReactElement;
  className?: string;
  subItems?: SubMenuItem[];
  accessible?: boolean;
  badge?: ReactElement;
  margin?: boolean;
  activeLink?: string;
  collapse?: number;
  hide?: (any) => boolean;
  openDropdowns: string[];
  setOpenDropdowns: (openDropdowns: string[]) => void;
  isActive?: (path: string) => boolean;
  notificationCount?: number;
  onClick?: () => void;
};

const SidebarItem: FC<SidebarItemProps> = ({
  className,
  label,
  link,
  icon,
  subItems,
  accessible,
  badge,
  margin,
  openDropdowns,
  setOpenDropdowns,
  isActive,
  notificationCount,
  onClick,
}) => {
  const { shiftPage, setShiftPage } = useContext(AppContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const isDropdown = subItems && subItems.length > 0;

  useEffect(() => {
    if (open) {
      setShiftPage(true);
      setOpenDropdowns([label]);
    }
  }, [open]);

  useEffect(() => {
    if (!openDropdowns.includes(label)) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [openDropdowns]);

  // Check if accessible is true or if a subitem is accessible
  const isAccessible = useMemo(() => {
    const hasAccessibleSubItem = subItems?.some((item) => item.accessible);
    return accessible || hasAccessibleSubItem;
  }, [accessible, subItems]);

  if (!isAccessible) return <></>;

  const strippedPathname = "/" + location.pathname.split("/")[1]?.split("?")[0];

  if (isDropdown) {
    const active =
      open ||
      isActive?.(strippedPathname) ||
      subItems?.some((item) => {
        // Remove trailing slash from path, get the last pathname, and remove query params

        if (item.link && strippedPathname.includes(item.link)) return true;
        if (item.isActive?.(strippedPathname)) return true;
        return false;
      });
    const containerClass =
      "menu-item-container " + (active ? "active-menu-item" : "") + (margin ? " menu-item-margin" : "");

    return (
      <div className={containerClass} onClick={() => setOpen(!open)}>
        <div className={"menu-item-dropdown-container"}>
          <div className={"menu-item menu-item-dropdown"}>
            {icon}
            <span className={"menu-item-label space-between"}>
              {label}{" "}
              {notificationCount ? (
                <Badge text={notificationCount + " "} className={"unread-count"} />
              ) : (
                <></>
              )}
            </span>

            {active ? (
              <FaChevronDown className={"menu-item-dropdown-icon"} />
            ) : (
              <FaChevronRight className={"menu-item-dropdown-icon"} />
            )}
          </div>
          <div className={"menu-item-sub-items " + (active && shiftPage ? "show-items" : "hide-items")}>
            {subItems?.map((item, index) => (
              <SidebarLink
                key={"sidebar-link-" + index}
                {...item}
                nested={true}
                setOpenDropdowns={setOpenDropdowns}
                showWarningCircle={!!item.notificationCount}
              />
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"menu-item-container "}>
        <SidebarLink
          label={label}
          link={link || "/"}
          badge={badge}
          className={className + (margin ? " menu-item-margin" : "")}
          icon={icon}
          nested={false}
          isActive={isActive}
          setOpenDropdowns={setOpenDropdowns}
          accessible={isAccessible}
          onClick={onClick}
        />
      </div>
    );
  }
};

const Sidebar: React.FC<SidebarProps> = ({ menuItems, shiftPage, setShiftPage }) => {
  const teamConversations = useTeamChatConversations();
  const activeTeamConversation = useActiveTeamChatConversation();
  const isTeamChatInitialized = useIsTeamChatInitialized();
  const [openDropdowns, setOpenDropdowns] = useState<string[]>([]);
  const canClockInFromDashboard = useCanClockInFromDashboard();

  const unreadMessages = teamConversations
    .filter((conversation) => conversation._id !== activeTeamConversation?._id)
    .filter((conversation) => conversation.unread);

  const chatBadge = <Badge text={unreadMessages.length + " "} className={"unread-count"} />;
  const showChatBadge = isTeamChatInitialized && unreadMessages.length > 0;

  const renderBadge = (item: RouteConfig) => {
    if (item.label === "Chat") {
      return showChatBadge ? chatBadge : item.badge;
    }

    if (item.notificationCount) {
      return <Badge text={item.notificationCount + " "} className={"unread-count"} />;
    }
  };

  const listItems = menuItems
    .filter((item) => !item.bottom)
    .map((item, index) => {
      return item.icon ? (
        <SidebarItem
          {...item}
          key={index}
          icon={item.icon!}
          openDropdowns={openDropdowns}
          setOpenDropdowns={setOpenDropdowns}
          badge={renderBadge(item)}
        />
      ) : null;
    });

  const bottomItems = menuItems
    .filter((item) => item.bottom)
    .map((item, index) => {
      return item.icon ? (
        <SidebarItem
          {...item}
          key={index}
          icon={item.icon!}
          openDropdowns={openDropdowns}
          setOpenDropdowns={setOpenDropdowns}
          badge={renderBadge(item)}
        />
      ) : null;
    });

  return (
    <>
      <div
        className={
          "sidebar-wrapper side-bar-wrapper flex-column " + (shiftPage ? "sidebar-wrapper-full" : "")
        }
      >
        <p className={"sidebar-header " + (shiftPage && "sidebar-shifted")}>
          {shiftPage && <span className="sidebar-header-full">Miter</span>}
          <AiOutlineMenu className="sidebar-header-icon" onClick={() => setShiftPage(!shiftPage)} />
        </p>
        <div className="sidebar-menu-items">{listItems}</div>
        <div className="flex-1"></div>
        {canClockInFromDashboard && <ClockInButton />}
        <div className="sidebar-menu-items-bottom">{bottomItems}</div>
      </div>
    </>
  );
};

export default Sidebar;
