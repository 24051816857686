import React, { FC, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { PerformanceReviewCyclesTable } from "../../components/performance/PerformanceReviewCyclesTable";
import { Params, useNavigate, useParams } from "react-router-dom";
import { Button, Toggler } from "ui";
import { PerformanceReviewsTable } from "dashboard/components/performance/PerformanceReviewsTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TogglerConfigItem } from "ui/toggler/Toggler";

export const PerformanceManagement: FC = () => {
  const { view } = useParams<Params>();
  const navigate = useNavigate();

  const { can } = useMiterAbilities();

  const togglerConfig = useMemo(() => {
    const list: TogglerConfigItem[] = [];

    if (can("performance:schedules:read")) {
      list.push({ label: "Review schedules", path: "review-schedules" });
    }

    if (can("performance:reviews:others:read") || can("performance:reviews:personal:read")) {
      list.push({ label: "All reviews", path: "all-reviews" });
    }

    return list;
  }, [can]);

  useEffect(() => {
    const defaultPath = togglerConfig[0]?.path || "review-schedules";

    if (!view) {
      navigate(`/performance/${defaultPath}`, { replace: true });
    }
  }, [view, togglerConfig]);

  const handleToggle = (path) => {
    navigate(`/performance/${path}`);
  };

  const renderView = () => {
    if (view === "review-schedules") {
      return <PerformanceReviewCyclesTable />;
    }
    if (view === "all-reviews") {
      return <PerformanceReviewsTable />;
    }
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Performance | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <h1>Performance</h1>
        <div className="flex-1"></div>
        {can("performance:settings") && (
          <Button className="button-1" text="Settings" onClick={() => navigate("/performance/settings")} />
        )}
      </div>
      <Toggler
        secondary={false}
        active={view || "review-schedules"}
        config={togglerConfig}
        toggle={handleToggle}
      />
      {renderView()}
    </div>
  );
};
