import AppContext from "dashboard/contexts/app-context";
import { EnrichedLedgerEntry, LedgerAccount, MiterAPI } from "dashboard/miter";
import { Notifier, getLedgerAccountLabel } from "dashboard/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { BasicModal, LoadingModal } from "ui";
import { LedgerEntryTableData } from "./LedgerEntries";
import { LookupAtomFunction } from "dashboard/atoms";
import { useActiveCompanyId, useLookupLedgerAccount } from "dashboard/hooks/atom-hooks";
import { IntegrationStatusObj } from "backend/services/integrations/integration-types";

type Props = {
  entriesToPost: (LedgerEntryTableData | EnrichedLedgerEntry)[];
  hide: () => void;
  getLedgerEntries?: () => Promise<void>;
};

export const PostEntriesModal: React.FC<Props> = ({ entriesToPost, hide, getLedgerEntries }) => {
  const { integrations } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const lookupLedgerAccount = useLookupLedgerAccount();

  const [loading, setLoading] = useState(false);

  const [connectionStatus, setConnectionStatus] = useState<IntegrationStatusObj>({
    status: "Pending setup",
    userMessage: `You do not have an integration with this capability enabled.`,
  });
  const [statusLoading, setStatusLoading] = useState(false);

  const icToUse = useMemo(() => {
    return integrations.find(
      (i) =>
        i.connection && !i.connection.pending_setup && i.supported_operations?.ledger_entries?.push?.enabled
    )?.connection;
  }, [integrations]);

  const notSyncable = useMemo(() => {
    if (!icToUse) {
      return "You do not have an integration with ledger entry sync enabled.";
    }
    for (const entry of entriesToPost) {
      const errMsg = checkLineItemsMapped(entry, lookupLedgerAccount);
      if (errMsg) {
        return `The entry for "${entry.description}" has an error: ${errMsg}.`;
      }
    }
    return false;
  }, [lookupLedgerAccount, entriesToPost, icToUse]);

  const getConnectionStatus = async () => {
    const icId = icToUse?._id;
    if (!activeCompanyId || !icId || notSyncable) return;
    setStatusLoading(true);
    try {
      const response = await MiterAPI.integrations.get_connection_status(icId);
      if (response.error) throw new Error(response.error);
      setConnectionStatus(response);
    } catch (e) {
      Notifier.error("Error checking connection to accounting system. Please reach out to support");
      console.error(e);
    }
    setStatusLoading(false);
  };

  useEffect(() => {
    getConnectionStatus();
  }, [notSyncable, !!activeCompanyId, icToUse]);

  const postEntries = async () => {
    setLoading(true);
    try {
      const idsToSync = entriesToPost.map((e) => e._id);
      if (!icToUse) {
        throw new Error("There must be one integration with ledger entry sync enabled.");
      }
      const response = await MiterAPI.integrations.run_manual_sync({
        id: icToUse._id,
        operation: {
          entity: "ledger_entries",
          direction: "push",
          idsToSync,
        },
        trigger: "manual",
      });
      if (response.error) throw new Error(response.error);
      await getLedgerEntries?.();
      if (response.some((sync) => sync.error_message || sync.status === "error")) {
        Notifier.warning(
          "Ledger entry sync complete with one or more errors. View your integration to view details about the sync"
        );
      } else {
        Notifier.success("Ledger entry sync complete. View your integration to view details about the sync.");
      }

      hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error syncing your entries.");
    }
    setLoading(false);
  };

  const entryText = entriesToPost.length > 1 ? "entries" : "entry";
  const genWarningText = () => {
    let resyncWarning = "";
    const syncedEntries = entriesToPost.filter((le) => le.sync?.qbo || le.sync?.qbd);
    if (syncedEntries.length) {
      if (entriesToPost.length > 1) {
        resyncWarning = `${syncedEntries.length} of these entries ${syncedEntries.length ? "have" : "has"}`;
      } else {
        resyncWarning = `This entry has`;
      }
      resyncWarning += ` already been posted and will be overwritten. `;
    }
    return `You are about to export ${entriesToPost.length} ledger ${entryText} to your accounting system. ${resyncWarning}Are you sure you want to continue?`;
  };

  const connectionError = connectionStatus.status !== "Connected";

  if (notSyncable) {
    return (
      <BasicModal headerText={"Ledger entry error"} button2Text={"Got it"} button2Action={hide}>
        <div className="red-text-container">
          <span>{`You cannot sync ${
            entriesToPost.length > 1 ? "these" : "this"
          } ledger ${entryText}. ${notSyncable}`}</span>
        </div>
      </BasicModal>
    );
  }

  if (statusLoading) {
    return <LoadingModal text="Checking connection to accounting system..." />;
  }

  return (
    <BasicModal
      headerText={connectionError ? "Connection error" : "Ledger entry sync confirmation"}
      loading={loading}
      button1Text={connectionError ? undefined : "Cancel"}
      button1Action={hide}
      button2Text={connectionError ? "Got it" : `Post ${entryText}`}
      button2Action={connectionError ? hide : postEntries}
    >
      {connectionError ? (
        <div className="red-text-container">
          <span>We are unable to connect to your accounting system:</span> <br /> <br />
          <span>{connectionStatus.userMessage || "Unknown error"}</span>
        </div>
      ) : (
        <div className="yellow-text-container">
          <span>{genWarningText()}</span>
        </div>
      )}
    </BasicModal>
  );
};

// Determines whether a ledger entry can be synced with a connected accounting system
const checkLineItemsMapped = (
  ledgerEntry: EnrichedLedgerEntry | LedgerEntryTableData,
  lookupLedgerAccount: LookupAtomFunction<LedgerAccount>
): string => {
  // "line_items" is how we determine if it's a full enriched ledger entry or just table ledger entry data
  const accountIds =
    "line_items" in ledgerEntry
      ? [...new Set(ledgerEntry.line_items.map((li) => li.ledger_account_id))]
      : ledgerEntry.account_ids;

  for (const id of accountIds) {
    const matchingLa = lookupLedgerAccount(id);
    if (!matchingLa) return "Every line item must have a GL account";

    if (!matchingLa?.integrations) {
      const label = getLedgerAccountLabel(matchingLa);
      return `GL account "${label}" was not imported from your accounting system`;
    }
  }
  return "";
};
