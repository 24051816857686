import { FlatfileResults } from "@flatfile/react";
import {
  useActiveCompanyId,
  useActiveJobs,
  useActiveTeam,
  useCostTypes,
  useLedgerAccounts,
  useSelectableActivitiesMap,
} from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage, normalizeDate } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { Notifier } from "ui";
import { keyBy } from "lodash";
import { CreateOrUpdateExpenseReimbursementParam, MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../importer/Importer";
import { useImportValidators } from "dashboard/hooks/flatfile-import/useImportValidators";
import { ExpenseReimbursementPayoutMethod, ReimbursementType } from "backend/models/expense-reimbursement";
import { useGetReimbursementPayoutMethodOptions } from "dashboard/pages/expenses/expenseUtils";

type PrelimExpenseReimbursementImportRow = {
  teamMemberId: string;
  reimbursementType: ReimbursementType;
  date: string;
  amount?: string;
  miles?: string;
  vendor?: string;
  jobCode?: string;
  costCode?: string;
  costTypeId?: string;
  glAccountCode?: string;
  payoutMethod?: ExpenseReimbursementPayoutMethod;
  memo?: string;
};

type Props = {
  onFinish: () => void;
};

export const ExpenseReimbursementImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeCompanyId = useActiveCompanyId();

  const activeJobs = useActiveJobs();
  const costTypes = useCostTypes();
  const teamMembers = useActiveTeam();
  const glAccounts = useLedgerAccounts();
  const getPaymentMethodOptions = useGetReimbursementPayoutMethodOptions();

  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);
  const lookupCostType = useMemo(() => keyBy(costTypes, "code"), [costTypes]);
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);
  const lookupActivity = useSelectableActivitiesMap();
  const lookupGLAccount = useMemo(() => keyBy(glAccounts, "external_id"), [glAccounts]);

  const {
    validateTeamMemberID,
    validateCostCode,
    validateCostTypeId,
    validateJobCode,
    validateGLAccountCode,
  } = useImportValidators();

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/

  const validateReimbursementType = (reimbursementType: string | undefined) => {
    if (reimbursementType !== "out_of_pocket" && reimbursementType !== "mileage") {
      return buildFlatfileMessage(
        "Reimbursement type must be either 'Out of pocket' or 'Mileage'",
        reimbursementType,
        "error"
      );
    }

    return { value: reimbursementType };
  };

  const validatePayoutMethod = (payoutMethod: string | undefined) => {
    if (!payoutMethod) return { value: undefined };

    if (getPaymentMethodOptions().every((o) => o.value !== payoutMethod)) {
      return buildFlatfileMessage(
        "Payout method must be one of 'payroll', 'ach' or 'manual'",
        payoutMethod,
        "error"
      );
    }

    return { value: payoutMethod };
  };

  const validateAmount = (amount: string | undefined, reimbursementType: string | undefined) => {
    if (amount) {
      const parsedAmount = parseFloat(amount);
      if (!/^[0-9]+\.?[0-9]?[0-9]?$/.test(amount) || parsedAmount <= 0) {
        return buildFlatfileMessage("Amount must be a valid dollar amount", amount, "error");
      }
      return { value: parsedAmount };
    } else {
      if (reimbursementType === "out of pocket") {
        return buildFlatfileMessage("Amount required for out of pocket reimbursement", amount, "error");
      }
      return { value: undefined };
    }
  };

  const validateMiles = (miles: string | undefined, reimbursementType: string | undefined) => {
    if (miles) {
      const parsedMiles = parseFloat(miles);
      if (isNaN(parsedMiles) || parsedMiles <= 0) {
        return buildFlatfileMessage("Miles must be a valid positive number", miles, "error");
      }
      return { value: parsedMiles };
    } else {
      if (reimbursementType === "mileage") {
        return buildFlatfileMessage("Miles required for mileage reimbursement", miles, "error");
      }
      return { value: undefined };
    }
  };

  const validateVendor = (vendor: string | undefined, reimbursementType: string | undefined) => {
    if (reimbursementType === "out of pocket" && !vendor) {
      return buildFlatfileMessage("Vendor required for out of pocket reimbursement", vendor, "error");
    }
    return { value: vendor };
  };

  const buildExpenseParams = (
    row: PrelimExpenseReimbursementImportRow
  ): CreateOrUpdateExpenseReimbursementParam => {
    if (!activeCompanyId) throw new Error("No active company ID");
    const {
      teamMemberId,
      reimbursementType,
      date,
      amount,
      miles,
      vendor,
      jobCode,
      costCode,
      costTypeId,
      glAccountCode,
      payoutMethod,
      memo,
    } = row;
    const teamMember = lookupTeamID[teamMemberId];
    if (!teamMember) throw new Error(`Team member ${teamMemberId} not found`);

    const job = jobCode ? lookupJobCode[jobCode] : null;
    const activity = costCode ? lookupActivity.get(job?._id).find((a) => a.cost_code === costCode) : null;
    const costType = costTypeId ? lookupCostType[costTypeId] : null;
    const glAccount = glAccountCode ? lookupGLAccount[glAccountCode] : null;
    const isTMOnboardedForPayroll = teamMember.check_tm?.onboard.status === "completed";

    const distanceInMiles = parseFloat(miles || "0");
    return {
      company_id: activeCompanyId,
      team_member_id: teamMember._id,
      date,
      type: reimbursementType,
      amount: amount !== undefined ? parseFloat(amount) : undefined,
      mileage_detail:
        miles !== undefined && reimbursementType === "mileage" ? { mileage: distanceInMiles } : undefined,
      job_id: job?._id,
      cost_type_id: costType?._id,
      activity_id: activity?._id,
      expense_account_id: glAccount?._id,
      vendor_name: reimbursementType === "out_of_pocket" ? vendor : undefined,
      memo,
      status: "unapproved",
      payout_method: payoutMethod ?? (isTMOnboardedForPayroll ? "payroll" : "manual"),
      is_taxable: false, // TODO: add support for taxable payroll reimbursements
      creation_method: "dashboard_bulk_import",
    };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedReimbursements = results.validData.map(buildExpenseParams);

      const response = await MiterAPI.expense_reimbursements.import({
        clean_inputs: preppedReimbursements,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;
      const warnings = response.results.warnings.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(
            `Imported ${successes} reimbursements with ${errors} errors and ${warnings} warnings.`
          );
        } else {
          Notifier.success(`Imported ${successes} reimbursements with ${warnings} warnings.`);
        }
      } else {
        Notifier.error(`There were ${errors} errors and ${warnings} warnings.`);
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the reimbursements.");
    }
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Employee ID number",
        type: "string",
        key: "teamMemberId",
        description: "Unique identifer for team member (must be same in source system and Miter).",
        validators: [{ validate: "required" }],
        hook: (val) =>
          typeof val === "string"
            ? validateTeamMemberID(val, true)
            : validateTeamMemberID(val.teamMemberId, true),
      },
      {
        label: "Reimbursement type",
        type: "select",
        key: "reimbursementType",
        description:
          "Either 'Out of pocket' for use of personal funds for the company or 'Mileage' for use of personal vehicle.",
        options: [
          { label: "Out of pocket", value: "out_of_pocket" },
          { label: "Mileage", value: "mileage" },
        ],
        validators: [{ validate: "required" }],
        hook: (row) =>
          typeof row === "string"
            ? validateReimbursementType(row)
            : validateReimbursementType(row.reimbursementType),
      },
      {
        label: "Date",
        type: "string",
        key: "date",
        description: "The date the expense was incurred.",
        validators: [{ validate: "required" }],
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.date)),
      },
      {
        label: "Amount",
        type: "string",
        key: "amount",
        description: "Amount to reimburse, required if an out of pocket reimbursement",
        hook: (val) =>
          typeof val === "string"
            ? validateAmount(val, undefined)
            : validateAmount(val.amount, val.reimbursementType),
      },
      {
        label: "Miles",
        type: "string",
        key: "miles",
        description: "Miles driven, up to 2 decimal places, required if a mileage reimbursement",
        hook: (val) =>
          typeof val === "string"
            ? validateMiles(val, undefined)
            : validateMiles(val.miles, val.reimbursementType),
      },
      {
        label: "Vendor",
        type: "string",
        key: "vendor",
        description: "Amount to reimburse, required if an out of pocket reimbursement",
        hook: (val) =>
          typeof val === "string"
            ? validateVendor(val, undefined)
            : validateVendor(val.vendor, val.reimbursementType),
      },
      {
        label: "Job code",
        type: "string",
        key: "jobCode",
        description: "Unique identifer for a job (must be same in source system and Miter)",
        hook: (val) => (typeof val === "string" ? validateJobCode(val) : validateJobCode(val.jobCode)),
      },
      {
        label: "Activity",
        type: "string",
        key: "costCode",
        description: "Unique identifer for an activity (must be same in source system and Miter)",
        hook: (val) =>
          typeof val === "string"
            ? validateCostCode(val, undefined)
            : validateCostCode(val.costCode, val.jobCode),
      },
      {
        label: "Cost type (ID)",
        type: "string",
        key: "costTypeId",
        description: "Cost type ID (must be same in source system and Miter) - ex. LR for Labor",
        hook: (val) => (typeof val === "string" ? validateCostTypeId(val) : validateCostTypeId(val.costType)),
      },
      {
        label: "GL account code",
        type: "string",
        key: "glAccountCode",
        description: "Unique identifer for a GL account (must be same in source system and Miter)",
        hook: (val) =>
          typeof val === "string" ? validateGLAccountCode(val) : validateGLAccountCode(val.glAccountCode),
      },
      {
        label: "Payout method",
        type: "select",
        key: "payoutMethod",
        description:
          "How the reimbursement will be paid out. If unspecified, will default to payroll if the team member is enrolled, manual otherwise.",
        options: getPaymentMethodOptions(),
        hook: (row) =>
          typeof row === "string" ? validatePayoutMethod(row) : validatePayoutMethod(row.payoutMethod),
      },
      {
        label: "Memo",
        type: "string",
        key: "memo",
        description: "Notes for the reimbursement",
      },
    ];

    return fieldList;
  }, []);

  return (
    <Importer
      id="expensereimbursements"
      resource="expense reimbursements"
      onSave={handleSubmit}
      fields={fields}
    />
  );
};
