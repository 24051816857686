import { useActiveCompanyId, useLookupCertificationTypes, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { Loader, Notifier } from "ui";
import FailuresModal from "../shared/FailuresModal";
import BulkTeamMemberSelect, { BulkTeamMemberSelectTeamMember } from "../team-members/BulkTeamMemberSelect";

type Props = {
  certificationTypeId: string;
  onClose: () => void;
};

export const RequestCertificationsModal: FC<Props> = ({ certificationTypeId, onClose }) => {
  const [activeTmIds, setActiveTmIds] = useState<string[]>([]);
  const [failures, setFailures] = useState<{ label: string; message: string }[]>([]);
  const lookupCertificationType = useLookupCertificationTypes();
  const certificationType = lookupCertificationType(certificationTypeId);
  const activeCompanyId = useActiveCompanyId();
  const lookupTeam = useLookupTeam();

  useEffect(() => {
    const checkForUnexpiredCertifications = async () => {
      MiterAPI.certifications
        .forage({
          filter: [
            { field: "certification_type_id", value: certificationTypeId, type: "string" },
            {
              type: "or",
              value: [
                {
                  field: "expires_at",
                  value: DateTime.now().toISODate(),
                  type: "string",
                  comparisonType: ">=",
                },
                { field: "expires_at", value: null, type: "string" },
              ],
            },
          ],
        })
        .then((res) => {
          setActiveTmIds(res.data.map((certification) => certification.team_member_id) || []);
        });
    };
    checkForUnexpiredCertifications();
  }, []);

  const onSubmit = async (selectedTeamMembers: BulkTeamMemberSelectTeamMember[]) => {
    if (!activeCompanyId) return Notifier.error("No active company found");
    try {
      const params = selectedTeamMembers.map((tm) => ({
        certification_type_id: certificationTypeId,
        team_member_id: tm._id,
        company_id: activeCompanyId,
        submitted: false,
        expires_at: undefined,
        custom_field_values: [],
        file_uploads: {},
        archived: false,
      }));
      const res = await MiterAPI.certifications.create(params);
      if (res.error) throw res.error;
      setFailures(
        res.failures.map((f) => ({
          label: lookupTeam(f._id)?.full_name || "Team member",
          message: f.message,
        }))
      );
      Notifier.success("Certification requests successfully sent!");
    } catch (e: $TSFixMe) {
      Notifier.error("Failed to request certifications: " + e.message);
    }
    onClose();
  };

  const renderUpdateFailuresModal = () => {
    if (!failures.length) return null;

    return (
      <FailuresModal
        headerText={"Certification request failures"}
        onClose={() => {
          setFailures([]);
        }}
        failures={failures}
      />
    );
  };

  if (!certificationType) {
    return <Loader />;
  }

  return (
    <div>
      <BulkTeamMemberSelect
        title={`Request certifications: ${certificationType.title}`}
        defaultTeamMembers={[]}
        disableTm={(tm) => ({
          status: activeTmIds.includes(tm._id),
          reason: "Already has this un-expired certification",
        })}
        onHide={onClose}
        submitText="Submit"
        onSubmit={onSubmit}
      />
      {renderUpdateFailuresModal()}
    </div>
  );
};
