import React, { useEffect, useState } from "react";
import { Button, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { Props } from "./types";
import useWizard from "ui/modal/useWizard";
import { OnboardingChecklistCompletionData } from "dashboard/types/onboarding-types";
import I9Wizard from "dashboard/components/i-9s/I9Wizard";
import { saveAs } from "file-saver";
import { MiterAPI } from "dashboard/miter";

type I9Props = Props & {
  i9: NonNullable<OnboardingChecklistCompletionData["i9"]>;
};

export const EmployerI9WizardScreen: React.FC<I9Props> = ({ task, name, i9, onboardingChecklistItem }) => {
  const [fillingI9, setFillingI9] = useState(false);
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();
  const [downloadingI9, setDownloadingI9] = useState(false);

  useEffect(() => {
    setCanNext(i9.status === "complete" || !i9.employee_esignature);
  }, [i9.status]);

  const onExit = () => {
    setFillingI9(false);
  };

  const onNext = () => {
    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  const handleDownloadClick = async () => {
    setDownloadingI9(true);
    try {
      const res = await MiterAPI.i_9s.retrieve_pdf(i9._id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      saveAs(blob, `I-9_${onboardingChecklistItem.new_hire.full_name}.pdf`);
    } catch (e: $TSFixMe) {
      Notifier.error("Error downloading I-9 preview. Please contact support.");
      console.error("Error previewing I-9", e);
    }
    setDownloadingI9(false);
  };

  const renderEmployeeNotComplete = () => {
    return (
      <div>{onboardingChecklistItem.new_hire.full_name} must first complete their section of the I-9.</div>
    );
  };

  const fillI9 = () => {
    if (onboardingChecklistItem.new_hire.ssn_last_four) {
      setFillingI9(true);
    } else {
      Notifier.error("Team member must first fill out their SSN before you can complete their I-9..");
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        {i9.employee_esignature ? (
          i9.status === "complete" ? (
            <div>
              <p style={{ marginBottom: 20 }}>I9 has been successfully completed.</p>
              <Button
                className={"button-2"}
                onClick={handleDownloadClick}
                style={{ margin: 0, marginBottom: 30, marginTop: 10, padding: "20px 35px", fontSize: 16 }}
                loading={downloadingI9}
              >
                {"Download I-9"}
              </Button>
            </div>
          ) : (
            <Button
              className={"button-2"}
              onClick={fillI9}
              style={{ margin: 0, marginBottom: 30, marginTop: 10, padding: "20px 35px", fontSize: 16 }}
            >
              {"Finalize I-9"}
            </Button>
          )
        ) : (
          renderEmployeeNotComplete()
        )}
        {fillingI9 && (
          <I9Wizard
            onExit={onExit}
            I9={i9}
            getI9={async () => {
              return i9;
            }}
          />
        )}
      </div>
    </WizardScreen>
  );
};
