import { AggregatedTeamConversation } from "dashboard/types/chat";
import { useLookupTeam, useTeamChatConversations } from "../atom-hooks";
import { useChatAbilities } from "../abilities-hooks/useChatAbilities";
import { useCallback } from "react";

type Filter = "unread" | "all";
type FilterTeamConversations = (search: string, filter: Filter) => AggregatedTeamConversation[];

export type useTeamChatUtilities = {
  filterConversations: FilterTeamConversations;
};

export const RECRUITING_CONVERSATIONS_KEY = "recruiting_conversations.1";

export const useTeamChatUtilities = (): useTeamChatUtilities => {
  /** Atom hooks */
  const chatAbilities = useChatAbilities();

  const teamConversations = useTeamChatConversations();
  const lookupTeam = useLookupTeam();

  const filterConversations: FilterTeamConversations = useCallback(
    (search, filter) => {
      const filteredConversations = teamConversations
        .filter((conversation) => chatAbilities.can("read", conversation))
        .filter((conversation) => {
          return (
            (conversation.friendly_name.toLowerCase().includes(search.toLowerCase()) ||
              lookupTeam(conversation.team_member)?.full_name.toLowerCase().includes(search.toLowerCase())) &&
            (filter === "all" || conversation.unread)
          );
        });

      return filteredConversations;
    },
    [teamConversations]
  );

  return {
    filterConversations,
  };
};
