import { ValueFormatterParams } from "ag-grid-community";
import { TeamMemberGroup } from "backend/types";
import { useTeamMemberGroupLabeler } from "dashboard/utils/approvals";
import { Plus } from "phosphor-react";
import React, { useCallback, useMemo, useState } from "react";
import { Option } from "ui/form/Input";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { CustomModalProps, OnboardingCustomTaskFormParams } from "./OnboardingCustomTaskModal";
import { OnboardingCustomTaskTableRow } from "./OnboardingCustomTasksTable";
import OnboardingItemModal, {
  OnboardingItemFormParams,
  TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
} from "./OnboardingItemModal";
import styles from "./OnboardingItemsTable.module.css";

export type OnboardingItemTableRow = {
  _id: string;
  name?: string | null;
  default_team_member_checklists?: TeamMemberGroup[] | null;
  default_assignee?: TeamMemberGroup[] | null;
};

export type OnboardingItemsTableProps = {
  tableId: string;
  objectOptions: Option<string>[];
  resourceName: string;
  handleRemove: (selectedRows: (OnboardingItemTableRow | OnboardingCustomTaskTableRow)[]) => Promise<void>;
  handleUpdate:
    | ((row: OnboardingItemFormParams) => Promise<void>)
    | ((row: OnboardingCustomTaskFormParams) => Promise<void>);
  handleCreate?:
    | ((row: OnboardingItemFormParams) => Promise<void>)
    | ((row: OnboardingCustomTaskFormParams) => Promise<void>);
  tableData: OnboardingItemTableRow[];
  columns?: ColumnConfig<OnboardingItemTableRow>[] | ColumnConfig<OnboardingCustomTaskTableRow>[];
  modalComponent?: React.ComponentType<CustomModalProps>;
};

export const OnboardingItemsTable: React.FC<OnboardingItemsTableProps> = (
  props: OnboardingItemsTableProps
) => {
  const {
    tableId,
    objectOptions,
    resourceName,
    handleRemove,
    handleUpdate,
    handleCreate,
    tableData,
    columns,
    modalComponent: ModalComponent,
  } = props;
  const [selectedRows, setSelectedRows] = useState<OnboardingItemTableRow[] | OnboardingCustomTaskTableRow[]>(
    []
  );
  const [clickedRow, setClickedRow] = useState<OnboardingItemTableRow | OnboardingCustomTaskTableRow>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);

  const { groupLabeler } = useTeamMemberGroupLabeler(TEAM_MEMBER_GROUP_LABEL_OVERRIDES);

  const handleSelect = (selections: OnboardingItemTableRow[]) => {
    setSelectedRows(selections);
  };

  const handleRemoveAction = useCallback(async () => {
    setRemoveLoading(true);
    await handleRemove(selectedRows);
    setSelectedRows([]);
    setRemoveLoading(false);
  }, [handleRemove, selectedRows]);

  const defaultColumns: ColumnConfig<OnboardingItemTableRow>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        dataType: "string",
      },
    ],
    []
  );

  const teamMemberGroupColumns = useMemo(
    () => [
      {
        field: "_id",
        headerName: "ID",
        dataType: "string" as const,
        hide: true,
      },
      {
        field: "default_team_member_checklists",
        headerName: "Relevant team groups",
        dataType: "string" as const,
        valueFormatter: (params: ValueFormatterParams<OnboardingItemTableRow>): string => {
          return (
            params.data?.default_team_member_checklists?.map((group) => groupLabeler(group)).join(", ") || "-"
          );
        },
      },
      {
        field: "default_assignee",
        headerName: "Default assignee",
        dataType: "string" as const,
        valueFormatter: (params: ValueFormatterParams<OnboardingItemTableRow>): string => {
          return params.data?.default_assignee?.map((group) => groupLabeler(group)).join(", ") || "-";
        },
      },
    ],
    [groupLabeler]
  );

  const tableColumns = useMemo(() => {
    return [...(columns || defaultColumns), ...teamMemberGroupColumns];
  }, [columns, defaultColumns, teamMemberGroupColumns]);

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Remove",
        action: handleRemoveAction,
        className: "button-3 table-button",
        loading: removeLoading,
      },
    ],
    [selectedRows, handleRemove, removeLoading]
  );

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add",
        action: () => {
          setShowCreateModal(true);
        },
        className: "button-2 table-button",
        important: true,
        icon: <Plus weight="bold" className={styles.plusIcon} />,
      },
    ],
    []
  );

  const remainingObjectOptions = useMemo(() => {
    return objectOptions.filter((option) => !tableData.map((row) => row._id).includes(option.value));
  }, [objectOptions, tableData]);

  return (
    <>
      <TableV2
        id={tableId}
        resource={resourceName}
        columns={tableColumns as ColumnConfig<OnboardingItemTableRow | OnboardingCustomTaskTableRow>[]}
        data={tableData}
        defaultSelectedRows={selectedRows}
        onSelect={handleSelect}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        hideSearch={true}
        hidePrinter={true}
        hideExporter={true}
        onClick={(row) => setClickedRow(row)}
        containerClassName={styles.customTable}
      />
      {(clickedRow || showCreateModal) &&
        (ModalComponent ? (
          <ModalComponent
            selectedObject={clickedRow as OnboardingCustomTaskTableRow}
            onHide={() => {
              setClickedRow(undefined);
              setShowCreateModal(false);
            }}
            onSave={() => {
              setClickedRow(undefined);
              setShowCreateModal(false);
            }}
            handleRemove={handleRemove}
            handleUpdate={handleUpdate as (row: OnboardingCustomTaskFormParams) => Promise<void>}
            handleCreate={handleCreate as (row: OnboardingCustomTaskFormParams) => Promise<void>}
            resourceName={resourceName}
          />
        ) : (
          <OnboardingItemModal
            selectedObject={clickedRow}
            onHide={() => {
              setClickedRow(undefined);
              setShowCreateModal(false);
            }}
            onSave={() => {
              setClickedRow(undefined);
              setShowCreateModal(false);
            }}
            handleRemove={handleRemove as (selectedRows: OnboardingItemTableRow[]) => Promise<void>}
            handleUpdate={handleUpdate as (row: OnboardingItemFormParams) => Promise<void>}
            objectOptions={remainingObjectOptions}
            resourceName={resourceName}
          />
        ))}
    </>
  );
};

export default OnboardingItemsTable;
