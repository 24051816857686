import { JobApplicationsTable } from "dashboard/components/ats/JobApplicationsTable";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { AggregatedCandidate } from "dashboard/types/ats";
import { getCandidateFullName } from "dashboard/utils/ats";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Loader } from "ui";

export const Candidate: React.FC = () => {
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const { id } = useParams<{ id: string; view: string }>();
  const [candidate, setCandidate] = useState<AggregatedCandidate>();

  /* Effects */
  useEffect(() => {
    if (!id || !activeCompanyId) {
      return;
    }
    MiterAPI.candidates.retrieve_many([{ field: "company_id", value: activeCompanyId }]).then((res) => {
      const candidate = res.find((c) => c._id === id);
      if (!candidate) {
        navigate("/recruiting/candidates", { replace: true });
        return;
      }
      setCandidate(candidate);
    });
  }, [id, activeCompanyId]);

  const fullName = useMemo(() => {
    if (!candidate) return "";
    return getCandidateFullName(candidate);
  }, [candidate]);

  /* Render Functions */
  const renderBreadcrumbs = () => {
    if (!candidate) return;

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Candidates", path: "/recruiting/candidates" },
          { label: fullName, path: "/recruiting/candidates/" + candidate._id },
        ]}
      />
    );
  };

  return candidate ? (
    <div className="page-content">
      <Helmet>
        <title>{fullName} | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <div>
          {renderBreadcrumbs()}
          <div className="flex" style={{ alignItems: "center" }}>
            <h1>{fullName}</h1>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <JobApplicationsTable candidate={candidate} />
    </div>
  ) : (
    <Loader />
  );
};
