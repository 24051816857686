import { FC, useState, useMemo } from "react";
import { Formblock, Label } from "ui";
import * as vals from "dashboard/utils/validators";
import { cleanAddress } from "dashboard/utils/utils";

import { useForm } from "react-hook-form";
import { ClickAwayListener } from "@material-ui/core";
import { ModalHeader } from "ui";
import { ModalFooter } from "ui";
import { AggregatedCompany, Company, MiterAPI } from "dashboard/miter";
import { FileToUpload, formatFilesForUpload } from "miter-utils";
import { useActiveCompanyId, useIsMiterAdmin } from "dashboard/hooks/atom-hooks";

type Props = {
  company: AggregatedCompany;
  onHide: () => void;
  onUpdate: (updateObj, setError) => {};
  submitting: boolean;
};

const UpdateCompanyModal: FC<Props> = ({ company, onHide, onUpdate, submitting }) => {
  const isMiterAdmin = useIsMiterAdmin();
  const activeCompanyId = useActiveCompanyId();
  const { register, control, watch, errors, handleSubmit, formState, setValue, setError } = useForm();
  const [savingLogo, setSavingLogo] = useState(false);
  // Triggering access to formState before render per RHF documentation.
  const formData = watch();
  const dirtyFields = formState.dirtyFields;

  // Watch for changes in the logo file input
  const logoFile = watch("logo_file");

  // Use useMemo to memoize the calculation of showLogoOptions
  const showLogoOptions = useMemo(() => {
    // During the initial render, logo_file data has not been initialized in the form state
    // If logo_file form data does exist, check if it's been deleted
    return !logoFile ? !!company.logo : logoFile.length > 0 && !logoFile[0].deleted;
  }, [company.logo, logoFile]);

  // Saves the updated logo in the backend + S3 and returns the file id
  const saveLogo = async (): Promise<string> => {
    setSavingLogo(true);

    const filesToUpload = formData.logo_file;
    const formattedFiles = formatFilesForUpload(filesToUpload, activeCompanyId!);

    const responseData = await MiterAPI.files.upload({ files: formattedFiles });
    if ("error" in responseData) throw new Error(responseData.error);

    return responseData[0]!.file._id.toString();
  };

  const deleteLogo = async (): Promise<void> => {
    setSavingLogo(true);
    try {
      await MiterAPI.files.delete([company.logo?._id]);
    } catch (e) {
      console.error("Unable to delete logo file: ", e);
    }
  };

  const onSubmit = async (data) => {
    // Upload image if it has been updated and return the new image _id and pass it to the update object
    const updateObject: Partial<Company & { logo_file?: FileToUpload }> = {};
    // Ensure we don't overwrite current company settings
    updateObject.settings = { ...company.settings };

    for (const key of Object.keys(dirtyFields)) {
      if (key === "address" || key === "workplace") {
        updateObject[key] = cleanAddress(data[key]);
      } else if (key === "settings") {
        updateObject[key] = { ...updateObject.settings, ...data[key] }; // Preserve current settings
      } else {
        updateObject[key] = data[key];
      }
    }

    for (const [key, val] of Object.entries(data)) {
      if (Object.keys(updateObject).includes(key)) continue;
      if (val == null || val === "") {
        updateObject[key] = null;
      }
    }

    // If the logo has been updated (i.e. the logo file has a blob), save the logo and set the _id to the update object
    if (formData.logo_file?.[0]?.blob) {
      updateObject.logo_file_id = await saveLogo();
      delete updateObject.logo_file;
    }

    // If the logo has been deleted, set the logo_file_id to null
    if (formData.logo_file?.[0]?.deleted) {
      await deleteLogo();
      updateObject.logo_file_id = null;
      delete updateObject.logo_file;
      // When deleting the logo, set show_company_logo to false
      updateObject.settings = {
        ...updateObject.settings,
        show_company_logo: false,
      };
    }

    if (!data.trade_name) {
      setError("trade_name", { type: "required" });
      return;
    }
    onUpdate(updateObject, setError);
    if (savingLogo) setSavingLogo(false);
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form two-column">
          <ModalHeader onHide={onHide} heading={"Edit company information"} />
          {company && (
            <div className="modal-body form two-column" style={{ paddingBottom: 50, overflow: "auto" }}>
              <div className={"modal-form-column"}>
                <Formblock
                  label="Name"
                  defaultValue={company.check_company.trade_name || company.check_company.legal_name}
                  type="text"
                  control={control}
                  register={register(vals.required)}
                  name="trade_name"
                  className="modal"
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="Business Address"
                  labelInfo="Legal address of company"
                  defaultValue={company.check_company.address}
                  type="address"
                  name="address"
                  register={register(vals.required)}
                  notRequiredRegister={register}
                  control={control}
                  className="modal"
                  errors={errors}
                  editing={true}
                  autocomplete={true}
                  setValue={setValue}
                />
                <Formblock
                  label="Signer name"
                  defaultValue={company.signer_name}
                  type="text"
                  control={control}
                  register={register}
                  name="signer_name"
                  className="modal "
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="Signer title"
                  defaultValue={company.signer_title}
                  type="text"
                  control={control}
                  register={register}
                  name="signer_title"
                  className="modal "
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="FEIN"
                  defaultValue={company.fein}
                  type="text"
                  control={control}
                  name="fein"
                  register={register}
                  className="modal"
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="Self insured certificate number"
                  defaultValue={company.self_insured_certificate_number}
                  type="text"
                  control={control}
                  name="self_insured_certificate_number"
                  register={register}
                  className="modal"
                  errors={errors}
                  editing={true}
                />
              </div>
              <div className={"modal-form-column"}>
                <Formblock
                  label="Email"
                  defaultValue={company.check_company.email}
                  type="text"
                  name="email"
                  register={register(vals.emailNotRequired)}
                  className="modal"
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="Phone"
                  defaultValue={company.phone}
                  type="phone"
                  control={control}
                  name="phone"
                  register={register}
                  val={vals.phoneNotRequired}
                  className="modal"
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="Workplace"
                  defaultValue={company.workplace?.check_workplace?.address}
                  type="address"
                  name="workplace"
                  labelInfo="Default physical location of work for this company"
                  register={register}
                  control={control}
                  className="modal"
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="Company website"
                  labelInfo="Main domain of your company webiste, such as miter.com."
                  defaultValue={company.website_url}
                  type="text"
                  control={control}
                  name="website_url"
                  register={register}
                  val={vals.websiteNotRequired}
                  className="modal"
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  label="Workers' comp policy number"
                  defaultValue={company.workers_comp_policy_number}
                  type="text"
                  control={control}
                  name="workers_comp_policy_number"
                  register={register}
                  className="modal"
                  errors={errors}
                  editing={true}
                />
                <Formblock
                  type="file"
                  label="Company logo"
                  labelInfo="Image of your company logo, with a maximum size 1MB, in JPG, PNG, or SVG format."
                  className="modal"
                  style={{ marginBottom: "0px" }}
                  defaultValue={company.logo ? [{ data: company.logo }] : []}
                  name={"logo_file"}
                  editing={true}
                  control={control}
                  variant={"button"}
                  multiple={false}
                  maxFileSize={1048576}
                  acceptedFileTypes={["image/jpeg", "image/png", "image/svg+xml"]}
                  noMargin={true}
                />
                {showLogoOptions && (
                  <>
                    <Formblock
                      type="checkbox"
                      text="Show logo in dashboard and team portal"
                      className="modal"
                      defaultValue={company.settings?.show_company_logo}
                      name={"settings.show_company_logo"}
                      register={register}
                      editing={true}
                      control={control}
                    />
                  </>
                )}
                {/* (mchaudhry05): temporary change as we rollout new expo camera library */}
                {/* only viewable to Miter Admins */}
                {isMiterAdmin && (
                  <>
                    <Label label="Use Expo Image Picker" style={{ marginTop: "15px" }} />
                    <div className="flex">
                      <Formblock
                        type="checkbox"
                        text="IOS"
                        className="modal"
                        style={{ marginRight: "15px" }}
                        defaultValue={company.settings?.feature_flags?.use_expo_image_picker_ios}
                        name={"settings.feature_flags.use_expo_image_picker_ios"}
                        register={register}
                        editing={true}
                        control={control}
                      />
                      <Formblock
                        type="checkbox"
                        text="Android"
                        className="modal"
                        defaultValue={company.settings?.feature_flags?.use_expo_image_picker_android}
                        name={"settings.feature_flags.use_expo_image_picker_android"}
                        register={register}
                        editing={true}
                        control={control}
                      />
                    </div>
                    <Label label="Use new signed url s3 upload" />
                    <div className="flex">
                      <Formblock
                        type="checkbox"
                        text="IOS"
                        className="modal"
                        style={{ marginRight: "15px" }}
                        defaultValue={company.settings?.feature_flags?.use_signed_s3_url_file_upload_ios}
                        name={"settings.feature_flags.use_signed_s3_url_file_upload_ios"}
                        register={register}
                        editing={true}
                        control={control}
                      />
                      <Formblock
                        type="checkbox"
                        text="Android"
                        className="modal"
                        defaultValue={company.settings?.feature_flags?.use_signed_s3_url_file_upload_android}
                        name={"settings.feature_flags.use_signed_s3_url_file_upload_android"}
                        register={register}
                        editing={true}
                        control={control}
                      />
                    </div>
                    <Formblock
                      type="checkbox"
                      text="Add mobile access to equipment tracking"
                      className="modal"
                      defaultValue={company.settings?.feature_flags?.has_access_equipment_tracking}
                      name={"settings.feature_flags.has_access_equipment_tracking"}
                      register={register}
                      editing={true}
                      control={control}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          <ModalFooter
            loading={submitting || savingLogo}
            onCancel={onHide}
            cancelText={"Cancel"}
            onSubmit={handleSubmit(onSubmit)}
            submitText={"Submit"}
            className="form"
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default UpdateCompanyModal;
