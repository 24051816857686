import React, { useMemo, useState } from "react";
import PayrollContext from "../payrollContext";
import { ActionModal, Formblock } from "ui";
import Notifier from "dashboard/utils/notifier";
import { AutoCalculateSettings, EnhancedMiterPayment, PayrollAdjustment } from "../../payrollTypes";
import { useForm } from "react-hook-form";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { getPaymentPayMethod, getSupplementalTaxCalculationMethod } from "dashboard/utils";
import { usePayrollAutoCalculateCategoryOptions } from "../viewPayrollUtils";
import { notNullish } from "miter-utils";

type Props = {
  hide: () => void;
  selectedPayments: EnhancedMiterPayment[];
};

const paymentMethodOptions = [
  { label: "Paper check", value: "manual" },
  { label: "Default payment method", value: "default" },
];

const PaymentSettingsModal: React.FC<Props> = (props) => {
  // Hooks
  const { payroll, recalculatePayroll } = React.useContext(PayrollContext);
  const { selectedPayments, hide } = props;
  const company = useActiveCompany();

  const { cannot } = useMiterAbilities();

  const form = useForm();

  const selectedAutoCalculateCategories = form.watch("disabled_categories");
  const disableAutoCalculateOptions = usePayrollAutoCalculateCategoryOptions(company);
  const availableAutoCalculateOptions = useMemo(() => {
    const selectedCategories = selectedAutoCalculateCategories?.map((o) => o.value);
    if (selectedCategories?.includes("all") || selectedCategories?.includes("none")) {
      return [];
    }
    return disableAutoCalculateOptions;
  }, [selectedAutoCalculateCategories]);

  // State
  const defaultPayment = selectedPayments.length === 1 ? selectedPayments[0] : null;
  const defaultPaymentMethod = defaultPayment ? getPaymentPayMethod(defaultPayment) : undefined;
  const defaultTaxMethod = defaultPayment
    ? getSupplementalTaxCalculationMethod(defaultPayment, company)
    : undefined;

  const [loading, setLoading] = useState(false);
  const [autoCalculateSettings, setAutoCalculateSettings] = useState<AutoCalculateSettings | undefined>(
    defaultPayment?.adjustment?.auto_calculate
  );
  const [supplementalTaxMethod, setSupplementalTaxMethod] = useState<"aggregate" | "flat" | undefined>(
    defaultTaxMethod
  );
  const [paymentMethod, setPaymentMethod] = useState<"manual" | "default" | undefined>(
    defaultPaymentMethod === "manual"
      ? "manual"
      : defaultPaymentMethod === "direct_deposit"
      ? "default"
      : undefined
  );

  const save = async () => {
    setLoading(true);
    try {
      const newAdjustments: PayrollAdjustment[] = selectedPayments
        .map((payment) => {
          const currentAdjustment = payment.adjustment;

          // First, check current adjustment or other defaults before overwriting with new settings.
          let newPaymentMethod = currentAdjustment?.payment_method;
          if (paymentMethod) {
            // If desired payment method is "default", set to `undefined` so there's no more override within the adjustment
            newPaymentMethod = paymentMethod === "manual" ? "manual" : undefined;
          }
          let newSupplementalTaxMethod = currentAdjustment?.supplemental_tax_calc_method;
          if (supplementalTaxMethod && payment.team_member.employment_type === "employee") {
            newSupplementalTaxMethod = supplementalTaxMethod;
          }
          let newAutoCalculateSettings = currentAdjustment?.auto_calculate;
          if (autoCalculateSettings) {
            newAutoCalculateSettings = autoCalculateSettings;
          }

          return {
            ...payment.adjustment,
            team_member: payment.team_member._id,
            auto_calculate: newAutoCalculateSettings,
            payment_method: newPaymentMethod,
            supplemental_tax_calc_method: newSupplementalTaxMethod,
          };
        })
        .filter(notNullish);
      // Add other team members' adjustments to the list
      const tmIds = new Set(selectedPayments.map((p) => p.team_member._id));
      const unchangedAdjustments = payroll?.adjustments?.filter((a) => a && !tmIds.has(a.team_member)) || [];

      await recalculatePayroll({
        adjustments: [...newAdjustments, ...unchangedAdjustments],
        tms: [...tmIds],
      });

      Notifier.success("Payment settings updated successfully.");
      hide();
    } catch (e) {
      console.error(e);
      Notifier.error("Error updating payment settings");
    }
    setLoading(false);
  };

  return (
    <ActionModal
      headerText={"Edit payment settings"}
      onHide={hide}
      showCancel={true}
      onCancel={hide}
      showSubmit={true}
      onSubmit={form.handleSubmit(save)}
      submitText={"Save"}
      loading={loading}
      bodyStyle={{ overflowX: "visible" }}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Disabled Auto-Calculate categories"
        type="multiselect"
        name="disabled_categories"
        form={form}
        placeholder={"Keep current settings"}
        defaultValue={
          autoCalculateSettings?.disable_all_categories ? ["all"] : autoCalculateSettings?.disabled_categories
        }
        onChange={(o) => {
          let newAutoCalculateSettings: AutoCalculateSettings | undefined;
          const disabledCategories = o?.map((o) => o.value);
          if (disabledCategories?.includes("all")) {
            newAutoCalculateSettings = {
              disable_all_categories: true,
            };
          } else if (disabledCategories?.includes("none")) {
            newAutoCalculateSettings = {};
          } else if (disabledCategories?.length) {
            newAutoCalculateSettings = {
              disable_all_categories: false,
              disabled_categories: disabledCategories,
            };
          }
          setAutoCalculateSettings(newAutoCalculateSettings);
        }}
        options={availableAutoCalculateOptions}
        className="modal"
        editing={true}
        height="auto"
        disabled={cannot("payrolls:update")}
      />
      <Formblock
        label="Payment method"
        type="select"
        name="payment_method"
        form={form}
        defaultValue={paymentMethod}
        onChange={(o) => {
          setPaymentMethod(o.value);
        }}
        options={paymentMethodOptions}
        className="modal"
        editing={true}
        disabled={cannot("payrolls:update")}
      />
      <Formblock
        label="Supplemental tax calculation method"
        labelInfo="Only applies to employees. Either flat 22% or earnings are aggregated and taxed normally."
        type="select"
        name="supplemental_tax_calc_method"
        form={form}
        defaultValue={supplementalTaxMethod}
        onChange={(o) => {
          setSupplementalTaxMethod(o.value);
        }}
        options={[
          { label: "Flat", value: "flat" },
          { label: "Aggregate", value: "aggregate" },
        ]}
        className="modal"
        editing={true}
        disabled={cannot("payrolls:update")}
      />
      <div className={"vertical-spacer"} />
    </ActionModal>
  );
};

export default PaymentSettingsModal;
