import React, { useMemo } from "react";
import PaymentContext from "./paymentContext";
import {
  getBenefitsListWithPrgFringesBrokenOut,
  UnifiedPrgFringeBenefit,
} from "../../../benefits/benefitsUtils";
import { checkBenPtdTaxAmountCellRenderer, checkBenPtdTaxAmountTooltip } from "../viewPayrollUtils";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

const Benefits: React.FC = () => {
  const { payment, tm, editing, payroll } = React.useContext(PaymentContext);
  const noBenefits = payroll?.check_payroll.off_cycle_options
    ? !payroll.check_payroll.off_cycle_options.apply_benefits
    : false;

  const benefits = useMemo(() => {
    if (editing) return null;

    return getBenefitsListWithPrgFringesBrokenOut(payment);
  }, [payment]);

  const columns: ColumnConfig<UnifiedPrgFringeBenefit>[] = [
    { field: "type", headerName: "Type", dataType: "string" },
    { field: "description", headerName: "Description", dataType: "string" },
    {
      field: "employee_contribution",
      headerName: "Employee contribution",
      dataType: "string",
      cellRenderer: (params) => {
        if (!params.data?._id || !params.data?.employee_contribution || !payment) {
          return <span>{params.data?.employee_contribution}</span>;
        }

        return checkBenPtdTaxAmountCellRenderer(
          params.data?._id,
          params.data?.employee_contribution,
          payment
        );
      },

      tooltipValueGetter: (params) => {
        if (!params.data?._id || !params.data?.employee_contribution || !payment) return;

        return checkBenPtdTaxAmountTooltip(params.data?._id, params.data?.employee_contribution, payment);
      },
    },
    { field: "company_contribution", headerName: "Company contribution", dataType: "string" },
    { field: "pre_tax", headerName: "Pre-tax", dataType: "boolean" },
  ];

  const visitProfile = () => {
    window.open(window.location.origin + "/team-members/" + tm!._id, "_blank");
  };

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Benefits</div>
        <div className="flex-1"></div>
      </div>
      {noBenefits ? (
        <div>
          <span>Benefits have been disabled for this payroll.</span>
        </div>
      ) : (
        <div>
          <div>
            <span>
              {
                "Employee benefits contributions are deducted automatically from pay based on benefits settings."
              }
            </span>
            &nbsp;
            <span>{"To view " + tm!.first_name + "'s benefits settings, visit"}</span>&nbsp;
            <span className="blue-link" onClick={visitProfile}>
              their profile
            </span>
            <span>.</span>
          </div>
          {benefits && (
            <TableV2
              id="team-member-benefits-table"
              resource="benefits"
              hideSelectColumn
              hideSearch
              hideSecondaryActions
              hideFooter
              columns={columns}
              data={benefits}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Benefits;
