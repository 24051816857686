import { FlatfileResults } from "@flatfile/react";
import { useActiveCompanyId, useActiveJobs, useWcCodeOptions } from "dashboard/hooks/atom-hooks";
import React, { useMemo } from "react";
import { Notifier } from "ui";
import { keyBy } from "lodash";
import { MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../importer/Importer";
import { CreateActivityParams } from "backend/services/activity-service";

type PrelimActivityImportRow = {
  label: string;
  costCode: string;
  jobCode?: string;
  wcCode?: string;
  default?: string;
};

type Props = {
  onFinish: () => void;
};

export const ActivityImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeJobs = useActiveJobs();
  const wcCodeOptions = useWcCodeOptions();

  const activeJobOptions = useMemo(
    () => activeJobs.filter((j) => j.code).map((j) => ({ label: j.code!, value: j.code! })),
    [activeJobs]
  );

  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/
  const buildActivityParams = (row: PrelimActivityImportRow): CreateActivityParams => {
    if (!activeCompanyId) throw new Error("No active role");

    const { label, costCode, jobCode, default: defaultVal } = row;

    const job = jobCode ? lookupJobCode[jobCode] : null;

    return {
      company: activeCompanyId,
      label,
      job: job?._id,
      cost_code: costCode,
      wc_code: row.wcCode,
      default: defaultVal === "true",
    };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedActivitys = results.validData.map(buildActivityParams);

      const response = await MiterAPI.activities.import({
        clean_inputs: preppedActivitys,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} activities with ${errors} errors.`);
        } else {
          Notifier.success(`Imported ${successes} activities.`);
        }
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the activities.");
    }
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Name",
        type: "string",
        key: "label",
        description: "The name of the activity.",
        validators: [{ validate: "required" }],
      },
      {
        label: "Cost code",
        type: "string",
        key: "costCode",
        description: "Unique identifer for a cost code (must be same in source system and Miter)",
      },
      {
        label: "Job code",
        type: "select",
        key: "jobCode",
        description: "Unique identifer for a job (must be same in source system and Miter)",
        options: activeJobOptions,
      },
      {
        label: "Workers comp code",
        type: "select",
        key: "wcCode",
        description: "Unique identifer for a workers comp code (must be same in source system and Miter)",
        options: wcCodeOptions,
      },
      {
        label: "Default",
        type: "select",
        key: "default",
        description:
          "If true, then this activity will be included by default when a job enables custom activities.",
        options: [
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ],
      },
    ];

    return fieldList;
  }, []);

  return <Importer id="activities" resource="activities" onSave={handleSubmit} fields={fields} />;
};
