import React, { useEffect, useState } from "react";
import { Button, ModalHeader, TextWithTooltip } from "ui";
import { ClickAwayListener } from "@material-ui/core";
import styles from "./Expenses.module.css";
import { addressToString, cx } from "dashboard/utils";
import { Address, ExpenseCard, MiterAPI, Stripe } from "dashboard/miter";
import ExpenseCardSpendingLimitsForm, {
  useSpendingLimitsForm,
  ValidFormValues,
} from "./ExpenseCardsSpendingLimitsForm";
import Notifier from "dashboard/utils/notifier";
import { noop } from "lodash";
import { DateTime } from "luxon"; // cspell:disable-line;
import { convertStripeAddressToMiterAddress } from "dashboard/utils/addressUtils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import Banner from "dashboard/components/shared/Banner";

type Props = {
  onHide: () => void;
  onSuccess: () => void;
  card: ExpenseCard;
};

const ExpenseCardModal = ({ onHide, onSuccess, card }: Props): React.ReactElement => {
  const { can, cannot } = useMiterAbilities();

  const {
    _id,
    card: { last4, status, exp_month, exp_year, shipping, replacement_for },
    cardholder: {
      name,
      type,
      email,
      phone_number,
      status: cardholderStatus,
      billing: { address: stripeAddress },
      spending_controls,
    },
    replaced_by,
  } = card;

  const address: Address = convertStripeAddressToMiterAddress(stripeAddress as Stripe.Address);

  const [isSaving, setIsSaving] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [replacedBy, setReplacedBy] = useState<ExpenseCard | null>(null);
  const [replacementFor, setReplacementFor] = useState<ExpenseCard | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultValues = spending_controls?.spending_limits?.length ? (spending_controls as any) : undefined;
  const form = useSpendingLimitsForm(defaultValues);

  const fetchReplacementData = async (): Promise<void> => {
    // we could Promise.all these but it's not that likely a card is both a replacement and replaced by another - ok to parallelize
    if (replaced_by) {
      const replacedByCard = await MiterAPI.expense_cards.retrieve(replaced_by);
      if (!replacedByCard.error) {
        setReplacedBy(replacedByCard);
      }
    }

    if (replacement_for) {
      const replacementForCard = await MiterAPI.expense_cards.list({
        filter: [{ field: "card.id", value: replacement_for }],
      });

      if (!replacementForCard.error) {
        setReplacementFor(replacementForCard[0] || null);
      }
    }
  };

  useEffect(() => {
    fetchReplacementData();
  }, [replaced_by, replacement_for]);

  const onSubmit = async ({ spending_limits }: ValidFormValues) => {
    setIsSaving(true);
    const result = await MiterAPI.expense_cards
      .update(_id, {
        spending_controls: {
          ...spending_controls,
          spending_limits_currency: "usd",
          spending_limits,
        },
      })
      .catch((error) => {
        return { error };
      });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: Stripe.StripeRawError = (result as any)?.error;
    if (!error) {
      Notifier.success("Updated spending limits.");
      onSuccess();
      setIsSaving(false);
      return onHide();
    }
    setIsSaving(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const message = error.message || "";
    if (error.param) {
      // Turn the Stripe error into a form error.
      // The regex turn "spending_controls[spending_limits][0][amount]"
      // into "spending_limits[0].amount", which is how the field is named.
      const name = error.param.replace(/^spending_controls\[(\w+)\]/, "$1").replace(/\[([a-z_]+)\]/g, ".$1");
      form.setError(name, { type: "manual", message });
    } else {
      Notifier.error(message);
    }
  };

  const updateStatus = async (status: "active" | "inactive") => {
    setIsUpdatingStatus(true);

    const result = await MiterAPI.expense_cards.update(_id, { status }).catch((error) => ({ error }));

    if ("error" in result) {
      setIsSaving(false);
      console.error(result.error);
      Notifier.error("Could not update card status. Please try again later.");
      return;
    }

    if (status === "inactive") {
      Notifier.success("Card locked.");
    } else if (status === "active") {
      Notifier.success("Card activated.");
    }

    onSuccess();
    setIsUpdatingStatus(false);
    onHide();
  };

  // TODO: only allow card lock/unlock actions if card has been delivered
  const shouldShowActivateButton =
    (cardholderStatus === "inactive" || status === "inactive") && can("miter_cards:update");

  let bannerMsg = "";
  if (shouldShowActivateButton && replacementFor && replacementFor?.card.status !== "canceled") {
    bannerMsg = `Activating will cancel the card ending in ${replacementFor?.card?.last4}.`;
  }

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={isReplaceModalOpen ? noop : onHide}>
        <div className="modal-wrapper form">
          <ModalHeader heading={`Expense Card *${last4}`} onHide={onHide} />
          {bannerMsg && <Banner content={bannerMsg} type="warning" />}
          <div id={styles.modal_body_height} className="modal-body form">
            <h4 className={styles.margin_bottom_8}>Card information</h4>

            <div className="info-row">
              <div>Last 4</div>
              <TextWithTooltip
                id="cvc-tooltip"
                place="left"
                text={`*${last4}`}
                className={"tooltip " + styles.width_380}
                tooltip={`For security reasons, we don’t expose card numbers or CVC numbers within Miter. You can access sensitive card information on the physical card.`}
              />
            </div>

            <div className="info-row">
              <div>Expires</div>
              <div>
                {exp_month}/{exp_year}
              </div>
            </div>

            <div className="info-row">
              <div>Status</div>
              <div className={styles.capitalize}>{status}</div>
            </div>

            {status === "inactive" && shipping && (
              <>
                <div className="info-row">
                  <div>Shipping Status</div>
                  <div className={styles.capitalize}>{shipping.status}</div>
                </div>
                {shipping.eta && (
                  <div className="info-row">
                    <div>Estimated Arrival</div>
                    <div>
                      {DateTime.fromSeconds(shipping.eta).toLocaleString({
                        weekday: "short",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    </div>
                  </div>
                )}

                {shipping.tracking_url && (
                  <div className="info-row">
                    <div></div>
                    <div>
                      <a href={shipping.tracking_url} target="_blank" rel="noreferrer">
                        Tracking Link
                      </a>
                    </div>
                  </div>
                )}
              </>
            )}

            {replacedBy && (
              <div className="info-row">
                <div>Replaced By</div>
                <div>{`Card ending in ${replacedBy.card?.last4}`}</div>
              </div>
            )}

            <h4 className={styles.margin_bottom_8}>Cardholder information</h4>

            <div className="info-row">
              <div>Name</div>
              <div>{name}</div>
            </div>

            <div className="info-row">
              <div>Type</div>
              <div className={styles.capitalize}>{type}</div>
            </div>

            <div className="info-row">
              <div>Status</div>
              <div className={styles.capitalize}>{cardholderStatus}</div>
            </div>

            {email && (
              <div className="info-row">
                <div>Email</div>
                <TextWithTooltip
                  id="expense-email-tooltip"
                  place="left"
                  text={email}
                  className={"tooltip " + styles.width_380}
                  tooltip={`This may be used to allow ${name} to set up a digital wallet like Apple Pay or Google Pay.`}
                />
              </div>
            )}

            {phone_number && (
              <div className="info-row">
                <div>Phone number</div>
                <TextWithTooltip
                  id="expense-email-tooltip"
                  place="left"
                  text={phone_number}
                  className={"tooltip " + styles.width_380}
                  tooltip={`This may be used to allow ${name} to set up a digital wallet like Apple Pay or Google Pay.`}
                />
              </div>
            )}

            <div className="info-row">
              <div>Address</div>
              <div className={styles.white_space_pre_line} style={{ textAlign: "right" }}>
                {addressToString(address)}
              </div>
            </div>

            <h4 className={styles.margin_bottom_8}>Spending controls</h4>

            <ExpenseCardSpendingLimitsForm form={form} readonly={cannot("miter_cards:update")} />

            <div className="vertical-spacer" />
          </div>

          <div className={`modal-footer form ${styles.justify_content_space_between}`}>
            {status === "canceled" ? (
              <em className={`${styles.small_text_on_white} ${styles.margin_auto}`}>
                This card is canceled and cannot be updated.
              </em>
            ) : (
              <>
                <div className="flex">
                  {shouldShowActivateButton ? (
                    <Button
                      className="button-1"
                      loading={isUpdatingStatus}
                      onClick={() => updateStatus("active")}
                    >
                      Activate
                    </Button>
                  ) : (
                    <TextWithTooltip
                      id="lock-tooltip"
                      className={"tooltip " + styles.width_380}
                      tooltip={
                        <span>
                          Immediately locks a card; all future authorizations will be declined.
                          <br />
                          You can unlock the card at any time.
                        </span>
                      }
                      place="top"
                    >
                      {can("miter_cards:update") && (
                        <Button
                          className="button-1"
                          loading={isUpdatingStatus}
                          onClick={() => updateStatus("inactive")}
                        >
                          Lock
                        </Button>
                      )}
                    </TextWithTooltip>
                  )}

                  {!replaced_by && can("miter_cards:update") && (
                    <Button className="button-1" onClick={() => setIsReplaceModalOpen(true)}>
                      Replace
                    </Button>
                  )}
                </div>

                {can("miter_cards:update") && (
                  <Button className="button-2" loading={isSaving} onClick={form.handleSubmit(onSubmit)}>
                    Save
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>

      {isReplaceModalOpen && (
        <ReplaceCardModal
          expenseCard={card}
          onHide={() => setIsReplaceModalOpen(false)}
          onSave={() => {
            onSuccess();
            onHide();
          }}
        />
      )}
    </div>
  );
};
export default ExpenseCardModal;

type ReplacementReason = Stripe.Issuing.Card.ReplacementReason;

const ReplaceCardModal = ({
  expenseCard,
  onHide,
  onSave,
}: {
  expenseCard: ExpenseCard;
  onHide: () => void;
  onSave: () => void;
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const [reason, setReason] = useState<ReplacementReason>("expired");

  const onReplacementSubmit = async () => {
    setIsSaving(true);
    const result = await MiterAPI.expense_cards
      .update(expenseCard._id, { replacement_reason: reason })
      .catch((error) => ({ error }));

    if ("error" in result) {
      setIsSaving(false);
      console.error(result.error);
      Notifier.error("Could not update card information. Please try again later.");
      return;
    }
    Notifier.success(
      "Replacement card created. Tracking information will be available once the card is printed and shipped."
    );
    onSave();
    setIsSaving(false);
    onHide();
  };

  const cancelationNote =
    reason === "damaged" || reason === "expired"
      ? `You can continue using this card until the replacement arrives and is activated.`
      : `The current card will be canceled shortly.`;
  return (
    <div className="modal-background">
      <div className="modal-wrapper form">
        <ModalHeader heading="Replace Card" onHide={onHide} />

        <div className="modal-body form" id={styles.modal_body_height}>
          <h4>Why do you need to replace this card?</h4>

          <div className="flex">
            <Button
              className={cx(reason === "lost" ? "button-2" : "button-1")}
              onClick={() => setReason("lost")}
            >
              Lost
            </Button>
            <Button
              className={cx(reason === "stolen" ? "button-2" : "button-1")}
              onClick={() => setReason("stolen")}
            >
              Stolen
            </Button>
            {expenseCard.card.type === "physical" && (
              <Button
                className={cx(reason === "damaged" ? "button-2" : "button-1")}
                onClick={() => setReason("damaged")}
              >
                Damaged
              </Button>
            )}
            <Button
              className={cx(reason === "expired" ? "button-2" : "button-1")}
              onClick={() => setReason("expired")}
            >
              Expired
            </Button>
          </div>

          <p className={styles.small_text_on_white}>{cancelationNote}</p>
        </div>

        <div className={`modal-footer form ${styles.justify_content_space_between}`}>
          <Button
            className="button-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onHide();
            }}
          >
            Cancel
          </Button>

          <Button className="button-2" loading={isSaving} disabled={!reason} onClick={onReplacementSubmit}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
