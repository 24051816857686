import styles from "../TeamMemberChecklistBuilder.module.css";

import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { WithholdingsOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const WithholdingsTaskContent: React.FC<ChecklistTaskContentProps<WithholdingsOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  return (
    <div className={styles["team-member-checklist-task-content"]}>
      Require this employee to fill out federal and state withholding forms.
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
