import React from "react";
import { ActionModal, LoadingModal, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { AggregatedPayroll } from "dashboard/miter";
import { Sage300JcdWarning } from "backend/services/sage300/sage300-types";

// Use this type as a return type from your hook
export type ExportModalReturnType<T> = {
  loading: boolean;
  build: (p: { earnings: T[]; forceDownload?: boolean; draftPayroll?: AggregatedPayroll }) => Promise<void>;
  warnings: Sage300JcdWarning[] | undefined;
  setWarnings: (warnings: Sage300JcdWarning[] | undefined) => void;
  downloadSage300ExportFile: (jcd: string, fileName: string) => void;
  data: string | undefined;
  timeEntriesFileName: string;
};

const columns: ColumnConfig<Sage300JcdWarning>[] = [
  {
    headerName: "Job",
    field: "jobCode",
    dataType: "string",
    minWidth: 25,
    width: 120,
  },
  {
    headerName: "Cost code",
    field: "costCode",
    dataType: "string",
    minWidth: 25,
    width: 120,
  },
  {
    headerName: "Category",
    field: "categoryCode",
    dataType: "string",
    minWidth: 25,
    width: 120,
  },
  {
    headerName: "Line Description",
    field: "desc",
    dataType: "string",
    minWidth: 25,
    width: 225,
  },
  {
    headerName: "Message",
    field: "message",
    dataType: "string",
    width: 500,
  },
];

interface ExportWarningsModalProps {
  downloadSage300ExportFile: (data: string, fileName: string) => void;
  warnings: Sage300JcdWarning[] | undefined;
  setWarnings: (warnings: Sage300JcdWarning[] | undefined) => void;
  data: string | undefined;
  loading: boolean;
  fileName: string;
  headerText?: string;
}

export const ExportWarningsModal: React.FC<ExportWarningsModalProps> = ({
  downloadSage300ExportFile,
  warnings,
  setWarnings,
  data,
  loading,
  fileName,
  headerText,
}) => {
  if (loading) return <LoadingModal text="Building the export..." />;
  if (!warnings || !data) return null;

  const onSubmit = () => {
    downloadSage300ExportFile(data, fileName);
    setWarnings(undefined);
  };

  return (
    <ActionModal
      headerText={headerText ?? "Sage 300 Export Warnings"}
      onHide={() => setWarnings(undefined)}
      onSubmit={onSubmit}
      submitText={"Download Export"}
      showSubmit
      onCancel={() => setWarnings(undefined)}
      showCancel
      cancelText={"Close"}
      wrapperStyle={{ width: "50%", minWidth: 700 }}
      bodyStyle={{ overflow: "hidden" }}
    >
      <TableV2
        data={warnings}
        columns={columns}
        resource="warnings"
        id="sage-300-warnings"
        gridWrapperStyle={{ height: "380px" }}
      />
    </ActionModal>
  );
};
