import React, { useEffect, useMemo, useState } from "react";

import { ConfirmModal, LargeModal, usdString } from "ui";
import PayrollContext from "./payrollContext";

import { createObjectMap, createObjectMapToArray, Notifier } from "dashboard/utils";
import {
  AggregatedEmployeeBenefit,
  CheckBenefit,
  CheckBenefitOverride,
  CheckPostTaxDeductionOverride,
  MiterAPI,
  MiterFilterField,
  PostTaxDeduction,
  TeamMember,
} from "dashboard/miter";

import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

import { TableActionLink, TableV2, ColumnConfig } from "ui/table-v2/Table";
import { usePayScheduleAccessor } from "dashboard/hooks/usePayScheduleAccessor";
import { benefitMap, getBenefitDescription } from "dashboard/pages/benefits/benefitsUtils";
import { PayrollAdjustment } from "../payrollTypes";
import { cloneDeep, isEmpty } from "lodash";
import { CheckPostTaxDeduction } from "backend/utils/check/check-types";
import { PayrollOverridesImporter } from "./PayrollOverridesImporter";
import { ImportResultModal } from "dashboard/components/importer/ImportHistory";

export type TmOverrideInfo = {
  tmId: string;
  teamMember: TeamMember;
  adjustment?: PayrollAdjustment;
  benefitOverridesLookup: Record<string, CheckBenefitOverride>;
  deductionOverridesLookup: Record<string, CheckPostTaxDeductionOverride>;
};

type OverrideTableEntry = {
  _id: string;
  tmId: string;
  employeeName: string;
  employeeFriendlyId: string;
  companyContribution?: string | null;
  employeeContribution?: string | null;
  overrideType: "Post-tax deduction" | "Benefit";
  overrideSubType: string;
  benefitOverride?: CheckBenefitOverride;
  ptdOverride?: CheckPostTaxDeductionOverride;
  label: string;
};

type Props = {
  onFinish: () => void;
  editing: boolean;
};

export const BulkPayrollOverrides: React.FC<Props> = ({ onFinish, editing }) => {
  const payrollContext = React.useContext(PayrollContext);
  const { payroll, recalculatePayroll, isLoading, getPayroll } = payrollContext;
  const companyId = useActiveCompanyId();

  // State
  const [selected, setSelected] = useState<OverrideTableEntry[]>([]);
  const [hasUnsavedChanged, setHasUnsavedChanged] = useState<boolean>(false);

  const [exitConfirmation, setExitConfirmation] = useState(false);
  const [importResults, setImportResults] = useState(null);

  const [employeeBenefits, setEmployeeBenefits] = useState<AggregatedEmployeeBenefit[]>([]);
  const [ptds, setPtds] = useState<PostTaxDeduction[]>([]);
  const [dataLoading, setDataLoading] = useState(false);

  const { canAccessTeamMember } = usePayScheduleAccessor();

  const payrollTeamMemberOverridesInfo = useMemo(() => {
    const adjustments: TmOverrideInfo[] =
      payroll?.miter_payments
        .filter((mp) => mp.onboarded && canAccessTeamMember(mp.team_member))
        .map((mp) => {
          const tmId = mp.team_member._id;
          const tmAdjustment = mp.adjustment;

          const tmOverrideInfo: TmOverrideInfo = {
            tmId: tmId,
            teamMember: mp.team_member,
            adjustment: tmAdjustment,
            benefitOverridesLookup:
              createObjectMap(tmAdjustment?.benefit_overrides || [], (override) => override.benefit) || {},
            deductionOverridesLookup:
              createObjectMap(
                tmAdjustment?.post_tax_deduction_overrides || [],
                (override) => override.post_tax_deduction
              ) || {},
          };
          return tmOverrideInfo;
        }) || [];

    return createObjectMap(adjustments, (adjustment) => adjustment.tmId);
  }, [payroll, canAccessTeamMember]);

  const getData = async () => {
    try {
      setDataLoading(true);
      const tmIds = Object.keys(payrollTeamMemberOverridesInfo) || [];
      const filter: MiterFilterField[] = [
        { field: "company", value: companyId! },
        {
          field: "employee",
          type: "string",
          comparisonType: "in",
          value: tmIds,
        },
      ];

      const applyBenefits =
        tmIds.length > 0 &&
        (payroll?.type === "regular" || !!payroll?.check_payroll.off_cycle_options?.apply_benefits);
      const applyPtds =
        tmIds.length > 0 &&
        (payroll?.type === "regular" ||
          !!payroll?.check_payroll.off_cycle_options?.apply_post_tax_deductions);

      const [benefits, ptds] = await Promise.all([
        applyBenefits
          ? MiterAPI.benefits.employee.search(filter)
          : Promise.resolve([] as unknown as ReturnType<typeof MiterAPI.benefits.employee.search>),
        applyPtds
          ? MiterAPI.post_tax_deductions.retrieve_many({ filter })
          : Promise.resolve([] as unknown as ReturnType<typeof MiterAPI.post_tax_deductions.retrieve_many>),
      ]);

      if (benefits.error) throw new Error(benefits.error);
      if (ptds.error) throw new Error(ptds.error);

      // filter by benefits/deductions that can actually  be overriden
      const payday = payroll?.check_payroll.payday as string | undefined;
      const validBenefits = benefits.filter((b) => {
        if (!payday) return false;
        const checkBen = b.check_benefit as CheckBenefit;
        if (checkBen.effective_start && checkBen.effective_start > payday) return false;
        if (checkBen.effective_end && checkBen.effective_end < payday) return false;
        return true;
      });
      const validPtds = ptds.filter((ptd) => {
        if (!payday) return false;

        const checkPtd = ptd.check_post_tax_deduction as CheckPostTaxDeduction;
        if (checkPtd.type === "child_support") return false;
        if (checkPtd.effective_start && checkPtd.effective_start > payday) return false;
        if (checkPtd.effective_end && checkPtd.effective_end < payday) return false;
        if (checkPtd.metadata?.fringe_group_id) return false;
        return true;
      });

      setEmployeeBenefits(validBenefits);
      setPtds(validPtds);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error loading override data. We're looking into it!");
    }
    setDataLoading(false);
  };
  const tableData = useMemo(() => {
    const overrideData: OverrideTableEntry[] = [];
    // get benefit overrides
    employeeBenefits?.forEach((benefit) => {
      const employeeAdjustment = payrollTeamMemberOverridesInfo[benefit.employee._id]!;
      const override = employeeAdjustment?.benefitOverridesLookup[benefit.check_id];
      const description = getBenefitDescription(benefit);

      overrideData.push({
        employeeName: benefit.employee.full_name,
        tmId: employeeAdjustment?.tmId,
        employeeFriendlyId: benefit.employee.friendly_id || "",
        _id: benefit.check_id,
        overrideType: "Benefit",
        overrideSubType: benefitMap[benefit.check_benefit?.benefit].label,
        label: description,
        companyContribution: override?.company_contribution_amount,
        employeeContribution: override?.employee_contribution_amount,
        benefitOverride: override,
      });
    });

    // Get PTD overrides
    ptds?.forEach((ptd) => {
      const employeeAdjustment = payrollTeamMemberOverridesInfo[ptd.employee]!;
      const override = employeeAdjustment?.deductionOverridesLookup[ptd.check_id];

      overrideData.push({
        tmId: employeeAdjustment?.tmId,
        employeeName: employeeAdjustment?.teamMember.full_name || "",
        employeeFriendlyId: employeeAdjustment?.teamMember.friendly_id || "",
        _id: ptd.check_id,
        label: ptd?.check_post_tax_deduction.description,
        employeeContribution: override?.amount,
        overrideType: "Post-tax deduction",
        overrideSubType:
          ptd.check_post_tax_deduction?.type === "child_support" ? "Child support" : "Miscellaneous",
        ptdOverride: override,
      });
    });

    return overrideData;
  }, [employeeBenefits, ptds, payrollTeamMemberOverridesInfo]);

  const validateEmployeeAmt = (value: string) => {
    if (value && (isNaN(Number(value)) || Number(value) < 0))
      return "Amount must be a non-negative number or empty";
    return true;
  };

  const validateCompanyAmt = (value: string) => {
    if (value && (isNaN(Number(value)) || Number(value) < 0))
      return "Amount must be a non-negative number or empty";
    return true;
  };

  const formatAmount = (value?: string | null) => {
    // if its an empty string, null or undefined return 'Default'
    if (isEmpty(value)) return "Default";
    // if not empty
    // if its a number return the number formatted with $ sign
    // if not a number just return the value and validations will error out
    if (!isNaN(Number(value))) {
      return usdString(value);
    }
    return value!;
  };

  const columns: ColumnConfig<OverrideTableEntry>[] = [
    { field: "employeeName", headerName: "Employee", dataType: "string" },
    { field: "employeeFriendlyId", headerName: "Employee ID", dataType: "string" },
    { field: "label", headerName: "Label", dataType: "string" },
    { field: "overrideType", headerName: "Type", dataType: "string" },
    { field: "overrideSubType", headerName: "Sub Type", dataType: "string" },
    {
      field: "employeeContribution",
      headerName: "Employee amount",
      editable: editing,
      dataType: "string",
      editorType: "text",
      valueFormatter: (params) => {
        const value = params.data?.employeeContribution;
        return formatAmount(value);
      },
      validations: (value) => validateEmployeeAmt(value),
    },
    {
      field: "companyContribution",
      headerName: "Company amount",
      editable: (params) => {
        return editing && params?.data?.overrideType === "Benefit";
      },
      dataType: "string",
      editorType: "text",
      valueFormatter: (params) => {
        if (params?.data?.overrideType === "Benefit") {
          const value = params.data?.companyContribution;
          return formatAmount(value);
        }
        return "N/A";
      },
      validations: (value) => validateCompanyAmt(value),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const saveData = async (params: OverrideTableEntry[]) => {
    const existingAdjustments: PayrollAdjustment[] = cloneDeep(payroll?.adjustments || []);
    const existingAdjustmentsLookup = createObjectMap(existingAdjustments, (a) => a.team_member);

    try {
      const overrideEditsMap = createObjectMapToArray(params || [], (row) => row.tmId);

      Object.keys(overrideEditsMap).forEach((tmWithOverridesId) => {
        if (!payrollTeamMemberOverridesInfo[tmWithOverridesId]) {
          throw new Error("Can't find team member: " + tmWithOverridesId);
        }

        const currentAdjustment = payrollTeamMemberOverridesInfo[tmWithOverridesId]?.adjustment || {
          team_member: tmWithOverridesId,
        };

        // Cloning deep because I will be changing these in place
        // If recalc fails we don't want to keep these changes that happened in place
        const currBenefitOverridesLookup = cloneDeep(
          payrollTeamMemberOverridesInfo[tmWithOverridesId]!.benefitOverridesLookup
        );
        const currPtdOverridesLookup = cloneDeep(
          payrollTeamMemberOverridesInfo[tmWithOverridesId]!.deductionOverridesLookup
        );

        overrideEditsMap[tmWithOverridesId]?.forEach((override) => {
          if (override.overrideType === "Benefit") {
            // if there was already an exisitng override
            // and if the user is trying to remove the override, delete it
            if (
              override.benefitOverride &&
              isEmpty(override.companyContribution) &&
              isEmpty(override.employeeContribution)
            ) {
              delete currBenefitOverridesLookup![override._id];
            }

            if (
              // Make sure there's an actual change before adding it
              !(isEmpty(override.companyContribution) && isEmpty(override.employeeContribution))
            ) {
              currBenefitOverridesLookup[override._id] = {
                benefit: override._id, // is empty is to make sure we don't send an empty string
                employee_contribution_amount: !isEmpty(override.employeeContribution)
                  ? override.employeeContribution!
                  : null,
                company_contribution_amount: !isEmpty(override.companyContribution)
                  ? override.companyContribution!
                  : null,
              };
            }
          } else if (override.overrideType === "Post-tax deduction") {
            // if there was already an exisitng override
            // and if the user is trying to remove the override delete it
            if (override.ptdOverride && isEmpty(override.employeeContribution)) {
              delete currPtdOverridesLookup![override._id];
              return;
            }
            // Make sure there's an actual value before adding the override
            if (!isEmpty(override.employeeContribution)) {
              currPtdOverridesLookup[override._id] = {
                post_tax_deduction: override._id,
                amount: override.employeeContribution!,
              };
            }
          }
        });

        existingAdjustmentsLookup[tmWithOverridesId] = {
          ...currentAdjustment,
          benefit_overrides: Object.values(currBenefitOverridesLookup) || [],
          post_tax_deduction_overrides: Object.values(currPtdOverridesLookup) || [],
        };
      });

      const adjustments = Object.values(existingAdjustmentsLookup);
      const teamMembers = adjustments.map((ad) => ad.team_member);
      if (adjustments.length > 0) {
        await recalculatePayroll({ adjustments, tms: teamMembers });
      }
      Notifier.success("Successfully saved payroll overrides");
      setSelected([]);
      return { successes: [], errors: [] };
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
  };

  const handleResetToDefault = () => {
    selected.forEach((row) => {
      row.companyContribution = null;
      row.employeeContribution = null;
    });

    saveData(selected);
  };

  const editedRowsChanged = (editedRows: string[]) => {
    if (editedRows.length > 0) {
      setHasUnsavedChanged(true);
    } else {
      setHasUnsavedChanged(false);
    }
  };

  const importFinished = async (results) => {
    setImportResults(results);
    await getPayroll();
  };

  const showBulkImportButton = () => {
    if (!editing) return false;
    if (ptds?.length === 0 && employeeBenefits?.length === 0) return false;
    if (selected.length > 0) return false;
    return true;
  };

  const staticActions = useMemo(() => {
    return [
      {
        key: "import",
        component: (
          <PayrollOverridesImporter
            ptds={ptds || []}
            employeeBenefits={employeeBenefits || []}
            onFinish={importFinished}
          />
        ),
        important: true,
        showInEditMode: true,
        shouldShow: showBulkImportButton,
      },
    ];
  }, [ptds, employeeBenefits, setPtds, setEmployeeBenefits]);

  const dynamicActions: TableActionLink[] = [
    {
      label: "Reset to default and save",
      className: "button-1 table-button",
      action: () => handleResetToDefault(),
      showInEditMode: true,
      style: { marginRight: -7 },
    },
  ];

  return (
    <>
      <LargeModal
        headerText={"Overrides"}
        beta={true}
        onClose={() => (editing && hasUnsavedChanged ? setExitConfirmation(true) : onFinish())}
      >
        {importResults && <ImportResultModal result={importResults} onClose={() => setImportResults(null)} />}
        <TableV2
          id={"payroll-overrides-table"}
          onSave={saveData}
          resource="payroll overrides"
          data={tableData}
          columns={columns}
          dynamicActions={dynamicActions}
          staticActions={staticActions}
          editable={editing && !isLoading}
          alwaysEditable={editing}
          isLoading={isLoading || dataLoading}
          onSelect={setSelected}
          onEditedNodesChanged={editedRowsChanged}
          defaultSelectedRows={selected}
          gridWrapperStyle={{ height: "60vh" }}
          containerStyle={{ paddingTop: 0 }}
          paginationPageSize={500}
        />
        {exitConfirmation && (
          <ConfirmModal
            title={"Are you sure you want to exit?"}
            body={"You will lose any unsaved changes"}
            onYes={() => onFinish()}
            onNo={() => setExitConfirmation(false)}
          />
        )}
      </LargeModal>
    </>
  );
};
