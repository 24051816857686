import { MiterAPI, StripeAccountResponse } from "dashboard/miter";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { Notifier } from "ui";
import { externalRedirect } from "./utils";
import { PlaidProducts } from "backend/controllers/plaid-controller";
import { ExternalFinancialAccountType } from "backend/utils/plaid";

/** Get the token required to open Plaid link */
export const getPlaidLinkToken = async (params: {
  company: string | null;
  external_financial_account_type: ExternalFinancialAccountType;
  updateMode?: boolean;
  itemId?: string;
  setLoading: (loading: boolean) => void;
  setPlaidLinkToken: (token: string) => void;
  products: PlaidProducts[];
}): Promise<void> => {
  const {
    company,
    external_financial_account_type,
    updateMode,
    itemId,
    setLoading,
    setPlaidLinkToken,
    products,
  } = params;
  if (!company) return;
  setLoading(true);
  try {
    const res = await MiterAPI.banking.plaid.retrieve_token({
      company: company,
      external_financial_account_type,
      products,
      update_mode: updateMode,
      item_id: itemId,
      link_customization_name: "multi_select",
    });

    if (res.error) throw new Error(res.error);
    setPlaidLinkToken(res.link_token);
  } catch (e: $TSFixMe) {
    console.error(e.message);
    Notifier.error(e.message);
  }
  setLoading(false);
};

/** Callback that connects a company's plaid card account into Miter */
export const connectExternalCards = async (params: {
  company: string | undefined;
  public_token: string;
  metadata: PlaidLinkOnSuccessMetadata;
  updateMode: boolean;
  callback?: () => void;
}): Promise<void> => {
  const { company, public_token, metadata, updateMode, callback } = params;

  if (!company) return;
  try {
    const res = await MiterAPI.banking.plaid.connect_cards({
      company,
      external_financial_account_type: "company",
      public_token,
      update_mode: updateMode,
      metadata,
    });
    if (res.error) throw new Error(res.error);

    if (res.newlyConnectedCards.length) {
      Notifier.success(
        `Successfully connected ${res.newlyConnectedCards.length} cards from ${res.financialInstitutionName}`
      );
    }
    callback?.();
  } catch (e: $TSFixMe) {
    console.error(e.message);
    Notifier.error(e.message);
  }
};

/** Gets the process started for creating a stripe account for the current company */
export const initializeStripeAccount = async (params: {
  company?: string;
  source?: string;
  account?: StripeAccountResponse | null;
  setLoading: (loading: boolean) => void;
}): Promise<void> => {
  const { company, source, setLoading, account } = params;
  if (!company || !source) return;

  setLoading(true);

  let finalAccount = account;

  if (!finalAccount) {
    finalAccount = await MiterAPI.stripe_accounts.create({ company, source }).catch((e) => {
      console.error(e);
      Notifier.error("Could not create account");
      return undefined;
    });
  }

  if (finalAccount?.account_onboarding_link) {
    externalRedirect(null, finalAccount.account_onboarding_link);
  }

  setLoading(false);
};
