import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "ui/notifier";
import { Button, Formblock, WizardScreen } from "ui";
import { saveAs } from "file-saver";

import styles from "../I9Wizard.module.css";

import * as vals from "dashboard/utils/validators";

import { AggregatedI9, ESignatureItem } from "dashboard/miter";
import useWizard from "ui/modal/useWizard";
import { getOS } from "miter-utils";
import { CheckCircle } from "phosphor-react";
import { ESignatureInputValue } from "ui/form/ESignatureInput";
import { DateTime } from "luxon";
import { useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";

type ReviewAndSignFormFields = {
  additional_information: string;
  employer_esignature_id: string;
  employer_esignature?: ESignatureInputValue;
  employer_first_name?: string;
  employer_last_name?: string;
  employer_title?: string;
  alternative_document_examination: boolean;
};

type Props = {
  I9: AggregatedI9;
  setI9: (I9: AggregatedI9) => void;
  name: "Sign and Submit";
};

const SignAndSubmitForm: React.FC<Props> = ({ I9, setI9, name = "Review and Sign" }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const { setCanNext, setNextButtonText, handleExit } = useWizard();
  const [downloadingPreview, setDownloadingPreview] = useState(false);
  const user = useUser();
  const form = useForm<ReviewAndSignFormFields>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
    defaultValues: {
      employer_esignature_id: I9?.section_2.employer_esignature_id,
      additional_information: I9?.section_2.additional_information,
      employer_first_name: I9?.section_2.employer_first_name || user?.first_name,
      employer_last_name: I9?.section_2.employer_last_name || user?.last_name,
      employer_title: I9?.section_2.employer_title,
      alternative_document_examination: !!I9?.section_2.alternative_document_examination,
    },
  });

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, watch, formState, errors } = form;
  const { dirtyFields, isValid } = formState;

  const formData = watch();

  /*********************************************************
   * useEffect's
   * - Trigger form validation on change
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    setNextButtonText("Complete");
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveI9)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    handleExit();
  };

  /*********************************************************
   *  Backend submission + cleaning functions
   **********************************************************/
  const buildI9Params = async (params: ReviewAndSignFormFields) => {
    // Final validations (needed for typescript)
    if (!params.employer_first_name || !params.employer_last_name || !params.employer_title) {
      throw new Error("Please enter your first name, last name, and title");
    }

    // Save e-signature
    const esignature = await saveESignature(params);

    // Save the update to the current users first / last name
    if (dirtyFields.employer_first_name || dirtyFields.employer_last_name) {
      await updateUser(params);
    }

    return {
      "section_2.employer_esignature_id": esignature._id,
      "section_2.employer_esignature_date": DateTime.fromSeconds(esignature.created_at).toISODate(),
      "section_2.employer_first_name": params.employer_first_name,
      "section_2.employer_last_name": params.employer_last_name,
      "section_2.employer_title": params.employer_title,
      "section_2.additional_information": params.additional_information,
      "section_2.alternative_document_examination": params.alternative_document_examination,
      employer_completion_date: DateTime.local().toISODate(),
    };
  };

  const saveESignature = async (params: ReviewAndSignFormFields): Promise<ESignatureItem> => {
    if ((!params.employer_esignature || !params.employer_esignature.data) && !params.employer_esignature_id) {
      throw new Error("Signature is required");
    }

    if (params.employer_esignature_id) {
      const retrievedEsigItem = await MiterAPI.esignature_items.retrieve(params.employer_esignature_id);
      if (retrievedEsigItem.error) throw new Error(retrievedEsigItem.error);
      return retrievedEsigItem;
    }

    const esignParams = {
      company_id: activeCompanyId,
      document_type: "i9",
      parent_document_id: I9._id,
      signature: {
        image: params!.employer_esignature!.data,
        device: { type: "desktop", os: getOS() },
        application: { name: "team-portal" },
      },
      signer: {
        name: user?.first_name + " " + user?.last_name,
        type: "admin",
        title: "Company Admin",
        user_id: user!._id,
      },
    };

    // @ts-expect-error fix me
    const res = await MiterAPI.esignature_items.signatures.create([esignParams]);
    if (res.error) throw new Error(res.error);
    if (!res || !res[0]) throw new Error("Error saving employee signature");

    return res[0];
  };

  const updateUser = async (params: ReviewAndSignFormFields) => {
    if (!user) return;

    const res = await MiterAPI.users.update(user?._id, {
      first_name: params.employer_first_name,
      last_name: params.employer_last_name,
    });

    if (res.error) throw new Error(res.error);
  };

  const saveI9 = async (data: ReviewAndSignFormFields) => {
    try {
      const params = await buildI9Params(data);

      // Update I-9
      const res = await MiterAPI.i_9s.complete(I9._id, params);
      if (res.error) throw new Error(res.error);

      setI9(res);
    } catch (e: $TSFixMe) {
      console.log("Error submitting I-9 personal information form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   *  Handlers
   **********************************************************/
  const handlePreviewClick = async () => {
    setDownloadingPreview(true);
    try {
      const res = await MiterAPI.i_9s.retrieve_pdf(I9._id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      saveAs(blob, `I-9 Preview.pdf`);
    } catch (e: $TSFixMe) {
      Notifier.error("Error downloading I-9 preview. Please contact support.");
      console.error("Error previewing I-9", e);
    }
    setDownloadingPreview(false);
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderAdditionalInformationBlock = () => {
    return (
      <div className="form-section">
        <Formblock
          type="paragraph"
          name="additional_information"
          label="Additional Information*"
          form={form}
          editing={true}
          className="modal wizard"
          maxLength={500}
          placeholder="Enter additional information here"
        />
        <Formblock
          type="checkbox"
          name="alternative_document_examination"
          text="Check here if you used an alternative procedure authorized by DHS to examine documents."
          form={form}
          editing={true}
          className="modal wizard"
        />
      </div>
    );
  };

  const renderPreviewBlock = () => {
    return (
      <Button
        className={"button-2"}
        onClick={handlePreviewClick}
        style={{ margin: 0, marginBottom: 30, marginTop: 10, padding: "20px 35px", fontSize: 16 }}
        loading={downloadingPreview}
      >
        Preview I-9
      </Button>
    );
  };

  const renderESignatureBlock = () => {
    // If we already have a employee signature, show a signed block instead
    if (formData.employer_esignature_id) {
      return (
        <div className={styles["employee-esignature-completed"]}>
          <CheckCircle style={{ marginRight: 10, marginBottom: -3 }} color={"#26A96C"} weight="fill" />
          E-Signature completed
        </div>
      );
    }

    return (
      <>
        <div className={styles["employer-disclosure"]}>
          <strong>I attest, under penalty of perjury, that</strong>
          <ol>
            <li>I have examined the document(s) presented by the employee </li>
            <li>
              The employee&apos;s document(s) appear to be genuine and to relate to the employee named, and
            </li>
            <li> To the best of my knowledge the employee is authorized to work in the United States.</li>
          </ol>
        </div>
        <div className="form-section">
          {renderEmployerNameBlock()}
          <Formblock
            type="text"
            name="employer_title"
            label="Title of employer or authorized representative*"
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Enter title"}
            val={vals.required}
          />
        </div>
        <Formblock
          type="esignature"
          name="employer_esignature"
          label="Signature of employor or authorized representative"
          form={form}
          editing={true}
          className="modal wizard"
          rules={{ validate: vals.esignatureRequired }}
          onError={Notifier.error}
        />
      </>
    );
  };

  const renderEmployerNameBlock = () => {
    if (user?.first_name && user?.last_name) return;

    return (
      <>
        <Formblock
          type="text"
          name="employer_first_name"
          label="First name of employer or authorized representative*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          maxLength={50}
          placeholder={"Enter first name"}
        />
        <Formblock
          type="text"
          name="employer_last_name"
          label="Last name of employer or authorized representative*"
          form={form}
          editing={true}
          className="modal wizard"
          maxLength={1}
          placeholder={"Enter last name"}
          val={vals.required}
        />
      </>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["sign-and-submit-form"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Finalize the I-9</h2>
          <p className={styles["subheader-description"]}>
            Please review the I-9 and sign below to complete the process.
          </p>
        </div>
        {renderAdditionalInformationBlock()}
        {renderPreviewBlock()}
        {renderESignatureBlock()}
      </div>
    </WizardScreen>
  );
};

export default SignAndSubmitForm;
