import AppContext from "dashboard/contexts/app-context";
import {
  IntegrationConnection,
  IntegrationSync,
  MiterAPI,
  MiterIntegrationForCompany,
} from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Loader, Toggler } from "ui";
import { DropdownItem } from "ui/button/Button";
import { DateRange } from "ui/form/DateRangePicker";
import { Option } from "ui/form/Input";
import { IntegrationStatusObj } from "backend/services/integrations/integration-types";
import { AuthenticateIntegration } from "./IntegrationComponents/AuthenticateIntegration";
import { IntegrationConfig } from "./IntegrationConfigs/IntegrationConfig";
import { IntegrationReports } from "./IntegrationReports";
import { IntegrationSummary } from "./IntegrationSummary";
import { IntegrationSyncHistory } from "./IntegrationSyncHistory";
import { IntegrationSyncSettings } from "./IntegrationSyncSettings";
import { IntacctConfigData } from "./IntegrationConfigs/Intacct/IntacctConfig";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterGuidesIcon } from "dashboard/components/MiterGuidesIcon";
import { miterGuidesIntegrationPaths } from "./constants";
import { useEnhancedSearchParams } from "miter-utils";
import { JonasCompanySelector } from "./IntegrationComponents/JonasCompanySelector";
import { IntacctEntitySelector } from "./IntegrationComponents/IntacctEntitySelector";

export const IntegrationPage: React.FC = () => {
  const { integrations, getIntegrations } = useContext(AppContext);
  const navigate = useNavigate();
  const { key } = useParams<{ key: string }>();

  const activeCompanyId = useActiveCompanyId();

  const [syncs, setSyncs] = useState<IntegrationSync[]>();
  const [lastSync, setLastSync] = useState<IntegrationSync>();
  const [fetchingSyncs, setFetchingSyncs] = useState(false);
  const [loadingConnectionStatus, setLoadingConnectionStatus] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState<IntegrationStatusObj>();
  const now = DateTime.now();
  const [syncsRange, setSyncsRange] = useState<DateRange>({ start: now.minus({ weeks: 1 }), end: now });

  const [integration, setIntegration] = useState<MiterIntegrationForCompany>();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams<"view">({ replaceInHistory: true });
  const toggleState = parsedSearchParams.view || "summary";
  const [xeroTrackingOptions, setXeroTrackingOptions] = useState<Option<string>[]>();
  const [intacctConfigData, setIntacctConfigData] = useState<IntacctConfigData>();

  const [reloggingIn, setReloggingIn] = useState(false);

  useEffect(() => {
    setIntegration(integrations.find((i) => i.key === key));
  }, [integrations, key]);

  const pendingSetup = integration?.connection?.pending_setup;
  const thirdPartySync = integration?.sync_managed_by === "third_party";
  const disableSyncConfig = integration?.disable_sync_config;

  const togglerOptions = [
    { label: "Summary", path: "summary" },
    ...(thirdPartySync || disableSyncConfig ? [] : [{ label: "Sync", path: "sync_settings" }]),
    ...(thirdPartySync ? [] : [{ label: "History", path: "sync_history" }]),
    ...(integration?.has_config_options ? [{ label: "Configuration", path: "configuration" }] : []),
    ...(integration?.has_reports ? [{ label: "Reports", path: "reports" }] : []),
  ];

  const getSyncs = async () => {
    if (!integration?.connection || !syncsRange.end) return;
    setFetchingSyncs(true);
    try {
      const response = await MiterAPI.integrations.get_syncs({
        integrationConnectionId: integration?.connection?._id.toString(),
        start: syncsRange.start!.startOf("day").toSeconds().toString(),
        end: syncsRange.end.endOf("day").toSeconds().toString(),
      });
      if (response.error) throw new Error(response.error);
      setSyncs(response);
      if (syncsRange.end.endOf("day") > DateTime.now()) {
        setLastSync(response[0]);
      }
    } catch (e) {
      console.error(e);
      Notifier.error(`There was a problem retrieving syncs.`);
    }
    setFetchingSyncs(false);
  };

  const getConnectionStatus = async () => {
    if (!integration?.connection) return;
    if (integration.is_reports_only) {
      setConnectionStatus({ status: "Connected" });
      return;
    }
    setLoadingConnectionStatus(true);
    try {
      const response = await MiterAPI.integrations.get_connection_status(
        integration?.connection?._id.toString()
      );
      if (response.error) throw new Error(response.error);
      setConnectionStatus(response);
    } catch (e) {
      console.error(e);
      Notifier.error(`Could not determine status of the integration connection.`);
    }
    setLoadingConnectionStatus(false);
  };

  const updateIntegrationConnection = async (
    update: Partial<IntegrationConnection> | Record<string, $TSFixMe>
  ): Promise<void> => {
    if (!integration?.connection) return;
    try {
      const response = await MiterAPI.integrations.update_connection(
        integration.connection._id.toString(),
        update
      );
      if (response.error) throw new Error(response.error);
      if (update.archived) navigate("/integrations");
      Notifier.success("Integration updated successfully.");
      await getIntegrations(); // This will eventually trigger `setIntegration` so no need to call explicitly
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error updating your settings. We're looking into it!`);
    }
  };

  const disconnectIntegrationConnection = async (): Promise<void> => {
    if (!integration?.connection) return;
    try {
      const response = await MiterAPI.integrations.disconnect(integration.connection._id.toString());
      if (response.error) throw new Error(response.error);
      navigate("/integrations");
      Notifier.success("Integration successfully disconnected.");
      await getIntegrations(); // This will eventually trigger `setIntegration` so no need to call explicitly
    } catch (e) {
      console.log(e);
      Notifier.error(`There was an error disconnecting this connection. We're looking into it!`);
    }
  };

  const isMissingJonasCompany =
    integration?.key === "jonas" && !integration.connection?.metadata?.jonas?.company?.CompanyId;

  const isMissingIntacctEntity =
    integration?.key === "sage_intacct" && !integration.connection?.metadata?.sage_intacct?.entity?.defaultId;

  useEffect(() => {
    if (reloggingIn || pendingSetup) return;
    getConnectionStatus();
  }, [!!integration?.connection, pendingSetup, reloggingIn]);

  useEffect(() => {
    getSyncs();
  }, [!!integration?.connection, syncsRange]);

  const goToSetupLink = async () => {
    if (!activeCompanyId || !integration?.connection?._id) return;
    setActionButtonLoading(true);
    try {
      const response = await MiterAPI.integrations.get_setup_link({
        key: integration.key,
        company_id: activeCompanyId,
        integrationConnectionId: integration.connection._id,
      });
      if (response.error) throw new Error(response.error);
      if (response.link) {
        window.open(response.link, "_blank");
      } else {
        Notifier.warning(`Setup link not found.`);
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error setting up the integration. We're looking into it!");
    }
    setActionButtonLoading(false);
  };

  const [actionButtonLoading, setActionButtonLoading] = useState(false);
  const buttonDropdownItems: DropdownItem[] = [
    {
      text: "Uninstall",
      onClick: () => disconnectIntegrationConnection(),
    },
  ];

  if (!pendingSetup) {
    if (integration?.has_setup_link) {
      buttonDropdownItems.push({ text: "Reconnect", onClick: () => goToSetupLink() });
    } else if (integration?.key === "jonas") {
      buttonDropdownItems.push({ text: "Reconnect", onClick: () => setReloggingIn(true) });
    }
  }

  const onAuth = async () => {
    await getConnectionStatus();
    setReloggingIn(false);
  };

  const guidesPath = integration?.key ? miterGuidesIntegrationPaths[integration.key] : undefined;

  if (!integration) return <Loader />;

  const sharedProps = { integration, updateIntegrationConnection };

  return (
    <div className="page-content">
      <Helmet>
        <title>{`${integration?.label}`} | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <div>
          <div onClick={() => navigate("/integrations")} className="reports-header-badge pointer">
            INTEGRATIONS
          </div>
          <div className="flex">
            <h1>{integration.label}</h1>
            {guidesPath && <MiterGuidesIcon path={guidesPath} />}
          </div>
          <span style={{ color: "slategray" }}>{integration.description}</span>
        </div>
        <div className="flex-1"></div>
        <Button
          text="Actions"
          dropdownItems={buttonDropdownItems}
          loading={actionButtonLoading}
          className="button-1"
        />
      </div>
      {pendingSetup || reloggingIn ? (
        <AuthenticateIntegration integration={integration} onAuth={onAuth} />
      ) : (
        <>
          {isMissingJonasCompany && <JonasCompanySelector {...sharedProps} />}
          {isMissingIntacctEntity && <IntacctEntitySelector {...sharedProps} />}
          <Toggler
            active={toggleState}
            toggle={(view) => setSearchParams({ view })}
            config={togglerOptions}
          />
          <div className="vertical-spacer"></div>
          {toggleState === "summary" && (
            <IntegrationSummary {...sharedProps} lastSync={lastSync} connectionStatus={connectionStatus} />
          )}
          {toggleState === "sync_settings" && (
            <IntegrationSyncSettings
              {...sharedProps}
              getSyncs={getSyncs}
              loadingConnectionStatus={loadingConnectionStatus}
            />
          )}
          {toggleState === "sync_history" && (
            <IntegrationSyncHistory
              {...sharedProps}
              syncs={syncs}
              fetchingSyncs={fetchingSyncs}
              syncsRange={syncsRange}
              setSyncsRange={setSyncsRange}
            />
          )}
          {toggleState === "configuration" && (
            <IntegrationConfig
              {...sharedProps}
              xeroTrackingOptions={xeroTrackingOptions}
              setXeroTrackingOptions={setXeroTrackingOptions}
              intacctConfigData={intacctConfigData}
              setIntacctConfigData={setIntacctConfigData}
              loadingConnectionStatus={loadingConnectionStatus}
            />
          )}
          {toggleState === "reports" && <IntegrationReports {...sharedProps} />}
        </>
      )}
    </div>
  );
};
