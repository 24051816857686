import BulkTeamMemberSelect, {
  BulkTeamMemberSelectTeamMember,
} from "dashboard/components/team-members/BulkTeamMemberSelect";
import { useActiveTeam, usePaySchedules, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import {
  AggregatedTeamMember,
  MiterAPI,
  MiterError,
  PaySchedule,
  UpdateTeamMemberResponse,
} from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { capitalize } from "lodash";
import React, { useMemo, useState } from "react";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

type Props = {
  ps: PaySchedule;
};

type TableRow = {
  _id: string;
  full_name: string;
  department: string | undefined;
  pay_type: string | undefined;
};

const tableColumns: ColumnConfig<TableRow>[] = [
  {
    field: "full_name",
    headerName: "Team member",
    dataType: "string" as const,
  },
  {
    field: "department",
    headerName: "Department",
    dataType: "string" as const,
  },
  {
    field: "pay_type",
    headerName: "Pay type",
    dataType: "string" as const,
  },
];

export const PayScheduleTms: React.FC<Props> = ({ ps }) => {
  const paySchedules = usePaySchedules();
  const refetchTeam = useRefetchTeam();
  const defaultPs = paySchedules.find((ps) => ps.default);

  const activeTms = useActiveTeam();

  const [bulkSelecting, setBulkSelecting] = useState(false);

  const payScheduleTms = useMemo(() => {
    return activeTms
      .filter((tm) => tm.pay_schedule_id === ps._id)
      .map((tm) => {
        return { ...tm, non_removable: ps.default };
      });
  }, [activeTms]);

  let selectableTmFilter: ((tm: AggregatedTeamMember) => boolean) | undefined;

  const tableData: TableRow[] = useMemo(() => {
    return payScheduleTms.map((tm) => {
      return {
        _id: tm._id,
        full_name: tm.full_name,
        department: tm.department?.name,
        pay_type: capitalize(tm.pay_type || undefined),
      };
    });
  }, [payScheduleTms]);

  const [submitting, setSubmitting] = useState(false);

  const handleBulkSubmit = async (tms: BulkTeamMemberSelectTeamMember[]) => {
    if (!defaultPs) return Notifier.error("You must have a default pay schedule to do this.");
    setSubmitting(true);
    try {
      const tmIds = new Set(tms.map((tm) => tm._id));
      const currentTms = new Set(payScheduleTms.map((tm) => tm._id));
      const newlyAssignedTms = tms.map((tm) => tm._id).filter((id) => !currentTms.has(id));
      const unassignedTms = payScheduleTms.map((tm) => tm._id).filter((id) => !tmIds.has(id));
      function responseHandler<T extends MiterError>(r: T) {
        if (r.error) throw new Error(r.error);
        return r;
      }
      const promises: Promise<UpdateTeamMemberResponse>[] = [];
      newlyAssignedTms.forEach((tmId) =>
        promises.push(MiterAPI.team_member.update(tmId, { pay_schedule_id: ps._id }).then(responseHandler))
      );
      unassignedTms.forEach((tmId) =>
        promises.push(
          MiterAPI.team_member.update(tmId, { pay_schedule_id: defaultPs._id }).then(responseHandler)
        )
      );
      const updateResults = await Promise.allSettled(promises);
      await refetchTeam();
      for (const r of updateResults) {
        if (r.status === "rejected") throw new Error(r.reason);
      }
      Notifier.success("Team member pay schedules updated successfully.");
      setBulkSelecting(false);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(
        `There was an error handling these updates: ${e.message}. Please refresh and try again.`
      );
    }
    setSubmitting(false);
  };

  return (
    <>
      {bulkSelecting && (
        <BulkTeamMemberSelect
          submitting={submitting}
          onHide={() => setBulkSelecting(false)}
          onSubmit={handleBulkSubmit}
          predicate={selectableTmFilter}
          title={`Choose team members for ${ps.label}`}
          defaultTeamMembers={payScheduleTms}
        />
      )}
      <TableV2
        id="pay-schedule-team-members-table"
        resource="team members"
        data={tableData}
        rowSelectDisabled={() => true}
        hideSelectColumn
        columns={tableColumns}
        paginationPageSize={75}
        staticActions={[
          {
            label: "Edit",
            action: () => setBulkSelecting(true),
            className: "button-1",
            important: true,
          },
        ]}
        containerStyle={{ height: 250, marginBottom: 50 }}
      />
    </>
  );
};
