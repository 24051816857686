import { MiterAPI } from "dashboard/miter";
import useDebounce from "dashboard/utils/useDebounce";
import { isValidEmail } from "dashboard/utils/validators";
import React, { useEffect, useState } from "react";
import { useActiveCompanyId } from "./atom-hooks";
import { LinkedTeamMember } from "backend/services/team-member-service";

export type PhonedLinkedTeamMembers = LinkedTeamMember[];
export type EmailedLinkedTeamMembers = LinkedTeamMember[];
export type UseLinkedTeamMembers = { phone: PhonedLinkedTeamMembers; email: EmailedLinkedTeamMembers };

/** Get's the team members linked to a phone number or email */
export const useLinkedTeamMembers = (params: {
  phone: string | null | undefined;
  email: string | null | undefined;
  teamMemberID?: string;
  defaultPhone?: string | null;
  defaultEmail?: string | null;
}): UseLinkedTeamMembers => {
  const { phone, email, teamMemberID, defaultPhone, defaultEmail } = params;
  const [linkedTeamMembers, setLinkedTeamMembers] = useState<UseLinkedTeamMembers>({ phone: [], email: [] });

  // Debounce changes to the phone and email
  const updatedPhone = useDebounce(phone, 500);
  const updatedEmail = useDebounce(email, 500);

  // Get linked team members when the phone changes
  useEffect(() => {
    getPhoneLinkedTeamMembers(phone);
  }, [updatedPhone]);

  // Get linked team members when the email changes
  useEffect(() => {
    getEmailLinkedTeamMembers(email);
  }, [updatedEmail]);

  const getLinkedTeamMembers = async (params: { phone?: string; email?: string; teamMemberID?: string }) => {
    try {
      const response = await MiterAPI.team_member.retrieve_linked_team_members(params);
      if (response.error) throw new Error(response.error);

      return response;
    } catch (e: $TSFixMe) {
      console.error("Couldn't get linked team members:", e);
    }
  };

  const getPhoneLinkedTeamMembers = async (phone?: string | null) => {
    if (!phone || !phone?.length || phone.length != 12 || phone === defaultPhone) {
      return setLinkedTeamMembers({ ...linkedTeamMembers, phone: [] });
    }

    const response = await getLinkedTeamMembers({ phone, teamMemberID });
    setLinkedTeamMembers({ ...linkedTeamMembers, phone: response || [] });
  };

  const getEmailLinkedTeamMembers = async (email?: string | null) => {
    if (
      !email ||
      !email?.length ||
      !isValidEmail(email) ||
      email.trim().toLowerCase() === defaultEmail?.trim().toLowerCase()
    ) {
      return setLinkedTeamMembers({ ...linkedTeamMembers, email: [] });
    }

    const response = await getLinkedTeamMembers({ email, teamMemberID });
    setLinkedTeamMembers({ ...linkedTeamMembers, email: response || [] });
  };

  return linkedTeamMembers;
};

type LinkedTeamMembersWarningProps = {
  mode: "create" | "update";
  type: "phone" | "email";
  employmentType: string | null;
  linkedTeamMembers: LinkedTeamMember[];
  style?: React.CSSProperties;
};

/** Displays a warning if a phone number or email is already linked to a team member */
export const LinkedTeamMembersWarning: React.FC<LinkedTeamMembersWarningProps> = ({
  mode,
  type,
  employmentType,
  linkedTeamMembers,
  style,
}) => {
  const activeCompanyId = useActiveCompanyId();

  if (!linkedTeamMembers?.length) return <></>;
  const formattedType = type === "phone" ? "phone number" : "email";

  const tmsHere = linkedTeamMembers.filter((t) => t.companyId === activeCompanyId);
  const sameEmploymentTypeHere = tmsHere.find((t) => employmentType === t.employmentType);

  // If we're updating, then we can't use a phone/email that's already in use at this company because that would require auto-merging user accounts, which isn't doable at this time
  // And we can never use a phone/email that's already in use by a TM of the same employment type at this company because that would be a duplicate record
  if ((mode === "update" && tmsHere.length) || sameEmploymentTypeHere) {
    return (
      <div className="error-message linked-team-members-warning" style={{ background: "#ffe9e7", ...style }}>
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          <strong>Error:</strong> {formattedType} is already in use at your company by{" "}
          {(sameEmploymentTypeHere || tmsHere[0]!).initials}
        </p>
      </div>
    );
  }

  // If we've gotten here, then we're creating a new TM with a phone/email of another TM at this company of a different employment type. So let's give a warning to make them confirm that it's the same underlying person
  if (tmsHere.length) {
    const tmHere = tmsHere[0]!;
    return (
      <div className="error-message linked-team-members-warning">
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          <strong>Warning:</strong> {formattedType} is already being used by {tmHere.initials} at your
          company. Since they are {tmHere.employmentType === "employee" ? "an employee" : "a contractor"} and
          you&apos;re creating {employmentType === "employee" ? "an employee" : "a contractor"}, it&apos;s
          allowed, but please make sure it&apos;s the same person.
        </p>
      </div>
    );
  }

  // At this point, it's just a warning that they should make sure it's a real email/phone since it's linked to other people at other companies
  const header = `This ${formattedType} is linked to a person already registered as ${
    linkedTeamMembers[0]?.initials
  } with another ${
    linkedTeamMembers.length > 1 ? linkedTeamMembers.length + " companies" : "company"
  } using Miter.`;

  const footer = ` Please confirm that this is a real ${formattedType} and that the team member has access to this ${type}.`;

  return (
    <div className="error-message linked-team-members-warning" style={style}>
      <p style={{ marginTop: 0 }}>
        <strong>Warning: </strong>
        {header}
      </p>
      <strong style={{ marginBottom: 0 }}>{footer}</strong>
    </div>
  );
};
