/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggregatedForm, FormSubmission, FrontendModel } from "dashboard/miter";
import { buildCompanyUserName, renderCustomFieldDefaultString } from "miter-utils";
import React, { useMemo, useState } from "react";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { FormAnswer as FormAnswer_ } from "backend/models/form";
import { Button } from "ui";
import { useLookupCompanyUsers } from "dashboard/hooks/atom-hooks";
import { ESignatureAnswerModal } from "./FormSubmissionAnswersTable";

type FormAnswer = FrontendModel<FormAnswer_>;

export type FormSubmissionAnswerTableRow = FormAnswer & {
  submission_id: string;
  submitter: string;
  created_at: number;
};

type Props = {
  form: AggregatedForm;
  formFieldId: string;
};

const FormQuestionAnswersTable: React.FC<Props> = ({ form, formFieldId }) => {
  const lookupCompanyUser = useLookupCompanyUsers();
  const [selectedAnswer, setSelectedAnswer] = useState<FormAnswer>();

  const getUserName = (formSubmission: FormSubmission) => {
    const user = lookupCompanyUser(formSubmission.user_id);
    return buildCompanyUserName(user);
  };

  const answers = useMemo(() => {
    return form.submissions.flatMap((submission) => {
      const filteredAnswers = submission.answers.filter((answer) => answer.form_field_id === formFieldId);

      return filteredAnswers.map((answer) => ({
        ...answer,
        submission_id: submission._id,
        submitter: getUserName(submission),
        created_at: submission.created_at,
      }));
    }) as FormSubmissionAnswerTableRow[];
  }, [formFieldId]);

  /*********************************************************
    Config variables for the table
  **********************************************************/

  const columns: ColumnConfig<FormSubmissionAnswerTableRow>[] = [
    {
      headerName: "Answer",
      field: "value",
      cellRenderer: (params) => {
        const formField = form.components.find((field) => field._id === params.data?.form_field_id);
        if (!formField) return "-";

        if (formField.type === "esignature" && params.data?.value?.signature) {
          return (
            <Button className="button-1 no-margin" onClick={() => setSelectedAnswer(params.data)}>
              View
            </Button>
          );
        }

        return renderCustomFieldDefaultString(formField as $TSFixMe, params.data);
      },
      valueGetter: (params) => {
        const formField = form.components.find((field) => field._id === params.data?.form_field_id);
        if (!formField) return "-";

        return renderCustomFieldDefaultString(formField as $TSFixMe, params.data);
      },
    },
    {
      headerName: "Submitter",
      field: "submitter",
    },
    {
      headerName: "Submitted On",
      field: "created_at",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "EEE, MMM d, yyyy h:mm a",
    },
  ];

  /*********************************************************
    Functions to render table components
  **********************************************************/

  return (
    <div className="form-table-wrapper">
      <TableV2
        id={"form-question-answers-table"}
        resource="form question answers"
        data={answers}
        columns={columns}
        paginationPageSize={10}
      />
      {selectedAnswer && (
        <ESignatureAnswerModal answer={selectedAnswer} onHide={() => setSelectedAnswer(undefined)} />
      )}
    </div>
  );
};

export default FormQuestionAnswersTable;
