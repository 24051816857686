/* global google */
import React, { useContext, useEffect, useState } from "react";
import TimesheetContext from "./timesheetContext";
import { GoogleMap, Marker, InfoWindow, Polyline } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import mapMarkerGreen from "dashboard/assets/mapMarkerGreen.png";
import mapMarkerBlue from "dashboard/assets/mapMarkerBlue.png";
import mapMarkerRed from "dashboard/assets/mapMarkerRed.png";
import { sampleEven, shorten } from "dashboard/utils";
import { determineGeofenceStatusOfTimesheet, useLoadGoogleMaps } from "dashboard/utils/geolocation";
import { DateTime } from "luxon";
import { Breadcrumb, MiterAPI } from "dashboard/miter";
import Banner from "dashboard/components/shared/Banner";
import { metersToMiles } from "miter-utils";
import { Notifier } from "ui";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

export const TimesheetGeolocation: React.FC = () => {
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();
  const { timesheet } = useContext(TimesheetContext);

  useEffect(() => {
    getBreadcrumbs();
  }, [timesheet]);

  const geoExists = !!(timesheet?.geolocation?.clock_out || timesheet?.geolocation?.clock_in);
  const [openedBreadcrumb, setOpenedBreadcrumb] = useState<Breadcrumb>();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [breadcrumbsError, setBreadcrumbsError] = useState<string>();

  const { isLoaded } = useLoadGoogleMaps();

  const timesheetJob = timesheet?.job;

  const companyHasGeolocationEnabled = activeCompany?.settings.timesheets?.geolocation;
  const companyHasBreadcrumbsEnabled = activeCompany?.settings.timesheets?.enable_geolocation_breadcrumbs;

  const companyName = activeCompany?.check_company.trade_name;
  const radiusMiles = activeCompany?.settings.timesheets.geofence_radius;

  const [_map, setMap] = useState(null);

  const calculateBearing = (lat1, lng1, lat2, lng2) => {
    if (!window.google) return;
    const dLon = lng2 - lng1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    let brng = Math.atan2(y, x);
    brng = window.google.maps.geometry.spherical.computeHeading(
      new window.google.maps.LatLng(lat1, lng1),
      new window.google.maps.LatLng(lat2, lng2)
    );
    return (brng + 360) % 360;
  };

  const getBreadcrumbs = async () => {
    if (!companyHasBreadcrumbsEnabled) return;
    if (!timesheet?.geolocation) return;

    try {
      if (!timesheet) return;
      const res = await MiterAPI.timesheets.breadcrumbs(timesheet._id);
      if (res.error) {
        if (res.error_type === "no_breadcrumbs") {
          setBreadcrumbsError("Breadcrumbs were not captured for this timesheet");
        } else {
          throw new Error(res.error);
        }
      } else {
        setBreadcrumbs(res.crumbs);
      }
    } catch (error) {
      console.error("Error getting breadcrumbs", error);
      Notifier.error("Error getting breadcrumbs");
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    if (timesheetJob?.geolocation) {
      bounds.extend(timesheetJob?.geolocation);
    }
    if (timesheet?.geolocation?.clock_in) {
      bounds.extend(timesheet?.geolocation?.clock_in);
    }
    if (timesheet?.geolocation?.clock_out) {
      bounds.extend(timesheet?.geolocation?.clock_out);
    }
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const goToTimesheetSettings = () => {
    navigate("/timesheet-settings");
  };

  let geofenceStatus = "n/a";
  if (timesheet) {
    geofenceStatus = determineGeofenceStatusOfTimesheet(timesheet, radiusMiles);
  }

  const renderBreadcrumbs = () => {
    if (!companyHasBreadcrumbsEnabled) return;

    const crumbs = (breadcrumbs || []).sort((c1, c2) => c1.timestamp - c2.timestamp);
    const displayedCrumbs = sampleEven(crumbs, 100);

    return (
      <>
        {displayedCrumbs.map((crumb, index, array) => {
          if (index < array.length - 1) {
            const bearing = calculateBearing(
              crumb.lat,
              crumb.lng,
              array[index + 1]?.lat,
              array[index + 1]?.lng
            );

            return (
              <>
                <Marker
                  position={crumb}
                  icon={{
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    fillColor: "#fff",
                    fillOpacity: 1.0,
                    strokeWeight: 2,
                    scale: 3,
                    rotation: bearing,
                  }}
                  options={{ optimized: false }}
                  onClick={() => setOpenedBreadcrumb(crumb)}
                />
              </>
            );
          }
        })}
      </>
    );
  };

  const generateNoGeolocationMessage = () => {
    switch (timesheet?.creation_method) {
      case "app_manual": {
        return "This timesheet was created manually in the app and thus does not have geolocation data.";
      }
      case "dashboard": {
        return "This timesheet was created manually on the dashboard and thus does not have geolocation data..";
      }
      case "dashboard_clock_in": {
        return "This timesheet was created via dashboard clock in and thus does not have geolocation data.";
      }
      case "app_clock_in": {
        return "No geolocation data was captured for this timesheet. Please ensure the app has location permissions.";
      }
      case "kiosk_clock_in": {
        return "No geolocation data was captured for this timesheet. Please ensure the app has location permissions.";
      }
      default: {
        return "This timesheet is missing geolocation data.";
      }
    }
  };

  const renderMapView = () => {
    const radiusMiles = activeCompany?.settings.timesheets.geofence_radius || 0.5;
    return (
      <div>
        {isLoaded ? (
          <div>
            {geofenceStatus === "outside_fence" && (
              <Banner
                type="warning"
                content={
                  "This timesheet's clock in or clock out location is more than " +
                  radiusMiles +
                  " mile(s) from the jobsite."
                }
              />
            )}
            {geofenceStatus === "missing_job" && (
              <Banner type="warning" content={"This timesheet does not have an associated job."} />
            )}
            {geofenceStatus === "missing_timesheet_geolocation" && (
              <Banner type="warning" content={"This timesheet is missing geolocation data."} />
            )}
            {geofenceStatus === "missing_job_geolocation" && (
              <Banner type="warning" content={"This timesheet's job does not have a geolocation."} />
            )}
            <div className="vertical-spacer-small" />
            {breadcrumbsError && <Banner type="warning" content={breadcrumbsError} />}
            <div className="vertical-spacer-small" />
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: 300 }}
              onLoad={onLoad}
              zoom={8}
              center={timesheet?.geolocation?.clock_out || timesheet?.geolocation?.clock_in || undefined}
              options={{
                mapTypeControlOptions: {
                  mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
                },
              }}
              onClick={() => setOpenedBreadcrumb(undefined)}
            >
              {timesheetJob?.geolocation && (
                <Marker
                  position={timesheetJob?.geolocation}
                  icon={{ url: mapMarkerBlue, scaledSize: new google.maps.Size(35, 35) }}
                />
              )}
              {timesheet?.geolocation?.clock_in &&
                timesheet.geolocation.clock_in.lat !== 0 &&
                timesheet.geolocation.clock_in.lng !== 0 && (
                  <Marker
                    position={timesheet?.geolocation?.clock_in}
                    icon={{ url: mapMarkerGreen, scaledSize: new google.maps.Size(35, 35) }}
                  />
                )}
              {timesheet?.geolocation?.clock_out && (
                <Marker
                  position={timesheet?.geolocation?.clock_out}
                  icon={{ url: mapMarkerRed, scaledSize: new google.maps.Size(25, 25) }}
                />
              )}
              {openedBreadcrumb && (
                // @ts-expect-error Not sure what the error is
                <InfoWindow position={openedBreadcrumb} onCloseClick={() => setOpenedBreadcrumb(undefined)}>
                  <div>{DateTime.fromSeconds(openedBreadcrumb.timestamp).toFormat("FFF")}</div>
                </InfoWindow>
              )}
              {renderBreadcrumbs()}
              {/* // Render line connecting breakdcrumbs with arrows */}
              {breadcrumbs && breadcrumbs.length > 1 && (
                <Polyline
                  path={breadcrumbs}
                  options={{
                    strokeColor: "#4d55bb",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
              )}
            </GoogleMap>
            <TimesheetMapLegend />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Geolocation</div>{" "}
      </div>
      {companyHasGeolocationEnabled ? (
        geoExists ? (
          renderMapView()
        ) : (
          generateNoGeolocationMessage()
        )
      ) : (
        <div className="yellow-text-container">
          <span>{`${companyName} has not yet enabled geolocation for its timesheets. To do so, visit `}</span>
          <span className="blue-link" onClick={goToTimesheetSettings}>
            Timesheet Settings
          </span>
          .
        </div>
      )}
    </div>
  );
};

export const TimesheetMapLegend: React.FC = () => {
  const { timesheet } = React.useContext(TimesheetContext);
  const activeCompany = useActiveCompany();

  const companyHasMileageEnabled = activeCompany?.settings.timesheets?.enable_geolocation_mileage;

  const jobName = timesheet?.job?.name;

  // Convert distance in meters to miles
  const distanceTraveled = metersToMiles(timesheet?.geolocation?.distance_traveled);

  if (!jobName && !timesheet?.geolocation) {
    return <></>;
  }

  return (
    <div className="" style={{ marginTop: 20, overflow: "scroll" }}>
      <div className="flex" style={{ alignItems: "top", marginBottom: 10 }}>
        {jobName && (
          <div className="flex" style={{ marginRight: 20 }}>
            <img src={mapMarkerBlue} style={{ height: 23, marginRight: 6 }} />
            <strong>Job:&nbsp;</strong>
            <div>{shorten(jobName, 30)}</div>
          </div>
        )}
        <div className="flex-1" />
        {distanceTraveled && companyHasMileageEnabled ? (
          <div className="flex">
            <strong>Distance traveled: &nbsp;</strong>
            {distanceTraveled} miles
          </div>
        ) : null}
      </div>
      {timesheet?.geolocation?.clock_in && (
        <div className="flex" style={{ marginRight: 20, marginBottom: 10 }}>
          <img src={mapMarkerGreen} style={{ height: 23, marginRight: 6 }} />
          <div>
            <strong>Clock in:</strong> {DateTime.fromSeconds(timesheet.clock_in).toFormat("FFF")}
          </div>
        </div>
      )}
      {timesheet?.geolocation?.clock_out && (
        <div className="flex">
          <img src={mapMarkerRed} style={{ height: 23, marginRight: 6 }} />
          <div>
            <strong>Clock out:</strong> {DateTime.fromSeconds(timesheet.clock_out).toFormat("FFF")}
          </div>
        </div>
      )}
    </div>
  );
};
