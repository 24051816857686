import React, { useEffect, useState, useMemo } from "react";
import { reportList } from "../reportList";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { recentYearOptions } from "dashboard/utils";
import { capitalize } from "lodash";
import { CheckTaxFiling, CheckTaxFilingEvent } from "backend/utils/check/check-types";
import { BasicModal, Formblock } from "ui";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";

const reportObject = reportList.find((report) => report.slug === "tax_filings");

type TaxFilingsReportEntry = Omit<CheckTaxFiling, "company"> & {
  _id: string;
  last_failure: CheckTaxFilingEvent | null;
};

export const TaxFilingsReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { errors, control } = useForm();
  const navigate = useNavigate();

  // State
  const [data, setData] = useState<TaxFilingsReportEntry[]>([]);
  const [year, setYear] = useState(() => {
    // In the first quarter, assume they're looking for the previous year
    const today = DateTime.now();
    return today.month <= 3 ? today.year - 1 : today.year;
  });
  const [loading, setLoading] = useState(false);
  const [selectedFiling, setSelectedFiling] = useState<TaxFilingsReportEntry | null>(null);

  const yearOptions = useMemo(recentYearOptions, []);

  useEffect(() => {
    const getData = async () => {
      if (!activeCompanyId) return;
      setLoading(true);
      try {
        const cleanedData = {
          company_id: activeCompanyId,
          year: year,
        };
        const payload = {
          type: "tax_filings",
          params: cleanedData,
          format: "json",
        };
        const response = await MiterAPI.reports.create(payload);
        if (response.error) throw new Error(response.error);
        const entries = response.map((filing: CheckTaxFiling): TaxFilingsReportEntry => {
          return {
            ...filing,
            _id: filing.id,
            last_failure: filing.tax_filing_events.find((e) => e.status === "rejected") || null,
          };
        });
        setData(entries);
      } catch (e: $TSFixMe) {
        console.log("Error fetching tax filings data:", e);
        Notifier.error(e.message);
      }
      setLoading(false);
    };
    getData();
  }, [!activeCompanyId, year]);

  const columns = useMemo(() => {
    const cols: ColumnConfig<TaxFilingsReportEntry>[] = [
      {
        field: "description",
        dataType: "string",
        headerName: "Description",
        enableRowGroup: true,
      },
      {
        field: "frequency",
        dataType: "string",
        headerName: "Frequency",
        enableRowGroup: true,
        valueGetter: (params) => capitalize(params.data?.frequency),
      },
      {
        field: "period",
        dataType: "string",
        headerName: "Period",
        enableRowGroup: true,
        valueGetter: (params) => capitalize(params.data?.period),
      },
      {
        field: "status",
        dataType: "string",
        headerName: "Filing status",
        valueGetter: (params) => capitalize(params.data?.status),
        enableRowGroup: true,
      },
      {
        field: "can_retry",
        dataType: "boolean",
        headerName: "Retryable",
        enableRowGroup: true,
      },
      {
        field: "last_failure_reason",
        dataType: "string",
        headerName: "Last failure reason",
        wrapText: true,
        autoHeight: true,
        valueGetter: (params) => {
          if (params.data?.last_failure) {
            return params.data.last_failure.label || "Unknown failure";
          } else {
            return "N/A";
          }
        },
      },
      {
        field: "last_failure_account_number",
        dataType: "string",
        headerName: "Last failure account number",
        valueGetter: (params) => params.data?.last_failure?.account_number,
      },
    ];

    return cols;
  }, []);

  return (
    <div className="page-content">
      <Helmet>
        <title>Tax Filings Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>{reportObject!.label}</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      <div style={{ maxWidth: 400 }}>
        <Formblock
          label="Year"
          name="year"
          control={control}
          type="select"
          options={yearOptions}
          defaultValue={year}
          onChange={(option) => setYear(option.value)}
          errors={errors}
          editing={true}
          style={{ alignItems: "center" }}
          labelStyle={{ flexDirection: "unset" }}
        />
      </div>
      {selectedFiling && (
        <BasicModal
          className="modal"
          headerText="Filing details"
          onHide={() => setSelectedFiling(null)}
          button2Action={() => setSelectedFiling(null)}
          button2Text={"Close"}
        >
          <Formblock
            type="text"
            name="description"
            label="Description"
            value={selectedFiling.description}
            editing={true}
            className="modal"
            disabled={true}
          />
          <Formblock
            type="text"
            name="status"
            label="Status"
            value={selectedFiling.status}
            editing={true}
            className="modal"
            disabled={true}
          />
          <Formblock
            type="paragraph"
            name="last_failure_reason"
            label="Last failure reason"
            defaultValue={selectedFiling.last_failure?.label || "N/A"}
            editing={true}
            disabled={true}
            className="modal"
          />
          <Formblock
            type="text"
            name="last_failure_account_number"
            label="Last failure account number"
            value={selectedFiling.last_failure?.account_number || "N/A"}
            editing={true}
            className="modal"
            disabled={true}
          />
        </BasicModal>
      )}
      <TableV2
        id="tax-filings-report"
        resource="tax filings"
        data={data}
        columns={columns}
        isLoading={loading}
        showReportViews={true}
        onClick={(entry) => setSelectedFiling(entry)}
      />
      <div className="vertical-spacer-large"></div>
    </div>
  );
};
