import React, { useContext, useEffect, useMemo, useState } from "react";
import { Wizard } from "ui";
import { AggregatedTeamMember, TeamMember } from "dashboard/miter";
import { TeamMemberBasicInfoScreen } from "./TeamMemberBasicInfoScreen";
import { TeamMemberHRInfoScreen } from "./TeamMemberHRInfoScreen";
import { TeamMemberPersonalInfoScreen } from "./TeamMemberPersonalInfo";
import { TeamMemberEmploymentInfoScreen } from "./TeamMemberEmploymentInfo";
import { TeamMemberPayrollInfoScreen } from "./TeamMemberPayrollInfoScreen";
import { TeamMemberDefaultsScreen } from "./TeamMemberDefaultsScreen";
import { TeamMemberEEOInfoScreen } from "./TeamMemberEEOInfoScreen";
import { TeamMemberPoliciesAndPermissionsScreen } from "./TeamMemberPoliciesAndPermissionsScreen";
import AppContext from "dashboard/contexts/app-context";
import { TeamMemberCustomFieldsScreen } from "./TeamMemberCustomFieldsScreen";
import { TeamMemberOrgInfoScreen } from "./TeamMemberOrgInfoScreen";
import { TeamMemberChecklistBuilderScreen } from "./TeamMemberChecklistBuilderScreen";
import { TeamMemberBenefitsInfoScreen } from "./TeamMemberBenefitsInfoScreen";
import { useHasAccessToOnboarding2_0 } from "dashboard/gating";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";

export type TeamMemberWizardMode = "create" | "enroll_in_payroll";
export type WizardTeamMember = Partial<TeamMember> | AggregatedTeamMember;

type Props = {
  onExit: () => void;
  onComplete: () => void;
  teamMember?: WizardTeamMember;
  mode?: TeamMemberWizardMode;
};

const TeamMemberWizard: React.FC<Props> = ({ onExit, onComplete, mode, ...props }) => {
  const { customFields } = useContext(AppContext);
  const hasAccessToOnboarding2_0 = useHasAccessToOnboarding2_0();
  const activeCompany = useActiveCompany();
  const isActiveOnClasp = isActiveClaspCompany(activeCompany);

  const [teamMember, setTeamMember] = useState<WizardTeamMember | undefined>(props.teamMember);

  const teamMemberCustomFields = useMemo(() => {
    return customFields.filter((field) => field.parent_type === "team_member");
  }, [customFields]);

  useEffect(() => {
    setTeamMember(props.teamMember);
  }, [props.teamMember]);

  const startIndex = useMemo(() => {
    if (mode === "enroll_in_payroll") {
      return 0;
    }
    return props.teamMember?.resume_wizard_index ?? 0;
  }, [mode, props.teamMember]);

  return (
    <Wizard onExit={onExit} onComplete={onComplete} startIndex={startIndex}>
      {mode !== "enroll_in_payroll" && (
        <TeamMemberBasicInfoScreen
          name={"Basic information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberPersonalInfoScreen
          name={"Personal information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberEmploymentInfoScreen
          name={"Employment information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberPayrollInfoScreen
          name={"Payroll information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
          mode={mode || "create"}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && isActiveOnClasp && (
        <TeamMemberBenefitsInfoScreen
          name={"Benefits information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
          mode={mode || "create"}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberDefaultsScreen
          name={"Default associations"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberEEOInfoScreen
          name={"EEO information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberPoliciesAndPermissionsScreen
          name={"Policies and permissions"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {hasAccessToOnboarding2_0 && teamMemberCustomFields.length > 0 && mode !== "enroll_in_payroll" && (
        <TeamMemberCustomFieldsScreen
          name={"Custom fields"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberChecklistBuilderScreen
          teamMember={teamMember}
          name={"New hire checklist"}
          setTeamMember={setTeamMember}
          type="new_hire"
        />
      )}
      {hasAccessToOnboarding2_0 && mode !== "enroll_in_payroll" && (
        <TeamMemberChecklistBuilderScreen
          teamMember={teamMember}
          name={"Admin checklist"}
          setTeamMember={setTeamMember}
          type="admin"
        />
      )}

      {/** To remove the HR info screen, comment out the following line */}
      {((hasAccessToOnboarding2_0 && mode === "enroll_in_payroll") ||
        (!hasAccessToOnboarding2_0 && mode)) && (
        <TeamMemberHRInfoScreen
          name={mode === "enroll_in_payroll" ? "Enroll in payroll" : "HR information"}
          mode={mode}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}

      {mode === "create" && (
        <TeamMemberOrgInfoScreen
          name={"Org information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
    </Wizard>
  );
};

export default TeamMemberWizard;
