import React, { useEffect, useMemo, useRef, useState } from "react";
import { PolicyRule, Policy } from "dashboard/miter";
import styles from "./PolicyRules.module.css";
import { deparameterize, isTruthy } from "miter-utils";
import {
  ChangeRequestPolicyFieldOptions,
  ExpensePolicyFieldOptions,
  ExpenseReimbursementPolicyFieldOptions,
  OperatorOptionLookup,
  TimeOffRequestPolicyFieldOptions,
  TimesheetPolicyFieldOptions,
  getApprovalItemType,
  useConditionLookup,
} from "./PolicyConstants";
import { PolicyRuleBuilder } from "./PolicyRuleBuilder";
import { DescriptionTable } from "../shared/DescriptionTable";
import { Button, ConfirmModal, IconWithTooltip } from "ui";
import { CaretDown, CaretUp, DotsSixVertical, Info, Pencil, Plus, Trash } from "phosphor-react";
import { capitalize } from "lodash";
import ObjectID from "bson-objectid";
import { PolicyType } from "backend/services/approvals/types";
import { useTeamMemberGroupLabeler } from "dashboard/utils/approvals";
import { motion, AnimatePresence, Reorder } from "framer-motion";
import pluralize from "pluralize";

type Props = {
  policy?: Policy;
  policyType: PolicyType;
  readonly: boolean;
  onSave: (rules: PolicyRule[]) => void;
  onEditStart: () => void;
  onEditEnd: () => void;
};

type PolicyRuleWithEditing = PolicyRule & { editing?: boolean };

export const PolicyRules: React.FC<Props> = ({
  policy,
  policyType,
  readonly,
  onSave,
  onEditStart,
  onEditEnd,
}) => {
  const rulesRef = useRef<HTMLDivElement>(null);
  const ruleRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  const [draggable, setDraggable] = useState(false);
  const [rules, setRules] = useState<PolicyRuleWithEditing[]>(
    policy?.rules || [{ ...buildDefaultRuleProps(), default: true }]
  );

  const defaultRule = useMemo(() => {
    return rules.find((rule) => rule.default);
  }, [rules]);

  const conditionalRules = useMemo(() => {
    return rules.filter((rule) => !rule.default);
  }, [rules]);

  // whenever rules are changed, either editing an existing rule or adding new one, call onSave
  useEffect(() => {
    onSave(rules);
  }, [rules]);

  const handleAddRule = () => {
    const updatedRules = [...rules];
    const newRule = buildDefaultRuleProps();

    // If there is already a default rule, set the new rule to be conditional
    const hasDefaultRule = rules.find((rule) => !rule.condition?.field);
    if (hasDefaultRule) {
      // @ts-expect-error ok to set operator as undefined because you can't save without updating it
      newRule.condition = { field: undefined, operator: undefined, type: "string" };
    }

    // Add after default rule
    updatedRules.splice(1, 0, newRule);
    setRules(updatedRules);
  };

  const handleSaveExistingRule = (updatedRule: PolicyRule) => {
    const updatedRules = rules.map((oldRule) => (oldRule._id === updatedRule._id ? updatedRule : oldRule));
    setRules(updatedRules);
  };

  const handleDeleteRule = (rule: PolicyRule) => {
    const updatedRules = rules.filter((oldRule) => oldRule._id !== rule._id);
    setRules(updatedRules);
  };

  const handleReorderRules = (reorderedConditionalRules: PolicyRule[]) => {
    if (!defaultRule) return;

    const reorderedRules = [defaultRule, ...reorderedConditionalRules];
    setRules(reorderedRules);
  };

  const renderDraggable = () => {
    return (
      <div
        className={styles["rules-draggable"]}
        style={{ position: "absolute", left: 0, top: 20 }}
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
        onTouchStart={() => setDraggable(true)}
      >
        <DotsSixVertical className="draggable-item" />
      </div>
    );
  };

  const renderConditionalRule = (rule: PolicyRule) => {
    return (
      <Reorder.Item
        key={"stage-" + rule._id}
        value={rule}
        dragListener={draggable}
        onDragEnd={() => setDraggable(false)}
        layout="position"
        ref={(r) => (ruleRefs.current[rule._id] = r)}
        id={rule._id}
        dragConstraints={rulesRef}
      >
        <div className={"relative " + styles["policy-rule-item"]}>
          {renderDraggable()}
          <PolicyRuleItem
            key={"rule-" + rule._id}
            policy={policy!}
            policyType={policyType}
            rule={rule}
            allRules={rules}
            readonly={readonly}
            onSave={handleSaveExistingRule}
            onDelete={() => handleDeleteRule(rule)}
            onEditStart={onEditStart}
            onEditEnd={onEditEnd}
          />
        </div>
      </Reorder.Item>
    );
  };

  const renderDefaultRule = () => {
    if (!defaultRule) return;

    return (
      <div className={"relative " + styles["policy-rule-item"]}>
        <PolicyRuleItem
          key={"rule-" + defaultRule._id}
          policy={policy!}
          policyType={policyType}
          rule={defaultRule}
          allRules={rules}
          readonly={readonly}
          onSave={handleSaveExistingRule}
          onDelete={() => handleDeleteRule(defaultRule)}
          onEditStart={onEditStart}
          onEditEnd={onEditEnd}
        />
      </div>
    );
  };

  // renders existing rules passed in from the policy
  const renderRules = () => {
    if (!rules.length)
      return (
        <div className={styles["policy-rules-empty"]}>
          There are currently no rules for this policy. Add a rule by clicking below.
        </div>
      );

    return (
      <div>
        <Reorder.Group
          className={styles["policy-rules"]}
          axis="y"
          values={conditionalRules}
          onReorder={handleReorderRules}
          ref={rulesRef}
        >
          {conditionalRules.map(renderConditionalRule)}
        </Reorder.Group>
        {renderDefaultRule()}
      </div>
    );
  };

  const renderAddRule = () => {
    return (
      <div className={styles["policy-add-rule"]}>
        <Button className="button-1 no-margin taller-button" onClick={handleAddRule}>
          <Plus size={12} weight="bold" style={{ marginRight: 5, marginBottom: 0 }} />
          Add Rule
        </Button>
      </div>
    );
  };

  return (
    <div className={styles["policy-rules-container"]}>
      {renderAddRule()}
      {renderRules()}
    </div>
  );
};

type PolicyRuleProps = {
  policy: Policy;
  policyType: PolicyType;
  rule: PolicyRule & { editing?: boolean };
  allRules: PolicyRule[];
  onSave: (rule: PolicyRule) => void;
  onEditStart: () => void;
  onEditEnd: () => void;
  onDelete: () => void;
  readonly: boolean;
  editing?: boolean;
};

const PolicyRuleItem: React.FC<PolicyRuleProps> = ({
  policy,
  policyType,
  rule,
  allRules,
  readonly,
  onSave,
  onDelete,
  onEditStart,
  onEditEnd,
  ...props
}) => {
  const conditionLabel = useConditionLabel(rule, policyType, policy);
  const { groupLabeler } = useTeamMemberGroupLabeler();
  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(!!props.editing || rule.editing);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const itemType = getApprovalItemType(policyType);

  const approvalFlowItems = useMemo(() => {
    return (rule.approval_flow || []).map((flow, index) => {
      const approverItems = flow.approvers.map((approver) => groupLabeler(approver)).filter(isTruthy);

      const finalString = flow.condition === "and" ? approverItems.join(" and ") : approverItems.join(" or ");
      return { label: index + 1 + "", value: finalString };
    });
  }, [rule.approval_flow]);

  const fieldOptions = useMemo(() => {
    if (policyType === "expense") {
      return ExpensePolicyFieldOptions;
    } else if (policyType === "expense_reimbursement") {
      return ExpenseReimbursementPolicyFieldOptions;
    } else if (policyType === "timesheet") {
      return TimesheetPolicyFieldOptions;
    } else if (policyType === "time_off_request") {
      return TimeOffRequestPolicyFieldOptions;
    } else if (policyType === "team_member_change_request") {
      return ChangeRequestPolicyFieldOptions;
    }
    return [];
  }, [policyType]);

  useEffect(() => {
    editing ? onEditStart() : onEditEnd();
  }, [editing]);

  const handleEditRule = () => {
    setEditing(true);
  };

  const handleDeleteRule = () => {
    setShowDeleteConfirmModal(true);
  };

  const handleSave = (rule: PolicyRule) => {
    onSave(rule);
    setEditing(false);
    setExpanded(true);
  };

  const handleCancel = () => {
    const hasApprovalFlow = rule.approval_flow?.length || rule.auto_approve;
    const hasCondition = !!rule.condition?.field;
    const hasFieldRequirements = Object.keys(rule.fields || {}).length > 0;
    const isDefaultRule = rule.default;

    const shouldDelete = !isDefaultRule && !hasApprovalFlow && !hasCondition && !hasFieldRequirements;
    if (shouldDelete) {
      onEditEnd();
      return onDelete();
    } else {
      setEditing(false);
    }
  };

  const buildConditionInfo = () => {
    const hasCondition = !!rule.condition?.field;
    const hasOnlyDefaultRule = allRules.length === 1 && !hasCondition;

    const isFallback = !hasCondition && !hasOnlyDefaultRule;
    const isDefault = hasOnlyDefaultRule;
    const items = pluralize(itemType);

    if (isFallback)
      return (
        <IconWithTooltip
          PhosphorIcon={Info}
          tooltip={`This rule will be applied to all ${items} that don't match any of the other rules.`}
          style={{ color: "#4d55bb", marginLeft: 10 }}
        />
      );
    else if (isDefault) {
      return (
        <IconWithTooltip
          PhosphorIcon={Info}
          tooltip={`This rule will be applied to all ${items}.`}
          style={{ color: "#4d55bb", marginLeft: 10 }}
        />
      );
    }
  };

  const renderCondition = () => {
    return (
      <div className={styles["policy-rule-condition"]} onClick={() => setExpanded(!expanded)}>
        <h3 className={styles["policy-rule-condition-header"]}>{conditionLabel}</h3>
        <div style={{ marginBottom: -3 }}>{buildConditionInfo()}</div>
      </div>
    );
  };

  const renderFieldRequirements = () => {
    if (!rule.fields) return;

    const items = Object.keys(rule.fields)
      .map((field) => ({
        // @ts-expect-error fix me
        label: fieldOptions.find((o) => o.value === field)?.label || capitalize(deparameterize(field)),
        value: capitalize(rule.fields?.[field]),
      }))
      .filter((item) => !!item.value);

    if (!items.length) return;

    return (
      <div
        className={styles["policy-rule-field-requirements"]}
        style={!rule.condition ? { marginTop: "0px" } : {}}
      >
        <h3 className={styles["policy-rule-section-title"]}>Enforce these field settings</h3>
        <div className={styles["policy-rule-section-subtitle"]}>
          Require and/or hide fields on the {itemType}
        </div>
        <DescriptionTable items={items} />
      </div>
    );
  };

  const renderApprovalFlow = () => {
    if (!rule.approval_flow?.length && !rule.auto_approve) return;

    return (
      <div
        className={styles["policy-rule-approval-flow"]}
        style={!rule.condition && !Object.keys(rule.fields || {}).length ? { marginTop: "0px" } : {}}
      >
        <h3 className={styles["policy-rule-section-title"]}>Add an approval flow</h3>
        <div className={styles["policy-rule-section-subtitle"]}>
          Create the step by step approval process for the {itemType}
        </div>
        {rule.auto_approve && (
          <div className={styles["policy-rule-section-content"]}>Auto-approve this item</div>
        )}
        <DescriptionTable items={approvalFlowItems} />
      </div>
    );
  };

  const renderSignOff = () => {
    if (!("sign_off" in rule) || !rule.sign_off) return;
    const signOff = rule.sign_off;

    return (
      <div
        className={styles["policy-rule-approval-flow"]}
        style={!rule.condition && !Object.keys(rule.fields || {}).length ? { marginTop: "0px" } : {}}
      >
        <h3 className={styles["policy-rule-section-title"]}>Require sign off</h3>
        <div className={styles["policy-rule-section-subtitle"]}>
          Require your employees to sign off on their {pluralize(itemType)}
        </div>
        <div className={styles["policy-rule-section-content"]}>
          <span style={{ color: "#555" }}>Attest with a {signOff.method}</span>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    const isDefaultRule = rule.condition == null;
    return (
      <div className={styles["policy-rule-header-right"]}>
        <div className={styles["policy-rule-actions"]}>
          {!isDefaultRule && (
            <Button className="button-1 no-margin square-button" onClick={handleDeleteRule}>
              <Trash />
            </Button>
          )}
          <Button className="button-1 no-margin square-button" onClick={handleEditRule}>
            <Pencil />
          </Button>
        </div>
        {expanded ? (
          <CaretUp height={32} size={20} onClick={() => setExpanded(!expanded)} />
        ) : (
          <CaretDown height={32} size={20} onClick={() => setExpanded(!expanded)} />
        )}
      </div>
    );
  };

  const renderEditor = () => {
    if (!editing) return;

    return (
      <PolicyRuleBuilder
        policyType={policyType}
        rule={rule}
        onCancel={handleCancel}
        onSave={handleSave}
        allRules={allRules}
      />
    );
  };

  const renderDeleteConfirmModal = () => {
    if (!showDeleteConfirmModal) return;

    return (
      <ConfirmModal
        title={"Delete rule"}
        body={"Are you sure you want to delete this rule?"}
        onYes={onDelete}
        onNo={() => setShowDeleteConfirmModal(false)}
      />
    );
  };

  const renderEmpty = () => {
    const hasCondition = !!rule.condition?.field;
    const hasFieldRequirements = Object.keys(rule.fields || {}).length > 0;
    const hasApprovalFlow = rule.approval_flow?.length || rule.auto_approve;
    const hasSignOff = "sign_off" in rule && rule.sign_off;

    if (!hasCondition && !hasFieldRequirements && !hasApprovalFlow && !hasSignOff && !rule.condition) {
      return (
        <div className={styles["policy-rule-empty"]}>
          This rule is empty. Click the edit button to add conditions, field requirements, approval flow, and
          more.
        </div>
      );
    }
  };

  const renderViewer = () => {
    return (
      <div className={styles["policy-rule-container"] + " " + (expanded ? styles["expanded"] : "")}>
        <motion.header initial={false} animate="open" transition={{ duration: 0.3 }}>
          <div className={styles["policy-rule-if"] + " " + (expanded ? styles["expanded"] : "")}>
            {renderCondition()}
            {renderActions()}
          </div>
        </motion.header>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div className={styles["policy-rule-then"]}>
                {renderFieldRequirements()}
                {renderApprovalFlow()}
                {renderSignOff()}
                {renderEmpty()}
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    );
  };

  return (
    <>
      {!editing && renderViewer()}
      {editing && renderEditor()}
      {renderDeleteConfirmModal()}
    </>
  );
};

const getReadableConditionValue = (condition: PolicyRule["condition"]) => {
  if (!condition) return "";

  const option = OperatorOptionLookup(condition.operator);
  switch (option?.field) {
    case "value":
      return condition.value_label || condition.value;
    case "range":
      return (condition.value?.[0] || "") + " and " + (condition.value?.[1] || "");
    default:
      return "";
  }
};

export const useConditionLabel = (
  rule: PolicyRule | null | undefined,
  policyType?: PolicyType,
  policy?: Policy // policy will be undefined if creating new one
): string => {
  const condition = useConditionLookup(rule?.condition?.field || "", policyType);
  const allRules = policy?.rules;

  const hasCondition = !!rule?.condition?.field;
  const hasOnlyDefaultRule = allRules?.length === 1 && !hasCondition;

  if (hasOnlyDefaultRule) {
    return "Default rule";
  } else if (!hasCondition) {
    return "Fallback rule";
  } else if (rule?.condition?.field) {
    const fieldLabel = condition?.label;
    const operatorLabel = OperatorOptionLookup(rule.condition.operator)?.label;
    const conditionValue = getReadableConditionValue(rule.condition);

    return `If ${fieldLabel?.toLocaleLowerCase()} ${operatorLabel} ${conditionValue}`;
  } else {
    return "";
  }
};

const defaultRuleProps = {
  condition: undefined,
  fields: {},
  approval_flow: [],
  auto_approve: false,
  editing: true,
  default: false,
};

const buildDefaultRuleProps = (): PolicyRuleWithEditing => {
  return {
    ...defaultRuleProps,
    _id: ObjectID().toHexString(),
  };
};
