import {
  Activity,
  AggregatedJob,
  AggregatedTeamMember,
  AggregatedTimeOffRequest,
  BalanceEstimate,
  CreateTimeOffRequestParams,
  Department,
  MiterAPI,
  TimeOffPolicy,
  TimeOffRequest,
} from "dashboard/miter";
import { DateTime } from "luxon";
import { MapWithDefault, dayBetween, generateUUID } from "miter-utils";
import { CaretDown, Eye, EyeSlash, Pencil } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ActionModal, Badge, Button, DropdownButton, Formblock, Notifier } from "ui";
import { getTenure } from "miter-utils";
import { sumBy } from "lodash";
import { Option, ValidationRuleset } from "ui/form/Input";

import {
  NameFormatterInput,
  useActiveCompany,
  useActiveTeamMember,
  useDepartmentOptions,
  useIsMiterAdmin,
  useLookupDepartment,
  useLookupPolicy,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { useTimeOffRequestPolicy } from "miter-components/time-off/time-off-request-policy-utils";
import TimeOffRequestSchedule from "./TimeOffRequestSchedule";
import TimeOffRequestSummary from "./TimeOffRequestSummary";
import { NeedsAttentionBanner } from "dashboard/components/policies/NeedsAttentionBanner";
import ApprovalItemInfoModal from "dashboard/components/approvals/ApprovalItemInfomodal";
import { LookupAtomFunction } from "dashboard/atoms";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";
import { AuditLogHistoryModal } from "dashboard/components/audit-logs/AuditLogHistoryModal";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { InboxMode } from "../approvals/inboxUtils";

/** Helper data we need to build the form */
export type TimeOffRequestFormItems = {
  companyId: string;
  teamMemberId?: string;
  teamMemberOptions?: Option<string>[];
  teamMembersMap?: (id: string | null | undefined) => AggregatedTeamMember | undefined;
  timeOffPolicies?: TimeOffPolicy[];
  timeOffPoliciesMap: (id: string | null | undefined) => TimeOffPolicy | undefined;
  jobs?: AggregatedJob[];
  jobsMap?: (id: string | null | undefined) => AggregatedJob | undefined;
  jobOptions?: Option<string>[];
  activities?: Activity[];
  activitiesMap?: (id: string | null | undefined) => Activity | undefined;
  activityOptions?: Option<string>[];
  activityOptionsMap?: MapWithDefault<string, Option<string>[]>;
  jobNameFormatter?: (a: NameFormatterInput<AggregatedJob>) => string;
  activityLabelFormatter?: (a: NameFormatterInput<Activity>) => string;
  selectableActivitiesMap?: MapWithDefault<string, Activity[]>;
};

export type TimeOffRequestForm = {
  // Base fields
  employee?: Option<string>;
  time_off_policy?: Option<string>;
  company_note?: string;
  employee_note?: string;

  // Time fields
  start_date?: DateTime;
  end_date?: DateTime;
  schedule_type?: "full" | "partial";
  include_weekends?: boolean;
  schedule?: TimeOffRequestFormSchedule;
  department_id?: Option<string>;
};

export type TimeOffRequestFormSchedule = {
  _id: string;
  date: string;
  hours: number;
  job_id?: string | null;
  activity_id?: string | null;
  status?: TimeOffRequest["status"];
}[];

type Props = {
  formItems: TimeOffRequestFormItems;
  timeOffRequest: AggregatedTimeOffRequest | TimeOffRequest | undefined;
  inboxMode?: InboxMode;
  onHide: () => void;
  onFinish: (keepOpen: boolean) => void;
};

export const required: ValidationRuleset = { required: "This field is required." };

const TimeOffRequestModal: React.FC<Props> = ({ timeOffRequest, formItems, onHide, onFinish, inboxMode }) => {
  const hasPolicy = timeOffRequest && (timeOffRequest.approval_stage || timeOffRequest.approval_history);

  /**********************************************************************************************************
   * State variables
   **********************************************************************************************************/
  const [editing, setEditing] = useState(!timeOffRequest);
  const [showAdvancedSchedule, setShowAdvancedSchedule] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showApprovalHistory, setShowApprovalHistory] = useState(false);
  const [showAuditLog, setShowAuditLog] = useState(false);
  const [balanceEstimate, setBalanceEstimate] = useState<BalanceEstimate>();

  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const company = useActiveCompany();
  const lookupPolicy = useLookupPolicy();
  const lookupDepartment = useLookupDepartment();
  const lookupTeam = useLookupTeam();
  const departmentOptions = useDepartmentOptions();
  const activeTeamMember = useActiveTeamMember();
  const isMiterAdmin = useIsMiterAdmin();
  const form = useForm<TimeOffRequestForm>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
    defaultValues: buildDefaultValues(timeOffRequest, formItems, lookupDepartment),
  });
  useWatch({ control: form.control });

  const { can, cannot } = useTimeOffRequestAbilities({ inboxMode });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { watch, errors } = form;
  const formData = watch();

  const currentTimeOffRequestData: Partial<TimeOffRequest> | AggregatedTimeOffRequest | undefined =
    useMemo(() => {
      return {
        team_member: formData.employee?.value,
        employee_note: formData.employee_note,
        company_note: formData.company_note,
        department_id: formData.department_id?.value,
      };
    }, [formData]);

  const { isFieldVisible, isFieldRequired, policy } = useTimeOffRequestPolicy({
    item: currentTimeOffRequestData,
    company,
    lookupPolicy,
    lookupDepartment,
    lookupTeam,
  });

  useEffect(() => {
    form.reset(formData);
  }, [policy]);
  const { teamMembersMap, timeOffPoliciesMap } = formItems;

  /**********************************************************************************************************
   * Get the estimated balance from the API with the summary values
   **********************************************************************************************************/
  useEffect(() => {
    getBalanceEstimate();
  }, [formData?.start_date, formData?.employee?.value, formData?.time_off_policy?.value]);

  const getBalanceEstimate = async () => {
    if (!formData?.start_date || !formData?.employee?.value || !formData?.time_off_policy?.value) return;

    try {
      const estimate = await MiterAPI.time_off.requests.getBalanceEstimate({
        requestStartDate: formData?.start_date?.toISO(),
        teamMemberId: formData?.employee?.value,
        timeOffPolicyId: formData?.time_off_policy?.value,
        timeOffRequestId: timeOffRequest?._id,
      });
      if (estimate) setBalanceEstimate(estimate);
    } catch (e: $TSFixMe) {
      Notifier.error("Error retrieving estimated balance");
      console.log(`Team member: ${formData?.employee?.value}, policy: ${formData.time_off_policy?.value}`);
      console.error(`Error retrieving estimated balance`, e);
    }
  };

  const disableImmutableFields = useMemo(() => {
    if (!timeOffRequest) return false;
    if (timeOffRequest.status !== "unapproved") return true;

    return false;
  }, [timeOffRequest]);

  const fullTeamMember = useMemo(() => {
    return teamMembersMap?.(formData?.employee?.value);
  }, [formData.employee, activeTeamMember]);

  const getPolicyOptions = () => {
    if (!fullTeamMember) return [];

    const teamMemberPolicies = fullTeamMember.time_off.policies ? fullTeamMember.time_off.policies : [];

    const teamMemberPoliciesWithConfig = teamMemberPolicies.map((tmPolicy) => {
      const policyConfig = timeOffPoliciesMap(tmPolicy.policy_id);
      return {
        ...tmPolicy,
        policy: policyConfig,
        level: policyConfig?.levels?.find((level) => level._id === tmPolicy.level_id),
      };
    });

    const tenure = getTenure(fullTeamMember.start_date);
    const activePolicyOptions = teamMemberPoliciesWithConfig
      .filter((tmPolicy) => {
        const policy = tmPolicy.policy;
        const level = tmPolicy.level;
        return (
          !!policy &&
          !policy.archived &&
          !!level &&
          (!level.min_tenure_for_requests || level.min_tenure_for_requests <= tenure)
        );
      })
      .map((tmPolicy) => {
        return { ...tmPolicy.policy, label: tmPolicy.policy!.name, value: tmPolicy.policy!._id };
      });

    // If there are no policies active for this team member but there is a time off request with an old policy, add the old policy's information to the policy options
    if (activePolicyOptions.length === 0 && timeOffRequest && timeOffRequest.time_off_policy) {
      const policy =
        typeof timeOffRequest.time_off_policy === "string"
          ? timeOffPoliciesMap(timeOffRequest.time_off_policy)
          : timeOffRequest.time_off_policy;

      if (!!policy) activePolicyOptions.push({ ...policy, label: policy.name, value: policy._id });
    }
    return activePolicyOptions;
  };

  const timeOffPolicyOptions = useMemo(() => {
    return getPolicyOptions();
  }, [formData.employee, formItems]);

  /**********************************************************************************************************
   * useEffects
   **********************************************************************************************************/

  /**  Keeps the schedule in sync with the start and end date */
  useEffect(() => {
    handleStartAndEndChange();
  }, [formData?.start_date?.toSeconds(), formData?.end_date?.toSeconds(), formData?.include_weekends]);

  /**********************************************************************************************************
   * Helper functions
   **********************************************************************************************************/
  /**
   * When the start or end date changes
   * 1. Remove the days before the new start date and the days after the new end date from the schedule
   * 2. Add the days that are missing from the schedule between the new start and end date
   * */
  const handleStartAndEndChange = () => {
    const schedule = formData.schedule || [];
    const startDate = formData.start_date;
    const endDate = formData.end_date;
    const includeWeekends = formData.include_weekends;

    if (!startDate || !endDate) return;

    // Remove the days before the new start date and the days after the new end date from the schedule - take into account weekends
    const newSchedule = schedule.filter((row) => {
      if (!row.date) return false;
      const rowDate = DateTime.fromISO(row.date);

      const isWithinRange =
        rowDate.toSeconds() >= startDate.toSeconds() && rowDate.toSeconds() <= endDate.toSeconds();

      const isWeekendDay = rowDate.weekday >= 6 && rowDate.weekday <= 7;

      return isWithinRange && (includeWeekends || !isWeekendDay);
    });

    // Get the days between the new start and end date
    const daysBetween = dayBetween(startDate.toJSDate(), endDate.toJSDate(), includeWeekends);

    // Add the days that are missing from the schedule between the new start and end date
    const newScheduleWithMissingDays = daysBetween.reduce((newSchedule, date) => {
      const row = newSchedule.find((row) => row.date === date.toISODate());
      if (row) return newSchedule;
      return [...newSchedule, { _id: generateUUID(), date: date.toISODate(), hours: 8 }];
    }, newSchedule);

    // Set the new schedule
    form.setValue("schedule", newScheduleWithMissingDays);
  };

  const validateStartDate = (value: DateTime) => {
    if (value && formData.end_date && value > formData.end_date) {
      return "Must be before end";
    }
  };

  const validateEndDate = (value: DateTime) => {
    if (value && formData.start_date && value < formData.start_date) {
      return "Must be after start";
    }
  };

  const handleCancel = () => {
    if (editing && timeOffRequest) {
      form.reset();
      setEditing(false);
    } else {
      onHide();
    }
  };

  const handleDelete = () => {
    if (timeOffRequest?.status === "unapproved") {
      updateTimeOffRequestStatus("denied");
    } else if (timeOffRequest?.status === "approved") {
      updateTimeOffRequestStatus("unapproved");
    }
  };

  const handleSubmit = () => {
    if (!timeOffRequest || editing) {
      form.handleSubmit(saveTimeOffRequest)();
    } else if (timeOffRequest?.status === "unapproved") {
      updateTimeOffRequestStatus("approved");
    } else if (timeOffRequest?.status === "approved") {
      updateTimeOffRequestStatus("paid");
    }
  };

  const showDelete = useMemo(() => {
    if (!timeOffRequest || editing) return false;
    if (cannot("delete", timeOffRequest)) return false;

    const hideDeleteStatus = ["processing", "paid", "denied"];
    if (hideDeleteStatus.includes(timeOffRequest.status)) return false;

    return true;
  }, [timeOffRequest]);

  const showSubmit = useMemo(() => {
    if (!timeOffRequest || editing) return true;
    if (cannot("approve", timeOffRequest)) return false;

    if (timeOffRequest.status === "unapproved") return true;
    if (timeOffRequest.status === "approved") return true;

    return false;
  }, [timeOffRequest, editing]);

  /**********************************************************************************************************
   * Backend / API functions for saving
   **********************************************************************************************************/
  const buildParams = (params: TimeOffRequestForm) => {
    if (!params.employee?.value) throw new Error("Employee is required");
    if (!params.start_date) throw new Error("Start date is required");
    if (!params.end_date) throw new Error("End date is required");
    if (!params.schedule) throw new Error("Schedule is required");
    if (!params.time_off_policy?.value) throw new Error("Time off policy is required");

    // Make sure the time off request doesn't go over the employee's balance
    const timeOffPolicyVal = params.time_off_policy?.value;
    const timeOffPolicy =
      typeof timeOffPolicyVal === "string" ? timeOffPoliciesMap(timeOffPolicyVal) : timeOffPolicyVal;
    const totalHours = buildTotalHours(params.schedule);

    if (!fullTeamMember) throw new Error("Could not find team member");

    const tmPolicy = fullTeamMember.time_off?.policies?.find((p) => p.policy_id === timeOffPolicy?._id);

    const disableNegativeBalance = timeOffPolicy?.levels.find(
      (level) => level._id === tmPolicy?.level_id
    )?.disable_negative_balances;

    if (timeOffPolicy && disableNegativeBalance) {
      const estimatedAccrual = balanceEstimate?.accrualProjection || 0;
      const estimatedUsage = balanceEstimate?.usageProjection || 0;
      const balance = tmPolicy?.balance || 0;
      const estimatedBalance = balance + estimatedAccrual - estimatedUsage;

      if (balance != null && totalHours > estimatedBalance) {
        throw new Error(`
        The employee's current level in the time off policy does not allow negative balances.`);
      }
    }

    if (disableImmutableFields) {
      return {
        company_note: params.company_note,
        employee_note: params.employee_note,
        department_id: params.department_id?.value || null,
        schedule: params.schedule,
      };
    }

    const cleanedSchedule = params.schedule.map((day) => (day.hours == null ? { ...day, hours: 0 } : day));

    const data: CreateTimeOffRequestParams["data"] = {
      company: formItems.companyId,
      employee: params.employee.value,
      start_date: params.start_date.toISODate(),
      end_date: params.end_date.toISODate(),
      schedule: cleanedSchedule.map((schedule) => ({ ...schedule, _id: undefined })),
      time_off_policy: params.time_off_policy.value,
      total_hours: totalHours,
      status: timeOffRequest?.status || "unapproved",
      company_note: params.company_note,
      employee_note: params.employee_note,
      department_id: params.department_id?.value || null,
    };

    return data;
  };

  const saveTimeOffRequest = async (params: TimeOffRequestForm) => {
    setSaving(true);
    try {
      const cleanedParams = buildParams(params);
      const res = timeOffRequest?._id
        ? await MiterAPI.time_off.requests.update(timeOffRequest._id, { data: cleanedParams })
        : await MiterAPI.time_off.requests.create({ data: cleanedParams });

      if (res.error) throw new Error(res.error);

      Notifier.success("Time off request saved successfully");
      onFinish(false);
    } catch (e: $TSFixMe) {
      console.log("Error saving time off request", e);
      Notifier.error(e.message);
    }
    setSaving(false);
  };

  const updateTimeOffRequestStatus = async (newStatus: "unapproved" | "approved" | "denied" | "paid") => {
    setSaving(true);
    try {
      if (!timeOffRequest) throw new Error("Missing time off request");
      const res = await MiterAPI.time_off.requests.update(timeOffRequest._id, {
        data: { status: newStatus },
      });

      if (res.error) throw new Error(res.error);

      Notifier.success("Time off request saved successfully");
      onFinish(false);
    } catch (e: $TSFixMe) {
      console.log("Error saving time off request", e);
      Notifier.error(e.message);
    }

    setSaving(false);
  };

  /**********************************************************************************************************
   * Render functions
   **********************************************************************************************************/
  const renderBaseFields = () => {
    const employeesEmptyMessage =
      formItems.teamMemberOptions?.length === 0
        ? "No employees are enrolled in a policy."
        : "No employees found";

    return (
      <div className="form-section">
        {isMiterAdmin && (
          <Formblock
            label="ID*"
            type="text"
            name="_id"
            className="modal "
            editing={false}
            disabled={true}
            defaultValue={timeOffRequest?._id}
          />
        )}
        <Formblock
          label="Employee*"
          labelInfo={"Select the team member that you are applying the time off request to."}
          type="select"
          form={form}
          name="employee"
          options={formItems.teamMemberOptions || []}
          val={{ required: true }}
          className="modal "
          editing={true}
          requiredSelect={true}
          disabled={!editing || disableImmutableFields}
          defaultValue={formData.employee?.value}
          noOptionsMessage={employeesEmptyMessage}
          onChange={(employee) => {
            const deptId = lookupTeam(employee.value)?.department_id;
            const name = lookupDepartment(deptId)?.name;
            form.setValue("department_id", { label: name, value: deptId });
          }}
        />
        <Formblock
          label={"Time Off Policy*"}
          labelInfo={"Select the time off policy that you want to use for this request."}
          type="select"
          name="time_off_policy"
          options={timeOffPolicyOptions}
          className="modal "
          form={form}
          editing={true}
          requiredSelect={true}
          disabled={!editing || disableImmutableFields}
          defaultValue={formData.time_off_policy?.value}
        />
        {departmentOptions && formData.employee && departmentOptions.length > 0 && (
          <Formblock
            label={"Department"}
            type="select"
            form={form}
            name="department_id"
            options={departmentOptions || []}
            className="modal "
            defaultValue={timeOffRequest ? timeOffRequest.department_id : null}
            errors={errors}
            editing={editing}
            disabled={!editing}
            requiredSelect={false}
            isClearable={true}
          />
        )}
      </div>
    );
  };

  const renderNoteFields = () => {
    return (
      <div className="form-section">
        {timeOffRequest && isFieldVisible("company_note") && (
          <Formblock
            label={`Company note ${isFieldRequired("company_note") ? "*" : ""}`}
            register={form.register(isFieldRequired("company_note") ? required : undefined)}
            type="paragraph"
            name="company_note"
            className="modal time-off-request-notes"
            form={form}
            inputStyle={{ height: "100px !important" }}
            editing={editing}
            defaultValue={formData.company_note}
          />
        )}
        {isFieldVisible("employee_note") && (
          <Formblock
            label={`Employee note ${isFieldRequired("employee_note") ? "*" : ""}`}
            type="paragraph"
            name="employee_note"
            className="modal time-off-request-notes"
            register={form.register(isFieldRequired("employee_note") ? required : undefined)}
            form={form}
            inputStyle={{ height: "100px !important" }}
            editing={editing}
            defaultValue={formData.employee_note}
          />
        )}
      </div>
    );
  };

  const renderHoursButtonContent = () => {
    if (!editing || disableImmutableFields) {
      if (showAdvancedSchedule) {
        return (
          <>
            {" "}
            <EyeSlash style={{ marginBottom: -2, marginRight: 5 }} />
            Hide hours
          </>
        );
      } else {
        return (
          <>
            <Eye style={{ marginBottom: -2, marginRight: 5 }} />
            Show hours
          </>
        );
      }
    } else {
      if (showAdvancedSchedule) {
        return (
          <>
            <EyeSlash style={{ marginBottom: -2, marginRight: 5 }} />
            Hide hours
          </>
        );
      } else {
        return (
          <>
            <Pencil style={{ marginBottom: -2, marginRight: 5 }} />
            Edit hours
          </>
        );
      }
    }
  };

  const renderTimeFields = () => {
    const datesClassName = !showAdvancedSchedule
      ? "flex align-items-top width-100-percent"
      : "flex align-items-top width-100-percent";

    return (
      <div className="form-section">
        <div
          className={datesClassName}
          style={!editing || disableImmutableFields ? {} : { marginBottom: 15 }}
        >
          <Formblock
            label={"Start Date"}
            labelInfo={"Start date for the time off request"}
            type="datetime"
            name="start_date"
            className="modal time-off-request-date"
            editing={true}
            disabled={!editing || disableImmutableFields}
            dateOnly={true}
            form={form}
            style={{ height: "100%", marginRight: 10 }}
            rules={{ validate: validateStartDate }}
            defaultValue={formData.start_date}
            customFormat={"EEE, DD"}
          />
          <Formblock
            label={"End Date"}
            labelInfo={"End date for the time off request"}
            type="datetime"
            name="end_date"
            className="modal time-off-request-date"
            errors={errors}
            editing={true}
            disabled={!editing || disableImmutableFields}
            dateOnly={true}
            form={form}
            rules={{ validate: validateEndDate }}
            defaultValue={formData.end_date}
            customFormat={"EEE, DD"}
          />
          {formData.start_date && formData.end_date && (
            <Button
              className="button-1 no-margin"
              onClick={() => setShowAdvancedSchedule(!showAdvancedSchedule)}
              wrapperStyle={{ marginTop: 23, justifyContent: "flex-end", width: 130 }}
              style={{ height: 32, marginLeft: 15, marginRight: 0, width: "100%" }}
            >
              {renderHoursButtonContent()}
            </Button>
          )}
        </div>
        <Formblock
          text={"Include weekend hours?"}
          type="checkbox"
          name="include_weekends"
          className="modal"
          editing={true}
          disabled={!editing || disableImmutableFields}
          form={form}
          style={
            !editing || disableImmutableFields ? { marginTop: -5, marginBottom: -5 } : { marginTop: -20 }
          }
          defaultValue={formData.include_weekends}
        />
      </div>
    );
  };

  const renderSchedule = () => {
    if (!formData.start_date || !formData.end_date || !showAdvancedSchedule) return;

    return (
      <TimeOffRequestSchedule
        timeOffRequest={timeOffRequest}
        form={form}
        editing={!!editing}
        formItems={formItems}
        onFinish={onFinish}
      />
    );
  };

  const renderSummary = () => {
    const employee = formItems.teamMembersMap?.(formData.employee?.value);
    const timeOffPolicy = timeOffPoliciesMap(formData.time_off_policy?.value);

    if (!employee || !timeOffPolicy || !formData.start_date) return;

    return (
      <>
        <TimeOffRequestSummary
          teamMember={employee}
          timeOffPolicy={timeOffPolicy}
          startDate={formData.start_date}
          timeOffRequest={timeOffRequest}
          schedule={formData.schedule || []}
          balanceEstimate={balanceEstimate}
        />
      </>
    );
  };

  const renderModalTitle = () => {
    if (!timeOffRequest) return "Create Time Off Request";

    if (editing) {
      return "Edit Time Off Request";
    } else {
      return "Manage Time Off Request";
    }
  };

  const buttonDropdownItems: ActionLink[] = useMemo(() => {
    const items: ActionLink[] = [];

    if (hasPolicy) {
      items.push({ label: "Approval History", action: () => setShowApprovalHistory(true) });
    }

    if (isMiterAdmin) {
      items.push({ label: "Audit Log (Mitosaur only)", action: () => setShowAuditLog(true) });
    }

    return items;
  }, []);

  const renderModalHeader = () => {
    const title = renderModalTitle();
    return (
      <div className="flex space-between ">
        {title}
        {timeOffRequest && (
          <Badge style={{ marginBottom: -1, marginLeft: 7 }} text={timeOffRequest?.status} />
        )}
        {hasPolicy && (
          <DropdownButton
            options={buttonDropdownItems}
            className="button-1 modal-dropdown-button"
            wrapperStyle={{ marginLeft: 30 }}
            closeOnClick={true}
          >
            Actions
            <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
          </DropdownButton>
        )}
      </div>
    );
  };

  const renderDeleteText = () => {
    if (!timeOffRequest) return;

    if (timeOffRequest.status === "unapproved") {
      return "Deny";
    } else if (timeOffRequest.status === "approved") {
      return "Unapprove";
    } else {
      return;
    }
  };

  const renderSubmitText = () => {
    if (!timeOffRequest || editing) return "Save";

    if (timeOffRequest.status === "unapproved") return "Approve";
    if (timeOffRequest.status === "approved") return "Mark as paid";
  };

  /**********************************************************************************************************
   * Style variables
   **********************************************************************************************************/
  const pageStyle = showAdvancedSchedule
    ? { paddingTop: 20, paddingBottom: 20, display: "grid", gridGap: 80, gridTemplateColumns: "480px 1fr" }
    : { paddingTop: 20, paddingBottom: 20 };

  const wrapperStyle = showAdvancedSchedule
    ? { width: "85%", minWidth: 1200, maxHeight: 800 }
    : { minWidth: 550, maxHeight: 800 };

  return (
    <>
      <ActionModal
        headerText={renderModalHeader()}
        onHide={onHide}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        showCancel={true}
        cancelText={editing ? "Cancel" : "Close"}
        submitText={renderSubmitText()}
        editText="Edit"
        showEdit={!!timeOffRequest && !editing && can("update", timeOffRequest)}
        onEdit={() => setEditing(true)}
        showSubmit={showSubmit}
        wrapperStyle={wrapperStyle}
        bodyStyle={{ maxHeight: 580 }}
        loading={saving}
        deleteText={renderDeleteText()}
        showDelete={showDelete}
        onDelete={handleDelete}
      >
        <NeedsAttentionBanner
          style={{ marginLeft: -20, marginRight: -20 }}
          item={timeOffRequest}
          policyType={"time_off_request"}
        />
        <div style={pageStyle}>
          <div>
            {showApprovalHistory && timeOffRequest && (
              <ApprovalItemInfoModal
                onHide={() => {
                  setShowApprovalHistory(false);
                }}
                item={timeOffRequest}
              ></ApprovalItemInfoModal>
            )}
            {showAuditLog && timeOffRequest && (
              <AuditLogHistoryModal
                itemId={timeOffRequest._id}
                type={"time_off_request"}
                onHide={() => setShowAuditLog(false)}
              />
            )}
            {renderBaseFields()}
            {renderTimeFields()}
            {renderNoteFields()}
            {renderSummary()}
          </div>
          <div>{renderSchedule()}</div>
        </div>
      </ActionModal>
    </>
  );
};

export default TimeOffRequestModal;

/**********************************************************************************************************
 * Helper function to build default values for the form
 **********************************************************************************************************/
const buildDefaultValues = (
  timeOffRequest: AggregatedTimeOffRequest | TimeOffRequest | undefined | null,
  formItems: TimeOffRequestFormItems,
  lookupDepartment: LookupAtomFunction<Department>
): TimeOffRequestForm => {
  if (!timeOffRequest) return { schedule: [] };
  const { teamMemberId, teamMemberOptions, timeOffPolicies } = formItems;

  const employee =
    teamMemberId ||
    (typeof timeOffRequest.employee === "string" ? timeOffRequest.employee : timeOffRequest.employee?._id);

  const employeeOption = teamMemberOptions?.find((option) => option.value === employee);

  const timeOffPolicy =
    typeof timeOffRequest.time_off_policy === "string"
      ? timeOffPolicies?.find((p) => p._id === timeOffRequest.time_off_policy)
      : timeOffRequest.time_off_policy;

  const timeOffPolicyOption = timeOffPolicy
    ? { label: timeOffPolicy.name, value: timeOffPolicy._id }
    : undefined;

  const department = lookupDepartment(timeOffRequest.department_id);

  const departmentOption = department ? { label: department.name, value: department._id } : undefined;

  const hasPartialHours = timeOffRequest.schedule?.some((s) => s.hours > 0 && s.hours < 8);
  const scheduleType = hasPartialHours ? "partial" : "full";

  const hasWeekendHours = timeOffRequest?.schedule?.some(
    (day) => DateTime.fromISO(day.date).weekday === 6 || DateTime.fromISO(day.date).weekday === 7
  );
  const includeWeekends = hasWeekendHours;

  const schedule = timeOffRequest.schedule?.map((day) => ({ _id: generateUUID(), ...day }));

  return {
    employee: employeeOption,
    time_off_policy: timeOffPolicyOption,
    start_date: DateTime.fromISO(timeOffRequest.start_date),
    end_date: DateTime.fromISO(timeOffRequest.end_date),
    schedule_type: scheduleType,
    include_weekends: includeWeekends,
    company_note: timeOffRequest.company_note || undefined,
    schedule,
    employee_note: timeOffRequest.employee_note || undefined,
    department_id: departmentOption,
  };
};
// Calculate the total hours of time off requested based on the schedule hours
export const buildTotalHours = (schedule: TimeOffRequestFormSchedule): number => {
  return sumBy(schedule, "hours");
};
