import { BooleanCheckmarkCell } from "dashboard/components/agGridTable/agGridUtils";
import {
  useRefetchActivities,
  useRefetchCostTypes,
  useRefetchDepartments,
  useRefetchJobs,
  useRefetchLedgerAccounts,
  useRefetchLocations,
  useRefetchTeam,
  useRefetchWorkplaces,
} from "dashboard/hooks/atom-hooks";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Formblock, LargeModal } from "ui";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { useDebouncedCallback } from "use-debounce";
import {
  IntegrationEntity,
  IntegrationSyncOpDirection,
  SyncableObject,
  SyncableObjectResult,
} from "backend/models/integrations/integrations";
import { SyncableLedgerEntry } from "backend/services/integrations/get-syncable-miter-objects-service";
import { LedgerEntry } from "../accounting/LedgerEntries/LedgerEntry";
import { getDirectionName, integrationEntityLookup } from "./IntegrationSyncHistory";
import { IDetailCellRendererParams } from "ag-grid-community";
import { TableActionLink } from "ui/table-v2/Table";

type Props = {
  integration: MiterIntegrationForCompany;
  entity: IntegrationEntity;
  direction: IntegrationSyncOpDirection;
  hide: () => void;
  getSyncs: () => Promise<void>;
};

type TableEntry = SyncableObject<unknown> & { _id: string };

const EntityLabelLookup: Record<IntegrationEntity, string> = {
  jobs: "Job",
  activities: "Activity",
  ledger_entries: "Entry",
  departments: "Department",
  ledger_accounts: "Account",
  team_members: "Team member",
  benefits: "Benefit",
  employee_benefits: "Benefit",
  timesheets: "Timesheet",
  cost_types: "Cost type",
  expenses: "Expense",
  standard_classifications: "Standard classification",
  equipment: "Equipment",
  quantities: "Quantities",
  locations: "Location",
  post_tax_deductions: "Post-tax deduction",
};

export const BatchSyncModal: React.FC<Props> = ({ integration, entity, direction, hide, getSyncs }) => {
  const form = useForm();
  const refetchJobs = useRefetchJobs();
  const refetchTeam = useRefetchTeam();
  const refetchActivities = useRefetchActivities();
  const refetchCostTypes = useRefetchCostTypes();
  const refetchLedgerAccounts = useRefetchLedgerAccounts();
  const refetchDepts = useRefetchDepartments();
  const refetchWorkplaces = useRefetchWorkplaces();
  const refetchLocations = useRefetchLocations();

  const { limitEnabled, modifiedSinceEnabled } = useMemo(() => {
    const queryCapabilities = integration.supported_operations[entity]?.[direction]?.query_capabilities;
    return {
      limitEnabled: queryCapabilities?.limit,
      modifiedSinceEnabled: queryCapabilities?.modified_since,
    };
  }, [integration]);

  const [modifiedSince, setModifiedSince] = useState<DateTime | undefined>(
    modifiedSinceEnabled ? DateTime.now().startOf("day").minus({ weeks: 1 }) : undefined
  );
  const [limit, setLimit] = useState<string | undefined>(limitEnabled ? "300" : undefined);
  const [tableEntries, setTableEntries] = useState<TableEntry[]>();
  const [tableColumns, setTableColumns] = useState<ColumnConfig<TableEntry>[]>([
    { headerName: EntityLabelLookup[entity] || "Label", field: "label" },
  ]);
  const [incompleteObjects, setIncompleteObjects] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedEntries, setSelectedEntries] = useState<TableEntry[]>([]);
  const [ledgerEntryModal, setLedgerEntryModal] = useState<SyncableLedgerEntry>();

  const getSyncableObjects = async () => {
    setLoading(true);
    try {
      if (!integration.connection) return;
      const result = await MiterAPI.integrations.get_syncable_objects({
        id: integration.connection._id.toString(),
        entity,
        direction,
        modifiedSince: modifiedSince?.toSeconds(),
        limit: limit,
      });
      if (result.error) throw new Error(result.error);
      setTableEntries(
        result.objects.map((o, i) => {
          return { ...o, _id: i.toString() };
        })
      );
      updateTableColumns(result);
      setIncompleteObjects(!!result.incompleteObjects);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`Error fetching data: ${e.message}`);
    }
    setLoading(false);
  };

  const [syncing, setSyncing] = useState(false);
  const syncObjects = async () => {
    setSyncing(true);
    try {
      if (!integration.connection) return;
      const result = await MiterAPI.integrations.run_manual_sync({
        id: integration.connection._id.toString(),
        operation: {
          entity,
          direction,
          objectsToSync: incompleteObjects ? undefined : selectedEntries.map((e) => e.object),
          idsToSync: incompleteObjects ? selectedEntries.map((e) => e.id) : undefined,
          integration: integration.connection?.integration_key,
        },
        trigger: "manual",
      });
      if (result.error) throw new Error(result.error);
      getSyncs();
      if (result.some((sync) => sync.error_message || sync.status === "error")) {
        Notifier.warning(`One or more syncs contained errors. Check the sync history for more details.`);
      } else {
        Notifier.success(`Sync completed!`);
      }

      if (direction === "pull") {
        if (entity === "jobs" || entity === "activities") {
          // Combining because of custom activities and close relationship
          refetchJobs();
          refetchWorkplaces();
          refetchActivities();
        } else if (entity === "departments") {
          refetchDepts();
        } else if (entity === "ledger_accounts") {
          refetchLedgerAccounts();
        } else if (entity === "cost_types") {
          refetchCostTypes();
        } else if (entity === "team_members") {
          refetchTeam();
          refetchWorkplaces();
        } else if (entity === "locations") {
          refetchLocations();
        }
      }
      hide();
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error syncing the objects.`);
    }
    setSyncing(false);
  };

  const updateTableColumns = (result: SyncableObjectResult) => {
    const columns: ColumnConfig<TableEntry>[] = [
      {
        field: "label",
        headerName: EntityLabelLookup[entity] || "Label",
        filter: true,
        minWidth: 400,
        maxWidth: 500,
      },
      {
        headerName: "ID",
        field: "id",
        filter: "agTextColumnFilter",
      },
    ];
    if (direction === "push" && entity === "ledger_entries") {
      columns.push({
        headerName: "",
        field: "button",
        width: 140,
        cellRenderer: (params: IDetailCellRendererParams<TableEntry>) => {
          return (
            <Button
              text="View"
              className="button-1"
              onClick={() => setLedgerEntryModal(params.data?.object as SyncableLedgerEntry | undefined)}
            />
          );
        },
      });
    }
    result.tableColumns?.forEach((column) => {
      columns.push({
        headerName: column.label,
        field: `object.${column.dataKey}`,
        filter: true,
        dataType: column.dataType,
        cellRenderer:
          column.dataType !== "boolean"
            ? undefined
            : (params) => {
                return <BooleanCheckmarkCell value={params.value} />;
              },
      });
    });
    if (direction === "pull" && result.objects[0]?.linked_to_miter != null) {
      columns.push({
        headerName: "Linked to Miter",
        field: "linked_to_miter",
        filter: true,
        cellRenderer: (params) => {
          return <BooleanCheckmarkCell value={params.value} />;
        },
      });
    }
    setTableColumns(columns);
  };

  const debouncedGetSyncableObjects = useDebouncedCallback(() => {
    getSyncableObjects();
  }, 500);

  useEffect(() => {
    setLoading(true);
    debouncedGetSyncableObjects();
  }, [modifiedSince?.toSeconds(), limit]);

  const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && isNaN(parseInt(e.target.value))) {
      Notifier.warning("Limit must be a number");
      return;
    }
    if (parseInt(e.target.value) < 0) {
      Notifier.warning("Limit must be 0 or greater");
      return;
    }
    if (parseInt(e.target.value) > 1000) {
      Notifier.warning("Limit must not be greater than 1000");
      return;
    }

    setLimit(e.target.value);
  };

  const queryCapabilities = useMemo(() => {
    return integration?.supported_operations?.[entity]?.[direction]?.query_capabilities || {};
  }, [integration, entity, direction]);

  const dynamicActions: TableActionLink[] = [
    {
      label: `Sync selected ${integrationEntityLookup[entity]}`,
      action: syncObjects,
      loading: syncing,
      className: "button-2",
    },
  ];

  const closeModal = () => {
    if (syncing) {
      Notifier.warning("Your sync is currently in progress, please wait for it to finish.");
      return;
    }
    hide();
  };

  return (
    <LargeModal
      headerText={`Sync ${integrationEntityLookup[entity]} ${getDirectionName(
        integration.label,
        direction,
        false
      )}`}
      onClose={closeModal}
    >
      {ledgerEntryModal && (
        <LargeModal headerText={ledgerEntryModal.description} onClose={() => setLedgerEntryModal(undefined)}>
          <div className="height-100">
            <div className="vertical-spacer-small"></div>
            <LedgerEntry ledgerEntryId={ledgerEntryModal._id} />
          </div>
        </LargeModal>
      )}
      {(queryCapabilities.modified_since || queryCapabilities.limit) && <div className="vertical-spacer" />}
      <div className="flex">
        <div>
          {queryCapabilities.modified_since && (
            <Formblock
              form={form}
              name="modifiedSince"
              type="datetime"
              dateOnly={true}
              label={`Fetch ${integrationEntityLookup[entity]} modified since`}
              style={{ alignItems: "center" }}
              inputProps={{ style: { width: 160 } }}
              defaultValue={modifiedSince}
              onChange={setModifiedSince}
              editing={true}
              labelStyle={{ width: 255 }}
              disableClearable={true}
            />
          )}
          {queryCapabilities.limit && (
            <Formblock
              form={form}
              name="limit"
              type="text"
              label={`Max # ${integrationEntityLookup[entity]} to fetch`}
              style={{ alignItems: "center" }}
              inputProps={{ style: { width: 160 } }}
              value={limit}
              onChange={handleLimitChange}
              editing={true}
              labelStyle={{ minWidth: 255 }}
            />
          )}
        </div>
      </div>
      <TableV2
        id="syncable-objects-table"
        resource="records"
        data={tableEntries}
        isLoading={loading}
        onSelect={setSelectedEntries}
        columns={tableColumns}
        gridWrapperStyle={{ height: 400 }}
        dynamicActions={dynamicActions}
      />
    </LargeModal>
  );
};
