/* global google */
import React, { useEffect, useState } from "react";
import { Formblock, usdString } from "ui";
import {
  AggregatedExpenseReimbursement,
  AggregatedTimesheet,
  ExpenseReimbursement,
  MiterAPI,
  MiterFilterArray,
} from "dashboard/miter";
import { DateTime } from "luxon";
import { roundTo, timezoneOptions } from "miter-utils";
import { useLookupPerDiemRates } from "dashboard/hooks/atom-hooks";
import pluralize from "pluralize";
import { useLoadGoogleMaps } from "dashboard/utils/geolocation";
import { GoogleMap } from "@react-google-maps/api";
import mapMarkerGreen from "dashboard/assets/mapMarkerGreen.png";
import mapMarkerBlue from "dashboard/assets/mapMarkerBlue.png";
import "./ExpenseReimbursementPerDiemInfo.css"; // apply these styles to the Google Maps components that will be created

const fitBoundsWithInfoBox = (
  map: google.maps.Map,
  bounds: google.maps.LatLngBounds,
  infoBoxHeight = 100
) => {
  const mapHeight = map.getDiv().clientHeight;
  const paddingBottom = infoBoxHeight;

  // Convert to latitude distance
  const latPadding =
    (paddingBottom / mapHeight) * (bounds.getNorthEast().lat() - bounds.getSouthWest().lat());

  bounds.extend({
    lat: bounds.getNorthEast().lat() + latPadding,
    lng: bounds.getNorthEast().lng(),
  });

  map.fitBounds(bounds);
};

const PerDiemCadenceHumanReadableLookup = {
  hourly: "Hour",
  daily: "Day",
};

type Props = {
  reimbursement: ExpenseReimbursement | AggregatedExpenseReimbursement;
};
export const ExpenseReimbursementPerDiemInfo: React.FC<Props> = ({ reimbursement }) => {
  const { isLoaded } = useLoadGoogleMaps();
  const [timesheets, setTimesheets] = useState<AggregatedTimesheet[]>([]);
  const lookupPerDiemRate = useLookupPerDiemRates();

  const perDiemRate = lookupPerDiemRate(reimbursement.per_diem_rate_id);

  // API call to get all timesheets
  useEffect(() => {
    const getTimesheets = async () => {
      try {
        const filter: MiterFilterArray = [
          {
            field: "_id",
            type: "_id" as const,
            comparisonType: "in" as const,
            value: reimbursement.timesheet_ids,
          },
          { field: "company", value: reimbursement.company_id },
        ];
        const response = await MiterAPI.timesheets.forage({ filter });
        setTimesheets(response.data);
      } catch (e) {
        console.error(`Error getting timesheets for per diem reimbursement view`, e);
      }
    };

    getTimesheets();
  }, []);

  const renderInfoAboutTimesheet = (timesheet: AggregatedTimesheet) => {
    return (
      <div key={timesheet._id}>
        <div className="tm-link" onClick={() => window.open(`/timesheets?tsid=${timesheet._id}`, "_blank")}>
          <span>Link to timesheet</span>
        </div>
        <div className="vertical-spacer" />

        <Formblock
          label="Clock in"
          type="datetime"
          name="clock_in"
          editing={false}
          defaultValue={DateTime.fromSeconds(timesheet.clock_in, { zone: timesheet?.timezone })}
          timezone={timesheet.timezone}
          customFormat={"MMM d, yyyy 'at' h:mm:ss a ZZZZ"}
        />
        <Formblock
          label="Clock out"
          type="datetime"
          name="clock_out"
          editing={false}
          defaultValue={DateTime.fromSeconds(timesheet.clock_out, { zone: timesheet?.timezone })}
          timezone={timesheet.timezone}
          customFormat={"MMM d, yyyy 'at' h:mm:ss a ZZZZ"}
        />
        {timesheet?.timezone && (
          <Formblock
            name="timezone"
            label="Timezone"
            type="select"
            defaultValue={timesheet.timezone}
            options={timezoneOptions}
            editing={false}
          />
        )}
        <Formblock
          label="Total hours"
          type="number"
          name="total_hours"
          editing={false}
          defaultValue={timesheet.hours}
        />
      </div>
    );
  };

  return (
    <>
      {(reimbursement.per_diem_rate_amount || reimbursement.distance_traveled_to_jobsite) && (
        <>
          <h4>Per Diem Information</h4>
          {reimbursement.per_diem_rate_amount && (
            <Formblock
              label="Rate"
              type="text"
              name="per_diem_rate_amount"
              editing={false}
              defaultValue={`${usdString(reimbursement.per_diem_rate_amount)}/${
                PerDiemCadenceHumanReadableLookup[perDiemRate?.cadence || ""]
              }`}
            />
          )}
          {reimbursement.distance_traveled_to_jobsite && (
            <Formblock
              label="Distance traveled to jobsite"
              type="text"
              name="distance_traveled_to_jobsite"
              editing={false}
              defaultValue={`${roundTo(reimbursement.distance_traveled_to_jobsite, 2)} miles`}
            />
          )}
          {reimbursement.origin_geolocation && reimbursement.jobsite_geolocation && isLoaded && (
            <>
              <div className="vertical-spacer"></div>
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: 250,
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  marginBottom: 25,
                }}
                options={{
                  disableDefaultUI: true,
                  mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP],
                  },
                }}
                onLoad={(map: google.maps.Map) => {
                  const bounds = new window.google.maps.LatLngBounds();
                  bounds.extend(reimbursement.origin_geolocation!);
                  bounds.extend(reimbursement.jobsite_geolocation!);

                  const originMarker = new google.maps.Marker({
                    position: reimbursement.origin_geolocation,
                    icon: { url: mapMarkerBlue, scaledSize: new google.maps.Size(25, 25) },
                    map,
                  });

                  const jobMarker = new google.maps.Marker({
                    position: reimbursement.jobsite_geolocation,
                    icon: { url: mapMarkerGreen, scaledSize: new google.maps.Size(25, 25) },
                    map,
                  });

                  const originInfoWindow = new google.maps.InfoWindow({
                    content: "Origin",
                    disableAutoPan: true,
                  });

                  const jobsiteInfoWindow = new google.maps.InfoWindow({
                    content: "Jobsite",
                    disableAutoPan: true,
                  });

                  originInfoWindow.open({
                    anchor: originMarker,
                    map,
                  });

                  jobsiteInfoWindow.open({
                    anchor: jobMarker,
                    map,
                  });

                  fitBoundsWithInfoBox(map, bounds);
                }}
              ></GoogleMap>
            </>
          )}
        </>
      )}

      <h4>Timesheet Information</h4>
      <p>
        Information about the {pluralize("timesheet", timesheets.length)} that generated this per diem
        reimbursement.
      </p>
      {timesheets.map(renderInfoAboutTimesheet)}
    </>
  );
};
