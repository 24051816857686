import { MiterAPI, PerformanceReviewCycle } from "dashboard/miter";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./Performance.module.css";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import useWizard from "ui/modal/useWizard";
import { isEmpty, set } from "lodash";
import { Assign } from "utility-types";
import { Option } from "ui/form/Input";
import { Formblock, Label, Notifier, WizardScreen } from "ui";
import {
  calculateLastReviewCycleStartDate,
  finalizeReviewSettings,
  startReviewCycle,
} from "dashboard/utils/performance";
import { hasNoTruthyValues } from "dashboard/utils";
import { useRefetchPerformanceReviewSchedules } from "dashboard/hooks/atom-hooks";

type Props = {
  name: string;
  performanceReviewCycle: PerformanceReviewCycle;
  setPerformanceReviewCycle: (performanceReviewCycle: PerformanceReviewCycle) => void;
};

export type RequestStyle = "start_of_cycle" | "anniversary";

export const dueDateOptions = [
  { label: "1 week after request", value: "1_week" },
  { label: "2 weeks after request", value: "2_weeks" },
  { label: "3 weeks after request", value: "3_weeks" },
];

export const cadenceOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Semi annually", value: "semi_annually" },
  { label: "Annually", value: "annually" },
];

export const mapCadenceToNoun = {
  monthly: "month",
  quarterly: "quarter",
  semi_annually: "half year",
  annually: "year",
};

const reviewValidation = (selfEnabled: boolean, reportEnabled: boolean) => {
  if (!selfEnabled && !reportEnabled) {
    return "At least one type of review must be enabled.";
  }
  return true;
};

const requestStyleOptions = [
  { label: "Periodically, based on the start date of the review cycle ", value: "start_of_cycle" },
  { label: "Periodically, based on the employee's start date anniversary ", value: "anniversary" },
  { label: "Once, based on the employee's start date", value: "start_date" },
  { label: "Once, based on the date of employee joining performance schedule", value: "date_added" },
];

export type FormFields = Assign<
  PerformanceReviewCycle,
  {
    next_start_date: DateTime;
    cadence: Option<string>;
    review_period: Option<string>;
    team_member_ids: Option<string>[];
  }
>;

export const ReviewCycleCreationScreen: FC<Props> = ({
  performanceReviewCycle,
  setPerformanceReviewCycle,
  name,
}) => {
  const { setCanNext, screens, curIndex, handleComplete, setNextButtonText } = useWizard();
  const { cadence, review_period, next_start_date, team_member_ids, request_style, finalized, ...rest } =
    performanceReviewCycle;
  const [cadenceEditable, setCadenceEditable] = useState(request_style === "start_of_cycle");
  const [isOneTimeReview, setIsOneTimeReview] = useState(
    ["start_date", "date_added"].includes(request_style)
  );
  const [daysOffsetText, setDaysOffsetText] = useState({
    prefix: "",
    suffix: "",
  });
  const form = useForm<FormFields>({
    mode: "all",
    defaultValues: {
      ...rest,
    },
  });
  const [formErrors, setFormErrors] = useState({});

  // Form
  const { register, errors, control, formState, trigger, getValues, setValue } = form;

  const reviewTypeValidation = {
    validate: {
      atLeastOneEnabled: () =>
        reviewValidation(getValues("self_review.enabled"), getValues("direct_report_review.enabled")),
    },
  };

  const refetchPerformanceReviewSchedules = useRefetchPerformanceReviewSchedules();

  const submitForm = async () => {
    try {
      const res = await MiterAPI.performance_review_cycles.update(performanceReviewCycle._id, {
        ...performanceReviewCycle,
      });
      if (res.error) {
        throw new Error(res.error);
      }
      setPerformanceReviewCycle(res as unknown as PerformanceReviewCycle);
      refetchPerformanceReviewSchedules();
      Notifier.success("Performance review cycle updated.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error(e);
    }
  };

  const onNext = async () => {
    await submitForm();
    if (curIndex === screens.length - 1) {
      const finalizeError = await finalizeReviewSettings(performanceReviewCycle);
      if (finalizeError) {
        Notifier.error(finalizeError);
      }
      const startError = await startReviewCycle(performanceReviewCycle);
      if (startError) {
        Notifier.error(startError);
      }
      handleComplete();
    }
  };

  useEffect(() => {
    if (curIndex === screens.length - 1) {
      setNextButtonText("Save and exit");
    }
  }, []);

  useEffect(() => {
    let prefix = "Once, ";
    let suffix = "";
    if (request_style === "start_of_cycle" || request_style === "anniversary") {
      prefix = "Periodically, ";
    }

    if (request_style === "start_of_cycle") {
      suffix = "days from the start of the review cycle";
    } else if (request_style === "anniversary") {
      suffix = "days from the employee's start date anniversary";
    } else if (request_style === "start_date") {
      suffix = "days from the employee's start date";
    } else if (request_style === "date_added") {
      suffix = "days from the employee joining performance schedule";
    }
    setDaysOffsetText({ prefix, suffix });
  }, [request_style]);

  // The earliest possible start date should within the timespan of the past review cycle
  const calculateMinStartDate = useCallback(() => {
    return calculateLastReviewCycleStartDate(DateTime.now(), cadence).plus({ days: 1 }).startOf("day");
  }, [cadence]);

  useEffect(() => {
    if (!isEmpty(formState.errors) || !hasNoTruthyValues(formErrors)) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [formState, formErrors]);

  useEffect(() => {
    if (!next_start_date || !cadence) return;
    const enteredStartDate = DateTime.fromISO(next_start_date);
    const minStartDate = calculateMinStartDate();

    setFormErrors({
      ...formErrors,
      next_start_date:
        enteredStartDate < minStartDate
          ? `Start date must be within the selected cadence (past ${mapCadenceToNoun[cadence]}).`
          : null,
    });
  }, [calculateMinStartDate, next_start_date]);

  // Wizard

  // Dynamically render the number of wizard screens based on whether self review and/or direct report review is enabled
  const handleFormEnabledCheckboxes = (field: string, checked: boolean) => {
    trigger(["self_review.enabled", "direct_report_review.enabled"]);
    const newValues = { ...performanceReviewCycle };
    set(newValues, field, checked);
    setPerformanceReviewCycle(newValues);
  };

  const generateCadence = (newStyle: RequestStyle) => {
    const isOneTime = ["start_date", "date_added"].includes(newStyle);
    if (newStyle === "anniversary") {
      return "annually";
    }
    if (isOneTime) {
      return null;
    }
    return cadence || "quarterly";
  };

  const generateNextStartDate = (newStyle: RequestStyle) => {
    const isOneTime = ["start_date", "date_added"].includes(newStyle);
    const currentStartDate = performanceReviewCycle.next_start_date;
    const januaryFirst = DateTime.now().set({ month: 1, day: 1 }).toISODate();
    const today = DateTime.now().toISODate();
    if (newStyle === "anniversary") {
      return januaryFirst;
    }
    if (isOneTime) {
      return null;
    }
    return currentStartDate || today;
  };

  const handleRequestStyleChange = (newStyle: RequestStyle) => {
    const updatedOneTimeReview = ["start_date", "date_added"].includes(newStyle);
    setCadenceEditable(newStyle === "start_of_cycle");
    setIsOneTimeReview(updatedOneTimeReview);

    setPerformanceReviewCycle({
      ...performanceReviewCycle,
      request_style: newStyle,
      cadence: generateCadence(newStyle),
      next_start_date: generateNextStartDate(newStyle),
    });
  };

  useEffect(() => {
    if (performanceReviewCycle.next_start_date) {
      setValue("next_start_date", DateTime.fromISO(performanceReviewCycle.next_start_date));
    }
  }, [performanceReviewCycle.next_start_date]);

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        {/* Name */}
        <Formblock
          name="name"
          type="text"
          label="Name*"
          register={register(vals.required)}
          errors={errors}
          editing={true}
          className="modal wizard"
          placeholder="Eg. Field crew quarterly review"
          onChange={(e) => setPerformanceReviewCycle({ ...performanceReviewCycle, name: e.target.value })}
        />

        {/* Request style */}
        <Formblock
          label="Evaluations will be requested*"
          type="select"
          name="schedule_type"
          register={register(vals.required)}
          className="modal wizard"
          control={control}
          errors={errors}
          editing={!finalized}
          options={requestStyleOptions}
          defaultValue={performanceReviewCycle.request_style}
          onChange={(e) => handleRequestStyleChange(e.value)}
          style={{ marginTop: 20 }}
        />

        <div style={{ display: "flex" }}>
          {daysOffsetText.prefix}
          <div className={styles["days-input"]}>
            <Formblock
              type="number"
              name="days_offset_request"
              control={control}
              className="modal time-off-request-date"
              register={register(vals.dollar)}
              sideBySideInput={false}
              editing={true}
              disabled={!!finalized}
              defaultValue={performanceReviewCycle.days_offset_request}
              onChange={(e) =>
                setPerformanceReviewCycle({ ...performanceReviewCycle, days_offset_request: e.target.value })
              }
              errors={errors}
            />
          </div>
          {daysOffsetText.suffix}
        </div>

        {/* Evaluations */}
        <Label style={{ marginTop: 15 }} label="Evaluations*" className="modal wizard" />
        <Formblock
          text="Reviewees will perform self-evaluations."
          type="checkbox"
          name={"self_review.enabled"}
          editing={true}
          register={register(reviewTypeValidation)}
          errors={errors}
          onChange={(e) => handleFormEnabledCheckboxes("self_review.enabled", e.target.checked)}
          style={{ marginTop: 10 }}
        />
        <Formblock
          className={styles["check-box"]}
          text="Reviewees will be evaluated by their managers."
          type="checkbox"
          name={"direct_report_review.enabled"}
          editing={true}
          register={register(reviewTypeValidation)}
          errors={errors}
          onChange={(e) => handleFormEnabledCheckboxes("direct_report_review.enabled", e.target.checked)}
          style={{ marginTop: -5 }}
        />
        {errors["non-field"] && <div className={styles["error"]}>{errors["non-field"].message}</div>}

        {/* Cadence */}
        {!isOneTimeReview ? (
          <div style={{ marginTop: 25 }}>
            <Formblock
              label="Cadence*"
              type="select"
              className={`modal `}
              name="cadence"
              options={cadenceOptions}
              editing={cadenceEditable && !finalized}
              control={control}
              errors={errors}
              register={register(vals.required)}
              placeholder="Select the cadence of review cycles"
              labelInfo={"This determines how often you want to conduct performance evaluations."}
              defaultValue={cadence}
              onChange={(e) => setPerformanceReviewCycle({ ...performanceReviewCycle, cadence: e.value })}
            />

            {/* Start date */}
            {next_start_date && (
              <Formblock
                label="Start date*"
                labelInfo={"This is when the new review cycle starts and evaluation requests are sent out."}
                type="datetime"
                dateOnly={true}
                name="next_start_date"
                control={control}
                rules={vals.required}
                min={calculateMinStartDate()}
                register={register(vals.required)}
                className="double time-off-request-date"
                editing={!finalized}
                placeholder="Select the review cycle start date"
                defaultValue={DateTime.fromISO(next_start_date)}
                style={{ marginTop: "20px" }}
                onChange={(e) => {
                  setPerformanceReviewCycle({ ...performanceReviewCycle, next_start_date: e.toISODate() });
                }}
              />
            )}

            {formErrors["next_start_date"] && (
              <div className="error-msg">{formErrors["next_start_date"]}</div>
            )}
          </div>
        ) : null}

        {/* Due date */}
        <Formblock
          label="Evaluations due*"
          type="select"
          className="modal "
          name="review_period"
          options={dueDateOptions}
          editing={true}
          register={register(vals.required)}
          control={control}
          requiredSelect={true}
          placeholder="Select when performance reviews are due"
          defaultValue={review_period}
          style={{ marginTop: "20px" }}
          onChange={(e) => setPerformanceReviewCycle({ ...performanceReviewCycle, review_period: e.value })}
        />

        {/* Automatic reminders */}
        <Formblock
          style={{ marginTop: "20px" }}
          text={
            <div style={{ display: "flex", flexDirection: "row" }}>
              {
                "Enable automated reminders for incomplete performance reviews 1 week before, 3 days before, and the day of the due date."
              }
            </div>
          }
          register={register}
          control={control}
          type="checkbox"
          name={"automated_reminders_enabled"}
          onChange={(e) =>
            setPerformanceReviewCycle({
              ...performanceReviewCycle,
              automated_reminders_enabled: e.target.checked,
            })
          }
          editing={true}
        />
      </div>
    </WizardScreen>
  );
};
