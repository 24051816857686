import { ReactElement, useMemo } from "react";
import { useState } from "react";
import { Button } from "ui";
import { AuditLogHistoryModal } from "./AuditLogHistoryModal";
import { AuditLogType } from "dashboard/utils/audit-log-utils";

type UseAuditLogHistory = {
  auditLogHistoryButton: ReactElement;
  auditLogHistoryModal: ReactElement;
};

export const useAuditLogHistory = (opts: {
  itemId: string;
  itemType: AuditLogType;
  refreshCounter: number;
  show: boolean;
}): UseAuditLogHistory => {
  const { itemId, itemType, refreshCounter } = opts;
  const [showModal, setShowModal] = useState(false);

  const auditLogHistoryButton = useMemo(() => {
    return <Button onClick={() => setShowModal(true)}>History</Button>;
  }, []);

  const auditLogHistoryModal = useMemo(() => {
    if (!showModal) return <></>;

    return (
      <AuditLogHistoryModal
        itemId={itemId}
        type={itemType}
        onHide={() => setShowModal(false)}
        refreshCounter={refreshCounter}
      />
    );
  }, [showModal]);

  return { auditLogHistoryButton, auditLogHistoryModal };
};
