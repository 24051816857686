import { AggregatedTeamMember, TeamMember } from "dashboard/miter";
import { CheckrPackage } from "dashboard/types/screening-types";
import { formatFromSnakeCase } from "dashboard/utils";

import React, { useEffect, useMemo } from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock, Label, Link } from "ui";
import { stateOptions } from "miter-utils";
import { HierarchicalNodeMap, useCheckrPackageOptions } from "./useCheckrPackages";
import { Option } from "packages/ui/form/Input";
import { WizardTeamMember } from "../team-members/TeamMemberWizard";
import { useLookupWorkplace } from "dashboard/hooks/atom-hooks";

type Props = {
  mapOfNodesToPackages: HierarchicalNodeMap;
  companyPackages: CheckrPackage[];
  teamMember?: AggregatedTeamMember | TeamMember | WizardTeamMember;
  form: UseFormMethods<ScreeningForm>;
  defaultValues?: ScreeningFormDefaultValues;

  hideState?: boolean;
  onChange?: (data: ScreeningForm) => void;
};

export type ScreeningForm = {
  checkr_package?: Option<string>;
  node_id?: Option<string>;
  state?: Option<string>;
  company_id: string;
};

export type ScreeningFormDefaultValues = {
  checkr_package?: string;
  node_id?: string;
  state?: string;
};

export const AddScreeningForm: React.FC<Props> = ({
  form,
  mapOfNodesToPackages,
  companyPackages,
  teamMember,
  hideState,
  defaultValues,
  onChange,
}) => {
  const { watch, setValue, control } = form;
  const formData = watch();

  const nodeId = formData?.node_id?.value;
  const checkrPackageId = formData?.checkr_package?.value;

  const lookupWorkplace = useLookupWorkplace();
  const teamMemberWorkplace = lookupWorkplace(getWorkplaceId(teamMember));

  const { activePackageOptions, nodeOptions, chosenPackage } = useCheckrPackageOptions({
    checkrPackageId,
    nodeId,
    companyPackages,
    mapOfNodesToPackages,
  });

  /** Reset checkr package when node changes */
  useEffect(() => {
    setValue("checkr_package", null);
  }, [nodeId]);

  /** Load default values when activePackageOptions or defaultValues change */
  useEffect(() => {
    // If there are no active packages or no default values, return
    if (activePackageOptions.length === 0 || !defaultValues) return;

    // If the default values is the same as the current values, return
    const isSameCheckrPackage = defaultValues.checkr_package === formData.checkr_package?.value;
    const isSameNodeId = defaultValues.node_id === formData.node_id?.value;
    if (isSameCheckrPackage && isSameNodeId) return;

    const defaultCheckrPackage = activePackageOptions.find(
      (option) => option.value === defaultValues.checkr_package
    );

    setValue("checkr_package", defaultCheckrPackage);
  }, [activePackageOptions, defaultValues]);

  const includedScreenings: string = useMemo(() => {
    if (!chosenPackage) return "";

    const screenings = chosenPackage.screenings.map((screening) => formatFromSnakeCase(screening.type));
    return "Included screenings: " + screenings.join(", ");
  }, [chosenPackage]);

  return (
    <>
      {nodeOptions.length > 0 && (
        <Formblock
          name="node_id"
          type="select"
          control={control}
          form={form}
          requiredSelect={true}
          label="Department*"
          editing={true}
          className="modal wizard"
          options={nodeOptions}
          defaultValue={defaultValues?.node_id}
          onChange={(nodeId) => {
            onChange?.({ ...formData, node_id: nodeId });
          }}
        />
      )}

      <Formblock
        name="checkr_package"
        type="select"
        control={control}
        form={form}
        requiredSelect={true}
        label="Package*"
        editing={true}
        className="modal wizard"
        options={activePackageOptions}
        defaultValue={defaultValues?.checkr_package}
        onChange={(checkrPackage) => {
          onChange?.({ ...formData, checkr_package: checkrPackage });
        }}
      />

      {includedScreenings && <div style={{ marginBottom: 20 }}>{includedScreenings}</div>}

      {!hideState && (
        <Formblock
          name="state"
          type="select"
          control={control}
          form={form}
          requiredSelect={true}
          label={`${teamMember?.full_name || "Team member"}'s work location`}
          editing={true}
          className="modal wizard"
          options={stateOptions}
          defaultValue={teamMemberWorkplace?.check_workplace?.address?.state}
        />
      )}

      {chosenPackage && (
        <div style={{ marginBottom: 20 }}>
          <Label
            style={{ width: 50 }}
            label={
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 5 }}>Price:</div>
                <div>{"$" + (chosenPackage.price / 100).toFixed(2)}</div>
              </div>
            }
          />
          <div style={{ display: "flex", marginRight: 5, fontSize: "0.9rem", opacity: 0.7, marginTop: 5 }}>
            <div>Actual charge may be greater due to pass through fees.</div>
            &nbsp;<Link onClick={() => window.open(CHECKR_HELP_URL, "_blank")}>{" Learn more."}</Link>
          </div>
        </div>
      )}
    </>
  );
};

const getWorkplaceId = (
  teamMember: AggregatedTeamMember | TeamMember | WizardTeamMember | undefined
): string | undefined => {
  if (!teamMember) return;

  if (typeof teamMember.workplace === "string") {
    return teamMember.workplace;
  } else if (teamMember.workplace) {
    return teamMember.workplace._id;
  }
};

const CHECKR_HELP_URL = "https://help.checkr.com/hc/en-us/articles/16652770844695";
