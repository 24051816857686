import React, { useCallback, useState, useMemo } from "react";
import { DeleteModal, Notifier, TableV2 } from "ui";
import {
  AggregatedPerformanceReview,
  AggregatedPerformanceReviewCycle,
  AggregatedTeamMember,
  FormSubmission,
  MiterAPI,
} from "dashboard/miter";
import { ForageRequest } from "backend/utils/forage/forage-types";
import { useActiveCompanyId, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { Envelope, Plus, Trash } from "phosphor-react";
import { CreatePerformanceReviewModal } from "dashboard/components/performance/CreatePerformanceReviewModal";
import { generateReviewPeriodString } from "dashboard/utils/performance";
import { EditPerformanceReviewModal } from "dashboard/components/performance/EditPerformanceReviewModal";
import { ColumnConfig, TableDropdownCellRenderer } from "ui/table-v2/Table";
import FailuresModal from "dashboard/components/shared/FailuresModal";
import { notNullish } from "miter-utils";
import { EnrollmentFailureTeamMember } from "dashboard/components/time-off/TimeOffPolicyEnrolleesTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { usePerformanceReviewAbilities } from "dashboard/hooks/abilities-hooks/usePerformanceReviewAbilities";

type Props = {
  reviewCycle?: AggregatedPerformanceReviewCycle;
  // team member is passed when showing performance reviews on team profile
  teamMember?: AggregatedTeamMember;
  additionalfilters?: ForageRequest["filter"];
  customEmptyMessage?: string;
};

const colors = {
  incomplete: "light-red",
  completed: "light-green",
  exempt: "light-gray",
  pending_request: "orange",
  anniversary: "yellow",
  start_of_cycle: "light-blue",
};

const displayReviewCycleHeader = (params) => {
  return (
    <div>Evaluations requested in the {generateReviewPeriodString(params.data.review_period)} cycle</div>
  );
};

enum EditMode {
  DELETE_REVIEW = "DELETE_REVIEW",
  EDIT_REVIEW = "EDIT_REVIEW",
  ADD_REVIEW = "ADD_REVIEW",
  NONE = "NONE",
}

export const PerformanceReviewsTable: React.FC<Props> = ({
  reviewCycle,
  teamMember,
  additionalfilters,
  customEmptyMessage,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const miterAbilities = useMiterAbilities();
  const reviewAbilities = usePerformanceReviewAbilities();

  const [refreshCount, setRefreshCount] = useState(0);
  const [editMode, setEditMode] = useState(EditMode.NONE);
  const [selectedPerformanceReview, setSelectedPerformanceReview] =
    useState<AggregatedPerformanceReview | null>(null);
  const [reminderFailures, setReminderFailures] = useState<EnrollmentFailureTeamMember[]>([]);
  const lookupTeam = useLookupTeam();
  const [loading, setLoading] = useState(false);

  const refresh = () => {
    setRefreshCount((c) => c + 1);
  };

  const getData = useCallback(
    async (query: ForageRequest) => {
      const filter = [
        ...(teamMember
          ? [
              {
                field: "reviewee._id",
                value: teamMember._id,
                type: "_id",
              } as const,
            ]
          : []),
        ...(query.filter || []),
        ...(additionalfilters || []),
        { field: "company_id", value: activeCompanyId },
        ...(reviewCycle ? [{ field: "review_cycle_id", value: reviewCycle._id }] : []),
      ];

      // Add a filter based on the permissions if a filter exists
      const abilitiesFilter = reviewAbilities.filter("read");
      if (abilitiesFilter) filter.push(abilitiesFilter);

      return await MiterAPI.performance_reviews.forage({
        sort: [
          {
            field: "reviewee.full_name",
            direction: 1,
          },
          {
            field: "created_at",
            direction: -1,
          },
        ],
        ...query,
        filter,
        select: undefined,
      });
    },
    [activeCompanyId, reviewCycle, additionalfilters, reviewAbilities.filter]
  );

  /* Modal Actions */

  const openAddReviewModal = () => {
    setEditMode(EditMode.ADD_REVIEW);
  };

  const openArchiveReviewModal = (performanceReview: AggregatedPerformanceReview) => {
    setSelectedPerformanceReview(performanceReview);
    setEditMode(EditMode.DELETE_REVIEW);
  };

  const openEditReviewModal = (performanceReview: AggregatedPerformanceReview) => {
    setSelectedPerformanceReview(performanceReview);
    setEditMode(EditMode.EDIT_REVIEW);
  };

  const closeAddReviewModal = () => {
    setEditMode(EditMode.NONE);
  };

  const closeArchiveReviewModal = () => {
    setSelectedPerformanceReview(null);
    setEditMode(EditMode.NONE);
  };

  const closeEditReviewModal = () => {
    setSelectedPerformanceReview(null);
    setEditMode(EditMode.NONE);
  };

  const staticActions = [
    // Checking if there's an existing review cycle before allowing a manual addition
    ...(reviewCycle?.previous_start_date
      ? [
          {
            label: "Add review to current cycle",
            className: "button-2 no-margin",
            action: openAddReviewModal,
            icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
            important: true,
            shouldShow: () =>
              miterAbilities.can("performance:reviews:others:create") ||
              miterAbilities.can("performance:reviews:personal:create"),
          },
        ]
      : []),
  ];

  const handleSendReminder = async (formSubmission: FormSubmission) => {
    try {
      const res = await MiterAPI.form_submissions.send_reminders(
        [formSubmission._id],
        "performance_review_cycle" as const
      );
      if (res.failures.length) {
        const failures = res.failures
          .map((error) => {
            const employee = lookupTeam(error.team_member_id);
            if (!employee) return;
            return { ...employee, failure_reason: error.message };
          })
          .filter(notNullish);

        setReminderFailures(failures);
        throw new Error(res.failures[0]?.message);
      }
      Notifier.success("Reminder sent!");
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error sending the reminder. We are looking into it!");
    }
  };

  const handleArchive = async (performanceReview: AggregatedPerformanceReview) => {
    if (!activeCompanyId) return;

    if (reviewAbilities.cannot("delete", performanceReview)) {
      Notifier.error("You do not have permission to delete this performance review.");
      return;
    }

    try {
      setLoading(true);
      const id = performanceReview._id;
      const res = await MiterAPI.performance_reviews.archive({ ids: [id], company_id: activeCompanyId });
      if (res.failures.length) throw new Error(res.failures[0]?.reason);
      Notifier.success("Performance review deleted!");
      refresh();
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error deleting the performance review. We are looking into it!");
    }
    setLoading(false);
    closeArchiveReviewModal();
  };

  const buildActionsCell = (performanceReview: AggregatedPerformanceReview) => {
    const selfReviewSubmission = performanceReview?.self_review_submission;
    const directReportSubmission = performanceReview?.reviewer_submission;

    return [
      ...(selfReviewSubmission && performanceReview.reviewee_submission_status === "incomplete"
        ? [
            {
              label: "Remind reviewee",
              action: () => handleSendReminder(selfReviewSubmission),
              icon: <Envelope weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
              shouldShow: () => reviewAbilities.can("update", performanceReview),
            },
          ]
        : []),
      ...(directReportSubmission && performanceReview.reviewer_submission_status === "incomplete"
        ? [
            {
              label: "Remind manager",
              action: () => handleSendReminder(directReportSubmission),
              icon: <Envelope weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
              shouldShow: () => reviewAbilities.can("update", performanceReview),
            },
          ]
        : []),

      {
        label: "Delete",
        action: () => openArchiveReviewModal(performanceReview),
        icon: <Trash weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => reviewAbilities.can("delete", performanceReview),
      },
    ];
  };

  const isPeriodic = useMemo(
    () => ["anniversary", "start_of_cycle"].includes(reviewCycle?.request_style || ""),
    [reviewCycle]
  );

  const performanceReviewColumns: ColumnConfig<AggregatedPerformanceReview>[] = useMemo(
    () => [
      ...(!!reviewCycle
        ? []
        : [
            {
              field: "performance_review_cycle.name",
              headerName: "Schedule",
              width: 200,
              dataType: "string" as const,
            },
          ]),
      {
        field: "review_period",
        headerName: "Cycle",
        enableRowGroup: !!reviewCycle && isPeriodic,
        rowGroup: !!reviewCycle && isPeriodic,
        initialHide: !!reviewCycle && isPeriodic,
        width: 300,
        openByDefault: true,
        hide: !!reviewCycle,
        valueFormatter: (params) => generateReviewPeriodString(params.value),
      },
      ...(teamMember
        ? []
        : [
            {
              field: "reviewee.full_name",
              headerName: "Reviewee",
              width: 200,
              dataType: "string" as const,
            },
          ]),
      {
        field: "reviewer.full_name",
        dataType: "string" as const,
        headerName: "Manager",
        width: 200,
      },
      {
        field: "reviewee_submission_status",
        headerName: "Self review",
        dataType: "string" as const,
        displayType: "badge" as const,
        minWidth: 150,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["completed", "incomplete", "exempt", "pending_request"],
          valueFormatter: (params) => params.value.replaceAll("_", " "),
        },
        colors,
      },
      {
        field: "reviewer_submission_status",
        headerName: "Manager review",
        dataType: "string" as const,
        displayType: "badge" as const,
        minWidth: 150,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["completed", "incomplete", "exempt", "pending_request"],
          valueFormatter: (params) => params.value.replaceAll("_", " "),
        },
        colors,
      },

      {
        field: "request_date",
        headerName: "Requested on",
        dataType: "date" as const,
        dateType: "iso",
      },

      {
        field: "due_date",
        headerName: "Due",
        dataType: "date" as const,
        dateType: "iso",
      },
      {
        field: "actions",
        headerName: "Actions",
        dataType: "component",
        disableCellClick: true,
        cellRenderer: (params) => {
          const actions = buildActionsCell(params.data);
          if (!actions.filter((action) => action.shouldShow()).length) return null;

          return <TableDropdownCellRenderer options={actions} />;
        },
      },
    ],
    [
      colors,
      buildActionsCell,
      generateReviewPeriodString,
      reviewCycle,
      isPeriodic,
      miterAbilities.can,
      miterAbilities.cannot,
    ]
  );

  const renderReminderFailuresModal = () => {
    if (!reminderFailures.length) return;

    const failures = reminderFailures.map((failure) => ({
      label: failure.full_name,
      message: failure.failure_reason,
    }));

    return (
      <FailuresModal
        headerText={"Errors sending reminders"}
        onClose={() => setReminderFailures([])}
        failures={failures}
      />
    );
  };

  const renderArchiveModal = (performanceReview: AggregatedPerformanceReview) => (
    <DeleteModal
      header={"Are you sure?"}
      body={<div>Deleting this performance review will permanently delete it.</div>}
      cancelText={"Cancel"}
      onHide={closeArchiveReviewModal}
      deleteText={"Yes, delete review"}
      onDelete={() => handleArchive(performanceReview)}
      loading={loading}
    />
  );

  const renderModals = () => {
    switch (editMode) {
      case "EDIT_REVIEW":
        return selectedPerformanceReview ? (
          <EditPerformanceReviewModal
            performanceReview={selectedPerformanceReview}
            onExit={closeEditReviewModal}
          />
        ) : null;
      case "DELETE_REVIEW":
        return selectedPerformanceReview ? renderArchiveModal(selectedPerformanceReview) : null;
      case "ADD_REVIEW":
        return reviewCycle ? (
          <CreatePerformanceReviewModal
            performanceReviewCycle={reviewCycle}
            onExit={closeAddReviewModal}
            refresh={refresh}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div>
      <TableV2
        id={"performance-reviews-table"}
        title="Performance Reviews"
        resource="performance reviews"
        columns={performanceReviewColumns}
        getData={getData}
        staticActions={staticActions}
        onClick={openEditReviewModal}
        ssr={true}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        refreshCount={refreshCount}
        groupDefaultExpanded={1}
        groupRowRendererParams={{
          innerRenderer: (params) => displayReviewCycleHeader(params),
          suppressCount: true,
          expanded: true,
        }}
        // TODO: use gridAPI to only expand the first group
        isServerSideGroupOpenByDefault={() => true}
        groupDisplayType="groupRows"
        customEmptyStateMessage={customEmptyMessage}
        hideFooter={!!reviewCycle}
      />
      {renderReminderFailuresModal()}
      {renderModals()}
    </div>
  );
};
