import { FlatfileResults } from "@flatfile/react";
import {
  useActivities,
  useActivityLabelFormatter,
  useActivityOptionsMap,
  useGetClassificationOptions,
  useJobNameFormatter,
  useJobOptions,
  useLedgerAccountOptions,
  useLookupJob,
  useTeam,
  useTimeOffPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage, normalizeDate } from "dashboard/utils/flatfile";
import React, { useContext, useMemo } from "react";
import { keyBy } from "lodash";
import { AggregatedJob, ImportResult, Job, MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../../../components/importer/Importer";
import { useGetEarningTypeOptions } from "./viewPayrollUtils";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { Notifier, earningsThatRequireHours } from "dashboard/utils";
import { CheckPayroll } from "backend/utils/check/check-types";

type Props = {
  onFinish: (params: ImportResult) => void;
};

export type EarningRecord = {
  teamMemberId: string;
  earningType: string;
  hours?: string;
  amount?: string;
  rate?: string;
  description?: string;
  date?: string;
  jobId?: string;
  activityId?: string;
  classificationOverrideId?: string;
  timeOffPolicyId?: string;
  ledgerAccountId?: string;
  ignoreBenefits?: boolean;
};

export const EarningsImporter: React.FC<Props> = ({ onFinish }) => {
  const { payroll } = useContext(PayrollContext);

  const teamMembers = useTeam();
  const validFriendlyIds: Set<string> = useMemo(() => {
    if (payroll?.type !== "off_cycle") {
      const tmIdSet = (payroll?.miter_payments || []).reduce(
        (acc, mp) => (mp.onboarded ? acc.add(mp.team_member.friendly_id) : acc),
        new Set<string>()
      );
      return tmIdSet;
    } else {
      const { period_start, period_end } = payroll?.check_payroll;
      const tmIdSet = new Set<string>();
      teamMembers
        .filter((tm) => {
          // Filter out TMs that are inactive for the given period or that don't have a check id
          if (
            (tm.end_date && tm.end_date < period_start) ||
            (tm.start_date && tm.start_date > period_end) ||
            !tm.check_id
          ) {
            return false;
          }
          return true;
        })
        .map((tm) => tmIdSet.add(tm.friendly_id));
      return tmIdSet;
    }
  }, [payroll, teamMembers]);

  const validTeamMembers = useMemo(() => {
    return teamMembers.filter((employee) => validFriendlyIds.has(employee.friendly_id));
  }, teamMembers);

  const lookupTeamID = useMemo(() => keyBy(validTeamMembers, "friendly_id"), [validTeamMembers]);

  const getEarningTypeOptions = useGetEarningTypeOptions();
  const classificationOptions = useGetClassificationOptions();

  const activityOptionsMap = useActivityOptionsMap();
  const activities = useActivities();
  const jobNameFormatter = useJobNameFormatter();
  const activityFormatter = useActivityLabelFormatter();

  const allActivityOptions = useMemo(
    () =>
      activities.map((activity) => {
        let label = activityFormatter(activity);

        if (!activity.company_activity) {
          label = jobNameFormatter(activity.job) + " - " + label;
        }
        return { value: activity._id, label };
      }),
    [activities]
  );

  const timeOffPoliciesOptions = useTimeOffPolicyOptions();

  const ledgerAccountOptions = useLedgerAccountOptions();

  const checkPayroll: CheckPayroll | undefined = payroll?.check_payroll;
  const benefitsNotApplied =
    checkPayroll?.type === "off_cycle"
      ? !checkPayroll.off_cycle_options?.apply_benefits
      : checkPayroll?.type !== "regular";

  const jobPred = useMemo(() => {
    const manualJobIdsOnPayroll = new Set<string>();
    for (const adjustment of payroll?.adjustments || []) {
      for (const me of adjustment.manual_earnings || []) {
        if (me.job) manualJobIdsOnPayroll.add(me.job);
      }
    }
    return (j: Job | AggregatedJob) => j.status === "active" || manualJobIdsOnPayroll.has(j._id);
  }, [payroll]);

  const jobOptions = useJobOptions({ predicate: jobPred, overrideBasePredicate: true });
  const lookupJob = useLookupJob();

  const activeJobs = useMemo(() => {
    return jobOptions.map((jobOption) => lookupJob(jobOption.value));
  }, [jobOptions]);
  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);
  const lookupActivityCode = useMemo(() => keyBy(activities, "cost_code"), [activities]);

  // validations
  const validateTeamMemberID = (teamMemberId: string | undefined) => {
    if (teamMemberId) {
      const idWithoutWhiteSpace = teamMemberId.trim();

      const teamMember = lookupTeamID[idWithoutWhiteSpace];
      if (!teamMember) {
        return buildFlatfileMessage("Team member not found", idWithoutWhiteSpace, "error");
      }

      return { value: idWithoutWhiteSpace };
    }
  };

  const validateEarningType = (record: EarningRecord) => {
    if (record.earningType) {
      const idWithoutWhiteSpace = record.teamMemberId?.trim();

      const teamMember = lookupTeamID[idWithoutWhiteSpace];

      const validType = getEarningTypeOptions(teamMember).filter(
        (option) => option.value === record.earningType
      );
      if (validType.length == 0) {
        return buildFlatfileMessage(
          "This earning type is not valid for this team member",
          record.earningType,
          "error"
        );
      }
    }

    return { value: record.earningType };
  };

  const validateActivity = (record: EarningRecord & { jobCode?: string }) => {
    let jobId: string | undefined;

    if (record.jobId) {
      jobId = record.jobId;
    } else if (record.jobCode) {
      jobId = lookupJobCode[record.jobCode]?._id;
    }

    if (record.activityId && jobId) {
      const validActivities = activityOptionsMap.get(jobId).map((activity) => activity.value);

      if (!validActivities.includes(record.activityId)) {
        return buildFlatfileMessage(
          "This activity is not valid for the job selected",
          record.activityId,
          "error"
        );
      }
    }

    return { value: record.activityId };
  };

  /**  If there is a job code and there is a job and the job has custom activities, validate that the cost code is one of the custom activities */
  const validateActivityCode = (record) => {
    let activityCostCode: string;
    let selectedActivityId: string | undefined;
    let jobCode: string | undefined;
    let jobId: string | undefined;

    if (typeof record !== "string") {
      activityCostCode = record.activityCostCode;
      selectedActivityId = record.activityId;
      jobCode = record.jobCode;
      jobId = record.jobId;
    } else {
      activityCostCode = record;
    }

    if (activityCostCode) {
      if (selectedActivityId) {
        return buildFlatfileMessage(
          "Activity already provided in activity column",
          activityCostCode,
          "error"
        );
      }

      const activityIdFromCode = lookupActivityCode[activityCostCode]?._id;

      if (!activityIdFromCode) {
        return buildFlatfileMessage("Cost code not found", activityCostCode, "error");
      }

      // get job id job code column if exists
      if (jobCode) {
        jobId = lookupJobCode[jobCode]?._id;
      }

      if (jobId) {
        const validActivities = activityOptionsMap.get(jobId).map((activity) => activity.value);

        if (!validActivities.includes(activityIdFromCode)) {
          return buildFlatfileMessage(
            "This activity is not valid for the job selected",
            activityCostCode,
            "error"
          );
        }
      }
    }

    return { value: activityCostCode };
  };

  /**  If there is a job code and there is a job and the job has custom activities, validate that the cost code is one of the custom activities */
  const validateJobCode = (record) => {
    let jobCode: string;
    let selectedJobId: string | undefined;

    if (typeof record !== "string") {
      jobCode = record.jobCode;
      selectedJobId = record.jobId;
    } else {
      jobCode = record;
    }

    if (jobCode) {
      if (selectedJobId) {
        return buildFlatfileMessage("Job already provided in job column", jobCode, "error");
      }

      const job = lookupJobCode[jobCode];
      if (!job) {
        return buildFlatfileMessage("Job code not found", jobCode, "error");
      }
    }

    return { value: jobCode };
  };

  const validateClassificationOverride = (record: EarningRecord) => {
    if (record.classificationOverrideId) {
      if (!earningsThatRequireHours.includes(record.earningType)) {
        return buildFlatfileMessage(
          "Earning types that do not require hours should not have classification overrides",
          record.classificationOverrideId,
          "error"
        );
      }
    }
    return { value: record.classificationOverrideId };
  };

  const validateTimeOffPolicy = (record: EarningRecord) => {
    const inputtedTimeOffPolicy = record.timeOffPolicyId;
    const isTimeOffEarning = record.earningType === "pto" || record.earningType === "sick";

    if (isTimeOffEarning && !!inputtedTimeOffPolicy) {
      const teamMember = lookupTeamID[record.teamMemberId];
      const timeOffPolicies = teamMember?.time_off_policies;
      const timeOffPolicy = timeOffPolicies?.find((p) => p._id === inputtedTimeOffPolicy);

      if (!timeOffPolicy) {
        return buildFlatfileMessage(
          "Team member is not enrolled in this time off policy",
          inputtedTimeOffPolicy,
          "error"
        );
      }

      const policyLevel = timeOffPolicy.levels.find(
        (p) =>
          p._id ===
          teamMember?.time_off.policies?.find((p) => p.policy_id === inputtedTimeOffPolicy)?.level_id
      );

      if (!policyLevel) {
        return buildFlatfileMessage(
          "Team member has not been assigned a level in this time off policy",
          inputtedTimeOffPolicy,
          "error"
        );
      }

      if (policyLevel.disable_negative_balances) {
        const balance = teamMember?.time_off.policies.find((p) => p.policy_id === timeOffPolicy._id)?.balance;
        if (balance != null && record.hours && balance < Number(record.hours)) {
          return buildFlatfileMessage(
            `Team member's balance for the ${timeOffPolicy.name} time off policy is too low to create their earning.`,
            inputtedTimeOffPolicy,
            "error"
          );
        }
      }
    }

    return { value: inputtedTimeOffPolicy };
  };

  const validateHours = (record: EarningRecord) => {
    const hours = record.hours;
    if (record.earningType) {
      if (earningsThatRequireHours.includes(record.earningType) && !hours) {
        return buildFlatfileMessage("Hours are required for this earning type", hours, "error");
      }
      if (!earningsThatRequireHours.includes(record.earningType) && !!hours) {
        return buildFlatfileMessage("Hours should not be included for this earning type", hours, "error");
      }
    }

    if (!!hours) {
      const hoursNumber = Number(hours);
      if (isNaN(hoursNumber) || hoursNumber === 0) {
        return buildFlatfileMessage("Hours must be a nonzero number", hours, "error");
      }
    }
    return { value: hours };
  };

  const validateAmount = (record: EarningRecord) => {
    const amount = record.amount;
    if (record.earningType) {
      if (!earningsThatRequireHours.includes(record.earningType) && !amount) {
        return buildFlatfileMessage("Amount is required for this earning type", amount, "error");
      }
      if (earningsThatRequireHours.includes(record.earningType) && !!amount) {
        return buildFlatfileMessage(
          "Amount should not be included for this earning type as it will be auto-calculated on submit",
          amount,
          "error"
        );
      }
    }

    if (!!amount) {
      const amountNumber = Number(amount);
      if (isNaN(amountNumber) || amountNumber === 0) {
        return buildFlatfileMessage("Amount must be a nonzero number", amount, "error");
      }
    }

    return { value: amount };
  };

  const validateRate = (record: EarningRecord) => {
    const rate = record.rate;

    if (!earningsThatRequireHours.includes(record.earningType) && !!rate) {
      return buildFlatfileMessage("Rate should not be included for this earning type", rate, "error");
    }

    if (!rate) {
      return buildFlatfileMessage("Rate will be auto calculated on save if left blank", record.rate, "info");
    }

    if (!!rate) {
      const rateNumber = Number(rate);
      if (isNaN(rateNumber)) {
        return buildFlatfileMessage(
          "Rate must be a number or empty. If left empty, rate will be auto-calculated on submit",
          rate,
          "error"
        );
      }
    }
    return { value: rate };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const cleanInput: EarningRecord[] = results.validData.map((input) => {
        const { jobCode, activityCostCode, ...rest } = input;

        return {
          ...rest,
          teamMemberId: lookupTeamID[input.teamMemberId]?._id,
          jobId: input.jobId || lookupJobCode[jobCode]?._id,
          activityId: input.activityId || lookupActivityCode[activityCostCode]?._id,
        };
      });

      const response = await MiterAPI.payrolls.import_earnings(payroll!._id, {
        clean_inputs: cleanInput,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);
      onFinish(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the earnings.");
    }
  };

  const fields = useMemo(() => {
    const earningTypeOptions = getEarningTypeOptions();
    const fieldList: ImportField[] = [
      {
        label: "Employee ID number",
        type: "string",
        key: "teamMemberId",
        description: "Unique identifer for team member (must be same in source system and Miter).",
        validators: [{ validate: "required" }],
        hook: (val) =>
          typeof val === "string" ? validateTeamMemberID(val) : validateTeamMemberID(val.teamMemberId),
      },
      {
        label: "Type",
        type: "select",
        key: "earningType",
        options: earningTypeOptions,
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record !== "string" ? validateEarningType(record) : { value: record }),
      },
      {
        label: "Hours",
        type: "string",
        key: "hours",
        hook: (record) => (typeof record !== "string" ? validateHours(record) : { value: record }),
      },
      {
        label: "Amount",
        type: "string",
        key: "amount",
        hook: (record) => (typeof record !== "string" ? validateAmount(record) : { value: record }),
      },
      {
        label: "Rate",
        type: "string",
        key: "rate",
        hook: (record) => (typeof record !== "string" ? validateRate(record) : { value: record }),
      },
      {
        label: "Description",
        type: "string",
        key: "description",
      },
      {
        label: "Date",
        type: "string",
        key: "date",
        hook: (record) => (typeof record !== "string" ? normalizeDate(record.date) : normalizeDate(record)),
      },
      {
        label: "Job",
        type: "select",
        key: "jobId",
        options: jobOptions,
      },
      {
        label: "Activity",
        type: "select",
        key: "activityId",
        options: allActivityOptions,
        hook: (record) => (typeof record !== "string" ? validateActivity(record) : { value: record }),
      },
      {
        label: "Job Code",
        type: "string",
        key: "jobCode",
        hook: (record) => validateJobCode(record),
      },
      {
        label: "Activity Code",
        type: "string",
        key: "activityCostCode",
        hook: (record) => validateActivityCode(record),
      },
      {
        label: "Classification override",
        type: "select",
        key: "classificationOverrideId",
        options: classificationOptions({}),
        hook: (record) =>
          typeof record !== "string" ? validateClassificationOverride(record) : { value: record },
      },
      {
        label: "Time off policy",
        type: "select",
        key: "timeOffPolicyId",
        options: timeOffPoliciesOptions,
        hook: (record) => (typeof record !== "string" ? validateTimeOffPolicy(record) : { value: record }),
      },
      {
        label: "GL account override",
        type: "select",
        key: "ledgerAccountId",
        options: ledgerAccountOptions,
      },
    ];

    if (!benefitsNotApplied) {
      fieldList.push({
        label: "Ignore benefits",
        type: "checkbox",
        key: "ignoreBenefits",
      });
    }
    return fieldList;
  }, [ledgerAccountOptions, timeOffPoliciesOptions, allActivityOptions, jobOptions, getEarningTypeOptions]);

  return <Importer id="earnings" resource="earnings" onSave={handleSubmit} fields={fields} />;
};
