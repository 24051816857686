import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AppContext from "../../../../contexts/app-context";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { Formblock, TableV2 } from "ui";
import { DateRange } from "ui/form/DateRangePicker";
import {
  useActiveCompanyId,
  useActivityLabelFormatter,
  useJobNameFormatter,
  useLedgerAccountLabeler,
  useLookupTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { useForm } from "react-hook-form";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { ColumnConfig } from "ui/table-v2/Table";
import { FringeReportEntry } from "backend/utils/reports/fringeReport";
import { ValueGetterParams } from "ag-grid-community";
import { toDollarFormat, roundToTwoDecimals, agGridIsoDateComparator } from "../../reportUtils";
import { startCase } from "lodash";
import { usePayrollAbilities } from "dashboard/hooks/abilities-hooks/usePayrollAbilities";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";

export const LegacyFringeReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { can } = useMiterAbilities();
  const { payroll } = useContext(PayrollContext);

  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();
  const accountLabeler = useLedgerAccountLabeler();
  const payrollAbilities = usePayrollAbilities();
  const lookupTeam = useLookupTeam();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns<FringeReportEntry>({
    field: "jobHierarchyIds",
  });

  const isMounted = useRef(false);
  const { setReverifyUser } = React.useContext(AppContext);
  const user = useUser();
  const form = useForm();
  const { errors, control } = form;

  // State
  const [rawData, setRawData] = useState<FringeReportEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ months: 1 }),
    end: DateTime.now(),
  });

  const [showRawSSNs, setShowRawSSNs] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);
  const [showTimeOffEarnings, setShowTimeOffEarnings] = useState(false);
  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const isReverified = (user?.reverified_at || 0) > DateTime.now().minus({ days: 1 }).toSeconds();

  const data = useMemo(() => {
    if (showBenefits) return rawData;
    return rawData.filter((entry) => {
      const teamMember = lookupTeam(entry.tmId);

      return (
        entry.source !== "benefit" && (!teamMember || payrollAbilities.teamPredicate("read")(teamMember))
      );
    });
  }, [rawData, showBenefits]);

  const columns = useMemo(() => {
    const cols: ColumnConfig<FringeReportEntry>[] = [
      {
        field: "prgLabel",
        filter: "agSetColumnFilter",
        headerName: "Pay rate group",
        enableRowGroup: true,
        rowGroup: true,
        hide: true,
      },
      {
        field: "tmName",
        filter: "agSetColumnFilter",
        headerName: "Team member",
        enableRowGroup: true,
        rowGroup: true,
        hide: true,
      },
      {
        field: "tmSSN",
        filter: true,
        headerName: "SSN",
      },
      {
        field: "payday",
        filter: "agDateColumnFilter",
        filterParams: { comparator: agGridIsoDateComparator },
        headerName: "Payday",
        enableRowGroup: true,
      },
      {
        field: "earningDate",
        filter: "agDateColumnFilter",
        filterParams: { comparator: agGridIsoDateComparator },
        headerName: "Earning date",
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "type",
        filter: true,
        headerName: "Type",
        valueGetter: (params: ValueGetterParams<FringeReportEntry>) => {
          if (!params.data) return null;
          return startCase(params.data.source + " " + params.data.paymentType);
        },
      },
      {
        field: "jobName",
        filter: true,
        headerName: "Job",
        enableRowGroup: true,
        initialHide: true,
        valueGetter: (params) => jobNameFormatter(params.data?.jobId),
      },
      {
        field: "activityName",
        filter: true,
        headerName: "Activity",
        enableRowGroup: true,
        initialHide: true,
        valueGetter: (params) => activityLabelFormatter(params.data?.activityId),
      },
      {
        field: "expenseAccountLabel",
        filter: true,
        headerName: "Expense account",
        enableRowGroup: true,
        initialHide: true,
        valueGetter: (params) => accountLabeler(params.data?.expenseAccountId),
      },
      {
        field: "liabilityAccountLabel",
        filter: true,
        headerName: "Liability account",
        enableRowGroup: true,
        initialHide: true,
        valueGetter: (params) => accountLabeler(params.data?.liabilityAccountId),
      },
      {
        field: "classification",
        filter: true,
        headerName: "Classification",
        enableRowGroup: true,
      },
      {
        field: "fringeLabel",
        filter: true,
        headerName: "Label",
        enableRowGroup: true,
      },
      {
        field: "fringeCategory",
        filter: true,
        headerName: "Category",
        enableRowGroup: true,
      },
      {
        field: "contributionRate",
        headerName: "Contribution rate",
      },
      {
        field: "contributionAmount",
        headerName: "Amount",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        field: "regHours",
        headerName: "REG hours",
        valueFormatter: roundToTwoDecimals,
        initialHide: true,
        aggFunc: "groupDedupedSumValues",
      },
      {
        field: "otHours",
        headerName: "OT hours",
        valueFormatter: roundToTwoDecimals,
        initialHide: true,
        aggFunc: "groupDedupedSumValues",
      },
      {
        field: "dotHours",
        headerName: "DOT hours",
        valueFormatter: roundToTwoDecimals,
        initialHide: true,
        aggFunc: "groupDedupedSumValues",
      },
      {
        field: "totalHours",
        headerName: "Total hours",
        valueFormatter: roundToTwoDecimals,
        aggFunc: "groupDedupedSumValues",
      },
      {
        field: "earningAmount",
        headerName: "Gross earnings",
        valueFormatter: toDollarFormat,
        aggFunc: "groupDedupedSumValues",
      },
      ...jobHierarchyTableColumns,
    ];

    return cols;
  }, [showBenefits, jobNameFormatter, activityLabelFormatter, accountLabeler, jobHierarchyTableColumns]);

  useEffect(() => {
    if (showReverificationModal) {
      setReverifyUser(showReverificationModal);
    }
  }, [showReverificationModal]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (showRawSSNs && !isReverified) {
      setShowReverificationModal(true);
      return;
    }

    getData();
  }, [showRawSSNs, isReverified]);

  const getData = async () => {
    setLoading(true);
    try {
      const payload = {
        type: "fringe_report",
        params: {
          start_date: dateRange.start?.toISODate(),
          end_date: dateRange.end?.toISODate(),
          company_id: activeCompanyId!,
          showRawSSNs,
          showTimeOffEarnings,
          draftPayroll: payroll,
        },
        format: "json",
        company: activeCompanyId!,
      };

      const entries = await MiterAPI.reports.create(payload);
      if (entries.error) throw new Error(entries.error);

      setRawData(entries);
    } catch (e: $TSFixMe) {
      console.log("Error fetching fringe report", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [dateRange, showTimeOffEarnings]);

  const fileName = "Miter Fringe Report " + dateRange.start?.toISODate() + " - " + dateRange.end?.toISODate();

  return (
    <div>
      {!payroll && (
        <>
          {can("team:read_sensitive") && (
            <Formblock
              label="Include raw SSNs"
              labelInfo="Show full SSNs on the report."
              control={control}
              type="checkbox"
              onChange={(e) => setShowRawSSNs(e.target.checked)}
              name="show_full_ssns"
              errors={errors}
              editing={true}
              style={{ alignItems: "flex-start" }}
              checkboxStyle={{ marginTop: -7 }}
              labelStyle={{ width: 300 }}
            />
          )}
          <Formblock
            label="Include benefits"
            labelInfo="Include employee benefits (e.g., 401k) in addition to classification fringes."
            control={control}
            type="checkbox"
            onChange={(e) => setShowBenefits(e.target.checked)}
            name="show_benefits"
            errors={errors}
            editing={true}
            style={{ alignItems: "flex-start" }}
            checkboxStyle={{ marginTop: -7 }}
            labelStyle={{ width: 300 }}
          />
          <Formblock
            label="Include time off earnings"
            labelInfo="Include time off and holiday earnings."
            control={control}
            type="checkbox"
            onChange={(e) => setShowTimeOffEarnings(e.target.checked)}
            name="show_time_off_earnings"
            errors={errors}
            editing={true}
            style={{ alignItems: "flex-start" }}
            checkboxStyle={{ marginTop: -7 }}
            labelStyle={{ width: 300 }}
          />
          <Formblock
            register={form.register}
            defaultValue={dateRange}
            value={dateRange}
            control={form.control}
            type="daterange"
            name="range"
            max={DateTime.now()}
            labelStyle={{ maxWidth: 100, fontFamily: "Karla", fontSize: 15 }}
            errors={form.errors}
            style={{ alignItems: "center" }}
            editing={true}
            onChange={setDateRange}
            label={"Payday range"}
            inputProps={{ style: { maxWidth: 250, fontFamily: "Karla" } }}
          />
          <div className="vertical-spacer-small"></div>
        </>
      )}

      <TableV2
        id="fringe-report"
        resource="data"
        data={data}
        columns={columns}
        isLoading={loading}
        groupHideOpenParents={false}
        gridWrapperStyle={{ height: "110%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        containerStyle={{ marginTop: -70 }}
        hideSearch={true}
        showReportViews={true}
        exportFileName={fileName}
        showExpandAll={true}
      />
      <div className="vertical-spacer-large"></div>
    </div>
  );
};
