import { AggregatedFillableTemplate } from "dashboard/types/fillable-template-types";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  useActiveAccount,
  useActiveCompanyId,
  useLookupTeam,
  useLookupFillableTemplates,
  useLookupCompanyRoles,
  useUser,
  useIsSuperAdmin,
  useRefetchActionableItems,
  useActiveTeam,
} from "dashboard/hooks/atom-hooks";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { Button, ConfirmModal, Notifier } from "ui";
import { MiterAPI, AggregatedFile, AggregatedTeamMember } from "dashboard/miter";
import BulkTeamMemberSelect, { BulkTeamMemberSelectTeamMember } from "../team-members/BulkTeamMemberSelect";
import { createFillableDocumentParams } from "dashboard/utils/fillable-document";
import { FillableDocumentWizard } from "miter-components";
import { PaperPlaneTilt, TrashSimple } from "phosphor-react";
import { getRoleName } from "dashboard/utils";
import { useNavigate } from "react-router-dom";
import { useDocumentAbilities } from "dashboard/hooks/abilities-hooks/useDocumentAbilities";
import { deleteFiles } from "miter-utils";

type Props = {
  fillableTemplate?: AggregatedFillableTemplate;
  fetchActionableFillableDocuments?: () => Promise<AggregatedFile[]>;
};

export type FillableDocumentRow = AggregatedFile & {
  recipient_team_member: AggregatedTeamMember;
  last_filled_on?: number;
  fillable_template: AggregatedFillableTemplate;
  requestor_name?: string;
};

export const FillableDocumentsTable: FC<Props> = ({ fillableTemplate, fetchActionableFillableDocuments }) => {
  const activeCompanyId = useActiveCompanyId();
  const lookupTeamMember = useLookupTeam();
  const lookupCompanyRole = useLookupCompanyRoles();
  const activeUser = useUser();
  const aggregatedTeamMembers = useActiveTeam();
  const activeAccount = useActiveAccount();
  const isSuperAdmin = useIsSuperAdmin();
  const lookupFillableTemplate = useLookupFillableTemplates();
  const navigate = useNavigate();
  const documentAbilities = useDocumentAbilities();
  const refetchActionableItems = useRefetchActionableItems();

  const [data, setData] = useState<FillableDocumentRow[]>([]);
  const [openRequestsModal, setOpenRequestsModal] = useState(false);
  const [selectedFillableDoc, setSelectedFillableDoc] = useState<FillableDocumentRow>();
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<FillableDocumentRow[]>([]);
  const [bulkRemoving, setBulkRemoving] = useState(false);

  const isActionableInbox = !!fetchActionableFillableDocuments;

  const teamMembersWithCount: BulkTeamMemberSelectTeamMember[] = useMemo(
    () =>
      aggregatedTeamMembers.map((teamMember) => {
        const totalCount = data.filter((file) => file.parent_id === teamMember._id).length;

        return {
          ...teamMember,
          additional_information: totalCount
            ? `${totalCount} previous request${totalCount > 1 ? "s" : ""}`
            : "",
        };
      }),
    [aggregatedTeamMembers, data]
  );

  const columns: ColumnConfig<FillableDocumentRow>[] = useMemo(
    () => [
      ...(isActionableInbox
        ? [
            {
              field: "fillable_template.name",
              headerName: "Document",
              dataType: "string" as const,
              width: 120,
            },
          ]
        : []),
      {
        field: "recipient_team_member.full_name",
        headerName: "Team member",
        dataType: "string" as const,
        width: 120,
      },
      {
        field: "fill_status",
        headerName: "Status",
        dataType: "string" as const,
        displayType: "badge",
        maxWidth: 120,
        colors: {
          pending: "orange",
          complete: "green",
        },
      },
      {
        field: "created_at",
        headerName: "Requested on",
        dateFormat: "ff",
        dataType: "date" as const,
        dateType: "timestamp" as const,
      },

      {
        field: "requestor_name",
        headerName: "Requested by",
        dataType: "string" as const,
      },

      ...(isActionableInbox
        ? [
            {
              field: "action",
              headerName: "Action",
              dataType: "component" as const,
              pinned: "right" as const,
              cellRenderer: (params) => {
                return (
                  <Button
                    style={{ fontWeight: 400 }}
                    onClick={() => setSelectedFillableDoc(params.data)}
                    className="button-text purple-link"
                  >
                    Fill document
                  </Button>
                );
              },
            },
          ]
        : [
            {
              field: "last_filled_on",
              headerName: "Completed on",
              dataType: "date" as const,
              dateType: "timestamp" as const,
              dateFormat: "ff",
            },
          ]),
    ],
    [isActionableInbox]
  );

  const generateFillableDocumentRows = (files: AggregatedFile[]): FillableDocumentRow[] => {
    return files
      .map((file) => {
        const teamMemberId = file.requestor?.team_member_id;
        const roleId = file.requestor?.role_id;
        const teamMember = lookupTeamMember(teamMemberId);
        const role = lookupCompanyRole(roleId);
        const requestorName = teamMember ? teamMember.full_name : getRoleName(role);

        const fillable_template = lookupFillableTemplate(file.fillable_template_id);
        const recipient_team_member = lookupTeamMember(file.parent_id);

        return {
          ...file,
          last_filled_on: file.fill_status === "complete" ? file.updated_at : undefined,
          recipient_team_member,
          fillable_template,
          requestor_name: requestorName,
        };
      })
      .filter((file) => !!file.recipient_team_member) as FillableDocumentRow[];
  };

  const getFillableDocumentsBaseFunction = async (): Promise<AggregatedFile[]> => {
    try {
      if (!activeCompanyId) throw new Error("Missing required data");
      if (fetchActionableFillableDocuments) {
        return await fetchActionableFillableDocuments();
      }
      const resp = await MiterAPI.files.search([
        {
          field: "fillable_template_id",
          value: fillableTemplate?._id,
        },
        {
          field: "company_id",
          value: activeCompanyId,
        },
      ]);
      if (resp?.error) {
        throw new Error(resp?.error);
      }
      return resp;
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    return [];
  };

  const getFillableDocuments = async () => {
    try {
      const resp = await getFillableDocumentsBaseFunction();
      setData(generateFillableDocumentRows(resp));
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  useEffect(() => {
    getFillableDocuments();
  }, []);

  const openRequestFillableDocuments = () => {
    setOpenRequestsModal(true);
  };

  const closeRequestFillableDocuments = () => {
    setOpenRequestsModal(false);
  };

  const staticActions = useMemo(
    () => [
      ...(isActionableInbox
        ? []
        : [
            {
              label: "Send requests",
              className: "button-2 no-margin",
              action: openRequestFillableDocuments,
              important: true,
              icon: <PaperPlaneTilt weight="fill" style={{ marginRight: 3 }} />,
            },
          ]),
    ],
    [openRequestFillableDocuments, isActionableInbox]
  );

  const dynamicActions = useMemo(
    () => [
      {
        label: "Delete",
        className: loading ? "button-3 inactive" : "button-3",
        action: () => setBulkRemoving(true),
        loading: bulkRemoving,
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => documentAbilities.can("delete", selectedRows, "team_member"),
      },
    ],
    [bulkRemoving, documentAbilities, selectedRows]
  );

  const onExit = () => {
    setSelectedFillableDoc(undefined);
  };

  const onComplete = async () => {
    await refetchActionableItems();
    await getFillableDocuments();
    onExit();
    Notifier.success("Document filled successfully");
  };

  const onSubmitRequests = async (teamMembers: BulkTeamMemberSelectTeamMember[]) => {
    setLoading(true);
    try {
      if (!activeAccount) return;
      if (!fillableTemplate) throw new Error("Fillable template not found");
      const fileUploadParams = createFillableDocumentParams({
        teamMembers,
        fillableTemplates: [fillableTemplate],
        account: activeAccount,
        onboarding: false,
      });
      const resp = await MiterAPI.files.create_without_blob(fileUploadParams);
      if (resp.error) {
        throw new Error(resp.error);
      }
      Notifier.success("Requests sent successfully");
      getFillableDocuments();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    closeRequestFillableDocuments();
    setLoading(false);
  };

  const onRowClick = (row: FillableDocumentRow) => {
    if (documentAbilities.can("read", row)) {
      navigate(`/documents/${row?._id}`);
    } else {
      Notifier.error(
        "You do not have permission to view this document. Please ask your administrator for permission group access."
      );
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    await deleteFiles(selectedRows.map((row) => row._id));
    await getFillableDocuments();
    setBulkRemoving(false);
    setSelectedRows([]);
    setLoading(false);
  };

  const renderModals = () => {
    return (
      <div>
        {openRequestsModal && (
          <BulkTeamMemberSelect
            title="Team members"
            defaultTeamMembers={[]}
            teamMembersPool={teamMembersWithCount}
            onSubmit={onSubmitRequests}
            onHide={closeRequestFillableDocuments}
            submitting={loading}
          />
        )}
        {selectedFillableDoc && activeUser && (
          <FillableDocumentWizard
            teamMember={activeAccount?.team_member}
            role={activeAccount?.role}
            fillableDocumentId={selectedFillableDoc._id}
            onExit={onExit}
            onComplete={onComplete}
            userId={activeUser?._id}
            isSuperAdmin={isSuperAdmin}
            application="dashboard"
          />
        )}
        {bulkRemoving ? (
          <ConfirmModal
            title="Are you sure you want to delete these documents?"
            body={"This action cannot be undone."}
            onYes={handleRemove}
            onNo={() => setBulkRemoving(false)}
            noText="No"
            loading={loading}
            className={"payment-cancel-modal"}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div>
      <TableV2
        id="fillable-documents-table"
        title="Fillable documents"
        resource="fillable documents"
        columns={columns}
        data={data}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onClick={onRowClick}
        onSelect={setSelectedRows}
      />
      {renderModals()}
    </div>
  );
};
