import React, { useMemo, useState } from "react";
import { ReportConfig } from "./reportList";
import arrow from "dashboard/assets/purpleRightArrow.png";
import "./reports.css";
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import qs from "qs";
import { Helmet } from "react-helmet";
import { Badge, Notifier, Toggler } from "ui";
import { useVisibleReports } from "./reportHooks";

export type ReportItemProps = ReportConfig & {
  key: string;
  index: number;
  handleClick: (r: ReportItemProps) => void;
};

export const ReportItem: React.FC<ReportItemProps> = (props) => {
  return (
    <div className="report-item-wrapper">
      <div onClick={() => props.handleClick(props)} className="report-item">
        <div>
          <div className="bold flex">
            {props.label}
            {props.beta && <Badge text="Beta" color="blue" />}
          </div>
          <div className="report-item-description">{props.description}</div>
        </div>
        <div className="flex">
          <div className="flex-1"></div>
          <div className="report-item-create-link">Create</div>
          <img src={arrow} style={{ marginLeft: 5, height: 15, width: 15 }} />
        </div>
      </div>
    </div>
  );
};

const toggleConfig = [
  { label: "General", path: "general" },
  { label: "Labor compliance", path: "labor_compliance" },
  { label: "All", path: "all" },
];

const Reports: React.FC = () => {
  const navigate = useNavigate();
  const visibleReports = useVisibleReports();

  const [activeTab, setActiveTab] = useState("general");
  const [searchTerm, setSearchTerm] = useState("");

  const filteredReportList = useMemo<ReportConfig[]>(() => {
    const toggleReports = visibleReports.filter((r) => {
      return !r.hide_from_main_list && (activeTab === "all" || r.category === activeTab);
    });

    if (!searchTerm) return toggleReports;

    const newReports: ReportConfig[] = [];
    for (const report of toggleReports) {
      const labelLower = report.label.toLowerCase();
      if (labelLower.includes(searchTerm)) {
        newReports.push(report);
      }
    }
    return newReports;
  }, [searchTerm, activeTab, visibleReports]);

  const handleClick = (r: ReportItemProps) => {
    let path = r.link;
    if (r.query) {
      path += "?" + qs.stringify(r.query);
    }
    navigate(path);
  };

  if (visibleReports.length === 0) {
    Notifier.error("You do not have permission to view reports.");
    return <Navigate to="/home" replace />;
  }

  return (
    <div className="page-content">
      <Helmet>
        <title>Reports | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <h1>Reports</h1>
      </div>
      <Toggler active={activeTab} toggle={setActiveTab} config={toggleConfig}></Toggler>
      <div>
        <div className="vertical-spacer"></div>
        <input
          type="text"
          className="table-search-input pw-input"
          style={{ width: 250, fontSize: 14, fontFamily: "Karla" }}
          placeholder={"Search by report name"}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          value={searchTerm}
        ></input>
      </div>
      <div className="reports-list">
        {filteredReportList.map((report, index) => {
          return <ReportItem handleClick={handleClick} key={report.slug} {...report} index={index} />;
        })}
      </div>
      <div className="vertical-spacer-large"></div>
    </div>
  );
};

export default Reports;
