import { uniqueFlatMap } from "miter-utils";
import { useCallback, useMemo } from "react";
import { JobScopeGroup, PermissionGroupModuleScopeType } from "backend/models/permission-group";
import { useHydratedPermissionGroups, useActiveTeamMember, useJobs, useLookupJob } from "../atom-hooks";
import { AggregatedJob, PermissionPaths } from "dashboard/miter";

type JobScopesHelperData = {
  managingJobs: AggregatedJob[];
  departmentJobs: AggregatedJob[];
  locationJobs: AggregatedJob[];
  manuallyAssignedJobs: AggregatedJob[];
};

type UseJobScopeUnfurler = {
  (jobScopes: JobScopeGroup[] | null): AggregatedJob[];
};

/**
 *  This hook returns a function that takes a job scope group and returns a list of jobs that match the scope.
 *  It is used to get the list of jobs that a user can take an action on based on their job scope.
 */
export const useJobGroupUnfurler = (): UseJobScopeUnfurler => {
  const activeTeamMember = useActiveTeamMember();
  const jobs = useJobs();
  const lookupJob = useLookupJob();

  const helperData: JobScopesHelperData = useMemo(() => {
    const managingJobs: AggregatedJob[] = [];
    const departmentJobs: AggregatedJob[] = [];
    const locationJobs: AggregatedJob[] = [];
    const manuallyAssignedJobs: AggregatedJob[] = [];

    // Create a list of manually assigned jobs on the jobs profile as a set for O(1) lookup later
    const activeTeamMemberJobListLookup = activeTeamMember?.limit_accessible_jobs
      ? new Set<string>(activeTeamMember.accessible_job_ids)
      : undefined;

    // Iterate through each job to build each of the helper job lists
    jobs.forEach((job) => {
      if (!activeTeamMember) return;

      // Add the job to the list of jobs that the user is managing
      const isManaging =
        job.supervisors.some((sup) => sup._id === activeTeamMember?._id) ||
        job.superintendents.some((sup) => sup._id === activeTeamMember?._id);

      if (isManaging) {
        managingJobs.push(job);
      }

      // Add the job to the list of jobs in the user's department
      const isDepartmentJob = job.department_id === activeTeamMember?.department_id;
      if (isDepartmentJob) {
        departmentJobs.push(job);
      }

      // Add the job to the list of jobs in the user's location
      const isLocationJob = job.location_id === activeTeamMember?.location_id;
      if (isLocationJob) {
        locationJobs.push(job);
      }

      // Add the job to the list of jobs assigned to the user has a job list
      if (activeTeamMemberJobListLookup) {
        const isManuallyAssigned =
          activeTeamMemberJobListLookup.has(job._id) || job.team_members?.includes(activeTeamMember._id);

        if (isManuallyAssigned) {
          manuallyAssignedJobs.push(job);
        }
      }
    });

    return {
      managingJobs,
      departmentJobs,
      locationJobs,
      manuallyAssignedJobs,
    };
  }, [activeTeamMember, jobs, lookupJob]);

  const unfurlJobGroup = useCallback(
    (jobScopes: JobScopeGroup[] | null): AggregatedJob[] => {
      if (!jobScopes?.length) return jobs;

      const { managingJobs, departmentJobs, locationJobs, manuallyAssignedJobs } = helperData;

      const jobsArrays = jobScopes.map((scope) => {
        switch (scope.type) {
          case "jobs_managing":
            if (!activeTeamMember) return [];
            return managingJobs;
          case "jobs_manually_assigned":
            if (!activeTeamMember) return [];
            return manuallyAssignedJobs;
          case "jobs_in_department":
            if (!activeTeamMember) return [];
            return departmentJobs;
          case "jobs_in_location":
            if (!activeTeamMember) return [];
            return locationJobs;
          case "all_jobs":
            return jobs;
          default:
            return [];
        }
      });

      const flattenedJobs = uniqueFlatMap(jobsArrays, (job) => job._id);
      return flattenedJobs;
    },
    [jobs, helperData, activeTeamMember]
  );

  return unfurlJobGroup;
};

type JobScopesHelpers = {
  getJobPermissions: (
    jobId: string,
    module: PermissionGroupModuleScopeType
  ) => {
    can: (action: PermissionPaths) => boolean;
    cannot: (action: PermissionPaths) => boolean;
  };

  getJobsForPermission: (permission: PermissionPaths, mod: PermissionGroupModuleScopeType) => AggregatedJob[];

  getJobIdsForPermission: (permission: PermissionPaths, mod: PermissionGroupModuleScopeType) => string[];
};

/**
 *  This hook returns a set of functions that help build the job scopes for the user
 */
export const useJobScopesHelpers = (): JobScopesHelpers => {
  const hydratedPermissionGroups = useHydratedPermissionGroups();

  const getJobPermissions = useCallback(
    (jobId: string, mod: PermissionGroupModuleScopeType) => {
      const scopedList = hydratedPermissionGroups
        .filter((item) => item.scopedJobs[mod]?.idsSet.has(jobId))
        .map((item) => item.abilities);

      const can = (action: PermissionPaths): boolean => {
        if (!action) return false;
        return scopedList.some((abilities) => abilities.can(action));
      };

      const cannot = (action: PermissionPaths): boolean => {
        return !can(action);
      };

      return { can, cannot };
    },
    [hydratedPermissionGroups]
  );

  /**
   * Get's the list of jobs that we can take an action on
   *
   * @param permission - The permission we want to take
   * @param mod - The module we want to take the action in (e.g. human_resources, workforce_management, etc)
   * @returns {AggregatedJob[]}
   */
  const getJobsForPermission = useCallback(
    (permission: PermissionPaths, mod: PermissionGroupModuleScopeType) => {
      const jobs = hydratedPermissionGroups.flatMap((item) => {
        if (item.abilities.can(permission || "")) {
          return item.scopedJobs[mod]?.list || [];
        } else {
          return [];
        }
      });

      return jobs;
    },
    [hydratedPermissionGroups]
  );

  /**
   * Get the ids of the jobs we can take an action on
   *
   * @param action - The action we want to take
   * @param mod - The module we want to take the action in (e.g. human_resources, workforce_management, etc)
   * @returns {string[]}
   */
  const getJobIdsForPermission = useCallback(
    (permission: PermissionPaths, mod: PermissionGroupModuleScopeType) => {
      const jobIds = hydratedPermissionGroups.flatMap((item) => {
        if (item.abilities.can(permission || "")) {
          return item.scopedJobs[mod]?.ids || [];
        } else {
          return [];
        }
      });

      return jobIds;
    },
    [hydratedPermissionGroups]
  );

  return {
    getJobPermissions,
    getJobsForPermission,
    getJobIdsForPermission,
  };
};
