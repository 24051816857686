import React, { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button } from "ui";
import PayrollContext from "../payrollContext";
import { capitalize, formatDate, payrollTypeLookup } from "dashboard/utils/utils";
import NonDraftPayrollSummary from "./NonDraftPayrollSummary";
import Timesheets from "./NonDraftPayrollTimesheets";
import ReopenPayrollModal from "./ReopenPayrollModal";
import { Toggler } from "ui";
import DownloadChecksModal from "./DownloadChecksModal";
import { Helmet } from "react-helmet";
import ReviewTimeOff from "../ReviewTimeOff";
import { NonDraftPayrollReports } from "./NonDraftPayrollReports";
import DownloadPaystubsModal from "./DownloadPaystubsModal";
import PaymentsTable from "../PaymentsTable";
import OffCycleOptions from "../DraftPayroll/OffCycleOptions";
import ReviewExpenses from "../ReviewExpenses";
import { HoursByPayPeriodTable } from "dashboard/pages/reports/reportPages/HoursByPayPeriodTable";
import { WireDetailsModal } from "../WireDetailsModal";
import { GridApi } from "ag-grid-community";
import { cleanPayScheduleLabel } from "../viewPayrollUtils";
import BillsTable from "dashboard/components/bills/BillsTable";

const NonDraftPayroll: React.FC = () => {
  const { payroll, stdTogglerItems, aggEarnings, setShowWireDetails, showWireDetails, wireDetails } =
    React.useContext(PayrollContext);
  const checkPayroll = payroll?.check_payroll;
  const navigate = useNavigate();
  const { view } = useParams<{ view: string }>();
  const activeView = view || "summary";

  const [isReopening, setIsReopening] = useState(false);
  const [downloadPaychecksModal, setDownloadPaychecksModal] = useState(false);
  const [downloadPaystubsModal, setDownloadPaystubsModal] = useState(false);
  const [workweekGridApi, setWorkweekGridApi] = useState<GridApi | undefined>();

  const togglerConfig = useMemo(() => {
    return [{ label: "Summary", path: "summary" }, ...stdTogglerItems, { label: "Reports", path: "reports" }];
  }, [stdTogglerItems]);

  const handleToggle = (path: string) => {
    navigate("/payrolls/" + payroll!._id + "/" + path, { replace: true });
  };

  const descriptor =
    formatDate(checkPayroll?.period_start, checkPayroll?.period_end) +
    " " +
    (payroll?.type === "off_cycle"
      ? `${payroll.label ? payroll.label + " o" : "O"}ff-cycle`
      : payroll?.pay_schedule?.label
      ? cleanPayScheduleLabel(payroll.pay_schedule.label)
      : capitalize(payrollTypeLookup[payroll!.type]));

  const pageTitle = descriptor + " Payroll";
  const header = descriptor + " payroll";

  const payrollHasPaperChecks = useMemo(() => {
    if (!payroll || !checkPayroll || payroll.type === "historical" || payroll.type === "amendment") {
      return false;
    }
    return (
      checkPayroll.items.some((item) => item.payment_method === "manual") ||
      checkPayroll.contractor_payments.some((cp) => cp.payment_method === "manual")
    );
  }, [payroll]);

  const payrollIsFullyManual = useMemo(() => {
    if (!payroll || !checkPayroll || payroll.type === "historical" || payroll.type === "amendment") {
      return false;
    }
    return (
      checkPayroll.items.every((item) => item.payment_method === "manual") &&
      checkPayroll.contractor_payments.every((cp) => cp.payment_method === "manual")
    );
  }, [payroll]);

  const payrollStatus = useMemo(() => {
    // If payroll is fully manual and switches to processing, it's considered paid
    return checkPayroll?.status.includes("paid") ||
      (payrollIsFullyManual && checkPayroll?.status === "processing")
      ? "paid"
      : checkPayroll?.status;
  }, [payroll]);

  const buttonDropdownItems = useMemo(() => {
    if (!payroll || !checkPayroll || payroll.type === "historical" || payroll.type === "amendment") return [];

    const items = [{ text: "Download paystubs", onClick: () => setDownloadPaystubsModal(true) }];

    if (payrollHasPaperChecks) {
      items.push({ text: "Download paper checks", onClick: () => setDownloadPaychecksModal(true) });
    }

    if (checkPayroll.status === "pending") {
      items.push({ text: "Reopen payroll", onClick: () => setIsReopening(true) });
    } else if (checkPayroll.status === "processing" && checkPayroll.funding_payment_method === "wire") {
      items.push({ text: "View wire details", onClick: () => setShowWireDetails(true) });
    }
    return items;
  }, [payroll, payrollHasPaperChecks]);

  if (!payroll || !checkPayroll || !payrollStatus) return null;

  return (
    <div className="height-100">
      <Helmet>
        <title>{pageTitle} | Miter</title>
      </Helmet>
      <div className="page-content">
        {isReopening && <ReopenPayrollModal hide={() => setIsReopening(false)} />}
        {downloadPaychecksModal && <DownloadChecksModal hide={() => setDownloadPaychecksModal(false)} />}
        {downloadPaystubsModal && <DownloadPaystubsModal hide={() => setDownloadPaystubsModal(false)} />}
        {showWireDetails && (
          <WireDetailsModal wireDetails={wireDetails} hide={() => setShowWireDetails(false)} />
        )}
        <div className="mini-sidebar-page-header">
          <h1>{header}</h1>
          <Badge text={payrollStatus} />
          {checkPayroll.status === "partially_paid" && (
            <Badge text={"partial"} className="no-margin" color="yellow" />
          )}
          {payroll?.void_of && <Badge text={"void"} className="no-margin" color="light-gray" />}
          <div className="flex-1"></div>
          {buttonDropdownItems.length ? <Button text="Actions" dropdownItems={buttonDropdownItems} /> : null}
        </div>
        <Toggler config={togglerConfig} active={activeView} toggle={handleToggle} />
        <div className="mini-sidebar-flex">
          <div className="mini-sidebar-page-active-view">
            {activeView === "summary" && <NonDraftPayrollSummary status={payrollStatus} />}
            {activeView === "payments" && <PaymentsTable editing={false} />}
            {activeView === "timesheets" && <Timesheets />}
            {activeView === "reports" && <NonDraftPayrollReports />}
            {activeView === "time_off" && (
              <ReviewTimeOff viewOnly={true} emptyHeader="This payroll has no time off requests." />
            )}
            {activeView === "reimbursements" && (
              <ReviewExpenses viewOnly={true} emptyHeader="This payroll has no expense reimbursements." />
            )}
            {activeView === "workweek" && (
              <HoursByPayPeriodTable
                periodStart={checkPayroll.period_start}
                periodEnd={checkPayroll.period_end}
                payroll={payroll}
                earnings={aggEarnings}
                payScheduleId={payroll.pay_schedule_id}
                gridApi={workweekGridApi}
                setGridApi={setWorkweekGridApi}
              />
            )}
            {activeView === "bills" && <BillsTable showToggler={false} />}
            {activeView === "settings" && <OffCycleOptions readOnly />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonDraftPayroll;
