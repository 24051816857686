import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { Timesheet } from "dashboard/miter";
import { DateTime } from "luxon";
import { readableSeconds } from "miter-utils";
import React, { FC, useMemo, useState } from "react";
import { Popover } from "react-tiny-popover";

type Props = {
  timesheet?: { breaks?: Timesheet["breaks"]; timezone?: string };
  type?: "paid" | "unpaid";
};

export const AdvancedBreakTimeHover: React.FC<Props> = ({ timesheet, type }) => {
  const activeCompany = useActiveCompany();
  const breakTypesObject = activeCompany?.settings.timesheets.break_types || {};

  const breaks = useMemo(() => {
    return (timesheet?.breaks || [])?.filter((b) => {
      const breakType = breakTypesObject[b.break_type_id];
      if (!breakType) return false;

      if (!type) return true;
      if (type === "paid" && breakType.paid) return true;
      if (type === "unpaid" && !breakType.paid) return true;
    });
  }, [timesheet?.breaks, breakTypesObject]);

  // If returning early, we need to return a full <div /> or else we get all sorts of errors from AgGrid
  if (!activeCompany?.settings?.timesheets?.advanced_break_time) return <div />;
  if (!breaks.length) return <div />;

  return (
    <div
      style={{
        background: "#fff",
        padding: 15,
        border: "1px solid #eee",
        borderRadius: 4,
        fontSize: "0.9rem",
        width: 500,
      }}
      className="breakTimeTable"
    >
      <p style={{ marginTop: 0, marginBottom: 10, fontWeight: "bold" }}>Advanced break time</p>
      <table className={"simple-table"}>
        <colgroup>
          <col span={1} style={{ width: 120 }} />
          <col span={1} style={{ width: 120 }} />
          <col span={1} />
        </colgroup>
        <thead className={"simple-table-header"}>
          <tr>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody className={"simple-table-body"}>
          {breaks.map((b) => {
            const parsedBreakStart = DateTime.fromSeconds(b.start, { zone: timesheet?.timezone });
            const parsedBreakEnd = DateTime.fromSeconds(b.end, { zone: timesheet?.timezone });
            const breakTypeLabel = breakTypesObject[b.break_type_id]?.label;
            const isPaid = breakTypesObject[b.break_type_id]?.paid ? "Paid" : "Unpaid";

            return (
              <tr key={b.break_type_id}>
                <td>{parsedBreakStart?.toFormat("h:mm a ZZZZ")}</td>
                <td>{parsedBreakEnd?.toFormat("h:mm a ZZZZ")}</td>
                <td>
                  {breakTypeLabel} ({isPaid})
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const AdvancedBreakTimeButton: FC<Props> = ({ timesheet, type }) => {
  const [showHover, setShowHover] = useState(false);
  const activeCompany = useActiveCompany();
  const breakTypesObject = activeCompany?.settings.timesheets.break_types || {};

  const { unpaidTotal, paidTotal } = useMemo(() => {
    const unpaidTotal = (timesheet?.breaks || []).reduce((acc, b) => {
      const breakType = breakTypesObject[b.break_type_id];
      if (!breakType || breakType.paid) return acc;
      return acc + b.end - b.start;
    }, 0);

    const paidTotal = (timesheet?.breaks || []).reduce((acc, b) => {
      const breakType = breakTypesObject[b.break_type_id];
      if (!breakType || !breakType.paid) return acc;
      return acc + b.end - b.start;
    }, 0);

    return { unpaidTotal, paidTotal };
  }, [timesheet?.breaks, breakTypesObject]);

  return (
    <>
      <Popover
        isOpen={showHover}
        positions={["bottom"]}
        containerStyle={{ zIndex: "5" }}
        align="start"
        content={<AdvancedBreakTimeHover timesheet={timesheet} type={type} />}
      >
        <span onClick={() => setShowHover(!showHover)} className="purple-link">
          {readableSeconds(type === "paid" ? paidTotal : unpaidTotal)}
        </span>
      </Popover>
    </>
  );
};
