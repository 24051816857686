import React, { useState, useEffect } from "react";
import { ClickableText, TableV2 } from "ui";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Helmet } from "react-helmet";
import { downloadBlob } from "dashboard/utils";
import { CheckTmTaxDocument } from "backend/utils/check/check-types";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Download } from "phosphor-react";
import { baseSensitiveCompare, zipAsync } from "miter-utils";
import { saveAs } from "file-saver";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useTeam } from "dashboard/hooks/atom-hooks";
import { useNavigate } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type AllTmTaxTableEntry = CheckTmTaxDocument & {
  _id: string;
  tm: AggregatedTeamMember;
  downloadButton: JSX.Element;
};

export const AllTmTaxForms: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const { cannot } = useMiterAbilities();

  const teamMembers = useTeam();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<AllTmTaxTableEntry[]>();
  const [selectedRows, setSelectedRows] = useState<AllTmTaxTableEntry[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);

  const getForms = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.companies.documents.retrieve_all_tm_tax(activeCompanyId!);
      if (response.error) throw new Error(response.error);

      const newForms = response
        .map((form) => {
          const tm = teamMembers.find((tm) => {
            const checkId = "employee" in form ? form.employee : form.contractor;
            return tm.check_id === checkId;
          });

          return {
            ...form,
            _id: form.id,
            tm: tm!,
            downloadButton: (
              <ClickableText text="Download" onClick={() => download([{ ...form, tm: tm! }])} />
            ),
          };
        })
        .filter((f) => !!f.tm)
        .sort((a, b) => {
          const yearD = b.year - a.year;
          if (yearD) return yearD;
          return baseSensitiveCompare(a.label, b.label);
        });
      setTableData(newForms);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving the company's tax forms.");
    }
    setLoading(false);
  };

  const download = async (forms: (CheckTmTaxDocument & { tm: AggregatedTeamMember })[]) => {
    setDownloading(true);
    try {
      const responses = await Promise.all(
        forms.map((form) =>
          MiterAPI.team_member.retrieve_tax_document(form.tm._id, form.id).then((res) => {
            if (res.error) throw new Error(res.error);
            return res;
          })
        )
      );

      if (responses.length === 1) {
        const form = forms[0]!;
        downloadBlob(await responses[0]!.blob(), `${form.year} ${form.tm.full_name} ${form.label}.pdf`);
      } else {
        const bufferMap: { [key: string]: Buffer } = {};
        await Promise.all(
          responses.map(async (res, i) => {
            const form = forms[i]!;
            const buffer = await res.arrayBuffer();
            const key = `${form.year} ${form.tm.full_name} ${form.label}.pdf`;
            bufferMap[key] = Buffer.from(buffer);
          })
        );

        const zippedTaxDocs = await zipAsync(bufferMap);
        const data = Buffer.from(zippedTaxDocs);

        saveAs(new Blob([data]), `Team Member Tax Documents.zip`);
      }

      Notifier.success("Tax forms downloaded successfully.");
      setSelectedRows([]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error downloading forms. We're looking into it!");
    }
    setDownloading(false);
  };

  const handleBulkDownload = () => {
    const selectedRowIds = selectedRows.map((r) => r._id);
    const forms = tableData?.filter((f) => selectedRowIds.includes(f._id)) || [];
    download(forms);
  };

  const dynamicActions: TableActionLink[] =
    selectedRows.length > 0
      ? [
          {
            label: "Download",
            action: handleBulkDownload,
            loading: downloading,
            className: "button-2 no-margin table-button",
            icon: <Download weight="bold" style={{ marginRight: 3 }} />,
          },
        ]
      : [];

  useEffect(() => {
    if (cannot("documents:team_member:read_sensitive")) {
      Notifier.error("You do not have permission to view this page.");
      navigate("/home");
    }
  }, [cannot]);

  useEffect(() => {
    getForms();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Tax Forms | Miter</title>
      </Helmet>
      <TableV2
        id="company-tax-forms"
        resource="tax forms"
        data={tableData}
        columns={columns}
        onSelect={(rows) => setSelectedRows(rows as AllTmTaxTableEntry[])}
        defaultSelectedRows={selectedRows}
        dynamicActions={dynamicActions}
        isLoading={loading}
      />
    </div>
  );
};

const columns: ColumnConfig<AllTmTaxTableEntry>[] = [
  { field: "tm.first_name", headerName: "First name", dataType: "string" },
  { field: "tm.last_name", headerName: "Last name", dataType: "string" },
  { field: "label", headerName: "Form", dataType: "string", width: 150 },
  { field: "year", headerName: "Year", dataType: "number", width: 100 },
  { field: "filed_on", headerName: "Filed on", dataType: "date", width: 150 },
  { field: "downloadButton", headerName: " ", dataType: "component" as const },
];
