import { GroupTypeOptions } from "dashboard/hooks/useTeamMemberGroups";
import { keyBy } from "lodash";
import { FieldRequirement } from "backend/models/company/company-settings";
import {
  SignOffMethod,
  ExpensePolicyField,
  ExpenseReimbursementPolicyField,
  TimesheetPolicyField,
  TimeOffRequestPolicyField,
  ChangeRequestPolicyField,
} from "backend/models/policy";
import { MiterFieldOperator } from "backend/utils";
import { Option } from "ui/form/Input";
import { Assign } from "utility-types";
import { ApproverGroupType, PolicyType } from "backend/services/approvals/types";
import { yesNoOptions } from "miter-utils";
import {
  useCardTransactionCategoryOptions,
  useExpenseReimbursementCategoryOptions,
  useLedgerAccountOptions,
} from "dashboard/hooks/atom-hooks";
import { useMemo } from "react";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

export type OperatorOption = Assign<Option<MiterFieldOperator>, { field: "value" | "range" | "none" }>;
export type FieldOptionExtras = {
  operators: string[];
  type?: "string" | "number" | "boolean";
  opts?: Option<string>[];
};

export type ExpensePolicyConditionFieldOption = Assign<Option<ExpensePolicyField>, FieldOptionExtras>;
export type ExpenseReimbursementPolicyConditionFieldOption = Assign<
  Option<ExpenseReimbursementPolicyField>,
  FieldOptionExtras
>;
export type TimesheetPolicyConditionFieldOption = Assign<Option<TimesheetPolicyField>, FieldOptionExtras>;
export type TimeOffRequestPolicyConditionFieldOption = Assign<
  Option<TimeOffRequestPolicyField>,
  FieldOptionExtras
>;

export type ChangeRequestPolicyConditionFieldOption = Assign<
  Option<ChangeRequestPolicyField>,
  FieldOptionExtras
>;

export type ConditionFieldOption =
  | ExpensePolicyConditionFieldOption
  | ExpenseReimbursementPolicyConditionFieldOption
  | TimesheetPolicyConditionFieldOption
  | TimeOffRequestPolicyConditionFieldOption
  | ChangeRequestPolicyConditionFieldOption;

export const OrOption: Option<"or"> = { label: "OR", value: "or" };
export const AndOption: Option<"and"> = { label: "AND", value: "and" };
export const AndOrOptions: Option<"and" | "or">[] = [OrOption, AndOption];

export const OperatorOptionLookup = (key: string): OperatorOption | undefined => {
  if (!key) return;
  return keyBy(OperatorOptions, "value")[key];
};

export const ApproverGroupTypeOptionLookup = (key: string): Option<ApproverGroupType> | undefined => {
  if (!key) return;
  return keyBy(GroupTypeOptions, "value")[key];
};

export const ExpensePolicyFieldOptions: Option<ExpensePolicyField>[] = [
  { label: "Receipt", value: "file_ids" },
  { label: "Submitter Memo", value: "submitter_note" },
  { label: "Job", value: "job_id" },
  { label: "Activity", value: "activity_id" },
  { label: "Cost Type", value: "cost_type_id" },
  { label: "GL Account", value: "expense_account_id" },
  { label: "Category", value: "card_transaction_category_id" },
];

// need this as a React custom hook to dynamically generate select options
export const useGetCardTransactionPolicyConditionFieldOptions = (): ExpensePolicyConditionFieldOption[] => {
  const cardTransactionCategoryOptions = useCardTransactionCategoryOptions();
  const glAccountOptions = useLedgerAccountOptions();

  const options = useMemo((): ExpensePolicyConditionFieldOption[] => {
    return [
      {
        label: "Amount",
        value: "amount",
        operators: ["=", "ne", "<", "<=", ">", ">=", "<+>", "<+>e"],
        type: "number",
      },
      {
        label: "Category",
        value: "card_transaction_category_id",
        operators: ["=", "ne", "exists", "not.exists"],
        opts: cardTransactionCategoryOptions,
      },
      { label: "Job", value: "job_id", operators: ["exists", "not.exists"] },
      { label: "Activity", value: "activity_id", operators: ["exists", "not.exists"] },
      { label: "Cost Type", value: "cost_type_id", operators: ["exists", "not.exists"] },
      {
        label: "GL Account",
        value: "expense_account_id",
        operators: ["=", "ne", "exists", "not.exists"],
        opts: glAccountOptions,
      },
    ];
  }, [cardTransactionCategoryOptions]);

  return options;
};

export const ExpenseReimbursementPolicyFieldOptions: Option<ExpenseReimbursementPolicyField>[] = [
  { label: "Receipt", value: "file_ids" },
  { label: "Submitter Memo", value: "memo" },
  { label: "Job", value: "job_id" },
  { label: "Activity", value: "activity_id" },
  { label: "Cost Type", value: "cost_type_id" },
  { label: "GL Account", value: "expense_account_id" },
];

export const TimeOffRequestPolicyFieldOptions: Option<TimeOffRequestPolicyField>[] = [
  { label: "Employee note", value: "employee_note" },
  { label: "Company note", value: "company_note" },
];

export const TimeOffRequestConditionFieldOptions: TimeOffRequestPolicyConditionFieldOption[] = [
  { label: "Employee note", value: "employee_note", operators: ["exists", "not.exists"] },
  { label: "Company note", value: "company_note", operators: ["exists", "not.exists"] },
];

export const ChangeRequestPolicyFieldOptions: Option<ChangeRequestPolicyField>[] = [
  { label: "Notes", value: "notes" },
];
ChangeRequestPolicyFieldOptions.push({ label: "Scheduled date", value: "effective_date" });

export const ChangeRequestPolicyConditionFieldOptions: ChangeRequestPolicyConditionFieldOption[] = [
  {
    label: "Notes",
    value: "notes",
    operators: ["exists", "not.exists"],
  },
];
ChangeRequestPolicyConditionFieldOptions.push({
  label: "Scheduled date",
  value: "effective_date",
  operators: ["exists", "not.exists"],
});

// need this as a React custom hook to dynamically generate select options
export const useGetExpenseReimbursementPolicyConditionFieldOptions =
  (): ExpenseReimbursementPolicyConditionFieldOption[] => {
    const expenseReimbursementCategoryOptions = useExpenseReimbursementCategoryOptions();

    const glAccountOptions = useLedgerAccountOptions();

    const options = useMemo((): ExpenseReimbursementPolicyConditionFieldOption[] => {
      return [
        {
          label: "Amount",
          value: "amount",
          operators: ["=", "ne", "<", "<=", ">", ">=", "<+>", "<+>e"],
          type: "number",
        },
        {
          label: "Category",
          value: "expense_reimbursement_category_id",
          operators: ["=", "ne", "exists", "not.exists"],
          opts: expenseReimbursementCategoryOptions,
        },
        { label: "Job", value: "job_id", operators: ["exists", "not.exists"] },
        { label: "Activity", value: "activity_id", operators: ["exists", "not.exists"] },
        { label: "Cost Type", value: "cost_type_id", operators: ["exists", "not.exists"] },
        {
          label: "GL Account",
          value: "expense_account_id",
          operators: ["=", "ne", "exists", "not.exists"],
          opts: glAccountOptions,
        },
      ];
    }, [expenseReimbursementCategoryOptions]);

    return options;
  };

export const useConditionLookup = (
  key: string,
  policyType?: PolicyType
): ConditionFieldOption | undefined => {
  const expenseReimbursementPolicyConditionOptions = useGetExpenseReimbursementPolicyConditionFieldOptions();
  const cardTransactionPolicyConditionOptions = useGetCardTransactionPolicyConditionFieldOptions();

  if (policyType === "expense") {
    return keyBy(cardTransactionPolicyConditionOptions, "value")[key];
  } else if (policyType === "expense_reimbursement") {
    return keyBy(expenseReimbursementPolicyConditionOptions, "value")[key];
  } else if (policyType === "timesheet") {
    return keyBy(TimesheetConditionFieldOptions, "value")[key];
  } else if (policyType === "time_off_request") {
    return keyBy(TimeOffRequestConditionFieldOptions, "value")[key];
  }
};

export const TimesheetPolicyFieldOptions: Option<TimesheetPolicyField>[] = [
  { label: "Job", value: "job" },
  { label: "Activity", value: "activity" },
  { label: "Cost type", value: "cost_type_id" },
  { label: "Injury", value: "injury" },
  { label: "Clock in photo", value: "clock_in_photo" },
  { label: "Clock out photo", value: "clock_out_photo" },
  { label: "Classification", value: "classification_override" },
  { label: "Notes", value: "notes" },
  { label: "Images", value: "images" },
];

export const useTimesheetPolicyFieldOptions = (): Option<TimesheetPolicyField>[] => {
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const timesheetPolicyFieldOptions: Option<TimesheetPolicyField>[] = TimesheetPolicyFieldOptions;
  if (hasAccessToEquipmentTracking) {
    timesheetPolicyFieldOptions.push({
      label: "Equipment",
      value: "equipment_ids",
    });
  }
  return timesheetPolicyFieldOptions;
};

export const TimesheetConditionFieldOptions: TimesheetPolicyConditionFieldOption[] = [
  { label: "Job", value: "job", operators: ["exists", "not.exists"] },
  { label: "Activity", value: "activity", operators: ["exists", "not.exists"] },
  { label: "Cost type", value: "cost_type_id", operators: ["exists", "not.exists"] },
  {
    label: "Hours",
    value: "hours",
    operators: ["=", "ne", "<", "<=", ">", ">=", "<+>", "<+>e"],
    type: "number",
  },
  { label: "Injury", value: "injury", operators: ["="], opts: yesNoOptions, type: "boolean" },
  { label: "Classification", value: "classification_override", operators: ["exists", "not.exists"] },
];

export const useTimesheetConditionFieldOptions = (): TimesheetPolicyConditionFieldOption[] => {
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const timesheetConditionFieldOptions: TimesheetPolicyConditionFieldOption[] =
    TimesheetConditionFieldOptions;
  if (hasAccessToEquipmentTracking) {
    timesheetConditionFieldOptions.push({
      label: "Equipment",
      value: "equipment_ids",
      operators: ["exists", "not.exists"],
    });
  }
  return timesheetConditionFieldOptions;
};

export const OperatorOptions: OperatorOption[] = [
  { label: "is", value: "=", field: "value" },
  { label: "is not", value: "ne", field: "value" },
  { label: "is less than", value: "<", field: "value" },
  { label: "is less than or equal to", value: "<=", field: "value" },
  { label: "is greater than", value: ">", field: "value" },
  { label: "is greater than or equal to", value: ">=", field: "value" },
  { label: "is between", value: "<+>", field: "range" },
  { label: "is not between", value: "<+>e", field: "range" },
  { label: "exists", value: "exists", field: "none" },
  { label: "does not exist", value: "not.exists", field: "none" },
  { label: "starts with", value: "startsWith", field: "value" },
  { label: "ends with", value: "endsWith", field: "value" },
];

export const EnforceFieldOptions: Option<FieldRequirement>[] = [
  { label: "Required", value: "required" },
  { label: "Hidden", value: "hidden" },
];

export const SignOffMethodOptions: Option<SignOffMethod>[] = [
  { label: "Checkbox", value: "checkbox" },
  { label: "E-Signature", value: "signature" },
];

export const getApprovalItemType = (
  policyType: PolicyType
): "timesheet" | "expense" | "reimbursement" | "time off request" | "team member profile change" => {
  switch (policyType) {
    case "timesheet":
      return "timesheet";
    case "expense":
      return "expense";
    case "expense_reimbursement":
      return "reimbursement";
    case "time_off_request":
      return "time off request";
    case "team_member_change_request":
      return "team member profile change";
    default:
      throw new Error(`Unknown policy type: ${policyType}`);
  }
};
