import { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import ObjectID from "bson-objectid";
import {
  useActiveCompany,
  useActivityOptionsMap,
  useEquipmentOptions,
  useJobOptions,
  useLookupActivity,
  useLookupEquipment,
  useLookupJob,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { DateTime } from "luxon";
import { IconWithTooltip, Notifier, PageModal, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "packages/ui/table-v2/Table";
import { Copy, FloppyDisk, Plus, Trash } from "phosphor-react";
import { FC, useCallback, useState } from "react";
import { MiterAPI } from "dashboard/miter";
import { CreateEquipmentTimesheetParams } from "backend/services/equipment-timesheet-service";

type BulkCreateEquipmentTimesheetModalProps = {
  onHide: () => void;
};

type DraftEquipmentTimesheet = {
  _id: string;

  equipment_id?: string;
  team_member_id?: string;
  clock_in?: number;
  clock_out?: number;
  job_id?: string;
  job_hierarchy_ids?: string[];
  activity_id?: string;
  notes?: string;
  hours?: number;
  quantity: number;
  created_by?: string;
};

const BulkCreateEquipmentTimesheetModal: FC<BulkCreateEquipmentTimesheetModalProps> = ({ onHide }) => {
  // hooks
  const activeCompany = useActiveCompany();
  const jobOptions = useJobOptions();
  const lookupJob = useLookupJob();
  const lookupEquipment = useLookupEquipment();
  const activityOptionsMappedByJob = useActivityOptionsMap({
    predicate: isTimesheetScoped,
  });
  const lookupActivity = useLookupActivity();
  const activeUser = useUser();
  const equipmentOptions = useEquipmentOptions();

  // state
  const [draftEquipmentTimesheets, setDraftEquipmentTimesheets] = useState<DraftEquipmentTimesheet[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<DraftEquipmentTimesheet[]>([]);

  // constants
  const todayAt9am = DateTime.now().set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).toSeconds();
  const todayAt5pm = DateTime.now().set({ hour: 17, minute: 0, second: 0, millisecond: 0 }).toSeconds();
  const emptyDraftEquipmentTimesheet = {
    _id: ObjectID().toString(),
    equipment_id: undefined,
    company_id: activeCompany?._id,
    clock_in: todayAt9am,
    clock_out: todayAt5pm,
    hours: 8,
    quantity: 1,
    created_by: activeUser?._id,
  };

  // setting these up to support multiple rows - reuse for both single and bulk actions
  const duplicateRows = (rows: DraftEquipmentTimesheet[]) => {
    const newRows = rows.map((row) => {
      const newRow = { ...row, _id: ObjectID().toString() };
      return newRow;
    });

    setDraftEquipmentTimesheets([...draftEquipmentTimesheets, ...newRows]);
  };

  const deleteRows = (rows: DraftEquipmentTimesheet[]) => {
    const rowIdsToDelete = rows.map((row) => row._id);
    const newRows = draftEquipmentTimesheets.filter((timesheet) => !rowIdsToDelete.includes(timesheet._id));
    setDraftEquipmentTimesheets(newRows);
  };

  // adds new row to the table
  const handleAddDraftEquipmentTimesheetRow = useCallback(() => {
    setDraftEquipmentTimesheets([...draftEquipmentTimesheets, emptyDraftEquipmentTimesheet]);
  }, [draftEquipmentTimesheets]);

  const handleSubmit = async () => {
    if (!activeCompany) return;
    if (!activeUser) return;

    const equipmentTimesheetCreationParams: CreateEquipmentTimesheetParams[] = [];

    for (const [i, row] of draftEquipmentTimesheets.entries()) {
      const errorMessagePrefix = `Row ${i + 1}: `;
      if (!row.equipment_id) {
        Notifier.error(errorMessagePrefix + "Equipment is required.");
        return;
      } else if (!row.hours || row.hours <= 0 || row.hours > 24) {
        Notifier.error(errorMessagePrefix + "Hours must be between 0 and 24.");
        return;
      } else if (!row.clock_in) {
        Notifier.error(errorMessagePrefix + "Clock in time is required.");
        return;
      } else if (!row.quantity || row.quantity < 1) {
        Notifier.error(errorMessagePrefix + "Quantity must be at least 1.");
        return;
      } else if (row.clock_in && (!row.clock_out || row.clock_out - row.clock_in !== row.hours * 60 * 60)) {
        row.clock_out = row.clock_in + row.hours * 60 * 60;
      }
      if (!row.clock_out) {
        Notifier.error(errorMessagePrefix + "Clock out time is required.");
        return;
      }
      const creationParams: CreateEquipmentTimesheetParams = {
        equipment_id: row.equipment_id,
        clock_in: row.clock_in,
        clock_out: row.clock_out,
        job_id: row.job_id,
        activity_id: row.activity_id,
        notes: row.notes,
        hours: row.hours,
        quantity: row.quantity,
        company_id: activeCompany._id,
        created_by: activeUser?._id,
        status: "unapproved",
      };

      equipmentTimesheetCreationParams.push(creationParams);
    }

    setLoading(true);
    try {
      await Promise.all(
        equipmentTimesheetCreationParams.map(async (equipmentTimesheetParams) => {
          // create new equipment timesheet
          const newEquipmentTimesheet = await MiterAPI.equipment_timesheets.create(equipmentTimesheetParams);
          if (newEquipmentTimesheet.error) throw new Error(newEquipmentTimesheet.error);
          console.log("newEquipmentTimesheet", newEquipmentTimesheet);
        })
      );
    } catch (e) {
      Notifier.error("Failed to create equipment timesheets.");
      console.error(e);
      return;
    } finally {
      setLoading(false);
    }

    Notifier.success("Equipment timesheets created successfully.");
    setDraftEquipmentTimesheets([]);
    onHide();
  };

  const handleRowUpdate = async (updatedRows: DraftEquipmentTimesheet[]) => {
    setDraftEquipmentTimesheets(updatedRows);
  };

  // table actions
  const staticActions: TableActionLink[] = [
    {
      label: "Add row",
      action: handleAddDraftEquipmentTimesheetRow,
      className: "button-2 table-button",
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
      important: true,
    },
    {
      label: "Save",
      action: handleSubmit,
      className: "button-2 table-button",
      icon: <FloppyDisk weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
      important: true,
      loading,
    },
  ];

  const dynamicActions: TableActionLink[] = [
    {
      label: "Duplicate",
      action: () => duplicateRows(selectedRows),
      className: "button-1 table-button",
      icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
    },
    {
      label: "Delete",
      action: () => deleteRows(selectedRows),
      className: "button-1 table-button",
      icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
    },
  ];

  // callbacks
  const renderJobName = useCallback((params: ValueFormatterParams) => params.value?.name || "", []);

  const renderActivityLabel = useCallback((params: ValueFormatterParams) => params.value?.label || "", []);

  // columns
  const columns: ColumnConfig<DraftEquipmentTimesheet>[] = [
    {
      headerName: "Date",
      field: "clock_in",
      dataType: "number",
      editorType: "date",
      editorDateType: "iso",
      editable: true,
      pinned: "left",
      valueFormatter: (params: ValueFormatterParams<DraftEquipmentTimesheet>) => {
        return DateTime.fromISO(params.value).toFormat("MMM dd, yyyy");
      },
      valueGetter: (params: ValueGetterParams<DraftEquipmentTimesheet>) => {
        return params.data?.clock_in ? DateTime.fromSeconds(params.data.clock_in).toISO() : undefined;
      },
      validations: (value) => {
        if (!value) return "Date is required";
        return true;
      },
      valueEditor: (newValue: string, rowData: DraftEquipmentTimesheet) => {
        const newDateTime = DateTime.fromISO(newValue);
        const newClockInSeconds = newDateTime.toSeconds();
        if (rowData.clock_in) {
          const currentClockIn = DateTime.fromSeconds(rowData.clock_in);
          const currentClockInTimeInSeconds = currentClockIn.hour * 60 * 60 + currentClockIn.minute * 60;
          const newClockInTimeInSeconds = newClockInSeconds + currentClockInTimeInSeconds;
          if (rowData.hours) {
            rowData.clock_out = newClockInTimeInSeconds + rowData.hours * 60 * 60;
            return newClockInTimeInSeconds;
          }
        } else {
          return newClockInSeconds;
        }
      },
    },
    {
      field: "equipment_id",
      headerName: "Equipment",
      dataType: "string",
      editable: true,
      editorType: "select",
      valueFormatter: (params: ValueFormatterParams<DraftEquipmentTimesheet>) => {
        const equipment = lookupEquipment(params.value);
        return equipment ? equipment.name : "";
      },
      cellEditorParams: () => ({ options: equipmentOptions, isClearable: true }),
      validations: (value) => {
        if (!value) {
          return "Equipment is required";
        }
        return true;
      },
    },
    {
      field: "clock_in",
      headerName: "In",
      dataType: "number",
      dateType: "timestamp",
      editorType: "date",
      editorDateType: "iso",
      editable: true,
      minWidth: 120,
      valueFormatter: (params: ValueFormatterParams<DraftEquipmentTimesheet>) => {
        return DateTime.fromISO(params.value).toFormat("h:mm a");
      },
      valueGetter: (params: ValueGetterParams<DraftEquipmentTimesheet>) => {
        return params.data?.clock_in ? DateTime.fromSeconds(params.data.clock_in).toISO() : undefined;
      },
      validations: (value) => {
        if (!value) return "Clock in time is required";
        return true;
      },
      valueEditor: (newValue: string, rowData: DraftEquipmentTimesheet) => {
        const newDateTime = DateTime.fromISO(newValue);
        const newClockInSeconds = newDateTime.toSeconds();
        if (rowData.hours) {
          rowData.clock_out = newClockInSeconds + rowData.hours * 60 * 60;
        }
        return newClockInSeconds;
      },
    },
    {
      field: "hours",
      headerName: "Hours",
      editable: true,
      dataType: "number",
      minWidth: 120,
      valueEditor: (newValue: number, rowData: DraftEquipmentTimesheet) => {
        if (rowData.clock_in) rowData.clock_out = rowData.clock_in + newValue * 60 * 60;
        return newValue;
      },
      validations: (value) => {
        if (!value) return "Hours are required";
        if (value < 0 || value > 24) return "Hours must be between 0 and 24.";
        return true;
      },
    },
    {
      headerName: "Job",
      field: "job_id",
      dataType: "string",
      editable: true,
      editorType: "select",
      valueFormatter: renderJobName,
      valueGetter: (params) => {
        const job = params.data?.job_id;

        // job will be an id if selecting from the bulk edit flow
        if (typeof job === "string") return lookupJob(job);
        return job;
      },
      cellEditorParams: () => ({ options: jobOptions, isClearable: true }),
    },
    {
      headerName: "Activity",
      field: "activity_id",
      dataType: "string",
      editable: true,
      editorType: "select",
      valueFormatter: renderActivityLabel,
      valueGetter: (params) => {
        const activity = params.data?.activity_id;
        if (typeof activity === "string") return lookupActivity(activity);
        return activity;
      },
      cellEditorParams: (row: { data: { job_id?: string } }) => {
        const jobId = row?.data?.job_id;
        return {
          options: activityOptionsMappedByJob.get(jobId),
          isClearable: true,
        };
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      dataType: "string",
      editable: true,
      minWidth: 150,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      editable: true,
      dataType: "number",
      minWidth: 120,
      validations: (value) => {
        if (value < 1) return "Quantity must be at least 1.";
        return true;
      },
    },
    {
      headerName: " ",
      field: "actions",
      maxWidth: 75,
      pinned: "right",
      cellRenderer: (params: ICellRendererParams<DraftEquipmentTimesheet>) => {
        return (
          <div className="flex">
            <IconWithTooltip
              PhosphorIcon={Copy}
              tooltip={`Make a copy of this equipment timesheet.`}
              style={{ marginRight: 10, cursor: "pointer" }}
              onClick={() => duplicateRows(params.data ? [params.data] : [])}
            />
            <IconWithTooltip
              PhosphorIcon={Trash}
              tooltip={`Delete this equipment timesheet.`}
              style={{ cursor: "pointer" }}
              onClick={() => deleteRows(params.data ? [params.data] : [])}
            />
          </div>
        );
      },
    },
  ];

  return (
    <PageModal
      header={"Bulk create equipment timesheets"}
      onClose={onHide}
      bodyContentStyle={{ maxWidth: "unset", height: "100%" }} // PageModal default is 800px maxWidth, unset to override
    >
      <TableV2
        id="bulk-create-equipment-timesheets"
        resource="equipment timesheets"
        customEmptyStateMessage="Click 'Add row' to start creating equipment timesheets."
        data={draftEquipmentTimesheets}
        columns={columns}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={false}
        onSelect={setSelectedRows}
        isLoading={loading}
        hideSearch
        disablePagination
        hideFilters
        alwaysEditable
        editable
        hideRowEditingStatus
        onSave={handleRowUpdate}
        autoSave
        gridWrapperStyle={{ height: "100%" }}
        containerStyle={{ height: "80%" }}
        editableControlled={true}
      />
    </PageModal>
  );
};

export default BulkCreateEquipmentTimesheetModal;
