/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Formblock, Label, Notifier, Wizard, WizardScreen, Link, ConfirmModal, DisplaySignature } from "ui";
import {
  ESignatureItem,
  Form,
  FormSubmission,
  FrontendModel,
  MiterAPI,
  CreateFormSubmissionParams,
  UpdateFormSubmissionParams,
} from "dashboard/miter";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { FormField as FormField_, FormSection as FormSection_ } from "../../../backend/models/form";
import {
  buildFieldSpecificPropsForFormField,
  buildValidationRuleSetForFormField,
  cleanCustomFieldValueParams,
  getCustomFieldTypeForFormBlock,
  renderCustomDefaultValue,
  renderCustomFieldDefaultString,
} from "miter-utils";

import styles from "./Forms.module.css";
import { DateTime } from "luxon";
import ObjectID from "bson-objectid";
import {
  GroupedFormComponentsArray,
  buildGroupedFormComponentsObject,
  saveFormsAndUpdateAnswers,
  canCompleteField,
} from "./utils";
import { PHOTO_UPLOAD_ACCEPTABLE_FILE_TYPES } from "dashboard/utils/form";
import { set } from "lodash";

export type FormSubmissionWizardMode = "create" | "edit-questions" | "edit-settings";
type FormField = FrontendModel<FormField_>;
type FormSection = FrontendModel<FormSection_>;

type Props = {
  onExit: () => void;
  onComplete: () => void;
  formItem: Form;
  formSubmission?: FormSubmission;
  mode: FormSubmissionWizardMode;
  parentType?: string;
  parentId?: string;
  readonly?: boolean;
  accountType: "admin" | "team_member";
  teamMember: {
    company: string;
    full_name: string;
    _id: string;
  };

  userId: string;
  roleId?: string;
};

const FormSubmissionWizard: React.FC<Props> = ({
  onExit,
  onComplete,
  mode,
  parentType,
  parentId,
  formItem,
  readonly,
  teamMember,
  userId,
  roleId,
  accountType,
  ...props
}) => {
  /** Form hooks */
  const form = useForm<{ [_id: string]: any }>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
  });

  const { formState } = form;
  useWatch({ control: form.control });

  /* The current form submission (not including the changes made in the wizard) */
  const [formSubmission, setFormSubmission] = useState<FormSubmission | undefined>(props.formSubmission);
  const [startedAt, setStartedAt] = useState<number>(DateTime.now().toSeconds());

  /** Keep the form submission in sync with the props */
  useEffect(() => {
    setFormSubmission(props.formSubmission);
    setStartedAt(DateTime.now().toSeconds());

    if (props.formSubmission) {
      form.reset(props.formSubmission);
    }
  }, [props.formSubmission]);

  /** Clean the form data for saving it in the backend */
  const prepareFormSubmissionParams = (): CreateFormSubmissionParams | UpdateFormSubmissionParams => {
    const formData = form.getValues();
    const answers = Object.keys(formData)
      // Sort into the correct order based on index
      .sort((a, b) => {
        const fieldA = formItem.components.findIndex((f) => f._id === a);
        const fieldB = formItem.components.findIndex((f) => f._id === b);

        return fieldA - fieldB;
      })
      // use string regex to determine key is a valid mongo id
      .filter(ObjectID.isValid)
      .map((key) => ({
        _id: ObjectID().toHexString(),
        form_field_id: key,
        value: cleanCustomFieldValueParams(formData[key]),
      }));

    return {
      ...form.getValues(),
      company_id: teamMember.company,
      form_id: formItem._id,
      role_id: roleId,
      team_member_id: teamMember._id,
      user_id: userId,
      completed_at: DateTime.now().toSeconds(),
      status: "completed",
      answers,

      ...(!formSubmission ? { started_at: startedAt } : {}),
    };
  };

  /** Save the form submission */
  const saveFormSubmission = async () => {
    try {
      const params = prepareFormSubmissionParams();

      const res = formSubmission
        ? await MiterAPI.form_submissions.update(formSubmission._id, params as UpdateFormSubmissionParams)
        : await MiterAPI.form_submissions.create(params as CreateFormSubmissionParams);

      if (res.error) throw new Error(res.error);

      await saveFormsAndUpdateAnswers({
        formSubmissionId: res._id,
        params,
        formItem,
        account: {
          company_id: teamMember.company,
          full_name: teamMember.full_name,
          roleId: roleId,
          userId,
          accountType,
          title: "Miter user",
        },
        deviceType: "desktop",
      });

      Notifier.success("Form submission saved");
      onComplete();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error("Error saving form submission", e);

      // Re-throw the error so the wizard doesn't continue
      throw e;
    }
  };

  /** Handler for the continue button in the wizard */
  const handleNext = async (isLastSection: boolean) => {
    await form.trigger();

    if (Object.keys(formState.errors).length > 0) {
      Notifier.error("Please fix the fields with errors");
      throw new Error("Form is invalid");
    }

    // If this is the last screen, save the form submission
    if (isLastSection) {
      if (readonly) {
        onComplete();
      } else {
        await saveFormSubmission();
      }
    }
  };

  /** Build each of the pages (screens) of the form */
  const buildScreens = () => {
    const groupedFormComponentsObject = buildGroupedFormComponentsObject(formItem);
    const groupedFormComponentsArray: GroupedFormComponentsArray = Object.values(
      groupedFormComponentsObject
    ).filter((gfc) => gfc.fields.length > 0);

    return groupedFormComponentsArray.map((groupedFormComponents, index) => {
      const sectionTitle = groupedFormComponents.section?.name || formItem.name;
      const lastSection = index === groupedFormComponentsArray.length - 1;

      return (
        <WizardScreen
          name={sectionTitle}
          onNext={() => handleNext(lastSection)}
          key={groupedFormComponents.section?._id || "no-section"}
        >
          <div className={styles["content"]}>
            <FormHeader section={groupedFormComponents.section} />
            <FormProvider {...form}>
              <FormFields
                groupedFormComponents={groupedFormComponents}
                answers={formSubmission?.answers || []}
                readonly={readonly}
              />
            </FormProvider>
          </div>
        </WizardScreen>
      );
    });
  };

  return (
    <Wizard onExit={onExit} onComplete={onComplete} defaultCanGoNext={true}>
      {buildScreens()}
    </Wizard>
  );
};

/** Build the header for the form section */
const FormHeader: FC<{ section?: FormSection }> = ({ section }) => {
  if (!section) return null;

  return (
    <div className={styles["subheader"]}>
      <h2 className={styles["subheader-title"]}>{section?.name}</h2>
      <p className={styles["subheader-description"]}>{section?.description}</p>
    </div>
  );
};

/** Build the formblocks for the group of form fields */
export const FormFields: FC<{
  groupedFormComponents: GroupedFormComponentsArray[number];
  answers: FormSubmission["answers"];
  readonly?: boolean;
  overrideRequired?: boolean;
  setAnswers?: (fillableDocument: any) => void;
}> = ({ groupedFormComponents, answers, readonly, overrideRequired, setAnswers }) => {
  const fields = groupedFormComponents.fields;
  const [selectedField, setSelectedField] = useState<FormField>();

  const form = useFormContext();

  const openConfirmResign = (field: FormField) => {
    setSelectedField(field);
  };

  const closeConfirmResign = () => {
    setSelectedField(undefined);
  };

  const erasePreviousSignature = () => {
    if (!setAnswers) return;
    const newAnswers = answers.filter((a) => a.form_field_id !== selectedField?._id);
    setAnswers(newAnswers);
    closeConfirmResign();
  };

  return (
    <div>
      {fields.filter(canCompleteField).map((field: FormField) => {
        const cfv = answers.find((a) => a.form_field_id === field._id);

        const defaultString = renderCustomFieldDefaultString(field, cfv);
        const defaultValue = renderCustomDefaultValue(field, cfv?.value);

        const editable = !readonly;

        const validations = buildValidationRuleSetForFormField(field) || {};

        if (overrideRequired) {
          set(validations, "required", undefined);
          set(validations, "validate", undefined);
        }

        const fieldSpecificProps = buildFieldSpecificPropsForFormField(field);

        const type = getCustomFieldTypeForFormBlock(field);

        if (field.type === "esignature") {
          const esignature = defaultValue as ESignatureItem;
          const isSigned = !!esignature?.signature?.image;

          const questionLabel = (
            <Label
              label={(field?.name || "Untitled Field") + (validations?.required ? "*" : "")}
              style={{
                marginBottom: 0,
                width: "100%",
              }}
              sublabel={field?.description}
            ></Label>
          );

          const displaySignature = isSigned ? (
            <div>
              <DisplaySignature signature={esignature!.signature!.image!} />
              <p style={{ marginTop: 3, opacity: 0.6, fontSize: "0.8rem", marginBottom: 0 }}>
                Signed by {esignature.signer.name} on{" "}
                {DateTime.fromSeconds(esignature.created_at).toFormat("FF")}
              </p>
            </div>
          ) : (
            "No signature provided."
          );

          // If the field is not editable, we should not return a signature Formblock
          if (!editable) {
            return (
              <div style={{ marginBottom: 30 }}>
                {questionLabel}
                {displaySignature}
              </div>
            );
          }

          // If its editable and signed, we should give them the option to clear and re-sign the signature
          // If setAnswers is not provided, then we cannot allow customers to clear out an older signature when editing a submission
          if (editable && isSigned && setAnswers) {
            return (
              <div style={{ marginBottom: 30 }}>
                {questionLabel}
                {displaySignature}
                <Link
                  onClick={() => openConfirmResign(field)}
                  style={{ color: "red", marginTop: 5, fontWeight: 400, fontSize: 15 }}
                >
                  Erase and re-sign this field?
                </Link>
              </div>
            );
          }
        }

        const supportMultiple = field.type === "file" || field.type === "photo";

        return (
          <Formblock
            key={field._id}
            label={(field?.name || "Untitled Field") + (validations?.required ? "*" : "")}
            sublabel={field?.description}
            name={field._id}
            form={form}
            defaultValue={defaultValue as any}
            defaultString={defaultString}
            editing={editable || (field.type === "select" && field.multiple)}
            disabled={!editable}
            className={"modal wizard"}
            placeholder={field?.placeholder}
            rules={validations}
            val={validations}
            onChange={(_e) => {
              form.trigger();
            }}
            dateOnly={true}
            // Allow multiple submissions if field type is file or photo
            multiple={supportMultiple}
            // Add select options if select field
            {...fieldSpecificProps}
            type={type as $TSFixMe}
            acceptedFileTypes={field.type === "photo" ? PHOTO_UPLOAD_ACCEPTABLE_FILE_TYPES : undefined}
          />
        );
      })}
      {selectedField && (
        <ConfirmModal
          title={"Erase previous signature"}
          body={"Are you sure you want to delete this signature? This action cannot be undone."}
          onYes={erasePreviousSignature}
          onNo={closeConfirmResign}
          yesText={"Erase signature"}
        />
      )}
    </div>
  );
};

export default FormSubmissionWizard;
