import { ICellEditorParams } from "ag-grid-community";
import React, { useImperativeHandle, useMemo, useState } from "react";
import Select from "react-select";
import { Option, selectFilterOption } from "ui/form/Input";
import { styles } from "ui/form/styles";

type SelectEditorProps = ICellEditorParams<$TSFixMe, string[] | undefined> & {
  options:
    | Option<string>[]
    | ((params: ICellEditorParams<$TSFixMe, string[] | undefined>) => Option<string>[]);
  isClearable?: boolean;
};

export const AgGridMultiSelectEditor = React.forwardRef(function RawSelectEditor(
  props: SelectEditorProps,
  ref
) {
  const [value, setValue] = useState(props.value);

  const options = useMemo(() => {
    if (typeof props.options === "function") {
      return props.options(props);
    } else {
      return props.options;
    }
  }, [props.options]);

  const defaultOpt = useMemo(() => {
    return options.find((o) => props.value?.some((v) => v === o.value));
  }, [value, options]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  return (
    <Select
      options={options}
      maxMenuHeight={175}
      width="200px"
      styles={styles}
      onChange={(o: $TSFixMe) => setValue(o)}
      autoFocus={true}
      openMenuOnFocus={true}
      defaultValue={defaultOpt}
      isClearable={props.isClearable}
      isMulti={true}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          // If "Enter" is hit, we want react-select to actually select the chosen option (default behavior), and once that happens we want AgGrid to stop editing the cell as well. If we call stopEditing() directly, then react-select won't actually select the chosen option, so let's wait for the next tick to call stopEditing().
          setTimeout(() => props.stopEditing(), 0);
        }
      }}
      filterOption={selectFilterOption}
    />
  );
});
