import SchedulingContext, { FetchScheduleEventsParams } from "dashboard/contexts/scheduling-context";
import { AggregatedJob, AggregatedTeamMember, MiterAPI, SearchAssignmentsResponse } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MiterFilterArray } from "backend/utils";
import SchedulingCalendar from "./SchedulingCalendar";
import { DateTime } from "luxon";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useAssignmentAbilities } from "dashboard/hooks/abilities-hooks/useAssignmentAbilities";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";

type Props = {
  job?: AggregatedJob;
  teamMember?: AggregatedTeamMember;
};

const Scheduling: React.FC = () => {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Scheduling | Miter</title>
      </Helmet>
      <div className="page-content">
        <SchedulingWrapper />
      </div>
    </div>
  );
};

export const SchedulingWrapper: FC<Props> = ({ job, teamMember }) => {
  const activeCompanyId = useActiveCompanyId();
  const assignmentAbilities = useAssignmentAbilities();
  const timeOffRequestAbilities = useTimeOffRequestAbilities();

  const company = activeCompanyId;
  const [_loading, setLoading] = useState(true);
  const [scheduleEvents, setScheduleEvents] = useState<SearchAssignmentsResponse[]>([]);

  const [lastQueriedStart, setLastQueriedStart] = useState(DateTime.now().minus({ days: 30 }));
  const [lastQueriedEnd, setLastQueriedEnd] = useState(DateTime.now().plus({ days: 30 }));

  const buildFilterArray = (params?: FetchScheduleEventsParams): MiterFilterArray => {
    const filter: MiterFilterArray = [{ field: "company", value: company }];

    if (job || params?.job) {
      filter.push({ field: "job", value: params?.job || job });
    }

    if (teamMember || params?.team_member) {
      filter.push({ field: "team_members", comparisonType: "in", value: params?.team_member || teamMember });
    }

    filter.push({
      type: "or",
      value: [
        {
          field: "starts_at",
          type: "number",
          comparisonType: "<+>e",
          value: [
            params?.starts_at?.[0] || lastQueriedStart.toSeconds(),
            params?.starts_at?.[1] || lastQueriedEnd.toSeconds(),
          ],
        },
        {
          field: "ends_at",
          type: "number",
          comparisonType: "<+>e",
          value: [
            params?.ends_at?.[0] || lastQueriedStart.toSeconds(),
            params?.ends_at?.[1] || lastQueriedEnd.toSeconds(),
          ],
        },
      ],
    });

    return filter;
  };

  const fetchScheduleEvents = async (params?: FetchScheduleEventsParams) => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const filter = buildFilterArray(params);
      const response = await MiterAPI.companies.schedule.events(activeCompanyId, filter);
      if (response.error) throw new Error(response.error);

      const finalResponse = response.filter((r) => {
        if (r.type === "time_off_request") {
          // @ts-expect-error fix me
          return timeOffRequestAbilities.can("read", r);
        } else {
          // @ts-expect-error fix me
          const hasTeamMembers = r?.team_members?.length;
          // @ts-expect-error fix me
          return !hasTeamMembers || assignmentAbilities.can("read", r);
        }
      });

      setScheduleEvents(finalResponse);

      const startTimestamp = filter.find((field) => field.field === "starts_at")?.value;
      if (startTimestamp) {
        setLastQueriedStart(DateTime.fromSeconds(startTimestamp));
      }
      const endTimestamp = filter.find((field) => field.field === "ends_at")?.value;
      if (endTimestamp) {
        setLastQueriedEnd(DateTime.fromSeconds(endTimestamp));
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving the events for the schedule. We're looking into it!");
    }
    setLoading(false);
  };

  const isCompanyWide = !teamMember && !job;

  useEffect(() => {
    if (!company && !teamMember && !job) return;
    fetchScheduleEvents();
  }, [company]);

  return (
    <SchedulingContext.Provider
      value={{
        fetchScheduleEvents,
        scheduleEvents,
        setScheduleEvents,
        isCompanyWide,
        lastQueriedStart,
        setLastQueriedStart,
        lastQueriedEnd,
        setLastQueriedEnd,
      }}
    >
      <SchedulingCalendar defaultJob={job} defaultTeamMember={teamMember} />
    </SchedulingContext.Provider>
  );
};

export default Scheduling;
