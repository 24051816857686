import { PolicyRules } from "dashboard/components/policies/PolicyRules";
import {
  useActiveCompany,
  useActiveCompanyId,
  useRefetchActionableItems,
  useRefetchPolicies,
} from "dashboard/hooks/atom-hooks";
import { MiterAPI, Policy, PolicyRule } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import { ConfirmModal, Formblock, Notifier, PageModal } from "ui";
import { PageModalActionLink } from "ui/modal/PageModal";
import { FloppyDisk, Trash } from "phosphor-react";
import { PolicyHeader } from "dashboard/components/policies/PolicyHeader";
import { PolicyType } from "backend/services/approvals/types";
import { getApprovalItemType } from "./PolicyConstants";
import { useFetchUserData } from "dashboard/hooks/useFetchUserData";
import { isEqual } from "lodash";
import { TimesheetPolicySettingsCard } from "./TimesheetPolicySettingsCard";
import { TimesheetPolicyConfig } from "dashboard/miter";
import styles from "./PolicyModal.module.css";
import { GeneralPolicyConfig } from "backend/models/policy";
import { useIsAddedToAutoBreakTimeInTimesheetPolicies } from "dashboard/gating";
import { PolicyCreationParams } from "backend/services/policy-service";

type Props = {
  policy?: Policy | undefined;
  type: PolicyType;
  onHide: () => void;
};

const typeLabels = {
  time_off_request: "time off request",
  timesheet: "timesheet",
  expense: "card transaction",
  default: "reimbursement",
  team_member_change_request: "team member profile change",
};

export const PolicyModal: React.FC<Props> = ({ policy, type, onHide }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const companyId = useActiveCompanyId();
  const company = useActiveCompany();
  const refetchPolicies = useRefetchPolicies();
  const fetchUserData = useFetchUserData();
  const refetchActionableItems = useRefetchActionableItems();
  const isAddedToAutoBreakTimeInTimesheetPolicies = useIsAddedToAutoBreakTimeInTimesheetPolicies();

  /*********************************************************
   *  Important hooks
   **********************************************************/
  const [currentPolicy, setCurrentPolicy] = useState<Policy | undefined>(policy);
  const [policyName, setPolicyName] = useState<string>(policy?.name || "");
  const timesheetPolicyConfig = policy?.config as TimesheetPolicyConfig;
  const startPolicyConfig =
    type === "timesheet"
      ? { ...policy?.config, automatic_break_time: timesheetPolicyConfig?.automatic_break_time || null }
      : policy?.config || {};
  const [policyConfig, setPolicyConfig] = useState<Policy["config"]>(startPolicyConfig);
  const [rules, setRules] = useState<PolicyRule[]>(policy?.rules || []);
  const [loading, setLoading] = useState(false);
  const [loadingDeleting, setLoadingDeleting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);

  const typeLabel = !!typeLabels[type] ? typeLabels[type] : typeLabels["default"];
  const timesheetSettings = company?.settings.timesheets;

  // Name or rules changed
  const isUpdated = useMemo(() => {
    return (
      (policyName != "" || rules.length > 0) &&
      (currentPolicy?.name !== policyName ||
        currentPolicy?.rules != rules ||
        !isEqual(currentPolicy?.config, policyConfig))
    );
  }, [policyName, rules, currentPolicy, policyConfig]);

  /*********************************************************
   *  Handler functions
   **********************************************************/
  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (!policyName || !rules || !companyId) {
        throw new Error(`Cannot save without a policy name and rules.`);
      }

      if (editing) {
        throw new Error(`Cannot save while editing a rule.`);
      }

      const defaultRule = rules.find((r) => !r.condition);
      if (defaultRule?.approval_flow?.length === 0 && !defaultRule.auto_approve) {
        throw new Error(`The default rule must have at least one approval step or be set to auto-approve.`);
      }

      const creationParams: PolicyCreationParams = {
        id: currentPolicy?._id,
        name: policyName,
        config: policyConfig,
        // @ts-expect-error _id field is created as an ObjectId
        rules,
        type,
        companyId,
      };

      const response = await MiterAPI.policies.create_or_update({ ...creationParams });
      if (response.error) throw new Error(response.error);

      await refetchPolicies();
      await fetchUserData();
      await refetchActionableItems();

      Notifier.success(`Policy ${policy ? "updated" : "created"}.`);

      setCurrentPolicy(response);
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoadingDeleting(true);

    try {
      if (!currentPolicy) throw new Error(`A policy must be saved to be deleted.`);

      const response = await MiterAPI.policies.archive(currentPolicy._id);
      if (response.error) throw new Error(response.error);

      await refetchPolicies();
      await fetchUserData();
      await refetchActionableItems();

      Notifier.success(`Policy deleted.`);

      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }

    setLoadingDeleting(false);
  };

  /*********************************************************
   *  Modal config variables
   **********************************************************/
  const modalActions: PageModalActionLink[] = useMemo(() => {
    const actions: PageModalActionLink[] = [];

    if (currentPolicy) {
      actions.push({
        label: "Delete policy",
        action: () => setDeleting(true),
        position: "left",
        icon: <Trash style={{ marginRight: 7 }} />,
        className: "button-1 no-margin",
      });
    } else {
      actions.push({
        label: "Cancel",
        action: onHide,
        position: "left",
        className: "button-1 no-margin",
      });
    }

    if (!currentPolicy || isUpdated) {
      actions.push({
        label: "Save and exit",
        action: handleSubmit,
        position: "right",
        icon: <FloppyDisk style={{ marginRight: 7 }} />,
        className: "button-2 no-margin",
        loading,
      });
    }

    return actions;
  }, [onHide, handleSubmit, loading, isUpdated]);

  const updateTimesheetPolicyConfig = (newFields: Partial<TimesheetPolicyConfig>): void => {
    const newConfig = { ...policyConfig, ...newFields };
    setPolicyConfig(newConfig);
  };

  const isTimesheetPolicyConfig = (
    policyConfig: GeneralPolicyConfig
  ): policyConfig is TimesheetPolicyConfig => {
    return (policyConfig as TimesheetPolicyConfig).automatic_break_time !== undefined && type === "timesheet";
  };

  const renderPolicyConfig = () => {
    // if timesheet policy, render timesheet policy settings card
    const configIsTimesheetPolicyConfig = policyConfig && isTimesheetPolicyConfig(policyConfig);
    return (
      <div className={styles["policy-config"]}>
        <PolicyHeader label="Configuration" style={{ marginTop: -18, marginBottom: 5 }} />
        <Formblock
          text={`Allow users with the ${getApprovalItemType(
            type
          )} approval permission to approve any ${typeLabel} in the system`}
          type="checkbox"
          className="modal"
          defaultValue={policyConfig?.enable_out_of_policy_approvals}
          name="config.enable_out_of_policy_approvals"
          editing={true}
          labelStyle={{ fontSize: "1rem" }}
          textStyle={{ fontSize: "1rem" }}
          onChange={(e) =>
            setPolicyConfig({ ...policyConfig, enable_out_of_policy_approvals: e.target.checked })
          }
        />
        {/* gate auto break time settings in timesheet policies to allan briteway and hillhouse */}
        {configIsTimesheetPolicyConfig && timesheetSettings && isAddedToAutoBreakTimeInTimesheetPolicies && (
          <TimesheetPolicySettingsCard
            automaticBreakTimeSettings={policyConfig?.automatic_break_time || null}
            timesheetSettings={timesheetSettings}
            setTimesheetPolicyConfig={updateTimesheetPolicyConfig}
          />
        )}
      </div>
    );
  };

  return (
    <PageModal
      header={policy ? `Manage ${typeLabel} policy` : `Create ${typeLabel} policy`}
      onClose={onHide}
      footerActions={modalActions}
    >
      <div>
        {deleting && (
          <ConfirmModal
            title="Delete policy?"
            body="Are you sure you want to delete the policy? All unapproved items that are tied to this policy and are in a multi-stage approval flow will lose their approval status."
            onNo={() => setDeleting(false)}
            onYes={handleDelete}
            loading={loadingDeleting}
            yesText={null}
            noText={null}
          />
        )}
        <div className="form-section" style={{ marginBottom: 30 }}>
          <PolicyHeader label="Policy name" />

          <Formblock
            placeholder="Enter a policy name"
            type="text"
            className="modal"
            defaultValue={policyName}
            name="name"
            editing={true}
            onChange={(e) => setPolicyName(e.target.value)}
          />
        </div>
        {renderPolicyConfig()}
        {/* Multiple rules cannot be applied to an item at the same time. You can reorder rules by dragging and dropping them. */}
        <PolicyHeader
          label="Rules"
          subLabel={`If different rules apply to the same ${getApprovalItemType(
            type
          )}, the rule that appears first in this list will be applied. You can reorder the rules by dragging and dropping them.`}
          style={{ marginBottom: 25, width: "600px", marginTop: 40 }}
        />
        <PolicyRules
          policy={policy}
          policyType={type}
          readonly={false}
          onSave={setRules}
          onEditStart={() => setEditing(true)}
          onEditEnd={() => setEditing(false)}
        />
      </div>
    </PageModal>
  );
};
