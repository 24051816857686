import React, { useEffect, FC } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "ui";
import EmptyChat from "../../../assets/empty-chat.svg";
import styles from "../Chat.module.css";
import {
  useActiveCompany,
  usePaginatedRecruitingConversations,
  useRecruitingChatInitialized,
  useSetActiveRecruitingConversation,
} from "dashboard/hooks/atom-hooks";
import { CHAT_DEBOUNCE } from "dashboard/utils/chat";
import { RecruitingConversationList } from "./RecruitingConversationList";
import { RecruitingConversationContainer } from "./RecruitingConversationContainer";
import { RecruitingChatProfile } from "./RecruitingChatProfile";
import { useDebounce } from "use-debounce";

export const RecruitingChatContainer: FC = ({}) => {
  /** Atom hooks */
  const recruitingConversations = usePaginatedRecruitingConversations();
  const setActiveRecruitingConversation = useSetActiveRecruitingConversation();
  const isRecruitingChatInitialized = useRecruitingChatInitialized();
  const activeCompany = useActiveCompany();

  /** General hooks */
  const params = useParams<{ id: string }>();

  /** Helper state */
  const conversationId = params.id;
  const [hasConversations] = useDebounce(recruitingConversations.length > 0, CHAT_DEBOUNCE);
  const noSelectedConversation = !conversationId;
  const hasChat = !!activeCompany?.candidate_chat?.conversations_service_sid;

  useEffect(() => {
    const conversation =
      (recruitingConversations || []).find((c) => c._id === conversationId) || recruitingConversations[0];
    if (!conversation) return;
    setActiveRecruitingConversation(conversation);
  }, [conversationId, recruitingConversations.length, noSelectedConversation]);

  const renderEmptyState = () => {
    return (
      <div className={styles["conversation-container-empty-state"]}>
        {isRecruitingChatInitialized && (
          <>
            <img src={EmptyChat} alt="No chats created" />
            <p>Click the plus button and send your first message!</p>
          </>
        )}
        {!isRecruitingChatInitialized && hasChat && <Loader />}
      </div>
    );
  };

  return (
    <div className={styles["chat-container"]}>
      <RecruitingConversationList />
      {hasConversations && <RecruitingConversationContainer />}
      {hasConversations && <RecruitingChatProfile />}
      {!hasConversations && renderEmptyState()}
    </div>
  );
};
