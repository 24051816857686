import React, { useEffect, useMemo, useState } from "react";
import { AggregatedChangeRequest, MiterAPI } from "dashboard/miter";
import { Formblock, ModalSidebar, ActionModal, Notifier, Badge } from "ui";
import person from "dashboard/assets/user.png";
import { handleTmClick } from "dashboard/utils/timesheetUtils";
import styles from "./viewChangeRequestModal.module.css";
import FieldsChangedTable from "./FieldsChangedTable";
import { ChangeRequestStatus } from "backend/models/change-request";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { ApprovalItemPolicyData } from "../approvals/ApprovalItemPolicyData";
import { ApprovalTimeline } from "../approvals/ApprovalTimeline";
import { getUserFriendlyTmChangeCategory } from "dashboard/pages/team-members/TeamUtils";
import { DateTime } from "luxon";
import Banner from "../shared/Banner";

type ViewChangeRequestModalProps = {
  changeRequestId: string;
  onHide: () => void;
  onSubmit: () => void;
  canUpdate: boolean;
};

const ViewChangeRequestModal: React.FC<ViewChangeRequestModalProps> = ({
  changeRequestId,
  onHide,
  onSubmit,
  canUpdate,
}) => {
  // useState hooks
  const [activeView, setActiveView] = useState("overview");
  const [changeRequest, setChangeRequest] = useState<AggregatedChangeRequest>();
  const [refreshModal, setRefreshModal] = useState(0);

  // useEffect hooks
  const getChangeRequestData = async () => {
    const response = await MiterAPI.change_requests.retrieve(changeRequestId);
    if (response.error) {
      Notifier.error("Failed to fetch change request.");
    } else {
      setChangeRequest(response);
    }
  };
  useEffect(() => {
    getChangeRequestData();
  }, [changeRequestId, refreshModal]);

  // handlers
  const handleToggle = (path) => {
    setActiveView(path);
  };

  const handleStatusChange = async (status: ChangeRequestStatus) => {
    try {
      const response = await MiterAPI.change_requests.update(changeRequestId, { status: status });
      if (response.error) {
        throw new Error(response.error);
      } else if (status === "approved") {
        Notifier.success("Change request approved");
      } else if (status === "denied") {
        Notifier.success("Change request denied");
      } else {
        Notifier.success("Change request moved to unapproved");
      }
      setRefreshModal(refreshModal + 1);
      onSubmit();
    } catch (e: $TSFixMe) {
      console.error(e);
    }
  };

  // constants and things
  const actions: ActionLink[] = useMemo(
    () => [
      {
        label: "Approve",
        action: () => handleStatusChange("approved"),
        important: true,
        shouldShow: () => changeRequest?.status === "unapproved",
      },
      {
        label: "Deny",
        action: () => handleStatusChange("denied"),
        secondary: true,
        shouldShow: () => changeRequest?.status === "unapproved",
      },
      {
        label: "Move to unapproved",
        style: { minWidth: "max-content" },
        action: () => handleStatusChange("unapproved"),
        secondary: true,
        shouldShow: () => changeRequest?.status === "denied" || changeRequest?.status === "scheduled",
      },
    ],
    [changeRequest]
  );

  const menuItems = useMemo(
    () => [
      {
        label: "Overview",
        path: "overview",
      },
      {
        label: "Approval history",
        path: "history",
      },
    ],
    []
  );

  const colorForStatus = (status: ChangeRequestStatus) => {
    if (status === "unapproved") return "yellow";
    if (status === "approved") return "green";
    if (status === "denied") return "red";
    if (status === "scheduled") return "light-blue";
    return "light-gray";
  };

  const headerText = changeRequest && (
    <div className="flex">
      {`Change request for ${changeRequest.parent.full_name}`}
      <Badge text={changeRequest.status} color={colorForStatus(changeRequest.status)} />
    </div>
  );

  const renderScheduledChangeBanner = () => {
    const effectiveDate = changeRequest?.effective_date;
    if (effectiveDate) {
      const showScheduledBanner = changeRequest.status !== "denied" && changeRequest.status !== "approved";
      if (showScheduledBanner) {
        const formattedDate = DateTime.fromISO(effectiveDate).toFormat("EEEE, MMMM dd");
        let scheduledBannerCopy = "This change is scheduled to take effect on " + formattedDate + ".";
        if (DateTime.now() >= DateTime.fromISO(effectiveDate) && changeRequest.status !== "scheduled") {
          scheduledBannerCopy = "If approved, this change will take effect immediately.";
        } else if (changeRequest?.status === "unapproved") {
          scheduledBannerCopy = "If approved, this change would take effect on " + formattedDate + ".";
        }
        return <Banner type={"warning"} content={scheduledBannerCopy} />;
      }
    }
    return null;
  };

  return (
    <ActionModal
      headerText={headerText}
      wrapperClassName={styles["modal-wrapper"]}
      bodyClassName={styles["modal-body"]}
      onHide={onHide}
      hideFooter={true}
      actions={canUpdate ? actions : undefined}
      actionsType={"button"}
      loading={false}
    >
      <ModalSidebar config={menuItems} active={activeView} toggle={handleToggle} />
      <div className="flex-column">
        {renderScheduledChangeBanner()}
        {changeRequest ? (
          <div className={styles["active-view"]}>
            {activeView === "overview" && (
              <div>
                <h3>Overview</h3>
                <Formblock
                  name="parent"
                  label="Team member"
                  type="text"
                  defaultValue={changeRequest.parent._id}
                  editing={false}
                >
                  <div className="tm-link" onClick={() => handleTmClick(changeRequest.parent._id)}>
                    <img className="tm-link-img" src={person} />
                    <span>{changeRequest.parent.full_name}</span>
                  </div>
                </Formblock>
                <Formblock
                  name="author"
                  label="Submitted by"
                  type="text"
                  defaultValue={changeRequest.author_id}
                  editing={false}
                >
                  <span>{changeRequest.author.first_name + " " + changeRequest.author.last_name}</span>
                </Formblock>
                {changeRequest.parent_type === "team_member" && (
                  <Formblock
                    name="category"
                    label="Category"
                    type="text"
                    defaultValue={getUserFriendlyTmChangeCategory(changeRequest.child_fields?.category)}
                    editing={false}
                  />
                )}
                {changeRequest.fields_changed && (
                  <Formblock
                    style={{ marginRight: "20px" }}
                    name="fields_changed"
                    label="Fields changed"
                    type="number"
                    editing={false}
                  >
                    <FieldsChangedTable
                      parentId={changeRequest.parent_id}
                      fieldsChanged={changeRequest.fields_changed}
                    />
                  </Formblock>
                )}
                {changeRequest.effective_date && (
                  <Formblock
                    name="effective_date"
                    label="Scheduled date"
                    type="datetime"
                    defaultValue={DateTime.fromISO(changeRequest.effective_date)}
                    editing={false}
                    dateOnly
                  />
                )}
                <Formblock
                  name="notes"
                  label="Notes"
                  type="text"
                  defaultValue={changeRequest.notes}
                  editing={false}
                />
              </div>
            )}
            {activeView === "history" &&
              (changeRequest.approval_history?.length || changeRequest.approval_stage ? (
                <div>
                  <h3>Approval history</h3>
                  <ApprovalTimeline item={changeRequest} />
                  <ApprovalItemPolicyData item={changeRequest} />
                </div>
              ) : (
                <div>No approval history</div>
              ))}
          </div>
        ) : (
          <div>Could not retrieve change request data</div>
        )}
      </div>
    </ActionModal>
  );
};

export default ViewChangeRequestModal;
