import React, { useMemo } from "react";
import { EnrichedLedgerEntry, MiterAPI } from "dashboard/miter";
import { Notifier } from "ui";
import { downloadCsvFromBlob } from ".";

export const useSageIntacctCsv = (
  icId: string | undefined
): {
  loading: boolean;
  build: (entryIds: string[]) => Promise<void>;
  buildFromDraft: (le: EnrichedLedgerEntry) => Promise<void>;
} => {
  const [loading, setLoading] = React.useState(false);

  const build = async (entryIds: string[]) => {
    if (!icId) return;
    setLoading(true);
    try {
      const response = await MiterAPI.ledger_entries.get_intacct_csv({
        ledgerEntryIds: entryIds,
        icId: icId,
      });
      if (response.error) throw new Error(response.error);
      downloadCsvFromBlob(response.csv, `Miter_Sage_Intacct_GL_Entry`);
    } catch (e: $TSFixMe) {
      Notifier.error(`Error building Sage Intacct csv file: ${e.message}`);
    }
    setLoading(false);
  };

  const buildFromDraft = async (le: EnrichedLedgerEntry) => {
    if (!icId) return;
    setLoading(true);
    try {
      const response = await MiterAPI.ledger_entries.get_intacct_csv({
        ledgerEntryIds: [le._id],
        le,
        icId: icId,
      });
      if (response.error) throw new Error(response.error);
      downloadCsvFromBlob(response.csv, `Miter_Sage_Intacct_GL_Entry`);
    } catch (e: $TSFixMe) {
      Notifier.error(`Error building Sage Intacct csv file: ${e.message}`);
    }
    setLoading(false);
  };

  return useMemo(() => ({ loading, build, buildFromDraft }), [icId, loading]);
};
