import { FileUploadResponse, FormAnswer, FormField } from "miter-utils";

import ObjectID from "bson-objectid";
import { Certification, CertificationType } from "dashboard/types/certification-types";

/** This function takes the custom fields and prepends values so that when used in a react-hook-form setting,
 * the data comes back to us as nested objects.
 */
export const prepareCertificationFields = (certificationType: CertificationType): FormField[] => {
  const customFields = certificationType.custom_fields || [];
  const fileUploads = certificationType.file_uploads || [];
  return [
    ...customFields.map((field) => ({
      ...field,
      _id: `custom_fields.${field._id}`,
    })),
    ...fileUploads.map((field) => ({
      ...field,
      _id: `file_uploads.${field._id}`,
    })),
    ...(certificationType.expires
      ? [
          {
            _id: "expires_at",
            type: "date",
            name: "Certification expiry date",
            label: "Expiration Date",
            validations: {
              required: true,
            },
          },
        ]
      : []),
  ] as FormField[];
};

export const prepareCertificationFormAnswers = (certification?: Certification): FormAnswer[] => {
  if (!certification) return [];
  return [
    ...certification.custom_field_values.map((field) => ({
      ...field,
      form_field_id: `custom_fields.${field.form_field_id}`,
    })),
    ...certification.file_upload_values.map((field) => ({
      ...field,
      form_field_id: `file_uploads.${field.form_field_id}`,
    })),
    ...(certification.expires_at
      ? [
          {
            form_field_id: "expires_at",
            _id: new ObjectID().toHexString(),
            value: certification.expires_at,
          },
        ]
      : []),
  ];
};

export const generateSyncedCachedFileUploadIds = (
  fileDiffs: {
    formFieldId: string;
    value: FileUploadResponse;
  }[],
  certification: Certification
): FormAnswer[] => {
  const fileUploads = certification.file_upload_values;
  const syncedIds = fileDiffs.map((diff) => {
    const existing = fileUploads.find((f) => f.form_field_id === diff.formFieldId);
    if (existing) {
      const existinFileIds = existing.value as string[];
      const newArrayOfFiles = existinFileIds
        .concat(diff.value.created_ids)
        .filter((field) => !diff.value.deleted_ids.includes(field));
      return {
        form_field_id: diff.formFieldId,
        value: newArrayOfFiles,
      };
    }
    return {
      form_field_id: diff.formFieldId,
      value: diff.value.created_ids,
    };
  });

  return syncedIds.map((syncedId) => ({
    _id: new ObjectID().toHexString(),
    ...syncedId,
  }));
};
