import { CompanySettings } from "backend/models/company";
import { TeamMemberGroup } from "backend/types";
import {
  TeamMemberGroupSelectValue,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import AppContext from "dashboard/contexts/app-context";
import { useActiveCompany, useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { isMiterRep, notNullish } from "miter-utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Formblock, Notifier } from "ui";
import { Option } from "ui/form/Input";
import { SettingsCard } from "ui/settings/SettingsCard";
import { useDebouncedCallback } from "use-debounce";
import styles from "./TeamMemberOnboardingSettings.module.css";

const personalInfoFields = [
  { label: "SSN", value: "ssn" },
  { label: "Profile picture", value: "profile_picture" },
  { label: "Date of birth", value: "dob" },
  { label: "Address", value: "address" },
  { label: "Language", value: "language" },
  { label: "Emergency contacts", value: "emergency_contacts" },
];

const OnboardingDataSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const settings = useMemo(() => activeCompany?.settings.team.onboarding, [activeCompany]);

  const [onboardingSettings, setOnboardingSettings] = useState<CompanySettings["team"]["onboarding"]>();

  const [selectedTmGroupOptions, setSelectedTmGroupOptions] = useState<Option<TeamMemberGroupSelectValue>[]>(
    []
  );

  const allTeamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: !isMiterRep(activeUser),
  });

  useEffect(() => {
    setOnboardingSettings(settings);
  }, [settings]);

  useEffect(() => {
    const teamMemberOptions = allTeamMemberGroupOptions.flatMap((group) => group.options);
    const defaultAssignee = (onboardingSettings?.i9?.default_assignee || [])
      .map((group) => {
        const option = teamMemberOptions.find(
          (tm) => tm.value?.type === group.type && tm.value?.value === group.value
        );
        return option;
      })
      .filter(notNullish);
    setSelectedTmGroupOptions(defaultAssignee);
  }, [onboardingSettings?.i9?.default_assignee]);

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: !isMiterRep(activeUser),
    selectedGroupOptions: selectedTmGroupOptions,
  });

  const updateOnboardingDataSettings = async () => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        "settings.team.onboarding": onboardingSettings,
      });
      if (response.error) throw new Error(response.error);
      fetchUserData();
      Notifier.success("Settings updated successfully.");
    } catch (e) {
      Notifier.error("There was an error updating team settings. Our engineers are looking into it!");
    }
  };

  const updateOnboardingDataSettingsDebounced = useDebouncedCallback(() => {
    updateOnboardingDataSettings();
  }, 1250);

  /**
   * Updates the onboarding settings state and triggers a debounced API update.
   */
  const handleOnboardingDataSettingsChange = (
    newSettings: Partial<CompanySettings["team"]["onboarding"]>
  ) => {
    setOnboardingSettings((prev) => {
      if (!prev) return prev;
      return { ...prev, ...newSettings };
    });
    updateOnboardingDataSettingsDebounced();
  };

  return (
    <div>
      <SettingsCard title="Personal information and EEO data">
        <div className="flex">
          <Formblock
            checked={onboardingSettings?.personal_info?.enabled ?? false}
            defaultValue={onboardingSettings?.personal_info?.enabled}
            editing={true}
            formblockClassName={styles.checkboxFormblock}
            name="personal_info.enabled"
            onChange={(e) =>
              handleOnboardingDataSettingsChange({
                personal_info: { enabled: e.target.checked },
              })
            }
            text="Collect personal information during onboarding"
            type="checkbox"
          />
        </div>
        {onboardingSettings?.personal_info?.enabled && (
          <div className={styles.nestedFields}>
            {personalInfoFields.map((field) => (
              <div className="flex" key={field.value}>
                <Formblock
                  checked={onboardingSettings?.personal_info?.fields?.[field.value] ?? false}
                  editing={true}
                  formblockClassName={styles.checkboxFormblock}
                  name={`personal_info.fields.${field}`}
                  onChange={(e) => {
                    const newFields = {
                      ...onboardingSettings?.personal_info?.fields,
                      [field.value]: e.target.checked,
                    };
                    handleOnboardingDataSettingsChange({
                      personal_info: {
                        enabled: true,
                        fields: {
                          ...newFields,
                        },
                      },
                    });
                  }}
                  text={field.label}
                  type="checkbox"
                />
              </div>
            ))}
          </div>
        )}
        <div>
          {onboardingSettings?.personal_info?.enabled && <div className="vertical-spacer-small" />}
          <div className="flex">
            <Formblock
              checked={onboardingSettings?.eeo?.enabled ?? false}
              editing={true}
              formblockClassName={styles.checkboxFormblock}
              name="eeo.enabled"
              onChange={(e) => {
                handleOnboardingDataSettingsChange({
                  eeo: { enabled: e.target.checked },
                });
              }}
              text="Collect EEO data during onboarding"
              type="checkbox"
            />
          </div>
        </div>
      </SettingsCard>
      <SettingsCard title="I-9 information">
        <div>
          <div className="flex">
            <Formblock
              checked={onboardingSettings?.i9?.enabled ?? false}
              editing={true}
              formblockClassName={styles.checkboxFormblock}
              name="i9.enabled"
              onChange={(e) => {
                handleOnboardingDataSettingsChange({
                  i9: { enabled: e.target.checked, default_assignee: [] },
                });
              }}
              text="Collect I-9 information during onboarding"
              type="checkbox"
            />
          </div>
          {onboardingSettings?.i9?.enabled && (
            <div className={styles.nestedFieldsWithMargin}>
              <Formblock
                type="multiselect"
                name="i9.default_assignee"
                label="Default assignee"
                labelInfo="Select the default assignee for I-9 verification."
                editing={true}
                className="modal"
                placeholder="Select assignee groups"
                options={teamMemberGroupOptions}
                value={selectedTmGroupOptions}
                onChange={(selectedOptions: Option<TeamMemberGroupSelectValue>[]) => {
                  const selectedGroups: TeamMemberGroup[] = selectedOptions?.map((option) => option.value);
                  setSelectedTmGroupOptions(selectedOptions);
                  handleOnboardingDataSettingsChange({
                    i9: {
                      enabled: onboardingSettings?.i9?.enabled ?? false,
                      default_assignee: selectedGroups,
                    },
                  });
                }}
                height="unset"
              />
            </div>
          )}
        </div>
      </SettingsCard>
      <SettingsCard title="Payroll and banking">
        <div>
          <div className="flex">
            <Formblock
              checked={onboardingSettings?.enroll_in_payroll?.enabled ?? false}
              editing={true}
              formblockClassName={styles.checkboxFormblock}
              name="enroll_in_payroll"
              onChange={(e) => {
                handleOnboardingDataSettingsChange({
                  enroll_in_payroll: { enabled: e.target.checked },
                });
              }}
              text="Automatically enroll new team members in payroll"
              type="checkbox"
            />
          </div>
          <div className="flex">
            <Formblock
              checked={onboardingSettings?.withholdings?.enabled ?? false}
              editing={true}
              formblockClassName={styles.checkboxFormblock}
              name="withholdings.enabled"
              onChange={(e) => {
                handleOnboardingDataSettingsChange({
                  withholdings: { enabled: e.target.checked },
                });
              }}
              text="Allow employees to submit their withholdings during onboarding"
              type="checkbox"
            />
          </div>
          <div className="flex">
            <Formblock
              checked={onboardingSettings?.payment_method?.enabled ?? false}
              editing={true}
              formblockClassName={styles.checkboxFormblock}
              name="payment_method.enabled"
              onChange={(e) => {
                handleOnboardingDataSettingsChange({
                  payment_method: { enabled: e.target.checked },
                });
              }}
              text="Allow employees to select their payment method (direct deposit or check) during onboarding"
              type="checkbox"
            />
          </div>
          <div className="flex">
            <Formblock
              checked={onboardingSettings?.bank_accounts?.enabled ?? false}
              editing={true}
              formblockClassName={styles.checkboxFormblock}
              name="bank_accounts.enabled"
              onChange={(e) => {
                handleOnboardingDataSettingsChange({
                  bank_accounts: { enabled: e.target.checked },
                });
              }}
              text="Allow employees to add their bank account information during onboarding"
              type="checkbox"
            />
          </div>
        </div>
      </SettingsCard>
    </div>
  );
};

export default OnboardingDataSettings;
