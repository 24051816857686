import { useEffect, useState, FC, useContext, useMemo } from "react";

import { Formblock, TableV2 } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

import { useActiveCompanyId, useLookupTeam, useUser } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { ColumnConfig } from "ui/table-v2/Table";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

import { reportList } from "../reportList";
import { DateTime } from "luxon";
import AppContext from "dashboard/contexts/app-context";
import React from "react";
import { useForm } from "react-hook-form";
import { EntryForHealthCuesReport } from "backend/utils/reports/healthCuesReport";

export const HealthCuesReport: FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();

  const navigate = useNavigate();

  const lookupTeam = useLookupTeam();
  const teamAbilities = useTeamAbilities();
  const user = useUser();
  const { can } = useMiterAbilities();
  const form = useForm();

  const { payroll } = useContext(PayrollContext);
  const { setReverifyUser } = React.useContext(AppContext);

  // State
  const [data, setData] = useState<EntryForHealthCuesReport[]>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [showRawSSNs, setShowRawSSNs] = useState(false);
  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const isReverified = (user?.reverified_at || 0) > DateTime.now().minus({ days: 1 }).toSeconds();

  useEffect(() => {
    if (showReverificationModal) {
      setReverifyUser(showReverificationModal);
    }
  }, [showReverificationModal]);

  useEffect(() => {
    if (showRawSSNs && !isReverified) {
      setShowReverificationModal(true);
      return;
    }

    getData();
  }, [showRawSSNs, isReverified, payroll]);

  const columns: ColumnConfig<EntryForHealthCuesReport>[] = useMemo(
    () => [
      {
        field: "groupName",
        headerName: "Group Name",
        dataType: "string",
      },
      {
        field: "groupLocation",
        headerName: "Group Location",
        dataType: "string",
      },
      {
        field: "firstName",
        headerName: "First Name",
        dataType: "string",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        dataType: "string",
      },
      {
        field: "tmFriendlyId",
        headerName: "Employee Id",
        dataType: "string",
      },
      {
        field: "grossWagesPerPayPeriod",
        headerName: "Gross Wages Per Pay Period",
        dataType: "number",
      },
      {
        field: "employeeSSN",
        headerName: "SSN",
        dataType: "number",
      },
      {
        field: "dob",
        headerName: "Birth Date",
        dataType: "string",
        dateType: "iso",
      },
      {
        field: "paySchedule",
        headerName: "Pay Schedule",
        dataType: "string",
      },
      {
        field: "state",
        headerName: "State",
        dataType: "string",
      },
      {
        field: "ssExempt",
        headerName: "Exempt From  Social Security",
        dataType: "string",
      },
      {
        field: "medicareExempt",
        headerName: "Exempt from Medicare",
        dataType: "string",
      },
      {
        field: "fedMaritalStatus",
        headerName: "Federal Marital Status",
        dataType: "string",
      },
      {
        field: "w4FedUsed2020",
        headerName: "Federal 2020 W4 used",
        dataType: "string",
      },
      {
        field: "w4FedAllowancesClaimed2019",
        headerName: "Federal Allowances Claimed (2019 W4 or Earlier)",
        dataType: "number",
      },
      {
        field: "w4FedDepAmount2020",
        headerName: "2020 W4 Federal Dependents Amount",
        dataType: "number",
      },
      {
        field: "w4Box2CTwoJobs2020",
        headerName: "2020 W4 Box 2c: Two Jobs",
        dataType: "string",
      },
      {
        field: "stateMaritalFilingStatus",
        headerName: "State Marital Filing Status",
        dataType: "string",
      },
      {
        field: "stateExemptionsClaimed",
        headerName: "State Exemptions Claimed",
        dataType: "number",
      },
      {
        field: "stateDepExemptionsClaimed",
        headerName: "State Dependent Exemptions Claimed (AL,GA,LA Only)",
        dataType: "string",
      },
      {
        field: "employeeContributionMM",
        headerName: "Employee Contribution Major Medical",
        dataType: "number",
      },
      {
        field: "employeeContributionDental",
        headerName: "Employee Contribution Dental",
        dataType: "number",
      },
      {
        field: "employeeContributionVision",
        headerName: "Employee Contribution Vision",
        dataType: "number",
      },
      {
        field: "addPreTaxDedPP",
        headerName: "Additional Pre-tax Deductions Total PP",
        dataType: "number",
      },
      {
        field: "postTaxDedPP",
        headerName: "Post-tax Deductions Total PP",
        dataType: "number",
      },
      {
        field: "tradContribution401k",
        headerName: "401K Contribution",
        dataType: "number",
      },
      {
        field: "rothContribution401k",
        headerName: "Roth Contribution",
        dataType: "number",
      },
      {
        field: "addTaxWithholding",
        headerName: "Additional Tax Withholding",
        dataType: "number",
      },
      {
        field: "retirementDeferral",
        headerName: "Retirement Deferral",
        dataType: "number",
      },
      {
        field: "withholdingRate",
        headerName: "Withholding Rate %(AZ Only)",
        dataType: "number",
      },
      {
        field: "exemptionAmountClaimed",
        headerName: "Exemption Amount Claimed (MS Only)",
        dataType: "number",
      },
      {
        field: "spouseWorks",
        headerName: "Spouse Works",
        dataType: "string",
      },
      {
        field: "gender",
        headerName: "Gender",
        dataType: "string",
      },
      {
        field: "phone",
        headerName: "Phone",
        dataType: "number",
      },
      {
        field: "email",
        headerName: "Email",
        dataType: "string",
      },
      {
        field: "addressLine1",
        headerName: "Address Line 1",
        dataType: "string",
      },
      {
        field: "addressLine2",
        headerName: "Address Line 2",
        dataType: "string",
      },
      {
        field: "city",
        headerName: "City",
        dataType: "string",
      },
      {
        field: "zip",
        headerName: "Zip Code",
        dataType: "string",
      },
      {
        field: "dateOfHire",
        headerName: "Date of Hire",
      },
    ],
    [payroll]
  );

  const getData = async () => {
    if (!payroll) return;
    setFetchingData(true);
    try {
      const cleanedData = {
        payrollId: payroll._id.toString(),
        companyId: activeCompanyId!,
        payroll,
        showRawSSNs,
      };
      const payload = {
        type: "health_cues",
        params: cleanedData,
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const filteredData = response.filter((entry: EntryForHealthCuesReport) => {
        return teamAbilities.can("read_sensitive", lookupTeam(entry.tmId));
      });

      setData(filteredData);
    } catch (e: $TSFixMe) {
      console.log("Error fetching HealthCues report", e);
      Notifier.error(e.message);
    }
    setFetchingData(false);
  };
  const reportObject = reportList.find((report) => report.slug === "health_cues");

  const fileName = "Miter HealthCues report";

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      {!payroll && (
        <Helmet>
          <title>HealthCues Report | Miter</title>
        </Helmet>
      )}
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <h1 style={{ marginTop: 0 }}>HealthCues report</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      <div className="vertical-spacer-small"></div>

      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 400 }}>
        {can("team:read_sensitive") && (
          <>
            <Formblock
              label="Include raw SSNs"
              labelInfo="Show full SSNs on the report."
              control={form.control}
              type="checkbox"
              onChange={(e) => setShowRawSSNs(e.target.checked)}
              name="show_full_ssns"
              errors={form.errors}
              editing={true}
              style={{ alignItems: "center" }}
              labelStyle={{ width: 600, flexDirection: "unset" }}
            />
          </>
        )}
      </div>
      <TableV2
        id="health_cues"
        resource="rows"
        data={data}
        paginationPageSize={50}
        columns={columns}
        exportFileName={fileName}
        isLoading={fetchingData}
        showExpandAll={true}
      />
    </div>
  );
};
