import { Activity } from "dashboard/miter";

export const isTimesheetScoped = (activity: Activity): boolean => {
  return activity.scopes.includes("timesheets");
};

export const isExpenseScoped = (activity: Activity): boolean => {
  return activity.scopes.includes("expenses");
};

export const isReimbursementScoped = (activity: Activity): boolean => {
  return activity.scopes.includes("reimbursements");
};
