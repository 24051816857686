import React, { useEffect, useState } from "react";
import DataBox from "dashboard/components/dataBox/DataBox";
import {
  useTimesheetPolicyOptions,
  useExpensePolicyOptions,
  useReimbursementPolicyOptions,
  useLookupPolicy,
  useRefetchActionableItems,
} from "dashboard/hooks/atom-hooks";
import { ActionModal, Formblock } from "ui";
import { AggregatedJob, Job } from "dashboard/miter";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import { Link } from "react-router-dom";
import { PolicyType } from "backend/services/approvals/types";
import { PolicyModal } from "dashboard/components/policies/PolicyModal";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";

type Props = {
  data: AggregatedJob;
  updateJob: (update: Partial<Job>) => Promise<void>;
  updatingJob: boolean;
};

type JobPoliciesForm = {
  timesheet_policy_id?: Option<string>;
  expense_policy_id?: Option<string>;
  reimbursement_policy_id?: Option<string>;
};

export const JobPolicies: React.FC<Props> = ({ data, updatingJob, updateJob }) => {
  const { cannot } = useMiterAbilities();

  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const expensePolicyOptions = useExpensePolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();
  const lookupPolicy = useLookupPolicy();
  const refetchActionableItems = useRefetchActionableItems();
  const jobAbilities = useJobAbilities();

  const [creatingPolicy, setCreatingPolicy] = useState<PolicyType>();
  const [editing, setEditing] = useState(false);

  const buildDefaults = () => {
    return {
      timesheet_policy_id: timesheetPolicyOptions.find((o) => o.value === data.timesheet_policy_id),
      expense_policy_id: expensePolicyOptions.find((o) => o.value === data.expense_policy_id),
      reimbursement_policy_id: reimbursementPolicyOptions.find(
        (o) => o.value === data.reimbursement_policy_id
      ),
    };
  };

  const form = useForm<JobPoliciesForm>({ defaultValues: buildDefaults() });

  useEffect(() => {
    form.reset(buildDefaults());
  }, [data]);

  const handleSave = async (data: JobPoliciesForm) => {
    await updateJob({
      timesheet_policy_id: data.timesheet_policy_id?.value || null,
      expense_policy_id: data.expense_policy_id?.value || null,
      reimbursement_policy_id: data.reimbursement_policy_id?.value || null,
    });

    refetchActionableItems();
    setEditing(false);
  };

  const renderPolicyModal = () => {
    if (!editing) return;

    return (
      <ActionModal
        headerText={"Edit job policies"}
        onHide={() => setEditing(false)}
        showSubmit={jobAbilities.can("update", data)}
        submitText={"Save"}
        onSubmit={form.handleSubmit(handleSave)}
        onCancel={() => setEditing(false)}
        showCancel={true}
        loading={updatingJob}
      >
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Formblock
            type="select"
            name="timesheet_policy_id"
            label="Timesheet policy"
            placeholder="Select a timesheet policy"
            options={timesheetPolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("jobs:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("timesheet")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="expense_policy_id"
            label="Card transaction policy"
            placeholder="Select a card transaction policy"
            options={expensePolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("jobs:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("expense")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="reimbursement_policy_id"
            label="Reimbursement policy"
            placeholder="Select a reimbursement policy"
            options={reimbursementPolicyOptions}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("jobs:update")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("expense_reimbursement")}
            isClearable={true}
          />
        </div>
      </ActionModal>
    );
  };

  return (
    <>
      <DataBox
        title="Policies"
        className="flex-col no-margin"
        onEdit={jobAbilities.can("update", data) ? () => setEditing(true) : undefined}
      >
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Timesheet policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={"/timesheet-settings"}>{lookupPolicy(data.timesheet_policy_id)?.name}</Link>
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Card transaction policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={`/expenses/settings/policies/${data.expense_policy_id}`}>
              {lookupPolicy(data.expense_policy_id)?.name}
            </Link>
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Reimbursement policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={`/expenses/settings/policies/${data.reimbursement_policy_id}`}>
              {lookupPolicy(data.reimbursement_policy_id)?.name}
            </Link>
          </span>
        </div>
      </DataBox>

      {renderPolicyModal()}
      {creatingPolicy && <PolicyModal type={creatingPolicy} onHide={() => setCreatingPolicy(undefined)} />}
    </>
  );
};
