import { useCallback } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, Conversation } from "dashboard/miter";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * CHAT
 */
type ChatParams = Conversation | Conversation[] | null;

type ChatAction = "send" | "read";
type ChatAbilities = {
  can: (action: ChatAction, items: ChatParams) => boolean;
  cannot: (action: ChatAction, items: ChatParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: ChatAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useChatAbilities = (): ChatAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: ChatAction, items?: ChatParams) => {
      if (!items) return false;

      const chats = Array.isArray(items) ? items : [items];

      return chats.every((chat) => {
        const teamMemberId = getTeamMemberId(chat);

        switch (action) {
          case "send":
            return can_("chat:send", { teamMember: teamMemberId });
          case "read":
            return can_("chat:read", { teamMember: teamMemberId });
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: ChatAction, items: ChatParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "chat",
    teamMemberField: { fieldName: "employee._id", fieldType: "_id" },
    appModule: "company",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<ChatAction>("chat", true);

  return { can, cannot, filter, teamPredicate };
};

const getTeamMemberId = (item: Conversation): string | undefined => {
  return item.team_member;
};
