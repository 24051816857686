import { MiterAPI } from "dashboard/miter";
import React, { FC, useEffect, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import styles from "./CertificationTypes.module.css";
import * as vals from "dashboard/utils/validators";
import { Formblock, Notifier, WizardScreen } from "ui";
import {
  useActiveCompanyId,
  useLookupTeam,
  usePermissionGroupOptions,
  useRefetchCertificationTypes,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { CertificationType } from "dashboard/types/certification-types";
import {
  TeamMemberGroupSelectValue,
  useTeamMemberGroupOptions,
} from "../team-members/useTeamMemberGroupOptions";
import { isMiterRep, notNullish } from "miter-utils";
import { isEmpty } from "lodash";
import useWizard from "ui/modal/useWizard";
import { Option } from "ui/form/Input";
type Props = {
  name: string;
  certificationType?: CertificationType;
  setCertificationType: (certificationType: CertificationType) => void;
};

const notificationWindows = [
  { label: "3 weeks before", value: "3_weeks" },
  { label: "2 weeks before", value: "2_weeks" },
  { label: "1 week before", value: "1_week" },
  { label: "Day of", value: "day_of" },
];

type CertificationTypeForm = Pick<
  CertificationType,
  "title" | "description" | "automatic_request_on_expiration"
> & {
  expires: string;
  team_member_groups: Option<TeamMemberGroupSelectValue>[];
  notify_before: Option<string>[];
};

export const CertificationTypeScreen: FC<Props> = ({ certificationType, setCertificationType, name }) => {
  const activeUser = useUser();
  const permissionGroupOptions = usePermissionGroupOptions();
  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    // @ts-expect-error fix me
    selectedGroupOptions: certificationType?.expiration_notification?.team_member_groups || [],
    hideMitosaurs: !isMiterRep(activeUser),
    excludedGroups: ["all_team_members", "employment_type", "pay_type"],
  });
  const { setCanNext, screens, curIndex, handleComplete } = useWizard();
  const isMounted = useRef(true);

  const activeCompanyId = useActiveCompanyId();
  const lookupTeam = useLookupTeam();

  const teamMemberOptions = teamMemberGroupOptions
    .flatMap((group) => group.options)
    .filter((o) => {
      if (isMiterRep(activeUser)) return true;
      const tm = lookupTeam(o.value.value);
      return !isMiterRep(tm);
    });

  const form = useForm<CertificationTypeForm>({
    mode: "all",
    defaultValues: buildDefaultValues({ certificationType, teamMemberOptions, permissionGroupOptions }),
  });

  const { register, errors, control, formState, trigger, handleSubmit } = form;
  const formData = useWatch({ control });

  const refetchCertificationTypes = useRefetchCertificationTypes();

  const submitForm = async (data) => {
    if (!activeCompanyId) return Notifier.error("No active company found. Please refresh your page.");
    const hasExpiry = data.expires === "expires";
    const formattedData = {
      ...data,
      company_id: activeCompanyId,
      expires: hasExpiry,
      archived: false,
      expiration_notification: hasExpiry
        ? {
            notify_before: (data.notify_before || []).map((option) => option.value),
            team_member_groups: (data.team_member_groups || []).map((option) => option.value),
          }
        : undefined,
      automatic_request_on_expiration: !!data.automatic_request_on_expiration,
      permission_groups_can_manage: data.permission_groups_can_manage?.map((pg) => pg.value),
    };
    try {
      const res = certificationType
        ? await MiterAPI.certification_types.update(certificationType._id, formattedData)
        : await MiterAPI.certification_types.create(formattedData);
      if (res.error) {
        throw new Error(res.error);
      }
      setCertificationType(res);
      refetchCertificationTypes();
      Notifier.success("Certification type is updated.");
      if (curIndex === screens.length - 1) {
        handleComplete();
      }
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error(e);
    }
  };

  const onNext = async () => {
    handleSubmit(submitForm)();
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (!isEmpty(formState.errors)) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [formState]);

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <Formblock
          label="Title*"
          type="text"
          form={form}
          register={register(vals.required)}
          control={control}
          errors={errors}
          name="title"
          className="modal"
          editing={true}
          placeholder="E.g. Driver's License"
          // defaultValue={formData.vendor_name}
        />
        <div>
          <Formblock
            type="paragraph"
            name="description"
            label="Description"
            className="modal time-off-request-notes"
            editing={true}
            placeholder={"Write certification description here..."}
            style={{ marginTop: "15px" }}
            control={control}
            errors={errors}
            register={register}
          />
        </div>
        <Formblock
          label="Does this certification expire?"
          type="radio"
          name="expires"
          labelInfo="Date of expiry is set by the certification uploader."
          form={form}
          className="modal wizard"
          editing={true}
          options={[
            { label: "Yes, require users to input the certification's expiration date", value: "expires" },
            { label: "No, this certification does not expire", value: "no_expiry" },
          ]}
          val={vals.required}
        />
        <Formblock
          type="multiselect"
          name="permission_groups_can_manage"
          label="Permission groups"
          sublabel="Select permission groups who can manage this certification type. Only the permission groups you select will be able manage this certification type, otherwise all team members can manage their certifications."
          form={form}
          editing={true}
          options={permissionGroupOptions}
          className="modal"
          height={"unset"}
          defaultValue={certificationType?.permission_groups_can_manage}
        />
        {formData.expires === "expires" ? (
          <div>
            <Formblock
              type="multiselect"
              name="notify_before"
              label={"When do you want to be notified about expiration?"}
              form={form}
              editing={true}
              className="modal wizard"
              placeholder={"Select notification windows"}
              options={notificationWindows}
              height="unset"
              defaultValue={certificationType?.expiration_notification?.notify_before}
            />
            <Formblock
              type="multiselect"
              name="team_member_groups"
              label={"Who should be notified?"}
              form={form}
              editing={true}
              className="modal wizard"
              placeholder={"Select team members to be notified."}
              options={teamMemberGroupOptions}
              val={vals.required}
              height="unset"
              labelButtonText="View team member list"
              // @ts-expect-error fix me
              defaultValue={certificationType?.expiration_notification?.team_member_groups}
            />
            <Formblock
              label="Renewals"
              text="Automatically request a new certification from an employee when their old certification expires."
              type="checkbox"
              name="automatic_request_on_expiration"
              form={form}
              className="modal wizard"
              editing={true}
            />
          </div>
        ) : null}
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (input: {
  certificationType?: CertificationType;
  teamMemberOptions: Option<TeamMemberGroupSelectValue>[];
  permissionGroupOptions: Option<string>[];
}): CertificationTypeForm | {} => {
  const { certificationType, teamMemberOptions, permissionGroupOptions } = input;
  if (!certificationType) {
    return {};
  }
  const teamMembers = (certificationType?.expiration_notification?.team_member_groups || [])
    .filter(notNullish)
    .map((group) => {
      const option = teamMemberOptions.find(
        (tm) => tm.value?.type === group.type && tm.value?.value === group.value
      );
      return option;
    })
    .filter(notNullish);

  const labeledPermissionGroups = (certificationType?.permission_groups_can_manage || [])
    .filter(notNullish)
    .map((groupID) => {
      const option = permissionGroupOptions.find((pg) => pg?.value === groupID);
      return option;
    })
    .filter(notNullish);

  return {
    title: certificationType?.title,
    description: certificationType?.description,
    expires: certificationType?.expires ? "expires" : "no_expiry",
    team_member_groups: teamMembers,
    automatic_request_on_expiration: !!certificationType?.automatic_request_on_expiration,
    permission_groups_can_manage: labeledPermissionGroups,
  };
};
