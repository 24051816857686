import { useActiveTeam } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember } from "dashboard/miter";
import { CircleDashed, Lock, MinusCircle, PlusCircle, Users } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { ActionModal, IconWithTooltip, Label, Loader } from "ui";

import styles from "./BulkTeamMemberSelect.module.css";
import { capitalize } from "lodash";
import EmptyState from "ui/empty-state/EmptyState";

export type StatusAndReason = {
  status: boolean;
  reason?: string;
};

export type BulkTeamMemberSelectTeamMember = AggregatedTeamMember & {
  non_removable?: boolean;
  disabled?: StatusAndReason;
  warning?: string;
  additional_information?: string;
};

type Props = {
  title?: string | React.ReactElement;
  teamMembersPool?: BulkTeamMemberSelectTeamMember[];
  defaultTeamMembers: BulkTeamMemberSelectTeamMember[];
  predicate?: (tm: AggregatedTeamMember) => boolean;
  disableTm?: (tm: AggregatedTeamMember) => StatusAndReason;
  nonRemovableDescriptor?: string;
  submitText?: string;
  onSubmit: (teamMembers: BulkTeamMemberSelectTeamMember[]) => void;
  onHide: () => void;
  submitting?: boolean;
  wrapperStyle?: React.CSSProperties;
};

const BulkTeamMemberSelect: React.FC<Props> = ({
  title,
  teamMembersPool,
  defaultTeamMembers,
  nonRemovableDescriptor,
  submitText,
  onSubmit,
  onHide,
  predicate,
  submitting,
  disableTm,
  wrapperStyle,
}) => {
  const activeTeam = useActiveTeam();

  const [loading, setLoading] = useState(true);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<BulkTeamMemberSelectTeamMember[]>([]);
  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    setSelectedTeamMembers(defaultTeamMembers);
    setLoading(false);
  }, [JSON.stringify(defaultTeamMembers), activeTeam, teamMembersPool]);

  const selectedTeamMembersMap = useMemo(() => {
    return selectedTeamMembers.reduce((map, teamMember) => {
      map[teamMember._id] = teamMember;
      return map;
    }, {});
  }, [selectedTeamMembers]);

  const filteredTeamMembers = useMemo(() => {
    return (teamMembersPool || activeTeam)
      .map((tm) => {
        return {
          ...tm,
          disabled: disableTm ? disableTm(tm) : { status: false },
        };
      })
      .filter((teamMember) => {
        if (selectedTeamMembersMap[teamMember._id]) return false;
        if (predicate && !predicate(teamMember)) return false;
        if (searchFilter === "") return true;
        if (teamMember.full_name.toLowerCase().includes(searchFilter.toLowerCase())) return true;
        if (teamMember.department?.name?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
        if (teamMember.pay_type?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
        if (teamMember.title?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
        if (teamMember.friendly_id?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
        return false;
      });
  }, [activeTeam, disableTm, selectedTeamMembersMap, searchFilter, teamMembersPool]);

  const handleAdd = (teamMember: BulkTeamMemberSelectTeamMember) => {
    setSelectedTeamMembers([...selectedTeamMembers, teamMember].filter((tm) => !tm?.disabled?.status));
  };

  const handleAddAll = () => {
    setSelectedTeamMembers(
      [...selectedTeamMembers, ...filteredTeamMembers].filter((tm) => !tm?.disabled?.status)
    );
  };

  const handleRemove = (teamMember: BulkTeamMemberSelectTeamMember) => {
    const newSelectedTeamMembers = selectedTeamMembers.filter((stm) => stm._id !== teamMember._id);
    setSelectedTeamMembers(newSelectedTeamMembers);
  };

  const handleRemoveAll = () => {
    setSelectedTeamMembers(selectedTeamMembers.filter((tm) => tm.non_removable));
  };

  const handleSubmit = () => {
    onSubmit(selectedTeamMembers);
  };

  const renderTeamMemberRowAttributes = (
    teamMember: BulkTeamMemberSelectTeamMember,
    type: "selected" | "selectable" | "non_removable"
  ) => {
    return (
      <div className={styles["bulk-team-member-select-team-member"]}>
        <div className={styles["bulk-team-member-select-team-member-icon-container"]}>
          {type === "selectable" && (
            <PlusCircle
              className={styles["bulk-team-member-select-team-member-attributes-icon"]}
              color="#4d55bb"
              opacity={teamMember?.disabled?.status ? 0 : undefined}
            />
          )}
        </div>
        <div className={styles["bulk-team-member-select-team-member-attributes"]}>
          <div className={styles["bulk-team-members-select-selectable-team-member-name"]}>
            {teamMember?.disabled?.status ? (
              <Label labelInfo={teamMember?.disabled?.reason} label={teamMember.full_name} underlineTooltip />
            ) : (
              teamMember.full_name
            )}
            {teamMember.warning && type === "selectable" && (
              <IconWithTooltip
                Icon={FaExclamationTriangle}
                tooltip={teamMember.warning}
                style={{ color: "#EED202" }}
              />
            )}
          </div>
          <div className={styles["bulk-team-member-select-team-member-sub-attributes"]}>
            <div className={styles["bulk-team-members-select-selectable-team-member-friendly-id"]}>
              {teamMember.friendly_id}
            </div>
            {teamMember.friendly_id && (teamMember.department || teamMember.title || teamMember.pay_type) && (
              <>&nbsp;&nbsp;•&nbsp;&nbsp;</>
            )}
            <div className={styles["bulk-team-members-select-selectable-team-member-job-title"]}>
              {teamMember.title}
            </div>
            {teamMember.department && teamMember.title && <>&nbsp;&nbsp;•&nbsp;&nbsp;</>}
            {teamMember.department && (
              <div className={styles["bulk-team-members-select-selectable-team-member-department"]}>
                {teamMember.department.name}
              </div>
            )}
            {teamMember.pay_type && (teamMember.department || teamMember.title) && (
              <>&nbsp;&nbsp;•&nbsp;&nbsp;</>
            )}
            {teamMember.pay_type && (
              <div className={styles["bulk-team-members-select-selectable-team-member-pay-type"]}>
                {capitalize(teamMember.pay_type)}
              </div>
            )}
          </div>
          {teamMember.additional_information && (
            <div className={styles["bulk-team-member-select-team-member-additional-information"]}>
              {teamMember.additional_information}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTeamMemberRow = (
    teamMember: BulkTeamMemberSelectTeamMember,
    type: "selected" | "selectable" | "non_removable"
  ) => {
    if (type === "selectable") {
      return (
        <div
          key={teamMember._id}
          className={styles["bulk-team-members-select-selectable-team-member"]}
          onClick={() => handleAdd(teamMember)}
        >
          {renderTeamMemberRowAttributes(teamMember, type)}
        </div>
      );
    } else if (type === "selected") {
      return (
        <div key={teamMember._id} className={styles["bulk-team-members-select-selected-team-member"]}>
          {renderTeamMemberRowAttributes(teamMember, type)}
          <div
            className={styles["bulk-team-members-select-selectable-team-member-remove"]}
            onClick={() => handleRemove(teamMember)}
          >
            <MinusCircle color="red" />
          </div>
        </div>
      );
    } else if (type === "non_removable") {
      return (
        <div key={teamMember._id} className={styles["bulk-team-members-select-selected-team-member"]}>
          {renderTeamMemberRowAttributes(teamMember, type)}
          <div className="flex">
            <span className={styles["non-removable-descriptor"]}>{nonRemovableDescriptor || "Locked"}</span>
            <Lock color="orange" className={styles["bulk-team-member-select-team-member-attributes-icon"]} />
          </div>
        </div>
      );
    }
  };

  const renderSelectableTeamMembers = () => {
    if (loading) return <Loader />;

    return (
      <div className={styles["bulk-team-members-select-selectable-team-members-container"]}>
        <div className={styles["bulk-team-members-select-selectable-team-members-header"]}>
          <div className={styles["bulk-team-members-select-selectable-team-members-header-title"]}>
            <div className={styles["bulk-team-members-select-selectable-team-members-header-text"]}>
              Team members
            </div>
            <div className={styles["bulk-team-members-select-selectable-team-members-header-actions"]}>
              <div className={styles["bulk-team-members-select-add-all-button"]} onClick={handleAddAll}>
                Add all
              </div>
              <div className={styles["bulk-team-members-select-remove-all-button"]} onClick={handleRemoveAll}>
                Clear
              </div>
            </div>
          </div>
          <div className={styles["bulk-team-members-select-selectable-team-members-header-search"]}>
            <input
              type="text"
              className="form2-text"
              placeholder="Search by name, job title, or department"
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
            />
          </div>
        </div>
        <div className={styles["bulk-team-members-select-selectable-team-members"]}>
          {filteredTeamMembers.length ? (
            filteredTeamMembers.map((teamMember) => renderTeamMemberRow(teamMember, "selectable"))
          ) : (
            <EmptyState
              icon={<CircleDashed weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
              title={"No team members to add"}
              type="small"
            />
          )}
        </div>
      </div>
    );
  };

  const renderSelectedTeamMembers = () => {
    const teamMemberElements = selectedTeamMembers.map((teamMember) => {
      if (teamMember.non_removable) {
        return renderTeamMemberRow(teamMember, "non_removable");
      } else {
        return renderTeamMemberRow(teamMember, "selected");
      }
    });

    return (
      <div className={styles["bulk-team-members-select-selected-team-members"]}>
        {teamMemberElements.length > 0 && teamMemberElements}
        {teamMemberElements.length === 0 && (
          <EmptyState
            icon={<Users weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
            title={"None added"}
            type="small"
          />
        )}
      </div>
    );
  };

  return (
    <ActionModal
      className={styles["bulk-team-member-select-modal"]}
      wrapperClassName={styles["bulk-team-member-select-modal-wrapper"]}
      bodyClassName={styles["bulk-team-member-select-modal-body"]}
      headerText={title || "Choose team members"}
      submitText={submitText || "Save"}
      cancelText={"Cancel"}
      onCancel={onHide}
      onHide={onHide}
      onSubmit={handleSubmit}
      showSubmit={true}
      showCancel={true}
      loading={submitting}
      wrapperStyle={wrapperStyle}
    >
      <div className={styles["bulk-team-member-select-container"]}>
        <div className={styles["bulk-team-member-select"]}>
          {renderSelectableTeamMembers()}
          {renderSelectedTeamMembers()}
        </div>
      </div>
    </ActionModal>
  );
};

export default BulkTeamMemberSelect;
