import ReactJson from "react-json-view";
import { TIMESHEET_DT_FORMAT } from "./timesheetUtils";
import {
  useLedgerAccountLabeler,
  useLookupActivity,
  useLookupCompanyUsers,
  useLookupCostType,
  useLookupDepartment,
  useLookupExpenseReimbursementCategories,
  useLookupJob,
  useLookupPerDiemRates,
  useLookupPolicy,
  useLookupRateClassification,
  useLookupTeam,
  useLookupTimeOffPolicy,
  useLookupWcCode,
  useLookupWcGroup,
  useTeam,
} from "dashboard/hooks/atom-hooks";
import {
  AuditLog,
  CompanyBenefit,
  CompanyUser,
  EmployeeBenefit,
  Expense,
  ExpenseReimbursement,
  Job,
  TeamMember,
  TimeOffRequest,
  Timesheet,
  WorkersCompCode,
} from "dashboard/miter";
import ObjectID from "bson-objectid";
import { buildCompanyUserName, deparameterize, deparameterizeKeys, readableSeconds } from "miter-utils";
import pluralize from "pluralize";
import { capitalize, isEqual } from "lodash";
import { addressToString, getUserLabel } from "./utils";
import { DateTime } from "luxon";
import { useEarningTypeLabeler } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { AdvancedBreakTimeButton } from "dashboard/pages/timesheets/AdvancedBreakTimeHover";
import { useMemo } from "react";

export type AuditLogType =
  | "expense"
  | "expense_reimbursement"
  | "timesheet"
  | "job"
  | "team_member"
  | "employee_benefit"
  | "company_benefit"
  | "time_off_request"
  | "allowance"
  | "post_tax_deduction"
  | "workers_comp_code";

const GLOBAL_FIELDS_TO_IGNORE = [
  "approval_history",
  "approval_stage",
  "merchant_amount",
  "parent_id",
  "parent_original_amount",
  "integrations",
  "ach_payout_information",
  "bank_account_settings",
  "conversation",
  "check_id",
  "check_tm",
];

type UserFacingField = {
  name: string;
  fieldLabel?: string;
  previousValueLabel: string | null;
  newValueLabel: string | null;
  addedIds: string[] | null;
  removedIds: string[] | null;
  operationLabel: "Added" | "Updated" | "Removed";
};

export type UserFacingAuditLog = {
  authorLabel: string;
  timestamp: number;
  fields: UserFacingField[];
  operationLabel: "Created" | "Updated";
  object: $TSFixMe;
};

export const useCleanAuditLogs = (auditLogs: AuditLog[], type: AuditLogType): UserFacingAuditLog[] => {
  const lookupUser = useLookupCompanyUsers();

  const lookupTeamMember = useLookupTeam();
  const lookupDepartment = useLookupDepartment();

  const lookupJob = useLookupJob();
  const lookupActivity = useLookupActivity();
  const lookupCostType = useLookupCostType();
  const lookupGLAccount = useLedgerAccountLabeler();
  const lookupPolicy = useLookupPolicy();
  const lookupClassification = useLookupRateClassification();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const lookupWcCode = useLookupWcCode();
  const lookupWcGroup = useLookupWcGroup();
  const earningTypeLabeler = useEarningTypeLabeler();
  const lookupPerDiemRate = useLookupPerDiemRates();

  const fullTeam = useTeam();
  const teamByUserId = useMemo(() => {
    return Object.fromEntries(fullTeam.map((t) => [t.user, t]));
  }, [fullTeam]);

  // reimbursement specific
  const lookupReimbursementCategory = useLookupExpenseReimbursementCategories();

  const lookupValueForExpenseReimbursement = (key: keyof ExpenseReimbursement, value: string | null) => {
    if (Array.isArray(value)) {
      if (value.length === 0) return null;

      switch (key) {
        case "file_ids":
          return `${value.length} ${pluralize("receipt")}`;
        default:
          return null;
      }
    }

    switch (key) {
      case "team_member_id":
        return lookupTeamMember(value)?.full_name;
      case "department_id":
        return lookupDepartment(value)?.name;
      case "job_id":
        return lookupJob(value)?.name;
      case "activity_id":
        return lookupActivity(value)?.label;
      case "cost_type_id":
        return lookupCostType(value)?.label;
      case "expense_account_id":
        return lookupGLAccount(value);
      case "expense_reimbursement_category_id":
        return lookupReimbursementCategory(value)?.name;
      case "date":
        return value && new Date(value).toLocaleDateString();
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForCardTransaction = (key: keyof Expense, value: string | string[] | null) => {
    if (Array.isArray(value)) {
      if (value.length === 0) return null;

      switch (key) {
        case "file_ids":
          return `${value.length} ${pluralize("receipt")}`;
        default:
          return null;
      }
    }
    switch (key) {
      case "team_member_id":
        return lookupTeamMember(value)?.full_name;
      case "department_id":
        return lookupDepartment(value)?.name;
      case "job_id":
        return lookupJob(value)?.name;
      case "activity_id":
        return lookupActivity(value)?.label;
      case "cost_type_id":
        return lookupCostType(value)?.label;
      case "expense_account_id":
        return lookupGLAccount(value);
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForJob = (key: keyof Job, value: string | string[] | null) => {
    if (Array.isArray(value)) {
      if (value.length === 0) return null;

      switch (key) {
        case "supervisors":
        case "superintendent_ids":
          return value.map((tmId) => lookupTeamMember(tmId)?.full_name).join(", ");
        case "team_members":
          return value.map((tmId) => lookupTeamMember(tmId)?.full_name).join(", ");
        case "activities":
          return value.map((activity) => lookupActivity(activity)?.label).join(", ");
        case "per_diem_rate_ids":
          return value.map((rateId) => lookupPerDiemRate(rateId)?.name || "Deleted per diem rate").join(", ");
        default:
          return null;
      }
    }

    switch (key) {
      case "department_id":
        return lookupDepartment(value)?.name;
      case "expense_policy_id":
      case "timesheet_policy_id":
      case "reimbursement_policy_id":
        return lookupPolicy(value)?.name;
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForTeamMember = (key: keyof TeamMember, value: string | string[] | $TSFixMe | null) => {
    if (Array.isArray(value)) {
      if (value.length === 0) return null;

      switch (key) {
        case "accessible_job_ids":
          return value.map((jobId) => lookupJob(jobId)?.name).join(", ");
        default:
          return null;
      }
    }

    switch (key) {
      case "department_id":
        return lookupDepartment(value)?.name;
      case "default_activity_id":
        return lookupActivity(value)?.label;
      case "default_cost_type_id":
        return lookupCostType(value)?.label;
      case "default_job_id":
        return lookupJob(value)?.name;
      case "created_by":
        return getUserLabel(value || "");
      case "reports_to":
        return lookupTeamMember(value)?.full_name;
      case "address":
        return addressToString(value);
      case "wc_code":
        return lookupWcCode(value)?.code;
      case "wc_group_id":
        return lookupWcGroup(value)?.name;
      case "ssn_last_four":
        return "SSN updated. Last 4: " + value;
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForEmployeeBenefit = (
    key: keyof EmployeeBenefit,
    value: string | string[] | $TSFixMe | null,
    auditLog: AuditLog,
    type: "previous" | "new"
  ) => {
    switch (key) {
      case "emp_liab_account_id":
      case "com_liab_account_id":
      case "expense_account_id":
      case "cost_type_id":
        return lookupGLAccount(value);
      default:
        if (value && typeof value === "object") {
          const cleanedObject = deparameterizeKeys(value);
          if (type === "new") return " ";

          return (
            <ReactJson
              src={Object.values(cleanedObject)?.[0] || {}}
              name={null}
              style={{ fontFamily: "Karla" }}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              shouldCollapse={() => false}
            />
          );
        } else {
          return value?.toString();
        }
    }
  };

  const lookupValueForCompanyBenefit = (
    key: keyof CompanyBenefit,
    value: string | string[] | $TSFixMe | null
  ) => {
    switch (key) {
      case "emp_liab_account_id":
      case "com_liab_account_id":
      case "expense_account_id":
      case "cost_type_id":
        return lookupGLAccount(value);
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForTimeOffRequest = (
    key: keyof TimeOffRequest,
    value: string | string[] | $TSFixMe | null
  ) => {
    switch (key) {
      // skip
      case "schedule":
        return null;
      case "employee":
        return lookupTeamMember(value)?.full_name;
      case "department_id":
        return lookupDepartment(value)?.name;
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForWorkersCompCode = (
    key: keyof WorkersCompCode,
    value: string | string[] | $TSFixMe | null
  ) => {
    switch (key) {
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForTimesheet = (
    key: keyof Timesheet,
    value: string | string[] | $TSFixMe | null,
    auditLog: AuditLog
  ) => {
    const timesheet = auditLog.document as Timesheet;
    switch (key) {
      case "clock_in":
        return DateTime.fromSeconds(value, { zone: timesheet.timezone }).toFormat(TIMESHEET_DT_FORMAT);
      case "clock_out":
        return DateTime.fromSeconds(value, { zone: timesheet.timezone }).toFormat(TIMESHEET_DT_FORMAT);
      case "team_member":
        return lookupTeamMember(value)?.full_name;
      case "department_id":
        return lookupDepartment(value)?.name;
      case "job":
        return lookupJob(value)?.name;
      case "activity":
        return lookupActivity(value)?.label;
      case "classification_override":
        return lookupClassification(value)?.classification;
      case "time_off_policy_id":
        return lookupTimeOffPolicy(value)?.name;
      case "custom_pay_rate":
        return value ? `$${value}` : "";
      case "injury":
        return value ? "Yes" : "No";
      case "images":
        return value.length > 0 ? `${value.length} ${pluralize("image")}` : null;
      case "hours":
        return value ? `${value} hours` : "";
      case "earning_type":
        return value ? earningTypeLabeler(value) : "";
      case "sign_off":
        return value?.status === "signed" ? "Signed" : "Missing";
      case "clock_in_photo":
      case "clock_out_photo":
        return value ? "Added" : "Removed";
      case "unpaid_break_time":
      case "paid_break_time":
        return readableSeconds(value);
      case "breaks":
        return (
          <>
            Paid:{" "}
            <AdvancedBreakTimeButton
              timesheet={{ breaks: value, timezone: timesheet.timezone }}
              type="unpaid"
            />
            Unpaid:{" "}
            <AdvancedBreakTimeButton
              timesheet={{ breaks: value, timezone: timesheet.timezone }}
              type="paid"
            />
          </>
        );
      case "notes":
      case "timezone":
      case "status":
      case "archived":
        return value;
      default:
        return "";
    }
  };

  const lookupValueForAllowance = (key: string, value: string | string[] | $TSFixMe | null) => {
    switch (key) {
      case "team_member":
        return lookupTeamMember(value)?.full_name;
      case "job_id":
        return lookupJob(value)?.name;
      case "activity_id":
        return lookupActivity(value)?.label;
      case "ledger_account_id":
        return lookupGLAccount(value);
      case "cost_type_id":
        return lookupCostType(value)?.label;
      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const lookupValueForPostTaxDeduction = (
    key: string,
    value: string | string[] | $TSFixMe | null,
    _auditLog: AuditLog,
    type: "previous" | "new"
  ) => {
    switch (key) {
      case "employee":
        return lookupTeamMember(value)?.full_name;
      case "gl_account_id":
        return lookupGLAccount(value);
      case "is_401k_loan":
        return value ? "Yes" : "No";
      case "check_post_tax_deduction":
        // Don't display if type is new because we are showing the object as JSON
        if (type === "new") return " ";

        // Deparameterize all the keys (deep) with lodash
        const cleanedObject = deparameterizeKeys(value);

        return (
          <ReactJson
            src={Object.values(cleanedObject)?.[0] || {}}
            name={null}
            style={{ fontFamily: "Karla" }}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            shouldCollapse={() => false}
          />
        );

      default:
        return value && typeof value === "object" ? JSON.stringify(value) : value?.toString();
    }
  };

  const getAuthorLabel = (authorId: string) => {
    if (ObjectID.isValid(authorId)) {
      const user = lookupUser(authorId);
      if (user) {
        return buildCompanyUserName(user as CompanyUser);
      } else {
        const tm = teamByUserId[authorId];
        if (tm) {
          return tm.full_name;
        } else {
          return "Deleted user";
        }
      }
    } else {
      return "System";
    }
  };

  const getOperationLabel = (operation: "create" | "update" | "delete") => {
    switch (operation) {
      case "create":
        return "Created";
      default:
        return "Updated";
    }
  };

  const getFieldOperationLabel = (oldValue: string | null, newValue: string | null) => {
    if (!oldValue) {
      return "Added";
    } else if (!newValue) {
      return "Removed";
    } else {
      return "Updated";
    }
  };

  const getChangedValues = (key: string, value: $TSFixMe) => {
    let name = key;
    let previousValueLabel: string | null = null;
    let newValueLabel: string | null = null;
    let addedIds: string[] | null = null;
    let removedIds: string[] | null = null;

    if (key.includes("__added")) {
      name = key.replace("__added", "");
      previousValueLabel = null;
      if (Array.isArray(value)) {
        addedIds = value;
      } else {
        newValueLabel = value as string;
      }
    } else if (key.includes("__") === false) {
      // value.length is total new array size
      if (Array.isArray(value)) {
        // parse out the structure, map to array of the IDs
        addedIds = value
          .filter((change) => Array.isArray(change) && change.length > 0 && change[0] === "+")
          .map((addedItem) => addedItem[1]);

        removedIds = value
          .filter((change) => Array.isArray(change) && change.length > 0 && change[0] === "-")
          .map((addedItem) => addedItem[1]);
      } else {
        // __old and __new values

        previousValueLabel = value?.__old;
        newValueLabel = value?.__new;

        // there's an edge case where the value is a recursive object. for now: stringify. TODO: add recursion here
        if (!value?.__old && !value?.__new) {
          // assumption: only one key in value
          const keys = Object.keys(value);
          if (keys.length === 1 && keys[0]) {
            const onlyKey = keys[0];
            value = value[onlyKey];

            previousValueLabel = value?.__old;
            newValueLabel = value?.__new;
          } else {
            // if there's no __old or __new, ignore change
            previousValueLabel = null;
            newValueLabel = null;
          }
        }
      }
    }

    return { name, previousValueLabel, newValueLabel, addedIds, removedIds };
  };

  const cleanAuditLog = (auditLog: AuditLog, type: AuditLogType): UserFacingAuditLog => {
    const cleanedFields: UserFacingField[] = Object.keys(auditLog.diff ?? {}).flatMap((key) => {
      const value = auditLog.diff![key];
      // if first time field is added, will look like: {'expense_account_id__added': '65677d3d779da50063c8fb70'}

      const changedValues = getChangedValues(key, value);
      let { previousValueLabel, newValueLabel, addedIds, removedIds } = changedValues;
      const { name } = changedValues;
      if (GLOBAL_FIELDS_TO_IGNORE.includes(name)) return [];

      let lookupFunc;
      switch (type) {
        case "expense":
          lookupFunc = lookupValueForCardTransaction;
          break;
        case "expense_reimbursement":
          lookupFunc = lookupValueForExpenseReimbursement;
          break;
        case "job":
          lookupFunc = lookupValueForJob;
          break;
        case "team_member":
          lookupFunc = lookupValueForTeamMember;
          break;
        case "employee_benefit":
          lookupFunc = lookupValueForEmployeeBenefit;
          break;
        case "company_benefit":
          lookupFunc = lookupValueForCompanyBenefit;
          break;
        case "time_off_request":
          lookupFunc = lookupValueForTimeOffRequest;
          break;
        case "workers_comp_code":
          lookupFunc = lookupValueForWorkersCompCode;
          break;
        case "timesheet":
          lookupFunc = lookupValueForTimesheet;
          break;
        case "allowance":
          lookupFunc = lookupValueForAllowance;
          break;
        case "post_tax_deduction":
          lookupFunc = lookupValueForPostTaxDeduction;
          break;
      }

      let fieldLabel: string | undefined;
      switch (type) {
        case "company_benefit":
        case "employee_benefit":
          fieldLabel = buildBenefitFieldLabel(key, value);
        case "expense":
        case "expense_reimbursement":
          fieldLabel = buildExpenseManagementFieldLabel(key, value);
      }

      if (Array.isArray(value)) {
        addedIds = lookupFunc(name, addedIds, auditLog);
        removedIds = lookupFunc(name, removedIds, auditLog);

        if (!addedIds && !removedIds) {
          return [];
        }

        // if added and removed are the same, also no-op
        if (isEqual(addedIds, removedIds)) {
          return [];
        }
      } else {
        // look up the label for both previous and new values
        previousValueLabel = lookupFunc(name, previousValueLabel, auditLog, "previous") ?? previousValueLabel;

        newValueLabel = lookupFunc(name, newValueLabel, auditLog, "new") ?? newValueLabel;

        if (!newValueLabel && !previousValueLabel) {
          return [];
        }
      }

      return [
        {
          name, // original field name - ex. file_ids
          previousValueLabel,
          newValueLabel,
          operationLabel: getFieldOperationLabel(previousValueLabel, newValueLabel),
          addedIds,
          removedIds,
          fieldLabel, // optional, will override the name. ex "Receipts"
        },
      ];
    });

    return {
      authorLabel: getAuthorLabel(auditLog.author),
      timestamp: auditLog.created_at,
      fields: cleanedFields,
      operationLabel: getOperationLabel(auditLog.operation),
      object: auditLog.document,
    };
  };

  // if fields is empty, don't include the log
  return auditLogs
    .map((log) => cleanAuditLog(log, type))
    .filter((log) => log.operationLabel === "Created" || log.fields.length > 0);
};

export const buildBenefitFieldLabel = (key: string, value: unknown): string | undefined => {
  switch (key) {
    case "emp_liab_account_id":
      return "Employee liability account";
    case "com_liab_account_id":
      return "Company liability account";
    case "expense_account_id":
      return "Expense account";
    case "cost_type_id":
      return "Cost Type";
    case "check_benefit":
      const subField = Object.keys(value as Record<string, unknown>)[0];
      return capitalize(deparameterize(subField));
    default:
      return undefined;
  }
};

export const buildExpenseManagementFieldLabel = (key: string, _value: unknown): string | undefined => {
  switch (key) {
    case "file_ids":
      return "Receipts";
    default:
      return undefined;
  }
};

export const buildPostTaxDeductionFieldLabel = (key: string, _value: unknown): string | undefined => {
  switch (key) {
    case "employee":
      return "Employee";
    case "gl_account_id":
      return "GL Account";
    case "check_post_tax_deduction":
      return "Deduction details";
    default:
      return undefined;
  }
};

export const cleanFieldName = (field: UserFacingField): string => {
  const { name, fieldLabel } = field;

  let cleanedName =
    fieldLabel || capitalize(name.includes("_") ? deparameterize(name) : name).replaceAll("_id", "");

  // edge case: if the field ends in _ids, remove the last character and pluralize
  if (cleanedName.endsWith(" s")) {
    cleanedName = cleanedName.slice(0, -2);
    cleanedName = pluralize(cleanedName);
  } else if (cleanedName.endsWith("ids")) {
    cleanedName = cleanedName.slice(0, -4);
    cleanedName = pluralize(cleanedName);
  }

  return cleanedName;
};
