import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Formblock, Label, Loader } from "ui";
import * as vals from "dashboard/utils/validators";
import styles from "./CompanyForm.module.css";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import AppContext from "dashboard/contexts/app-context";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { useAuthToken, useToggleAccount, useUser, useUserAccounts } from "dashboard/hooks/atom-hooks";
import { poBoxRegex, sleep } from "miter-utils";
import { Option } from "ui/form/Input";

const CompanyForm: React.FC = () => {
  const authToken = useAuthToken();
  const [loading, setLoading] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState<string>();
  const { fetchUserData } = useContext(AppContext);
  const { register, control, errors, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();
  const user = useUser();
  const userAccounts = useUserAccounts();
  const toggleAccount = useToggleAccount();

  const switchRoletoNewCompany = async () => {
    // If updated roles were successfully pulled, switch the user to the role of the company that was created
    const newAccount = userAccounts.find((account) => account.company_id === newCompanyId);
    if (newAccount) {
      await toggleAccount({ new_account_id: newAccount._id });
      navigate("/home");
    }
  };

  // Need to switch roles using an effect because `roles` will be stale if we do it within the `createCompany` function
  useEffect(() => {
    if (newCompanyId) {
      switchRoletoNewCompany();
    }
  }, [newCompanyId]);

  if (!authToken) return <Navigate to="/login" replace />;

  const validateData = (data): boolean => {
    // Validate that the address street is not a PO Box
    if (data.address.line1 && poBoxRegex.test(data.address.line1)) {
      throw new Error("The address contains a PO box. Please enter a valid street address.");
    }

    // Validate that the address line2 is not a PO Box
    if (data.address.line2 && poBoxRegex.test(data.address.line2)) {
      throw new Error("The address contains a PO box. Please enter a valid street address.");
    }

    return true;
  };

  const createCompany = async (data): Promise<void> => {
    setLoading(true);

    try {
      const payload = { ...data };

      validateData(payload);

      // Remove the address line2 if its an empty string
      if (payload.address.line2.length === 0) {
        payload.address.line2 = undefined;
      }

      // Set the state to the right value
      if (payload.address.state) {
        payload.address.state = payload.address.state.value;
      }

      if (payload.signer_type) {
        payload.signer_type = payload.signer_type.value;
      }

      const res = await MiterAPI.companies.create(payload);
      if (res.error) {
        throw new Error(res.error);
      }

      await fetchUserData();

      await sleep(1); // let the user data be updated before switching roles with the next line

      setNewCompanyId(res._id);

      Notifier.success("Company created successfully");
    } catch (e: $TSFixMe) {
      console.error("Error:", e);
      Notifier.error(e.message);
    }

    setLoading(false);
  };

  return (
    <div className={styles["container"]}>
      <Link
        to={!user ? "/login" : userAccounts.length > 0 ? "/home" : "/logout"}
        className={styles["home-button"]}
      >
        <IoMdClose />
      </Link>
      <div className={styles["company-form-container"]}>
        <div className={styles["company-form"]}>
          <h1 className={styles["company-form-title"]}>Create a company</h1>
          <div className={styles["company-form-body"]}>
            <form>
              <Formblock
                label="Trade name"
                type="text"
                control={control}
                register={register(vals.required)}
                name="trade_name"
                className="modal"
                errors={errors}
                editing={true}
                placeholder="SF Electrical"
              />
              <Formblock
                label="Legal name"
                type="text"
                control={control}
                register={register(vals.required)}
                name="legal_name"
                className="modal"
                errors={errors}
                editing={true}
                placeholder="SF Electrical Inc."
              />
              <Formblock
                label="Company phone"
                type="phone"
                name="phone"
                control={control}
                register={register}
                val={vals.phoneNotRequired}
                className="modal"
                errors={errors}
                editing={true}
                placeholder={"(555) 423-5178"}
              />
              <Formblock
                label="Company email"
                type="text"
                name="email"
                control={control}
                register={register(vals.email)}
                className="modal"
                errors={errors}
                editing={true}
                placeholder={"info@sfelectrical.com"}
              />
              <Formblock
                label="Address"
                type="address"
                name="address"
                labelInfo="Legal address of company"
                register={register(vals.required)}
                notRequiredRegister={register}
                required
                control={control}
                className="modal"
                errors={errors}
                editing={true}
                autocomplete={true}
                setValue={setValue}
              />
              <h4 style={{ marginBottom: 10 }}>Signer information</h4>
              <Label label="First and last name" className="modal" />
              <div className={styles["signer-name"]}>
                <Formblock
                  type="text"
                  control={control}
                  register={register(vals.required)}
                  name="signer_first"
                  className="modal"
                  errors={errors}
                  editing={true}
                  placeholder="Jerry"
                />
                <Formblock
                  type="text"
                  control={control}
                  register={register(vals.required)}
                  name="signer_last"
                  className="modal"
                  errors={errors}
                  editing={true}
                  placeholder="Smith"
                />
              </div>
              <Formblock
                label="Title"
                type="text"
                control={control}
                register={register(vals.required)}
                name="signer_title"
                className="modal"
                errors={errors}
                editing={true}
                placeholder="CEO"
              />
              <Formblock
                label="Email"
                type="text"
                name="signer_email"
                control={control}
                register={register(vals.email)}
                className="modal"
                errors={errors}
                editing={true}
                placeholder={"jerry@sfelectrical.com"}
              />
              <Formblock
                label="Type"
                type="select"
                name="signer_type"
                control={control}
                register={register}
                className="modal"
                errors={errors}
                editing={true}
                options={accountTypeOptions}
                requiredSelect
              />
              <button
                type="submit"
                onClick={handleSubmit(createCompany)}
                className={"" + styles["submit-button"]}
              >
                {loading ? <Loader className="button auth" /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
        <a className={styles["logout"]} href={"tel:3235764166"}>
          Need help? Give us a call at (323) 576-4166
        </a>
      </div>
    </div>
  );
};

export default CompanyForm;

const accountTypeOptions: Option<string>[] = [
  { label: "Employee", value: "employee" },
  { label: "1099 Contractor", value: "contractor" },
  { label: "External role", value: "role" },
];
