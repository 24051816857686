/* global google */
import convertDistance from "geolib/es/convertDistance";
import { TimesheetGeofenceStatus } from ".";
import getDistance from "geolib/es/getDistance";
import { Address, AggregatedLiveTimesheet, AggregatedTimesheet } from "dashboard/miter";

export type LatLng = {
  lat: number;
  lng: number;
};

import { useRef, useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";

export const GoogleLoader = new Loader({
  id: "google-map-script",
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ["geometry", "places"],
  language: "en",
  region: "US",
  mapIds: [],
  nonce: "",
  authReferrerPolicy: "origin",
});

/** Loads up the Google Maps JS API */
export const useLoadGoogleMaps = (): {
  isLoaded: boolean;
  loadError: Error | undefined;
} => {
  const isMounted = useRef(false);
  const [isLoaded, setLoaded] = useState(false);
  const [loadError, setLoadError] = useState<Error>();

  useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      return;
    } else {
      GoogleLoader.load()
        .then(() => {
          if (isMounted.current) {
            setLoaded(true);
          }

          return;
        })
        .catch((error) => {
          setLoadError(error);
        });
    }
  }, []);

  return { isLoaded, loadError };
};

export const getAddressFromCoordinates = async (latLng: {
  lat: number;
  lng: number;
}): Promise<{ address?: Address; addressString?: string }> => {
  let Geocoder: typeof google.maps.Geocoder;
  try {
    Geocoder = await GoogleLoader.load().then((r) => r.maps.Geocoder);
  } catch (err: $TSFixMe) {
    console.error("Error loading Google Maps API for reverse geocode:", err);
    return {};
  }
  const res = await new Geocoder().geocode({ location: latLng });

  const first = res.results[0];
  if (!first) return {};

  const rawStreetAddress = first.address_components.find((c) =>
    c.types.includes("street_address")
  )?.long_name;
  const streetNum = first.address_components.find((c) => c.types.includes("street_number"))?.long_name;
  const route = first.address_components.find((c) => c.types.includes("route"))?.short_name;
  const city = first.address_components.find((c) => c.types.includes("locality"))?.long_name;
  const state = first.address_components.find((c) =>
    c.types.includes("administrative_area_level_1")
  )?.short_name;
  const postalCode = first.address_components.find((c) => c.types.includes("postal_code"))?.long_name;

  const streetAddress = rawStreetAddress || streetNum ? `${streetNum} ${route}` : route;

  if (!streetAddress || !city || !state || !postalCode) return {};

  return {
    addressString: first.formatted_address,
    address: {
      line1: streetAddress,
      city: city,
      state: state,
      postal_code: postalCode,
    },
  };
};

export const getLatLngFromAddressString = async (
  addressString: string
): Promise<{ lat: number; lng: number } | null> => {
  let Geocoder: typeof google.maps.Geocoder;
  try {
    Geocoder = await GoogleLoader.load().then((r) => r.maps.Geocoder);
  } catch (err: $TSFixMe) {
    console.error("Error loading Google Maps API for geocode:", err);
    return null;
  }

  try {
    const res = await new Geocoder().geocode({ address: addressString });
    const latlng = res.results[0]?.geometry.location;
    if (latlng) return latlng.toJSON();
  } catch (err: $TSFixMe) {
    if (!err.message?.includes("ZERO_RESULTS")) {
      console.error("Geocode error:", err);
    }
  }
  return null;
};

export const determineGeofenceStatusOfTimesheet = (
  timesheet: AggregatedTimesheet,
  radiusMiles = 0.5
): TimesheetGeofenceStatus => {
  // If app was not created via clock in or if timesheet job does not have coordinates, return n/a
  if (!timesheet.job) {
    return "missing_job";
  } else if (timesheet.creation_method !== "app_clock_in" && timesheet.creation_method !== "kiosk_clock_in") {
    return "n/a";
  } else if (!timesheet.geolocation?.clock_in && !timesheet.geolocation?.clock_out) {
    return "missing_timesheet_geolocation";
  } else if (!timesheet.job?.geolocation) {
    return "missing_job_geolocation";
  } else {
    let clockInDistance = 0;
    let clockOutDistance = 0;
    if (timesheet.geolocation?.clock_in) {
      clockInDistance = getDistanceBetweenCoordinates(
        timesheet.job?.geolocation,
        timesheet.geolocation.clock_in,
        { unit: "mile" }
      );
    }
    if (timesheet.geolocation?.clock_out) {
      clockOutDistance = getDistanceBetweenCoordinates(
        timesheet.job?.geolocation,
        timesheet.geolocation.clock_out,
        { unit: "mile" }
      );
    }
    if (clockInDistance > radiusMiles || clockOutDistance > radiusMiles) {
      return "outside_fence";
    } else {
      return "within_fence";
    }
  }
};

export const getTimesheetGeolocationMessage = (ts: AggregatedTimesheet, radiusMiles?: number): string => {
  const status = determineGeofenceStatusOfTimesheet(ts, radiusMiles);

  if (status === "missing_job") {
    return "This timesheet does not have an associated job.";
  } else if (status === "n/a") {
    return "This timesheet was created manually.";
  } else if (status === "missing_job_geolocation") {
    return "This timesheet's job is missing geolocation data.";
  } else if (status === "missing_timesheet_geolocation") {
    return "This timesheet is missing geolocation data.";
  } else if (status === "outside_fence") {
    return "This timesheet's clock in or clock out was over " + radiusMiles + " mile(s) from the jobsite.";
  } else {
    return "Clock in and clock out were within " + radiusMiles + " mile(s) of the jobsite.";
  }
};

export const determineGeofenceStatusOfLiveTimesheet = (
  liveTimesheet: AggregatedLiveTimesheet,
  radiusMiles = 0.5
): TimesheetGeofenceStatus => {
  const hasJobGeolocation = !!liveTimesheet?.job?.geolocation;
  const hasTimesheetGeolocation = !!liveTimesheet?.last_bread_crumb;

  if (!hasTimesheetGeolocation) {
    return "missing_timesheet_geolocation";
  } else if (hasJobGeolocation) {
    const distance = getDistanceBetweenCoordinates(
      liveTimesheet!.job!.geolocation as LatLng,
      liveTimesheet.last_bread_crumb as LatLng,
      { unit: "mile" }
    );
    if (distance > radiusMiles) {
      return "outside_fence";
    }
    return "within_fence";
  } else {
    return "current_location";
  }
};

export const getDistanceBetweenCoordinates = (
  coord1: LatLng,
  coord2: LatLng,
  options?: { unit: "mile" | "meter" | "feet" }
): number => {
  const distanceInMeters = getDistance(coord1, coord2, 1);

  if (options?.unit === "mile") {
    return convertDistance(distanceInMeters, "mi");
  } else if (options?.unit === "feet") {
    return convertDistance(distanceInMeters, "ft");
  } else {
    return distanceInMeters;
  }
};

export const getDistanceBetweenCoordinatesArray = (
  coordinates: LatLng[],
  options?: { unit: "mile" | "meter" | "feet" }
): number => {
  let totalDistance = 0;
  for (let i = 0; i < coordinates.length - 1; i++) {
    totalDistance += getDistanceBetweenCoordinates(coordinates[i]!, coordinates[i + 1]!, options);
  }

  return totalDistance;
};
