import React, { useEffect, useMemo, useState } from "react";
import TimesheetContext from "./timesheetContext";
import { useForm } from "react-hook-form";
import { Formblock, Label, Notifier, TableV2, TextWithTooltip, Toggler } from "ui";
import * as vals from "dashboard/utils/validators";
import { PayRateOverview } from "dashboard/components/payRates/PayRateOverview";
import {
  useActivityLabelFormatter,
  useClassificationOptions,
  useJobNameFormatter,
  useLookupActivity,
  useLookupPrg,
  useLookupRateClassification,
  useRateDifferentials,
} from "dashboard/hooks/atom-hooks";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { getTooltipForPayRate } from "dashboard/utils/timesheetUtils";
import { createObjectMap } from "dashboard/utils";
import { ColumnConfig } from "ui/table-v2/Table";
import { Option } from "ui/form/Input";

const payRateTypeLookup = {
  auto: "Auto-determined rate",
  classification_override: "Manually-specified classification",
  custom: "Custom-defined rate",
};

const TimesheetPayRate: React.FC = () => {
  const { timesheet } = React.useContext(TimesheetContext);

  const rateDifferentials = useRateDifferentials();

  const [activeTab, setActiveTab] = useState("summary");

  const tabs = useMemo(() => {
    const tabArr = [
      { label: "Summary", path: "summary" },
      { label: "Classification", path: "classification" },
    ];
    if (rateDifferentials.length) {
      tabArr.push({ label: "Rate differentials", path: "rate_differentials" });
    }
    return tabArr;
  }, [timesheet]);

  return (
    <div className="width-100">
      <div className="payment-active-view-header">
        <div className="">Pay rate</div>
      </div>
      <Toggler active={activeTab} toggle={setActiveTab} config={tabs} />
      {activeTab === "summary" && <TimesheetPayRateSummary />}
      {activeTab === "classification" && <ClassificationSummary />}
      {activeTab === "rate_differentials" && <RateDifferentialsSummary />}
    </div>
  );
};

export default TimesheetPayRate;

type SummaryProps = {};

const TimesheetPayRateSummary: React.FC<SummaryProps> = ({}) => {
  const { control, register, errors, handleSubmit } = useForm();
  const { timesheet, inboxMode, updateTimesheet, changeTimesheetEditStatus, readOnly } =
    React.useContext(TimesheetContext);
  const timesheetAbilities = useTimesheetAbilities({ inboxMode });

  const lookupPrg = useLookupPrg();
  const lookupActivity = useLookupActivity();
  const lookupRateClassification = useLookupRateClassification();

  const classificationOptions = useClassificationOptions({
    tmId: timesheet?.team_member._id,
    activityId: timesheet?.activity?._id,
    jobId: timesheet?.job?._id,
  });
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    changeTimesheetEditStatus(editing);
  }, [editing]);

  const handleClickEdit = () => {
    setEditing(true);
  };

  const EditSaveButton = () => {
    if (timesheetAbilities.cannot("update_sensitive", timesheet)) return <></>;

    return (
      <div>
        {!editing && timesheet!.status === "unapproved" && !readOnly && (
          <div>
            <button className="button-2" onClick={handleClickEdit}>
              Edit
            </button>
          </div>
        )}
        {editing && (
          <div>
            <button className="button-1" onClick={() => setEditing(false)}>
              Cancel
            </button>
            <button
              className={saving ? "button-2 no-margin inactive" : "no-margin button-2"}
              onClick={handleSubmit(save)}
            >
              Save
            </button>
          </div>
        )}
      </div>
    );
  };

  const payRateTypeOptions = useMemo(() => {
    const opts: Option<string>[] = [
      {
        value: "auto",
        label: "The auto-determined pay rate",
      },
      {
        value: "custom",
        label: "Enter a custom rate",
      },
    ];

    if (classificationOptions.length) {
      opts.splice(1, 0, {
        value: "classification_override",
        label: "Manually-specified classification",
      });
    }

    return opts;
  }, [classificationOptions]);

  const save = async (data) => {
    if (timesheetAbilities.cannot("update_sensitive", timesheet)) {
      Notifier.error("You do not have permission to update this timesheet");
      return;
    }

    if (selectedPayRateType?.value === "classification_override" && !selectedClassificationOption) {
      Notifier.warning(`Please select a classification.`);
      return;
    }
    setSaving(true);
    await updateTimesheet({
      custom_pay_rate:
        data.custom_pay_rate == null || data.custom_pay_rate === "" || selectedPayRateType?.value !== "custom"
          ? null
          : data.custom_pay_rate,
      classification_override:
        selectedPayRateType?.value === "classification_override" ? selectedClassificationOption?.value : null,
    });
    setEditing(false);
    setSaving(false);
  };

  const classificationLabel = useMemo(() => {
    let label = "";
    if (timesheet?.pay_rate.classification) {
      if (timesheet.pay_rate.classification.source === "union_rate") {
        const prgRate = lookupRateClassification(timesheet.pay_rate.classification.source_id);
        if (prgRate) {
          label = prgRate.classification;
          const prg = lookupPrg(prgRate?.pay_rate_group);
          if (prg) label = `${prg.label}: ${label}`;
        }
      } else {
        const activity = lookupActivity(timesheet.pay_rate.classification.source_id);
        if (activity?.classification) {
          label = activity.classification;
        }
      }
    }
    return label;
  }, [timesheet, lookupRateClassification, lookupPrg]);

  const UNFINALIZED_STATUSES = ["unapproved", "approved"];
  let payRateType = "auto";
  if (
    timesheet?.custom_pay_rate &&
    (UNFINALIZED_STATUSES.includes(timesheet?.status) || timesheet?.pay_rate?.method === "custom")
  ) {
    payRateType = "custom";
  } else if (
    timesheet?.classification_override &&
    (UNFINALIZED_STATUSES.includes(timesheet?.status) || timesheet?.pay_rate?.method === "union_rate")
  ) {
    payRateType = "classification_override";
  }

  const [selectedPayRateType, setSelectedPayRateType] = useState(() => {
    return payRateTypeOptions.find((o) => o.value === payRateType);
  });

  const [selectedClassificationOption, setSelectedClassificationOption] = useState(
    classificationOptions.find((o) => o.value === timesheet?.classification_override) || null
  );

  const handlePayRateTypeChange = (option) => {
    if (option.value !== "classification_override") setSelectedClassificationOption(null);
    setSelectedPayRateType(option);
  };

  const tooltip = useMemo(() => {
    if (!timesheet?.pay_rate) return;

    return getTooltipForPayRate({
      pri: timesheet.pay_rate,
      teamMember: timesheet?.team_member,
      lookupClassification: lookupRateClassification,
      lookupPrg,
      job: timesheet?.job,
      activity: timesheet?.activity,
    });
  }, [timesheet]);

  if (!timesheet) return <></>;

  const renderPayRateTypeDesc = () => {
    if (!selectedPayRateType) return <>-</>;
    const desc = payRateTypeLookup[selectedPayRateType?.value];
    return (
      <>
        <TextWithTooltip id={desc} text={desc || ""} tooltip={tooltip} />
      </>
    );
  };

  return (
    <>
      <div className="vertical-spacer"></div>
      <div className="flex" style={{ marginBottom: 10 }}>
        <div className="flex-1"></div>
        <EditSaveButton />
      </div>
      <Formblock
        label="Pay rate type"
        labelInfo="How the team member's pay will be calculated for these hours"
        type="select"
        control={control}
        options={payRateTypeOptions}
        value={selectedPayRateType}
        onChange={handlePayRateTypeChange}
        name="pay_rate_type"
        editing={editing}
      >
        {renderPayRateTypeDesc()}
      </Formblock>
      {!editing ? (
        <>
          {timesheet?.custom_pay_rate == null && !!classificationLabel && (
            <Formblock label="Classification" type="text" name="classification" editing={false}>
              <div>{classificationLabel}</div>
            </Formblock>
          )}
        </>
      ) : (
        <>
          {selectedPayRateType?.value === "custom" && (
            <Formblock
              register={register(vals.numberValidator({ excludeNegatives: true }))}
              type="unit"
              name="custom_pay_rate"
              unit="$"
              errors={errors}
              defaultValue={timesheet.custom_pay_rate}
              label="Custom pay rate"
              editing={editing}
            />
          )}
          {selectedPayRateType?.value === "classification_override" && (
            <div>
              <Formblock
                label="Classification*"
                requiredSelect={true}
                type="select"
                control={control}
                options={classificationOptions}
                value={selectedClassificationOption}
                onChange={setSelectedClassificationOption}
                name="classification_override"
                editing={editing}
              />
            </div>
          )}
        </>
      )}

      <div className="vertical-spacer"></div>
      {!editing && <PayRateOverview payRateItem={timesheet.pay_rate} />}
    </>
  );
};

const ClassificationSummary: React.FC<SummaryProps> = ({}) => {
  const { timesheet } = React.useContext(TimesheetContext);

  const lookupPrg = useLookupPrg();
  const lookupRateClassification = useLookupRateClassification();
  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();

  const pri = timesheet?.pay_rate;

  const prg = useMemo(() => {
    if (!pri?.classification) return null;
    if (pri.classification.source === "union_rate") {
      const unionRate = lookupRateClassification(pri.classification.source_id);
      return lookupPrg(unionRate?.pay_rate_group);
    }
    return null;
  }, [pri, lookupPrg, lookupRateClassification]);

  const goToPrgPage = () => {
    window.open(`/settings/pay-rate-groups/${prg?._id}`, "_blank");
  };

  const goToActivity = () => {
    if (!timesheet?.activity) return;
    const aId = timesheet.activity._id;
    if (timesheet.activity.job) {
      window.open(`/jobs/${timesheet.activity.job}/activities?aid=${aId}`, "_blank");
    } else {
      window.open(`/activities?aid=${aId}`, "_blank");
    }
  };

  const renderUnionRateSource = () => {
    const urRef = timesheet?.pay_rate.union_rate_reference;
    const groupSource = urRef?.group_source;
    let label: string | undefined;
    let link: string | undefined;
    if (urRef?.type === "ts_classification_override") {
      label = "Manually-specified classification";
    } else if (groupSource === "job") {
      label = jobNameFormatter(timesheet?.job);
      link = `/jobs/${timesheet?.job?._id}`;
    } else if (groupSource === "activity" && timesheet?.activity) {
      label = activityLabelFormatter(timesheet.activity);
      const aId = timesheet?.activity?._id;
      if (timesheet.activity.job) {
        link = `/jobs/${timesheet.activity.job}/activities?aid=${aId}`;
      } else {
        link = `/activities?aid=${aId}`;
      }
    }
    return (
      <div className="flex" style={{ marginBottom: 10 }}>
        <Label label="Source" style={{ width: 120 }} />
        <div className="approval-item-policy-data-value">
          {link ? (
            <div
              className="blue-link"
              style={{ cursor: "pointer" }}
              onClick={() => window.open(link, "_blank")}
            >
              {label}
            </div>
          ) : (
            label
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="vertical-spacer"></div>
      <div className="approval-item-policy-data-body">
        <div className="flex" style={{ marginBottom: 10 }}>
          <Label label="Classification" style={{ width: 120 }} />
          <div className="approval-item-policy-data-value">{pri?.classification?.label || "-"}</div>
        </div>
        {pri?.classification?.source === "union_rate" && (
          <>
            <div className="flex" style={{ marginBottom: 10 }}>
              <Label label="Pay Rate Group" style={{ width: 120 }} />
              <div className="approval-item-policy-data-value">
                <div className="blue-link" style={{ cursor: "pointer" }} onClick={goToPrgPage}>
                  {prg?.label}
                </div>
              </div>
            </div>
            {renderUnionRateSource()}
          </>
        )}
        {pri?.classification?.source === "activity" && timesheet?.activity && (
          <>
            <div className="flex" style={{ marginBottom: 10 }}>
              <Label label="Activity" style={{ width: 120 }} />
              <div className="approval-item-policy-data-value">
                <div className="blue-link" style={{ cursor: "pointer" }} onClick={goToActivity}>
                  {timesheet?.activity?.label}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const RateDifferentialsSummary: React.FC<SummaryProps> = ({}) => {
  const { timesheet } = React.useContext(TimesheetContext);

  const rateDifferentials = useRateDifferentials();

  const tableData: TableRow[] = useMemo(() => {
    const activeRds = timesheet?.pay_rate.rate_differential_reference?.rate_differentials || [];
    const activeRdLookup = createObjectMap(activeRds, (r) => r.id);
    return rateDifferentials.map((rd) => {
      const activeRd = activeRdLookup[rd._id];
      let source = "-";
      if (activeRd?.source === "activity") {
        source = `Activity: ${timesheet?.activity?.label}`;
      } else if (activeRd?.source === "team_member") {
        source = "Team member default";
      } else if (activeRd?.source === "timesheet") {
        source = "Timesheet";
      }
      return {
        _id: rd._id,
        label: rd.label,
        active: !!activeRd,
        source,
      };
    });
  }, [timesheet]);

  type TableRow = {
    _id: string;
    label: string;
    active: boolean;
    source: string | undefined;
  };

  const columns: ColumnConfig<TableRow>[] = useMemo(() => {
    return [
      {
        headerName: "Rate differential",
        field: "label",
      },
      {
        headerName: "Active",
        field: "active",
        dataType: "boolean",
        sort: "desc",
      },
      {
        headerName: "Source",
        field: "source",
      },
    ];
  }, []);

  return (
    <>
      <div className="vertical-spacer"></div>
      <TableV2
        id="rate-differentials"
        resource="rate differentials"
        columns={columns}
        data={tableData}
        hideSearch={true}
        hideSecondaryActions={true}
      />
    </>
  );
};
