import AppContext from "dashboard/contexts/app-context";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

export const ActivitySettings: React.FC = () => {
  const navigate = useNavigate();

  const ref = useRef<{ isMounted: boolean }>({ isMounted: false });
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const { settings } = activeCompany!;
  const [activitySettings, setActivitySettings] = useState(settings.activities);

  useEffect(() => {
    if (!ref.current?.isMounted) {
      ref.current.isMounted = true;
      return;
    }

    updateSettingsDebounced(activitySettings);
  }, [activitySettings]);

  const updateSettings = async (newSettings) => {
    try {
      if (!activeCompany?._id) throw new Error("No active company");
      const response = await MiterAPI.companies.update(activeCompany._id, {
        $set: { "settings.activities": newSettings },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Activity settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating timesheet settings. Our engineers are looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(updateSettings, 500);

  const handleChange = async (fieldName: string, newValue) => {
    const newSettings = { ...activitySettings };
    newSettings[fieldName] = newValue;
    setActivitySettings(newSettings);
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Activity settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/activities")}>
          Back to activities
        </div>
        <div className="flex">
          <h1>Activity settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure activity related settings</div>
        <div className="vertical-spacer-small"></div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }}>Selection settings</div>
          <div className="flex">
            <input
              type="checkbox"
              checked={activitySettings?.show_code_in_dashboard}
              onChange={(e) => handleChange("show_code_in_dashboard", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>
              Prefix the activity name with its code in the dashboard when selecting an activity for a
              timesheet, expense, etc.
            </span>
          </div>
          <div className="vertical-spacer-small" />
          <div className="flex">
            <input
              type="checkbox"
              checked={activitySettings?.show_code_in_mobile}
              onChange={(e) => handleChange("show_code_in_mobile", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>Prefix the activity name with its code in the mobile app</span>
          </div>
        </div>
      </div>
    </div>
  );
};
