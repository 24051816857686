import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  AggregatedTeamMember,
  MiterAPI,
  Timesheet,
  AggregatedJob,
  MiterFilterArray,
  AggregatedPayroll,
  AggregatedTimesheet,
  UpdateTimesheetParams,
  UpdateManyTimesheetsParams,
  Policy,
  TimesheetPolicyRule,
} from "dashboard/miter";

import { BasicModal, TableV2, Toggler } from "ui";

import AppContext from "dashboard/contexts/app-context";
import { BulkCreateTimesheets } from "dashboard/pages/timesheets/BulkCreateTimesheets/BulkCreateTimesheets";
import { BulkUpdateTimesheetsPopover } from "dashboard/pages/timesheets/BulkUpdateTimesheets/BulkUpdateTimesheetsPopover";
import Notifier from "dashboard/utils/notifier";
import {
  creationMethodBadgeLookup,
  LiveTimesheetTableEntry,
  TimesheetTableEntry,
  useCleanTimesheets,
  useTimesheetApprover,
} from "dashboard/utils/timesheetUtils";
import { DateTime } from "luxon";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import {
  cleanFloatingPointErrors,
  customFieldAgGridTypeMap,
  readableSeconds,
  lookupTimezoneLabel,
  timezoneOptions,
  useEnhancedSearchParams,
} from "miter-utils";
import { ForageRequest, ForageResponse } from "backend/utils/forage/forage-types";
import TimesheetModal from "dashboard/pages/timesheets/ViewTimesheet/TimesheetModal";
import {
  ArrowClockwise,
  Check,
  ClockCounterClockwise,
  Copy,
  Pencil,
  Scissors,
  Stack,
  TrashSimple,
  X,
} from "phosphor-react";
import { capitalize } from "lodash";
import { LiveTimesheetsTable } from "./LiveTimesheetsTable";
import { FaCircle } from "react-icons/fa";
import { TogglerConfigItem } from "ui/toggler/Toggler";

import {
  useActiveCompanyId,
  useActiveCompany,
  useTeam,
  useActiveTeamMember,
  useRefetchActionableItems,
  useCrews,
  useLookupTeamMemberCrews,
  useActiveJobs,
  useDepartmentOptions,
  useLocationOptions,
  useActiveRole,
  useLookupWcCode,
  useLookupRateClassification,
  useLookupPolicy,
  useRateDifferentials,
  useLookupRateDifferential,
  useLookupCostType,
} from "dashboard/hooks/atom-hooks";
import { KeyCreatorParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";

import { TimesheetImporter } from "../timesheets/TimesheetImporter";
import { ImportHistory } from "../importer/ImportHistory";
import { numberValidator } from "dashboard/utils/validators";
import { TimesheetGpsComponent } from "dashboard/pages/timesheets/TimesheetGeofenceIndicator";
import { RecodeTimesheetsModal } from "../timesheets/RecodeTimesheetsModal";
import { useApprovalGroupColumns as useApprovalGroupColumns } from "dashboard/utils/approvals";
import { BLANK_SET_FILTER_OPTION } from "ui/table-v2/table-forage-utils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { SplitTimesheetModal } from "dashboard/pages/timesheets/SplitTimesheetModal";
import { useEarningTypeLabeler } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { useKickBackItemsAction } from "../approvals/KickBackItemsAction";
import {
  TimesheetAction,
  useTimesheetAbilities,
} from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { AdvancedBreakTimeHover } from "dashboard/pages/timesheets/AdvancedBreakTimeHover";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";
import { usdString } from "ui";
import { SignOffModal } from "../timesheets/SignOffModal";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";
import pluralize from "pluralize";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

type Props = {
  id?: string;
  defaultFilters: MiterFilterArray;
  showToggler?: boolean;
  showMiniTitle?: boolean;
  rerender?: boolean;
  activeTeamMember?: AggregatedTeamMember;
  activeJob?: AggregatedJob;
  activePayroll?: AggregatedPayroll;
  activeDate?: DateTime;
  defaultSelectedRows?: TimesheetTableEntry[];
  onSelect?: (selectedRows: (TimesheetTableEntry | LiveTimesheetTableEntry)[]) => void;
  togglerConfigFilter?: (path: string) => boolean;
  hasDailyReport?: boolean;
  hideCreateButtons?: boolean;
  hideReportViews?: boolean;
  hideDateRangeFilter?: boolean;
  containerClassName?: string;
  onChange?: () => void;
  secondaryToggler?: boolean;
  doNotAddPayRates?: boolean;
  readOnly?: boolean;
  fetchActionableTimesheets?: (
    params: ForageRequest,
    doNotAddPayRates?: boolean
  ) => Promise<ForageResponse<AggregatedTimesheet>>;
  inboxMode?: InboxMode;
  hideTimezoneFilter?: boolean;
};

const TimesheetsTable: React.FC<Props> = ({
  id,
  defaultFilters,
  showToggler,
  showMiniTitle,
  activeTeamMember,
  activePayroll,
  activeJob,
  activeDate,
  rerender,
  defaultSelectedRows,
  onSelect,
  togglerConfigFilter,
  hideCreateButtons,
  hideReportViews,
  hideDateRangeFilter,
  containerClassName,
  onChange,
  secondaryToggler,
  doNotAddPayRates,
  readOnly,
  fetchActionableTimesheets,
  inboxMode,
  hideTimezoneFilter,
}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const { customFields } = useContext(AppContext);
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const { tsView, action, tsid: modalTimesheet } = parsedSearchParams;
  const bulkEditRef = useRef();
  const cleanTimesheetsFromBackend = useCleanTimesheets();
  const team = useTeam();
  const refetchActionableItems = useRefetchActionableItems();
  const crews = useCrews();
  const lookupTeamMemberCrews = useLookupTeamMemberCrews();
  const lookupRateClassification = useLookupRateClassification();
  const loggedInTeamMember = useActiveTeamMember();
  const lookupPolicy = useLookupPolicy();
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const activeRole = useActiveRole();
  const getApprover = useTimesheetApprover();
  const departmentOptions = useDepartmentOptions();
  const locationOptions = useLocationOptions();
  const { can, cannot } = useMiterAbilities();
  const timesheetAbilities = useTimesheetAbilities({ inboxMode });
  const approvalGroupColumns = useApprovalGroupColumns("timesheet");
  const earningTypeLabeler = useEarningTypeLabeler();
  const lookupWcCode = useLookupWcCode();
  const rateDifferentials = useRateDifferentials();
  const lookupRateDifferential = useLookupRateDifferential();
  const lookupCostTypes = useLookupCostType();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns<TimesheetTableEntry>({ ssr: true });
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState(false);
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [selectedRows, setSelectedRows] = useState<TimesheetTableEntry[]>(defaultSelectedRows || []);
  const [selectFilter, setSelectFilter] = useState<MiterFilterArray>();
  const [refreshCount, setRefreshCount] = useState(0);
  const [lastRefreshedLiveTimesheets, setLastRefreshedLiveTimesheets] = useState<DateTime>();

  // States related to table actions
  const [archive, setArchive] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [isBulkCreating, setIsBulkCreating] = useState(action === "create");
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [modalTsChanged, setModalTsChanged] = useState(false);
  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);
  const [showRecodeModal, setShowRecodeModal] = useState(false);
  const [showSignOffModal, setShowSignOffModal] = useState(false);
  const [timesheetsToSplit, setTimesheetsToSplit] = useState<string[]>([]);

  // Doing a lookup b/c the name on the job option includes the activity code but we don't want that included in the name value for the filter
  const activeJobs = useActiveJobs();
  const jobFilterOptions = useMemo(
    () => activeJobs.map((j) => j.name).concat(BLANK_SET_FILTER_OPTION),
    [activeJobs]
  );
  const settings = activeCompany?.settings?.timesheets;
  const hasEnabledPolicies = !!activeCompany?.settings?.timesheets?.enable_timesheet_policies;
  const breakTypesObject = activeCompany?.settings.timesheets.break_types || {};

  /*
   * Helper variable that determines if we use a filter for a bulk action or _ids
   * - We only want to use the select filter if we are filter by something other than _id
   * - This is because the select filter adds an extra query to get the _ids for the timesheets*
   * - Don't use select filter in inbox mode because the query for timesheets is different and happens in the backend
   */
  const shouldUseSelectFilter = useMemo(() => {
    // If the company setting is set to only select the loaded rows, we should not use the select filter
    if (activeCompany?.settings?.timesheets?.table_select_all_selects_loaded_rows) return false;

    if (!selectFilter || inboxMode) return false;
    if (!Array.isArray(selectFilter)) return false;

    // If select filter is an empty array but exists, this is a select all and we should use
    if (selectFilter.length === 0) return true;

    // If we don't have a select filter value, then we shouldn't use it
    const selectFilterValue = selectFilter[0]?.value;
    if (!selectFilterValue) return false;

    const hasNonIDFilter = selectFilterValue.length > 1 || selectFilterValue[0]?.field !== "_id";
    return hasNonIDFilter;
  }, [selectFilter, inboxMode]);

  /** Builds the table action link for kicking back items */
  const kickBackItemAction = useKickBackItemsAction(selectedRows, "timesheet", () => {
    setSelectedRows([]);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  });

  /*********************************************************
   *  Call useEffect
   **********************************************************/
  useEffect(() => {
    if (showToggler && !tsView) {
      setSearchParams({ tsView: "unapproved" });
    }
  }, [showToggler]);

  useEffect(() => {
    refetchActionableItems();
  }, []);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleRowClick = useCallback(
    (ts: TimesheetTableEntry) => {
      setSearchParams({ tsid: ts._id, tsView });
      setShowBulkEdit(false);
    },
    [tsView]
  );

  const hideModal = useCallback(() => {
    setSearchParams({ tsid: undefined, tsView });
    if (modalTsChanged) setModalTsChanged(false);
  }, [modalTsChanged, tsView]);

  const handleSelect = useCallback((selections: TimesheetTableEntry[]) => {
    setSelectedRows(selections);
    onSelect?.(selections);
  }, []);

  const handleToggle = (newView: string) => {
    handleSelect([]);
    if (showToggler) {
      setSearchParams({ tsView: newView });
    }
  };

  const buildBulkActionParams = (
    action: TimesheetAction,
    extraParams?: { team_member_id?: string; role_id?: string } | { update: UpdateTimesheetParams }
  ) => {
    if (shouldUseSelectFilter) {
      const finalFilter: MiterFilterArray = [];

      // Add default filters
      if (defaultFilters) finalFilter.push(...defaultFilters);

      // Add toggler filter
      const togglerFilter = buildTogglerFilter();
      if (togglerFilter) finalFilter.push(...togglerFilter);

      // Add table select filter
      if (selectFilter) finalFilter.push(...selectFilter);

      // Add the accounts abilities filter
      const abilitiesFilter = timesheetAbilities.filter(action);
      if (abilitiesFilter) finalFilter.push(abilitiesFilter);

      // Add the company filter
      finalFilter.push({ field: "company", value: activeCompanyId });

      return { filter: finalFilter, ...extraParams };
    } else {
      return { ids: selectedRows.map((r) => r._id), ...extraParams };
    }
  };

  const handleSplit = () => {
    setTimesheetsToSplit(selectedRows.map((row) => row._id));
  };

  const handleArchive = async () => {
    setArchiveLoading(true);
    try {
      const params = buildBulkActionParams("delete");
      const responseData = await MiterAPI.timesheets.archive(params);
      if (responseData.error) throw responseData.error;

      Notifier.success("Timesheets successfully archived.");
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was an error archiving timesheets. We're looking into it.");
    }
    setSelectedRows([]);
    refreshTimesheets();
    setArchiveLoading(false);
    setShowBulkEdit(false);
    onChange?.();
    refetchActionableItems();
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      const params = buildBulkActionParams("approve", {
        team_member_id: loggedInTeamMember?._id,
        role_id: activeRole?._id,
      });

      const responseData = await MiterAPI.timesheets.approve(params);
      if (responseData.error) throw responseData.error;

      if ("failures" in responseData && responseData.failures?.length) {
        const failures = responseData.failures.map((f) => ({
          label: "Timesheet",
          message: f.message,
        }));

        setFailures(failures);
        throw new Error("One or more timesheets could not be approved.");
      }

      Notifier.success(`${pluralize("Timesheet", selectedRows.length)} approved.`);
    } catch (e: $TSFixMe) {
      console.log("error", e.message);
      Notifier.error(
        `There was an error approving the ${pluralize(
          "timesheet",
          selectedRows.length
        )}. We're looking into it.`
      );
    }
    setSelectedRows([]);
    refreshTimesheets();
    setLoading(false);
    setShowBulkEdit(false);
    onChange?.();
    refetchActionableItems();
  };

  const handleUnapprove = async () => {
    setLoading(true);
    try {
      const params = buildBulkActionParams("approve", {
        team_member_id: loggedInTeamMember?._id,
        role_id: activeRole?._id,
      });
      const responseData = await MiterAPI.timesheets.unapprove(params);
      if (responseData.error) throw responseData.error;

      if ("failures" in responseData && responseData.failures?.length) {
        const failures = responseData.failures.map((f) => ({
          label: "Timesheet",
          message: f.message,
        }));

        setFailures(failures);
        throw new Error("One or more timesheets could not be unapproved.");
      }

      Notifier.success(`${pluralize("Timesheet", selectedRows.length)} approved.`);
    } catch (e: $TSFixMe) {
      console.log(e.message);
      Notifier.error(
        `There was an error unapproving the ${pluralize(
          "timesheet",
          selectedRows.length
        )}. We're looking into it.`
      );
    }
    setSelectedRows([]);
    refreshTimesheets();
    setLoading(false);
    setShowBulkEdit(false);
    onChange?.();
    refetchActionableItems();
  };

  const handleDuplicate = async () => {
    setDuplicating(true);
    try {
      const params = buildBulkActionParams("create");
      const responseData = await MiterAPI.timesheets.duplicate(params);
      if (responseData.error) throw responseData.error;

      if ("failures" in responseData && responseData.failures?.length) {
        const failures = responseData.failures.map((f) => ({
          label: "Timesheet",
          message: f.error,
        }));

        setFailures(failures);
        throw new Error("One or more timesheets could not be duplicated.");
      }

      Notifier.success("Timesheets successfully duplicated.");
      setSelectedRows([]);
      refreshTimesheets();
      setShowBulkEdit(false);
      onChange?.();
      refetchActionableItems();
    } catch (e: $TSFixMe) {
      console.log(e.message);
      Notifier.error("There was an error duplicating timesheets. We're looking into it.");
    }
    setDuplicating(false);
  };

  const [markAsPaidConfirmation, setMarkAsPaidConfirmation] = useState(false);

  const handleMarkAsPaid = async () => {
    setMarkAsPaidLoading(true);
    try {
      const params = buildBulkActionParams("update");
      const responseData = await MiterAPI.timesheets.markAsPaid(params);

      if (responseData.error) throw responseData.error;
      if (responseData.failures?.length) {
        Notifier.warning(`One or more timesheets could not be updated.`);
      } else {
        Notifier.success("Timesheets successfully updated.");
      }
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was an updating timesheets. We're looking into it.");
    }
    refreshTimesheets();
    setMarkAsPaidConfirmation(false);
    setSelectedRows([]);
    setMarkAsPaidLoading(false);
    setShowBulkEdit(false);
    onChange?.();
    refetchActionableItems();
  };

  const unarchiveTimesheets = async () => {
    setLoading(true);
    try {
      const params = buildBulkActionParams("update", {
        update: { archived: false },
      }) as UpdateManyTimesheetsParams;

      const responseData = await MiterAPI.timesheets.update_many(params);
      if (responseData.error) throw responseData.error;

      Notifier.success("Timesheets successfully restored.");
    } catch (e: $TSFixMe) {
      console.log(e.message);
      Notifier.error("There was an error restoring timesheets. We're looking into it.");
    }
    refreshTimesheets();
    setSelectedRows([]);
    setLoading(false);
    setShowBulkEdit(false);
    onChange?.();
    refetchActionableItems();
  };

  const buildBulkEditPopover = () => {
    const params = buildBulkActionParams("update");
    return (
      <BulkUpdateTimesheetsPopover
        findParams={params}
        show={showBulkEdit}
        setShow={setShowBulkEdit}
        onFinish={onBulkEditFinish}
        ref={bulkEditRef}
      >
        <button className="button-1 table-button" onClick={() => setShowBulkEdit(!showBulkEdit)}>
          <Pencil weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />
          Edit
        </button>
      </BulkUpdateTimesheetsPopover>
    );
  };

  const openBulkCreate = async () => {
    setShowBulkEdit(false);
    setIsBulkCreating(true);
  };

  const onBulkCreateFinish = (tsData: Timesheet[]) => {
    setIsBulkCreating(false);
    setSearchParams({ action: undefined });
    if (tsData.length) {
      refreshTimesheets();
    }
    onChange?.();
    refetchActionableItems();
  };

  const onBulkEditFinish = () => {
    setShowBulkEdit(false);
    setSelectedRows([]);
    refreshTimesheets();
    onChange?.();
    refetchActionableItems();
  };

  const onRecodeSubmit = () => {
    setSelectedRows([]);
    refreshTimesheets();
    onChange?.();
  };

  const handleSignOff = () => {
    if (!selectedRows.length) {
      Notifier.error("Please select timesheets to continue.");
      return;
    }

    if (!hasSignableTimesheets(selectedRows)) {
      Notifier.error("None of the selected timesheets require a signature.");
      return;
    }

    setShowSignOffModal(true);
  };

  const onSignOffSubmit = () => {
    setShowSignOffModal(false);
    setSelectedRows([]);
    refreshTimesheets();
    onChange?.();
    refetchActionableItems();
  };

  const refreshTimesheets = async () => {
    setRefreshCount((prev) => prev + 1);
  };

  const onTimesheetImport = () => {
    refreshTimesheets();
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  const buildTogglerFilter = useCallback(() => {
    if (!showToggler) return [];

    if (tsView && tsStatuses.includes(tsView)) {
      return [{ field: "status", value: tsView }];
    } else if (tsView === "archived") {
      return [
        { field: "archived", comparisonType: "include_archived" as const },
        { field: "archived", value: true },
      ];
    } else if (showToggler && tsView !== "all") {
      return [{ field: "status", value: "unapproved" }];
    } else {
      return [];
    }
  }, [tsView, showToggler]);

  const hasSignableTimesheets = (selectedTimesheets: TimesheetTableEntry[]) => {
    return selectedTimesheets.some((ts) => {
      const policy = lookupPolicy(ts.approval_stage?.policy_id);
      return requiresEsignature(policy);
    });
  };

  const requiresEsignature = (policy: Policy | undefined): boolean => {
    return ((policy?.rules || []) as TimesheetPolicyRule[]).some(
      (rule) => "sign_off" in rule && rule.sign_off?.method === "signature"
    );
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const tableActions = useMemo((): {
    dynamicButtons: TableActionLink[];
    staticButtons: TableActionLink[];
  } => {
    if (inboxMode === "approval") {
      return {
        staticButtons: [
          {
            label: "Approve",
            className: "button-2 table-button",
            action: () => {
              Notifier.warning("Please select the timesheets you want to approve.");
            },
            icon: <Check weight="bold" style={{ marginRight: 3 }} />,
            loading: loading,
            important: true,
          },
        ],
        dynamicButtons: [
          {
            label: "Split",
            className: "button-1 table-button",
            action: handleSplit,
            icon: <Scissors weight="bold" style={{ marginRight: 3 }} />,
            shouldShow: () => timesheetAbilities.can("update", selectedRows),
          },
          ...kickBackItemAction,
          {
            label: "Approve",
            className: "button-2 table-button",
            action: handleApprove,
            icon: <Check weight="bold" style={{ marginRight: 3 }} />,
            loading: loading,
            important: true,
          },
        ],
      };
    }

    if (inboxMode === "needs_attention") {
      return {
        staticButtons: [
          {
            label: "Sign Off",
            className: "button-2 table-button",
            action: handleSignOff,
            icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
            loading: loading,
            important: true,
            shouldShow: () => true,
          },
        ],
        dynamicButtons: [
          {
            label: "Sign Off",
            className: "button-2 table-button",
            action: handleSignOff,
            icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
            loading: loading,
            important: true,
            shouldShow: () => true,
          },
        ],
      };
    }

    const statuses = new Set(selectedRows.map((r) => r.status));
    const showDynamicUnapproved =
      tsView === "unapproved" || (statuses.size === 1 && statuses.has("unapproved"));
    const showDynamicApproved = tsView === "approved" || (statuses.size === 1 && statuses.has("approved"));
    const showRestoreButton = tsView === "archived" || (statuses.size === 1 && statuses.has("archived"));

    // Don't include the `view` check here because we only want to show the recode button if the user has selected specific timesheets
    const showRecodeButton =
      (statuses.size === 2 && statuses.has("paid") && statuses.has("processing")) ||
      (statuses.size === 1 && (statuses.has("paid") || statuses.has("processing")));

    let dynamicButtons: TableActionLink[] = [
      {
        label: "Duplicate",
        className: "button-1 table-button",
        action: handleDuplicate,
        icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
        loading: duplicating,
        shouldShow: () => timesheetAbilities.can("create", selectedRows) || shouldUseSelectFilter,
      },
    ];
    let staticButtons: TableActionLink[] = [];

    if (showDynamicUnapproved) {
      dynamicButtons = dynamicButtons.concat([
        {
          key: "bulk-edit",
          component: buildBulkEditPopover(),
          important: true,
          shouldShow: () => timesheetAbilities.can("update", selectedRows) || shouldUseSelectFilter,
        },
        ...kickBackItemAction,
        {
          label: "Approve",
          className: "button-2 table-button",
          action: handleApprove,
          important: true,
          icon: <Check weight="bold" style={{ marginRight: 3 }} />,
          loading: loading,
          shouldShow: () => timesheetAbilities.can("approve", selectedRows) || shouldUseSelectFilter,
        },
        {
          label: "Split",
          className: "button-1 table-button",
          action: handleSplit,
          icon: <Scissors weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => timesheetAbilities.can("update", selectedRows),
        },
        {
          label: "Archive",
          className: "button-3 table-button",
          action: handleArchive,
          icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
          loading: archiveLoading,
          shouldShow: () => timesheetAbilities.can("delete", selectedRows) || shouldUseSelectFilter,
        },
      ]);
    }

    if (showDynamicApproved) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Mark as paid",
          className: "button-2 table-button",
          action: () => setMarkAsPaidConfirmation(true),
          icon: <Check weight="bold" style={{ marginRight: 3 }} />,
          loading: markAsPaidLoading,
          shouldShow: () => timesheetAbilities.can("update", selectedRows) || shouldUseSelectFilter,
        },
        {
          label: "Unapprove",
          className: "button-3 no-margin table-button",
          action: handleUnapprove,
          icon: <X weight="bold" style={{ marginRight: 3 }} />,
          important: true,
          loading: loading,
          shouldShow: () => timesheetAbilities.can("approve", selectedRows) || shouldUseSelectFilter,
        },
      ]);
    }

    if (showRestoreButton) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Restore",
          className: "button-2 table-button",
          action: unarchiveTimesheets,
          icon: <ArrowClockwise weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => timesheetAbilities.can("update", selectedRows) || shouldUseSelectFilter,
        },
      ]);
    }

    if (showRecodeButton) {
      dynamicButtons = dynamicButtons.concat([
        {
          label: "Recode",
          className: "button-1 table-button",
          action: () => setShowRecodeModal(true),
          icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => timesheetAbilities.can("update", selectedRows) || shouldUseSelectFilter,
        },
      ]);
    }

    if (!hideCreateButtons && !readOnly) {
      staticButtons = staticButtons.concat([
        {
          label: "Create",
          className: "button-2 no-margin",
          action: openBulkCreate,
          icon: <Stack weight="bold" style={{ marginRight: 3 }} />,
          important: true,
          shouldShow: () => can("timesheets:personal:create") || can("timesheets:others:create"),
        },
        {
          key: "import",
          component: <TimesheetImporter onFinish={onTimesheetImport} />,
          shouldShow: () => can("timesheets:personal:create") || can("timesheets:others:create"),
        },
        {
          key: "import-history",
          className: "button-1",
          action: () => setShowImportHistory(true),
          label: "Import history",
          icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
          shouldShow: () => can("timesheets:personal:create") || can("timesheets:others:create"),
        },
      ]);
    }

    return { dynamicButtons, staticButtons };
  }, [
    tsView,
    selectedRows,
    setShowBulkEdit,
    handleApprove,
    handleArchive,
    handleSignOff,
    handleSplit,
    handleUnapprove,
    hideCreateButtons,
    hasEnabledPolicies,
    readOnly,
    inboxMode,
    timesheetAbilities.can,
    timesheetAbilities.cannot,
    can,
    selectFilter,
    kickBackItemAction,
  ]);

  const togglerConfig = useMemo(() => {
    const liveTsConfig: TogglerConfigItem = {
      label: (
        <>
          <FaCircle size={10} style={{ marginRight: 7, marginBottom: 0, color: "green" }} />
          {activeCompany?.settings.timesheets.show_all_team_members_in_live_view ? "Live" : "Clocked-in"}
        </>
      ),
      path: "live",
    };

    const paths = ["unapproved", "approved", "processing", "paid", "all", "archived"];
    const tsConfig: TogglerConfigItem[] = paths.map((v) => ({
      label: capitalize(v),
      path: v,
    }));

    tsConfig.unshift(liveTsConfig);

    if (!togglerConfigFilter) return tsConfig;

    return tsConfig.filter((v) => togglerConfigFilter(v.path));
  }, [togglerConfigFilter, activeCompany]);

  const bodyText = `Archived timesheets will be excluded from the timesheets view. You'll be able to restore them if needed.`;

  const equipmentTrackingColumns: ColumnConfig<TimesheetTableEntry>[] = [
    {
      field: "equipment",
      headerName: "Equipment",
      dataType: "string",
      valueGetter: (params) => params.data?.equipment?.map((e) => e.name).join(", ") || "-",
      minWidth: 200,
    },
  ];
  const finalTimesheetColumns = useMemo(() => {
    const timesheetColumns: ColumnConfig<TimesheetTableEntry>[] = [
      {
        field: "date",
        headerName: "Date",
        dataType: "date",
        dateType: "timestamp",
        minWidth: 150,
        enableRowGroup: true,
        sortField: "clock_in",
        filterField: "clock_in",
        groupField: "clock_in",
        groupFormatter: "seconds",
        valueFormatter: formatTimesheetDate,
        valueGetter: getTimesheetDate,
        cellRenderer: renderTimesheetDate,
        displayRangeFilter: !hideDateRangeFilter,
      },

      {
        field: "status",
        headerName: "Status",
        dataType: "string",
        displayType: "badge",
        colors: {
          unapproved: "light-gray",
          approved: "light-blue",
          processing: "light-green",
          paid: "green",
        },
        minWidth: 120,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["unapproved", "approved", "processing", "paid", "archived"],
        },
      },
      {
        field: "hours",
        headerName: "Hours",
        dataType: "number",
        minWidth: 100,
        aggFunc: "sum",
        valueFormatter: (params) => cleanFloatingPointErrors(params.value) + "",
        validations: numberValidator({ excludeNegatives: true, excludeZero: true }),
      },
      {
        field: "team_member.full_name",
        headerName: "Team member",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        filterParams: { values: team.map((tm) => tm.full_name) },
      },
      {
        field: "team_member.friendly_id",
        headerName: "Team member ID",
        dataType: "string",
        minWidth: 200,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "activity.label",
        useValueFormatterForExport: true,
        headerName: "Activity",
        dataType: "string",
        minWidth: 160,
        enableRowGroup: true,
      },
      {
        field: "activity.cost_code",
        useValueFormatterForExport: true,
        headerName: "Activity code",
        dataType: "string",
        minWidth: 160,
        enableRowGroup: true,
        hide: true,
      },
      {
        field: "cost_type_id",
        useValueFormatterForExport: true,
        headerName: "Cost type",
        dataType: "string",
        minWidth: 160,
        enableRowGroup: true,
        hide: true,
        valueFormatter: (params) => lookupCostTypes(params.data?.cost_type_id)?.label || "-",
      },
      {
        field: "job.name",
        headerName: "Job",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        filter: "agSetColumnFilter", // filterParam added in TimesheetsTable component
      },
      {
        field: "job.code",
        headerName: "Job code",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "job.address.state",
        headerName: "Job state",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "job.cpr_info",
        headerName: "Certified payroll",
        dataType: "boolean",
        valueGetter: (params) => !!params.data?.job?.cpr_info,
        minWidth: 200,
        enableRowGroup: true,
        initialHide: true,
        customFilterBuilder: (filterItem) => {
          const values = filterItem?.values || [];
          if (!values?.length) return;

          if (values.length == 2) return;
          if (values.length == 0) return { field: "_id", value: "null", type: "_id" };

          return { field: "job.cpr_info", comparisonType: "exists", value: values[0] === "✓" };
        },
      },
      {
        field: "clock_in",
        headerName: "In",
        dataType: "date",
        dateType: "timestamp",
        minWidth: 120,
        cellRenderer: (params) => params.data?.clock_in_string || "-",
        valueGetter: (params) => params.data?.clock_in_string || "-",
      },
      {
        field: "clock_out",
        headerName: "Out",
        dataType: "date",
        dateType: "timestamp",
        minWidth: 120,
        cellRenderer: (params) => params.data.clock_out_string || "-",
        valueGetter: (params) => params.data?.clock_out_string || "-",
      },
      {
        field: "rounding_metadata.rounded",
        headerName: "Rounded",
        dataType: "boolean",
        minWidth: 120,
        initialHide: true,
      },
      {
        field: "unpaid_break_time",
        headerName: "Unpaid break",
        dataType: "number",
        minWidth: 120,
        valueFormatter: (params) => readableSeconds(params.value, { abbreviate: true }),
        aggFunc: "sum",
        useValueFormatterForExport: true,
        tooltipValueGetter: (params) =>
          !!params.data?.breaks?.filter((b) => !breakTypesObject[b.break_type_id]?.paid).length,
        tooltipComponent: (params) => <AdvancedBreakTimeHover timesheet={params.data} type="unpaid" />,
      },
      {
        field: "paid_break_time",
        headerName: "Paid break",
        dataType: "number",
        minWidth: 120,
        valueFormatter: (params) => readableSeconds(params.value, { abbreviate: true }),
        initialHide: true,
        aggFunc: "sum",
        useValueFormatterForExport: true,
        tooltipValueGetter: (params) =>
          !!params.data?.breaks?.filter((b) => breakTypesObject[b.break_type_id]?.paid).length,
        tooltipComponent: (params) => <AdvancedBreakTimeHover timesheet={params.data} type="paid" />,
      },
      ...(hasEnabledPolicies ? approvalGroupColumns : []),
      ...(hasAccessToEquipmentTracking ? equipmentTrackingColumns : []),
      {
        field: "has_notes",
        headerName: "Notes",
        dataType: "boolean",
        minWidth: 100,
      },
      {
        field: "notes",
        headerName: "Full notes",
        dataType: "string",
        minWidth: 150,
      },
      {
        field: "has_pics",
        headerName: "Pics",
        dataType: "boolean",
        minWidth: 100,
      },
      {
        field: "injury",
        headerName: "Injured",
        dataType: "boolean",
        minWidth: 100,
        valueGetter: (params) => params.data?.injury || false,
      },
      {
        field: "sign_off.status",
        sortField: "sign_off.status",
        headerName: "Sign off",
        dataType: "boolean",
        minWidth: 120,
        customFilterBuilder: (filterItem) => {
          if (filterItem.values.length === 0) {
            return { field: "created_at", value: "not.exists" };
          } else if (filterItem.values.length === 2) {
            return;
          } else if (filterItem.values[0] === "✓") {
            return { field: "sign_off.status", value: "completed" };
          } else {
            return {
              type: "or",
              value: [
                { field: "sign_off.status", value: "missing" },
                { field: "sign_off.status", value: "not.exists" },
              ],
            };
          }
        },
        valueGetter: (params) => {
          if (!params.data?.sign_off) return;
          return params.data.sign_off.status === "completed";
        },
        tooltipValueGetter: (params) => {
          if (!params.data?.sign_off?.timestamp) return;
          return DateTime.fromSeconds(params.data.sign_off?.timestamp).toLocaleString(DateTime.DATETIME_MED);
        },
      },
      {
        field: "creation_method",
        headerName: "Creation method",
        dataType: "string",
        displayType: "badge",
        minWidth: 155,
        colors: {
          "Admin entry": "green",
          "App manual entry": "gray",
          "App clock in": "blue",
          Kiosk: "light-purple",
          "Bulk import": "light-green",
          "Dashboard clock in": "light-blue",
        },
        enableRowGroup: true,
        initialHide: true,
        filter: false,
        sortable: false,
        valueGetter: (params) => creationMethodBadgeLookup?.[params.data?.creation_method || ""]?.label || "",
      },
      {
        field: "classification",
        headerName: "Classification",
        dataType: "string",
        minWidth: 155,
        initialHide: true,
        filter: false,
        sortable: false,
      },
      {
        field: "prg",
        headerName: "Pay rate group",
        dataType: "string",
        minWidth: 155,
        initialHide: true,
        filter: false,
        sortable: false,
      },
      {
        field: "created_by_identifier",
        dataType: "string",
        headerName: "Created by",
        initialHide: true,
        minWidth: 150,
      },
      {
        field: "approver",
        headerName: "Approver",
        enableRowGroup: true,
        initialHide: true,
        dataType: "string",
        filter: false,
        sortable: false,
        valueGetter: (params) => getApprover(params.data),
      },
      {
        field: "department.name",
        headerName: "Department",
        enableRowGroup: true,
        dataType: "string",
        filter: "agSetColumnFilter",
        filterParams: {
          values: departmentOptions,
          keyCreator: (option: KeyCreatorParams<TimesheetTableEntry>) => option.value?.label,
          valueFormatter: (params: ValueFormatterParams) => params.value?.label,
        },
      },
      {
        field: "location.name",
        headerName: "Location",
        enableRowGroup: true,
        dataType: "string",
        filter: "agSetColumnFilter",
        filterParams: {
          values: locationOptions,
          keyCreator: (option: KeyCreatorParams<TimesheetTableEntry>) => option.value?.label,
          valueFormatter: (params: ValueFormatterParams) => params.value?.label,
        },
      },
      {
        field: "earning_type",
        headerName: "Earning type",
        enableRowGroup: true,
        initialHide: true,
        filter: false,
        valueGetter: (params: ValueGetterParams<TimesheetTableEntry>) => earningTypeLabeler(params.data),
        dataType: "string",
      },
      {
        field: "modified",
        headerName: "Edited",
        enableRowGroup: true,
        initialHide: true,
        filter: false,
        sortable: false,
        headerTooltip: "Indicates if the timesheet has been edited since it was created.",
        valueGetter: (params: ValueGetterParams<TimesheetTableEntry>) =>
          params.data?.created_at !== params.data?.last_updated_at,
        dataType: "boolean",
      },
      {
        field: "date_local",
        headerName: "Date (Local)",
        dataType: "date",
        dateType: "timestamp",
        minWidth: 150,
        filter: false,
        sortable: false,
        initialHide: true,
        valueGetter: (params) => {
          if (!params.data?.clock_in) return;

          const date = DateTime.fromSeconds(params.data?.clock_in, { zone: params.data.timezone });
          return date.toISODate();
        },
      },
      {
        field: "clock_in_local",
        headerName: "In (Local)",
        dataType: "date",
        dateType: "timestamp",
        minWidth: 120,
        filter: false,
        sortable: false,
        initialHide: true,
        cellRenderer: (params) => params.data.clock_in_local_string || "-",
        valueGetter: (params) => params.data?.clock_in_local_string || "-",
      },
      {
        field: "clock_out_local",
        headerName: "Out (Local)",
        dataType: "date",
        dateType: "timestamp",
        minWidth: 120,
        filter: false,
        sortable: false,
        initialHide: true,
        cellRenderer: (params) => params.data.clock_out_local_string || "-",
        valueGetter: (params) => params.data?.clock_out_local_string || "-",
      },
      {
        field: "timezone",
        headerName: "Timezone",
        enableRowGroup: true,
        initialHide: true,
        dataType: "string",
        filter: "agSetColumnFilter",
        filterParams: {
          values: timezoneOptions,
          keyCreator: (option: KeyCreatorParams<TimesheetTableEntry>) => option.value?.value,
          valueFormatter: (params: ValueFormatterParams) => params.value?.label,
        },
        cellRenderer: (params) => lookupTimezoneLabel(params.data.timezone) || "-",
      },
      {
        field: "team_member.wc_code",
        headerName: "Workers comp code",
        initialHide: true,
        dataType: "string",
        filter: false,
        sortable: false,
        valueGetter: (params) => {
          let wcCodeId: string | null | undefined;
          const timesheetCode = params.data?.wc_code;
          const aCode = params.data?.activity?.wc_code,
            rId = params.data?.pay_rate?.union_rate_reference?.rate_id;
          if (timesheetCode) {
            wcCodeId = timesheetCode;
          } else if (aCode) {
            wcCodeId = aCode;
          } else if (rId) {
            wcCodeId = lookupRateClassification(rId)?.wc_code;
          }
          if (!wcCodeId) {
            wcCodeId = params.data?.team_member?.wc_code || undefined;
          }
          return lookupWcCode(wcCodeId)?.label || "-";
        },
      },
      {
        field: "has_clock_in_photo",
        headerName: "Clock in photo",
        dataType: "boolean",
        minWidth: 100,
      },
      {
        field: "has_clock_out_photo",
        headerName: "Clock out photo",
        dataType: "boolean",
        minWidth: 100,
      },
      {
        field: "team_member.pay_type",
        headerName: "Pay type",
        dataType: "string",
        minWidth: 120,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        valueFormatter: (params) => capitalize(params.value),
        filterParams: {
          values: [
            { value: "hourly", label: "Hourly" },
            { value: "salary", label: "Salary" },
          ],
          keyCreator: (option: KeyCreatorParams<TimesheetTableEntry>) => option.value?.value,
          valueFormatter: (params: ValueFormatterParams) => params.value?.label,
        },
      },
    ];

    const baseColumns = timesheetColumns.slice() || [];

    const customFieldColumns: ColumnConfig<TimesheetTableEntry>[] = customFields
      .filter((cf) => cf.parent_type === "timesheet")
      .map((cf) => {
        return {
          headerName: cf.name,
          field: "custom_field." + cf._id,
          minWidth: 120,
          dataType: customFieldAgGridTypeMap[cf.type] as ColumnConfig<TimesheetTableEntry>["dataType"],
          aggFunc: cf.type === "number" ? "sum" : undefined,
          enableRowGroup: true,
        };
      });

    if (activeCompany?.settings.timesheets.geolocation) {
      customFieldColumns.push({
        field: "geofence_status_string",
        headerName: "GPS",
        dataType: "component",
        headerTooltip: "Indicates the timesheet's geofence status, if applicable.",
        minWidth: 120,
        cellRenderer: ({ data }) => {
          return <TimesheetGpsComponent ts={data} />;
        },
      });
    }

    if (activeCompany?.settings.timesheets.break_name_into_first_and_last && !activeTeamMember) {
      // Hide team_member.full_name column if we're showing first and last name columns and the team_member.full_name column is not present
      const teamMemberColumnIndex = baseColumns.findIndex((c) => c.field === "team_member.full_name");
      if (teamMemberColumnIndex > -1) {
        baseColumns[teamMemberColumnIndex] = {
          ...(baseColumns[teamMemberColumnIndex] as $TSFixMe),
          hide: true,
        } as ColumnConfig<TimesheetTableEntry>;
      }

      baseColumns.splice(2, 0, {
        field: "team_member.first_name",
        headerName: "First name",
        dataType: "string",
        minWidth: 100,
        sortField: "team_member.full_name",
      });

      baseColumns.splice(3, 0, {
        field: "team_member.last_name",
        headerName: "Last name",
        dataType: "string",
        minWidth: 120,
      });
    }

    // Add job option parameters
    const jobColumnIndex = baseColumns.findIndex((c) => c.field === "job.name");
    baseColumns[jobColumnIndex] = {
      ...(baseColumns[jobColumnIndex] as $TSFixMe),
      filterParams: {
        buttons: ["apply", "reset"],
        values: jobFilterOptions,
      },
    } as ColumnConfig<TimesheetTableEntry>;

    // Remove job column if active job is set
    if (activeJob) {
      const jobColumnIndex = baseColumns.findIndex((c) => c.field === "job.name");
      if (jobColumnIndex > -1) baseColumns.splice(jobColumnIndex, 1);
    }

    // Remove team member column if active team member is set
    if (activeTeamMember) {
      const teamMemberColumnIndex = baseColumns.findIndex((c) => c.field === "team_member.full_name");
      if (teamMemberColumnIndex > -1) baseColumns.splice(teamMemberColumnIndex, 1);
    }

    if (can("timesheets:others:read_sensitive") || can("timesheets:personal:read_sensitive")) {
      baseColumns.push({
        field: "pay_rate.rates.reg",
        headerName: "Reg pay rate",
        dataType: "number",
        minWidth: 150,
        valueGetter: (params) => {
          try {
            return timesheetAbilities.can("read_sensitive", params.data)
              ? params.data?.pay_rate?.rates?.reg
              : null;
          } catch (e) {
            console.log(e);
            return null;
          }
        },
        valueFormatter: (params) => (params.value != null ? usdString(Number(params.value)) : "-"),
        sortable: false,
        filter: false,
      });
      if (rateDifferentials.length) {
        baseColumns.push({
          field: "rate_differential_id",
          headerName: "Rate differential",
          dataType: "string",
          minWidth: 150,
          valueGetter: (params) => {
            const timesheetRateDiff = params.data?.rate_differential_id;
            const activityRateDiff = params.data?.activity?.rate_differential_id;
            const teamMemberRateDiff = params.data?.team_member?.rate_differential_id;
            const rateDiffId = timesheetRateDiff || activityRateDiff || teamMemberRateDiff;
            return rateDiffId ? lookupRateDifferential(rateDiffId)?.label : "-";
          },
          sortable: false,
          filter: false,
        });
      }
    }

    if (activeCompany?.settings.timesheets.enable_geolocation_mileage) {
      baseColumns.push({
        field: "geolocation.distance_traveled",
        headerName: "Distance traveled",
        dataType: "number",
        minWidth: 150,
        valueFormatter: (params) => params.data?.distance_traveled_miles + " miles",
      });
    }

    // If there are any crews for this company, add the crew column
    if (crews.length > 0) {
      baseColumns.push({
        headerName: "Crews",
        field: "crews",
        filterField: "team_member",
        dataType: "string",
        filter: "agSetColumnFilter",
        suppressForageSearch: true,
        filterParams: {
          suppressAndOrCondition: true,
          buttons: ["apply", "reset"],
          values: crews,
          keyCreator: (option: KeyCreatorParams<TimesheetTableEntry>) => option.value.team_member_ids,
          valueFormatter: (params: ValueFormatterParams) => params.value?.name,
          valueGetter: (filter: string[][]) => filter.flat(),
        },
        valueGetter: (params) => {
          const teamMemberId = params.data?.team_member?._id;
          const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);

          return teamMemberCrews?.map((crew) => crew.name).join(", ");
        },
      });
    }

    return baseColumns.concat(customFieldColumns).concat(jobHierarchyTableColumns);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore There are some upstream type issues that need to be resolved
  }, [
    loggedInTeamMember,
    customFields,
    activeTeamMember,
    team,
    crews,
    lookupTeamMemberCrews,
    jobFilterOptions,
    departmentOptions,
    locationOptions,
    getApprover,
    approvalGroupColumns,
    earningTypeLabeler,
    lookupWcCode,
    lookupRateClassification,
    lookupCostTypes,
    jobHierarchyTableColumns,
  ]);

  const getData = useCallback(
    async (query: ForageRequest) => {
      const filter = (query.filter || []).concat([{ field: "company", value: activeCompanyId }]);

      // Add the scope and abilities filters if we are not in the inbox
      if (!inboxMode) {
        // If we can't read others' timesheets, then we should only show the current user's timesheets
        const abilitiesFilter = timesheetAbilities.filter("read");
        if (abilitiesFilter) filter.push(abilitiesFilter);
      }

      if (defaultFilters) filter.push(...defaultFilters);

      const togglerFilter = buildTogglerFilter();
      if (togglerFilter) filter.push(...togglerFilter);

      const sort = query.sort || [];
      if (!query.sort?.find((s) => s.field === "clock_in")) {
        sort.push({ field: "clock_in", direction: -1 });
      }

      const select = query.select || [];
      if (!query.group?.length) {
        select.push(
          { field: "geolocation", show: true },
          { field: "creation_method", show: true },
          { field: "job.geolocation", show: true },
          { field: "job_hierarchy_ids", show: true },
          { field: "pay_rate", show: true },
          { field: "team_member", show: true },
          { field: "company", show: true },
          { field: "status", show: true }
        );
      }

      // If we're on the approvals page, we want to fetch all timesheets that are approvable
      const forageFunc = fetchActionableTimesheets || MiterAPI.timesheets.forage;

      const res = await forageFunc({ ...query, filter, sort, select }, doNotAddPayRates);

      const finalData = !query.group?.length
        ? cleanTimesheetsFromBackend(res.data, query.timezone)
        : res.data;

      return { ...res, data: finalData };
    },
    [
      JSON.stringify(defaultFilters),
      tsView,
      loggedInTeamMember,
      rerender,
      fetchActionableTimesheets,
      can,
      cannot,
      timesheetAbilities.filter,
      inboxMode,
    ]
  );

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderTable = () => {
    if (tsView === "live") return;

    return (
      <TableV2
        id={id || "timesheets-table"}
        resource="timesheets"
        columns={finalTimesheetColumns}
        onSelect={readOnly ? undefined : handleSelect}
        staticActions={tableActions.staticButtons}
        dynamicActions={tableActions.dynamicButtons}
        showReportViews={!hideReportViews}
        onClick={handleRowClick}
        defaultSelectedRows={selectedRows}
        ssr={true}
        getData={getData}
        refreshCount={refreshCount}
        wrapperClassName="base-ssr-table"
        containerClassName={containerClassName || "timesheets-table-container"}
        onSelectFilter={setSelectFilter}
        hideSelectedCount={shouldUseSelectFilter}
        showTimezoneDropdown={settings?.show_timezone_selector && !hideTimezoneFilter}
        groupExpandOnSelect={activeCompany?.settings.timesheets?.expand_table_groups_on_select || !!inboxMode}
      />
    );
  };

  const renderLiveTimesheetsTable = () => {
    if (tsView !== "live") return;

    return (
      <LiveTimesheetsTable
        activeTeamMember={activeTeamMember?._id}
        activeJob={activeJob?._id}
        setLastRefreshed={setLastRefreshedLiveTimesheets}
      />
    );
  };

  const renderArchiveModal = () => {
    return (
      <BasicModal
        headerText="Are you sure you want to archive the selected timesheets?"
        bodyText={bodyText}
        button1Text="Cancel"
        button2Text="Archive"
        button1Action={() => setArchive(false)}
        button2Action={() => handleArchive()}
        loading={archiveLoading}
      />
    );
  };

  const renderTimesheetModal = () => {
    if (!modalTimesheet) return;

    return (
      <TimesheetModal
        hide={hideModal}
        doNotAddPayRates={doNotAddPayRates}
        id={modalTimesheet}
        readOnly={readOnly}
        setShowSplitModal={() => setTimesheetsToSplit([modalTimesheet])}
        inboxMode={inboxMode}
        handleChange={() => {
          setModalTsChanged(true);
          refreshTimesheets();
          onChange?.();
          refetchActionableItems();
        }}
      />
    );
  };

  const renderToggler = () => {
    if (!showToggler) return;

    const lastRefreshedLiveTimesheetsElement = (
      <span style={{ opacity: 0.5 }}>
        Last refreshed at: {lastRefreshedLiveTimesheets?.toLocaleString(DateTime.TIME_SIMPLE)}
      </span>
    );

    return (
      <Toggler
        active={tsView || "unapproved"}
        toggle={handleToggle}
        config={togglerConfig}
        secondary={!!(activeJob || activeTeamMember || activePayroll || secondaryToggler)}
        rightHandContent={tsView === "live" ? lastRefreshedLiveTimesheetsElement : undefined}
      />
    );
  };

  return (
    <div className="timesheets-table-wrapper">
      {isBulkCreating && (can("timesheets:personal:create") || can("timesheets:others:create")) && (
        <BulkCreateTimesheets
          onFinish={onBulkCreateFinish}
          activeTeamMember={activeTeamMember}
          activeJob={activeJob}
          activeDate={activeDate}
        />
      )}

      {showMiniTitle && <h2 style={{ marginTop: 25, marginBottom: 10 }}>Timesheets</h2>}
      {markAsPaidConfirmation && (
        <BasicModal
          headerText="Are you sure?"
          yellowBodyText={true}
          bodyText="Are you sure you want to manually mark these timesheets as paid? Once you do so, the timesheets will no longer be editable, and they won't be included in any payrolls. This action cannot be undone."
          onHide={() => setMarkAsPaidConfirmation(false)}
          button1Text="Cancel"
          button2Text="Mark as Paid"
          button2Action={handleMarkAsPaid}
          loading={loading}
          button1Action={() => setMarkAsPaidConfirmation(false)}
        />
      )}
      {timesheetsToSplit.length ? (
        <SplitTimesheetModal
          timesheetIds={timesheetsToSplit}
          onHide={() => setTimesheetsToSplit([])}
          onSubmit={() => {
            refreshTimesheets();
            setTimesheetsToSplit([]);
          }}
        />
      ) : null}
      {renderToggler()}
      {renderTable()}
      {showRecodeModal && (
        <RecodeTimesheetsModal
          selectedTimesheetIds={selectedRows.map((ts) => ts._id)}
          onSubmit={onRecodeSubmit}
          hide={() => setShowRecodeModal(false)}
        />
      )}
      {showSignOffModal && (
        <SignOffModal
          selectedTimesheets={selectedRows}
          onSubmit={onSignOffSubmit}
          hide={() => setShowSignOffModal(false)}
        />
      )}
      {renderLiveTimesheetsTable()}
      {archive && renderArchiveModal()}
      {modalTimesheet && renderTimesheetModal()}
      {showImportHistory && (
        <ImportHistory
          id={"timesheets"}
          resource={"timesheets"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
      {renderFailuresModal()}
    </div>
  );
};

export default TimesheetsTable;

/*********************************************************
 * Helper functions to format timesheet dates
 *********************************************************/
export const formatTimesheetDate = (params: ValueFormatterParams<TimesheetTableEntry>): string => {
  if (params.data?.clock_in_object) {
    return params.data?.clock_in_object?.toFormat("DD") || "-";
  } else {
    // _id is an ISO Date when we group by date
    if (!params.data || !DateTime.fromISO(params.data._id).isValid) {
      return "-";
    }

    return DateTime.fromISO(params.data._id).toFormat("EEE, LLL dd, yyyy");
  }
};

export const getTimesheetDate = (params: ValueGetterParams<TimesheetTableEntry>): string => {
  if (params.data?.clock_in_object) {
    return params.data?.clock_in_object?.toISODate() || "-";
  } else {
    // _id is an ISO Date when we group by date
    return params.data?._id || "-";
  }
};

export const renderTimesheetDate = (params: ValueFormatterParams<TimesheetTableEntry>): string => {
  if (params.data?.clock_in_object) {
    return params.data?.clock_in_object?.toFormat("EEE, LLL dd, yyyy") || "-";
  } else {
    if (!DateTime.fromISO(params.value).isValid) {
      return "-";
    }

    return DateTime.fromISO(params.value).toFormat("EEE, LLL dd, yyyy");
  }
};

const tsStatuses = ["unapproved", "approved", "processing", "paid"];
