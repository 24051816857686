import { MiterAPI, ChatProfileData } from "dashboard/miter";
import { formatPhoneNumber, Notifier } from "dashboard/utils";
import { truncate } from "lodash";
import React, { useEffect, useState, FC } from "react";
import { FaArrowCircleRight, FaEnvelope, FaExternalLinkSquareAlt, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Loader } from "ui";
import styles from "../Chat.module.css";
import { useActiveTeamChatConversation } from "dashboard/hooks/atom-hooks";

const TeamChatProfile: FC = ({}) => {
  /**********************************************************************************
   *  Hooks and states
   **********************************************************************************/
  const activeTeamConversation = useActiveTeamChatConversation();

  const [loading, setLoading] = useState(false);
  const [teamMember, setTeamMember] = useState<ChatProfileData>();

  useEffect(() => {
    if (!activeTeamConversation?._id) return;
    getTeamMemberData();
  }, [activeTeamConversation?._id]);

  /**********************************************************************************
   *  Get team member information
   **********************************************************************************/
  const getTeamMemberData = async () => {
    setLoading(true);
    if (!activeTeamConversation?.team_member) return;
    try {
      const response = await MiterAPI.team_member.retrieve_chat_profile(activeTeamConversation.team_member);
      if (response.error) throw new Error(response.error);

      setTeamMember(response);
    } catch (e: $TSFixMe) {
      Notifier.error("We are unable to access chat  at this time. Please reach out to support@miter.com");
      console.log(e);
    }
    setLoading(false);
  };

  /**********************************************************************************
   *  Render functions
   **********************************************************************************/
  const renderHeader = () => {
    const tmPath = "/team-members/" + activeTeamConversation?.team_member;

    return (
      <div className={styles["chat-subheader"] + " " + styles["conversation-profile-subheader"]}>
        <h2 className={styles["chat-subheader-title"]}>Profile</h2>
        <Link target="_blank" to={tmPath} className={styles["team-member-link"]}>
          <FaExternalLinkSquareAlt className={styles["team-member-link-icon"]} />
        </Link>
      </div>
    );
  };

  const renderTeamMemberData = () => {
    if (!teamMember || loading) return;

    return (
      <div className={styles["team-member-data"]}>
        <h3 className={styles["team-member-name"]}>{teamMember.full_name}</h3>
        <p className={styles["team-member-job-title"]}>{teamMember.title}</p>

        {(teamMember.email || teamMember.phone) && (
          <h3 className={styles["team-member-data-subtitle"]}>Contact</h3>
        )}

        {teamMember.phone && (
          <a
            target="_blank"
            href={"tel:" + teamMember.phone}
            className={styles["team-member-phone"]}
            rel="noreferrer"
          >
            <FaPhone className={styles["team-member-phone-icon"]} />
            {formatPhoneNumber(teamMember.phone)}
          </a>
        )}

        {teamMember.email && (
          <a
            target="_blank"
            href={"mailto:" + teamMember.email}
            className={styles["team-member-email"]}
            rel="noreferrer"
          >
            <FaEnvelope className={styles["team-member-email-icon"]} />
            {truncate(teamMember.email, { length: 22 })}
          </a>
        )}

        {teamMember.department && (
          <>
            <h3 className={styles["team-member-data-subtitle"]}>Department</h3>
            <p className={styles["team-member-department"]}>{teamMember.department?.name}</p>
          </>
        )}

        {teamMember.reports_to && (
          <>
            <h3 className={styles["team-member-data-subtitle"]}>Supervisor</h3>
            <p className={styles["team-member-supervisor"]}>{teamMember.reports_to.full_name}</p>
          </>
        )}
      </div>
    );
  };

  const renderTeamMemberJobs = () => {
    if (!teamMember || loading) return;

    const hasJobs = teamMember.jobs.length > 0;

    const jobElements = teamMember.jobs.map((job) => (
      <div className={styles["team-member-job"]} key={job._id}>
        <Link target="_blank" to={"/jobs/" + job._id} className={styles["team-member-job-link"]}>
          <span className={styles["team-member-job-name"]}>{truncate(job.name, { length: 20 })}</span>
          <FaArrowCircleRight className={styles["team-member-job-link-icon"]} />
        </Link>
      </div>
    ));

    return (
      <div className={styles["team-member-jobs"]}>
        <h3 className={styles["team-member-jobs-subtitle"]}>Active Jobs</h3>
        {hasJobs ? (
          <div className={styles["job-list"]}> {jobElements}</div>
        ) : (
          <p className={styles["team-member-jobs-empty"]}>No active jobs</p>
        )}
      </div>
    );
  };

  return (
    <div className={styles["conversations-profile-container"]}>
      {renderHeader()}
      {loading && <Loader />}
      {renderTeamMemberData()}
      {renderTeamMemberJobs()}
    </div>
  );
};

export default TeamChatProfile;
