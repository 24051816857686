import React, { useEffect, useState } from "react";
import { Formblock } from "ui";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { Option } from "ui/form/Input";
import { ApprenticeshipProgram } from "dashboard/miter";
import { useActiveCompany, useActiveCprJobOptions } from "dashboard/hooks/atom-hooks";
import { JobInput } from "dashboard/components/shared/JobInput";

type Props = {
  form: $TSFixMe;
  programs: ApprenticeshipProgram[];
  allFormData: $TSFixMe;
};

const getDedupedOptions = (arr, field): Option<string>[] => {
  const all = arr.map((item) => item[field]);
  const deduped = [...new Set(all)];
  const options: Option<string>[] = [];
  for (const item of deduped) {
    if (typeof item !== "string") continue;
    options.push({
      label: item,
      value: item,
    });
  }
  return options;
};

export const BackgroundForm: React.FC<Props> = ({ form, programs, allFormData }) => {
  const activeCompany = useActiveCompany();
  const company_name = activeCompany?.check_company.trade_name;
  const jobOptions = useActiveCprJobOptions();

  useEffect(() => {
    form.setValue("job", allFormData?.background?.job);
    form.setValue("county", allFormData?.background?.county);
    form.setValue("occupation", allFormData?.background?.occupation);
  }, []);

  const defaultStartDate = allFormData.background?.start_date
    ? cleanDate(allFormData.background?.start_date)
    : DateTime.now();

  const defaultEndDate = allFormData.background?.end_date
    ? cleanDate(allFormData.background?.end_date)
    : undefined;

  const [startDate, setStartDate] = useState<DateTime | undefined>(defaultStartDate);
  const handleStartDateChange = (dt) => {
    form.setValue("start_date", dt);
    setStartDate(dt);
  };

  const [endDate, setEndDate] = useState<DateTime | undefined>(defaultEndDate);
  const handleEndDateChange = (dt) => {
    form.setValue("end_date", dt);
    setEndDate(dt);
  };

  const trainingOptions = [
    {
      label:
        "We are already approved to train apprentices by this Apprenticeship Committee. We will employ and train under their Standards.",
      value: "already_approved",
    },
    {
      label:
        "We will comply with the standards of this Apprenticeship Committee for the duration of this job only.",
      value: "will_comply",
    },
    {
      label:
        "We will employ and train apprentices in accordance with the California Apprenticeship Council regulations, including 230.1 (c) which requires that apprentices employed on public projects can only be assigned to perform work of the craft or trade to which the apprentice is registered and that the apprentices must at all times work with or under the direct supervision of journeyman/men.",
      value: "employ_train",
    },
  ];

  const countyOptions: Option<string>[] = getDedupedOptions(programs, "county");
  const occupationOptions = getDedupedOptions(programs, "occupation");

  return (
    <div style={{ maxWidth: 700 }}>
      {(!activeCompany!.phone || !activeCompany!.ca_info?.license_number) && (
        <div>
          <div className="bold">Your company</div>
          <div className="vertical-spacer"></div>
          <div style={{ maxWidth: 700 }}>
            <Formblock
              label="Phone number"
              register={form.register(vals.phone)}
              type="text"
              name="phone"
              errors={form.errors}
              defaultValue={activeCompany!.phone}
              editing={true}
            />
            <Formblock
              label="State license number"
              register={form.register(vals.required)}
              type="text"
              defaultValue={activeCompany!.ca_info?.license_number}
              name="license_num"
              errors={form.errors}
              editing={true}
            />
            <div className="vertical-spacer"></div>
            <div className="bold">Job and apprentice information</div>
            <div className="vertical-spacer"></div>
          </div>
        </div>
      )}
      <div style={{ maxWidth: 700 }}>
        <JobInput
          type="select"
          name="job"
          form={form}
          register={form.register(vals.required)}
          control={form.control}
          defaultValue={allFormData?.background?.job?.value}
          label="Job"
          errors={form.errors}
          editing={true}
          options={jobOptions}
        />
      </div>
      <Formblock
        name="county"
        type="select"
        options={countyOptions}
        defaultValue={allFormData?.background?.county?.value}
        label="County"
        control={form.control}
        editing={true}
        errors={form.errors}
        requiredSelect={true}
      />
      <Formblock
        name="occupation"
        type="select"
        options={occupationOptions}
        label="Occupation"
        defaultValue={allFormData?.background?.occupation?.value}
        control={form.control}
        editing={true}
        errors={form.errors}
        requiredSelect={true}
      />
      <Formblock
        label="Journeyman hours"
        register={form.register(vals.required)}
        type="text"
        defaultValue={allFormData?.background?.journeyman_hours}
        name="journeyman_hours"
        errors={form.errors}
        editing={true}
      />
      <Formblock
        label="Apprentice hours"
        register={form.register(vals.isNumber)}
        type="text"
        name="apprentice_hours"
        defaultValue={allFormData?.background?.apprentice_hours}
        errors={form.errors}
        editing={true}
      />
      <Formblock
        type="datetime"
        name="start_date"
        control={form.control}
        label="Start date"
        rules={vals.required}
        labelInfo="Start date of apprentice employment"
        errors={form.errors}
        defaultValue={startDate}
        onChange={handleStartDateChange}
        editing={true}
        dateOnly={true}
        max={endDate}
      />
      <Formblock
        name="end_date"
        type="datetime"
        control={form.control}
        dateOnly={true}
        rules={vals.required}
        label="End date"
        labelInfo="End date of apprentice employment"
        errors={form.errors}
        onChange={handleEndDateChange}
        editing={true}
        min={startDate}
      />
      <div className="vertical-spacer-large"></div>
      <div className="bold">{`${company_name}'s training status`}</div>
      <div className="vertical-spacer"></div>
      <Formblock
        type="radio"
        name="training_status"
        register={form.register(vals.required)}
        className="flow black"
        errors={form.errors}
        defaultValue={allFormData?.background?.training_status}
        editing={true}
        options={trainingOptions}
      />
    </div>
  );
};

const cleanDate = (date: string | DateTime | undefined) => {
  try {
    if (!date) return undefined;
    if (typeof date === "string") return DateTime.fromFormat(date, "DD");
    return date;
  } catch (e) {
    console.log("Error parsing date", e, date);
    return undefined;
  }
};
