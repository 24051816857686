import React, { useEffect, useMemo, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock, Label } from "ui";
import {
  EXPENSE_REIMBURSEMENT_EDIT_MODE,
  ExpenseReimbursementModalFormData,
} from "./ExpenseReimbursementModalForm";
import * as vals from "dashboard/utils/validators";
import { roundTo } from "miter-utils";
import { MileageLocation } from "dashboard/miter";
import { isValidTrip } from "../expenseUtils";
import { StopBasedMileageEditor } from "dashboard/components/expense-reimbursements/StopBasedMileageEditor";

type Props = {
  editingMode?: EXPENSE_REIMBURSEMENT_EDIT_MODE;
  mileageRate: number;
  mileageTrip?: MileageLocation[];
  form: UseFormMethods<ExpenseReimbursementModalFormData>;
};
export const ExpenseReimbursementMileageDataFields: React.FC<Props> = ({
  editingMode,
  mileageRate,
  mileageTrip,
  form,
}) => {
  const { watch, register, errors, setValue } = form;
  const formData = watch();

  const [currentTrip, setCurrentTrip] = useState<MileageLocation[] | undefined>(mileageTrip);
  const calculatedMileageReimbursement = useMemo(() => {
    return roundTo(mileageRate * (formData?.mileage || 0)).toFixed(2);
  }, [mileageRate, formData]);

  const tripExists = isValidTrip(currentTrip);

  // syncs currentTrip to formdata
  useEffect(() => {
    setValue("trip", currentTrip);
  }, [currentTrip]);

  return (
    <>
      <h4>Mileage Information</h4>
      <StopBasedMileageEditor
        editingMode={editingMode}
        mileageTrip={mileageTrip}
        setMileage={(mileage: number) => setValue("mileage", mileage)}
        onTripUpdate={setCurrentTrip}
      />
      <Formblock
        type="number"
        name="mileage"
        label={"Miles*"}
        className="double time-off-request-date"
        register={register(
          vals.numberValidator({
            required: true,
            excludeNegatives: true,
            excludeZero: true,
            maxDecimals: 2,
          })
        )}
        errors={errors}
        editing={editingMode === "all_fields"}
        defaultValue={formData?.mileage}
        readOnly={tripExists}
      />
      <Label
        label={`Amount reimbursed: $${calculatedMileageReimbursement}`}
        labelInfo={
          `Calculated using $${mileageRate} / mile mileage rate.` +
          `${formData?.is_taxable?.value ? " This rate is taxable." : ""}`
        }
        underlineTooltip={true}
      />
    </>
  );
};
