import React, { useEffect, useRef } from "react";
import { Formblock, Label, Notifier, WizardScreen, usdString } from "ui";
import styles from "./TeamMemberWizard.module.css";
import {
  Company,
  FormAddress,
  MiterAPI,
  TMTimeOffPolicy,
  TeamMember,
  UpdateTeamMemberParams,
} from "dashboard/miter";
import {
  useActiveCompany,
  useActiveCompanyId,
  useClassificationOptions,
  useHolidayScheduleOptions,
  useLookupRateClassification,
  useLookupTimeOffPolicy,
  usePayScheduleOptions,
  usePrgOptions,
  usePrgs,
  useRefetchTeam,
  useTimeOffPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { isEmptyAddress, isValidAddress, sleep, states } from "dashboard/utils";
import { DateTime } from "luxon";
import * as vals from "dashboard/utils/validators";
import { Option } from "ui/form/Input";
import {
  convertAnnualRateToDisplayRate,
  convertDisplayRateToAnnualRate,
} from "dashboard/pages/team-members/TeamUtils";
import { WizardTeamMember } from "./TeamMemberWizard";
import { formatToAddress, salaryRateOptions } from "miter-utils";
import { useOtExemptUtils } from "dashboard/hooks/useOtExempt";
import { assignDefaultLevel, levelComputedStartDates } from "dashboard/utils/policies/time-off-policy-utils";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
  mode: "create" | "enroll_in_payroll";
};

type TeamMemberHRInfoForm = {
  ssn?: string;
  dob?: DateTime;
  address?: FormAddress | undefined;
  mailing_address?: FormAddress | undefined;
  start_date?: DateTime | null;
  holiday_schedule_id?: Option<string> | null;
  enroll_in_payroll?: Option<boolean>;
  time_off_policies?: Option<string>[];
  pay_type?: Option<TeamMember["pay_type"] | "union_rate"> | null;
  union_rate?: Option<string> | null;
  pay_rate?: number | null;
  salary_rate_display?: Option<TeamMember["salary_rate_display"]> | null;
  pay_schedule_id?: Option<string> | null;
  overtime_exempt?: boolean;
  pay_rate_group?: Option<string> | null;
  classification?: Option<string> | null;
  salary_rate?: number;
  onboarding_checklist_id?: Option<string> | null;
  language?: Option<"en" | "es">;
  use_same_address_for_mailing_address?: boolean;
};

export const TeamMemberHRInfoScreen: React.FC<Props> = ({ name, mode, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();

  const holidayScheduleOptions = useHolidayScheduleOptions();
  const timeOffPolicyOptions = useTimeOffPolicyOptions();
  const lookUpTimeOffPolicy = useLookupTimeOffPolicy();
  const defaultTimeOffPolicyIds = teamMember?.time_off?.policies.map((policy) => policy.policy_id) || [];
  const defaultTimeOffPolicyOptions = useTimeOffPolicyOptions({
    predicate: (policy) => defaultTimeOffPolicyIds.includes(policy._id),
  });
  const payScheduleOptions = usePayScheduleOptions();
  const isMounted = useRef(true);

  const { setCanNext, setNextButtonText, handleComplete, screens } = useWizard();
  const prgs = usePrgs();
  const refetchTeam = useRefetchTeam();
  const form = useForm<TeamMemberHRInfoForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(activeCompany, teamMember, mode, defaultTimeOffPolicyOptions),
  });
  const { otExemptionIsRelevant } = useOtExemptUtils();

  const formData = useWatch<TeamMemberHRInfoForm>({ control: form.control });

  const lookupRateClassification = useLookupRateClassification();
  const classificationOptions = useClassificationOptions({ prgId: formData.pay_rate_group?.value });
  const selectedClassification = lookupRateClassification(formData.classification?.value);
  const prgOptions = usePrgOptions();

  const defaultaPRG = Object.keys(teamMember?.prg_classifications || {})[0];
  const defaultClassification = teamMember?.prg_classifications?.[defaultaPRG || ""];

  const company = activeCompany;
  const companyHasPayroll = company?.has_payroll && company.check_company.start_date;

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch, register } = form;
  const { dirtyFields, isValid } = formState;

  const enrollInPayroll = form.watch("enroll_in_payroll")?.value === true;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  useEffect(() => {
    if (defaultClassification) {
      const fullOption = classificationOptions.find((c) => c.value === defaultClassification);
      form.setValue("classification", fullOption);
    }
  }, [defaultClassification, defaultaPRG, classificationOptions]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0 || mode === "enroll_in_payroll") {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const generateFinalTimeOffBalancesAndLevels = (
    selectedTimeoffIds: string[],
    startDate?: string
  ): TMTimeOffPolicy[] => {
    const originalTimeOffPolicies = teamMember?.time_off?.policies || [];
    return selectedTimeoffIds.map((policyId) => {
      const existingPolicy = originalTimeOffPolicies.find((policy) => policy.policy_id === policyId);
      if (existingPolicy) return existingPolicy;

      const newPolicy = lookUpTimeOffPolicy(policyId);
      const levelId = assignDefaultLevel(startDate, levelComputedStartDates(newPolicy));
      const levelConfig = newPolicy?.levels.find((level) => level._id.toString() === levelId);
      const defaultBalance = levelConfig?.default_starting_balance || 0;

      return { policy_id: policyId, balance: defaultBalance, level_id: levelId };
    });
  };

  const buildParams = (params: TeamMemberHRInfoForm) => {
    if (!activeCompanyId) throw new Error("No active role");

    const cleanAddress = formatToAddress(params.address);

    const addressIsEmpty = isEmptyAddress(cleanAddress);

    // If the address is not empty and is not valid, throw an error
    if (!addressIsEmpty && !isValidAddress(cleanAddress)) {
      throw new Error("Address is not valid. Please make sure all the required fields are filled out.");
    }

    const cleanMailingAddress = params.use_same_address_for_mailing_address
      ? null
      : formatToAddress(params.mailing_address);

    const mailingAddressIsEmpty = isEmptyAddress(cleanMailingAddress);

    // If the mailing address is not valid, throw an error
    if (!mailingAddressIsEmpty && !isValidAddress(cleanMailingAddress)) {
      throw new Error(
        "Mailing address is not valid. Please make sure all the required fields are filled out."
      );
    }

    const calculateMailingAddress = () => {
      if (cleanMailingAddress === null) return null;
      if (!mailingAddressIsEmpty) return cleanMailingAddress;
      return undefined;
    };

    const data: UpdateTeamMemberParams = {
      ssn: params.ssn,
      dob: params.dob?.toISODate(),
      address: !addressIsEmpty ? formatToAddress(params.address) : undefined,
      mailing_address: calculateMailingAddress(),

      start_date: params.start_date?.toISODate(),
      holiday_schedule_id: params.holiday_schedule_id?.value,
      pay_schedule_id: params.pay_schedule_id?.value,
      enroll_in_payroll: params.enroll_in_payroll?.value || mode === "enroll_in_payroll" || false,
      salary_rate_display: params.salary_rate_display?.value,
      overtime_exempt: params.overtime_exempt,

      time_off: {
        policies: generateFinalTimeOffBalancesAndLevels(
          (params?.time_off_policies || []).map((policy) => policy.value),
          params.start_date?.toISODate()
        ),
      },

      pay_type: params.pay_type?.value === "union_rate" ? "hourly" : params.pay_type?.value,
      pay_rate:
        params.pay_type?.value === "salary"
          ? convertDisplayRateToAnnualRate(
              company,
              params.salary_rate_display?.value || "year",
              params.salary_rate || 0
            )
          : Number(params.pay_rate),

      union_rate: params.pay_type?.value === "union_rate" ? params.classification?.value : null,
      language: params?.language?.value || "en",
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberHRInfoForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }
      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      if (params.onboarding_checklist_id?.value) {
        const assignRes = await MiterAPI.onboarding_checklist_items.assign({
          tm_id: res._id,
          checklist_id: params.onboarding_checklist_id?.value,
        });
        if (assignRes.error) {
          throw new Error(assignRes.error);
        }
      }

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   * Build form options
   **********************************************************/
  const payTypeOptions = [
    { value: "hourly", label: prgs.length ? "Hourly (non-union)" : "Hourly" },
    ...(prgs.length ? [{ value: "union_rate", label: "Hourly (union)" }] : []),
    { value: "salary", label: "Salary" },
  ];

  // When the pay rate group changes, reset the classification options and the selected classification
  const handlePrgChange = () => {
    form.setValue("classification", null);
  };

  const renderPayRate = () => {
    if (formData.pay_type?.value === "union_rate") {
      return renderUnionPayRate();
    } else if (formData.pay_type?.value === "hourly") {
      return renderHourlyPayRate();
    } else {
      return renderSalaryPayRate();
    }
  };

  const renderSalaryPayRate = () => {
    return (
      <div className="form-section">
        <Label label={"Pay rate" + (enrollInPayroll ? "*" : "")} style={{ marginBottom: 5 }} />

        <div className="flex space-between">
          <Formblock
            type="unit"
            unit="$"
            form={form}
            placeholder={"0.00"}
            name="salary_rate"
            className="modal wizard"
            editing={true}
            style={{ width: "100%", height: 32 }}
            val={enrollInPayroll ? vals.required : undefined}
          />
          <Formblock
            type="select"
            options={salaryRateOptions}
            defaultValue={teamMember?.salary_rate_display || "year"}
            form={form}
            name="salary_rate_display"
            className="modal wizard"
            editing={true}
            style={{ width: 150, marginLeft: 10, height: 32 }}
            val={enrollInPayroll ? vals.required : undefined}
          />
        </div>
      </div>
    );
  };

  const renderHourlyPayRate = () => {
    return (
      <Formblock
        type="unit"
        unit="$"
        name="pay_rate"
        label={"Hourly pay rate" + (enrollInPayroll ? "*" : "")}
        form={form}
        placeholder="0.00"
        editing={true}
        className="modal wizard"
        val={enrollInPayroll ? vals.required : undefined}
      />
    );
  };

  const renderUnionPayRate = () => {
    return (
      <>
        <Formblock
          type="select"
          name="pay_rate_group"
          label="Pay rate group*"
          form={form}
          options={prgOptions}
          requiredSelect={true}
          editing={true}
          onChange={handlePrgChange}
          className="modal wizard"
          defaultValue={defaultaPRG}
        />
        {classificationOptions && (
          <Formblock
            type="select"
            name="classification"
            label="Classification*"
            form={form}
            options={classificationOptions}
            requiredSelect={true}
            editing={true}
            className="modal wizard"
            defaultValue={defaultClassification}
          />
        )}
        {selectedClassification && (
          <Formblock
            type="text"
            name="union_pay_rate"
            label="Base hourly rate"
            value={usdString(selectedClassification?.base_rate)}
            form={form}
            editing={true}
            className="modal wizard"
            disabled={true}
          />
        )}
      </>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>HR information</h2>
          <p className={styles["subheader-description"]}>Add the team member&apos;s HR related information</p>
        </div>
        <div className="form-section">
          <h3 style={{ marginTop: -5 }} className="form-section-subheader">
            Basic information
          </h3>
          <Formblock
            label={`Start date`}
            labelInfo="Date the team member started working for the company"
            type="datetime"
            dateOnly={true}
            name="start_date"
            placeholder="MM/DD/YYYY"
            form={form}
            className="modal wizard"
            editing={true}
          />
          <Formblock
            label={`Birthday`}
            type="datetime"
            dateOnly={true}
            name="dob"
            placeholder="MM/DD/YYYY"
            form={form}
            className="modal wizard"
            editing={true}
          />
          <Formblock
            label={`Language preference`}
            type="select"
            name="language"
            form={form}
            className="modal wizard"
            editing={true}
            options={[
              { label: "English", value: "en" },
              { label: "Español", value: "es" },
            ]}
          />
          <Formblock
            label={`Address`}
            type="address"
            name="address"
            form={form}
            className="modal wizard"
            editing={true}
          />

          <Formblock
            form={form}
            name="use_same_address_for_mailing_address"
            type="checkbox"
            text="Use same address for mailing address"
            editing={true}
            register={register}
            className="modal"
            defaultValue={!!formData.use_same_address_for_mailing_address}
          />

          {!formData.use_same_address_for_mailing_address && (
            <Formblock
              label={`Mailing Address`}
              type="address"
              name="mailing_address"
              form={form}
              className="modal wizard"
              editing={true}
            />
          )}

          {!teamMember?.ssn_last_four && (
            <Formblock
              type="ssn"
              name="ssn"
              label="SSN"
              form={form}
              editing={true}
              className="modal wizard"
              placeholder={"XXX-XX-" + (teamMember?.ssn_last_four || "XXXX")}
            />
          )}
        </div>

        <Formblock
          label="Time off policies"
          type="multiselect"
          name="time_off_policies"
          placeholder="Select time off policies"
          form={form}
          className="modal wizard"
          editing={true}
          options={timeOffPolicyOptions}
          height="unset"
        />

        <div className="form-section">
          <Formblock
            label="Holiday schedule"
            type="select"
            name="holiday_schedule_id"
            placeholder="Select a holiday schedule"
            form={form}
            className="modal wizard"
            editing={true}
            options={holidayScheduleOptions}
            defaultValue={teamMember?.holiday_schedule_id}
            isClearable={true}
          />
        </div>
        {companyHasPayroll && (
          <Formblock
            type="select"
            name="enroll_in_payroll"
            label="Add this team member to payroll"
            form={form}
            className="modal wizard"
            editing={true}
            defaultValue={true}
            options={
              [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ] as unknown as Option<boolean>[]
            }
            val={vals.required}
          />
        )}

        <div className="form-section">
          <h3 className="form-section-subheader">Add this team member to pay schedule and rate</h3>
          <Formblock
            type="select"
            name="pay_schedule_id"
            label={"Pay schedule" + (enrollInPayroll ? "*" : "")}
            form={form}
            options={payScheduleOptions}
            requiredSelect={enrollInPayroll ? true : false}
            editing={true}
            className="modal wizard"
            val={enrollInPayroll ? vals.required : undefined}
            defaultValue={teamMember?.pay_schedule_id}
          />
          <Formblock
            type="select"
            name="pay_type"
            label={"Pay type" + (enrollInPayroll ? "*" : "")}
            form={form}
            options={payTypeOptions}
            editing={true}
            requiredSelect={enrollInPayroll ? true : false}
            className="modal wizard"
            val={enrollInPayroll ? vals.required : undefined}
            defaultValue={teamMember?.union_rate ? "union_rate" : teamMember?.pay_type}
          />
          {formData.pay_type?.value && (
            <>
              {renderPayRate()}
              {otExemptionIsRelevant(teamMember) && (
                <Formblock
                  type="checkbox"
                  name="overtime_exempt"
                  label="Overtime exempt"
                  text="This team member is exempt from overtime pay"
                  form={form}
                  defaultValue={
                    teamMember!.overtime_exempt != null
                      ? teamMember!.overtime_exempt
                      : formData.pay_type?.value === "salary"
                  }
                  editing={true}
                  className="modal wizard"
                />
              )}
            </>
          )}
        </div>
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  company: Company | null,
  teamMember: WizardTeamMember | undefined,
  mode: "create" | "enroll_in_payroll",
  defaultTimeOffPolicyOptions: Option<string>[]
): TeamMemberHRInfoForm => {
  if (!teamMember) return {};

  return {
    dob: teamMember.dob ? DateTime.fromISO(teamMember.dob) : undefined,
    start_date: teamMember.start_date ? DateTime.fromISO(teamMember.start_date) : undefined,
    pay_rate: teamMember.pay_type === "hourly" ? teamMember.pay_rate : undefined,
    salary_rate:
      teamMember.pay_type === "salary"
        ? convertAnnualRateToDisplayRate(
            company,
            "salary",
            teamMember.salary_rate_display,
            teamMember.pay_rate
          )
        : undefined,
    enroll_in_payroll: mode === "enroll_in_payroll" ? { value: true, label: "Yes" } : undefined,
    address: teamMember.address
      ? {
          ...teamMember.address,
          state: {
            label: states.find((s) => s.abbreviation === teamMember.address?.state)?.abbreviation || "",
            value: teamMember.address?.state,
          },
        }
      : undefined,
    mailing_address: teamMember.mailing_address
      ? {
          ...teamMember.mailing_address,
          state: {
            label:
              states.find((s) => s.abbreviation === teamMember.mailing_address?.state)?.abbreviation || "",
            value: teamMember.mailing_address?.state,
          },
        }
      : undefined,
    use_same_address_for_mailing_address: isEmptyAddress(teamMember.mailing_address),
    language:
      teamMember.language === "es"
        ? { label: "Español", value: "es" }
        : {
            label: "English",
            value: "en",
          },
    time_off_policies: defaultTimeOffPolicyOptions,
  };
};
