import { useCallback } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, PostTaxDeduction } from "dashboard/miter";
import { PtdTableEntry } from "dashboard/pages/post-tax-deductions/PostTaxDeductions";
import { PostTaxDeductionParams as PostTaxDeductionFormParams } from "backend/services/post-tax-deductions-service";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * POST TAX DEDUCTIONS
 */
type PostTaxDeductionParams =
  | PtdTableEntry
  | PtdTableEntry[]
  | PostTaxDeductionFormParams
  | PostTaxDeduction
  | PostTaxDeduction[]
  | undefined;

type PostTaxDeductionAction = "create" | "read" | "update" | "delete";
type PostTaxDeductionAbilities = {
  can: (action: PostTaxDeductionAction, items?: PostTaxDeductionParams) => boolean;
  cannot: (action: PostTaxDeductionAction, items?: PostTaxDeductionParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: PostTaxDeductionAction) => (tm: AggregatedTeamMember) => boolean;
};

export const usePostTaxDeductionAbilities = (): PostTaxDeductionAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: PostTaxDeductionAction, items?: PostTaxDeductionParams) => {
      if (!items) return false;

      const ptds = Array.isArray(items) ? items : [items];

      return ptds.every((ptd) => {
        const teamMemberId = getTeamMemberId(ptd);

        switch (action) {
          case "create":
            return can_("post_tax_deductions:create", { teamMember: teamMemberId });
          case "read":
            return can_("post_tax_deductions:read", { teamMember: teamMemberId });
          case "update":
            return can_("post_tax_deductions:update", { teamMember: teamMemberId });

          case "delete":
            return can_("post_tax_deductions:delete", { teamMember: teamMemberId });
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: PostTaxDeductionAction, items: PostTaxDeductionParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "post_tax_deductions",
    teamMemberField: { fieldName: "employee._id", fieldType: "_id" },
    appModule: "payroll_and_compliance",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<PostTaxDeductionAction>("post_tax_deductions", true);

  return { can, cannot, filter, teamPredicate };
};

function getTeamMemberId<T extends { employee: string }>(item: T | undefined): string | undefined {
  return item?.employee;
}
