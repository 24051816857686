import { ColDef, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import {
  toDollarFormat,
  roundToTwoDecimals,
  agGridIsoDateComparator,
  toRateDollarFormat,
} from "../../reportUtils";
import { EarningsSummaryEntry } from "./CreateEarningsSummary";
import { buildAgGridColumns, roundTo } from "miter-utils";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useLookupTeam } from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import { checkTypeToTimeType } from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";

const replaceBlankWithDashFormatter = (params: ValueFormatterParams<EarningsSummaryEntry>): string => {
  return params.value || "-";
};

export const useEarningSummaryColumnDefs = (): ColDef<EarningsSummaryEntry>[] => {
  const { customFields } = useContext(AppContext);
  const lookupTeam = useLookupTeam();

  const jobCustomFieldColumnDefs = useMemo(
    () => buildAgGridColumns(customFields.filter((cf) => cf.parent_type === "job")),
    [customFields]
  );

  const teamMemberCustomFieldColumnDefs = useMemo(
    () => buildAgGridColumns(customFields.filter((cf) => cf.parent_type === "team_member")),
    [customFields]
  );

  const earningSummaryColumnDefs: ColDef<EarningsSummaryEntry>[] = useMemo(
    () => [
      {
        field: "team_member.full_name",
        headerName: "Team member",
        filter: true,
        enableRowGroup: true,
      } as ColDef<EarningsSummaryEntry>,
      {
        headerName: "Team member (last, first)",
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): string => {
          const tm = lookupTeam(params.data?.team_member?._id);
          if (!tm) return "";
          return `${tm.last_name}, ${tm.first_name}`;
        },
        filter: true,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "team_member.friendly_id",
        headerName: "Team member ID",
        filter: true,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "team_member.title",
        headerName: "Team member title",
        filter: true,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        valueGetter: (params) => params.data?.job?.name || "", // Need this implementation for row grouping to work when job is absent
        filter: true,
        headerName: "Job",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        valueGetter: (params) => params.data?.job?.code || "", // Need this implementation for row grouping to work when job is absent
        filter: true,
        headerName: "Job code",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        valueGetter: (params) => params.data?.activity?.label || "", // Need this implementation for row grouping to work when activity is absent
        filter: true,
        headerName: "Activity",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        valueGetter: (params) => params.data?.activity?.cost_code || "", // Need this implementation for row grouping to work when activity is absent
        filter: true,
        headerName: "Cost code",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        valueGetter: (params) => params.data?.department?.name || "", // Need this implementation for row grouping to work when department is absent
        filter: true,
        headerName: "Department",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        valueGetter: (params) => params.data?.department?.identifier || "", // Need this implementation for row grouping to work when department is absent
        filter: true,
        headerName: "Department code",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        valueGetter: (params) => params.data?.location?.name || "", // Need this implementation for row grouping to work when location is absent
        filter: true,
        headerName: "Location",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        field: "date",
        headerName: "Earning date",
        enableRowGroup: true,
        filter: "agDateColumnFilter",
        filterParams: { comparator: agGridIsoDateComparator },
        valueFormatter: replaceBlankWithDashFormatter,
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): string => params.data?.date || "-",
      },
      {
        field: "payday",
        headerName: "Earning payday",
        enableRowGroup: true,
        filter: "agDateColumnFilter",
        filterParams: { comparator: agGridIsoDateComparator },
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        field: "earning_label",
        filter: true,
        headerName: "Earning type",
        enableRowGroup: true,
      },
      {
        field: "description",
        filter: "agTextColumnFilter",
        headerName: "Description",
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        headerName: "Week number",
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): number | null => {
          const payday = params.data?.payday;
          if (!payday) return null;
          return DateTime.fromISO(payday).weekNumber;
        },
        filter: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "pay_schedule_label",
        filter: true,
        headerName: "Pay schedule",
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "prg_label",
        filter: true,
        headerName: "Pay rate group",
        initialHide: true,
        enableRowGroup: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        field: "classification_label",
        filter: true,
        headerName: "Classification",
        initialHide: true,
        enableRowGroup: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        field: "classification_type",
        filter: true,
        headerName: "Classification type",
        initialHide: true,
        enableRowGroup: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        field: "ignore_benefit_contributions",
        filter: true,
        headerName: "Ignore benefit contributions",
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "hours",
        headerName: "Hours",
        filter: "agNumberColumnFilter",
        aggFunc: "sumValues",
        valueFormatter: roundToTwoDecimals,
      },
      {
        field: "amount",
        headerName: "Gross earnings",
        filter: "agNumberColumnFilter",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        headerName: "Rate type paid",
        filter: "agSetColumnFilter",
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): string | null => {
          const pri = params.data?.pay_rate_item;
          if (!pri) return null;
          if (pri.method === "custom") return "Custom";
          if (pri.method === "union_rate") return "Rate group classification";
          if (pri.method === "team_member") return "Team member default";
          if (pri.method === "activity") {
            if (pri.classification?.source === "activity") return "Activity classification";
            return "Activity custom";
          }
          return null;
        },
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        headerName: "Pay rate",
        filter: "agNumberColumnFilter",
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): number | null => {
          if (params.data) {
            const pri = params.data.pay_rate_item;
            const tt = checkTypeToTimeType(params.data.check_type);
            if (pri?.rates[tt] != null) return pri.rates[tt];

            const amount = params.data.raw_amount ?? params.data.amount;
            if (amount != null && params.data.hours) {
              return roundTo(amount / params.data.hours, 5);
            }
          }
          return null;
        },
        valueFormatter: toRateDollarFormat,
        initialHide: true,
      },
      {
        headerName: "REG pay rate",
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): number | null => {
          const pri = params.data?.pay_rate_item;
          if (pri?.rates.reg != null) return pri.rates.reg;
          const amount = params.data?.raw_amount ?? params.data?.amount;
          if (amount != null && params.data?.hours) {
            return roundTo(amount / params.data.hours, 5);
          }
          return null;
        },
        filter: "agNumberColumnFilter",
        valueFormatter: toRateDollarFormat,
        initialHide: true,
      },
      {
        headerName: "Classification base rate",
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): number | null => {
          const pri = params.data?.pay_rate_item;
          if (!pri) return null;
          if (pri.pw_reference?.base_rate != null) return pri.pw_reference.base_rate;
          if (pri.classification?.source === "union_rate" && pri.union_rate_reference?.base_rate != null) {
            return pri.union_rate_reference.base_rate;
          }
          return null;
        },
        filter: "agNumberColumnFilter",
        valueFormatter: toRateDollarFormat,
        initialHide: true,
      },
      {
        field: "pay_rate_item.pw_reference.fringe_rates.reg",
        headerName: "Classification fringe rate",
        filter: "agNumberColumnFilter",
        valueFormatter: toRateDollarFormat,
        initialHide: true,
      },
      {
        field: "wc_code.label",
        headerName: "Workers comp code",
        filter: true,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        headerName: "Total fringes paid as cash",
        filter: "agNumberColumnFilter",
        valueGetter: (params: ValueGetterParams<EarningsSummaryEntry>): number | null => {
          const pri = params.data?.pay_rate_item;
          const fringesCashRate = pri?.pw_reference?.fringe_paid_as_cash || 0;
          if (!fringesCashRate || !pri?.classification || !params.data?.hours) return null;
          const checkType = params.data.check_type;
          if (checkType === "double_overtime") {
            return roundTo(params.data.hours * fringesCashRate.dot);
          } else if (checkType === "overtime") {
            return roundTo(params.data.hours * fringesCashRate.ot);
          } else {
            return roundTo(params.data.hours * fringesCashRate.reg);
          }
        },
        valueFormatter: toDollarFormat,
        initialHide: true,
        aggFunc: "sumValues",
      },
      {
        field: "address.line1",
        headerName: "Line 1",
        sortable: true,
        filter: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "address.line2",
        headerName: "Line 2",
        sortable: true,
        filter: true,
        initialHide: true,
      },
      {
        field: "address.city",
        headerName: "City",
        sortable: true,
        filter: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "address.state",
        headerName: "State",
        sortable: true,
        filter: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "address.postal_code",
        headerName: "Postal code",
        sortable: true,
        filter: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        valueGetter: (params) => (!!params.data?.job?.cpr_info?.project_number ? "True" : "False"), // Need this implementation for row grouping to work when job is absent
        filter: true,
        headerName: "Is Certified Payroll job",
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: replaceBlankWithDashFormatter,
      },
      {
        field: "burdenRateTotal",
        headerName: "Burden rate total",
        filter: "agNumberColumnFilter",
        valueFormatter: toDollarFormat,
        initialHide: true,
        aggFunc: "sumValues",
      },
      ...jobCustomFieldColumnDefs,
      ...teamMemberCustomFieldColumnDefs,
    ],
    [lookupTeam, jobCustomFieldColumnDefs]
  );

  return earningSummaryColumnDefs;
};
