import React, { useState } from "react";

import Banner from "dashboard/components/shared/Banner";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier } from "ui";
import * as formValidators from "dashboard/utils/validators";
import { CardProgramTableRow } from "../CardProgramTable";
import { MiterAPI } from "dashboard/miter";

const CombineCardProgramsModal: React.FC<{
  selectedRows: CardProgramTableRow[];
  onHide: () => void;
  onSuccess: () => void;
}> = ({ selectedRows, onHide, onSuccess }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { control, register, errors, handleSubmit } = useForm();

  const handleCombine = async (formData) => {
    setLoading(true);
    const programToKeepId = formData.new_program_id.value;

    const res = await MiterAPI.expenses.card_programs.combine({
      card_program_to_keep: programToKeepId,
      selected_card_program_ids: selectedRows.map((row) => row._id),
    });
    if (res.error) {
      Notifier.error(res.error);
    } else {
      Notifier.success("Card programs combined successfully");
      onSuccess();
    }

    setLoading(false);
  };

  return (
    <ActionModal
      headerText={"Combine Programs"}
      submitText={"Combine"}
      onSubmit={handleSubmit(handleCombine)}
      showSubmit={true}
      showCancel={true}
      cancelText={"Cancel"}
      onCancel={onHide}
      onHide={onHide}
      loading={loading}
    >
      <div>
        <Banner
          content={"Select the program you want to *keep*. All other card programs will be deleted."}
          type="warning"
          style={{ marginTop: 15, marginBottom: 15 }}
        />
        <Banner
          content={"All cards linked to the selected programs will be updated to the newly merged program."}
          type="modal"
          style={{ marginTop: 15, marginBottom: 15 }}
        />
        <p>number of selected programs: {selectedRows.length}</p>
        <Formblock
          type="select"
          name="new_program_id"
          label="Program to keep"
          options={selectedRows.map((row) => ({ value: row._id, label: row.name! }))}
          control={control}
          register={register(formValidators.required)}
          editing={true}
          errors={errors}
        />
      </div>
    </ActionModal>
  );
};

export default CombineCardProgramsModal;
