import React, { useEffect, useState } from "react";
import { Formblock, Input } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";

import { useForm } from "react-hook-form";
import { SettingsCard } from "ui/settings/SettingsCard";
import { Option } from "ui/form/Input";
import {
  Sage100ConnectionMetadata,
  Sage100JobStatus,
  SimplifiedSage100LineType,
} from "backend/services/sage100/sage100-types";
import { useActivityOptionsMap, useJobOptions } from "dashboard/hooks/atom-hooks";
import { useDebounce } from "use-debounce";
import { KeyLabelPair } from "../Intacct/IntacctConfig";
import { hh2JobStatusOptions } from "../Sage300/Sage300Config";

const INPUTLENGTH = 250;

const importPackageOptions: Option<Sage100ConnectionMetadata["import_package"]>[] = [
  { value: "ei_dynamics", label: "EI Dynamics" },
  { value: "wright_office", label: "Wright Office Solutions" },
  { value: "zoom_geeks", label: "Zoom Geeks" },
];

const sage100LineTypeKeys: KeyLabelPair<SimplifiedSage100LineType>[] = [
  { key: "employer_benefit_contributions", label: "Employer benefit contributions" },
  { key: "employee_benefit_deductions", label: "Employee benefit deductions" },
  { key: "employer_taxes", label: "Employer tax expenses" },
  { key: "tax_liabilities", label: "Tax liabilities" },
  { key: "child_support_deductions", label: "Child support deductions" },
  { key: "other_deductions", label: "Other deductions" },
  { key: "reimbursements", label: "Reimbursements" },
  { key: "other", label: "Other" },
];

export const Sage100Config: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const jobOptions = useJobOptions();
  const activityOptionsMap = useActivityOptionsMap();
  const form = useForm();
  const isMounted = React.useRef(false);
  const [sage100Metadata, setSage100Metadata] = useState<DeepPartial<Sage100ConnectionMetadata>>(
    integration.connection?.metadata?.sage_100 || {}
  );
  const [debouncedSage100Metadata] = useDebounce(sage100Metadata, 1000);

  const updateSage100Metadata = async (metadataUpdate: DeepPartial<Sage100ConnectionMetadata>) => {
    setSage100Metadata((prev) => ({ ...prev, ...metadataUpdate }));
  };

  const updateLineItemType = async (e) => {
    updateSage100Metadata({
      gl_line_code_map: {
        ...sage100Metadata.gl_line_code_map,
        [e.target.name]: e.target.value,
      },
    });
  };

  const activityOptions = activityOptionsMap.get(sage100Metadata.default_job_id);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    updateIntegrationConnection({ metadata: { sage_100: debouncedSage100Metadata } });
  }, [debouncedSage100Metadata]);

  const sage100ImportPackages = ["zoom_geeks", "ei_dynamics"];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Sage 100 import software package">
        <Formblock
          form={form}
          name="importPackage"
          type="select"
          options={importPackageOptions}
          label="Software package"
          defaultValue={sage100Metadata.import_package}
          onChange={(o: Option<Sage100ConnectionMetadata["import_package"]> | null) =>
            updateSage100Metadata({ import_package: o?.value })
          }
          editing={true}
          labelInfo="The third-party software package used to import job costs and GL entries into Sage 100C."
          underlineTooltip
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
      </SettingsCard>
      <SettingsCard title="Activity Settings">
        <Formblock
          form={form}
          name="restrict_job_level_activities"
          type="checkbox"
          label="Restrict Job level activities"
          defaultValue={sage100Metadata.restrict_job_level_activities}
          onChange={(e) => updateSage100Metadata({ restrict_job_level_activities: e.target.checked })}
          editing={true}
          labelInfo="When pulling Activities from Sage 100, only pull Company Activities."
          underlineTooltip
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
      </SettingsCard>
      <SettingsCard title="Job cost settings">
        <Formblock
          form={form}
          name="default_job_id"
          type="select"
          options={jobOptions}
          label={`Fallback job`}
          defaultValue={sage100Metadata.default_job_id}
          onChange={(o: Option<Sage100ConnectionMetadata["default_job_id"]> | null) =>
            updateSage100Metadata({ default_job_id: o?.value })
          }
          editing={true}
          labelInfo="If direct expenses are not assigned a job in Miter, Miter will use this job when exporting payrolls to Sage 100."
          underlineTooltip
          inputProps={{ style: { width: INPUTLENGTH } }}
          isClearable={true}
        />
        <Formblock
          form={form}
          name="default_activity_id"
          type="select"
          value={activityOptions.find((option) => option.value === sage100Metadata.default_activity_id)}
          options={activityOptions}
          label={`Fallback cost code`}
          defaultValue={sage100Metadata.default_activity_id}
          onChange={(o: Option<Sage100ConnectionMetadata["default_activity_id"]> | null) =>
            updateSage100Metadata({ default_activity_id: o?.value })
          }
          editing={true}
          labelInfo="If direct expenses are not assigned a cost code in Miter, Miter will use this cost code when exporting payrolls to Sage 100."
          underlineTooltip
          isClearable={true}
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
        <Formblock
          form={form}
          name="subAccountSeparator"
          label="Sub-account separator"
          labelInfo="The characters used to separate sub-accounts in the GL account code."
          type="text"
          defaultValue={sage100Metadata.subAccountSeparator}
          onChange={(e) => updateSage100Metadata({ subAccountSeparator: e.target.value })}
          inputProps={{ style: { width: INPUTLENGTH } }}
          editing={true}
        ></Formblock>
        <Formblock
          form={form}
          name="direct_expense_account_codes"
          type="text"
          editing={false}
          labelStyle={{ minWidth: 225 }}
          label={"Direct expense account range"}
          labelInfo={"The range of GL account codes used for direct expenses in Sage 100."}
          underlineTooltip
          inputProps={{ style: { width: INPUTLENGTH } }}
        >
          <div className="flex" style={{ width: INPUTLENGTH }}>
            <Input
              form={form}
              name="direct_expense_account_code_min"
              type="text"
              placeholder="Min"
              defaultValue={sage100Metadata.direct_expense_account_code_min?.toString()}
              onChange={(e) => updateSage100Metadata({ direct_expense_account_code_min: e.target.value })}
            />
            <div style={{ width: 15 }}></div>
            <Input
              form={form}
              name="direct_expense_account_code_max"
              type="text"
              placeholder="Max"
              defaultValue={sage100Metadata.direct_expense_account_code_max?.toString()}
              onChange={(e) => updateSage100Metadata({ direct_expense_account_code_max: e.target.value })}
            />
          </div>
        </Formblock>
      </SettingsCard>
      {sage100ImportPackages.includes(sage100Metadata?.import_package || "") && (
        <>
          <SettingsCard title="Sage 100 code map">
            {sage100LineTypeKeys.map((obj) => {
              return (
                <Formblock
                  form={form}
                  name={obj.key}
                  type="text"
                  label={obj.label}
                  defaultValue={sage100Metadata.gl_line_code_map?.[obj.key] || ""}
                  onChange={(e) => updateLineItemType(e)}
                  editing={true}
                  labelInfo={obj.tooltip}
                  underlineTooltip
                  inputProps={{ style: { width: INPUTLENGTH } }}
                  key={obj.key}
                />
              );
            })}
          </SettingsCard>
        </>
      )}
      <SettingsCard title="Job Sync">
        <Formblock
          form={form}
          options={hh2JobStatusOptions}
          name="inactiveStatusValues"
          type="multiselect"
          defaultValue={integration.connection?.metadata?.sage_100?.inactiveJobStatuses}
          onChange={(options: Option<Sage100JobStatus>[] | null) => {
            const statuses = options?.map((o) => o.value);
            updateSage100Metadata({ inactiveJobStatuses: statuses ?? [] });
          }}
          label="Inactive job statuses"
          editing={true}
          labelInfo="Sage 100 statuses Miter will consider to be 'Inactive' when syncing jobs"
          isClearable
        />
      </SettingsCard>
      <SettingsCard title="Ledger Account Labels">
        <Formblock
          form={form}
          name="includeParentNameInAccountLabel"
          type="checkbox"
          text="Include the parent account name in sub-account labels"
          defaultValue={!!sage100Metadata.includeParentNameInAccountLabel}
          onChange={(e) => updateSage100Metadata({ includeParentNameInAccountLabel: e.target.checked })}
          editing={true}
        />
      </SettingsCard>
    </div>
  );
};
