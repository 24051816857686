import { ClickAwayListener } from "@material-ui/core";
import { AggregatedLiveTimesheet, MiterAPI } from "dashboard/miter";
import { determineGeofenceStatusOfLiveTimesheet } from "dashboard/utils/geolocation";
import React, { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { ModalSidebar, Notifier } from "ui";
import LiveTimesheetDetails from "./LiveTimesheetDetails";
import LiveTimesheetGeolocation from "./LiveTimesheetGeolocation";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
type Props = {
  id: string;
  hide: (refresh?: boolean) => void;
};

const LiveTimesheetModal: React.FC<Props> = ({ hide, id }) => {
  const [activeView, setActiveView] = useState("details");
  const [liveTimesheet, setLiveTimesheet] = useState<AggregatedLiveTimesheet | null>(null);
  const company = useActiveCompany();
  const radiusMiles = company?.settings.timesheets.geofence_radius;
  const [isOnBreak, setIsOnBreak] = useState(false);

  const togglerConfig = [
    { label: "Details", path: "details" },
    {
      label: "Geolocation",
      path: "geolocation",
      alert:
        !!liveTimesheet &&
        determineGeofenceStatusOfLiveTimesheet(liveTimesheet, radiusMiles) === "outside_fence",
    },
  ];

  const fetchLiveTimesheet = async () => {
    try {
      const resp = await MiterAPI.live_timesheets.list({ filter: [{ field: "_id", value: id }] });
      if (resp.error) throw Error(resp.error);
      if (resp?.length !== 1) {
        Notifier.error("Could not get live timesheet, which was most likely clocked out.");
        hide();
        return;
      }
      setLiveTimesheet(resp[0] || null);
      if (resp[0]) {
        const hasOngoingBreak = !!resp[0]?.breaks?.find((b) => !b.end);
        setIsOnBreak(hasOngoingBreak);
      }
    } catch (e) {
      console.error(`Fetching live timesheet with id: ${id}: ${e}`);
      Notifier.error("Error fetching live timesheet.");
      hide();
    }
  };

  useEffect(() => {
    fetchLiveTimesheet();
  }, [id]);

  return (
    <div className="modal-background">
      {liveTimesheet ? (
        <ClickAwayListener onClickAway={() => hide()}>
          <div className="modal-wrapper payment" style={{ width: "70%", maxWidth: 900 }}>
            <div className="modal-header payment">
              <FaCircle
                size={14}
                style={{ marginRight: 8, marginBottom: -2, color: isOnBreak ? "orange" : "green" }}
              />
              <div>{`${liveTimesheet.team_member?.full_name}`}</div>
            </div>
            <div className="payment-wrapper" style={{ width: "100%" }}>
              <ModalSidebar
                config={togglerConfig}
                active={activeView}
                toggle={(path) => setActiveView(path)}
              />
              <div className="payment-active-view">
                <div>
                  {activeView === "details" && <LiveTimesheetDetails ts={liveTimesheet} hide={hide} />}
                </div>
                <div>{activeView === "geolocation" && <LiveTimesheetGeolocation ts={liveTimesheet} />}</div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};

export default LiveTimesheetModal;
