import { usePermissionGroupOptions } from "dashboard/hooks/atom-hooks";
import { capitalize, uniqBy } from "lodash";
import { X } from "phosphor-react";
import React, { FC, useMemo } from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock, Button, Label } from "ui";
import { styles } from "ui/form/styles";
import { DocumentInfoScreenFields } from "../documents/wizard/DocumentInfoScreen";

type AdvancedPermissionsProps = {
  form: UseFormMethods<DocumentInfoScreenFields>;
};

/**
 * Advanced permissions allow you to control which permission groups can view and manage this document.
 * - You can select multiple permission groups and assign them different permissions - view, edit, delete.
 * - Currently this is only used for documents but we will be adding support for other forms as well
 */
export const AdvancedPermissions: FC<AdvancedPermissionsProps> = ({ form }) => {
  const permissionGroupOptions = usePermissionGroupOptions();
  const permissionsSet = form.watch().advanced_permissions || [];

  // Filter out the permission groups that have already been selected
  const selectablePermissionGroupOptions = useMemo(() => {
    return permissionGroupOptions.filter((option) => {
      return !permissionsSet.find((permission) => permission.permission_group_id?.value === option.value);
    });
  }, [permissionGroupOptions, permissionsSet]);

  const handleRemove = (index: number) => {
    const newPermissionsSet = [...permissionsSet];
    newPermissionsSet.splice(index, 1);

    form.setValue("advanced_permissions", newPermissionsSet, { shouldDirty: true });
  };

  const handleAdd = () => {
    if (!selectablePermissionGroupOptions.length) return;
    const newPermissionsSet = [...permissionsSet, { permission_group_id: undefined, can: [] }];
    form.setValue("advanced_permissions", newPermissionsSet, { shouldDirty: true });
  };

  const buildSelectableOptions = (index: number) => {
    const defaultPermissionGroup = permissionsSet[index]?.permission_group_id;
    const selectableOptions = [
      ...selectablePermissionGroupOptions,
      ...(defaultPermissionGroup ? [defaultPermissionGroup] : []),
    ];

    return uniqBy(selectableOptions, "value");
  };

  const renderAddRow = () => {
    if (!selectablePermissionGroupOptions.length) return;

    return (
      <Button className="button-1 no-margin" onClick={handleAdd}>
        + Add group
      </Button>
    );
  };

  const renderRow = (index: number) => {
    const selectableOptions = buildSelectableOptions(index);

    return (
      <div key={`group-` + index} className="flex align-items-center width-100-percent">
        <Formblock
          type="select"
          name={`advanced_permissions.${index}.permission_group_id`}
          placeholder="Select a permission group"
          editing={true}
          className="modal wizard"
          options={selectableOptions}
          style={{ width: "47%", marginRight: 15 }}
          form={form}
          control={form.control}
          isClearable={true}
        />
        <Formblock
          type="multiselect"
          name={`advanced_permissions.${index}..can`}
          placeholder="Select permissions"
          editing={true}
          className="modal wizard"
          options={PERMISSION_OPTIONS}
          style={{ width: "47%", marginRight: 15 }}
          form={form}
          control={form.control}
          isClearable={true}
        />
        <Button
          className="button-text"
          onClick={() => handleRemove(index)}
          style={{ fontSize: "15px", color: "#555", marginBottom: 20, marginLeft: "auto" }}
        >
          <X />
        </Button>
      </div>
    );
  };

  return (
    <div className="form-section">
      <Label
        label=""
        sublabel="Advanced permissions allow you to control which permission groups can view and manage this doc."
        style={{ width: "100%" }}
      />
      <div className={styles["permissions-list"]}>
        {permissionsSet.map((permission, index) => renderRow(index))}
      </div>
      {renderAddRow()}
    </div>
  );
};

/** Constants used to define the advanced permissions */
const PERMISSIONS = ["read", "update", "delete"];
const PERMISSION_OPTIONS = PERMISSIONS.map((action) => ({
  label: capitalize(action),
  value: action,
}));
