import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BasicModal, ConfirmModal } from "ui";
import {
  TeamMember,
  AggregatedTeamMember,
  AggregatedTimeOffRequest,
  MiterAPI,
  MiterFilterArray,
} from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Helmet } from "react-helmet";
import { CheckPayroll } from "backend/utils/check/check-types";
import { ColumnConfig, TableActionLink, TableTogglerConfig, TableV2 } from "ui/table-v2/Table";
import { Check, CheckCircle, Plus, TrashSimple, X } from "phosphor-react";
import { useApprovalGroupColumns as useApprovalGroupColumns } from "dashboard/utils/approvals";
import { useQuery } from "miter-utils";
import {
  useActiveCompanyId,
  useRefetchActionableItems,
  useRefetchTeam,
  useLookupDepartment,
  useLookupTeamMemberCrews,
  useLookupCrew,
} from "dashboard/hooks/atom-hooks";
import { ForageRequest, ForageResponse } from "backend/utils/forage/forage-types";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";
import TimeOffRequestModal from "./TimeOffRequestModal";
import { useTimeOffRequestFormItems } from "dashboard/hooks/useTimeOffRequestFormItems";
import { useKickBackItemsAction } from "dashboard/components/approvals/KickBackItemsAction";
import { InboxMode } from "../approvals/inboxUtils";
import { ValueFormatterParams } from "ag-grid-community";

export type RequestTableEntry = AggregatedTimeOffRequest & {
  dates: string;
  payroll_hours: React.ReactElement | number;
};

export const cleanTimeOffRequests = (
  data: AggregatedTimeOffRequest[],
  tm?: TeamMember,
  checkPayroll?: CheckPayroll
): RequestTableEntry[] => {
  return data
    .filter((req) => !tm || tm._id === req.employee._id)
    .map((req) => {
      const payrollHours = checkPayroll
        ? req.schedule.reduce((sum, day) => {
            if (day.date >= checkPayroll.period_start && day.date <= checkPayroll.period_end)
              return sum + Number(day.hours);
            return sum;
          }, 0)
        : 0;

      return {
        ...req,
        dates: req.start_date + " " + req.end_date,
        payroll_hours: payrollHours,
      };
    });
};

type Props = {
  employee?: TeamMember | AggregatedTeamMember;
  showToggler?: boolean;
  viewOnly?: boolean;
  defaultFilters?: MiterFilterArray;
  alternateColumns?: ColumnConfig<RequestTableEntry>[];
  providedTimeOffRequests?: RequestTableEntry[] | undefined;
  onRefreshTimeOffRequests?: () => Promise<void>;
  onDataChange?: (tmIds?: string[]) => Promise<void>;
  emptyHeader?: string;
  fetchActionableTimeOffRequests?: (
    params: ForageRequest
  ) => Promise<ForageResponse<AggregatedTimeOffRequest>>;
  inboxMode?: InboxMode;
};

export const TimeOffRequestsTable: FC<Props> = ({
  employee,
  viewOnly,
  showToggler,
  defaultFilters,
  alternateColumns,
  providedTimeOffRequests,
  onRefreshTimeOffRequests,
  onDataChange,
  emptyHeader,
  fetchActionableTimeOffRequests,
  inboxMode,
}) => {
  const query = useQuery();
  const status = query.get("status");

  const activeCompanyId = useActiveCompanyId();
  const refetchActionableItems = useRefetchActionableItems();
  const miterAbilities = useMiterAbilities();
  const timeOffRequestAbilities = useTimeOffRequestAbilities({ inboxMode });
  const refetchTeam = useRefetchTeam();
  const approvalGroupColumns = useApprovalGroupColumns("time_off_request");
  // Crews
  const lookupTeamMemberCrews = useLookupTeamMemberCrews();
  const lookupCrew = useLookupCrew();

  const [timeOffRequests, setTimeOffRequests] = useState<RequestTableEntry[]>();
  const [selectedTimeOffRequests, setSelectedTimeOffRequests] = useState<RequestTableEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [idsForDeletion, setIDsForDeletion] = useState<string[]>([]);
  const [activeTimeOffRequest, setActiveTimeOffRequest] = useState<AggregatedTimeOffRequest | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showMarkAsPaidConfirmation, setShowMarkAsPaidConfirmation] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const lookupDepartment = useLookupDepartment();

  const kickBackItemAction = useKickBackItemsAction(selectedTimeOffRequests, "time_off_request", () => {
    setSelectedTimeOffRequests([]);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  });

  useEffect(() => {
    if (onRefreshTimeOffRequests) return;
    getTimeOffRequests();
  }, []);

  useEffect(() => {
    refetchActionableItems();
  }, []);

  /*********************************************************
   *  Functions for fetching, updating, and cleaning data
   **********************************************************/
  const forageTimeOffRequests = useCallback(
    async (query: ForageRequest) => {
      if (!fetchActionableTimeOffRequests) return { data: [], next_page: null, prev_page: null };

      const sort = query.sort || [{ field: "created_at", direction: -1 }];

      const filter = query.filter || [];

      if (!inboxMode) {
        const abilitiesFilter = timeOffRequestAbilities.filter("read");
        if (abilitiesFilter) filter.push(abilitiesFilter);
      }

      const res = await fetchActionableTimeOffRequests?.({ ...query, filter, sort, select: undefined });
      const data = cleanTimeOffRequests(res?.data || []);

      return { ...res, data };
    },
    [fetchActionableTimeOffRequests, inboxMode]
  );

  const getTimeOffRequests = async () => {
    setLoading(true);

    if (providedTimeOffRequests && onRefreshTimeOffRequests) {
      await onRefreshTimeOffRequests();
    } else {
      try {
        const dFilters = defaultFilters ? defaultFilters : [];

        const filters: MiterFilterArray = [
          { field: "company", type: "string", value: activeCompanyId! },
          ...dFilters,
        ];

        const abilitiesFilter = timeOffRequestAbilities.filter("read");
        if (abilitiesFilter) filters.push(abilitiesFilter);

        if (employee) {
          filters.push({ field: "employee._id", type: "_id", value: employee._id });
        }

        const response = await MiterAPI.time_off.requests.search(filters);
        if (response.error) throw new Error(response.error);

        const cleanedTimeOffRequests = cleanTimeOffRequests(response);
        setTimeOffRequests(cleanedTimeOffRequests);
      } catch (e: $TSFixMe) {
        console.log(e);
        Notifier.error(e.message);
      }
    }

    setLoading(false);
  };

  const deleteTimeOffRequest = async (timeOffRequestID) => {
    if (timeOffRequestAbilities.cannot("delete", selectedTimeOffRequests)) {
      Notifier.error("You don't have permission to delete this time off request.");
      return;
    }

    setLoading(true);
    try {
      const response = await MiterAPI.time_off.requests.delete(timeOffRequestID);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success("Deleted time off request.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
    refetchActionableItems();
  };

  const approveTimeOffRequest = async (timeOffRequestID) => {
    if (timeOffRequestAbilities.cannot("approve", selectedTimeOffRequests)) {
      Notifier.error("You don't have permission to approve this time off request.");
      return;
    }

    setLoading(true);
    try {
      const responseData = await MiterAPI.time_off.requests.update(timeOffRequestID, {
        data: { status: "approved" },
      });

      if (responseData.error) throw new Error(responseData.error);
      Notifier.success("Approved time off request.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  };

  const denyTimeOffRequest = async (timeOffRequestID) => {
    if (timeOffRequestAbilities.cannot("approve", selectedTimeOffRequests)) {
      Notifier.error("You don't have permission to deny this time off request.");
      return;
    }

    setLoading(true);
    try {
      const responseData = await MiterAPI.time_off.requests.update(timeOffRequestID, {
        data: { status: "denied" },
      });

      if (responseData.error) throw new Error(responseData.error);
      Notifier.success("Denied time off request.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  };

  const unapproveTimeOffRequest = async (timeOffRequestID: string) => {
    if (timeOffRequestAbilities.cannot("approve", selectedTimeOffRequests)) {
      Notifier.error("You don't have permission to unapprove this time off request.");
      return;
    }

    setLoading(true);
    try {
      const responseData = await MiterAPI.time_off.requests.update(timeOffRequestID, {
        data: { status: "unapproved" },
      });

      if (responseData.error) throw new Error(responseData.error);
      Notifier.success("Unapproved time off request.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  };

  const markTimeOffRequestsAsPaid = async (timeOffRequestIds: string[]) => {
    if (timeOffRequestAbilities.cannot("update", selectedTimeOffRequests)) {
      Notifier.error("You don't have permission to mark this time off request as paid.");
      return;
    }

    setLoading(true);
    try {
      const responseData = await MiterAPI.time_off.requests.update_multiple({
        ids: timeOffRequestIds,
        update: { status: "paid" },
      });

      if (responseData.error) throw new Error(responseData.error);
      Notifier.success("Marked time off request as paid.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }

    setLoading(false);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  };

  /*********************************************************
   *  Handler functions for the modal and table
   **********************************************************/

  // tmId below is the ID of the TM of the request that was just featured in the modal. Need it passed back
  // from down the chain for when a new request is created and we don't know the TM directly here
  const handleModalSubmit = (keepOpen: boolean, tmId?: string) => {
    const tmIdImpacted = tmId || activeTimeOffRequest?.employee._id.toString();

    getTimeOffRequests();
    setShowModal(keepOpen);
    onDataChange?.(tmIdImpacted ? [tmIdImpacted] : undefined);
    if (!keepOpen) setActiveTimeOffRequest(null);
    refetchActionableItems();
    setRefreshCount((prev) => prev + 1);
  };

  const handleModalHide = () => {
    setShowModal(false);
    setActiveTimeOffRequest(null);
  };

  const handleRowClick = (row) => {
    setActiveTimeOffRequest(row);
    setShowModal(true);
  };

  const handleDelete = (selected: RequestTableEntry[]) => {
    const selectedIds = selected.map((r) => r._id);

    setShowDeleteConfirmation(true);
    setIDsForDeletion(selectedIds);
  };

  const handleSelect = (selections) => {
    setSelectedTimeOffRequests(selections);
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  /*********************************************************
   *  Helper function for deleting multiple time off requests
   **********************************************************/

  const deleteTimeOffRequests = async () => {
    const tmIds = providedTimeOffRequests
      ?.filter((r) => idsForDeletion.includes(r._id))
      .map((r) => r.employee._id.toString() as string);

    await Promise.all(idsForDeletion.map(async (id) => deleteTimeOffRequest(id)));
    getTimeOffRequests();
    setIDsForDeletion([]);
    hideDeleteConfirmation();
    setSelectedTimeOffRequests([]);
    onDataChange?.(tmIds && [...new Set(tmIds)]);
    refetchActionableItems();
  };

  const approveTimeOffRequests = async () => {
    const tmIds = providedTimeOffRequests
      ?.filter((r) => selectedTimeOffRequests.find((s) => s._id === r._id))
      .map((r) => r.employee._id.toString() as string);

    await Promise.all(selectedTimeOffRequests.map(async (r) => approveTimeOffRequest(r._id)));
    getTimeOffRequests();
    setSelectedTimeOffRequests([]);
    onDataChange?.(tmIds && [...new Set(tmIds)]);
    refetchActionableItems();
  };

  const denyTimeOffRequests = async () => {
    const tmIds = providedTimeOffRequests
      ?.filter((r) => selectedTimeOffRequests.find((s) => s._id === r._id))
      .map((r) => r.employee._id.toString() as string);

    await Promise.all(selectedTimeOffRequests.map(async (r) => denyTimeOffRequest(r._id)));
    getTimeOffRequests();
    setSelectedTimeOffRequests([]);
    onDataChange?.(tmIds && [...new Set(tmIds)]);
    refetchActionableItems();
  };

  const unapproveTimeOffRequests = async () => {
    const tmIds = providedTimeOffRequests
      ?.filter((r) => selectedTimeOffRequests.find((s) => s._id === r._id))
      .map((r) => r.employee._id.toString() as string);

    await Promise.all(selectedTimeOffRequests.map(async (r) => unapproveTimeOffRequest(r._id)));
    getTimeOffRequests();
    setSelectedTimeOffRequests([]);
    onDataChange?.(tmIds && [...new Set(tmIds)]);
    refetchActionableItems();
  };

  const handleMarkTimeOffRequestsAsPaid = async () => {
    const tmIds = providedTimeOffRequests
      ?.filter((r) => selectedTimeOffRequests.find((s) => s._id === r._id))
      .map((r) => r.employee._id.toString() as string);

    const timeOffRequestIds = selectedTimeOffRequests.map((r) => r._id);
    await markTimeOffRequestsAsPaid(timeOffRequestIds);

    // Refetch team members to get updated balances
    await refetchTeam();

    getTimeOffRequests();
    setShowMarkAsPaidConfirmation(false);
    setSelectedTimeOffRequests([]);
    onDataChange?.(tmIds && [...new Set(tmIds)]);
    refetchActionableItems();
  };

  /*********************************************************
    Config buttons for the table
  **********************************************************/
  const dynamicActions = useMemo(() => {
    const acts: TableActionLink[] = [];

    if (inboxMode === "needs_attention") return acts;

    if (selectedTimeOffRequests.every((r) => r.status === "unapproved" || r.status === "denied")) {
      acts.push({
        label: "Delete",
        action: () => handleDelete(selectedTimeOffRequests),
        className: "button-1 table-button",
        icon: <TrashSimple weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />,
        shouldShow: () => timeOffRequestAbilities.can("delete", selectedTimeOffRequests),
      });
    }

    if (selectedTimeOffRequests.every((r) => r.status === "unapproved")) {
      acts.push(
        {
          label: "Deny",
          action: () => denyTimeOffRequests(),
          className: "button-1 table-button",
          icon: <X weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />,
          shouldShow: () => timeOffRequestAbilities.can("approve", selectedTimeOffRequests),
        },
        ...kickBackItemAction,
        {
          label: "Approve",
          action: () => approveTimeOffRequests(),
          className: "button-2 table-button",
          icon: <Check weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />,
          shouldShow: () => timeOffRequestAbilities.can("approve", selectedTimeOffRequests),
        }
      );
    }

    if (selectedTimeOffRequests.every((r) => r.status === "approved")) {
      acts.push(
        {
          label: "Unapprove",
          action: () => unapproveTimeOffRequests(),
          className: "button-1 table-button",
          icon: <CheckCircle weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />,
          shouldShow: () => timeOffRequestAbilities.can("approve", selectedTimeOffRequests),
        },
        {
          label: "Mark as paid",
          className: "button-2 table-button",
          action: () => setShowMarkAsPaidConfirmation(true),
          icon: <Check weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />,
          shouldShow: () => timeOffRequestAbilities.can("update", selectedTimeOffRequests),
        }
      );
    }

    return acts;
  }, [selectedTimeOffRequests, timeOffRequestAbilities.can, kickBackItemAction]);

  const staticActions: TableActionLink[] = useMemo(() => {
    if (inboxMode === "approval") {
      return [
        {
          label: "Approve",
          className: "button-2 table-button",
          action: () => {
            Notifier.warning("Please select the time off requests you want to approve.");
          },
          icon: <Check weight="bold" style={{ marginRight: 3 }} />,
          loading: loading,
          important: true,
        },
      ];
    }

    if (inboxMode === "needs_attention") {
      return [];
    }

    if (inboxMode !== "approval" && inboxMode !== "needs_attention") {
      return [
        {
          label: "New request",
          icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
          action: () => setShowModal(true),
          className: "button-2",
          important: true,
          shouldShow: () =>
            miterAbilities.can("time_off:requests:personal:create") ||
            miterAbilities.can("time_off:requests:others:create"),
        },
      ];
    } else return [];
  }, [miterAbilities.can, inboxMode]);

  const columns = useMemo<ColumnConfig<RequestTableEntry>[]>(() => {
    const cols: ColumnConfig<RequestTableEntry>[] = [];

    if (alternateColumns) {
      cols.push(...alternateColumns, ...approvalGroupColumns);
      return cols;
    }

    if (!employee) {
      cols.push(
        {
          field: "employee.full_name",
          filterField: "employee.full_name",
          headerName: "Employee",
          dataType: "string",
          minWidth: 200,
          enableRowGroup: true,
        },
        {
          field: "employee.friendly_id",
          headerName: "Employee ID",
          dataType: "string",
          initialHide: true,
          enableRowGroup: true,
        },
        {
          field: "employee.title",
          headerName: "Employee title",
          dataType: "string",
          initialHide: true,
          enableRowGroup: true,
        },
        {
          field: "crews",
          headerName: "Crews",
          dataType: "string",
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => {
              const crew = lookupCrew(params.value);
              return crew?.name;
            },
          },
          filterValueGetter: (params) => {
            const teamMemberId = params.data?.employee?._id;
            const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
            const crewIds = teamMemberCrews?.map((crew) => crew._id) || [];

            return crewIds;
          },
          valueGetter: (params) => {
            const teamMemberId = params.data?.employee?._id;
            const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
            return teamMemberCrews?.map((crew) => crew.name);
          },
          useValueFormatterForExport: true,
          valueFormatter: (params) => {
            return params.value?.join(", ");
          },
        },
        {
          field: "department_id",
          headerName: "Department",
          dataType: "string",
          enableRowGroup: true,
          filter: "agSetColumnFilter",
          valueGetter: (params) => {
            const department = lookupDepartment(
              //remove employee dept once all time off requests have one by default
              params.data?.department_id || params.data?.employee?.department_id
            );
            return department?.name;
          },
        }
      );
    }

    cols.push(
      {
        field: "time_off_policy.name",
        headerName: "Policy",
        dataType: "string",
        enableRowGroup: true,
        minWidth: 200,
      },
      {
        field: "start_date",
        headerName: "Start date",
        dataType: "date",
        dateType: "iso",
        useValueFormatterForExport: true,
        displayRangeFilter: true,
      },
      {
        field: "end_date",
        headerName: "End date",
        dataType: "date",
        dateType: "iso",
        useValueFormatterForExport: true,
      },
      {
        field: "total_hours",
        headerName: "Hours",
        dataType: "number",
        valueFormatter: (params) => params.value + " hours",
        aggFunc: "sum",
      },
      {
        field: "status",
        headerName: "Status",
        dataType: "string",
        displayType: "badge",
        colors: { unapproved: "yellow", approved: "blue", denied: "red", paid: "green" },
      },
      {
        field: "created_at",
        headerName: "Submitted on",
        dataType: "date",
        dateType: "timestamp",
        useValueFormatterForExport: true,
      },
      {
        field: "approved_or_denied_by",
        headerName: "Approver/Denier",
        dataType: "string",
        initialHide: true,
        valueFormatter: (params) => {
          if (!params.value) return "-";
          return (params.value.first_name || "") + " " + (params.value.last_name || "");
        },
      },
      ...approvalGroupColumns
    );

    return cols;
  }, [status, employee, lookupDepartment, approvalGroupColumns as $TSFixMe, alternateColumns]);

  /*********************************************************
    Toggler configs
  **********************************************************/
  const togglerConfig: TableTogglerConfig<RequestTableEntry> = {
    config: [
      { path: "unapproved", label: "Unapproved" },
      { path: "approved", label: "Approved" },
      { path: "processing", label: "Processing" },
      { path: "paid", label: "Paid" },
      { path: "denied", label: "Denied" },
      { path: "all", label: "All" },
    ],
    field: "status",
    secondary: true,
  };

  const isLoading = useMemo(() => {
    if (fetchActionableTimeOffRequests) return;
    return loading;
  }, [fetchActionableTimeOffRequests, loading]);

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/
  const renderTable = () => {
    if (fetchActionableTimeOffRequests) {
      return (
        <TableV2
          id="time-off-requests-table"
          resource="time off requests"
          columns={columns}
          getData={forageTimeOffRequests}
          ssr={true}
          onSelect={handleSelect}
          toggler={showToggler ? togglerConfig : undefined}
          staticActions={staticActions}
          dynamicActions={dynamicActions}
          showReportViews={true}
          onClick={!viewOnly ? handleRowClick : undefined}
          isLoading={isLoading}
          showTotals={providedTimeOffRequests && providedTimeOffRequests.length > 0}
          defaultSelectedRows={selectedTimeOffRequests}
          customEmptyStateMessage={emptyHeader}
          gridWrapperStyle={{ height: "100%" }}
          wrapperClassName="base-ssr-table"
          refreshCount={refreshCount}
          containerClassName={"time-off-requests-actionable-items-table-container"}
        />
      );
    }

    return (
      <TableV2
        id="time-off-requests-table"
        resource="time off requests"
        columns={columns}
        data={providedTimeOffRequests || timeOffRequests}
        onSelect={handleSelect}
        toggler={showToggler ? togglerConfig : undefined}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={true}
        onClick={!viewOnly ? handleRowClick : undefined}
        isLoading={isLoading}
        showTotals={providedTimeOffRequests && providedTimeOffRequests.length > 0}
        defaultSelectedRows={selectedTimeOffRequests}
        customEmptyStateMessage={emptyHeader}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"time-off-requests-table-container"}
      />
    );
  };

  const formItems = useTimeOffRequestFormItems();

  const renderModal = () => {
    if (!activeCompanyId || !formItems.companyId) return;

    return (
      <TimeOffRequestModal
        onHide={handleModalHide}
        onFinish={handleModalSubmit}
        timeOffRequest={activeTimeOffRequest || undefined}
        formItems={formItems}
        inboxMode={inboxMode}
      />
    );
  };

  const renderDeleteConfirmation = () => {
    const title = idsForDeletion.length === 1 ? "Delete Time Off Request" : "Delete Time Off Requests";
    const body =
      idsForDeletion.length === 1
        ? `Are you sure you want to delete this time off request?`
        : `Are you sure you want to delete these ${idsForDeletion.length} time off requests?`;

    return (
      <ConfirmModal title={title} body={body} onYes={deleteTimeOffRequests} onNo={hideDeleteConfirmation} />
    );
  };

  const renderMarkAsPaidConfirmation = () => {
    return (
      <BasicModal
        headerText="Are you sure?"
        yellowBodyText={true}
        bodyText="Are you sure you want to manually mark these time off requests as paid? Once you do so, the time off requests will no longer be editable, and they won't be included in any payrolls. This action cannot be undone."
        onHide={() => setShowMarkAsPaidConfirmation(false)}
        button1Text="Cancel"
        button2Text="Mark as Paid"
        button2Action={handleMarkTimeOffRequestsAsPaid}
        loading={loading}
        button1Action={() => setShowMarkAsPaidConfirmation(false)}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Time Off Requests | Miter</title>
      </Helmet>
      {showModal && renderModal()}
      {showDeleteConfirmation && renderDeleteConfirmation()}
      {showMarkAsPaidConfirmation && renderMarkAsPaidConfirmation()}
      {renderTable()}
    </>
  );
};
