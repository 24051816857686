import { useCallback } from "react";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { AggregatedTeamMember, MiterFilterField, PermissionPaths, TeamMember } from "dashboard/miter";
import { PermissionGroupSubModule } from "backend/models/permission-group";
import { useActiveTeamMember, useIsSuperAdmin } from "../atom-hooks";

export type FilterBuilder = (action: string) => MiterFilterField | undefined;

/**
 * This hook returns a predicate that takes an action string and returns a function that takes a team member and returns
 * a boolean indicating whether the user has the ability on an item to performan an action, in this module, with this team member
 */
export const useAbilitiesTeamPredicate = <TAction extends string>(
  subModule: PermissionGroupSubModule,
  skipOthers?: boolean
): ((action?: TAction) => (teamMember: AggregatedTeamMember | TeamMember) => boolean) => {
  const { cannot } = useMiterAbilities();
  const activeTeamMember = useActiveTeamMember();
  const isSuperAdmin = useIsSuperAdmin();

  return useCallback(
    (action?: TAction) => {
      return (teamMember: AggregatedTeamMember | TeamMember) => {
        // If the user is a super admin, they can do anything
        if (isSuperAdmin) return true;

        const personalPermissionPath = (
          skipOthers
            ? subModule + ":" + (action || "create")
            : subModule + ":personal:" + (action || "create")
        ) as PermissionPaths;

        if (cannot(personalPermissionPath) && teamMember._id === activeTeamMember?._id) {
          return false;
        }

        const othersPermissionPath = (
          skipOthers ? subModule + ":" + (action || "create") : subModule + ":others:" + (action || "create")
        ) as PermissionPaths;

        if (
          cannot(othersPermissionPath, { teamMember, isPredicate: true }) &&
          teamMember._id !== activeTeamMember?._id
        ) {
          return false;
        }

        return true;
      };
    },
    [activeTeamMember, cannot, skipOthers, isSuperAdmin]
  );
};
