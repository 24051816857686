import React, { useEffect, useState } from "react";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import DataBox from "dashboard/components/dataBox/DataBox";
import { Loader, Notifier, TextWithTooltip, usdString } from "ui";
import { CheckJurisdiction, CheckTaxParameter } from "backend/utils/check/check-types";
import { capitalize } from "lodash";
import { useCheckComponent } from "dashboard/utils/useCheckComponent";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {
  teamMember: AggregatedTeamMember;
};

type Withholding = { id: string; label: string; description: string; value: string };

type WithholdingSection = {
  id: string;
  jurisdictionLabel: string;
  withholdings: Withholding[];
};

const using2020W4Id = "spa_pimp4e1PwE6FAiis1jBI";
const additionalWithholdingId = "spa_HYEdSMPT3a9dYyaGIX4B";
const non2020W4Ids = ["spa_yqVxHQZb7aVPHrh16HRq", "spa_oF0kVhKWwgAEeilN8EaL"];
// Check's Sandbox and Production federal jurisdiction ids are different (even though the various param ids aren't different)
const federalJurisIds = ["jur_aafg9JXgNGuwsGhGbRcS", "jur_kSO0VneVfdx5TDDQ9Xnp"];

export const TeamMemberWithholdings: React.FC<Props> = (props) => {
  const { teamMember } = props;
  const { can } = useMiterAbilities();

  const [loading, setLoading] = useState(false);
  const [withholdings, setWithholdings] = useState<WithholdingSection[]>([]);

  const shouldShow = teamMember.employment_type === "employee" && can("team:read_sensitive");

  const getData = async () => {
    setLoading(true);
    try {
      const checkId = teamMember.check_id;
      if (!checkId) throw new Error("This team member is not enrolled in payroll");

      const res = await MiterAPI.check.tax_params_juris.list(checkId);
      if (res.error) throw new Error(res.error);
      setWithholdings(prepWithholdings(res.tax_params, res.jurisdictions));
    } catch (err) {
      console.error(err);
      Notifier.error("There was an issue retrieving the withholdings");
    }
    setLoading(false);
  };

  const getOnboardLink = async () => {
    setLoadingComponent(true);
    try {
      const res = await MiterAPI.team_member.retrieve_withholdings_setup_link(teamMember?._id);
      setComponentURL(res.url);
    } catch (e: $TSFixMe) {
      console.error("Error getting withholdings setup link", e);
      Notifier.error("There was an error opening this page. Please contact support.");
    }
    setLoadingComponent(false);
  };

  const { componentURL, setComponentURL, renderComponent, setLoadingComponent } = useCheckComponent(getData);

  useEffect(() => {
    if (!shouldShow) return;
    getData();
  }, [shouldShow, teamMember._id]);

  if (!shouldShow) return null;
  if (loading) return <Loader />;
  return (
    <>
      {withholdings.map((sec) => (
        <div className="details-section-wrapper" key={sec.id}>
          <DataBox
            title={sec.jurisdictionLabel}
            className="flex-col no-margin"
            onEdit={can("team:update_sensitive") ? () => getOnboardLink() : undefined}
          >
            {sec.withholdings.map((w) => (
              <div className={"data-box-section"} key={w.id}>
                <span className={"data-box-section-title font-14"}>
                  <TextWithTooltip id={w.id} text={w.label} tooltip={w.description} suppressUnderline />
                </span>
                <span className={"data-box-section-value font-14"}>{w.value}</span>
              </div>
            ))}
          </DataBox>
        </div>
      ))}
      {componentURL && renderComponent()}
    </>
  );
};

const prepWithholdings = (
  taxParams: CheckTaxParameter[],
  jurisdictions: CheckJurisdiction[]
): WithholdingSection[] => {
  const taxParamMap = taxParams.reduce((map, tp) => {
    const arr = map.get(tp.jurisdiction) || [];
    arr.push(tp);
    return map.set(tp.jurisdiction, arr);
  }, new Map<string, CheckTaxParameter[]>());

  const data: WithholdingSection[] = [];

  for (const [jId, taxParams] of taxParamMap.entries()) {
    const juris = jurisdictions.find((j) => j.id === jId);
    if (!juris) throw new Error(`Jurisdiction ${jId} not found`);

    const using2020W4Param = taxParams.find((tp) => tp.id === using2020W4Id);
    const isUsing2020W4 = using2020W4Param?.setting?.value === "true";

    const sectionWithholdings = taxParams
      .filter((tp) => {
        // Show every parameter for non-federal jurisdictions, and always show the "Using 2020 W-4" and "Additional Withholdings" federal params
        if (
          !federalJurisIds.includes(tp.jurisdiction) ||
          [using2020W4Id, additionalWithholdingId].includes(tp.id)
        ) {
          return true;
        }
        // If the employee is using the 2020 W-4 then don't show the non-2020 params, and vice versa
        const isNon2020W4Param = non2020W4Ids.includes(tp.id);
        if (isUsing2020W4 && isNon2020W4Param) return false;
        if (!isUsing2020W4 && !isNon2020W4Param) return false;
        return true;
      })
      .sort((a, b) => {
        // The 2020 W-4 param should be at the top since it gives context for rest
        if (a.id === using2020W4Id) return -1;
        if (b.id === using2020W4Id) return 1;
        // Params that are independent of other params should bubble up
        if (a.depends_on && !b.depends_on) return 1;
        if (!a.depends_on && b.depends_on) return -1;
        // Filing status should also bubble up since it gives context
        if (a.label.toLowerCase().includes("filing status")) return -1;
        if (b.label.toLowerCase().includes("filing status")) return 1;
        return 0;
      })
      .map((tp): Withholding => {
        const rawValue = tp.setting?.value;
        let value: string;
        if (!rawValue) {
          value = "-";
        } else if (tp.type === "number") {
          value = Number(rawValue).toString();
        } else if (tp.type === "boolean") {
          value = capitalize(rawValue);
        } else if (tp.type === "currency") {
          value = usdString(Number(rawValue));
        } else if (tp.type === "percent") {
          value = `${rawValue}%`;
        } else if (tp.type === "select") {
          const opt = tp.options?.find((o) => o.value === rawValue);
          if (!opt) throw new Error(`Option ${rawValue} not found`);
          value = opt.label;
        } else {
          value = rawValue;
        }
        return { id: tp.id, label: tp.label, description: tp.description, value };
      });

    const sectionData: WithholdingSection = {
      id: juris.id,
      jurisdictionLabel: juris.label,
      withholdings: sectionWithholdings,
    };

    data.push(sectionData);
  }

  data.sort((a, b) => {
    // Jurisdictions are naturally sorted alphabetically but that's silly. Start with Federal and then can go alphabetically
    if (federalJurisIds.includes(a.id)) return -1;
    if (federalJurisIds.includes(b.id)) return 1;
    return 0;
  });

  return data;
};
