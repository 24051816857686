import { useActiveCompanyId, useActiveTeamMember, useLookupPolicy } from "dashboard/hooks/atom-hooks";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { TimesheetTableEntry, bulkTimesheetSignOff } from "dashboard/utils/timesheetUtils";
import { esignatureRequired } from "dashboard/utils/validators";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier } from "ui";

type Props = {
  selectedTimesheets: TimesheetTableEntry[];
  onSubmit: () => void;
  hide: () => void;
};

type SignableTimesheetPage = {
  agreement: string;
  agreement_es: string;
  timesheetIds: string[];
};

export const SignOffModal: React.FC<Props> = ({ selectedTimesheets, onSubmit, hide }) => {
  const onModalClose = () => {
    onSubmit();
    hide();
  };

  const { setFailures, renderFailuresModal } = useFailuresModal({ onClose: onModalClose });
  const form = useForm();
  const companyId = useActiveCompanyId();
  const teamMember = useActiveTeamMember();
  const lookupPolicy = useLookupPolicy();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState<SignableTimesheetPage[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    const groupedTimesheets = {};

    selectedTimesheets.map((ts) => {
      const policy = lookupPolicy(ts.approval_stage?.policy_id);
      const rule = getSignOffRuleFromPolicy(policy);

      if (rule) {
        if (!groupedTimesheets[rule._id]) {
          groupedTimesheets[rule._id] = {
            agreement: rule.sign_off.agreement,
            agreement_es: rule.sign_off.agreement_es,
            timesheetIds: [],
          };
        }
        groupedTimesheets[rule._id]["timesheetIds"].push(ts._id);
      }
      return groupedTimesheets;
    });

    setPages(Object.values(groupedTimesheets));
  }, [selectedTimesheets]);

  const getSignOffRuleFromPolicy = (policy) => {
    return policy.rules.find((rule) => rule.sign_off.method === "signature");
  };

  const save = async (data) => {
    const page = pages[currentPage];

    if (!page || !companyId || !teamMember || selectedTimesheets.length === 0) return;
    setLoading(true);
    try {
      const response = await bulkTimesheetSignOff(data.sign_off, companyId, teamMember, page.timesheetIds);
      const error = response.find((res) => res.error);

      if (error) {
        setFailures([{ label: "Error", message: error.error }]);
      } else {
        getNextPage();
      }
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const renderPage = () => {
    const page = pages[currentPage];

    return (
      page && (
        <Formblock
          key={formKey}
          name="sign_off"
          form={form}
          type="esignature"
          rules={{ validate: esignatureRequired }}
          editing={true}
          onError={Notifier.error}
          agreement={page.agreement}
        />
      )
    );
  };

  const getNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFormKey((prevKey) => prevKey + 1);

    if (currentPage + 1 >= pages.length) {
      Notifier.success("Timesheets successfully signed.");
      onSubmit();
      hide();
    }
  };

  const renderMainModal = () => (
    <ActionModal
      headerText={`Sign Off On Timesheets`}
      onHide={hide}
      showCancel={true}
      bodyStyle={{ overflow: "visible", maxHeight: "100%" }}
      onCancel={hide}
      showSubmit={true}
      onSubmit={form.handleSubmit(save)}
      submitText={currentPage + 1 === pages.length ? "Finish" : `Next (${currentPage + 1}/${pages.length})`}
      loading={loading}
    >
      {renderPage()}
    </ActionModal>
  );

  return (
    <>
      {renderMainModal()}
      {renderFailuresModal()}
    </>
  );
};
