import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { AggregatedJob, Job, MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { ColDef, IAggFunc } from "ag-grid-community";
import { Formblock, Loader, LoadingModal } from "ui";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { reportList } from "../reportList";
import { Option } from "ui/form/Input";
import { FringeBenefitStatementEntry } from "backend/utils/reports/fringeBenefitStatement";
import { useForm } from "react-hook-form";
import { toDollarFormat } from "../reportUtils";
import { saveAs } from "file-saver";
import { useActiveCompanyId, useJobOptions, useLookupJob } from "dashboard/hooks/atom-hooks";
import { getPayPeriodsOfPaySchedule, isWeeklyOrBiweekly } from "dashboard/utils/paySchedules";
import { usePayrollAbilities } from "dashboard/hooks/abilities-hooks/usePayrollAbilities";
import { JobInput } from "dashboard/components/shared/JobInput";
import { usePayScheduleAccessor } from "dashboard/hooks/usePayScheduleAccessor";
import { baseSensitiveCompare } from "miter-utils";

export const classificationAggFunc: IAggFunc = (params) => {
  if (params.rowNode.field === "classificationLabel") {
    return params.values.reduce((sum, v) => sum + v, 0);
  }
};

const baseJobsPredicate = (j: AggregatedJob | Job) => !!j.start_date && !j.archived;

export const FringeBenefitStatement: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { canAccessPaySchedule } = usePayScheduleAccessor();
  const lookupJob = useLookupJob();
  const navigate = useNavigate();
  const form = useForm({ shouldUnregister: false });
  const payrollAbilities = usePayrollAbilities();

  const jobOptions = useJobOptions({
    predicate: (job) => baseJobsPredicate(job) && payrollAbilities.jobPredicate("read")(job),
    overrideBasePredicate: true,
  });

  const [data, setData] = useState<FringeBenefitStatementEntry[] | undefined>();
  const [fetchingData, setFetchingData] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  // We need to filter out payrolls covering periods before the selected job's start date
  const [allWeekEndingOptions, setAllWeekEndingOptions] = useState<Option<string>[]>([]);
  const [filteredWeekEndingOptions, setFilteredWeekEndingOptions] = useState<Option<string>[]>([]);
  const [selectedWeekEnding, setSelectedWeekEnding] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<Option<string> | null>(null);

  const getWeekEndingOptions = async () => {
    try {
      const response = await MiterAPI.pay_schedules.retrieve({
        filter: [
          {
            field: "company_id",
            value: activeCompanyId,
          },
          {
            field: "inactive",
            value: { $in: [null, false] },
          },
        ],
      });

      if (response.error) throw new Error(response.error);

      const paySchedules = response.filter((ps) => canAccessPaySchedule(ps));

      const weeks = new Set<string>();
      for (const ps of paySchedules) {
        const payFrequency = ps.check_pay_schedule.pay_frequency;
        if (!isWeeklyOrBiweekly(payFrequency)) continue;

        const threeYearsAgo = DateTime.now().minus({ years: 3 });
        const periods = getPayPeriodsOfPaySchedule(ps, threeYearsAgo, DateTime.now());

        for (const period of periods) {
          if (payFrequency === "biweekly") {
            weeks.add(DateTime.fromISO(period.periodEnd).minus({ weeks: 1 }).toISODate());
          }
          weeks.add(period.periodEnd);
        }
      }

      const options = [...weeks]
        .map((week): Option<string> => ({ label: DateTime.fromISO(week).toFormat("DD"), value: week }))
        .sort((a, b) => baseSensitiveCompare(b.value, a.value));

      setAllWeekEndingOptions(options);
      setFilteredWeekEndingOptions(options);
    } catch (e) {
      Notifier.error("There was an error. We're looking into it!");
    }
  };

  const handleJobSelect = (option: Option<string> | null): void => {
    setSelectedJob(option);
    const selectedJobObj = lookupJob(option?.value);
    const startDate = selectedJobObj?.cpr_info?.payroll_start_date || selectedJobObj?.start_date;
    const newWeekEndingOptions = allWeekEndingOptions.filter((o) => startDate && o.value >= startDate);
    setSelectedWeekEnding(null);
    setFilteredWeekEndingOptions(newWeekEndingOptions);
  };

  const getData = async (format: string) => {
    if (!selectedWeekEnding || !selectedJob) return;

    setFetchingData(format === "json");
    setDownloadingPDF(format === "pdf");

    try {
      const payload = {
        type: "fringe_benefit_statement",
        params: {
          weekEnding: selectedWeekEnding,
          jobId: selectedJob.value,
        },
        format,
      };

      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      if (payload.format === "json") {
        setData(response);
      } else if (payload.format === "pdf") {
        console.log("PDF response", response);
        const blob = new Blob([Uint8Array.from(response.buffer.data).buffer], { type: "application/pdf" });
        saveAs(blob, response.filename);
      }
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    setFetchingData(false);
    setDownloadingPDF(false);
  };

  useEffect(() => {
    getData("json");
  }, [selectedJob, selectedWeekEnding]);

  useEffect(() => {
    getWeekEndingOptions();
  }, []);

  const reportObject = reportList.find((report) => report.slug === "fringe_benefit_statement");

  const fileName = "Fringe Benefit Statement";

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <div className="flex">
          <h1 style={{ margin: 0 }}>Fringe Benefit Statement</h1>
        </div>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 500 }}>
        <JobInput
          label="Job"
          type="select"
          name="job"
          form={form}
          onChange={handleJobSelect}
          value={jobOptions.find((option) => option.value === selectedJob?.value)}
          editing={true}
          options={jobOptions}
          isClearable
        />
        <Formblock
          label="Week ending"
          labelInfo="Showing all period end dates of paid payrolls."
          type="select"
          value={filteredWeekEndingOptions.find((option) => option.value === selectedWeekEnding)}
          onChange={(o) => setSelectedWeekEnding(o.value)}
          name="week_ending"
          form={form}
          editing={true}
          options={filteredWeekEndingOptions}
        />
      </div>
      {fetchingData ? (
        <Loader />
      ) : (
        <AgGridTable
          data={data}
          columnDefs={colDefs}
          fileName={fileName}
          gridOptions={{
            autoGroupColumnDef: {
              minWidth: 400,
              cellRendererParams: {
                suppressCount: true,
              },
              headerName: "Employee/classification",
            },
            groupDefaultExpanded: 1,
            aggFuncs: {
              classificationAggFunc,
            },
          }}
          supplementalTableActions={[
            {
              text: "Download PDF",
              onClick: () => getData("pdf"),
            },
          ]}
        />
      )}

      <div className="vertical-spacer-large"></div>
      {downloadingPDF && <LoadingModal text="Downloading PDF" />}
    </div>
  );
};

const colDefs: ColDef<FringeBenefitStatementEntry>[] = [
  {
    field: "tmLabel",
    headerName: "Team member",
    filter: true,
    initialRowGroup: true,
    initialHide: true,
    initialWidth: 400,
  },
  {
    field: "classificationLabel",
    headerName: "Classification",
    filter: true,
    initialRowGroup: true,
    initialHide: true,
    initialWidth: 400,
  },
  {
    field: "label",
    headerName: "Benefit",
    filter: true,
  },
  {
    field: "benefitCategory",
    headerName: "Category",
    filter: true,
  },
  {
    field: "sourceType",
    headerName: "Source",
    filter: true,
  },
  {
    field: "amountPerHour",
    headerName: "Fringe contributions ($/hour)",
    filter: true,
    aggFunc: "classificationAggFunc",
    valueFormatter: toDollarFormat,
  },
];
