import { ReportComponentLookup } from "./reportHooks";

export type ReportConfig = {
  slug: keyof typeof ReportComponentLookup;
  label: string;
  roles_without_access?: string[];
  description: string;
  link: string;
  query?: {
    [x: string]: string;
  };
  category: string;
  hide_from_main_list?: boolean;
  beta?: boolean;
};

export const reportList: ReportConfig[] = [
  {
    slug: "cpr",
    label: "Certified Payroll Report",
    description:
      "Weekly report of hours, payroll, and fringes by employee and classification. Required on public works jobs.",
    link: "/reports/cpr",
    category: "labor_compliance",
  },
  {
    slug: "pay_period_hours",
    label: "Hours by pay period",
    roles_without_access: [],
    description: "View approved hours by team member, job, and activity for a specific pay period.",
    link: "/reports/pay-period-hours",
    category: "general",
  },
  {
    slug: "earnings_summary",
    label: "Earnings",
    description: "Explore hours and earnings for any team member for any set of payrolls.",
    link: "/reports/earnings-summary",
    category: "general",
  },
  {
    slug: "fringe_report",
    label: "Fringe report",
    description:
      "See hours, earnings, and fringe contributions/deductions by pay rate group and classification for any set of payrolls.",
    link: "/reports/fringe-report",
    category: "general",
  },
  {
    slug: "payroll_register",
    label: "Payroll register",
    description:
      "View comprehensive employee payroll details, including hours worked, earnings, and deductions by team member.",
    link: "/reports/payroll-register",
    category: "general",
    hide_from_main_list: true,
  },
  {
    slug: "payroll_journal",
    label: "Payroll journal",
    description:
      "View your team's earnings, deductions, and taxes, and download journal entries for your accounting system.",
    link: "/reports/payroll-journal",
    category: "general",
  },
  {
    slug: "das_140",
    label: "DAS 140",
    description:
      "Required form for all contractors utilizing an apprenticeable craft in the state of California.",
    link: "/reports/das-140",
    category: "labor_compliance",
  },
  {
    slug: "das_142",
    label: "DAS 142",
    description:
      "This form is used to request the dispatch of an apprentice for California public works projects.",
    link: "/reports/das-142",
    category: "labor_compliance",
  },
  {
    slug: "job_costing",
    label: "Job costing",
    description: "View a breakdown of hours and labor costs by job, activity, and team member.",
    link: "/reports/job-costing",
    category: "general",
  },
  {
    slug: "timesheet_analysis",
    label: "Timesheet analysis",
    description: "Analyze hours and labor costs from timesheets.",
    link: "/reports/timesheet-report",
    category: "general",
  },
  {
    slug: "eeo_1",
    label: "EEO-1 Report",
    description: "Employee count by ethnicity, gender, and job category.",
    link: "/reports/eeo-1",
    category: "labor_compliance",
  },
  {
    slug: "team_summary",
    label: "Team member summary",
    description: "View all details about your team members' employment information and compensation.",
    link: "/reports/team-summary",
    category: "general",
  },
  {
    slug: "deductions_contributions",
    label: "Deductions and contributions",
    description: "See aggregate deductions and contributions for all employees for any set of payrolls.",
    link: "/reports/deductions",
    category: "general",
  },
  {
    slug: "reimbursements",
    label: "Reimbursements",
    description: "Analyze reimbursements across any set of payrolls.",
    link: "/reports/reimbursements",
    category: "general",
  },
  {
    slug: "workers_comp",
    label: "Workers' comp",
    description: "See a breakdown of hours and earnings for each employee, job, and workers' comp code.",
    link: "/reports/workers-comp",
    category: "general",
  },
  {
    slug: "401k_report",
    label: "401K contributions",
    description: "See employer and employee 401K contributions for any payroll.",
    link: "/reports/401k-report",
    category: "general",
  },
  {
    slug: "simple_ira_report",
    label: "SIMPLE IRA contributions",
    description: "See employer and employee SIMPLE IRA contributions for any payroll.",
    link: "/reports/simple-ira-report",
    category: "general",
  },
  {
    slug: "fringe_benefit_statement",
    label: "Fringe benefit statement",
    description: "Summarize fringe contributions by employee and classification.",
    link: "/reports/fringe-benefit-statement",
    category: "labor_compliance",
  },
  {
    slug: "apprentice_ratio",
    label: "Apprentice ratio",
    description: "See the breakdown of journeymen vs apprentices hours by job for any set of payrolls.",
    link: "/reports/apprentice-ratio",
    category: "labor_compliance",
  },
  {
    slug: "training_contributions",
    label: "Training contributions",
    description: "Explore hours and training contributions by classification.",
    link: "/reports/training-contributions",
    category: "labor_compliance",
  },
  {
    slug: "time_off_balances",
    label: "Vacation and sick time balances",
    description: "See the vacation and sick time balances of each employee.",
    link: "/reports/time-off-balances",
    category: "general",
  },
  {
    slug: "birthdays_and_tenure_milestones",
    label: "Birthdays and tenure milestones",
    description: "See a list of employees with birthdays and tenure milestones.",
    link: "/reports/birthdays-and-tenure-milestones",
    category: "general",
  },
  {
    slug: "ledger_entry",
    label: "Ledger entry line items",
    description: "View the full list of line items for this payroll's ledger entry.",
    link: "/reports/ledger-entry",
    category: "general",
  },
  {
    slug: "pay_rate_histories",
    label: "Pay rate & title histories",
    description: "See a history of each team members' default pay rate and title.",
    link: "/reports/pay-rate-histories",
    category: "general",
  },
  {
    slug: "expenses",
    label: "Expenses",
    description:
      "Get a list of approved card transactions and reimbursements. Filter on the time period, job, and/or team member. Receipt images are attached to the final report.",
    link: "/reports/expenses",
    category: "general",
  },
  {
    slug: "miter_card_statement",
    label: "Miter Card Statement",
    description: "Monthly statements for Miter Card transactions.",
    link: "/reports/miter-card-statement",
    category: "general",
  },
  {
    slug: "tax_deposits",
    label: "Tax deposits",
    description: "Get a list of tax deposits and their status by payroll.",
    link: "/reports/tax-deposits",
    category: "general",
  },
  {
    slug: "tax_filings",
    label: "Tax filings",
    description: "Get a list of failed tax filings.",
    link: "/reports/tax-filings",
    category: "general",
  },
  {
    slug: "w2_preview",
    label: "W2 preview",
    description: "Get a preview of W-2 data for all employees as of the current date for a specified year.",
    link: "/reports/w2-preview",
    category: "general",
  },
  {
    slug: "change_requests",
    label: "Change requests",
    description: "View team member profile change requests",
    link: "/reports/change-requests",
    category: "general",
  },
  {
    slug: "team_member_retention",
    label: "Team member retention",
    description: "View team member turnover/retention rates",
    link: "/reports/team-member-retention",
    category: "general",
  },
  {
    slug: "health_cues",
    label: "Healthcues data",
    description: "View census data needed for Healthcues",
    link: "/reports/health-cues",
    category: "general",
  },
  {
    slug: "enrollment_census",
    label: "Enrollment census",
    description: "View demographic and election information for employees and their dependents",
    link: "/reports/enrollment-census",
    category: "general",
  },
];
