import { useEffect, useRef, useState } from "react";
import {
  useActiveCompany,
  useActiveTeamChatConversation,
  useSetActiveTeamChatConversation,
  useSetIsTeamChatInitialized,
  useSetTeamChatConversations,
  useSetTeamTwilioClient,
  useTeamChatConversations,
  useTeamChatRestart,
  useTeamTwilioClient,
} from "../atom-hooks";
import { ParticipantUpdatedData, TwilioClient, TwilioConnectionStatus } from "dashboard/types/chat";
import { DateTime } from "luxon";
import { MiterAPI } from "dashboard/miter";
import { ConnectionState, Client as ConversationsClient, Message, Participant } from "@twilio/conversations";
import {
  conversationSorter,
  markTwilioConversationAsRead,
  twilioClientConnectStateLookup,
} from "dashboard/utils/chat";

export const useTeamChat = (): void => {
  /** Atom hooks */
  const teamConversations = useTeamChatConversations();
  const setTeamConversations = useSetTeamChatConversations();

  const activeTeamConversation = useActiveTeamChatConversation();
  const setActiveTeamConversation = useSetActiveTeamChatConversation();

  const setTeamChatInitialized = useSetIsTeamChatInitialized();

  const teamTwilioClient = useTeamTwilioClient();
  const setTeamTwilioClient = useSetTeamTwilioClient();

  const restartChatCount = useTeamChatRestart();

  /** Internal helpers */
  const [_twilioConnectionStatus, setTwilioConnectionStatus] = useState<TwilioConnectionStatus>();
  const [lastRefreshedChat, setLastRefreshedChat] = useState(DateTime.now());
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const teamConversationsRef = useRef(teamConversations);
  const activeTeamConversationRef = useRef(activeTeamConversation);

  /** Use Effects */

  useEffect(() => {
    teamConversationsRef.current = teamConversations;
  }, [teamConversations]);

  useEffect(() => {
    activeTeamConversationRef.current = activeTeamConversation;
  }, [activeTeamConversation]);

  useEffect(() => {
    if (!activeCompanyId) return;

    initializeTeamChat();
    return () => {
      clearTeamChat();
    };
  }, [activeCompanyId, restartChatCount]);

  // Initialize and teardown the Twilio listeners for chat
  useEffect(() => {
    if (!teamTwilioClient) return;
    initializeTeamTwilioListeners();

    return () => {
      teardownTeamTwilioListeners();
    };
  }, [teamTwilioClient]);

  // If we navigate away from chat, set the active conversation to null
  useEffect(() => {
    if (!window.location.pathname.includes("/chat") && activeTeamConversationRef.current) {
      setActiveTeamConversation(null);
    }
  }, [window.location.pathname]);

  // If connection state is denied or disconnected, refresh the page
  useEffect(() => {
    const chatDisconnected =
      teamTwilioClient?.connectionState === "denied" || teamTwilioClient?.connectionState === "disconnected";

    if (chatDisconnected) {
      initializeTeamChat();
    }
  }, [teamTwilioClient?.connectionState, lastRefreshedChat]);

  // Every 15 minutes update last refreshed chat
  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshedChat(DateTime.now());
    }, 1000 * 60 * 15);

    return () => clearInterval(interval);
  }, []);

  /** Utility functions */
  const initializeTeamChat = async () => {
    if (!activeCompany?.chat?.conversations_service_sid) return;
    const twilioAccessToken = await getTeamTwilioAccessToken();
    if (!twilioAccessToken) {
      return;
    }

    const teamTwilioClientRes = await initializeTeamTwilioClient(twilioAccessToken);
    setTeamTwilioClient(teamTwilioClientRes);
  };

  const clearTeamChat = () => {
    setTeamConversations([]);
    setActiveTeamConversation(null);
    setTeamTwilioClient(null);
    setTeamChatInitialized(false);

    // remove twilio keys from sessionStorage
    for (const key in sessionStorage) {
      if (sessionStorage.hasOwnProperty(key) && sessionStorage[key].includes("twilio")) {
        sessionStorage.removeItem(key);
      }
    }
  };

  /** Business logic helpers */
  const getTeamTwilioAccessToken = async () => {
    if (!activeCompanyId) return;
    try {
      const response = await MiterAPI.chat.get_token(activeCompanyId);
      if (response.error) throw new Error(response.error);

      return response.token;
    } catch (e: $TSFixMe) {
      console.log(e);
    }
  };

  /** Twilio logic */
  const initializeTeamTwilioClient = async (twilioAccessToken: string): Promise<TwilioClient> => {
    return new Promise((resolve, reject) => {
      const client = new ConversationsClient(twilioAccessToken!);

      client.on("stateChanged", (state) => {
        if (state === "initialized") {
          resolve(client);
        } else if (state === "failed") {
          reject(client);
        }
      });
    });
  };

  const initializeTeamTwilioListeners = async () => {
    if (!teamTwilioClient) {
      console.error("Unable to initialize team twilio listeners: no twilio client");
      return;
    }

    setTwilioConnectionStatus({ message: "Connecting to Twilio...", status: "connecting" });

    teamTwilioClient.on("connectionStateChanged", handleConnectionStateChange);
    teamTwilioClient.on("messageAdded", handleMessageAdded);
    teamTwilioClient.on("participantUpdated", handleParticipantUpdated);

    teamTwilioClient.on("conversationJoined", (_conversation) => {});
    teamTwilioClient.on("conversationLeft", (_conversation) => {});
  };

  const teardownTeamTwilioListeners = async () => {
    if (!teamTwilioClient) {
      return console.error("Unable to tear down twilio listeners: no twilio client");
    }

    teamTwilioClient.off("connectionStateChanged", handleConnectionStateChange);
    teamTwilioClient.off("messageAdded", handleMessageAdded);
    teamTwilioClient.off("participantUpdated", handleParticipantUpdated);

    teamTwilioClient.off("conversationJoined", (_conversation) => {});
    teamTwilioClient.off("conversationLeft", (_conversation) => {});
  };

  /** Twilio logic helpers */
  const handleConnectionStateChange = (state: ConnectionState) => {
    setTwilioConnectionStatus({ message: twilioClientConnectStateLookup[state], status: state });
  };

  const handleMessageAdded = async (message: Message) => {
    updateConversations({ message });
  };

  const handleParticipantUpdated = async (data: ParticipantUpdatedData) => {
    updateConversations({ participant: data.participant });
  };

  /** State management helpers  */

  const updateConversations = async ({
    message,
    participant,
  }: {
    message?: Message;
    participant?: Participant;
  }) => {
    const twilioConversation = message?.conversation || participant?.conversation;
    const sender = await message?.getParticipant();

    // Update conversations array
    const newConversations = await Promise.all(
      teamConversationsRef.current.map(async (conversation) => {
        if (conversation.conversation_sid === twilioConversation?.sid) {
          const unreadCount = await twilioConversation?.getUnreadMessagesCount();
          let unread = !!unreadCount;
          const activeConversationSid = activeTeamConversationRef.current?.conversation_sid;

          if (message && (twilioConversation.sid === activeConversationSid || sender?.type === "chat")) {
            markTwilioConversationAsRead(twilioConversation);
            unread = false;
          }

          if (participant && participant.type === "chat") {
            unread = false;
          }

          const messages = await twilioConversation?.getMessages();

          const last_message = messages?.items.pop() || {
            body: "New conversation",
            dateCreated: twilioConversation?.dateCreated as Date,
          };

          return {
            ...conversation,
            twilio_conversation: twilioConversation,
            unread,
            last_message: last_message as Message,
          };
        }
        return conversation;
      })
    );

    const sortedConversations = newConversations.sort(conversationSorter);
    setTeamConversations(sortedConversations);
  };
};
