/* eslint-disable @typescript-eslint/no-explicit-any */
import { Department, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Gear, Plus, TrashSimple } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { BasicModal } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import DepartmentModal from "./DepartmentModal";
import {
  useDepartmentsAtom,
  useLedgerMappingOptions,
  useLookupLedgerMapping,
  useLookupTeam,
  useRefetchDepartments,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useNavigate } from "react-router-dom";

const DepartmentTable: React.FC = () => {
  const navigate = useNavigate();
  const refectchDepartments = useRefetchDepartments();
  const [departments, setDepartments] = useDepartmentsAtom();
  const ledgerMappingOptions = useLedgerMappingOptions();
  const lookupLedgerMapping = useLookupLedgerMapping();
  const lookupTeam = useLookupTeam();
  const tmOptions = useTeamOptions();
  const { can, cannot } = useMiterAbilities();

  // States related to the table
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Department[]>([]);

  // States related to table actions
  const [adding, setAdding] = useState<boolean>(false);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>();
  const [archiving, setArchiving] = useState<boolean>(false);

  const handleArchive = async () => {
    if (cannot("lists:departments:manage")) return;

    setLoading(true);
    try {
      const archivedDepts: Department[] = [];
      let error = "";
      await Promise.all(
        selectedRows.map(async (department) => {
          const response = await MiterAPI.departments.delete(department._id);
          if (response.error) {
            error = response.error;
          } else {
            archivedDepts.push(response);
          }
        })
      );

      setDepartments((prev) => prev.concat(archivedDepts));

      if (error) throw new Error(error);

      const singPlur = selectedRows.length > 1 ? "Departments" : "Department";
      Notifier.success(singPlur + " successfully deleted.");
      setArchiving(false);
      setSelectedRows([]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting one or more department. Please reach out to support.");
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setAdding(false);
    setArchiving(false);
    setSelectedDepartment(undefined);
  };

  const handleModalSubmit = () => {
    setAdding(false);
    setArchiving(false);
  };

  const columns = useMemo(() => {
    const cols: ColumnConfig<Department>[] = [
      {
        headerName: "Name",
        field: "name",
        editable: (params) => !params.data?.integrations,
        dataType: "string",
      },
      {
        headerName: "Identifier",
        field: "identifier",
        editable: (params) => !params.data?.integrations,
        dataType: "string",
      },
      {
        headerName: "Department head",
        field: "department_head_id",
        dataType: "string",
        editorType: "select",
        cellEditorParams: {
          options: tmOptions,
          isClearable: true,
        },
        editable: true,
        valueGetter: (params) => {
          const team = lookupTeam(params.data?.department_head_id);
          return team?.full_name || "-";
        },
      },
      {
        field: "ledger_mapping_id",
        headerName: "GL account mapping",
        editorType: "select",
        cellEditorParams: {
          options: ledgerMappingOptions,
          isClearable: true,
        },
        editable: true,
        valueGetter: (params) => {
          const mapping = lookupLedgerMapping(params.data?.ledger_mapping_id);
          return mapping?.name;
        },
      },
    ];
    return cols;
  }, [departments, lookupTeam]);

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Add department",
        className: "button-2",
        action: () => setAdding(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:departments:manage"),
      },
      {
        label: "Settings",
        className: "button-1 ",
        action: () => navigate("settings"),
        important: true,
        icon: <Gear weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:departments:manage"),
      },
    ];
    return acts;
  }, [can]);

  const dynamicActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:departments:manage"),
      },
    ];
    return acts;
  }, [can]);

  const onBulkSave = async (data: Department[]) => {
    try {
      const updateData = data.map((d) => {
        const { _id, created_at, updated_at, integrations, company_id, ...rest } = d;
        return { _id, params: rest };
      });
      const response = await MiterAPI.departments.updateMany(updateData);
      if (response.error) throw new Error(response.error);
      await refectchDepartments();
      return response;
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={"departments-table"}
        resource="departments"
        data={departments}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        editable={can("lists:departments:manage")}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={setSelectedDepartment}
        showReportViews={true}
        onSave={onBulkSave}
      />
    );
  };

  return (
    <div className="department-table-wrapper">
      {adding && <DepartmentModal onHide={handleModalClose} onFinish={handleModalSubmit} />}
      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={handleArchive}
          headerText={"Delete department" + (selectedRows.length > 1 ? "s" : "")}
          bodyText={
            "Are you sure you want to delete the selected department" +
            (selectedRows.length > 1 ? "s" : "") +
            "? Deleting the department will remove it from all team members. To restore a deleted department, you must contact Miter Support."
          }
        />
      )}
      {selectedDepartment && (
        <DepartmentModal
          onHide={handleModalClose}
          onFinish={handleModalSubmit}
          departmentID={selectedDepartment._id}
        />
      )}
      {renderTable()}
    </div>
  );
};

export default DepartmentTable;
