import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Notifier, Toggler } from "ui";
import { Helmet } from "react-helmet";
import LinkedAccountsTable from "dashboard/components/banking/LinkedAccountsTable";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { ExpenseSettings } from "./ExpenseSettings";
import ExpenseFunding, { ExpenseFundingGetStarted } from "../cards/ExpenseFunding";
import { useStripeConnectedAccount } from "dashboard/hooks/atom-hooks";
import { isMiterCardsAccountFullyActive } from "./expenseUtils";
import { ExpensePolicies } from "./ExpensePolicies";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { PerDiemRateSettings } from "./PerDiemRateSettings";

const ExpenseSettingsV2: React.FC = () => {
  const navigate = useNavigate();
  const { cannot } = useMiterAbilities();

  useEffect(() => {
    if (cannot("expenses:settings")) {
      navigate("/home");
      Notifier.error("You do not have permission to view this page.");
    }
  }, [cannot]);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Cards | Miter</title>
      </Helmet>
      <div className="page-content">
        <div>
          <div className="blue-link" onClick={() => navigate("/expenses")}>
            {"Back to Expenses"}
          </div>
          <h1>Expense Settings</h1>
          <ExpenseWrapper />
        </div>
      </div>
    </div>
  );
};

export const ExpenseWrapper: React.FC = () => {
  const { view } = useParams<Params>();
  const navigate = useNavigate();

  // TODO: refresh button doesn't update account balance because it's cached from atom. add explicit refresh hook
  const stripeAccount = useStripeConnectedAccount();
  const [refresh, setRefresh] = useState<number>(0);

  const toggle = (page: string) => navigate("/expenses/settings/" + page, { replace: true });

  const renderView = () => {
    if (!view || view === "policies") {
      return <ExpensePolicies />;
    } else if (view === "settings") {
      return <ExpenseSettings />;
    } else if (view === "per-diems") {
      return <PerDiemRateSettings />;
    } else if (view === "balance") {
      if (isMiterCardsAccountFullyActive(stripeAccount) === false) {
        return <ExpenseFundingGetStarted stripeAccount={stripeAccount} />;
      } else {
        return <ExpenseFunding stripeAccount={stripeAccount!} refreshData={() => setRefresh(refresh + 1)} />;
      }
    } else if (view === "banking") {
      return <LinkedAccountsTable />;
    }
  };

  const fullTogglerConfig: TogglerConfigItem[] = [
    { path: "policies", label: "Policies" },
    { path: "settings", label: "General settings" },
    { path: "per-diems", label: "Per diems" },
    { path: "balance", label: "Miter card funding balance" },
    { path: "banking", label: "External bank accounts" },
  ];

  return (
    <>
      <Toggler config={fullTogglerConfig} active={view || "policies"} toggle={toggle} />
      {renderView()}
    </>
  );
};

type Params = {
  view?: "policies" | "settings" | "per-diems" | "balance" | "banking";
};

export default ExpenseSettingsV2;
