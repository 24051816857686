import { MiterAPI, ReportView } from "dashboard/miter";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Option } from "ui/form/Input";
import Select from "react-select";
import { ActionModal, Button } from "ui";
import { Pencil, PlusCircle, XCircle, User, UsersThree } from "phosphor-react";
import { Notifier } from "ui";
import { selectStyles } from "ui/form/styles";
import styles from "./ReportViewSelect.module.css";
import { cx } from "dashboard/utils";
import Banner from "dashboard/components/shared/Banner";
import {
  useScopedReportViews,
  useRefetchReportViews,
  useUser,
  useActiveCompanyId,
  useIsSuperAdmin,
} from "dashboard/hooks/atom-hooks";

export type CreateReportViewParams = Pick<
  ReportView,
  "name" | "config" | "creator_user_id" | "report_id" | "company_id" | "default" | "scope"
>;

export type UpdateReportViewParams = Pick<ReportView, "name" | "config" | "default" | "scope">;

type Props = {
  reportId: string;
  value: ReportView | CreateReportViewParams | undefined | null;
  onSelect: (value: ReportView | CreateReportViewParams | undefined | null) => void;
  onSave: (value: ReportView | CreateReportViewParams | undefined | null) => void;
  onReset?: () => void;
  showSaveButton?: boolean;
};

const ReportViewSelect: FC<Props> = ({ reportId, value, onSelect, showSaveButton, onSave, onReset }) => {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [name, setName] = useState(value?.name || "");
  const [isDefaultView, setIsDefaultView] = useState(value?.default || false);
  const [scopeSelection, setScopeSelection] = useState(value?.scope || "account");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const reportViews = useScopedReportViews();
  const getReportViews = useRefetchReportViews();
  const isAdmin = useIsSuperAdmin();
  const selectRef = useRef<Select<Option<ReportView>>>(null);
  const activeUser = useUser();
  const activeUserId = activeUser?._id;
  const activeCompanyId = useActiveCompanyId();
  if (!activeUser) throw new Error("No active user");
  if (!activeCompanyId) throw new Error("No active company");

  const sharedIcon = <UsersThree style={{ paddingRight: "6px", fontSize: "12px", minWidth: "12px" }} />;
  const personalIcon = <User style={{ paddingRight: "6px", fontSize: "12px", minWidth: "12px" }} />;

  const options = useMemo(() => {
    return reportViews
      .filter((view) => view.report_id === reportId)
      .map((view) => {
        const viewIcon = view.scope === "company" ? sharedIcon : personalIcon;
        const viewLabel = (
          <div className="flex">
            {viewIcon}
            {view.name}
          </div>
        );
        return { label: viewLabel, value: view };
      });
  }, [reportViews, reportId]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value?._id === (value && "_id" in value ? value?._id : undefined));
  }, [options, value]);

  // Get options + set default view
  useEffect(() => {
    const defaultOptions = options.filter((option) => option.value?.default);
    const defaultOption =
      defaultOptions.find((option) => option.value.scope === "account") ||
      defaultOptions.find((option) => option.value.scope === "company");
    const defaultView = defaultOption?.value;

    // Only set the default value if it is not undefined (otherwise, the first change to aggrid will be skipped)
    if (defaultView && !selectedOption) {
      onSelect(defaultView);
    }
  }, [!!activeCompanyId, activeUserId, options]);

  // Keep name in sync with value
  useEffect(() => {
    if (value?.name && value?.name !== name) {
      setName(value?.name);
    }
  }, [value?.name]);

  // When we display the update modal, make sure default is checked/not checked
  useEffect(() => {
    if (showUpdateModal) {
      setIsDefaultView(value?.default || false);
      setScopeSelection(value?.scope || "account");
    }
  }, [showUpdateModal]);

  useEffect(() => {
    if (showCreateModal) {
      setName("");
    }
  }, [showCreateModal]);

  const buildParams = (): CreateReportViewParams | UpdateReportViewParams => {
    if (showUpdateModal && value && "_id" in value) {
      return {
        name: name,
        config: value.config,
        default: isDefaultView,
        scope: scopeSelection,
      };
    } else {
      if (!name) throw new Error("Template name is required");

      // Remove _id from value in case we are creating a new view from an existing one
      let params = { ...value };
      if (value && "_id" in value) {
        params = { ...params, _id: undefined };
      }

      return {
        ...params,
        name: name || "Untitled report template",
        config: value?.config || {
          filters: {},
          columns: [],
          group: [],
          pivot_enabled: false,
        },
        company_id: activeCompanyId,
        creator_user_id: activeUserId,
        report_id: reportId,
        default: isDefaultView,
        scope: scopeSelection,
      };
    }
  };

  const saveReportView = async () => {
    setSaving(true);

    try {
      const params = buildParams();
      const res =
        showUpdateModal && value && "_id" in value
          ? await MiterAPI.report_views.update(value._id, params)
          : await MiterAPI.report_views.create(params as CreateReportViewParams);

      if (res.error) throw new Error(res.error);

      Notifier.success("Template saved");
      await getReportViews();

      setShowCreateModal(false);
      setShowUpdateModal(false);
      onSelect(res);
      onSave(res);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.log("Error saving report template", e);
    }

    setSaving(false);
  };

  const deleteReportView = async () => {
    if (!value || !("_id" in value)) return;

    setDeleting(true);

    try {
      const res = await MiterAPI.report_views.delete(value._id);
      if (res.error) throw new Error(res.error);

      Notifier.success("Template deleted");
      getReportViews();

      setShowCreateModal(false);
      setShowUpdateModal(false);
      onSelect(undefined);
      onSave(undefined);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error("Error deleting report template", e);
    }

    setDeleting(false);
  };

  const handleNameChange = (newName: string) => {
    setName(newName);
  };

  const handleHideModal = () => {
    setShowCreateModal(false);
    setShowUpdateModal(false);
  };

  const renderReportViewModal = () => {
    if (!showCreateModal && !showUpdateModal) return <></>;
    const scopeOptions: { label: JSX.Element; value: "account" | "company" }[] = [
      {
        label: <div className="flex">{personalIcon} Personal</div>,
        value: "account",
      },
      {
        label: (
          <div className="flex">
            {sharedIcon}
            Shared
          </div>
        ),
        value: "company",
      },
    ];

    const title = showCreateModal ? "Create Template" : "Update Template";
    const defaultText = scopeSelection === "account" ? "Set as my default" : "Set as company default";
    return (
      <ActionModal
        headerText={title}
        onHide={handleHideModal}
        onSubmit={saveReportView}
        submitText="Submit"
        showSubmit={true}
        showCancel={true}
        onCancel={handleHideModal}
        onDelete={deleteReportView}
        showDelete={!!showUpdateModal}
        submitDisabled={saving || deleting}
        loading={saving}
        deleting={deleting}
      >
        <div className={styles["report-view-modal-body"]}>
          <div className="flex">
            <input
              className={cx("form2-text", styles["report-view-name"])}
              type="text"
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              placeholder="Template name"
            />
            <Select
              className={styles["scope-select"]}
              height="32px"
              options={scopeOptions}
              onChange={(scopeOption) => setScopeSelection(scopeOption?.value || "account")}
              value={scopeOptions.find((scopeOption) => scopeOption.value === scopeSelection)}
            />
          </div>

          <div className={"checkbox-input-wrapper flex"} style={{ marginTop: 10 }}>
            <input
              className={"form2-checkbox"}
              type="checkbox"
              onChange={(e) => setIsDefaultView(e.target.checked)}
              name={"default_view"}
              disabled={saving}
              checked={isDefaultView}
            />
            <div className={"checkbox-text "}>{defaultText}</div>
          </div>
          {scopeSelection === "company" && isDefaultView && (
            <Banner
              type={"warning"}
              content={
                "Warning: Setting this template as the company default will change the default view for everyone at your company unless they have set their own personal default."
              }
            />
          )}
        </div>
      </ActionModal>
    );
  };

  const isOwnReportView = value?.creator_user_id === activeUserId;
  return (
    <div className={styles["report-view-selector-container"]}>
      {/** @ts-expect-error Select types */}
      <Select
        ref={selectRef}
        key={"report-view-selector-" + (value && "_id" in value ? value?._id : "new")}
        name="report-view-selector"
        options={options}
        width={"100%"}
        zIndex={10}
        onChange={(option) => onSelect(option?.value)}
        placeholder={"Select template"}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        noOptionsMessage={() => "No templates found"}
        height="32px"
        value={selectedOption}
        isClearable={true}
        styles={{
          control: (provided, state) => ({
            // @ts-expect-error styles
            ...selectStyles.control!(provided, state),
            width: "200px",
            fontFamily: "Karla",
          }),
          // Need the below for option dropdown to appear when used in modals
          menuPortal: (base) => ({ ...base, zIndex: 10 }),
        }}
      />
      {value && (isOwnReportView || isAdmin) && "_id" in value && (
        <Button
          onClick={() => setShowUpdateModal(true)}
          className={"button-1 " + styles["update-report-view-modal-btn"]}
        >
          <Pencil weight="fill" fontSize={"0.95rem"} />
        </Button>
      )}
      {(showSaveButton || !value || !isOwnReportView) && (
        <Button
          onClick={() => setShowCreateModal(true)}
          className={"button-1 no-margin " + styles["show-report-view-modal-btn"]}
        >
          <PlusCircle weight="bold" fontSize={"0.95rem"} />
        </Button>
      )}
      {showSaveButton && (!value || !("_id" in value)) && onReset && (
        <Button
          onClick={() => onReset()}
          className={"button-1 no-margin " + styles["show-report-view-modal-btn"]}
        >
          <XCircle weight="bold" fontSize={"0.95rem"} />
        </Button>
      )}
      {renderReportViewModal()}
    </div>
  );
};

export default ReportViewSelect;
