import { AggregatedCandidate, Candidate, JobPosting } from "dashboard/types/ats";

export const APPLICATION_STATUS_VALUE_LABEL = {
  applied: "Applied",
  in_review: "In review",
  interviewing: "Interviewing",
  offer: "Offer",
  hired: "Hired",
  rejected: "Rejected",
};

export type JobPostingTableData = JobPosting & {
  isActive: boolean;
};

export type CandidateTableData = AggregatedCandidate & {
  count: number;
};

export const CANDIDATE_STATUSES = ["applied", "in_review", "interviewing", "offer", "hired", "rejected"];

export const formatJobPostingsTableData = (jobPostings: JobPosting[]): JobPostingTableData[] => {
  return jobPostings.map((jobPosting) => ({
    ...jobPosting,
    isActive: jobPosting.status === "active",
  }));
};

export const formatCandidateTableData = (candidates: AggregatedCandidate[]): CandidateTableData[] => {
  return candidates.map((c) => ({
    ...c,
    count: c.job_applications.length,
  }));
};

export const getCandidateFullName = (candidate: Candidate): string => {
  const { first_name, last_name } = candidate;
  return `${first_name} ${last_name}`;
};

export const WORKPLACE_OPTIONS = [
  {
    label: "In person",
    value: "in_person",
  },
  {
    label: "Remote",
    value: "remote",
  },
  {
    label: "Hybrid",
    value: "hybrid",
  },
  {
    label: "Travel (different sites)",
    value: "travel",
  },
];

export const PAY_TYPE_OPTIONS = [
  {
    label: "Salary",
    value: "salary",
  },
  {
    label: "Hourly",
    value: "hourly",
  },
];

export const EMPLOYMENT_OPTIONS = [
  {
    label: "Full time",
    value: "full_time",
  },
  {
    label: "Part time",
    value: "part_time",
  },

  {
    label: "Contract",
    value: "contract",
  },

  {
    label: "Intern",
    value: "intern",
  },
];
