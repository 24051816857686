import EquipmentTable from "dashboard/components/equipment/EquipmentTable";
import { Toggler } from "ui";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import EquipmentTimesheets from "./EquipmentTimesheets";
import { Params, useNavigate, useParams } from "react-router-dom";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

const Equipment: FC = () => {
  /********************************************************
   * Hooks
   * ******************************************************/
  const { view } = useParams<Params>();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const navigate = useNavigate();
  const activePath = view;
  const { cannot } = useMiterAbilities();

  /****************************************************************
   *  Toggler functions
   **************************************************************/
  const togglerConfig = [
    {
      path: "inventory",
      label: "Inventory",
      hide: !hasAccessToEquipmentTracking || cannot("equipment:inventory:read"),
    },
    {
      path: "timesheets",
      label: "Timesheets",
      hide: !hasAccessToEquipmentTracking || cannot("equipment:timesheets:read"),
    },
  ];

  const toggle = (page: string) => {
    return navigate("/equipment/" + page);
  };

  const renderView = () => {
    if (activePath === "inventory") {
      return <EquipmentTable />;
    } else if (activePath === "timesheets") {
      return <EquipmentTimesheets />;
    }
  };

  useEffect(() => {
    const defaultPath = togglerConfig.find((item) => !item.hide)?.path;
    if (!activePath) {
      navigate(`/equipment/${defaultPath}`, { replace: true });
    }
  }, []);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Equipment | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1 className="view-title title-with-toggler">Equipment</h1>
          <div className="flex-1"></div>
        </div>
        <Toggler active={activePath} toggle={toggle} config={togglerConfig}></Toggler>
        {renderView()}
      </div>
    </div>
  );
};

export default Equipment;
