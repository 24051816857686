import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ActionModal, Formblock, Notifier, PageModal, TableV2 } from "ui";

import * as vals from "dashboard/utils/validators";
import styles from "./PermissionGroups.module.css";
import {
  AggregatedRole,
  AggregatedTeamMember,
  MiterAPI,
  PermissionGroup,
  PermissionsConfig,
  TeamMember,
  TeamMemberGroupType,
  User,
} from "dashboard/miter";
import {
  useActiveCompanyId,
  useCompanyRoleOptions,
  useLookupCompanyRoles,
  useLookupDepartment,
  useLookupTeam,
  useRefetchPermissionGroups,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { get, set } from "lodash";
import {
  PERMISSIONS_TABLE_COLUMNS,
  PERMISSIONS_TABLE_LIST,
  buildSelectedPermissionsList,
} from "dashboard/utils/permission-groups";
import {
  TeamMemberGroupSelectValue,
  TeamMemberOptionGroup,
  useTeamMemberGroupOptions,
} from "../team-members/useTeamMemberGroupOptions";
import { Option } from "ui/form/Input";
import { isMiterRep, notNullish } from "miter-utils";
import { useNavigate } from "react-router-dom";
import { ColumnConfig } from "ui/table-v2/Table";
import { LookupAtomFunction } from "dashboard/atoms";
import {
  JobPostingScopeGroupType,
  JobScopeGroupType,
  TeamMemberScopeGroupType,
} from "backend/models/permission-group";
import { PermissionGroupScopes } from "./PermissionGroupScopes";
import { useTeamMemberScopesBuilder } from "dashboard/hooks/useTeamMemberScopesBuilder";
import { JOB_SCOPE_OPTIONS } from "./PermissionGroupScopeSection";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import {
  PermissionGroupRecruitingScope,
  RECRUITING_SCOPE_OPTIONS,
} from "./recruiting/PermissionGroupRecruitingScope";
import {
  CreatePermissionGroupParams,
  UpdatePermissionGroupParams,
} from "backend/services/permission-group-service";

type Props = {
  permissionGroup?: PermissionGroup | undefined;
  onHide: () => void;
};

export type PermissionGroupScopeData = {
  team_member?: Option<TeamMemberScopeGroupType>[];
  job?: Option<JobScopeGroupType>[];
};

type RecruitingPermissionGroupScopeData = {
  job_postings?: Option<JobPostingScopeGroupType>[];
};

type ModulePermissionScopesData = {
  recruiting?: RecruitingPermissionGroupScopeData;
};

export type PermissionGroupScopesForm = {
  global?: PermissionGroupScopeData;
  human_resources: PermissionGroupScopeData;
  workforce_management: PermissionGroupScopeData;
  expense_management: PermissionGroupScopeData;
  payroll_and_compliance: PermissionGroupScopeData;
  modules: ModulePermissionScopesData;
};

export type PermissionGroupForm = {
  name: string;
  description: string;
  permissions: string[]; // List of permission paths that are selected
  permission_table_entries: PermissionsTableEntry[];
  roles: Option<string>[];
  teamMembers: Option<TeamMemberGroupSelectValue>[];
  scopes?: PermissionGroupScopesForm;
  /**
   * Defines the scope type for all non-module specific data that team members and jobs apply to
   * Eg. time-tracking, expenses, payroll, etc.
   */
  scope_type?: "full" | "job" | "team_member";
};

type PermissionsTableEntry = {
  _id: string;
  label: string;
  path: string;
  info?: string;
};

const PermissionGroupModal: React.FC<Props> = ({ onHide, ...props }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeUser = useUser();
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const lookupRole = useLookupCompanyRoles();
  const lookupTeam = useLookupTeam();
  const refetchPermissionGroups = useRefetchPermissionGroups();
  const { buildTeamMemberScopeGroup, getTeamMemberScopeOption } = useTeamMemberScopesBuilder();

  // If this is a super admin group, only all team members to be manually selected, no groups
  const excludedGroups: TeamMemberGroupType[] = useMemo(() => {
    if (props.permissionGroup?.super_admin) return SUPER_ADMIN_EXCLUDED_GROUPS;
    return ["self"] as TeamMemberGroupType[];
  }, [props.permissionGroup?.super_admin]);

  const [selectedTmGroupOptions, setSelectedTmGroupOptions] = useState<Option<TeamMemberGroupSelectValue>[]>(
    []
  );

  const rolePredicate = useCallback(
    (role: AggregatedRole) => {
      if (isMiterRep(activeUser)) return true;
      return !isMiterRep(role);
    },
    [activeUser]
  );

  const roleOptions = useCompanyRoleOptions({ predicate: rolePredicate });

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    selectedGroupOptions: selectedTmGroupOptions,
    excludedGroups,
    hideMitosaurs: !isMiterRep(activeUser),
  });

  const lookupDepartment = useLookupDepartment();
  const [permissionGroup, setPermissionGroup] = useState<PermissionGroup | undefined>(props.permissionGroup);

  const [permissionGroupTeamMembers, setPermissionGroupTeamMembers] = useState<TeamMember[]>();
  const [showTeamMembers, setShowTeamMembers] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const form = useForm<PermissionGroupForm>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
    defaultValues: buildDefaultValues(permissionGroup, {
      roleOptions,
      teamMemberGroupOptions,
      lookupRole,
      lookupTeam,
      activeUser,
      getTeamMemberScopeOption,
    }),
  });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit } = form;

  const formData = form.watch();
  useWatch({ control: form.control });

  form.register({ name: "permissions" });

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    setSelectedTmGroupOptions(formData.teamMembers);
    getMembersList();
  }, [JSON.stringify(formData.teamMembers)]);

  const getMembersList = async () => {
    try {
      if (!activeCompanyId) return;

      const members = buildMembers();
      if (members.length === 0) return setPermissionGroupTeamMembers([]);

      const res = await MiterAPI.permission_groups.retrieve_draft_members(members, activeCompanyId);
      if (res.error) throw new Error(res.error);

      setPermissionGroupTeamMembers(res.teamMembers);
    } catch (e: $TSFixMe) {
      console.error("Error retrieving permission group members", e.message);
    }
  };

  const buildMembers = () => {
    const members: PermissionGroup["members"] = [];
    (formData.roles || []).forEach((role) => {
      members.push({ type: "role", group: { type: "role", value: role.value } });
    });
    (formData.teamMembers || []).forEach((group) => {
      members.push({ type: "team_member", group: group.value });
    });

    return members;
  };

  /** Converts the scope option values into data that can be saved in the backend */
  const generateScopesHelper = (
    scopeType: "job" | "team_member",
    scopeData: PermissionGroupScopesForm | undefined,
    keys: string[]
  ) => {
    const extractedScopes = keys.reduce((acc, key) => {
      // Build the team member scope groups from the selected team member scope values
      const teamMemberScopes =
        scopeData?.[key]?.team_member?.map((o) => buildTeamMemberScopeGroup(o.value)).filter(notNullish) ||
        [];

      // Build the job scope groups from the selected job scope values
      const jobScopes = scopeData?.[key]?.job?.map((o) => ({ type: o.value, value: o.value })) || [];

      // If either team member scopes or job scopes are present, add the extracted scopes to the object
      if (scopeType === "team_member" && teamMemberScopes?.length) {
        acc[key] = {
          team_member: teamMemberScopes,
        };
      }

      if (scopeType === "job" && jobScopes?.length) {
        acc[key] = {
          job: jobScopes,
        };
      }

      return acc;
    }, {});

    return extractedScopes;
  };

  /** Generates the global scope. */
  const buildGlobalScopeParams = (params: PermissionGroupForm) => {
    if (params.scope_type === "full") return null;

    if (params.scope_type === "job" || params.scope_type === "team_member") {
      return {
        human_resources: null,
        workforce_management: null,
        expense_management: null,
        payroll_and_compliance: null,
        ...generateScopesHelper(params.scope_type, params.scopes, ["global"]),
      };
    }
    return null;
  };

  /** Generates the module scope. */
  const buildModuleScopeParams = (params: PermissionGroupForm) => {
    const recruitingScopes = (params.scopes?.modules?.recruiting?.job_postings || []).map((o) => ({
      type: o.value,
      value: o.value,
    }));

    return {
      recruiting: {
        job_postings: recruitingScopes,
      },
    };
  };

  /** Generates the complete scope params */
  const buildCompleteScopeParams = (params: PermissionGroupForm) => {
    // Generate the global scopes
    const allScopes = buildGlobalScopeParams(params);
    // Generate the module scopes
    const moduleScopes = buildModuleScopeParams(params);

    return {
      scopes: {
        ...(allScopes || {}),
        modules: moduleScopes,
      },
    };
  };

  const buildParams = (
    params: PermissionGroupForm
  ): CreatePermissionGroupParams | UpdatePermissionGroupParams => {
    if (!activeCompanyId) throw new Error("No active company found");

    // Build the permission by building nested objects by splitting the path into nested objects.
    const permissionsConfig: PermissionsConfig = {};
    params.permissions.forEach((path) => {
      const cleanedPath = path.split(".").slice(1);

      // Make sure this path isn't already in the permissions config so we don't have a parent override a child
      const hasPath = get(permissionsConfig, cleanedPath);
      if (hasPath) return;

      set(permissionsConfig, cleanedPath, true);
    });

    // Build the members list
    const members: PermissionGroup["members"] = [];
    (params.roles || []).forEach((role) => {
      members.push({ type: "role", group: { type: "role", value: role.value } });
    });
    (params.teamMembers || []).forEach((group) => {
      members.push({ type: "team_member", group: group.value });
    });

    // Add back the miter team members / roles if the person is not a miter admin
    if (!isMiterRep(activeUser)) {
      const roleMembers = (permissionGroup?.members || []).filter((member) => member.type === "role");
      const teamMembers = (permissionGroup?.members || []).filter((member) => member.type === "team_member");

      const miterRoles = roleMembers.filter((member) => {
        const role = lookupRole(member.group.value);
        return isMiterRep(role);
      });

      const miterTeamMembers = teamMembers.filter((member) => {
        const teamMember = lookupTeam(member.group.value);
        return isMiterRep(teamMember);
      });

      members.push(...miterRoles);
      members.push(...miterTeamMembers);
    }

    return {
      company_id: activeCompanyId,
      name: params.name,
      description: params.description,
      members,

      // Only add the permissions if this isn't a super admin group
      ...(!permissionGroup?.super_admin ? { permissions: permissionsConfig } : {}),
      ...buildCompleteScopeParams(params),
    };
  };

  const savePermissionGroup = async (params: PermissionGroupForm) => {
    setSaving(true);
    try {
      const cleanedParams = buildParams(params);
      const res = permissionGroup?._id
        ? await MiterAPI.permission_groups.update(permissionGroup._id, cleanedParams)
        : await MiterAPI.permission_groups.create(cleanedParams as CreatePermissionGroupParams);

      if (res.error) throw new Error(res.error);

      await refetchPermissionGroups();
      navigate(`/settings/permissions`);

      setPermissionGroup(res);
      Notifier.success("Permission group saved successfully");
      onHide();
    } catch (e: $TSFixMe) {
      console.error("Error saving permission group", e);
      Notifier.error(e.message);
    }
    setSaving(false);
  };

  const handlePermissionsSelect = (data: PermissionsTableEntry[]) => {
    const permissionPaths = data.map((d) => d.path);
    form.setValue("permissions", permissionPaths, { shouldDirty: true });
  };

  const defaultSelectedRows = useMemo(() => {
    return (
      buildDefaultValues(permissionGroup, {
        roleOptions,
        teamMemberGroupOptions,
        lookupRole,
        lookupTeam,
        activeUser,
        getTeamMemberScopeOption,
      })?.permission_table_entries || []
    );
  }, [JSON.stringify(permissionGroup?.permissions), lookupRole]);

  const teamMemberColumns: ColumnConfig<TeamMember>[] = [
    {
      headerName: "Name",
      field: "full_name",
      dataType: "string",
      minWidth: 250,
    },
    {
      headerName: "Title",
      field: "title",
      dataType: "string",
    },
    {
      headerName: "Department",
      field: "department_id",
      dataType: "string",
      valueGetter: (params) => {
        const department = lookupDepartment(params.data?.department_id);
        return department?.name;
      },
    },
  ];

  const footerActions = useMemo(
    () => [
      {
        label: "Save and exit",
        action: () => handleSubmit(savePermissionGroup)(),
        position: "right" as const,
        className: "button-2",
        loading: saving,
      },
    ],
    [saving]
  );

  const renderCommonFields = () => {
    return (
      <div className={styles["section"]}>
        <h3 className={styles["section-header"]}>Basic Information</h3>
        <Formblock
          label="Group name*"
          labelInfo={"A unique name for the permission group"}
          type="text"
          name="name"
          placeholder="e.g. Department Managers"
          form={form}
          className="modal wizard"
          editing={true}
          val={vals.required}
          disabled={permissionGroup?.super_admin}
        />
        <Formblock
          label="Group description"
          labelInfo={"Optional description for the permission group"}
          type="paragraph"
          name="description"
          placeholder={"Enter a description"}
          form={form}
          className="modal wizard"
          editing={true}
          disabled={permissionGroup?.super_admin}
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  };

  const renderPermissions = () => {
    if (permissionGroup?.super_admin) return null;

    return (
      <div className={styles["section"]}>
        <h3 className={styles["section-header"]}>Permissions</h3>
        <p className={styles["section-description"]} style={{ marginBottom: -20 }}>
          Select the actions that this permission group can perform on the dashboard
        </p>

        <TableV2
          id="permissions"
          resource="permissions"
          data={PERMISSIONS_TABLE_LIST}
          columns={PERMISSIONS_TABLE_COLUMNS}
          groupDisplayType="singleColumn"
          groupHideOpenParents={false}
          groupDefaultExpanded={0}
          groupAllowUnbalanced={true}
          onSelect={(data) => handlePermissionsSelect(data)}
          hideDefaultCheckbox={true}
          hideSearch={true}
          hideSecondaryActions={true}
          hideFooter={true}
          hideHeader={true}
          treeData={true}
          defaultSelectedRows={defaultSelectedRows}
          autoGroupColumnDef={autoGroupColumnDef}
          containerStyle={{ paddingBottom: 0 }}
        />
      </div>
    );
  };

  const renderMembersFields = () => {
    return (
      <div className={styles["section"]}>
        <h3 className={styles["section-header"]}>Group members</h3>
        <p className={styles["section-description"]}>
          Select the team members and roles to enroll in this permission group.
        </p>
        <Formblock
          type="multiselect"
          name="teamMembers"
          label={"Team members"}
          labelInfo={
            "Select team members to enroll in this permission group" +
            (permissionGroup?.super_admin
              ? ". Only individual team members can be selected for super administrator permission groups"
              : "")
          }
          form={form}
          editing={true}
          className="modal wizard"
          placeholder={"Select team members"}
          options={teamMemberGroupOptions}
          val={vals.required}
          height="unset"
          labelButtonText="View team member list"
          labelButtonClick={() => setShowTeamMembers(!showTeamMembers)}
        />
        <Formblock
          type="multiselect"
          name="roles"
          label={"External roles"}
          labelInfo={"Select the external roles to enroll in this permission group"}
          form={form}
          editing={true}
          className="modal wizard"
          placeholder={"Select external roles"}
          options={roleOptions}
          val={vals.required}
          height="unset"
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  };

  const renderScopes = () => {
    // If the user is a super admin, don't show the scopes section
    if (permissionGroup?.super_admin || !activeCompanyId) return;

    return (
      <div className={styles["section"]}>
        <h3 className={styles["section-header"]}>Global scopes</h3>
        <p className={styles["section-description"]}>
          Specify whose data this permission group can access on the dashboard.
        </p>
        <PermissionGroupScopes form={form} permissionGroup={permissionGroup} />
      </div>
    );
  };

  const renderRecruitingScopes = () => {
    // If the user is a super admin, don't show the scopes section
    if (permissionGroup?.super_admin || !activeCompanyId) return;
    if (!formData.permissions.some((p) => p.includes("recruiting"))) return;
    return (
      <div className={styles["section"]}>
        <h3 className={styles["section-header"]}>Recruiting scopes</h3>
        <p className={styles["section-description"]}>
          Specify what job postings and applications this permission group can access on the dashboard.
        </p>
        <PermissionGroupRecruitingScope form={form} />
      </div>
    );
  };

  const renderTeamMembersTable = () => {
    return (
      <ActionModal
        headerText="Team Members"
        onHide={() => setShowTeamMembers(false)}
        onCancel={() => setShowTeamMembers(false)}
        showCancel={true}
        cancelText={"Close"}
        wrapperStyle={{ width: "80%" }}
      >
        <TableV2
          id={"permission-group-team-members-table"}
          resource="team members"
          data={permissionGroupTeamMembers}
          columns={teamMemberColumns}
          gridWrapperStyle={{ height: "100%" }}
          wrapperClassName="base-ssr-table"
          containerClassName={"permission-groups-team-members-table "}
          containerStyle={{ marginBottom: 25 }}
        />
      </ActionModal>
    );
  };

  return (
    <PageModal
      header={permissionGroup ? "Edit permission group" : "Create permission group"}
      onClose={onHide}
      footerActions={footerActions}
      onHelp={() => goToMiterGuide("getting-started/permissions#faq")}
    >
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>
            {permissionGroup ? permissionGroup.name : "Setup permission group"}
          </h2>
          <p className={styles["subheader-description"]}>
            {permissionGroup
              ? "Edit your permission group"
              : "Add a name for your permission group and select the permissions"}
          </p>
        </div>
        {renderCommonFields()}
        {renderPermissions()}
        {renderScopes()}
        {renderRecruitingScopes()}
        {renderMembersFields()}
        {showTeamMembers && renderTeamMembersTable()}
      </div>
    </PageModal>
  );
};

export default PermissionGroupModal;

const buildDefaultScopeType = (permissionGroup: PermissionGroup | undefined) => {
  if (!permissionGroup?.scopes) return "full";

  const { global } = permissionGroup.scopes || {};

  // Commenting out the below code because we are not using module scopes
  // if (human_resources || workforce_management || expense_management || payroll_and_compliance) {
  //   return "module";
  // }

  if (global?.team_member?.length) {
    return "team_member";
  }

  if (global?.job?.length) {
    return "job";
  }

  return "full";
};

const buildDefaultGlobalScopeValues = (
  permissionGroup: PermissionGroup | undefined,
  lookupOption: (value: string) => Option<string> | undefined
) => {
  // Get the keys for where each of the individual scopes live
  const keys = [
    "global",
    "human_resources",
    "workforce_management",
    "expense_management",
    "payroll_and_compliance",
    "company",
  ];

  const scopes = permissionGroup?.scopes || {};

  // Build the default values for each of the scope levels
  return keys.reduce((acc, key) => {
    try {
      // Convert the scope DB values into options that we can pass into the form
      const teamMemberScopes = scopes?.[key]?.team_member
        ?.map((o) => lookupOption(o.value))
        ?.filter(notNullish);

      // Do the same for the jobs
      const jobScopes = JOB_SCOPE_OPTIONS.filter((o) => scopes?.[key]?.job?.find((j) => j.value === o.value));

      // If either team member scopes or job scopes are present, add the extracted scopes to the object for the form
      if (teamMemberScopes?.length || jobScopes?.length) {
        acc[`scopes.${key}.team_member`] = teamMemberScopes;
        acc[`scopes.${key}.job`] = jobScopes;
      }
    } catch (e: $TSFixMe) {
      console.error("Error building default scope values", e);
    }

    return acc;
  }, {});
};

const buildDefaultModuleScopeValues = (permissionGroup: PermissionGroup | undefined) => {
  const recruitingScopes = RECRUITING_SCOPE_OPTIONS.filter((o) =>
    (permissionGroup?.scopes?.modules?.recruiting?.job_postings || []).find((j) => j.value === o.value)
  );
  return { recruiting: { job_postings: recruitingScopes } };
};

const buildDefaultValues = (
  permissionGroup: PermissionGroup | undefined,
  options: {
    roleOptions: Option<string>[];
    teamMemberGroupOptions: TeamMemberOptionGroup[];
    lookupRole: LookupAtomFunction<AggregatedRole>;
    lookupTeam: LookupAtomFunction<AggregatedTeamMember>;
    activeUser?: User | null;
    getTeamMemberScopeOption: (value: string) => Option<string> | undefined;
  }
): PermissionGroupForm => {
  const permissionsConfig = buildSelectedPermissionsList(permissionGroup);
  const scopeType = buildDefaultScopeType(permissionGroup);
  const globalScope = buildDefaultGlobalScopeValues(permissionGroup, options.getTeamMemberScopeOption);
  set(globalScope, "scopes.modules", buildDefaultModuleScopeValues(permissionGroup));

  return {
    name: permissionGroup?.name || "",
    description: permissionGroup?.description || "",
    permissions: permissionsConfig.map((p) => p.path),
    permission_table_entries: permissionsConfig,
    scope_type: scopeType,
    ...buildDefaultMemberValues(permissionGroup, options),
    ...globalScope,
  };
};

const autoGroupColumnDef = {
  valueFormatter: (params) => params.data.label,
  cellRendererParams: { suppressCount: true, checkbox: true },
};

const buildDefaultMemberValues = (
  permissionGroup: PermissionGroup | undefined,
  options: {
    roleOptions: Option<string>[];
    teamMemberGroupOptions: TeamMemberOptionGroup[];
    lookupRole: LookupAtomFunction<AggregatedRole>;
    lookupTeam: LookupAtomFunction<AggregatedTeamMember>;
    activeUser?: User | null;
  }
) => {
  const roleIds = (permissionGroup?.members || [])
    .filter((member) => member.type === "role")
    .map((member) => member.group.value)
    .filter(notNullish);

  const roles = options.roleOptions
    .filter((role) => roleIds.includes(role.value))
    .filter((o) => {
      if (isMiterRep(options.activeUser)) return true;

      const role = options.lookupRole(o.value);
      return !isMiterRep(role);
    });

  const teamMemberOptions = options.teamMemberGroupOptions
    .flatMap((group) => group.options)
    .filter((o) => {
      if (isMiterRep(options.activeUser)) return true;

      const tm = options.lookupTeam(o.value.value);
      return !isMiterRep(tm);
    });

  const teamMembers = (permissionGroup?.members || [])
    .filter((member) => member.type === "team_member")
    .map((member) => member.group)
    .filter(notNullish)
    .map((group) => {
      const option = teamMemberOptions.find(
        (tm) => tm.value?.type === group.type && tm.value?.value === group.value
      );
      return option;
    })
    .filter(notNullish);

  return { roles, teamMembers };
};

const SUPER_ADMIN_EXCLUDED_GROUPS: TeamMemberGroupType[] = [
  "department",
  "pay_type",
  "employment_type",
  "title",
  "crew",
  "all_team_members",
  "job_supervisor",
  "job_superintendent",
  "direct_managers",
  "crew_lead",
  "department_head",
  "admin",
  "self",
];
