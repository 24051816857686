import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Button, Formblock, Notifier } from "ui";
import { useTranslation } from "react-i18next";
import { TeamPortalUser } from "team-portal/utils/miter";
import { AggregatedTeamMember, MiterAPI, UpdateTeamMemberParams } from "dashboard/miter";
import { Option } from "ui/form/Input";
import { CheckContractor, CheckEmployee } from "../../../backend/utils/check/check-types";
import Banner from "dashboard/components/shared/Banner";
import { useNavigate } from "react-router-dom";
import { useCheckComponent } from "dashboard/utils/useCheckComponent";

type Props = {
  teamMember: AggregatedTeamMember | TeamPortalUser;
  isOnboarding?: boolean;
  isProfile?: boolean;
  onClose: () => void;
};
type ChangePaymentMethodForm = {
  payment_method: Option<"direct_deposit" | "manual">;
  w2_electronic_consent_provided?: boolean;
  "1099_nec_electronic_consent_provided"?: boolean;
};

export const UpdatePaymentMethodAndWithholdingsModal: React.FC<Props> = ({
  teamMember,
  isOnboarding,
  isProfile,
  onClose,
}) => {
  const form = useForm<ChangePaymentMethodForm>();
  const navigate = useNavigate();
  const { t } = useTranslation<$TSFixMe>();
  const { setComponentURL, renderComponent, setLoadingComponent } = useCheckComponent();

  const { control, handleSubmit, formState, watch } = form;
  const formData = watch();
  const [saving, setSaving] = React.useState(false);

  const existingPaymentMethod = teamMember.check_tm?.payment_method_preference;
  const isEmployee = teamMember.employment_type === "employee";
  const currentElectronicStatementOptionSelected = isEmployee
    ? (teamMember?.check_tm as CheckEmployee)?.w2_electronic_consent_provided
    : (teamMember?.check_tm as CheckContractor)["1099_nec_electronic_consent_provided"];

  const cleanFormData = (formData: ChangePaymentMethodForm): UpdateTeamMemberParams => {
    const { payment_method, ...rest } = formData;
    return {
      payment_method_preference: payment_method.value, // unwrap from Option to value
      ...rest,
    };
  };

  const save = async (formData: ChangePaymentMethodForm) => {
    setSaving(true);
    try {
      const cleanedFormData = cleanFormData(formData);
      const response = await MiterAPI.team_member.update(teamMember._id, cleanedFormData);
      if (response.error) {
        throw new Error(response.error);
      }

      Notifier.success(t("Payment method updated"));
      onClose();
    } catch (err) {
      Notifier.error(t("Failed to update payment method"));
    }
    setSaving(false);
  };

  const getWitholdingsSetupLink = async () => {
    setLoadingComponent(true);
    try {
      const res = await MiterAPI.team_member.retrieve_withholdings_setup_link(teamMember?._id);
      setComponentURL(res.url);
    } catch (e: $TSFixMe) {
      console.error("Error getting withholdings setup link", e);
      Notifier.error(t("There was an error opening this page. Please contact support."));
    }
    setLoadingComponent(false);
  };

  const renderBottomText = useCallback(() => {
    const currentSelection = formData.payment_method?.value ?? existingPaymentMethod;
    return (
      currentSelection === "direct_deposit" && (
        <p>
          Go to the{" "}
          <a className="purple-link" onClick={() => navigate("/payment-info")}>
            Payment Info
          </a>{" "}
          tab to add bank accounts and update your paycheck distribution.
        </p>
      )
    );
  }, [formData]);

  const paperlessTaxString = `I wish to receive paperless ${
    isEmployee ? "W2" : "1099"
  } statements and related tax information from Miter.`;

  return (
    <>
      <ActionModal
        headerText={t("Update payment method")}
        onHide={onClose}
        loading={saving}
        showSubmit
        submitDisabled={!formState.isDirty}
        onSubmit={handleSubmit(save)}
        bodyStyle={{ minHeight: 200 }}
      >
        {isProfile && (
          <Banner
            type="warning"
            content={t(
              "Bank accounts and your paycheck split preferences have moved to the new Payment Info tab! Click on this banner to navigate there."
            )}
            onClick={() => {
              navigate("/payment-info");
            }}
            style={{ marginLeft: "-20px", marginRight: "-20px" }}
          />
        )}
        <div className="margin-top-15">
          <Formblock
            type="select"
            options={[
              { label: t("Direct deposit"), value: "direct_deposit" },
              { label: t("Paper check"), value: "manual" },
            ]}
            name="payment_method"
            className="modal"
            editing={true}
            control={control}
            label={t("Payment method")}
            defaultValue={existingPaymentMethod}
          />
          {/* don't show edit withholdings button if onboarding - separate flow there */}
          {!isOnboarding && isEmployee && (
            <div className="margin-top-15">
              <Button
                onClick={getWitholdingsSetupLink}
                text={"Edit Withholdings"}
                className="button-1 no-margin"
              />
            </div>
          )}
          <div className="margin-top-15">
            <Formblock
              type="checkbox"
              name={isEmployee ? "w2_electronic_consent_provided" : "1099_nec_electronic_consent_provided"}
              editing={true}
              defaultValue={currentElectronicStatementOptionSelected}
              text={t(paperlessTaxString)}
              form={form}
              checkboxWrapperStyle={{ alignItems: "flex-start" }}
            />
          </div>
        </div>
        {renderBottomText()}
      </ActionModal>
      {renderComponent()}
    </>
  );
};
