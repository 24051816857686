import { useCallback } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, ThirdPartyCard } from "dashboard/miter";
import { CardManagementTableRow } from "dashboard/pages/expenses/CardManagementTable";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * THIRD PARTY CARDS
 */
type ThirdPartyCardParams = ThirdPartyCard | CardManagementTableRow | CardManagementTableRow[];

type ThirdPartyCardAction = "create" | "read" | "update" | "delete";
type ThirdPartyCardAbilities = {
  can: (action: ThirdPartyCardAction, items: ThirdPartyCardParams) => boolean;
  cannot: (action: ThirdPartyCardAction, items: ThirdPartyCardParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: ThirdPartyCardAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useThirdPartyCardAbilities = (): ThirdPartyCardAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: ThirdPartyCardAction, items?: ThirdPartyCardParams) => {
      if (!items) return false;

      const thirdPartyCards = Array.isArray(items) ? items : [items];

      return thirdPartyCards.every((thirdPartyCard) => {
        const teamMemberId = getTeamMemberId(thirdPartyCard);

        switch (action) {
          case "create":
            return can_("third_party_cards:create", { teamMember: teamMemberId });
          case "read":
            return can_("third_party_cards:read", { teamMember: teamMemberId });
          case "update":
            return can_("third_party_cards:update", { teamMember: teamMemberId });
          case "delete":
            return can_("third_party_cards:delete", { teamMember: teamMemberId });
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: ThirdPartyCardAction, items: ThirdPartyCardParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "third_party_cards",
    teamMemberField: { fieldName: "team_member_id", fieldType: "string" },
    appModule: "expense_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<ThirdPartyCardAction>("third_party_cards", true);

  return { can, cannot, filter, teamPredicate };
};

const getTeamMemberId = (item: ThirdPartyCard | CardManagementTableRow) => {
  if ("team_member_id" in item) {
    return item.team_member_id;
  }

  if ("assignedTeamMemberId" in item) {
    return item.assignedTeamMemberId;
  }
};
