import { AggregatedFile, CheckDocument, File, MiterAPI, MiterFilterArray } from "dashboard/miter";
import { deleteFiles, downloadFiles, markFilesAsViewed } from "miter-utils";
import { DateTime } from "luxon";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { ActionMenu, Badge, BasicModal, LoadingModal } from "ui";
import Notifier from "../../utils/notifier";
import { isUUID } from "../../utils/utils";
import { SendMessageModal } from "../sendMessageModal/SendMessageModal";
import DocumentWizard from "../documents/wizard/DocumentWizard";
import { Download, PaperPlaneTilt, Plus, TrashSimple } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { useDocumentAbilities } from "dashboard/hooks/abilities-hooks/useDocumentAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useLookupActiveTeam } from "dashboard/hooks/atom-hooks";

export type FilesTableFile = AggregatedFile | File | CheckDocument;

export type DefaultFilesTableRow = {
  _id: string;
  label?: string;
  uploaded: string;
  actions: ReactElement;
  name: string;
  created_at: number;
  tag_values: string[];
  signatures: "completed" | "incomplete" | "no_requests";
};

export type FilesTableRow = FilesTableFile & DefaultFilesTableRow;

type Props = {
  parentType: File["parent_type"];
  parentId: string;
  defaultFilters?: MiterFilterArray;
  smallWidth?: boolean;
  hideActions?: boolean;
  showVerticalSpacer?: boolean;
  showSignatureCompletion?: boolean;
  readonly?: boolean;
};

const FilesTable: React.FC<Props> = ({
  parentType,
  parentId,
  defaultFilters,
  smallWidth,
  hideActions,
  showVerticalSpacer,
  showSignatureCompletion,
  readonly,
}) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const docAbilities = useDocumentAbilities();
  const miterAbilities = useMiterAbilities();
  const lookupTeam = useLookupActiveTeam();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // States related to the table
  const [data, setData] = useState<FilesTableRow[]>();
  const [selectedRows, setSelectedRows] = useState<FilesTableRow[]>([]);
  const [isSending, setIsSending] = useState(false);

  // States related to table actions
  const [bulkRemoving, setBulkRemoving] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  // States related to file upload
  const [upload, setUpload] = useState(false);
  const selectedRowIds = useMemo(() => selectedRows.map((row) => row._id), [selectedRows]);

  useEffect(() => {
    getFiles();
  }, []);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleNewButtonClick = () => {
    setUpload(true);
  };

  const handleRemove = async () => {
    // Gets the files that are miter files (i.e. the ones that are deletable)
    const deletableFiles = selectedRows.filter((row) => "company_id" in row) as (AggregatedFile | File)[];

    if (docAbilities.cannot("delete", deletableFiles, parentType)) {
      Notifier.error("You do not have permission to delete these files.");
      return;
    }

    setRemoveLoading(true);
    await deleteFiles(selectedRowIds);
    getFiles();
    setBulkRemoving(false);
    setSelectedRows([]);
    setRemoveLoading(false);
  };

  const handleDownload = async (file?: FilesTableFile) => {
    if (file && "_id" in file) {
      downloadFiles([file._id], setDownloading);
      markFilesAsViewed([file._id]);
    } else if (file && "id" in file) {
      handleDownloadTaxDocument(file);
    } else {
      downloadFiles(selectedRowIds, setDownloading);
      markFilesAsViewed(selectedRowIds);
    }
  };

  const handleDownloadTaxDocument = async (file: CheckDocument) => {
    if (parentType !== "team_member") return;

    if (docAbilities.cannot("read", file, parentType)) {
      Notifier.error("You do not have permission to download this file.");
      return;
    }

    try {
      const response = await MiterAPI.team_member.retrieve_tax_document(parentId, file.id);
      if (response.error) throw new Error(response.error);

      saveAs(await response.blob(), file.label);
    } catch (err: $TSFixMe) {
      console.error("Error downloading tax document", err);
      Notifier.error("There was an error downloading the tax document. We're looking into it.");
    }
  };

  const handleDocumentWizardExit = () => {
    setUpload(false);
    getFiles();
  };

  /*********************************************************
   *  Data fetching functions
   **********************************************************/
  const buildActionsCell = (file: FilesTableFile) => {
    const actions = [
      {
        label: "View",
        action: () => viewFile(file),
        shouldShow: () => docAbilities.can("read", file, parentType),
      },
      {
        label: "Download",
        action: () => handleDownload(file),
        loading: loading,
        shouldShow: () => docAbilities.can("read", file, parentType),
      },
    ];

    return <ActionMenu links={actions} />;
  };

  const buildTagsCell = (file: FilesTableFile) => {
    if ("tags" in file) {
      return (
        <div style={{ display: "flex" }}>
          {file.tags.map((tag) => (
            <Badge backgroundColor={tag.color} text={tag.label} style={{ marginLeft: 0 }} />
          ))}
          {!file.tags.length ? "-" : ""}
        </div>
      );
    }

    return "-";
  };

  const getDocumentStatus = (file: FilesTableFile) => {
    if ("esignature_items" in file && file.esignature_items.length) {
      /** If the file has esignature items, we want to check if all active signers have signed the document */
      const completed = file.esignature_items
        .filter((item) => !!lookupTeam(item.signer.team_member_id))
        .every((item) => item.status === "signed");

      return completed ? "completed" : "incomplete";
    }

    return "no_requests";
  };

  const buildTableRows = (files: FilesTableFile[]): FilesTableRow[] => {
    return files
      .filter(
        (file) =>
          docAbilities.can("read", file, parentType) &&
          (("originalname" in file && file.originalname && !isUUID(file.originalname)) || "id" in file)
      ) // Measure to not display timesheet images in here
      .map((file) => {
        let fileName =
          file.label || ("originalname" in file ? file.originalname : "Untitled file") || "Untitled file";

        if (smallWidth && fileName.length > 25) {
          fileName = fileName?.slice(0, 25) + "...";
        }

        const createdAt =
          "created_at" in file ? DateTime.fromSeconds(file.created_at) : DateTime.fromISO(file.filed_on);

        const uploadedString = smallWidth ? createdAt.toISODate() : createdAt.toFormat("f");

        const aggregatedDoc = file as AggregatedFile;
        const completedAllSignatures = getDocumentStatus(aggregatedDoc);

        return {
          ...file,
          label: file.label || "Untitled file",
          _id: "id" in file ? file.id : file._id,
          name: fileName,
          uploaded: uploadedString,
          actions: buildActionsCell(file),
          created_at: createdAt.toSeconds(),
          tag_values: "tags" in file ? file.tags.map((tag) => tag.label) : [],
          signatures: completedAllSignatures,
        };
      });
  };

  // Get the files for a user filtered by the filter button and search bar.
  const getFiles = async () => {
    setLoading(true);

    try {
      const responseData = await MiterAPI.files.search(defaultFilters || []);
      if (responseData.error) throw Error(responseData.error);

      const cleanedData = buildTableRows(responseData);
      setData(cleanedData);
    } catch (e) {
      console.log("Error getting files", e);
      Notifier.error("We are unable to retrieve your files at this time. Please try again later");
    }
    setLoading(false);
  };

  const viewFile = async (file: FilesTableFile | FilesTableRow) => {
    try {
      if ("_id" in file) {
        navigate(`/documents/${file._id}`);
      } else {
        handleDownloadTaxDocument(file);
      }
    } catch (e) {
      console.log(e);
      Notifier.error("There was an getting the link to the file. We're looking into it.");
    }
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add document",
        className: "button-2 no-margin table-button",
        action: handleNewButtonClick,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => !hideActions && !readonly && miterAbilities.can("documents:company:create"),
      },
    ],
    [readonly]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () =>
      !hideActions
        ? [
            {
              label: "Download",
              className: loading ? "button-1 inactive" : "button-1",
              action: () => handleDownload(),
              loading: downloading,
              icon: <Download weight="bold" style={{ marginRight: 3 }} />,
              shouldShow: () => docAbilities.can("read", selectedRows, parentType),
            },
            {
              label: "Send",
              className: loading ? "button-1 inactive" : "button-1",
              action: () => setIsSending(true),
              icon: <PaperPlaneTilt weight="bold" style={{ marginRight: 3 }} />,
              shouldShow: () => docAbilities.can("read", selectedRows, parentType),
            },
            {
              label: "Delete",
              className: loading ? "button-3 inactive" : "button-3",
              action: () => setBulkRemoving(true),
              loading: bulkRemoving,
              icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
              shouldShow: () => !readonly && docAbilities.can("delete", selectedRows, parentType),
            },
          ]
        : [],
    [loading, downloading, bulkRemoving, docAbilities.can, selectedRowIds]
  );

  const columns: ColumnConfig<FilesTableRow>[] = [
    {
      field: "name",
      headerName: "File",
      dataType: "string",
    },
    {
      field: "tag_values",
      headerName: "Tags",
      dataType: "string",
      cellRenderer: (params) => buildTagsCell(params.data),
      filter: "agSetColumnFilter",
      hide: parentType === "message",
      valueFormatter: (params) => params.value,
    },
    ...(showSignatureCompletion
      ? [
          {
            field: "signatures",
            headerName: "Signatures",
            displayType: "badge" as const,
            colors: {
              completed: "green",
              incomplete: "red",
              no_requests: "grey",
            },
          },
        ]
      : []),
    {
      field: "created_at",
      headerName: "Created at",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "ff",
      maxWidth: 250,
    },
  ];

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderTable = () => {
    return (
      <TableV2
        id={"documents-table"}
        resource="documents"
        data={data}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={viewFile}
      />
    );
  };

  const renderRemoveModal = () => {
    const headerText =
      selectedRows.length > 1
        ? "Are you sure you want to delete these files?"
        : "Are you sure you want to delete this file?";

    return (
      <BasicModal
        headerText={headerText}
        bodyText={"This action cannot be undone."}
        button1Text="Cancel"
        button2Text="Remove"
        button1Action={() => setBulkRemoving(false)}
        button2Action={() => handleRemove()}
        loading={removeLoading}
      />
    );
  };

  return (
    <div className="files-table-wrapper">
      {isSending && (
        <SendMessageModal
          hide={() => {
            setIsSending(false);
            setSelectedRows([]);
          }}
          file_ids={selectedRowIds}
          job_id={parentType === "job" ? parentId : undefined}
          onSend={() => {
            markFilesAsViewed(selectedRowIds);
          }}
        />
      )}
      {showVerticalSpacer && <div className="vertical-spacer" />}
      {!removeLoading && renderTable()}
      {downloading && (
        <LoadingModal text={"Downloading file" + ((selectedRows?.length || 0) > 1 ? "s" : "")} />
      )}
      {bulkRemoving && renderRemoveModal()}
      {upload && (
        <DocumentWizard
          onExit={handleDocumentWizardExit}
          onComplete={handleDocumentWizardExit}
          parentId={parentId}
          parentType={parentType}
          mode="create"
        />
      )}
    </div>
  );
};

export default FilesTable;
