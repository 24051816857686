import { states } from "dashboard/utils/utils";
import * as vals from "dashboard/utils/validators";
import _ from "lodash";
import React, { useEffect } from "react";
import { Controller, FieldError } from "react-hook-form";
import Select from "react-select";
import "./form.css";
import { stateStyles } from "./styles";
import { RegisterFn } from "./types";
import { SetValueFn } from ".";
import { Option } from "./Input";
import { Address } from "dashboard/miter";

const getValidatedRef = (register: RegisterFn | undefined, required: boolean | undefined) => {
  // React 18 gets mad when putting react-hook-form's raw register into a ref, it needs to get called.
  // But sometimes we call it in the relevant Formblock, so need to figure out if that's the case.
  // Thankfully the uncalled version's name is "register" and the called version is an anonymous function
  return register?.name === "register" ? register(required ? vals.required : undefined) : register;
};

type Props = {
  defaultValue?: Address | null;
  showPostalName?: boolean;
  errors: { [K in keyof Address]?: FieldError };
  name?: string;
  register?: RegisterFn;
  notRequiredRegister?: RegisterFn;
  required?: boolean;
  control: React.ComponentProps<typeof Controller>["control"];
  shortStateSelect?: boolean;
  autocomplete?: boolean;
  setValue?: SetValueFn;
  value?: Address | null;
};

const AddressInput = ({
  defaultValue,
  showPostalName,
  errors,
  required,
  name = "",
  register,
  notRequiredRegister,
  control,
  shortStateSelect,
  setValue,
  value,
}: Props): React.ReactElement => {
  const finalRef = getValidatedRef(register, required);
  const finalNotReqRef = notRequiredRegister?.() || getValidatedRef(register, false);

  useEffect(() => {
    if (setValue && value) {
      const streetName = line1InputName;
      const line2Name = line2InputName;
      const cityName = cityInputName;
      const stateName = stateInputName;
      const postalCodeName = postalCodeInputName;

      setValue(streetName, value?.line1);
      setValue(line2Name, value?.line2);
      setValue(cityName, value?.city);
      setValue(stateName, value?.state);
      setValue(postalCodeName, value?.postal_code);
    }
  }, [JSON.stringify(value)]);

  /***************************************************************************************************
    Need to call this conditionally, otherwise it will force every address input to be a
    Google autocomplete input, regardless of whether autcomplete is true, which is not what we want..

    Turn off autocomplete if the user passes in a value (this means this is a controlled component)
  ****************************************************************************************************/
  // if (autocomplete) {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   ({ ref } = usePlacesWidget({
  //     apiKey: autocomplete ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : undefined,
  //     onPlaceSelected: (place) => _handleAutocomplete(place),
  //     options: { types: ["address"] },
  //   }));
  // }

  const { postal_name, line1, line2, city, state, postal_code } = defaultValue ?? {};

  const prependAddressName = (field?: string) => {
    return name + "." + field;
  };

  const postalNameInputName = prependAddressName("postal_name");
  const line1InputName = prependAddressName("line1");
  const line2InputName = prependAddressName("line2");
  const cityInputName = prependAddressName("city");
  const stateInputName = prependAddressName("state");
  const postalCodeInputName = prependAddressName("postal_code");

  const stateOptions: Option<string | undefined>[] = states.map((state) => ({
    label: state.abbreviation,
    value: state.abbreviation,
  }));
  stateOptions.unshift({ label: "-", value: undefined });

  const getBottomLineError = () => {
    return errors[name]?.city || errors[name]?.state || errors[name]?.postal_code;
  };

  const getLine1Error = () => {
    return errors[name] && errors[name].line1;
  };

  // Update the values of the address fields when the user selects a place from autocomplete
  const _handleAutocomplete = (place: $TSFixMe) => {
    if (!setValue) return;

    const streetName = line1InputName;
    const streetValue =
      (place.address_components.find((c) => c.types.includes("street_number"))?.long_name || "") +
      " " +
      place.address_components.find((c) => c.types.includes("route"))?.long_name;

    const cityName = cityInputName;
    const cityValue = place.address_components.find((c) => c.types.includes("locality"))?.long_name;

    const stateName = stateInputName;
    const stateValue = place.address_components.find((c) =>
      c.types.includes("administrative_area_level_1")
    )?.short_name;

    const postalCodeName = postalCodeInputName;
    const postalValue = place.address_components.find((c) => c.types.includes("postal_code"))?.short_name;

    setValue(streetName, streetValue);
    setValue(cityName, cityValue);
    setValue(stateName, { label: stateValue, value: stateValue });
    setValue(postalCodeName, postalValue);
  };

  const isBottomLineError = getBottomLineError();
  const isLine1Error = getLine1Error();

  return (
    <div className="address-input-wrapper">
      {showPostalName && (
        <input
          defaultValue={postal_name}
          className="form2-text line1"
          name={postalNameInputName}
          placeholder="Postal name"
          ref={finalRef}
        />
      )}
      <input
        defaultValue={line1}
        className="form2-text line1"
        name={line1InputName}
        placeholder="Line 1"
        ref={finalRef}
      />
      {isLine1Error && <div className="error-msg">This field is required.</div>}
      <input
        defaultValue={line2 || undefined}
        className="form2-text line2"
        name={line2InputName}
        placeholder="Line 2"
        ref={finalNotReqRef}
      />
      <div className="address-last-line">
        <input
          className="form2-text city"
          defaultValue={city}
          placeholder="City"
          name={cityInputName}
          ref={finalRef}
        />
        <Controller
          name={"" + name + ".state"}
          defaultValue={state ? stateOptions.find((option) => _.isEqual(option.value, state)) : null}
          control={control}
          rules={required ? vals.required : undefined}
          render={({ onChange, value }) => (
            <Select
              options={stateOptions}
              name={name!}
              onChange={onChange}
              value={value}
              height="30px"
              width="110px"
              maxMenuHeight={shortStateSelect ? 110 : 180}
              defaultValue={state ? stateOptions.find((option) => _.isEqual(option.value, state)) : null}
              menuPlacement="auto"
              placeholder="State"
              isMulti={false}
              styles={stateStyles}
            />
          )}
        />
        <input
          className="form2-text zip"
          defaultValue={postal_code}
          placeholder="Zip code"
          name={postalCodeInputName}
          ref={finalRef}
        />
      </div>
      {isBottomLineError && (
        <div className="error-msg">Please enter a valid city, state, and postal code.</div>
      )}
    </div>
  );
};

export default AddressInput;
