import { Notifier } from "dashboard/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { ActionModal, BasicModal, Formblock, TableV2 } from "ui";
import { MiterAPI, BurdenRate } from "dashboard/miter";
import InfoButton from "dashboard/components/information/information";
import { ColDef } from "ag-grid-community";
import {
  useActiveCompanyId,
  useActivityLabelFormatter,
  useActivityOptions,
  useCostTypeOptions,
  useJobOptions,
  useLedgerAccountLabeler,
  useLedgerAccountOptions,
  useLookupActivity,
  useLookupCostType,
  useLookupLedgerAccount,
} from "dashboard/hooks/atom-hooks";
import { includeWithEarningOption } from "../accounting/accountingUtils";
import {
  allTeamMembersGroup,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import _ from "lodash";
import { Plus, TrashSimple } from "phosphor-react";
import { TableActionLink } from "ui/table-v2/Table";

type BurdenRateTableEntry = BurdenRate;

type Props = {};

const jobScopeOptions = [
  { value: "all", label: "All earnings" },
  { value: "all_jobs", label: "All earnings with jobs" },
  { value: "all_jobs_except", label: "On earnings for all jobs, except..." },
  { value: "certain_jobs", label: "On earnings for certain jobs" },
  { value: "public_jobs", label: "On earnings for public jobs (Davis-Bacon or Certified Payroll)" },
  { value: "private_jobs", label: "On earnings for private jobs (Not Davis-Bacon or Certified Payroll)" },
  { value: "no_job", label: "On earnings without a job" },
];

const earningTypeScopeOptions = [
  { value: "all", label: "All earnings" },
  { value: "hourly", label: "Hourly earnings" },
  { value: "non_hourly", label: "Non-hourly earnings (bonuses, imputed income, etc.)" },
];

const rateTypeOptions = [
  { value: "percent", label: "% of earnings" },
  { value: "percent_of_std_earnings", label: "% of standard earnings (ignore OT premiums)" },
  { value: "per_hour", label: "$ per hour" },
];

export const BurdenRates: React.FC<Props> = () => {
  const companyId = useActiveCompanyId();
  const lookupLedgerAccount = useLookupLedgerAccount();
  const lookupActivty = useLookupActivity();
  const lookupCostType = useLookupCostType();
  const activityLabelFormatter = useActivityLabelFormatter();
  const ledgerAccountLabeler = useLedgerAccountLabeler();
  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: true,
    excludedGroups: ["self"],
  });
  const [creating, setCreating] = useState(false);
  const [selectedBurdenRate, setSelectedBurdenRate] = useState<BurdenRateTableEntry | undefined>();
  const [fetchingRates, setFetchingRates] = useState(false);
  const [burdenRates, setBurdenRates] = useState<BurdenRateTableEntry[]>([]);
  const [selectedRates, setSelectedRates] = useState<BurdenRateTableEntry[]>([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const flatTeamMemberGroupOptions = useMemo(() => {
    return teamMemberGroupOptions.flatMap((o) => o.options);
  }, [teamMemberGroupOptions]);

  const columns: ColDef<BurdenRateTableEntry>[] = useMemo(() => {
    return [
      {
        field: "label",
        headerName: "Label",
        pinned: "left",
        minWidth: 200,
        dataType: "string",
      },
      {
        field: "rate",
        headerName: "Rate",
        valueGetter: (params) => {
          if (params.data?.rate_type === "per_hour") {
            return `$${params.data?.rate.toFixed(2)}/hour`;
          } else if (params.data?.rate_type === "percent") {
            return `${params.data?.rate}% of earnings`;
          } else if (params.data?.rate_type === "percent_of_std_earnings") {
            return `${params.data?.rate}% of standard earnings`;
          }
        },
        dataType: "string",
      },
      {
        field: "job_scope",
        headerName: "Job scope",
        minWidth: 200,
        valueGetter: (params) => {
          const scope = params.data?.job_scope as BurdenRate["job_scope"];
          const scopeOpt = jobScopeOptions.find((o) => o.value === scope);
          return scopeOpt?.label || "-";
        },
        dataType: "string",
      },
      {
        field: "earning_type_scope",
        headerName: "Earning type scope",
        minWidth: 200,
        valueGetter: (params) => {
          const scope = params.data?.earning_type_scope as BurdenRate["earning_type_scope"];
          const scopeOpt = earningTypeScopeOptions.find((o) => o.value === scope);
          return scopeOpt?.label || "-";
        },
        dataType: "string",
      },
      {
        field: "team_groups",
        headerName: "Team member scope",
        minWidth: 200,
        valueGetter: (params) => {
          const selectedOptions = flatTeamMemberGroupOptions.filter((g) =>
            params.data?.team_groups.some((sg) => _.isEqual(sg, g.value))
          );
          return selectedOptions.map((g) => g.label).join(", ");
        },
        dataType: "string",
      },
      {
        field: "debit_account_id",
        headerName: "Debit GL account",
        valueGetter: (params) => {
          const account = lookupLedgerAccount(params.data?.debit_account_id);
          return ledgerAccountLabeler(account);
        },
        dataType: "string",
      },
      {
        field: "credit_account_id",
        headerName: "Credit GL account",
        valueGetter: (params) => {
          const account = lookupLedgerAccount(params.data?.credit_account_id);
          return ledgerAccountLabeler(account);
        },
        dataType: "string",
      },
      {
        field: "activity_id",
        headerName: "Activity",
        valueGetter: (params) => {
          const activity = lookupActivty(params.data?.activity_id);
          return activityLabelFormatter(activity);
        },
        dataType: "string",
      },
      {
        field: "cost_type_id",
        headerName: "Cost type",
        valueGetter: (params) => {
          const costType = lookupCostType(params.data?.cost_type_id);
          return costType?.label;
        },
        dataType: "string",
      },
    ];
  }, [lookupLedgerAccount, lookupCostType, lookupActivty]);

  const tableEntries: BurdenRateTableEntry[] = useMemo(() => {
    return burdenRates;
  }, [burdenRates]);

  const archiveBurdenRates = async () => {
    setArchiving(true);
    await Promise.all(
      selectedRates.map(async (r) => {
        try {
          const response = await MiterAPI.burden_rates.update(r._id, { archived: true });
          if (response.error) throw new Error(response.error);
        } catch (e) {
          console.error(e);
          Notifier.error("There was an error deleting burden rates. We're looking into it!");
        }
      })
    );
    getBurdenRates();
    setShowArchiveModal(false);
    setSelectedRates([]);
    setArchiving(false);
  };

  const getBurdenRates = async () => {
    if (!companyId) return null;
    setFetchingRates(true);

    try {
      const response = await MiterAPI.burden_rates.search({
        filter: [{ field: "company_id", value: companyId }],
      });
      if (response.error) throw new Error(response.error);
      setBurdenRates(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving burden rates. We're looking into it!");
    }
    setFetchingRates(false);
  };

  useEffect(() => {
    getBurdenRates();
  }, []);

  const handleModalHide = () => {
    setCreating(false);
    setSelectedBurdenRate(undefined);
    getBurdenRates();
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "New",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ],
    []
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setShowArchiveModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
      },
    ],
    []
  );

  return (
    <div className="billing-card-wrapper" style={{ height: "auto" }}>
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Burden rates</div>
        <InfoButton text="Account for extra costs associated with certain types of earnings." />
        <div className="flex-1"></div>
      </div>
      <div className="vertical-spacer-small"></div>
      <TableV2
        id="burden-rates-table"
        resource="burden rates"
        isLoading={fetchingRates || !tableEntries}
        columns={columns}
        containerStyle={{ maxHeight: 500, paddingBottom: 0 }}
        gridWrapperStyle={{ height: 400, width: "100%" }}
        data={tableEntries}
        onSelect={setSelectedRates}
        onClick={(r) => setSelectedBurdenRate(r)}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
      />
      {(creating || selectedBurdenRate) && (
        <BurdenRateModal hide={handleModalHide} burdenRateToUpdate={selectedBurdenRate} />
      )}
      {showArchiveModal && (
        <BasicModal
          headerText={`Delete burden rate${selectedRates.length > 1 ? "s" : ""}?`}
          yellowBodyText={true}
          loading={archiving}
          bodyText={`Are you sure you want to delete ${
            selectedRates.length > 1 ? "these rates" : "this rate"
          }?`}
          button2Action={archiveBurdenRates}
          button2Text="Delete"
          button1Action={() => setShowArchiveModal(false)}
          button2ClassName="button-3 no-margin"
        />
      )}
    </div>
  );
};

type BurdenRateModalProps = {
  hide: () => void;
  burdenRateToUpdate?: BurdenRateTableEntry | undefined;
};

const BurdenRateModal: React.FC<BurdenRateModalProps> = ({ burdenRateToUpdate, hide }) => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const jobOptions = useJobOptions();
  const costTypeOptions = useCostTypeOptions();
  const activityOptions = useActivityOptions();

  const [selectedTmGroups, setSelectedTmGroups] = useState(
    burdenRateToUpdate?.team_groups || [allTeamMembersGroup]
  );

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: true,
    excludedGroups: ["self"],
  });

  const excludableTeamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: true,
    excludedGroups: ["self", "admin", "direct_manager", "all_team_members"],
  });

  const ledgerAccountOptions = useLedgerAccountOptions();
  const expenseAccountOptions = useLedgerAccountOptions({ includeCustomOption: includeWithEarningOption });

  const [loading, setLoading] = useState(false);
  const [rateType, setRateType] = useState<BurdenRate["rate_type"]>(
    burdenRateToUpdate?.rate_type || "percent"
  );
  const [jobScope, setJobScope] = useState<BurdenRate["job_scope"]>(
    burdenRateToUpdate?.job_scope || "all_jobs"
  );
  const [earningTypeScope, setEarningTypeScope] = useState<BurdenRate["earning_type_scope"]>(
    burdenRateToUpdate?.earning_type_scope || "hourly"
  );

  const [showOptionToExcludeJobs, setShowOptionToExcludeJobs] = useState<boolean>(
    jobScope !== "all_jobs_except" && !!burdenRateToUpdate?.excluded_job_ids?.length
  );

  const allowAdditionalExcludedJobs =
    jobScope !== "all_jobs_except" &&
    jobScope !== "certain_jobs" &&
    jobScope !== "all_jobs" &&
    jobScope !== "all" &&
    jobScope !== "no_job";

  const showExcludableJobs =
    jobScope === "all_jobs_except" || (allowAdditionalExcludedJobs && showOptionToExcludeJobs);

  const selectedGroupOptions = useMemo(() => {
    const flatGroupOptions = teamMemberGroupOptions.flatMap((o) => o.options);
    return flatGroupOptions.filter((g) => selectedTmGroups.some((sg) => _.isEqual(sg, g.value)));
  }, [selectedTmGroups, teamMemberGroupOptions]);

  const [showOptionToExcludeTms, setShowOptionToExcludeTms] = useState<boolean>(
    !!burdenRateToUpdate?.excluded_team_groups?.length
  );

  const [selectedExcludedTmGroups, setSelectedExcludedTmGroups] = useState(
    burdenRateToUpdate?.excluded_team_groups || []
  );

  const selectedExcludedGroupOptions = useMemo(() => {
    const flatGroupOptions = excludableTeamMemberGroupOptions.flatMap((o) => o.options);
    return flatGroupOptions.filter((g) => selectedExcludedTmGroups.some((sg) => _.isEqual(sg, g.value)));
  }, [selectedExcludedTmGroups, excludableTeamMemberGroupOptions]);

  const form = useForm();

  const save = async (data) => {
    if (selectedTmGroups.length === 0) {
      form.setError("team_groups", { message: "Select at least one group.", shouldFocus: true });
      return;
    }
    setLoading(true);

    try {
      const params = {
        company_id: activeCompanyId!,
        label: data.label,
        rate: Number(data.rate),
        rate_type: rateType,
        excluded_job_ids: showExcludableJobs ? data.excluded_job_ids?.map((j) => j.value) : null,
        included_job_ids: jobScope === "certain_jobs" ? data.included_job_ids?.map((j) => j.value) : null,
        debit_account_id: data.debit_account_id?.value || null,
        credit_account_id: data.credit_account_id?.value || null,
        job_scope: jobScope || null,
        earning_type_scope: earningTypeScope,
        activity_id: data.activity_id?.value || null,
        cost_type_id: data.cost_type_id?.value || null,
        team_groups: selectedGroupOptions.map((g) => g.value),
        excluded_team_groups:
          showOptionToExcludeTms && selectedExcludedGroupOptions.length
            ? selectedExcludedGroupOptions.map((g) => g.value)
            : null,
      };
      let response;
      if (burdenRateToUpdate) {
        response = await MiterAPI.burden_rates.update(burdenRateToUpdate._id, params);
      } else {
        response = await MiterAPI.burden_rates.create(params);
      }
      if (response.error) throw new Error(response.error);
      hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error saving the burdenRate. We're looking into it!");
    }
    setLoading(false);
  };

  return (
    <ActionModal
      headerText={`${burdenRateToUpdate ? "Edit" : "Create"} burden rate`}
      onHide={hide}
      showCancel={true}
      onCancel={hide}
      showSubmit={true}
      onSubmit={form.handleSubmit(save)}
      submitText={"Submit"}
      loading={loading}
      wrapperStyle={{ maxWidth: 1000, width: "70vw", height: 695 }}
      bodyStyle={{ height: 575, maxHeight: 575 }}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          label="Label*"
          labelInfo="The name of the burden rate."
          className="modal"
          defaultValue={burdenRateToUpdate?.label}
          type="text"
          val={vals.required}
          name="label"
          form={form}
          editing={true}
        />
        <div className="flex" style={{ width: "100%" }}>
          <Formblock
            label="Rate*"
            className="modal"
            defaultValue={burdenRateToUpdate?.rate}
            type={rateType === "per_hour" ? "unit" : "number"}
            unit={rateType === "per_hour" ? "$" : "%"}
            val={vals.required}
            style={{ width: "100%" }}
            name="rate"
            form={form}
            editing={true}
          />
          <div style={{ width: 30 }}></div>
          <Formblock
            label="Rate type*"
            labelInfo="How the burden amount should be calculated."
            className="modal"
            type="select"
            style={{ width: "100%" }}
            options={rateTypeOptions}
            value={rateTypeOptions.find((o) => o.value === rateType)}
            defaultValue={rateType}
            onChange={(o) => setRateType(o.value)}
            requiredSelect
            name="rate_type"
            form={form}
            editing={true}
          />
        </div>
        <Formblock
          label="Earning type scope*"
          labelInfo="Select earning types for which the burden rate should apply"
          className="modal"
          type="select"
          requiredSelect={true}
          isClearable={false}
          options={earningTypeScopeOptions}
          value={earningTypeScopeOptions.find((o) => o.value === earningTypeScope)}
          defaultValue={earningTypeScope}
          onChange={(o) => setEarningTypeScope(o.value)}
          name="earning_type_scope"
          form={form}
          editing={true}
        />
        <Formblock
          type="multiselect"
          name="team_groups"
          label={"Team member scope*"}
          labelInfo={"Select team members for which this burden rate should apply"}
          form={form}
          editing={true}
          className="modal"
          placeholder={"Select team members"}
          value={selectedGroupOptions}
          onChange={(v) => setSelectedTmGroups(v?.map((o) => o.value) || [])}
          options={teamMemberGroupOptions}
          height="unset"
        />
        <Formblock
          text="Further exclude team members from the above scope"
          className="modal"
          type="checkbox"
          checked={showOptionToExcludeTms}
          onChange={(e) => setShowOptionToExcludeTms(e.target.checked)}
          name="show_excluded_tms"
          height={"unset"}
          editing={true}
        />
        {showOptionToExcludeTms && (
          <Formblock
            type="multiselect"
            name="excluded_team_groups"
            label={"Excluded team members"}
            labelInfo={
              "Select team members for which this burden rate should be excluded, despite them appearing in scope above."
            }
            form={form}
            editing={true}
            className="modal"
            placeholder={"Select team members to exclude"}
            value={selectedExcludedGroupOptions}
            onChange={(v) => setSelectedExcludedTmGroups(v?.map((o) => o.value) || [])}
            options={excludableTeamMemberGroupOptions}
            height="unset"
          />
        )}
        <Formblock
          label="Job scope*"
          labelInfo="Select earning jobs for which the burden rate should apply"
          className="modal"
          type="select"
          requiredSelect={true}
          isClearable={false}
          options={jobScopeOptions}
          value={jobScopeOptions.find((o) => o.value === jobScope)}
          defaultValue={jobScope}
          onChange={(o) => setJobScope(o?.value)}
          name="job_scope"
          form={form}
          editing={true}
        />
        {allowAdditionalExcludedJobs && (
          <Formblock
            text="Further exclude some jobs from the above list"
            className="modal"
            type="checkbox"
            checked={showOptionToExcludeJobs}
            onChange={(e) => setShowOptionToExcludeJobs(e.target.checked)}
            name="show_excluded_jobs"
            height={"unset"}
            editing={true}
          />
        )}
        {showExcludableJobs && (
          <Formblock
            label="Excluded jobs"
            labelInfo="To which jobs should the burden rate NOT apply?"
            className="modal"
            type="multiselect"
            options={jobOptions}
            defaultValue={burdenRateToUpdate?.excluded_job_ids}
            name="excluded_job_ids"
            form={form}
            height={"unset"}
            editing={true}
          />
        )}
        {jobScope === "certain_jobs" && (
          <Formblock
            label="Included jobs"
            labelInfo="To which jobs should the burden rate apply?"
            className="modal"
            type="multiselect"
            options={jobOptions}
            defaultValue={burdenRateToUpdate?.included_job_ids}
            name="included_job_ids"
            form={form}
            height={"unset"}
            editing={true}
          />
        )}
        <Formblock
          label="Debit GL account override"
          labelInfo="The GL account that will be debited in payroll GL entries."
          className="modal"
          type="select"
          options={expenseAccountOptions}
          defaultValue={burdenRateToUpdate?.debit_account_id}
          name="debit_account_id"
          form={form}
          editing={true}
          isClearable
        />
        <Formblock
          label="Credit GL account override"
          labelInfo="The GL account that will be credited in payroll GL entries."
          className="modal"
          type="select"
          options={ledgerAccountOptions}
          defaultValue={burdenRateToUpdate?.credit_account_id}
          name="credit_account_id"
          form={form}
          editing={true}
          isClearable
        />
        <Formblock
          label="Activity override"
          labelInfo="Burden rate line items will be assigned this activity. If left blank, Miter will use the base earning's activity."
          className="modal"
          type="select"
          options={activityOptions}
          defaultValue={burdenRateToUpdate?.activity_id}
          name="activity_id"
          form={form}
          editing={true}
          isClearable
        />
        <Formblock
          label="Cost type override"
          labelInfo="Burden rate line items will be assigned this cost type. If left blank, Miter will use the indirect labor expense cost type."
          className="modal"
          type="select"
          options={costTypeOptions}
          defaultValue={burdenRateToUpdate?.cost_type_id}
          name="cost_type_id"
          form={form}
          editing={true}
          isClearable
        />
      </div>
    </ActionModal>
  );
};
