import { AggregatedVendor, RawBankAccount } from "dashboard/miter";
import * as vals from "dashboard/utils/validators";
import React, { FC, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { Button, Formblock } from "ui";
import styles from "./VendorForm.module.css";
import { VendorPaymentMethod } from "backend/models/vendor";
import { Option } from "ui/form/Input";

type Props = {
  form: UseFormMethods<VendorFormValues>;
  vendor?: AggregatedVendor;
};

export type VendorFormValues = {
  name: string;
  external_id?: string; // only if updating
  description?: string;
  bank_account?: {
    account_number: string;
    routing_number: string;
  };

  // Unused until we implement paper checks
  paper_check_config?: {
    pay_to: string;
    address: {
      line1: string;
      line2?: string | null;
      city: string;
      state: string;
      postal_code: string;
      country?: string;
    };
  };

  default_payment_method: Option<VendorPaymentMethod>;
};

const VendorForm: FC<Props> = ({ vendor, form }) => {
  const { register, errors, watch } = form;
  const formData = watch();

  /*********************************************************
   *  Call important hooks
   **********************************************************/

  /*********************************************************
   *  State
   **********************************************************/
  const [isUpdatingBankAccountInfo, setIsUpdatingBankAccountInfo] = useState(false);
  const [isUpdatingPaperCheckMailingInfo, setIsUpdatingPaperCheckMailingInfo] = useState(false);

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderBasicFields = () => {
    return (
      <div>
        <h2 className={styles["subheader"]}>Basic information</h2>
        <Formblock
          label="Name*"
          type="text"
          className="modal"
          name="name"
          editing={true}
          register={register(vals.required)}
          errors={errors}
          placeholder="Vendor name"
          maxLength={100}
        />
        {vendor && (
          <Formblock
            label="ID"
            type="text"
            className="modal"
            name="external_id"
            editing={false}
            register={register}
            defaultString={vendor.external_id}
          />
        )}
        <Formblock
          label="Description"
          type="text"
          className="modal"
          name="description"
          editing={true}
          errors={errors}
          register={register}
          placeholder="Description (optional)"
          maxLength={100}
        />
      </div>
    );
  };

  const renderPaymentMethodFields = () => {
    return (
      <div className="margin-top-50">
        <h2 className={styles["subheader"]}>Payment details</h2>
        {/* Bank account */}
        <div className={styles["payment-method"]}>
          <div className="flex space-between start">
            {vendor?.bank_account && (
              <>
                <h3 className="margin-top-0">Bank account</h3>
                <Button
                  className={!isUpdatingBankAccountInfo ? "button-1" : "button-3"}
                  text={!isUpdatingBankAccountInfo ? "Update" : "Cancel"}
                  onClick={() => setIsUpdatingBankAccountInfo(!isUpdatingBankAccountInfo)}
                />
              </>
            )}
          </div>
          {
            // if the vendor already has a bank account, show it
            vendor?.bank_account && !isUpdatingBankAccountInfo && (
              <div className="margin-top-15">
                <Formblock
                  label="Account number last 4"
                  type="text"
                  className="modal"
                  name="bank_account.account_number_last_4"
                  editing={false}
                  defaultString={vendor.bank_account.account_number_last_4}
                />
                <Formblock
                  label="Routing number"
                  type="text"
                  className="modal"
                  name="bank_account.routing_number"
                  editing={false}
                  defaultString={(vendor.bank_account as RawBankAccount).routing_number}
                />
              </div>
            )
          }
          {!vendor?.bank_account && (
            <Formblock
              label="Bank transfer (ACH)"
              sublabel="Deposit funds into your vendor’s bank account."
              className="modal slider bold vendor-form"
              type="checkbox"
              register={register}
              name="add_bank_account"
              errors={errors}
              editing={true}
              onClick={() => setIsUpdatingBankAccountInfo(!isUpdatingBankAccountInfo)}
            />
          )}
          {isUpdatingBankAccountInfo && (
            <div className="margin-top-15">
              <Formblock
                label="Account number*"
                type="text"
                className="modal"
                name="bank_account.account_number"
                editing={true}
                register={register(vals.isNumber)}
                errors={errors}
                placeholder="Account number"
                maxLength={17}
              />
              <Formblock
                label="Routing number*"
                type="text"
                className="modal"
                name="bank_account.routing_number"
                editing={true}
                register={register(vals.isNumber)}
                errors={errors}
                placeholder="Routing number"
                minLength={9}
                maxLength={9}
              />
            </div>
          )}
        </div>
        {/* Check mailing */}
        <div className={styles["payment-method"] + " margin-top-25"}>
          <div className="flex space-between start">
            {vendor?.paper_check_config && (
              <>
                <h3 className="margin-top-0">Mailing address</h3>
                <Button
                  className={!isUpdatingPaperCheckMailingInfo ? "button-1" : "button-3"}
                  text={!isUpdatingPaperCheckMailingInfo ? "Update" : "Cancel"}
                  onClick={() => setIsUpdatingPaperCheckMailingInfo(!isUpdatingPaperCheckMailingInfo)}
                />
              </>
            )}
          </div>
          {!vendor?.paper_check_config && (
            <Formblock
              label="Mail paper check"
              sublabel="Send a paper check to your vendor’s address."
              className="modal slider bold vendor-form"
              type="checkbox"
              register={register}
              name="add_paper_check_config"
              errors={errors}
              editing={true}
              onClick={() => setIsUpdatingPaperCheckMailingInfo(!isUpdatingPaperCheckMailingInfo)}
            />
          )}
          {(vendor?.paper_check_config || isUpdatingPaperCheckMailingInfo) && (
            <div className="margin-top-15">
              <Formblock
                label="Pay to*"
                type="text"
                className="modal"
                name="paper_check_config.pay_to"
                form={form}
                editing={isUpdatingPaperCheckMailingInfo}
                errors={errors}
                placeholder="John Smith"
                defaultValue={vendor?.paper_check_config?.pay_to}
              />
              <Formblock
                type="address"
                name="paper_check_config.address"
                errors={errors}
                form={form}
                editing={isUpdatingPaperCheckMailingInfo}
                defaultValue={vendor?.paper_check_config?.address}
              />
            </div>
          )}
        </div>
        <div className="margin-top-50">
          <h2 className={styles["subheader"]}>Default payment method</h2>
          <Formblock
            type="select"
            className="modal"
            name="default_payment_method"
            editing={true}
            register={register}
            // only show options that are added to the vendor
            options={[
              {
                value: "ach",
                label: "Bank transfer (ACH)",
                isDisabled: !vendor?.bank_account && !formData.bank_account,
              },
              {
                value: "check",
                label: "Mail paper check",
                isDisabled: !vendor?.paper_check_config && !formData.paper_check_config,
              },
            ]}
            form={form}
            defaultValue={vendor?.default_payment_method}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderBasicFields()}
      {renderPaymentMethodFields()}
    </>
  );
};

export default VendorForm;
