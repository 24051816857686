import {
  eeoEthnicityCategories,
  eeoJobCategories,
  eeoGenderCategories,
  eeoVeteranStatusCategories,
  eeoMaritalStatusCategories,
  eeoDisabilityStatusCategories,
} from "miter-utils";
import { Option } from "ui/form/Input";

export const employment_type = [
  { label: "Employee", value: "employee" },
  { label: "Contractor", value: "contractor" },
];

export const license_status = [
  { label: "Journeyman", value: "journeyman" },
  { label: "Apprentice", value: "apprentice" },
];

export const EEO_ETHNICITY_OPTIONS = eeoEthnicityCategories.map((category) => ({
  label: category,
  value: category,
}));

export const EEO_GENDER_OPTIONS = eeoGenderCategories.map((category) => ({
  label: category,
  value: category,
}));

export const EEO_VETERAN_STATUS_OPTIONS = eeoVeteranStatusCategories.map((category) => ({
  label: category,
  value: category,
}));

export const EEO_MARITAL_STATUS_OPTIONS = eeoMaritalStatusCategories.map((category) => ({
  label: category,
  value: category,
}));

export const EEO_DISABILITY_STATUS_OPTIONS = eeoDisabilityStatusCategories.map((category) => ({
  label: category,
  value: category,
}));

export const EEO_JOB_CATEGORY_OPTIONS = eeoJobCategories.map((category) => ({
  label: category,
  value: category,
}));

export const NON_UNION_PAY_TYPE_OPTIONS = [
  { label: "Salary", value: "salary" },
  { label: "Hourly", value: "hourly" },
];

export const NON_UNION_PAY_TYPE_OPTIONS_MAP = {
  salary: "Salary",
  hourly: "Hourly",
};

export const UNION_PAY_TYPE_OPTIONS = [
  { label: "Salary", value: "salary" },
  { label: "Hourly (non-union)", value: "hourly" },
  { label: "Hourly (union)", value: "union_rate" },
];

export const UNION_PAY_TYPE_OPTIONS_MAP = {
  salary: "Salary",
  hourly: "Hourly (non-union)",
  union_rate: "Hourly (union)",
};

export const SALARY_RATE_OPTIONS = [
  { label: "Per year", value: "year" },
  { label: "Per month", value: "month" },
  { label: "Per week", value: "week" },
];

export const SALARY_RATE_OPTIONS_MAP = {
  year: "Per year",
  month: "Per month",
  week: "Per week",
};

export const BENEFITS_ELIGIBILITY_STATUS_OPTIONS: Option<"eligible" | "ineligible">[] = [
  { label: "Eligible", value: "eligible" as const },
  { label: "Ineligible", value: "ineligible" as const },
];
