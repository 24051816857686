import { ClickAwayListener } from "@material-ui/core";
import { AggregatedJob, Job, MiterAPI } from "dashboard/miter";
import { shorten } from "dashboard/utils";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Formblock, ModalHeader, Notifier } from "ui";
import DataBox from "dashboard/components/dataBox/DataBox";
import {
  useLookupOtRule,
  usePrgs,
  useLookupPrg,
  useOtRules,
  usePrgOptions,
  useOtRuleOptions,
  useActiveTeam,
  useActiveCompanyId,
  useTimesheetPolicyOptions,
  useLedgerMappingOptions,
  useLookupLedgerMapping,
  useLookupWcGroup,
  useWcGroupOptions,
  useHasEnabledWcGroups,
} from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { QBOClass } from "backend/services/qbo/qbo-types";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";

type Props = {
  data: AggregatedJob;
  updateJob: (update: Partial<Job> & Record<string, $TSFixMe>) => Promise<void>;
  updatingJob: boolean;
};

export const JobAdvancedOptions: React.FC<Props> = (props) => {
  const { data } = props;
  const [editing, setEditing] = useState(false);
  const lookupGlMapping = useLookupLedgerMapping();

  const jobAbilities = useJobAbilities();

  const otRules = useOtRules();
  const lookupOtRule = useLookupOtRule();
  const otRuleText = lookupOtRule(data.overtime_rule_id)?.label || "-";

  const prgs = usePrgs();
  const lookupPrg = useLookupPrg();
  const prgLabel = lookupPrg(data.pay_rate_group)?.label || "-";

  const lookupWcGroup = useLookupWcGroup();
  const hasEnabledWcGroups = useHasEnabledWcGroups();

  const teamMembersWithAccessString = data.specific_team_members_can_access
    ? `${data.team_members?.length || 0} team member${data.team_members?.length === 1 ? "" : "s"}`
    : "All team members";

  const ledgerMapping = lookupGlMapping(data.ledger_mapping_id)?.name;
  const wcGroupName = lookupWcGroup(data.wc_group_id)?.name;

  return (
    <DataBox
      title="Advanced options"
      className={undefined}
      onEdit={jobAbilities.can("update", data) ? () => setEditing(true) : undefined}
      editText={undefined}
      rows={undefined}
    >
      <>
        {!!prgs.length && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Pay rate group</span>
            <span className={"data-box-section-value font-14"}>{prgLabel}</span>
          </div>
        )}
        {editing && <JobAdvancedOptionsForm {...props} hide={() => setEditing(false)} />}
        {!!otRules.length && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Overtime rule</span>
            <span className={"data-box-section-value font-14"}>{otRuleText}</span>
          </div>
        )}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Team members with access</span>
          <span className={"data-box-section-value font-14"}>{teamMembersWithAccessString}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Accounting ID</span>
          <span className={"data-box-section-value font-14"}>{data.external_accounting_id || "-"}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Prime contractor</span>
          <span className={"data-box-section-value font-14"}>{data.prime_contractor ? "Yes" : "No"}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>GL mapping</span>
          <span className={"data-box-section-value font-14"}>{ledgerMapping || "-"}</span>
        </div>
        {hasEnabledWcGroups && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Workers comp group</span>
            <span className={"data-box-section-value font-14"}>{wcGroupName || "-"}</span>
          </div>
        )}
        {data.integrations?.qbo && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>QuickBooks Class</span>
            <span className={"data-box-section-value font-14"}>
              {data.integrations.qbo.qboClass?.Name || "-"}
            </span>
          </div>
        )}
      </>
    </DataBox>
  );
};

type QBOClassOption = Option<string> & { qboClass: QBOClass };

export const JobAdvancedOptionsForm: React.FC<Props & { hide: () => void }> = (props) => {
  const { data: jobData, updateJob, updatingJob, hide } = props;
  const activeCompanyId = useActiveCompanyId();
  const { register, errors, handleSubmit, control } = useForm();
  const [primeContractor, setPrimeContractor] = useState(jobData?.prime_contractor);
  const { can } = useMiterAbilities();
  const [specificTeamMembersCanAccess, setSetSpecificTeamMembersCanAccess] = useState<boolean>(
    !!jobData?.specific_team_members_can_access
  );

  const otRules = useOtRules();
  const prgs = usePrgs();
  const payRateGroupOptions = usePrgOptions();
  const otRuleOptions = useOtRuleOptions();
  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const ledgerMappingOptions = useLedgerMappingOptions();
  const wcGroupOptions = useWcGroupOptions();
  const hasEnabledWcGroups = useHasEnabledWcGroups();

  const [qboClassOptions, setQboClassOptions] = useState<QBOClassOption[] | undefined>();
  const [selectedQboClassOption, setSelectedQboClassOption] = useState<QBOClassOption>();

  const submit = async (data) => {
    const { external_accounting_id, code, team_members } = data;
    await updateJob({
      external_accounting_id,
      code,
      prime_contractor: primeContractor,
      pay_rate_group: data.pay_rate_group?.value || null,
      overtime_rule_id: data.overtime_rule_id?.value || null,
      specific_team_members_can_access: specificTeamMembersCanAccess,
      team_members: specificTeamMembersCanAccess ? team_members?.map((tm) => tm.value) || [] : [],
      "integrations.qbo.qboClass": selectedQboClassOption?.qboClass,
      timesheet_policy_id: data.timesheet_policy_id?.value || null,
      ledger_mapping_id: data.ledger_mapping_id?.value || null,
      wc_group_id: data.wc_group_id?.value || null,
    });
    hide();
  };

  const teamMembers = useActiveTeam();

  const teamMemberOptions = teamMembers.map((tm) => ({ label: tm.full_name, value: tm._id }));

  const getQboClassOptions = async () => {
    if (!jobData.integrations?.qbo || !activeCompanyId) return;
    try {
      const response = await MiterAPI.integrations.quickbooks.get_activity_mapping_options(activeCompanyId);
      if (response.error) throw new Error(response.error);
      const classOptions = response.qbo?.classes.map((c) => ({ label: c.Name, value: c.Id, qboClass: c }));
      setQboClassOptions(classOptions);
      setSelectedQboClassOption(
        classOptions?.find((o) => o.value === jobData.integrations?.qbo?.qboClass?.Id)
      );
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error retrieving Classes from QBO.`);
    }
  };

  useEffect(() => {
    getQboClassOptions();
  }, []);

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form">
          <ModalHeader heading={shorten(`Advanced options for ${jobData.name}`, 30)} onHide={hide} />
          <div className="modal-body form">
            <div className="vertical-spacer"></div>
            {!!prgs.length && (
              <Formblock
                label="Pay rate group"
                type="select"
                control={control}
                className="modal"
                options={payRateGroupOptions}
                defaultValue={jobData?.pay_rate_group}
                name="pay_rate_group"
                editing={true}
                isClearable={true}
                errors={errors}
              />
            )}
            {!!otRules.length && (
              <Formblock
                label="Overtime rule"
                type="select"
                control={control}
                className="modal"
                options={otRuleOptions}
                defaultValue={jobData?.overtime_rule_id}
                name="overtime_rule_id"
                editing={true}
                isClearable={true}
                errors={errors}
              />
            )}
            <Formblock
              label="Team member access"
              text="Only specific team members can clock into this job."
              type="checkbox"
              className="modal"
              defaultValue={jobData?.specific_team_members_can_access}
              name="specific_team_members_can_access"
              editing={true}
              onChange={(e) => setSetSpecificTeamMembersCanAccess(e.target.checked)}
              register={register}
              errors={errors}
            />
            {specificTeamMembersCanAccess && (
              <Formblock
                type="multiselect"
                name="team_members"
                label="Select team members"
                labelInfo="Select the team members that can clock into this job. Universal supervisors and supervisors on this job can always clock into this job."
                options={teamMemberOptions}
                control={control}
                editing={true}
                errors={errors}
                defaultValue={jobData?.team_members}
                className="modal"
                height="unset"
                sublabelStyle={{ marginBottom: 10 }}
              />
            )}
            <Formblock
              label="Accounting ID"
              labelInfo="The identifier of the job in your accounting system."
              type="text"
              className="modal"
              defaultValue={jobData.external_accounting_id}
              name="external_accounting_id"
              editing={true}
              register={register}
              errors={errors}
            />
            <Formblock
              label="Prime contractor"
              text="We are the prime contractor"
              type="checkbox"
              className="modal"
              defaultValue={primeContractor}
              name="prime_contractor"
              editing={true}
              onChange={(e) => setPrimeContractor(e.target.checked)}
              register={register}
              errors={errors}
            />
            <Formblock
              label="Timesheet policy"
              labelInfo="The timesheet policy that will be used for timesheets on this job."
              type="select"
              className="modal"
              defaultValue={jobData?.timesheet_policy_id}
              options={timesheetPolicyOptions}
              name="timesheet_policy_id"
              editing={true}
              errors={errors}
              control={control}
            />
            {can("accounting:settings") && (
              <Formblock
                label="GL mapping"
                type="select"
                className="modal"
                defaultValue={jobData?.ledger_mapping_id}
                options={ledgerMappingOptions}
                name="ledger_mapping_id"
                editing={true}
                errors={errors}
                control={control}
                isClearable={true}
              />
            )}
            {hasEnabledWcGroups && can("lists:workers_comp_codes:manage") && (
              <Formblock
                label="Workers comp group"
                type="select"
                className="modal"
                defaultValue={jobData?.wc_group_id}
                options={wcGroupOptions}
                name="wc_group_id"
                editing={true}
                errors={errors}
                control={control}
                isClearable={true}
              />
            )}
            {qboClassOptions && (
              <Formblock
                type="select"
                name="qbo_class"
                label="QBO Class"
                labelInfo="A Job's class will override a team member's default Class."
                control={control}
                editing={true}
                errors={errors}
                options={qboClassOptions}
                value={selectedQboClassOption}
                onChange={setSelectedQboClassOption}
                className="modal"
              />
            )}

            <div className="vertical-spacer"></div>
          </div>
          <div className="modal-footer form">
            <button className={updatingJob ? "button-1 inactive " : "button-1"} onClick={hide}>
              Cancel
            </button>
            <Button onClick={handleSubmit(submit)} loading={updatingJob} className="button-2" text="Update" />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
