import { MiterIntegrationForCompany } from "dashboard/miter";
import React, { useEffect } from "react";
import { Button, Loader } from "ui";
import { IntegrationLoginForm } from "./IntegrationLoginForm";
import connectToQuickbooks from "dashboard/assets/connectToQuickbooks.png";
import { AgaveLink } from "./AgaveLink";
import { HH2Authentication } from "./HH2Authentication";
import { ProcoreAuthenticateIntegration } from "./ProcoreAuthenticateIntegration";
import { AuthenticateIntegrationWrapper } from "./AuthenticateIntegrationWrapper";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { IntacctAuthentication } from "./IntacctAuthentication";
import { BuildOpsAuthentication } from "./buildops/BuildOpsAuthentication";
import { NetSuiteAuthentication } from "./netsuite/NetSuiteAuthentication";
import { PayrollIntegrationsOnboardingModal } from "./PayrollIntegrationsOnboardingModal";
import { useGetSetupLink } from "dashboard/hooks/integrations/useGetSetupLink";
import { BambooAuthentication } from "./bamboo/BambooAuthentication";

export const AuthenticateIntegration: React.FC<{
  integration: MiterIntegrationForCompany;
  onAuth: () => Promise<void>;
}> = ({ integration, onAuth }) => {
  const activeCompanyId = useActiveCompanyId();
  const { getSetupLink, setupLink, goToSetupLink } = useGetSetupLink({ integration, activeCompanyId });

  useEffect(() => {
    if (integration.has_setup_link) {
      getSetupLink();
    }
  }, [integration, activeCompanyId]);

  if (integration.key === "qbo") {
    return (
      <AuthenticateIntegrationWrapper integration={integration} showPopupBlockerWarning>
        {setupLink ? (
          <img src={connectToQuickbooks} onClick={goToSetupLink} style={{ height: 33, cursor: "pointer" }} />
        ) : (
          <Loader />
        )}
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "procore") {
    return (
      <div>
        <div className="vertical-spacer"></div>
        <ProcoreAuthenticateIntegration
          setupLink={setupLink}
          integration={integration}
          goToSetupLink={goToSetupLink}
        />
      </div>
    );
  }

  if (integration.key === "xero") {
    return (
      <AuthenticateIntegrationWrapper integration={integration} showPopupBlockerWarning>
        {setupLink ? (
          <Button text="Connect to Xero" className="button-2 no-margin" onClick={goToSetupLink} />
        ) : (
          <Loader />
        )}
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "jonas") {
    return (
      <AuthenticateIntegrationWrapper integration={integration}>
        <IntegrationLoginForm integration={integration} onLogin={onAuth} />
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "sage_intacct") {
    return (
      <AuthenticateIntegrationWrapper integration={integration} includeChildrenInSameContainer>
        <IntacctAuthentication integration={integration} />
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "sage_300" || integration.key === "sage_100") {
    return <HH2Authentication integration={integration} />;
  }

  if (integration.key === "checkr") {
    return (
      <div style={{ marginTop: 10 }}>
        <div>
          {!integration?.connection?.metadata?.checkr?.encrypted_access_token && setupLink && (
            <Button onClick={goToSetupLink} className="button-2">
              Set up Checkr
            </Button>
          )}
          {integration?.connection?.metadata?.checkr?.encrypted_access_token && (
            <div>
              It will take 1 -2 business days to be approved by Checkr and begin conducting background checks.
            </div>
          )}
        </div>
      </div>
    );
  }

  if (integration.key === "bamboo") {
    return (
      <AuthenticateIntegrationWrapper integration={integration} showPopupBlockerWarning>
        <BambooAuthentication integration={integration} onLogin={onAuth} />
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.agave) {
    return (
      <AuthenticateIntegrationWrapper integration={integration} showPopupBlockerWarning>
        <AgaveLink integration={integration} />
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "qbd") {
    return (
      <AuthenticateIntegrationWrapper integration={integration} showPopupBlockerWarning>
        <Button onClick={goToSetupLink} className="button-2">
          Set Up Quickbooks Desktop
        </Button>
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "employee_navigator") {
    // No setup link, needs to be done via the partner website / coordinated over email for now.
    return <AuthenticateIntegrationWrapper integration={integration}></AuthenticateIntegrationWrapper>;
  }

  if (integration.key === "payroll_integrations") {
    // No setup link, needs to be done via the partner website / coordinated over email for now.
    return (
      <AuthenticateIntegrationWrapper integration={integration}>
        <PayrollIntegrationsOnboardingModal activeCompanyId={activeCompanyId} />
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "buildops") {
    return (
      <AuthenticateIntegrationWrapper integration={integration}>
        <BuildOpsAuthentication integration={integration} onAuth={onAuth} />
      </AuthenticateIntegrationWrapper>
    );
  }

  if (integration.key === "netsuite") {
    return (
      <AuthenticateIntegrationWrapper integration={integration}>
        <NetSuiteAuthentication integration={integration} activeCompanyId={activeCompanyId} />
      </AuthenticateIntegrationWrapper>
    );
  }

  return (
    <>
      <div className="vertical-spacer"></div>
      <div className="yellow-text-container">
        This integration is not yet set up. A Miter product specialist will reach out shortly to help you get
        started!
      </div>
    </>
  );
};
