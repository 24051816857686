/* global google */
import { GoogleMap, Marker } from "@react-google-maps/api";
import Banner from "dashboard/components/shared/Banner";
import { AggregatedLiveTimesheet } from "dashboard/miter";
import {
  determineGeofenceStatusOfLiveTimesheet,
  LatLng,
  useLoadGoogleMaps,
} from "dashboard/utils/geolocation";
import mapMarkerBlue from "dashboard/assets/mapMarkerBlue.png";
import mapMarkerGreen from "dashboard/assets/mapMarkerGreen.png";
import React, { useCallback } from "react";
import { shorten } from "dashboard/utils";
import { DateTime } from "luxon";
import InfoButton from "dashboard/components/information/information";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

const LiveMapView: React.FC<{ tmLocation: LatLng | null; jobLocation: LatLng | null }> = ({
  tmLocation,
  jobLocation,
}) => {
  const { isLoaded } = useLoadGoogleMaps();

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    if (tmLocation) {
      bounds.extend(tmLocation);
    }
    if (jobLocation) {
      bounds.extend(jobLocation);
    }
    map.fitBounds(bounds);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: 300 }}
      onLoad={onLoad}
      zoom={8}
      center={tmLocation || undefined}
      options={{
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
        },
      }}
    >
      {tmLocation && (
        <Marker
          position={tmLocation}
          icon={{ url: mapMarkerGreen, scaledSize: new google.maps.Size(35, 35) }}
        />
      )}
      {jobLocation && (
        <Marker
          position={jobLocation}
          icon={{ url: mapMarkerBlue, scaledSize: new google.maps.Size(35, 35) }}
        />
      )}
    </GoogleMap>
  ) : null;
};

const LiveTimesheetGeolocation: React.FC<{ ts: AggregatedLiveTimesheet }> = ({ ts }) => {
  const company = useActiveCompany();
  const radiusMiles = company?.settings.timesheets.geofence_radius || 0.5;
  const geofenceStatus = determineGeofenceStatusOfLiveTimesheet(ts, radiusMiles);

  const { timestamp, ...tmGeolocation } = ts?.last_bread_crumb || {};

  const showTmLocation = Object.entries(tmGeolocation).length === 2;

  const showTextBox = () => {
    switch (geofenceStatus) {
      case "outside_fence":
        return (
          <Banner
            content={`${ts.team_member?.first_name}'s most recent location was more than ${radiusMiles} mile(s) from the jobsite.`}
            type={"warning"}
          ></Banner>
        );
      case "missing_timesheet_geolocation":
        return (
          <Banner
            content={`${ts.team_member?.first_name}'s location is not being tracked. Please ensure that location services are enabled from the dashboard and mobile app.`}
            type={"question"}
          ></Banner>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="payment-active-view-header">
        <div style={{ display: "flex", flexDirection: "row" }}>
          {"Geolocation"}
          {timestamp ? (
            <InfoButton text={`Last location change: ${DateTime.fromSeconds(timestamp).toFormat("t")}.`} />
          ) : null}
        </div>
      </div>
      {showTextBox()}
      <div style={{ marginTop: 15 }}></div>
      {showTmLocation ? (
        <LiveMapView tmLocation={tmGeolocation as LatLng} jobLocation={ts?.job?.geolocation || null} />
      ) : null}
      <div style={{ marginTop: 15 }}></div>
      {ts.job?.name && showTmLocation ? (
        <div className="flex" style={{ marginTop: 10, marginBottom: 10 }}>
          <img src={mapMarkerBlue} style={{ height: 23, marginRight: 6 }} />
          <strong>Job:&nbsp;</strong>
          <div>{shorten(ts.job?.name, 30)}</div>
        </div>
      ) : null}
      <div className="flex-1" />
      {ts?.last_bread_crumb?.timestamp && (
        <div className="flex" style={{ marginBottom: 10 }}>
          <img src={mapMarkerGreen} style={{ height: 23, marginRight: 6 }} />
          <div>
            <strong>Clock in: </strong>
            {DateTime.fromSeconds(ts?.clock_in).toFormat("FFF")}
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveTimesheetGeolocation;
