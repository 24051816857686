import React, { useState, useEffect, CSSProperties, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AggregatedTeamMember, BulkUpdateResult, MiterAPI, PostTaxDeduction } from "dashboard/miter";
import PostTaxDeductionModal, { DeleteDeductionModal } from "./PostTaxDeductionModal";
import Notifier from "dashboard/utils/notifier";
import { CheckPostTaxDeduction } from "backend/utils/check/check-types";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import {
  useActiveCompanyId,
  useLedgerAccountLabeler,
  useLedgerAccountOptions,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { ImportHistory } from "dashboard/components/importer/ImportHistory";
import { PTDImporter } from "dashboard/components/post-tax-deductions/PTDImporter";
import { ClockCounterClockwise } from "phosphor-react";
import { sortBy } from "lodash";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";
import { EditableCallbackParams } from "ag-grid-community";
import { isActive } from "miter-utils";
import { DeepPartial } from "utility-types";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { usePostTaxDeductionAbilities } from "dashboard/hooks/abilities-hooks/usePostTaxDeductionAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { isTmActive } from "../team-members/TeamUtils";
import { isPtdConnectedToExternalSource } from "../benefits/benefitsUtils";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { IS_PRODUCTION } from "dashboard/utils/environment";

type Props = {
  teamMember?: AggregatedTeamMember;
  header?: string;
  headerStyle?: CSSProperties;
};

export type PtdTableEntry = PostTaxDeduction & {
  amountLabel: string;
  description: string;
  employee_name: string | undefined;
  employee_id: string | undefined;
  type: "Child support" | "Miscellaneous";
  effective_start: string | undefined;
  effective_end: string | null | undefined;
  readonly: boolean;
  amountType: "Dollar" | "Percent";
  status: "active" | "inactive";
};

const TOGGLER_CONFIG = {
  config: [
    { path: "active", label: "Active" },
    { path: "inactive", label: "Inactive" },
    { path: "all", label: "All" },
  ],
  path: "active",
  secondary: true,
  field: "status",
};

const PostTaxDeductions: React.FC<Props> = ({ teamMember }: Props) => {
  const activeCompanyId = useActiveCompanyId();
  const ledgerAccountOptions = useLedgerAccountOptions({
    includeCustomOption: { label: "Default", value: "" },
  });
  const lookupTeam = useLookupTeam();
  const accountLabeler = useLedgerAccountLabeler();
  // const { can, cannot } = useMiterAbilities();

  const [showModal, setShowModal] = useState(false);
  const [activePostTaxDeduction, setActivePostTaxDeduction] = useState<PtdTableEntry | null | undefined>(
    null
  );
  const [postTaxDeductions, setPostTaxDeductions] = useState<PtdTableEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRows, setSelectedRows] = useState<PtdTableEntry[]>([]);

  const miterAbilities = useMiterAbilities();
  const ptdAbilities = usePostTaxDeductionAbilities();

  const { enPTDDeductionCodeOptions, enDeductionCodesOptionsMap } = useEmployeeNavigator();

  const integrationLookup = useHasIntegration();
  const hasEnIntegration = integrationLookup.has("employee_navigator");
  const has401KPayrollIntegrations = integrationLookup.has("payroll_integrations");

  /*********************************************************
   *  Functions for fetching, updating, and cleaning data
   **********************************************************/

  const cleanPostTaxDeductions = (data: PostTaxDeduction[]): PtdTableEntry[] => {
    return data
      .filter((ptd) => !ptd.check_post_tax_deduction.metadata?.fringe_group_id)
      .map((ptd) => {
        const tm = lookupTeam(ptd.employee);
        const check_ptd = ptd.check_post_tax_deduction as CheckPostTaxDeduction;
        const row: PtdTableEntry = {
          ...ptd,
          employee_name: tm?.full_name,
          employee_id: tm?.friendly_id,
          description: check_ptd.description,
          type: check_ptd.type === "child_support" ? "Child support" : "Miscellaneous",
          effective_start: check_ptd.effective_start,
          effective_end: check_ptd.effective_end,
          readonly: ptdAbilities.cannot("update", ptd),
          amountLabel: "",
          amountType: check_ptd.miscellaneous?.amount ? "Dollar" : "Percent",
          status:
            tm && isTmActive(tm) && isActive(check_ptd.effective_start, check_ptd.effective_end)
              ? "active"
              : "inactive",
        };

        if (check_ptd.child_support) {
          row.amountLabel = "$" + check_ptd.child_support.amount;
        } else if (check_ptd.miscellaneous) {
          row.amountLabel = check_ptd.miscellaneous.amount
            ? "$" + check_ptd.miscellaneous.amount
            : check_ptd.miscellaneous.percent + "%";
        }

        return row;
      });
  };

  const getPostTaxDeductions = async () => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const response = teamMember
        ? await MiterAPI.team_member.post_tax_deductions.all(teamMember._id)
        : await MiterAPI.post_tax_deductions.retrieve_many({
            filter: [{ field: "company", value: activeCompanyId }],
          });

      if (response.error) throw Error(response.error);

      const cleanedPostTaxDeductions =
        "post_tax_deductions" in response
          ? cleanPostTaxDeductions(response.post_tax_deductions)
          : cleanPostTaxDeductions(response);

      const accessiblePTDs = cleanedPostTaxDeductions.filter((ptd) => ptdAbilities.can("read", ptd));

      setPostTaxDeductions(sortBy(accessiblePTDs, "employee_name"));
    } catch (e) {
      console.log("Error", e);
      // @ts-expect-error Error cleanup
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  /*********************************************************
   *  Handler functions for the modal and table
   **********************************************************/

  const handleModalSubmit = () => {
    getPostTaxDeductions();
    setShowModal(false);
    setActivePostTaxDeduction(null);
  };

  const handleModalHide = () => {
    setShowModal(false);
    setActivePostTaxDeduction(null);
  };

  const handleRowClick = (row) => {
    setActivePostTaxDeduction(row);
    setShowModal(true);
  };

  const hideDeleteConfirmation = () => setShowDeleteConfirmation(false);

  const handleBulkCreateFinish = async () => {
    await getPostTaxDeductions();
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  /*********************************************************
   *  Helper function for deleting multiple deductions
   **********************************************************/
  const deletePostTaxDeductions = async () => {
    if (ptdAbilities.cannot("delete", selectedRows)) {
      Notifier.error("You do not have permission to delete these post-tax deductions.");
      return;
    }

    setDeleting(true);
    const res = await MiterAPI.post_tax_deductions.bulk_delete(selectedRows.map((p) => p._id));
    if (res.error) throw new Error(res.error);
    if (res.errors.length) {
      Notifier.warning(`At least one deduction failed to delete: ${res.errors[0]!.message}`);
    } else {
      Notifier.success(`Deduction(s) deleted successfully.`);
    }

    setDeleting(false);
    getPostTaxDeductions();
    setSelectedRows([]);
    hideDeleteConfirmation();
  };

  const cleanBulkIntegrationFields = (d: Partial<PtdTableEntry>): DeepPartial<PostTaxDeduction> => {
    const integrationParams: DeepPartial<PostTaxDeduction["integrations"]> = {};

    // If we have an employee navigator deduction code, add that to the params
    if (d.integrations?.employee_navigator?.deduction_code) {
      integrationParams.employee_navigator = {
        deduction_code: d.integrations.employee_navigator.deduction_code,
      };
    }

    if (Object.keys(integrationParams).length) {
      return { integrations: integrationParams };
    }

    return {};
  };

  const cleanUpdateParams = (deductions: PtdTableEntry[]) => {
    return deductions.map((d) => {
      return {
        _id: d._id,
        params: {
          gl_account_id: d.gl_account_id || null,
          description: d.description,
          is_401k_loan: d.is_401k_loan,
          effective_start: d.effective_start,
          effective_end: d.effective_end,
          ...buildAtomicMongoUpdateFromNested(cleanBulkIntegrationFields(d)),
        },
      };
    });
  };

  const updatePostTaxDeductions = async (deductions: PtdTableEntry[]): Promise<BulkUpdateResult> => {
    if (ptdAbilities.cannot("update", deductions)) {
      Notifier.error("You do not have permission to update these post-tax deductions.");
      return { successes: [], errors: [] };
    }

    try {
      const params = cleanUpdateParams(deductions);
      const res = await MiterAPI.post_tax_deductions.bulk_update(params);
      if (res.error) throw Error(res.error);

      if (res.errors.length > 0) {
        const successes = res.successes;
        const errors = res.errors;

        return { successes, errors };
      }

      getPostTaxDeductions();
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem updating post tax deductions. We're looking into it.");
      return { successes: [], errors: [] };
    }
  };

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const isEditableColumn = (params: EditableCallbackParams<PtdTableEntry>) => {
    return !isPtdConnectedToExternalSource(params.data, activeCompanyId);
  };

  const dynamicButtons = useMemo(() => {
    return [
      {
        label: "Delete",
        className: "button-3",
        action: () => setShowDeleteConfirmation(true),
        shouldShow: () => ptdAbilities.can("delete", selectedRows),
      },
    ];
  }, [selectedRows, ptdAbilities.can]);

  const defaultButtons: TableActionLink[] = useMemo(
    () => [
      {
        key: "import",
        component: <PTDImporter onFinish={handleBulkCreateFinish} />,
        shouldShow: () => miterAbilities.can("post_tax_deductions:create"),
      },
      {
        key: "import-history",
        className: "button-1",
        action: () => setShowImportHistory(true),
        label: "Import history",
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => miterAbilities.can("post_tax_deductions:create"),
      },
      {
        label: "+ New",
        important: true,
        className: "button-2",
        action: () => setShowModal(true),
        shouldShow: () => miterAbilities.can("post_tax_deductions:create"),
      },
    ],
    [miterAbilities.can]
  );

  const ptdColumns = useMemo(() => {
    const cols: ColumnConfig<PtdTableEntry>[] = [
      {
        field: "description",
        headerName: "Description",
        dataType: "string",
        minWidth: 250,
        editorType: "text",
        filter: "agTextColumnFilter",
        editable: true,
        pinned: "left",
        enableRowGroup: true,
      },
      {
        field: "type",
        headerName: "Category",
        displayType: "badge",
        colors: {
          Miscellaneous: "blue",
          "Child support": "green",
        },
        filter: true,
      },
      {
        field: "amountLabel",
        headerName: "Amount",
        sumRow: true,
        isCurrency: true,
      },
      {
        field: "effective_start",
        headerName: "Start",
        dataType: "date",
        editorType: "date",
        editorDateType: "iso",
        editable: isEditableColumn,
      },
      {
        field: "effective_end",
        headerName: "End",
        dataType: "date",
        editorType: "date",
        editorDateType: "iso",
        editable: isEditableColumn,
      },
      {
        field: "gl_account_id",
        headerName: "GL account",
        filter: true,
        valueGetter: (params) =>
          params.data?.check_post_tax_deduction?.managed
            ? "N/A"
            : accountLabeler(params.data?.gl_account_id) || "Default",
        dataType: "string",
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: ledgerAccountOptions,
        }),
      },
      {
        field: "is_401k_loan",
        headerName: "Is 401K loan",
        filter: true,
        initialHide: true,
        dataType: "boolean",
        editable: (params) => {
          return params.data?.check_post_tax_deduction?.type === "miscellaneous";
        },
        editorType: "checkbox",
      },
    ];

    if (!teamMember) {
      cols.unshift({
        field: "employee_id",
        headerName: "Employee ID",
        dataType: "string",
        minWidth: 150,
        filter: true,
        enableRowGroup: true,
        hide: true,
      });
      cols.unshift({
        field: "employee_name",
        headerName: "Employee",
        dataType: "string",
        minWidth: 150,
        filter: true,
        enableRowGroup: true,
        pinned: "left",
      });
    }

    if (hasEnIntegration) {
      cols.push({
        field: "integrations.employee_navigator.deduction_code",
        headerName: "Deduction Code",
        dataType: "string" as const,
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({ options: enPTDDeductionCodeOptions }),
        valueGetter: (params) => {
          const deductionCode = params.data?.integrations?.employee_navigator?.deduction_code || "";
          return enDeductionCodesOptionsMap?.[deductionCode]?.label || "-";
        },
      });
    }
    if (has401KPayrollIntegrations) {
      cols.push({
        field: "integrations.payroll_integrations.deduction_code",
        headerName: "401(k) Deduction Code",
        dataType: "string",
        valueGetter: (params) => {
          return params.data?.integrations?.payroll_integrations?.deduction_code || "";
        },
      });
      if (!IS_PRODUCTION) {
        cols.push({
          field: "integrations.payroll_integrations.external_id",
          headerName: "401(k) external ID",
          dataType: "string",
          editable: true,
          editorType: "text",
        });
      }
    }

    return cols;
  }, [teamMember, hasEnIntegration, enPTDDeductionCodeOptions, has401KPayrollIntegrations]);

  /*********************************************************
   *  useEffect function to fetch post-tax deductions
   **********************************************************/

  useEffect(() => {
    getPostTaxDeductions();
  }, [teamMember]);

  return (
    <div className="post-tax-deductions-table-wrapper">
      {showModal && (
        <PostTaxDeductionModal
          teamMember={teamMember}
          onHide={handleModalHide}
          ptd={activePostTaxDeduction}
          onSubmit={handleModalSubmit}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteDeductionModal
          multi={selectedRows.length > 1}
          onDelete={deletePostTaxDeductions}
          onCancel={hideDeleteConfirmation}
          loading={deleting}
        />
      )}
      {(teamMember?.employment_type === "employee" || !teamMember) && (
        <TableV2
          data={postTaxDeductions}
          columns={ptdColumns}
          onSelect={setSelectedRows}
          onClick={handleRowClick}
          dynamicActions={dynamicButtons}
          staticActions={defaultButtons}
          isLoading={loading}
          id="ptds-table"
          resource="post-tax deductions"
          showReportViews={true}
          showTotals={!!teamMember}
          editable={miterAbilities.can("post_tax_deductions:update")}
          onSave={updatePostTaxDeductions}
          gridWrapperStyle={{ height: 550 }}
          toggler={TOGGLER_CONFIG}
        />
      )}

      {teamMember?.employment_type === "contractor" && <ContractorEmptyCard teamMember={teamMember} />}

      {showImportHistory && (
        <ImportHistory
          id={"post-tax-deductions"}
          resource={"post-tax deductions"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
    </div>
  );
};

export default PostTaxDeductions;

/*********************************************************
 *  ContractorEmptyCard
 *
 *  This is a basic react component we use to display
 *  an empty state for team members who are contractors
 *  and can't have post-tax deductions attached to them.
 **********************************************************/

export const ContractorEmptyCard: React.FC<{ teamMember: AggregatedTeamMember }> = ({ teamMember }) => {
  const name = teamMember ? teamMember.full_name : "This team member";

  const navigate = useNavigate();

  return (
    <div className="contractor-benefits-wrapper" style={{ marginTop: 50 }}>
      <h3>Contractor post-tax deductions</h3>
      <div className="contractor-benefits-text">
        {name +
          " is a contractor, and contractors aren't eligible to have benefits or post-tax deductions. Make sure you have selected an employee before adding post-tax deductions."}
      </div>
      <div className="contractor-benefits-button">
        <button className="button-1 no-margin" onClick={() => navigate("/team")}>
          Go to team members
        </button>
      </div>
    </div>
  );
};

export const MISC_AMOUNT_TYPE_OPTIONS = [
  { label: "Dollar amount", value: "Dollar", input_type: "unit" },
  { label: "Percent", value: "Percent", input_type: "percent" },
];
