import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { CheckCircle } from "phosphor-react";
import { downloadFiles, formatFilesForUpload } from "miter-utils";
import { useForm } from "react-hook-form";
import { Button, Formblock, Notifier, SettingsCard } from "ui";
import { ESignatureInputValue } from "ui/form/ESignatureInput";
import { useContext, useState } from "react";
import AppContext from "dashboard/contexts/app-context";
import { useHasAccessToMiterPaystubsAndChecks } from "dashboard/gating";

export const PayrollCheckSignature: React.FC = () => {
  const hasAccessToMiterPayStubs = useHasAccessToMiterPaystubsAndChecks();
  const activeCompany = useActiveCompany();
  const { fetchUserData } = useContext(AppContext);

  const [signatureFileId, setSignatureFileId] = useState<string | null | undefined>(
    activeCompany!.settings.payroll.signature_file_id
  );
  const [loading, setLoading] = useState(false);
  const form = useForm();

  const { handleSubmit } = form;

  const save = async (data) => {
    const signature: ESignatureInputValue = data.payrollSignature;

    if (!activeCompany?._id || !signature?.data) return;

    if (!signature.agreed_to_disclosure) {
      Notifier.error("Must agree to the disclosure agreement");
      return;
    }

    const file = createFileWithSignature(signature.data);
    if (!file) return;

    try {
      setLoading(true);
      const signatureFiles = formatFilesForUpload([{ blob: file, url: signature.data }], activeCompany._id);
      const response = await MiterAPI.files.upload({
        files: signatureFiles,
      });
      if ("error" in response) throw new Error("Unable to create signature file " + response.error);

      const fileId = response[0]?.file?._id;

      if (!fileId) {
        throw new Error("Unable to create signature file" + activeCompany._id);
      }

      const updateCompany = await MiterAPI.companies.update(activeCompany?._id, {
        "settings.payroll.signature_file_id": fileId,
      });
      if (!updateCompany) {
        throw new Error("Failed to save payroll signature file id to company" + activeCompany._id);
      }
      fetchUserData();
      setSignatureFileId(fileId);
    } catch (e: $TSFixMe) {
      Notifier.error("Failed to create signature file");
      console.error(e.message);
    }
    setLoading(false);
  };

  const downloadSignature = async () => {
    if (!signatureFileId) return;
    downloadFiles([signatureFileId], setLoading);
  };

  const deleteSignature = async () => {
    if (!signatureFileId || !activeCompany) return;
    try {
      setLoading(true);
      const response = await MiterAPI.files.delete([signatureFileId]);
      if (response.error) throw new Error("Error deleting signature");

      const updateCompany = await MiterAPI.companies.update(activeCompany._id, {
        ["settings.payroll.signature_file_id"]: null,
      });
      if (updateCompany.error) throw new Error("Error deleting signature");
      Notifier.success("Successfully deleted signature");
      fetchUserData();
      setSignatureFileId(null);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error(e.message, activeCompany?._id);
    }
    setLoading(false);
  };

  const createFileWithSignature = (signature: string) => {
    const parts = signature.split(",");
    const base64String = parts[1];
    if (!base64String) return;

    const buffer = Buffer.from(base64String, "base64");
    // If I don't add .png to the name the download fails later even though I give the type so let's leave that for now
    const file = new File([buffer], activeCompany!._id + "-payroll-signature.png", { type: "image/png" });
    return file;
  };

  if (!hasAccessToMiterPayStubs) return null;

  if (!!signatureFileId) {
    return (
      <SettingsCard
        title="Paper check signature"
        subtitle="Signature provided will be printed on paper checks"
        contentStyle={{ color: "rgb(51,51,51)" }}
      >
        <CheckCircle style={{ marginRight: 10, marginBottom: -3 }} color={"#26A96C"} weight="fill" />
        E-Signature completed
        <div style={{ display: "flex", marginTop: 15 }}>
          <Button className={"button-1"} loading={loading} onClick={downloadSignature}>
            View signature
          </Button>
          <Button className={"button-3"} loading={loading} onClick={deleteSignature}>
            Archive
          </Button>
        </div>
      </SettingsCard>
    );
  }

  return (
    <>
      <SettingsCard
        title="Paper check signature"
        subtitle="Signature provided will be printed on paper checks"
        contentStyle={{ color: "rgb(51,51,51)" }}
      >
        <Formblock
          type="esignature"
          name="payrollSignature"
          disclosure='The section above should be filled out by the person whose name is to appear on your companies’ checks. By selecting “I agree to electronically sign all checks using this e-signature" checkbox, you authorize Miter to sign checks using the signature above, you certify that it is an authorized signature of the company, and you agree your electronic signature is the legal equivalent of your manual/handwritten signature on this Document.  By selecting "I agree to electronically sign all checks using this e-signature" using any device, means or action, you consent to the legally binding terms and conditions of this Agreement. You further agree that your signature on this document (hereafter referred to as your "E-Signature") is as valid as if you signed the document in writing. You also agree that no certification authority or other third party verification is necessary to validate your E-Signature, and that the lack of such certification or third party verification will not in any way affect the enforceability of your E-Signature.'
          agreementAffirmationText="I agree to electionically sign all paper checks using this e-signature"
          editing={true}
          className="modal wizard"
          form={form}
          onError={Notifier.error}
        />
        <Button loading={loading} text="Save" onClick={handleSubmit(save)}></Button>
      </SettingsCard>
    </>
  );
};
