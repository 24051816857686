import { BreakType, LiveTimesheet, Timesheet } from "dashboard/miter";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";
import { X } from "phosphor-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, DateTimePicker, Label } from "ui";
import { Option } from "ui/form/Input";
import Select from "react-select";

import styles from "./BreakTimeForm.module.css";
import { selectStyles } from "ui/form/styles";
import { roundTo } from "miter-utils";
import TimesheetContext from "dashboard/pages/timesheets/ViewTimesheet/timesheetContext";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

type Props = {
  editing?: boolean;
  defaultBreaks?: LiveTimesheet["breaks"];
  onChange?: (breaks: Breaks) => void;
  mode: "create" | "update";
  clockIn?: DateTime;
  clockOut?: DateTime;
  clearNewBreaks?: boolean; // this is used to ensure empty breaks are only shown in edit mode
};

export type Breaks = Partial<NonNullable<Timesheet["breaks"] | LiveTimesheet["breaks"]>[number]>[];

type Break = Breaks[number];

const AdvancedBreakTimeForm: React.FC<Props> = ({
  clockIn,
  clockOut,
  editing,
  defaultBreaks,
  onChange,
  mode,
  clearNewBreaks,
}) => {
  const activeCompany = useActiveCompany();
  const { timesheet } = useContext(TimesheetContext);
  const [advancedBreaks, setAdvancedBreaks] = useState<Breaks>(defaultBreaks || []);

  useEffect(() => {
    if (!editing && clearNewBreaks) {
      setAdvancedBreaks(defaultBreaks || []);
    }
  }, [clearNewBreaks]);

  const timezone = timesheet?.timezone || clockIn?.zoneName;

  // Get break types
  const breakTypesObject = activeCompany?.settings.timesheets.break_types || {};
  const breakTypeOptions = useMemo(() => {
    return Object.entries(breakTypesObject)
      .filter(([_id, breakType]) => !breakType.archived)
      .map(([id, breakType]) => ({ label: breakType.label, value: id }));
  }, [activeCompany]);

  const addBreak = () => {
    const newBreak = {
      start: clockIn?.toSeconds(),
      end: clockIn?.toSeconds(),
      duration: 0,
      break_type_id: undefined,
      paid: undefined,
    };

    setAdvancedBreaks((prev) => [...prev, newBreak]);
  };

  const removeBreak = (index: number) => {
    const newBreaks = cloneDeep(advancedBreaks).filter((_: unknown, i: number) => i !== index);
    setAdvancedBreaks(newBreaks);
    onChange?.(newBreaks);
  };

  const handleChange = (breakItem: Break, index: number) => {
    const newBreaks = cloneDeep(advancedBreaks);
    newBreaks[index] = breakItem;
    setAdvancedBreaks(newBreaks);
    onChange?.(newBreaks);
  };

  const handleBreakTypeChange = (option: Option<string> | undefined | null, index: number) => {
    const curBreak = advancedBreaks[index];
    if (!curBreak) return;

    // if the break type has a minimum break time and the user has not set a break end time, set the break length to the minimum
    let newBreakEnd = curBreak.end;
    if (clockIn?.toSeconds() && curBreak.end === clockIn?.toSeconds()) {
      newBreakEnd = clockIn?.toSeconds() + (breakTypesObject[option?.value || ""]?.minimum_time || 0) * 60;
    }
    handleChange({ ...curBreak, end: newBreakEnd, break_type_id: option?.value }, index);
  };

  const handleBreakStartTimeChange = (date: DateTime, index: number) => {
    const curBreak = advancedBreaks[index];
    if (!curBreak) return;

    const parsedBreakEnd = curBreak.end
      ? DateTime.fromSeconds(curBreak.end).set({ millisecond: 0, second: 0 }).setZone(timezone)
      : undefined;

    const cleanedDateTime = date.set({ millisecond: 0, second: 0 }).setZone(timezone);

    // Round to nearest whole number
    let duration = roundTo((parsedBreakEnd?.toSeconds() || 0) - cleanedDateTime.toSeconds(), 0);
    if (duration < 0) duration = 0;

    handleChange(
      { ...curBreak, start: cleanedDateTime.toSeconds(), end: parsedBreakEnd?.toSeconds(), duration },
      index
    );
  };

  const handleBreakEndTimeChange = (date: DateTime, index: number) => {
    const curBreak = advancedBreaks[index];
    if (!curBreak) return;

    const parsedBreakStart = curBreak.start
      ? DateTime.fromSeconds(curBreak.start).set({ millisecond: 0, second: 0 }).setZone(timezone)
      : undefined;

    const cleanedDateTime = date.set({ millisecond: 0, second: 0 }).setZone(timezone);
    const duration = roundTo(cleanedDateTime.toSeconds() - (parsedBreakStart?.toSeconds() || 0), 0);

    if (duration < 0) return;

    handleChange(
      { ...curBreak, start: parsedBreakStart?.toSeconds(), end: cleanedDateTime.toSeconds(), duration },
      index
    );
  };

  // Duration input, start time input, break type select
  const renderBreak = (breakItem: Break, index: number) => {
    const parsedBreakStart = breakItem.start
      ? DateTime.fromSeconds(breakItem.start).set({ millisecond: 0, second: 0 }).setZone(timezone)
      : undefined;

    const parsedBreakEnd = breakItem.end
      ? DateTime.fromSeconds(breakItem.end).set({ millisecond: 0, second: 0 }).setZone(timezone)
      : undefined;

    const selectedBreakType = breakItem.break_type_id ? breakTypesObject[breakItem.break_type_id] : undefined;

    return (
      <div
        className={styles["break-row"] + " " + (!editing ? styles["break-row-readonly"] : "")}
        key={"break-" + index}
      >
        <div className="flex space-between" style={{ paddingTop: editing ? "10px" : "" }}>
          <div className="flex space-between" style={!editing ? { width: "100%" } : { width: "90%" }}>
            <div style={{ width: "35%" }}>
              {mode === "update" && <Label label={"Break start"} className="modal" />}
              {!editing && parsedBreakStart?.toFormat("h:mm a ZZZZ")}
              {editing && (
                <DateTimePicker
                  onChange={(date) => handleBreakStartTimeChange(date, index)}
                  timeOnly={false}
                  timePlaceholder={"Break start"}
                  timeIntervals={15}
                  value={breakItem.start ? DateTime.fromSeconds(breakItem.start) : undefined}
                  max={breakItem.end ? DateTime.fromSeconds(breakItem.end) : undefined}
                  controlled={true}
                  timezone={timesheet?.timezone}
                />
              )}
            </div>
            <div style={{ width: "35%" }}>
              {mode === "update" && <Label label={"Break end"} className="modal" />}
              {!editing && parsedBreakEnd?.toFormat("h:mm a ZZZZ")}
              {editing && (
                <DateTimePicker
                  onChange={(date) => handleBreakEndTimeChange(date, index)}
                  timeOnly={false}
                  timePlaceholder={"Break end"}
                  timeIntervals={15}
                  value={breakItem.end ? DateTime.fromSeconds(breakItem.end) : undefined}
                  min={breakItem.start ? DateTime.fromSeconds(breakItem.start) : undefined}
                  max={clockOut ? clockOut : DateTime.now()}
                  controlled={true}
                  timezone={timesheet?.timezone}
                />
              )}
            </div>

            <div style={{ width: "25%" }}>
              {mode === "update" && <Label label={"Break type"} className="modal" />}
              {!editing &&
                selectedBreakType &&
                selectedBreakType.label + ` (${selectedBreakType.paid ? "Paid" : "Unpaid"})`}
              {editing && (
                <Select
                  name="break_type_id"
                  options={breakTypeOptions}
                  width="100%"
                  height="32px"
                  onChange={(option) => handleBreakTypeChange(option as Option<string>, index)}
                  styles={selectStyles}
                  isClearable={true}
                  menuPlacement={"bottom"}
                  placeholder={"Type"}
                  defaultValue={breakTypeOptions.find((option) => option.value === breakItem.break_type_id)}
                />
              )}
            </div>
          </div>
          {!!editing && (
            <Button
              className={
                "button-1 no-margin icon-button " +
                styles["remove-break-btn"] +
                " " +
                (mode === "update" ? styles["update"] : "")
              }
              onClick={() => removeBreak(index)}
            >
              <X />
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderAdvancedBreakTime = () => {
    return (
      <>
        {advancedBreaks.map((breakItem, index) => renderBreak(breakItem, index))}
        {mode === "update" && !advancedBreaks.length && !editing && "No breaks added"}
        {editing && (
          <Button className="button-1 no-margin" onClick={addBreak}>
            Add break
          </Button>
        )}
      </>
    );
  };

  return <div className={styles["breaks"]}>{renderAdvancedBreakTime()}</div>;
};

export const validateAdvancedBreaks = (
  breaks: Timesheet["breaks"] | Breaks | undefined,
  clockIn: DateTime,
  clockOut: DateTime,
  breakTypesObject: Record<string, BreakType>
): void => {
  if (!breaks) return;
  const tsLength = clockOut.diff(clockIn, "seconds").seconds;

  breaks.forEach((breakItem) => {
    if (!breakItem.start) {
      throw new Error(`Please make sure you have a start time for each break.`);
    }

    if (breakItem.start < clockIn.toSeconds()) {
      throw new Error(`Breaks cannot start before the timesheet.`);
    }

    if (!breakItem.end) {
      throw new Error(`Please make sure you have an end time for each break.`);
    }

    if (breakItem.start >= breakItem.end) {
      throw new Error(`Break start time has to be before the end time.`);
    }

    if (breakItem.end && breakItem.end > clockOut.toSeconds()) {
      throw new Error(`Breaks cannot end after the timesheet.`);
    }

    if (!breakItem.duration) {
      throw new Error(`Breaks require a duration.`);
    }

    if (breakItem.duration && tsLength < breakItem.duration) {
      throw new Error(`Breaks cannot be longer than the timesheet.`);
    }

    if (!breakItem.break_type_id) {
      throw new Error(`Please make sure you have a type for each break.`);
    }

    const breakType = breakItem.break_type_id ? breakTypesObject[breakItem.break_type_id] : undefined;
    if (breakItem.duration && breakType?.minimum_time && breakType.minimum_time * 60 > breakItem.duration) {
      throw new Error(
        `Break of type ${breakType.label} cannot be shorter than ${breakType.minimum_time} minutes.`
      );
    }
  });

  const hasNoOverlaps = breaks.every((b, i) =>
    // either start of break 1 has to be after end of break 2, or end of break 2 has to be before start of break 1
    breaks.every((b2, i2) => {
      if (i === i2) return true;
      if (!b.start || !b2.start) throw new Error(`Break start time is required.`);
      return !b2.end || b.start >= b2.end || !b.end || b.end <= b2.start;
    })
  );
  if (!hasNoOverlaps) {
    throw new Error(`Breaks cannot overlap.`);
  }
};

export const validateSimpleBreakTime = (breakTime: number, clockIn: DateTime, clockOut: DateTime): void => {
  const tsLength = clockOut.diff(clockIn, "seconds").seconds;

  if (tsLength < breakTime) {
    throw new Error(`Break time annot be longer than the timesheet.`);
  }

  if (breakTime < 0) {
    throw new Error(`Break time cannot be negative.`);
  }
};

export default AdvancedBreakTimeForm;
