import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import React, { useCallback, useMemo, useState } from "react";
import {
  SelectionChangedEvent,
  GridApi,
  ColGroupDef,
  CellEditRequestEvent,
  RowDragEndEvent,
  GetRowIdParams,
  ColDef,
  ValueGetterParams,
  ValueFormatterParams,
  ValueFormatterFunc,
  ICellRendererParams,
  IRowNode,
} from "ag-grid-community";
import { Badge, BasicModal, Button, ConfirmModal } from "ui";
import { ClassificationTableEntry } from "./PayRateGroupModalUtils";
import { Notifier, roundTo, sleep } from "dashboard/utils";
import { AggregatedPayRateGroup, MiterAPI, UnionRateFringe } from "dashboard/miter";
import { FringeModal, isFringeDeduction } from "./PayRateGroupFringeModal";
import ObjectID from "bson-objectid";
import { cloneDeep } from "lodash";
import {
  useActiveCompany,
  useActiveCompanyId,
  useHasEnabledWcGroups,
  useLookupOtRule,
  useLookupWcCode,
  useLookupWcGroup,
  useOtRuleOptions,
  useRefetchActivities,
  useRefetchPrgs,
  useRefetchTeam,
  useWcCodeOptions,
  useWcGroupOptions,
} from "dashboard/hooks/atom-hooks";
import { selectEditorSuppressKeyboardEvent } from "ui/table-v2/AgGridSelectEditor";
import { ClassificationsImporter } from "../ClassificationsImporter";
import { ImportResultModal } from "dashboard/components/importer/ImportHistory";

type Props = {
  rateGroup: AggregatedPayRateGroup;
  tableData: ClassificationTableEntry[];
  setTableData: React.Dispatch<React.SetStateAction<ClassificationTableEntry[]>>;
  hasUnsavedData: boolean;
  setUnsavedData: React.Dispatch<React.SetStateAction<boolean>>;
  readonly?: boolean;
};

const MIN_RATE_COL_WIDTH = 120;

export const ClassificationTable: React.FC<Props> = ({
  rateGroup,
  tableData,
  setTableData,
  hasUnsavedData,
  setUnsavedData,
  readonly,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const hasEnabledWcGroups = useHasEnabledWcGroups();

  const refetchPrgs = useRefetchPrgs();
  const refetchTeam = useRefetchTeam();
  const refetchActivities = useRefetchActivities();
  const wcCodeOptions = useWcCodeOptions();
  const wcGroupOptions = useWcGroupOptions();
  const otRuleOptions = useOtRuleOptions();
  const lookupWcCode = useLookupWcCode();
  const lookupWcGroup = useLookupWcGroup();
  const lookupOtRule = useLookupOtRule();

  const [gridApi, setGridApi] = useState<GridApi<ClassificationTableEntry>>();
  const [trigger, setTrigger] = useState(false);
  const [importResults, setImportResults] = useState(null);
  const [confirmArchiveModal, setConfirmArchiveModal] = useState(false);
  const [anyRowsSelected, setAnyRowsSelected] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [addingFringe, setAddingFringe] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState<IRowNode<ClassificationTableEntry>[]>([]);
  const [hideAdvancedOptions, setHideAdvancedOptions] = useState(true);
  const [showEditingFringeRateModalWarning, setShowEditingFringeRateModalWarning] = useState(false);

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const newSelection = event.api.getSelectedNodes();
    setSelectedNodes(newSelection);
    setAnyRowsSelected(!!newSelection.length);
  };

  const archiveClassifications = async () => {
    if (!activeCompanyId || !gridApi || readonly) return;
    setArchiving(true);

    // Nodes to remove from frontend, not backend
    const idsToRemove = new Set<string>(
      selectedNodes
        .filter((node) => !node.data?.new)
        .filter((n) => n.data?._id)
        .map((node) => node.data!._id)
    );

    if (idsToRemove.size) {
      setTableData((prev) => prev.filter((rate) => !idsToRemove.has(rate._id)));
    }

    try {
      // Nodes to archive from backend
      const idsToArchive = selectedNodes
        .filter((node) => !node.data?.new)
        .map((node) => node.data?._id)
        .filter((id): id is string => {
          if (!id) return false;
          return rateGroup.union_rates.some((r) => r._id === id);
        });
      if (idsToArchive.length) {
        const response = await MiterAPI.union_rates.archive(idsToArchive, activeCompanyId);
        if (response.error) {
          throw new Error(response.error);
        } else if (response.failed.length) {
          for (const failure of response.failed) {
            Notifier.error(failure.reason);
          }
        }
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the classification(s). We're looking into it!");
    }

    // refresh TMs and activities in case prg_classification mappings have changed and activities were impacted
    refetchTeam();
    refetchActivities();
    setConfirmArchiveModal(false);
    setArchiving(false);

    // Only refetch the PRGs if there isn't unsaved data so we don't lose the unsaved data
    if (!hasUnsavedData) {
      await refetchPrgs(rateGroup._id);
    }
  };

  const duplicateClassifications = async () => {
    if (readonly) return;

    const dups: ClassificationTableEntry[] = [];
    for (const node of selectedNodes) {
      const rate = node.data;
      if (!rate) continue;

      const newFringes = rate.fringes.map((f) => {
        return { ...f, _id: ObjectID().toString() };
      });

      dups.push({ ...rate, _id: ObjectID().toString(), new: true, fringes: newFringes });
    }

    setTableData((prev) => prev.concat(dups));
    setUnsavedData(true);
  };

  const addClassification = async () => {
    if (!activeCompanyId || readonly) return;
    const newIndex = tableData.length;
    const newEntry: ClassificationTableEntry = {
      _id: ObjectID().toString(),
      classification: "",
      fringes: [],
      base_rate: 0,
      pay_rate_group: rateGroup._id,
      company: activeCompanyId,
      always_pay: true,
      new: true,
    };
    setTableData((prev) => prev.concat(newEntry));
    // React state setting is asynchronous, so let's pause for 1 ms before opening up the classification cell of the new row for editing
    await sleep(1);
    gridApi?.startEditingCell({ rowIndex: newIndex, colKey: "classification" });
    setUnsavedData(true);
  };

  const importFinished = async (results) => {
    setImportResults(results);
    refetchPrgs(rateGroup._id);
  };

  const actionButtons = () => {
    return (
      <div className="flex">
        {!readonly && (
          <Button style={{ height: 32 }} text="Add fringe" onClick={() => setAddingFringe(true)} />
        )}
        {!readonly && <Button style={{ height: 32 }} text="Add classification" onClick={addClassification} />}
        <Button
          style={{ height: 32 }}
          text={`${hideAdvancedOptions ? "Show" : "Hide"} advanced`}
          onClick={() => setHideAdvancedOptions(!hideAdvancedOptions)}
        />
        {!readonly && <ClassificationsImporter payRateGroup={rateGroup._id} onFinish={importFinished} />}
      </div>
    );
  };

  const [updatingDefault, setUpdatingDefault] = useState(false);
  const updateIsDefaultProperty = async (isDefault: boolean) => {
    const selectedId = selectedNodes[0]?.data?._id;
    if (!selectedId) return;
    setUpdatingDefault(true);
    try {
      const response = await MiterAPI.union_rates.update(selectedId, { is_default: isDefault });
      if (response.error) throw new Error(response.error);

      await refetchPrgs(rateGroup._id);
      setTableData((prev) => {
        return prev.map((rate) => {
          if (rate._id !== selectedId && isDefault) return { ...rate, is_default: false };
          return { ...rate, is_default: isDefault };
        });
      });
      gridApi?.refreshCells({ columns: ["classification"], force: true, suppressFlash: true });
      gridApi?.deselectAll();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the default classification. We're looking into it!");
    }

    setUpdatingDefault(false);
  };

  const selectedActionButtons = useCallback(() => {
    const showToggleIsDefaultButton = selectedNodes.length === 1;
    const rowSelectedIsDefault = showToggleIsDefaultButton && selectedNodes[0]?.data?.is_default;
    const buttonText = rowSelectedIsDefault ? "Remove default" : "Make default";

    return (
      <div className="flex">
        {showToggleIsDefaultButton && (
          <Button
            text={buttonText}
            loading={updatingDefault}
            onClick={() => updateIsDefaultProperty(!rowSelectedIsDefault)}
          />
        )}
        <Button text="Duplicate" onClick={duplicateClassifications} />

        <Button text="Delete" className="button-3" onClick={() => setConfirmArchiveModal(true)} />
      </div>
    );
  }, [updatingDefault, selectedNodes]);

  const onCellEditRequest = (event: CellEditRequestEvent<ClassificationTableEntry>) => {
    const rateIndex = tableData.findIndex((d) => d._id === event.data._id);
    if (rateIndex === -1) return;

    // Need to deep copy the rate since we'll be making changes to it and need to respect React's preference for immutable state
    const rate = cloneDeep(tableData[rateIndex]!);

    const field = event.colDef.field;
    const colId = event.colDef.colId;
    let newValue = event.newValue;

    // If this is a rate or fringe contribution column then turn the value into a number and round it
    const RATE_COLUMNS = ["base_rate", "fringe_rates.reg", "fringe_rates.ot", "fringe_rates.dot"];
    if (newValue && ((field && RATE_COLUMNS.includes(field)) || colId?.startsWith("fringe"))) {
      newValue = roundTo(Number(newValue), 3);
      if (isNaN(newValue)) {
        Notifier.error(`Value must be a number`);
        return;
      } else if (newValue < 0) {
        Notifier.error(`Value must be non-negative`);
        return;
      } else if (field === "fringe_rates.dot" && rate.fringe_rates?.ot && newValue < rate.fringe_rates.ot) {
        Notifier.error(`Value must be at least OT fringe rate`);
        return;
      } else if (
        field &&
        ["fringe_rates.ot", "fringe_rates.dot"].includes(field) &&
        rate.fringe_rates?.reg &&
        newValue < rate.fringe_rates.reg
      ) {
        Notifier.error(`Value must be at least REG fringe rate`);
        return;
      }
    }

    // Show OT fringe rates when editing REG fringe rate if previously overridden
    const hasOTFringeRateOverrides =
      rate.fringe_rates?.reg !== rate.fringe_rates?.ot || rate.fringe_rates?.reg !== rate.fringe_rates?.dot;
    if (hasOTFringeRateOverrides && field === "fringe_rates.reg" && hideAdvancedOptions) {
      setShowEditingFringeRateModalWarning(true);
    }

    // Helper variable to tell us if we actually need to update the table data state variable
    let changed = false;

    const nonFringeFields = [
      "overtime_rule_id",
      "wc_code",
      "wc_group_id",
      "classification",
      "base_rate",
      "fringe_rates.reg",
      "fringe_rates.ot",
      "fringe_rates.dot",
      "notes",
    ];

    if (field && nonFringeFields.includes(field)) {
      // These are the non-fringe-contribution columns
      if (!newValue) {
        // If user is trying to delete value, then that's only allowed with the fringe rate, notes, wc_code, wc_group_id, overtime_rule_id
        // Setting to null since that's cleaner than a value of 0 or a blank string
        if (["wc_code", "wc_group_id", "overtime_rule_id", "notes"].includes(field)) {
          rate[field] = null;
          changed = true;
        } else if (field === "fringe_rates.reg") {
          // Clear out all fringe rates if normal fringe rate is cleared
          rate.fringe_rates = {
            reg: null,
            ot: null,
            dot: null,
          };
          changed = true;
        } else if (field === "fringe_rates.ot") {
          rate.fringe_rates!.ot = null;
          changed = true;
        } else if (field === "fringe_rates.dot") {
          rate.fringe_rates!.dot = null;
          changed = true;
        } else if (field === "classification") {
          return;
        } else if (field === "base_rate") {
          rate[field] = 0;
          changed = true;
        }
      } else if (rate[field] !== newValue) {
        // If the field has changed, then update it in our data
        if (field === "fringe_rates.reg") {
          rate.fringe_rates = {
            reg: newValue,
            ot: (rate.fringe_rates?.reg === rate.fringe_rates?.ot ? 1 : 1.5) * newValue,
            dot: (rate.fringe_rates?.reg === rate.fringe_rates?.dot ? 1 : 2) * newValue,
          };
          changed = true;
        } else if (field === "fringe_rates.ot") {
          rate.fringe_rates = {
            reg: rate.fringe_rates?.reg ?? newValue,
            ot: newValue,
            dot: rate.fringe_rates?.dot ?? newValue,
          };
          changed = true;
        } else if (field === "fringe_rates.dot") {
          rate.fringe_rates = {
            reg: rate.fringe_rates?.reg ?? newValue,
            ot: rate.fringe_rates?.ot ?? newValue,
            dot: newValue,
          };
          changed = true;
        } else {
          rate[field] = newValue;
          changed = true;
        }
      }
    } else if (colId?.startsWith("fringe")) {
      // These are the fringe contribution columns
      const fringeGroupId = colId.slice(6);
      const fr = rate.fringes.find((f) => f.fringe_group_id === fringeGroupId);
      if (fr) {
        // If we're updating an existing fringe for this specific rate...
        if (fr.amount !== newValue) {
          // If the fringe amount has changed, then update our data
          fr.amount = newValue;
          changed = true;
        }
      } else {
        // If we're populating a fringe on this rate for the first time...
        if (!newValue) return;

        // Let's find an existing fringe of the same "archetype" that we can copy and just replace the _id and amount
        const allFringes = tableData.flatMap((r) => r.fringes);
        const fringeToCopy = allFringes.find((f) => f.fringe_group_id === fringeGroupId);
        if (!fringeToCopy) return;

        const newFr: UnionRateFringe = {
          ...fringeToCopy,
          _id: ObjectID().toString(),
          amount: newValue,
        };
        rate.fringes.push(newFr);
        changed = true;
      }
    }

    if (changed) {
      rate.changed = true;
      const newData = tableData.slice();
      newData.splice(rateIndex, 1, rate);
      setTableData(newData);
      setUnsavedData(true);
    }
  };

  /** Resorts the underlying table data state variable based on how the user has dragged the rows */
  const onRowDragEnd = async (event: RowDragEndEvent<ClassificationTableEntry>) => {
    const rateId = event.node.data?._id;
    const newRowIndex = event.node.rowIndex;
    if (!rateId || newRowIndex == null) return;

    const rateIndex = tableData.findIndex((d) => d._id === rateId);
    if (rateIndex === -1) return;

    const rate = cloneDeep(tableData[rateIndex]!);

    const newData = tableData.slice();
    newData.splice(rateIndex, 1);
    newData.splice(newRowIndex, 0, rate);
    // Need to update the `changed` flag so we know to actually send an update request to the backend for the rates whose rowIndex changed
    for (let i = 0; i < newData.length; i++) {
      const newRate = newData[i]!;
      const oldRate = tableData[i]!;
      if (newRate._id !== oldRate._id) newRate.changed = true;
    }
    setTableData(newData);
    setUnsavedData(true);
  };

  const columnDefs = useMemo(() => {
    if (trigger) return [];

    const onCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      key:
        | "always_pay"
        | "include_fringe_income_for_deductions"
        | "ignore_in_cprs"
        | "ignore_rate_differentials",
      rateId?: string
    ) => {
      const checked = event.target.checked;

      setTableData((prev) => {
        return prev.map((rate) => {
          if (rate._id !== rateId) return { ...rate };
          const newRate = { ...rate };
          newRate[key] = checked;
          newRate.changed = true;
          return newRate;
        });
      });
      setUnsavedData(true);
    };

    const onDynamicOffsetChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      colId: string,
      rateId?: string
    ) => {
      if (!colId?.startsWith("fringe")) return;

      const fringeGroupId = colId.slice(6);
      const checked = event.target.checked;

      setTableData((prev) => {
        const newData = cloneDeep(prev);
        newData.forEach((rate) => {
          if (rate._id !== rateId) return;
          const fr = rate.fringes.find((f) => f.fringe_group_id === fringeGroupId);
          if (fr) {
            // If we're updating an existing fringe for this specific rate...
            fr.dynamic_offset = checked;
            rate.changed = true;
          } else {
            // If we're populating a fringe on this rate for the first time...
            // Let's find an existing fringe of the same "archetype" that we can copy and just replace the _id and amount
            const allFringes = tableData.flatMap((r) => r.fringes);
            const fringeToCopy = allFringes.find((f) => f.fringe_group_id === fringeGroupId);
            if (!fringeToCopy) return;

            rate.fringes.push({
              ...fringeToCopy,
              _id: ObjectID().toString(),
              dynamic_offset: checked,
            });
            rate.changed = true;
          }
        });
        return newData;
      });
      setUnsavedData(true);
    };

    const fringeColDefs: ColDef<ClassificationTableEntry>[] = [];
    const fringeDeductionColDefs: ColDef<ClassificationTableEntry>[] = [];
    for (const rate of tableData) {
      for (const fringe of rate.fringes) {
        const cid = "fringe" + fringe.fringe_group_id;
        let valueFormatter: ValueFormatterFunc | undefined = rateDollarFormat;
        if (fringe.calculation_method === "percent") valueFormatter = ratePercentFormat;
        if (fringe.dynamic_offset_eligible) valueFormatter = undefined;
        const colDef: ColDef = {
          colId: cid,
          headerName: fringe.label,
          valueGetter: fringe.dynamic_offset_eligible
            ? undefined
            : (params: ValueGetterParams<ClassificationTableEntry>) => {
                return params.data?.fringes.find((f) => f.fringe_group_id === fringe.fringe_group_id)?.amount;
              },
          cellRenderer: fringe.dynamic_offset_eligible
            ? (params: ICellRendererParams<ClassificationTableEntry>) => {
                const fr = params.data?.fringes.find((f) => f.fringe_group_id === fringe.fringe_group_id);
                return (
                  <input
                    type="checkbox"
                    checked={fr?.dynamic_offset}
                    onChange={(e) => onDynamicOffsetChange(e, cid, params.data?._id)}
                  />
                );
              }
            : undefined,
          headerTooltip: fringe.dynamic_offset_eligible
            ? "Dynamic offset"
            : fringe.calculation_method === "per_hour"
            ? "$/hour"
            : "%",
          valueFormatter,
          cellStyle: fringe.dynamic_offset ? { color: "darkgray" } : undefined,
          editable: fringe.dynamic_offset_eligible ? false : true,
          minWidth: 200,
        };
        if (isFringeDeduction(fringe.type)) {
          if (fringeDeductionColDefs.some((c) => c.colId === cid)) continue;
          fringeDeductionColDefs.push(colDef);
        } else {
          if (fringeColDefs.some((c) => c.colId === cid)) continue;
          fringeColDefs.push(colDef);
        }
      }
    }
    const colGroups: ColGroupDef<ClassificationTableEntry>[] = [
      {
        headerName: "",
        children: [
          {
            field: "classification",
            checkboxSelection: true,
            headerName: "Classification",
            filter: true,
            minWidth: 300,
            pinned: "left",
            width: 300,
            rowDrag: true,
            cellRenderer: (params: ICellRendererParams<ClassificationTableEntry>) => {
              if (params.data?.is_default) {
                return (
                  <div className="flex">
                    <div>{params.data?.classification}</div>
                    <Badge text="default" color="green" />
                  </div>
                );
              } else return params.data?.classification;
            },
          },
        ],
      },
      {
        headerName: "Pay rates ($/hour)",
        children: [
          {
            field: "base_rate",
            headerName: "Base",
            valueFormatter: rateDollarFormat,
            minWidth: MIN_RATE_COL_WIDTH,
            suppressMenu: true,
          },
          {
            field: "fringe_rates.reg",
            valueGetter: (params) => params.data?.fringe_rates?.reg,
            headerName: "Fringe rate",
            headerTooltip: "The required fringe rate for the classification, usually for prevailing wages.",
            suppressMenu: true,
            valueFormatter: rateDollarFormat,
            minWidth: MIN_RATE_COL_WIDTH,
          },
          {
            field: "fringe_rates.ot",
            valueGetter: (params) => params.data?.fringe_rates?.ot,
            headerName: "OT fringe rate",
            headerTooltip:
              "The required fringe rate in overtime for the classification, usually for prevailing wages.",
            suppressMenu: true,
            valueFormatter: rateDollarFormat,
            minWidth: MIN_RATE_COL_WIDTH,
            hide: hideAdvancedOptions,
          },
          {
            field: "fringe_rates.dot",
            valueGetter: (params) => params.data?.fringe_rates?.dot,
            headerName: "DOT fringe rate",
            headerTooltip:
              "The required fringe rate in double overtime for the classification, usually for prevailing wages.",
            suppressMenu: true,
            valueFormatter: rateDollarFormat,
            minWidth: MIN_RATE_COL_WIDTH,
            hide: hideAdvancedOptions,
          },
        ],
      },
      { headerName: "Contributions", children: fringeColDefs },
      { headerName: "Deductions", children: fringeDeductionColDefs },
      {
        headerName: "Advanced options",
        children: [
          {
            field: "wc_code",
            headerName: "Workers comp",
            headerTooltip:
              "This workers comp code will apply to earnings attached to this classification, unless overriden by an activity-level code",
            minWidth: 200,
            valueFormatter: (params) => lookupWcCode(params.value)?.label || "",
            suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
            cellEditor: "reactSelectEditor",
            cellEditorPopup: true,
            cellEditorParams: {
              options: wcCodeOptions,
              isClearable: true,
            },
            hide: hideAdvancedOptions,
          },
          ...(hasEnabledWcGroups
            ? ([
                {
                  field: "wc_group_id",
                  headerName: "Workers comp group",
                  headerTooltip:
                    "This workers comp group will apply to earnings attached to this classification, unless overriden by an activity-level code or a workers comp code",
                  minWidth: 200,
                  valueFormatter: (params) => lookupWcGroup(params.value)?.name || "",
                  suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
                  cellEditor: "reactSelectEditor",
                  cellEditorPopup: true,
                  cellEditorParams: {
                    options: wcGroupOptions,
                    isClearable: true,
                  },
                  hide: hideAdvancedOptions,
                },
              ] as ColDef<ClassificationTableEntry>[])
            : []),
          {
            field: "overtime_rule_id",
            headerName: "Overtime rule",
            headerTooltip: "How Miter should calculate overtime for this classification",
            minWidth: 200,
            valueFormatter: (params) => lookupOtRule(params.value)?.label || "",
            suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
            cellEditor: "reactSelectEditor",
            cellEditorPopup: true,
            cellEditorParams: {
              options: otRuleOptions,
              isClearable: true,
            },
            hide: hideAdvancedOptions,
          },
          {
            field: "always_pay",
            headerName: "Always use base rate",
            suppressMenu: true,
            headerTooltip:
              "If unchecked, the higher of the employee's default rate and the classification rate will be used as the employee's rate.",
            cellRenderer: (params: ICellRendererParams<ClassificationTableEntry, boolean | undefined>) => {
              return (
                <input
                  type="checkbox"
                  checked={params.value || undefined}
                  onChange={(e) => onCheckboxChange(e, "always_pay", params.data?._id)}
                />
              );
            },
            minWidth: 200,
            editable: false,
            hide: hideAdvancedOptions,
          },
          {
            field: "include_fringe_income_for_deductions",
            headerName: "Include fringe income for deductions",
            suppressMenu: true,
            headerTooltip:
              "If checked, Miter will calculate %-based fringe deductions by including taxable income from fringes.",
            cellRenderer: (params: ICellRendererParams<ClassificationTableEntry, boolean | undefined>) => {
              return (
                <input
                  type="checkbox"
                  checked={params.value || undefined}
                  onChange={(e) =>
                    onCheckboxChange(e, "include_fringe_income_for_deductions", params.data?._id)
                  }
                />
              );
            },
            minWidth: 200,
            editable: false,
            hide: hideAdvancedOptions,
          },
          {
            field: "ignore_in_cprs",
            headerName: "Ignore in Certified Payroll",
            suppressMenu: true,
            headerTooltip: "If checked, this classification will not appear in Certified Payroll Reports.",
            cellRenderer: (params: ICellRendererParams<ClassificationTableEntry, boolean | undefined>) => {
              return (
                <input
                  type="checkbox"
                  checked={params.value || undefined}
                  onChange={(e) => onCheckboxChange(e, "ignore_in_cprs", params.data?._id)}
                />
              );
            },
            minWidth: 200,
            editable: false,
            hide: hideAdvancedOptions,
          },
          {
            field: "ignore_rate_differentials",
            headerName: "Ignore rate differentials",
            suppressMenu: true,
            headerTooltip:
              "If checked, rate differentials will not apply automatically to this classification's pay rate.",
            cellRenderer: (params: ICellRendererParams<ClassificationTableEntry, boolean | undefined>) => {
              return (
                <input
                  type="checkbox"
                  checked={params.value || undefined}
                  onChange={(e) => onCheckboxChange(e, "ignore_rate_differentials", params.data?._id)}
                />
              );
            },
            minWidth: 200,
            editable: false,
            hide: hideAdvancedOptions,
          },
        ],
      },
      {
        headerName: "",
        children: [
          {
            field: "notes",
            headerName: "Notes",
            cellEditorPopup: true,
            minWidth: 400,
            cellEditor: "agLargeTextCellEditor",
            cellEditorParams: {
              maxLength: 200,
              rows: 10,
              cols: 50,
            },
            lockPosition: "right",
          },
        ],
      },
    ];
    return colGroups;
  }, [tableData, activeCompany, wcCodeOptions, lookupWcCode, trigger, hideAdvancedOptions]);

  const prgHasDynamicOffset = useMemo(() => {
    for (const rate of tableData) {
      for (const fringe of rate.fringes) {
        if (fringe.dynamic_offset) return true;
      }
    }
    return false;
  }, [tableData]);

  // For some reason when adding a new fringe from the classifications table, the columns get really glitchy. So here's a hack that temporarily erases all columns and then adds then back in order to get the new column to show up in the right place and not be glitchy.
  const handleNewFringe = async () => {
    setTrigger(true);
    await sleep(0);
    setTrigger(false);
    setAddingFringe(false);
  };

  return (
    <div className="height-100">
      {importResults && <ImportResultModal result={importResults} onClose={() => setImportResults(null)} />}
      {confirmArchiveModal && (
        <ConfirmModal
          onYes={archiveClassifications}
          onNo={() => setConfirmArchiveModal(false)}
          loading={archiving}
          body="Are you sure you want to delete the selected classification(s)?"
          yellowBodyText
        />
      )}
      {addingFringe && (
        <FringeModal
          setTableData={setTableData}
          prgHasDynamicOffset={prgHasDynamicOffset}
          setUnsavedData={setUnsavedData}
          onHide={handleNewFringe}
        />
      )}
      {showEditingFringeRateModalWarning && (
        <BasicModal
          button2Text="Continue"
          button2Action={() => {
            setHideAdvancedOptions(false);
            setShowEditingFringeRateModalWarning(false);
          }}
          headerText={"Warning: editing fringe rates"}
        >
          Please confirm the updated OT and DOT fringe rates as well.
        </BasicModal>
      )}
      <div className="vertical-spacer"></div>
      <AgGridTable
        columnDefs={columnDefs}
        data={tableData}
        defaultActionButtons={actionButtons}
        defaultColDef={{ sortable: false, editable: true }}
        setGridApi={setGridApi}
        selectedActionButtons={selectedActionButtons}
        showSelectedActionButtons={anyRowsSelected}
        gridHeight={500}
        gridOptions={{
          readOnlyEdit: true,
          rowSelection: "multiple",
          onCellEditRequest,
          onSelectionChanged,
          suppressRowClickSelection: true,
          singleClickEdit: true,
          rowDragManaged: true,
          onRowDragEnd,
          stopEditingWhenCellsLoseFocus: true,
          suppressFieldDotNotation: true,
          sideBar: false,
          getRowId: (params: GetRowIdParams<ClassificationTableEntry>) => params.data._id,
        }}
      />
    </div>
  );
};

const rateDollarFormat = (
  params: ValueFormatterParams<ClassificationTableEntry, number | undefined>
): string => {
  if (params.value == null) return "";
  const formattedNum = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  }).format(params.value);
  return formattedNum;
};

const ratePercentFormat = (
  params: ValueFormatterParams<ClassificationTableEntry, number | undefined>
): string => {
  if (params.value == null) return "";
  const formattedNum = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format(params.value / 100);
  return formattedNum;
};
