import AppContext from "dashboard/contexts/app-context";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Loader } from "ui";
import { Option } from "ui/form/Input";
import checkmark from "dashboard/assets/check-mark.png";
import { useActiveCompany, useUser } from "dashboard/hooks/atom-hooks";

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  defaultCompanyOptions?: Option<number>[];
  integration: MiterIntegrationForCompany;
};

export const SelectProcoreCompanyModal: React.FC<Props> = ({
  onCancel,
  onSuccess,
  defaultCompanyOptions,
  integration,
}) => {
  const activeCompany = useActiveCompany();
  const activeUser = useUser();

  const { fetchUserData } = useContext(AppContext);
  const { handleSubmit } = useForm();

  const [companyOptions, setCompanyOptions] = useState<Option<number>[] | undefined>(defaultCompanyOptions);
  const [connecting, setConnecting] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>();

  const integrationConnectionId = integration.connection?._id;

  const getCompanyOptions = async () => {
    if (!integrationConnectionId) {
      Notifier.error("There was an error retrieving Procore data.");
      return;
    }
    try {
      const result = await MiterAPI.integrations.procore.list_companies(integrationConnectionId);
      if (result.error) {
        throw new Error(result.error);
      }
      const optionList = result.map((pc) => {
        return { value: pc.id, label: pc.name };
      });
      const initialSelectedCompany = optionList[0]?.value;
      setSelectedCompany(initialSelectedCompany);
      setCompanyOptions(optionList);
    } catch (e) {
      console.error(e);
      console.log(e);
      Notifier.error("There was an error retrieving Procore data.");
    }
  };

  const connectCompanyToProcore = async () => {
    if (!integrationConnectionId) {
      Notifier.error("There was an error retrieving Procore data.");
      return;
    }
    setConnecting(true);
    try {
      if (!selectedCompany) {
        throw new Error("Company not found.");
      }
      if (!activeCompany) {
        throw new Error("No active company found.");
      }
      if (!activeUser) {
        throw new Error("No active team member found.");
      }

      const selectedCompanyName = companyOptions?.find((o) => o.value === selectedCompany)?.label;
      if (!selectedCompanyName) {
        throw new Error("Company not found.");
      }

      const permissions = await MiterAPI.integrations.procore.validate_permissions(
        selectedCompany,
        integrationConnectionId
      );
      if (permissions.error) throw new Error(permissions.error);

      if (permissions.type !== "failed") {
        const result = await MiterAPI.integrations.procore.connect_companies({
          procore_company_id: selectedCompany,
          procore_company_name: selectedCompanyName,
          miter_company_id: activeCompany._id,
          connecting_miter_user_id: activeUser._id,
          integration_type: permissions.type,
          integration_connection_id: integrationConnectionId,
        });
        if (result.error) {
          throw new Error(result.error);
        }
        Notifier.success("Procore company successfully connected to Miter.");
        await fetchUserData();
        onSuccess();
      } else {
        let msg =
          "In order to integrate with this Procore company, your Procore permissions must have at least ";
        for (const mdl of permissions.modules) {
          msg += `${mdl.required_level} for ${mdl.name}, `;
        }
        Notifier.error(msg.slice(0, -2), { duration: 10000 });
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error connecting to Procore. Our engineers have been notified.");
    }
    setConnecting(false);
  };

  useEffect(() => {
    if (!defaultCompanyOptions) {
      getCompanyOptions();
    }
  }, []);

  useEffect(() => {
    if (!selectedCompany && companyOptions) {
      setSelectedCompany(companyOptions[0]?.value);
    }
  }, [companyOptions]);

  return (
    <ActionModal
      headerText="Connect a Procore company"
      onSubmit={handleSubmit(connectCompanyToProcore)}
      submitText="Connect"
      cancelText="Cancel"
      onHide={onCancel}
      onCancel={onCancel}
      showSubmit={true}
      loading={connecting}
    >
      {!companyOptions ? (
        <Loader />
      ) : (
        <>
          <div className="vertical-spacer"></div>
          <div className="yellow-text-container">{`Showing all companies associated with your Procore account.`}</div>
          <div className="vertical-spacer"></div>
          <div style={{ maxHeight: 300, overflowY: "auto" }}>
            {companyOptions.map((c) => {
              const selected = selectedCompany === c.value;
              return (
                <div
                  onClick={() => setSelectedCompany(c.value)}
                  className={`${selected ? "selected" : ""} calculation_option_wrapper flex`}
                >
                  <div>
                    <div style={{ fontSize: 17, fontWeight: selected ? "bold" : "" }}>{c.label}</div>
                    <div style={{ fontSize: 14, color: "darkslategray" }}>Company ID: {c.value}</div>
                  </div>

                  <div className="flex-1"></div>
                  {selected && <img src={checkmark} style={{ height: 15 }} />}
                </div>
              );
            })}
          </div>

          <div className="vertical-spacer"></div>
        </>
      )}
    </ActionModal>
  );
};
