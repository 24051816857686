import React, { useState, useEffect } from "react";
import PaymentContext from "./paymentContext";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { checkBenPtdTaxAmountCellRenderer, checkBenPtdTaxAmountTooltip } from "../viewPayrollUtils";

type TableEntry = {
  _id: string;
  amount: string;
  description: string;
};

const Deductions: React.FC = () => {
  const { payment, tm, editing, payroll } = React.useContext(PaymentContext);
  const noPtd = payroll?.check_payroll.off_cycle_options
    ? !payroll.check_payroll.off_cycle_options.apply_post_tax_deductions
    : false;

  const checkDeductions = payment.check_item?.post_tax_deductions;
  const [deductions, setDeductions] = useState<TableEntry[] | undefined>();

  const setTableData = () => {
    if (editing || !Array.isArray(checkDeductions)) {
      return;
    }
    const ptds: TableEntry[] = [];

    for (const deduction of checkDeductions) {
      ptds.push({
        _id: deduction.post_tax_deduction,
        amount: deduction.amount,
        description: deduction.description,
      });
    }

    setDeductions(ptds);
  };

  const columns: ColumnConfig<TableEntry>[] = [
    {
      field: "description",
      headerName: "Description",
      dataType: "string",
    },
    {
      field: "amount",
      headerName: "Amount",
      cellRenderer: (params) => {
        if (!params.data?._id || !params.data?.amount || !payment) return <span>{params.data?.amount}</span>;
        return checkBenPtdTaxAmountCellRenderer(params.data?._id, params.data?.amount, payment);
      },

      tooltipValueGetter: (params) => {
        if (!params.data?._id || !params.data?.amount || !payment) return;
        return checkBenPtdTaxAmountTooltip(params.data?._id, params.data?.amount, payment);
      },
    },
  ];

  const visitProfile = () => {
    window.open(window.location.origin + "/team-members/" + tm._id + "/post-tax-deductions", "_blank");
  };

  useEffect(() => {
    setTableData();
  }, [checkDeductions]);

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Deductions</div>
        <div className="flex-1"></div>
      </div>

      {noPtd ? (
        <div>
          <span>Post-tax deductions have been disabled for this payroll.</span>
        </div>
      ) : (
        <div>
          <span>
            Employee deductions are deducted automatically from net pay, after taxes have been applied.
          </span>
          {editing && (
            <div>
              <div className="vertical-spacer"></div>
              <span>
                {"To view " + tm.first_name + "'s deductions settings or to set up a 1-time deduction, visit"}
              </span>
              &nbsp;
              <span className="blue-link" onClick={visitProfile}>
                their profile
              </span>
              <span>.</span>
            </div>
          )}
          {!editing && deductions && (
            <TableV2
              id="deductions-table"
              resource="post tax deductions"
              data={deductions}
              columns={columns}
              hideSecondaryActions
              hideSelectColumn
              paginationPageSize={5}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Deductions;
