import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import React, { FC, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "./DateTimePicker.css";

// Can clear out the date for dateOnly pickers
type ClearableProps = {
  isClearable: true;
  onChange: (datetime: DateTime | null) => void;
};

type NonClearableProps = {
  isClearable?: false;
  onChange: (datetime: DateTime) => void;
};

type Props = {
  value?: DateTime | string;
  min?: DateTime;
  max?: DateTime;
  timeIntervals?: number;
  dateFormat?: string;
  className?: string;
  dateOnly?: boolean;
  timeOnly?: boolean;
  timeOnlyDate?: DateTime;
  sideEffect?: () => void;
  inputClassName?: string;
  disabled?: boolean;
  dateRef?: React.Ref<DatePicker>;
  timeRef?: React.Ref<DatePicker>;
  placeholder?: string;
  timePlaceholder?: string;
  containerStyle?: React.CSSProperties;
  controlled?: boolean;
  timezone?: string;
} & (ClearableProps | NonClearableProps);

const DateTimePicker: FC<Props> = ({
  min,
  max,
  value,
  timeIntervals,
  dateFormat,
  className,
  onChange,
  dateOnly,
  timeOnly,
  timeOnlyDate,
  sideEffect,
  inputClassName,
  disabled,
  dateRef,
  timeRef,
  placeholder,
  timePlaceholder,
  containerStyle,
  controlled,
  timezone,
  isClearable,
}) => {
  const castedDateTimeValue = value ? (typeof value === "string" ? DateTime.fromISO(value) : value) : null;
  const [datetime, setDatetime] = useState<DateTime | null | undefined>(castedDateTimeValue);
  const [currentZone, setCurrentZone] = useState<string | undefined>(timezone);

  const minDate = min ? min.toJSDate() : DateTime.now().setZone(currentZone).minus({ years: 100 }).toJSDate();
  const maxDate = max ? max.toJSDate() : DateTime.now().setZone(currentZone).plus({ years: 100 }).toJSDate();

  /* Keep local time changes the timezone to the local timezone AND changes the epoch seconds
   * directly (needed for situations where we want the date picker to show a specific timezone)
     - If this is a time only date, don't keep the local time otherwise the datetime will get incremented
  */

  const now = (datetime || DateTime.now()).setZone(currentZone, {
    keepLocalTime: timeOnlyDate ? false : true,
  });

  useEffect(() => {
    setCurrentZone(timezone);
  }, [timezone]);

  useEffect(() => {
    // Convert to the proper timezone if the value is passed in
    setDatetime(castedDateTimeValue?.setZone(currentZone));
  }, [castedDateTimeValue?.toSeconds(), currentZone]);

  useEffect(() => {
    if (timeOnlyDate) {
      const updatedDateTime = now.set({
        year: timeOnlyDate.year,
        month: timeOnlyDate.month,
        day: timeOnlyDate.day,
      });

      if (!controlled) {
        setDatetime(updatedDateTime);
      }

      onChange(updatedDateTime);
    }
  }, [timeOnlyDate?.toSeconds(), currentZone]);

  const handleDatetimeChange = (date: Date | null, trigger: "date" | "time") => {
    let updatedDateTime: DateTime | null = null;

    // Update only the date in the datetime
    if (trigger === "date" && date) {
      updatedDateTime = now.set({
        year: date?.getFullYear(),
        month: date ? date.getMonth() + 1 : undefined,
        day: date?.getDate(),
      });
    }

    // Update only the time in the datetime
    if (trigger === ("time" as const) && date) {
      updatedDateTime = now.set({
        year: timeOnlyDate?.year,
        month: timeOnlyDate?.month,
        day: timeOnlyDate?.day,
        hour: date?.getHours(),
        minute: date?.getMinutes(),
        second: date?.getSeconds(),
        millisecond: 0,
      });
    }

    if (!controlled) {
      setDatetime(updatedDateTime!);
    }
    if (isClearable) {
      onChange(updatedDateTime);
    } else if (updatedDateTime) {
      onChange(updatedDateTime);
    }

    if (sideEffect) sideEffect();
  };

  return (
    <div className={"datetime-picker-container-v2 " + (className ? className : "")} style={containerStyle}>
      {!timeOnly && (
        <div className={"datetime-picker-date-container " + (dateOnly ? "date-only" : "")}>
          <DatePicker
            ref={dateRef}
            selected={datetime?.setZone("local", { keepLocalTime: true }).toJSDate()}
            onChange={(date) => handleDatetimeChange(date, "date")}
            dateFormat={dateFormat || "EEE, MMM d, yyyy"}
            minDate={minDate}
            maxDate={maxDate}
            showYearDropdown
            className={"form2-text " + inputClassName}
            wrapperClassName={"datetime-picker-date-wrapper"}
            placeholderText={placeholder || "Select a date"}
            disabled={disabled}
            isClearable={!!isClearable}
          />
        </div>
      )}
      {!dateOnly && (
        <div className={"datetime-picker-time-container" + (timeOnly ? " time-only" : "")}>
          <DatePicker
            ref={timeRef}
            selected={datetime?.setZone("local", { keepLocalTime: true }).toJSDate()}
            onChange={(date) => handleDatetimeChange(date, "time")}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals || 15}
            timeCaption="Time"
            dateFormat={"h:mm aa"}
            className={"form2-text " + inputClassName}
            wrapperClassName={"datetime-picker-time-wrapper"}
            placeholderText={timePlaceholder || "Select a time"}
            disabled={disabled}
          />
        </div>
      )}
      {!dateOnly && currentZone && (
        <div
          className="form2-text datetime-picker-timezone"
          style={{ marginLeft: 0, width: 50, border: "none" }}
        >
          {DateTime.now().setZone(currentZone).offsetNameShort}
        </div>
      )}
    </div>
  );
};

export default DateTimePicker;
