import React, { FC } from "react";
import { ExpenseReimbursementsTable } from "./ExpenseReimbursementsTable";
import { Helmet } from "react-helmet";

type Props = {
  expenseReimbursementId?: string;
  shouldRedirectURLWhenOpening: boolean;
};

const ExpenseReimbursements: FC<Props> = ({ expenseReimbursementId, shouldRedirectURLWhenOpening }) => {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Reimbursements | Miter</title>
      </Helmet>
      <ExpenseReimbursementsTable
        expenseReimbursementId={expenseReimbursementId}
        showToggler={true}
        shouldRedirectURLWhenOpening={shouldRedirectURLWhenOpening}
      />
    </div>
  );
};

export default ExpenseReimbursements;
