import CustomFieldsTable from "dashboard/components/custom-fields/CustomFieldsTable";
import AppContext from "dashboard/contexts/app-context";
import { useActiveCompany, useLedgerMappingOptions, usePrgOptions } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Formblock, SettingsCard } from "ui";
import { Option } from "ui/form/Input";
import { useDebouncedCallback } from "use-debounce";

export const JobSettings: React.FC = () => {
  const navigate = useNavigate();
  const ref = useRef<{ isMounted: boolean }>({ isMounted: false });
  const { fetchUserData } = useContext(AppContext);

  const activeCompany = useActiveCompany();
  const ledgerMappingOptions = useLedgerMappingOptions();
  const prgOptions = usePrgOptions();
  const activeCompanyId = activeCompany?._id;
  const { settings } = activeCompany!;

  const [jobSettings, setJobSettings] = useState(settings.jobs);

  const filteredLedgerMappingOptions = useMemo(
    () => ledgerMappingOptions.filter((o) => o.label !== "Company default mapping"),
    [ledgerMappingOptions]
  );

  useEffect(() => {
    if (!ref.current?.isMounted) {
      ref.current.isMounted = true;
      return;
    }

    updateSettingsDebounced(jobSettings);
  }, [jobSettings]);

  // Set default depth to 1 if sub jobs are enabled and depth is not set
  useEffect(() => {
    if (jobSettings?.enabled_sub_jobs && jobSettings?.sub_job_config?.depth == undefined) {
      handleChange("sub_job_config.depth", 1);
    } else if (!jobSettings?.enabled_sub_jobs && jobSettings?.sub_job_config?.depth != undefined) {
      handleChange("sub_job_config.depth", null);
    }
  }, [jobSettings?.enabled_sub_jobs]);

  const updateSettings = async (newSettings) => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { "settings.jobs": newSettings },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Job settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating job settings. Our engineers are looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(updateSettings, 500);

  const handleChange = async (fieldName: string, newValue) => {
    const newSettings = { ...jobSettings };
    newSettings[fieldName] = newValue;

    setJobSettings(newSettings);
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Job settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/jobs")}>
          Back to jobs
        </div>
        <div className="flex">
          <h1>Job settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure job related settings and custom fields</div>
        <div className="vertical-spacer-small"></div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }}>List settings</div>
          <div className="flex">
            <input
              type="checkbox"
              checked={jobSettings?.show_code_in_dashboard}
              onChange={(e) => handleChange("show_code_in_dashboard", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>
              Prefix the job name with its code in the dashboard when selecting a job for a timesheet,
              expense, etc.
            </span>
          </div>
          <div className="vertical-spacer-small" />
          <div className="flex">
            <input
              type="checkbox"
              checked={jobSettings?.show_address_in_mobile_list}
              onChange={(e) => handleChange("show_address_in_mobile_list", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>
              Show the job address in the mobile app&apos;s job selection list
            </span>
          </div>
          <div className="vertical-spacer-small" />
          <div className="flex">
            <input
              type="checkbox"
              checked={jobSettings?.show_full_job_name_in_mobile_list}
              onChange={(e) => handleChange("show_full_job_name_in_mobile_list", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>
              Show the full job name in the mobile app&apos;s job selection list
            </span>
          </div>
          <div className="vertical-spacer-small" />
          <div className="flex">
            <input
              type="checkbox"
              checked={jobSettings?.sort_mobile_job_list_by_job_code}
              onChange={(e) => handleChange("sort_mobile_job_list_by_job_code", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>
              Sort the mobile app&apos;s job selection list by job code instead of job name
            </span>
          </div>
        </div>
        <SettingsCard title="Locations" info={"Configure job's location settings"}>
          <div style={{ marginTop: 10 }}>
            <div className="flex">
              <input
                type="checkbox"
                checked={jobSettings?.inherit_location_supervisors_and_superintendents}
                onChange={(e) =>
                  handleChange("inherit_location_supervisors_and_superintendents", e.target.checked)
                }
              />
              <span style={{ marginLeft: 15 }}>
                Inherit job&apos;s supervisors and superintendents from job&apos;s location
              </span>
            </div>
          </div>
        </SettingsCard>
        <SettingsCard title="Sub jobs" info={"Enable and manage your company's sub job settings"}>
          <div style={{ marginTop: 10 }}>
            <div className="flex">
              <input
                type="checkbox"
                checked={!!jobSettings?.enabled_sub_jobs}
                onChange={(e) => {
                  handleChange("enabled_sub_jobs", e.target.checked);
                }}
              />
              <span style={{ marginLeft: 15 }}>
                <strong>Enable sub jobs</strong>: turn on the ability to create parent/child relationships
                between jobs
              </span>
            </div>
          </div>
          {jobSettings?.enabled_sub_jobs && (
            <div style={{ marginTop: 10 }}>
              <Formblock
                label="How many levels of sub jobs would you like to allow?"
                labelInfo="One level means that each job can have one sub job, while two levels would mean that each sub job can have a sub job."
                type="number"
                name="sub_job_config.depth"
                className="modal"
                min={1}
                placeholder={"Depth"}
                defaultValue={jobSettings?.sub_job_config?.depth || 1}
                onChange={(e) => {
                  handleChange("sub_job_config.depth", e.target.value || 1);
                }}
                editing={true}
                labelStyle={{ fontSize: "1rem", color: "black" }}
                inputProps={{ style: { width: 100 } }}
                style={{ marginLeft: 35 }}
              />
              <Formblock
                label="Inherited parent properties"
                labelInfo="Select which properties from the parent job should be inherited by the sub job when created"
                type="multiselect"
                name="sub_job_config.inherited_parent_properties"
                options={SubJobInheritedPropertyOptions}
                defaultValue={jobSettings?.sub_job_config?.inherited_parent_properties}
                onChange={(option) =>
                  handleChange(
                    "sub_job_config.inherited_parent_properties",
                    option?.map((o) => o.value) || []
                  )
                }
                className="modal"
                editing={true}
                height="unset"
                isClearable={true}
                labelStyle={{ width: 400 }}
                style={{ marginTop: 20 }}
              />
            </div>
          )}
        </SettingsCard>
        <SettingsCard
          title="Access configuration"
          info={"Configure which jobs are accessible to which users"}
        >
          <div style={{ marginTop: 10 }}>
            <div className="flex">
              <input
                type="checkbox"
                checked={jobSettings?.team_members_can_access_department_jobs_only_on_mobile}
                onChange={(e) =>
                  handleChange("team_members_can_access_department_jobs_only_on_mobile", e.target.checked)
                }
              />
              <span style={{ marginLeft: 15 }}>
                Team members can only access jobs that are tied their department (mobile app only)
              </span>
            </div>
          </div>
        </SettingsCard>
        <SettingsCard title="Defaults" info={"Add default values for new jobs"}>
          <div style={{ marginTop: 10 }}>
            <div className="flex">
              <Formblock
                label="Default pay rate group"
                labelInfo="The pay rate group that will be selected by default when creating a new job"
                type="select"
                name="default_pay_rate_group_id"
                options={prgOptions}
                defaultValue={jobSettings?.default_pay_rate_group_id}
                onChange={(option) => handleChange("default_pay_rate_group_id", option?.value || null)}
                editing={true}
                isClearable={true}
                labelStyle={{ width: 400 }}
              />
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <div className="flex">
              <Formblock
                label="GL mapping"
                labelInfo="The ledger mapping that will be selected by default when creating a new job"
                type="select"
                name="default_ledger_mapping_id"
                options={filteredLedgerMappingOptions}
                defaultValue={jobSettings?.default_ledger_mapping_id}
                onChange={(option) => handleChange("default_ledger_mapping_id", option?.value || null)}
                editing={true}
                isClearable={true}
                labelStyle={{ width: 400 }}
              />
            </div>
          </div>
        </SettingsCard>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Custom fields</div>
          <CustomFieldsTable parentType="job" />
        </div>
      </div>
    </div>
  );
};

const SubJobInheritedPropertyOptions: Option<string>[] = [
  { value: "customer", label: "Customer" },
  { value: "address", label: "Address" },
  { value: "workplace_id", label: "Workplace" },
  { value: "start_date", label: "Start date" },
  { value: "supervisors", label: "Supervisors" },
  { value: "superintendent_ids", label: "Superintendents" },
  { value: "pay_rate_group", label: "Pay rate group" },
  { value: "overtime_rule_id", label: "Overtime rule" },
  { value: "is_ocip", label: "OCIP" },
  { value: "ledger_mapping_id", label: "Ledger mapping" },
  { value: "timesheet_policy_id", label: "Timesheet policy" },
  { value: "expense_policy_id", label: "Expense policy" },
  { value: "cpr_info", label: "CPR info" },
  { value: "specific_team_members_can_access", label: "Specific team members can access" },
  { value: "team_members", label: "Team members" },
  { value: "reimbursement_policy_id", label: "Reimbursement policy" },
];
