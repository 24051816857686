import { AggregatedTimesheet, MiterAPI, MiterFilterArray } from "dashboard/miter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActionModal, Button, Formblock, Notifier, PageModal } from "ui";
import { Option } from "ui/form/Input";
import { Plus, TrashSimple } from "phosphor-react";
import {
  useActiveCompanyId,
  useActivityLabelFormatter,
  useActivityOptionsMap,
  useGetClassificationOptions,
  useJobNameFormatter,
  useJobOptions,
  useLookupRateClassification,
  useLookupStandardClassification,
} from "dashboard/hooks/atom-hooks";
import Banner from "dashboard/components/shared/Banner";
import { isTimesheetScoped } from "../activities/activityUtils";
import {
  CellClassParams,
  CellValueChangedEvent,
  GridApi,
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { ColumnConfig } from "ui/table-v2/Table";
import { PageModalActionLink } from "ui/modal/PageModal";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { cleanFloatingPointErrors } from "miter-utils";
import TimesheetModal from "./ViewTimesheet/TimesheetModal";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import ObjectID from "bson-objectid";
import { SplitTimesheetsRow } from "backend/services/timesheets/timesheets-service";
import { selectEditorSuppressKeyboardEvent } from "ui/table-v2/AgGridSelectEditor";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";

type Props = {
  timesheetIds: string[];
  onHide: () => void;
  onSubmit: () => void;
};

type SplitType = "hours" | "pct";

// type of the local split. not using camelCase for easier comparison to AggregatedTimesheet.
export type SplitTimesheetTableRow = SplitTimesheetsRow;

const splitTypeOptions: Option<string>[] = [
  {
    label: "Hours",
    value: "hours",
  },
  {
    label: "% of Total",
    value: "pct",
  },
];

export const SplitTimesheetModal: React.FC<Props> = ({ timesheetIds, onHide, onSubmit }) => {
  // STATE
  const activeCompanyId = useActiveCompanyId();
  const timesheetAbilities = useTimesheetAbilities();
  const [splitType, setSplitType] = useState<SplitType>("hours");
  const [splits, setSplits] = useState<SplitTimesheetTableRow[]>([]);
  const [timesheets, setTimesheets] = useState<AggregatedTimesheet[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();
  const getClassificationOptions = useGetClassificationOptions();
  const lookupClass = useLookupRateClassification();
  const lookupStdClass = useLookupStandardClassification();
  const jobOptions = useJobOptions({ predicate: timesheetAbilities.jobPredicate("create") });
  const [approvedTimesheetWarning, setApprovedTimesheetWarning] = useState<string | undefined>();
  const [breakTimeWarning, setBreakTimeWarning] = useState<string | undefined>();
  const [importedTimesheetWarning, setImportedTimesheetWarning] = useState<string | undefined>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [invalidSplitMsg, setInvalidSplitMsg] = useState<string>();
  const [totalsRowData, setTotalsRowData] = useState<Partial<SplitTimesheetTableRow>[]>([]);
  const [originalTimesheetsModal, setOriginalTimesheetsModal] = useState(false);
  const [modalTimesheet, setModalTimesheet] = useState<string | undefined>();

  const timesheetsFilter = useMemo(() => {
    return [
      { field: "_id", value: timesheetIds, comparisonType: "in" as const },
      { field: "company", value: activeCompanyId },
    ];
  }, [activeCompanyId, timesheetIds]);

  const activityOptionsMap = useActivityOptionsMap({
    predicate: isTimesheetScoped,
  });

  const originalTimesheet = useMemo(() => {
    if (timesheets.length !== 1) return;
    return timesheets[0];
  }, [timesheets]);

  // initialize
  useEffect(() => {
    getData();
  }, [timesheetsFilter]);

  // HANDLERS
  const getData = async () => {
    if (!timesheetsFilter) return;
    const data = await MiterAPI.timesheets.forage({
      filter: timesheetsFilter,
    });

    initializeRows(data.data);
    setTimesheets(data.data);
  };

  const initializeRows = (timesheets: AggregatedTimesheet[]) => {
    if (timesheets.length > 1) {
      setSplitType("pct");
      setSplits([
        {
          _id: ObjectID().toHexString(),
        },
      ]);
    } else {
      const timesheet = timesheets[0];
      if (timesheet) {
        setSplits([
          {
            _id: ObjectID().toHexString(),
            job_id: timesheet.job?._id,
            activity_id: timesheet.activity?._id,
            classification_override: timesheet.classification_override,
            hours: timesheet.hours,
          },
        ]);
      }
    }

    if (timesheets.some((ts) => !ts.breaks?.length && ts.unpaid_break_time)) {
      setBreakTimeWarning(
        "One or more timesheets have break time. Break time will not be copied to the new timesheets."
      );
    } else {
      setBreakTimeWarning(undefined);
    }

    if (timesheets.some((ts) => ts.status !== "unapproved")) {
      if (timesheets.length === 1) {
        Notifier.warning(`This timesheet has been approved and therefore cannot be split.`);
        onHide();
      } else {
        setApprovedTimesheetWarning(
          "One or more timesheets have already been approved. These timesheets can't be split."
        );
      }
    } else {
      setApprovedTimesheetWarning(undefined);
    }

    if (timesheets.some((ts) => ts.integrations)) {
      if (timesheets.length === 1) {
        Notifier.warning(`This timesheet has been synced to/from another system and cannot be split.`);
        onHide();
      } else {
        setImportedTimesheetWarning(
          "One or more timesheets were imported from another system. These timesheets can't be split."
        );
      }
    } else {
      setImportedTimesheetWarning(undefined);
    }
  };

  const addRow = () => {
    setSplits((prev) => {
      const newSplit = {
        _id: ObjectID().toHexString(),
        job_id: null,
        activity_id: null,
        standard_classification_id: null,
        hours: 0,
        pct: 0,
      };
      return [...prev, newSplit];
    });
  };

  const refreshFirstRowValue = () => {
    if (!gridApi) return;
    if (splitType === "pct") {
      let sumPct = 0;
      gridApi?.forEachNode((node) => {
        if (node.rowIndex === 0) return;
        sumPct += node.data?.pct || 0;
      });
      if (sumPct > 100) {
        setInvalidSplitMsg(`Total % cannot exceed 100`);
        return;
      } else {
        gridApi?.getDisplayedRowAtIndex(0)?.setDataValue("pct", 100 - sumPct, "controlledUpdate");
      }
    } else if (splitType === "hours" && originalTimesheet) {
      let sumHours = 0;
      gridApi?.forEachNode((node) => {
        if (node.rowIndex === 0) return;
        sumHours += node.data?.hours || 0;
      });
      if (sumHours > originalTimesheet.hours) {
        setInvalidSplitMsg(
          `Hours across splits cannot exceed the original timesheet's hours (${originalTimesheet.hours})`
        );
        return;
      } else {
        gridApi
          ?.getDisplayedRowAtIndex(0)
          ?.setDataValue("hours", originalTimesheet.hours - sumHours, "controlledUpdate");
      }
    }
    setInvalidSplitMsg(undefined);
  };

  useEffect(() => {
    refreshFirstRowValue();
  }, [splits]);

  const handleSplitTypeChange = (selectedOption: Option<SplitType>) => {
    const newSplitType = selectedOption.value;
    setSplitType(newSplitType);
    initializeRows(timesheets);
  };

  // AgGrid actually directly mutates splits, which is super screwed up - it bypasses the state in this component

  const activityEditorParams = useCallback(
    (params: ICellEditorParams<SplitTimesheetTableRow>) => {
      return { options: activityOptionsMap.get(params?.data.job_id), isClearable: true };
    },
    [activityOptionsMap]
  );

  const deleteRow = (row: SplitTimesheetTableRow | undefined) => {
    setSplits((prev) => {
      const newSplits: SplitTimesheetTableRow[] = [];
      for (const split of prev) {
        if (split._id !== row?._id) {
          newSplits.push(split);
        }
      }
      return newSplits;
    });
  };

  const columns = useMemo(() => {
    const newColumns: ColumnConfig<SplitTimesheetTableRow>[] = [
      {
        headerName: "Job",
        field: "job_id",
        minWidth: 190,
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        valueGetter: (params: ValueGetterParams<SplitTimesheetTableRow>) => {
          return jobNameFormatter(params.data?.job_id) || "-";
        },
        editable: true,
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: { options: jobOptions, isClearable: true },
      },
      {
        headerName: "Activity",
        field: "activity_id",
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        valueGetter: (params: ValueGetterParams<SplitTimesheetTableRow>) => {
          return activityLabelFormatter(params.data?.activity_id) || "-";
        },
        minWidth: 190,
        editable: true,
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: activityEditorParams,
      },
      {
        headerName: "Classification",
        field: "classification_override",
        valueGetter: (params: ValueGetterParams<SplitTimesheetTableRow>) => {
          if (!params.data || params.data._id?.includes("totals")) return "-";
          return lookupClass(params.data?.classification_override)?.classification || "Auto";
        },
        minWidth: 190,
        editable: true,
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: (params: ICellEditorParams<SplitTimesheetTableRow>) => {
          const options = getClassificationOptions({
            jobId: params.data?.job_id,
            activityId: params.data?.activity_id,
            opts: {
              includeCustomOption: { label: "Auto", value: "" },
            },
          });
          return { options, isClearable: true };
        },
      },
      {
        headerName: splitType === "hours" ? "Hours" : "%",
        field: splitType === "hours" ? "hours" : "pct",
        valueFormatter:
          splitType === "hours"
            ? undefined
            : (params: ValueFormatterParams<SplitTimesheetTableRow>) => {
                if (!params.value) return "";
                const formattedNum = new Intl.NumberFormat("en-US", {
                  style: "percent",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                }).format(params.value / 100);
                return formattedNum;
              },
        minWidth: 190,
        cellStyle: (params: CellClassParams<SplitTimesheetTableRow>) => {
          if (params.node.rowIndex === 0) {
            return { color: "darkGray", cursor: "default" };
          }
        },
        editable: (params) => {
          return params.node.rowIndex !== 0;
        },
      },
      {
        headerName: " ",
        field: "actions",
        maxWidth: 70,
        pinned: "right",
        cellRenderer: (params: ICellRendererParams<SplitTimesheetTableRow>) => {
          if (!params.data) return null;
          return (
            <div className="flex" style={{ cursor: "default" }}>
              {params.node.rowIndex !== 0 && (
                <TrashSimple size={15} onClick={() => deleteRow(params.data)} style={{ cursor: "pointer" }} />
              )}
            </div>
          );
        },
      },
    ];
    return newColumns;
  }, [lookupStdClass, useGetClassificationOptions, splits, splitType, jobOptions, activityEditorParams]);

  const cleanSplits = (splits: SplitTimesheetTableRow[]) => {
    return splits.map((split) => {
      if (splitType === "hours") {
        delete split.pct;
      } else {
        delete split.hours;
      }
      return split;
    });
  };

  const handleSave = async () => {
    const companyId = activeCompanyId;
    if (!companyId) return;
    setIsSaving(true);
    try {
      // Remove the other split type field if it's not being used
      const cleanedSplits = cleanSplits(splits);

      const response = await MiterAPI.timesheets.split({ timesheetIds, splits: cleanedSplits, companyId });
      if (response.error) throw new Error(response.error);
      onSubmit();
    } catch (e) {
      Notifier.error(`There was an error splitting the timesheet(s). We're looking into it!`);
    }
    setIsSaving(false);
  };

  const getTotalsRowData = () => {
    const totalsRow = splits.reduce(
      (overall, data) => {
        return Object.keys(data).reduce((acc, key) => {
          if (data[key] == null) return acc;
          // Check if the column is a number or strip any non-numeric characters and convert it into a number if possible
          const isNumber = typeof data[key] === "number";
          const val = isNumber ? data[key] : Number(data[key].toString().replace(/[^0-9.-]+/g, ""));

          if (isNaN(val)) return acc;

          acc[key] = (acc[key] || 0) + val;
          return acc;
        }, overall);
      },
      { _id: "totals_row" }
    );

    for (const key of Object.keys(totalsRow)) {
      if (typeof totalsRow[key] == "number") {
        const cellValue = cleanFloatingPointErrors(totalsRow[key]);
        totalsRow[key] = cellValue;
      }
    }

    setTotalsRowData([totalsRow]);
  };

  useEffect(() => {
    getTotalsRowData();
  }, [splits]);

  const footerActions = useMemo(() => {
    const acts: PageModalActionLink[] = [];
    acts.push({
      label: "Save and close",
      action: handleSave,
      loading: isSaving,
      className: `button-2`,
      position: "right",
      disabled: !!invalidSplitMsg,
    });
    return acts;
  }, [isSaving, handleSave, invalidSplitMsg]);

  const onCellValueChanged = async (event: CellValueChangedEvent<SplitTimesheetTableRow>): Promise<void> => {
    getTotalsRowData();
    if (event.colDef.field === "job_id") {
      const newJobId = event.newValue;
      const selectableActivities = activityOptionsMap.get(newJobId);
      const currActivityId = event.node.data?.activity_id;
      if (!newJobId || selectableActivities.every((a) => a.value !== currActivityId)) {
        event.node.setDataValue("activity_id", undefined, "controlledUpdate");
      }
    } else if (event.colDef.field === "pct" || event.colDef.field === "hours") {
      refreshFirstRowValue();
    }

    if (["job_id", "activity_id"].includes(event.colDef.field || "")) {
      event.node.setDataValue("classification_override", undefined, "controlledUpdate");
    }

    setSplits(() => {
      const newSplits: SplitTimesheetTableRow[] = [];
      event.api.forEachNode((node) => {
        if (node.data) newSplits.push(node.data);
      });
      return newSplits;
    });
  };

  const renderHeader = useCallback(() => {
    const onClick = () => {
      if (timesheets.length === 1 && timesheets[0]) {
        setModalTimesheet(timesheets[0]._id);
      } else {
        setOriginalTimesheetsModal(true);
      }
    };
    return (
      <div className="flex">
        <span>{`Split timesheet${timesheets.length > 1 ? "s" : ""}`}</span>
        <Button
          wrapperStyle={{ marginLeft: 10 }}
          className="button-1"
          text={`View original timesheet${timesheets.length > 1 ? "s" : ""}`}
          onClick={onClick}
        />
      </div>
    );
  }, [timesheets]);

  return (
    <PageModal
      header={renderHeader()}
      onClose={onHide}
      footerActions={footerActions}
      bodyContentStyle={{ maxWidth: 1600, padding: "3%" }}
    >
      {invalidSplitMsg && (
        <Banner type="error" style={{ marginBottom: 30 }}>
          {invalidSplitMsg}
        </Banner>
      )}
      {breakTimeWarning && (
        <Banner type="warning" style={{ marginBottom: 30 }}>
          {breakTimeWarning}
        </Banner>
      )}
      {approvedTimesheetWarning && (
        <Banner type="warning" style={{ marginBottom: 30 }}>
          {approvedTimesheetWarning}
        </Banner>
      )}
      {importedTimesheetWarning && (
        <Banner type="warning" style={{ marginBottom: 30 }}>
          {importedTimesheetWarning}
        </Banner>
      )}
      {modalTimesheet && (
        <TimesheetModal
          hide={() => setModalTimesheet(undefined)}
          id={modalTimesheet}
          readOnly={true}
          handleChange={() => {
            getData();
          }}
        />
      )}
      {originalTimesheetsModal && (
        <OriginalTimesheetsModal onHide={() => setOriginalTimesheetsModal(false)} filter={timesheetsFilter} />
      )}
      <>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 25 }}>
          <div style={{ width: 250 }}>
            <Formblock
              label="Split By: "
              type="select"
              onChange={handleSplitTypeChange}
              editing={true}
              options={splitTypeOptions}
              value={splitTypeOptions.find((o) => o.value === splitType)}
              disabled={timesheets.length > 1}
            />
          </div>
          <Button className={"button-1"} onClick={addRow}>
            <Plus weight="bold" style={{ marginRight: 3 }} />
            New row
          </Button>
        </div>
        <div style={{}}>
          <AgGridTable
            gridHeight={"60vh"}
            hideDownloadCSV={true}
            data={splits}
            columnDefs={columns}
            hideSidebar={true}
            loading={timesheets.length === 0}
            setGridApi={setGridApi}
            gridOptions={{
              singleClickEdit: true,
              stopEditingWhenCellsLoseFocus: true,
              onCellValueChanged,
              pinnedBottomRowData: totalsRowData,
            }}
          />
        </div>
      </>
    </PageModal>
  );
};

type OriginalTimesheetModalProps = {
  onHide: () => void;
  filter: MiterFilterArray;
};

const OriginalTimesheetsModal: React.FC<OriginalTimesheetModalProps> = ({ onHide, filter }) => {
  return (
    <ActionModal
      onSubmit={onHide}
      submitText="Close"
      showSubmit={true}
      onHide={onHide}
      headerText="Timesheets to split"
      wrapperStyle={{ width: "60vw" }}
    >
      <TimesheetsTable
        defaultFilters={filter}
        hideReportViews={true}
        readOnly={true}
        hideDateRangeFilter={true}
        hideCreateButtons={true}
        hideTimezoneFilter={true}
      />
    </ActionModal>
  );
};
