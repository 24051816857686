import React, { useMemo, useState } from "react";

import { BasicModal, Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import {
  useActiveCompanyId,
  useFillableTemplates,
  useLookupFillableTemplates,
  useRefetchFillableTemplates,
} from "dashboard/hooks/atom-hooks";
import { Plus, TrashSimple } from "phosphor-react";
import { MiterAPI } from "dashboard/miter";
import { AggregatedFillableTemplate } from "dashboard/types/fillable-template-types";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { notNullish } from "miter-utils";
import { useFillableTemplateAbilities } from "dashboard/hooks/abilities-hooks/useFillableTemplateAbilities";
import { FillableTemplateWizard } from "./wizard/FillableTemplateWizard";

const columns: ColumnConfig<AggregatedFillableTemplate>[] = [
  {
    field: "name",
    headerName: "Template",
    width: 250,
    dataType: "string" as const,
  },
  {
    field: "pending_fillable_docs",
    headerName: "Pending",
    dataType: "number",
  },
  {
    field: "complete_fillable_docs",
    headerName: "Complete",
    dataType: "number",
  },
  {
    field: "created_at",
    headerName: "Created on",
    dataType: "date",
    dateType: "timestamp",
  },
];

const FillableTemplatesTable: React.FC = ({}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const fillableTemplates = useFillableTemplates();
  const refetchFillableTemplates = useRefetchFillableTemplates();
  const { can, cannot } = useFillableTemplateAbilities();
  const lookupFillableTemplate = useLookupFillableTemplates();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [selectedRows, setSelectedRows] = useState<AggregatedFillableTemplate[]>([]);
  const [loading] = useState(false);
  const [confirmModal, setConfirmModal] = useState<null | "delete">(null);
  const [createTemplateWizardOpen, setCreateTemplateWizardOpen] = useState(false);
  const [activeFillableTemplate, setActiveFillableTemplate] = useState<AggregatedFillableTemplate>();

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleSelect = (selections: AggregatedFillableTemplate[]) => {
    setSelectedRows(selections);
  };

  const archiveFillableTemplate = async () => {
    if (selectedRows.length === 0 || cannot("delete", selectedRows) || !activeCompanyId) return;
    try {
      const fillableTemplateIds = selectedRows.map((row) => row._id);
      const res = await MiterAPI.fillable_templates.archive({
        company_id: activeCompanyId,
        ids: fillableTemplateIds,
      });
      if ("failures" in res && res.failures?.length) {
        const failures = res.failures
          .map((f) => {
            const fillableTemplate = lookupFillableTemplate(f.id);
            if (!fillableTemplate) return null;
            return {
              label: fillableTemplate.name,
              message: f.reason,
            };
          })
          .filter(notNullish);

        setFailures(failures);
      }
      if (res.failures.length || res.error) {
        throw new Error(res.error || "Error deleting fillable templates.");
      }
      Notifier.success("Successfully deleted fillable templates.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setConfirmModal(null);
    setSelectedRows([]);
    refetchFillableTemplates();
  };

  /*********************************************************
    Toggler configs
  **********************************************************/

  const dynamicActions = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 table-button",
        action: () => setConfirmModal("delete"),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("delete", selectedRows),
      },
    ],
    [setConfirmModal, can, selectedRows]
  );

  const openTemplateWizard = () => {
    setCreateTemplateWizardOpen(true);
  };

  const onExit = () => {
    setCreateTemplateWizardOpen(false);
    setActiveFillableTemplate(undefined);
    refetchFillableTemplates();
  };

  const closeTemplateWizard = () => {
    onExit();
    Notifier.success("Template successfully saved.");
  };

  const staticActions = useMemo(
    () => [
      {
        label: "Create template",
        className: "button-2 table-button",
        action: openTemplateWizard,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        important: true,
      },
    ],
    [setConfirmModal, can]
  );

  const renderTable = () => {
    return (
      <TableV2
        id="fillable-templates-table"
        resource="fillable templates"
        data={fillableTemplates}
        columns={columns}
        onSelect={handleSelect}
        isLoading={loading}
        defaultSelectedRows={selectedRows}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        hideFooter={true}
        containerStyle={{ paddingBottom: 0 }}
        rowLinkBuilder={(row) => `/fillable-templates/${row!._id}/requests`}
      />
    );
  };

  return (
    <div>
      {renderTable()}

      {confirmModal === "delete" && (
        <BasicModal
          headerText={"Are you sure?"}
          bodyText={
            "Deleting a fillable template will delete all documents requested from the template. If you still want access those documents, do not delete this template."
          }
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button1Action={() => setConfirmModal(null)}
          button2Action={archiveFillableTemplate}
          button2ClassName={"button-3"}
        />
      )}
      {createTemplateWizardOpen && (
        <FillableTemplateWizard
          fillableTemplate={activeFillableTemplate}
          onExit={onExit}
          onComplete={closeTemplateWizard}
        />
      )}
      {renderFailuresModal()}
    </div>
  );
};

export default FillableTemplatesTable;
