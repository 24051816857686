import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, ExpenseCard } from "dashboard/miter";
import { CardManagementTableRow } from "dashboard/pages/expenses/CardManagementTable";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * MITER CARDS
 */
type MiterCardParams = ExpenseCard | CardManagementTableRow | CardManagementTableRow[];

type MiterCardAction = "create" | "read" | "update" | "delete";
type MiterCardAbilities = {
  can: (action: MiterCardAction, items: MiterCardParams) => boolean;
  cannot: (action: MiterCardAction, items: MiterCardParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: MiterCardAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useMiterCardAbilities = (): MiterCardAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: MiterCardAction, items?: MiterCardParams) => {
      if (!items) return false;

      const miterCards = Array.isArray(items) ? items : [items];

      return miterCards.every((miterCard) => {
        const teamMemberId = getTeamMemberId(miterCard);

        switch (action) {
          case "create":
            return can_("miter_cards:create", { teamMember: teamMemberId });
          case "read":
            return can_("miter_cards:read", { teamMember: teamMemberId });
          case "update":
            return can_("miter_cards:update", { teamMember: teamMemberId });
          case "delete":
            return can_("miter_cards:delete", { teamMember: teamMemberId });
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: MiterCardAction, items: MiterCardParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "miter_cards",
    teamMemberField: { fieldName: "team_member", fieldType: "string" },
    appModule: "expense_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<MiterCardAction>("miter_cards", true);

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, filter, teamPredicate]);
};

const getTeamMemberId = (item: ExpenseCard | CardManagementTableRow) => {
  return "team_member" in item ? item.team_member : item.assignedTeamMemberId;
};
