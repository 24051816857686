import { useCallback } from "react";
import { PermissionGroupSubModule, PermissionPaths } from "backend/models/permission-group";
import { AggregatedJob, Job } from "dashboard/miter";
import { useIsSuperAdmin } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";

/**
 * This hook returns a predicate that takes an action string and returns a function that takes a job and returns
 * a boolean indicating whether the user has the ability on an item to performan an action, in this module, with this job
 */
export const useAbilitiesJobPredicate = <TAction extends string>(
  subModule: PermissionGroupSubModule,
  skipOthers?: boolean
): ((action?: TAction) => (job: AggregatedJob | Job) => boolean) => {
  const { can } = useMiterAbilities();
  const isSuperAdmin = useIsSuperAdmin();

  return useCallback(
    (action?: TAction) => {
      return (job: AggregatedJob | Job) => {
        // If the user is a super admin, they can do anything
        if (isSuperAdmin) return true;

        const personalPermissionPath = (
          skipOthers
            ? subModule + ":" + (action || "create")
            : subModule + ":personal:" + (action || "create")
        ) as PermissionPaths;

        const othersPermissionPath = (
          skipOthers ? subModule + ":" + (action || "create") : subModule + ":others:" + (action || "create")
        ) as PermissionPaths;

        return can(othersPermissionPath, { job, isPredicate: true }) || can(personalPermissionPath, { job });
      };
    },
    [can, isSuperAdmin, skipOthers]
  );
};
