import React, { useEffect, useMemo, useState } from "react";
import TimesheetContext from "./timesheetContext";
import { useForm, useWatch } from "react-hook-form";
import { Formblock, Button, Badge } from "ui";
import * as vals from "dashboard/utils/validators";
import person from "dashboard/assets/user.png";
import Notifier from "dashboard/utils/notifier";
import { AggregatedTimesheet, MiterAPI, Timesheet } from "dashboard/miter";
import { DateTime } from "luxon";
import { Option } from "ui/form/Input";
import { roundTo } from "dashboard/utils";
import { checkTmAndActivityLicenseMatch } from "dashboard/utils/utils";
import { creationMethodBadgeLookup, handleTmClick } from "dashboard/utils/timesheetUtils";
import {
  useLookupActivity,
  useJobOptions,
  useTeamOptions,
  useLookupTeam,
  useUser,
  useActivityOptionsMap,
  useActiveCompany,
  useClassificationOptions,
  useJobNameFormatter,
  useActivityLabelFormatter,
  useActiveTeamMember,
  useDepartmentOptions,
  useActiveRole,
  useLookupTimeOffPolicy,
  useWcCodeOptions,
  useLocationOptions,
  useRateDifferentials,
  useRateDifferentialOptions,
  useLookupCostType,
  useCostTypeOptions,
  useEquipmentOptions,
} from "dashboard/hooks/atom-hooks";
import { required } from "dashboard/utils/validators";
import { timezoneOptions } from "miter-utils";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import {
  getEarningTypeAliasForTs,
  getEarningTypeOptionsForTmFlattened,
  getEarningTypeParamsFromAlias,
} from "../TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { NeedsAttentionBanner } from "dashboard/components/policies/NeedsAttentionBanner";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { DraftTimesheet } from "../BulkCreateTimesheets/BulkCreateTimesheets";

type TimesheetDetailsForm = {
  team_member: Option<string>;
  job: Option<string>;
  activity: Option<string>;
  cost_type_id: Option<string>;
  classification_override: Option<string>;
  notes: string;
  clock_in: DateTime;
  clock_out: DateTime;
  clock_in_time: string;
  clock_out_time: string;
  department_id: Option<string>;
  location_id: Option<string>;
  timezone: Option<string>;
  earning_type: Option<string>;
  rate_differential_id: Option<string>;
  equipment_ids: Option<string[]>;
};
import Banner from "dashboard/components/shared/Banner";
import { useTimesheetPolicy } from "dashboard/utils/policies/timesheet-policy-utils";
import { JobInput } from "dashboard/components/shared/JobInput";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { isCostTypeTimesheetScoped } from "dashboard/components/cost-types/costTypeUtils";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

// This component renders the "Details" page/form within the TimesheetModal
const TimesheetDetails: React.FC = () => {
  // Hooks
  const {
    timesheet,
    getTimesheet,
    updateTimesheet,
    changeTimesheetEditStatus,
    handleChange,
    readOnly,
    inboxMode,
  } = React.useContext(TimesheetContext);
  const lookupTeam = useLookupTeam();
  const lookupActivity = useLookupActivity();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const lookupCostType = useLookupCostType();
  const user = useUser();
  const abilities = useMiterAbilities();
  const canReadWcCode = abilities.can("lists:workers_comp_codes:read");
  const company = useActiveCompany();
  const activeTeamMember = useActiveTeamMember();
  const activeRole = useActiveRole();
  const timesheetAbilities = useTimesheetAbilities({ inboxMode });
  const departmentOptions = useDepartmentOptions();
  const locationOptions = useLocationOptions();
  const wcCodeOptions = useWcCodeOptions();
  const rateDifferentials = useRateDifferentials();
  const rateDifferentialOptions = useRateDifferentialOptions();
  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeTimesheetScoped,
  });
  const equipmentOptions = useEquipmentOptions();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const enableAdminWcCode = company?.settings?.timesheets.enable_admin_wc_code;

  const form = useForm<TimesheetDetailsForm>({ shouldUnregister: false });
  const formData = form.watch();
  useWatch({ control: form.control });

  const defaultClockIn = DateTime.fromSeconds(timesheet!.clock_in, { zone: timesheet?.timezone });
  const defaultClockOut = DateTime.fromSeconds(timesheet!.clock_out, { zone: timesheet?.timezone });

  /**
   * Returns the clock in time for saving
   * 1. If the clock in time's day, hour, or minute changed, return the new clock in time
   * 2. If the clock in time's seconds only changed, return the default clock in time
   */
  const buildCleanedClockIn = (dt: DateTime) => {
    const dayChanged = dt.day !== defaultClockIn.day;
    const hourChanged = dt.hour !== defaultClockIn.hour;
    const minuteChanged = dt.minute !== defaultClockIn.minute;

    if (dayChanged || hourChanged || minuteChanged) {
      return dt.startOf("minute");
    } else {
      return defaultClockIn;
    }
  };

  const buildCleanedClockOut = (dt: DateTime) => {
    const dayChanged = dt.day !== defaultClockOut.day;
    const hourChanged = dt.hour !== defaultClockOut.hour;
    const minuteChanged = dt.minute !== defaultClockOut.minute;

    if (dayChanged || hourChanged || minuteChanged) {
      return dt.startOf("minute");
    } else {
      return defaultClockOut;
    }
  };

  // Need to create a draft timesheet for the policies to work
  const draftTimesheet: DraftTimesheet | AggregatedTimesheet | undefined = useMemo(() => {
    return {
      team_member: formData.team_member?.value,
      job: formData.job?.value,
      activity: formData.activity?.value,
      cost_type_id: formData.cost_type_id?.value,
      classification_override: formData.classification_override?.value,
      clock_in: formData.clock_in ? buildCleanedClockIn(formData.clock_in).toSeconds() : undefined,
      clock_out: formData.clock_out ? buildCleanedClockOut(formData.clock_out).toSeconds() : undefined,
      clock_in_time: formData.clock_in_time,
      clock_out_time: formData.clock_out_time,
      earning_type: formData.earning_type?.value as Timesheet["earning_type"],
      rate_differential_id: formData.rate_differential_id?.value,
      notes: formData.notes,
    };
  }, [formData]);

  const [editing, setEditing] = useState(false);
  const editingAndUnapproved = editing && timesheet!.status === "unapproved";

  const { isFieldVisible, isFieldRequired } = useTimesheetPolicy(draftTimesheet);

  // Build team member options
  const teamOptions = useTeamOptions({
    defaultValue: timesheet?.team_member._id,
    predicate: timesheetAbilities.teamPredicate(),
  });

  const [selectedTmOption, setSelectedTmOption] = useState(() =>
    timesheet?.team_member._id ? teamOptions.find((t) => t.value === timesheet.team_member._id) : undefined
  );
  const selectedTm = lookupTeam(selectedTmOption?.value);

  // Created a flattened list of earning type options because the grouped list is not working
  const earningTypeOptions = useMemo(() => {
    return getEarningTypeOptionsForTmFlattened(lookupTimeOffPolicy, "timesheet", selectedTm);
  }, [selectedTm]);

  const earningTypeAlias = useMemo(() => getEarningTypeAliasForTs(timesheet), [timesheet]);

  // Build job options and selected job option
  const jobFormatter = useJobNameFormatter();
  const jobOptions = useJobOptions({
    defaultValue: timesheet?.job?._id,
    predicate: timesheetAbilities.jobPredicate("read"),
  });

  const [selectedJobOption, setSelectedJobOption] = useState(
    () => jobOptions.find((t) => t.value === timesheet?.job?._id) || null
  );

  // Build activity options and selected activity option
  const activityFormatter = useActivityLabelFormatter();

  const activityOptionsMap = useActivityOptionsMap({
    defaultValue: timesheet?.activity?._id,
    predicate: isTimesheetScoped,
  });
  const [activityOptions, setActivityOptions] = useState(() => activityOptionsMap.get(timesheet?.job?._id));
  const [selectedActivityOption, setSelectedActivityOption] = useState(
    () => activityOptions.find((t) => t?.value === timesheet?.activity?._id) || null
  );

  const selectedActivity = lookupActivity(selectedActivityOption?.value);

  const classificationOptions = useClassificationOptions({
    tmId: editingAndUnapproved ? formData.team_member?.value : timesheet?.team_member._id,
    activityId: editingAndUnapproved ? formData.activity?.value : timesheet?.activity?._id,
    jobId: editingAndUnapproved ? formData.job?.value : timesheet?.job?._id,
  });

  const [selectedClassificationOption, setSelectedClassificationOption] = useState(
    () => classificationOptions.find((t) => t.value === timesheet?.classification_override) || null
  );

  const [selectedCostTypeOption, setSelectedCostTypeOption] = useState(
    () => costTypeOptions.find((t) => t?.value === timesheet?.cost_type_id) || null
  );

  const [selectedEquipmentOptions, setSelectedEquipmentOptions] = useState(
    () => equipmentOptions.filter((t) => timesheet?.equipment_ids?.includes(t.value)) || null
  );

  const [saving, setSaving] = useState(false);
  const [approving, setApproving] = useState(false);

  const [clockOutErrorMessage, setClockOutErrorMessage] = useState<string | undefined>();

  const [breakTimeHours, setBreakTimeHours] = useState(0);
  const [breakTimeMinutes, setBreakTimeMinutes] = useState(0);
  const [breakTimeSeconds, setBreakTimeSeconds] = useState(0);

  let initialBreakTimeHours = 0;
  let initialBreakTimeMinutes = 0;
  let initialBreakTimeSeconds = 0;
  if (timesheet?.unpaid_break_time) {
    initialBreakTimeHours = Math.floor(timesheet.unpaid_break_time / 3600);
    initialBreakTimeMinutes = Math.round((timesheet.unpaid_break_time - initialBreakTimeHours * 3600) / 60);
    initialBreakTimeSeconds =
      timesheet.unpaid_break_time - initialBreakTimeHours * 3600 - initialBreakTimeMinutes * 60;
  }

  const fullBreakTime = breakTimeHours * 3600 + breakTimeMinutes * 60 + breakTimeSeconds;

  useEffect(() => {
    changeTimesheetEditStatus(editing);
  }, [editing]);

  useEffect(() => {
    checkTmAndActivityLicenseMatch(selectedActivity, selectedTm);
  }, [selectedActivity, selectedTm]);

  const save = async (data) => {
    if (timesheetAbilities.cannot("update", timesheet)) {
      Notifier.error("You do not have permission to update this timesheet.");
      return;
    }

    setSaving(true);

    let payload: Partial<Timesheet>;
    if (timesheet!.status === "unapproved") {
      payload = {
        ...data,
        department_id: data.department_id?.value || null,
        location_id: data.location_id?.value || null,
        timezone: data.timezone?.value || null,
        job: data.job === null ? null : data.job?.value,
        activity: selectedActivityOption === null ? null : selectedActivityOption?.value,
        equipment_ids: data.equipment_ids?.map((e: Option<string>) => e.value) || null,
        team_member: data.team_member?.value || undefined,
        cost_type_id: data.cost_type_id?.value || null,
        classification_override: selectedClassificationOption?.value || null,
        clock_in: data.clock_in ? buildCleanedClockIn(data.clock_in).toSeconds() : undefined,
        clock_out: data.clock_out ? buildCleanedClockOut(data.clock_out).toSeconds() : undefined,
        clock_in_time: data.clock_in_time,
        clock_out_time: data.clock_out_time,
        wc_code: data.wc_code?.value,
        rate_differential_id: data.rate_differential_id?.value || null,
        ...(data.earning_type ? { ...getEarningTypeParamsFromAlias(data.earning_type?.value) } : {}),
      };
    } else {
      payload = {
        notes: data.notes,
      };
    }
    await updateTimesheet(payload);
    setEditing(false);
    setSaving(false);
  };

  const handleApprove = async () => {
    if (timesheetAbilities.cannot("approve", timesheet)) {
      Notifier.error("You do not have permission to approve this timesheet.");
      return;
    }

    setApproving(true);
    try {
      if (!timesheet) return;

      const payload = {
        ids: [timesheet._id],
        team_member_id: activeTeamMember?._id,
        role_id: activeRole?._id,
      };

      const response = await MiterAPI.timesheets.approve(payload);
      if (response.error) throw new Error(response.error);

      if ("failures" in response && response.failures?.length) {
        const failures = response.failures.map((f) => ({
          label: "Timesheet",
          message: f.message,
        }));

        setFailures(failures);
        throw new Error("We were unable to approve this timesheet");
      }

      Notifier.success("Timesheet approved.");
      handleChange?.();
      await getTimesheet();
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error approving the timesheet.");
    }
    setApproving(false);
  };

  const handleUnapprove = async () => {
    if (timesheetAbilities.cannot("approve", timesheet)) {
      Notifier.error("You do not have permission to unapprove this timesheet.");
      return;
    }

    setApproving(true);
    try {
      if (!timesheet) return;

      const payload = {
        ids: [timesheet._id],
        team_member_id: activeTeamMember?._id,
      };

      const response = await MiterAPI.timesheets.unapprove(payload);
      if (response.error) throw new Error(response.error);

      if ("failures" in response && response.failures?.length) {
        const failures = response.failures.map((f) => ({
          label: "Timesheet",
          message: f.message,
        }));

        setFailures(failures);
        throw new Error("We were unable to unapprove this timesheet");
      }

      Notifier.success("Timesheet unapproved.");
      handleChange?.();
      await getTimesheet();
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error unapproving the timesheet.");
    }
    setApproving(false);
  };

  const handleRestore = async () => {
    if (timesheetAbilities.cannot("update", timesheet)) {
      Notifier.error("You do not have permission to update this timesheet.");
      return;
    }

    setSaving(true);
    const payload = {
      archived: false,
    };
    await updateTimesheet(payload);
    setSaving(false);
  };

  const editButtonText = timesheet?.status === "unapproved" ? "Edit" : "Edit Notes";

  const EditSaveButton = () => {
    return (
      <div className="flex">
        {!editing && !timesheet?.archived && !readOnly && (
          <div className="flex">
            {timesheetAbilities.can("update", timesheet) && (
              <button className="button-1" onClick={() => setEditing(true)}>
                {editButtonText}
              </button>
            )}
            {timesheetAbilities.can("approve", timesheet) && timesheet?.status === "unapproved" && (
              <Button onClick={handleApprove} loading={approving} text="Approve" className="button-2" />
            )}
          </div>
        )}
        {timesheetAbilities.can("approve", timesheet) &&
          timesheet?.status === "approved" &&
          !timesheet?.archived &&
          !editing &&
          !readOnly && (
            <Button onClick={handleUnapprove} loading={approving} text="Unapprove" className="button-1" />
          )}
        {editing && (
          <div className="flex">
            <button className="button-1" onClick={handleCancel}>
              Cancel
            </button>
            <Button
              className={"button-2 no-margin"}
              text="Save"
              loading={saving}
              onClick={form.handleSubmit(save)}
              disabled={Object.keys(form.errors).length > 0 || clockOutErrorMessage ? true : false}
            />
          </div>
        )}
        {timesheetAbilities.can("update", timesheet) && timesheet!.archived && !readOnly && (
          <button className={"button-2"} onClick={handleRestore}>
            Restore
          </button>
        )}
      </div>
    );
  };

  const handleCancel = () => {
    setClockOutErrorMessage(undefined);
    setBreakTimeHours(initialBreakTimeHours);
    setBreakTimeMinutes(initialBreakTimeMinutes);
    setBreakTimeSeconds(initialBreakTimeSeconds);
    setEditing(false);
  };

  const handleTmChange = (tmOption: Option<string>) => {
    setSelectedTmOption(tmOption);
  };

  const handleJobChange = (jobOption: Option<string> | null) => {
    setSelectedJobOption(jobOption);
    const newActivityOptions = activityOptionsMap.get(jobOption?.value);
    setActivityOptions(newActivityOptions);
    if (newActivityOptions.every((o) => o.value !== selectedActivityOption?.value)) {
      setSelectedActivityOption(null);
    }
  };

  const handleActivityChange = (activityOption: Option<string> | null) => {
    setSelectedActivityOption(activityOption);
  };

  const handleEquipmentChange = (equipmentOptions: Option<string>[]) => {
    setSelectedEquipmentOptions(equipmentOptions);
  };

  const handleClassificationChange = (classificationOption: Option<string> | null) => {
    setSelectedClassificationOption(classificationOption);
  };

  const handleCostTypeChange = (costTypeOption: Option<string> | null) => {
    setSelectedCostTypeOption(costTypeOption);
  };

  const renderClockInOutFields = () => {
    return (
      <>
        <Formblock
          label="Clock in*"
          type="datetime"
          name="clock_in"
          form={form}
          editing={editingAndUnapproved}
          defaultValue={defaultClockIn}
          timezone={formData.timezone?.value || timesheet?.timezone}
          rules={vals.required}
          max={formData.clock_out}
          customFormat={"MMM d, yyyy 'at' h:mm:ss a ZZZZ"}
        />
        <Formblock
          label="Clock out*"
          type="datetime"
          name="clock_out"
          form={form}
          editing={editingAndUnapproved}
          defaultValue={defaultClockOut}
          timezone={formData.timezone?.value || timesheet?.timezone}
          rules={vals.required}
          min={formData.clock_in}
          customFormat={"MMM d, yyyy 'at' h:mm:ss a ZZZZ"}
        />
        {timesheet?.timezone && (
          <Formblock
            name="timezone"
            label="Timezone"
            type="select"
            defaultValue={timesheet.timezone}
            options={timezoneOptions}
            editing={editingAndUnapproved}
            form={form}
          />
        )}
      </>
    );
  };

  let defaultJobString = jobFormatter(timesheet?.job);
  if (timesheet?.job?.archived) {
    defaultJobString += " (deleted)";
  }

  let defaultActivityString = activityFormatter(timesheet?.activity);
  if (timesheet?.activity?.archived) {
    defaultActivityString += " (deleted)";
  }

  const selectedCostType = lookupCostType(selectedCostTypeOption?.value);
  let defaultCostTypeString = selectedCostTypeOption?.label;
  if (selectedCostType?.archived) {
    defaultCostTypeString += " (deleted)";
  }

  const seconds =
    ((formData.clock_out || defaultClockOut).toSeconds() || 0) -
    ((formData.clock_in || defaultClockIn).toSeconds() || 0) -
    (fullBreakTime || 0);

  const timesheetHours = seconds ? roundTo(Math.max(seconds / 3600, 0.01)) : 0;

  useEffect(() => {
    setBreakTimeHours(initialBreakTimeHours);
    setBreakTimeMinutes(initialBreakTimeMinutes);
  }, [timesheet]);

  let breakTimeRoundingText;
  if (timesheet?.rounding_metadata?.warning === "same_clock_in_and_clock_out") {
    if (timesheet?.rounding_metadata?.rounded_clock_in && timesheet?.rounding_metadata?.rounded_clock_out) {
      const clockInDateTime = DateTime.fromSeconds(timesheet?.rounding_metadata?.rounded_clock_in, {
        zone: timesheet?.timezone,
      });
      const clockTimeText = clockInDateTime.toFormat("MMM d, yyyy 'at' h:mm:ss a ZZZZ");
      breakTimeRoundingText =
        "This timesheet has been left unrounded because rounding clock in and clock out times would set them both to " +
        clockTimeText +
        ".";
    } else {
      breakTimeRoundingText =
        "This timesheet has been left unrounded because rounding clock in and clock out times would set them to the same time.";
    }
  } else if (timesheet?.rounding_metadata?.warning === "breaks_eliminated") {
    breakTimeRoundingText =
      "This timesheet has been left unrounded because rounding break times would eliminate a break.";
  }

  return (
    <>
      <div className="flex-col" style={{ marginTop: -35, marginBottom: 15 }}>
        <NeedsAttentionBanner item={timesheet} policyType={"timesheet"} />
        {timesheet?.rounding_metadata?.warning && (
          <Banner
            type="warning"
            content={breakTimeRoundingText}
            style={{ marginLeft: -42, marginRight: -42 }}
          />
        )}
      </div>
      <div className="width-100" style={{ overflow: "visible" }}>
        <div className="payment-active-view-header">
          <div className="">Details</div>
          <div className="flex-1"></div>
          <EditSaveButton />
        </div>
        <div className="vertical-spacer-small"></div>
        {(user?.miter_admin || !IS_PRODUCTION) && !editing && (
          <Formblock name="_id" label="ID" type="text" defaultValue={timesheet?._id} editing={false} />
        )}
        <Formblock
          name="team_member"
          label="Team member"
          type="select"
          options={teamOptions!}
          defaultValue={timesheet!.team_member._id}
          onChange={handleTmChange}
          form={form}
          requiredSelect
          editing={editingAndUnapproved}
        >
          <div className="tm-link" onClick={() => handleTmClick(timesheet?.team_member._id)}>
            <img className="tm-link-img" src={person} />
            <span>{timesheet?.team_member.full_name}</span>
          </div>
        </Formblock>
        {renderClockInOutFields()}
        <Formblock type="text" locked label="Hours">
          <div>{timesheetHours}</div>
        </Formblock>
        {(company?.settings.timesheets.always_display_earning_types ||
          (earningTypeAlias && earningTypeOptions)) && (
          <Formblock
            name="earning_type"
            options={earningTypeOptions}
            label="Earning type"
            type="select"
            form={form}
            editing={editingAndUnapproved}
            defaultValue={earningTypeAlias}
            isClearable
          />
        )}
        {isFieldVisible("job") && (
          <JobInput
            name="job"
            label="Job"
            type="select"
            form={form}
            options={jobOptions}
            onChange={handleJobChange}
            defaultValue={timesheet?.job?._id}
            maxMenuHeight={200}
            value={selectedJobOption}
            editing={editingAndUnapproved}
            isClearable
            requiredSelect={isFieldRequired("job")}
            defaultString={defaultJobString}
          />
        )}
        {isFieldVisible("activity") && (
          <Formblock
            name="activity"
            label="Activity"
            type="select"
            options={activityOptions}
            onChange={handleActivityChange}
            defaultValue={timesheet?.activity?._id}
            value={selectedActivityOption}
            editing={editingAndUnapproved}
            isClearable
            requiredSelect={isFieldRequired("activity")}
            defaultString={defaultActivityString}
          />
        )}
        {hasAccessToEquipmentTracking && isFieldVisible("equipment_ids") && (
          <Formblock
            name="equipment_ids"
            label="Equipment"
            type="multiselect"
            options={equipmentOptions}
            onChange={handleEquipmentChange}
            defaultValue={timesheet?.equipment_ids || []}
            value={selectedEquipmentOptions}
            editing={editingAndUnapproved}
            style={{
              control: (base) => ({
                ...base,
                height: "fit-content",
              }),
            }}
            form={form}
            isClearable
            requiredSelect={isFieldRequired("equipment_ids")}
          />
        )}
        {isFieldVisible("cost_type_id") && (
          <Formblock
            name="cost_type_id"
            label="Cost type"
            type="select"
            options={costTypeOptions}
            onChange={handleCostTypeChange}
            defaultValue={timesheet?.cost_type_id}
            value={selectedCostTypeOption}
            editing={editingAndUnapproved}
            form={form}
            isClearable
            requiredSelect={isFieldRequired("cost_type_id")}
            defaultString={defaultCostTypeString}
          />
        )}
        {isFieldVisible("classification_override") && (
          <Formblock
            name="classification_override"
            label="Classification override"
            labelInfo="This classification will override the auto-selected classification."
            type="select"
            options={classificationOptions}
            onChange={handleClassificationChange}
            defaultValue={timesheet?.classification_override}
            value={selectedClassificationOption}
            editing={editingAndUnapproved}
            isClearable
            requiredSelect={isFieldRequired("classification_override")}
          />
        )}
        {!!rateDifferentials.length && (
          <Formblock
            name="rate_differential_id"
            label="Rate differential"
            type="select"
            options={rateDifferentialOptions}
            defaultValue={timesheet?.rate_differential_id}
            form={form}
            editing={editingAndUnapproved}
            isClearable
          />
        )}
        <Formblock
          name="department_id"
          label="Department"
          type="select"
          options={departmentOptions}
          form={form}
          editing={editingAndUnapproved}
          defaultValue={timesheet?.department_id}
          isClearable
        />
        <Formblock
          name="location_id"
          label="Location"
          type="select"
          options={locationOptions}
          form={form}
          editing={editingAndUnapproved}
          defaultValue={timesheet?.location_id}
          isClearable
        />
        {canReadWcCode && enableAdminWcCode && (
          <Formblock
            name="wc_code"
            label="Workers' comp code"
            type="select"
            options={wcCodeOptions}
            form={form}
            editing={editingAndUnapproved}
            defaultValue={timesheet?.wc_code}
            isClearable
          />
        )}
        {isFieldVisible("injury") && (
          <Formblock type="text" locked label="Injury">
            <div>{timesheet?.injury ? "Yes" : "No"}</div>
          </Formblock>
        )}
        {isFieldVisible("notes") && (
          <Formblock
            type="paragraph"
            defaultValue={timesheet?.notes}
            editing={editing}
            form={form}
            val={isFieldRequired("notes") ? required : undefined}
            label="Notes"
            name="notes"
          >
            <>{timesheet?.notes || "-"}</>
          </Formblock>
        )}
        {timesheet?.creation_method && creationMethodBadgeLookup.hasOwnProperty(timesheet?.creation_method) && (
          <Formblock type="text" locked label="Creation method">
            <Badge
              text={creationMethodBadgeLookup[timesheet?.creation_method].label}
              color={creationMethodBadgeLookup[timesheet?.creation_method].color}
              style={{ margin: 0 }}
              className="no-margin"
            />
          </Formblock>
        )}
      </div>
      {renderFailuresModal()}
    </>
  );
};

export default TimesheetDetails;
