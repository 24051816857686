import React, { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Formblock, Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";

import { MiterAPI, Form } from "dashboard/miter";
import { useCompanyUserOptions } from "dashboard/hooks/atom-hooks";
import { Assign } from "utility-types";
import { Option } from "ui/form/Input";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { keyBy } from "lodash";
import { styles } from "miter-components/forms";
import { notNullish } from "miter-utils";

type Props = {
  formItem: Form | undefined;
  name: string;
};

export type FormSettingsForm = Assign<
  Pick<
    Form,
    | "allow_multiple_submissions"
    | "allow_partial_submissions"
    | "allow_editable_submissions"
    | "allow_unrequested_submissions"
  >,
  { users_to_notify_on_submission: { label: string; value: string }[] }
>;

const FormSettingsScreen: React.FC<Props> = ({ name, formItem }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { setCanNext, setNextButtonText, handleComplete, screens } = useWizard();
  const { can } = useMiterAbilities();
  const userOptions = useCompanyUserOptions();
  const userOptionsLookup = useMemo(() => keyBy(userOptions, "value"), [userOptions]);

  const form = useForm<FormSettingsForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(formItem, userOptionsLookup),
  });

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors } = form;
  const { dirtyFields, isValid } = formState;

  /*********************************************************
   * useEffect's
   * - Trigger form validation on change
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Submit");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveForm)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    handleComplete();
  };

  const saveForm = async (params: FormSettingsForm) => {
    if (!formItem) return;

    try {
      const cleanedParams = {
        ...params,
        users_to_notify_on_submission: params.users_to_notify_on_submission.map((user) => user.value),
      };

      const res = await MiterAPI.forms.update(formItem._id, cleanedParams);
      if (res.error) throw new Error(res.error);
      Notifier.success("Form saved successfully");
    } catch (e: $TSFixMe) {
      console.error("Error saving form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Additional Settings</h2>
          <p className={styles["subheader-description"]}>Setup additional settings for your form</p>
        </div>
        <Formblock
          text="Allow multiple submissions"
          type="checkbox"
          name="allow_multiple_submissions"
          form={form}
          className="modal wizard"
          editing={true}
          style={{ marginBottom: 10 }}
        />
        <Formblock
          text="Allow unrequested submissions"
          type="checkbox"
          name="allow_unrequested_submissions"
          form={form}
          className="modal wizard"
          editing={true}
          style={{ marginBottom: 10 }}
        />
        <Formblock
          text="Allow editable submissions"
          type="checkbox"
          name="allow_editable_submissions"
          form={form}
          className="modal wizard"
          editing={true}
          style={{ marginBottom: 10 }}
        />
        <Formblock
          label="Users to notify on submission"
          sublabel="Select users to email when a submission is received"
          options={userOptions}
          type="multiselect"
          name="users_to_notify_on_submission"
          form={form}
          className="modal wizard"
          editing={true}
          style={{ marginTop: 25 }}
          height="unset"
        />

        {can("forms:forms:manage_sensitive") && (
          <Formblock
            type="checkbox"
            name="sensitive"
            form={form}
            text="This forms contains sensitive information"
            editing={true}
            className="modal wizard"
          />
        )}
      </div>
    </WizardScreen>
  );
};

export default FormSettingsScreen;

const buildDefaultValues = (
  formItem: Form | undefined,
  userOptionsLookup: Record<string, Option<string>>
): FormSettingsForm => {
  if (!formItem) return { users_to_notify_on_submission: [] };

  return {
    ...formItem,
    users_to_notify_on_submission:
      formItem.users_to_notify_on_submission?.map((user) => userOptionsLookup[user]).filter(notNullish) || [],
  };
};
