import React, { useEffect, useState } from "react";
import { reportList } from "../reportList";
import { LoadingModal } from "ui";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { DateRange } from "ui/form/DateRangePicker";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { ColDef, GridApi, ValueGetterParams } from "ag-grid-community";
import { saveAs } from "file-saver";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type ApprenticeRatioReportEntry = {
  jobName: string;
  classification: string;
  apprenticeHours: number;
  journeymanHours: number;
  otherHours: number;
};

const reportObject = reportList.find((report) => report.slug === "apprentice_ratio");

export const CreateApprenticeRatioReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();

  // State
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ months: 1 }),
    end: DateTime.now(),
  });
  const [data, setData] = useState<ApprenticeRatioReportEntry[]>([]);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi<ApprenticeRatioReportEntry>>();

  const getData = async (format: "json" | "pdf") => {
    if (!activeCompanyId || !gridApi) return;
    if (format === "json") {
      gridApi.showLoadingOverlay();
    }
    setDownloadingPDF(format === "pdf");

    try {
      const payload = {
        type: "apprentice_ratio",
        params: {
          start_date: dateRange.start!.toISODate(),
          end_date: dateRange.end!.toISODate(),
          company_id: activeCompanyId!,
        },
        format,
      };

      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      if (payload.format === "json") {
        setData(response);
      } else if (payload.format === "pdf") {
        console.log("PDF response", response);
        const blob = new Blob([Uint8Array.from(response.buffer.data).buffer], { type: "application/pdf" });
        saveAs(blob, response.filename);
      }
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    gridApi.hideOverlay();
    setDownloadingPDF(false);
  };

  useEffect(() => {
    getData("json");
  }, [dateRange, !!gridApi, !!activeCompanyId]);

  const fileName =
    "Miter Apprentice Ratio Report " + dateRange.start!.toISODate() + " - " + dateRange.end!.toISODate();

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Apprentice ratio report</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}*</div>
      <div className="vertical-spacer-small"></div>
      <AgGridTable
        reportId="apprentice-ratio"
        dateRange={dateRange}
        dateRangeLabel="Payday range"
        onDateRangeChange={setDateRange}
        data={data}
        columnDefs={colDefs}
        fileName={fileName}
        setGridApi={setGridApi}
        supplementalTableActions={[
          {
            text: "Download PDF",
            onClick: () => getData("pdf"),
          },
        ]}
      />
      <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
        * Includes earnings associated with paid, processing, and pending payrolls.
      </div>
      <div className="vertical-spacer-large"></div>
      {downloadingPDF && <LoadingModal text="Downloading PDF" />}
    </div>
  );
};

const ratioValueGetter = (params: ValueGetterParams<ApprenticeRatioReportEntry>) => {
  if (!params.data?.apprenticeHours) return "-";
  const ratio = (params.data?.journeymanHours || 0) / params.data.apprenticeHours;
  return ratio.toFixed(1);
};

const colDefs: ColDef[] = [
  {
    field: "jobName",
    headerName: "Job",
    filter: true,
  },
  {
    field: "classification",
    headerName: "Classification",
    filter: true,
  },
  {
    field: "journeymanHours",
    headerName: "Journeyman hours",
    aggFunc: "sumValues",
  },
  {
    field: "apprenticeHours",
    headerName: "Apprentice hours",
    aggFunc: "sumValues",
  },
  {
    field: "otherHours",
    headerName: "Other hours",
    aggFunc: "sumValues",
  },
  {
    valueGetter: ratioValueGetter,
    headerName: "Journeyman:Apprentice ratio",
  },
];
