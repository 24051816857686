import React, { useEffect, useState } from "react";
import "ui/table/Table.css";
import { Badge, Button, usdString } from "ui";
import { MiterAPI, StripeAccountResponse } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { externalRedirect } from "dashboard/utils";
import { useEnhancedSearchParams } from "miter-utils";
import styles from "./Expenses.module.css";
import InboundTransferTable from "./InboundTransfersTable";
import ExpenseCTA from "./ExpenseCTA";
import { Helmet } from "react-helmet";
import { Loader } from "ui";
import InfoButton from "dashboard/components/information/information";
import { HiRefresh } from "react-icons/hi";
import { initializeStripeAccount } from "dashboard/utils/expenses";
import { getMiterCardsAccount, isMiterCardsAccountFullyActive } from "../expenses/expenseUtils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { StripeInboundTransfer } from "backend/utils/stripe";
import { MiterCardFundingModal } from "./MiterCardFundingModal";

export const ExpenseFundingGetStarted: React.FC<{ stripeAccount: StripeAccountResponse | null }> = ({
  stripeAccount,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const [isLoading, setLoading] = useState(false);
  const company = activeCompanyId!;

  const handleGetStarted = async () => {
    initializeStripeAccount({
      company,
      account: stripeAccount,
      source: "Expense Funding Getting Started lander",
      setLoading,
    });
  };

  return (
    <ExpenseCTA
      headline="Streamline job costing and save 5 hours a week with expense cards."
      description={
        "View and classify transactions for your company, all in one place. To get started, set up an expense " +
        "account and then issue debit cards to team members."
      }
      ctaButton={
        <Button className="button-2 btn-wide" loading={isLoading} onClick={handleGetStarted}>
          {stripeAccount ? "Continue setup" : "Get started"}
        </Button>
      }
    />
  );
};

const ExpenseAccountBalance: React.FC<{
  stripeAccount: StripeAccountResponse;
  refreshData: () => void;
}> = ({ stripeAccount, refreshData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const miterCardsAccount = getMiterCardsAccount(stripeAccount);

  const update = async () => {
    setLoading(true);
    refreshData();
    setLoading(false);
  };

  return (
    <div className="flex space-between width-100-percent">
      <div className="flex">
        <h2>{usdString((miterCardsAccount?.balance.cash.usd || 0) / 100)}</h2>
        <HiRefresh
          className={`${loading && "fa-spin-reverse"} pointer color-gray font-size-12 margin-left-4`}
          onClick={update}
        />
      </div>
      <AddFundsButton stripeAccount={stripeAccount} refreshData={update} />
    </div>
  );
};

export const AddFundsButton: React.FC<{
  stripeAccount: StripeAccountResponse;
  refreshData: () => void;
}> = ({ refreshData }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} className="button-2" text={"Add / Manage funding"} />
      {open && <MiterCardFundingModal onHide={() => setOpen(false)} refreshBalance={refreshData} />}
    </>
  );
};

const ExpenseFunding: React.FC<{
  stripeAccount: StripeAccountResponse | null;
  refreshData: () => void;
}> = ({ stripeAccount, refreshData }) => {
  if (!stripeAccount) return <Loader />;

  return (
    <>
      <Helmet>
        <title>Expense Funding | Miter</title>
      </Helmet>
      {isMiterCardsAccountFullyActive(stripeAccount) && (
        <>
          <BalanceCard stripeAccount={stripeAccount} refreshData={refreshData} />
        </>
      )}
      <AccountCard stripeAccount={stripeAccount} />
    </>
  );
};

const AccountCard: React.FC<{ stripeAccount: StripeAccountResponse }> = ({ stripeAccount }) => {
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams<"from" | "redirect">({
    replaceInHistory: true,
  });

  useEffect(() => {
    if (parsedSearchParams.from === "account_onboarding") {
      Notifier.success("Expense account created.", { duration: 7 * 1000 });

      // Remove "from" from the query string.
      setSearchParams({ from: undefined });
    }

    if (parsedSearchParams.redirect) {
      resumeStripeAccountLink(parsedSearchParams.redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSearchParams]);

  const resumeStripeAccountLink = async (redirect: string) => {
    if (redirect === "account_onboarding" && stripeAccount.account_onboarding_link) {
      externalRedirect(null, stripeAccount.account_onboarding_link);
    }
    if (redirect === "account_update" && stripeAccount.account_update_link) {
      externalRedirect(null, stripeAccount.account_update_link);
    }
  };

  const description =
    "Continue setting up your expense account. Then you can issue debit cards and easily view and classify your transactions." +
    "\n\nIf you have submitted all your documents, it may take some time to verify your account. Check if your status has changed by refreshing this page or resubmitting your information.";

  const isFullyActive = isMiterCardsAccountFullyActive(stripeAccount);
  return (
    <div className={styles.card_wrapper}>
      <div className="flex">
        <h3 className={styles.vertical_margin_12}>Account</h3>
        <InfoButton text="This cash account is used to fund purchases made with your expense cards." />
      </div>
      {isFullyActive === false && <p className={styles.white_space_pre_line}>{`${description}`}</p>}

      <div className={`${styles.small_text_on_white} info-row align-items-center`}>
        <div>Status</div>
        <Badge table text={isFullyActive ? "active" : "pending"} />
      </div>
      {isFullyActive && (
        <>
          {stripeAccount.account_update_link && (
            <div className={`${styles.vertical_margin_12} flex`}>
              <Button
                onClick={(e) => externalRedirect(e, stripeAccount.account_update_link)}
                className="btn-wide no-margin button-1"
              >
                Update your info
              </Button>
              <InfoButton text="Miter partners with Stripe for secure financial services." />
            </div>
          )}
        </>
      )}
      {!isFullyActive && (
        <>
          {stripeAccount.account_onboarding_link && (
            <div className={styles.vertical_margin_12}>
              <Button
                className="btn-wide no-margin button-2"
                onClick={(e) => externalRedirect(e, stripeAccount.account_onboarding_link)}
              >
                Continue setup
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const BalanceCard: React.FC<{
  stripeAccount: StripeAccountResponse;
  refreshData: () => void;
}> = ({ stripeAccount, refreshData }) => {
  const [transfers, setTransfers] = useState<StripeInboundTransfer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const miterCardAccount = getMiterCardsAccount(stripeAccount);
  const getTransfers = async () => {
    if (!miterCardAccount) return;

    setIsLoading(true);
    try {
      const inboundTransfers = await MiterAPI.stripe_accounts.list_inbound_transfers({
        financialAccountStripeId: miterCardAccount?.id,
      });
      if (inboundTransfers.error) throw new Error(inboundTransfers.error);
      setTransfers(inboundTransfers);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error(e.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTransfers();
  }, []);

  const update = () => {
    getTransfers();
    refreshData();
  };

  return (
    <div className={styles.card_wrapper}>
      <ExpenseAccountBalance stripeAccount={stripeAccount} refreshData={update} />
      <InboundTransferTable transfers={transfers} isLoading={isLoading} />
    </div>
  );
};

export default ExpenseFunding;
