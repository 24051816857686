import React, { useMemo } from "react";
import { ActionModal } from "ui";
import {
  useActivities,
  useDepartments,
  useJobNameFormatter,
  useJobs,
  useLookupLedgerMapping,
  useTeam,
} from "dashboard/hooks/atom-hooks";
import { ColumnOrGroupConfig, TableV2 } from "ui/table-v2/Table";

type Props = {
  ledgerMappingId: string;
  hide: () => void;
};

type UsageEntry = {
  _id: string;
  type: "Job" | "Activity" | "Department" | "Team Member";
  code?: string | null;
  label: string;
};

export const LedgerMappingUsageModal: React.FC<Props> = ({ ledgerMappingId, hide }) => {
  const lookupMapping = useLookupLedgerMapping();
  const team = useTeam();
  const jobs = useJobs();
  const jobFormatter = useJobNameFormatter();
  const activities = useActivities();
  const depts = useDepartments();

  const mapping = lookupMapping(ledgerMappingId);

  const data = useMemo(() => {
    const entries: UsageEntry[] = [];
    for (const dept of depts) {
      if (dept.ledger_mapping_id === ledgerMappingId) {
        entries.push({ _id: dept._id, type: "Department", code: dept.identifier, label: dept.name });
      }
    }
    for (const job of jobs) {
      if (job.ledger_mapping_id === ledgerMappingId) {
        entries.push({ _id: job._id, type: "Job", code: job.code, label: job.name });
      }
    }
    for (const act of activities) {
      if (act.ledger_mapping_id === ledgerMappingId) {
        let label = act.label;
        if (act.job) {
          const jobLabel = jobFormatter(act.job);
          if (jobLabel) {
            label += ` (${jobLabel})`;
          }
        }
        entries.push({ _id: act._id, type: "Activity", code: act.cost_code, label });
      }
    }
    for (const tm of team) {
      if (tm.ledger_mapping_id === ledgerMappingId) {
        entries.push({ _id: tm._id, type: "Team Member", code: tm.friendly_id, label: tm.full_name });
      }
    }
    return entries;
  }, [team, jobs, activities, depts]);

  const columns = useMemo(() => {
    const cols: ColumnOrGroupConfig<UsageEntry>[] = [
      {
        field: "type",
        headerName: "Type",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        field: "code",
        headerName: "ID",
        dataType: "string",
      },
      {
        field: "label",
        headerName: "Label",
        dataType: "string",
      },
    ];
    return cols;
  }, []);

  if (!mapping) return null;

  return (
    <ActionModal
      headerText={`Objects using mapping '${mapping.name}'`}
      onHide={hide}
      wrapperStyle={{ width: "50%" }}
      bodyStyle={{ overflow: "visible", maxHeight: "100%" }}
      showCancel={false}
      cancelText="Hide"
      onCancel={hide}
      hideFooter
    >
      <TableV2
        data={data}
        columns={columns}
        resource="objects"
        id={`objects-using-ledger-mapping`}
        gridWrapperStyle={{ height: "40vh" }}
        hideFooter
        hideSelectColumn
      />
    </ActionModal>
  );
};
