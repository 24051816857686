import React, { useEffect, useMemo, useRef, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";

import arrow from "dashboard/assets/right-arrow.png";
import { ActionLink } from "../action-menu/ActionMenu";
import { Loader } from "../loader";
import useWindowDimensions from "dashboard/utils/useWindowDimensions";
import { getScrollableParent } from "miter-utils";
import { debounce } from "lodash";
import { TableActionLink } from "../table-v2/Table";

type Props = {
  className?: string;
  wrapperClassName?: string;
  menuClassName?: string;
  label?: React.ReactNode;
  options: ActionLink[] | TableActionLink[];
  children?: React.ReactNode;
  showArrow?: boolean;
  closeOnClick?: boolean;
  showOnHover?: boolean;
  wrapperStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  optionStyle?: React.CSSProperties;
  position?: "left" | "right";
  shiftIcons?: boolean;
  alwaysShowMenuBelow?: boolean;
  wrapperRef?: (node?: Element | null | undefined) => void;
};

const DropdownButton: React.FC<Props> = ({
  className = "",
  wrapperClassName,
  menuClassName,
  label,
  options,
  children,
  showArrow,
  closeOnClick,
  showOnHover,
  wrapperStyle,
  buttonStyle,
  optionStyle,
  position = "right",
  shiftIcons,
  alwaysShowMenuBelow,
  wrapperRef,
}) => {
  const [open, setOpen] = useState(false);
  const [scrollableParent, setScrollableParent] = useState<HTMLElement | null>(null);

  const { width, height } = useWindowDimensions();
  const { scrollTop, scrollLeft } = useElementScroll(scrollableParent);

  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuHeight = options.length * 32;

  useEffect(() => {
    setScrollableParent(getScrollableParent(menuRef.current));
  }, [open, menuRef.current]);

  const visibleOptions = useMemo(() => {
    // @ts-expect-error fix me
    return options.filter((option) => !("shouldShow" in option) || option.shouldShow?.());
  }, [options]);

  const showMenuAbove = useMemo(() => {
    if (alwaysShowMenuBelow) return false;
    const midPoint = height / 2;
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    if (!buttonRect) return false;

    return buttonRect.top > midPoint;
  }, [open, width, height, scrollTop, scrollLeft]);

  const toggle = () => {
    setOpen(!open);
  };

  const handleClick = (option) => {
    option.action();

    if (closeOnClick) {
      setOpen(false);
    }
  };

  const onMouseEnter = () => {
    if (showOnHover) {
      setOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (showOnHover) {
      setOpen(false);
    }
  };

  if (!visibleOptions.length) return <></>;

  const renderIcon = (option) => {
    if (!option.icon) return <></>;
    if (!shiftIcons) return option.icon;

    return <div style={{ marginBottom: -4, marginRight: 4 }}>{option.icon}</div>;
  };

  return (
    <div
      className={"dropdown-button-wrapper " + wrapperClassName}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={wrapperStyle}
      ref={wrapperRef}
    >
      <button onClick={toggle} className={"dropdown-button " + className} style={buttonStyle} ref={buttonRef}>
        {children || label}
      </button>
      {open && (
        <ClickAwayListener onClickAway={toggle}>
          <div
            className={"dropdown-button-dropdown " + menuClassName + " " + position}
            ref={menuRef}
            style={{ ...(showMenuAbove ? { top: menuHeight * -1 - 10 } : {}) }}
          >
            {visibleOptions.map((option, index) => (
              <div
                onClick={() => handleClick(option)}
                className={
                  "dropdown-button-option flex" + (option.loading ? "dropdown-button-option-loading" : "")
                }
                key={"option-" + index}
                style={optionStyle}
              >
                <div className="flex">
                  {renderIcon(option)}
                  {option.loading ? <Loader className="small-text dropdown-option-loader" /> : option.label}
                </div>
                {showArrow ? <img src={arrow} className="dropdown-option-arrow" /> : <></>}
              </div>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DropdownButton;

type ScrollPosition = {
  scrollTop: number;
  scrollLeft: number;
};

// Custom Hook to detect element scroll
const useElementScroll = (element: HTMLElement | null): ScrollPosition => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({ scrollTop: 0, scrollLeft: 0 });

  const handleScroll = debounce(() => {
    if (element) {
      setScrollPosition({
        scrollTop: element.scrollTop,
        scrollLeft: element.scrollLeft,
      });
      console.log("Scroll", scrollPosition);
    }
  }, 100);

  useEffect(() => {
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [element, handleScroll]);

  return scrollPosition;
};
