import React, { useEffect, useState } from "react";
import { reportList } from "../reportList";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { DateRange } from "ui/form/DateRangePicker";
import { TrainingContributionsEntry } from "backend/utils/reports/trainingContributions";
import { MiterAPI } from "dashboard/miter";
import { Notifier, capitalize } from "dashboard/utils";
import { ColDef, GridApi } from "ag-grid-community";
import { dashFormatter, toDollarFormat } from "../reportUtils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

const reportObject = reportList.find((report) => report.slug === "training_contributions");

export const TrainingContributionReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();

  // State
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ months: 1 }),
    end: DateTime.now(),
  });

  const [data, setData] = useState<TrainingContributionsEntry[]>([]);
  const [gridApi, setGridApi] = useState<GridApi<TrainingContributionsEntry>>();

  const getData = async () => {
    if (!gridApi || !activeCompanyId || !dateRange.start || !dateRange.end) return;
    gridApi.showLoadingOverlay();
    try {
      const payload = {
        type: "training_contributions",
        params: {
          start_date: dateRange.start?.toISODate(),
          end_date: dateRange.end?.toISODate(),
          company_id: activeCompanyId,
        },
        format: "json",
      };

      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      setData(response);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    gridApi.hideOverlay();
  };

  useEffect(() => {
    getData();
  }, [dateRange, !!gridApi, !!activeCompanyId]);

  const fileName =
    "Miter Training Contributions Report " +
    dateRange.start!.toISODate() +
    " - " +
    dateRange.end!.toISODate();

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Training contributions report</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}*</div>
      <div className="vertical-spacer-small"></div>
      <AgGridTable
        reportId="training-contributions"
        setGridApi={setGridApi}
        dateRange={dateRange}
        dateRangeLabel="Payday range"
        onDateRangeChange={setDateRange}
        data={data}
        columnDefs={colDefs}
        fileName={fileName}
        gridOptions={{
          groupDisplayType: "multipleColumns",
          showOpenedGroup: true,
        }}
      />
      <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
        * Includes earnings associated with paid, processing, and pending payrolls.
      </div>
      <div className="vertical-spacer-large"></div>
    </div>
  );
};

const colDefs: ColDef<TrainingContributionsEntry>[] = [
  {
    field: "classification",
    headerName: "Classification",
    filter: true,
    initialRowGroup: true,
    initialHide: true,
    enableRowGroup: true,
  },
  {
    field: "jobCode",
    headerName: "Job code",
    filter: true,
    enableRowGroup: true,
    valueFormatter: dashFormatter,
  },
  {
    field: "jobName",
    headerName: "Job",
    filter: true,
    enableRowGroup: true,
    valueFormatter: dashFormatter,
  },
  {
    field: "type",
    headerName: "Contribution/Deduction",
    valueGetter: (params) => capitalize(params.data?.type || ""),
    filter: true,
    enableRowGroup: true,
  },
  {
    field: "label",
    headerName: "Label",
    filter: true,
    enableRowGroup: true,
  },
  {
    field: "hours",
    headerName: "Hours",
    aggFunc: "sumValues",
  },
  {
    colId: "trainingContributionRate",
    headerName: "Rate",
    valueGetter: (params) => params.getValue("totalContribution") / params.getValue("hours"),
    valueFormatter: toDollarFormat,
  },
  {
    field: "totalContribution",
    headerName: "Total",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
];
