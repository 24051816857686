import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { MiterAPI } from "dashboard/miter";
import { useActiveCompanyId, useLookupTeam, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { WizardTeamMember } from "./TeamMemberWizard";
import AppContext from "dashboard/contexts/app-context";
import CustomFieldValuesForm from "../custom-fields/CustomFieldValuesForm";
import { cleanCustomFieldValuesBeforeSaving } from "miter-utils";
import { pick } from "lodash";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberCustomFieldsForm = {
  [key: string]: $TSFixMe;
};

export const TeamMemberCustomFieldsScreen: React.FC<Props> = ({ name, teamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { customFields } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const refetchTeam = useRefetchTeam();
  const isMounted = useRef(true);
  const lookupTeam = useLookupTeam();

  const customFieldValues = useMemo(
    () => lookupTeam(teamMember?._id)?.custom_field_values || [],
    [teamMember?._id]
  );

  const teamCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "team_member"),
    [customFields]
  );

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const form = useForm<TeamMemberCustomFieldsForm>({
    reValidateMode: "onChange",
    mode: "all",
  });

  const formData = useWatch<TeamMemberCustomFieldsForm>({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberCustomFieldsForm) => {
    if (!activeCompanyId) throw new Error("No active role");
    if (!teamMember?._id) throw new Error("No team member");

    return cleanCustomFieldValuesBeforeSaving(
      pick(params, dirtyFields),
      activeCompanyId!,
      teamMember._id,
      "team_member",
      customFieldValues
    );
  };

  const saveTeamMember = async (params: TeamMemberCustomFieldsForm) => {
    try {
      if (!activeCompanyId) throw new Error("No active role");
      if (!teamMember?._id) throw new Error("No team member");

      const finalParams = buildParams(params);
      const res = await MiterAPI.custom_field_values.save(finalParams);
      if (res.error) throw new Error(res.error);

      await MiterAPI.team_member.update(teamMember._id, { resume_wizard_index: curIndex + 1 });
      await refetchTeam(teamMember?._id);

      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Custom fields</h2>
          <p className={styles["subheader-description"]}> Add custom fields to this team member</p>
        </div>
        <div className="form-section">
          <CustomFieldValuesForm
            form={form}
            customFields={teamCustomFields}
            defaultValues={customFieldValues}
            fieldOptions={{ inlineCheckbox: true }}
          />
        </div>
      </div>
    </WizardScreen>
  );
};
