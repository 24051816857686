import {
  CreateOnboardingChecklistParams,
  OnboardingChecklist,
  OnboardingMilestone,
  OnboardingTask,
  OnboardingTaskType,
} from "dashboard/types/onboarding-types";
import React, { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, DropdownButton, Formblock, Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import { styles } from "miter-components/forms";
import checklistStyles from "./Checklists.module.css";
import { Reorder, motion } from "framer-motion";
import { delay, isEmpty, isObject, set } from "lodash";
import { DotsSixVertical } from "phosphor-react";
import ObjectID from "bson-objectid";
import {
  useActiveCompanyId,
  useCompanyFormOptions,
  useCompanyDocumentOptions,
  useUser,
  useCertificationTypeOptions,
  useActiveCompany,
} from "dashboard/hooks/atom-hooks";
import { notNullish } from "miter-utils";
import { MiterAPI } from "dashboard/miter";
import { requiredCertification, requiredDocument, requiredForm } from "dashboard/utils/onboarding";

import ChecklistComponent from "./ChecklistComponent";
import {
  DEFAULT_MILESTONES,
  MAP_MILESTONE_TO_TASKS,
  MAP_TASK_TO_ACTION_TITLE,
  MAP_TASK_TO_DESCRIPTION,
  MAP_TASK_TO_MILESTONE,
} from "./constants";

export type OnboardingTaskComponent = OnboardingTask & {
  locked: boolean;
};

type OnboardingChecklistForm = Pick<OnboardingChecklist, "title" | "milestones"> & {
  tasks: OnboardingTaskComponent[];
};

type Props = {
  checklistItem: OnboardingChecklist | undefined;
  setChecklistItem: Dispatch<SetStateAction<OnboardingChecklist | undefined>>;
  name: string;
};

const ChecklistBuilderScreen: React.FC<Props> = ({ checklistItem, setChecklistItem, name }) => {
  /* Custom Hooks */
  const { setCanNext, handleComplete, screens, curIndex } = useWizard();
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();
  const company = useActiveCompany();
  // Not using for keeping track of values, but for free form-error checking
  const form = useForm({
    mode: "all",
  });
  const componentRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const documentOptions = useCompanyDocumentOptions();
  const formOptions = useCompanyFormOptions();
  const certificationTypeOptions = useCertificationTypeOptions();
  const sectionsRef = useRef<HTMLDivElement>(null);

  /* Use State Hooks */
  const [checklistData, setChecklistData] = useState<OnboardingChecklistForm>(
    buildDefaultValues(checklistItem)
  );
  const [wizardScreenRef, setWizardScreenRef] = useState<RefObject<HTMLDivElement> | undefined>();
  const [activeComponent, setActiveComponent] = useState<OnboardingTaskComponent>();
  const [lastDraggedComponent, setLastDraggedComponent] = useState<OnboardingTaskComponent>();
  const [draggable, setDraggable] = useState(false);
  const [activeFieldY, setActiveFieldY] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState({});

  const { formState, clearErrors, register, errors: formErrors, trigger } = form;

  /* Use Effects */

  // Set whether or not the user can move forward based on manual and react-hook-form validations
  useEffect(() => {
    if (Object.values(errors).filter(notNullish).length > 0 || !isEmpty(formState.errors)) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formState]);

  useEffect(() => {
    const newTasks = checklistData.tasks.map((task) => {
      return {
        ...task,
        locked: determineLockStatus(checklistData.milestones, task.type),
      };
    });
    setChecklistData({ ...checklistData, tasks: newTasks });
  }, [checklistData.milestones]);

  useEffect(() => {
    trigger();
  }, [checklistData.tasks, trigger]);

  useEffect(() => {
    if (checklistData.tasks.length === 0) {
      setActiveFieldY(0);
    }
  }, [checklistData.tasks.length]);

  useEffect(() => {
    if (checklistData.tasks.length) {
      delay(() => {
        setActiveComponent(checklistData.tasks[0]);
        handleMoveFormManager(checklistData.tasks[0]);
      }, 400);
    }
  }, []);

  /*********************************************************
   *  Submitting the checklist
   *********************************************************/

  const buildParams = (params: OnboardingChecklistForm) => {
    if (!activeCompanyId || !activeUser) throw new Error("No active user");
    const { tasks } = params;
    const fixedTasks = tasks.map((task) => {
      const { locked, ...strappedParams } = task;
      const isNewHireAssigned =
        strappedParams.assignee.user_id === "new_hire" && strappedParams.assignee.type === "new_hire";
      return {
        ...strappedParams,
        assignee: {
          type: (isNewHireAssigned ? "new_hire" : "admin") as OnboardingTask["assignee"]["type"],
          user_id: isNewHireAssigned ? undefined : strappedParams.assignee.user_id,
        },
      };
    });

    const finalizedParams: CreateOnboardingChecklistParams = {
      ...params,
      tasks: fixedTasks,
      company_id: activeCompanyId,
    };

    return finalizedParams;
  };

  const saveChecklist = async (params: OnboardingChecklistForm) => {
    if (Object.values(errors).filter(notNullish).length > 0 || !isEmpty(formState.errors)) return;

    try {
      const cleanedParams = buildParams(params);
      const res = checklistItem?._id
        ? await MiterAPI.onboarding_checklists.update(checklistItem._id, cleanedParams)
        : await MiterAPI.onboarding_checklists.create(cleanedParams);
      if (res.error) throw new Error(res.error);
      setChecklistItem(res);

      Notifier.success("Checklist saved");
    } catch (e: $TSFixMe) {
      console.error("Error saving checklist", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  // Wizard handlers
  const onNext = async () => {
    await saveChecklist(checklistData);
    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  /*********************************************************
   *  Other helper functions
   *********************************************************/
  const handleSetRef = (ref: RefObject<HTMLDivElement>) => {
    setWizardScreenRef(ref);
  };

  const taskChosen = (type: OnboardingTaskComponent["type"]) => {
    return checklistData.tasks.some((task) => task.type === type);
  };

  /** Header form that allows the user to edit the form name and form description */
  const renderHeaderForm = () => {
    return (
      <div style={{ paddingBottom: 10 }} className={styles["miter-form-header"]}>
        <Formblock
          className={styles["miter-form-title"]}
          placeholder="Form name"
          type="text"
          name="name"
          register={register({ required: "Form name is required" })}
          errors={formErrors}
          editing={true}
          onChange={(e) => setChecklistData({ ...checklistData, title: e.target.value })}
          defaultValue={checklistData.title}
          disabled={false}
        />
      </div>
    );
  };

  /** The checklist manager allows the user to add new component and manage the active component */
  const renderChecklistManager = () => {
    const fieldOptions = [
      {
        label: "Personal information",
        action: () => handleAddField("personal_information"),
        shouldShow: () => !taskChosen("personal_information"),
      },

      {
        label: "EEO information",
        action: () => handleAddField("eeo_information"),
        shouldShow: () => !taskChosen("eeo_information"),
      },

      {
        label: "New hire custom fields",
        action: () => handleAddField("custom_fields"),
        shouldShow: () => !taskChosen("custom_fields"),
      },

      {
        label: "Phone number",
        action: () => handleAddField("phone_number"),
        shouldShow: () => !taskChosen("phone_number"),
      },

      {
        label: "Employment information",
        action: () => handleAddField("employment_information"),
        shouldShow: () => !taskChosen("employment_information"),
      },

      ...(!company?.has_payroll
        ? [
            {
              label: "Add bank account",
              action: () => handleAddField("bank_account"),
              shouldShow: () => !taskChosen("bank_account"),
            },
          ]
        : [
            {
              label: "Payment method",
              action: () => handleAddField("payment_method"),
              shouldShow: () => !taskChosen("payment_method"),
            },
          ]),

      {
        label: "Witholding method",
        action: () => handleAddField("withholding_information"),
        shouldShow: () => !taskChosen("withholding_information"),
      },

      {
        label: "I-9",
        action: () => handleAddField("i9_employee"),
        shouldShow: () => !taskChosen("i9_employee"),
      },

      {
        label: "Documents",
        action: () => handleAddField("documents"),
      },

      {
        label: "Forms",
        action: () => handleAddField("forms"),
      },

      {
        label: "Certifications",
        action: () => handleAddField("certifications"),
      },

      {
        label: "Custom task",
        action: () => handleAddField("custom"),
      },
    ];

    return (
      <motion.div
        className={checklistStyles["form-builder-manager"]}
        animate={{ y: activeFieldY ? activeFieldY : 0 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      >
        <div className={styles["form-builder-manager-components"]}>
          <DropdownButton
            className="button-2 no-margin"
            options={fieldOptions}
            wrapperStyle={{ width: 100, height: 36 }}
            buttonStyle={{ width: 100, height: 36 }}
            closeOnClick={true}
            optionStyle={{ padding: "10px 15px", paddingRight: 40 }}
            alwaysShowMenuBelow={true}
          >
            Add Task
          </DropdownButton>
          {activeComponent && !activeComponent.locked && (
            <>
              <Button
                className="button-1 no-margin"
                onClick={handleDeleteField}
                style={{ width: 100, height: 36, marginTop: 10 }}
              >
                Delete task
              </Button>
            </>
          )}
        </div>
      </motion.div>
    );
  };

  const renderDraggable = () => {
    return (
      <div
        className={styles["form-component-draggable"]}
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
        onTouchStart={() => setDraggable(true)}
      >
        <DotsSixVertical style={{ fontSize: "24px", backgroundColor: "#fafafa" }} />
      </div>
    );
  };

  /*********************************************************
   * Form component movement handlers
   **********************************************************

  /** Handle's any drag events that changes the order of the fields */
  const handleFieldsReorder = (newlyOrderedFields: OnboardingTaskComponent[]) => {
    const updatedComponents = [...newlyOrderedFields];

    // Get the field that was moved by comparing with the last dragged component
    const movedFieldIndex = newlyOrderedFields.findIndex((field) => field._id === lastDraggedComponent?._id);

    // If the field that was moved, doesn't exist, return
    if (movedFieldIndex === -1) return;

    // Save the field that was moved
    const movedField = updatedComponents[movedFieldIndex];
    if (!movedField) return;

    setChecklistData({ ...checklistData, tasks: updatedComponents });
    setActiveComponent(updatedComponents[movedFieldIndex]);
  };

  const handleMoveFormManager = (component: OnboardingTaskComponent | undefined): void => {
    if (!component) return;

    // Get the ref for the component
    const newFieldRef = componentRefs.current[component._id];
    if (!newFieldRef) return;

    // Get the top of the newly added field
    const listTop = sectionsRef.current?.getBoundingClientRect().top;

    const newFieldTop = newFieldRef.getBoundingClientRect().top - (listTop || 0) + 155;

    // Set's the y position of the form manager so that we can animate it to the new position

    setActiveFieldY(newFieldTop);

    // Scroll to the field if specified
    if (true) wizardScreenRef?.current?.scrollTo({ top: newFieldTop, behavior: "smooth" });
  };

  const addTasksChangeLockedStatuses = (milestone: OnboardingMilestone): OnboardingTaskComponent[] => {
    const tasksToAccomplish = MAP_MILESTONE_TO_TASKS[milestone] || [];
    const tasksToAdd = tasksToAccomplish.filter((task) => {
      return !checklistData.tasks.some((t) => t.type === task);
    });

    return tasksToAdd.map((task) => buildDefaultField(task as OnboardingTaskType, checklistData));
  };

  /*********************************************************
   *  Form field handlers
   **********************************************************/
  const handleParseValue = (value: string | boolean | OnboardingTaskComponent["assignee"]) => {
    if (isObject(value)) return value;
    // If it's only digits, parse it as a number
    if (/^\d+$/.test(value.toString())) return Number(value.toString());
    return value;
  };

  const handleFieldChange = (
    key: string,
    value: string | boolean | OnboardingTaskComponent["assignee"],
    index: number
  ) => {
    const updatedComponents = [...checklistData.tasks];
    if (!updatedComponents[index]) return;

    set(updatedComponents[index]!, key, handleParseValue(value));
    setChecklistData({ ...checklistData, tasks: updatedComponents });
  };

  /**  Sets the clicked component as the active component and moves the form manager to that component */
  const handleSetActiveTaskComponent = (e, component: OnboardingTaskComponent) => {
    setActiveComponent(component);
    handleMoveFormManager(component);
  };

  /** Add a new field to the form */
  const handleAddField = (type: OnboardingTaskComponent["type"]) => {
    const newField: OnboardingTaskComponent = buildDefaultField(type, checklistData);

    const components = checklistData.tasks;
    const index = components.findIndex((c) => activeComponent && c._id === activeComponent._id);

    let updatedComponents: OnboardingTaskComponent[] = [];

    const newFieldAsArray = [newField];

    // If an i9 task is selected, we must also include the i9 employer task
    if (type === "i9_employee") {
      newFieldAsArray.push(buildDefaultField("i9_employer", checklistData));
    }

    updatedComponents = [
      ...components.slice(0, index + 1),
      ...newFieldAsArray,
      ...components.slice(index + 1),
    ];
    // If this is the last section, we will need manually move the form manager as this won't trigger a layout change
    if (index === updatedComponents.length - 2) {
      delay(() => handleMoveFormManager(newField), 100);
    }
    setChecklistData({ ...checklistData, tasks: updatedComponents });
    setActiveComponent(newField);
  };

  const handleDeleteField = () => {
    if (!activeComponent) return;
    const errorKey = `components.${activeComponent._id}`;

    const isI9 = activeComponent.type === "i9_employee" || activeComponent.type === "i9_employer";

    const index = checklistData.tasks.findIndex((c) => c._id === activeComponent._id);
    const updatedComponents = checklistData.tasks.filter((c) => {
      const isActiveComponent = c._id === activeComponent._id;
      const removingI9 = isI9 && (c.type === "i9_employer" || c.type === "i9_employee");
      return !isActiveComponent && !removingI9;
    });

    // clear out errors from deleted fields
    clearErrors(errorKey);
    setErrors((errors) => {
      const newErrors = { ...errors };
      Object.keys(newErrors).forEach((key) => {
        if (key.includes(errorKey)) {
          delete newErrors[key];
        }
      });

      return newErrors;
    });
    setActiveComponent(updatedComponents[updatedComponents.length - 1]);
    setChecklistData({ ...checklistData, tasks: updatedComponents });
    if (index >= 1) {
      delay(() => handleMoveFormManager(updatedComponents[updatedComponents.length - 1]), 400);
    }
  };

  const renderComponent = (component: OnboardingTaskComponent, index: number) => {
    return (
      <Reorder.Item
        key={"section-" + component._id}
        value={component}
        dragListener={draggable}
        className={styles["form-builder-section-container"]}
        onDragStart={() => setLastDraggedComponent(component)}
        onDragEnd={() => setDraggable(false)}
        onDragTransitionEnd={() => {
          setActiveComponent(component);
        }}
        layout="position"
        ref={(r) => (componentRefs.current[component._id] = r)}
        id={component._id}
      >
        <div style={{ position: "relative" }}>
          {renderDraggable()}
          {component.type === "personal_information" && renderGenericTask(component, index)}
          {component.type === "eeo_information" && renderGenericTask(component, index)}
          {component.type === "custom_fields" && renderGenericTask(component, index)}
          {component.type === "phone_number" && renderPhoneNumber(component, index)}
          {component.type === "employment_information" && renderEmploymentInformation(component, index)}
          {component.type === "payment_method" && renderGenericTask(component, index)}
          {component.type === "withholding_information" && renderGenericTask(component, index)}
          {component.type === "i9_employee" && renderEmployeeI9(component, index)}
          {component.type === "i9_employer" && renderEmployerI9(component, index)}
          {component.type === "documents" && renderDocuments(component, index)}
          {component.type === "forms" && renderForms(component, index)}
          {component.type === "certifications" && renderCertifications(component, index)}
          {component.type === "bank_account" && renderBankAccount(component, index)}
          {component.type === "custom" && renderCustom(component, index)}
        </div>
      </Reorder.Item>
    );
  };

  const renderPayrollCheckbox = () => {
    const setPayrollStatus = (e) => {
      setChecklistData({
        ...checklistData,
        milestones: { ...checklistData.milestones, payroll: e.target.checked },
        tasks: [...checklistData.tasks, ...addTasksChangeLockedStatuses("payroll")],
      });
    };
    return (
      <div className="flex" style={{ alignItems: "flex-start", marginBottom: 30 }}>
        <input
          style={{ marginTop: 4 }}
          type="checkbox"
          checked={checklistData.milestones["payroll"]}
          onChange={setPayrollStatus}
        />
        <div className={checklistStyles["milestone-container"]}>
          <span>{"Ensure new hire is ready for payroll."}</span>
        </div>
      </div>
    );
  };

  /* Components */

  const renderPhoneNumber = (component: OnboardingTaskComponent, index: number) => {
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
        rules={{ assignee: { admin_only: true, new_hire_only: false } }}
      ></ChecklistComponent>
    );
  };

  const renderEmploymentInformation = (component: OnboardingTaskComponent, index: number) => {
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
        rules={{ assignee: { admin_only: true, new_hire_only: false } }}
      ></ChecklistComponent>
    );
  };

  const renderGenericTask = (component: OnboardingTaskComponent, index: number) => {
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
      ></ChecklistComponent>
    );
  };

  const renderEmployeeI9 = (component: OnboardingTaskComponent, index: number) => {
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
        rules={{ assignee: { admin_only: false, new_hire_only: true } }}
      ></ChecklistComponent>
    );
  };

  const renderEmployerI9 = (component: OnboardingTaskComponent, index: number) => {
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
        rules={{ assignee: { admin_only: true, new_hire_only: false } }}
      ></ChecklistComponent>
    );
  };

  const renderDocuments = (component: OnboardingTaskComponent, index: number) => {
    const key = `components.${component._id}`;
    const documentActionKey = `${key}.metadata.document.action`;
    const documentIdKey = `${key}.metadata.document.document_id`;

    const documentMetaData =
      "document" in (component?.metadata || {})
        ? (component.metadata as unknown as { document: { action: string; document_id: string } })
        : null;
    const defaultDocumentAction = documentMetaData?.document?.action || "view";
    const defaultDocumentID = documentMetaData?.document?.document_id || undefined;

    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
        options={{ teamOnly: true }}
      >
        <div className={styles["miter-form-component-body"]}>
          <Formblock
            type="select"
            className="modal"
            name={documentActionKey}
            label="Action"
            errors={formErrors}
            disabled={false}
            editing={true}
            options={[
              { label: "View", value: "view" },
              { label: "Sign", value: "sign" },
            ]}
            requiredSelect={true}
            form={form}
            onChange={(e) => handleFieldChange("metadata.document.action", e.value, index)}
            defaultValue={defaultDocumentAction}
          />
          <Formblock
            type="select"
            className="modal"
            name={documentIdKey}
            label="Document"
            errors={formErrors}
            disabled={false}
            editing={true}
            options={documentOptions}
            requiredSelect={true}
            form={form}
            onChange={(e) => handleFieldChange("metadata.document.document_id", e.value, index)}
            defaultValue={defaultDocumentID}
            rules={{
              validate: requiredDocument,
            }}
            style={{ marginBottom: 25 }}
          />
        </div>
      </ChecklistComponent>
    );
  };

  const renderForms = (component: OnboardingTaskComponent, index: number) => {
    const key = `components.${component._id}`;
    const formIdKey = `${key}.metadata.form.form_id`;

    const defaultFormID =
      "form" in (component?.metadata || {})
        ? (component.metadata as unknown as { form: { form_id: string } }).form?.form_id
        : undefined;
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        <div className={styles["miter-form-component-body"]}>
          <Formblock
            type="select"
            className="modal"
            name={formIdKey}
            label="Form"
            errors={formErrors}
            disabled={false}
            editing={true}
            options={formOptions}
            requiredSelect={true}
            form={form}
            onChange={(e) => handleFieldChange("metadata.form.form_id", e.value, index)}
            defaultValue={defaultFormID}
            rules={{
              validate: requiredForm,
            }}
            style={{ marginBottom: 25 }}
          />
        </div>
      </ChecklistComponent>
    );
  };

  const renderCertifications = (component: OnboardingTaskComponent, index: number) => {
    const key = `components.${component._id}`;
    const certificationTypeIdKey = `${key}.metadata.certification.certfication_type_id`;

    const defaultCertificationTypeId =
      "certification" in (component?.metadata || {})
        ? (component.metadata as unknown as { certification: { certification_type_id: string } })
            .certification?.certification_type_id
        : undefined;
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
      >
        <div className={styles["miter-form-component-body"]}>
          <Formblock
            type="select"
            className="modal"
            name={certificationTypeIdKey}
            label="Certification"
            errors={formErrors}
            disabled={false}
            editing={true}
            options={certificationTypeOptions}
            requiredSelect={true}
            form={form}
            onChange={(e) =>
              handleFieldChange("metadata.certification.certification_type_id", e.value, index)
            }
            defaultValue={defaultCertificationTypeId}
            rules={{
              validate: requiredCertification,
            }}
            style={{ marginBottom: 25 }}
          />
        </div>
      </ChecklistComponent>
    );
  };

  const renderBankAccount = (component: OnboardingTaskComponent, index: number) => {
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
        rules={{ assignee: { admin_only: false, new_hire_only: false } }}
      ></ChecklistComponent>
    );
  };

  const renderCustom = (component: OnboardingTaskComponent, index: number) => {
    return (
      <ChecklistComponent
        component={component}
        onClick={handleSetActiveTaskComponent}
        index={index}
        onFieldChange={handleFieldChange}
        rules={{
          assignee: { admin_only: false, new_hire_only: false },
          description: { required: true },
        }}
      ></ChecklistComponent>
    );
  };

  return (
    <FormProvider {...form}>
      <WizardScreen name={name} onNext={onNext} setRef={handleSetRef}>
        <div className={styles["form-builder"]}>
          {renderHeaderForm()}
          <div className={styles["form-builder-sections"]}>{renderPayrollCheckbox()}</div>

          <div className={styles["form-builder-sections"]}>
            <Reorder.Group
              className={styles["form-builder-sections-container"]}
              axis="y"
              values={checklistData.tasks}
              onReorder={handleFieldsReorder}
              ref={sectionsRef}
            >
              {checklistData.tasks.map((component, index) => renderComponent(component, index))}
            </Reorder.Group>
          </div>

          {renderChecklistManager()}
        </div>
      </WizardScreen>
    </FormProvider>
  );
};

export default ChecklistBuilderScreen;

const determineLockStatus = (
  milestones: OnboardingChecklist["milestones"],
  taskType: OnboardingChecklist["tasks"][0]["type"]
) => {
  if (MAP_TASK_TO_MILESTONE[taskType] === "payroll") {
    return milestones.payroll;
  } else if (MAP_TASK_TO_MILESTONE[taskType] === "mobile_app") {
    return milestones.mobile_app;
  } else if (MAP_TASK_TO_MILESTONE[taskType] === "ach") {
    return milestones.ach;
  } else if (MAP_TASK_TO_MILESTONE[taskType] === "work_authorization") {
    return milestones.work_authorization;
  } else {
    return false;
  }
};

const buildDefaultValues = (checklist: OnboardingChecklist | undefined): OnboardingChecklistForm => {
  if (checklist && checklist.tasks.length > 0) {
    const tasks = checklist.tasks.map((task) => {
      // In mongo, we don't store a team member id for new hire assigned tasks
      const isNewHireAssigned = task.assignee.type === "new_hire";
      return {
        ...task,
        locked: determineLockStatus(checklist.milestones, task.type),
        assignee: {
          type: (isNewHireAssigned ? "new_hire" : "admin") as "admin" | "new_hire",
          user_id: isNewHireAssigned ? "new_hire" : task.assignee.user_id,
        },
      };
    });

    return {
      title: checklist?.title || "New hire checklist",
      tasks: tasks || [],
      milestones: checklist?.milestones || DEFAULT_MILESTONES,
    };
  }

  return {
    title: "New hire checklist",
    tasks: [],
    milestones: DEFAULT_MILESTONES,
  };
};

const buildDefaultField = (
  type: OnboardingTaskComponent["type"],
  onboardinChecklist: OnboardingChecklistForm
): OnboardingTaskComponent => {
  let metadata: OnboardingTaskComponent["metadata"] = null;
  if (type === "documents") {
    metadata = {
      document: {
        action: "view",
        document_id: "",
      },
    };
  } else if (type === "forms") {
    metadata = {
      form: {
        form_id: "",
      },
    };
  } else if (type === "certifications") {
    metadata = {
      certification: {
        certification_type_id: "",
      },
    };
  }
  return {
    _id: ObjectID().toHexString(),
    type: type,
    title: MAP_TASK_TO_ACTION_TITLE[type],
    description: MAP_TASK_TO_DESCRIPTION[type],
    assignee: generateAssignee(type),
    metadata,
    due_after: 3,
    archived: false,
    locked: determineLockStatus(onboardinChecklist.milestones, type),
  };
};

const defautToNewHireCompletion: OnboardingTaskType[] = [
  "personal_information",
  "eeo_information",
  "custom_fields",
  "phone_number",
  "payment_method",
  "withholding_information",
  "i9_employee",
  "documents",
  "forms",
  "bank_account",
  "certifications",
  "custom",
];

// Assignee is either the new hire (type = "new_hire" and user_id = "new_hire") or an admin (type = "admin", and user_id = ObjectId)
const generateAssignee = (type: OnboardingTask["type"]): OnboardingTask["assignee"] => {
  const newHireCompleted = defautToNewHireCompletion.includes(type);
  return {
    type: newHireCompleted ? "new_hire" : "admin",
    user_id: newHireCompleted ? "new_hire" : "",
  };
};
