import React, { useEffect, useRef } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { FormAddress, MiterAPI, UpdateTeamMemberParams } from "dashboard/miter";
import { useActiveCompanyId, useLookupTeam, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { isEmptyAddress, isValidAddress, sleep } from "dashboard/utils";
import { DateTime } from "luxon";
import { Option } from "ui/form/Input";
import { WizardTeamMember } from "./TeamMemberWizard";
import { formatToAddress, formatToFormAddress } from "miter-utils";
import { TeamMemberProfilePicture } from "miter-components";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberPersonalInfoForm = {
  ssn?: string;
  dob?: DateTime;
  address?: FormAddress | undefined;
  mailing_address?: FormAddress | undefined;
  language?: Option<"en" | "es">;
  use_same_address_for_mailing_address?: boolean;
};

export const TeamMemberPersonalInfoScreen: React.FC<Props> = ({ name, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const refetchTeam = useRefetchTeam();
  const isMounted = useRef(true);
  const lookupTeam = useLookupTeam();

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const form = useForm<TeamMemberPersonalInfoForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(teamMember),
  });

  const formData = useWatch<TeamMemberPersonalInfoForm>({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch, register } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  useEffect(() => {
    const newTeamMember = lookupTeam(teamMember?._id);
    if (newTeamMember) setTeamMember(newTeamMember);
  }, [lookupTeam]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberPersonalInfoForm) => {
    if (!activeCompanyId) throw new Error("No active role");

    const cleanAddress = formatToAddress(params.address);
    const addressIsEmpty = isEmptyAddress(cleanAddress);

    // If the address is not empty and is not valid, throw an error
    if (!addressIsEmpty && !isValidAddress(cleanAddress)) {
      throw new Error("Address is not valid. Please make sure all the required fields are filled out.");
    }

    const cleanMailingAddress = params.use_same_address_for_mailing_address
      ? null
      : formatToAddress(params.mailing_address);

    const mailingAddressIsEmpty = isEmptyAddress(cleanMailingAddress);

    // If the mailing address is not valid, throw an error
    if (!mailingAddressIsEmpty && !isValidAddress(cleanMailingAddress)) {
      throw new Error(
        "Mailing address is not valid. Please make sure all the required fields are filled out."
      );
    }

    const calculateMailingAddress = () => {
      if (cleanMailingAddress === null) return null;
      if (!mailingAddressIsEmpty) return cleanMailingAddress;
      return undefined;
    };

    const data: UpdateTeamMemberParams = {
      ssn: params.ssn,
      dob: params.dob?.toISODate(),
      address: !addressIsEmpty ? formatToAddress(params.address) : undefined,
      mailing_address: calculateMailingAddress(),
      language: params?.language?.value || "en",
      resume_wizard_index: curIndex + 1,
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberPersonalInfoForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");
      if (!activeCompanyId) throw new Error("No active company");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }

      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Personal information</h2>
          <p className={styles["subheader-description"]}>Add the team member&apos;s personal information</p>
        </div>
        <div className="form-section">
          {teamMember && <TeamMemberProfilePicture teamMember={teamMember} refetchTeam={refetchTeam} />}
          <Formblock
            label={`Birthday`}
            type="datetime"
            dateOnly={true}
            name="dob"
            placeholder="MM/DD/YYYY"
            form={form}
            className="modal wizard"
            editing={true}
            style={{ marginTop: 25 }}
          />
          <Formblock
            type="ssn"
            name="ssn"
            label="SSN"
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"XXX-XX-" + (teamMember?.ssn_last_four || "XXXX")}
          />
          <Formblock
            label={`Language preference`}
            labelInfo="This is the language that the team member will use the mobile app and team member portal in"
            type="select"
            name="language"
            form={form}
            className="modal wizard"
            editing={true}
            options={[
              { label: "English", value: "en" },
              { label: "Español", value: "es" },
            ]}
          />
          <Formblock
            label={`Address`}
            type="address"
            name="address"
            form={form}
            className="modal wizard"
            editing={true}
          />
          <Formblock
            form={form}
            name="use_same_address_for_mailing_address"
            type="checkbox"
            text="Use same address for mailing address"
            editing={true}
            register={register}
            className="modal"
            defaultValue={!!formData.use_same_address_for_mailing_address}
          />
          {!formData.use_same_address_for_mailing_address && (
            <Formblock
              label={`Mailing address`}
              type="address"
              name="mailing_address"
              form={form}
              className="modal wizard"
              editing={true}
            />
          )}
        </div>
      </div>
    </WizardScreen>
  );
};

/**
 * Default values helpers
 */

const formatLanguage = (language?: string) => {
  if (language === "es") {
    return { label: "Español", value: "es" as const };
  } else {
    return { label: "English", value: "en" as const };
  }
};

const buildDefaultValues = (teamMember?: WizardTeamMember): TeamMemberPersonalInfoForm => {
  if (!teamMember) return {};

  const dob = teamMember.dob ? DateTime.fromISO(teamMember.dob) : undefined;
  const address = formatToFormAddress(teamMember.address);
  const mailingAddress = formatToFormAddress(teamMember.mailing_address);
  const useSameAddressForMailingAddress = isEmptyAddress(teamMember.mailing_address);
  const language = formatLanguage(teamMember.language);

  return {
    dob,
    address,
    mailing_address: mailingAddress,
    use_same_address_for_mailing_address: useSameAddressForMailingAddress,
    language,
  };
};
