import { MiterAPI, TeamMember } from "dashboard/miter";
import React, { FC, MouseEventHandler, ReactElement, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { saveAs } from "file-saver";

import Empty from "../../assets/empty-photo-2.svg";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";
import styles from "./PhotoExplorer.module.css";
import { extensionToMimeType, getExtension } from "dashboard/utils";
import Notifier from "dashboard/utils/notifier";
import Pagination from "../shared/Pagination";
import { Button, DeleteModal } from "ui";
import _ from "lodash";
import printJS from "print-js";
import EmptyState from "ui/empty-state/EmptyState";

export type Photo = {
  id: string;
  url: string;
  clock_in?: number;
  date?: string;
  team_member?: TeamMember;
  selected?: boolean;
  photo_id_type?: "clock_in" | "clock_out";
};

type Props = {
  photos: Photo[];
  searchPlaceholder?: string;
  version?: string;
  showUpload?: boolean;
  handleUpload?: MouseEventHandler<HTMLButtonElement>;
  showDelete?: boolean;
  hideActionBar?: boolean;
  handleDelete?: (photos: Photo[]) => Promise<void>;
  hideCaption?: boolean;
  hideSearch?: boolean;
  photoClassName?: string;
  containerClassName?: string;
  actionsClassName?: string;
  paginationClassName?: string;
  emptyStateHeader?: string;
  emptyStateSubheader?: string;
  emptyStateClassName?: string;
};

const PhotoExplorer: FC<Props> = ({
  photos,
  searchPlaceholder,
  version,
  showUpload,
  hideActionBar,
  hideSearch,
  handleUpload,
  showDelete,
  handleDelete,
  hideCaption,
  photoClassName,
  containerClassName,
  actionsClassName,
  paginationClassName,
  emptyStateHeader,
  emptyStateSubheader,
  emptyStateClassName,
}) => {
  const [allPhotos, setAllPhotos] = useState<Photo[]>(_.cloneDeep(photos));

  const [search, setSearch] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);

  const [openedLightbox, setOpenedLightbox] = useState<boolean>(false);
  const [lightboxPhoto, setLightboxPhoto] = useState<number>(0);
  const [downloadingPhotos, setDownloadingPhotos] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [printingPhotos, setPrintingPhotos] = useState<boolean>(false);

  const PAGE_LENGTH = 50;

  // Filtered photos is a list of all the photos that match the search filter (if there is one)
  const filteredPhotos =
    search && search.length > 0
      ? allPhotos.filter((photo) => JSON.stringify(photo).toLowerCase().includes(search.toLowerCase()))
      : allPhotos;

  // Selected photos are photos that have been selected for an action (such as downloading)
  const selectedPhotos = filteredPhotos.filter((photo) => photo.selected);

  // Visible photos are the photos that are visible on the current page
  const visiblePhotos = filteredPhotos.slice(page * PAGE_LENGTH, page * PAGE_LENGTH + PAGE_LENGTH);

  // Keeps this components list of photos up to date with the list of photos from the props
  useEffect(() => {
    setAllPhotos(_.cloneDeep(photos));
  }, [JSON.stringify(photos)]);

  const createFileNameWithExt = (originalName: string, fileType: string) => {
    if (originalName.includes(".")) {
      return originalName;
    }
    const ext = getExtension(fileType);
    if (ext) {
      return originalName + ext;
    }
    return "";
  };

  const handleDownload = async () => {
    setDownloadingPhotos(true);
    try {
      const selectedIds = selectedPhotos.map((photo) => photo.id);
      const responseData = await MiterAPI.files.download(selectedIds);

      if (responseData.error) {
        throw responseData.error;
      }

      let originalNameWithExt = createFileNameWithExt(responseData.originalname, responseData.type);

      // Assume image is jpeg if no extension is found
      if (!originalNameWithExt) {
        originalNameWithExt = responseData.originalname + ".jpeg";
      }

      saveAs(
        // @ts-expect-error Blob issue
        new Blob([Uint8Array.from(responseData.data.data).buffer], {
          type: extensionToMimeType(originalNameWithExt),
        }),
        originalNameWithExt
      );

      if (selectedPhotos.length > 1) {
        Notifier.success(`The photos downloaded successfully`);
      } else {
        Notifier.success(`The photo downloaded successfully`);
      }
    } catch (e) {
      console.log(e);

      if (selectedPhotos.length > 1) {
        Notifier.error(`There was an error downloading the photos. We're looking into it.`);
      } else {
        Notifier.error(`There was an error downloading the photo. We're looking into it.`);
      }
    }

    setDownloadingPhotos(false);
  };

  const handlePrint = async () => {
    setPrintingPhotos(true);
    try {
      const selectedIds = selectedPhotos.map((photo) => photo.id);
      const responseData = await MiterAPI.files.download(selectedIds);

      if (responseData.error) {
        throw responseData.error;
      }

      let originalNameWithExt = createFileNameWithExt(responseData.originalname, responseData.type);

      // Assume image is jpeg if no extension is found
      if (!originalNameWithExt) {
        originalNameWithExt = responseData.originalname + ".jpeg";
      }

      // @ts-expect-error Blob issue
      const blob = new Blob([Uint8Array.from(responseData.data.data).buffer], {
        type: extensionToMimeType(originalNameWithExt),
      });

      const url = URL.createObjectURL(blob);
      printJS(url, "image");

      if (selectedPhotos.length > 1) {
        Notifier.success(`The photos printed successfully`);
      } else {
        Notifier.success(`The photo printed successfully`);
      }
    } catch (e) {
      console.log(e);

      if (selectedPhotos.length > 1) {
        Notifier.error(`There was an error printing the photos. We're looking into it.`);
      } else {
        Notifier.error(`There was an error printing the photo. We're looking into it.`);
      }
    }

    setPrintingPhotos(false);
  };

  const handleSelectAll = () => {
    setAllPhotos(allPhotos.map((photo) => ({ ...photo, selected: true })));
  };

  const handleClear = () => {
    setAllPhotos(allPhotos.map((photo) => ({ ...photo, selected: false })));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePhotoSelect = (index) => {
    const paginatedIndex = page * PAGE_LENGTH + index;
    const newPhotos = [...allPhotos];

    if (paginatedIndex >= newPhotos.length) return;

    newPhotos[paginatedIndex]!.selected = !newPhotos[paginatedIndex]!.selected;
    setAllPhotos(newPhotos);
  };

  const handlePhotoClick = (index) => {
    const paginatedIndex = page * PAGE_LENGTH + index;
    setLightboxPhoto(paginatedIndex);
    setOpenedLightbox(true);
  };

  const handlePageChange = (newPage) => {
    scrollTo(0, 0);
    setPage(newPage.selected);
  };

  const buildCaption = () => {
    if (hideCaption) return "";
    if (allPhotos[lightboxPhoto!]?.team_member) {
      return (
        "Taken by " +
        allPhotos[lightboxPhoto!]?.team_member?.full_name +
        " on " +
        filteredPhotos[lightboxPhoto!]?.date
      );
    }

    return "";
  };

  const renderLightbox = (): ReactElement => {
    const imageCaption = buildCaption();

    return (
      <Lightbox
        mainSrc={filteredPhotos[lightboxPhoto!]!.url}
        imageCaption={imageCaption}
        nextSrc={filteredPhotos[(lightboxPhoto + 1) % filteredPhotos.length]?.url}
        prevSrc={filteredPhotos[(lightboxPhoto + filteredPhotos.length - 1) % filteredPhotos.length]?.url}
        onCloseRequest={() => setOpenedLightbox(false)}
        onMovePrevRequest={() =>
          setLightboxPhoto((lightboxPhoto + filteredPhotos.length - 1) % filteredPhotos.length)
        }
        onMoveNextRequest={() => setLightboxPhoto((lightboxPhoto + 1) % filteredPhotos.length)}
      />
    );
  };

  const renderActionButtons = (): ReactElement => {
    if (selectedPhotos.length > 0) {
      return (
        <>
          <div className={styles["selected-text"]}>
            {selectedPhotos.length} selected {!hideSearch && " | "}
          </div>

          <Button className="button-1" text="Clear" onClick={handleClear} />
          <Button className="button-2" text="Download" onClick={handleDownload} loading={downloadingPhotos} />
          {selectedPhotos.length === 1 && (
            <Button className="button-2" text="Print" onClick={handlePrint} loading={printingPhotos} />
          )}

          {showDelete && handleDelete && (
            <Button className="button-3" text="Delete" onClick={() => setShowDeleteModal(true)} />
          )}
        </>
      );
    } else {
      return (
        <>
          {filteredPhotos.length > 0 && (
            <Button
              className={showUpload ? "button-1" : "button-2 no-margin"}
              text="Select all"
              onClick={handleSelectAll}
            />
          )}
          {showUpload && handleUpload && (
            <Button className="button-2 no-margin" text="Upload" onClick={handleUpload} />
          )}
        </>
      );
    }
  };

  const renderActionBar = (): ReactElement => {
    return hideActionBar ? (
      <></>
    ) : (
      <div className={styles["action-bar"] + " " + actionsClassName}>
        <div className={styles["action-bar-left"]}>
          {version === "modal" ? (
            <h3>Pictures</h3>
          ) : (
            filteredPhotos?.length > 0 &&
            !hideSearch && (
              <input
                type="text"
                className={"form2-text " + styles["search"]}
                placeholder={searchPlaceholder ? searchPlaceholder : "Search photos"}
                onChange={handleSearch}
              />
            )
          )}
        </div>
        <div className={hideSearch ? styles["action-bar-left"] : styles["action-bar-right"]}>
          {renderActionButtons()}
        </div>
      </div>
    );
  };

  const renderPhotos = (): ReactElement => {
    if (filteredPhotos.length === 0) {
      return (
        <EmptyState
          image={Empty}
          title={emptyStateHeader || "No photos found"}
          description={
            emptyStateSubheader ||
            (version === "modal" ? undefined : "Photos uploaded to timesheets on this job will appear here.")
          }
          type={version === "modal" ? "modal" : undefined}
          className={emptyStateClassName}
        />
      );
    }

    const photoElements = visiblePhotos.map((photo, index) => {
      const paginatedIndex = page * PAGE_LENGTH + index;
      return (
        <div style={{ marginRight: 5, marginBottom: 5 }} key={photo.url}>
          <div className={styles["photo"] + " " + photoClassName}>
            <button className={styles["photo-select"]} onClick={() => handlePhotoSelect(index)}>
              <FaCheckCircle
                className={styles["photo-select-icon"] + " " + (photo.selected ? styles["selected"] : "")}
              />
            </button>
            <div
              onClick={() => handlePhotoClick(index)}
              className={styles["photo-overlay"] + " " + (photo.selected ? styles["selected"] : "")}
            >
              {!hideCaption && (
                <span>
                  <strong>{allPhotos[paginatedIndex!]?.team_member?.full_name || ""} </strong>
                  <br />
                  {allPhotos[paginatedIndex!]?.date || ""}
                </span>
              )}
            </div>
            <img
              className={photo.selected ? styles["selected"] : ""}
              src={photo.url}
              onError={(e) => (e.currentTarget!.parentElement!.style.display = "none")}
            />
          </div>

          {photo?.photo_id_type && (
            <div style={{ textAlign: "center", marginTop: "15px", fontWeight: "bold" }}>
              {photo.photo_id_type === "clock_in" ? "Clock in" : "Clock out"}
            </div>
          )}
        </div>
      );
    });

    return <div className={styles["photos"]}>{photoElements}</div>;
  };

  const renderPagination = (): ReactElement => {
    return (
      <div className={paginationClassName}>
        <Pagination handlePageChange={handlePageChange} pageCount={filteredPhotos.length / PAGE_LENGTH} />
      </div>
    );
  };

  return (
    <div className={styles["container"] + " " + (version ? styles[version] : "") + " " + containerClassName}>
      {showDeleteModal && handleDelete && (
        <DeleteModal
          header={"Delete photos"}
          body={"Are you sure you want to delete the selected photos?"}
          onHide={() => setShowDeleteModal(false)}
          onDelete={() => handleDelete(selectedPhotos)}
        />
      )}
      {renderActionBar()}
      {renderPhotos()}
      {filteredPhotos.length > 0 && renderPagination()}
      {openedLightbox && renderLightbox()}
    </div>
  );
};

export default PhotoExplorer;
