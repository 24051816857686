import { useContext, useMemo, useState } from "react";
import { Notifier } from "ui";
import { useActiveCompanyId } from "./atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import AppContext from "dashboard/contexts/app-context";

const AstradaModalNotifierErrorMessage = "Your card cannot be linked right now. We're looking into it!";
const handleGetAccessToken = async () => {
  try {
    const response = await MiterAPI.astrada.request_access_token();
    if (response.error) throw new Error(response.error);
    return response.access_token;
  } catch (err) {
    Notifier.error(AstradaModalNotifierErrorMessage);
  }
};

export const useAstradaModal = (params: {
  cardProgramId?: string;
  onSuccess: () => Promise<void>;
}): {
  openAstradaModal: () => Promise<void>;
  isAstradaModalLoading: boolean;
} => {
  const { cardProgramId, onSuccess } = params;

  const activeCompanyId = useActiveCompanyId();
  const integrationLookup = useHasIntegration();
  let astradaIntegrationConnection = integrationLookup.get("astrada");
  const { getIntegrations } = useContext(AppContext);

  const [isAstradaModalLoading, setIsAstradaModalLoading] = useState(false);

  const handleConnectNewCard = async (data: { subscriptionId: string }) => {
    try {
      const response = await MiterAPI.astrada.connect_new_card(data.subscriptionId, cardProgramId);
      if (response.error) throw new Error(response.error);
      Notifier.success(
        `Linked card ending in ${response.last_four}. Future transactions will be automatically synced to Miter.`
      );
    } catch (err) {
      console.error(`Astrada error: card connected to Astrada but failed to be saved to Miter.`, {
        subscriptionId: data.subscriptionId,
        err: JSON.stringify(err),
      });
      Notifier.error(AstradaModalNotifierErrorMessage);
    }
    onSuccess();
  };

  const openAstradaModal = async () => {
    setIsAstradaModalLoading(true);

    // set up new Astrada subaccount for this company
    if (!astradaIntegrationConnection?.metadata?.astrada?.subaccount_id) {
      try {
        const response = await MiterAPI.astrada.create_subaccount();
        if (response.error) throw new Error(response.error);
        astradaIntegrationConnection = response;

        // this will update integrations, so if the user closes the Astrada modal and tries again without refreshing, we'll have the subaccount_id
        await getIntegrations();
      } catch (err) {
        Notifier.error(AstradaModalNotifierErrorMessage);
        setIsAstradaModalLoading(false);
        return;
      }
    }

    try {
      // @ts-expect-error CardEnrollmentSdk not defined
      window.CardEnrollmentSdk.openForm({
        companyName: "Miter",
        subaccountId: astradaIntegrationConnection?.metadata?.astrada?.subaccount_id,
        getAccessToken: handleGetAccessToken,
        onSuccess: handleConnectNewCard,
      });
    } catch (e) {
      Notifier.error(AstradaModalNotifierErrorMessage);
    }
    setIsAstradaModalLoading(false);
  };

  return useMemo(
    () => ({ openAstradaModal, isAstradaModalLoading }),
    [isAstradaModalLoading, activeCompanyId]
  );
};
