import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";

import "ag-grid-enterprise";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { DateRange } from "ui/form/DateRangePicker";
import { reportList } from "../reportList";
import { agGridIsoDateComparator, toDollarFormat } from "miter-utils";
import { ReimbursementReportRow } from "backend/utils/reports/reimbursementsReport";
import { ColDef, GridApi } from "ag-grid-enterprise";
import { useActiveCompanyId, useLookupJob, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { useExpenseReimbursementAbilities } from "dashboard/hooks/abilities-hooks/useExpenseReimbursementAbilities";

export type TableEntry = ReimbursementReportRow;

export const ReimbursementsReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const reimbursementAbilities = useExpenseReimbursementAbilities();

  const lookupTeam = useLookupTeam();
  const lookupJob = useLookupJob();

  // State
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ months: 1 }),
    end: DateTime.now(),
  });

  const [data, setData] = useState<TableEntry[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();

  const getData = async () => {
    gridApi?.showLoadingOverlay();
    try {
      const payload = {
        type: "reimbursements",
        params: {
          start_date: dateRange.start?.toISODate(),
          end_date: dateRange.end?.toISODate(),
          company_id: activeCompanyId!,
        },
        format: "json",
      };

      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const filteredData = response.filter((entry: ReimbursementReportRow) => {
        const teamMember = lookupTeam(entry.tmId);
        const job = lookupJob(entry.jobId);

        return (
          (!teamMember || reimbursementAbilities.teamPredicate("read")(teamMember)) &&
          (!job || reimbursementAbilities.jobPredicate("read")(job))
        );
      });

      setData(filteredData);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    gridApi?.hideOverlay();
  };

  useEffect(() => {
    if (gridApi) getData();
  }, [!!gridApi, dateRange]);

  const reportObject = reportList.find((report) => report.slug === "reimbursements");

  const fileName =
    "Miter Reimbursements Report Summary " +
    dateRange.start?.toISODate() +
    " - " +
    dateRange.end?.toISODate();

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <div className="flex">
          <h1 style={{ margin: 0 }}>Reimbursements report</h1>
        </div>
      </div>
      <div className="report-page-description">{reportObject?.description}*</div>
      <div className="vertical-spacer-small"></div>
      <AgGridTable
        reportId="reimbursements-summary"
        dateRange={dateRange}
        dateRangeLabel="Payday range"
        onDateRangeChange={setDateRange}
        data={data}
        columnDefs={colDefs}
        fileName={fileName}
        setGridApi={setGridApi}
      />
      <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
        * Includes reimbursements associated with paid, processing, and pending payrolls.
      </div>
      <div className="vertical-spacer-large"></div>
    </div>
  );
};

const colDefs: ColDef<TableEntry>[] = [
  {
    colId: "tmName",
    headerName: "Team member",
    filter: true,
    enableRowGroup: true,
    valueGetter: (params) => {
      if (!params.data) return "";
      return `${params.data.tmFirstName} ${params.data.tmLastName}`;
    },
  },
  {
    colId: "tmNameLastFirst",
    headerName: "Team member (last, first)",
    filter: true,
    enableRowGroup: true,
    valueGetter: (params) => {
      if (!params.data) return "";
      return `${params.data.tmLastName}, ${params.data.tmFirstName}`;
    },
  },
  {
    field: "description",
    filter: true,
    headerName: "Description",
    enableRowGroup: true,
  },
  {
    field: "amount",
    headerName: "Amount",
    filter: "agNumberColumnFilter",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "payday",
    headerName: "Payday",
    enableRowGroup: true,
    filter: "agDateColumnFilter",
    filterParams: { comparator: agGridIsoDateComparator },
  },
  {
    field: "jobName",
    filter: true,
    headerName: "Job",
    enableRowGroup: true,
  },
  {
    field: "jobCode",
    filter: true,
    headerName: "Job code",
    enableRowGroup: true,
  },
  {
    field: "activityName",
    filter: true,
    headerName: "Activity",
    enableRowGroup: true,
  },
  {
    field: "costCode",
    filter: true,
    headerName: "Cost code",
    enableRowGroup: true,
  },
  {
    field: "departmentName",
    filter: true,
    headerName: "Department",
    enableRowGroup: true,
  },
  {
    field: "departmentCode",
    filter: true,
    headerName: "Department ID",
    enableRowGroup: true,
  },
  {
    field: "typeLabel",
    filter: true,
    headerName: "Type",
    enableRowGroup: true,
  },
  {
    field: "date",
    headerName: "Date",
    enableRowGroup: true,
    filter: "agDateColumnFilter",
    filterParams: { comparator: agGridIsoDateComparator },
  },
];
