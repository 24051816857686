import { useCallback } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedJob, AggregatedTeamMember, Job, Payroll } from "dashboard/miter";
import { CardManagementTableRow } from "dashboard/pages/expenses/CardManagementTable";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * THIRD PARTY CARDS
 */
type PayrollParams = Payroll | CardManagementTableRow | CardManagementTableRow[];

type PayrollAction = "create" | "read" | "update" | "delete";
type PayrollAbilities = {
  can: (action: PayrollAction, items: PayrollParams) => boolean;
  cannot: (action: PayrollAction, items: PayrollParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: PayrollAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: PayrollAction) => (job: AggregatedJob | Job) => boolean;
};

// TODO: UPDATE THIS ENTIRE FILE
export const usePayrollAbilities = (): PayrollAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: PayrollAction, items?: PayrollParams) => {
      if (!items) return false;

      const payrolls = Array.isArray(items) ? items : [items];

      return payrolls.every((_payroll) => {
        switch (action) {
          case "create":
            return can_("payrolls:create");
          case "read":
            return can_("payrolls:read");
          case "update":
            return can_("payrolls:update");
          case "delete":
            return can_("payrolls:delete");
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: PayrollAction, items: PayrollParams) => {
      return !can(action, items);
    },
    [can]
  );

  /**
   * TODO: CHANGE
   * Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "payrolls",
    teamMemberField: { fieldName: "team_member_id", fieldType: "string" },
    appModule: "expense_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<PayrollAction>("payrolls", true);

  /** Jjob predicate */
  const jobPredicate = useAbilitiesJobPredicate<PayrollAction>("payrolls", true);

  return { can, cannot, filter, teamPredicate, jobPredicate };
};
