import BillsTable from "dashboard/components/bills/BillsTable";
import React, { FC } from "react";
import { Helmet } from "react-helmet";

type Props = {
  billId?: string;
};

const BillPay: FC<Props> = ({ billId }) => {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Bill Pay | Miter</title>
      </Helmet>
      <BillsTable billId={billId} showToggler={true} />
    </div>
  );
};

export default BillPay;
