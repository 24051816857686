import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { Formblock, Button } from "ui";
import AppContext from "dashboard/contexts/app-context";
import {
  ClientCredentials,
  useExchangeClientCredentials,
} from "dashboard/hooks/integrations/useExchangeClientCredentials";
import { MiterIntegrationForCompany } from "dashboard/miter";

export const BuildOpsAuthentication: React.FC<{
  integration: MiterIntegrationForCompany;
  onAuth: () => Promise<void>;
}> = ({ integration, onAuth }) => {
  const form = useForm();
  const { getIntegrations } = useContext(AppContext);

  const { submitClientSecret, submitting } = useExchangeClientCredentials(integration);

  const exchangeClientCredentialsCallback = useCallback(async () => {
    // In order, so status is updated before the page refreshes and shows the normal status page!
    await onAuth();
    await getIntegrations();
  }, []);

  const handleSubmit = async (data: ClientCredentials) => {
    await submitClientSecret(data, exchangeClientCredentialsCallback);
  };

  return (
    <div style={{ border: "1px solid lightgray", padding: 20, borderRadius: 5 }}>
      <div className="vertical-spacer" />
      <Formblock
        label="Client ID"
        name="clientId"
        type="password"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <Formblock
        label="Client Secret"
        name="clientSecret"
        type="password"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <Formblock
        label="Tenant ID"
        name="tenantId"
        type="text"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <div className="vertical-spacer-small"></div>
      <Button
        text="Connect"
        onClick={form.handleSubmit(handleSubmit)}
        className="button-2 no-margin"
        loading={submitting}
      />
    </div>
  );
};
