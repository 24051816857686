import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import {
  useActiveCompanyId,
  useActiveCompany,
  useActiveTeam,
  useActiveTeamMember,
  useLookupTeam,
  useSetActiveLiveTimesheetUpdateLocal,
  useActiveLiveTimesheetUpdateLocal,
} from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import LiveTimesheetModal from "dashboard/pages/timesheets/ViewTimesheet/LiveTimesheetModal";
import {
  cleanLiveTimesheetsFromBackend,
  creationMethodBadgeLookup,
  LiveTimesheetTableEntry,
} from "dashboard/utils/timesheetUtils";
import { DateTime } from "luxon";
import { ArrowsClockwise } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { Button, Formblock, Notifier, Toggler } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import styles from "./LiveTimesheetsTable.module.css";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { FailureItem } from "dashboard/components/shared/FailuresModal";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

type Props = {
  activeTeamMember?: string;
  activeJob?: string;
  setLastRefreshed: (lastRefreshed: DateTime) => void;
};

export const LiveTimesheetsTable: React.FC<Props> = ({ activeTeamMember, activeJob, setLastRefreshed }) => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const loggedInTeamMember = useActiveTeamMember();
  const teamMemberList = useActiveTeam();
  const navigate = useNavigate();
  const abilities = useMiterAbilities();
  const timesheetAbilities = useTimesheetAbilities();
  const lookupTeam = useLookupTeam();
  const liveTimesheetUpdate = useActiveLiveTimesheetUpdateLocal();
  const setActiveLiveTimesheetUpdate = useSetActiveLiveTimesheetUpdateLocal();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();

  const [data, setData] = useState<LiveTimesheetTableEntry[]>();
  const [selectedRow, setSelectedRow] = useState<LiveTimesheetTableEntry>();
  const [selectedRows, setSelectedRows] = useState<LiveTimesheetTableEntry[]>([]);
  const [activeTab, setActiveTab] = useState<string>("clocked-in");
  const [showBulkClockOutPopover, setShowBulkClockOutPopover] = useState<boolean>(false);
  const [bulkClockOutHours, setBulkClockOutHours] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const showAllTeamMembers = !!activeCompany?.settings.timesheets.show_all_team_members_in_live_view;

  const activeTeam = useMemo(
    () => teamMemberList.filter(timesheetAbilities.teamPredicate("read")),
    [teamMemberList, timesheetAbilities.teamPredicate]
  );

  // Get the non clocked in team members so we can populate the non clocked in team members table
  const inactiveTeam = useMemo(() => {
    if (!showAllTeamMembers) return [];

    const clockedInTeamMembersSet = new Set(data?.map((timesheet) => timesheet.team_member_id));
    return activeTeam.filter((teamMember) => !clockedInTeamMembersSet.has(teamMember._id));
  }, [activeTeam, data]);

  useEffect(() => {
    getLiveTimesheets();
  }, [!!activeCompanyId]);

  /*********************************************************
    Handlers
  **********************************************************/
  const handleRowClick = (row: LiveTimesheetTableEntry) => {
    setSelectedRow(row);
  };

  const handleHideModal = (refresh?: boolean) => {
    if (refresh) getLiveTimesheets();
    setSelectedRow(undefined);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const columns: ColumnConfig<LiveTimesheetTableEntry>[] = useMemo(() => {
    const baseColumns: ColumnConfig<LiveTimesheetTableEntry>[] = [
      {
        field: "team_member.full_name",
        headerName: "Team member",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
      },
      {
        field: "job.name",
        headerName: "Job",
        dataType: "string",
        minWidth: 150,
        enableRowGroup: true,
      },
      {
        field: "activity.label",
        headerName: "Activity",
        dataType: "string",
        minWidth: 150,
        enableRowGroup: true,
      },
      {
        field: "creation_method",
        headerName: "Creation method",
        dataType: "string",
        displayType: "badge",
        minWidth: 155,
        colors: {
          "Admin entry": "green",
          "App manual entry": "gray",
          "App clock in": "blue",
          Kiosk: "light-purple",
          "Dashboard clock in": "light-blue",
        },
        filter: false,
        sortable: false,
        enableRowGroup: true,
        valueGetter: (params) => creationMethodBadgeLookup?.[params.data?.creation_method || ""]?.label || "",
      },
      {
        field: "clock_in",
        headerName: "In",
        dataType: "date",
        dateType: "timestamp",
        minWidth: 120,
        cellRenderer: (params) => params.data?.clock_in_string || "-",
      },
    ];

    if (activeCompany?.settings.timesheets.geolocation) {
      baseColumns.push({
        field: "geofence_status",
        headerName: "GPS",
        dataType: "component",
        headerTooltip: "Indicates the timesheet's geofence status, if applicable.",
        minWidth: 60,
        valueFormatter: (params) => params.value,
      });
    }

    if (hasAccessToEquipmentTracking) {
      baseColumns.splice(3, 0, {
        field: "equipment",
        headerName: "Equipment",
        dataType: "string",
        valueGetter: (params) => params.data?.equipment?.map((e) => e.name).join(", ") || "-",
        minWidth: 200,
      });
    }

    if (activeCompany?.settings.timesheets.break_name_into_first_and_last) {
      // Hide team_member.full_name column if we're showing first and last name columns
      const teamMemberColumnIndex = baseColumns.findIndex((c) => c.field === "team_member.full_name");
      if (teamMemberColumnIndex > -1) {
        baseColumns[teamMemberColumnIndex] = {
          ...(baseColumns[teamMemberColumnIndex] as $TSFixMe),
          hide: true,
        };
      }

      baseColumns.splice(2, 0, {
        field: "team_member.first_name",
        headerName: "First name",
        dataType: "string",
        minWidth: 100,
        sortField: "team_member.full_name",
      });

      baseColumns.splice(3, 0, {
        field: "team_member.last_name",
        headerName: "Last name",
        dataType: "string",
        minWidth: 120,
      });
    }

    return baseColumns;
  }, []);

  const inactiveTeamColumns: ColumnConfig<AggregatedTeamMember>[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "full_name",
        dataType: "string",
        onCellClicked: (params) => {
          if (params.data?._id) {
            navigate(`/team-members/${params.data._id}`);
          }
        },
      },
      {
        headerName: "Title",
        field: "title",
        dataType: "string",
      },
      {
        headerName: "Department",
        field: "department.name",
        dataType: "string",
      },
    ],
    []
  );

  const buildBulkLiveTimesheetPopover = useMemo(() => {
    const popoverFunction = () => {
      if (selectedRows.length === 0) return <></>;

      const bulkClockOutPopoverContent = (
        <div className={styles["bulk-clock-out-container"]}>
          <div className="flex">
            <div>Set clock out to </div>
            <Formblock
              type="number"
              value={bulkClockOutHours}
              min={0}
              max={24}
              onChange={(e) => {
                setBulkClockOutHours(e.target.value);
              }}
              editing={true}
              style={{ width: "50px", paddingLeft: "10px", paddingRight: "10px", marginTop: "-5px" }}
            />
            <div> hours after clock in.</div>
          </div>
          <div className={styles["flex-row-reverse"]}>
            <Button className="button-2 no-margin" onClick={() => handleBulkClockOut()} loading={loading}>
              Clock out
            </Button>
            <Button className="button-1" onClick={() => setShowBulkClockOutPopover(false)}>
              Cancel
            </Button>
          </div>
        </div>
      );

      return (
        <Popover
          isOpen={showBulkClockOutPopover}
          positions={["bottom"]}
          containerStyle={{ zIndex: "5" }}
          align="end"
          content={bulkClockOutPopoverContent}
        >
          <button
            className="button-1 no-margin"
            onClick={() => setShowBulkClockOutPopover(!showBulkClockOutPopover)}
          >
            Bulk clock out
          </button>
        </Popover>
      );
    };

    return popoverFunction;
  }, [selectedRows.length, bulkClockOutHours, loading, showBulkClockOutPopover]);
  /*********************************************************
    Config variables for the toggler
  **********************************************************/

  const togglerConfig = useMemo(() => {
    return [
      {
        label: "Clocked in",
        path: "clocked-in",
      },
      {
        label: "Clocked out",
        path: "inactive",
      },
    ];
  }, [activeCompany]);

  const getLiveTimesheets = async () => {
    try {
      const filter = [{ field: "company_id", value: activeCompanyId }];
      if (activeTeamMember) filter.push({ field: "team_member_id", value: activeTeamMember });
      if (activeJob) filter.push({ field: "job_id", value: activeJob });
      const radiusMiles = activeCompany?.settings.timesheets.geofence_radius;

      const liveTimesheets = await MiterAPI.live_timesheets.list({ filter });
      if (liveTimesheets.error) throw new Error(liveTimesheets.error);

      const filteredLiveTimesheets = liveTimesheets.filter((timesheet) => {
        const teamMember = lookupTeam(timesheet.team_member_id);
        const isPersonal = timesheet.team_member_id === loggedInTeamMember?._id;

        const hasAccessToTeamMember = teamMember && timesheetAbilities.teamPredicate("read")(teamMember);
        if (abilities.can("timesheets:others:clock_in") && !isPersonal && hasAccessToTeamMember) {
          return true;
        }

        if (abilities.can("timesheets:personal:clock_in") && isPersonal) {
          return true;
        }

        return false;
      });

      setData(cleanLiveTimesheetsFromBackend(filteredLiveTimesheets, radiusMiles));
      setLastRefreshed(DateTime.now());
    } catch (error) {
      Notifier.error("Error fetching live timesheets. Please contact support.");
      console.error(error);
    }
  };

  const bulkClockOutLiveTimesheets = async (): Promise<FailureItem[]> => {
    const errorArray: FailureItem[] = [];
    await Promise.all(
      selectedRows.map(async (liveTimesheet) => {
        const clockOut = liveTimesheet?.clock_in + bulkClockOutHours * 60 * 60;
        const resp = await MiterAPI.live_timesheets.clock_out(liveTimesheet._id, clockOut);
        if (resp.error) {
          errorArray.push({
            label: "Error clocking out " + liveTimesheet.team_member.full_name,
            message: resp.error,
          });
        }
        if (liveTimesheet.creation_method === "dashboard_clock_in") {
          if (liveTimesheetUpdate && loggedInTeamMember?._id === liveTimesheet.team_member_id) {
            setActiveLiveTimesheetUpdate({
              key: liveTimesheetUpdate?.key,
              value: (liveTimesheetUpdate?.value || 0) + 1,
            });
          }
        }
      })
    );
    return errorArray;
  };

  const handleBulkClockOut = async () => {
    if (selectedRows.length === 0) return;

    setLoading(true);
    try {
      const errorArray = await bulkClockOutLiveTimesheets();

      if (errorArray.length === 0) {
        Notifier.success(
          "Clocked timesheets out successfully. Team members may need to refresh their apps to see their updated timesheets."
        );
      } else {
        setFailures(errorArray);
      }
      getLiveTimesheets();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const staticActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "Refresh",
        className: "button-2 no-margin",
        action: getLiveTimesheets,
        important: true,
        icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, []);

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        key: "bulk-clock-out",
        label: "Bulk clock out",
        className: "button-2 no-margin",
        component: buildBulkLiveTimesheetPopover(),
      },
    ],
    [buildBulkLiveTimesheetPopover]
  );

  const handleSelect = (selected: LiveTimesheetTableEntry[]) => {
    setSelectedRows(selected);
  };

  return (
    <>
      {showAllTeamMembers &&
        (abilities.can("timesheets:others:clock_in") || abilities.can("timesheets:personal:clock_in")) && (
          <Toggler config={togglerConfig} secondary={true} active={activeTab} toggle={setActiveTab} />
        )}
      {(!showAllTeamMembers || activeTab === "clocked-in") && (
        <TableV2
          id={"live-timesheets-table"}
          resource="timesheets"
          data={data}
          columns={columns}
          onClick={handleRowClick}
          showReportViews={true}
          staticActions={staticActions}
          dynamicActions={dynamicActions}
          onSelect={handleSelect}
        />
      )}
      {showAllTeamMembers && activeTab === "inactive" && (
        <TableV2
          id={"live-timesheets-inactive-team-members-table"}
          resource="team members"
          data={inactiveTeam}
          columns={inactiveTeamColumns}
        />
      )}
      {selectedRow && <LiveTimesheetModal id={selectedRow._id} hide={handleHideModal} />}
      {renderFailuresModal()}
    </>
  );
};
