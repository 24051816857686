import React, { useCallback, useEffect, useMemo, useState } from "react";
import { reportList } from "../reportList";
import { useForm } from "react-hook-form";
import { Formblock, TableV2 } from "ui";
import Notifier from "dashboard/utils/notifier";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";

import "ag-grid-enterprise";
import "../reports.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Loader } from "ui";
import {
  useActiveCompanyId,
  useLookupTeam,
  useLookupTimeOffPolicy,
  useTeamOptions,
  useTimeOffPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { capitalize } from "lodash";
import ObjectID from "bson-objectid";
import { ColumnConfig } from "ui/table-v2/Table";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";
import { DateTime } from "luxon";

type TimeOffBalanceEntry = {
  _id: string;
  tmId: string;
  tmFriendlyId: string;
  tmName: string;
  policy: string;
  policyType: string;
  balance: number;
};

const timeOffEmployeesPredicate = (tm: AggregatedTeamMember) => !!tm.time_off.policies.length;

export const CreateTimeOffBalanceReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const timeOffAbilities = useTimeOffRequestAbilities();
  const { control, errors } = useForm();
  const navigate = useNavigate();
  const policyOptions = useTimeOffPolicyOptions();
  const policyLookup = useLookupTimeOffPolicy();
  const lookupTeam = useLookupTeam();

  // State

  const [allData, setAllData] = useState<TimeOffBalanceEntry[]>();
  const [fetchingData, setFetchingData] = useState(false);
  const [selectedTm, setSelectedTm] = useState("");
  const [policyType, setPolicyType] = useState("");
  const [policy, setPolicy] = useState(null);
  const [removeDismissedEmployees, setRemoveDismissedEmployees] = useState(false);
  const [date, setDate] = useState<DateTime | null>(null);

  const finalPredicate = useCallback(
    (tm: AggregatedTeamMember) => {
      return timeOffEmployeesPredicate(tm) && timeOffAbilities.teamPredicate("read")(tm);
    },
    [timeOffAbilities.teamPredicate]
  );

  const employeeOptions = useTeamOptions({ predicate: finalPredicate });

  const finalTmOptions = useMemo(() => {
    return [{ label: "All team members", value: "" as string | null }].concat(employeeOptions);
  }, [employeeOptions]);

  const data = useMemo(() => {
    const data = allData
      ?.filter((d) => {
        if (selectedTm && d.tmId !== selectedTm) return false;
        if (policyType && d.policyType !== policyType) return false;
        if (policy && d.policy !== policy) return false;

        const teamMember = lookupTeam(d.tmId);
        if (!teamMember) return false;

        if (
          removeDismissedEmployees &&
          teamMember.end_date &&
          teamMember.end_date <= DateTime.now().toISODate()
        )
          return false;

        return timeOffAbilities.teamPredicate("read")(teamMember);
      })
      .map((d) => ({
        ...d,
        policyType: capitalize(d.policyType),
        _id: ObjectID().toHexString(),
      }));

    return data;
  }, [allData, selectedTm, policyType, policy, lookupTeam, removeDismissedEmployees]);

  const getData = async (tmId?: string, type?: string) => {
    setFetchingData(true);
    try {
      const payload = {
        type: "time_off_balances",
        params: {
          company: activeCompanyId!,
          teamMember: tmId,
          policyType: type,
          policy: policy,
          asOfDate: date && date.toISODate(),
          removeDismissedEmployees,
        },
        format: "json",
        company: activeCompanyId!,
      };

      const balances = await MiterAPI.reports.create(payload);
      if (balances.error) throw new Error(balances.error);

      setAllData(balances);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    setFetchingData(false);
  };

  const hoursFormatter = (params) => {
    return params?.value?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  };

  const columns: ColumnConfig<TimeOffBalanceEntry>[] = [
    {
      headerName: "Team member",
      field: "tmName",
      dataType: "string",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Team member ID",
      field: "tmFriendlyId",
      dataType: "string",
      enableRowGroup: true,
    },
    {
      headerName: "Policy type",
      field: "policyType",
      dataType: "string",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Policy",
      field: "policy",
      dataType: "string",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      valueFormatter: (params) => {
        return policyLookup(params.data?.policy)?.name || "";
      },
      useValueFormatterForExport: true,
    },
    {
      headerName: "Balance",
      field: "balance",
      valueFormatter: hoursFormatter,
      dataType: "number",
    },
  ];

  const handleTmChange = (tmId: string) => {
    setSelectedTm(tmId);
  };

  const handleRemoveDismissedEmployeesChange = (checked: boolean) => {
    setRemoveDismissedEmployees(checked);
  };

  const handlePolicyTypeChange = (type) => {
    setPolicyType(type);
  };

  const handlePolicyChange = (policy) => {
    setPolicy(policy);
  };

  const handleDateChange = (asOfDate) => {
    setDate(asOfDate);
  };

  useEffect(() => {
    getData();
  }, [date]);

  const reportObject = reportList.find((report) => report.slug === "time_off_balances");

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Vacation and sick time balances</h1>
      </div>
      <div className="report-page-description">{reportObject?.description}*</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 400 }}>
        <Formblock
          type="select"
          name="teamMember"
          control={control}
          onChange={(o) => handleTmChange(o.value)}
          label="Team member"
          errors={errors}
          editing={true}
          options={finalTmOptions}
          value={finalTmOptions.find((o) => o.value === selectedTm)}
        />
        <Formblock
          type="select"
          name="policyType"
          control={control}
          onChange={(o) => handlePolicyTypeChange(o.value)}
          label="Policy type"
          errors={errors}
          editing={true}
          options={policyTypeOptions}
          value={policyTypeOptions.find((o) => o.value === policyType)}
        />

        <Formblock
          type="select"
          name="policy"
          control={control}
          onChange={(o) => handlePolicyChange(o.value)}
          label="Policy"
          errors={errors}
          editing={true}
          options={policyOptions}
          value={policyOptions.find((o) => o.value === policy)}
        />
        <Formblock
          type="datetime"
          name="asOfDate"
          control={control}
          dateOnly={true}
          onChange={(d) => handleDateChange(d)}
          label="As of date"
          errors={errors}
          editing={true}
          value={date}
        />
        <Formblock
          type="checkbox"
          name="removeDismissedEmployees"
          style={{ alignItems: "flex-start" }}
          control={control}
          onChange={(r) => handleRemoveDismissedEmployeesChange(r.target.checked)}
          label="Hide dismissed team members"
          errors={errors}
          editing={true}
          checked={removeDismissedEmployees}
        />
      </div>
      {!data && fetchingData && <Loader />}
      {data && (
        <div>
          <div className="ag-theme-alpine" style={{ maxHeight: 300, maxWidth: "100%" }}>
            <TableV2
              isLoading={fetchingData}
              id="vaction-sick-time-balance-table"
              resource="time off balances"
              columns={columns}
              ssr={false}
              data={data}
              wrapperClassName="base-ssr-table"
              containerClassName={"timesheets-table-container"}
            />

            <div className="vertical-spacer-large"></div>
          </div>
        </div>
      )}
    </div>
  );
};

const policyTypeOptions = [
  { label: "All policy types", value: "" },
  { label: "Sick", value: "sick" },
  { label: "Vacation", value: "vacation" },
];
