import React, { useState, useEffect, useRef, useContext, LegacyRef } from "react";
import TimesheetContext from "./timesheetContext";

import Notifier from "dashboard/utils/notifier";
import { convertHeicToPng } from "dashboard/utils/utils";
import { MiterAPI } from "dashboard/miter";
import { Loader } from "ui";
import PhotoExplorer, { Photo } from "dashboard/components/photo-explorer/PhotoExplorer";
import { FileToUpload, formatFilesForUpload } from "miter-utils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

const TimesheetPictures: React.FC = () => {
  const fileInputRef = useRef();
  const activeCompanyId = useActiveCompanyId();
  const { timesheet, readOnly } = useContext(TimesheetContext);

  const [fetching, setFetching] = useState(false);
  const [images, setImages] = useState<Photo[] | null>([]);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      // @ts-expect-error Doing this to save time. This clearly works.
      fileInputRef.current.click();
    }
  };

  const fetchImages = async () => {
    setFetching(true);

    try {
      const response = await MiterAPI.files.get_urls({
        filter: [
          {
            field: "_id",
            value: timesheet?.images || [],
            type: "_id",
          },
        ],
      });

      if (response.error) {
        throw new Error(response.error);
      }

      const imageUrls: Photo[] = [];
      for (const image of response.urls) {
        if (!image.file) continue;
        const fileId = image.file._id as unknown as string;

        let url = image.value.url;

        if (image.value.url?.toLowerCase().includes(".heic")) {
          url = await convertHeicToPng(image.value.url);
        }

        if (!url) continue;

        imageUrls.push({
          url: url,
          id: fileId,
        });
      }
      setImages(imageUrls);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving this timesheet's photos.");
    }
    setFetching(false);
  };

  const handleDelete = async (selectedPhotos) => {
    setFetching(true);
    try {
      const responseData = await MiterAPI.files.delete(selectedPhotos.map((photo) => photo.id));

      if (responseData.error) {
        throw responseData.error;
      }

      if (selectedPhotos.length > 1) {
        Notifier.success("Photos successfully deleted.");
      } else {
        Notifier.success("Photo successfully deleted.");
      }

      fetchImages();
    } catch (e) {
      // @ts-expect-error error cleanup
      console.log(e.message);

      if (selectedPhotos.length > 1) {
        Notifier.error("There was an error deleting the photos. We're looking into it.");
      } else {
        Notifier.error("There was an error deleting the photo. We're looking into it.");
      }
    }
    setFetching(false);
  };

  const handleFileInput = async (e) => {
    if (!e.target.files.length) {
      Notifier.error("There was an error reading the file.");
      return;
    }

    // Sum the filesize of all files
    let totalSize = 0;
    for (let i = 0; i < e.target.files.length; i++) {
      totalSize += e.target.files[i].size;
    }

    // Check if the total size is greater than 50mb and throw an error if it is
    if (totalSize > 50000000) {
      Notifier.error(
        "The total size of the files is greater than 50MB. Please try again with fewer or smaller files."
      );
      return;
    }

    const fileParams = [...e.target.files].map((blob) => ({ blob }));
    const cleanedFiles = formatFilesForUpload(fileParams, activeCompanyId!, {
      parent_id: timesheet?._id,
      parent_type: "timesheet",
    });

    // Clear the file input so that it can be reused
    if (fileInputRef && fileInputRef.current) {
      // @ts-expect-error regular HTML elements
      fileInputRef.current.files = null;
      // @ts-expect-error regular HTML elements
      fileInputRef.current.value = "";
    }

    await handleUpload(cleanedFiles);
  };

  const handleUpload = async (files: FileToUpload[]) => {
    setFetching(true);
    try {
      const responseData = await MiterAPI.files.upload({ files });
      if ("error" in responseData) throw new Error(responseData.error);

      if (files.length > 1) {
        Notifier.success(`${files.length} photos uploaded successfully`);
      } else {
        Notifier.success(`The file uploaded successfully`);
      }

      fetchImages();
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef as unknown as LegacyRef<HTMLInputElement>}
        hidden
        onChange={handleFileInput}
        multiple={true}
      />
      {fetching && <Loader />}
      {!fetching && images && (
        <PhotoExplorer
          photos={images}
          version="modal"
          showUpload={!readOnly}
          handleUpload={handleUploadClick}
          showDelete={true}
          handleDelete={handleDelete}
          hideCaption={true}
        />
      )}
    </div>
  );
};

export default TimesheetPictures;
