import { ChecklistTask } from "./ChecklistTask";
import React, { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Reorder } from "framer-motion";
import styles from "./Checklists.module.css";
import {
  ChecklistTaskConfig,
  ChecklistTaskItem,
  ChecklistTaskItemData,
} from "dashboard/utils/checklist-utils";
import { DropdownButton } from "ui";
import { Plus } from "phosphor-react";
import { SetStateAction } from "jotai";
import ObjectID from "bson-objectid";
import { useInView } from "react-intersection-observer";

type Props<T extends ChecklistTaskItemData> = {
  tasks: ChecklistTaskItem<T>[];
  setTasks: Dispatch<SetStateAction<ChecklistTaskItem<T>[]>>;
  taskConfig: ChecklistTaskConfig<T>;
};

/**
 * Component that renders a list of checklist tasks.
 * @param tasks - The list of tasks to render.
 * @param setTasks - The function to update the list of tasks.
 */
export const ChecklistTasks: React.FC<Props<ChecklistTaskItemData>> = ({ tasks, setTasks, taskConfig }) => {
  /*********************************************************
   * Important refs, states, and variables
   **********************************************************/
  const isMounted = useRef(false);
  const sectionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const taskRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const { ref: addButtonRef, inView: isAddButtonVisible } = useInView({ threshold: 0 });

  const [draggable, setDraggable] = useState(false);
  const [showScrollingAddButton, setShowScrollingAddButton] = useState(false);
  const reorderableTasks = useMemo(() => tasks.filter((task) => !task.disableReorder), [tasks]);
  const nonReorderableTasks = useMemo(() => tasks.filter((task) => task.disableReorder), [tasks]);

  /*********************************************************
   * useEffect
   **********************************************************/
  /** When errors are added to any task, scroll to the first task with an error */
  useEffect(() => {
    const taskWithError = tasks.find((task) => task.errors && Object.keys(task.errors).length > 0);
    if (taskWithError) {
      const taskRef = taskRefs.current[taskWithError._id];
      if (taskRef) taskRef.scrollIntoView({ behavior: "smooth" });
    }
  }, [tasks]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (isAddButtonVisible) {
      setShowScrollingAddButton(false);
    } else {
      setShowScrollingAddButton(true);
    }
  }, [isAddButtonVisible]);

  /*********************************************************
   * Dropdown options to add tasks
   **********************************************************/
  const shouldShowTaskOption = useCallback(
    (taskKey: string) => {
      const task = tasks.find((task) => task.type === taskKey);
      return (!task || taskConfig?.[taskKey]?.allowMultiple) && !taskConfig?.[taskKey]?.hidden;
    },
    [tasks, taskConfig]
  );

  const buildTaskOption = useCallback(
    (taskKey: string) => ({
      label: taskConfig[taskKey]?.header.title || taskKey,
      value: taskKey,
      icon: <div style={{ marginRight: 7, marginBottom: -4 }}>{taskConfig[taskKey]?.header.icon}</div>,
      action: () => handleAddTask(taskKey),
    }),
    [taskConfig]
  );

  const taskOptions = useMemo(() => {
    const taskKeys = Object.keys(taskConfig);
    return taskKeys.filter((key) => shouldShowTaskOption(key)).map((key) => buildTaskOption(key));
  }, [taskConfig, shouldShowTaskOption, buildTaskOption]);

  /*********************************************************
   * Handler functions
   **********************************************************/
  const handleReorderTasks = (newReorderableTasks: ChecklistTaskItem<ChecklistTaskItemData>[]) => {
    const newTaskList = [...nonReorderableTasks, ...newReorderableTasks];
    const newTaskListWithIndex = newTaskList.map((task, index) => ({ ...task, index }));

    setTasks(newTaskListWithIndex);
  };

  const handleAddTask = (taskKey: string) => {
    const config = taskConfig[taskKey];
    if (!config) return;

    const { header, defaultData } = config;
    const newTaskId = ObjectID().toHexString();
    setTasks((prev) => {
      const newTask: ChecklistTaskItem<ChecklistTaskItemData> = {
        ...header,
        _id: newTaskId,
        type: taskKey,
        index: prev.length,
        showDropdown: true,
        data: defaultData,
        editing: true,
        open: true,
      };

      return [...prev, newTask];
    });

    setTimeout(() => {
      const taskRef = taskRefs.current[newTaskId];
      if (taskRef) taskRef.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleDeleteTask = useCallback((taskId: string) => {
    setTasks((prev) => prev.filter((task) => task._id !== taskId));
  }, []);

  const handleUpdateTask = useCallback((task: ChecklistTaskItem<ChecklistTaskItemData>) => {
    setTasks((prev) => {
      const index = prev.findIndex((t) => t._id === task._id);
      if (index === -1) return prev;

      const newTasks = [...prev];
      newTasks[index] = task;

      return newTasks;
    });
  }, []);

  return (
    <div className={styles["checklist-tasks-wrapper"]} ref={wrapperRef}>
      <div className={styles["checklist-tasks-header"]} ref={addButtonRef}>
        <DropdownButton
          className={"button-2 tall-button no-margin"}
          wrapperClassName={styles["add-task-button"]}
          options={taskOptions}
          closeOnClick={true}
          position="left"
        >
          <Plus style={{ marginBottom: -2, marginRight: 7 }} />
          Add task
        </DropdownButton>
      </div>
      <div
        className={
          styles["checklist-tasks-header-scroll"] + " " + (showScrollingAddButton ? styles["show"] : "")
        }
      >
        <DropdownButton
          className={"button-2 tall-button no-margin square-button"}
          wrapperClassName={styles["add-task-button-scroll"]}
          options={taskOptions}
          closeOnClick={true}
          position="left"
        >
          <Plus style={{ marginBottom: -2 }} weight="bold" />
        </DropdownButton>
      </div>
      <div className={styles["checklist-tasks-group"]}>
        {nonReorderableTasks.map((task) => (
          <div className={styles["checklist-task-container"]} key={"non-reorderable-task-" + task._id}>
            <ChecklistTask task={task} taskConfig={taskConfig} setTask={handleUpdateTask} />
          </div>
        ))}

        <Reorder.Group axis="y" values={tasks} onReorder={handleReorderTasks} ref={sectionsRef}>
          {reorderableTasks.map((task) => (
            <Reorder.Item
              id={task._id}
              key={"reorder-task-" + task._id}
              ref={(el) => (taskRefs.current[task._id] = el)}
              value={task}
              dragListener={draggable}
              onDragEnd={() => setDraggable(false)}
              className={"flex align-items-center " + styles["checklist-task-container"]}
              draggable={!task.disableReorder}
              animate={false}
            >
              <ChecklistTask
                task={task}
                setDraggable={setDraggable}
                onDelete={handleDeleteTask}
                taskConfig={taskConfig}
                setTask={handleUpdateTask}
              />
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </div>
    </div>
  );
};
