import { DateTime } from "luxon";
import { generateUUID } from "miter-utils";
import { ArrowSquareOut, Plus } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { TableV2, Label, Notifier, BasicModal } from "ui";
import { TableActionLink } from "ui/table-v2/Table";
import { ColumnConfig } from "ui/table-v2/Table";
import {
  TimeOffRequestFormSchedule,
  TimeOffRequestFormItems,
  TimeOffRequestForm,
} from "./TimeOffRequestModal";
import { cloneDeep } from "lodash";
import { UseFormMethods } from "react-hook-form";
import { AggregatedTimeOffRequest, MiterAPI, TimeOffRequest } from "dashboard/miter";

type TimeOffRequestScheduleProps = {
  form: UseFormMethods<TimeOffRequestForm>;
  formItems: TimeOffRequestFormItems;
  editing: boolean;
  timeOffRequest?: AggregatedTimeOffRequest | TimeOffRequest | undefined;
  onFinish: (keepOpen: boolean) => void;
};

const mutableStatuses = ["approved", "unapproved"];

/**
 * `TimeOffRequestSchedule` component renders a table configuration that allows users to specify
 * the hours, job, and activity for each day within a given start and end date range.
 */
const TimeOffRequestSchedule: React.FC<TimeOffRequestScheduleProps> = ({
  form,
  editing,
  formItems,
  timeOffRequest,
  onFinish,
}) => {
  const formData = form.watch();

  const timeOffRequestId = timeOffRequest?._id;
  const startDate = formData.start_date!;
  const endDate = formData.end_date!;
  const schedule = formData.schedule;

  const [selectedRows, setSelectedRows] = useState<TimeOffRequestFormSchedule[number][]>([]);
  const [splitConfirmation, setSplitConfirmation] = useState(false);
  const [splitting, setSplitting] = useState(false);

  // schedule has atleast one day that is splitable because it hasn't been paid out yet
  const scheduleCanBeSplit = useMemo(() => {
    return !!schedule?.find((day) => !!day.status && mutableStatuses.includes(day.status));
  }, [schedule]);

  const sortedSchedule = useMemo(() => {
    return schedule?.sort((a, b) => {
      if (!a.date || !b.date) return 0;
      return a.date < b.date ? -1 : 1;
    });
  }, [schedule]);

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/
  /**  Add a new row to the schedule */
  const handleAddRow = () => {
    const newSchedule = cloneDeep(schedule || []);

    const date = buildRowDate();
    newSchedule.push({ _id: generateUUID(), date, hours: 8 });

    form.setValue("schedule", newSchedule, { shouldDirty: true });
  };

  /** Update the rows in the schedule with the rows that were passed back with the same _id */
  const handleUpdateGrid = async (updatedRows: TimeOffRequestFormSchedule) => {
    const newSchedule = [...(schedule || [])];

    updatedRows.forEach((updatedRow) => {
      const index = newSchedule.findIndex((row) => row._id === updatedRow._id);
      if (index >= 0) newSchedule[index] = updatedRow;
    });

    form.setValue("schedule", newSchedule);
  };

  const validSplitDates = (days: TimeOffRequestFormSchedule[number][]) => {
    // dates to split must not have any gaps & also not leave gaps in the current request if split out
    if (!days || !schedule || !(days.length > 0) || !(schedule.length > 0)) return false;

    const sortedSplitDates = days.map((day) => day.date).sort();
    const sortedScheduleDates = schedule.map((day) => day.date).sort();

    if (
      sortedSplitDates[0] !== sortedScheduleDates[0] &&
      sortedSplitDates[sortedSplitDates.length - 1] !== sortedScheduleDates[sortedScheduleDates.length - 1]
    ) {
      return false;
    }

    if (sortedSplitDates[0] === sortedScheduleDates[0]) {
      for (let i = 0; i < sortedSplitDates.length; i++) {
        if (sortedSplitDates[i] !== sortedScheduleDates[i]) {
          return false;
        }
      }
    } else {
      const sizeDiff = sortedScheduleDates.length - sortedSplitDates.length;
      for (let i = sortedSplitDates.length - 1; i >= 0; i--) {
        if (sortedSplitDates[i] !== sortedScheduleDates[sizeDiff + i]) {
          return false;
        }
      }
    }

    return true;
  };

  const handleSplit = async () => {
    setSplitting(true);
    if (validSplitDates(selectedRows)) {
      try {
        const response = await MiterAPI.time_off.requests.split_schedule(timeOffRequestId!, selectedRows);

        if (response.error) throw new Error(response.error);
        Notifier.success("Time off request saved successfully");
        // have to do a reset here to ensure that future resets on canceling edits don't bring back split out dates
        form.reset({ ...form.getValues(), schedule: response.schedule });

        onFinish(true);
      } catch (e) {
        Notifier.error("There was an error splitting schedule");
      }
    } else {
      Notifier.error("Dates must be consecutive and not leave gaps in the current request");
    }
    setSplitting(false);
    setSplitConfirmation(false);
  };

  /**********************************************************************************************************
   * Helpers
   **********************************************************************************************************/

  /** Build the date for a new row in the schedule */
  const buildRowDate = () => {
    // If the schedule is empty, return the start date
    if (!schedule?.length) return startDate.toISODate();

    // Get the last date in the schedule
    const latestDate = schedule.reduce((latest, row) => {
      if (!row.date) return latest;
      return latest && latest > row.date ? latest : row.date;
    }, startDate.toISODate());

    const nextDate = DateTime.fromISO(latestDate).plus({ days: 1 });

    // If the latest date is after the end date, return the end date
    if (endDate && nextDate.toISODate() > endDate.toISODate()) return endDate.toISODate();

    // Otherwise, return the latest date incremented by one day
    return nextDate.toISODate();
  };

  /**********************************************************************************************************
   * Table configuration
   **********************************************************************************************************/
  const columns: ColumnConfig<TimeOffRequestFormSchedule[number]>[] = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        dataType: "date",
        editable: !timeOffRequestId || timeOffRequest?.status === "unapproved",
        editorType: "date",
        editorDateType: "iso",
        dateFormat: "EEE, DD",
        cellEditorParams: {
          min: startDate,
          max: endDate,
        },
      },
      {
        field: "hours",
        headerName: "Hours",
        dataType: "number",
        cellEditorParams: { min: 0, max: 24 },
        editable: !timeOffRequestId || timeOffRequest?.status === "unapproved",
        editorType: "number",
        valueFormatter: (params) => params.value || 0,
        maxWidth: 100,
      },
      {
        field: "status",
        headerName: "Status",
        editable: false,
        dataType: "string",
        displayType: "badge",
        colors: {
          unapproved: "light-gray",
          approved: "light-blue",
          processing: "light-green",
          paid: "green",
        },
        minWidth: 120,
        valueFormatter: (params) => params.data?.status || "-",
      },
      {
        field: "job_id",
        headerName: "Job",
        valueFormatter: (params) => {
          const job = formItems.jobsMap?.(params.data?.job_id);
          if (!job) return "-";

          return formItems.jobNameFormatter?.(job) || job.name;
        },
        dataType: "string",
        editable: true,
        editorType: "select",
        cellEditorParams: {
          options: formItems.jobOptions,
          isClearable: true,
        },
      },
      {
        field: "activity_id",
        headerName: "Activity",
        valueFormatter: (params) => {
          const activity = formItems.activitiesMap?.(params.data?.activity_id);
          if (!activity) return "-";

          return formItems.activityLabelFormatter?.(activity) || activity.label;
        },
        dataType: "string",
        editable: true,
        editorType: "select",
        cellEditorParams: (params) => {
          return {
            options:
              formItems.selectableActivitiesMap?.get(params?.data?.job_id).map((o) => ({
                value: o._id,
                label: formItems.activityLabelFormatter?.(o) || o.label,
              })) || [],
            isClearable: true,
          };
        },
      },
    ],
    [formItems, startDate, endDate, schedule]
  );

  const staticActions: TableActionLink[] = [
    {
      label: "Add entry",
      className: "button-2 no-margin",
      action: () => handleAddRow(),
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
      style: { marginLeft: 10 },
      shouldShow: () => !timeOffRequestId || timeOffRequest?.status === "unapproved",
    },
  ];

  const splitButtonStyle = useMemo(() => {
    return editing ? { marginRight: -5, marginLeft: 10 } : { marginLeft: 10 };
  }, [editing]);

  const dynamicActions: TableActionLink[] = [
    {
      label: "Split out",
      className: "button-1 no-margin",
      action: () => setSplitConfirmation(true),
      important: true,
      showInEditMode: true,
      icon: <ArrowSquareOut weight="bold" style={{ marginRight: 3 }} />,
      style: splitButtonStyle,
    },
  ];

  const labelStyle = useMemo(() => {
    return (editing || (scheduleCanBeSplit && selectedRows.length > 0)) &&
      (!timeOffRequestId || timeOffRequest?.status === "unapproved")
      ? { marginBottom: -70, marginTop: 0, width: 400 }
      : { width: 400, marginTop: 0, marginBottom: -30 };
  }, [editing, selectedRows, scheduleCanBeSplit]);

  return (
    <div>
      {splitConfirmation && (
        <BasicModal
          headerText="Split out days?"
          onHide={() => setSplitConfirmation(false)}
          button2Action={handleSplit}
          loading={splitting}
          button1Text="No"
          button1Action={() => setSplitConfirmation(false)}
          button2Text="Yes"
          yellowBodyText={true}
          bodyText="Are you sure you want to split out the selected dates from this request?"
        ></BasicModal>
      )}
      <Label
        label="Advanced hours"
        sublabel="Specify the hours, job, and activity for each day."
        style={labelStyle}
      />
      <TableV2
        id="time-off-request-schedule"
        resource={"days"}
        columns={columns}
        data={sortedSchedule}
        hideSearch={true}
        staticActions={editing ? staticActions : []}
        dynamicActions={!!timeOffRequestId ? dynamicActions : []}
        editable={editing}
        hideFooter={true}
        onSelect={setSelectedRows}
        hideSelectColumn={!editing && !scheduleCanBeSplit}
        hideSecondaryActions={true}
        alwaysEditable={editing}
        rowSelectDisabled={(row) => {
          return !!row?.data?.status && !mutableStatuses.includes(row?.data?.status);
        }}
        autoSave={true}
        disablePagination={true}
        onSave={(newSchedule) => handleUpdateGrid(newSchedule)}
        hideRowEditingStatus={true}
        gridWrapperStyle={{ height: "460px", paddingBottom: 0, marginBottom: 0 }}
        containerStyle={{ paddingBottom: 0 }}
      />
    </div>
  );
};

export default TimeOffRequestSchedule;
