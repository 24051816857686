import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier, convertFieldRequirementToBoolean } from "dashboard/utils";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TagsTable from "../tags/TagsTable";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui/settings/SettingsCard";
import { DailyReportMobileFieldRequirements } from "./DailyReportMobileFieldRequirements";

const mapOfNewFieldToOld = {
  "field_requirements.mobile.customer_signature": "require_customer_signature",
};

export const DailyReportsSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const navigate = useNavigate();
  const mounted = useRef(false);
  const activeCompany = useActiveCompany();

  const { settings } = activeCompany!;
  const [dailyReportsSettings, setDailyReportsSettings] = useState(settings.daily_reports || {});
  const [disclosure, setDisclosure] = useState<string | undefined>(settings.daily_reports?.disclosure);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    } else {
      handleChangeDebounced("disclosure", disclosure);
    }
  }, [disclosure]);

  const goBackToDailyReports = () => {
    navigate("/daily-reports");
  };

  const handleChange = async (fieldName: string, newValue) => {
    const newSettings = { ...dailyReportsSettings };
    newSettings[fieldName] = newValue;
    const oldField = mapOfNewFieldToOld?.[fieldName] || "";
    if (oldField) {
      newSettings[oldField] = convertFieldRequirementToBoolean(newValue);
    }
    setDailyReportsSettings(newSettings);
    try {
      if (!activeCompany) throw new Error("No active company");
      const response = await MiterAPI.companies.update(activeCompany._id, {
        $set: { "settings.daily_reports": newSettings },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Daily report settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error(
        "There was an error updating the daily report settings. Our engineers are looking into it!"
      );
    }
  };

  const handleChangeDebounced = useDebouncedCallback((fieldName: string, newValue) => {
    handleChange(fieldName, newValue);
  }, 500);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Daily report settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={goBackToDailyReports}>
          Back to daily reports
        </div>
        <div className="flex">
          <h1>Daily report settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure the settings for your daily reports</div>
        <div className="vertical-spacer-small"></div>
        <SettingsCard title="General" info={"Manage the general settings for daily reports."}>
          <div style={{ marginTop: 10 }}>
            <div className="flex">
              <input
                type="checkbox"
                name="enable_multi_day_reports"
                checked={dailyReportsSettings.enable_multi_day_reports}
                onChange={(e) => handleChange("enable_multi_day_reports", e.target.checked)}
              />
              <span style={{ marginLeft: 15 }}>{`Enable multi-day daily reports`}</span>
            </div>
          </div>
        </SettingsCard>
        <SettingsCard
          title="Mobile field requirements"
          info={"Set field requirements for daily reports submitted from the mobile app."}
        >
          <div style={{ marginTop: 10 }}>
            <DailyReportMobileFieldRequirements settings={dailyReportsSettings} handleChange={handleChange} />
          </div>
        </SettingsCard>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Display options</div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="condensed_pdf"
              checked={dailyReportsSettings.condensed_pdf}
              onChange={(e) => handleChange("condensed_pdf", e.target.checked)}
            />
            <span
              style={{ marginLeft: 15 }}
            >{`Condense the information on the daily report PDF to take up fewer pages.`}</span>
          </div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="show_missed_lunch"
              checked={dailyReportsSettings.show_missed_lunch}
              onChange={(e) => handleChange("show_missed_lunch", e.target.checked)}
            />
            <span
              style={{ marginLeft: 15 }}
            >{`Always show the missed lunch column on the daily report`}</span>
          </div>

          <div className="vertical-spacer"></div>
          <div className="flex">
            <input
              type="radio"
              style={{ margin: "0px 12px", marginLeft: 4 }}
              onChange={() => handleChange("display_hours_type", "payroll")}
              checked={
                dailyReportsSettings?.display_hours_type === "payroll" ||
                !dailyReportsSettings?.display_hours_type
              }
            />
            <label>
              <span className="bold">Show payroll hours</span>: excludes any breaks
            </label>
          </div>
          <div className="flex" style={{ marginTop: 7 }}>
            <input
              type="radio"
              style={{ margin: "0px 12px", marginLeft: 4 }}
              onChange={() => handleChange("display_hours_type", "billable")}
              checked={dailyReportsSettings?.display_hours_type === "billable"}
            />
            <label>
              <span className="bold">Show billable hours</span>: include breaks and missed lunch hours
            </label>
          </div>
          <div className="vertical-spacer"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="display_identifier_in_header"
              checked={dailyReportsSettings.display_identifier_in_header}
              onChange={(e) => handleChange("display_identifier_in_header", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>{`Show daily report identifier in the report header.`}</span>
          </div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="display_only_billable_activities"
              checked={dailyReportsSettings.display_only_billable_activities}
              onChange={(e) => handleChange("display_only_billable_activities", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>{`Show only billable activities in the daily report PDF.`}</span>
          </div>
          <div className="vertical-spacer"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="display_identifier_in_header"
              checked={dailyReportsSettings.include_timesheet_photos_in_pdf}
              onChange={(e) => handleChange("include_timesheet_photos_in_pdf", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>{`Include timesheet photos in the daily report PDF.`}</span>
          </div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="hide_company_email_in_pdf"
              checked={dailyReportsSettings.hide_company_email_in_pdf}
              onChange={(e) => handleChange("hide_company_email_in_pdf", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>{`Hide the company email address in the daily report PDF`}</span>
          </div>
        </div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Permissions</div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="enable_non_supervisor_access"
              checked={dailyReportsSettings.enable_non_supervisor_access}
              onChange={(e) => handleChange("enable_non_supervisor_access", e.target.checked)}
            />
            <span
              style={{ marginLeft: 15 }}
            >{`Allow non-supervisors to create, edit, and sign daily reports`}</span>
          </div>
          <div className="flex" style={{ marginTop: 10 }}>
            <input
              type="checkbox"
              name="view_other_supervisor_dailies_on_mobile"
              checked={dailyReportsSettings.view_other_supervisor_dailies_on_mobile}
              onChange={(e) => handleChange("view_other_supervisor_dailies_on_mobile", e.target.checked)}
            />
            <span
              style={{ marginLeft: 15 }}
            >{`Allow supervisors to view other supervisor's daily reports on the mobile app`}</span>
          </div>
        </div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Deleting</div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="require_customer_signature"
              checked={dailyReportsSettings.archive_attached_data_when_daily_is_archived}
              onChange={(e) => handleChange("archive_attached_data_when_daily_is_archived", e.target.checked)}
            />
            <span
              style={{ marginLeft: 15 }}
            >{`Delete timesheets and other attachments when a daily report is deleted`}</span>
          </div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="allow_archiving_from_mobile"
              checked={dailyReportsSettings.allow_archiving_from_mobile}
              onChange={(e) => handleChange("allow_archiving_from_mobile", e.target.checked)}
            />
            <span style={{ marginLeft: 15 }}>{`Allow daily reports to be deleted from the mobile app`}</span>
          </div>
        </div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Manage tags</div>
          <TagsTable parentType="daily_report" />
        </div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Disclosure</div>
          <div style={{ opacity: 0.7, fontSize: 16, marginTop: 7 }}>
            Add a disclosure to the bottom of each daily report PDF that you generate.
          </div>
          <div className="vertical-spacer-small"></div>
          <ReactQuill
            theme="snow"
            value={disclosure}
            onChange={setDisclosure}
            placeholder="Add disclosure here..."
            style={{
              marginTop: 15,
            }}
          />
        </div>
      </div>
    </div>
  );
};
