import React, { useEffect, useState, useMemo, useContext } from "react";
import { reportList } from "../reportList";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { W2PreviewReportEntry } from "backend/utils/reports/w2PreviewReport";
import { useActiveCompanyId, useLookupTeam, useUser } from "dashboard/hooks/atom-hooks";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { DateTime } from "luxon";
import { Formblock } from "ui";
import { useForm } from "react-hook-form";
import AppContext from "dashboard/contexts/app-context";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { recentYearOptions } from "dashboard/utils";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

const reportObject = reportList.find((report) => report.slug === "w2_preview");

export const W2PreviewReport: React.FC = () => {
  // Hooks
  const { setReverifyUser } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const { errors, control } = useForm();
  const { can } = useMiterAbilities();
  const user = useUser();
  const teamAbilities = useTeamAbilities();
  const lookupTeam = useLookupTeam();

  // State
  const [data, setData] = useState<W2PreviewReportEntry[]>([]);
  const [year, setYear] = useState(() => {
    // In January, assume they're looking for the previous year
    const today = DateTime.now();
    return today.month === 1 ? today.year - 1 : today.year;
  });
  const [showRawSSNs, setShowRawSsns] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const isReverified = (user?.reverified_at || 0) > DateTime.now().minus({ days: 1 }).toSeconds();

  // Options
  const yearOptions = useMemo(recentYearOptions, []);

  const fileName = "Miter W2 Preview Report ";

  useEffect(() => {
    if (showReverificationModal) {
      setReverifyUser(showReverificationModal);
    }
  }, [showReverificationModal]);

  useEffect(() => {
    if (showRawSSNs && !isReverified) {
      setShowReverificationModal(true);
      return;
    }
    getData();
  }, [showRawSSNs, isReverified, !activeCompanyId, year]);

  const getData = async () => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const payload = {
        type: "w2_preview",
        params: {
          company_id: activeCompanyId,
          year: year,
          showRawSSNs: isReverified ? showRawSSNs : false,
        },
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const filteredData = response.filter((entry: W2PreviewReportEntry) => {
        const teamMember = lookupTeam(entry.team_member);
        return teamAbilities.can("read_sensitive", teamMember);
      });

      setData(filteredData);
    } catch (e: $TSFixMe) {
      console.log("Error fetching W2 preview data:", e);
      if (e.message.includes("W2 data not found")) {
        Notifier.error(`Your company has no payrolls in Miter with W-2 employees for ${year}.`);
      } else {
        Notifier.error(e.message);
      }
    }
    setLoading(false);
  };

  const columns = useMemo(() => {
    const cols: ColumnConfig<W2PreviewReportEntry>[] = [
      {
        field: "friendly_id",
        filter: true,
        headerName: "Team member ID",
        dataType: "string",
      },
      {
        field: "full_name",
        filter: true,
        headerName: "Team member",
        dataType: "string",
      },
      {
        field: "first_name",
        filter: true,
        headerName: "First name",
        dataType: "string",
        initialHide: true,
      },
      {
        field: "last_name",
        filter: true,
        headerName: "Last name",
        dataType: "string",
        initialHide: true,
      },
      {
        field: "employee_ssn",
        headerName: "SSN",
        dataType: "string",
        initialHide: true,
      },
      {
        field: "federal_taxable_wages",
        headerName: "Federal Taxable Wages",
        dataType: "number",
        sumRow: true,
        unit: "dollar",
      },
      {
        field: "federal_income_tax_withheld",
        headerName: "Federal Income Tax Withheld",
        dataType: "number",
        sumRow: true,
        unit: "dollar",
      },
      {
        field: "social_security_wages",
        headerName: "Social Security Wages",
        dataType: "number",
        sumRow: true,
        unit: "dollar",
      },
      {
        field: "social_security_tax_withheld",
        headerName: "Social Security Tax Withheld",
        dataType: "number",
        sumRow: true,
        unit: "dollar",
      },
      {
        field: "medicare_wages_and_tips",
        headerName: "Medicare Wages & Tips",
        dataType: "number",
        sumRow: true,
        unit: "dollar",
      },
      {
        field: "medicare_tax_withheld",
        headerName: "Medicare Tax Withheld",
        dataType: "number",
        sumRow: true,
        unit: "dollar",
      },
      {
        field: "social_security_tips",
        headerName: "Social Security Tips",
        dataType: "number",
        sumRow: true,
        unit: "dollar",
      },
      {
        field: "box_10_items",
        headerName: "Box 10 Items",
        dataType: "string",
      },
      {
        field: "box_12_items",
        headerName: "Box 12 Items",
        dataType: "string",
      },
      {
        field: "box_14_items",
        headerName: "Box 14 Items",
        dataType: "string",
      },
      {
        field: "state_items",
        headerName: "State Items",
        dataType: "string",
      },
      {
        field: "local_items",
        headerName: "Local Items",
        dataType: "string",
      },
    ];

    return cols;
  }, []);

  return (
    <div className="page-content">
      <Helmet>
        <title>W2 Preview Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>{reportObject!.label}</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      {can("team:read_sensitive") && (
        <div style={{ maxWidth: 400 }}>
          <Formblock
            label="Show full SSNs"
            control={control}
            type="checkbox"
            onChange={(e) => setShowRawSsns(e.target.checked)}
            name="show_full_ssns"
            errors={errors}
            editing={true}
            style={{ alignItems: "center" }}
            labelStyle={{ flexDirection: "unset" }}
          />
        </div>
      )}
      <div style={{ maxWidth: 400 }}>
        <Formblock
          label="Year"
          name="year"
          control={control}
          type="select"
          options={yearOptions}
          defaultValue={year}
          onChange={(option) => setYear(option.value)}
          errors={errors}
          editing={true}
          style={{ alignItems: "center" }}
          labelStyle={{ flexDirection: "unset" }}
        />
      </div>
      <div className="vertical-spacer-large"></div>
      <TableV2
        id="w2-preview-report"
        resource="W2 preview"
        data={data}
        columns={columns}
        isLoading={loading}
        groupHideOpenParents={false}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        containerStyle={{ marginTop: -30 }}
        showReportViews={true}
        exportFileName={fileName}
        showTotals={true}
      />
      <div className="vertical-spacer-large"></div>
    </div>
  );
};
