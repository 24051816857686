import React, { useMemo, useState } from "react";
import styles from "./Checklists.module.css";
import { ActionModal, Button, ConfirmModal } from "ui";
import { CaretDown, CaretUp, CheckSquare, DotsSixVertical, MinusCircle, Square } from "phosphor-react";
import {
  ChecklistTaskConfig,
  ChecklistTaskItem,
  ChecklistTaskItemData,
} from "dashboard/utils/checklist-utils";
import { AnimatePresence, motion } from "framer-motion";
import Banner from "../shared/Banner";

type Props<T extends ChecklistTaskItemData> = {
  task: ChecklistTaskItem<T>;
  setTask: (task: ChecklistTaskItem<T>) => void;
  setDraggable?: (draggable: boolean) => void;
  taskConfig: ChecklistTaskConfig<T>;

  onClick?: (taskId: string) => void;
  onDelete?: (taskId: string) => void;

  showIndex?: boolean;
  showStatus?: boolean;
};

export const ChecklistTask: React.FC<Props<ChecklistTaskItemData>> = ({
  task,
  setTask,
  setDraggable,
  onClick,
  onDelete,
  taskConfig,
  showIndex,
}) => {
  const [open, setOpen] = useState(task.open);
  const TaskContentComponent = taskConfig[task.type!]?.content;

  const hasErrors = useMemo(() => task.errors && Object.keys(task.errors).length > 0, [task.errors]);

  return (
    <div className={"flex align-items-center width-100-percent"} style={{ position: "relative" }}>
      {!task.disableReorder && setDraggable && <Draggable setDraggable={setDraggable} />}

      <div
        className={styles["checklist-task"] + " " + (task.status === "complete" ? styles["complete"] : "")}
        onClick={() => onClick?.(task._id)}
        style={{ ...(hasErrors && { borderColor: "#FF4C4C" }) }}
      >
        <div className={styles["checklist-task-header"] + " " + (open ? styles["open"] : "")}>
          {showIndex && (
            <div className={styles["checklist-task-index"]}>
              <div className={styles["checklist-task-index-number"]}>{task.index + 1}</div>
            </div>
          )}
          {task.showStatus && <ChecklistTaskStatus status={task.status!} />}
          <div className={styles["checklist-task-icon"]}>{task.icon}</div>
          <div className={styles["checklist-task-title"]}>{task.title}</div>
          <div className={styles["checklist-task-actions"]}>
            {task.showDropdown && (
              <Button className="button-text" onClick={() => setOpen((prev) => !prev)}>
                {open ? <CaretUp /> : <CaretDown />}
              </Button>
            )}
          </div>
        </div>
        {TaskContentComponent && (
          <div className={styles["checklist-task-content"]}>
            <AnimatePresence initial={false}>
              {open && (
                <motion.section
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <ChecklistTaskErrors errors={task.errors} />
                  <TaskContentComponent task={task} setTask={setTask} />
                </motion.section>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>

      {!task.disableDelete && onDelete && <ChecklistTaskDelete taskId={task._id} onDelete={onDelete} />}
    </div>
  );
};

type DraggableProps = {
  setDraggable: (draggable: boolean) => void;
};

export const Draggable: React.FC<DraggableProps> = ({ setDraggable }) => {
  return (
    <div
      className={styles["draggable"]}
      onMouseEnter={() => setDraggable(true)}
      onMouseLeave={() => setDraggable(false)}
      onTouchStart={() => setDraggable(true)}
    >
      <DotsSixVertical style={{ fontSize: "24px", backgroundColor: "#fafafa" }} />
    </div>
  );
};

type ChecklistTaskDeleteProps = {
  taskId: string;
  onDelete: (taskId: string) => void;
};

export const ChecklistTaskDelete: React.FC<ChecklistTaskDeleteProps> = ({ taskId, onDelete }) => {
  return (
    <div className={styles["checklist-task-delete"]} onClick={() => onDelete(taskId)}>
      <MinusCircle />
    </div>
  );
};

type ChecklistTaskModalProps<T extends ChecklistTaskItemData> = {
  task: ChecklistTaskItem<T>;
  onSubmit: (updatedTask: ChecklistTaskItem<T>) => void;
  onCancel: () => void;
  onDelete: () => void;
};

type ChecklistTaskErrorsProps = {
  errors: Record<string, string> | undefined;
};

export const ChecklistTaskErrors: React.FC<ChecklistTaskErrorsProps> = ({ errors }) => {
  if (!errors || Object.keys(errors).length === 0) return null;

  return (
    <Banner type="error" hideIcon={true}>
      {Object.entries(errors).map(([key, value]) => (
        <div key={`error-${key}`}>{value}</div>
      ))}
    </Banner>
  );
};

type ChecklistTaskStatusProps = {
  status: "incomplete" | "complete";
};

export const ChecklistTaskStatus: React.FC<ChecklistTaskStatusProps> = ({ status }) => {
  return (
    <div className={styles["checklist-task-status"]}>
      <div className={styles["checklist-task-status-icon"]}>
        {status === "complete" ? (
          <CheckSquare style={{ marginBottom: -4, fontSize: "1.3rem" }} weight="fill" color="#26A96C" />
        ) : (
          <Square style={{ marginBottom: -4, fontSize: "1.3rem" }} />
        )}
      </div>
    </div>
  );
};

export const ChecklistTaskModal: React.FC<ChecklistTaskModalProps<ChecklistTaskItemData>> = ({
  task,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <>
      <ActionModal
        headerText={`Edit Task: ${task.title}`}
        showSubmit={!!onSubmit}
        showCancel={true}
        showDelete={!!onDelete}
        cancelText={"Close"}
        onCancel={onCancel}
        submitText={"Save"}
        onHide={onCancel}
        onSubmit={() => onSubmit(task)}
        onDelete={() => setShowDeleteConfirm(true)}
      ></ActionModal>
      {showDeleteConfirm && (
        <ConfirmModal
          title={`Delete Task: ${task.title}`}
          body={`Are you sure you want to delete this task?`}
          onYes={onDelete}
          onNo={() => setShowDeleteConfirm(false)}
        />
      )}
    </>
  );
};
