import ChatMessage, { ChatTimestamp } from "dashboard/components/chat/ChatMessage";
import { TwilioMessage } from "dashboard/types/chat";
import { DateTime } from "luxon";
import React, { ReactElement, useCallback, useMemo } from "react";

type GetOrderedMessagesParams = {
  messages: TwilioMessage[];
  participantSid?: string;
};

export const useOrderedMessages = (input: GetOrderedMessagesParams): ReactElement[] => {
  const { messages, participantSid } = input;

  const orderedMessages = useCallback((): ReactElement[] => {
    const messageElements: ReactElement[] = [];

    messages.forEach((message, index) => {
      // Get the user / chat info for the messag

      const author = message.participantSid === participantSid ? "sender" : "receiver";
      const previousMessage = messages[index - 1];
      const messageTimestamp = DateTime.fromJSDate(message.dateCreated);

      // If this isn't the first message in the conversation, add a new timestamp seperator if needed.
      if (previousMessage) {
        const sameAuthor = previousMessage.participantSid === message.participantSid;

        // Get the difference in minutes between the previous message and this message
        const minutesBetweenMessages = messageTimestamp.diff(
          DateTime.fromJSDate(previousMessage.dateCreated),
          "minutes"
        ).minutes;

        // If it's been more than 15 minutes since the last message or if the author changed, add a new timestamp seperator
        if (minutesBetweenMessages > 15 || !sameAuthor) {
          messageElements.push(
            <ChatTimestamp key={messageTimestamp.toMillis()} timestamp={messageTimestamp} />
          );
        }
      } else {
        messageElements.push(
          <ChatTimestamp key={messageTimestamp.toMillis()} timestamp={messageTimestamp} />
        );
      }

      messageElements.push(
        <ChatMessage key={message.sid} message={message} prevMessage={previousMessage} author={author} />
      );
    });

    // Reverse the messages so they are in the correct order
    messageElements.reverse();
    return messageElements;
  }, [messages, participantSid]);

  const messaagesToReturn = useMemo(() => orderedMessages(), [input, orderedMessages]);
  return messaagesToReturn;
};
