import { useActiveTeam } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, MiterAPI, PerformanceReviewCycle } from "dashboard/miter";
import React, { FC, useEffect, useState } from "react";
import { Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import styles from "./Performance.module.css";
import { RevieweesTableAndSelector } from "./RevieweesTableAndSelector";
import { DateTime } from "luxon";

type Props = {
  performanceReviewCycle: PerformanceReviewCycle;
  setPerformanceReviewCycle: (performanceReviewCycle: PerformanceReviewCycle) => void;
  name: string;
};

export const ReviewCycleRevieweesScreen: FC<Props> = ({
  performanceReviewCycle,
  setPerformanceReviewCycle,
  name,
}) => {
  const { setCanNext } = useWizard();
  const teamMembers = useActiveTeam();
  const [reviewees, setReviewees] = useState<AggregatedTeamMember[]>([]);
  const [selectedReviewees, setSelectedReviewees] = useState<AggregatedTeamMember[]>([]);
  const { team_member_ids } = performanceReviewCycle;

  useEffect(() => {
    setReviewees(teamMembers.filter((member) => team_member_ids.includes(member._id)));
  }, [teamMembers, team_member_ids]);

  // can only continue if at least one TM is selected
  useEffect(() => {
    if (reviewees.length > 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [reviewees]);

  const onNext = async () => {
    await saveReviewees();
  };

  const removeReviewees = async () => {
    setReviewees(reviewees.filter((reviewee) => !selectedReviewees.includes(reviewee)));
    setSelectedReviewees([]);
  };

  const saveReviewees = async () => {
    try {
      const res = await MiterAPI.performance_review_cycles.update(performanceReviewCycle._id, {
        ...performanceReviewCycle,
        team_member_ids: reviewees.map((enrollee) => enrollee._id),
        timezone: DateTime.local().zoneName,
      });
      if (res.error) {
        throw new Error(res.error);
      }
      setPerformanceReviewCycle(res as unknown as PerformanceReviewCycle);
      Notifier.success("Updated enrolled reviewees.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error(e);
    }
  };

  return (
    <WizardScreen onNext={onNext} name={name}>
      <div className={styles["content"]}>
        <div>Select team members to review!</div>
        <RevieweesTableAndSelector
          reviewees={reviewees}
          onSubmit={setReviewees}
          onRemove={removeReviewees}
          selectedReviewees={selectedReviewees}
        />
      </div>
    </WizardScreen>
  );
};
