import { AggregatedTeamMember, TeamMember } from "dashboard/miter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { ActionModal } from "ui";
import { DateTime } from "luxon";
import { TeamMemberRehire, TeamMemberDismissal } from "backend/models/teamMember/team-member";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { UpdateTeamMembersParams } from "../../../../backend/utils/team-members/updateTeamMember";
import {
  EditableCallbackParams,
  ValueFormatterParams,
  ValueGetterParams,
  ICellRendererParams,
  ValueSetterParams,
} from "ag-grid-community";
import {
  TERMINATION_REASON_OPTIONS,
  TERMINATION_REASON_LABEL_MAP,
} from "dashboard/pages/team-members/DismissModal";
import { TableActionLink } from "ui/table-v2/Table";
import { Copy, X } from "phosphor-react";
import ObjectID from "bson-objectid";

type TeamEmploymentHistoryTableEntry = {
  _id: string;
  date?: DateTime;
  type: "hire" | "dismissal" | "rehire" | null;
} & Partial<TeamMemberRehire> &
  Partial<TeamMemberDismissal>;

type Props = {
  teamMember: AggregatedTeamMember | TeamMember;
  onHide: () => void;
};

/**
 * Get the initial hire entry for the team member using the rehires array, original start date, or start date
 * @param teamMember
 * @returns
 */
const getInitialHireEntry = (
  currentHireRehire: TeamMemberRehire | undefined,
  teamMember: AggregatedTeamMember | TeamMember
): TeamEmploymentHistoryTableEntry | null => {
  if (currentHireRehire) {
    // Use the rehire entry as the current hire
    return {
      _id: ObjectID().toHexString(),
      type: "hire",
      timestamp: Number(currentHireRehire.timestamp),
      date: DateTime.fromISO(currentHireRehire.start_date),
      start_date: currentHireRehire.start_date,
    };
  } else if (teamMember.original_start_date) {
    // If no matching rehire, use the original start date field
    return {
      _id: ObjectID().toHexString(),
      type: "hire",
      date: DateTime.fromISO(teamMember.original_start_date),
      start_date: teamMember.original_start_date,
    };
  } else if (teamMember.start_date) {
    // If no matching rehire and no original start date, use the start date field
    return {
      _id: ObjectID().toHexString(),
      type: "hire",
      date: DateTime.fromISO(teamMember.start_date),
      start_date: teamMember.start_date,
    };
  }

  return null;
};

const TeamEmploymentHistory: React.FC<Props> = ({ teamMember, onHide }) => {
  /*********************************************************
    Hooks
  *********************************************************/
  const refetchTeam = useRefetchTeam();
  const miterAbilities = useMiterAbilities();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [selectedRows, setSelectedRows] = useState<TeamEmploymentHistoryTableEntry[]>([]);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);

  const data = useMemo((): TeamEmploymentHistoryTableEntry[] => {
    const tableEntries: TeamEmploymentHistoryTableEntry[] = [];

    // Check if there's a rehire with the same date as the current start date or original start date
    const currentHireRehire = teamMember.rehires?.find(
      (rehire) =>
        rehire.start_date === teamMember.original_start_date || rehire.start_date === teamMember.start_date
    );

    const initialHireEntry = getInitialHireEntry(currentHireRehire, teamMember);
    if (initialHireEntry) {
      tableEntries.push(initialHireEntry);
    }

    teamMember.dismissals?.forEach((dismissal) => {
      const dismissedDt = DateTime.fromISO(dismissal.end_date);
      tableEntries.push({
        _id: ObjectID().toHexString(), // Generate unique ID
        type: "dismissal",
        eligible_for_rehire: dismissal.eligible_for_rehire,
        involuntary: dismissal.involuntary,
        last_payday: dismissal.last_payday,
        termination_reason: dismissal.termination_reason,
        note: dismissal.note,
        timestamp: Number(dismissal.timestamp),
        date: dismissedDt,
      });
    });

    teamMember.rehires?.forEach((rehire) => {
      // If the rehire date is the same as the current hire date, don't show it
      if (rehire === currentHireRehire) return;
      const rehiredDt = DateTime.fromISO(rehire.start_date);

      tableEntries.push({
        _id: ObjectID().toHexString(), // Generate unique ID
        type: "rehire",
        timestamp: Number(rehire.timestamp),
        date: rehiredDt,
      });
    });

    tableEntries.sort((a, b) => {
      // First, compare by date (descending order)
      const dateComparison = (b.date?.toMillis() || 0) - (a.date?.toMillis() || 0);

      // If dates are equal, use timestamp as a tiebreaker
      if (dateComparison === 0) {
        return (b.timestamp || 0) - (a.timestamp || 0);
      }

      return dateComparison;
    });

    return tableEntries;
  }, [teamMember]);

  const [tableData, setTableData] = useState<TeamEmploymentHistoryTableEntry[]>(data);

  // Update the table data when the team member data changes
  useEffect(() => {
    setTableData(data);
  }, [data]);

  /*********************************************************
    Handler Functions
  *********************************************************/

  const handleAddRow = () => {
    const newTableData = [
      {
        _id: ObjectID().toHexString(),
        type: null,
        timestamp: DateTime.now().toSeconds(),
      },
      ...tableData,
    ];
    setTableData(newTableData);
  };

  const handleSelect = (selections: TeamEmploymentHistoryTableEntry[]) => {
    setSelectedRows(selections);
  };

  const handleDeleteRows = useCallback(
    (rows: TeamEmploymentHistoryTableEntry[]) => {
      setDeleting(true);
      // Filter out the rows that need to be deleted
      const rowIdsToDelete = rows.map((row) => row._id);
      const newEmploymentHistoryEntries = tableData.filter((entry) => !rowIdsToDelete.includes(entry._id));
      setTableData(newEmploymentHistoryEntries);
      setDeleting(false);
    },
    [tableData]
  );

  const handleRowUpdate = useCallback(
    async (updatedRows: TeamEmploymentHistoryTableEntry[]) => {
      setTableData(updatedRows);
    },
    [tableData]
  );

  const handleDuplicateRow = useCallback(
    (row: TeamEmploymentHistoryTableEntry, index: number) => {
      // Add the new row to the table after the current row
      const newRows = [
        ...tableData.slice(0, index + 1),
        {
          ...row,
          _id: ObjectID().toHexString(),
          edited: true,
        },
        ...tableData.slice(index + 1),
      ];

      setTableData(newRows);
    },
    [tableData]
  );

  const cleanEmploymentHistoryParams = (
    params: TeamEmploymentHistoryTableEntry[]
  ): UpdateTeamMembersParams => {
    const rehires: TeamMemberRehire[] = [];
    const dismissals: TeamMemberDismissal[] = [];

    params.forEach((param) => {
      if (param.timestamp == null) {
        throw new Error("Every employment history entry must have a timestamp");
      }

      if (param.date == null) {
        throw new Error("Every employment history entry must have a date");
      }

      if (param.type === "rehire" || param.type === "hire") {
        rehires.push({
          timestamp: param.timestamp,
          start_date: param.date.toISODate(),
        });
      } else if (param.type === "dismissal") {
        dismissals.push({
          timestamp: param.timestamp,
          end_date: param.date.toISODate(),
          involuntary: param.involuntary ?? false,
          note: param.note || "",
          // Optional dismissal fields
          eligible_for_rehire: param.eligible_for_rehire ?? undefined,
          termination_reason: param.termination_reason || undefined,
          last_payday: param.last_payday || undefined,
        });
      }
    });

    return {
      rehires,
      dismissals,
    };
  };

  const handleSave = async (teamMemberParamsArray: TeamEmploymentHistoryTableEntry[]): Promise<void> => {
    if (!teamMember) return;

    if (miterAbilities.cannot("team:update_sensitive")) {
      Notifier.error("You do not have permission to update team members.");
      return;
    }
    setSaving(true);
    try {
      const params = cleanEmploymentHistoryParams(teamMemberParamsArray);
      const response = await MiterAPI.team_member.update(teamMember._id, params, {
        validateEmploymentHistory: true, // This validates the employment history dates when updating the team member object
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Team member updated successfully.");
      await refetchTeam(teamMember._id);
    } catch (e: $TSFixMe) {
      Notifier.error(`There was an error updating the team member: ${e.message}`);
    } finally {
      setSaving(false);
    }
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add rehire or dismissal",
        action: () => handleAddRow(),
        shouldShow: () => miterAbilities.can("team:update_sensitive"),
        showInEditMode: true,
        important: true,
        className: "button-1 tall-button",
      },
      {
        label: "Save",
        action: () => handleSave(tableData),
        shouldShow: () => miterAbilities.can("team:update_sensitive"),
        showInEditMode: true,
        important: true,
        className: "button-2 tall-button",
        loading: saving,
      },
    ],
    [miterAbilities, handleAddRow, handleSave, tableData, selectedRows]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Remove rows",
        action: () => handleDeleteRows(selectedRows),
        className: "button-1 table-button",
        shouldShow: () => miterAbilities.can("team:update_sensitive"),
        showInEditMode: true,
        loading: deleting,
      },
    ],
    [miterAbilities, tableData, selectedRows]
  );

  /*********************************************************
    Config variables for the table
  **********************************************************/

  const columns: ColumnConfig<TeamEmploymentHistoryTableEntry>[] = useMemo(
    () => [
      {
        field: "type",
        headerName: "Type",
        dataType: "string",
        displayType: "badge",
        colors: {
          dismissal: "red",
          hire: "blue",
          rehire: "green",
        },
        maxWidth: 100,
        editable: true,
        editorType: "select",
        cellEditorParams: {
          options: [
            { label: "Dismissal", value: "dismissal" },
            { label: "Rehire", value: "rehire" },
          ],
        },
        isEditableRequired: () => {
          return true;
        },
      },
      {
        field: "date",
        headerName: "Hire/dismissal date",
        dataType: "date",
        valueGetter: (params: ValueGetterParams<TeamEmploymentHistoryTableEntry>) => {
          return params.data?.date?.toISODate();
        },
        valueFormatter: (params: ValueFormatterParams<TeamEmploymentHistoryTableEntry>) => {
          const label = params.data?.type === "dismissal" ? "Dismissed" : "Hired";
          return params.value ? `${label} on ${DateTime.fromISO(params.value).toFormat("DD")}` : "-";
        },
        valueEditor: (newValue: string) => {
          return DateTime.fromISO(newValue);
        },
        dateType: "iso",
        editable: true,
        editorType: "date",
        editorDateType: "iso",
        validations: (value: string) => (value ? true : "Hire/dismissal date cannot be empty."),
        minWidth: 200,
        isEditableRequired: () => {
          return true;
        },
      },
      {
        field: "timestamp",
        headerName: "Changed at",
        dataType: "date",
        dateType: "timestamp",
        dateFormat: "ff",
        editable: true,
        editorType: "date",
        editorDateType: "iso",
        valueGetter: (params: ValueGetterParams<TeamEmploymentHistoryTableEntry>) => {
          return params.data?.timestamp ? DateTime.fromSeconds(params.data.timestamp).toISO() : undefined;
        },
        valueSetter: (params: ValueSetterParams<TeamEmploymentHistoryTableEntry>) => {
          const newValue = params.newValue ? DateTime.fromISO(params.newValue).toSeconds() : undefined;
          params.data.timestamp = newValue;
          return true;
        },
        validations: (value: string) => (value ? true : "Changed at date cannot be empty."),
      },
      {
        field: "last_payday",
        headerName: "Last payday",
        dataType: "date",
        dateType: "iso",
        editable: (params: EditableCallbackParams<TeamEmploymentHistoryTableEntry>) => {
          return params.data?.type === "dismissal";
        },
        editorType: "date",
        editorDateType: "iso",
      },
      {
        field: "involuntary",
        headerName: "Involuntary",
        dataType: "string",
        displayType: "badge",
        colors: {
          Involuntary: "red",
          Voluntary: "green",
        } as Record<string, string>,
        valueGetter: (params: ValueGetterParams<TeamEmploymentHistoryTableEntry>) => {
          const involuntary = params.data?.involuntary;
          if (involuntary == null) return null;
          return involuntary ? "Involuntary" : "Voluntary";
        },
        editable: (params: EditableCallbackParams<TeamEmploymentHistoryTableEntry>) => {
          return params.data?.type === "dismissal";
        },
        isEditableRequired: (rowData: TeamEmploymentHistoryTableEntry) => {
          return rowData.type === "dismissal";
        },
        editorType: "select",
        cellEditorParams: {
          options: [
            { label: "Involuntary", value: true },
            { label: "Voluntary", value: false },
          ],
          isClearable: true,
        },
      },
      {
        field: "eligible_for_rehire",
        headerName: "Eligible for rehire",
        dataType: "string",
        displayType: "badge",
        colors: {
          Eligible: "green",
          Ineligible: "red",
        } as Record<string, string>,
        valueGetter: (params: ValueGetterParams<TeamEmploymentHistoryTableEntry>) => {
          const eligibleForRehire = params.data?.eligible_for_rehire;
          if (eligibleForRehire == null) return null;
          return eligibleForRehire ? "Eligible" : "Ineligible";
        },
        editable: (params: EditableCallbackParams<TeamEmploymentHistoryTableEntry>) => {
          return params.data?.type === "dismissal";
        },
        editorType: "select",
        cellEditorParams: {
          options: [
            { label: "Eligible", value: true },
            { label: "Ineligible", value: false },
          ],
          isClearable: true,
        },
      },
      {
        field: "termination_reason",
        headerName: "Termination reason",
        valueGetter: (params: ValueGetterParams<TeamEmploymentHistoryTableEntry>) => {
          if (!params.data?.termination_reason) return "-";
          return TERMINATION_REASON_LABEL_MAP[params.data!.termination_reason];
        },
        editable: (params: EditableCallbackParams<TeamEmploymentHistoryTableEntry>) => {
          return params.data?.type === "dismissal";
        },
        editorType: "select",
        cellEditorParams: {
          options: TERMINATION_REASON_OPTIONS,
          isClearable: true,
        },
      },
      {
        field: "note",
        headerName: "Notes",
        dataType: "string",
        minWidth: 250,
        editable: (params: EditableCallbackParams<TeamEmploymentHistoryTableEntry>) => {
          return params.data?.type === "dismissal";
        },
        editorType: "text",
        useValueFormatterOnExport: true,
        isEditableRequired: (rowData: TeamEmploymentHistoryTableEntry) => {
          return rowData.type === "dismissal";
        },
      },
      {
        field: "_id",
        headerName: "",
        pinned: "right",
        maxWidth: 80,
        cellRenderer: (params: ICellRendererParams<TeamEmploymentHistoryTableEntry>) => {
          if (!params.node.allChildrenCount) {
            return (
              <div className="flex justify-content-center width-100-percent">
                <button
                  onClick={() => params.data && handleDuplicateRow(params.data, params.node.rowIndex!)}
                  className="black-link no-margin button-text hover-purple-link"
                  style={{ marginRight: 10, fontSize: "1.2rem", opacity: 1 }}
                >
                  <Copy style={{ marginTop: 3 }} />
                </button>
                <button
                  onClick={() => params.data && handleDeleteRows([params.data])}
                  className="black-link no-margin button-text hover-purple-link"
                  style={{ marginRight: 5, fontSize: "1.2rem", opacity: 1 }}
                >
                  <X style={{ marginTop: 3 }} />
                </button>
              </div>
            );
          } else {
            return null;
          }
        },
        suppressMenu: true,
      },
    ],
    [tableData, handleDuplicateRow, handleDeleteRows]
  );

  return (
    <div className="form-table-wrapper">
      <ActionModal
        onHide={onHide}
        headerText={`Employment history for ${teamMember.full_name}`}
        wrapperStyle={{ width: "80%" }}
        bodyStyle={{ height: "600px" }}
        hideFooter={true}
      >
        <TableV2
          id={"team-employment-history"}
          resource={"history"}
          data={tableData}
          columns={columns}
          isLoading={saving}
          dynamicActions={dynamicActions}
          staticActions={staticActions}
          editable={miterAbilities.can("team:update_sensitive")}
          editableControlled={true}
          alwaysEditable={miterAbilities.can("team:update_sensitive")}
          onSave={handleRowUpdate}
          autoSave={true}
          onSelect={handleSelect}
          defaultSelectedRows={selectedRows}
          suppressEditableGreyOut={true}
        />
      </ActionModal>
    </div>
  );
};

export default TeamEmploymentHistory;
