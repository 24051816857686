import React, { useEffect, useMemo, useState } from "react";
import { ActionMenuRelative, Badge, Loader } from "ui";
import Earnings from "./Earnings";
import PaymentContext from "./paymentContext";

import Reimbursements from "./Reimbursements";
import { PaymentModalTimesheets } from "./PaymentModalTimesheets";
import PaymentSummary from "./PaymentSummary";
import PayrollContext from "../payrollContext";
import { ClickAwayListener } from "@material-ui/core";
import Benefits from "./Benefits";
import Deductions from "./Deductions";
import Taxes from "./Taxes";

import "./paymentModal.css";
import { ModalSidebar } from "ui";
import DownloadChecksModal from "../nonDraftPayroll/DownloadChecksModal";
import { getPaymentPayMethod, Notifier, downloadBlob } from "dashboard/utils";
import { MiterAPI, RetrievePayrollResponse } from "dashboard/miter";
import PaymentModalTimeOff from "./PaymentModalTimeOff";

import x from "dashboard/assets/x.png";
import { Overtime } from "./Overtime";
import PaymentSettingsModal from "./PaymentSettingsModal";
import { Overrides } from "./Overrides";
import { PaymentWarnings } from "./PaymentWarnings";
import { CheckItem, CheckPayrollStatus } from "backend/utils/check/check-types";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { ArrowLeft, ArrowRight, Pencil } from "phosphor-react";
import { useEnhancedSearchParams } from "miter-utils";
import CreateExpenseReimbursementModal from "dashboard/pages/expenses/modals/CreateExpenseReimbursementModal";
import { useHasAccessToMiterPaystubsAndChecks } from "dashboard/gating";
import { EditExpenseReimbursementModal } from "dashboard/pages/expenses/modals/EditExpenseReimbursementModal";

type Props = {
  tmId: string;
  moveToTeamMember?: (direction: "previous" | "next") => void;
  editing: boolean;
  hide: () => void;
  payrollId?: string;
  paymentsTablePosition?: "first" | "last";
  initialView?: string;
};

const PaymentModal: React.FC<Props> = ({
  tmId,
  moveToTeamMember,
  editing,
  hide,
  payrollId,
  paymentsTablePosition,
  initialView,
}) => {
  const activeCompany = useActiveCompany();
  const payrollContext = React.useContext(PayrollContext);

  const { searchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });

  const [loading, setLoading] = useState(false);
  const [payroll, setPayroll] = useState(payrollContext.payroll);
  const [downloadCheckModal, setDownloadCheckModal] = useState(false);
  const [isLoading, setIsLoading] = useState(payrollContext.isLoading);
  const [isChangingPaymentSettings, setIsChangingPaymentSettings] = useState(false);
  const [isAddingReimbursement, setIsAddingReimbursement] = useState(false);
  const [expenseReimbursementIdtoView, setExpenseReimbursementIdtoView] = useState<string | undefined>(
    searchParams.get("reimbursementId") || undefined
  );

  const hasAccessToMiterPayStubs = useHasAccessToMiterPaystubsAndChecks();

  const initialize = async (refreshPayroll?: boolean) => {
    if (payroll && !refreshPayroll) return;
    let response: RetrievePayrollResponse | undefined;
    setLoading(true);
    try {
      if (!payrollId) {
        throw new Error("No payrollId");
      }
      response = await MiterAPI.payrolls.retrieve(payrollId);
      if (response.error) throw new Error(response.error);
      setPayroll(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error displaying the payment. We're looking into it!");
    }
    setLoading(false);
    return response;
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (payrollContext.payroll) {
      setPayroll(payrollContext.payroll);
    }
  }, [payrollContext]);

  const payment = payroll?.miter_payments.find((p) => p.team_member._id.toString() === tmId);

  const checkPaymentExists = !!payment?.check_item || !!payment?.check_cp;
  const paymentMethod = payment && getPaymentPayMethod(payment);

  const togglerConfig = useMemo(() => {
    const tm = payment?.team_member;
    if (!tm) return [];

    // Base
    const config: TogglerConfigItem[] = [
      { label: "Summary", path: "summary" },
      { label: "Earnings", path: "earnings" },
      { label: "Reimbursements", path: "reimbursements" },
      { label: "Timesheets", path: "timesheets" },
    ];

    // Overtime
    if (tm.employment_type === "employee" || !!activeCompany?.settings.payroll.ot_applies_to_1099s) {
      config.push({ label: "Overtime", path: "overtime" });
    }

    // Time off
    if (payroll?.type === "regular") {
      config.push({ label: "Time Off", path: "time_off" });
    }

    // Overrides
    if (
      tm.employment_type === "employee" &&
      (payroll?.type === "regular" ||
        !!payroll?.check_payroll.off_cycle_options?.apply_benefits ||
        !!payroll?.check_payroll.off_cycle_options?.apply_post_tax_deductions)
    ) {
      config.push({ label: "Overrides", path: "overrides" });
    }

    // Warnings
    const unacknowledgedWarnings = payment?.warnings?.filter((w) => !w.acknowledged);
    if (payment?.warnings?.length) {
      config.push({
        label: "Warnings",
        path: "warnings",
        alert: !!unacknowledgedWarnings?.length && payroll?.check_payroll.status === "draft",
      });
    }

    // Deductions & Contributions
    const checkBenefitWarnings = !!payment?.check_item?.warnings?.some((w) => w.deduction_type === "benefit");
    const checkPtdWarnings = !!payment?.check_item?.warnings?.some(
      (w) => w.deduction_type === "post_tax_deduction"
    );
    const checkTaxWarnings = !!payment?.check_item?.warnings?.some((w) => w.deduction_type === "tax");

    if (!editing && payment?.team_member.employment_type === "employee") {
      config.push(
        { label: "Benefits", path: "benefits", alert: checkBenefitWarnings },
        { label: "Taxes", path: "taxes", alert: checkTaxWarnings },
        { label: "Deductions", path: "deductions", alert: checkPtdWarnings }
      );
    }

    return config;
  }, [payment, activeCompany]);

  // When navigating directly from one payment to the next/previous, the active tab might not appear in the new payment, so let's switch to the first tab.
  let activeView = searchParams.get("activeView") || initialView;

  if (!activeView || !togglerConfig.find((c) => c.path === activeView)) {
    activeView = "summary";
  }

  const handleToggle = (path) => {
    setSearchParams({ activeView: path });
  };

  const downloadPaystub = async () => {
    const checkTmId = payment?.team_member._id.toString();
    const payrollId = payroll?._id;
    if (!checkTmId || !payrollId) return;
    setIsLoading(true);
    try {
      let response;

      if (hasAccessToMiterPayStubs) {
        response = await MiterAPI.team_member.miter_paystubs.retrieve(checkTmId, [payrollId]);
      } else {
        // Todo clean up old check paystub endpoints once miter paystubs are stable
        response = await MiterAPI.team_member.paystubs.retrieve(checkTmId, [payrollId]);
      }

      if (response.error) throw new Error(response.error);

      const blob = await response.blob();
      const fileName = `${payroll!.check_payroll.payday} ${payment?.team_member.full_name} paystub.pdf`;
      downloadBlob(blob, fileName);
      Notifier.success("Paystub downloaded successfully.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`There was an error downloading your paystub: ${e.message}`);
    }
    setIsLoading(false);
  };

  const actionMenuLinks = useMemo(() => {
    const actions: ActionLink[] = [];
    if (payroll && payroll.type !== "historical") {
      if (paymentMethod === "manual" && payroll.type !== "amendment") {
        actions.push({
          label: "Download paper check",
          action: () => setDownloadCheckModal(true),
        });
      }
      actions.push({
        label: "Download paystub",
        action: () => downloadPaystub(),
      });
    }
    return actions;
  }, [payroll, paymentMethod]);

  const status: CheckItem["status"] =
    ((payroll?.type === "historical" || payroll?.type === "amendment") && "paid") ||
    (payment?.check_item || payment?.check_cp)?.status ||
    (payroll?.check_payroll.status && checklessPaymentStatusLookup[payroll.check_payroll.status]) ||
    "draft";

  const onHide = async () => {
    setSearchParams({ activeView: undefined });
    hide();
  };

  return (
    <div className="modal-background">
      {!payment || !payroll ? (
        <div className="modal-wrapper payment payment-wrapper payment-active-view">
          <Loader />
        </div>
      ) : (
        <PaymentContext.Provider
          value={{
            payroll: payrollContext.payroll || payroll,
            isLoading: payrollContext.isLoading || loading,
            editing,
            payment,
            tm: payment.team_member,
            toggle: handleToggle,
          }}
        >
          <ClickAwayListener onClickAway={() => {}}>
            <div className="modal-wrapper payment">
              {downloadCheckModal && (
                <DownloadChecksModal payment={payment} hide={() => setDownloadCheckModal(false)} />
              )}
              <div className="modal-header payment">
                <div>{"Payment to " + payment.team_member.full_name}</div>
                <Badge text={status.replace("_", " ")} />
                {payment.voided && <Badge text="Voided" color={"yellow"} style={{ margin: 0 }} />}
                {payment.is_void && <Badge text="Void" color={"gray"} style={{ margin: 0 }} />}
                {isLoading && <Loader className="small-text" />}
                {payroll.check_payroll.status !== "draft" &&
                  checkPaymentExists &&
                  payroll.type !== "historical" && <ActionMenuRelative links={actionMenuLinks} />}
                <div className="flex-1"></div>
                {paymentsTablePosition !== "first" && moveToTeamMember && (
                  <button
                    onClick={() => moveToTeamMember("previous")}
                    className="button-1"
                    style={paymentsTablePosition === "last" ? { marginRight: "14px" } : {}}
                  >
                    <ArrowLeft style={{ marginRight: 3, marginTop: 3, marginBottom: -2 }} />
                    Previous payment
                  </button>
                )}
                {paymentsTablePosition !== "last" && moveToTeamMember && (
                  <button
                    onClick={() => moveToTeamMember("next")}
                    className="button-1"
                    style={{ marginRight: "14px" }}
                  >
                    Next payment
                    <ArrowRight style={{ marginLeft: 3, marginTop: 3, marginBottom: -2 }} />
                  </button>
                )}
                {payroll.check_payroll.status === "draft" && editing && (
                  <div className="flex" style={{ marginRight: 6 }}>
                    <button onClick={() => setIsChangingPaymentSettings(true)} className="button-1">
                      <Pencil style={{ marginRight: 3, marginTop: 3, marginBottom: -2 }} />
                      Edit payment settings
                    </button>
                  </div>
                )}
                <img src={x} className="exit-icon" onClick={onHide} />
              </div>
              {isChangingPaymentSettings && (
                <PaymentSettingsModal
                  selectedPayments={[payment]}
                  hide={() => setIsChangingPaymentSettings(false)}
                />
              )}
              {payment.onboarded && (
                <div className="payment-wrapper">
                  <ModalSidebar config={togglerConfig} active={activeView} toggle={handleToggle} />
                  <div className="payment-active-view">
                    <div>
                      {activeView === "summary" && <PaymentSummary status={status} />}
                      {activeView === "earnings" && <Earnings />}
                      {activeView === "reimbursements" && (
                        <Reimbursements
                          setIsAdding={setIsAddingReimbursement}
                          setViewingExpenseReimbursement={(id) => {
                            setExpenseReimbursementIdtoView(id);
                            setSearchParams({ activeView: "reimbursements", reimbursementId: id });
                          }}
                        />
                      )}
                      {activeView === "timesheets" && (
                        <PaymentModalTimesheets teamMemberId={tmId} readOnly={!editing} />
                      )}
                      {activeView === "deductions" && <Deductions />}
                      {activeView === "benefits" && <Benefits />}
                      {activeView === "taxes" && <Taxes />}
                      {activeView === "overtime" && <Overtime />}
                      {activeView === "time_off" && <PaymentModalTimeOff />}
                      {activeView === "overrides" && <Overrides />}
                      {activeView === "warnings" && <PaymentWarnings />}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </PaymentContext.Provider>
      )}
      {isAddingReimbursement && (
        <CreateExpenseReimbursementModal
          onHide={() => setIsAddingReimbursement(false)}
          onSubmit={() => {
            setIsAddingReimbursement(false);
            payrollContext.recalculatePayroll({ tms: [tmId] });
          }}
          teamMemberIdFromPayroll={tmId}
        />
      )}
      {expenseReimbursementIdtoView && (
        <EditExpenseReimbursementModal
          expenseReimbursementId={expenseReimbursementIdtoView}
          onHide={() => {
            setExpenseReimbursementIdtoView(undefined);
            setSearchParams({ reimbursementId: undefined });
          }}
          onSubmit={() => {
            setExpenseReimbursementIdtoView(undefined);
            setSearchParams({ reimbursementId: undefined });
            payrollContext.recalculatePayroll({ tms: [tmId] });
          }}
        />
      )}
    </div>
  );
};

export default PaymentModal;

// If the payroll is failed, a $0 payment should still show as paid since no payment was going to be made anyway. In other words: in the absence of true failure, it's successful.
export const checklessPaymentStatusLookup: Record<CheckPayrollStatus, CheckPayrollStatus> = {
  draft: "draft",
  pending: "pending",
  processing: "processing",
  partially_paid: "paid",
  paid: "paid",
  failed: "paid",
};
