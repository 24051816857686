import DAS140 from "./reportPages/DAS140/CreateDAS140";
import PayrollJournal from "./reportPages/payrollJournal/CreatePayrollJournal";
import CreateTeamSummary from "./reportPages/CreateTeamSummary";
import { CreateDAS142 } from "./reportPages/DAS142/CreateDAS142";
import CreateWorkersCompReport from "./reportPages/workersComp/CreateWorkersCompReport";
import { CreateEarningsSummary } from "./reportPages/earningSummary/CreateEarningsSummary";
import { CreateCPR } from "./reportPages/CPR/createCPR";
import CreateDeductionsReport from "./reportPages/deductionsAndContributions/DeductionsAndContributionsReport";
import { CreateTimeOffBalanceReport } from "./reportPages/CreateTimeOffBalanceReport";
import { TimesheetAnalysisReport } from "./reportPages/timesheetAnalysis/TimesheetAnalysisReport";
import { Create401KReport } from "./reportPages/401KReport/401KReport";
import { FringeReport } from "./reportPages/FringeReport/FringeReport";
import { JobCostReport } from "./reportPages/jobCost/JobCostReport";
import { SIMPLEIRAReport } from "./reportPages/SIMPLEIRAreport/SIMPLEIRAReport";
import { EEOReport } from "./reportPages/EEOReport";
import { FringeBenefitStatement } from "./reportPages/FringeBenefitStatement";
import { CreateApprenticeRatioReport } from "./reportPages/CreateApprenticeRatioReport";
import { TrainingContributionReport } from "./reportPages/TrainingContributionReport";
import { PayPeriodHours } from "./reportPages/HoursByPayPeriod";
import CreateBirthdaysAndTenuresReports from "./reportPages/CreateBirthdaysAndTenuresReports";
import { ReimbursementsReport } from "./reportPages/ReimbursementsReport";
import { ReportConfig, reportList } from "./reportList";
import { NonDraftPayrollLedgerEntry } from "./NonDraftPayrollLedgerEntry";
import PayRateHistoriesReport from "./reportPages/PayRateHistoriesReport";
import { CreateExpensesReport } from "./reportPages/CreateExpensesReport";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { PermissionPaths } from "dashboard/miter";
import { useMemo } from "react";
import { useActiveCompany, useActiveCompanyId, useInitializing, usePrgs } from "dashboard/hooks/atom-hooks";
import { PREMO_ROOFING_ID } from "dashboard/utils";
import { RouteItem } from "dashboard/routes";
import { TaxDepositsReport } from "./reportPages/TaxDepositsReport";
import { W2PreviewReport } from "./reportPages/W2PreviewReport";
import { TaxFilingsReport } from "./reportPages/TaxFilingsReport";
import { CreateMiterCardStatementReport } from "./reportPages/CreateMiterCardStatementReport";
import CreateChangeRequestReport from "./reportPages/CreateChangeRequestReport";
import { CreateTeamMemberRetentionReport } from "./reportPages/CreateTeamMemberRetentionReport";
import { HealthCuesReport } from "./reportPages/HealthCuesReport";
import { CreatePayrollRegister } from "./reportPages/PayrollRegister/CreatePayrollRegister";
import EnrollmentCensus from "./reportPages/EnrollmentCensus";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";

/** Lookup for report components. This has to live in a different file than reportList.ts due to circular dependencies, since the components themselves depend on the reportList */
export const ReportComponentLookup = {
  cpr: CreateCPR,
  pay_period_hours: PayPeriodHours,
  earnings_summary: CreateEarningsSummary,
  fringe_report: FringeReport,
  payroll_journal: PayrollJournal,
  das_140: DAS140,
  das_142: CreateDAS142,
  job_costing: JobCostReport,
  timesheet_analysis: TimesheetAnalysisReport,
  eeo_1: EEOReport,
  team_summary: CreateTeamSummary,
  deductions_contributions: CreateDeductionsReport,
  reimbursements: ReimbursementsReport,
  workers_comp: CreateWorkersCompReport,
  "401k_report": Create401KReport,
  simple_ira_report: SIMPLEIRAReport,
  fringe_benefit_statement: FringeBenefitStatement,
  apprentice_ratio: CreateApprenticeRatioReport,
  training_contributions: TrainingContributionReport,
  time_off_balances: CreateTimeOffBalanceReport,
  birthdays_and_tenure_milestones: CreateBirthdaysAndTenuresReports,
  ledger_entry: NonDraftPayrollLedgerEntry,
  pay_rate_histories: PayRateHistoriesReport,
  expenses: CreateExpensesReport,
  miter_card_statement: CreateMiterCardStatementReport,
  tax_deposits: TaxDepositsReport,
  tax_filings: TaxFilingsReport,
  w2_preview: W2PreviewReport,
  change_requests: CreateChangeRequestReport,
  team_member_retention: CreateTeamMemberRetentionReport,
  health_cues: HealthCuesReport,
  payroll_register: CreatePayrollRegister,
  enrollment_census: EnrollmentCensus,
};

export const useReportConfigs = (payroll?: boolean): (ReportConfig & { accessible: () => boolean })[] => {
  const { can } = useMiterAbilities();
  const initializing = useInitializing();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const prgs = usePrgs();

  const reports = useMemo(() => {
    const filteredReports = reportList
      .filter((report) => {
        const hasComponent = !!ReportComponentLookup[report.slug];

        // The SIMPLE IRA report should be visible only to Premo in prod
        if (
          report.slug === "simple_ira_report" &&
          activeCompanyId !== PREMO_ROOFING_ID &&
          process.env.REACT_APP_ENVIRONMENT !== "development"
        ) {
          return false;
        }

        // Exclude fringe report if the company isn't using pay rate groups (only valid to check prgs length if we're not initializing still)
        if (report.slug === "fringe_report" && !prgs.length && !initializing) {
          return false;
        }

        // Exclude ledger entry report since that's only for non-draft payrolls
        if (report.slug === "ledger_entry" && !payroll) {
          return false;
        }

        // Excluding from reports page as this is just for Alan Briteway and they can see it in the individual payroll reports
        // Can fix up the endpoint and healthcues report to allow them to choose a payroll if they want it here too in the future
        if (report.slug === "health_cues") {
          return false;
        }

        // Exclude Clasp benefits reports for companies not integrated with Clasp
        if (report.slug === "enrollment_census" && !isActiveClaspCompany(activeCompany)) {
          return false;
        }

        return hasComponent;
      })
      .map((report) => {
        return {
          ...report,
          accessible: () => can(`reports:${report.slug}` as PermissionPaths),
        };
      });

    return filteredReports;
  }, [can, prgs.length, !payroll, initializing]);

  return reports;
};

export const useVisibleReports = (payroll?: boolean): (ReportConfig & { accessible: () => boolean })[] => {
  const all = useReportConfigs(payroll);
  return useMemo(() => all.filter((r) => r.accessible()), [all]);
};

export const useReportRoutes = (): RouteItem[] => {
  const reportConfigs = useReportConfigs();

  return useMemo(() => {
    return reportConfigs.map(
      (report): RouteItem => ({
        component: ReportComponentLookup[report.slug],
        path: report.link,
        accessible: report.accessible,
      })
    );
  }, [reportConfigs]);
};
