import { MiterAPI } from "dashboard/miter";
import { useState, useCallback, useEffect } from "react";
import { Notifier } from "ui";
import { AcumaticaCorporateCardSourceData } from "backend/services/acumatica/types/acumatica-expense-types";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { IS_DEVELOPMENT } from "dashboard/utils/environment";

type FetchBranches = {
  corporateCards: AcumaticaCorporateCardSourceData[] | undefined;
  loading: boolean;
  error: unknown | null;
  fetchCorporateCards: () => void;
};

const AVAILABLE_CUSTOMERS = ["661d30d7e3f740187385b400"];

export const useFetchAcumaticaCorporateCards = (integrationConnectionId?: string): FetchBranches => {
  const companyId = useActiveCompanyId();
  const [corporateCards, setCorporateCards] = useState<AcumaticaCorporateCardSourceData[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const shouldFetch = IS_DEVELOPMENT || (companyId && AVAILABLE_CUSTOMERS.includes(companyId));

  const fetchCorporateCards = useCallback(async () => {
    if (!integrationConnectionId) return;

    setLoading(true);
    try {
      const result = await MiterAPI.integrations.acumatica.retrieve_corporate_cards(integrationConnectionId);

      if (result.error) throw Error(result.error);

      setCorporateCards(result);
    } catch (e: $TSFixMe) {
      Notifier.error("Error fetching Acumatica Corporate Cards.");
      setError(e);
    }
    setLoading(false);
  }, [integrationConnectionId]);

  useEffect(() => {
    if (shouldFetch) {
      fetchCorporateCards();
    }
  }, []);

  return {
    corporateCards,
    loading,
    error,
    fetchCorporateCards,
  };
};
