import { useMemo, useState } from "react";
import { Notifier } from "ui";
import { useFetchUserData } from "./useFetchUserData";
import { useActiveCompanyId, useRefetchTeam } from "./atom-hooks";
import { sleep } from "miter-utils";
import { MiterAPI } from "dashboard/miter";

export const useCheckCompanyOnboard = (): {
  open: () => Promise<void>;
  loading: boolean;
} => {
  const activeCompanyId = useActiveCompanyId();
  const fetchUserData = useFetchUserData();
  const refetchTeam = useRefetchTeam();
  const [loading, setLoading] = useState(false);

  const getCheckOnboardLink = async (): Promise<string | void> => {
    if (!activeCompanyId) return;
    try {
      const res = await MiterAPI.companies.get_check_onboard_link(activeCompanyId);
      if (res.error) throw Error(res.error);
      return res.link;
    } catch (e: $TSFixMe) {
      console.log(e.message);
      Notifier.error("There was an error getting your onboarding link. Please reach out to support.");
    }
  };

  const onCheckOnboardEvent = async (event_name: string) => {
    if (event_name === "check-onboard-app-loaded") {
      setLoading(false);
    }
  };

  const open = async () => {
    setLoading(true);
    const link = await getCheckOnboardLink();

    if (!link) return setLoading(false);
    // If modal takes too long to open, redirect user to new window.
    const timeoutId = setTimeout(() => {
      const newWin = window.open(link, "_blank");
      if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
        Notifier.error(
          "Your browser blocked Miter from opening a new window. Make sure to allow popups from miter.com"
        );
      }
      setLoading(false);
    }, 8000);
    try {
      const handler = window.Check.create({
        link: link,
        onClose: async () => {
          await sleep(1000);
          fetchUserData();
          refetchTeam();
        },
        onEvent: onCheckOnboardEvent,
      });
      await handler.open();
      clearTimeout(timeoutId);
    } catch (e) {
      console.log("Error opening Check Onboard", e);
      Notifier.error("Sorry, we can't update payment information right now. We're looking into it!");
      setLoading(false);
    }
  };

  return useMemo(() => ({ open, loading }), [fetchUserData, refetchTeam, loading]);
};
