import React, { useMemo, useState } from "react";

import { BasicModal, Notifier, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import {
  useActiveCompanyId,
  useOnboardingChecklists,
  useRefetchOnboardingChecklists,
} from "dashboard/hooks/atom-hooks";
import ChecklistWizard from "dashboard/components/onboarding/checklist/ChecklistWizard";
import { OnboardingChecklistRow, generateOnboardingChecklistRow } from "dashboard/utils/onboarding";
import { Plus, TrashSimple } from "phosphor-react";
import { MiterAPI } from "dashboard/miter";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

const columns: ColumnConfig<OnboardingChecklistRow>[] = [
  {
    field: "title",
    headerName: "Name",
    width: 250,
    dataType: "string" as const,
  },

  {
    field: "created_at",
    headerName: "Created on",
    dataType: "date",
    dateType: "timestamp",
  },
];

const ChecklistsTable: React.FC = ({}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const checklists = useOnboardingChecklists();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [selectedRows, setSelectedRows] = useState<OnboardingChecklistRow[]>([]);
  const [showCreateChecklistModal, setShowCreateChecklistModal] = useState(false);
  const [loading] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState<OnboardingChecklistRow | null>(null);
  const [confirmModal, setConfirmModal] = useState<null | "delete">(null);
  const { can, cannot } = useMiterAbilities();
  const data = useMemo(
    () => generateOnboardingChecklistRow(checklists || []),
    [checklists, generateOnboardingChecklistRow]
  );
  const refetchOnboardingChecklists = useRefetchOnboardingChecklists();

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleSelect = (selections: OnboardingChecklistRow[]) => {
    setSelectedRows(selections);
  };

  const closeCreateModal = () => {
    setShowCreateChecklistModal(false);
    setSelectedChecklist(null);
  };

  const selectChecklistToShow = (checklist: OnboardingChecklistRow) => {
    setSelectedChecklist(checklist);
    setShowCreateChecklistModal(true);
  };

  const archiveOnboardingChecklists = async () => {
    if (selectedRows.length === 0 || !activeCompanyId || cannot("performance:schedules:delete")) return;
    try {
      const onboardingChecklistIds = selectedRows.map((row) => row._id);
      const res = await MiterAPI.onboarding_checklists.archive({
        ids: onboardingChecklistIds,
        company_id: activeCompanyId,
      });
      if (res.failures.length || res.error) throw new Error(res.error + res.failures.join(", "));
      Notifier.success("Successfully deleted onboarding checklists.");
    } catch (e) {
      console.error(e);
      Notifier.error("Failed to delete onboarding checklist(s).");
    }
    setConfirmModal(null);
    setSelectedRows([]);
    refetchOnboardingChecklists();
  };

  /*********************************************************
    Toggler configs
  **********************************************************/

  const staticActions: TableActionLink[] = [
    {
      label: "Add checklist",
      action: () => setShowCreateChecklistModal(true),
      className: "button-2 table-button",
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const dynamicActions = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 table-button",
        action: () => setConfirmModal("delete"),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("performance:schedules:delete"),
      },
    ],
    [setConfirmModal, can]
  );

  const renderTable = () => {
    return (
      <TableV2
        id="onborading-checklists-table"
        resource="onboarding checklists"
        data={data || []}
        columns={columns}
        onSelect={handleSelect}
        onClick={selectChecklistToShow}
        isLoading={loading}
        defaultSelectedRows={selectedRows}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        hideFooter={true}
        containerStyle={{ paddingBottom: 0 }}
      />
    );
  };

  return (
    <div>
      {renderTable()}
      {(showCreateChecklistModal || selectedChecklist) && (
        <ChecklistWizard
          onExit={closeCreateModal}
          onComplete={closeCreateModal}
          onboardingChecklist={selectedChecklist || undefined}
        />
      )}
      {confirmModal === "delete" && (
        <BasicModal
          headerText={"Are you sure?"}
          bodyText={
            "Deleting an onboarding checklist will mean new team members cannot be onboarded with this checklist. If you still want access to this data, do not delete."
          }
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button1Action={() => setConfirmModal(null)}
          button2Action={archiveOnboardingChecklists}
          button2ClassName={"button-3"}
        />
      )}
    </div>
  );
};

export default ChecklistsTable;
