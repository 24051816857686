import { MiterAPI } from "dashboard/miter";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, ConfirmModal, DropdownButton, Notifier, Toggler } from "ui";
import { AggregatedForm } from "dashboard/miter";

import { styles } from "miter-components/forms";
import { CaretDown, Copy, PencilSimpleLine, Question, Trash } from "phosphor-react";
import DataBox from "dashboard/components/dataBox/DataBox";
import { DateTime } from "luxon";
import FormSubmissionTable from "dashboard/components/forms/FormSubmissionsTable";
import FormWizard, { FormWizardMode } from "dashboard/components/forms/FormWizard";
import { FormQuestionsTable } from "dashboard/components/forms/FormQuestionsTable";
import { useCompanyUserOptions } from "dashboard/hooks/atom-hooks";
import { keyBy, truncate } from "lodash";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { notNullish } from "miter-utils";

export const Form: React.FC = () => {
  const { id, view } = useParams<{ id: string; view: string }>();
  const { can, cannot } = useMiterAbilities();

  const [form, setForm] = useState<AggregatedForm>();
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [editing, setEditing] = useState<FormWizardMode>();
  const navigate = useNavigate();

  const userOptions = useCompanyUserOptions();
  const userOptionsLookup = useMemo(() => keyBy(userOptions, "value"), [userOptions]);

  useEffect(() => {
    if (!view) navigate("/forms/" + id + "/submissions", { replace: true });
  }, [view]);

  useEffect(() => {
    getForm();
  }, [id]);

  const getForm = async () => {
    if (!id) return;

    try {
      const forms = await MiterAPI.forms.search([{ field: "_id", value: id, type: "_id" }]);
      if (forms.error) throw new Error(forms.error);
      if (!forms[0]) throw new Error("Form not found");

      setForm(forms[0]);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error fetching the form. We're looking into it.");
    }
  };

  const permissions = useMemo(() => {
    const hasPermissions = form?.sensitive ? can("forms:forms:manage_sensitive") : can("forms:forms:manage");

    return {
      canSendSubmissionRequests: hasPermissions,
      canEditForm: hasPermissions,
      canDeleteForm: hasPermissions,
    };
  }, [form]);

  const duplicateForm = async () => {
    if (cannot("forms:forms:manage")) {
      Notifier.error("You do not have permission to manage forms");
      return;
    }

    if (!form) return;

    try {
      const duplicatedForm = await MiterAPI.forms.duplicate(form._id);
      if (duplicatedForm.error) throw new Error(duplicatedForm.error);

      Notifier.success("Form duplicated!");
      navigate("/forms/" + duplicatedForm._id);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error duplicating the form. We're looking into it.");
    }
  };

  const archiveForm = async () => {
    if (cannot("forms:forms:manage")) {
      Notifier.error("You do not have permission to manage forms");
      return;
    }

    if (!form) return;
    setArchiving(true);

    try {
      const archivedForm = await MiterAPI.forms.delete(form._id);
      if (archivedForm.error) throw new Error(archivedForm.error);

      Notifier.success("Form deleted!");
      navigate("/forms");
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error deleting the form. We're looking into it.");
    } finally {
      setArchiving(false);
    }
  };

  /*********************************************************
   *  Toggler config
   **********************************************************/
  const handleToggle = (option) => {
    if (!form) return;
    navigate("/forms/" + form._id + "/" + option);
  };

  /*********************************************************
   *  Helper functions
   **********************************************************/
  const getUsersToNotifyOnSubmission = () => {
    const userLabels = form?.users_to_notify_on_submission
      ?.map((user) => userOptionsLookup[user]?.label)
      .filter(notNullish);

    // If there are more than 3 users, only show the first 3 and add a +X more label
    if (userLabels && userLabels?.length > 3) {
      return userLabels.slice(0, 3).join(", ") + `, +${userLabels.length - 3} more`;
    }

    return userLabels?.join(", ");
  };

  /*********************************************************
   *  Render Functions
   **********************************************************/

  const togglerConfig = [
    {
      path: "submissions",
      label: "Submissions",
    },
    {
      path: "questions",
      label: "Questions",
    },
    {
      path: "details",
      label: "Details",
    },
  ];

  const renderBreadcrumbs = () => {
    if (!form) return;

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Forms", path: "/forms" },
          { label: form.name || "Untitled Form", path: "/forms/" + form._id },
        ]}
      />
    );
  };

  const renderActions = () => {
    if (!form) return;

    const actions = [
      {
        label: "Edit Questions",
        action: () => setEditing("edit-questions"),
        icon: <Question style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => can("forms:forms:manage") && !!permissions.canEditForm,
      },
      {
        label: "Edit Details",
        action: () => setEditing("edit-settings"),
        icon: <PencilSimpleLine style={{ marginBottom: -2, marginRight: 7 }} />,
      },
      {
        label: "Duplicate form",
        action: duplicateForm,
        icon: <Copy style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => can("forms:forms:manage"),
      },
      {
        label: "Delete Form",
        action: () => setShowArchiveModal(true),
        icon: <Trash style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => can("forms:forms:manage") && !!permissions.canDeleteForm,
      },
    ];

    return (
      <div className={styles["actions"]}>
        <DropdownButton className={"button-1"} options={actions} closeOnClick={true}>
          Actions
          <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
        </DropdownButton>
      </div>
    );
  };

  const renderDetails = () => {
    if (!form) return;

    const createdAt = DateTime.fromSeconds(form.created_at).toLocaleString(DateTime.DATETIME_MED);
    const updatedAt = DateTime.fromSeconds(form.updated_at).toLocaleString(DateTime.DATETIME_MED);

    return (
      <>
        <div className="vertical-spacer" />

        <DataBox
          title={"Details"}
          onEdit={can("forms:forms:manage") ? () => setEditing("edit-settings") : undefined}
        >
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Description </span>
            <span className={"data-box-section-value font-14"}>
              {truncate(form?.description, { length: 100 })}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Created at</span>
            <span className={"data-box-section-value font-14"}>{createdAt}</span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Updated at</span>
            <span className={"data-box-section-value font-14"}>{updatedAt}</span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Allow multiple submissions</span>
            <span className={"data-box-section-value font-14"}>
              {form?.allow_multiple_submissions ? "Yes" : "No"}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Allow unrequested submissions</span>
            <span className={"data-box-section-value font-14"}>
              {form?.allow_unrequested_submissions ? "Yes" : "No"}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Users to notify on submission</span>
            <span className={"data-box-section-value font-14"}>{getUsersToNotifyOnSubmission()}</span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Editable submissions</span>
            <span className={"data-box-section-value font-14"}>
              {form?.allow_editable_submissions ? "Yes" : "No"}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Sensitive</span>
            <span className={"data-box-section-value font-14"}>{form?.sensitive ? "Yes" : "No"}</span>
          </div>
        </DataBox>
      </>
    );
  };

  const renderTitle = () => {
    return (
      <h1 className="view-title" style={{ marginTop: 10 }}>
        {form?.name || "Untitled Form"}
      </h1>
    );
  };

  const renderSubmissions = () => {
    return form && <FormSubmissionTable form={form} getForm={getForm} />;
  };

  const renderQuestions = () => {
    return form && <FormQuestionsTable form={form} />;
  };

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>Form | Miter</title>
        </Helmet>
        <div className={"page-content-header " + styles["header"]} style={{ marginTop: 5 }}>
          {renderBreadcrumbs()}
          {renderTitle()}
          {renderActions()}
        </div>

        <Toggler config={togglerConfig} toggle={handleToggle} active={view} />
        {view === "submissions" && renderSubmissions()}
        {view === "questions" && renderQuestions()}
        {view === "details" && renderDetails()}

        {showArchiveModal && (
          <ConfirmModal
            onYes={archiveForm}
            onNo={() => setShowArchiveModal(false)}
            body={"Are you sure you want to delete this form?"}
            loading={archiving}
          />
        )}
        {editing && (
          <FormWizard
            form={form}
            onExit={() => setEditing(undefined)}
            onComplete={() => {
              getForm();
              setEditing(undefined);
              navigate("/forms/" + form?._id + "/submissions");
            }}
            mode={editing}
          />
        )}
      </div>
    </>
  );
};
