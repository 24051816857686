import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { AggregatedTeamMember, CreatePostTaxDeductionParams, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Button, ModalHeader } from "ui";
import { ModalFooter } from "ui";
import PostTaxDeductionForm from "./PostTaxDeductionForm";
import { ConfirmModal } from "ui";
import Banner from "dashboard/components/shared/Banner";
import { displayFieldErrors } from "dashboard/utils/errors";
import { PtdTableEntry } from "./PostTaxDeductions";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";
import { usePostTaxDeductionAbilities } from "dashboard/hooks/abilities-hooks/usePostTaxDeductionAbilities";
import { useActiveCompanyId, useIsSuperAdmin } from "dashboard/hooks/atom-hooks";
import { AuditLogHistoryModal } from "dashboard/components/audit-logs/AuditLogHistoryModal";
import { isPtdConnectedToExternalSource } from "../benefits/benefitsUtils";

type Props = {
  teamMember?: AggregatedTeamMember;
  ptd?: PtdTableEntry | null;
  onHide: () => void;
  onSubmit: () => void;
};

const PostTaxDeductionModal: React.FC<Props> = ({ ptd, teamMember, onHide, onSubmit }: Props) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const form = useForm();
  const { handleSubmit, setError } = form;

  const { enIntegration } = useEmployeeNavigator();
  const ptdAbilities = usePostTaxDeductionAbilities();
  const activeCompanyId = useActiveCompanyId();
  const isSuperAdmin = useIsSuperAdmin();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);

  const readOnly = !!(
    (ptd && ptdAbilities.cannot("update", ptd)) ||
    isPtdConnectedToExternalSource(ptd, activeCompanyId)
  );

  /*********************************************************
   *  Functions for fetching, updating, and preparing data
   **********************************************************/
  const prepareData = (data) => {
    const payload: CreatePostTaxDeductionParams = {};
    const category = ptd ? ptd.check_post_tax_deduction.type : data.category.value;

    const ptdEmployee = teamMember?._id || ptd?.employee || data?.employee?.value;
    if (!ptdEmployee) throw Error("No employee found for post-tax deduction");

    if (!ptd) {
      payload.employee = ptdEmployee;
      payload.type = category;
    }
    payload.gl_account_id = data.gl_account?.value || null;

    payload.effective_start = data.effective_start?.toISODate() || null;
    payload.effective_end = data.effective_end?.toISODate() || null;
    payload.vendor_id = data.vendor_id?.value;

    if (category === "child_support") {
      payload.description = data.description || "Child support";

      payload.child_support = {
        external_id: data.external_id,
        agency: data.agency?.value,
        issue_date: data.issue_date?.toISODate(),
        amount: data.amount,
        max_percent: data.max_percent,
      };

      if ((data.fips_code && data.fips_code.length > 0) || ptd) {
        payload.child_support.fips_code = data.fips_code || null;
      }

      payload.managed = data.managed?.value === "true";
    } else if (category === "miscellaneous") {
      payload.description = data.description;

      payload.miscellaneous = {};
      payload.miscellaneous.total_amount =
        data.total_amount && data.total_amount.length > 0 ? data.total_amount : null;

      if (data.calculation_type.value === "dollar_amount") {
        payload.miscellaneous.amount = data.calculation_value;
      } else if (data.calculation_type.value === "percent") {
        payload.miscellaneous.percent = data.calculation_value;
      }
      payload.is_401k_loan = data.is_401k_loan?.value === "true";
      if (
        enIntegration &&
        enIntegration.connection &&
        data.integrations?.employee_navigator?.deduction_code
      ) {
        payload.integrations = {
          employee_navigator: {
            integration_connection_id: enIntegration.connection._id.toString(),
            deduction_code: data.integrations.employee_navigator.deduction_code.value,
            connected: ptd?.integrations?.employee_navigator?.connected || false,
          },
        };
      }
    }

    return payload;
  };

  const createPostTaxDeduction = async (data) => {
    setLoading(true);
    try {
      const payload = prepareData(data);
      const response = await MiterAPI.post_tax_deductions.create(payload);

      if (response.error) {
        if (response.fields) displayFieldErrors(response.fields, setError);
        throw Error(response.error);
      }

      Notifier.success("Created post-tax deduction.");
      onSubmit();
    } catch (e: $TSFixMe) {
      console.log("Error creating deduction:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updatePostTaxDeduction = async (data) => {
    if (!ptd) return;
    if (ptdAbilities.cannot("update", ptd)) {
      Notifier.error("You do not have permission to update post-tax deductions.");
      return;
    }

    setLoading(true);
    try {
      const payload = prepareData(data);
      const response = await MiterAPI.post_tax_deductions.update(ptd._id, payload);

      if (response.error) {
        if (response.fields) displayFieldErrors(response.fields, setError);
        throw Error(response.error);
      }

      Notifier.success("Updated post-tax deduction.");
      onSubmit();
    } catch (e: $TSFixMe) {
      console.log("Error updating deduction:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const deletePostTaxDeduction = async () => {
    if (!ptd) return;
    if (ptdAbilities.cannot("delete", ptd)) {
      Notifier.error("You do not have permission to delete post-tax deductions.");
      return;
    }

    setLoading(true);
    try {
      const response = await MiterAPI.post_tax_deductions.delete(ptd._id);
      if (response.error) throw Error(response.error);
      Notifier.success("Deleted post-tax deduction.");
      onSubmit();
    } catch (e: $TSFixMe) {
      console.log("Error deleting deduction:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
    setShowDeleteConfirmation(false);
  };

  /*********************************************************
   *  Handler functions for the moda
   **********************************************************/

  const buildPostTaxDeduction = async (data) => {
    if (!ptd) {
      await createPostTaxDeduction(data);
    } else {
      await updatePostTaxDeduction(data);
    }
  };

  const handleDelete = () => setShowDeleteConfirmation(true);
  const hideDeleteConfirmation = () => setShowDeleteConfirmation(false);

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const modalTitle = (() => {
    let headerText = "";
    if (ptd) {
      headerText = "Update Post-Tax Deduction";
    } else {
      headerText = "New Post-Tax Deduction";
    }

    return (
      <div className="flex space-between width-100-percent">
        {headerText}
        {ptd && isSuperAdmin && (
          <Button
            className="button-1"
            onClick={() => setShowAuditLogModal(true)}
            text="View Audit Log"
            style={{ marginLeft: "auto", marginRight: 10 }}
          />
        )}
      </div>
    );
  })();

  const renderBanner = () => {
    if (ptd?.guideline_id) {
      return (
        <Banner
          content={"Note: This deduction is managed by Guideline."}
          type="modal"
          onClick={() => window.open("https://guideline.com/", "_blank")}
        />
      );
    } else if (ptd?.integrations?.employee_navigator?.connected) {
      return (
        <Banner
          content={"Manage this deduction in Employee Navigator."}
          type="modal"
          onClick={() => window.open("https://employeenavigator.com/", "_blank")}
        />
      );
    } else if (ptd?.integrations?.payroll_integrations?.external_id) {
      return <Banner content={"Manage this deduction in your 401(k) provider."} type="modal" />;
    } else if (ptd?.integrations?.clasp?.clasp_benefit?.id) {
      return <Banner content={"Manage this deduction in the benefits administration tab."} type="modal" />;
    }
    return <></>;
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form two-column">
          <ModalHeader heading={modalTitle} onHide={onHide} className={"post-tax-deduction"} />
          {renderBanner()}
          <PostTaxDeductionForm ptd={ptd} teamMember={teamMember} readOnly={readOnly} form={form} />
          <ModalFooter
            loading={loading}
            className={"form"}
            cancelText={"Cancel"}
            onCancel={onHide}
            submitText={ptd ? "Save" : "Create"}
            showDelete={!!ptd && !readOnly && ptdAbilities.can("delete", ptd)}
            hideCancel={readOnly}
            hideSubmit={false}
            onDelete={ptd ? handleDelete : undefined}
            onSubmit={handleSubmit(buildPostTaxDeduction)}
          />
        </div>
      </ClickAwayListener>
      {showDeleteConfirmation && (
        <DeleteDeductionModal
          onDelete={deletePostTaxDeduction}
          onCancel={hideDeleteConfirmation}
          loading={loading}
        />
      )}
      {showAuditLogModal && ptd && (
        <AuditLogHistoryModal
          itemId={ptd._id}
          type="post_tax_deduction"
          onHide={() => setShowAuditLogModal(false)}
        />
      )}
    </div>
  );
};

export default PostTaxDeductionModal;

const generateDeletePtdDescription = (multi: boolean | undefined) => {
  return (
    <>
      Are you sure you want to delete {multi ? "these" : "this"} post-tax deduction{multi ? "s" : ""}?{" "}
      <strong>This action is permanent.</strong>
      <br />
      <br />
      Consider setting the end date to be yesterday instead. Deductions should only be deleted if they should
      not have been created in the first place.
    </>
  );
};

export const DeleteDeductionModal: React.FC<{
  onDelete: () => Promise<void> | void;
  onCancel: () => void;
  loading?: boolean;
  multi?: boolean;
}> = ({ multi, onDelete, onCancel, loading }) => {
  return (
    <ConfirmModal
      title={`Delete ${multi ? "deductions" : "deduction"}`}
      body={generateDeletePtdDescription(multi)}
      yesText="Delete"
      onYes={onDelete}
      onNo={onCancel}
      loading={loading}
      yellowBodyText
    />
  );
};
