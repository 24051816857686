import React, { useEffect, useMemo, useState } from "react";
import { reportList } from "../reportList";
import { Formblock, Toggler } from "ui";
import { useNavigate } from "react-router";
import {
  AggregatedMiterEarning,
  Company,
  MiterAPI,
  MiterError,
  MiterFilterField,
  PaySchedule,
} from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { GridApi } from "ag-grid-community";
import { Option } from "ui/form/Input";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { HoursByPayPeriodResponse } from "backend/utils/reports/hoursByPayPeriod";
import { HoursByPayPeriodTable } from "./HoursByPayPeriodTable";
import {
  useActiveCompany,
  useActiveCompanyId,
  useLookupPaySchedule,
  usePayScheduleOptions,
  usePaySchedules,
  useTeam,
} from "dashboard/hooks/atom-hooks";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import {
  RequestTableEntry,
  TimeOffRequestsTable,
  cleanTimeOffRequests,
} from "dashboard/pages/time-off/TimeOffRequestsTable";
import { excludeLiveTimesheetsToggle } from "dashboard/utils/timesheetUtils";
import { PayPeriod, getPayPeriodsOfPaySchedule } from "dashboard/utils/paySchedules";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";

const togglerConfig = [
  { path: "hours", label: "Hours" },
  { path: "timesheets", label: "Timesheets" },
  { path: "time_off", label: "Time off" },
];

export const getPayPeriodsForHoursByPayPeriod = (paySchedule: PaySchedule, company: Company): PayPeriod[] => {
  const now = DateTime.now().startOf("day");
  const companyCreated = DateTime.fromSeconds(company.created_at).minus({ months: 1 }).startOf("day");
  const firstPeriodEnd = DateTime.fromISO(paySchedule.check_pay_schedule.first_period_end);
  const genesis = firstPeriodEnd > companyCreated ? companyCreated : firstPeriodEnd;
  const periods = getPayPeriodsOfPaySchedule(paySchedule, genesis, now);
  return periods.reverse();
};

export const PayPeriodHours: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();
  const form = useForm();
  const teamMembers = useTeam();

  const timesheetAbilities = useTimesheetAbilities();
  const timeOffRequestAbilities = useTimeOffRequestAbilities();

  const paySchedules = usePaySchedules();
  const lookupPaySchedule = useLookupPaySchedule();
  const psOptions = usePayScheduleOptions();

  const [selectedPaySchedule, setSelectedPaySchedule] = useState(() => {
    if (!paySchedules) return;
    const defaultPs = paySchedules.find((ps) => ps.default);
    return psOptions.find((option) => option.value === defaultPs?._id);
  });

  const [data, setData] = useState<AggregatedMiterEarning[] | undefined>();
  const [fetchingData, setFetchingData] = useState(false);
  const [selectedPayPeriodOption, setSelectedPayPeriodOption] = useState<Option<string>>();
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [redirectToPayroll, setRedirectToPayroll] = useState<string | undefined>();
  const [activeView, setActiveView] = useState("hours");
  const [timeOffRequests, setTimeOffRequests] = useState<RequestTableEntry[]>([]);

  const payPeriods = useMemo(() => {
    const ps = lookupPaySchedule(selectedPaySchedule?.value);
    if (!ps || !activeCompany) return [];
    return getPayPeriodsForHoursByPayPeriod(ps, activeCompany);
  }, [selectedPaySchedule?.value, lookupPaySchedule]);

  const payPeriodOptions = useMemo(() => {
    return payPeriods.map((p) => {
      const startString = DateTime.fromISO(p.periodStart).toFormat("MMM dd");
      const endString = DateTime.fromISO(p.periodEnd).toFormat("MMM dd, yyyy");
      return { label: `${startString} to ${endString}`, value: p.periodEnd };
    });
  }, [payPeriods]);

  const selectedPayPeriod = useMemo(() => {
    if (!selectedPayPeriodOption?.value) return;
    const payPeriod = payPeriods.find((p) => p.periodEnd === selectedPayPeriodOption?.value);
    if (!payPeriod) throw new Error("No pay period selected");
    return payPeriod;
  }, [selectedPayPeriodOption]);

  const getEarnings = async () => {
    if (!selectedPayPeriod || !activeCompanyId) return;
    setFetchingData(true);
    try {
      const payload = {
        type: "pay_period_hours",
        params: {
          payScheduleId: selectedPaySchedule?.value,
          companyId: activeCompanyId,
          periodStart: selectedPayPeriod.periodStart,
          periodEnd: selectedPayPeriod.periodEnd,
          includeNonPayrollTeamMembers: true,
        },
        format: "json",
      };
      const response: HoursByPayPeriodResponse & MiterError = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      setRedirectToPayroll(response.payrollId);
      // @ts-expect-error _id is a string, not ObjectId
      setData(response.earnings);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    setFetchingData(false);
  };

  const getTimeOffRequests = async () => {
    if (!selectedPayPeriod || !activeCompanyId) return;
    try {
      const response = await MiterAPI.time_off.requests.retrieve_for_pay_period({
        periodStart: selectedPayPeriod.periodStart,
        periodEnd: selectedPayPeriod.periodEnd,
        companyId: activeCompanyId,
        includeUnapproved: true,
        payScheduleId: selectedPaySchedule?.value,
      });
      if (response.error) throw new Error(response.error);

      const filteredTimeOffRequests = response.filter((request) =>
        timeOffRequestAbilities.can("read", request)
      );

      setTimeOffRequests(cleanTimeOffRequests(filteredTimeOffRequests));
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
  };

  useEffect(() => {
    getEarnings();
    getTimeOffRequests();
  }, [selectedPayPeriod]);

  const reportObject = reportList.find((report) => report.slug === "pay_period_hours");

  const handlePayScheduleChange = (option) => {
    if (option === selectedPaySchedule) return;
    setSelectedPaySchedule(option);
    setSelectedPayPeriodOption(undefined);
  };

  const timesheetsFilter: MiterFilterField[] = useMemo(() => {
    if (!selectedPayPeriod || !activeCompanyId || !activeCompany) return [];

    // Get the payroll start and end times
    const start = DateTime.fromISO(selectedPayPeriod.periodStart, {
      zone: activeCompany.timezone,
    }).startOf("day");

    const end = DateTime.fromISO(selectedPayPeriod.periodEnd, {
      zone: activeCompany.timezone,
    }).endOf("day");

    const abilitiesFilter = timesheetAbilities.filter("read");

    const tmIds = teamMembers
      .filter((tm) => {
        return (
          !tm.archived &&
          (!tm.start_date || tm.start_date <= selectedPayPeriod.periodEnd) &&
          (!tm.end_date || tm.end_date >= selectedPayPeriod.periodStart) &&
          tm.pay_schedule_id === selectedPaySchedule?.value
        );
      })
      .map((tm) => tm._id);

    return [
      {
        field: "company",
        value: activeCompanyId,
        type: "string",
      },
      {
        field: "status",
        value: ["unapproved", "approved"],
        comparisonType: "in",
        type: "string",
      },
      {
        field: "clock_in",
        value: [start.toSeconds(), end.toSeconds()],
        comparisonType: "<+>",
        type: "number",
      },
      {
        field: "team_member",
        value: tmIds,
        comparisonType: "in",
        type: "string",
      },
      ...(abilitiesFilter ? [abilitiesFilter] : []),
    ];
  }, [selectedPayPeriod, activeCompanyId, selectedPaySchedule]);

  useEffect(() => {
    if (fetchingData) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [fetchingData]);

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Hours by pay period</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}*</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 400 }}>
        <Formblock
          label="Pay schedule"
          type="select"
          options={psOptions}
          name="pay_schedule"
          value={selectedPaySchedule}
          form={useForm()}
          editing={true}
          onChange={handlePayScheduleChange}
        />
        <Formblock
          label="Pay period"
          type="select"
          options={payPeriodOptions}
          name="pay_schedule"
          value={selectedPayPeriodOption}
          form={form}
          editing={payPeriodOptions.length > 0}
          onChange={setSelectedPayPeriodOption}
        />
      </div>
      <>
        {selectedPayPeriod && (
          <>
            {redirectToPayroll && (
              <div style={{ marginTop: 15 }} className="yellow-text-container">
                <strong>A reminder</strong>: This report only includes hours from approved timesheets. View
                <span
                  className="blue-link"
                  onClick={() => navigate("/payrolls/" + redirectToPayroll + "/workweek")}
                >
                  {` the payroll `}
                </span>
                to see all hours for this pay period.
              </div>
            )}
            <Toggler secondary={false} config={togglerConfig} active={activeView} toggle={setActiveView} />
            {activeView === "hours" && (
              <HoursByPayPeriodTable
                earnings={data}
                gridApi={gridApi}
                setGridApi={setGridApi}
                periodStart={selectedPayPeriod?.periodStart}
                payScheduleId={selectedPaySchedule?.value}
                periodEnd={selectedPayPeriod?.periodEnd}
              />
            )}
            {activeView === "timesheets" && (
              <TimesheetsTable
                defaultFilters={timesheetsFilter}
                showToggler={true}
                showMiniTitle={false}
                secondaryToggler={true}
                togglerConfigFilter={excludeLiveTimesheetsToggle}
                onChange={getEarnings}
              />
            )}
            {activeView === "time_off" && (
              <TimeOffRequestsTable
                defaultFilters={timesheetsFilter}
                showToggler={true}
                onDataChange={getEarnings}
                providedTimeOffRequests={timeOffRequests}
              />
            )}
          </>
        )}
        <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
          * Includes earnings associated with paid, processing, and pending payrolls.
        </div>
        <div className="vertical-spacer-large"></div>
      </>
    </div>
  );
};
