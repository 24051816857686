import AppContext from "dashboard/contexts/app-context";
import { useTeam } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import React, { useEffect, useState } from "react";
import { Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";

type Props = {};

type TableEntry = {
  _id: string;
  last_name: string;
  first_name: string;
  ssn: string;
  dob?: string;
  start_date?: string;
  end_date: string;
  rehire_date: string;
  residence?: {
    line1: string;
    line2?: string | null | undefined;
    city: string;
    state: string;
    postal_code: string;
    country?: string | undefined;
  } | null;
  phone: string;
  email?: string | null;
};

export const ContractorsPlanCensus: React.FC<Props> = () => {
  const { setReverifyUser, setOnReverifyUser } = React.useContext(AppContext);
  const teamMembers = useTeam();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<TableEntry[] | undefined>();

  const getSsns = async () => {
    setLoading(true);
    try {
      const tmIds = teamMembers.map((tm) => tm._id);
      const response = await MiterAPI.team_member.retrieve_raw_ssns(tmIds);
      if (response.error) throw new Error(response.error);
      const tmsForTable: TableEntry[] = [];
      const employees = teamMembers.filter((tm) => tm.employment_type === "employee");
      for (const tm of employees) {
        const tmSsn = response[tm._id] || "-";
        tmsForTable.push({
          _id: tm._id,
          last_name: tm.last_name,
          first_name: tm.first_name,
          ssn: tmSsn,
          dob: tm.dob || "-",
          start_date: tm.start_date || "-",
          end_date: tm.end_date || "-",
          rehire_date: "-",
          residence: tm.address,
          phone: tm.phone || "-",
          email: tm.email,
        });
      }
      setTableData(tmsForTable);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error retrieving employee data.`);
    }
    setLoading(false);
  };

  useEffect(() => {
    setReverifyUser(true);
    setOnReverifyUser(() => getSsns);
  }, []);

  return (
    <div>
      <TableV2
        id="cp-employee-census"
        resource="team members"
        data={tableData}
        columns={columns}
        paginationPageSize={30}
        isLoading={loading}
      />
    </div>
  );
};

export const columns: ColumnConfig<TableEntry>[] = [
  {
    field: "last_name",
    headerName: "Last name",
    dataType: "string",
  },
  {
    field: "first_name",
    headerName: "First name",
    dataType: "string",
  },
  {
    field: "ssn",
    headerName: "SSN",
    dataType: "string",
  },
  {
    field: "dob",
    headerName: "Date of birth",
    dataType: "string",
  },
  {
    field: "start_date",
    headerName: "Date of hire",
    dataType: "string",
  },
  {
    field: "end_date",
    headerName: "Date of termination",
    dataType: "string",
  },
  {
    field: "rehire_date",
    headerName: "Date of rehire",
    dataType: "string",
  },
  {
    field: "residence.line1",
    headerName: "Address line 1",
    dataType: "string",
  },
  {
    field: "residence.line2",
    headerName: "Address line 2",
    dataType: "string",
  },
  {
    field: "residence.city",
    headerName: "City",
    dataType: "string",
  },
  {
    field: "residence.state",
    headerName: "State",
    dataType: "string",
  },
  {
    field: "residence.postal_code",
    headerName: "Zip code",
    dataType: "string",
  },
  {
    field: "phone",
    headerName: "Phone",
    dataType: "string",
  },
  {
    field: "email",
    headerName: "Email address",
    dataType: "string",
  },
];
