import { ModalHeader } from "ui";
import React from "react";

type Props = {
  headerText: React.ReactNode;
  link: string | undefined;
  onHide: () => void;
};

const SimplyInsuredModal: React.FC<Props> = ({ headerText, link, onHide }) => {
  return (
    <div className="modal-background">
      <div className="modal-simply-insured" style={{ width: 1100 }}>
        <ModalHeader heading={headerText} onHide={onHide} className="modal" />
        <iframe
          src={link}
          style={{
            minWidth: "1100px",
            minHeight: "550px",
          }}
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default SimplyInsuredModal;
