import { MiterAPI, File as MiterFile, TeamMember, ESignatureItem } from "dashboard/miter";
import { convertHeicToPng, isImageFile, Notifier, base64toBlob, addressString } from "dashboard/utils";
import { capitalize } from "lodash";
import { Notebook, CheckSquareOffset, CloudSun, Check, X, Spinner, Truck, Clipboard } from "phosphor-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ActionModal, Badge, ConfirmModal, Label, Loader, LoadingModal } from "ui";
import { FilePickerFiles } from "ui/form/FilePicker";
import styles from "./DailyReports.module.css";
import { AggregatedDailyReport } from "dashboard/miter";
import PhotoExplorer from "../photo-explorer/PhotoExplorer";
import { MemoizedDailyReportTimesheets } from "./DailyReportTimesheets";
import DailyReportWeather from "./DailyReportWeather";
import { DateTime } from "luxon";
import { MemoizedDailyReportEquipmentLogs } from "./DailyReportEquipmentLogs";
import { saveAs } from "file-saver";
import emptyVault from "dashboard/assets/empty-vault.svg";
import { FaSignature } from "react-icons/fa";
import { SendMessageModal } from "../sendMessageModal/SendMessageModal";
import { TimesheetTableEntry, cleanTimesheetsFromBackend } from "dashboard/utils/timesheetUtils";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { deparameterize } from "miter-utils";
import { ESignatureModal } from "../esignatures/ESignatureModal";
import { TagBadges } from "../shared/TagBadge";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useDailyReportAbilities } from "dashboard/hooks/abilities-hooks/useDailyReportAbilities";
import EmptyState from "ui/empty-state/EmptyState";

type DailyReportAttachment = {
  id: string;
  url: string;
  type: string;
  file: MiterFile;
  team_member?: TeamMember;
  date: string;
};

type Props = {
  dailyReportId: string;
  onHide: () => void;
  onChange: (dailyReport: AggregatedDailyReport) => void;
};

const DailyReportModal: React.FC<Props> = ({ dailyReportId, onHide, onChange }) => {
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();
  const radiusMiles = activeCompany?.settings.timesheets.geofence_radius;

  const { can, cannot } = useDailyReportAbilities();

  const [activeSidebarItem, setActiveSidebarItem] = useState<string>("Timesheets");
  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState<DailyReportAttachment[]>([]);

  const [dailyReport, setDailyReport] = useState<AggregatedDailyReport>();

  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [sendingPDF, setSendingPDF] = useState(false);
  const [viewingSignature, setViewingSignature] = useState<ESignatureItem | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const [timesheets, setTimesheets] = useState<TimesheetTableEntry[]>(
    cleanTimesheetsFromBackend(dailyReport?.timesheets || [], undefined, radiusMiles)
  );
  const [invalidatingESignatures, setInvalidatingESignatures] = useState(false);
  const [confirmInvalidatingESignatures, setConfirmInvalidatingESignatures] = useState(false);

  const enableMultiDayReports = !!activeCompany?.settings?.daily_reports?.enable_multi_day_reports;

  // Build work dates
  const startWork = dailyReport ? DateTime.fromISO(dailyReport?.start_date) : undefined;
  const endWork = dailyReport ? DateTime.fromISO(dailyReport?.end_date) : undefined;

  const showDateRange = enableMultiDayReports && dailyReport?.start_date !== dailyReport?.end_date;

  const workDates =
    showDateRange && startWork && endWork
      ? `${startWork.toFormat("EEE LLL dd")} - ${endWork.toFormat("EEE LLL dd, yyyy")}`
      : `${startWork?.toFormat("EEE LLL dd, yyyy")}`;

  const getDailyReport = async () => {
    try {
      const response = await MiterAPI.daily_reports.retrieve(dailyReportId);
      if (response.error) throw new Error(response.error);

      setDailyReport(response);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("We were unable to get your daily report. Please contact support.");
    }
  };

  useEffect(() => {
    if (activeSidebarItem === "Photos" || activeSidebarItem === "Documents") {
      getPhotos();
    } else {
      setLoading(false);
    }
  }, [activeSidebarItem]);

  useEffect(() => {
    getDailyReport();
  }, [dailyReportId]);

  useEffect(() => {
    setTimesheets(cleanTimesheetsFromBackend(dailyReport?.timesheets || [], undefined, radiusMiles));
  }, [dailyReport, radiusMiles]);

  const getPhotos = async () => {
    if (!dailyReport) return;
    setLoading(true);

    try {
      const files = [
        ...dailyReport?.files.map((f) => f._id),
        ...(timesheets?.flatMap((ts) => ts.images) || []),
      ];

      const response = await MiterAPI.files.get_urls({
        filter: [
          {
            field: "_id",
            value: files,
            type: "_id",
            comparisonType: "in",
          },
        ],
      });
      if (response.error) throw new Error(response.error);

      const attachmentURLs: DailyReportAttachment[] = [];
      for (const image of response.urls as $TSFixMe) {
        const fileId = image.file._id;

        let url = image.value.url;

        if (image.value.url.toLowerCase().includes(".heic")) {
          url = await convertHeicToPng(image.value.url);
        }

        // If it's a photo, set type as photo, otherwise set type as document
        attachmentURLs.push({
          url: url,
          id: fileId,
          type: isImageFile(image.file.type) ? "photo" : "document",
          file: image.file,
          team_member:
            timesheets?.find((ts) => (ts.images || []).includes(fileId))?.team_member || dailyReport.creator,
          date: DateTime.fromSeconds(image.file.created_at).toFormat("EEEE DD"),
        });
      }
      setAttachments(attachmentURLs);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving this timesheet's photos.");
    }
    setLoading(false);
  };

  const getDailyReportPDF = async () => {
    if (!dailyReport) return;
    setDownloadingPDF(true);
    try {
      const res = await MiterAPI.daily_reports.retrieve_pdfs([dailyReport._id], {
        timezone: DateTime.local().zoneName,
      });

      if (res.error) throw new Error(res.error);

      const blobFile = base64toBlob(res.data, "application/pdf");
      saveAs(blobFile, res.filename);

      Notifier.success("Your daily report was successfully generated.");
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("We were unable to get your daily reports. Please contact support.");
    }

    setDownloadingPDF(false);
  };

  const invalidateESignatures = async () => {
    if (cannot("update", dailyReport)) {
      Notifier.error("You do not have permission to update this daily");
      return;
    }

    if (!dailyReport) return;
    setInvalidatingESignatures(true);
    try {
      const res = await MiterAPI.daily_reports.invalidate_esignatures(dailyReport._id);
      if (res.error) throw new Error(res.error);

      setConfirmInvalidatingESignatures(false);
      Notifier.success("The e-signature was successfully deleted.");
      setDailyReport(res);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("We were unable to delete this e-signature. Please contact support.");
    }
    setInvalidatingESignatures(false);
  };

  const handleEdit = () => {
    if (!dailyReport) return;
    navigate(`/daily-reports/${dailyReport._id}/edit`);
  };

  const handleStatusChange = async () => {
    if (cannot("approve", dailyReport)) {
      Notifier.error("You do not have permission to update this daily");
      return;
    }

    if (!dailyReport) return;
    setSubmitting(true);
    try {
      const res =
        dailyReport.status === "unapproved"
          ? await MiterAPI.daily_reports.approve(dailyReport._id)
          : await MiterAPI.daily_reports.unapprove(dailyReport._id);

      setDailyReport(res);
      onChange(res);
      Notifier.success(`Daily report is now ${res.status}`);
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      console.error("Unable to get daily report", err);
    }
    setSubmitting(false);
  };

  const handleInvalidateESignatures = async () => {
    setConfirmInvalidatingESignatures(true);
  };

  const renderESignBadgeContent = () => {
    if (!dailyReport) return;

    if (dailyReport.signature_status === "not_signed") {
      return (
        <>
          <X weight="fill" color={"black"} style={{ marginBottom: -2 }} />{" "}
          <span style={{ marginBottom: -2, display: "inline-block" }}>NOT SIGNED</span>
        </>
      );
    } else if (dailyReport.signature_status === "partially_signed") {
      return (
        <>
          <Spinner weight="fill" color={"black"} style={{ marginBottom: -2 }} />{" "}
          <span style={{ marginBottom: -2, display: "inline-block" }}>PARTIALLY SIGNED</span>
        </>
      );
    } else if (dailyReport.signature_status === "signed") {
      return (
        <>
          <Check weight="fill" color={"black"} style={{ marginBottom: -2 }} />{" "}
          <span style={{ marginBottom: -2, display: "inline-block" }}>SIGNED</span>
        </>
      );
    }
  };

  const actions: ActionLink[] = [
    {
      label: "Approve",
      action: handleStatusChange,
      important: true,
      loading: submitting,
      shouldShow: () => can("approve", dailyReport) && dailyReport?.status !== "approved",
    },
    {
      label: "Unapprove",
      action: handleStatusChange,
      important: true,
      loading: submitting,
      shouldShow: () => can("approve", dailyReport) && dailyReport?.status !== "unapproved",
    },
    {
      label: "Edit report",
      action: handleEdit,
      shouldShow: () => can("update", dailyReport) && dailyReport?.status === "unapproved",
    },
    {
      label: "Invalidate esignatures",
      action: handleInvalidateESignatures,
      loading: invalidatingESignatures,
      shouldShow: () => can("update", dailyReport) && dailyReport?.signature_status !== "not_signed",
    },
    {
      label: "Download PDF",
      action: () => getDailyReportPDF(),
    },
    {
      label: "Email PDF",
      action: () => setSendingPDF(true),
    },
  ];

  const renderHeader = () => {
    const signatureColorLookup = {
      not_signed: "red",
      partially_signed: "yellow",
      signed: "green",
    };

    return (
      <div className={styles["daily-report-header"]}>
        <div className={styles["daily-report-header-supervisor"] + " flex no-select"}>
          {dailyReport?.creator.full_name}&apos;s Report
        </div>
        <div style={{ fontWeight: "500", fontSize: "0.9rem", opacity: 1, marginTop: 7, display: "flex" }}>
          <span style={{ opacity: 0.6 }}>
            {workDates} - {dailyReport?.job.name}
          </span>
          <Badge
            text={dailyReport?.status}
            className={"badge-no-hover"}
            style={{ marginTop: -1, marginLeft: 10 }}
          />
          <Badge
            element={renderESignBadgeContent()}
            className={"badge-no-hover"}
            style={{ marginLeft: 0, marginTop: -1 }}
            color={signatureColorLookup[dailyReport?.signature_status || ""]}
          />
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    const items = [
      "Timesheets",
      "Work Items",
      "Equipment",
      "Notes",
      "Photos",
      "Documents",
      "Weather",
      "Signatures",
      "Metadata",
    ];

    return (
      <div className={styles["daily-report-sidebar"]}>
        {items.map((item, index) => (
          <div
            key={"item-" + index}
            className={
              styles["daily-report-sidebar-item"] + " " + (activeSidebarItem === item ? styles["active"] : "")
            }
            onClick={() => setActiveSidebarItem(item)}
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderWeather = () => {
    if (!dailyReport) return;

    if (!dailyReport.weather?.length) {
      if (!dailyReport.job.address && dailyReport.job.geolocation) {
        return (
          <EmptyState
            icon={<CloudSun weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
            title={"Weather data is not available"}
            description={"Please enter an address or geolocation to get weather data."}
            type="small"
          />
        );
      } else if (dailyReport.start_date > DateTime.now().toISODate()) {
        return (
          <EmptyState
            icon={<CloudSun weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
            title={"Weather data is not available"}
            description={"Weather data is not available until the day of the report."}
            type="small"
          />
        );
      } else {
        return (
          <EmptyState
            icon={<CloudSun weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
            title={"Weather data is not available"}
            description={"Please refresh the page to get weather data."}
            type="small"
          />
        );
      }
    }

    return (
      <div className={styles["daily-reports-weather-container"]}>
        {dailyReport.weather
          .filter((weather) => ["700", "1000", "1300", "1600", "1900", "2200"].includes(weather.hour))
          .map((weather) => (
            <DailyReportWeather key={weather._id} weather={weather} />
          ))}
      </div>
    );
  };

  const renderTimesheets = () => {
    if (dailyReport?.timesheets.length === 0) {
      return (
        <EmptyState
          icon={<Clipboard weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
          title={"No timesheets added"}
          description={"Add timesheets to this report to see them appear here."}
          type="small"
          ctaButtonAction={dailyReport.status === "unapproved" ? handleEdit : undefined}
          ctaButtonText={dailyReport.status === "unapproved" ? "Add timesheets" : undefined}
        />
      );
    }

    return (
      <MemoizedDailyReportTimesheets
        timesheets={timesheets}
        setTimesheets={setTimesheets}
        readOnly={true}
        report={dailyReport}
      />
    );
  };

  const renderEquipmentLogs = () => {
    if (!dailyReport) return;
    if (dailyReport.equipment_logs.length === 0) {
      return (
        <EmptyState
          icon={<Truck weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
          title={"No equipment logs added"}
          description={
            "Add equipment logs to your daily report to keep track of your equipment on the job site."
          }
          type="small"
          ctaButtonAction={dailyReport.status === "unapproved" ? handleEdit : undefined}
          ctaButtonText={dailyReport.status === "unapproved" ? "Add equipment logs" : undefined}
        />
      );
    }

    return <MemoizedDailyReportEquipmentLogs equipmentLogs={dailyReport.equipment_logs} readOnly={true} />;
  };

  const renderNotes = () => {
    if (!dailyReport) return;

    if (dailyReport.notes.length === 0) {
      return (
        <EmptyState
          icon={<Notebook weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
          title={"No notes added"}
          description={
            "Use notes to keep track of any additional important information you want to add to this report."
          }
          type="small"
          ctaButtonAction={dailyReport.status === "unapproved" ? handleEdit : undefined}
          ctaButtonText={dailyReport.status === "unapproved" ? "Add notes" : undefined}
        />
      );
    }

    return (
      <table className={styles["simple-table"]}>
        <colgroup>
          <col span={1} style={{ width: "20%" }} />
          {showDateRange ? <col span={1} style={{ width: "15%" }} /> : <></>}
          <col span={1} />
        </colgroup>

        <thead className={styles["simple-table-header"]}>
          <tr>
            <th>Type</th>
            {showDateRange ? <th>Date</th> : <></>}
            <th>Content</th>
          </tr>
        </thead>
        <tbody className={styles["simple-table-body"]}>
          {dailyReport.notes.map((note) => {
            return (
              <tr key={note._id}>
                <td>{capitalize(note.type)}</td>
                {showDateRange ? <td>{DateTime.fromISO(note.date).toFormat("EEE LLL, dd")}</td> : <></>}
                <td>{note.content}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderWorkItems = () => {
    if (!dailyReport) return;

    if (dailyReport.work_items.length === 0) {
      return (
        <EmptyState
          icon={<CheckSquareOffset weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
          title={"No work items added"}
          description={"Add work items to your daily report to keep track of work completed during the day."}
          type="small"
          ctaButtonAction={dailyReport.status === "unapproved" ? handleEdit : undefined}
          ctaButtonText={dailyReport.status === "unapproved" ? "Add work items" : undefined}
        />
      );
    }

    return (
      <table className={styles["simple-table"]}>
        <colgroup>
          <col span={1} style={{ width: "20%" }} />
          {showDateRange ? <col span={1} style={{ width: "15%" }} /> : <></>}
          <col span={1} />
        </colgroup>

        <thead className={styles["simple-table-header"]}>
          <tr>
            <th>Name</th>
            {showDateRange ? <th>Date</th> : <></>}
            <th>Description</th>
          </tr>
        </thead>
        <tbody className={styles["simple-table-body"]}>
          {dailyReport.work_items.map((workItem) => {
            return (
              <tr key={workItem._id}>
                <td>{capitalize(workItem.name)}</td>
                {showDateRange ? <td>{DateTime.fromISO(workItem.date).toFormat("EEE LLL, dd")}</td> : <></>}
                <td>{workItem.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  const renderPhotos = () => {
    if (!dailyReport) return;
    if (loading) return <Loader />;

    const photos = attachments.filter((attachment) => attachment.type === "photo");
    return (
      <PhotoExplorer
        photos={photos}
        photoClassName={styles["daily-report-photo"]}
        containerClassName={styles["daily-report-photos-container"]}
        paginationClassName={styles["daily-report-photos-pagination"]}
        emptyStateSubheader="Photos uploaded to your daily report will appear here."
        emptyStateClassName={styles["daily-report-photos-empty-state"]}
        // hideCaption={true}
        hideSearch={true}
      />
    );
  };

  const renderDocuments = () => {
    if (!dailyReport) return;
    if (loading) return <Loader />;

    const documents = attachments
      .filter((attachment) => attachment.type === "document")
      .map((document) => ({ data: document.file, url: document.url }));

    if (documents.length === 0) {
      return (
        <EmptyState
          image={emptyVault}
          title={"No documents found"}
          description={"Documents uploaded on this daily report will appear here."}
          type="modal"
        />
      );
    }

    return <FilePickerFiles files={documents} className={styles["documents"]} showDownloadIcon={true} />;
  };

  const renderSignatures = () => {
    if (!dailyReport) return;
    if (dailyReport.esignatures.length === 0) {
      return (
        <EmptyState
          icon={<FaSignature color="#4d55bb" style={{ fontSize: "3rem" }} />}
          title={"No signatures added"}
          description={"Add signature to the daily report via the mobile app."}
          type="small"
        />
      );
    }

    return (
      <table className={styles["simple-table"]}>
        <colgroup>
          <col span={1} style={{ width: "40%" }} />
          <col span={1} style={{ width: "25%" }} />
          <col span={1} style={{ width: "25%" }} />
          <col span={1} style={{ width: "10%" }} />
        </colgroup>

        <thead className={styles["simple-table-header"]}>
          <tr>
            <th>Signer</th>
            <th>Type</th>
            <th>Signed at</th>
            <th>Signature</th>
          </tr>
        </thead>
        <tbody className={styles["simple-table-body"]}>
          {dailyReport.esignatures
            .filter((e) => !!e.created_at)
            .map((esignature) => {
              const signedAt = esignature.events.find((e) => e.type === "signed")?.timestamp;

              return (
                <tr key={esignature._id}>
                  <td>
                    <p style={{ marginBottom: 3, marginTop: 0, fontSize: "0.9rem" }}>
                      {esignature.signer.name}
                    </p>
                    <p style={{ marginTop: 0, fontSize: "0.9rem", opacity: 0.5, marginBottom: 0 }}>
                      {esignature.signer?.title}
                    </p>
                  </td>
                  <td style={{ fontSize: "0.9rem" }}>{deparameterize(esignature.signer?.type)}</td>
                  <td style={{ fontSize: "0.9rem" }}>
                    {signedAt ? DateTime.fromSeconds(signedAt).toFormat("FF") : ""}
                  </td>
                  <td style={{ textAlign: "center", display: "flex" }}>
                    <button
                      onClick={() => setViewingSignature(esignature)}
                      className="button-1 no-margin"
                      style={{ height: 32 }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const renderMetadata = () => {
    if (!dailyReport) return;
    return (
      <div className={styles["daily-report-metadata"]}>
        <div className={styles["daily-report-metadata-item"]}>
          <Label label={"Identifier"} className={"form2-label modal"} />
          <span>{dailyReport.identifier || dailyReport._id}</span>
        </div>
        <div className={styles["daily-report-metadata-item"]}>
          <Label label={"Date"} className={"form2-label modal"} />
          <span>{workDates}</span>
        </div>
        <div className={styles["daily-report-metadata-item"]}>
          <Label label={"Creator"} className={"form2-label modal"} />
          <span>{dailyReport.creator.full_name}</span>
        </div>
        <div className={styles["daily-report-metadata-item"]}>
          <Label label={"Job"} className={"form2-label modal"} />
          <span>{dailyReport.job.name}</span>
        </div>
        <div className={styles["daily-report-metadata-item"]}>
          <Label label={"Address"} className={"form2-label modal"} />
          <span>{addressString(dailyReport.job.address)}</span>
        </div>
        <div className={styles["daily-report-metadata-item"]}>
          <Label label={"Tags"} className={"form2-label modal"} />
          <span className="flex">
            <TagBadges tags={dailyReport.tags} />
          </span>
        </div>
      </div>
    );
  };

  const renderSignatureModal = () => {
    if (!dailyReport) return;
    if (!viewingSignature) return <></>;
    if (!viewingSignature.created_at) return <></>;

    return (
      <ESignatureModal esignatureItem={viewingSignature} onHide={() => setViewingSignature(undefined)} />
    );
  };

  const renderContent = () => {
    return (
      <div className={styles["daily-report-content"]}>
        {activeSidebarItem === "Timesheets" && renderTimesheets()}
        {activeSidebarItem === "Work Items" && renderWorkItems()}
        {activeSidebarItem === "Equipment" && renderEquipmentLogs()}
        {activeSidebarItem === "Weather" && renderWeather()}
        {activeSidebarItem === "Notes" && renderNotes()}
        {activeSidebarItem === "Photos" && renderPhotos()}
        {activeSidebarItem === "Documents" && renderDocuments()}
        {activeSidebarItem === "Signatures" && renderSignatures()}
        {activeSidebarItem === "Metadata" && renderMetadata()}
      </div>
    );
  };

  const renderSendMessageModal = () => {
    if (!dailyReport) return;

    return (
      <SendMessageModal
        hide={() => setSendingPDF(false)}
        daily_reports={[dailyReport]}
        hideMail={true}
        hideFax={true}
        job_id={dailyReport.job._id}
        header={"Send a daily report"}
      />
    );
  };

  const renderModalBody = () => {
    return (
      <div className={styles["daily-report"]}>
        <div className={styles["daily-report-body"]}>
          {renderSidebar()}
          {renderContent()}
        </div>
      </div>
    );
  };

  return (
    <>
      <ActionModal
        headerText={dailyReport ? renderHeader() : "Loading Daily Report..."}
        submitText={undefined}
        onHide={onHide}
        onSubmit={function (): void {
          throw new Error("Function not implemented.");
        }}
        wrapperClassName={styles["daily-report-modal-wrapper"]}
        bodyClassName={styles["daily-report-modal-body"]}
        hideFooter={true}
        actions={actions as ActionLink[]}
        actionsType={"button"}
        loading={loading}
      >
        {dailyReport && renderModalBody()}
        {!dailyReport && <Loader />}
      </ActionModal>
      {viewingSignature && renderSignatureModal()}
      {downloadingPDF && <LoadingModal text="Building report PDF" />}
      {sendingPDF && renderSendMessageModal()}
      {confirmInvalidatingESignatures && (
        <ConfirmModal
          title="Invalidate esignatures?"
          body="Are you sure you want invalidate the esignatures on this daily report? This cannot be undone."
          onNo={() => setConfirmInvalidatingESignatures(false)}
          onYes={invalidateESignatures}
          loading={invalidatingESignatures}
        />
      )}
    </>
  );
};

export default DailyReportModal;
