import { DateTime } from "luxon";
import {
  I9DocumentsFields,
  I9PersonalInformationFields,
  I9PreparerFields,
  I9ResidencyFields,
  I9Section2EmployeeFields,
} from "../../backend/models/i-9";
import { Assign } from "utility-types";
import { Address, AggregatedI9 } from "dashboard/miter";
import { FilePickerFile } from "ui/form/FilePicker";
import { ESignatureInputValue } from "ui/form/ESignatureInput";

/*********************************************************
 *  General types
 **********************************************************/
export type I9EmployeeAuthorizationType = AggregatedI9["section_1"]["employee_authorization_type"];

export type I9Document = {
  // Name that appears as a UI heade
  name: string;
  // Description that appears in the UI
  description: string;
  // The list that this document falls other
  category: "list_a" | "list_b" | "list_c" | "no_list";
  // Residency type that this document is valid for
  employee_authorization_types: I9EmployeeAuthorizationType[];
  // Document title on the I-9
  title: string;
  // Array of descriptions of the files required
  uploads: string[];
  // Allow extra files to be uploaded
  additional_docs?: (
    | "Arrival/Departure Record (Form I-94)"
    | "Arrival/Departure Record (Form I-94A)"
    | "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20)"
    | "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)"
  )[];
};

/*********************************************************
 *  Personal information form helpers + types
 **********************************************************/
export type PersonalInformationFormFields = Assign<
  I9PersonalInformationFields,
  {
    employee_dob: DateTime;
    employee_ssn?: string;
    employee_address: Address;
  }
>;

/*********************************************************
 *  Residency form helpers + types
 **********************************************************/
export type ResidencyFormFields = Assign<
  I9ResidencyFields,
  {
    employee_authorization_type: {
      value: I9ResidencyFields["employee_authorization_type"];
      label: string;
    } | null;
    alien_authorization_number_type: {
      value: I9ResidencyFields["alien_authorization_number_type"];
      label: string;
    } | null;
    permanent_resident_number_type: {
      value: I9ResidencyFields["permanent_resident_number_type"];
      label: string;
    } | null;
    alien_work_authorization_expiration_date?: DateTime;
  }
>;

export const buildResidencyFormDefaults = (I9: AggregatedI9): ResidencyFormFields => {
  return {
    employee_authorization_type: I9.section_1?.employee_authorization_type
      ? {
          value: I9.section_1?.employee_authorization_type,
          label: EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS_MAP[I9.section_1?.employee_authorization_type]!,
        }
      : null,
    alien_authorization_number_type: I9.section_1?.alien_authorization_number_type
      ? {
          value: I9.section_1?.alien_authorization_number_type,
          label: ALIEN_AUTHORIZATION_NUMBER_TYPE_OPTIONS_MAP[I9.section_1?.alien_authorization_number_type]!,
        }
      : null,
    permanent_resident_number_type: I9.section_1?.permanent_resident_number_type
      ? {
          value: I9.section_1?.permanent_resident_number_type,
          label: PERMANENT_RESIDENT_NUMBER_TYPE_OPTIONS_MAP[I9.section_1?.permanent_resident_number_type]!,
        }
      : null,
    alien_registration_number: I9.section_1?.alien_registration_number,
    form_i_94_number: I9.section_1?.form_i_94_number,
    foreign_passport_number: I9.section_1?.foreign_passport_number,
    foreign_passport_country: I9.section_1?.foreign_passport_country,
    alien_work_authorization_expiration_date: I9.section_1?.alien_work_authorization_expiration_date
      ? DateTime.fromISO(I9.section_1?.alien_work_authorization_expiration_date)
      : undefined,
    uscis_number: I9.section_1?.uscis_number,
  };
};

/*********************************************************
 *  Upload documents form helpers + types
 **********************************************************/
export type DocumentsFormFields = Assign<I9DocumentsFields, {}>;

// Add files array to the list_a array items, list_b object, and list_c object
export type DocumentUploadFields = Assign<
  I9DocumentsFields,
  {
    list_a: Assign<
      I9DocumentsFields["list_a"][0],
      {
        files: FilePickerFile[][];
        expiration_date?: DateTime;
      }
    >[];
    list_b: Assign<
      I9DocumentsFields["list_b"],
      {
        files: FilePickerFile[][];
        expiration_date?: DateTime;
      }
    >;
    list_c: Assign<
      I9DocumentsFields["list_c"],
      {
        files: FilePickerFile[][];
        expiration_date?: DateTime;
      }
    >;
  }
>;

const buildDefaultValuesForUploadedDocument = (doc?: AggregatedI9["section_2"]["list_b"]) => {
  if (!doc) return {};

  return {
    ...doc,
    files: doc.files.map((file) => [file]),
    expiration_date: doc.expiration_date ? DateTime.fromISO(doc.expiration_date) : undefined,
  };
};

export const buildUploadFormDefaultValues = (I9: AggregatedI9): DocumentUploadFields => {
  const initialListA = I9.section_2.list_a?.length ? I9.section_2.list_a : [];
  const initialListB = I9.section_2.list_b?.type ? I9.section_2.list_b : undefined;
  const initialListC = I9.section_2.list_c?.type ? I9.section_2.list_c : undefined;

  return {
    // @ts-expect-error fix me
    list_a: [...initialListA.map((listADoc) => buildDefaultValuesForUploadedDocument(listADoc))],
    // @ts-expect-error fix me
    list_b: buildDefaultValuesForUploadedDocument(initialListB),
    // @ts-expect-error fix me
    list_c: buildDefaultValuesForUploadedDocument(initialListC),
  };
};

/*********************************************************
 *  Preparer/Translator form helpers + types
 **********************************************************/
export type PreparerFormFields = Assign<
  I9PreparerFields,
  { used_preparer: "true" | "false"; preparer_esignature?: ESignatureInputValue }
>;

export const buildPreparerFormDefaults = (I9: AggregatedI9): PreparerFormFields => {
  const defaults = {
    used_preparer: I9.section_1.used_preparer ? ("true" as const) : ("false" as const),
    preparer_first_name: I9.section_1.preparer_first_name,
    preparer_last_name: I9.section_1.preparer_last_name,
    preparer_esignature_id: I9.section_1.preparer_esignature_id,
    // We manually set preparer_address as a defaultValue in the formblock b/c of our wonkey address input
  };

  return defaults;
};

/*********************************************************
 *  Verify employee information form helpers + types
 **********************************************************/
export type VerifyEmployeeInformationFormFields = Assign<
  I9Section2EmployeeFields,
  {
    employee_start_date: DateTime;
    employee_citizenship_status: {
      value: I9Section2EmployeeFields["employee_citizenship_status"];
      label: string;
    } | null;
  }
>;

export const buildVerifyEmployeeInformationFormDefaults = (
  I9: AggregatedI9
): VerifyEmployeeInformationFormFields => {
  return {
    employee_first_name: I9.section_2.employee_first_name,
    employee_last_name: I9.section_2.employee_last_name,
    employee_middle_initial: I9.section_2.employee_middle_initial,
    // @ts-expect-error fix me
    employee_start_date: DateTime.fromISO(I9.section_2.employee_start_date),
    employee_citizenship_status: I9.section_2.employee_citizenship_status
      ? {
          value: I9.section_2.employee_citizenship_status,
          label: EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS_MAP[I9.section_2.employee_citizenship_status]!,
        }
      : null,
  };
};

/*********************************************************
 *  Constants
 **********************************************************/
export const EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS = [
  { label: "U.S. Citizen", value: "citizen" },
  { label: "Lawful Permanent Resident", value: "lawful_permanent_resident" },
  { label: "Non-Citizen National (U.S. Territory)", value: "noncitizen_national" },
  { label: "Alien Authorized to Work", value: "alien_authorized_to_work" },
];

export const EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS_MAP = EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS.reduce(
  (acc, option) => {
    acc[option.value] = option.label;
    return acc;
  },
  {} as Record<string, string>
);

export const ALIEN_AUTHORIZATION_NUMBER_TYPE_OPTIONS = [
  { label: "Alien Registration Number (A-Number)", value: "alien_registration_number" },
  { label: "USCIS Number", value: "uscis_number" },
  { label: "Foreign Passport Number", value: "foreign_passport_number" },
  { label: "I-94 Admission Number", value: "form_i_94_number" },
];

export const ALIEN_AUTHORIZATION_NUMBER_TYPE_OPTIONS_MAP = ALIEN_AUTHORIZATION_NUMBER_TYPE_OPTIONS.reduce(
  (acc, option) => {
    acc[option.value] = option.label;
    return acc;
  },
  {} as Record<string, string>
);

export const PERMANENT_RESIDENT_NUMBER_TYPE_OPTIONS = [
  { label: "Alien Registration Number (A-Number)", value: "alien_registration_number" },
  { label: "USCIS Number", value: "uscis_number" },
];

export const PERMANENT_RESIDENT_NUMBER_TYPE_OPTIONS_MAP = PERMANENT_RESIDENT_NUMBER_TYPE_OPTIONS.reduce(
  (acc, option) => {
    acc[option.value] = option.label;
    return acc;
  },
  {} as Record<string, string>
);

/*********************************************************
 * I-9 Document Configurations
 **********************************************************/
export const I9Documents: I9Document[] = [
  {
    name: "US Passport",
    description: "U.S. Passport",
    category: "list_a",
    employee_authorization_types: ["citizen", "noncitizen_national"],
    title: "Passport",
    uploads: ["Passport ID Page of the US Passport", "Passport Barcode Page of the US Passport"],
  },
  {
    name: "US Passport Card",
    description: "U.S. Passport Card",
    category: "list_a",
    employee_authorization_types: ["citizen", "noncitizen_national"],
    title: "Passport Card",
    uploads: ["Passport ID Page of the US Passport Card", "Passport Barcode Page of the US Passport Card"],
  },
  {
    name: "Permanent Resident Card",
    description: "Permanent Resident Card",
    category: "list_a",
    employee_authorization_types: ["lawful_permanent_resident"],
    title: "Permanent Resident Card",
    uploads: ["Front of the Permanent Resident Card", "Back of the Permanent Resident Card"],
  },
  {
    name: "Alien Registration Receipt Card (Form I-551)",
    description: "Alien Registration Receipt Card (Form I-551)",
    category: "list_a",
    employee_authorization_types: ["lawful_permanent_resident"],
    title: "Form I-551",
    uploads: [
      "Front of the Alien Registration Receipt Card (Form I-551)",
      "Back of the Alien Registration Receipt Card (Form I-551)",
    ],
  },
  {
    name: "Foreign passport with I-551 Stamp or MRIV",
    description:
      "Foreign passport that contains a temporary I-551 stamp or temporary I-551 printed notation on a machine-readable immigrant visa",
    category: "list_a",
    employee_authorization_types: ["lawful_permanent_resident"],
    title: "Foreign passport",
    uploads: ["Passport ID Page of the Foreign Passport", "Passport Barcode Page of the Foreign Passport"],
  },
  {
    name: "Employment Authorization Document (Form I-766)",
    description: "Employment Authorization Document that contains a photograph (Form I-766)",
    category: "list_a",
    employee_authorization_types: ["alien_authorized_to_work"],
    title: "Form I-766",
    uploads: [
      "Front of the Employment Authorization Document (Form I-766)",
      "Back of the Employment Authorization Document (Form I-766)",
    ],
  },
  {
    name: "Foreign passport with Form I-94",
    description: `For a nonimmigrant alien authorized to work for a specific employer because of his or her status:
    a. Foreign passport; and
    b. Form I-94 or Form I-94A that has
    the following:
    (1) The same name as the passport; and
    (2) An endorsement of the alien's nonimmigrant status as long as that period of endorsement has not yet expired and the proposed employment is not in conflict with any restrictions or limitations identified on the form.`,
    category: "list_a",
    employee_authorization_types: ["alien_authorized_to_work"],
    title: "Foreign passport",
    uploads: ["Passport ID Page of the Foreign Passport", "Passport Barcode Page of the Foreign Passport"],
    additional_docs: [
      "Arrival/Departure Record (Form I-94A)",
      "Arrival/Departure Record (Form I-94)",
      "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20)",
      "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)",
    ],
  },
  {
    name: "Passport from Federated States of Micronesia or Republic of the Marshall Islands with Form I-94 or Form I-94A",
    description:
      "A passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A indicating nonimmigrant admission under the Compact of Free Association Between the United States and the FSM or RMI",
    category: "list_a",
    employee_authorization_types: ["alien_authorized_to_work"],
    title: "Passport",
    uploads: ["Passport ID Page of the Passport", "Passport Barcode Page of the Passport"],
    additional_docs: ["Arrival/Departure Record (Form I-94A)", "Arrival/Departure Record (Form I-94)"],
  },
  {
    name: "Driver's License",
    description:
      "Driver's license issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Driver's License",
    uploads: ["Front of the Driver's License", "Back of the Driver's License"],
  },
  {
    name: "ID Card",
    description:
      "ID card issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "ID Card",
    uploads: ["Front of the ID Card", "Back of the ID Card"],
  },
  {
    name: "State ID Card",
    description:
      "State ID card issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "State ID Card",
    uploads: ["Front of the State ID Card", "Back of the State ID Card"],
  },
  {
    name: "School ID Card",
    description: "School ID card, provided it contains a photograph.",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "School ID Card",
    uploads: ["Front of the School ID Card", "Back of the School ID Card"],
  },
  {
    name: "Voter's Registration Card",
    description: "Voter's registration card",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Voter's Registration Card",
    uploads: ["Front of the Voter's Registration Card", "Back of the Voter's Registration Card"],
  },
  {
    name: "US Military card",
    description: "US military",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "US Military card",
    uploads: ["Front of the US Military card", "Back of the US Military card"],
  },
  {
    name: "Draft Record",
    description: "Draft record",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Draft Record",
    uploads: ["Front of the Draft Record", "Back of the Draft Record"],
  },
  {
    name: "Military Dependent's ID Card",
    description: "Military dependent's ID card",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Military Dependent's ID Card",
    uploads: ["Front of the Military Dependent's ID Card", "Back of the Military Dependent's ID Card"],
  },
  {
    name: "US Coast Guard Merchant Mariner Card",
    description: "US Coast Guard Merchant Mariner Card",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "US Coast Guard Merchant Mariner Card",
    uploads: [
      "Front of the US Coast Guard Merchant Mariner Card",
      "Back of the US Coast Guard Merchant Mariner Card",
    ],
  },
  {
    name: "Native American Tribal Document",
    description: "Native American tribal document",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Native American Tribal Document",
    uploads: ["Front of the Native American Tribal Document", "Back of the Native American Tribal Document"],
  },
  {
    name: "Canadian Driver's License",
    description: "Canadian driver's license",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Canadian Driver's License",
    uploads: ["Front of the Canadian Driver's License", "Back of the Canadian Driver's License"],
  },
  {
    name: "School Record",
    description:
      "School record - for persons under 18 years of age that can't present a documment listed above",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "School Record",
    uploads: ["Front of the School Record", "Back of the School Record"],
  },
  {
    name: "Day-care/Nursery School Record",
    description:
      "Nursery school record - for persons under 18 years of age that can't present a documment listed above",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Nursery School Record",
    uploads: ["Front of the Nursery School Record", "Back of the Nursery School Record"],
  },
  {
    name: "Clinic/Doctor/Hospital Record",
    description:
      "Clinic/Doctor/Hospital record - for persons under 18 years of age that can't present a documment listed above",
    category: "list_b",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Clinic/Doctor/Hospital Record",
    uploads: ["Front of the Clinic/Doctor/Hospital Record", "Back of the Clinic/Doctor/Hospital Record"],
  },
  {
    name: "Social Security Card",
    description: `A Social Security Account Number card, unless the card includes one of the following restrictions:
    (1) NOT VALID FOR EMPLOYMENT
    (2) VALID FOR WORK ONLY WITH INS AUTHORIZATION
    (3) VALID FOR WORK ONLY WITH DHS AUTHORIZATION`,
    category: "list_c",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Social Security Card",
    uploads: ["Front of the Social Security Card", "Back of the Social Security Card"],
  },
  {
    name: "Certification of Report of Birth",
    description:
      "Certification of report of birth issued by the Department of State (Forms DS-1350, FS-545, FS-240)",
    category: "list_c",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Certification of Report of Birth",
    uploads: [
      "Front of the Certification of Report of Birth",
      "Back of the Certification of Report of Birth",
    ],
  },
  {
    name: "US Birth Certificate",
    description:
      "Original or certified copy of birth certificate issued by a State, county, municipal authority, or territory of the United States bearing an official seal",
    category: "list_c",
    employee_authorization_types: ["citizen", "noncitizen_national"],
    title: "US Birth Certificate",
    uploads: ["Front of the US Birth Certificate", "Back of the US Birth Certificate"],
  },
  {
    name: "Native American Tribal Document",
    description: "Native American tribal document",
    category: "list_c",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Native American Tribal Document",
    uploads: ["Front of the Native American Tribal Document", "Back of the Native American Tribal Document"],
  },
  {
    name: "US Citizen ID Card (Form I-197)",
    description: "US Citizen ID Card (Form I-197)",
    category: "list_c",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Form I-197",
    uploads: ["Front of the US Citizen ID Card (Form I-197)", "Back of the US Citizen ID Card (Form I-197)"],
  },
  {
    name: "ID Card for Use of Resident Citizen in the US (Form I-179)",
    description: "ID Card for Use of Resident Citizen in the US (Form I-179)",
    category: "list_c",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Form I-179",
    uploads: [
      "Front of the ID Card for Use of Resident Citizen in the US (Form I-179)",
      "Back of the ID Card for Use of Resident Citizen in the US (Form I-179)",
    ],
  },
  {
    name: "Employment authorization document issued by the Department of Homeland Security",
    description: "Employment authorization document issued by the Department of Homeland Security ",
    category: "list_c",
    employee_authorization_types: [
      "citizen",
      "noncitizen_national",
      "lawful_permanent_resident",
      "alien_authorized_to_work",
    ],
    title: "Employment Authorization Document",
    uploads: [
      "Front of the Employment Authorization Document",
      "Back of the Employment Authorization Document",
    ],
  },
  {
    name: "Arrival/Departure Record (Form I-94)",
    description: "Arrival/Departure Record (Form I-94)",
    category: "no_list",
    employee_authorization_types: ["lawful_permanent_resident", "alien_authorized_to_work"],
    title: "Form I-94",
    uploads: ["Front of the Arrival/Departure Record (Form I-94)"],
  },
  {
    name: "Arrival/Departure Record (Form I-94A)",
    description: "Arrival/Departure Record (Form I-94A)",
    category: "no_list",
    employee_authorization_types: ["lawful_permanent_resident", "alien_authorized_to_work"],
    title: "Form I-94A",
    uploads: ["Front of the Form I-94A)"],
  },
  {
    name: "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20)",
    description: "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20)",
    category: "no_list",
    employee_authorization_types: ["alien_authorized_to_work"],
    title: "Form I-20",
    uploads: ["Front of the Form I-20"],
  },
  {
    name: "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)",
    description: "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)",
    category: "no_list",
    employee_authorization_types: ["alien_authorized_to_work"],
    title: "Form DS-2019",
    uploads: ["Front of the Form DS-2019"],
  },
];
