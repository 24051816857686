import Banner from "dashboard/components/shared/Banner";
import { MiterAPI } from "dashboard/miter";
import { GroupOperation } from "backend/utils/forage/forage-types";
import { useEffect, useState } from "react";
import React from "react";
import PayrollContext from "../payrollContext";
import { usePayrollTimesheetFilters } from "dashboard/hooks/usePayrollTimesheetFilters";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

export const UnapprovedTimesheetPayrollWarning: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const payrollContext = React.useContext(PayrollContext);
  const { payroll } = payrollContext;
  const activeCompany = useActiveCompany();

  const filters = usePayrollTimesheetFilters(payroll, ["unapproved"]);

  useEffect(() => {
    setShowBannerVal();
  }, [payroll]);

  const setShowBannerVal = async () => {
    if (
      !payroll ||
      !activeCompany ||
      !activeCompany._id ||
      !activeCompany?.settings.payroll.enable_unapproved_timesheet_warning
    ) {
      return;
    }

    const miterQueryObject = {
      filter: [...filters],
      group: [
        {
          field: "company",
          label: "company",
          accumulators: [
            {
              field: "",
              label: "count",
              operation: "count" as GroupOperation,
            },
          ],
        },
      ],
      pagination: false,
    };

    try {
      const response = await MiterAPI.timesheets.forage(miterQueryObject, false);
      const companyTimesheetData = response?.data[0] as unknown as { id: string; count: number } | undefined;
      const count = companyTimesheetData?.count || 0;
      // if there are 1 or more members with unapproved timesheets show the warning banner
      setShowBanner(count > 0);
    } catch (e) {
      console.error("Error getting unapproved timesheets for payroll warning banner:", e);
    }
  };

  if (!showBanner) return null;

  return (
    <Banner
      content="Note: There are still timesheets that have not been approved in this payroll."
      type="warning"
      style={{ marginTop: 15, marginBottom: -10 }}
      onDismiss={() => setShowBanner(false)}
    />
  );
};
