import { uniq, map, capitalize } from "lodash";
import { notNullish } from "miter-utils";
import { useMemo, useCallback } from "react";
import { TeamMemberScopeGroup, TeamMemberScopeGroupType } from "backend/models/permission-group";
import { useActiveTeam, useDepartmentOptions, useTeamOptions, useCrewOptions } from "./atom-hooks";
import { Option } from "ui/form/Input";

export type useTeamMemberScopes = {
  options: { label: string; options: Option<string>[] }[];
  buildTeamMemberScopeGroup: (value: string | undefined) => TeamMemberScopeGroup | undefined;
  getTeamMemberScopeOption: (value: string | undefined) => Option<string> | undefined;
};

/**
 * useTeamMemberScopes is a custom hook that generates the options for the team member permission group
 * scopes multiselect. It also provides a map for converting the option value into a TeamMemberScopeGroup
 * and vice versa.
 */
export const useTeamMemberScopesBuilder = (): useTeamMemberScopes => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeTeam = useActiveTeam();
  const departmentOptions = useDepartmentOptions();
  const teamMemberOptions = useTeamOptions();
  const crewOptions = useCrewOptions();

  /**********************************************************************************************************
   * Options arrays
   **********************************************************************************************************/
  const payTypeOptions = useMemo(() => {
    return uniq(map(activeTeam, "pay_type"))
      .filter(notNullish)
      .map((payType) => ({ label: capitalize(payType), value: payType }));
  }, [activeTeam]);

  const employmentTypeOptions = useMemo(() => {
    return uniq(map(activeTeam, "employment_type"))
      .filter(notNullish)
      .map((employmentType) => ({ label: capitalize(employmentType), value: employmentType }));
  }, [activeTeam]);

  const titleOptions = useMemo(() => {
    return uniq(map(activeTeam, "title"))
      .filter(notNullish)
      .map((title) => ({ label: capitalize(title), value: title }));
  }, [activeTeam]);

  const options = useMemo(() => {
    return [
      { label: "General", options: GENERAL_OPTIONS },
      { label: "Departments", options: departmentOptions },
      { label: "Crews", options: crewOptions },
      { label: "Titles", options: titleOptions },
      { label: "Employment types", options: employmentTypeOptions },
      { label: "Pay types", options: payTypeOptions },
      { label: "Team members", options: teamMemberOptions },
    ];
  }, [
    departmentOptions,
    teamMemberOptions,
    crewOptions,
    payTypeOptions,
    employmentTypeOptions,
    titleOptions,
  ]);

  /** Map for converting the team member group value into an option */
  const getTeamMemberScopeOption = useCallback(
    (value: string | undefined) => {
      const lookuper = options.reduce((acc, option) => {
        option.options.forEach((option) => {
          acc[option.value] = option;
        });
        return acc;
      }, {} as Record<string, Option<string>>);

      return lookuper[value || ""];
    },
    [options]
  );

  /** Converts an option into the group that it's part so we can save the value */
  const reverseLookupGroupType = useCallback(
    (option: Option<string>): TeamMemberScopeGroupType | undefined => {
      switch (option.label) {
        case "All team members":
          return "all_team_members";
        case "Direct reports":
          return "direct_reports";
        case "User's department":
          return "user_department";
        case "User's location":
          return "user_location";
        case "Direct and indirect reports":
          return "direct_and_indirect_reports";
        case "Crews managing":
          return "crews_managing";
      }

      const isDepartment = departmentOptions.some((o) => o.value === option.value);
      if (isDepartment) return "department";

      const isCrew = crewOptions.some((o) => o.value === option.value);
      if (isCrew) return "crew";

      const isTitle = titleOptions.some((o) => o.value === option.value);
      if (isTitle) return "title";

      const isEmploymentType = employmentTypeOptions.some((o) => o.value === option.value);
      if (isEmploymentType) return "employment_type";

      const isPayType = payTypeOptions.some((o) => o.value === option.value);
      if (isPayType) return "pay_type";

      const isTeamMember = teamMemberOptions.some((o) => o.value === option.value);
      if (isTeamMember) return "team_member";

      return;
    },
    [
      activeTeam,
      departmentOptions,
      teamMemberOptions,
      crewOptions,
      payTypeOptions,
      employmentTypeOptions,
      titleOptions,
    ]
  );

  /** Map for converting the option value into a TeamMemberScopeGroup */
  const buildTeamMemberScopeGroup = useCallback(
    (value: string | undefined) => {
      if (!value) return;

      const selectedOption = getTeamMemberScopeOption(value);
      if (!selectedOption) return;

      const groupType = reverseLookupGroupType(selectedOption);
      if (!groupType) return;

      return { type: groupType, value };
    },
    [reverseLookupGroupType, options]
  );

  return { options, buildTeamMemberScopeGroup, getTeamMemberScopeOption };
};

const GENERAL_OPTIONS = [
  { label: "All team members", value: "all_team_members" },
  { label: "User's department", value: "user_department" },
  { label: "User's location", value: "user_location" },
  { label: "Direct reports", value: "direct_reports" },
  { label: "Direct and indirect reports", value: "direct_and_indirect_reports" },
  { label: "Crews managing", value: "crews_managing" },
];
