import AppContext from "dashboard/contexts/app-context";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Button, Formblock, Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";

import * as vals from "dashboard/utils/validators";
import styles from "./DocumentWizard.module.css";
import { capitalize } from "lodash";
import { FilePickerFile } from "ui/form/FilePicker";
import { File, MiterAPI, Tag } from "dashboard/miter";
import { FileToUpload, formatFilesForUpload } from "miter-utils";
import TagModal from "dashboard/components/tags/TagModal";
import { DateTime } from "luxon";
import { useActiveCompanyId, usePermissionGroupOptions } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { CaretDown, CaretUp } from "phosphor-react";
import { Option } from "ui/form/Input";
import { AdvancedPermissionCan } from "backend/models/file";
import { AdvancedPermissions } from "dashboard/components/shared/AdvancedPermissions";

type Props = {
  document: FilePickerFile | undefined;
  setDocument: Dispatch<SetStateAction<FilePickerFile | undefined>>;
  name: string;
  parentId: string;
  parentType: File["parent_type"];
};

export type DocumentInfoScreenFields = {
  label: File["label"];
  file: FilePickerFile[];
  tag_ids: Option<string>[] | null;
  expires_at?: DateTime;
  sensitive?: boolean;
  advanced_permissions?: AdvancedPermissions[];
};

type AdvancedPermissions = {
  permission_group_id: Option<string> | undefined;
  can: Option<string>[] | null;
};

const DocumentInfoScreen: React.FC<Props> = ({ name, document, setDocument, parentId, parentType }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { tags, getTags } = useContext(AppContext);
  const { setCanNext, setNextButtonText, handleComplete, screens } = useWizard();
  const [openNewTagModal, setOpenNewTagModal] = useState(false);
  const [showAdvancedPermissions, setShowAdvancedPermissions] = useState(false);

  const permissionGroupOptions = usePermissionGroupOptions();
  const activeCompanyId = useActiveCompanyId();
  const documentTags = useMemo(() => tags.filter((tag) => tag.parent_type === "file"), [tags]);

  const showSensitiveCheckbox = useShowSensitiveCheckbox(document, parentType);

  const form = useForm<DocumentInfoScreenFields>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
    defaultValues: buildDefaultValues(document, permissionGroupOptions, tags),
  });

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, watch, formState, errors } = form;
  const { dirtyFields, isValid } = formState;
  const isMounted = useRef(false);
  const formData = watch();

  /*********************************************************
   * useEffect's
   * - Trigger form validation on change
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveDocument)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: DocumentInfoScreenFields) => {
    if (!activeCompanyId) throw new Error("No active role");

    const cleanedFile = formatFilesForUpload(params.file, activeCompanyId, {
      parent_id: parentId,
      parent_type: parentType,
    })[0]!;

    const cleanedAdvancedPermissions = (params.advanced_permissions || [])
      .filter((permission) => permission.permission_group_id?.value)
      ?.map((permission) => ({
        permission_group_id: permission.permission_group_id!.value,
        can: permission.can?.map((c) => c.value) as AdvancedPermissionCan[],
      }));

    const data: FileToUpload = {
      ...cleanedFile,
      label: params.label,
      company_id: activeCompanyId,
      tag_ids: params.tag_ids?.map((tag) => tag.value) || [],
      // @ts-expect-error fix me
      expires_at: params.expires_at?.toISODate() || null,
      advanced_permissions: cleanedAdvancedPermissions,
    };

    if (showSensitiveCheckbox && params.sensitive != null) {
      data.sensitive = params.sensitive;
    }

    return data;
  };

  const saveDocument = async (params: DocumentInfoScreenFields) => {
    try {
      const cleanedParams = buildParams(params);
      const res = document?.data?._id
        ? await MiterAPI.files.update_many({ files: [{ ...cleanedParams, _id: document.data._id }] })
        : await MiterAPI.files.upload({ files: [cleanedParams] });

      if ("error" in res) throw new Error(res.error);
      if (!res[0]) throw new Error("Unable to save file.");

      const file = "file" in res[0] ? res[0].file : res[0];

      setDocument({ data: file });
      Notifier.success("Document saved");

      // If we are creating a team member document, we can end the wizard here.
      if (parentType === "team_member") {
        handleComplete();
      }
    } catch (e: $TSFixMe) {
      console.error("Error saving document", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  const renderAdvancedPermissionFields = () => {
    if (!showSensitiveCheckbox) return;

    return (
      <div className="form-section">
        <Button
          className="button-text"
          onClick={() => setShowAdvancedPermissions(!showAdvancedPermissions)}
          style={{ fontSize: "15px", color: "#555" }}
        >
          {`${showAdvancedPermissions ? "Hide" : "Show"} advanced permissions`}
          {showAdvancedPermissions && <CaretUp style={{ marginLeft: 5 }} />}{" "}
          {!showAdvancedPermissions && <CaretDown style={{ marginLeft: 5 }} />}
        </Button>

        {showAdvancedPermissions && <AdvancedPermissions form={form} />}
      </div>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Let&apos;s start with some basic information</h2>
        </div>
        <Formblock
          type="text"
          name="label"
          label="Document Label*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          maxLength={50}
          placeholder={"What do you want to call this document?"}
        />
        <Formblock
          type="multiselect"
          name="tag_ids"
          label="Tags"
          form={form}
          editing={true}
          className="modal wizard"
          placeholder={"Select tags"}
          options={documentTags.map((tag) => ({ label: capitalize(tag.label), value: tag._id }))}
          val={vals.required}
          labelButtonText="+ Add tag"
          labelButtonClick={() => setOpenNewTagModal(true)}
        />
        <Formblock
          label={"Upload a document*"}
          sublabel="Note: If you want this document to be signable, please upload a PDF."
          sublabelStyle={{ color: "#777" }}
          name={"file"}
          className="modal wizard"
          type="file"
          form={form}
          multiple={true}
          maxFilesAllowed={1}
          variant="dropzone"
          editing={true}
          readOnly={!!document}
          compact={true}
          labelStyle={{ marginBottom: 10, marginTop: 10 }}
          dropzoneLabel="Drag and drop or click to upload a document"
          val={vals.required}
          maxFileSize={1024 * 1024 * 50 /**  50MB */}
          acceptedFileTypes={[
            "image/jpeg",
            "image/png",
            "text/csv",
            "video/mpeg",
            "video/mp4",
            "application/zip",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "applcation/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          ]}
        />
        {showSensitiveCheckbox && (
          <Formblock
            type="checkbox"
            name="sensitive"
            label="Sensitive document"
            labelInfo="Sensitive documents are only accessible to dashboard users with the sensitive document access permission"
            form={form}
            text="This document contains sensitive information"
            editing={true}
            className="modal wizard"
          />
        )}
        <Formblock
          type="datetime"
          name="expires_at"
          label="Expires at"
          labelInfo="You will receive an email notification on the Monday before the expiration date."
          form={form}
          editing={true}
          className="modal wizard"
          min={DateTime.now()}
          dateOnly={true}
        />
        {renderAdvancedPermissionFields()}
      </div>

      {openNewTagModal && (
        <TagModal
          parentType="file"
          onFinish={() => {
            getTags();
            setOpenNewTagModal(false);
          }}
          onHide={() => setOpenNewTagModal(false)}
        />
      )}
    </WizardScreen>
  );
};

export default DocumentInfoScreen;

const buildDefaultValues = (
  document: FilePickerFile | undefined,
  permissionGroupOptions: Option<string>[],
  tagOptions: Tag[]
) => {
  if (!document?.data) return {};

  const addvancedPermissions = document.data.advanced_permissions?.map((p) => {
    const permissionGroup = permissionGroupOptions.find((option) => option.value === p.permission_group_id);
    return {
      permission_group_id: permissionGroup,
      can: p.can.map((c) => ({ label: capitalize(c), value: c })),
    };
  });

  const lookupTag = tagOptions.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, Tag>);

  return {
    label: document.data.label,
    file: [document],
    tag_ids: document.data.tag_ids.map((tagId) => ({ label: lookupTag[tagId]?.label, value: tagId })),
    expires_at: document.data.expires_at ? DateTime.fromISO(document.data.expires_at) : undefined,
    sensitive: document.data.sensitive,
    advanced_permissions: addvancedPermissions,
  };
};

/** Simple custom hook to decide if we need to show the sensitive document checkbox. */
const useShowSensitiveCheckbox = (
  document: FilePickerFile | undefined,
  parentType: File["parent_type"]
): boolean => {
  const { can } = useMiterAbilities();

  return useMemo(() => {
    if (!document) {
      // If we are creating a team member document and the user can create sensitive team documents, show the checkbox
      if (parentType === "team_member" && can("documents:team_member:create_sensitive")) {
        return true;
      }

      // If we are creating a company document and the user can create sensitive company documents, show the checkbox
      if (parentType === "company" && can("documents:company:create_sensitive")) {
        return true;
      }
    } else {
      // If we are editing a team member document and the user can edit sensitive team documents, show the checkbox
      if (parentType === "team_member" && can("documents:team_member:update_sensitive")) {
        return true;
      }

      // If we are editing a company document and the user can edit sensitive company documents, show the checkbox
      if (parentType === "company" && can("documents:company:update_sensitive")) {
        return true;
      }
    }

    return false;
  }, [parentType, document, can]);
};
