import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AppContext from "../../../../contexts/app-context";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI, MiterError } from "dashboard/miter";
import { Formblock, TableV2 } from "ui";
import { DateRange } from "ui/form/DateRangePicker";
import { useActiveCompanyId, usePrgOptions, useUser } from "dashboard/hooks/atom-hooks";
import { useForm } from "react-hook-form";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { ColumnConfig } from "ui/table-v2/Table";
import { FringeReportEntryv2, FringeReportv2Response } from "backend/utils/reports/fringeReportv2";
import { toDollarFormat, roundToTwoDecimals, agGridIsoDateComparator } from "../../reportUtils";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";

export const NewFringeReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { can } = useMiterAbilities();
  const { payroll } = useContext(PayrollContext);

  const prgOptions = usePrgOptions();

  const isMounted = useRef(false);
  const { setReverifyUser } = React.useContext(AppContext);
  const user = useUser();
  const form = useForm();
  const { errors, control } = form;

  // State
  const [rawData, setRawData] = useState<FringeReportv2Response>({ entries: [], fringes: [] });
  const [selectedPrg, setSelectedPrg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ months: 1 }),
    end: DateTime.now(),
  });

  const [showRawSSNs, setShowRawSSNs] = useState(false);
  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const isReverified = (user?.reverified_at || 0) > DateTime.now().minus({ days: 1 }).toSeconds();

  const columns = useMemo(() => {
    const cols: ColumnConfig<FringeReportEntryv2>[] = [
      {
        field: "tmName",
        filter: "agSetColumnFilter",
        headerName: "Team member",
        rowGroup: true,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "classificationLabel",
        filter: true,
        headerName: "Classification",
        enableRowGroup: true,
        rowGroup: true,
        initialHide: true,
      },
      {
        field: "tmSSN",
        filter: true,
        headerName: "SSN",
        initialHide: true,
      },
      {
        field: "payday",
        dataType: "date",
        filter: "agDateColumnFilter",
        filterParams: { comparator: agGridIsoDateComparator },
        headerName: "Payday",
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "regHours",
        headerName: "REG hours",
        valueFormatter: roundToTwoDecimals,
        aggFunc: "sum",
      },
      {
        field: "otHours",
        headerName: "OT hours",
        valueFormatter: roundToTwoDecimals,
        aggFunc: "sum",
      },
      {
        field: "dotHours",
        headerName: "DOT hours",
        valueFormatter: roundToTwoDecimals,
        aggFunc: "sum",
      },
      {
        field: "totalHours",
        headerName: "Total hours",
        valueFormatter: roundToTwoDecimals,
        aggFunc: "sum",
      },
      {
        field: "earningAmount",
        headerName: "Gross earnings",
        valueFormatter: toDollarFormat,
        aggFunc: "sum",
      },
    ];

    rawData.fringes.forEach((fringe) => {
      cols.push({
        field: `fringeContributions.${fringe.groupId}`,
        headerName: fringe.label,
        valueFormatter: toDollarFormat,
        aggFunc: "sum",
      });
    });

    return cols;
  }, [rawData]);

  useEffect(() => {
    if (showReverificationModal) {
      setReverifyUser(showReverificationModal);
    }
  }, [showReverificationModal]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (showRawSSNs && !isReverified) {
      setShowReverificationModal(true);
      return;
    }

    getData();
  }, [showRawSSNs, isReverified]);

  const getData = async () => {
    if (!selectedPrg) return;
    setLoading(true);
    try {
      const payload = {
        type: "fringe_report_v2",
        params: {
          start_date: dateRange.start?.toISODate(),
          end_date: dateRange.end?.toISODate(),
          company_id: activeCompanyId!,
          pay_rate_group_id: selectedPrg,
          showRawSSNs: showRawSSNs && isReverified,
          draftPayroll: payroll,
        },
        format: "json",
        company: activeCompanyId!,
      };

      const data: FringeReportv2Response & MiterError = await MiterAPI.reports.create(payload);
      if (data.error) throw new Error(data.error);

      setRawData(data);
    } catch (e: $TSFixMe) {
      console.log("Error fetching fringe report", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [dateRange, selectedPrg]);

  const fileName = "Miter Fringe Report " + dateRange.start?.toISODate() + " - " + dateRange.end?.toISODate();

  return (
    <div>
      <>
        <Formblock
          label="Pay rate group"
          control={control}
          type="select"
          options={prgOptions}
          onChange={(e) => setSelectedPrg(e.value)}
          name="pay_rate_group_id"
          errors={errors}
          editing={true}
          labelStyle={{ width: 175, minWidth: 175 }}
          style={{ maxWidth: 500 }}
        />
        {can("team:read_sensitive") && (
          <Formblock
            label="Include raw SSNs"
            labelInfo="Show full SSNs on the report."
            control={control}
            type="checkbox"
            onChange={(e) => setShowRawSSNs(e.target.checked)}
            name="show_full_ssns"
            errors={errors}
            editing={true}
            labelStyle={{ width: 175, minWidth: 175 }}
            style={{ maxWidth: 500 }}
          />
        )}
        {!payroll && (
          <Formblock
            register={form.register}
            defaultValue={dateRange}
            value={dateRange}
            control={form.control}
            type="daterange"
            name="range"
            max={DateTime.now()}
            errors={form.errors}
            editing={true}
            onChange={setDateRange}
            label={"Payday range"}
            labelStyle={{ width: 175, minWidth: 175 }}
            style={{ maxWidth: 500 }}
          />
        )}

        <div className="vertical-spacer-small"></div>
      </>

      <TableV2
        id="fringe-report-v2"
        resource="data"
        data={rawData.entries}
        columns={columns}
        isLoading={loading}
        groupHideOpenParents={false}
        gridWrapperStyle={{ height: "110%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        containerStyle={{ marginTop: -70 }}
        hideSearch={true}
        showReportViews={true}
        exportFileName={fileName}
        showExpandAll={true}
      />
      <div className="vertical-spacer-large"></div>
    </div>
  );
};
