import AppContext from "dashboard/contexts/app-context";
import React, { useContext, useEffect } from "react";
import { Company, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard, TextWithTooltip } from "ui";
import { useDebounce } from "use-debounce";

type EarningsGroupingType = Company["settings"]["payroll"]["earnings_groupings"][number];

export const PaystubEarningGrouping: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const [earningsGroupings, setEarningsGroupings] = React.useState<EarningsGroupingType[]>(
    activeCompany?.settings.payroll.earnings_groupings || []
  );

  const [debouncedGroupings] = useDebounce(earningsGroupings, 500);

  const handleToggle = (newValue: EarningsGroupingType) => {
    setEarningsGroupings((prev) => {
      const newGroups = new Set(prev);
      if (newGroups.has(newValue)) {
        newGroups.delete(newValue);
      } else {
        newGroups.add(newValue);
      }
      return Array.from(newGroups).sort();
    });
  };

  useEffect(() => {
    if (!activeCompanyId) return;

    const existingGroupings = (activeCompany?.settings.payroll.earnings_groupings || []).slice().sort();
    if (existingGroupings.join() === debouncedGroupings.join()) return;

    const update = async () => {
      try {
        const response = await MiterAPI.companies.update(activeCompanyId, {
          "settings.payroll.earnings_groupings": debouncedGroupings,
        });
        if (response.error) throw new Error(response.error);
        Notifier.success("Earning group settings updated successfully.");
        fetchUserData();
      } catch (e) {
        console.error(e);
        Notifier.error("There was an error updating your settings.");
      }
    };

    update();
  }, [debouncedGroupings]);

  return (
    <SettingsCard
      title="Paystub earnings grouping"
      subtitle="All earnings are grouped by time type (REG, OT) and workplace to start plus any combination of the following."
      contentStyle={{ color: "rgb(51,51,51)" }}
    >
      <div className="flex">
        <input
          type="checkbox"
          style={{ margin: "0px 12px" }}
          onChange={() => handleToggle("rate")}
          checked={earningsGroupings.includes("rate")}
        />
        <label>
          <span className="bold">Pay rate</span>:
          <span>{` Earnings are additionally separated by pay rate.`}</span>
        </label>
      </div>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="checkbox"
          style={{ margin: "0px 12px" }}
          onChange={() => handleToggle("job")}
          checked={earningsGroupings.includes("job")}
        />
        <label>
          <span className="bold">Job</span>:<span>{` Earnings are additionally separated by job.`}</span>
        </label>
      </div>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="checkbox"
          style={{ margin: "0px 12px" }}
          onChange={() => handleToggle("activity")}
          checked={earningsGroupings.includes("activity")}
        />
        <label>
          <span className="bold">Activity</span>:
          <span>{` Earnings are additionally separated by activity.`}</span>
        </label>
      </div>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="checkbox"
          style={{ margin: "0px 12px" }}
          onChange={() => handleToggle("classification")}
          checked={earningsGroupings.includes("classification")}
        />
        <label className="flex">
          <span className="bold">
            <TextWithTooltip
              id="classification"
              text="Classification"
              tooltip="Pay rate group classification"
            />
          </span>
          <span>{`: Earnings are additionally separated by classification.`}</span>
        </label>
      </div>
    </SettingsCard>
  );
};
