import AppContext from "dashboard/contexts/app-context";
import { useActiveCompanyId, useLookupTimeOffPolicy } from "dashboard/hooks/atom-hooks";
import { AggregatedMiterEarning } from "dashboard/miter";
import { TRUEBECK_COMPANY_ID } from "dashboard/utils";
import {
  downloadJonasTimesheetCsv,
  downloadMiterCsv,
  downloadTruebeckBridgeTxtFile,
  downloadTruebeckUkgCsv,
  downloadVistaCsv,
  downloadWbsCsv,
} from "dashboard/utils/csvDownloadFunctions";
import { useSage300TimeEntriesCSV } from "dashboard/utils/useSage300TimeEntriesCsv";
import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier } from "ui";
import { Option } from "ui/form/Input";

type Props = {
  earnings: AggregatedMiterEarning[];
  selectedIds: string[];
  filteredIds: string[];
  periodStart: string;
  periodEnd: string;
  payScheduleId: string;
  hide: () => void;
};

type DownloadFunction = (earnings: AggregatedMiterEarning[]) => Promise<void>;

export const PayPeriodHoursExportModal: React.FC<Props> = ({
  earnings,
  selectedIds,
  filteredIds,
  periodStart,
  periodEnd,
  payScheduleId,
  hide,
}) => {
  const form = useForm();
  const activeCompanyId = useActiveCompanyId();
  const { integrations } = useContext(AppContext);
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const sage300Downloader = useSage300TimeEntriesCSV(activeCompanyId, periodStart, periodEnd);

  const [loading, setLoading] = useState(false);

  const downloadFuncLookup: Record<string, DownloadFunction> = useMemo(() => {
    return {
      csv: async (e: AggregatedMiterEarning[]) => {
        downloadMiterCsv(e, periodEnd, lookupTimeOffPolicy);
      },
      vista: async (e: AggregatedMiterEarning[]) => {
        downloadVistaCsv(e, periodEnd);
      },
      sage_300: async (e: AggregatedMiterEarning[]) => {
        await sage300Downloader.build({ earnings: e, forceDownload: true });
      },
      jonas: async (e: AggregatedMiterEarning[]) => {
        downloadJonasTimesheetCsv(e, periodEnd);
      },
      wbs: async (_e: AggregatedMiterEarning[]) => {
        if (!activeCompanyId) return;
        await downloadWbsCsv({ companyId: activeCompanyId, periodStart, periodEnd, payScheduleId });
      },
      truebeck_ukg: async (e: AggregatedMiterEarning[]) => {
        downloadTruebeckUkgCsv(e, periodEnd, lookupTimeOffPolicy);
      },
      truebeck_bridge: async (e: AggregatedMiterEarning[]) => {
        downloadTruebeckBridgeTxtFile(e, periodEnd, lookupTimeOffPolicy);
      },
    };
  }, [periodStart, periodEnd, activeCompanyId, sage300Downloader]);

  const formatOptions: Option<string>[] = useMemo(() => {
    const enabledIntegrations = integrations.filter((i) => i.connection);
    const options = [{ label: "Standard Miter CSV", value: "csv" }];
    if (enabledIntegrations.some((i) => i.key === "vista")) {
      options.push({ label: "Vista CSV", value: "vista" });
    }
    if (enabledIntegrations.some((i) => i.key === "sage_300")) {
      options.push({ label: "Sage 300 CSV", value: "sage_300" });
    }
    if (enabledIntegrations.some((i) => i.key === "jonas")) {
      options.push({ label: "Jonas CSV", value: "jonas" });
    }
    if (enabledIntegrations.some((i) => i.key === "wbs")) {
      options.push({ label: "WBS CSV", value: "wbs" });
    }
    if (activeCompanyId === TRUEBECK_COMPANY_ID || process.env.REACT_APP_ENVIRONMENT === "development") {
      options.push(
        ...[
          { label: "Truebeck UKG CSV", value: "truebeck_ukg" },
          { label: "Truebeck bridge TXT", value: "truebeck_bridge" },
        ]
      );
    }
    return options;
  }, [integrations, activeCompanyId]);

  const downloadData = async (data) => {
    setLoading(true);
    try {
      const filteredIdsSet = new Set(filteredIds);

      let earningsToDownload = filteredIds.length
        ? earnings.filter((e) => filteredIdsSet.has(e._id))
        : earnings;

      if (data.include_only_selected) {
        const selectedIdsSet = new Set(selectedIds);
        earningsToDownload = earningsToDownload.filter((e) => selectedIdsSet.has(e._id));
      }

      if (!data.include_unapproved) {
        earningsToDownload = earningsToDownload.filter((e) => e.approval_status !== "unapproved");
      }

      const downloadFunc = downloadFuncLookup[data.download_format?.value || ""];
      if (!downloadFunc) throw new Error("Invalid format selected.");

      await downloadFunc(earningsToDownload);
      Notifier.success("Your hours were exported successfully.");
      hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error exporting your hours. Please try again or reach out to support.");
    }
    setLoading(false);
  };

  return (
    <ActionModal
      onHide={hide}
      headerText="Export hours"
      onSubmit={form.handleSubmit(downloadData)}
      showSubmit={true}
      loading={loading}
      showCancel={true}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Format"
        name="download_format"
        type="select"
        options={formatOptions}
        form={form}
        className="modal"
        editing={true}
        requiredSelect
      />
      <Formblock
        type="checkbox"
        name="include_unapproved"
        text="Include unapproved hours in the export."
        className="modal"
        editing={true}
        form={form}
      />
      {selectedIds.length > 0 && (
        <Formblock
          type="checkbox"
          name="include_only_selected"
          text="Include only selected hours in the export."
          className="modal"
          editing={true}
          form={form}
        />
      )}
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};
