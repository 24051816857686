import { atom, Getter, Setter, WritableAtom, Atom, getDefaultStore } from "jotai";
import { atomWithStorage, atomFamily } from "jotai/utils";
import { DateTime } from "luxon";
import { baseSensitiveCompare, MapWithDefault } from "miter-utils";
import { SetStateAction } from "react";
import { Notifier } from "ui";
import { MiterError } from "backend/services/error-service";
import {
  AggregatedJob,
  LiveTimesheet,
  AggregatedPayRateGroup,
  AggregatedPerformanceReviewCycle,
  AggregatedRole,
  AggregatedTeamMember,
  Company,
  CostType,
  Crew,
  Department,
  HolidaySchedule,
  LedgerAccount,
  MiterAPI,
  MiterFilterArray,
  OvertimeRule,
  PaySchedule,
  ReportView,
  RateDifferential,
  StandardClassification,
  TimeOffPolicy,
  Policy,
  User,
  WorkersCompCode,
  StripeAccountResponse,
  PermissionGroup,
  Activity,
  CompanyUser,
  TeamMember,
  Role,
  Workplace,
  LedgerMapping,
  Form,
  ExpenseReimbursementCategory,
  File as MiterFile,
  SessionUpdateParams,
  PerDiemRate,
  WorkersCompGroup,
  Location,
  DashboardAuthenticatedUserData,
  Account,
  CardTransactionCategory,
  Equipment,
  AggregatedVendor,
  BenefitsEligibilityGroup,
  LeaveType,
  CustomTask,
} from "./miter";
import { buildTeamBaseFilterPredicate } from "./pages/team-members/TeamUtils";
import { getLedgerAccountLabel, sleep } from "./utils";
import { getPayrollReadinessForCompany } from "./utils/payrollReadiness";
import { ActionableItems } from "backend/services/actionable-item-service";
import { AggregatedJobPosting } from "./types/ats";
import { OnboardingChecklist } from "./types/onboarding-types";
import { HydratedPermissionGroups } from "./hooks/abilities-hooks/usePermissionGroupHydrator";
import { AggregatedCertificationType } from "./types/certification-types";
import { AggregatedFillableTemplate } from "./types/fillable-template-types";
import { getSessionAndLocalKeyValue, sessionAndLocalStorage } from "./storage";
import {
  buildChatConversation,
  conversationSorter,
  getPaginatedRecruitingConversations,
  getTwilioConversations,
  sortConversationByLastMessages,
} from "./utils/chat";
import { AggregatedRecruitingConversation, AggregatedTeamConversation, TwilioClient } from "./types/chat";

export const defaultAtomStore = getDefaultStore();

/** Type associated with input to a refetch atom setter */
export type RefetchInput = string | string[] | undefined | null;

/**
 * Takes an array of Miter objects and performs a few cleaning steps: First it filters out duplicate objects based on _id, giving preference to later objects in the array. Then it filters out archived objects (allowing newly archived objects to replace their unarchived prior versions and then get removed from the array). Finally, the array is sorted by the given key.
 */
const filterOutArchivedAndDupsAndSortByKey = <
  T extends { _id: string; archived?: boolean | null },
  K extends keyof T
>(
  arr: T[],
  sorter: K | ((a: T, b: T) => number),
  direction: 1 | -1 | "asc" | "desc" = "asc"
): T[] => {
  let sortFunc: (a: T, b: T) => number;
  if (typeof sorter === "function") {
    sortFunc = sorter;
  } else {
    const allNums = arr.every((v) => typeof v[sorter] === "number");
    sortFunc = allNums
      ? (a: T, b: T) => (a[sorter] as number) - (b[sorter] as number)
      : (a: T, b: T) => baseSensitiveCompare((a[sorter] ?? "").toString(), (b[sorter] ?? "").toString());
  }

  const noDupsMap = arr.reduce((acc, cur) => {
    return acc.set(cur._id, cur);
  }, new Map<string, T>());

  const sorted = [...noDupsMap.values()].filter((a) => !a.archived).sort(sortFunc);
  return direction === "asc" || direction === 1 ? sorted : sorted.reverse();
};

/**
 * Builds a read-write atom off a base atom, enforcing the no-archive filter and desired sort on any writes
 */
function buildCoreAtom<T extends { _id: string; archived?: boolean | null }, K extends keyof T>(
  baseAtom: WritableAtom<T[], [T[]], void>,
  sorter: K | ((a: T, b: T) => number)
) {
  return atom(
    (get) => get(baseAtom),
    (get: Getter, set: Setter, update: SetStateAction<T[]>) => {
      const newVal = typeof update === "function" ? update(get(baseAtom)) : update;
      const sorted = filterOutArchivedAndDupsAndSortByKey(newVal, sorter);
      set(baseAtom, sorted);
    }
  );
}

export type LookupAtomFunction<T> = (id: string | null | undefined) => T | undefined;

/**
 * Builds a read-only lookup atom off a core atom, turning an array into a record keyed by _id, for O(1) lookups
 */
function buildLookupAtom<T extends { _id: string }>(
  coreAtom: WritableAtom<T[], [T[]], void>
): Atom<LookupAtomFunction<T>> {
  return atom((get) => {
    const lookup = Object.fromEntries(get(coreAtom).map((o) => [o._id, o]));
    return (id: string | null | undefined) => (id ? lookup[id] : undefined);
  });
}

/**
 * Write-only atom that can be used to refetch data for any core atom. When setting this atom, pass in the ids to refetch (if falsy, then it'll automatically issue company-wide request), the core atom to refetch, and the appropriate MiterAPI retrieve_many callback.
 */
const genericRefetchAtom = atom(null, async function genericRefetchAtomWrite<
  T extends { _id: string }
>(get: Getter, set: Setter, ids: RefetchInput, coreAtom: WritableAtom<T[], [$TSFixMe], void>, refetchFunc: (f: MiterFilterArray) => Promise<T[] & MiterError>): Promise<void> {
  const activeCompanyId = get(activeCompanyAtom)?._id;
  if (!activeCompanyId) return;
  try {
    if (ids && Array.isArray(ids) && ids.length === 0) return;

    if (ids) {
      const arrIds = Array.isArray(ids) ? ids : [ids];
      for (const id of arrIds) {
        if (/^[a-f0-9]{24}$/.test(id)) continue;

        Notifier.error("Invalid ID for refreshing data");
        return;
      }
    }

    const filter: MiterFilterArray = ids
      ? [
          {
            field: "_id",
            value: ids,
            comparisonType: Array.isArray(ids) ? "in" : undefined,
          },
          {
            field: "archived",
            comparisonType: "include_archived",
          },
        ]
      : [
          {
            type: "or",
            value: [
              { field: "company", value: activeCompanyId },
              { field: "company_id", value: activeCompanyId },
            ],
          },
        ];
    const response = await refetchFunc(filter);
    if (response.error) throw new Error(response.error);
    let newObjs: T[];
    if (ids) {
      const currentObjs = get(coreAtom);
      newObjs = currentObjs.concat(response);
    } else {
      newObjs = response;
    }
    set(coreAtom, newObjs);
  } catch (e) {
    console.error("Error generically refetching data:", e);
  }
});

const activeLiveTimesheetSubscribe = (key: string, set: (value: string | null) => void): (() => void) => {
  if (typeof window === "undefined" || typeof window.addEventListener === "undefined") {
    return () => {}; // jotai typed poorly, or else it would allow us to return `void`
  }
  const listener = (e: StorageEvent) => {
    if (e.key === key) {
      if (e.newValue) {
        set(e.newValue);
      } else {
        set(null);
        sessionStorage.removeItem(key);
      }
    }
  };
  window.addEventListener("storage", listener);
  return () => window.removeEventListener("storage", listener);
};

/*
 * AUTH TOKEN
 */
const authTokenKey = "authToken";

export const authTokenAtom = atomWithStorage(
  authTokenKey,
  getSessionAndLocalKeyValue(authTokenKey),
  sessionAndLocalStorage
);

/*
 * AUTHENTICATION DATA
 */
export const authenticatedUserDataAtom = atom(
  null,
  (get: Getter, set: Setter, update: DashboardAuthenticatedUserData | null) => {
    const allAccounts = update?.available_accounts || [];
    set(authTokenAtom, update?.auth_token || null);
    set(userAtom, update?.user || null);
    set(userAccountsAtom, allAccounts);

    const activeAccount = update?.active_account || null;

    // Set team member/roles atoms
    if (activeAccount?.type === "team_member") {
      set(activeTeamMemberAtom, activeAccount.team_member);
      set(activeRoleAtom, null);
    } else if (activeAccount?.type === "role") {
      set(activeRoleAtom, activeAccount.role);
      set(activeTeamMemberAtom, null);
    } else {
      set(activeRoleAtom, null);
      set(activeTeamMemberAtom, null);
    }

    set(activeAccountAtom, activeAccount);
    set(activeCompanyAtom, activeAccount?.company_object || null);

    // Don't set the stored active account id to null so that it persists between logouts and logins within a browser
    if (activeAccount?._id) {
      set(storedActiveAccountIdAtom, activeAccount._id);
    }
  }
);

/*
 * USER
 */
export const userAtom = atom<User | null>(null);

/*
 * COMPANY USERS
 */

const baseCompanyUsersAtom = atom<CompanyUser[]>([]);
export const companyUsersAtom = buildCoreAtom(baseCompanyUsersAtom, "_id");
export const lookupCompanyUsersAtom = buildLookupAtom(baseCompanyUsersAtom);

/*
 * USER FETCHED AND INITIALIZING
 */
export const userFetchedAtom = atom(false);
export const initializingAtom = atom(true);

/*
 * ACTIVE TEAM MEMBER
 */

export const activeRoleAtom = atom<Role | null>(null);
export const activeTeamMemberAtom = atom<TeamMember | null>(null);
export const activeAccountAtom = atom<Account | null>(null);

export const userAccountsAtom = atom<Account[]>([]);

export const toggleAccountAtom = atom(null, async (get, set, params: SessionUpdateParams) => {
  try {
    const authToken = get(authTokenAtom);
    if (!authToken) return;

    const res = await MiterAPI.sessions.update(params);
    set(userFetchedAtom, true);

    if (res.error) throw new Error(res.error);
    if (!res.auth_token) throw new Error("No auth token returned");

    set(authenticatedUserDataAtom, res);
  } catch (e) {
    console.error("Error toggling role:", e);
    if (e instanceof Error) {
      Notifier.error(e.message);
    }
  }
});

const storedActiveAccountIdKey = "activeAccountId";

export const storedActiveAccountIdAtom = atomWithStorage(
  storedActiveAccountIdKey,
  getSessionAndLocalKeyValue(storedActiveAccountIdKey),
  sessionAndLocalStorage
);

/*
 * ACTIVE COMPANY
 */
export const activeCompanyAtom = atom<Company | null>(null);

/*
 * PAYROLL READINESS
 */
export const payrollReadinessAtom = atom((get) => {
  const company = get(activeCompanyAtom);
  if (!company) return null;
  return getPayrollReadinessForCompany(company);
});

/*
 * TEAM
 */
const baseTeamAtom = atom<AggregatedTeamMember[]>([]);
export const teamAtom = buildCoreAtom(baseTeamAtom, "full_name");
export const lookupTeamAtom = buildLookupAtom(teamAtom);
export const refetchTeamAtom = atom<null, [RefetchInput], Promise<void>>(null, async (get, set, update) => {
  await set(genericRefetchAtom, update, teamAtom, MiterAPI.team_member.retrieve_many);
});
export const activeTeamAtom = atom<AggregatedTeamMember[]>((get) => {
  const tms = get(teamAtom);
  const today = DateTime.now().toISODate();
  return tms.filter(buildTeamBaseFilterPredicate(today));
});
export const activeEmployeesAtom = atom<AggregatedTeamMember[]>((get) => {
  const tms = get(activeTeamAtom);
  return tms.filter((t) => t.employment_type === "employee");
});

/*
 * JOBS
 */
const baseJobsAtom = atom<AggregatedJob[]>([]);
export const jobsAtom = buildCoreAtom(baseJobsAtom, "name");
export const lookupJobAtom = buildLookupAtom(jobsAtom);
export const refetchJobsAtom = atom<null, [RefetchInput], Promise<void>>(null, async (get, set, update) => {
  await set(genericRefetchAtom, update, jobsAtom, MiterAPI.jobs.retrieve_many);
});
export const activeJobsAtom = atom((get) => get(jobsAtom).filter((j) => j.status === "active"));

/*
 * LIVE TIMESHEETS
 */

const baseLiveTimesheetsAtom = atom<LiveTimesheet[]>([]);
export const liveTimesheetsAtom = buildCoreAtom(baseLiveTimesheetsAtom, "created_at");
export const lookupLiveTimesheets = buildLookupAtom(liveTimesheetsAtom);
export const refetchLiveTimesheetsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, liveTimesheetsAtom, MiterAPI.live_timesheets.retrieve_many);
  }
);

// with storage
const activeLiveTimesheetKey = "activeLiveTimesheet";
export const activeLiveTimesheetUpdateStringAtom = atomWithStorage(
  activeLiveTimesheetKey,
  getSessionAndLocalKeyValue(activeLiveTimesheetKey),
  { ...sessionAndLocalStorage, subscribe: activeLiveTimesheetSubscribe }
);

export const activeLiveTimesheetUpdateAtom = atom(
  (get) => {
    const liveTimesheetUpdateString = get(activeLiveTimesheetUpdateStringAtom);
    return liveTimesheetUpdateString ? JSON.parse(liveTimesheetUpdateString) : null;
  },
  (get, set, newLiveTimesheetUpdate) => {
    if (newLiveTimesheetUpdate) {
      const newLiveTimesheetUpdateString = JSON.stringify(newLiveTimesheetUpdate);
      set(activeLiveTimesheetUpdateStringAtom, newLiveTimesheetUpdateString);
    } else {
      set(activeLiveTimesheetUpdateStringAtom, null);
    }
  }
);

/*
 * ACTIVITIES
 */
const baseActivitiesAtom = atom<Activity[]>([]);
export const activitiesAtom = buildCoreAtom(baseActivitiesAtom, "label");
export const lookupActivityAtom = buildLookupAtom(activitiesAtom);
export const refetchActivitiesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, activitiesAtom, MiterAPI.activities.retrieve_many);
  }
);
/** Read-only atom that creates a map between job ids and each job's selectable activities. Default get-value of the map is the company activities list, even if you pass it `null` or `undefined` */
export const selectableActivitiesMapAtom = atom((get) => {
  const activities = get(activitiesAtom);
  const jobs = get(jobsAtom);

  const activitiesMap = new Map(activities.map((a) => [a._id, a]));

  const companyActivities = activities.filter((a) => a.company_activity);

  const mapWithDefault = jobs.reduce((acc, job) => {
    if (!job.activities) return acc;
    const selectable: Activity[] = [];
    for (const id of job.activities) {
      const a = activitiesMap.get(id);
      if (a) selectable.push(a);
    }
    return acc.set(job._id, selectable);
  }, new MapWithDefault<string, Activity[]>(companyActivities));

  return mapWithDefault;
});

/*
 * EQUIPMENT
 */
const baseEquipmentAtom = atom<Equipment[]>([]);
export const equipmentAtom = buildCoreAtom(baseEquipmentAtom, "name");
export const refetchEquipmentAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, equipmentAtom, MiterAPI.equipment.search);
  }
);
export const lookupEquipmentAtom = buildLookupAtom(equipmentAtom);

/*
 * WORKERS COMP
 */
const baseWcCodesAtom = atom<WorkersCompCode[]>([]);
export const wcCodesAtom = buildCoreAtom(baseWcCodesAtom, "label");
export const lookupWcCodeAtom = buildLookupAtom(wcCodesAtom);
export const refetchWcCodesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, wcCodesAtom, MiterAPI.wc_codes.retrieve_many);
  }
);

const baseWcGroupsAtom = atom<WorkersCompGroup[]>([]);
export const wcGroupsAtom = buildCoreAtom(baseWcGroupsAtom, "name");
export const lookupWcGroupAtom = buildLookupAtom(wcGroupsAtom);
export const refetchWcGroupsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, wcGroupsAtom, MiterAPI.wc_groups.search);
  }
);

/*
 * COMPANY ROLES
 */
const baseCompanyRolesAtom = atom<AggregatedRole[]>([]);
export const companyRolesAtom = buildCoreAtom(baseCompanyRolesAtom, "email");
export const lookupCompanyRoleAtom = buildLookupAtom(companyRolesAtom);
export const refetchCompanyRolesAtom = atom(null, async (get, set) => {
  const activeCompany = get(activeCompanyAtom);
  if (!activeCompany) return;
  try {
    const response = await MiterAPI.roles.retrieve_company_roles(activeCompany._id);
    if (response.error) throw new Error(response.error);

    set(companyRolesAtom, response.roles);
  } catch (e) {
    console.error(e);
  }
});

/*
 * PAY RATE GROUPS AND RATE CLASSIFICATIONS
 */
const basePrgsAtom = atom<AggregatedPayRateGroup[]>([]);
export const prgsAtom = buildCoreAtom(basePrgsAtom, "label");
export const lookupPrgAtom = buildLookupAtom(prgsAtom);
export const refetchPrgsAtom = atom<null, [RefetchInput], Promise<void>>(null, async (get, set, update) => {
  await set(genericRefetchAtom, update, prgsAtom, (filter: MiterFilterArray) =>
    MiterAPI.pay_rate_groups.retrieve({ filter })
  );
});
export const lookupRateClassificationAtom = atom((get) => {
  const prgs = get(prgsAtom);
  const rates = prgs.flatMap((p) => p.union_rates);
  const lookup = Object.fromEntries(rates.map((r) => [r._id, r]));
  return (id: string | null | undefined) => (id ? lookup[id] : undefined);
});

/*
 * OVERTIME RULES
 */
const baseOtRulesAtom = atom<OvertimeRule[]>([]);
export const otRulesAtom = buildCoreAtom(baseOtRulesAtom, "precedence");
export const lookupOtRuleAtom = buildLookupAtom(otRulesAtom);
export const refetchOtRulesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, otRulesAtom, (filter: MiterFilterArray) =>
      MiterAPI.overtime_rules.retrieve({ filter })
    );
  }
);

/*
 * LEDGER MAPPINGS
 */
const baseLedgerMappingsAtom = atom<LedgerMapping[]>([]);
export const ledgerMappingsAtom = buildCoreAtom(baseLedgerMappingsAtom, "name");
export const lookupLedgerMappingAtom = buildLookupAtom(ledgerMappingsAtom);
export const refetchLedgerMappingsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, ledgerMappingsAtom, (filter: MiterFilterArray) =>
      MiterAPI.ledger_mappings.retrieve({ filter })
    );
  }
);

/*
 * RATE DIFFERENTIALS
 */
const baseRateDifferentialsAtom = atom<RateDifferential[]>([]);
export const rateDifferentialsAtom = buildCoreAtom(baseRateDifferentialsAtom, "label");
export const lookupRateDifferentialAtom = buildLookupAtom(rateDifferentialsAtom);
export const refetchRateDifferentialsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, rateDifferentialsAtom, (filter: MiterFilterArray) =>
      MiterAPI.rate_differentials.search({ filter })
    );
  }
);

/*
 * STANDARD CLASSIFICATIONS
 */
const baseStandardClassificationsAtom = atom<StandardClassification[]>([]);
export const standardClassificationsAtom = buildCoreAtom(baseStandardClassificationsAtom, "label");
export const lookupStandardClassificationAtom = buildLookupAtom(standardClassificationsAtom);
export const refetchStandardClassificationsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, standardClassificationsAtom, (filter: MiterFilterArray) =>
      MiterAPI.standard_classifications.search({ filter })
    );
  }
);

/*
 * POLICIES
 */
const basePoliciesAtom = atom<Policy[]>([]);
export const policiesAtom = buildCoreAtom(basePoliciesAtom, "name");
export const lookupPolicyAtom = buildLookupAtom(policiesAtom);
export const refetchPoliciesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, policiesAtom, (filter: MiterFilterArray) =>
      MiterAPI.policies.search({ filter })
    );
  }
);

/*
 * PAY SCHEDULES
 */
const basePaySchedulesAtom = atom<PaySchedule[]>([]);
export const paySchedulesAtom = buildCoreAtom(basePaySchedulesAtom, (a, b) => {
  if (a.default && !b.default) return -1;
  if (!a.default && b.default) return 1;
  return baseSensitiveCompare(a.label, b.label);
});
export const lookupPayScheduleAtom = buildLookupAtom(paySchedulesAtom);
export const refetchPaySchedulesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, paySchedulesAtom, (filter: MiterFilterArray) =>
      MiterAPI.pay_schedules.retrieve({ filter })
    );
  }
);

/*
 * HOLIDAY SCHEDULES
 */
const baseHolidaySchedulesAtom = atom<HolidaySchedule[]>([]);
export const holidaySchedulesAtom = buildCoreAtom(baseHolidaySchedulesAtom, (a, b) => {
  if (a.is_default && !b.is_default) return -1;
  if (!a.is_default && b.is_default) return 1;
  return baseSensitiveCompare(a.title, b.title);
});
export const lookupHolidayScheduleAtom = buildLookupAtom(holidaySchedulesAtom);
export const refetchHolidaySchedulesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, holidaySchedulesAtom, MiterAPI.holiday_schedules.list);
  }
);

/*
 * COST TYPES
 */
const baseCostTypesAtom = atom<CostType[]>([]);
export const costTypesAtom = buildCoreAtom(baseCostTypesAtom, "label");
export const lookupCostTypeAtom = buildLookupAtom(costTypesAtom);
export const refetchCostTypesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, costTypesAtom, (filter: MiterFilterArray) => {
      const fullFilter: MiterFilterArray = [
        ...filter,
        { field: "is_standard", value: true, type: "boolean" },
      ];
      return MiterAPI.cost_types.search({ filter: fullFilter });
    });
  }
);

/*
 * CREWS
 */
const baseCrewsAtom = atom<Crew[]>([]);
export const crewsAtom = buildCoreAtom(baseCrewsAtom, "name");
export const lookupCrewAtom = buildLookupAtom(crewsAtom);
export const refetchCrewsAtom = atom<null, [RefetchInput], Promise<void>>(null, async (get, set, update) => {
  await set(genericRefetchAtom, update, crewsAtom, (filter: MiterFilterArray) =>
    MiterAPI.crews.search(filter)
  );
});
export const lookupTeamMemberCrewsAtom = atom((get) => {
  const crews = get(crewsAtom);

  // Each crew has a list of team_member_ids, we want to create a map of team_member_id -> crews
  const lookup = crews.reduce((acc, cur) => {
    cur.team_member_ids.forEach((tmId) => {
      const existing = acc.get(tmId) || [];
      acc.set(tmId, existing.concat(cur));
    });
    return acc;
  }, new Map<string, Crew[]>());

  return (id: string | null | undefined) => (id ? lookup.get(id) : undefined);
});

/*
 * DEPARTMENTS
 */
const baseDepartmentsAtom = atom<Department[]>([]);
export const departmentsAtom = buildCoreAtom(baseDepartmentsAtom, "name");
export const lookupDepartmentAtom = buildLookupAtom(departmentsAtom);
export const refetchDepartmentsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, departmentsAtom, MiterAPI.departments.search);
  }
);

/*
 * PERFORMANCE REVIEW SCHEDULES
 */
const basePerformanceReviewSchedulesAtom = atom<AggregatedPerformanceReviewCycle[]>([]);
export const performanceReviewSchedulesAtom = buildCoreAtom(basePerformanceReviewSchedulesAtom, "name");
export const lookupPerformanceReviewSchedulesAtom = buildLookupAtom(performanceReviewSchedulesAtom);
export const refetchPerformanceReviewSchedulesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, performanceReviewSchedulesAtom, (filter: MiterFilterArray) =>
      MiterAPI.performance_review_cycles.retrieve_many(filter)
    );
  }
);

/*
 * LEDGER ACCOUNTS
 */
const baseLedgerAccountsAtom = atom<LedgerAccount[]>([]);
export const ledgerAccountsAtom = buildCoreAtom(baseLedgerAccountsAtom, (a, b) => {
  if (a.active && !b.active) return -1;
  if (!a.active && b.active) return 1;
  return baseSensitiveCompare(getLedgerAccountLabel(a), getLedgerAccountLabel(b));
});
export const lookupLedgerAccountAtom = buildLookupAtom(ledgerAccountsAtom);
export const refetchLedgerAccountsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, ledgerAccountsAtom, MiterAPI.ledger_accounts.retrieve);
  }
);

/*
 * ACTION CENTER
 */

export const baseActionableItemsAtom = atom<ActionableItems | null>(null);
export const actionableItemsAtom = atom(
  (get) => get(baseActionableItemsAtom),
  (get: Getter, set: Setter, update: SetStateAction<ActionableItems | null>) => {
    const newVal = typeof update === "function" ? update(get(baseActionableItemsAtom)) : update;
    set(baseActionableItemsAtom, newVal);
  }
);
export const refetchActionableItemsAtom = atom(null, async (get, set) => {
  const teamMember = get(activeTeamMemberAtom);
  if (!teamMember) return;

  try {
    const response = await MiterAPI.action_center.actionable_items(teamMember._id);
    if (response.error) throw new Error(response.error);
    set(actionableItemsAtom, response);
  } catch (e) {
    console.error(e);
  }
});

/*
 * TIME OFF POLICIES
 */
const baseTimeOffPoliciesAtom = atom<TimeOffPolicy[]>([]);
export const timeOffPoliciesAtom = buildCoreAtom(baseTimeOffPoliciesAtom, "name");
export const lookupTimeOffPolicyAtom = buildLookupAtom(timeOffPoliciesAtom);
export const refetchTimeOffPoliciesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, timeOffPoliciesAtom, MiterAPI.time_off.policies.retrieve_many);
  }
);

/*
 * Leave Types
 */
const baseLeaveTypesAtom = atom<LeaveType[]>([]);
export const leaveTypesAtom = buildCoreAtom(baseLeaveTypesAtom, "label");
export const lookupLeaveTypeAtom = buildLookupAtom(leaveTypesAtom);
export const refetchLeaveTypesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, leaveTypesAtom, MiterAPI.time_off.leave.get_types);
  }
);
/*
 * JOB POSTINGS
 */
const baseJobPostingsAtom = atom<AggregatedJobPosting[]>([]);
export const jobPostingsAtom = buildCoreAtom(baseJobPostingsAtom, "title");
export const lookupJobPostingsAtom = buildLookupAtom(jobPostingsAtom);
export const refetchJobPostingsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, jobPostingsAtom, MiterAPI.job_postings.jotai_retrieve);
  }
);

/*
 * FORMS
 */
const baseFormsAtom = atom<Form[]>([]);
export const formsAtom = buildCoreAtom(baseFormsAtom, "name");
export const lookupFormsAtom = buildLookupAtom(formsAtom);
export const refetchFormsAtom = atom<null, [RefetchInput], Promise<void>>(null, async (get, set, update) => {
  await set(genericRefetchAtom, update, formsAtom, MiterAPI.forms.search_basic_forms);
});

/*
 * Company documents
 */
const baseCompanyDocuments = atom<MiterFile[]>([]);
export const companyDocumentsAtom = buildCoreAtom(baseCompanyDocuments, "label");
export const lookupCompanyDocumentsAtom = buildLookupAtom(companyDocumentsAtom);
export const refetchCompanyDocumentsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, companyDocumentsAtom, (filter: MiterFilterArray) =>
      MiterAPI.files.retrieve_many(filter)
    );
  }
);

/*
 * Onboarding checklists
 */
const baseOnboardingChecklistsAtom = atom<OnboardingChecklist[]>([]);
export const onboardingChecklistsAtom = buildCoreAtom(baseOnboardingChecklistsAtom, "title");
export const lookupOnboardingChecklistsAtom = buildLookupAtom(onboardingChecklistsAtom);
export const refetchOnboardingChecklistsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, onboardingChecklistsAtom, (filter: MiterFilterArray) =>
      MiterAPI.onboarding_checklists.retrieve_many(filter)
    );
  }
);

/*
 * Certification types
 */
const baseCertificationTypesAtom = atom<AggregatedCertificationType[]>([]);
export const certificationTypesAtom = buildCoreAtom(baseCertificationTypesAtom, "title");
export const lookupCertificationTypesAtom = buildLookupAtom(certificationTypesAtom);
export const refetchCertificationTypesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, certificationTypesAtom, (filter: MiterFilterArray) =>
      MiterAPI.certification_types.retrieve_many(filter)
    );
  }
);

/*
 * Fillable templates
 */
const baseFillableTemplatesAtom = atom<AggregatedFillableTemplate[]>([]);
export const fillableTemplatesAtom = buildCoreAtom(baseFillableTemplatesAtom, "name");
export const lookupFillableTemplatesAtom = buildLookupAtom(baseFillableTemplatesAtom);
export const refetchFillableTemplatesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, fillableTemplatesAtom, (filter: MiterFilterArray) =>
      MiterAPI.fillable_templates.retrieve_many(filter)
    );
  }
);

/*
 * WORKPLACES
 */
const baseWorkplacesAtom = atom<Workplace[]>([]);
export const workplacesAtom = buildCoreAtom(baseWorkplacesAtom, (a, b) => {
  if (a.is_company_default) return -1;
  else if (b.is_company_default) return 1;
  return baseSensitiveCompare(a.address_key, b.address_key);
});
export const lookupWorkplacesAtom = buildLookupAtom(workplacesAtom);
export const refetchWorkplacesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    const forageCallback = async (filter: MiterFilterArray) => {
      const allData: Workplace[] = [];
      let moreResults = true;
      let nextPage: string | undefined;
      while (moreResults) {
        const response = await MiterAPI.workplaces.forage({
          filter,
          limit: 1000,
          starting_after: nextPage,
        });
        allData.push(...response.data);
        if (!response.next_page) {
          moreResults = false;
        } else {
          nextPage = response.next_page;
        }
      }
      return allData as Workplace[] & MiterError;
    };
    await set(genericRefetchAtom, update, workplacesAtom, forageCallback);
  }
);

/*
 * Stripe Connected Account
 */
export const stripeConnectedAccountAtom = atom<StripeAccountResponse | null>(null);

/*
 * PERMISSION GROUPS
 */
const basePermissionGroupsAtom = atom<PermissionGroup[]>([]);
export const permissionGroupsAtom = buildCoreAtom(basePermissionGroupsAtom, "name");
export const lookupPermissionGroupsAtom = buildLookupAtom(permissionGroupsAtom);

const refetchSessionPermissionGroupsCallback = async (get: Getter, set: Setter): Promise<void> => {
  const teamMember = get(activeTeamMemberAtom);
  if (!teamMember) return;

  try {
    const response = await MiterAPI.team_member.retrieve_permission_groups(teamMember._id);
    if (response.error) throw new Error(response.error);

    set(sessionPermissionGroupsAtom, response);
    await sleep(1);
    set(sessionPermissionGroupsFetchedAtom, true);
  } catch (e) {
    console.error(e);
  }
};

export const refetchPermissionGroupsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await Promise.all([
      set(genericRefetchAtom, update, permissionGroupsAtom, MiterAPI.permission_groups.search),
      refetchSessionPermissionGroupsCallback(get, set),
    ]);
  }
);

const baseSessionPermissionGroupsAtom = atom<PermissionGroup[]>([]);
export const sessionPermissionGroupsAtom = buildCoreAtom(baseSessionPermissionGroupsAtom, "name");
export const refetchSessionPermissionGroupsAtom = atom(null, refetchSessionPermissionGroupsCallback);
export const sessionPermissionGroupsFetchedAtom = atom(false);

/*
 * ABILITIES LIST
 */
export const hydratedPermissionGroupsAtom = atom<HydratedPermissionGroups>([]);

/*
 * Expense Reimbursement Categories
 */
export const expenseReimbursementCategoriesAtom = atom<ExpenseReimbursementCategory[]>([]);

export const refetchExpenseReimbursementCategoriesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    const forageCallback = async (filter: MiterFilterArray) => {
      const response = await MiterAPI.expense_reimbursements.categories.list({
        filter,
      });
      return response as ExpenseReimbursementCategory[] & MiterError;
    };
    await set(genericRefetchAtom, update, expenseReimbursementCategoriesAtom, forageCallback);
  }
);

export const lookupExpenseReimbursementCategoriesAtom = buildLookupAtom(expenseReimbursementCategoriesAtom);

/*
 * Card Transaction Categories
 */
export const cardTransactionCategoriesAtom = atom<CardTransactionCategory[]>([]);

export const refetchCardTransactionCategoriesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    const forageCallback = async (filter: MiterFilterArray) => {
      const response = await MiterAPI.expenses.categories.list({
        filter,
      });
      return response as CardTransactionCategory[] & MiterError;
    };
    await set(genericRefetchAtom, update, cardTransactionCategoriesAtom, forageCallback);
  }
);

export const lookupCardTransactionCategoriesAtom = buildLookupAtom(cardTransactionCategoriesAtom);

/*
 * REPORT VIEWS
 */
const baseReportViewsAtom = atom<ReportView[]>([]);
export const reportViewsAtom = buildCoreAtom(baseReportViewsAtom, "creator_user_id");
export const refetchReportViewsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, reportViewsAtom, MiterAPI.report_views.search);
  }
);

/*
 * PROFILE PICTURE URLS MAP
 */
export const profilePictureUrlsAtom = atom<Record<string, string>>({} as Record<string, string>);
export const refetchProfilePictureUrlsAtom = atom(null, async (get, set) => {
  const company = get(activeCompanyAtom);
  if (!company) return;

  const response = await MiterAPI.team_member.get_profile_picture_urls(company._id);
  if (response.error) {
    console.error(response.error);
    return;
  }
  set(profilePictureUrlsAtom, response);
});

/*
 * RATE DIFFERENTIALS
 */
const basePerDiemRatesAtom = atom<PerDiemRate[]>([]);
export const perDiemRatesAtom = buildCoreAtom(basePerDiemRatesAtom, "name");
export const lookupPerDiemRatesAtom = buildLookupAtom(perDiemRatesAtom);
export const refetchPerDiemRatesAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, perDiemRatesAtom, (filter: MiterFilterArray) =>
      MiterAPI.expense_reimbursements.per_diem_rates.list({ filter })
    );
  }
);

/*
 * LOCATIONS
 */
const baseLocationsAtom = atom<Location[]>([]);
export const locationsAtom = buildCoreAtom(baseLocationsAtom, "name");
export const lookupLocationsAtom = buildLookupAtom(locationsAtom);
export const refetchLocationsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    const forageCallback = async (filter: MiterFilterArray) => {
      const allData: Location[] = [];
      let moreResults = true;
      let nextPage: string | undefined;
      while (moreResults) {
        const response = await MiterAPI.locations.forage({
          filter,
          limit: 1000,
          starting_after: nextPage,
        });
        allData.push(...response.data);
        if (!response.next_page) {
          moreResults = false;
        } else {
          nextPage = response.next_page;
        }
      }
      return allData as Location[] & MiterError;
    };
    await set(genericRefetchAtom, update, locationsAtom, forageCallback);
  }
);

/*
 * BANNER NOTIFICATIONS
 */
const bannerNotificationsKey = "bannerNotifications";

export const bannerNotificationsAtomFamily = atomFamily((notificationKey: string) => {
  const bannerNotificationKey = `${bannerNotificationsKey}_${notificationKey}`;
  return atomWithStorage(
    bannerNotificationKey,
    getSessionAndLocalKeyValue(bannerNotificationKey),
    sessionAndLocalStorage
  );
});

/*
 * TEAM CHAT
 */

export const baseTeamTwilioClientAtom = atom<TwilioClient | null>(null);

/** We initialize the teamTwilioClient once and store it in Jotai. In useTeamChat, we'll add all the websocket logic. useTeamChat will only be called once. */
export const teamTwilioClientAtom = atom(
  (get) => get(baseTeamTwilioClientAtom),
  async (get: Getter, set: Setter, update: TwilioClient | null) => {
    if (!update) return;
    const activeCompanyId = get(activeCompanyAtom)?._id;
    const getTeamConversations = async () => {
      if (!activeCompanyId) return;
      try {
        const teamConversations = await MiterAPI.companies.get_conversations(activeCompanyId);
        if (teamConversations.error) throw new Error(teamConversations.error);
        const mergedConversations = await Promise.all(teamConversations.map((c) => buildChatConversation(c)));
        return mergedConversations;
      } catch (e: $TSFixMe) {
        return [];
      }
    };

    const teamMiterConversations = await getTeamConversations();
    const teamTwilioConversations = await getTwilioConversations(update);

    const mergedConversations = await Promise.all(
      (teamMiterConversations || []).map(async (conversation) => {
        const twilioConversation = teamTwilioConversations.find(
          (twilioConv) => twilioConv.sid === conversation.conversation_sid
        );

        return buildChatConversation(conversation, twilioConversation);
      })
    );

    const sortedConversations = mergedConversations.sort(conversationSorter);
    set(teamChatConversationsAtom, sortedConversations);
    set(isTeamChatInitializedAtom, true);
    set(baseTeamTwilioClientAtom, update);
  }
);

/** All team conversations  */
export const teamChatConversationsAtom = atom<AggregatedTeamConversation[]>([]);

/** Active team conversation */
export const activeTeamChatConversationAtom = atom<AggregatedTeamConversation | null>(null);

/** Team chat initialized */
export const isTeamChatInitializedAtom = atom<boolean>(false);

export const teamChatRestartAtom = atom<number>(0);

/** RECRUITING CONVERSATIONS */

export const baseRecruitingTwilioClientAtom = atom<TwilioClient | null>(null);

export const recruitingTwilioClientAtom = atom(
  (get) => get(baseRecruitingTwilioClientAtom),
  async (get: Getter, set: Setter, update: TwilioClient | null) => {
    const activeCompanyId = get(activeCompanyAtom)?._id;
    if (!update || !activeCompanyId) return;
    const recruitingConversations = await getPaginatedRecruitingConversations({
      client: update,
      companyId: activeCompanyId,
      search: "",
    });

    set(paginatedRecruitingConversationsAtom, recruitingConversations.data);
    set(baseRecruitingTwilioClientAtom, update);
    set(isRecruitingChatInitializedAtom, true);
  }
);

/** List of recruiting conversations that have been loaded. It will not contain all recruiting conversations for a company. */
const basePaginatedRecruitingConversationsAtom = atom<AggregatedRecruitingConversation[]>([]);

export const paginatedRecruitingConversationsAtom = buildCoreAtom(
  basePaginatedRecruitingConversationsAtom,
  sortConversationByLastMessages
);

/** Active recruiting conversation */
export const activeRecruitingConversationAtom = atom<AggregatedRecruitingConversation | null>(null);

/** Recruiting chat initialized */
export const isRecruitingChatInitializedAtom = atom<boolean>(false);

/** Number of unread recruiting conversations */
export const unreadRecruitingConversationsCountAtom = atom<number>(0);

export const recruitingChatRestartAtom = atom<number>(0);

/*
 * VENDORS
 */
const baseVendorsAtom = atom<AggregatedVendor[]>([]);
export const vendorsAtom = buildCoreAtom(baseVendorsAtom, "name");
export const lookupVendorsAtom = buildLookupAtom(vendorsAtom);
export const refetchVendorsAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, vendorsAtom, MiterAPI.vendors.search);
  }
);

/*
 * BENEFITS ELIGIBILITY GROUPS
 */
export const benefitsEligibilityGroupsAtom = atom<BenefitsEligibilityGroup[]>([]);

export const lookupBenefitsEligibilityGroupsAtom = buildLookupAtom(benefitsEligibilityGroupsAtom);

export const refetchBenefitsEligibilityGroupsAtom = atom(null, async (get, set) => {
  const company = get(activeCompanyAtom);
  if (!company) return;
  const response = await MiterAPI.benefits_eligibility_groups.list(company._id);
  if (response.error) {
    console.error(response.error);
    return;
  }
  set(benefitsEligibilityGroupsAtom, response);
});

/*
 * CUSTOM TASKS
 */
const baseCustomTasksAtom = atom<CustomTask[]>([]);
export const customTasksAtom = buildCoreAtom(baseCustomTasksAtom, "title");
export const lookupCustomTasksAtom = buildLookupAtom(customTasksAtom);
export const refetchCustomTasksAtom = atom<null, [RefetchInput], Promise<void>>(
  null,
  async (get, set, update) => {
    await set(genericRefetchAtom, update, customTasksAtom, (filter: MiterFilterArray) =>
      MiterAPI.custom_tasks.search(filter)
    );
  }
);

/*
 * OTHER
 */
export const confettiAtom = atom(false);
export const showNewReleaseModalAtom = atom(false);
export const newReleaseAvailableAtom = atom(false);
export const hitUnauthorizedErrorAtom = atom(false);
export const lastRefreshedAtAtom = atom(DateTime.now());
