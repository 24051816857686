import { AggregatedTeamMember, Department } from "dashboard/miter";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Label, TextWithTooltip } from "ui";
import Select, { ValueType } from "react-select";
import { styles as SelectStyles } from "ui/form/styles";
import styles from "./TeamMemberGroupSelect.module.css";
import { capitalize } from "lodash";
import { FaTimes } from "react-icons/fa";
import { useActiveTeam, useCrews, useDepartmentOptions, useLookupCrew } from "dashboard/hooks/atom-hooks";
import { Warning } from "phosphor-react";
import { Option } from "ui/form/Input";

type Props = {
  title: string;
  preSelectedTeamMembers?: AggregatedTeamMember[];
  handleSelect: (teamMembers: AggregatedTeamMember[]) => void;
  formblock?: boolean;
  version?: string;
  readOnly?: boolean;
  maxHeightOfTMBox?: number;
  emptyMessage?: string;
  warnings?: { [tmID: string]: string };
  selectableTeamMemberPredicate?: (tm: AggregatedTeamMember) => boolean;
};

const categoryOptions = [
  { label: "All team members", value: "all" },
  { label: "Specific team members", value: "_id" },
  { label: "Department", value: "department" },
  { label: "Crew", value: "crew" },
  { label: "Employment type", value: "employment_type" },
  { label: "Pay type", value: "pay_type" },
  { label: "Job title", value: "title" },
];

const TeamMemberSelect: React.FC<Props> = ({
  title = "Team members",
  preSelectedTeamMembers,
  handleSelect,
  formblock,
  version,
  maxHeightOfTMBox,
  readOnly,
  emptyMessage,
  warnings,
  selectableTeamMemberPredicate,
}) => {
  const crews = useCrews();
  const lookupCrew = useLookupCrew();
  const groupSelectRef = useRef<$TSFixMe>(null);
  const initialTeamMembers = useActiveTeam();
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<AggregatedTeamMember[]>([]);
  const [category, setCategory] = useState<string | undefined | null>("_id");

  const teamMembers = useMemo(() => {
    if (!selectableTeamMemberPredicate) return initialTeamMembers;
    return initialTeamMembers.filter(selectableTeamMemberPredicate);
  }, [initialTeamMembers, selectableTeamMemberPredicate]);

  const deptPredicate = useCallback(
    (d: Department) => selectedTeamMembers.every((tm) => tm.department_id !== d._id),
    [selectedTeamMembers]
  );

  const deptOptions = useDepartmentOptions({ predicate: deptPredicate });

  useEffect(() => {
    if (preSelectedTeamMembers) {
      handleTeamMemberSelect(preSelectedTeamMembers);
    }
  }, [JSON.stringify(preSelectedTeamMembers), readOnly]);

  const handleTeamMemberSelect = (tms) => {
    setSelectedTeamMembers(tms);
    handleSelect(tms);
  };

  useEffect(() => {
    if (selectedTeamMembers?.length) {
      groupSelectRef.current?.focus();
    }
  }, [selectedTeamMembers]);

  const handleCategorySelect = (value?: string | null) => {
    groupSelectRef.current?.select?.clearValue();
    setCategory(value);

    if (value === "all") {
      handleTeamMemberSelect(teamMembers);
    } else {
      handleTeamMemberSelect([]);
    }
  };

  const handleGroupSelect = (selection: string | null | undefined) => {
    if (!selection) return;

    let tms: AggregatedTeamMember[] = [];
    if (category === "department") {
      tms = teamMembers.filter((tm) => tm["department_id"] === selection);
    } else if (category === "crew") {
      const crew = lookupCrew(selection);
      tms = teamMembers.filter((tm) => crew?.team_member_ids.includes(tm._id));
    } else {
      tms = teamMembers.filter((tm) => tm[category!] === selection);
    }
    handleTeamMemberSelect(tms);
  };

  const handleCustomTeamMemberSelect = (selection: string | null | undefined) => {
    if (!selection) return handleTeamMemberSelect([]);

    const tm = teamMembers.find((tm) => selection === tm._id);
    // groupSelectRef.current.select.clearValue();
    handleTeamMemberSelect([...selectedTeamMembers, tm!]);
  };

  const handleRemoveSelectedTeamMember = (teamMember: AggregatedTeamMember) => {
    setCategory("_id");
    handleTeamMemberSelect(selectedTeamMembers.filter((tm) => tm._id !== teamMember._id));
  };

  const renderCategorySelect = () => {
    const value = categoryOptions.find((o) => o.value === category);

    return (
      <>
        <Select
          name="category"
          options={categoryOptions}
          value={value}
          width={"100%"}
          zIndex={10}
          onChange={(option: ValueType<Option<string>, false>) => handleCategorySelect(option?.value)}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          defaultValue={{ label: "Specific team members", value: "_id" }}
          height="32px"
          placeholder="Select a group"
          styles={SelectStyles}
        />
        {category ? <div className="vertical-spacer-small" /> : <div className={"vertical-spacer"} />}
      </>
    );
  };

  const renderGroupSelect = () => {
    if (category === "all" || !category) return;

    const availableTeamMembers =
      category === "_id"
        ? teamMembers.filter((tm) => !selectedTeamMembers.find((selectedTm) => selectedTm._id === tm._id))
        : teamMembers;

    const options = availableTeamMembers.reduce((currOptions, teamMember) => {
      if (!category) return currOptions;

      if (category === "crew") {
        return crews.map((crew) => ({ value: crew._id, label: crew.name || "-" }));
      }

      const option = teamMember[category];
      if (!option || option.length === 0 || currOptions.find((o) => o.value === option)) return currOptions;

      if (category === "_id") {
        return [...currOptions, { value: option, label: teamMember.full_name }];
      } else if (category === "department") {
        return deptOptions;
      } else {
        return [...currOptions, { value: option, label: capitalize(option) }];
      }
    }, [] as Option<string>[]);

    const placeholder =
      category === "_id" ? "Select a team member" : `Select ${category?.replaceAll("_", " ")}`;

    return (
      <>
        <Select
          key={category === "_id" ? JSON.stringify(selectedTeamMembers) : category}
          ref={groupSelectRef}
          name="category"
          options={options}
          width={"100%"}
          zIndex={10}
          onChange={(opt: ValueType<Option<string>, false>) => {
            if (category === "_id") {
              handleCustomTeamMemberSelect(opt?.value);
            } else {
              handleGroupSelect(opt?.value);
            }
          }}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          height="32px"
          styles={SelectStyles}
        />
        <div className={"vertical-spacer"} />
      </>
    );
  };

  const renderWarning = (teamMember: AggregatedTeamMember) => {
    const warning = warnings?.[teamMember._id];
    if (!warning) return;

    //warning red that looks nice that isn't plain red
    return (
      <TextWithTooltip
        suppressUnderline={true}
        id={teamMember._id}
        tooltip={warning}
        text={<Warning color={"#d0342c"} weight="fill" style={{ marginBottom: -3, marginRight: 7 }} />}
      />
    );
  };

  const renderSelectedTeamMembers = () => {
    if (!selectedTeamMembers || selectedTeamMembers.length === 0) return emptyMessage;

    const teamMemberElements = selectedTeamMembers.map((tm) => (
      <div key={tm._id} className={styles["selected-team-member"]}>
        <div className={"flex " + styles["selected-team-member-name"]}>
          {renderWarning(tm)}
          {readOnly ? (
            <a className={"black-link"} href={"/team-members/" + tm._id} target="_blank" rel="noreferrer">
              {tm.full_name}
            </a>
          ) : (
            tm.full_name
          )}
        </div>
        {!readOnly && (
          <button
            className={styles["remove-selected-team-member"]}
            onClick={() => handleRemoveSelectedTeamMember(tm)}
          >
            <FaTimes />
          </button>
        )}
      </div>
    ));

    return (
      <>
        {readOnly && <div className="vertical-spacer-small" />}
        <div
          className={styles["selected-team-members"] + " " + (version ? styles[version!] : "")}
          style={{ maxHeight: maxHeightOfTMBox }}
        >
          {teamMemberElements}
        </div>
      </>
    );
  };

  const renderTeamMemberSelection = () => {
    return (
      <div className={styles["select-team-members"] + " "}>
        {formblock ? (
          <Label label={title} className="modal" />
        ) : (
          <p className={styles["select-info-text"]}>{title}</p>
        )}
        {!readOnly && renderCategorySelect()}
        {!readOnly && renderGroupSelect()}
        {renderSelectedTeamMembers()}
      </div>
    );
  };

  return <>{renderTeamMemberSelection()}</>;
};

export default TeamMemberSelect;
