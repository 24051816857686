import React, { useEffect, useRef } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { MiterAPI, UpdateTeamMemberParams } from "dashboard/miter";
import { useActiveCompanyId, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { sleep } from "dashboard/utils";
import { Option } from "ui/form/Input";
import { WizardTeamMember } from "./TeamMemberWizard";
import {
  EEOEthnicityCategory,
  EEOGenderCategory,
  EEOJobCategory,
  EEOVeteranStatus,
  MaritalStatus,
  EEODisabilityStatus,
} from "backend/models/teamMember/teamMemberTypes";
import {
  EEO_DISABILITY_STATUS_OPTIONS,
  EEO_ETHNICITY_OPTIONS,
  EEO_GENDER_OPTIONS,
  EEO_JOB_CATEGORY_OPTIONS,
  EEO_MARITAL_STATUS_OPTIONS,
  EEO_VETERAN_STATUS_OPTIONS,
} from "dashboard/pages/team-members/forms/options";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberEEOInfoForm = {
  ethnicity?: Option<string>;
  gender?: Option<string>;
  job_category?: Option<string>;
  veteran_status?: Option<string>;
  marital_status?: Option<string>;
  disability_status?: Option<string>;
};

export const TeamMemberEEOInfoScreen: React.FC<Props> = ({ name, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const refetchTeam = useRefetchTeam();
  const isMounted = useRef(true);

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const form = useForm<TeamMemberEEOInfoForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(teamMember),
  });

  const formData = useWatch<TeamMemberEEOInfoForm>({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberEEOInfoForm): UpdateTeamMemberParams => {
    if (!activeCompanyId) throw new Error("No active role");

    const demographics: UpdateTeamMemberParams["demographics"] = {};

    if (params.ethnicity?.value !== undefined) {
      demographics.ethnicity = params.ethnicity?.value as EEOEthnicityCategory;
    }
    if (params.gender?.value !== undefined) {
      demographics.gender = params?.gender?.value as EEOGenderCategory;
    }
    if (params.disability_status?.value !== undefined) {
      demographics.disability_status = params.disability_status?.value as EEODisabilityStatus;
    }
    if (params.job_category?.value !== undefined) {
      demographics.job_category = params.job_category?.value as EEOJobCategory;
    }
    if (params.veteran_status?.value !== undefined) {
      demographics.veteran_status = params.veteran_status?.value as EEOVeteranStatus;
    }
    if (params.marital_status?.value !== undefined) {
      demographics.marital_status = params.marital_status?.value as MaritalStatus;
    }

    return {
      demographics,
      resume_wizard_index: curIndex + 1,
    };
  };

  const saveTeamMember = async (params: TeamMemberEEOInfoForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");
      if (!activeCompanyId) throw new Error("No active company");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }

      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>EEO Info</h2>
          <p className={styles["subheader-description"]}> Add the team member&apos;s EEO information</p>
        </div>
        <div className="form-section">
          <Formblock
            label="Gender"
            type="select"
            name="gender"
            placeholder="Select a gender"
            form={form}
            className="modal wizard"
            editing={true}
            options={EEO_GENDER_OPTIONS}
          />
          <Formblock
            label="Ethnicity"
            type="select"
            name="ethnicity"
            placeholder="Select an ethnicity"
            form={form}
            className="modal wizard"
            editing={true}
            options={EEO_ETHNICITY_OPTIONS}
          />
          <Formblock
            label="Marital status"
            type="select"
            name="marital_status"
            placeholder="Select a marital status"
            form={form}
            className="modal wizard"
            editing={true}
            options={EEO_MARITAL_STATUS_OPTIONS}
          />
          <Formblock
            label="Disability status"
            type="select"
            name="disability_status"
            placeholder="Select a disability status"
            form={form}
            className="modal wizard"
            editing={true}
            options={EEO_DISABILITY_STATUS_OPTIONS}
          />
          <Formblock
            label="Veteran status"
            type="select"
            name="veteran_status"
            placeholder="Select a veteran status"
            form={form}
            className="modal wizard"
            editing={true}
            options={EEO_VETERAN_STATUS_OPTIONS}
          />
          <Formblock
            label="Job category"
            type="select"
            name="job_category"
            placeholder="Select a job category"
            form={form}
            className="modal wizard"
            editing={true}
            options={EEO_JOB_CATEGORY_OPTIONS}
          />
        </div>
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (teamMember: WizardTeamMember | undefined): TeamMemberEEOInfoForm => {
  if (!teamMember) return {};

  const { ethnicity, gender, job_category, veteran_status, marital_status, disability_status } =
    teamMember.demographics || {};

  return {
    ethnicity: EEO_ETHNICITY_OPTIONS.find((o) => o.value === ethnicity),
    gender: EEO_GENDER_OPTIONS.find((o) => o.value === gender),
    job_category: EEO_JOB_CATEGORY_OPTIONS.find((o) => o.value === job_category),
    veteran_status: EEO_VETERAN_STATUS_OPTIONS.find((o) => o.value === veteran_status),
    marital_status: EEO_MARITAL_STATUS_OPTIONS.find((o) => o.value === marital_status),
    disability_status: EEO_DISABILITY_STATUS_OPTIONS.find((o) => o.value === disability_status),
  };
};
