import React, { useMemo, useState } from "react";

import { BasicModal, Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import {
  useActiveCompanyId,
  useCertificationTypes,
  useLookupCertificationTypes,
  useRefetchCertificationTypes,
} from "dashboard/hooks/atom-hooks";
import { Plus, TrashSimple } from "phosphor-react";
import { MiterAPI } from "dashboard/miter";
import { AggregatedCertificationType } from "dashboard/types/certification-types";
import { CertificationTypeWizard } from "./CertificationTypeWizard";
import { useCertificationTypeAbilities } from "dashboard/hooks/abilities-hooks/useCertificationTypeAbilities";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { useNavigate } from "react-router-dom";
import { notNullish } from "miter-utils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

const columns: ColumnConfig<AggregatedCertificationType>[] = [
  {
    field: "title",
    headerName: "Name",
    width: 150,
    dataType: "string" as const,
  },
  {
    field: "missing",
    headerName: "# Missing",
    dataType: "number",
  },
  {
    field: "created_at",
    headerName: "Created on",
    dataType: "date",
    dateType: "timestamp",
  },
];

const CertificationTypesTable: React.FC = ({}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const certificationTypes = useCertificationTypes();
  const refetchCertificationTypes = useRefetchCertificationTypes();
  const { can, cannot } = useCertificationTypeAbilities();
  const lookupCertificationType = useLookupCertificationTypes();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const navigate = useNavigate();
  const abilities = useMiterAbilities();
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [selectedRows, setSelectedRows] = useState<AggregatedCertificationType[]>([]);
  const [loading] = useState(false);
  const [confirmModal, setConfirmModal] = useState<null | "delete">(null);
  const [createCertification, setCreateCertification] = useState(false);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleSelect = (selections: AggregatedCertificationType[]) => {
    setSelectedRows(selections);
  };

  const openCertificationWizard = () => {
    setCreateCertification(true);
  };

  const closeCertificationWizard = () => {
    setCreateCertification(false);
  };

  const archiveCertificationType = async () => {
    if (selectedRows.length === 0 || !activeCompanyId || cannot("delete", selectedRows)) return;
    try {
      const certificationTypeIds = selectedRows.map((row) => row._id);
      const res = await MiterAPI.certification_types.archive(certificationTypeIds);
      if ("failures" in res && res.failures?.length) {
        const failures = res.failures
          .map((f) => {
            const certificationType = lookupCertificationType(f.id);
            if (!certificationType) return null;
            return {
              label: certificationType.title,
              message: f.reason,
            };
          })
          .filter(notNullish);

        setFailures(failures);
      }
      if (res.failures.length || res.error) {
        throw new Error(res.error || "Error deleting certification types.");
      }
      Notifier.success("Successfully deleted certification types.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setConfirmModal(null);
    setSelectedRows([]);
    refetchCertificationTypes();
  };

  /*********************************************************
    Toggler configs
  **********************************************************/

  const staticActions = useMemo(
    () => [
      {
        label: "Add certification",
        className: "button-2 no-margin",
        action: openCertificationWizard,
        shouldShow: () => abilities.can("certifications:certification_types:create"),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ],
    [can]
  );

  const dynamicActions = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 table-button",
        action: () => setConfirmModal("delete"),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("delete", selectedRows),
      },
    ],
    [setConfirmModal, can]
  );

  const selectRow = (row: AggregatedCertificationType) => {
    navigate(`/certification-types/${row._id}`);
  };

  const renderTable = () => {
    return (
      <TableV2
        id="certification-types-table"
        resource="certification types"
        data={certificationTypes}
        columns={columns}
        onSelect={handleSelect}
        isLoading={loading}
        defaultSelectedRows={selectedRows}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        hideFooter={true}
        containerStyle={{ paddingBottom: 0 }}
        onClick={selectRow}
      />
    );
  };

  return (
    <div>
      {renderTable()}
      {createCertification && (
        <CertificationTypeWizard
          onExit={closeCertificationWizard}
          onComplete={closeCertificationWizard}
          mode={"all"}
        />
      )}
      {confirmModal === "delete" && (
        <BasicModal
          headerText={"Are you sure?"}
          bodyText={
            "Deleting a certification type will delete all certifications with this type. If you still want access those certifications, do not delete this."
          }
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button1Action={() => setConfirmModal(null)}
          button2Action={archiveCertificationType}
          button2ClassName={"button-3"}
        />
      )}
      {renderFailuresModal()}
    </div>
  );
};

export default CertificationTypesTable;
