import React, { useState } from "react";
import { Button, Formblock, Label, Loader, Notifier } from "ui";
import * as vals from "dashboard/utils/validators";
import { ModalHeader } from "ui";
import { ModalFooter } from "ui";
import { AggregatedTeamMemberWithI9 } from "dashboard/miter";
import { TeamMember } from "backend/models";
import UpdateI9Modal from "dashboard/components/i-9s/UpdateI9Modal";
import { timezoneOptions } from "miter-utils";
import {
  useActiveCompany,
  useDepartmentOptions,
  useHasEnabledWcGroups,
  useLocationOptions,
  useRefetchTeam,
  useTeamOptions,
  useWcCodeOptions,
  useWcGroupOptions,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { DateTime } from "luxon";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { employmentCategoryOptions, employmentTermOptions } from "../TeamUtils";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";

type PatchTeamMemberBody = Partial<TeamMember>;

type Props = {
  defaultData: AggregatedTeamMemberWithI9;
  onHide: () => void;
  onSubmit: (update: PatchTeamMemberBody, scheduleChanges: boolean) => void;
  submitting: boolean;
  form: UseFormMethods<FieldValues>;
  submitText?: string;
};

const EmploymentInfoForm: React.FC<Props> = ({
  defaultData,
  onHide,
  onSubmit,
  submitting,
  form,
  submitText,
}) => {
  const activeCompany = useActiveCompany();
  const refetchTeam = useRefetchTeam();
  const { can, cannot } = useMiterAbilities();
  const isActiveOnClasp = isActiveClaspCompany(activeCompany);
  const hasEnabledWcGroups = useHasEnabledWcGroups();
  const enabledKioskClockIn = activeCompany?.settings.timesheets.kiosk?.enabled;

  const { register, control, errors, handleSubmit, formState, watch } = form;
  const formData = watch();

  const wcCodeOptions = useWcCodeOptions({ defaultValue: defaultData.wc_code?._id });
  const wcGroupOptions = useWcGroupOptions({ defaultValue: defaultData.wc_group_id });
  const reportsToOptions = useTeamOptions({ defaultValue: defaultData.reports_to?._id });
  const deptOptions = useDepartmentOptions({ defaultValue: defaultData.department?._id });
  const locationOptions = useLocationOptions({ defaultValue: defaultData.location_id });
  const [showUpdateI9Modal, setShowUpdateI9Modal] = useState(false);

  const cleanDataForUpdate = (data, scheduleChanges: boolean) => {
    if (defaultData.end_date) {
      const startDate = data.start_date || defaultData.start_date;
      const endDate = data.end_date || defaultData.end_date;
      if (startDate && endDate < startDate) {
        return Notifier.error("End date cannot be before start date");
      }
    }

    const update: PatchTeamMemberBody = {};
    // Loop through dirtied fields and make sure formatting is
    // compatible with backend.
    for (const key of Object.keys(formState.dirtyFields)) {
      if (key === "start_date" || key === "end_date") {
        update[key] = data[key]?.toISODate() || null;
      } else if (key === "wc_code") {
        update.wc_code = data.wc_code?.value || null;
      } else if (key === "wc_group_id") {
        update.wc_group_id = data.wc_group_id?.value || null;
      } else if (key === "reports_to") {
        update.reports_to = data.reports_to?.value || null;
      } else if (key === "department") {
        update.department_id = data.department?.value || null;
      } else if (key === "location") {
        update.location_id = data.location?.value || null;
      } else if (key === "timezone") {
        update.timezone = data.timezone?.value || null;
      } else if (key === "language") {
        update.language = data.language?.value || "en";
      } else if (key === "employment_category") {
        update.employment_category = data.employment_category?.value || null;
      } else if (key === "employment_term") {
        update.employment_term = data.employment_term?.value || null;
      } else {
        update[key] = data[key];
      }
    }

    onSubmit(update, scheduleChanges);
  };

  const handleUpdateI9 = async () => {
    setShowUpdateI9Modal(true);
  };

  return (
    <>
      <div className="modal-wrapper form two-column">
        <ModalHeader onHide={onHide} heading={"Edit " + defaultData.first_name + "'s employment info"} />
        {defaultData ? (
          <div className="modal-body form two-column">
            <div className={"modal-form-column"}>
              <Formblock
                label="ID"
                defaultValue={defaultData.friendly_id}
                type="text"
                name="friendly_id"
                register={register(vals.required)}
                className="modal small-margin"
                errors={errors}
                editing={true}
              />

              {can("team:read_sensitive") && (
                <Formblock
                  label="Kiosk pin"
                  defaultValue={defaultData.kiosk_pin || "-"}
                  type="text"
                  name="kiosk_pin"
                  register={register(vals.isValidKioskPin)}
                  className="modal small-margin"
                  errors={errors}
                  editing={true}
                  disabled={cannot("team:update_sensitive")}
                />
              )}

              <Formblock
                label="Title"
                defaultValue={defaultData.title}
                type="text"
                name="title"
                register={register()}
                className="modal small-margin"
                errors={errors}
                editing={true}
              />
              <Formblock
                label={`Language preference`}
                type="select"
                name="language"
                form={form}
                className="modal small-margin"
                defaultValue={defaultData.language || "en"}
                editing={true}
                options={[
                  { label: "English", value: "en" },
                  { label: "Español", value: "es" },
                ]}
              />
              <Formblock
                label="Department"
                defaultValue={defaultData?.department?._id}
                type="select"
                name="department"
                options={deptOptions}
                control={control}
                className="modal small-margin"
                errors={errors}
                editing={true}
                isClearable
              />
              <Formblock
                label="Location"
                defaultValue={defaultData?.location_id}
                type="select"
                name="location"
                options={locationOptions}
                control={control}
                className="modal small-margin"
                errors={errors}
                editing={true}
                isClearable
              />
              <Formblock
                type="checkbox"
                label="Give supervisor permission on ALL jobs?"
                labelInfo="If this team member should only be a supervisor for specific jobs, leave this box unchecked."
                text="This team member is a supervisor with permission to create and edit timesheets on behalf of others, on any job."
                name="is_universal_supervisor"
                defaultValue={defaultData.is_universal_supervisor}
                register={register}
                className="modal small-margin extra-margin supervisor-permission-checkbox"
                errors={errors}
                editing={true}
                style={{ marginBottom: 35 }}
              />
              {enabledKioskClockIn && (
                <Formblock
                  label="Kiosk mode"
                  type="checkbox"
                  text="This team member can enable kiosk mode."
                  name="can_enable_kiosk"
                  defaultValue={defaultData.can_enable_kiosk}
                  register={register}
                  className="modal small-margin extra-margin supervisor-permission-checkbox"
                  errors={errors}
                  editing={true}
                />
              )}
            </div>
            <div className="modal-form-column">
              <Formblock
                label="Start date"
                type="datetime"
                dateOnly={true}
                name="start_date"
                form={form}
                className="double time-off-request-date"
                editing={true}
                defaultValue={defaultData.start_date ? DateTime.fromISO(defaultData.start_date) : null}
              />
              {defaultData.end_date && can("team:read_sensitive") && (
                <Formblock
                  label="End date"
                  type="datetime"
                  dateOnly={true}
                  name="end_date"
                  form={form}
                  className="double time-off-request-date"
                  editing={true}
                  defaultValue={defaultData.end_date ? DateTime.fromISO(defaultData.end_date) : null}
                  disabled={cannot("team:update_sensitive")}
                />
              )}
              {reportsToOptions && (
                <Formblock
                  label="Reports to"
                  defaultValue={defaultData.reports_to?._id}
                  type="select"
                  name="reports_to"
                  control={control}
                  className="modal small-margin"
                  options={reportsToOptions}
                  errors={errors}
                  editing={true}
                  isClearable
                />
              )}
              <Formblock
                label="Employment category"
                defaultValue={defaultData?.employment_category}
                type="select"
                name="employment_category"
                options={employmentCategoryOptions}
                control={control}
                className="modal small-margin"
                errors={errors}
                editing={true}
                val={isActiveOnClasp ? vals.required : undefined}
                isClearable
              />
              {(defaultData.employment_category === "part_time" ||
                formData.employment_category?.value === "part_time") && (
                <Formblock
                  type="number"
                  name="estimated_hours_worked_per_week"
                  label="Hours worked (weekly estimate)"
                  labelInfo="Number of hours this team member works per week, on average"
                  form={form}
                  editing={true}
                  className="modal small-margin"
                  defaultValue={defaultData.estimated_hours_worked_per_week}
                  val={isActiveOnClasp ? vals.required : undefined}
                  placeholder={"40"}
                />
              )}
              <Formblock
                label="Employment term"
                defaultValue={defaultData?.employment_term}
                type="select"
                name="employment_term"
                options={employmentTermOptions}
                control={control}
                className="modal small-margin"
                errors={errors}
                editing={true}
                isClearable
              />
              {defaultData.employment_type === "employee" && (
                <>
                  <Formblock
                    label="Workers' compensation code"
                    labelInfo="The employee's default workers' comp code."
                    defaultValue={defaultData.wc_code ? defaultData.wc_code._id : undefined}
                    name="wc_code"
                    type="select"
                    control={control}
                    className="modal small-margin"
                    errors={errors}
                    editing={true}
                    options={wcCodeOptions}
                    isClearable
                  />
                  {hasEnabledWcGroups && (
                    <Formblock
                      label="Workers' compensation group"
                      labelInfo="The employee's workers comp group."
                      defaultValue={defaultData.wc_group_id || null}
                      name="wc_group_id"
                      type="select"
                      control={control}
                      className="modal small-margin"
                      errors={errors}
                      editing={true}
                      options={wcGroupOptions}
                      isClearable
                    />
                  )}
                </>
              )}
              <Formblock
                type="select"
                name="timezone"
                label="Timezone"
                register={register}
                control={control}
                options={timezoneOptions}
                editing={true}
                errors={errors}
                className="modal small-margin"
                // Get current timezone
                defaultValue={defaultData.timezone}
                isClearable
              />
              {activeCompany?.settings.team?.onboarding?.enable_online_i9s && can("team:update_sensitive") && (
                <>
                  <Label
                    label="Other actions"
                    labelStyle={{ marginBottom: 10, marginTop: 20 }}
                    className="modal"
                  />
                  <Button className="button-1 no-margin" onClick={handleUpdateI9}>
                    {defaultData.I9 ? "Update I-9" : "Add I-9"}
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          <Loader />
        )}
        <ModalFooter
          loading={submitting}
          onCancel={onHide}
          cancelText={"Cancel"}
          onSubmit={handleSubmit((data) => cleanDataForUpdate(data, false))}
          submitText={submitText || "Save"}
          className="form"
          showEdit={submitText !== "Request changes"}
          editText={"Schedule changes"}
          onEdit={handleSubmit((data) => cleanDataForUpdate(data, true))}
        />
      </div>
      {showUpdateI9Modal && (
        <UpdateI9Modal
          onCancel={() => setShowUpdateI9Modal(false)}
          onFinish={() => {
            setShowUpdateI9Modal(false);
            refetchTeam(defaultData._id);
          }}
          teamMember={defaultData}
        />
      )}
    </>
  );
};

export default EmploymentInfoForm;
