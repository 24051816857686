import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Formblock, Notifier, TableV2 } from "ui";
import styles from "./Performance.module.css";
import { AggregatedPerformanceReviewCycle, Form, FormSubmission, MiterAPI } from "dashboard/miter";
import { Option } from "ui/form/Input";
import { generateReviewPeriodString } from "dashboard/utils/performance";
import { FormComponent } from "../forms/FormBuilderScreen";
import { ValueFormatterParams } from "ag-grid-community";
import { isQuestionAnswered } from "dashboard/utils/form";
import { useNavigate } from "react-router-dom";
import { ColumnConfig } from "ui/table-v2/Table";

type Props = {
  performanceReviewSchedule: AggregatedPerformanceReviewCycle;
};

const assessmentTypeOptions = [
  { label: "Self review", value: "self_review" },
  { label: "Manager review", value: "direct_report_review" },
];

type AssessmentQuestionEntry = FormComponent & {
  count: number;
};

const columns: ColumnConfig<AssessmentQuestionEntry>[] = [
  {
    field: "name",
    dataType: "string",
    headerName: "Question",
  },
  {
    field: "count",
    headerName: "# Responses",
    dataType: "number",
    valueFormatter: (params: ValueFormatterParams<AssessmentQuestionEntry>) =>
      (params?.data?.count || 0) + "",
  },
];

export const PerformanceReport: FC<Props> = ({ performanceReviewSchedule }) => {
  const { control, errors, register } = useForm();
  const {
    _id: performanceReviewScheduleId,
    self_review: { enabled: selfReviewEnabled },
    request_style,
  } = performanceReviewSchedule;
  const navigate = useNavigate();
  const [reviewCycleWindows, setReviewCycleWindows] = useState<Option<string>[]>([]);
  const [reviewCycleFilter, setReviewCycleFilter] = useState<Option<string> | null>(null);
  const [assessmentTypeFilter, setAssessmentTypeFilter] = useState<Option<string> | null>(null);
  const [questions, setQuestions] = useState<AssessmentQuestionEntry[]>([]);

  // Grabs the review cycle window from every performance review of the schedule
  const getUniqueReviewCyclesWindows = async () => {
    try {
      const response = await MiterAPI.performance_reviews.unique_review_cycles({
        filter: [{ field: "review_cycle_id", value: performanceReviewScheduleId }],
        sort: [
          {
            field: "review_period",
            direction: -1,
          },
        ],
      });
      setReviewCycleWindows(
        response.data.map((reviewCycleWindow) => ({
          label: generateReviewPeriodString(reviewCycleWindow._id),
          value: reviewCycleWindow._id,
        }))
      );
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error fetching performance reviews. We're looking into it.");
    }
  };

  const isPeriodic = ["start_of_cycle", "anniversary"].includes(request_style);

  useEffect(() => {
    if (isPeriodic) {
      getUniqueReviewCyclesWindows();
    }
  }, [isPeriodic]);

  useEffect(() => {
    const initialAssessmentTypeFilter = selfReviewEnabled
      ? { label: "Self review", value: "self_review" }
      : { label: "Manager review", value: "direct_report_review" };
    setAssessmentTypeFilter(initialAssessmentTypeFilter);
  }, [selfReviewEnabled]);

  useEffect(() => {
    if (reviewCycleWindows?.[0]) {
      setReviewCycleFilter(reviewCycleWindows[0]);
    }
  }, [reviewCycleWindows]);

  useEffect(() => {
    getQuestions();
  }, [reviewCycleFilter, assessmentTypeFilter, isPeriodic]);

  const getQuestions = async () => {
    if (!assessmentTypeFilter?.value) {
      return;
    }
    try {
      const formResp = await MiterAPI.performance_reviews.forage({
        filter: [
          { field: "review_cycle_id", value: performanceReviewScheduleId },
          ...(isPeriodic ? [{ field: "review_period", value: reviewCycleFilter?.value }] : []),
        ],
        select: [
          {
            field:
              assessmentTypeFilter.value === "self_review" ? "self_review_submission" : "reviewer_submission",
            show: true,
          },
        ],
      });

      setQuestions(
        createFormQuestionEntries({
          form: performanceReviewSchedule?.[`${assessmentTypeFilter.value}_form`],
          submissions: formResp.data.map(
            (item) =>
              item[
                assessmentTypeFilter.value === "self_review"
                  ? "self_review_submission"
                  : "reviewer_submission"
              ]
          ),
        })
      );
    } catch (e: $TSFixMe) {
      console.error("Error grabbing form submissions from performance reviews", e);
      Notifier.error("There was an error fetching the assessment form. We're looking into it.");
    }
  };

  const handleRowClick = (row: AssessmentQuestionEntry) => {
    navigate(
      `/performance/review-schedules/${performanceReviewSchedule._id}/report/question/${row._id}?review-window=${reviewCycleFilter?.value}&assessment-type=${assessmentTypeFilter?.value}`
    );
  };

  const createFormQuestionEntries = (params: {
    form?: Form;
    submissions: FormSubmission[];
  }): AssessmentQuestionEntry[] => {
    const { form, submissions } = params;
    if (!form) {
      return [];
    }
    return form.components
      .filter((c) => c.type !== "section")
      .map((c, i) => ({
        ...c,
        index: i + 1,
        count:
          submissions.filter((submission) =>
            submission?.answers?.some(
              (answer) => answer.form_field_id === c._id && isQuestionAnswered(answer)
            )
          ).length || 0,
      }));
  };

  return (
    <div>
      <div style={{ display: "flex", marginBottom: -63 }}>
        <Formblock
          label="Assessment"
          type="select"
          className={`modal ${styles["select-box"]}`}
          options={assessmentTypeOptions}
          editing={true}
          control={control}
          errors={errors}
          register={register}
          style={{ marginRight: 30 }}
          value={assessmentTypeFilter}
          onChange={(option) => {
            setAssessmentTypeFilter(option);
          }}
        />
        {isPeriodic ? (
          <Formblock
            label="Review cycle"
            type="select"
            className={`modal ${styles["select-box"]}`}
            options={reviewCycleWindows}
            editing={true}
            control={control}
            errors={errors}
            register={register}
            value={reviewCycleFilter}
            placeholder="Select a review cycle."
            noOptionsMessage="A review cycle has not started yet."
            onChange={(option) => {
              setReviewCycleFilter(option);
            }}
          />
        ) : null}
      </div>
      <TableV2
        id={"form-submission-questions-table"}
        resource="performance questions"
        data={questions}
        columns={columns}
        hideSearch={true}
        onClick={handleRowClick}
      />
    </div>
  );
};
