import { useFillableTemplates, useLookupFillableTemplates } from "dashboard/hooks/atom-hooks";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { Formblock } from "ui";
import { useMemo } from "react";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import styles from "../TeamMemberChecklistBuilder.module.css";
import { Option } from "packages/ui/form/Input";
import { FillDocumentOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const FillableDocumentTaskContent: React.FC<
  ChecklistTaskContentProps<FillDocumentOnboardingTaskForm>
> = ({ task, setTask }) => {
  const companyDocuments = useFillableTemplates();
  const lookupFillableTemplate = useLookupFillableTemplates();

  const onboardingDocumentOptions = useMemo(() => {
    return companyDocuments.map((doc) => ({ value: doc._id, label: doc.name || "Document" }));
  }, [companyDocuments]);

  const handleDocumentChange = (option: Option<string> | null) => {
    const fileId = lookupFillableTemplate(option?.value)?.fillable_file_id;
    console.log("Option: ", option);

    setTask({
      ...task,
      data: {
        ...task.data,
        fillable_template_id: option?.value,
        file_id: fileId,
      },
    });
  };

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      <Formblock
        type="select"
        name="fillable_template_id"
        label="Select document"
        editing={true}
        defaultValue={task.data.fillable_template_id}
        options={onboardingDocumentOptions}
        className="modal"
        onChange={handleDocumentChange}
        style={{ marginBottom: 0, marginRight: 5 }}
      />

      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
