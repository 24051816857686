import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import React, { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Params, useNavigate, useParams } from "react-router-dom";
import { Button, Toggler } from "ui";
import { CardTransactionsTable } from "../expenses/CardTransactionsTable";
import { ExpenseReimbursementsTable } from "../expenses/ExpenseReimbursementsTable";
import { TimeOffRequestsTable } from "../time-off/TimeOffRequestsTable";
import { ForageRequest, ForageResponse } from "backend/utils/forage/forage-types";
import { MiterAPI } from "dashboard/miter";
import {
  useActionableItems,
  useActiveTeamMember,
  useIsSuperAdmin,
  usePolicies,
} from "dashboard/hooks/atom-hooks";
import ChangeRequestsTable from "dashboard/components/change-requests/ChangeRequestsTable";

type VIEW_STRING =
  | null
  | "expenses"
  | "reimbursements"
  | "timesheets"
  | "time-off-requests"
  | "team-member-change-requests";

export const Approvals: React.FC = () => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeTeamMember = useActiveTeamMember();
  const isSuperAdmin = useIsSuperAdmin();
  const policies = usePolicies();

  const [actionableItems] = useActionableItems();
  const navigate = useNavigate();
  const { view } = useParams<Params>();

  /**********************************************************************************************************
   * useEffects
   **********************************************************************************************************/
  useEffect(() => {
    if (!view && policies.length > 0) {
      const hasTimesheetPolicy = policies.some((policy) => policy.type === "timesheet");
      const hasExpensePolicy = policies.some((policy) => policy.type === "expense");
      const hasReimbursementPolicy = policies.some((policy) => policy.type === "expense_reimbursement");
      const hasTimeOffRequestPolicy = policies.some((policy) => policy.type === "time_off_request");
      const hasTeamMemberChangeRequestPolicy = policies.some(
        (policy) => policy.type === "team_member_change_request"
      );

      let viewString: VIEW_STRING = null;
      if (hasTimesheetPolicy) {
        viewString = "timesheets";
      } else if (hasExpensePolicy) {
        viewString = "expenses";
      } else if (hasReimbursementPolicy) {
        viewString = "reimbursements";
      } else if (hasTimeOffRequestPolicy) {
        viewString = "time-off-requests";
      } else if (hasTeamMemberChangeRequestPolicy) {
        viewString = "team-member-change-requests";
      } else {
        navigate("/home");
        return;
      }

      navigate(`/inbox/approvals/${viewString}`, { replace: true });
    }
  }, [view]);

  /**********************************************************************************************************
   * Handler functions
   **********************************************************************************************************/
  const handleToggle = (path: string) => {
    navigate(`/inbox/approvals/${path}`, { replace: true });
  };

  const togglerConfig = useMemo(() => {
    const hasTimesheetPolicies = policies.filter((policy) => policy.type === "timesheet").length > 0;
    const hasExpensePolicies = policies.filter((policy) => policy.type === "expense").length > 0;
    const hasReimbursementPolicies =
      policies.filter((policy) => policy.type === "expense_reimbursement").length > 0;
    const hasTimeOffRequestPolicies =
      policies.filter((policy) => policy.type === "time_off_request").length > 0;
    const hasTeamMemberChangeRequestPolicies =
      policies.filter((policy) => policy.type === "team_member_change_request").length > 0;

    return [
      {
        label: "Timesheets",
        path: "timesheets",
        count: actionableItems?.counts.timesheets.approvable || 0,
        hide: !hasTimesheetPolicies,
      },
      {
        label: "Card transactions",
        path: "expenses",
        count: actionableItems?.counts.expenses.approvable || 0,
        hide: !hasExpensePolicies,
      },
      {
        label: "Reimbursements",
        path: "reimbursements",
        count: actionableItems?.counts.reimbursements.approvable || 0,
        hide: !hasReimbursementPolicies,
      },
      {
        label: "Time off requests",
        path: "time-off-requests",
        count: actionableItems?.counts.time_off_requests.approvable || 0,
        hide: !hasTimeOffRequestPolicies,
      },
      {
        label: "Team member profile changes",
        path: "team-member-change-requests",
        count: actionableItems?.counts.change_requests.approvable || 0,
        hide: !hasTeamMemberChangeRequestPolicies,
      },
    ];
  }, [actionableItems?.counts]);

  /**********************************************************************************************************
   * Fetching functions
   **********************************************************************************************************/
  const getTimesheets = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.approvals.timesheets(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getExpenses = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.approvals.expenses(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getReimbursements = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.approvals.reimbursements(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getTimeOffRequests = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.approvals.time_off_requests(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  const getChangeRequests = useCallback(
    async (params: ForageRequest): Promise<ForageResponse> => {
      return MiterAPI.action_center.approvals.change_requests(activeTeamMember!._id, params);
    },
    [activeTeamMember?._id]
  );

  /**********************************************************************************************************
   * Render functions
   **********************************************************************************************************/
  const renderTimesheets = () => {
    if (!activeTeamMember) return <></>;
    return (
      <TimesheetsTable defaultFilters={[]} fetchActionableTimesheets={getTimesheets} inboxMode={"approval"} />
    );
  };

  const renderExpenses = () => {
    if (!activeTeamMember) return <></>;
    return (
      <CardTransactionsTable
        fetchActionableExpenses={getExpenses}
        hideToggle={true}
        shouldRedirectURLWhenOpening={false}
        inboxMode={"approval"}
      />
    );
  };

  const renderReimbursements = () => {
    if (!activeTeamMember) return <></>;
    return (
      <ExpenseReimbursementsTable
        fetchActionableReimbursements={getReimbursements}
        inboxMode={"approval"}
        shouldRedirectURLWhenOpening={false}
      />
    );
  };

  const renderTimeOffRequests = () => {
    if (!activeTeamMember) return <></>;
    return (
      <TimeOffRequestsTable inboxMode={"approval"} fetchActionableTimeOffRequests={getTimeOffRequests} />
    );
  };

  const renderTeamMemberChangeRequests = () => {
    if (!activeTeamMember) return <></>;
    return <ChangeRequestsTable inboxMode="approval" fetchActionableChangeRequests={getChangeRequests} />;
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Approvals | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Approvals</h1>
          <div className="flex-1"></div>
          {isSuperAdmin && (
            <Button
              className="button-1"
              text="Settings"
              onClick={() => navigate("/inbox/approvals/settings")}
            />
          )}
        </div>
        <p className={"header-subtitle"}>Review and approve the items waiting for your approval.</p>
        <Toggler secondary={false} active={view} config={togglerConfig} toggle={handleToggle} />
        <div className="view">
          {view === "timesheets" && renderTimesheets()}
          {view === "expenses" && renderExpenses()}
          {view === "reimbursements" && renderReimbursements()}
          {view === "time-off-requests" && renderTimeOffRequests()}
          {view === "team-member-change-requests" && renderTeamMemberChangeRequests()}
        </div>
      </div>
    </div>
  );
};
