import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Formblock, Loader } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";

export const XeroIntegrationConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
  xeroTrackingOptions,
  setXeroTrackingOptions,
}) => {
  const form = useForm();

  const { defaultJobTrackingCategoryId, defaultDepartmentTrackingCategoryId } = useMemo(() => {
    const defaultJobTrackingCategoryId = integration.connection?.metadata?.xero?.jobs_tracking_category_id;
    const defaultDepartmentTrackingCategoryId =
      integration.connection?.metadata?.xero?.departments_tracking_category_id;
    return { defaultJobTrackingCategoryId, defaultDepartmentTrackingCategoryId };
  }, [integration]);

  const getTrackingOptions = async () => {
    if (!integration.connection || xeroTrackingOptions) return;
    try {
      const options = await MiterAPI.integrations.get_xero_tracking_categories(integration.connection._id);
      if (options.error) throw new Error(options.error);
      setXeroTrackingOptions(options);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error retrieving the options.`);
    }
  };

  useEffect(() => {
    getTrackingOptions();
  }, []);

  const handleChange = async (metadataUpdate: {
    jobs_tracking_category_id?: string;
    departments_tracking_category_id?: string;
  }) => {
    const metadata = {
      ...integration?.connection?.metadata,
      xero: { ...integration?.connection?.metadata?.xero, ...metadataUpdate },
    };
    updateIntegrationConnection({ metadata });
  };

  return xeroTrackingOptions ? (
    <div style={{ maxWidth: 500 }}>
      <div style={{ fontWeight: "bold", fontSize: 16, marginBottom: 15 }}>Tracking categories</div>
      <Formblock
        form={form}
        options={xeroTrackingOptions}
        name="jobs_tracking_category_id"
        type="select"
        label="Jobs"
        defaultValue={defaultJobTrackingCategoryId}
        onChange={(o) => handleChange({ jobs_tracking_category_id: o.value })}
        editing={true}
        labelStyle={{ minWidth: 215 }}
        labelInfo="If you use tracking categories for jobs, select the category here. Otherwise, leave this blank."
      />
      <Formblock
        form={form}
        options={xeroTrackingOptions}
        name="departments_tracking_category_id"
        type="select"
        label="Departments"
        defaultValue={defaultDepartmentTrackingCategoryId}
        onChange={(o) => handleChange({ departments_tracking_category_id: o.value })}
        editing={true}
        labelStyle={{ minWidth: 215 }}
        labelInfo="If you use tracking categories for departments, select the category here. Otherwise, leave this blank."
      />
    </div>
  ) : (
    <Loader />
  );
};
