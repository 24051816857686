import AppContext from "dashboard/contexts/app-context";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notifier, Button } from "ui";
import styles from "./integrations.module.css";
import qboLogo from "dashboard/assets/approvedQbLogo.png";
import qbdLogo from "dashboard/assets/approvedQbLogo.png";
import guidelineLogo from "dashboard/assets/Guideline.png";
import siLogo from "dashboard/assets/SimplyInsured.png";
import sage300Logo from "dashboard/assets/sage300logo.png";
import sage100Logo from "dashboard/assets/sage100logo.png";
import vistaLogo from "dashboard/assets/vistaLogo.png";
import xeroLogo from "dashboard/assets/xeroLogo.png";
import foundationLogo from "dashboard/assets/foundationLogo.png";
import contractorsPlanLogo from "dashboard/assets/contractorsPlanLogo.png";
import employeeNavigatorLogo from "dashboard/assets/employeeNavigatorLogo.svg";
import jonasLogo from "dashboard/assets/jonasLogo.png";
import wbsLogo from "dashboard/assets/wbsLogo.png";
import sageIntacctLogo from "dashboard/assets/sageIntacctlogo.png";
import procoreLogo from "dashboard/assets/Procore_Logo_FC_Black_RGB.png";
import hcssLogo from "dashboard/assets/hcss.png";
import checkrLogo from "dashboard/assets/checkr.png";
import bambooLogo from "dashboard/assets/bamboo-logo.png";
import acumaticaLogo from "dashboard/assets/acumatica_logo.png";
import netsuiteLogo from "dashboard/assets/netsuite-logo.png";
import buildopsLogo from "dashboard/assets/buildops-logo.png";
import payrollIntegrationsLogo from "dashboard/assets/401kIntegrations.png";

type IntegrationListItemProps = {
  integration: MiterIntegrationForCompany;
};

const logoMap = {
  qbo: qboLogo,
  qbd: qbdLogo,
  procore: procoreLogo,
  guideline: guidelineLogo,
  foundation: foundationLogo,
  simply_insured: siLogo,
  sage_300: sage300Logo,
  sage_intacct: sageIntacctLogo,
  vista: vistaLogo,
  xero: xeroLogo,
  jonas: jonasLogo,
  contractors_plan: contractorsPlanLogo,
  wbs: wbsLogo,
  employee_navigator: employeeNavigatorLogo,
  hcss: hcssLogo,
  checkr: checkrLogo,
  bamboo: bambooLogo,
  acumatica: acumaticaLogo,
  netsuite: netsuiteLogo,
  sage_100: sage100Logo,
  buildops: buildopsLogo,
  payroll_integrations: payrollIntegrationsLogo,
};

const statusColorMap = {
  "Not connected": "red",
  "Pending setup": "orange",
  Connected: "green",
};

export const IntegrationListItem: React.FC<IntegrationListItemProps> = ({ integration }) => {
  const { key } = integration;

  const { getIntegrations } = useContext(AppContext);
  const logoSrc = logoMap[key];
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();

  const [buttonLoading, setButtonLoading] = useState(false);

  let connectionStatus = "Not connected";
  if (integration.connection) {
    if (integration.connection.pending_setup) {
      connectionStatus = "Pending setup";
    } else {
      connectionStatus = "Connected";
    }
  }

  const pendingSetup = integration.connection?.pending_setup;
  let buttonLabel = connectionStatus === "Connected" ? "Manage" : "Set up";
  if (pendingSetup) {
    buttonLabel = "Continue";
  }

  const openMgmtLink = async () => {
    if (!integration.connection?._id) return;
    setButtonLoading(true);
    try {
      const response = await MiterAPI.integrations.get_mgmt_link(
        integration.key,
        integration.connection._id.toString()
      );
      if (response.error) throw new Error(response.error);
      window.open(response.link, "_blank");
    } catch (e) {
      console.error(e);
      Notifier.error("There was a problem retrieving the link to manage your integration.");
    }
    setButtonLoading(false);
  };

  const setupIntegrationConnection = async () => {
    if (!activeCompanyId) return;
    setButtonLoading(true);
    try {
      const response = await MiterAPI.integrations.setup(activeCompanyId, integration.key);
      if (response.error) throw new Error(response.error);
      await getIntegrations();
      navigate("/integrations/" + integration.key);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error setting up the integration. We're looking into it!");
    }
    setButtonLoading(false);
  };

  const handleButtonClick = async () => {
    if (connectionStatus === "Connected" && integration.has_mgmt_link) {
      await openMgmtLink();
    } else if (connectionStatus === "Not connected") {
      await setupIntegrationConnection();
    } else {
      navigate("/integrations/" + integration.key);
    }
  };

  return (
    <div className={styles["integration-wrapper"]}>
      <div className={styles["integration-logo-wrapper"]}>
        <img src={logoSrc} className={styles["integration-logo"]} />
      </div>
      <div className="flex-1"></div>
      <div className={styles["integration-description-wrapper"]}>
        <div className="bold">{integration.label}</div>
        <div className={styles["integration-description"]}>{integration.description}</div>
      </div>
      <div className="flex-1"></div>
      <div className={styles["integration-status-wrapper"]}>
        <div className={`${styles["status-circle"]} ${styles[statusColorMap[connectionStatus]]}`}></div>
        <div>{connectionStatus}</div>
      </div>
      <div className="flex-1"></div>
      <div className={styles["integration-button-wrapper"]}>
        <Button loading={buttonLoading} className="button-2" text={buttonLabel} onClick={handleButtonClick} />
      </div>
    </div>
  );
};
