import { IntegrationSync, MiterIntegrationForCompany } from "dashboard/miter";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { Formblock, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { DateRange } from "ui/form/DateRangePicker";
import { IntegrationSyncModal } from "./IntegrationSyncModal";
import { useForm } from "react-hook-form";
import Warning from "dashboard/assets/yellow-warning.png";
import { useEnhancedSearchParams } from "miter-utils";

type Props = {
  integration: MiterIntegrationForCompany;
  syncs?: IntegrationSync[];
  fetchingSyncs: boolean;
  syncsRange: DateRange;
  setSyncsRange: (range: DateRange) => void;
};

type IntegrationSyncTableEntry = {
  _id: string;
  syncObj: IntegrationSync;
  directionName?: string;
  trigger: string | null;
  entityName?: string;
  createdAt: number;
  errorMessage?: string;
};

export const IntegrationSyncHistory: React.FC<Props> = ({
  integration,
  syncs,
  fetchingSyncs,
  syncsRange,
  setSyncsRange,
}) => {
  const integrationLabel = integration?.label;
  const form = useForm();

  const { setSearchParams, parsedSearchParams } = useEnhancedSearchParams<"isrid">({
    replaceInHistory: true,
  });

  const syncId = parsedSearchParams.isrid;

  const tableData = useMemo(() => {
    return (
      syncs?.map((sync): IntegrationSyncTableEntry => {
        let errorMessage = sync.error_message || undefined;
        if (!errorMessage && sync.status === "error") {
          errorMessage = "One or more records failed to sync";
        }
        return {
          _id: sync._id,
          createdAt: sync.created_at,
          entityName: integrationEntityLookup[sync.entity],
          directionName: getDirectionName(integrationLabel, sync.direction),
          trigger: sync.trigger && triggerLookup[sync.trigger],
          syncObj: sync,
          errorMessage,
        };
      }) || []
    );
  }, [syncs]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", maxWidth: 1400 }}>
      {syncId && (
        <IntegrationSyncModal
          integration={integration}
          integrationSyncId={syncId}
          hide={() => setSearchParams({ isrid: undefined })}
        />
      )}
      <Formblock
        register={form.register}
        defaultValue={syncsRange}
        value={syncsRange}
        control={form.control}
        type="daterange"
        name="range"
        max={DateTime.now()}
        labelStyle={{ maxWidth: 100, fontFamily: "Karla", fontSize: 15 }}
        errors={form.errors}
        style={{ alignItems: "center" }}
        editing={true}
        onChange={setSyncsRange}
        inputProps={{ style: { maxWidth: 250, fontFamily: "Karla" } }}
      />{" "}
      <div className="vertical-spacer"></div>
      <TableV2
        id="integration-sync-history"
        resource="integration syncs"
        data={tableData}
        columns={colDefs}
        isLoading={fetchingSyncs}
        hideSearch={true}
        onClick={(r) => setSearchParams({ isrid: r._id })}
        hideSecondaryActions={true}
        containerStyle={{ marginTop: -50, paddingBottom: 0 }}
      />
    </div>
  );
};

export const integrationEntityLookup = {
  jobs: "Jobs",
  team_members: "Team members",
  timesheets: "Timesheets",
  benefits: "Benefits",
  ledger_entries: "GL entries",
  ledger_accounts: "GL accounts",
  activities: "Activities",
  departments: "Departments",
  cost_types: "Cost types",
  employee_benefits: "Employee benefits",
  payrolls: "Payrolls",
  expenses: "Expenses",
  standard_classifications: "Standard classifications",
  equipment: "Equipment",
  quantities: "Quantities",
  locations: "Locations",
  post_tax_deductions: "Post-tax deductions",
};

export const getDirectionName = (integrationLabel: string, direction: string, capitalize = true): string => {
  let string = "";
  if (direction === "pull") {
    string = `from ${integrationLabel} to Miter`;
  } else if (direction === "push") {
    string = `from Miter to ${integrationLabel}`;
  }
  return capitalize ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};

export const triggerLookup = {
  daily: "Daily sync",
  manual: "Manual",
  automatic: "Automatic",
};

// TODO
const colDefs: ColumnConfig<IntegrationSyncTableEntry>[] = [
  { field: "createdAt", headerName: "Sync ran at", dataType: "date", dateFormat: "MMM d, yyyy, h:mm a ZZZZ" },
  { field: "trigger", headerName: "Trigger", filter: "agSetColumnFilter" },
  { field: "entityName", headerName: "Entity", filter: "agSetColumnFilter" },
  { field: "directionName", headerName: "Direction", filter: "agSetColumnFilter" },
  {
    field: "errorMessage",
    headerName: " ",
    cellRenderer: (params) => {
      return params.value ? <img src={Warning} height={20} width={20} /> : <div></div>;
    },
  },
];
