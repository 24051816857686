import React from "react";
import { FaArrowCircleRight, FaExclamationTriangle, FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import { Loader } from "ui";
import styles from "./Banner.module.css";
import xPng from "dashboard/assets/x.png";

type Props = {
  content?: React.ReactChild;
  onClick?: () => void;
  onDismiss?: () => void;
  /* info is similar to modal but includes info icon */
  type?: "error" | "warning" | "modal" | "info" | "question";
  children?: React.ReactNode;
  style?: $TSFixMe;
  className?: string;
  loading?: boolean;
  hideIcon?: boolean;
};

const Banner: React.FC<Props> = ({
  content,
  onClick,
  onDismiss,
  type,
  style,
  children,
  className,
  loading,
  hideIcon,
}) => {
  let containerClassName = styles["banner-container"];

  if (type === "modal" || type === "info") containerClassName += ` ${styles["banner-modal"]}`;
  if (className) containerClassName += ` ${className}`;

  let bannerClassName = styles["banner"];
  if (type === "error") bannerClassName += ` ${styles["banner-error"]}`;
  else if (type === "warning") bannerClassName += ` ${styles["banner-warning"]}`;
  else if (type === "question") bannerClassName += ` ${styles["banner-question"]}`;
  if (onClick) bannerClassName += ` ${styles["pointer"]}`;

  return (
    <div className={containerClassName} style={style}>
      <div className={bannerClassName} onClick={onClick}>
        {type === "error" && !hideIcon && (
          <div className={styles["banner-error-icon"]}>
            <FaExclamationTriangle className={styles["banner-error-icon"]} />
          </div>
        )}
        {type === "warning" && !hideIcon && (
          <div className={styles["banner-warning-icon"]}>
            <FaExclamationTriangle className={styles["banner-warning-icon"]} />
          </div>
        )}
        {type === "info" && !hideIcon && (
          <div className={styles["banner-warning-icon"]}>
            <FaInfoCircle className={styles["banner-icon"]} />
          </div>
        )}

        {type === "question" && !hideIcon && (
          <div className={styles["banner-question-icon"]}>
            <FaQuestionCircle style={{ color: "gray" }} />
          </div>
        )}
        <div className={styles["banner-content"]}>{children || content}</div>
        {onDismiss && (
          <div className={styles["banner-icon"]}>
            <img src={xPng} onClick={onDismiss} style={{ marginLeft: 15, height: 12, width: 12 }} />
          </div>
        )}
        {onClick && (
          <div className={styles["banner-icon"]}>
            {loading ? <Loader className="small-text" /> : <FaArrowCircleRight />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
