import React, { useEffect, useMemo } from "react";
import { Formblock, Label, Notifier, WizardScreen, usdString } from "ui";
import styles from "../forms/Forms.module.css";
import { useForm, useWatch } from "react-hook-form";
import { Company, MiterAPI, TeamMember, UpdateTeamMemberParams } from "dashboard/miter";
import { Props } from "./types";
import { Option } from "ui/form/Input";
import useWizard from "ui/modal/useWizard";
import * as vals from "dashboard/utils/validators";
import { salaryRateOptions } from "miter-utils";
import { PaymentData } from "dashboard/types/onboarding-types";
import {
  convertAnnualRateToDisplayRate,
  convertDisplayRateToAnnualRate,
} from "dashboard/pages/team-members/TeamUtils";
import { DateTime } from "luxon";
import { TeamPortalUser } from "team-portal/utils/miter";
import { useOtExemptUtils } from "dashboard/hooks/useOtExempt";

type EmploymentInformationProps = Props & {
  company: Company | TeamPortalUser["company"] | null;
  pay: PaymentData;
};

export const EmploymentInformationScreen: React.FC<EmploymentInformationProps> = ({
  company,
  task,
  name,
  onboardingChecklistItem,
  pay,
}) => {
  const { new_hire } = onboardingChecklistItem;
  const { paySchedules, prgs, classifications } = pay;

  const { otExemptionIsRelevant } = useOtExemptUtils();

  const form = useForm({
    mode: "all",
    defaultValues: buildDefaultValues(company, new_hire, pay),
  });
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();
  const {
    errors,
    handleSubmit,
    trigger,
    formState: { errors: formErrors },
    register,
  } = form;

  const formData = useWatch({ control: form.control });

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    trigger();
  }, [formData.pay_type?.value]);

  const classificationOptions = useMemo(() => {
    return classifications.map((c) => {
      return {
        label: c.classification,
        value: c._id,
      };
    });
  }, [classifications]);

  const selectedClassification = useMemo(() => {
    if (formData.classification?.value) {
      return classifications.find((c) => c._id === formData.classification?.value) || null;
    }
    return null;
  }, [classifications, formData.classification?.value]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formErrors, Object.keys(errors)]);

  /**
   * Options
   */

  const payTypeOptions = [
    { value: "hourly", label: prgs.length ? "Hourly (non-union)" : "Hourly" },
    ...(prgs.length ? [{ value: "union_rate", label: "Hourly (union)" }] : []),
    { value: "salary", label: "Salary" },
  ];

  const payScheduleOptions = paySchedules.map((ps) => {
    return {
      label: ps.label,
      value: ps._id,
    };
  });

  const prgOptions = prgs.map((prg) => {
    return {
      label: prg.label,
      value: prg._id,
    };
  });

  const onNext = async () => {
    handleSubmit(
      async (
        data: { [x: string]: Option<string> | null } & {
          start_date: DateTime | null;
        }
      ) => {
        const params = {
          pay_schedule_id: data.pay_schedule_id?.value,
          salary_rate_display: data.salary_rate_display?.value,
          overtime_exempt: data.overtime_exempt,
          pay_type: data.pay_type?.value === "union_rate" ? "hourly" : data.pay_type?.value,
          pay_rate:
            data.pay_type?.value === "salary"
              ? convertDisplayRateToAnnualRate(
                  company,
                  data.salary_rate_display?.value || "year",
                  (data.salary_rate as unknown as number) || 0
                )
              : Number(data.pay_rate),
          union_rate: data.pay_type?.value === "union_rate" ? data.classification?.value : null,
          start_date: data.start_date?.toISODate(),
        };

        try {
          const res = await MiterAPI.team_member.update(new_hire._id, params as UpdateTeamMemberParams);
          if (res.fields?.length) {
            throw new Error(res.fields?.map((field) => field.error).join(", "));
          }
          if (res.error) {
            throw new Error(res.error);
          }
          if (curIndex === screens.length - 1) {
            handleComplete();
          }
        } catch (e: $TSFixMe) {
          console.error("Error saving team member in EmploymentInformationScreen", e);
          Notifier.error(e.message);
          throw e;
        }
      }
    )();
  };

  const renderUnionPayRate = () => {
    return (
      <>
        <Formblock
          type="select"
          name="pay_rate_group"
          label="Pay rate group*"
          form={form}
          options={prgOptions}
          requiredSelect={true}
          val={vals.required}
          editing={true}
          className="modal wizard"
        />
        {classificationOptions && (
          <Formblock
            type="select"
            name="classification"
            label="Classification*"
            form={form}
            options={classificationOptions}
            requiredSelect={true}
            val={vals.required}
            editing={true}
            className="modal wizard"
          />
        )}
        {selectedClassification && (
          <Formblock
            type="text"
            name="union_pay_rate"
            label="Base hourly rate"
            value={usdString(selectedClassification?.base_rate)}
            form={form}
            editing={true}
            className="modal wizard"
            disabled={true}
          />
        )}
      </>
    );
  };

  const renderSalaryPayRate = () => {
    return (
      <div className="form-section">
        <Label label={"Pay rate" + "*"} style={{ marginBottom: 5 }} />

        <div className="flex space-between">
          <Formblock
            type="unit"
            unit="$"
            form={form}
            placeholder={"0.00"}
            name="salary_rate"
            className="modal wizard"
            editing={true}
            style={{ width: "100%", height: 32 }}
            val={vals.required}
          />
          <Formblock
            type="select"
            options={salaryRateOptions}
            defaultValue={new_hire?.salary_rate_display || "year"}
            form={form}
            name="salary_rate_display"
            className="modal wizard"
            editing={true}
            style={{ width: 150, marginLeft: 10, height: 32 }}
            val={vals.required}
          />
        </div>
      </div>
    );
  };

  const renderHourlyPayRate = () => {
    return (
      <Formblock
        type="unit"
        unit="$"
        name="pay_rate"
        label={"Hourly pay rate" + "*"}
        form={form}
        placeholder="0.00"
        editing={true}
        className="modal wizard"
        val={vals.required}
      />
    );
  };

  const renderPayRate = () => {
    if (formData.pay_type?.value === "union_rate") {
      return renderUnionPayRate();
    } else if (formData.pay_type?.value === "hourly") {
      return renderHourlyPayRate();
    } else {
      return renderSalaryPayRate();
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        <Formblock
          label={`Start date`}
          labelInfo="Date the team member started working for the company"
          type="datetime"
          dateOnly={true}
          register={register(vals.required)}
          name="start_date"
          placeholder="MM/DD/YYYY"
          form={form}
          className="modal wizard"
          editing={true}
          max={DateTime.now()}
          rules={vals.required}
        />
        <Formblock
          type="select"
          name="pay_schedule_id"
          label={"Pay schedule*"}
          form={form}
          options={payScheduleOptions}
          requiredSelect={true}
          editing={true}
          className="modal wizard"
          val={vals.required}
          defaultValue={new_hire?.pay_schedule_id}
        />
        <Formblock
          type="select"
          name="pay_type"
          label={"Pay type" + "*"}
          form={form}
          options={payTypeOptions}
          editing={true}
          requiredSelect={true}
          className="modal wizard"
          val={vals.required}
          defaultValue={new_hire?.union_rate ? "union_rate" : new_hire?.pay_type}
        />
        {formData.pay_type?.value && (
          <>
            {renderPayRate()}
            {otExemptionIsRelevant(new_hire) && (
              <Formblock
                type="checkbox"
                name="overtime_exempt"
                label="Overtime exempt"
                text="This team member is exempt from overtime pay"
                form={form}
                defaultValue={
                  new_hire.overtime_exempt != null
                    ? new_hire.overtime_exempt
                    : formData.pay_type?.value === "salary"
                }
                editing={true}
                className="modal wizard"
              />
            )}
          </>
        )}
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  company: Company | TeamPortalUser["company"] | null,
  teamMember: TeamMember,
  pay: PaymentData
) => {
  const { classifications } = pay;
  const pay_rate_group_id = classifications.find((c) => c._id === teamMember.union_rate)?.pay_rate_group;
  return {
    pay_rate: teamMember.pay_type === "hourly" ? teamMember.pay_rate : undefined,
    salary_rate:
      teamMember.pay_type === "salary"
        ? convertAnnualRateToDisplayRate(
            company,
            "salary",
            teamMember.salary_rate_display,
            teamMember.pay_rate
          )
        : undefined,
    pay_rate_group: pay_rate_group_id,
    classification: teamMember.union_rate,
    overtime_exempt: teamMember.overtime_exempt,
    start_date: teamMember.start_date ? DateTime.fromISO(teamMember.start_date) : undefined,
  };
};
