import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Button, Formblock, Loader } from "ui";
import { displayFieldErrors } from "dashboard/utils/errors";

import * as vals from "dashboard/utils/validators";
import { Notifier } from "dashboard/utils";
import { AggregatedJob, EquipmentLog, MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import { EQUIPMENT_STATUS_OPTIONS } from "dashboard/utils/equipment";
import styles from "./Equipment.module.css";
import { AggregatedEquipmentLog } from "dashboard/miter";
import EquipmentModal from "./EquipmentModal";
import {
  useActiveCompanyId,
  useEquipmentOptions,
  useJobOptions,
  useRefetchEquipment,
} from "dashboard/hooks/atom-hooks";
import { JobInput } from "../shared/JobInput";

type Props = {
  equipmentLogID?: string;
  defaultDate?: DateTime;
  defaultJob?: AggregatedJob;
  readOnly?: boolean;
  hideDelete?: boolean;
  onCancel: () => void;
  onSubmit: (log?: AggregatedEquipmentLog, action?: "create" | "update" | "delete") => void;
};

const EquipmentLogModal: FC<Props> = ({
  equipmentLogID,
  defaultDate,
  defaultJob,
  readOnly,
  hideDelete,
  onCancel,
  onSubmit,
}) => {
  const form = useForm({ shouldUnregister: false });
  const formData = form.watch();
  const activeCompanyId = useActiveCompanyId();
  const refetchEquipment = useRefetchEquipment();
  const equipmentOptions = useEquipmentOptions();

  const [equipmentLog, setEquipmentLog] = useState<EquipmentLog>();
  const jobOptions = useJobOptions({ defaultValue: equipmentLog?.job_id });
  const [loadingEquipmentLog, setLoadingEquipmentLog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, register, errors, handleSubmit, setError, setValue } = form;

  const [addNewEquipment, setAddNewEquipment] = useState(false);

  useEffect(() => {
    getEquipmentLog();
  }, [equipmentLogID]);

  useEffect(() => {
    setValue("hours", undefined);
  }, [formData.status?.value]);

  // Remove properties that equal what exists in the Equipment log object
  const cleanParams = (data) => {
    if (!data.equipment?.value) {
      throw new Error("Equipment is required");
    }

    return {
      ...data,
      status: data.status?.value || "idle",
      job_id: data.job_id?.value || defaultJob?._id,
      equipment_id: data.equipment?.value,
      company_id: activeCompanyId,
      date: data.date?.toISODate() || defaultDate?.toISODate(),
    };
  };

  const handleEquipmentCreated = async () => {
    await refetchEquipment();
    setAddNewEquipment(false);
  };

  const getEquipmentLog = async () => {
    if (!equipmentLogID) return;
    setLoadingEquipmentLog(true);
    try {
      const res = await MiterAPI.equipment_logs.retrieve(equipmentLogID);
      if (res.error) throw new Error(res.error);

      setEquipmentLog(res as unknown as EquipmentLog);
    } catch (e: $TSFixMe) {
      console.error("Error getting equipment:", e);
      Notifier.error(e.message);
    }
    setLoadingEquipmentLog(false);
  };

  const createEquipmentLog = async (data) => {
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.equipment_logs.create(params, { aggregated: true });
      if (res.error) {
        if (res.fields) {
          displayFieldErrors(res.fields, setError);
        }
        throw new Error(res.error);
      }

      setEquipmentLog(res);
      Notifier.success("Equipment log updated successfully");

      if ("equipment" in res) {
        onSubmit(res, "create");
      }
    } catch (e: $TSFixMe) {
      console.error("Error updating equipment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateEquipmentLog = async (data) => {
    if (!equipmentLog) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.equipment_logs.update(equipmentLog?._id, params, { aggregated: true });
      if (res.error) {
        if (res.fields) {
          displayFieldErrors(res.fields, setError);
        }
        throw new Error(res.error);
      }

      setEquipmentLog(res);
      Notifier.success("Equipment log updated successfully");

      if ("equipment" in res) {
        onSubmit(res, "update");
      }
    } catch (e: $TSFixMe) {
      console.error("Error updating equipment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const deleteEquipmentLog = async () => {
    if (!equipmentLog) return;
    setLoading(true);
    try {
      const res = await MiterAPI.equipment_logs.delete(equipmentLog?._id, { aggregated: true });
      if (res.error) throw new Error(res.error);

      Notifier.success("Equipment log deleted successfully");

      if ("equipment" in res) {
        onSubmit(res, "delete");
      }
    } catch (e: $TSFixMe) {
      console.error("Error deleting equipment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const submit = () => {
    if (equipmentLogID) {
      handleSubmit(updateEquipmentLog)();
    } else {
      handleSubmit(createEquipmentLog)();
    }
  };

  const hide = () => {
    onCancel();
  };

  const renderForm = () => {
    if (loadingEquipmentLog) return <Loader />;

    const disableHours = formData?.status?.value === "removed" || equipmentLog?.status === "removed";
    const defaultHours =
      (formData?.status?.value || equipmentLog?.status || "").replaceAll("_", " ").toLowerCase() || "";

    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        {!readOnly && (
          <Button
            className={"button-text no-margin " + styles["add-equipment-btn"]}
            onClick={() => setAddNewEquipment(true)}
          >
            + Add new equipment
          </Button>
        )}
        <Formblock
          type="select"
          name="equipment"
          label="Equipment"
          control={control}
          options={equipmentOptions}
          editing={!readOnly}
          errors={errors}
          defaultValue={equipmentLog?.equipment_id}
          className="modal"
        />

        {!defaultDate && (
          <Formblock
            label="Date*"
            type="datetime"
            className="modal"
            defaultValue={equipmentLog?.date ? DateTime.fromISO(equipmentLog.date) : defaultDate}
            name="date"
            disabled={!!defaultDate}
            editing={!readOnly}
            control={control}
            register={register(vals.required)}
            errors={errors}
            dateOnly={true}
            rules={vals.required}
          />
        )}
        {
          <JobInput
            type="select"
            name="job_id"
            label="Job"
            form={form}
            control={control}
            options={jobOptions}
            disabled={readOnly}
            editing={!readOnly}
            errors={errors}
            defaultValue={equipmentLog?.job_id || defaultJob?._id}
            className="modal"
          />
        }
        <div className={styles["equipment-log-modal-info"] + " " + (readOnly ? styles["readonly"] : "")}>
          <Formblock
            type="number"
            name="quantity"
            label="Quantity"
            register={register}
            editing={!readOnly}
            errors={errors}
            defaultValue={equipmentLog?.quantity || 1}
            min={1}
            className="modal"
          />
          <Formblock
            type="select"
            name="status"
            label="Status"
            control={control}
            options={EQUIPMENT_STATUS_OPTIONS}
            editing={!readOnly}
            errors={errors}
            defaultValue={equipmentLog?.status}
            className="modal"
          />
          <Formblock
            type="number"
            name="hours"
            label={"Hours " + (disableHours ? "(N/A)" : defaultHours)}
            register={register}
            editing={!readOnly}
            errors={errors}
            defaultValue={equipmentLog?.hours || 0}
            disabled={disableHours}
            min={0}
            className="modal"
          />
        </div>

        <Formblock
          type="paragraph"
          name="notes"
          label="Notes"
          register={register}
          editing={!readOnly}
          errors={errors}
          defaultValue={equipmentLog?.notes}
          className={"modal " + styles["equipment-log-modal-notes"]}
        />
      </div>
    );
  };

  return (
    <>
      {!addNewEquipment && (
        <ActionModal
          headerText={
            readOnly ? "Equipment log" : !!equipmentLogID ? "Edit equipment log" : "Add equipment log"
          }
          showSubmit={!readOnly}
          showCancel={true}
          showDelete={!readOnly && !hideDelete && !!equipmentLogID}
          cancelText={"Close"}
          onCancel={hide}
          submitText={"Save"}
          deleteText={"Delete"}
          onHide={hide}
          onDelete={deleteEquipmentLog}
          onSubmit={submit}
          loading={loading}
        >
          {renderForm()}
        </ActionModal>
      )}
      {addNewEquipment && (
        <EquipmentModal onCancel={() => setAddNewEquipment(false)} onSubmit={handleEquipmentCreated} />
      )}
    </>
  );
};

export default EquipmentLogModal;
