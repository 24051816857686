import CustomFieldsTable from "dashboard/components/custom-fields/CustomFieldsTable";
import InfoButton from "dashboard/components/information/information";
import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { OvertimeSettings } from "dashboard/pages/settings/payrollSettings/OvertimeCalculation";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { DateTimePicker, Formblock, Input } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { CompanySettings } from "backend/models/company";
import { AutomaticBreakTime } from "./AutomaticBreakTime";
import { AdvancedBreakTime } from "./AdvancedBreakTime";
import { NonSupervisorEditing } from "./NonSupervisorEditing";
import { useForm } from "react-hook-form";
import { RateDifferentials } from "./RateDifferentials";
import {
  useActiveCompany,
  useDepartmentOptions,
  useTimesheetPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { TimesheetMobileFieldRequirements } from "dashboard/components/timesheets/TimesheetMobileFieldRequirements";
import { SettingsCard } from "ui/settings/SettingsCard";
import { TimesheetDashboardFieldRequirements } from "dashboard/components/timesheets/TimesheetDashboardFieldRequirements";
import { PoliciesTable } from "dashboard/components/policies/PoliciesTable";
import { DefaultTimesheetsView } from "./DefaultTimesheetsView";
import Banner from "dashboard/components/shared/Banner";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { MiterGuidesIcon } from "dashboard/components/MiterGuidesIcon";
import { PAY_PERIOD_EDITOR_COLUMN_OPTIONS } from "../TimesheetsByPayPeriod/TimesheetsByPayPeriodEditor";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useHasAccessToBulkEntryPayRates, useHasAccessToSiteBoundClockOut } from "dashboard/gating";

export type TimesheetSettings = CompanySettings["timesheets"];

export const TimesheetSettings: React.FC = () => {
  const form = useForm();
  const { fetchUserData } = useContext(AppContext);
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();
  const abilities = useMiterAbilities();
  const canReadWcCode = abilities.can("lists:workers_comp_codes:read");
  const activeCompanyId = activeCompany?._id;
  const { settings } = activeCompany!;

  const hasAccessToBulkEntryPayRates = useHasAccessToBulkEntryPayRates();
  const hasAccessToSiteBoundClockOut = useHasAccessToSiteBoundClockOut();
  const timesheetPolicyOptions = useTimesheetPolicyOptions();

  const [timesheetSettings, setTimesheetSettings] = useState(settings.timesheets);

  const hasPoliciesEnabled = settings.timesheets.enable_timesheet_policies;
  const hasTimesheetPolicies = timesheetPolicyOptions.length > 0;
  const hasDefaultTimesheetPolicy = timesheetSettings.default_policy_id;
  const GEOFENCE_RADIUS_MIN = 0.1;
  const GEOFENCE_RADIUS_MAX = 10;

  const goBackToTimesheets = () => {
    navigate("/timesheets");
  };

  const departmentOptions = useDepartmentOptions();

  const updateSettings = async (newSettings) => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { "settings.timesheets": newSettings },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Timesheet settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating timesheet settings. Our engineers are looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(updateSettings, 500);

  const handleChange = async (newFields: Partial<TimesheetSettings>, wait?: boolean) => {
    const newSettings = { ...timesheetSettings, ...newFields };
    setTimesheetSettings(newSettings);

    if (wait) {
      await updateSettings(newSettings);
    } else {
      updateSettingsDebounced(newSettings);
    }
  };

  const handleToggleGeolocation = async (e) => {
    const newSettings = { ...timesheetSettings };
    newSettings.geolocation = e.target.checked;

    if (!newSettings.geolocation) {
      newSettings.enable_geolocation_breadcrumbs = false;
      newSettings.enable_geolocation_mileage = false;
    }

    setTimesheetSettings(newSettings);
    updateSettings(newSettings);
  };

  const handleToggleGeolocationGeofencing = async (
    e,
    field: "disable_clock_in_out_outside_geofence" | "disable_clock_out_outside_geofence"
  ) => {
    const newSettings = { ...timesheetSettings };
    newSettings[field] = e.target.checked;

    setTimesheetSettings(newSettings);
    updateSettings(newSettings);
  };

  const handleChangeGeofenceRadius = async (e) => {
    const newSettings = { ...timesheetSettings };
    let newRadius = Math.floor(e.target.value * 100) / 100;

    if (newRadius < GEOFENCE_RADIUS_MIN) {
      newRadius = GEOFENCE_RADIUS_MIN;
    } else if (newRadius > GEOFENCE_RADIUS_MAX) {
      newRadius = GEOFENCE_RADIUS_MAX;
    }

    newSettings.geofence_radius = newRadius;

    setTimesheetSettings(newSettings);
    updateSettings(newSettings);
  };

  const handleToggleGeolocationBreadcrumbs = async (e) => {
    const newSettings = { ...timesheetSettings };
    newSettings.enable_geolocation_breadcrumbs = e.target.checked;

    if (!newSettings.enable_geolocation_breadcrumbs) {
      newSettings.enable_geolocation_mileage = false;
    }

    setTimesheetSettings(newSettings);
    updateSettings(newSettings);
  };

  const roundingIntervalCopy = {
    "1_min": "minute",
    "5_min": "5 minutes",
    "10_min": "10 minutes",
    "15_min": "15 minutes",
    "30_min": "30 minutes",
    "1_hour": "hour",
  }[timesheetSettings.clock_times_rounding_interval];

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Timesheet settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={goBackToTimesheets}>
          Back to timesheets
        </div>
        <div className="flex">
          <h1>Timesheet settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure time-tracking settings for the field and the office.</div>
        <div className="vertical-spacer-small"></div>

        <div className="billing-card-wrapper">
          <div className="flex">
            <div style={{ fontWeight: 600, fontSize: 18 }}>Kiosks</div>
            <InfoButton text="Supervisors can display “kiosk mode” from the mobile app. Multiple crew members can clock in and clock out from a kiosk using their 4 - digit pins." />
          </div>
          <div className="vertical-spacer-small"></div>
          <div className="flex" style={{ alignItems: "flex-start" }}>
            <input
              style={{ marginLeft: 10 }}
              type="checkbox"
              name="kiosk.enabled"
              checked={timesheetSettings.kiosk?.enabled}
              onChange={(e) =>
                handleChange({ kiosk: { ...timesheetSettings.kiosk, enabled: e.target.checked } })
              }
            />
            <span style={{ marginLeft: 15 }}>
              <strong>Enable kiosk mode: </strong>
              {`Allow team members with permissions and all supervisors to set up timesheet kiosk mode from the mobile app. To add this permission for team members, edit the "Can enable kiosk field" from the team member details page.`}
            </span>
          </div>
          {timesheetSettings.kiosk?.enabled ? (
            <div className="flex" style={{ alignItems: "flex-start", marginTop: 8 }}>
              <input
                style={{ marginLeft: 10 }}
                type="checkbox"
                name="kiosk.enabled"
                checked={timesheetSettings.kiosk?.show_timesheets_tab}
                onChange={(e) =>
                  handleChange({
                    kiosk: {
                      ...timesheetSettings.kiosk,
                      enabled: !!timesheetSettings.kiosk?.enabled,
                      show_timesheets_tab: e.target.checked,
                    },
                  })
                }
              />
              <span style={{ marginLeft: 15 }}>
                {`Allow team members to edit, create, and attest to timesheets within kiosk mode.`}
              </span>
            </div>
          ) : null}
          <div className="flex" style={{ alignItems: "flex-start", marginTop: 8 }}>
            <input
              style={{ marginLeft: 10 }}
              type="checkbox"
              name="kiosk.enabled"
              checked={timesheetSettings.kiosk?.enable_connected_kiosks}
              onChange={(e) =>
                handleChange({
                  kiosk: {
                    ...timesheetSettings.kiosk,
                    enabled: !!timesheetSettings.kiosk?.enabled,
                    enable_connected_kiosks: e.target.checked,
                  },
                })
              }
            />
            <span style={{ marginLeft: 15 }}>
              <strong>Connected kiosks: </strong>
              {`Allow employees to manage their time clock from any kiosk. Note that this setting requires all kiosks to be connected to the internet.`}
            </span>
          </div>
        </div>

        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Geolocation</div>
          <div className="vertical-spacer-small"></div>
          <div className="flex" style={{ alignItems: "flex-start" }}>
            <input
              style={{ marginLeft: 10 }}
              type="checkbox"
              name="geolocation"
              checked={timesheetSettings.geolocation}
              onChange={handleToggleGeolocation}
            />
            <span style={{ marginLeft: 15 }}>
              <strong>Geolocation: </strong>
              {`Store team members' device geo-coordinates when they clock in and out. When this setting is enabled, Miter will prompt your team to enable location sharing when using the mobile app.`}
            </span>
          </div>
          {timesheetSettings.geolocation && (
            <>
              <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
                <span style={{ marginLeft: 60 }}>
                  <strong>Set the geofence radius. </strong>
                  {` Team members will be considered outside of the job site if they are located this far away from the job location: `}
                  <input
                    style={{
                      fontFamily: "Karla",
                      fontSize: "16px",
                      borderRadius: "4px",
                      border: "1px solid rgb(211, 211, 211)",
                    }}
                    type="number"
                    value={timesheetSettings.geofence_radius}
                    min={GEOFENCE_RADIUS_MIN.toString()}
                    max={GEOFENCE_RADIUS_MAX.toString()}
                    step="0.01"
                    onChange={handleChangeGeofenceRadius}
                  />
                  {` miles`}
                </span>
              </div>
              <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
                <input
                  style={{ marginLeft: 10 }}
                  type="checkbox"
                  name="disable_clock_in_out_outside_geofence"
                  checked={timesheetSettings.disable_clock_in_out_outside_geofence}
                  onChange={(e) =>
                    handleToggleGeolocationGeofencing(e, "disable_clock_in_out_outside_geofence")
                  }
                />
                <span style={{ marginLeft: 15 }}>
                  <strong>Site bound clock-in: </strong>
                  {` Prevent team members from clocking in via the mobile app if they are located outside of the job site for their selected job.`}
                </span>
              </div>
              {hasAccessToSiteBoundClockOut && (
                <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
                  <input
                    style={{ marginLeft: 10 }}
                    type="checkbox"
                    name="disable_clock_out_outside_geofence"
                    checked={timesheetSettings.disable_clock_out_outside_geofence}
                    onChange={(e) =>
                      handleToggleGeolocationGeofencing(e, "disable_clock_out_outside_geofence")
                    }
                  />
                  <span style={{ marginLeft: 15 }}>
                    <strong>Site bound clock-out: </strong>
                    {` Prevent team members from clocking out via the mobile app if they are located outside of the job site for their selected job.`}
                  </span>
                </div>
              )}
              <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
                <input
                  style={{ marginLeft: 10 }}
                  type="checkbox"
                  name="enable_geolocation_breadcrumbs"
                  checked={timesheetSettings.enable_geolocation_breadcrumbs}
                  onChange={handleToggleGeolocationBreadcrumbs}
                />
                <span style={{ marginLeft: 15 }}>
                  <strong>GPS breadcrumbs: </strong>
                  {` Track team members' geolocation throughout the duration of the timesheet.`}
                </span>
              </div>
            </>
          )}
          {timesheetSettings.geolocation && timesheetSettings.enable_geolocation_breadcrumbs && (
            <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
              <input
                style={{ marginLeft: 10 }}
                type="checkbox"
                name="enable_geolocation_mileage"
                checked={timesheetSettings.enable_geolocation_mileage}
                onChange={(e) => handleChange({ enable_geolocation_mileage: e.target.checked })}
              />
              <span style={{ marginLeft: 15 }}>
                <strong>Mileage tracking: </strong>
                {`Keep track of the distance traveled by a team member based on their GPS breadcrumbs`}
              </span>
            </div>
          )}
        </div>

        <>
          <div className="billing-card-wrapper">
            <div className="flex">
              <div style={{ fontWeight: 600, fontSize: 18 }}>Mobile manual time input</div>
              <InfoButton text="This settings determines how team members can manually input their time from the mobile app." />
            </div>

            <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
              <div className="vertical-spacer-small"></div>
              <div className="flex">
                <input
                  type="radio"
                  style={{ margin: "0px 12px" }}
                  onChange={() => handleChange({ mobile_time_input: "clock_out" })}
                  checked={timesheetSettings.mobile_time_input === "clock_out"}
                />
                <label>
                  <span className="bold">Clock in/out</span>: Team members enter the clock in time, clock out
                  time, and unpaid break time.
                </label>
              </div>
              <div className="flex" style={{ marginTop: 7 }}>
                <input
                  type="radio"
                  style={{ margin: "0px 12px" }}
                  onChange={() => handleChange({ mobile_time_input: "duration" })}
                  checked={timesheetSettings.mobile_time_input === "duration"}
                />
                <label>
                  <span className="bold">Duration</span>: Team members enter the number of hours (duration)
                  they worked.
                </label>
              </div>
            </div>
          </div>
          <NonSupervisorEditing
            updateTimesheetSettings={handleChange}
            timesheetSettings={timesheetSettings}
          />

          <div className="billing-card-wrapper">
            <div className="flex">
              <div style={{ fontWeight: 600, fontSize: 18 }}>Mobile timesheet creation</div>
              <InfoButton text="This setting allows you specify how team members can create timesheets in the mobile app." />
            </div>

            <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
              <div className="vertical-spacer-small"></div>

              <div className="flex" style={{ marginBottom: 10 }}>
                Prevent team members in the following departments from creating timesheets in the mobile app
                (in non-kiosk mode).
              </div>

              <Formblock
                type="multiselect"
                name="disable_timesheet_creation_by_department"
                form={form}
                editing={true}
                className="modal wizard"
                placeholder={"Select departments..."}
                options={departmentOptions}
                onChange={(e) => {
                  handleChange({
                    disable_all_mobile_app_timesheet_creation: e?.map((department) => department.value) || [],
                  });
                }}
                height="unset"
                defaultValue={timesheetSettings.disable_all_mobile_app_timesheet_creation}
                style={{ width: "90%" }}
              />

              <div className="flex" style={{ marginTop: 5 }}>
                <input
                  style={{ marginLeft: 10 }}
                  type="checkbox"
                  checked={timesheetSettings.disable_team_member_manual_timesheet_creation}
                  onChange={(e) => {
                    handleChange({
                      disable_team_member_manual_timesheet_creation: e.target.checked,
                    });
                  }}
                />
                <span style={{ marginLeft: 10 }}>
                  Only allow supervisors, superintendents, and crew leads to manually create timesheets in the
                  mobile app
                </span>
              </div>
            </div>
          </div>

          <SettingsCard
            title="Mobile supervisors timesheet view"
            info={"Set how supervisors review timesheets on the mobile app."}
          >
            <div className="flex" style={{ marginTop: 5 }}>
              <input
                style={{ marginLeft: 10 }}
                type="checkbox"
                checked={timesheetSettings.timesheets_grouped_by_tm_for_supervisors}
                onChange={(e) => {
                  handleChange({
                    timesheets_grouped_by_tm_for_supervisors: e.target.checked,
                  });
                }}
              />
              <span style={{ marginLeft: 10 }}>
                Present timesheets grouped by team member for supervisors reviewing hours on the mobile app.
              </span>
            </div>
          </SettingsCard>

          <div className="billing-card-wrapper">
            <div className="flex">
              <div style={{ fontWeight: 600, fontSize: 18 }}>Timesheets table</div>
              <InfoButton text="This setting allows you to sort by first and last name" />
            </div>

            <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
              <div className="vertical-spacer-small"></div>
              <div className="flex">
                <input
                  type="radio"
                  style={{ margin: "0px", marginRight: 12 }}
                  onChange={() => handleChange({ break_name_into_first_and_last: true })}
                  checked={timesheetSettings.break_name_into_first_and_last}
                />
                <label>
                  <span className="bold">First and last name columns</span>: Separate the first/last name of
                  the team member into two columns in the table.
                </label>
              </div>
              <div className="flex" style={{ marginTop: 7 }}>
                <input
                  type="radio"
                  style={{ margin: "0px", marginRight: 12 }}
                  onChange={() => handleChange({ break_name_into_first_and_last: false })}
                  checked={!timesheetSettings.break_name_into_first_and_last}
                />
                <label>
                  <span className="bold">Full name column</span>: Show the full name of the team member as one
                  column in the table.
                </label>
              </div>
            </div>
            <div className="vertical-spacer"></div>
            <div className="flex">
              <input
                style={{ marginLeft: 0 }}
                type="checkbox"
                checked={timesheetSettings.show_timezone_selector}
                onChange={(e) => handleChange({ show_timezone_selector: e.target.checked })}
              />
              <span style={{ marginLeft: 12 }}>
                Show a timezone selector in the timesheets table to allow you to view timesheets in different
                timezones.
              </span>
            </div>
            <div className="vertical-spacer-small"></div>
            <div className="flex">
              <input
                style={{ marginLeft: 0 }}
                type="checkbox"
                checked={timesheetSettings.expand_table_groups_on_select}
                onChange={(e) => handleChange({ expand_table_groups_on_select: e.target.checked })}
              />
              <span style={{ marginLeft: 12 }}>
                Expand grouped rows in the timesheets table when you select them
              </span>
            </div>
            <div className="vertical-spacer-small"></div>
            <div className="flex">
              <input
                style={{ marginLeft: 0 }}
                type="checkbox"
                checked={timesheetSettings.table_select_all_selects_loaded_rows}
                onChange={(e) => handleChange({ table_select_all_selects_loaded_rows: e.target.checked })}
              />
              <span style={{ marginLeft: 12 }}>
                When selecting all rows in the timesheets table, only select the rows that have been loaded in
              </span>
            </div>
          </div>
          <SettingsCard
            title="Timesheets Pay Period Editor"
            info={"Configure the timesheets pay period editor"}
          >
            <Formblock
              type="multiselect"
              name="default_timesheet_pay_period_editor_columns"
              form={form}
              editing={true}
              label="Select the default columns to display in the timesheets pay period view"
              className="modal wizard"
              placeholder={"Select columns"}
              options={PAY_PERIOD_EDITOR_COLUMN_OPTIONS}
              onChange={(e) => {
                handleChange({
                  default_timesheet_pay_period_editor_columns: e?.map((col) => col.value) || [],
                });
              }}
              height="unset"
              defaultValue={timesheetSettings.default_timesheet_pay_period_editor_columns}
              style={{ width: "90%" }}
            />
          </SettingsCard>
          {hasAccessToBulkEntryPayRates && (
            <SettingsCard
              title="Timesheets Bulk Creation"
              info={"Configure the timesheets bulk creation settings"}
            >
              <Formblock
                type="checkbox"
                name="show_pay_rate_in_bulk_create"
                text="Show pay rate in the timesheets bulk creation view"
                onChange={(e) => handleChange({ show_pay_rate_in_bulk_create: e.target.checked })}
                checked={timesheetSettings.show_pay_rate_in_bulk_create}
                editing={true}
                style={{ zIndex: 2, width: "50%" }}
              />
            </SettingsCard>
          )}
          <div className="billing-card-wrapper">
            <div className="flex">
              <div style={{ fontWeight: 600, fontSize: 18 }}>Default clock-in time</div>
              <InfoButton text="Control the default clock-in time within the dashboard (not the mobile app)" />
            </div>

            <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
              <div className="vertical-spacer-small"></div>

              <div className="flex">
                <span>
                  The default clock-in time when creating timesheets in the admin dashboard should
                  be:&nbsp;&nbsp;
                </span>
                <div style={{ width: 120 }}>
                  <Input
                    type="time"
                    defaultValue={timesheetSettings.default_clock_in_time || "09:00"}
                    onChange={(e) => handleChange({ default_clock_in_time: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <SettingsCard
            title="Clock out time"
            info="Choose when to record the clock out time for timesheets created via clock in."
          >
            <Formblock
              type="radio"
              name="record_clock_out_time_as"
              form={form}
              editing={true}
              label="Choose when the clock out time should be recorded."
              className="modal"
              options={[
                {
                  label: (
                    <div>
                      <b>Before filling clock out form: </b>the time at which team members initially click the
                      clock out button
                    </div>
                  ),
                  value: "before_clock_out_form",
                },
                {
                  label: (
                    <div>
                      <b>After filling clock out form: </b>the time at which the timesheet is saved
                    </div>
                  ),
                  value: "after_clock_out_form",
                },
              ]}
              onChange={(e) => {
                handleChange({ record_clock_out_time_as: e?.target?.value });
              }}
              height="unset"
              defaultValue={timesheetSettings.record_clock_out_time_as || "before_clock_out_form"}
              style={{ width: "90%" }}
            />
          </SettingsCard>
          <SettingsCard
            title="Timesheet rounding"
            info="Round clock in and clock out times when team members clock in via kiosk or the mobile app. This setting also rounds break times that overlap with the rounded clock in and out times for the timesheet."
          >
            {(!timesheetSettings.clock_times_rounding_interval ||
              timesheetSettings.clock_times_rounding_interval !== "none") && (
              <Banner
                type={"warning"}
                content={
                  "Warning: Turning rounding on will automatically edit clock in and clock out times for team members! Check your state and local laws to make sure time rounding is allowed."
                }
                style={{ width: "90%", marginBottom: 18 }}
              />
            )}
            <Formblock
              type="select"
              name="clock_times_rounding_interval"
              form={form}
              editing={true}
              label="Round clock in and clock out times to the nearest: "
              className="modal"
              options={[
                { label: "Off", value: "none" },
                { label: "1 minute", value: "1_min" },
                { label: "5 minutes", value: "5_min" },
                { label: "10 minutes", value: "10_min" },
                { label: "15 minutes", value: "15_min" },
                { label: "30 minutes", value: "30_min" },
                { label: "1 hour", value: "1_hour" },
              ]}
              onChange={(e) => {
                handleChange({ clock_times_rounding_interval: e?.value });
              }}
              height="unset"
              defaultValue={timesheetSettings.clock_times_rounding_interval || "none"}
              style={{ width: "90%" }}
            />
            {timesheetSettings.clock_times_rounding_interval &&
              timesheetSettings.clock_times_rounding_interval !== "none" && (
                <Formblock
                  type="select"
                  name="clock_times_rounding_direction"
                  form={form}
                  editing={true}
                  label="Rounding direction to determine clock in and clock out times: "
                  className="modal"
                  placeholder={"Select interval"}
                  options={[
                    { label: "Round to nearest " + roundingIntervalCopy, value: "half" },
                    { label: "Round up", value: "up" },
                    { label: "Round down", value: "down" },
                  ]}
                  onChange={(e) => {
                    handleChange({ clock_times_rounding_direction: e?.value });
                  }}
                  height="unset"
                  defaultValue={timesheetSettings.clock_times_rounding_direction}
                  style={{ width: "90%" }}
                />
              )}
          </SettingsCard>
          <div className="billing-card-wrapper">
            <div className="flex">
              <div style={{ fontWeight: 600, fontSize: 18 }}>Notifications</div>
              <InfoButton text="Manage notifications related to timesheets" />
            </div>

            <div className="vertical-spacer-small"></div>
            <div className="flex">
              <input
                style={{ marginLeft: 10 }}
                type="checkbox"
                checked={timesheetSettings.send_daily_unapproved_timesheet_notifications_to_supervisors}
                onChange={(e) => {
                  handleChange({
                    send_daily_unapproved_timesheet_notifications_to_supervisors: e.target.checked,
                  });
                }}
              />
              <span style={{ marginLeft: 15 }}>
                Send unapproved timesheet reminders via SMS to job supervisors every weekday at:
              </span>
              <div style={{ width: 250, marginLeft: 5 }}>
                <DateTimePicker
                  onChange={(date) => {
                    const formattedDate = date.set({ minute: 0, second: 0, millisecond: 0 });
                    handleChange({
                      supervisor_notification_hour: formattedDate.hour,
                    });
                  }}
                  timeOnly={true}
                  timePlaceholder={"Send at"}
                  timeIntervals={60}
                  value={DateTime.now().set({
                    hour: timesheetSettings.supervisor_notification_hour || 17,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })}
                />
              </div>
            </div>
            {timesheetSettings.kiosk?.enable_connected_kiosks && (
              <div>
                <div className="vertical-spacer-small"></div>
                <span>{` The following settings only apply to timesheets created via connected kiosk.`}</span>
                <div className="vertical-spacer-small"></div>
                <div className="flex">
                  <Formblock
                    type="checkbox"
                    name="send_clock_out_reminders"
                    text="Send SMS reminders to clock out if team members have been clocked in for longer than"
                    defaultValue={timesheetSettings.send_clock_out_reminders}
                    onChange={(e) => {
                      handleChange({
                        send_clock_out_reminders: e.target.checked,
                      });
                    }}
                    checked={timesheetSettings.send_clock_out_reminders}
                    editing={true}
                    style={{ marginLeft: "9px" }}
                  />
                  <Formblock
                    type="number"
                    name="clock_out_reminder_time"
                    defaultValue={timesheetSettings.clock_out_reminder_time}
                    onChange={(e) => handleChange({ clock_out_reminder_time: Number(e.target.value) })}
                    editing={true}
                    style={{ width: 50, marginLeft: 10, marginRight: 10 }}
                  />
                  <span style={{ marginBottom: "7px", color: "#2E2E2E" }}>{` hours.`}</span>
                </div>
                <div className="flex">
                  <Formblock
                    type="checkbox"
                    name="send_clock_out_reminders"
                    text="Send SMS reminders to end break if team members have been on break for longer than"
                    defaultValue={timesheetSettings.send_end_break_reminders}
                    onChange={(e) => {
                      handleChange({
                        send_end_break_reminders: e.target.checked,
                      });
                    }}
                    checked={timesheetSettings.send_end_break_reminders}
                    editing={true}
                    style={{ marginLeft: "9px" }}
                  />
                  <Formblock
                    type="select"
                    name="clock_out_reminder_time"
                    options={[
                      { label: "5 minutes", value: 5 },
                      { label: "10 minutes", value: 10 },
                      { label: "15 minutes", value: 15 },
                      { label: "20 minutes", value: 20 },
                      { label: "30 minutes", value: 30 },
                      { label: "45 minutes", value: 45 },
                      { label: "1 hour", value: 60 },
                    ]}
                    defaultValue={timesheetSettings.end_break_reminder_time || null}
                    onChange={(e) => handleChange({ end_break_reminder_time: e.value })}
                    editing={true}
                    style={{ width: 150, marginLeft: 10, marginRight: 10 }}
                    requiredSelect={false}
                  />
                  <span style={{ marginBottom: "7px", color: "#2E2E2E" }}>{`.`}</span>
                </div>
              </div>
            )}
          </div>
          <SettingsCard title="Earning types" info={"Manage timesheet earning types settings"}>
            <Formblock
              type="checkbox"
              name="always_display_earning_types"
              text="Always display earning types on timesheets"
              onChange={(e) => handleChange({ always_display_earning_types: e.target.checked })}
              checked={timesheetSettings.always_display_earning_types}
              editing={true}
              style={{ zIndex: 2, width: "50%" }}
              labelInfo="If enabled, we will always display earning types on the timesheet modal."
            />
            <Formblock
              type="checkbox"
              name=""
              text="Allow team members to update earning type from the mobile app"
              onChange={(e) => handleChange({ edit_earning_type_from_mobile: e.target.checked })}
              checked={timesheetSettings.edit_earning_type_from_mobile}
              editing={true}
            />
          </SettingsCard>
          {canReadWcCode && (
            <SettingsCard
              title="Workers' compensation code"
              info={"Manage timesheet workers' comp code settings"}
            >
              <Formblock
                type="checkbox"
                name=""
                text="Allow users with permissions to view workers' compensation codes to also add or update workers' comp codes on timesheets."
                onChange={(e) => handleChange({ enable_admin_wc_code: e.target.checked })}
                checked={timesheetSettings.enable_admin_wc_code}
                editing={true}
              />
            </SettingsCard>
          )}
          <div className="billing-card-wrapper">
            <div style={{ fontWeight: 600, fontSize: 18 }}>Bulk import</div>

            <div className="vertical-spacer-small"></div>
            <div className="flex">
              <input
                style={{ marginLeft: 10 }}
                type="checkbox"
                checked={timesheetSettings.bulk_import?.require_all_timesheets_to_be_successful}
                onChange={(e) => {
                  handleChange({
                    bulk_import: { require_all_timesheets_to_be_successful: e.target.checked },
                  });
                }}
              />
              <span style={{ marginLeft: 15 }}>
                Require all timesheets to be successful for the bulk import to be successful.
              </span>
            </div>
          </div>

          {!hasPoliciesEnabled && (
            <SettingsCard
              title="Mobile field requirements"
              info={"Set field requirements for timesheets submitted from the mobile app."}
            >
              <div style={{ marginTop: 10 }}>
                <TimesheetMobileFieldRequirements settings={timesheetSettings} handleChange={handleChange} />
              </div>
            </SettingsCard>
          )}
          {!hasPoliciesEnabled && (
            <SettingsCard
              title={"Dashboard field requirements"}
              info={"Set field requirements for timesheets submitted from the dashboard."}
            >
              <div style={{ marginTop: 10 }}>
                <TimesheetDashboardFieldRequirements
                  settings={timesheetSettings}
                  handleChange={handleChange}
                />
              </div>
            </SettingsCard>
          )}
          <SettingsCard
            title={
              <div className="flex">
                Timesheet policies
                <MiterGuidesIcon path="/time-tracking/timesheets/timesheet-policies-beta" marginLeft={0} />
              </div>
            }
            info={
              "Setup timesheet policies to customize who should approve a timesheet, what fields should be shown/required on a timesheet, and add sign off requirements to timesheets."
            }
          >
            {hasPoliciesEnabled && !hasTimesheetPolicies && (
              <>
                <Banner
                  type="warning"
                  content={
                    "You have turned on timesheet policies but you have not created any policies. If you don't create a default timesheet policy, your employees will not be able to create and approve timesheets as expected."
                  }
                />
                <div className="vertical-spacer-small" />
              </>
            )}

            <Formblock
              type="checkbox"
              name="enable_timesheet_policies"
              text="Enable timesheet policies"
              onChange={(e) => {
                // If there are no policies, don't allow the user to enable timesheet policies and show a warning
                if (!hasTimesheetPolicies || !hasDefaultTimesheetPolicy) {
                  Notifier.error("You must create & add default policy before enabling timesheet policies.");
                  return;
                }

                handleChange({ enable_timesheet_policies: e.target.checked });
              }}
              checked={timesheetSettings.enable_timesheet_policies}
              editing={true}
              style={{ zIndex: 2, width: "50%" }}
              labelInfo="If enabled, timesheet policies will be used instead of your current field/approval settings"
            />
            <hr style={{ border: "1px solid #eee", borderBottom: "none", marginTop: 20 }} />

            <Formblock
              type="select"
              name="default_policy_id"
              label="Default policy"
              placeholder="Select a default policy"
              options={timesheetPolicyOptions}
              onChange={(o) => handleChange({ default_policy_id: o?.value || null })}
              defaultValue={timesheetSettings.default_policy_id}
              editing={true}
              labelStyle={{ marginRight: 0 }}
              style={{ width: 350, marginBottom: -60, marginTop: 25 }}
            />

            <PoliciesTable policyType="timesheet" />
          </SettingsCard>

          <div className="billing-card-wrapper">
            <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Custom fields</div>
            <CustomFieldsTable parentType="timesheet" />
          </div>
          <OvertimeSettings />
          <AdvancedBreakTime
            timesheetSettings={timesheetSettings}
            handleChange={(newSettings) => handleChange(newSettings, true)}
          />
          <AutomaticBreakTime
            updateTimesheetSettings={handleChange}
            automaticBreakTimeSettings={timesheetSettings?.automatic_break_time || null}
            breakTypes={timesheetSettings.break_types}
            advancedBreakTime={timesheetSettings?.advanced_break_time}
          />

          {!IS_PRODUCTION && <RateDifferentials />}
          <DefaultTimesheetsView
            updateTimesheetSettings={handleChange}
            timesheetSettings={timesheetSettings}
          />
          <SettingsCard title="Live timesheets">
            <Formblock
              type="checkbox"
              name="show_all_team_members_in_live_view"
              text="Show all team members in the live timesheets table"
              onChange={(e) => handleChange({ show_all_team_members_in_live_view: e.target.checked })}
              checked={timesheetSettings.show_all_team_members_in_live_view}
              editing={true}
              style={{ zIndex: 2, width: "50%" }}
              labelInfo="If enabled, you will also see not clocked in team members in the live timesheets table"
            />
          </SettingsCard>
          <SettingsCard title="Weekly timesheet reports">
            <Formblock
              type="checkbox"
              name="show_all_team_members_weekly_timesheet_reports_in_mobile_app"
              text="Show all team members weekly timesheet reports in mobile app"
              onChange={(e) =>
                handleChange({
                  show_all_team_members_weekly_timesheet_reports_in_mobile_app: e.target.checked,
                })
              }
              checked={timesheetSettings.show_all_team_members_weekly_timesheet_reports_in_mobile_app}
              editing={true}
              style={{ zIndex: 2, width: "100%" }}
              labelInfo="If enabled, all team members will be able to see weekly timesheet reports in mobile app"
            />
          </SettingsCard>
        </>
      </div>
    </div>
  );
};
