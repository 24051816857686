import React from "react";
import PayrollContext from "../payrollContext";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import { usePayrollTimesheetFilters } from "dashboard/hooks/usePayrollTimesheetFilters";

export const DraftPayrollTimesheetsTable: React.FC = () => {
  const payrollContext = React.useContext(PayrollContext);
  const { payroll, recalculatePayroll } = payrollContext;
  const filters = usePayrollTimesheetFilters(payroll, ["approved", "unapproved"]);

  return (
    <TimesheetsTable
      defaultFilters={filters}
      showToggler={true}
      togglerConfigFilter={draftPayrollTimesheetsTogglerFilter}
      showMiniTitle={false}
      activePayroll={payroll}
      onChange={() => recalculatePayroll()}
      // doNotAddPayRates={true}
    />
  );
};

// Has to be outside of the component so it's stable
const draftPayrollTimesheetsTogglerFilter = (path: string) => {
  return path === "unapproved" || path === "approved";
};
