import { ClickAwayListener } from "@material-ui/core";
import React, { useEffect, useState, useRef, ReactElement, useMemo } from "react";

import ThreeDotMenu from "dashboard/assets/menu.png";
import useWindowDimensions from "dashboard/utils/useWindowDimensions";
import type { Property } from "csstype";

import "./actionMenu.css";
import { Loader } from "../loader";
import { DropdownItem } from "../button/Button";

export type ActionLink = {
  action: (params?: $TSFixMe) => void | Promise<void>;
  label: string;
  icon?: ReactElement;
  important?: boolean;
  secondary?: boolean;
  loading?: boolean;
  align?: "left" | "right";
  style?: React.CSSProperties;
  className?: string;
  shouldShow?: (params?: $TSFixMe) => boolean;
  disabled?: boolean;
  dropdownItems?: DropdownItem[];
};

type DropdownPosition = {
  bottom?: number;
  top?: number | string;
  right: number;
};

type Props = {
  className?: string;
  links: ActionLink[];
  position?: Property.Position;
};

const ActionMenu: React.FC<Props> = ({ className, links, position }) => {
  const { width } = useWindowDimensions();
  const inputRef = useRef<HTMLImageElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>();

  const filteredLinks = useMemo(() => {
    return links.filter((link) => {
      if (!("shouldShow" in link)) return true;
      return link.shouldShow?.();
    });
  }, [links]);

  const setNewDropdownPosition = () => {
    if (!inputRef.current) return;

    const domRect = inputRef.current!.getBoundingClientRect();
    const right = width - domRect.right;

    if (window.innerHeight - domRect.bottom < 300) {
      setDropdownPosition({
        top: domRect.top - links.length * 33,
        // bottom: domRect.y,
        right: right,
      });
    } else {
      setDropdownPosition({
        top: domRect.top + 25,
        right: right,
      });
    }
  };

  useEffect(() => {
    setNewDropdownPosition();
    window.addEventListener("scroll", setNewDropdownPosition, true);
    return () => {
      window.removeEventListener("scroll", setNewDropdownPosition, true);
    };
  }, []);

  if (filteredLinks.length === 0) return <></>;

  return (
    <div className={"action-menu-wrapper " + className}>
      <div>
        <img
          src={ThreeDotMenu}
          className={"action-menu-icon " + className}
          onClick={() => setIsOpen(true)}
          id={"image"}
          ref={inputRef}
        />
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div
            style={{
              position: position ?? "fixed",
              top: dropdownPosition?.top,
              right: dropdownPosition?.right,
              bottom: dropdownPosition?.bottom,
              zIndex: 10,
            }}
            className={"action-menu-dropdown " + className}
          >
            {filteredLinks.map((link, index) => {
              const action = () => {
                link.action();
                setIsOpen(false);
              };

              return (
                <div onClick={action} className={"action-menu-link " + className} key={index}>
                  {link.loading ? <Loader /> : link.label}
                </div>
              );
            })}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default ActionMenu;
