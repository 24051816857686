import { AggregatedExpense, MiterAPI } from "dashboard/miter";
import React, { useCallback, useEffect, useState } from "react";
import { ActionModal, Button, Formblock, Notifier, TableV2 } from "ui";
import { Option } from "ui/form/Input";
import { SplitType, useSplitCardTransactionsTableColDefs } from "../expenseUtils";
import { Plus } from "phosphor-react";
import ObjectID from "bson-objectid";
import InfoButton from "dashboard/components/information/information";
import {
  CreateSplitCardTransactionParam,
  UpdateExpenseParams,
} from "backend/services/expenses/expense-service";
import { BulkUpdateParams } from "backend/utils";
import { ExpenseTypeEnum } from "backend/models/expense";
import { useActiveCompanyId, useLookupActivity, useLookupCostType } from "dashboard/hooks/atom-hooks";
import { roundTo } from "miter-utils";
import Banner from "dashboard/components/shared/Banner";
import pluralize from "pluralize";

const MAX_SPLITS = 5;
const LOCAL_EXPENSE_ID_IDENTIFIER = "TEMP_";

type Props = {
  parentId: string;
  onHide: () => void;
  onSubmit: () => void;
};

// type of the local split. not using camelCase for easier comparison to AggregatedExpense.
export type SplitCardTransactionTableRow = {
  _id: string;
  amount: number;
  percent: number;
  type: ExpenseTypeEnum;
  team_member_id?: string;
  department_id?: string;
  job_id?: string;
  activity_id?: string;
  cost_type_id?: string;
  expense_account_id?: string;
};

const splitTypeOptions: Option<string>[] = [
  {
    label: "Amount",
    value: "amount",
  },
  {
    label: "% of Total",
    value: "pct",
  },
];

const areTwoSplitsTheSame = (split1: SplitCardTransactionTableRow, split2: SplitCardTransactionTableRow) => {
  for (const [key, value] of Object.entries(split1)) {
    // ignore percent because it doesn't exist on AggregatedExpense
    if (split2![key] !== value) {
      return false;
    }
  }

  return true;
};

export const SplitCardTransactionModal: React.FC<Props> = ({ parentId, onHide, onSubmit }) => {
  const activeCompanyId = useActiveCompanyId();
  const lookupCostType = useLookupCostType();
  const lookupActivity = useLookupActivity();

  // STATE
  const [splitType, setSplitType] = useState<SplitType>("amount");
  const [splits, setSplits] = useState<SplitCardTransactionTableRow[]>([]);
  const [expenses, setExpenses] = useState<AggregatedExpense[]>([]);
  const [existingSplitCardTransactionIdsToDelete, setExistingSplitExenseIdsToDelete] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [areAmountsValid, setAreAmountsValid] = useState<boolean>(true);

  const totalAmount = roundTo(expenses.reduce((acc, expense) => acc + expense.amount, 0));
  const isTotalAmountEndingInOddNumber = Number(totalAmount.toFixed(2).slice(-1)) % 2 === 1;

  const setSplitsAndValidateAmount = (newSplits: SplitCardTransactionTableRow[]) => {
    setSplits(newSplits);
    const newTotalAmount = roundTo(
      newSplits.reduce((acc, split) => acc + split.amount, 0),
      2
    );

    setAreAmountsValid(newTotalAmount === totalAmount && newSplits.every((split) => split.amount > 0));
  };

  // initialize
  useEffect(() => {
    getData();
  }, []);

  // convert existing AggregatedExpenses to SplitCardTransactionTableRow
  useEffect(() => {
    const tableRows: SplitCardTransactionTableRow[] = expenses.map((expense) => {
      const {
        _id,
        amount,
        team_member_id,
        department_id,
        job_id,
        activity_id,
        cost_type_id,
        expense_account_id,
        type,
      } = expense;

      return {
        _id,
        amount,
        percent: roundTo((amount / totalAmount) * 100, 2),
        team_member_id,
        department_id,
        job_id,
        activity_id,
        cost_type_id,
        expense_account_id,
        type,
      };
    });

    setSplitsAndValidateAmount(tableRows);
  }, [expenses]);

  // HANDLERS
  const getData = async () => {
    const data = await MiterAPI.expenses.forage({
      filter: [
        {
          type: "or",
          value: [
            { field: "parent_id", value: parentId },
            { field: "_id", value: parentId, type: "_id" },
          ],
        },
        { field: "company_id", value: activeCompanyId },
      ],
    });

    setExpenses(data.data);
    setExistingSplitExenseIdsToDelete([]);
  };

  const handleNewSplit = () => {
    const mostRecentSplit = splits[splits.length - 1];
    if (!mostRecentSplit) {
      console.error(
        `Error splitting expense - no existing splits found. Invariant splits.length > 0 failed while user was splitting parent_id ${parentId}`
      );
      Notifier.error(`Error splitting expense - existing one not found.`);
    } else {
      let idToUse = LOCAL_EXPENSE_ID_IDENTIFIER + ObjectID().toHexString();

      // if there is an existing split to be deleted, but user changes their mind and re-splits, grab previous _id to directly update it instead. Basically pretends nothing was deleted.
      if (existingSplitCardTransactionIdsToDelete.length > 0) {
        idToUse =
          existingSplitCardTransactionIdsToDelete[existingSplitCardTransactionIdsToDelete.length - 1]!;

        // set state to everything except last item
        setExistingSplitExenseIdsToDelete(existingSplitCardTransactionIdsToDelete.slice(0, -1));
      }

      // deep clone most recent split, but remove _id
      const newSplit = {
        ...mostRecentSplit,
        _id: idToUse,
        amount: 0,
        percent: 0,
      };

      let newSplits: SplitCardTransactionTableRow[] = [];
      // if there was only one split before, set new split to half of amount and 50%, then update previous split
      if (splits.length === 1) {
        const currentSingleSplit = splits[0]!;
        const halfAmount = roundTo(totalAmount / 2, 2);

        newSplit.amount = halfAmount;
        newSplit.percent = 50;

        currentSingleSplit.amount = halfAmount;
        currentSingleSplit.percent = 50;

        if (isTotalAmountEndingInOddNumber) {
          newSplit.amount = halfAmount - 0.01;
        }
        newSplits = [currentSingleSplit, newSplit];
      } else {
        newSplits = splits.concat([newSplit]);
      }

      setSplitsAndValidateAmount(newSplits);
    }
  };

  const handleDeleteSplit = useCallback(
    (splitId: string) => {
      // if this isn't a local split, we need to cache this ID so backend service can archive the existing expenses
      if (splitId.includes(LOCAL_EXPENSE_ID_IDENTIFIER) === false) {
        setExistingSplitExenseIdsToDelete(existingSplitCardTransactionIdsToDelete.concat(splitId));
      }

      const newSplits = splits.filter((split) => split._id !== splitId);

      // if only one split left, set amount and percent back to totals
      if (newSplits.length === 1) {
        newSplits[0]!.amount = totalAmount;
        newSplits[0]!.percent = 100;
      }

      setSplitsAndValidateAmount(newSplits);
    },
    [splits, existingSplitCardTransactionIdsToDelete]
  );

  // AgGrid actually directly mutates splits, which is super screwed up - it bypasses the state in this component
  const handleRowUpdate = useCallback(
    async (updatedRows: SplitCardTransactionTableRow[]) => {
      const newSplits = splits.map((existingRow) => {
        const updatedRow = updatedRows.find((updatedRow) => updatedRow._id === existingRow._id);

        // there's a bug with AGGrid where updatedRows also includes previous updates. check if a row is actually updated by comparing all values
        const isNotUpdated = !updatedRow || areTwoSplitsTheSame(existingRow, updatedRow);

        // row hasn't been updated, return existing
        if (isNotUpdated) {
          // if there are only two splits, automatically update
          if (isNotUpdated && splits.length === 2) {
            // get other row
            const actuallyUpdatedRow = updatedRows.find((updatedRow) => updatedRow._id !== existingRow._id)!;
            if (actuallyUpdatedRow) {
              const { amount: amountFromOtherRow, percent: percentFromOtherRow } = actuallyUpdatedRow;

              if (splitType === "amount") {
                const inverseAmount = totalAmount - amountFromOtherRow;
                const syncedPercent = (inverseAmount / totalAmount) * 100;

                existingRow.percent = roundTo(syncedPercent, 2);
                existingRow.amount = inverseAmount;
              } else {
                const inversePercent = 100 - percentFromOtherRow;
                const syncedAmount = (inversePercent / 100) * totalAmount;
                existingRow.amount = roundTo(syncedAmount, 2);
                existingRow.percent = inversePercent;
              }
            }
          }

          return existingRow;
        } else {
          // sync amount and percent
          const { amount, percent } = updatedRow;

          // if currently changing amount, sync percent
          if (splitType === "amount") {
            const syncedPercent = (amount / totalAmount) * 100;
            updatedRow.percent = roundTo(syncedPercent, 2);
          } else {
            // if currently changing percent, sync amount
            const syncedAmount = (percent / 100) * totalAmount;
            updatedRow.amount = roundTo(syncedAmount, 2);
          }

          // if activity is updated, also update cost type
          if (updatedRow.activity_id !== existingRow.activity_id) {
            const newActivity = lookupActivity(updatedRow.activity_id);
            const existingActivity = lookupActivity(existingRow.activity_id);

            if (newActivity?.default_cost_type_id) {
              updatedRow.cost_type_id = newActivity.default_cost_type_id;
            } else if (
              existingRow.cost_type_id &&
              existingRow.cost_type_id === existingActivity?.default_cost_type_id
            ) {
              // if unsetting or changing activity and the current cost type is set by the activity, unset the cost type
              updatedRow.cost_type_id = undefined;
            }
          }

          // if cost type is updated, also update expense account
          if (updatedRow.cost_type_id !== existingRow.cost_type_id) {
            const newCostType = lookupCostType(updatedRow.cost_type_id);
            const existingCostType = lookupCostType(existingRow.cost_type_id);

            if (newCostType?.ledger_account_id) {
              updatedRow.expense_account_id = newCostType.ledger_account_id;
            } else if (
              existingRow.expense_account_id &&
              existingRow.expense_account_id === existingCostType?.ledger_account_id
            ) {
              // if unsetting or changing cost type and the current ledger account is set by the cost type, unset the ledger account
              updatedRow.expense_account_id = undefined;
            }
          }

          return updatedRow;
        }
      });

      setSplitsAndValidateAmount(newSplits);
    },
    [splits]
  );

  // call backend
  const handleSave = async () => {
    // TODO: add client-side amount validation
    setIsSaving(true);
    const splitsToCreate: CreateSplitCardTransactionParam[] = splits
      .filter((split) => split._id.includes(LOCAL_EXPENSE_ID_IDENTIFIER))
      .map((split) => {
        const {
          team_member_id,
          department_id,
          job_id,
          activity_id,
          cost_type_id,
          expense_account_id,
          amount,
        } = split;

        return {
          parent_id: parentId,
          parent_original_amount: totalAmount,
          amount,
          team_member_id,
          department_id,
          job_id,
          activity_id,
          cost_type_id,
          expense_account_id,
        };
      });

    const splitsToUpdate = splits.filter((split) => {
      const isExisting = split._id.includes(LOCAL_EXPENSE_ID_IDENTIFIER) === false;
      if (isExisting === false) return false;

      // compare split to corresponding existing expense. If any field is updated, return
      const correspondingExistingExpense = expenses.find((expense) => expense._id === split._id);

      for (const [key, value] of Object.entries(split)) {
        // ignore percent because it doesn't exist on AggregatedExpense
        if (key !== "percent" && correspondingExistingExpense![key] !== value) {
          return true;
        }
      }

      return false;
    });

    const existingSplits: BulkUpdateParams<UpdateExpenseParams> = splitsToUpdate.map((split) => {
      const {
        _id,
        team_member_id,
        department_id,
        job_id,
        activity_id,
        cost_type_id,
        expense_account_id,
        amount,
      } = split;

      const updateParams: UpdateExpenseParams = {
        parent_id: parentId,
        parent_original_amount: totalAmount,
        amount,
        team_member_id,
        department_id,
        job_id,
        activity_id,
        cost_type_id,
        expense_account_id,
      };
      return {
        _id,
        params: updateParams,
      };
    });

    const deletedSplits: BulkUpdateParams<UpdateExpenseParams> = existingSplitCardTransactionIdsToDelete.map(
      (deletedSplit: string) => {
        return {
          _id: deletedSplit,
          params: {
            archived: true,
          },
        };
      }
    );
    const allExistingExpensesToUpdate = existingSplits.concat(deletedSplits);

    if (allExistingExpensesToUpdate.length === 0 && splitsToCreate.length === 0) {
      Notifier.info(`No changes to save.`);
      setIsSaving(false);
      return;
    }

    const result = await MiterAPI.expenses.split(allExistingExpensesToUpdate, splitsToCreate);
    if (result.error) {
      Notifier.error(result.error);
    } else {
      const numberUpdated = result.updated.successes.length;
      const numberCreated = result.created.successes.length;

      const numberUpdateErrors = result.updated.errors.length;
      const numberCreateErrors = result.created.errors.length;

      if (numberUpdated || numberCreated) {
        Notifier.success(
          `Updated ${numberUpdated} existing ${pluralize(
            "card transaction",
            numberUpdated
          )} and created ${numberCreated} new ${pluralize("card transaction", numberCreated)}.`
        );
      }

      if (numberUpdateErrors > 0) {
        Notifier.error(
          `Error updating ${numberUpdateErrors} ${pluralize("card transaction", numberUpdateErrors)}.`
        );
      }

      if (numberCreateErrors > 0) {
        Notifier.error(
          `Error creating ${numberCreateErrors} ${pluralize("card transaction", numberCreateErrors)}.`
        );
      }
    }

    getData();
    onSubmit();
    onHide();
    setIsSaving(false);
  };

  // HOOKS
  // need to initialize state + handleDeleteSplit handler to pass into this hook
  const columns = useSplitCardTransactionsTableColDefs({ handleDeleteSplit, splitType });

  return (
    <ActionModal
      headerText="Split card transaction"
      onHide={onHide}
      cancelText="Cancel"
      onCancel={onHide}
      submitText="Save"
      onSubmit={handleSave}
      showSubmit={true}
      submitDisabled={!areAmountsValid}
      showCancel={true}
      loading={expenses.length === 0 || isSaving}
      wrapperStyle={{ minWidth: "1400px" }}
      bodyStyle={{ paddingTop: 0 }}
    >
      {expenses.length > 0 && !areAmountsValid && (
        <Banner
          type="error"
          style={{ borderBottom: "1px solid #d6d6d6", marginBottom: 30, marginRight: -30, marginLeft: -30 }}
        >
          Amounts must be greater than 0 and sum to the original total of ${totalAmount}.
        </Banner>
      )}
      <>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 25 }}>
          <div style={{ width: 250 }}>
            <Formblock
              label="Split By: "
              type="select"
              onChange={(selectedOption: Option<SplitType>) => setSplitType(selectedOption.value)}
              editing={true}
              options={splitTypeOptions}
              value={splitTypeOptions.find((o) => o.value === splitType)}
            />
          </div>
          <Button className={"button-1"} onClick={handleNewSplit} disabled={splits.length > MAX_SPLITS - 1}>
            <Plus weight="bold" style={{ marginRight: 3 }} />
            Split
            <InfoButton text={`Max ${MAX_SPLITS} splits per card transaction.`} />
          </Button>
        </div>
        {/* TODO: add styling to fix dropdown rendering issue */}
        <div style={{}}>
          <TableV2
            id={"split-card-transactions-table"}
            resource="Split card transactions"
            data={splits}
            columns={columns}
            alwaysEditable
            editable
            hideSecondaryActions
            hideRowEditingStatus
            hideSearch
            hideFilters
            hideFooter
            hideSelectColumn
            disableSort
            disablePagination
            onClick={() => {}}
            onSave={handleRowUpdate}
            autoSave
            isLoading={expenses.length === 0}
          />
        </div>
        <div
          className="margin-bottom-25"
          style={{ display: "grid", gridTemplate: "1fr / 1fr", justifyItems: "end", gap: "10px" }}
        >
          <div>
            Current amount (must equal original to save): $
            {roundTo(
              splits.reduce((acc, split) => acc + split.amount, 0),
              2
            )}
          </div>
          <div>Original amount: ${totalAmount}</div>
        </div>
      </>
    </ActionModal>
  );
};
