import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import AppContext from "dashboard/contexts/app-context";
import {
  AggregatedPayroll,
  EnrichedLedgerEntry,
  EnrichedLedgerLineItem,
  MiterAPI,
  MiterFilterField,
} from "dashboard/miter";
import { Notifier, downloadCsvFromBlob } from "dashboard/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ColDef, GridApi, ValueFormatterParams } from "ag-grid-community";
import { toDollarFormat } from "dashboard/pages/reports/reportUtils";
import { capitalize } from "lodash";
import { Button } from "ui";
import { PostEntriesModal } from "./PostEntriesModal";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useSageIntacctCsv } from "dashboard/utils/useSageIntacctCsv";
import { GranularityLevel, LedgerGranularityConfig } from "backend/utils/accounting";
import { ExportWarningsModal } from "dashboard/components/shared/ExportWarnings";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useSage100PayrollExports } from "dashboard/utils/useSage100PayrollExports";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { useSage300LedgerEntriesCsv } from "dashboard/utils/useSage300LedgerEntriesCsv";

type LineItemRow = EnrichedLedgerLineItem & {
  debit: number | undefined;
  credit: number | undefined;
  accountTypeFormatted: string;
  isVoid: string;
};

type Props = {
  ledgerEntryId?: string;
  getLedgerEntries?: () => Promise<void>;
  filterArray?: MiterFilterField[];
  draftPayrollPreview?: AggregatedPayroll;
  gridHeight?: string;
};

export const LedgerEntry: React.FC<Props> = ({
  ledgerEntryId,
  filterArray,
  getLedgerEntries,
  draftPayrollPreview,
  gridHeight,
}) => {
  const { integrations } = React.useContext(AppContext);
  const activeCompany = useActiveCompany();
  const { can, cannot } = useMiterAbilities();
  const activeCompanyId = activeCompany?._id || null;
  const { payroll } = useContext(PayrollContext);
  const payrollIsDraft = payroll?.check_payroll.status === "draft";

  const granularity = useMemo(() => {
    const raw = (activeCompany?.settings.ledger_entries?.granularity || {}) as LedgerGranularityConfig;
    const clean = new Set<GranularityLevel>();
    for (const levels of Object.values(raw)) {
      for (const level of levels) {
        clean.add(level);
      }
    }
    return clean;
  }, [activeCompany]);

  const [jonasIcId, sage300IcId, intacctIcId, sage100IcId] = useMemo(() => {
    let jonas: string | undefined,
      sage300: string | undefined,
      intacct: string | undefined,
      sage100: string | undefined;
    for (const i of integrations) {
      const c = i.connection;
      if (!c?.metadata || c.pending_setup) continue;
      if (i.key === "jonas") {
        jonas = i.connection?._id;
      } else if (i.key === "sage_300") {
        sage300 = i.connection?._id;
      } else if (i.key === "sage_intacct") {
        intacct = i.connection?._id;
      } else if (i.key === "sage_100") {
        sage100 = i.connection?._id;
      }
    }
    return [jonas, sage300, intacct, sage100];
  }, [integrations]);

  const [gridApi, setGridApi] = useState<GridApi<LineItemRow>>();
  const [ledgerEntry, setLedgerEntry] = useState<EnrichedLedgerEntry>();
  const [data, setData] = useState<LineItemRow[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [showPostEntriesModal, setShowPostEntriesModal] = useState(false);
  const [trigger, setTrigger] = useState(true);

  const sage300Jcd = useSage300LedgerEntriesCsv(sage300IcId);
  const intacctCsv = useSageIntacctCsv(intacctIcId);
  const sage100Exports = useSage100PayrollExports(activeCompanyId, sage100IcId);

  const regenerateEntry = async () => {
    if (!activeCompanyId || !ledgerEntry || cannot("accounting:ledger_entries:generate")) return;

    gridApi?.showLoadingOverlay();
    try {
      const response = await MiterAPI.ledger_entries.refresh(activeCompanyId, [ledgerEntry._id]);
      if (response.error) throw new Error(response.error);
      if (response.failures.length > 0) {
        throw new Error(response.failures[0]?.message);
      }
      Notifier.success("Ledger entry refreshed successfully.");
      setTrigger((prev) => !prev); // This is how we automatically refresh the data if they regenerate!
      getLedgerEntries?.();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error refreshing the ledger entry.");
      gridApi?.hideOverlay(); // do this only in the catch block since in the happy path, the getData function is about to call showLoadingOverlay
    }
  };

  useEffect(() => {
    let abort = false;
    const getData = async () => {
      if (!activeCompany?._id || !gridApi) return;
      if (!filterArray && !ledgerEntryId) return;
      gridApi.showLoadingOverlay();
      try {
        let enLedgerEntry: EnrichedLedgerEntry | undefined;
        if (draftPayrollPreview) {
          const response = await MiterAPI.ledger_entries.get_draft_payroll_preview(draftPayrollPreview);
          if (response.error) throw new Error(response.error);
          enLedgerEntry = response;
        } else {
          const filter = ledgerEntryId ? [{ field: "_id", value: ledgerEntryId }] : filterArray;
          const response = await MiterAPI.ledger_entries.retrieve({ queryObject: { filter } });
          if (response.error) throw new Error(response.error);

          if (response.length > 1) {
            throw new Error("Response does not contain exactly one ledger entry");
          }
          enLedgerEntry = response[0];
        }

        setLedgerEntry(enLedgerEntry);

        const cleanedLines = enLedgerEntry?.line_items.map((li, k): LineItemRow => {
          const newLine: LineItemRow = {
            ...li,
            position: li.position ?? k + 1,
            debit: li.direction === "debit" ? li.amount : undefined,
            credit: li.direction === "credit" ? li.amount : undefined,
            accountTypeFormatted: capitalize(li.account_type),
            job_name: li.job_name || "-",
            activity_label: li.activity_label || "-",
            department_name: li.department_name || "-",
            location_name: li.location_name || "-",
            location_external_id: li.location_external_id || "-",
            isVoid: li.void ? "True" : "False",
          };

          return newLine;
        });

        setData(cleanedLines || []);
      } catch (e: $TSFixMe) {
        console.log(e);
        Notifier.error("Error getting your ledger entry: " + e.message);
      }
      // Running into an issue where there's an uncaught exception (at the AgGrid library level) if you close the modal before the entry has fully loaded
      if (!abort) gridApi.hideOverlay();
    };
    getData();

    return () => {
      abort = true;
    };
  }, [!!activeCompany?._id, !!gridApi, ledgerEntryId, trigger, draftPayrollPreview, filterArray]);

  const columnDefs = useMemo(() => {
    const cols = sharedColDefs.slice();
    if (granularity.has("department") || data.some((i) => i.department_id)) {
      cols.push(...deptColDefs);
    }
    if (granularity.has("location") || data.some((i) => i.location_id)) {
      cols.push(...locationColDefs);
    }
    if (granularity.has("team_member") || data.some((i) => i.team_member_id)) {
      cols.push(...tmColDefs);
    }
    if (granularity.has("job") || data.some((i) => i.job_id)) {
      cols.push(...jobColDefs);
    }
    if (granularity.has("activity") || data.some((i) => i.activity_id)) {
      cols.push(...activityColDefs);
    }
    if (granularity.has("cost_type") || data.some((i) => i.cost_type_id)) {
      cols.push(...costTypeColDefs);
    }
    // Only need to include this column if this is an old GL entry from when we combined void lines in the original payroll_run GL entry
    if (data.some((i) => i.void) && ledgerEntry?.entry_type !== "payroll_void") {
      cols.push(voidColDef);
    }
    return cols;
  }, [data]);

  const filenameNoExt = ledgerEntry?.description || "export";

  const [defaultCsvExportParams, defaultExcelExportParams] = useMemo(() => {
    const shared = { skipRowGroups: true };
    return [
      { ...shared, fileName: filenameNoExt + ".csv" },
      { ...shared, fileName: filenameNoExt + ".xlsx" },
    ];
  }, [filenameNoExt]);

  const handleDownloadCsv = () => {
    gridApi?.exportDataAsCsv();
  };

  const handleDownloadExcel = () => {
    gridApi?.exportDataAsExcel();
  };

  const downloadJonasCsv = async () => {
    if (!ledgerEntry || !jonasIcId) return;
    try {
      const response = await MiterAPI.ledger_entries.get_jonas_csv([ledgerEntry._id], jonasIcId);
      if (response.error) throw new Error(response.error);
      downloadCsvFromBlob(response.csv, ledgerEntry.description + " Jonas CSV");
    } catch (e) {
      console.error(e);
      Notifier.error("Error downloading Jonas CSV. We're looking into it!");
    }
  };

  const tableActions = useMemo(() => {
    const acts = [
      ...(can("accounting:ledger_entries:generate") && !draftPayrollPreview
        ? [{ text: "Regenerate entry", onClick: regenerateEntry }]
        : []),
      { text: "Download CSV", onClick: handleDownloadCsv },
      { text: "Download Excel", onClick: handleDownloadExcel },
    ];
    if (ledgerEntry) {
      if (jonasIcId && !draftPayrollPreview) {
        acts.push({
          text: "Download Jonas CSV",
          onClick: downloadJonasCsv,
        });
      }
      if (sage300IcId) {
        acts.push({
          text: "Build Sage 300 JCD",
          onClick: () =>
            sage300Jcd.build({
              ledgerEntryIds: [ledgerEntry._id],
              draftPayroll: payrollIsDraft ? payroll : undefined,
            }),
        });
      }
      if (intacctIcId) {
        acts.push({
          text: "Download Intacct CSV",
          onClick: () => {
            if (draftPayrollPreview) {
              intacctCsv.buildFromDraft(ledgerEntry);
            } else {
              intacctCsv.build([ledgerEntry._id]);
            }
          },
        });
      }
      if (sage100IcId && !draftPayrollPreview) {
        acts.push({
          text: "Download Sage 100 CSVs",
          onClick: () => {
            sage100Exports.build([ledgerEntry._id]);
          },
        });
      }
    }
    return acts;
  }, [jonasIcId, ledgerEntry, sage300IcId, intacctIcId, !!gridApi, can]);

  const renderActionButtons = () => {
    return (
      <Button
        text="Actions"
        dropdownItems={tableActions}
        className="button-1 no-margin"
        style={{ height: 32 }}
      />
    );
  };

  // RENDER MODAL
  return (
    <>
      <AgGridTable
        reportId="ledger-entry-line-items"
        data={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        setGridApi={setGridApi}
        actionButtonsLocation="left"
        defaultActionButtons={renderActionButtons}
        gridHeight={gridHeight || "75%"}
        hideDownloadCSV
        searchValue={searchTerm}
        onSearch={setSearchTerm}
        searchPlaceholder="Search line items"
        gridOptions={{
          suppressRowClickSelection: true,
          suppressCellFocus: true,
          suppressRowHoverHighlight: true,
          quickFilterText: searchTerm,
          groupDisplayType: "multipleColumns",
          showOpenedGroup: true,
          defaultCsvExportParams,
          defaultExcelExportParams,
          groupHideOpenParents: true,
          groupDefaultExpanded: -1,
        }}
      />
      <ExportWarningsModal
        downloadSage300ExportFile={sage300Jcd.downloadSage300ExportFile}
        warnings={sage300Jcd.warnings}
        setWarnings={sage300Jcd.setWarnings}
        data={sage300Jcd.data}
        loading={sage300Jcd.loading}
        fileName={sage300Jcd.timeEntriesFileName}
        headerText="Export Warning For Sage300 Ledger Entries"
      />
      {showPostEntriesModal && ledgerEntry && (
        <PostEntriesModal
          hide={() => setShowPostEntriesModal(false)}
          entriesToPost={[ledgerEntry]}
          getLedgerEntries={getLedgerEntries}
        />
      )}
    </>
  );
};

const replaceBlankWithDashFormatter = (params: ValueFormatterParams<LineItemRow, string>) => {
  return params.value || "-";
};

const sharedColDefs: ColDef<LineItemRow>[] = [
  { headerName: "Line", field: "position", maxWidth: 110, filter: "agNumberColumnFilter", pinned: "left" },
  { headerName: "Description", field: "description", minWidth: 250, pinned: "left" },
  { headerName: "Account type", field: "accountTypeFormatted" },
  { headerName: "Account ID", field: "account_ext_id", filter: "agTextColumnFilter" },
  { headerName: "Account", field: "account_label" },
  {
    headerName: "Debit",
    field: "debit",
    type: "rightAligned",
    valueFormatter: toDollarFormat,
    aggFunc: "sum",
    enableValue: true,
  },
  {
    headerName: "Credit",
    field: "credit",
    type: "rightAligned",
    valueFormatter: toDollarFormat,
    aggFunc: "sum",
    enableValue: true,
  },
];

const voidColDef: ColDef<LineItemRow> = {
  headerName: "Is void",
  headerTooltip: "Whether the line is from a void payroll that offsets the main payroll",
  field: "isVoid",
};

const tmColDefs: ColDef<LineItemRow>[] = [
  { headerName: "Team member ID", field: "tm_friendly_id" },
  { headerName: "First name", field: "tm_first_name" },
  { headerName: "Last name", field: "tm_last_name" },
];

const deptColDefs: ColDef<LineItemRow>[] = [
  { headerName: "Department ID", field: "department_code" },
  { headerName: "Department", field: "department_name" },
];

const locationColDefs: ColDef<LineItemRow>[] = [
  { headerName: "Location ID", field: "location_external_id" },
  { headerName: "Location", field: "location_name" },
];

const jobColDefs: ColDef<LineItemRow>[] = [
  { headerName: "Job ID", field: "job_code" },
  { headerName: "Job", field: "job_name" },
];

const activityColDefs: ColDef<LineItemRow>[] = [
  { headerName: "Cost code", field: "cost_code" },
  { headerName: "Activity", field: "activity_label" },
];

const costTypeColDefs: ColDef<LineItemRow>[] = [
  { headerName: "Cost type ID", field: "cost_type_code" },
  { headerName: "Cost type", field: "cost_type_label" },
];

const defaultColDef: ColDef<LineItemRow> = {
  enableRowGroup: true,
  enablePivot: true,
  resizable: true,
  valueFormatter: replaceBlankWithDashFormatter,
  filter: true,
};
