import { AggregatedFillableTemplate } from "dashboard/types/fillable-template-types";
import React, { FC, useEffect, useMemo } from "react";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import styles from "./FillableTemplateWizard.module.css";
import { WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import { useTeamMemberGroupLabeler } from "dashboard/utils/approvals";
import { getFormFieldTypeTitle } from "miter-utils";
type Props = {
  fillableTemplate: AggregatedFillableTemplate;
  name: string;
};

type TemplateInputRow = {
  _id: string;
  name: string;
  type: string;
  assignee: string;
  description: string;
  required: boolean;
  pageNumber: number;
};

const columns: ColumnConfig<TemplateInputRow>[] = [
  {
    field: "name",
    headerName: "Field",
    width: 150,
    dataType: "string" as const,
  },
  {
    field: "assignee",
    width: 300,
    headerName: "Assignee",
    dataType: "string",
  },
  {
    field: "description",
    headerName: "Description",
    dataType: "string",
    width: 300,
  },
  {
    field: "pageNumber",
    headerName: "Page #",
    dataType: "number",
    width: 50,
  },
  {
    field: "type",
    headerName: "Type",
    dataType: "string",
    width: 50,
  },
  {
    field: "required",
    headerName: "Required",
    dataType: "boolean",
    width: 50,
  },
];

export const ConfirmFillableInputsWizardScreen: FC<Props> = ({ fillableTemplate, name }) => {
  const { setCanNext, handleComplete, screens, curIndex, setNextButtonText } = useWizard();
  const { groupLabeler } = useTeamMemberGroupLabeler();

  // Automatically allow the user to proceed to the next screen
  setCanNext(true);

  const mapToTableInputRow = (input: AggregatedFillableTemplate["inputs"][number]): TemplateInputRow => {
    const assignees = groupLabeler(
      // @ts-expect-error fix me
      (input.assignee || []).map((assignee) => {
        if (assignee.type === "self") {
          return { ...assignee, type: "Recipient" };
        }
        return assignee;
      })
    );
    return {
      _id: input._id,
      name: input.name,
      type: getFormFieldTypeTitle({
        formFieldType: input.type,
        multiple: input.multiple,
      }),
      assignee: assignees,
      description: input.description || "N/A",
      required: !!input?.validations?.required,
      pageNumber: input.position.page_number + 1,
    };
  };

  const templateInputs: TemplateInputRow[] = useMemo(
    () => fillableTemplate.inputs.map(mapToTableInputRow),
    [fillableTemplate.inputs]
  );

  useEffect(() => {
    if (curIndex === screens.length - 1) {
      setNextButtonText("Save");
    }
  }, [screens, curIndex, setNextButtonText]);

  return (
    <WizardScreen name={name} onNext={handleComplete}>
      <div className={styles["confirm-inputs-header"]}>
        <h2 className={styles["subheader-title"]}>Confirm template inputs</h2>
        <p className={styles["subheader-description"]}>
          Verify the field names, descriptions, and assigned team members for each input.
        </p>
      </div>
      <div className={styles["confirm-fillable-inputs-table-wrapper"]}>
        <TableV2
          resource="template input"
          id={"confirm-fillable-inputs-table"}
          data={templateInputs}
          columns={columns}
          containerClassName={styles["confirm-fillable-inputs-table-container"]}
          hidePrinter={true}
          hideSecondaryActions={true}
        />
      </div>
    </WizardScreen>
  );
};
