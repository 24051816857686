import AppContext from "dashboard/contexts/app-context";
import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ActionModal, Button, ConfirmModal, Formblock } from "ui";

import { CustomField, MiterAPI } from "dashboard/miter";
import { booleanOptions, Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import {
  CustomFieldParentType,
  CustomFieldTeamMemberAccessLevels,
  CustomFieldType,
} from "backend/models/custom-field";
import { pick } from "lodash";
import { Assign } from "utility-types";
import { Option } from "ui/form/Input";
import { useActiveCompanyId, usePerDiemRateOptions } from "dashboard/hooks/atom-hooks";
import { useUser } from "dashboard/hooks/atom-hooks";
import { useHasAccessToOnboarding2_0 } from "dashboard/gating";
import {
  TeamMemberGroupSelectValue,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import { DueDateForm, DueDateFormProps, DueType } from "dashboard/components/team-members/DueDateForm";
import { isMiterRep, notNullish } from "miter-utils";
import { TEAM_MEMBER_GROUP_LABEL_OVERRIDES } from "dashboard/pages/team-members/teamSettings/OnboardingItemModal";

import { CaretDown, CaretUp } from "phosphor-react";
import { DateTime } from "luxon";
import OptionsList from "./OptionsList";
import { CreateCustomFieldParams, UpdateCustomFieldParams } from "backend/services/custom-field-service";

type Props = {
  customFieldID?: string;
  parentType: CustomFieldParentType;
  onFinish: (customField: CustomField) => void;
  onHide: () => void;
};

const TEAM_MEMBER_ACCESS_LEVELS: CustomFieldTeamMemberAccessLevels[] = ["none", "viewable", "editable"];
const TEAM_MEMBER_ACCESS_LEVELS_MAP: Record<CustomFieldTeamMemberAccessLevels, string> = {
  none: "Team members can't see or edit this field",
  viewable: "Team members can see but not edit this field",
  editable: "Team members can see and edit this field",
};

type CustomFieldForm = Assign<
  Omit<CustomField, "_id" | "created_at" | "updated_at">,
  {
    type: Option<string>;
    team_member_access: Option<CustomFieldTeamMemberAccessLevels>;
    piece_rate_amount: string;
    piece_rate_taxable: Option<string>;
    onboarding?: boolean;
    validations: {
      required?: boolean;
      min?: number;
      max?: number;
      min_date?: DateTime;
      max_date?: DateTime;
    };
    per_diem_rate_ids?: Option<string>[];
    onboarding_config?: {
      default_due_days_from_start?: number;
      default_team_member_checklists?: Option<TeamMemberGroupSelectValue>[];
      default_assignee?: Option<TeamMemberGroupSelectValue>[];
    };
  }
>;

const CustomFieldModal: React.FC<Props> = ({ customFieldID, parentType, onFinish, onHide }) => {
  // Form hooks
  const form = useForm<CustomFieldForm>();
  useWatch({ control: form.control });

  // Other hooks
  const { customFields, getCustomFields } = useContext(AppContext);
  const selectedCustomField = customFields.find((customField) => customField._id === customFieldID);

  const activeCompanyId = useActiveCompanyId();
  const perDiemRateOptions = usePerDiemRateOptions();
  const activeUser = useUser();
  const hasAccessToOnboarding2_0 = useHasAccessToOnboarding2_0();

  const [selectedTmGroupOptionsDefaultGroups, setSelectedTmGroupOptionsDefaultGroups] = useState<
    Option<TeamMemberGroupSelectValue>[]
  >([]);

  const [selectedTmGroupOptionsDefaultAssignees, setSelectedTmGroupOptionsDefaultAssignees] = useState<
    Option<TeamMemberGroupSelectValue>[]
  >([]);

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
  });

  const teamMemberGroupOptionsDefaultGroups = useTeamMemberGroupOptions({
    selectedGroupOptions: selectedTmGroupOptionsDefaultGroups,
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
  });

  const teamMemberGroupOptionsDefaultAssignees = useTeamMemberGroupOptions({
    selectedGroupOptions: selectedTmGroupOptionsDefaultAssignees,
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
  });

  const teamMemberOptions = teamMemberGroupOptions.flatMap((group) => group.options);

  // State functions
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { control, register, errors, handleSubmit, watch, setValue } = form;

  // Component level variables
  const formData = watch();
  const default_due_days_from_start: number = watch("onboarding_config.default_due_days_from_start");

  useEffect(() => {
    register("onboarding_config.default_due_days_from_start");
  }, [register]);

  useEffect(() => {
    setSelectedTmGroupOptionsDefaultGroups(formData.onboarding_config?.default_team_member_checklists || []);
  }, [JSON.stringify(formData.onboarding_config?.default_team_member_checklists)]);

  useEffect(() => {
    setSelectedTmGroupOptionsDefaultAssignees(formData.onboarding_config?.default_assignee || []);
  }, [JSON.stringify(formData.onboarding_config?.default_assignee)]);

  // Custom select field options list
  const [optionsList, setOptionsList] = useState(selectedCustomField?.options_list || []);
  const [isOptionsListDirty, setIsOptionsListDirty] = useState(false);

  const [pieceRateEnabled, setPieceRateEnabled] = useState(!!selectedCustomField?.piece_rate);

  // automatically open advanced fields if there are any
  const initiallyShowAdvanced = useMemo(() => {
    return (
      !!selectedCustomField?.spanish?.description ||
      !!selectedCustomField?.spanish?.name ||
      !!selectedCustomField?.spanish?.placeholder ||
      !!selectedCustomField?.piece_rate ||
      !!selectedCustomField?.per_diem_rate_ids?.length
    );
  }, [selectedCustomField]);

  const [showAdvanced, setShowAdvanced] = useState(initiallyShowAdvanced);

  const { days, dueType } = useMemo(() => {
    const defaultDueDaysValue =
      default_due_days_from_start ?? selectedCustomField?.onboarding_config?.default_due_days_from_start;
    if (defaultDueDaysValue == null) {
      return { days: undefined, dueType: "days_before_start" };
    }
    const days = Math.abs(defaultDueDaysValue);
    const dueType: DueType =
      defaultDueDaysValue && defaultDueDaysValue >= 0 ? "days_from_start" : "days_before_start";
    return { days, dueType };
  }, [default_due_days_from_start, selectedCustomField]);

  const handleDueDateChange: DueDateFormProps["onChange"] = (days, dueType) => {
    const newDueDaysFromStart = dueType === "days_from_start" ? days : days * -1;
    setValue("onboarding_config.default_due_days_from_start", newDueDaysFromStart, { shouldDirty: true });
  };

  const cleanParams = useCallback(
    (data: CustomFieldForm): CreateCustomFieldParams | UpdateCustomFieldParams => {
      // Trim whitespace for options_list values and translations strings
      const trimmedOptionsList = optionsList.map((optionList) => ({
        ...optionList,
        value: optionList.value.trim(),
        translations: {
          es: optionList.translations.es.trim(),
        },
      }));
      setOptionsList(trimmedOptionsList);

      // Since we are not using react-hook-form for custom select options,
      // we need to manually update options_list and options
      const params: Partial<CustomField> = {
        name: data.name,
        description: data.description,
        placeholder: data.placeholder,
        team_member_access: data.team_member_access?.value,
        options: trimmedOptionsList.map((optionList) => optionList.value), // Update options outside of react-hook-form form state
        options_list: trimmedOptionsList, // Update options_list outside of react-hook-form form state
        multiple: data.multiple,
        validations: {
          ...data.validations,
          min_date: data.validations?.min_date?.toISODate(),
          max_date: data.validations?.max_date?.toISODate(),
        },
        onboarding: data.onboarding,
        unit_of_measurement: data.unit_of_measurement,
        spanish: {
          name: data.spanish?.name,
          description: data.spanish?.description,
          placeholder: data.spanish?.placeholder,
        },
        piece_rate:
          pieceRateEnabled && !isNaN(Number(data.piece_rate_amount))
            ? {
                amount: Number(data.piece_rate_amount),
                taxable: data.piece_rate_taxable?.value === "true",
              }
            : null,
        per_diem_rate_ids: data.per_diem_rate_ids?.map((perDiemRate) => perDiemRate.value),
        onboarding_config:
          parentType === "onboarding_task"
            ? {
                default_due_days_from_start: data.onboarding_config?.default_due_days_from_start,
                default_team_member_checklists:
                  data.onboarding_config?.default_team_member_checklists?.map((group) => group.value) ?? null,
                default_assignee:
                  data.onboarding_config?.default_assignee?.map((group) => group.value) ?? null,
              }
            : undefined,
      };

      if (data.type.value === "select") {
        const updatedOptionsValues = params.options_list?.map((option) => option.value) || [];
        // Validate that there are no empty strings in the options list values
        if (updatedOptionsValues.some((optionValue) => optionValue === "")) {
          throw new Error("Option values cannot be empty.");
        }
        // Validate that there are no duplicate values in the options list values
        const optionsValuesSet = new Set(updatedOptionsValues);
        if (optionsValuesSet.size !== updatedOptionsValues.length) {
          throw new Error("Duplicate values are not allowed in options.");
        }
      }

      if (customFieldID) {
        const dirtyKeys = Object.keys(form.formState.dirtyFields);
        // Since we aren't using react-hook-form, if the options list is dirty,
        // we need to include the options_list and options keys in the dirtyKeys array
        if (isOptionsListDirty) {
          dirtyKeys.push("options_list");
          dirtyKeys.push("options");
        }
        return {
          ...pick(params, dirtyKeys),
          piece_rate:
            pieceRateEnabled && !isNaN(Number(data.piece_rate_amount))
              ? {
                  amount: Number(data.piece_rate_amount),
                  taxable: data.piece_rate_taxable?.value === "true",
                }
              : null,
        } as UpdateCustomFieldParams;
      } else {
        return {
          ...params,
          type: data.type.value as CustomFieldType,
          company_id: activeCompanyId!,
          parent_type: parentType,
        } as CreateCustomFieldParams;
      }
    },
    [
      form.formState.dirtyFields,
      customFieldID,
      selectedCustomField,
      activeCompanyId,
      parentType,
      pieceRateEnabled,
      optionsList,
    ]
  );

  const createCustomField = async (data: CustomFieldForm) => {
    if (selectedCustomField) return;
    setLoading(true);
    try {
      const params = cleanParams(data) as CreateCustomFieldParams;
      const res = await MiterAPI.custom_fields.create(params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Custom field created successfully");
      await getCustomFields();
      onFinish(res);
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateCustomField = async (data: CustomFieldForm) => {
    if (!selectedCustomField) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.custom_fields.update(selectedCustomField._id, params);
      if (res.error) throw new Error(res.error);

      await getCustomFields();
      onFinish(res);
      onHide();
      Notifier.success("Custom field updated successfully");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const deleteCustomField = async () => {
    if (!selectedCustomField) return;
    setDeleting(true);
    try {
      const res = await MiterAPI.custom_fields.delete(selectedCustomField._id);
      if (res.error) throw new Error(res.error);

      await getCustomFields();
      onFinish(res);
      onHide();
      Notifier.success("Custom field deleted successfully");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setDeleting(false);
  };

  const submit = () => {
    if (selectedCustomField) {
      handleSubmit(updateCustomField)();
    } else {
      handleSubmit(createCustomField)();
    }
  };

  const isNumberField = useMemo(
    () => (formData.type?.value ? formData.type.value === "number" : selectedCustomField?.type === "number"),
    [formData.type?.value, selectedCustomField?.type]
  );

  const isDateField = useMemo(
    () => (formData.type?.value ? formData.type.value === "date" : selectedCustomField?.type === "date"),
    [formData.type?.value, selectedCustomField?.type]
  );

  const showOptions = useMemo(
    () => (formData.type?.value ? formData.type.value === "select" : selectedCustomField?.type === "select"),
    [formData.type?.value, selectedCustomField?.type]
  );

  const isQuantityField = useMemo(
    () => formData.type?.value === "quantity" || selectedCustomField?.type === "quantity",
    [formData.type?.value, selectedCustomField?.type]
  );

  const showRequired = useMemo(() => parentType === "timesheet", [parentType]);

  const showTeamMemberAccess = useMemo(
    () => parentType !== "job" && parentType !== "onboarding_task",
    [parentType]
  );

  const showOnboardingConfigFields = useMemo(() => parentType === "onboarding_task", [parentType]);

  const isTwoColumn = useMemo(() => {
    return isNumberField || showOptions || showTeamMemberAccess || showOnboardingConfigFields;
  }, [showTeamMemberAccess, isNumberField, showOptions, showOnboardingConfigFields]);

  const isCheckBoxField = useMemo(
    () =>
      formData.type?.value ? formData.type.value === "checkbox" : selectedCustomField?.type === "checkbox",
    [formData.type?.value, selectedCustomField?.type]
  );

  const defaultTeamMemberChecklists = useMemo(() => {
    return (selectedCustomField?.onboarding_config?.default_team_member_checklists || [])
      .map((group) => {
        const option = teamMemberOptions.find(
          (tm) => tm.value?.type === group.type && tm.value?.value === group.value
        );
        return option;
      })
      .filter(notNullish);
  }, [selectedCustomField, teamMemberOptions]);

  const defaultAssignee = useMemo(() => {
    return (selectedCustomField?.onboarding_config?.default_assignee || [])
      .map((group) => {
        const option = teamMemberOptions.find(
          (tm) => tm.value?.type === group.type && tm.value?.value === group.value
        );
        return option;
      })
      .filter(notNullish);
  }, [selectedCustomField, teamMemberOptions]);

  const renderParentSpecificFields = () => {
    if (parentType === "timesheet") {
      return (
        <>
          {isNumberField && (
            <div>
              <Formblock
                form={form}
                label="Piece rate pay"
                name="piece_rate_pay_enabled"
                type="checkbox"
                text="Enable piece rate pay"
                editing={true}
                onChange={(e) => setPieceRateEnabled(e.target.checked)}
                checked={pieceRateEnabled}
                className="modal"
              />
              {pieceRateEnabled && (
                <div className="flex" style={{ width: "100%" }}>
                  <Formblock
                    form={form}
                    style={{ width: "100%" }}
                    label="Piece rate"
                    labelInfo={"Team members will be paid this amount per unit."}
                    underlineTooltip={true}
                    name="piece_rate_amount"
                    type="unit"
                    unit="$"
                    editing={true}
                    defaultValue={selectedCustomField?.piece_rate?.amount}
                    register={register(vals.isNumber)}
                    className="modal"
                  />
                  <div style={{ width: 15 }}></div>
                  <Formblock
                    label="Taxable?"
                    labelInfo={"Whether employees' piece rate pay is subject to payroll taxes."}
                    underlineTooltip={true}
                    type="select"
                    style={{ width: "100%" }}
                    form={form}
                    name="piece_rate_taxable"
                    options={booleanOptions}
                    className="modal"
                    errors={errors}
                    editing={true}
                    defaultValue={selectedCustomField?.piece_rate?.taxable ? "true" : "false"}
                  />
                </div>
              )}
            </div>
          )}
        </>
      );
    }

    // TODO: Fully deprecate this when onboarding checklists 2.0 is released
    if (parentType === "team_member" && !hasAccessToOnboarding2_0) {
      return (
        <Formblock
          form={form}
          label="Onboarding"
          name="onboarding"
          type="checkbox"
          text="Display during the onboarding process"
          editing={true}
          className="modal"
          defaultValue={selectedCustomField?.onboarding}
        />
      );
    }
  };

  const renderOnboardingConfigFields = () => {
    if (showOnboardingConfigFields) {
      return (
        <>
          <DueDateForm days={days} dueType={dueType as DueType} onChange={handleDueDateChange} />
          <Formblock
            type="multiselect"
            name="onboarding_config.default_team_member_checklists"
            label="Default team member groups"
            labelInfo="Select the default team member groups."
            form={form}
            control={control}
            editing={true}
            className="modal"
            placeholder="Select team member groups"
            options={teamMemberGroupOptionsDefaultGroups}
            height="unset"
            customMultiselectDefaultValue={defaultTeamMemberChecklists}
          />
          <Formblock
            type="multiselect"
            name="onboarding_config.default_assignee"
            label="Default assignee"
            labelInfo="Select a default assignee."
            form={form}
            control={control}
            editing={true}
            className="modal"
            placeholder="Select assignee groups"
            options={teamMemberGroupOptionsDefaultAssignees}
            height="unset"
            customMultiselectDefaultValue={defaultAssignee}
          />
        </>
      );
    }
  };

  const renderAdvancedFields = () => {
    const isPerDiemSelectDisabled = !isCheckBoxField;
    const isPerDiemSelectHidden = parentType !== "timesheet";

    return (
      <>
        <Button
          className="button-text"
          onClick={() => setShowAdvanced(!showAdvanced)}
          style={{ fontSize: "15px", color: "#555", marginTop: 5 }}
        >
          {`${showAdvanced ? "Hide" : "Show"} advanced fields`}
          {showAdvanced && <CaretUp style={{ marginLeft: 5 }} />}{" "}
          {!showAdvanced && <CaretDown style={{ marginLeft: 5 }} />}
        </Button>
        {showAdvanced && (
          <div style={{ marginTop: 25 }}>
            <Formblock
              type="text"
              name="spanish.name"
              label="Spanish name"
              control={control}
              register={register}
              editing={true}
              errors={errors}
              defaultValue={selectedCustomField?.spanish?.name}
              className="modal"
              placeholder="Name of the custom field in spanish"
              labelInfo="This is the name of the field that will be displayed to users."
            />

            <Formblock
              type="text"
              name="spanish.description"
              label="Spanish helper text"
              control={control}
              register={register()}
              editing={true}
              errors={errors}
              defaultValue={selectedCustomField?.spanish?.description}
              className="modal"
              labelInfo="This will appear above the field to help users fill it out."
              placeholder="Custom field helper text in spanish"
            />

            <Formblock
              type="text"
              name="spanish.placeholder"
              label="Spanish placeholder"
              control={control}
              register={register()}
              editing={true}
              errors={errors}
              defaultValue={selectedCustomField?.spanish?.placeholder}
              className="modal"
              labelInfo="This will appear inside the field to help users fill it out."
              placeholder="Placeholder text in spanish"
            />

            {/* Only show per diem rate selection if this is a timesheet custom field */}
            {!isPerDiemSelectHidden && (
              <Formblock
                type="multiselect"
                name="per_diem_rate_ids"
                label="Per diem rate"
                control={control}
                register={register()}
                editing={true}
                errors={errors}
                defaultValue={selectedCustomField?.per_diem_rate_ids}
                className="modal"
                disabled={isPerDiemSelectDisabled}
                labelInfo={
                  isPerDiemSelectDisabled
                    ? "Per diem rates can only be applied to timesheets and checkbox fields."
                    : "Any selected per diems that the team member is eligible for (based on distance, etc) will be created after the timesheet is approved."
                }
                options={perDiemRateOptions}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const renderFormFirstColumn = () => {
    return (
      <div style={{ padding: 15, width: 350 }}>
        <Formblock
          type="text"
          name="name"
          label="Name*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={selectedCustomField?.name}
          className="modal"
          placeholder="Name of the custom field"
          labelInfo="This is the name of the field that will be displayed to users."
        />
        <Formblock
          type="select"
          name="type"
          label="Type*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          requiredSelect={true}
          defaultValue={selectedCustomField?.type}
          className="modal"
          placeholder="Select a field type"
          options={[
            { label: "Text", value: "text" },
            { label: "Paragraph", value: "paragraph" },
            { label: "Number", value: "number" },
            { label: "Select", value: "select" },
            { label: "Checkbox", value: "checkbox" },
            { label: "Quantity", value: "quantity" },
            { label: "Date", value: "date" },
          ]}
          labelInfo="This is the type of data that will be stored in this field."
          disabled={!!customFieldID}
        />

        {isQuantityField && (
          <Formblock
            type="text"
            name="unit_of_measurement"
            label="Unit of Measurement"
            control={control}
            register={register(vals.required)}
            editing={true}
            errors={errors}
            defaultValue={selectedCustomField?.unit_of_measurement}
            className="modal"
            labelInfo="This is the Unit of Measurement used for the quantity (ea, lbs, etc)."
          />
        )}

        <Formblock
          type="text"
          name="description"
          label="Helper text"
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={selectedCustomField?.description}
          className="modal"
          labelInfo="This will appear above the field to help users fill it out."
          placeholder="Custom field helper text"
        />

        <Formblock
          type="text"
          name="placeholder"
          label="Placeholder"
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={selectedCustomField?.placeholder}
          className="modal"
          labelInfo="This will appear inside the field to help users fill it out."
          placeholder="Placeholder text"
        />

        {showRequired && (
          <Formblock
            type="checkbox"
            name="validations.required"
            register={register}
            editing={true}
            errors={errors}
            defaultValue={selectedCustomField?.validations?.required}
            className="modal"
            text="This field is required"
            style={{ marginBottom: 0 }}
          />
        )}
        {(formData.type?.value === "select" || selectedCustomField?.type === "select") && (
          <Formblock
            type="checkbox"
            name="multiple"
            register={register}
            editing={true}
            disabled={!!customFieldID}
            errors={errors}
            defaultValue={selectedCustomField?.multiple}
            className="modal"
            text="This is a multi-select field"
          />
        )}
      </div>
    );
  };

  const renderFormSecondColumn = () => {
    return (
      <div style={{ padding: 15, width: 350 }}>
        {showTeamMemberAccess && (
          <Formblock
            type="select"
            name="team_member_access"
            label="Team member access*"
            control={control}
            editing={true}
            errors={errors}
            requiredSelect={true}
            defaultValue={selectedCustomField?.team_member_access}
            className="modal"
            placeholder="Select an option"
            options={TEAM_MEMBER_ACCESS_LEVELS.map((level) => ({
              label: TEAM_MEMBER_ACCESS_LEVELS_MAP[level],
              value: level,
            }))}
            labelInfo="This is the level of access that team members have to this field."
          />
        )}
        {isNumberField && (
          <>
            <div className="flex" style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
              <Formblock
                type="number"
                name="validations.min"
                label="Min"
                register={register({
                  valueAsNumber: true,
                })}
                editing={true}
                errors={errors}
                defaultValue={selectedCustomField?.validations?.min}
                className="modal"
                labelInfo="The minimum value that can be entered."
                placeholder="No min"
                style={{ width: "49%" }}
                max={formData.validations?.max}
              />
              <Formblock
                type="number"
                name="validations.max"
                label="Max"
                register={register({
                  valueAsNumber: true,
                  validate: {
                    greaterThanMin: (value) => {
                      const min = formData.validations?.min;
                      if (value && min && value < min) {
                        return "Max must be more than min";
                      }

                      return true;
                    },
                  },
                })}
                editing={true}
                errors={errors}
                defaultValue={selectedCustomField?.validations?.max}
                className="modal"
                min={formData.validations?.min}
                placeholder="No max"
                style={{ width: "49%" }}
              />
            </div>
          </>
        )}
        {isDateField && (
          <>
            <div className="flex" style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
              <Formblock
                type="datetime"
                name="validations.min_date"
                label="Min date"
                register={register}
                control={control}
                editing={true}
                errors={errors}
                defaultValue={
                  selectedCustomField?.validations?.min_date
                    ? DateTime.fromISO(selectedCustomField?.validations?.min_date)
                    : undefined
                }
                className="modal"
                labelInfo="The minimum date value that can be entered."
                placeholder="No min"
                style={{ width: "49%" }}
                dateOnly={true}
                max={formData.validations?.max_date}
                rules={{
                  validate: {
                    lessThanMax: (value) => {
                      const max = formData.validations?.max_date;
                      if (value && max && value > max) {
                        return "Min must be less than max";
                      }

                      return true;
                    },
                  },
                }}
              />
              <Formblock
                type="datetime"
                name="validations.max_date"
                label="Max date"
                register={register}
                control={control}
                editing={true}
                errors={errors}
                defaultValue={
                  selectedCustomField?.validations?.max_date
                    ? DateTime.fromISO(selectedCustomField?.validations?.max_date)
                    : undefined
                }
                className="modal"
                placeholder="No max"
                style={{ width: "49%" }}
                dateOnly={true}
                min={formData.validations?.min_date}
                rules={{
                  validate: {
                    greaterThanMin: (value) => {
                      const min = formData.validations?.min_date;
                      if (value && min && value < min) {
                        return "Max must be more than min";
                      }

                      return true;
                    },
                  },
                }}
              />
            </div>
          </>
        )}
        {renderParentSpecificFields()}
        {renderOnboardingConfigFields()}
        {showOptions && (
          <OptionsList
            optionsList={selectedCustomField?.options_list || []} // Provide a default value of an empty array if options_list is undefined
            updateOptionsList={(e) => {
              setOptionsList(e);
              setIsOptionsListDirty(true);
            }}
          />
        )}
        {renderAdvancedFields()}
      </div>
    );
  };

  return (
    <ActionModal
      headerText={selectedCustomField ? "Update custom field" : "Create custom field"}
      showSubmit={true}
      showCancel={true}
      showDelete={!!selectedCustomField}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={submit}
      wrapperStyle={{ width: isTwoColumn ? 800 : undefined }}
      onDelete={() => setShowDeleteConfirm(true)}
      loading={loading}
    >
      <div style={{ width: "100%", display: "flex" }}>
        {renderFormFirstColumn()}
        {isTwoColumn ? renderFormSecondColumn() : null}
      </div>
      {showDeleteConfirm && (
        <ConfirmModal
          title="Delete custom field"
          body="Are you sure you want to delete this custom field?"
          onYes={deleteCustomField}
          onNo={() => setShowDeleteConfirm(false)}
          loading={deleting}
        />
      )}
    </ActionModal>
  );
};

export default CustomFieldModal;
