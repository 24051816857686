import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/shared/Banner";
import { Helmet } from "react-helmet";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import { Button, Formblock } from "ui";
import { MiterFilterArray } from "dashboard/miter";
import { useEnhancedSearchParams } from "miter-utils";
import { useActiveCompany, usePayrollReadiness } from "dashboard/hooks/atom-hooks";
import { PayPeriodHoursv2 } from "./TimesheetsByPayPeriod/TimesheetsByPayPeriod";

const viewOptions = [
  { label: "List view", value: "list" },
  { label: "Pay period view", value: "pay_period" },
  { label: "Sections view", value: "sections" },
];

export type TimesheetsView = "list" | "pay_period" | "sections";

const Timesheets: React.FC = () => {
  const activeCompany = useActiveCompany();
  const payrollReadiness = usePayrollReadiness();
  const navigate = useNavigate();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const { tsid, action } = parsedSearchParams;

  const timesheetsFilters: MiterFilterArray = [
    { field: "company", type: "string", value: activeCompany?._id || "" },
  ];

  const defaultView = activeCompany?.settings.timesheets.default_timesheets_view || "list";
  const [selectedView, setSelectedView] = useState<TimesheetsView>(
    tsid || action === "create" ? "list" : defaultView
  );

  useEffect(() => {
    if (selectedView === "pay_period") {
      setSearchParams({ action: undefined });
    }
  }, [selectedView]);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Timesheets | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Timesheets</h1>
          <div className="flex-1"></div>
          <Formblock
            name="view"
            type="select"
            options={viewOptions}
            style={{ width: 160, marginBottom: 0, height: 32 }}
            inputProps={{
              style: { marginBottom: 0, height: 32, display: "flex", alignItems: "center" },
            }}
            height={"30px"}
            editing={true}
            onChange={(o) => setSelectedView(o.value)}
            value={viewOptions.find((option) => option.value === selectedView)}
          />
          <Button className="button-1" text="Settings" onClick={() => navigate("/timesheet-settings")} />
        </div>

        {payrollReadiness?.status === "not_under_agreement" && (
          <Banner
            content={
              "Construction payroll should take minutes, not hours. Get started with Miter Payroll today."
            }
            onClick={() => window.open("mailto: sales@miter.com")}
            style={{ margin: "20px 0px" }}
          />
        )}

        {selectedView === "list" ? (
          <TimesheetsTable defaultFilters={timesheetsFilters} showToggler={true} showMiniTitle={false} />
        ) : (
          <PayPeriodHoursv2 view={selectedView} />
        )}
      </div>
    </div>
  );
};

export default Timesheets;
