import { useContext, useMemo } from "react";
import { ColumnConfig } from "ui/table-v2/Table";
import { Location, MiterIntegrationForCompany } from "dashboard/miter";
import AppContext from "dashboard/contexts/app-context";

export function useLocationsTableColDef(): ColumnConfig<Location>[] {
  return useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        dataType: "string",
      },
      {
        field: "status",
        dataType: "string",
        displayType: "badge",
        colors: {
          active: "green",
          inactive: "yellow",
        },
      },
      {
        field: "parent_location_name",
        headerName: "Parent Location Name",
        dataType: "string",
        sortable: false,
      },
    ];
  }, []);
}

export const useLocationSourceSystem = (location?: Location): MiterIntegrationForCompany | undefined => {
  const { integrations } = useContext(AppContext);

  let sourceSystem: MiterIntegrationForCompany | undefined;
  if (location?.integrations) {
    const key = Object.keys(location.integrations)[0];
    if (key) sourceSystem = integrations.find((i) => i.key === key);
  }

  return sourceSystem;
};
