import {
  useLookupDepartment,
  useLookupJob,
  useLookupPolicy,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { ApprovalItem, PolicyRule } from "dashboard/miter";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Label } from "ui";
import { useConditionLabel } from "../policies/PolicyRules";

type Props = {
  item: ApprovalItem;
};

export const ApprovalItemPolicyData: React.FC<Props> = ({ item }) => {
  const lookupPolicy = useLookupPolicy();
  const lookupJob = useLookupJob();
  const lookupDepartment = useLookupDepartment();
  const lookupTeam = useLookupTeam();

  const lastApprovedHistoryItem = item?.approval_history
    ? item.approval_history[item.approval_history.length - 1]
    : undefined;

  const policyId = item.approval_stage?.policy_id || lastApprovedHistoryItem?.policy_id;

  const policyRuleId = item.approval_stage?.policy_rule_id || lastApprovedHistoryItem?.policy_rule_id;

  const policy = lookupPolicy(policyId);

  const policyURL = useMemo(() => {
    if (!policy) return null;

    switch (policy.type) {
      case "timesheet":
        return "/timesheet-settings/";
      case "expense":
        return `/expenses/settings/policies/${policy._id}`;
      case "expense_reimbursement":
        return `/expenses/settings/policies/${policy._id}`;
      case "time_off_request":
        return `/time-off/settings/`;
    }
  }, [policy]);

  const policyRule = useMemo(() => {
    if (!policy) return null;
    return (policy.rules as PolicyRule[]).find((rule) => rule._id === policyRuleId);
  }, [policy, item.approval_stage?.policy_rule_id]);

  const conditionLabel = useConditionLabel(policyRule, policy?.type, policy);

  const policySource = useMemo(() => {
    const source = item.approval_stage?.policy_source;
    if (!source) return null;

    if (source.type === "job") {
      const job = lookupJob(source._id);
      if (!job) return null;

      return (
        <Link className="purple-link" to={"/jobs/" + source._id}>
          Job: {job.name}
        </Link>
      );
    }

    if (source.type === "department") {
      const department = lookupDepartment(source._id);
      if (!department) return null;

      return (
        <Link className="purple-link" to={"/departments/" + source._id}>
          Department: {department.name}
        </Link>
      );
    }

    if (source.type === "team_member") {
      const teamMember = lookupTeam(source._id);
      if (!teamMember) return null;

      return (
        <Link className="purple-link" to={"/team-members/" + source._id}>
          Team Member: {teamMember.full_name}
        </Link>
      );
    }

    if (source.type === "company") {
      return <>Company default</>;
    }
  }, [lookupJob, lookupDepartment, item]);

  return (
    <div>
      <div className="approval-item-policy-data-body">
        <div className="flex" style={{ marginBottom: 10 }}>
          <Label label="Policy" style={{ width: 120 }} />
          <div className="approval-item-policy-data-value">
            <Link className="purple-link" to={policyURL || ""} target="_blank">
              {policy ? policy.name : "Unknown policy"}
            </Link>
          </div>
        </div>
        <div className="flex" style={{ marginBottom: 10 }}>
          <Label label="Policy Rule" style={{ width: 120 }} />
          <div className="approval-item-policy-data-value">{conditionLabel ?? "Unknown rule"}</div>
        </div>
        {policySource && (
          <div className="flex" style={{ marginBottom: 10 }}>
            <Label label="Policy Source" style={{ width: 120 }} />
            <div className="approval-item-policy-data-value">{policySource}</div>
          </div>
        )}
      </div>
    </div>
  );
};
