import { FC, useMemo, useState } from "react";
import { Button, TableV2 } from "ui";
import { AggregatedTeamMember, BankAccount, TeamMember } from "dashboard/miter";
import { ColumnConfig } from "ui/table-v2/Table";
import React from "react";
import { useFetchBankAccounts } from "./utils";
import { ViewBankAccountModal } from "./ViewBankAccountModal";
import { CreateBankAccountModal } from "./CreateBankAccountModal";
import { useTranslation } from "react-i18next";
import { TeamPortalUser } from "team-portal/utils/miter";
import { CheckBankAccountStatus } from "../../../backend/utils/check/check-types";

type Props = {
  teamMember?: TeamMember | AggregatedTeamMember | TeamPortalUser;
  companyId: string;
  onUpdate?: () => void;
};

export const BankAccountsTable: FC<Props> = ({ teamMember, companyId, onUpdate }) => {
  const [refreshCount, setRefreshCount] = useState(0);
  const { result, loading } = useFetchBankAccounts({
    companyId,
    refreshCount,
    teamMemberId: teamMember?._id,
  });

  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [openCreate, setOpenCreate] = useState(false);
  const { t } = useTranslation<$TSFixMe>();

  const refresh = () => {
    if (onUpdate) onUpdate(); // refreshes team member data if team portal
    setRefreshCount((prev) => prev + 1);
  };

  // remember that these columns will also be shown on the team portal!
  const columns = useMemo(() => {
    const isTeamMemberEnrolledInPayroll = !!teamMember?.check_tm;

    const cols: ColumnConfig<BankAccount>[] = [
      {
        headerName: t("Last 4"),
        field: "account_number_last_4",
        dataType: "string",
        valueFormatter: (params) => `····${params.value}`,
      },
      {
        headerName: t("Type"),
        field: "account_subtype",
        dataType: "string",
        displayType: "badge",
        colors: {
          checking: "green",
          savings: "orange",
        },
      },
    ];

    if (isTeamMemberEnrolledInPayroll) {
      cols.push({
        headerName: t("Direct deposit verification"),
        field: "check_bank_account.status",
        dataType: "string" as const,
        displayType: "badge" as const,
        colors: {
          verified: "green",
          pending: "blue",
          disabled: "red",
        },
        valueGetter: (params) => {
          const checkStatus: CheckBankAccountStatus | undefined = params.data?.check_bank_account?.status;
          switch (checkStatus) {
            case "disabled_irrecoverable":
            case "disabled_recoverable":
              return "disabled";
            case "ownership_verified":
            case "validated":
              return "verified";
            case "validation_pending":
              return "pending";
          }
        },
      });
    }

    return cols;
  }, [teamMember]);

  return (
    <div>
      <div className="flex space-between margin-bottom-negative-15">
        <h2>{t("Bank Accounts")}</h2>
        <Button onClick={() => setOpenCreate(true)} text={t("Add bank account")} />
      </div>
      <TableV2
        id="bank-accounts-table"
        resource="bank accounts"
        columns={columns}
        data={result}
        isLoading={loading}
        onClick={setSelectedBankAccount}
        hideSearch
        hideSecondaryActions
      />
      {selectedBankAccount && (
        <ViewBankAccountModal
          teamMember={teamMember}
          bankAccount={selectedBankAccount}
          onUpdate={() => refresh()}
          onClose={() => setSelectedBankAccount(undefined)}
        />
      )}
      {openCreate && (
        <CreateBankAccountModal
          teamMemberId={teamMember?._id}
          companyId={companyId}
          onSubmit={() => refresh()}
          onClose={() => {
            setOpenCreate(false);
          }}
        />
      )}
    </div>
  );
};
