// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Notifier } from "ui";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { shorten } from "dashboard/utils";
import { hoursFormatter } from "miter-utils";
import { PayPeriodHoursEditorParams, WorkweekEditorModal } from "./TimesheetsByPayPeriodEditor";

import { Chat, Check, Export, IconWeight, ListDashes, Pencil, X } from "phosphor-react";
import {
  DAY_COL_PREFIX,
  EARNING_TYPE_ALIAS_SPLITTER,
  PayPeriodSelector,
  getDayColDefs,
  timesheetsByPayPeriodEarnTypeLookup,
} from "./timesheetsByPayPeriodUtils";
import {
  AggregatedJob,
  AggregatedMiterEarning,
  AggregatedTeamMember,
  TimesheetSection,
  Crew,
  MiterAPI,
} from "dashboard/miter";
import {
  useActiveCompany,
  useActiveJobs,
  useActivityLabelFormatter,
  useCrews,
  useDepartments,
  useJobNameFormatter,
  useLookupDepartment,
  useLookupJob,
  useLookupTeam,
  useLookupTeamMemberCrews,
  useLookupTimeOffPolicy,
  useSubJobsConfig,
  useTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { DateTime } from "luxon";
import {
  CheckboxSelectionCallbackParams,
  ICellRendererParams,
  ITooltipParams,
  KeyCreatorParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { capitalize } from "lodash";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { PayPeriodHoursExportModal } from "./PayPeriodHoursExportModal";
import { TimeOffRequestStatus } from "backend/models/time-off-request";
import { TimesheetPayPeriodSectionsModal, TimesheetListModalProps } from "./TimesheetPayPeriodSectionsModal";
import { PaymentWarning } from "backend/utils/payroll/types";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";
import { TimesheetsView } from "../timesheets";
import { TimesheetSectionsTable } from "./TimesheetSectionsTable";
import { TimesheetWarningsTooltip } from "./TimesheetWarningsTooltip";
import { useGetJobHierarchy, useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";

export type PayPeriodHoursRow = {
  _id: string;
  team_member?: AggregatedTeamMember | AggregatedMiterEarning["team_member"];
  job?: AggregatedMiterEarning["job"] | AggregatedJob;
  job_hierarchy_ids: string[];
  activity?: AggregatedMiterEarning["activity"];
  department?: AggregatedMiterEarning["department"];
  wc_code?: AggregatedMiterEarning["wc_code"];
  hours?: number;
  approval_status?: AggregatedMiterEarning["approval_status"];
  check_type?: AggregatedMiterEarning["check_type"];
  miter_type?: AggregatedMiterEarning["miter_type"];
  crews?: Crew[];
  timesheet_ids: string[];
  time_off_request_ids: string[];
  earning_type_alias?: string;
  earningIds: string[];
};

export type PayPeriod = {
  periodStart: string;
  periodEnd: string;
};

type Props = { view?: TimesheetsView };

export const PayPeriodHoursv2: React.FC<Props> = ({ view }) => {
  /**************************************************************************
   *  Important hooks
   ***************************************************************************/
  const activeCompany = useActiveCompany();
  const activeUser = useUser();
  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();
  const teamMembers = useTeam();
  const jobs = useActiveJobs();
  const crews = useCrews();
  const departments = useDepartments();
  const timesheetAbilities = useTimesheetAbilities();
  const timeOffRequestAbilities = useTimeOffRequestAbilities();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns<PayPeriodHoursRow>({ initialRowGroup: true });
  const subJobsConfig = useSubJobsConfig();

  const lookupJob = useLookupJob();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const lookupTeamMemberCrews = useLookupTeamMemberCrews();
  const lookupDepartment = useLookupDepartment();
  const lookupTeam = useLookupTeam();
  const getJobHierarchy = useGetJobHierarchy();

  const { can } = useMiterAbilities();

  /**************************************************************************
   * State hooks
   ***************************************************************************/
  const [selectedRows, setSelectedRows] = useState<PayPeriodHoursRow[]>([]);
  const [filteredRows, setFilteredRows] = useState<PayPeriodHoursRow[]>([]);
  const [earnings, setEarnings] = useState<AggregatedMiterEarning[]>();
  const [warnings, setWarnings] = useState<PaymentWarning[]>([]);
  const [timesheetSections, setTimesheetSections] = useState<TimesheetSection[]>();
  const [loading, setLoading] = useState(false);
  const [payPeriodEditorProps, setPayPeriodEditorProps] = useState<PayPeriodHoursEditorParams | undefined>();
  const [selectedPayScheduleId, setSelectedPayScheduleId] = useState<string | undefined>();
  const [selectedPeriod, setSelectedPeriod] = useState<PayPeriod>();
  const [showExportModal, setShowExportModal] = useState(false);

  const [reApplyReportView, setReApplyReportView] = useState(false);

  // Keep track of if we are approving or unapproving rows
  const [approving, setApproving] = useState(false);
  const [unapproving, setUnapproving] = useState(false);
  const [listModalProps, setListModalProps] = useState<TimesheetListModalProps>();

  /**************************************************************************
   *  Thread hooks
   ***************************************************************************/

  // TODO: Re-enable this once we build our own version of in-app messaging/threading
  const convosEnabled = false && activeCompany?.settings.other?.enable_cord_conversations;

  const threadDateRangeString = useMemo(() => {
    if (!selectedPeriod) return "";
    const formatter = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const startJsDate = DateTime.fromISO(selectedPeriod?.periodStart || "").toJSDate();
    const endJsDate = DateTime.fromISO(selectedPeriod?.periodEnd || "").toJSDate();

    return formatter.formatRange(startJsDate, endJsDate);
  }, [selectedPeriod]);

  const warningsMap = useMemo(() => {
    if (!warnings) return {};
    return warnings.reduce((map, w) => {
      if (!w.team_member_id) return map;
      if (!map[w.team_member_id]) map[w.team_member_id] = [];
      map[w.team_member_id]!.push(w);

      return map;
    }, {} as Record<string, PaymentWarning[]>);
  }, [warnings]);

  /**************************************************************************
   *  Table configurations
   ***************************************************************************/
  const dayColDefs = useMemo(() => {
    if (!selectedPeriod) return [];
    return getDayColDefs({ ...selectedPeriod, usingTableV2: true });
  }, [selectedPeriod]);

  const moveTo = (
    groupIndex: number,
    params: ICellRendererParams<PayPeriodHoursRow>,
    editorType: "single_job" | "single_tm",
    tablePosition: "first" | "middle" | "last" | "none",
    direction: "next" | "previous"
  ) => {
    // If we're coming from a specific direction, we actually want to set the
    // "previous / next" node for the next iteration back to the current node.
    const curNodeIndex = direction === "next" ? groupIndex + 1 : groupIndex - 1;

    const prevNode = params?.node.parent?.childrenAfterSort?.[curNodeIndex - 1];
    const curNode = params?.node.parent?.childrenAfterSort?.[curNodeIndex];
    const nextNode = params?.node.parent?.childrenAfterSort?.[curNodeIndex + 1];

    let prevGroup: string | undefined, curGroup: string | undefined, nextGroup: string | undefined;
    if (editorType === "single_tm") {
      prevGroup = prevNode?.allLeafChildren?.[0]?.data?.team_member?._id;
      curGroup = curNode?.allLeafChildren?.[0]?.data?.team_member?._id;
      nextGroup = nextNode?.allLeafChildren?.[0]?.data?.team_member?._id;
    } else if (editorType === "single_job") {
      prevGroup = prevNode?.allLeafChildren?.[0]?.data?.job?._id;
      curGroup = curNode?.allLeafChildren?.[0]?.data?.job?._id;
      nextGroup = nextNode?.allLeafChildren?.[0]?.data?.job?._id;
    }

    let jobId: string | undefined, teamMemberId: string | undefined, newGroupIndex: number;
    if (editorType === "single_job") jobId = curGroup;
    if (editorType === "single_tm") teamMemberId = curGroup;
    if (direction === "next") {
      newGroupIndex = groupIndex + 1;
    } else {
      newGroupIndex = groupIndex - 1;
    }

    let newTablePosition: "first" | "middle" | "last" | "none" = "middle";
    if (prevGroup == null && nextGroup == null) newTablePosition = "none";
    else if (prevGroup == null) newTablePosition = "first";
    else if (nextGroup == null) newTablePosition = "last";

    setListModalProps({
      jobId,
      teamMemberId,
      payPeriod: selectedPeriod!,
      warnings: warnings,
      moveTo: (direction) => moveTo(newGroupIndex, params, editorType, tablePosition, direction),
      tablePosition: newTablePosition,
      onHide: () => {
        setListModalProps(undefined);
      },
      earnings: earnings || [],
      timesheetSections: timesheetSections || [],
      refreshTimesheets: getHours,
    });
  };

  const columns: ColumnConfig<PayPeriodHoursRow>[] = useMemo(() => {
    const baseColumns: ColumnConfig<PayPeriodHoursRow>[] = [
      {
        headerName: "Team member",
        field: "team_member.full_name",
        maxWidth: 180,
        filter: true,
        enableRowGroup: true,
        initialRowGroup: true,
        hide: true,
        pinned: "left" as const,
        enablePivot: true,
        useValueFormatterForExport: true,
        valueGetter: (params) => {
          if (!params.data?.team_member) return null;

          const name = shorten(params.data?.team_member?.full_name || "", 20);
          const friendlyId = params.data?.team_member?.friendly_id || "";

          return `${name} (${friendlyId})`;
        },
      },
      {
        field: "-",
        headerName: " ",
        cellClass: "less-padding",
        headerClass: "less-padding",
        pinned: "left" as const,
        maxWidth: convosEnabled ? 150 : 110,
        useValueFormatterForExport: true,
        cellRenderer: (params: ICellRendererParams<PayPeriodHoursRow>) => {
          if (!selectedPeriod || !selectedPayScheduleId) return null;
          let tmId: string | undefined,
            jobId: string | undefined,
            editorType: "single_job" | "single_tm" | undefined,
            _threadName: string | undefined;

          if (params.node.field === "team_member.full_name") {
            const tm = params.node.allLeafChildren[0]?.data?.team_member;
            tmId = tm?._id.toString();
            editorType = "single_tm";
            _threadName = `${tm?.full_name}'s hours for ${threadDateRangeString}`;
          } else if (params.node.field === "job") {
            jobId = params.node.allLeafChildren[0]?.data?.job?._id;
            editorType = "single_job";
            _threadName = `${jobNameFormatter(jobId)} hours for ${threadDateRangeString}`;
          } else if (params.node.field?.includes("job_hierarchy_ids")) {
            const indexString = params.node.field?.split(".")?.[1];
            if (!indexString) return null;

            const index = parseInt(indexString);
            if (isNaN(index)) return null;

            jobId = params.node.allLeafChildren[0]?.data?.job_hierarchy_ids?.[index];
            if (!jobId) return null;

            editorType = "single_job";
            _threadName = `${jobNameFormatter(jobId)} hours for ${threadDateRangeString}`;
          }

          // These are placeholders for the chat thread (used to be powered by Cord but they went out business)
          const threadLength = 0;
          const unreadMessages = 0;

          let chatIconColor: string, chatIconWeight: IconWeight;
          if (unreadMessages > 0) {
            chatIconColor = "#4d55bb";
            chatIconWeight = "duotone";
          } else if (threadLength > 0) {
            chatIconColor = "black";
            chatIconWeight = "bold";
          } else {
            chatIconColor = "gray";
            chatIconWeight = "regular";
          }

          return (
            <div className="flex">
              {editorType && (tmId || jobId) && (
                <div className="flex">
                  {convosEnabled && (
                    <Button
                      className="button-1 square-button"
                      text={
                        <Chat
                          style={{ marginLeft: 0, marginBottom: -2 }}
                          weight={chatIconWeight}
                          color={chatIconColor}
                        />
                      }
                      onClick={() => {
                        /* DISPLAY CHAT THREAD IN SLIDER HERE */
                      }}
                    />
                  )}

                  <Button
                    className="button-1 square-button"
                    text={<ListDashes style={{ marginLeft: 0, marginBottom: -2 }} />}
                    onClick={() => {
                      let initialTablePosition: "first" | "last" | "middle" | "none" = "middle";
                      if (
                        !params.node.parent?.childrenAfterSort ||
                        params.node.parent.childrenAfterSort.length === 1
                      ) {
                        initialTablePosition = "none";
                      } else if (params.rowIndex === 0) {
                        initialTablePosition = "first";
                      } else if (params.rowIndex === params.node.parent.childrenAfterSort.length - 1) {
                        initialTablePosition = "last";
                      }

                      setListModalProps({
                        jobId: jobId,
                        teamMemberId: tmId,
                        payPeriod: selectedPeriod,
                        warnings: warnings,
                        moveTo: (direction) =>
                          moveTo(params.rowIndex, params, editorType, initialTablePosition, direction),
                        tablePosition: initialTablePosition,
                        onHide: () => {
                          setListModalProps(undefined);
                        },
                        earnings: earnings || [],
                        timesheetSections: timesheetSections || [],
                        refreshTimesheets: getHours,
                      });
                    }}
                  />

                  <Button
                    className="button-1 square-button"
                    text={<Pencil style={{ marginLeft: 0, marginBottom: -2 }} />}
                    onClick={() => {
                      setPayPeriodEditorProps({
                        tmId,
                        ...selectedPeriod,
                        editorType,
                        jobId,
                        payScheduleId: selectedPayScheduleId,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerName: "First name",
        field: "tmFirst",
        width: 100,
        filter: true,
        filterField: "tmFirst",
        enableRowGroup: true,
        useValueFormatterForExport: true,
        valueGetter: (params) => {
          if (params.node?.field !== "team_member.full_name" || !selectedPeriod) return null;
          return params.data?.team_member?.first_name;
        },
        hide: true,
      },
      {
        headerName: "Last name",
        field: "tmLast",
        width: 100,
        filter: true,
        enableRowGroup: true,
        useValueFormatterForExport: true,
        valueGetter: (params) => {
          if (params.node?.field !== "team_member.full_name" || !selectedPeriod) return null;
          return params.data?.team_member?.last_name;
        },
        hide: true,
      },
      {
        headerName: "Warnings",
        field: "warnings",
        dataType: "boolean",
        useValueFormatterForExport: true,
        hide: true,
        suppressColumnsToolPanel: true,
        valueGetter: (params) => {
          // Get the team member id from the data or the child node if it's a group row
          const teamMember = params.data?.team_member || params.node?.allLeafChildren?.[0]?.data?.team_member;

          if (!teamMember?._id) return false;
          return !!warningsMap[teamMember._id]?.length;
        },
      },
      {
        field: "wc_code.label",
        headerName: "Workers Comp Code",
        cellClass: "less-padding",
        headerClass: "less-padding",
        dataType: "string" as const,
        minWidth: 70,
        useValueFormatterForExport: true,
        hide: true,
      },
      {
        headerName: "Department",
        field: "department",
        minWidth: 150,
        dataType: "string" as const,
        useValueFormatterForExport: true,
        maxWidth: 210,
        enableRowGroup: true,
        initialHide: true,
        valueFormatter: (params) => {
          const data = params.node?.allLeafChildren?.[0]?.data;
          if (data?.department) {
            return data?.department?.name;
          } else if (params.data?.department) {
            return params.data?.department?.name;
          }
          return "-";
        },
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params) => {
            const department = lookupDepartment(params?.value);
            return department?.name || "-";
          },
        },
      },
      {
        headerName: "Pay type",
        field: "team_member.pay_type",
        maxWidth: 180,
        filter: true,
        hide: true,
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          return capitalize(params.data?.team_member?.pay_type || "");
        },
      },
      ...(crews.length > 0
        ? [
            {
              headerName: "Crews",
              field: "crews",
              initialHide: true,
              filter: "agSetColumnFilter",
              valueFormatter: (params: ValueFormatterParams) => {
                if (params.node?.field !== "team_member.full_name") return "-";
                const teamMemberId = params.node?.allLeafChildren?.[0]?.data?.team_member?._id;
                const teamMemberCrews = lookupTeamMemberCrews(teamMemberId);
                return teamMemberCrews?.map((crew) => crew.name).join(", ") || "-";
              },
              useValueFormatterForExport: true,
              filterParams: {
                suppressAndOrCondition: true,
                buttons: ["apply", "reset"],
                values: crews,
                valueFormatter: (params: ValueFormatterParams) => {
                  return params.value?.name;
                },
                keyCreator: (option: KeyCreatorParams<Crew>) => {
                  return option.value?.name;
                },
              },
            },
          ]
        : []),
      {
        headerName: "Job supervisors",
        field: "job.supervisors",
        initialHide: true,
        filter: "agSetColumnFilter",
        useValueFormatterForExport: true,
        valueFormatter: (params: ValueFormatterParams<PayPeriodHoursRow>) => {
          const jobId =
            params.node?.field === "job"
              ? params.node?.allLeafChildren[0]?.data?.job?._id
              : params.data?.job?._id;

          const job = lookupJob(jobId);
          return job?.supervisors?.map((s) => s.full_name).join(", ") || "-";
        },

        filterParams: {
          suppressAndOrCondition: true,
          buttons: ["apply", "reset"],
          values: teamMembers,
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value?.full_name;
          },
          keyCreator: (option: KeyCreatorParams<Crew>) => {
            return option.value?.full_name;
          },
        },
      },
      {
        headerName: "Status",
        field: "approval_status",
        minWidth: 150,
        filter: true,
        enableRowGroup: true,
        initialRowGroup: true,
        hide: true,
        valueGetter: (params) => {
          if (!params.data?.approval_status) return "N/A";
          return capitalize(params.data?.approval_status);
        },
      },

      {
        headerName: "Earning type",
        field: "earning_type_alias",
        enableRowGroup: true,
        initialRowGroup: true,
        hide: true,
        filter: "agSetColumnFilter",
        valueGetter: (params) => {
          if (params?.data?._id.includes("totals_row")) return "";
          if (!params.data) return "-";
          const [eType, pId] = params?.data?.earning_type_alias?.split(EARNING_TYPE_ALIAS_SPLITTER) || [];
          if (!eType) return "-";
          let label = timesheetsByPayPeriodEarnTypeLookup(params.data?.check_type, params.data?.miter_type);
          const policy = lookupTimeOffPolicy(pId);
          if (policy) label += ` (${policy.name})`;
          return label || "Auto";
        },
        maxWidth: 150,
      },
      {
        headerName: "Job",
        field: "job",
        minWidth: 150,
        filter: true,
        enableRowGroup: true,
        hide: true,
        initialRowGroup: !subJobsConfig.enabled,
        valueGetter: (params) => {
          return jobNameFormatter(params.data?.job?._id);
        },
      },
      {
        headerName: "Activity",
        field: "activity",
        minWidth: 150,
        filter: true,
        enableRowGroup: true,
        hide: true,
        initialRowGroup: true,
        useValueFormatterForExport: true,
        valueGetter: (params) => {
          return activityLabelFormatter(params.data?.activity?._id);
        },
      },
      ...dayColDefs,
      {
        field: "no_date",
        aggFunc: "sumValues",
        valueFormatter: hoursFormatter,
        headerName: "No date",
        cellClass: "day-header",
        headerClass: "day-header",
        dataType: "number" as const,
        minWidth: 105,
        sumRow: true,
        useValueFormatterForExport: true,
      },
      {
        field: "hours",
        aggFunc: "sumValues",
        valueFormatter: hoursFormatter,
        headerName: "Total",
        cellClass: "less-padding",
        headerClass: "less-padding",
        pinned: "right" as const,
        dataType: "number" as const,
        minWidth: 70,
        sumRow: true,
        useValueFormatterForExport: true,
      },
    ];
    return baseColumns.concat(jobHierarchyTableColumns);
  }, [dayColDefs, crews, lookupTeamMemberCrews, departments, lookupTimeOffPolicy, jobHierarchyTableColumns]);

  const canApproveOrUnapproveRow = (params: CheckboxSelectionCallbackParams<PayPeriodHoursRow>) => {
    if (!can("timesheets:others:approve") && !can("timesheets:personal:approve")) return false;

    const allLeafChildren = params.node.allLeafChildren || params.node.parent?.allLeafChildren;
    const data = allLeafChildren?.map((child) => child.data);
    const someApprovedOrUnapproved = data?.some((d) => {
      if (d && (d.approval_status === "unapproved" || d.approval_status === "approved")) return true;
    });
    return someApprovedOrUnapproved;
  };

  const payPeriodTms = useMemo(() => {
    if (!selectedPeriod) return [];
    return teamMembers.filter((tm) => {
      const isActive = !tm.archived;

      const isInPaySchedule = tm.pay_schedule_id === selectedPayScheduleId;

      const isInPayPeriod =
        selectedPeriod.periodStart &&
        selectedPeriod.periodEnd &&
        (!tm.start_date || tm.start_date <= selectedPeriod.periodEnd) &&
        (!tm.end_date || tm.end_date >= selectedPeriod.periodStart);

      const isViewableByUser =
        timesheetAbilities.teamPredicate("read")(tm) || timeOffRequestAbilities.teamPredicate("read")(tm);

      return isActive && isInPaySchedule && isInPayPeriod && isViewableByUser;
    });
  }, [
    teamMembers,
    selectedPeriod,
    can,
    activeUser,
    selectedPayScheduleId,
    timesheetAbilities.teamPredicate,
    timeOffRequestAbilities.teamPredicate,
  ]);

  const canAccessEarning = useCallback(
    (earning: AggregatedMiterEarning) => {
      const teamMember = lookupTeam(earning.team_member._id);
      if (!teamMember) return false;

      // We are using teamPredicate instead of .can because we don't have the full timesheet/time off objects so this is a neat work around

      // If the earning is a time off or holiday, we need to check the time off request abilities
      if (earning.miter_type === "time_off" || earning.miter_type === "holiday") {
        return timeOffRequestAbilities.teamPredicate("read")(teamMember);
        // All other earnings will fall under timesheet abilities
      } else {
        return timesheetAbilities.can("read", earning);
      }
    },
    [timesheetAbilities.teamPredicate, timeOffRequestAbilities.teamPredicate, lookupTeam]
  );

  const data = useMemo(() => {
    if (!earnings) return;
    const tmsWithTimesheets = new Set<string>();
    const jobsWithTimesheets = new Set<string>();
    const tableDataMap = earnings.reduce((map, e) => {
      if (!e.hours) return map;

      const hasAccessToEarning = canAccessEarning(e);
      if (!hasAccessToEarning) return map;

      const zone = activeCompany?.timezone;
      const dateString = e.date ? DateTime.fromISO(e.date, { zone }).toISODate() : "";
      const key =
        e.team_member._id +
        e.job?._id +
        e.activity?._id +
        e.approval_status +
        dateString +
        e.check_type +
        e.miter_type;
      const dayColumnString = e.date ? `${DAY_COL_PREFIX}${dateString}` : "no_date";
      const crews = lookupTeamMemberCrews(e.team_member._id);

      const item: PayPeriodHoursRow = map.get(key) || {
        _id: key,
        team_member: e.team_member,
        job: lookupJob(e.job?._id),
        approval_status: e.approval_status,
        activity: e.activity,
        department: e.department,
        crews: crews,
        check_type: e.check_type,
        miter_type: e.miter_type,
        wc_code: e.wc_code,
        hours: 0,
        timesheet_ids: [],
        time_off_request_ids: [],
        earning_type_alias: `${e.check_type}${EARNING_TYPE_ALIAS_SPLITTER}${e.time_off_policy_id}`,
        earningIds: [],
        job_hierarchy_ids: e.job_hierarchy_ids || [],
        [dayColumnString]: 0,
      };
      item.hours = (item.hours || 0) + (e.hours || 0);
      tmsWithTimesheets.add(e.team_member._id);
      if (e.job) jobsWithTimesheets.add(e.job._id);
      if (e.timesheet) item.timesheet_ids.push(e.timesheet);
      if (e.time_off_request) item.time_off_request_ids.push(e.time_off_request);
      item[dayColumnString] += e.hours;
      item.earningIds.push(e._id);
      return map.set(key, item);
    }, new Map<string, PayPeriodHoursRow>());

    const tableData = Array.from(tableDataMap.values());
    payPeriodTms?.forEach((tm) => {
      if (tmsWithTimesheets.has(tm._id)) return;
      tableData.push({
        _id: tm._id,
        team_member: tm,
        crews: lookupTeamMemberCrews(tm._id),
        timesheet_ids: [],
        time_off_request_ids: [],
        earningIds: [],
        job_hierarchy_ids: [],
        department: tm.department,
      });
    });

    jobs?.forEach((job) => {
      if (jobsWithTimesheets.has(job._id)) return;
      tableData.push({
        _id: job._id,
        job: job,
        timesheet_ids: [],
        time_off_request_ids: [],
        earningIds: [],
        job_hierarchy_ids: getJobHierarchy(job._id),
      });
    });
    setLoading(false);
    return tableData;
  }, [earnings, payPeriodTms, lookupJob, canAccessEarning]);

  const getHours = async () => {
    if (!selectedPeriod || !activeCompany || !selectedPayScheduleId) return;
    setLoading(true);
    try {
      const res = await MiterAPI.payrolls.pay_period_mock_earnings({
        companyId: activeCompany?._id,
        payScheduleId: selectedPayScheduleId,
        periodStart: selectedPeriod?.periodStart,
        periodEnd: selectedPeriod?.periodEnd,
        tmIds: payPeriodTms.map((tm) => tm._id),
        timesheetStatuses: ["approved", "unapproved", "processing", "paid"],
        timeOffRequestStatuses: ["approved", "unapproved", "processing", "paid"],
        includeNonPayrollTeamMembers: true,
      });
      if (res.error) throw new Error(res.error);

      setEarnings(res.earnings);
      setWarnings(res.warnings);
      setTimesheetSections(res.tsSections);
      setReApplyReportView(true);
      setTimeout(() => {
        setReApplyReportView(false);
      }, 100);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error("Error getting hours:", e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getHours();
  }, [selectedPeriod]);

  const renderRangeSelector = () => {
    return (
      <PayPeriodSelector
        setPeriod={(newPeriod) => {
          setSelectedPeriod(newPeriod);
        }}
        selectedPayScheduleId={selectedPayScheduleId}
        setSelectedPayScheduleId={(newSelected) => {
          setSelectedPayScheduleId(newSelected);
        }}
      />
    );
  };

  const handleStatusChange = async (type: "approve" | "unapprove") => {
    const setLoadingFunc = type === "approve" ? setApproving : setUnapproving;
    setLoadingFunc(true);
    try {
      const timesheetIds = new Set<string>();
      const timeOffRequestIds = new Set<string>();
      selectedRows.forEach((r) => {
        if (type === "approve" && r.approval_status !== "unapproved") return;
        if (type === "unapprove" && r.approval_status !== "approved") return;

        r.timesheet_ids.forEach((id) => {
          if (!timesheetAbilities.can("approve", r)) return;
          timesheetIds.add(id);
        });

        r.time_off_request_ids.forEach((id) => {
          if (!timeOffRequestAbilities.can("approve", r)) return;
          timeOffRequestIds.add(id);
        });
      });

      if (timesheetIds.size || timeOffRequestIds.size) {
        const tsFunc = type === "approve" ? MiterAPI.timesheets.approve : MiterAPI.timesheets.unapprove;
        const [tsResponse, torResponse] = await Promise.all([
          timesheetIds.size ? tsFunc({ ids: Array.from(timesheetIds) }) : null,
          timeOffRequestIds.size
            ? MiterAPI.time_off.requests.update_multiple({
                ids: Array.from(timeOffRequestIds),
                update: {
                  status:
                    type === "approve"
                      ? ("approved" as TimeOffRequestStatus)
                      : ("unapproved" as TimeOffRequestStatus),
                },
              })
            : null,
        ]);

        if (tsResponse?.error) throw new Error(tsResponse?.error);
        if (torResponse?.errors.length)
          throw new Error(`Error approving time off requests. Please contact support.`);
      }
      Notifier.success(`Successfully ${type === "unapprove" ? "un" : ""}approved hours.`);
      getHours();
      setSelectedRows([]);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(`${e.message}`);
    }
    setLoadingFunc(false);
  };

  const exportButton = {
    label: "Export",
    className: "button-1",
    action: () => setShowExportModal(true),
    important: true,
    icon: <Export weight="bold" style={{ marginRight: 3 }} />,
  };

  const dynamicActions = useMemo(() => {
    if (!selectedRows.length) return [];

    const timesheetRows = selectedRows.filter((r) => r.timesheet_ids?.length);
    const timeOffRequestRows = selectedRows.filter((r) => r.time_off_request_ids.length);

    const canApproveTimesheets = timesheetRows.some((r) => {
      return r.approval_status === "unapproved" && timesheetAbilities.can("approve", selectedRows);
    });

    const canUnapproveTimesheets = timesheetRows.some(
      (r) => r.approval_status === "approved" && timesheetAbilities.can("approve", selectedRows)
    );

    const canApproveTimeOffRequests = timeOffRequestRows.some((r) => {
      return r.approval_status === "unapproved" && timeOffRequestAbilities.can("approve", selectedRows);
    });

    const canUnapproveTimeOffRequests = timeOffRequestRows.some(
      (r) => r.approval_status === "approved" && timeOffRequestAbilities.can("approve", selectedRows)
    );

    return [
      exportButton,

      {
        label: "Unapprove",
        className: "button-3 table-button",
        loading: unapproving,
        action: () => handleStatusChange("unapprove"),
        icon: <X weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => canUnapproveTimesheets || canUnapproveTimeOffRequests,
      },
      {
        label: "Approve",
        className: "button-2 table-button",
        loading: approving,
        action: () => handleStatusChange("approve"),
        icon: <Check weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => canApproveTimesheets || canApproveTimeOffRequests,
      },
    ];
  }, [selectedRows, approving, unapproving, timesheetAbilities.can, timeOffRequestAbilities.can]);

  const staticActions = useMemo(() => {
    return [exportButton];
  }, []);

  const renderPayPeriodTable = () => {
    return (
      <TableV2
        id={"pay-period-timesheets"}
        resource="hours"
        data={data}
        columns={columns}
        staticActions={staticActions}
        renderLeftActionBar={renderRangeSelector}
        groupHideOpenParents={false}
        groupDefaultExpanded={0}
        dynamicActions={dynamicActions}
        showReportViews={true}
        reApplyReportView={reApplyReportView}
        hideSelectColumn={true}
        groupAggFiltering={true}
        onSelect={setSelectedRows}
        showMiniSearch={true}
        groupDisplayType="singleColumn"
        showTotals={true}
        groupRowRendererParams={{ suppressCount: true }}
        isLoading={loading}
        disablePagination={true}
        onFilter={setFilteredRows}
        showExpandAll={true}
        autoGroupColumnDef={{
          pinned: "left",
          width: 210,
          maxWidth: 350,
          filter: "agGroupColumnFilter",
          cellStyle: { overflow: "hidden" },
          checkboxSelection: canApproveOrUnapproveRow,
          useValueFormatterForExport: true,

          cellRendererParams: {
            suppressCount: true,
            innerRenderer: (params) => {
              if (params.node?.field !== "team_member.full_name") {
                if (params.value) {
                  return params.value;
                } else {
                  if (params.node?.field === "activity") {
                    return "No activity";
                  } else if (params.node?.field === "job") {
                    return "No job";
                  } else {
                    return "-";
                  }
                }
              }

              const tm = params.node.allLeafChildren[0]?.data?.team_member;
              if (!tm) return params.value;
              const tmId = tm._id.toString();

              const warnings = warningsMap[tmId];
              if (!warnings?.length) return params.value;

              return (
                <div className="flex">
                  <div className={"yellow" + " dot"} style={{ marginRight: 8 }} />
                  {params.value}
                </div>
              );
            },
          },

          tooltipValueGetter: (params: ITooltipParams<PayPeriodHoursRow>) => {
            if (params.node?.field !== "team_member.full_name") return [];

            const tm = params.node.allLeafChildren[0]?.data?.team_member;
            if (!tm) return [];
            const tmId = tm._id.toString();

            const warnings = warningsMap[tmId];
            if (!warnings?.length) return [];

            return warnings;
          },
          tooltipComponent: (params) => (
            <TimesheetWarningsTooltip
              warnings={params.value}
              style={{
                background: "#fff",
                border: "1px solid #eee",
                borderRadius: 4,
                width: 500,
              }}
            />
          ),
        }}
        gridOptions={{
          onFirstDataRendered: (e) => {
            e.columnApi.autoSizeAllColumns();
          },
          onGridSizeChanged: (e) => {
            e.columnApi.autoSizeAllColumns();
          },
        }}
        defaultCsvExportParams={{ skipRowGroups: false }}
        defaultExcelExportParams={{ skipRowGroups: false }}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
      />
    );
  };

  const renderSectionsTable = () => {
    return (
      <TimesheetSectionsTable
        timesheetSections={timesheetSections || []}
        loading={loading}
        earnings={earnings || []}
        payPeriodSelector={renderRangeSelector}
        refreshTimesheets={getHours}
      />
    );
  };

  return (
    <div>
      {payPeriodEditorProps && (
        <WorkweekEditorModal
          {...payPeriodEditorProps}
          onSave={async () => {
            await getHours();
            setPayPeriodEditorProps(undefined);
          }}
          onHide={() => setPayPeriodEditorProps(undefined)}
        />
      )}
      {listModalProps && (
        <TimesheetPayPeriodSectionsModal {...listModalProps} payPeriodTeamMembers={payPeriodTms} />
      )}
      {showExportModal && earnings && selectedPeriod && selectedPayScheduleId && (
        <PayPeriodHoursExportModal
          earnings={earnings}
          selectedIds={selectedRows.flatMap((r) => r.earningIds)}
          filteredIds={filteredRows.flatMap((r) => r.earningIds)}
          periodStart={selectedPeriod?.periodStart}
          payScheduleId={selectedPayScheduleId}
          periodEnd={selectedPeriod?.periodEnd}
          hide={() => setShowExportModal(false)}
        />
      )}
      {view === "pay_period" && renderPayPeriodTable()}
      {view === "sections" && renderSectionsTable()}
    </div>
  );
};
