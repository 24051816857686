import React, { useContext, useEffect, useMemo, useState } from "react";
import { MileageLocation } from "dashboard/miter";
import { Option } from "ui/form/Input";
import { Formblock } from "ui";
import {
  TaxableOptions,
  glAccountSelectionOptions,
  useGetDefaultReimbursementPayoutMethodOption,
  useGetReimbursementPayoutMethodOptions,
} from "../expenseUtils";
import { useExpenseReimbursementAbilities } from "dashboard/hooks/abilities-hooks/useExpenseReimbursementAbilities";
import {
  useDepartmentOptions,
  useJobOptions,
  useCostTypeOptions,
  useActivityOptions,
  useLedgerAccountOptions,
  useLookupTeam,
  useLookupExpenseReimbursementCategories,
  useActivityOptionsMap,
  useActiveCompanyId,
  useLookupDepartment,
  useExpenseReimbursementCategoryOptions,
  useIsSuperAdmin,
  useLookupActivity,
  useLookupCostType,
} from "dashboard/hooks/atom-hooks";
import { isReimbursementScoped } from "dashboard/pages/activities/activityUtils";
import { FilePickerFile } from "ui/form/FilePicker";
import { UseFormMethods } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { ExpenseReimbursementPayoutMethod } from "backend/models/expense-reimbursement";
import { ExpenseReimbursementOutOfPocketPurchaseDataFields } from "./ExpenseReimbursementOutOfPocketPurchaseDataFields";
import { ExpenseReimbursementMileageDataFields } from "./ExpenseReimbursementMileageDataFields";
import { withValue } from "dashboard/utils";
import { useTeamMemberOptionsEligibleForPayroll } from "dashboard/hooks/useTeamMembersEligibleForPayroll";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { useExpenseReimbursementPolicy } from "dashboard/utils/policies/expense-reimbursement-policy-utils";
import InfoButton from "dashboard/components/information/information";
import { JobInput } from "dashboard/components/shared/JobInput";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";
import { isCostTypeExpenseManagementScoped } from "dashboard/components/cost-types/costTypeUtils";

// max height before scrolling
const maxDropdownMenuHeight = 300;
// min height before flipping from below to above
const minHeightOfSelectDropdownBeforeRenderingAbove = 300;

export type ExpenseReimbursementModalFormData = {
  // all
  expense_reimbursement_category_id?: Option<string>;
  date: DateTime;
  // purchase data
  amount?: number;
  vendor_name?: string;
  memo?: string;

  // mileage
  mileage?: number;
  trip?: MileageLocation[];

  // team member
  team_member_id: Option<string>;
  department_id?: Option<string>;

  // job costing
  job_id?: Option<string>;
  activity_id?: Option<string>;
  cost_type_id?: Option<string>;
  expense_account_id?: Option<string>;
  attachments: FilePickerFile[];

  // approver_note: string; // TODO: change to be part of approval history
  // advanced
  payout_method: Option<ExpenseReimbursementPayoutMethod>;
  is_taxable: Option<boolean>;
};

export type EXPENSE_REIMBURSEMENT_EDIT_MODE = "all_fields" | "job_costing_and_payout" | "job_costing_only";

type Props = {
  form: UseFormMethods<ExpenseReimbursementModalFormData>;
  editingMode?: EXPENSE_REIMBURSEMENT_EDIT_MODE;
  cachedMileageRate?: number;
  mileageTrip?: MileageLocation[];
  isCreating?: boolean;
  inboxMode?: InboxMode;
};
export const ExpenseReimbursementModalForm: React.FC<Props> = ({
  form,
  editingMode,
  cachedMileageRate,
  mileageTrip,
  isCreating,
  inboxMode,
}) => {
  /*********************************************************
   *  Hooks
   **********************************************************/
  const isAdmin = useIsSuperAdmin();
  const { teamPredicate, jobPredicate } = useExpenseReimbursementAbilities();
  const { cannot: globalAbilitiesCannot } = useMiterAbilities();

  const lookupTeam = useLookupTeam();
  const lookupDepartment = useLookupDepartment();
  const lookupCategory = useLookupExpenseReimbursementCategories();
  const lookupActivity = useLookupActivity();
  const lookupCostType = useLookupCostType();
  const activeCompanyId = useActiveCompanyId();

  /*********************************************************
   *  Form
   **********************************************************/
  const { watch, setValue, register, errors, control } = form;
  const formData = watch();

  const { isFieldHidden, isFieldLocked, isFieldRequired } = useExpenseReimbursementPolicy(formData);

  // payroll will be undefined if this modal is open from a non payroll page
  const { payroll } = useContext(PayrollContext);

  /*********************************************************
   *  Formblock Dropdown Options
   **********************************************************/

  // if payroll, teamOptions should be all team members in the payroll, which will include terminated employees (ex. last paycheck situation). Otherwise this is just a wrapper for useTeamOptions
  const teamOptions = useTeamMemberOptionsEligibleForPayroll({
    payroll,
    predicate: teamPredicate(isCreating ? "create" : "update"),
  });

  const departmentOptions = useDepartmentOptions();

  const options = useMemo(() => {
    return glAccountSelectionOptions({
      activeCompanyId,
      departmentId: formData?.department_id?.value,
      lookupDepartment: lookupDepartment,
    });
  }, [formData]);

  const ledgerAccountOptions = useLedgerAccountOptions(options);

  const jobOptions = useJobOptions({
    predicate: inboxMode ? undefined : jobPredicate(isCreating ? "create" : "update"),
  });
  const categoryOptions = useExpenseReimbursementCategoryOptions();

  const activityOptions = useActivityOptions(formData.job_id?.value, {
    predicate: isReimbursementScoped,
  });
  // only need this for categories logic to prevent a race condition
  const activityOptionsMap = useActivityOptionsMap({
    predicate: isReimbursementScoped,
  });
  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });

  const getReimbursementPayoutMethodOptions = useGetReimbursementPayoutMethodOptions();
  const getDefaultReimbursementPayoutMethodOption = useGetDefaultReimbursementPayoutMethodOption();

  const payoutMethodOptions = useMemo(() => {
    // recalculate payout method options if team member changes
    return getReimbursementPayoutMethodOptions(formData.team_member_id?.value);
  }, [getReimbursementPayoutMethodOptions, formData.team_member_id?.value]);
  /*********************************************************
   *  State
   **********************************************************/
  const [mileageRate, setMileageRate] = useState<number | null | undefined>();

  /*********************************************************
   *  useEffects - automations
   **********************************************************/

  useEffect(() => {
    setMileageRate(cachedMileageRate);
  }, [cachedMileageRate]);

  // this handles gating whether taxable is defaulted to false or not.
  useEffect(() => {
    if (formData.payout_method?.value === "ach") {
      setValue("is_taxable", TaxableOptions.find(withValue(false)));
    }
  }, [formData.payout_method?.value]);

  // initialize default category values
  useEffect(() => {
    if (formData.expense_reimbursement_category_id?.value) {
      handleCategoryChange(formData.expense_reimbursement_category_id);
    }
  }, []);

  const handleCategoryChange = (option: Option<string>) => {
    const newCategoryId = option?.value;
    const newCategory = lookupCategory(newCategoryId);

    const categoryAmount = newCategory?.amount?.value;
    const categoryMerchantName = newCategory?.merchant_name?.id;
    const categoryJobId = newCategory?.job?.id;
    const categoryActivityId = newCategory?.activity?.id;
    const categoryCostTypeId = newCategory?.cost_type?.id;
    const categoryExpenseAccountId = newCategory?.expense_account?.id;

    setMileageRate(newCategory?.mileage_rate);

    /*
     * there is a race condition where the Formblocks for
     * mileage, amount, vendor_name are not rendered when these setValues are called.
     * Wait two animation frames before setting the values (waiting for one didn't work, two always works)
     */
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setValue("mileage", undefined);
        setValue("trip", undefined);
        if (categoryAmount) {
          setValue("amount", categoryAmount || undefined);
        }
        if (categoryMerchantName) {
          setValue("vendor_name", categoryMerchantName);
        }
      });
    });

    setValue("job_id", jobOptions.find(withValue(categoryJobId)));

    // need to do this because of race condition - activityOptions is not populated yet
    const activityOptionsBasedOnCategoryJob = activityOptionsMap.get(categoryJobId || null);
    setValue("activity_id", activityOptionsBasedOnCategoryJob.find(withValue(categoryActivityId)));
    setValue("cost_type_id", costTypeOptions.find(withValue(categoryCostTypeId)));
    setValue("expense_account_id", ledgerAccountOptions.find(withValue(categoryExpenseAccountId)));

    // is_taxable can only be set from the category. If removing the category, set is_taxable to false.
    setValue("is_taxable", TaxableOptions.find(withValue(newCategory?.is_taxable || false)));

    if (newCategory?.payout_method) {
      // check if the payment method is valid for this team member
      const optionFromCategory = payoutMethodOptions.find(withValue(newCategory?.payout_method));
      const isPayoutMethodValid = !optionFromCategory?.isDisabled;

      if (isPayoutMethodValid) {
        setValue("payout_method", optionFromCategory);
      }
    }
  };

  const disableIsTaxable = formData.payout_method?.value === "ach" || !isAdmin;

  return (
    // set minWidth for two column edit/view mode
    <div style={{ width: "400px" }}>
      <div className="form-section">
        <Formblock
          type="select"
          name="expense_reimbursement_category_id"
          className="modal"
          label={"Category"}
          options={categoryOptions}
          requiredSelect={false}
          noOptionsMessage={"No categories found."}
          control={control}
          onChange={handleCategoryChange}
          editing={editingMode === "all_fields"}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          isClearable
          defaultValue={formData.expense_reimbursement_category_id?.value}
        />
        <Formblock
          label="Date*"
          labelInfo={"Date this expense or trip took place."}
          type="datetime"
          dateOnly={true}
          name="date"
          className="modal"
          rules={vals.required}
          form={form}
          editing={editingMode === "all_fields"}
          customFormat="MMM dd, yyyy"
          defaultValue={formData.date}
        />
      </div>
      <div className="form-section">
        {/* If mileage category selected, show mileage fields. Else, show purchase data fields. */}
        {mileageRate ? (
          <ExpenseReimbursementMileageDataFields
            editingMode={editingMode}
            mileageRate={mileageRate}
            mileageTrip={mileageTrip}
            form={form}
          />
        ) : (
          <ExpenseReimbursementOutOfPocketPurchaseDataFields editingMode={editingMode} form={form} />
        )}
      </div>
      <Formblock
        type="paragraph"
        name="memo"
        label={"Memo" + (isFieldRequired("memo") ? "*" : "")}
        className="modal time-off-request-notes"
        editing={editingMode === "all_fields"}
        placeholder={"Memo"}
        style={{ marginTop: "15px" }}
        control={control}
        errors={errors}
        val={isFieldRequired("memo") ? vals.required : undefined}
        register={register}
        hidden={isFieldHidden("memo")}
        defaultValue={formData.memo}
      />
      <h4>Team Member</h4>
      <div>
        <Formblock
          label="Team member*"
          labelInfo={"Select the team member this reimbursement is for."}
          type="select"
          name="team_member_id"
          className="modal"
          control={control}
          errors={errors}
          options={teamOptions}
          onChange={(option: Option<string>) => {
            // update the department if the team member changes
            const teamMember = lookupTeam(option?.value);
            setValue("department_id", departmentOptions.find(withValue(teamMember?.department_id)));

            const newEligiblePayoutMethods = getReimbursementPayoutMethodOptions(option?.value).filter(
              (option) => !option.isDisabled
            );

            const currentCategoryPayoutMethod = lookupCategory(
              formData.expense_reimbursement_category_id?.value
            )?.payout_method;

            // business logic:
            // reset to the category's if eligible, otherwise default
            if (newEligiblePayoutMethods.find(withValue(currentCategoryPayoutMethod))) {
              setValue(
                "payout_method",
                newEligiblePayoutMethods.find(withValue(currentCategoryPayoutMethod))
              );
            } else {
              setValue("payout_method", getDefaultReimbursementPayoutMethodOption(option?.value));
            }
          }}
          requiredSelect={true}
          noOptionsMessage={"No team members found."}
          editing={editingMode === "all_fields"}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          isClearable
          defaultValue={formData.team_member_id?.value}
          disabled={globalAbilitiesCannot("reimbursements:others:update")}
        />
        <Formblock
          label="Department"
          labelInfo={"Select the department associated with this reimbursement."}
          type="select"
          name="department_id"
          className="modal"
          control={control}
          options={departmentOptions}
          requiredSelect={false}
          noOptionsMessage={"No departments found."}
          editing={editingMode != null} // department is also considered a job costing field
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          isClearable
          defaultValue={formData.department_id?.value}
        />
        <h4>Job Costing</h4>
        <JobInput
          label={"Job" + (isFieldRequired("job_id") ? "*" : "")}
          labelInfo={"Select the job associated with this purchase."}
          type="select"
          name="job_id"
          className="modal"
          errors={errors}
          form={form}
          control={control}
          options={jobOptions}
          onChange={(option: Option<string>) => {
            const newJobId = option?.value;
            const activityOptionsBasedOnNewJob = activityOptionsMap.get(newJobId);

            // if the current activity is not in the new job, reset it
            if (!activityOptionsBasedOnNewJob.find(withValue(formData.activity_id?.value))) {
              setValue("activity_id", null);
            }
          }}
          noOptionsMessage={"No jobs found."}
          editing={editingMode != null}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          isClearable
          requiredSelect={isFieldRequired("job_id")}
          disabled={isFieldLocked("job_id")}
          hidden={isFieldHidden("job_id")}
          defaultValue={formData.job_id?.value}
        />
        <Formblock
          label={"Activity" + (isFieldRequired("activity_id") ? "*" : "")}
          labelInfo={"Select the activity associated with this purchase."}
          type="select"
          name="activity_id"
          className="modal"
          errors={errors}
          control={control}
          options={activityOptions}
          noOptionsMessage={"Please select a job first."}
          editing={editingMode != null}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          isClearable
          requiredSelect={isFieldRequired("activity_id")}
          disabled={isFieldLocked("activity_id")}
          hidden={isFieldHidden("activity_id")}
          defaultValue={formData.activity_id?.value}
          onChange={(option: Option<string>) => {
            const newActivityId = option?.value;
            const previousActivityId = formData.activity_id?.value;

            // if activity has a default cost type, select it
            const activity = lookupActivity(newActivityId);
            const previousActivity = lookupActivity(previousActivityId);

            if (activity?.default_cost_type_id) {
              setValue("cost_type_id", costTypeOptions.find(withValue(activity.default_cost_type_id)));
            } else if (
              formData.cost_type_id?.value &&
              formData.cost_type_id.value === previousActivity?.default_cost_type_id
            ) {
              // if unsetting or changing the activity, unset the cost type
              setValue("cost_type_id", null);
            }
          }}
        />
        <Formblock
          label={"Cost type" + (isFieldRequired("cost_type_id") ? "*" : "")}
          labelInfo={"Select the cost type associated with this purchase."}
          type="select"
          name="cost_type_id"
          className="modal"
          errors={errors}
          control={control}
          options={costTypeOptions}
          editing={editingMode != null}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          isClearable
          disabled={isFieldLocked("cost_type_id")}
          hidden={isFieldHidden("cost_type_id")}
          requiredSelect={isFieldRequired("cost_type_id")}
          defaultValue={formData.cost_type_id?.value}
          onChange={(option: Option<string>) => {
            const newCostTypeId = option?.value;
            const previousCostTypeId = formData.cost_type_id?.value;

            // if cost type has an associated gl account, select it
            const newCostType = lookupCostType(newCostTypeId);
            const previousCostType = lookupCostType(previousCostTypeId);

            if (newCostType?.ledger_account_id) {
              setValue(
                "expense_account_id",
                ledgerAccountOptions.find(withValue(newCostType.ledger_account_id))
              );
            } else if (
              formData.expense_account_id?.value &&
              formData.expense_account_id.value === previousCostType?.ledger_account_id
            ) {
              // if unsetting or changing the cost type, unset the expense account
              setValue("expense_account_id", null);
            }
          }}
        />
        {!isFieldHidden("expense_account_id") && (
          <Formblock
            label={"GL account override" + (isFieldRequired("expense_account_id") ? "*" : "")}
            labelInfo={
              "Select the expense account this purchase should be posted to. It will override the default from your ledger mapping."
            }
            type="select"
            name="expense_account_id"
            className="modal"
            errors={errors}
            control={control}
            options={ledgerAccountOptions}
            editing={editingMode != null}
            maxMenuHeight={maxDropdownMenuHeight}
            minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
            isClearable
            requiredSelect={!isFieldHidden("expense_account_id") && isFieldRequired("expense_account_id")}
            disabled={isFieldLocked("expense_account_id")}
            hidden={isFieldHidden("expense_account_id")}
            defaultValue={formData.expense_account_id?.value}
          />
        )}
        <h4 className="flex">
          Advanced Settings
          <InfoButton text={"These can only be updated by an admin."} />
        </h4>
        <Formblock
          label="Payout Method"
          type="select"
          name="payout_method"
          className="modal"
          errors={errors}
          form={form}
          control={control}
          register={register}
          options={payoutMethodOptions}
          requiredSelect={true}
          editing={editingMode === "all_fields" || editingMode === "job_costing_and_payout"}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          defaultValue={formData.payout_method?.value}
          disabled={!isAdmin}
        />
        <Formblock
          label="Taxable*"
          labelInfo={
            formData.payout_method?.value === "ach"
              ? "ACH reimbursements to team members are always non-taxable."
              : undefined
          }
          type="select"
          name="is_taxable"
          className="modal"
          options={TaxableOptions}
          requiredSelect={true}
          editing={editingMode === "all_fields" || editingMode === "job_costing_and_payout"}
          form={form}
          val={vals.required}
          errors={errors}
          control={control}
          defaultValue={formData.is_taxable?.value}
          disabled={disableIsTaxable}
        />
      </div>
    </div>
  );
};
