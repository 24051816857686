import React, { useMemo } from "react";
import { TableV2, usdString } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { GetCompanyBillingInfoResponse, MiterBillingItem } from "dashboard/miter";
import { capitalize } from "lodash";

const billingItemRateTypeLabelLookup: Record<string, string> = {
  per_active_tm: " per active user",
  per_paid_tm: " per paid user",
  per_expense_tm: " per expense user",
  per_timesheet_tm: " per timesheet user",
  per_benefits_enrolled_tm: " per benefits enrolled user",
  per_401k_enrolled_tm: " per 401k-enrolled user",
  per_health_enrolled_tm: " per health-enrolled user",
  manual_qty: "",
  per_active_tm_legacy: " per active user (legacy)",
  per_timesheet_only_tm: " per timesheet-only user",
};

type Props = {
  info: GetCompanyBillingInfoResponse;
};

export const SubscriptionInfo: React.FC<Props> = ({ info }) => {
  const columns: ColumnConfig<MiterBillingItem>[] = [
    { field: "label", headerName: "Item" },
    {
      field: "monthly_rate",
      headerName: "Rate",
      minWidth: 275,
      valueFormatter: (params) => {
        const usd = usdString(params.value as number);
        const rateTypeString = billingItemRateTypeLabelLookup[params.data?.rate_type || ""] || "";
        return `${usd}${rateTypeString} per month`;
      },
    },
    {
      field: "billing_frequency",
      headerName: "Billing cadence",
      valueFormatter: (params) => capitalize(params.value),
    },
    { field: "minimum_quantity", headerName: "Min. qty", maxWidth: 100 },
    { field: "start", headerName: "Start", dataType: "date", maxWidth: 125 },
    { field: "end", headerName: "End", dataType: "date", maxWidth: 125 },
  ];

  const tableEntries = useMemo(() => {
    return info.miterBillingItems;
  }, [info]);

  return (
    <div className="billing-card-wrapper">
      <div>
        <div style={{ fontWeight: 600, fontSize: 18 }}>Your subscription</div>
        <TableV2
          data={tableEntries}
          columns={columns}
          hideSecondaryActions={true}
          id={"subscription-table"}
          resource={"subscriptions"}
          hideFooter={true}
          hideSearch={true}
          containerStyle={{ paddingBottom: 0 }}
        />
      </div>
    </div>
  );
};
