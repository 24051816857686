import { useActiveAccount, useActiveCompany, useActiveCompanyId, useIsMiterAdmin } from "./hooks/atom-hooks";
import {
  ALIVE_SOLAR_ROOFING_COMPANY_ID,
  AMERICAN_ROOFING_METAL_COMPANY_ID,
  ANCHOR_DESIGN_BUILD_GROUP_COMPANY_ID,
  BECKETT_ELECTRICAL_COMPANY_ID,
  BERGER_COMPANY_ID,
  BILBRO_COMPANY_ID,
  BLAIR_FIRE_COMPANY_ID,
  CJ_HANSON_COMPANY_ID,
  CVE_DEMO_COMPANY_ID,
  CVE_FRESNO_COMPANY_ID,
  CVE_NORTH_BAY_COMPANY_ID,
  DEBLOIS_ELECTRIC_ID,
  DEMO_COMPANY_ID,
  EN_TEST_COMPANY,
  FACILITY_SITE_COMPANY_ID,
  HAYS_ELECTRICAL_COMPANY_ID,
  HILLHOUSE_COMPANY_ID,
  KAUFMAN_LYNN_COMPANY_ID,
  LONG_DOORS_COMPANY_ID,
  MARATHON_ELECTRICAL_ID,
  MARKSMEN_GC_COMPANY_ID,
  MAXIM_CONCRETE_ID,
  MITER_COMPANY_ID,
  MUSE_CONCRETE_COMPANY_ID,
  ONYX_COMPANY_ID,
  PALACE_CONSTRUCTION_COMPANY_ID,
  PERIMETER_SECURITY_PARTNERS_COMPANY_ID,
  PRIMARY_UTILITY_COMPANY_ID,
  QOVO_COMPANY_ID,
  SOUTHEAST_MECHANICAL_COMPANY_IDS,
  SOUTHERN_SPEAR_COMPANY_IDS,
  STILES_COMPANY_ID,
  SUNSTALL_COMPANY_ID,
  TRUEBECK_COMPANY_ID,
  UNITED_CONSTRUCTION_COMPANY_ID,
  UNITED_MECHANICAL_COMPANY_ID,
  WHITAKER_ELLIS_COMPANY_ID,
} from "./utils";
import { isClaspCompany } from "./utils/clasp-utils";
import {
  ALLAN_BRITEWAY_COMPANY_IDS,
  BOUTEN_COMPANY_ID,
  COASTAL_COMPANY_IDS,
  MARCON_COMPANY_ID,
  RLC_BRIX_COMPANY_IDS,
} from "./utils/constants";
import { IS_DEVELOPMENT, IS_PRODUCTION } from "./utils/environment";

export const useHasAccessToChecklists = (): boolean => {
  const accessibleCompanies = [
    HAYS_ELECTRICAL_COMPANY_ID,
    MITER_COMPANY_ID,
    BERGER_COMPANY_ID,
    ONYX_COMPANY_ID,
    CVE_DEMO_COMPANY_ID,
    CVE_FRESNO_COMPANY_ID,
    CVE_NORTH_BAY_COMPANY_ID,
    BECKETT_ELECTRICAL_COMPANY_ID,
    UNITED_CONSTRUCTION_COMPANY_ID,
    DEMO_COMPANY_ID,
  ];
  const activeCompanyId = useActiveCompanyId();
  return !IS_PRODUCTION || (!!activeCompanyId && accessibleCompanies.includes(activeCompanyId));
};

export const useIsAddedToAutoBreakTimeInTimesheetPolicies = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  const eligibleCompanies = ALLAN_BRITEWAY_COMPANY_IDS;
  eligibleCompanies.push(HILLHOUSE_COMPANY_ID);

  if (!IS_PRODUCTION) return true;
  if (activeCompanyId && eligibleCompanies.includes(activeCompanyId)) return true;
  return false;
};

export const useHasAccessToFringeMaximums = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  const eligibleCompanies = ALLAN_BRITEWAY_COMPANY_IDS;

  if (!IS_PRODUCTION) return true;
  if (activeCompanyId && eligibleCompanies.includes(activeCompanyId)) return true;
  return false;
};

export const useHasAccessToFillableDocuments = (): boolean => {
  return true;
};

// covers both bill pay and vendor management in Expense Management.
export const useHasAccessToBillPay = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  const companiesList = [
    MARKSMEN_GC_COMPANY_ID,
    UNITED_MECHANICAL_COMPANY_ID,
    DEBLOIS_ELECTRIC_ID,
    PALACE_CONSTRUCTION_COMPANY_ID,
    BLAIR_FIRE_COMPANY_ID,
    LONG_DOORS_COMPANY_ID,
    MITER_COMPANY_ID,
  ];
  return !IS_PRODUCTION || companiesList.includes(activeCompanyId || "");
};

// if the company is in the list, they have access to card management no matter what - skips the card lander
export const useHasAccessToCardManagement = (): boolean => {
  const list = [MARCON_COMPANY_ID, BILBRO_COMPANY_ID, TRUEBECK_COMPANY_ID, BERGER_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();

  return list.includes(activeCompanyId || "");
};

// Has access to timesheet bulk entry pay rates - don't GA this because it's not a feature we want to advertise
export const useHasAccessToBulkEntryPayRates = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return RLC_BRIX_COMPANY_IDS.includes(activeCompanyId || "");
};

export const useHasAccessToMiterPaystubsAndChecks = (): boolean => {
  const company = useActiveCompany();
  return !!company?.settings.payroll.use_miter_paystubs_and_checks;
};

export const useHasAccessToWcGroups = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  const wcGroupCompanyIds = [
    ...RLC_BRIX_COMPANY_IDS,
    CJ_HANSON_COMPANY_ID,
    MAXIM_CONCRETE_ID,
    ...SOUTHERN_SPEAR_COMPANY_IDS,
  ];

  return !IS_PRODUCTION || wcGroupCompanyIds.includes(activeCompanyId || "");
};

export const useHasCompanyCreationAccess = (): boolean => {
  const isMiterAdmin = useIsMiterAdmin();
  const activeAccount = useActiveAccount();

  // Labor compliance pros are an early customer who does prevailing wage consulting. Let's give them access to company creation for now
  return isMiterAdmin || !!activeAccount?.email?.includes("laborcompliancepros.com");
};

export const useHasAccessToAllowanceOnJobLedgerAccount = (): boolean => {
  const list = [...COASTAL_COMPANY_IDS, STILES_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();

  return !IS_PRODUCTION || list.includes(activeCompanyId || "");
};

export const useCanEditParentJob = (): boolean => {
  const list = [ANCHOR_DESIGN_BUILD_GROUP_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();

  return list.includes(activeCompanyId || "");
};

export const useHasAccessToTimeOffMaxUsageLimits = (): boolean => {
  const list = [
    MUSE_CONCRETE_COMPANY_ID,
    FACILITY_SITE_COMPANY_ID,
    WHITAKER_ELLIS_COMPANY_ID,
    ALIVE_SOLAR_ROOFING_COMPANY_ID,
    PERIMETER_SECURITY_PARTNERS_COMPANY_ID,
  ];
  const activeCompanyId = useActiveCompanyId();
  return IS_DEVELOPMENT || list.includes(activeCompanyId || "");
};

export const useHasAccessToBenAdmin = (): boolean => {
  const activeCompany = useActiveCompany();
  if (activeCompany?._id === EN_TEST_COMPANY) return false;
  return !IS_PRODUCTION || isClaspCompany(activeCompany);
};

export const useHasBillingFlagOptionForExpMgmt = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return !IS_PRODUCTION || activeCompanyId === BOUTEN_COMPANY_ID;
};

export const useHasAccessToOnboarding2_0 = (): boolean => {
  const accessibleCompanies = [DEMO_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();
  return !IS_PRODUCTION || (!!activeCompanyId && accessibleCompanies.includes(activeCompanyId));
};

export const useHasAccessToSiteBoundClockOut = (): boolean => {
  return !IS_PRODUCTION;
};

export const useHasAccessToImmediateActionCenterNotifs = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return IS_DEVELOPMENT || activeCompanyId === TRUEBECK_COMPANY_ID;
};

export const useHasAccessToRecruitingChat = (): boolean => {
  const accessibleCompanies = [
    SUNSTALL_COMPANY_ID,
    PERIMETER_SECURITY_PARTNERS_COMPANY_ID,
    DEMO_COMPANY_ID,
    AMERICAN_ROOFING_METAL_COMPANY_ID,
    MARATHON_ELECTRICAL_ID,
    KAUFMAN_LYNN_COMPANY_ID,
    QOVO_COMPANY_ID,
    ...SOUTHEAST_MECHANICAL_COMPANY_IDS,
  ];
  const activeCompanyId = useActiveCompanyId();
  return !IS_PRODUCTION || (!!activeCompanyId && accessibleCompanies.includes(activeCompanyId));
};

export const useCanEditTmHsaLimitValue = (employmentType: "employee" | "contractor"): boolean => {
  const activeCompanyId = useActiveCompanyId();
  if (employmentType !== "employee") return false;
  return !IS_PRODUCTION || activeCompanyId === DEMO_COMPANY_ID;
};

export const useCanAssociateBensWithNonTaxableContributions = (): boolean => {
  return IS_DEVELOPMENT;
};

export const useHasAccessToEquipmentTracking = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return IS_DEVELOPMENT || activeCompanyId === PRIMARY_UTILITY_COMPANY_ID;
};
