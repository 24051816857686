import React, { useMemo, useState } from "react";
import {
  SignOffMethod,
  ExpensePolicyField,
  ExpensePolicyFields,
  ExpenseReimbursementPolicyField,
  TimesheetPolicyField,
  TimeOffRequestPolicyField,
  ChangeRequestPolicyField,
} from "backend/models/policy";

import styles from "./PolicyRules.module.css";
import { Button, Notifier } from "ui";
import { Check, X } from "phosphor-react";
import { Option } from "ui/form/Input";
import { FieldRequirement, PolicyRule, TimesheetPolicyRule } from "dashboard/miter";
import { RuleConditionBuilder } from "./RuleConditionBuilder";
import { ApprovalFlowBuilder } from "./ApprovalFlowBuilder";
import ObjectID from "bson-objectid";
import { FieldRequirementsBuilder } from "./FieldRequirementsBuilder";
import { GroupTypeHasOptions, useTeamMemberGroups } from "dashboard/hooks/useTeamMemberGroups";
import {
  OrOption,
  ExpensePolicyFieldOptions,
  OperatorOptionLookup,
  ApproverGroupTypeOptionLookup,
  AndOrOptions,
  EnforceFieldOptions,
  OperatorOption,
  ConditionFieldOption,
  ExpenseReimbursementPolicyFieldOptions,
  SignOffMethodOptions,
  TimesheetPolicyFieldOptions,
  TimesheetConditionFieldOptions,
  useGetExpenseReimbursementPolicyConditionFieldOptions,
  ExpenseReimbursementPolicyConditionFieldOption,
  TimeOffRequestConditionFieldOptions,
  TimeOffRequestPolicyFieldOptions,
  ChangeRequestPolicyFieldOptions,
  ChangeRequestPolicyConditionFieldOptions,
  useGetCardTransactionPolicyConditionFieldOptions,
  ExpensePolicyConditionFieldOption,
  useTimesheetConditionFieldOptions,
  useTimesheetPolicyFieldOptions,
} from "./PolicyConstants";
import { ApproverGroupType, PolicyType } from "backend/services/approvals/types";
import { Assign } from "utility-types";
import { SignOffBuilder } from "./SignOffBuilder";
import { isEqual } from "lodash";

type Props = {
  policyType: PolicyType;
  rule?: PolicyRule;
  allRules: PolicyRule[];
  onSave: (rule: PolicyRule) => void;
  onCancel: () => void;
};

export const PolicyRuleBuilder: React.FC<Props> = ({ policyType, rule, allRules, onSave, onCancel }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { getOptions } = useTeamMemberGroups();

  // hooks for getting dynamically generated field rule options
  const expenseReimbursementPolicyConditionFieldOptions =
    useGetExpenseReimbursementPolicyConditionFieldOptions();
  const timesheetPolicyFieldOptions = useTimesheetPolicyFieldOptions();
  const timesheetConditionFieldOptions = useTimesheetConditionFieldOptions();

  const cardTransactionPolicyConditionFieldOptions = useGetCardTransactionPolicyConditionFieldOptions();

  const [condition, setCondition] = useState<ConditionForm>(
    buildCondition({
      rule,
      type: policyType,
      expenseReimbursementPolicyOptions: expenseReimbursementPolicyConditionFieldOptions,
      cardTransactionPolicyOptions: cardTransactionPolicyConditionFieldOptions,
    })
  );

  const [approvalFlow, setApprovalFlow] = useState<ApprovalFlowForm>(buildApprovalFlow(rule, getOptions));
  const [autoApprove, setAutoApprove] = useState<AutoApproveForm>(!!rule?.auto_approve);
  const [fields, setFields] = useState<FieldRequirementsForm>(buildFields(rule, policyType));
  const [signOff, setSignOff] = useState<SignOffForm>(buildSignOff(rule));
  const showSave = useMemo(() => {
    if (!rule) return false;

    const hasApprovalFlow = approvalFlow.length > 0 || autoApprove;
    const hasCondition = !!condition;
    const hasFieldRequirements = Object.keys(fields).length > 0;

    return hasApprovalFlow || hasCondition || hasFieldRequirements;
  }, [approvalFlow, condition, fields]);

  /*********************************************************
   *  Save function - converts form data to PolicyRule
   **********************************************************/
  const handleSave = () => {
    const isValid = validateRule();
    if (!isValid) return;

    const ruleCondition = condition
      ? {
          field: condition.field?.value,
          type: condition.field!.type!,
          operator: condition.operator!.value,
          value: condition.value as string | string[],
          value_label: condition?.value_label,
        }
      : null;

    if (ruleCondition?.operator === "exists") {
      ruleCondition.value = "true";
    }

    const ruleApprovalFlow = approvalFlow.map((flow) => ({
      _id: flow._id,
      approvers: flow.approvers
        .filter((approver) => approver.type && (approver.value || !GroupTypeHasOptions(approver.type.value)))
        .map((approver) => ({ _id: approver._id, type: approver.type!.value, value: approver.value?.value })),
      condition: flow.condition?.value || "or",
    }));

    const ruleAutoApprove = autoApprove;

    const ruleFields = fields?.reduce((acc, field) => {
      if (!field.field?.value || !field.value?.value) return acc;

      return {
        ...acc,
        [field.field.value]: field.value.value,
      };
    }, {});

    const newRule: PolicyRule = {
      _id: rule?._id || ObjectID().toHexString(),
      condition: ruleCondition,
      approval_flow: ruleApprovalFlow,
      auto_approve: ruleAutoApprove,
      fields: (ruleFields || {}) as ExpensePolicyFields, // TODO: change to be any policy fields
      default: rule?.default || false,
    };

    if (policyType === "timesheet" && signOff?.method) {
      (newRule as TimesheetPolicyRule).sign_off = {
        ...signOff,
        method: signOff!.method!.value,
      };
    } else {
      (newRule as TimesheetPolicyRule).sign_off = null;
    }

    onSave(newRule);
  };

  const validateRule = () => {
    try {
      // if condition exists, field and operator must be filled out
      if (condition && (!condition.field || !condition.operator)) {
        throw new Error("A condition must have a field and operator.");
      }

      if (Array.isArray(condition?.value) && condition?.value.some((v) => !v)) {
        throw new Error("Please fill out the condition");
      }

      const hasUnfinishedApprover = approvalFlow.some((flow) =>
        flow.approvers.some((approver) => {
          if (!approver.type) return true;

          if (GroupTypeHasOptions(approver.type.value)) {
            return !approver.value;
          }
        })
      );

      if (hasUnfinishedApprover) {
        throw new Error("Please finish adding approvers");
      }

      const hasUnfinishedField = fields.some((field) => {
        if (!field.field) return true;
        return !field.value;
      });

      if (hasUnfinishedField) {
        throw new Error("Please finish adding field requirements");
      }

      if (policyType === "timesheet" && signOff && !signOff.method) {
        throw new Error("Please select a sign off method");
      }

      const isMissingApprovalFlow = approvalFlow.length === 0 && !autoApprove;
      if (isMissingApprovalFlow) {
        throw new Error("You must add at least one approver or enable auto-approval");
      }

      const hasSameCondition = allRules.some((r) => {
        return (
          r._id !== rule?._id &&
          r.condition?.field === condition?.field?.value &&
          r.condition?.operator === condition?.operator?.value &&
          isEqual(r.condition?.value, condition?.value)
        );
      });

      if (hasSameCondition) {
        throw new Error("A rule with the same condition already exists");
      }

      return true;
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      return false;
    }
  };

  const buildConditionFieldOptions = (): Option<string>[] => {
    if (policyType === "expense") {
      return cardTransactionPolicyConditionFieldOptions;
    } else if (policyType === "expense_reimbursement") {
      return expenseReimbursementPolicyConditionFieldOptions;
    } else if (policyType === "timesheet") {
      return timesheetConditionFieldOptions;
    } else if (policyType === "time_off_request") {
      return TimeOffRequestConditionFieldOptions;
    } else if (policyType === "team_member_change_request") {
      return ChangeRequestPolicyConditionFieldOptions;
    }

    return [];
  };
  const buildFieldOptions = (): Option<string>[] => {
    if (policyType === "expense") {
      return ExpensePolicyFieldOptions;
    } else if (policyType === "expense_reimbursement") {
      return ExpenseReimbursementPolicyFieldOptions;
    } else if (policyType === "timesheet") {
      return timesheetPolicyFieldOptions;
    } else if (policyType === "time_off_request") {
      return TimeOffRequestPolicyFieldOptions;
    } else if (policyType === "team_member_change_request") {
      return ChangeRequestPolicyFieldOptions;
    }
    return [];
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderRuleActions = () => {
    return (
      <div className={styles["rule-actions"]}>
        <div className={styles["rule-actions-right"]}>
          <Button className="button-1 square-button" onClick={onCancel}>
            <X />
          </Button>
          {showSave && (
            <Button className="button-2 square-button no-margin" onClick={handleSave}>
              <Check weight="bold" />
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles["rule-builder"]}>
      <RuleConditionBuilder
        policyType={policyType}
        allRules={allRules}
        condition={condition}
        setCondition={setCondition}
        fieldOptions={buildConditionFieldOptions()}
      />
      <FieldRequirementsBuilder
        policyType={policyType}
        fields={fields}
        setFields={setFields}
        fieldOptions={buildFieldOptions()}
      />
      <ApprovalFlowBuilder
        policyType={policyType}
        autoApprove={autoApprove}
        approvalFlow={approvalFlow}
        setApprovalFlow={setApprovalFlow}
        setAutoApprove={setAutoApprove}
      />
      {policyType === "timesheet" && (
        <SignOffBuilder policyType={policyType} signOff={signOff} setSignOff={setSignOff} />
      )}
      {renderRuleActions()}
    </div>
  );
};

type RuleBuilderSectionProps = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
};

export const RuleBuilderSection: React.FC<RuleBuilderSectionProps> = ({ title, subtitle, children }) => {
  return (
    <div className={styles["rule-builder-section"]}>
      <div className={styles["rule-builder-section-title"]}>{title}</div>
      <div className={styles["rule-builder-section-subtitle"]}>{subtitle}</div>
      <div className={styles["rule-builder-section-content"]}>{children}</div>
    </div>
  );
};

const buildCondition = (params: {
  rule?: PolicyRule;
  type?: string;
  expenseReimbursementPolicyOptions?: ExpenseReimbursementPolicyConditionFieldOption[];
  cardTransactionPolicyOptions?: ExpensePolicyConditionFieldOption[];
}): ConditionForm => {
  const { rule, type, expenseReimbursementPolicyOptions, cardTransactionPolicyOptions } = params;
  if (!rule || !rule.condition) return null;

  let conditionField: ConditionFieldOption | undefined;
  if (type === "expense") {
    conditionField = cardTransactionPolicyOptions?.find((option) => option.value === rule.condition?.field);
  } else if (type === "expense_reimbursement") {
    conditionField = expenseReimbursementPolicyOptions?.find(
      (option) => option.value === rule.condition?.field
    );
  } else if (type === "timesheet") {
    conditionField = TimesheetConditionFieldOptions.find((option) => option.value === rule.condition?.field);
  } else if (type === "time_off_request") {
    conditionField = TimeOffRequestConditionFieldOptions.find(
      (option) => option.value === rule.condition?.field
    );
  } else if (type === "team_member_change_request") {
    conditionField = ChangeRequestPolicyConditionFieldOptions.find(
      (option) => option.value === rule.condition?.field
    );
  }

  return {
    field: conditionField,
    operator: OperatorOptionLookup(rule.condition.operator),
    value: rule.condition.value,
    value_label: rule.condition.value_label,
  };
};

const buildApprovalFlow = (
  rule: PolicyRule | undefined,
  getOptions: (groupType: ApproverGroupType) => Option<string>[]
): ApprovalFlowForm => {
  if (!rule) return [];

  return rule.approval_flow.map((flow) => ({
    _id: ObjectID().toHexString(),
    approvers: flow.approvers.map((approver) => ({
      _id: approver._id,
      type: ApproverGroupTypeOptionLookup(approver.type),
      value: approver.value
        ? getOptions(approver.type).find((option) => option.value === approver.value)
        : undefined,
    })),
    condition: AndOrOptions.find((option) => option.value === flow.condition) || OrOption,
  }));
};

const buildFields = (rule: PolicyRule | undefined, type: PolicyType): FieldRequirementsForm => {
  if (!rule || !rule.fields) return [];

  let fields: FieldOptions[] = [];
  if (type === "expense") {
    fields = ExpensePolicyFieldOptions;
  } else if (type === "expense_reimbursement") {
    fields = ExpenseReimbursementPolicyFieldOptions;
  } else if (type === "timesheet") {
    fields = TimesheetPolicyFieldOptions;
  } else if (type === "time_off_request") {
    fields = TimeOffRequestPolicyFieldOptions;
  } else if (type === "team_member_change_request") {
    fields = ChangeRequestPolicyFieldOptions;
  }

  return Object.keys(rule.fields).map((field) => ({
    field: fields.find((option) => option.value === field),
    value: EnforceFieldOptions.find((option) => option.value === rule.fields?.[field]),
  }));
};

const buildSignOff = (rule: PolicyRule | undefined): SignOffForm => {
  if (!rule || !rule.fields || !("sign_off" in rule) || rule.sign_off == null) return;

  // Couldn't figure out a way to solve this other than casting - the signOff type guard doesn't seem to work
  const timesheetRule = rule as TimesheetPolicyRule;

  return {
    agreement: timesheetRule.sign_off?.agreement,
    agreement_es: timesheetRule.sign_off?.agreement_es,
    method: SignOffMethodOptions.find((o) => timesheetRule.sign_off?.method === o.value),
  };
};

export type ConditionForm = {
  field: ConditionFieldOption | undefined;
  operator: OperatorOption | undefined;
  value: NonNullable<PolicyRule["condition"]>["value"] | undefined | (string | undefined)[];
  value_label?: string;
} | null;

export type ApprovalFlowForm = {
  _id: string;
  approvers: {
    _id: string;
    type: Option<ApproverGroupType> | undefined;
    value: Option<string> | undefined;
  }[];
  condition: Option<"and" | "or"> | undefined;
}[];

export type AutoApproveForm = PolicyRule["auto_approve"];

export type FieldRequirementsForm = {
  field?: FieldOptions;
  value?: Option<FieldRequirement>;
}[];

export type SignOffForm =
  | Assign<NonNullable<TimesheetPolicyRule["sign_off"]>, { method?: Option<SignOffMethod> | undefined }>
  | undefined
  | null;

export type FieldOptions =
  | Option<ExpensePolicyField>
  | Option<ExpenseReimbursementPolicyField>
  | Option<TimesheetPolicyField>
  | Option<TimeOffRequestPolicyField>
  | Option<ChangeRequestPolicyField>;
