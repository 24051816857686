/* eslint-disable @typescript-eslint/no-explicit-any */
import AppContext from "dashboard/contexts/app-context";
import { CustomField } from "dashboard/miter";
import { capitalize } from "dashboard/utils";
import { Plus } from "phosphor-react";
import React, { useContext, useMemo, useState } from "react";
import { TableActionLink, ColumnConfig, TableV2 } from "ui/table-v2/Table";
import CustomFieldModal from "./CustomFieldModal";
import { TeamMemberGroup } from "backend/types";
import { ValueFormatterParams } from "ag-grid-community";
import pluralize from "pluralize";
import { TEAM_MEMBER_GROUP_LABEL_OVERRIDES } from "dashboard/pages/team-members/teamSettings/OnboardingItemModal";
import { useTeamMemberGroupLabeler } from "dashboard/utils/approvals";
import styles from "dashboard/pages/team-members/teamSettings/OnboardingItemsTable.module.css";

type Props = {
  parentType: CustomField["parent_type"];
};

export type CustomFieldTableRow = CustomField & {
  onboarding_config?: {
    default_due_days_from_start?: number;
    default_team_member_checklists?: TeamMemberGroup[] | null;
    default_assignee?: TeamMemberGroup[] | null;
  };
};

const CustomFieldTable: React.FC<Props> = ({ parentType }) => {
  const { customFields, getCustomFields } = useContext(AppContext);
  const { groupLabeler } = useTeamMemberGroupLabeler(TEAM_MEMBER_GROUP_LABEL_OVERRIDES);

  const [adding, setAdding] = useState<boolean>(false);
  const [selectedCustomField, setSelectedCustomField] = useState<CustomFieldTableRow | null>(null);

  const handleRowClick = (customField) => {
    handleEdit(customField);
  };

  const handleAdd = () => {
    setAdding(true);
  };

  const handleEdit = (customField) => {
    setSelectedCustomField(customField);
  };

  const handleModalClose = () => {
    setAdding(false);
    setSelectedCustomField(null);
  };

  const handleModalSubmit = () => {
    getCustomFields();
    setAdding(false);
  };

  const isOnboardingCustomField = parentType === "onboarding_task";

  const tableData = useMemo(() => {
    return customFields
      .filter((field) => field.parent_type === parentType)
      .map((field) => ({
        ...field,
        dataType: capitalize(field.type),
        team_member_access: field.team_member_access ? capitalize(field.team_member_access) : undefined,
      })) as CustomFieldTableRow[];
  }, [customFields]);

  const addButtonLabel = parentType === "onboarding_task" ? "Add" : "Add field";

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = [
    {
      label: addButtonLabel,
      className: "button-2 no-margin",
      action: handleAdd,
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const columns = useMemo(() => {
    const baseColumns: ColumnConfig<CustomFieldTableRow>[] = [
      {
        headerName: "Name",
        field: "name",
        dataType: "string",
      },
      {
        headerName: "Type",
        field: "type",
        dataType: "string",
        displayType: "badge",
        colors: {
          checkbox: "light-blue",
          select: "light-yellow",
          text: "light-green",
          paragraph: "light-purple",
          number: "light-red",
        },
      },
    ];

    if (parentType !== "job" && parentType !== "onboarding_task") {
      baseColumns.push({
        headerName: "Team member access",
        field: "team_member_access",
        dataType: "string" as const,
      });
    }

    if (parentType === "onboarding_task") {
      baseColumns.push(
        {
          field: "onboarding_config.default_due_days_from_start",
          headerName: "Due (days from start)",
          dataType: "number" as const,
          valueFormatter: (params: ValueFormatterParams<CustomFieldTableRow>): string => {
            if (params.value == null) {
              return "-";
            }

            const value = params.value as number;
            if (value > 0) {
              return `${value} ${pluralize("day", value)} after start`;
            } else {
              const absValue = Math.abs(value);
              return `${absValue} ${pluralize("day", absValue)} before start`;
            }
          },
        },
        {
          field: "onboarding_config.default_team_member_checklists",
          headerName: "Relevant team groups",
          dataType: "string" as const,
          valueFormatter: (params: ValueFormatterParams<CustomFieldTableRow>): string => {
            return (
              params.data?.onboarding_config?.default_team_member_checklists
                ?.map((group) => groupLabeler(group))
                .join(", ") || "-"
            );
          },
        },
        {
          field: "onboarding_config.default_assignee",
          headerName: "Default assignee",
          dataType: "string" as const,
          valueFormatter: (params: ValueFormatterParams<CustomFieldTableRow>): string => {
            return (
              params.data?.onboarding_config?.default_assignee
                ?.map((group) => groupLabeler(group))
                .join(", ") || "-"
            );
          },
        }
      );
    }

    baseColumns.push({
      headerName: "ID",
      field: "friendly_id",
      dataType: "string",
      hide: isOnboardingCustomField,
    });

    return baseColumns;
  }, [parentType, groupLabeler]);

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={`${parentType}-custom-fields-table`}
        resource={isOnboardingCustomField ? "onboarding custom fields" : "custom fields"}
        data={tableData}
        columns={columns}
        staticActions={staticActions}
        onClick={handleRowClick}
        hideFooter={true}
        containerStyle={{ paddingBottom: 0 }}
        hideSearch={isOnboardingCustomField}
        hidePrinter={isOnboardingCustomField}
        hideExporter={isOnboardingCustomField}
        containerClassName={isOnboardingCustomField ? styles.customTable : undefined}
      />
    );
  };

  return (
    <div className="customField-table-wrapper">
      <div>
        {adding && (
          <CustomFieldModal parentType={parentType} onHide={handleModalClose} onFinish={handleModalSubmit} />
        )}
        {selectedCustomField && (
          <CustomFieldModal
            onHide={handleModalClose}
            onFinish={handleModalSubmit}
            customFieldID={selectedCustomField._id}
            parentType={parentType}
          />
        )}

        {renderTable()}
      </div>
    </div>
  );
};

export default CustomFieldTable;
