import {
  AggregatedTeamMember,
  BankAccount,
  MiterAPI,
  PlaidBankAccount,
  RawBankAccount,
  CheckOriginatedBankAccount,
  TeamMember,
  UpdateTeamMemberParams,
} from "dashboard/miter";
import { capitalize } from "lodash";
import React, { FC, useState } from "react";
import { ActionModal, Button, Formblock, Notifier } from "ui";
import { useTranslation } from "react-i18next";
import { TeamPortalUser } from "team-portal/utils/miter";

type Props = {
  bankAccount: BankAccount;
  teamMember?: TeamMember | AggregatedTeamMember | TeamPortalUser;
  onUpdate: () => void;
  onClose: () => void;
};

export const ViewBankAccountModal: FC<Props> = ({ teamMember, bankAccount, onUpdate, onClose }) => {
  const [rawAccountNumber, setRawAccountNumber] = useState<string>();
  const [loadingRawAccountNumber, setLoadingRawAccountNumber] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const isCurrentlyDefaultForTeamMemberAchReimbursements =
    teamMember?.bank_account_settings?.ach_reimbursements_bank_account_id === bankAccount._id;

  const canUseForAchReimbursements =
    !!teamMember &&
    !isCurrentlyDefaultForTeamMemberAchReimbursements &&
    (bankAccount.type === "plaid_bank_account" || bankAccount.type === "raw_bank_account");

  const { t } = useTranslation<$TSFixMe>();

  const toggleRevealFullBankAccountNumber = async () => {
    if (rawAccountNumber) {
      setRawAccountNumber(undefined);
      return;
    }

    try {
      setLoadingRawAccountNumber(true);
      const response = await MiterAPI.bank_accounts.reveal_full_account_number(bankAccount._id);
      if (response.error) {
        throw new Error(response.error);
      }

      setRawAccountNumber(response.account_number);
    } catch (err) {
      Notifier.error(t("Could not show full account number."));
      console.error(err);
    }
    setLoadingRawAccountNumber(false);
  };

  const handleArchiveAccount = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.bank_accounts.archive(bankAccount._id);
      if (response.error) {
        throw new Error(response.error);
      }

      Notifier.success("Bank account archived successfully.");
      onUpdate();
      onClose();
    } catch (err: $TSFixMe) {
      Notifier.error("Could not archive bank account. " + err.message);
    }
    setLoading(false);
  };

  const handleTeamMemberAchReimbursementDefault = async () => {
    if (!teamMember) return;

    setLoading(true);
    try {
      // TODO: this will override other future fields in bank_account_settings
      const params: UpdateTeamMemberParams = {
        bank_account_settings: {
          ach_reimbursements_bank_account_id: bankAccount._id,
        },
      };

      const response = await MiterAPI.team_member.update(teamMember._id, params);

      if (response.error) {
        throw new Error(response.error);
      }

      Notifier.success(t("Bank account is now used for ACH reimbursements."));
      onUpdate();
      onClose();
    } catch (err) {
      Notifier.error(
        t("Could not use bank account for ACH reimbursements. Contact an administrator for help.")
      );
      console.error(err);
    }
  };

  // if raw bank account, show account and routing numbers
  const renderRawBankAccountDetails = () => {
    // cast bankAccount to RawBankAccount
    const rawBankAccount = bankAccount as RawBankAccount;
    return (
      <>
        <Formblock
          label={t("Account number")}
          type="text"
          name="account_number"
          className="modal"
          editing={false}
        >
          <span className={"flex space-between"}>
            {rawAccountNumber || `····${rawBankAccount.account_number_last_4}`}
            <Button
              className="button-text purple-link hover"
              style={{ marginLeft: 10, fontWeight: "500" }}
              onClick={() => toggleRevealFullBankAccountNumber()}
              loading={loadingRawAccountNumber}
              text={rawAccountNumber ? t("Hide") : t("View full account number")}
              hideTextWhileLoading
            />
          </span>
        </Formblock>
        <Formblock
          label={t("Routing number")}
          type="text"
          name="routing_number"
          className="modal"
          editing={false}
          defaultValue={rawBankAccount.routing_number}
        />
        <Formblock
          label={t("Type")}
          type="text"
          name="account_subtype"
          className="modal"
          editing={false}
          defaultValue={capitalize(t(rawBankAccount.account_subtype))}
        />
      </>
    );
  };

  const renderPlaidBankAccountDetails = () => {
    // cast bankAccount to PlaidBankAccount
    const plaidBankAccount = bankAccount as PlaidBankAccount;
    return (
      <>
        <Formblock
          label={t("Account Name")}
          type="text"
          name="institution_name"
          className="modal"
          editing={false}
          defaultValue={plaidBankAccount.external_raw_data.name}
        />
        <Formblock
          label={t("Account number")}
          type="text"
          name="account_number"
          className="modal"
          editing={false}
          defaultValue={`····${plaidBankAccount.account_number_last_4}`}
        />
        <Formblock
          label={t("Type")}
          type="text"
          name="account_subtype"
          className="modal"
          editing={false}
          defaultValue={capitalize(t(plaidBankAccount.account_subtype))}
        />
      </>
    );
  };

  const renderCheckOriginatedBankAccountDetails = () => {
    // cast bankAccount to CheckOriginatedBankAccount
    const checkOriginatedBankAccount = bankAccount as CheckOriginatedBankAccount;

    return (
      <>
        <Formblock
          label={t("Account number")}
          type="text"
          name="account_number"
          className="modal"
          editing={false}
          defaultValue={`····${checkOriginatedBankAccount.account_number_last_4}`}
        />
        <Formblock
          label={t("Type")}
          type="text"
          name="account_subtype"
          className="modal"
          editing={false}
          defaultValue={capitalize(t(checkOriginatedBankAccount.account_subtype))}
        />
      </>
    );
  };

  const renderBankAccountDetails = () => {
    switch (bankAccount.type) {
      case "raw_bank_account":
        return renderRawBankAccountDetails();
      case "plaid_bank_account":
        return renderPlaidBankAccountDetails();
      case "check_originated_bank_account":
        return renderCheckOriginatedBankAccountDetails();
      default:
        return <></>;
    }
  };
  return (
    <>
      <ActionModal
        headerText={t("Manage Bank Account")}
        loading={loading}
        onHide={onClose}
        showDelete={true}
        onDelete={handleArchiveAccount}
        showEdit={canUseForAchReimbursements}
        onEdit={handleTeamMemberAchReimbursementDefault}
        editText={t("Use for ACH reimbursements")}
      >
        <>
          <div className="margin-top-15">{renderBankAccountDetails()}</div>
          <Formblock
            label={t("Used for ACH reimbursements?")}
            type="text"
            name="default_for_ach_reimbursements"
            className="modal"
            readOnly={true}
            defaultValue={isCurrentlyDefaultForTeamMemberAchReimbursements ? t("Yes") : t("No")}
          />
        </>
      </ActionModal>
    </>
  );
};
