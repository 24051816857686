import { FlatfileResults } from "@flatfile/react";
import {
  useActiveCompanyId,
  useActivityOptions,
  useClassificationOptions,
  useDepartmentOptions,
  useHasEnabledWcGroups,
  useExpensePolicyOptions,
  useJobOptions,
  useLedgerMappingOptions,
  useLocationOptions,
  useOtRuleOptions,
  useReimbursementPolicyOptions,
  useStandardClassificationOptions,
  useTeamMemberChangeRequestPolicyOptions,
  useTeamOptions,
  useTimeOffRequestPolicyOptions,
  useTimesheetPolicyOptions,
  useWcCodeOptions,
  useWcGroupOptions,
  useBenefitsEligibilityGroupOptions,
  useActiveCompany,
} from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import React, { useCallback, useContext, useMemo } from "react";
import { Notifier } from "ui";
import { MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../importer/Importer";
import { CreateTeamMemberParams, TeamMemberPaymentParams } from "backend/utils/team-members/createTeamMember";
import { buildFlatfileMessage, normalizeDate, validateRoutingNum } from "dashboard/utils/flatfile";
import { useEnabledDemographicQuestions, clearEmptyAndNullishValues } from "dashboard/utils";
import {
  eeoEthnicityCategories,
  eeoGenderCategories,
  eeoJobCategories,
  eeoVeteranStatusCategories,
  eeoMaritalStatusCategories,
  eeoDisabilityStatusCategories,
} from "miter-utils";
import { isValidState, salaryRateOptions, stateOptions } from "miter-utils";
import { useImportValidators } from "dashboard/hooks/flatfile-import/useImportValidators";
import {
  EEOEthnicityCategory,
  EEOGenderCategory,
  EEOJobCategory,
  EEOVeteranStatus,
  MaritalStatus,
} from "backend/models/teamMember/teamMemberTypes";
import { isEmpty, set } from "lodash";
import { DateTime } from "luxon";
import {
  defaultTmActivityTooltip,
  stdTmClassificationTooltip,
  defaultTmJobTooltip,
  employmentCategoryOptions,
  employmentTermOptions,
} from "dashboard/pages/team-members/TeamUtils";
import { useOtExemptUtils } from "dashboard/hooks/useOtExempt";
import { TERMINATION_REASON_OPTIONS } from "dashboard/pages/team-members/DismissModal";
import { buildCustomFieldColumns, buildCustomFieldValues } from "dashboard/utils/flatfile";
import { FlatfileImportButtonProps } from "../shared/FlatfileImportButton";
import { TeamMemberEmploymentHistoryParams } from "backend/utils/team-members/import-employment-history";
import TeamMember, { TeamMemberTerminationReason } from "backend/models/teamMember/team-member";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";

type PrelimTeamMemberImportRow = {
  friendly_id?: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  phone?: string;
  email?: string;
  employment_type: "employee" | "contractor";
  enroll_in_payroll?: "true" | "false" | "";
  title: string;
  department_id?: string | null;
  employment_category?: TeamMember["employment_category"];
  employment_term?: TeamMember["employment_term"];
  benefits_eligibility_group?: string;
  location_id?: string | null;
  reports_to?: string;
  start_date?: string;
  end_date?: string;
  termination_reason?: string;
  note?: string;
  eligible_for_rehire?: boolean;
  last_payday?: string;
  ssn?: string;
  dob?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  pay_type?: "salary" | "hourly";
  pay_rate?: string;
  salary_rate_display?: string;
  pay_rate_effective_date?: string;
  overtime_exempt?: "true" | "false";
  license_status?: "journeyman" | "apprentice";
  union?: string;
  wc_code?: string;
  wc_group_id?: string;
  default_job_id?: string;
  default_activity_id?: string;
  standard_classification_id?: string;
  can_enable_kiosk?: "true" | "false" | "";
  union_rate?: string;
  ledger_mapping_id?: string;
  default_ot_rule_id?: string;

  expense_policy_id?: string | null;
  reimbursement_policy_id?: string | null;
  timesheet_policy_id?: string | null;
  time_off_request_policy_id?: string | null;
  team_member_change_request_policy_id?: string | null;

  demographics_ethnicity?: EEOEthnicityCategory;
  demographics_gender?: EEOGenderCategory;
  demographics_job_category?: EEOJobCategory;
  demographics_veteran_status?: EEOVeteranStatus;
  demographics_marital_status?: MaritalStatus;
  demographics_disability_status?: string;
} & TeamMemberPaymentParams;

type PrelimEmploymentHistoryImportRow = {
  friendly_id: string;
  type: "rehire" | "dismissal";
  start_date?: string;
  end_date?: string;
  involuntary?: string;
  note?: string;
  eligible_for_rehire?: string;
  termination_reason?: string;
  last_payday?: string;
};

type Props = {
  onFinish: () => void;
  upsert: boolean;
  importEmploymentHistory?: boolean;
  /** If true, overwrite the rehires/dismissals array */
  overwriteHistory?: boolean;
  ImportButtonComponent: React.ComponentType<FlatfileImportButtonProps>;
};

export const TeamMemberImporter: React.FC<Props> = ({
  onFinish,
  upsert,
  importEmploymentHistory = false,
  overwriteHistory = false,
  ImportButtonComponent,
}) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const { customFields } = useContext(AppContext);

  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const isActiveOnClasp = isActiveClaspCompany(activeCompany);
  const hasEnabledWcGroups = useHasEnabledWcGroups();
  const { otExemptionIsRelevant } = useOtExemptUtils();
  const wcCodeOptions = useWcCodeOptions();
  const wcGroupOptions = useWcGroupOptions();
  const deptOptions = useDepartmentOptions();
  const locationOptions = useLocationOptions();
  const teamOptions = useTeamOptions({ includeBlank: true });
  const stdClassificationOptions = useStandardClassificationOptions();
  const allClassificationOptions = useClassificationOptions({});
  const { validateTeamMemberID, validateOtherFieldsEmpty, validateEmail, validatePhone } =
    useImportValidators();

  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const timeOffRequestPolicyOptions = useTimeOffRequestPolicyOptions();
  const expensePolicyOptions = useExpensePolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();
  const teamMemberChangeRequestPolicyOptions = useTeamMemberChangeRequestPolicyOptions();

  const jobOptions = useJobOptions();
  const activityOptions = useActivityOptions();
  const enabledDemographicQuestions = useEnabledDemographicQuestions();
  const benefitsEligibilityGroupOptions = useBenefitsEligibilityGroupOptions();

  const ledgerMappingOptions = useLedgerMappingOptions();
  const otRuleOptions = useOtRuleOptions();

  const teamMembersCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "team_member"),
    [customFields]
  );

  /**********************************************************************************************************
   * Data cleaning
   *********************************************************************************************************/

  // Messy data cleaning function.
  const buildTeamMemberParams = (row: PrelimTeamMemberImportRow): CreateTeamMemberParams => {
    if (!activeCompanyId) throw new Error("No active company ID");

    // Remove dollar sign and commas from pay rate
    const payRate = row.pay_rate?.replace(/[$,]/g, "");

    const cleanedData: CreateTeamMemberParams = {
      company: activeCompanyId,
      friendly_id: row.friendly_id,
      employment_type: row.employment_type,
      phone: row.phone,
      title: row.title,
      department_id: row.department_id,
      employment_category: row.employment_category,
      employment_term: row.employment_term,
      location_id: row.location_id,
      first_name: row.first_name,
      middle_name: row.middle_name,
      last_name: row.last_name,
      email: row.email,
      reports_to: row.reports_to,
      pay_type: row.pay_type,
      pay_rate: payRate ? Number(payRate) : undefined, // Only allow for $0 when it's specifically given
      dob: row.dob,
      start_date: row.start_date,
      end_date: row.end_date,
      ssn: row.ssn,
      termination_reason: row.termination_reason as TeamMemberTerminationReason,
      note: row.note,
      eligible_for_rehire: row.eligible_for_rehire,
      last_payday: row.last_payday,
      pay_rate_effective_date: row.pay_rate_effective_date,
      salary_rate_display: row.salary_rate_display as TeamMember["salary_rate_display"],
      wc_code: row.wc_code,
      wc_group_id: row.wc_group_id,
      default_job_id: row.default_job_id,
      default_activity_id: row.default_activity_id,
      standard_classification_id: row.standard_classification_id,
      send_sms: false,
      overwrite_bank_account: row.overwrite_bank_account,
      payment_method_preference: row.payment_method_preference,
      bank_account_type: row.bank_account_type,
      bank_account_number: row.bank_account_number,
      bank_routing_number: row.bank_routing_number && row.bank_routing_number.padStart(9, "0"),
      union_rate: row.union_rate,
      ledger_mapping_id: row.ledger_mapping_id,
      expense_policy_id: row.expense_policy_id,
      reimbursement_policy_id: row.reimbursement_policy_id,
      timesheet_policy_id: row.timesheet_policy_id,
      time_off_request_policy_id: row.time_off_request_policy_id,
      team_member_change_request_policy_id: row.team_member_change_request_policy_id,
      default_ot_rule_id: row.default_ot_rule_id,
      benefits_eligibility_groups: row.benefits_eligibility_group
        ? [row.benefits_eligibility_group]
        : undefined,
      ...buildDemographicData(row),
    };

    // Only add enroll in payroll if not null
    if (row.enroll_in_payroll != null && row.enroll_in_payroll !== "") {
      cleanedData.enroll_in_payroll = row.enroll_in_payroll === "true" ? true : false;
    }

    if (row.can_enable_kiosk != null && row.can_enable_kiosk !== "") {
      cleanedData.can_enable_kiosk = row.can_enable_kiosk === "true" ? true : false;
    }

    // Don't allow deleting of end dates from this flow
    if (row.end_date === "null") {
      delete cleanedData.end_date;
    }

    if (row.line1) {
      cleanedData.address = {
        line1: row.line1,
        line2: row.line2 === "" ? null : row.line2,
        city: row.city || "",
        state: row.state || "",
        postal_code: row.postal_code || "",
      };
    }

    if (otExemptionIsRelevant(row)) {
      cleanedData.overtime_exempt =
        row.overtime_exempt === "true" ? true : row.overtime_exempt === "false" ? false : undefined;
    }

    const customFieldValues = buildCustomFieldValues(row, teamMembersCustomFields);

    return {
      ...clearEmptyAndNullishValues(cleanedData),
      custom_field_values: customFieldValues,
    } as CreateTeamMemberParams;
  };

  const buildEmploymentHistoryData = (
    row: PrelimEmploymentHistoryImportRow
  ): TeamMemberEmploymentHistoryParams => {
    const cleanedData: TeamMemberEmploymentHistoryParams = {
      friendly_id: row.friendly_id!,
      start_date: row.start_date,
      end_date: row.end_date,
      involuntary: row.involuntary === "true" ? true : false,
      note: row.note,
      eligible_for_rehire: row.eligible_for_rehire === "true" ? true : false,
      termination_reason: row.termination_reason as TeamMemberTerminationReason,
      last_payday: row.last_payday,
    };

    return clearEmptyAndNullishValues(cleanedData) as TeamMemberEmploymentHistoryParams;
  };

  const buildDemographicData = (row: PrelimTeamMemberImportRow): CreateTeamMemberParams["demographics"] => {
    const data: CreateTeamMemberParams["demographics"] = {};

    if (!isEmpty(row["demographics_ethnicity"])) {
      set(data, "demographics.ethnicity", row["demographics_ethnicity"]);
    }
    if (!isEmpty(row["demographics_gender"])) {
      set(data, "demographics.gender", row["demographics_gender"]);
    }
    if (!isEmpty(row["demographics_job_category"])) {
      set(data, "demographics.job_category", row["demographics_job_category"]);
    }
    if (!isEmpty(row["demographics_veteran_status"])) {
      set(data, "demographics.veteran_status", row["demographics_veteran_status"]);
    }
    if (!isEmpty(row["demographics_marital_status"])) {
      set(data, "demographics.marital_status", row["demographics_marital_status"]);
    }
    if (!isEmpty(row["demographics_disability_status"])) {
      set(data, "demographics.disability_status", row["demographics_disability_status"]);
    }

    return data;
  };

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/
  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedTeamMembers = results.validData.map(buildTeamMemberParams);

      const response = await MiterAPI.team_member.import({
        clean_inputs: preppedTeamMembers,
        raw_inputs: results.validData,
        upsert,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} team members with ${errors} errors.`);
        } else {
          Notifier.success(`Imported ${successes} team members.`);
        }
      } else if (errors > 0) {
        Notifier.error(`There were ${errors} errors importing the team members.`);
      }

      onFinish();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  const handleEmploymentHistorySubmit = async (results: FlatfileResults): Promise<void> => {
    // Make call to import rehire history endpoint
    try {
      if (!activeCompanyId) throw new Error("No active company ID");

      const cleanData = results.validData.map(buildEmploymentHistoryData);

      const response = await MiterAPI.team_member.import_employment_history({
        clean_inputs: cleanData,
        raw_inputs: results.validData,
        company_id: activeCompanyId!,
        overwrite: overwriteHistory,
      });
      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} team members with ${errors} errors.`);
        } else {
          Notifier.success(`Imported ${successes} team members.`);
        }
      } else if (errors > 0) {
        Notifier.error(`There were ${errors} errors importing the team members.`);
      }

      onFinish();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  /**********************************************************************************************************
   * Validation hooks
   **********************************************************************************************************/

  const validateFriendlyId = (friendlyId: string | null | undefined) => {
    if (!friendlyId) return { value: "" };

    const cleanedFriendlyId = friendlyId.trim().toUpperCase();
    return { value: cleanedFriendlyId };
  };

  const validateSSN = (ssn: string | null | undefined) => {
    if (!ssn) return { value: "" };
    // Replace dashes, spaces, and periods with nothing.
    ssn = ssn.replace(/[- .]/g, "").trim();

    // Make sure it's 9 digits.
    if (!/^\d{9}$/.test(ssn)) {
      return buildFlatfileMessage("Please enter a valid 9-digit SSN.", ssn, "error");
    }

    return { value: ssn };
  };

  const validateDOB = (dob: string | null | undefined) => {
    if (!dob) return { value: "" };

    // If upserting, allow null values
    if (upsert && dob === "null") return { value: "null" };

    const cleanedDOB = normalizeDate(dob);
    if (!cleanedDOB || !cleanedDOB["value"]) {
      return buildFlatfileMessage("Please enter a valid date of birth.", cleanedDOB["value"], "error");
    }

    // Ensure DOB is at least 5 years ago. This is just to prevent errors during
    // the import process.  While the usual laws from the FLSA limit the minimum
    // age to be 14, there are exceptions for owner's children, where they can
    // be any age.
    // https://www.dol.gov/general/topic/youthlabor/agerequirements
    const minDOB = DateTime.now().minus({ years: 5 });
    const dobDateTime = DateTime.fromISO(cleanedDOB["value"]);

    if (dobDateTime > minDOB) {
      return buildFlatfileMessage(
        "Team member is below minimum age for payroll",
        cleanedDOB["value"],
        "error"
      );
    }

    return cleanedDOB;
  };

  const validateState = (state: string | null | undefined) => {
    if (!state) return { value: "" };

    const cleanedState = state.trim().toUpperCase();
    if (!isValidState(cleanedState)) {
      return buildFlatfileMessage("Please enter a valid state (abbreviation).", cleanedState, "error");
    }

    return { value: cleanedState };
  };

  // Add this helper function at the top of the file or in a separate utils file
  const validateAndNormalizeDate = (
    row: PrelimEmploymentHistoryImportRow,
    currentField: { key: keyof PrelimEmploymentHistoryImportRow; label: string },
    otherFields: Array<{ key: keyof PrelimEmploymentHistoryImportRow; label: string }>
  ) => {
    const validationResult = validateOtherFieldsEmpty(row, currentField, otherFields);
    const normalizedDate =
      typeof row === "string" ? normalizeDate(row) : normalizeDate(row[currentField.key]!);

    // If there is an error message, show the error message but keep value as the normalized date
    return validationResult?.info ? { ...validationResult, value: normalizedDate.value } : normalizedDate;
  };

  const validateAndNormalizeStartDate = (row: PrelimEmploymentHistoryImportRow) => {
    return validateAndNormalizeDate(row, { key: "start_date", label: "Rehire start date" }, [
      { key: "end_date", label: "Dismissal end date" },
      { key: "note", label: "Dismissal note" },
      { key: "involuntary", label: "Involuntary dismissal" },
      { key: "eligible_for_rehire", label: "Eligible for rehire" },
      { key: "termination_reason", label: "Termination reason" },
      { key: "last_payday", label: "Last payday" },
    ]);
  };

  const validateAndNormalizeEndDate = (row: PrelimEmploymentHistoryImportRow) => {
    return validateAndNormalizeDate(row, { key: "end_date", label: "Dismissal end date" }, [
      { key: "start_date", label: "Rehire start date" },
    ]);
  };

  /**********************************************************************************************************
   * Custom fields
   **********************************************************************************************************/

  const customFieldColumns = useMemo(
    () => buildCustomFieldColumns(teamMembersCustomFields),
    [teamMembersCustomFields]
  );

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "ID",
        key: "friendly_id",
        type: "string",
        description:
          "Unique identifier (typically a number) for this team member. We will create one automatically if you leave this blank.",
        validators: [{ validate: "unique" }],
        hook: (val) =>
          typeof val === "string" ? validateFriendlyId(val) : validateFriendlyId(val.friendly_id),
      },
      {
        label: "First name",
        key: "first_name",
        description: "Required to create a team member.",
        validators: upsert ? [] : [{ validate: "required" }],
      },
      {
        label: "Middle name",
        key: "middle_name",
      },
      {
        label: "Last name",
        key: "last_name",
        description: "Required to create a team member.",
        validators: upsert ? [] : [{ validate: "required" }],
      },
      {
        label: "Phone",
        key: "phone",
        description: "Required to create a team member. Must be unique within the company.",
        validators: [
          ...(upsert
            ? []
            : [
                {
                  validate: "required_without" as const,
                  fields: ["email"],
                },
                {
                  validate: "unique" as const,
                },
                {
                  validate: "regex_matches" as const,
                  regex: "^\\+?[1-9]\\d{1,14}$",
                  error: "Must be a valid phone number.",
                },
              ]),
        ],
        hook: (val) =>
          typeof val === "string" ? validatePhone(val, upsert) : validatePhone(val.phone, upsert),
      },
      {
        label: "Email",
        key: "email",
        validators: [
          ...(upsert
            ? []
            : [
                {
                  validate: "required_without" as const,
                  fields: ["phone"],
                },
                {
                  validate: "unique" as const,
                },
                {
                  validate: "regex_matches" as const,
                  regex:
                    "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])",
                  error: "Must be a valid email address.",
                },
              ]),
        ],
        hook: (val) =>
          typeof val === "string" ? validateEmail(val, upsert) : validateEmail(val.email, upsert),
      },
      {
        label: "Employment type",
        key: "employment_type",
        type: "select",
        options: [
          { value: "employee", label: "Employee", alternates: ["Employee (W-2)", "emp"] },
          { value: "contractor", label: "Contractor", alternates: ["Contractor (1099)"] },
        ],
        description: "Choose Employee (W-2) or Contractor (1099)",
        validators: upsert ? [] : [{ validate: "required" }],
      },
      {
        label: "Enroll in payroll",
        key: "enroll_in_payroll",
        type: "select",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        description: "Choose yes if the team member is an employee and should be enrolled in payroll.",
      },
      {
        label: "Title",
        key: "title",
        description:
          "Required to create a team member. Specifies the team member's role, e.g. Electrician, Boilermaker, Painter...",
      },
      {
        label: "Department",
        key: "department_id",
        type: "select",
        description: "The team member's department. This is an optional field.",
        options: deptOptions,
      },
      {
        label: "Employment category",
        key: "employment_category",
        type: "select",
        description: "The team member's employment category (FT or PT). This is an optional field.",
        options: employmentCategoryOptions,
      },
      {
        label: "Hours worked (weekly estimate)",
        key: "estimated_hours_worked_per_week",
        type: "string",
        description: "The average number of hours this team member works per week.",
      },
      {
        label: "Employment term",
        key: "employment_term",
        type: "select",
        description:
          "The team member's employment term (Temporary, Seasonal, Permanent). This is an optional field.",
        options: employmentTermOptions,
      },
      ...(isActiveOnClasp && benefitsEligibilityGroupOptions.length > 0
        ? [
            {
              label: "Benefits eligibility group",
              key: "benefits_eligibility_group",
              type: "select" as const,
              description: "The team member's primary benefit eligibility group.",
              options: benefitsEligibilityGroupOptions,
            },
          ]
        : []),
      {
        label: "Location",
        key: "location_id",
        type: "select",
        description: "The team member's location. This is an optional field.",
        options: locationOptions,
      },
      {
        label: "Reports to",
        key: "reports_to",
        type: "select",
        description: "The team member's direct manager. This is an optional field.",
        options: teamOptions,
      },
      {
        label: "Start date",
        key: "start_date",
        description: "This field is required for employees (not contractors).",
        validators: upsert
          ? []
          : [
              {
                validate: "required_with_all_values",
                fieldValues: { employment_type: "employee", enroll_in_payroll: "true" },
              },
            ],
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.start_date)),
      },
      {
        label: "End date",
        key: "end_date",
        description: "This field optional for employees but not relevant to contractors.",
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.end_date)),
      },
      {
        label: "Termination reason",
        key: "termination_reason",
        description: "The reason for termination.",
        type: "select",
        options: TERMINATION_REASON_OPTIONS,
        validators: [
          {
            validate: "required_with",
            fields: ["end_date"],
          },
        ],
      },
      {
        label: "Termination note",
        key: "note",
        description: "Notes for the termination.",
        validators: [
          {
            validate: "required_with",
            fields: ["end_date"],
          },
        ],
      },
      {
        label: "Eligible for rehire",
        key: "eligible_for_rehire",
        description: "Is the team member eligible to be rehired.",
        type: "select",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        validators: [
          {
            validate: "required_with",
            fields: ["end_date"],
          },
        ],
      },
      {
        label: "Last payday",
        key: "last_payday",
        description: "This field is to record the team member's last payday. This field is optional.",
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.last_payday)),
      },
      {
        label: "SSN",
        key: "ssn",
        description: "Team member's SSN",
        hook: (val) => (typeof val === "string" ? validateSSN(val) : validateSSN(val.ssn)),
      },
      {
        label: "Date of birth",
        key: "dob",
        description:
          "This field is required for employees (not contractors). Team members can add/edit their date of birth via the team member portal.",
        validators: upsert
          ? []
          : [
              {
                validate: "required_with_all_values",
                fieldValues: { employment_type: "employee", enroll_in_payroll: "true" },
              },
            ],
        hook: (row) => (typeof row === "string" ? validateDOB(row) : validateDOB(row.dob)),
      },
      {
        label: "Line 1",
        key: "line1",
        description:
          "Home address if the team member is an employee. If the team member if a contractor, use the legal address of the contractor.",
        validators: upsert
          ? []
          : [{ validate: "required_with_all_values", fieldValues: { enroll_in_payroll: "true" } }],
      },
      {
        label: "Line 2",
        key: "line2",
        description:
          "Home address if the team member is an employee. If the team member if a contractor, use the legal address of the contractor.",
      },
      {
        label: "City",
        key: "city",
        description:
          "Home address if the team member is an employee. If the team member if a contractor, use the legal address of the contractor.",
        validators: upsert
          ? []
          : [{ validate: "required_with_all_values", fieldValues: { enroll_in_payroll: "true" } }],
      },
      {
        label: "State",
        key: "state",
        type: "select",

        description:
          "Home address if the team member is an employee. If the team member if a contractor, use the legal address of the contractor.",
        validators: upsert
          ? []
          : [{ validate: "required_with_all_values", fieldValues: { enroll_in_payroll: "true" } }],
        options: stateOptions,
        hook: (row) => (typeof row === "string" ? validateState(row) : validateState(row.state)),
      },
      {
        label: "Postal code",
        key: "postal_code",
        description:
          "Home address if the team member is an employee. If the team member if a contractor, use the legal address of the contractor.",
        validators: [
          {
            validate: "regex_matches",
            regex: "^[0-9]{5}(?:-[0-9]{4})?$",
            error: "Must be a valid 5-digit or 9-digit zip code.",
          },
          ...(upsert
            ? []
            : [
                { validate: "required_with_all_values" as const, fieldValues: { enroll_in_payroll: "true" } },
              ]),
        ],
      },
      {
        label: "Pay type",
        key: "pay_type",
        type: "select",
        options: [
          { value: "salary", label: "Salary" },
          { value: "hourly", label: "Hourly" },
        ],
        validators: upsert
          ? []
          : [{ validate: "required_with_all_values", fieldValues: { enroll_in_payroll: "true" } }],
      },
      {
        label: "Pay rate",
        key: "pay_rate",
        description:
          "If the pay type is hourly, pay rate is the team member's hourly wage. If salary, then pay rate is the yearly wage.",

        validators: [
          ...(upsert
            ? []
            : [
                { validate: "required_with_all_values" as const, fieldValues: { enroll_in_payroll: "true" } },
              ]),
          {
            validate: "regex_matches",
            regex: "^\\$?[0-9]+\\.?[0-9]?[0-9]?$",
            error: "Must be a valid dollar amount.",
          },
        ],
      },
      {
        label: "Salary rate display",
        key: "salary_rate_display",
        type: "select",
        options: salaryRateOptions,
        description:
          "This field is only applicable if team member is a salaried employee (not contractor). If left blank, the salary rate will be displayed as a yearly salary.",
      },
      {
        label: "Pay effective date",
        key: "pay_rate_effective_date",
        description:
          "The date the pay rate is effective. If left blank, the pay rate will be effective immediately.",
        hook: (row) =>
          typeof row === "string" ? normalizeDate(row) : normalizeDate(row.pay_rate_effective_date),
      },
      {
        label: "Overtime exempt",
        key: "overtime_exempt",
        type: "select",
        options: [
          { value: "true", label: "True" },
          { value: "false", label: "False" },
        ],
        description: "If left blank, the employee will NOT be exempt from overtime.",
        validators: upsert
          ? []
          : [
              {
                validate: "required_with_all_values",
                fieldValues: { employment_type: "employee", enroll_in_payroll: "true" },
              },
            ],
      },
      {
        label: "Default overtime rule",
        key: "default_ot_rule_id",
        type: "select",
        options: otRuleOptions,
      },
      {
        label: "License status",
        key: "license_status",
        type: "select",
        options: [
          { value: "journeyman", label: "Journeyman" },
          { value: "apprentice", label: "Apprentice" },
        ],
      },
      {
        label: "Workers comp code",
        key: "wc_code",
        type: "select",
        options: wcCodeOptions,
      },
      {
        label: "Default job",
        key: "default_job_id",
        description: defaultTmJobTooltip,
        type: "select",
        options: jobOptions,
      },
      {
        label: "Default activity",
        key: "default_activity_id",
        description: defaultTmActivityTooltip,
        type: "select",
        options: activityOptions,
      },
      {
        label: "Standard classification",
        key: "standard_classification_id",
        description: stdTmClassificationTooltip,
        type: "select",
        options: stdClassificationOptions,
      },
      {
        label: "Classification",
        type: "select",
        key: "union_rate",
        description:
          "The classification to use as the team member's default pay rate (typically only used for union employees)",
        options: allClassificationOptions,
      },
      ...(enabledDemographicQuestions["ethnicity"]
        ? [
            {
              label: "Ethnicity",
              key: "demographics_ethnicity",
              type: "select" as const,
              options: eeoEthnicityCategories.map((category) => ({
                value: category,
                label: category,
              })),
            },
          ]
        : []),
      ...(enabledDemographicQuestions["gender"]
        ? [
            {
              label: "Gender",
              key: "demographics_gender",
              type: "select" as const,
              options: eeoGenderCategories.map((category) => ({
                value: category,
                label: category,
              })),
            },
          ]
        : []),
      ...(enabledDemographicQuestions["job_category"]
        ? [
            {
              label: "Job category",
              key: "demographics_job_category",
              type: "select" as const,
              options: eeoJobCategories.map((category) => ({
                value: category,
                label: category,
              })),
            },
          ]
        : []),
      ...(enabledDemographicQuestions["veteran_status"]
        ? [
            {
              label: "Veteran status",
              key: "demographics_veteran_status",
              type: "select" as const,
              options: eeoVeteranStatusCategories.map((category) => ({
                value: category,
                label: category,
              })),
            },
          ]
        : []),
      ...(enabledDemographicQuestions["marital_status"]
        ? [
            {
              label: "Marital status",
              key: "demographics_marital_status",
              type: "select" as const,
              options: eeoMaritalStatusCategories.map((category) => ({
                value: category,
                label: category,
              })),
            },
          ]
        : []),
      ...(enabledDemographicQuestions["disability_status"]
        ? [
            {
              label: "Disability status",
              key: "demographics_disability_status",
              type: "select" as const,
              options: eeoDisabilityStatusCategories.map((category) => ({
                value: category,
                label: category,
              })),
            },
          ]
        : []),
      {
        label: "Payment method",
        key: "payment_method_preference",
        description:
          "Whether the team member should be paid via direct deposit or paper check. Only applicable if the team member is enrolled in payroll.",
        type: "select",
        options: [
          { value: "direct_deposit", label: "Direct deposit" },
          { value: "manual", label: "Paper check" },
        ],
      },
      ...(upsert
        ? [
            {
              label: "Overwrite previous bank accounts?",
              key: "overwrite_bank_account",
              description:
                "If selected, all previous bank accounts for this team member will be deleted and replaced with this new account.",
              type: "checkbox" as const,
              sizeHint: 2,
            },
          ]
        : []),
      {
        label: "Bank account type",
        key: "bank_account_type",
        type: "select",
        options: [
          { value: "checking", label: "Checking" },
          { value: "savings", label: "Savings" },
        ],
        validators: [
          {
            validate: "required_with",
            fields: ["bank_routing_number", "bank_account_number"],
          },
        ],
      },
      {
        label: "Routing number",
        key: "bank_routing_number",
        validators: [
          {
            validate: "required_with",
            fields: ["bank_account_type", "bank_account_number"],
          },
        ],
        hook: (row) =>
          typeof row === "string" ? validateRoutingNum(row) : validateRoutingNum(row.bank_routing_number),
      },
      {
        label: "Account number",
        key: "bank_account_number",
        validators: [
          {
            validate: "regex_matches",
            regex: "^[0-9]{4,17}$",
            error: "Please enter a number between 4 and 17 digits.",
          },
          {
            validate: "required_with",
            fields: ["bank_routing_number", "bank_account_type"],
          },
        ],
      },
      {
        label: "Can enable kiosk",
        key: "can_enable_kiosk",
        type: "select",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
      },
      {
        label: "Timesheet policy",
        key: "timesheet_policy_id",
        type: "select",
        options: timesheetPolicyOptions,
      },
      {
        label: "Time off request policy",
        key: "time_off_request_policy_id",
        type: "select",
        options: timeOffRequestPolicyOptions,
      },
      {
        label: "Expense policy",
        key: "expense_policy_id",
        type: "select",
        options: expensePolicyOptions,
      },
      {
        label: "Reimbursement policy",
        key: "reimbursement_policy_id",
        type: "select",
        options: reimbursementPolicyOptions,
      },
      {
        label: "Team member change request policy",
        key: "team_member_change_request_policy_id",
        type: "select",
        options: teamMemberChangeRequestPolicyOptions,
      },
      {
        label: "GL mapping",
        key: "ledger_mapping_id",
        type: "select",
        options: ledgerMappingOptions,
      },
      ...(hasEnabledWcGroups
        ? [
            {
              label: "Workers comp group",
              key: "wc_group_id",
              type: "select" as const,
              options: wcGroupOptions,
            },
          ]
        : []),
      ...customFieldColumns,
    ];

    return fieldList;
  }, [
    isActiveOnClasp,
    deptOptions,
    locationOptions,
    teamOptions,
    wcCodeOptions,
    benefitsEligibilityGroupOptions,
    upsert,
    customFieldColumns,
  ]);

  const employmentHistoryFields = useMemo(() => {
    if (!importEmploymentHistory) return [];
    const employmentHistoryFieldsList: ImportField[] = [
      {
        label: "ID",
        key: "friendly_id",
        type: "string",
        description: "Unique identifier (typically a number) for this team member.",
        hook: (val) =>
          typeof val === "string" ? validateTeamMemberID(val) : validateTeamMemberID(val.friendly_id),
        validators: [{ validate: "required" }],
      },
      {
        label: "Rehire start date",
        key: "start_date",
        description: "The date of the rehire event. This field is required for rehire entries.",
        validators: [
          {
            validate: "required_without",
            fields: ["end_date"],
          },
        ],
        hook: validateAndNormalizeStartDate,
      },
      {
        label: "Dismissal end date",
        key: "end_date",
        description: "The date of the dismissal event. This field is required for dismissal entries.",
        hook: validateAndNormalizeEndDate,
        validators: [{ validate: "required_without", fields: ["start_date"] }],
      },
      {
        label: "Dismissal note",
        key: "note",
        description: "Notes for the dismissal. This field is required for dismissal entries.",
        validators: [
          {
            validate: "required_with",
            fields: ["end_date"],
          },
        ],
      },
      {
        label: "Involuntary dismissal",
        key: "involuntary",
        description: "Whether the dismissal was involuntary. This field is required for dismissal entries.",
        type: "select",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        validators: [{ validate: "required_with", fields: ["end_date"] }],
      },
      {
        label: "Eligible for rehire",
        key: "eligible_for_rehire",
        description: "Is the team member eligible to be rehired.",
        type: "select",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        validators: [],
      },
      {
        label: "Termination reason",
        key: "termination_reason",
        description: "The reason for termination.",
        type: "select",
        options: TERMINATION_REASON_OPTIONS,
        validators: [],
      },
      {
        label: "Last payday",
        key: "last_payday",
        description: "The date of the team member's last payday.",
        validators: [],
        hook: (row) => (typeof row === "string" ? normalizeDate(row) : normalizeDate(row.last_payday)),
      },
    ];
    return employmentHistoryFieldsList;
  }, [upsert, importEmploymentHistory]);

  const getImporterProps = useCallback(() => {
    if (importEmploymentHistory) {
      return {
        id: `${overwriteHistory ? "overwritten_" : ""}employment_history`,
        resource: "rehires/dismissals",
        onSave: handleEmploymentHistorySubmit,
        fields: employmentHistoryFields,
        title: overwriteHistory ? "Overwrite employment history" : "Add to employment history",
        preload: false,
      };
    } else {
      return {
        id: "team_member",
        resource: "team member",
        onSave: handleSubmit,
        fields: fields,
        title: upsert ? "Update team members" : "Create team members",
        preload: false,
      };
    }
  }, [
    importEmploymentHistory,
    overwriteHistory,
    handleEmploymentHistorySubmit,
    employmentHistoryFields,
    handleSubmit,
    fields,
    upsert,
    otRuleOptions,
  ]);

  const importerProps = getImporterProps();

  return <Importer {...importerProps} ImportButtonComponent={ImportButtonComponent} />;
};
