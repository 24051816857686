import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedCertification, Certification } from "dashboard/types/certification-types";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { AggregatedTeamMember } from "dashboard/miter";

/*
 * CERTIFICATIONS
 */
type CertificationParams = Certification | AggregatedCertification | AggregatedCertification[] | undefined;

type CertificationAction = "upload" | "request" | "read" | "update" | "delete";
type CertificationAbilities = {
  can: (action: CertificationAction, items: CertificationParams) => boolean;
  cannot: (action: CertificationAction, items: CertificationParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: CertificationAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useCertificationAbilities = (): CertificationAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: CertificationAction, items?: CertificationParams) => {
      if (!items) return false;
      const certifications = Array.isArray(items) ? items : [items];

      return certifications.every((certification) => {
        const teamMemberId = certification.team_member_id;

        switch (action) {
          case "upload":
            return can_("certifications:certifications:upload", { teamMember: teamMemberId });
          case "request":
            return can_("certifications:certifications:request", { teamMember: teamMemberId });
          case "read":
            return can_("certifications:certifications:read", { teamMember: teamMemberId });
          case "update":
            return can_("certifications:certifications:update", { teamMember: teamMemberId });
          case "delete":
            return can_("certifications:certifications:delete", { teamMember: teamMemberId });
          default:
            return false;
        }
      });
    },
    [can_]
  );

  const cannot = useCallback(
    (action: CertificationAction, items: CertificationParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "certifications:certifications",
    teamMemberField: { fieldName: "team_member_id", fieldType: "string" },
    appModule: "human_resources",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<CertificationAction>("certifications:certifications", true);

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, teamPredicate]);
};
