import React, { useState, useMemo } from "react";
import { MiterAPI, AggregatedJob, BulkUpdateResult, Activity } from "dashboard/miter";
import { ActionModal, LoadingModal } from "ui";
import { CreateActivityModal } from "../activities/CreateActivityModal";
import Notifier from "dashboard/utils/notifier";
import { BasicModal } from "ui";
import { TabbedActivityModal } from "../activities/TabbedActivityModal";
import { Link } from "react-router-dom";
import { ClockCounterClockwise, Download, Plus, Trash, X } from "phosphor-react";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import {
  useCostTypeOptions,
  useHasEnabledWcGroups,
  useLedgerMappingOptions,
  useLookupActivity,
  useLookupCostType,
  useLookupLedgerMapping,
  useLookupRateDifferential,
  useLookupWcCode,
  useLookupWcGroup,
  useRateDifferentialOptions,
  useRefetchActivities,
  useRefetchJobs,
  useSelectableActivitiesMap,
  useSetActivities,
  useWcCodeOptions,
  useWcGroupOptions,
} from "dashboard/hooks/atom-hooks";
import { useEnhancedSearchParams } from "miter-utils";
import { ActivityImporter } from "../activities/ActivitiesImporter";
import { ImportHistory } from "../importer/ImportHistory";
import { activityPayRateTypeLookup } from "../activities/activityModalUtils";
import {
  isExpenseScoped,
  isReimbursementScoped,
  isTimesheetScoped,
} from "dashboard/pages/activities/activityUtils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {
  defaultSelectedRows?: Activity[];
  hiddenActivitiesSet?: Set<Activity>;
  job?: AggregatedJob;
  hideActions?: boolean;
  onSelect?: (selectedRows: Activity[]) => void;
};

export type ActivityTableEntry = Activity & {
  timesheets_scope?: boolean;
  expenses_scope?: boolean;
  reimbursements_scope?: boolean;
};

const ActivitiesTable: React.FC<Props> = ({
  defaultSelectedRows,
  job,
  hideActions,
  hiddenActivitiesSet,
  onSelect,
}: Props) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const refetchJobs = useRefetchJobs();
  const refetchActivities = useRefetchActivities();
  const setActivities = useSetActivities();
  const lookupActivity = useLookupActivity();
  const ledgerMappingOptions = useLedgerMappingOptions();
  const lookupRateDifferential = useLookupRateDifferential();
  const rateDifferentialOptions = useRateDifferentialOptions();
  const lookupLedgerMapping = useLookupLedgerMapping();
  const costTypeOptions = useCostTypeOptions();
  const lookupCostType = useLookupCostType();
  const lookupWcGroup = useLookupWcGroup();
  const lookupWcCode = useLookupWcCode();
  const hasEnabledWcGroups = useHasEnabledWcGroups();

  const wcCodeOptions = useWcCodeOptions();
  const wcGroupOptions = useWcGroupOptions({ predicate: (wcGroup) => wcGroup.type !== "job_and_cost_code" });
  const selectableActivitiesMap = useSelectableActivitiesMap();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const activityIdToLoad = parsedSearchParams.aid;
  const { can, cannot } = useMiterAbilities();

  const tableEntries: ActivityTableEntry[] = useMemo(() => {
    let activities = selectableActivitiesMap.get(job?._id) || [];
    if (hiddenActivitiesSet) {
      activities = activities.filter((a) => !hiddenActivitiesSet.has(a));
    }

    return activities.map((a) => ({
      ...a,
      timesheets_scope: isTimesheetScoped(a),
      expenses_scope: isExpenseScoped(a),
      reimbursements_scope: isReimbursementScoped(a),
    }));
  }, [selectableActivitiesMap, job?._id, hiddenActivitiesSet]);

  /*********************************************************
   *  Initialize states
   **********************************************************/
  // States related to the table
  const selectedActivity = lookupActivity(activityIdToLoad);

  const [selectedRows, setSelectedRows] = useState<ActivityTableEntry[]>(defaultSelectedRows || []);
  const [showImportHistory, setShowImportHistory] = useState<boolean>(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState<boolean>(false);

  // States related to table actions
  const [adding, setAdding] = useState<boolean | null>(false);
  const [removing, setRemoving] = useState<boolean | null>(false);
  const [archiving, setArchiving] = useState<boolean>(false);

  const [showCompanyActivitiesSelector, setShowCompanyActivitiesSelector] = useState<boolean>(false);
  const [tentativelySelectedActivityIds, setTentativelySelectedActivityIds] = useState<string[]>([]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const isJobActivities = !!job;
  const jobCompanyActivities = tableEntries.filter((a) => a.company_activity);

  const [loading, setLoading] = useState<boolean>(false);

  const columns = useMemo(() => {
    const prelimColumns: ColumnConfig<ActivityTableEntry>[] = [
      {
        headerName: "Activity",
        field: "label",
        dataType: "string",
        editable: true,
        editorType: "text",
      },
      {
        headerName: "Pay rate type",
        field: "pay_rate_type",
        valueGetter: (params) =>
          params.data?.pay_rate_type ? activityPayRateTypeLookup[params.data.pay_rate_type] : undefined,
        headerTooltip: "How Miter determines the hourly pay rate for this activity.",
        dataType: "string",
        displayType: "badge",
        colors: {
          "Prevailing wage": "yellow",
          "Pay rate group": "blue",
          Custom: "green",
          Default: "gray",
        },
      },
      {
        headerName: "Cost code",
        field: "cost_code",
        dataType: "string",
        editable: true,
        editorType: "text",
      },
      {
        field: "wc_code",
        headerName: "Workers' comp code",
        dataType: "string",
        valueGetter: (params) => {
          const wcCode = lookupWcCode(params.data?.wc_code);
          return wcCode?.label || wcCode?.code;
        },
        editable: true,
        editorType: "select",
        cellEditorParams: {
          options: wcCodeOptions,
          isClearable: true,
        },
      },

      {
        field: "timesheets_scope",
        headerName: "Scopes: timesheets",
        initialHide: true,
        filter: true,
        dataType: "boolean",
        editorType: "checkbox",
        editable: true,
      },
      {
        field: "expenses_scope",
        headerName: "Scopes: expenses",
        initialHide: true,
        filter: true,
        dataType: "boolean",
        editorType: "checkbox",
        editable: true,
      },
      {
        field: "reimbursements_scope",
        headerName: "Scopes: reimbursements",
        initialHide: true,
        filter: true,
        dataType: "boolean",
        editorType: "checkbox",
        editable: true,
      },
      {
        field: "rate_differential_id",
        headerName: "Rate differential",
        dataType: "string",
        initialHide: true,
        valueGetter: (params) => {
          const rd = lookupRateDifferential(params.data?.rate_differential_id);
          return rd?.label;
        },
        editable: true,
        editorType: "select",
        cellEditorParams: {
          options: rateDifferentialOptions,
          isClearable: true,
        },
      },
      {
        field: "default_cost_type_id",
        headerName: "Default cost type",
        editorType: "select",
        initialHide: true,
        cellEditorParams: {
          options: costTypeOptions,
          isClearable: true,
        },
        editable: true,
        valueGetter: (params) => {
          const mapping = lookupCostType(params.data?.default_cost_type_id);
          return mapping?.label;
        },
      },
      {
        field: "ledger_mapping_id",
        headerName: "GL account mapping",
        editorType: "select",
        initialHide: true,
        cellEditorParams: {
          options: ledgerMappingOptions,
          isClearable: true,
        },
        editable: true,
        valueGetter: (params) => {
          const mapping = lookupLedgerMapping(params.data?.ledger_mapping_id);
          return mapping?.name;
        },
      },
    ];

    if (!job) {
      prelimColumns.push({
        headerName: "Default",
        field: "default",
        dataType: "boolean",
        headerTooltip:
          "If true, this activity will be included by default when you enable custom activities for a job.",
      });
    } else {
      prelimColumns.push({
        headerName: "Company-level",
        field: "company_activity",
        dataType: "boolean",
        headerTooltip:
          "If yes, this an imported company activity. If not, this a custom activity created for just this job.",
      });
    }

    if (hasEnabledWcGroups) {
      prelimColumns.push({
        field: "wc_group_id",
        headerName: "Workers' comp group",
        dataType: "string",
        valueGetter: (params) => {
          const wcGroup = lookupWcGroup(params.data?.wc_group_id);
          return wcGroup?.name || "-";
        },
        editable: true,
        editorType: "select",
        cellEditorParams: {
          options: wcGroupOptions,
          isClearable: true,
        },
      });
    }
    return prelimColumns;
  }, [job, wcCodeOptions, ledgerMappingOptions, costTypeOptions, lookupWcGroup, hasEnabledWcGroups]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/

  const handleRowClick = async (activity: ActivityTableEntry | undefined) => {
    if (activity?._id) {
      setSearchParams({ aid: activity._id });
    }
  };

  const handleAdd = () => {
    setAdding(true);
  };

  const hideActivityModal = () => {
    setSearchParams({ aid: undefined });
  };

  const handleArchive = async () => {
    if (cannot("lists:activities:manage")) {
      Notifier.error("You don't have permission to manage activities");
    }

    setLoading(true);
    try {
      const results: Activity[] = [];
      await Promise.all(
        selectedRows.map(async (activity) => {
          const response = await MiterAPI.activities.archive(activity._id, job?._id);
          if (response.error) {
            console.error(response.error);
            Notifier.error(`Error deleting "${activity.label}"`);
          } else {
            results.push(response);
            if (selectedRows.length < 5) {
              Notifier.success(`Successfully deleted ${activity.label}`);
            }
          }
        })
      );

      if (selectedRows.length >= 5) {
        Notifier.success(`Successfully deleted ${results.length} activities`);
      }

      setActivities((prev) => prev.concat(results));
      setArchiving(false);
      setSelectedRows([]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting one or more activities. We're looking into it.");
    }
    setLoading(false);
  };

  const handleRemove = async () => {
    if (cannot("lists:activities:manage")) {
      Notifier.error("You don't have permission to manage activities");
    }

    setLoading(true);
    try {
      if (!job) throw new Error("You can only remove activities that are tied to a job.");

      const selectedRowIds = selectedRows.map((a) => a._id);
      const updatedActivityIds = tableEntries
        .filter((a) => !selectedRowIds.includes(a._id))
        .map((a) => a._id);

      const res = await MiterAPI.jobs.update(job._id, { activities: updatedActivityIds });
      if (res.error) throw new Error(res.error);

      setLoading(true);
      await refetchJobs(job._id);

      const singPlur = selectedRows.length > 1 ? "Activities" : "Activity";
      Notifier.success(singPlur + " successfully removed.");
      setRemoving(false);
      setSelectedRows([]);
    } catch (e) {
      console.error("Error removing company activities from job:", e);
      Notifier.error("There was an error removing one or more activities. We're looking into it.");
    }
    setLoading(false);
  };

  const handleUpdateJobCompanyActivities = async () => {
    if (!job) return;
    if (cannot("lists:activities:manage")) {
      Notifier.error("You don't have permission to manage activities");
      return;
    }

    setModalLoading(true);
    try {
      const customActivityIds = tableEntries.filter((a) => !a.company_activity)?.map((a) => a._id);
      const companyActivityIds = tentativelySelectedActivityIds;
      const updatedActivityIds = [...customActivityIds, ...companyActivityIds];

      const res = await MiterAPI.jobs.update(job._id, { activities: updatedActivityIds });
      if (res.error) throw new Error(res.error);

      setLoading(true);
      await refetchJobs(job._id);

      Notifier.success("Activities successfully updated.");
      setShowCompanyActivitiesSelector(false);
    } catch (e: $TSFixMe) {
      console.error("Error updating job company activities:", e);
      Notifier.error("There was an error updating this job company activities. Please contact support.");
    }
    setLoading(false);
    setModalLoading(false);
  };

  const handleBulkCreateFinish = async () => {
    await refetchActivities();
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const selectedRowsAreAllCompanyActivities = selectedRows.every(
    (row) => (tableEntries || []).find((a) => a._id.toString() === row._id)?.company_activity
  );

  const selectedRowsAreAllCustomActivities = selectedRows.every(
    (row) => !(tableEntries || []).find((a) => a._id.toString() === row._id)?.company_activity
  );
  const dynamicActions: TableActionLink[] = useMemo(() => {
    if (hideActions) return [];

    return [
      {
        label: "Delete",
        action: () => setArchiving(true),
        className: "button-3",
        icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () =>
          can("lists:activities:manage") && (!isJobActivities || selectedRowsAreAllCustomActivities),
      },
      {
        label: "Remove from Job",
        action: async () => setRemoving(true),
        className: "button-1",
        icon: <X weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () =>
          can("lists:activities:manage") && isJobActivities && selectedRowsAreAllCompanyActivities,
      },
    ];
  }, [
    selectedRows,
    isJobActivities,
    selectedRowsAreAllCompanyActivities,
    selectedRowsAreAllCustomActivities,
    can,
    hideActions,
  ]);

  const staticActions: TableActionLink[] = useMemo(() => {
    if (hideActions) return [];

    return [
      {
        label: "Import company activities",
        icon: <Download weight="bold" style={{ marginRight: 3 }} />,
        className: "button-1",
        action: () => setShowCompanyActivitiesSelector(true),
        shouldShow: () => can("lists:activities:manage") && isJobActivities,
      },
      {
        label: "New activity",
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        className: "button-2",
        action: handleAdd,
        important: true,
        shouldShow: () => can("lists:activities:manage"),
      },
      {
        key: "import",
        component: <ActivityImporter onFinish={handleBulkCreateFinish} />,
        shouldShow: () => can("lists:activities:manage"),
      },
      {
        key: "import-history",
        className: "button-1",
        action: () => setShowImportHistory(true),
        label: "Import history",
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:activities:manage"),
      },
    ];
  }, [can, isJobActivities, hideActions, can]);

  const updateActivities = async (activityParamsArray: ActivityTableEntry[]): Promise<BulkUpdateResult> => {
    if (cannot("lists:activities:manage")) {
      Notifier.error("You don't have permission to manage activities");
      return { successes: [], errors: [] };
    }

    try {
      const params = activityParamsArray.map((a) => {
        const scopes: Activity["scopes"] = [];
        if (a.timesheets_scope) scopes.push("timesheets");
        if (a.expenses_scope) scopes.push("expenses");
        if (a.reimbursements_scope) scopes.push("reimbursements");
        return {
          _id: a._id,
          params: {
            label: a.label,
            cost_code: a.cost_code,
            scopes,
            ledger_mapping_id: a.ledger_mapping_id,
            default_cost_type_id: a.default_cost_type_id,
            wc_code: a.wc_code,
            wc_group_id: a.wc_group_id || null,
            rate_differential_id: a.rate_differential_id,
          },
        };
      });
      const res = await MiterAPI.activities.bulk_update(params);
      if (res.error) throw Error(res.error);

      if (res.errors.length > 0) {
        const successes = res.successes;
        const errors = res.errors;

        return { successes, errors };
      }

      setActivities((prev) => prev.concat(res.successes.map((a) => a.item)));
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem updating the activities. We're looking into it.");
      return { successes: [], errors: [] };
    }
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderTable = () => {
    return (
      <TableV2
        id={job ? "job-activities-table" : "company-activities-table"}
        resource="activities"
        data={tableEntries}
        columns={columns}
        onSelect={(rows) => {
          onSelect?.(rows);
          setSelectedRows(rows);
        }}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={!hideActions}
        onClick={handleRowClick}
        isLoading={loading}
        hideSecondaryActions={hideActions}
        defaultSelectedRows={selectedRows}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"activities-table-container"}
        editable={can("lists:activities:manage")}
        onSave={updateActivities}
      />
    );
  };

  const renderCompanyActivitiesSelector = () => {
    return (
      <ActionModal
        headerText={"Select company-level activities for this job"}
        submitText={"Submit"}
        showSubmit={true}
        cancelText={"Cancel"}
        showCancel={true}
        onHide={() => setShowCompanyActivitiesSelector(false)}
        onSubmit={handleUpdateJobCompanyActivities}
        onCancel={() => setShowCompanyActivitiesSelector(false)}
        wrapperClassName={"medium-width-modal-wrapper"}
        loading={modalLoading}
      >
        <p>
          Select the{" "}
          <Link to={"/settings/activities"} className="purple-link">
            company-level activities
          </Link>{" "}
          that you would like to use on this job.
        </p>
        <ActivitiesTable
          onSelect={(activities) => setTentativelySelectedActivityIds(activities.map((a) => a._id))}
          defaultSelectedRows={jobCompanyActivities}
          hideActions={true}
        />
      </ActionModal>
    );
  };

  return (
    <div className="activities-table-wrapper">
      {adding && <CreateActivityModal job={job} hide={() => setAdding(false)} />}
      {selectedActivity && (
        <TabbedActivityModal
          selectedActivity={selectedActivity}
          hide={hideActivityModal}
          readonly={cannot("lists:activities:manage")}
        />
      )}
      {!selectedActivity && activityIdToLoad && <LoadingModal />}
      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={handleArchive}
          headerText={"Delete activit" + (selectedRows.length > 1 ? "ies" : "y")}
          bodyText={
            "Are you sure you want to delete the selected activit" +
            (selectedRows.length > 1 ? "ies" : "y") +
            "? To restore an deleted activity, you must contact Miter Support."
          }
        />
      )}
      {removing && (
        <BasicModal
          loading={loading}
          button2Text="Remove"
          button1Action={() => setRemoving(false)}
          button1Text="Cancel"
          button2Action={handleRemove}
          headerText={"Remove activit" + (selectedRows.length > 1 ? "ies" : "y")}
          bodyText={
            "Are you sure you want to remove the selected activit" +
            (selectedRows.length > 1 ? "ies" : "y") +
            " from this job? They will still be available in the company's list of activities."
          }
        />
      )}
      {showCompanyActivitiesSelector && renderCompanyActivitiesSelector()}
      {renderTable()}
      {showImportHistory && (
        <ImportHistory
          id={"activities"}
          resource={"activities"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
    </div>
  );
};

export default ActivitiesTable;
