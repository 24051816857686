import React, { useMemo, useState } from "react";
import { Button, Formblock, Label } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { X } from "phosphor-react";
import { benefitSelections } from "dashboard/pages/benefits/benefitsUtils";
import { useForm } from "react-hook-form";
import { EN_POST_TAX_DEDUCTION_OPTIONS } from "dashboard/utils/employee-navigator-utils";
import { useDebouncedCallback } from "use-debounce";

type DeductionCodeMapping = {
  code: string;
  benefitType?: string;
};

const useDeductionCodes = (integration: IntegrationConfigProps["integration"]) => {
  const deductionCodesMap = integration.connection?.metadata?.employee_navigator?.deductionCodesMap || {};
  return useMemo(() => {
    return Object.keys(deductionCodesMap)
      .map((code) => ({ code, benefitType: deductionCodesMap[code] }))
      .sort((a, b) => {
        return a.code.localeCompare(b.code);
      });
  }, [deductionCodesMap]);
};

export const EmployeeNavigatorIntegrationConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const { control, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const updateSettingsDebounced = useDebouncedCallback(() => {
    handleSubmit(updateSettings)();
  }, 500);

  const employeeNavigatorBenefitSelections = (
    benefitSelections as {
      value: string;
      label: string;
    }[]
  ).concat(EN_POST_TAX_DEDUCTION_OPTIONS);

  const deductionCodesArray = useDeductionCodes(integration);
  const [currentDeductionCodeArray, setCurrentDeductionCodeArray] =
    useState<DeductionCodeMapping[]>(deductionCodesArray);

  const updateSettings = async (data) => {
    await updateIntegrationConnection({
      "metadata.employee_navigator.syncPhoneNumbersWithoutCountryCode":
        data.syncPhoneNumbersWithoutCountryCode,
    });
  };

  const handleSaveDeductionCodes = async () => {
    setLoading(true);
    const newDeductionCodesMap = currentDeductionCodeArray?.reduce((acc, { code, benefitType }) => {
      if (code && benefitType) {
        acc[code] = benefitType;
      }
      return acc;
    }, {} as Record<string, string>);

    const originalMetadata = integration.connection?.metadata?.employee_navigator || {};
    const cleanedUpdate = { ...originalMetadata, deductionCodesMap: newDeductionCodesMap };

    await updateIntegrationConnection({ metadata: { employee_navigator: cleanedUpdate } });
    setLoading(false);
  };

  const handleAddDeductionCode = () => {
    const newDeductionCodesArray = [...currentDeductionCodeArray];
    newDeductionCodesArray.push({ code: "", benefitType: "" });
    setCurrentDeductionCodeArray(newDeductionCodesArray);
  };

  const handleChangeDeductionCode = (index: number, value: string) => {
    const newDeductionCodesArray = [...currentDeductionCodeArray];
    newDeductionCodesArray[index]!.code = value;
    setCurrentDeductionCodeArray(newDeductionCodesArray);
  };

  const handleChangeBenefitType = (index: number, value: string) => {
    const newDeductionCodesArray = [...currentDeductionCodeArray];
    newDeductionCodesArray[index]!.benefitType = value;
    setCurrentDeductionCodeArray(newDeductionCodesArray);
  };

  const handleDeleteDeductionCode = (index: number) => {
    const newDeductionCodesArray = [...currentDeductionCodeArray];
    newDeductionCodesArray.splice(index, 1);
    setCurrentDeductionCodeArray(newDeductionCodesArray);
  };

  return (
    <div style={{ maxWidth: 600 }}>
      <div className="vertical-spacer-small"></div>
      <div>
        <Label
          label="Settings"
          sublabel="Configure your EmployeeNavigator integration settings."
          style={{ width: "100%" }}
        />
        <div>
          <Formblock
            type="checkbox"
            name="syncPhoneNumbersWithoutCountryCode"
            text="Sync team member phone numbers without country code (+1)"
            className="modal"
            control={control}
            register={register}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={
              integration.connection?.metadata?.employee_navigator?.syncPhoneNumbersWithoutCountryCode
            }
          />
        </div>
      </div>
      <div className="vertical-spacer-small"></div>
      <div>
        <Label
          label="Deduction Codes"
          sublabel="Map your deduction codes to benefit types so that your benefits can be synced from EmployeeNavigator into Miter."
          style={{ marginBottom: 15, width: "100%" }}
        />
        {currentDeductionCodeArray.map(({ code, benefitType }, index) => {
          return (
            <div className="flex align-items-center" key={index}>
              <Formblock
                className={"modal width-100-percent"}
                placeholder={`Deduction code`}
                type="text"
                name={`code-${index}-${code}`}
                value={code}
                editing={true}
                onChange={(e) => handleChangeDeductionCode(index, e.target.value?.trim())}
              />
              <Formblock
                className={"modal width-100-percent"}
                placeholder={`Benefit type`}
                type="select"
                options={employeeNavigatorBenefitSelections}
                name={`deductionCodes.${index}.benefitType`}
                control={control}
                value={employeeNavigatorBenefitSelections.find((b) => b.value === benefitType)}
                editing={true}
                onChange={(o) => handleChangeBenefitType(index, o.value)}
                style={{ marginLeft: 10 }}
              />

              <div>
                <Button className="button-text" onClick={() => handleDeleteDeductionCode(index)}>
                  <X style={{ marginLeft: 10, marginTop: -10 }} />
                </Button>
              </div>
            </div>
          );
        })}
        {!currentDeductionCodeArray.length && (
          <div style={{ marginBottom: 25, opacity: 0.7 }}>No options selected</div>
        )}

        <div className="flex" style={{ marginTop: 20 }}>
          <Button
            className="button-2 no-margin"
            text="Save changes"
            onClick={() => handleSaveDeductionCodes()}
            loading={loading}
          />
          <Button className="button-1" text="Add option" onClick={() => handleAddDeductionCode()} />
        </div>
      </div>
    </div>
  );
};
