import React, { useEffect } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { useForm } from "react-hook-form";

import {
  eeoEthnicityCategories,
  eeoGenderCategories,
  eeoJobCategories,
  eeoVeteranStatusCategories,
} from "miter-utils";
import { MiterAPI, TeamMember } from "dashboard/miter";
import { Props } from "./types";
import { Option } from "ui/form/Input";
import {
  EEOEthnicityCategory,
  EEOGenderCategory,
  EEOJobCategory,
  EEOVeteranStatus,
} from "../../../backend/models/teamMember/teamMemberTypes";
import useWizard from "ui/modal/useWizard";

export const EEOWizardScreen: React.FC<Props> = ({ task, name, onboardingChecklistItem }) => {
  const { new_hire } = onboardingChecklistItem;

  const form = useForm<NonNullable<TeamMember["demographics"]>>({
    mode: "all",
  });
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();
  const {
    errors,
    control,
    handleSubmit,
    formState: { errors: formErrors },
    trigger,
  } = form;

  useEffect(() => {
    if (Object.keys(errors).length) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formErrors]);

  const onNext = async () => {
    handleSubmit(async (data: { [x: string]: Option<string> | null }) => {
      const ethnicity = data.ethnicity ? (data.ethnicity.value as EEOEthnicityCategory) : undefined;
      const job_category = data.job_category ? (data.job_category.value as EEOJobCategory) : undefined;
      const gender = data.gender ? (data.gender.value as EEOGenderCategory) : undefined;
      const veteran_status = data.veteran_status
        ? (data.veteran_status.value as EEOVeteranStatus)
        : undefined;

      try {
        const res = await MiterAPI.team_member.update(new_hire._id, {
          demographics: {
            ...new_hire.demographics,
            ethnicity,
            job_category,
            gender,
            veteran_status,
          },
        });
        if (res.fields?.length) {
          throw new Error(res.fields?.map((field) => field.error).join(", "));
        }
        if (res.error) {
          throw new Error(res.error);
        }
        if (curIndex === screens.length - 1) {
          handleComplete();
        }
      } catch (e: $TSFixMe) {
        console.error("Error saving team member", e);
        Notifier.error(e.message);
        throw e;
      }
    })();
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        <Formblock
          label="Ethnicity"
          labelInfo="This data must be self-reported by the employee"
          defaultValue={new_hire.demographics?.ethnicity}
          type="select"
          name="ethnicity"
          options={eeoEthnicityCategories.map((c) => ({ label: c, value: c }))}
          onChange={(_e) => {
            trigger();
          }}
          form={form}
          className="modal small-margin"
          errors={errors}
          editing={true}
          requiredSelect={true}
        />
        <Formblock
          label="Gender"
          labelInfo="This data must be self-reported by the employee"
          defaultValue={new_hire.demographics?.gender}
          type="select"
          name="gender"
          options={eeoGenderCategories.map((c) => ({ label: c, value: c }))}
          control={control}
          className="modal small-margin"
          errors={errors}
          editing={true}
          requiredSelect={true}
          onChange={(_e) => {
            trigger();
          }}
        />
        <Formblock
          label="Veteran Status"
          labelInfo="This data must be self-reported by the employee"
          defaultValue={new_hire.demographics?.veteran_status}
          type="select"
          name="veteran_status"
          options={eeoVeteranStatusCategories.map((c) => ({ label: c, value: c }))}
          control={control}
          className="modal small-margin"
          errors={errors}
          editing={true}
          requiredSelect={true}
          onChange={(_e) => {
            trigger();
          }}
        />
        <Formblock
          label="Job category"
          defaultValue={new_hire.demographics?.job_category}
          type="select"
          name="job_category"
          options={eeoJobCategories.map((c) => ({ label: c, value: c }))}
          control={control}
          className="modal small-margin"
          errors={errors}
          editing={true}
          requiredSelect={true}
          onChange={(_e) => {
            trigger();
          }}
        />
      </div>
    </WizardScreen>
  );
};
