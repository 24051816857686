import React, { useContext } from "react";
import { useActiveCompanyId, useLookupCompanyUsers, useTeam } from "dashboard/hooks/atom-hooks";
import { MiterAPI, ImportResult } from "dashboard/miter";
import { capitalize } from "dashboard/utils";
import { useState, useEffect, useMemo } from "react";
import { Notifier, ActionModal, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { singular } from "pluralize";
import { buildCompanyUserName, deparameterize, splitCamelCase } from "miter-utils";
import { DateTime } from "luxon";
import { keyBy } from "lodash";
import AppContext from "dashboard/contexts/app-context";
import { TABLE_COLUMN_CUSTOM_FIELD_PREFIX, getCustomIdFromColumnKey } from "dashboard/utils/custom-fields";

type ImportHistoryModalProps = {
  id: string;
  resource: string;
  onClose: () => void;
  openLastResult?: boolean;
};

type ImportHistoryTableEntry = {
  _id: string;
  created_at: number;
  user_id: string;
  success_count: number;
  warning_count: number;
  error_count: number;
  full_result: ImportResult;
} & { [key: string]: $TSFixMe };

const SENSITIVE_KEYS_SET = new Set(["password", "ssn", "bank_routing_number", "bank_account_number"]);

export const ImportHistory: React.FC<ImportHistoryModalProps> = ({
  id,
  resource,
  onClose,
  openLastResult,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const lookupCompanyUser = useLookupCompanyUsers();

  const [history, setHistory] = useState<ImportResult[]>();
  const [openResult, setOpenResult] = useState<ImportResult>();

  useEffect(() => {
    getImportResults();
  }, [id]);

  /** Open the last result if the prop is set */
  useEffect(() => {
    if (openLastResult && history && history.length) {
      setOpenResult(history[0]);
    }
  }, [openLastResult, history]);

  const getImportResults = async () => {
    if (!activeCompanyId) return;
    try {
      const res = await MiterAPI.import_results.search([
        { field: "importer_id", value: id },
        { field: "company_id", value: activeCompanyId },
      ]);

      if (res.error) throw new Error(res.error);

      setHistory(res);
    } catch (error) {
      console.error(error);
      Notifier.error("Failed to get import results for this importer. Please contact support.");
    }
  };

  const handleResultClick = (result: ImportHistoryTableEntry) => {
    setOpenResult(result.full_result);
  };

  const columns = useMemo(() => {
    const cols: ColumnConfig<ImportHistoryTableEntry>[] = [
      {
        field: "created_at",
        headerName: "Imported at",
        dataType: "date",
        dateType: "timestamp",
        dateFormat: "ff",
      },
      {
        field: "user_id",
        headerName: "Imported by",
        dataType: "string",
        valueFormatter: (row) => {
          const user = lookupCompanyUser(row.data?.user_id);
          return buildCompanyUserName(user);
        },
      },
      {
        field: "success_count",
        headerName: "Successful imports",
        dataType: "number",
      },
      {
        field: "warning_count",
        headerName: "Imports with warnings",
        dataType: "number",
      },
      {
        field: "error_count",
        headerName: "Failed imports",
        dataType: "number",
      },
    ];

    return cols;
  }, [lookupCompanyUser]);

  const data = useMemo(() => {
    if (!history) return;

    return history.map((h) => {
      const { _id, created_at, user_id, results } = h;

      return {
        _id,
        created_at,
        user_id,
        success_count: results.successes.length,
        warning_count: results.warnings.length,
        error_count: results.errors.length,
        full_result: h,
      };
    });
  }, [history]);

  return (
    <>
      {!openResult && (
        <ActionModal
          headerText={`${singular(capitalize(resource))} import history`}
          cancelText={"Close"}
          onCancel={onClose}
          onHide={onClose}
          showCancel={true}
          wrapperStyle={{ width: "80%" }}
        >
          <TableV2
            id={"import-history-table"}
            resource="import history"
            data={data}
            columns={columns}
            onClick={handleResultClick}
            gridWrapperStyle={{ height: 360 }}
          />
        </ActionModal>
      )}
      {openResult && <ImportResultModal result={openResult} onClose={() => setOpenResult(undefined)} />}
    </>
  );
};

type ImportResultModalProps = {
  result: ImportResult;
  onClose: () => void;
};

type ImportResultTableEntry = {
  _id: string;
  row: number;
  result: "success" | "warning" | "error";
  message: string;
  item?: ImportResult["results"]["successes"][number]["item"];
};

export const ImportResultModal: React.FC<ImportResultModalProps> = ({ result, onClose }) => {
  const { customFields } = useContext(AppContext);

  const lookupCustomFields = useMemo(
    () =>
      customFields.reduce((acc, cf) => {
        acc[cf._id] = cf;
        return acc;
      }, {}),
    [customFields]
  );

  const teamMembers = useTeam();
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: "row",
        headerName: "Row",
        maxWidth: 70,
        dataType: "number",
        pinned: true,
        filter: false,
      },
      {
        field: "result",
        headerName: "Result",
        dataType: "string",
        displayType: "badge",
        colors: {
          success: "green",
          warning: "yellow",
          error: "red",
        },
        maxWidth: 100,
        pinned: true,
        filter: "agSetColumnFilter",
      },
      {
        field: "message",
        headerName: "Message",
        dataType: "string",
        width: 200,
        minWidth: 200,
        pinned: true,
      },
    ] as ColumnConfig<ImportResultTableEntry>[];

    // Add all keys from this input that aren't already in the list using set
    const keySet = result.raw_inputs.reduce((keys, input) => {
      Object.keys(input).forEach((key) => keys.add(key));
      return keys;
    }, new Set<string>());

    const inputKeys: string[] = Array.from(keySet);

    const keyColumns: ColumnConfig<ImportResultTableEntry>[] = inputKeys.map((key) => {
      let headerName = "";

      if (key.includes(TABLE_COLUMN_CUSTOM_FIELD_PREFIX)) {
        const customFieldId = getCustomIdFromColumnKey(key);

        if (customFieldId) {
          const customField = lookupCustomFields[customFieldId];
          if (customField) headerName = customField.name;
        }
      } else {
        headerName = splitCamelCase(deparameterize(key));
      }

      return {
        field: key,
        headerName,
        dataType: "string",
        enableRowGroup: true,
        valueGetter: (row) => {
          const value = row.data?.[key];
          return SENSITIVE_KEYS_SET.has(key) && value ? "********" : value;
        },
      };
    });

    const fullColumns = baseColumns.concat(keyColumns);
    return fullColumns;
  }, [lookupCustomFields]);

  const data = useMemo(() => {
    const successList = result.results.successes.map(({ row, raw_input: input, item }) => {
      return {
        _id: row + "",
        row: row,
        result: "success",
        message: "Successfully imported",
        item,
        ...input,
      };
    });

    const warningList = result.results.warnings.map(({ row, message, raw_input: input }) => {
      return {
        _id: row + "",
        row: row,
        result: "warning",
        message: message,
        ...input,
      };
    });

    const errorList = result.results.errors.map(({ row, message, raw_input: input }) => {
      return {
        _id: row + "",
        row: row,
        result: "error",
        message: message,
        ...input,
      };
    });

    return [...successList, ...warningList, ...errorList] as ImportResultTableEntry[];
  }, [result]);

  const handleResultRowClick = (row: ImportResultTableEntry & { teamMemberId?: string }) => {
    if (result.importer_id === "timesheets" && row.item) {
      // @ts-expect-error fix me
      window.open(`/timesheets?tsid=${row.item._id}`, "_blank");
    }

    if (result.importer_id === "employee_benefits" && row.item) {
      // @ts-expect-error fix me
      window.open(`/benefits/employee-benefits/${row.item._id}/`, "_blank");
    }

    if (result.importer_id === "activities" && row.item) {
      // @ts-expect-error fix me
      window.open(`/activities?aid=${row.item._id}`, "_blank");
    }

    if (result.importer_id === "team_member" && row.item) {
      // @ts-expect-error fix me
      window.open(`/team-members/${row.item._id}`, "_blank");
    }

    if (result.importer_id === "earnings" && row.item) {
      if (row.teamMemberId) {
        window.open(`./payments?tmid=${lookupTeamID[row.teamMemberId]?._id}&activeView=earnings`, "_blank");
      }
    }

    if (result.importer_id === "payroll_overrides" && row.item) {
      if (row.teamMemberId) {
        window.open(`./payments?tmid=${lookupTeamID[row.teamMemberId]?._id}&activeView=overrides`, "_blank");
      }
    }

    if (result.importer_id === "ledger_accounts" && row.item) {
      // @ts-expect-error fix me
      window.open(`/accounting?view=accounts&anid=${row.item._id}`, "_blank");
    }
  };

  return (
    <ActionModal
      headerText={`Results from ${result.resource} imported on ${DateTime.fromSeconds(
        result.created_at
      ).toFormat("ff")}`}
      cancelText={"Close"}
      onCancel={onClose}
      onHide={onClose}
      showCancel={true}
      wrapperStyle={{ width: "80%" }}
    >
      <TableV2
        id={"import-results-table"}
        resource="import results"
        data={data}
        columns={columns}
        gridWrapperStyle={{ height: 360 }}
        onClick={handleResultRowClick}
      />
    </ActionModal>
  );
};
