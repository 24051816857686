import React, { useEffect, useMemo, useState } from "react";
import {
  AggregatedTeamMember,
  MiterAPI,
  MiterFilterArray,
  TimeOffPolicy,
  TimeOffUpdate,
} from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Helmet } from "react-helmet";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { useActiveCompanyId, useLookupTeam, useLookupTimeOffPolicy } from "dashboard/hooks/atom-hooks";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { roundTo } from "dashboard/utils";
import { Assign } from "utility-types";

type Props = {
  employee?: AggregatedTeamMember;
  timeOffPolicy?: TimeOffPolicy;
  trigger?: boolean;
  showAllUpdates?: boolean;
};

type TimeOffUpdateRow = Assign<
  NonNullable<TimeOffUpdate>,
  { hours: string; updated_balance: string; time_off_policy: string }
>;

const TimeOffUpdatesTable: React.FC<Props> = ({ employee, timeOffPolicy, trigger, showAllUpdates }) => {
  const [timeOffUpdates, setTimeOffUpdates] = useState<TimeOffUpdateRow[]>();
  const lookupTeam = useLookupTeam();
  const teamAbilities = useTeamAbilities();
  const activeCompanyId = useActiveCompanyId();
  const timeOffPolicyMap = useLookupTimeOffPolicy();

  useEffect(() => {
    getTimeOffUpdates();
  }, [trigger, timeOffPolicy, employee]);

  const cleanTimeOffUpdates = (data: TimeOffUpdate[]): TimeOffUpdateRow[] => {
    return data
      .filter((update) => teamAbilities.can("read", lookupTeam(update.employee)))
      .map((timeOffUpdate) => {
        const hours =
          timeOffUpdate.hours != null
            ? (timeOffUpdate.hours >= 0 ? "+" : "") +
              roundTo(timeOffUpdate.hours) +
              (timeOffUpdate.hours === 1 ? " hour" : " hours")
            : "-";

        const updated_balance =
          timeOffUpdate.updated_balance || timeOffUpdate.updated_balance === 0
            ? `${roundTo(timeOffUpdate.updated_balance)} hour${
                timeOffUpdate.updated_balance === 1 ? "" : "s"
              }`
            : "-";

        const policy = timeOffPolicy || timeOffPolicyMap(timeOffUpdate.time_off_policy_id);
        return {
          ...timeOffUpdate,
          time_off_policy: policy?.name || "-",
          level: policy?.levels?.find((level) => level._id === timeOffUpdate.level_id)?.name || "-",
          hours: hours,
          updated_balance: updated_balance,
        };
      });
  };

  const getTimeOffUpdates = async () => {
    try {
      let filters: MiterFilterArray;

      if (showAllUpdates && activeCompanyId) {
        filters = [{ field: "company", type: "string", value: activeCompanyId }];
      } else if (employee) {
        filters = [{ field: "employee", type: "string", value: employee._id }];
      } else {
        filters = [{ field: "time_off_policy._id", type: "_id", value: timeOffPolicy?._id }];
      }

      const response = await MiterAPI.time_off.updates.retrieve_many(filters);

      if (response.error) {
        throw Error(response.error);
      }

      const cleanedTimeOffUpdates = cleanTimeOffUpdates(response);
      setTimeOffUpdates(cleanedTimeOffUpdates);
    } catch (e) {
      console.error(e);
      Notifier.error("Unable to return time off updates for this team member. We are looking into it.");
    }
  };

  const timeOffUpdatesColumns = useMemo(() => {
    const cols: ColumnConfig<TimeOffUpdateRow>[] = [
      {
        field: "timestamp",
        headerName: "Timestamp",
        dataType: "date",
        dateFormat: "FF",
      },
      ...(employee || (showAllUpdates && activeCompanyId)
        ? [
            {
              field: "time_off_policy",
              headerName: "Policy",
              dataType: "string",
              enableRowGroup: true,
            } as ColumnConfig<TimeOffUpdateRow>,
          ]
        : []),
      ...(timeOffPolicy || (showAllUpdates && activeCompanyId)
        ? [
            {
              field: "employee",
              headerName: "Employee",
              dataType: "string",
              enableRowGroup: true,
              valueGetter: (params) => lookupTeam(params?.data?.employee)?.full_name,
            } as ColumnConfig<TimeOffUpdateRow>,
            {
              field: "employee.friendly_id",
              headerName: "Employee ID",
              dataType: "string",
              initialHide: true,
              enableRowGroup: true,
              valueGetter: (params) => lookupTeam(params?.data?.employee)?.friendly_id,
            } as ColumnConfig<TimeOffUpdateRow>,
          ]
        : []),
      {
        field: "type",
        headerName: "Type",
        dataType: "string",
        enableRowGroup: true,
      },
      {
        field: "hours",
        headerName: "Hours",
        dataType: "number",
      },
      {
        field: "updated_balance",
        headerName: "New Balance",
        dataType: "number",
      },
      {
        field: "level",
        headerName: "Policy Level",
        dataType: "string",
      },
    ];
    return cols;
  }, [employee, timeOffPolicy, lookupTeam]);

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={"time-off-updates-table"}
        resource="time off updates"
        data={timeOffUpdates}
        showReportViews={true}
        columns={timeOffUpdatesColumns}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Time Off Updates | Miter</title>
      </Helmet>
      <div>{renderTable()}</div>
    </>
  );
};

export default TimeOffUpdatesTable;
