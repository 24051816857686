import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Badge, Button, Toggler } from "ui";
import { Helmet } from "react-helmet";
import styles from "./Documents.module.css";
import FilesTable from "dashboard/components/tables/FilesTable";
import CompanyTaxForms from "../settings/CompanyTaxForms";
import { AllTmTaxForms } from "../settings/AllTmTaxForms";
import { useQuery } from "miter-utils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import FillableTemplatesTable from "dashboard/components/fillable-templates/FillableTemplatesTable";
import { useHasAccessToFillableDocuments } from "dashboard/gating";

const Documents: React.FC = () => {
  /*********************************************************
                       Hooks/State
  **********************************************************/
  const view = useQuery().get("view") || "";
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const { can, cannot } = useMiterAbilities();
  const canAccessFillableDocuments = useHasAccessToFillableDocuments();

  useEffect(() => {
    if (view === "fillable-templates" && !canAccessFillableDocuments) {
      navigate("/documents?view=", { replace: true });
    }
  }, [view, canAccessFillableDocuments]);

  /*********************************************************
                        Toggler
  **********************************************************/

  const togglerConfig = [
    {
      path: "",
      label: "General",
    },
    ...(canAccessFillableDocuments
      ? [
          {
            path: "fillable-templates",
            label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                Fillable templates
                <Badge
                  style={{ marginLeft: 3, marginRight: 0, marginBottom: -1 }}
                  text="Beta"
                  color="green"
                />
              </div>
            ),
          },
        ]
      : []),
    {
      path: "company-tax-forms",
      label: "Company tax forms",
      hide: cannot("documents:company:read_sensitive"),
    },
    {
      path: "tm-tax-forms",
      label: "Team member tax forms",
      hide: cannot("documents:team_member:read_sensitive"),
    },
  ];

  const toggle = (page: string) => {
    navigate("/documents?view=" + page, { replace: true });
  };

  const getViewTitle = () => {
    if (view === "company-tax-forms") {
      return "Company Tax Forms";
    } else if (view === "tm-tax-forms") {
      return "Team Member Tax Forms";
    } else if (view === "fillable-templates") {
      return "Fillable Templates";
    } else {
      return "Documents";
    }
  };

  const renderCompanyDocs = () => {
    return (
      <div>
        <FilesTable
          defaultFilters={[
            {
              field: "company_id",
              type: "string",
              value: activeCompanyId!,
            },
            {
              field: "parent_id",
              type: "string",
              value: activeCompanyId,
            },
            {
              field: "parent_type",
              type: "string",
              value: "company",
            },
          ]}
          parentType={"company"}
          parentId={activeCompanyId!}
          showSignatureCompletion={true}
        />
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{getViewTitle()} | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <h1 className="view-title title-with-toggler">Documents</h1>
          {can("documents:settings") && (
            <Button className="button-1" text="Settings" onClick={() => navigate("/documents/settings")} />
          )}
        </div>
        <Toggler secondary={false} config={togglerConfig} active={view} toggle={toggle} />

        {view === "" &&
          (can("documents:company:read") || can("documents:company:read_sensitive")) &&
          renderCompanyDocs()}

        {view === "company-tax-forms" && can("documents:company:read_sensitive") && <CompanyTaxForms />}

        {view === "tm-tax-forms" && can("documents:team_member:read_sensitive") && <AllTmTaxForms />}

        {/* TODO: We should replace an empty screen with an explanation around permission groups. */}
        {view === "fillable-templates" && can("documents:company:read") && <FillableTemplatesTable />}
      </div>
      <div className={"footer" + " " + styles.footer}></div>
    </div>
  );
};

export default Documents;
