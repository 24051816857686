import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { ColDef, GridApi } from "ag-grid-enterprise";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { AggregatedTeamMember, AggregatedTimesheet, MiterAPI, MiterFilterArray } from "dashboard/miter";
import TimesheetModal from "dashboard/pages/timesheets/ViewTimesheet/TimesheetModal";
import { AMERICAN_PLUMBING_ID, QOVO_COMPANY_ID, roundTo } from "dashboard/utils";
import { buildAgGridColumns, buildAgGridRow } from "miter-utils";
import Notifier from "dashboard/utils/notifier";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { Pencil } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { DateRange } from "ui/form/DateRangePicker";
import AppContext from "../../../../contexts/app-context";
import { reportList } from "../../reportList";
import { roundToTwoDecimals } from "../../reportUtils";
import {
  useActiveCompanyId,
  useLookupDepartment,
  useLookupPrg,
  useLookupRateClassification,
} from "dashboard/hooks/atom-hooks";
import { useTimesheetApprover } from "dashboard/utils/timesheetUtils";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";

type TableEntry = {
  _id: string;
  date: string;
  clock_in_time: string;
  clock_out_time: string;
  tm_name: string;
  tm_first_name: string;
  tm_last_name: string;
  job_name?: string;
  hours: number;
  unpaid_break_hours: number;
  activity_name: string;
  rate: number;
  gross_earnings: number;
  status: string;
  notes: string;
  department?: string;
  location?: string;
  team_member: AggregatedTeamMember;
};

export const TimesheetAnalysisReport: React.FC = () => {
  // Hooks
  const { customFields } = React.useContext(AppContext);
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();

  const timesheetAbilities = useTimesheetAbilities();
  const lookupDept = useLookupDepartment();
  const lookupClassification = useLookupRateClassification();
  const getApprover = useTimesheetApprover();
  const lookupPrg = useLookupPrg();

  // State
  const [data, setData] = useState<TableEntry[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ weeks: 1 }),
    end: DateTime.now(),
  });
  const [isViewingTimesheet, setIsViewingTimesheet] = useState<string | undefined>();

  const reportObject = reportList.find((report) => report.slug === "timesheet_analysis");
  const fileName = "Miter timesheets " + dateRange.start?.toISODate() + " - " + dateRange?.end?.toISODate();

  const timesheetAnalysisColumnDefs: ColDef[] = useMemo(() => {
    const tsCustomFields = customFields.filter((cf) => cf.parent_type === "timesheet");
    const customFieldColumns = buildAgGridColumns(tsCustomFields);

    return [
      {
        field: "tm_name",
        filter: true,
        headerName: "Team member",
        enableRowGroup: true,
        rowGroup: true,
        initialHide: true,
      },
      {
        field: "team_member.first_name",
        filter: true,
        headerName: "First name",
        enableRowGroup: true,
        hide: true,
      },
      {
        field: "team_member.last_name",
        filter: true,
        headerName: "Last name",
        enableRowGroup: true,
        hide: true,
      },
      {
        field: "team_member.title",
        filter: true,
        headerName: "Title",
        enableRowGroup: true,
      },
      {
        field: "date",
        filter: true,
        headerName: "Date",
        enableRowGroup: true,
      },
      {
        field: "job_name",
        filter: true,
        headerName: "Job",
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "job_code",
        filter: true,
        headerName: "Job code",
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "activity_name",
        filter: true,
        headerName: "Activity",
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "status",
        filter: true,
        headerName: "Status",
      },
      {
        field: "clock_in_time",
        headerName: "In",
      },
      {
        field: "clock_out_time",
        headerName: "Out",
      },
      {
        field: "injuredString",
        filter: true,
        headerName: "Injured",
        initialHide: true,
      },
      {
        field: "notes",
        headerName: "Notes",
        filter: "agTextColumnFilter",
        initialHide: true,
      },
      {
        field: "unpaid_break_hours",
        headerName: "Break hours",
        aggFunc: "sumValues",
        enableRowGroup: true,
        enableValue: true,
        valueFormatter: roundToTwoDecimals,
        initialHide: true,
      },
      {
        field: "hours",
        headerName: "Work hours",
        aggFunc: "sumValues",
        enableRowGroup: true,
        enableValue: true,
        valueFormatter: roundToTwoDecimals,
      },
      {
        field: "approver",
        headerName: "Approver",
        filter: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          return getApprover(params.data);
        },
      },
      {
        field: "department",
        headerName: "Department",
        enableRowGroup: true,
        filter: true,
        sortable: true,
      },
      {
        field: "location",
        headerName: "Location",
        enableRowGroup: true,
        filter: true,
        sortable: true,
      },
      {
        field: "classification",
        headerName: "Classification",
        enableRowGroup: true,
        filter: true,
        sortable: true,
      },
      {
        field: "pay_rate_group",
        headerName: "Pay rate group",
        enableRowGroup: true,
        filter: true,
        sortable: true,
      },
      ...customFieldColumns,
      // Pacifying QOVO churn risk Nov 2022
      ...([AMERICAN_PLUMBING_ID, QOVO_COMPANY_ID].includes(activeCompanyId || "")
        ? [
            {
              field: "dirJob",
              headerName: "DIR Job",
              initialHide: true,
              filter: true,
            },
          ]
        : []),
      {
        field: "_id",
        headerName: "",
        pinned: "right",
        maxWidth: 70,
        cellRenderer: (params) => {
          if (!params.node.allChildrenCount) {
            return (
              <button
                onClick={() => setIsViewingTimesheet(params.value)}
                className="button-1 no-margin"
                style={{ height: 32, width: 32 }}
              >
                <Pencil style={{ marginTop: 3 }} />
              </button>
            );
          } else {
            return null;
          }
        },
        suppressMenu: true,
      },
    ];
  }, [getApprover]);

  const createTableEntry = (ts: AggregatedTimesheet): TableEntry => {
    const dept = lookupDept(ts.team_member.department_id);
    const classification = lookupClassification(ts.pay_rate?.classification?.source_id);
    const prg = lookupPrg(classification?.pay_rate_group);
    const durationHours = Math.max((ts.clock_out - ts.clock_in - (ts.unpaid_break_time || 0)) / 3600, 0.01);
    return {
      // new timesheet data
      _id: ts._id,
      date: DateTime.fromSeconds(ts.clock_in).toISODate(),
      tm_name: ts.team_member.full_name,
      job_name: ts.job?.name || "N/A",
      job_code: ts.job?.code || "N/A",
      clock_in_time: DateTime.fromSeconds(ts.clock_in).toLocaleString(DateTime.TIME_SIMPLE),
      clock_out_time: DateTime.fromSeconds(ts.clock_out).toLocaleString(DateTime.TIME_SIMPLE),
      hours: durationHours,
      dirJob: !!ts.job?.cpr_info,
      unpaid_break_hours: (ts.unpaid_break_time || 0) / 3600,
      rate: ts.pay_rate.rates.reg,
      activity_name: ts.activity?.label || "N/A",
      gross_earnings: roundTo(durationHours * ts.pay_rate.rates.reg),
      status: capitalize(ts.status || undefined),
      notes: ts.notes || "",
      team_member: ts.team_member,
      approver_by: ts.approved_by,
      approved_at: ts.approved_at,
      approval_history: ts.approval_history,
      approval_stage: ts.approval_stage,
      department: dept?.name || "-",
      location: ts.location?.name,
      classification: classification?.classification || "-",
      pay_rate_group: prg?.label || "-",

      // Custom fields
      ...buildAgGridRow(ts.custom_field_values, customFields),
    };
  };

  const refetchViewingTimesheet = async (timesheetId: string) => {
    const select = [{ field: "pay_rate", show: true }];
    const filter: MiterFilterArray = [
      {
        field: "_id",
        value: timesheetId,
      },
    ];
    const response = await MiterAPI.timesheets.forage({
      filter,
      select,
    });
    const ts = response?.data[0];

    if (ts) {
      setData(
        data?.map((entry) => {
          if (entry._id === timesheetId) {
            return createTableEntry(ts);
          } else {
            return entry;
          }
        })
      );
    } else {
      setData(data?.filter((entry) => entry._id !== timesheetId));
    }
  };

  const handleDateRangeChange = (range: DateRange) => {
    setDateRange(range);
  };

  const defaultExportParams = {
    allColumns: false,
    fileName: fileName,
  };

  useEffect(() => {
    if (!gridApi) return;
    let abort = false;
    const getData = async () => {
      try {
        gridApi?.showLoadingOverlay();
        const filter: MiterFilterArray = [
          {
            field: "clock_in",
            type: "number" as const,
            comparisonType: "<+>e" as const,
            value: [dateRange.start?.startOf("day").toSeconds(), dateRange?.end?.endOf("day").toSeconds()],
          },
          { field: "company", value: activeCompanyId! },
        ];

        const abilitiesFilter = timesheetAbilities.filter("read");
        if (abilitiesFilter) filter.push(abilitiesFilter);

        const select = [{ field: "pay_rate", show: true }];
        const data: AggregatedTimesheet[] = [];

        let nextPage: string | null = null;
        do {
          const response = await MiterAPI.timesheets.forage({
            filter,
            select,
            limit: 1000,
            starting_after: nextPage,
          });
          if (abort) return;

          data.push(...response.data);
          nextPage = response.next_page;
        } while (nextPage);

        const cleanData: TableEntry[] = [];
        for (const ts of data) {
          cleanData.push(createTableEntry(ts));
        }
        setData(cleanData);
      } catch (e) {
        console.log(e);
        Notifier.error("There was an error retrieving data. Please try again.");
      } finally {
        if (!abort) gridApi?.hideOverlay();
      }
    };
    getData();
    return () => {
      abort = true;
    };
  }, [dateRange, !!gridApi]);

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Timesheet analysis</h1>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <div className="vertical-spacer-small"></div>
      {
        <>
          {isViewingTimesheet && (
            <TimesheetModal
              id={isViewingTimesheet}
              hide={() => setIsViewingTimesheet(undefined)}
              handleChange={() => refetchViewingTimesheet(isViewingTimesheet)}
            />
          )}
          <AgGridTable
            reportId="timesheet-analysis"
            data={data}
            columnDefs={timesheetAnalysisColumnDefs}
            csvExportParams={defaultExportParams}
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
            setGridApi={setGridApi}
            gridHeight={"63vh"}
            gridOptions={{
              autoGroupColumnDef: {
                minWidth: 200,
              },
              enableRangeSelection: true,
              animateRows: true,
              groupDisplayType: "multipleColumns",
              suppressAggFuncInHeader: true,
              defaultCsvExportParams: defaultExportParams,
              defaultExcelExportParams: defaultExportParams,
              showOpenedGroup: true,
            }}
          />
          <div style={{ marginTop: 10, fontSize: 13, color: "#3C3C3C" }}>
            * This report does not factor in overtime pay and assumes all hours are paid at the regular rate.
          </div>
          <div className="vertical-spacer-large"></div>
        </>
      }
    </div>
  );
};
