import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MiterAPI, TimeOffPolicyLevelConfig } from "dashboard/miter";
import { TimeOffPolicy } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { TableV2 } from "ui";
import { ConfirmModal } from "ui";
import { Helmet } from "react-helmet";
import { ClockCounterClockwise, Plus, TrashSimple } from "phosphor-react";
import { TableActionLink, ColumnConfig } from "ui/table-v2/Table";

import { useActiveCompany, useActiveTeam, useLookupTimeOffPolicy } from "dashboard/hooks/atom-hooks";
import { Assign } from "utility-types";

import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TimeOffPolicyEnrolleeImporter } from "dashboard/components/time-off/TimeOffPolicyEnrolleeImporter";
import { ImportHistory } from "dashboard/components/importer/ImportHistory";
import { getWorkWeeksInYear, roundTo } from "dashboard/utils";
import { DEFAULT_WORK_WEEKS_IN_YEAR } from "dashboard/utils/constants";

type TimeOffPolicyTableEntry = Assign<
  TimeOffPolicyLevelConfig,
  {
    type: "vacation" | "sick";
    accrual_type: "hourly" | "fixed" | "unlimited";
    enrollee_count: number;
    policy_name: string;
    policy_id: string;
    level_name: string;
    accrue_on_davis_bacon_jobs: boolean;
  }
>;

const TimeOffPolicies: React.FC = () => {
  /*********************************************************
   *  Call hooks + import context
   **********************************************************/
  const navigate = useNavigate();
  const company = useActiveCompany();
  const team = useActiveTeam();
  const { can, cannot } = useMiterAbilities();
  const lookUpTimeOffPolicy = useLookupTimeOffPolicy();

  /*********************************************************
   *  Setup states
   **********************************************************/
  const [policyLevels, setPolicyLevels] = useState<TimeOffPolicyTableEntry[]>();
  const [selectedTimeOffPolicies, setSelectedTimeOffPolicies] = useState<TimeOffPolicyTableEntry[]>([]);

  const [archiving, setArchiving] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);

  /*********************************************************
   *  useEffect functions
   **********************************************************/
  useEffect(() => {
    if (cannot("time_off:policies:read")) {
      Notifier.error("You do not have permission to view time off policies");
      return navigate("/home");
    }

    getPolicies();
  }, []);

  /*********************************************************
   *  API Functions
   **********************************************************/

  const buildEnrolleeCount = (timeOffPolicy: TimeOffPolicy, levelId: string): number => {
    return team.reduce((total, teamMember) => {
      const hasPolicy = teamMember.time_off.policies.some(
        (p) => p.policy_id === timeOffPolicy._id && p.level_id === levelId
      );
      if (hasPolicy) {
        return total + 1;
      } else {
        return total;
      }
    }, 0);
  };

  const getPolicies = async () => {
    if (!company?._id) return;
    try {
      const response = await MiterAPI.time_off.policies.retrieve_many([
        { field: "company", type: "string", value: company._id },
      ]);

      if (response.error) {
        throw Error(response.error);
      }

      const policyLevels = response
        .map((p) =>
          p.levels.map((level): TimeOffPolicyTableEntry => {
            return {
              ...level,
              level_name: level.name,
              policy_name: p.name,
              policy_id: p._id.toString(),
              type: p.type,
              accrue_on_davis_bacon_jobs: p.accrue_on_davis_bacon_jobs || false,
              accrual_type: buildAccrualType(level),
              enrollee_count: buildEnrolleeCount(p, level._id),
            };
          })
        )
        .flat()
        .sort((p1, p2) => p2.policy_name.localeCompare(p1.policy_name));

      setPolicyLevels(policyLevels);
    } catch (e) {
      console.log("Error", e);
      Notifier.error("There was an error getting the time off policies. We're looking into it.");
    }
  };

  const deleteTimeOffPolicy = async (policyId: string) => {
    try {
      if (cannot("time_off:policies:delete")) {
        Notifier.error("You do not have permission to delete time off policies");
        return;
      }

      if (!policyId) throw Error("No time off policy selected");
      const response = await MiterAPI.time_off.policies.delete(policyId);

      if (response.error) {
        throw Error(response.error);
      }

      Notifier.success("You have successfully delete the time off policy");
    } catch (e) {
      console.log("Error", e);

      // @ts-expect-error Error cleanup
      if (e.message === "Time off policy cannot be deleted because there is unpaid time off.") {
        Notifier.error(
          "Time off policy cannot be deleted because there are unpaid time off requests.\nIf you would like to delete this policy, please delete those time off requests in the time off module.",
          { style: { minWidth: "800px", whiteSpace: "pre-line" }, duration: 10000 }
        );
      } else {
        Notifier.error("There was an error deleting the time off policy. We're looking into it.");
      }
    }
  };

  const deleteTimeOffPolicies = async () => {
    setArchiving(true);
    await Promise.all(selectedTimeOffPolicies.map((policy) => deleteTimeOffPolicy(policy.policy_id)));
    await getPolicies();

    setArchiving(false);
    setSelectedTimeOffPolicies([]);
    setShowArchiveModal(false);
  };

  /*********************************************************
   *  Handler helper functions
   **********************************************************/

  const handleAdd = () => {
    navigate("/time-off/policies/new");
  };

  /*********************************************************
   * Helper functions
   ********************************************************/
  const buildAccrualConfigCell = (data: TimeOffPolicyTableEntry | undefined) => {
    const accrualConfig = data?.accrual_config;
    if (data?.unlimited) {
      return "Unlimited";
    } else if (accrualConfig?.fixed) {
      const hoursAccruedYearly = accrualConfig?.fixed.total_hours;
      if (accrualConfig.fixed.cadence === "weekly") {
        const workWeeksInYear = company ? getWorkWeeksInYear(company) : DEFAULT_WORK_WEEKS_IN_YEAR;
        const hoursAccruedWeekly = roundTo(hoursAccruedYearly / workWeeksInYear);
        return `${hoursAccruedWeekly} hours per week`;
      }
      if (accrualConfig.fixed.accrual_date_type === "start_date") {
        return `${hoursAccruedYearly} ${
          hoursAccruedYearly > 1 ? "hours" : "hour"
        } each year on work anniversary`;
      } else {
        return `${hoursAccruedYearly} ${hoursAccruedYearly > 1 ? "hours" : "hour"} each year on ${
          accrualConfig.fixed.accrual_date?.month
        }/${accrualConfig.fixed.accrual_date?.day}`;
      }
    } else if (accrualConfig?.hourly) {
      return `${accrualConfig?.hourly.rate.hours_earned} ${
        accrualConfig.hourly.rate.hours_earned > 1 ? "hours" : "hour"
      } for every ${accrualConfig?.hourly.rate.hours_based_on} ${
        accrualConfig.hourly.rate.hours_based_on > 1 ? "hours" : "hour"
      } ${accrualConfig.hourly.count_hours_paid ? "paid" : "worked"}`;
    } else {
      return "-";
    }
  };

  const onImportFinished = () => {
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        key: "import",
        component: <TimeOffPolicyEnrolleeImporter onFinish={onImportFinished} />,
        important: false,
        showInEditMode: true,
        shouldShow: () => can("time_off:policies:update"),
      },
      {
        key: "import-history",
        className: "button-1",
        action: () => setShowImportHistory(true),
        label: "Import history",
        important: false,
        showInEditMode: true,
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("time_off:policies:update"),
      },
      {
        label: "Add time off policy",
        className: "button-2",
        action: handleAdd,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("time_off:policies:create"),
      },
    ],
    [can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setShowArchiveModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("time_off:policies:delete"),
      },
    ],
    [can]
  );

  // Dynamically set columns based on whether any time off policy have work classifications
  const columns = useMemo(() => {
    const cols: ColumnConfig<TimeOffPolicyTableEntry>[] = [
      {
        headerName: "Policy Name",
        field: "policy_id", // To ensure we don't group by policy name in case multiple policies have the same name (edge case!)
        dataType: "string",
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
        valueFormatter: (params) => {
          return lookUpTimeOffPolicy(params.value)?.name || "";
        },
      },
      {
        headerName: "Type",
        field: "type",
        enableRowGroup: true,
        dataType: "string",
        displayType: "badge",
        colors: {
          vacation: "green",
          sick: "light-blue",
        },
      },
      {
        headerName: "Accrual",
        enableRowGroup: true,
        field: "accrual_type",
        dataType: "string",
        displayType: "badge",
        colors: {
          hourly: "orange",
          fixed: "light-gray",
          unlimited: "light-purple",
        } as $TSFixMe,
      },
      {
        headerName: "Accrual config",
        enableRowGroup: true,
        field: "accrual_config",
        dataType: "string",
        minWidth: 300,
        valueFormatter: (params) => buildAccrualConfigCell(params.data),
      },
      {
        headerName: "Paid",
        enableRowGroup: true,
        field: "unpaid",
        dataType: "string",
        valueGetter: (params) => {
          if (!params?.node?.group) return !params.data?.unpaid ? "Yes" : "No";
        },
        width: 80,
      },
      {
        headerName: "Enrollees",
        field: "enrollee_count",
        dataType: "number",
        width: 80,
      },

      // Yearly carryover limit
      {
        headerName: "Yearly carryover limit",
        field: "accrual_config.carryover_limit",
        dataType: "number",
        hide: true,
      },
      {
        headerName: "Carryover date",
        enableRowGroup: true,
        field: "accrual_config.carryover_date_type",
        dataType: "string",
        valueGetter: (params) => {
          return !params?.node?.group ? buildCarryOverDate(params.data) : "-";
        },
      },
      {
        headerName: "Yearly accrual limit",
        field: "accrual_config.yearly_limit",
        dataType: "number",
        hide: true,
      },
      {
        headerName: "Max accruable balance",
        field: "accrual_config.max_balance",
        dataType: "number",
        hide: true,
      },
      {
        headerName: "Default starting balance",
        field: "default_starting_balance",
        dataType: "number",
        hide: true,
      },
      {
        headerName: "Min tenure for requests",
        field: "min_tenure_for_requests",
        dataType: "number",
        hide: true,
      },
      {
        headerName: "Min tenure for accruals",
        field: "min_tenure_for_accruals",
        dataType: "number",
        hide: true,
      },
      {
        headerName: "Disable negative balances",
        field: "disable_negative_balances",
        dataType: "string",
        valueGetter: (params) => {
          if (!params?.node?.group) return params.data?.disable_negative_balances ? "Yes" : "No";
        },
        hide: true,
      },
      {
        headerName: "Accrues on Davis-Bacon jobs",
        field: "accrue_on_davis_bacon_jobs",
        dataType: "string",
        valueGetter: (params) => {
          if (!params?.node?.group) return params?.data?.accrue_on_davis_bacon_jobs ? "Yes" : "No";
        },
        hide: true,
      },
    ];
    return cols;
  }, [lookUpTimeOffPolicy, policyLevels]);

  /*********************************************************
   *  Rendering functions
   **********************************************************/

  const renderDeletePoliciesModal = () => {
    return (
      <ConfirmModal
        title={"Delete Time Off Policy"}
        body={`Are you sure you want to delete ${
          selectedTimeOffPolicies.length > 1 ? "these time off policies" : "this time off policy"
        } All currently enrolled team members will be unenrolled.`}
        onYes={() => deleteTimeOffPolicies()}
        onNo={() => setShowArchiveModal(false)}
      />
    );
  };

  const handleRowClick = (row) => {
    navigate(`/time-off/policies/${row?.policy_id}`);
  };

  return (
    <div>
      <Helmet>
        <title>Time Off Policies | Miter</title>
      </Helmet>
      {showImportHistory && (
        <ImportHistory
          id={"time_off_policy_enrollees"}
          resource={"enrollees"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
      <TableV2
        id={"time-off-policies-table"}
        resource="time off policies"
        data={policyLevels}
        groupDisplayType="singleColumn"
        groupHideOpenParents={false}
        groupDefaultExpanded={1}
        groupAllowUnbalanced={true}
        autoGroupColumnDef={{ headerName: "Policy", valueFormatter: (params) => params?.data?.level_name }}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        // onSelect={setSelectedTimeOffPolicies} TODO: Bring this back MIT-5885
        defaultSelectedRows={selectedTimeOffPolicies}
        isLoading={archiving}
        showReportViews={true}
        onClick={handleRowClick}
        gridWrapperStyle={{ height: "60vh" }}
      />

      {showArchiveModal && renderDeletePoliciesModal()}
    </div>
  );
};

export default TimeOffPolicies;

export const buildAccrualType = (policyLevel: TimeOffPolicyLevelConfig): "hourly" | "fixed" | "unlimited" => {
  if (policyLevel.unlimited) {
    return "unlimited";
  } else if (policyLevel.accrual_config?.fixed) {
    return "fixed";
  } else {
    return "hourly";
  }
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const buildCarryOverDate = (data: TimeOffPolicyTableEntry | undefined): string => {
  if (data?.accrual_config?.carryover_date_type === "start_date") return "Anniversary date";

  let month = "";
  if (data?.accrual_config?.carryover_date?.month) {
    month = months[data.accrual_config.carryover_date.month - 1] || "";
  }

  const day = data?.accrual_config?.carryover_date?.day;
  if (month && day) {
    return month + " " + data?.accrual_config?.carryover_date?.day;
  }

  return "-";
};
