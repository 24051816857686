import { AggregatedBill } from "dashboard/miter";
import { useBillLineItemsTableColDefs } from "dashboard/pages/bills/billPayUtils";
import { useMemo, useState } from "react";
import { IconWithTooltip, TableV2 } from "ui";
import { ICellRendererParams } from "ag-grid-community";
import { DraftBillLineItem } from "./FullPageBillModal";
import { Copy, Plus, Trash } from "phosphor-react";
import ObjectID from "bson-objectid";
import { TableActionLink } from "packages/ui/table-v2/Table";
import { useLookupActivity, useLookupCostType, useLookupTeam } from "dashboard/hooks/atom-hooks";

type Props = {
  draftLineItems: DraftBillLineItem[];
  setDraftLineItems: (draftLineItems: DraftBillLineItem[]) => void;
  bill?: AggregatedBill;
  editing: boolean;
};
export const BillLineItemsTable: React.FC<Props> = ({ draftLineItems, setDraftLineItems, bill, editing }) => {
  const columnDefs = useBillLineItemsTableColDefs(editing, bill?.payment_status);
  const lookupTeamMember = useLookupTeam();
  const lookupActivity = useLookupActivity();
  const lookupCostType = useLookupCostType();

  // rows
  const [selectedRows, setSelectedRows] = useState<DraftBillLineItem[]>([]);

  const emptyDraftBill: DraftBillLineItem = {
    _id: ObjectID().toString(),
    amount: 0,
  };

  const memoizedColumnDefs = useMemo(() => {
    if (!editing) return columnDefs;

    const updatedColumnDefs = columnDefs.concat([
      {
        headerName: " ",
        field: "actions",
        maxWidth: 75,
        pinned: "right",
        cellRenderer: (params: ICellRendererParams<DraftBillLineItem>) => {
          return (
            <div className="flex">
              <IconWithTooltip
                PhosphorIcon={Copy}
                tooltip={`Make a copy of this line item.`}
                style={{ marginRight: 10, cursor: "pointer" }} // TODO: fix opacity
                onClick={() => duplicateRows(params.data ? [params.data] : [])}
              />
              <IconWithTooltip
                PhosphorIcon={Trash}
                tooltip={`Delete this line item.`}
                style={{ cursor: "pointer" }} // TODO: fix opacity
                onClick={() => deleteRows(params.data ? [params.data] : [])}
              />
            </div>
          );
        },
      },
    ]);

    return updatedColumnDefs;
  }, [columnDefs, draftLineItems, editing]);

  // setting these up to support multiple rows - reuse for both single and bulk actions
  const duplicateRows = (rows: DraftBillLineItem[]) => {
    const newRows = rows.map((row) => {
      const newRow = { ...row, _id: ObjectID().toString() };
      return newRow;
    });

    setDraftLineItems([...draftLineItems, ...newRows]);
  };

  const deleteRows = (rows: DraftBillLineItem[]) => {
    const rowIdsToDelete = rows.map((row) => row._id);
    const newRows = draftLineItems.filter((line_item) => !rowIdsToDelete.includes(line_item._id));
    setDraftLineItems(newRows);
  };

  // adds new row to the table
  const handleAddDraftLineItem = () => {
    setDraftLineItems([...draftLineItems, emptyDraftBill]);
  };

  const dynamicActions: TableActionLink[] = [
    {
      label: "Delete",
      action: () => deleteRows(selectedRows),
      className: "button-3 table-button",
      showInEditMode: true,
      icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
    },
    {
      label: "Duplicate",
      action: () => duplicateRows(selectedRows),
      className: "button-1 table-button",
      showInEditMode: true,
      icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const staticActions: TableActionLink[] = editing
    ? [
        {
          label: "New line item",
          action: handleAddDraftLineItem,
          className: "button-2 table-button",
          icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
          showInEditMode: true,
          important: true,
        },
      ]
    : [];

  const handleRowUpdate = async (updatedRows: DraftBillLineItem[]) => {
    const updatedLineItems = draftLineItems.map((draftLineItem) => {
      const updatedLineItem = updatedRows.find((updatedRow) => updatedRow._id === draftLineItem._id);
      if (updatedLineItem) {
        // if team member is updated, also update department
        if (updatedLineItem.team_member_id !== draftLineItem.team_member_id) {
          const newTeamMember = lookupTeamMember(updatedLineItem.team_member_id);
          updatedLineItem.department_id = newTeamMember?.department_id || undefined;
        }

        // if activity is updated, also update cost type
        if (updatedLineItem.activity_id !== draftLineItem.activity_id) {
          const newActivity = lookupActivity(updatedLineItem.activity_id);
          const existingActivity = lookupActivity(draftLineItem.activity_id);

          if (newActivity?.default_cost_type_id) {
            updatedLineItem.cost_type_id = newActivity.default_cost_type_id;
          } else if (
            draftLineItem.cost_type_id &&
            draftLineItem.cost_type_id === existingActivity?.default_cost_type_id
          ) {
            // if unsetting or changing activity and the current cost type is set by the activity, unset the cost type
            updatedLineItem.cost_type_id = undefined;
          }
        }

        // if cost type is updated, also update expense account
        if (updatedLineItem.cost_type_id !== draftLineItem.cost_type_id) {
          const newCostType = lookupCostType(updatedLineItem.cost_type_id);
          const existingCostType = lookupCostType(draftLineItem.cost_type_id);

          if (newCostType?.ledger_account_id) {
            updatedLineItem.ledger_account_id = newCostType.ledger_account_id;
          } else if (
            draftLineItem.ledger_account_id &&
            draftLineItem.ledger_account_id === existingCostType?.ledger_account_id
          ) {
            // if unsetting or changing cost type and the current ledger account is set by the cost type, unset the ledger account

            updatedLineItem.ledger_account_id = undefined;
          }
        }
        return updatedLineItem;
      }

      return draftLineItem;
    });

    setDraftLineItems(updatedLineItems);
  };

  return (
    <>
      <h2 className="margin-bottom-negative-45">Line Items</h2>
      <TableV2
        id={"bill-line-items-table"}
        resource="line items"
        customEmptyStateMessage='Click "+ New line item" to create a new line item.'
        data={draftLineItems}
        columns={memoizedColumnDefs}
        alwaysShowBulkEdit
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={false}
        onSelect={setSelectedRows}
        // isLoading={loading}
        hideSearch
        disablePagination
        hideFilters
        hideRowEditingStatus
        onSave={handleRowUpdate}
        autoSave
        containerStyle={{ width: "100%", paddingBottom: "unset" }}
        editable={editing}
        alwaysEditable={editing}
        hideSelectColumn={!editing}
      />
      <h3>Total amount: ${draftLineItems.reduce((acc, lineItem) => acc + lineItem.amount, 0)}</h3>
    </>
  );
};
