import React, { useEffect, useState } from "react";
import { Button, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { Props } from "./types";
import useWizard from "ui/modal/useWizard";
import { OnboardingChecklistCompletionData } from "dashboard/types/onboarding-types";
import { TeamPortalUser } from "team-portal/utils/miter";
import { I9Wizard } from "../i-9/employee/I9Wizard";

type I9Props = Props & {
  i9: NonNullable<OnboardingChecklistCompletionData["i9"]>;
};

export const EmployeeI9WizardScreen: React.FC<I9Props> = ({ task, name, i9, onboardingChecklistItem }) => {
  const [fillingI9, setFillingI9] = useState(false);
  const [i9Completed, setI9Completed] = useState(false);
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();

  useEffect(() => {
    setCanNext(!!i9.employee_esignature);
  }, [i9.employee_esignature]);

  const onExit = () => {
    setFillingI9(false);
  };

  const onComplete = () => {
    Notifier.success("I-9 saved successfully");
    setI9Completed(true);
    setFillingI9(false);
    setCanNext(true);
  };

  const onNext = () => {
    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        {i9.status === "complete" ? (
          <div>I9 has been completed.</div>
        ) : (
          <Button
            className={"button-2"}
            onClick={() => setFillingI9(true)}
            style={{ margin: 0, marginBottom: 30, marginTop: 10, padding: "20px 35px", fontSize: 16 }}
          >
            {i9.employee_esignature || i9Completed ? "Edit I-9" : "Fill out I-9"}
          </Button>
        )}

        {fillingI9 && (
          <I9Wizard
            onExit={onExit}
            onComplete={onComplete}
            I9={i9}
            activeTM={onboardingChecklistItem.new_hire as unknown as TeamPortalUser}
          />
        )}
      </div>
    </WizardScreen>
  );
};
