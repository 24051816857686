import { AggregatedTeamMember, MiterAPI, MiterError, TeamMember } from "dashboard/miter";
import {
  CertificationType,
  AggregatedCertification,
  Certification,
} from "dashboard/types/certification-types";
import { Notifier, WizardScreen } from "ui";
import styles from "./UploadCertificationWizardScreen.module.css";
import { FormProvider, useForm } from "react-hook-form";
import { CollectFormFields } from "miter-components";
import {
  FileUploadParams,
  FormField,
  generateSyncedCachedFileUploadIds,
  prepareCertificationFields,
  prepareCertificationFormAnswers,
  updateFiles,
} from "miter-utils";
import { FC, useEffect } from "react";
import { FilePickerFile } from "ui/form/FilePicker";
import { DateTime } from "luxon";
import ObjectID from "bson-objectid";
import { isEmpty } from "lodash";
import useWizard from "ui/modal/useWizard";
import React from "react";
import { TeamPortalUser } from "team-portal/utils/miter";
import Banner from "dashboard/components/shared/Banner";
import { BulkCreateResponse } from "backend/types";

type Props = {
  certificationOwner: AggregatedTeamMember | TeamPortalUser | TeamMember;
  certificationType: CertificationType;
  certification?: AggregatedCertification;
  companyId: string;
  getOnboardingChecklistItem?: () => void;
  onClose: () => void;
  name: string;
  readOnly: boolean;
};

export type CertificationFormValues = {
  custom_fields: { [form_field_id: string]: string };
  file_uploads: { [form_field_id: string]: FilePickerFile[] };
  expires_at: DateTime;
};

const UploadCertificationWizardScreen: FC<Props> = ({
  certificationOwner,
  certificationType,
  onClose,
  certification,
  companyId,
  name,
  getOnboardingChecklistItem,
  readOnly,
}) => {
  const form = useForm<CertificationFormValues>({
    mode: "all",
  });

  const { trigger, errors: formErrors, formState, watch } = form;
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();
  const data = watch();
  // Set whether or not the user can move forward based on manual and react-hook-form validations
  useEffect(() => {
    if (!isEmpty(formErrors) && !isEmpty(formState.errors)) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [formState, formErrors]);

  useEffect(() => {
    trigger();
  }, []);

  const submit = () => {
    if (readOnly) {
      onClose();
    } else {
      onSave(data);
    }
  };

  /** First save the non file related fields of the certification,
   * then upload the file blobs, and then sync the new/deleted file ids
   * onto the certification.
   */
  const onSave = async (data: CertificationFormValues) => {
    try {
      onClose();
      const customFieldValues = Object.entries(data.custom_fields || {}).map(([form_field_id, value]) => ({
        _id: new ObjectID().toHexString(),
        form_field_id,
        value,
      }));
      const params = {
        certification_type_id: certificationType._id,
        team_member_id: certificationOwner._id,
        company_id: companyId,
        submitted: true,
        expires_at: data.expires_at ? data.expires_at.toISODate() : undefined,
        archived: false,
        custom_field_values: customFieldValues,
        file_uploads: data.file_uploads,
      };

      let res: (Certification & MiterError) | (BulkCreateResponse<Certification> & MiterError) | null = null;

      if (certification) {
        res = await MiterAPI.certifications.update(certification._id, params);
      } else {
        res = await MiterAPI.certifications.create([params]);
      }

      if (res.error) {
        throw new Error(res.error);
      }

      if ("failures" in res && res.failures.length > 0) {
        throw new Error(res.failures[0]?.message);
      }
      const certificate = "successes" in res ? res?.successes?.[0] : res;
      if (!certificate) {
        throw new Error("Certification was not found.");
      }

      const fileUploadParams: FileUploadParams = {
        parent_id: certificate._id,
        parent_type: "certification",
        company_id: companyId,
      };
      const file_uploads = data.file_uploads || {};
      const promises = await Promise.all(
        Object.keys(file_uploads).map(async (formFieldIdKey) => {
          return {
            formFieldId: formFieldIdKey,
            value: await updateFiles(file_uploads[formFieldIdKey] || null, fileUploadParams),
          };
        })
      );

      const syncedCachedIds = generateSyncedCachedFileUploadIds(promises, certificate);

      const updateRes = await MiterAPI.certifications.update(certificate._id, {
        file_upload_values: syncedCachedIds,
      });

      if (updateRes.error) {
        throw new Error(updateRes.error);
      }

      getOnboardingChecklistItem?.();

      if (curIndex === screens.length - 1) {
        handleComplete();
      }

      Notifier.success(`Certification ${certification ? "updated" : "uploaded"} successfully`);
    } catch (e: $TSFixMe) {
      Notifier.error("There was an error uploading the certification: " + e.message);
    }
  };

  const formattedFormFields = prepareCertificationFields(certificationType);
  const formattedFormAnswers = prepareCertificationFormAnswers(certification);

  return (
    <WizardScreen onNext={submit} name={name}>
      <div className={styles["form-builder"]}>
        <div style={{ fontSize: 16, marginBottom: 20 }} className={styles["preview-header"]}>
          This a certification upload for {certificationOwner.full_name}
        </div>
        {certificationType.description ? (
          <div style={{ marginBottom: 20 }}>{certificationType.description}</div>
        ) : null}
        {readOnly ? (
          <div style={{ marginBottom: 20 }}>
            {" "}
            <Banner content={"You do not have permission to update this certification."} type="warning" />
          </div>
        ) : null}
        <FormProvider {...form}>
          <CollectFormFields
            formFields={formattedFormFields as FormField[]}
            formAnswers={formattedFormAnswers}
            readonly={readOnly}
          />
        </FormProvider>
      </div>
    </WizardScreen>
  );
};

export default UploadCertificationWizardScreen;
