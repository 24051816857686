import "./App.css";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import "@anvilco/react-signature-modal/dist/styles.css";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/auth/login";
import { IntercomProvider } from "react-use-intercom";
import AppContext from "./contexts/app-context";
import { useRoutes } from "./routes";
import Authenticate from "./pages/auth/authenticate";
import NotFound from "./components/not-found/NotFound";
import { MiterAPI, Customer, CustomField, Tag, MiterIntegrationForCompany } from "./miter";
import Logout from "./pages/auth/logout";
import { Notifier, ScrollToTop } from "ui";
import Confetti from "react-confetti";
import CompanyForm from "./components/companies/CompanyForm";
import { PermissionDeniedPage } from "./components/not-found/PermissionDeniedPage";
import { Helmet } from "react-helmet";
import UserSettingsModal from "./components/users/UserSettingsModal";
import ReverifyModal from "./components/auth/ReverifyModal";
import { UserAcknowledgements } from "./components/userAcknowledgements/UserAcknowledgements";
import { useCheckScript } from "./utils/useCheckComponent";
import { RegularNPSSurvey } from "./components/userAcknowledgements/RegularNPSSurvey";
import { NewReleaseModal } from "ui/modal/NewReleaseModal";
import { useDisableNumberInputScroll } from "ui/hooks/useDisableNumberInputScroll";
import DashboardWrapper from "./wrappers/dashboardWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import {
  useConfettiAtom,
  useSetActivities,
  useSetCompanyRoles,
  useSetCrews,
  useSetHolidaySchedules,
  useSetJobs,
  useSetOtRules,
  useSetPaySchedules,
  useSetPrgs,
  useSetTeam,
  useSetWcCodes,
  useShowNewReleaseModalAtom,
  useSetRateDifferentials,
  useSetCostTypes,
  useSetWorkplaces,
  useSetLastRefreshedAt,
  useSetLedgerAccounts,
  useSetPerformanceReviewSchedules,
  useActiveCompanyId,
  useSetDepartments,
  useSetTimeOffPolicies,
  useSetStandardClassifications,
  useSetPolicies,
  useSetJobPostings,
  useActiveCompany,
  useSetStripeConnectedAccount,
  useUser,
  useSetPermissionGroups,
  useSetSessionPermissionGroups,
  useSetCompanyUsers,
  useActiveAccount,
  useSetLedgerMappings,
  useSetForms,
  useSetExpenseReimbursementCategories,
  useSetCardTransactionCategories,
  useUserFetched,
  useSetInitializing,
  useSetHydratedPermissionGroups,
  useSetOnboardingChecklists,
  useSetCompanyDocuments,
  useToggleAccount,
  useSetReportViews,
  useSetCertificationTypes,
  useSetProfilePictureUrlsMap,
  useSetFillableTemplate,
  useSetPerDiemRates,
  useHitUnauthorizedErrorAtom,
  useSetLiveTimesheets,
  useSetWcGroups,
  useSetLocations,
  useSetEquipment,
  useSetVendors,
  useSetBenefitsEligibilityGroups,
  useSetLeaveTypes,
} from "./hooks/atom-hooks";
import { DateTime } from "luxon";
import { sleep } from "miter-utils";
import { useFetchUserData } from "./hooks/useFetchUserData";
import Impersonate from "./pages/auth/impersonate";
import { useTrackers } from "./hooks/useTrackers";
import { useActionableItemsInterval } from "./hooks/useActionableItemsInterval";
import { useMiterGuidesRedirect } from "./hooks/useMiterGuidesRedirect";
import { useTargetCompany } from "./hooks/useTargetCompany";
import { usePermissionGroupHydrator } from "./hooks/abilities-hooks/usePermissionGroupHydrator";
import { useTeamChat } from "./hooks/chat/useTeamChat";
import { useRecruitingChat } from "./hooks/chat/useRecruitingChat";

const App: React.FC = () => {
  const setAbilitiesList = useSetHydratedPermissionGroups();
  const initialLoad = useRef<boolean>(true);
  const buildHydratedPermissionGroups = usePermissionGroupHydrator();

  useTrackers();

  useEffect(() => {
    // Don't update abilities until session permission groups have been loaded, otherwise abilities will be marked as fetched erroneously
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    const hydratedPermissionGroups = buildHydratedPermissionGroups();
    setAbilitiesList(hydratedPermissionGroups);
  }, [buildHydratedPermissionGroups]);

  return (
    <Router>
      <Helmet>
        <title>Dashboard | Miter</title>
      </Helmet>
      <ScrollToTop />
      <IntercomProvider appId={"a9b5sbj0"}>
        <Layout />
      </IntercomProvider>
    </Router>
  );
};

const Layout: React.FC = () => {
  const location = useLocation();
  const impersonatingRef = useRef(location.pathname === "/impersonate");
  const navigate = useNavigate();
  const user = useUser();
  const fetchUserData = useFetchUserData();
  const userFetched = useUserFetched();
  const setInitializing = useSetInitializing();

  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const activeAccount = useActiveAccount();
  const toggleAccount = useToggleAccount();

  const [shiftPage, setShiftPage] = useState<boolean>(true);
  const setTeamMembers = useSetTeam();
  const setJobs = useSetJobs();
  const setCrews = useSetCrews();
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const setWcCodes = useSetWcCodes();
  const setRateDifferentials = useSetRateDifferentials();
  const setStandardClassifications = useSetStandardClassifications();
  const setPolicies = useSetPolicies();
  const setLeaveTypes = useSetLeaveTypes();
  const setJobPostings = useSetJobPostings();
  const setLedgerMappings = useSetLedgerMappings();
  const setCostTypes = useSetCostTypes();
  const setPaySchedules = useSetPaySchedules();
  const setHolidaySchedules = useSetHolidaySchedules();
  const setCompanyActivities = useSetActivities();
  const setPerformanceReviewSchedules = useSetPerformanceReviewSchedules();
  const setTimeOffPolicies = useSetTimeOffPolicies();
  const setPermissionGroups = useSetPermissionGroups();
  const setSessionPermissionGroups = useSetSessionPermissionGroups();
  const setCompanyUsers = useSetCompanyUsers();
  const setForms = useSetForms();
  const setWorkplaces = useSetWorkplaces();
  const setProfilePictureUrlsMap = useSetProfilePictureUrlsMap();
  const setFillableTemplates = useSetFillableTemplate();
  const setWcGroups = useSetWcGroups();
  const [showUserSettingsModal, setShowUserSettingsModal] = useState(false);
  const [reverifyUser, setReverifyUser] = useState<boolean>(false);
  const [onReverifyUser, setOnReverifyUser] = useState<() => void>(() => () => {});
  const setPrgs = useSetPrgs();
  const setOtRules = useSetOtRules();
  const setCompanyRoles = useSetCompanyRoles();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const setDepartments = useSetDepartments();
  const setLedgerAccounts = useSetLedgerAccounts();
  const [tags, setTags] = useState<Tag[]>([]);
  const [needsNps, setNeedsNps] = useState(false);
  const [showNewReleaseModal, setShowNewReleaseModal] = useShowNewReleaseModalAtom();
  const [integrations, setIntegrations] = useState<MiterIntegrationForCompany[]>([]);
  const setReportViews = useSetReportViews();
  const [confetti, setConfetti] = useConfettiAtom();
  const setStripeConnectedAccount = useSetStripeConnectedAccount();
  const setExpenseReimbursementCategories = useSetExpenseReimbursementCategories();
  const setCardTransactionCategories = useSetCardTransactionCategories();
  const setOnboardingChecklists = useSetOnboardingChecklists();
  const setDocuments = useSetCompanyDocuments();
  const setCertificationTypes = useSetCertificationTypes();
  const setPerDiemRates = useSetPerDiemRates();
  const routes = useRoutes();
  const [hitUnauthorizedError, setHitUnauthorizedError] = useHitUnauthorizedErrorAtom();
  const setLiveTimesheets = useSetLiveTimesheets();
  const setLocations = useSetLocations();
  const setEquipment = useSetEquipment();
  const setVendors = useSetVendors();
  const setBenefitsEligibilityGroups = useSetBenefitsEligibilityGroups();

  const setLastRefreshedAt = useSetLastRefreshedAt();

  useTeamChat();
  useRecruitingChat();

  useDisableNumberInputScroll();

  const redirectToGuidesRef = useMiterGuidesRedirect();

  const targetCompanyRef = useTargetCompany();

  useActionableItemsInterval();

  // Dyanmically  (without slowing load time) imports the Check script that we use to load check components
  useCheckScript("https://cdn.checkhq.com/component-initialize.js");

  const getIntegrations = async () => {
    if (!activeCompanyId) return;
    try {
      const response = await MiterAPI.integrations.retrieve(activeCompanyId);
      if (response.error) throw new Error(response.error);
      setIntegrations(response);
    } catch (e) {
      console.error(e);
    }
  };

  const getCustomers = async () => {
    if (!activeCompanyId) return;
    try {
      const filter = [{ field: "company_id", value: activeCompanyId }];
      const response = await MiterAPI.customers.search(filter);
      if (response.error) throw new Error(response.error);
      setCustomers(response);
    } catch (e) {
      console.error(e);
    }
  };

  const getCustomFields = async () => {
    if (!activeCompanyId) return;
    try {
      const filter = [{ field: "company_id", value: activeCompanyId }];
      const response = await MiterAPI.custom_fields.search(filter);
      if (response.error) throw new Error(response.error);
      setCustomFields(response);
    } catch (e) {
      console.error(e);
    }
  };

  const getTags = async () => {
    if (!activeCompanyId) return;
    try {
      const filter = [{ field: "company_id", value: activeCompanyId }];
      const response = await MiterAPI.tags.search(filter);
      if (response.error) throw new Error(response.error);
      setTags(response);
    } catch (e) {
      console.error(e);
    }
  };

  const initialize = async (): Promise<void> => {
    if (!userFetched) return;

    if (!activeCompanyId || !user || !activeAccount) return setInitializing(false);

    setInitializing(true);
    try {
      const res = await MiterAPI.initialize_apps.dashboard({
        company_id: activeCompanyId,
        user_id: user._id,
        team_member_id: activeAccount.team_member?._id,
        role_id: activeAccount.role?._id,
      });

      if (res.error) throw new Error(res.error);

      setCompanyActivities(res.activities);
      setJobs(res.jobs);
      setPrgs(res.payRateGroups);
      setTeamMembers(res.teamMembers);
      setOtRules(res.overtimeRules);
      setLedgerAccounts(res.ledgerAccounts);
      setCustomers(res.customers);
      setDepartments(res.departments);
      setNeedsNps(res.needsNps);
      setCustomFields(res.customFields);
      setPaySchedules(res.paySchedules.filter((ps) => !ps.archived));
      setHolidaySchedules(res.holidaySchedules);
      setTags(res.tags);
      setReportViews(res.reportViews);
      setCompanyRoles(res.roles);
      setIntegrations(res.integrations);
      setWcCodes(res.wcCodes);
      setCrews(res.crews);
      setRateDifferentials(res.rateDifferentials);
      setPolicies(res.policies);
      setLeaveTypes(res.leaveTypes);
      setJobPostings(res.jobPostings);
      setCostTypes(res.costTypes);
      setWorkplaces(res.workplaces);
      setLastRefreshedAt(DateTime.now());
      setPerformanceReviewSchedules(res.performanceReviewSchedules);
      setTimeOffPolicies(res.timeOffPolicies);
      setStandardClassifications(res.standardClassifications);
      setStripeConnectedAccount(res.stripeConnectedAccount);
      setPermissionGroups(res.permissionGroups);
      setSessionPermissionGroups(res.sessionPermissionGroups);
      setCompanyUsers(res.companyUsers);
      setLedgerMappings(res.ledgerMappings);
      setForms(res.forms);
      setExpenseReimbursementCategories(res.expenseReimbursementCategories);
      setCardTransactionCategories(res.cardTransactionCategories);
      setOnboardingChecklists(res.onboardingChecklists);
      setDocuments(res.documents);
      setCertificationTypes(res.certificationTypes);
      setProfilePictureUrlsMap(res.profilePictureUrlsMap);
      setFillableTemplates(res.fillableTemplates);
      setPerDiemRates(res.perDiemRates);
      setLiveTimesheets(res.liveTimesheets);
      setWcGroups(res.wcGroups);
      setLocations(res.locations);
      setEquipment(res.equipment);
      setVendors(res.vendors);
      setBenefitsEligibilityGroups(res.benefitsEligibilityGroups);
    } catch (e) {
      Notifier.error("Error initializing your dashboard data. Please reach out to support.");
      console.error("Error initializing dashboard data:", e);
    }
    await sleep(1); // Need to do this in order for the abilities to get set before we stop showing the loading page
    setInitializing(false);
  };

  const renderDashboardRoutes = (): ReactNode => {
    const protectedRoutes = routes.map((item) => {
      const element = (
        <DashboardWrapper accessible={item.accessible}>
          <item.component />
        </DashboardWrapper>
      );
      return <Route path={item.path} key={item.path} element={element} />;
    });
    return protectedRoutes;
  };

  useEffect((): void => {
    // If redirecting or impersonating, don't fetch user data
    if (redirectToGuidesRef.current || impersonatingRef.current) return;

    // If there's a search param for target company, toggle into it
    if (targetCompanyRef.current) {
      toggleAccount({ new_company_id: targetCompanyRef.current });
    } else {
      // Else just get the default role data
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    initialize();
    if (process.env.REACT_APP_ENVIRONMENT !== "production" || user?.miter_admin) {
      if (activeCompany) console.log("activeCompanyId", activeCompany._id || null);
      if (activeAccount) console.log("activeAccount", activeAccount);
    }
  }, [activeAccount?._id, userFetched]);

  useEffect(() => {
    if (hitUnauthorizedError) {
      setHitUnauthorizedError(false);
      navigate("/logout");
    }
  }, [hitUnauthorizedError]);

  const queryClient = new QueryClient();

  return (
    <div className="app-wrapper">
      <AppContext.Provider
        value={{
          fetchUserData,
          customers,
          getCustomers,
          integrations,
          getIntegrations,
          shiftPage,
          setShiftPage,
          showUserSettingsModal,
          setShowUserSettingsModal,
          reverifyUser,
          setReverifyUser,
          onReverifyUser,
          setOnReverifyUser,
          customFields,
          getCustomFields,
          tags,
          getTags,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/authenticate" element={<Authenticate />} />
            <Route path="/impersonate" element={<Impersonate />} />
            <Route path="/companies/new" element={<CompanyForm />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/permission-denied" element={<PermissionDeniedPage />} />
            {renderDashboardRoutes()}
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>

          {user && <UserAcknowledgements user={user} />}
          {needsNps && user && <RegularNPSSurvey setNeedsNps={setNeedsNps} user={user} />}
          {reverifyUser && <ReverifyModal />}
          {showUserSettingsModal && <UserSettingsModal />}
          {showNewReleaseModal && <NewReleaseModal onHide={() => setShowNewReleaseModal(false)} />}
          {confetti && (
            <Confetti
              tweenDuration={1e4}
              numberOfPieces={1e3}
              recycle={false}
              onConfettiComplete={() => setConfetti(false)}
            />
          )}
          <Toaster />
        </QueryClientProvider>
      </AppContext.Provider>
    </div>
  );
};

export default App;
