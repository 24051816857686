import { useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { Formblock, Button, Loader } from "ui";
import AppContext from "dashboard/contexts/app-context";
import { useGetSetupLink } from "dashboard/hooks/integrations/useGetSetupLink";
import {
  ClientCredentials,
  useExchangeClientCredentials,
} from "dashboard/hooks/integrations/useExchangeClientCredentials";
import { useQuery } from "miter-utils";
import { useAddFromUrl } from "dashboard/hooks/integrations/useAddFromUrl";
import { MiterIntegrationForCompany } from "dashboard/miter";

interface NetSuiteAuthenticationProps {
  integration: MiterIntegrationForCompany;
  activeCompanyId: string | null;
}

export const NetSuiteAuthentication: React.FC<NetSuiteAuthenticationProps> = ({
  integration,
  activeCompanyId,
}) => {
  const form = useForm();
  const query = useQuery();
  const { getIntegrations } = useContext(AppContext);
  const { getSetupLink, setupLink, loadingSetupLink, goToSetupLink } = useGetSetupLink({
    integration,
    activeCompanyId,
  });
  const { submitClientSecret, submitting: submittingClientCredentials } =
    useExchangeClientCredentials(integration);
  const { addFromUrl, loading: loadingAddFromUrlResponse } = useAddFromUrl(activeCompanyId);

  const exchangeClientCredentialsCallback = useCallback(async () => {
    await getIntegrations();
    getSetupLink();
  }, []);

  const handleSubmit = async (data: ClientCredentials) => {
    await submitClientSecret(data, exchangeClientCredentialsCallback);
  };

  useEffect(() => {
    const codeExists = !!query.get("code");
    if (codeExists) {
      addFromUrl("netsuite");
    }
  }, []);

  useEffect(() => {
    if (integration.connection?.metadata?.netsuite?.netsuiteCompanyId) {
      getSetupLink();
    }
  }, []);

  if (submittingClientCredentials || loadingSetupLink || loadingAddFromUrlResponse) return <Loader />;

  if (setupLink) {
    return (
      <Button onClick={goToSetupLink} className="button-2">
        Set Up NetSuite
      </Button>
    );
  }

  return (
    <div style={{ border: "1px solid lightgray", padding: 20, borderRadius: 5 }}>
      <div className="vertical-spacer" />
      <Formblock
        label="Client ID"
        name="clientId"
        type="password"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <Formblock
        label="Client Secret"
        name="clientSecret"
        type="password"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <Formblock
        label="Tenant ID"
        name="tenantId"
        type="text"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <div className="vertical-spacer-small"></div>
      <Button
        text="Connect"
        onClick={form.handleSubmit(handleSubmit)}
        className="button-2 no-margin"
        loading={submittingClientCredentials}
      />
    </div>
  );
};
