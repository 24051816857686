import { FlatfileResults } from "@flatfile/react";
import {
  useActiveCompany,
  useActiveCompanyId,
  useActiveJobs,
  useEquipment,
  useEquipmentOptions,
  useJobOptions,
  useSelectableActivitiesMap,
  useTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { EquipmentTimesheet, MiterAPI } from "dashboard/miter";
import { Importer, ImportField } from "../importer/Importer";
import { useMemo } from "react";
import {
  useValidateEquipmentCodes,
  useValidateHours,
  useValidateJobCode,
  useValidateCostCode,
  useValidateClockInTime,
  useValidateTeamMemberID,
  useValidateQuantity,
} from "dashboard/utils/timesheetUtils";
import { parseClockInTime, timezoneOptions } from "miter-utils";
import { normalizeDate } from "dashboard/utils/flatfile";
import { Notifier } from "dashboard/utils";
import { keyBy } from "lodash";
import { DateTime } from "luxon";

export type PrelimEquipmentTimesheetImportRow = {
  equipmentCode: string;
  equipmentName: string;
  teamMemberId: string;
  date: string;
  clockIn: string;
  jobCode: string;
  jobName: string;
  costCode: string;
  hours: string;
  timezone: string;
  notes: string;
  status: EquipmentTimesheet["status"];
  quantity: number;
};

type Props = {
  onFinish: () => void;
};

export const EquipmentTimesheetImporter: React.FC<Props> = ({ onFinish }) => {
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const equipment = useEquipment();
  const teamMembers = useTeam();
  const activeJobs = useActiveJobs();
  const jobOptions = useJobOptions();
  const equipmentOptions = useEquipmentOptions();
  const user = useUser();
  const validateTeamMemberID = useValidateTeamMemberID();
  const validateEquipmentCodes = useValidateEquipmentCodes();
  const validateJobCode = useValidateJobCode();
  const validateCostCode = useValidateCostCode();
  const validateClockInTime = useValidateClockInTime();
  const validateHours = useValidateHours();
  const validateQuantity = useValidateQuantity();
  const lookupEquipmentCode = useMemo(() => keyBy(equipment, "code"), [equipment]);
  const lookupEquipmentName = useMemo(() => keyBy(equipment, "name"), [equipment]);
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);
  const lookupActivity = useSelectableActivitiesMap();
  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);
  const lookupJobName = useMemo(() => keyBy(activeJobs, "name"), [activeJobs]);

  /*********************************************************
   *  Helper functions
   **********************************************************/
  const buildEquipmentTimesheetParams = (row: PrelimEquipmentTimesheetImportRow) => {
    const {
      equipmentCode,
      equipmentName,
      teamMemberId,
      date,
      clockIn,
      jobCode,
      jobName,
      costCode,
      hours,
      timezone,
      notes,
      status,
      quantity,
    } = row;

    if (!activeCompanyId) throw new Error("Company not found");
    if (!user?._id) throw new Error("User not found");

    const teamMember = lookupTeamID[teamMemberId];

    const equipmentId = lookupEquipmentCode[equipmentCode]?._id || lookupEquipmentName[equipmentName]?._id;
    if (!equipmentId) throw new Error(`Equipment ${equipmentCode} ${equipmentName} not found`);

    const job = lookupJobCode[jobCode] || lookupJobName[jobName];
    const activity = costCode ? lookupActivity.get(job?._id).find((a) => a.cost_code === costCode) : null;

    const finalTimezone = timezone || job?.timezone || teamMember?.timezone || activeCompany?.timezone;

    const { hour, minute, second } = parseClockInTime(clockIn, activeCompany);

    const clockInDT = DateTime.fromISO(date, { zone: timezone }).set({
      hour,
      minute,
      second,
      millisecond: 0,
    });
    const clockOutDT = clockInDT.plus({ hours: Number(hours) });

    return {
      equipment_id: equipmentId,
      company_id: activeCompanyId,
      team_member_id: teamMember?._id,
      date,
      clock_in: clockInDT.toSeconds(),
      clock_out: clockOutDT.toSeconds(),
      job_id: job?._id,
      activity_id: activity?._id,
      timezone: finalTimezone,
      status: status || undefined,
      hours: Number(hours),
      created_by: user?._id,
      quantity: quantity || 1,
      notes,
    };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const formattedParams = results.validData.map(buildEquipmentTimesheetParams);
      const response = await MiterAPI.equipment_timesheets.import({
        clean_inputs: formattedParams,
        raw_inputs: results.validData,
      });

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;
      const warnings = response.results.warnings.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} timesheets with ${errors} errors and ${warnings} warnings.`);
        } else {
          Notifier.success(`Imported ${successes} timesheets with ${warnings} warnings.`);
        }
      } else {
        Notifier.error(`There were ${errors} errors and ${warnings} warnings.`);
      }
      onFinish();
    } catch (error) {
      console.error(error);
      Notifier.error("There was an error creating the equipmenttimesheets.");
    }
  };

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Equipment code",
        key: "equipmentCode",
        type: "string",
        hook: (val) =>
          typeof val === "string"
            ? validateEquipmentCodes({ equipmentCodes: val })
            : validateEquipmentCodes(val),
      },
      {
        label: "Equipment name",
        key: "equipmentName",
        description: "The name of the equipment.",
        type: "select",
        options: equipmentOptions,
      },
      {
        label: "Team Member ID",
        key: "teamMemberId",
        type: "string",
        hook: (val) =>
          typeof val === "string"
            ? validateTeamMemberID({ teamMemberId: val }, true)
            : validateTeamMemberID(val, true),
      },
      {
        label: "Date",
        type: "string",
        key: "date",
        description: "The date work was performed, in YYYY-MM-DD format.",
        validators: [{ validate: "required" }],
        hook: (val) => (typeof val === "string" ? normalizeDate(val) : normalizeDate(val.date)),
      },
      {
        label: "Clock-in time",
        type: "string",
        key: "clockIn",
        description: `Example: "09:30" or "17:30".`,
        hook: (val) =>
          typeof val === "string" ? validateClockInTime({ clockIn: val }) : validateClockInTime(val),
      },
      {
        label: "Job Code",
        type: "string",
        key: "jobCode",
        hook: (val) => (typeof val === "string" ? validateJobCode({ jobCode: val }) : validateJobCode(val)),
      },
      {
        label: "Job name",
        type: "select",
        key: "jobName",
        description: "The unique job name.",
        options: jobOptions,
      },
      {
        label: "Cost code",
        type: "string",
        key: "costCode",
        hook: (val) =>
          typeof val === "string" ? validateCostCode({ costCode: val }) : validateCostCode(val),
      },
      {
        label: "Hours",
        type: "string",
        key: "hours",
        validators: [{ validate: "required" }],
        hook: (val) => (typeof val === "string" ? validateHours({ hours: val }) : validateHours(val)),
      },
      {
        label: "Status",
        type: "select",
        key: "status",
        options: [
          { label: "Unapproved", value: "unapproved" },
          { label: "Approved", value: "approved" },
        ],
      },
      {
        label: "Quantity",
        type: "string",
        key: "quantity",
        description: "The quantity of the equipment used.",
        hook: (val) =>
          typeof val === "string" ? validateQuantity({ quantity: val }) : validateQuantity(val),
      },
      {
        label: "Time zone",
        type: "select",
        key: "timezone",
        description: `TZ identifier (i.e., "America/Los_Angeles"). If left blank, Miter will use the timezone of the job or employee`,
        options: timezoneOptions,
      },
      {
        label: "Notes",
        type: "string",
        key: "notes",
        description: "Any additional information about the timesheet",
      },
    ];
    return fieldList;
  }, [
    validateEquipmentCodes,
    validateTeamMemberID,
    validateJobCode,
    validateCostCode,
    validateClockInTime,
    validateHours,
    timezoneOptions,
  ]);

  return (
    <Importer
      id="equipment_timesheets"
      resource="equipment timesheets"
      onSave={handleSubmit}
      fields={fields}
    />
  );
};
