import React from "react";
import InfoButton from "dashboard/components/information/information";
import { TextWithTooltip } from "..";

type Props = {
  label: React.ReactNode;
  labelInfo?: string;
  tooltipPlace?: "top" | "right" | "bottom" | "left";
  underlineTooltip?: boolean;
  sublabel?: React.ReactNode;
  className?: string;
  labelStyle?: React.CSSProperties;
  sublabelStyle?: React.CSSProperties;
  alert?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: $TSFixMe) => void;
};

const Label: React.FC<Props> = ({
  label,
  labelInfo,
  tooltipPlace,
  underlineTooltip,
  sublabel,
  className = "",
  alert,
  onClick,
  style,
  labelStyle,
  sublabelStyle,
}) => {
  return (
    <div className={"label-wrapper " + className} onClick={onClick} style={labelStyle || style}>
      <div className={"label-line " + className}>
        {labelInfo ? (
          <>
            {underlineTooltip ? (
              <TextWithTooltip
                id={labelInfo.toString()}
                text={label}
                tooltip={labelInfo}
                place={tooltipPlace}
              />
            ) : (
              <>
                <span className="label-text">{label}</span>
                <InfoButton text={labelInfo} place={tooltipPlace} />
              </>
            )}
          </>
        ) : (
          <span className="label-text">{label}</span>
        )}
        {alert && <div className="label-alert"></div>}
      </div>
      {sublabel && (
        <div className="sublabel-wrapper" style={sublabelStyle}>
          <span className="sub-label">{sublabel}</span>
        </div>
      )}
    </div>
  );
};

export default Label;
