import React from "react";
import { SettingsCard, Formblock } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "dashboard/miter";
import { ProcoreConfigObject, ProcoreConnectionMetadata } from "backend/services/procore/procore-types";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { useForm } from "react-hook-form";
import { useCostTypeOptions } from "dashboard/hooks/atom-hooks";
import { CustomFieldDefinitionsFormBlock } from "./CustomFieldDefinitionsFormBlock";
import { expandedEarningTypeOptions } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { IntegrationEarningTypeConfig } from "../IntegrationEarningTypeConfig";
import { TimesheetEarningType } from "backend/models/timesheet";

export const PROCORE_CONFIG_INPUT_LENGTH = 250;
export const DEFAULT_PROCORE_LABEL_STYLE = { minWidth: 230 };

export const ProcoreConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
  ...rest
}) => {
  const form = useForm();

  const costTypeOptions = useCostTypeOptions();
  const procoreConfigOptions: ProcoreConfigObject | undefined =
    integration.connection?.metadata?.procore?.configObject;
  const { earningTypeMapping } = procoreConfigOptions?.timesheetsSync || {};

  const updateProcoreMetadata = async (
    update: DeepPartial<ProcoreConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { procore: update } };
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(raw, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateIntegrationConnection(flattened);
  };

  const saveEarningTypeCallback = async (newEarningTypeMap: Record<string, TimesheetEarningType>) => {
    await updateProcoreMetadata(
      {
        configObject: { timesheetsSync: { earningTypeMapping: newEarningTypeMap } },
      },
      { collapseCount: 2 }
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Timesheets">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="presyncJobsAndActivities"
          type="checkbox"
          labelInfo="When syncing Timesheets, run syncs for Jobs and Activities automatically."
          label="WBS Auto-Sync"
          defaultValue={procoreConfigOptions?.timesheetsSync?.presyncJobsAndActivities}
          onChange={(e) =>
            updateProcoreMetadata({
              configObject: { timesheetsSync: { presyncJobsAndActivities: e.target.checked } },
            })
          }
          labelStyle={DEFAULT_PROCORE_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          type="select"
          form={form}
          label="Default Cost Type ID"
          options={costTypeOptions}
          editing={true}
          underlineTooltip={true}
          labelInfo="The Cost Type Miter will use by default when pushing Timesheets to Procore"
          defaultValue={procoreConfigOptions?.timesheetsSync?.defaultCostTypeId}
          labelStyle={DEFAULT_PROCORE_LABEL_STYLE}
          onChange={(o) =>
            updateProcoreMetadata({
              configObject: { timesheetsSync: { defaultCostTypeId: o?.value } },
            })
          }
          inputProps={{ style: { width: PROCORE_CONFIG_INPUT_LENGTH } }}
        />
        <CustomFieldDefinitionsFormBlock
          integration={integration}
          updateProcoreMetadata={updateProcoreMetadata}
          procoreConfigOptions={procoreConfigOptions}
          form={form}
          updateIntegrationConnection={updateIntegrationConnection}
          {...rest}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="syncSubjobsAsJobs"
          type="checkbox"
          labelInfo="When syncing Timesheets, default to using Sub Jobs for the Job field."
          label="Sync Sub Jobs as Jobs"
          defaultValue={procoreConfigOptions?.timesheetsSync?.syncSubjobsAsJobs}
          onChange={(e) =>
            updateProcoreMetadata({
              configObject: { timesheetsSync: { syncSubjobsAsJobs: e.target.checked } },
            })
          }
          labelStyle={DEFAULT_PROCORE_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Activities">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="syncSubJobCostCodes"
          type="checkbox"
          labelInfo="When syncing Activities, look for Cost Codes attached to Sub Jobs."
          label="Sync Sub Job Cost Codes as Activities"
          defaultValue={procoreConfigOptions?.activitiesSync?.pullSubJobCostCodesAsActivities}
          onChange={(e) =>
            updateProcoreMetadata({
              configObject: { activitiesSync: { pullSubJobCostCodesAsActivities: e.target.checked } },
            })
          }
          labelStyle={DEFAULT_PROCORE_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Earning type mapping">
        <IntegrationEarningTypeConfig
          saveEarningTypeCallback={saveEarningTypeCallback}
          earningTypeMapping={earningTypeMapping}
          earningTypeOptions={expandedEarningTypeOptions}
        />
      </SettingsCard>
    </div>
  );
};
