import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { CheckrPackage } from "dashboard/types/screening-types";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Notifier } from "ui";
import Banner from "../shared/Banner";
import { MiterGuidesIcon } from "../MiterGuidesIcon";
import { HierarchicalNodeMap } from "./useCheckrPackages";
import { AddScreeningForm, ScreeningForm } from "./AddScreeningForm";

type Props = {
  onExit: () => void;
  mapOfNodesToPackages: HierarchicalNodeMap;
  companyPackages: CheckrPackage[];
  teamMember: AggregatedTeamMember;
};

export const AddScreeningModal: React.FC<Props> = ({
  mapOfNodesToPackages,
  companyPackages,
  onExit,
  teamMember,
}) => {
  const form = useForm<ScreeningForm>();
  const { watch, setValue } = form;

  const nodeId = watch("node_id")?.value;
  const companyId = useActiveCompanyId();

  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  /** Reset checkr package when node changes */
  useEffect(() => {
    setValue("checkr_package", null);
  }, [nodeId]);

  const createNewScreening = async (data) => {
    try {
      if (!companyId) return;
      const res = await MiterAPI.screenings.create({
        company_id: companyId,
        team_member_id: teamMember._id,
        checkr_package: data.checkr_package.value,
        state: data.state.value,
        node: data?.node_id?.value,
      });

      if (res.error) {
        throw new Error(res.error);
      }
      Notifier.success(`New background check started for ${teamMember?.full_name || "team member"}!`);
      onExit();
    } catch (e: $TSFixMe) {
      Notifier.error("Error creating a background check: " + e.message);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    await handleSubmit(createNewScreening)();
    setLoading(false);
  };

  return (
    <ActionModal
      headerText={"Create new background check"}
      onHide={onExit}
      showCancel={true}
      onCancel={onExit}
      showSubmit={true}
      onSubmit={onSubmit}
      submitText={"Confirm purchase"}
      loading={loading}
      bodyStyle={{ overflowX: "visible", paddingTop: 15 }}
      wrapperStyle={{ minWidth: 650 }}
    >
      <Banner style={{ marginBottom: 15 }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div>Learn more about available packages when conducting a background check.</div>
          <div style={{ marginBottom: -2 }}>
            <MiterGuidesIcon path="team-members/background-checks" marginLeft={7} />
          </div>
        </div>
      </Banner>
      <AddScreeningForm
        form={form}
        mapOfNodesToPackages={mapOfNodesToPackages}
        companyPackages={companyPackages}
        teamMember={teamMember}
      />
    </ActionModal>
  );
};
