import { Button } from "ui";

interface SyncEntriesButtonProps {
  hasIntegrationWithLeSyncEnabled: boolean;
  allSelectableAreSyncableToIntacct: boolean;
  intacctIcId: string | undefined;
  acumaticaIcId: string | undefined;
  onClick: () => void;
}

export const SyncEntriesButton: React.FC<SyncEntriesButtonProps> = ({
  hasIntegrationWithLeSyncEnabled,
  allSelectableAreSyncableToIntacct,
  intacctIcId,
  acumaticaIcId,
  onClick,
}) => {
  if (!!acumaticaIcId) return null;

  if (hasIntegrationWithLeSyncEnabled && (allSelectableAreSyncableToIntacct || !intacctIcId))
    return <Button text="Sync entries" onClick={onClick} />;

  return null;
};
