import React, { useEffect, useRef } from "react";
import { Formblock, Label, Notifier, WizardScreen, usdString } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { Company, MiterAPI, TeamMember, UpdateTeamMemberParams } from "dashboard/miter";
import {
  useActiveCompany,
  useActiveCompanyId,
  useClassificationOptions,
  useLookupRateClassification,
  usePayScheduleOptions,
  usePrgOptions,
  usePrgs,
  useRefetchTeam,
} from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { sleep } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import { Option } from "ui/form/Input";
import {
  convertAnnualRateToDisplayRate,
  convertDisplayRateToAnnualRate,
} from "dashboard/pages/team-members/TeamUtils";
import { WizardTeamMember } from "./TeamMemberWizard";
import { salaryRateOptions } from "miter-utils";
import { useOtExemptUtils } from "dashboard/hooks/useOtExempt";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
  mode: "create" | "enroll_in_payroll";
};

type TeamMemberPayrollInfoForm = {
  enroll_in_payroll?: Option<boolean>;
  pay_type?: Option<TeamMember["pay_type"] | "union_rate"> | null;
  union_rate?: Option<string> | null;
  pay_rate?: number | null;
  salary_rate_display?: Option<TeamMember["salary_rate_display"]> | null;
  pay_schedule_id?: Option<string> | null;
  overtime_exempt?: boolean;
  pay_rate_group?: Option<string> | null;
  classification?: Option<string> | null;
  salary_rate?: number;
};

export const TeamMemberPayrollInfoScreen: React.FC<Props> = ({ name, mode, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();

  const payScheduleOptions = usePayScheduleOptions();
  const isMounted = useRef(true);

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();
  const prgs = usePrgs();
  const refetchTeam = useRefetchTeam();
  const form = useForm<TeamMemberPayrollInfoForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(teamMember, { mode, company: activeCompany }),
  });

  const { otExemptionIsRelevant } = useOtExemptUtils();

  const formData = useWatch<TeamMemberPayrollInfoForm>({ control: form.control });

  const lookupRateClassification = useLookupRateClassification();
  const classificationOptions = useClassificationOptions({ prgId: formData.pay_rate_group?.value });
  const selectedClassification = lookupRateClassification(formData.classification?.value);
  const prgOptions = usePrgOptions();

  const defaultaPRG = Object.keys(teamMember?.prg_classifications || {})[0];
  const defaultClassification = teamMember?.prg_classifications?.[defaultaPRG || ""];

  const company = activeCompany;
  const companyHasPayroll = company?.has_payroll && company.check_company.start_date;

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  const enrollInPayroll = form.watch("enroll_in_payroll")?.value === true;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  useEffect(() => {
    if (defaultClassification) {
      const fullOption = classificationOptions.find((c) => c.value === defaultClassification);
      form.setValue("classification", fullOption);
    }
  }, [defaultClassification, defaultaPRG, classificationOptions]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0 || mode === "enroll_in_payroll") {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberPayrollInfoForm) => {
    if (!activeCompanyId) throw new Error("No active company");

    const {
      pay_type,
      salary_rate_display,
      salary_rate,
      pay_rate,
      enroll_in_payroll,
      pay_schedule_id,
      overtime_exempt,
    } = params;

    const payRate =
      pay_type?.value === "salary"
        ? convertDisplayRateToAnnualRate(company, salary_rate_display?.value || "year", salary_rate || 0)
        : Number(pay_rate);

    const data: UpdateTeamMemberParams = {
      pay_schedule_id: pay_schedule_id?.value,
      enroll_in_payroll: enroll_in_payroll?.value || mode === "enroll_in_payroll" || false,
      salary_rate_display: salary_rate_display?.value,
      overtime_exempt: overtime_exempt,

      pay_type: pay_type?.value === "union_rate" ? "hourly" : pay_type?.value,
      pay_rate: payRate,

      union_rate: pay_type?.value === "union_rate" ? params.classification?.value : null,
      resume_wizard_index: curIndex + 1,
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberPayrollInfoForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }
      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  // When the pay rate group changes, reset the classification options and the selected classification
  const handlePrgChange = () => {
    form.setValue("classification", null);
  };

  /*********************************************************
   * Build form options
   **********************************************************/
  const payTypeOptions = [
    { value: "hourly", label: prgs.length ? "Hourly (non-union)" : "Hourly" },
    ...(prgs.length ? [{ value: "union_rate", label: "Hourly (union)" }] : []),
    { value: "salary", label: "Salary" },
  ];

  const renderPayRate = () => {
    if (formData.pay_type?.value === "union_rate") {
      return renderUnionPayRate();
    } else if (formData.pay_type?.value === "hourly") {
      return renderHourlyPayRate();
    } else {
      return renderSalaryPayRate();
    }
  };

  const renderSalaryPayRate = () => {
    return (
      <div className="form-section">
        <Label label={"Pay rate" + (enrollInPayroll ? "*" : "")} style={{ marginBottom: 5 }} />

        <div className="flex space-between">
          <Formblock
            type="unit"
            unit="$"
            form={form}
            placeholder={"0.00"}
            name="salary_rate"
            className="modal wizard"
            editing={true}
            style={{ width: "100%", height: 32 }}
            val={enrollInPayroll ? vals.required : undefined}
          />
          <Formblock
            type="select"
            options={salaryRateOptions}
            defaultValue={teamMember?.salary_rate_display || "year"}
            form={form}
            name="salary_rate_display"
            className="modal wizard"
            editing={true}
            style={{ width: 150, marginLeft: 10, height: 32 }}
            val={enrollInPayroll ? vals.required : undefined}
          />
        </div>
      </div>
    );
  };

  const renderHourlyPayRate = () => {
    return (
      <Formblock
        type="unit"
        unit="$"
        name="pay_rate"
        label={"Hourly pay rate" + (enrollInPayroll ? "*" : "")}
        form={form}
        placeholder="0.00"
        editing={true}
        className="modal wizard"
        val={enrollInPayroll ? vals.required : undefined}
      />
    );
  };

  const renderUnionPayRate = () => {
    return (
      <>
        <Formblock
          type="select"
          name="pay_rate_group"
          label="Pay rate group*"
          form={form}
          options={prgOptions}
          requiredSelect={true}
          editing={true}
          onChange={handlePrgChange}
          className="modal wizard"
          defaultValue={defaultaPRG}
        />
        {classificationOptions && (
          <Formblock
            type="select"
            name="classification"
            label="Classification*"
            form={form}
            options={classificationOptions}
            requiredSelect={true}
            editing={true}
            className="modal wizard"
            defaultValue={defaultClassification}
          />
        )}
        {selectedClassification && (
          <Formblock
            type="text"
            name="union_pay_rate"
            label="Base hourly rate"
            value={usdString(selectedClassification?.base_rate)}
            form={form}
            editing={true}
            className="modal wizard"
            disabled={true}
          />
        )}
      </>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Payroll information</h2>
          <p className={styles["subheader-description"]}> Add the team member&apos;s payroll information</p>
        </div>
        <div className="form-section">
          {companyHasPayroll && (
            <Formblock
              type="select"
              name="enroll_in_payroll"
              label="Add this team member to payroll"
              form={form}
              className="modal wizard"
              editing={true}
              defaultValue={true}
              options={ENROLL_IN_PAYROLL_OPTIONS}
              val={vals.required}
            />
          )}
          <div className="form-section">
            {companyHasPayroll && (
              <h3 className="form-section-subheader">Add this team member to a pay schedule and rate</h3>
            )}
            <Formblock
              type="select"
              name="pay_schedule_id"
              label={"Pay schedule" + (enrollInPayroll ? "*" : "")}
              form={form}
              options={payScheduleOptions}
              requiredSelect={enrollInPayroll ? true : false}
              editing={true}
              className="modal wizard"
              val={enrollInPayroll ? vals.required : undefined}
              defaultValue={teamMember?.pay_schedule_id}
            />
            <Formblock
              type="select"
              name="pay_type"
              label={"Pay type" + (enrollInPayroll ? "*" : "")}
              form={form}
              options={payTypeOptions}
              editing={true}
              requiredSelect={enrollInPayroll ? true : false}
              className="modal wizard"
              val={enrollInPayroll ? vals.required : undefined}
              defaultValue={teamMember?.union_rate ? "union_rate" : teamMember?.pay_type}
            />
            {formData.pay_type?.value && (
              <>
                {renderPayRate()}
                {otExemptionIsRelevant(teamMember) && (
                  <Formblock
                    type="checkbox"
                    name="overtime_exempt"
                    label="Overtime exempt"
                    text="This team member is exempt from overtime pay"
                    form={form}
                    defaultValue={
                      teamMember!.overtime_exempt != null
                        ? teamMember!.overtime_exempt
                        : formData.pay_type?.value === "salary"
                    }
                    editing={true}
                    className="modal wizard"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  teamMember: WizardTeamMember | undefined,
  params: {
    company: Company | null;
    mode: "create" | "enroll_in_payroll";
  }
): TeamMemberPayrollInfoForm => {
  if (!teamMember) return {};

  const { company, mode } = params;

  const salaryRate =
    teamMember.pay_type === "salary"
      ? convertAnnualRateToDisplayRate(company, "salary", teamMember.salary_rate_display, teamMember.pay_rate)
      : undefined;

  return {
    pay_rate: teamMember.pay_type === "hourly" ? teamMember.pay_rate : undefined,
    salary_rate: salaryRate,
    enroll_in_payroll: mode === "enroll_in_payroll" ? { value: true, label: "Yes" } : undefined,
  };
};

const ENROLL_IN_PAYROLL_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
] as unknown as Option<boolean>[];
