import styles from "../TeamMemberChecklistBuilder.module.css";

import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { PaymentMethodOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const PaymentMethodTaskContent: React.FC<ChecklistTaskContentProps<PaymentMethodOnboardingTaskForm>> =
  ({ task, setTask }) => {
    return (
      <div className={styles["team-member-checklist-task-content"]}>
        Require this employee to select whether they would like to be paid via direct deposit or paper check.
        <DueDateForm task={task} setTask={setTask} />
      </div>
    );
  };
