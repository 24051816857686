/* global google */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Formblock, Loader } from "ui";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Address, AggregatedJob, Job } from "dashboard/miter";
import { Notifier, cleanAddress } from "dashboard/utils";
import mapMarkerGreen from "dashboard/assets/mapMarkerGreen.png";
import mapMarkerBlue from "dashboard/assets/mapMarkerBlue.png";
import {
  getAddressFromCoordinates,
  getLatLngFromAddressString,
  LatLng,
  useLoadGoogleMaps,
} from "dashboard/utils/geolocation";
import { useForm } from "react-hook-form";
import { LargeModal } from "ui";
import { useActiveCompany, useRefetchWorkplaces } from "dashboard/hooks/atom-hooks";
import InfoButton from "dashboard/components/information/information";

type Props = {
  job: AggregatedJob;
  updateJob: (update: Partial<Job>) => Promise<void>;
  hide: () => void;
  updatingJob: boolean;
};

export const JobLocationModal: React.FC<Props> = ({ job, updateJob, hide, updatingJob }) => {
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();

  const multiWorkplacePayroll = activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;

  const companyName = activeCompany!.check_company.trade_name;
  const timesheetGeolocationIsEnabled = activeCompany!.settings.timesheets.geolocation;

  const [lastLocationClicked, setLastLocationClicked] = useState<
    { lat: number; lng: number; address: Partial<Address>; addressString: string } | undefined
  >();
  const [geocodeLoading, setGeocodeLoading] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [initialMapCenter, setInitialMapCenter] = useState<{ lat: number; lng: number } | undefined>();

  const [ignoreWorkplace, setIgnoreWorkplace] = useState(!!job.ignore_workplace);

  const getAddressOfMapClick = async (e) => {
    setGeocodeLoading(true);
    const clickCoordinates: LatLng = e.latLng.toJSON();
    const { address, addressString } = await getAddressFromCoordinates(clickCoordinates);
    if (addressString && address) {
      setLastLocationClicked({
        ...clickCoordinates,
        addressString,
        address,
      });
    }

    setGeocodeLoading(false);
  };

  const updateFromLastLocationClicked = async () => {
    if (lastLocationClicked) {
      try {
        await updateJob({
          address: { ...lastLocationClicked.address, line2: lastLocationClicked.address.line2 || undefined },
          geolocation: {
            lat: lastLocationClicked.lat,
            lng: lastLocationClicked.lng,
          },
        });
        setLastLocationClicked(undefined);
      } catch (e) {
        console.error("Error updating job.");
        Notifier.error("There was an error updating the job. We're looking into it.");
      }
    }
  };

  const goToTimesheetSettings = () => {
    navigate("/timesheet-settings");
  };

  const getInitialMapCenter = async () => {
    setInitializing(true);
    let mapCenter = job.geolocation || { lat: 30.2672, lng: -97.7431 };
    try {
      const initialAddressObject = job.address || activeCompany!.check_company.address;
      if (initialAddressObject) {
        const addressString = `${initialAddressObject.line1} ${initialAddressObject.line2} ${initialAddressObject.city} ${initialAddressObject.state} ${initialAddressObject.postal_code}`;
        const coordinates = await getLatLngFromAddressString(addressString);
        if (coordinates?.lat && coordinates?.lng) {
          mapCenter = coordinates;
        }
      }
    } catch (e) {
      console.error("Error determining initial map center for job geolocation.");
    }
    setInitialMapCenter(mapCenter);
    setInitializing(false);
  };

  useEffect(() => {
    getInitialMapCenter();
  }, [job]);

  const [editingAddress, setEditingAddress] = useState(false);
  const { register, control, errors, handleSubmit } = useForm();

  const refetchWorkplaces = useRefetchWorkplaces();

  const submit = async (data) => {
    const cleanedAddress = data.address ? cleanAddress(data.address) : undefined;
    await updateJob({
      address: cleanedAddress,
      ignore_workplace: ignoreWorkplace,
    });
    await refetchWorkplaces();
    setEditingAddress(false);
  };

  const [clearingAddress, setClearingAddress] = useState(false);
  const clearJobAddress = async () => {
    setClearingAddress(true);
    await updateJob({ address: null, geolocation: null });
    setClearingAddress(false);
    setEditingAddress(false);
  };

  const handleCancelEdit = () => {
    setEditingAddress(false);
    setIgnoreWorkplace(!!job.ignore_workplace);
  };

  const renderIgnoreWorkplaceText = () => {
    return (
      <div className="flex">
        <span>Disable tax jurisdiction for this job</span>
        <InfoButton text="If checked, no formal tax jurisdiction will be created for this job" />
      </div>
    );
  };

  return (
    <LargeModal onClose={hide} headerText="Edit job location">
      <div className="vertical-spacer"></div>
      <div>
        {!timesheetGeolocationIsEnabled && (
          <>
            <div className="yellow-text-container">
              <span>{`Warning: Geolocation is not enabled for ${companyName}'s timesheets. To see your team's location when they clock in and out, visit `}</span>
              <span className="blue-link" onClick={goToTimesheetSettings}>
                Timesheet Settings
              </span>
              .
            </div>
            <div className="vertical-spacer" />
          </>
        )}
        {initializing ? (
          <Loader />
        ) : (
          <div className="flex" style={{ alignItems: "flex-start" }}>
            <div style={{ width: "40%", marginRight: 20 }}>
              <div className="billing-card-wrapper" style={{ marginTop: 0 }}>
                <div className="flex">
                  <img src={mapMarkerGreen} style={{ height: 25, marginRight: 20, marginBottom: 100 }} />
                  <div style={{ width: "100%" }}>
                    <Formblock
                      type="address"
                      name="address"
                      notRequiredRegister={register}
                      defaultValue={job.address ? (job.address as Address) : null}
                      editing={editingAddress}
                      register={register}
                      control={control}
                      errors={errors}
                      required={job.cpr_info ? true : false}
                    />
                    {multiWorkplacePayroll && (
                      <Formblock
                        text={renderIgnoreWorkplaceText()}
                        type="checkbox"
                        className="modal"
                        checked={ignoreWorkplace}
                        onChange={(e) => setIgnoreWorkplace(e.target.checked)}
                        name="ignore_workplace"
                        editing={true}
                        disabled={!editingAddress}
                        register={register}
                        errors={errors}
                      />
                    )}
                    <div className="flex" style={{ marginTop: 20 }}>
                      <>
                        {editingAddress && (
                          <Button
                            className="button-3 no-margin"
                            text="Clear"
                            onClick={() => clearJobAddress()}
                            loading={clearingAddress}
                          />
                        )}
                        <div className="flex-1"></div>
                        {editingAddress ? (
                          <div className="flex">
                            <div className="flex-1"></div>
                            <Button className="button-1" text="Cancel" onClick={handleCancelEdit} />
                            <Button
                              onClick={handleSubmit(submit)}
                              className="button-2 no-margin"
                              text="Save"
                              loading={updatingJob && !clearingAddress}
                            />
                          </div>
                        ) : (
                          <Button className="button-1" text="Edit" onClick={() => setEditingAddress(true)} />
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
              {geocodeLoading ? (
                <Loader />
              ) : (
                <>
                  {lastLocationClicked && (
                    <div className="billing-card-wrapper">
                      <div className="flex">
                        <img src={mapMarkerBlue} style={{ height: 25, marginRight: 20 }} />
                        <div>
                          <div style={{ fontWeight: "bold" }}>Last location clicked</div>
                          <div>{lastLocationClicked.addressString}</div>
                        </div>
                      </div>
                      <div className="flex" style={{ marginTop: 20 }}>
                        <div className="flex-1"></div>
                        <Button
                          className="button-2 no-margin"
                          text="Update job location"
                          onClick={updateFromLastLocationClicked}
                          loading={updatingJob}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <MapComponent
              getAddressOfMapClick={getAddressOfMapClick}
              initialMapCenter={initialMapCenter}
              jobGeolocation={job.geolocation}
            />
          </div>
        )}
      </div>
    </LargeModal>
  );
};

type MapComponentProps = {
  initialMapCenter?: {
    lat: number;
    lng: number;
  };
  jobGeolocation?: {
    lat: number;
    lng: number;
  } | null;
  getAddressOfMapClick: (e) => void;
};

const MapComponent: React.FC<MapComponentProps> = ({
  initialMapCenter,
  getAddressOfMapClick,
  jobGeolocation,
}) => {
  const containerStyle = {
    width: "60%",
    height: "400px",
  };

  const { isLoaded } = useLoadGoogleMaps();
  const [_map, setMap] = React.useState(null);

  const [center, setCenter] = useState<{ lat: number; lng: number }>(
    initialMapCenter || { lat: 30.2672, lng: -97.7431 }
  );

  useEffect(() => {
    if (initialMapCenter) {
      setCenter(initialMapCenter);
    }
  }, [initialMapCenter]);

  const [lastLocationClicked, setLastLocationClicked] = useState<{ lat: number; lng: number } | undefined>();

  const onUnmount = React.useCallback(function callback(_map) {
    setMap(null);
  }, []);

  const handleMapClick = (e) => {
    const { lat, lng } = e.latLng.toJSON();
    setLastLocationClicked({ lat, lng });
    getAddressOfMapClick(e);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={jobGeolocation ? 10 : 3}
      onUnmount={onUnmount}
      onClick={handleMapClick}
      options={{
        disableDefaultUI: true,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
        },
      }}
    >
      {lastLocationClicked && (
        <Marker
          position={lastLocationClicked}
          icon={{ url: mapMarkerBlue, scaledSize: new google.maps.Size(25, 25) }}
        />
      )}
      {jobGeolocation && (
        <Marker
          position={jobGeolocation}
          icon={{ url: mapMarkerGreen, scaledSize: new google.maps.Size(25, 25) }}
        />
      )}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};
