/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggregatedJob, MiterAPI, AggregatedEquipmentLog } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { BasicModal } from "ui";
import { MiterFilterField } from "backend/utils/utils";
import ErrorCard from "../error/errorCard";
import EquipmentLogModal from "./EquipmentLogModal";
import { Pencil, Plus, TrashSimple } from "phosphor-react";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type Props = {
  activeJob: AggregatedJob;
  activeStartDate: DateTime;
  activeEndDate: DateTime;
  onRowSelect: (log: AggregatedEquipmentLog[]) => void;
  defaultSelectedRows?: AggregatedEquipmentLog[];
  version: "daily_report_form";
};

const EquipmentLogsTable: React.FC<Props> = ({
  activeJob,
  activeStartDate,
  onRowSelect,
  defaultSelectedRows,
  version,
}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<AggregatedEquipmentLog[]>([]);
  const [selectedRows, setSelectedRows] = useState<AggregatedEquipmentLog[]>(defaultSelectedRows || []);
  const [error, setError] = useState<string>();
  const [adding, setAdding] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [selectedEquipmentLog, setSelectedEquipmentLog] = useState<AggregatedEquipmentLog>();

  const updating = !!selectedEquipmentLog;

  /*********************************************************
   *  Call useEffect hooks
   **********************************************************/
  useEffect(() => {
    getEquipmentLogs();
  }, []);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleRowClick = (log: AggregatedEquipmentLog) => {
    handleEdit(log);
  };

  const handleAdd = () => {
    setAdding(true);
  };

  const handleEdit = (log: AggregatedEquipmentLog) => {
    setSelectedEquipmentLog(log);
  };

  const handleSelect = (selections: AggregatedEquipmentLog[]) => {
    setSelectedRows(selections);

    const selectionIds = selections.map((selection) => selection._id);
    if (onRowSelect) onRowSelect(tableData.filter((row) => selectionIds.includes(row._id)) || []);
  };

  const handleArchive = async () => {
    setLoading(true);
    try {
      for (const equipmentLog of selectedRows) {
        const response = await MiterAPI.equipment_logs.delete(equipmentLog._id, { aggregated: false });
        if (response.error) throw new Error(response.error);
      }

      const singPlur = selectedRows.length > 1 ? "Equipment logs" : "Equipment log";
      Notifier.success(singPlur + " successfully deleted.");

      setArchiving(false);
      setSelectedRows([]);
      getEquipmentLogs();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting one or more equipment logs. We're looking into it.");
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setAdding(false);
    setArchiving(false);
    setSelectedEquipmentLog(undefined);
  };

  const handleModalSubmit = () => {
    getEquipmentLogs();
    setAdding(false);
    setArchiving(false);
    setSelectedEquipmentLog(undefined);
  };

  /*********************************************************
   *  Data fetching functions
   **********************************************************/
  const buildTableRow = (equipmentLog: AggregatedEquipmentLog) => {
    return {
      ...equipmentLog,
      equipment_name: equipmentLog.equipment?.name,
      date_formatted: DateTime.fromISO(equipmentLog.date).toFormat("DD"),
      status_formatted: capitalize(equipmentLog.status.replaceAll("_", " ")),
      code_formatted: equipmentLog.equipment?.code || "-",
    };
  };

  const getEquipmentLogs = async () => {
    if (!tableData) setLoading(true);

    const filterArray: MiterFilterField[] = [
      {
        field: "company_id",
        value: activeCompanyId,
      },
      {
        field: "job_id",
        value: activeJob?._id.toString(),
      },
      {
        field: "date",
        type: "string",
        comparisonType: ">=",
        value: activeStartDate?.toISODate(),
      },
    ];

    try {
      const equipmentLogs = await MiterAPI.equipment_logs.search(filterArray, { aggregated: true });
      if (equipmentLogs.error) throw equipmentLogs.error;

      const rows = equipmentLogs.map((equipment) => buildTableRow(equipment));
      setTableData(rows);
    } catch (e) {
      console.error("Error getting equipment logs", e);
      setError(
        "We can't retrieve equipment logs information right now. Contact support@miter.com if the problem persists."
      );
    }
    setLoading(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = [
    {
      label: "Add log",
      className: "button-2 no-margin table-button",
      action: handleAdd,
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const dynamicActions: TableActionLink[] = [
    ...(version === "daily_report_form"
      ? []
      : [
          ...(selectedRows.length === 1 && selectedRows[0]
            ? [
                {
                  label: "Update",
                  className: "button-2 no-margin table-button",
                  action: () => handleEdit(selectedRows[0]!),
                  icon: <Pencil weight="bold" style={{ marginRight: 3 }} />,
                },
              ]
            : []),
          {
            label: "Delete",
            className: "button-3 no-margin table-button",
            action: () => setArchiving(true),
            icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
          },
        ]),
  ];

  // Dynamically set columns based on whether any equipmentLogs have work classifications
  const columns: ColumnConfig<AggregatedEquipmentLog>[] = [
    {
      headerName: "Equipment",
      field: "equipment_name",
      dataType: "string",
    },
    {
      headerName: "Code",
      field: "code_formatted",
      dataType: "string",
      maxWidth: 120,
    },
    {
      headerName: "Date",
      field: "date_formatted",
      dataType: "string",
    },
    {
      headerName: "Qty",
      field: "quantity",
      dataType: "number",
      maxWidth: 100,
    },
    {
      headerName: "Hours",
      field: "hours",
      dataType: "number",
      maxWidth: 100,
    },
    {
      headerName: "Status",
      field: "status_formatted",
      dataType: "string",
      displayType: "badge",
      colors: {
        "in use": "green",
        idle: "grey",
        "out of order": "yellow",
        removed: "red",
      },
    },
  ];

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderTable = () => {
    return (
      <TableV2
        id={"equipment-logs-table"}
        resource="equipment logs"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={handleSelect}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
      />
    );
  };

  return (
    <div className="equipment-logs-table-wrapper">
      {error && <ErrorCard errorMessage={error || ""} />}
      {!error && (
        <div>
          {adding && (
            <EquipmentLogModal
              onCancel={handleModalClose}
              onSubmit={handleModalSubmit}
              defaultDate={activeStartDate}
              defaultJob={activeJob}
            />
          )}
          {archiving && (
            <BasicModal
              loading={loading}
              button2Text="Delete"
              button1Action={() => setArchiving(false)}
              button1Text="Cancel"
              button2Action={handleArchive}
              headerText={"Delete equipment"}
              bodyText={
                "Are you sure want to delete the selected equipment? To restore deleted equipment, you must contact Miter Support."
              }
            />
          )}
          {updating && (
            <EquipmentLogModal
              onCancel={handleModalClose}
              onSubmit={handleModalSubmit}
              equipmentLogID={selectedEquipmentLog._id}
              defaultDate={activeStartDate}
              defaultJob={activeJob}
            />
          )}

          {tableData && columns && renderTable()}
        </div>
      )}
    </div>
  );
};

export default EquipmentLogsTable;
