/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDataHookRecord } from "@flatfile/adapter/build/main/interfaces";
import { FieldHookCallback } from "@flatfile/react";
import { round } from "lodash";
import { DateTime } from "luxon";
import { cleanCustomFieldValueParams, notNullish, replaceAllPolyfill } from "miter-utils";
import { CustomField } from "dashboard/miter";
import { ImportField } from "../components/importer/Importer";
import { buildCustomFieldColumnKey } from "./custom-fields";
import { PartialCustomFieldValue } from "backend/services/custom-field-value-service";

// Conver the imported flatfile string into a date using Luxon
export type NormalizedFlatfileDate = {
  value?: string;
  info: { message: string; level: "error" | "info" }[];
};

type NormalizedFlatfileCurrency =
  | {
      value?: number;
      info: { message: string; level: "error" | "info" }[];
    }
  | undefined;

type FlatfileFiledHookRes = [NormalizedFlatfileDate | NormalizedFlatfileCurrency, string | number];

export const normalizeDate = (date: string): NormalizedFlatfileDate => {
  if (!date) return { value: date, info: [] };

  // Replace slashes with commas for and remove ordinals (th, nd, rd) for Luxon
  const formattedDate = replaceAllPolyfill(date, "/", "-").replace(/(\d+)(st|nd|rd|th)/, "$1");

  // Initialize parsed date
  let parsedDate: DateTime;

  // Try MM-DD-YYYY format
  parsedDate = DateTime.fromFormat(formattedDate, "MM-dd-yyyy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try MM/DD/YYYY format
  parsedDate = DateTime.fromFormat(formattedDate, "MM/dd/yyyy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try M/DD/YYYY format
  parsedDate = DateTime.fromFormat(formattedDate, "M/dd/yyyy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try M/D/YYYY format
  parsedDate = DateTime.fromFormat(formattedDate, "M/d/yyyy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try M-D-YYYY format
  parsedDate = DateTime.fromFormat(formattedDate, "M-d-yyyy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try M-D-YY format
  parsedDate = DateTime.fromFormat(formattedDate, "M-d-yy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try M/D/YY format
  parsedDate = DateTime.fromFormat(formattedDate, "M/d/yy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try MM/DD/YY format
  parsedDate = DateTime.fromFormat(formattedDate, "MM/dd/yy");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try YYYY-MM-DD format
  parsedDate = DateTime.fromFormat(formattedDate, "yyyy-MM-dd");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try May 25, 1982
  parsedDate = DateTime.fromFormat(formattedDate, "DDD");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  // Try Aug 6, 2014
  parsedDate = DateTime.fromFormat(formattedDate, "DD");
  if (parsedDate.isValid) {
    return {
      value: parsedDate.toISODate(),
      info: [{ message: "All dates are automatically formatted to YYYY-MM-DD.", level: "info" }],
    };
  }

  return {
    info: [
      {
        message: "We weren't able to parse this date. Please format your date as: YYYY-MM-DD",
        level: "error",
      },
    ],
  };
};

export const normalizeCurrency = (currency: string): NormalizedFlatfileCurrency => {
  if (!currency) return;

  // Remove commas and dollar sign
  const formattedCurrency = currency.replaceAll(",", "").replaceAll("$", "");

  // Try to parse as a number
  const parsedCurrency = parseFloat(formattedCurrency);
  if (!isNaN(parsedCurrency)) {
    return {
      value: round(parsedCurrency, 2),
      info: [{ message: "All currencies are automatically formatted to numbers.", level: "info" }],
    };
  }

  return {
    info: [
      {
        message: "We weren't able to parse this currency. Please format your currency as: 1234.56",
        level: "error",
      },
    ],
  };
};

export const bulkNormalizeDates = (dates: any[][]): FlatfileFiledHookRes[] => {
  return dates.map((date) => [normalizeDate(date[0]), date[1]]);
};

export const bulkNormalizeCurrency = (currencies: any[][]): FlatfileFiledHookRes[] => {
  return currencies.map((currency) => [normalizeCurrency(currency[0]), currency[1]]);
};

/** Validate a flatfile number */
export const validateFlatfileNumber = (
  value: any,
  opts?: { min?: number; max?: number }
): IDataHookRecord | undefined => {
  if (value === undefined || value === null || value === "") return { value };

  if (isNaN(value)) {
    return buildFlatfileMessage("This value must be a number.", value, "error");
  }

  if (opts?.min != undefined && value < opts.min) {
    return buildFlatfileMessage(`This value must be greater than or equal to ${opts.min}.`, value, "error");
  }

  if (opts?.max != undefined && value > opts.max) {
    return buildFlatfileMessage(`This value must be less than or equal to ${opts.max}.`, value, "error");
  }

  return { value };
};

/** Build an error/info/warning message for a flatfile row */
export const buildFlatfileMessage = (
  message: string,
  value: any,
  level: "error" | "info" | "warning"
): IDataHookRecord => {
  return { value, info: [{ message, level }] };
};

/** Bulk run a validation on values */
export const bulkFlatfileValidate = (
  values: $TSFixMe,
  validationFunc: (value: any) => IDataHookRecord | Promise<IDataHookRecord> | undefined
): FieldHookCallback => {
  if (!values) return [] as $TSFixMe;
  return values.map((value) => {
    return [validationFunc(value[0]), value[1]];
  });
};

/** Build the array of custom field values for a flatfile row
 *
 * @param row - The flatfile row
 * @param customFields - The custom fields to build the values for
 * @returns The array of objects with custom field values and their ids
 */
export const buildCustomFieldValues = (
  row: Record<string, any>,
  customFields: CustomField[]
): PartialCustomFieldValue[] => {
  const customFieldValues = customFields
    .map((cf) => {
      const key = buildCustomFieldColumnKey(cf);
      const value = row[key];

      if (value === "") return;

      return {
        custom_field_id: cf._id,
        value: cleanCustomFieldValueParams(value),
      };
    })
    .filter(notNullish);

  return customFieldValues;
};

/** This function is used to validate the date and ensure it is within the
 * min and max date and returns the normalized date
 */
export const getValidatedCustomFieldDate = (
  cf: CustomField,
  normalizedDate: NormalizedFlatfileDate
): NormalizedFlatfileDate => {
  const parsedDate = normalizedDate.value ? DateTime.fromISO(normalizedDate.value) : null;
  if (!parsedDate) return normalizedDate;

  if (cf.validations?.min_date) {
    const minDate = DateTime.fromISO(cf.validations.min_date);
    if (parsedDate < minDate) {
      return {
        info: [
          {
            message: `This date must be greater than or equal to ${minDate.toISODate()}.`,
            level: "error",
          },
        ],
      };
    }
  }

  if (cf.validations?.max_date) {
    const maxDate = DateTime.fromISO(cf.validations.max_date);
    if (parsedDate > maxDate) {
      return {
        info: [
          {
            message: `This date must be less than or equal to ${maxDate.toISODate()}.`,
            level: "error",
          },
        ],
      };
    }
  }

  return normalizedDate;
};

/** Build the array of custom field columns for a flatfile row */
export const buildCustomFieldColumns = (customFields: CustomField[]): ImportField[] => {
  return customFields
    .map((cf) => {
      const isRequired = cf.validations?.required;

      const baseFields = {
        label: cf.name,
        key: buildCustomFieldColumnKey(cf),
        description: cf.description,
        validators: isRequired ? [{ validate: "required" as const }] : undefined,
      };

      if (cf.type === "text" || cf.type === "paragraph") {
        return {
          ...baseFields,
          type: "string" as const,
        };
      }

      if (cf.type === "select") {
        return {
          ...baseFields,
          type: "select" as const,
          options: cf.options_list?.map((option) => ({ label: option.value, value: option.value })) || [],
        };
      }

      if (cf.type === "number" || cf.type === "quantity") {
        return {
          ...baseFields,
          type: "string" as const,
          hook: (val) =>
            typeof val === "string"
              ? validateFlatfileNumber(val, { ...cf.validations })
              : validateFlatfileNumber(val?.[baseFields.key], { ...cf.validations }),
        };
      }

      if (cf.type === "date") {
        return {
          ...baseFields,
          type: "string" as const,
          hook: (val) => {
            const normalizedDate =
              typeof val === "string" ? normalizeDate(val) : normalizeDate(val?.[baseFields.key]);
            return getValidatedCustomFieldDate(cf, normalizedDate);
          },
        };
      }

      if (cf.type === "checkbox") {
        return {
          ...baseFields,
          type: "select" as const,
          options: [
            { label: "True", value: "true" },
            { label: "False", value: "false" },
          ],
        };
      }
    })
    .filter(notNullish);
};

export const validateRoutingNum = (aba: string | null | undefined) => {
  if (!aba) return { value: "" };

  aba = aba.toString().trim().padStart(9, "0");

  const regexMatch = aba.match(/^\d{9}$/);
  if (!regexMatch) {
    return buildFlatfileMessage("Please enter a number with 9 digits.", aba, "error");
  }

  // https://en.wikipedia.org/wiki/ABA_routing_transit_number#MICR_routing_number_format
  const f2 = Number(aba.slice(0, 2));
  const validF2 = (0 <= f2 && f2 <= 12) || (21 <= f2 && f2 <= 32) || (61 <= f2 && f2 <= 72) || f2 === 80;
  if (!validF2) {
    return buildFlatfileMessage("Please enter a valid ABA routing number.", aba, "error");
  }

  // https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit
  const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1];
  let tot = 0;
  for (let i = 0; i < 9; i++) {
    tot += Number(aba[i]) * weights[i]!;
  }

  if (tot % 10 !== 0) {
    return buildFlatfileMessage("Please enter a valid ABA routing number.", aba, "error");
  }

  return { value: aba };
};
