import {
  useLookupActivity,
  useActivityOptions,
  useJobOptions,
  useTeamOptions,
  useLookupTeam,
  useActiveCompany,
  useActiveCompanyId,
  useCostTypeOptions,
} from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, MiterAPI, Timesheet, AggregatedJob } from "dashboard/miter";
import { checkTmAndActivityLicenseMatch, Notifier, SomeRequiredRestOptional } from "dashboard/utils";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Select, { ActionMeta, ValueType } from "react-select";
import { Popover } from "react-tiny-popover";
import { Button, ConfirmModal, DateTimeInput, LargeModal, SelectOption } from "ui";
import { Option } from "ui/form/Input";
import styles from "./BulkCreateTimesheets.module.css";
import { BulkCreateTimesheetsColumns } from "./BulkCreateTimesheetsColumns";
import { BulkCreateTimesheetsRow } from "./BulkCreateTimesheetsRow";
import { bulkEditTimesheetSelectStyles } from "./SelectStyles";
import { isTimesheetScoped as isActivityTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { useTimesheetPolicies } from "dashboard/utils/policies/timesheet-policy-utils";
import { TimesheetPolicyField } from "backend/models/policy";
import { usePreppedTimesheetPayRates } from "dashboard/usePreppedTimesheetPayRates";
import { PayRateItem } from "backend/utils/payroll/types";
import ObjectID from "bson-objectid";
import { useDebouncedCallback } from "use-debounce";
import { TimesheetEarningType } from "backend/models/timesheet";
import { getEarningTypeParamsFromAlias } from "../TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { isCostTypeTimesheetScoped } from "dashboard/components/cost-types/costTypeUtils";

type Props = {
  onFinish: (tsInputData: Timesheet[]) => void;
  activeTeamMember?: AggregatedTeamMember;
  activeJob?: AggregatedJob;
  activeDate?: DateTime;
};

export type PreppedBulkEntryTimesheet = Pick<
  Timesheet,
  | "team_member"
  | "company"
  | "clock_in"
  | "clock_out"
  | "unpaid_break_time"
  | "job"
  | "activity"
  | "notes"
  | "rate_differential_id"
  | "earning_type"
  | "classification_override"
  | "standard_classification_id"
  | "hours"
> &
  Required<Pick<Timesheet, "creation_method">>;

export type DraftTimesheet = {
  _id?: string;
  date?: DateTime;
  clock_in_time?: string;
  clock_out_time?: string;
  hours?: number;
  break_minutes?: number;
  team_member?: string;
  job?: string;
  activity?: string | null;
  classification_override?: string | null;
  department_id?: string;
  cost_type_id?: string;
  notes?: string;
  editing?: boolean;
  selected?: boolean;
  injury?: boolean;
  clock_in_photo?: string;
  clock_out_photo?: string;
  wc_code?: string;
  rate_differential_id?: string | null;
  standard_classification_id?: string;
  earning_type?: TimesheetEarningType | null;
  pay_rate_item?: PayRateItem;
  equipment_ids?: string[];
};

type ValidatedDraftTimesheet = SomeRequiredRestOptional<
  DraftTimesheet,
  "date" | "clock_in_time" | "clock_out_time" | "hours" | "team_member"
>;

export const BulkCreateTimesheets: React.FC<Props> = ({
  onFinish,
  activeTeamMember,
  activeJob,
  activeDate,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const [saving, setSaving] = useState(false);
  const lookupTeam = useLookupTeam();

  const { buildPolicy } = useTimesheetPolicies();
  const timesheetAbilities = useTimesheetAbilities();
  const teamOptions = useTeamOptions({ predicate: timesheetAbilities.teamPredicate("create") });

  const [draftTimesheets, setDraftTimesheets] = useState<DraftTimesheet[]>([]);
  const [draftsAreValid, setDraftsAreValid] = useState(false);
  const [clockOutNotHours, setClockOutNotHours] = useState(false);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [exitConfirmation, setExitConfirmation] = useState(false);

  const [bulkJob, setBulkJob] = useState<string | undefined>();
  const jobOptions = useJobOptions({ predicate: timesheetAbilities.jobPredicate("create") });
  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeTimesheetScoped,
  });

  const [payRatesMap, setPayRatesMap] = useState<Record<string, PayRateItem>>({});

  const { buildPayRatesMap } = usePreppedTimesheetPayRates();
  const lookupActivity = useLookupActivity();
  const activityOptions = useActivityOptions(bulkJob, { predicate: isActivityTimesheetScoped });
  const showPayRate = activeCompany?.settings?.timesheets?.show_pay_rate_in_bulk_create;

  const defaultClockInTime = activeCompany?.settings.timesheets.default_clock_in_time || "09:00";
  const defaultClockOutTime = defaultClockInTime
    .split(":")
    .map((v, i) => (i === 0 ? String((Number(v) + 8) % 24) : v))
    .join(":");

  /****************************************************************
   * Timesheet helper variables
   ****************************************************************/

  const allSelected = draftTimesheets.every((d) => d.selected);
  const anySelected = draftTimesheets.some((d) => d.selected);
  const anyHaveData = draftTimesheets.some((ts) => ts.team_member || ts.job || ts.activity);

  /****************************************************************
   * API helper functions
   ****************************************************************/
  const prepDraftTimesheets = (drafts: DraftTimesheet[]): PreppedBulkEntryTimesheet[] => {
    // @ts-expect-error TS doesn't understand .filter!
    const validatedTimesheets: ValidatedDraftTimesheet[] = drafts.filter(
      (ts) => ts.team_member && ts.date && ts.hours && ts.clock_in_time && ts.clock_out_time
    );

    return validatedTimesheets.map((ts) => {
      const clockInTime = ts.clock_in_time;
      const hour = Number(clockInTime.split(":")[0]);
      const minute = Number(clockInTime.split(":")[1]);
      const clockInDt = DateTime.fromObject({
        ...ts.date.toObject(),
        hour,
        minute,
        second: 0,
        millisecond: 0,
      });

      let clockOutDt: DateTime;
      // Final clock in/out should match what they input, and because of rounding, that means we need to send to the backend exactly what they inputted
      if (clockOutNotHours) {
        const clockOutTimeString = ts.clock_out_time;
        const clockOutHour = Number(clockOutTimeString.split(":")[0]);
        const clockOutMinute = Number(clockOutTimeString.split(":")[1]);

        clockOutDt = DateTime.fromObject({
          ...ts.date.toObject(),
          hour: clockOutHour,
          minute: clockOutMinute,
          second: 0,
          millisecond: 0,
        });
        // Since we're only working with a single ts.date, if the clock-out is before the clock-in, let's assume that it's because they've entered a timesheet that crosses midnight, so we have to advance clock-out by a full day
        if (clockOutDt < clockInDt) {
          clockOutDt = clockOutDt.plus({ days: 1 });
        }
      } else {
        clockOutDt = clockInDt.plus({ hours: ts.hours });
      }

      return {
        ...ts,
        job: ts.job || undefined,
        activity: ts.activity || undefined,
        creation_method: "dashboard",
        company: activeCompanyId!,
        clock_in: clockInDt.toSeconds(),
        clock_out: clockOutDt.toSeconds(),
        unpaid_break_time: (ts.break_minutes || 0) * 60,
        ...(ts.earning_type ? { ...getEarningTypeParamsFromAlias(ts.earning_type) } : {}),
      };
    });
  };

  /****************************************************************
   * API Calls
   ****************************************************************/
  const updatePayRates = async () => {
    if (!showPayRate) return;

    const newPayRatesMap = await buildPayRatesMap(prepDraftTimesheets(draftTimesheets));
    setPayRatesMap(newPayRatesMap || {});
  };

  const debouncedUpdatePayRates = useDebouncedCallback(updatePayRates, 1000);

  const save = async () => {
    const hoursByTm = draftTimesheets.reduce((map, ts) => {
      const key = (ts.team_member || "unknown") + ts.date?.toISODate();
      return map.set(key, (map.get(key) || 0) + (ts.hours || 0));
    }, new Map<string | undefined, number>());
    const anyErrors = draftTimesheets.some((ts) => !ts.team_member || !ts.date || !ts.hours || ts.hours < 0);
    if (anyErrors || [...hoursByTm.values()].some((v) => v > 24)) {
      Notifier.error("Please fix input errors before submitting");
      return;
    }
    setSaving(true);
    try {
      const preppedTimesheets = prepDraftTimesheets(draftTimesheets);
      const response = await MiterAPI.timesheets.create_many({ timesheets: preppedTimesheets });
      if (response.error) throw new Error(response.error);

      const successes = response.createdTimesheets.length;
      if (successes)
        Notifier.success(`Successfully created ${successes} timesheet` + (successes === 1 ? "" : "s"));

      const fails = response.errors.length;
      if (fails) {
        console.log("Failed to create timesheets", response.errors);
        Notifier.error(`Failed to create ${fails} timesheet` + (fails === 1 ? "" : "s"));
      }

      onFinish(response.createdTimesheets);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the timesheets.");
    }
    setSaving(false);
  };

  /****************************************************************
   * Helper functions
   ****************************************************************/

  const buildDefaultRow = () => {
    return {
      _id: ObjectID().toHexString(),
      ...(activeTeamMember ? { team_member: activeTeamMember._id.toString() } : {}),
      ...(activeJob ? { job: activeJob._id.toString() } : {}),
      hours: 8,
      date: activeDate || DateTime.now().startOf("day"),
      clock_in_time: defaultClockInTime,
      clock_out_time: defaultClockOutTime,
      editing: false,
      selected: false,
    };
  };

  const toggleShowBulkEdit = () => {
    if (!showBulkEdit) {
      setShowBulkEdit(true);
    } else {
      setBulkJob(undefined);
      setShowBulkEdit(false);
    }
  };

  const validateSingleDraftTimesheet = (d: DraftTimesheet) => {
    const policy = buildPolicy(d);
    const requiredFields = ["date", "team_member", "hours"];
    const configurableFields: TimesheetPolicyField[] = [
      "job",
      "activity",
      "classification_override",
      "notes",
    ];

    for (const field of requiredFields) {
      if (!d[field]) {
        return false;
      }
    }

    for (const field of configurableFields) {
      if (policy.isFieldRequired(field) && !d[field]) {
        return false;
      }
    }

    return true;
  };

  const validateDraftTimesheets = () => {
    for (const d of draftTimesheets) {
      if (!validateSingleDraftTimesheet(d)) {
        return false;
      }
    }

    return true;
  };

  /****************************************************************
   * Selection handlers
   ****************************************************************/

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setDraftTimesheets(_.cloneDeep(draftTimesheets.map((d) => ({ ...d, selected: true }))));
    } else {
      setDraftTimesheets(_.cloneDeep(draftTimesheets.map((d) => ({ ...d, selected: false }))));
    }
  };

  const handleClearSelection = () => {
    setDraftTimesheets(_.cloneDeep(draftTimesheets.map((d) => ({ ...d, selected: false }))));
  };

  const handleDuplicateSelected = () => {
    const currentRows = _.cloneDeep(draftTimesheets);
    const selectedRows = currentRows.filter((d) => d.selected);
    const newRows = selectedRows.map((d) => ({ ...d, selected: false, _id: ObjectID().toHexString() }));

    setDraftTimesheets(currentRows.concat(newRows));
  };

  const handleDeleteSelected = () => {
    const currentRows = _.cloneDeep(draftTimesheets);
    const newRows = currentRows.filter((d) => !d.selected);
    setDraftTimesheets(newRows);
  };

  const handleAddNewRow = () => {
    const currentRows = [...draftTimesheets];
    const newRow = buildDefaultRow();
    setDraftTimesheets(currentRows.concat([newRow]));
  };

  /****************************************************************
   * Bulk input field handlers
   ****************************************************************/

  const handleBulkSelectChange = (
    option: Option<string | null | undefined> | null | undefined,
    element: ActionMeta<Option<string | null | undefined>>
  ): void => {
    const value = option?.value || undefined;
    const currentRows = _.cloneDeep(draftTimesheets);
    const newRows = currentRows.map((d) => {
      if (d.selected) {
        if (element.name === "job") {
          setBulkJob(value);
        } else if (element.name === "activity") {
          const selectedActivity = lookupActivity(value);
          const selectedTm = lookupTeam(d.team_member);
          checkTmAndActivityLicenseMatch(selectedActivity, selectedTm);
        } else if (element.name === "team_member") {
          const selectedActivity = lookupActivity(d.activity);
          const selectedTm = lookupTeam(value);
          checkTmAndActivityLicenseMatch(selectedActivity, selectedTm);
        }

        return {
          ...d,
          editing: true,
          [element.name!]: value,

          // Remove the activity if job is changed
          ...(element.name === "job" ? { activity: undefined } : {}),
        };
      } else {
        return d;
      }
    });
    setDraftTimesheets(newRows);
  };

  const handleBulkTextChange = (e) => {
    const currentRows = _.cloneDeep(draftTimesheets);
    const newRows = currentRows.map((d) => {
      if (d.selected) {
        return { ...d, [e.target.name]: e.target.value, editing: true };
      } else {
        return d;
      }
    });
    setDraftTimesheets(newRows);
  };

  const handleBulkDateChange = (dt) => {
    const currentRows = _.cloneDeep(draftTimesheets);
    const newRows = currentRows.map((d) => {
      if (d.selected) {
        return { ...d, date: dt, editing: true };
      } else {
        return d;
      }
    });
    setDraftTimesheets(newRows);
  };

  /****************************************************************
   * Render functions
   ****************************************************************/

  const renderBulkEditFields = () => {
    return (
      <div className={styles["bulk-edit-fields-container"]}>
        <div className={styles["team-member-bulk-edit-container"]}>
          <Select
            key={"bulk-team-member-selector"}
            name="team_member"
            options={teamOptions}
            width="100%"
            height="32px"
            onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
              handleBulkSelectChange(value, actionMeta)
            }
            styles={bulkEditTimesheetSelectStyles}
            isClearable={true}
            menuPlacement={"bottom"}
            placeholder={"Bulk update the team member"}
          />
        </div>
        <div className={styles["job-bulk-edit-container"]}>
          <Select
            key={"bulk-job-selector"}
            name="job"
            options={jobOptions}
            width="100%"
            height="32px"
            onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
              handleBulkSelectChange(value, actionMeta)
            }
            styles={bulkEditTimesheetSelectStyles}
            isClearable={true}
            menuPlacement={"bottom"}
            placeholder={"Select the job"}
          />
        </div>
        <div className={styles["activity-bulk-edit-container"]}>
          <Select
            key={"bulk-activity-selector"}
            name="activity"
            options={activityOptions}
            width="100%"
            height="32px"
            onChange={(value: ValueType<SelectOption, false>, actionMeta) =>
              handleBulkSelectChange(value, actionMeta)
            }
            styles={bulkEditTimesheetSelectStyles}
            isClearable={true}
            menuPlacement={"bottom"}
            placeholder={"Select the activity"}
          />
        </div>
        <div className={styles["clock-in-time-bulk-edit-container"]}>
          <input
            className="form2-text"
            name="clock_in_time"
            type="time"
            onChange={handleBulkTextChange}
            placeholder={"Clock in time"}
          />
        </div>
        <div className={styles["hours-bulk-edit-container"]}>
          <input
            className="form2-text"
            name="hours"
            type="number"
            onChange={handleBulkTextChange}
            placeholder={"Hours"}
            min={0}
            max={24}
          />
        </div>
        <div className={styles["date-bulk-edit-container"]}>
          <DateTimeInput onChange={handleBulkDateChange} placeholder={"Select a date"} />
        </div>
      </div>
    );
  };

  const renderSelectedButtons = () => {
    return (
      <div className={styles["selected-buttons"]}>
        <div className={styles["bulk-edit-buttons"]}>
          <Popover
            isOpen={showBulkEdit}
            positions={["bottom"]}
            containerStyle={{ zIndex: "5" }}
            align="start"
            content={renderBulkEditFields()}
          >
            <button className={styles["bulk-edit-button"] + " button-2"} onClick={toggleShowBulkEdit}>
              {!showBulkEdit ? "Bulk edit" : "Finish"}
            </button>
          </Popover>
        </div>
        <div className={styles["bulk-edit-buttons"]}>
          <Button onClick={handleClearSelection} className="button-1" text="Clear selection" />
          <Button text="Duplicate rows" onClick={handleDuplicateSelected} className="button-2 " />
          <Button text="Delete rows" onClick={handleDeleteSelected} className="button-3 " />
        </div>
      </div>
    );
  };

  const renderNotSelectedButtons = () => {
    return (
      <div className={styles["no-select-buttons"]}>
        <Button
          text="+ Add Row"
          onClick={handleAddNewRow}
          className={"button-1 " + styles["add-row-button"]}
        />
        <Button
          text="Create timesheets"
          onClick={save}
          className={"button-2 no-margin " + styles["create-timesheets-button"]}
          loading={saving}
          disabled={draftTimesheets.length === 0 || !draftsAreValid}
        />
      </div>
    );
  };

  const renderHeaderButtons = () => {
    if (anySelected) {
      return renderSelectedButtons();
    } else {
      return renderNotSelectedButtons();
    }
  };

  const renderTimesheetRows = () => {
    return draftTimesheets.map((draft, index) => (
      <BulkCreateTimesheetsRow
        key={index}
        index={index}
        draftTimesheets={draftTimesheets}
        setDraftTimesheets={setDraftTimesheets}
        clockOutNotHours={clockOutNotHours}
        defaultClockInTime={defaultClockInTime}
        defaultClockOutTime={defaultClockOutTime}
        teamOptions={teamOptions}
        jobOptions={jobOptions}
        costTypeOptions={costTypeOptions}
        payRatesMap={payRatesMap}
      />
    ));
  };

  const renderTable = () => {
    return (
      <table className={styles["table-wrapper"]} cellSpacing="0">
        <thead className={styles["table-header-timesheets"]}>
          <BulkCreateTimesheetsColumns
            handleSelectAll={handleSelectAll}
            allSelected={allSelected}
            clockOutNotHours={clockOutNotHours}
            setClockOutNotHours={setClockOutNotHours}
          />
        </thead>
        <tbody className={styles["table-body"]}>{renderTimesheetRows()}</tbody>
      </table>
    );
  };

  /****************************************************************
   * useEffect functions
   ****************************************************************/

  useEffect(() => {
    handleAddNewRow();
  }, []);

  useEffect(() => {
    debouncedUpdatePayRates();

    const valid = validateDraftTimesheets();
    setDraftsAreValid(valid);
  }, [JSON.stringify(draftTimesheets), debouncedUpdatePayRates]);

  return (
    <LargeModal
      headerText="Create timesheets"
      onClose={() => (anyHaveData ? setExitConfirmation(true) : onFinish([]))}
    >
      <div className="vertical-spacer"></div>
      <div className="flex">{renderHeaderButtons()}</div>
      <div className="vertical-spacer"></div>
      {renderTable()}
      <div className="vertical-spacer-small"></div>
      {exitConfirmation && (
        <ConfirmModal
          title={"Are you sure?"}
          body={"You will lose all of the data you've entered."}
          yellowBodyText
          onYes={() => onFinish([])}
          onNo={() => setExitConfirmation(false)}
        />
      )}
    </LargeModal>
  );
};
