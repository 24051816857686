import React, { useEffect, useState } from "react";
import { Wizard } from "ui";
import { CreateJobPostingStep } from "./CreateJobPostingStep";
import { AggregatedJobPosting } from "dashboard/types/ats";
import { FormBuilderScreen } from "../forms/FormBuilderScreen";
import { Form } from "dashboard/miter";
import { useRefetchJobPostings } from "dashboard/hooks/atom-hooks";

type Props = {
  onExit: () => void;
  jobPosting?: AggregatedJobPosting;
};

const CreateJobPosting: React.FC<Props> = ({ onExit, jobPosting }) => {
  const [updatedJobPosting, setJobPosting] = useState<AggregatedJobPosting | undefined>(jobPosting);
  const [form, setForm] = useState<Form | undefined>(jobPosting?.question_form);
  const refetchJobPosting = useRefetchJobPostings();

  useEffect(() => {
    if (updatedJobPosting?.question_form) {
      setForm(updatedJobPosting?.question_form);
    }
  }, [updatedJobPosting]);

  const refetchAlongComplete = async () => {
    refetchJobPosting(updatedJobPosting?._id);
    onExit();
  };

  return (
    <Wizard onExit={onExit} onComplete={refetchAlongComplete}>
      <CreateJobPostingStep
        setJobPosting={setJobPosting}
        jobPosting={updatedJobPosting}
        name={"Create a job posting"}
      />

      <FormBuilderScreen
        name={"Application form"}
        formItem={form}
        setFormItem={setForm}
        parentType={"job_posting"}
        parentId={jobPosting?._id}
      />
    </Wizard>
  );
};

export default CreateJobPosting;
