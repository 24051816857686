import React, { useEffect, useState } from "react";
import { Loader, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { MiterAPI, TeamMemberOnboardingChecklist, TeamMemberOnboardingTask } from "dashboard/miter";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import useWizard from "ui/modal/useWizard";
import { WizardTeamMember } from "./TeamMemberWizard";
import { EE_ONBOARDING_TASK_CONFIG, TeamMemberChecklistBuilder } from "./TeamMemberChecklistBuilder";
import { ChecklistTaskItem } from "dashboard/utils/checklist-utils";
import ObjectID from "bson-objectid";
import { TeamMemberOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
  type: "new_hire" | "admin";
};

export const TeamMemberChecklistBuilderScreen: React.FC<Props> = ({ name, teamMember, type }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const { setCanNext, setNextButtonText, handleComplete, screens } = useWizard();

  const [checklist, setChecklist] = useState<Partial<TeamMemberOnboardingChecklist> | undefined>();

  const [newHireTasks, setNewHireTasks] = useState<ChecklistTaskItem<TeamMemberOnboardingTaskForm>[]>([]);
  const [adminTasks, setAdminTasks] = useState<ChecklistTaskItem<TeamMemberOnboardingTaskForm>[]>([]);

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }

    setCanNext(!saving && !loading);
  }, [saving, loading]);

  const onNext = async () => {
    await upsertChecklist();

    if (screens.length === 1) {
      handleComplete();
    }
  };

  /**
   * Validates the provided tasks to ensure they are ready for saving.
   * Updates the task errors state and throws an error if any task is invalid.
   *
   * @param tasks - The array of checklist tasks to be validated.
   */
  const validateTasksForSaving = (): void => {
    const tasks = type === "admin" ? adminTasks : newHireTasks;
    const allErrors: { [key: string]: Record<string, string> } = {};

    tasks.forEach((task) => {
      const taskType = task.type as keyof typeof EE_ONBOARDING_TASK_CONFIG;
      const { validator } = EE_ONBOARDING_TASK_CONFIG[taskType];

      const errors = validator ? validator(task as $TSFixMe) : {};
      allErrors[task._id] = errors;
    });

    // Update tasks with errors
    const updatedTasks = tasks.map((task) => ({ ...task, errors: allErrors[task._id] }));
    if (type === "admin") {
      setAdminTasks(updatedTasks);
    } else {
      setNewHireTasks(updatedTasks);
    }

    const hasErrors = Object.values(allErrors).some((errors) => Object.keys(errors).length > 0);
    if (hasErrors) throw new Error("Please fix the errors in the tasks before saving");
  };

  /**
   * Prepares the tasks for saving in the backend by transforming them into the required
   * format and filling in any missing data.
   *
   * @param tasks - The array of checklist tasks to be saved.
   * @returns An array of transformed TeamMemberOnboardingTask objects.
   */
  const buildTasksForSaving = (
    tasks: ChecklistTaskItem<TeamMemberOnboardingTaskForm>[]
  ): TeamMemberOnboardingTask[] => {
    // Validate the tasks before saving
    validateTasksForSaving();

    // We probably shouldn't be casting here, but it's the easiest way to get around the type errors - we already validate via the validator function
    return tasks.map((task) => {
      return {
        ...task.data,

        _id: task._id || ObjectID().toHexString(),
        type: task.type,
        status: task.data.status || "incomplete",
        assignee: task.data.assignee || [{ type: "self", value: "self" }],
      } as TeamMemberOnboardingTask;
    });
  };

  const upsertChecklist = async () => {
    if (!teamMember?._id || !activeCompanyId) return;
    setSaving(true);

    try {
      const taskData = buildTasksForSaving(newHireTasks.concat(adminTasks));

      const res = checklist?._id
        ? await MiterAPI.team_member_onboarding_checklists.update(checklist._id, { tasks: taskData })
        : await MiterAPI.team_member_onboarding_checklists.create({
            team_member_id: teamMember._id,
            tasks: taskData,
            company_id: activeCompanyId,
          });

      if (res.error) throw new Error(res.error);

      Notifier.success("Checklist saved successfully");
      setSaving(false);
    } catch (e: $TSFixMe) {
      console.log("Error saving checklist", e);
      Notifier.error(e.message);
      setSaving(false);

      // Need to rethrow the error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  useEffect(() => {
    getChecklist();
  }, [teamMember]);

  const getChecklist = async () => {
    if (!teamMember?._id) return;
    setLoading(true);

    try {
      const filter = [{ field: "team_member_id", value: teamMember._id }];
      const res = await MiterAPI.team_member_onboarding_checklists.search(filter);
      if (res.error) throw new Error(res.error);

      if (res.length === 0) {
        const tasks = await MiterAPI.team_member_onboarding_checklists.retrieve_default_tasks(teamMember._id);
        if (tasks.error) throw new Error(tasks.error);

        // If there are no tasks, add the default personal info task
        if (tasks.length === 0) {
          tasks.push(buildDefaultPersonalInfoTask());
        }

        setChecklist({ team_member_id: teamMember._id, tasks });

        const newHireTasks = tasks.filter((task) => task.assignee.every((g) => g.type === "self"));
        const adminTasks = tasks.filter((task) => task.assignee.some((g) => g.type !== "self"));

        setNewHireTasks(buildChecklistTaskItems(newHireTasks));
        setAdminTasks(buildChecklistTaskItems(adminTasks));
      } else {
        setChecklist(res[0]);

        const tasks = res[0]?.tasks || [];

        const newHireTasks = tasks.filter((task) => task.assignee.every((g) => g.type === "self"));
        const adminTasks = tasks.filter((task) => task.assignee.some((g) => g.type !== "self"));

        setNewHireTasks(buildChecklistTaskItems(newHireTasks));
        setAdminTasks(buildChecklistTaskItems(adminTasks));
      }
    } catch (e: $TSFixMe) {
      console.log("Error getting checklist", e);
      Notifier.error(e.message);
    }

    setLoading(false);
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>
            {type === "admin" ? "Admin checklist" : "New hire onboarding checklist"}
          </h2>
          <p className={styles["subheader-description"]}>
            {type === "admin"
              ? "Add tasks that need to be completed by the admin team."
              : `Add tasks that need to be completed by ${teamMember?.first_name}.`}
          </p>
        </div>
        {loading && <Loader />}
        {teamMember && !loading && (
          <TeamMemberChecklistBuilder
            tasks={type === "admin" ? adminTasks : newHireTasks}
            setTasks={type === "admin" ? setAdminTasks : setNewHireTasks}
            type={type}
          />
        )}
      </div>
    </WizardScreen>
  );
};

/**
 * Builds checklist task items (from the backend) into the format required by the ChecklistTasks component.
 *
 * @param tasks - The array of TeamMemberOnboardingTask objects.
 * @returns An array of ChecklistTaskItem objects.
 */
const buildChecklistTaskItems = (
  tasks: TeamMemberOnboardingTask[]
): ChecklistTaskItem<TeamMemberOnboardingTaskForm>[] => {
  const config = EE_ONBOARDING_TASK_CONFIG;

  return tasks.map((task, index) => {
    const taskType = task.type as keyof typeof config;
    const { header } = config[taskType];

    const title = "title" in task ? task.title || header.title : header.title;
    const assigneeType = task.assignee.every((g) => g.type === "self") ? "new_hire" : "admin";

    return {
      _id: task._id,
      index,
      type: taskType,
      title,
      icon: header.icon,
      data: { ...task, assignee_type: assigneeType },
      showDropdown: true,
      disableReorder: config[taskType].disableReorder || false,
      open: true,
    };
  });
};

/**
 *  Builds the default personal info task for a new hire checklist.
 */
const buildDefaultPersonalInfoTask = (): TeamMemberOnboardingTask => {
  return {
    _id: ObjectID().toHexString(),
    status: "incomplete",
    type: "personal_info",
    fields: {
      ssn: true,
      dob: true,
      address: true,
      language: true,
      emergency_contacts: true,
      profile_picture: true,
    },
    due_days_from_start: undefined,
    assignee: [{ type: "self", value: "self" }],
  };
};
