import React, { useState, useEffect } from "react";
import { addressString, formatPhoneNumber, sleep } from "../../../utils/utils";
import { Button, Loader } from "ui";
import { MiterAPI } from "dashboard/miter";

import UpdateCompanyModal from "./UpdateCompanyModal";
import DataBox from "dashboard/components/dataBox/DataBox";
import Notifier from "dashboard/utils/notifier";

import type { AggregatedCompany } from "dashboard/miter";
import { UpdateCaInfoModal } from "./UpdateCaInfoModal";
import { displayFieldErrors } from "dashboard/utils/errors";
import { useActiveCompanyId, useRefetchTeam, useUser } from "dashboard/hooks/atom-hooks";
import { convertSeconds, lookupTimezoneLabel } from "miter-utils";
import { CompanySecurityModal } from "./CompanySecurityModal";
import { useFetchUserData } from "dashboard/hooks/useFetchUserData";
import { useCheckCompanyOnboard } from "dashboard/hooks/useCheckCompanyOnboard";
import { DataBoxRow } from "dashboard/components/dataBox/DataBoxRow";

const CompanyInfo: React.FC = () => {
  const fetchUserData = useFetchUserData();
  const checkCompanyOnboard = useCheckCompanyOnboard();
  const activeCompanyId = useActiveCompanyId();

  const refetchTeam = useRefetchTeam();
  const user = useUser();

  const [company, setCompany] = useState<AggregatedCompany | undefined>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);

  const [editingCaInfo, setEditingCaInfo] = useState(false);
  const [editingSecurity, setEditingSecurity] = useState(false);

  const getCompany = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.companies.retrieve(activeCompanyId!);
      if (response.error) throw new Error(response.error);
      setCompany(response);
    } catch (e) {
      Notifier.error("There was an error retrieving company info.");
    }
    setLoading(false);
  };

  const updateCompany = async (data, setError) => {
    setSubmitting(true);

    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, data);
      if (response.error) {
        if (response.fields) {
          const addressFields = ["line1", "line2", "city", "state", "postal_code"];
          const mappedFields = response.fields.map((field) => {
            if (addressFields.includes(field.name)) {
              return { name: "address." + field.name, error: field.error };
            }

            return field;
          });
          displayFieldErrors(mappedFields, setError);
        }

        throw Error(response.error);
      }
      Notifier.success("Successfully updated company.");
      setEditing(false);
      getCompany();
      fetchUserData();
      if (data.workplace) {
        // On the backend, all the TMs get updated without awaiting since it takes considerably longer, so let's give it a couple minutes before we refresh our data
        sleep(2 * 60 * 1000).then(() => refetchTeam());
      }
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const openLogo = async () => {
    try {
      if (company && !company.logo_file_id) throw new Error("No logo found");

      const res = await MiterAPI.files.get_urls({
        filter: [{ field: "_id", value: company?.logo_file_id, type: "_id" }],
      });

      if (res.error) {
        Notifier.error("We are unable to open this file. Please try again later.");
        console.error(res.error);
      } else if (res.urls.length) {
        window.open(res.urls[0]!["value"].url, "_blank");
      }
    } catch (e: $TSFixMe) {
      console.error("Error opening logo", e);
      Notifier.error("We are unable to open this logo. Please try again later.");
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  const renderCompanyInfo = () => {
    if (!company) return;

    const address = addressString(company.check_company.address);
    const workplace = company.workplace?.check_workplace
      ? addressString(company.workplace.check_workplace.address)
      : "-";

    return (
      <>
        <DataBox title="Company Information" className="flex-col no-margin" onEdit={() => setEditing(true)}>
          {user?.miter_admin && <DataBoxRow label="Company ID" value={company._id} />}
          <DataBoxRow
            label="Company name"
            value={company.check_company.trade_name || company.check_company.legal_name}
          />
          <DataBoxRow label="Address" value={address} />
          <DataBoxRow label="Signer name" value={company.signer_name || ""} />
          <DataBoxRow label="Signer title" value={company.signer_title || ""} />
          <DataBoxRow label="Email" value={company.check_company.email || ""} />
          <DataBoxRow label="Phone #" value={formatPhoneNumber(company.phone || "")} />
          <DataBoxRow label="Workplace" value={workplace} />
          <DataBoxRow label="Company website" value={company.website_url || ""} />
          <DataBoxRow label="Timezone" value={lookupTimezoneLabel(company.timezone) || "-"} />
          <DataBoxRow label="Federal employer identification number (FEIN)" value={company.fein || "-"} />
          <DataBoxRow label="Workers' comp policy number" value={company.workers_comp_policy_number || "-"} />
          <DataBoxRow
            label="Self insured certificate number"
            value={company.self_insured_certificate_number || "-"}
          />
          <DataBoxRow
            label="Logo"
            value={
              company.logo_file_id ? (
                <button className="button-text purple-link" onClick={openLogo}>
                  View
                </button>
              ) : (
                "-"
              )
            }
          />
          <DataBoxRow
            label="Show logo in dashboard and team portal"
            value={company.logo_file_id ? (company.settings?.show_company_logo ? "Yes" : "No") : "-"}
          />
        </DataBox>
        {editingCaInfo && (
          <UpdateCaInfoModal company={company} hide={() => setEditingCaInfo(false)} getCompany={getCompany} />
        )}
        {editingSecurity && (
          <CompanySecurityModal
            onHide={() => setEditingSecurity(false)}
            onFinish={() => {
              getCompany();
              setEditingSecurity(false);
            }}
          />
        )}
        {company?.workplace?.check_workplace?.address?.state === "CA" && (
          <DataBox
            title="CA information"
            className="flex-col no-margin"
            onEdit={() => setEditingCaInfo(true)}
          >
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>State license number</span>
              <span className={"data-box-section-value font-14"}>{company.ca_info?.license_number}</span>
            </div>

            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>PWC registration number</span>
              <span className={"data-box-section-value font-14"}>
                {company.ca_info?.pwc_registration_number}
              </span>
            </div>
          </DataBox>
        )}
        <DataBox title="Security" className="flex-col no-margin" onEdit={() => setEditingSecurity(true)}>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Dashboard session lifespan</span>
            <span className={"data-box-section-value font-14"}>
              {convertSeconds(company.settings?.security?.dashboard_session_lifespan) || "Default"}
            </span>
          </div>

          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Mobile session lifespan</span>
            <span className={"data-box-section-value font-14"}>
              {convertSeconds(company.settings?.security?.mobile_session_lifespan) || "Default"}
            </span>
          </div>
        </DataBox>

        <Button
          className="button-2"
          onClick={checkCompanyOnboard.open}
          style={{ marginLeft: 0 }}
          text="Manage company and tax settings"
          loading={checkCompanyOnboard.loading}
        />
      </>
    );
  };

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="vertical-spacer-large" />
          {company && renderCompanyInfo()}
          {editing && company && (
            <UpdateCompanyModal
              company={company}
              onHide={() => setEditing(false)}
              onUpdate={updateCompany}
              submitting={submitting}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CompanyInfo;
