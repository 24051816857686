import {
  useActiveCompany,
  useActiveCompanyId,
  useBannerNotification,
  useTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, BulkUpdateResult, MiterAPI, MiterFilterArray } from "dashboard/miter";
import { BANNER_NOTIFICATION, Notifier, SLACK_CHANNEL } from "dashboard/utils";
import { ClockCounterClockwise, Plus, TrashSimple } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { TableActionLink, TableV2 } from "ui/table-v2/Table";
import { BenefitModal } from "./BenefitModal";
import {
  cleanEmployeeBenefit,
  CleanedEmployeeBenefit,
  useBenefitColDefs,
  DeleteBenefitModal,
} from "./benefitsUtils";
import { baseSensitiveCompare } from "miter-utils";
import { EmployeeBenefitImporter } from "dashboard/components/employee-benefits/EmployeeBenefitsImporter";
import { ImportHistory } from "dashboard/components/importer/ImportHistory";
import { useNavigate, useParams } from "react-router-dom";
import { pick } from "lodash";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";
import { useEmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { BulkUpdateParams } from "backend/utils";
import { UpdateEmployeeBenefitParams } from "backend/services/benefits-service";
import Banner from "dashboard/components/shared/Banner";
import { isClaspCompany } from "dashboard/utils/clasp-utils";

const TOGGLER_CONFIG = {
  config: [
    { path: "active", label: "Active" },
    { path: "inactive", label: "Inactive" },
    { path: "all", label: "All" },
  ],
  path: "active",
  secondary: true,
  field: "status",
};

export const AllEmployeeBenefitsTable: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const user = useUser();
  const teamMembers = useTeam();
  const navigate = useNavigate();

  const miterAbilities = useMiterAbilities();
  const benefitAbilities = useEmployeeBenefitAbilities();
  const { enIntegration } = useEmployeeNavigator();

  const [employeeBenefits, setEmployeeBenefits] = useState<CleanedEmployeeBenefit[]>();
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [selectedRows, setSelectedRows] = useState<CleanedEmployeeBenefit[]>([]);
  const [editing, setEditing] = useState<CleanedEmployeeBenefit>();
  const [editingTm, setEditingTm] = useState<AggregatedTeamMember>();
  const [deleting, setDeleting] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const params = useParams<{ benefit_id?: string }>();

  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);

  const [hideBenefitsAdminWaitlistBanner, setHideBenefitsAdminWaitlistBanner] = useBannerNotification(
    BANNER_NOTIFICATION.BENEFITS_ADMIN_WAITLIST
  );

  const benefitsAdminWaitlistText = useMemo(() => {
    if (!employeeBenefits?.some((b) => b.benefit_type === "125_medical")) {
      return `Interested in offering health benefits? Click this banner to schedule a call with our benefits team.`;
    } else if (false && employeeBenefits?.some((b) => b.integrations?.employee_navigator)) {
      // TODO: Re-enable this once we want to push benefits admin to EN companies.
      return `You're using Miter Benefits with Employee Navigator. Click this banner to learn more about Miter Benefits Administration, where you can manage your deductions within Miter.`;
    } else {
      return `Interested in having deductions automatically synced to payroll? Sign up for the Miter Benefits Admin waitlist by clicking this banner.`;
    }
  }, [employeeBenefits]);

  const handleSignUpForWaitlist = async () => {
    try {
      await MiterAPI.slack.send_message(
        `User ${user?.email || user?._id} from ${
          activeCompany?.check_company.trade_name
        } signed up for the benefits admin waitlist.`,
        SLACK_CHANNEL.BENEFITS_FEEDBACK
      );
      setHideBenefitsAdminWaitlistBanner("hide");
      Notifier.success("You've been added to the benefits admin waitlist.");
    } catch (e: $TSFixMe) {
      console.log("Error signing up for benefits admin waitlist", e);
      Notifier.error(e.message);
    }
  };

  useEffect(() => {
    getEmployeeBenefits();
  }, [!!activeCompanyId]);

  /** If a benefit_id is in the URL, set it as the editing benefit and then remove it from the URL */
  useEffect(() => {
    if (params.benefit_id) {
      const benefit = employeeBenefits?.find((b) => b._id === params.benefit_id);
      if (benefit) {
        setEditing(benefit);
        navigate({ pathname: "/benefits/employee-benefits", search: location.search }, { replace: true });
      }
    }
  }, [params.benefit_id, employeeBenefits]);

  const getEmployeeBenefits = async () => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const filter: MiterFilterArray = [
        { field: "company", type: "string" as const, value: activeCompanyId },
      ];

      const response = await MiterAPI.benefits.employee.search(filter);
      if (response.error) throw Error(response.error);

      const cleanedBenefits = response
        .filter((b) => benefitAbilities.can("read", b) && !b.check_benefit.metadata?.fringe_use)
        .map((b) => cleanEmployeeBenefit(b, benefitAbilities))
        .sort((a, b) => {
          if (a.employee_check_id === b.employee_check_id) {
            return baseSensitiveCompare(a.benefit_type_label, b.benefit_type_label);
          }
          return baseSensitiveCompare(a.miter_tm.full_name, b.miter_tm.full_name);
        });

      setEmployeeBenefits(cleanedBenefits);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was a problem retrieving employee benefit data. We're looking into it.");
    }
    setLoading(false);
  };

  const errorMap = {
    employee_contribution_percent: "employee_contribution_value",
    employee_contribution_amount: "employee_contribution_value",
    employee_period_amount: "employee_contribution_value",
    per_hour_employee_contribution: "employee_contribution_value",
    company_contribution_percent: "company_contribution_value",
    company_contribution_amount: "company_contribution_value",
    company_period_amount: "company_contribution_value",
    per_hour_company_contribution: "company_contribution_value",
  };

  /** Turns a cleaned employee benefit's company contribution type / value into the right params for updating the benefit */
  const getCompanyContributionParams = (b: CleanedEmployeeBenefit) => {
    const companyContributionValue = b.company_contribution_value;
    const companyContributionType = b.company_contribution_type;

    switch (companyContributionType) {
      case "contribution_amount":
        return {
          company_contribution_amount: companyContributionValue + "",
          company_contribution_percent: null,
          company_period_amount: null,
          per_hour_company_contribution: false,
        };
      case "contribution_percent":
        return {
          company_contribution_amount: null,
          company_contribution_percent: companyContributionValue,
          company_period_amount: null,
          per_hour_company_contribution: false,
        };
      case "period_amount":
        return {
          period: "monthly" as const,
          company_contribution_amount: null,
          company_contribution_percent: null,
          company_period_amount: companyContributionValue + "",
          per_hour_company_contribution: false,
        };
      case "per_hour":
        return {
          company_contribution_amount: companyContributionValue + "",
          company_contribution_percent: null,
          company_period_amount: null,
          per_hour_company_contribution: true,
        };
    }
  };

  /** Turns a cleaned employee benefit's employee contribution type / value into the right params for updating the benefit */
  const getEmployeeContributionParams = (b: CleanedEmployeeBenefit) => {
    const employeeContributionValue = b.employee_contribution_value;
    const employeeContributionType = b.employee_contribution_type;

    switch (employeeContributionType) {
      case "contribution_amount":
        return {
          employee_contribution_amount: employeeContributionValue + "",
          employee_contribution_percent: null,
          employee_period_amount: null,
          per_hour_employee_contribution: false,
        };
      case "contribution_percent":
        return {
          employee_contribution_amount: null,
          employee_contribution_percent: employeeContributionValue,
          employee_period_amount: null,
          per_hour_employee_contribution: false,
        };
      case "period_amount":
        return {
          period: "monthly" as const,
          employee_contribution_amount: null,
          employee_contribution_percent: null,
          employee_period_amount: employeeContributionValue + "",
          per_hour_employee_contribution: false,
        };
      case "per_hour":
        return {
          employee_contribution_amount: employeeContributionValue + "",
          employee_contribution_percent: null,
          employee_period_amount: null,
          per_hour_employee_contribution: true,
        };
    }
  };

  type CleanedEmployeeBenefitBaseParams = Pick<CleanedEmployeeBenefit, typeof BULK_UPDATE_FIELDS[number]>;

  /** Turns GL account properties that are empty strings into null for the backend */
  const cleanBulkBaseFields = (b: CleanedEmployeeBenefitBaseParams): CleanedEmployeeBenefitBaseParams => {
    return {
      ...b,
      emp_liab_account_id: b.emp_liab_account_id === "" ? null : b.emp_liab_account_id,
      com_liab_account_id: b.com_liab_account_id === "" ? null : b.com_liab_account_id,
      expense_account_id: b.expense_account_id === "" ? null : b.expense_account_id,
    };
  };

  /** Builds integration related params */
  const cleanBulkIntegrationFields = (
    b: Partial<CleanedEmployeeBenefit>
  ): CleanedEmployeeBenefit["integrations"] => {
    const integrationParams = b.integrations || {};

    // If we have an employee navigator deduction code, add that to the params
    if (b.integrations?.employee_navigator?.deduction_code && enIntegration?.connection) {
      integrationParams.employee_navigator = {
        ...b.integrations?.employee_navigator,
        integration_connection_id: enIntegration.connection?._id,
        deduction_code: b.integrations?.employee_navigator?.deduction_code,
      };
    }

    return integrationParams;
  };

  const cleanUpdateParams = (
    benefits: CleanedEmployeeBenefit[]
  ): BulkUpdateParams<UpdateEmployeeBenefitParams> => {
    return benefits.map((b) => {
      // Get the base params
      const baseParams = cleanBulkBaseFields(pick(b, BULK_UPDATE_FIELDS));
      const integrationParams = cleanBulkIntegrationFields(b);
      const employeeContributionParams = getEmployeeContributionParams(b);
      const companyContributionParams = getCompanyContributionParams(b);

      // Merge the params
      const finalParams: UpdateEmployeeBenefitParams = {
        ...baseParams,
        ...integrationParams,
        ...employeeContributionParams,
        ...companyContributionParams,

        // misc
        vendor_id: b.vendor_id,
      };

      // Return the final params
      return { _id: b._id, params: finalParams };
    });
  };

  const updateEmployeeBenefits = async (benefits: CleanedEmployeeBenefit[]): Promise<BulkUpdateResult> => {
    try {
      const params = cleanUpdateParams(benefits);
      const res = await MiterAPI.benefits.employee.bulk_update(params);
      if (res.error) throw Error(res.error);

      if (res.errors.length > 0) {
        const successes = res.successes;
        const errors = res.errors.map((e) => {
          return {
            ...e,
            fieldErrors: e.fieldErrors?.map((fe) => {
              return { ...fe, field: errorMap[fe.field] || fe.field };
            }),
          };
        });

        return { successes, errors };
      }

      getEmployeeBenefits();
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem updating employee benefits. We're looking into it.");
      return { successes: [], errors: [] };
    }
  };

  const onBenefitsImported = async () => {
    setShowImportHistory(true);
    setShowLatestImportResult(true);
    getEmployeeBenefits();
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add benefit",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => miterAbilities.can("benefits:create"),
      },
      {
        key: "import",
        component: <EmployeeBenefitImporter onFinish={onBenefitsImported} />,
        shouldShow: () => miterAbilities.can("benefits:create"),
      },
      {
        key: "import-history",
        className: "button-1",
        action: () => setShowImportHistory(true),
        label: "Import history",
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => miterAbilities.can("benefits:create"),
      },
    ],
    [miterAbilities.can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setDeleteModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        loading: deleting,
        shouldShow: () => benefitAbilities.can("delete", selectedRows),
      },
    ],
    [deleting, selectedRows, benefitAbilities.can]
  );

  const handleRowClick = (ben: CleanedEmployeeBenefit) => {
    const matchingTm = teamMembers.find((tm) => tm.check_id === ben.employee_check_id);
    if (!matchingTm) {
      console.error(`TM not found for benefit ${JSON.stringify(ben)}.`);
      Notifier.error("There was an error opening the benefit. We're investigating!");
    } else {
      setEditing(ben);
      setEditingTm(matchingTm);
    }
  };

  const deleteBenefit = async () => {
    if (benefitAbilities.cannot("delete", selectedRows)) {
      Notifier.error("You do not have permission to delete benefits.");
      return;
    }

    setDeleting(true);
    try {
      const response = await MiterAPI.benefits.employee.delete({ ids: selectedRows.map((b) => b._id) });
      if (response.error) throw Error(response.error);
      if (response.errors.length) throw new Error("One or more benefits could not be deleted.");
      await getEmployeeBenefits();
      Notifier.success("Employee benefit(s) deleted successfully.");
      setDeleteModal(false);
      setSelectedRows([]);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("One or more benefits could not be deleted.");
    }
    setDeleting(false);
  };

  const employeeBenefitColumns = useBenefitColDefs({ type: "employee", showEmployeeField: true });

  return (
    <div>
      {!hideBenefitsAdminWaitlistBanner && !isClaspCompany(activeCompany) && (
        <>
          <div className="vertical-spacer"></div>
          <Banner type="info" onClick={handleSignUpForWaitlist} content={benefitsAdminWaitlistText} />
        </>
      )}
      <TableV2
        id="employee-benefits-table"
        resource="employee benefits"
        data={employeeBenefits}
        columns={employeeBenefitColumns}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        isLoading={loading}
        showReportViews={true}
        editable={miterAbilities.can("benefits:update")}
        onSave={updateEmployeeBenefits}
        gridWrapperStyle={{ height: 550 }}
        toggler={TOGGLER_CONFIG}
      />

      {deleteModal && (
        <DeleteBenefitModal
          onDelete={deleteBenefit}
          onCancel={() => setDeleteModal(false)}
          multi={selectedRows.length > 1}
          loading={deleting}
        />
      )}
      {(creating || editing) && (
        <BenefitModal
          hide={() => {
            setCreating(false);
            setEditing(undefined);
            setEditingTm(undefined);
          }}
          onSuccess={getEmployeeBenefits}
          isEmployeeBenefit={true}
          employee={editingTm}
          benefitToUpdate={editing}
        />
      )}
      {showImportHistory && (
        <ImportHistory
          id={"employee_benefits"}
          resource={"employee benefits"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
    </div>
  );
};

const BULK_UPDATE_FIELDS = [
  "description",
  "per_hour_company_contribution",
  "per_hour_employee_contribution",
  "company_contribution_amount",
  "employee_contribution_amount",
  "company_contribution_percent",
  "employee_contribution_percent",
  "employee_period_amount",
  "company_period_amount",
  "effective_start",
  "effective_end",
  "emp_liab_account_id",
  "com_liab_account_id",
  "expense_account_id",
  "cost_type_id",
  "group_term_life_amount",
  "integrations",
  "non_bonafide",
  "plan_number",
] as const;
