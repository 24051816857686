import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Toggler } from "ui";
import { LedgerEntries } from "./LedgerEntries/LedgerEntries";
import { AccountingSettings } from "./AccountingSettings/AccountingSettings";
import { LedgerAccounts } from "./LedgerAccounts/LedgerAccounts";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { MiterGuidesIcon } from "dashboard/components/MiterGuidesIcon";
import { useEnhancedSearchParams } from "miter-utils";

export const AccountingPage: React.FC = () => {
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const { can, cannot } = useMiterAbilities();

  const handleToggle = (path: string) => {
    setSearchParams({ view: path });
  };

  const togglerConfig: TogglerConfigItem[] = useMemo(
    () => [
      { label: "Entries", path: "ledger_entries", hide: cannot("accounting:ledger_entries:read") },
      { label: "Accounts", path: "accounts", hide: cannot("accounting:ledger_accounts:read") },
      { label: "Settings", path: "settings", hide: cannot("accounting:settings") },
    ],
    [can]
  );

  const togglerState = useMemo(
    () => parsedSearchParams.view || togglerConfig.find((c) => !c.hide)?.path || "ledger_entries",
    [parsedSearchParams, togglerConfig]
  );

  return (
    <div className="page-content">
      <Helmet>
        <title>{`Accounting setup`}</title>
      </Helmet>
      <div className="page-content-header">
        <div className="flex">
          <h1 style={{ marginTop: 0 }}>Accounting</h1>
          <MiterGuidesIcon path="company/accounting" marginLeft={10} />
        </div>
      </div>
      <div style={{ color: "darkslategray" }}>
        View ledger entries, connect your accounting system, and configure settings.
      </div>
      <Toggler config={togglerConfig} toggle={handleToggle} active={togglerState} />
      {togglerState === "settings" && can("accounting:ledger_entries:read") && <AccountingSettings />}
      {togglerState === "accounts" && can("accounting:ledger_accounts:read") && <LedgerAccounts />}
      {togglerState === "ledger_entries" && can("accounting:settings") && <LedgerEntries />}
    </div>
  );
};
