import React, { useCallback, useContext, useMemo, useRef, useEffect, useState } from "react";
import styles from "./PayrollRegister.module.css";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { CheckItemPostTaxDeduction, CheckTax } from "backend/utils/check/check-types";
import {
  EnhancedMiterPayment,
  MiterReimbursement,
  MiterEarning,
} from "dashboard/pages/payrolls/payrollTypes";
import { AggregatedPayroll } from "dashboard/miter";
import { capitalize, getPaperCheckTotalForPayroll, getPaymentTotals } from "dashboard/utils";
import { Button, Link, usdString } from "ui";
import { useActiveAccount, useActiveCompany, useLookupDepartment } from "dashboard/hooks/atom-hooks";
import { reportList } from "../../reportList";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { deparameterize, roundTo, sleep } from "miter-utils";
import { useReactToPrint } from "react-to-print";

import {
  earningTypeLookup,
  miterEarningTypeLookup,
} from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";
import PaymentModal from "dashboard/pages/payrolls/viewPayroll/PaymentModal/PaymentModal";
import { Printer } from "phosphor-react";
import { DateTime } from "luxon";
import {
  getBenefitsListWithPrgFringesBrokenOut,
  UnifiedPrgFringeBenefit,
} from "dashboard/pages/benefits/benefitsUtils";

type Props = {};

const BuildEmployeeRow: React.FC<{
  payment: EnhancedMiterPayment;
  onSelectPayment: (p: EnhancedMiterPayment | null) => void;
  printing?: boolean;
}> = ({ payment, onSelectPayment, printing }) => {
  const lookupDepartment = useLookupDepartment();
  const department = lookupDepartment(payment.team_member.department_id);

  return (
    <>
      <div className={`${styles["payroll-register-table-header"]} ${styles["darken"]}`}>
        <div className={styles["payroll-register-table-cell"]}>
          {capitalize(payment.team_member.employment_type)}
        </div>
      </div>
      <div className={styles["payroll-register-relative-container"]}>
        <div>
          <div className={styles["payroll-register-table-row"]}>
            <div
              className={`${styles["payroll-register-table-cell"]} ${styles["text-bold"]} ${styles["team-member-name"]}`}
            >
              {!printing && (
                <Link onClick={() => onSelectPayment(payment)}>{payment.team_member.full_name}</Link>
              )}
              {printing && payment.team_member.full_name}
            </div>
          </div>
          <div className={styles["payroll-register-table-row"]}>
            <div className={styles["payroll-register-table-cell"]}>{department ? department.name : " "}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const EarningsRow: React.FC<{ earning: AccumulatedEarning }> = ({ earning }) => (
  <div className={styles["payroll-register-table-row"]}>
    <div className={`${styles["payroll-register-table-cell"]} ${styles["flex-auto"]}`}>
      {earning.description}
    </div>
    <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
      {roundTo(earning.hours)}hrs
    </div>
    <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
      {usdString(earning.amount)}
    </div>
  </div>
);

const TaxDeductionsRows: React.FC<{ taxes?: CheckTax[] | null }> = ({ taxes }) => {
  const [employeePayers, companyPayers] = useMemo(() => {
    const employeePayers: CheckTax[] = [];
    const companyPayers: CheckTax[] = [];

    taxes?.forEach((tax) => {
      if (tax.payer === "employee") employeePayers.push(tax);
      if (tax.payer === "company") companyPayers.push(tax);
    });

    return [employeePayers, companyPayers];
  }, [taxes]);

  const renderTaxRows = useCallback(
    (taxes: CheckTax[]) => (
      <>
        {taxes.map((tax) => (
          <div className={styles["payroll-register-table-row"]} key={tax.tax}>
            <div className={styles["payroll-register-table-cell"]}>{tax.description}</div>
            <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
              {usdString(tax.amount)}
            </div>
          </div>
        ))}
      </>
    ),
    []
  );

  return (
    <>
      <div className={`${styles["payroll-register-table-cell"]} ${styles["outline"]}`}>
        {renderTaxRows(employeePayers)}
      </div>
      <div className={`${styles["payroll-register-table-cell"]} ${styles["outline"]}`}>
        {renderTaxRows(companyPayers)}
      </div>
    </>
  );
};

const PostTaxDeductionsRow: React.FC<{ deduction: CheckItemPostTaxDeduction }> = ({ deduction }) => (
  <div className={styles["payroll-register-table-row"]}>
    <div className={styles["payroll-register-table-cell"]}>{deduction.description}</div>
    <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
      {usdString(deduction.amount)}
    </div>
  </div>
);

const BenefitsRow: React.FC<{ benefits?: UnifiedPrgFringeBenefit[] | null }> = ({ benefits }) => {
  return benefits && benefits.length ? (
    <>
      <div className={`${styles["payroll-register-table-row"]} ${styles["collapse-padding"]}`}>
        <div className={styles["payroll-register-table-cell"]}></div>
        <div className={`${styles["payroll-register-table-cell"]}`}>Employee</div>
        <div className={`${styles["payroll-register-table-cell"]}`}>Company</div>
      </div>

      {benefits.map((benefit) => (
        <div
          className={`${styles["payroll-register-table-row"]}  ${styles["row-spacing"]}`}
          key={benefit._id}
        >
          <div className={styles["payroll-register-table-cell"]}>{benefit.description}</div>
          <div className={styles["payroll-register-table-cell"]}>{benefit.employee_contribution}</div>
          <div className={styles["payroll-register-table-cell"]}>{benefit.company_contribution}</div>
        </div>
      ))}
    </>
  ) : (
    <></>
  );
};

const ReimbursementRow: React.FC<{ reimbursement: MiterReimbursement }> = ({ reimbursement }) => (
  <div className={styles["payroll-register-table-row"]}>
    <div className={styles["payroll-register-table-cell"]}>{reimbursement.description}</div>
    <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
      {usdString(reimbursement.amount)}
    </div>
  </div>
);

const EmployeeTotalsRow: React.FC<{ payment: EnhancedMiterPayment }> = ({ payment }) => {
  const { hours, earnings, net_pay } = getPaymentTotals(payment);

  return (
    <div
      className={`${styles["payroll-register-table-row"]} ${styles["payroll-register-table-employee-totals"]}`}
    >
      <div className={styles["payroll-register-table-cell"]}>
        <div className={`${styles["payroll-register-table-row"]}`}>
          <div className={`${styles["payroll-register-table-cell"]} ${styles["text-bold"]}`}>Hours</div>
          <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
            {roundTo(hours)}
          </div>
        </div>
      </div>
      <div className={styles["payroll-register-table-cell"]}>
        {" "}
        <div className={styles["payroll-register-table-row"]}>
          <div className={`${styles["payroll-register-table-cell"]} ${styles["text-bold"]}`}>Gross</div>
          <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
            {usdString(earnings)}
          </div>
        </div>
      </div>
      <div className={styles["payroll-register-table-cell"]}>
        {" "}
        <div className={styles["payroll-register-table-row"]}>
          <div className={`${styles["payroll-register-table-cell"]} ${styles["text-bold"]}`}>Net</div>
          <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
            {usdString(net_pay)}
          </div>
        </div>
      </div>
    </div>
  );
};

type AccumulatedEarning = {
  hours: number;
  amount: number;
  description: string;
};

type AccumulatedEarnings = {
  [key: string]: AccumulatedEarning;
};

const getAccEarningDescription = (me: MiterEarning): string => {
  const checkTypeLabel = earningTypeLookup[me.check_type];
  let desc = "";
  if (me.description) {
    desc = me.description;
  } else if (me.miter_type === "manual") {
    desc = `Manual ${checkTypeLabel.toLowerCase()}`;
  } else {
    desc = miterEarningTypeLookup[me.miter_type];
  }

  if (!desc) {
    return checkTypeLabel;
  } else if (me.miter_type === "hourly") {
    let tt: string;
    if (me.check_type === "overtime") {
      tt = "OT";
    } else if (me.check_type === "double_overtime") {
      tt = "DOT";
    } else {
      tt = "REG";
    }
    return `${desc}, ${tt}`;
  }

  return desc;
};

const aggregateMiterEarnings = (earnings: MiterEarning[]): AccumulatedEarning[] => {
  const earningsMap = earnings.reduce((acc, earning) => {
    const key = getAccEarningDescription(earning);
    if (!acc[key]) {
      acc[key] = {
        description: key,
        hours: 0,
        amount: 0,
      };
    }
    acc[key]!.amount += earning.amount;
    acc[key]!.hours += earning.hours || 0;
    return acc;
  }, {} as AccumulatedEarnings);

  return Object.values(earningsMap).sort((a, b) => b.amount - a.amount);
};

const RenderMiterPayment: React.FC<{
  payment: EnhancedMiterPayment;
  onSelectPayment: (p: EnhancedMiterPayment | null) => void;
  printing?: boolean;
}> = ({ payment, onSelectPayment, printing }) => {
  const aggregatedEarnings = useMemo(
    () => aggregateMiterEarnings(payment.miter_earnings),
    [payment.miter_earnings]
  );

  const benefits = useMemo(() => getBenefitsListWithPrgFringesBrokenOut(payment), [payment]);

  return (
    <div className={styles["payroll-register-table"]}>
      <div className={styles["payroll-register-table-row"]}>
        <div className={styles["payroll-register-col-1"]}>
          <BuildEmployeeRow payment={payment} onSelectPayment={onSelectPayment} printing={printing} />
        </div>

        <div className={styles["payroll-register-col-2"]}>
          <div className={styles["payroll-register-table-header"]}>
            <div className={styles["payroll-register-table-cell"]}>Earnings</div>
            <div className={styles["payroll-register-table-cell"]}>Employee Taxes</div>
            <div className={styles["payroll-register-table-cell"]}>Company Taxes</div>
          </div>
          <div className={`${styles["payroll-register-table-row"]}`}>
            <div className={`${styles["payroll-register-table-cell"]} ${styles["outline"]}`}>
              {aggregatedEarnings.map((earning, index) => (
                <EarningsRow earning={earning} key={`${earning.description}-${index}`} />
              ))}
            </div>
            <TaxDeductionsRows taxes={payment.check_item?.taxes} />
          </div>
          <div className={styles["payroll-register-table-header"]}>
            <div className={styles["payroll-register-table-cell"]}>Reimbursements</div>
            <div className={styles["payroll-register-table-cell"]}>Benefits</div>
            <div className={styles["payroll-register-table-cell"]}>Deductions</div>
          </div>
          <div className={`${styles["payroll-register-table-row"]}`}>
            <div className={`${styles["payroll-register-table-cell"]} ${styles["outline"]}`}>
              {payment.miter_reimbursements.map((reimbursement) => (
                <ReimbursementRow reimbursement={reimbursement} key={reimbursement._id} />
              ))}
            </div>
            <div className={`${styles["payroll-register-table-cell"]} ${styles["outline"]}`}>
              <BenefitsRow benefits={benefits} />
            </div>
            <div
              className={`${styles["payroll-register-table-cell"]}  ${styles["outline"]} ${styles["text-right"]}`}
            >
              {payment.check_item?.post_tax_deductions?.map((deduction) => (
                <PostTaxDeductionsRow deduction={deduction} key={deduction.post_tax_deduction} />
              ))}
            </div>
          </div>
          <EmployeeTotalsRow payment={payment} />
        </div>
      </div>
    </div>
  );
};

type MiterPaymentsProps = {
  payments: EnhancedMiterPayment[];
  onSelectPayment: (p: EnhancedMiterPayment | null) => void;
  printing?: boolean;
};

const VirtualizedMiterPayments: React.FC<MiterPaymentsProps> = ({ payments, onSelectPayment }) => {
  const listRef = useRef<List>(null);
  const paymentRowHeights = useRef<Record<number, number>>({});

  const getPaymentRowSize = useCallback(
    (index: number) => (paymentRowHeights.current[index] ? paymentRowHeights.current[index]! + 50 : 50),
    []
  );

  const setPaymentRowHeight = useCallback((index: number, size: number) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
      paymentRowHeights.current[index] = size;
    }
  }, []);

  const Row: React.FC<{ index: number; style: React.CSSProperties }> = ({ index, style }) => {
    const rowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (rowRef.current) {
        const height = rowRef.current.getBoundingClientRect().height;
        setPaymentRowHeight(index, height);
      }
    }, [index, setPaymentRowHeight]);

    const payment = payments[index];
    return payment ? (
      <div style={style}>
        <div ref={rowRef}>
          <RenderMiterPayment payment={payment} onSelectPayment={onSelectPayment} />
        </div>
      </div>
    ) : null;
  };

  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            height={height}
            width={width}
            itemCount={payments.length}
            itemSize={getPaymentRowSize}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

const MiterPayments: React.FC<MiterPaymentsProps> = ({ payments, onSelectPayment, printing }) => {
  return (
    <>
      {payments.map((payment) => (
        <RenderMiterPayment
          key={payment._id}
          payment={payment}
          onSelectPayment={onSelectPayment}
          printing={printing}
        />
      ))}
    </>
  );
};

type PrintableHeaderProps = {
  payroll: AggregatedPayroll;
};

const PrintableHeader: React.FC<PrintableHeaderProps> = ({ payroll }) => {
  const activeCompany = useActiveCompany();
  const activeAccount = useActiveAccount();

  return (
    <div className={styles["printable-header"]}>
      <div className={styles["printable-header-left"]}>
        <h2 style={{ marginBottom: 15 }}>Payroll Register</h2>
        <div>
          <div className="vertical-spacer-small" />

          <div className={styles["printable-header-grid"]}>
            <div className={styles["printable-header-row"]}>
              <div className={styles["printable-header-label"]}>Company</div>
              <div>{activeCompany?.check_company?.legal_name}</div>
            </div>

            <div className={styles["printable-header-row"]}>
              <div className={styles["printable-header-label"]}>Pay period</div>
              <div>
                {DateTime.fromISO(payroll.check_payroll.period_start).toFormat("MM/dd/yyyy")} -
                {DateTime.fromISO(payroll.check_payroll.period_end).toFormat("MM/dd/yyyy")}
              </div>
            </div>
            <div className={styles["printable-header-row"]}>
              <div className={styles["printable-header-label"]}>Payroll type</div>
              <div>{capitalize(deparameterize(payroll.check_payroll.type))}</div>
            </div>
            <div className={styles["printable-header-row"]}>
              <div className={styles["printable-header-label"]}>Pay day</div>
              <div>{DateTime.fromISO(payroll.check_payroll.payday).toFormat("MM/dd/yyyy")}</div>
            </div>
            <div className={styles["printable-header-row"]}>
              <div className={styles["printable-header-label"]}>Generated at</div>
              <div>{DateTime.now().toLocal().toFormat("MM/dd/yyyy HH:mm a")}</div>
            </div>
            <div className={styles["printable-header-row"]}>
              <div className={styles["printable-header-label"]}>Generated by</div>
              <div>{activeAccount?.full_name || "Unknown user"}</div>
            </div>
          </div>
          <div className="vertical-spacer" />
        </div>
      </div>
    </div>
  );
};

export const CreatePayrollRegister: React.FC<Props> = () => {
  const { payroll } = useContext(PayrollContext);
  const paperCheckAmount = useMemo(() => getPaperCheckTotalForPayroll(payroll), [payroll]);
  const report = reportList.find((report) => report.slug === "payroll_register");
  const componentRef = useRef<HTMLDivElement>(null);

  const [selectedPayment, setSelectedPayment] = useState<EnhancedMiterPayment | null>(null);
  const [printing, setPrinting] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
    onBeforeGetContent: async () => {
      setPrinting(true);
      await sleep(100);
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
    // Allows for printing page numbers
    pageStyle: `@page { margin-top: 0; }`,
  });

  const filteredPayments = useMemo(() => {
    if (!payroll) return [];
    return payroll.miter_payments.filter((payment) => {
      const { earnings, net_pay } = getPaymentTotals(payment);
      return earnings > 0 || net_pay > 0;
    });
  }, [payroll]);

  if (!payroll) return null;

  return (
    <>
      <div className="flex width-100-percent space-between" style={{ marginBottom: 10 }}>
        <h1 style={{ margin: 0 }}>{report?.label}</h1>
        <Button className="button-2 tall-button no-margin" onClick={handlePrint} loading={printing}>
          <Printer style={{ marginRight: 7 }} /> Print
        </Button>
      </div>
      <div className="report-page-description">{report?.description}</div>

      <div className={styles["payroll-register-container"]}>
        {selectedPayment && (
          <PaymentModal
            tmId={selectedPayment.team_member._id}
            payrollId={selectedPayment.payroll_id}
            editing={selectedPayment.payroll_status === "draft"}
            hide={() => setSelectedPayment(null)}
          />
        )}
        {!printing && (
          <VirtualizedMiterPayments payments={filteredPayments} onSelectPayment={setSelectedPayment} />
        )}
        {printing && (
          <div ref={componentRef} className={styles["printable-payroll-register"]}>
            <PrintableHeader payroll={payroll} />
            <MiterPayments
              payments={filteredPayments}
              onSelectPayment={setSelectedPayment}
              printing={printing}
            />
          </div>
        )}

        <div className={`${styles["payroll-register-table"]} ${styles["border-bottom"]}`}>
          <div className={styles["payroll-register-table-header--dark"]}>
            <div className={styles["payroll-register-table-cell"]}>
              <div className={styles["payroll-register-table-row"]}>
                <div className={`${styles["payroll-register-table-cell"]} ${styles["text-bold"]}`}>
                  Liability
                </div>
                <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
                  {usdString(payroll.check_payroll.totals?.liability)}
                </div>
              </div>
            </div>
            <div className={styles["payroll-register-table-cell"]}>
              <div className={styles["payroll-register-table-row"]}>
                <div className={`${styles["payroll-register-table-cell"]} ${styles["text-bold"]}`}>
                  Cash Requirement
                </div>
                <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
                  {usdString(payroll.check_payroll.totals?.cash_requirement)}
                </div>
              </div>
            </div>
            <div className={styles["payroll-register-table-cell"]}>
              <div className={styles["payroll-register-table-row"]}>
                <div className={`${styles["payroll-register-table-cell"]} ${styles["text-bold"]}`}>
                  Paper Checks
                </div>
                <div className={`${styles["payroll-register-table-cell"]} ${styles["text-right"]}`}>
                  {usdString(paperCheckAmount)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
