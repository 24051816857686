import React, { useEffect, useRef } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { MiterAPI, TeamMember, UpdateTeamMemberParams } from "dashboard/miter";
import {
  useActiveCompany,
  useActiveCompanyId,
  useDepartmentOptions,
  useHasEnabledWcGroups,
  useLocationOptions,
  useRefetchTeam,
  useTeamOptions,
  useWcCodeOptions,
  useWcGroupOptions,
} from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { sleep } from "dashboard/utils";
import { Option } from "ui/form/Input";
import { WizardTeamMember } from "./TeamMemberWizard";
import { employmentCategoryOptions, employmentTermOptions } from "dashboard/pages/team-members/TeamUtils";
import { DateTime } from "luxon";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";
import * as vals from "dashboard/utils/validators";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberEmploymentInfoForm = {
  friendly_id?: string;
  employment_category?: Option<TeamMember["employment_category"]>;
  estimated_hours_worked_per_week?: number | null;
  employment_term?: Option<TeamMember["employment_term"]>;
  start_date?: DateTime;
  title?: string;
  location_id?: Option<string>;
  department_id?: Option<string>;
  wc_code?: Option<string>;
  wc_group_id?: Option<string>;
  reports_to?: Option<string>;
};

export const TeamMemberEmploymentInfoScreen: React.FC<Props> = ({ name, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const isActiveOnClasp = isActiveClaspCompany(activeCompany);
  const refetchTeam = useRefetchTeam();
  const isMounted = useRef(true);
  const locationOptions = useLocationOptions();
  const departmentOptions = useDepartmentOptions();
  const directManagerOptions = useTeamOptions();
  const wcCodeOptions = useWcCodeOptions();
  const wcGroupOptions = useWcGroupOptions();

  const hasEnabledWcGroups = useHasEnabledWcGroups();

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const form = useForm<TeamMemberEmploymentInfoForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(teamMember, {
      locationOptions,
      departmentOptions,
      wcCodeOptions,
      wcGroupOptions,
      directManagerOptions,
    }),
  });

  const formData = useWatch<TeamMemberEmploymentInfoForm>({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberEmploymentInfoForm) => {
    if (!activeCompanyId) throw new Error("No active role");

    const data: UpdateTeamMemberParams = {
      employment_category: params.employment_category?.value,
      estimated_hours_worked_per_week: params.estimated_hours_worked_per_week,
      employment_term: params.employment_term?.value,
      start_date: params.start_date?.toISODate(),
      title: params.title,
      location_id: params.location_id?.value,
      department_id: params.department_id?.value,
      wc_code: params.wc_code?.value,
      wc_group_id: params.wc_group_id?.value,
      reports_to: params.reports_to?.value,
      resume_wizard_index: curIndex + 1,
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberEmploymentInfoForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");
      if (!activeCompanyId) throw new Error("No active company");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }

      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Employment information</h2>
          <p className={styles["subheader-description"]}>
            Add the team member&apos;s employment related information
          </p>
        </div>
        <div className="form-section">
          <Formblock
            label="Team Member ID"
            type="text"
            name="friendly_id"
            labelInfo="Unique identifier (typically a number) for this team member. We will create one automatically if you leave this blank."
            placeholder="Enter an ID"
            form={form}
            className="modal wizard"
            editing={true}
          />
          <Formblock
            label={`Employment category${isActiveOnClasp ? "*" : ""}`}
            type="select"
            name="employment_category"
            placeholder="Select an employment category"
            form={form}
            className="modal wizard"
            editing={true}
            options={employmentCategoryOptions}
            requiredSelect={isActiveOnClasp}
          />
          {formData.employment_category?.value === "part_time" && (
            <Formblock
              type="number"
              name="estimated_hours_worked_per_week"
              label={`Hours worked (weekly estimate)${isActiveOnClasp ? "*" : ""}`}
              labelInfo="Number of hours this team member works per week, on average"
              form={form}
              editing={true}
              className="modal small-margin"
              val={isActiveOnClasp ? vals.required : undefined}
              placeholder={"40"}
            />
          )}
          <Formblock
            label="Employment term"
            labelInfo="The team member's employment term (Temporary, Seasonal, Permanent). This is an optional field."
            type="select"
            name="employment_term"
            placeholder="Select an employment term"
            form={form}
            className="modal wizard"
            editing={true}
            options={employmentTermOptions}
          />
          <Formblock
            label={`Start date`}
            labelInfo="Date the team member started/will start working for the company."
            type="datetime"
            dateOnly={true}
            name="start_date"
            placeholder="MM/DD/YYYY"
            form={form}
            className="modal wizard"
            editing={true}
          />
          <Formblock
            type="text"
            name="title"
            label="Title"
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Enter a job title"}
          />
          <Formblock
            label="Location"
            type="select"
            name="location"
            placeholder="Select a location"
            form={form}
            className="modal wizard"
            editing={true}
            options={locationOptions}
          />
          <Formblock
            label="Department"
            type="select"
            name="department_id"
            placeholder="Select a department"
            form={form}
            className="modal wizard"
            editing={true}
            options={departmentOptions}
          />
          <Formblock
            label="Workers' comp code"
            type="select"
            name="wc_code"
            placeholder="Select a workers comp code"
            form={form}
            className="modal wizard"
            editing={true}
            options={wcCodeOptions}
          />
          {hasEnabledWcGroups && (
            <Formblock
              label="Workers' comp group"
              type="select"
              name="wc_group_id"
              placeholder="Select a workers comp group"
              form={form}
              className="modal wizard"
              editing={true}
              options={wcGroupOptions}
            />
          )}
          <Formblock
            label="Reports to"
            type="select"
            name="reports_to"
            placeholder="Select a manager"
            form={form}
            className="modal wizard"
            editing={true}
            options={directManagerOptions}
          />
        </div>
      </div>
    </WizardScreen>
  );
};

/**
 * Default values helpers
 */

const buildDefaultValues = (
  teamMember: WizardTeamMember | undefined | null,
  params: {
    locationOptions: Option<string>[];
    departmentOptions: Option<string>[];
    wcCodeOptions: Option<string>[];
    wcGroupOptions: Option<string>[];
    directManagerOptions: Option<string>[];
  }
): TeamMemberEmploymentInfoForm => {
  if (!teamMember) return {};

  return {
    friendly_id: teamMember.friendly_id,
    employment_category: employmentCategoryOptions.find((o) => o.value === teamMember.employment_category),
    estimated_hours_worked_per_week: teamMember.estimated_hours_worked_per_week,
    employment_term: employmentTermOptions.find((option) => option.value === teamMember.employment_term),
    start_date: teamMember.start_date ? DateTime.fromISO(teamMember.start_date) : undefined,
    title: teamMember.title,
    location_id: params.locationOptions.find((option) => option.value === teamMember.location_id),
    department_id: params.departmentOptions.find((option) => option.value === teamMember.department_id),
    wc_code: params.wcCodeOptions.find((option) => option.value === teamMember.wc_code),
    wc_group_id: params.wcGroupOptions.find((option) => option.value === teamMember.wc_group_id),
    reports_to: params.directManagerOptions.find((option) => option.value === teamMember.reports_to),
  };
};
