import React, { useEffect } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { AggregatedTeamMember, MiterAPI, TeamMember } from "dashboard/miter";
import { useActiveCompanyId, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import * as vals from "dashboard/utils/validators";
import { sleep } from "dashboard/utils";
import type { CreateTeamMemberParams } from "backend/utils/team-members/createTeamMember";
import { useQuery } from "miter-utils";
import { LinkedTeamMembersWarning, useLinkedTeamMembers } from "dashboard/hooks/useLinkedTeamMembers";
import { LinkedTeamMember } from "backend/services/team-member-service";
import { Navigate } from "react-router-dom";
import { WizardTeamMember } from "./TeamMemberWizard";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberBasicInfoForm = {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  timezone?: string;
  send_sms?: boolean;
  candidate_id?: string;
};

export const TeamMemberBasicInfoScreen: React.FC<Props> = ({ name, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const query = useQuery();
  const refetchTeam = useRefetchTeam();

  const employmentType = query.get("type") || teamMember?.employment_type;

  const form = useForm<TeamMemberBasicInfoForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(teamMember),
  });

  const formData = useWatch({ control: form.control });
  const linkedTeamMembers = useLinkedTeamMembers({ phone: form.watch().phone, email: form.watch().email });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors } = form;
  const { dirtyFields, isValid } = formState;

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async (earlyExit?: boolean) => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    if (!teamMember && Object.keys(dirtyFields).length === 0) {
      throw new Error("Please fill out the form before continuing");
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1 || earlyExit) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberBasicInfoForm) => {
    if (!activeCompanyId) throw new Error("No active role");

    if (!params.email && !params.phone) throw new Error("Email or phone is required");
    if (!params.first_name) throw new Error("First name is required");
    if (!params.last_name) throw new Error("Last name is required");

    const data: CreateTeamMemberParams = {
      first_name: params.first_name,
      middle_name: params.middle_name,
      last_name: params.last_name,
      email: params.email,
      phone: params.phone,
      company: activeCompanyId,
      send_sms: !!params.send_sms,
      employment_type: employmentType as "employee" | "contractor",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      candidate_id: teamMember?.candidate_id,
      title: teamMember?.title,
      pay_type: teamMember?.pay_type,
      resume_wizard_index: curIndex + 1,
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberBasicInfoForm) => {
    try {
      const cleanedParams = buildParams(params);
      // @ts-expect-error fix me
      const res = await MiterAPI.team_member.create(cleanedParams);

      if (res.error) throw new Error(res.error);

      setTeamMember(res);

      await refetchTeam(res._id);
      await sleep(100);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  const renderLinkedTeamMembersMessage = (type: "phone" | "email", linkedTeamMembers: LinkedTeamMember[]) => {
    if (!employmentType || Object.keys(dirtyFields).length === 0) return null;

    return (
      <LinkedTeamMembersWarning
        mode="create"
        type={type}
        linkedTeamMembers={linkedTeamMembers}
        employmentType={employmentType}
      />
    );
  };

  if (employmentType !== "contractor" && employmentType !== "employee") {
    Notifier.error("Invalid employment type");
    return <Navigate to="/team-members" />;
  }

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Basic Information</h2>
          <p className={styles["subheader-description"]}>Let&apos;s start with some basic information</p>
        </div>
        <div className={styles["team-member-first-and-middle-name"]}>
          <Formblock
            label="First name*"
            type="text"
            name="first_name"
            placeholder="First name"
            form={form}
            className="modal wizard"
            editing={true}
            val={vals.required}
          />
          <Formblock
            label="Middle name"
            type="text"
            name="middle_name"
            placeholder="Middle name"
            form={form}
            className="modal wizard"
            editing={true}
          />
        </div>

        <Formblock
          label="Last name*"
          type="text"
          name="last_name"
          placeholder="Last name"
          form={form}
          className="modal wizard"
          editing={true}
          val={vals.required}
        />
        <Formblock
          label="Email"
          type="text"
          name="email"
          placeholder="Enter email"
          form={form}
          className="modal wizard"
          editing={true}
          val={vals.emailNotRequired}
        />
        {renderLinkedTeamMembersMessage("email", linkedTeamMembers.email)}

        <Formblock
          label="Phone"
          type="phone"
          name="phone"
          placeholder="Enter phone"
          form={form}
          className="modal wizard"
          editing={true}
          defaultValue={formData.phone}
        />
        {renderLinkedTeamMembersMessage("phone", linkedTeamMembers.phone)}
        {formData.phone && (
          <Formblock
            text="Send the team member an SMS with a link to self-onboard"
            type="checkbox"
            name="send_sms"
            form={form}
            className="modal wizard"
            editing={true}
          />
        )}
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  teamMember?: Partial<TeamMember> | AggregatedTeamMember
): TeamMemberBasicInfoForm => {
  return {
    first_name: teamMember?.first_name || "",
    middle_name: teamMember?.middle_name || "",
    last_name: teamMember?.last_name || "",
    email: teamMember?.email || "",
    phone: teamMember?.phone || "",
  };
};
