import {
  useActiveCompany,
  useLookupPolicy,
  useLookupDepartment,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { AggregatedChangeRequest, CreateChangeRequestParams, Policy } from "dashboard/miter";
import { useCallback } from "react";
import { ChangeRequestPolicyField, ChangeRequestPolicyFields } from "backend/models/policy";
import { findItemPolicyRule } from "../approvals";
import { ChangeRequestParentType } from "backend/models/change-request";

type ChangeRequestPolicyItem = AggregatedChangeRequest | CreateChangeRequestParams;

type UseChangeRequestPolicy = {
  meetsPolicyRequirements: () => boolean;
  isFieldHidden: (field: ChangeRequestPolicyField) => boolean;
  isFieldVisible: (field: ChangeRequestPolicyField) => boolean;
  isFieldRequired: (field: ChangeRequestPolicyField) => boolean;
  policy: Policy | undefined;
  needsAttentionMessages: string[];
};

type UseChangeRequestPolicies = {
  buildPolicy: (item: ChangeRequestPolicyItem) => UseChangeRequestPolicy;
};

/** Reusable hook to get the policy and/or policy rule for an item */
export const useChangeRequestPolicy = (item: ChangeRequestPolicyItem): UseChangeRequestPolicy => {
  const { buildPolicy } = useChangeRequestPolicies(item.parent_type);
  return buildPolicy(item);
};

/** Reusable hook to get the policy and/or policy rule for an item */
export const useChangeRequestPolicies = (parentType: ChangeRequestParentType): UseChangeRequestPolicies => {
  /*********************************************************
   * Hooks
   **********************************************************/
  const company = useActiveCompany();
  const lookupPolicy = useLookupPolicy();
  const lookupDepartment = useLookupDepartment();
  const lookupTeamMember = useLookupTeam();

  const buildPolicy = useCallback((item: ChangeRequestPolicyItem): UseChangeRequestPolicy => {
    /*********************************************************
     * Policy getters
     **********************************************************/
    const getTeamMemberPolicy = (): Policy | undefined => {
      const teamMemberId = item.parent_type === "team_member" ? item.parent_id : undefined;
      const teamMember = lookupTeamMember(teamMemberId);
      if (parentType === "team_member") return lookupPolicy(teamMember?.team_member_change_request_policy_id);
    };

    const getDepartmentPolicy = (): Policy | undefined => {
      const department = lookupDepartment(item.department_id);
      if (parentType === "team_member") return lookupPolicy(department?.team_member_change_request_policy_id);
    };

    const getCompanyPolicy = (): Policy | undefined => {
      if (parentType === "team_member")
        return lookupPolicy(company?.settings?.team?.default_change_request_policy_id);
    };

    const policy = getTeamMemberPolicy() || getDepartmentPolicy() || getCompanyPolicy();

    const matchingPolicyRule = findItemPolicyRule(item, policy);

    // Helper function to determine the field's policy status based on the new and old policy settings.
    const getFieldPolicyStatus = (
      field: ChangeRequestPolicyField,
      status: "hidden" | "required"
    ): boolean => {
      if (!policy || !matchingPolicyRule?.fields) return false;
      const fields = matchingPolicyRule.fields as ChangeRequestPolicyFields;
      return fields[field] === status;
    };

    // Use the helper function to check if the field is required.
    const isFieldRequired = (field: ChangeRequestPolicyField): boolean => {
      return getFieldPolicyStatus(field, "required");
    };

    // Use the hidden function to check if the field is visible.
    const isFieldVisible = (field: ChangeRequestPolicyField): boolean => {
      return !isFieldHidden(field);
    };

    const isFieldHidden = (field: ChangeRequestPolicyField): boolean => {
      return getFieldPolicyStatus(field, "hidden");
    };

    const meetsPolicyRequirements = (): boolean => {
      if (!item) return true;

      // If this item has been kicked back, it doesn't meet the policy requirements
      if ("approval_stage" in item && item.approval_stage?.kick_back) return false;

      return isFieldRequired("notes") && !item?.notes;
    };

    const getNeedsAttentionMessages = () => {
      const reasons: string[] = [];

      // If this item has been kicked back, it doesn't meet the policy requirements
      if (item && "approval_stage" in item && item.approval_stage?.kick_back) {
        reasons.push(
          "Item kicked back: " +
            (item.approval_stage.kick_back.notes
              ? item.approval_stage.kick_back.notes
              : " please fix or contact the your approver.")
        );
      }

      const noNotes = isFieldRequired("notes") && !item?.notes;
      if (noNotes) reasons.push("Notes required");

      return reasons;
    };

    const needsAttentionMessages = getNeedsAttentionMessages();

    return {
      meetsPolicyRequirements,
      isFieldHidden,
      isFieldVisible,
      isFieldRequired,
      policy,
      needsAttentionMessages,
    };
  }, []);

  return { buildPolicy };
};
