import React from "react";
import { Label, Formblock } from "ui";
import { Option } from "packages/ui/form/Input";

export type DueType = "days_from_start" | "days_before_start";

export type DueDateFormProps = {
  days?: number;
  dueType?: DueType;
  onChange: (days: number, dueType: DueType) => void;
};

export const DueDateForm: React.FC<DueDateFormProps> = ({ days, dueType, onChange }) => {
  const handleDaysChange = (value: string) => {
    if (dueType != null) {
      onChange(Number(value), dueType);
    }
  };

  const handleDueTypeChange = (option: Option<DueType>) => {
    if (days != null) {
      onChange(days, option.value as DueType);
    }
  };

  return (
    <div>
      <Label label="Due" style={{ marginBottom: 7, fontSize: "0.9rem" }} />
      <div className="flex">
        <Formblock
          type="append"
          name="days"
          editing={true}
          defaultValue={days}
          className="modal"
          onChange={(e) => handleDaysChange(e.target.value)}
          style={{ width: 80, marginRight: 10 }}
          appendText="days"
        />
        <Formblock
          type="select"
          name="due_type"
          editing={true}
          defaultValue={dueType}
          options={DUE_TYPE_OPTIONS}
          className="modal"
          onChange={(option) => handleDueTypeChange(option as Option<DueType>)}
          style={{ width: 175 }}
        />
      </div>
    </div>
  );
};

const DUE_TYPE_OPTIONS: Option<DueType>[] = [
  { value: "days_from_start", label: "After start date" },
  { value: "days_before_start", label: "Before start date" },
];
