import { useEffect, useState } from "react";
import { TMPayType } from "backend/models/teamMember/team-member";

export type UsePayTypeArray = {
  loading: boolean;
  currentPayTypeArray: PayTypeMapping[];
  setCurrentPayTypesArray: (currentPayTypeArray: PayTypeMapping[]) => void;
  handleAddPayTypeMapping: () => void;
  handleChangeDesignation: (index: number, value: string) => void;
  handleChangePayType: (index: number, value: TMPayType) => void;
  handleDeletePayTypeMapping: (index: number) => void;
  handlePayTypeMapSave: (callback: (newPayTypeMap: Record<string, TMPayType>) => void) => Promise<void>;
};

export type PayTypeMapping = { designation: string; payType: TMPayType };

export const usePayTypeArray = (payTypeMapping?: Record<string, TMPayType | "default">): UsePayTypeArray => {
  const [loading, setLoading] = useState(false);
  const [currentPayTypeArray, setCurrentPayTypesArray] = useState<PayTypeMapping[]>([]);

  const setPayTypes = () => {
    if (payTypeMapping) {
      const mappingArray = Object.keys(payTypeMapping)
        .map(
          (designation): PayTypeMapping => ({
            designation,
            payType: payTypeMapping[designation] as TMPayType,
          })
        )
        .sort((a, b) => {
          return a.designation.localeCompare(b.designation);
        });

      setCurrentPayTypesArray(mappingArray);
    }
  };

  const handleAddPayTypeMapping = () => {
    const newPayTypeMappingArray = [...currentPayTypeArray];
    newPayTypeMappingArray.push({ designation: "", payType: "hourly" });
    setCurrentPayTypesArray(newPayTypeMappingArray);
  };

  const handleChangeDesignation = (index: number, value: string) => {
    const newPayTypeMappingArray = [...currentPayTypeArray];
    newPayTypeMappingArray[index]!.designation = value;
    setCurrentPayTypesArray(newPayTypeMappingArray);
  };

  const handleChangePayType = (index: number, value: TMPayType) => {
    const newPayTypeMappingArray = [...currentPayTypeArray];
    newPayTypeMappingArray[index]!.payType = value;
    setCurrentPayTypesArray(newPayTypeMappingArray);
  };

  const handleDeletePayTypeMapping = (index: number) => {
    const newPayTypeMappingArray = [...currentPayTypeArray];
    newPayTypeMappingArray.splice(index, 1);
    setCurrentPayTypesArray(newPayTypeMappingArray);
  };

  const handlePayTypeMapSave = async (callback: (newPayTypeMap: Record<string, TMPayType>) => void) => {
    setLoading(true);
    const newPayTypeMap = currentPayTypeArray.reduce((acc, { designation, payType }) => {
      if (designation && payType) {
        acc[designation] = payType;
      }
      return acc;
    }, {} as Record<string, TMPayType>);
    callback(newPayTypeMap);
    setLoading(false);
  };

  useEffect(() => {
    setPayTypes();
  }, []);

  return {
    currentPayTypeArray,
    setCurrentPayTypesArray,
    handleAddPayTypeMapping,
    handleChangeDesignation,
    handleChangePayType,
    handleDeletePayTypeMapping,
    loading,
    handlePayTypeMapSave,
  };
};
