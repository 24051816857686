import React, { useEffect } from "react";
import styles from "./NotFound.module.css";
import Balloon from "../../assets/404.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthToken } from "dashboard/hooks/atom-hooks";

const NotFound: React.FC = () => {
  const authToken = useAuthToken();
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/", { replace: true });
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  if (!authToken) return <Navigate to="/login" replace />;

  return (
    <div className={styles["not-found-container"]}>
      <div className={styles["not-found"]}>
        <img src={Balloon} alt="404" />
        <h1>Whoops! It looks like you&apos;re lost.</h1>
        <p className={styles["subtitle"]}>
          We can&apos;t seem to find the page you&apos;re looking for. Click the button below to go home or
          just wait a few seconds and we&apos;ll take you there!
        </p>
        <Link className={"button-2 " + styles["dashboard-btn"]} to={"/"}>
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
