import React, { useState, useEffect } from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TableFooter, { DEFAULT_LOW_RESULTS_PER_PAGE } from "./TableFooter";
import TableActions from "./TableActions";
import Row from "./TableRow";
import { ActionButtonConfig, ColumnConfigs, SortConfig, TableEntry } from "./types";
import { Loader, Toggler, TogglerProps } from "ui";
import "./Table.css";

import emptyState from "dashboard/assets/empty.png";
import { FiltersMap } from "./filter/Filter";
import { notNullish } from "miter-utils";

export type TogglerConfig<T extends string = string> = {
  config: TogglerProps<T>["config"];
  path: TogglerProps<T>["active"];
  handler: TogglerProps<T>["toggle"];
  secondary: TogglerProps<T>["secondary"];
};

type TableProps<D extends TableEntry, P extends string = string> = {
  columns: ColumnConfigs;
  onClick?: (entry: D) => void;
  onClickBtnConfig?: React.ComponentProps<typeof Row>["onClickBtnConfig"];
  data?: D[];
  onSelect?: (ids: string[]) => void;
  selectedRows?: string[];
  emptyHeader?: string;
  hideSearch?: boolean;
  maxBodyHeight?: number;
  hideSelect?: boolean;
  hideFooterButtons?: boolean;
  hideFooterText?: boolean;
  hideToggleVerticalSpacer?: boolean;
  hideActionsVerticalSpacer?: boolean;
  hideTableVerticalSpacer?: boolean;
  smallTableVerticalSpacer?: boolean;
  showTotalsRow?: boolean;
  buttonsConfig?: {
    defaultButtons: ActionButtonConfig[];
    dynamicButtons: ActionButtonConfig[];
  };
  togglerConfig?: TogglerConfig<P>;
  activeToggle?: string;
  filters?: FiltersMap;
  searchHandler?: React.ComponentProps<typeof TableActions>["searchHandler"];
  resourceName?: string;
  loading?: boolean;
  sortColumn?: SortConfig;
  onSort?: (col: SortConfig | undefined) => void;
  disableSort?: boolean;
  paginated?: boolean;
  retrieveNextPage?: () => Promise<void>;
  resultCount?: number;
  lowResultsPerPage?: number;
  highResultsPerPage?: number;
  children?: React.ReactNode;
  EmptyComponent?: React.FC;
  tableTitle?: string;
  innerWrapperClassName?: string;
  hideEmptyStateIcon?: boolean;
  rightHandContent?: string | React.ReactElement;
};

/** @deprecated Use TableV2 instead. */
const Table = <D extends TableEntry, P extends string = string>(
  props: TableProps<D, P>
): React.ReactElement | null => {
  const {
    columns,
    onClick,
    onClickBtnConfig,
    onSelect,
    selectedRows = [],
    hideSelect,
    hideFooterButtons,
    hideFooterText,
    hideSearch,
    hideToggleVerticalSpacer,
    hideActionsVerticalSpacer,
    hideTableVerticalSpacer,
    hideEmptyStateIcon,
    smallTableVerticalSpacer,
    showTotalsRow,
    buttonsConfig,
    togglerConfig,
    activeToggle,
    filters,
    searchHandler,
    maxBodyHeight,
    resourceName,
    loading,
    sortColumn,
    onSort,
    disableSort,
    paginated,
    retrieveNextPage,
    lowResultsPerPage,
    highResultsPerPage,
    EmptyComponent,
    tableTitle,
    data,
    innerWrapperClassName,
    rightHandContent,
  } = props;

  // Inherit from parent if specified (usually during paginated responses). Otherwise, calculate with length of data.
  const resultCount = (paginated ? props.resultCount : data?.length) || 0;

  const [sortedData, setSortedData] = useState(data);
  const [numResultsPerPage, setNumResultsPerPage] = useState(
    lowResultsPerPage || DEFAULT_LOW_RESULTS_PER_PAGE
  );
  const [page, setPage] = useState(1);
  const [activeResults, setActiveResults] = useState<D[] | null>(null);

  // Generally, the table will handle all sort state. But some parent components may want to pass in
  // a default sort column.
  const [tableSortColumn, setTableSortColumn] = useState<SortConfig | undefined>(
    sortColumn && !disableSort ? sortColumn : undefined
  );
  const [bulkSelected, setBulkSelected] = useState(false);

  useEffect(() => {
    setTableSortColumn(sortColumn);
  }, [sortColumn?.sort_field || sortColumn?.field]);

  useEffect(() => {
    setBulkSelected(!!selectedRows.length && selectedRows.length === data?.length);
  }, [selectedRows.length, data?.length]);

  // Use the field specified by the sortColumn and look at the type of the column to make sure sort works properly.
  // This sortFunction assumes each item to compare is the row structure used by jobs/timesheets/teams.
  const sortFunction = (a, b) => {
    if (!tableSortColumn) return 0;

    const sortField = tableSortColumn.sort_field || tableSortColumn.field;
    const sortType = tableSortColumn.sort_type || tableSortColumn.type;
    let aval = a[sortField];
    let bval = b[sortField];

    if (sortType === "number" || sortType === "timestamp") {
      aval = parseFloat(aval);
      bval = parseFloat(bval);
    }

    if (aval === bval) {
      return 0;
    } else {
      return aval < bval ? -1 : 1;
    }
  };

  const setSortOrder = (col: SortConfig | undefined) => {
    setTableSortColumn(col);
    // Some parent components may want to save sort state
    // or more commonly, the parent is using paginated data and needs
    // to call the backend on sort.
    onSort?.(col);
  };

  const handlePrevious = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  const handleNext = async () => {
    // Go to next page if there are more results.
    if (resultCount / numResultsPerPage > page) {
      if (paginated && retrieveNextPage && numResultsPerPage * (page + 1) > (data?.length || 1)) {
        // If paginated, check whether next page would extend past end of paginated data.

        // Need to wait until have data before updating page.
        // Otherwise, page reverts to 0 in useEffect to avoid indexing into non-existent data.
        await retrieveNextPage();
      }
      setPage(page + 1);
    }
  };

  const handleSelect = (id: string, isSelected: boolean) => {
    let newIds = [...selectedRows];
    if (isSelected) {
      newIds.push(id);
      if (newIds.length === data?.length) {
        setBulkSelected(true);
      }
    } else {
      newIds = newIds.filter((row) => row !== id);
      if (bulkSelected) {
        setBulkSelected(false);
      }
    }
    onSelect?.(newIds);
  };

  const handleBulkSelect = (isSelected: boolean) => {
    setBulkSelected(isSelected);
    if (isSelected) {
      const allIds = data?.map((entry) => entry._id) || [];
      onSelect?.(allIds);
    } else {
      onSelect?.([]);
    }
  };

  const handlePageAndDataChange = async () => {
    // Before setting active results, need to make sure there are active results for that page.
    // This handles the case where a toggle changes the number of results, but the previous page extends
    // beyond the length of new data.
    const indexOfFirstItemOnCurrentPage = (page - 1) * numResultsPerPage;
    const exclusiveUpperBoundOfCurrentPage = page * numResultsPerPage;

    if (sortedData && indexOfFirstItemOnCurrentPage >= sortedData.length) {
      // Check to see if we want to be on a certain page that should theoretically have data but we don't have
      // the data in hand just yet (because of pagination)
      if (indexOfFirstItemOnCurrentPage + 1 <= resultCount && retrieveNextPage) {
        await retrieveNextPage();
      } else {
        setPage(1);
        setActiveResults(sortedData ? sortedData.slice(0, numResultsPerPage) : null);
      }
    } else {
      setActiveResults(
        sortedData ? sortedData.slice(indexOfFirstItemOnCurrentPage, exclusiveUpperBoundOfCurrentPage) : null
      );
    }
  };

  useEffect(() => {
    setPage(1);
  }, [tableSortColumn?.field, tableSortColumn?.order]);

  useEffect(() => {
    // Assumption is data is small here, so can residually sort and then reverse cheaply.
    const tempData = data?.slice();
    if (tableSortColumn && !paginated && tempData) {
      tempData.sort(sortFunction);
      if (tableSortColumn.order === "desc") {
        tempData.reverse();
      }
    }
    setSortedData(tempData);
  }, [tableSortColumn?.field, tableSortColumn?.order, data]);

  useEffect(() => {
    handlePageAndDataChange();
  }, [page, numResultsPerPage, sortedData]);

  const defaultButtons: ActionButtonConfig[] = [
    // Add a button to clear the sort, if there is a sort column.
    tableSortColumn && !disableSort && tableSortColumn !== sortColumn
      ? { name: "Clear sort", style: "button-1", onClick: () => setSortOrder(undefined) }
      : undefined,

    ...(buttonsConfig?.defaultButtons || []),
  ].filter(notNullish);

  const innerWrapperStyle = maxBodyHeight
    ? {
        overflowY: "auto" as const,
        maxHeight: maxBodyHeight,
      }
    : {};

  return (
    <div className="table-wrapper">
      <div className="transparent">
        {!hideToggleVerticalSpacer && togglerConfig && <div className="vertical-spacer-medium"></div>}
        {togglerConfig && (
          <Toggler
            config={togglerConfig.config}
            active={activeToggle || togglerConfig.path}
            toggle={togglerConfig.handler}
            secondary={togglerConfig.secondary}
            rightHandContent={rightHandContent}
          />
        )}
        {!hideActionsVerticalSpacer && (buttonsConfig || searchHandler) && (
          <div className="vertical-spacer"></div>
        )}
        {(buttonsConfig || searchHandler || tableTitle) && (
          <TableActions
            defaultButtons={defaultButtons}
            dynamicButtons={buttonsConfig ? buttonsConfig.dynamicButtons : []}
            selectedCount={!hideSelect ? selectedRows.length : 0}
            columns={columns}
            hideSearch={hideSearch}
            filters={filters}
            searchHandler={searchHandler}
            resourceName={resourceName}
            tableTitle={tableTitle}
          />
        )}
        {!hideTableVerticalSpacer && !smallTableVerticalSpacer && <div className="vertical-spacer"></div>}
        {smallTableVerticalSpacer && <div className="vertical-spacer-small"></div>}
        <div
          className={"table-inner-wrapper " + innerWrapperClassName}
          style={{ overflowX: "auto", ...innerWrapperStyle }}
        >
          <table className={`table`}>
            <TableHeader
              columns={columns}
              sortColumn={tableSortColumn}
              onSort={setSortOrder}
              disableSort={disableSort}
              onSelect={handleBulkSelect}
              onClickBtnConfig={onClickBtnConfig}
              isSelected={bulkSelected}
              hideSelect={hideSelect}
              disableSelect={data?.every((v) => v.disableSelect)}
            />

            {activeResults && activeResults.length > 0 && !loading && (
              <TableBody
                columns={columns}
                data={activeResults}
                onClick={onClick}
                onClickBtnConfig={onClickBtnConfig}
                onSelect={handleSelect}
                selected={selectedRows}
                hideSelect={hideSelect}
                maxBodyHeight={maxBodyHeight}
                showTotalsRow={showTotalsRow && data && data.length <= numResultsPerPage}
              />
            )}
          </table>
        </div>
        <div className="width-100">{loading && <Loader />}</div>
        {activeResults?.length === 0 &&
          !loading &&
          (EmptyComponent ? (
            <EmptyComponent />
          ) : (
            <div className="table-empty-state-wrapper" style={{ width: "100%" }}>
              {!hideEmptyStateIcon && <img className="table-empty-state-img" src={emptyState} />}
              <div className="table-empty-state-header">
                {props.emptyHeader ? props.emptyHeader : "No results found"}
              </div>
              {props.children}
            </div>
          ))}
        {resultCount > 0 && (
          <TableFooter
            resultCount={resultCount}
            activeResults={activeResults}
            onNext={handleNext}
            onPrevious={handlePrevious}
            page={page}
            setPage={setPage}
            lowNumResultsPerPage={lowResultsPerPage}
            highNumResultsPerPage={highResultsPerPage}
            numResultsPerPage={numResultsPerPage}
            setNumResultsPerPage={setNumResultsPerPage}
            hideFooterButtons={hideFooterButtons}
            hideFooterText={hideFooterText}
            resourceName={resourceName}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
