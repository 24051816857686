import React, { useMemo } from "react";
import { AggregatedMiterEarning, AggregatedTeamMember, TimesheetSection } from "dashboard/miter";
import { ActionModal } from "ui";
import { PayPeriod } from "./TimesheetsByPayPeriod";
import { useLookupJob, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { DateTime } from "luxon";
import { TimesheetSectionsTable } from "./TimesheetSectionsTable";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { PaymentWarning } from "backend/utils/payroll/types";
import { ArrowLeft, ArrowRight } from "phosphor-react";

type EarningDataLookup = Pick<
  AggregatedMiterEarning,
  "job" | "activity" | "classification_override" | "team_member"
>;

export type TimesheetListModalProps = {
  jobId?: string;
  teamMemberId?: string;
  payPeriod: PayPeriod;
  warnings: PaymentWarning[];
  onHide: () => void;
  moveTo: (direction: "previous" | "next") => void;
  tablePosition: "first" | "middle" | "last" | "none";
  payPeriodTeamMembers?: AggregatedTeamMember[];
  earnings: AggregatedMiterEarning[];
  timesheetSections: TimesheetSection[];
  refreshTimesheets: () => void;
};

export const TimesheetPayPeriodSectionsModal: React.FC<TimesheetListModalProps> = ({
  jobId,
  teamMemberId,
  payPeriod,
  warnings,
  onHide,
  moveTo,
  tablePosition,
  payPeriodTeamMembers,
  earnings,
  timesheetSections,
  refreshTimesheets,
}) => {
  const lookupJob = useLookupJob();
  const lookupTeam = useLookupTeam();

  const lookupJobData = useMemo(() => {
    return earnings.reduce((acc, earning) => {
      if (!earning.timesheet) return acc;

      acc.set(earning.timesheet, {
        job: earning.job,
        team_member: earning.team_member,
      });

      return acc;
    }, new Map<string, EarningDataLookup>());
  }, [earnings]);

  const filteredTimesheetSections = timesheetSections.filter(
    (section) => {
      const earningData = lookupJobData.get(section.timesheet);
      if (!earningData) {
        return false;
      } else if (jobId) {
        return earningData.job?._id === jobId;
      } else if (teamMemberId) {
        return earningData.team_member?._id === teamMemberId;
      } else {
        return true;
      }
    },
    [payPeriodTeamMembers, teamMemberId, jobId]
  );

  const modalTitle = useMemo(() => {
    const parsedPeriodStart = DateTime.fromISO(payPeriod.periodStart);
    const parsedPeriodEnd = DateTime.fromISO(payPeriod.periodEnd);

    const formattedPeriodStart = parsedPeriodStart.toFormat("LLL dd");
    const formattedPeriodEnd = parsedPeriodEnd.toFormat("LLL dd, yyyy");

    if (jobId) {
      const job = lookupJob(jobId);
      return `Timesheets Sections for ${job?.name} - ${formattedPeriodStart} to ${formattedPeriodEnd}`;
    }
    if (teamMemberId) {
      const team = lookupTeam(teamMemberId);
      return `Timesheets Sections for ${team?.full_name} - ${formattedPeriodStart} to ${formattedPeriodEnd}`;
    }
  }, [payPeriod, jobId, teamMemberId, lookupJob, lookupTeam]);

  const grouping = useMemo(() => {
    if (jobId) {
      return "job";
    } else if (teamMemberId) {
      return "team member";
    } else {
      return "group";
    }
  }, [jobId, teamMemberId]);

  const actions: ActionLink[] = useMemo(
    () => [
      {
        label: `Previous ${grouping}`,
        icon: <ArrowLeft style={{ marginRight: 3 }} />,
        action: () => moveTo("previous"),
        shouldShow: () => tablePosition !== "first" && tablePosition !== "none",
        secondary: true,
        style: { width: "max-content" },
      },
      {
        label: `Next ${grouping}`,
        icon: <ArrowRight style={{ marginRight: 3 }} />,
        action: () => moveTo("next"),
        shouldShow: () => tablePosition !== "last" && tablePosition !== "none",
        secondary: true,
        style: { width: "max-content" },
      },
    ],
    [tablePosition, moveTo]
  );

  return (
    <ActionModal
      onSubmit={onHide}
      submitText="Close"
      showSubmit={true}
      onHide={onHide}
      headerText={modalTitle || "Timesheets"}
      actions={actions}
      actionsType={"button"}
      wrapperStyle={{ width: "80vw" }}
      bodyStyle={{ minHeight: "70vh" }}
    >
      <TimesheetSectionsTable
        timesheetSections={filteredTimesheetSections}
        loading={false}
        earnings={earnings}
        warnings={warnings.filter((w) => w.team_member_id === teamMemberId)}
        containerClassName={"timesheet-pay-period-list-table"}
        refreshTimesheets={refreshTimesheets}
      />
    </ActionModal>
  );
};
