import CustomFieldsTable from "dashboard/components/custom-fields/CustomFieldsTable";
import ChecklistsTable from "dashboard/pages/team-members/onboarding/ChecklistsTable";
import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDebouncedCallback } from "use-debounce";
import { CompanySettings } from "backend/models/company";
import { ReviewerPermissions } from "./ReviewerPermissions";
import TenureAlerts from "./TenureAlerts";
import { CrewsTable } from "dashboard/components/crews/CrewsTable";
import { deparameterize, EEO_FIELDS, useQuery } from "miter-utils";
import { Formblock, Label } from "ui";
import { SettingsCard } from "ui/settings/SettingsCard";
import {
  useActiveCompany,
  useActiveCompanyId,
  useCompanyUserOptions,
  usePermissionGroups,
} from "dashboard/hooks/atom-hooks";
import { useForm } from "react-hook-form";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";

import CertificationTypesTable from "dashboard/components/certifications/CertificationTypesTable";
import { useHasAccessToChecklists } from "dashboard/gating";
import TeamChangeRequestPolicySettings from "dashboard/components/change-requests/TeamChangeRequestPolicySettings";
import { capitalize } from "lodash";
import { Option } from "ui/form/Input";

export const TeamSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const settings = useMemo(() => activeCompany?.settings.team, [activeCompany]);
  const [tempSettings, setTempSettings] = useState<CompanySettings["team"]>();
  const [loading, setLoading] = useState(false);
  const userOptions = useCompanyUserOptions();
  const permissionGroups = usePermissionGroups();
  const form = useForm();
  const hasAccessToChecklists = useHasAccessToChecklists();

  const crewsRef = useRef<HTMLDivElement>(null);
  const certificationsRef = useRef<HTMLDivElement>(null);

  const [scrollCount, setScrollCount] = useState(0);
  const [adminOptions, setAdminOptions] = useState<Option<string>[]>([]);
  const [adminOptionsLoading, setAdminOptionsLoading] = useState(true);

  const query = useQuery();
  const section = query.get("section");

  const teamPortalTabs: { setting: string; label: string; val?: boolean }[] = [
    { setting: "onboarding", label: "Onboarding", val: tempSettings?.enabled_portal_tabs?.onboarding },
    {
      setting: "payment_info",
      label: "Payment Info",
      val: tempSettings?.enabled_portal_tabs?.payment_info,
    },
    { setting: "time_off", label: "Time Off", val: tempSettings?.enabled_portal_tabs?.time_off },
  ];
  if (isActiveClaspCompany(activeCompany)) {
    teamPortalTabs.push({
      setting: "benefits_admin",
      label: "Benefits",
      val: tempSettings?.enabled_portal_tabs?.benefits_admin,
    });
  }
  teamPortalTabs.push(
    { setting: "forms", label: "Forms", val: tempSettings?.enabled_portal_tabs?.forms },
    {
      setting: "certifications",
      label: "Certifications",
      val: tempSettings?.enabled_portal_tabs?.certifications,
    },
    { setting: "documents", label: "Documents", val: tempSettings?.enabled_portal_tabs?.documents },
    { setting: "profile", label: "Profile", val: tempSettings?.enabled_portal_tabs?.profile },
    { setting: "help", label: "Help", val: tempSettings?.enabled_portal_tabs?.help }
  );

  const demographicReportingFields = EEO_FIELDS;

  useEffect(() => {
    setTempSettings(settings);
  }, [settings]);

  // For some reason the divs with refs weren't being rendered, so we wait a second to trigger the useEffect call
  useEffect(() => {
    setTimeout(() => {
      setScrollCount(scrollCount + 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (section === "crews") {
      crewsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (section === "certifications") {
      certificationsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [query, section, crewsRef, certificationsRef, scrollCount]);

  useEffect(() => {
    getAdminOptions();
  }, [permissionGroups, userOptions]);

  const getAdminOptions = async () => {
    try {
      const superAdminPermissionGroup = permissionGroups.filter((group) => group.super_admin)[0];
      if (!superAdminPermissionGroup) return [];
      const { roles, teamMembers } = await MiterAPI.permission_groups.retrieve_members(
        superAdminPermissionGroup._id
      );
      const rolesUserIds = roles.map((role) => role.user);
      const teamMembersUserIds = teamMembers.map((teamMember) => teamMember.user);
      setAdminOptions(
        userOptions.filter(
          (user) => rolesUserIds.includes(user.value) || teamMembersUserIds.includes(user.value)
        )
      );
    } catch (e) {
      console.error("Error fetching super admin members");
    } finally {
      setAdminOptionsLoading(false);
    }
  };

  const updateCompany = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        "settings.team": tempSettings,
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
    } catch (e) {
      Notifier.error("There was an error updating team settings. Our engineers are looking into it!");
    }
    fetchUserData();
    setLoading(false);
  };

  const updateCompanyDebounced = useDebouncedCallback(() => {
    updateCompany();
  }, 1000);

  const handleSettingsChange = (newFields: Partial<CompanySettings["team"]>) => {
    setTempSettings((prev) => {
      if (!prev) return prev;
      return { ...prev, ...newFields };
    });
    updateCompanyDebounced();
  };

  const handleEeoClick = (e) => {
    handleSettingsChange({ enable_demographic_reporting: e.target.checked });
  };

  if (!settings || !tempSettings) return null;

  return (
    <div>
      <Helmet>
        <title>Team settings | Miter</title>
      </Helmet>
      <div className="vertical-spacer-large"></div>
      <div>Configure HR, employee data, and employee profile-related settings.</div>
      <div className="vertical-spacer-small"></div>
      <div className="billing-card-wrapper">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Demographic + EEO reporting</div>
        <div className="vertical-spacer-small"></div>
        <div className="flex">
          <input
            type="checkbox"
            name="enable_demographic_reporting"
            checked={tempSettings?.enable_demographic_reporting}
            onChange={handleEeoClick}
          />
          <span
            style={{ marginLeft: 6 }}
          >{`Enable the collection of demographic data for EEO and workforce reporting purposes.`}</span>
        </div>
        {tempSettings?.enable_demographic_reporting && (
          <div>
            <div className="vertical-spacer" />
            <Label label="Enabled demographic questions" style={{ width: 300 }} />
            <div className="vertical-spacer-small" />

            {demographicReportingFields.map((field) => {
              return (
                <div className="flex" style={{ marginBottom: 3 }}>
                  <input
                    type="checkbox"
                    name={"enabled_demographic_questions." + field}
                    checked={tempSettings?.enabled_demographic_questions?.[field]}
                    onChange={(e) =>
                      handleSettingsChange({
                        enabled_demographic_questions: {
                          ...tempSettings.enabled_demographic_questions,
                          [field]: e.target.checked,
                        },
                      })
                    }
                  />
                  <span style={{ marginLeft: 6 }}>{capitalize(deparameterize(field))}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <ReviewerPermissions settings={tempSettings} handleSettingsChange={handleSettingsChange} />
      <div className="billing-card-wrapper">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Onboarding</div>
        <div className="vertical-spacer-small"></div>
        <div className="flex">
          <input
            type="checkbox"
            name="onboarding.enable_online_i9s"
            checked={tempSettings?.onboarding?.enable_online_i9s}
            onChange={(e) => handleSettingsChange({ onboarding: { enable_online_i9s: e.target.checked } })}
          />
          <span style={{ marginLeft: 6 }}>{`Enable online I-9s`}</span>
        </div>
        {tempSettings?.onboarding?.enable_online_i9s && (
          <div style={{ marginTop: 5 }}>
            Note: for any I-9s created outside of Miter, upload them through the team member profile. Team
            members without an associated I-9 will be prompted to create another in the team portal.
          </div>
        )}
      </div>
      <SettingsCard title={"Dashboard"}>
        <div className="flex">
          <input
            type="checkbox"
            name="show_friendly_id_in_dashboard"
            checked={tempSettings?.show_friendly_id_in_dashboard}
            onChange={(e) => handleSettingsChange({ show_friendly_id_in_dashboard: e.target.checked })}
          />
          <span style={{ marginLeft: 6 }}>
            Prefix the team member&apos;s name with their ID in the dashboard when selecting a job for a
            timesheet, expense, etc.
          </span>
        </div>
      </SettingsCard>
      <SettingsCard title={"Mobile"}>
        <div className="flex">
          <input
            type="checkbox"
            name="onboarding.enable_mobile_employee_directory"
            checked={tempSettings?.enable_mobile_employee_directory}
            onChange={(e) => handleSettingsChange({ enable_mobile_employee_directory: e.target.checked })}
          />
          <span
            style={{ marginLeft: 6 }}
          >{`Have the company's team directory be visible on the mobile app.`}</span>
        </div>
      </SettingsCard>
      <SettingsCard
        title={"Team portal"}
        subtitle="Enable/Disable which tabs will be available to team members in team portal"
      >
        {teamPortalTabs.map((tab) => {
          return (
            <div className="flex" style={{ marginBottom: 3 }}>
              <input
                type="checkbox"
                name={"enabled_portal_tabs." + tab.setting}
                checked={tab.val}
                onChange={(e) =>
                  handleSettingsChange({
                    enabled_portal_tabs: {
                      ...tempSettings.enabled_portal_tabs,
                      [tab.setting]: e.target.checked,
                    },
                  })
                }
              />
              <span style={{ marginLeft: 6 }}>{tab.label}</span>
            </div>
          );
        })}
      </SettingsCard>
      <div className="billing-card-wrapper">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Notifications</div>
        <div className="vertical-spacer-small"></div>
        <h3 className="settings-subheader">Changes to employee profile</h3>
        <div>
          <input
            type="checkbox"
            name="notify_on_team_profile_change"
            checked={tempSettings?.notify_on_team_profile_change?.enabled}
            onChange={(e) =>
              handleSettingsChange({
                notify_on_team_profile_change: {
                  enabled: e.target.checked,
                  users_to_notify: tempSettings?.notify_on_team_profile_change?.users_to_notify || [],
                },
              })
            }
          />
          <span style={{ marginLeft: 6 }}>
            Send email alerts to super admins when a team member updates their profile from the portal.
          </span>
          <div style={{ width: "75%" }}>
            {tempSettings?.notify_on_team_profile_change?.enabled && !adminOptionsLoading && (
              <Formblock
                label="Admins to notify for profile changes"
                options={adminOptions}
                type="multiselect"
                name="users_to_notify_on_profile_submission"
                form={form}
                className="modal wizard"
                editing={true}
                height="unset"
                style={{ marginTop: 10 }}
                onChange={(e) => {
                  handleSettingsChange({
                    notify_on_team_profile_change: {
                      users_to_notify: (e || []).map((user) => user.value),
                      enabled: true,
                    },
                  });
                }}
                defaultValue={tempSettings?.notify_on_team_profile_change?.users_to_notify}
              />
            )}
          </div>
        </div>
        <div className="vertical-spacer-small"></div>
        <h3 className="settings-subheader">Changes to employee tax documents</h3>
        <div>
          <input
            type="checkbox"
            name="notify_on_team_tax_document_change"
            checked={tempSettings?.notify_on_tax_document_change?.enabled}
            onChange={(e) =>
              handleSettingsChange({
                notify_on_tax_document_change: {
                  enabled: e.target.checked,
                  users_to_notify: tempSettings?.notify_on_tax_document_change?.users_to_notify || [],
                },
              })
            }
          />
          <span style={{ marginLeft: 6 }}>
            Send email alerts to super admins when tax documents (W-4s, etc.) are updated for a team member.
          </span>
          <div style={{ width: "75%" }}>
            {tempSettings?.notify_on_tax_document_change?.enabled && (
              <Formblock
                label="Admins to notify for tax document changes"
                options={adminOptions}
                type="multiselect"
                name="users_to_notify_on_tax_document_submission"
                form={form}
                className="modal wizard"
                editing={true}
                height="unset"
                style={{ marginTop: 10 }}
                onChange={(e) => {
                  handleSettingsChange({
                    notify_on_tax_document_change: {
                      users_to_notify: (e || []).map((user) => user.value),
                      enabled: true,
                    },
                  });
                }}
                defaultValue={tempSettings?.notify_on_tax_document_change?.users_to_notify}
              />
            )}
          </div>
        </div>
        <div className="vertical-spacer-small"></div>
        <h3 className="settings-subheader">Birthday alerts</h3>
        <div className="flex">
          <input
            type="checkbox"
            name="onboarding.enable_birthday_alerts"
            checked={tempSettings?.enable_birthday_alerts}
            onChange={(e) => handleSettingsChange({ enable_birthday_alerts: e.target.checked })}
          />
          <span style={{ marginLeft: 6 }}>
            Send email alerts to super admins when a team member&apos;s birthday is coming up.
          </span>
        </div>
        <div className="vertical-spacer"></div>
        <h3 className="settings-subheader">Tenure alerts</h3>
        <TenureAlerts teamSettings={tempSettings} onChange={handleSettingsChange} loading={loading} />
      </div>
      <div className="billing-card-wrapper" ref={crewsRef}>
        <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Crews</div>
        <div className="vertical-spacer-small"></div>
        <h3 className="settings-subheader">Mobile settings</h3>
        <div className="flex">
          <input
            type="checkbox"
            name="crews.supervisors_see_only_crew_on_mobile"
            checked={tempSettings?.crews?.supervisors_see_only_crew_on_mobile}
            onChange={(e) =>
              handleSettingsChange({ crews: { supervisors_see_only_crew_on_mobile: e.target.checked } })
            }
          />
          <span style={{ marginLeft: 6 }}>
            Crew leads only see their team members from their crew(s) on mobile (instead of all team members).
          </span>
        </div>
        <div className="vertical-spacer"></div>
        <CrewsTable />
      </div>
      <div className="billing-card-wrapper">
        <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Custom fields</div>
        <CustomFieldsTable parentType="team_member" />
      </div>

      <div ref={certificationsRef} className="billing-card-wrapper">
        <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Certifications & licenses</div>
        <CertificationTypesTable />
      </div>

      {hasAccessToChecklists ? (
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Onboarding checklists</div>
          <ChecklistsTable />
        </div>
      ) : null}
      <TeamChangeRequestPolicySettings handleTeamSettingsChange={handleSettingsChange} />
    </div>
  );
};
