import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { Copy, Plus, TrashSimple } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { BasicModal, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { PermissionGroup } from "dashboard/miter";
import { usePermissionGroups, useRefetchPermissionGroups } from "dashboard/hooks/atom-hooks";
import PermissionGroupModal from "./PermissionGroupModal";
import { useNavigate, useParams } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type PermissionGroupTableEntry = PermissionGroup;

export const PermissionGroupsTable: React.FC = () => {
  const { can, cannot } = useMiterAbilities();

  const permissionGroups = usePermissionGroups();
  const refetchPermissionGroups = useRefetchPermissionGroups();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<PermissionGroupTableEntry>();
  const [duplicating, setDuplicating] = useState(false);
  const [archiveConfirmation, setArchiveConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState<PermissionGroupTableEntry[]>([]);

  const [groupMemberCounts, setGroupMemberCounts] = useState<Record<string, string[]>>();

  const tableData: PermissionGroupTableEntry[] | undefined = useMemo(() => {
    if (!groupMemberCounts) return;

    return permissionGroups.slice().sort((a, b) => {
      if (a.super_admin && !b.super_admin) return -1;
      if (!a.super_admin && b.super_admin) return 1;
      return 0;
    });
  }, [permissionGroups, groupMemberCounts]);

  useEffect(() => {
    getPermissionGroupMemberCounts();
  }, [permissionGroups]);

  useEffect(() => {
    setEditing(permissionGroups.find((group) => group._id === params.id));
  }, [params.id]);

  const hideModal = () => {
    setCreating(false);
    setEditing(undefined);
    refetchPermissionGroups();
    navigate("/settings/permissions");
  };

  const handleClick = (entry: PermissionGroupTableEntry) => {
    navigate(`/settings/permissions/${entry._id}`);
  };

  const getPermissionGroupMemberCounts = async () => {
    try {
      const groupMemberCounts = await Promise.all(
        permissionGroups.map(async (group) => {
          const members = await MiterAPI.permission_groups.retrieve_members(group._id);
          return { [group._id]: members.roles.length + members.teamMembers.length };
        })
      );

      return setGroupMemberCounts(Object.assign({}, ...groupMemberCounts));
    } catch (e: $TSFixMe) {}
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add permission group",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("company:permission_groups:manage"),
      },
    ],
    [can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Duplicate",
        className: "button-1 no-margin table-button",
        action: () => duplicatePermissionGroups(),
        icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("company:permission_groups:manage") && !selected.some((s) => s.super_admin),
        loading: duplicating,
      },
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiveConfirmation(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("company:permission_groups:manage") && !selected.some((s) => s.super_admin),
        loading: deleting,
      },
    ],
    [selected, can, duplicating, deleting]
  );

  const columns: ColumnConfig<PermissionGroupTableEntry>[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        dataType: "string",
      },
      {
        headerName: "Members",
        field: "members",
        dataType: "number",
        valueGetter: (params) => groupMemberCounts?.[params.data?._id || ""] || 0,
      },
    ],
    [groupMemberCounts]
  );

  const archiveSelectedPermissionGroups = async () => {
    if (cannot("company:permission_groups:manage", { showToast: true })) return;

    setDeleting(true);
    try {
      await Promise.all(selected.map((s) => MiterAPI.permission_groups.delete(s._id)));
      await refetchPermissionGroups();
      setSelected([]);
      setArchiveConfirmation(false);
      Notifier.success("PermissionGroup(s) deleted successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the selected crew(s). We're looking into it!");
    }
    setDeleting(false);
  };

  const duplicatePermissionGroups = async () => {
    if (cannot("company:permission_groups:manage", { showToast: true })) return;

    setDuplicating(true);
    try {
      await Promise.all(selected.map((s) => MiterAPI.permission_groups.duplicate(s._id)));
      await refetchPermissionGroups();
      Notifier.success("Permission group(s) duplicated successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error duplicating the permission groups. We're looking into it!");
    }
    setDuplicating(false);
  };

  return (
    <div>
      <TableV2
        id={"permission-groups-table"}
        resource="permission groups"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelected}
        defaultSelectedRows={selected}
        onClick={handleClick}
        containerStyle={{ paddingBottom: 0 }}
        hideFooter={true}
      />

      {creating && <PermissionGroupModal onHide={hideModal} />}
      {editing && <PermissionGroupModal onHide={hideModal} permissionGroup={editing} />}
      {archiveConfirmation && (
        <BasicModal
          headerText="Delete group(s)?"
          onHide={() => setArchiveConfirmation(false)}
          button2Action={archiveSelectedPermissionGroups}
          loading={deleting}
          button2Text="Delete"
        >
          <div className="red-text-container">
            Are you sure you want to delete the selected permission group(s)?
          </div>
        </BasicModal>
      )}
    </div>
  );
};
