import React from "react";
import { Formblock } from "ui";
import { CostTypeScope } from "backend/models/cost-type";

export const CostTypeScopes: React.FC<{
  currentScopes: CostTypeScope[];
  handleFieldsChange: (scopes: CostTypeScope[]) => void;
}> = ({ currentScopes, handleFieldsChange }) => {
  const handleScopeChange = (isAdded: boolean, scope: CostTypeScope) => {
    // add or remove the scope from the current scopes
    const scopeSet = new Set(currentScopes);
    if (isAdded) {
      scopeSet.add(scope);
    } else {
      scopeSet.delete(scope);
    }

    handleFieldsChange(Array.from(scopeSet));
  };

  return (
    <>
      <Formblock
        label="Timesheets"
        type="checkbox"
        name="timesheets"
        className="tabbed-modal"
        defaultValue={currentScopes.includes("timesheets")}
        onChange={(e) => handleScopeChange(e.target.checked, "timesheets")}
        editing={true}
        style={{ alignItems: "center" }}
      />
      <Formblock
        label="Expense Management"
        labelInfo="Cost type dropdown for coding card transactions, reimbursements, bill pay etc"
        type="checkbox"
        name="expenses"
        className="tabbed-modal"
        defaultValue={currentScopes.includes("expense_management")}
        onChange={(e) => handleScopeChange(e.target.checked, "expense_management")}
        editing={true}
        style={{ alignItems: "center" }}
      />
    </>
  );
};
