/* global google */
import { GoogleMap } from "@react-google-maps/api";
import React, { FC, useEffect, useRef, useState } from "react";
import { roundTo } from "dashboard/utils";
import { MileageLocation } from "dashboard/miter";
import { Button, Formblock } from "ui";
import { Reorder } from "framer-motion";
import { isValidTrip } from "dashboard/pages/expenses/expenseUtils";
import { DotsSixVertical, X } from "phosphor-react";
import ObjectID from "bson-objectid";
import useDebounce from "dashboard/utils/useDebounce";
import { EXPENSE_REIMBURSEMENT_EDIT_MODE } from "dashboard/pages/expenses/modals/ExpenseReimbursementModalForm";
import { useLoadGoogleMaps } from "dashboard/utils/geolocation";

const METERS_IN_MILE = 1609.34;

// same as MileageLocation, but with a unique _id field and nullable place_id/description
export type DraftLocation = {
  _id: string;
  place_id?: string;
  description?: string;
};

type Props = {
  editingMode?: EXPENSE_REIMBURSEMENT_EDIT_MODE;
  mileageTrip?: MileageLocation[];
  setMileage: (mileage: number) => void;
  onTripUpdate: (trip?: MileageLocation[]) => void;
};

export const StopBasedMileageEditor: FC<Props> = ({ editingMode, mileageTrip, setMileage, onTripUpdate }) => {
  const { isLoaded } = useLoadGoogleMaps();
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
  const [currentDirections, setCurrentDirections] = useState<google.maps.DirectionsResult>();
  const [draggable, setDraggable] = useState(false);

  // if readOnly, cannot edit trip
  const readOnly = editingMode !== "all_fields";

  const [trip, setTrip] = useState<DraftLocation[]>(
    mileageTrip
      ? mileageTrip.map((location: MileageLocation) => {
          return { _id: new ObjectID().toHexString(), ...location };
        })
      : [{ _id: new ObjectID().toHexString() }, { _id: new ObjectID().toHexString() }]
  );

  // only update trips every 200 ms - this debounces API calls to DirectionService
  const debouncedUpdateTrip = useDebounce(trip, 200);

  // 1:1 with trip
  const tripStateRef = useRef<DraftLocation[]>(debouncedUpdateTrip);

  // for dragging
  const tripRef = useRef<HTMLDivElement>(null);
  const tripRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  // keeps tripStateRef 1:1 with the trip
  useEffect(() => {
    tripStateRef.current = debouncedUpdateTrip;
  }, [debouncedUpdateTrip]);

  // we need two different Google SDKs - DirectionsService and DirectionsRenderer. Initialize them both only when the actual Map reference loads
  useEffect(() => {
    if (isLoaded && googleMap) {
      setDirectionsService(new google.maps.DirectionsService());
      const newDirectionsRenderer = new google.maps.DirectionsRenderer();
      newDirectionsRenderer.setMap(googleMap);
      setDirectionsRenderer(newDirectionsRenderer);
    }
  }, [isLoaded, googleMap]);

  // recalculate route directions when trip changes
  useEffect(() => {
    const reroute = async () => {
      if (!isLoaded || !googleMap || !directionsService) return;

      // if any stops are empty, remove them
      const cleanedTrip = trip.filter((stop) => stop.place_id != null);

      // don't make API call unless there are two valid stops
      if (cleanedTrip.length >= 2) {
        const request: google.maps.DirectionsRequest = {
          origin: {
            placeId: cleanedTrip[0]!.place_id,
          },
          destination: {
            placeId: cleanedTrip[cleanedTrip.length - 1]!.place_id,
          },
          waypoints: cleanedTrip
            .filter((_, index) => index > 0 && index < cleanedTrip.length - 1)
            .map((stop) => ({ location: { placeId: stop.place_id }, stopover: true })),
          travelMode: google.maps.TravelMode.DRIVING,
        };
        const directions = await directionsService.route(request);
        setCurrentDirections(directions);
      } else {
        setCurrentDirections(undefined);
      }
    };

    reroute();
  }, [debouncedUpdateTrip, isLoaded, googleMap, directionsService]);

  // renders directions on map
  useEffect(() => {
    if (!directionsRenderer) return;

    if (!!currentDirections?.routes.length) {
      directionsRenderer.setDirections(currentDirections);

      const totalMileageInMeters = currentDirections?.routes[0]?.legs.reduce(
        (acc, leg) => acc + (leg.distance?.value || 0),
        0
      );

      const totalMileageInMiles = roundTo((totalMileageInMeters ?? 0) / METERS_IN_MILE, 2);
      setMileage(totalMileageInMiles);
    } else {
      directionsRenderer.setDirections({
        routes: [],
      });
    }
  }, [currentDirections]);

  // syncs current trip to parent form
  useEffect(() => {
    // if user deleted all trip rows, treat as no trip exists
    if (trip.length === 0) {
      onTripUpdate(undefined);
      return;
    }

    const validStops = trip.filter((stop) => stop.place_id != null && stop.description != null);
    onTripUpdate(
      validStops.map((stop) => {
        const { place_id, description } = stop;
        return { place_id: place_id!, description: description! };
      })
    );
  }, [trip]);

  // adds a new stop to end of trip
  const addStop = () => {
    setTrip([...trip, { _id: new ObjectID().toHexString() }]);
  };

  // deletes a stop when trash icon is clicked
  const deleteStop = (stop: DraftLocation) => {
    if (trip.length === 1) {
      setTrip([{ _id: new ObjectID().toHexString() }]);
      return;
    }
    setTrip(trip.filter((s) => s._id !== stop._id));
  };

  const renderDraggable = () => {
    if (readOnly) return;

    return (
      <div
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
        onTouchStart={() => setDraggable(true)}
        style={{ marginTop: 4, marginRight: 5, marginLeft: -5 }}
      >
        <DotsSixVertical style={{ fontSize: "24px", color: "#aaa" }} />
      </div>
    );
  };

  const onChange = (id: string) => (e) => {
    if (e.place_id && e.formatted_address) {
      // on paper calling trip would work here, but for some reason the value is cached. use the ref instead
      const newTrip = tripStateRef.current.map((stop) => {
        if (stop._id === id) {
          // update place_id and description
          return { _id: stop._id, place_id: e.place_id, description: e.formatted_address };
        } else {
          return stop;
        }
      });

      setTrip(newTrip);
    }
  };
  const renderComponent = (stop: DraftLocation) => {
    return (
      <Reorder.Item
        key={"section-" + stop._id}
        value={stop}
        dragListener={draggable}
        onDragEnd={() => setDraggable(false)}
        layout="position"
        ref={(r) => (tripRefs.current[stop._id] = r)}
        id={stop._id}
        dragConstraints={tripRef}
      >
        <div className="flex width-100-percent align-items-center" style={{ marginBottom: "7px" }}>
          <div className="flex width-100-percent align-items-center">
            {renderDraggable()}
            <Formblock
              type="google-place-autocomplete"
              name={`stop-${stop.place_id}`}
              editing={!readOnly}
              placeholder="E.g. 100 Main St, Baltimore"
              defaultValue={stop?.description}
              onChange={onChange(stop._id)}
              style={{ width: "100%", marginBottom: "0", marginRight: -25 }}
            />
          </div>
          {!readOnly && (
            <X
              size={14}
              onClick={() => deleteStop(stop)}
              color="#aaa"
              className="pointer"
              style={{ marginRight: 10 }}
            />
          )}
        </div>
      </Reorder.Item>
    );
  };

  const handleReorder = (trip: DraftLocation[]) => {
    setTrip(trip);
  };

  const renderTrip = () => {
    return (
      <div style={{ marginBottom: 15 }}>
        <Reorder.Group axis="y" values={trip} onReorder={handleReorder} ref={tripRef} className="no-margin">
          <>{trip.map((stop) => renderComponent(stop))}</>
        </Reorder.Group>
        {trip.length < 5 && !readOnly ? (
          <Button
            onClick={addStop}
            text="+ Add stop"
            className={"button-1 no-margin"}
            style={{ marginTop: "5px", marginBottom: "15px", marginLeft: 23 }}
          />
        ) : null}
      </div>
    );
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      {renderTrip()}
      {isValidTrip(trip) && (
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: 250,
              border: "1px solid #ccc",
              borderRadius: 4,
              marginBottom: 25,
            }}
            onLoad={(map) => {
              setGoogleMap(map);
            }}
            zoom={3}
            options={{
              disableDefaultUI: true,
              mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP],
              },
            }}
          />
        </div>
      )}
    </>
  );
};
