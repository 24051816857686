import {
  IntegrationSync,
  MiterAPI,
  MiterIntegrationForCompany,
  IntegrationSyncResult,
} from "dashboard/miter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { LargeModal, TableV2 } from "ui";
import { integrationEntityLookup, getDirectionName } from "./IntegrationSyncHistory";
import { capitalize } from "lodash";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { ArrowsClockwise } from "phosphor-react";
import { Notifier } from "dashboard/utils";

type Props = {
  integrationSyncId: string;
  integrationSync?: IntegrationSync;
  integration: MiterIntegrationForCompany;
  hide: () => void;
};

type IntegrationSyncTableEntry = {
  _id: string;
  label: string;
  status: string;
};

export const IntegrationSyncModal: React.FC<Props> = (props) => {
  const { integration, integrationSyncId, hide } = props;
  const [rerunningSync, setRerunningSync] = useState(false);
  const [tableData, setTableData] = useState<IntegrationSyncTableEntry[] | undefined>(undefined);

  const integrationConnectionId = integration.connection?._id;

  const [integrationSync, setIntegrationSync] = useState(props.integrationSync);

  const headerText = useMemo(() => {
    if (!integrationSync) return "";

    const entityName = integrationEntityLookup[integrationSync?.entity];
    const directionName = getDirectionName(integration.label, integrationSync?.direction, false);
    const syncDate = DateTime.fromSeconds(integrationSync?.created_at).toFormat("DD");

    return `${entityName} sync ${directionName} on ${syncDate}`;
  }, [integrationSync]);

  const rerunSync = async () => {
    if (!integrationConnectionId) return;
    setRerunningSync(true);
    try {
      const res = await MiterAPI.integrations.rerun_sync(integrationConnectionId, integrationSyncId);
      if (res.error) throw new Error(res.error);
      Notifier.success("Sync rerun successfully");
    } catch (err) {
      Notifier.error("Error rerunning sync");
      console.error(err);
    }
    setRerunningSync(false);
  };

  const handleSyncResultsResponse = useCallback((syncResults: IntegrationSyncResult[]) => {
    const syncResultsForTableData = syncResults.map((syncResult) => {
      return {
        // _id is a Miter Mongo ID, id is a user friendly ID for the
        // customer to tell which item is being synced.
        _id: syncResult._id.toString(),
        id: syncResult.external_id,
        label: syncResult.label || "-",
        status: capitalize(syncResult.status),
        message: syncResult.message || "-",
      };
    });
    setTableData(syncResultsForTableData);
  }, []);

  useEffect(() => {
    if (!integrationConnectionId) return;
    const getTableData = async () => {
      setRerunningSync(true);
      try {
        const [syncObj, results] = await Promise.all([
          integrationSync ? null : MiterAPI.integrations.get_sync(integrationConnectionId, integrationSyncId),
          MiterAPI.integrations.get_sync_results(integrationConnectionId, integrationSyncId),
        ]);
        if (syncObj?.error) throw new Error(syncObj.error);
        if (results.error) throw new Error(results.error);
        if (syncObj) setIntegrationSync(syncObj);
        handleSyncResultsResponse(results);
      } catch (err) {
        Notifier.error("Error getting sync results");
        console.error(err);
      }
      setRerunningSync(false);
    };
    getTableData();
  }, [integrationConnectionId, integrationSyncId]);

  const staticActions: TableActionLink[] = useMemo(() => {
    if (!integration.enable_rerunning_syncs) return [];
    return [
      {
        label: "Rerun Sync",
        className: "button-2 no-margin table-button",
        action: rerunSync,
        icon: <ArrowsClockwise weight="bold" style={{ marginRight: 3 }} />,
        loading: rerunningSync,
        important: true,
      },
    ];
  }, [rerunningSync]);

  return (
    <LargeModal headerText={headerText} onClose={hide}>
      {integrationSync?.error_message && (
        <div className="yellow-text-container">{integrationSync.error_message}</div>
      )}
      <TableV2
        id="integration-sync-history"
        resource="integration syncs"
        data={tableData}
        gridWrapperStyle={{ height: 500 }}
        columns={colDefs}
        staticActions={staticActions}
      />
    </LargeModal>
  );
};

const colDefs: ColumnConfig<IntegrationSyncTableEntry>[] = [
  { field: "id", headerName: "ID", filter: "agTextColumnFilter", maxWidth: 300 },
  { field: "label", headerName: "Label", filter: "agTextColumnFilter", minWidth: 200 },
  { field: "status", headerName: "Status", filter: true, maxWidth: 200 },
  {
    field: "message",
    headerName: "Message",
    filter: "agTextColumnFilter",
    minWidth: 500,
    wrapText: true,
    autoHeight: true,
  },
];
