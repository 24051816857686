import React, { useMemo, useState } from "react";
import { DateTime, DurationLike } from "luxon";
import { useNavigate } from "react-router";

import { useActiveCompany, useActiveTeam, useTeam } from "dashboard/hooks/atom-hooks";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { reportList } from "../reportList";
import { Formblock, TableV2 } from "ui";
import { AggregatedTeamMember } from "dashboard/miter";
import { ColumnConfig } from "ui/table-v2/Table";
import { capitalize } from "lodash";
import { roundTo } from "miter-utils";
import { ArrowDown, ArrowUp } from "phosphor-react";
import pluralize from "pluralize";
import { Helmet } from "react-helmet";

type TeamMemberTimeline = {
  date: string;
  type: "hire" | "dismissal";
  reason?: string;
  voluntary?: boolean;
}[];

type TeamMemberWithTimeline = AggregatedTeamMember & { timeline: TeamMemberTimeline };

export type TeamMemberRetentionRow = {
  _id: string;
  bucket: string;
  activeEmployees: number;
  employeesAtStart: number;
  employeesAtEnd?: number;
  newHires: number;
  newTerminations: number;
  terminationRate?: number;
  retentionRate?: number;
  netChange?: number;
  percentChange?: number;
  voluntaryTerminations: number;
  involuntaryTerminations: number;
  involuntaryTerminationRate?: number;
  voluntaryTerminationRate?: number;
};

type BucketType = "month" | "quarter" | "year";

export const CreateTeamMemberRetentionReport: React.FC = () => {
  /******************************************************************************
   *  Important hooks
   ******************************************************************************/
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();
  const teamMembers = useTeam();
  const activeTeam = useActiveTeam();
  const teamAbilities = useTeamAbilities();
  const [bucketType, setBucketType] = useState<BucketType>("month");

  /******************************************************************************
   *  Static variables
   ******************************************************************************/
  const dateFormat = useMemo(() => getBucketDateFormat(bucketType), [bucketType]);
  const reportObject = reportList.find((report) => report.slug === "team_member_retention");
  const accessibleTeamMembers = useMemo(
    () => teamMembers.filter((tm) => teamAbilities.can("read", tm)),
    [teamMembers, teamAbilities]
  );

  /******************************************************************************
   * Build the time buckets that we want to group the data (mnths, quarters, yrs)
   * since the company was created and initialize the data for each bucket.
   *
   * - i.e. If the company was created in 2020-01 and we are grouping by quarter,
   *  we would have 2020-Q1, 2020-Q2, 2020-Q3, 2020-Q4, 2021-Q1, 2021-Q2, etc.
   ******************************************************************************/
  const buildBuckets = () => {
    const bucketsMap: Record<string, TeamMemberRetentionRow> = {};

    // Create a map of all the months since the company was created
    const companyCreation = activeCompany ? DateTime.fromSeconds(activeCompany.created_at) : null;
    if (!companyCreation) return {};

    // Build time buckets from company creation to now
    let currentBucketEnd = DateTime.now().startOf(bucketType);

    while (currentBucketEnd.startOf(bucketType) >= companyCreation.startOf(bucketType)) {
      const bucket = currentBucketEnd.toFormat(dateFormat);

      bucketsMap[bucket] = {
        _id: bucket,
        bucket,
        activeEmployees: 0,
        employeesAtStart: 0,
        employeesAtEnd: 0,
        newHires: 0,
        newTerminations: 0,
        voluntaryTerminations: 0,
        involuntaryTerminations: 0,
      };

      currentBucketEnd = currentBucketEnd.minus({ [pluralize(bucketType)]: 1 } as DurationLike);
    }

    return bucketsMap;
  };

  /******************************************************************************
   * Create and add, to each team member, an ordered timeline of their hires and
   * terminations. Used to calculate all of the retention metrics.
   ******************************************************************************/
  const buildTeamMembersWithTimelines = () => {
    const teamMembersWithTimeline: TeamMemberWithTimeline[] = accessibleTeamMembers.map((teamMember) => {
      const timeline: TeamMemberTimeline = [];

      if (teamMember.start_date) {
        timeline.push({ date: teamMember.start_date, type: "hire" });
      }

      teamMember.dismissals?.forEach((dismissal) => {
        timeline.push({ date: dismissal.end_date, type: "dismissal", voluntary: dismissal.involuntary });
      });

      teamMember.rehires?.forEach((rehire) => {
        if (rehire.start_date === teamMember.start_date) return;
        timeline.push({ date: rehire.start_date, type: "hire" });
      });

      const sortedTimeline = timeline.sort(
        (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis()
      );

      return { ...teamMember, timeline: sortedTimeline };
    });

    return teamMembersWithTimeline;
  };

  const data = useMemo(() => {
    // Create a map of all the months since the company was created
    const bucketMap: Record<string, TeamMemberRetentionRow> = buildBuckets();

    // Create a list of team members with sorted timelines
    const teamMembersWithTimeline: TeamMemberWithTimeline[] = buildTeamMembersWithTimelines();

    teamMembersWithTimeline.forEach((teamMember) => {
      const timeline = teamMember.timeline;

      // Iterate through each bucket and calculate the retention metrics
      Object.values(bucketMap).forEach((row) => {
        // Get the start of the time bucket we are getting data for
        const bucket = row.bucket;
        const bucketStart = DateTime.fromFormat(bucket, dateFormat).startOf(bucketType);

        let terminatedInMonth = false;
        let voluntaryTermination = false;
        let startInBucket = false;

        // Check if the team member was hired or terminated in the time bucket
        for (let i = 0; i < timeline.length; i++) {
          const timelineItem = timeline[i]!;
          const timelineDate = DateTime.fromISO(timelineItem.date);

          if (
            timelineDate[bucketType] === bucketStart[bucketType] &&
            timelineDate.year === bucketStart.year
          ) {
            if (timelineItem.type === "hire") {
              startInBucket = true;
            } else if (timelineItem.type === "dismissal") {
              terminatedInMonth = true;
              voluntaryTermination = !!timelineItem.voluntary;
            }
          }
        }

        // If team member was hired in the bucket, add to new hires
        if (startInBucket) {
          row.newHires += 1;
        }

        // If team member was terminated in the bucket, add to new terminations
        if (terminatedInMonth) {
          row.newTerminations += 1;
          if (voluntaryTermination) {
            row.voluntaryTerminations += 1;
          } else {
            row.involuntaryTerminations += 1;
          }
        }

        // Calculate net change
        const totalChange = row.newHires - row.newTerminations;
        row.netChange = totalChange;

        // If the bucket is the current month, set the active employees and employees at start to the current team size
        if (
          bucketStart[bucketType] === DateTime.now()[bucketType] &&
          bucketStart.year === DateTime.now().year
        ) {
          row.activeEmployees = activeTeam.length;

          // The current month is partially through so we need to clear out the changes from the month
          row.employeesAtStart = activeTeam.length + row.newTerminations - row.newHires;
        } else if (bucketStart >= DateTime.fromSeconds(activeCompany!.created_at).startOf(bucketType)) {
          // Previous row total employees + net change
          const nextBucket = DateTime.fromFormat(bucket, dateFormat)
            .plus({ [pluralize(bucketType)]: 1 } as DurationLike)
            .toFormat(dateFormat);

          row.employeesAtEnd = bucketMap[nextBucket]!.employeesAtStart;

          // If the next period hasn't finished processing, that means their active employees count is not going to equate to the employees at the start of the period
          // So we need to calculate the active employees based on the net change of the current period and the active employees at the start of the period
          if (!bucketMap[nextBucket]?.employeesAtEnd) {
            row.activeEmployees = bucketMap[nextBucket]!.employeesAtStart - totalChange;
          } else {
            row.activeEmployees = bucketMap[nextBucket]!.activeEmployees - totalChange;
          }

          // The total employees at the start of the month is the same as the total employees for non first month rows
          row.employeesAtStart = row.activeEmployees;
        }

        // Add termination %, retention %, and % change
        if (row.employeesAtStart != null) {
          row.terminationRate = roundTo((row.newTerminations / row.employeesAtStart) * 100);
          row.retentionRate = roundTo((1 - row.newTerminations / row.employeesAtStart) * 100);
          row.percentChange = roundTo((row.netChange! / row.employeesAtStart) * 100);
        }
      });
    });

    // Sort the buckets in descending order and return the rows
    return Object.values(bucketMap).sort((a, b) => sortBuckets(a.bucket, b.bucket, dateFormat));
  }, [accessibleTeamMembers, activeCompany, bucketType]);

  /******************************************************************************
   * Table config
   ******************************************************************************/
  const columns: ColumnConfig<TeamMemberRetentionRow>[] = useMemo(
    () => [
      {
        headerName: capitalize(bucketType),
        field: "bucket",
        dataType: "string",
        minWidth: 120,
        pinned: "left",
      },
      {
        headerName: "EEs at Start",
        field: "employeesAtStart",
        dataType: "number",
        minWidth: 80,
      },
      {
        headerName: "EEs at End",
        field: "employeesAtEnd",
        dataType: "number",
        minWidth: 80,
      },
      {
        headerName: "Hires",
        field: "newHires",
        dataType: "number",
        width: 100,
        cellRenderer: (params) => rateCellRenderer(params, false),
      },
      {
        headerName: "Terminations",
        field: "newTerminations",
        dataType: "number",
        width: 100,
        cellRenderer: (params) => rateCellRenderer(params, false),
      },
      {
        headerName: "Voluntary Terminations",
        field: "voluntaryTerminations",
        dataType: "number",
        width: 100,
        hide: true,
        cellRenderer: (params) => rateCellRenderer(params, false),
      },
      {
        headerName: "Involuntary Terminations",
        field: "involuntaryTerminations",
        dataType: "number",
        width: 100,
        hide: true,
        cellRenderer: (params) => rateCellRenderer(params, false),
      },
      {
        headerName: "Voluntary Term %",
        field: "voluntaryTerminationRate",
        dataType: "number",
        width: 100,
        hide: true,
        cellRenderer: (params) => params.value + "%",
      },
      {
        headerName: "Involuntary Term %",
        field: "involuntaryTerminationRate",
        dataType: "number",
        width: 100,
        hide: true,
        cellRenderer: (params) => params.value + "%",
      },
      {
        headerName: "Voluntary Term %",
        field: "voluntaryTerminationRate",
        dataType: "number",
        width: 100,
        hide: true,
        cellRenderer: (params) => params.value + "%",
      },
      {
        headerName: "Net Change",
        field: "netChange",
        dataType: "number",
        width: 100,
        cellRenderer: rateCellRenderer,
      },
      {
        headerName: "% Change",
        field: "percentChange",
        dataType: "number",
        width: 100,
        cellRenderer: rateCellRenderer,
        hide: true,
      },
      {
        headerName: "Termination %",
        field: "terminationRate",
        dataType: "number",
        width: 100,
        cellRenderer: (params) => params.value + "%",
      },
      {
        headerName: "Retention %",
        field: "retentionRate",
        dataType: "number",
        width: 100,
        cellRenderer: (params) => params.value + "%",
      },
    ],
    [bucketType]
  );

  return (
    <div className="page-content">
      <Helmet>
        <title>Team Member Retention Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <div className="flex">
          <h1 style={{ margin: 0 }}>Team Member Retention Report</h1>
        </div>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <div className="vertical-spacer-small"></div>
      <div className="flex">
        <Formblock
          label="Group by"
          type="select"
          className=""
          onChange={(o) => setBucketType(o.value as "month" | "quarter" | "year")}
          editing={true}
          value={BUCKET_OPTIONS.find((o) => o.value === bucketType)}
          options={BUCKET_OPTIONS}
          style={{ width: 250, marginBottom: -60, marginTop: 15 }}
          labelStyle={{ width: 120 }}
        />
      </div>
      <TableV2
        id="team-member-retention-report"
        resource="rows"
        data={data}
        columns={columns}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName="timesheets-table-container"
        hideSearch={true}
      />
      <div className="vertical-spacer-large"></div>
    </div>
  );
};

const BUCKET_OPTIONS = [
  {
    value: "month",
    label: "Month",
  },
  {
    value: "quarter",
    label: "Quarter",
  },
  {
    value: "year",
    label: "Year",
  },
];

const rateCellRenderer = (params, includePercent?: boolean) => {
  return (
    <div className="flex">
      {params.value > 0 && <ArrowUp size={16} color="green" />}
      {params.value < 0 && <ArrowDown size={16} color="red" />}
      &nbsp;
      {params.value ? params.value : "-"}
      {includePercent && "%"}
    </div>
  );
};

/** Get the stringified date format for the time bucket that we are grouping by */
const getBucketDateFormat = (bucketType: BucketType) => {
  switch (bucketType) {
    case "month":
      return "MMM yyyy";
    case "quarter":
      return "Qq yyyy";
    case "year":
      return "yyyy";
  }
};

/** Sort the buckets in descending order */
const sortBuckets = (a: string, b: string, dateFormat: string) => {
  return DateTime.fromFormat(b, dateFormat).toSeconds() - DateTime.fromFormat(a, dateFormat).toSeconds();
};
