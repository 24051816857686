import {
  useActiveCompanyId,
  useClassificationOptions,
  useFringeOptions,
  usePrgOptions,
  usePrgs,
} from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { FringeAmount, ImportResult, MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "../../../components/importer/Importer";
import { Notifier } from "ui";
import { FlatfileResults } from "@flatfile/react";
import { createObjectMap, createObjectMapToArray } from "dashboard/utils";

type Props = {
  onFinish: (importResult?: ImportResult) => void;
};

export const FringeAmountImporter: React.FC<Props> = ({ onFinish }) => {
  const companyId = useActiveCompanyId();
  const payRateGroupOptions = usePrgOptions();
  const classificationOptions = useClassificationOptions({});
  const fringeOptions = useFringeOptions();
  const allPrgs = usePrgs();

  const allClassifications = useMemo(() => {
    return allPrgs.flatMap((r) => r.union_rates);
  }, [allPrgs]);

  const classificationLookup = useMemo(() => {
    return createObjectMap(allClassifications, (classification) => classification._id);
  }, [allClassifications]);

  const fringesByFringeGroupId = useMemo(() => {
    const fringesWithPayRateGroup = allClassifications.flatMap((c) => {
      return c.fringes.map((fringe) => {
        return { ...fringe, payRateGroup: c.pay_rate_group };
      });
    });

    return createObjectMapToArray(fringesWithPayRateGroup, (fringe) => fringe.fringe_group_id);
  }, [allPrgs]);

  const validateAmount = (value: string) => {
    const amount = Number(value);

    // invalid if there is a value but its not a number or its a negative number
    if (value && (isNaN(amount) || amount < 0)) {
      return buildFlatfileMessage("Must be a positive number or empty", value, "error");
    }
    return { value };
  };

  const validateClassification = (record: FringeAmount) => {
    const selectedPayRateGroup = record.payRateGroup;
    const selectedClassification = record.classification;

    if (classificationLookup[selectedClassification]?.pay_rate_group != selectedPayRateGroup) {
      return buildFlatfileMessage(
        "This classification does not exist in selected pay rate group",
        selectedClassification,
        "error"
      );
    }
    return { value: selectedClassification };
  };

  const validateFringe = (record: FringeAmount) => {
    const selectedPayRateGroup = record.payRateGroup;
    const selectedFringeGroup = record.fringeGroupId;
    const fringes = fringesByFringeGroupId[selectedFringeGroup];

    if (fringes && fringes[0]?.payRateGroup != selectedPayRateGroup) {
      return buildFlatfileMessage(
        "This fringe does not exist in selected pay rate group",
        selectedFringeGroup,
        "error"
      );
    }
    return { value: selectedFringeGroup };
  };

  const handleSubmit = async (results: FlatfileResults) => {
    try {
      const response = await MiterAPI.union_rates.import_fringe_amounts({
        company: companyId || "",
        clean_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);
      onFinish(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating payroll overrides.");
    }
    return;
  };
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Pay Rate Group",
        type: "select",
        key: "payRateGroup",
        options: payRateGroupOptions,
        validators: [{ validate: "required" }],
      },
      {
        label: "Classification",
        type: "select",
        key: "classification",
        options: classificationOptions,
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record === "string" ? { value: record } : validateClassification(record)),
      },
      {
        label: "Fringe",
        type: "select",
        key: "fringeGroupId",
        options: fringeOptions,
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record === "string" ? { value: record } : validateFringe(record)),
      },
      {
        label: "Amount",
        type: "string",
        key: "amount",
        validators: [{ validate: "required" }],
        hook: (record) =>
          typeof record === "string" ? validateAmount(record) : validateAmount(record.amount),
      },
    ];

    return fieldList;
  }, [payRateGroupOptions, classificationOptions, fringeOptions]);

  return (
    <Importer
      id="fringe-amounts"
      title="Import fringe amounts"
      resource="fringe amounts"
      onSave={handleSubmit}
      fields={fields}
    />
  );
};
