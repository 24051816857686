import Banner from "dashboard/components/shared/Banner";
import { useEffect, useState } from "react";
import React from "react";
import PayrollContext from "../payrollContext";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { MiterEarning } from "../../payrollTypes";

export const PayRateGroupWeightedAverageOvertimeWarning: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const payrollContext = React.useContext(PayrollContext);
  const { payroll } = payrollContext;
  const activeCompany = useActiveCompany();

  useEffect(() => {
    setShowBannerVal();
  }, [payroll]);

  const setShowBannerVal = async () => {
    if (!payroll || !activeCompany || !activeCompany._id) {
      return;
    }

    const companyOvertimeConfiguration = activeCompany.settings.payroll?.ot_configuration;
    if (
      companyOvertimeConfiguration?.ot_rate_determination === "weighted_average" &&
      companyOvertimeConfiguration?.classification_rate_preference === "higher_rate"
    ) {
      return;
    }

    const hasProblematicTimesheetInPayroll = payroll.miter_payments.some((payment) => {
      const hasProblematicTimesheetInEarnings = payment.miter_earnings.some((earning: MiterEarning) => {
        const hasClassification = earning.pay_rate_item?.classification?.source_id;
        const hasBeenAdjustedForRegularRateOfPay =
          earning.pay_rate_item?.regular_rate_of_pay &&
          !earning.pay_rate_item?.method_reference?.used_higher_classification_rate_for_weighted_average;
        return hasClassification && hasBeenAdjustedForRegularRateOfPay;
      });
      return hasProblematicTimesheetInEarnings;
    });

    setShowBanner(hasProblematicTimesheetInPayroll);
  };

  if (!showBanner) return null;

  return (
    <Banner
      content="Note: Some timesheet pay rates have been adjusted from your pay rate group settings due to weighted average OT."
      type="warning"
      style={{ marginTop: 15, marginBottom: -10 }}
      onDismiss={() => setShowBanner(false)}
    />
  );
};
