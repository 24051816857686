import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, Allowance, CreateAllowanceParams } from "dashboard/miter";
import { AllowanceTableEntry } from "dashboard/pages/benefits/AllowancesTable";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * ALLOWANCES
 */
type AllowanceParams =
  | Allowance
  | Allowance[]
  | AllowanceTableEntry
  | AllowanceTableEntry[]
  | CreateAllowanceParams
  | undefined;

type AllowanceAction = "create" | "read" | "update" | "delete";
type AllowanceAbilities = {
  can: (action: AllowanceAction, items?: AllowanceParams) => boolean;
  cannot: (action: AllowanceAction, items?: AllowanceParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: AllowanceAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useAllowanceAbilities = (): AllowanceAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: AllowanceAction, items?: AllowanceParams) => {
      if (!items) return false;
      const allowances = Array.isArray(items) ? items : [items];

      return allowances.every((allowance) => {
        const teamMemberId = getTeamMemberId(allowance);

        switch (action) {
          case "create":
            return can_("allowances:create", { teamMember: teamMemberId });
          case "read":
            return can_("allowances:read", { teamMember: teamMemberId });
          case "update":
            return can_("allowances:update", { teamMember: teamMemberId });
          case "delete":
            return can_("allowances:delete", { teamMember: teamMemberId });
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: AllowanceAction, items?: AllowanceParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "allowances",
    teamMemberField: { fieldName: "employee._id", fieldType: "_id" },
    appModule: "payroll_and_compliance",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<AllowanceAction>("allowances", true);

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, filter, teamPredicate]);
};

const getTeamMemberId = (item: Allowance | AllowanceTableEntry | CreateAllowanceParams | undefined) => {
  return item?.team_member;
};
