import {
  AggregatedTeamMember,
  MiterAPI,
  Allowance,
  BulkUpdateResult,
  MiterFilterArray,
} from "dashboard/miter";
import { Notifier, createObjectMap, roundTo } from "dashboard/utils";
import { ClockCounterClockwise, Plus, TrashSimple } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { BasicModal, TableV2, usdString } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import {
  AllowanceModal,
  allowanceTypeOptions,
  includeWithEarningActivityOption,
  includeWithEarningJobOption,
} from "./AllowanceModal";
import {
  useActiveCompanyId,
  useActivityLabelFormatter,
  useActivityOptionsMap,
  useCostTypeOptions,
  useJobNameFormatter,
  useJobOptions,
  useLedgerAccountLabeler,
  useLedgerAccountOptions,
  useLookupActivity,
  useLookupCostType,
  useLookupJob,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { ImportHistory } from "dashboard/components/importer/ImportHistory";
import { AllowanceImporter } from "./AllowanceImporter";
import { isActive, useEnhancedSearchParams } from "miter-utils";
import { useAllowanceAbilities } from "dashboard/hooks/abilities-hooks/useAllowanceAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { COASTAL_COMPANY_IDS } from "dashboard/utils/constants";
import { isTmActive } from "../team-members/TeamUtils";

export type AllowanceTableEntry = Allowance & {
  amount_string: string;
  active_status?: string;
  department_name?: string;
};

type Props = {
  tm?: AggregatedTeamMember;
};

const TOGGLER_CONFIG = {
  config: [
    { path: "active", label: "Active" },
    { path: "inactive", label: "Inactive" },
    { path: "all", label: "All" },
  ],
  path: "active",
  secondary: true,
  field: "status",
};

const typeLabelLookup = {
  reimbursement: "Non-taxable",
  earning: "Taxable cash earning",
  imputed_earning: "Taxable imputed income",
};

export const AllowancesTable: React.FC<Props> = ({ tm }) => {
  const activeCompanyId = useActiveCompanyId();
  const lookupTeam = useLookupTeam();
  const accountLabeler = useLedgerAccountLabeler();
  const ledgerAccountOptions = useLedgerAccountOptions({
    includeCustomOption: { label: "Default", value: "" },
  });
  const costTypeOptions = useCostTypeOptions({
    includeCustomOption: { label: "Default", value: "" },
  });

  const lookupJob = useLookupJob();
  const lookupActivity = useLookupActivity();
  const lookupCostType = useLookupCostType();
  const jobNameFormatter = useJobNameFormatter();
  const activityNameFormatter = useActivityLabelFormatter();

  const jobOptions = useJobOptions({ includeCustomOption: includeWithEarningJobOption });
  const activityOptionsMap = useActivityOptionsMap({ includeCustomOption: includeWithEarningActivityOption });
  const allowanceAbilities = useAllowanceAbilities();
  const miterAbilities = useMiterAbilities();

  const { searchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const allowanceId = searchParams.get("alid");

  const [allowances, setAllowances] = useState<Allowance[]>();
  const [selectedAllowances, setSelectedAllowances] = useState<AllowanceTableEntry[]>([]);

  const lookupAllowance = useMemo(() => {
    const lookup = createObjectMap(allowances || [], (o) => o._id);

    return (id: string | null | undefined) => {
      if (!id) return;
      return lookup[id];
    };
  }, [allowances]);

  const allowanceToView = lookupAllowance(allowanceId);
  const [deleting, setDeleting] = useState(false);
  const [unenrolling, setUnenrolling] = useState(false);
  const [adding, setAdding] = useState(false);
  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);

  useEffect(() => {
    getAllowances();
  }, [tm, activeCompanyId]);

  const getAllowances = async () => {
    if (!activeCompanyId) return;
    try {
      const filter: MiterFilterArray = tm
        ? [{ field: "team_member", value: tm._id }]
        : [{ field: "company", value: activeCompanyId }];

      const response = await MiterAPI.allowances.retrieve({ filter });
      if (response.error) throw new Error("Error retrieving allowances");

      setAllowances(response);
    } catch (e) {
      console.error(e, "Error retrieving allowances");
      Notifier.error("There was an error retrieving allowances");
    }
  };

  const getAmountString = (allowance: Allowance): string => {
    if (allowance.calculation_method === "per_month") {
      return usdString(allowance.amount) + "/month";
    } else if (allowance.calculation_method === "per_week") {
      return usdString(allowance.amount) + "/week";
    } else if (allowance.calculation_method === "per_hour") {
      return usdString(allowance.amount) + "/hour";
    } else if (allowance.calculation_method === "percent") {
      return roundTo(allowance.amount) + "% of earnings";
    }
    return "";
  };

  const handleBulkCreateFinish = async () => {
    await getAllowances();
    setShowImportHistory(true);
    setShowLatestImportResult(true);
  };

  const tableEntries: AllowanceTableEntry[] | undefined = useMemo(() => {
    return allowances?.map((a) => {
      const tm = lookupTeam(a.team_member);
      return {
        ...a,
        amount_string: getAmountString(a),
        team_member_name: tm?.full_name || "-",
        department_name: tm?.department?.name || "-",
        status: tm && isTmActive(tm) && isActive(a.start_date, a.end_date) ? "active" : "inactive",
        readonly: allowanceAbilities.cannot("update", a),
      };
    });
  }, [allowances, allowanceAbilities.cannot]);

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        key: "import",
        component: <AllowanceImporter onFinish={handleBulkCreateFinish} />,
        shouldShow: () => miterAbilities.can("allowances:create"),
      },
      {
        key: "import-history",
        className: "button-1",
        action: () => setShowImportHistory(true),
        label: "Import history",
        icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => miterAbilities.can("allowances:create"),
      },
      {
        label: "Add allowance",
        className: "button-2",
        action: () => setAdding(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => miterAbilities.can("allowances:create"),
      },
    ],
    [miterAbilities.can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setUnenrolling(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => allowanceAbilities.can("delete", selectedAllowances),
      },
    ],
    [allowanceAbilities.can, selectedAllowances]
  );

  const columns: ColumnConfig<AllowanceTableEntry>[] = useMemo(() => {
    const cols: ColumnConfig<AllowanceTableEntry>[] = [];
    if (!tm) {
      cols.push(
        {
          field: "team_member_name",
          headerName: "Team Member",
          dataType: "string" as const,
          pinned: "left" as const,
        },
        {
          field: "department_name",
          headerName: "Department",
          dataType: "string" as const,
          initialHide: true,
          filter: true,
        }
      );
    }
    cols.push(
      {
        field: "description",
        headerName: "Description",
        dataType: "string",
        editorType: "text",
        editable: true,
        pinned: "left",
      },
      {
        field: "amount_string",
        headerName: "Amount",
        dataType: "component",
        editableHide: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        dataType: "number",
        editorType: "number",
        editable: true,
        editableOnly: true,
        suppressColumnsToolPanel: true,
        maxWidth: 150,
        validations: (value) => {
          if (value === "" || value == null) return "Amount is required.";
          const num = Number(value);
          if (isNaN(num)) return "Amount must be a number.";
          if (num < 0) return "Amount must not be negative.";
          return true;
        },
        cellEditorParams: () => ({
          min: 0,
          step: 0.01,
        }),
      },
      {
        field: "calculation_method",
        headerName: "Calculation method",
        editorType: "select",
        editable: true,
        editableOnly: true,
        suppressColumnsToolPanel: true,
        cellEditorParams: () => ({
          options: [
            { value: "per_month", label: "Per month" },
            { value: "per_week", label: "Per week" },
            { value: "per_hour", label: "Per hour" },
            { value: "percent", label: "% of earnings" },
          ],
        }),
        valueFormatter: (params) => {
          if (params.data?.calculation_method === "per_hour") return "Per hour";
          if (params.data?.calculation_method === "per_month") return "Per month";
          if (params.data?.calculation_method === "per_week") return "Per week";
          if (params.data?.calculation_method === "percent") return "% of earnings";
          return "";
        },
        minWidth: 250,
      },
      {
        field: "type",
        headerName: "Type",
        dataType: "string",
        cellEditorParams: () => ({
          options: allowanceTypeOptions,
        }),
        editable: true,
        editorType: "select",
        valueGetter: (params) => typeLabelLookup[params.data?.type || ""] || "Default",
      },
      {
        field: "ignore_benefit_contributions",
        headerName: "Ignore benefits",
        dataType: "boolean",
        editable: true,
        editorType: "checkbox",
      },
      {
        field: "ignore_misc_ptds",
        headerName: "Ignore misc deductions",
        dataType: "boolean",
        editable: true,
        editorType: "checkbox",
      },
      {
        field: "start_date",
        headerName: "Start",
        dataType: "date",
        editorType: "date",
        editorDateType: "iso",
        editable: true,
      },
      {
        field: "end_date",
        headerName: "End",
        dataType: "date",
        editorType: "date",
        editorDateType: "iso",
        editable: true,
      },
      {
        field: "ledger_account_id",
        headerName: "GL account",
        dataType: "string",
        valueGetter: (params) => accountLabeler(params.data?.ledger_account_id) || "Default",
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: ledgerAccountOptions,
        }),
        minWidth: 200,
      }
    );
    if (activeCompanyId && COASTAL_COMPANY_IDS.includes(activeCompanyId)) {
      cols.push({
        field: "on_job_ledger_account_id",
        headerName: "On job ledger account",
        dataType: "string" as const,
        valueGetter: (params) => accountLabeler(params.data?.on_job_ledger_account_id) || "Default",
        editable: true,
        editorType: "select" as const,
        cellEditorParams: () => ({
          options: ledgerAccountOptions,
        }),
        minWidth: 200,
      });
    }
    cols.push(
      {
        field: "cost_type_id",
        headerName: "Cost type",
        dataType: "string",
        valueGetter: (params) => lookupCostType(params?.data?.cost_type_id)?.label || "Default",
        editable: true,
        editorType: "select",
        cellEditorParams: () => ({
          options: costTypeOptions,
        }),
        minWidth: 200,
      },
      {
        field: "job_id",
        headerName: "Job",
        dataType: "string",
        valueGetter: (params) => {
          const jobId = params?.data?.job_id;
          if (jobId === includeWithEarningJobOption.value) {
            return includeWithEarningJobOption.label;
          }
          const job = lookupJob(jobId);
          return job ? jobNameFormatter(job) : "-";
        },
        editable: true,
        editorType: "select",
        cellEditorParams: {
          options: jobOptions,
          isClearable: true,
        },
        minWidth: 200,
        initialHide: true,
      },
      {
        field: "activity_id",
        headerName: "Activity",
        dataType: "string",
        valueGetter: (params) => {
          const activityId = params?.data?.activity_id;
          if (activityId === includeWithEarningActivityOption.value) {
            return includeWithEarningActivityOption.label;
          }
          const activity = lookupActivity(activityId);
          return activity ? activityNameFormatter(activity) : "-";
        },
        cellEditorParams: (params) => {
          return {
            options: activityOptionsMap.get(params?.data?.job_id) || [],
            isClearable: true,
          };
        },
        editable: true,
        editorType: "select",
        minWidth: 200,
        initialHide: true,
      }
    );

    return cols;
  }, [tm, accountLabeler, ledgerAccountOptions, activityOptionsMap, jobOptions, activeCompanyId]);

  const cleanUpdateParams = (allowances: AllowanceTableEntry[]) => {
    return allowances.map((d) => {
      return {
        _id: d._id,
        params: {
          ledger_account_id: d.ledger_account_id || null,
          on_job_ledger_account_id: d.on_job_ledger_account_id || null,
          cost_type_id: d.cost_type_id || null,
          description: d.description,
          type: d.type,
          ignore_benefit_contributions: d.ignore_benefit_contributions,
          ignore_misc_ptds: d.ignore_misc_ptds,
          start_date: d.start_date,
          end_date: d.end_date || null,
          amount: d.amount,
          calculation_method: d.calculation_method,
          job_id: d.job_id || null,
          activity_id: d.activity_id || null,
        },
      };
    });
  };

  const updateAllowances = async (allowances: AllowanceTableEntry[]): Promise<BulkUpdateResult> => {
    if (allowanceAbilities.cannot("update", allowances)) {
      Notifier.error("You do not have permission to update allowances.");
      return { successes: [], errors: [] };
    }

    try {
      const params = cleanUpdateParams(allowances);
      const res = await MiterAPI.allowances.bulk_update(params);
      if (res.error) throw Error(res.error);

      if (res.errors.length > 0) {
        const successes = res.successes;
        const errors = res.errors;

        return { successes, errors };
      }

      getAllowances();
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem updating allowances. We're looking into it.");
      return { successes: [], errors: [] };
    }
  };

  const archiveAllowances = async () => {
    if (allowanceAbilities.cannot("delete", selectedAllowances)) {
      Notifier.error("You do not have permission to delete allowances.");
      return;
    }

    setDeleting(true);
    try {
      const ids = selectedAllowances.map((a) => a._id);
      const response = await MiterAPI.allowances.update(ids, { archived: true });
      if (response.error) throw new Error(response.error);

      await getAllowances();
      setSelectedAllowances([]);
      setUnenrolling(false);

      Notifier.success("Allowance(s) successfully deleted.");
    } catch (e) {
      Notifier.error("There was an error deleting allowance(s). We're looking into it!");
    }
    setDeleting(false);
  };

  return (
    <div>
      {allowanceToView && (
        <AllowanceModal
          hide={() => setSearchParams({ alid: undefined })}
          onSuccess={getAllowances}
          allowanceToUpdate={allowanceToView}
          tm={tm}
        />
      )}
      {adding && <AllowanceModal onSuccess={getAllowances} hide={() => setAdding(false)} tm={tm} />}
      {unenrolling && (
        <BasicModal
          headerText="Are you sure?"
          button2Action={archiveAllowances}
          button2Text={"Delete"}
          button1Text="Cancel"
          loading={deleting}
          button1Action={() => setUnenrolling(false)}
        >
          <div className="payroll-approval-text-wrapper">
            <span>{`Are you sure you want to delete ${
              selectedAllowances.length > 1 ? "these allowances" : "this allowance"
            }?`}</span>
          </div>
        </BasicModal>
      )}

      <TableV2
        id={"employee-allowances-table"}
        resource="allowances"
        data={tableEntries}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedAllowances}
        defaultSelectedRows={selectedAllowances}
        onClick={(entry) => setSearchParams({ alid: entry._id })}
        editable={miterAbilities.can("allowances:update")}
        onSave={updateAllowances}
        toggler={TOGGLER_CONFIG}
        showReportViews
      />
      {showImportHistory && (
        <ImportHistory
          id={"allowances"}
          resource={"allowances"}
          onClose={() => {
            setShowImportHistory(false);
            setShowLatestImportResult(false);
          }}
          openLastResult={showLatestImportResult}
        />
      )}
    </div>
  );
};
