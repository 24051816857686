import { MiterAPI, AggregatedVendor } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Plus, TrashSimple } from "phosphor-react";
import { useEffect, useState, FC } from "react";
import { BasicModal } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";

import VendorModal from "./VendorModal";
import { useRefetchVendors, useVendors } from "dashboard/hooks/atom-hooks";
import { useNavigate } from "react-router-dom";
import { VendorImporter } from "./VendorImporter";
import pluralize from "pluralize";
import { BillPaymentMethodString } from "./vendor-types";

type VendorTableRow = AggregatedVendor & {
  available_payment_methods: BillPaymentMethodString[];
  default_payment_method_string?: BillPaymentMethodString; // override from enum to string
};

type Props = {
  vendorId?: string;
};

const VendorsTable: FC<Props> = ({ vendorId }) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const navigate = useNavigate();
  const vendors = useVendors();
  const fetchVendors = useRefetchVendors();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState<boolean>(false);

  // States related to the table
  const [tableData, setTableData] = useState<VendorTableRow[]>();
  const [selectedRows, setSelectedRows] = useState<VendorTableRow[]>([]);

  // States related to table actions
  const [adding, setAdding] = useState<boolean | null>(false);
  const [archiving, setArchiving] = useState<boolean>(false);

  const [selectedVendorId, setSelectedVendorId] = useState<string>();

  /*********************************************************
   *  Call useEffect hooks
   **********************************************************/
  useEffect(() => {
    const rows = vendors.map((vendor) => buildTableRow(vendor));
    setTableData(rows);
  }, [vendors]);

  useEffect(() => {
    setSelectedVendorId(vendorId);
  }, [vendorId]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/

  const handleRowClick = (vendor) => {
    const sidebarDetailsUrl = `/expenses/vendors/${vendor._id}`;

    navigate(sidebarDetailsUrl);
  };

  const handleArchive = async () => {
    setLoading(true);
    try {
      for (const vendor of selectedRows) {
        const response = await MiterAPI.vendors.delete(vendor._id);
        if (response.error) throw new Error(response.error);
      }

      Notifier.success(`${pluralize("Vendor", selectedRows.length)} deleted.`);

      setArchiving(false);
      setSelectedRows([]);
      fetchVendors();
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error deleting one or more vendors. We're looking into it.");
    }
    setLoading(false);
  };

  const handleModalFinish = () => {
    setAdding(false);
    setSelectedVendorId(undefined);
    setSelectedRows([]);
    navigate(`/expenses/vendors/`);
    fetchVendors();
  };

  /*********************************************************
   *  Data fetching functions
   **********************************************************/
  const buildAvailablePaymentMethods = (vendor: AggregatedVendor): BillPaymentMethodString[] => {
    const paymentMethodList: BillPaymentMethodString[] = [];

    if (vendor.bank_account) {
      paymentMethodList.push(BillPaymentMethodString.ACH);
    }

    if (vendor.paper_check_config) {
      paymentMethodList.push(BillPaymentMethodString.CHECK);
    }

    return paymentMethodList;
  };

  const buildDefaultPaymentMethodString = (vendor: AggregatedVendor): BillPaymentMethodString | undefined => {
    if (vendor.default_payment_method === "ach") {
      return BillPaymentMethodString.ACH;
    } else if (vendor.default_payment_method === "check") {
      return BillPaymentMethodString.CHECK;
    } else {
      return;
    }
  };

  const buildTableRow = (vendor: AggregatedVendor) => {
    return {
      ...vendor,
      available_payment_methods: buildAvailablePaymentMethods(vendor),
      default_payment_method_string: buildDefaultPaymentMethodString(vendor),
    };
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const dynamicActions: TableActionLink[] = [
    {
      label: "Delete",
      action: () => setArchiving(true),
      className: "button-3 no-margin table-button",
      icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const staticActions: TableActionLink[] = [
    {
      label: "Add vendor",
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      action: () => setAdding(true),
      className: "button-2",
      important: true,
    },
    {
      key: "import",
      component: <VendorImporter onFinish={() => fetchVendors()} />,
    },
  ];

  const columns: ColumnConfig<VendorTableRow>[] = [
    { headerName: "Name", field: "name", dataType: "string" },
    { headerName: "Description", field: "description", dataType: "string" },
    {
      headerName: "Available payment methods",
      field: "available_payment_methods",
      valueFormatter: (params) => params.value?.available_payment_methods?.join(", "),
      useValueFormatterForExport: true,
      dataType: "string",
    },
    { headerName: "Default payment method", field: "default_payment_method_string", dataType: "string" },
  ];

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id="vendors-table"
        resource="vendors"
        data={tableData}
        columns={columns}
        onSelect={setSelectedRows}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={true}
        onClick={handleRowClick}
        defaultSelectedRows={selectedRows}
      />
    );
  };

  return (
    <div className="vendors-table-wrapper">
      {adding && <VendorModal onHide={() => setAdding(false)} onFinish={handleModalFinish} />}
      {selectedVendorId && (
        <VendorModal
          vendorId={selectedVendorId}
          onFinish={handleModalFinish}
          onHide={() => {
            setSelectedVendorId(undefined);
            navigate(`/expenses/vendors/`);
          }}
        />
      )}
      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={handleArchive}
          headerText={"Delete vendor" + (selectedRows.length > 1 ? "s" : "")}
          bodyText={
            "Are you sure you want to delete the selected vendor" +
            (selectedRows.length > 1 ? "s" : "") +
            "? To restore a deleted vendor, you must contact Miter Support."
          }
        />
      )}

      {renderTable()}
    </div>
  );
};

export default VendorsTable;
