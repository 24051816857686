import { AggregatedTeamMember, CheckDocument, MiterAPI } from "dashboard/miter";
import { deleteFiles, downloadFiles, getTeamMemberESignatureItem, zipAsync } from "miter-utils";
import { DateTime } from "luxon";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { ActionMenu, Badge, BasicModal, LoadingModal } from "ui";
import Notifier from "../../utils/notifier";
import { isUUID } from "../../utils/utils";
import { SendMessageModal } from "../sendMessageModal/SendMessageModal";
import DocumentWizard from "../documents/wizard/DocumentWizard";
import { Download, PaperPlaneTilt, Plus, TrashSimple } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { FilesTableFile } from "../tables/FilesTable";
import { saveAs } from "file-saver";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { useDocumentAbilities } from "dashboard/hooks/abilities-hooks/useDocumentAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {
  teamMember: AggregatedTeamMember;
};

export type TeamMemberDocumentRow = FilesTableFile & {
  _id: string;
  label: string;
  document_type: "personal" | "company" | "tax form" | "job";
  status: "Shared" | "Not viewed" | "Viewed" | "Awaiting signature" | "Signed" | "Complete" | "Pending";
  uploaded: string;
  created_at: number;
  actions: ReactElement;
};

const TeamMemberDocumentsTable: React.FC<Props> = ({ teamMember }) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const navigate = useNavigate();
  const docAbilities = useDocumentAbilities();
  const miterAbilities = useMiterAbilities();

  const [loading, setLoading] = useState(false);

  // States related to the table
  const [docs, setDocs] = useState<FilesTableFile[]>([]);
  const [data, setData] = useState<TeamMemberDocumentRow[]>();
  const [selectedRows, setSelectedRows] = useState<TeamMemberDocumentRow[]>([]);
  const [isSending, setIsSending] = useState(false);

  // States related to table actions
  const [bulkRemoving, setBulkRemoving] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  // States related to file upload
  const [upload, setUpload] = useState(false);
  const selectedRowIds = useMemo(() => selectedRows.map((row) => row._id), [selectedRows]);

  /*********************************************************
   * useEffects
   **********************************************************/
  useEffect(() => {
    const canReadDocuments = docAbilities.teamPredicate("read")(teamMember);

    if (!canReadDocuments) {
      Notifier.error("You do not have permission to view these documents.");
      navigate("/home");
    }
  }, [docAbilities.cannot, docAbilities]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleNewButtonClick = () => {
    setUpload(true);
  };

  const handleRemove = async () => {
    if (docAbilities.cannot("delete", selectedRows, "team_member")) {
      Notifier.error("You do not have permission to delete these documents.");
      return;
    }

    setRemoveLoading(true);
    await deleteFiles(selectedRowIds);
    getDocuments();
    setBulkRemoving(false);
    setSelectedRows([]);
    setRemoveLoading(false);
  };

  const handleBulkArchive = () => {
    if (docAbilities.cannot("delete", selectedRows, "team_member")) {
      Notifier.error("You do not have permission to delete these documents.");
      return;
    }

    const hasTaxDocsSelected = selectedRowIds.some((id) => id.includes("doc"));

    if (hasTaxDocsSelected) {
      Notifier.error("You cannot delete tax documents. Please deselect them and try again.");
      return;
    }

    setBulkRemoving(true);
  };

  const handleDownload = async (file?: FilesTableFile) => {
    if (docAbilities.cannot("read", selectedRows, "team_member")) {
      Notifier.error("You do not have permission to delete these documents.");
      return;
    }

    if (file && !("filed_on" in file)) {
      downloadFiles([file._id], setDownloading);
    } else if (file && "id" in file) {
      handleDownloadTaxDocument(file);
    } else {
      const miterDocIds = selectedRowIds.filter((id) => !id.includes("doc"));
      const selectedDocs = docs.filter((doc) => {
        if ("_id" in doc) {
          return selectedRowIds.includes(doc._id);
        } else {
          return selectedRowIds.includes(doc.id);
        }
      });

      if (selectedDocs.length === 1 && selectedDocs[0]) {
        if (!("filed_on" in selectedDocs[0])) {
          await downloadFiles(miterDocIds, setDownloading);
        } else {
          await handleDownloadTaxDocument(selectedDocs[0]);
        }
        return;
      }

      await downloadFiles(miterDocIds, setDownloading, `${teamMember.full_name} Documents.zip`);

      // Download multiple tax documents 1 by 1 to not overload server
      const bufferMap: { [key: string]: Buffer } = {};
      await Promise.all(
        selectedRowIds
          .filter((id) => id.includes("doc"))
          .map(async (id) => {
            const doc = docs.find((doc) => "id" in doc && doc.id === id);
            if (!doc || !("id" in doc)) return;

            const blob = await handleDownloadTaxDocument(doc, true);
            if (!blob) return;

            const key = `${doc.label}.pdf`;

            const buffer = await blob.arrayBuffer();
            bufferMap[key] = Buffer.from(buffer);
          })
      );

      if (!Object.keys(bufferMap).length) return;

      const zippedTaxDocs = await zipAsync(bufferMap);
      const data = Buffer.from(zippedTaxDocs);

      saveAs(new Blob([data]), `${teamMember.full_name} Tax Documents.zip`);
    }
  };

  const handleDownloadTaxDocument = async (file: CheckDocument, multiple?: boolean) => {
    try {
      const response = await MiterAPI.team_member.retrieve_tax_document(teamMember._id, file.id);
      if (response.error) throw new Error(response.error);

      const blob = await response.blob();

      if (!multiple) {
        saveAs(blob, file.label);
      }

      return blob;
    } catch (err: $TSFixMe) {
      console.error("Error downloading tax document", err);
      Notifier.error("There was an error downloading the tax document. We're looking into it.");
    }
  };

  const handleDocumentWizardExit = () => {
    setUpload(false);
    getDocuments();
  };

  /*********************************************************
   *  Data fetching functions
   **********************************************************/

  const buildActionsCell = (file: FilesTableFile) => {
    const actions = [
      {
        label: "View",
        action: () => viewFile(file),
        shouldShow: () => docAbilities.can("read", file, "team_member"),
      },
      {
        label: "Download",
        action: () => handleDownload(file),
        loading: loading,
        shouldShow: () => docAbilities.can("read", file, "team_member"),
      },
    ];

    return <ActionMenu links={actions} />;
  };

  const buildDocStatus = (file: FilesTableFile): TeamMemberDocumentRow["status"] => {
    if ("esignature_items" in file) {
      const esignatureItem = getTeamMemberESignatureItem(file, teamMember._id);
      const viewEvent = file.events.find(
        (event) => event.action === "viewed" && event.team_member_ids.includes(teamMember._id)
      );

      if ((esignatureItem && esignatureItem.status === "signed") || file?.signed_copy) {
        return "Signed";
      } else if (esignatureItem && esignatureItem.status !== "signed") {
        return "Awaiting signature";
      } else if (file.fill_status === "complete") {
        return "Complete";
      } else if (file.fill_status === "pending") {
        return "Pending";
      } else if (viewEvent) {
        return "Viewed";
      } else {
        return "Not viewed";
      }
    }

    return "Shared";
  };

  const buildDocumentType = (file: FilesTableFile): TeamMemberDocumentRow["document_type"] => {
    const parentTypeToDocumentType = {
      team_member: "personal",
      company: "company",
      job: "job",
    };

    if ("parent_type" in file && file.parent_type) {
      if ("esignature_items" in file) {
        return "personal";
      } else {
        return parentTypeToDocumentType[file.parent_type];
      }
    } else {
      return "tax form";
    }
  };

  const buildTagsCell = (file: FilesTableFile) => {
    if ("tags" in file) {
      return (
        <div style={{ display: "flex" }}>
          {file.tags.map((tag) => (
            <Badge
              backgroundColor={tag.color}
              text={tag.label}
              style={{ marginLeft: 0 }}
              key={`tag-${tag.label}-${file._id}`}
            />
          ))}
          {!file.tags.length ? "" : ""}
        </div>
      );
    }

    return "";
  };

  const buildTableRows = (files: FilesTableFile[]): TeamMemberDocumentRow[] => {
    const signedFileIds: string[] = [];
    files.forEach((file) => {
      if (!("esignature_items" in file)) return;

      const esignatureItem = getTeamMemberESignatureItem(file, teamMember._id);
      if (!esignatureItem || esignatureItem.status !== "signed") return;

      const signedFile = esignatureItem.parent_document_id;
      if (!signedFile) return;

      signedFileIds.push(signedFile);
    });

    return files
      .filter((file) => {
        // If it's a miter document, check if the user has permission to view it and if it's not already signed
        if ("_id" in file) {
          return docAbilities.can("read", file, "team_member") && !signedFileIds.includes(file._id);
          // If it's a tax document, check if the user has permission to view it
        } else {
          return miterAbilities.can("documents:team_member:read_sensitive");
        }
      })
      .filter(
        (file) => ("originalname" in file && file.originalname && !isUUID(file.originalname)) || "id" in file
      ) // Measure to not display timesheet images in here
      .map((file) => {
        const fileName =
          file.label || ("originalname" in file ? file.originalname : "Untitled file") || "Untitled file";

        const createdAt =
          "created_at" in file ? DateTime.fromSeconds(file.created_at) : DateTime.fromISO(file.filed_on);

        const documentType = buildDocumentType(file);
        const status = buildDocStatus(file);

        return {
          ...file,
          label: file.label || "Untitled file",
          document_type: documentType,
          _id: "id" in file ? file.id : file._id,
          name: fileName,
          uploaded: createdAt.toFormat("f"),
          actions: buildActionsCell(file),
          status,
          created_at: createdAt.toSeconds(),
          tag_values: "tags" in file ? file.tags.map((tag) => tag.label) : [],
        };
      })
      .sort((a, b) => b.created_at - a.created_at);
  };

  // Get the files for a user filtered by the filter button and search bar.
  const getDocuments = async () => {
    setLoading(true);

    try {
      const res = await MiterAPI.team_member.documents(teamMember._id);
      if (res.error) throw Error(res.error);

      const documents = [...res.documents, ...res.tax_forms];
      const tableRows = buildTableRows(documents);

      setData(tableRows);
      setDocs(documents);
    } catch (e) {
      console.log("Error getting files", e);
      Notifier.error("We are unable to retrieve your files at this time. Please try again later");
    }
    setLoading(false);
  };

  const viewFile = async (file: FilesTableFile | TeamMemberDocumentRow) => {
    try {
      if (!("filed_on" in file)) {
        navigate(`/documents/${file._id}`);
      } else {
        handleDownloadTaxDocument(file);
      }
    } catch (e) {
      console.log(e);
      Notifier.error("There was an getting the link to the file. We're looking into it.");
    }
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = [
    {
      label: "Add document",
      className: "button-2 no-margin table-button",
      action: () => handleNewButtonClick(),
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => docAbilities.teamPredicate("create")(teamMember),
    },
  ];

  const dynamicActions: TableActionLink[] = [
    {
      label: "Download",
      className: loading ? "button-1 inactive" : "button-1",
      action: () => handleDownload(),
      loading: downloading,
      icon: <Download weight="bold" style={{ marginRight: 3 }} />,
    },
    {
      label: "Send",
      className: loading ? "button-1 inactive" : "button-1",
      action: () => setIsSending(true),
      icon: <PaperPlaneTilt weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () =>
        docAbilities.can("read", selectedRows, "team_member") &&
        selectedRows.every((row) => row.document_type !== "tax form"),
    },
    {
      label: "Delete",
      className: loading ? "button-3 inactive" : "button-3",
      action: () => handleBulkArchive(),
      loading: bulkRemoving,
      icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => docAbilities.can("delete", selectedRows, "team_member"),
    },
  ];

  const columns: ColumnConfig<TeamMemberDocumentRow>[] = [
    {
      field: "name",
      headerName: "File",
      dataType: "string",
      minWidth: 200,
    },
    {
      field: "document_type",
      headerName: "Type",
      dataType: "string",
      displayType: "badge",
      colors: {
        personal: "light-blue",
        company: "light-green",
        job: "light-purple",
        "tax form": "light-red",
      },
    },
    {
      field: "status",
      headerName: "Status",
      dataType: "string",
      displayType: "badge",
      colors: {
        Signed: "green",
        Viewed: "blue",
        "Awaiting signature": "yellow",
        Shared: "light-gray",
        "Not viewed": "light-red",
        Pending: "orange",
        Complete: "green",
      },
    },
    {
      field: "tag_values",
      headerName: "Tags",
      dataType: "string",
      cellRenderer: (params) => buildTagsCell(params.data),
      filter: "agSetColumnFilter",
      valueFormatter: (params) => params.value,
    },
    {
      field: "created_at",
      headerName: "Created at",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "ff",
      maxWidth: 250,
    },
  ];

  /*********************************************************
   *  Call useEffect hooks
   **********************************************************/
  useEffect(() => {
    getDocuments();
  }, []);

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderTable = () => {
    return (
      <TableV2
        id={"team-documents-table"}
        resource="documents"
        data={data}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={viewFile}
      />
    );
  };

  const renderRemoveModal = () => {
    const headerText =
      selectedRows.length > 1
        ? "Are you sure you want to delete these files?"
        : "Are you sure you want to delete this file?";

    return (
      <BasicModal
        headerText={headerText}
        bodyText={"This action cannot be undone."}
        button1Text="Cancel"
        button2Text="Remove"
        button1Action={() => setBulkRemoving(false)}
        button2Action={() => handleRemove()}
        loading={removeLoading}
      />
    );
  };

  return (
    <div className="files-table-wrapper">
      {isSending && (
        <SendMessageModal
          hide={() => {
            setIsSending(false);
            setSelectedRows([]);
          }}
          file_ids={selectedRowIds}
        />
      )}
      {!removeLoading && renderTable()}
      {downloading && (
        <LoadingModal text={"Downloading file" + ((selectedRows?.length || 0) > 1 ? "s" : "")} />
      )}
      {bulkRemoving && renderRemoveModal()}
      {upload && (
        <DocumentWizard
          onExit={handleDocumentWizardExit}
          onComplete={handleDocumentWizardExit}
          parentId={teamMember._id}
          parentType={"team_member"}
          mode="create"
        />
      )}
    </div>
  );
};

export default TeamMemberDocumentsTable;
