import React, { useEffect } from "react";
import { IntegrationStatusObj } from "backend/services/integrations/integration-types";
import { Badge, Button, Loader } from "ui";
import { MiterIntegrationKey } from "backend/models/integrations/integrations";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useGetSetupLink } from "dashboard/hooks/integrations/useGetSetupLink";
import { MiterIntegrationForCompany } from "dashboard/miter";

export const integrationConnectionStatusColors = {
  "Connection error": "red",
  Connected: "green",
  "Pending setup": "yellow",
  Unknown: "gray",
};

export const RECONNECTABLE_INTEGRATIONS: MiterIntegrationKey[] = ["netsuite"];

interface IntegrationConnectionStatusProps {
  connectionStatus: IntegrationStatusObj;
  integration: MiterIntegrationForCompany;
}

export const IntegrationConnectionStatus: React.FC<IntegrationConnectionStatusProps> = ({
  connectionStatus,
  integration,
}) => {
  const { key } = integration;
  const { shouldReauthenticate } = connectionStatus;

  if (RECONNECTABLE_INTEGRATIONS.includes(key) && shouldReauthenticate) {
    return <ReconnectIntegration integration={integration} />;
  }

  return (
    <Badge
      text={connectionStatus.status}
      tooltip={connectionStatus.userMessage}
      style={{ margin: 0 }}
      color={integrationConnectionStatusColors[connectionStatus.status]}
    />
  );
};

const ReconnectIntegration: React.FC<Omit<IntegrationConnectionStatusProps, "connectionStatus">> = ({
  integration,
}) => {
  const activeCompanyId = useActiveCompanyId();

  const { getSetupLink, loadingSetupLink, goToSetupLink } = useGetSetupLink({
    integration,
    activeCompanyId,
  });

  useEffect(() => {
    getSetupLink();
  }, []);

  if (loadingSetupLink) return <Loader small={true} />;

  return (
    <Button onClick={goToSetupLink} className="button-2">
      Reconnect To NetSuite
    </Button>
  );
};
