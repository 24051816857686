import React, { useEffect, useMemo, useRef, useState } from "react";
import PaymentContext from "./paymentContext";
import { PaymentWarning } from "backend/utils/payroll/types";
import PayrollContext from "../payrollContext";
import { PayrollAdjustment } from "../../payrollTypes";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

type PaymentWarningsTableEntry = {
  _id: string;
  warning_text: string;
  acknowledgedCheckbox: JSX.Element;
};

export const PaymentWarnings: React.FC = () => {
  const { payment, tm, payroll } = React.useContext(PaymentContext);
  const { recalculatePayroll } = React.useContext(PayrollContext);

  const isDraftPayroll = payroll?.check_payroll.status === "draft";

  const [loading, setLoading] = useState(false);

  const AcknowledgedCheckbox: React.FC<{ warning: PaymentWarning }> = ({ warning }) => {
    const mounted = useRef(false);
    const [checked, setChecked] = useState(warning.acknowledged);

    const handleCheckboxChange = async () => {
      setLoading(true);
      const newPayrollAdjustments: PayrollAdjustment[] =
        payroll!.adjustments?.filter((a) => a.team_member !== tm._id.toString()) || [];

      let newAcknowledgedWarnings = payment.adjustment?.acknowledged_warnings || [];

      if (checked) {
        newAcknowledgedWarnings.push(warning.signature);
      } else {
        newAcknowledgedWarnings = newAcknowledgedWarnings.filter((w) => w !== warning.signature);
      }

      // Add the TM's adjustment to the list.
      newPayrollAdjustments.push({
        ...payment.adjustment,
        team_member: tm._id.toString(),
        acknowledged_warnings: newAcknowledgedWarnings,
      });
      await recalculatePayroll({ adjustments: newPayrollAdjustments, tms: [tm._id.toString()] });
      setLoading(false);
    };

    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true;
        return;
      } else {
        handleCheckboxChange();
      }
    }, [checked]);

    return (
      <input
        onChange={(e) => setChecked(e.target.checked)}
        disabled={!isDraftPayroll}
        type="checkbox"
        checked={checked}
      ></input>
    );
  };

  const tableData: PaymentWarningsTableEntry[] = useMemo(() => {
    return (
      payment.warnings?.map((w) => {
        return { ...w, _id: w.signature, acknowledgedCheckbox: <AcknowledgedCheckbox warning={w} /> };
      }) || []
    );
  }, [payment.warnings]);

  const columns: ColumnConfig<PaymentWarningsTableEntry>[] = [
    { field: "warning_text", headerName: "Warning", dataType: "string" },
    { field: "acknowledgedCheckbox", headerName: "Acknowledged", dataType: "component" },
  ];

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Warnings</div>
      </div>
      <TableV2
        id="payment-warnings-table"
        resource="warnings"
        data={tableData}
        columns={columns}
        hideSelectColumn
        hideSecondaryActions
        hideSearch
        isLoading={loading}
      />
    </div>
  );
};
