import AppContext from "dashboard/contexts/app-context";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import { toDollarFormat } from "miter-utils";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Formblock, Loader, Notifier, TableV2 } from "ui";
import { Option } from "ui/form/Input";
import { ColumnConfig } from "ui/table-v2/Table";

type Props = {};

type TableEntry = {
  _id: string;
  last_name: string;
  first_name: string;
  ssn: string;
  dob?: string;
  start_date: string;
  end_date: string;
  rehire_date: string;
  residence?: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postal_code: string;
  };
  phone: string;
  email: string;
};

export const ContractorsPlanContributions: React.FC<Props> = () => {
  const { setReverifyUser, setOnReverifyUser } = React.useContext(AppContext);
  const form = useForm();
  const activeCompanyId = useActiveCompanyId();

  const [tableData, setTableData] = useState<TableEntry[] | undefined>();
  const [payrollOptions, setPayrollOptions] = useState<Option<string>[] | undefined>();
  const [selectedPayrollOption, setSelectedPayrollOption] = useState<Option<string | null> | null>(null);

  const getPayrolls = async () => {
    try {
      if (!activeCompanyId) throw new Error("No active company");

      const response = await MiterAPI.payrolls.get_table({ company: activeCompanyId! });
      if (response.error) throw new Error(response.error);

      const sortedPayrolls = response
        .filter((p) => {
          return !p.void_of && p.status !== "draft" && p.type === "regular";
        })
        .sort((a, b) => b.period_end.localeCompare(a.period_end));

      const options: Option<string>[] = [];
      for (const p of sortedPayrolls) {
        if (!p.pay_schedule) continue;
        const startString = DateTime.fromISO(p.period_start).toFormat("LLL dd");
        const endString = DateTime.fromISO(p.period_end).toFormat("LLL dd, yyyy");
        options.push({ label: `${p.pay_schedule}: ${startString} to ${endString}`, value: p._id });
      }
      setPayrollOptions(options);
    } catch (e) {
      Notifier.error("There was an error. We're looking into it!");
    }
  };

  const getContributionsData = async () => {
    if (!selectedPayrollOption) return;
    setTableData(undefined);
    try {
      const response = await MiterAPI.reports.create({
        type: "contractors_plan_contributions",
        params: { payrollId: selectedPayrollOption.value },
        format: "json",
      });
      if (response.error) throw new Error(response.error);
      setTableData(
        response.map((r) => {
          return {
            ...r,
            erPwContributions: r.erPwContributions || undefined,
            eePreTaxDeferrals: r.eePreTaxDeferrals || undefined,
            eePostTaxDeferrals: r.eePostTaxDeferrals || undefined,
            erMatchContributions: r.erMatchContributions || undefined,
          };
        })
      );
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error. We're looking into it!");
    }
  };

  useEffect(() => {
    setReverifyUser(true);
    getPayrolls();
    setOnReverifyUser(() => getContributionsData);
  }, []);

  useEffect(() => {
    getContributionsData();
  }, [selectedPayrollOption]);

  return (
    <div>
      {payrollOptions ? (
        <>
          <div style={{ maxWidth: 400 }}>
            <Formblock
              label="Payroll"
              type="select"
              options={payrollOptions}
              name="pay_schedule"
              value={selectedPayrollOption}
              form={form}
              editing={true}
              onChange={setSelectedPayrollOption}
            />
          </div>
          {selectedPayrollOption && (
            <TableV2
              id="cp-contributions"
              resource="team members"
              data={tableData}
              columns={columns}
              showUndefinedValuesAs=" "
              paginationPageSize={30}
              isLoading={!tableData}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export const columns: ColumnConfig<TableEntry>[] = [
  {
    field: "last_name",
    headerName: "Last name",
    dataType: "string",
  },
  {
    field: "first_name",
    headerName: "First name",
    dataType: "string",
  },
  {
    field: "ssn",
    headerName: "SSN",
    dataType: "string",
  },
  {
    field: "erPwContributions",
    headerName: "Employer PW contributions",
    dataType: "string",
    valueFormatter: toDollarFormat,
  },
  {
    field: "eePreTaxDeferrals",
    headerName: "Employee pre-tax deferrals",
    dataType: "string",
    valueFormatter: toDollarFormat,
  },
  {
    field: "eePostTaxDeferrals",
    headerName: "Employee post-tax deferrals",
    dataType: "string",
    valueFormatter: toDollarFormat,
  },
  {
    field: "erMatchContributions",
    headerName: "Employer match contributions",
    dataType: "string",
    valueFormatter: toDollarFormat,
  },
  {
    field: "dob",
    headerName: "Date of birth",
    dataType: "date",
    dateFormat: "MM/dd/yyyy",
  },
  {
    field: "start_date",
    headerName: "Date of hire",
    dataType: "date",
    dateFormat: "MM/dd/yyyy",
  },
  {
    field: "end_date",
    headerName: "Date of termination",
    dataType: "date",
    dateFormat: "MM/dd/yyyy",
  },
  {
    field: "rehire_date",
    headerName: "Date of rehire",
    dataType: "date",
    dateFormat: "MM/dd/yyyy",
  },
  {
    field: "residence.line1",
    headerName: "Address line 1",
    dataType: "string",
  },
  {
    field: "residence.line2",
    headerName: "Address line 2",
    dataType: "string",
  },
  {
    field: "residence.city",
    headerName: "City",
    dataType: "string",
  },
  {
    field: "residence.state",
    headerName: "State",
    dataType: "string",
  },
  {
    field: "residence.postal_code",
    headerName: "Zip code",
    dataType: "string",
  },
  {
    field: "phone",
    headerName: "Phone number",
    dataType: "string",
  },
  {
    field: "email",
    headerName: "Email address",
    dataType: "string",
  },
];
