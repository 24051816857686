import React, { FC, useCallback, useState, useMemo, useEffect } from "react";
import { cleanFloatingPointErrors } from "miter-utils";
import { numberValidator } from "dashboard/utils/validators";
import { useActiveCompany, useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { ForageRequest } from "backend/utils/forage/forage-types";
import { MiterFilterArray } from "backend/utils";
import { AggregatedEquipmentTimesheet, MiterAPI } from "dashboard/miter";
import { TableV2, ColumnConfig, TableTogglerConfig } from "ui/table-v2/Table";
import { formatTimesheetTime } from "dashboard/utils/timesheetUtils";
import { ClockCounterClockwise, Stack } from "phosphor-react";
import BulkCreateEquipmentTimesheetModal from "./BulkCreateEquipmentTimesheetModal";
import { DateTime } from "luxon";
import EquipmentTimesheetModal from "./EquipmentTimesheetModal";
import { Params, useNavigate, useParams } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useEquipmentTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useEquipmentTimesheetAbilities";
import { EquipmentTimesheetImporter } from "./EquipmentTimesheetImporter";
import { ImportHistory } from "../importer/ImportHistory";

type EquipmentTimesheetTableProps = {
  status?: string;
};

const EquipmentTimesheetsTable: FC<EquipmentTimesheetTableProps> = ({ status }) => {
  // hooks
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();
  const { can } = useMiterAbilities();
  const equipmentTimesheetAbilities = useEquipmentTimesheetAbilities();

  // state
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEquipmentTimesheetId, setSelectedEquipmentTimesheetId] = useState<string | undefined>();
  const [openBulkCreateModal, setOpenBulkCreateModal] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [pathBeforeRowClick, setPathBeforeRowClick] = useState<string | undefined>();
  const [showImportHistory, setShowImportHistory] = useState(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState(false);

  // URL deeplink
  const { id: idFromUrl } = useParams<Params>();
  useEffect(() => {
    setSelectedEquipmentTimesheetId(idFromUrl);
  }, [idFromUrl]);

  // callback
  const getData = useCallback(
    async (query: ForageRequest) => {
      // filters
      const filter: MiterFilterArray = [];
      if (status) {
        filter.push({ field: "status", type: "string", value: status });
      }
      if (query.filter) {
        filter.push(...query.filter);
      }

      const abilitiesFilter = equipmentTimesheetAbilities.filter("read");
      if (abilitiesFilter) filter.push(abilitiesFilter);

      const sort = query.sort || [];

      const forageFunction = MiterAPI.equipment_timesheets.forage;

      const data = await forageFunction({ ...query, filter, sort });
      return data;
    },
    [activeCompanyId, equipmentTimesheetAbilities.filter]
  );

  // handlers
  const handleSelect = (selections) => {
    setSelectedRows(selections);
  };

  const handleRowClick = (row) => {
    setPathBeforeRowClick("/equipment/timesheets?status=" + row.status);
    setSelectedEquipmentTimesheetId(row._id);
    navigate(`/equipment/timesheets/${row._id}`);
  };

  const handleCreate = () => {
    setOpenBulkCreateModal(true);
  };

  // config
  const hideModal = () => {
    setSelectedEquipmentTimesheetId(undefined);
    setRefreshCount(refreshCount + 1);
    let tableURL = "/equipment/timesheets";
    if (pathBeforeRowClick) {
      tableURL = pathBeforeRowClick;
      setPathBeforeRowClick(undefined);
    }
    navigate(tableURL);
  };

  const onEquipmentTimesheetImport = () => {
    setShowImportHistory(true);
    setShowLatestImportResult(true);
    setRefreshCount(refreshCount + 1);
  };

  const equipmentTimesheetsTableColDefs: ColumnConfig<AggregatedEquipmentTimesheet>[] = useMemo(
    () => [
      {
        field: "clock_in",
        headerName: "Date",
        dataType: "number",
        dateType: "timestamp",
        minWidth: 150,
        enableRowGroup: true,
        sortField: "clock_in",
        filterField: "clock_in",
        groupField: "clock_in",
        groupFormatter: "seconds",
        valueGetter: (params) => {
          if (!params.data?.clock_in) return;
          const timezone = params.data?.timezone || activeCompany?.timezone || DateTime.local().zoneName;
          return DateTime.fromSeconds(params.data?.clock_in).setZone(timezone).toFormat("MMM dd, yyyy");
        },
        displayRangeFilter: true,
      },
      {
        field: "created_by_user",
        headerName: "Created by",
        dataType: "string" as const,
        enableRowGroup: true,
        valueFormatter: (params) => {
          return params.value?.first_name + " " + params.value?.last_name || "Unknown user";
        },
        initialHide: true,
      },
      {
        field: "equipment",
        headerName: "Equipment",
        dataType: "string" as const,
        enableRowGroup: true,
        valueFormatter: (params) => {
          return params.value?.name || "Deleted equipment";
        },
      },
      {
        field: "team_member",
        headerName: "Team Member",
        dataType: "string" as const,
        enableRowGroup: true,
        valueFormatter: (params) => {
          return params.value?.full_name || "None";
        },
      },
      {
        field: "status",
        headerName: "Status",
        dataType: "string" as const,
        displayType: "badge" as const,
        colors: {
          unapproved: "light-gray",
          approved: "light-blue",
          processing: "light-green",
          paid: "green",
        },
        enableRowGroup: true,
      },
      {
        field: "hours",
        headerName: "Hours",
        dataType: "number",
        minWidth: 100,
        aggFunc: "sum",
        valueFormatter: (params) => cleanFloatingPointErrors(params.value) + "",
        validations: numberValidator({ excludeNegatives: true, excludeZero: true }),
      },
      {
        field: "quantity",
        headerName: "Quantity",
        dataType: "number",
        minWidth: 100,
        aggFunc: "sum",
        validations: numberValidator({ excludeNegatives: true, excludeZero: true }),
      },
      {
        field: "clock_in",
        headerName: "In",
        dataType: "number",
        dateType: "timestamp",
        minWidth: 120,
        valueGetter: (params) => (params.data?.clock_in ? formatTimesheetTime(params.data?.clock_in) : "-"),
      },
      {
        field: "clock_out",
        headerName: "Out",
        dataType: "number",
        dateType: "timestamp",
        minWidth: 120,
        valueGetter: (params) => (params.data?.clock_in ? formatTimesheetTime(params.data?.clock_out) : "-"),
      },
      {
        field: "job.name",
        headerName: "Job",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        filter: "agSetColumnFilter", // filterParam added in TimesheetsTable component
      },
      {
        field: "job.code",
        headerName: "Job code",
        dataType: "string",
        minWidth: 200,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "activity.label",
        useValueFormatterForExport: true,
        headerName: "Activity",
        dataType: "string",
        minWidth: 160,
        enableRowGroup: true,
      },
      {
        field: "activity.cost_code",
        useValueFormatterForExport: true,
        headerName: "Activity code",
        dataType: "string",
        minWidth: 160,
        enableRowGroup: true,
        initialHide: true,
      },
      {
        field: "notes",
        headerName: "Notes",
        dataType: "string",
        minWidth: 150,
      },
    ],
    []
  );

  const togglerConfig: TableTogglerConfig<AggregatedEquipmentTimesheet> = {
    config: [
      { path: "unapproved", label: "Unapproved" },
      { path: "approved", label: "Approved" },
    ],
    field: "status",
    secondary: true,
  };

  // buttons
  const staticButtons = [
    {
      label: "Create",
      className: "button-2 no-margin",
      action: handleCreate,
      icon: <Stack weight="bold" style={{ marginRight: 3 }} />,
      important: true,
      shouldShow: () => can("equipment:timesheets:create"),
    },
    {
      key: "import",
      component: <EquipmentTimesheetImporter onFinish={onEquipmentTimesheetImport} />,
      shouldShow: () => can("equipment:timesheets:create"),
    },
    {
      key: "import-history",
      className: "button-1",
      action: () => setShowImportHistory(true),
      label: "Import history",
      icon: <ClockCounterClockwise weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => can("timesheets:personal:create") || can("timesheets:others:create"),
    },
  ];

  // table
  const renderTable = () => {
    return (
      <TableV2
        id="equipment-timesheets-table"
        resource="equipment timesheets"
        columns={equipmentTimesheetsTableColDefs}
        toggler={togglerConfig}
        onSelect={handleSelect}
        onClick={handleRowClick}
        defaultSelectedRows={selectedRows}
        ssr={true}
        staticActions={staticButtons}
        refreshCount={refreshCount}
        getData={getData}
        editable={true}
        showReportViews={true}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        hideBulkEdit={true}
      />
    );
  };

  const renderBulkCreateModal = () => {
    if (!openBulkCreateModal) return null;
    return (
      <BulkCreateEquipmentTimesheetModal
        onHide={() => {
          setOpenBulkCreateModal(false);
          setRefreshCount(refreshCount + 1);
        }}
      />
    );
  };

  const renderEquipmentTimesheetModal = () => {
    if (!selectedEquipmentTimesheetId) return null;
    return (
      <EquipmentTimesheetModal
        equipmentTimesheetId={selectedEquipmentTimesheetId}
        onHide={hideModal}
        onSubmit={() => setRefreshCount(refreshCount + 1)}
      />
    );
  };

  const renderImportHistory = () => {
    if (!showImportHistory) return null;
    return (
      <ImportHistory
        id={"equipment_timesheets"}
        resource={"equipment timesheets"}
        onClose={() => {
          setShowImportHistory(false);
          setShowLatestImportResult(false);
        }}
        openLastResult={showLatestImportResult}
      />
    );
  };

  return (
    <div>
      {renderTable()}
      {renderEquipmentTimesheetModal()}
      {renderBulkCreateModal()}
      {renderImportHistory()}
    </div>
  );
};

export default EquipmentTimesheetsTable;
