import React, { useEffect, useState } from "react";

import styles from "./BulkSelector.module.css";
import { ActionModal } from "../modal";
import BulkSelector, { BulkSelectOptionValue, BulkSelectProps } from "./BulkSelector";
import { Assign } from "utility-types";

type Props = Assign<
  BulkSelectProps,
  {
    /** On submit */
    onSubmit: (selections: BulkSelectOptionValue[]) => void;
    /** On cancel */
    onCancel: () => void;
    /** Title */
    title?: string;
    /** Submit text */
    submitText?: string;
    /** Submitting */
    submitting?: boolean;
  }
>;

const BulkSelectorModal: React.FC<Props> = (props) => {
  const { onSubmit, onCancel, title, submitText, submitting, selections } = props;

  const [selectedOptions, setSelectedOptions] = useState<BulkSelectOptionValue[]>(selections || []);

  useEffect(() => {
    setSelectedOptions(selections || []);
  }, [selections]);

  const handleSubmit = () => {
    onSubmit(selectedOptions);
  };

  return (
    <ActionModal
      className={styles["bulk-select-modal"]}
      wrapperClassName={styles["bulk-select-modal-wrapper"]}
      bodyClassName={styles["bulk-select-modal-body"]}
      headerText={title || `Choose ${props.resource}`}
      submitText={submitText || "Save"}
      cancelText={"Cancel"}
      onCancel={onCancel}
      onHide={onCancel}
      onSubmit={handleSubmit}
      showSubmit={true}
      showCancel={true}
      loading={submitting}
      wrapperStyle={{ width: "80%" }}
    >
      <BulkSelector {...props} onChange={setSelectedOptions} selections={selectedOptions} />
    </ActionModal>
  );
};

export default BulkSelectorModal;
