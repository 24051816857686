import { useActivityOptions, useJobOptions } from "dashboard/hooks/atom-hooks";
import { Activity, AggregatedJob, IntegrationConnection, Job } from "dashboard/miter";
import { buildAtomicMongoUpdateFromNested, convertUndefinedToMongoUnset } from "dashboard/utils";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Formblock, Loader } from "ui";
import { Option } from "ui/form/Input";
import {
  IntacctAttachmentFolder,
  IntacctConnectionEntityPushObject,
  IntacctConnectionJournal,
  IntacctConnectionMetadata,
  IntacctConnectionTimeType,
  IntacctCreditCard,
  IntacctDateFormatOptions,
  IntacctJournal,
  IntacctTimeType,
} from "backend/services/intacct/intacct-types";
import { baseSensitiveCompare } from "miter-utils";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";
import { SettingsCard } from "ui/settings/SettingsCard";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { useFetchIntacctObjects } from "dashboard/hooks/integrations/sage_intacct/useFetchIntacctObjects";
import { useHasBillingFlagOptionForExpMgmt } from "dashboard/gating";

export type IntacctConfigData = {
  timeTypes?: IntacctTimeType[];
  journals?: IntacctJournal[];
  creditCards?: IntacctCreditCard[];
  defaultEntityLabel?: string;
  attachmentFolders?: IntacctAttachmentFolder[];
};

export type KeyLabelPair<T> = { key: T; label: string; tooltip?: string };

export const INTACCT_DATE_FORMAT_OPTIONS: IntacctDateFormatOptions[] = [
  "MM/dd/yyyy",
  "MM/dd/yy",
  "MM.dd.yy",
  "MM.dd.yyyy",
  "MM-dd-yy",
  "MM-dd-yyyy",
  "MM dd yy",
  "MM dd yyyy",
  "dd/MM/yy",
  "dd/MM/yyyy",
  "dd.MM.yy",
  "dd.MM.yyyy",
  "dd-MM-yy",
  "dd-MM-yyyy",
  "dd MM yy",
  "dd MM yyyy",
  "yy/MM/dd",
  "yyyy/MM/dd",
  "yy.MM.dd",
  "yyyy.MM.dd",
  "yy-MM-dd",
  "yyyy-MM-dd",
  "yy MM dd",
  "yyyy MM dd",
];

const INPUTLENGTH = 250;

const defaultIntacctLabelStyle = { minWidth: 230 };

const intacctJobPredicate = (j: AggregatedJob | Job) => j.integrations?.sage_intacct;

const intacctActivityPredicate = (a: Activity) =>
  a.integrations?.sage_intacct?.intacctStandardTask && isTimesheetScoped(a);

const pushEntityObjectKeys: KeyLabelPair<IntacctConnectionEntityPushObject>[] = [
  { key: "employees", label: "employees" },
  { key: "timesheets", label: "timesheets" },
  { key: "glEntries", label: "GL entries and expenses" },
];

const timeTypesKeys: KeyLabelPair<IntacctConnectionTimeType>[] = [
  { key: "reg", label: "Regular" },
  { key: "ot", label: "Overtime" },
  { key: "dot", label: "Double overtime" },
  { key: "pto", label: "Time off", tooltip: "Vacation, sick, and holiday hours" },
];

const journalKeys: KeyLabelPair<IntacctConnectionJournal>[] = [
  { key: "payrolls", label: "Payrolls journal" },
  {
    key: "expense_management",
    label: "Expense mgmt journal",
    tooltip:
      "This covers all money movement from Expense Management: Miter card funding transfers, bill pay and reimbursements paid via ACH.",
  },
];

const projectAddressFieldOptions: Option<IntacctConnectionMetadata["projectAddressField"]>[] = [
  { label: "Ship-to contact", value: "ship_to_contact" },
  { label: "Bill-to contact", value: "bill_to_contact" },
  { label: "Primary contact", value: "primary_contact" },
];

const employeeContactNameOptions: Option<IntacctConnectionMetadata["employeeContactNameFormat"]>[] = [
  { label: "First Last", value: "first_last" },
  { label: "Last, First", value: "last_comma_first" },
  { label: "Last; First", value: "last_semicolon_first" },
];

const dateFormatOptions: Option<IntacctDateFormatOptions>[] = INTACCT_DATE_FORMAT_OPTIONS.map((option) => {
  return {
    label: option,
    value: option,
  };
});

export const IntacctIntegrationConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
  intacctConfigData,
  setIntacctConfigData,
  loadingConnectionStatus,
}) => {
  const form = useForm();

  const intacctMetadata: IntacctConnectionMetadata = integration.connection?.metadata?.sage_intacct || {};

  const defaultEntityId = intacctMetadata.entity?.defaultId || undefined;

  const timeTypes = intacctConfigData?.timeTypes || [];
  const journals = intacctConfigData?.journals || [];
  const defaultEntityLabel = intacctConfigData?.defaultEntityLabel || defaultEntityId;

  const defaultTimesheetJobOptions = useJobOptions({ predicate: intacctJobPredicate });

  const defaultTimesheetActivityOptions = useActivityOptions(null, {
    predicate: intacctActivityPredicate,
  });

  const timeTypeOptions = useMemo(() => {
    return timeTypes
      .map((t) => ({ label: t.NAME, value: t.NAME }))
      .sort((a, b) => baseSensitiveCompare(a.label, b.label));
  }, [timeTypes]);

  const journalOptions = useMemo(() => {
    return journals
      .map((t) => ({ label: t.TITLE, value: t.SYMBOL }))
      .sort((a, b) => baseSensitiveCompare(a.label, b.label));
  }, [journals]);

  const { loading: loadingConfigData } = useFetchIntacctObjects({
    integrationConnectionId: integration.connection?._id,
    disabled: loadingConnectionStatus || !!intacctConfigData,
    objectTypes: ["entities", "timeTypes", "journals", "creditCards", "attachmentFolders"],
    onData: (intacctData) => {
      const { entities, ...rest } = intacctData;
      const entity = entities?.find((e) => e.LOCATIONID === defaultEntityId);
      let entitylabel: string | undefined;
      if (entity) entitylabel = `${entity.LOCATIONID} - ${entity.NAME}`;

      setIntacctConfigData({ ...rest, defaultEntityLabel: entitylabel });
    },
  });

  const updateIntacctMetadata = async (update: DeepPartial<IntacctConnectionMetadata>) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { sage_intacct: update } };
    const flattened = buildAtomicMongoUpdateFromNested(raw);
    const finalUpdate = convertUndefinedToMongoUnset(flattened);
    await updateIntegrationConnection(finalUpdate);
  };

  const hasBillingFlagOptionForExpMgmt = useHasBillingFlagOptionForExpMgmt();

  // For Intacct, getting the connection status generates a new API session, which will invalidate existing sessions, so we have to wait before we can fetch other data
  if (loadingConnectionStatus) return <Loader />;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Entity configuration">
        <Formblock
          type="text"
          label="Default entity ID"
          defaultString={defaultEntityLabel}
          editing={false}
          labelStyle={defaultIntacctLabelStyle}
        />
        {pushEntityObjectKeys.map(({ key, label }) => {
          return (
            <Formblock
              key={key}
              underlineTooltip={true}
              type="checkbox"
              label={`Push ${label} to entity level`}
              labelStyle={defaultIntacctLabelStyle}
              labelInfo={`By default, Miter will push ${label} to the top-level. Check this box to instead push directly to the "${defaultEntityLabel}" level.`}
            >
              <input
                type="checkbox"
                style={{ marginLeft: 0 }}
                defaultChecked={intacctMetadata.pushToEntityLevel?.[key]}
                onChange={(e) => updateIntacctMetadata({ pushToEntityLevel: { [key]: e.target.checked } })}
              />
            </Formblock>
          );
        })}
        <Formblock
          form={form}
          options={dateFormatOptions}
          name={"dateFormatOption"}
          type="select"
          label={"Date format option"}
          labelInfo={"Sets the date format Miter will use when sending data to Intacct"}
          onChange={(o) => updateIntacctMetadata({ dateFormat: o?.value || undefined })}
          defaultValue={intacctMetadata.dateFormat}
          editing={true}
          labelStyle={defaultIntacctLabelStyle}
          isClearable
          inputProps={{ style: { width: INPUTLENGTH } }}
          underlineTooltip={true}
        />
      </SettingsCard>
      <SettingsCard title="Work breakdown structure sync settings">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="pullOtherLocationProjects"
          type="checkbox"
          text="Automatically pull in jobs associated with other locations"
          label="Pull other-location jobs"
          defaultValue={intacctMetadata?.pullOtherLocationProjects}
          onChange={(e) => updateIntacctMetadata({ pullOtherLocationProjects: e.target.checked })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          labelInfo={`When auto-syncing projects, automatically pull in jobs associated with locations that don't roll up to the entity ${defaultEntityLabel}.`}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="defaultToNoActivitiesForJob"
          type="checkbox"
          text="When a job is imported, start with an empty activities list"
          label="Empty activities list"
          defaultValue={intacctMetadata?.defaultToNoActivitiesForJob}
          onChange={(e) => updateIntacctMetadata({ defaultToNoActivitiesForJob: e.target.checked })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          labelInfo={`Check this box if you want jobs to be imported with an empty list of selectable activities that is subsequently populated based on associated cost codes for the job in Intacct. Otherwise, the selectable activities for new jobs will be all company-level activities`}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="syncTasksWithProjects"
          type="checkbox"
          text="When manually syncing jobs, automatically sync all related activities."
          label="WBS auto-sync"
          defaultValue={intacctMetadata?.syncTasksWithProjects}
          onChange={(e) => updateIntacctMetadata({ syncTasksWithProjects: e.target.checked })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          labelInfo="Keep your Work Breakdown Structure (Jobs, Activities, Cost Types) in sync across Intacct and Miter. When manually syncing jobs, automatically sync all related activities."
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="onlyPullSyncedTasksAndCostTypes"
          type="checkbox"
          text="When automatically syncing activities, only sync objects currently in Miter."
          label="Sync only existing WBS"
          defaultValue={intacctMetadata?.onlyPullSyncedTasksAndCostTypes}
          onChange={(e) => updateIntacctMetadata({ onlyPullSyncedTasksAndCostTypes: e.target.checked })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          labelInfo="Check this box if you only want a subset of your Intacct cost codes to appear in Miter when new jobs are created and activities are synced. Define the subset during manual sync and future auto-syncs will only pull in those."
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="syncProjectManagerAsJobSupervisor"
          type="checkbox"
          text="When automatically syncing jobs, sync project managers as supervisors."
          label="Sync Job Supervisors"
          defaultValue={intacctMetadata?.syncProjectManagerAsJobSupervisor}
          onChange={(e) => updateIntacctMetadata({ syncProjectManagerAsJobSupervisor: e.target.checked })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          labelInfo="Check this box if you want to sync the Intacct Project Manager as the Job Supervisor in Miter."
          editing={true}
        />
        <div className="vertical-spacer-small" />
        <Formblock
          form={form}
          options={projectAddressFieldOptions}
          name={"projectAddressField"}
          type="select"
          label={"Project address field"}
          labelInfo={"Which Intacct project field Miter should look at to determine the Miter job address"}
          onChange={(o) => updateIntacctMetadata({ projectAddressField: o?.value || undefined })}
          defaultValue={intacctMetadata.projectAddressField}
          editing={true}
          labelStyle={defaultIntacctLabelStyle}
          isClearable
          inputProps={{ style: { width: INPUTLENGTH } }}
          underlineTooltip={true}
        />
      </SettingsCard>
      <SettingsCard title="GL entry sync settings">
        {loadingConfigData ? (
          <Loader />
        ) : (
          journalKeys.map(({ key, label, tooltip }) => {
            return (
              <Formblock
                form={form}
                options={journalOptions}
                name={"journals." + key}
                labelInfo={tooltip}
                type="select"
                label={label}
                defaultValue={intacctMetadata.journals?.[key]}
                onChange={(o: Option<string> | null) =>
                  updateIntacctMetadata({ journals: { [key]: o?.value || undefined } })
                }
                editing={true}
                labelStyle={defaultIntacctLabelStyle}
                isClearable
                inputProps={{ style: { width: INPUTLENGTH } }}
                key={key}
              />
            );
          })
        )}
        <Formblock
          form={form}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="useCurrentLocationData"
          type="checkbox"
          text="When syncing GL entries, always use the job and employee's current location."
          label="Use current location data"
          defaultValue={intacctMetadata?.useCurrentLocationData}
          onChange={(e) => updateIntacctMetadata({ useCurrentLocationData: e.target.checked })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          editing={true}
        />
        {hasBillingFlagOptionForExpMgmt && (
          <Formblock
            form={form}
            inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
            name="enableBillableFlagForGl.expense_management"
            type="checkbox"
            text="When syncing non-payroll reimbursement GL entries, explicitly set the 'Billable' property."
            label="Enable billable flag for reimbursements"
            defaultValue={intacctMetadata?.enableBillableFlagForGl?.expense_management}
            onChange={(e) =>
              updateIntacctMetadata({ enableBillableFlagForGl: { expense_management: e.target.checked } })
            }
            labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
            editing={true}
          />
        )}
      </SettingsCard>
      <SettingsCard title="Team member sync settings">
        <Formblock
          form={form}
          options={employeeContactNameOptions}
          name="employeeContactNameFormat"
          type="select"
          label="Contact name format"
          labelInfo="How Miter should format the contact name when creating employees in Intacct."
          defaultValue={intacctMetadata.employeeContactNameFormat}
          onChange={(o: Option<IntacctConnectionMetadata["employeeContactNameFormat"]> | null) =>
            o?.value && updateIntacctMetadata({ employeeContactNameFormat: o.value })
          }
          editing={true}
          labelStyle={defaultIntacctLabelStyle}
          inputProps={{ style: { width: INPUTLENGTH } }}
          underlineTooltip={true}
        />
      </SettingsCard>
      <SettingsCard
        title="Timesheet sync"
        subtitle="Note: Intacct requires all timesheet entries to have both a Project and a Cost Code."
      >
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="includeApproved"
          type="checkbox"
          text="Include approved timesheets during sync in addition to processing and paid timesheets."
          label="Approved timesheets"
          defaultValue={intacctMetadata?.timesheetSync?.includeApproved}
          onChange={(e) => updateIntacctMetadata({ timesheetSync: { includeApproved: e.target.checked } })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name="includeTimeOff"
          type="checkbox"
          text="Include time off hours during sync in addition to regular and overtime hours."
          label="Time off hours"
          defaultValue={intacctMetadata?.timesheetSync?.includeTimeOff}
          onChange={(e) => updateIntacctMetadata({ timesheetSync: { includeTimeOff: e.target.checked } })}
          labelStyle={{ ...defaultIntacctLabelStyle, justifyContent: "center" }}
          editing={true}
        />
        <div className="vertical-spacer-five" />
        <Formblock
          form={form}
          options={daysOfWeekOptions}
          name={"startDayOfWeek"}
          type="select"
          label={"Start of week"}
          labelInfo={"What day of the week do weekly timesheets start in your Intacct instance?"}
          onChange={(o) =>
            updateIntacctMetadata({ timesheetSync: { startDayOfWeek: o?.value || undefined } })
          }
          defaultValue={intacctMetadata.timesheetSync?.startDayOfWeek || 1}
          editing={true}
          labelStyle={defaultIntacctLabelStyle}
          inputProps={{ style: { width: INPUTLENGTH } }}
          underlineTooltip={true}
        />
        <Formblock
          form={form}
          options={defaultTimesheetJobOptions}
          name={"defaultProjectForTimesheet"}
          type="select"
          label={"Default project"}
          labelInfo={
            "If a Miter timesheet is missing a project, this project will be used when syncing the timesheet to Sage Intacct."
          }
          onChange={(o) => updateIntacctMetadata({ timesheetSync: { defaultJobId: o?.value || undefined } })}
          defaultValue={intacctMetadata.timesheetSync?.defaultJobId}
          editing={true}
          labelStyle={defaultIntacctLabelStyle}
          isClearable
          inputProps={{ style: { width: INPUTLENGTH } }}
          underlineTooltip={true}
        />
        <Formblock
          form={form}
          options={defaultTimesheetActivityOptions}
          name={"defaultActivityForTimesheet"}
          type="select"
          underlineTooltip={true}
          label={"Default cost code"}
          labelInfo={
            "If a Miter timesheet is missing an activity, this cost code will be used when syncing the timesheet to Sage Intacct."
          }
          onChange={(o) =>
            updateIntacctMetadata({ timesheetSync: { defaultActivityId: o?.value || undefined } })
          }
          defaultValue={intacctMetadata.timesheetSync?.defaultActivityId}
          editing={true}
          labelStyle={defaultIntacctLabelStyle}
          isClearable
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
      </SettingsCard>
      <SettingsCard title="Time types">
        {loadingConfigData ? (
          <Loader />
        ) : (
          timeTypesKeys.map(({ key, label, tooltip }) => {
            return (
              <Formblock
                form={form}
                options={timeTypeOptions}
                name={"timeTypes." + key}
                type="select"
                label={label}
                defaultValue={intacctMetadata.timeTypes?.[key]}
                onChange={(o: Option<string> | null) =>
                  updateIntacctMetadata({ timeTypes: { [key]: o?.value || undefined } })
                }
                editing={true}
                labelStyle={defaultIntacctLabelStyle}
                labelInfo={tooltip}
                underlineTooltip
                isClearable
                inputProps={{ style: { width: INPUTLENGTH } }}
                key={key}
              />
            );
          })
        )}
      </SettingsCard>
      <div className="vertical-spacer-large" />
    </div>
  );
};

const daysOfWeekOptions: Option<number>[] = [
  { label: "Monday (recommended)", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
];
