import React, { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { Checkmark, Alert } from "dashboard/components/icons/icons";
import Notifier from "dashboard/utils/notifier";
import {
  AggregatedI9,
  AggregatedJob,
  AggregatedPayRateGroup,
  AggregatedTeamMember,
  MiterAPI,
  MiterFilterArray,
  UnionRate,
} from "dashboard/miter";
import {
  formatDate,
  addressString,
  formatPhoneNumber,
  titleCase,
  getTenureString,
  useEnabledDemographicQuestions,
} from "dashboard/utils/utils";
import EditTeamMemberModal from "./forms/EditTeamMemberModal";
import DataBox from "dashboard/components/dataBox/DataBox";
import InfoButton from "dashboard/components/information/information";
import { Button, LargeModal, usdString } from "ui";
import AppContext from "dashboard/contexts/app-context";
import {
  renderPayRateLabel,
  convertAnnualRateToDisplayRate,
  defaultTmJobTooltip,
  defaultTmActivityTooltip,
  defaultTmCostTypeTooltip,
  stdTmClassificationTooltip,
  benefitsEligibilityStatusTooltip,
  TeamMemberEditingModalCategory,
  employmentCategoryFormatter,
  tmHsaLimitTooltip,
} from "./TeamUtils";
import { CheckTM } from "backend/utils/check/check-types";
import { renderCustomFieldDefaultString, lookupTimezoneLabel, buildCompanyUserName } from "miter-utils";
import { DateTime } from "luxon";
import { saveAs } from "file-saver";
import I9Wizard from "dashboard/components/i-9s/I9Wizard";
import { baseSensitiveCompare } from "miter-utils";
import {
  useActiveCompany,
  useActiveCompanyId,
  useCostTypes,
  useCrews,
  useLookupActivity,
  useLookupCostType,
  useLookupHolidaySchedule,
  useLookupJob,
  useLookupOtRule,
  useLookupPaySchedule,
  useLookupPrg,
  useLookupRateClassification,
  useUser,
  useLookupStandardClassification,
  useLookupLedgerMapping,
  useLookupCompanyUsers,
  useLookupPolicy,
  useLookupRateDifferential,
  useActiveJobs,
  usePrgs,
  useHasEnabledWcGroups,
  useLookupWcGroup,
  useLookupBenefitsEligibilityGroups,
  useBenefitsEligibilityGroups,
} from "dashboard/hooks/atom-hooks";
import { TeamRateHistory } from "./TeamRateHistory";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useTeamMemberAccessibleJobs } from "dashboard/utils/jobUtils";
import TeamEmploymentHistory from "./TeamEmploymentHistory";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useTeamMemberPermissionGroups } from "dashboard/hooks/useTeamMemberPermissionGroups";
import UpdateI9Modal from "dashboard/components/i-9s/UpdateI9Modal";
import { CompanyDefinedAttributesModal } from "./teamCompanyDefinedAttributes";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { AuditLogHistory } from "dashboard/components/audit-logs/AuditLogHistory";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { TeamMemberChangeCategory } from "backend/models/change-request";
import { capitalize, startCase, truncate } from "lodash";
import Banner from "dashboard/components/shared/Banner";
import { IntegrationEmployeeFields } from "./teamIntegrations/IntegrationEmployeeFields";
import { useOtExemptUtils } from "dashboard/hooks/useOtExempt";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";
import { useCanEditTmHsaLimitValue } from "dashboard/gating";

/***********************************************
 *
 * Simple component to display all team member information from backend.
 * Majority of code formats the team member object returned from the backend.
 * Component heavily uses DataBox component.
 *
 * Implementation note:
 * There's a decent amount of repeated HTML.
 * Though DataBox component supports sending an object to generate the rows, it was turned out to
 * be cleaner and more readable to duplicate the HTML in each render method.
 *
 **********************************************/

type Props = {
  teamMember: AggregatedTeamMember;
};

const TeamMemberDetails: React.FC<Props> = (props) => {
  const { teamMember } = props;
  const { setReverifyUser, setOnReverifyUser, customFields } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const user = useUser();
  const lookupHolidaySchedules = useLookupHolidaySchedule();
  const lookupPaySchedule = useLookupPaySchedule();
  const lookupOtRule = useLookupOtRule();
  const lookupPrg = useLookupPrg();
  const lookupRateClassification = useLookupRateClassification();
  const lookupJob = useLookupJob();
  const lookupActivity = useLookupActivity();
  const lookupGlMapping = useLookupLedgerMapping();
  const lookupRateDifferential = useLookupRateDifferential();
  const lookupCostType = useLookupCostType();
  const lookupStandardClassification = useLookupStandardClassification();
  const lookupUser = useLookupCompanyUsers();
  const lookupPolicy = useLookupPolicy();
  const lookupWcGroup = useLookupWcGroup();
  const lookupBenefitsEligibilityGroup = useLookupBenefitsEligibilityGroups();
  const activeJobs = useActiveJobs();
  const costTypes = useCostTypes();
  const hasCostTypes = costTypes.length > 0;
  const crews = useCrews();
  const teamAbilities = useTeamAbilities();
  const teamMemberPermissionGroups = useTeamMemberPermissionGroups(teamMember._id);
  const miterAbilities = useMiterAbilities();
  const enabledDemographicQuestions = useEnabledDemographicQuestions();
  const { otExemptionIsRelevant } = useOtExemptUtils();
  const hasEnabledWcGroups = useHasEnabledWcGroups();
  const showAuditLogHistory = !!user?.miter_admin || !IS_PRODUCTION;

  const [showCdaModal, setShowCdaModal] = useState(false);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);

  const [showManualI9Modal, setShowManualI9Modal] = useState(false);
  const isDismissed = teamMember.end_date ? true : false;
  const checkTm = teamMember.check_tm as CheckTM | undefined;
  const isEmployee = teamMember.employment_type === "employee";
  const canRequestChange =
    teamAbilities.can("request_change", teamMember) &&
    activeCompany?.settings.team.enable_change_request_policies;
  const enabledChangeRequestCategories = activeCompany?.settings.team.change_request_category || [];
  const enabledKioskClockIn = activeCompany?.settings.timesheets.kiosk?.enabled;

  const [jobsSupervising, jobsSuperintending] = useMemo(() => {
    const supervising: AggregatedJob[] = [];
    const superintending: AggregatedJob[] = [];

    for (const job of activeJobs) {
      if (job.supervisors.some((supervisor) => supervisor._id === teamMember._id)) {
        supervising.push(job);
      }

      if (job.superintendent_ids.includes(teamMember._id)) {
        superintending.push(job);
      }
    }

    return [supervising, superintending];
  }, [activeJobs]);

  const isCategoryEnabled = (category: TeamMemberChangeCategory) =>
    enabledChangeRequestCategories.includes(category) || enabledChangeRequestCategories.includes("all");

  const canEditOrRequestChanges = (category: TeamMemberChangeCategory, sensitive = false) => {
    if (teamAbilities.can("update_sensitive", teamMember)) return true;
    if (!sensitive && teamAbilities.can("update", teamMember)) return true;
    if (canRequestChange && isCategoryEnabled(category)) return true;
    return false;
  };

  const leadingCrews = useMemo(
    () => crews.filter((crew) => crew.lead_team_member_id === teamMember._id),
    [crews, teamMember._id]
  );

  const partOfCrews = useMemo(
    () =>
      crews.filter(
        (crew) => crew.team_member_ids.includes(teamMember._id) && crew.lead_team_member_id !== teamMember._id
      ),
    [crews, teamMember._id]
  );

  // State
  const [editing, setEditing] = useState<TeamMemberEditingModalCategory | undefined>();
  const [onboardingDocs, setOnboardingDocs] = useState<string[]>([]);
  const [loadingRawSSN, setLoadingRawSSN] = useState(false);
  const [rawSSN, setRawSSN] = useState<string>();
  const [showRateHistory, setShowRateHistory] = useState(false);
  const [i9, setI9] = useState<AggregatedI9>();
  const [downloadingI9, setDownloadingI9] = useState(false);
  const [showI9Wizard, setShowI9Wizard] = useState(false);
  const [showPayRate, setShowPayRate] = useState(false);
  const [showEmploymentHistory, setShowEmploymentHistory] = useState(false);
  const [hasScheduledChangeRequests, setHasScheduledChangeRequests] = useState(false);

  const teamMemberWithI9 = useMemo(() => ({ ...teamMember, I9: i9 }), [teamMember, i9]);

  const canEditTmHsaLimitValue = useCanEditTmHsaLimitValue(teamMember.employment_type);

  const prgs = usePrgs();
  const prgMappings = useMemo(() => {
    const mappings: { prg: AggregatedPayRateGroup; rate: UnionRate }[] = [];
    for (const [prgId, rateId] of Object.entries(teamMember.prg_classifications || {})) {
      const prg = lookupPrg(prgId);
      const rate = lookupRateClassification(rateId);
      if (!prg || !rate) continue;
      mappings.push({ prg, rate });
    }
    mappings.sort((a, b) => baseSensitiveCompare(a.prg.label, b.prg.label));
    return mappings;
  }, [teamMember, lookupPrg, lookupRateClassification]);

  const benefitsEligibilityGroups = useBenefitsEligibilityGroups();

  const { accessibleJobs } = useTeamMemberAccessibleJobs(teamMember);

  useEffect(() => {
    getHasScheduledChangeRequests();
    if (teamMember.employment_type !== "employee") return;
    getI9();
  }, []);

  const getHasScheduledChangeRequests = async () => {
    const response = await MiterAPI.change_requests.retrieve_many([
      { field: "status", value: "scheduled" },
      { field: "parent_id", value: teamMember._id },
    ]);
    setHasScheduledChangeRequests(!!response.length);
  };

  const sendOnboardingSMS = async () => {
    if (!teamMember.phone) {
      Notifier.error("This team member has no phone number.");
      return;
    }
    try {
      const response = await MiterAPI.team_member.send_onboard_sms(teamMember._id);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success("Sent SMS.");
    } catch (e: $TSFixMe) {
      console.log("Error sending SMS", e);
      Notifier.error(e.message);
    }
  };

  const sendOnboardingEmail = async () => {
    if (!teamMember.email) {
      Notifier.error("This team member has no email.");
      return;
    }
    try {
      const response = await MiterAPI.team_member.send_onboard_email(teamMember._id);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success("Sent email.");
    } catch (e: $TSFixMe) {
      console.log("Error sending email", e);
      Notifier.error(e.message);
    }
  };

  const viewRawSSN = async () => {
    setLoadingRawSSN(true);
    try {
      const res = await MiterAPI.team_member.retrieve_raw_ssn(teamMember._id);
      if (res.error) throw new Error(res.error);

      setRawSSN(res.ssn.slice(0, 3) + "-" + res.ssn.slice(3, 5) + "-" + res.ssn.slice(5));
    } catch (e: $TSFixMe) {
      console.error("Error loading raw SSN", e);
      Notifier.error("Failed to show raw SSN. Please contact support.");
    }
    setLoadingRawSSN(false);
  };

  const getI9 = async () => {
    try {
      const res = await MiterAPI.i_9s.search([{ field: "team_member_id", value: teamMember._id }]);
      if (res.error) throw new Error(res.error);

      if (res.length > 0) {
        setI9(res[0]);
        return res[0];
      }
    } catch (e: $TSFixMe) {
      console.error("Error loading I9", e);
      Notifier.error("Failed to load I9. Please contact support.");
    }
  };

  const downloadI9 = async () => {
    if (!i9) return;

    setDownloadingI9(true);
    try {
      const res = await MiterAPI.i_9s.retrieve_pdf(i9._id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      saveAs(blob, `I-9_${teamMember?.full_name}.pdf`);
    } catch (e: $TSFixMe) {
      console.error("Error downloading I9", e);
      Notifier.error("Failed to download I9. Please contact support.");
    }
    setDownloadingI9(false);
  };

  const getCurrentOnboardingDocs = async () => {
    try {
      const filter = [
        {
          field: "company",
          type: "string",
          value: activeCompanyId!,
        },
        {
          field: "onboarding_document",
          type: "boolean",
          value: true,
        },
        {
          field: "job",
          comparisonType: "exists",
          value: false,
        },
        {
          field: "team_member",
          comparisonType: "exists",
          value: false,
        },
        {
          field: "timesheet",
          comparisonType: "exists",
          value: false,
        },
      ];

      const res = await MiterAPI.files.list(filter as MiterFilterArray);
      if (res.error) throw new Error(res.error);

      const fileIds = res.map((file) => file._id.toString());
      setOnboardingDocs(fileIds);
    } catch (e: $TSFixMe) {
      console.error(e);
    }
  };

  const handleViewSSNClick = () => {
    setReverifyUser(true);
    setOnReverifyUser(() => viewRawSSN);
  };

  const handleOpenI9Wizard = () => {
    if (!i9) return;
    setShowI9Wizard(true);
  };

  const handleCloseI9Wizard = () => {
    setShowI9Wizard(false);
    getI9();
  };

  const renderLinkToRateHistory = (item: string | number, type: "title" | "pay_rate") => {
    return (
      <div className="flex">
        {teamAbilities.can("read_sensitive", teamMember) && !!teamMember.rate_title_history?.length && (
          <span className="blue-link" onClick={() => setShowRateHistory(true)} style={{ marginRight: 10 }}>
            See history
          </span>
        )}
        {showPayRate || type === "title" ? (
          item
        ) : (
          <span className="blue-link" onClick={() => setShowPayRate(true)}>
            | &nbsp; View pay rate
          </span>
        )}
      </div>
    );
  };

  const partOfCrewsText = useMemo(() => {
    const crewsString = partOfCrews.map((crew) => crew.name).join(", ");
    return truncate(crewsString, { length: 50 });
  }, [partOfCrews]);

  const leadingCrewsText = useMemo(() => {
    const crewsString = leadingCrews.map((crew) => crew.name).join(", ");
    return truncate(crewsString, { length: 50 });
  }, [leadingCrews]);

  const benefitsEligibilityGroupsText = useMemo(() => {
    const benefitsEligibilityGroupsString =
      teamMember.benefits_eligibility_groups
        ?.map((group) => lookupBenefitsEligibilityGroup(group)?.integrations?.clasp?.clasp_subclass?.name)
        ?.join(", ") || "-";
    return truncate(benefitsEligibilityGroupsString, { length: 50 });
  }, [teamMember, lookupBenefitsEligibilityGroup]);

  const jobsSupervisingText = useMemo(() => {
    const jobsString = jobsSupervising.map((job) => job.name).join(", ");
    return truncate(jobsString, { length: 50 });
  }, [jobsSupervising]);

  const jobsSuperintendingText = useMemo(() => {
    const jobsString = jobsSuperintending.map((job) => job.name).join(", ");
    return truncate(jobsString, { length: 50 });
  }, [jobsSuperintending]);

  const renderScheduledChangeRequestsBanner = () => {
    if (hasScheduledChangeRequests) {
      return (
        <Banner
          type={"warning"}
          content={
            "Changes to " +
            teamMember.full_name +
            "'s information have been scheduled. Toggle to change requests to view pending changes."
          }
          style={{ marginBottom: "10px" }}
        />
      );
    } else return null;
  };

  const renderEmploymentInfo = () => {
    let startDate: JSX.Element | string = "-",
      tenure = "-";
    if (teamMember.start_date) {
      startDate = formatDate(teamMember.start_date, undefined, true);
      tenure = getTenureString(
        teamMember.end_date ? DateTime.fromISO(teamMember.end_date) : DateTime.now(),
        DateTime.fromISO(teamMember.start_date)
      );
    } else if (teamMember.check_tm) {
      startDate = <Alert />;
    }

    return (
      <>
        {renderScheduledChangeRequestsBanner()}
        <DataBox
          title="Employment information"
          onEdit={canEditOrRequestChanges("employment") ? () => setEditing("employment") : undefined}
          className="flex-col no-margin"
        >
          {" "}
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>ID</span>
            <span className={"data-box-section-value font-14"}>{teamMember.friendly_id}</span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Title</span>
            <span className={"data-box-section-value font-14"}>
              {renderLinkToRateHistory(teamMember.title, "title")}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Department</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.department ? teamMember.department.name : "-"}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Location</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.location ? teamMember.location.name : "-"}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Reports to</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.reports_to ? teamMember.reports_to.full_name : "-"}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Employment category</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.employment_category
                ? employmentCategoryFormatter(teamMember.employment_category)
                : "-"}
            </span>
          </div>
          {teamMember.employment_category === "part_time" && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Hours worked (weekly estimate)</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.estimated_hours_worked_per_week || "-"}
              </span>
            </div>
          )}
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Employment term</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.employment_term ? titleCase(teamMember.employment_term) : "-"}
            </span>
          </div>
          {leadingCrews.length > 0 && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Crews - Lead</span>
              <span className={"data-box-section-value font-14 flex"}>
                <Link className="purple-link" to={"/team-members/settings?section=crews"}>
                  {leadingCrewsText}
                </Link>
              </span>
            </div>
          )}
          {partOfCrews.length > 0 && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Crews - Member</span>
              <span className={"data-box-section-value font-14 flex"}>
                <Link className="purple-link" to={"/team-members/settings?section=crews"}>
                  {partOfCrewsText}
                </Link>
              </span>
            </div>
          )}
          <div className={"data-box-section"}>
            <div className="flex">
              <span className={"data-box-section-title font-14"}>Is universal supervisor</span>
              <InfoButton text="I.e., has supervisor permissions on all jobs" />
            </div>

            <span className={"data-box-section-value font-14"}>
              {teamMember.is_universal_supervisor ? "Yes" : "No"}
            </span>
          </div>
          {jobsSupervising.length > 0 && (
            <div className={"data-box-section"}>
              <div className="flex">
                <span className={"data-box-section-title font-14"}>Jobs supervisor on</span>
              </div>

              <span className={"data-box-section-value font-14"}>{jobsSupervisingText}</span>
            </div>
          )}
          {jobsSuperintending.length > 0 && (
            <div className={"data-box-section"}>
              <div className="flex">
                <span className={"data-box-section-title font-14"}>Job superintendent on</span>
              </div>

              <span className={"data-box-section-value font-14"}>{jobsSuperintendingText}</span>
            </div>
          )}
          {enabledKioskClockIn && (
            <div className={"data-box-section"}>
              <div className="flex">
                <span className={"data-box-section-title font-14"}>Can enable kiosk</span>
                <InfoButton text="i.e., can turn on kiosk mode on their mobile device" />
              </div>

              <span className={"data-box-section-value font-14"}>
                {teamMember.can_enable_kiosk ? "Yes" : "No"}
              </span>
            </div>
          )}
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Start date</span>
            <span className={"data-box-section-value font-14"}>
              <div className="flex">
                {teamAbilities.can("read_sensitive", teamMember) && (
                  <span
                    className="blue-link"
                    onClick={() => setShowEmploymentHistory(true)}
                    style={{ marginRight: 10 }}
                  >
                    See history
                  </span>
                )}
                {startDate}
              </div>
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Tenure</span>
            <span className={"data-box-section-value font-14"}>{tenure}</span>
          </div>
          {isDismissed && teamAbilities.can("read_sensitive", teamMember) && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>End date</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.end_date ? formatDate(teamMember.end_date, undefined, true) : "-"}
              </span>
            </div>
          )}
          {teamMember.employment_type === "employee" && (
            <>
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Workers&apos; comp code</span>
                <span className={"data-box-section-value font-14"}>
                  {teamMember.wc_code ? teamMember.wc_code.label : "-"}
                </span>
              </div>
              {hasEnabledWcGroups && (
                <div className={"data-box-section"}>
                  <span className={"data-box-section-title font-14"}>Workers&apos; comp group</span>
                  <span className={"data-box-section-value font-14"}>
                    {teamMember.wc_group_id ? lookupWcGroup(teamMember.wc_group_id)?.name : "-"}
                  </span>
                </div>
              )}
            </>
          )}
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Timezone</span>
            <span className={"data-box-section-value font-14"}>
              {lookupTimezoneLabel(teamMember.timezone) || "-"}
            </span>
          </div>
          {enabledKioskClockIn && teamAbilities.can("read", teamMember) && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Kiosk clock-in pin</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.kiosk_pin ? teamMember.kiosk_pin : "-"}
              </span>
            </div>
          )}
          {renderI9Row()}
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Permission groups</span>
            <span className={"data-box-section-value font-14"}>
              {teamMemberPermissionGroups &&
                teamMemberPermissionGroups.length === 0 &&
                miterAbilities.can("company:permission_groups:manage") && (
                  <Link className="purple-link" to={"/settings/permissions"}>
                    + Add to group
                  </Link>
                )}
              {teamMemberPermissionGroups?.map((pg, index) =>
                miterAbilities.can("company:permission_groups:manage") ? (
                  <Link key={pg._id} className="purple-link" to={`/settings/permissions/${pg._id}`}>
                    {pg.name}
                    {index < teamMemberPermissionGroups.length - 1 && ", "}
                  </Link>
                ) : (
                  <span key={pg._id}>
                    {pg.name}
                    {index < teamMemberPermissionGroups.length - 1 && ", "}
                  </span>
                )
              )}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Language preference</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember?.language === "es" ? "Español" : "English"}
            </span>
          </div>
        </DataBox>
      </>
    );
  };

  const renderDefaultsInfo = () => {
    const defaultJob = lookupJob(teamMember.default_job_id);
    let defaultJobText = "-";
    if (defaultJob) {
      defaultJobText = defaultJob.name;
      if (defaultJob.code) {
        defaultJobText = `${defaultJob.code} - ${defaultJobText}`;
      }
    }

    const defaultActivity = lookupActivity(teamMember.default_activity_id);
    let defaultActivityText = "-";
    if (defaultActivity) {
      defaultActivityText = defaultActivity.label;
      if (defaultActivity.cost_code) {
        defaultActivityText = `${defaultActivity.cost_code} - ${defaultActivityText}`;
      }
    }

    const defaultCostTypeText = lookupCostType(teamMember.default_cost_type_id)?.label || "-";

    const stdClassificationText =
      lookupStandardClassification(teamMember.standard_classification_id)?.label || "-";

    const hsaLimitText = teamMember.hsa_limit ? startCase(teamMember.hsa_limit) : "-";

    const glMapping = lookupGlMapping(teamMember.ledger_mapping_id);

    return (
      <DataBox
        title={`Default associations`}
        className="flex-col no-margin"
        onEdit={canEditOrRequestChanges("defaults") ? () => setEditing("defaults") : undefined}
      >
        <div className={"data-box-section"}>
          <div className="flex">
            <span className={"data-box-section-title font-14"}>Default job</span>
            <InfoButton text={defaultTmJobTooltip} />
          </div>
          <span className={"data-box-section-value font-14"}>{defaultJobText}</span>
        </div>
        <div className={"data-box-section"}>
          <div className="flex">
            <span className={"data-box-section-title font-14"}>Default activity</span>
            <InfoButton text={defaultTmActivityTooltip} />
          </div>
          <span className={"data-box-section-value font-14"}>{defaultActivityText}</span>
        </div>
        {hasCostTypes && (
          <div className={"data-box-section"}>
            <div className="flex">
              <span className={"data-box-section-title font-14"}>Default cost type</span>
              <InfoButton text={defaultTmCostTypeTooltip} />
            </div>
            <span className={"data-box-section-value font-14"}>{defaultCostTypeText}</span>
          </div>
        )}
        {teamAbilities.can("read_sensitive", teamMember) && (
          <div className={"data-box-section"}>
            <div className="flex">
              <span className={"data-box-section-title font-14"}>Standard classification</span>
              <InfoButton text={stdTmClassificationTooltip} />
            </div>
            <span className={"data-box-section-value font-14"}>{stdClassificationText}</span>
          </div>
        )}
        {canEditTmHsaLimitValue && (
          <div className={"data-box-section"}>
            <div className="flex">
              <span className={"data-box-section-title font-14"}>HSA limit</span>
              <InfoButton text={tmHsaLimitTooltip} />
            </div>
            <span className={"data-box-section-value font-14"}>{hsaLimitText}</span>
          </div>
        )}
        {miterAbilities.can("accounting:settings") && (
          <div className={"data-box-section"}>
            <div className="flex">
              <span className={"data-box-section-title font-14"}>GL mapping</span>
            </div>
            <span className={"data-box-section-value font-14"}>{glMapping?.name || "-"}</span>
          </div>
        )}
      </DataBox>
    );
  };

  const renderPoliciesInfo = () => {
    return (
      <DataBox
        title="Approval policies"
        onEdit={teamAbilities.can("update", teamMember) ? () => setEditing("policies") : undefined}
        className="flex-col no-margin"
        editText={"Edit"}
      >
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Timesheet policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={"/timesheet-settings"}>{lookupPolicy(teamMember.timesheet_policy_id)?.name}</Link>
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Card transaction policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={`/expenses/settings/policies/${teamMember.expense_policy_id}`}>
              {lookupPolicy(teamMember.expense_policy_id)?.name}
            </Link>
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Reimbursement policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={`/expenses/settings/policies/${teamMember.reimbursement_policy_id}`}>
              {lookupPolicy(teamMember.reimbursement_policy_id)?.name}
            </Link>
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Time off request policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={`/time-off/settings`}>{lookupPolicy(teamMember.time_off_request_policy_id)?.name}</Link>
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Change request policy</span>
          <span className={"data-box-section-value font-14"}>
            <Link to={`/team-members/settings`}>
              {lookupPolicy(teamMember.team_member_change_request_policy_id)?.name}
            </Link>
          </span>
        </div>
      </DataBox>
    );
  };

  const renderJobsInfo = () => {
    return (
      <DataBox
        title="Jobs information"
        onEdit={teamAbilities.can("update", teamMember) ? () => setEditing("jobs") : undefined}
        className="flex-col no-margin"
        editText={"Edit"}
      >
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Has access to</span>
          <span className={"data-box-section-value font-14"}>{`${accessibleJobs.length} job${
            accessibleJobs.length === 1 ? "" : "s"
          }`}</span>
        </div>
      </DataBox>
    );
  };

  const renderPersonalInfo = () => {
    let address: JSX.Element | string = addressString(teamMember.address);
    if (!address && teamMember.check_tm) {
      address = <Alert />;
    }

    const mailingAddress: JSX.Element | string = addressString(teamMember.mailing_address);

    let dob: JSX.Element | string = "-";
    if (teamMember.dob) {
      dob = formatDate(teamMember.dob, undefined, true);
    } else if (teamMember.check_tm) {
      dob = <Alert />;
    }

    return (
      <DataBox
        title="Personal information"
        className="flex-col no-margin"
        onEdit={canEditOrRequestChanges("personal") ? () => setEditing("personal") : undefined}
      >
        {" "}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>First name</span>
          <span className={"data-box-section-value font-14"}>{teamMember?.first_name || "-"}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Middle name</span>
          <span className={"data-box-section-value font-14"}>{teamMember?.middle_name || "-"}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Last name</span>
          <span className={"data-box-section-value font-14"}>{teamMember?.last_name || "-"}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Phone</span>
          <span className={"data-box-section-value font-14"}>
            {teamMember.phone ? formatPhoneNumber(teamMember.phone) : "-"}
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Email</span>
          <span className={"data-box-section-value font-14"}>
            {teamMember.email ? teamMember.email : "-"}
          </span>
        </div>
        {teamAbilities.can("read_sensitive", teamMember) && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Date of birth</span>
            <span className={"data-box-section-value font-14"}>{dob}</span>
          </div>
        )}
        {teamAbilities.can("read_sensitive", teamMember) && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>
              {teamMember.employment_type === "employee" ? "Residence" : "Address"}
            </span>
            <span className={"data-box-section-value font-14"}>{address || "-"}</span>
          </div>
        )}
        {teamAbilities.can("read_sensitive", teamMember) && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Mailing address</span>
            <span className={"data-box-section-value font-14"}>{mailingAddress || address || "-"}</span>
          </div>
        )}
        {teamAbilities.can("read_sensitive", teamMember) && !checkTm && renderSSNRow()}
      </DataBox>
    );
  };

  const renderOtherInformation = () => {
    let creatorName: string;

    // If the created_by is an object id, lookup the user name
    if (teamMember.creation_method === "user") {
      const user = lookupUser(teamMember.created_by);
      if (user) {
        creatorName = buildCompanyUserName(user);
      } else {
        creatorName = "integration";
      }
    } else {
      creatorName = "integration";
    }

    return (
      <DataBox title="Other information" className="flex-col no-margin">
        {teamAbilities.can("read_sensitive", teamMember) && showAuditLogHistory && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Audit Log (Mitosaur Only)</span>
            <Button onClick={() => setShowAuditLogModal(true)}>View</Button>
          </div>
        )}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Created by</span>
          <span className={"data-box-section-value font-14"}>{creatorName}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Created at</span>
          <span className={"data-box-section-value font-14"}>
            {DateTime.fromSeconds(teamMember.created_at).toFormat("LLL d, yyyy h:mm a")}
          </span>
        </div>
      </DataBox>
    );
  };

  const renderEmergencyContactInfo = () => {
    if (teamAbilities.cannot("read_sensitive", teamMember)) return;

    const contactList = teamMember.emergency_contacts || [];
    while (contactList.length < 2) {
      contactList.push({});
    }

    return (
      <>
        <DataBox
          title={`Emergency contacts`}
          className="flex-col no-margin"
          onEdit={
            canEditOrRequestChanges("emergency-contacts") ? () => setEditing("emergency-contacts") : undefined
          }
        >
          {contactList.map((contact, i) => (
            <div key={`${contact.name}-${i}`}>
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Name</span>
                <span className={"data-box-section-value font-14"}>{contact?.name || "-"}</span>
              </div>
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Relationship</span>
                <span className={"data-box-section-value font-14"}>{contact?.relationship || "-"}</span>
              </div>
              <div className={"data-box-section"} style={{ borderBottomWidth: 2 }}>
                <span className={"data-box-section-title font-14"}>Phone number</span>
                <span className={"data-box-section-value font-14"}>{contact?.phone || "-"}</span>
              </div>
            </div>
          ))}
        </DataBox>
      </>
    );
  };

  const renderPrgMappings = () => {
    if (!prgs.length || teamAbilities.cannot("read_sensitive", teamMember)) return null;

    return (
      <DataBox
        title="Classifications"
        className="flex-col no-margin"
        onEdit={
          (!isDismissed || user?.miter_admin) && canEditOrRequestChanges("classifications")
            ? () => setEditing("classifications")
            : undefined
        }
      >
        {prgMappings.map((m) => {
          const classificationTxt = `${m.rate.classification} - ${usdString(m.rate.base_rate)}`;
          return (
            <div className={"data-box-section"} key={m.prg._id}>
              <span className={"data-box-section-title font-14"}>{m.prg.label}</span>
              <span className={"data-box-section-value font-14"}>{classificationTxt}</span>
            </div>
          );
        })}
      </DataBox>
    );
  };

  const renderPayRateInfo = () => {
    if (teamAbilities.cannot("read_sensitive", teamMember)) return <></>;

    let payType = "-";
    if (teamMember.pay_type === "salary") {
      payType = "Salary";
    } else if (teamMember.union_rate) {
      payType = "Hourly (union)";
    } else if (teamMember.pay_type === "hourly") {
      payType = "Hourly (non-union)";
    }

    const payRate =
      teamMember.pay_rate != null && teamMember.pay_type
        ? usdString(
            Number(
              convertAnnualRateToDisplayRate(
                activeCompany,
                teamMember.pay_type,
                teamMember.salary_rate_display,
                teamMember.pay_rate
              )
            )
          )
        : "-";

    const paySchedule = lookupPaySchedule(teamMember.pay_schedule_id);

    const holidaySchedule = lookupHolidaySchedules(teamMember.holiday_schedule_id);

    let payRateGroupLabel: string | undefined;
    if (teamMember.union_rate?.pay_rate_group) {
      const prg = lookupPrg(teamMember.union_rate?.pay_rate_group);
      if (prg) payRateGroupLabel = prg.label;
    }

    const showOtRuleField = teamMember.employment_type === "employee" && !teamMember.overtime_exempt;

    // If the TM has a specified OT rule, use that. Otherwise, use the company default if it exists. Otherwise, use the federal/state default.
    const tmDefaultRuleId =
      teamMember.default_ot_rule_id || activeCompany?.settings.payroll.default_ot_rule_id;
    const otRuleText = lookupOtRule(tmDefaultRuleId)?.label || "Federal/state defaults";

    return (
      <DataBox
        title={`Pay & overtime`}
        className="flex-col no-margin"
        onEdit={canEditOrRequestChanges("pay", true) ? () => setEditing("pay") : undefined}
      >
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Pay type</span>
          <span className={"data-box-section-value font-14"}>{payType}</span>
        </div>

        {payRateGroupLabel && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Pay rate group</span>
            <span className={"data-box-section-value font-14"}>{payRateGroupLabel}</span>
          </div>
        )}
        {teamMember.union_rate?.classification && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Classification</span>
            <span className={"data-box-section-value font-14"}>{teamMember.union_rate?.classification}</span>
          </div>
        )}
        {!!teamMember.union_rate?.classification && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Overscale rate differential</span>
            <span className={"data-box-section-value font-14"}>
              {lookupRateDifferential(teamMember.rate_differential_id)?.label || "-"}
            </span>
          </div>
        )}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>{renderPayRateLabel(teamMember)}</span>
          <span className={"data-box-section-value font-14"}>
            {renderLinkToRateHistory(payRate, "pay_rate")}
          </span>
        </div>
        {otExemptionIsRelevant(teamMember) && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Overtime exempt</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.overtime_exempt ? "True" : "False"}
            </span>
          </div>
        )}
        {showOtRuleField && (
          <div className={"data-box-section"}>
            <div className="flex">
              <span className={"data-box-section-title font-14"}>Default overtime rule</span>
              <InfoButton text="How this team member's OT will be calculated, unless it is superseded by another rule." />
            </div>
            <span className={"data-box-section-value font-14"}>{otRuleText}</span>
          </div>
        )}
        {!!prgs.length && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Ignore in Certified Payroll</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.ignore_in_cprs ? "True" : "False"}
            </span>
          </div>
        )}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Pay schedule</span>
          <span className={"data-box-section-value font-14"}>{paySchedule?.label || "Unknown"}</span>
        </div>
        {teamMember.employment_type === "employee" ? (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Holiday schedule</span>
            <span className={"data-box-section-value font-14"}>{holidaySchedule?.title || "-"}</span>
          </div>
        ) : null}
      </DataBox>
    );
  };

  const renderCprInfo = () => {
    if (!prgs.length) return;

    return (
      <div>
        <DataBox
          title="Certified Payroll"
          className="flex-col no-margin"
          onEdit={teamAbilities.can("update_sensitive", teamMember) ? () => setEditing("cprs") : undefined}
        >
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Ignore in Certified Payroll</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.ignore_in_cprs ? "True" : "False"}
            </span>
          </div>
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Disable fringe offsets</span>
            <span className={"data-box-section-value font-14"}>
              {teamMember.disable_fringe_offsets ? "True" : "False"}
            </span>
          </div>
        </DataBox>
      </div>
    );
  };

  const renderBenefitsInfo = () => {
    const hasClasp = isActiveClaspCompany(activeCompany);
    if (teamAbilities.cannot("read_sensitive", teamMember) || !hasClasp) return;

    return (
      <div>
        <DataBox
          title="Benefits"
          className="flex-col no-margin"
          onEdit={
            teamAbilities.can("update_sensitive", teamMember) ? () => setEditing("benefits") : undefined
          }
        >
          <div className={"data-box-section"}>
            <div className="flex">
              <span className={"data-box-section-title font-14"}>Benefits eligibility status</span>
              <InfoButton text={benefitsEligibilityStatusTooltip} />
            </div>
            <span className={"data-box-section-value font-14"}>
              {teamMember.benefits_eligibility_status
                ? capitalize(teamMember.benefits_eligibility_status)
                : "Default"}
            </span>
          </div>
          {!!benefitsEligibilityGroups.length && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Benefits eligibility groups</span>
              <span className={"data-box-section-value font-14 flex"}>{benefitsEligibilityGroupsText}</span>
            </div>
          )}
        </DataBox>
      </div>
    );
  };

  const renderDemographicsInfo = () => {
    const enabledDemographic = activeCompany?.settings.team?.enable_demographic_reporting;
    if (teamAbilities.cannot("read_sensitive", teamMember) || !isEmployee || !enabledDemographic) return;

    return (
      <div>
        <DataBox
          title="Demographics + EEO reporting"
          className="flex-col no-margin"
          onEdit={canEditOrRequestChanges("demographics") ? () => setEditing("demographics") : undefined}
        >
          {enabledDemographicQuestions["ethnicity"] && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Ethnicity</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.demographics?.ethnicity || "-"}
              </span>
            </div>
          )}
          {enabledDemographicQuestions["gender"] && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Gender</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.demographics?.gender || "-"}
              </span>
            </div>
          )}
          {enabledDemographicQuestions["marital_status"] && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Marital status</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.demographics?.marital_status || "-"}
              </span>
            </div>
          )}
          {enabledDemographicQuestions["disability_status"] && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Disability status</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.demographics?.disability_status || "-"}
              </span>
            </div>
          )}
          {enabledDemographicQuestions["veteran_status"] && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Veteran status</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.demographics?.veteran_status || "-"}
              </span>
            </div>
          )}
          {enabledDemographicQuestions["job_category"] && (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Job category</span>
              <span className={"data-box-section-value font-14"}>
                {teamMember.demographics?.job_category || "-"}
              </span>
            </div>
          )}
        </DataBox>
      </div>
    );
  };

  const renderMisalignedSSNAlert = () => {
    return (
      <>
        <div
          data-tip="Please confirm SSN by submitting again"
          data-for="misalignedSSN"
          className="flex"
          style={{ marginLeft: 5 }}
        >
          <Alert />
        </div>
        <ReactTooltip id="misalignedSSN" className="tooltip" />
      </>
    );
  };

  const renderSSNRow = () => {
    const ssnNeedsAttention = checkTm?.onboard?.remaining_steps?.includes("ssn");
    const miterSSN = teamMember.ssn_last_four;
    const checkSSN = checkTm?.ssn_last_four;

    const misAligned = !!miterSSN && !!checkSSN && miterSSN !== checkSSN;

    let ssn: React.ReactNode;
    if (checkTm) {
      ssn = !ssnNeedsAttention && (miterSSN || checkSSN) ? "XXX-XX-" + (miterSSN || checkSSN) : <Alert />;
    } else {
      ssn = miterSSN;
    }

    return (
      <div className={"data-box-section"}>
        <span className={"data-box-section-title font-14"}>Social Security #</span>
        <span className={"data-box-section-value font-14"} style={{ display: "flex" }}>
          {miterSSN && !rawSSN && teamMember.ssn_id ? (
            <Button
              className="button-text purple-link"
              style={{ marginRight: 10, fontSize: 15, fontWeight: "500" }}
              onClick={handleViewSSNClick}
              loading={loadingRawSSN}
            >
              View full SSN
            </Button>
          ) : (
            <></>
          )}
          {rawSSN || ssn}
          {misAligned && renderMisalignedSSNAlert()}
        </span>
      </div>
    );
  };

  const renderPaymentInfo = () => {
    if (!checkTm || teamAbilities.cannot("read_sensitive", teamMember)) return;
    // Set payment and tax information

    const paymentMethodPrefNeedsAttention = checkTm.onboard.payment_method?.includes(
      "payment_method_preference_set"
    );
    const paymentMethodPref = checkTm.payment_method_preference;
    const payment_method = paymentMethodPrefNeedsAttention ? (
      <Alert />
    ) : paymentMethodPref === "direct_deposit" ? (
      "Direct deposit"
    ) : (
      "Paper check"
    );

    const bankAccountNeedsAttention = checkTm.onboard.payment_method?.some((s) => s.includes("bank_account"));
    const bank_account = bankAccountNeedsAttention ? (
      <Alert />
    ) : !checkTm.bank_accounts?.length ? (
      "N/A"
    ) : (
      <Checkmark />
    );

    const withholdingsNeedsAttention =
      checkTm.onboard.remaining_steps.some((s) => s === "withholdings" || s === "ssn") ||
      ("employee_details" in checkTm.onboard && checkTm.onboard.employee_details?.includes("residence"));
    const withholdings = withholdingsNeedsAttention ? <Alert /> : <Checkmark />;

    return (
      <DataBox
        title="Payroll information: employee-defined"
        className="flex-col no-margin"
        onEdit={teamAbilities.can("update_sensitive", teamMember) ? () => setEditing("payment") : undefined}
      >
        {renderSSNRow()}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Payment method</span>
          <span className={"data-box-section-value font-14"}>{payment_method}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Bank account</span>
          <span className={"data-box-section-value font-14"}>{bank_account}</span>
        </div>
        {teamMember.employment_type === "employee" && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Withholdings</span>
            <span className={"data-box-section-value font-14"}>{withholdings}</span>
          </div>
        )}
      </DataBox>
    );
  };

  const renderCompanyDefinedAttributes = () => {
    if (!checkTm || teamAbilities.cannot("update_sensitive", teamMember)) return;
    const cdaNeedsAttention = checkTm.onboard.remaining_steps.includes("company_defined_attributes");
    const cdaIcon = cdaNeedsAttention ? <Alert /> : <Checkmark />;

    return (
      <DataBox
        title="Payroll information: company-defined"
        className="flex-col no-margin"
        onEdit={teamAbilities.can("update_sensitive", teamMember) ? () => setShowCdaModal(true) : undefined}
      >
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Company-defined attributes</span>
          <span className={"data-box-section-value font-14"}>{cdaIcon}</span>
        </div>
      </DataBox>
    );
  };

  const renderOnboardingInfo = () => {
    if (!onboardingDocs || onboardingDocs.length === 0) return <></>;
    if (!teamMember.acknowledged_onboarding_docs?.length) return <></>;

    const acknowledgedAllDocs = onboardingDocs.every((doc) =>
      (teamMember.acknowledged_onboarding_docs || []).includes(doc)
    );

    return (
      <DataBox
        title="Onboarding information"
        className="flex-col no-margin"
        onEdit={
          teamAbilities.can("update_sensitive", teamMember) ? () => setEditing("onboarding") : undefined
        }
      >
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Acknowledged documents </span>
          <span className={"data-box-section-value font-14"}>
            {acknowledgedAllDocs ? <Checkmark /> : <Alert />}
          </span>
        </div>
      </DataBox>
    );
  };

  const renderOnboardingFooter = () => {
    if (isDismissed) return null;
    return (
      <div className="onboarding-details-text">
        {
          "Team members can update personal information, modify payment details, and view their paystubs using the Miter team portal."
        }
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="onboarding-details-text link" onClick={sendOnboardingSMS}>
            {"Send " + teamMember.first_name + " an onboarding text"}
          </div>
          <div style={{ marginRight: 5, marginLeft: 8 }}>|</div>

          <div className="onboarding-details-text link" onClick={sendOnboardingEmail}>
            {"Send " + teamMember.first_name + " an onboarding email"}
          </div>
        </div>
      </div>
    );
  };

  const renderCustomFields = () => {
    const tmCustomFields = customFields.filter((cf) => cf.parent_type === "team_member");
    const tmCustomFieldValues = teamMember.custom_field_values || [];

    if (!tmCustomFields.length) return <></>;

    return (
      <DataBox
        title="Custom fields"
        className="flex-col no-margin"
        onEdit={teamAbilities.can("update", teamMember) ? () => setEditing("custom-fields") : undefined}
      >
        {tmCustomFields.map((cf) => {
          const selectedValue = tmCustomFieldValues.find((cfv) => cfv.custom_field_id === cf._id)!;
          const valueString = renderCustomFieldDefaultString(cf, selectedValue);

          return (
            <div className={"data-box-section"} key={cf._id}>
              <span className={"data-box-section-title font-14"}>{cf.name}</span>
              <span className={"data-box-section-value font-14"}>{valueString}</span>
            </div>
          );
        })}
      </DataBox>
    );
  };

  const renderI9Row = () => {
    if (teamMember.employment_type !== "employee") return;

    const canUpdateSensitive = teamAbilities.can("update_sensitive", teamMember);

    let content: ReactElement;
    if (!!activeCompany?.settings.team.onboarding?.enable_online_i9s && canUpdateSensitive) {
      if (!i9) {
        content = (
          <Button
            className="button-text purple-link"
            onClick={() => setShowManualI9Modal(true)}
            text="Upload I-9 document"
          />
        );
      } else {
        if (i9.status === "draft") {
          if (i9.section_1.employee_esignature_id) {
            if (canUpdateSensitive) {
              content = (
                <div style={{ display: "flex" }}>
                  <Button
                    className="button-text purple-link"
                    onClick={handleOpenI9Wizard}
                    text="Review and submit"
                  />
                  <span>&nbsp; | &nbsp; </span>
                  <Button
                    className="button-text purple-link"
                    onClick={() => setShowManualI9Modal(true)}
                    text="Upload I-9 document"
                  />
                </div>
              );
            } else {
              content = <>Waiting for reviewer submission</>;
            }
          } else {
            content = <>Waiting for employee submission</>;
          }
        } else {
          content = (
            <Button
              className="button-text purple-link"
              onClick={downloadI9}
              loading={downloadingI9}
              text="Download"
            />
          );
        }
      }
    } else {
      return null;
    }
    return (
      <div className={"data-box-section"} key={"I-9"}>
        <span className={"data-box-section-title font-14"}>I-9</span>
        <span className={"data-box-section-value font-14"}>{content}</span>
      </div>
    );
  };

  const renderManulI9Modal = () => {
    if (!showManualI9Modal) return null;
    return (
      <UpdateI9Modal
        onFinish={() => {
          setShowManualI9Modal(false);
          getI9();
        }}
        onCancel={() => setShowManualI9Modal(false)}
        teamMember={teamMember}
      />
    );
  };

  useEffect(() => {
    getCurrentOnboardingDocs();
  }, []);

  return (
    <div>
      {teamMember && (
        <>
          {editing && teamMember && (
            <EditTeamMemberModal
              teamMember={teamMemberWithI9}
              type={editing}
              onHide={() => setEditing(undefined)}
            />
          )}
          <div className="details-section-wrapper">{renderEmploymentInfo()}</div>
          <div className="details-section-wrapper">{renderJobsInfo()}</div>
          <div className="details-section-wrapper">{renderPayRateInfo()}</div>
          <div className="details-section-wrapper">{renderPrgMappings()}</div>
          <div className="details-section-wrapper">{renderPersonalInfo()}</div>
          <div className="details-section-wrapper">{renderDemographicsInfo()}</div>
          <div className="details-section-wrapper">{renderBenefitsInfo()}</div>
          <div className="details-section-wrapper">{renderCprInfo()}</div>
          <div className="details-section-wrapper">{renderOnboardingInfo()}</div>
          <div className="details-section-wrapper">{renderPaymentInfo()} </div>
          <div className="details-section-wrapper">{renderCompanyDefinedAttributes()} </div>
          <div className="details-section-wrapper">{renderDefaultsInfo()}</div>
          <div className="details-section-wrapper">{renderPoliciesInfo()}</div>
          <div className="details-section-wrapper">{renderCustomFields()} </div>
          <div className="details-section-wrapper">{renderEmergencyContactInfo()} </div>
          <IntegrationEmployeeFields tm={teamMember} />
          <div className="details-section-wrapper">{renderOtherInformation()} </div>
          <div className="details-section-wrapper">{renderManulI9Modal()} </div>
          <div className="vertical-spacer" />
          {renderOnboardingFooter()}
          <div className="vertical-spacer-large" />
          {showI9Wizard && i9 && <I9Wizard I9={i9} onExit={handleCloseI9Wizard} getI9={getI9} />}
          {showRateHistory && (
            <LargeModal
              headerText={`Default pay rate & title history for ${teamMember.full_name}`}
              onClose={() => setShowRateHistory(false)}
            >
              <TeamRateHistory teamMember={teamMember} />
            </LargeModal>
          )}
          {showEmploymentHistory && (
            <TeamEmploymentHistory teamMember={teamMember} onHide={() => setShowEmploymentHistory(false)} />
          )}

          {showCdaModal && (
            <CompanyDefinedAttributesModal hide={() => setShowCdaModal(false)} tmId={teamMember._id} />
          )}

          {showAuditLogModal && (
            <LargeModal
              headerText={`Audit log for ${teamMember.full_name}`}
              onClose={() => setShowAuditLogModal(false)}
            >
              <div style={{ overflowY: "scroll", maxHeight: "90%", overflowX: "clip" }}>
                <AuditLogHistory itemId={teamMember._id} type="team_member" />
              </div>
            </LargeModal>
          )}
        </>
      )}
    </div>
  );
};

export default TeamMemberDetails;
