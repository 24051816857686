import { CertificationTypeDetails } from "dashboard/components/certifications/CertificationTypeDetails";
import {
  CertificationTypeWizard,
  CertificationTypeWizardMode,
} from "dashboard/components/certifications/CertificationTypeWizard";
import { CertificationsTable } from "dashboard/components/certifications/CertificationsTable";
import { useCertificationTypeAbilities } from "dashboard/hooks/abilities-hooks/useCertificationTypeAbilities";
import { useLookupCertificationTypes, useRefetchCertificationTypes } from "dashboard/hooks/atom-hooks";
import { capitalize } from "lodash";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Loader } from "ui";
import Toggler, { TogglerConfigItem } from "ui/toggler/Toggler";

export const Certification: FC = () => {
  const params = useParams<{ id: string; view: string }>();
  const navigate = useNavigate();
  const lookupCertificationType = useLookupCertificationTypes();
  const certificationType = lookupCertificationType(params.id);
  const refetchCertificationType = useRefetchCertificationTypes();
  const [mode, setMode] = useState<CertificationTypeWizardMode | null>(null);
  const view = params.view;
  const { can } = useCertificationTypeAbilities();

  const paths = ["certifications", "details"];
  const togglerConfig: TogglerConfigItem[] = paths.map((v) => ({
    label: capitalize(v),
    path: v,
  }));

  useEffect(() => {
    const defaultPath = togglerConfig[0]?.path || "certifications";

    if (!view) {
      navigate(`/certification-types/${params.id}/${defaultPath}`, { replace: true });
    }
  }, [view]);

  if (!certificationType) {
    return <Loader />;
  }

  const onComplete = () => {
    refetchCertificationType(certificationType._id);
    setMode(null);
  };

  const onExit = () => {
    setMode(null);
  };

  const renderModal = () => {
    if (!mode) return null;
    return (
      <CertificationTypeWizard
        certificationTypeParam={certificationType}
        onExit={onExit}
        onComplete={onComplete}
        mode={mode}
      />
    );
  };

  const handleToggle = (newView: string) => {
    navigate(`/certification-types/${params.id}/${newView}`, { replace: true });
  };

  const renderToggler = () => {
    return (
      <Toggler active={view || "active"} toggle={handleToggle} config={togglerConfig} secondary={false} />
    );
  };

  const renderView = () => {
    if (view === "certifications") {
      return <CertificationsTable certificationTypeId={certificationType._id} />;
    } else if (view === "details") {
      return (
        <div style={{ marginTop: 20 }}>
          <CertificationTypeDetails certificationType={certificationType} onEdit={() => setMode("edit")} />
        </div>
      );
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Certifications | Miter</title>
      </Helmet>

      <div className="page-content">
        <div
          className="blue-link"
          onClick={() => navigate("/team-members/settings?section=certifications", { replace: true })}
        >
          Back to team settings
        </div>
        <div className="flex">
          <h1>{certificationType.title}</h1>
          <div className="flex-1"></div>
          {can("update", certificationType) && (
            <Button className="button-2" onClick={() => setMode("all")}>
              Edit
            </Button>
          )}
        </div>
        {renderToggler()}
        {renderView()}
        {renderModal()}
      </div>
    </div>
  );
};
