import {
  AggregatedTeamMember,
  AggregatedTimeOffRequest,
  BalanceEstimate,
  TimeOffPolicy,
  TimeOffRequest,
} from "dashboard/miter";
import { roundTo } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import styles from "./TimeOffRequestModal.module.css";
import { TimeOffRequestFormSchedule, buildTotalHours } from "./TimeOffRequestModal";
import InfoButton from "dashboard/components/information/information";

type SummaryProps = {
  schedule: TimeOffRequestFormSchedule;
  startDate: DateTime;
  teamMember: AggregatedTeamMember;
  timeOffPolicy: TimeOffPolicy;
  timeOffRequest?: AggregatedTimeOffRequest | TimeOffRequest | undefined;
  balanceEstimate?: BalanceEstimate;
};

/**
 * `TimeOffRequestSummary` displays a summary of a time-off request. It provides a breakdown
 * of the current balance, the amount of time requested off, and an updated balance post-request.
 *
 * The component also calculates estimated balances when the start date is in the future.
 * />
 */
const TimeOffRequestSummary: React.FC<SummaryProps> = ({
  schedule,
  startDate,
  teamMember,
  timeOffPolicy,
  timeOffRequest,
  balanceEstimate,
}) => {
  /**********************************************************************************************************
   * Build the summary values (balance, requested, updated, status, unlimited)
   **********************************************************************************************************/
  const { currentBalance, requestedHours, updatedBalance, unlimited } = useMemo(() => {
    // Get the current balance
    const tmPolicy = teamMember.time_off.policies.find((p) => p.policy_id === timeOffPolicy._id);
    let currentBalance = tmPolicy?.balance || 0;
    const currentLevel = tmPolicy?.level_id;
    const levelConfig = timeOffPolicy.levels.find((level) => level._id === currentLevel);

    currentBalance = parseFloat((Math.round(currentBalance * 100) / 100).toFixed(2));

    // Get the total hours requested
    const requestedHours = buildTotalHours(schedule || []);

    // Get the updated balance
    let updatedBalance = currentBalance - requestedHours;
    updatedBalance = parseFloat((Math.round(updatedBalance * 100) / 100).toFixed(2));

    // Get request status and unlimited value
    const unlimited = timeOffPolicy ? levelConfig?.unlimited : false;

    return { currentBalance, requestedHours, updatedBalance, status, unlimited };
  }, [teamMember, timeOffPolicy, schedule, timeOffRequest]);

  const estimatedBalance = useMemo(() => {
    const { accrualProjection, usageProjection } = balanceEstimate || {
      accrualProjection: 0,
      usageProjection: 0,
    };

    return roundTo(currentBalance + accrualProjection - usageProjection);
  }, [currentBalance, balanceEstimate]);

  const estimatedUpdatedBalance = useMemo(() => {
    return roundTo(estimatedBalance - requestedHours, 2);
  }, [estimatedBalance, requestedHours]);

  /**********************************************************************************************************
   * Render the balance
   **********************************************************************************************************/
  const renderStartingBalance = () => {
    // If the request is from the past, show the current balance otherwise show the estimated balance
    if (startDate < DateTime.now()) {
      return (
        <p className={styles["summary-item"]}>
          <span className={styles["summary-title"]}>Current balance</span>
          <span className={styles["summary-value"]}>
            {currentBalance !== null && currentBalance !== undefined ? currentBalance + " hrs" : "N/A"}
          </span>
        </p>
      );
    } else {
      return (
        <p className={styles["summary-item"]}>
          <span className={styles["summary-title"]}>
            Estimated balance
            <InfoButton
              text={
                "This is an estimated number based on the balance an employee will have on the start date of this time off request. This includes any accruals / usage in between now and then. This will not include balance/accrual changes due to level changes"
              }
              place={"bottom"}
            />
          </span>
          <span className={styles["summary-value"]}>
            {estimatedBalance !== null && estimatedBalance !== undefined ? estimatedBalance + " hrs" : "N/A"}
          </span>
        </p>
      );
    }
  };

  return (
    <div className={styles["time-off-request-summary"]}>
      <div className={styles["summary-box"]}>
        {timeOffRequest?.status !== "paid" && !unlimited && <>{renderStartingBalance()}</>}
        <p className={styles["summary-item"]}>
          <span className={styles["summary-title"]}>Amount requested</span>
          <span className={styles["summary-value"]}>
            {requestedHours != null && !unlimited ? "- " : ""}
            {requestedHours != null ? requestedHours + " hrs" : "N/A"}
          </span>
        </p>
        {timeOffRequest?.status !== "paid" && !unlimited && (
          <>
            <hr></hr>
            <p className={styles["summary-item"] + " " + styles["summary-item-bold"]}>
              <span className={styles["summary-title"]}>Updated balance</span>
              <span
                className={
                  styles["summary-value"] +
                  " " +
                  (updatedBalance && updatedBalance < 0 && styles["summary-danger"])
                }
              >
                {estimatedUpdatedBalance != null ? "= " + estimatedUpdatedBalance + " hrs" : "N/A"}
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default TimeOffRequestSummary;
