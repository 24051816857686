import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActionModal, Badge, Button, ConfirmModal, Formblock, Notifier, PageModal } from "ui";
import {
  DAY_COL_PREFIX,
  EARNING_TYPE_ALIAS_SPLITTER,
  PrepTimesheetChangeOutput,
  getDayColDefs,
  getEarningTypeAliasForTs,
  getEarningTypeAliasForTor,
  getEarningTypeOptionsForTm,
  getPriorPayPeriod,
  getTimesheetsForPeriod,
  prepTimesheetChanges,
  getEarningTypeAliasForHoliday,
  getEarningTypeAliasForMissedBreak,
  timesheetsByPayPeriodEarnTypeLookup,
} from "./timesheetsByPayPeriodUtils";
import {
  AggregatedMiterEarning,
  AggregatedTimeOffRequest,
  AggregatedTimesheet,
  Company,
  CreateTimeOffRequestResponse,
  MiterAPI,
  TimeOffRequest,
  Timesheet,
  UpdateTimeOffRequestResponse,
} from "dashboard/miter";
import {
  useActiveCompany,
  useActiveTeamMember,
  useActivityLabelFormatter,
  useActivityOptionsMap,
  useGetClassificationOptions,
  useJobNameFormatter,
  useJobOptions,
  useLookupRateClassification,
  useLookupRateDifferential,
  useLookupTeam,
  useLookupTimeOffPolicy,
  useRateDifferentialOptions,
  useTeamOptions,
  useUser,
} from "dashboard/hooks/atom-hooks";
import {
  CellClassParams,
  CellValueChangedEvent,
  EditableCallbackParams,
  GridApi,
  ICellEditorParams,
  ICellRendererParams,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { DateTime } from "luxon";
import { capitalize } from "lodash";
import { PageModalActionLink } from "ui/modal/PageModal";
import { cleanFloatingPointErrors, notNullish, roundTo } from "miter-utils";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import styles from "./TimesheetsByPayPeriodEditor.module.css";
import { Copy, Plus, Trash } from "phosphor-react";
import ObjectID from "bson-objectid";
import { FaExclamationCircle } from "react-icons/fa";
import { ColumnConfig } from "ui/table-v2/Table";
import { isTimesheetScoped } from "dashboard/pages/activities/activityUtils";
import { selectEditorSuppressKeyboardEvent } from "ui/table-v2/AgGridSelectEditor";
import {
  PrepTimeOffRequestChangeOutput,
  prepTimeOffRequestChanges,
} from "./timesheet-pay-period-utils/pay-period-time-off-request-utils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { useTimesheetPolicies } from "dashboard/utils/policies/timesheet-policy-utils";
import { DraftTimesheet } from "../BulkCreateTimesheets/BulkCreateTimesheets";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";

type LocalError = { _id: string; label: string; message: string };

type PayPeriodEditorSaveResponses = {
  createdTimesheets: { errors: LocalError[]; createdTimesheets: Timesheet[] };
  updatedTimesheets: { errors: LocalError[]; updatedTimesheets: AggregatedTimesheet[] };
  archivedTimesheets: { errors: LocalError[]; archivedTimesheets: Timesheet[] };
  createdTimeOffRequests: { errors: LocalError[]; createdRequests: CreateTimeOffRequestResponse[] };
  updatedTimeOffRequests: { errors: LocalError[]; updatedRequests: UpdateTimeOffRequestResponse[] };
  archivedTimeOffRequests: { errors: LocalError[]; archivedRequests: TimeOffRequest[] };
};

export type PayPeriodHoursEditorParams = {
  editorType: "single_tm" | "single_job";
  tmId?: string;
  jobId?: string;
  periodStart: string;
  periodEnd: string;
  payScheduleId: string;
};

type Props = PayPeriodHoursEditorParams & {
  onHide: () => void;
  onSave: () => Promise<void>;
};

export type EditPayPeriodTimesheetRow = {
  _id: string;
  team_member_id?: string;
  job_id?: string;
  activity_id?: string;
  classification_override?: string;
  status: string;
  actions?: string;
  total_hours?: number;
  earning_type_alias?: string;
  rate_differential_id?: string;
  earliest_clock_in?: number;
  source?: "timesheet" | "time_off_request" | "holiday" | "missed_break";
};

export type AttributeColumnId =
  | "team_member_id"
  | "job_id"
  | "activity_id"
  | "earning_type_alias"
  | "classification_override"
  | "rate_differential_id";

type AttributeColumn = {
  value: AttributeColumnId;
  label: string;
  shown: boolean;
  required?: boolean;
};

type PayPeriodInputs = {
  timesheets: AggregatedTimesheet[];
  timeOffRequests: AggregatedTimeOffRequest[];
  mockedEarnings: AggregatedMiterEarning[];
};

type TableDataInputs = {
  timesheets: AggregatedTimesheet[];
  periodOffset?: number;
  allUnapproved?: boolean;
  timeOffRequests: AggregatedTimeOffRequest[];
  mockedEarnings: AggregatedMiterEarning[];
};

export const WorkweekEditorModal: React.FC<Props> = ({
  editorType,
  onHide,
  onSave,
  periodStart,
  periodEnd,
  tmId,
  payScheduleId,
  jobId,
}) => {
  /**************************************************************************
   *  Important hooks
   ***************************************************************************/
  const lookupTm = useLookupTeam();
  const lookupPolicy = useLookupTimeOffPolicy();
  const miterAbilities = useMiterAbilities();
  const timesheetAbilities = useTimesheetAbilities();
  const timeOffRequestAbilities = useTimeOffRequestAbilities();
  const rateDifferentialOptions = useRateDifferentialOptions();

  const { renderFailuresModal, setFailures } = useFailuresModal({ title: "Error saving timesheet" });

  const tm = lookupTm(tmId);
  const jobOptions = useJobOptions({ predicate: timesheetAbilities.jobPredicate("create") });
  const timesheetTmOptions = useTeamOptions({ predicate: timesheetAbilities.teamPredicate("create") });

  const timeOffRequestTmOptions = useTeamOptions({
    predicate: timeOffRequestAbilities.teamPredicate("create"),
  });

  const { buildPolicy } = useTimesheetPolicies();

  const getClassificationOptions = useGetClassificationOptions();
  const lookupClassification = useLookupRateClassification();
  const lookupRateDifferential = useLookupRateDifferential();
  const activeCompany = useActiveCompany();
  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();
  const activityOptionsMap = useActivityOptionsMap({ predicate: isTimesheetScoped });
  const activeTeamMember = useActiveTeamMember();
  const activeUser = useUser();
  const { can } = useMiterAbilities();

  /**************************************************************************
   *  States
   ***************************************************************************/
  const [inputs, setInputs] = useState<PayPeriodInputs>();
  const [loading, setLoading] = useState(true);
  const [unsavedData, setUnsavedData] = useState(false);
  const [saving, setSaving] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi<EditPayPeriodTimesheetRow>>();
  const [tableData, setTableData] = useState<EditPayPeriodTimesheetRow[]>([]);
  const [confirmModalProps, setConfirmProps] = useState<{ text: string; action: () => void }>();
  const [showUpdateColumnsModal, setShowUpdateColumnsModal] = useState(false);
  const [totalsRowData, setTotalsRowData] = useState<Partial<EditPayPeriodTimesheetRow>[]>([]);
  const [loadedDefaultColumns, setLoadedDefaultColumns] = useState(false);

  // Initialize the job, activity, classification, and earning type columns
  const [attributeColumns, setAttributeColumns] = useState(initializeAttributeColumns(editorType));

  const shownAttributeColumnIds = useMemo(() => {
    return attributeColumns.filter((c) => c.shown).map((c) => c.value);
  }, [attributeColumns]);

  /** Function to build a draft timesheet so we can pass it in into the policy builder */
  const buildDraftTimesheet = (row: EditPayPeriodTimesheetRow): DraftTimesheet => {
    return {
      _id: ObjectID().toHexString(),
      team_member: row.team_member_id,
      job: row.job_id,
      activity: row.activity_id,
      classification_override: row.classification_override,
    };
  };

  const validationErrors: Record<string, Record<string, string>> = useMemo(() => {
    const errors: Record<string, Record<string, string>> = {};
    tableData.forEach((row) => {
      // Only validate timesheets that are unapproved
      if (row.status !== "unapproved" || row.source !== "timesheet") return;

      const draftTimesheet = buildDraftTimesheet(row);
      const { isFieldRequired } = buildPolicy(draftTimesheet);

      errors[row._id] = errors[row._id] || {};

      if (isFieldRequired("job") && !row.job_id) {
        errors[row._id]!["job_id"] = "Job is required.";
      }
      if (isFieldRequired("activity") && !row.activity_id) {
        errors[row._id]!["activity_id"] = "Activity is required.";
      }
      if (isFieldRequired("classification_override") && !row.activity_id) {
        errors[row._id]!["classification_override"] = "Classification is required.";
      }
      if (!row.team_member_id) {
        errors[row._id]!["team_member_id"] = "Team member is required.";
      }
    });

    // Remove all rows that don't have any errors
    Object.keys(errors).forEach((rowId) => {
      if (!Object.keys(errors[rowId] || {}).length) {
        delete errors[rowId];
      }
    });

    return errors;
  }, [tableData, buildPolicy]);

  /**************************************************************************
   *  Data functions
   ***************************************************************************/
  const getData = async () => {
    if (!activeCompany || !payScheduleId) return;
    setLoading(true);

    const timesheetAuthFilter = timesheetAbilities.filter("read");
    const timeOffRequestTmIds = timeOffRequestTmOptions.map((tm) => tm.value);

    try {
      const [tsResponse, torResponse, mockEarningsResponse] = await Promise.all([
        getTimesheetsForPeriod({
          tmIds: tmId ? [tmId] : undefined,
          jobId,
          periodStart: periodStart,
          periodEnd: periodEnd,
          activeCompany,
          authFilter: timesheetAuthFilter,
        }),
        getTimeOffRequests({
          timeOffRequests: inputs?.timeOffRequests,
          jobId,
          tmIds: tmId ? [tmId] : timeOffRequestTmIds,
          periodStart,
          periodEnd,
          payScheduleId,
          activeCompany,
        }),
        MiterAPI.payrolls.pay_period_mock_earnings({
          companyId: activeCompany?._id,
          payScheduleId,
          periodStart,
          periodEnd,
          tmIds: tmId ? [tmId] : [],
          timesheetStatuses: ["approved", "unapproved", "processing", "paid"],
          timeOffRequestStatuses: ["approved", "unapproved", "processing", "paid"],
          includeNonPayrollTeamMembers: true,
        }),
      ]);

      const newColumnIds = new Set<AttributeColumnId>(shownAttributeColumnIds);

      // First loop through the timesheets to determine if any columns should be added
      tsResponse.forEach((ts) => {
        if (editorType !== "single_job" && ts.job?._id) newColumnIds.add("job_id");
        if (ts.activity) newColumnIds.add("activity_id");
        if (ts.earning_type) newColumnIds.add("earning_type_alias");
        if (ts.classification_override) newColumnIds.add("classification_override");
        if (ts.rate_differential_id) newColumnIds.add("rate_differential_id");
      });

      // Filter out timesheets that the user doesn't have access to
      const finalTimesheets = tsResponse;

      // Filter out time off requests that the user doesn't have access to
      const finalTimeOffRequests = torResponse.filter((tor) => {
        return (
          (can("time_off:requests:personal:read") && tor.employee.user === activeUser?._id) ||
          (can("time_off:requests:others:read") && tor.employee.user !== activeUser?._id)
        );
      });

      // Filter out mocked earnings that the user doesn't have access to
      const finalMockedEarnings = mockEarningsResponse.earnings.filter((e) => {
        return (
          (can("timesheets:personal:read") && e.team_member._id === activeTeamMember?._id) ||
          (can("timesheets:others:read") && e.team_member._id !== activeTeamMember?._id)
        );
      });

      setInputs({
        timesheets: finalTimesheets,
        timeOffRequests: finalTimeOffRequests,
        mockedEarnings: finalMockedEarnings,
      });
    } catch (e) {
      console.log(e);
      Notifier.error(`Error getting hours!`);
    }
  };
  /**
   * Builds the table data for the pay period using the inputs
   * - Utilizes helper functions for each type of row (timesheet, time off request, holiday, missed break penalty)
   *   to build maps of the table rows to each status. Then we get the values from the maps and sort and use them as
   *   the table data
   */
  const buildData = useCallback(
    (inputs: TableDataInputs) => {
      if (!activeCompany) return [];

      const { timesheets, periodOffset, allUnapproved, timeOffRequests } = inputs;
      const sortedTableData: EditPayPeriodTimesheetRow[] = [];

      const timesheetRows = buildTimesheetsHoursList({
        activeCompany,
        timesheets,
        periodOffset,
        shownAttributeColumnIds,
        allUnapproved,
        tmId,
        jobId,
      });

      const torMap = buildTimeOffRequestHoursMap({
        timeOffRequests,
        periodStart,
        periodEnd,
        shownAttributeColumnIds,
      });

      const holidayMap = buildHolidayHoursMap(inputs);
      const missedBreakPenaltyMap = buildMissedBreakPenaltyHoursMap(inputs);

      sortedTableData.push(...timesheetRows);
      sortedTableData.push(...torMap.values());
      sortedTableData.push(...holidayMap.values());
      sortedTableData.push(...missedBreakPenaltyMap.values());

      return sortedTableData;
    },
    [attributeColumns, activeCompany]
  );

  /** Checks if there are errors in any of the respones */
  const buildErrors = (responses: PayPeriodEditorSaveResponses): LocalError[] => {
    return [
      ...responses.createdTimesheets?.errors,
      ...responses.createdTimeOffRequests.errors,
      ...responses.updatedTimesheets.errors,
      ...responses.createdTimeOffRequests.errors,
      ...responses.updatedTimeOffRequests.errors,
      ...responses.archivedTimeOffRequests.errors,
    ];
  };

  /** Rolls back all changes */
  const rollbackChanges = async (
    responses: PayPeriodEditorSaveResponses,
    originals: {
      originalTimesheets: AggregatedTimesheet[];
      originalTimeOffRequests: AggregatedTimeOffRequest[];
    }
  ) => {
    const {
      createdTimesheets,
      updatedTimesheets,
      archivedTimesheets,
      createdTimeOffRequests,
      updatedTimeOffRequests,
      archivedTimeOffRequests,
    } = responses;

    const { originalTimesheets, originalTimeOffRequests } = originals;

    const lookupOriginalTimesheet = originalTimesheets.reduce((acc, ts) => {
      acc[ts._id] = ts;
      return acc;
    }, {} as Record<string, AggregatedTimesheet>);

    const lookupOriginalTimeOffRequest = originalTimeOffRequests.reduce((acc, tor) => {
      acc[tor._id] = tor;
      return acc;
    }, {} as Record<string, AggregatedTimeOffRequest>);

    // Delete all created timesheets and time off requests
    const archiveRes = await Promise.all([
      archiveTimesheets(createdTimesheets.createdTimesheets.map((ts) => ts._id)),
      archiveTimeOffRequests(createdTimeOffRequests.createdRequests.map((tor) => tor._id)),
    ]);

    // Unarchive all archived timesheets and time off requests
    const unarchiveRes = await Promise.all([
      archivedTimesheets.archivedTimesheets.map(async (ts) => {
        return await MiterAPI.timesheets.update_one(ts._id, { archived: false });
      }),
      MiterAPI.time_off.requests.update_multiple({
        ids: archivedTimeOffRequests.archivedRequests.map((tor) => tor._id),
        update: { archived: false },
      }),
    ]);

    // Reset all updated timesheets and time off requests
    const updatedTimesheetUpdates = updatedTimesheets.updatedTimesheets
      .map((ts) => {
        const ogTs = lookupOriginalTimesheet[ts._id];
        if (!ogTs) return;

        return {
          timesheetId: ts._id,
          clock_in: ogTs.clock_in,
          clock_out: ogTs.clock_out,
          job: ogTs.job?._id,
          activity: ogTs.activity?._id,
          classification_override: ogTs.classification_override,
          rate_differential_id: ogTs.rate_differential_id,
        };
      })
      .filter(notNullish);

    const updatedTORUpdates = updatedTimeOffRequests.updatedRequests
      .map((tor) => {
        const ogTor = lookupOriginalTimeOffRequest[tor._id];
        if (!ogTor) return;

        return {
          _id: tor._id,
          update: {
            start_date: ogTor.start_date,
            end_date: ogTor.end_date,
            schedule: ogTor.schedule,
            total_hours: ogTor.total_hours,
          },
        };
      })
      .filter(notNullish);

    // Apply changes
    const updateRes = await Promise.all([
      updateTimesheets(updatedTimesheetUpdates),
      updateTimeOffRequests(updatedTORUpdates),
    ]);

    const rollbackResObject = {
      createdTimeOffRequests: { errors: [], createdRequests: [] },
      createdTimesheets: { errors: [], createdTimesheets: [] },
      archivedTimesheets: archiveRes[0],
      archivedTimeOffRequests: archiveRes[1],
      unarchivedTimesheets: unarchiveRes[0],
      unarchivedTimeOffRequests: unarchiveRes[1],
      updatedTimesheets: updateRes[0],
      updatedTimeOffRequests: updateRes[1],
    };

    const rollbackErrors = buildErrors(rollbackResObject);
    if (rollbackErrors.length) {
      throw new Error(
        "There was an unknown error with your timesheet. Please refresh the page and try again."
      );
    }
  };

  /** Saves the table data to the database */
  const saveData = useCallback(async () => {
    if (!unsavedData || !inputs?.timesheets || !activeCompany) return;
    setSaving(true);

    try {
      // If there are validation errors, don't save
      if (Object.keys(validationErrors).length) {
        Notifier.error(
          "There are errors in the timesheet. Show all columns via the actions button to make sure all required fields are filled out."
        );
        setSaving(false);
        return;
      }

      const { timesheets, timeOffRequests } = inputs;

      /** Prep the changes for saving */
      const timesheetChanges = prepTimesheetChanges(tableData, timesheets, activeCompany);
      const timeOffRequestChanges = prepTimeOffRequestChanges(
        tableData,
        timeOffRequests,
        activeCompany,
        periodStart,
        periodEnd
      );

      /** Save the changes and get the response */
      const res = await Promise.all([
        createTimesheets(timesheetChanges.creates, activeCompany._id),
        updateTimesheets(timesheetChanges.updates, { updateTimeOnly: true }),
        archiveTimesheets(timesheetChanges.idsToArchive),
        createTimeOffRequests(timeOffRequestChanges.creates),
        updateTimeOffRequests(timeOffRequestChanges.updates),
        archiveTimeOffRequests(timeOffRequestChanges.idsToArchive),
      ]);

      const saveResponsesObj = {
        createdTimesheets: res[0],
        updatedTimesheets: res[1],
        archivedTimesheets: res[2],
        createdTimeOffRequests: res[3],
        updatedTimeOffRequests: res[4],
        archivedTimeOffRequests: res[5],
      };

      /** Pass the response to the error builder so we can rollback changes if there are errors */
      const errors = buildErrors(saveResponsesObj);

      /** If there are errors, rollback all changes */
      if (errors.length) {
        await rollbackChanges(saveResponsesObj, {
          originalTimesheets: timesheets,
          originalTimeOffRequests: timeOffRequests,
        });

        /** Set the errors in the modal */
        setFailures(errors);

        /** Throw error so we don't reload the page */
        throw new Error("Error saving timesheet");
      }

      await onSave();

      Notifier.success(`Timesheet saved successfully.`);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error saving the timesheet.`);
    }

    setSaving(false);
  }, [gridApi, tableData, unsavedData]);

  /**************************************************************************
   *  Row actions
   ***************************************************************************/
  const addRow = useCallback(
    (row: EditPayPeriodTimesheetRow | undefined) => {
      if (!gridApi) return;

      const copyOfExistingData = tableData.slice();
      const newTableData: EditPayPeriodTimesheetRow[] = [];

      const newRow = {
        _id: ObjectID().toString(),
        tmId,
        team_member_id: tmId,
        job_id: jobId,
        status: "unapproved",
        source: row?.source || "timesheet",
      };

      if (!row) newTableData.push(newRow);

      copyOfExistingData.forEach((r) => {
        newTableData.push(r);

        if (r._id === row?._id) {
          newTableData.push(newRow);
        }
      });

      setTableData(newTableData);
      gridApi.refreshCells({ force: true, suppressFlash: true });
      setUnsavedData(true);
    },
    [tableData, gridApi]
  );

  const duplicateRow = useCallback(
    (row: EditPayPeriodTimesheetRow | undefined) => {
      if (!row) return;
      const copyOfExistingData = tableData.slice();
      const newTableData: EditPayPeriodTimesheetRow[] = [];
      const newRow = { ...row, _id: ObjectID().toString() };

      copyOfExistingData.forEach((r) => {
        newTableData.push(r);

        if (r._id === row._id) {
          newTableData.push(newRow);
        }
      });

      setTableData(newTableData);
      gridApi?.refreshCells({ force: true, suppressFlash: true });
      setUnsavedData(true);
    },
    [tableData, gridApi]
  );

  const deleteRow = useCallback(
    (row: EditPayPeriodTimesheetRow | undefined) => {
      if (!row) return;
      setTableData((prev) => prev.filter((r) => r._id !== row._id));
      setUnsavedData(true);
    },
    [gridApi]
  );

  /**************************************************************************
   *  Column helper functions
   ***************************************************************************/

  /** Helper function to reset the activity value when the job in the row is changed */
  const onCellValueChanged = async (
    event: CellValueChangedEvent<EditPayPeriodTimesheetRow>
  ): Promise<void> => {
    if (event.colDef.field === "job_id" && shownAttributeColumnIds.includes("activity_id")) {
      const newJobId = event.newValue;
      const selectableActivities = activityOptionsMap.get(newJobId);
      const currActivityId = event.node.data?.activity_id;
      if (!newJobId || selectableActivities.every((a) => a.value !== currActivityId)) {
        event.node.setDataValue("activity_id", undefined, "controlledUpdate");
      }
    }

    // Update the table data when the job, activity, or classification is changed to update validation errors
    const isPolicyField =
      event.colDef.field && ["job_id", "activity_id", "classification_override"].includes(event.colDef.field);

    if (isPolicyField) {
      const newUpdatedData: EditPayPeriodTimesheetRow[] = [];
      event.api.forEachNode((node) => {
        if (!node.data) return;
        newUpdatedData.push(node.data);
      });

      setTableData(newUpdatedData);
    }

    getTotalsRowData();
    setUnsavedData(true);
  };

  const activityEditorParams = useCallback(
    (params: ICellEditorParams<EditPayPeriodTimesheetRow>) => {
      return { options: activityOptionsMap.get(params?.data.job_id), isClearable: true };
    },
    [activityOptionsMap]
  );

  const earningTypeEditorParams = useCallback(
    (params: ICellEditorParams<EditPayPeriodTimesheetRow>) => {
      const tm = lookupTm(params.data?.team_member_id);
      const options = getEarningTypeOptionsForTm(lookupPolicy, params.data?.source, tm);
      return { options: options, isClearable: true };
    },
    [activityOptionsMap]
  );

  const classificationEditorParams = useCallback(
    (params: ICellEditorParams<EditPayPeriodTimesheetRow>) => {
      const options = getClassificationOptions({
        tmId: params.data?.team_member_id,
        jobId: params.data?.job_id,
        activityId: params.data?.activity_id,
      });

      return { options: options, isClearable: true };
    },
    [getClassificationOptions]
  );

  const isEditable = useCallback(
    (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) => {
      if (!params.data) return false;

      // Allow editing of timesheets that are unapproved
      const isEditableTimesheet =
        params.data.source === "timesheet" && ["unapproved"].includes(params.data.status);

      // Allow editing of approved time off requests
      const isEditableTimeOffRequest =
        params.data.source === "time_off_request" && ["approved", "unapproved"].includes(params.data.status);

      if (!isEditableTimesheet && !isEditableTimeOffRequest) {
        return false;
      }

      if ((params.data.job_id && tmId) || (params.data.team_member_id && jobId)) {
        if (params.data.source === "timesheet" && timesheetAbilities.cannot("update", params.data)) {
          return false;
        }
      }

      if (
        params.data.source === "time_off_request" &&
        timeOffRequestAbilities.cannot("update", params.data)
      ) {
        return false;
      }

      return true;
    },
    [timesheetAbilities, timeOffRequestAbilities]
  );

  /**************************************************************************
   *  Table styling helpers
   ***************************************************************************/
  const cellStyle = (params: CellClassParams<EditPayPeriodTimesheetRow>) => {
    if (params.colDef.field === "actions" || params.colDef.field === "total_hours" || !params.data) {
      return { cursor: "default", color: "" };
    } else if (!isEditable(params)) {
      return { color: "darkGray", cursor: "default" };
    } else {
      const errors = validationErrors[params.data._id]?.[params.colDef?.field || ""];

      if (errors) {
        return {
          border: "1px solid #ff8a8a",
          cursor: "default",
          backgroundColor: "#ffe6e6",
        } as $TSFixMe;
      } else {
        return { cursor: "default", color: "" };
      }
    }
  };

  const cellClass = (params: CellClassParams<EditPayPeriodTimesheetRow>) => {
    if (params.node.level === 0 || !params.colDef.headerName || params.data?.status !== "unapproved") {
      return "no-border";
    }
  };

  const tooltipValueGetter = (params: ITooltipParams<EditPayPeriodTimesheetRow>) => {
    if (!params.data?._id) return null;
    if (!params.colDef || !("field" in params.colDef)) return null;

    return validationErrors[params.data._id]?.[params.colDef.field || ""];
  };

  /**************************************************************************
   *  Columns
   ***************************************************************************/
  const columns = useMemo(() => {
    const hasTimeOffRequests = !!inputs?.timeOffRequests?.length;

    const rawColumns: ColumnConfig<EditPayPeriodTimesheetRow>[] = [
      ...(hasTimeOffRequests
        ? [
            {
              headerName: "Source",
              field: "source",
              enableRowGroup: true,
              enablePivot: true,
              rowDrag: true,
              rowGroupIndex: 0,
              hide: true,
              pinned: "left" as const,
              rowGroup: true,
              valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
                return params.data?.source === "time_off_request" ? "Time off requests" : "Timesheets";
              },
            },
          ]
        : []),
      {
        headerName: "Status",
        field: "status",
        enableRowGroup: true,
        enablePivot: true,
        rowDrag: true,
        hide: true,
        pinned: "left",
        rowGroup: true,
        valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
          return capitalize(params.data?.status);
        },
      },
    ];

    if (shownAttributeColumnIds.includes("job_id")) {
      rawColumns.push({
        headerName: "Job",
        field: "job_id",
        minWidth: 190,
        valueFormatter: (params) => {
          return jobNameFormatter(params.data?.job_id);
        },
        cellRenderer: (params: ICellRendererParams<EditPayPeriodTimesheetRow>) => {
          return jobNameFormatter(params.data?.job_id);
        },
        filter: true,
        editable: (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) => {
          const editable = isEditable(params);
          if (!editable || !params.data) return false;

          const draftTimesheet = buildDraftTimesheet(params.data);
          const { isFieldVisible } = buildPolicy(draftTimesheet);

          return isFieldVisible("job");
        },
        filterParams: {
          keyCreator: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
            return jobNameFormatter(params.data?.job_id) || "-";
          },
          valueFormatter: (params: ValueFormatterParams<EditPayPeriodTimesheetRow>) => {
            return jobNameFormatter(params.value) || "-";
          },
        },
        comparator: (a, b) => {
          return jobNameFormatter(a).localeCompare(jobNameFormatter(b));
        },
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: { options: jobOptions, isClearable: true },
        pinned: "left",
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
      });
    }

    if (shownAttributeColumnIds.includes("team_member_id")) {
      rawColumns.push({
        headerName: "Team member",
        field: "team_member_id",
        minWidth: 190,
        valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
          return lookupTm(params.data?.team_member_id)?.full_name;
        },
        filter: true,
        editable: (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) => {
          const editable = isEditable(params);
          if (!editable || !params.data) return false;

          return true;
        },
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          if (params.data?.source === "time_off_request") {
            return { options: timeOffRequestTmOptions, isClearable: true };
          }

          return { options: timesheetTmOptions, isClearable: true };
        },
        pinned: "left",
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
      });
    }

    if (shownAttributeColumnIds.includes("activity_id")) {
      rawColumns.push({
        headerName: "Activity",
        field: "activity_id",
        valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
          return activityLabelFormatter(params.data?.activity_id);
        },
        minWidth: 190,
        editable: (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) => {
          const editable = isEditable(params);
          if (!editable || !params.data) return false;

          const draftTimesheet = buildDraftTimesheet(params.data);
          const { isFieldVisible } = buildPolicy(draftTimesheet);

          return isFieldVisible("activity");
        },
        pinned: "left",
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: activityEditorParams,
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
      });
    }

    if (shownAttributeColumnIds.includes("classification_override")) {
      rawColumns.push({
        headerName: "Classification",
        field: "classification_override",
        valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
          if (!params?.data?.classification_override) return "Auto";
          return lookupClassification(params?.data?.classification_override)?.classification;
        },
        minWidth: 190,
        editable: (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) => {
          const editable = isEditable(params);
          if (!editable || !params.data) return false;

          const draftTimesheet = buildDraftTimesheet(params.data);
          const { isFieldVisible } = buildPolicy(draftTimesheet);

          return isFieldVisible("classification_override");
        },
        pinned: "left",
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: classificationEditorParams,
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
      });
    }

    if (shownAttributeColumnIds.includes("earning_type_alias")) {
      rawColumns.push({
        headerName: "Earning type",
        field: "earning_type_alias",
        valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
          if (params?.data?._id.includes("totals_row")) return "";

          const [eType, pId] = params?.data?.earning_type_alias?.split(EARNING_TYPE_ALIAS_SPLITTER) || [];
          if (!eType) return "Auto";

          let label = timesheetsByPayPeriodEarnTypeLookup(eType, eType);

          const policy = lookupPolicy(pId);
          if (policy) label += ` (${policy.name})`;

          return label || "Auto";
        },
        editable: (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) =>
          params.data?.status === "unapproved" && timesheetAbilities.can("update", params.data),
        pinned: "left",
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: earningTypeEditorParams,
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
      });
    }

    if (shownAttributeColumnIds.includes("rate_differential_id")) {
      rawColumns.push({
        headerName: "Rate differential",
        field: "rate_differential_id",
        valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
          return lookupRateDifferential(params.data?.rate_differential_id)?.label || "";
        },
        editable: (params: EditableCallbackParams<EditPayPeriodTimesheetRow>) =>
          params.data?.status === "unapproved" && timesheetAbilities.can("update", params.data),
        pinned: "left",
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: { options: rateDifferentialOptions, isClearable: true },
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
      });
    }

    rawColumns.push(
      ...getDayColDefs({
        periodStart,
        periodEnd,
        usingTableV2: false,
        timesheetAbilities,
        timeOffRequestAbilities,
      }),
      {
        headerName: "Total",
        aggFunc: "sumValues",
        field: "total_hours" as const,
        maxWidth: 90,
        pinned: "right",
        valueGetter: (params: ValueGetterParams<EditPayPeriodTimesheetRow>) => {
          let hours = 0;
          for (const key of Object.keys(params?.data || {})) {
            if (key.startsWith(DAY_COL_PREFIX)) {
              hours += Number(params.data?.[key]) || 0;
            }
          }
          return roundTo(hours);
        },
      },
      {
        headerName: " ",
        field: "actions",
        maxWidth: 100,
        minWidth: 200,
        pinned: "right",
        cellRenderer: (params: ICellRendererParams<EditPayPeriodTimesheetRow>) => {
          if (params.data?.status !== "unapproved") return null;
          const canCreate =
            (params?.data?.source === "timesheet" && timesheetAbilities.can("create", params.data)) ||
            (params?.data?.source === "time_off_request" &&
              timeOffRequestAbilities.can("create", params.data));

          return (
            <div className="flex" style={{ cursor: "default" }}>
              {canCreate && (
                <Plus
                  size={15}
                  onClick={() => addRow(params.data)}
                  style={{ cursor: "pointer", marginRight: 10 }}
                />
              )}
              {canCreate && (
                <Copy
                  size={15}
                  onClick={() => duplicateRow(params.data)}
                  style={{ cursor: "pointer", marginRight: 10 }}
                />
              )}
              <Trash size={15} onClick={() => deleteRow(params.data)} style={{ cursor: "pointer" }} />
            </div>
          );
        },
      }
    );

    return rawColumns.map(
      (c): ColumnConfig<EditPayPeriodTimesheetRow> => ({ ...c, cellStyle, cellClass, tooltipValueGetter })
    );
  }, [
    periodStart,
    periodEnd,
    activityEditorParams,
    jobOptions,
    deleteRow,
    duplicateRow,
    attributeColumns,
    tableData,
    inputs?.timeOffRequests,
    shownAttributeColumnIds,
    timesheetAbilities,
    timeOffRequestAbilities,
    isEditable,
  ]);

  /**************************************************************************
   *  Table actions
   ***************************************************************************/

  /** Copies over the timesheets from the previous pay period into this one. Does not copy any other types of earnings */
  const copyTimesheetsFromPriorPeriod = async () => {
    if (!inputs) return;
    clearHours();

    const {
      periodStart: priorPeriodStart,
      periodEnd: priorPeriodEnd,
      periodLength,
    } = getPriorPayPeriod({
      periodEnd,
      periodStart,
    });

    const priorPeriodTimesheets = await getTimesheetsForPeriod({
      tmIds: tmId ? [tmId] : undefined,
      jobId,
      periodStart: priorPeriodStart,
      periodEnd: priorPeriodEnd,
      activeCompany,
    });

    const priorPeriodRows = buildData({
      timesheets: priorPeriodTimesheets,
      periodOffset: periodLength,
      allUnapproved: true,
      timeOffRequests: inputs?.timeOffRequests,
      mockedEarnings: [],
    });

    setTableData((prev) => {
      const newRows = prev.filter((row) => row.status !== "unapproved");
      newRows.push(...priorPeriodRows);

      return newRows.sort((a, _b) => {
        if (a.status === "unapproved") return -1;
        else return 1;
      });
    });
    setUnsavedData(true);
  };

  /** Helper function to clear out the hours for the timesheets that were copied from the previous pay period */
  const clearHours = () => {
    setTableData((prev) => {
      const newData: EditPayPeriodTimesheetRow[] = [];

      for (const row of prev) {
        if (row.status === "unapproved") {
          const newRow = { ...row };

          for (const key of Object.keys(row)) {
            if (key.startsWith(DAY_COL_PREFIX)) {
              newRow[key] = 0;
            }
          }
          newData.push(newRow);
        } else {
          newData.push(row);
        }
      }
      return newData;
    });

    setUnsavedData(true);
    setConfirmProps(undefined);
  };

  const footerActions = useMemo(() => {
    const acts: PageModalActionLink[] = [];
    acts.push({
      label: "Save and close",
      action: saveData,
      loading: saving,
      className: `button-2`,
      disabled: !unsavedData || saving,
      position: "right",
    });
    return acts;
  }, [unsavedData, tableData, saving, saveData]);

  const renderDefaultActionButtons = () => {
    const canCreate = tm
      ? timesheetAbilities.teamPredicate("create")(tm)
      : miterAbilities.can("timesheets:others:create") || miterAbilities.can("timesheets:personal:create");

    const dropdownItems = [
      {
        text: "Copy from prior period",
        onClick: () => {
          const { rangeString } = getPriorPayPeriod({ periodEnd, periodStart });
          setConfirmProps({
            text: `Are you sure you want to copy time from the prior period (${rangeString})? This action will overwrite existing unapproved hours.`,
            action: copyTimesheetsFromPriorPeriod,
          });
        },
        show: canCreate,
      },
      {
        text: "Clear hours",
        onClick: () => {
          setConfirmProps({
            text: "Are you sure you want to clear all hours?",
            action: clearHours,
          });
        },
        show: canCreate,
      },
      {
        text: "Update columns",
        onClick: () => setShowUpdateColumnsModal(true),
        show: true,
      },
    ];

    const filteredDropdownItems = dropdownItems.filter((item) => item.show);

    return (
      <div className="flex">
        {canCreate && <Button className="button-2" text={"+ Add row"} onClick={() => addRow(undefined)} />}
        <Button text={"Actions"} dropdownItems={filteredDropdownItems} />
      </div>
    );
  };

  /**************************************************************************
   *  useEffects
   ***************************************************************************/

  /** Show all the columns that are set in the */
  useEffect(() => {
    if (!activeCompany) return;

    if (!activeCompany?.settings?.timesheets?.default_timesheet_pay_period_editor_columns) {
      setLoadedDefaultColumns(true);
      return;
    }

    const defaultColumns = activeCompany?.settings?.timesheets?.default_timesheet_pay_period_editor_columns;
    const shownColumns = attributeColumns.map((c) => {
      if (c.value === "team_member_id" && editorType === "single_job") return { ...c, shown: true };
      if (c.value === "job_id" && editorType === "single_tm") return { ...c, shown: true };

      return { ...c, shown: defaultColumns.includes(c.value) };
    });
    setAttributeColumns(shownColumns);
    setLoadedDefaultColumns(true);
  }, [activeCompany?.settings?.timesheets?.default_timesheet_pay_period_editor_columns]);

  /** Load the table data inputs once the default columns have been loaded */
  useEffect(() => {
    if (!loadedDefaultColumns) return;
    getData();
  }, [loadedDefaultColumns]);

  /** Rebuild table data anytime the inputs change */
  useEffect(() => {
    if (!inputs) return;
    const data = buildData(inputs);
    setTableData(data);
  }, [inputs, buildData]);

  /** Build and set the totals row data anytime the table data changes */
  useEffect(() => {
    getTotalsRowData();
  }, [tableData]);

  const handleModalExit = () => {
    if (!unsavedData) return onHide();

    setConfirmProps({
      text: "You have unsaved changes. Are you sure you want to exit?",
      action: onHide,
    });
  };

  const getTotalsRowData = () => {
    const totalsRow = tableData.reduce(
      (overall, data) => {
        return Object.keys(data).reduce((acc, key) => {
          if (data[key] == null) return acc;

          // Check if the column is a number or strip any non-numeric characters and convert it into a number if possible
          const isNumber = typeof data[key] === "number";
          const val = isNumber ? data[key] : Number(data[key].toString().replace(/[^0-9.-]+/g, ""));

          if (isNaN(val)) return acc;

          acc[key] = (acc[key] || 0) + val;
          return acc;
        }, overall);
      },
      { _id: "totals_row" }
    );

    for (const key of Object.keys(totalsRow)) {
      if (typeof totalsRow[key] == "number") {
        const cellValue = cleanFloatingPointErrors(totalsRow[key]);
        totalsRow[key] = cellValue;
      }
    }

    setTotalsRowData([totalsRow]);
  };

  const pageTitle = useMemo(() => {
    const periodStartDt = DateTime.fromISO(periodStart);
    const periodStartString = periodStartDt.toFormat("LLL d");
    const periodEndString = DateTime.fromISO(periodEnd).toFormat("LLL d, yyyy");
    const dateRangeString = `${periodStartString} - ${periodEndString}`;
    const titlePrefix = jobId ? jobNameFormatter(jobId) : `${tm?.full_name}'s`;

    return `${titlePrefix} hours for ${dateRangeString}`;
  }, [tm, periodStart, periodEnd, jobId]);

  return (
    <PageModal
      header={pageTitle}
      onClose={handleModalExit}
      footerActions={footerActions}
      bodyContentStyle={{ maxWidth: 1600, padding: "3%" }}
    >
      {showUpdateColumnsModal && (
        <UpdateColumnsModal
          attributeColumns={attributeColumns}
          setColumns={setAttributeColumns}
          hide={() => setShowUpdateColumnsModal(false)}
        />
      )}
      {renderFailuresModal()}
      <div className={styles["grid-container"]}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%", maxWidth: 1600 }}>
          <AgGridTable
            columnDefs={columns}
            data={tableData}
            gridHeight={"60vh"}
            hideDownloadCSV={true}
            wrapperClassName="pay-period-hours-editor"
            hideSidebar={true}
            loading={loading}
            setGridApi={setGridApi}
            defaultActionButtons={renderDefaultActionButtons}
            gridOptions={{
              rowSelection: "multiple",
              isGroupOpenByDefault() {
                return true;
              },
              onCellValueChanged,
              pinnedBottomRowData: totalsRowData,
              groupDisplayType: "groupRows",
              suppressRowClickSelection: true,
              singleClickEdit: true,
              stopEditingWhenCellsLoseFocus: true,
              tooltipShowDelay: 0,
              alwaysShowHorizontalScroll: true,
              groupDefaultExpanded: 2,
              autoGroupColumnDef: {
                pinned: "left",
                cellClass: "no-border",
                maxWidth: 130,
                headerName: "Status",
                cellRendererParams: {
                  suppressCount: true,
                },
                cellRenderer: (params: ICellRendererParams<EditPayPeriodTimesheetRow>) => {
                  if (params.data?.status === "unapproved") return null;
                  return <Badge className="table-v2-badge" text={params?.data?.status} />;
                },
              },
            }}
            containerStyle={{ marginBottom: 0, paddingBottom: 0 }}
          />
        </div>
        {confirmModalProps && (
          <ConfirmModal
            body={confirmModalProps.text}
            yellowBodyText={true}
            onYes={() => confirmModalProps.action()}
            onNo={() => setConfirmProps(undefined)}
          />
        )}
      </div>
    </PageModal>
  );
};

type ChangeColumnsProps = {
  setColumns: (columns: AttributeColumn[]) => void;
  hide: () => void;
  attributeColumns: AttributeColumn[];
};

const UpdateColumnsModal: React.FC<ChangeColumnsProps> = ({ attributeColumns, setColumns, hide }) => {
  const [localColumns, setLocalColumns] = useState<AttributeColumn[]>(attributeColumns);

  const shownColumnIds = useMemo(() => {
    return localColumns.filter((c) => c.shown).map((c) => c.value);
  }, [localColumns]);

  const handleSubmit = () => {
    setColumns(localColumns);
    hide();
  };

  const handleItemClick = (e) => {
    setLocalColumns((prev) => {
      return prev.map((c) => {
        return { ...c, shown: c.value === e.target.name ? e.target.checked : c.shown };
      });
    });
  };

  return (
    <ActionModal
      headerText="Update columns"
      onSubmit={handleSubmit}
      submitText="Save"
      cancelText="Cancel"
      onCancel={hide}
      onHide={hide}
      showSubmit={true}
      showCancel={true}
    >
      <>
        <div className="vertical-spacer"></div>
        <div className="yellow-text-container flex">
          <FaExclamationCircle style={{ color: " #a59613", marginRight: "10px" }} />
          <span>
            <span className="bold">Note</span>
            <span>: Updating columns will overwrite any unsaved changes.</span>
          </span>
        </div>
        <div className="vertical-spacer"></div>
        <div style={{ paddingLeft: 10 }}>
          {attributeColumns.map((c) => {
            return (
              <div>
                <Formblock
                  type="checkbox"
                  onChange={handleItemClick}
                  editing={true}
                  key={c.value}
                  name={c.value}
                  disabled={c.required}
                  checked={shownColumnIds.includes(c.value)}
                  text={c.label}
                />
              </div>
            );
          })}
        </div>
        <div className="vertical-spacer"></div>
      </>
    </ActionModal>
  );
};

export const PAY_PERIOD_EDITOR_COLUMN_OPTIONS = [
  { label: "Job", value: "job_id" },
  { label: "Activity", value: "activity_id" },
  { label: "Team member", value: "team_member_id" },
  { label: "Earning type", value: "earning_type_alias" },
  { label: "Classification", value: "classification_override" },
  { label: "Rate differential", value: "rate_differential_id" },
];

// Helper function to initialize attribute columns
const initializeAttributeColumns = (editorType: EditorType): AttributeColumn[] => {
  const cols: AttributeColumn[] = [];

  if (editorType !== "single_job") {
    cols.push({ label: "Job", value: "job_id", shown: true });
  }

  cols.push({ label: "Activity", value: "activity_id", shown: true });

  if (editorType !== "single_tm") {
    cols.push({ label: "Team member", value: "team_member_id", shown: true, required: true });
  }

  cols.push({ label: "Earning type", value: "earning_type_alias", shown: false });
  cols.push({ label: "Classification", value: "classification_override", shown: false });
  cols.push({ label: "Rate differential", value: "rate_differential_id", shown: false });

  return cols;
};

type EditorType = "single_tm" | "single_job";

/**************************************************************************
 *  Backend CRUD helpers
 ***************************************************************************/
const createTimesheets = async (creates: PrepTimesheetChangeOutput["creates"], companyId: string) => {
  if (!companyId || !creates.length) return { createdTimesheets: [], errors: [] };

  const timesheets = creates.map((c) => {
    return { ...c, company: companyId, creation_method: "dashboard" };
  });

  const response = await MiterAPI.timesheets.create_many({ timesheets });
  const createdTimesheets = response.createdTimesheets;
  const errors = response.errors.map((e) => {
    return { _id: "n/a", label: "Error creating timesheet", message: e };
  });

  return { createdTimesheets, errors };
};

const updateTimesheets = async (
  updates: PrepTimesheetChangeOutput["updates"],
  opts?: { updateTimeOnly?: boolean }
) => {
  const { updateTimeOnly } = opts || {};

  const errors: LocalError[] = [];
  if (!updates.length) return { updatedTimesheets: [], errors: [] };

  const updatedTimesheets = await Promise.all(
    updates.map(async (u) => {
      try {
        const updateParams = updateTimeOnly ? { clock_in: u.clock_in, clock_out: u.clock_out } : u;
        const response = await MiterAPI.timesheets.update_one(u.timesheetId, updateParams);
        if (response.error) throw new Error(response.error);

        return response;
      } catch (e: $TSFixMe) {
        errors.push({
          _id: u.timesheetId,
          label: "Error updating timesheet",
          message: e.message,
        });
      }
    })
  );

  return { updatedTimesheets: updatedTimesheets.filter(notNullish), errors };
};

const archiveTimesheets = async (idstoArchive: PrepTimesheetChangeOutput["idsToArchive"]) => {
  if (!idstoArchive.length) return { archivedTimesheets: [], errors: [] };

  const response = await MiterAPI.timesheets.archive({ ids: idstoArchive });

  const archivedTimesheets = response.archived;
  const errors = response.failed_to_archive.map((e) => {
    return { _id: e._id, label: "Error deleting timesheet", message: "Unable to archive timesheet" };
  });

  return { archivedTimesheets, errors };
};

const getTimeOffRequests = (params: {
  timeOffRequests?: AggregatedTimeOffRequest[];
  jobId?: string;
  tmIds?: string[];
  periodStart: string;
  periodEnd: string;
  payScheduleId: string;
  activeCompany: Company;
}) => {
  const { timeOffRequests, jobId, tmIds, periodStart, periodEnd, payScheduleId, activeCompany } = params;

  if (jobId) return [];
  if (timeOffRequests) return timeOffRequests;

  return MiterAPI.time_off.requests.retrieve_for_pay_period({
    payScheduleId,
    companyId: activeCompany?._id,
    periodStart,
    periodEnd,
    tmIds: tmIds,
    includedStatuses: ["approved", "unapproved", "processing", "paid"],
  });
};

const createTimeOffRequests = async (creates: PrepTimeOffRequestChangeOutput["creates"]) => {
  if (!creates.length) return { errors: [], createdRequests: [] };

  const errors: LocalError[] = [];
  const createdRequests = await Promise.all(
    creates.map(async (create) => {
      try {
        const response = await MiterAPI.time_off.requests.create({ data: create });
        if (response.error) throw new Error(response.error);

        return response;
      } catch (e: $TSFixMe) {
        errors.push({ _id: "n/a", label: "Error creating time off request", message: e.message });
      }
    })
  );

  return { errors, createdRequests: createdRequests.filter(notNullish) };
};

const updateTimeOffRequests = async (updates: PrepTimeOffRequestChangeOutput["updates"]) => {
  const errors: LocalError[] = [];

  const updatedRequests = await Promise.all(
    updates.map(async (u) => {
      try {
        const response = await MiterAPI.time_off.requests.update(u._id, {
          data: u.update,
          opts: { allow_approved_hours_changes: true },
        });
        if (response.error) throw new Error(response.error);
        return response;
      } catch (e: $TSFixMe) {
        errors.push({
          _id: u._id,
          label: "Error updating time off request",
          message: e.message,
        });
      }
    })
  );

  return { errors, updatedRequests: updatedRequests.filter(notNullish) };
};

const archiveTimeOffRequests = async (idsToArchive: PrepTimesheetChangeOutput["idsToArchive"]) => {
  const response = await MiterAPI.time_off.requests.update_multiple({
    ids: idsToArchive,
    update: { archived: true },
    opts: { allow_approved_hours_changes: true },
  });

  return {
    errors: response.errors.map((e) => ({
      _id: e._id,
      label: "Error deleting time off request",
      message: e.message,
    })),
    archivedRequests: response.successes,
  };
};

/**************************************************************************
 *  Mapper functions used to convert inputs into table data maps
 *  - THe actual rows are the values of the maps
 ***************************************************************************/
const buildTimesheetsHoursList = (params: {
  timesheets: AggregatedTimesheet[];
  activeCompany: Company;
  periodOffset?: number;
  allUnapproved?: boolean;
  shownAttributeColumnIds: AttributeColumnId[];
  jobId?: string;
  tmId?: string;
}) => {
  const { timesheets, activeCompany, periodOffset, allUnapproved, shownAttributeColumnIds, jobId, tmId } =
    params;

  const zone = activeCompany?.timezone;

  const timesheetMap = timesheets.reduce((map, ts) => {
    const earningTypeAlias = getEarningTypeAliasForTs(ts);
    const dateString = DateTime.fromSeconds(ts.clock_in, { zone })
      .plus({ days: periodOffset || 0 })
      .toISODate();

    let key = ts.status;

    if (shownAttributeColumnIds.includes("team_member_id")) key += ts.team_member?._id;
    if (shownAttributeColumnIds.includes("job_id")) key += ts.job?._id;
    if (shownAttributeColumnIds.includes("activity_id")) key += ts.activity?._id;
    if (shownAttributeColumnIds.includes("earning_type_alias")) key += earningTypeAlias;
    if (shownAttributeColumnIds.includes("classification_override")) key += ts.classification_override;
    if (shownAttributeColumnIds.includes("rate_differential_id")) key += ts.rate_differential_id;

    const dayColString = `${DAY_COL_PREFIX}${dateString}`;
    const item = map.get(key) || {
      _id: ObjectID().toString(),
      team_member_id: ts.team_member?._id,
      status: allUnapproved ? "unapproved" : ts.status,
      classification_override: ts.classification_override || undefined,
      total_hours: 0,
      job_id: ts.job?._id,
      activity_id: ts.activity?._id,
      earning_type_alias: earningTypeAlias,
      rate_differential_id: ts.rate_differential_id || undefined,
      earliest_clock_in: ts.clock_in,
      source: "timesheet",
    };

    item.total_hours = (item.total_hours || 0) + ts.hours;
    item[dayColString] = (item[dayColString] || 0) + ts.hours;

    if (ts.clock_in < (item.earliest_clock_in || 0)) item.earliest_clock_in = ts.clock_in;

    return map.set(key, item);
  }, new Map<string, EditPayPeriodTimesheetRow>());

  // Ensure unapproved hours are first
  const sortedData = Array.from(timesheetMap.values()).sort((a, b) => {
    if (a.status === b.status) return (a.earliest_clock_in || 0) - (b.earliest_clock_in || 0);
    return a.status === "unapproved" ? -1 : 1;
  });

  if (sortedData.filter((r) => r.status === "unapproved").length === 0) {
    sortedData.push({
      _id: ObjectID().toString(),
      team_member_id: tmId,
      job_id: jobId,
      status: "unapproved",
      source: "timesheet",
    });
  }

  return sortedData;
};

const buildTimeOffRequestHoursMap = (params: {
  timeOffRequests: AggregatedTimeOffRequest[];
  periodStart: string;
  periodEnd: string;
  shownAttributeColumnIds: AttributeColumnId[];
}) => {
  const { timeOffRequests, periodStart, periodEnd, shownAttributeColumnIds } = params;

  const torMap = timeOffRequests.reduce((map, tor) => {
    const earning_type_alias = getEarningTypeAliasForTor(tor);

    tor.schedule.forEach((day) => {
      // If the day is not in the pay period, skip it
      if (day.date < periodStart || day.date > periodEnd) return;

      let key = tor.status;

      if (shownAttributeColumnIds.includes("team_member_id")) key += tor.employee._id;
      if (shownAttributeColumnIds.includes("job_id")) key += day.job_id;
      if (shownAttributeColumnIds.includes("activity_id")) key += day.activity_id;
      if (shownAttributeColumnIds.includes("earning_type_alias")) key += earning_type_alias;

      const item = map.get(key) || {
        _id: ObjectID().toString(),
        team_member_id: tor.employee?._id,
        total_hours: 0,
        status: tor.status,
        earning_type_alias,
        source: "time_off_request",
      };

      const dayColString = `${DAY_COL_PREFIX}${day.date}`;
      item[dayColString] = (item[dayColString] || 0) + day.hours;
      item.total_hours = (item.total_hours || 0) + day.hours;
      item.job_id = day.job_id || undefined;
      item.activity_id = day.activity_id || undefined;

      map.set(key, item);
    });

    return map;
  }, new Map<string, EditPayPeriodTimesheetRow>());

  return torMap;
};

const buildHolidayHoursMap = (params: { mockedEarnings: AggregatedMiterEarning[] }) => {
  const { mockedEarnings } = params;

  const holidayMap = mockedEarnings
    // Filter out any mocked holiday earnings that are from a timesheet as the timesheet will already be included
    .filter((e) => e.miter_type === "holiday" && !e.timesheet)
    .reduce((map, he) => {
      if (!he.date) return map;
      const earningTypeAlias = getEarningTypeAliasForHoliday(he);

      const item = {
        _id: ObjectID().toString(),
        team_member_id: he.team_member._id,
        tmId: he.team_member._id,
        total_hours: he.hours || 0,
        source: "holiday" as const,
        earning_type_alias: earningTypeAlias,
        job_id: he.job?._id,
        activity_id: he.activity?._id,
        status: "auto",
      };

      const dayColString = `${DAY_COL_PREFIX}${he.date}`;
      item[dayColString] = (item[dayColString] || 0) + he.hours;
      item.total_hours += he.hours || 0;

      return map.set(he.date, item);
    }, new Map<string, EditPayPeriodTimesheetRow>());

  return holidayMap;
};

const buildMissedBreakPenaltyHoursMap = (params: { mockedEarnings: AggregatedMiterEarning[] }) => {
  const { mockedEarnings } = params;

  const missedBreakPenaltyHoursMap = mockedEarnings
    .filter((e) => e.miter_type === ("missed_break" as const))
    .reduce((map, e) => {
      if (!e.date) return map;
      const earningTypeAlias = getEarningTypeAliasForMissedBreak(e);

      const item = {
        _id: ObjectID().toString(),
        team_member_id: e.team_member._id,
        tmId: e.team_member._id,
        total_hours: e.hours || 0,
        source: "missed_break" as const,
        earning_type_alias: earningTypeAlias,
        job_id: e.job?._id,
        activity_id: e.activity?._id,
        status: "auto",
      };

      const dayColString = `${DAY_COL_PREFIX}${e.date}`;
      item[dayColString] = (item[dayColString] || 0) + e.hours;
      item.total_hours += e.hours || 0;

      return map.set(e.date, item);
    }, new Map<string, EditPayPeriodTimesheetRow>());

  return missedBreakPenaltyHoursMap;
};
