import { useEffect, useState } from "react";
import { NetSuiteSubsidiary } from "backend/services/netsuite/types/netsuite-types";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "ui";

type GetSubsidiariesHook = {
  loading: boolean;
  subsidiaries: NetSuiteSubsidiary[];
};

export const useGetSubsidiaries = (integration: MiterIntegrationForCompany): GetSubsidiariesHook => {
  const [loading, setLoading] = useState(false);
  const [subsidiaries, setSubsidiaries] = useState<NetSuiteSubsidiary[]>([]);

  const getSubsidiaries = async () => {
    if (!integration.connection?._id) {
      Notifier.error(`There was a problem getting the setup link for the integration.`);
      return;
    }
    setLoading(true);

    try {
      const response = await MiterAPI.integrations.netsuite.get_subsidiaries(integration.connection._id);
      if (response.error) throw new Error(response.error);
      setSubsidiaries(response.subsidiaries);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was a problem getting the setup link for the integration.`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getSubsidiaries();
  }, []);

  return {
    loading,
    subsidiaries,
  };
};
