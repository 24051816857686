/* global Canny */
import { Notifier, useQuery } from "dashboard/utils";
import React, { useEffect, useState } from "react";
import { MiterAPI } from "dashboard/miter";
import { Helmet } from "react-helmet";
import { Loader } from "ui";
import { AirplaneTakeoff } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";

const BoardToken = "14517958-ff39-4b11-0e5e-2969d63f442a";

const Feedback: React.FC<{ ssoToken: string }> = ({ ssoToken }) => {
  useEffect(() => {
    (function (w: $TSFixMe, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          const f = d.getElementsByTagName(s)[0] as $TSFixMe,
            e = d.createElement(s) as $TSFixMe;
          (e.type = "text/javascript"),
            (e.async = !0),
            (e.src = "https://canny.io/sdk.js"),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ("function" != typeof w.Canny) {
        const c = function () {
          // eslint-disable-next-line prefer-rest-params
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          "complete" === d.readyState
            ? l()
            : w.attachEvent
            ? w.attachEvent("onload", l)
            : w.addEventListener("load", l, !1);
      }
    })(window, document, "canny-jssdk", "script");

    // @ts-expect-error Canny is not a function
    Canny("render", { boardToken: BoardToken, basePath: "/forums", ssoToken });
  }, [ssoToken]);

  return <div data-canny />;
};

export const FeatureRequests: React.FC = () => {
  const activeUser = useUser();
  const activeCompanyId = useActiveCompanyId();
  const query = useQuery();
  const navigate = useNavigate();

  const [ssoToken, setSsoToken] = useState<string | undefined>();

  useEffect(() => {
    getToken();
  }, [activeCompanyId]);

  useEffect(() => {
    const redirect = query.get("redirect");
    const companyID = query.get("companyID");
    if (redirect && companyID && ssoToken) handleCannyRedirect(redirect, companyID);
  }, [query, ssoToken]);

  const getToken = async () => {
    if (!activeUser || !activeCompanyId) return;

    try {
      const response = await MiterAPI.canny.get_sso_token(activeUser._id, activeCompanyId);
      if (response.error) throw new Error(response.error);
      setSsoToken(response.token);
    } catch (e) {
      Notifier.error("Unable to load feature requests");
      console.error(e);
      navigate("/home");
    }
  };

  const handleCannyRedirect = async (redirect: string, companyID: string) => {
    if (ssoToken) {
      const redirectURL =
        "https://canny.io/api/redirects/sso?companyID=" +
        companyID +
        "&ssoToken=" +
        ssoToken +
        "&redirect=" +
        redirect;

      window.location.assign(redirectURL);
    }
  };

  return (
    <>
      <Helmet>
        <title>Feature Requests | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Feature Requests</h1>
        </div>
        <div className="vertical-spacer"></div>
        <div className="yellow-text-container flex">
          <AirplaneTakeoff size={25} color="#4d55bb" />
          <div style={{ marginLeft: 10 }}>
            Your feedback is extremely important to us. Please add, upvote, and comment on features that would
            make your life easier!
          </div>
        </div>
        <div className="vertical-spacer"></div>
        {ssoToken ? <Feedback ssoToken={ssoToken} /> : <Loader />}
      </div>
    </>
  );
};
