import React, { useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { MiterAPI } from "../../miter";
import { Notifier, Button, Toggler, Loader } from "ui";
import { useActiveCompanyId, useStripeConnectedAccount } from "../../hooks/atom-hooks";
import { Params, useNavigate, useParams } from "react-router-dom";
import ExpenseReimbursements from "./ExpenseReimbursements";
import CardsLander from "./CardsLander";
import { initializeStripeAccount } from "dashboard/utils/expenses";
import { isMiterCardsAccountFullyActive } from "./expenseUtils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import CardProgramTable from "./CardProgramTable";
import { CardTransactionsTable } from "./CardTransactionsTable";
import { useHasAccessToBillPay, useHasAccessToCardManagement } from "dashboard/gating";
import Vendors from "../vendors/Vendors";
import BillPay from "../bills/BillPay";

const Expenses: React.FC = () => {
  /*********************************************************
   *  Use initial hooks
   **********************************************************/
  const { view, id } = useParams<Params>();
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const stripeAccount = useStripeConnectedAccount();
  const [hasThirdPartyCards, setHasThirdPartyCards] = useState<boolean>(true);
  const [isLoadingThirdPartyCards, setIsLoadingThirdPartyCards] = useState(true);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const { can, cannot } = useMiterAbilities();

  const hasAccessToBillPay = useHasAccessToBillPay();
  const alwaysHasAccessToCards = useHasAccessToCardManagement();

  const activePath = view;
  const isStripeAccountSetupComplete = isMiterCardsAccountFullyActive(stripeAccount);

  const showCardsOnboarding = useMemo(() => {
    // no third party cards or hasn't finished setting up Stripe account
    return !alwaysHasAccessToCards && !hasThirdPartyCards && !isStripeAccountSetupComplete;
  }, [hasThirdPartyCards, stripeAccount]);

  /*********************************************************
   *  Configure toggler
   **********************************************************/
  const togglerConfig = useMemo(() => {
    if (showCardsOnboarding) {
      return [
        {
          path: "reimbursements",
          label: "Reimbursements",
          hide: cannot("reimbursements:others:read") && cannot("reimbursements:personal:read"),
        },
        {
          path: "bill-pay",
          label: "Bill pay",
          hide: !hasAccessToBillPay || cannot("bill_pay:bills:read"),
        },
        {
          path: "vendors",
          label: "Vendors",
          hide: !hasAccessToBillPay || cannot("bill_pay:bills:read"),
        },
        {
          path: "card-transactions",
          label: "Get started with cards",
          hide: cannot("miter_cards:create") && cannot("third_party_cards:create"),
        },
      ];
    } else {
      return [
        {
          path: "card-transactions",
          label: "Card transactions",
          hide: cannot("card_transactions:others:read") && cannot("card_transactions:personal:read"),
        },
        {
          path: "card-programs",
          label: "Card programs",
          hide: cannot("miter_cards:read") && cannot("third_party_cards:read"),
        },
        {
          path: "reimbursements",
          label: "Reimbursements",
          hide: cannot("reimbursements:others:read") && cannot("reimbursements:personal:read"),
        },
        {
          path: "bill-pay",
          label: "Bill pay",
          hide: !hasAccessToBillPay || cannot("bill_pay:bills:read"),
        },
        {
          path: "vendors",
          label: "Vendors",
          hide: !hasAccessToBillPay || cannot("bill_pay:bills:read"),
        },
      ];
    }
  }, [showCardsOnboarding, cannot]);

  const toggle = (page: string) => {
    return navigate("/expenses/" + page);
  };

  useEffect(() => {
    if (isLoadingThirdPartyCards) return;

    // get first toggler path not hidden
    const defaultPath = togglerConfig.find((item) => !item.hide)?.path;
    if (!activePath) {
      navigate(`/expenses/${defaultPath}`, { replace: true });
    }
  }, [isLoadingThirdPartyCards, togglerConfig, activePath]);

  /*********************************************************
   *  Rendering functions
   **********************************************************/

  const renderToggler = () => <Toggler config={togglerConfig} active={activePath} toggle={toggle} />;

  const renderView = () => {
    if (activePath === "card-transactions") {
      const canReadCardTransactions =
        can("card_transactions:others:read") || can("card_transactions:personal:read");

      if (!canReadCardTransactions) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      if (showCardsOnboarding) {
        return <CardsLander stripeAccount={stripeAccount} refreshData={getThirdPartyCards} />;
      } else {
        return <CardTransactionsTable shouldRedirectURLWhenOpening={true} />;
      }
    } else if (activePath === "card-programs") {
      const canReadCards = can("miter_cards:read") || can("third_party_cards:read");
      if (!canReadCards) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      if (id) {
        navigate(`/expenses/card-programs/${id}`);
      }
      return <CardProgramTable />;
    } else if (activePath === "reimbursements") {
      const canReadReimbursements = can("reimbursements:others:read") || can("reimbursements:personal:read");
      if (!canReadReimbursements) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }
      return <ExpenseReimbursements expenseReimbursementId={id} shouldRedirectURLWhenOpening={true} />;
    } else if (activePath === "bill-pay") {
      const canReadBillPay = hasAccessToBillPay && can("bill_pay:bills:read");
      if (!canReadBillPay) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      return <BillPay billId={id} />;
    } else if (activePath === "vendors") {
      const canReadBillPay = hasAccessToBillPay && can("bill_pay:bills:read");
      if (!canReadBillPay) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      return <Vendors vendorId={id} />;
    }
  };

  const getThirdPartyCards = async () => {
    if (!activeCompanyId) return;

    try {
      const filter = [{ field: "company_id", value: activeCompanyId }];
      const res = await MiterAPI.expenses.third_party_cards.list({ filter });
      if (res.error) throw new Error(res.error);

      setHasThirdPartyCards(res?.length > 0);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error(e.message);
    }
    setIsLoadingThirdPartyCards(false);
  };

  useEffect(() => {
    getThirdPartyCards();
  }, [activeCompanyId]);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Expenses | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Expenses</h1>
          <div className="flex-1"></div>
          {!isStripeAccountSetupComplete && can("miter_cards:create") ? (
            <Button
              className="button-2"
              text={
                activePath === "reimbursements"
                  ? "Get started with ACH reimbursements"
                  : "Get started with Miter cards"
              }
              onClick={() =>
                initializeStripeAccount({
                  company: activeCompanyId || undefined,
                  account: stripeAccount,
                  source:
                    activePath === "reimbursements"
                      ? "Get started with ACH reimbursements button"
                      : "Get started with Miter cards button",
                  setLoading: setLoadingStripe,
                })
              }
              loading={loadingStripe}
            />
          ) : (
            <></>
          )}
          {can("expenses:settings") && (
            <Button
              className="button-1"
              text="Policies and settings"
              onClick={() => navigate("/expenses/settings/policies")}
            />
          )}
        </div>
        {isLoadingThirdPartyCards ? (
          <Loader />
        ) : (
          <>
            {renderToggler()}
            {renderView()}
          </>
        )}
      </div>
    </div>
  );
};

export default Expenses;
