import React, { useEffect, useMemo, useState } from "react";
import editIcon from "dashboard/assets/edit.png";
import styles from "./PayRateGroupPage/PayRateGroupModal.module.css";
import { Breadcrumbs, Button, Toggler } from "ui";
import { ClassificationTable } from "./PayRateGroupPage/ClassificationTable";
import { MiterAPI, UnionRate, PayRateGroup, AggregatedPayRateGroup } from "dashboard/miter";
import { Notifier, sleep } from "dashboard/utils";
import { ClassificationTableEntry, unionRateSorter } from "./PayRateGroupPage/PayRateGroupModalUtils";
import { PayRateGroupFringes } from "./PayRateGroupPage/PayRateGroupFringes";
import FilesTable from "dashboard/components/tables/FilesTable";
import { useForm } from "react-hook-form";
import { TeamClassificationMapping } from "./PayRateGroupPage/TeamClassificationMapping";
import { PayRateGroupOvertime } from "./PayRateGroupPage/PayRateGroupOvertime";
import {
  useActiveCompanyId,
  useLookupPrg,
  useRefetchPrgs,
  useRefetchStandardClassifications,
} from "dashboard/hooks/atom-hooks";
import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { IS_DEVELOPMENT } from "dashboard/utils/environment";
import { UnionReciprocityRulesTable } from "./PayRateGroupPage/UnionReciprocityRulesTable";

export const PayRateGroupPage: React.FC = () => {
  const { id: prgId } = useParams<{ id: string }>();
  // CONTEXT
  const activeCompanyId = useActiveCompanyId();
  const lookupPrg = useLookupPrg();
  const refetchPrgs = useRefetchPrgs();
  const refetchStandardClassifications = useRefetchStandardClassifications();
  const { cannot } = useMiterAbilities();

  // STATE
  const payRateGroup = lookupPrg(prgId);
  const [editingLabel, setEditingLabel] = useState(false);
  const [reloaded, setReloaded] = useState(false);
  const [updatingGroup, setUpdatingGroup] = useState(false);
  const [saving, setSaving] = useState(false);
  const [toggleState, setToggleState] = useState("classifications");

  const cleanUnionRates = (payRateGroup?: AggregatedPayRateGroup): ClassificationTableEntry[] => {
    if (!payRateGroup) return [];
    return payRateGroup.union_rates.slice().sort(unionRateSorter);
  };
  const [tableData, setTableData] = useState<ClassificationTableEntry[]>(cleanUnionRates(payRateGroup));

  const [unsavedData, setUnsavedData] = useState(false);

  const readonly = cannot("lists:pay_rate_groups:manage");

  // EFFECTS
  useEffect(() => {
    if (!payRateGroup || !reloaded) return;
    const data = cleanUnionRates(payRateGroup);
    setTableData(data);
  }, [payRateGroup, reloaded]);

  // Get the latest data from the server when the page loads
  useEffect(() => {
    if (prgId) {
      refetchPrgs([prgId])
        .then(() => sleep(0))
        .then(() => setReloaded(true));
    }
  }, [prgId]);

  // OTHER HOOKS
  const form = useForm();

  // HELPER FUNCS
  const savePayRateTable = async () => {
    if (readonly) {
      Notifier.error("You do not have permission to create pay rate groups");
      return;
    }

    setSaving(true);

    const payloads: Partial<UnionRate>[] = [];
    let goodToSubmit = true;

    tableData.forEach((r, i) => {
      const { new: newRate, changed, ...rate } = r;
      rate.rowIndex = i;
      // No need to make any request for a rate that isn't new or changed
      if (!newRate && !changed) return;

      // _id we used for new rates in the frontend is just a placeholder to get the grid to work properly. Need to remove now
      const finalPayload = {
        ...rate,
        _id: newRate ? undefined : rate._id,
      };
      if (!finalPayload.classification || finalPayload.base_rate == null || finalPayload.base_rate < 0) {
        goodToSubmit = false;
      }
      // Clear out any $0 fringes
      finalPayload.fringes = finalPayload.fringes.filter((f) => !isNaN(f.amount));

      // Clean up fringe rates
      if (finalPayload.fringe_rates == null || Object.values(finalPayload.fringe_rates).every((v) => !v)) {
        finalPayload.fringe_rates = null;
      } else {
        const ot = finalPayload.fringe_rates?.ot;
        const dot = finalPayload.fringe_rates?.dot;
        const reg = finalPayload.fringe_rates?.reg ?? ot ?? dot ?? 0;
        finalPayload.fringe_rates = { reg, ot: ot ?? reg, dot: dot ?? reg };
      }

      payloads.push(finalPayload);
    });

    if (!goodToSubmit) {
      Notifier.error("Every classification must have a label and a non-negative base rate");
      setSaving(false);
      return;
    }

    const responses = await Promise.all(
      payloads.map(async (rate) => {
        const res = await (rate._id
          ? MiterAPI.union_rates.update(rate._id, rate)
          : MiterAPI.union_rates.create(rate));
        if (res.error) {
          console.error(`Error saving PRG ${prgId} classification ${rate.classification}:`, res.error);
        }
        return res;
      })
    );

    const fails = responses.filter((r) => r.error);
    if (fails.length) {
      if (fails.length === responses.length) {
        Notifier.error("There was an issue saving the pay rate group. We're looking into it!");
      } else {
        Notifier.warning("Some rates of this pay rate group failed to save. Please check your changes.");
      }
    } else {
      Notifier.success("Rates saved successfully.");
    }

    await refetchPrgs(prgId);
    refetchStandardClassifications();
    setUnsavedData(false);
    setSaving(false);
  };

  const updateGroup = async (update: Partial<PayRateGroup>) => {
    if (readonly) {
      Notifier.error("You do not have permission to edit pay rate groups");
      return;
    }

    if (!prgId) return;
    setUpdatingGroup(true);
    // Copy existing table data in user is editing table and then decides to change the label, so we don't lose the current state
    const existingTable = tableData.slice();
    try {
      const updatedAggPrg = { ...payRateGroup, ...update };
      const { union_rates, company, created_at, updated_at, _id, ...finalUpdate } = updatedAggPrg;
      const response = await MiterAPI.pay_rate_groups.update([prgId], finalUpdate);
      if (response.error) throw new Error(response.error);
      if (response.failed.length) throw new Error(response.failed[0]!.reason);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the pay rate group. We're looking into it!");
    }
    // Need to give the data time to refresh before resetting it, so setTimeout for 0 ms
    await refetchPrgs(prgId).then(() => sleep(0));
    setTableData(existingTable);
    setUpdatingGroup(false);
    setEditingLabel(false);
    Notifier.success("Pay rate group updated successfully.");
  };

  const togglerOptions = useMemo(() => {
    return [
      { label: "Classifications", path: "classifications" },
      { label: "Fringes", path: "fringes" },
      { label: "Team members", path: "team" },
      { label: "Overtime", path: "overtime" },
      { label: "Attachments", path: "attachments" },
      { label: "Reciprocity Rules", path: "reciprocity", hide: !IS_DEVELOPMENT },
    ];
  }, []);

  const handleOvertimeSettingsChange = async (update: Partial<UnionRate>) => {
    if (!payRateGroup) return;
    setSaving(true);
    // Copy existing table data in user is editing table and then decides to change the label, so we don't lose the current state
    const existingTable = tableData.slice();
    try {
      const unionRateIds = payRateGroup.union_rates.map((rate) => rate._id);
      const responses = await Promise.all(unionRateIds.map((id) => MiterAPI.union_rates.update(id, update)));
      for (const response of responses) {
        if (response.error) throw new Error(response.error);
      }
      for (const c of existingTable) {
        const response = responses.find((r) => r._id === c._id);
        if (!response) continue;
        c.overtime_rule_id = response.overtime_rule_id;
      }
      Notifier.success("Overtime settings updated successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the overtime rule.");
    }
    // Need to give the data time to refresh before resetting it, so setTimeout for 0 ms
    await refetchPrgs(prgId).then(() => sleep(0));
    setTableData(existingTable);
    setSaving(false);
  };

  if (!payRateGroup) {
    Notifier.error("Pay rate group not found");
    return <Navigate to="/settings/pay-rate-groups" />;
  }

  const renderTitle = () => {
    if (editingLabel) {
      return (
        <div className="flex width-100-percent">
          <input
            className={`${styles["label-input"]}`}
            ref={form.register()}
            name="label"
            defaultValue={payRateGroup.label}
            placeholder={"Add a name for the pay rate group"}
            style={{ marginRight: 10 }}
          />
          <Button
            text="Done"
            className="button-2"
            loading={updatingGroup}
            onClick={form.handleSubmit(updateGroup)}
          />
        </div>
      );
    } else {
      return (
        <div className="flex width-100-percent">
          <h1 style={{ margin: 0 }}>{payRateGroup.label}</h1>
          <img
            src={editIcon}
            style={{ marginLeft: 15, height: 10, cursor: "pointer" }}
            onClick={() => setEditingLabel(true)}
          />
        </div>
      );
    }
  };

  const renderHeader = () => {
    return (
      <div className={"page-content-header " + styles["header"]} style={{ marginTop: 5, width: "100%" }}>
        <Breadcrumbs
          crumbs={[
            { label: "Pay Rate Groups", path: "/settings/pay-rate-groups" },
            { label: payRateGroup.label, path: `/settings/pay-rate-groups/${prgId}` },
          ]}
        />
        <div className="flex width-100-percent">
          {renderTitle()}
          {unsavedData && (
            <Button
              disabled={!unsavedData}
              className="button-2 "
              text="Save"
              onClick={savePayRateTable}
              loading={saving}
              style={{ marginLeft: "auto" }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{payRateGroup.label} | Miter</title>
      </Helmet>
      <div className="page-content">
        {renderHeader()}
        <Toggler active={toggleState} toggle={setToggleState} config={togglerOptions} />
        {toggleState === "classifications" && (
          <ClassificationTable
            rateGroup={payRateGroup}
            setTableData={setTableData}
            hasUnsavedData={unsavedData}
            tableData={tableData}
            setUnsavedData={setUnsavedData}
            readonly={readonly}
          />
        )}
        {toggleState === "fringes" && (
          <PayRateGroupFringes
            setUnsavedData={setUnsavedData}
            setTableData={setTableData}
            tableData={tableData}
            readonly={readonly}
          />
        )}
        {toggleState === "team" && <TeamClassificationMapping prg={payRateGroup} readonly={readonly} />}
        {toggleState === "overtime" && (
          <PayRateGroupOvertime
            rateGroup={payRateGroup}
            updateGroup={updateGroup}
            handleChange={handleOvertimeSettingsChange}
            form={form}
            readonly={readonly}
          />
        )}
        {toggleState === "attachments" && (
          <>
            <FilesTable
              parentType="pay_rate_group"
              parentId={payRateGroup._id}
              defaultFilters={[
                { field: "company_id", value: activeCompanyId! },
                { field: "parent_id", value: payRateGroup._id },
                { field: "parent_type", value: "pay_rate_group" },
              ]}
              readonly={readonly}
            />
          </>
        )}
        {toggleState === "reciprocity" && (
          <>
            <UnionReciprocityRulesTable readonly={readonly} payRateGroup={payRateGroup} />
          </>
        )}
      </div>
    </div>
  );
};
