import React, { useEffect, useMemo, useState } from "react";

import { Badge, FileViewer, ModalHeader, Notifier, SliderModal } from "ui";
import {
  EXPENSE_REIMBURSEMENT_EDIT_MODE,
  ExpenseReimbursementModalForm,
  ExpenseReimbursementModalFormData,
} from "./ExpenseReimbursementModalForm";
import { useForm } from "react-hook-form";
import { FileUploadParams, updateFiles } from "miter-utils";
import {
  ACHFailureReason,
  TaxableOptions,
  cleanExpenseReimbursementFormData,
  getACHFailureReasons,
  isPlaidAccountVerified,
  isPlaidBankAccount,
  useReimbursementPayoutMethodOptions,
  useValidateTeamMemberHasReimbursementPayoutMethod,
} from "../expenseUtils";
import {
  useActiveCompany,
  useIsSuperAdmin,
  useLookupExpenseReimbursementCategories,
} from "dashboard/hooks/atom-hooks";
import { AggregatedExpenseReimbursement, BankAccount, MiterAPI, PlaidBankAccount } from "dashboard/miter";
import { DateTime } from "luxon";
import { EditExpenseReimbursementModalFooter } from "./EditExpenseReimbursementModalFooter";
import { ExpenseReimbursementStatus, MileageDetail } from "backend/models/expense-reimbursement";
import { ACHReimbursementBanner } from "./ACHReimbursementBanner";
import { NeedsAttentionBanner } from "dashboard/components/policies/NeedsAttentionBanner";
import { ApprovalTimeline } from "dashboard/components/approvals/ApprovalTimeline";
import { ApprovalItemPolicyData } from "dashboard/components/approvals/ApprovalItemPolicyData";
import { LeftAlignedModalFormToggler } from "ui/modal/ModalFormToggler";
import { AuditLogHistory } from "dashboard/components/audit-logs/AuditLogHistory";
import { ExpenseReimbursementPerDiemInfo } from "./ExpenseReimbursementPerDiemInfo";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type Props = {
  expenseReimbursementId: string;
  onHide: () => void; // hide modal
  onSubmit: (tmId: string) => void;
  inboxMode?: InboxMode;
};

const getEditModeFromStatus = (
  status?: ExpenseReimbursementStatus
): EXPENSE_REIMBURSEMENT_EDIT_MODE | undefined => {
  if (!status) return;

  if (status === "unapproved") return "all_fields";
  else if (status === "approved") return "job_costing_and_payout";
  else return "job_costing_only";
};

export const EditExpenseReimbursementModal: React.FC<Props> = ({
  expenseReimbursementId,
  onHide,
  onSubmit,
  inboxMode,
}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompany = useActiveCompany();
  const lookupReimbursementCategory = useLookupExpenseReimbursementCategories();
  const isUserSuperAdmin = useIsSuperAdmin();
  const payoutMethodOptions = useReimbursementPayoutMethodOptions();
  const validateTeamMemberHasPayoutMethod = useValidateTeamMemberHasReimbursementPayoutMethod();
  /*********************************************************
   *  Initialize state
   **********************************************************/
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<EXPENSE_REIMBURSEMENT_EDIT_MODE>(); // toggle edit mode
  const [expenseReimbursement, setExpenseReimbursement] = useState<AggregatedExpenseReimbursement>();
  const [refreshCounter, setRefreshCounter] = useState<number>(0);

  // form toggle
  const [activeTab, setActiveTab] = useState<"Overview" | "Approvals" | "Per diem" | "Audit log">("Overview");

  // ACH reimbursement state
  const [teamMemberBankAccount, setTeamMemberBankAccount] = useState<BankAccount>();
  const [companyBankAccount, setCompanyBankAccount] = useState<PlaidBankAccount>();
  const [achFailureReason, setACHFailureReason] = useState<ACHFailureReason>();

  const isApprovedAndIsACHPayout =
    expenseReimbursement?.payout_method === "ach" && expenseReimbursement?.status === "approved";

  const canSubmitForACHReimbursement =
    isApprovedAndIsACHPayout &&
    companyBankAccount != null &&
    teamMemberBankAccount != null &&
    (isPlaidBankAccount(teamMemberBankAccount)
      ? isPlaidAccountVerified(teamMemberBankAccount.external_raw_data)
      : true);

  const getBankAccounts = async (reimbursement: AggregatedExpenseReimbursement) => {
    if (!activeCompany) return;

    const teamMemberBankAccountIdForReimbursement =
      reimbursement?.team_member.bank_account_settings?.ach_reimbursements_bank_account_id;

    try {
      const [teamMemberBankAccount, companyBankAccountToUseForReimbursements] = await Promise.all([
        teamMemberBankAccountIdForReimbursement
          ? MiterAPI.bank_accounts.forage({
              filter: [
                {
                  field: "company_id",
                  value: activeCompany._id,
                },
                {
                  field: "external_financial_account_type",
                  value: "team_member",
                },
                {
                  field: "team_member_id",
                  value: reimbursement.team_member_id,
                },
                {
                  field: "_id",
                  value: teamMemberBankAccountIdForReimbursement,
                },
              ],
            })
          : Promise.resolve(undefined),
        MiterAPI.bank_accounts.forage({
          filter: [
            {
              field: "company_id",
              value: activeCompany._id,
            },
            {
              field: "external_financial_account_type",
              value: "company",
            },
            {
              field: "_id",
              value: activeCompany.settings.bank_accounts?.ach_reimbursements_bank_account_id,
            },
          ],
        }),
      ]);

      if (!companyBankAccountToUseForReimbursements.data.length) {
        throw new Error(`Could not get bank accounts for company ${activeCompany._id}.`);
      }

      const companyBankAccount = companyBankAccountToUseForReimbursements.data[0] as PlaidBankAccount;

      // confirm that this company bank account is verified
      if (isPlaidAccountVerified(companyBankAccount.external_raw_data)) {
        setCompanyBankAccount(companyBankAccount);
      }

      if (!teamMemberBankAccount?.data.length) {
        throw new Error(`Could not get bank account for team member ${reimbursement.team_member_id}.`);
      }
      setTeamMemberBankAccount(teamMemberBankAccount.data[0]);
    } catch (e: $TSFixMe) {
      // don't Notifier error here - errors will be exposed through ACHReimbursementBanner
    }
  };

  /*********************************************************
   *  useEffects
   **********************************************************/
  const getExpenseReimbursementData = async () => {
    setIsLoading(true);
    try {
      const res = await MiterAPI.expense_reimbursements.forage({
        filter: [
          { field: "company_id", value: activeCompany?._id },
          { field: "_id", type: "_id", value: expenseReimbursementId },
        ],
      });

      if (res.data.length === 0) {
        // expense is deleted
        Notifier.error(`Reimbursement ${expenseReimbursementId} has been deleted.`);
        onHide();
      } else {
        const reimbursementFromResponse = res.data[0];
        if (
          reimbursementFromResponse?.payout_method === "ach" &&
          reimbursementFromResponse?.status === "approved"
        ) {
          await getBankAccounts(reimbursementFromResponse);
        }
        if (
          reimbursementFromResponse?.payout_method === "ach" &&
          reimbursementFromResponse?.status === "failed"
        ) {
          const achFailureReason = await getACHFailureReasons(reimbursementFromResponse);
          setACHFailureReason(achFailureReason);
        }

        setExpenseReimbursement(reimbursementFromResponse);
        form.reset(buildDefaults(reimbursementFromResponse));
      }
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error(e.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getExpenseReimbursementData();
  }, [expenseReimbursementId, refreshCounter]);

  /*********************************************************
   *  Initialize form
   **********************************************************/

  const buildDefaults = (aggregatedReimbursement: AggregatedExpenseReimbursement | undefined) => {
    if (!aggregatedReimbursement) return {};

    const {
      expense_reimbursement_category,
      date,
      amount,
      vendor_name,
      memo,
      team_member,
      department,
      job,
      activity,
      cost_type,
      ledger_account,
      payout_method,
      is_taxable,
      attachments,
      mileage_detail,
    } = aggregatedReimbursement;

    const cleanedAttachments = attachments?.map((receipt) => ({ data: receipt }));
    const defaults = {
      expense_reimbursement_category_id: expense_reimbursement_category
        ? {
            label: expense_reimbursement_category?.name,
            value: expense_reimbursement_category?._id,
          }
        : undefined,
      date: date ? DateTime.fromISO(date) : undefined,
      amount: amount,
      vendor_name,
      memo,
      mileage: mileage_detail?.mileage,
      trip: mileage_detail?.trip,
      team_member_id: {
        label: team_member?.full_name,
        value: team_member?._id,
      },
      department_id: department
        ? {
            label: department?.name,
            value: department?._id,
          }
        : undefined,
      job_id: job
        ? {
            label: job?.name,
            value: job?._id,
          }
        : undefined,
      activity_id: activity
        ? {
            label: activity?.label,
            value: activity?._id,
          }
        : undefined,
      cost_type_id: cost_type
        ? {
            label: cost_type?.label,
            value: cost_type?._id,
          }
        : undefined,
      expense_account_id: ledger_account
        ? {
            label: ledger_account?.label,
            value: ledger_account?._id,
          }
        : undefined,
      payout_method: payoutMethodOptions.find((option) => option.value === payout_method),
      is_taxable: TaxableOptions.find((option) => option.value === is_taxable),
      attachments: cleanedAttachments,
    };

    return defaults;
  };

  const form = useForm<ExpenseReimbursementModalFormData>({
    defaultValues: buildDefaults(expenseReimbursement),
    shouldUnregister: false,
  });
  const { handleSubmit } = form;

  const attachments = form.watch("attachments");

  const formAttachments = useMemo(() => {
    return attachments?.filter((file) => !file.deleted);
  }, [expenseReimbursement, attachments]);

  /*********************************************************
   *  Handlers
   **********************************************************/
  const editExpenseReimbursement = async (data: ExpenseReimbursementModalFormData) => {
    if (!activeCompany || !expenseReimbursement) return;

    setIsLoading(true);
    setIsSaving(true);
    try {
      // validate payout method
      if (validateTeamMemberHasPayoutMethod(data.team_member_id.value, data.payout_method?.value) === false) {
        if (data.payout_method?.value === "payroll") {
          throw new Error("This team member is not enrolled in payroll.");
        } else if (data.payout_method?.value === "ach") {
          throw new Error(
            "Your company has not set up direct ACH reimbursements yet. Contact Miter Support."
          );
        }
      }

      // for some reason data.mileage will be undefined if the FormBlock is disabled.
      let mileageDetail: MileageDetail | undefined;
      if (data.mileage) {
        mileageDetail = {
          mileage: data.mileage,
          trip: data.trip,
        };
      }

      const cleanData = cleanExpenseReimbursementFormData({
        formData: data,
        activeCompany,
        mileageDetail,
        selectedCategory: lookupReimbursementCategory(data.expense_reimbursement_category_id?.value),
        isCreation: false,
      });
      const res = await MiterAPI.expense_reimbursements.update([
        { id: expenseReimbursementId, update: cleanData },
      ]);

      if (res.error) throw new Error(res.error);

      const params: FileUploadParams = {
        parent_id: expenseReimbursementId,
        parent_type: "expensereimbursement",
        company_id: activeCompany._id,
      };

      await updateFiles(data.attachments, params);
      setRefreshCounter((prev) => prev + 1); // we do get the updated expense from the update POST. However, refreshing the modal also refreshes the policy, ACH bank accounts etc - lots of other side effects.
      setEditMode(undefined);
      onSubmit(expenseReimbursement.team_member_id);
      Notifier.success("Saved changes.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setIsLoading(false);
    setIsSaving(false);
  };

  /*********************************************************
   *  Render
   **********************************************************/
  const title = (
    <div className="flex" style={{ justifyContent: "left" }}>
      <>View reimbursement</>
      <div className="horizontal-spacer" />
      <Badge className="table-v2-badge" text={expenseReimbursement?.status} />
    </div>
  );

  const tabsToShow = useMemo(() => {
    const tabs = ["Overview"];
    if (expenseReimbursement?.approval_history?.length || expenseReimbursement?.approval_stage) {
      tabs.push("Approvals");
    }

    if (expenseReimbursement?.per_diem_rate_id && expenseReimbursement.timesheet_ids?.length) {
      tabs.push("Per diem");
    }

    if (isUserSuperAdmin) {
      tabs.push("Audit log");
    }

    return tabs;
  }, [isUserSuperAdmin, expenseReimbursement]);

  const toggler = (
    <LeftAlignedModalFormToggler
      tabs={tabsToShow}
      activeTab={activeTab}
      onToggle={(t) => {
        setActiveTab(t);
      }}
    />
  );

  const header = (
    <ModalHeader heading={title} onHide={onHide} className={toggler ? "remove-border-bottom" : undefined} />
  );

  return (
    <SliderModal
      header={header}
      toggler={toggler}
      onCancel={onHide}
      animate={true}
      shouldOnclickAwayClose={true}
      footer={
        expenseReimbursement && (
          <EditExpenseReimbursementModalFooter
            expenseReimbursement={expenseReimbursement}
            editing={editMode != null}
            isSaving={isSaving}
            onSave={handleSubmit(editExpenseReimbursement)}
            onApprovalChange={() => {
              onSubmit(expenseReimbursement!.team_member_id); // refresh table
              setRefreshCounter((prev) => prev + 1); // refresh modal
            }}
            toggleEdit={() => {
              if (editMode) setEditMode(undefined);
              else setEditMode(getEditModeFromStatus(expenseReimbursement?.status));
            }}
            canSubmitForACHReimbursement={canSubmitForACHReimbursement}
            onHide={onHide}
            onCancelEdit={() => {
              form.reset(buildDefaults(expenseReimbursement)); // this unsaves any changes made while in edit mode
            }}
            inboxMode={inboxMode}
          />
        )
      }
      loading={isLoading}
      leftElement={
        // empty array is truthy - explicity check if attachments exists
        formAttachments?.length && formAttachments.length > 0 ? (
          <div
            style={{
              width: "400px",
              padding: "30px",
              backgroundColor: "white",
              borderRight: "1px solid #d6d6d6",
            }}
          >
            <FileViewer files={formAttachments || []} mode="read" />
          </div>
        ) : undefined
      }
    >
      <div style={{ width: "400px" }}>
        {activeTab === "Overview" ? (
          <>
            <ACHReimbursementBanner
              canSubmitForReimbursement={canSubmitForACHReimbursement}
              expenseReimbursementRequest={expenseReimbursement}
              companyBankAccount={companyBankAccount}
              teamMemberBankAccount={teamMemberBankAccount}
              achFailureReason={achFailureReason}
              loading={isLoading}
              bannerStyle={{ marginBottom: 15 }}
            />
            {/* it shouldn't really be possible to create a reimbursement that doesn't meet policy unless the policy is updated later. */}
            <NeedsAttentionBanner
              item={expenseReimbursement}
              policyType={"expense_reimbursement"}
              style={{ marginBottom: 15 }}
            />
            <ExpenseReimbursementModalForm
              editingMode={editMode}
              form={form}
              mileageTrip={expenseReimbursement?.mileage_detail?.trip}
              cachedMileageRate={expenseReimbursement?.mileage_detail?.rate_snapshot}
              inboxMode={inboxMode}
            />
          </>
        ) : activeTab === "Approvals" ? (
          <>
            <ApprovalTimeline item={expenseReimbursement!} />
            <div style={{ marginTop: "65px", marginBottom: "20px", fontWeight: "bold" }}>Approval Policy</div>
            <ApprovalItemPolicyData item={expenseReimbursement!} />
          </>
        ) : activeTab === "Per diem" ? (
          <ExpenseReimbursementPerDiemInfo reimbursement={expenseReimbursement!} />
        ) : (
          <AuditLogHistory
            itemId={expenseReimbursementId}
            type={"expense_reimbursement"}
            refreshCounter={refreshCounter}
          />
        )}
      </div>
    </SliderModal>
  );
};
