/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLookupCompanyUsers, useUser } from "dashboard/hooks/atom-hooks";
import { AggregatedForm, FormSubmission, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { DateTime } from "luxon";
import { Envelope, PaperPlaneTilt, Pencil, Plus, Trash, TrashSimple } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionModal, BasicModal } from "ui";
import { ColumnConfig, TableActionLink, TableDropdownCellRenderer, TableV2 } from "ui/table-v2/Table";
import BulkTeamMemberSelect, { BulkTeamMemberSelectTeamMember } from "../team-members/BulkTeamMemberSelect";
import FormSubmissionAnswersTable, { ESignatureAnswerModal } from "./FormSubmissionAnswersTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useFormSubmissionAbilities } from "dashboard/hooks/abilities-hooks/useFormSubmissionAbilities";
import { FormAnswer, buildCompanyUserName } from "miter-utils";
import { useFormColumns } from "dashboard/hooks/table/useFormColumns";

export type FormSubmissionTableRow = {};

type Props = {
  form: AggregatedForm;
  getForm: () => Promise<void>;
};

const FormSubmissionTable: React.FC<Props> = ({ form, getForm }) => {
  const activeUser = useUser();
  const lookupCompanyUser = useLookupCompanyUsers();
  const navigate = useNavigate();
  const miterAbilities = useMiterAbilities();
  const submissionAbilities = useFormSubmissionAbilities();

  // States related to the table
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<FormSubmission[]>([]);
  const [activeFormSubmission, setActiveFormSubmission] = useState<FormSubmission>();

  // States related to table actions
  const [archiving, setArchiving] = useState<boolean>(false);
  const [requestingSubmissions, setRequestingSubmissions] = useState<boolean>(false);
  const [sendingSubmissionRequests, setSendingSubmissionRequests] = useState<boolean>(false);
  const [selectedAnswer, setSelectedAnswer] = useState<FormAnswer>();

  const { formColumns } = useFormColumns({
    form,
    setSelectedAnswer: setSelectedAnswer as React.Dispatch<React.SetStateAction<FormAnswer>>,
  });

  const formSubmissions = useMemo(
    () => form.submissions.filter((s) => submissionAbilities.can("read", s)),
    [form]
  );

  const handleRowClick = (formSubmission) => {
    if (submissionAbilities.cannot("read", formSubmission)) {
      Notifier.error("You do not have permission to view this submission.");
      return;
    }

    setActiveFormSubmission(formSubmission);
  };

  const handleAdd = () => {
    if (!activeUser) return;

    // Check if the form has multiple submissions on
    if (!form.allow_multiple_submissions) {
      const hasSubmission = formSubmissions.some((submission) => submission.user_id === activeUser._id);
      if (hasSubmission) {
        Notifier.error("You have already have a submission for this form.");
        return;
      }
    }

    navigate("/forms/" + form._id + "/submissions/new");
  };

  const handleEdit = (formSubmission) => {
    if (!form.allow_editable_submissions) {
      Notifier.error("This form does not allow editing of submissions.");
      return;
    }

    if (submissionAbilities.cannot("update", formSubmission)) {
      Notifier.error("You do not have permission to edit this submission.");
      return;
    }

    navigate("/forms/" + form._id + "/submissions/" + formSubmission._id);
  };

  const handleArchive = async (formSubmission?: FormSubmission) => {
    if (formSubmission && submissionAbilities.cannot("delete", formSubmission)) {
      Notifier.error("You do not have permission to delete this submission.");
      return;
    } else if (!formSubmission && submissionAbilities.cannot("delete", selectedRows)) {
      Notifier.error("You do not have permission to delete one or more of these submissions.");
      return;
    }

    setLoading(true);
    try {
      const archivingIds = formSubmission ? [formSubmission._id] : selectedRows.map((form) => form._id);
      for (const id of archivingIds) {
        const response = await MiterAPI.form_submissions.delete(id);
        if (response.error) throw new Error(response.error);
      }

      const singPlur = selectedRows.length > 1 ? "Form submissions" : "Form submission";
      Notifier.success(singPlur + " successfully deleted.");

      await getForm();
      setArchiving(false);
      setSelectedRows([]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting one or more form. We're looking into it.");
    }
    setLoading(false);
  };

  const handleSendReminder = async (formSubmission?: FormSubmission) => {
    setLoading(true);
    try {
      const sendingIds = formSubmission ? [formSubmission._id] : selectedRows.map((form) => form._id);
      for (const _id of sendingIds) {
        const response = await MiterAPI.form_submissions.send_reminders([_id]);
        if (response.error || response.failures.length > 0) {
          throw new Error(response.error || response.failures[0]?.message);
        }
      }

      const singPlur = selectedRows.length > 1 ? "Reminders" : "Reminder";
      Notifier.success(singPlur + " successfully sent.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error sending one or more reminders. We're looking into it.");
    }
    setLoading(false);
  };

  const handleSendSubmissionRequests = async (teamMembers: BulkTeamMemberSelectTeamMember[]) => {
    if (miterAbilities.cannot("forms:submissions:others:create")) {
      Notifier.error("You do not have permission to request submissions from other team members.");
      return;
    }

    setSendingSubmissionRequests(true);
    try {
      const res = await MiterAPI.forms.send_submission_requests({
        team_member_ids: teamMembers.map((tm) => tm._id),
        form_ids: [form._id],
      });

      if (res.error) throw new Error(res.error);
      await getForm();

      Notifier.success("Submission requests successfully sent.");
      setRequestingSubmissions(false);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error sending one or more submission requestss. We're looking into it.");
    }

    setSendingSubmissionRequests(false);
  };

  const getUserName = (formSubmission: FormSubmission) => {
    const user = lookupCompanyUser(formSubmission.user_id);
    return buildCompanyUserName(user);
  };

  /*********************************************************
    Helper functions
  **********************************************************/
  const buildActionsCell = (formSubmission: FormSubmission) => {
    return [
      {
        label: "Send reminder",
        action: () => handleSendReminder(formSubmission),
        icon: <Envelope weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => submissionAbilities.can("update", formSubmission) && !formSubmission.started_at,
      },
      {
        label: "Edit",
        action: () => handleEdit(formSubmission),
        icon: <Pencil weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () =>
          submissionAbilities.can("update", formSubmission) && !!form.allow_editable_submissions,
      },
      {
        label: "Delete",
        action: () => handleArchive(formSubmission),
        icon: <Trash weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => submissionAbilities.can("delete", formSubmission),
      },
    ];
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Create a submission",
        className: "button-1 no-margin",
        action: handleAdd,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () =>
          miterAbilities.can("forms:submissions:personal:create") ||
          miterAbilities.can("forms:submissions:others:create"),
      },
      {
        label: "Request a submission",
        className: "button-2 ",
        action: () => setRequestingSubmissions(true),
        important: true,
        icon: <PaperPlaneTilt weight="fill" style={{ marginRight: 3 }} />,
        shouldShow: () => miterAbilities.can("forms:submissions:others:create"),
      },
    ],
    [miterAbilities.can, setRequestingSubmissions]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => submissionAbilities.can("delete", selectedRows),
      },
    ],
    [submissionAbilities.can]
  );

  // Dynamically set columns based on whether any form have work classifications
  const columns: ColumnConfig<FormSubmission>[] = useMemo(() => {
    const baseColumns: ColumnConfig<FormSubmission>[] = [
      {
        headerName: "Team Member / Role",
        field: "team_member_or_role",
        dataType: "string" as const,
        valueGetter: (params) => {
          const fullName = params.data ? getUserName(params.data) : null;
          return fullName || "-";
        },
        pinned: "left" as const,
      },
      {
        headerName: "Status",
        field: "status",
        dataType: "string" as const,
        displayType: "badge" as const,
        pinned: "left" as const,
      },
      {
        headerName: "Completed at",
        field: "completed_at",
        dataType: "date" as const,
        dateType: "timestamp" as const,
        dateFormat: "ff",
        pinned: "left" as const,
        minWidth: 200,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - TS doesn't like the fact that we're using a custom displayType
    ].concat(formColumns);

    return baseColumns.concat([
      {
        field: "actions",
        headerName: "Actions",
        dataType: "component",
        disableCellClick: true,
        pinned: "right",
        cellRenderer: (params) => {
          const actions = buildActionsCell(params.data).filter((a) => a.shouldShow());
          if (!actions.length) return;

          return <TableDropdownCellRenderer options={buildActionsCell(params.data)} />;
        },
      },
    ]);
  }, [form, formColumns]);

  /*********************************************************
    Functions to render table components
  **********************************************************/
  const renderTable = () => {
    return (
      <TableV2
        id={"form-submissions-table"}
        resource="form submissions"
        data={formSubmissions}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
      />
    );
  };

  const renderFormSubmissionModal = () => {
    if (!activeFormSubmission) return;

    const name = getUserName(activeFormSubmission);
    const submissionDate = activeFormSubmission.completed_at
      ? DateTime.fromSeconds(activeFormSubmission.completed_at).toFormat("ff")
      : "-";

    return (
      <ActionModal
        headerText={`Submission for ${name} on ${submissionDate}`}
        showCancel={true}
        cancelText="Close"
        onCancel={() => setActiveFormSubmission(undefined)}
        onHide={() => setActiveFormSubmission(undefined)}
        wrapperStyle={{ width: "80%" }}
      >
        <FormSubmissionAnswersTable form={form} formSubmission={activeFormSubmission} />
      </ActionModal>
    );
  };

  const renderSubmissionRequestsModal = () => {
    return (
      <BulkTeamMemberSelect
        title={`Request submissions`}
        onHide={() => setRequestingSubmissions(false)}
        onSubmit={handleSendSubmissionRequests}
        submitting={sendingSubmissionRequests}
        defaultTeamMembers={[]}
        predicate={(tm) => {
          // Filter out team members that have a submission request OR if they have a submission and the form only allows one submission OR don't have permission to create a submission
          return (
            submissionAbilities.teamPredicate("create")(tm) &&
            !formSubmissions.some(
              (submission) =>
                submission.team_member_id === tm._id &&
                (submission.status === "requested" || !form.allow_multiple_submissions)
            )
          );
        }}
      />
    );
  };

  return (
    <div className="form-table-wrapper">
      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={() => handleArchive()}
          headerText={"Delete form submission" + (selectedRows.length > 1 ? "s" : "")}
          bodyText={
            "Are you sure you want to delete the selected form submission" +
            (selectedRows.length > 1 ? "s" : "") +
            "? To restore deleted submission(s), you must contact Miter Support."
          }
        />
      )}
      {selectedAnswer && (
        <ESignatureAnswerModal answer={selectedAnswer} onHide={() => setSelectedAnswer(undefined)} />
      )}
      {activeFormSubmission && renderFormSubmissionModal()}
      {requestingSubmissions && renderSubmissionRequestsModal()}
      {renderTable()}
    </div>
  );
};

export default FormSubmissionTable;
