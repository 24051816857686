import React from "react";
import "./modal.css";
import { IoIosClose } from "react-icons/io";
import { useDisableBackgroundScroll } from "../hooks/useDisableBackgroundScroll";
import { Badge } from "..";

type Props = {
  headerText: React.ReactNode;
  onClose: () => void;
  children: React.ReactNode;
  beta?: boolean;
};

export const LargeModal: React.FC<Props> = ({ headerText, children, onClose, beta }) => {
  useDisableBackgroundScroll();

  return (
    <div className="modal-background">
      <div className={"large-modal-wrapper modal-wrapper"}>
        <div className={"header-buttons"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h2>{headerText}</h2>
            {beta && (
              <Badge style={{ marginLeft: 3, marginRight: 0, marginBottom: -1 }} text="Beta" color="green" />
            )}
          </div>
          <button className={"close-button"} onClick={onClose}>
            <IoIosClose />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};
