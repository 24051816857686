import { capitalize, keyBy, map, uniq } from "lodash";
import { useMemo } from "react";
import { useActiveTeam, useCrewOptions, useDepartmentOptions, useTeamOptions } from "./atom-hooks";
import { notNullish } from "miter-utils";
import { Option } from "ui/form/Input";
import { ApproverGroup, TeamMemberGroup, TeamMemberGroupType } from "dashboard/miter";
import { ApproverGroupType } from "backend/services/approvals/types";

export type SelectedTeamMemberGroups = {
  departments?: (Option<string> | string)[];
  crews?: (Option<string> | string)[];
  teamMembers?: (Option<string> | string)[];
  titles?: (Option<string> | string)[];
  employmentTypes?: (Option<string> | string)[];
  payTypes?: (Option<string> | string)[];
  allTeamMembers?: boolean;
  jobSupervisor?: boolean;
  jobSuperintendent?: boolean;
  directManager?: boolean;
  crewLead?: boolean;
  departmentHead?: boolean;
  self?: boolean;
};

type TeamMemberGroupOptions = {
  departmentOptions: Option<string>[];
  crewOptions: Option<string>[];
  teamMemberOptions: Option<string>[];
  titleOptions: Option<string>[];
  employmentTypeOptions: Option<string>[];
  payTypeOptions: Option<string>[];
};

export type useTeamMemberGroups = {
  options: TeamMemberGroupOptions;
  getOptions: (type: GroupType | undefined) => Option<string>[];
  groupOptions: Option<GroupType>[];
};

type GroupType = ApproverGroup["type"] | TeamMemberGroup["type"];

/**
 * A hook that provides team member group options and selection management.
 *
 * @param baseSelections - An optional object of type SelectedTeamMemberGroups containing initial selections.
 *                         This object can include arrays of pre-selected departments, crews, team members,
 *                         titles, employment types, pay types, and boolean flags for specific roles.
 *
 * @param labelOverrides - An optional object that allows customization of labels for different group types.
 *                         Keys should be group type identifiers (e.g., 'direct_manager', 'department'),
 *                         and values should be the desired custom labels.
 *
 * @returns An object containing options for various group types, a function to get options for a specific
 *          group type, and an array of available group options.
 */
export const useTeamMemberGroups = (
  baseSelections?: SelectedTeamMemberGroups,
  labelOverrides?: Record<string, string>
): useTeamMemberGroups => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeTeam = useActiveTeam();
  const baseDepartmentOptions = useDepartmentOptions();
  const baseTeamMemberOptions = useTeamOptions();
  const baseCrewOptions = useCrewOptions();

  /**********************************************************************************************************
   * Map for looking up if item is already selected
   **********************************************************************************************************/
  const buildLookupMap = (items: (Option<string> | string)[]) => {
    return items.reduce((acc, item) => {
      if (typeof item === "string") {
        acc[item] = true;
      } else {
        acc[item.value] = true;
      }
      return acc;
    }, {} as Record<string, boolean>);
  };

  const selections = useMemo(() => {
    const departments = buildLookupMap(baseSelections?.departments || []);
    const crews = buildLookupMap(baseSelections?.crews || []);
    const teamMembers = buildLookupMap(baseSelections?.teamMembers || []);
    const titles = buildLookupMap(baseSelections?.titles || []);
    const employmentTypes = buildLookupMap(baseSelections?.employmentTypes || []);
    const payTypes = buildLookupMap(baseSelections?.payTypes || []);

    return { departments, crews, teamMembers, titles, employmentTypes, payTypes };
  }, [baseSelections]);

  /**********************************************************************************************************
   * Options arrays
   **********************************************************************************************************/
  const departmentOptions = useMemo(() => {
    return baseDepartmentOptions.filter((department) => !selections.departments[department.value]);
  }, [baseDepartmentOptions, selections.departments]);

  const teamMemberOptions = useMemo(() => {
    return baseTeamMemberOptions.filter((teamMember) => !selections.teamMembers[teamMember.value]);
  }, [baseTeamMemberOptions, selections.teamMembers]);

  const crewOptions = useMemo(() => {
    return baseCrewOptions.filter((crew) => !selections.crews[crew.value]);
  }, [baseCrewOptions, selections.crews]);

  const payTypeOptions = useMemo(() => {
    return uniq(map(activeTeam, "pay_type"))
      .filter(notNullish)
      .filter((payType) => !selections.payTypes[payType])
      .map((payType) => ({ label: capitalize(payType), value: payType }));
  }, [activeTeam, selections.payTypes]);

  const employmentTypeOptions = useMemo(() => {
    return uniq(map(activeTeam, "employment_type"))
      .filter(notNullish)
      .filter((employmentType) => !selections.employmentTypes[employmentType])
      .map((employmentType) => ({ label: capitalize(employmentType), value: employmentType }));
  }, [activeTeam, selections.employmentTypes]);

  const titleOptions = useMemo(() => {
    return uniq(map(activeTeam, "title"))
      .filter(notNullish)
      .filter((title) => !selections.titles[title])
      .map((title) => ({ label: capitalize(title), value: title }));
  }, [activeTeam, selections.titles]);

  const groupOptions = useMemo(() => {
    const groups: Option<GroupType>[] = [];

    if (!baseSelections?.directManager)
      groups.push({ label: labelOverrides?.["direct_manager"] || "Direct Manager", value: "direct_manager" });
    if (!baseSelections?.jobSupervisor)
      groups.push({ label: labelOverrides?.["job_supervisor"] || "Job Supervisor", value: "job_supervisor" });
    if (!baseSelections?.jobSuperintendent)
      groups.push({
        label: labelOverrides?.["job_superintendent"] || "Job Superintendent",
        value: "job_superintendent",
      });
    if (!baseSelections?.crewLead)
      groups.push({ label: labelOverrides?.["crew_lead"] || "Crew Lead", value: "crew_lead" });
    if (!baseSelections?.departmentHead)
      groups.push({
        label: labelOverrides?.["department_head"] || "Department Head",
        value: "department_head",
      });
    if (!baseSelections?.self) groups.push({ label: labelOverrides?.["self"] || "Submitter", value: "self" });

    if (departmentOptions.length)
      groups.push({ label: labelOverrides?.["department"] || "Department", value: "department" });
    if (teamMemberOptions.length)
      groups.push({ label: labelOverrides?.["team_member"] || "Team Member", value: "team_member" });
    if (crewOptions.length) groups.push({ label: labelOverrides?.["crew"] || "Crew", value: "crew" });
    if (payTypeOptions.length)
      groups.push({ label: labelOverrides?.["pay_type"] || "Pay Type", value: "pay_type" });
    if (employmentTypeOptions.length)
      groups.push({
        label: labelOverrides?.["employment_type"] || "Employment Type",
        value: "employment_type",
      });
    if (titleOptions.length) groups.push({ label: labelOverrides?.["title"] || "Title", value: "title" });
    if (!baseSelections?.allTeamMembers)
      groups.push({
        label: labelOverrides?.["all_team_members"] || "All Team Members",
        value: "all_team_members",
      });

    return groups;
  }, [
    departmentOptions.length,
    teamMemberOptions.length,
    crewOptions.length,
    payTypeOptions.length,
    employmentTypeOptions.length,
    titleOptions.length,
    baseSelections?.allTeamMembers,
    baseSelections?.jobSupervisor,
    baseSelections?.jobSuperintendent,
    baseSelections?.directManager,
    baseSelections?.crewLead,
    baseSelections?.departmentHead,
    baseSelections?.self,
    labelOverrides,
  ]);

  /**********************************************************************************************************
   * Get the options
   **********************************************************************************************************/
  return useMemo(
    () => ({
      options: {
        departmentOptions,
        teamMemberOptions,
        crewOptions,
        payTypeOptions,
        employmentTypeOptions,
        titleOptions,
      },
      getOptions: (type: GroupType | undefined) => {
        switch (type) {
          case "department":
            return departmentOptions;
          case "crew":
            return crewOptions;
          case "team_member":
            return teamMemberOptions;
          case "title":
            return titleOptions;
          case "employment_type":
            return employmentTypeOptions;
          case "pay_type":
            return payTypeOptions;
        }

        return [];
      },
      groupOptions,
    }),
    [
      departmentOptions,
      teamMemberOptions,
      crewOptions,
      payTypeOptions,
      employmentTypeOptions,
      titleOptions,
      groupOptions,
    ]
  );
};

export const GroupTypeHasOptions = (type: ApproverGroupType | TeamMemberGroupType | undefined): boolean => {
  if (!type) return false;

  const options = { department: true, team_member: true, pay_type: true, employment_type: true, title: true };
  return options[type] === true;
};

export const GroupTypeOptions: Option<ApproverGroupType | TeamMemberGroupType>[] = [
  { label: "Direct Manager", value: "direct_manager" },
  { label: "Job Supervisor", value: "job_supervisor" },
  { label: "Job Superintendent", value: "job_superintendent" },
  { label: "Crew Lead", value: "crew_lead" },
  { label: "Department Head", value: "department_head" },
  { label: "Submitter", value: "self" },
  { label: "Department", value: "department" },
  { label: "Team Member", value: "team_member" },
  { label: "Pay Type", value: "pay_type" },
  { label: "Employment Type", value: "employment_type" },
  { label: "Title", value: "title" },
  { label: "All Team Members", value: "all_team_members" },
  { label: "Admin", value: "admin" },
];

export const GroupTypeLabelLookup = (
  type: TeamMemberGroupType | ApproverGroupType | undefined,
  labelOverrides?: Record<string, string>
): string => {
  if (!type) return "";
  return labelOverrides?.[type] || keyBy(GroupTypeOptions, "value")[type]?.label || "";
};
