import CustomerModal from "dashboard/components/customers/CustomerModal";
import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { formatPhoneNumber, Notifier } from "dashboard/utils";
import { Plus, TrashSimple } from "phosphor-react";
import React, { useContext, useMemo, useState } from "react";
import { BasicModal, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Customer } from "backend/models";

type CustomerTableEntry = Omit<Customer, "_id"> & { formattedPhone: string; _id: string };

type Props = { readonly?: boolean };

export const CustomerTable: React.FC<Props> = ({ readonly }) => {
  const { customers, getCustomers } = useContext(AppContext);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<CustomerTableEntry | undefined>();
  const [archiveConfirmation, setArchiveConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState<CustomerTableEntry[]>([]);

  const tableData: CustomerTableEntry[] = useMemo(() => {
    return customers.map((c) => ({ ...c, formattedPhone: c.phone ? formatPhoneNumber(c.phone) : "-" }));
  }, [customers]);

  const hideModal = () => {
    setCreating(false);
    setEditing(undefined);
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add customer",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => !readonly,
      },
    ],
    [readonly]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiveConfirmation(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => !readonly,
      },
    ],
    [readonly]
  );

  const archiveSelectedCustomers = async () => {
    if (readonly) return;

    setDeleting(true);
    try {
      await Promise.all(
        selected.map((s) => {
          return MiterAPI.customers.update(s._id, { archived: true });
        })
      );
      await getCustomers();
      setSelected([]);
      setArchiveConfirmation(false);
      Notifier.success("Customer(s) deleted successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the selected customer(s). We're looking into it!");
    }
    setDeleting(false);
  };

  return (
    <div>
      <TableV2
        id={"customers-table"}
        resource="customers"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelected}
        defaultSelectedRows={selected}
        onClick={!readonly ? setEditing : undefined}
      />
      {(creating || editing) && (
        <CustomerModal onFinish={hideModal} onHide={hideModal} customer_id={editing?._id} />
      )}
      {archiveConfirmation && (
        <BasicModal
          headerText="Delete customer(s)?"
          onHide={() => setArchiveConfirmation(false)}
          button2Action={archiveSelectedCustomers}
          loading={deleting}
          button2Text="Delete"
        >
          <div className="red-text-container">Are you sure you want to delete the selected customer(s)?</div>
        </BasicModal>
      )}
    </div>
  );
};

const columns: ColumnConfig<CustomerTableEntry>[] = [
  { headerName: "label", field: "name", dataType: "string" },
  { headerName: "Identifier", field: "identifier", dataType: "string" },
  { headerName: "Phone", field: "formattedPhone", dataType: "string" },
];
