import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { AggregatedI9, MiterAPI } from "dashboard/miter";
import { Notifier } from "ui";
import { Formblock, WizardScreen } from "ui";
import * as vals from "dashboard/utils/validators";

import styles from "../I9Wizard.module.css";

import useWizard from "ui/modal/useWizard";
import {
  buildVerifyEmployeeInformationFormDefaults,
  EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS,
  VerifyEmployeeInformationFormFields,
} from "miter-utils/i-9";
import { useRefetchTeam } from "dashboard/hooks/atom-hooks";

type Props = {
  I9: AggregatedI9;
  setI9: (I9: AggregatedI9) => void;
  name: "Verify Employee Information";
};

const VerifyEmployeeInformationForm: React.FC<Props> = ({
  I9,
  setI9,
  name = "Verify Employee Information",
}) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { setCanNext } = useWizard();
  const refetchTeam = useRefetchTeam();
  const form = useForm<VerifyEmployeeInformationFormFields>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildVerifyEmployeeInformationFormDefaults(I9),
    shouldUnregister: false,
  });

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, watch, formState, errors, reset } = form;
  const { dirtyFields, isValid } = formState;

  const formData = watch();

  /*********************************************************
   * useEffects
   **********************************************************/
  useEffect(() => {
    form.trigger();
  }, [JSON.stringify(formData)]);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  useEffect(() => {
    reset({ ...buildVerifyEmployeeInformationFormDefaults(I9) });
  }, [I9]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveI9)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }
  };

  /*********************************************************
   *  Backend submission + cleaning functions
   **********************************************************/

  const buildI9Params = (params: VerifyEmployeeInformationFormFields) => {
    return {
      "section_1.employee_first_name": params.employee_first_name,
      "section_2.employee_first_name": params.employee_first_name,
      "section_1.employee_middle_initial": params.employee_middle_initial,
      "section_2.employee_middle_initial": params.employee_middle_initial,
      "section_1.employee_last_name": params.employee_last_name,
      "section_2.employee_last_name": params.employee_last_name,
      "section_2.employee_start_date": params.employee_start_date.toISODate(),

      "section_2.employee_citizenship_status": params.employee_citizenship_status?.value,
      "section_1.employee_authorization_type": params.employee_citizenship_status?.value,
    };
  };

  const saveI9 = async (data: VerifyEmployeeInformationFormFields) => {
    try {
      const params = buildI9Params(data);

      // If something is updated on this page, we need to make sure we update the team member first before we update the I9
      const updateTM = await MiterAPI.team_member.update(I9.team_member_id, {
        first_name: params["section_2.employee_first_name"],
        last_name: params["section_2.employee_last_name"],
        start_date: params["section_2.employee_start_date"],
      });

      if (updateTM.error) throw new Error(updateTM.error);
      await refetchTeam(I9.team_member_id);

      // Update I-9

      // @ts-expect-error nested field changes
      const updateI9 = await MiterAPI.i_9s.update(I9._id, params);
      if (updateI9.error) throw new Error(updateI9.error);

      setI9(updateI9);
    } catch (e: $TSFixMe) {
      console.log("Error submitting I-9 personal information form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   *  Form input handler functions
   **********************************************************/

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["verify-employee-info-form"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Let&apos;s make sure we have the right information</h2>
          <p className={styles["subheader-description"]}>
            Note: Any changes made here will also be reflected in the employee&apos;s profile.
          </p>
        </div>
        <div className="flex">
          <Formblock
            type="text"
            name="employee_first_name"
            label="Employee first name*"
            form={form}
            val={vals.required}
            editing={true}
            className="modal wizard"
            maxLength={50}
            placeholder={"Enter first name"}
            style={{ width: "70%", marginRight: "10px" }}
          />
          <Formblock
            type="text"
            name="employee_middle_initial"
            label="Employee middle initial"
            form={form}
            editing={true}
            className="modal wizard"
            maxLength={1}
            placeholder={"Enter middle initial"}
          />
        </div>
        <Formblock
          type="text"
          name="employee_last_name"
          label="Employee last name*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          maxLength={50}
          placeholder={"Enter last name"}
        />
        <Formblock
          type="select"
          name="employee_citizenship_status"
          label="Employee citizenship/immigration status*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          options={EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS}
          isClearable={true}
        />
        <Formblock
          type="datetime"
          name="employee_start_date"
          label="Employee start date*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          placeholder={"MM/DD/YYYY"}
          dateOnly={true}
          customFormat={"MM/dd/yyyy"}
        />
      </div>
    </WizardScreen>
  );
};

export default VerifyEmployeeInformationForm;
