import { Form, MiterAPI } from "dashboard/miter";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Notifier } from "ui";
import { FormSubmission } from "dashboard/miter";
import { FormSubmissionWizard } from "miter-components";
import { useActiveAccount, useActiveRole, useActiveTeamMember } from "dashboard/hooks/atom-hooks";

export const FormSubmissionView: React.FC = () => {
  const navigate = useNavigate();
  const { id, submission_id } = useParams<{ id: string; submission_id: string }>();

  const [form, setForm] = useState<Form>();
  const [formSubmission, setFormSubmission] = useState<FormSubmission>();
  const teamMember = useActiveTeamMember();
  const activeRole = useActiveRole();
  const account = useActiveAccount();

  const submitter = teamMember || activeRole;

  useEffect(() => {
    getForm();

    if (submission_id) getFormSubmission();
  }, [submission_id, id]);

  const getFormSubmission = async () => {
    if (!submission_id) return;

    try {
      const formSubmissions = await MiterAPI.form_submissions.search([
        { field: "_id", value: submission_id, type: "_id" },
      ]);

      if (formSubmissions.error) throw new Error(formSubmissions.error);
      if (!formSubmissions[0]) throw new Error("Form not found");

      setFormSubmission(formSubmissions[0]);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error fetching the form. We're looking into it.");
    }
  };

  const getForm = async () => {
    if (!id) return;

    try {
      const forms = await MiterAPI.forms.search([{ field: "_id", value: id, type: "_id" }]);
      if (forms.error) throw new Error(forms.error);
      if (!forms[0]) throw new Error("Form not found");

      setForm(forms[0]);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error fetching the form. We're looking into it.");
    }
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Submit Form | Miter</title>
      </Helmet>
      {form && (!submission_id || formSubmission) && !!submitter && (
        <FormSubmissionWizard
          onExit={() => navigate("/forms/" + id)}
          onComplete={() => navigate("/forms/" + id)}
          mode={"create"}
          formSubmission={formSubmission}
          formItem={form}
          teamMember={{
            company: submitter?.company,
            full_name: submitter?.full_name || submitter.email || "External Role",
            _id: submitter?._id,
          }}
          accountType={account?.type === "role" ? "admin" : "team_member"}
          userId={submitter?.user}
        />
      )}
    </div>
  );
};
