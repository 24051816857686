import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Formblock, Loader, ModalWithSidebar, Notifier } from "ui";
import { MiterAPI, PlaidBankAccount, StripeAccountResponse } from "dashboard/miter";
import { useActiveCompany, useActiveCompanyId, useStripeConnectedAccount } from "dashboard/hooks/atom-hooks";
import { getMiterCardsAccount, isPlaidAccountVerified } from "../expenses/expenseUtils";
import * as formValidators from "dashboard/utils/validators";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styles from "./Expenses.module.css";
import Banner from "dashboard/components/shared/Banner";
import AppContext from "dashboard/contexts/app-context";
import { Option } from "ui/form/Input";
import { useFetchBankAccounts } from "miter-components/bank-accounts/utils";
import { usdString } from "ui";

const AccountAndRoutingNumber: React.FC<{
  accountNumber?: string;
  stripeAccount: StripeAccountResponse | null;
}> = ({ stripeAccount }) => {
  const activeCompanyId = useActiveCompanyId();
  const [open, setOpen] = useState<boolean>(false);
  const [accountNumber, setAccountNumber] = useState<string>();
  const [accountNumberLoading, setAccountNumberLoading] = useState<boolean>(true);

  const getAccountNumber = async () => {
    if (!activeCompanyId || !stripeAccount) return;

    try {
      const response = await MiterAPI.stripe_accounts.retrieve(stripeAccount.stripe_id, {
        show_account_number: true,
      });

      const miterCardsAccountNumber =
        getMiterCardsAccount(response)?.financial_addresses[0]?.aba?.account_number;
      setAccountNumber(miterCardsAccountNumber || undefined);
    } catch (e: $TSFixMe) {
      Notifier.error("Unable to retrieve account number.");
    }
    setAccountNumberLoading(false);
  };

  useEffect(() => {
    getAccountNumber();
  }, [activeCompanyId]);

  return (
    <>
      <div className="pointer flex space-between margin-top-16 color-gray" onClick={() => setOpen(!open)}>
        <p className={`${styles.white_space_pre_line} no-margin`}>Or send funds manually</p>
        {open ? <FaChevronUp className="font-size-12" /> : <FaChevronDown className="font-size-12" />}
      </div>
      {open && (
        <>
          <p className={`${styles.white_space_pre_line}`}>
            Log into your bank and initiate an ach or wire with your account number.
          </p>
          <div className={`${styles.small_text_on_white} info-row align-items-center`}>
            <div>Routing number</div>
            <div>{getMiterCardsAccount(stripeAccount)?.financial_addresses[0]?.aba?.routing_number}</div>
          </div>
          <div className={`${styles.small_text_on_white} info-row align-items-center`}>
            <div>Account number</div>
            <div>{accountNumberLoading ? "Loading..." : accountNumber}</div>
          </div>
        </>
      )}
    </>
  );
};

type ManualAddFundsFormData = {
  originating_account: Option<string>;
  amount_in_dollars: number;
};

const ManualAddFundsForm: FC<{
  bankAccountOptions: Option<string>[];
  onHide: () => void;
  refreshBalance: () => void;
}> = ({ bankAccountOptions, onHide, refreshBalance }) => {
  // state
  const stripeAccount = useStripeConnectedAccount();
  const [loading, setLoading] = useState(false);

  const [firstOption, ..._rest] = bankAccountOptions;
  console.log("bankAccountOptions", bankAccountOptions);

  // form
  const { register, errors, handleSubmit, setValue, control } = useForm<ManualAddFundsFormData>({
    defaultValues: {
      originating_account: firstOption,
    },
  });
  const activeCompanyId = useActiveCompanyId();

  const onSubmit = async (data: ManualAddFundsFormData) => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const { amount_in_dollars, originating_account } = data;
      const miterCardAccount = getMiterCardsAccount(stripeAccount);
      if (miterCardAccount) {
        const newTransfer = await MiterAPI.stripe_accounts.inbound_transfer({
          amount_in_dollars,
          companyId: activeCompanyId,
          bank_account_id: originating_account.value,
          financial_account_id: miterCardAccount.id,
          parent_type: "miter_card_funding",
        });
        if (newTransfer.error) throw new Error(newTransfer.error);
      } else {
        throw new Error(`Miter Card funding account not found.`);
      }

      Notifier.success(`${usdString(amount_in_dollars)} is being transferred to your Miter account.`);
      refreshBalance();
    } catch (e: $TSFixMe) {
      console.error(`Error initiating Miter Card funding transfer for ${activeCompanyId}`, e.message);
      Notifier.error("Unable to transfer funds.");
    }
    setValue("amount_in_dollars", 0);
    setLoading(false);
    onHide();
  };

  if (loading) return <Loader />;

  if (bankAccountOptions.length === 0) {
    return <>Connect your bank account to Miter to make transfers easy.</>;
  }

  return (
    <>
      <Formblock
        label="Bank account"
        style={{ marginTop: 15 }}
        type="select"
        requiredSelect
        options={bankAccountOptions}
        name="originating_account"
        control={control}
        errors={errors}
        editing={true}
      />
      <Formblock
        label="Amount"
        style={{ marginTop: 15 }}
        type="unit"
        unit="$"
        name="amount_in_dollars"
        register={register(
          formValidators.numberValidator({
            required: true,
            excludeNegatives: true,
            excludeZero: true,
            maxDecimals: 2,
          })
        )}
        errors={errors}
        control={control}
        editing={true}
      />
      <AccountAndRoutingNumber stripeAccount={stripeAccount} />
      <div className="flex modal-footer">
        <Button onClick={handleSubmit(onSubmit)} className="button-2 no-margin">
          Transfer to Miter account
        </Button>
      </div>
    </>
  );
};

type AutomaticAddFundsFormData = {
  originating_account: Option<string>;
  minimum_balance: number;
  funding_amount: number;
};

const AutomaticAddFundsForm: FC<{
  bankAccountOptions: Option<string>[];
}> = ({ bankAccountOptions }) => {
  // hooks
  const activeCompany = useActiveCompany();
  const currentAutomaticFundingSettings = activeCompany?.settings.expenses?.miter_card_automated_funding;

  const { fetchUserData } = useContext(AppContext);
  const { register, errors, handleSubmit, control, watch, reset } = useForm<AutomaticAddFundsFormData>({
    defaultValues: {
      originating_account:
        bankAccountOptions.find(
          (account) => account.value === currentAutomaticFundingSettings?.bank_account_id
        ) || bankAccountOptions[0],
      minimum_balance: currentAutomaticFundingSettings?.minimum_balance,
      funding_amount: currentAutomaticFundingSettings?.funding_amount,
    },
  });
  const formData = watch();

  // state
  const [submitting, setSubmitting] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  // stateful copy of formData to populate banner in real time
  const [formDataCopy, setFormDataCopy] = useState<{ [x: string]: $TSFixMe }>();

  useEffect(() => {
    setFormDataCopy(formData);
  }, [JSON.stringify(formData)]);

  const areFormValuesSameAsCurrentSettings = useMemo(() => {
    return (
      (formDataCopy?.originating_account?.value || undefined) ===
        currentAutomaticFundingSettings?.bank_account_id &&
      (formDataCopy?.minimum_balance || undefined) === currentAutomaticFundingSettings?.minimum_balance &&
      (formDataCopy?.funding_amount || undefined) === currentAutomaticFundingSettings?.funding_amount
    );
  }, [formDataCopy, currentAutomaticFundingSettings]);

  const onSave = async (data: AutomaticAddFundsFormData) => {
    if (!activeCompany) return;
    setSubmitting(true);
    try {
      const response = await MiterAPI.companies.update(activeCompany._id, {
        $set: {
          "settings.expenses.miter_card_automated_funding": {
            bank_account_id: data.originating_account.value,
            minimum_balance: data.minimum_balance,
            funding_amount: data.funding_amount,
          },
        },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success(
        `Automatic funding updated. This will take effect tomorrow night. If you need to top up your balance immediately, do so with a manual funding today.`
      );
      fetchUserData(); // updates company
    } catch (e: $TSFixMe) {
      console.error(`Error saving automatic Miter Card funding for ${activeCompany._id}`, e.message);
      Notifier.error("Unable to save automatic funding cycle.");
    }
    setSubmitting(false);
  };

  const onClearCurrentSettings = async () => {
    if (!activeCompany) return;
    setCancelling(true);
    try {
      const response = await MiterAPI.companies.update(activeCompany._id, {
        $unset: {
          "settings.expenses.miter_card_automated_funding": "",
        },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success(`The previous automatic funding cycle is now canceled.`);
      fetchUserData(); // updates company

      // reset form
      reset({
        originating_account: undefined,
        minimum_balance: undefined,
        funding_amount: undefined,
      });
    } catch (e: $TSFixMe) {
      console.error(`Error canceling automatic Miter Card funding for ${activeCompany._id}`, e.message);
      Notifier.error("Unable to cancel the current automatic funding cycle.");
    }
    setCancelling(false);
  };

  return (
    <div>
      <Formblock
        label="Bank account"
        style={{ marginTop: 15 }}
        type="select"
        requiredSelect
        options={bankAccountOptions}
        name="originating_account"
        errors={errors}
        control={control}
        editing={true}
      />
      <Formblock
        label="Balance threshold"
        style={{ marginTop: 15 }}
        type="unit"
        unit="$"
        name="minimum_balance"
        register={register(
          formValidators.numberValidator({
            required: true,
            excludeNegatives: true,
            excludeZero: true,
            maxDecimals: 2,
          })
        )}
        errors={errors}
        control={control}
        editing={true}
      />
      <Formblock
        label="Amount to transfer"
        style={{ marginTop: 15, marginBottom: 15 }}
        type="unit"
        unit="$"
        name="funding_amount"
        register={register(
          formValidators.numberValidator({
            required: true,
            excludeNegatives: true,
            excludeZero: true,
            maxDecimals: 2,
          })
        )}
        errors={errors}
        control={control}
        editing={true}
      />
      {formDataCopy && !areFormValuesSameAsCurrentSettings && (
        <Banner
          type="modal"
          content={`By saving, Miter will automatically transfer ${usdString(
            formDataCopy.funding_amount
          )} from ${formDataCopy.originating_account?.label} when the funding balance goes below ${usdString(
            formDataCopy.minimum_balance
          )}.`}
        />
      )}
      {areFormValuesSameAsCurrentSettings && currentAutomaticFundingSettings && (
        <Banner
          type="modal"
          content={`Automatic funding is currently set up to transfer ${usdString(
            currentAutomaticFundingSettings?.funding_amount
          )} when the funding balance goes below ${usdString(
            currentAutomaticFundingSettings?.minimum_balance
          )}.`}
        />
      )}

      <div className="flex modal-footer">
        {currentAutomaticFundingSettings && (
          <Button
            onClick={onClearCurrentSettings}
            className="button-3"
            text="Cancel automatic funding"
            loading={cancelling}
          />
        )}
        {!areFormValuesSameAsCurrentSettings && formDataCopy && (
          <Button onClick={handleSubmit(onSave)} className="button-2" text="Save" loading={submitting} />
        )}
      </div>
    </div>
  );
};

export const MiterCardFundingModal: FC<{
  onHide: () => void;
  refreshBalance: () => void;
}> = ({ onHide, refreshBalance }) => {
  const activeCompanyId = useActiveCompanyId();
  const { result, bankAccountsLoaded } = useFetchBankAccounts({
    companyId: activeCompanyId || "",
    refreshCount: 0,
  });

  const bankAccountOptions = (result as PlaidBankAccount[])
    .filter((bankAccount) => isPlaidAccountVerified(bankAccount.external_raw_data))
    .map((account: PlaidBankAccount) => {
      return {
        label: `${account.external_raw_data.name} - ${account.account_number_last_4}`,
        value: account._id,
      };
    });

  const menuItems = useMemo(
    () => [
      {
        label: "One time transfer",
        path: "one_time_transfer",
        component: !bankAccountsLoaded ? (
          <Loader />
        ) : (
          <ManualAddFundsForm
            bankAccountOptions={bankAccountOptions}
            onHide={onHide}
            refreshBalance={refreshBalance}
          />
        ),
        overflow: "visible" as const,
      },
      ...(bankAccountOptions.length > 0
        ? [
            {
              label: "Automated",
              path: "automated_transfers",
              component: <AutomaticAddFundsForm bankAccountOptions={bankAccountOptions} />,
              overflow: "visible" as const,
            },
          ]
        : []),
    ],
    [bankAccountsLoaded, bankAccountOptions]
  );

  return <ModalWithSidebar menuItems={menuItems} header="Add / Manage funding" hide={onHide} />;
};
