import React, { FC, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Notifier } from "ui/notifier";
import { Button, Formblock, WizardScreen } from "ui";
import { I9FormProps } from "./I9Wizard";
import * as vals from "dashboard/utils/validators";
import { saveAs } from "file-saver";

import styles from "./I9Wizard.module.css";

import { ESignatureItem, MiterAPI } from "dashboard/miter";
import useWizard from "ui/modal/useWizard";
import { getOS } from "miter-utils";
import { CheckCircle } from "phosphor-react";
import { ESignatureInputValue } from "ui/form/ESignatureInput";
import { EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS } from "miter-utils/i-9";
import { DateTime } from "luxon";

type ReviewAndSignFormFields = {
  employee_esignature?: ESignatureInputValue;
  employee_esignature_id?: string;
  employee_other_last_names?: string;
};

const ReviewAndSignForm: FC<I9FormProps> = ({ I9, setI9, activeTM, name = "Review and Sign", getI9 }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { setCanNext, handleComplete, setNextButtonText } = useWizard();
  const [downloadingPreview, setDownloadingPreview] = useState(false);

  const form = useForm<ReviewAndSignFormFields>({
    reValidateMode: "onChange",
    mode: "all",
    shouldUnregister: false,
    defaultValues: {
      employee_esignature_id: I9?.section_1.employee_esignature_id,
      employee_other_last_names: I9?.section_1.employee_other_last_names,
    },
  });

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, watch, formState, errors } = form;
  const { dirtyFields, isValid } = formState;

  const formData = watch();

  /*********************************************************
   * useEffect's
   * - Trigger form validation on change
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    setNextButtonText("Complete");
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveI9)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    getI9();
    handleComplete();
  };

  /*********************************************************
   *  Backend submission + cleaning functions
   **********************************************************/
  const buildI9Params = async (params: ReviewAndSignFormFields) => {
    // Save e-signature if they used a employee
    const esignature = await saveESignature(params);

    return {
      "section_1.employee_other_last_names": params.employee_other_last_names,
      "section_1.employee_esignature_id": esignature?._id || null,
      "section_1.employee_esignature_date": esignature?.created_at
        ? DateTime.fromSeconds(esignature.created_at).toISODate()
        : null,
    };
  };

  const saveESignature = async (params: ReviewAndSignFormFields): Promise<ESignatureItem> => {
    if (!params.employee_esignature || !params.employee_esignature.data) {
      throw new Error("Signature is required");
    }

    const esignParams = {
      company_id: I9.company_id,
      document_type: "i9",
      parent_document_id: I9._id,
      signature: {
        image: params.employee_esignature.data,
        device: { type: "desktop", os: getOS() },
        application: { name: "team-portal" },
      },
      signer: {
        name: activeTM?.full_name,
        type: "team_member",
        title: activeTM?.title || "Team member",
        team_member_id: activeTM?._id,
        user_id: activeTM.user,
      },
    };

    // @ts-expect-error fix me
    const res = await MiterAPI.esignature_items.signatures.create([esignParams]);
    if (res.error) throw new Error(res.error);
    if (!res || !res[0]) throw new Error("Error saving employee signature");

    return res[0];
  };

  const saveI9 = async (data: ReviewAndSignFormFields) => {
    try {
      const params = await buildI9Params(data);

      // Update I-9
      // @ts-expect-error fix me
      const res = await MiterAPI.i_9s.update(I9._id, params);
      if (res.error) throw new Error(res.error);

      setI9(res);

      Notifier.success(
        "You have submitted your I-9. Please remember to bring original versions of the documents you used to complete this form to your first day of work."
      );
    } catch (e: $TSFixMe) {
      console.log("Error submitting I-9 personal information form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   *  Handlers
   **********************************************************/
  const handlePreviewClick = async () => {
    setDownloadingPreview(true);
    try {
      const res = await MiterAPI.i_9s.retrieve_pdf(I9._id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      saveAs(blob, `I-9 Preview for ${activeTM?.full_name}.pdf`);
    } catch (e: $TSFixMe) {
      Notifier.error("Error downloading I-9 preview. Please contact support.");
      console.error("Error previewing I-9", e);
    }
    setDownloadingPreview(false);
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderPreviewBlock = () => {
    return (
      <>
        <Formblock
          type="text"
          name="employee_other_last_names"
          label="Employee's other last names (if applicable)"
          form={form}
          editing={true}
          className="modal wizard"
          maxLength={50}
          placeholder={"Enter any other last names here"}
        />

        <Button
          className={"button-2"}
          onClick={handlePreviewClick}
          style={{ margin: 0, marginBottom: 30, marginTop: 10, padding: "20px 35px", fontSize: 16 }}
          loading={downloadingPreview}
        >
          Preview I-9
        </Button>
      </>
    );
  };

  const renderESignatureBlock = () => {
    // If we already have a employee signature, show a signed block instead
    if (formData.employee_esignature_id && !formState.isDirty) {
      return (
        <div className={styles["employee-esignature-completed"]}>
          <CheckCircle style={{ marginRight: 10, marginBottom: -3 }} color={"#26A96C"} weight="fill" />
          E-Signature completed
        </div>
      );
    }

    const eeAuthorizationType = EMPLOYEE_AUTHORIZATION_TYPE_OPTIONS.find(
      (o) => o.value === I9.section_1.employee_authorization_type
    )?.label;

    return (
      <>
        <div className={styles["employee-disclosure"]}>
          I am aware that federal law provides for imprisonment and/or fines for false statements or use of
          false documents in connection with the completion of this form. I attest, under penalty of perjury,
          that I am: {eeAuthorizationType}
        </div>
        <Formblock
          type="esignature"
          name="employee_esignature"
          label="Employee Signature*"
          form={form}
          editing={true}
          className="modal wizard"
          rules={{ validate: vals.esignatureRequired }}
          onError={Notifier.error}
        />
      </>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["review-and-sign-form"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Finalize your I-9</h2>
          <p className={styles["subheader-description"]}>
            Please review your I-9 and sign below to complete the process.
          </p>
        </div>
        {renderPreviewBlock()}
        {renderESignatureBlock()}
      </div>
    </WizardScreen>
  );
};

export default ReviewAndSignForm;
