import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Loader } from "ui";
import { displayFieldErrors } from "dashboard/utils/errors";

import * as vals from "dashboard/utils/validators";
import { Notifier } from "dashboard/utils";
import { Equipment, MiterAPI } from "dashboard/miter";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

type Props = {
  equipmentID?: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const EquipmentModal: FC<Props> = ({ equipmentID, onCancel, onSubmit }) => {
  const form = useForm();
  const formData = form.watch();
  const { can, cannot } = useMiterAbilities();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();

  const [equipment, setEquipment] = useState<Equipment>();
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, register, errors, handleSubmit, setError } = form;

  const activeCompanyId = useActiveCompanyId();

  useEffect(() => {
    if (equipmentID) {
      getEquipment();
    }
  }, [equipmentID]);

  // Remove properties that equal what exists in the equipment object
  const cleanParams = (data) => {
    return {
      ...data,
      status: data.status?.value || null,
      company_id: activeCompanyId,
    };
  };

  const getEquipment = async () => {
    if (!equipmentID || cannot("lists:equipment:manage")) return;
    setLoadingEquipment(true);
    try {
      const res = await MiterAPI.equipment.retrieve(equipmentID);
      if (res.error) throw new Error(res.error);

      setEquipment(res);
    } catch (e: $TSFixMe) {
      console.error("Error getting equipment:", e);
      Notifier.error(e.message);
    }
    setLoadingEquipment(false);
  };

  const createEquipment = async (data) => {
    if (cannot("lists:equipment:manage")) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.equipment.create(params);
      if (res.error) {
        displayFieldErrors(res.fields!, setError);
        throw new Error(res.error);
      }

      setEquipment(res);
      Notifier.success("Equipment updated successfully");
      onSubmit();
    } catch (e: $TSFixMe) {
      console.error("Error updating equipment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateEquipment = async (data) => {
    if (!equipmentID || cannot("lists:equipment:manage")) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.equipment.update(equipmentID, params);
      if (res.error) {
        displayFieldErrors(res.fields!, setError);
        throw new Error(res.error);
      }
      setEquipment(res);
      Notifier.success("Equipment updated successfully");
      onSubmit();
    } catch (e: $TSFixMe) {
      console.error("Error updating equipment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const deleteEquipment = async () => {
    if (!equipment || cannot("lists:equipment:manage")) return;
    setLoading(true);
    try {
      const res = await MiterAPI.equipment.delete(equipment?._id);
      if (res.error) throw new Error(res.error);

      Notifier.success("Equipment deleted successfully");
      onSubmit();
    } catch (e: $TSFixMe) {
      console.error("Error deleting equipment:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const submit = () => {
    if (formData.cost_rate && formData.cost_rate < 0) {
      setError("cost_rate", { message: "Cost rate must be greater than 0" });
      return;
    }
    if (equipmentID) {
      handleSubmit(updateEquipment)();
    } else {
      handleSubmit(createEquipment)();
    }
  };

  const hide = () => {
    onCancel();
  };

  const renderForm = () => {
    if (loadingEquipment) return <Loader />;

    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={equipment?.name}
          className="modal"
          disabled={cannot("lists:equipment:manage")}
        />
        <Formblock
          type="text"
          name="code"
          label="Code"
          labelInfo="This is the code that will be used to identify this equipment"
          register={register}
          editing={true}
          errors={errors}
          defaultValue={equipment?.code}
          className="modal"
          disabled={cannot("lists:equipment:manage")}
        />
        {hasAccessToEquipmentTracking && (
          <>
            <Formblock
              type="select"
              name="status"
              label="Status"
              options={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
              control={control}
              register={register}
              editing={true}
              errors={errors}
              defaultValue={equipment?.status || ""}
              className="modal"
              isClearable
              disabled={cannot("lists:equipment:manage")}
            />
            <Formblock
              type="number"
              name="cost_rate"
              label="Cost Rate"
              register={register}
              step={0.01}
              min={0}
              editing={true}
              errors={errors}
              defaultValue={equipment?.cost_rate}
              className="modal"
              disabled={cannot("lists:equipment:manage")}
            />
          </>
        )}
        <Formblock
          type="paragraph"
          name="notes"
          label="Notes"
          register={register}
          editing={true}
          errors={errors}
          defaultValue={equipment?.notes}
          className="modal"
          disabled={cannot("lists:equipment:manage")}
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={`${equipmentID ? "Edit" : "Add"} equipment`}
      showSubmit={can("lists:equipment:manage")}
      showCancel={true}
      showDelete={!!equipmentID}
      cancelText={"Close"}
      onCancel={hide}
      submitText={"Save"}
      deleteText={"Delete"}
      onHide={hide}
      onDelete={deleteEquipment}
      onSubmit={submit}
      loading={loading}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default EquipmentModal;
