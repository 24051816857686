import { ForageResponse } from "backend/utils/forage/forage-types";
import { Conversation, MiterAPI } from "dashboard/miter";
import {
  AggregatedRecruitingConversation,
  AggregatedTeamConversation,
  RecruitingConversation,
  TeamConversation,
  TwilioClient,
  TwilioConversation,
  TwilioMessage,
  TwilioMetadata,
} from "dashboard/types/chat";

export const buildChatConversation = async <T extends TeamConversation | RecruitingConversation>(
  conversation: T,
  twilioConversation?: TwilioConversation
): Promise<T & TwilioMetadata> => {
  const unreadCount = await twilioConversation?.getUnreadMessagesCount();
  const unread = unreadCount ? true : false;
  const messages = await twilioConversation?.getMessages();

  // If there is no last message, create a placeholder one with the dateCreated being when the convo was created
  const last_message = messages?.items.pop() || {
    body: "New conversation",
    dateCreated: twilioConversation?.dateCreated as Date,
  };

  return {
    ...conversation,
    twilio_conversation: twilioConversation,
    unread,
    last_message: last_message as TwilioMessage,
  };
};

export const conversationSorter = (
  convo1: AggregatedTeamConversation,
  convo2: AggregatedTeamConversation
): number => {
  const c1DateCreated = convo1.last_message?.dateCreated || convo1.twilio_conversation?.dateCreated;
  const c2DateCreated = convo2.last_message?.dateCreated || convo2.twilio_conversation?.dateCreated;

  if (!c1DateCreated) return 1;
  if (!c2DateCreated) return -1;

  return c2DateCreated.getTime() - c1DateCreated.getTime();
};

export const getTwilioConversations = async (client: TwilioClient): Promise<TwilioConversation[]> => {
  let conversationsResponse = await client.getSubscribedConversations();
  let subscribedConversations = conversationsResponse.items;

  while (conversationsResponse.hasNextPage) {
    conversationsResponse = await conversationsResponse.nextPage();
    subscribedConversations = subscribedConversations.concat(conversationsResponse.items);
  }

  return subscribedConversations;
};

export const twilioClientConnectStateLookup = {
  connecting: "Connecting to Twilio...",
  connected: "You are connected.",
  disconnecting: "Disconnecting from Twilio…",
  disconnected: "Disconnected.",
  denied: "Failed to connect.",
};

export const allowedTwilioFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/png",
  "audio/basic",
  "audio/L24",
  "audio/mp4",
  "audio/mpeg",
  "audio/ogg",
  "audio/vnd.rn-realaudio",
  "audio/vnd.wave",
  "audio/3gpp",
  "audio/3gpp2",
  "audio/ac3",
  "audio/webm",
  "audio/amr-nb",
  "audio/amr",
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
  "video/3gpp-tt",
  "video/H261",
  "video/H263",
  "video/H263-1998",
  "video/H263-2000",
  "video/H264",
  "image/bmp",
  "image/tiff",
  "text/vcard",
  "text/x-vcard",
  "text/csv",
  "text/rtf",
  "text/richtext",
  "text/calendar",
  "text/directory",
  "application/pdf",
  "application/vcard",
];

type FileUploadError = "file-size" | "file-type" | "total-size" | "file-count";

export const handleFileUploadHelper = (files: FileList): FileUploadError | null => {
  const filesArr = Array.from(files);

  // SMS can only send up to 10 files at a time
  if (filesArr.length > 10) {
    return "file-count";
  }

  // SMS can't send files not in this file types list
  const hasInvalidFileTypes = filesArr.some((file) => !allowedTwilioFileTypes.includes(file.type));
  if (hasInvalidFileTypes) {
    return "file-type";
  }

  // SMS can't send a file larger than 2MB
  const hasFileOverSizeLimit = filesArr.some((file) => file.size > 1024 * 1024 * 1);
  if (hasFileOverSizeLimit) {
    return "file-size";
  }

  // SMS can't handle more than 5MB of files at once
  const totalFileSize = filesArr.reduce((acc, file) => acc + file.size, 0);
  if (totalFileSize > 1024 * 1024 * 10) {
    return "total-size";
  }

  return null;
};

/** Helper for recruiting chat atom  */

export const RECRUITING_CONVERSATION_PAGE_SIZE = 25;

type GetRecruitingConversationParams = {
  client: TwilioClient;
  companyId: string;
  cursor?: string;
  search: string;
};

export const getPaginatedRecruitingConversations = async (
  input: GetRecruitingConversationParams
): Promise<ForageResponse<AggregatedRecruitingConversation>> => {
  const { client, companyId, cursor, search } = input;

  const getRecruitingConversations = async (): Promise<ForageResponse<RecruitingConversation> | null> => {
    try {
      return await MiterAPI.chat.recruiting.forage({
        filter: [
          { field: "company", value: companyId },
          {
            field: "candidate_id",
            comparisonType: "exists",
            value: true,
          },
          ...(search
            ? [
                {
                  field: "candidate_names",
                  comparisonType: "contains",
                  value: search,
                  type: "string",
                } as const,
              ]
            : []),
        ],
        limit: RECRUITING_CONVERSATION_PAGE_SIZE,
        starting_after: cursor,
        sort: [{ field: "last_messaged_at", direction: -1 }],
      });
    } catch (e: $TSFixMe) {
      return null;
    }
  };

  const [forageResponse, twilioConversations] = await Promise.all([
    getRecruitingConversations(),
    getTwilioConversations(client),
  ]);

  if (!forageResponse) {
    return {
      data: [],
      next_page: null,
      prev_page: null,
    };
  }

  const recruitingConversations = forageResponse.data || [];

  const twilioConversationsMap = twilioConversations.reduce((acc, convo) => {
    acc[convo.sid] = convo;
    return acc;
  }, {});

  const mergedConversations = await Promise.all(
    recruitingConversations.map(async (conversation) => {
      const twilioConversation = twilioConversationsMap[conversation.conversation_sid];

      return buildChatConversation(conversation, twilioConversation);
    })
  );

  return {
    ...forageResponse,
    data: mergedConversations,
  };
};

/** Used to calculate the count of all unread conversations. It's a separate calculation because we paginate recruiting conversations */
export const calculateUnreadCount = async (client: TwilioClient): Promise<number> => {
  const allConversations = await getTwilioConversations(client);
  const unreadConversations = await Promise.all(
    allConversations.map(async (conversation) => {
      return (await conversation.getUnreadMessagesCount()) || 0;
    })
  );
  return unreadConversations.reduce((acc, count) => acc + (!!count ? 1 : 0), 0);
};

export const markTwilioConversationAsRead = async (
  twilioConversation?: TwilioConversation | null
): Promise<void> => {
  if (twilioConversation?.lastMessage?.index != null) {
    await twilioConversation?.updateLastReadMessageIndex(twilioConversation!.lastMessage!.index!);
  }
};

export const markNewTimestamp = async (conversationSid: string): Promise<RecruitingConversation | null> => {
  try {
    const response = await MiterAPI.chat.recruiting.update_timestamp(conversationSid);
    if (response.error) throw new Error(response.error);
    return response || null;
  } catch (e: $TSFixMe) {
    return null;
  }
};

const FILE_SIZE_ERROR = "No file can be larger than 1MB due to SMS rules.";
const FILE_TYPE_ERROR = "You can only send PDFs, images, videos, and audio messages over chat.";
const TOTAL_SIZE_ERROR = "The total size of the files you send must be less than 5MB.";
const FILE_COUNT_ERROR = "You can only upload up to 10 files at a time";

export const FILE_UPLOAD_ERRORS = {
  "file-size": FILE_SIZE_ERROR,
  "file-type": FILE_TYPE_ERROR,
  "total-size": TOTAL_SIZE_ERROR,
  "file-count": FILE_COUNT_ERROR,
};

export const GENERIC_MESSAGE_ERROR = "We are unable to send a message at this time. Please try again later.";

type SendFilesParams = {
  files: File[];
  twilioConversation: TwilioConversation;
  userLabel: string;
};
export const sendChatFileHelper = async (input: SendFilesParams): Promise<{ success: boolean }> => {
  const { files, twilioConversation, userLabel } = input;
  for (const file of files) {
    const formData = new FormData();
    formData.append(file.name, file as Blob);

    const sentMessageIndex = await twilioConversation?.sendMessage(formData, {
      sender: { label: userLabel },
    });

    if (sentMessageIndex === null || sentMessageIndex === undefined) {
      return { success: false };
    }

    await twilioConversation?.updateLastReadMessageIndex(twilioConversation!.lastMessage!.index!);
  }
  return { success: true };
};

type SendMessageParams = {
  message: string;
  twilioConversation: TwilioConversation;
  userLabel: string;
};

export const sendMessageHelper = async (input: SendMessageParams): Promise<{ success: boolean }> => {
  const { message, twilioConversation, userLabel } = input;
  if (message.trim().length > 0) {
    const sentMessageIndex = await twilioConversation?.sendMessage(message, {
      sender: { label: userLabel },
    });

    if (sentMessageIndex === null || sentMessageIndex === undefined) {
      return { success: false };
    }

    await twilioConversation?.updateLastReadMessageIndex(sentMessageIndex);
  }
  return { success: true };
};

export const sortConversationByLastMessages = (c1: Conversation, c2: Conversation): number => {
  if (!c1.last_messaged_at) return 1;
  if (!c2.last_messaged_at) return -1;

  return c2.last_messaged_at - c1.last_messaged_at;
};

export const CHAT_DEBOUNCE = 1000; // 1 second
