import { useEffect, useRef, useState } from "react";
import {
  useActiveCompany,
  useActiveRecruitingConversation,
  usePaginatedRecruitingConversations,
  useRecruitingChatRestart,
  useRecruitingTwilioClient,
  useSetActiveRecruitingConversation,
  useSetPaginatedRecruitingConversations,
  useSetRecruitingChatInitialized,
  useSetRecruitingTwilioClient,
  useSetUnreadRecruitingConversationsCount,
} from "../atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { ConnectionState, Client as ConversationsClient, Message, Participant } from "@twilio/conversations";
import { ParticipantUpdatedData, TwilioClient, TwilioConnectionStatus } from "dashboard/types/chat";
import { calculateUnreadCount, markNewTimestamp, twilioClientConnectStateLookup } from "dashboard/utils/chat";
import { DateTime } from "luxon";

export const useRecruitingChat = (): void => {
  /** Atom hooks */
  const recruitingConversations = usePaginatedRecruitingConversations();
  const setRecruitingConversations = useSetPaginatedRecruitingConversations();

  const recruitingTwilioClient = useRecruitingTwilioClient();
  const setRecruitingTwilioClient = useSetRecruitingTwilioClient();

  const setUnreadCount = useSetUnreadRecruitingConversationsCount();

  const activeRecruitingConversation = useActiveRecruitingConversation();
  const setActiveRecruitingConversation = useSetActiveRecruitingConversation();

  const setRecruitingChatInitialized = useSetRecruitingChatInitialized();

  const restartChatCount = useRecruitingChatRestart();

  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  /** Refs */
  const recruitingConversationsRef = useRef(recruitingConversations);
  const activeRecruitingConversationRef = useRef(activeRecruitingConversation);

  /** Internal helpers */
  const [_twilioConnectionStatus, setTwilioConnectionStatus] = useState<TwilioConnectionStatus>();
  const [lastRefreshedChat, setLastRefreshedChat] = useState(DateTime.now());

  /** Use Effects */

  useEffect(() => {
    recruitingConversationsRef.current = recruitingConversations;
  }, [recruitingConversations]);

  useEffect(() => {
    activeRecruitingConversationRef.current = activeRecruitingConversation;
  }, [activeRecruitingConversation]);

  useEffect(() => {
    if (!activeCompanyId) return;

    initializeRecruitingChat();
    return () => {
      clearRecruitingChat();
    };
  }, [activeCompanyId, restartChatCount]);

  useEffect(() => {
    if (!recruitingTwilioClient) return;
    // initializeTeamTwilioListeners();
    const asyncSetUnreadCount = async () => {
      const count = await calculateUnreadCount(recruitingTwilioClient);
      setUnreadCount(count);
    };

    asyncSetUnreadCount();
    initializeRecruitingTwilioListeners();

    return () => {
      teardownRecruitingTwilioListeners();
    };
  }, [recruitingTwilioClient]);

  useEffect(() => {
    const chatDisconnected =
      recruitingTwilioClient?.connectionState === "denied" ||
      recruitingTwilioClient?.connectionState === "disconnected";

    if (chatDisconnected) {
      initializeRecruitingChat();
    }
  }, [recruitingTwilioClient?.connectionState, lastRefreshedChat]);

  // Every 15 minutes update last refreshed chat
  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshedChat(DateTime.now());
    }, 1000 * 60 * 15);

    return () => clearInterval(interval);
  }, []);

  /** Utility functions */
  const initializeRecruitingChat = async () => {
    if (!activeCompany?.candidate_chat?.conversations_service_sid) return;
    const twilioAccessToken = await getRecruitingTwilioAccessToken();
    if (!twilioAccessToken) {
      return;
    }

    const recruitingTwilioClientRes = await initializeRecruitingTwilioClient(twilioAccessToken);
    setRecruitingTwilioClient(recruitingTwilioClientRes);
  };

  const clearRecruitingChat = () => {
    setRecruitingConversations([]);
    setRecruitingTwilioClient(null);
    setActiveRecruitingConversation(null);
    setUnreadCount(0);
    setRecruitingChatInitialized(false);

    // remove twilio keys from sessionStorage
    for (const key in sessionStorage) {
      if (sessionStorage.hasOwnProperty(key) && sessionStorage[key].includes("twilio")) {
        sessionStorage.removeItem(key);
      }
    }
  };

  /** Business logic helpers */
  const getRecruitingTwilioAccessToken = async () => {
    if (!activeCompanyId) return;
    try {
      const response = await MiterAPI.chat.recruiting.get_token(activeCompanyId);
      if (response.error) throw new Error(response.error);
      return response.token;
    } catch (e: $TSFixMe) {
      console.log(e);
    }
  };

  /** Twilio logic */
  const initializeRecruitingTwilioClient = async (twilioAccessToken: string): Promise<TwilioClient> => {
    return new Promise((resolve, reject) => {
      const client = new ConversationsClient(twilioAccessToken!);

      client.on("stateChanged", (state) => {
        if (state === "initialized") {
          resolve(client);
        } else if (state === "failed") {
          reject(client);
        }
      });
    });
  };

  const initializeRecruitingTwilioListeners = async () => {
    if (!recruitingTwilioClient) {
      console.error("Unable to initialize team twilio listeners: no twilio client");
      return;
    }

    setTwilioConnectionStatus({ message: "Connecting to Twilio...", status: "connecting" });

    recruitingTwilioClient.on("connectionStateChanged", handleConnectionStateChange);
    recruitingTwilioClient.on("messageAdded", handleMessageAdded);
    recruitingTwilioClient.on("participantUpdated", handleParticipantUpdated);

    recruitingTwilioClient.on("conversationJoined", (_conversation) => {});
    recruitingTwilioClient.on("conversationLeft", (_conversation) => {});
  };

  const teardownRecruitingTwilioListeners = async () => {
    if (!recruitingTwilioClient) {
      return console.error("Unable to tear down twilio listeners: no twilio client");
    }

    recruitingTwilioClient.off("connectionStateChanged", handleConnectionStateChange);
    recruitingTwilioClient.off("messageAdded", handleMessageAdded);
    recruitingTwilioClient.off("participantUpdated", handleParticipantUpdated);

    recruitingTwilioClient.off("conversationJoined", (_conversation) => {});
    recruitingTwilioClient.off("conversationLeft", (_conversation) => {});
  };

  const handleConnectionStateChange = (state: ConnectionState) => {
    setTwilioConnectionStatus({ message: twilioClientConnectStateLookup[state], status: state });
  };

  const handleMessageAdded = async (message: Message) => {
    updateConversation({ message });
  };

  const handleParticipantUpdated = async (data: ParticipantUpdatedData) => {
    updateConversation({ participant: data.participant });
  };

  const updateConversation = async ({
    message,
    participant,
  }: {
    message?: Message;
    participant?: Participant;
  }) => {
    const twilioConversation = message?.conversation || participant?.conversation;
    if (!recruitingTwilioClient || !twilioConversation) return;
    const [sender, messages, unreadMessageCount] = await Promise.all([
      message?.getParticipant(),
      twilioConversation?.getMessages(),
      twilioConversation?.getUnreadMessagesCount(),
    ]);
    const activeConversationSid = activeRecruitingConversationRef.current?.conversation_sid;
    const isActiveConversation = twilioConversation.sid === activeConversationSid;
    const newMessageIsChat = sender?.type === "chat";
    const newParticipantIsChat = participant?.type === "chat";

    const last_message = messages?.items.pop() || {
      body: "New conversation",
      dateCreated: twilioConversation?.dateCreated as Date,
    };
    const hasUnreadMessages = !!unreadMessageCount;
    const loadedConversations = [...recruitingConversationsRef.current];

    if (message) {
      // When we receive a message, we need to update the timestamp in Mongo and return the latest conversation
      const latestConversation = await markNewTimestamp(twilioConversation.sid);
      if (!latestConversation) return;
      const isUnread = !newMessageIsChat && hasUnreadMessages;
      const updatedConversation = {
        ...latestConversation,
        unread: isUnread,
        last_message: last_message as Message,
        twilio_conversation: twilioConversation,
      };
      if (isActiveConversation) {
        setActiveRecruitingConversation(updatedConversation);
      }
      setRecruitingConversations((prev) => prev.concat(updatedConversation));
      const unreadCount = await calculateUnreadCount(recruitingTwilioClient);
      setUnreadCount(unreadCount);
    } else if (participant) {
      const loadedIndex = loadedConversations.findIndex((c) => c.conversation_sid === twilioConversation.sid);
      if (loadedIndex !== -1) return;
      const conversationToReplace = loadedConversations[loadedIndex];
      if (!conversationToReplace) return;
      loadedConversations[loadedIndex] = {
        ...conversationToReplace,
        twilio_conversation: twilioConversation,
        unread: !newParticipantIsChat,
      };
      setRecruitingConversations(loadedConversations);
    }
  };
};
