import { AggregatedExpenseReimbursement, MiterAPI } from "dashboard/miter";
import React, { FC, useState } from "react";
import { Button, DropdownButton, Notifier } from "ui";
import { ExpenseReimbursementStatus } from "backend/models/expense-reimbursement";
import { useExpenseReimbursementAbilities } from "dashboard/hooks/abilities-hooks/useExpenseReimbursementAbilities";
import { archiveExpenseReimbursementRequests, submitExpensesForReimbursement } from "../expenseUtils";
import { Copy, DotsThreeVertical, TrashSimple } from "phosphor-react";
import { KickBackItemsAction } from "dashboard/components/approvals/KickBackItemsAction";
import { TableActionLink } from "ui/table-v2/Table";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type Props = {
  expenseReimbursement: AggregatedExpenseReimbursement;
  editing: boolean;
  isSaving: boolean; // isSaving is updated from modal helper, send to footer as prop
  toggleEdit: () => void; // toggle edit mode
  onSave: () => void; // save changes
  onApprovalChange: () => void; // refresh background table
  onCancelEdit: () => void; // resets form to defaults if cancel after updating some fields
  onHide: () => void; // close modal
  canSubmitForACHReimbursement: boolean;
  inboxMode?: InboxMode;
};

export const EditExpenseReimbursementModalFooter: FC<Props> = ({
  expenseReimbursement,
  editing,
  isSaving,
  toggleEdit,
  onSave,
  onApprovalChange,
  onCancelEdit,
  onHide,
  canSubmitForACHReimbursement,
  inboxMode,
}) => {
  /****
   * Hooks
   */
  const { can, cannot } = useExpenseReimbursementAbilities();
  const { _id, payout_method, status } = expenseReimbursement;

  /****
   * Initialize state
   */

  // loading states for each approval button
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isDenying, setIsDenying] = useState<boolean>(false);
  const [isUnapproving, setIsUnapproving] = useState<boolean>(false);
  const [isProcessingACH, setIsProcessingACH] = useState<boolean>(false);

  const updateStatus = async (newStatus: ExpenseReimbursementStatus) => {
    if (!expenseReimbursement) {
      Notifier.error("Reimbursement not found");
      return;
    }

    if (newStatus === "denied") setIsDenying(true);
    else if (newStatus === "approved") setIsApproving(true);
    else setIsUnapproving(true);

    // set approval_status to the new status
    const updateApprovalStatusResult = await MiterAPI.expense_reimbursements.update_status([
      {
        id: _id,
        new_status: newStatus,
      },
    ]);

    if (updateApprovalStatusResult.error) {
      Notifier.error(`Failed to update status.`);
    } else {
      if (updateApprovalStatusResult.failures.length) {
        for (const failure of updateApprovalStatusResult.failures) {
          Notifier.error(failure.message);
        }
      } else {
        onApprovalChange(); // refreshes table
        Notifier.success(`Marked reimbursement as ${newStatus}`);
      }
    }

    if (newStatus === "denied") {
      setIsDenying(false);
    } else if (newStatus === "approved") {
      setIsApproving(false);
    } else {
      setIsUnapproving(false);
    }
  };

  const submitForReimbursement = async () => {
    if (cannot("update", expenseReimbursement)) {
      Notifier.error("You do not have permission to pay out this expense reimbursement.");
      return;
    }

    setIsProcessingACH(true);
    const { successes } = await submitExpensesForReimbursement([_id]);
    if (successes > 0) {
      onApprovalChange(); // refreshes modal and table - this reimbursement moved from approved to processing
    }
    setIsProcessingACH(false);
  };

  const retryReimbursement = async () => {
    if (cannot("update", expenseReimbursement)) {
      Notifier.error("You do not have permission to pay out this expense reimbursement.");
      return;
    }

    setIsProcessingACH(true);
    try {
      const response = await MiterAPI.expense_reimbursements.retry_ach_transfer(expenseReimbursement._id);
      if (response.error) throw new Error(response.error);

      Notifier.success("Successfully retried the ACH payment.");
      onApprovalChange();
    } catch (err) {
      Notifier.error("There was an error retrying the reimbursement. We're looking into it.");
    }
    setIsProcessingACH(false);
  };

  const isUnapprovedAndHasPermissionToApprove =
    status === "unapproved" && can("approve", expenseReimbursement);

  const isApprovedOrDeniedAndHasPermissionToApprove =
    status === "approved" || (status === "denied" && can("approve", expenseReimbursement));

  const hasPermissionToProcessPayment = can("process_payment", expenseReimbursement);
  const loading = isSaving || isApproving || isDenying || isUnapproving || isProcessingACH;

  const duplicateReimbursement = async (id: string) => {
    try {
      const response = await MiterAPI.expense_reimbursements.duplicate([{ id }]);
      if (response.error) {
        console.error(response.error);
      }

      if (response.failures.length) {
        // setUpdateFailures(response.failures);
      } else {
        Notifier.success(`Successfully duplicated ${"reimbursement"}.`);
        onApprovalChange(); // refreshes table
      }
    } catch (err) {
      console.error("Error duplicating reimbursements", err);
      Notifier.error("There was an error duplicating reimbursements. We're looking into it.");
    }
  };

  const renderMoreActionsForUnapprovedReimbursements = () => {
    const actions: TableActionLink[] = [
      {
        label: "Duplicate",
        action: () => duplicateReimbursement(_id),
        icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("create", expenseReimbursement),
      },
      {
        label: "Delete",
        action: async () => {
          await archiveExpenseReimbursementRequests([_id]);
          onApprovalChange(); // refreshes table
          onHide(); // close modal
        },
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("delete", expenseReimbursement),
      },
    ];

    return (
      <DropdownButton
        className={"button-1 table-v2-secondary-action-bar-btn shift-icons no-margin"}
        shiftIcons={true}
        options={actions || []}
        closeOnClick={true}
      >
        <DotsThreeVertical weight="bold" style={{ marginTop: 2 }} />
      </DropdownButton>
    );
  };

  if (!editing) {
    return (
      <>
        <div className={"modal-footer"}>
          {renderMoreActionsForUnapprovedReimbursements()}
          {(can("update", expenseReimbursement) || inboxMode) && (
            <Button className="button-1 tall-button" onClick={toggleEdit} text="Edit" />
          )}
          <div className="flex-1"></div>
          {isUnapprovedAndHasPermissionToApprove && (
            <>
              {/* only show kick back if policy is applied - won't show up in inbox if customer isn't using policies */}
              {expenseReimbursement.approval_stage && (
                <KickBackItemsAction
                  items={[expenseReimbursement]}
                  itemType={"expense_reimbursement"}
                  onKickBack={() => {
                    onApprovalChange();
                    onHide();
                  }}
                />
              )}
              <Button
                className="button-3 tall-button"
                onClick={() => updateStatus("denied")}
                text="Deny"
                loading={isDenying}
                disabled={loading}
              />
              <Button
                className="button-2 tall-button"
                onClick={() => updateStatus("approved")}
                text="Approve"
                loading={isApproving}
                disabled={loading}
              />
            </>
          )}
          {isApprovedOrDeniedAndHasPermissionToApprove && (
            <Button
              className="button-1"
              onClick={() => updateStatus("unapproved")}
              text="Unapprove"
              loading={isUnapproving}
              disabled={loading}
            />
          )}
          {
            // if ACH reimbursement is approved, show the submit button. Disable it if either company or team member doesn't have a valid bank account.
            payout_method === "ach" && status === "approved" && (
              <Button
                className="button-2"
                onClick={submitForReimbursement}
                text="Process ACH to Team Member"
                tooltip={
                  !canSubmitForACHReimbursement
                    ? "Both company and team member must have a verified bank account to pay out this reimbursement."
                    : undefined
                }
                loading={isProcessingACH}
                disabled={!canSubmitForACHReimbursement || !hasPermissionToProcessPayment}
              />
            )
          }
          {payout_method === "ach" && status === "failed" && (
            <Button
              className="button-2"
              onClick={retryReimbursement}
              text="Retry ACH Transfer"
              loading={isProcessingACH}
              disabled={loading || !hasPermissionToProcessPayment}
            />
          )}
        </div>
      </>
    );
  } else {
    // editing mode. Show cancel and save buttons.
    return (
      <div className={"modal-footer"}>
        <Button
          className="button-1"
          onClick={() => {
            toggleEdit();
            onCancelEdit();
          }}
          text="Cancel"
        />
        <div className="flex-1"></div>
        <Button className="button-2" onClick={onSave} text="Save" loading={isSaving} disabled={loading} />
      </div>
    );
  }
};
