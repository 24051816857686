import React, { useMemo, useState } from "react";
import { ActionModal, BulkSelectorModal, TableV2 } from "ui";
import { AggregatedJob, AggregatedTeamMember } from "dashboard/miter";
import { useNavigate } from "react-router-dom";
import { useActiveJobs } from "dashboard/hooks/atom-hooks";
import { useJobColumns } from "dashboard/components/tables/JobsTable";
import { Pencil } from "phosphor-react";
import { CleanedJob, requiredJobAccess, useTeamMemberAccessibleJobs } from "dashboard/utils/jobUtils";

type Props = {
  defaultData: AggregatedTeamMember;
  onHide: () => void;
  onSubmit: (data: $TSFixMe) => Promise<void>;
  submitting: boolean;
};

export const JobInformationForm: React.FC<Props> = ({ defaultData, onHide, onSubmit, submitting }) => {
  const activeJobs = useActiveJobs();
  const navigate = useNavigate();
  const jobsColumns = useJobColumns();

  const [showBulkSelector, setShowBulkSelector] = useState(false);

  const { accessibleJobsPool, accessibleJobs } = useTeamMemberAccessibleJobs(defaultData, { clean: true });

  const accessibleJobsPoolOptions = useMemo(
    () =>
      accessibleJobsPool.map((job) => ({
        label: job.name,
        value: job as CleanedJob<AggregatedJob>,
        locked: requiredJobAccess(job, defaultData)
          ? `${defaultData.first_name} is a supervisor on this job`
          : undefined,
      })),
    [accessibleJobsPool]
  );

  const handleUpdateJobs = async (selectedJobs: CleanedJob<AggregatedJob>[]) => {
    const jobIds = selectedJobs.map((job) => job._id);
    const update =
      jobIds.length === activeJobs.length
        ? { limit_accessible_jobs: false }
        : { limit_accessible_jobs: true, accessible_job_ids: jobIds };

    onSubmit(update);
  };

  const renderJobsTable = () => {
    return (
      <TableV2
        id="jobs-table"
        resource="jobs"
        data={accessibleJobs as CleanedJob<AggregatedJob>[]}
        columns={jobsColumns}
        staticActions={[
          {
            label: "Edit accessible jobs",
            className: "button-2 no-margin table-button",
            action: () => setShowBulkSelector(true),
            important: true,
            icon: <Pencil weight="bold" style={{ marginRight: 5 }} />,
          },
        ]}
        onClick={(job) => navigate(`/jobs/${job._id}`)}
        gridWrapperStyle={{ height: 365, paddingBottom: 0 }}
        containerStyle={{ paddingBottom: 20 }}
      />
    );
  };

  return (
    <ActionModal
      headerText={<div className="flex">Jobs {defaultData.first_name} can clock into</div>}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      onHide={onHide}
      wrapperStyle={{ width: "70%" }}
    >
      {renderJobsTable()}
      {showBulkSelector && (
        <BulkSelectorModal
          options={accessibleJobsPoolOptions}
          selections={accessibleJobs}
          resource="jobs"
          properties={["name", "code"]}
          onSubmit={handleUpdateJobs}
          onCancel={() => setShowBulkSelector(false)}
          submitting={submitting}
          valueKey="_id"
        />
      )}
    </ActionModal>
  );
};
