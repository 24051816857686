import { AggregatedTeamMember, TeamMember } from "dashboard/miter";
import { uniqBy } from "lodash";
import { notNullish } from "miter-utils";
import { useMemo, useCallback } from "react";
import {
  PermissionGroupModuleScopeType,
  PermissionPaths,
  TeamMemberScopeGroup,
} from "backend/models/permission-group";
import {
  useActiveTeamMember,
  useTeam,
  useCrews,
  useLookupTeamMemberCrews,
  useLookupTeam,
  useHydratedPermissionGroups,
} from "../atom-hooks";
import { TeamMemberGroup } from "backend/types";

type ReportType = "direct" | "direct_and_indirect";

export const PERMISSION_GROUP_SCOPE_MODULES: PermissionGroupModuleScopeType[] = [
  "human_resources",
  "workforce_management",
  "expense_management",
  "payroll_and_compliance",
  "company",
];

type TeamMemberScopesHelpers = {
  getTeamMemberPermissions: (
    teamMemberId: string,
    module: PermissionGroupModuleScopeType
  ) => {
    can: (action: PermissionPaths) => boolean;
    cannot: (action: PermissionPaths) => boolean;
  };

  getTeamMembersForPermission: (
    permission: PermissionPaths,
    mod: PermissionGroupModuleScopeType
  ) => AggregatedTeamMember[];

  getTeamMemberIdsForPermission: (
    permission: PermissionPaths,
    mod: PermissionGroupModuleScopeType
  ) => string[];
};

type TeamMemberScopeUnfurler = (
  scopes: TeamMemberScopeGroup[] | TeamMemberGroup[] | null
) => AggregatedTeamMember[];

/**
 * This hook returns a function that takes a list of team member scopes and returns a list of team members that match the scopes
 */
export const useTeamMemberGroupUnfurler = (): TeamMemberScopeUnfurler => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeTeamMember = useActiveTeamMember();
  const teamMemberList = useTeam();
  const crews = useCrews();
  const lookupTMCrew = useLookupTeamMemberCrews();
  const baseLookupTeam = useLookupTeam();

  /**********************************************************************************************
   * Build the lists of reports, direct and indirect
   **********************************************************************************************/
  const reportsMap = useMemo(() => buildDirectReportsMap(teamMemberList), [teamMemberList]);

  const directReports = useMemo(
    () => getDirectAndIndirectReports({ tm: activeTeamMember, type: "direct", reportsMap }),
    [activeTeamMember, reportsMap]
  );

  const directAndIndirectReports = useMemo(
    () => getDirectAndIndirectReports({ tm: activeTeamMember, type: "direct_and_indirect", reportsMap }),
    [activeTeamMember, reportsMap]
  );

  const unfurlGroup = useCallback(
    (scopes: TeamMemberScopeGroup[] | TeamMemberGroup[] | null) => {
      if (!scopes?.length) return teamMemberList;

      const filteredTeamMembers = scopes.flatMap((scope) => {
        switch (scope.type) {
          case "all_team_members":
            return teamMemberList;
          case "employment_type":
            return teamMemberList.filter((tm) => tm.employment_type === scope.value);
          case "pay_type":
            return teamMemberList.filter((tm) => tm.pay_type === scope.value);
          case "team_member":
            return teamMemberList.filter((tm) => tm._id === scope.value);
          case "title":
            return teamMemberList.filter((tm) => tm.title === scope.value);
          case "crew":
            return teamMemberList.filter((tm) => {
              return lookupTMCrew(tm?._id)?.some((crew) => crew._id === scope.value);
            });
          case "department":
            return teamMemberList.filter((tm) => tm.department_id === scope.value);
          case "location":
            return teamMemberList.filter((tm) => tm.location_id === scope.value);
          case "user_department":
            if (!activeTeamMember) return [];
            return teamMemberList.filter((tm) => tm.department_id === activeTeamMember?.department_id);
          case "user_location":
            if (!activeTeamMember) return [];
            return teamMemberList.filter((tm) => tm.location_id === activeTeamMember?.location_id);
          case "direct_reports":
            if (!activeTeamMember) return [];
            return directReports;
          case "direct_and_indirect_reports":
            if (!activeTeamMember) return [];
            return directAndIndirectReports;
          case "crews_managing":
            if (!activeTeamMember) return [];
            return crews
              .filter((crew) => crew.lead_team_member_id === activeTeamMember?._id)
              .flatMap((crew) => crew.team_member_ids.map((tmId) => baseLookupTeam(tmId)))
              .filter(notNullish);
          default:
            return [];
        }
      });

      // // Make sure to always include the active team member so that they appear in every unfurled team member list
      // const activeTM = baseLookupTeam(activeTeamMember._id);

      // // Do a check b/c when switching there is no active team member
      // if (activeTM) filteredTeamMembers.push(activeTM);

      // Return unique team members
      return uniqBy(filteredTeamMembers, "_id");
    },
    [teamMemberList, crews, directReports, directAndIndirectReports, activeTeamMember, baseLookupTeam]
  );

  return unfurlGroup;
};

/**
 *  This hook returns a set of helper functions to work with team member scopes
 */
export const useTeamMemberScopesHelpers = (): TeamMemberScopesHelpers => {
  const teamMemberList = useTeam();
  const hydratedPermissionGroups = useHydratedPermissionGroups();

  const getTeamMemberPermissions = useCallback(
    (teamMemberId: string, mod: PermissionGroupModuleScopeType) => {
      const scopedList = hydratedPermissionGroups
        .filter((item) => item.scopedTeamMembers[mod]?.idsSet.has(teamMemberId))
        .map((item) => item.abilities);

      const can = (action: PermissionPaths): boolean => {
        if (!action) return false;
        return scopedList.some((abilities) => abilities.can(action));
      };

      const cannot = (action: PermissionPaths): boolean => {
        if (!action) return false;
        return scopedList.some((abilities) => abilities.cannot(action));
      };

      return { can, cannot };
    },
    [hydratedPermissionGroups]
  );

  /**
   * Get's the list of team members that we can take an action on
   *
   * @param permission - The permission we want to take
   * @param mod - The module we want to take the action in (e.g. human_resources, workforce_management, etc)
   * @returns {AggregatedTeamMember[]}
   */
  const getTeamMembersForPermission = useCallback(
    (permission: PermissionPaths, mod: PermissionGroupModuleScopeType) => {
      const teamMembers = hydratedPermissionGroups.flatMap((item) => {
        if (item.abilities.can(permission || "")) {
          return item.scopedTeamMembers[mod]?.list || [];
        } else {
          return [];
        }
      });

      return teamMembers;
    },
    [teamMemberList, hydratedPermissionGroups]
  );

  /**
   * Get the ids of the team member we can take an action on
   *
   * @param action - The action we want to take
   * @param mod - The module we want to take the action in (e.g. human_resources, workforce_management, etc)
   * @returns {string[]}
   */
  const getTeamMemberIdsForPermission = useCallback(
    (permission: PermissionPaths, mod: PermissionGroupModuleScopeType) => {
      const teamMembersIds = hydratedPermissionGroups.flatMap((item) => {
        if (item.abilities.can(permission || "")) {
          return item.scopedTeamMembers[mod]?.ids || [];
        } else {
          return [];
        }
      });

      return teamMembersIds;
    },
    [getTeamMembersForPermission]
  );

  return {
    getTeamMemberPermissions,
    getTeamMembersForPermission,
    getTeamMemberIdsForPermission,
  };
};

/** Creating a map of team member IDs to their direct reports  */
const buildDirectReportsMap = (teamMembers: AggregatedTeamMember[]) => {
  return teamMembers.reduce((map, teamMember) => {
    const manager = teamMember.reports_to;
    if (!manager) return map;

    // Append current team member to their manager's report list
    const reports = map[manager._id] || [];
    reports.push(teamMember);
    map[manager._id] = reports;

    return map;
  }, {} as Record<string, AggregatedTeamMember[]>);
};

/** Get all direct and indirect reports for a team member */
const getDirectAndIndirectReports = (params: {
  tm: TeamMember | AggregatedTeamMember | undefined | null;
  type: ReportType;
  reportsMap: Record<string, AggregatedTeamMember[]>;
  viewed?: Set<AggregatedTeamMember>;
}) => {
  const { tm, type, reportsMap, viewed } = params;

  if (!tm) return [];

  const reports: AggregatedTeamMember[] = [];
  const viewedSet = viewed || new Set<AggregatedTeamMember>();
  const directReports = reportsMap[tm._id] || [];

  directReports.forEach((report) => {
    // Base case: if we've already seen this report, don't add it to the list
    if (viewedSet.has(report)) return;

    viewedSet.add(report);
    reports.push(report);

    // If looking for indirect reports, recursively fetch them
    if (type === "direct_and_indirect") {
      const allReports = getDirectAndIndirectReports({
        tm: report,
        type: "direct_and_indirect",
        reportsMap,
        viewed: viewedSet,
      });

      reports.push(...allReports);
    }
  });

  return reports;
};
