import { ColDef, ICellRendererParams } from "ag-grid-community";
import InfoButton from "dashboard/components/information/information";
import AppContext from "dashboard/contexts/app-context";
import {
  useActiveCompany,
  useRefetchWorkplaces,
  useWorkplaceOptions,
  useWorkplaces,
} from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, UpdateTeamMemberParams, Workplace } from "dashboard/miter";
import { addressString, sleep } from "dashboard/utils";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Badge, Button, Formblock, Loader, TableV2 } from "ui";
import { CheckAddress } from "backend/utils/check/check-types";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  tm: AggregatedTeamMember;
  updateTm: (params: UpdateTeamMemberParams) => Promise<void>;
};

type TableRow = Workplace & {
  addressString: string;
  isPrimary: boolean;
  isIgnored: boolean;
};

export const TeamMemberWorkplaces: React.FC<Props> = ({ tm, updateTm }) => {
  const activeCompany = useActiveCompany();
  const allWorkplaces = useWorkplaces();
  const workplaces = useMemo(() => {
    return allWorkplaces.filter(
      (w) => tm.check_tm?.workplaces?.includes(w.check_id) || tm.ignored_workplaces.includes(w.check_id)
    );
  }, [allWorkplaces, tm]);

  const form = useForm();
  const mounted = useRef(false);
  const companyMultiEnabled = !!activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;

  const tmMultiEnabled = !tm.disable_multi_workplace_payrolls && companyMultiEnabled;

  const primaryWorkplace = useMemo(() => {
    const primaryId = tm.check_tm?.primary_workplace;
    return workplaces?.find((w) => w.check_id === primaryId);
  }, [workplaces, tm]);

  const [tmWorkplaceModal, setTmWorkplaceModal] = useState(false);
  const [ignoredWorkplaces, setIgnoredWorkplaces] = useState(tm.ignored_workplaces);
  const [loading, setLoading] = useState(false);

  const updatedIgnoredWorkplacesDebounced = useDebouncedCallback(async (ignored_workplaces: string[]) => {
    await updateTm({ ignored_workplaces });
    setLoading(false);
  }, 1000);

  useEffect(() => {
    if (mounted.current) {
      // Start showing the loading icon before we've necessarily called the underlying debounced function
      setLoading(true);
      updatedIgnoredWorkplacesDebounced(ignoredWorkplaces);
    } else {
      mounted.current = true;
    }
  }, [ignoredWorkplaces]);

  // In case the component unmounts before the debounced function is called
  useEffect(() => {
    return updatedIgnoredWorkplacesDebounced.flush;
  }, []);

  const tableData: TableRow[] = useMemo(() => {
    if (!workplaces) return [];
    return workplaces
      ?.filter((w) => w.check_workplace.active)
      .map((w) => {
        return {
          ...w,
          addressString: addressString(w.check_workplace.address),
          isPrimary: w.check_id === tm.check_tm?.primary_workplace,
          isIgnored: ignoredWorkplaces.includes(w.check_id),
        };
      })
      .sort((a, b) => (a.isPrimary ? -1 : b.isPrimary ? 1 : 0));
  }, [ignoredWorkplaces, tm, workplaces]);

  const handleIgnoreChange = (e, row?: TableRow) => {
    if (!row) return;
    if (e.target.checked) {
      setIgnoredWorkplaces((prev) => {
        return [...prev, row?.check_id];
      });
    } else {
      setIgnoredWorkplaces((prev) => {
        return prev.filter((id) => id !== row?.check_id);
      });
    }
  };

  const colDefs: ColDef[] = [
    {
      field: "addressString",
      headerName: "Address",
      minWidth: 400,
      cellRenderer: (params: ICellRendererParams<TableRow>) => {
        if (!params.data?.isPrimary) return params.data?.addressString;
        return (
          <div className="flex">
            <div>{params.data?.addressString}</div>
            <Badge text="Primary" color="light-blue" />
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Exclude in tax calculations",
      headerTooltip: "Ignore this workplace when calculating taxes",
      cellStyle: { zIndex: 15, overflow: "visible" },
      cellRenderer: (params: ICellRendererParams<TableRow>) => {
        if (!params.data) return null;
        if (params.data?.isPrimary) return null;
        return (
          <input
            type="checkbox"
            defaultChecked={params.data?.isIgnored}
            onChange={(e) => handleIgnoreChange(e, params.data)}
          />
        );
      },
    },
  ];

  return !workplaces ? (
    <Loader />
  ) : (
    <div>
      {tmWorkplaceModal && (
        <TeamMemberWorkplaceModal
          tm={tm}
          hide={() => setTmWorkplaceModal(false)}
          primaryWorkplace={primaryWorkplace}
          updateTm={updateTm}
        />
      )}
      <div className="billing-card-wrapper">
        <div className="flex">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Workplace settings</div>
          <div className="flex-1"></div>
          <Button text="Edit" onClick={() => setTmWorkplaceModal(true)} className="button-1" />
        </div>
        <div className="vertical-spacer"></div>
        <Formblock
          defaultValue={addressString(primaryWorkplace?.check_workplace.address) || ""}
          form={form}
          name="primary_workplace"
          type="text"
          style={{ marginBottom: 0 }}
          label="Primary workplace"
          labelInfo={`${tm.full_name}'s default workplace and tax location`}
        >
          <>
            {primaryWorkplace ? (
              <div>{addressString(primaryWorkplace?.check_workplace.address) || ""}</div>
            ) : (
              <Loader className="small-text" />
            )}
          </>
        </Formblock>
        {companyMultiEnabled && (
          <Formblock
            form={form}
            name="job_based_taxes"
            type="text"
            labelInfo={`If enabled, ${tm.full_name} will be taxed based on the locations of the jobs they work on.`}
            style={{ alignItems: "center" }}
            label="Job-based taxes"
          >
            <Badge
              text={tmMultiEnabled ? "Enabled" : "Disabled"}
              color={tmMultiEnabled ? "green" : "gray"}
              style={{ margin: 0 }}
            />
          </Formblock>
        )}
      </div>
      {tmMultiEnabled && (
        <div className="billing-card-wrapper" style={{ height: "auto" }}>
          <div className="flex">
            <div style={{ fontWeight: 600, fontSize: 18 }}>Active workplaces</div>
            <InfoButton
              text={`In Miter, Workplaces represent distinct tax jurisdictions used to calculate payroll taxes.`}
            />
            <div className="flex-1" />
            {loading && <Loader className="small-text" />}
          </div>
          <TableV2
            data={tableData}
            columns={colDefs}
            id="workplaces"
            resource="workplaces"
            hideSecondaryActions={true}
          />
        </div>
      )}
    </div>
  );
};

type ModalProps = {
  hide: () => void;
  tm: AggregatedTeamMember;
  primaryWorkplace?: Workplace;
  updateTm: (params: UpdateTeamMemberParams) => Promise<void>;
};

const TeamMemberWorkplaceModal: React.FC<ModalProps> = ({ tm, hide, primaryWorkplace, updateTm }) => {
  const { fetchUserData } = useContext(AppContext);
  const form = useForm();
  const [loading, setLoading] = useState(false);

  const refetchWorkplaces = useRefetchWorkplaces();

  const doSubmit = async (data) => {
    setLoading(true);
    const cleanedFormAddress = {
      ...data.workplace_address,
      line2: data.workplace_address?.line2 || undefined,
      state: data.workplace_address?.state?.value,
    };
    const params: {
      disable_multi_workplace_payrolls?: boolean;
      workplace?: string;
      workplace_address?: CheckAddress;
      workplace_type?: "company" | "custom";
    } = {
      disable_multi_workplace_payrolls: companyMultiEnabled ? tmMultiEnabled === "false" : undefined,
    };
    if (chosenWorkplaceType === "existing") {
      params.workplace = workplaceId;
    } else {
      params.workplace_address = cleanedFormAddress;
      params.workplace_type = "custom";
    }
    await updateTm(params);
    refetchWorkplaces();

    // The company onboard status might change depending on the submitted workplace change, so let's give 10 seconds for Check to send the relevant "company updated" webhook and for us to process it
    sleep(1000 * 10).then(fetchUserData);

    hide();
    setLoading(false);
  };

  const [workplaceId, setWorkplaceId] = useState(tm.workplace._id);
  const [chosenWorkplaceType, setChosenWorkplaceType] = useState<"existing" | "new">("existing");

  const workplaceOptions = useWorkplaceOptions({
    includeCustomOption: { label: "+ Create new workplace", value: "" },
  });

  const activeCompany = useActiveCompany();
  const companyMultiEnabled = activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;

  const [tmMultiEnabled, setTmMultiEnabled] = useState(
    !tm.disable_multi_workplace_payrolls && companyMultiEnabled ? "true" : "false"
  );

  const multiWorkplaceOptions = [
    { label: "Enabled", value: "true" },
    { label: "Disabled", value: "false" },
  ];

  return (
    <ActionModal
      headerText={`Edit ${tm.first_name}'s workplace`}
      onCancel={hide}
      onHide={hide}
      onSubmit={form.handleSubmit(doSubmit)}
      loading={loading}
      submitText="Save"
      cancelText="Cancel"
      showSubmit={true}
      showCancel={true}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Default workplace"
        name="workplace_type"
        type="select"
        form={form}
        className="modal"
        editing={true}
        value={workplaceOptions.find((o) => o.value === workplaceId)}
        options={workplaceOptions}
        onChange={(o) => {
          setWorkplaceId(o.value);
          setChosenWorkplaceType(!o.value ? "new" : "existing");
        }}
      />
      {!workplaceId && (
        <div>
          <Formblock
            type="address"
            label="Create new workplace"
            name="workplace_address"
            form={form}
            defaultValue={primaryWorkplace?.check_workplace?.address}
            className="modal small-margin"
            editing={true}
            shortStateSelect={true}
          />
        </div>
      )}
      {companyMultiEnabled && (
        <Formblock
          label="Job-based taxes"
          name="workplace_type"
          labelInfo={`If enabled, ${tm.full_name} will be taxed based on the locations of the jobs they work on.`}
          type="select"
          form={form}
          className="modal"
          editing={true}
          value={multiWorkplaceOptions.find((o) => o.value === tmMultiEnabled)}
          options={multiWorkplaceOptions}
          onChange={(o) => setTmMultiEnabled(o.value)}
        />
      )}
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};
