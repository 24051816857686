import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Formblock } from "ui";
import { Notifier } from "dashboard/utils";
import {
  AgGridEvent,
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ToolPanelDef,
} from "ag-grid-community";
import { useReactToPrint } from "react-to-print";
import { useForm, UseFormMethods } from "react-hook-form";
import { DateRange } from "ui/form/DateRangePicker";
import { DateTime } from "luxon";
import { DropdownItem } from "ui/button/Button";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./AgGridTable.css";
import { verticalCenteredCellStyle, identityAggFunc } from "./agGridUtils";
import { ReportView } from "dashboard/miter";
import { isEqual } from "lodash";
import { sumValues } from "dashboard/pages/reports/reportUtils";
import ReportViewSelect, { CreateReportViewParams } from "ui/table-v2/report-view/ReportViewSelect";
import { AgGridCheckboxRenderer } from "ui/table-v2/AgGridCheckboxRenderer";
import { AgGridSelectEditor } from "ui/table-v2/AgGridSelectEditor";
import { AgGridDateEditor } from "ui/table-v2/AgGridDateEditor";

type SelectedReportView = ReportView | CreateReportViewParams | null | undefined;
type Props = {
  reportId?: string;
  loading?: boolean;
  data?: $TSFixMe[];
  columnDefs: (ColDef | ColGroupDef)[];
  showSelectedActionButtons?: boolean;
  selectedActionButtons?: React.FC<{}>;
  defaultActionButtons?: React.FC<{}>;
  actionButtonsLocation?: "right" | "left";
  reportViewLocation?: "right" | "left";
  defaultColDef?: Partial<ColDef>;
  alternativeCsvDownload?: () => void;
  form?: UseFormMethods;
  dateRange?: DateRange;
  supplementalTableActions?: DropdownItem[];
  actionButtonLoading?: boolean;
  onDateRangeChange?: (range: DateRange) => void;
  setGridApi?: (gridApi: GridApi | undefined) => void;
  setColumnApi?: (columnApi: ColumnApi | undefined) => void;
  renderRowActions?: (p: ICellRendererParams) => React.ReactElement;
  gridOptions?: GridOptions;
  fileName?: string;
  toolPanels?: ToolPanelDef[];
  containerStyle?: React.CSSProperties;
  hideSidebar?: boolean;
  csvExportParams?: {
    fileName?: string;
    skipRowGroups?: boolean;
    skipColumnGroupHeaders?: boolean;
    allColumns?: boolean;
  };
  rowActionButtonsWidth?: number;
  searchPlaceholder?: string;
  searchValue?: string;
  onSearch?: (e: string) => void;
  gridHeight?: number | string;
  hideDownloadCSV?: boolean;
  dateRangeLabel?: string;
  wrapperClassName?: string;
  renderActionBarLeft?: () => React.ReactElement;
  disableRange?: boolean;
};

const components = {
  reactSelectEditor: AgGridSelectEditor,
  checkboxRenderer: AgGridCheckboxRenderer,
  datePickerEditor: AgGridDateEditor,
};

export const AgGridTable: React.FC<Props> = ({
  reportId,
  data,
  columnDefs,
  loading,
  gridOptions,
  showSelectedActionButtons,
  selectedActionButtons,
  defaultActionButtons,
  dateRangeLabel,
  supplementalTableActions,
  actionButtonLoading,
  actionButtonsLocation,
  reportViewLocation,
  form,
  dateRange,
  onDateRangeChange,
  alternativeCsvDownload,
  containerStyle,
  setGridApi,
  setColumnApi,
  gridHeight,
  renderRowActions,
  hideSidebar,
  toolPanels,
  defaultColDef,
  fileName,
  rowActionButtonsWidth,
  searchValue,
  csvExportParams,
  searchPlaceholder,
  onSearch,
  hideDownloadCSV,
  wrapperClassName,
  renderActionBarLeft,
  disableRange,
}) => {
  const [localGridApi, setLocalGridApi] = useState<GridApi | undefined>();
  const [localColumnApi, setLocalColumnApi] = useState<ColumnApi | undefined>();
  const [downloading, setDownloading] = useState(false);
  const defaultForm = useForm();
  const [selectedReportView, setSelectedReportView] = useState<SelectedReportView>();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [expandedAll, setExpandedAll] = useState(false);
  const [rendering, setRendering] = useState(false);

  form = form || defaultForm;

  const tableRef = useRef<HTMLDivElement>(null);

  const reactPrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const dataIds = useMemo(() => data?.map((d) => d._id), [data]);

  useEffect(() => {
    function handleResize() {
      if (localGridApi) localGridApi.sizeColumnsToFit();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  // Anytime the data or selected report view changes
  useEffect(() => {
    if (selectedReportView?.config && localGridApi && localColumnApi && data) {
      // IF the selected report view exists, set AgGrid to use that's report views configuration.
      handleAgGridRendered({ api: localGridApi, columnApi: localColumnApi } as AgGridEvent<$TSFixMe>);

      // ELSE IF the selected report view is null, reset AgGrid's columns to the default columns.
    } else if (!selectedReportView?.config && localGridApi && localColumnApi && data) {
      localColumnApi.resetColumnState();
      localGridApi.setFilterModel(null);
    }
  }, [JSON.stringify(selectedReportView), dataIds, !!localGridApi, !!localColumnApi]);

  // Need to clear out the grid and column APIs that have been passed in to prevent a memory leak
  useEffect(() => {
    return () => {
      setGridApi?.(undefined);
      setColumnApi?.(undefined);
    };
  }, []);

  const finalColDefs = renderRowActions
    ? columnDefs.concat({
        headerName: " ",
        width: rowActionButtonsWidth,
        cellRenderer: renderRowActions,
        cellStyle: { overflow: "visible", ...verticalCenteredCellStyle },
        aggFunc: "identityAggFunc",
      })
    : columnDefs;

  const handleViewSelect = (selectedView: SelectedReportView) => {
    setSelectedReportView(selectedView);
    setShowSaveButton(false);
  };

  const handleViewSave = () => {
    setShowSaveButton(false);
  };

  const handleDownloadCSV = async () => {
    setDownloading(true);
    try {
      if (alternativeCsvDownload) {
        alternativeCsvDownload();
      } else {
        await localGridApi?.exportDataAsCsv({
          fileName,
          exportedRows: "filteredAndSorted" as const,
          ...csvExportParams,
        });
      }
      Notifier.success("Your report was successfully generated");
    } catch (e) {
      Notifier.error("There was an error downloading the CSV. We're looking into it!");
    }
    setDownloading(false);
  };

  const onGridReady = (params) => {
    setLocalGridApi(params.api);
    setLocalColumnApi(params.columnApi);
    setGridApi?.(params.api);
    setColumnApi?.(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  /** Handler used to expand all table groups */
  const handleExpandAll = () => {
    if (expandedAll) {
      localGridApi?.collapseAll();
      setExpandedAll(false);
    } else {
      localGridApi?.expandAll();
      setExpandedAll(true);
    }
  };

  const handlePrint = () => {
    const eGridDiv = document.getElementById("#miterGrid");
    if (eGridDiv) {
      eGridDiv.setAttribute("height", "");
      eGridDiv.style.height = "";
      eGridDiv.style.width = "500px";
    }
    localGridApi?.setDomLayout("print");
    localGridApi?.sizeColumnsToFit();
    setTimeout(function () {
      reactPrint();
      localGridApi?.setDomLayout();
    }, 1000);
  };

  useEffect(() => {
    if (localGridApi) {
      if (loading) {
        localGridApi.showLoadingOverlay();
      } else {
        localGridApi.hideOverlay();
      }
    }
  }, [loading]);

  const handleAgGridChanged = <T,>(event: AgGridEvent<T>) => {
    if (rendering) return;
    const filterState = event.api.getFilterModel();
    const columnState = event.columnApi.getColumnState();
    const groupState = event.columnApi.getColumnGroupState();
    const pivotEnabled = event.columnApi.isPivotMode();

    const config = {
      filters: filterState || {},
      columns: columnState,
      group: groupState,
      pivot_enabled: pivotEnabled,
    };

    if (selectedReportView?.config) {
      const previousConfig = {
        ...selectedReportView?.config,
        columns: selectedReportView.config.columns.map((c) => ({ ...c, width: undefined })),
        filters: selectedReportView.config.filters || {},
      };

      const currentConfig = {
        ...config,
        columns: config.columns.map((c) => ({ ...c, width: undefined })),
        filters: config.filters || {},
      };

      if (!isEqual(previousConfig, currentConfig)) {
        setShowSaveButton(true);
      }
    }

    setSelectedReportView({
      ...(selectedReportView as CreateReportViewParams | ReportView),
      config,
    });
  };

  const handleAgGridRendered = (params: GridReadyEvent) => {
    setRendering(true);
    if (!selectedReportView) return;

    const columnState = selectedReportView?.config?.columns;
    const columnGroupState = selectedReportView?.config?.group;
    const filterModel = selectedReportView?.config?.filters;
    const pivotEnabled = selectedReportView?.config?.pivot_enabled;
    if (columnState) {
      params.columnApi.applyColumnState({ state: columnState, applyOrder: true });
    }
    if (columnGroupState) {
      params.columnApi.setColumnGroupState(columnGroupState);
    }
    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }
    if (pivotEnabled) {
      params.columnApi.setPivotMode(true);
    }
    setTimeout(() => setRendering(false), 0);
  };

  // List out Miter's custom aggregation functions to make them available to the grid
  const aggFuncs = {
    sumValues: (params) => sumValues(params),
    identityAggFunc: (params) => identityAggFunc(params),
  };

  const tableActions = [
    { text: "Print", onClick: handlePrint },
    { text: "Download CSV", onClick: handleDownloadCSV },
    { text: expandedAll ? "Collapse rows" : "Expand rows", onClick: handleExpandAll },
    ...(supplementalTableActions ? [...supplementalTableActions] : []),
  ];

  const reportViewOptions = {
    onSortChanged: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onSortChanged?.(params);
    },
    onFilterChanged: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onFilterChanged?.(params);
    },
    onColumnMoved: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onColumnMoved?.(params);
    },
    onColumnVisible: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onColumnVisible?.(params);
    },
    onColumnValueChanged: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onColumnValueChanged?.(params);
    },
    onColumnPivotChanged: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onColumnPivotChanged?.(params);
    },
    onColumnGroupOpened: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onColumnGroupOpened?.(params);
    },
    onColumnRowGroupChanged: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onColumnRowGroupChanged?.(params);
    },
    onGridColumnsChanged: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onGridColumnsChanged?.(params);
    },
    onColumnPivotModeChanged: (params) => {
      handleAgGridChanged(params);
      gridOptions?.onColumnPivotModeChanged?.(params);
    },
    onFirstDataRendered: (params) => {
      handleAgGridRendered(params);
      gridOptions?.onFirstDataRendered?.(params);
    },
    aggFuncs: {
      ...aggFuncs,
      ...gridOptions?.aggFuncs,
    },
  };

  const renderDateRange = () => {
    return (
      dateRange &&
      form &&
      onDateRangeChange && (
        <div>
          <Formblock
            register={form.register}
            defaultValue={dateRange}
            value={dateRange}
            control={form.control}
            type="daterange"
            name="range"
            max={DateTime.now()}
            labelStyle={{ maxWidth: 100, fontFamily: "Karla", fontSize: 15 }}
            errors={form.errors}
            style={{ alignItems: "center" }}
            editing={true}
            onChange={onDateRangeChange}
            label={dateRangeLabel}
            inputProps={{ style: { maxWidth: 250, fontFamily: "Karla" } }}
            disabled={disableRange}
          />
        </div>
      )
    );
  };

  const renderReportViewDropdown = () => {
    return (
      reportId &&
      localGridApi && (
        <ReportViewSelect
          reportId={reportId}
          value={selectedReportView}
          onSelect={handleViewSelect}
          onSave={handleViewSave}
          showSaveButton={showSaveButton}
        />
      )
    );
  };

  const renderSearchAndButtons = () => {
    return showSelectedActionButtons && selectedActionButtons ? (
      // div with minHeight of 42px below keeps the table in the same location when rows are selected and search is present
      <>
        {onSearch && <div style={{ minHeight: "42px" }} />}
        {selectedActionButtons({ children: undefined })}
      </>
    ) : (
      <div className="flex" style={{ marginLeft: 5 }}>
        {onSearch && (
          <input
            className="table-search-input pw-input"
            onChange={(e) => onSearch(e.target.value)}
            placeholder={searchPlaceholder}
            value={searchValue}
            type="text"
            style={{ width: 250, fontSize: 14, fontFamily: "Karla", margin: "5px 15px 5px 0px" }}
          />
        )}
        {defaultActionButtons?.({})}
        {!hideDownloadCSV && !!tableActions?.length && (
          <Button
            text="Actions"
            loading={downloading || actionButtonLoading}
            dropdownItems={tableActions}
            className="button-1 no-margin"
            style={{ marginLeft: 20 }}
          />
        )}
      </div>
    );
  };

  const sideBar = useMemo(() => {
    if (hideSidebar) return;

    return {
      toolPanels: toolPanels || [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressValues: false,
            suppressPivots: false,
            suppressPivotMode: false,
            suppressRowGroups: false,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
    };
  }, [hideSidebar, toolPanels]);

  const finalDefaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 150,
      sortable: true,
      resizable: true,
      ...defaultColDef,
    }),
    [defaultColDef]
  );

  return data ? (
    <div
      id="miterGrid"
      ref={tableRef}
      className={"ag-theme-alpine ag-theme-miter " + wrapperClassName}
      style={{ height: gridHeight, minHeight: gridHeight || 500, marginBottom: 50, ...containerStyle }}
    >
      <div className="flex space-between" style={{ marginBottom: 10 }}>
        <div className="flex">
          {renderActionBarLeft?.()}
          {renderDateRange()}
          {reportViewLocation === "left" && renderReportViewDropdown()}
          {actionButtonsLocation === "left" && renderSearchAndButtons()}
        </div>
        <div className="flex">
          {reportViewLocation !== "left" && renderReportViewDropdown()}
          {actionButtonsLocation !== "left" && renderSearchAndButtons()}
        </div>
      </div>
      <div></div>
      <AgGridReact
        rowData={data}
        columnDefs={finalColDefs}
        animateRows={true}
        defaultColDef={finalDefaultColDef}
        rowClass="thisclass"
        sideBar={sideBar}
        suppressAggFuncInHeader={true}
        containerStyle={finalContainerStyle}
        components={components}
        enterNavigatesVerticallyAfterEdit={true}
        {...gridOptions}
        {...reportViewOptions}
        onGridReady={(params) => {
          // Call the default onGridReady function
          onGridReady(params);
          // Call the onGridReady function passed in as a prop
          gridOptions?.onGridReady?.(params);
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const finalContainerStyle = { overflow: "visible" };
