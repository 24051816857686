import React, { useContext, useEffect, useMemo, useState } from "react";
import PayrollContext from "../payrollContext";
import { formatDate, isPastDue, payrollTypeLookup, shorten } from "dashboard/utils/utils";
import { Badge, BasicModal, Button, Toggler } from "ui";
import EditIcon from "dashboard/assets/edit.png";
import { SetNewPayday } from "../SetNewPayday";
import { PreviewPayroll } from "./PreviewPayroll";
import ApprovePayrollModal from "./ApprovePayrollModal";
import PastDueAlert from "./PastDueAlert";
import OffCycleOptions from "./OffCycleOptions";
import Notifier from "dashboard/utils/notifier";
import ReviewTimeOff from "../ReviewTimeOff";
import rightArrow from "dashboard/assets/right-arrow-white.png";
import { Helmet } from "react-helmet";
import { DateTime } from "luxon";
import { GridApi } from "ag-grid-community";
import PaymentsTable from "../PaymentsTable";
import ReviewExpenses from "../ReviewExpenses";
import { HoursByPayPeriodTable } from "dashboard/pages/reports/reportPages/HoursByPayPeriodTable";
import { DraftPayrollTimesheetsTable } from "./DraftPayrollTimesheetsTable";
import { usePayrollReadiness } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useNavigate, useParams } from "react-router-dom";
import { MAX_PAYROLL_PAYMENTS, cleanPayScheduleLabel } from "../viewPayrollUtils";
import { EditPayrollLabelModal } from "../EditPayrollLabelModal";
import { UnapprovedTimesheetPayrollWarning } from "../warnings/UnapprovedTimesheetPayrollWarning";
import { PayRateGroupWeightedAverageOvertimeWarning } from "../warnings/PayRateGroupWeightedAverageOvertimeWarning";

const DraftPayroll: React.FC = () => {
  // Hooks
  const { payroll, getPayroll, stdTogglerItems, aggEarnings, isLoading, isPreviewing, setIsPreviewing } =
    useContext(PayrollContext);

  const payrollReadiness = usePayrollReadiness();
  const { can } = useMiterAbilities();
  const navigate = useNavigate();
  const { view } = useParams<{ view: string }>();
  const activeTab = view;

  // State
  const [dismissedPaymentLimitWarning, setDismissedPaymentLimitWarning] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [hasDismissedPastDueNotice, setHasDismissedPastDueNotice] = useState(false);
  const [selectingNewPayday, setSelectingNewPayday] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [editingLabel, setEditingLabel] = useState(false);
  const [workweekGridApi, setWorkweekGridApi] = useState<GridApi | undefined>();
  const psLabel = payroll?.pay_schedule?.label || "Unknown";

  const payrollIsPastDue = useMemo(() => isPastDue(payroll), [payroll]);

  const disableApproval = !payrollReadiness?.ready;

  const buildApprovalDisableTooltip = () => {
    if (!payrollReadiness?.ready) {
      return "You are unable to approve payroll at this time. Ensure you have completed all onboarding steps and have no outstanding invoices.";
    }
  };

  const handleApprove = () => {
    const ans = buildApprovalDisableTooltip();
    if (ans) {
      Notifier.error(ans);
    } else {
      setIsApproving(true);
    }
  };

  const positivePaymentCount = useMemo(() => {
    let count = 0;
    for (const mp of payroll?.miter_payments || []) {
      let tot = 0;
      for (const me of [...mp.miter_earnings, ...mp.miter_reimbursements]) {
        tot += me.amount;
      }
      if (tot > 0) count++;
    }
    return count;
  }, [payroll]);

  const handleToggle = (path: string) => {
    navigate("/payrolls/" + payroll!._id + "/" + path, { replace: true });
  };

  useEffect(() => {
    if (view && stdTogglerItems.some((i) => i.path === view)) return;
    handleToggle("payments");
  }, []);

  const continueToPreview = async () => {
    if (!positivePaymentCount) {
      Notifier.error("Cannot preview payroll without any positive payments");
      return;
    }

    setPreviewing(true);
    await getPayroll({ preview: "true" });
    setPreviewing(false);
  };

  if (!payroll) return null;

  const check_payroll = payroll.check_payroll;

  const header = `${isPreviewing ? "Approve" : "Run"} ${
    payroll.type === "off_cycle" ? `off-cycle ` : ""
  }payroll for ${formatDate(check_payroll.period_start, check_payroll.period_end)}`;

  const deadline_string = check_payroll.approval_deadline
    ? DateTime.fromISO(check_payroll.approval_deadline).toFormat("ha ZZZZ, MMM d")
    : "N/A";

  const renderPaymentsTable = () => {
    return (
      <div>
        <div className="vertical-spacer-small"></div>
        <PaymentsTable editing={can("payrolls:update")} />
      </div>
    );
  };

  const escapePreview = () => {
    setIsPreviewing(false);
    handleToggle("payments");
  };

  const renderPaymentLimitWarningText = () => {
    let message = `A single payroll cannot have more than ${MAX_PAYROLL_PAYMENTS} non-zero payments. You will not be able to preview or approve this payroll as-is.`;
    if (payroll.type === "off_cycle") {
      message += " Please split this payroll across two or more off-cycles.";
    } else {
      message += " Please split this payroll's set of team members across two or more pay schedules.";
    }
    return message;
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>
          Run {payrollTypeLookup[payroll?.type]?.toLowerCase()} payroll for&nbsp;
          {formatDate(check_payroll.period_start, check_payroll.period_end)} | Miter
        </title>
      </Helmet>
      {positivePaymentCount > MAX_PAYROLL_PAYMENTS && !dismissedPaymentLimitWarning && (
        <BasicModal
          headerText="Too many payments"
          button2Text="Okay"
          button2Action={() => setDismissedPaymentLimitWarning(true)}
        >
          <div className="red-text-container">{renderPaymentLimitWarningText()}</div>
        </BasicModal>
      )}
      {selectingNewPayday && <SetNewPayday hide={() => setSelectingNewPayday(false)} />}
      {isApproving && (
        <ApprovePayrollModal hide={() => setIsApproving(false)} onEscapePreview={escapePreview} />
      )}
      {editingLabel && <EditPayrollLabelModal setEditingLabel={setEditingLabel} />}
      <div className="flex">
        <div>
          <div className="flex">
            <h1>{header}</h1>
            {payrollIsPastDue && <Badge text="PAST DUE" color="red" />}
          </div>
          <div className="pay-period-header">
            <div className="flex pp-item">
              {payroll.pay_schedule_id ? (
                <>
                  <span>{"Pay schedule: "}</span>&nbsp;
                  <span>{shorten(cleanPayScheduleLabel(psLabel), 25)}</span>
                </>
              ) : (
                <>
                  <span>{"Off-cycle: " + (payroll.label || "(blank)")}</span>
                  {can("payrolls:update") && (
                    <img
                      className="formblock-edit-icon"
                      src={EditIcon}
                      onClick={() => setEditingLabel(true)}
                    />
                  )}
                </>
              )}
            </div>
            |
            <div className="flex pp-item">
              <span>{"Deadline: "}</span>&nbsp;
              <span>{deadline_string}</span>
            </div>
            |
            <div className="flex pp-item">
              <span>{"Payday: "}</span>&nbsp;
              <span>{formatDate(check_payroll.payday)}</span>
              {can("payrolls:update") && (
                <img
                  className="formblock-edit-icon"
                  src={EditIcon}
                  onClick={() => setSelectingNewPayday(true)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
        <div className="flex">
          {isPreviewing && (
            <div className="small-gray-link" onClick={escapePreview}>
              Back
            </div>
          )}
          <div style={{ width: 13 }}></div>
          {can("payrolls:approve") && (
            <Button
              disabled={isLoading || (isPreviewing && disableApproval)}
              loading={previewing}
              onClick={isPreviewing ? handleApprove : continueToPreview}
              className="button-2 no-margin large"
              tooltip={isPreviewing ? buildApprovalDisableTooltip() : undefined}
            >
              <div className="flex">
                <div>{isPreviewing ? "Approve payroll" : "Continue"}</div>
                {!isPreviewing && <img src={rightArrow} style={{ height: 17, marginLeft: 10 }} />}
              </div>
            </Button>
          )}
        </div>
      </div>
      {isPreviewing ? (
        <>
          <UnapprovedTimesheetPayrollWarning />
          <PayRateGroupWeightedAverageOvertimeWarning />
          <div className="vertical-spacer-large"></div>
          <PreviewPayroll />
        </>
      ) : (
        <>
          {payrollIsPastDue && !hasDismissedPastDueNotice && (
            <PastDueAlert hide={() => setHasDismissedPastDueNotice(true)} />
          )}
          <Toggler config={stdTogglerItems} active={activeTab} toggle={handleToggle} />
          <div className="vertical-spacer-small"></div>
          <div>
            {activeTab === "payments" && renderPaymentsTable()}
            {activeTab === "timesheets" && <DraftPayrollTimesheetsTable />}
            {activeTab === "time_off" && (
              <ReviewTimeOff emptyHeader="This payroll has no time off requests." />
            )}
            {activeTab === "reimbursements" && <ReviewExpenses viewOnly={false} />}
            {activeTab === "settings" && <OffCycleOptions readOnly={false} />}
            {activeTab === "workweek" && (
              <HoursByPayPeriodTable
                periodStart={payroll?.check_payroll.period_start}
                periodEnd={payroll?.check_payroll.period_end}
                payroll={payroll}
                earnings={aggEarnings}
                editing={true}
                gridApi={workweekGridApi}
                setGridApi={setWorkweekGridApi}
                initialViewType="byDay"
                payScheduleId={payroll.pay_schedule_id}
              />
            )}
          </div>
          <div className="vertical-spacer-large"></div>
        </>
      )}
    </div>
  );
};

export default DraftPayroll;
