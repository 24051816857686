import {
  useActiveCompanyId,
  useLookupCertificationTypes,
  useLookupTeam,
  usePermissionGroups,
} from "dashboard/hooks/atom-hooks";
import { FC, useCallback, useMemo, useState } from "react";
import { Button, DeleteModal, Notifier, TableV2, Wizard } from "ui";
import { ForageRequest } from "backend/utils/forage/forage-types";
import { useCertificationAbilities } from "dashboard/hooks/abilities-hooks/useCertificationAbilities";
import { MiterAPI } from "dashboard/miter";
import { ColumnConfig } from "ui/table-v2/Table";
import { AggregatedCertification } from "dashboard/types/certification-types";
import { PencilSimple, Plus } from "phosphor-react";
import { UploadCertificationModal } from "./UploadCertificationModal";
import { RequestCertificationsModal } from "./RequestCertificationsModal";
import { RequestCertificationsForTM } from "./RequestCertificationsForTM";
import { UploadCertificationForTMModal } from "./UploadCertificationForTMModal";
import { UploadCertificationWizardScreen } from "miter-components";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { notNullish, renderCustomFieldDefaultString, useEnhancedSearchParams } from "miter-utils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { FilePickerFile } from "ui/form/FilePicker";
import Toggler, { TogglerConfigItem } from "ui/toggler/Toggler";
import { capitalize } from "lodash";
import { useFileDownload } from "dashboard/hooks/useFileDownload";
import { userHasPermission } from "dashboard/utils/permission-groups";

type Props = {
  certificationTypeId?: string;
  teamMemberId?: string;
};

const colors = {
  expired: "light-red",
  active: "light-green",
  missing: "orange",
};

export const CertificationsTable: FC<Props> = ({ certificationTypeId, teamMemberId }) => {
  const lookupCertificationType = useLookupCertificationTypes();

  const activeCompanyId = useActiveCompanyId();
  const certificationAbilities = useCertificationAbilities();
  const [modalDisplay, setModalDisplay] = useState<"request" | "upload" | "edit" | "delete" | null>(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState<AggregatedCertification>();
  const lookupTeam = useLookupTeam();
  const selectedTeamMember = lookupTeam(selectedCertification?.team_member_id);
  const selectedCertificationType = lookupCertificationType(selectedCertification?.certification_type_id);
  const teamMember = lookupTeam(teamMemberId);
  const certificationType = lookupCertificationType(certificationTypeId);
  const [selectedCertifications, setSelectedCertifications] = useState<AggregatedCertification[]>([]);
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const abilities = useMiterAbilities();
  const permissionGroups = usePermissionGroups();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const { status } = parsedSearchParams;
  const handleFileClick = useFileDownload();

  const paths = ["active", "missing", "expired"];
  const tsConfig: TogglerConfigItem[] = paths.map((v) => ({
    label: capitalize(v),
    path: v,
  }));

  const renderCertificationsToggler = () => {
    return (
      <Toggler
        active={status || "active"}
        toggle={(newView: string) => setSearchParams({ status: newView })}
        config={tsConfig}
        secondary={true}
      />
    );
  };

  const getData = useCallback(
    async (query: ForageRequest) => {
      const filter = [
        ...(query.filter || []),
        { field: "company_id", value: activeCompanyId },
        ...(teamMemberId ? [{ field: "team_member_id", value: teamMemberId }] : []),
        ...(certificationTypeId ? [{ field: "certification_type_id", value: certificationTypeId }] : []),
        { field: "status", value: status || "active" },
      ];

      // Add a filter based on the permissions if a filter exists
      const abilitiesFilter = certificationAbilities.filter("read");
      if (abilitiesFilter) filter.push(abilitiesFilter);

      return await MiterAPI.certifications.forage({
        ...query,
        filter,
        select: undefined,
      });
    },
    [activeCompanyId, status]
  );

  const staticActions = [
    {
      label: "Request certification",
      className: "button-2 table-button",
      action: () => setModalDisplay("request"),
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      important: true,
      shouldShow: () => {
        return abilities.can("certifications:certifications:request");
      },
    },

    {
      label: "Upload certification",
      className: "button-1 table-button",
      action: () => setModalDisplay("upload"),
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      important: true,
      shouldShow: () => {
        return abilities.can("certifications:certifications:upload");
      },
    },
  ];

  const archiveCertifications = async () => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const res = await MiterAPI.certifications.archive({
        ids: selectedCertifications.map((cert) => cert._id),
        company_id: activeCompanyId,
      });
      if (res.error) throw res.error;
      if (res.failures.length > 0) {
        const failures = res.failures
          .map((f) => {
            const foundCertification = selectedCertifications.find((cert) => cert._id === f.id);
            const foundTeamMember = foundCertification ? lookupTeam(foundCertification.team_member_id) : null;
            if (!foundTeamMember || !foundCertification) return null;

            const label = `${foundTeamMember.full_name} - ${foundCertification.certification_type.title}`;
            return {
              label: label,
              message: f.reason,
            };
          })
          .filter(notNullish);

        setFailures(failures);
        throw new Error(`Failed to delete ${res.failures.length} certifications.`);
      }
      Notifier.success("Successfully deleted certifications.");
    } catch (e: $TSFixMe) {
      Notifier.error("Failed to delete certifications: " + e.message);
    }
    setLoading(false);
    closeModal();
  };

  const dynamicActions = [
    {
      label: "Delete",
      action: () => setModalDisplay("delete"),
      shouldShow: () => certificationAbilities.can("delete", selectedCertifications),
      className: "button-3 table-button",
    },
  ];

  const columns: ColumnConfig<AggregatedCertification>[] = useMemo(() => {
    const cols = [
      ...(!teamMemberId
        ? [
            {
              field: "team_member.full_name",
              headerName: "Team member",
              width: 250,
              dataType: "string" as const,
              pinned: "left" as const,
            },
          ]
        : []),
      ...(!certificationTypeId
        ? [
            {
              field: "certification_type.title",
              headerName: "Certification",
              width: 250,
              dataType: "string" as const,
              pinned: "left" as const,
            },
          ]
        : []),

      {
        field: "status",
        headerName: "Status",
        dataType: "string" as const,
        displayType: "badge" as const,
        minWidth: 150,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["active", "missing", "expired"],
        },
        colors,
        pinned: "left" as const,
      },

      {
        headerName: "Requested on",
        field: "created_at",
        dataType: "date" as const,
        dateType: "timestamp" as const,
        pinned: "left" as const,
      },

      {
        field: "expires_at",
        headerName: "Expires on",
        dataType: "date" as const,
        dateType: "iso" as const,
        pinned: "left" as const,
      },
    ];
    const customFieldCols: ColumnConfig<AggregatedCertification>[] = [];
    certificationType?.custom_fields.forEach((c) => {
      customFieldCols.push({
        headerName: c.name,
        field: c._id,
        dataType: "component" as const,
        cellRenderer: (params) => {
          const answer = params.data?.custom_field_values.find((a) => a.form_field_id === c._id);
          return <div>{renderCustomFieldDefaultString(c, answer)}</div>;
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - valueGetter is not in the types
        valueGetter: (params) => {
          const answer = params.data?.custom_field_values.find((a) => a.form_field_id === c._id);
          return renderCustomFieldDefaultString(c, answer);
        },
      });
    });
    certificationType?.file_uploads.forEach((c) => {
      customFieldCols.push({
        headerName: c.name,
        field: c._id,
        dataType: "component" as const,
        cellRenderer: (params) => {
          const answer = params.data?.file_upload_values.find((a) => a.form_field_id === c._id);
          if (answer && answer.value[0]) {
            return (
              <Button
                className="button-1 no-margin"
                onClick={() =>
                  handleFileClick({
                    file: {
                      data: { _id: answer.value[0] },
                    } as unknown as NonNullable<FilePickerFile>,
                  })
                }
              >
                View
              </Button>
            );
          }
          return <div>-</div>;
        },

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - valueGetter is not in the types
        valueGetter: (params) => {
          const answer = params.data?.file_upload_values.find((a) => a.form_field_id === c._id);
          return renderCustomFieldDefaultString(c, answer);
        },
      });
    });
    cols.push({
      field: "actions",
      headerName: "Actions",
      dataType: "component" as const,
      disableCellClick: true,
      pinned: customFieldCols.length > 0 ? "right" : undefined,
      cellRenderer: (params) => {
        return (
          <Button className="button-1 modal" onClick={() => selectCertification(params.data)}>
            <PencilSimple weight="bold" style={{ marginRight: 3 }} />
            Update
          </Button>
        );
      },
    } as $TSFixMe);

    // @ts-expect-error fix me
    return [...cols, ...customFieldCols];
  }, [certificationType?.expires, certificationTypeId, teamMemberId]);

  const closeModal = () => {
    setRefreshCount((prev) => prev + 1);
    setModalDisplay(null);
    setSelectedCertifications([]);
  };

  const selectCertification = (row: AggregatedCertification) => {
    setSelectedCertification(row);
    setModalDisplay("edit");
  };

  const userCanManage = userHasPermission(
    selectedCertification?.certification_type.permission_groups_can_manage || [],
    permissionGroups.map((pg) => pg._id)
  );

  const renderModal = () => {
    if (!activeCompanyId) return null;
    switch (modalDisplay) {
      case "request":
        return teamMemberId ? (
          <RequestCertificationsForTM teamMemberId={teamMemberId} onClose={closeModal} />
        ) : certificationTypeId ? (
          <RequestCertificationsModal certificationTypeId={certificationTypeId} onClose={closeModal} />
        ) : null;
      case "upload":
        return teamMemberId ? (
          <UploadCertificationForTMModal teamMemberId={teamMemberId} close={closeModal} />
        ) : certificationTypeId ? (
          <UploadCertificationModal certificationTypeId={certificationTypeId} close={closeModal} />
        ) : null;
      case "edit":
        if ((!certificationType && !selectedCertificationType) || (!selectedTeamMember && !teamMember))
          return null;
        return (
          <Wizard onExit={closeModal}>
            <UploadCertificationWizardScreen
              certificationOwner={(teamMember || selectedTeamMember)!}
              certificationType={(certificationType || selectedCertificationType)!}
              certification={selectedCertification}
              onClose={closeModal}
              companyId={activeCompanyId}
              name={"Edit certification"}
              readOnly={!userCanManage}
            />
          </Wizard>
        );
      case "delete":
        return (
          <DeleteModal
            header={"Are you sure?"}
            body={<div>Deleting certifications cannot be undone.</div>}
            cancelText={"Cancel"}
            onHide={closeModal}
            deleteText={"Yes, delete certifications"}
            onDelete={archiveCertifications}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderCertificationsToggler()}
      <TableV2
        id="certifications-table"
        getData={getData}
        ssr={true}
        columns={columns}
        resource="certifications"
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        customEmptyStateMessage={`No ${status || "active"} certifications.`}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        refreshCount={refreshCount}
        defaultSelectedRows={selectedCertifications}
        onSelect={setSelectedCertifications}
      />
      {renderModal()}
      {renderFailuresModal()}
    </div>
  );
};
