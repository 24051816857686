import React, { useState, useEffect } from "react";

import DateTimePicker from "dashboard/components/pickers/dateTimePicker";
import { ClickAwayListener } from "@material-ui/core";
import { DateTime } from "luxon";
import { useRect } from "@reach/rect";
import { ValidationRuleset } from "./Input";
import { RegisterFn } from "./types";

type Props = {
  className?: string;
  disableDefaultClass?: boolean;
  name?: string;
  register?: RegisterFn;
  val?: ValidationRuleset;
  defaultValue?: DateTime | null;
  min?: DateTime;
  max?: DateTime;
  onChange?: (dt: DateTime | null) => void;
  onFocus?: () => void;
  time?: boolean;
  closeOnSelect?: boolean;
  specifyPickerPosition?: boolean;
  hideReset?: boolean;
  topOfInput?: boolean;
  position?: "absolute" | "relative";
  disabled?: boolean;
  placeholder?: string;
};

const DateTimeInput: React.FC<Props> = ({
  className,
  name,
  register,
  val,
  defaultValue = null,
  min,
  max,
  hideReset,
  onChange,
  disableDefaultClass,
  onFocus,
  time,
  closeOnSelect,
  topOfInput,
  position,
  disabled,
  placeholder,
}) => {
  const ref = React.useRef(null);
  const rect = useRect(ref, { observe: true });
  const mounted = React.useRef(false);
  const finalRef = register?.name === "register" ? register(val) : register;

  const [selected, setSelected] = useState<DateTime | null>();
  const [text, setText] = useState<string | null>(null);
  const [focused, setFocused] = useState<boolean | null>();
  const [pickerPosition, setPickerPosition] = useState<{ top?: number; left?: number; bottom?: number }>();

  const setNewSelected = (dt: DateTime | null, mount = false) => {
    if (!dt) {
      setText("");
      return null;
    }
    setSelected(dt);
    if (mounted.current && onChange) {
      onChange(dt);
    }
    setNewText(dt);
    if (!mount && closeOnSelect) {
      setTimeout(() => closeAndUpdate(dt), 150);
    }
    // setFocused(false);
  };

  const closeAndUpdate = (dt: DateTime) => {
    setFocused(false);
    if (onChange) {
      onChange(dt);
    }
  };

  const escFunction = React.useCallback((event) => {
    if (event.keyCode === 27) {
      setFocused(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const setNewText = (dt: DateTime) => {
    if (!time) {
      setText(dt.toFormat("LLL dd, yyyy"));
      setFocused(false);
    } else {
      const object: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        timeZoneName: "short",
      };
      setText(dt.toLocaleString(object));
    }
  };

  const onBlur = () => {
    setFocused(false);
  };

  const handleReset = () => {
    setSelected(null);
    setText("");
    onChange?.(null);
    setFocused(false);
  };

  useEffect(() => {
    setNewSelected(defaultValue, true);
    if (!mounted.current) {
      mounted.current = true;
    }
  }, [defaultValue?.toSeconds()]);

  useEffect(() => {
    if (topOfInput) {
      setPickerPosition({
        bottom: rect?.height ? rect.height + 2 : undefined,
      });
    }
  }, [rect]);

  return (
    <ClickAwayListener onClickAway={onBlur}>
      <div ref={ref} className="datetime-input-wrapper">
        <input
          onFocus={() => {
            setFocused(true);
            if (onFocus) onFocus();
          }}
          onClick={() => setFocused(true)}
          value={text || ""}
          readOnly
          className={disableDefaultClass ? className : "form2-text " + className}
          name={name}
          ref={finalRef}
          disabled={disabled}
          placeholder={placeholder}
        />
        {focused && (
          <div style={{ position: position || "absolute" }}>
            <DateTimePicker
              position={pickerPosition}
              time={time}
              selection={selected || undefined}
              select={setNewSelected}
              min={min}
              max={max}
              onBlur={onBlur}
              handleReset={hideReset ? undefined : handleReset}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DateTimeInput;
