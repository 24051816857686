import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Toggler } from "ui";
import Billing from "./billing/billing";
import Roles from "./roles/Roles";
import "ui/modal/modal.css";
import CompanyInfo from "./companyInfo/CompanyInfo";
import { Helmet } from "react-helmet";
import { capitalize } from "dashboard/utils";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import { PayRateGroups } from "./payRateGroups/PayRateGroups";
import DepartmentsTable from "dashboard/components/departments/DepartmentsTable";
import EquipmentTable from "dashboard/components/equipment/EquipmentTable";
import { CostTypes } from "../costTypes/CostTypes";
import { PermissionGroupsTable } from "dashboard/components/permission-groups/PermissionGroupsTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Workplaces } from "./workplaces/Workplaces";
import Banner from "dashboard/components/shared/Banner";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { WorkersComp } from "./workers-comp/WorkersComp";
import { Locations } from "./locations/Locations";

const Settings: React.FC = () => {
  /*********************************************************
                       Hooks/State
  **********************************************************/
  const params = useParams<{ view: string }>();
  const navigate = useNavigate();
  const { cannot } = useMiterAbilities();
  const view = params.view;

  /*********************************************************
                        Toggler
  **********************************************************/
  // If you add a new page to the settings page, make sure you update the route permissions
  const togglerConfig = useMemo(() => {
    return [
      { path: "company", label: "Company", hide: cannot("company:settings:manage") },
      { path: "pay-rate-groups", label: "Pay rate groups", hide: cannot("lists:pay_rate_groups:read") },
      { path: "departments", label: "Departments", hide: cannot("lists:departments:read") },
      { path: "workplaces", label: "Workplaces", hide: cannot("lists:workplaces:read") },
      { path: "workers-comp", label: "Workers' comp", hide: cannot("lists:workers_comp_codes:read") },
      { path: "cost-types", label: "Cost types", hide: cannot("lists:cost_types:read") },
      { path: "locations", label: "Locations", hide: cannot("lists:locations:read") },
      {
        path: "permissions",
        label: "Permissions",
        hide: cannot("company:permission_groups:manage") && cannot("company:roles:manage"),
      },
      { path: "billing", label: "Billing", hide: cannot("company:billing:manage") },
      { path: "equipment", label: "Equipment", hide: cannot("lists:equipment:read") },
    ];
  }, [cannot]);

  const [permissionsView, setPermissionsView] = useState("permission-groups");
  const permissionsTogglerConfig = useMemo(() => {
    const config: TogglerConfigItem[] = [];
    if (!cannot("company:permission_groups:manage")) {
      config.push({ path: "permission-groups", label: "Permission groups" });
    }
    if (!cannot("company:roles:manage")) config.push({ path: "roles", label: "External roles" });
    return config;
  }, [cannot]);

  const showView = useMemo(() => {
    return togglerConfig.reduce((acc, curr) => {
      acc[curr.path] = !curr.hide;
      return acc;
    }, {} as { [key: string]: boolean });
  }, [togglerConfig]);

  const toggle = async (page) => {
    navigate("/settings/" + page, { replace: true });
  };

  const getViewTitle = () => {
    if (view === "files") {
      return "Company Files";
    } else if (view === "workers-comp") {
      return "Workers Compensation Settings";
    } else if (view === "activities") {
      return "Company Activities";
    } else {
      return view ? capitalize(view) + " Settings" : "Settings";
    }
  };

  /*********************************************************
                  useEffect and return
  **********************************************************/

  useEffect(() => {
    const defaultView = togglerConfig.find((item) => !item.hide)?.path;
    if (!view) {
      navigate("/settings/" + defaultView, { replace: true });
    }
  }, [view, togglerConfig]);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{getViewTitle()} | Miter</title>
      </Helmet>
      <div className="page-content">
        <h1>Settings</h1>
        <Toggler secondary={false} config={togglerConfig} active={view} toggle={toggle} />
        {view === "company" && showView["company"] && <CompanyInfo />}
        {view === "departments" && showView["departments"] && <DepartmentsTable />}
        {view === "workplaces" && showView["workplaces"] && <Workplaces />}
        {view === "locations" && showView["locations"] && <Locations />}
        {view === "roles" && showView["roles"] && <Roles />}
        {view === "billing" && showView["billing"] && <Billing />}
        {view === "pay-rate-groups" && showView["pay-rate-groups"] && <PayRateGroups />}
        {view === "workers-comp" && showView["workers-comp"] && <WorkersComp />}
        {view === "cost-types" && showView["cost-types"] && <CostTypes />}
        {view === "permissions" && showView["permissions"] && (
          <>
            <Banner
              type="info"
              style={{ marginTop: 20 }}
              onClick={() => goToMiterGuide("/getting-started/permissions")}
            >
              <span className="flex">
                {`New to permission groups? Click this banner to learn more about creating and managing permission groups in Miter.`}
              </span>
            </Banner>
            <Toggler
              secondary={true}
              config={permissionsTogglerConfig}
              active={permissionsView}
              toggle={setPermissionsView}
            />
            {permissionsView === "roles" && <Roles />}
            {permissionsView === "permission-groups" && <PermissionGroupsTable />}
          </>
        )}
        {view === "equipment" && showView["equipment"] && <EquipmentTable />}
      </div>
    </div>
  );
};

export default Settings;
