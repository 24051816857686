import { AggregatedRecruitingConversation } from "dashboard/types/chat";
import {
  useActiveCompanyId,
  useActiveRecruitingConversation,
  useRecruitingTwilioClient,
  useSetActiveRecruitingConversation,
  useSetPaginatedRecruitingConversations,
  useSetUnreadRecruitingConversationsCount,
} from "../atom-hooks";
import { useEffect } from "react";
import { EMPTY_FORAGE_RESPONSE } from "dashboard/utils";
import { getPaginatedRecruitingConversations, markTwilioConversationAsRead } from "dashboard/utils/chat";
import {
  FetchNextPageOptions,
  InfiniteQueryObserverBaseResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { ForageResponse } from "backend/utils/forage/forage-types";

export type useRecruitingChatUtilities = {
  fetchMoreConversations: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverBaseResult<ForageResponse, unknown>>;
  hasNextPage: boolean;
  markConversationAsRead: (conversation: AggregatedRecruitingConversation) => Promise<void>;
};

type FetchInput = {
  pageParam?: string;
  searchParam: string;
};

export const RECRUITING_CONVERSATIONS_KEY = "recruiting_conversations.1";

export const useRecruitingChatUtilities = (input?: {
  recruitingSearch: string;
}): useRecruitingChatUtilities => {
  const { recruitingSearch } = input || {};

  /** Atom hooks */
  const activeCompanyId = useActiveCompanyId();

  const recruitingTwilioClient = useRecruitingTwilioClient();
  const setRecruitingConversations = useSetPaginatedRecruitingConversations();
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const setActiveRecruitingConversation = useSetActiveRecruitingConversation();
  const setRecruitingUnreadCount = useSetUnreadRecruitingConversationsCount();

  const fetchRecruitingConversations = async (input: FetchInput): Promise<ForageResponse> => {
    const { pageParam, searchParam } = input;

    if (!activeCompanyId || !recruitingTwilioClient) return EMPTY_FORAGE_RESPONSE as $TSFixMe;
    const forageResponse = await getPaginatedRecruitingConversations({
      client: recruitingTwilioClient,
      companyId: activeCompanyId,
      cursor: pageParam,
      search: searchParam,
    });

    setRecruitingConversations((prev) => prev.concat(forageResponse.data));
    return forageResponse;
  };

  // Reset recruiting conversations when search changes
  useEffect(() => {
    setRecruitingConversations([]);
  }, [recruitingSearch]);

  const { fetchNextPage, hasNextPage } = useInfiniteQuery(
    [
      RECRUITING_CONVERSATIONS_KEY,
      { activeCompanyId, client: !!recruitingTwilioClient, recruitingSearch },
    ] as const,
    ({ pageParam, queryKey: [, params] }) =>
      fetchRecruitingConversations({ pageParam, searchParam: params.recruitingSearch || "" }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) return undefined;
        return lastPage?.next_page;
      },
    }
  );

  /** This utility function marks a conversation as read, and applies any necessary side-effects. */
  const markConversationAsRead = async (conversation: AggregatedRecruitingConversation): Promise<void> => {
    const updatedConversation = { ...conversation, unread: false };

    /** Set new global states */
    setRecruitingConversations((prev) => prev.concat(updatedConversation));
    if (activeRecruitingConversation?._id === conversation._id) {
      setActiveRecruitingConversation(updatedConversation);
    }

    // Decrement counter if conversation was unread
    const wasUnread = conversation.unread;
    if (wasUnread) {
      setRecruitingUnreadCount((prev) => prev - 1);
    }

    // Mark conversation as read in Twilio
    await markTwilioConversationAsRead(conversation.twilio_conversation);
  };

  return {
    fetchMoreConversations: fetchNextPage,
    hasNextPage: !!hasNextPage,
    markConversationAsRead,
  };
};
