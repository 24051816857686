import { ColDef } from "ag-grid-community";
import { toDollarFormat, roundToTwoDecimals } from "../../reportUtils";

export const jobCostColumnDefs: ColDef[] = [
  {
    field: "job_name",
    headerName: "Job",
    filter: true,
    enableRowGroup: true,
    rowGroup: true,
    initialHide: true,
  },
  {
    field: "job_code",
    headerName: "Job code",
    filter: true,
    enableRowGroup: true,
    initialHide: true,
  },
  {
    field: "activity_name",
    headerName: "Activity",
    filter: true,
    enableRowGroup: true,
    rowGroup: true,
    initialHide: true,
  },
  {
    field: "activity_code",
    headerName: "Activity code",
    filter: true,
    enableRowGroup: true,
    initialHide: true,
  },
  {
    field: "tm_name",
    headerName: "Team member",
    filter: true,
    enableRowGroup: true,
    rowGroup: true,
    initialHide: true,
  },
  {
    field: "tm_friendly_id",
    headerName: "Team member ID",
    filter: true,
    enableRowGroup: true,
    initialHide: true,
  },
  {
    field: "department",
    headerName: "Department",
    filter: true,
    enableRowGroup: true,
    initialHide: true,
  },
  {
    field: "classification",
    headerName: "Classification",
    filter: true,
    enableRowGroup: true,
    initialHide: true,
  },
  {
    field: "location",
    headerName: "Location",
    filter: true,
    enableRowGroup: true,
    initialHide: true,
  },
  {
    field: "date",
    headerName: "Date",
    filter: true,
    enableRowGroup: true,
  },
  {
    field: "reg_hours",
    headerName: "REG hours",
    aggFunc: "sumValues",
    valueFormatter: roundToTwoDecimals,
  },
  {
    field: "ot_hours",
    headerName: "OT hours",
    aggFunc: "sumValues",
    valueFormatter: roundToTwoDecimals,
  },
  {
    field: "dot_hours",
    headerName: "DOT hours",
    aggFunc: "sumValues",
    valueFormatter: roundToTwoDecimals,
  },
  {
    field: "earnings",
    headerName: "Earnings",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "employer_taxes",
    headerName: "Taxes",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "employer_benefit_contributions",
    headerName: "Benefits",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "workers_comp",
    headerName: "Workers' comp",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "reimbursements",
    headerName: "Reimbursements",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "bill_expenses",
    headerName: "Bill expenses",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "other_expenses",
    headerName: "Card expenses",
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
  },
  {
    field: "total_cost",
    headerName: "Total cost",
    enableValue: true,
    aggFunc: "sumValues",
    valueFormatter: toDollarFormat,
    pinned: "right",
  },
];
