import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useHasAccessToOnboarding2_0 } from "dashboard/gating";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Notifier } from "ui";
import OnboardingDataSettings from "./OnboardingDataSettings";
import OnboardingItemsTable from "./OnboardingItemsTable";
import { SettingsCard } from "ui";
import { MiterAPI } from "dashboard/miter";
import { OnboardingItemTableRow } from "./OnboardingItemsTable";
import {
  useActiveCompanyId,
  useCompanyDocuments,
  useCompanyDocumentOptions,
  useRefetchCompanyDocuments,
  useForms,
  useFormOptions,
  useRefetchForms,
  useCertificationTypes,
  useCertificationTypeOptions,
  useRefetchCertificationTypes,
  useFillableTemplates,
  useFillableTemplateOptions,
  useRefetchFillableTemplates,
  useCustomTasks,
  useCustomTaskOptions,
  useRefetchCustomTasks,
} from "dashboard/hooks/atom-hooks";
import { TeamMemberOnboardingConfig } from "backend/services/team-member-onboarding/team-member-onboarding-types";
import { OnboardingItemFormParams } from "./OnboardingItemModal";
import OnboardingCustomTasksTable from "./OnboardingCustomTasksTable";
import { OnboardingCustomTaskFormParams } from "./OnboardingCustomTaskModal";
import { OnboardingCustomTaskTableRow } from "./OnboardingCustomTasksTable";
import CustomFieldsTable from "dashboard/components/custom-fields/CustomFieldsTable";

export const TeamMemberOnboardingSettings: React.FC = () => {
  const navigate = useNavigate();
  const companyId = useActiveCompanyId();
  const hasAccessToOnboarding2_0 = useHasAccessToOnboarding2_0();
  const { can } = useMiterAbilities();
  const documents = useCompanyDocuments();
  const documentOptions = useCompanyDocumentOptions();
  const refetchCompanyDocuments = useRefetchCompanyDocuments();
  const forms = useForms();
  const formOptions = useFormOptions();
  const refetchForms = useRefetchForms();
  const certificationTypes = useCertificationTypes();
  const certificationTypeOptions = useCertificationTypeOptions();
  const refetchCertificationTypes = useRefetchCertificationTypes();
  const fillableTemplates = useFillableTemplates();
  const fillableTemplateOptions = useFillableTemplateOptions();
  const refetchFillableTemplates = useRefetchFillableTemplates();
  const customTasks = useCustomTasks();
  const customTaskOptions = useCustomTaskOptions();
  const refetchCustomTasks = useRefetchCustomTasks();

  const canAccessOnboardingSettings = can("team:settings") && hasAccessToOnboarding2_0;

  const [documentRows, setDocumentRows] = useState<OnboardingItemTableRow[]>([]);
  const [formRows, setFormRows] = useState<OnboardingItemTableRow[]>([]);
  const [certificationRows, setCertificationRows] = useState<OnboardingItemTableRow[]>([]);
  const [fillableTemplateRows, setFillableTemplateRows] = useState<OnboardingItemTableRow[]>([]);
  const [customTaskRows, setCustomTaskRows] = useState<OnboardingCustomTaskTableRow[]>([]);

  const createOnboardingRows = <
    T extends { _id: string; onboarding_config?: TeamMemberOnboardingConfig | null }
  >(
    items: T[] | undefined,
    getItemName: (item: T) => string
  ): OnboardingItemTableRow[] => {
    if (!items) return [];
    return items
      .filter((item) => item.onboarding_config != null)
      .map((item) => ({
        _id: item._id.toString(),
        name: getItemName(item),
        default_team_member_checklists: item.onboarding_config?.default_team_member_checklists || [],
        default_assignee: item.onboarding_config?.default_assignee || [],
      }));
  };

  // Fetch custom tasks on load
  useEffect(() => {
    refetchCustomTasks();
  }, []);

  useEffect(() => {
    if (!canAccessOnboardingSettings) {
      navigate("/team-members/settings/settings");
      Notifier.error("You do not have permission to view this page.");
    }
  }, [canAccessOnboardingSettings, navigate]);

  useEffect(() => {
    setDocumentRows(createOnboardingRows(documents, (doc) => doc.label || ""));
  }, [documents]);

  useEffect(() => {
    setFormRows(createOnboardingRows(forms, (form) => form.name));
  }, [forms]);

  useEffect(() => {
    setCertificationRows(createOnboardingRows(certificationTypes, (cert) => cert.title));
  }, [certificationTypes]);

  useEffect(() => {
    setFillableTemplateRows(createOnboardingRows(fillableTemplates, (template) => template.name));
  }, [fillableTemplates]);

  useEffect(() => {
    if (!customTasks) {
      setCustomTaskRows([]);
    } else {
      const customTaskRows = customTasks.map((task) => ({
        _id: task._id.toString(),
        title: task.title,
        description: task.description,
        due_days_from_start: task.due_days_from_start,
        default_team_member_checklists: task.onboarding_config?.default_team_member_checklists || [],
        default_assignee: task.onboarding_config?.default_assignee || [],
      }));
      setCustomTaskRows(customTaskRows);
    }
  }, [customTasks]);

  const handleRemoveDocuments = async (selectedRows: OnboardingItemTableRow[]) => {
    try {
      const filesToUpdate = selectedRows.map((row) => ({
        _id: row._id,
        onboarding_config: null,
      }));
      const res = await MiterAPI.files.update_many({
        files: filesToUpdate,
      });
      if (res.error) throw new Error(res.error);
      await refetchCompanyDocuments([...filesToUpdate.map((file) => file._id)]);
      Notifier.success("Documents removed from defaults successfully.");
    } catch (error) {
      Notifier.error("Failed to remove documents from defaults.");
    }
  };

  const handleUpdateDocument = async (row: OnboardingItemFormParams) => {
    try {
      const fileToUpdate = {
        _id: row.objectOption.value,
        onboarding_config: {
          default_team_member_checklists:
            row.default_team_member_checklists?.map((group) => group.value) ?? null,
          default_assignee: row.default_assignee?.map((group) => group.value) ?? null,
        },
      };

      const res = await MiterAPI.files.update_many({
        files: [fileToUpdate],
      });
      if (res.error) throw new Error(res.error);
      await refetchCompanyDocuments(fileToUpdate._id);
      Notifier.success("Document updated successfully.");
    } catch (error) {
      Notifier.error("Failed to update document.");
    }
  };

  const handleRemoveFillableTemplates = async (selectedRows: OnboardingItemTableRow[]) => {
    try {
      const templatesToUpdate = selectedRows.map((row) => ({
        _id: row._id,
        onboarding_config: null,
      }));

      const updatePromises = templatesToUpdate.map((template) =>
        MiterAPI.fillable_templates.update({ id: template._id, update: template })
      );

      const results = await Promise.all(updatePromises);

      if (results.some((res) => res.error)) {
        throw new Error("One or more fillable template updates failed");
      }

      await refetchFillableTemplates(templatesToUpdate.map((template) => template._id));
      Notifier.success("Fillable templates removed from defaults successfully.");
    } catch (error) {
      Notifier.error("Failed to remove fillable templates from defaults.");
    }
  };

  const handleUpdateFillableTemplate = async (row: OnboardingItemFormParams) => {
    try {
      const templateToUpdate = {
        _id: row.objectOption.value,
        onboarding_config: {
          default_team_member_checklists:
            row.default_team_member_checklists?.map((group) => group.value) ?? null,
          default_assignee: row.default_assignee?.map((group) => group.value) ?? null,
        },
      };
      const res = await MiterAPI.fillable_templates.update({
        id: templateToUpdate._id,
        update: templateToUpdate,
      });
      if (res.error) throw new Error(res.error);
      await refetchFillableTemplates(templateToUpdate._id);
      Notifier.success("Fillable template updated successfully.");
    } catch (error) {
      Notifier.error("Failed to update fillable template.");
    }
  };

  const handleRemoveForms = async (selectedRows: OnboardingItemTableRow[]) => {
    try {
      const formsToUpdate = selectedRows.map((row) => ({
        _id: row._id,
        onboarding_config: null,
      }));

      const updatePromises = formsToUpdate.map((form) => MiterAPI.forms.update(form._id, form));

      const results = await Promise.all(updatePromises);

      if (results.some((res) => res.error)) {
        throw new Error("One or more form updates failed");
      }

      await refetchForms(formsToUpdate.map((form) => form._id));
      Notifier.success("Forms removed from defaults successfully.");
    } catch (error) {
      Notifier.error("Failed to remove forms from defaults.");
    }
  };

  const handleUpdateForm = async (row: OnboardingItemFormParams) => {
    try {
      const formToUpdate = {
        _id: row.objectOption.value,
        onboarding_config: {
          default_team_member_checklists:
            row.default_team_member_checklists?.map((group) => group.value) ?? null,
          default_assignee: row.default_assignee?.map((group) => group.value) ?? null,
        },
      };
      const res = await MiterAPI.forms.update(formToUpdate._id, formToUpdate);
      if (res.error) throw new Error(res.error);
      await refetchForms(formToUpdate._id);
      Notifier.success("Form updated successfully.");
    } catch (error) {
      Notifier.error("Failed to update form.");
    }
  };

  const handleRemoveCertifications = async (selectedRows: OnboardingItemTableRow[]) => {
    try {
      const certificationTypesToUpdate = selectedRows.map((row) => ({
        _id: row._id,
        onboarding_config: null,
      }));

      const updatePromises = certificationTypesToUpdate.map((certificationType) =>
        MiterAPI.certification_types.update(certificationType._id, certificationType)
      );

      const results = await Promise.all(updatePromises);

      if (results.some((res) => res.error)) {
        throw new Error("One or more certification type updates failed");
      }

      await refetchCertificationTypes(
        certificationTypesToUpdate.map((certificationType) => certificationType._id)
      );
      Notifier.success("Certifications removed from defaults successfully.");
    } catch (error) {
      Notifier.error("Failed to remove certifications from defaults.");
    }
  };

  const handleUpdateCertification = async (row: OnboardingItemFormParams) => {
    try {
      const certificationTypeToUpdate = {
        _id: row.objectOption.value,
        onboarding_config: {
          default_team_member_checklists:
            row.default_team_member_checklists?.map((group) => group.value) ?? null,
          default_assignee: row.default_assignee?.map((group) => group.value) ?? null,
        },
      };
      const res = await MiterAPI.certification_types.update(
        certificationTypeToUpdate._id,
        certificationTypeToUpdate
      );
      if (res.error) throw new Error(res.error);
      await refetchCertificationTypes(certificationTypeToUpdate._id);
      Notifier.success("Certification updated successfully.");
    } catch (error) {
      Notifier.error("Failed to update certification.");
    }
  };

  const handleRemoveCustomTasks = async (
    selectedRows: (OnboardingItemTableRow | OnboardingCustomTaskTableRow)[]
  ) => {
    // Cast the selectedRows to OnboardingCustomTaskTableRow[] inside the function
    const customTaskRows = selectedRows as OnboardingCustomTaskTableRow[];

    // Rest of the function implementation
    try {
      const tasksToUpdate = customTaskRows.map((row) => ({
        _id: row._id,
        onboarding_config: null,
      }));

      const updatePromises = tasksToUpdate.map((task) => MiterAPI.custom_tasks.delete(task._id));

      const results = await Promise.all(updatePromises);

      if (results.some((res) => res.error)) {
        throw new Error("One or more custom task removals failed");
      }

      await refetchCustomTasks(tasksToUpdate.map((task) => task._id));
      Notifier.success("Custom tasks removed successfully.");
    } catch (error) {
      Notifier.error("Failed to remove custom tasks.");
    }
  };

  const handleUpdateCustomTask = async (row: OnboardingCustomTaskFormParams) => {
    try {
      const taskToUpdate = {
        _id: row._id,
        title: row.title,
        description: row.description,
        due_days_from_start: row.due_days_from_start,
        onboarding_config: {
          default_team_member_checklists:
            row.default_team_member_checklists?.map((group) => group.value) ?? null,
          default_assignee: row.default_assignee?.map((group) => group.value) ?? null,
        },
      };
      const res = await MiterAPI.custom_tasks.update(taskToUpdate._id, taskToUpdate);
      if (res.error) throw new Error(res.error);
      await refetchCustomTasks(taskToUpdate._id);
      Notifier.success("Custom task updated successfully.");
    } catch (error) {
      Notifier.error("Failed to update custom task.");
    }
  };

  const handleCreateCustomTask = async (row: OnboardingCustomTaskFormParams) => {
    try {
      if (!companyId) throw new Error("Company ID not found.");
      const res = await MiterAPI.custom_tasks.create({
        title: row.title,
        description: row.description,
        due_days_from_start: row.due_days_from_start,
        onboarding_config: {
          default_team_member_checklists:
            row.default_team_member_checklists?.map((group) => group.value) ?? null,
          default_assignee: row.default_assignee?.map((group) => group.value) ?? null,
        },
        company_id: companyId,
      });
      if (res.error) throw new Error(res.error);
      await refetchCustomTasks(res._id);
      Notifier.success("Custom task created successfully.");
    } catch (error) {
      Notifier.error("Failed to create custom task.");
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Onboarding settings | Miter</title>
      </Helmet>
      <div className="vertical-spacer-large"></div>
      <div>Configure team member onboarding settings.</div>
      <div className="vertical-spacer-small"></div>

      <OnboardingDataSettings />
      <SettingsCard title="Documents">
        <div>Add default documents for onboarding checklists.</div>
        <OnboardingItemsTable
          tableId="onboarding-documents-table"
          resourceName="onboarding documents"
          handleRemove={handleRemoveDocuments}
          handleUpdate={handleUpdateDocument}
          tableData={documentRows}
          objectOptions={documentOptions}
        />
      </SettingsCard>
      <SettingsCard title="Fillable documents">
        <div>Add default fillable documents for onboarding checklists.</div>
        <OnboardingItemsTable
          tableId="onboarding-fillable-templates-table"
          resourceName="onboarding fillable documents"
          handleRemove={handleRemoveFillableTemplates}
          handleUpdate={handleUpdateFillableTemplate}
          tableData={fillableTemplateRows}
          objectOptions={fillableTemplateOptions}
        />
      </SettingsCard>
      <SettingsCard title="Forms">
        <div>Add default forms for onboarding checklists.</div>
        <OnboardingItemsTable
          tableId="onboarding-forms-table"
          resourceName="onboarding forms"
          handleRemove={handleRemoveForms}
          handleUpdate={handleUpdateForm}
          tableData={formRows}
          objectOptions={formOptions}
        />
      </SettingsCard>
      <SettingsCard title="Certifications & licenses">
        <div>Add default certifications and licenses for onboarding checklists.</div>
        <OnboardingItemsTable
          tableId="onboarding-certifications-table"
          resourceName="onboarding certifications"
          handleRemove={handleRemoveCertifications}
          handleUpdate={handleUpdateCertification}
          tableData={certificationRows}
          objectOptions={certificationTypeOptions}
        />
      </SettingsCard>
      <SettingsCard title="Custom tasks">
        <div>Add default custom tasks for onboarding checklists.</div>
        <OnboardingCustomTasksTable
          tableId="onboarding-custom-tasks-table"
          resourceName="onboarding custom tasks"
          handleRemove={handleRemoveCustomTasks}
          handleUpdate={handleUpdateCustomTask}
          handleCreate={handleCreateCustomTask}
          tableData={customTaskRows}
          objectOptions={customTaskOptions}
        />
      </SettingsCard>
      <SettingsCard title="Custom fields">
        <div>Add default custom fields for onboarding checklists.</div>
        <CustomFieldsTable parentType="onboarding_task" />
      </SettingsCard>
    </div>
  );
};

export default TeamMemberOnboardingSettings;
