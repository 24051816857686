import React, { useMemo, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { useForm } from "react-hook-form";
import AppContext from "../../../contexts/app-context";
import { Formblock } from "ui";
import { ModalHeader } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI, AggregatedJob, Customer, Job, Address, MiterError } from "dashboard/miter";
import { DateTime } from "luxon";
import * as vals from "dashboard/utils/validators";
import CustomerModal from "dashboard/components/customers/CustomerModal";
import {
  useActiveCompany,
  useActiveCompanyId,
  useDepartmentOptions,
  useJobOptions,
  useLocationOptions,
  usePerDiemRateOptions,
  useRefetchJobs,
  useSubJobsConfig,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { isEmptyAddress } from "miter-utils";
import { useCanEditParentJob } from "dashboard/gating";

type Props = {
  hide: () => void;
} & (
  | {
      updating: true;
      jobData: AggregatedJob;
    }
  | {
      updating?: false;
      jobData?: undefined;
    }
);

const JobModalForm: React.FC<Props> = ({ jobData, updating, hide }) => {
  // Hooks
  const { customers, getCustomers } = React.useContext(AppContext);
  const { can } = useMiterAbilities();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();

  const { register, handleSubmit, errors, setValue, control } = useForm();

  const refetchJobs = useRefetchJobs();
  const supervisorDefaults = jobData?.supervisors.map((sup) => sup._id);
  const teamOptionsForSupervisors = useTeamOptions({ defaultValue: supervisorDefaults });
  const jobOptions = useJobOptions();
  const subJobsConfig = useSubJobsConfig();

  const superintendentDefaults = jobData?.superintendents.map((sup) => sup._id);
  const teamOptionsForSuperIntendents = useTeamOptions({ defaultValue: superintendentDefaults });

  const departmentOptions = useDepartmentOptions();
  const perDiemRateOptions = usePerDiemRateOptions();
  const locationOptions = useLocationOptions();
  const canEditParentJob = useCanEditParentJob();

  const defaultPerDiemRatesForNewJob = useMemo(() => {
    return activeCompany?.settings?.per_diem_rates?.default_rates_for_new_job || [];
  }, [perDiemRateOptions]);

  // State
  const [saving, setSaving] = useState(false);
  const [startDate, setStartDate] = useState(
    jobData?.start_date ? DateTime.fromISO(jobData.start_date) : null
  );
  const [addNewCustomer, setAddNewCustomer] = useState(false);

  const cleanData = (data) => {
    const body = { ...data };
    body.supervisors = data.supervisors ? data.supervisors.map((s) => s.value) : [];
    body.superintendent_ids = data.superintendent_ids ? data.superintendent_ids.map((s) => s.value) : [];
    body.customer = body.customer?.value;
    body.parent_job_id = body.parent_job_id?.value || null;
    body.company = activeCompanyId!;
    body.status = body.status ? body.status.value : "active";
    body.department_id = body.department_id?.value || null;
    body.location_id = body.location_id?.value || null;
    body.address = {
      ...data.address,
      state: data.address.state?.value,
    };

    if (startDate === null) {
      body.start_date = null;
    } else {
      body.start_date = startDate?.toISODate();
    }

    body.per_diem_rate_ids = data.per_diem_rate_ids?.map((perDiemRate) => perDiemRate.value) ?? [];

    // If we are not updating, strip out empty values or empty arrays
    if (!updating) {
      Object.keys(body).forEach((key) => {
        if (body[key] === "" || body[key] === null || (Array.isArray(body[key]) && body[key].length === 0)) {
          delete body[key];
        }
      });

      if (isEmptyAddress(body.address)) {
        delete body.address;
      }
    }

    return body;
  };

  const handleNewCustomerAdded = (customer: Customer) => {
    getCustomers();
    setAddNewCustomer(false);

    if (customer) {
      setValue("customer", { value: customer._id, label: customer.name });
    }
  };

  const submit = async (data) => {
    setSaving(true);
    try {
      const body = cleanData(data);
      let response: Job & MiterError;
      if (updating) {
        response = await MiterAPI.jobs.update(jobData!._id, body);
      } else {
        response = await MiterAPI.jobs.create(body);
      }

      if (response.error) {
        throw new Error(response.error);
      }
      await refetchJobs(response._id);
      Notifier.success(updating ? "Job updated successfully." : "Job created successfully.");
      hide();
    } catch (e) {
      console.log(e);
      Notifier.error(
        `There was an error ${
          updating ? "updating" : "creating"
        } the job. Please contact support if the problem persists.`
      );
    }
    setSaving(false);
  };

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  return (
    <>
      <div className="modal-background" style={addNewCustomer ? { opacity: 0 } : { opacity: 1 }}>
        <ClickAwayListener onClickAway={() => {}}>
          <div className="modal-wrapper form" style={{ width: 500 }}>
            <ModalHeader heading={updating ? "Update job" : "Create job"} onHide={hide} />
            <div className="modal-body form">
              <div className="vertical-spacer"></div>
              {
                <>
                  <Formblock
                    label="Job name*"
                    type="text"
                    className="modal"
                    defaultValue={jobData?.name}
                    name="name"
                    editing={true}
                    register={register(vals.required)}
                    errors={errors}
                  />
                  <Formblock
                    label="Job code"
                    type="text"
                    className="modal"
                    defaultValue={jobData?.code}
                    name="code"
                    editing={true}
                    register={register}
                    errors={errors}
                  />
                  <Formblock
                    label="Job description"
                    type="text"
                    className="modal"
                    defaultValue={jobData?.description}
                    name="description"
                    editing={true}
                    register={register}
                    errors={errors}
                  />
                  <Formblock
                    label="Job address"
                    type="address"
                    className="modal"
                    defaultValue={jobData?.address as Address}
                    name="address"
                    editing={true}
                    register={register}
                    notRequiredRegister={register}
                    control={control}
                    errors={errors}
                  />
                  {subJobsConfig.enabled && (
                    <Formblock
                      label="Parent job"
                      type="select"
                      className="modal"
                      defaultValue={jobData?.parent_job_id}
                      name="parent_job_id"
                      options={jobOptions}
                      editing={true}
                      control={control}
                      errors={errors}
                      disabled={!!jobData && !canEditParentJob}
                    />
                  )}
                  <Formblock
                    label="Customer"
                    type="select"
                    className="modal"
                    defaultValue={jobData?.customer?._id}
                    name="customer"
                    options={customers.map((c) => ({ label: c.name, value: c._id }))}
                    editing={true}
                    control={control}
                    errors={errors}
                    labelButtonText={can("lists:customers:manage") ? "+ Add a new customer" : undefined}
                    labelButtonClick={
                      can("lists:customers:manage") ? () => setAddNewCustomer(true) : undefined
                    }
                  />
                  <Formblock
                    label="Department"
                    type="select"
                    className="modal"
                    defaultValue={jobData?.department_id}
                    name="department_id"
                    options={departmentOptions}
                    editing={true}
                    control={control}
                    errors={errors}
                    isClearable={true}
                  />
                  <Formblock
                    label="Location"
                    type="select"
                    className="modal"
                    defaultValue={jobData?.location_id}
                    name="location_id"
                    options={locationOptions}
                    editing={true}
                    control={control}
                    errors={errors}
                    isClearable={true}
                  />

                  {updating && (
                    <Formblock
                      label="Status*"
                      type="select"
                      control={control}
                      className="modal"
                      options={statusOptions}
                      defaultValue={jobData?.status}
                      name="status"
                      editing={true}
                      errors={errors}
                    />
                  )}
                  <Formblock
                    label="Start date"
                    type="datetime"
                    control={control}
                    dateOnly
                    className="modal"
                    name="start_date"
                    editing={true}
                    defaultValue={startDate}
                    errors={errors}
                    onChange={setStartDate}
                  />
                  <Formblock
                    label="Supervisors"
                    labelInfo="Supervisors can view, update, and approve timesheets for this job."
                    name="supervisors"
                    type="multiselect"
                    height="auto"
                    defaultValue={supervisorDefaults}
                    options={teamOptionsForSupervisors}
                    className="modal"
                    editing={true}
                    maxMenuHeight={175}
                    control={control}
                    errors={errors}
                  />
                  <Formblock
                    label="Superintendents"
                    labelInfo="Superintendents can view, update, and approve timesheets for this job."
                    name="superintendent_ids"
                    type="multiselect"
                    height="auto"
                    defaultValue={superintendentDefaults}
                    options={teamOptionsForSuperIntendents}
                    className="modal"
                    editing={true}
                    maxMenuHeight={175}
                    control={control}
                    errors={errors}
                  />
                  {/* only on job creation */}
                  {!!perDiemRateOptions.length && !updating && (
                    <Formblock
                      type="multiselect"
                      label="Per diem rates"
                      labelInfo="You can create or edit per diem rates after job creation."
                      name="per_diem_rate_ids"
                      editing={true}
                      className="modal"
                      placeholder="Select per diem rates for this job"
                      options={perDiemRateOptions}
                      defaultValue={defaultPerDiemRatesForNewJob}
                      register={register}
                      control={control}
                    />
                  )}
                  <Formblock
                    text="This is an OCIP job"
                    type="checkbox"
                    className="modal"
                    register={register}
                    defaultValue={jobData?.is_ocip}
                    name="is_ocip"
                    editing={true}
                    control={control}
                    errors={errors}
                  />
                </>
              }

              <div className="vertical-spacer"></div>
            </div>

            <div className="modal-footer form">
              <button className={saving ? "button-1 inactive " : "button-1"} onClick={hide}>
                Cancel
              </button>
              <button
                onClick={handleSubmit(submit)}
                className={saving || Object.keys(errors).length ? "button-2 inactive" : "button-2"}
              >
                {updating ? "Update job" : "Create job"}
              </button>
            </div>
          </div>
        </ClickAwayListener>
      </div>
      {addNewCustomer && (
        <CustomerModal onHide={() => setAddNewCustomer(false)} onFinish={handleNewCustomerAdded} />
      )}
    </>
  );
};

export default JobModalForm;
