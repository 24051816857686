import ObjectID from "bson-objectid";
import AppContext from "dashboard/contexts/app-context";
import { UnionRateFringe } from "dashboard/miter";
import { cloneDeep, isNumber } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier } from "ui";
import { Option } from "ui/form/Input";
import { ClassificationTableEntry } from "./PayRateGroupModalUtils";
import { useLedgerAccountOptions } from "dashboard/hooks/atom-hooks";
import { includeWithEarningOption } from "dashboard/pages/accounting/accountingUtils";
import { RateGroupFringeTableEntry } from "./PayRateGroupFringes";
import { CheckBenefitType, CheckHSALimit } from "../../../../../../backend/utils/check/check-types";
import {
  useCanAssociateBensWithNonTaxableContributions,
  useHasAccessToFringeMaximums,
} from "dashboard/gating";
import { UnionRateFringeType } from "backend/models/union-rate";
import { getOtMultiplierTooltip } from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";

type FringeModalProps = {
  selectedFringe?: RateGroupFringeTableEntry;
  prgHasDynamicOffset: boolean;
  onHide: () => void;
  setTableData: React.Dispatch<React.SetStateAction<ClassificationTableEntry[]>>;
  setUnsavedData: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FringeModal: React.FC<FringeModalProps> = ({
  onHide,
  prgHasDynamicOffset,
  selectedFringe,
  setTableData,
  setUnsavedData,
}) => {
  const [fringeType, setFringeType] = useState(selectedFringe?.type);
  const isDeduction = isFringeDeduction(fringeType);
  const isNonTaxableContribution = fringeType === "non_taxable_contribution";
  const isCash = fringeType === "taxable_earning";

  const initialMultipliers = useMemo(() => {
    const otm = selectedFringe?.ot_multipliers;
    return { ot: otm?.ot?.toString() || "1", dot: otm?.dot?.toString() || "1" };
  }, [selectedFringe]);

  const [otMultipliers, setOtMultipliers] = useState(initialMultipliers);
  const [perHourMaximums, setPerHourMaximums] = useState<RateGroupFringeTableEntry["per_hour_maximums"]>(
    selectedFringe?.per_hour_maximums
  );
  const hasAccessToMaximums = useHasAccessToFringeMaximums();
  const [isNonBonafide, setIsNonBonafide] = useState(selectedFringe?.non_bonafide);

  const [tenureDaysUntilEligible, setTenureDaysUntilEligible] = useState<
    RateGroupFringeTableEntry["tenure_days_until_eligible"]
  >(selectedFringe?.tenure_days_until_eligible);

  const form = useForm();
  const ledgerAccountOptions = useLedgerAccountOptions();
  const expenseAccountOptions = useLedgerAccountOptions({ includeCustomOption: includeWithEarningOption });

  const { integrations } = useContext(AppContext);

  const hasContractorsPlan = integrations.find(
    (i) => i.key === "contractors_plan" && i.connection && !i.connection.pending_setup
  );

  const [isContractorsPlan, setIsContractorsPlan] = useState(
    selectedFringe?.integrations?.contractors_plan?.is_cp_benefit
  );

  const [selectedCategory, setSelectedCategory] = useState(
    categoryOptions.find((o) => o.value === selectedFringe?.category)
  );

  const [isAssociatedWithBenefit, setIsAssociatedWithBenefit] = useState(
    !!selectedFringe?.check_benefit_type
  );

  const [checkBenefitType, setCheckBenefitType] = useState(selectedFringe?.check_benefit_type);

  const [eligibleHourlyEarnings, setEligibleHourlyEarnings] = useState(
    selectedFringe?.eligible_hourly_earnings || "hours_worked"
  );

  const save = (data: {
    label: string;
    category: Option<UnionRateFringe["category"]>;
    expense_account_id?: Option<string>;
    liability_account_id?: Option<string>;
  }) => {
    const { label, category, expense_account_id, liability_account_id } = data;
    if (!fringeType) return;

    const cleanedOtMultipliers = Object.entries(otMultipliers).reduce((obj, [key, value]) => {
      if (!value || value === "1") return obj;
      const num = Number(value);
      if (isNaN(num)) return obj;
      if (!obj) obj = {};
      obj[key] = num;
      return obj;
    }, null as UnionRateFringe["ot_multipliers"]);

    let cleanedFringeMaxes: UnionRateFringe["per_hour_maximums"] | null = null;
    if (perHourMaximums?.hours_per_day || perHourMaximums?.hours_per_pay_period) {
      cleanedFringeMaxes = {};
      for (const prop of ["hours_per_day", "hours_per_pay_period"] as const) {
        if (perHourMaximums[prop]) {
          if (perHourMaximums[prop]! < 0) {
            Notifier.error("Fringe maximums cannot be negative.");
            return;
          }
          cleanedFringeMaxes[prop] = perHourMaximums[prop];
        }
      }
    }

    setTableData((prev) => {
      const newData = cloneDeep(prev);
      newData.forEach((rate, i) => {
        if (!selectedFringe && i === 0) {
          // If this is a brand new fringe, we need to add it to at least one classification, so let's just use the first one
          // The classification table logic will automatically duplicate it to other classifications as necessary
          const dynamicOffset = selectedContributionType?.value === "dynamic";
          const calcMethod = dynamicOffset ? "per_hour" : selectedContributionType?.value || "per_hour";
          const cleanCheckBenType =
            isAssociatedWithBenefit || fringeType === "benefit_type_deduction" ? checkBenefitType : undefined;
          rate.fringes.push({
            _id: ObjectID().toString(),
            fringe_group_id: ObjectID().toString(),
            label: label,
            category: category.value,
            type: fringeType,
            check_benefit_type: cleanCheckBenType,
            expense_account_id: expense_account_id?.value || undefined,
            liability_account_id: liability_account_id?.value || undefined,
            calculation_method: calcMethod as UnionRateFringe["calculation_method"],
            dynamic_offset_eligible: dynamicOffset,
            ot_multipliers: cleanedOtMultipliers,
            eligible_hourly_earnings: eligibleHourlyEarnings,
            amount: 0,
            integrations: { contractors_plan: { is_cp_benefit: !!isContractorsPlan } },
            per_hour_maximums: cleanedFringeMaxes,
            tenure_days_until_eligible: tenureDaysUntilEligible || undefined,
            non_bonafide: isNonBonafide || undefined,
          });
        } else if (selectedFringe) {
          for (const fringe of rate.fringes) {
            if (fringe.fringe_group_id === selectedFringe._id) {
              // category, type, check_benefit_type are immutable
              fringe.label = label;
              fringe.ot_multipliers = cleanedOtMultipliers;
              fringe.tenure_days_until_eligible = tenureDaysUntilEligible || undefined;
              fringe.per_hour_maximums = cleanedFringeMaxes;
              fringe.eligible_hourly_earnings = eligibleHourlyEarnings;
              fringe.expense_account_id = expense_account_id?.value || undefined;
              fringe.liability_account_id = liability_account_id?.value || undefined;
              fringe.integrations = {
                ...fringe.integrations,
                contractors_plan: { is_cp_benefit: !!isContractorsPlan },
              };
              fringe.non_bonafide = isNonBonafide || undefined;
            }
          }
        }
        rate.changed = true;
      });
      return newData;
    });
    setUnsavedData(true);
    onHide();
  };

  const canAssociateBensWithNTCs = useCanAssociateBensWithNonTaxableContributions();

  const showDynamicOption = !prgHasDynamicOffset && fringeType === "non_taxable_contribution";

  const contributionTypeOptions = useMemo<Option<string>[]>(() => {
    const arr = [
      { label: "$/hour", value: "per_hour" },
      { label: "% of earnings", value: "percent" },
    ];

    if (!fringeType || fringeType === "non_taxable_contribution" || isDeduction) {
      arr.push({ label: "$/week", value: "per_week" });
      if (!isDeduction) {
        arr.push({ label: "$/month (lump sum)", value: "per_month_lump_sum" });
      }
    }

    if (showDynamicOption) {
      arr.push({ label: "Dynamic offset", value: "dynamic" });
    }
    return arr;
  }, [showDynamicOption, fringeType]);

  const [selectedContributionType, setSelectedContributionType] = useState(() => {
    const defaultTypeOption = selectedFringe?.dynamic_offset_eligible
      ? "dynamic"
      : selectedFringe?.calculation_method || "per_hour";

    return contributionTypeOptions.find((o) => o.value === defaultTypeOption);
  });

  useEffect(() => {
    // Some contribution type options aren't available for some fringe types, but per_hour is always available.
    if (selectedFringe) return;
    setSelectedContributionType(contributionTypeOptions.find((o) => o.value === "per_hour"));
  }, [fringeType, selectedFringe]);

  useEffect(() => {
    // most people want percent fringes to have OT multipliers of 1.5/2.0
    if (selectedFringe) return;
    if (selectedContributionType?.value === "percent") {
      setOtMultipliers({ ot: "1.5", dot: "2" });
    } else {
      setOtMultipliers({ ot: "1", dot: "1" });
    }
  }, [selectedContributionType, selectedFringe]);

  const contributionTypeTooltip = useMemo(() => {
    const dynamicTooltip =
      "Dynamic contributions offset the entirety of the classification's fringe rate, minus other employer contributions, ensuring no fringe is paid in cash.";
    if (selectedFringe?.dynamic_offset) return dynamicTooltip;

    if (selectedFringe && selectedFringe.calculation_method !== "percent") return "";

    const earningsTooltip =
      "In the context of a fringe offset, % of earnings is calculated using the classification's base rate. The final fringe amount will ultimately be based on final classification earnings.";
    if (isDeduction || !showDynamicOption) {
      return earningsTooltip;
    }

    return `${dynamicTooltip} ${earningsTooltip} `;
  }, [isDeduction, selectedFringe, showDynamicOption]);

  const handleOtMultiplierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeTypeKey = e.target.name.split("_")[0];
    if (timeTypeKey && ["ot", "dot"].includes(timeTypeKey)) {
      setOtMultipliers((prev) => ({ ...prev, [timeTypeKey]: e.target.value }));
    }
  };

  const handleFringeMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value) || undefined;
    setPerHourMaximums((prev) => ({ ...prev, [e.target.name]: val }));
  };

  const showMaximums =
    fringeType === "non_taxable_contribution" &&
    selectedContributionType?.value === "per_hour" &&
    hasAccessToMaximums;

  const categoryLabelStyle = { fontWeight: "bold", marginBottom: 15, marginTop: 25 };

  return (
    <ActionModal
      headerText={selectedFringe ? "Edit fringe" : "Add a fringe"}
      submitText={selectedFringe ? "Save" : "Add"}
      onHide={onHide}
      showSubmit={true}
      showCancel={true}
      onCancel={onHide}
      onSubmit={form.handleSubmit(save)}
    >
      <div style={categoryLabelStyle}>Basics</div>
      <Formblock
        type="text"
        label="Description"
        form={form}
        defaultValue={selectedFringe?.label}
        name="label"
        editing={true}
        className="modal"
        val={{ required: "This field is required." }}
      />
      <Formblock
        type="select"
        name="type"
        value={typeOptions.find((o) => o.value === fringeType)}
        defaultValue={selectedFringe?.type}
        onChange={(o) => setFringeType(o.value)}
        options={typeOptions}
        label="Fringe type"
        labelInfo="Contributions are paid by the employer to a third-party; deductions are withheld from employee pay; earnings are paid by the employer to the employee."
        form={form}
        editing={true}
        className="modal"
        disabled={!!selectedFringe}
        requiredSelect
      />
      {isNonTaxableContribution && canAssociateBensWithNTCs && (
        <Formblock
          name="is_associated_with_benefit"
          type="checkbox"
          label="Associated with a pre-tax benefit"
          labelInfo="If checked, Miter will subject these fringes to contribution limits and report them as required on tax forms."
          text="This fringe is a true 401(k) or HSA plan."
          form={form}
          defaultValue={isAssociatedWithBenefit}
          onChange={(e) => setIsAssociatedWithBenefit(e.target.checked)}
          editing={true}
          className="modal"
          disabled={!!selectedFringe}
        />
      )}
      {(isAssociatedWithBenefit || fringeType === "benefit_type_deduction") && (
        <Formblock
          type="select"
          name="check_benefit_type"
          value={benefitTypeOptions.find((o) => o.value === checkBenefitType)}
          onChange={(o) => setCheckBenefitType(o.value)}
          options={benefitTypeOptions}
          label="Associated benefit type"
          form={form}
          editing={true}
          defaultValue={selectedFringe?.check_benefit_type}
          className="modal"
          disabled={!!selectedFringe}
          requiredSelect
        />
      )}
      <Formblock
        type="select"
        name="category"
        defaultValue={selectedFringe?.category}
        value={selectedCategory}
        onChange={setSelectedCategory}
        options={categoryOptions}
        label="Category"
        form={form}
        editing={true}
        className="modal"
        disabled={!!selectedFringe}
        requiredSelect
      />
      <Formblock
        label={`Calculation method`}
        className="modal"
        type="select"
        options={contributionTypeOptions}
        name="contribution_type"
        form={form}
        value={selectedContributionType}
        onChange={setSelectedContributionType}
        labelInfo={contributionTypeTooltip}
        disabled={!!selectedFringe}
        editing={true}
      />
      {(selectedContributionType?.value === "per_week" ||
        selectedContributionType?.value === "per_month_lump_sum") && (
        <div className="yellow-text-container">
          <span className="bold">Note</span>: this fringe will only apply to hourly union employees assigned a
          classification within this Pay Rate Group as their normal pay rate.
        </div>
      )}
      {(selectedContributionType?.value === "per_hour" || selectedContributionType?.value === "percent") && (
        <>
          <Formblock
            label={`Applies to`}
            className="modal"
            type="select"
            options={eligibleHourlyEarningsOptions}
            labelInfo="Hours paid includes all hours paid, including time off and holiday hours."
            name="eligible_hourly_earnings"
            form={form}
            value={eligibleHourlyEarningsOptions.find((o) => o.value === eligibleHourlyEarnings)}
            onChange={(o) => setEligibleHourlyEarnings(o.value)}
            editing={true}
          />
          {!isDeduction && (
            <Formblock
              type="checkbox"
              text="This fringe should not offset prevailing wage fringe rates."
              name="non_bonafide"
              checked={isNonBonafide}
              onChange={(e) => setIsNonBonafide(e.target.checked)}
              form={form}
              editing={true}
              className="modal"
            />
          )}
          <div style={categoryLabelStyle}>OT multipliers</div>
          <div className="flex" style={{ marginBottom: -10 }}>
            <Formblock
              type="text"
              label="OT multiplier"
              labelInfo={getOtMultiplierTooltip("ot", "fringes")}
              form={form}
              value={otMultipliers?.ot?.toString() ?? "1"}
              onChange={handleOtMultiplierChange}
              name="ot_multiplier"
              editing={true}
              className="modal"
              val={{ required: "This field is required." }}
            />
            <div style={{ width: 15 }}></div>
            <Formblock
              type="text"
              label="DOT multiplier"
              labelInfo={getOtMultiplierTooltip("dot", "fringes")}
              form={form}
              value={otMultipliers?.dot?.toString() ?? "1"}
              onChange={handleOtMultiplierChange}
              name="dot_multiplier"
              editing={true}
              className="modal"
              val={{ required: "This field is required." }}
            />
          </div>

          {showMaximums && (
            <>
              <div style={categoryLabelStyle}>Maximums</div>
              <Formblock
                type="checkbox"
                text="Stop contributions after a certain # of hours worked."
                name="maximums"
                style={{ marginTop: -7 }}
                checked={!!perHourMaximums}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setPerHourMaximums(null);
                  } else {
                    setPerHourMaximums({ hours_per_day: 8, hours_per_pay_period: 40 });
                  }
                }}
                form={form}
                editing={true}
                className="modal"
              />
              {!!perHourMaximums && (
                <div className="flex">
                  <Formblock
                    type="text"
                    label="Hours per day"
                    form={form}
                    value={perHourMaximums?.hours_per_day?.toString()}
                    onChange={handleFringeMaxChange}
                    name="hours_per_day"
                    editing={true}
                    className="modal"
                    val={{ required: "This field is required." }}
                  />
                  <div style={{ width: 15 }}></div>
                  <Formblock
                    type="text"
                    label="Hours per pay period"
                    form={form}
                    value={perHourMaximums?.hours_per_pay_period?.toString()}
                    onChange={handleFringeMaxChange}
                    name="hours_per_pay_period"
                    editing={true}
                    className="modal"
                    val={{ required: "This field is required." }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      <div style={categoryLabelStyle}>Eligibilty</div>
      <Formblock
        type="checkbox"
        text="This fringe has tenure eligibility restrictions."
        name="tenureEligibility"
        style={{ marginTop: -7 }}
        checked={isNumber(tenureDaysUntilEligible)}
        onChange={(e) => {
          setTenureDaysUntilEligible(e.target.checked ? 90 : null);
        }}
        form={form}
        editing={true}
        className="modal"
      />
      {isNumber(tenureDaysUntilEligible) && (
        <Formblock
          type="text"
          label="# of days until employee is eligible"
          form={form}
          value={tenureDaysUntilEligible?.toString()}
          onChange={(e) => setTenureDaysUntilEligible(Number(e.target.value))}
          name="tenure_days_until_eligible"
          editing={true}
          className="modal"
          val={{ required: "This field is required." }}
        />
      )}
      <div style={categoryLabelStyle}>Accounting</div>
      {!isDeduction && (
        <Formblock
          type="select"
          name="expense_account_id"
          defaultValue={selectedFringe?.expense_account_id}
          options={expenseAccountOptions}
          label="GL expense account (optional)"
          form={form}
          editing={true}
          className="modal"
          isClearable
        />
      )}
      {!isCash && (
        <Formblock
          type="select"
          name="liability_account_id"
          defaultValue={selectedFringe?.liability_account_id}
          options={ledgerAccountOptions}
          label="GL liability account (optional)"
          form={form}
          editing={true}
          className="modal"
          isClearable
        />
      )}
      {!isDeduction && hasContractorsPlan && selectedCategory?.value === "pension" && (
        <Formblock
          name="is_contractors_plan"
          style={{ marginTop: 5 }}
          type="checkbox"
          text="Report contributions to The Contractors' Plan."
          checked={isContractorsPlan}
          onChange={(e) => setIsContractorsPlan(e.target.checked)}
          editing={true}
          form={form}
          className="modal"
        />
      )}
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};

export const categoryOptions = [
  { label: "Health & Welfare", value: "health_and_welfare" },
  { label: "Pension", value: "pension" },
  { label: "Training", value: "training" },
  { label: "Union dues", value: "dues" },
  { label: "Vacation/holiday", value: "vacation_holiday" },
  { label: "Travel/subsistence", value: "travel_subsistence" },
  { label: "Supplemental unemployment benefit", value: "sub" },
  { label: "Other", value: "other" },
];

export const benefitTypeOptions: Option<CheckBenefitType>[] = [
  { label: "401(k)", value: "401k" },
  { label: "HSA", value: "hsa" },
];

export const hsaLimitOptions: Option<CheckHSALimit>[] = [
  { label: "Single", value: "single" },
  { label: "Family", value: "family" },
];

const eligibleHourlyEarningsOptions = [
  { label: "Hours worked", value: "hours_worked" },
  { label: "Hours paid", value: "hours_paid" },
];

export const typeOptions: Option<UnionRateFringeType>[] = [
  { label: "Non-taxable contribution", value: "non_taxable_contribution" },
  { label: "Taxable contribution", value: "taxable_contribution" },
  { label: "Pre-tax deduction", value: "benefit_type_deduction" },
  { label: "Post-tax deduction", value: "post_tax_deduction" },
  { label: "Cash earning", value: "taxable_earning" },
];

export const isFringeDeduction = (
  fringeType: UnionRateFringeType | undefined
): fringeType is "post_tax_deduction" | "benefit_type_deduction" => {
  return fringeType === "post_tax_deduction" || fringeType === "benefit_type_deduction";
};

export const canBeAssociatedWithBenefit = (
  fringeType: UnionRateFringeType | undefined
): fringeType is "non_taxable_contribution" | "benefit_type_deduction" => {
  return fringeType === "non_taxable_contribution" || fringeType === "benefit_type_deduction";
};
