import { useTeam } from "dashboard/hooks/atom-hooks";
import { buildFlatfileMessage } from "dashboard/utils/flatfile";
import React, { useContext, useMemo } from "react";
import { AggregatedEmployeeBenefit, ImportResult, MiterAPI, PostTaxDeduction } from "dashboard/miter";
import { ImportField, Importer } from "../../../components/importer/Importer";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { Notifier } from "ui";
import { createObjectMap } from "dashboard/utils/utils";

type Props = {
  ptds: PostTaxDeduction[];
  employeeBenefits: AggregatedEmployeeBenefit[];
  onFinish: (params: ImportResult) => void;
};

export type PayrollOverride = {
  teamMemberId: string;
  overrideType: "benefit" | "ptd";
  overrideSubType: string;
  companyContribution: string;
  employeeContribution: string;
};

export const PayrollOverridesImporter: React.FC<Props> = ({ onFinish, ptds, employeeBenefits }) => {
  const { payroll } = useContext(PayrollContext);
  const teamMembers = useTeam();

  const validFriendlyIds: Set<string> = useMemo(() => {
    const tmIdSet = (payroll?.miter_payments || []).reduce(
      (acc, mp) => (mp.onboarded ? acc.add(mp.team_member.friendly_id) : acc),
      new Set<string>()
    );
    return tmIdSet;
  }, [payroll, teamMembers]);

  const validTeamMembers = useMemo(() => {
    return teamMembers.filter((employee) => validFriendlyIds.has(employee.friendly_id));
  }, [teamMembers]);

  const lookupTeamMemberId = useMemo(
    () => createObjectMap(validTeamMembers, (t) => t._id),
    [validTeamMembers]
  );

  const lookupTeamMemberFriendlyID = useMemo(
    () => createObjectMap(validTeamMembers, (t) => t.friendly_id),
    [validTeamMembers]
  );

  const lookupPtd = createObjectMap(ptds, (ptd) => ptd.check_post_tax_deduction.id);
  const lookupBenefit = createObjectMap(employeeBenefits, (b) => b.check_benefit.id);

  const subTypeOptions = useMemo(() => {
    const options: { label: string; value: string }[] = [];
    employeeBenefits?.forEach((benefit) => {
      options.push({
        label:
          benefit.employee.friendly_id +
          "-" +
          benefit.check_benefit.benefit +
          "-" +
          benefit.check_benefit.description,
        value: benefit.check_benefit?.id,
      });
    });

    ptds?.forEach((ptd) => {
      options.push({
        label:
          lookupTeamMemberId[ptd.employee]?.friendly_id +
          "-" +
          ptd.check_post_tax_deduction.type +
          "-" +
          ptd.check_post_tax_deduction.description,
        value: ptd.check_post_tax_deduction?.id,
      });
    });
    return options;
  }, [ptds, employeeBenefits]);

  /** VALIDATIONS */
  const validateTeamMemberID = (teamMemberId: string | undefined) => {
    if (teamMemberId) {
      const idWithoutWhiteSpace = teamMemberId.trim();

      const teamMember = lookupTeamMemberFriendlyID[idWithoutWhiteSpace];
      if (!teamMember) {
        return buildFlatfileMessage("Team member not found", idWithoutWhiteSpace, "error");
      }

      return { value: idWithoutWhiteSpace };
    }
  };

  const amountIsNonNegativeNumberOrEmpty = (value: string | undefined) => {
    if (!value) return true;
    const num = Number(value);
    return !isNaN(num) && num >= 0;
  };

  const validateEmployeeAmount = (record: PayrollOverride) => {
    if (!amountIsNonNegativeNumberOrEmpty(record.employeeContribution)) {
      return buildFlatfileMessage(
        "Must be a non-negative number or empty",
        record.employeeContribution,
        "error"
      );
    }
    return { value: record.employeeContribution };
  };

  const validateCompanyAmount = (record: PayrollOverride) => {
    const value = record.companyContribution;
    if (value && record.overrideType !== "benefit") {
      return buildFlatfileMessage(
        "Only benefits can have company amounts",
        record.companyContribution,
        "error"
      );
    }

    if (!amountIsNonNegativeNumberOrEmpty(record.companyContribution)) {
      return buildFlatfileMessage(
        "Must be a non-negative number or empty",
        record.companyContribution,
        "error"
      );
    }

    return { value: record.companyContribution };
  };

  const validateType = (record: PayrollOverride) => {
    const type = record.overrideType;
    const subType = record.overrideSubType;

    const deduction = lookupPtd[subType];
    const benefit = lookupBenefit[subType];

    if (type === "benefit" && deduction) {
      return buildFlatfileMessage("The subtype selected is a post tax deduction", type, "error");
    }

    if (type === "ptd" && benefit) {
      return buildFlatfileMessage("The subtype selected is a benefit", type, "error");
    }

    return { value: record.overrideType };
  };

  const validateSubType = (record: PayrollOverride) => {
    const subType = record.overrideSubType;
    const tmId = lookupTeamMemberFriendlyID[record.teamMemberId]?._id;

    if (lookupPtd[subType]?.employee != tmId && lookupBenefit[subType]?.employee?._id != tmId) {
      return buildFlatfileMessage(
        "This benefit or deduction does not belong to this employee",
        subType,
        "error"
      );
    }

    return { value: record.overrideSubType };
  };

  const handleSubmit = async (results) => {
    try {
      const cleanInput = results.validData.map((input) => {
        return { ...input, teamMemberId: lookupTeamMemberFriendlyID[input.teamMemberId]?._id };
      });

      if (!payroll?.company._id)
        throw new Error(
          "There was an error creating payroll overrides. Company not found " + payroll?.company._id
        );

      const response = await MiterAPI.payrolls.import_overrides(payroll!._id, {
        clean_inputs: cleanInput,
        raw_inputs: results.validData,
        companyId: payroll?.company._id,
      });

      if (response.error) throw new Error(response.error);
      onFinish(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating payroll overrides.");
    }

    return;
  };
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Employee ID number",
        type: "string",
        key: "teamMemberId",
        description: "Unique identifer for team member (must be same in source system and Miter).",
        validators: [{ validate: "required" }],
        hook: (record) =>
          typeof record === "string" ? { value: record } : validateTeamMemberID(record.teamMemberId),
      },
      {
        label: "Type",
        type: "select",
        key: "overrideType",
        options: [
          { label: "Benefit", value: "benefit" },
          { label: "Post tax deduction", value: "ptd" },
        ],
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record === "string" ? { value: record } : validateType(record)),
      },
      {
        label: "Sub Type",
        type: "select",
        key: "overrideSubType",
        options: subTypeOptions,
        validators: [{ validate: "required" }],
        hook: (record) => (typeof record === "string" ? { value: record } : validateSubType(record)),
      },
      {
        label: "Employee Amount",
        type: "string",
        key: "employeeContribution",
        hook: (record) => (typeof record === "string" ? { value: record } : validateEmployeeAmount(record)),
      },
      {
        label: "Company Amount",
        type: "string",
        key: "companyContribution",
        hook: (record) => (typeof record === "string" ? { value: record } : validateCompanyAmount(record)),
      },
    ];
    return fieldList;
  }, [subTypeOptions]);

  return (
    <Importer id="payroll_overrides" resource="payroll overrides" onSave={handleSubmit} fields={fields} />
  );
};
