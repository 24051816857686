import React, { useState } from "react";
import { ActionModal, Button } from "ui";
import styles from "./LinkNewCardModal.module.css";
import mastercard from "dashboard/assets/mastercard.png";
import visa from "dashboard/assets/visa.png";
import amex from "dashboard/assets/amex.png";
import { useAstradaModal } from "dashboard/hooks/useAstradaModal";
import { getPlaidLinkToken } from "dashboard/utils/expenses";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type NetworkOptions = "mastercard" | "other";

const LinkOptionCard: React.FC<{
  network: NetworkOptions;
  handleNewCardLink: (network: NetworkOptions) => void;
  isAstradaLoading: boolean;
  isPlaidLoading: boolean;
}> = ({ network, handleNewCardLink, isAstradaLoading, isPlaidLoading }) => {
  const connectionDescrtiption =
    network === "mastercard"
      ? "Real-time connection. Cardholders will recieve a text immediately after a purchase is made."
      : "Direct connection. Miter will be notified of purchases 6-24 hours after a purchase is made.";

  // if Mastercard, open Astrada. else, open Plaid
  return (
    <div className={styles["link-card-modal-wrapper"]}>
      <div>
        <div className="flex">
          <div className="bold">{network === "mastercard" ? "Mastercard" : "Visa or American Express"}</div>
          {network === "mastercard" ? (
            <img src={mastercard} style={{ height: 25 }} />
          ) : (
            <div className="flex">
              <img src={visa} style={{ height: 25, marginLeft: 5 }} />
              <img src={amex} style={{ height: 25, marginLeft: 5 }} />
            </div>
          )}
        </div>
        <div className={styles["description"]}>{connectionDescrtiption}</div>
      </div>
      <div className="flex">
        <div className="flex-1"></div>
        <Button
          className="bold button-text blue-link"
          onClick={() => handleNewCardLink(network)}
          loading={network === "mastercard" ? isAstradaLoading : isPlaidLoading}
        >
          Link
        </Button>
      </div>
    </div>
  );
};

const LinkNewCardModal: React.FC<{
  setPlaidLinkToken: (token: string) => void;
  onHide: (shouldRefresh?: boolean) => void;
}> = ({ setPlaidLinkToken, onHide }) => {
  const activeCompanyId = useActiveCompanyId();
  const { openAstradaModal, isAstradaModalLoading } = useAstradaModal({
    onSuccess: async () => {
      // close parent modal
      onHide(true);
    },
  });
  const [isPlaidLoading, setIsPlaidLoading] = useState(false);

  const handleNewCardLink = async (network: NetworkOptions) => {
    if (network === "mastercard") {
      await openAstradaModal();
    } else {
      await getPlaidLinkToken({
        company: activeCompanyId,
        external_financial_account_type: "company",
        setLoading: setIsPlaidLoading,
        setPlaidLinkToken,
        products: ["transactions"],
      });
    }
  };

  return (
    <ActionModal
      headerText={"Link a new credit card"}
      showSubmit={false}
      showCancel={true}
      cancelText={"Cancel"}
      onCancel={() => onHide()}
      onHide={onHide}
      wrapperStyle={{ width: "800px" }}
    >
      <div className="flex">
        <LinkOptionCard
          network="mastercard"
          handleNewCardLink={handleNewCardLink}
          isAstradaLoading={isAstradaModalLoading}
          isPlaidLoading={isPlaidLoading}
        />
        <LinkOptionCard
          network="other"
          handleNewCardLink={handleNewCardLink}
          isAstradaLoading={isAstradaModalLoading}
          isPlaidLoading={isPlaidLoading}
        />
      </div>
    </ActionModal>
  );
};

export default LinkNewCardModal;
