import React, { useEffect, useMemo, useState } from "react";
import Banner from "dashboard/components/shared/Banner";
import { useActiveCompanyId, useActiveTeam, useLookupTeam, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { LargeModal, Notifier, TableV2 } from "ui";
import { BulkUpdateResult, MiterAPI } from "dashboard/miter";
import {
  TmCompanyDefinedAttributes,
  UpdateCompanyDefinedAttributesEntry,
} from "backend/services/team-member-service";
import { ColumnConfig, ColumnGroupConfig } from "ui/table-v2/Table";
import { CheckCompanyDefinedAttribute } from "backend/utils/check/check-types";
import { useNavigate } from "react-router-dom";
import { Checkmark, Alert } from "dashboard/components/icons/icons";
import { createObjectMap } from "dashboard/utils";
import { EditableCallbackParams, ValueFormatterFunc, ValueGetterParams } from "ag-grid-community";
import { Option } from "ui/form/Input";
import { baseSensitiveCompare, sleep } from "miter-utils";
import { DateTime } from "luxon";
import { IGNORE_EDIT_REQUEST_CHANGE } from "ui/constants";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

export const CompanyDefinedAttributesBanner: React.FC = () => {
  // Hooks
  const activeTeam = useActiveTeam();
  const navigate = useNavigate();
  const { cannot } = useMiterAbilities();

  const empsNeedingCompDefinedAttrs = useMemo(() => {
    return activeTeam.filter((tm) =>
      tm.check_tm?.onboard.remaining_steps.includes("company_defined_attributes")
    );
  }, [activeTeam]);
  const cdaEmpCount = empsNeedingCompDefinedAttrs.length;

  const bannerText = () => {
    return `There ${cdaEmpCount > 1 ? "are" : "is"} ${cdaEmpCount} employee${
      cdaEmpCount > 1 ? "s" : ""
    } we need you to answer a few questions about. Click here.`;
  };

  if (!cdaEmpCount || cannot("team:update_sensitive")) return null;

  return (
    <>
      <div className="vertical-spacer-small" />
      <Banner type="warning" content={bannerText()} onClick={() => navigate("/team-members?editing=cda")} />
    </>
  );
};

type CompanyDefinedAttributeTableEntry = {
  _id: string;
  tmName: string;
  tmFriendlyId: string;
  anyMissing: boolean;
  attributes: Record<string, { value: string | null; effective_start: string | null }>;
};

export const CompanyDefinedAttributesModal: React.FC<{ hide: () => void; tmId?: string }> = ({
  hide,
  tmId,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const lookupTeam = useLookupTeam();
  const refetchTeam = useRefetchTeam();
  const teamAbilities = useTeamAbilities();

  const [loading, setLoading] = useState(false);
  const [rawEntries, setRawEntries] = useState<TmCompanyDefinedAttributes[]>();

  const [formattedEntries, attributeMap] = useMemo(() => {
    const attrMap = new Map<string, CheckCompanyDefinedAttribute>();
    if (!rawEntries) return [, attrMap];

    const attrs: CompanyDefinedAttributeTableEntry[] = [];
    for (const raw of rawEntries) {
      const tm = lookupTeam(raw.tmId);
      if (!tm) continue;

      // Only show employees that the user can edit
      if (teamAbilities.cannot("update_sensitive", tm)) continue;

      const entry: CompanyDefinedAttributeTableEntry = {
        _id: raw.tmId,
        tmName: tm.full_name,
        tmFriendlyId: tm.friendly_id,
        anyMissing: false,
        attributes: {},
      };

      for (const attr of raw.attributes) {
        entry.attributes[attr.name] = {
          value: attr.value,
          effective_start: attr.effective_start,
        };

        if (!attr.value) {
          entry.anyMissing = true;
        }

        attrMap.set(attr.name, attr);
      }
      attrs.push(entry);
    }

    attrs.sort((a, b) => {
      if (a.anyMissing && !b.anyMissing) return -1;
      if (!a.anyMissing && b.anyMissing) return 1;
      return baseSensitiveCompare(a.tmName, b.tmName);
    });

    return [attrs, attrMap];
  }, [rawEntries, lookupTeam]);

  const columns = useMemo(() => {
    if (!rawEntries) return [];
    const cols: ColumnGroupConfig<CompanyDefinedAttributeTableEntry>[] = [
      {
        headerName: "Employee",
        children: [
          {
            field: "tmFriendlyId",
            headerName: "Employee ID",
            dataType: "string",
            width: 150,
            pinned: "left",
          },
          {
            field: "tmName",
            headerName: "Employee Name",
            dataType: "string",
            width: 150,
            pinned: "left",
          },
          {
            field: "anyMissing",
            headerName: "Status",
            dataType: "component",
            cellRenderer: (params) => (params.data?.anyMissing ? <Alert /> : <Checkmark />),
            pinned: "left",
          },
        ],
      },
    ];

    for (const [attrName, attr] of attributeMap) {
      const options: Option<string>[] = attr.options || [];

      let editorType: "text" | "number" | "select" = "number";
      if (options.length) editorType = "select";
      else if (attr.type === "string") editorType = "text";

      let mainValueFormatter: ValueFormatterFunc<CompanyDefinedAttributeTableEntry>;
      if (editorType === "select") {
        mainValueFormatter = (params) => {
          const option = options.find((o) => o.value === params.value);
          const final = option?.label || params.value;
          return final === undefined ? "N/A" : final || "-"; // detect difference between required/applicable vs not using undefined vs null
        };
      } else {
        mainValueFormatter = (params) =>
          params.data?.attributes[attrName]?.value === undefined ? "N/A" : params.value || "-";
      }

      const isRequiredCallback = (
        params:
          | ValueGetterParams<CompanyDefinedAttributeTableEntry>
          | EditableCallbackParams<CompanyDefinedAttributeTableEntry>
      ) => params.data?.attributes[attrName]?.value !== undefined;

      const customValueEditor = (newValue: $TSFixMe, data: CompanyDefinedAttributeTableEntry) => {
        if (data.attributes[attrName]?.value === undefined) return IGNORE_EDIT_REQUEST_CHANGE;
        return newValue;
      };

      const children: ColumnConfig<CompanyDefinedAttributeTableEntry>[] = [
        {
          field: `attributes.${attrName}.required`,
          headerName: "Required",
          dataType: "boolean",
          valueGetter: isRequiredCallback,
        },
        {
          field: `attributes.${attrName}.value`,
          headerName: "Value",
          editable: isRequiredCallback,
          editorType,
          valueFormatter: mainValueFormatter,
          cellEditorParams: { options },
          valueEditor: customValueEditor,
        },
      ];

      if (attr.effective_start_required) {
        children.push({
          field: `attributes.${attrName}.effective_start`,
          headerName: "Effective start",
          dataType: "date",

          editable: isRequiredCallback,
          editorType: "date",
          editorDateType: "iso",
          valueFormatter: (params) => {
            if (params.data?.attributes[attrName]?.value === undefined) return "N/A";
            if (!params.value) return "-";
            const dt = DateTime.fromISO(params.value);
            if (!dt.isValid) return "-";
            return dt.toFormat("LLL dd, yyyy");
          },
          valueEditor: customValueEditor,
        });
      }

      cols.push({
        headerName: attr.label,
        headerTooltip: attr.description,
        children,
      });
    }
    return cols;
  }, [rawEntries, attributeMap]);

  useEffect(() => {
    if (!activeCompanyId) return;
    const getData = async () => {
      setLoading(true);
      try {
        const res = await MiterAPI.team_member.retrieve_company_defined_attributes(
          activeCompanyId,
          tmId ? [tmId] : undefined
        );
        if (res.error) throw new Error(res.error);

        setRawEntries(res);
      } catch (e: $TSFixMe) {
        console.log(e);
        Notifier.error(e.message);
      }
      setLoading(false);
    };
    getData();
  }, [activeCompanyId]);

  const handleSave = async (
    updatedEntries: CompanyDefinedAttributeTableEntry[]
  ): Promise<BulkUpdateResult> => {
    const ret: BulkUpdateResult<TmCompanyDefinedAttributes> = { successes: [], errors: [] };
    try {
      const existingEntryLookup = createObjectMap(formattedEntries || [], (o) => o._id);

      const fullUpdates: UpdateCompanyDefinedAttributesEntry[] = [];
      for (const updatedEntry of updatedEntries) {
        const existingEntry = existingEntryLookup[updatedEntry._id];
        if (!existingEntry) continue;

        const attrUpdates: UpdateCompanyDefinedAttributesEntry["attributes"] = [];

        // Ensure we only send back updates for attributes that have changed and actually apply to the given employee
        for (const [attrName, attrVals] of Object.entries(updatedEntry.attributes)) {
          // Unsetting values is not supported by Check, so let's cut that off here
          if (!attrVals.value) continue;

          // If the attribute is not required for this employee, we don't need to send it back
          const existingVals = existingEntry.attributes[attrName];
          if (!existingVals || existingVals.value === undefined) continue;

          const fullAttr = attributeMap.get(attrName);
          if (!fullAttr) continue;

          // Make sure it's different before sending it back
          const valueIsSame = attrVals.value === existingVals.value;
          const effectiveStartIsSame =
            !fullAttr.effective_start_required || attrVals.effective_start === existingVals.effective_start;

          if (valueIsSame && effectiveStartIsSame) continue;

          attrUpdates.push({
            name: attrName,
            value: String(attrVals.value), // all values are strings in Check's model
            effective_start: (fullAttr.effective_start_required && attrVals.effective_start) || undefined,
          });
        }

        if (attrUpdates.length) {
          fullUpdates.push({ tmId: updatedEntry._id, attributes: attrUpdates });
        }
      }

      if (fullUpdates.length === 0) return ret;

      const res = await MiterAPI.team_member.update_company_defined_attributes(fullUpdates);
      if (res.error) throw new Error(res.error);

      // Only need to refetch the team members that had their attributes updated AND were flagged for Check onboarding beforehand
      const tmIdsToRefetch: string[] = [];
      for (const entry of res.successes) {
        const tm = lookupTeam(entry.tmId);
        if (tm?.check_tm?.onboard.remaining_steps.includes("company_defined_attributes")) {
          tmIdsToRefetch.push(entry.tmId);
        }
      }

      // Give time for the backend to process the webhooks that Check sends that actually change everyones' attributes.
      sleep(5000).then(() => refetchTeam(tmIdsToRefetch));

      ret.successes = res.successes;
      ret.errors = res.errors.map((e) => {
        const entry = fullUpdates.find((u) => u.tmId === e._id);
        if (!entry) return e;

        return {
          ...e,
          fieldErrors: e.fieldErrors?.map((fe) => {
            if (fe.field === "value" || fe.field === "effective_start") {
              const index = fe.field_path?.[1];
              if (!index) return fe;

              const name = entry.attributes[Number(index)]?.name;
              if (!name) return fe;

              // Field error message generally comes in the form of "<attribute_name>: <message>", so let's parse that
              let message = fe.message;
              const colonIndex = fe.message.indexOf(":");
              if (colonIndex >= 0) {
                message = fe.message.slice(colonIndex + 1).trim();
              }

              const useEffectiveStart =
                fe.field === "effective_start" || fe.message.toLowerCase().includes("effective date");
              const finalField = useEffectiveStart ? "effective_start" : fe.field;
              return { ...fe, field: `attributes.${name}.${finalField}`, message };
            }
            return fe;
          }),
        };
      });

      if (res.errors.length) return ret;

      const successLookup = createObjectMap(res.successes, (o) => o.tmId);

      setRawEntries((prev) => {
        if (!prev) return prev;
        const updatedRaw: TmCompanyDefinedAttributes[] = [];
        for (const p of prev) {
          const success = successLookup[p.tmId];
          if (success) {
            updatedRaw.push(success);
          } else {
            updatedRaw.push(p);
          }
        }
        return updatedRaw;
      });
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`There was an error updating company-defined attributes: ${e.message}`);
    }

    return ret;
  };

  return (
    <LargeModal headerText="Edit company-defined payroll attributes" onClose={() => hide()}>
      <div className="height-100">
        <div style={{ maxHeight: 200 }}>
          <TableV2
            data={formattedEntries}
            columns={columns}
            resource="employees"
            id={`company-defined-attributes`}
            editable={true}
            onSave={handleSave}
            gridWrapperStyle={{ height: "55vh" }}
            disablePagination
            isLoading={loading}
          />
        </div>
      </div>
      <div className="vertical-spacer-small"></div>
    </LargeModal>
  );
};
