import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Notifier } from "ui";
import {
  useActiveCompanyId,
  useActiveDashboardLiveTimesheet,
  useActiveLiveTimesheetUpdateLocal,
  useActiveTeamMember,
  useSetActiveLiveTimesheetUpdateLocal,
} from "dashboard/hooks/atom-hooks";
import { Clock, ClockAfternoon, Hamburger } from "phosphor-react";
import { ActiveTimesheetButtonState, getFormattedElapsedTime } from "dashboard/utils/timesheetUtils";
import styles from "./ClockInButton.module.css";
import ClockInModal from "../timesheets/ClockInModal";
import { LiveTimesheet, MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import AppContext from "../../contexts/app-context";

const getButtonText = (
  liveTimesheet: LiveTimesheet | undefined | null,
  clockTime: string,
  shiftPage: boolean
) => {
  const iconStyles = `${styles["icon"]} ${styles[!shiftPage ? "icon-collapsed" : ""]}`;
  if (!liveTimesheet) {
    return (
      <>
        <Clock className={iconStyles} />
        {shiftPage && "Clock in"}
      </>
    );
  } else if (liveTimesheet.active_break_start || liveTimesheet.active_break_time) {
    return (
      <>
        <Hamburger className={iconStyles} />
        {clockTime}
      </>
    );
  } else {
    return (
      <>
        <ClockAfternoon className={iconStyles} />
        {clockTime}
      </>
    );
  }
};

const ClockInButton: React.FC = () => {
  const companyId = useActiveCompanyId();
  const teamMember = useActiveTeamMember();
  const originalLiveTimesheet = useActiveDashboardLiveTimesheet();
  const liveTimesheetUpdate = useActiveLiveTimesheetUpdateLocal();
  const setActiveLiveTimesheetUpdate = useSetActiveLiveTimesheetUpdateLocal();
  const { shiftPage } = useContext(AppContext);
  const [liveTimesheet, setLiveTimesheet] = useState(originalLiveTimesheet);
  const [modalOpen, setModalOpen] = useState(false);
  const [clockTime, setClockTime] = useState("");
  const [buttonText, setButtonText] = useState(<></>);
  const [buttonState, setButtonState] = useState<ActiveTimesheetButtonState>("no-live-timesheet");
  const isFirstRender = useRef(true);
  const [updateCount, setUpdateCount] = useState(0);

  const fetchLiveTimesheet = async () => {
    if (!teamMember || !companyId) return;
    try {
      const filter = [
        { field: "company_id", value: companyId },
        { field: "team_member_id", value: teamMember._id },
        { field: "archived", value: false },
      ];
      const res = await MiterAPI.live_timesheets.list({ filter });
      if (res.error) throw new Error(res.error);
      setLiveTimesheet((res?.[0] as LiveTimesheet) || null);
    } catch (e: $TSFixMe) {
      Notifier.error(e);
    }
  };

  const setActiveLiveTimesheet = (timesheet: LiveTimesheet | null) => {
    if (!teamMember) return;
    setActiveLiveTimesheetUpdate({ key: teamMember._id, value: updateCount + 1 });
    setLiveTimesheet(timesheet);
  };

  useEffect(() => {
    if (!teamMember) return;
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setLiveTimesheet(originalLiveTimesheet);
      if (originalLiveTimesheet) {
        if (liveTimesheetUpdate?.key === teamMember?._id && liveTimesheetUpdate?.value > 0) {
          setUpdateCount(liveTimesheetUpdate.value);
        }
        setActiveLiveTimesheetUpdate({ key: teamMember._id, value: updateCount + 1 });
      }
    } else if (
      liveTimesheetUpdate &&
      liveTimesheetUpdate.key === teamMember?._id &&
      liveTimesheetUpdate?.value > updateCount
    ) {
      fetchLiveTimesheet();
      setUpdateCount(liveTimesheetUpdate.value);
    }
  }, [liveTimesheetUpdate]);

  useEffect(() => {
    setButtonText(getButtonText(liveTimesheet, clockTime, shiftPage));
    if (!liveTimesheet) {
      setButtonState("no-live-timesheet");
    } else if (liveTimesheet.active_break_start) {
      setButtonState("on-break");
    } else {
      setButtonState("clocked-in");
    }
  }, [liveTimesheet, clockTime, shiftPage]);

  const timeOnJob = (): string => {
    const now = DateTime.now();
    if (liveTimesheet?.active_break_start) {
      return getFormattedElapsedTime(DateTime.fromSeconds(liveTimesheet?.active_break_start), now);
    }
    if (liveTimesheet?.clock_in) {
      return getFormattedElapsedTime(DateTime.fromSeconds(liveTimesheet?.clock_in), now);
    }
    return "";
  };

  useEffect(() => {
    const timer = setInterval(() => setClockTime(timeOnJob()), 1000);
    return () => clearInterval(timer);
  }, [liveTimesheet?.clock_in, liveTimesheet?.active_break_start]);

  return (
    <div>
      <Button
        className={`${styles["clock-in-button"]} ${styles[buttonState as string]} ${
          styles[!shiftPage ? "button-collapsed" : ""]
        }`}
        text={buttonText}
        onClick={() => setModalOpen(true)}
        uncenterText={true}
        style={{ alignItems: "left", justifyContent: shiftPage ? "left" : "" }}
      />
      {modalOpen && (
        <ClockInModal
          onHide={() => setModalOpen(false)}
          buttonState={buttonState}
          liveTimesheet={liveTimesheet}
          setLiveTimesheet={setActiveLiveTimesheet}
          clockTime={clockTime}
        />
      )}
    </div>
  );
};

export default ClockInButton;
