import React, { useEffect, useState } from "react";

import { Loader } from "ui";

import "./billing.css";
import { MiterAPI } from "../../../miter";
import PaymentMethodInfo from "./PaymentMethodInfo";
import Invoices from "./Invoices";
import Notifier from "dashboard/utils/notifier";
import { GetCompanyBillingInfoResponse } from "dashboard/miter";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useNavigate } from "react-router-dom";
import { SubscriptionInfo } from "./SubscriptionInfo";

const Billing: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const { cannot } = useMiterAbilities();

  const [billingInfo, setBillingInfo] = useState<GetCompanyBillingInfoResponse | null>(null);
  const [loading, setLoading] = useState(false);

  const getBillingInfo = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.companies.get_billing_info(activeCompanyId!);
      if (response.error) throw new Error(response.error);
      setBillingInfo(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving billing information. We're looking into it.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (cannot("company:billing:manage")) {
      Notifier.error("You do not have permission to manage billing information.");
      navigate("/home");
    }
  }, [cannot]);

  useEffect(() => {
    getBillingInfo();
  }, []);

  return (
    <div>
      <div className="vertical-spacer"></div>
      {loading && <Loader />}
      {!loading && billingInfo && (
        <div>
          <PaymentMethodInfo info={billingInfo} />
          <SubscriptionInfo info={billingInfo} />
          <Invoices info={billingInfo} refresh={getBillingInfo} />
        </div>
      )}
    </div>
  );
};

export default Billing;
