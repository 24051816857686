import React, { useEffect, useMemo, useState } from "react";
import { Formblock, Label, Notifier, WizardScreen } from "ui";
import styles from "./ATS.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import { CreateJobPostingParams } from "backend/services/ats/job-posting-service";
import * as vals from "dashboard/utils/validators";
import { states } from "miter-utils/lists";
import useWizard from "ui/modal/useWizard";
import { AggregatedTeamMember, MiterAPI, User } from "dashboard/miter";
import {
  useActiveCompanyId,
  useJobPostings,
  useLookupTeam,
  useTeamOptions,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { set } from "lodash";
import { AggregatedJobPosting } from "dashboard/types/ats";
import { hasHTMLContent } from "dashboard/utils";
import {
  TeamMemberGroupSelectValue,
  TeamMemberOptionGroup,
  useTeamMemberGroupOptions,
} from "../team-members/useTeamMemberGroupOptions";
import { isMiterRep, notNullish } from "miter-utils";
import { Option } from "ui/form/Input";
import { LookupAtomFunction } from "dashboard/atoms";
import { EMPLOYMENT_OPTIONS, PAY_TYPE_OPTIONS, WORKPLACE_OPTIONS } from "dashboard/utils/ats";

type Props = {
  name: string;
  jobPosting?: AggregatedJobPosting;
  setJobPosting: (jobPosting: AggregatedJobPosting) => void;
};

export const CreateJobPostingStep: React.FC<Props> = ({ name, jobPosting, setJobPosting }) => {
  /** Atom hooks */
  const lookupTeam = useLookupTeam();
  const activeUser = useUser();
  const teamOptions = useTeamOptions();
  const companyId = useActiveCompanyId();
  const jobPostings = useJobPostings();

  /** Wizard hooks */
  const { setCanNext } = useWizard();

  /** State management */
  const [description, setDescription] = useState(jobPosting?.description || "");
  const [selectedTmGroupOptions, setSelectedTmGroupOptions] = useState<Option<TeamMemberGroupSelectValue>[]>(
    []
  );

  /** Team member group generation */
  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    excludedGroups: [
      "self",
      "pay_type",
      "crew_lead",
      "department_head",
      "job_superintendent",
      "job_supervisor",
      "direct_managers",
      "all_team_members",
      "employment_type",
      "title",
    ],
    selectedGroupOptions: selectedTmGroupOptions,
    hideMitosaurs: !isMiterRep(activeUser),
  });

  /** Form state management*/
  const form = useForm<CreateJobPostingParams>({
    reValidateMode: "onChange",
    mode: "all",
    // @ts-expect-error fix me
    defaultValues: buildDefaultValues(jobPosting, {
      teamMemberGroupOptions,
      teamOptions,
      activeUser,
      lookupTeam,
    }),
  });

  const {
    register,
    errors,
    watch,
    control,
    handleSubmit,
    trigger,
    setError,
    formState: { errors: formErrors },
    clearErrors,
  } = form;

  const { workplace, pay, hiring_team, requisition_id } = watch();
  // @ts-expect-error fix me
  const isHourly = pay?.type?.value === "hourly";

  const stateOptions = states.map((state) => ({
    label: state.abbreviation,
    value: state.abbreviation,
  }));

  /** Memo and Effect callBacks */

  const descriptionHasContent = useMemo(() => {
    return hasHTMLContent(description);
  }, [description]);

  useEffect(() => {
    const isValidState =
      Object.keys(errors).length === 0 &&
      Object.keys(formErrors).length === 0 &&
      form.formState.isValid &&
      descriptionHasContent;
    setCanNext(isValidState);
  }, [errors, formErrors, form.formState.isValid, descriptionHasContent]);

  useEffect(() => {
    // @ts-expect-error fix me
    if (workplace?.work_type?.value === "remote") {
      clearErrors("workplace.address.city");
      clearErrors("workplace.address.state");
    }
  }, [workplace]);

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    // @ts-expect-error fix me
    setSelectedTmGroupOptions(hiring_team || []);
  }, [JSON.stringify(hiring_team)]);

  useEffect(() => {
    if (!requisition_id) {
      clearErrors("requisition_id");
    } else {
      const duplicateJp = jobPostings.find(
        (jp) => jp.requisition_id === requisition_id && jp._id !== jobPosting?._id
      );
      if (duplicateJp) {
        setError("requisition_id", {
          message: `Job posting ${duplicateJp.title} with requisition ID ${requisition_id} already exists.`,
        });
      } else {
        clearErrors("requisition_id");
      }
    }
  }, [requisition_id]);

  /** Submit function */

  const submit = async (data) => {
    try {
      /** Verify that requisition_id is unique */

      const jobPostingIsNotRemote =
        data?.workplace?.work_type.value !== "remote" ||
        (jobPosting && jobPosting.workplace.work_type !== "remote");
      if (jobPostingIsNotRemote) {
        // TODO: The line below is a hacky way to solve a bug. When updating a job posting, the city field is disabled
        // and uneditable, however, when updating, the field shows as undefined. The fix is to default the value
        // to the job posting's city value.
        data.workplace.address.city = jobPosting?.workplace?.address?.city || data.workplace.address.city;
      }
      const newData = {
        ...data,
        company_id: companyId,
        creator_user_id: activeUser?._id,
        employment_type: data.employment_type.value,
        description,
        hiring_team: (data.hiring_team || []).map((tm) => tm.value),
        hiring_manager_id: data.hiring_manager_id?.value || null,
        requisition_id: data.requisition_id || null,
      };
      set(newData, "pay.type", data.pay.type.value);
      set(newData, "workplace.work_type", data?.workplace?.work_type.value);
      set(newData, "workplace.address.state", data?.workplace?.address?.state?.value);
      if (newData.workplace.work_type === "remote") {
        newData.workplace.address = null;
      }

      set(newData, "external_job_boards", {
        indeed: {
          job_posting_id: jobPosting?.external_job_boards?.indeed?.job_posting_id,
        },
        linkedin: {
          enabled: false,
        },
      });

      const res = jobPosting
        ? await MiterAPI.job_postings.update(jobPosting._id, newData)
        : await MiterAPI.job_postings.create(newData);
      if (res.error) {
        throw new Error(res.error);
      }
      if (jobPosting) {
        Notifier.success("Updated job posting!");
      } else {
        Notifier.success("Created new job posting!");
      }
      setJobPosting(res);
    } catch (e: $TSFixMe) {
      if (jobPosting) {
        Notifier.error(`Failed to update: ${e.message}`);
        return;
      } else {
        Notifier.error(`Failed to create: ${e.message}`);
      }
    }
  };

  const onNext = async () => {
    handleSubmit(submit)();
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <Formblock
          name="title"
          type="text"
          label="Title*"
          register={register(vals.required)}
          errors={errors}
          editing={true}
          className="modal wizard"
          placeholder="Carpentry apprentice"
          defaultValue={jobPosting?.title}
        />
        <Formblock
          name="requisition_id"
          type="text"
          label="Requisition ID"
          sublabel="Optional unique identifier for the job posting."
          errors={errors}
          register={register}
          editing={true}
          className="modal wizard"
          placeholder="Optional"
          defaultValue={jobPosting?.requisition_id}
        />
        <div>
          <Label label="Description*" labelStyle={{ fontSize: "1rem", marginBottom: 9 }}></Label>
          <div style={{ backgroundColor: "white" }}>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={setDescription}
              placeholder="Add job description here..."
            />
          </div>
          {!descriptionHasContent && (
            <div className="error" style={{ fontSize: 13, marginTop: 3 }}>
              This field is required.
            </div>
          )}
        </div>
        <div className={styles["form-section"]}>
          <Formblock
            name="workplace.work_type"
            type="select"
            label="Workplace*"
            register={register(vals.required)}
            errors={errors}
            editing={true}
            requiredSelect={true}
            options={WORKPLACE_OPTIONS}
            className="modal wizard"
            form={form}
            control={control}
            defaultValue={jobPosting?.workplace?.work_type}
            disabled={!!jobPosting}
          />
          {/* @ts-expect-error fix me */}
          {workplace?.work_type?.value !== "remote" && (
            <div className="flex space-between">
              <Formblock
                name="workplace.address.city"
                type="text"
                label="City*"
                register={register(vals.required)}
                errors={errors}
                editing={true}
                className="modal wizard"
                form={form}
                style={{ width: "49%" }}
                defaultValue={jobPosting?.workplace?.address?.city}
                disabled={!!jobPosting}
              />
              <Formblock
                name="workplace.address.state"
                type="select"
                requiredSelect={true}
                label="State*"
                register={register(vals.required)}
                errors={errors}
                editing={true}
                className="modal wizard"
                options={stateOptions}
                form={form}
                style={{ width: "49%" }}
                disabled={!!jobPosting}
                defaultValue={jobPosting?.workplace?.address?.state}
              />
            </div>
          )}
        </div>

        <Formblock
          name="employment_type"
          type="select"
          requiredSelect={true}
          label="Employment*"
          register={register(vals.required)}
          errors={errors}
          editing={true}
          options={EMPLOYMENT_OPTIONS}
          className="modal wizard"
          form={form}
          defaultValue={jobPosting?.employment_type}
        />

        <div className={styles["form-section"]}>
          <Formblock
            name="pay.type"
            type="select"
            requiredSelect={true}
            label="Pay type*"
            register={register(vals.required)}
            errors={errors}
            editing={true}
            options={PAY_TYPE_OPTIONS}
            className="modal wizard"
            form={form}
            defaultValue={jobPosting?.pay?.type || "hourly"}
          />
          <div className="flex space-between">
            <Formblock
              name="pay.min"
              type="unit"
              unit="$"
              label={`Minimum ` + (isHourly ? "$/hr" : "$/year") + `*`}
              register={register(
                vals.numberValidator({
                  required: true,
                  excludeNegatives: true,
                  excludeZero: true,
                  maxDecimals: 2,
                })
              )}
              errors={errors}
              editing={true}
              className="modal wizard"
              form={form}
              style={{ width: "49%" }}
              defaultValue={jobPosting?.pay?.min ? Number(jobPosting?.pay?.min) : undefined}
            />
            <Formblock
              name="pay.max"
              type="unit"
              unit="$"
              label={`Maximum ` + (isHourly ? "$/hr" : "$/year") + `*`}
              register={register(
                vals.numberValidator({
                  required: true,
                  excludeNegatives: true,
                  excludeZero: true,
                  maxDecimals: 2,
                })
              )}
              errors={errors}
              editing={true}
              className="modal wizard"
              form={form}
              style={{ width: "49%" }}
              defaultValue={jobPosting?.pay?.max ? Number(jobPosting?.pay?.max) : undefined}
            />
          </div>
          <Formblock
            name="min_years_of_experience"
            type="number"
            label="Required years of experience*"
            register={register(vals.required)}
            errors={errors}
            editing={true}
            className="modal wizard"
            form={form}
            defaultValue={jobPosting?.min_years_of_experience}
          />

          <Formblock
            name="require_resume"
            type="checkbox"
            text="Require candidate to submit a resume."
            label="Resume"
            register={register}
            errors={errors}
            editing={true}
            className="modal wizard"
            form={form}
            defaultValue={jobPosting ? !!jobPosting?.require_resume : false}
          />

          <Formblock
            name="interest_form"
            type="checkbox"
            text="This is a generic interest form for no specific role."
            label="Generic interest form"
            register={register}
            errors={errors}
            editing={true}
            className="modal wizard"
            form={form}
            defaultValue={jobPosting ? !!jobPosting?.interest_form : false}
          />

          <Formblock
            type="select"
            name="hiring_manager_id"
            label={"Hiring manager"}
            labelInfo="Assign an individual hiring manager to the job posting."
            form={form}
            editing={true}
            className="modal wizard"
            options={teamOptions}
            height="unset"
            isClearable={true}
          />

          <Formblock
            type="multiselect"
            name="hiring_team"
            label={"Hiring team"}
            labelInfo="Assign members to this job posting's hiring team."
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Select team members"}
            options={teamMemberGroupOptions}
            height="unset"
          />
        </div>
      </div>
    </WizardScreen>
  );
};

type BuildDefaultValuesOpts = {
  teamMemberGroupOptions: TeamMemberOptionGroup[];
  teamOptions: Option<string>[];
  activeUser: User | null;
  lookupTeam: LookupAtomFunction<AggregatedTeamMember>;
};

const buildDefaultValues = (jobPosting: AggregatedJobPosting | undefined, opts: BuildDefaultValuesOpts) => {
  const { teamMemberGroupOptions, activeUser, lookupTeam, teamOptions } = opts;

  const teamMemberOptions = teamMemberGroupOptions
    .flatMap((group) => group.options)
    .filter((o) => {
      if (isMiterRep(activeUser)) return true;
      const tm = lookupTeam(o.value.value);
      return !isMiterRep(tm);
    });

  const hiringTeam = (jobPosting?.hiring_team || [])
    .map((group) => {
      const option = teamMemberOptions.find(
        (tm) => tm.value?.type === group.type && tm.value?.value === group.value
      );
      return option;
    })
    .filter(notNullish);

  const hiringManagerOption = teamOptions.find((tm) => tm.value === jobPosting?.hiring_manager_id);

  return {
    hiring_team: hiringTeam,
    hiring_manager_id: hiringManagerOption,
  };
};
