import {
  jobsAtom,
  lookupJobAtom,
  refetchActivitiesAtom,
  refetchJobsAtom,
  refetchTeamAtom,
  teamAtom,
  userAtom,
  wcCodesAtom,
  refetchWcCodesAtom,
  activitiesAtom,
  lookupActivityAtom,
  lookupTeamAtom,
  activeTeamAtom,
  confettiAtom,
  activeEmployeesAtom,
  refetchCompanyRolesAtom,
  companyRolesAtom,
  prgsAtom,
  refetchPrgsAtom,
  lookupPrgAtom,
  lookupRateClassificationAtom,
  otRulesAtom,
  refetchPaySchedulesAtom,
  lookupOtRuleAtom,
  selectableActivitiesMapAtom,
  activeJobsAtom,
  paySchedulesAtom,
  lookupPayScheduleAtom,
  holidaySchedulesAtom,
  lookupHolidayScheduleAtom,
  refetchHolidaySchedulesAtom,
  LookupAtomFunction,
  lookupCompanyRoleAtom,
  costTypesAtom,
  lookupCostTypeAtom,
  refetchCostTypesAtom,
  showNewReleaseModalAtom,
  crewsAtom,
  lookupCrewAtom,
  refetchCrewsAtom,
  lookupWcCodeAtom,
  lastRefreshedAtAtom,
  rateDifferentialsAtom,
  refetchOtRulesAtom,
  refetchRateDifferentialsAtom,
  ledgerAccountsAtom,
  lookupLedgerAccountAtom,
  refetchLedgerAccountsAtom,
  liveTimesheetsAtom,
  refetchLiveTimesheetsAtom,
  performanceReviewSchedulesAtom,
  lookupPerformanceReviewSchedulesAtom,
  refetchPerformanceReviewSchedulesAtom,
  activeTeamMemberAtom,
  activeCompanyAtom,
  payrollReadinessAtom,
  departmentsAtom,
  lookupDepartmentAtom,
  refetchDepartmentsAtom,
  actionableItemsAtom,
  refetchActionableItemsAtom,
  lookupTimeOffPolicyAtom,
  refetchTimeOffPoliciesAtom,
  timeOffPoliciesAtom,
  lookupTeamMemberCrewsAtom,
  standardClassificationsAtom,
  refetchStandardClassificationsAtom,
  lookupStandardClassificationAtom,
  policiesAtom,
  refetchPoliciesAtom,
  lookupPolicyAtom,
  jobPostingsAtom,
  lookupJobPostingsAtom,
  refetchJobPostingsAtom,
  stripeConnectedAccountAtom,
  authTokenAtom,
  authenticatedUserDataAtom,
  userFetchedAtom,
  permissionGroupsAtom,
  refetchPermissionGroupsAtom,
  lookupPermissionGroupsAtom,
  sessionPermissionGroupsAtom,
  refetchSessionPermissionGroupsAtom,
  sessionPermissionGroupsFetchedAtom,
  companyUsersAtom,
  lookupCompanyUsersAtom,
  activeRoleAtom,
  activeAccountAtom,
  userAccountsAtom,
  toggleAccountAtom,
  workplacesAtom,
  lookupWorkplacesAtom,
  refetchWorkplacesAtom,
  ledgerMappingsAtom,
  refetchLedgerMappingsAtom,
  lookupLedgerMappingAtom,
  formsAtom,
  lookupFormsAtom,
  refetchFormsAtom,
  expenseReimbursementCategoriesAtom,
  refetchExpenseReimbursementCategoriesAtom,
  initializingAtom,
  storedActiveAccountIdAtom,
  lookupExpenseReimbursementCategoriesAtom,
  hydratedPermissionGroupsAtom,
  onboardingChecklistsAtom,
  lookupOnboardingChecklistsAtom,
  refetchOnboardingChecklistsAtom,
  companyDocumentsAtom,
  lookupCompanyDocumentsAtom,
  refetchCompanyDocumentsAtom,
  refetchReportViewsAtom,
  reportViewsAtom,
  fillableTemplatesAtom,
  lookupCertificationTypesAtom,
  refetchCertificationTypesAtom,
  profilePictureUrlsAtom,
  lookupRateDifferentialAtom,
  certificationTypesAtom,
  lookupFillableTemplatesAtom,
  refetchFillableTemplatesAtom,
  perDiemRatesAtom,
  lookupPerDiemRatesAtom,
  refetchPerDiemRatesAtom,
  hitUnauthorizedErrorAtom,
  activeLiveTimesheetUpdateAtom,
  wcGroupsAtom,
  lookupWcGroupAtom,
  refetchWcGroupsAtom,
  locationsAtom,
  lookupLocationsAtom,
  refetchLocationsAtom,
  bannerNotificationsAtomFamily,
  lookupCardTransactionCategoriesAtom,
  cardTransactionCategoriesAtom,
  refetchCardTransactionCategoriesAtom,
  teamTwilioClientAtom,
  teamChatConversationsAtom,
  activeTeamChatConversationAtom,
  isTeamChatInitializedAtom,
  recruitingTwilioClientAtom,
  paginatedRecruitingConversationsAtom,
  activeRecruitingConversationAtom,
  isRecruitingChatInitializedAtom,
  unreadRecruitingConversationsCountAtom,
  recruitingChatRestartAtom,
  teamChatRestartAtom,
  RefetchInput,
  equipmentAtom,
  refetchEquipmentAtom,
  vendorsAtom,
  lookupVendorsAtom,
  refetchVendorsAtom,
  refetchBenefitsEligibilityGroupsAtom,
  lookupBenefitsEligibilityGroupsAtom,
  benefitsEligibilityGroupsAtom,
  leaveTypesAtom,
  lookupLeaveTypeAtom,
  refetchLeaveTypesAtom,
  customTasksAtom,
  lookupCustomTasksAtom,
  refetchCustomTasksAtom,
  lookupEquipmentAtom,
} from "dashboard/atoms";
import {
  Activity,
  AggregatedJob,
  AggregatedPayRateGroup,
  AggregatedPerformanceReviewCycle,
  AggregatedRole,
  AggregatedTeamMember,
  BenefitsEligibilityGroup,
  Company,
  CostType,
  Crew,
  Department,
  HolidaySchedule,
  LedgerAccount,
  LiveTimesheet,
  OvertimeRule,
  PaySchedule,
  ReportView,
  RateDifferential,
  StandardClassification,
  TimeOffPolicy,
  TimesheetPolicy,
  Policy,
  UnionRate,
  User,
  WorkersCompCode,
  StripeAccountResponse,
  Job,
  PermissionGroup,
  CompanyUser,
  Role,
  TeamMember,
  Workplace,
  LedgerMapping,
  Form,
  ExpenseReimbursementCategory,
  File as MiterFile,
  PerDiemRate,
  ExpensePolicy,
  ExpenseReimbursementPolicy,
  WorkersCompGroup,
  Location,
  Account,
  CardTransactionCategory,
  SessionUpdateParams,
  DashboardAuthenticatedUserData,
  Equipment,
  AggregatedVendor,
  LeaveType,
  CustomTask,
} from "dashboard/miter";
import { unionRateSorter } from "dashboard/pages/settings/payRateGroups/PayRateGroupPage/PayRateGroupModalUtils";
import { buildTeamBaseFilterPredicate } from "dashboard/pages/team-members/TeamUtils";
import {
  MITER_COMPANY_ID,
  MaybeArray,
  capitalize,
  convertToArray,
  createObjectMap,
  getLedgerAccountLabel,
} from "dashboard/utils";
import { baseJobOptionsFilterPredictate, cprJobsFilterPredicate } from "dashboard/utils/jobUtils";
import { PayrollReadiness } from "dashboard/utils/payrollReadiness";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { DateTime } from "luxon";
import { baseSensitiveCompare, MapWithDefault, notNullish } from "miter-utils";
import { SetStateAction, useCallback, useMemo } from "react";
import { Option } from "ui/form/Input";
import { ActionableItems } from "backend/services/actionable-item-service";
import { AggregatedJobPosting } from "dashboard/types/ats";
import { OnboardingChecklist } from "dashboard/types/onboarding-types";
import { HydratedPermissionGroups } from "./abilities-hooks/usePermissionGroupHydrator";
import { AggregatedCertificationType } from "dashboard/types/certification-types";
import { AggregatedFillableTemplate } from "dashboard/types/fillable-template-types";
import {
  AggregatedRecruitingConversation,
  AggregatedTeamConversation,
  TwilioClient,
} from "dashboard/types/chat";

/*
 * GENERIC OPTIONS
 */

const blankOption = { label: "-", value: "" };

type UseOptionParams<T> = {
  /** Mapping function to turn the object into an Option */
  mapFunc: (obj: T) => Option<string>;
  /** Optional filter predicate for filtering the items */
  baseFilterPredicate?: (obj: T) => $TSFixMe;
  /** Optional sorting function if you want to sort by something other than the eventual option label */
  sortFunc?: (a: T, b: T) => number;
};

/**
 *
 */
export type UseOptionOpts<T> = {
  /** Optional filter predicate that can either be applied on top of or in addition to the base predicate from the params */
  predicate?: (obj: T) => $TSFixMe;
  /** Flag to indicate with the given predicate within `opts` should override the base predicate */
  overrideBasePredicate?: boolean;
  /** Override mapping function to go from the object to the option. Make sure this is a stable function! */
  mapFuncOverride?: (obj: T) => Option<string>;
  /** Include a blank option in the array at the start with a "-" for a `label` and an empty string for a `value` */
  includeBlank?: boolean;
  /** Include the provided custom option at the start of the options array */
  includeCustomOption?: Option<string> | Option<string>[];
  /** If provided, the options array will include this value even if it normally wouldn't pass either of the filter predicates */
  defaultValue?: MaybeArray<string> | null;
};

/**
 * A hook to generate an array of options for use in a dropdown or other input.
 */
export function getOptions<T extends { _id: string }>(
  items: T[],
  params: UseOptionParams<T>,
  opts?: UseOptionOpts<T>
): Option<string>[] {
  // First build the array of default values based on the `defaultValue` option
  const defaultValues = convertToArray(opts?.defaultValue);

  // Next, filter the items down to just what should appear in the options array
  const filteredItems = items.filter((o) => {
    // If the item is one of the default values, include it
    if (defaultValues.includes(o._id)) {
      return true;
    }

    // To start, check item against the base filter predicate, unless the override is in place or there is no base predicate given, in which case we let it pass
    const baseResult =
      opts?.overrideBasePredicate || !params.baseFilterPredicate ? true : params.baseFilterPredicate(o);

    // Then check the item against the predicate from the options, unless there is no predicate, in which case we let it pass
    return baseResult && (opts?.predicate ? opts.predicate(o) : true);
  });

  // If sortFunc is provided, use it pre-map when we have access to the full object
  if (params.sortFunc) filteredItems.sort(params.sortFunc);

  // Create the actual options from the filtered items using the mapFunc
  const options = filteredItems.map(opts?.mapFuncOverride || params.mapFunc);

  // If sortFunc is not provided, sort by option label
  if (!params.sortFunc) options.sort((a, b) => baseSensitiveCompare(a.label, b.label));

  // If the custom option is provided or the blank flag is set, add to the start of the array
  if (opts?.includeCustomOption) {
    const customs = Array.isArray(opts.includeCustomOption)
      ? opts.includeCustomOption
      : [opts.includeCustomOption];
    options.unshift(...customs);
  }
  if (opts?.includeBlank) options.unshift(blankOption);

  return options;
}

/**
 * A hook to generate an array of options for use in a dropdown or other input.
 */
function useOptions<T extends { _id: string }>(
  items: T[],
  params: UseOptionParams<T>,
  opts?: UseOptionOpts<T>
): Option<string>[] {
  return useMemo(() => {
    return getOptions(items, params, opts);
  }, [
    items,
    params.baseFilterPredicate,
    params.mapFunc,
    params.sortFunc,
    opts?.includeBlank,
    JSON.stringify(opts?.includeCustomOption || null),
    opts?.defaultValue,
    opts?.predicate,
    opts?.overrideBasePredicate,
    opts?.mapFuncOverride,
  ]);
}

type UseSetAtom<T> = React.Dispatch<SetStateAction<T>>;
type UseFullAtom<T> = [T, React.Dispatch<SetStateAction<T>>];

export type NameFormatterInput<T> = T | string | null | undefined;

/*
 * AUTHENTICATED USER DATA
 */
export const useSetAuthenticatedUserData = (): ((
  userData: DashboardAuthenticatedUserData | null
) => void) => {
  return useSetAtom(authenticatedUserDataAtom);
};

/*
 * AUTH TOKEN
 */
export const useAuthToken = (): string | null => {
  return useAtomValue(authTokenAtom);
};

/*
 * Stored Active Account
 */
export const useStoredActiveAccountId = (): string | null => {
  return useAtomValue(storedActiveAccountIdAtom);
};

/*
 * USER
 */
export const useUser = (): User | null => {
  return useAtomValue(userAtom);
};

export const useSetUser = (): UseSetAtom<User | null> => {
  return useSetAtom(userAtom);
};

export const useUserAtom = (): UseFullAtom<User | null> => {
  return useAtom(userAtom);
};

/*
 * COMPANY USERS
 */
export const useCompanyUsers = (): CompanyUser[] => {
  return useAtomValue(companyUsersAtom);
};

export const useSetCompanyUsers = (): UseSetAtom<CompanyUser[]> => {
  return useSetAtom(companyUsersAtom);
};

export const useCompanyUsersAtom = (): UseFullAtom<CompanyUser[]> => {
  return useAtom(companyUsersAtom);
};

export const useLookupCompanyUsers = (): LookupAtomFunction<CompanyUser> => {
  return useAtomValue(lookupCompanyUsersAtom);
};

export const companyUserOptionsMapCallback = (c: CompanyUser): Option<string> => {
  return {
    label:
      c.first_name && c.last_name
        ? `${c.first_name} ${c.last_name} ${c.email ? `(${c.email})` : ""}`
        : c.email || c.phone || "",
    value: c._id,
  };
};

export const useCompanyUserOptions = (opts?: UseOptionOpts<CompanyUser>): Option<string>[] => {
  const companyUsers = useCompanyUsers();
  return useOptions(companyUsers, { mapFunc: companyUserOptionsMapCallback }, opts);
};

/*
 * USER FETCHED
 */
export const useUserFetched = (): boolean => {
  return useAtomValue(userFetchedAtom);
};

export const useSetUserFetched = (): UseSetAtom<boolean> => {
  return useSetAtom(userFetchedAtom);
};

/*
 * INITIALIZING
 */
export const useInitializing = (): boolean => {
  return useAtomValue(initializingAtom);
};

export const useSetInitializing = (): UseSetAtom<boolean> => {
  return useSetAtom(initializingAtom);
};

/*
 * ACTIVE TEAM MEMBER
 */
export const useActiveTeamMember = (): TeamMember | null => {
  return useAtomValue(activeTeamMemberAtom);
};

/*
 * ACTIVE ROLE
 */
export const useActiveRole = (): Role | null => {
  return useAtomValue(activeRoleAtom);
};

/*
 * ACTIVE ACCOUNT
 */
export const useActiveAccount = (): Account | null => {
  return useAtomValue(activeAccountAtom);
};

export const useUserAccounts = (): Account[] => {
  return useAtomValue(userAccountsAtom);
};

export const useToggleAccount = (): ((params: SessionUpdateParams) => Promise<void>) => {
  return useSetAtom(toggleAccountAtom);
};

/*
 * PAYROLL READINESS
 */
export const usePayrollReadiness = (): PayrollReadiness | null => {
  return useAtomValue(payrollReadinessAtom);
};

/*
 * ACTIVE COMPANY
 */
export const useActiveCompany = (): Company | null => {
  return useAtomValue(activeCompanyAtom);
};

export const useActiveCompanyId = (): string | null => {
  return useAtomValue(activeCompanyAtom)?._id || null;
};

export const useSetActiveCompany = (): UseSetAtom<Company | null> => {
  return useSetAtom(activeCompanyAtom);
};

/*
 * SUB JOBS
 */

export const useSubJobsConfig = (): { enabled: boolean; depth: number } => {
  const jobSettings = useAtomValue(activeCompanyAtom)?.settings?.jobs || {};

  return useMemo(
    () => ({ enabled: !!jobSettings?.enabled_sub_jobs, depth: jobSettings?.sub_job_config?.depth || 0 }),
    [jobSettings?.enabled_sub_jobs, jobSettings?.sub_job_config?.depth]
  );
};

/*
 * TEAM
 */
export const useTeam = (): AggregatedTeamMember[] => {
  return useAtomValue(teamAtom);
};

export const useSetTeam = (): UseSetAtom<AggregatedTeamMember[]> => {
  return useSetAtom(teamAtom);
};

export const useRefetchTeam = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchTeamAtom);
};

export const useLookupTeam = (): LookupAtomFunction<AggregatedTeamMember> => {
  return useAtomValue(lookupTeamAtom);
};

export const useActiveTeam = (): AggregatedTeamMember[] => {
  return useAtomValue(activeTeamAtom);
};

export const useActiveEmployees = (): AggregatedTeamMember[] => {
  return useAtomValue(activeEmployeesAtom);
};

export const useLookupActiveTeam = (): LookupAtomFunction<AggregatedTeamMember> => {
  const activeTeam = useActiveTeam();
  const activeTeamMap = useMemo(() => createObjectMap(activeTeam, (tm) => tm._id), [activeTeam]);

  return useCallback(
    (id: string | undefined | null): AggregatedTeamMember | undefined => {
      if (!id) return undefined;
      return activeTeamMap[id] || undefined;
    },
    [activeTeamMap]
  );
};

export const useTeamOptions = (opts?: UseOptionOpts<AggregatedTeamMember>): Option<string>[] => {
  const today = DateTime.now().toISODate();
  const team = useTeam();

  const formatter = useTeamMemberNameFormatter();
  const teamOptionsMapCallback = useCallback(
    (tm: AggregatedTeamMember): Option<string> => {
      return { value: tm._id, label: formatter(tm) };
    },
    [formatter]
  );

  const basePredicate = useCallback(buildTeamBaseFilterPredicate(today), [today]);
  return useOptions(team, { baseFilterPredicate: basePredicate, mapFunc: teamOptionsMapCallback }, opts);
};

export const useTeamMemberNameFormatter = (): ((a: NameFormatterInput<AggregatedTeamMember>) => string) => {
  const activeCompany = useActiveCompany();
  const prefixWithCode = !!activeCompany?.settings.team?.show_friendly_id_in_dashboard;
  const lookupTeam = useLookupTeam();
  return useCallback(
    (a: NameFormatterInput<AggregatedTeamMember>): string => {
      if (!a) return "";
      const teamMember = typeof a === "string" ? lookupTeam(a) : a;
      if (!teamMember) return "";
      return prefixWithCode ? `${teamMember.friendly_id} ${teamMember.full_name}` : teamMember.full_name;
    },
    [prefixWithCode, lookupTeam]
  );
};

/*
 * Leave types
 */
export const useLeaveTypes = (): LeaveType[] => {
  return useAtomValue(leaveTypesAtom);
};

export const useLeaveTypeOptions = (opts?: UseOptionOpts<LeaveType>): Option<string>[] => {
  const leaveTypes = useLeaveTypes();

  const leaveTypesCallback = (leaveType: LeaveType): Option<string> => {
    return { label: leaveType.label, value: leaveType._id };
  };

  return useOptions(leaveTypes, { mapFunc: leaveTypesCallback }, opts);
};

export const useSetLeaveTypes = (): UseSetAtom<LeaveType[]> => {
  return useSetAtom(leaveTypesAtom);
};

export const useLeaveTypeAtom = (): UseFullAtom<LeaveType[]> => {
  return useAtom(leaveTypesAtom);
};

export const useLookupLeaveType = (): LookupAtomFunction<LeaveType> => {
  return useAtomValue(lookupLeaveTypeAtom);
};

export const useRefetchLeaveTypes = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchLeaveTypesAtom);
};

/*
 * JOBS
 */
export const useJobs = (): AggregatedJob[] => {
  return useAtomValue(jobsAtom);
};

export const useSetJobs = (): UseSetAtom<AggregatedJob[]> => {
  return useSetAtom(jobsAtom);
};

export const useActiveJobs = (): AggregatedJob[] => {
  return useAtomValue(activeJobsAtom);
};

export const useRefetchJobs = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchJobsAtom);
};

export const useSelectableSubJobsMap = (opts?: {
  defaultJobOptions?: Option<string>[];
}): Map<string, AggregatedJob[]> | null => {
  const { defaultJobOptions } = opts || {};

  const jobOptions = useJobOptions();
  const lookupJob = useLookupJob();
  const activeCompany = useActiveCompany();
  const subJobsConfig = useSubJobsConfig();

  return useMemo(() => {
    if (!subJobsConfig.enabled) return null;

    const subJobMap = new Map<string, AggregatedJob[]>();
    (defaultJobOptions || jobOptions).forEach((jobOption) => {
      const fullJob = lookupJob(jobOption.value);
      if (!fullJob) return;

      // If the job has a parent job, add it to the parent job's list of sub jobs or add to the root level if no parent job
      const parentJobId = fullJob.parent_job_id || EMPTY_JOB_KEY;

      const currentSubJobs = subJobMap.get(parentJobId) || [];
      subJobMap.set(parentJobId, currentSubJobs.concat(fullJob));
    });

    return subJobMap;
  }, [subJobsConfig.enabled, jobOptions, lookupJob, activeCompany, defaultJobOptions]);
};

export const useGetSelectableSubJobOptions = (opts?: {
  defaultJobOptions?: Option<string>[];
}): ((jobId: string | null | undefined) => Option<string>[]) => {
  const selectableSubJobsMap = useSelectableSubJobsMap(opts);
  const formatter = useJobNameFormatter({ disableParentJobPrefixing: true });
  const jobOptionsMapCallback = useCallback(
    (job: AggregatedJob | Job): Option<string> => {
      return { label: formatter(job), value: job._id };
    },
    [formatter]
  );

  return useCallback(
    (jobId: string | null | undefined): Option<string>[] => {
      if (!selectableSubJobsMap) return [];

      const key = jobId || EMPTY_JOB_KEY;
      const subJobOptions = selectableSubJobsMap.get(key)?.map(jobOptionsMapCallback) || [];
      return subJobOptions;
    },
    [selectableSubJobsMap, jobOptionsMapCallback, formatter]
  );
};

export const useJobNameFormatter = (opts?: {
  disableParentJobPrefixing?: boolean;
}): ((job: NameFormatterInput<AggregatedJob | Job>) => string) => {
  const activeCompany = useActiveCompany();
  const prefixWithCodeSetting = !!activeCompany?.settings.jobs?.show_code_in_dashboard;
  const lookupJob = useLookupJob();
  const subJobsConfig = useSubJobsConfig();

  const parentJobPrefixBuilder = useCallback(
    (job: AggregatedJob | Job): string => {
      let prefix = "";
      let currentJob: AggregatedJob | Job | undefined = job;

      while (currentJob?.parent_job_id) {
        currentJob = lookupJob(currentJob.parent_job_id);
        if (!currentJob) break;

        prefix = `${currentJob.name} > ${prefix}`;
      }

      return prefix;
    },
    [lookupJob]
  );

  return useCallback(
    (j: NameFormatterInput<AggregatedJob | Job>): string => {
      if (!j) return "";
      const job = typeof j === "string" ? lookupJob(j) : j;
      if (!job) return "";

      const prefixWithCode =
        prefixWithCodeSetting &&
        job.code &&
        !job.name.trim().toLowerCase().startsWith(job.code.trim().toLowerCase());

      if (subJobsConfig.enabled && !opts?.disableParentJobPrefixing) {
        if (prefixWithCode) {
          return job.code + " " + parentJobPrefixBuilder(job) + job.name;
        } else {
          return parentJobPrefixBuilder(job) + job.name;
        }
      } else {
        if (prefixWithCode) {
          return job.code + " " + job.name;
        } else {
          return job.name;
        }
      }
    },
    [prefixWithCodeSetting, lookupJob]
  );
};

export const useJobOptions = (opts?: UseOptionOpts<AggregatedJob | Job>): Option<string>[] => {
  const formatter = useJobNameFormatter();
  const jobOptionsMapCallback = useCallback(
    (job: AggregatedJob | Job): Option<string> => {
      return { label: formatter(job), value: job._id };
    },
    [formatter]
  );
  const jobs = useJobs();
  return useOptions(
    jobs,
    { baseFilterPredicate: baseJobOptionsFilterPredictate, mapFunc: jobOptionsMapCallback },
    opts
  );
};

export const useActiveCprJobOptions = (opts?: {
  predicate: (obj: Job | AggregatedJob) => boolean;
}): Option<string>[] => {
  return useJobOptions({
    predicate: (job) => cprJobsFilterPredicate(job) && (!opts?.predicate || opts?.predicate(job)),
  });
};

export const useLookupJob = (): LookupAtomFunction<AggregatedJob> => {
  return useAtomValue(lookupJobAtom);
};

/*
 * ACTIVITIES
 */
export const useActivities = (): Activity[] => {
  return useAtomValue(activitiesAtom);
};

export const useSetActivities = (): UseSetAtom<Activity[]> => {
  return useSetAtom(activitiesAtom);
};

export const useLookupActivity = (): LookupAtomFunction<Activity> => {
  return useAtomValue(lookupActivityAtom);
};

export const useRefetchActivities = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchActivitiesAtom);
};

export const useSelectableActivitiesMap = (): MapWithDefault<string, Activity[]> => {
  return useAtomValue(selectableActivitiesMapAtom);
};

export const useActivityLabelFormatter = (): ((a: NameFormatterInput<Activity>) => string) => {
  const activeCompany = useActiveCompany();
  const prefixWithCode = !!activeCompany?.settings.activities?.show_code_in_dashboard;
  const lookupActivity = useLookupActivity();
  return useCallback(
    (a: NameFormatterInput<Activity>): string => {
      if (!a) return "";
      const activity = typeof a === "string" ? lookupActivity(a) : a;
      if (!activity) return "";
      return prefixWithCode &&
        activity.cost_code &&
        !activity.label.trim().toLowerCase().startsWith(activity.cost_code.trim().toLowerCase())
        ? `${activity.cost_code} ${activity.label}`
        : activity.label;
    },
    [prefixWithCode, lookupActivity]
  );
};

/*
 * CUSTOM TASKS
 */
export const useCustomTasks = (): CustomTask[] => {
  return useAtomValue(customTasksAtom);
};

export const useSetCustomTasks = (): UseSetAtom<CustomTask[]> => {
  return useSetAtom(customTasksAtom);
};

export const useCustomTasksAtom = (): UseFullAtom<CustomTask[]> => {
  return useAtom(customTasksAtom);
};

export const useLookupCustomTask = (): LookupAtomFunction<CustomTask> => {
  return useAtomValue(lookupCustomTasksAtom);
};

export const useRefetchCustomTasks = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchCustomTasksAtom);
};

const customTaskOptionsMapCallback = (ct: CustomTask): Option<string> => {
  return { label: ct.title, value: ct._id };
};

export const useCustomTaskOptions = (opts?: UseOptionOpts<CustomTask>): Option<string>[] => {
  const customTasks = useCustomTasks();
  return useOptions(customTasks, { mapFunc: customTaskOptionsMapCallback }, opts);
};

export const useCustomTaskFormatter = (): ((ct: NameFormatterInput<CustomTask>) => string) => {
  const lookupCustomTask = useLookupCustomTask();
  return useCallback(
    (ct: NameFormatterInput<CustomTask>): string => {
      if (!ct) return "";
      const customTask = typeof ct === "string" ? lookupCustomTask(ct) : ct;
      return customTask?.title || "";
    },
    [lookupCustomTask]
  );
};

/*
 * REST OF THE CODE
 */
/** Returns map from job _id to selectable activity options (with default of company activity options) */
export const useActivityOptionsMap = (
  opts?: UseOptionOpts<Activity>
): MapWithDefault<string, Option<string>[]> => {
  const allActivities = useActivities();

  const formatter = useActivityLabelFormatter();
  const activityOptionsMapCallback = useCallback(
    (a: Activity): Option<string> => {
      return { value: a._id, label: formatter(a) };
    },
    [formatter]
  );

  const allOptions = useOptions(allActivities, { mapFunc: activityOptionsMapCallback }, opts);
  const mappedOptions = useMemo(() => new Map(allOptions.map((o) => [o.value, o])), [allOptions]);

  const selectableActivitiesMap = useSelectableActivitiesMap();

  // Rebuilds the selectable map but with options instead of activities, plus includes option generation opts
  return useMemo(() => {
    // Build the company activity options
    const companyActivities = selectableActivitiesMap.defaultVal;
    const companyActivityIds = companyActivities.reduce((s, a) => s.add(a._id), new Set<string>());
    const companyActivityOptions = allOptions.filter(
      (o) => companyActivityIds.has(o.value) || o === opts?.includeCustomOption || o === blankOption
    );

    // Everything is derived from `allOptions` to reduce object duplication. If we ran `getOptions` on every job's activities array, we'd have a lot of duplicate objects.
    const mapWithDefault = new MapWithDefault<string, Option<string>[]>(companyActivityOptions);
    for (const [jobId, activities] of selectableActivitiesMap.entries()) {
      // Need to filter the activity options down to just the ones that are in the job's activities array or are default values
      const options: Option<string>[] = [];
      for (const a of activities) {
        const o = mappedOptions.get(a._id);
        if (o) options.push(o);
      }
      options.sort((a, b) => baseSensitiveCompare(a.label, b.label));
      if (opts?.includeCustomOption) {
        const customs = Array.isArray(opts.includeCustomOption)
          ? opts.includeCustomOption
          : [opts.includeCustomOption];
        options.unshift(...customs);
      }
      if (opts?.includeBlank) options.unshift(blankOption);
      mapWithDefault.set(jobId, options);
    }

    return mapWithDefault;
  }, [allActivities, allOptions, selectableActivitiesMap]);
};

export const useActivityOptions = (
  job?: string | AggregatedJob | null,
  opts?: UseOptionOpts<Activity>
): Option<string>[] => {
  const optionsMap = useActivityOptionsMap(opts);
  const jobId = typeof job === "string" ? job : job?._id;
  return useMemo(() => optionsMap.get(jobId), [jobId, optionsMap]);
};

/*
 * WORKERS COMP
 */
export const useWcCodes = (): WorkersCompCode[] => {
  return useAtomValue(wcCodesAtom);
};

export const useSetWcCodes = (): UseSetAtom<WorkersCompCode[]> => {
  return useSetAtom(wcCodesAtom);
};

export const useWcCodesAtom = (): UseFullAtom<WorkersCompCode[]> => {
  return useAtom(wcCodesAtom);
};

export const useLookupWcCode = (): LookupAtomFunction<WorkersCompCode> => {
  return useAtomValue(lookupWcCodeAtom);
};

export const useRefetchWcCodes = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchWcCodesAtom);
};

const wcCodeOptionsMapCallback = (c: WorkersCompCode) => {
  const label = !c.code || c.label.startsWith(c.code) ? c.label : `${c.code} ${c.label}`;
  return { label: label, value: c._id };
};

export const useWcCodeOptions = (opts?: UseOptionOpts<WorkersCompCode>): Option<string>[] => {
  const wcCodes = useWcCodes();
  return useOptions(wcCodes, { mapFunc: wcCodeOptionsMapCallback }, opts);
};

export const useWcCodeFormatter = (): ((wcCode: NameFormatterInput<WorkersCompCode>) => string) => {
  const lookupWcCode = useLookupWcCode();
  return useCallback(
    (w: NameFormatterInput<WorkersCompCode>): string => {
      if (!w) return "";
      const wcCode = typeof w === "string" ? lookupWcCode(w) : w;
      if (!wcCode) return "";
      return `${wcCode.label}`;
    },
    [lookupWcCode]
  );
};

export const useWcGroups = (): WorkersCompGroup[] => {
  return useAtomValue(wcGroupsAtom);
};

export const useSetWcGroups = (): UseSetAtom<WorkersCompGroup[]> => {
  return useSetAtom(wcGroupsAtom);
};

export const useWcGroupsAtom = (): UseFullAtom<WorkersCompGroup[]> => {
  return useAtom(wcGroupsAtom);
};

export const useLookupWcGroup = (): LookupAtomFunction<WorkersCompGroup> => {
  return useAtomValue(lookupWcGroupAtom);
};

export const useRefetchWcGroups = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchWcGroupsAtom);
};

const wcGroupOptionsMapCallback = (c: WorkersCompGroup) => {
  return { label: c.name, value: c._id };
};

export const useWcGroupOptions = (opts?: UseOptionOpts<WorkersCompGroup>): Option<string>[] => {
  const wcGroups = useWcGroups();
  return useOptions(wcGroups, { mapFunc: wcGroupOptionsMapCallback }, opts);
};

export const useHasEnabledWcGroups = (): boolean => {
  return useActiveCompany()?.settings?.workers_comp?.enable_wc_groups || false;
};

/*
 * LIVE TIMESHEETS
 */

export const useLiveTimesheets = (): LiveTimesheet[] => {
  return useAtomValue(liveTimesheetsAtom);
};

export const useRefetchLiveTimesheets = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchLiveTimesheetsAtom);
};

export const useSetLiveTimesheets = (): UseSetAtom<LiveTimesheet[]> => {
  return useSetAtom(liveTimesheetsAtom);
};

export const useActiveDashboardLiveTimesheet = (): LiveTimesheet | null => {
  const liveTimesheets = useLiveTimesheets();
  const activeTeamMember = useActiveTeamMember();
  const activeDashboardLiveTimesheet = useMemo(
    () =>
      liveTimesheets.find((timesheet) => {
        return (
          timesheet.team_member_id === activeTeamMember?._id &&
          timesheet.creation_method === "dashboard_clock_in"
        );
      }),
    [liveTimesheets, activeTeamMember]
  );
  return activeDashboardLiveTimesheet || null;
};

/*
  LIVE TIMESHEET UPDATES
*/

// json stringified value of the live timesheet
export const useActiveLiveTimesheetUpdateLocal = (): { key: string; value: number } | null => {
  return useAtomValue(activeLiveTimesheetUpdateAtom);
};

export const useSetActiveLiveTimesheetUpdateLocal = (): UseSetAtom<{ key: string; value: number } | null> => {
  return useSetAtom(activeLiveTimesheetUpdateAtom);
};

/*
 * EQUIPMENT
 */
export const useEquipment = (): Equipment[] => {
  return useAtomValue(equipmentAtom);
};

export const useRefetchEquipment = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchEquipmentAtom);
};

export const useSetEquipment = (): UseSetAtom<Equipment[]> => {
  return useSetAtom(equipmentAtom);
};

const equipmentOptionsMapCallback = (e: Equipment): Option<string> => {
  return { label: e.name, value: e._id };
};

export const useEquipmentOptions = (opts?: UseOptionOpts<Equipment>): Option<string>[] => {
  const equipment = useEquipment();
  return useOptions(equipment, { mapFunc: equipmentOptionsMapCallback }, opts);
};

export const useLookupEquipment = (): LookupAtomFunction<Equipment> => {
  return useAtomValue(lookupEquipmentAtom);
};

/*
 * COMPANY ROLES
 */
export const useCompanyRoles = (): AggregatedRole[] => {
  return useAtomValue(companyRolesAtom);
};

export const useCompanyRoleOptions = (opts?: UseOptionOpts<AggregatedRole>): Option<string>[] => {
  const companyRoles = useCompanyRoles();
  return useOptions(companyRoles, { mapFunc: companyRoleOptionsMapCallback }, opts);
};

export const useSetCompanyRoles = (): UseSetAtom<AggregatedRole[]> => {
  return useSetAtom(companyRolesAtom);
};

export const useCompanyRolesAtom = (): UseFullAtom<AggregatedRole[]> => {
  return useAtom(companyRolesAtom);
};

export const useLookupCompanyRoles = (): LookupAtomFunction<AggregatedRole> => {
  return useAtomValue(lookupCompanyRoleAtom);
};

export const useRefetchCompanyRoles = (): (() => Promise<void>) => {
  return useSetAtom(refetchCompanyRolesAtom);
};

const companyRoleOptionsMapCallback = (c: AggregatedRole): Option<string> => {
  return { label: c.first_name ? `${c.first_name} ${c.last_name} (${c.email})` : c.email, value: c._id };
};

/*
 * PAY RATE GROUPS AND RATE CLASSIFICATIONS
 */
export const usePrgs = (): AggregatedPayRateGroup[] => {
  return useAtomValue(prgsAtom);
};

export const useSetPrgs = (): UseSetAtom<AggregatedPayRateGroup[]> => {
  return useSetAtom(prgsAtom);
};

export const useLookupPrg = (): LookupAtomFunction<AggregatedPayRateGroup> => {
  return useAtomValue(lookupPrgAtom);
};

export const useRefetchPrgs = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchPrgsAtom);
};

export const useLookupRateClassification = (): LookupAtomFunction<UnionRate> => {
  return useAtomValue(lookupRateClassificationAtom);
};

const prgOptionsMapCallback = (c: AggregatedPayRateGroup) => {
  return { label: c.label, value: c._id };
};

export const usePrgOptions = (opts?: UseOptionOpts<AggregatedPayRateGroup>): Option<string>[] => {
  const prgs = usePrgs();
  return useOptions(prgs, { mapFunc: prgOptionsMapCallback }, opts);
};

export const usePrgFormatter = (): ((prg: NameFormatterInput<AggregatedPayRateGroup>) => string) => {
  const lookupPrg = useLookupPrg();
  return useCallback(
    (prg: NameFormatterInput<AggregatedPayRateGroup>): string => {
      if (!prg) return "";
      const payRateGroup = typeof prg === "string" ? lookupPrg(prg) : prg;
      return payRateGroup?.label || "";
    },
    [lookupPrg]
  );
};

export const useGetClassificationOptions = (): ((inputs: {
  prgId?: string;
  tmId?: string;
  jobId?: string | null;
  activityId?: string | null;
  opts?: UseOptionOpts<UnionRate>;
}) => Option<string>[]) => {
  const lookupPrg = useLookupPrg();
  const lookupJob = useLookupJob();
  const lookupActivity = useLookupActivity();
  const lookupTeam = useLookupTeam();
  const prgs = usePrgs();
  const allClassifications = useMemo(() => prgs.flatMap((r) => r.union_rates), [prgs]);

  return useCallback(
    (inputs: {
      prgId?: string;
      tmId?: string;
      jobId?: string | null;
      activityId?: string | null;
      opts?: UseOptionOpts<UnionRate>;
    }) => {
      const { prgId, tmId, activityId, jobId } = inputs;
      const tm = lookupTeam(tmId);
      const tmMappedClassifications = new Set(Object.values(tm?.prg_classifications || {}));
      const showAll = !prgId && !tmId && !activityId && !jobId;
      const prgIds = prgId
        ? [prgId]
        : [
            lookupActivity(activityId)?.pay_rate_group,
            lookupJob(jobId)?.pay_rate_group,
            tm?.union_rate?.pay_rate_group,
          ].filter(notNullish);

      const prgIdsSet = new Set(prgIds);

      const rateOptionsMapCallback = prgId
        ? (c: UnionRate) => ({ label: c.classification, value: c._id })
        : (c: UnionRate) => {
            const prg = lookupPrg(c.pay_rate_group);
            const label = `${prg!.label}: ${c.classification}`;
            return { label, value: c._id };
          };

      const optionsSorter = prgId
        ? unionRateSorter
        : (a: UnionRate, b: UnionRate) => {
            if (a.pay_rate_group === b.pay_rate_group) {
              return unionRateSorter(a, b);
            }

            return baseSensitiveCompare(a.pay_rate_group, b.pay_rate_group);
          };

      return getOptions(
        allClassifications,
        {
          baseFilterPredicate: (c: UnionRate) =>
            showAll || prgIdsSet.has(c.pay_rate_group) || tmMappedClassifications.has(c._id),
          mapFunc: rateOptionsMapCallback,
          sortFunc: optionsSorter,
        },
        inputs.opts
      );
    },
    [lookupPrg, lookupActivity, lookupTeam, lookupJob, allClassifications]
  );
};

export const useFringeOptions = (): Option<string>[] => {
  const prgs = usePrgs();

  const fringeOptions = useMemo(() => {
    const optsMap = new Map<string, Option<string>>();
    for (const p of prgs) {
      for (const r of p.union_rates) {
        for (const f of r.fringes) {
          optsMap.set(f.fringe_group_id, {
            label: `${p.label}: ${f.label}`,
            value: f.fringe_group_id,
          });
        }
      }
    }
    return [...optsMap.values()];
  }, [prgs]);

  return fringeOptions;
};

export const useClassificationOptions = (inputs: {
  prgId?: string;
  tmId?: string;
  jobId?: string;
  activityId?: string;
  opts?: UseOptionOpts<UnionRate>;
}): Option<string>[] => {
  const { opts, ...rest } = inputs;
  const getClassificationOptions = useGetClassificationOptions();
  return useMemo(() => {
    return getClassificationOptions(inputs);
  }, [
    getClassificationOptions,
    JSON.stringify(rest),
    opts?.includeBlank,
    JSON.stringify(opts?.includeCustomOption || null),
    opts?.defaultValue,
    opts?.predicate,
    opts?.overrideBasePredicate,
    opts?.mapFuncOverride,
  ]);
};

export const useClassificationFormatter = (): ((c: NameFormatterInput<UnionRate>) => string) => {
  const lookupClassification = useLookupRateClassification();
  return useCallback(
    (c: NameFormatterInput<UnionRate>): string => {
      if (!c) return "";
      const classification = typeof c === "string" ? lookupClassification(c) : c;
      return classification?.classification || "";
    },
    [lookupClassification]
  );
};

/*
 * OVERTIME RULES
 */
export const useOtRules = (): OvertimeRule[] => {
  return useAtomValue(otRulesAtom);
};

export const useSetOtRules = (): UseSetAtom<OvertimeRule[]> => {
  return useSetAtom(otRulesAtom);
};

export const useOtRulesAtom = (): UseFullAtom<OvertimeRule[]> => {
  return useAtom(otRulesAtom);
};

export const useLookupOtRule = (): LookupAtomFunction<OvertimeRule> => {
  return useAtomValue(lookupOtRuleAtom);
};

export const useRefetchOtRules = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchOtRulesAtom);
};

const otRuleOptionsMapCallback = (c: OvertimeRule): Option<string> => {
  return { label: c.label, value: c._id };
};

export const useOtRuleOptions = (opts?: UseOptionOpts<OvertimeRule>): Option<string>[] => {
  const rules = useOtRules();
  return useOptions(rules, { mapFunc: otRuleOptionsMapCallback }, opts);
};

export const useOtRuleFormatter = (): ((otRule: NameFormatterInput<OvertimeRule>) => string) => {
  const lookupOtRule = useLookupOtRule();
  return useCallback(
    (otr: NameFormatterInput<OvertimeRule>) => {
      if (!otr) return "";
      const otRule = typeof otr === "string" ? lookupOtRule(otr) : otr;
      return otRule?.label || "";
    },
    [lookupOtRule]
  );
};

/*
 * LEDGER MAPPINGS
 */
export const useLedgerMappings = (): LedgerMapping[] => {
  return useAtomValue(ledgerMappingsAtom);
};

export const useSetLedgerMappings = (): UseSetAtom<LedgerMapping[]> => {
  return useSetAtom(ledgerMappingsAtom);
};

export const useLedgerMappingsAtom = (): UseFullAtom<LedgerMapping[]> => {
  return useAtom(ledgerMappingsAtom);
};

export const useLookupLedgerMapping = (): LookupAtomFunction<LedgerMapping> => {
  return useAtomValue(lookupLedgerMappingAtom);
};

export const useRefetchLedgerMappings = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchLedgerMappingsAtom);
};

const ledgerMappingOptionsMapCallback = (c: LedgerMapping): Option<string> => {
  return { label: c.name, value: c._id };
};

export const useLedgerMappingOptions = (opts?: UseOptionOpts<LedgerMapping>): Option<string>[] => {
  const mappings = useLedgerMappings();
  return useOptions(mappings, { mapFunc: ledgerMappingOptionsMapCallback }, opts);
};

export const useDefaultLedgerMapping = (): LedgerMapping | undefined => {
  const ledgerMappings = useLedgerMappings();
  return ledgerMappings.find((lm) => lm.is_company_default);
};

export const useLedgerMappingFormatter = (): ((lm: NameFormatterInput<LedgerMapping>) => string) => {
  const lookupLedgerMapping = useLookupLedgerMapping();
  return useCallback(
    (lm: NameFormatterInput<LedgerMapping>): string => {
      if (!lm) return "";
      const ledgerMapping = typeof lm === "string" ? lookupLedgerMapping(lm) : lm;
      return ledgerMapping?.name || "";
    },
    [lookupLedgerMapping]
  );
};

/*
 * RATE DIFFERENTIALS
 */
export const useRateDifferentials = (): RateDifferential[] => {
  return useAtomValue(rateDifferentialsAtom);
};

export const useLookupRateDifferential = (): LookupAtomFunction<RateDifferential> => {
  return useAtomValue(lookupRateDifferentialAtom);
};

export const useSetRateDifferentials = (): UseSetAtom<RateDifferential[]> => {
  return useSetAtom(rateDifferentialsAtom);
};

export const useRateDifferentialsAtom = (): UseFullAtom<RateDifferential[]> => {
  return useAtom(rateDifferentialsAtom);
};

const rateDifferentialOptionsMapCallback = (c: RateDifferential): Option<string> => {
  return { label: c.label, value: c._id };
};

export const useRateDifferentialOptions = (opts?: UseOptionOpts<RateDifferential>): Option<string>[] => {
  const mappings = useRateDifferentials();
  return useOptions(mappings, { mapFunc: rateDifferentialOptionsMapCallback }, opts);
};

export const useRefetchRateDifferentials = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchRateDifferentialsAtom);
};

export const useRateDifferentialFormatter = (): ((rd: NameFormatterInput<RateDifferential>) => string) => {
  const lookupRateDifferential = useLookupRateDifferential();
  return useCallback(
    (rd: NameFormatterInput<RateDifferential>): string => {
      if (!rd) return "";
      const rateDifferential = typeof rd === "string" ? lookupRateDifferential(rd) : rd;
      if (!rateDifferential) return "";
      return `${rateDifferential.label}`;
    },
    [lookupRateDifferential]
  );
};

/*
 * STANDARD CLASSIFICATIONS
 */
export const useStandardClassifications = (): StandardClassification[] => {
  return useAtomValue(standardClassificationsAtom);
};

export const useSetStandardClassifications = (): UseSetAtom<StandardClassification[]> => {
  return useSetAtom(standardClassificationsAtom);
};

export const useStandardClassificationsAtom = (): UseFullAtom<StandardClassification[]> => {
  return useAtom(standardClassificationsAtom);
};

export const useLookupStandardClassification = (): LookupAtomFunction<StandardClassification> => {
  return useAtomValue(lookupStandardClassificationAtom);
};

export const useRefetchStandardClassifications = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchStandardClassificationsAtom);
};

const stdClassificationOptionsMapCallback = (s: StandardClassification): Option<string> => {
  return { label: capitalize(s.label), value: s._id };
};

export const useStandardClassificationOptions = (
  opts?: UseOptionOpts<StandardClassification>
): Option<string>[] => {
  const scs = useStandardClassifications();
  return useOptions(scs, { mapFunc: stdClassificationOptionsMapCallback }, opts);
};

export const useStandardClassificationFormatter = (): ((
  sc: NameFormatterInput<StandardClassification>
) => string) => {
  const lookupStandardClassification = useLookupStandardClassification();
  return useCallback(
    (sc: NameFormatterInput<StandardClassification>): string => {
      if (!sc) return "";
      const standardClassification = typeof sc === "string" ? lookupStandardClassification(sc) : sc;
      if (!standardClassification) return "";
      return `${standardClassification.label}`;
    },
    [lookupStandardClassification]
  );
};

/*
 * POLICIES
 */
export const usePolicies = (): Policy[] => {
  return useAtomValue(policiesAtom);
};
export const useSetPolicies = (): UseSetAtom<Policy[]> => {
  return useSetAtom(policiesAtom);
};

export const useLookupPolicy = (): LookupAtomFunction<Policy> => {
  return useAtomValue(lookupPolicyAtom);
};

export const useRefetchPolicies = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchPoliciesAtom);
};

const policyOptionsMapCallback = (c: Policy): Option<string> => {
  return { label: c.name, value: c._id };
};

export const useTimesheetPolicyOptions = (opts?: UseOptionOpts<TimesheetPolicy>): Option<string>[] => {
  const policies = usePolicies();
  const timesheetPolicies = useMemo(
    () => policies.filter((p): p is TimesheetPolicy => p.type === "timesheet"),
    [policies]
  );

  return useOptions(timesheetPolicies, { mapFunc: policyOptionsMapCallback }, opts);
};

export const usePolicyFormatter = (): ((p: NameFormatterInput<Policy>) => string) => {
  const lookupPolicy = useLookupPolicy();
  return useCallback(
    (p: NameFormatterInput<Policy>): string => {
      if (!p) return "";
      const policy = typeof p === "string" ? lookupPolicy(p) : p;
      if (!policy) return "";
      return `${policy.name}`;
    },
    [lookupPolicy]
  );
};

export const useExpensePolicyOptions = (opts?: UseOptionOpts<ExpensePolicy>): Option<string>[] => {
  const policies = usePolicies();
  const expensePolicies = useMemo(
    () => policies.filter((p): p is ExpensePolicy => p.type === "expense"),
    [policies]
  );

  return useOptions(expensePolicies, { mapFunc: policyOptionsMapCallback }, opts);
};

export const useReimbursementPolicyOptions = (
  opts?: UseOptionOpts<ExpenseReimbursementPolicy>
): Option<string>[] => {
  const policies = usePolicies();
  const reimbursementPolicies = useMemo(
    () => policies.filter((p): p is ExpenseReimbursementPolicy => p.type === "expense_reimbursement"),
    [policies]
  );

  return useOptions(reimbursementPolicies, { mapFunc: policyOptionsMapCallback }, opts);
};

export const useTimeOffRequestPolicyOptions = (opts?: UseOptionOpts<Policy>): Option<string>[] => {
  const policies = usePolicies();

  const timeOffRequestPolicies = useMemo(
    () => policies.filter((p) => p.type === "time_off_request"),
    [policies]
  );

  return useOptions(timeOffRequestPolicies, { mapFunc: policyOptionsMapCallback }, opts);
};

export const useTeamMemberChangeRequestPolicyOptions = (opts?: UseOptionOpts<Policy>): Option<string>[] => {
  const policies = usePolicies();

  const teamMemberChangeRequestPolicies = useMemo(
    () => policies.filter((p) => p.type === "team_member_change_request"),
    [policies]
  );

  return useOptions(teamMemberChangeRequestPolicies, { mapFunc: policyOptionsMapCallback }, opts);
};

/*
 * PAY SCHEDULES
 */
export const usePaySchedules = (): PaySchedule[] => {
  return useAtomValue(paySchedulesAtom);
};

export const useSetPaySchedules = (): UseSetAtom<PaySchedule[]> => {
  return useSetAtom(paySchedulesAtom);
};

export const usePaySchedulesAtom = (): UseFullAtom<PaySchedule[]> => {
  return useAtom(paySchedulesAtom);
};

export const useLookupPaySchedule = (): LookupAtomFunction<PaySchedule> => {
  return useAtomValue(lookupPayScheduleAtom);
};

export const useRefetchPaySchedules = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchPaySchedulesAtom);
};

export const payScheduleOptionsMapCallback = (ps: PaySchedule): Option<string> => {
  return { label: `${ps.label}${ps.default ? " (default)" : ""}`, value: ps._id };
};

export const usePayScheduleOptions = (opts?: UseOptionOpts<PaySchedule>): Option<string>[] => {
  const rules = usePaySchedules();
  return useOptions(rules, { mapFunc: payScheduleOptionsMapCallback }, opts);
};

export const usePayScheduleFormatter = (): ((ps: NameFormatterInput<PaySchedule>) => string) => {
  const lookupPaySchedule = useLookupPaySchedule();
  return useCallback(
    (ps: NameFormatterInput<PaySchedule>): string => {
      if (!ps) return "";
      const paySchedule = typeof ps === "string" ? lookupPaySchedule(ps) : ps;
      if (!paySchedule) return "";
      return `${paySchedule.label}`;
    },
    [lookupPaySchedule]
  );
};

/*
 * HOLIDAY SCHEDULES
 */
export const useHolidaySchedules = (): HolidaySchedule[] => {
  return useAtomValue(holidaySchedulesAtom);
};

export const useSetHolidaySchedules = (): UseSetAtom<HolidaySchedule[]> => {
  return useSetAtom(holidaySchedulesAtom);
};

export const useHolidaySchedulesAtom = (): UseFullAtom<HolidaySchedule[]> => {
  return useAtom(holidaySchedulesAtom);
};

export const useLookupHolidaySchedule = (): LookupAtomFunction<HolidaySchedule> => {
  return useAtomValue(lookupHolidayScheduleAtom);
};

export const useRefetchHolidaySchedules = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchHolidaySchedulesAtom);
};

const HolidayScheduleOptionsMapCallback = (s: HolidaySchedule): Option<string> => {
  return { label: s.title, value: s._id };
};

export const useHolidayScheduleOptions = (opts?: UseOptionOpts<HolidaySchedule>): Option<string>[] => {
  const hols = useHolidaySchedules();
  return useOptions(hols, { mapFunc: HolidayScheduleOptionsMapCallback }, opts);
};

/*
 * COST TYPES
 */
export const useCostTypes = (): CostType[] => {
  return useAtomValue(costTypesAtom);
};

export const useSetCostTypes = (): UseSetAtom<CostType[]> => {
  return useSetAtom(costTypesAtom);
};

export const useCostTypesAtom = (): UseFullAtom<CostType[]> => {
  return useAtom(costTypesAtom);
};

export const useLookupCostType = (): LookupAtomFunction<CostType> => {
  return useAtomValue(lookupCostTypeAtom);
};

export const useRefetchCostTypes = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchCostTypesAtom);
};

const costTypeOptionsMapCallback = (s: CostType): Option<string> => {
  return { label: s.label, value: s._id };
};

export const useCostTypeOptions = (opts?: UseOptionOpts<CostType>): Option<string>[] => {
  const types = useCostTypes();
  return useOptions(types, { mapFunc: costTypeOptionsMapCallback }, opts);
};

export const useCostTypeFormatter = (): ((ct: NameFormatterInput<CostType>) => string) => {
  const lookupCostType = useLookupCostType();
  return useCallback(
    (ct: NameFormatterInput<CostType>) => {
      if (!ct) return "";
      const costType = typeof ct === "string" ? lookupCostType(ct) : ct;
      return costType?.label || "";
    },
    [lookupCostType]
  );
};

/*
 * CREWS
 */
export const useCrews = (): Crew[] => {
  return useAtomValue(crewsAtom);
};

export const useSetCrews = (): UseSetAtom<Crew[]> => {
  return useSetAtom(crewsAtom);
};

export const useCrewsAtom = (): UseFullAtom<Crew[]> => {
  return useAtom(crewsAtom);
};

export const useLookupCrew = (): LookupAtomFunction<Crew> => {
  return useAtomValue(lookupCrewAtom);
};

export const useRefetchCrews = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchCrewsAtom);
};

export const useCrewOptions = (opts?: UseOptionOpts<Crew>): Option<string>[] => {
  const crews = useCrews();
  return useOptions(crews, { mapFunc: crewOptionsCallback }, opts);
};

const crewOptionsCallback = (c: Crew): Option<string> => {
  return { label: c.name, value: c._id };
};

export const useLookupTeamMemberCrews = (): LookupAtomFunction<Crew[]> => {
  return useAtomValue(lookupTeamMemberCrewsAtom);
};

/*
 * DEPARTMENTS
 */
export const useDepartments = (): Department[] => {
  return useAtomValue(departmentsAtom);
};

export const useSetDepartments = (): UseSetAtom<Department[]> => {
  return useSetAtom(departmentsAtom);
};

export const useDepartmentsAtom = (): UseFullAtom<Department[]> => {
  return useAtom(departmentsAtom);
};

export const useLookupDepartment = (): LookupAtomFunction<Department> => {
  return useAtomValue(lookupDepartmentAtom);
};

export const useRefetchDepartments = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchDepartmentsAtom);
};

const deptOptionsMapCallback = (s: Department): Option<string> => {
  return { label: s.name, value: s._id };
};

export const useDepartmentOptions = (opts?: UseOptionOpts<Department>): Option<string>[] => {
  const depts = useDepartments();
  return useOptions(depts, { mapFunc: deptOptionsMapCallback }, opts);
};

export const useDepartmentNameFormatter = (): ((id?: string) => string) => {
  const lookupDepartment = useLookupDepartment();
  return useCallback(
    (id?: string) => {
      const department = lookupDepartment(id);
      return department?.name || "";
    },
    [lookupDepartment]
  );
};

/*
 * PERFORMANCE REVIEW SCHEDULES
 */
export const usePerformanceReviewSchedules = (): AggregatedPerformanceReviewCycle[] => {
  return useAtomValue(performanceReviewSchedulesAtom);
};

export const useSetPerformanceReviewSchedules = (): UseSetAtom<AggregatedPerformanceReviewCycle[]> => {
  return useSetAtom(performanceReviewSchedulesAtom);
};

export const useLookupPerformanceReviewSchedule =
  (): LookupAtomFunction<AggregatedPerformanceReviewCycle> => {
    return useAtomValue(lookupPerformanceReviewSchedulesAtom);
  };

export const useRefetchPerformanceReviewSchedules = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchPerformanceReviewSchedulesAtom);
};

/*
 * LEDGER ACCOUNTS
 */
export const useLedgerAccounts = (): LedgerAccount[] => {
  return useAtomValue(ledgerAccountsAtom);
};

export const useSetLedgerAccounts = (): UseSetAtom<LedgerAccount[]> => {
  return useSetAtom(ledgerAccountsAtom);
};

export const useLedgerAccountsAtom = (): UseFullAtom<LedgerAccount[]> => {
  return useAtom(ledgerAccountsAtom);
};

export const useLookupLedgerAccount = (): LookupAtomFunction<LedgerAccount> => {
  return useAtomValue(lookupLedgerAccountAtom);
};

export const useRefetchLedgerAccounts = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchLedgerAccountsAtom);
};

export const ledgerAccountOptionsMapCallback = (la: LedgerAccount): Option<string> => {
  const qboAccountType = la.integrations?.qbo?.account?.AccountType;
  const qbdAccountType = la.integrations?.qbd?.account.AccountType;
  const isAP = qboAccountType === "Accounts Payable" || qbdAccountType === "AccountsPayable";
  const isAR = qboAccountType === "Accounts Receivable" || qbdAccountType === "AccountsReceivable";
  return {
    value: la._id,
    label: getLedgerAccountLabel(la),

    // QB A/P and A/R accounts are disabled because they require a Vendor/Customer selection when sending GL entries back to QBO, and we don't currently support that
    isDisabled: !la.active || isAP || isAR,
  };
};

// Accounts are already sorted properly in the atom and we don't want to disturb that. Since `Array.sort` is a stable sort, then we can return 0 to prevent sorting by option label
const ledgerAccountSorter = (_a: LedgerAccount, _b: LedgerAccount) => {
  return 0;
};

export const useLedgerAccountOptions = (opts?: UseOptionOpts<LedgerAccount>): Option<string>[] => {
  const types = useLedgerAccounts();
  return useOptions(
    types,
    {
      mapFunc: ledgerAccountOptionsMapCallback,
      sortFunc: ledgerAccountSorter,
    },
    opts
  );
};

export const useLedgerAccountLabeler = (): ((a?: LedgerAccount | string | null | undefined) => string) => {
  const lookupLedgerAccount = useLookupLedgerAccount();
  return useCallback(
    (a: LedgerAccount | string | null | undefined): string => {
      const account = typeof a === "string" ? lookupLedgerAccount(a) : a;
      if (!account) return "";
      return getLedgerAccountLabel(account);
    },
    [lookupLedgerAccount]
  );
};

/*
 * TIME OFF POLICIES
 */
export const useTimeOffPolicies = (): TimeOffPolicy[] => {
  return useAtomValue(timeOffPoliciesAtom);
};

export const useTimeOffPolicyOptions = (opts?: UseOptionOpts<TimeOffPolicy>): Option<string>[] => {
  const timeOffPolicies = useTimeOffPolicies();

  const timeOffPoliciesCallback = (timeoffPolicy: TimeOffPolicy): Option<string> => {
    return { label: timeoffPolicy.name, value: timeoffPolicy._id };
  };

  return useOptions(timeOffPolicies, { mapFunc: timeOffPoliciesCallback }, opts);
};

export const useSetTimeOffPolicies = (): UseSetAtom<TimeOffPolicy[]> => {
  return useSetAtom(timeOffPoliciesAtom);
};

export const useTimeOffPoliciesAtom = (): UseFullAtom<TimeOffPolicy[]> => {
  return useAtom(timeOffPoliciesAtom);
};

export const useLookupTimeOffPolicy = (): LookupAtomFunction<TimeOffPolicy> => {
  return useAtomValue(lookupTimeOffPolicyAtom);
};

export const useRefetchTimeOffPolicies = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchTimeOffPoliciesAtom);
};

/*
 * Job Postings
 */

export const useJobPostings = (): AggregatedJobPosting[] => {
  return useAtomValue(jobPostingsAtom);
};

export const useSetJobPostings = (): UseSetAtom<AggregatedJobPosting[]> => {
  return useSetAtom(jobPostingsAtom);
};

export const useJobPostingsAtom = (): UseFullAtom<AggregatedJobPosting[]> => {
  return useAtom(jobPostingsAtom);
};

export const useLookupJobPostings = (): LookupAtomFunction<AggregatedJobPosting> => {
  return useAtomValue(lookupJobPostingsAtom);
};

export const useRefetchJobPostings = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchJobPostingsAtom);
};

/*
 * Forms
 */

export const useForms = (): Form[] => {
  return useAtomValue(formsAtom);
};

export const useSetForms = (): UseSetAtom<Form[]> => {
  return useSetAtom(formsAtom);
};

export const useFormsAtom = (): UseFullAtom<Form[]> => {
  return useAtom(formsAtom);
};

export const useLookupForms = (): LookupAtomFunction<Form> => {
  return useAtomValue(lookupFormsAtom);
};

export const useRefetchForms = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchFormsAtom);
};

export const useFormOptions = (opts?: UseOptionOpts<Form>): Option<string>[] => {
  const forms = useForms();

  const formsCallBack = (form: Form): Option<string> => {
    return { label: form.name, value: form._id };
  };

  return useOptions(forms, { mapFunc: formsCallBack }, opts);
};

export const useCompanyFormOptions = (opts?: UseOptionOpts<Form>): Option<string>[] => {
  const forms = useForms();

  const predicate = (form: Form): boolean => {
    if (form.parent_type === "company") {
      if (opts?.predicate) {
        return opts.predicate(form);
      }
      return true;
    }
    return false;
  };

  const formsCallBack = (form: Form): Option<string> => {
    return { label: form.name, value: form._id };
  };

  return useOptions(forms, { mapFunc: formsCallBack }, { ...opts, predicate });
};

// Files, default company documents

export const useCompanyDocuments = (): MiterFile[] => {
  return useAtomValue(companyDocumentsAtom);
};

export const useSetCompanyDocuments = (): UseSetAtom<MiterFile[]> => {
  return useSetAtom(companyDocumentsAtom);
};

export const useCompanyDocumentsAtom = (): UseFullAtom<MiterFile[]> => {
  return useAtom(companyDocumentsAtom);
};

export const useLookupCompanyDocuments = (): LookupAtomFunction<MiterFile> => {
  return useAtomValue(lookupCompanyDocumentsAtom);
};

export const useRefetchCompanyDocuments = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchCompanyDocumentsAtom);
};

export const useCompanyDocumentOptions = (opts?: UseOptionOpts<MiterFile>): Option<string>[] => {
  const documents = useCompanyDocuments();

  const formsCallBack = (form: MiterFile): Option<string> => {
    return { label: form.label || form.originalname || "Untitled form", value: form._id };
  };

  return useOptions(documents, { mapFunc: formsCallBack }, opts);
};

/** CHECKLISTS */

export const useOnboardingChecklists = (): OnboardingChecklist[] => {
  return useAtomValue(onboardingChecklistsAtom);
};

export const useSetOnboardingChecklists = (): UseSetAtom<OnboardingChecklist[]> => {
  return useSetAtom(onboardingChecklistsAtom);
};

export const useOnboardingChecklistsAtom = (): UseFullAtom<OnboardingChecklist[]> => {
  return useAtom(onboardingChecklistsAtom);
};

export const useLookupOnboardingChecklists = (): LookupAtomFunction<OnboardingChecklist> => {
  return useAtomValue(lookupOnboardingChecklistsAtom);
};

export const useRefetchOnboardingChecklists = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchOnboardingChecklistsAtom);
};

export const useOnboardingChecklistOptions = (
  opts?: UseOptionOpts<OnboardingChecklist>
): Option<string>[] => {
  const forms = useOnboardingChecklists();

  const onboardingChecklistCallBack = (onboardingChecklist: OnboardingChecklist): Option<string> => {
    return { label: onboardingChecklist.title, value: onboardingChecklist._id };
  };

  return useOptions(forms, { mapFunc: onboardingChecklistCallBack }, opts);
};

/** CERTIFICATION TYPES */

export const useCertificationTypes = (): AggregatedCertificationType[] => {
  return useAtomValue(certificationTypesAtom);
};

export const useSetCertificationTypes = (): UseSetAtom<AggregatedCertificationType[]> => {
  return useSetAtom(certificationTypesAtom);
};

export const useCertificationTypesAtom = (): UseFullAtom<AggregatedCertificationType[]> => {
  return useAtom(certificationTypesAtom);
};

export const useLookupCertificationTypes = (): LookupAtomFunction<AggregatedCertificationType> => {
  return useAtomValue(lookupCertificationTypesAtom);
};

export const useRefetchCertificationTypes = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchCertificationTypesAtom);
};

export const useCertificationTypeOptions = (
  opts?: UseOptionOpts<AggregatedCertificationType>
): Option<string>[] => {
  const certificationTypes = useCertificationTypes();

  const certificationTypeCallback = (certificationType: AggregatedCertificationType): Option<string> => {
    return { label: certificationType.title, value: certificationType._id };
  };

  return useOptions(certificationTypes, { mapFunc: certificationTypeCallback }, opts);
};

/** FILLABLE TEMPLATES */

export const useFillableTemplates = (): AggregatedFillableTemplate[] => {
  return useAtomValue(fillableTemplatesAtom);
};

export const useSetFillableTemplate = (): UseSetAtom<AggregatedFillableTemplate[]> => {
  return useSetAtom(fillableTemplatesAtom);
};

export const useFillableTemplatesAtom = (): UseFullAtom<AggregatedFillableTemplate[]> => {
  return useAtom(fillableTemplatesAtom);
};

export const useLookupFillableTemplates = (): LookupAtomFunction<AggregatedFillableTemplate> => {
  return useAtomValue(lookupFillableTemplatesAtom);
};

export const useRefetchFillableTemplates = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchFillableTemplatesAtom);
};

export const useFillableTemplateOptions = (
  opts?: UseOptionOpts<AggregatedFillableTemplate>
): Option<string>[] => {
  const fillableTemplates = useFillableTemplates();

  const fillableTemplateCallback = (fillableTemplate: AggregatedFillableTemplate): Option<string> => {
    return { label: fillableTemplate.name, value: fillableTemplate._id };
  };

  return useOptions(fillableTemplates, { mapFunc: fillableTemplateCallback }, opts);
};

/*
 * WORKPLACES
 */
export const useWorkplaces = (): Workplace[] => {
  return useAtomValue(workplacesAtom);
};

export const useSetWorkplaces = (): UseSetAtom<Workplace[]> => {
  return useSetAtom(workplacesAtom);
};

export const useWorkplacesAtom = (): UseFullAtom<Workplace[]> => {
  return useAtom(workplacesAtom);
};

export const useLookupWorkplace = (): LookupAtomFunction<Workplace> => {
  return useAtomValue(lookupWorkplacesAtom);
};

export const useRefetchWorkplaces = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchWorkplacesAtom);
};

export const useWorkplaceFormatter = (): ((workplace: NameFormatterInput<Workplace>) => string) => {
  const lookupWorkplace = useLookupWorkplace();
  return useCallback(
    (w: NameFormatterInput<Workplace>): string => {
      if (!w) return "";
      const workplace = typeof w === "string" ? lookupWorkplace(w) : w;
      if (!workplace) return "";
      const workplaceName = workplace.name || workplace.check_workplace.address.line1;
      return `${workplaceName}${workplace.is_company_default ? " (default)" : ""}`;
    },
    [lookupWorkplace]
  );
};

export const useWorkplaceOptions = (opts?: UseOptionOpts<Workplace>): Option<string>[] => {
  const workplaces = useDedupedWorkplaces();

  const formatter = useWorkplaceFormatter();
  const workplaceOptionsCallback = useCallback(
    (workplace: Workplace): Option<string> => {
      return { label: formatter(workplace), value: workplace._id };
    },
    [formatter]
  );

  return useOptions(workplaces, { mapFunc: workplaceOptionsCallback }, opts);
};

export const useDedupedWorkplaces = (): Workplace[] => {
  const workplaces = useWorkplaces();
  return useMemo(() => workplaces.filter((w) => !w.duplicate_of), [workplaces]);
};

export const useActiveWorkplaces = (): Workplace[] => {
  const workplaces = useWorkplaces();
  return useMemo(() => workplaces.filter((w) => w.check_workplace.active && !w.duplicate_of), [workplaces]);
};

/*
 * CONFETTI
 */
export const useConfettiAtom = (): UseFullAtom<boolean> => {
  return useAtom(confettiAtom);
};

export const useSetConfetti = (): UseSetAtom<boolean> => {
  return useSetAtom(confettiAtom);
};

/*
 * SHOW NEW RELEASE MODAL
 */
export const useShowNewReleaseModalAtom = (): UseFullAtom<boolean> => {
  return useAtom(showNewReleaseModalAtom);
};

/*
 * HIT UNAUTHORIZED ERROR
 */
export const useHitUnauthorizedErrorAtom = (): UseFullAtom<boolean> => {
  return useAtom(hitUnauthorizedErrorAtom);
};

/*
 * LAST REFRESHED AT
 */
export const useLastRefreshedAtAtom = (): UseFullAtom<DateTime> => {
  return useAtom(lastRefreshedAtAtom);
};

export const useSetLastRefreshedAt = (): UseSetAtom<DateTime> => {
  return useSetAtom(lastRefreshedAtAtom);
};

/*
 * ACTION CENTER
 */
export const useActionableItems = (): UseFullAtom<ActionableItems | null> => {
  return useAtom(actionableItemsAtom);
};

export const useSetActionableItems = (): UseSetAtom<ActionableItems | null> => {
  return useSetAtom(actionableItemsAtom);
};

export const useRefetchActionableItems = (): (() => Promise<void>) => {
  const refetchAction = useSetAtom(refetchActionableItemsAtom);
  return useCallback(() => refetchAction(), [refetchAction]);
};

/*
 * PERMISSION GROUPS
 */
export const usePermissionGroups = (): PermissionGroup[] => {
  return useAtomValue(permissionGroupsAtom);
};

export const useSetPermissionGroups = (): UseSetAtom<PermissionGroup[]> => {
  return useSetAtom(permissionGroupsAtom);
};

export const usePermissionGroupsAtom = (): UseFullAtom<PermissionGroup[]> => {
  return useAtom(permissionGroupsAtom);
};

export const useLookupPermissionGroup = (): LookupAtomFunction<PermissionGroup> => {
  return useAtomValue(lookupPermissionGroupsAtom);
};

export const useRefetchPermissionGroups = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchPermissionGroupsAtom);
};

const PermissionGroupOptionsCallback = (s: PermissionGroup): Option<string> => {
  return { label: s.name, value: s._id };
};

export const usePermissionGroupOptions = (
  opts?: UseOptionOpts<PermissionGroup> & { excludeSuperAdmins?: boolean }
): Option<string>[] => {
  const hols = usePermissionGroups();

  const filteredGroups = useMemo(() => {
    if (opts?.excludeSuperAdmins) {
      return hols.filter((p) => !p.super_admin);
    }
    return hols;
  }, [hols, opts?.excludeSuperAdmins]);

  return useOptions(filteredGroups, { mapFunc: PermissionGroupOptionsCallback }, opts);
};

/*
 * SESSION PERMISSION GROUPS
 */
export const useSessionPermissionGroups = (): PermissionGroup[] => {
  return useAtomValue(sessionPermissionGroupsAtom);
};

export const useSetSessionPermissionGroups = (): UseSetAtom<PermissionGroup[]> => {
  return useSetAtom(sessionPermissionGroupsAtom);
};

export const useSessionPermissionGroupsAtom = (): UseFullAtom<PermissionGroup[]> => {
  return useAtom(sessionPermissionGroupsAtom);
};

export const useRefetchSessionPermissionGroups = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchSessionPermissionGroupsAtom);
};

export const useSessionPermissionGroupsFetched = (): boolean => {
  return useAtomValue(sessionPermissionGroupsFetchedAtom);
};

/*
 * SUPER ADMIN
 */
export const useIsSuperAdmin = (): boolean => {
  const sessionPermissionGroups = useSessionPermissionGroups();
  const user = useUser();
  const activeCompanyId = useActiveCompanyId();

  return useMemo(() => {
    const isMiterAdmin = user?.miter_admin && activeCompanyId !== MITER_COMPANY_ID;
    return isMiterAdmin || sessionPermissionGroups.some((p) => p.super_admin);
  }, [sessionPermissionGroups, user]);
};

/*
 * MITER ADMIN
 */
export const useIsMiterAdmin = (): boolean => {
  const user = useUser();
  return useMemo(() => !!user?.miter_admin, [user]);
};

/**
 * ABILITIES LIST
 */
export const useHydratedPermissionGroupsAtom = (): UseFullAtom<HydratedPermissionGroups> => {
  return useAtom(hydratedPermissionGroupsAtom);
};

export const useSetHydratedPermissionGroups = (): UseSetAtom<HydratedPermissionGroups> => {
  return useSetAtom(hydratedPermissionGroupsAtom);
};

export const useHydratedPermissionGroups = (): HydratedPermissionGroups => {
  return useAtomValue(hydratedPermissionGroupsAtom);
};

/*
 * Stripe Connected Account
 */

export const useStripeConnectedAccount = (): StripeAccountResponse | null => {
  return useAtomValue(stripeConnectedAccountAtom);
};

export const useSetStripeConnectedAccount = (): UseSetAtom<StripeAccountResponse | null> => {
  return useSetAtom(stripeConnectedAccountAtom);
};

/*
 * Expense Reimbursement Categories
 */
export const useExpenseReimbursementCategories = (): ExpenseReimbursementCategory[] => {
  return useAtomValue(expenseReimbursementCategoriesAtom);
};

export const useDefaultExpenseReimbursementCategory = (): ExpenseReimbursementCategory | undefined => {
  const categories = useExpenseReimbursementCategories();
  return categories.find((lm) => lm.is_default);
};

export const expenseReimbursementCategoryOptionsMapCallback =
  (params: { sessionPermissionGroupIds: string[]; isSuperAdmin: boolean }) =>
  (category: ExpenseReimbursementCategory): Option<string> => {
    const { sessionPermissionGroupIds, isSuperAdmin } = params;

    return {
      label: category.name,
      value: category._id,
      isDisabled:
        !isSuperAdmin &&
        !!category.permission_group_ids?.length &&
        !category.permission_group_ids.some((pgId) => sessionPermissionGroupIds.includes(pgId)),
    };
  };

export const useExpenseReimbursementCategoryOptions = (
  opts?: UseOptionOpts<ExpenseReimbursementCategory>
): Option<string>[] => {
  const categories = useExpenseReimbursementCategories();
  const isSuperAdmin = useIsSuperAdmin();

  // all permission groups currently logged in TM is part of
  const sessionPermissionGroups = useSessionPermissionGroups();

  return useOptions(
    categories,
    {
      mapFunc: expenseReimbursementCategoryOptionsMapCallback({
        isSuperAdmin,
        sessionPermissionGroupIds: sessionPermissionGroups.map((pg) => pg._id),
      }),
    },
    opts
  );
};

export const useLookupExpenseReimbursementCategories =
  (): LookupAtomFunction<ExpenseReimbursementCategory> => {
    return useAtomValue(lookupExpenseReimbursementCategoriesAtom);
  };

export const useSetExpenseReimbursementCategories = (): UseSetAtom<ExpenseReimbursementCategory[]> => {
  return useSetAtom(expenseReimbursementCategoriesAtom);
};

export const useRefetchExpenseReimbursementCategories = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchExpenseReimbursementCategoriesAtom);
};

export const useExpenseReimbursementCategoryName = (): ((id?: string) => string) => {
  const lookCategory = useLookupExpenseReimbursementCategories();
  return useCallback(
    (id?): string => {
      const category = lookCategory(id);
      if (!category) return "";
      return category.name;
    },
    [lookCategory]
  );
};

/*
 * Card Transaction Categories
 */
export const useCardTransactionCategories = (): CardTransactionCategory[] => {
  return useAtomValue(cardTransactionCategoriesAtom);
};

export const cardTransactionCategoryOptionsMapCallback =
  (params: { sessionPermissionGroupIds: string[]; isSuperAdmin: boolean }) =>
  (category: CardTransactionCategory): Option<string> => {
    const { sessionPermissionGroupIds, isSuperAdmin } = params;

    return {
      label: category.name,
      value: category._id,
      isDisabled:
        !isSuperAdmin &&
        !!category.permission_group_ids?.length &&
        !category.permission_group_ids.some((pgId) => sessionPermissionGroupIds.includes(pgId)),
    };
  };

export const useCardTransactionCategoryOptions = (
  opts?: UseOptionOpts<CardTransactionCategory>
): Option<string>[] => {
  const categories = useCardTransactionCategories();
  const isSuperAdmin = useIsSuperAdmin();

  // all permission groups currently logged in TM is part of
  const sessionPermissionGroups = useSessionPermissionGroups();

  return useOptions(
    categories,
    {
      mapFunc: cardTransactionCategoryOptionsMapCallback({
        isSuperAdmin,
        sessionPermissionGroupIds: sessionPermissionGroups.map((pg) => pg._id),
      }),
    },
    opts
  );
};

export const useLookupCardTransactionCategories = (): LookupAtomFunction<CardTransactionCategory> => {
  return useAtomValue(lookupCardTransactionCategoriesAtom);
};

export const useSetCardTransactionCategories = (): UseSetAtom<CardTransactionCategory[]> => {
  return useSetAtom(cardTransactionCategoriesAtom);
};

export const useRefetchCardTransactionCategories = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchCardTransactionCategoriesAtom);
};

export const useCardTransactionCategoryName = (): ((id?: string) => string) => {
  const lookCategory = useLookupCardTransactionCategories();
  return useCallback(
    (id?): string => {
      const category = lookCategory(id);
      if (!category) return "";
      return category.name;
    },
    [lookCategory]
  );
};

/*
 * REPORT VIEWS
 */
export const useReportViews = (): ReportView[] => {
  return useAtomValue(reportViewsAtom);
};

export const useRefetchReportViews = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchReportViewsAtom);
};

export const useScopedReportViews = (): ReportView[] => {
  const allReportViews = useReportViews();
  const user = useUser();
  return useMemo(
    () => allReportViews.filter((view) => view.creator_user_id === user?._id || view.scope === "company"),
    [allReportViews]
  );
};

export const useSetReportViews = (): UseSetAtom<ReportView[]> => {
  return useSetAtom(reportViewsAtom);
};

/*
 * PROFILE PICTURE URLS MAP
 */
export const useProfilePictureUrlsMap = (): Record<string, string> => {
  return useAtomValue(profilePictureUrlsAtom);
};

export const useSetProfilePictureUrlsMap = (): UseSetAtom<Record<string, string>> => {
  return useSetAtom(profilePictureUrlsAtom);
};

export const EMPTY_JOB_KEY = "";

/*
 * RATE DIFFERENTIALS
 */
export const usePerDiemRates = (): PerDiemRate[] => {
  return useAtomValue(perDiemRatesAtom);
};

export const useSetPerDiemRates = (): UseSetAtom<PerDiemRate[]> => {
  return useSetAtom(perDiemRatesAtom);
};

export const useLookupPerDiemRates = (): LookupAtomFunction<PerDiemRate> => {
  return useAtomValue(lookupPerDiemRatesAtom);
};

export const useRefetchPerDiemRates = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchPerDiemRatesAtom);
};

export const perDiemRateOptionsMapCallback = (perDiemRate: PerDiemRate): Option<string> => {
  return {
    label: perDiemRate.name,
    value: perDiemRate._id,
  };
};

export const usePerDiemRateOptions = (opts?: UseOptionOpts<PerDiemRate>): Option<string>[] => {
  const perDiemRates = usePerDiemRates();
  return useOptions(perDiemRates, { mapFunc: perDiemRateOptionsMapCallback }, opts);
};

/*
 * LOCATIONS
 */
export const useLocations = (): Location[] => {
  return useAtomValue(locationsAtom);
};

export const useSetLocations = (): UseSetAtom<Location[]> => {
  return useSetAtom(locationsAtom);
};

export const useLocationsAtom = (): UseFullAtom<Location[]> => {
  return useAtom(locationsAtom);
};

export const useLookupLocation = (): LookupAtomFunction<Location> => {
  return useAtomValue(lookupLocationsAtom);
};

export const useRefetchLocations = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchLocationsAtom);
};

const locationsOptionsMapCallback = (s: Location): Option<string> => {
  return { label: s.name, value: s._id };
};

export const useLocationOptions = (opts?: UseOptionOpts<Location>): Option<string>[] => {
  const locations = useLocations();
  return useOptions(locations, { mapFunc: locationsOptionsMapCallback }, opts);
};

export const useLocationNameFormatter = (): ((id?: string) => string) => {
  const lookupLocation = useLookupLocation();
  return useCallback(
    (id?: string) => {
      const location = lookupLocation(id);
      return location?.name || "";
    },
    [lookupLocation]
  );
};

/*
 * BANNER NOTIFICATIONS
 */
export const useBannerNotification = (notificationName: string): UseFullAtom<string | null> => {
  const activeCompanyId = useActiveCompanyId();
  const bannerNotificationKey = `${notificationName}_${activeCompanyId}`;
  const notificationAtom = bannerNotificationsAtomFamily(bannerNotificationKey);
  return useAtom(notificationAtom);
};

/*
 * TEAM CHAT
 */
export const useTeamTwilioClient = (): TwilioClient | null => {
  return useAtomValue(teamTwilioClientAtom);
};

export const useSetTeamTwilioClient = (): ((update: TwilioClient | null) => void) => {
  return useSetAtom(teamTwilioClientAtom);
};

/** Chat Conversations */
export const useTeamChatConversations = (): AggregatedTeamConversation[] => {
  return useAtomValue(teamChatConversationsAtom);
};

export const useSetTeamChatConversations = (): UseSetAtom<AggregatedTeamConversation[]> => {
  return useSetAtom(teamChatConversationsAtom);
};

/** Active team chat conversation */
export const useActiveTeamChatConversation = (): AggregatedTeamConversation | null => {
  return useAtomValue(activeTeamChatConversationAtom);
};

export const useSetActiveTeamChatConversation = (): UseSetAtom<AggregatedTeamConversation | null> => {
  return useSetAtom(activeTeamChatConversationAtom);
};

/** Team chat initialized */
export const useIsTeamChatInitialized = (): boolean => {
  return useAtomValue(isTeamChatInitializedAtom);
};

export const useSetIsTeamChatInitialized = (): UseSetAtom<boolean> => {
  return useSetAtom(isTeamChatInitializedAtom);
};

export const useTeamChatRestart = (): number => {
  return useAtomValue(teamChatRestartAtom);
};

export const useSetTeamChatRestart = (): UseSetAtom<number> => {
  return useSetAtom(teamChatRestartAtom);
};
/*
 * RECRUITING CHAT
 */

export const useRecruitingTwilioClient = (): TwilioClient | null => {
  return useAtomValue(recruitingTwilioClientAtom);
};

export const useSetRecruitingTwilioClient = (): ((update: TwilioClient | null) => void) => {
  return useSetAtom(recruitingTwilioClientAtom);
};

/** "Loaded" recruiting conversations */
export const usePaginatedRecruitingConversations = (): AggregatedRecruitingConversation[] => {
  return useAtomValue(paginatedRecruitingConversationsAtom);
};

export const useSetPaginatedRecruitingConversations = (): UseSetAtom<AggregatedRecruitingConversation[]> => {
  return useSetAtom(paginatedRecruitingConversationsAtom);
};

export const useActiveRecruitingConversation = (): AggregatedRecruitingConversation | null => {
  return useAtomValue(activeRecruitingConversationAtom);
};

export const useSetActiveRecruitingConversation = (): UseSetAtom<AggregatedRecruitingConversation | null> => {
  return useSetAtom(activeRecruitingConversationAtom);
};

export const useRecruitingChatInitialized = (): boolean => {
  return useAtomValue(isRecruitingChatInitializedAtom);
};

export const useSetRecruitingChatInitialized = (): UseSetAtom<boolean> => {
  return useSetAtom(isRecruitingChatInitializedAtom);
};

export const useUnreadRecruitingConversationsCount = (): number => {
  return useAtomValue(unreadRecruitingConversationsCountAtom);
};

export const useSetUnreadRecruitingConversationsCount = (): UseSetAtom<number> => {
  return useSetAtom(unreadRecruitingConversationsCountAtom);
};

export const useRecruitingChatRestart = (): number => {
  return useAtomValue(recruitingChatRestartAtom);
};

export const useSetRecruitingChatRestart = (): UseSetAtom<number> => {
  return useSetAtom(recruitingChatRestartAtom);
};

/*
 * Vendors
 */
export const useVendors = (): AggregatedVendor[] => {
  return useAtomValue(vendorsAtom);
};

export const useSetVendors = (): UseSetAtom<AggregatedVendor[]> => {
  return useSetAtom(vendorsAtom);
};

export const useVendorsAtom = (): UseFullAtom<AggregatedVendor[]> => {
  return useAtom(vendorsAtom);
};

export const useLookupVendor = (): LookupAtomFunction<AggregatedVendor> => {
  return useAtomValue(lookupVendorsAtom);
};

export const useRefetchVendors = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchVendorsAtom);
};

const vendorsOptionsMapCallback = (s: AggregatedVendor): Option<string> => {
  return { label: s.name, value: s._id };
};

export const useVendorOptions = (opts?: UseOptionOpts<AggregatedVendor>): Option<string>[] => {
  const vendors = useVendors();
  return useOptions(vendors, { mapFunc: vendorsOptionsMapCallback }, opts);
};

export const useVendorNameFormatter = (): ((id?: string) => string) => {
  const lookupVendor = useLookupVendor();
  return useCallback(
    (id?: string) => {
      const vendor = lookupVendor(id);
      return vendor?.name || "";
    },
    [lookupVendor]
  );
};

/*
 * BENEFITS ELIGIBILITY GROUPS
 */
export const useBenefitsEligibilityGroups = (): BenefitsEligibilityGroup[] => {
  return useAtomValue(benefitsEligibilityGroupsAtom);
};
export const useSetBenefitsEligibilityGroups = (): UseSetAtom<BenefitsEligibilityGroup[]> => {
  return useSetAtom(benefitsEligibilityGroupsAtom);
};

export const useLookupBenefitsEligibilityGroups = (): LookupAtomFunction<BenefitsEligibilityGroup> => {
  return useAtomValue(lookupBenefitsEligibilityGroupsAtom);
};

export const useRefetchBenefitsEligibilityGroups = (): ((ids?: RefetchInput) => Promise<void>) => {
  return useSetAtom(refetchBenefitsEligibilityGroupsAtom);
};

export const useBenefitsEligibilityGroupFormatter = (): ((
  benefitsEligibilityGroup: NameFormatterInput<BenefitsEligibilityGroup>
) => string) => {
  const lookupBenefitsEligibilityGroups = useLookupBenefitsEligibilityGroups();
  return useCallback(
    (s: NameFormatterInput<BenefitsEligibilityGroup>): string => {
      if (!s) return "";
      const benefitsEligibilityGroup = typeof s === "string" ? lookupBenefitsEligibilityGroups(s) : s;
      return benefitsEligibilityGroup?.integrations?.clasp?.clasp_subclass?.name || "";
    },
    [lookupBenefitsEligibilityGroups]
  );
};

export const useBenefitsEligibilityGroupOptions = (
  opts?: UseOptionOpts<BenefitsEligibilityGroup>
): Option<string>[] => {
  const benefitsEligibilityGroups = useBenefitsEligibilityGroups();
  const formatter = useBenefitsEligibilityGroupFormatter();
  const benefitsEligibilityGroupOptionsCallback = useCallback(
    (benefitsEligibilityGroup: BenefitsEligibilityGroup): Option<string> => {
      return { label: formatter(benefitsEligibilityGroup), value: benefitsEligibilityGroup._id };
    },
    [formatter]
  );
  return useOptions(benefitsEligibilityGroups, { mapFunc: benefitsEligibilityGroupOptionsCallback }, opts);
};
