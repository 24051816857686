import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Badge, Label, ModalHeader, Notifier, SliderModal, usdString } from "ui";
import { DateTime } from "luxon";
import { useActiveCompanyId, useIsSuperAdmin, useRefetchActionableItems } from "../../../hooks/atom-hooks";
import { AggregatedExpense, MiterAPI, MiterCardTransaction } from "../../../miter";
import { CardTransactionsModalFooter } from "./CardTransactionsModalFooter";
import { UpdateExpenseParams } from "backend/services/expenses/expense-service";
import { useDebouncedCallback } from "use-debounce";
import { expenseTableColors, generateDeclineReasonString } from "../expenseUtils";
import { ApprovalTimeline } from "dashboard/components/approvals/ApprovalTimeline";
import { useExpenseAbilities } from "dashboard/hooks/abilities-hooks/useExpenseAbilities";
import { SplitCardTransactionModal } from "./SplitCardTransactionModal";
import { CardTransactionsModalForm } from "./CardTransactionsModalForm";
import { NeedsAttentionBanner } from "dashboard/components/policies/NeedsAttentionBanner";
import { ApprovalItemPolicyData } from "dashboard/components/approvals/ApprovalItemPolicyData";
import { LeftAlignedModalFormToggler } from "ui/modal/ModalFormToggler";
import { AuditLogHistory } from "dashboard/components/audit-logs/AuditLogHistory";
import { shorten } from "dashboard/utils";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type Props = {
  expenseId: string;
  onHide: (isExpenseUpdated: boolean) => void; // hide modal
  inboxMode?: InboxMode;
};

export const CardTransactionsModal: React.FC<Props> = ({ expenseId, onHide, inboxMode }) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [expenseObj, setExpenseObj] = useState<AggregatedExpense | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [isExpenseUpdated, setIsExpenseUpdated] = useState<boolean>(false);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<"Overview" | "Approval history" | "Audit log">("Overview");

  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const { can, cannot } = useExpenseAbilities({ inboxMode });

  const isUserSuperAdmin = useIsSuperAdmin();

  const debouncedUpdateExpense = useDebouncedCallback((updateExpenseParam) => {
    updateExpense(updateExpenseParam);
  }, 1000);
  const refetchActionableItems = useRefetchActionableItems();

  const refreshExpenseData = () => {
    // trigger modal refresh
    setRefreshCounter(refreshCounter + 1);
  };

  const updateExpense = async (expenseUpdateParams: UpdateExpenseParams): Promise<void> => {
    if (!expenseObj) return;

    try {
      const updateExpensesResponse = await MiterAPI.expenses.update([
        { _id: expenseObj._id, params: expenseUpdateParams },
      ]);
      if (updateExpensesResponse.error) throw Error(updateExpensesResponse.error);

      if (updateExpensesResponse.errors?.length > 0) {
        // will only be one failure
        for (const failure of updateExpensesResponse.errors) {
          Notifier.error(`${failure.message}`);
        }
      }

      Notifier.success(`Updated card transaction.`);
      setIsExpenseUpdated(true);
      refreshExpenseData();
      refetchActionableItems();
    } catch (e: $TSFixMe) {
      Notifier.error(`There was a problem updating the card transaction. We're looking into it.`);
    }
  };

  const getExpenseData = async () => {
    setIsLoading(true);
    try {
      const res = await MiterAPI.expenses.forage({
        filter: [
          { field: "company_id", value: activeCompanyId },
          { field: "_id", value: expenseId },
        ],
      });

      if (res.data.length === 0) {
        // expense is deleted
        Notifier.error(`Card transaction is deleted. You can find and unarchive it from the "Archived" tab.`);
        onHide(true);
      } else {
        setExpenseObj(res.data[0]);
      }
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error(e.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getExpenseData();
  }, [expenseId, refreshCounter]);

  // assumption: only one field will be set per update, but this could support multi-field
  const handleExpenseValueChange = useCallback(
    async (updateExpenseParam: UpdateExpenseParams) => {
      if (cannot("update", expenseObj)) return;

      if (updateExpenseParam.approver_note || updateExpenseParam.submitter_note) {
        debouncedUpdateExpense(updateExpenseParam);
      } else {
        await updateExpense(updateExpenseParam);
      }
    },
    [expenseObj]
  );

  const renderDetails = () => {
    if (!expenseObj) return <></>;

    let sourceString = expenseObj.source;
    if (expenseObj.type === "third_party_card_transaction" || expenseObj.type === "miter_card_transaction") {
      sourceString = `Card ending in ${expenseObj.source}`;
    }

    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #C4C4C4",
            borderRadius: "3px",
            padding: "20px",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: "200%", marginBottom: "5px" }}>
            {usdString(expenseObj.amount)}
          </div>
          <Label label={DateTime.fromISO(expenseObj.date).toFormat("DD")} className="form2-label modal" />
          <div
            style={{
              display: "grid",
              gridRow: "auto auto",
              gridTemplateColumns: "45% 45%",
              columnGap: "10%",
              rowGap: "15px",
              marginTop: "25px",
            }}
          >
            <div>
              <Label label="Merchant" className="form2-label modal" />
              {expenseObj.merchant_name}
            </div>
            <div>
              <Label label="Purchase category" className="form2-label modal" />
              {expenseObj["category"] || "N/A"}
            </div>
            <div>
              <Label label="Source" className="form2-label modal" />
              {sourceString}
            </div>
            <div>
              <Label label="Approval Status" className="form2-label modal" />
              <Badge
                className="table-v2-badge"
                text={expenseObj.approval_status}
                color={expenseTableColors[expenseObj.approval_status]}
              />
            </div>
            <div>
              <Label label="Purchase Status" className="form2-label modal" />
              <Badge
                className="table-v2-badge"
                text={expenseObj.purchase_status}
                color={expenseTableColors[expenseObj.purchase_status]}
              />
            </div>
            {expenseObj.purchase_status === "declined" ? (
              <div>
                <Label label="Decline Reason" className="form2-label modal" />
                {/* converting from AggregatedExpense to MiterCardTransaction */}
                {generateDeclineReasonString(expenseObj as unknown as MiterCardTransaction)}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  };

  const tabsToShow = useMemo(() => {
    const tabs = ["Overview"];
    if (expenseObj?.approval_history?.length || expenseObj?.approval_stage) {
      tabs.push("Approval history");
    }

    if (isUserSuperAdmin) {
      tabs.push("Audit log");
    }
    return tabs;
  }, [isUserSuperAdmin, expenseObj]);

  const toggler = (
    <LeftAlignedModalFormToggler
      tabs={tabsToShow}
      activeTab={activeTab}
      onToggle={(t) => {
        setActiveTab(t);
      }}
    />
  );

  const title = expenseObj
    ? `${shorten(expenseObj.merchant_name, 25)} - ${DateTime.fromISO(expenseObj.date).toFormat("DD")}`
    : "Loading...";

  const header = (
    <ModalHeader
      heading={title}
      onHide={() => onHide(isExpenseUpdated)}
      className={toggler ? "remove-border-bottom" : undefined}
    />
  );

  return (
    <SliderModal
      header={header}
      toggler={toggler}
      onCancel={() => {
        onHide(isExpenseUpdated);
      }}
      animate={true}
      shouldOnclickAwayClose={true}
      footer={
        <CardTransactionsModalFooter
          expense={expenseObj}
          onHide={() => onHide(isExpenseUpdated)}
          onSplitCardTransaction={() => setIsSplitModalOpen(true)}
          onApprovalChange={() => setIsExpenseUpdated(true)}
          refreshData={refreshExpenseData}
          inboxMode={inboxMode}
        />
      }
      loading={isLoading}
    >
      <div style={{ width: "480px" }}>
        {activeTab === "Overview" ? (
          <>
            <NeedsAttentionBanner item={expenseObj} policyType={"expense"} style={{ marginBottom: 25 }} />
            {renderDetails()}
            {expenseObj && (
              <CardTransactionsModalForm
                expense={expenseObj}
                handleExpenseValueChange={handleExpenseValueChange}
                refreshExpense={() => refreshExpenseData()}
                inboxMode={inboxMode}
              />
            )}
            {expenseObj && can("split", expenseObj) && isSplitModalOpen && (
              <SplitCardTransactionModal
                parentId={expenseObj.parent_id || expenseObj._id}
                onHide={() => setIsSplitModalOpen(false)}
                onSubmit={() => {
                  setIsExpenseUpdated(true);
                  refreshExpenseData();
                }}
              />
            )}
          </>
        ) : activeTab === "Approval history" ? (
          <>
            {expenseObj && (expenseObj.approval_history?.length || expenseObj.approval_stage) && (
              <>
                <div style={{ marginBottom: "20px", fontWeight: "bold" }}>Approval history</div>
                <ApprovalTimeline item={expenseObj} />
              </>
            )}

            {expenseObj && (expenseObj.approval_history?.length || expenseObj.approval_stage) && (
              <>
                <div style={{ marginTop: "65px", marginBottom: "20px", fontWeight: "bold" }}>
                  Approval Policy
                </div>
                <ApprovalItemPolicyData item={expenseObj} />
              </>
            )}
          </>
        ) : (
          <AuditLogHistory itemId={expenseObj?._id || ""} type={"expense"} refreshCounter={refreshCounter} />
        )}
      </div>
    </SliderModal>
  );
};
