import { Company, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Formblock } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { useActiveCompany, useActiveCompanyId, useSetActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui/settings/SettingsCard";
import InfoButton from "dashboard/components/information/information";

type CPRSettingUpdateParams = NonNullable<NonNullable<Company["settings"]["reports"]>["cpr"]>;

export const CPRSettings: React.FC = () => {
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const setActiveCompany = useSetActiveCompany();
  const cprSettings = activeCompany?.settings?.reports?.cpr;
  const form = useForm<CPRSettingUpdateParams>({ defaultValues: cprSettings });
  const { handleSubmit } = form;

  const backToCPR = () => {
    navigate("/reports/cpr");
  };

  /* Form */
  const updateSettings = async (data: CPRSettingUpdateParams) => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, { "settings.reports.cpr": data });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      setActiveCompany(response);
    } catch (e) {
      Notifier.error("There was an error updating certified payroll settings. We're looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(() => {
    handleSubmit(updateSettings)();
  }, 500);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Certified Payroll Settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={backToCPR}>
          Back to certified payroll
        </div>
        <div className="flex">
          <h1>Certified payroll settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure how your certified payroll report gets generated</div>
        <div className="vertical-spacer-small"></div>

        <SettingsCard title={"General"}>
          <div style={{ marginBottom: -10 }}>
            <Formblock
              type="checkbox"
              name="use_active_role_email"
              form={form}
              editing={true}
              onChange={updateSettingsDebounced}
              defaultValue={cprSettings?.use_active_role_email}
            >
              <div>{`Use the active user's email on the CPR cover sheet`}</div>
            </Formblock>
          </div>
        </SettingsCard>
        <SettingsCard title={"Pay rates"}>
          <Formblock
            type="checkbox"
            name="include_imputed_income_in_gross_pay"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.include_imputed_income_in_gross_pay}
          >
            <div>{`Include imputed income (taxable fringes, etc.) in reported gross pay`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="include_constant_salary_earnings"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.include_constant_salary_earnings}
          >
            <div>{`Include earnings for overtime-exempt salaried team members with pay held constant`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="include_taxable_fringes_in_pay_rates"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.include_taxable_fringes_in_pay_rates}
          >
            <div>{`Include cash and imputed per-hour fringes in pay rates`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="include_cash_fringes_for_in_lieu_payment_rates"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.include_cash_fringes_for_in_lieu_payment_rates}
          >
            <div>{`Include cash per-hour fringes in wages in lieu of fringe rates`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="reg_rate_as_total_minus_cash_in_lieu"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.reg_rate_as_total_minus_cash_in_lieu}
          >
            <div className="flex">
              {`Adjust base rate to ensure it equals total pay rate minus cash in lieu of benefits`}
              <InfoButton
                text={
                  "When a team member's base rate is greater than their classification pay rate and we use the team member rate, the cash in lieu of fringe will be counted in both the in lieu pay rate as well as in the team rate. This setting will adjust the base rate to ensure it equals the total pay rate minus cash in lieu of benefits."
                }
              />
            </div>
          </Formblock>
          <div style={{ marginBottom: -10 }}>
            <Formblock
              type="checkbox"
              name="always_display_union_base_pay_rates"
              form={form}
              editing={true}
              onChange={updateSettingsDebounced}
              defaultValue={cprSettings?.always_display_union_base_pay_rates}
            >
              <div className="flex">
                {`Always display classification base rates`}
                <InfoButton
                  text={
                    "By default, Miter will use the calculated pay rate from the week, including adjustments for weighted average overtime or custom activity pay rates. Enabling this will always use the base rate on the classification for the displayed rate and for calculating gross amounts."
                  }
                />
              </div>
            </Formblock>
          </div>
        </SettingsCard>
        <SettingsCard title={"Formatting"}>
          <Formblock
            type="checkbox"
            name="hide_zero_hours"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.hide_zero_hours}
          >
            <div>{`Don't show 0s in the hours columns of the certified payroll report PDF`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="include_all_payment_numbers"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.hide_zero_hours}
          >
            <div className="flex">
              {`Include payment numbers (ACH trace IDs, check numbers, etc.) for all payments in the week`}
              <InfoButton
                text={
                  "By default, Miter only includes the main payment number on reports, and doesn't add payment numbers for corrections or other off-cycle payrolls."
                }
              />
            </div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="add_dues_fringe_deductions"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.add_dues_fringe_deductions}
          >
            <div className="flex">
              {`Include employee fringe deductions for dues in reported totals`}
              <InfoButton text={"By default, we only report employer contributions toward fringes."} />
            </div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="add_vacation_holiday_fringe_deductions"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.add_vacation_holiday_fringe_deductions}
          >
            <div className="flex">
              {`Include employee fringe deductions for vacation/holiday in reported totals`}
              <InfoButton text={"By default, we only report employer contributions toward fringes."} />
            </div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="use_mmddyyyy_format_for_lcp_payment_date"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.use_mmddyyyy_format_for_lcp_payment_date}
          >
            <div>{`Use MM/DD/YYYY format for the LCP tracker CSV payment date`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="use_mmddyyyy_format_for_pdf_dates"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.use_mmddyyyy_format_for_pdf_dates}
          >
            <div>{`Use MM/DD/YYYY format for dates in the certified payroll report PDF`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="include_demographics_for_federal"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.include_demographics_for_federal}
          >
            <div>{`Include employee demographic data in the federal WH-347 PDF`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="include_employee_address_for_federal"
            form={form}
            editing={true}
            onChange={updateSettingsDebounced}
            defaultValue={cprSettings?.include_employee_address_for_federal}
          >
            <div>{`Include employee address in the federal WH-347 PDF`}</div>
          </Formblock>
          <div style={{ marginBottom: -10 }}>
            <Formblock
              type="checkbox"
              name="include_employee_address_for_pa"
              form={form}
              editing={true}
              onChange={updateSettingsDebounced}
              defaultValue={cprSettings?.include_employee_address_for_pa}
            >
              <div>{`Include employee address in the PA LLC-25 PDF`}</div>
            </Formblock>
          </div>
        </SettingsCard>
      </div>
    </div>
  );
};
