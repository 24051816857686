import React, { useEffect, useMemo, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";

import styles from "./BulkSelector.module.css";
import { FolderSimpleDotted, Lock, MinusCircle, PlusCircle, Stack } from "phosphor-react";
import { matchSorter } from "match-sorter";
import { IconWithTooltip } from "../tooltipItems";
import { Label } from "../form";
import EmptyState from "../empty-state/EmptyState";
import { capitalize, keyBy } from "lodash";
import { Button } from "../button";
import { joinWithAnd, joinWithDelimiter } from "miter-utils";

export type BulkSelectOptionValue = $TSFixMe;

export type BulkSelectorOption = {
  label: string;
  value: BulkSelectOptionValue;
  /** The item cannot be removed from the selection */
  locked?: string;
  /** The item cannot be selected */
  disabled?: boolean;
  /** The items warning if any */
  warning?: string;
  /** The item's message */
  message?: string;
};

export type BulkSelectProps = {
  /** The options to display in the bulk selector */
  options: BulkSelectorOption[];
  /** The current selections */
  selections?: BulkSelectOptionValue[];
  /** The function to call when the selections change */
  onChange?: (value: BulkSelectOptionValue[]) => void;
  /** The properties to display in the bulk selector table */
  properties?: string[];
  /** The name of the resource to use for the table */
  resource: string;
  /** Key for the value */
  valueKey?: string;
};

const BulkSelector = ({
  options,
  selections,
  onChange,
  properties,
  resource,
  valueKey,
}: BulkSelectProps): React.ReactElement => {
  /*********************************************************
   *  State values
   **********************************************************/
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<BulkSelectOptionValue[]>(selections || []);
  useEffect(() => {
    setSelectedOptions(selections || []);
  }, [selections]);

  /*********************************************************
   *  Important variables
   **********************************************************/
  const selectedOptionsMap = useMemo(
    () =>
      valueKey
        ? keyBy(selectedOptions, valueKey)
        : Object.fromEntries(selectedOptions.map((option) => [option, option])),
    [selectedOptions]
  );

  const availableOptions = useMemo(() => {
    const props = properties?.map((p) => `value.${p}`) || ["value.label"];

    const nonSelectedOptions = options.filter(
      (option) => !selectedOptionsMap[valueKey ? option.value[valueKey] : option.value]
    );

    return matchSorter(nonSelectedOptions, searchFilter, {
      keys: ["label", ...props],
      baseSort: (a, b) => {
        return a.item.label.trim().localeCompare(b.item.label.trim());
      },
    });
  }, [options, selectedOptions, searchFilter]);

  /*********************************************************
   *  Handlers
   **********************************************************/
  const updateSelections = (updatedOptions: BulkSelectOptionValue[]) => {
    setSelectedOptions(updatedOptions);
    onChange?.(updatedOptions);
  };

  const handleSelect = (item: BulkSelectorOption) => {
    const updatedSelections = [...selectedOptions, item.value];
    updateSelections(updatedSelections);
  };

  const handleRemove = (item: BulkSelectorOption) => {
    const updatedSelections = selectedOptions.filter((selection) => {
      if (valueKey) {
        return selection[valueKey] !== item.value[valueKey];
      } else {
        return selection !== item.value;
      }
    });
    updateSelections(updatedSelections);
  };

  const handleSelectAll = () => {
    const selectableOptions = availableOptions
      .filter((option) => !option.locked)
      .map((option) => option.value);

    updateSelections([...selectedOptions, ...selectableOptions]);
  };

  const handleRemoveAll = () => {
    updateSelections(options.filter((option) => option.locked).map((option) => option.value));
  };

  /*********************************************************
   * Render functions
   **********************************************************/
  const renderHeader = () => {
    return (
      <div className={styles["bulk-select-selectable-header"]}>
        <div className={styles["bulk-select-selectable-header-title"]}>
          <div className={styles["bulk-select-selectable-header-text"]}>{capitalize(resource)}</div>
          <div className={styles["bulk-select-selectable-header-actions"]}>
            <Button className="button-1" onClick={handleSelectAll}>
              <PlusCircle style={{ marginBottom: -2, marginRight: 7 }} />
              Add all
            </Button>
            <Button className="button-1" onClick={handleRemoveAll}>
              <MinusCircle style={{ marginBottom: -2, marginRight: 7 }} />
              Remove all
            </Button>
          </div>
        </div>
        <div className={styles["bulk-select-selectable-header-search"]}>
          <input
            type="text"
            className="form2-text"
            placeholder={`Search ${resource} by ${joinWithAnd(properties)}`}
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const renderAvailableOptions = () => {
    const optionElements = availableOptions.map((option) => (
      <BulkSelectOption
        key={valueKey ? option.value[valueKey] : option.value}
        item={option}
        onSelect={handleSelect}
        onRemove={handleRemove}
        selected={selectedOptions.includes(option.value)}
        properties={properties}
      />
    ));

    return (
      <div className={styles["bulk-select-selectable-container"]}>
        {renderHeader()}
        <div className={styles["bulk-select-selectable-options"]}>
          {optionElements.length > 0 && optionElements}
          {optionElements.length === 0 && (
            <EmptyState
              icon={<FolderSimpleDotted weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
              title={`No ${resource} to add`}
              type="small"
            />
          )}
        </div>
      </div>
    );
  };

  const renderSelections = () => {
    const selections = options.filter((o) => selectedOptionsMap[valueKey ? o.value[valueKey] : o.value]);
    if (!selections?.length) {
      return (
        <div className={styles["bulk-select-selected"]}>
          <EmptyState
            icon={<Stack weight="duotone" color="#4d55bb" style={{ fontSize: "3rem" }} />}
            title={`No ${resource} selected`}
            type="small"
          />
        </div>
      );
    }

    const selectionElements = selections.map((option) => (
      <BulkSelectOption
        key={valueKey ? option.value[valueKey] : option.value}
        item={option}
        onSelect={handleSelect}
        onRemove={handleRemove}
        selected={true}
        properties={properties}
      />
    ));

    return <div className={styles["bulk-select-selected"]}>{selectionElements}</div>;
  };

  return (
    <div className={styles["bulk-select-container"]}>
      <div className={styles["bulk-select"]}>
        {renderAvailableOptions()}
        {renderSelections()}
      </div>
    </div>
  );
};

export default BulkSelector;

type BulkSelectOptionProps = {
  item: BulkSelectorOption;
  onSelect: (item: BulkSelectorOption) => void;
  onRemove: (item: BulkSelectorOption) => void;
  selected: boolean;
  properties?: string[];
};

const BulkSelectOption = ({ item, onSelect, onRemove, selected, properties }: BulkSelectOptionProps) => {
  const generatePropertyElements = () => {
    return properties
      ?.filter((property) => item.value[property] && item.label !== item.value[property])
      ?.map((property) => (
        <div key={property} className={styles["bulk-select-attribute"]}>
          {item.value[property]}
        </div>
      ));
  };

  const handleOnClick = () => {
    if (!selected && !item.disabled) onSelect(item);
    if (selected && !item.locked) onRemove(item);
  };

  const getClassNames = (baseClass, conditionalClass) => {
    return [styles[baseClass], conditionalClass && styles[conditionalClass]].join(" ");
  };

  const propertyElements = generatePropertyElements();

  return (
    <div
      key={item.value.toString()}
      className={getClassNames("bulk-select-option", selected && "selected")}
      onClick={handleOnClick}
    >
      <div className={styles["bulk-select-icon-container"]}>
        {!selected && !item.disabled && (
          <PlusCircle className={styles["bulk-select-attributes-icon"]} color="#4d55bb" />
        )}
      </div>
      <div className={styles["bulk-select-attributes"]}>
        <div className={styles["bulk-select-selectable-name"]}>
          {item?.disabled ? (
            <Label labelInfo={item?.message} label={item.label} underlineTooltip />
          ) : (
            item.label
          )}
        </div>
        <div className={styles["bulk-select-sub-attributes"]}>
          {joinWithDelimiter(propertyElements, <>&nbsp;&nbsp;•&nbsp;&nbsp;</>)}
        </div>
      </div>
      {item.warning && (
        <div className={styles["bulk-select-selectable-warning"]}>
          <IconWithTooltip Icon={FaExclamationTriangle} tooltip={item.warning} style={{ color: "#EED202" }} />
        </div>
      )}
      {item.locked && (
        <div className={styles["bulk-select-selectable-locked"]}>
          <IconWithTooltip PhosphorIcon={Lock} tooltip={item.locked} style={{ color: "#EED202" }} />
        </div>
      )}
      {selected && !item.locked && (
        <div className={styles["bulk-select-selectable-remove"]}>
          <MinusCircle color="red" />
        </div>
      )}
    </div>
  );
};
