import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { useState, useEffect, useContext } from "react";
import { BasicModal } from "ui";
import ReactCodeInput from "react-code-input";
import styles from "./ReverifyModal.module.css";
import { useNavigate } from "react-router-dom";
import { useUserAtom } from "dashboard/hooks/atom-hooks";

const ReverifyModal: React.FC = () => {
  const { setShowUserSettingsModal, setReverifyUser, onReverifyUser, setOnReverifyUser } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [user, setUser] = useUserAtom();

  const [firstLoad, setFirstLoad] = useState(true);
  const [authMethodId, setAuthMethodId] = useState<string | undefined>();
  const [authCode, setAuthCode] = useState<string | undefined>();
  const [sendingCode, setSendingCode] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);

  const isReverified = (() => {
    const reverifiedAt = DateTime.fromSeconds(user?.reverified_at || 0);
    const now = DateTime.now();

    return now.diff(reverifiedAt).as("hours") < 24;
  })();

  useEffect(() => {
    if (isReverified) {
      setReverifyUser(false);
      onReverifyUser?.();
    } else {
      sendAuthCode();
    }
  }, [user]);

  const hideModal = () => {
    navigate(-1);
    setReverifyUser(false);
  };

  const sendAuthCode = async () => {
    if (!user) return;
    if (!user?.phone) {
      Notifier.warning("You need to verify your phone number before you complete this action.");
      setShowUserSettingsModal(true);
      return;
    }

    setSendingCode(true);

    try {
      const res = await MiterAPI.users.sendAuthCode(user?.phone);
      if (res.error) throw Error(res.error);

      setAuthMethodId(res.method_id);
      Notifier.success("A verification code has been sent to your phone.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error("Unable to send auth code:", e);
    }

    setFirstLoad(false);
    setSendingCode(false);
  };

  const verifyAuthCode = async (e) => {
    e.preventDefault();
    if (!authMethodId || !user || !authCode) return;

    setVerifyingCode(true);
    try {
      const res = await MiterAPI.users.reverify(authCode, authMethodId);
      if (res.error) throw Error(res.error);

      setUser(res);
      onReverifyUser?.();
      setOnReverifyUser(() => {});
      Notifier.success("Your account has been verified.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.log("Unable to verify auth code:", e);
    }
    setVerifyingCode(false);
  };

  if (isReverified) {
    return <></>;
  }

  return (
    <>
      <BasicModal
        headerText={"Secondary verification"}
        subheaderText={"Please enter the 6 digit auth code we sent to your phone number."}
        button1Text={"Resend code"}
        button1Action={sendAuthCode}
        button1Loading={!firstLoad && sendingCode}
        button2Text={"Submit"}
        button2Action={verifyAuthCode}
        button2Loading={verifyingCode}
        wrapperClassName={styles["verify-modal-wrapper"]}
        showCloseX={true}
        onHide={hideModal}
        bloackClickaway={true}
      >
        <div className={styles["verify-form"]}>
          {!verifyingCode && (
            <ReactCodeInput
              className={styles["react-code"]}
              inputMode="numeric"
              name="authCode"
              type="number"
              fields={6}
              onChange={(val) => setAuthCode(val)}
            />
          )}
        </div>
      </BasicModal>
    </>
  );
};

export default ReverifyModal;
