import React, { useState, useMemo, useContext } from "react";
import { Button, TableV2, Toggler } from "ui";
import PayrollContext from "../payrollContext";
import PaymentContext from "./paymentContext";
import Notifier from "dashboard/utils/notifier";
import EarningModal from "./EarningModal";
import { GroupedEarningForCheck, MiterEarning, PayrollAdjustment } from "../../payrollTypes";
import { checkTypeToTimeType, earningTypeLookup } from "../viewPayrollUtils";
import { hoursFormatter } from "miter-utils";
import { ColumnConfig } from "ui/table-v2/Table";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import {
  useActivityLabelFormatter,
  useJobNameFormatter,
  useWorkplaceFormatter,
  useWorkplaces,
  useLookupRateClassification,
  useLookupDepartment,
} from "dashboard/hooks/atom-hooks";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { createObjectMap } from "dashboard/utils";

export type EarningsTableEntry = ((GroupedEarningForCheck & { _id: string }) | MiterEarning) & {
  typeIndex: number;
};

const orderedEarningTypes = Object.keys(earningTypeLookup);

const togglerConfig: TogglerConfigItem[] = [
  { path: "summary", label: "Summary" },
  { path: "detail", label: "Detail" },
];

const rateDollarFormat = (
  params: ValueFormatterParams<EarningsTableEntry, number | undefined>,
  maxDigits: number
): string => {
  if (params.value == null) return "";

  const currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDigits,
  });
  return `${currencyFormat.format(params.value)}/hour`;
};

const amountFormat = (
  params: ValueFormatterParams<EarningsTableEntry, number | undefined>,
  maxDigits: number
): string => {
  if (params.value == null) return "";

  const currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDigits,
  });
  return currencyFormat.format(params.value);
};

const Earnings: React.FC = () => {
  // Hooks
  const { payment, tm, editing, payroll, isLoading } = useContext(PaymentContext);
  const { recalculatePayroll } = React.useContext(PayrollContext);

  const lookupRateClassification = useLookupRateClassification();
  const workplaces = useWorkplaces();
  const workplacesByCheckId = useMemo(() => createObjectMap(workplaces, (w) => w.check_id), [workplaces]);

  const jobNameFormatter = useJobNameFormatter();
  const activityLabelFormatter = useActivityLabelFormatter();
  const workplaceFormatter = useWorkplaceFormatter();
  const lookupDept = useLookupDepartment();

  // State
  const [selectedRows, setSelectedRows] = useState<EarningsTableEntry[]>([]);
  const [unselectedEarnings, setUnselectedEarnings] = useState<GroupedEarningForCheck[]>([]);
  const [removingEarnings, setRemovingEarnings] = useState(false);
  const [isAddingEarning, setIsAddingEarning] = useState(false);
  const [isViewingEarning, setIsViewingEarning] = useState<EarningsTableEntry>();
  const [activeTab, setActiveTab] = useState("summary");

  const earnings = useMemo(() => {
    const relevantEarnings = activeTab === "summary" ? payment.grouped_earnings : payment.miter_earnings;
    const groupedEarnings = (relevantEarnings || []).map((e, index) => ({
      ...e,
      typeIndex: orderedEarningTypes.indexOf(e.check_type),
      _id: e._id || index.toString(),
    })) as EarningsTableEntry[];
    return groupedEarnings.sort((a, b) => a.typeIndex - b.typeIndex);
  }, [payment, activeTab]);

  // Used in two scenarios:
  // (1) Delete earnings when they are selected within the table.
  // (2) Delete an earning when viewed in a modal.
  const removeEarnings = async () => {
    setRemovingEarnings(true);
    try {
      let earningsToKeep = unselectedEarnings;
      if (isViewingEarning) earningsToKeep = getUnselectedEarnings([isViewingEarning]) || [];
      if (!payment.adjustment) throw new Error(`Payment adjustment not found`);

      const manualEarningsToKeep = earningsToKeep.map((ge) => {
        const mae = payment.adjustment?.manual_earnings?.find((e) => e.manual_id === ge?._id);
        if (!mae) throw new Error(`Could not find manual earning ${ge?._id}`);
        return mae;
      });

      // Create a new adjustment for the tm with the new set of added earnings
      const newPayrollAdjustment: PayrollAdjustment = {
        ...payment.adjustment,
        team_member: tm._id,
        manual_earnings: manualEarningsToKeep,
      };
      // Create a new set of adjustments for the payroll with the tm's new adjustment
      const newAdjustments: PayrollAdjustment[] = [];
      for (const adj of payroll?.adjustments || []) {
        if (adj.team_member === payment?.adjustment?.team_member) {
          newAdjustments.push(newPayrollAdjustment);
        } else {
          newAdjustments.push(adj);
        }
      }

      await recalculatePayroll({ adjustments: newAdjustments, tms: [tm._id.toString()] });
      setSelectedRows([]);
      setIsViewingEarning(undefined);
      Notifier.success("Earning(s) removed successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error removing earnings. We're looking into it!");
    }
    setRemovingEarnings(false);
  };

  const handleSelect = (selected: EarningsTableEntry[]) => {
    setSelectedRows(selected);
    // Determine unselected earnings
    const notSelected = getUnselectedEarnings(selected);
    if (notSelected) setUnselectedEarnings(notSelected);
  };

  // Utility function to get all "unselected/non-relevant" earnings. Used both for selection
  // and deletion (when an Earning is deleted from a modal, and we need to determine the
  // earnings not to be deleted)
  const getUnselectedEarnings = (selected: EarningsTableEntry[]) => {
    const selectedIds = selected.map((s) => s._id);
    return earnings?.filter((e) => !selectedIds.includes(e._id) && e.miter_type === "manual");
  };

  const handleRowClick = (r: EarningsTableEntry) => {
    if (r.miter_type === "manual" && payroll?.check_payroll.status === "draft") {
      setIsViewingEarning(r);
    }
  };

  const columns = useMemo(() => {
    const cols: ColumnConfig<EarningsTableEntry>[] = [
      {
        field: "kind",
        headerName: "Kind",
        displayType: "badge",
        colors: { manual: "lightgray", auto: "green" },
        valueGetter: (params: ValueGetterParams<EarningsTableEntry>) =>
          params.data?.miter_type === "manual" ? "manual" : params.data?.miter_type ? "auto" : "",
        maxWidth: 75,
      },
      {
        field: "description",
        headerName: "Description",
        filter: "agTextColumnFilter",
      },
      {
        field: "check_type",
        headerName: "Type",
        enableRowGroup: true,
        valueGetter: (params: ValueGetterParams<EarningsTableEntry>) => {
          if (params.data?._id.includes("totals")) return "-";
          return earningTypeLookup[params.data?.check_type || ""] || "-";
        },
        filter: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        maxWidth: 110,
        sumRow: true,
        dataType: "number",
        unit: "dollar",
        aggFunc: "sum",
        valueGetter: (params: ValueGetterParams<EarningsTableEntry>) => {
          // If we're in the detail tab, we want to show the raw amount, for rounding purposes!
          if (
            activeTab === "detail" &&
            params.data &&
            "raw_amount" in params.data &&
            params.data.raw_amount != null
          ) {
            return params.data?.raw_amount;
          }
          return params.data?.amount;
        },
        valueFormatter: (params) =>
          activeTab === "detail" ? amountFormat(params, 4) : amountFormat(params, 2),
      },
      {
        field: "hours",
        headerName: "Hours",
        sumRow: true,
        aggFunc: "sum",
        maxWidth: 100,
        dataType: "number",
        valueFormatter: hoursFormatter,
      },
      {
        field: "rate",
        headerName: "Rate",
        enableRowGroup: true,
        aggFunc: "max",
        maxWidth: 100,
        dataType: "number",
        valueGetter: (params: ValueGetterParams<EarningsTableEntry>) => {
          // If we're in the detail tab, we want to show the raw rate!
          if (!params.data) return null;
          if ("pay_rate_item" in params.data && params.data.pay_rate_item) {
            const earningType = checkTypeToTimeType(params.data.check_type);
            return params.data.pay_rate_item.rates?.[earningType] || 0;
          }
          const amt = params.getValue("amount");
          if (params.data?.hours) {
            return amt / params.data.hours;
          }
        },
        valueFormatter: (params) =>
          activeTab === "detail" ? rateDollarFormat(params, 3) : rateDollarFormat(params, 2),
      },
      ...(activeTab === "detail"
        ? [
            {
              field: "job",
              headerName: "Job",
              enableRowGroup: true,
              initialHide: true,
              valueGetter: (params: ValueGetterParams<EarningsTableEntry>) => {
                return jobNameFormatter(params.data?.job);
              },
              filter: true,
            },
            {
              field: "activity",
              headerName: "Activity",
              enableRowGroup: true,
              initialHide: true,
              valueGetter: (params: ValueGetterParams<EarningsTableEntry>) => {
                return activityLabelFormatter(params.data?.activity);
              },
              filter: true,
            },
            {
              field: "classification",
              headerName: "Classification",
              headerTooltip: "Pay rate group classification",
              enableRowGroup: true,
              initialHide: true,
              valueGetter: (params: ValueGetterParams<EarningsTableEntry>) => {
                if (!params.data) return null;
                let clsId: string | null | undefined;
                if ("union_rate" in params.data) {
                  clsId = params.data.union_rate;
                } else if ("pay_rate_item" in params.data) {
                  const c = params.data.pay_rate_item?.classification;
                  if (c?.source === "union_rate") clsId = c.source_id;
                }
                return lookupRateClassification(clsId)?.classification;
              },
              filter: true,
            },
            {
              field: "workplace",
              headerName: "Workplace",
              enableRowGroup: true,
              initialHide: true,
              valueGetter: (params: ValueGetterParams<EarningsTableEntry>) => {
                if (!params.data?.check_workplace_id) {
                  return null;
                }
                const workplace = workplacesByCheckId[params.data?.check_workplace_id];
                return workplaceFormatter(workplace);
              },
              filter: true,
            },
          ]
        : []),
    ];
    if (activeTab === "detail") {
      cols.push({
        field: "department_id",
        headerName: "Department",
        enableRowGroup: true,
        initialHide: true,
        valueGetter: (params: ValueGetterParams<MiterEarning>) => {
          return lookupDept(params.data?.department_id)?.name;
        },
        filter: true,
      } as $TSFixMe);
    }
    return cols;
  }, [
    jobNameFormatter,
    activityLabelFormatter,
    workplaceFormatter,
    workplacesByCheckId,
    lookupDept,
    activeTab,
  ]);

  return (
    <div className="width-100" style={{ overflow: "hidden" }}>
      <div className="payment-active-view-header">
        <div className="">Earnings</div>
        <div className="flex-1"></div>
        {selectedRows.length > 0 && editing && (
          <Button
            loading={removingEarnings}
            text="Remove"
            onClick={removeEarnings}
            className="button-3 no-margin"
          />
        )}
        {selectedRows.length === 0 && !isLoading && editing && (
          <button onClick={() => setIsAddingEarning(true)} className="button-2 no-margin">
            + New
          </button>
        )}
      </div>
      <Toggler config={togglerConfig} active={activeTab} toggle={setActiveTab} />

      <div className="width-100" style={{ marginTop: 10 }}>
        <TableV2
          id="earnings-table"
          columns={columns}
          data={earnings}
          resource="earnings"
          onSelect={handleSelect}
          showReportViews={true}
          onClick={editing ? handleRowClick : undefined}
          hideSelectColumn={!editing}
          containerStyle={{ paddingTop: 10, height: 330 }}
          rowClickDisabled={(r) => r.miter_type !== "manual" || !editing}
          rowSelectDisabled={(r) => r.data?.miter_type !== "manual" || !editing}
          showTotals={true}
          customEmptyStateMessage="This payment has no earnings."
        />
        {isAddingEarning && <EarningModal hide={() => setIsAddingEarning(false)} />}
        {isViewingEarning && (
          <EarningModal
            hide={() => setIsViewingEarning(undefined)}
            earningToUpdate={isViewingEarning}
            deleteEarning={removeEarnings}
          />
        )}
      </div>
    </div>
  );
};

export default Earnings;
