import React, { useEffect, useState } from "react";
import { usdString } from "ui";
import { DateTime } from "luxon";
import { GetCompanyBillingInfoResponse } from "dashboard/miter";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

type InvoiceEntry = {
  _id: string;
  date: string;
  dueDate: string | null;
  total: string;
  status: string | null;
  description?: string | null;
  hosted_url?: string | null | undefined;
};

type Props = {
  info: GetCompanyBillingInfoResponse;
  refresh: () => Promise<void>;
};

const Invoices: React.FC<Props> = ({ info }) => {
  const [invoices, setInvoices] = useState<InvoiceEntry[]>();

  const invoicesColumns: ColumnConfig<InvoiceEntry>[] = [
    {
      headerName: "Date",
      field: "date",
      maxWidth: 140,
    },
    {
      headerName: "Total",
      field: "total",
      maxWidth: 140,
    },
    {
      headerName: "Status",
      field: "status",
      dataType: "string",
      displayType: "badge",
      maxWidth: 140,
      colors: {
        paid: "green",
        open: "yellow",
        "past due": "red",
        void: "gray",
      },
    },
    {
      headerName: "Due date",
      field: "dueDate",
    },
    {
      headerName: "Description",
      field: "description",
      width: 275,
    },
  ];

  const cleanInvoices = () => {
    const cleanedInvoices: InvoiceEntry[] = [];
    const nowSeconds = DateTime.now().toSeconds();
    for (const invoice of info.invoices) {
      const pastDue =
        invoice.status === "open" &&
        ((invoice.due_date && invoice.due_date <= nowSeconds) ||
          (invoice.collection_method === "charge_automatically" && invoice.attempted));

      const dueDateSeconds = invoice.due_date == null ? invoice.created : invoice.due_date;

      cleanedInvoices.push({
        _id: invoice.id,
        date: DateTime.fromSeconds(invoice.created).toLocaleString(DateTime.DATE_MED),
        dueDate: DateTime.fromSeconds(dueDateSeconds).toLocaleString(DateTime.DATE_MED),
        total: usdString(invoice.amount_due / 100),
        status: pastDue ? "past due" : invoice.status,
        description: invoice.description || invoice.lines.data[0]?.description,
        hosted_url: invoice.hosted_invoice_url,
      });
    }
    setInvoices(cleanedInvoices);
  };

  useEffect(() => {
    cleanInvoices();
  }, []);

  return (
    <div className="billing-card-wrapper">
      <div style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>Invoices</div>
      {invoices && (
        <TableV2
          data={invoices}
          columns={invoicesColumns}
          disableSort={true}
          id={"invoices-table"}
          resource={"invoices"}
          containerStyle={{ paddingBottom: 0 }}
          onClick={(invoice: InvoiceEntry) =>
            invoice.hosted_url ? window.open(invoice.hosted_url, "_blank") : null
          }
        />
      )}
    </div>
  );
};

export default Invoices;
