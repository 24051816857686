import { DateTime } from "luxon";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { CustomHoliday, HolidaySchedule, UpdateHolidayScheduleParams } from "dashboard/miter";
import * as vals from "dashboard/utils/validators";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { DeepPartial } from "utility-types";

export type HolidayForm = { label: string; date: DateTime; hours?: number };
type Props = {
  selectedCustomHoliday: CustomHoliday | null;
  setSelectedCustomHoliday: React.Dispatch<React.SetStateAction<CustomHoliday | null>>;
  updateHolidaySchedule: (params: DeepPartial<UpdateHolidayScheduleParams>) => Promise<void>;
  originalHolidaySchedule: HolidaySchedule;
  hide: () => void;
};
export const CustomHolidayModal: React.FC<Props> = ({
  selectedCustomHoliday,
  setSelectedCustomHoliday,
  updateHolidaySchedule,
  originalHolidaySchedule,
  hide,
}) => {
  const { can, cannot } = useMiterAbilities();

  const form = useForm<HolidayForm>();
  const { control, handleSubmit, register, errors } = form;
  const [loading, setLoading] = useState(false);

  const modalCleanup = () => {
    setLoading(false);
    hide();
    setSelectedCustomHoliday(null);
  };

  const onAddOrUpdate = async (data: HolidayForm) => {
    if (cannot("time_off:holidays:manage")) return;

    setLoading(true);

    const dateToAdd = data.date.toISODate();

    const holidayCreatedThenArchived = originalHolidaySchedule.custom_holidays.find(
      (ch) => ch.date === dateToAdd && ch.archived
    );

    const chosenHoliday = selectedCustomHoliday || holidayCreatedThenArchived;

    await updateHolidaySchedule({
      customHolidayUpdate: {
        customHoliday: {
          label: data.label,
          date: dateToAdd,
          _id: chosenHoliday?._id,
          archived: false,
          hours: data.hours ? Number(data.hours) : undefined,
        },
        type: chosenHoliday ? "update" : "add",
      },
    });
    modalCleanup();
  };

  const deleteHoliday = async () => {
    if (cannot("time_off:holidays:manage")) return;

    if (!selectedCustomHoliday?._id) throw new Error("Custom holiday not selected");

    setLoading(true);
    await updateHolidaySchedule({
      customHolidayUpdate: {
        customHoliday: { ...selectedCustomHoliday },
        type: "delete",
      },
    });
    modalCleanup();
  };

  const actions: ActionLink[] = [
    {
      label: "Delete holiday",
      action: deleteHoliday,
      shouldShow: () => !!selectedCustomHoliday && can("time_off:holidays:manage"),
    },
  ];

  const validCustomHoliday = (date: DateTime | null): boolean | string => {
    return vals.isValidCustomHoliday(
      date,
      originalHolidaySchedule.custom_holidays,
      selectedCustomHoliday?._id
    );
  };

  return (
    <ActionModal
      headerText={selectedCustomHoliday ? "Edit custom holiday" : "Create custom holiday"}
      onSubmit={handleSubmit(onAddOrUpdate)}
      submitText={selectedCustomHoliday ? "Update" : "Add"}
      onHide={modalCleanup}
      showSubmit={can("time_off:holidays:manage")}
      actions={actions ? (actions as ActionLink[]) : undefined}
      actionsType={"button"}
      loading={loading}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Holiday*"
        type="text"
        register={register(vals.required)}
        name="label"
        className="modal time-off-request-date"
        errors={errors}
        editing={true}
        placeholder="E.g., Annual day off!"
        defaultValue={selectedCustomHoliday?.label}
        disabled={cannot("time_off:holidays:manage")}
      />
      <Formblock
        label="Date*"
        type="datetime"
        dateOnly={true}
        name="date"
        className="double time-off-request-date"
        editing={true}
        control={control}
        defaultValue={selectedCustomHoliday ? DateTime.fromISO(selectedCustomHoliday.date) : null}
        customFormat="MMM dd, yyyy"
        errors={errors}
        rules={{ validate: validCustomHoliday }}
        disabled={!!selectedCustomHoliday || cannot("time_off:holidays:manage")}
      />
      <Formblock
        label="Hours"
        type="number"
        labelInfo="If this is a partial holiday, specify a number of hours. The default for a full day holiday is 8 hours."
        name="hours"
        className="double time-off-request-date"
        editing={true}
        form={form}
        errors={errors}
        register={register(
          vals.numberValidator({
            required: false,
            excludeNegatives: true,
            excludeZero: true,
            maxDecimals: 0,
          })
        )}
        defaultValue={selectedCustomHoliday?.hours}
        disabled={!!selectedCustomHoliday || cannot("time_off:holidays:manage")}
      />
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};
