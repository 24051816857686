import React, { FC, useEffect, useState } from "react";
import Notifier from "../../utils/notifier";
import { AggregatedJob, MiterAPI } from "../../miter";
import { convertHeicToPng } from "../../utils/utils";

import { DateTime } from "luxon";
import PhotoExplorer from "dashboard/components/photo-explorer/PhotoExplorer";
import { Loader } from "ui";
import { useLookupTeam } from "dashboard/hooks/atom-hooks";

type Props = {
  job: AggregatedJob;
};

const JobPhotos: FC<Props> = ({ job }) => {
  const [images, setImages] = useState([]);
  const lookupTeamMember = useLookupTeam();

  const [fetching, setFetching] = useState(false);
  const [fetched, setFetched] = useState(false);

  const getPhotos = async () => {
    setFetching(true);
    try {
      const response = await MiterAPI.jobs.retrieve_images(job._id);

      let cleanedImages: $TSFixMe = [];
      for (const image of response.images) {
        let url = image.url;
        if (image.url.includes(".heic")) {
          url = await convertHeicToPng(image.url);
        }

        cleanedImages.push({
          ...image,
          url,
          team_member: lookupTeamMember(image.team_member_id),
          date: DateTime.fromSeconds(image.clock_in).toLocaleString(DateTime.DATE_FULL),
        });
      }
      cleanedImages = cleanedImages.sort((img) => img.clock_in).reverse();

      setImages(cleanedImages);
      setFetched(true);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving this job's photos. We're looking into it!");
    }
    setFetching(false);
  };

  useEffect(() => {
    getPhotos();
  }, []);

  return (
    <div>
      {fetched && <PhotoExplorer photos={images} searchPlaceholder={"Search by team member or date"} />}
      {fetching && <Loader />}
    </div>
  );
};

export default JobPhotos;
