import React, { FC, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Notifier } from "ui/notifier";
import { Formblock, WizardScreen } from "ui";
import { I9FormProps } from "./I9Wizard";

import styles from "./I9Wizard.module.css";

import { buildPreparerFormDefaults, PreparerFormFields } from "miter-utils/i-9";
import { ESignatureItem, MiterAPI } from "dashboard/miter";
import useWizard from "ui/modal/useWizard";
import { Option } from "ui/form/Input";
import { getOS } from "miter-utils";
import { CheckCircle } from "phosphor-react";
import { DateTime } from "luxon";
import * as vals from "dashboard/utils/validators";

const PreparerForm: FC<I9FormProps> = ({ I9, getI9, name = "Preparer/Translator" }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const { setCanNext } = useWizard();

  const form = useForm<PreparerFormFields>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildPreparerFormDefaults(I9),
    shouldUnregister: false,
  });

  useWatch({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, watch, formState, errors, setValue, reset } = form;
  const { dirtyFields, isValid } = formState;

  const formData = watch();

  /*********************************************************
   * useEffect's
   * - Trigger form validation on change
   **********************************************************/
  useEffect(() => {
    form.trigger();
  }, [JSON.stringify(formData)]);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  useEffect(() => {
    reset({ ...buildPreparerFormDefaults(I9) });
  }, [I9]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveI9)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }
  };

  /*********************************************************
   *  Backend submission + cleaning functions
   **********************************************************/

  const buildI9Params = async (params: PreparerFormFields) => {
    // Save e-signature if they used a preparer
    const preparerESignature = params.used_preparer === "true" ? await savePreparerSignature(params) : null;

    return {
      "section_1.used_preparer": params.used_preparer === "true" ? true : false,
      "section_1.preparer_first_name": params.preparer_first_name || null,
      "section_1.preparer_last_name": params.preparer_last_name || null,
      "section_1.preparer_address": {
        ...params.preparer_address,
        state: (params.preparer_address!.state as unknown as Option<string>)?.value,
      },
      "section_1.preparer_esignature_id": preparerESignature?._id || null,
      "section_1.preparer_esignature_date": preparerESignature?.created_at
        ? DateTime.fromSeconds(preparerESignature.created_at).toISODate()
        : null,

      // We do this to reset the employee signature if any of section 1's fields change
      "section_1.employee_esignature_id": null,
      "section_1.employee_esignature_date": null,
    };
  };

  const savePreparerSignature = async (params: PreparerFormFields): Promise<ESignatureItem> => {
    if (!params.preparer_esignature || !params.preparer_esignature.data) {
      throw new Error("Preparer signature is required");
    }

    const esignParams = {
      company_id: I9.company_id,
      document_type: "i9",
      parent_document_id: I9._id,
      signature: {
        image: params.preparer_esignature.data,
        device: { type: "desktop", os: getOS() },
        application: { name: "team-portal" },
      },
      signer: {
        name: `${params.preparer_first_name} ${params.preparer_last_name}`,
        type: "i9_preparer",
        title: "I-9 Preparer/Translator",
      },
    };

    // @ts-expect-error fix me
    const res = await MiterAPI.esignature_items.signatures.create([esignParams]);
    if (res.error) throw new Error(res.error);
    if (!res || !res[0]) throw new Error("Error saving preparer signature");

    return res[0];
  };

  const saveI9 = async (data: PreparerFormFields) => {
    try {
      const params = await buildI9Params(data);

      // Update I-9
      // @ts-expect-error fix me
      const res = await MiterAPI.i_9s.update(I9._id, params);
      if (res.error) throw new Error(res.error);
      getI9();
    } catch (e: $TSFixMe) {
      console.log("Error submitting I-9 personal information form", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /*********************************************************
   *  Form input handler functions
   **********************************************************/
  const resetPreparerInputs = () => {
    setValue("preparer_first_name", null, { shouldDirty: true });
    setValue("preparer_last_name", null, { shouldDirty: true });
    setValue("preparer_address", null, { shouldDirty: true });
    setValue("preparer_esignature", null, { shouldDirty: true });
  };

  /*********************************************************
   *  Render functions
   **********************************************************/
  const renderESignatureBlock = () => {
    // If we already have a preparer signature,  show a signed block instead
    if (formData.preparer_esignature_id) {
      return (
        <div className={styles["preparer-esignature-completed"]}>
          <CheckCircle style={{ marginRight: 10, marginBottom: -3 }} color={"#26A96C"} weight="fill" />
          E-Signature completed
        </div>
      );
    }

    return (
      <>
        <div className={styles["preparer-disclosure"]}>
          I attest, under penalty of perjury, that I have assisted in the completion of this form and that to
          the best of my knowledge the information is true and correct.
        </div>
        <Formblock
          type="esignature"
          name="preparer_esignature"
          label="Preparer/Translator Signature*"
          form={form}
          editing={true}
          className="modal wizard"
          rules={{ validate: vals.esignatureRequired }}
          onError={Notifier.error}
        />
      </>
    );
  };

  const renderPreparerFields = () => {
    if (formData.used_preparer !== "true") return;

    return (
      <>
        <Formblock
          type="text"
          name="preparer_first_name"
          label="Preparer first name*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          maxLength={50}
          placeholder={"Enter first name"}
        />
        <Formblock
          type="text"
          name="preparer_last_name"
          label="Preparer last name*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          maxLength={50}
          placeholder={"Enter last name"}
        />
        <Formblock
          type="address"
          name="preparer_address"
          label="Preparer address*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          required={true}
          defaultValue={I9?.section_1?.preparer_address}
        />
        {renderESignatureBlock()}
      </>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["preparer-form"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Preparer or translator certification</h2>
        </div>
        <div className="form-section">
          <Formblock
            label="Did a preparer and/or translator assist you in completing the previous sections"
            type="radio"
            name="used_preparer"
            form={form}
            className="modal wizard"
            editing={true}
            options={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
            val={vals.required}
            onChange={resetPreparerInputs}
          />
        </div>
        {renderPreparerFields()}
      </div>
    </WizardScreen>
  );
};

export default PreparerForm;
