import {
  useActiveCompany,
  useActiveRecruitingConversation,
  useLookupJobPostings,
  usePaginatedRecruitingConversations,
  useRecruitingTwilioClient,
  useSetActiveRecruitingConversation,
  useTeam,
} from "dashboard/hooks/atom-hooks";
import { CaretDown, Circle, Download, Trash } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Badge,
  Breadcrumbs,
  Button,
  DeleteModal,
  DropdownButton,
  Loader,
  Notifier,
  Toggler,
  Popup,
} from "ui";
import styles from "./Ats.module.css";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { useQuery } from "miter-utils";
import Notes from "dashboard/components/notes/Notes";
import { JobApplicationStatus } from "backend/models/ats/job-application";
import Select from "react-select";
import { selectStyles } from "ui/form/styles";
import TeamMemberWizard, { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { AggregatedJobApplication } from "dashboard/types/ats";
import { APPLICATION_STATUS_VALUE_LABEL, CANDIDATE_STATUSES } from "dashboard/utils/ats";
import FormSubmissionAnswerTable from "dashboard/components/forms/FormSubmissionAnswersTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { RecruitingConversationContainer } from "dashboard/components/chat/recruiting/RecruitingConversationContainer";
import { buildChatConversation, getTwilioConversations } from "dashboard/utils/chat";
import { useHasAccessToRecruitingChat } from "dashboard/gating";
import { DocumentDisplay } from "miter-components";
import { downloadFiles } from "miter-utils";

export const JobApplication: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string; view: string }>();
  const [searchParams] = useSearchParams();
  const candidatePath = searchParams.get("path") === "candidate";
  const view = useQuery().get("view");
  const company = useActiveCompany();
  const { can, cannot } = useMiterAbilities();
  const twilioClient = useRecruitingTwilioClient();

  const [resume, setResume] = useState<{ url: string; type: string } | null>();
  const [jobApplication, setJobApplication] = useState<AggregatedJobApplication | null>(null);
  const [applicationStatus, setApplicationStatus] = useState<JobApplicationStatus | undefined>(
    jobApplication?.status
  );
  const [isArchiveModalOpen, setArchiveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOnboardModalOpen, setOnboardModal] = useState(false);
  const [associatedTeamMember, setAssociatedTeamMember] = useState<AggregatedTeamMember | null>(null);
  const teamMembers = useTeam();
  const fullName = jobApplication?.candidate?.first_name + " " + jobApplication?.candidate?.last_name;
  const lookupJobPosting = useLookupJobPostings();
  const jobPosting = lookupJobPosting(jobApplication?.job_posting_id);

  const setActiveRecruitingConversation = useSetActiveRecruitingConversation();
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const recruitingConversations = usePaginatedRecruitingConversations();
  const hasChatAccess = useHasAccessToRecruitingChat();

  // Fetches the job application, and redirects if there's no job application id
  useEffect(() => {
    const fetchJobApplication = async () => {
      if (!id) {
        navigate("/recruiting/job-postings", { replace: true });
        return;
      }
      try {
        const res = await MiterAPI.job_applications.forage({
          filter: [{ field: "_id", value: id, type: "_id" }],
        });
        if (res.data.length !== 1) throw new Error(`${res.data.length} jobs were returned for id ${id}`);
        setJobApplication(res.data[0] || null);
      } catch (err: $TSFixMe) {
        Notifier.error(`Failed to fetch job application: ${err.message}`);
      }
    };
    fetchJobApplication();
  }, [id]);

  const createChat = async (): Promise<boolean> => {
    try {
      if (!company || !jobApplication) return false;

      const res = await MiterAPI.chat.recruiting.create(company._id, jobApplication?.candidate._id);
      if (res.error) throw new Error(res.error);

      if (!twilioClient) {
        window.location.reload();
        return false;
      }

      const twilioConversations = await getTwilioConversations(twilioClient);
      const matchingTwilioConversation = twilioConversations.find(
        (conversation) => conversation.sid === res.conversation_sid
      );
      if (!matchingTwilioConversation) {
        throw new Error("Failed to create chat: Twilio conversation not found.");
      }

      const fullConversation = await buildChatConversation(res, matchingTwilioConversation);

      setActiveRecruitingConversation(fullConversation);
      return true;
    } catch (e: $TSFixMe) {
      Notifier.error(`Failed to create chat: ${e.message}`);
      return false;
    }
  };

  // Fetches the resume URL and sets the application status
  useEffect(() => {
    if (!jobApplication) return;
    if (jobApplication?.resume) {
      MiterAPI.files
        .get_urls({
          filter: [{ field: "_id", value: jobApplication?.resume._id, type: "_id" }],
        })
        .then((res) => {
          if (res.urls.length === 0) return;
          const [resUrl, resType] = [res?.urls[0]?.value.url, res?.urls[0]?.file?.type];
          if (resUrl && resType) {
            setResume({ url: resUrl, type: resType });
          }
        });
    }

    setApplicationStatus(jobApplication.status);
  }, [jobApplication]);

  useEffect(() => {
    setActiveRecruitingConversation(
      recruitingConversations.find((c) => c.candidate_id === "66b54a46b148e13c097dfe51") || null
    );
  }, []);

  // Finds the associated team member if the candidate has already been converted into a TM
  useEffect(() => {
    const associatedTM = teamMembers.find((tm) => tm.candidate_id === jobApplication?.candidate?._id);
    setAssociatedTeamMember(associatedTM || null);
  }, [jobApplication?.candidate?._id, teamMembers]);

  /* Constants and Handlers */

  const teamMemberNotHired = applicationStatus === "hired" && !associatedTeamMember;

  const tMParams = useMemo(() => {
    if (!jobApplication) return null;
    return {
      first_name: jobApplication.candidate.first_name,
      last_name: jobApplication.candidate.last_name,
      email: jobApplication.candidate.email,
      phone: jobApplication.candidate.phone,
      employment_type: jobApplication.job_posting.employment_type === "contract" ? "contractor" : "employee",
      title: jobApplication.job_posting.title,
      // We don't know whether the hourly worker position is union or not, so we don't set it
      pay_type: jobApplication.job_posting.pay.type === "salary" ? "salary" : undefined,
      candidate_id: jobApplication.candidate._id,
    } as WizardTeamMember;
  }, [jobApplication]);

  const handleArchive = async (id) => {
    if (!company) {
      Notifier.error("Failed to delete job application: company not found");
      return;
    }

    if (cannot("recruiting:job_applications:delete")) {
      Notifier.error("You do not have permission to delete job applications");
      return;
    }

    try {
      setLoading(true);
      const res = await MiterAPI.job_applications.archive({
        ids: [id],
        company_id: company?._id,
      });
      if (res.error) throw new Error(res.error);
      Notifier.success("Successfully deleted job application");
      navigate("/recruiting/job-postings/" + jobApplication?.job_posting._id, { replace: true });
    } catch (err: $TSFixMe) {
      Notifier.error(`Failed to delete job application: ${err.message}`);
    }
    setLoading(false);
  };

  const handleStatusChange = async (option) => {
    if (!jobApplication) return;

    if (cannot("recruiting:job_applications:update")) {
      Notifier.error("You do not have permission to update job applications");
      return;
    }

    try {
      const newStatus = option.value;
      const res = await MiterAPI.job_applications.update([
        { _id: jobApplication._id, params: { status: newStatus } },
      ]);
      if (res.errors.length) throw new Error(res.errors[0]!.message);
      setApplicationStatus(newStatus);
      Notifier.success("Status successfully updated");
    } catch (err: $TSFixMe) {
      Notifier.error(`Failed to update status: ${err.message}`);
    }
  };

  const openArchiveApplicationModal = () => {
    setArchiveModal(true);
  };
  const closeArchiveApplicationModal = () => {
    setArchiveModal(false);
  };
  const openOnboardModal = () => {
    setOnboardModal(true);
  };
  const closeOnboardModal = () => {
    setOnboardModal(false);
  };

  const downloadResume = () => {
    if (!jobApplication?.resume?._id) return;
    downloadFiles([jobApplication?.resume._id], () => {});
  };

  const actions = useMemo(
    () => [
      {
        label: "Delete application",
        action: openArchiveApplicationModal,
        icon: <Trash style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => can("recruiting:job_applications:delete"),
      },
      {
        label: "Download resume",
        action: downloadResume,
        icon: <Download style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => !!resume,
      },
    ],
    [can, !!resume]
  );

  const togglerConfig = [
    { path: "profile", label: "Profile" },
    { path: "screener-response", label: "Screener response" },
    { path: "notes", label: "Notes" },
  ];

  const toggle = (page) =>
    navigate("/recruiting/job-applications/" + jobApplication?._id.toString() + "?" + `view=${page}`, {
      replace: true,
    });

  /* Render Functions */

  const renderLabel = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={styles["label"]}>Conversation</div>
        {activeRecruitingConversation?.unread && (
          <Circle weight="fill" color="#cc2553" size={10} style={{ marginRight: -5, marginLeft: 5 }} />
        )}
      </div>
    );
  };

  const renderBreadcrumbs = () => {
    if (!jobApplication) return;

    if (candidatePath) {
      return (
        <Breadcrumbs
          crumbs={[
            { label: "Candidates", path: "/recruiting/candidates" },
            {
              label: fullName,
              path: "/recruiting/candidates/" + jobApplication.candidate._id,
            },
            {
              label: jobApplication.job_posting.title,
              path: "/recruiting/job-applications/" + jobApplication._id + "?path=candidate",
            },
          ]}
        />
      );
    }

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Job postings", path: "/recruiting/job-postings" },
          {
            label: jobApplication.job_posting.title,
            path: "/recruiting/job-postings/" + jobApplication.job_posting._id,
          },
          {
            label: fullName,
            path: "/recruiting/job-applications/" + jobApplication._id,
          },
        ]}
      />
    );
  };

  const renderActions = () => {
    return (
      <div className="flex">
        <Select
          options={CANDIDATE_STATUSES.map((status) => ({
            value: status,
            label: APPLICATION_STATUS_VALUE_LABEL[status],
          }))}
          width={"200px"}
          zIndex={1000}
          onChange={handleStatusChange}
          placeholder={"Status"}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          noOptionsMessage={() => "No location found"}
          height="32px"
          isDisabled={!!associatedTeamMember || cannot("recruiting:job_applications:update")}
          value={
            applicationStatus
              ? { value: applicationStatus, label: APPLICATION_STATUS_VALUE_LABEL[applicationStatus] }
              : undefined
          }
          styles={{
            control: (provided, state) => ({
              // @ts-expect-error styles
              ...selectStyles.control!(provided, state),
              fontFamily: "Karla",
            }),
            // Need the below for option dropdown to appear when used in modals
            menuPortal: (base) => ({ ...base, zIndex: 1000 }),
          }}
        />
        {teamMemberNotHired ? (
          <Button onClick={openOnboardModal} className="button-2 no-margin" style={{ marginLeft: 5 }}>
            Onboard
          </Button>
        ) : null}
        <DropdownButton className={"button-1"} options={actions} closeOnClick={true}>
          Actions
          <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
        </DropdownButton>
      </div>
    );
  };

  const renderResponses = () => {
    if (!jobPosting?.question_form || !jobApplication?.response_submission) return null;
    return (
      <FormSubmissionAnswerTable
        form={jobPosting?.question_form}
        formSubmission={jobApplication.response_submission}
      />
    );
  };

  const renderProfile = () => {
    return (
      <div>
        <div className={styles["profile-container"]}>
          <div className={styles["profile-box"]}>
            <div className={styles["profile-box-header"]}>
              <h3>Resume</h3>
            </div>

            {resume ? (
              <DocumentDisplay uri={resume.url} />
            ) : (
              <div className={styles["profile-box-body"]}>
                <div>No resume was submitted.</div>
              </div>
            )}
          </div>
          <div className={styles["profile-box"]}>
            <div className={styles["profile-box-header"]}>
              <h3>Basic information</h3>
            </div>
            <div className={styles["profile-box-body"] + " " + styles["no-flex"]}>
              <div className={styles["profile-box-row"]}>
                <span className={styles["profile-box-label"]}>Email</span>
                <span className={styles["profile-box-value"]}>{jobApplication?.candidate?.email || "-"}</span>
              </div>
              <div className={styles["profile-box-row"]}>
                <span className={styles["profile-box-label"]}>Phone</span>
                <span className={styles["profile-box-value"]}>{jobApplication?.candidate?.phone || "-"}</span>
              </div>
              {/* Job information */}
              <div className={styles["profile-box-row"]}>
                <span className={styles["profile-box-label"]}>Job Title</span>
                <span className={styles["profile-box-value"]}>{jobApplication?.current_job_title}</span>
              </div>
            </div>
          </div>
          {hasChatAccess ? (
            <div className={styles["profile-box"] + " " + styles["chat-container"]}>
              <Popup label={renderLabel()} defaultOpen={false} canOpen={createChat}>
                <RecruitingConversationContainer isInvidualChat={true} />
              </Popup>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderToggler = () => <Toggler config={togglerConfig} active={view || "profile"} toggle={toggle} />;
  const renderView = () => {
    if (!jobApplication) return;
    switch (view) {
      case "notes":
        return (
          <Notes
            parentId={jobApplication._id}
            parentType={"job_application"}
            readonly={cannot("recruiting:job_applications:update")}
          />
        );
      case "screener-response":
        return renderResponses();
      default:
        return renderProfile();
    }
  };

  const renderArchiveModal = () => {
    if (!jobApplication || !isArchiveModalOpen) return;
    return (
      <DeleteModal
        header={"Are you sure?"}
        body={<div>Deleting this job application will permanently delete it.</div>}
        cancelText={"Cancel"}
        onHide={closeArchiveApplicationModal}
        deleteText={"Yes, delete application"}
        onDelete={() => handleArchive(jobApplication._id)}
        loading={loading}
      />
    );
  };

  return jobApplication ? (
    <div className="page-content">
      <Helmet>
        <title>{fullName} | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <div>
          {renderBreadcrumbs()}
          <h1>{fullName}</h1>
          <div className={"member-info"}>
            <span>Candidate</span>
            {applicationStatus === "hired" && (
              <div style={{ marginLeft: 5 }}>
                {associatedTeamMember ? (
                  <Badge className="no-margin" text={"Onboarded"} color={"blue"} />
                ) : (
                  <Badge className="no-margin" text={"Not onboarded"} color={"orange"} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex-1"></div>
        {renderActions()}
      </div>
      {renderToggler()}
      {renderView()}
      {renderArchiveModal()}
      {isOnboardModalOpen && tMParams ? (
        <TeamMemberWizard
          onComplete={closeOnboardModal}
          onExit={closeOnboardModal}
          mode="create"
          teamMember={tMParams}
        />
      ) : null}
    </div>
  ) : (
    <Loader />
  );
};
