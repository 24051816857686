import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { reportList } from "../../reportList";
import { useForm } from "react-hook-form";
import { Button, Formblock } from "ui";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { ColDef, GridApi } from "ag-grid-community";
import { useActiveCompanyId, useLookupTeam, useUser } from "dashboard/hooks/atom-hooks";
import { DateRange } from "ui/form/DateRangePicker";
import AppContext from "dashboard/contexts/app-context";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { usePayrollAbilities } from "dashboard/hooks/abilities-hooks/usePayrollAbilities";
import { toDollarFormat } from "miter-utils";

type DeductionContributionCategory =
  | "Child support"
  | "Tax"
  | "Misc. deduction"
  | "Benefit"
  | "Classification fringe"
  | "Activity-level contribution";

export type DeductionsAndContributionsReportEntry = {
  tm_id: string;
  tm_name: string;
  tm_friendly_id: string;
  tmSsn?: string;
  payroll_id: string;
  payroll_description: string;
  payday: string;
  notes: string;
  type: "Deduction" | "Contribution";
  category: DeductionContributionCategory;
  id: string;
  label: string;
  remitted_by_miter: "True" | "False";
  amount: number;
  classificationLabel?: string;
  payRateGroupLabel?: string;
  fringeCategory?: string;
  benefitType?: string;
  tmDepartmentName?: string;
};

const reportObject = reportList.find((report) => report.slug === "deductions_contributions");

const CreateDeductionsReport: React.FC = () => {
  // Hooks
  const { setReverifyUser } = React.useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const { errors, control } = useForm();
  const { can } = useMiterAbilities();
  const navigate = useNavigate();
  const user = useUser();
  const { payroll } = useContext(PayrollContext);
  const isMounted = useRef(false);

  const lookupTeam = useLookupTeam();
  const payrollAbilities = usePayrollAbilities();

  // State
  const [data, setData] = useState<DeductionsAndContributionsReportEntry[]>([]);
  const [gridApi, setGridApi] = useState<GridApi<$TSFixMe>>();
  const [dateRange, setDateRange] = useState<DateRange>({ start: null, end: null });
  const [includeRawSsns, setIncludeRawSsns] = useState(false);

  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const isReverified = (user?.reverified_at || 0) > DateTime.now().minus({ days: 1 }).toSeconds();

  useEffect(() => {
    if (showReverificationModal) {
      setReverifyUser(showReverificationModal);
    }
  }, [showReverificationModal]);

  const getData = async () => {
    if (!activeCompanyId || !gridApi) return;
    gridApi.showLoadingOverlay();
    try {
      const payload = {
        type: "deductions",
        params: {
          start_date: payroll?.check_payroll.payday || dateRange.start?.toISODate(),
          end_date: payroll?.check_payroll.payday || dateRange.end?.toISODate(),
          company_id: activeCompanyId,
          payrollId: payroll?._id,
          draftPayroll: payroll?.check_payroll.status === "draft" ? payroll : undefined,
          includeRawSsns: isReverified ? includeRawSsns : false,
        },
        format: "json",
        company: activeCompanyId,
      };

      const entries = await MiterAPI.reports.create(payload);
      if (entries.error) throw new Error(entries.error);

      const filteredData = entries.filter((entry: DeductionsAndContributionsReportEntry) => {
        const teamMember = lookupTeam(entry.tm_id);
        return !teamMember || payrollAbilities.teamPredicate("read")(teamMember);
      });

      setData(filteredData);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
    gridApi.hideOverlay();
  };

  const handleDateRangeChange = (range: DateRange) => {
    if (range.start && range.end) {
      if (range.start.toISODate() > range.end.toISODate()) {
        Notifier.error("End date cannot be before start date.");
      } else {
        setDateRange(range);
      }
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (includeRawSsns && !isReverified) {
      setShowReverificationModal(true);
      return;
    }
    getData();
  }, [includeRawSsns, isReverified]);

  const deductionsAndContributionsColDefs = useMemo(() => {
    const cols: ColDef<DeductionsAndContributionsReportEntry>[] = [
      {
        field: "tm_name",
        headerName: "Team member",
        enableRowGroup: true,
        filter: true,
      },
      {
        field: "tm_friendly_id",
        headerName: "Team member ID",
        enableRowGroup: true,
        hide: true,
        filter: true,
      },
      {
        field: "tmSsn",
        headerName: "Team member SSN",
        enableRowGroup: true,
        hide: true,
        filter: true,
      },
      {
        field: "type",
        headerName: "Type",
        enableRowGroup: true,
        filter: true,
      },
      {
        field: "payroll_description",
        headerName: "Payroll description",
        enableRowGroup: true,
        hide: true,
        filter: true,
      },
      {
        field: "payday",
        headerName: "Payday",
        enableRowGroup: true,
        filter: true,
      },
      {
        field: "category",
        headerName: "Category",
        enableRowGroup: true,
        filter: true,
      },
      {
        field: "label",
        headerName: "Label",
        enableRowGroup: true,
        filter: true,
      },
      {
        field: "remitted_by_miter",
        headerName: "Remitted by Miter",
        enableRowGroup: true,
        filter: true,
      },
      {
        field: "amount",
        headerName: "Amount $",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        field: "notes",
        headerName: "Notes",
        filter: true,
      },
      {
        field: "classificationLabel",
        headerName: "Classification",
        enableRowGroup: true,
        filter: true,
        initialHide: true,
      },
      {
        field: "payRateGroupLabel",
        headerName: "Pay rate group",
        enableRowGroup: true,
        filter: true,
        initialHide: true,
      },
      {
        field: "fringeCategory",
        headerName: "Fringe category",
        enableRowGroup: true,
        filter: true,
        initialHide: true,
      },
      {
        field: "benefitType",
        headerName: "Benefit type",
        enableRowGroup: true,
        filter: true,
        initialHide: true,
      },
      {
        field: "tmDepartmentName",
        headerName: "Team member department",
        enableRowGroup: true,
        filter: true,
        initialHide: true,
      },
    ];
    if (user?.miter_admin) {
      cols.push({
        field: "payroll_id",
        headerName: "Payroll ID",
        enableRowGroup: true,
        filter: true,
        hide: true,
      });
    }
    return cols;
  }, [user]);

  let fileName = "Miter Deductions & Contributions Report ";
  if (payroll) {
    fileName += payroll.check_payroll.payday;
  } else {
    fileName += dateRange.start?.toISODate() + " - " + dateRange.end?.toISODate();
  }

  const HeaderTag = payroll ? "h2" : "h1";

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      {!payroll && (
        <Helmet>
          <title>Deductions + Contributions Report | Miter</title>
        </Helmet>
      )}
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <HeaderTag style={{ marginTop: 0, marginBottom: payroll ? 5 : undefined }}>
          {reportObject!.label}
        </HeaderTag>
      </div>
      <div className="report-page-description">
        {reportObject!.description}
        {!payroll && "*"}
      </div>
      <div className="flex">
        {!payroll && (
          <>
            <div className="vertical-spacer-small"></div>
            <div style={{ maxWidth: 400 }}>
              <Formblock
                type="daterange"
                label="Payday range"
                editing={true}
                name="range"
                control={control}
                value={dateRange}
                onChange={handleDateRangeChange}
              />
            </div>
          </>
        )}
        <Button
          className="button-2 tall-button"
          onClick={getData}
          style={{ marginLeft: 10, marginBottom: 8 }}
        >
          Run report
        </Button>
      </div>
      {can("team:read_sensitive") && (
        <div style={{ maxWidth: 400 }}>
          <Formblock
            label="Show full SSNs"
            control={control}
            type="checkbox"
            onChange={(e) => setIncludeRawSsns(e.target.checked)}
            name="show_full_ssns"
            errors={errors}
            editing={true}
            style={{ alignItems: "center" }}
            labelStyle={{ flexDirection: "unset" }}
          />
        </div>
      )}
      <AgGridTable
        reportId="deductions-and-contributions"
        data={data}
        columnDefs={deductionsAndContributionsColDefs}
        fileName={fileName}
        setGridApi={setGridApi}
      />
      {!payroll && (
        <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
          * Includes all pending, processing, and paid payrolls.
        </div>
      )}
      <div className="vertical-spacer"></div>
    </div>
  );
};

export default CreateDeductionsReport;
