import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  CellValueChangedEvent,
  ColDef,
  ValueParserParams,
  ICellEditorParams,
  GridApi,
  ValueFormatterParams,
  CellClassParams,
  ITooltipParams,
  ValueGetterParams,
  GetQuickFilterTextParams,
  IRowNode,
  SelectionChangedEvent,
} from "ag-grid-community";
import { Button, ConfirmModal, LargeModal, usdString } from "ui";
import PayrollContext from "./payrollContext";
import { ManualEarning, PayRateItem } from "backend/utils/payroll/types";
import { earningsThatRequireHours, imputedEarningTypes, Notifier, roundTo } from "dashboard/utils";
import { DateTime } from "luxon";
import ObjectID from "bson-objectid";
import { AggregatedJob, AggregatedPayroll, AggregatedTeamMember, Job, MiterAPI } from "dashboard/miter";
import { CheckEarningType, CheckPayroll } from "backend/utils/check/check-types";
import { getRowData } from "dashboard/components/agGridTable/agGridUtils";
import { AgGridTooltip } from "dashboard/components/agGridTable/AgGridTooltip";
import { PayrollAdjustment } from "../payrollTypes";
import { earningTypeLookup, useGetEarningTypeOptions } from "./viewPayrollUtils";
import { baseSensitiveCompare } from "miter-utils";
import {
  useLookupActivity,
  useLookupJob,
  useLookupTeam,
  useTeamOptions,
  useJobOptions,
  useActivityOptionsMap,
  useTeam,
  useJobNameFormatter,
  useActivityLabelFormatter,
  useLedgerAccountOptions,
  useLedgerAccountLabeler,
  useGetClassificationOptions,
  useLookupRateClassification,
  useLookupPrg,
  useActiveCompanyId,
} from "dashboard/hooks/atom-hooks";

import { selectEditorSuppressKeyboardEvent } from "ui/table-v2/AgGridSelectEditor";
import { EarningsImporter } from "dashboard/pages/payrolls/viewPayroll/EarningsImporter";
import { ImportResultModal } from "dashboard/components/importer/ImportHistory";
import { INVALID_ENTRY_COLOR } from "ui/table-v2/Table";
import { usePayScheduleAccessor } from "dashboard/hooks/usePayScheduleAccessor";

const WARNING_COLOR = "#ffe198";
const CONTROLLED_UPDATE = "controlledUpdate";

type EarningDataRow = Partial<Omit<ManualEarning, "manual_id">> & {
  manual_id: string;
  customRateEnabled?: boolean;
  finalPayRate?: number;
  tmId?: string;
  timeOffPolicyId?: string;
  ignoreBenefitContributions?: boolean;
};

type Props = {
  readOnly: boolean;
  onFinish: () => void;
};

export const BulkEarnings: React.FC<Props> = ({ readOnly, onFinish }) => {
  // TODO: Retrieve jobs and activities from the payroll input cache because archived objects aren't in the app context

  // HOOKS
  const { payroll, recalculatePayroll, getPayroll } = useContext(PayrollContext);
  const activeCompanyId = useActiveCompanyId();
  const { canAccessTeamMember } = usePayScheduleAccessor();

  const team = useTeam();
  const tmOptPred = useMemo(() => {
    const tmIdSet = (payroll?.miter_payments || []).reduce(
      (acc, mp) => (mp.onboarded ? acc.add(mp.team_member._id) : acc),
      new Set<string>()
    );
    return (tm: AggregatedTeamMember) => tmIdSet.has(tm._id) && canAccessTeamMember(tm);
  }, [payroll, canAccessTeamMember]);

  const ledgerAccountLabeler = useLedgerAccountLabeler();
  const ledgerAccountOptions = useLedgerAccountOptions();

  const tmOptions = useTeamOptions({ predicate: tmOptPred, overrideBasePredicate: true });
  const lookupTeam = useLookupTeam();
  const lookupJob = useLookupJob();
  const lookupActivity = useLookupActivity();
  const lookupClassification = useLookupRateClassification();
  const lookupPrg = useLookupPrg();
  const activityOptionsMap = useActivityOptionsMap();
  const getClassificationOptions = useGetClassificationOptions();

  const jobPred = useMemo(() => {
    const manualJobIdsOnPayroll = new Set<string>();
    for (const adjustment of payroll?.adjustments || []) {
      for (const me of adjustment.manual_earnings || []) {
        if (me.job) manualJobIdsOnPayroll.add(me.job);
      }
    }

    return (j: Job | AggregatedJob) => j.status === "active" || manualJobIdsOnPayroll.has(j._id);
  }, [payroll]);

  const jobOptions = useJobOptions({ predicate: jobPred, overrideBasePredicate: true });

  const policyLookup = useMemo(() => {
    const lookup = Object.fromEntries(team.flatMap((tm) => tm.time_off_policies.map((p) => [p._id, p])));
    return (id: string | undefined) => (id ? lookup[id] : undefined);
  }, [team]);

  // STATES
  const [gridApi, setGridApi] = useState<GridApi<EarningDataRow>>();
  const [initialTableData, setInitialTableData] = useState<EarningDataRow[]>();
  const [anyRowsSelected, setAnyRowsSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [exitConfirmation, setExitConfirmation] = useState(false);
  const [gettingPayRate, setGettingPayRate] = useState(false);
  const [importResults, setImportResults] = useState(null);

  // HELPERS
  const checkPayroll: CheckPayroll | undefined = payroll?.check_payroll;
  const benefitsNotApplied =
    checkPayroll?.type === "off_cycle"
      ? !checkPayroll.off_cycle_options?.apply_benefits
      : checkPayroll?.type !== "regular";

  const getEarningTypeOptions = useGetEarningTypeOptions();

  // EFFECTS
  useEffect(() => {
    if (!payroll) return;
    const rowData = generateRowsFromPayroll(payroll)
      .map((r) => {
        const tm = lookupTeam(r.tmId);
        return { ...r, tmName: tm?.full_name };
      })
      .sort((a, b) => {
        if (a.tmName && b.tmName) {
          return baseSensitiveCompare(a.tmName, b.tmName);
        } else if (!a.tmName && b.tmName) return 1;
        else if (a.tmName && !b.tmName) return -1;
        return 0;
      });

    setInitialTableData(rowData);
  }, [payroll]);

  // STYLERS
  const hoursCellStyle = (
    params: CellClassParams<EarningDataRow, number | undefined>
  ): Record<string, string> | undefined => {
    // If there's no earning type, then no styling necessary because we don't know if this cell is necessary. Also no styling for read-only
    if (!params.data?.type || readOnly) return;

    if (earningsThatRequireHours.includes(params.data.type)) {
      // If the earning type requires hours, and there's no value, show a warning background color
      if (!params.value) return { backgroundColor: INVALID_ENTRY_COLOR };
      if (params.value < 0) return { backgroundColor: WARNING_COLOR };
    } else {
      // If the earning type doesn't require hours, show gray text (indicating cell isn't editable / doesn't apply)
      return { color: "darkgray" };
    }
  };

  const amountCellStyle = (
    params: CellClassParams<EarningDataRow, number | undefined>
  ): Record<string, string> | undefined => {
    // If there's no earning type, then no styling necessary because we don't know if this cell will be directly editable. Also no styling for read-only
    if (!params.data?.type || readOnly) return;

    if (earningsThatRequireHours.includes(params.data.type)) {
      // If earning requires hours, show gray text (indicating cell isn't editable)
      return { color: "darkgray" };
    } else if (!params.value) {
      // If the earning type doesn't requires hours (meaning the user has to directly input the amount) and there's no value, show a warning background color.
      // If it does require hours, then the warning will appear for hours or pay rate (which is what's controllable)
      return { backgroundColor: INVALID_ENTRY_COLOR };
    } else if (params.value < 0) {
      return { backgroundColor: WARNING_COLOR };
    }
  };

  const finalPayRateCellStyle = (
    params: CellClassParams<EarningDataRow, number | undefined>
  ): Record<string, string> | undefined => {
    // If there's no earning type, then no styling necessary because we don't know if this cell is necessary. Also no styling for read-only
    if (!params.data?.type || readOnly) return;

    const hourlyType = earningsThatRequireHours.includes(params.data.type);
    const custom = !!params.data?.customRateEnabled;
    const invalidValue = params.value == null || params.value < 0;

    // If the earning type requires hours, custom rate is enabled (indicating the cell needs user input), and there's no/negative value, show a warning background color
    if (hourlyType && custom && invalidValue) {
      return { backgroundColor: INVALID_ENTRY_COLOR };
    }

    // If the earning type doesn't require hours or it does and custom rate is disabled, show gray text (indicating cell isn't editable / doesn't apply)
    if (!hourlyType || (hourlyType && !custom)) {
      return { color: "darkgray" };
    }
  };

  // TOOLTIP VALUE GETTERS
  const hoursTooltipValue = (params: ITooltipParams<EarningDataRow, number | undefined>) => {
    if (params.data?.type && earningsThatRequireHours.includes(params.data.type)) {
      if (!params.value) {
        // If the earning type requires hours and there's no value, prompt the user with the following text
        return "Please provide a value";
      }
      if (params.value < 0) {
        return "Are you sure you want this to be negative?";
      }
    }
    // Don't return anything if all is well, so that tooltip only shows up when there's a problem
  };

  const amountTooltipValue = (params: ITooltipParams<EarningDataRow, number | undefined>) => {
    if (params.data?.type && !earningsThatRequireHours.includes(params.data.type)) {
      if (!params.value) {
        // If the earning type doesn't require hours (meaning the user is directly entering the amount) and there's no value, prompt the user with the following text
        return "Please provide a value";
      }
      if (params.value < 0) {
        return "Are you sure you want this to be negative?";
      }
    }
    // Don't return anything if all is well, so that tooltip only shows up when there's a problem
  };

  const finalPayRateTooltipValue = (params: ITooltipParams<EarningDataRow, number | undefined>) => {
    if (
      (params.value == null || params.value < 0) &&
      params.data?.customRateEnabled &&
      params.data?.type &&
      earningsThatRequireHours.includes(params.data.type)
    ) {
      // If the earning type requires hours and custom rate is enabled (meaning the user directly controls the pay rate) and there's no value or it's negative, prompt the user with the following text
      return "Please provide a non-negative value";
    }
    // Don't return anything if all is well, so that tooltip only shows up when there's a problem
  };

  // FORMATTERS
  const jobNameFormatter = useJobNameFormatter();
  const jobFormatter = useCallback(
    (params: ValueFormatterParams<EarningDataRow, string | undefined>): string => {
      const job = lookupJob(params.value);
      if (!job) return "";
      return jobNameFormatter(job);
    },
    [jobNameFormatter, lookupJob]
  );

  const activityLabelFormatter = useActivityLabelFormatter();
  const activityFormatter = useCallback(
    (params: ValueFormatterParams<EarningDataRow, string | undefined>): string => {
      const a = lookupActivity(params.value);
      if (!a) return "";
      return activityLabelFormatter(a);
    },
    [activityLabelFormatter, lookupActivity]
  );

  const classificationFormatter = useCallback(
    (params: ValueFormatterParams<EarningDataRow, string | undefined>): string => {
      const c = lookupClassification(params.value);
      if (!c) return "";
      const prg = lookupPrg(c.pay_rate_group);
      return `${prg!.label}: ${c.classification}`;
    },
    [lookupClassification, lookupPrg]
  );

  // EDITORS PARAMS
  const activityEditorParams = useCallback(
    (params: ICellEditorParams<EarningDataRow>) => {
      return { options: activityOptionsMap.get(params.data.job), isClearable: true };
    },
    [activityOptionsMap]
  );

  const classificationEditorParams = useCallback(
    (params: ICellEditorParams<EarningDataRow>) => {
      const options = getClassificationOptions({
        opts: { defaultValue: params.data.classification_override || undefined },
      });
      return { options, isClearable: true };
    },
    [getClassificationOptions]
  );

  const timeOffPolicyEditorParams = (params: ICellEditorParams<EarningDataRow>) => {
    const teamMember = lookupTeam(params.data?.tmId);
    if (!teamMember) return { options: [] };

    const filteredTimeOffPolicyOptions = teamMember.time_off_policies
      .filter((policy) => {
        return (
          (params.data?.type === "pto" && policy.type === "vacation") ||
          (params.data?.type === "sick" && policy.type === "sick")
        );
      })
      .map((policy) => ({ value: policy._id, label: policy.name }));

    return { options: filteredTimeOffPolicyOptions };
  };

  // COLUMN DEFINITIONS
  const defaultColDef: ColDef<EarningDataRow> = useMemo(() => {
    return {
      editable: !readOnly,
      resizable: true,
      minWidth: 100,
      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],
    };
  }, [readOnly]);

  const ignoreBenefitContributionsColDef: ColDef<EarningDataRow> = {
    field: "ignoreBenefitContributions",
    headerName: "Ignore benefits",
    headerTooltip: "This earning should not accrue benefit contributions or use benefits to offset fringe",
    editable: false,
    cellRenderer: "checkboxRenderer",
    minWidth: 175,
    filter: "agSetColumnFilter",
    tooltipValueGetter: (params: ITooltipParams<EarningDataRow>) =>
      params.data?.type && imputedEarningTypes.includes(params.data.type)
        ? "Benefits are always ignored for this earning type"
        : null,
    cellRendererParams: {
      disabled: (data: EarningDataRow | undefined) =>
        !!readOnly || !data?.type || imputedEarningTypes.includes(data.type),
    },
  };

  const bulkEarningsColumns = useMemo(() => {
    const cols: ColDef<EarningDataRow>[] = [
      {
        field: "tmId",
        checkboxSelection: !readOnly,
        headerName: "Team member",
        valueFormatter: (params) => lookupTeam(params.value)?.full_name || "",
        minWidth: 300,
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: { options: tmOptions },
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        headerCheckboxSelection: !readOnly,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: (params) => (params.value ? null : { backgroundColor: INVALID_ENTRY_COLOR }),
        tooltipComponent: AgGridTooltip,
        tooltipValueGetter: (params: ITooltipParams<EarningDataRow, string | undefined>) =>
          params.value ? null : "Please specify a team member",
        filter: "agTextColumnFilter",
        filterParams: defaultTextFilterParams,
        getQuickFilterText: (params) => lookupTeam(params.value)?.full_name || "",
        pinned: "left",
      },
      {
        field: "type",
        headerName: "Type",
        valueFormatter: (params) => earningTypeLookup[params.value],
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: (params: ICellEditorParams<EarningDataRow>) => {
          const tm = lookupTeam(params.data?.tmId);
          return { options: getEarningTypeOptions(tm) };
        },
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        cellStyle: (params) => (params.value ? null : { backgroundColor: INVALID_ENTRY_COLOR }),
        tooltipComponent: AgGridTooltip,
        tooltipValueGetter: (params: ITooltipParams<EarningDataRow, string | undefined>) =>
          params.value ? null : "Please specify an earning type",
        filter: "agTextColumnFilter",
        filterParams: earningTypeFilterParams,
        getQuickFilterText: (params) => earningTypeLookup[params.value] || "",
        pinned: "left",
      },
      {
        field: "hours",
        headerName: "Hours",
        valueFormatter: hourFormat,
        valueParser: numberParser,
        editable: (params) =>
          !readOnly && !!params.data?.type && earningsThatRequireHours.includes(params.data.type),
        type: "numericColumn",
        width: 100,
        cellStyle: hoursCellStyle,
        tooltipComponent: AgGridTooltip,
        tooltipValueGetter: hoursTooltipValue,
        filter: "agNumberColumnFilter",
        filterParams: defaultNumberFilterParams,
      },
      {
        field: "amount",
        headerName: "Amount",
        valueFormatter: dollarFormat,
        valueParser: numberParser,
        width: 150,
        type: "numericColumn",
        cellStyle: amountCellStyle,
        editable: (params) =>
          !readOnly && !!params.data?.type && !earningsThatRequireHours.includes(params.data.type),
        tooltipComponent: AgGridTooltip,
        tooltipValueGetter: amountTooltipValue,
        filter: "agNumberColumnFilter",
        filterParams: defaultNumberFilterParams,
      },
      {
        field: "finalPayRate",
        headerName: "Rate",
        valueFormatter: payRateFormat,
        width: 150,
        editable: (params) => !readOnly && !!params.data?.customRateEnabled,
        cellStyle: finalPayRateCellStyle,
        tooltipComponent: AgGridTooltip,
        tooltipValueGetter: finalPayRateTooltipValue,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: defaultNumberFilterParams,
      },
      {
        field: "customRateEnabled",
        headerName: "Custom rate",
        headerTooltip: "Custom rate enabled",
        editable: false,
        cellRenderer: "checkboxRenderer",
        cellRendererParams: {
          disabled: (data: EarningDataRow | undefined) =>
            !!readOnly || !data?.type || !earningsThatRequireHours.includes(data.type),
        },
        width: 125,
        filter: "agSetColumnFilter",
      },
      {
        field: "description",
        headerName: "Description",
        filter: "agTextColumnFilter",
        filterParams: defaultTextFilterParams,
        minWidth: 150,
      },
      {
        field: "date",
        headerName: "Date",
        valueFormatter: dateFormatter,
        cellEditor: "datePickerEditor",
        cellEditorPopup: true,
        cellEditorParams: {
          min: checkPayroll?.period_start,
          max: checkPayroll?.period_end,
        },
        getQuickFilterText: dateFormatter,
        minWidth: 150,
      },
      {
        field: "job",
        headerName: "Job",
        valueFormatter: jobFormatter,
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: { options: jobOptions, isClearable: true },
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        filter: "agTextColumnFilter",
        filterParams: defaultTextFilterParams,
        getQuickFilterText: jobFormatter,
        minWidth: 200,
      },
      {
        field: "activity",
        headerName: "Activity",
        valueFormatter: activityFormatter,
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: activityEditorParams,
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        filter: "agTextColumnFilter",
        filterParams: defaultTextFilterParams,
        getQuickFilterText: activityFormatter,
        minWidth: 200,
      },
      {
        field: "classification_override",
        headerName: "Classification override",
        valueFormatter: classificationFormatter,
        editable: (params) =>
          !readOnly && !!params.data?.type && earningsThatRequireHours.includes(params.data.type),
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: classificationEditorParams,
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        filter: "agTextColumnFilter",
        filterParams: defaultTextFilterParams,
        getQuickFilterText: classificationFormatter,
        minWidth: 200,
      },
      {
        field: "timeOffPolicyId",
        headerName: "Time off policy",
        valueFormatter: (params) => policyLookup(params.value)?.name || "",
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: timeOffPolicyEditorParams,
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        editable: (params) => !readOnly && !!params.data?.type && isTimeOffEarning(params.data.type),
        tooltipValueGetter: (params: ITooltipParams<EarningDataRow, string | undefined>) =>
          isTimeOffEarning(params.data?.type) && !params.data?.timeOffPolicyId?.length
            ? "Please select a time off policy"
            : null,
        cellStyle: (params) =>
          isTimeOffEarning(params.data?.type) && !params.data?.timeOffPolicyId?.length
            ? { backgroundColor: INVALID_ENTRY_COLOR }
            : null,
        filter: "agTextColumnFilter",
        filterParams: defaultTextFilterParams,
        getQuickFilterText: (params) => policyLookup(params.value)?.name || "",
        minWidth: 150,
      },
      {
        field: "ledger_account_id",
        headerName: "GL account override",
        headerTooltip: "This account will override the default account for this earning type",
        valueFormatter: (params) => ledgerAccountLabeler(params.value),
        cellEditor: "reactSelectEditor",
        cellEditorPopup: true,
        cellEditorParams: { options: ledgerAccountOptions, isClearable: true },
        suppressKeyboardEvent: selectEditorSuppressKeyboardEvent,
        getQuickFilterText: (params) => ledgerAccountLabeler(params.value),
        minWidth: 150,
      },
      ...(benefitsNotApplied ? [] : [ignoreBenefitContributionsColDef]),
    ];
    return cols;
  }, [
    payroll,
    tmOptions,
    jobOptions,
    readOnly,
    jobFormatter,
    activityFormatter,
    activityEditorParams,
    classificationFormatter,
    classificationEditorParams,
    lookupTeam,
    getEarningTypeOptions,
  ]);

  // CELL VALUE CHANGED FUNCTIONS
  const onCellValueChanged = async (event: CellValueChangedEvent<EarningDataRow>): Promise<void> => {
    setUnsavedChanges(true);
    if (event.colDef.field === "tmId") {
      onTmChanged(event);
    } else if (event.colDef.field === "type") {
      onTypeChanged(event);
    } else if (event.colDef.field === "hours") {
      onHoursChanged(event);
    } else if (event.colDef.field === "amount") {
      onAmountChanged(event);
    } else if (event.colDef.field === "finalPayRate") {
      onFinalPayRateChanged(event);
    } else if (event.colDef.field === "customRateEnabled") {
      onCustomRateEnabledChanged(event);
    } else if (event.colDef.field === "job") {
      onJobChanged(event);
    } else if (event.colDef.field === "activity") {
      onActivityChanged(event);
    } else if (event.colDef.field === "classification_override") {
      onClassificationChanged(event);
    } else if (event.colDef.field === "ignoreBenefitContributions") {
      onIgnoreBenefitsChanged(event);
    }
    event.api.redrawRows({ rowNodes: [event.node] });
  };

  const onTmChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["tmId"]>
  ): Promise<void> => {
    // Reset time off policy
    event.node.setDataValue("timeOffPolicyId", undefined, CONTROLLED_UPDATE);

    // Refresh the pay rate when the TM changes
    await refreshPayRateInData(event.node);
  };

  const onTypeChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["type"]>
  ): Promise<void> => {
    // If we switch the type such that hours are now required/not required, change the hours and custom field
    const oldTypeHourly = !!event.oldValue && earningsThatRequireHours.includes(event.oldValue);
    const newTypeHourly = !!event.newValue && earningsThatRequireHours.includes(event.newValue);
    if (oldTypeHourly && !newTypeHourly) {
      event.node.setDataValue("hours", undefined, CONTROLLED_UPDATE);
      event.node.setDataValue("customRateEnabled", false, CONTROLLED_UPDATE);
      event.node.setDataValue("classification_override", undefined, CONTROLLED_UPDATE);
    } else if (!oldTypeHourly && newTypeHourly) {
      event.node.setDataValue("hours", 0, CONTROLLED_UPDATE);
    }
    // Reset time off policy
    event.node.setDataValue("timeOffPolicyId", undefined, CONTROLLED_UPDATE);

    // Toggle ignore benefits checkbox off if type changed to an imputed earning
    if (
      bulkEarningsColumns.includes(ignoreBenefitContributionsColDef) &&
      (!event.newValue || imputedEarningTypes.includes(event.newValue))
    ) {
      event.node.setDataValue("ignoreBenefitContributions", false, CONTROLLED_UPDATE);
    }

    // Refresh the pay rate. However, if we're staying within hourly or within non-hourly during this change,
    // we don't need to hit the backend for a new pay rate item
    await refreshPayRateInData(event.node, oldTypeHourly !== newTypeHourly);
  };

  const onHoursChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["hours"]>
  ): Promise<void> => {
    // Automatically set the amount based on the hours and pay rate, and explicitly set the source so we can identify the change event on the amount field
    if (
      event.node.data?.type &&
      event.node.data.finalPayRate != null &&
      earningsThatRequireHours.includes(event.node.data.type)
    ) {
      event.node.setDataValue(
        "amount",
        roundTo(event.node.data.finalPayRate * (event.newValue || 0)),
        CONTROLLED_UPDATE
      );
    }
  };

  const onAmountChanged = (
    _event: CellValueChangedEvent<EarningDataRow, EarningDataRow["amount"]>
  ): void => {};

  const onFinalPayRateChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["finalPayRate"]>
  ): Promise<void> => {
    // Automatically set the amount based on the hours and pay rate, and explicitly set the source so we can identify the change event on the amount field
    if (
      event.newValue != null &&
      event.node.data?.type &&
      earningsThatRequireHours.includes(event.node.data.type)
    ) {
      event.node.setDataValue(
        "amount",
        roundTo((event.node.data?.hours || 0) * event.newValue),
        CONTROLLED_UPDATE
      );
    }
    if (event.source !== CONTROLLED_UPDATE) await refreshPayRateInData(event.node);
  };

  const onCustomRateEnabledChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["customRateEnabled"]>
  ): Promise<void> => {
    // If we're turning off the custom rate, then need to go back to the calculated default
    if (event.oldValue != null && event.newValue == null) await refreshPayRateInData(event.node);
  };

  const onJobChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["job"]>
  ): Promise<void> => {
    // If the job is changed such that the existing activity is no longer a selectable activity for that job, then clear the activity
    let changedActivity = false;
    if (event.node.data?.activity) {
      const newJobId = event.newValue;
      const selectableActivities = activityOptionsMap.get(newJobId);
      const currActivityId = event.node.data.activity;
      if (!newJobId || selectableActivities.every((a) => a.value !== currActivityId)) {
        event.node.setDataValue("activity", undefined, CONTROLLED_UPDATE);
        changedActivity = true;
      }
    }
    // Refresh the pay rate when the job changes. Since we refresh the pay rate in the activity change event, we only need to refresh it if we know the activity isn't changing
    if (!changedActivity) await refreshPayRateInData(event.node);
  };

  const onActivityChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["activity"]>
  ): Promise<void> => {
    // Refresh the pay rate when the activity changes
    await refreshPayRateInData(event.node);
  };

  const onClassificationChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["classification_override"]>
  ): Promise<void> => {
    // Refresh the pay rate when the classification changes
    await refreshPayRateInData(event.node);
  };

  const onIgnoreBenefitsChanged = async (
    event: CellValueChangedEvent<EarningDataRow, EarningDataRow["ignoreBenefitContributions"]>
  ): Promise<void> => {
    // Refresh the pay rate when "ignore benefit contributions" is toggled
    await refreshPayRateInData(event.node);
  };

  // PAY RATE FUNCTIONS
  const getPayRateItem = async (data: EarningDataRow, tmId: string): Promise<PayRateItem | undefined> => {
    if (!data.type || !earningsThatRequireHours.includes(data.type) || !activeCompanyId) return;
    const activityId = data.activity;
    let customPayRate: number | undefined;
    if (data.customRateEnabled && data.finalPayRate != null) {
      // If the currently selected type is OT or DOT, then we need to back into the REG custom rate
      const divisor = data.type === "overtime" ? 1.5 : data.type === "double_overtime" ? 2 : 1;
      customPayRate = data.finalPayRate / divisor;
    }
    // Use the true pay frequency only if it's a regular payroll since payFrequency is used for calculating fringe offset, and the user can control the "pay frequency" setting of an off-cycle payroll
    const payFrequency = checkPayroll?.type === "regular" ? checkPayroll.pay_frequency : undefined;

    setGettingPayRate(true);
    const payRateItem = await MiterAPI.team_member.get_pay_rate(tmId, {
      payday: checkPayroll?.payday || DateTime.now().toISODate(),
      companyId: activeCompanyId,
      activityId,
      jobId: data.job,
      customPayRate,
      ignoreBenefits: data.ignoreBenefitContributions || benefitsNotApplied,
      payFrequency,
      classificationOverride: data.classification_override || undefined,
      earningType: data.type,
    });
    setGettingPayRate(false);
    if (payRateItem.error) {
      console.error("Pay rate item error:", payRateItem.error);
      Notifier.error(
        "An error occurred while retrieving the appropriate pay rate. Please double check this earning's calculated pay rate."
      );
      return;
    }
    return payRateItem;
  };

  const getFinalPayRate = (
    earningType: CheckEarningType,
    payRateItem: PayRateItem | undefined
  ): number | undefined => {
    // There's no pay rate if earnings don't require hours
    if (!earningsThatRequireHours.includes(earningType) || !payRateItem?.rates) return;

    return earningType === "overtime"
      ? payRateItem.rates.ot
      : earningType === "double_overtime"
      ? payRateItem.rates.dot
      : payRateItem.rates.reg;
  };

  const refreshPayRateInData = async (
    node: IRowNode<EarningDataRow>,
    refreshPayRateItem = true
  ): Promise<void> => {
    const data = node.data;
    if (!data || !data.type) return;
    const tmId = data.tmId;
    if (!tmId) return;
    let updatedPRI = data.pay_rate_item;
    if (refreshPayRateItem) {
      // gridApi?.showLoadingOverlay();
      updatedPRI = await getPayRateItem(data, tmId);
      data.pay_rate_item = updatedPRI;
      // gridApi?.hideOverlay();
    }
    const refreshedFinalPayRate = getFinalPayRate(data.type, updatedPRI);
    node.setDataValue("finalPayRate", refreshedFinalPayRate, CONTROLLED_UPDATE);
  };

  // OTHER HELPER FUNCTIONS
  const generateRowsFromPayroll = (payroll: AggregatedPayroll): EarningDataRow[] => {
    const adjustments = payroll.adjustments;
    const allRows = adjustments.flatMap((a) => {
      // Convert every "manual_earning" into a data row
      const adjRows = (a.manual_earnings || []).map((earning): EarningDataRow => {
        const finalPayRate = getFinalPayRate(earning.type, earning.pay_rate_item);

        const row: EarningDataRow = {
          finalPayRate,
          customRateEnabled: earning.hours ? earning.pay_rate_item?.method === "custom" : undefined,
          tmId: a.team_member,
          timeOffPolicyId: earning.time_off_policy_id || undefined,
          ignoreBenefitContributions: !!earning.ignore_benefit_contributions,
          ...earning,
        };
        return row;
      });
      return adjRows;
    });
    return allRows;
  };

  const onSelectionChanged = (event: SelectionChangedEvent<EarningDataRow>) => {
    const selectedNodes = event.api.getSelectedNodes() || [];
    setAnyRowsSelected(!!selectedNodes.length);
  };

  const addEarning = () => {
    const earningData = getRowData(gridApi);
    gridApi?.setRowData([{ manual_id: ObjectID().toString() }, ...(earningData || [])]);
    setUnsavedChanges(true);
  };

  const duplicateEarnings = () => {
    const selectedNodes = gridApi?.getSelectedNodes() || [];
    const newData = getRowData(gridApi) || [];
    const dups: EarningDataRow[] = [];
    for (const node of selectedNodes) {
      const earning = node.data;
      if (!earning) continue;
      dups.push({ ...earning, manual_id: ObjectID().toString() });
    }
    gridApi?.setRowData(dups.concat(newData));
    setUnsavedChanges(true);
  };

  const deleteEarnings = async () => {
    const selectedNodes = gridApi?.getSelectedNodes() || [];
    const newData: EarningDataRow[] = [];
    gridApi?.forEachNode((node) => {
      const earning = node.data;
      if (!earning) return;
      if (selectedNodes.every((sNode) => sNode.id !== node.id)) {
        newData.push(earning);
      }
    });
    gridApi?.setRowData(newData);
    setUnsavedChanges(true);
  };

  // PREP AND SAVE FUNCTIONS
  const validateEarnings = (earningData: EarningDataRow[]) => {
    for (const earning of earningData) {
      if (!earning.type) throw new Error("Every earning must have a type");
      if (!earning.tmId) throw new Error("Every earning must have a team member");
      if (earning.pay_rate_item && earning.pay_rate_item.rates.reg < 0) {
        throw new Error("No pay rate is allowed to be negative");
      } else if (earning.amount == null || isNaN(earning.amount)) {
        throw new Error("Every earning must have a valid amount");
      } else if (isTimeOffEarning(earning.type) && !earning.timeOffPolicyId) {
        throw new Error("Every PTO earning must have a time off policy");
      } else if (earningsThatRequireHours.includes(earning.type) && !earning.hours) {
        throw new Error("Every hourly earning must have hours");
      }

      if (earning.type === "sick" || earning.type === "pto") {
        const timeOffPolicies = lookupTeam(earning.tmId)?.time_off_policies;
        const timeOffPolicy = timeOffPolicies?.find((p) => p._id === earning.timeOffPolicyId);
        if (!timeOffPolicy) throw new Error("Could not find time off policy");

        const teamMember = lookupTeam(earning.tmId);
        if (!teamMember) throw new Error("Could not find team member");

        const tmPolicy = teamMember.time_off.policies.find((p) => p.policy_id === timeOffPolicy._id);
        const levelConfig = timeOffPolicy.levels.find((level) => level._id === tmPolicy?.level_id);

        if (!levelConfig) {
          throw new Error(teamMember.full_name + " has not been assigned a level in " + timeOffPolicy.name);
        }

        if (levelConfig.disable_negative_balances) {
          const balance = tmPolicy?.balance;
          if (balance != null && earning.hours && balance < earning.hours) {
            throw new Error(
              `${teamMember.full_name}'s balance for the ${timeOffPolicy.name} time off policy is too low to create their earning.`
            );
          }
        }
      }
    }
  };

  const prepareRevisedAdjustments = (payroll: AggregatedPayroll, earningData: EarningDataRow[]) => {
    return payroll.miter_payments
      .map((mp) => {
        const tm = mp.team_member;
        const adjExisted = !!mp.adjustment;
        const adj = mp.adjustment || { team_member: tm._id as string };
        const revEarnings = earningData
          .filter((data) => {
            if (!data.type || data.amount == null) {
              return false;
            }
            if (earningsThatRequireHours.includes(data.type)) {
              if (!data.hours) return false;
            } else {
              if (!data.amount) return false;
            }
            const tmId = data.tmId;
            return tmId && tmId === tm?._id;
          })
          .map((data): ManualEarning => {
            const hourlyType = earningsThatRequireHours.includes(data.type!);
            const earning: ManualEarning = {
              manual_id: data.manual_id,
              type: data.type!,
              hours: data.hours || undefined,
              amount: data.amount!,
              pay_rate_item: hourlyType ? data.pay_rate_item : undefined,
              description: data.description?.trim() || undefined,
              job: data.job || undefined,
              activity: data.activity || undefined,
              date: data.date || undefined,
              ignore_benefit_contributions: data.ignoreBenefitContributions,
              time_off_policy_id: data.timeOffPolicyId || undefined,
              ledger_account_id: data.ledger_account_id || undefined,
              classification_override: data.classification_override || undefined,
            };
            return earning;
          });
        if (!adjExisted && !revEarnings.length) return;
        return { ...adj, manual_earnings: revEarnings } as PayrollAdjustment;
      })
      .filter((a): a is PayrollAdjustment => a !== undefined);
  };

  const saveEarnings = async () => {
    const earningData = getRowData(gridApi);
    if (!earningData || !payroll) return;
    setSaving(true);
    let prepared = false;
    let updatedAdjustments = payroll.adjustments;
    try {
      validateEarnings(earningData);
      updatedAdjustments = prepareRevisedAdjustments(payroll, earningData);
      prepared = true;
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    if (prepared) {
      const adjTms = updatedAdjustments.map((a) => a.team_member);
      await recalculatePayroll({ adjustments: updatedAdjustments, tms: adjTms });
      setUnsavedChanges(false);
    }
    setSaving(false);
  };

  const importFinished = async (results) => {
    setImportResults(results);
    await getPayroll();
  };

  // BUTTONS
  const actionButtons = () => {
    return readOnly ? null : (
      <div className="flex">
        <Button text="Add earning" onClick={addEarning} />
        {unsavedChanges && (
          <Button
            className="button-2"
            text="Save"
            onClick={saveEarnings}
            loading={saving}
            disabled={gettingPayRate}
          />
        )}
        {!unsavedChanges && (
          <div>
            <EarningsImporter onFinish={importFinished} />
          </div>
        )}
      </div>
    );
  };

  const selectedActionButtons = () => {
    return (
      <div className="flex">
        <Button text="Duplicate" onClick={duplicateEarnings} />
        <Button text="Delete" className="button-3" onClick={deleteEarnings} />
      </div>
    );
  };

  // RENDER MODAL
  return (
    <LargeModal
      headerText={(readOnly ? "M" : "Edit m") + "anual earnings"}
      onClose={() => (unsavedChanges ? setExitConfirmation(true) : onFinish())}
    >
      {importResults && <ImportResultModal result={importResults} onClose={() => setImportResults(null)} />}
      <div className="height-100 bulk-earnings">
        <div className="vertical-spacer-small"></div>
        <AgGridTable
          columnDefs={bulkEarningsColumns}
          data={initialTableData}
          defaultActionButtons={actionButtons}
          actionButtonsLocation={"left"}
          defaultColDef={defaultColDef}
          setGridApi={setGridApi}
          selectedActionButtons={selectedActionButtons}
          showSelectedActionButtons={!readOnly && anyRowsSelected}
          hideDownloadCSV={true}
          hideSidebar
          gridHeight={"75%"}
          searchValue={searchTerm}
          onSearch={setSearchTerm}
          searchPlaceholder="Search earnings"
          gridOptions={{
            rowSelection: "multiple",
            onCellValueChanged,
            onSelectionChanged,
            suppressRowClickSelection: true,
            suppressCellFocus: readOnly,
            suppressRowHoverHighlight: readOnly,
            singleClickEdit: true,
            stopEditingWhenCellsLoseFocus: true,
            getRowId: (params) => params.data.manual_id,
            tooltipShowDelay: 0,
            quickFilterText: searchTerm,
            alwaysShowHorizontalScroll: true,
          }}
        />
      </div>
      <div className="vertical-spacer-small"></div>
      {exitConfirmation && (
        <ConfirmModal
          title={"Are you sure?"}
          body={"You will lose all of your changes"}
          onYes={() => onFinish()}
          onNo={() => setExitConfirmation(false)}
        />
      )}
    </LargeModal>
  );
};

// FORMATTERS AND PARSERS
export const hourFormat = (params: ValueFormatterParams<EarningDataRow, number | undefined>): string => {
  if (params.data?.type && !earningsThatRequireHours.includes(params.data.type)) return "N/A";
  return params.value?.toFixed(2) || "";
};

const dollarFormat = (params: ValueFormatterParams<EarningDataRow, number | undefined>): string => {
  if (params.value == null) return "";
  return usdString(params.value);
};

const payRateFormat = (params: ValueFormatterParams<EarningDataRow, number | undefined>): string => {
  if (params.data?.type && !earningsThatRequireHours.includes(params.data.type)) return "N/A";
  if (params.value == null) return "";
  const formattedNum = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  }).format(params.value);
  return formattedNum;
};

const numberParser = (params: ValueParserParams<EarningDataRow>): number => {
  return roundTo(Number(params.newValue)) || 0;
};

const dateFormatter = (
  params:
    | GetQuickFilterTextParams<EarningDataRow, string | undefined>
    | ValueFormatterParams<EarningDataRow, string | undefined>
) => {
  return params.value ? DateTime.fromISO(params.value).toFormat("DD") : "";
};

// FILTER PARAMS
const defaultSharedFilterParams = {
  debounceMs: 300,
};

const defaultTextFilterParams = {
  ...defaultSharedFilterParams,
  suppressAndOrCondition: true,
};

const defaultNumberFilterParams = {
  ...defaultSharedFilterParams,
};

const earningTypeFilterParams = {
  ...defaultTextFilterParams,
  filterValueGetter: (params: ValueGetterParams<EarningDataRow>) =>
    params.data?.type && earningTypeLookup[params.data.type],
};

const isTimeOffEarning = (earningType: string | undefined) => {
  return earningType === "pto" || earningType === "sick";
};
