import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Formblock, Label, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import {
  AggregatedFile,
  AggregatedTeamMember,
  MiterAPI,
  TeamMember,
  UpdateTeamMemberParams,
} from "dashboard/miter";
import {
  useActiveAccount,
  useActiveCompany,
  useActiveCompanyId,
  useBenefitsEligibilityGroupOptions,
  useCertificationTypeOptions,
  useCompanyFormOptions,
  useDepartmentOptions,
  useFillableTemplateOptions,
  useLookupCompanyDocuments,
  useLookupFillableTemplates,
  usePermissionGroupOptions,
  useRefetchTeam,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { sleep } from "dashboard/utils";
import { Option } from "ui/form/Input";
import { WizardTeamMember } from "./TeamMemberWizard";
import { useTeamMemberPermissionGroups } from "dashboard/hooks/useTeamMemberPermissionGroups";
import { DateTime } from "luxon";
import { createFillableDocumentParams } from "dashboard/utils/fillable-document";
import { notNullish } from "miter-utils";
import { useCompanyDocumentOptions } from "../../hooks/atom-hooks";
import { employmentCategoryOptions, employmentTermOptions } from "dashboard/pages/team-members/TeamUtils";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";
import * as vals from "dashboard/utils/validators";
import { BENEFITS_ELIGIBILITY_STATUS_OPTIONS } from "dashboard/pages/team-members/forms/options";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberOrgAttributesForm = {
  department_id?: Option<string> | null;
  title?: string;
  is_universal_supervisor?: boolean;
  reports_to?: Option<string> | null;
  employment_category?: Option<"full_time" | "part_time"> | null;
  estimated_hours_worked_per_week?: number;
  benefits_eligibility_status?: Option<"eligible" | "ineligible">;
  benefits_eligibility_groups?: Option<string>[] | null;
  employment_term?: Option<"temporary" | "seasonal" | "permanent"> | null;
  friendly_id?: string;
  permission_groups?: Option<string>[];
  forms?: Option<string>[];
  certifications?: Option<string>[];
  fillableTemplates?: Option<string>[];
  documentSignatures?: Option<string>[];
};

export const TeamMemberOrgInfoScreen: React.FC<Props> = ({ name, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/

  const isMounted = useRef(true);

  const teamOptions = useTeamOptions();
  const refetchTeam = useRefetchTeam();
  const departmentsOptions = useDepartmentOptions();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const isActiveOnClasp = isActiveClaspCompany(activeCompany);
  const formOptions = useCompanyFormOptions();
  const fillableTemplateOptions = useFillableTemplateOptions();
  const companyDocumentOptions = useCompanyDocumentOptions({
    predicate: (doc) => doc.type === "application/pdf",
  });
  const lookupCompanyDocument = useLookupCompanyDocuments();

  const lookupFillableTemplate = useLookupFillableTemplates();
  const certificationTypeOptions = useCertificationTypeOptions();
  const benefitsEligibilityGroupOptions = useBenefitsEligibilityGroupOptions();
  const activeAccount = useActiveAccount();
  const { setCanNext, setNextButtonText, handleComplete } = useWizard();

  const form = useForm<TeamMemberOrgAttributesForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(
      teamMember,
      departmentsOptions,
      teamOptions,
      benefitsEligibilityGroupOptions
    ),
  });

  const formData = useWatch<TeamMemberOrgAttributesForm>({ control: form.control });

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  const [unexpiredCertificationTypeIds, setUnexpiredCertificationTypeIds] = useState<string[]>([]);

  const finalCertificationTypeOptions = useMemo(() => {
    return certificationTypeOptions.filter((cert) => !unexpiredCertificationTypeIds.includes(cert.value));
  }, [unexpiredCertificationTypeIds, certificationTypeOptions]);

  /*********************************************************
   * Get's the permission groups for this team member
   **********************************************************/
  const pgOverrideAttributes = useMemo(
    () => ({
      department_id: formData.department_id?.value,
      title: formData.title,
    }),
    [formData.department_id?.value, formData.title]
  );

  const teamMemberPermissionGroups = useTeamMemberPermissionGroups(teamMember?._id, pgOverrideAttributes);
  const selectablePermissionGroupsPredicate = useCallback(
    (pg) => {
      if (!teamMemberPermissionGroups) return true;
      return !teamMemberPermissionGroups.find((tpg) => tpg._id === pg._id);
    },
    [teamMemberPermissionGroups]
  );
  const selectablePermissionGroupOptions = usePermissionGroupOptions({
    predicate: selectablePermissionGroupsPredicate,
  });

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/

  useEffect(() => {
    setNextButtonText("Save and exit");
  }, []);

  useEffect(() => {
    const getUnexpiredCertifications = async () => {
      MiterAPI.certifications
        .forage({
          filter: [
            { field: "team_member_id", value: teamMember?._id, type: "string" },
            {
              type: "or",
              value: [
                {
                  field: "expires_at",
                  value: DateTime.now().toISODate(),
                  type: "string",
                  comparisonType: ">=",
                },
                { field: "expires_at", value: null, type: "string" },
              ],
            },
          ],
        })
        .then((res) => {
          setUnexpiredCertificationTypeIds(
            res.data.map((certification) => certification.certification_type_id) || []
          );
        });
    };
    getUnexpiredCertifications();
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    handleComplete();
  };

  const buildParams = (params: TeamMemberOrgAttributesForm) => {
    const data: UpdateTeamMemberParams = {
      department_id: params.department_id?.value,
      title: params.title,
      is_universal_supervisor: params.is_universal_supervisor,
      reports_to: params.reports_to?.value,
      employment_category: params.employment_category?.value as TeamMember["employment_category"],
      employment_term: params.employment_term?.value as TeamMember["employment_term"],
      estimated_hours_worked_per_week: params.estimated_hours_worked_per_week,
      benefits_eligibility_status: params.benefits_eligibility_status?.value,
      benefits_eligibility_groups: params.benefits_eligibility_groups?.map((bs) => bs.value),
      permission_group_ids: params.permission_groups?.map((pg) => pg.value),
    };

    if (params.friendly_id) {
      data.friendly_id = params.friendly_id;
    }

    return {
      data,
      forms: params.forms?.map((f) => f.value) || [],
      certifications: params.certifications?.map((c) => c.value) || [],
      fillableTemplates: (params.fillableTemplates || [])
        .map((ft) => lookupFillableTemplate(ft.value))
        .filter(notNullish),
      documentSignatures: params.documentSignatures?.map((ds) => ds.value) || [],
    };
  };

  const saveTeamMember = async (params: TeamMemberOrgAttributesForm) => {
    const teamMemberId = teamMember?._id;
    if (!teamMemberId) throw new Error("No team member");
    if (!activeCompanyId) throw new Error("No active company");
    if (!activeAccount) throw new Error("No active account");
    try {
      const cleanedParams = buildParams(params);
      const { data, forms, certifications, fillableTemplates, documentSignatures } = cleanedParams;

      const updateTeamMember = async () => {
        return await MiterAPI.team_member.update(teamMemberId, data);
      };

      const sendForms = async () => {
        return await MiterAPI.forms.send_submission_requests({
          team_member_ids: [teamMemberId],
          form_ids: forms,
        });
      };

      const sendCertifications = async () => {
        const certificationParams = certifications.map((certification_type_id) => ({
          certification_type_id,
          team_member_id: teamMember._id!,
          company_id: activeCompanyId,
          submitted: false,
          expires_at: undefined,
          custom_field_values: [],
          file_uploads: {},
          archived: false,
        }));

        return await MiterAPI.certifications.create(certificationParams);
      };

      const sendFillableDocuments = async () => {
        const fillableDocumentParams = createFillableDocumentParams({
          teamMembers: [teamMember as AggregatedTeamMember],
          fillableTemplates,
          account: activeAccount,
          onboarding: true,
        });
        return await MiterAPI.files.create_without_blob(fillableDocumentParams);
      };

      const sendSignatureRequests = async () => {
        const signatureRequestParams = documentSignatures?.map((document_id) => {
          return {
            company_id: activeCompanyId,
            status: "sent" as const,
            requestor: {
              user_id: activeAccount._id,
            },
            events: [],
            parent_document_id: document_id,
            document_type: "file" as const,
            signer: {
              team_member_id: teamMember?._id,
              name: teamMember.full_name || "Team member",
              title: teamMember.title || "N/A",
              email: teamMember.email,
              type: "team_member" as const,
            },
          };
        });
        return await MiterAPI.esignature_items.requests.create(signatureRequestParams);
      };

      const giveSignersAccess = async (): Promise<void> => {
        const additionalPermission = [
          {
            group: { type: "team_member", value: teamMember._id },
            can: ["view"],
          },
        ] as NonNullable<AggregatedFile["team_permissions"]>;
        const fileParams = documentSignatures?.map((document_id) => {
          const miterDocument = lookupCompanyDocument(document_id);
          if (!miterDocument) throw new Error(`Document with id ${document_id} not found.`);
          return {
            _id: miterDocument._id,
            team_permissions: (miterDocument.team_permissions || []).concat(additionalPermission),
            existing_tm_document: false,
            onboarding_document: true,
          };
        });

        const docRes = await MiterAPI.files.update_many({
          files: fileParams,
        });

        if (docRes.error) throw Error(docRes.error);
      };

      const [updateTMRes, formsRes, certificationsRes, fillableDocumentsRes, signatureRequestRes, _] =
        await Promise.all([
          updateTeamMember(),
          sendForms(),
          sendCertifications(),
          sendFillableDocuments(),
          sendSignatureRequests(),
          giveSignersAccess(),
        ]);

      if (updateTMRes.error) throw new Error(updateTMRes.error);
      setTeamMember(updateTMRes);
      Notifier.success("Team member saved successfully");

      if (formsRes.error) {
        Notifier.error(formsRes.error);
      }
      if (certificationsRes.error) {
        Notifier.error(certificationsRes.error);
      }
      if (fillableDocumentsRes.error) {
        Notifier.error(fillableDocumentsRes.error);
      }
      if (signatureRequestRes.error) {
        Notifier.error(signatureRequestRes.error);
      }

      await sleep(100);
      await refetchTeam(updateTMRes._id);
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  /** Renders the permission groups that this person is automatically a part of due to their attributes */
  const renderAutomaticPermissionGroups = () => {
    return (
      <>
        <Label
          className="modal wizard"
          label="Automatically added permission groups"
          labelInfo="These permission groups are automatically added based on the team member's attributes"
        />
        <div className={styles["automatically-added-permission-groups"]}>
          {teamMemberPermissionGroups?.map((pg) => (
            <Badge key={pg._id} className="modal wizard no-margin" text={pg.name} color="light-blue" />
          ))}
        </div>
      </>
    );
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Org Information</h2>
          <p className={styles["subheader-description"]}>
            Organization related information for this team member
          </p>
        </div>
        <div className="form-section">
          <Formblock
            label="ID"
            type="text"
            name="friendly_id"
            labelInfo="Unique identifier (typically a number) for this team member. We will create one automatically if you leave this blank."
            placeholder="Enter an ID"
            form={form}
            className="modal wizard"
            editing={true}
          />
          <Formblock
            label="Department"
            type="select"
            name="department_id"
            placeholder="Select a department"
            form={form}
            className="modal wizard"
            editing={true}
            options={departmentsOptions}
          />
          <Formblock
            label="Reports to"
            labelInfo="This team member's direct manager."
            type="select"
            name="reports_to"
            placeholder="Select a manager"
            form={form}
            className="modal wizard"
            editing={true}
            options={teamOptions}
          />
          <Formblock
            label="Supervisor"
            type="checkbox"
            text="This team member is a universal supervisor across jobs"
            name="is_universal_supervisor"
            form={form}
            className="modal wizard"
            editing={true}
          />
          <Formblock
            type="text"
            name="title"
            label="Title"
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Enter a job title"}
          />
          <Formblock
            label={`Employment category${isActiveOnClasp ? "*" : ""}`}
            type="select"
            name="employment_category"
            placeholder="Select an employment category"
            form={form}
            className="modal wizard"
            editing={true}
            options={employmentCategoryOptions}
            requiredSelect={isActiveOnClasp}
          />
          {formData.employment_category?.value === "part_time" && (
            <Formblock
              type="number"
              name="estimated_hours_worked_per_week"
              label="Hours worked per week (estimated)"
              labelInfo="Number of hours this team member works per week, on average"
              form={form}
              editing={true}
              className="modal wizard"
              placeholder={"40"}
              height={"unset"}
              val={isActiveOnClasp ? vals.required : undefined}
            />
          )}
          {isActiveOnClasp && (
            <Formblock
              label="Benefits eligibility override"
              labelInfo="By default, all employees enrolled for payroll are eligible for benefits (with SSNs, DOBs, start dates, addresses). This field allows you to override that setting for EEs unenrolled in payroll, or for contractors that may be benefits eligible."
              type="select"
              name="benefits_eligibility_status"
              form={form}
              isClearable={true}
              placeholder="Default"
              options={BENEFITS_ELIGIBILITY_STATUS_OPTIONS}
              errors={errors}
              className="modal small-margin"
              editing={true}
            />
          )}
          {isActiveOnClasp && !!benefitsEligibilityGroupOptions.length ? (
            <Formblock
              type="multiselect"
              name="benefits_eligibility_groups"
              label="Benefits eligibility groups*"
              form={form}
              editing={true}
              className="modal wizard"
              placeholder={"Select benefits eligibility groups"}
              options={benefitsEligibilityGroupOptions}
              height={"unset"}
              requiredSelect={true}
            />
          ) : null}
          <Formblock
            label="Employment term"
            labelInfo="The team member's employment term (Temporary, Seasonal, Permanent)."
            type="select"
            name="employment_term"
            placeholder="Select an employment term"
            form={form}
            className="modal wizard"
            editing={true}
            options={employmentTermOptions}
          />
          {renderAutomaticPermissionGroups()}
          <Formblock
            type="multiselect"
            name="permission_groups"
            label="Additional permission groups"
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Select permission groups"}
            options={selectablePermissionGroupOptions}
            height={"unset"}
          />
          <Formblock
            type="multiselect"
            name="forms"
            label="Collect additional information with Miter Forms"
            labelInfo={`Request submissions from ${
              teamMember?.full_name || "your onboardee"
            } for the selected forms. Use the Forms module to create forms.`}
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Request custom forms"}
            options={formOptions}
            height={"unset"}
          />
          <Formblock
            type="multiselect"
            name="documentSignatures"
            label="Select documents to sign"
            labelInfo={`Head to the general tab of the Documents module to upload PDFs to be signed.`}
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Select documents for signing"}
            height={"unset"}
            options={companyDocumentOptions}
          />
          <Formblock
            type="multiselect"
            name="fillableTemplates"
            label="Send fillable PDFs to be completed"
            labelInfo={`Head to the fillable templates tab of the Documents module to create fillable PDFs.`}
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Select PDF templates"}
            options={fillableTemplateOptions}
            height={"unset"}
          />

          <Formblock
            type="multiselect"
            name="certifications"
            label="Collect certifications"
            labelInfo={`Request certifications from ${
              teamMember?.full_name || "your onboardee"
            } for the selected certification types. Use the Team Settings page to create new certification types.`}
            form={form}
            editing={true}
            className="modal wizard"
            placeholder={"Request certifications"}
            options={finalCertificationTypeOptions}
            height={"unset"}
          />
        </div>
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  teamMember: WizardTeamMember | undefined,
  departments: Option<string>[],
  teamMembers: Option<string>[],
  benefitsEligibilityGroups: Option<string>[]
): TeamMemberOrgAttributesForm => {
  const tmEligibilityGroupOptions = teamMember?.benefits_eligibility_groups
    ?.map((group) => {
      return benefitsEligibilityGroups.find((o) => o.value === group);
    })
    .filter(notNullish);

  return {
    department_id: departments.find((d) => d.value === teamMember?.department_id) || null,
    title: teamMember?.title || "",
    employment_category: employmentCategoryOptions.find((o) => o.value === teamMember?.employment_category),
    estimated_hours_worked_per_week: teamMember?.estimated_hours_worked_per_week ?? undefined,
    is_universal_supervisor: teamMember?.is_universal_supervisor || false,
    reports_to: teamMembers.find((tm) => tm.value === teamMember?.reports_to) || null,
    benefits_eligibility_status: BENEFITS_ELIGIBILITY_STATUS_OPTIONS.find(
      (o) => o.value === teamMember?.benefits_eligibility_status
    ),
    benefits_eligibility_groups: !!tmEligibilityGroupOptions?.length ? tmEligibilityGroupOptions : null,
  };
};
