import AppContext from "dashboard/contexts/app-context";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ActionModal } from "ui";

import { CustomFieldValue, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import CustomFieldValuesForm from "./CustomFieldValuesForm";
import { pick } from "lodash";
import { cleanCustomFieldValuesBeforeSaving } from "miter-utils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { CustomFieldParentType } from "backend/models/custom-field";

type Props = {
  parentID: string;
  parentType: CustomFieldParentType;
  defaultValues: CustomFieldValue[];
  onFinish: (values: CustomFieldValue[]) => void;
  onHide: () => void;
};

const CustomFieldValueModal: React.FC<Props> = ({
  parentID,
  parentType,
  defaultValues,
  onFinish,
  onHide,
}) => {
  const form = useForm();

  // Renders the form
  useWatch({ control: form.control });

  const activeCompanyId = useActiveCompanyId();
  const { customFields, getCustomFields } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const selectedCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === parentType),
    [customFields]
  );

  const saveCustomFieldValues = useCallback(
    async (data) => {
      setLoading(true);
      try {
        // Only save fields with values that have changed
        const dirtyFields = Object.keys(form.formState.dirtyFields);

        const params = cleanCustomFieldValuesBeforeSaving(
          pick(data, dirtyFields),
          activeCompanyId!,
          parentID,
          parentType,
          defaultValues
        );

        const res = await MiterAPI.custom_field_values.save(params);
        if (res.error) throw new Error(res.error);

        Notifier.success("Custom field values saved successfully");
        await getCustomFields();

        onFinish(res);
      } catch (e: $TSFixMe) {
        console.error("Error creating custom field:", e);
        Notifier.error(e.message);
      }
      setLoading(false);
    },
    [
      form.formState.dirtyFields,
      activeCompanyId,
      parentID,
      parentType,
      defaultValues,
      onFinish,
      getCustomFields,
    ]
  );

  const submit = () => {
    form.handleSubmit(saveCustomFieldValues)();
  };

  const hide = () => {
    onHide();
  };

  return (
    <ActionModal
      headerText={"Custom fields"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={hide}
      submitText={"Save"}
      onHide={hide}
      onSubmit={submit}
      loading={loading}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <CustomFieldValuesForm
          form={form}
          customFields={selectedCustomFields}
          defaultValues={defaultValues}
          fieldOptions={{ inlineCheckbox: true }}
        />
      </div>
    </ActionModal>
  );
};

export default CustomFieldValueModal;
