import React, { useState, useEffect, useMemo } from "react";
import { getPaymentPayMethod, getPaymentTotals } from "dashboard/utils/utils";
import PayrollContext from "./payrollContext";
import Notifier from "../../../utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { ClickAwayListener } from "@material-ui/core";
import { Button, Loader } from "ui";
import { DateTime } from "luxon";
import { CheckCompanyPayday, CheckPayroll } from "backend/utils/check/check-types";
import { getNextBankDay } from "miter-utils/holidays";
import InfoButton from "dashboard/components/information/information";
import { useActiveCompany, useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type Props = {
  hide: () => void;
};

export const SetNewPayday: React.FC<Props> = (props) => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const { payroll, setPayroll } = React.useContext(PayrollContext);

  const [loading, setLoading] = useState(false);
  const [paydays, setPaydays] = useState<CheckCompanyPayday[]>();
  const [selectedPayday, setSelectedPayday] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [nextDaySelected, setNextDaySelected] = useState(false);

  const checkCompany = activeCompany?.check_company;
  const checkPayroll = payroll?.check_payroll;
  const companyProcessingPeriod = checkCompany?.processing_period;
  const payrollIsOneDay = checkPayroll?.processing_period === "one_day";

  const directDepositIncluded = useMemo(() => {
    return !!payroll?.miter_payments.some((mp) => {
      const dd = getPaymentPayMethod(mp) === "direct_deposit";
      const { earnings, reimbursements } = getPaymentTotals(mp);
      return dd && (earnings || reimbursements);
    });
  }, [payroll]);

  const showNextDayPayOption = companyProcessingPeriod === "two_day" && directDepositIncluded;

  const todayDt = DateTime.now().setZone("America/Los_Angeles");
  // If it's 5pm PT or later, then we've missed today's deadline, so need to act like it's tomorrow
  const deadlinePivot = todayDt.plus({ days: todayDt.hour > 16 ? 1 : 0 });

  // Approval deadline can only be on a bank day, so let's figure out the approval deadline for doing next-day-pay
  const nextDayPayApprovalDayDt = getNextBankDay(deadlinePivot.minus({ days: 1 }));
  const nextPaydayBankDay = getNextBankDay(nextDayPayApprovalDayDt).toISODate();

  const handleNextDayPayClick = () => {
    setSelectedPayday(nextPaydayBankDay);
    setNextDaySelected(true);
  };

  const handleOtherClick = (pd: CheckCompanyPayday) => {
    setSelectedPayday(pd.payday);
    setNextDaySelected(false);
  };

  const getPaydays = async () => {
    if (!activeCompanyId || !payroll || !checkPayroll) return;
    setLoading(true);
    try {
      const response = await MiterAPI.payrolls.retrieve_paydays(activeCompanyId);
      if (response.error) {
        throw new Error(response.error);
      }

      const finalPaydays = directDepositIncluded
        ? response.filter((p) => p.approval_deadline >= deadlinePivot.toISODate())
        : response.slice();
      setPaydays(finalPaydays);

      if (payrollIsOneDay && checkPayroll.payday === nextPaydayBankDay) {
        setNextDaySelected(true);
        setSelectedPayday(checkPayroll.payday);
      } else {
        const paydayOption = finalPaydays.find((p) => p.payday === checkPayroll.payday);
        setSelectedPayday(paydayOption?.payday || finalPaydays[0]!.payday);
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving paydays.");
    }
    setLoading(false);
  };

  const handleSave = async () => {
    if (!payroll) return;
    setSaving(true);
    try {
      const params: Partial<CheckPayroll> = {
        payday: selectedPayday,
        processing_period: nextDaySelected ? "one_day" : companyProcessingPeriod,
      };
      const response = await MiterAPI.payrolls.update_payroll(payroll._id, params);
      if (response.error) {
        throw new Error(response.error);
      }
      setPayroll((prev) => {
        if (!prev) return;
        // Need to keep most of our existing CheckPayroll because it's the previewed version (with totals, benefits, taxes, etc.), and we don't want to lose those
        const newCheckPayroll = {
          ...prev.check_payroll,
          payday: response.check_payroll.payday,
          processing_period: response.check_payroll.processing_period,
          approval_deadline: response.check_payroll.approval_deadline,
        };
        return { ...prev, check_payroll: newCheckPayroll };
      });
      Notifier.success("Payday successfully updated.");
      props.hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was error updating the payroll's payday. Please reach out to support.");
      setNextDaySelected(false);
    }
    setSaving(false);
  };

  useEffect(() => {
    getPaydays();
  }, [payroll]);

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={props.hide}>
        <div className="modal-wrapper form">
          <div className="modal-header form">
            <div className="flex">
              Set new payday
              <InfoButton text="If the payroll contains no direct deposit payments, disregard the below deadlines. The approval deadline is the same as the payday." />
            </div>
          </div>
          <div className="modal-body form">
            <div className="vertical-spacer"></div>
            {loading && <Loader />}
            {!loading && showNextDayPayOption && (
              <>
                <div className="next-day-pay-text">
                  <span>Pay your team next business day:</span>
                </div>
                <PaydayOption
                  onClick={handleNextDayPayClick}
                  selected={nextDaySelected}
                  approvalDate={nextDayPayApprovalDayDt.toISODate()}
                  payday={nextPaydayBankDay}
                />
                <div className="vertical-spacer-five" />
                <div style={{ borderTop: "2px solid #ccc" }} className="vertical-spacer-small" />
                <div className="vertical-spacer-five" />
              </>
            )}

            {!loading &&
              paydays &&
              paydays.map((pd) => (
                <div key={pd.approval_deadline + pd.payday}>
                  <PaydayOption
                    onClick={() => handleOtherClick(pd)}
                    selected={selectedPayday === pd.payday}
                    approvalDate={pd.approval_deadline}
                    payday={pd.payday}
                  />
                </div>
              ))}
          </div>
          <div className="modal-footer form">
            <div className="flex">
              <button className="button-1" onClick={props.hide}>
                Cancel
              </button>
              <Button text="Save" className="button-2" onClick={handleSave} loading={saving} />
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

const PaydayOption: React.FC<{
  onClick: () => void;
  selected: boolean;
  approvalDate: string;
  payday: string;
}> = (props) => {
  return (
    <div onClick={props.onClick} className={"payday-option-wrapper " + (props.selected ? "selected" : "")}>
      <div className="payday-option-section">
        <div className="payday-option-label">Approval deadline</div>
        <div className="payday-option-text">
          {DateTime.fromISO(props.approvalDate).toFormat("EEE, MMM d")}
        </div>
      </div>
      <div className="payday-option-section">
        <div className="payday-option-label">Payday</div>
        <div className="payday-option-text">{DateTime.fromISO(props.payday).toFormat("EEE, MMM d")}</div>
      </div>
    </div>
  );
};
