import { useMemo, SetStateAction, Dispatch } from "react";

import styles from "./TeamMemberChecklistBuilder.module.css";
import {
  Bank,
  Equals,
  Flag,
  Hand,
  IdentificationCard,
  Table,
  Vault,
  IdentificationBadge,
  Textbox,
  CheckSquare,
  MagnifyingGlass,
  ScribbleLoop,
  FileText,
} from "phosphor-react";
import { ChecklistTasks } from "../checklists/ChecklistTasks";
import { ChecklistTaskItem } from "dashboard/utils/checklist-utils";
import { useCheckrCompanyPackages } from "../screenings/useCheckrPackages";
import { CustomTaskContent } from "./team-member-checklist-builder/CustomTaskContent";
import { EEOTaskContent } from "./team-member-checklist-builder/EEOTaskContent";
import { PersonalInfoTaskContent } from "./team-member-checklist-builder/PersonalInformationTaskContent";
import { ScreeningTaskContent } from "./team-member-checklist-builder/ScreeningTaskContent";
import { CertificationTaskContent } from "./team-member-checklist-builder/CertificationTaskContent";
import { CustomFieldTaskContent } from "./team-member-checklist-builder/CustomFieldTaskContent";
import { FillableDocumentTaskContent } from "./team-member-checklist-builder/FillableDocumentTaskContent";
import { FormTaskContent } from "./team-member-checklist-builder/FormTaskContent";
import { I9EmployeeTaskContent } from "./team-member-checklist-builder/I9EmployeeTaskContent";
import { PaymentMethodTaskContent } from "./team-member-checklist-builder/PaymentMethodTaskContent";
import { WithholdingsTaskContent } from "./team-member-checklist-builder/WithholdingsTaskContent";
import { BankAccountTaskContent } from "./team-member-checklist-builder/BankAccountTaskContent";
import { SignableDocumentTaskContent } from "./team-member-checklist-builder/SignableDocumentContent";
import { I9EmployerTaskContent } from "./team-member-checklist-builder/I9EmployerTaskContent";
import { cloneDeep } from "lodash";
import {
  TeamMemberOnboardingTaskConfig,
  TeamMemberOnboardingTaskForm,
} from "dashboard/utils/team-member-checklist-utils";

type Props = {
  tasks: ChecklistTaskItem<TeamMemberOnboardingTaskForm>[];
  setTasks: Dispatch<SetStateAction<ChecklistTaskItem<TeamMemberOnboardingTaskForm>[]>>;
  type: "new_hire" | "admin";
};

/**
 * The `TeamMemberChecklistBuilder` component is responsible for rendering a checklist builder
 * for team members during the onboarding process. This component handles different types of tasks,
 * including personal information, EEO, withholdings, payment method, and more.
 *
 * @param {WizardTeamMember} teamMember - The team member being onboarded.
 * @param {ChecklistTaskItem<TeamMemberOnboardingTaskForm>[]} tasks - An array of task items that need to be completed during the onboarding process.
 * @param {Dispatch<SetStateAction<ChecklistTaskItem<TeamMemberOnboardingTaskForm>[]>>} setTasks - A state dispatcher function used to update the tasks.
 * @param {"new_hire" | "admin"} type - The type of checklist, which can either be for a "new_hire" or for an "admin".
 *
 * @returns {JSX.Element} A React component that renders the team member checklist builder.
 */
export const TeamMemberChecklistBuilder: React.FC<Props> = ({ tasks, setTasks, type }) => {
  const { checkrEnabled, companyPackages, mapOfNodesToPackages } = useCheckrCompanyPackages();

  const taskConfig = useMemo(() => {
    // Need to clone deep so we don't mutate the original config
    const config = cloneDeep({ ...EE_ONBOARDING_TASK_CONFIG });

    // Add checkr screenings information
    if (config.screening) {
      if (!checkrEnabled) config.screening.hidden = true;
      config.screening.metadata = { companyPackages, mapOfNodesToPackages };
    }

    // If this is an admin checklist, hide all tasks except custom tasks
    if (type === "admin") {
      Object.keys(config).forEach((key) => {
        const adminTaskTypes = ["custom_task"];

        if (!adminTaskTypes.includes(key)) {
          config[key].hidden = true;
        }
      });
    }

    return config;
  }, [checkrEnabled, type, companyPackages, mapOfNodesToPackages]);

  return (
    <div className={styles["team-member-checklist-builder-container"]}>
      <div className={styles["team-member-checklist-builder-tasks"]}>
        <ChecklistTasks tasks={tasks} setTasks={setTasks} taskConfig={taskConfig} />
      </div>
    </div>
  );
};

/**
 * The `EE_ONBOARDING_TASK_CONFIG` is a configuration object defining the tasks required during the onboarding
 * process for employees. Each task is represented by a key-value pair, where the key is the task type and the
 * value is an object describing the task's properties, including header information, default data, content,
 * and validation rules.
 *
 * The configuration includes tasks such as personal information, EEO, withholdings, payment method, and more.
 * It also supports custom tasks and screenings, with options to hide or validate tasks based on specific criteria.
 */
export const EE_ONBOARDING_TASK_CONFIG: TeamMemberOnboardingTaskConfig = {
  personal_info: {
    header: {
      title: "Personal information",
      icon: <IdentificationCard />,
    },
    defaultData: {
      type: "personal_info",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: PersonalInfoTaskContent,
    allowMultiple: false,
    disableReorder: true,
  },
  eeo: {
    header: {
      title: "EEO",
      icon: <Equals />,
    },
    defaultData: {
      type: "eeo",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: EEOTaskContent,
    allowMultiple: false,
  },
  withholdings: {
    header: {
      title: "Withholdings",
      icon: <Hand />,
    },
    defaultData: {
      type: "withholdings",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: WithholdingsTaskContent,
    allowMultiple: false,
  },
  payment_method: {
    header: {
      title: "Payment method",
      icon: <Bank />,
    },
    defaultData: {
      type: "payment_method",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: PaymentMethodTaskContent,
    allowMultiple: false,
  },
  bank_accounts: {
    header: {
      title: "Bank accounts",
      icon: <Vault />,
    },
    defaultData: {
      type: "bank_accounts",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: BankAccountTaskContent,
    allowMultiple: false,
  },
  i9_employee: {
    header: {
      title: "I-9 (Employee)",
      icon: <Flag />,
    },
    defaultData: {
      type: "i9_employee",
      status: "incomplete",
      due_days_from_start: undefined,
      i9_id: undefined,
    },
    content: I9EmployeeTaskContent,
    allowMultiple: false,
  },
  i9_employer: {
    header: {
      title: "I-9 (Employer)",
      icon: <Flag />,
    },
    defaultData: {
      type: "i9_employer",
      status: "incomplete",
      due_days_from_start: undefined,
      i9_id: undefined,
    },
    content: I9EmployerTaskContent,
    allowMultiple: false,
    hidden: true,
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data.assignee?.length) {
        errors.assignee = "Please assign this task to someone";
      }

      return errors;
    },
  },
  fill_document: {
    header: {
      title: "Fillable Document",
      icon: <FileText />,
    },
    defaultData: {
      type: "fill_document",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: FillableDocumentTaskContent,
    allowMultiple: true,
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data?.fillable_template_id) {
        errors.fillable_template_id = "Please select a document";
      }

      return errors;
    },
  },
  sign_document: {
    header: {
      title: "Signable Document",
      icon: <ScribbleLoop />,
    },
    defaultData: {
      type: "sign_document",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: SignableDocumentTaskContent,
    allowMultiple: true,
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data?.file_id) {
        errors.file_id = "Please select a document";
      }

      return errors;
    },
  },
  form: {
    header: {
      title: "Form",
      icon: <Table />,
    },
    defaultData: {
      type: "form",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: FormTaskContent,
    allowMultiple: true,
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data?.form_id) {
        errors.form_id = "Please select a form";
      }

      return errors;
    },
  },
  certification: {
    header: {
      title: "Certification",
      icon: <IdentificationBadge />,
    },
    defaultData: {
      type: "certification",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: CertificationTaskContent,
    allowMultiple: true,
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data?.certification_type_id) {
        errors.certification_id = "Please select a certification";
      }

      return errors;
    },
  },
  custom_field: {
    header: {
      title: "Custom field",
      icon: <Textbox />,
    },
    defaultData: {
      type: "custom_field",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: CustomFieldTaskContent,
    allowMultiple: true,
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data?.custom_field_id) {
        errors.custom_field_id = "Please select a custom field";
      }

      return errors;
    },
  },
  custom_task: {
    header: {
      title: "Custom task",
      icon: <CheckSquare />,
    },
    defaultData: {
      type: "custom_task",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: CustomTaskContent,
    allowMultiple: true,
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data?.title && !task.data?.custom_task_id) {
        errors.name = "Please make sure the task has a name";
      }

      return errors;
    },
  },
  screening: {
    header: {
      title: "Screenings",
      icon: <MagnifyingGlass />,
    },
    defaultData: {
      type: "screening",
      status: "incomplete",
      due_days_from_start: undefined,
    },
    content: ScreeningTaskContent,
    allowMultiple: true,
    metadata: {
      screeningPackages: [],
    },
    validator: (task) => {
      const errors: Record<string, string> = {};

      if (!task.data?.checkr_package) {
        errors.checkr_package = "Please select a package";
      }

      return errors;
    },
  },
};
