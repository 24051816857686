import { pickTextColor } from "miter-utils";
import React, { CSSProperties, ReactElement } from "react";
import { TextWithTooltip } from "../tooltipItems";

import "./Badge.css";

type Props = {
  color?: string;
  element?: ReactElement;
  text?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  table?: boolean;
  tooltip?: string;
  style?: CSSProperties;
  backgroundColor?: string;
  textColor?: string;
  border?: CSSProperties["border"];
};

const Badge = ({
  color,
  text,
  className,
  onClick,
  tooltip,
  table,
  style,
  element,
  backgroundColor,
  textColor,
  border,
}: Props): React.ReactElement => {
  color = color || getColor(text);

  const renderBadge = () => {
    return (
      <div
        style={{
          ...style,
          ...(backgroundColor ? { backgroundColor, color: pickTextColor(backgroundColor) } : {}),
          ...(textColor ? { color: textColor } : {}),
          ...(border ? { border } : {}),
        }}
        className={className + " " + (table ? " badge " + color : "badge " + color)}
      >
        <div className="badge-text">{element || text?.replace(/_/g, " ")?.toUpperCase?.()}</div>
      </div>
    );
  };

  return (
    <div style={style} onClick={onClick} className={(className ? className : "") + " badge-wrapper"}>
      {tooltip ? (
        <TextWithTooltip place="bottom" id={text || "tooltip-id"} tooltip={tooltip}>
          {renderBadge()}
        </TextWithTooltip>
      ) : (
        <>{renderBadge()}</>
      )}
    </div>
  );
};

export default Badge;

const getColor = (text?: string) => {
  const lowerText = text?.toLowerCase?.();

  if (
    lowerText === "active" ||
    lowerText === "paid" ||
    lowerText === "refunded" ||
    lowerText === "credited" ||
    lowerText === "approved" ||
    lowerText === "connected" ||
    lowerText === "in use" ||
    lowerText === "returned" ||
    lowerText === "yes" ||
    lowerText === "completed"
  ) {
    return "green";
  }

  if (
    lowerText === "draft" ||
    lowerText === "unapproved" ||
    lowerText === "scheduled" ||
    lowerText === "out of order" ||
    lowerText === "left on site" ||
    lowerText === "not_started"
  ) {
    return "yellow";
  }

  if (lowerText === "pending") {
    return "light-blue";
  }

  if (
    lowerText === "denied" ||
    lowerText === "declined" ||
    lowerText === "rejected" ||
    lowerText === "cancelled" ||
    lowerText === "canceled" ||
    lowerText === "reconnect required" ||
    lowerText === "removed" ||
    lowerText === "failed" ||
    lowerText === "archived" ||
    lowerText === "no" ||
    lowerText === "awaiting signature"
  ) {
    return "red";
  }

  if (lowerText === "idle") {
    return "grey;";
  }
};

export const badgeColors = ["green", "yellow", "light-blue", "red", "grey"];
