import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import { FaComments, FaPauseCircle, FaPhone, FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import { Button, DropdownButton } from "ui";
import TeamMemberDetails from "./TeamMemberDetails";
import TeamMemberBenefitsAndAllowances from "../benefits/TeamMemberBenefitsAndAllowances";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import {
  AggregatedTeamMember,
  MiterAPI,
  MiterFilterArray,
  Note,
  UpdateTeamMemberParams,
} from "dashboard/miter";
import "./team.css";
import PostTaxDeductions from "../post-tax-deductions/PostTaxDeductions";
import { Alert, Warning } from "dashboard/components/icons/icons";
import { Toggler } from "ui";
import TeamMemberTimeOff from "./TeamMemberTimeOff";
import { DismissModal } from "./DismissModal";
import { capitalize, formatDate, Notifier } from "dashboard/utils";
import { RehireModal } from "./RehireModal";
import { Helmet } from "react-helmet";
import { TeamMemberPayments } from "./TeamMemberPayments";
import { SchedulingWrapper } from "dashboard/pages/scheduling/Scheduling";
import { CheckTM } from "backend/utils/check/check-types";
import { isTmActive } from "./TeamUtils";
import Notes from "dashboard/components/notes/Notes";
import TeamMemberDocumentsTable from "dashboard/components/documents/TeamDocumentsTable";
import { TeamMemberWithholdings } from "./TeamMemberWithholdings";
import {
  useRefetchTeam,
  useLookupTeam,
  useActiveCompany,
  useActiveCompanyId,
  useActiveTeam,
} from "dashboard/hooks/atom-hooks";
import { TeamMemberWorkplaces } from "./TeamMemberWorkplaces";
import TeamMemberWizard from "dashboard/components/team-members/TeamMemberWizard";
import { ArrowLeft, ArrowRight, CaretDown, PlusCircle } from "phosphor-react";
import { styles } from "ui/form/styles";
import { PerformanceReviewsTable } from "dashboard/components/performance/PerformanceReviewsTable";
import { ScreeningsTable } from "dashboard/components/screenings/ScreeningsTable";
import { useEmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";
import { useAllowanceAbilities } from "dashboard/hooks/abilities-hooks/useAllowanceAbilities";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { usePostTaxDeductionAbilities } from "dashboard/hooks/abilities-hooks/usePostTaxDeductionAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TeamMemberOnboarding } from "./onboarding/TeamMemberOnboarding";
import { useTimeOffRequestAbilities } from "dashboard/hooks/abilities-hooks/useTimeOffRequestAbilities";
import Banner from "dashboard/components/shared/Banner";
import { CertificationsTable } from "dashboard/components/certifications/CertificationsTable";
import { useHasAccessToChecklists, useHasAccessToOnboarding2_0 } from "dashboard/gating";
import { TeamMemberProfilePicture } from "miter-components";
import { TeamMemberPaymentInfo } from "dashboard/components/team-members/TeamMemberPaymentInfo";
import ChangeRequestsTable from "dashboard/components/change-requests/ChangeRequestsTable";
import { startCase } from "lodash";
import { DateTime } from "luxon";
import { LeaveOfAbsenceModal } from "./LeaveOfAbsenceModal";

const TeamMember: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const hasAccessToChecklists = useHasAccessToChecklists();
  const hasAccessToOnboarding2_0 = useHasAccessToOnboarding2_0();

  const lookupTeam = useLookupTeam();
  const refetchTeam = useRefetchTeam();
  const miterAbilities = useMiterAbilities();

  const timesheetAbilities = useTimesheetAbilities();
  const teamAbilities = useTeamAbilities();

  const timeOfRequestAbilities = useTimeOffRequestAbilities();
  const refetchTms = useRefetchTeam();
  const activeTeam = useActiveTeam();
  const accessibleTeam = useMemo(
    () => activeTeam.filter((tm) => teamAbilities.can("read", tm)),
    [activeTeam, teamAbilities]
  );

  // Hooks
  const { id, view, filter } = useParams<{ id: string; view: string; filter: string }>();
  const navigate = useNavigate();

  const benefitAbilities = useEmployeeBenefitAbilities();
  const allowanceAbilities = useAllowanceAbilities();
  const ptdAbilities = usePostTaxDeductionAbilities();

  // State
  const tm = lookupTeam(id);

  const [isDismissing, setIsDismissing] = useState(false);
  const [isSchedulingLeave, setIsSchedulingLeave] = useState(false);
  const [isRehiring, setIsRehiring] = useState(false);
  const [showEnrollInPayrollModal, setShowEnrollInPayrollModal] = useState(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [missingCertifications, setMissingCertifications] = useState(false);
  const [showResumeWizard, setShowResumeWizard] = useState(false);

  // Other
  const checkOnboard = tm?.check_tm?.onboard as CheckTM["onboard"] | undefined;

  /*** Returns whether the user is already on leave or has on leave scheduled for the future */
  const isScheduledForLeave = useMemo(() => {
    return !!tm?.on_leave && (!tm.on_leave?.end_date || DateTime.now().toISODate() < tm.on_leave?.end_date);
  }, [tm]);

  const updateTm = async (params: UpdateTeamMemberParams) => {
    if (!tm) return;
    try {
      const response = await MiterAPI.team_member.update(tm._id, params);
      if (response.error) throw new Error(response.error);
      Notifier.success("Team member updated successfully.");
      await refetchTms(tm._id);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`There was an error updating the team member: ${e.message}`);
    }
  };

  const getNotes = async () => {
    if (!activeCompanyId) return;
    try {
      const filter = [
        { field: "parent_id", value: tm?._id },
        { field: "parent_type", value: "team_member" },
        { field: "company_id", value: activeCompanyId! },
      ];

      const notes = await MiterAPI.notes.search(filter);

      setNotes(notes);
    } catch (e: $TSFixMe) {
      console.error("Unable to get notes", e);
      Notifier.error("We are unable to get notes at this time. Please try again later.");
    }
  };

  /********************************************************************
                             Backend
  *********************************************************************/

  const isDismissed = !!tm?.end_date;

  const determineMissingCertifications = async () => {
    if (!tm) return;
    try {
      const res = await MiterAPI.certifications.forage({
        filter: [
          { field: "company_id", value: tm.company._id },
          { field: "team_member_id", value: tm._id },
          { field: "submitted", value: false },
        ],
      });
      if (res.data.length > 0) {
        setMissingCertifications(true);
      }
    } catch (error) {
      Notifier.error("Failed to determine certification status.");
    }
  };

  useEffect(() => {
    determineMissingCertifications();
  }, [tm]);

  const getOnboardingLabel = () => {
    const isOnboarded = tm?.onboarding_status?.all_tasks_complete !== false;
    if (!isOnboarded) {
      return <Alert text="Onboarding" className="details" />;
    } else {
      return "Onboarding";
    }
  };

  const getPaymentInfoLabel = () => {
    const paymentMethodPrefNeedsAttention = tm?.check_tm?.onboard.payment_method?.includes(
      "payment_method_preference_set"
    );
    const bankAccountNeedsAttention = tm?.check_tm?.onboard.payment_method?.some((s) =>
      s.includes("bank_account")
    );

    if (paymentMethodPrefNeedsAttention || bankAccountNeedsAttention) {
      return <Alert text="Payment Info" className="details" />;
    } else {
      return "Payment info";
    }
  };

  const getWithholdingsLabel = () => {
    const checkTm = tm?.check_tm;
    const withholdingsNeedsAttention =
      checkTm &&
      (checkTm.onboard.remaining_steps.some((s) => s === "withholdings" || s === "ssn") ||
        ("employee_details" in checkTm.onboard && checkTm.onboard.employee_details?.includes("residence")));

    if (withholdingsNeedsAttention) {
      return <Alert text="Withholdings" className="details" />;
    } else {
      return "Withholdings";
    }
  };

  const getDetailsLabel = () => {
    if (isDismissed || teamAbilities.cannot("update", tm) || hasAccessToChecklists) return "Details";
    if (checkOnboard?.status === "blocking") {
      return <Alert text="Details" className="details" />;
    } else if (checkOnboard?.status === "needs_attention") {
      return <Warning text="Details" className="details" />;
    } else {
      return "Details";
    }
  };

  const renderOnboardingBanner = () => {
    if (
      isDismissed ||
      checkOnboard?.status === "completed" ||
      !tm?.check_id ||
      teamAbilities.cannot("update_sensitive", tm)
    ) {
      return null;
    }

    return (
      <div>
        <div className="vertical-spacer"></div>
        <Banner
          content={
            tm.first_name + " is not fully onboarded. Follow the prompts below to complete onboarding."
          }
          type="warning"
        />
      </div>
    );
  };

  const renderResumeWizardBanner = () => {
    if (tm?.resume_wizard_index == null) return;

    return (
      <div>
        <div className="vertical-spacer"></div>
        <Banner
          content={`Click here to finish setting up ${tm.first_name}'s profile.`}
          type="info"
          onClick={handleResumeWizard}
        />
      </div>
    );
  };

  const timesheetsFilters: MiterFilterArray = [{ field: "team_member", type: "string", value: id }];
  const hasSensitiveReads = teamAbilities.can("read_sensitive", tm);

  const togglerConfig = useMemo(() => {
    if (!tm || !activeCompanyId) return [];

    return [
      {
        path: "details",
        label: getDetailsLabel(),
      },
      {
        path: "onboarding",
        label: getOnboardingLabel(),
        // TODO: Team member without permission can potentially be assigned a new hire checklist task
        hide: !hasSensitiveReads || !hasAccessToChecklists,
      },
      {
        path: "timesheets",
        label: "Timesheets",
        hide: !timesheetAbilities.teamPredicate("read")(tm),
      },
      {
        path: "payment-info",
        label: getPaymentInfoLabel(),
        hide: teamAbilities.cannot("read_sensitive", tm),
      },
      {
        path: "payments",
        label: "Payments",
        hide: !hasSensitiveReads || !tm?.check_tm,
      },
      {
        path: "benefits",
        label: "Benefits",
        hide:
          !hasSensitiveReads ||
          !tm?.check_tm ||
          (!benefitAbilities.teamPredicate("read")(tm) && !allowanceAbilities.teamPredicate("read")(tm)),
      },
      {
        path: "post-tax-deductions",
        label: "Deductions",
        hide:
          !ptdAbilities.teamPredicate("read")(tm) ||
          teamAbilities.cannot("read_sensitive", tm) ||
          !tm?.check_tm ||
          tm.employment_type !== "employee",
      },
      {
        path: "time-off",
        label: "Time off",
        hide: !timeOfRequestAbilities.teamPredicate("read")(tm),
      },
      {
        path: "withholdings",
        label: getWithholdingsLabel(),
        hide:
          teamAbilities.cannot("read_sensitive", tm) || !tm?.check_tm || tm.employment_type !== "employee",
      },
      {
        label: "Workplaces",
        path: "workplaces",
        hide:
          teamAbilities.cannot("read_sensitive", tm) || !tm?.check_tm || tm.employment_type !== "employee",
      },
      {
        label: "Documents",
        path: "documents",
        hide:
          miterAbilities.cannot("documents:team_member:read_sensitive") &&
          miterAbilities.cannot("documents:team_member:read"),
      },
      {
        label: `Notes ${notes.length > 0 ? `(${notes.length})` : ""}`,
        path: "notes",
        hide: teamAbilities.cannot("read_sensitive", tm),
      },
      {
        label: "Performance",
        path: "performance-reviews",
        hide:
          teamAbilities.cannot("read_sensitive", tm) ||
          miterAbilities.cannot("performance:reviews:others:read"),
      },
      {
        label: "Background checks",
        path: "background-checks",
        hide: teamAbilities.cannot("read_sensitive", tm),
      },
      {
        label: missingCertifications ? <Alert text="Certifications" /> : "Certifications",
        path: "certifications",
        hide: miterAbilities.cannot("certifications:certifications:read"),
      },
      {
        label: "Change requests",
        path: "change-requests",
        hide: teamAbilities.cannot("read_sensitive", tm),
      },
    ];
  }, [tm, teamAbilities, timesheetAbilities, benefitAbilities, allowanceAbilities, miterAbilities]);

  const handleToggle = (option) => {
    navigate("/team-members/" + id + "/" + option);
  };

  const handleResumeWizard = () => {
    setShowResumeWizard(true);
  };

  /*********************************************************
    useEffect functions
  **********************************************************/
  useEffect(() => {
    if (!view) {
      navigate("details", { replace: true });
    }
  }, [view]);

  useEffect(() => {
    refetchTeam(id);
    getNotes();
  }, []);

  useEffect(() => {
    // If the user is not allowed to view the current view, redirect to home
    if (view && !togglerConfig.find((config) => config.path === view && !config.hide)) {
      Notifier.error("You do not have permission to view this page.");
      navigate("/home");
    }
  }, [view, togglerConfig]);

  useEffect(() => {
    if (tm && teamAbilities.cannot("read", tm)) {
      Notifier.error("You do not have permission to view this team member.");
      navigate("/home");
    }
  }, [teamAbilities]);

  /*********************************************************
    Helper variables
  **********************************************************/

  const employeeType = tm?.employment_type ? capitalize(tm.employment_type) : "";

  const getEmployeeLoc = () => {
    if (tm && tm.address?.city && tm.address?.state) {
      return tm.address.city + ", " + tm.address.state;
    } else {
      return "-";
    }
  };
  const employeeLoc = getEmployeeLoc();

  const handleDismissRehireHide = () => {
    setIsRehiring(false);
    setIsDismissing(false);
    refetchTeam(id);
  };

  const handleLeaveOfAbsenceHide = () => {
    setIsSchedulingLeave(false);
    refetchTeam(id);
  };

  const cleanedView = view
    ?.split("-")
    ?.map((str) => capitalize(str))
    ?.join(" ");

  const title = tm ? `${tm.full_name}'s ${cleanedView}` : "Team Member";

  const renderEnrollInPayrollModal = () => {
    if (tm?.check_id) return;

    // Modal with the following fields prefilled with employee data: first name, last name, email, address, dob, start date
    return (
      <TeamMemberWizard
        teamMember={tm}
        mode="enroll_in_payroll"
        onComplete={async () => {
          Notifier.success("Team member enrolled in payroll successfully.");
          setShowEnrollInPayrollModal(false);
        }}
        onExit={async () => {
          setShowEnrollInPayrollModal(false);
        }}
      />
    );
  };

  const renderActions = () => {
    if (teamAbilities.cannot("update", tm) && teamAbilities.cannot("terminate", tm)) return;

    const actions = [
      {
        label: "Enroll in payroll",
        action: () => setShowEnrollInPayrollModal(true),
        icon: <PlusCircle style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => teamAbilities.can("update", tm) && !tm?.check_id,
      },
      {
        label: "Rehire",
        action: () => setIsRehiring(true),
        icon: <FaSignInAlt style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => !!tm?.end_date && teamAbilities.can("terminate", tm),
      },
      {
        label: "Dismiss",
        action: () => setIsDismissing(true),
        icon: <FaSignOutAlt style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => !tm?.end_date && teamAbilities.can("terminate", tm),
      },
      {
        label: "Schedule leave of absence",
        action: () => setIsSchedulingLeave(true),
        icon: <FaPauseCircle style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () =>
          !!tm?.start_date && miterAbilities.can("time_off:leave:manage") && !isScheduledForLeave,
      },
    ];

    return (
      <div className={styles["actions"]}>
        <DropdownButton
          className={"button-1"}
          options={actions}
          closeOnClick={true}
          buttonStyle={{ marginLeft: "20px" }}
        >
          Actions
          <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
        </DropdownButton>
      </div>
    );
  };

  if (!tm) return <Navigate to="/404" replace />;
  if (!activeCompanyId) return null;

  const currentTeamMemberIndex = accessibleTeam.findIndex((teamMember) => teamMember._id === tm._id);
  const prevTeamMember = currentTeamMemberIndex > 0 ? accessibleTeam[currentTeamMemberIndex - 1] : null;
  const nextTeamMember =
    currentTeamMemberIndex < accessibleTeam.length - 1 ? accessibleTeam[currentTeamMemberIndex + 1] : null;

  return (
    <>
      <Helmet>
        <title>{title} | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex-column ">
          <div className="flex">
            <TeamMemberProfilePicture teamMember={tm} refetchTeam={refetchTeam} />
            <div className="flex space-between width-100-percent " style={{ marginLeft: 25 }}>
              <div>
                <h1 style={{ marginTop: -3 }}>{tm.full_name}</h1>
                <div className={"member-info"}>
                  <span>
                    {employeeType}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{employeeLoc}
                  </span>
                  {tm.phone && (
                    <>
                      <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                      <Link
                        to={tm.conversation ? "/chat/" + tm.conversation : "/chat"}
                        className={"icon-link"}
                      >
                        <FaComments style={{ marginBottom: -2 }} title="Chat" />
                      </Link>
                      &nbsp;&nbsp;
                      <a className={"icon-link"} href={"tel:" + tm.phone}>
                        <FaPhone style={{ fontSize: 16, marginBottom: -2 }} title="Call" />
                      </a>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-row align-self-top">
                {isTmActive(tm) && (
                  <div>
                    {prevTeamMember && (
                      <button
                        className="button-1"
                        onClick={() => navigate(`/team-members/${prevTeamMember._id}`)}
                      >
                        <ArrowLeft style={{ marginRight: 3, marginTop: 3, marginBottom: -2 }} />
                        {prevTeamMember.full_name}
                      </button>
                    )}
                    {nextTeamMember && (
                      <button
                        className="button-1"
                        onClick={() => navigate(`/team-members/${nextTeamMember._id}`)}
                      >
                        {nextTeamMember.full_name}
                        <ArrowRight style={{ marginLeft: 3, marginTop: 3, marginBottom: -2 }} />
                      </button>
                    )}
                  </div>
                )}
                {renderActions()}
              </div>
            </div>
          </div>
        </div>

        {!hasAccessToChecklists && renderOnboardingBanner()}
        {/* Hide the resume wizard banner unless the user has access to onboarding checklists 2.0 */}
        {hasAccessToOnboarding2_0 && renderResumeWizardBanner()}

        {isDismissed && <DismissalBanner tm={tm} setIsDismissing={setIsDismissing} />}
        {isSchedulingLeave && <LeaveOfAbsenceModal tm={tm} hide={handleLeaveOfAbsenceHide} />}
        {isDismissing && <DismissModal tm={tm} hide={handleDismissRehireHide} />}
        {isRehiring && <RehireModal tm={tm} hide={handleDismissRehireHide} />}
        {showResumeWizard && (
          <TeamMemberWizard
            teamMember={tm}
            onComplete={async () => {
              Notifier.success("Team member profile completed.");
              setShowResumeWizard(false);
            }}
            onExit={async () => {
              setShowResumeWizard(false);
            }}
          />
        )}

        <div className="toggler-vertical-container team-member-container">
          <Toggler
            config={togglerConfig}
            toggle={handleToggle}
            active={view}
            type="vertical"
            className="team-member-toggler"
          />
          <div className="toggler-right-content">
            <div className="view" style={{ flexGrow: 10 }}>
              {tm && view === "details" && <TeamMemberDetails teamMember={tm} />}
              {tm && view === "onboarding" && <TeamMemberOnboarding teamMember={tm} />}
              {view === "timesheets" && (
                <TimesheetsTable
                  defaultFilters={timesheetsFilters}
                  showToggler={true}
                  activeTeamMember={tm}
                />
              )}
              {view === "schedule" && <SchedulingWrapper teamMember={tm} />}
              {view === "benefits" && tm.check_tm && <TeamMemberBenefitsAndAllowances teamMember={tm} />}
              {view === "payments" && tm.check_tm && !filter && <TeamMemberPayments tm={tm} />}
              {view === "post-tax-deductions" && tm.check_tm && <PostTaxDeductions teamMember={tm} />}
              {view === "time-off" && <TeamMemberTimeOff teamMember={tm} />}
              {view === "withholdings" && tm.check_tm && <TeamMemberWithholdings teamMember={tm} />}
              {view === "notes" && <Notes parentId={tm._id} parentType={"team_member"} />}
              {view === "documents" && <TeamMemberDocumentsTable teamMember={tm} />}
              {view === "workplaces" && tm.check_tm && <TeamMemberWorkplaces tm={tm} updateTm={updateTm} />}
              {view === "performance-reviews" && <PerformanceReviewsTable teamMember={tm} />}
              {/* Need to set up a Checkr application in a staging environment */}
              {view === "background-checks" && <ScreeningsTable teamMember={tm} />}
              {view === "certifications" && <CertificationsTable teamMemberId={tm._id} />}
              {view === "payment-info" && <TeamMemberPaymentInfo teamMember={tm} />}
              {view === "change-requests" && (
                <ChangeRequestsTable parentType="team_member" parentId={tm._id} />
              )}
            </div>
          </div>
          {showEnrollInPayrollModal && renderEnrollInPayrollModal()}
        </div>
      </div>
    </>
  );
};

export default TeamMember;

const DismissalBanner: React.FC<{
  tm: AggregatedTeamMember;
  setIsDismissing: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ tm, setIsDismissing }) => {
  const activeCompany = useActiveCompany();

  const isDismissed = !!tm?.end_date;
  const dismissals = (isDismissed && tm?.dismissals) || [];
  const lastDismissal = dismissals[dismissals.length - 1];
  const dismissNote = lastDismissal?.note || "";
  const dismissInvoluntary = !!lastDismissal?.involuntary;
  const dismissEligibleForRehire = lastDismissal?.eligible_for_rehire;
  const dismissLastPayday = lastDismissal?.last_payday;
  const dismissTerminationReason = lastDismissal?.termination_reason || "";

  return (
    <div className="dismissal-banner">
      <div>
        <div className="flex">
          {tm.first_name +
            ` is ${
              !lastDismissal ? "" : dismissInvoluntary ? "involuntarily " : "voluntarily "
            }dismissed from ${activeCompany?.check_company.trade_name} as of ${formatDate(
              tm.end_date,
              undefined,
              true
            )}.`}
          <div className="flex-1" />
          <Button text="Edit" onClick={() => setIsDismissing(true)} />
        </div>
        <div style={{ marginTop: "15px" }} />
        {dismissEligibleForRehire !== undefined && (
          <>
            <strong>Eligible for Rehire: </strong>
            {dismissEligibleForRehire ? "Yes" : "No"}
          </>
        )}
        <div style={{ marginTop: "5px" }} />
        {dismissLastPayday && (
          <>
            <strong>Last Payday: </strong>
            {formatDate(dismissLastPayday, undefined, true)}
          </>
        )}
        <div style={{ marginTop: "5px" }} />
        {dismissTerminationReason && (
          <>
            <strong>Termination Reason: </strong>
            {startCase(dismissTerminationReason)}
          </>
        )}
        <div style={{ marginTop: "5px" }} />
        {dismissNote && (
          <>
            <strong>Note: </strong>
            {dismissNote}
          </>
        )}
      </div>
    </div>
  );
};
