import {
  useTeamMemberGroups,
  GroupTypeHasOptions,
  SelectedTeamMemberGroups,
} from "dashboard/hooks/useTeamMemberGroups";
import { cloneDeep, set } from "lodash";
import { RequireKeys } from "miter-utils";
import { X } from "phosphor-react";
import { useMemo } from "react";
import { Formblock, Button } from "ui";
import React from "react";
import { Option } from "ui/form/Input";
import { AndOrOptions } from "./PolicyConstants";

import styles from "./PolicyRules.module.css";
import { ApprovalFlowForm } from "./PolicyRuleBuilder";
import { ApprovalFlowStageForm } from "./ApprovalFlowBuilder";
import { ApproverGroupType } from "backend/services/approvals/types";

type ApprovalStageProps = {
  approvalFlow: ApprovalFlowForm;
  setApprovalFlow: React.Dispatch<React.SetStateAction<ApprovalFlowForm>>;
  stage: ApprovalFlowStageForm;
  index: number;
  removeJobApprovers?: boolean;
};

export const ApprovalStage: React.FC<ApprovalStageProps> = ({
  approvalFlow,
  setApprovalFlow,
  stage,
  index,
  removeJobApprovers,
}) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const selections = useMemo(() => buildSelections(stage), [stage]);
  const { groupOptions, getOptions } = useTeamMemberGroups(selections);

  const filteredGroupOptions = useMemo(() => {
    let tempGroupOptions = [...groupOptions];

    // if time off policy, remove job approvers
    tempGroupOptions = removeJobApprovers
      ? groupOptions.filter((option) => {
          return !option.value?.includes("job");
        })
      : groupOptions;

    // for all policies, remove "crew" as an option for approvers
    return tempGroupOptions.filter((option) => {
      return option.value !== "crew";
    });
  }, [removeJobApprovers, groupOptions]);
  /*********************************************************
   *  Handler functions
   **********************************************************/
  const handleRemoveApprover = (stage: ApprovalFlowStageForm, index: number) => {
    const newApprovers = stage.approvers.filter((_, i) => i !== index);
    const newStage = { ...stage, approvers: newApprovers };

    let newApprovalFlow;
    if (newApprovers.length === 0) {
      newApprovalFlow = approvalFlow.filter((s) => s._id !== stage._id);
    } else {
      newApprovalFlow = approvalFlow.map((s) => (s._id === stage._id ? newStage : s));
    }

    setApprovalFlow(newApprovalFlow);
  };

  const handleApproverTypeChange = (
    type: Option<ApproverGroupType> | undefined,
    stageIndex: number,
    approverIndex: number
  ) => {
    setApprovalFlow((prev) => {
      const newApprovalFlow = cloneDeep(prev);
      set(newApprovalFlow, `[${stageIndex}].approvers[${approverIndex}].type`, type);
      set(newApprovalFlow, `[${stageIndex}].approvers[${approverIndex}].value`, undefined);
      return newApprovalFlow;
    });
  };

  const handleApproverValueChange = (
    value: Option<string> | undefined,
    stageIndex: number,
    approverIndex: number
  ) => {
    setApprovalFlow((prev) => {
      const newApprovalFlow = cloneDeep(prev);
      set(newApprovalFlow, `[${stageIndex}].approvers[${approverIndex}].value`, value);
      return newApprovalFlow;
    });
  };

  const handleApproverConditionChange = (condition: Option<string> | undefined, stageIndex: number) => {
    setApprovalFlow((prev) => {
      const newApprovalFlow = cloneDeep(prev);
      set(newApprovalFlow, `[${stageIndex}].condition`, condition);
      return newApprovalFlow;
    });
  };

  const renderApprover = (
    stageIndex: number,
    approver: ApprovalFlowStageForm["approvers"][number],
    index: number
  ) => {
    const hasOptions = GroupTypeHasOptions(approver.type?.value);
    const groupValueOptions = getOptions(approver.type?.value);

    return (
      <>
        <Formblock
          type="select"
          name={`approval_flow[${stageIndex}]approvers[${index}]type`}
          editing={true}
          className="modal approval-flow"
          placeholder={"approver"}
          rules={{ required: "Type" }}
          options={filteredGroupOptions}
          style={{ marginRight: 15, width: 200 }}
          defaultValue={approver.type?.value}
          value={approver.type}
          onChange={(o) => handleApproverTypeChange(o, stageIndex, index)}
        />
        {hasOptions && (
          <>
            <span style={{ marginRight: 15 }}>is</span>
            <Formblock
              type="select"
              name={`approval_flow[${stageIndex}]approvers[${index}]value`}
              editing={true}
              className="modal approval-flow"
              placeholder={`Select ${approver.type?.value?.toLocaleLowerCase().replaceAll("_", " ")}`}
              rules={{ required: "Value is required" }}
              options={groupValueOptions}
              style={{ marginRight: 15, width: 200 }}
              defaultValue={approver.value?.value}
              value={approver.value}
              isClearable={true}
              onChange={(o) => handleApproverValueChange(o, stageIndex, index)}
            />
          </>
        )}
      </>
    );
  };

  const renderApproverCondition = (
    stage: ApprovalFlowStageForm,
    stageIndex: number,
    approverIndex: number
  ) => {
    // If there are less than 2 approvers, don't show the condition OR if they are the last approver
    if (approverIndex === 0) return;
    if (stage.approvers.length < 2) return;
    if (stage.approvers.length === approverIndex) return;

    return (
      <Formblock
        type="select"
        name={`[${stageIndex}]condition`}
        editing={true}
        className="modal approval-flow"
        placeholder={"operator"}
        rules={{ required: "Operator is required" }}
        options={AndOrOptions}
        style={{ marginRight: 15, width: 100 }}
        defaultValue={stage.condition?.value}
        value={stage.condition}
        onChange={(o) => handleApproverConditionChange(o, stageIndex)}
      />
    );
  };

  const renderRemoveApprover = (stage: ApprovalFlowStageForm, index: number) => {
    return (
      <Button
        className="button-text"
        style={{ marginLeft: -5, color: "#777" }}
        onClick={() => handleRemoveApprover(stage, index)}
      >
        <X />
      </Button>
    );
  };

  const renderStageRow = (stage: ApprovalFlowStageForm, stageIndex: number) => {
    return stage.approvers.map((approver, index) => {
      return (
        <div key={approver._id} className={"flex " + styles["approver"]}>
          {renderApproverCondition(stage, stageIndex, index)}
          {renderApprover(stageIndex, approver, index)}
          {renderRemoveApprover(stage, index)}
        </div>
      );
    });
  };

  return (
    <>
      <div className="relative flex">
        <strong className={styles["stage-number"]}>{index + 1}</strong>
        <div className={"relative flex flex-wrap width-100-percent " + styles["approvers"]}>
          {renderStageRow(stage, index)}
        </div>
      </div>
    </>
  );
};

const buildSelections = (stage: ApprovalFlowStageForm) => {
  return stage.approvers.reduce((acc, approver) => {
    if (approver?.value) {
      if (approver.type?.value === "department") {
        acc.departments.push(approver.value);
      } else if (approver.type?.value === "team_member") {
        acc.teamMembers.push(approver.value);
      } else if (approver.type?.value === "crew") {
        acc.crews.push(approver.value);
      } else if (approver.type?.value === "title") {
        acc.titles.push(approver.value);
      } else if (approver.type?.value === "employment_type") {
        acc.employmentTypes.push(approver.value);
      } else if (approver.type?.value === "pay_type") {
        acc.payTypes.push(approver.value);
      }
    } else {
      if (approver.type?.value === "crew_lead") {
        acc.crewLead = true;
      } else if (approver.type?.value === "job_supervisor") {
        acc.jobSupervisor = true;
      } else if (approver.type?.value === "job_superintendent") {
        acc.jobSuperintendent = true;
      } else if (approver.type?.value === "direct_manager") {
        acc.directManager = true;
      } else if (approver.type?.value === "department_head") {
        acc.departmentHead = true;
      } else if (approver.type?.value === "all_team_members") {
        acc.allTeamMembers = true;
      } else if (approver.type?.value === "self") {
        acc.self = true;
      }
    }

    return acc;
  }, cloneDeep(baseSelections));
};

const baseSelections: RequireKeys<SelectedTeamMemberGroups, keyof SelectedTeamMemberGroups> = {
  departments: [],
  teamMembers: [],
  crews: [],
  titles: [],
  employmentTypes: [],
  payTypes: [],
  allTeamMembers: false,
  jobSupervisor: false,
  jobSuperintendent: false,
  directManager: false,
  crewLead: false,
  departmentHead: false,
  self: false,
};
