import { ActionModal, ClickableText, Loader, Notifier } from "ui";
import React, { useState } from "react";
import { Option } from "ui/form/Input";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import checkmark from "dashboard/assets/check-mark.png";
import { Navigate } from "react-router-dom";
import { IntacctConnectionMetadata } from "backend/services/intacct/intacct-types";
import { useFetchIntacctObjects } from "dashboard/hooks/integrations/sage_intacct/useFetchIntacctObjects";
import { IntegrationConnection, MiterIntegrationForCompany } from "dashboard/miter";

export const IntacctEntitySelector: React.FC<{
  integration: MiterIntegrationForCompany;
  updateIntegrationConnection: (
    update: Partial<IntegrationConnection> | Record<string, $TSFixMe>
  ) => Promise<void>;
}> = ({ integration, updateIntegrationConnection }) => {
  const [options, setOptions] = useState<Option<string>[]>();
  const [connecting, setConnecting] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<string>();
  const [error, setError] = useState<string>();
  const [hide, setHide] = useState(
    !integration.connection ||
      integration.connection.integration_key !== "sage_intacct" ||
      !!integration.connection.metadata?.sage_intacct?.entity?.defaultId ||
      false
  );

  const intacctMetadata = integration.connection?.metadata?.sage_intacct as
    | IntacctConnectionMetadata
    | undefined;

  const connectIntacctEntity = async () => {
    if (!intacctMetadata) return;
    setConnecting(true);
    try {
      if (!selectedEntity) throw new Error("Entity ID not found.");
      const entity = options?.find((co) => co.value === selectedEntity);
      if (!entity) throw new Error("Entity not found.");

      await updateIntegrationConnection({
        "metadata.sage_intacct.entity.defaultId": entity.value,
      });
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setConnecting(false);
  };

  useFetchIntacctObjects({
    integrationConnectionId: integration.connection?._id,
    disabled: !!intacctMetadata?.entity?.defaultId,
    objectTypes: ["entities"],
    onData: (intacctData) => {
      if (!intacctData.entities) return;
      const optionList = intacctData.entities.map((entity) => {
        return { value: entity.LOCATIONID, label: entity.NAME, obj: entity };
      });
      setSelectedEntity(optionList[0]?.value);
      setOptions(optionList);
    },
  });

  if (!integration.connection?._id) return <Navigate to="/integrations" />;

  if (hide) return null;

  const onHide = () => {
    setHide(true);
    setError(undefined);
  };

  if (error) {
    return (
      <ActionModal
        headerText="Error getting Intacct entity list"
        onHide={onHide}
        submitText="Okay"
        onSubmit={onHide}
        showSubmit
        wrapperStyle={{ width: 510 }}
      >
        <div className="vertical-spacer" />
        There was an error getting your list of Intacct entities in order to associate with this Miter
        company. Please check your Intacct settings per{" "}
        <ClickableText
          text="our guide"
          onClick={() => goToMiterGuide("integrations/accounting/sage-intacct")}
        />{" "}
        and try again.
        <div className="vertical-spacer" />
        <div className="red-text-container">{error}</div>
        <div className="vertical-spacer" />
      </ActionModal>
    );
  }

  const renderModalBody = () => {
    if (!options) return <Loader />;

    return (
      <>
        <div className="yellow-text-container">
          Please associate this Miter company with one of your Intacct entities. This will determine the
          default location associated with new employees and the base location for journal entries. We
          strongly recommend choosing the entity with the EIN that corresponds to this Miter company.
        </div>
        <div className="vertical-spacer"></div>
        <div style={{ maxHeight: 280, overflowY: "auto" }}>
          {options.map((c) => {
            const selected = selectedEntity === c.value;
            return (
              <div
                onClick={() => setSelectedEntity(c.value)}
                className={`${selected ? "selected" : ""} calculation_option_wrapper flex`}
                key={c.value}
              >
                <div>
                  <div style={{ fontSize: 17, fontWeight: selected ? "bold" : "" }}>{c.label}</div>
                  <div style={{ fontSize: 14, color: "darkslategray" }}>Entity ID: {c.value}</div>
                </div>

                <div className="flex-1"></div>
                {selected && <img src={checkmark} style={{ height: 15 }} />}
              </div>
            );
          })}
        </div>

        <div className="vertical-spacer"></div>
      </>
    );
  };

  return (
    <ActionModal
      headerText="Set an Intacct entity"
      onSubmit={connectIntacctEntity}
      submitText="Connect"
      submitDisabled={!options}
      onHide={onHide}
      showSubmit={true}
      loading={connecting}
      wrapperStyle={{ width: 510 }}
    >
      <div className="vertical-spacer"></div>
      {renderModalBody()}
    </ActionModal>
  );
};
