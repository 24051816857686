import { useCallback } from "react";
import {
  PermissionGroupSubModule,
  PermissionGroupModuleScopeType,
  PermissionPaths,
} from "backend/models/permission-group";
import { MiterFilterField } from "backend/utils";
import { useTeam, useJobs, useActiveTeamMember, useIsSuperAdmin, useJobPostings } from "../atom-hooks";
import { useJobScopesHelpers } from "./useJobScopes";
import { useMiterAbilities } from "./useMiterAbilities";
import { useTeamMemberScopesHelpers } from "./useTeamMemberScopes";
import { useJobPostingScopesHelpers } from "./useJobPostingScopes";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type FilterMetadata = { fieldName: string; fieldType: MiterFilterField["type"] };

/**
 * This hook returns a function that takes an action string and returns a MiterFilterField that can be used
 * to as a query to filter down the dashboard data based on the team member's permissions.
 */
export const useAbilitiesBackendFilter = (params: {
  permissionPath?: PermissionGroupSubModule;
  personalPermissionPath?: string;
  othersPermissionPath?: string;
  teamMemberField?: FilterMetadata;
  jobField?: FilterMetadata;
  inboxMode?: InboxMode;
  appModule?: PermissionGroupModuleScopeType;
  modules?: {
    jobPostingField?: FilterMetadata;
  };
}): ((action: string) => MiterFilterField | undefined) => {
  const {
    permissionPath,
    personalPermissionPath,
    othersPermissionPath,
    teamMemberField,
    jobField,
    inboxMode,
    appModule,
    modules,
  } = params;

  const teamMembers = useTeam();
  const allJobs = useJobs();
  const allJobPostings = useJobPostings();
  const activeTeamMember = useActiveTeamMember();
  const isSuperAdmin = useIsSuperAdmin();

  const { can } = useMiterAbilities();
  const { getTeamMemberIdsForPermission } = useTeamMemberScopesHelpers();
  const { getJobIdsForPermission } = useJobScopesHelpers();
  const { getJobPostingIdsForPermission } = useJobPostingScopesHelpers();

  const buildTeamMemberDataFilter = useCallback(
    (action): MiterFilterField | undefined => {
      // If the user is a super admin, they can read everything
      if (isSuperAdmin) return;

      // If we are in inbox mode, we can read everything
      if (inboxMode) return;

      // If there is no team member field, that means we don't filter on team member field
      if (!teamMemberField) return;

      // If there is no app module specified, then we don't filter
      if (!appModule) return;

      const { fieldName, fieldType } = teamMemberField;

      if (personalPermissionPath && othersPermissionPath) {
        const personalPermission = (personalPermissionPath + ":" + action) as PermissionPaths;
        const othersPermission = (othersPermissionPath + ":" + action) as PermissionPaths;

        const hasPersonalPermission = can(personalPermission);
        const hasOthersPermission = can(othersPermission);
        const teamMemberIdsSet = new Set<string>();

        // If we have personal permission access, add our own team member id
        if (hasPersonalPermission && activeTeamMember) {
          teamMemberIdsSet.add(activeTeamMember._id);
        }

        if (hasOthersPermission) {
          const teamMemberIdsForPermission = getTeamMemberIdsForPermission(othersPermission, appModule);
          teamMemberIdsForPermission.forEach((id) => teamMemberIdsSet.add(id));
        }

        // If we have access to all team members, don't filter
        if (teamMemberIdsSet.size === teamMembers.length) return;

        return {
          field: fieldName,
          type: fieldType,
          value: [...teamMemberIdsSet],
          comparisonType: "in",
        } as MiterFilterField;
      } else if (permissionPath) {
        const permission = (permissionPath + ":" + action) as PermissionPaths;
        const teamMemberIdsForPermission = getTeamMemberIdsForPermission(permission, appModule);

        // If we have access to all team members, don't filter
        if (teamMemberIdsForPermission.length === teamMembers.length) return;

        return {
          field: fieldName,
          type: fieldType,
          value: teamMemberIdsForPermission,
          comparisonType: "in",
        } as MiterFilterField;
      }
    },
    [can, activeTeamMember, getTeamMemberIdsForPermission, isSuperAdmin]
  );

  const buildJobDataFilter = useCallback(
    (action): MiterFilterField | undefined => {
      // If the user is a super admin, they can read everything
      if (isSuperAdmin) return;

      // If we are in inbox mode, we can read everything
      if (inboxMode) return;

      // If there is no job field, that means we don't filter on job field
      if (!jobField) return;

      // If there is no app module specified, then we don't filter
      if (!appModule) return;

      const { fieldName, fieldType } = jobField;

      const othersPermission = (othersPermissionPath + ":" + action) as PermissionPaths;
      const hasOthersPermission = can(othersPermission);
      const jobIdsSet = new Set<string>();

      // If the current team member can see other people's data, get the job ids they can see for this action
      if (hasOthersPermission) {
        const jobIdsForPermission = getJobIdsForPermission(othersPermission, appModule);
        jobIdsForPermission.forEach((id) => jobIdsSet.add(id));
      }

      // If we have access to all jobs, don't filter
      if (jobIdsSet.size === allJobs.length) return;

      return {
        field: fieldName,
        type: fieldType,
        value: [...jobIdsSet],
        comparisonType: "in",
      } as MiterFilterField;
    },
    [can, activeTeamMember, getTeamMemberIdsForPermission, isSuperAdmin]
  );

  const buildJobPostingScopesFilter = useCallback(
    (action): MiterFilterField | undefined => {
      const jobPostingField = modules?.jobPostingField;
      if (!jobPostingField) return;
      const jobPostingIdsSet = new Set<string>();

      const { fieldName, fieldType } = jobPostingField;

      // If there is no permission path specified, then we don't filter
      if (!permissionPath) return;

      const permission = (permissionPath + ":" + action) as PermissionPaths;

      const jobPostingIdsForPermission = getJobPostingIdsForPermission(permission);
      jobPostingIdsForPermission.forEach((id) => jobPostingIdsSet.add(id));

      // If we have access to all job postings, don't filter
      if (jobPostingIdsSet.size === allJobPostings.length) return;

      return {
        field: fieldName,
        type: fieldType,
        value: [...jobPostingIdsSet],
        comparisonType: "in",
      } as MiterFilterField;
    },
    [can, activeTeamMember, getTeamMemberIdsForPermission, isSuperAdmin]
  );

  const buildModulesScopesFilters = useCallback(
    (action): MiterFilterField[] | undefined => {
      // If the user is a super admin, they can read everything
      if (isSuperAdmin) return;

      // If we are in inbox mode, we can read everything
      if (inboxMode) return;

      const jobPostingScopesFilter = buildJobPostingScopesFilter(action);
      if (jobPostingScopesFilter) return [jobPostingScopesFilter];
    },
    [can, activeTeamMember, getTeamMemberIdsForPermission, isSuperAdmin, buildJobPostingScopesFilter]
  );

  return useCallback(
    (action) => {
      const filters: MiterFilterField[] = [];
      if (isSuperAdmin) return;

      /** Global scopes */
      const teamMemberFilter = buildTeamMemberDataFilter(action);
      if (teamMemberFilter) filters.push(teamMemberFilter);

      const jobFilter = buildJobDataFilter(action);
      if (jobFilter) filters.push(jobFilter);

      /** Module scopes */
      const moduleScopesFilter = buildModulesScopesFilters(action);
      if (moduleScopesFilter) filters.push(...moduleScopesFilter);

      // if neither team member nor job filter is present, return undefined
      if (filters.length === 0) return;

      return { type: "or", value: filters };
    },
    [buildTeamMemberDataFilter, buildJobDataFilter, isSuperAdmin]
  );
};
