import React, { useState, useEffect } from "react";
import { ClickableText, TableV2 } from "ui";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Helmet } from "react-helmet";
import { capitalize } from "lodash";
import { downloadBlob } from "dashboard/utils";
import { CheckCompanyTaxDocument } from "backend/utils/check/check-types";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Download } from "phosphor-react";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type CompanyTaxTableEntry = CheckCompanyTaxDocument & {
  _id: string;
  quarterClean: string;
  downloadButton: JSX.Element;
};

const CompanyTaxForms: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<CompanyTaxTableEntry[]>();
  const [selectedRows, setSelectedRows] = useState<CompanyTaxTableEntry[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);

  const getForms = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.companies.documents.retrieve_all_tax(activeCompanyId!);
      if (response.error) throw new Error(response.error);

      const newForms = response.map((form) => {
        return {
          ...form,
          quarterClean: form.quarter ? capitalize(form.quarter) : "-",
          _id: form.id,
          downloadButton: <ClickableText text="Download" onClick={() => download([form])} />,
        };
      });
      setTableData(newForms);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving the company's tax forms.");
    }
    setLoading(false);
  };

  const download = async (forms: CheckCompanyTaxDocument[]) => {
    setDownloading(true);
    try {
      await Promise.all(
        forms.map((form) =>
          MiterAPI.companies.documents
            .download(activeCompanyId!, form.id)
            .then((res) => res.blob())
            .then((blob) => {
              const fileName = `${form.year}${form.quarter ? " " + capitalize(form.quarter) : ""} ${
                form.label
              }.pdf`;
              downloadBlob(blob, fileName);
            })
        )
      );

      Notifier.success("Tax forms downloaded successfully.");
      setSelectedRows([]);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error downloading forms. We're looking into it!");
    }
    setDownloading(false);
  };

  const handleBulkDownload = () => {
    const selectedRowIds = selectedRows.map((r) => r._id);
    const forms = tableData?.filter((f) => selectedRowIds.includes(f._id)) || [];
    download(forms);
  };

  const dynamicActions: TableActionLink[] =
    selectedRows.length > 0
      ? [
          {
            label: "Download",
            action: handleBulkDownload,
            loading: downloading,
            className: "button-2 no-margin table-button",
            icon: <Download weight="bold" style={{ marginRight: 3 }} />,
          },
        ]
      : [];

  useEffect(() => {
    getForms();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Tax Forms | Miter</title>
      </Helmet>
      <TableV2
        id="company-tax-forms"
        resource="tax forms"
        data={tableData}
        columns={columns}
        onSelect={(rows) => setSelectedRows(rows as CompanyTaxTableEntry[])}
        defaultSelectedRows={selectedRows}
        dynamicActions={dynamicActions}
        isLoading={loading}
      />
    </div>
  );
};

export default CompanyTaxForms;

const columns: ColumnConfig<CompanyTaxTableEntry>[] = [
  { field: "label", headerName: "Form", dataType: "string" },
  { field: "quarterClean", headerName: "Quarter", dataType: "string" },
  { field: "year", headerName: "Year", dataType: "number" },
  { field: "filed_on", headerName: "Filed on", dataType: "date" },
  { field: "downloadButton", headerName: " ", dataType: "component" as const },
];
