import React, { useMemo } from "react";
import PayrollContext from "../payrollContext";
import { MiterFilterArray } from "dashboard/miter";
import { useActiveCompany, useTeam } from "dashboard/hooks/atom-hooks";
import { DateTime } from "luxon";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";
import PaymentContext from "./paymentContext";

type Props = {
  teamMemberId: string;
  readOnly: boolean;
};
export const PaymentModalTimesheets: React.FC<Props> = ({ teamMemberId, readOnly }) => {
  const activeCompany = useActiveCompany();

  const payrollContext = React.useContext(PayrollContext);
  const paymentContext = React.useContext(PaymentContext);
  const { recalculatePayroll } = payrollContext;
  const { payroll, payment } = paymentContext;

  const team = useTeam();

  const activeTeamMember = useMemo(() => team.find((t) => t._id === teamMemberId), [team, teamMemberId]);

  const defaultFilters = useMemo(() => {
    if (!payroll || !activeCompany?._id) return [];

    if (readOnly) {
      const nonDraftFilters: MiterFilterArray = [
        { field: "_id", comparisonType: "in", value: payment.timesheets || [], type: "_id" },
      ];
      return nonDraftFilters;
    }

    // Get the payroll start and end times
    const start = DateTime.fromISO(payroll.check_payroll.period_start, {
      zone: activeCompany.timezone,
    }).startOf("day");

    const end = DateTime.fromISO(payroll.check_payroll.period_end, {
      zone: activeCompany.timezone,
    }).endOf("day");

    // Add base filters
    const filters: MiterFilterArray = [
      {
        field: "status",
        value: ["unapproved", "approved"],
        comparisonType: "in",
        type: "string",
      },
      {
        field: "clock_in",
        value: [start.toSeconds(), end.toSeconds()],
        comparisonType: "<+>",
        type: "number",
      },
      {
        field: "team_member",
        value: teamMemberId,
        type: "string",
      },
    ];

    return filters;
  }, [payroll]);

  return (
    <div style={{ marginTop: -25 }}>
      <TimesheetsTable
        defaultFilters={defaultFilters}
        showToggler={false}
        activeTeamMember={activeTeamMember}
        showMiniTitle={false}
        hideDateRangeFilter={true}
        containerClassName="payment-modal-timesheets-table-container"
        onChange={() => recalculatePayroll({ tms: [teamMemberId] })}
        readOnly={readOnly}
        // doNotAddPayRates={true}
      />
    </div>
  );
};
