import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useCallback } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import {
  AggregatedAssignment,
  AggregatedJob,
  AggregatedTeamMember,
  Assignment,
  TeamMember,
  Job,
} from "dashboard/miter";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { notNullish } from "miter-utils";

type AssignmentParams = Assignment | Assignment[] | AggregatedAssignment | AggregatedAssignment[] | undefined;

type AssignmentAction = "create" | "read" | "update" | "delete";
type AssignmentAbilities = {
  can: (action: AssignmentAction, items?: AssignmentParams) => boolean;
  cannot: (action: AssignmentAction, items?: AssignmentParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: AssignmentAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: AssignmentAction) => (job: AggregatedJob | Job) => boolean;
};

export const useAssignmentAbilities = (): AssignmentAbilities => {
  const { can: can_ } = useMiterAbilities();
  const activeTeamMember = useActiveTeamMember();

  const can = useCallback(
    (action: AssignmentAction, items: AssignmentParams) => {
      if (!items) return false;

      const assignments = Array.isArray(items) ? items : [items];

      return assignments.every((assignment) => {
        const teamMemberIds = assignment.team_members.map((tm) => getTeamMemberId(tm)).filter(notNullish);
        // @ts-expect-error fix me
        const jobId = getJobId(assignment.job);

        switch (action) {
          case "create":
            return !teamMemberIds.length
              ? can_("assignments:create")
              : teamMemberIds.some((tmId) => can_("assignments:create", { teamMember: tmId, job: jobId }));
          case "read":
            return !teamMemberIds.length
              ? can_("assignments:read")
              : teamMemberIds.some((tmId) => can_("assignments:read", { teamMember: tmId, job: jobId }));
          case "update":
            return !teamMemberIds?.length
              ? can_("assignments:update")
              : teamMemberIds.some((tmId) => can_("assignments:update", { teamMember: tmId, job: jobId }));
          case "delete":
            return !teamMemberIds?.length
              ? can_("assignments:delete")
              : teamMemberIds.some((tmId) => can_("assignments:delete", { teamMember: tmId, job: jobId }));
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: AssignmentAction, items: AssignmentParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "assignments",
    teamMemberField: { fieldName: "team_members._id", fieldType: "_id" },
    jobField: { fieldName: "job._id", fieldType: "_id" },
    appModule: "workforce_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<AssignmentAction>("assignments", true);

  /** Job filter predicate */
  const jobPredicate = useAbilitiesJobPredicate<AssignmentAction>("assignments", true);

  return { can, cannot, filter, teamPredicate, jobPredicate };
};

const getTeamMemberId = (item: string | TeamMember): string | undefined => {
  return typeof item === "string" ? item : item._id;
};

const getJobId = (item: string | Job | AggregatedJob | undefined | null): string | undefined => {
  return typeof item === "string" ? item : item?._id;
};
