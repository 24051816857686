import styles from "../TeamMemberChecklistBuilder.module.css";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { BankAccountsOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const BankAccountTaskContent: React.FC<ChecklistTaskContentProps<BankAccountsOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  return (
    <div className={styles["team-member-checklist-task-content"]}>
      Require this employee to provide their bank account information for direct deposit and/or ACH
      reimbursements.
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
