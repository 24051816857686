import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Formblock } from "ui";
import * as vals from "dashboard/utils/validators";
import { states } from "dashboard/utils/utils";
import styles from "./PostTaxDeductionForm.module.css";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { Option } from "ui/form/Input";
import { PtdTableEntry } from "./PostTaxDeductions";
import { useLedgerAccountOptions, useTeamOptions, useVendorOptions } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember } from "dashboard/miter";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";
import { employeeFilterWithCheckPredicate } from "../team-members/TeamUtils";
import { usePostTaxDeductionAbilities } from "dashboard/hooks/abilities-hooks/usePostTaxDeductionAbilities";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { useHasAccessToBillPay } from "dashboard/gating";

/*********************************************************
 *  PostTaxDeductionForm
 *
 *  This component generates the form that we use to CRUD
 *  post-tax deductions. It is built using react-hook-form
 *  so all of the state data is managed with that library.
 *
 *  If you want to keep track of the state data, use the
 *  watch() function outlined below.
 **********************************************************/
type Props = {
  ptd?: PtdTableEntry | null;
  form: UseFormMethods<FieldValues>; // React Hook Form;
  readOnly?: boolean;
  teamMember?: AggregatedTeamMember;
};

const PostTaxDeductionForm: React.FC<Props> = ({ ptd, teamMember, form, readOnly = false }) => {
  const { register, control, watch, errors, trigger, setValue } = form;
  const { enPTDDeductionCodeOptions } = useEmployeeNavigator();

  const integrationLookup = useHasIntegration();
  const hasEnIntegration = integrationLookup.has("employee_navigator");
  const has401KPayrollIntegrations = integrationLookup.has("payroll_integrations");

  const postTaxDeduction = ptd?.check_post_tax_deduction;
  const ptdAbilities = usePostTaxDeductionAbilities();

  const hasAccessToBillPay = useHasAccessToBillPay();
  const vendorOptions = useVendorOptions();

  const finalPredicate = useCallback(
    (tm: AggregatedTeamMember) => {
      return (
        (employeeFilterWithCheckPredicate(tm) && ptdAbilities.teamPredicate("create")(tm)) ||
        ptd?.employee === tm._id
      );
    },
    [employeeFilterWithCheckPredicate, ptdAbilities.teamPredicate]
  );

  const teamOptions = useTeamOptions({ predicate: finalPredicate });

  // Format the date into the luxon date
  const formatDate = (date): DateTime | undefined => {
    if (!date) return undefined;
    return DateTime.fromISO(date);
  };

  // We need to keep track of the state data for a few fields we use in this form.
  const formData = watch();

  // Start and end date hooks and handlers
  const [startDate, setStartDate] = useState<DateTime | undefined>(
    postTaxDeduction?.effective_start ? DateTime.fromISO(postTaxDeduction.effective_start) : DateTime.now()
  );
  const [endDate, setEndDate] = useState<DateTime | undefined>(
    postTaxDeduction?.effective_end ? DateTime.fromISO(postTaxDeduction.effective_end) : undefined
  );

  const [managed, setManaged] = useState(!postTaxDeduction || postTaxDeduction.managed ? "true" : "false");

  const handleStateChange = (o: Option<string>) => {
    const newV = checkManagedStates.includes(o.value) ? "true" : "false";
    setManaged(newV);
    setValue(
      "managed",
      booleanOptions.find((o) => o.value === newV)
    );
  };

  const handleStartChange = (dt) => {
    setStartDate(dt);
  };

  const handleEndChange = (dt) => {
    setEndDate(dt);
  };

  useEffect(() => {
    if (formData.effective_end) {
      trigger("effective_end");
    }
  }, [startDate, endDate]);

  // This self executing function tell us what type of post-tax deduction is being created/updated
  const selectedCategory = (() => {
    if (postTaxDeduction) {
      return postTaxDeduction.type;
    } else if (formData.category) {
      return formData.category.value;
    } else {
      return null;
    }
  })();

  const selectedState = formData?.agency?.value || postTaxDeduction?.child_support?.agency;
  const manageable =
    selectedCategory === "child_support" && (!selectedState || checkManagedStates.includes(selectedState));

  const validateEndDate = (effective_end?: DateTime) => {
    if (!effective_end) return;

    let effective_start: DateTime, errMessage: string | undefined;
    if (startDate) {
      effective_start = startDate;
    } else {
      effective_start = DateTime.now();
      errMessage = "Since you haven't given a start date, end date must be blank or on/after today.";
    }

    return vals.isValidEndDate(effective_start?.toFormat("DD"), effective_end?.toFormat("DD"), errMessage);
  };

  const renderStartEndDates = () => {
    return (
      <>
        <Formblock
          label="Start Date*"
          labelInfo="The date this deduction will be considered effective. Payrolls with a payday before this date will not include the deduction."
          type="datetime"
          control={control}
          dateOnly={true}
          rules={vals.required}
          name="effective_start"
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={startDate}
          disabled={readOnly}
          onChange={handleStartChange}
          max={endDate}
        />
        <Formblock
          label="End Date"
          labelInfo="The date this deduction will no longer be considered effective. Payrolls with a payday after this date will not include the deduction."
          type="datetime"
          control={control}
          dateOnly={true}
          name="effective_end"
          rules={{ validate: validateEndDate }}
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={endDate}
          disabled={readOnly}
          onChange={handleEndChange}
          min={startDate}
        />
      </>
    );
  };

  // Renders the dropdown for selecting the type of post-tax deduction
  const renderTypeDropdown = () => {
    const options = [
      { label: "Child support", value: "child_support" },
      { label: "Miscellaneous", value: "miscellaneous" },
    ];

    return (
      <Formblock
        label="Category*"
        labelInfo={
          "This defines whether the post-tax deduction is a child support garnishment or any other misc post-tax deduction"
        }
        type="select"
        control={control}
        name="category"
        options={options}
        className="modal"
        defaultValue={postTaxDeduction?.type}
        errors={errors}
        editing={true}
        requiredSelect={true}
        disabled={postTaxDeduction ? true : false}
        onChange={(o) => setManaged(o.value === "miscellaneous" ? "false" : "true")}
      />
    );
  };

  // Renders the form for creating/updating a child support post-tax deduction
  const renderChildSupportFormPart1 = () => {
    // Create a list of available child support agencies
    const agencyOptions = states
      .filter((state) => !["AS", "GU", "MP", "PR", "VI"].includes(state.abbreviation))
      .map((state) => {
        return { label: state.abbreviation, value: state.abbreviation };
      });

    return (
      <>
        {!teamMember && (
          <Formblock
            label="Employee*"
            labelInfo={
              "The team member who this deduction is for. This is the team member who will see the deduction on their pay stub."
            }
            type="select"
            name="employee"
            control={control}
            className="modal"
            errors={errors}
            editing={true}
            options={teamOptions}
            defaultValue={ptd?.employee}
            disabled={readOnly || !!ptd?.employee}
          />
        )}
        <Formblock
          label="Description"
          labelInfo={
            "You can use this field to add an optional description for the child support post-tax deduction."
          }
          type="text"
          name="description"
          register={register()}
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={postTaxDeduction?.description || ""}
          disabled={readOnly}
        />
        <Formblock
          label="External ID*"
          labelInfo={"The case number listed on the order, which is a unique identifier for the garnishment"}
          type="text"
          name="external_id"
          register={register(vals.required)}
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={postTaxDeduction?.child_support?.external_id || ""}
          disabled={readOnly}
        />
        <Formblock
          label="Agency*"
          labelInfo={"The state abbreviation of the agency that issued the order"}
          type="select"
          name="agency"
          register={register(vals.required)}
          control={control}
          className="modal"
          options={agencyOptions}
          errors={errors}
          editing={true}
          defaultValue={postTaxDeduction?.child_support?.agency || ""}
          disabled={!!postTaxDeduction?.child_support || readOnly}
          onChange={handleStateChange}
        />
        <Formblock
          label="FIPS Code"
          labelInfo={
            "An optional 5 or 7 digit number used to identify the correct agency within the state. This should be provided if present in the order."
          }
          type="text"
          name="fips_code"
          register={register(vals.fips_code)}
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={postTaxDeduction?.child_support?.fips_code || ""}
          disabled={readOnly}
        />
        <Formblock
          label="Managed"
          labelInfo={"If true, Miter will remit payment of this deduction"}
          type="select"
          control={control}
          name="managed"
          options={booleanOptions}
          className="modal"
          defaultValue={managed}
          value={booleanOptions.find((o) => o.value === managed)}
          errors={errors}
          editing={true}
          disabled={!manageable || readOnly}
          onChange={(o) => setManaged(o.value)}
        />
      </>
    );
  };

  const renderManagedByCheckNote = (): ReactElement | null => {
    let msg = "";
    if (!manageable && selectedState) {
      const selectedStateName = states.find((state) => state.abbreviation === selectedState)?.name;
      msg = `Note: At this time, child support deductions in ${selectedStateName} are NOT remitted by Miter.`;
    } else if (manageable && managed === "false") {
      msg = `Note: By setting "Managed" to False, Miter will NOT remit this child support deduction on
      your behalf.`;
    }
    return msg ? <p className={styles["managed-callout"]}>{msg}</p> : null;
  };

  const glAccountOptions = useLedgerAccountOptions();
  const renderGlAccountDropdown = () => {
    // Since it's "managed" by Check, then there's no liability for it in the GL, since it was taken out directly from the bank account asset. And if there's no liability, then there's no need for a GL account override to represent the non-existent liability
    if (managed === "true") return null;

    return (
      <>
        <Formblock
          label="GL account override"
          labelInfo={
            "Miter will credit this account when creating payroll GL entries. It will override the default."
          }
          type="select"
          name="gl_account"
          register={register}
          control={control}
          className="modal"
          options={glAccountOptions}
          errors={errors}
          editing={true}
          defaultValue={ptd?.gl_account_id}
          isClearable
          // Don't use readonly flag here b/c guideline ptds will be
          // readonly but they should still be updated unless the user is not
          // allowed to update ptds
          disabled={!!ptd && ptdAbilities.cannot("update", ptd)}
        />
      </>
    );
  };

  const render401kloanDropdown = () => {
    return (
      <Formblock
        label="Is 401k loan"
        type="select"
        name="is_401k_loan"
        register={register}
        control={control}
        className="modal"
        options={booleanOptions}
        errors={errors}
        editing={true}
        defaultValue={ptd?.is_401k_loan ? "true" : "false"}
        disabled={!!ptd && ptdAbilities.cannot("update", ptd)}
      />
    );
  };

  // Renders the form for creating/updating a child support post-tax deduction
  const renderChildSupportFormPart2 = () => {
    return (
      <>
        <Formblock
          label="Amount Per Pay Period*"
          labelInfo={"Per pay period amount to deduct"}
          type="unit"
          unit="$"
          name="amount"
          register={register(vals.isNumber)}
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={Number(postTaxDeduction?.child_support?.amount) || undefined}
          disabled={readOnly}
        />
        <Formblock
          label="Max Percent*"
          labelInfo={"The maximum percent of disposable income that can be deducted"}
          type="unit"
          name="max_percent"
          register={register(vals.percent)}
          className={"modal percent-unit"}
          errors={errors}
          editing={true}
          unit={"%"}
          defaultValue={postTaxDeduction?.child_support?.max_percent}
          disabled={readOnly}
        />
        {!!formData.max_percent && Number(formData.max_percent) === 0 && (
          <div className="formblock-warning">
            <strong>Warning:</strong> A value of zero for &quot;Max Percent&quot; means this deduction will
            never be applied.
          </div>
        )}
        <Formblock
          label="Issue Date*"
          labelInfo={"The date the collections agency issued the order"}
          type="datetime"
          control={control}
          dateOnly={true}
          name="issue_date"
          rules={vals.required}
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={formatDate(postTaxDeduction?.child_support?.issue_date) || undefined}
          disabled={readOnly}
        />
        {renderStartEndDates()}
      </>
    );
  };

  // Renders the form for creating/updating a misc support post-tax deduction
  const renderMiscFormPart1 = () => {
    // showDefaults is used to decide if this form is a create form or an update form.
    let showDefaults = false;
    if (postTaxDeduction && postTaxDeduction.miscellaneous) {
      showDefaults = true;
    }

    const calculationTypeOptions = [
      { label: "Per paycheck $ amount", value: "dollar_amount" },
      { label: "Percent", value: "percent" },
    ];

    let defaultCalculationType = "dollar_amount";
    if (showDefaults && postTaxDeduction?.miscellaneous?.percent) {
      defaultCalculationType = "percent";
    }

    return (
      <div className={styles["misc-form"]}>
        {!teamMember && (
          <Formblock
            label="Employee*"
            labelInfo={
              "The team member who this deduction is for. This is the team member who will see the deduction on their pay stub."
            }
            type="select"
            name="employee"
            control={control}
            className="modal"
            errors={errors}
            editing={true}
            options={teamOptions}
            defaultValue={ptd?.employee}
            disabled={readOnly || !!ptd?.employee}
          />
        )}
        <Formblock
          label="Description*"
          labelInfo={
            "You can use this field to add an optional description for the miscellaneous post-tax deduction."
          }
          type="text"
          name="description"
          register={register(vals.required)}
          className="modal"
          errors={errors}
          editing={true}
          defaultValue={showDefaults ? postTaxDeduction?.description : ""}
        />
        {hasEnIntegration && (
          <Formblock
            label="Deduction code"
            type="select"
            name="integrations.employee_navigator.deduction_code"
            options={enPTDDeductionCodeOptions}
            register={register}
            control={form.control}
            className="modal"
            labelInfo="The deduction code used to identify this benefit in Employee Navigator."
            editing={true}
            defaultValue={ptd?.integrations?.employee_navigator?.deduction_code}
            disabled={!!ptd && ptdAbilities.cannot("update", ptd)}
          />
        )}
        {has401KPayrollIntegrations && !!ptd?.integrations?.payroll_integrations?.deduction_code && (
          <Formblock
            label="401(k) deduction code"
            type="text"
            name="integrations.payroll_integrations.deduction_code"
            className="modal"
            labelInfo="The deduction code used to identify this benefit in your 401(k) provider."
            defaultValue={ptd?.integrations?.payroll_integrations?.deduction_code}
            readOnly={true}
          />
        )}
        <div className={styles["calculation-wrapper"]}>
          <Formblock
            label="Calculation type*"
            labelInfo={"This defines how you want to calculate the post-tax deduction."}
            type="select"
            control={control}
            name="calculation_type"
            options={calculationTypeOptions}
            register={register(vals.required)}
            className="modal"
            errors={errors}
            editing={true}
            requiredSelect={true}
            defaultValue={defaultCalculationType}
            disabled={readOnly}
          />
          {renderCalculationValueField(showDefaults)}
        </div>
        {hasAccessToBillPay && (
          <Formblock
            name="vendor_id"
            type="select"
            options={vendorOptions}
            defaultValue={ptd?.vendor_id}
            errors={errors}
            label="Bill pay vendor"
            editing={true}
            control={control}
            className="modal"
            labelInfo="If specified, a bill to this vendor will be generated per payroll."
            isClearable
            disabled={readOnly}
          />
        )}
      </div>
    );
  };

  const renderMiscFormPart2 = () => {
    // showDefaults is used to decide if this form is a create form or an update form.
    let showDefaults = false;
    if (postTaxDeduction && postTaxDeduction.miscellaneous) {
      showDefaults = true;
    }

    return renderDatesAndMax(showDefaults);
  };

  // Renders the inputs that decide between making the post-tax deduction a percent calcuation or dollar amount calculation
  const renderCalculationValueField = (showDefaults) => {
    let showPercentField = false;

    if (formData.calculation_type && formData.calculation_type.value === "percent") {
      showPercentField = true;
    } else if (!formData.calculation_type && postTaxDeduction && postTaxDeduction?.miscellaneous?.percent) {
      showPercentField = true;
    }

    const defaultValue = showDefaults
      ? showPercentField
        ? Number(postTaxDeduction?.miscellaneous?.percent)
        : Number(postTaxDeduction?.miscellaneous?.amount)
      : undefined;

    return (
      <Formblock
        label={showPercentField ? "% of earnings to deduct*" : "Deduction amount per paycheck*"}
        type="unit"
        name="calculation_value"
        register={register(vals.isNumber)}
        className={showPercentField ? "modal percent-unit" : "modal"}
        unit={showPercentField ? "%" : "$"}
        errors={errors}
        editing={true}
        defaultValue={defaultValue}
        disabled={readOnly}
      />
    );
  };

  // Renders the inputs that allow a user to specifcy the start/end dates and the max amount for a post-tax deduction
  const renderDatesAndMax = (showDefaults) => {
    return (
      <>
        <Formblock
          label="Total aggregate amount to deduct"
          labelInfo={"The total amount that should ever be withheld across payrolls"}
          type="unit"
          name="total_amount"
          register={register(vals.isNumberButNotRequired)}
          className="modal"
          unit="$"
          errors={errors}
          editing={true}
          defaultValue={
            showDefaults &&
            postTaxDeduction?.miscellaneous?.total_amount !== null &&
            postTaxDeduction?.miscellaneous?.total_amount !== undefined
              ? Number(postTaxDeduction.miscellaneous.total_amount)
              : undefined
          }
          disabled={readOnly}
        />
        {renderStartEndDates()}
      </>
    );
  };

  return (
    <div className={styles["post-tax-deduction-body"]}>
      <div className={styles["two-column"]}>
        <div className={"modal-form-column"}>
          {renderTypeDropdown()}
          {selectedCategory === "child_support" && renderChildSupportFormPart1()}
          {selectedCategory === "miscellaneous" && renderMiscFormPart1()}
        </div>
        <div className={"modal-form-column"}>
          {selectedCategory === "child_support" && renderChildSupportFormPart2()}
          {selectedCategory === "miscellaneous" && renderMiscFormPart2()}
          {selectedCategory === "miscellaneous" && render401kloanDropdown()}
          {selectedCategory && renderGlAccountDropdown()}
        </div>
      </div>
      {renderManagedByCheckNote()}
    </div>
  );
};

const checkManagedStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "PA",
  "OH",
  "OK",
  "OR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

const booleanOptions = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

export default PostTaxDeductionForm;
