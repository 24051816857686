import React, { useEffect, useMemo, useState } from "react";
import { reportList } from "../../reportList";
import { useForm } from "react-hook-form";
import { Formblock, Loader } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { Option } from "ui/form/Input";
import { DateTime } from "luxon";
import { getPayrollTypeString, roundTo } from "dashboard/utils";
import { toDollarFormat } from "../../reportUtils";
import { useActiveCompanyId, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

export type SimpleIRAReportEntry = {
  employeeId: string;
  employeeName: string;
  grossEarnings: number;
  employeeContributions: number;
};

export const SIMPLEIRAReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const lookupTeam = useLookupTeam();
  const teamAbilities = useTeamAbilities();

  const { register, errors, control, handleSubmit } = useForm();
  const navigate = useNavigate();

  // State
  const [data, setData] = useState<SimpleIRAReportEntry[] | undefined>();
  const [payrollOptions, setPayrollOptions] = useState<Option<string>[]>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [employerContributionPct, setEmployerContributionPct] = useState(2);

  const getPayrolls = async () => {
    if (!activeCompanyId) return;
    setFetchingData(true);
    try {
      const response = await MiterAPI.payrolls.get_table({ company: activeCompanyId });
      if (response.error) throw new Error(response.error);
      const options = response
        .filter((p) => p.status !== "draft")
        .map((payroll) => {
          return {
            label: DateTime.fromISO(payroll.payday).toFormat("DD") + ` (${getPayrollTypeString(payroll)})`,
            value: payroll._id.toString(),
          };
        });
      setPayrollOptions(options);
    } catch (e) {
      console.error("Error fetching payrolls for 401K report.");
      Notifier.error(
        "There was an error preparing the report. Our engineers have been notified and are investigating."
      );
    }
    setFetchingData(false);
  };

  useEffect(() => {
    getPayrolls();
  }, []);

  const getData = async (data) => {
    setFetchingData(true);
    try {
      const cleanedData = {
        payrollId: data.payroll?.value,
        companyId: activeCompanyId!,
      };
      const payload = {
        type: "simpleIra",
        params: cleanedData,
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const filteredData = response.filter((entry: SimpleIRAReportEntry) => {
        return teamAbilities.can("read_sensitive", lookupTeam(entry.employeeId));
      });

      setData(filteredData);
    } catch (e) {
      Notifier.error("There was an error downloading the CSV. We're looking into it!");
    }
    setFetchingData(false);
  };

  const tableData = useMemo(() => {
    return (
      data?.map((entry) => {
        const employerContributions = roundTo(employerContributionPct * 0.01 * entry.grossEarnings);
        return {
          ...entry,
          employerContributions,
          totalContributions: entry.employeeContributions + employerContributions,
        };
      }) || []
    );
  }, [data, employerContributionPct]);

  const reportObject = reportList.find((report) => report.slug === "simple_ira_report");

  const fileName = "Miter SIMPLE IRA report";

  return (
    <div className="page-content">
      <Helmet>
        <title>SIMPLE IRA Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>SIMPLE IRA contributions report</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}*</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 400 }}>
        <Formblock
          label="Payroll"
          register={register}
          type="select"
          control={control}
          options={payrollOptions}
          name="payroll"
          errors={errors}
          editing={true}
          onChange={handleSubmit(getData)}
        />
        {data && (
          <Formblock
            label="Employer contribution %"
            register={register}
            type="text"
            value={employerContributionPct.toString()}
            onChange={(e) => setEmployerContributionPct(e.target.value)}
            name="employerContributionPct"
            errors={errors}
            editing={true}
          />
        )}
      </div>
      {fetchingData && <Loader />}
      {!fetchingData && data && (
        <>
          <AgGridTable data={tableData} columnDefs={colDefs} fileName={fileName} />
          <div className="vertical-spacer"></div>
        </>
      )}
    </div>
  );
};

export const colDefs = [
  {
    field: "employeeName",
    headerName: "Employee",
  },
  {
    field: "grossEarnings",
    headerName: "Gross earnings",
    valueFormatter: toDollarFormat,
  },
  {
    field: "employeeContributions",
    headerName: "Employee contributions",
    valueFormatter: toDollarFormat,
  },
  {
    field: "employerContributions",
    headerName: "Employer contributions",
    valueFormatter: toDollarFormat,
  },
  {
    field: "totalContributions",
    headerName: "Total contributions",
    valueFormatter: toDollarFormat,
  },
];
