import { AggregatedFillableTemplate, FillableTemplate } from "dashboard/types/fillable-template-types";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import { MiterAPI } from "dashboard/miter";
import {
  MAX_FILLABLE_INPUTS,
  PAGE_SCALE_FACTOR,
  PartialTemplateInput,
  generateBoxStyle,
} from "dashboard/utils/fillable-templates";
import { generateFillableInputFields } from "dashboard/utils/fillable-templates";
import { InputMapperForm } from "../mapping-inputs/InputMapperForm";
import styles from "./FillableTemplateWizard.module.css";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { notNullish } from "miter-utils";
import {
  TeamMemberGroupSelectValue,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import { Option } from "ui/form/Input";
import { useRefetchActionableItems, useRefetchFillableTemplates } from "dashboard/hooks/atom-hooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const DEFAULT_PAGE_HEIGHT = 790;
const DEFAULT_PAGE_WIDTH = 612;

type Props = {
  fillableTemplate: AggregatedFillableTemplate;
  name: string;
  setFillableTemplate: (fillableTemplate: AggregatedFillableTemplate) => void;
};

type ReactHookFormFillableTemplateInput = {
  inputs: { [x: string]: PartialTemplateInput };
};

const buildDefaultValues = (
  inputs: PartialTemplateInput[],
  teamMemberOptions: Option<TeamMemberGroupSelectValue>[]
): ReactHookFormFillableTemplateInput => {
  const storingAssigneesSeparately = {};
  const calculatedInputs = inputs.reduce((acc, input) => {
    const teamMembers = (input?.assignee || [])
      .map((group) => {
        const option = teamMemberOptions.find(
          (tm) => tm.value?.type === group.type && tm.value?.value === group.value
        );
        return option;
      })
      .filter(notNullish);
    acc[input._id!] = {
      ...input,
    };
    storingAssigneesSeparately[`${input._id}-assignee`] = teamMembers;
    return acc;
  }, {}) as ReactHookFormFillableTemplateInput;

  return {
    ...storingAssigneesSeparately,
    inputs: calculatedInputs,
  };
};

type PageDimensions = {
  height: number;
  width: number;
};

export const MapFillableInputsWizardScreen: FC<Props> = ({ fillableTemplate, name, setFillableTemplate }) => {
  const [templateInputs, setTemplateInputs] = useState<PartialTemplateInput[]>(fillableTemplate.inputs);
  const [selectedInput, setSelectedInput] = useState<PartialTemplateInput>();
  const [fileURL, setFileURL] = useState<string>();
  const [documentLoading, setDocumentLoading] = useState(true);
  const refetchActionableItems = useRefetchActionableItems();
  const refetchFillableTemplates = useRefetchFillableTemplates();

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    excludedGroups: ["all_team_members", "employment_type", "pay_type", "title", "department", "team_member"],
    hideMitosaurs: true,
  });
  const teamMemberOptions = useMemo(
    () => teamMemberGroupOptions.flatMap((group) => group.options),
    [teamMemberGroupOptions]
  );
  const [allPageDimensions, setAllPageDimensions] = useState<PageDimensions[]>([]);

  const form = useForm<ReactHookFormFillableTemplateInput>({
    defaultValues: buildDefaultValues(templateInputs, teamMemberOptions),
    reValidateMode: "onChange",
    mode: "all",
  });

  const {
    errors,
    formState: { errors: formErrors },
    handleSubmit,
    control,
  } = form;

  useWatch({
    control,
  });

  const { setCanNext, setCurIndex, screens } = useWizard();

  useEffect(() => {
    if (Object.keys(errors).length) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [errors, formErrors, form.formState.isValid]);

  useEffect(() => {
    MiterAPI.files
      .get_urls({
        filter: [{ field: "_id", value: fillableTemplate.original_file_id, type: "_id" }],
      })
      .then((res) => {
        if (res.urls.length === 0) return;
        const [resUrl, resType] = [res?.urls[0]?.value.url, res?.urls[0]?.file?.type];
        if (resUrl && resType) {
          setFileURL(resUrl);
        }
      })
      .catch((error) => {
        Notifier.error(`Error getting file URL: ${error.message}`);
      });
  }, []);

  useEffect(() => {
    const limitFillableTemplate = async () => {
      try {
        const response = await MiterAPI.fillable_templates.limit({ id: fillableTemplate._id });
        if (response.error) {
          throw new Error(response.error);
        }
      } catch (error: $TSFixMe) {
        console.error(`Error limiting template to ${MAX_FILLABLE_INPUTS} inputs: ${error.message}`);
      }
    };
    const getAnvilFields = async () => {
      try {
        await limitFillableTemplate();
        const response = await MiterAPI.fillable_templates.template_inputs({ id: fillableTemplate._id });
        if (response.error) {
          throw new Error(response.error);
        }

        const calculatedTemplateInputs = generateFillableInputFields({
          fillableTemplate,
          anvilFormInputs: response,
        });
        if (calculatedTemplateInputs.length === 0) {
          Notifier.error("Please add at least one input for this fillable template.");
          // Should land at the Anvil template editor screen regardless of in creation/edit mode
          setCurIndex(screens.length - 3);
        }
        setTemplateInputs(calculatedTemplateInputs);
        setSelectedInput(calculatedTemplateInputs[0]);
        form.reset(buildDefaultValues(calculatedTemplateInputs, teamMemberOptions));
      } catch (error: $TSFixMe) {
        Notifier.error(`Error getting file inputs: ${error.message}`);
      }
    };
    getAnvilFields();
  }, []);

  const submit = async () => {
    const data = form.getValues();
    // Ensure the customer has at least one required input
    if (!Object.values(data.inputs).some((input) => input.validations?.required)) {
      Notifier.error("Please add at least one required input for this fillable template.");
      throw new Error("No required inputs found.");
    }
    await handleSubmit(saveInputAnswers)();
  };

  const saveInputAnswers = async (data: ReactHookFormFillableTemplateInput) => {
    try {
      const finalTemplateInputs = templateInputs
        .map((input) => {
          const reactHookFormUpdate = data.inputs[input._id!];
          if (!reactHookFormUpdate) return null;
          const assignee = data[`${input._id}-assignee`]; // is separately stored as it cannot be a nested field

          return {
            ...input,
            name: reactHookFormUpdate.name || "Unknown question title",
            description: reactHookFormUpdate.description || "Unknown question description",
            assignee: (assignee || []).map((a) => a.value),
            validations: { ...input.validations, required: !!reactHookFormUpdate?.validations?.required },
          } as FillableTemplate["inputs"][0];
        })
        .filter(notNullish);

      const res = await MiterAPI.fillable_templates.update({
        id: fillableTemplate._id,
        update: {
          inputs: finalTemplateInputs,
        },
      });

      if (res.error) {
        throw new Error(res.error);
      }

      Notifier.success("Successfully updated fillable template inputs.");
      refetchActionableItems();
      setFillableTemplate(res);
      refetchFillableTemplates(res._id);
    } catch (error: $TSFixMe) {
      Notifier.error(error.message);
    }
  };

  const handleLoadSuccess = async (pdfObject) => {
    const numPages = pdfObject.numPages;
    const allPageDimensions = await Promise.all(
      new Array(numPages).fill(0).map(async (_, i) => {
        const page = await pdfObject.getPage(i + 1);
        return {
          height: page.view[3],
          width: page.view[2],
        };
      })
    );
    setAllPageDimensions(allPageDimensions);
    setDocumentLoading(false);
  };

  const currentPageDimensions = useMemo(() => {
    return allPageDimensions[selectedInput?.position?.page_number || 0];
  }, [selectedInput?.position?.page_number, allPageDimensions]);

  /*
   * As we are manually overlaying CSS boxes on the PDF, we need to caulcaute the correct dimenionss of the PDF
   */
  const finalPageWidth = (currentPageDimensions?.width || DEFAULT_PAGE_WIDTH) * PAGE_SCALE_FACTOR;
  const finalPageHeight = (currentPageDimensions?.height || DEFAULT_PAGE_HEIGHT) * PAGE_SCALE_FACTOR;

  return (
    <WizardScreen
      name={name}
      onNext={submit}
      style={{
        overflowY: "hidden",
      }}
    >
      <div className={styles["map-inputs-header"]}>
        <h2 className={styles["subheader-title"]}>Assign descriptions</h2>
        <p className={styles["subheader-description"]}>
          Add detailed descriptions assigned team members for each input.
        </p>
      </div>

      <div className={styles["split-pane"]}>
        <div className={styles["fill-inputs-pane-2"]}>
          <FormProvider {...form}>
            <InputMapperForm
              inputs={templateInputs}
              setSelectedInput={setSelectedInput}
              selectedInput={selectedInput}
            />
          </FormProvider>
          {/* <div style={{ height: 200 }}></div> */}
        </div>
        <div
          className={styles["view-pdf-pane-1"]}
          style={{
            minWidth: finalPageWidth,
            minHeight: finalPageHeight,
          }}
        >
          {fileURL && (
            <div className={styles["document-container"]}>
              {!documentLoading && (
                <div
                  style={{
                    height: finalPageHeight,
                    width: finalPageWidth,
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      ...(selectedInput?.position
                        ? generateBoxStyle({
                            position: selectedInput.position,
                            pageDimensions: currentPageDimensions!,
                          })
                        : {}),
                    }}
                    className={styles["hover-box"]}
                  ></div>
                </div>
              )}

              <Document file={fileURL} onLoadSuccess={handleLoadSuccess}>
                {/* 1-indexed */}
                <Page
                  scale={PAGE_SCALE_FACTOR}
                  pageNumber={(selectedInput?.position?.page_number || 0) + 1}
                />
              </Document>
            </div>
          )}
        </div>
      </div>
    </WizardScreen>
  );
};
