import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import BenefitsAdminLander from "./BenefitsAdminLander";
import { Loader, Notifier } from "ui";
import { MiterAPI } from "dashboard/miter";
import { useEffect, useState } from "react";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";
import { ClaspReact } from "clasp-components";
import axios from "axios";

const BenefitsAdmin = (): JSX.Element | null => {
  const company = useActiveCompany();
  const [componentUrl, setComponentUrl] = useState<string | null>(null);

  const getComponentUrl = async () => {
    if (!isActiveClaspCompany(company)) return;
    try {
      const response = await MiterAPI.clasp.get_employer_component_url(company?._id);
      if (response.error) throw new Error(response.error);
      setComponentUrl(response.url);
    } catch (e) {
      console.log("Clasp error: ", e);
      Notifier.error(
        "Failed to authenticate for Miter benefits administration. Please try again or reach out to support if the issue persists."
      );
    }
  };

  useEffect(() => {
    getComponentUrl();
  }, [company]);

  if (!isActiveClaspCompany(company)) {
    return <BenefitsAdminLander status={company?.integrations?.clasp?.clasp_employer?.status} />;
  } else if (!componentUrl) {
    return <Loader />;
  }

  return (
    <>
      <div className="vertical-spacer" />
      <ClaspReact
        className="height-100"
        link={componentUrl}
        onError={(error) => {
          if (axios.isAxiosError(error) && error.response?.status !== 403) {
            console.error("Clasp component error: ", error.response?.data);
          }
          Notifier.error("There was an error loading the benefits administration page.");
        }}
      />
    </>
  );
};

export default BenefitsAdmin;
