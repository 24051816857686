import React, { useState, useEffect, useMemo } from "react";
import { usdString } from "ui";

import PaymentContext from "./paymentContext";
import { Button, Formblock, ModalFooter, ModalHeader, TableV2 } from "ui";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import {
  CheckBenefit,
  CheckBenefitOverride,
  CheckPostTaxDeduction,
  CheckPostTaxDeductionOverride,
} from "backend/utils/check/check-types";
import { useForm } from "react-hook-form";
import { PostTaxDeduction, AggregatedEmployeeBenefit, MiterAPI } from "dashboard/miter";
import PayrollContext from "../payrollContext";
import { PayrollAdjustment } from "../../payrollTypes";
import { Option } from "ui/form/Input";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import Banner from "dashboard/components/shared/Banner";
import { getBenefitDescription } from "dashboard/pages/benefits/benefitsUtils";

const benefitAmountValidator = vals.numberValidator({
  required: false,
  excludeNegatives: true,
  maxDecimals: 2,
});

const ptdAmountValidator = vals.numberValidator({
  required: true,
  excludeNegatives: true,
  maxDecimals: 2,
});

type TableEntry = {
  _id: string;
  company_contribution: string;
  employee_contribution: string;
  overrideType: "Post-tax deduction" | "Benefit";
  benefitOverride?: CheckBenefitOverride;
  ptdOverride?: CheckPostTaxDeductionOverride;
  label: string;
};

export const Overrides: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const { payment, editing } = React.useContext(PaymentContext);

  const [employeeBenefits, setEmployeeBenefits] = useState<AggregatedEmployeeBenefit[]>();
  const [ptds, setPtds] = useState<PostTaxDeduction[]>();
  const [overrideToEdit, setOverrideToEdit] = useState<TableEntry | undefined>(undefined);
  const [creating, setCreating] = useState(false);

  const getData = async () => {
    try {
      const filter = [
        { field: "company", value: activeCompanyId! },
        { field: "employee", value: payment.team_member._id },
      ];
      const [bResponse, ptdResponse] = await Promise.all([
        MiterAPI.benefits.employee.search(filter),
        MiterAPI.post_tax_deductions.retrieve_many({ filter }),
      ]);
      if (bResponse.error) throw new Error(bResponse.error);
      if (ptdResponse.error) throw new Error(ptdResponse.error);
      setEmployeeBenefits(bResponse);
      setPtds(ptdResponse);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error loading override data. We're looking into it!");
    }
  };

  const tableData = useMemo(() => {
    if (!employeeBenefits || !ptds) return [];
    // Get benefit overrides
    const bRows: TableEntry[] = [];
    for (const bo of payment.adjustment?.benefit_overrides || []) {
      const benefit = employeeBenefits.find((b) => b.check_id === bo.benefit);
      if (!benefit) {
        // Assume that the benefit has been deleted after an override has been set, so it won't show up
        continue;
      }
      const description = getBenefitDescription(benefit);

      bRows.push({
        _id: bo.benefit,
        overrideType: "Benefit",
        label: description,
        company_contribution: bo.company_contribution_amount
          ? usdString(Number(bo.company_contribution_amount))
          : "Default",
        employee_contribution: bo.employee_contribution_amount
          ? usdString(Number(bo.employee_contribution_amount))
          : "Default",
        benefitOverride: bo,
      });
    }

    // Get PTD overrides
    const ptdRows: TableEntry[] = [];
    for (const ptdo of payment.adjustment?.post_tax_deduction_overrides || []) {
      const ptd = ptds.find((b) => b.check_id === ptdo.post_tax_deduction);
      if (!ptd) {
        // Assume that the ptd has been deleted after an override has been set, so it won't show up
        continue;
      }
      ptdRows.push({
        _id: ptdo.post_tax_deduction,
        label: ptd?.check_post_tax_deduction.description,
        company_contribution: "N/A",
        employee_contribution: ptdo.amount ? usdString(Number(ptdo.amount)) : "Default",
        overrideType: "Post-tax deduction",
        ptdOverride: ptdo,
      });
    }

    return bRows.concat(ptdRows);
  }, [payment, employeeBenefits, ptds]);

  const columns = [
    { field: "label", headerName: "Label", dataType: "string" as const },
    { field: "overrideType", headerName: "Type", dataType: "string" as const },
    { field: "employee_contribution", headerName: "Employee amount" },
    { field: "company_contribution", headerName: "Company amount" },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Overrides</div>
        <div className="flex-1"></div>
        {editing && <Button className="button-2" onClick={() => setCreating(true)} text="+ New" />}
      </div>
      <div>
        <span>
          Override the normal deduction amounts for any benefit or post-tax deduction, for this payment only.
        </span>
        {(creating || overrideToEdit) && (
          <OverrideModal
            overrideToEdit={overrideToEdit}
            employeeBenefits={employeeBenefits || []}
            ptds={ptds || []}
            hide={() => {
              setOverrideToEdit(undefined);
              setCreating(false);
            }}
          />
        )}

        <TableV2
          id={"payment-overrides-table"}
          resource="overrides"
          data={tableData}
          columns={columns}
          onClick={editing ? (r) => setOverrideToEdit(r) : undefined}
          hideSearch={true}
          hideSecondaryActions={true}
          containerStyle={{ paddingTop: 10 }}
        />
      </div>
    </div>
  );
};

type ModalProps = {
  overrideToEdit: TableEntry | undefined;
  employeeBenefits: AggregatedEmployeeBenefit[];
  ptds: PostTaxDeduction[];
  hide: () => void;
};

const OverrideModal: React.FC<ModalProps> = ({ overrideToEdit, hide, employeeBenefits, ptds }) => {
  const { payment, tm, editing, payroll } = React.useContext(PaymentContext);
  const { recalculatePayroll } = React.useContext(PayrollContext);

  const adjustment = payment.adjustment;
  const payday = payroll?.check_payroll.payday as string | undefined;

  const form = useForm();

  const benefitOptions = useMemo(() => {
    return employeeBenefits
      .filter((b) => {
        if (!payday) return false;
        if (b.employee._id.toString() !== tm._id.toString()) return false;
        if (b.check_benefit.metadata?.fringe_use) return false;

        const checkBen = b.check_benefit as CheckBenefit;
        if (checkBen.effective_start && checkBen.effective_start > payday) return false;
        if (checkBen.effective_end && checkBen.effective_end < payday) return false;
        if (
          adjustment?.benefit_overrides?.some(
            (o) => o.benefit === b.check_id && o.benefit !== overrideToEdit?._id
          )
        )
          return false;
        return true;
      })
      .map((b) => {
        return { label: getBenefitDescription(b), value: b.check_id };
      });
  }, [employeeBenefits, payday, tm]);

  const ptdOptions = useMemo(() => {
    return ptds
      .filter((ptd) => {
        if (!payday) return false;
        if (ptd.employee !== tm._id.toString()) return false;

        const checkPtd = ptd.check_post_tax_deduction as CheckPostTaxDeduction;
        if (checkPtd.type === "child_support") return false;
        if (checkPtd.effective_start && checkPtd.effective_start > payday) return false;
        if (checkPtd.effective_end && checkPtd.effective_end < payday) return false;
        if (checkPtd.metadata?.fringe_group_id) return false;
        if (adjustment?.post_tax_deduction_overrides?.some((o) => o.post_tax_deduction === ptd.check_id)) {
          return false;
        }
        return true;
      })
      .map((b) => {
        return { label: b.check_post_tax_deduction.description, value: b.check_id };
      });
  }, [ptds, payday, tm]);

  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overrideType, setOverrideType] = useState<"benefit" | "ptd" | undefined>(
    overrideToEdit?.benefitOverride ? "benefit" : overrideToEdit?.ptdOverride ? "ptd" : undefined
  );
  const [selectedBenefitOption, setSelectedBenefitOption] = useState(
    benefitOptions.find((bo) => bo.value === overrideToEdit?.benefitOverride?.benefit)
  );
  const [selectedPtdOption, setSelectedPtdOption] = useState(
    ptdOptions.find((ptdo) => ptdo.value === overrideToEdit?.ptdOverride?.post_tax_deduction)
  );
  const [showExternallySyncedWarning, setShowExternallySyncedWarning] = useState(false);

  const overrideTypeOptions: Option<string>[] = [];
  if (payroll?.type === "regular" || payroll?.check_payroll.off_cycle_options?.apply_benefits) {
    overrideTypeOptions.push({ label: "Benefit", value: "benefit" });
  }
  if (payroll?.type === "regular" || payroll?.check_payroll.off_cycle_options?.apply_post_tax_deductions) {
    overrideTypeOptions.push({ label: "Post-tax deduction", value: "ptd" });
  }

  const getOverridesFromFormData = (
    formData
  ): {
    benefitOverrides: CheckBenefitOverride[];
    ptdOverrides: CheckPostTaxDeductionOverride[];
  } => {
    let benefitOverrides: CheckBenefitOverride[] = adjustment?.benefit_overrides || [];
    let ptdOverrides: CheckPostTaxDeductionOverride[] = adjustment?.post_tax_deduction_overrides || [];

    if (overrideType === "benefit") {
      const { company_contribution_amount, employee_contribution_amount } = formData;
      if (!company_contribution_amount && !employee_contribution_amount) {
        throw new Error("Please specify at least one of employee or company amount");
      }
      const benefitId = selectedBenefitOption?.value;
      if (!benefitId) throw new Error("Form is missing benefit selection.");
      benefitOverrides = adjustment?.benefit_overrides?.filter((bo) => bo.benefit !== benefitId) || [];
      if (company_contribution_amount || employee_contribution_amount) {
        // Evidently, our negative number formblock validation doesn't work for some users, so let's do another check here
        if (Number(company_contribution_amount) < 0 || Number(employee_contribution_amount) < 0) {
          throw new Error("Override amounts may not be negative");
        }
        benefitOverrides.push({
          benefit: benefitId,
          company_contribution_amount: company_contribution_amount || null,
          employee_contribution_amount: employee_contribution_amount || null,
        });
      }
    } else if (overrideType === "ptd") {
      const ptdId = selectedPtdOption?.value;
      if (!ptdId) throw new Error("Form is missing PTD selection.");
      ptdOverrides =
        adjustment?.post_tax_deduction_overrides?.filter((ptdo) => ptdo.post_tax_deduction !== ptdId) || [];
      // Evidently, our negative number formblock validation doesn't work for some users, so let's do another check here
      if (Number(formData.amount) < 0) {
        throw new Error("Override amounts may not be negative");
      }
      if (formData.amount) {
        ptdOverrides.push({
          post_tax_deduction: ptdId,
          amount: formData.amount,
        });
      }
    }
    return { benefitOverrides, ptdOverrides };
  };

  useEffect(() => {
    if (overrideType === "benefit" && selectedBenefitOption) {
      const benefit = employeeBenefits.find((b) => b.check_id === selectedBenefitOption.value);
      setShowExternallySyncedWarning(
        !!benefit?.guideline_id || !!benefit?.integrations?.employee_navigator?.connected
      );
    } else if (overrideType === "ptd" && selectedPtdOption) {
      const ptd = ptds.find((ptd) => ptd.check_id === selectedPtdOption.value);
      setShowExternallySyncedWarning(
        !!ptd?.guideline_id || !!ptd?.integrations?.employee_navigator?.connected
      );
    } else {
      setShowExternallySyncedWarning(false);
    }
  }, [selectedBenefitOption, selectedPtdOption, overrideType]);

  const handleSave = async (data) => {
    setLoading(true);
    try {
      // Get the list of all payroll adjustments except for the active TM's
      const newPayrollAdjustments: PayrollAdjustment[] =
        payroll?.adjustments?.filter((a) => a.team_member !== tm._id.toString()) || [];

      // Get override data from the form.
      const { benefitOverrides, ptdOverrides } = getOverridesFromFormData(data);

      // Add the TM's adjustment to the list.
      newPayrollAdjustments.push({
        ...adjustment,
        team_member: tm._id.toString(),
        benefit_overrides: benefitOverrides,
        post_tax_deduction_overrides: ptdOverrides,
      });

      await recalculatePayroll({ adjustments: newPayrollAdjustments, tms: [tm._id.toString()] });
      Notifier.success("Override saved successfully.");
      hide();
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const newPayrollAdjustments: PayrollAdjustment[] =
        payroll!.adjustments?.filter((a) => a.team_member !== tm._id.toString()) || [];

      let benefitOverrides: CheckBenefitOverride[] = adjustment?.benefit_overrides || [];
      let ptdOverrides: CheckPostTaxDeductionOverride[] = adjustment?.post_tax_deduction_overrides || [];

      // Filter out the selected override
      if (overrideToEdit?.benefitOverride) {
        benefitOverrides = benefitOverrides.filter(
          (bo) => bo.benefit !== overrideToEdit.benefitOverride?.benefit
        );
      } else if (overrideToEdit?.ptdOverride) {
        ptdOverrides = ptdOverrides.filter(
          (ptdo) => ptdo.post_tax_deduction !== overrideToEdit.ptdOverride?.post_tax_deduction
        );
      }

      // Add the TM's adjustment to the list.
      newPayrollAdjustments.push({
        ...adjustment,
        team_member: tm._id.toString(),
        benefit_overrides: benefitOverrides,
        post_tax_deduction_overrides: ptdOverrides,
      });
      await recalculatePayroll({ adjustments: newPayrollAdjustments, tms: [tm._id.toString()] });
      Notifier.success("Override deleted successfully.");
      hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the override. We're looking into it.");
    }

    setDeleting(false);
  };

  return (
    <div className="modal-background">
      <div className={"modal-wrapper form"}>
        <ModalHeader heading={overrideToEdit ? "Edit override" : "Add override"} onHide={hide} />
        {showExternallySyncedWarning && (
          <Banner
            content={
              "You are manually overriding a benefit / deduction that is managed outside of Miter, just for this payroll. Any changes you make here will not be reflected in the administrator's system."
            }
            type="warning"
          />
        )}
        <div className={"modal-body form"} style={{ paddingTop: 15 }}>
          <Formblock
            form={form}
            label="Override type"
            type="select"
            onChange={(option) => setOverrideType(option.value)}
            value={overrideTypeOptions.find((o) => o.value === overrideType)}
            className="modal"
            options={overrideTypeOptions}
            name="label"
            disabled={!!overrideToEdit}
            editing={editing}
          />
          {overrideType === "benefit" && (
            <div>
              <Formblock
                form={form}
                label="Benefit"
                type="select"
                className="modal"
                options={benefitOptions}
                value={selectedBenefitOption}
                onChange={setSelectedBenefitOption}
                name="benefit"
                disabled={!!overrideToEdit}
                editing={editing}
                requiredSelect={!overrideToEdit}
              />
              <Formblock
                form={form}
                label="Company contribution amount on this payroll"
                type="unit"
                unit="$"
                val={benefitAmountValidator}
                defaultValue={overrideToEdit?.benefitOverride?.company_contribution_amount}
                className="modal"
                name="company_contribution_amount"
                editing={editing}
              />
              <Formblock
                form={form}
                label="Employee contribution amount on this payroll"
                type="unit"
                unit="$"
                className="modal"
                val={benefitAmountValidator}
                defaultValue={overrideToEdit?.benefitOverride?.employee_contribution_amount}
                name="employee_contribution_amount"
                editing={editing}
              />
            </div>
          )}
          {overrideType === "ptd" && (
            <div>
              <Formblock
                form={form}
                label="Post-tax deduction"
                type="select"
                className="modal"
                value={selectedPtdOption}
                onChange={setSelectedPtdOption}
                options={ptdOptions}
                name="post_tax_deduction"
                disabled={!!overrideToEdit}
                editing={editing}
                requiredSelect={!overrideToEdit}
              />
              <Formblock
                form={form}
                label="Amount to deduct on this payroll"
                type="unit"
                unit="$"
                val={ptdAmountValidator}
                defaultValue={overrideToEdit?.ptdOverride?.amount}
                className="modal"
                name="amount"
                editing={editing}
              />
            </div>
          )}
          <div className="vertical-spacer"></div>
        </div>
        <ModalFooter
          submitText="Save"
          loading={loading}
          deleting={deleting}
          cancelText="Cancel"
          className={"form"}
          onSubmit={form.handleSubmit(handleSave)}
          onCancel={hide}
          showDelete={!!overrideToEdit}
          onDelete={handleDelete}
          deleteText="Delete"
        />
      </div>
    </div>
  );
};
