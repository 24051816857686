import React, { useEffect, useMemo, useState } from "react";
import { Wizard } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import { File } from "dashboard/miter";
import DocumentInfoScreen from "./DocumentInfoScreen";
import DocumentTeamAccessScreen from "./DocumentTeamAccessScreen";
import { useLookupTeam } from "dashboard/hooks/atom-hooks";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  document?: FilePickerFile;
  parentId: string;
  parentType: File["parent_type"];
  mode: "create" | "edit-info" | "edit-team-access";
};

const DocumentWizard: React.FC<Props> = ({ onExit, onComplete, parentId, parentType, mode, ...props }) => {
  const lookupTeam = useLookupTeam();
  const [document, setDocument] = useState<FilePickerFile | undefined>(props.document);

  const infoScreenName = useMemo(() => {
    if (mode !== "create") {
      return "Edit Document Info for " + document?.data?.label;
    } else if (parentType === "team_member") {
      const teamMember = lookupTeam(parentId);
      return "Upload Document for " + teamMember?.full_name;
    } else {
      return "Upload Document";
    }
  }, [lookupTeam, document, parentId, parentType]);

  const accessScreenName = useMemo(() => {
    if (mode !== "create") {
      return "Edit Team Access for " + document?.data?.label;
    } else {
      return "Team Access";
    }
  }, [document, mode]);

  useEffect(() => {
    setDocument(props.document);
  }, [props.document]);

  return (
    <Wizard onExit={onExit} onComplete={onComplete}>
      {(mode === "create" || mode === "edit-info") && (
        <DocumentInfoScreen
          name={infoScreenName}
          document={document}
          setDocument={setDocument}
          parentId={parentId}
          parentType={parentType}
        />
      )}
      {((mode === "create" && parentType !== "team_member") || mode == "edit-team-access") && (
        <DocumentTeamAccessScreen
          name={accessScreenName}
          document={document}
          setDocument={setDocument}
          mode={mode}
        />
      )}
    </Wizard>
  );
};

export default DocumentWizard;
