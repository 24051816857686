import React, { useEffect, useMemo, useRef, useState } from "react";
import { TableV2 } from "ui";
import { HolidayTabProps, predefinedHolidaySchemas } from "./utils";
import Banner from "dashboard/components/shared/Banner";
import { PredefinedHolidayId } from "backend/models/holiday-schedule";
import { useDebounce } from "use-debounce";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { ColumnConfig } from "ui/table-v2/Table";

type FederalHolidayTableRow = {
  label: string;
  _id: string;
  date: string;
  observed: string;
};

export const FederalHolidays: React.FC<HolidayTabProps> = ({
  originalHolidaySchedule,
  updateHolidaySchedule,
}) => {
  const mounted = useRef(false);
  const holidayRef = useRef(originalHolidaySchedule.enabled_predefined_holidays);
  const { cannot } = useMiterAbilities();

  const [enabledHolidays, setEnabledHolidays] = useState(holidayRef.current);

  const [debouncedEnabledHolidays, controlFuncs] = useDebounce(enabledHolidays, 2000);

  const CheckboxItem: React.FC<{ holiday: PredefinedHolidayId; disabled?: boolean }> = ({
    holiday,
    disabled,
  }) => {
    return (
      <input
        type={"checkbox"}
        checked={enabledHolidays.includes(holiday)}
        onChange={(e) => onSave(e, holiday)}
        disabled={disabled}
      />
    );
  };

  const federalHolidayColumns: ColumnConfig<FederalHolidayTableRow>[] = [
    {
      field: "label",
      headerName: "Holiday",
      dataType: "string",
    },
    {
      field: "date",
      headerName: "Upcoming date",
      type: "string",
    },
    {
      field: "observed",
      headerName: "Observed",
      dataType: "component",
      disableCellClick: true,
      cellRenderer: (params) => {
        return <CheckboxItem holiday={params.data.observed} disabled={cannot("time_off:holidays:manage")} />;
      },
    },
  ];

  const onSave = async (e: React.ChangeEvent<HTMLInputElement>, holiday: PredefinedHolidayId) => {
    let newEnabledHolidays = [...enabledHolidays];
    const holidayAlreadyEnabled = newEnabledHolidays.includes(holiday);
    const checkBoxSelected = e.target.checked;
    // avoid redundant operation
    if ((holidayAlreadyEnabled && checkBoxSelected) || (!holidayAlreadyEnabled && !checkBoxSelected)) {
      return;
    }
    if (checkBoxSelected) {
      newEnabledHolidays.push(holiday);
    } else {
      newEnabledHolidays = newEnabledHolidays.filter((federalHoliday) => federalHoliday !== holiday);
    }
    setEnabledHolidays(newEnabledHolidays);
    holidayRef.current = newEnabledHolidays;
  };

  const tableData = useMemo(() => {
    return predefinedHolidaySchemas.map((holiday) => {
      return {
        _id: holiday.id,
        label: holiday.name,
        date: holiday.getUpcomingDate().toFormat("DDDD"),
        observed: holiday.id,
      };
    });
  }, [enabledHolidays, cannot]);

  // Update holiday schedule when enabled holidays change, accounting for debounces, and then accounting for if they unmount before we've saved down the latest changes
  useEffect(() => {
    if (mounted.current) {
      updateHolidaySchedule({
        enabled_predefined_holidays: debouncedEnabledHolidays,
      });
    } else {
      mounted.current = true;
    }
    return () => {
      if (controlFuncs.isPending()) {
        updateHolidaySchedule({
          enabled_predefined_holidays: holidayRef.current,
        });
      }
    };
  }, [debouncedEnabledHolidays]);

  return (
    <div>
      <Banner
        content="Miter will ensure team members are paid a full day on observed holidays."
        type={"info"}
      ></Banner>
      <div className="flex">
        <div className="flex-1"></div>
      </div>
      <TableV2
        id={"federal-holidays-table"}
        data={tableData}
        columns={federalHolidayColumns}
        ssr={false}
        hideSearch={true}
        hideSecondaryActions={true}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        resource="holidays"
        paginationPageSize={5}
      />
    </div>
  );
};
