import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Label } from "ui";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { Notifier } from "dashboard/utils";

import CreateOffCyclePayrollModal from "../payrolls/OffCycleSettingsModal";
import { cloneDeep } from "lodash";
import { OptionCards, OptionCardConfig } from "dashboard/components/option-cards/OptionCards";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { DismissTmBodyParams } from "backend/controllers/team-members-controller";

const INVOLUNTARY_OPTIONS = [
  { label: "Voluntary", value: "voluntary" },
  { label: "Involuntary", value: "involuntary" },
];

const ELIGIBLE_FOR_REHIRE_OPTIONS = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const TERMINATION_REASON_OPTIONS = [
  {
    label: "Voluntary resignation",
    value: "voluntary_resignation",
  },
  {
    label: "Laid off",
    value: "laid_off",
  },
  {
    label: "Furlough",
    value: "furlough",
  },
  {
    label: "Role elimination",
    value: "role_elimination",
  },
  {
    label: "Temporary employment ended",
    value: "temporary_employment_ended",
  },
  {
    label: "Unsatisfactory performance",
    value: "unsatisfactory_performance",
  },
  {
    label: "Violation of company policy",
    value: "violation_of_company_policy",
  },
  {
    label: "Internal transfer: multi-entity organization",
    value: "internal_transfer",
  },
  {
    label: "Retired",
    value: "retired",
  },
  {
    label: "Disabled",
    value: "disabled",
  },
  {
    label: "Deceased",
    value: "deceased",
  },
];

export const TERMINATION_REASON_LABEL_MAP = Object.fromEntries(
  TERMINATION_REASON_OPTIONS.map((option) => [option.value, option.label])
);

type Props = {
  tm: AggregatedTeamMember;
  hide: () => void;
};

export const DismissModal: React.FC<Props> = ({ tm, hide }) => {
  const isActivePayrollTm = !!tm.check_id;

  const { register, errors, control, handleSubmit, setValue } = useForm();
  const { can } = useMiterAbilities();
  const [loading, setLoading] = useState(false);
  const [lastDay, setLastDay] = useState(() =>
    tm.end_date ? DateTime.fromISO(tm.end_date) : DateTime.now()
  );
  const [lastPayday, setLastPayday] = useState(() =>
    tm.end_date && tm?.dismissals && tm.dismissals[tm.dismissals.length - 1]?.last_payday
      ? DateTime.fromISO(tm.dismissals[tm.dismissals.length - 1]?.last_payday || "")
      : undefined
  );
  const [selectedPayrollConfiguration, setSelectedValue] = useState(
    isActivePayrollTm ? "default" : "excluded"
  );
  const [isCreatingOffCycle, setIsCreatingOffCycle] = useState(false);

  // only recalculate on lastDay changes
  useEffect(() => {
    if (!tm.end_date || (tm.end_date && lastDay && lastDay.diff(DateTime.fromISO(tm.end_date)))) {
      calculateLastPayday(selectedPayrollConfiguration);
    }
  }, [lastDay, selectedPayrollConfiguration]);

  useEffect(() => {
    setValue("last_payday", lastPayday);
  }, [lastPayday]);

  const updating = !!tm.end_date;
  const dismissals = tm.dismissals || [];
  const lastDismissal = dismissals[dismissals.length - 1];
  const existingVoluntary = lastDismissal?.involuntary ? "involuntary" : "voluntary";
  const existingNote = lastDismissal?.note;

  const calculateExistingEligibleForRehire = () => {
    if (lastDismissal?.eligible_for_rehire === undefined) return undefined;

    return lastDismissal?.eligible_for_rehire ? "yes" : "no";
  };
  const existingEligibleForRehire = calculateExistingEligibleForRehire();

  const existingTerminationReason = lastDismissal?.termination_reason;

  const options: OptionCardConfig<string>[] = useMemo(() => {
    return [
      {
        value: "default",
        header: "Include on final regular payroll",
        subheader: "Final paycheck will be the last regular payroll that includes the above end date",
      },
      {
        value: "exclude",
        header: "Remove from final regular payroll",
        subheader: "Final paycheck has already been processed",
      },
      ...(can("payrolls:create")
        ? [
            {
              value: "off-cycle",
              header: "Remove from final regular payroll and create off-cycle",
              subheader: "Create an off-cycle for this team member's final paycheck",
            },
          ]
        : []),
    ];
  }, [can]);

  const buildDismissParam = (data): DismissTmBodyParams => {
    const lastDayIso = lastDay.toISODate();
    const lastPaydayIso = lastPayday ? lastPayday.toISODate() : undefined;

    return {
      end_date: lastDayIso,
      involuntary: data.departure_type?.value === "involuntary" ? true : false,
      note: data.note,
      ignore_in_last_payroll: selectedPayrollConfiguration !== "default",
      eligible_for_rehire: data.eligible_for_rehire?.value === "yes",
      last_payday: lastPaydayIso,
      termination_reason: data.termination_reason?.value,
      last_payroll_configuration: selectedPayrollConfiguration,
      should_end_benefits: data.should_end_benefits,
    };
  };

  const validateData = () => {
    if (!tm.start_date) {
      Notifier.error("Please set a start date before dismissing.");
    } else if (!lastDay) {
      Notifier.error("Please set a last day.");
    } else if (lastDay.toISODate() < tm.start_date) {
      Notifier.error("The last day must be after the team member's start date.");
    } else {
      return true;
    }
  };

  const dismiss = async (data) => {
    if (!validateData()) return;
    setLoading(true);

    try {
      const response = await MiterAPI.team_member.dismiss(tm._id, buildDismissParam(data));

      if (response.error) throw new Error(response.error);
      Notifier.success("The employee was successfully dismissed.");

      if (selectedPayrollConfiguration == "off-cycle") {
        setIsCreatingOffCycle(true);
      } else {
        hide();
      }
    } catch (e) {
      console.error(e);
      Notifier.error("There was a problem dismissing the employee. We're looking into it!");
    }
    setLoading(false);
  };

  const update = async (data) => {
    if (!validateData()) return;
    setLoading(true);
    try {
      const newDismissals = cloneDeep(tm.dismissals) || [];
      const lastDismissal = newDismissals[newDismissals.length - 1];

      if (lastDismissal) {
        newDismissals[newDismissals.length - 1] = {
          ...lastDismissal,
          ...buildDismissParam(data),
        };
      } else {
        newDismissals.push({
          timestamp: DateTime.now().toSeconds(),
          ...buildDismissParam(data),
        });
      }
      const response = await MiterAPI.team_member.update(tm._id, {
        end_date: lastDay.toISODate(),
        dismissals: newDismissals,
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Update successful.");
      hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was a problem updating the employee. We're looking into it!");
    }
    setLoading(false);
  };

  const calculateLastPayday = async (finalPayrollConfiguration: string) => {
    if (!lastDay || !isActivePayrollTm) {
      setLastPayday(undefined);
      return;
    }

    if (finalPayrollConfiguration === "default") {
      if (!tm.pay_schedule_id) return;

      try {
        const paydays = await MiterAPI.pay_schedules.paydays(tm.pay_schedule_id);

        if (paydays.error) throw new Error(paydays.error);

        if (paydays.length > 0) {
          for (const payday of paydays) {
            const paydayIso = DateTime.fromISO(payday.payday);
            if (paydayIso >= lastDay) {
              setLastPayday(paydayIso);
              break;
            }
          }
        }
      } catch (e) {
        setLastPayday(undefined);
      }
    } else if (finalPayrollConfiguration === "exclude") {
      if (!tm._id) return;

      try {
        const result = await MiterAPI.team_member.retrieve_last_payday(tm._id);

        if (result.error) throw new Error(result.error);
        const lastPayday = result?.payday;

        const newLastPayday = lastPayday ? DateTime.fromISO(lastPayday) : undefined;
        setLastPayday(newLastPayday);
      } catch (e) {
        setLastPayday(undefined);
      }
    } else {
      // reset last payday on payroll configuration change
      setLastPayday(undefined);
    }
  };

  if (isCreatingOffCycle) {
    return (
      <CreateOffCyclePayrollModal
        hide={() => {
          setIsCreatingOffCycle(false);
          hide();
        }}
        adjustments={[{ team_member: tm._id }]}
      />
    );
  }

  return (
    <ActionModal
      onHide={hide}
      onCancel={hide}
      onSubmit={handleSubmit(updating ? update : dismiss)}
      submitText={updating ? "Update" : "Dismiss"}
      headerText={`Dismiss ${tm.full_name}`}
      loading={loading}
      showCancel={true}
      showSubmit={true}
      wrapperStyle={{ width: isActivePayrollTm ? 800 : 400 }}
      bodyStyle={{ maxHeight: 580 }}
    >
      <div className="vertical-spacer"></div>
      <div className="yellow-text-container">
        <span className="bold">Note</span>: Your state may have specific laws around dismissing. Please
        research your state&apos;s laws and stay compliant!
      </div>
      <div className="vertical-spacer"></div>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ padding: 15, width: 350 }}>
          <Formblock
            label="Last day*"
            labelInfo={`Please select the team member's last day. ${
              tm.start_date
                ? `Must be after their start date: ${DateTime.fromISO(tm.start_date).toFormat("DD")}`
                : ""
            } `}
            type="datetime"
            control={control}
            dateOnly={true}
            name="end_date"
            className={"modal"}
            rules={vals.required}
            errors={errors}
            defaultValue={lastDay}
            onChange={setLastDay}
            editing={true}
            isClearable={true}
          />
          <Formblock
            label="Voluntary or involuntary*"
            labelInfo="Is the team member leaving your company voluntarily or involuntarily?"
            type="select"
            control={control}
            requiredSelect={true}
            name="departure_type"
            className={"modal "}
            options={INVOLUNTARY_OPTIONS}
            errors={errors}
            editing={true}
            defaultValue={updating ? existingVoluntary : undefined}
          />
          <Formblock
            label="Eligible for Rehire*"
            labelInfo="Is the team member eligible to be rehired by your company."
            type="select"
            control={control}
            requiredSelect={true}
            name="eligible_for_rehire"
            className={"modal "}
            options={ELIGIBLE_FOR_REHIRE_OPTIONS}
            errors={errors}
            editing={true}
            defaultValue={updating ? existingEligibleForRehire : undefined}
          />
          <Formblock
            label="Termination Reason*"
            labelInfo="Please select a reason for the team member's termination."
            type="select"
            control={control}
            requiredSelect={true}
            name="termination_reason"
            className={"modal "}
            options={TERMINATION_REASON_OPTIONS}
            errors={errors}
            editing={true}
            defaultValue={updating ? existingTerminationReason : undefined}
          />
          <Formblock
            label="Notes*"
            labelInfo="Some notes on why the team member is leaving."
            type="paragraph"
            register={register(vals.required)}
            name="note"
            className={"modal"}
            errors={errors}
            editing={true}
            defaultValue={updating ? existingNote : undefined}
          />
        </div>
        {isActivePayrollTm && (
          <div style={{ padding: 15, width: 350 }}>
            {!updating && (
              <div>
                <Label
                  labelInfo="This section defines what payroll actions, if any, should be taken upon dismissing this employee."
                  className="modal"
                  label="Final payroll configuration"
                />
                <OptionCards
                  options={options}
                  selectedValue={selectedPayrollConfiguration}
                  setSelectedValue={async (value) => {
                    setSelectedValue(value);
                    calculateLastPayday(value);
                  }}
                  style={{ fontSize: 14 }}
                />
              </div>
            )}
            <Formblock
              name="should_end_benefits"
              type="checkbox"
              text="End benefits, allowances, and deductions on last payroll"
              errors={errors}
              defaultValue={isActivePayrollTm ? true : false}
              editing={true}
              register={register()}
              className="modal"
            />
            <Formblock
              label={`Last payday${selectedPayrollConfiguration === "default" ? "*" : ""}`}
              labelInfo={"Please select the team member's last payday. This will not affect final payroll"}
              type="datetime"
              control={control}
              dateOnly={true}
              name="last_payday"
              className={"modal"}
              errors={errors}
              value={lastPayday}
              onChange={setLastPayday}
              editing={true}
              defaultValue={lastPayday}
              rules={selectedPayrollConfiguration === "default" ? vals.required : undefined}
              isClearable={true}
            />
            <div className="vertical-spacer"></div>
          </div>
        )}
      </div>
    </ActionModal>
  );
};
