import React, { useEffect, useRef } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { Company, MiterAPI, UpdateTeamMemberParams } from "dashboard/miter";
import {
  useActiveCompany,
  useActiveCompanyId,
  useBenefitsEligibilityGroupOptions,
  useRefetchTeam,
} from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { sleep } from "dashboard/utils";
import { Option } from "ui/form/Input";
import { WizardTeamMember } from "./TeamMemberWizard";
import { notNullish } from "miter-utils";
import { BENEFITS_ELIGIBILITY_STATUS_OPTIONS } from "dashboard/pages/team-members/forms/options";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
  mode: "create" | "enroll_in_payroll";
};

type TeamMemberBenefitsInfoForm = {
  benefits_eligibility_status?: Option<"eligible" | "ineligible">;
  benefits_eligibility_groups?: Option<string>[] | null;
};

export const TeamMemberBenefitsInfoScreen: React.FC<Props> = ({ name, mode, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();

  const isMounted = useRef(true);

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();
  const refetchTeam = useRefetchTeam();
  const benefitsEligibilityGroupOptions = useBenefitsEligibilityGroupOptions();

  const form = useForm<TeamMemberBenefitsInfoForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(
      teamMember,
      { mode, company: activeCompany },
      benefitsEligibilityGroupOptions
    ),
  });

  const formData = useWatch<TeamMemberBenefitsInfoForm>({ control: form.control });

  const company = activeCompany;
  const companyHasPayroll = company?.has_payroll && company.check_company.start_date;

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0 || mode === "enroll_in_payroll") {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberBenefitsInfoForm) => {
    if (!activeCompanyId) throw new Error("No active company");

    const { benefits_eligibility_status, benefits_eligibility_groups } = params;

    const data: UpdateTeamMemberParams = {
      benefits_eligibility_status: benefits_eligibility_status?.value,
      benefits_eligibility_groups: benefits_eligibility_groups?.map((group) => group.value),
      resume_wizard_index: curIndex + 1,
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberBenefitsInfoForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }
      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Benefits information</h2>
          <p className={styles["subheader-description"]}> Add the team member&apos;s benefits information</p>
        </div>
        <div className="form-section">
          <div className="form-section">
            {companyHasPayroll && (
              <h3 className="form-section-subheader">Specify this team member&apos;s benefits eligibility</h3>
            )}
            <Formblock
              label="Benefits eligibility override"
              labelInfo="By default, all employees enrolled for payroll are eligible for benefits (with SSNs, DOBs, start dates, addresses). This field allows you to override that setting for EEs unenrolled in payroll, or for contractors that may be benefits eligible."
              type="select"
              name="benefits_eligibility_status"
              form={form}
              isClearable={true}
              placeholder="Default"
              options={BENEFITS_ELIGIBILITY_STATUS_OPTIONS}
              errors={errors}
              className="modal small-margin"
              editing={true}
            />
            <div className="vertical-spacer-small"></div>
            {!!benefitsEligibilityGroupOptions.length && (
              <Formblock
                label="Benefits eligibility groups*"
                labelInfo="The benefits eligibility groups that this employee is in."
                type="multiselect"
                name="benefits_eligibility_groups"
                form={form}
                options={benefitsEligibilityGroupOptions}
                errors={errors}
                className="modal small-margin"
                editing={true}
                requiredSelect={true}
              />
            )}
          </div>
        </div>
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  teamMember: WizardTeamMember | undefined,
  params: {
    company: Company | null;
    mode: "create" | "enroll_in_payroll";
  },
  benefitsEligibilityGroupOptions: Option<string>[]
): TeamMemberBenefitsInfoForm => {
  if (!teamMember) return {};

  const tmEligibilityGroupOptions = teamMember.benefits_eligibility_groups
    ?.map((group) => {
      return benefitsEligibilityGroupOptions.find((o) => o.value === group);
    })
    .filter(notNullish);
  return {
    benefits_eligibility_status: BENEFITS_ELIGIBILITY_STATUS_OPTIONS.find(
      (o) => o.value === teamMember.benefits_eligibility_status
    ),
    benefits_eligibility_groups: !!tmEligibilityGroupOptions?.length ? tmEligibilityGroupOptions : null,
  };
};
