import { AggregatedTeamMember, Allowance, MiterAPI } from "dashboard/miter";
import React, { useEffect, useMemo, useState } from "react";
import { Formblock, ActionModal, Button } from "ui";
import { useForm } from "react-hook-form";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { DateRange } from "ui/form/DateRangePicker";
import {
  useActiveCompanyId,
  useActivityOptions,
  useCostTypeOptions,
  useJobOptions,
  useLedgerAccountOptions,
  useLookupTeam,
  useTeamOptions,
} from "dashboard/hooks/atom-hooks";
import { useAllowanceAbilities } from "dashboard/hooks/abilities-hooks/useAllowanceAbilities";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { JobInput } from "dashboard/components/shared/JobInput";
import { AuditLogHistoryModal } from "dashboard/components/audit-logs/AuditLogHistoryModal";
import { useHasAccessToAllowanceOnJobLedgerAccount } from "dashboard/gating";

type Props = {
  allowanceToUpdate?: Allowance;
  tm?: AggregatedTeamMember;
  onSuccess: () => void;
  hide: () => void;
};

const INCLUDE_WITH_EARNING_JOB = "__ALLOCATE__";
export const includeWithEarningJobOption = {
  label: "(allocate based on hourly earnings)",
  value: INCLUDE_WITH_EARNING_JOB,
};
const INCLUDE_WITH_EARNING_ACTIVITY = "__ALLOCATE__";
export const includeWithEarningActivityOption = {
  label: "(allocate based on hourly earnings)",
  value: INCLUDE_WITH_EARNING_ACTIVITY,
};

const calculationOptions = [
  { label: "Per month", value: "per_month" },
  { label: "Per week", value: "per_week" },
  { label: "Per hour", value: "per_hour" },
  { label: "% of earnings", value: "percent" },
];

export const allowanceTypeOptions = [
  { label: "Non-taxable", value: "reimbursement" },
  { label: "Taxable cash earning", value: "earning" },
  { label: "Taxable imputed income", value: "imputed_earning" },
];

export const AllowanceModal: React.FC<Props> = ({ onSuccess, hide, allowanceToUpdate, tm }) => {
  const form = useForm({ shouldUnregister: false });
  const { register, errors, control, handleSubmit } = form;

  const activeCompanyId = useActiveCompanyId();
  const miterAbilities = useMiterAbilities();
  const allowanceAbilities = useAllowanceAbilities();
  const ledgerAccountOptions = useLedgerAccountOptions();
  const costTypeOptions = useCostTypeOptions({ defaultValue: allowanceToUpdate?.cost_type_id });
  const jobOptions = useJobOptions({
    includeCustomOption: includeWithEarningJobOption,
    defaultValue: allowanceToUpdate?.job_id,
  });

  const hasAccessToAllowanceOnJobLedgerAccount = useHasAccessToAllowanceOnJobLedgerAccount();

  const lookupTeam = useLookupTeam();
  const teamOptions = useTeamOptions({
    defaultValue: allowanceToUpdate?.team_member,
    predicate: allowanceAbilities.teamPredicate(allowanceToUpdate ? "update" : "create"),
  });

  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    start: allowanceToUpdate ? DateTime.fromISO(allowanceToUpdate?.start_date) : DateTime.now(),
    end: allowanceToUpdate?.end_date ? DateTime.fromISO(allowanceToUpdate?.end_date) : undefined,
  });
  const [allowanceType, setAllowanceType] = useState(allowanceToUpdate?.type || "reimbursement");
  const [calcMethod, setCalcMethod] = useState(allowanceToUpdate?.calculation_method || "per_hour");
  const [ignoreBenefitContributions, setIgnoreBenefitContributions] = useState(
    !!allowanceToUpdate?.ignore_benefit_contributions
  );
  const [ignoreMiscPtds, setIgnoreMiscPtds] = useState(!!allowanceToUpdate?.ignore_misc_ptds);
  const [selectedJobId, setSelectedJobId] = useState(allowanceToUpdate?.job_id || null);
  const [selectedActivityId, setSelectedActivityId] = useState(allowanceToUpdate?.activity_id || null);
  const [selectedTmId, setSelectedTmId] = useState(allowanceToUpdate?.team_member || tm?._id);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);

  const activityOptions = useActivityOptions(selectedJobId, {
    includeCustomOption: includeWithEarningActivityOption,
  });
  const readonly =
    (allowanceAbilities.cannot("update", allowanceToUpdate) && !!allowanceToUpdate) ||
    miterAbilities.cannot("allowances:create");

  const filteredAllowanceTypeOptions = useMemo(() => {
    const tm = lookupTeam(selectedTmId);
    if (tm?.employment_type === "contractor") {
      setAllowanceType("reimbursement");
      return allowanceTypeOptions.filter((o) => o.value === "reimbursement");
    } else {
      return allowanceTypeOptions;
    }
  }, [selectedTmId, allowanceTypeOptions]);

  useEffect(() => {
    if (calcMethod === "per_hour" || calcMethod === "percent") {
      if (!selectedJobId) setSelectedJobId(INCLUDE_WITH_EARNING_JOB);
      if (!selectedActivityId) setSelectedActivityId(INCLUDE_WITH_EARNING_ACTIVITY);
    }
  }, [calcMethod]);

  const save = async (data) => {
    setLoading(true);
    try {
      if (!activeCompanyId) {
        throw new Error("No company found");
      } else if (allowanceToUpdate && allowanceAbilities.cannot("update", allowanceToUpdate)) {
        throw new Error("You do not have permission to update allowances");
      }
      const tmId = tm?._id || allowanceToUpdate?.team_member || data.team_member?.value;
      if (!tmId) throw new Error("No team member selected");

      const cleanedData: Partial<Allowance> = {
        company: activeCompanyId,
        team_member: tmId,
        description: data.description,
        amount: Number(data.amount),
        calculation_method: data.calculation_method?.value,
        start_date: dateRange.start?.toISODate(),
        end_date: dateRange.end?.toISODate() || null,
        ignore_benefit_contributions: ignoreBenefitContributions,
        ignore_misc_ptds: ignoreMiscPtds,
        ledger_account_id: data.ledger_account_id?.value || null,
        on_job_ledger_account_id: data.on_job_ledger_account_id?.value || null,
        type: allowanceType,
        job_id: selectedJobId || null,
        activity_id: selectedActivityId || null,
        cost_type_id: data.cost_type_id?.value || null,
      };

      let response;
      if (allowanceToUpdate) {
        response = await MiterAPI.allowances.update([allowanceToUpdate._id.toString()], cleanedData);
      } else {
        response = await MiterAPI.allowances.create(cleanedData);
      }

      if (response.error) {
        throw new Error(response.error);
      }

      Notifier.success(`Allowance successfully ${allowanceToUpdate ? "updated" : "created"}`);
      onSuccess();
      hide();
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const handleAllowanceTypeChange = (o) => {
    setAllowanceType(o.value);
    if (o.value === "reimbursement") {
      setIgnoreBenefitContributions(false);
      setIgnoreMiscPtds(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex space-between width-100-percent">
        {allowanceToUpdate ? "Update" : "Create"} allowance
        {allowanceToUpdate && (
          <Button
            className="button-1"
            onClick={() => setShowAuditLogModal(true)}
            text="View Audit Log"
            style={{ marginLeft: "auto", marginRight: 10 }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <ActionModal
        submitText={allowanceToUpdate ? "Update" : "Create"}
        onHide={hide}
        cancelText="Cancel"
        onCancel={hide}
        onSubmit={handleSubmit(save)}
        loading={loading}
        headerText={renderHeader()}
        showSubmit={
          allowanceToUpdate
            ? allowanceAbilities.can("update", allowanceToUpdate)
            : miterAbilities.can("allowances:create")
        }
        showCancel={true}
        bodyStyle={{ overflow: "auto", maxHeight: "80vh" }}
      >
        <div className="vertical-spacer"></div>
        {!tm && (
          <Formblock
            label="Team member*"
            labelInfo={"The team member who this allowance is for"}
            type="select"
            name="team_member"
            control={control}
            className="modal"
            errors={errors}
            editing={true}
            options={teamOptions}
            onChange={(o) => setSelectedTmId(o.value)}
            value={teamOptions.find((o) => o.value === selectedTmId)}
            disabled={!!allowanceToUpdate?.team_member || readonly}
          />
        )}
        <Formblock
          label="Description*"
          className="modal"
          type="text"
          register={register(vals.required)}
          name="description"
          errors={errors}
          defaultValue={allowanceToUpdate?.description}
          editing={true}
          disabled={readonly}
        />
        <Formblock
          label="Type*"
          labelInfo="Imputed income refers to benefits that aren't part of an employee's cash salary or wages but should still be taxed."
          className="modal benefit"
          type="select"
          options={filteredAllowanceTypeOptions}
          onChange={handleAllowanceTypeChange}
          value={filteredAllowanceTypeOptions.find((o) => o.value === allowanceType)}
          defaultValue={allowanceType}
          requiredSelect={true}
          name="type"
          control={control}
          errors={errors}
          style={{ width: "100%" }}
          editing={true}
          disabled={readonly || !!allowanceToUpdate}
        />
        <div className="flex" style={{ width: "100%" }}>
          <Formblock
            label="Amount*"
            className="modal benefit"
            type={calcMethod === "percent" ? "percent" : "unit"}
            unit="$"
            register={register(vals.numberValidator({ excludeNegatives: true }))}
            name="amount"
            defaultValue={allowanceToUpdate?.amount}
            errors={errors}
            style={{ width: "100%" }}
            editing={true}
            disabled={readonly}
          />
          <div style={{ width: 65 }}></div>
          <Formblock
            label="Calculation*"
            className="modal benefit"
            type="select"
            options={calculationOptions}
            defaultValue={calcMethod}
            onChange={(o) => setCalcMethod(o.value)}
            value={calculationOptions.find((o) => o.value === calcMethod)}
            requiredSelect={true}
            name="calculation_method"
            control={control}
            errors={errors}
            style={{ width: "100%" }}
            editing={true}
            disabled={readonly}
          />
        </div>

        <Formblock
          label="Effective dates"
          labelInfo="Pay periods that overlap with the below range will include the allowance. Unlike benefits and post-tax deductions, the payday is not considered."
          className="modal benefit"
          type="daterange"
          control={control}
          onChange={setDateRange}
          value={dateRange}
          name="end_date"
          errors={errors}
          topOfInput={true}
          editing={true}
          disabled={readonly}
        />
        <JobInput
          label="Job"
          type="select"
          name="job_id"
          form={form}
          control={control}
          className="modal"
          errors={errors}
          onChange={(e) => {
            setSelectedJobId(e?.value);
            setSelectedActivityId(null);
          }}
          editing={true}
          options={jobOptions}
          value={jobOptions.find((o) => o.value === selectedJobId)}
          isClearable={true}
          disabled={readonly}
          customOption={includeWithEarningJobOption}
        />
        <Formblock
          label="Activity"
          type="select"
          name="activity_id"
          control={control}
          className="modal"
          onChange={(e) => setSelectedActivityId(e?.value)}
          errors={errors}
          editing={true}
          options={activityOptions}
          value={activityOptions.find((o) => o.value === selectedActivityId) || null}
          isClearable={true}
          disabled={readonly}
        />
        <Formblock
          label="GL account"
          labelInfo={"The ledger account this allowance will be posted to"}
          type="select"
          name="ledger_account_id"
          control={control}
          className="modal"
          errors={errors}
          editing={true}
          options={ledgerAccountOptions}
          defaultValue={allowanceToUpdate?.ledger_account_id}
          isClearable={true}
          disabled={readonly}
        />
        {hasAccessToAllowanceOnJobLedgerAccount && (
          <Formblock
            label="On job ledger account"
            labelInfo={"The ledger account this allowance will be posted to if time is allocated to job"}
            type="select"
            name="on_job_ledger_account_id"
            control={control}
            className="modal"
            errors={errors}
            editing={true}
            options={ledgerAccountOptions}
            defaultValue={allowanceToUpdate?.on_job_ledger_account_id}
            isClearable={true}
            disabled={readonly}
          />
        )}
        <Formblock
          label="Cost type override"
          labelInfo={
            "The cost type this allowance will be associated with. If left blank, Miter will use the default earning cost type."
          }
          type="select"
          name="cost_type_id"
          control={control}
          className="modal"
          errors={errors}
          editing={true}
          options={costTypeOptions}
          defaultValue={allowanceToUpdate?.cost_type_id}
          isClearable={true}
          disabled={readonly}
        />

        {allowanceType !== "reimbursement" && (
          <>
            <div style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
              <input
                type="checkbox"
                onChange={(e) => setIgnoreBenefitContributions(e.target.checked)}
                checked={ignoreBenefitContributions}
                disabled={readonly}
              />
              <span style={{ marginLeft: 6, fontSize: 15, color: "rgb(46, 46, 46)" }}>
                Ignore for benefits contributions
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
              <input
                type="checkbox"
                onChange={(e) => setIgnoreMiscPtds(e.target.checked)}
                checked={ignoreMiscPtds}
                disabled={readonly}
              />
              <span style={{ marginLeft: 6, fontSize: 15, color: "rgb(46, 46, 46)" }}>
                Ignore for misc post-tax deductions
              </span>
            </div>
          </>
        )}
        <div className="vertical-spacer"></div>
      </ActionModal>
      {showAuditLogModal && allowanceToUpdate && (
        <AuditLogHistoryModal
          itemId={allowanceToUpdate._id}
          type="allowance"
          onHide={() => setShowAuditLogModal(false)}
        />
      )}
    </>
  );
};
