/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FlatfileButton,
  FlatfileSettings,
  FlatfileResults,
  IDataHookResponse,
  ScalarDictionaryWithCustom,
} from "@flatfile/react";
import { columns } from "dashboard/pages/integrations/IntegrationReports/ContractorsPlanCensus";
import React, { useMemo, useState } from "react";
import { LoadingModal } from "ui";
import { FlatfileImportButton, FlatfileImportButtonProps } from "../shared/FlatfileImportButton";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { bulkFlatfileValidate } from "dashboard/utils/flatfile";
import { themeObject } from "dashboard/utils/flatFileThemeSettings";

export type ImportField = FlatfileSettings["fields"][number] & {
  hook?: (value: any) => Promise<IDataHookResponse[string]> | IDataHookResponse[string] | undefined;
};

type Props = {
  /** Unique identifier for the importer - used to point the right import results to this component */
  id: string;
  /** Title of the importer */
  title?: string;
  /** Name of the items being imported: i.e. jobs, employee benefits, etc */
  resource: string;
  /** The function that will be called when the data that is being imported is ready to be saved */
  onSave: (results: FlatfileResults) => Promise<void>;
  /** Configure the fields that are being imported */
  fields: ImportField[];
  //** Custom visual component to show for the button */
  ImportButtonComponent?: React.ComponentType<FlatfileImportButtonProps>;
  /** Whether to preload the importer or not */
  preload?: boolean;
};

export const Importer: React.FC<Props> = ({
  id,
  title,
  resource,
  onSave,
  fields,
  ImportButtonComponent = FlatfileImportButton,
  preload = true,
}) => {
  const activeCompanyId = useActiveCompanyId();
  const [saving, setSaving] = useState(false);

  const handleRecordChange = async (row: ScalarDictionaryWithCustom): Promise<IDataHookResponse> => {
    const record: Record<string, any> = {};

    for (const field of fields) {
      const { key, hook } = field;
      if (!key || !hook) continue;

      try {
        record[key] = await hook(row);
      } catch (err) {
        // an error here means the actual validator crashed - surface internally
        console.error(
          `Error validating Flatfile record for importer id ${id}, company ${activeCompanyId}, field ${field}`,
          err
        );
      }
    }
    return record;
  };

  const handleSave = async (results: FlatfileResults) => {
    setSaving(true);
    await onSave(results);
    setSaving(false);
  };

  const settings = useMemo(
    () => ({
      type: resource,
      // Need to remove the hook from the field otherwise flatfile gets angry
      fields: fields.map(({ hook, ...field }) => field),
      title: title,
      ...themeObject,
    }),
    [resource, fields, title]
  );

  const customer = useMemo(
    () => ({ companyId: activeCompanyId!, userId: activeCompanyId! }),
    [activeCompanyId]
  );

  const fieldHooks = useMemo(() => {
    return fields.reduce((acc, { key, hook }) => {
      if (!key || !hook) return acc;
      return {
        ...acc,
        [key]: (values) => bulkFlatfileValidate(values, hook),
      };
    }, {});
  }, [columns]);

  return (
    <>
      <FlatfileButton
        licenseKey={process.env.REACT_APP_FLATFILE_LICENSE_KEY!}
        customer={customer}
        settings={settings}
        fieldHooks={fieldHooks}
        render={(_importer, launch) => (
          <ImportButtonComponent launch={launch} resource={resource} title={title || "Bulk import"} />
        )}
        onData={handleSave}
        onRecordChange={handleRecordChange}
        onRecordInit={handleRecordChange}
        preload={preload}
      />
      {saving && <LoadingModal text={`Importing ${resource}...`} />}
    </>
  );
};
