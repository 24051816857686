import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAgaveLink } from "@agave-api/react-agave-link";
import { Button, Notifier } from "ui";
import AppContext from "dashboard/contexts/app-context";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { IS_PRODUCTION } from "dashboard/utils/environment";

type Props = {
  integration: MiterIntegrationForCompany;
};

export const AgaveLink: React.FC<Props> = ({ integration }) => {
  const { getIntegrations } = useContext(AppContext);
  const [linkToken, setLinkToken] = useState<string | undefined>();
  const [authenticating, setAuthenticating] = useState(false);

  const getLinkToken = async () => {
    if (!integration?.connection || !integration.agave) return;
    try {
      const response = await MiterAPI.integrations.get_agave_link_token(integration.connection._id);
      if (response.error) throw new Error(response.error);
      setLinkToken(response.token);
    } catch (e) {
      console.error(e);
      Notifier.error(`Could not connect to ${integration?.label}. We're looking into it!`);
    }
  };

  useEffect(() => {
    getLinkToken();
  }, []);

  const sendPublicToken = async (token: string) => {
    if (!integration?.connection || !integration.agave) return;
    setAuthenticating(true);
    try {
      const response = await MiterAPI.integrations.exchange_agave_public_token(
        integration.connection._id,
        token
      );
      if (response.error) throw new Error(response.error);
      await getIntegrations();
      Notifier.success(`Successfully connected to ${integration.label}`);
    } catch (e) {
      console.error(e);
      Notifier.error(`Could not connect to ${integration?.label}. We're looking into it!`);
    }
    setAuthenticating(false);
  };

  const onSuccess = useCallback(sendPublicToken, [getIntegrations]);

  const onExit = useCallback((error) => {
    if (error) {
      console.error(error);
      Notifier.error(`There was an error: ${error.message}`);
    }
  }, []);

  /*
   * For a full list of available options, see https://docs.agaveapi.com/agave-link/component
   */
  const { openLink, isReady } = useAgaveLink({
    referenceId: integration.connection?._id.toString(),

    // Replace linkToken with what you got in step 2 on https://docs.agaveapi.com/quickstart#step-2-generate-link-token
    linkToken,
    showSandboxSourceSystems: !IS_PRODUCTION, // Only for local development
    showProductionSourceSystems: true,
    sourceSystem: sourceSystemKeyMap[integration?.key || "-"],
    sourceSystemEnvironment: "sandbox",

    onSuccess,
    onExit,
  });

  return (
    <div>
      <Button
        onClick={openLink}
        loading={!isReady || authenticating}
        className="button-2 no-margin"
        text={`Connect Miter with ${integration?.label}`}
      />
    </div>
  );
};

const sourceSystemKeyMap = {
  foundation: "foundation",
  vista: "vista-hosted",
};
