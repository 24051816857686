import { useAuthToken } from "dashboard/hooks/atom-hooks";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import { useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const useMiterGuidesRedirect = (): React.MutableRefObject<boolean> => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const miterGuidesRedirect = searchParams.get("miterGuidesRedirect") === "true";
  const queryLocation = (searchParams.get("location") || "") + location.hash;
  const authToken = useAuthToken();

  // Holding in ref so internal navigation doesn't trigger a re-render by removing the query param from the url
  const redirectToGuidesRef = useRef(miterGuidesRedirect);

  useEffect(() => {
    if (!miterGuidesRedirect) return;

    if (authToken) {
      goToMiterGuide(queryLocation, true);
    } else {
      localStorage.setItem("guidesRedirect", queryLocation);
    }
  }, [miterGuidesRedirect]); // don't add queryLocation to the deps list because of the redirects

  return redirectToGuidesRef;
};
