import { LookupAtomFunction } from "dashboard/atoms";
import {
  useActiveCompany,
  useLookupDepartment,
  useLookupJob,
  useLookupPolicy,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import {
  AggregatedJob,
  AggregatedTeamMember,
  AggregatedTimesheet,
  AutomaticBreakTimeSettings,
  Company,
  Department,
  Job,
  Policy,
  PolicyRule,
  TeamMember,
} from "dashboard/miter";
import { useCallback } from "react";
import { TimeSheetPolicySignOff, TimesheetPolicyField, TimesheetPolicyFields } from "backend/models/policy";
import { findItemPolicyRule } from "../approvals";
import { DraftTimesheet } from "dashboard/pages/timesheets/BulkCreateTimesheets/BulkCreateTimesheets";

type UseTimesheetPolicy = {
  policy: Policy | undefined;
  meetsPolicyRequirements: () => boolean;
  matchingPolicyRule: PolicyRule | null | undefined;
  isFieldHidden: (field: TimesheetPolicyField) => boolean;
  isFieldVisible: (field: TimesheetPolicyField) => boolean;
  isFieldRequired: (field: TimesheetPolicyField) => boolean;
  needsAttentionMessages: string[];
  signOff: TimeSheetPolicySignOff | null | undefined;
};

export type TimesheetPolicyItem = AggregatedTimesheet | DraftTimesheet | null | undefined;

/** Reusable hook to get the policy and/or policy rule for an item */
export const useTimesheetPolicy = (item: TimesheetPolicyItem): UseTimesheetPolicy => {
  const { buildPolicy } = useTimesheetPolicies();
  return buildPolicy(item);
};

export const useAutomaticBreakTimeSettings = (
  item: TimesheetPolicyItem
): AutomaticBreakTimeSettings | null => {
  const company = useActiveCompany();
  const companySetting = company?.settings?.timesheets.automatic_break_time;

  const { policy } = useTimesheetPolicy(item);
  if (!policy || !policy.config || !("automatic_break_time" in policy.config)) return companySetting || null;
  const policySetting = policy.config.automatic_break_time;

  return policySetting || companySetting || null;
};

type UseTimesheetPolicies = {
  buildPolicy: (item: TimesheetPolicyItem) => UseTimesheetPolicy;
};

export const useTimesheetPolicies = (): UseTimesheetPolicies => {
  /*********************************************************
   * Hooks
   **********************************************************/
  const company = useActiveCompany();
  const lookupPolicy = useLookupPolicy();
  const lookupJob = useLookupJob();
  const lookupDepartment = useLookupDepartment();
  const lookupTeam = useLookupTeam();

  const legacyFieldSettings = company?.settings.timesheets.field_requirements;
  const hasEnabledPolicies = !!company?.settings.timesheets.enable_timesheet_policies;

  /*********************************************************
   * Lookup functions to get the job, team, and department
   **********************************************************/
  const getJob = useCallback(
    (item: TimesheetPolicyItem): AggregatedJob | Job | undefined => {
      if (!item || !("job" in item)) return;

      if (typeof item.job === "string") {
        return lookupJob(item.job);
      } else {
        return item.job;
      }
    },
    [lookupJob]
  );

  const getTeamMember = useCallback(
    (item: TimesheetPolicyItem): AggregatedTeamMember | TeamMember | undefined => {
      if (!item || !("team_member" in item)) return;

      if (typeof item.team_member === "string") {
        return lookupTeam(item.team_member);
      } else {
        return item.team_member;
      }
    },
    [lookupTeam]
  );

  const getDepartment = useCallback(
    (item: TimesheetPolicyItem): Department | undefined => {
      if (!item) return;

      const departmentSource = company?.settings.departments?.department_source;

      let deptId: string | undefined | null;
      if ("department_id" in item && item.department_id) {
        deptId = item.department_id;
      } else if (departmentSource === "job") {
        deptId = getJob(item)?.department_id || getTeamMember(item)?.department_id;
      } else {
        deptId = getTeamMember(item)?.department_id;
      }

      return lookupDepartment(deptId);
    },
    [company?.settings.departments?.department_source, getJob, getTeamMember, lookupDepartment]
  );

  /*********************************************************
   * Policy getters
   **********************************************************/
  const getTeamMemberPolicy = useCallback(
    (item: TimesheetPolicyItem): Policy | undefined => {
      const teamMember = getTeamMember(item);
      return lookupPolicy(teamMember?.timesheet_policy_id);
    },
    [getTeamMember, lookupPolicy]
  );

  const getJobPolicy = useCallback(
    (item: TimesheetPolicyItem): Policy | undefined => {
      const job = getJob(item);
      return lookupPolicy(job?.timesheet_policy_id);
    },
    [getJob, lookupPolicy]
  );

  const getDepartmentPolicy = useCallback(
    (item: TimesheetPolicyItem): Policy | undefined => {
      const department = getDepartment(item);
      return lookupPolicy(department?.timesheet_policy_id);
    },
    [getDepartment, lookupPolicy]
  );

  const getPolicy = useCallback(
    (item: TimesheetPolicyItem): Policy | undefined => {
      if (!hasEnabledPolicies) return;
      const teamMemberPolicy = getTeamMemberPolicy(item);
      const jobPolicy = getJobPolicy(item);
      const departmentPolicy = getDepartmentPolicy(item);
      const companyPolicy = getCompanyPolicy();

      return teamMemberPolicy || jobPolicy || departmentPolicy || companyPolicy;
    },
    [company?.settings?.timesheets?.default_policy_id, getDepartmentPolicy, getJobPolicy, hasEnabledPolicies]
  );

  const getCompanyPolicy = useCallback((): Policy | undefined => {
    return lookupPolicy(company?.settings?.timesheets?.default_policy_id);
  }, [company?.settings?.timesheets?.default_policy_id, lookupPolicy]);

  const getSignOff = useCallback(
    (
      policy: Policy | undefined,
      policyRule: PolicyRule | undefined | null
    ): TimeSheetPolicySignOff | null | undefined => {
      if (!policyRule || !policy) return null;

      if ("sign_off" in policyRule) {
        return policyRule.sign_off as TimeSheetPolicySignOff;
      }
    },
    [findItemPolicyRule, getPolicy]
  );

  const buildPolicy = useCallback(
    (item: TimesheetPolicyItem): UseTimesheetPolicy => {
      const policy = getPolicy(item);
      const matchingPolicyRule = findItemPolicyRule(item, policy);
      const signOff = getSignOff(policy, matchingPolicyRule);

      // Helper function to determine the field's policy status based on the new and old policy settings.
      const getFieldPolicyStatus = (field: TimesheetPolicyField, status: "hidden" | "required"): boolean => {
        if (hasEnabledPolicies) {
          if (!policy || !matchingPolicyRule?.fields) return false;

          const fields = matchingPolicyRule.fields as TimesheetPolicyFields;
          return fields[field] === status;
        } else {
          let finalField: string = field;
          if (field === "classification_override") {
            finalField = "classification";
          } else if (field === "injury") {
            finalField = "injury_form";
          }

          return !!(
            legacyFieldSettings &&
            finalField in legacyFieldSettings.dashboard &&
            legacyFieldSettings.dashboard[finalField] === status
          );
        }
      };

      // Use the helper function to check if the field is hidden.
      const isFieldHidden = (field: TimesheetPolicyField): boolean => {
        return getFieldPolicyStatus(field, "hidden");
      };

      // Use the helper function to check if the field is required.
      const isFieldRequired = (field: TimesheetPolicyField): boolean => {
        const baseRequiredStatus = getFieldPolicyStatus(field, "required");

        if (field === "classification_override") {
          return baseRequiredStatus && getJob(item)?.pay_rate_group != null;
        }

        return baseRequiredStatus;
      };

      // Use the isFieldHidden function to check if the field is visible.
      const isFieldVisible = (field: TimesheetPolicyField): boolean => {
        return !isFieldHidden(field);
      };

      const meetsPolicyRequirements = (): boolean => {
        const {
          job,
          activity,
          injury,
          clock_in_photo,
          clock_out_photo,
          classification_override: classification,
          equipment_ids,
        } = item || {};

        // If this item has been kicked back, it doesn't meet the policy requirements
        if (item && "approval_stage" in item && item.approval_stage?.kick_back) return false;

        if (isFieldRequired("activity") && !activity) return false;
        if (isFieldRequired("job") && !job) return false;
        if (isFieldRequired("clock_in_photo") && !clock_in_photo) return false;
        if (isFieldRequired("clock_out_photo") && !clock_out_photo) return false;
        if (isFieldRequired("equipment_ids") && !equipment_ids?.length) return false;

        if (hasEnabledPolicies) {
          if (isFieldRequired("injury") && injury == null) return false;
          if (isFieldRequired("classification_override") && !classification) return false;
        } else {
          if (isFieldRequired("injury") && injury == null) return false;
          if (isFieldRequired("classification_override") && !classification) return false;
        }

        if (signOff && item && "sign_off" in item && item?.sign_off?.status !== "completed") return false;

        return true;
      };

      const needsAttentionMessages = (() => {
        const reasons: string[] = [];

        const {
          job,
          activity,
          injury,
          clock_in_photo,
          clock_out_photo,
          classification_override: classification,
        } = item || {};

        // If this item has been kicked back, it doesn't meet the policy requirements
        if (item && "approval_stage" in item && item.approval_stage?.kick_back) {
          reasons.push(
            "Item kicked back: " +
              (item.approval_stage.kick_back.notes
                ? item.approval_stage.kick_back.notes
                : " please fix or contact the your approver.")
          );
        }

        const activityFailed = isFieldRequired("activity") && !activity;
        if (activityFailed) reasons.push("Activity is required but missing.");

        const jobFailed = isFieldRequired("job") && !job;
        if (jobFailed) reasons.push("Job is required but missing.");

        const clockInPhotoFailed = isFieldRequired("clock_in_photo") && !clock_in_photo;
        if (clockInPhotoFailed) reasons.push("Clock-in photo is required but missing.");

        const clockOutPhotoFailed = isFieldRequired("clock_out_photo") && !clock_out_photo;
        if (clockOutPhotoFailed) reasons.push("Clock-out photo is required but missing.");

        if (hasEnabledPolicies) {
          const injuryFailed = isFieldRequired("injury") && injury == null;
          if (injuryFailed) reasons.push("Injury field is required but missing.");

          const classificationFailed = isFieldRequired("classification_override") && !classification;
          if (classificationFailed) reasons.push("Classification override is required but missing.");
        } else {
          const injuryFailed = isFieldRequired("injury") && injury == null;
          if (injuryFailed) reasons.push("Injury field is required but missing.");

          const classificationFailed = isFieldRequired("classification_override") && !classification;
          if (classificationFailed) reasons.push("Classification override is required but missing.");
        }

        if (signOff && item && "sign_off" in item && item?.sign_off?.status !== "completed")
          reasons.push("This item requires a sign-off.");

        return reasons;
      })();

      return {
        meetsPolicyRequirements,
        needsAttentionMessages,
        matchingPolicyRule,
        isFieldHidden,
        isFieldVisible,
        isFieldRequired,
        signOff,
        policy,
      };
    },
    [getDepartment, getDepartmentPolicy, getJob, getJobPolicy, getPolicy, getSignOff, hasEnabledPolicies]
  );

  return { buildPolicy };
};
/** Regular function to get a timesheet's policy */
export const findTimesheetPolicy = (
  item: AggregatedTimesheet,
  data: { company: Company; lookupPolicy: LookupAtomFunction<Policy> }
): Policy | undefined => {
  const { company, lookupPolicy } = data;
  const { settings } = company;

  if (item.job?.timesheet_policy_id) {
    return lookupPolicy(item.job.timesheet_policy_id);
  } else if (item.department?.timesheet_policy_id) {
    return lookupPolicy(item.department.timesheet_policy_id);
  } else if (settings.timesheets.default_policy_id) {
    return lookupPolicy(settings.timesheets.default_policy_id);
  }
};
