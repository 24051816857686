import React, { useEffect, useState } from "react";
import { MiterAPI, PaySchedule } from "../../../../miter";
import { formatDate } from "dashboard/utils/utils";
import { CheckPayday } from "backend/utils/check/check-types";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

type Props = {
  ps: PaySchedule;
};

type PaydayTableEntry = {
  pay_period?: string | null;
  payday?: string | null;
  approval_deadline?: string | null;
  _id: string;
};

export const PaySchedulePaydays: React.FC<Props> = (props) => {
  const [tableData, setTableData] = useState<PaydayTableEntry[] | undefined>();
  const [loading, setLoading] = useState(false);

  const getUpcomingPaydays = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.pay_schedules.paydays(props.ps._id);
      if (response.error) throw new Error(response.error);
      const cleanedPaydays = cleanPaydays(response);
      setTableData(cleanedPaydays);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const cleanPaydays = (paydays: CheckPayday[]) => {
    const cleanedPaydays: PaydayTableEntry[] = [];
    let index = 0;
    for (const payday of paydays) {
      cleanedPaydays.push({
        _id: index.toString(),
        pay_period: formatDate(payday.period_start, payday.period_end),
        payday: formatDate(payday.payday),
        approval_deadline: formatDate(payday.approval_deadline),
      });
      index += 1;
    }
    return cleanedPaydays;
  };

  const tableColumns: ColumnConfig<PaydayTableEntry>[] = [
    {
      field: "pay_period",
      headerName: "Pay period",
      dataType: "string",
    },
    {
      field: "payday",
      headerName: "Payday",
      dataType: "string",
    },
    {
      field: "approval_deadline",
      headerName: "Approval deadline",
      dataType: "string",
    },
  ];

  useEffect(() => {
    getUpcomingPaydays();
  }, []);
  return (
    <TableV2
      id="upcoming-paydays-table"
      resource="upcoming paydays"
      data={tableData}
      hideSelectColumn
      rowSelectDisabled={() => true}
      hideSearch
      hideSecondaryActions
      columns={tableColumns}
      paginationPageSize={20}
      containerStyle={{ height: 300 }}
      isLoading={loading}
    />
  );
};
