import { useCallback, useEffect, useState } from "react";
import { TimesheetEarningType } from "backend/models/timesheet";

export type UseEarningTypeArray = {
  loading: boolean;
  currentEarningTypeArray: EarningTypeMapping[];
  setCurrentEarningTypesArray: (currentEarningTypeArray: EarningTypeMapping[]) => void;
  handleAddEarningTypeMapping: () => void;
  handleChangeTimeType: (index: number, value: string) => void;
  handleChangeEarningType: (index: number, value: TimesheetEarningType) => void;
  handleDeleteEarningTypeMapping: (index: number) => void;
  handleEarningTypeMapSave: (
    callback: (newEarningTypeMap: Record<string, TimesheetEarningType>) => void
  ) => Promise<void>;
};

export type EarningTypeMapping = { timeType: string; earningType?: TimesheetEarningType };

export const useEarningTypeArray = (
  earningTypeMapping?: Record<string, TimesheetEarningType>
): UseEarningTypeArray => {
  const [loading, setLoading] = useState(false);
  const [currentEarningTypeArray, setCurrentEarningTypesArray] = useState<EarningTypeMapping[]>([]);

  const setEarningTypes = useCallback(() => {
    if (earningTypeMapping) {
      const mappingArray = Object.keys(earningTypeMapping)
        .map(
          (timeType): EarningTypeMapping => ({
            timeType,
            earningType: earningTypeMapping[timeType],
          })
        )
        .sort((a, b) => {
          return a.timeType.localeCompare(b.timeType);
        });

      setCurrentEarningTypesArray(mappingArray);
    }
  }, [earningTypeMapping]);

  const handleAddEarningTypeMapping = () => {
    const newEarningTypeMappingArray = [...currentEarningTypeArray];
    newEarningTypeMappingArray.push({ timeType: "", earningType: "hourly" });
    setCurrentEarningTypesArray(newEarningTypeMappingArray);
  };

  const handleChangeTimeType = (index: number, value: string) => {
    const newEarningTypeMappingArray = [...currentEarningTypeArray];
    newEarningTypeMappingArray[index]!.timeType = value;
    setCurrentEarningTypesArray(newEarningTypeMappingArray);
  };

  const handleChangeEarningType = (index: number, value: TimesheetEarningType) => {
    const newEarningTypeMappingArray = [...currentEarningTypeArray];
    newEarningTypeMappingArray[index]!.earningType = value;
    setCurrentEarningTypesArray(newEarningTypeMappingArray);
  };

  const handleDeleteEarningTypeMapping = (index: number) => {
    const newEarningTypeMappingArray = [...currentEarningTypeArray];
    newEarningTypeMappingArray.splice(index, 1);
    setCurrentEarningTypesArray(newEarningTypeMappingArray);
  };

  const handleEarningTypeMapSave = async (
    callback: (newEarningTypeMap: Record<string, TimesheetEarningType>) => void
  ) => {
    setLoading(true);
    const newEarningTypeMap = currentEarningTypeArray.reduce((acc, { timeType, earningType }) => {
      if (timeType && earningType) {
        acc[timeType] = earningType;
      }
      return acc;
    }, {} as Record<string, TimesheetEarningType>);
    callback(newEarningTypeMap);
    setLoading(false);
  };

  useEffect(() => {
    setEarningTypes();
  }, []);

  return {
    currentEarningTypeArray,
    setCurrentEarningTypesArray,
    handleAddEarningTypeMapping,
    handleChangeTimeType,
    handleChangeEarningType,
    handleDeleteEarningTypeMapping,
    loading,
    handleEarningTypeMapSave,
  };
};
