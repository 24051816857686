import React, { useContext } from "react";
import { BasicModal, Formblock } from "ui";
import { CheckPaymentWireDetails } from "backend/utils/check/check-types";
import { usdString } from "ui";
import PayrollContext from "./payrollContext";
import { DateTime } from "luxon";

export const WireDetailsModal: React.FC<{ wireDetails: CheckPaymentWireDetails | null; hide: () => void }> =
  ({ wireDetails, hide }) => {
    const { payroll } = useContext(PayrollContext);
    if (!wireDetails || !payroll) return null;

    const checkPayroll = payroll.check_payroll;

    const deadline =
      // We should only use the approval deadline if the funding payment method has already changed to `wire` on the backend, since then we know it's correct
      checkPayroll.funding_payment_method === "wire" && checkPayroll.approval_deadline
        ? DateTime.fromISO(checkPayroll.approval_deadline).toFormat("t ZZZZ 'on' DD")
        : null;

    return (
      <BasicModal
        headerText={`Wire details`}
        button2Text="Okay"
        button2Action={hide}
        wrapperStyle={{ width: "400px" }}
      >
        <div className="yellow-text-container">
          Use the following information when wiring the funds.{" "}
          <strong>Make sure to make a wire transfer, not a normal ACH.</strong> If you have already wired the
          funds, no further action is required.
          <br />
          <br />
          Please wire the <strong>EXACT</strong> amount shown below {deadline ? `by ${deadline}` : "ASAP"}. If
          wiring funds for multiple payrolls, please send a separate wire for each payroll.
        </div>
        <div className="vertical-spacer-small" />
        <Formblock
          className="tiny-margin"
          type="text"
          label="Amount"
          defaultString={usdString(wireDetails.amount)}
        />
        <Formblock
          className="tiny-margin"
          type="text"
          label="Bank name"
          defaultString={wireDetails.bank_name}
        />
        <Formblock
          className="tiny-margin"
          type="text"
          label="Routing number"
          defaultString={wireDetails.routing_number}
        />
        <Formblock
          className="tiny-margin"
          type="text"
          label="Account number"
          defaultString={wireDetails.account_number}
        />
        <Formblock
          className="tiny-margin"
          type="text"
          label="Account name"
          defaultString={wireDetails.account_name}
        />
        <Formblock
          className="tiny-margin"
          type="text"
          label="Account address"
          defaultString={wireDetails.account_address}
        />
      </BasicModal>
    );
  };
