import { BankAccount, MiterAPI, PlaidBankAccount, PlaidVerificationEnum } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { PlusCircle } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { PlaidLinkError, PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { BasicModal, LoadingModal, TableV2 } from "ui";
import { styles as SelectStyles } from "ui/form/styles";
import { Option } from "ui/form/Input";

import styles from "./LinkedAccountsTable.module.css";
import {
  useActiveCompany,
  useActiveCompanyId,
  useLedgerAccountOptions,
  useSetActiveCompany,
} from "dashboard/hooks/atom-hooks";
import { ColumnConfig, TableDropdownCellRenderer } from "ui/table-v2/Table";
import Select from "react-select";
import PlaidLink from "./PlaidLink";
import { useFetchBankAccounts } from "miter-components/bank-accounts/utils";
import { isPlaidBankAccount } from "dashboard/pages/expenses/expenseUtils";
import { DeepPartial } from "utility-types";

type AccountStatusEnum = "Connected" | "Reconnect required" | "Unverified";
type ExternalAccountsTableRow = BankAccount & {
  account_info: JSX.Element;
  status: AccountStatusEnum;
  ledgerAccountLabel: string;
};

const LedgerDropDown: React.FC<{
  onSelect: (option: Option<string>) => Promise<void>;
  defaultLedgerAccountId: string | undefined | null;
  ledgerOptions: Option<string>[];
  accountId: string;
}> = ({ onSelect, defaultLedgerAccountId, ledgerOptions, accountId }) => {
  const [isLedgerAccountSelectLoading, setIsLedgerAccountSelectLoading] = useState<boolean>(false);

  const currentLedgerAccount = ledgerOptions.find((option) => option.value === defaultLedgerAccountId);

  return (
    <Select
      key={accountId}
      name="ledger_account"
      options={ledgerOptions}
      width={"250px"}
      isLoading={isLedgerAccountSelectLoading}
      onChange={async (option) => {
        setIsLedgerAccountSelectLoading(true);
        await onSelect(option as Option<string>);
        setIsLedgerAccountSelectLoading(false);
      }}
      isClearable={true}
      menuPortalTarget={document.body}
      styles={SelectStyles}
      height="32px"
      value={currentLedgerAccount}
    />
  );
};

const LinkedAccountsTable: React.FC = () => {
  /*********************************************************
    Hooks
  **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const ledgerAccounts = useLedgerAccountOptions();
  const activeCompany = useActiveCompany();
  const setActiveCompany = useSetActiveCompany();

  /*********************************************************
    States
  **********************************************************/
  const [tableData, setTableData] = useState<ExternalAccountsTableRow[]>([]);

  const [showPlaidConsentModal, setShowPlaidConsentModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  const [plaidToken, setPlaidToken] = useState<string>();
  const [plaidTokenLoading, setPlaidTokenLoading] = useState(false);
  const [plaidAccountsConnectingLoading, setPlaidAccountsConnectingLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<BankAccount>();
  const [refreshCounter, setRefreshCounter] = useState<number>(0);

  const { result, loading } = useFetchBankAccounts({
    companyId: activeCompanyId || "",
    refreshCount: refreshCounter,
  });

  useEffect(() => {
    const tableRows = result?.map((account: BankAccount) => buildTableRow(account));
    if (tableRows) {
      setTableData(tableRows);
    }
  }, [result]);

  /*********************************************************
    Helper functions
  **********************************************************/
  const buildTableRow = (account: BankAccount): ExternalAccountsTableRow => {
    let reconnectOrVerificationStatus: AccountStatusEnum = "Connected";

    if (isPlaidBankAccount(account)) {
      reconnectOrVerificationStatus = account.reconnect_required ? "Reconnect required" : "Connected";
      if (
        ["pending_manual_verification", "pending_automatic_verification"].includes(
          account.external_raw_data.verification_status as PlaidVerificationEnum
        )
      ) {
        reconnectOrVerificationStatus = "Unverified";
      }
    }

    return {
      ...account,
      account_info: buildAccountInfoCell(account),
      status: reconnectOrVerificationStatus,
      ledgerAccountLabel: ledgerAccounts.find((la) => la.value === account.ledger_account_id)?.label || "-",
    };
  };

  const buildAccountInfoCell = (account: BankAccount) => {
    // one implicit assumption here - all company bank accounts are linked via Plaid. Safe to cast here
    const plaidBankAccount = account as PlaidBankAccount;
    return (
      <div className={styles["account-cell"]}>
        <div className={styles["account-cell-info"]}>
          <div className={styles["account-name-container"]}>
            <p className={styles["account-name"]}>{plaidBankAccount.external_raw_data?.name}</p>
          </div>
          <p className={styles["account-more-info"]}>- {account.account_number_last_4}</p>
        </div>
      </div>
    );
  };

  /*********************************************************
    Handler functions
  **********************************************************/

  const handleDisconnectAccount = async (bankAccount: BankAccount) => {
    if (isPlaidBankAccount(bankAccount)) {
      setSelectedAccount(bankAccount);
      setShowDisconnectModal(true);
    }
  };

  const handleUpdateAccount = async (bankAccount: BankAccount) => {
    if (isPlaidBankAccount(bankAccount)) {
      setSelectedAccount(bankAccount);
      getPlaidLinkToken(null, true, bankAccount.plaid_item_id);
    }
  };

  const handlePlaidLinkExit = async (error: PlaidLinkError | null) => {
    if (error) {
      Notifier.error("There was an error linking your account. Please contact support.");
      console.error("Plaid link failed", error);
    }

    setSelectedAccount(undefined);
    setPlaidToken(undefined);
  };

  /*********************************************************
    API functions
  **********************************************************/

  const getPlaidLinkToken = async (e, update_mode?: boolean, item_id?: string) => {
    if (!activeCompanyId) return;
    setPlaidTokenLoading(true);

    try {
      const res = await MiterAPI.banking.plaid.retrieve_token({
        company: activeCompanyId,
        products: ["auth"],
        update_mode,
        item_id,
        external_financial_account_type: "company", // company bank account only
      });

      if (res.error) throw new Error(res.error);

      setPlaidToken(res.link_token);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error(e.message);
    }
    setPlaidTokenLoading(false);
    setShowPlaidConsentModal(false);
  };

  const connectPlaidAccounts = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    if (!activeCompanyId) return;
    setPlaidAccountsConnectingLoading(true);
    try {
      const res = await MiterAPI.banking.plaid.connect_accounts({
        company: activeCompanyId,
        external_financial_account_type: "company",
        public_token,
        metadata,
      });

      // this will throw if there was an error for the Plaid item or earlier steps, not for individual accounts
      if (res.error) throw new Error(res.error);

      for (const connectAccountResult of res) {
        const last4 = connectAccountResult.account?.mask;
        // wasn't connected
        if (connectAccountResult.error) {
          Notifier.error(`Account ending in ${last4} wasn't connected to Miter.`);
        } else {
          Notifier.success(`Account ending in ${last4} connected to Miter.`);
        }
      }
      setRefreshCounter(refreshCounter + 1);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }

    setPlaidToken(undefined);
    setShowPlaidConsentModal(false);
    setPlaidAccountsConnectingLoading(false);
  };

  const archiveBankAccount = async () => {
    if (!selectedAccount) return;
    setArchiveLoading(true);

    try {
      const res = await MiterAPI.bank_accounts.archive(selectedAccount._id.toString());
      if (res.error) throw new Error(res.error);

      Notifier.success("The account has been unlinked.");
      setRefreshCounter(refreshCounter + 1);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }

    setArchiveLoading(false);
    setSelectedAccount(undefined);
    setShowDisconnectModal(false);
  };

  const reconnectPlaidAccount = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    if (!selectedAccount || !activeCompanyId || !isPlaidBankAccount(selectedAccount)) return;
    setPlaidAccountsConnectingLoading(true);

    try {
      const res = await MiterAPI.banking.plaid.reconnect_accounts({
        company: activeCompanyId,
        public_token,
        external_financial_account_type: "company",
        metadata,
        item_id: selectedAccount.plaid_item_id,
      });

      if (res.error) throw new Error(res.error);
      setRefreshCounter(refreshCounter + 1);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }

    setSelectedAccount(undefined);
    setPlaidToken(undefined);
    setShowPlaidConsentModal(false);
    setPlaidAccountsConnectingLoading(false);
  };

  const updateBankAccount = async (bankAccountId: string, params: DeepPartial<BankAccount>) => {
    try {
      const res = await MiterAPI.bank_accounts.update(bankAccountId, params);
      if (res.error) throw new Error(res.error);
      Notifier.success("Linked account updated successfully");
      setRefreshCounter(refreshCounter + 1);
    } catch (e: $TSFixMe) {
      console.error("Error updating Plaid account:", e);
      Notifier.error(e.message);
    }
  };

  const setAccountAsReimbursementPayoutsDefault = async (row: ExternalAccountsTableRow) => {
    try {
      if (!activeCompanyId) throw new Error("Current company not found");
      const updatedCompany = await MiterAPI.companies.update(activeCompanyId, {
        $set: { "settings.bank_accounts.ach_reimbursements_bank_account_id": row._id },
      });

      if (updatedCompany.error) throw new Error(updatedCompany.error);
      Notifier.success(
        `Bank account ending in ${row.account_number_last_4} now set as funding source for reimbursement payouts. Reimbursements already submitted for processing will not be affected.`
      );

      setActiveCompany(updatedCompany);
    } catch (e: $TSFixMe) {
      console.error("Error setting bank account as reimbursement payouts default:", e);
      Notifier.error(e.message);
    }
  };
  /*********************************************************
     Configs
  **********************************************************/
  const columns: ColumnConfig<ExternalAccountsTableRow>[] = [
    {
      headerName: "Account",
      field: "account_info",
      dataType: "component",
      minWidth: 350,
    },
    {
      headerName: "GL account",
      field: "ledgerAccountLabel",
      dataType: "string",
      displayType: "dropdown",
      minWidth: 300,
      cellRenderer: (params) => {
        const currentRow: ExternalAccountsTableRow = params.data;
        const handleLedgerAccountSelectCurried =
          (currentRow: ExternalAccountsTableRow) => async (option: Option<string>) => {
            await updateBankAccount(currentRow._id, {
              ledger_account_id: option?.value ?? null,
            });
          };

        return (
          <LedgerDropDown
            onSelect={handleLedgerAccountSelectCurried(currentRow)}
            defaultLedgerAccountId={currentRow.ledger_account_id}
            ledgerOptions={ledgerAccounts}
            accountId={currentRow._id}
          />
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      dataType: "string",
      displayType: "badge",
      maxWidth: 120,
    },
    {
      headerName: "Reimbursement payouts",
      field: "ach_reimbursements_bank_account_id",
      dataType: "boolean",
      headerTooltip:
        "If selected, this account will be used as the funding source for non-payroll reimbursement payouts via ACH.",
      headerComponent: () => (
        <>
          <h4>
            Reimbursement <br />
            ACH payouts
          </h4>
        </>
      ),
      cellRenderer: (params) => {
        const currentRow: ExternalAccountsTableRow = params.data;
        return (
          <input
            type="radio"
            name="default_for_ach_reimbursement_payouts"
            checked={
              activeCompany?.settings.bank_accounts?.ach_reimbursements_bank_account_id === currentRow._id
            }
            onChange={() => {
              setAccountAsReimbursementPayoutsDefault(currentRow);
            }}
          />
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      displayType: "dropdown",
      cellRenderer: (params) => {
        const currentRow: ExternalAccountsTableRow = params.data;

        const options = [{ label: "Disconnect account", action: () => handleDisconnectAccount(currentRow) }];

        if (currentRow.status !== "Connected") {
          options.unshift({
            label: currentRow.status === "Reconnect required" ? "Reconnect account" : "Verify account",
            action: () => handleUpdateAccount(currentRow),
          });
        }

        return <TableDropdownCellRenderer options={options} />;
      },
      pinned: "right",
      maxWidth: 40,
    },
  ];

  /*********************************************************
    Render functions
  **********************************************************/
  const renderPlaidConsentModal = () => {
    return (
      <BasicModal
        headerText="Debit Consent"
        bodyText="You agree to allow Miter to create one-time debits to this account. These debits will be in the amount you specify when you initiate a transfer of funds."
        button2Action={getPlaidLinkToken}
        button2Loading={plaidTokenLoading}
        button2Text="I accept"
        button1Action={() => setShowPlaidConsentModal(false)}
        button1Text="Cancel"
      />
    );
  };

  const renderArchiveAccountModal = () => {
    return (
      <BasicModal
        headerText="Disconnect account"
        bodyText="Are you sure you want to disconnect this linked account?"
        button2Action={archiveBankAccount}
        button2Loading={archiveLoading}
        button2Text="Yes"
        button1Action={() => {
          setShowDisconnectModal(false);
          setSelectedAccount(undefined);
        }}
        button1Text="No"
      />
    );
  };

  const renderLinkAccountButton = () => {
    if (loading) return;
    return (
      <div style={{ marginTop: 20 }}>
        <button className="button-1 no-margin" onClick={() => setShowPlaidConsentModal(true)}>
          <PlusCircle style={{ marginBottom: -2.5, marginRight: 0 }} /> Link a new account
        </button>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableV2
        data={tableData}
        columns={columns}
        id={"external-accounts-table"}
        resource="external accounts"
        isLoading={loading}
        hideFooter={true}
        rowHeight={70}
      />
    );
  };

  return (
    <>
      {renderTable()}
      {renderLinkAccountButton()}
      {showPlaidConsentModal && renderPlaidConsentModal()}
      {showDisconnectModal && renderArchiveAccountModal()}
      {plaidToken && (
        <PlaidLink
          token={plaidToken}
          onSuccess={selectedAccount ? reconnectPlaidAccount : connectPlaidAccounts}
          onExit={handlePlaidLinkExit}
        />
      )}
      {plaidAccountsConnectingLoading && <LoadingModal text="Linking in progress." />}
    </>
  );
};

export default LinkedAccountsTable;
