import { MiterAPI } from "dashboard/miter";
import { downloadPdfFromBase64String } from "dashboard/utils";
import Notifier from "dashboard/utils/notifier";
import React, { useEffect, useState } from "react";
import { BasicModal, Button } from "ui";

type Props = {
  messageId: string;
  job?: string;
  hide: () => void;
};

export const SendMessageSuccessModal: React.FC<Props> = ({ messageId, job, hide }) => {
  const [downloading, setDownloading] = useState(false);
  const [proofDownloaded, setProofDownloaded] = useState(false);
  const [hideProofDownload, setHideProofDownload] = useState(true);

  useEffect(() => {
    getMessage();
  }, [messageId]);

  const goToOutbox = async () => {
    window.open(process.env.REACT_APP_URL + "/jobs/" + job + "/outbox");
  };

  const getMessage = async () => {
    try {
      const res = await MiterAPI.messages.list([{ field: "_id", value: messageId }]);

      const faxStatus = res[0]?.sent_fax?.status;
      if (!faxStatus) return setHideProofDownload(false);

      if (faxStatus !== "success") {
        setHideProofDownload(true);
      } else {
        setHideProofDownload(false);
      }
    } catch (error) {
      Notifier.error("Error retrieving message");
    }
  };

  const downloadProof = async () => {
    setDownloading(true);
    try {
      const response = await MiterAPI.messages.get_proof(messageId);
      if (response.error) throw new Error(response.error);
      await downloadPdfFromBase64String(response.data, "Proof of submission.pdf");
      Notifier.success("Success! Proof of submission downloaded.");
      setProofDownloaded(true);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error downloading proof of submission. We're looking into it.");
    }
    setDownloading(false);
  };

  return (
    <BasicModal headerText="Message sent!" button2Action={hide} button2Text={"All done"} button1Action={hide}>
      {job && (
        <div>
          <div style={{ marginBottom: 15 }} className="payroll-approval-text-wrapper">
            <span>Congrats! Your message was sent successfully</span>
            {job && <span>{`. A record of your message was saved to `}</span>}
            {job && (
              <span onClick={goToOutbox} className="blue-link">
                the job&apos;s outbox
              </span>
            )}
            .
          </div>
          {!hideProofDownload && (
            <Button
              className="button-1 width-100"
              onClick={downloadProof}
              loading={downloading}
              text={proofDownloaded ? "Proof downloaded" : "Download proof of submission"}
              disabled={proofDownloaded}
            />
          )}
        </div>
      )}
    </BasicModal>
  );
};
