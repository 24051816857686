import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { AggregatedJobPosting } from "dashboard/types/ats";
import { useMiterAbilities } from "../abilities-hooks/useMiterAbilities";
import { useAbilitiesBackendFilter } from "../abilities-hooks/useAbilitiesBackendFilter";
import { FilterBuilder } from "../abilities-hooks/useAbilitiesTeamPredicate";

/*
 * JOB POSTINGS
 */
type JobPostingParams = AggregatedJobPosting | AggregatedJobPosting[] | undefined;
type JobPostingAction = "create" | "read" | "update" | "delete";

type JobPostingAbilities = {
  can: (action: JobPostingAction, items: JobPostingParams) => boolean;
  cannot: (action: JobPostingAction, items: JobPostingParams) => boolean;
  filter: FilterBuilder;
};

export const useJobPostingAbilities = (): JobPostingAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: JobPostingAction, items?: JobPostingParams) => {
      if (!items) return true;

      const jobPostings = Array.isArray(items) ? items : [items];

      return jobPostings.every((jp) => {
        const opts = { moduleParams: { recruiting: { jobPosting: jp } } };
        switch (action) {
          case "create":
            return can_("recruiting:job_postings:create", opts);
          case "read":
            return can_("recruiting:job_postings:read", opts);
          case "update":
            return can_("recruiting:job_postings:update", opts);
          case "delete":
            return can_("recruiting:job_postings:delete", opts);
          default:
            return false;
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: JobPostingAction, items: JobPostingParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "recruiting:job_postings",
    modules: { jobPostingField: { fieldName: "_id", fieldType: "_id" } },
  });

  return useMemo(() => ({ can, cannot, filter }), [can, cannot, filter]);
};
