import React, { useEffect, useRef } from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "./TeamMemberWizard.module.css";
import { MiterAPI, UpdateTeamMemberParams } from "dashboard/miter";
import {
  useActiveCompanyId,
  useActivityOptions,
  useCostTypeOptions,
  useJobOptions,
  useLookupTeam,
  useOtRuleOptions,
  useRefetchTeam,
  useStandardClassificationOptions,
} from "dashboard/hooks/atom-hooks";
import { useForm, useWatch } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { sleep } from "dashboard/utils";
import { Option } from "ui/form/Input";
import { WizardTeamMember } from "./TeamMemberWizard";
import {
  defaultTmActivityTooltip,
  defaultTmCostTypeTooltip,
  defaultTmJobTooltip,
  stdTmClassificationTooltip,
} from "dashboard/pages/team-members/TeamUtils";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
};

type TeamMemberDefaultsForm = {
  default_ot_rule_id?: Option<string>;
  default_job_id?: Option<string>;
  default_activity_id?: Option<string>;
  default_cost_type_id?: Option<string>;
  standard_classification_id?: Option<string>;
};

export const TeamMemberDefaultsScreen: React.FC<Props> = ({ name, teamMember, setTeamMember }) => {
  /*********************************************************
   *  Important hooks
   **********************************************************/
  const activeCompanyId = useActiveCompanyId();
  const refetchTeam = useRefetchTeam();
  const isMounted = useRef(true);
  const lookupTeam = useLookupTeam();

  const otRuleOptions = useOtRuleOptions();
  const jobOptions = useJobOptions();
  const allActivityOptions = useActivityOptions();
  const costTypeOptions = useCostTypeOptions();
  const standardClassificationOptions = useStandardClassificationOptions();

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const form = useForm<TeamMemberDefaultsForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(teamMember, {
      otRuleOptions,
      jobOptions,
      activityOptions: allActivityOptions,
      costTypeOptions,
      standardClassificationOptions,
    }),
  });

  const formData = useWatch<TeamMemberDefaultsForm>({ control: form.control });
  const selectableActivityOptions = useActivityOptions(formData.default_job_id?.value);

  /*********************************************************
   *  Break out form data to enforce rerenders
   **********************************************************/
  const { handleSubmit, formState, errors, watch } = form;
  const { dirtyFields, isValid } = formState;

  watch();

  /*********************************************************
   * useEffect's
   * - Set the next button text to be "Complete"
   **********************************************************/
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    form.trigger();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (screens.length === 1) {
      setNextButtonText("Save and exit");
    } else {
      setNextButtonText("Save and continue");
    }
  }, []);

  // Set whether or not the user can move forward based on the errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors, isValid, Object.keys(errors)]);

  useEffect(() => {
    const newTeamMember = lookupTeam(teamMember?._id);
    if (newTeamMember) setTeamMember(newTeamMember);
  }, [lookupTeam]);

  // Wizard handlers
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await handleSubmit(saveTeamMember)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }

    if (screens.length === 1) {
      handleComplete();
    }
  };

  const buildParams = (params: TeamMemberDefaultsForm) => {
    if (!activeCompanyId) throw new Error("No active role");

    const data: UpdateTeamMemberParams = {
      default_ot_rule_id: params.default_ot_rule_id?.value,
      default_job_id: params.default_job_id?.value,
      default_activity_id: params.default_activity_id?.value,
      default_cost_type_id: params.default_cost_type_id?.value,
      standard_classification_id: params.standard_classification_id?.value,

      resume_wizard_index: curIndex + 1,
    };

    return data;
  };

  const saveTeamMember = async (params: TeamMemberDefaultsForm) => {
    try {
      if (!teamMember?._id) throw new Error("No team member");
      if (!activeCompanyId) throw new Error("No active company");

      const cleanedParams = buildParams(params);
      const res = await MiterAPI.team_member.update(teamMember?._id, cleanedParams);

      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }

      if (res.error) {
        throw new Error(res.error);
      }

      setTeamMember(res);

      await sleep(100);
      await refetchTeam(res._id);
      Notifier.success("Team member saved successfully");
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);

      // We need to throw an error to prevent the wizard from moving to the next screen
      throw e;
    }
  };

  return (
    <WizardScreen name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>Default Assocations</h2>
          <p className={styles["subheader-description"]}> Set the team member&apos;s default associations</p>
        </div>
        <div className="form-section">
          <Formblock
            label="Default job"
            sublabel={defaultTmJobTooltip}
            type="select"
            name="default_job_id"
            placeholder="Select a job (optional)"
            form={form}
            className="modal wizard"
            editing={true}
            options={jobOptions}
          />
          <Formblock
            label="Default activity"
            sublabel={defaultTmActivityTooltip}
            type="select"
            name="default_activity_id"
            placeholder="Select an activity (optional)"
            form={form}
            className="modal wizard"
            editing={true}
            options={selectableActivityOptions}
          />{" "}
          <Formblock
            label="Default cost type"
            sublabel={defaultTmCostTypeTooltip}
            type="select"
            name="default_cost_type_id"
            placeholder="Select a cost type (optional)"
            form={form}
            className="modal wizard"
            editing={true}
            options={costTypeOptions}
          />
          <Formblock
            label="Default classification"
            sublabel={stdTmClassificationTooltip}
            type="select"
            name="standard_classification_id"
            placeholder="Select a classification (optional)"
            form={form}
            className="modal wizard"
            editing={true}
            options={standardClassificationOptions}
          />
          <Formblock
            label="Default OT rule"
            sublabel={defaultTmJobTooltip}
            type="select"
            name="default_ot_rule_id"
            placeholder="Select an OT rule (optional)"
            form={form}
            className="modal wizard"
            editing={true}
            options={otRuleOptions}
          />
        </div>
      </div>
    </WizardScreen>
  );
};

const buildDefaultValues = (
  teamMember: WizardTeamMember | undefined,
  params: {
    otRuleOptions: Option<string>[];
    jobOptions: Option<string>[];
    activityOptions: Option<string>[];
    costTypeOptions: Option<string>[];
    standardClassificationOptions: Option<string>[];
  }
): TeamMemberDefaultsForm => {
  if (!teamMember) return {};

  const { otRuleOptions, jobOptions, activityOptions, costTypeOptions, standardClassificationOptions } =
    params;

  return {
    default_ot_rule_id: otRuleOptions.find((o) => o.value === teamMember.default_ot_rule_id),
    default_job_id: jobOptions.find((o) => o.value === teamMember.default_job_id),
    default_activity_id: activityOptions.find((o) => o.value === teamMember.default_activity_id),
    default_cost_type_id: costTypeOptions.find((o) => o.value === teamMember.default_cost_type_id),
    standard_classification_id: standardClassificationOptions.find(
      (o) => o.value === teamMember.standard_classification_id
    ),
  };
};
