import { AggregatedFillableTemplate } from "dashboard/types/fillable-template-types";
import React, { FC, useEffect, useRef, useState } from "react";
import { Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import { MiterAPI } from "dashboard/miter";
import AnvilEmbedFrame from "@anvilco/anvil-embed-frame";
import styles from "./FillableTemplateWizard.module.css";
import { MAX_FILLABLE_INPUTS } from "dashboard/utils/fillable-templates";

type Props = {
  fillableTemplate: AggregatedFillableTemplate;
  name: string;
  setFillableTemplate: (fillableTemplate: AggregatedFillableTemplate) => void;
};

type AnvilEvent = {
  action: string;
  castEid: string;
  organizationSlug: string;
};

export const TemplateEditorWizardScreen: FC<Props> = ({ fillableTemplate, name }) => {
  const [editorLink, setEditorLink] = useState<string>("");
  const { setCanNext } = useWizard();
  const anvilRef = useRef(null);
  const [inputCount, setInputCount] = useState(0);

  const submit = () => {
    if (!anvilRef?.current) {
      throw new Error("Could not save the fillable template. Please exit and try again.");
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - iframeRef is not in the type definition
    anvilRef.current.postMessage(
      {
        action: "castEditSubmit",
      },
      "*"
    );
  };

  /** This hook calls the limit template function when the component unmounts. */
  useEffect(() => {
    const limitFillableTemplate = async () => {
      try {
        const response = await MiterAPI.fillable_templates.limit({ id: fillableTemplate._id });
        if (response.error) {
          throw new Error(response.error);
        }
      } catch (error: $TSFixMe) {
        console.error(`Error limiting template to ${MAX_FILLABLE_INPUTS} inputs: ${error.message}`);
      }
    };
    return () => {
      limitFillableTemplate();
    };
  }, []);

  useEffect(() => {
    const getFillableTemplateLink = async () => {
      try {
        const response = await MiterAPI.fillable_templates.template_editor_link({ id: fillableTemplate._id });
        if (response.error) {
          throw new Error(response.error);
        }
        setEditorLink(response.embed_url);
      } catch (error: $TSFixMe) {
        Notifier.error(`Error generating editor link: ${error.message}`);
      }
    };

    const getAnvilFields = async () => {
      try {
        const response = await MiterAPI.fillable_templates.template_inputs({ id: fillableTemplate._id });
        if (response.error) {
          throw new Error(response.error);
        }
        setInputCount(response.fields.length);
      } catch (error: $TSFixMe) {
        Notifier.error(`Error getting count of existing template inputs: ${error.message}`);
      }
    };

    getFillableTemplateLink();
    getAnvilFields();
  }, []);

  useEffect(() => {
    if (editorLink) document?.getElementById("scroller")?.scrollIntoView();
  }, [editorLink]);

  return (
    <WizardScreen name={name} onNext={submit}>
      <div>
        <div className={styles["empty-div-template-editor"]} id="scroller"></div>
        {editorLink && (
          <div>
            <div className={styles["template-editor-header"]}>
              <h2 className={styles["subheader-title"]}>Drag-and-drop inputs</h2>
              <p className={styles["subheader-description"]}>
                Drag input fields onto the document to create a fillable form.
              </p>
            </div>

            <div className={styles["anvil-editor"]}>
              <AnvilEmbedFrame
                ref={anvilRef}
                iframeURL={editorLink}
                onEvent={(event: AnvilEvent) => {
                  const { action } = event;
                  if (action === "castEditLoad") {
                    setCanNext(true);
                  }

                  // Adding a field
                  if (action === "castEditFieldsAdded") {
                    if (inputCount + 1 === MAX_FILLABLE_INPUTS) {
                      Notifier.warning(
                        `You have reached the maximum number of input fields: ${MAX_FILLABLE_INPUTS}.`
                      );
                    }
                    if (inputCount + 1 > MAX_FILLABLE_INPUTS) {
                      Notifier.error(
                        `Miter will only keep the last ${MAX_FILLABLE_INPUTS} inputs upon saving.`
                      );
                      console.error(
                        `Company set ${inputCount + 1} inputs for template ${fillableTemplate?._id}.`
                      );
                    }
                    setInputCount(inputCount + 1);
                  }

                  // Deleting a field
                  if (action === "castEditFieldsRemoved") {
                    setInputCount(inputCount - 1);
                  }
                }}
                scroll="auto"
                style={{ width: "80%", height: "80vh", border: "none" }}
              />
            </div>
          </div>
        )}
      </div>
    </WizardScreen>
  );
};
