import React, { useEffect, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "ui";
import EmptyChat from "../../../assets/empty-chat.svg";
import styles from "../Chat.module.css";
import TeamChatProfile from "./TeamChatProfile";
import TeamConversationContainer from "./TeamConversationContainer";
import TeamConversationList from "./TeamConversationList";
import {
  useActiveCompany,
  useIsTeamChatInitialized,
  useLastRefreshedAtAtom,
  useSetActiveTeamChatConversation,
  useSetTeamChatConversations,
  useTeamChatConversations,
} from "dashboard/hooks/atom-hooks";

const TeamChatContainer: FC = ({}) => {
  // Chat atom hooks
  const teamConversations = useTeamChatConversations();
  const setTeamConversations = useSetTeamChatConversations();
  const setActiveTeamConversation = useSetActiveTeamChatConversation();
  const isTeamChatInitialized = useIsTeamChatInitialized();

  const [lastRefreshedAt] = useLastRefreshedAtAtom();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();
  const hasChat = !!activeCompany?.chat?.conversations_service_sid;

  const conversationId = params.id;
  const hasConversations = teamConversations.length > 0;
  const noSelectedConversation = !conversationId || conversationId.length === 0;

  useEffect(() => {
    // If it's been more than 20 minutes since the last refresh, refresh the page to fix any twilio connection issues
    if (lastRefreshedAt.diffNow().as("hours") < -2) {
      window.location.reload();
    }
  }, [lastRefreshedAt]);

  useEffect(() => {
    if (noSelectedConversation) return;

    const conversation = (teamConversations || []).find((c) => c._id === conversationId);

    const twilioConversation = conversation?.twilio_conversation;
    if (twilioConversation) {
      const updatedTeamConvos = teamConversations.map((c) => {
        if (c._id === conversationId) {
          return { ...c, unread: false };
        }
        return c;
      });
      setTeamConversations(updatedTeamConvos);
    }

    setActiveTeamConversation(conversation || null);
  }, [params.id, teamConversations.length]);

  useEffect(() => {
    if (noSelectedConversation && hasConversations) {
      navigate(`/chat/${teamConversations[0]!._id}`);
    }
  }, [params.id, teamConversations.length]);

  const renderEmptyState = () => {
    return (
      <div className={styles["conversation-container-empty-state"]}>
        {isTeamChatInitialized && (
          <>
            <img src={EmptyChat} alt="No chats created" />
            <p>Click the plus button and send your first message!</p>
          </>
        )}
        {!isTeamChatInitialized && hasChat && <Loader />}
      </div>
    );
  };

  return (
    <div className={styles["chat-container"]}>
      <TeamConversationList />
      {hasConversations && <TeamConversationContainer />}
      {hasConversations && <TeamChatProfile />}
      {!hasConversations && renderEmptyState()}
    </div>
  );
};

export default TeamChatContainer;
