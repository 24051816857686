import { useState, useMemo, useEffect, useContext } from "react";
import { CheckrPackage, HierarchicalNode } from "dashboard/types/screening-types";
import { MiterAPI } from "dashboard/miter";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import { Notifier } from "ui";

/**
 * Key Terminology and Concepts:
 *
 * • Checkr: A background check platform that provides employment screening services.
 *
 * • Package: A pre-defined set of background check services offered by Checkr.
 *   - Can include various types of screenings such as criminal records,
 *     education verification, employment verification, etc.
 *
 * • Node: In the context of this application, a hierarchical organizational unit
 *   (e.g., department, team) within a company.
 *   - Nodes can have associated packages, allowing for customized background check
 *     options for different parts of the organization.
 *
 * • Screening: An individual background check service.
 *   - Examples: criminal record check, employment verification
 *   - Multiple screenings can be combined into a package
 *
 * • HierarchicalNodeMap: A data structure that represents the organizational hierarchy
 *   and associated packages for each node in the company.
 *
 * • Slug: A unique identifier for a package, typically a URL-friendly version of the
 *   package name.
 *
 * • Integration: The connection between the company's system and Checkr's services.
 *   - When enabled, it allows the company to use Checkr's background check
 *     services directly through the application.
 *
 * This hook (useCheckrPackages) manages the fetching, selection, and presentation
 * of Checkr packages and related data within the application. It provides a unified
 * interface for components to interact with Checkr-related functionality.
 */

export type HierarchicalNodeMap = {
  [key: string]: {
    parent_custom_id: string;
    name: string;
    packages: HierarchicalNode["packages"];
  };
};

type UseCheckrPackages = {
  loading: boolean;
  checkrEnabled: boolean;
  companyPackages: CheckrPackage[];
  mapOfNodesToPackages: HierarchicalNodeMap;
};

/**
 * Hook to fetch and manage Checkr packages for a company
 * @returns {UseCheckrPackages} Object containing package data and loading state
 */
export const useCheckrCompanyPackages = (): UseCheckrPackages => {
  const companyId = useActiveCompanyId();
  const { integrations } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [companyPackages, setCompanyPackages] = useState<CheckrPackage[]>([]);
  const [mapOfNodesToPackages, setMapOfNodesToPackages] = useState<HierarchicalNodeMap>({});

  // Check if Checkr integration is enabled and credentialed for the company
  const checkrEnabled = !!integrations.find((i) => i?.connection?.integration_key === "checkr")?.connection
    ?.metadata?.checkr?.credentialed;

  /**
   * Fetch packages and nodes from the API
   * This function is responsible for:
   * 1. Fetching packages and nodes data from the API
   * 2. Updating the companyPackages state with fetched packages
   * 3. Creating a map of nodes (mapOfNodesToPackages) for efficient lookup
   */
  const fetchPackages = async () => {
    setLoading(true);
    try {
      if (!companyId || !checkrEnabled) return;
      const res = await MiterAPI.screenings.packages({ company_id: companyId });
      if (res.error) {
        throw new Error(res.error);
      }
      const { nodes, packages } = res;
      setCompanyPackages(packages);
      const mapOfNodes = {};
      for (const node of nodes) {
        mapOfNodes[node.custom_id] = node;
      }
      setMapOfNodesToPackages(mapOfNodes);
    } catch (e: $TSFixMe) {
      Notifier.error("Error fetching background check packages: " + e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPackages();
  }, [companyId, checkrEnabled]);

  return useMemo(
    () => ({
      loading,
      checkrEnabled,
      companyPackages,
      mapOfNodesToPackages,
    }),
    [loading, checkrEnabled, companyPackages, mapOfNodesToPackages]
  );
};

type UseCheckrPackageOptionsParams = {
  nodeId?: string;
  checkrPackageId?: string;
  companyPackages: CheckrPackage[];
  mapOfNodesToPackages: HierarchicalNodeMap;
};

type UseCheckrPackageOptions = {
  activePackages: CheckrPackage[];
  activePackageOptions: { value: string; label: string }[];
  nodeOptions: { value: string; label: string }[];
  chosenPackage: CheckrPackage | null;
};
/**
 * Hook to manage Checkr package options for a specific node
 * This hook provides:
 * 1. Active packages for the selected node
 * 2. Package options for dropdown selection
 * 3. Node options for dropdown selection
 * 4. The currently chosen package
 *
 * @param {UseCheckrPackageOptionsParams} params - Parameters for package options
 * @returns {UseCheckrPackageOptions} Object containing package options and selections
 */
export const useCheckrPackageOptions = (params: UseCheckrPackageOptionsParams): UseCheckrPackageOptions => {
  const { nodeId, checkrPackageId, companyPackages, mapOfNodesToPackages } = params;

  /**
   * Recursively get packages for a node, falling back to company packages if none found
   * This function handles the hierarchical nature of nodes and their packages:
   * - If the node exists and has packages, return those packages
   * - If the node exists but has no packages, return company-wide packages
   * - If the node doesn't exist, return company-wide packages as a fallback
   *
   * @param {string} startingNodeId - ID of the node to start from
   * @returns {CheckrPackage[]} Array of packages for the node
   */
  const getNodePackages = (startingNodeId: string): CheckrPackage[] => {
    const node = mapOfNodesToPackages[startingNodeId];
    if (!node) {
      return companyPackages;
    }
    if (!node.packages.length) {
      return companyPackages;
    }
    return node.packages;
  };

  // Get active packages for the current node. If no nodeId is provided, fall back to company-wide packages
  const activePackages = useMemo(() => {
    if (!nodeId) return companyPackages;
    return getNodePackages(nodeId);
  }, [nodeId, companyPackages, mapOfNodesToPackages]);

  // Create options for package selection dropdown
  const activePackageOptions = useMemo(() => {
    return activePackages.map((value) => ({
      value: value.slug,
      label: value.name,
    }));
  }, [activePackages]);

  // Create options for node selection dropdown - this transforms the mapOfNodesToPackages into a format suitable for dropdown components
  const nodeOptions = useMemo(() => {
    return Object.entries(mapOfNodesToPackages).map(([key, value]) => ({
      value: key,
      label: value.name,
    }));
  }, [mapOfNodesToPackages]);

  // Find the currently selected package based on the provided checkrPackageId
  const chosenPackage = useMemo(() => {
    if (!checkrPackageId) {
      return null;
    }
    return activePackages.find((value) => value.slug === checkrPackageId) || null;
  }, [checkrPackageId, activePackages]);

  return {
    activePackages,
    activePackageOptions,
    nodeOptions,
    chosenPackage,
  };
};
