import { useContext, useMemo, useState } from "react";
import { AggregatedMiterEarning, MiterAPI } from "dashboard/miter";
import { Notifier } from "ui";
import { downloadPlainTextFromBlob, getIntegrationConnectionByKey } from ".";
import AppContext from "dashboard/contexts/app-context";
import { Sage300JcdWarning } from "backend/services/sage300/sage300-types";

export type UseSage300TimeEntriesCSVReturnType<T> = {
  loading: boolean;
  build: (p: { earnings: T[]; forceDownload?: boolean }) => Promise<void>;
  warnings: Sage300JcdWarning[] | undefined;
  setWarnings: (warnings: Sage300JcdWarning[] | undefined) => void;
  downloadSage300ExportFile: (jcd: string, fileName: string) => void;
  data: string | undefined;
  timeEntriesFileName: string;
};

export const useSage300TimeEntriesCSV = (
  companyId: string | null,
  periodStart: string,
  periodEnd: string
): UseSage300TimeEntriesCSVReturnType<AggregatedMiterEarning> => {
  const { integrations } = useContext(AppContext);
  const TIME_ENTRIES_FILE_NAME = `Sage300 Time Entries Export ${periodStart} to ${periodEnd}`;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string>();
  const [warnings, setWarnings] = useState<Sage300JcdWarning[]>();

  const downloadSage300ExportFile = (jcd: string, fileName: string) => {
    downloadPlainTextFromBlob(jcd, fileName);
  };

  const build = async (p: { earnings: AggregatedMiterEarning[]; forceDownload?: boolean }) => {
    const { earnings, forceDownload } = p;
    setLoading(true);
    try {
      if (!companyId) {
        throw new Error("There was an unexpected error - we're looking into it!");
      }

      const sage300Integration = getIntegrationConnectionByKey("sage_300", integrations);
      const integrationConnectionId = sage300Integration?.connection?._id.toString();

      if (!integrationConnectionId) {
        throw new Error("Sage300 Integration Connection not found for this company");
      }

      const response = await MiterAPI.ledger_entries.get_sage_300_time_entry_csv(
        earnings,
        companyId,
        integrationConnectionId,
        periodStart,
        periodEnd
      );
      if (response.error) throw new Error(response.error);

      if (response.warnings) {
        setWarnings(response.warnings);
        setData(response.csv);
        if (forceDownload) {
          downloadSage300ExportFile(response.csv, TIME_ENTRIES_FILE_NAME);
        }
      } else {
        downloadSage300ExportFile(response.csv, TIME_ENTRIES_FILE_NAME);
      }
    } catch (e: $TSFixMe) {
      const errorMessage = `Error building Sage 300 file: ${e.message}`;
      console.error(errorMessage);
      Notifier.error(errorMessage);
    }
    setLoading(false);
  };

  return useMemo(
    () => ({
      loading,
      build,
      warnings,
      setWarnings,
      downloadSage300ExportFile,
      data,
      timeEntriesFileName: TIME_ENTRIES_FILE_NAME,
    }),
    [integrations, warnings, data, loading]
  );
};
