import { useEffect, useState } from "react";
import {
  AggregatedJob,
  IntegrationConnectionForQB,
  MiterIntegrationForCompanyWithConnection,
  QBTypes,
} from "dashboard/miter";
import { Option } from "ui/form/Input";
import { QBDCustomer } from "backend/services/qbd/qbd-types";
import { QBOCustomer } from "backend/services/qbo/qbo-types";
import { Notifier } from "ui";
import { MiterAPI } from "dashboard/miter";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { QBCustomersOptionsResponse } from "backend/services/quickbooks/quickbooks-types";

export type QBCustomerOption<T> = Option<string> & { customer: T };
export type AvailableIntegrationKeys = QBTypes;

type QBJobMappinResponse = {
  loadingCustomerOptions: boolean;
  qboCustomerOptions?: QBCustomerOption<QBOCustomer>[];
  qbdCustomerOptions?: QBCustomerOption<QBDCustomer>[];
  selectedQboCustomerOption?: QBCustomerOption<QBOCustomer>;
  selectedQbdCustomerOption?: QBCustomerOption<QBDCustomer>;
  setSelectedQboCustomerOption: (selectedQboCustomerOption: QBCustomerOption<QBOCustomer>) => void;
  setSelectedQbdCustomerOption: (selectedQboCustomerOption: QBCustomerOption<QBDCustomer>) => void;
};

export const useQBJobMapping = (inputs: {
  integrationConnection: MiterIntegrationForCompanyWithConnection<IntegrationConnectionForQB>;
  job: AggregatedJob;
}): QBJobMappinResponse => {
  const { integrationConnection, job } = inputs;

  const activeCompanyId = useActiveCompanyId();

  const [qboCustomerOptions, setQboCustomerOptions] = useState<QBCustomerOption<QBOCustomer>[] | undefined>();
  const [qbdCustomerOptions, setQbdCustomerOptions] = useState<QBCustomerOption<QBDCustomer>[] | undefined>();
  const [selectedQboCustomerOption, setSelectedQboCustomerOption] = useState<QBCustomerOption<QBOCustomer>>();
  const [selectedQbdCustomerOption, setSelectedQbdCustomerOption] = useState<QBCustomerOption<QBDCustomer>>();
  const [loadingCustomerOptions, setLoadingCustomerOptions] = useState(false);

  const connectedSystem = integrationConnection.connection?.integration_key;

  const getQboCustomerOptions = async () => {
    setLoadingCustomerOptions(true);
    if (!activeCompanyId) return;
    try {
      const response = await MiterAPI.integrations.quickbooks.get_qb_customers(
        connectedSystem,
        activeCompanyId
      );
      if (response.error) throw new Error(response.error);
      setQBCustomerOptions(response);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error retrieving Customers from ${connectedSystem.toUpperCase()}.`);
    }
    setLoadingCustomerOptions(false);
  };

  const setQBCustomerOptions = (response: QBCustomersOptionsResponse) => {
    if (connectedSystem === "qbd") {
      const use_full_name_as_job =
        integrationConnection.connection.metadata?.qbd?.use_full_name_as_job || false;

      const customerOptions = response.qbd?.customers.map((c) => {
        const label = getQBDJobName(use_full_name_as_job, c);
        return {
          label,
          value: c.ListID,
          customer: c,
        };
      });
      setQbdCustomerOptions(customerOptions);
      setSelectedQbdCustomerOption(
        customerOptions?.find((o) => o.customer.ListID === job.integrations?.qbd?.customer?.ListID)
      );
    } else {
      const use_full_name_as_job =
        integrationConnection.connection.metadata?.qbo?.use_full_name_as_job || false;

      const customerOptions = response.qbo?.customers.map((c) => {
        const label = getQBOJobName(use_full_name_as_job, c);
        return {
          label,
          value: c.Id,
          customer: c,
        };
      });
      setQboCustomerOptions(customerOptions);
      setSelectedQboCustomerOption(
        customerOptions?.find((o) => o.value === job.integrations?.qbo?.customer?.Id)
      );
    }
  };

  useEffect(() => {
    getQboCustomerOptions();
  }, []);

  return {
    loadingCustomerOptions,
    qboCustomerOptions,
    qbdCustomerOptions,
    selectedQboCustomerOption,
    selectedQbdCustomerOption,
    setSelectedQboCustomerOption,
    setSelectedQbdCustomerOption,
  };
};

const getQBOJobName = (use_full_name_as_job: boolean, qboJob: QBOCustomer): string => {
  let jobName = qboJob.DisplayName;
  if (use_full_name_as_job) {
    if (qboJob.FullyQualifiedName) {
      jobName = qboJob.FullyQualifiedName;
    } else if (qboJob.CompanyName) {
      jobName = `${qboJob.CompanyName}: ${qboJob.DisplayName}`;
    }
  }

  return jobName;
};

const getQBDJobName = (use_full_name_as_job: boolean, qbdJob: QBDCustomer): string => {
  return use_full_name_as_job ? qbdJob.FullName : qbdJob.Name;
};
