import React, { useEffect, useState, useContext, useMemo } from "react";
import { reportList } from "../reportList";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { TaxDepositsReportEntry } from "backend/utils/reports/taxDepositsReport";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { DateRange } from "ui/form/DateRangePicker";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { payrollTypeLookup } from "dashboard/utils";
import { capitalize } from "lodash";

const reportObject = reportList.find((report) => report.slug === "tax_deposits");

export const TaxDepositsReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const { payroll } = useContext(PayrollContext);

  // State
  const [data, setData] = useState<TaxDepositsReportEntry[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [loading, setLoading] = useState(false);

  let fileName = "Miter Tax Deposits Report ";
  if (payroll) {
    fileName += payroll.check_payroll.payday;
  } else {
    fileName += dateRange?.start?.toISODate() + " - " + dateRange?.end?.toISODate();
  }

  useEffect(() => {
    const getData = async () => {
      if (!activeCompanyId || !dateRange?.start || !dateRange?.end) return;
      setLoading(true);
      try {
        const cleanedData = {
          start_date: payroll?.check_payroll.payday || dateRange?.start.toISODate(),
          end_date: payroll?.check_payroll.payday || dateRange?.end.toISODate(),
          company_id: activeCompanyId,
          payroll_id: payroll?._id,
        };
        const payload = {
          type: "tax_deposits",
          params: cleanedData,
          format: "json",
        };
        const response = await MiterAPI.reports.create(payload);
        if (response.error) throw new Error(response.error);
        setData(response);
      } catch (e: $TSFixMe) {
        console.log("Error fetching tax deposits data:", e);
        Notifier.error(e.message);
      }
      setLoading(false);
    };
    getData();
  }, [!activeCompanyId, dateRange?.start, dateRange?.end]);

  const columns = useMemo(() => {
    const cols: ColumnConfig<TaxDepositsReportEntry>[] = [
      {
        field: "payroll_type",
        filter: true,
        headerName: "Payroll type",
        enableRowGroup: true,
        valueGetter: (params) =>
          params.data?.payroll_type ? payrollTypeLookup[params.data.payroll_type] : undefined,
      },
      {
        field: "payroll_label",
        filter: true,
        headerName: "Label",
        enableRowGroup: true,
      },
      {
        field: "payday",
        dataType: "date",
        filter: true,
        headerName: "Payday",
        enableRowGroup: true,
      },
      {
        field: "period_start",
        dataType: "date",
        filter: true,
        headerName: "Period start",
        enableRowGroup: true,
      },
      {
        field: "period_end",
        dataType: "date",
        filter: true,
        headerName: "Period end",
        enableRowGroup: true,
      },
      {
        field: "tax_label",
        dataType: "string",
        headerName: "Tax",
        enableRowGroup: true,
      },
      {
        field: "amount",
        dataType: "number",
        headerName: "Amount",
        aggFunc: "sumValues",
        unit: "dollar",
      },
      {
        field: "due_date",
        dataType: "date",
        filter: true,
        headerName: "Due date",
        enableRowGroup: true,
      },
      {
        field: "funding_status",
        filter: true,
        headerName: "Funding status",
        enableRowGroup: true,
        valueGetter: (params) => capitalize(params.data?.funding_status),
      },
      {
        field: "deposit_status",
        filter: true,
        headerName: "Deposit status",
        enableRowGroup: true,
        valueGetter: (params) => capitalize(params.data?.deposit_status),
      },
    ];

    return cols;
  }, []);

  const HeaderTag = payroll ? "h2" : "h1";

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      {!payroll && (
        <Helmet>
          <title>Tax Deposits Report | Miter</title>
        </Helmet>
      )}
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <HeaderTag style={{ marginTop: 0, marginBottom: payroll ? 5 : undefined }}>
          {reportObject!.label}
        </HeaderTag>
      </div>
      <div className="report-page-description">
        {reportObject!.description}
        {!payroll && "*"}
      </div>
      <TableV2
        id="tax-deposits-report"
        resource="tax deposits"
        data={data}
        columns={columns}
        isLoading={loading}
        groupHideOpenParents={false}
        gridWrapperStyle={{ height: "100%" }}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        containerStyle={{ marginTop: -30 }}
        showReportViews={true}
        exportFileName={fileName}
        displayDateRange={!payroll}
        defaultDateRange={{
          start: DateTime.now().minus({ weeks: 1 }),
          end: DateTime.now().plus({ weeks: 1 }),
        }}
        onDateRangeChange={setDateRange}
      />
      <div className="vertical-spacer-large"></div>
      {!payroll && (
        <div style={{ fontSize: 13, color: "#3C3C3C" }}>
          * Includes tax deposits associated with paid, processing, and pending payrolls.
        </div>
      )}
    </div>
  );
};
