/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AgGridEvent,
  ColDef,
  ColGroupDef,
  ColumnApi,
  CsvExportParams,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  SelectionChangedEvent,
  ToolPanelVisibleChangedEvent,
  SortChangedEvent,
  FilterChangedEvent,
  ColumnMovedEvent,
  ColumnVisibleEvent,
  ColumnValueChangedEvent,
  ColumnGroupOpenedEvent,
  ColumnPivotChangedEvent,
  ColumnRowGroupChangedEvent,
  ColumnResizedEvent,
  GridColumnsChangedEvent,
  ColumnPivotModeChangedEvent,
  CellClickedEvent,
  ModelUpdatedEvent,
  ICellRendererParams,
  IServerSideGetRowsParams,
  IServerSideDatasource,
  RowDataUpdatedEvent,
  PaginationChangedEvent,
  ValueFormatterParams,
  IRowNode,
  StoreUpdatedEvent,
  CellEditRequestEvent,
  CellClassParams,
  HeaderClassParams,
  ITooltipParams,
  EditableCallbackParams,
  ExcelExportParams,
  RowClassParams,
  GetRowIdParams,
  IsRowSelectable,
  GridOptions,
  RowSelectedEvent,
} from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { BulkUpdateResult, MiterFilterArray, MiterFilterField, ReportView } from "dashboard/miter";
import { Notifier, formatDate } from "dashboard/utils";
import { cloneDeep, isEqual, keyBy, set } from "lodash";
import { DateTime } from "luxon";
import {
  cleanFloatingPointErrors,
  groupDedupedSumValues,
  identityAggFunc,
  notNullish,
  sumValues,
  timezoneOptions,
  yesNoOptions,
  useEnhancedSearchParams,
  sleep,
} from "miter-utils";
import {
  CaretDown,
  Check,
  Columns,
  DotsThree,
  DotsThreeVertical,
  FileCsv,
  FileXls,
  FloppyDisk,
  FolderNotchOpen,
  FrameCorners,
  Funnel,
  MagnifyingGlass,
  Pencil,
  PencilSimpleLine,
  Printer,
  Warning,
  X,
} from "phosphor-react";
import React, {
  Dispatch,
  FC,
  memo,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Popover } from "react-tiny-popover";
import { useReactToPrint } from "react-to-print";
import { useDebouncedCallback } from "use-debounce";
import { ForageRequest, ForageResponse } from "../../../backend/utils/forage/forage-types";
import { ActionLink } from "../action-menu/ActionMenu";
import { Badge } from "../badge";
import { Button, DropdownButton } from "../button";
import { Loader } from "../loader";
import { Toggler, TogglerProps } from "../toggler";
import { ReportViewSelect } from "./report-view";
import { CreateReportViewParams } from "./report-view/ReportViewSelect";
import ContentLoader from "react-content-loader";
import "./Table.css";
import {
  buildForageSortFromAgGridSort,
  buildForageGroup,
  buildForageSelectFromAgGridColumns,
  buildForageGroupFilter,
  buildForageSearchFilter,
  buildForageFilterFromAgGridFilter,
} from "./table-forage-utils";
import { DateRangePicker, Formblock } from "../form";
import { DateRange } from "../form/DateRangePicker";
import { Assign } from "utility-types";
import { AgGridSelectEditor, selectEditorSuppressKeyboardEvent } from "./AgGridSelectEditor";
import { AgGridDateEditor, DEFAULT_TIME_FORMAT } from "./AgGridDateEditor";
import { ValidationRuleset } from "../form/Input";
import { useForm } from "react-hook-form";
import { AgGridCheckboxRenderer } from "./AgGridCheckboxRenderer";
import { getAllChildColumnConfigs, makeNestedUpdateUsingDotNotation, usdString } from "../utils";
import { AgGridMultiSelectEditor } from "./AgGridMultiSelectEditor";
import { LoadingModal } from "../modal";
import { IGNORE_EDIT_REQUEST_CHANGE } from "../constants";
import { Link } from "react-router-dom";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

/** Type for the current view configuration - can be a full report view object or a not created report view */
export type SelectedReportView = ReportView | CreateReportViewParams | null | undefined;

/** Table data */
export type TData = { _id: string; readonly?: boolean; [key: string]: $TSFixMe };

/** Table-specific toggler configuration */
export type TableTogglerConfig<D extends TData, T extends string = string> = {
  config: TogglerProps<T>["config"];
  secondary?: TogglerProps<T>["secondary"];
  field: string;
  customFilter?: (row: D, value: T) => boolean;
  onChange?: (value: T) => void;
};

type ColumnEditorType = "text" | "paragraph" | "number" | "select" | "multiselect" | "date" | "checkbox";

/** Additional fields that we've added to AG Grid columns */
export type ColumnConfigExtras<D extends TData> = {
  field?: ColDef["field"] | "select";
  unit?: "dollar";
  dataType?: "string" | "number" | "date" | "boolean" | "component";
  displayType?: "badge" | "dropdown";
  colors?: { [key: string]: string };
  suppressExport?: boolean;

  dateFormat?: string;
  dateType?: "iso" | "timestamp";
  isDateRange?: boolean;
  includeDateYear?: boolean;
  displayRangeFilter?: boolean;
  sumRow?: boolean;
  isCurrency?: boolean;
  alwaysShow?: boolean;

  /** Sort by a different field. SSR only */
  sortField?: string;

  /** Filter on a different field. SSR only */
  filterField?: string;
  customFilterBuilder?: (value: any) => MiterFilterField | undefined;

  /** Suppress search filter on field. SSR only */
  suppressForageSearch?: boolean;

  /** Group on a different field. SSR only */
  groupField?: string;
  groupFilterValueGetter?: (value: any) => string | undefined;

  /** Custom grouping mechanism for epoch seconds into dates */
  groupFormatter?: "seconds";

  /** Editable table fields */
  editorField?: string; // Field to use for the editor value
  editorType?: ColumnEditorType | ((params: EditableCallbackParams<D>) => ColumnEditorType);
  editorDateType?: "iso" | "timestamp" | "time";
  editableOnly?: boolean; // Only shows when table is in edit mode
  editableHide?: boolean; // Hides the field when table is in edit mode
  validations?: TableValidations<D>;
  allowClickInEditMode?: boolean; // if true, column is not disabled when table is in edit mode

  /** If you need to control the order of when the bulk edit fields are updated with new values */
  bulkEditPriority?: number;

  /** Function that takes the value from the cell edit request for this field returns the value to be set */
  valueEditor?: (newValue: any, rowData: D) => any;

  /** Dropdown props */
  dropdownOptions?: ActionLink[];
  disableCellClick?: boolean;

  /** @protected DO NOT SPECIFY DIRECTLY. This is built in the getAllChildColumnConfigs utility. */
  parentHeaderName?: string;

  // if true, the user will be notified that this field is required when editing
  isEditableRequired?: (rowData: D) => boolean;
};

export const INVALID_ENTRY_COLOR = "#ffc4c4";

export type ColumnGroupConfig<D extends TData> = Assign<
  ColGroupDef,
  { children: (ColumnGroupConfig<D> | ColumnConfig<D>)[] }
>;

/** Column configuration */
export type ColumnConfig<D extends TData> = Assign<ColDef<D>, ColumnConfigExtras<D>>;

export type ColumnOrGroupConfig<D extends TData> = ColumnConfig<D> | ColumnGroupConfig<D>;

/** Table-specific action links */
export type TableActionLink =
  | (ActionLink & { showInEditMode?: boolean })
  | {
      key: string;
      component: ReactElement;
      important?: boolean;
      disableHide?: boolean;
      showInEditMode?: boolean;
      disabled?: boolean;
      loading?: boolean;
      label?: string;
    };

/** Validation functions used in editable table fields (we allow passing in react hook form validation functions as well)*/
export type TableValidations<D extends TData> =
  | TableValidation<D>
  | TableValidation<D>[]
  | { validate: { [key: string]: TableValidation<D> } }
  | ValidationRuleset;

export type TableValidation<D extends TData> = (value: any, params?: IRowNode<D>) => string | boolean;

/** Table node that has been edited */
export type TableEditedNode<D extends TData> = {
  id: string;
  node: IRowNode<D>;
  edits: { [field: string]: any };
  saved: boolean;
  fieldErrors: TableNodeErrors;
  rowError?: string;
};

/** Last edited data item */
export type LastedEditedDataItem = { _id: string; action: "added" | "edited" | "deleted" };

/** Errors for a table node */
export type TableNodeErrors = { [field: string]: string };

/** Configuration to generate a custom filter in TableV2 */
export type TableCustomFilter = TableCustomSelectFilter;

type TableCustomSelectFilter = {
  name: string;
  type: "select";
  field: string;
  options: string[];
  comparator?: (filterValue: string, cellValue: string) => number;
};

/** Table props */
type BaseTableProps<D extends TData> = {
  /** Table identification props */
  id: string;
  title?: string;
  resource: string;

  /** Data / columns */
  columns: (ColumnConfig<D> | ColumnGroupConfig<D>)[];

  /** Loading state */
  isLoading?: boolean;

  /** Toggler configuration */
  toggler?: TableTogglerConfig<D>;

  /** Totals row props */
  showTotals?: boolean;

  /** Secondary actionbar props */
  hideSecondaryActions?: boolean;
  alwaysShowSecondaryActions?: boolean;
  hideFilters?: boolean;
  hideColumns?: boolean;
  hideExporter?: boolean;
  hidePrinter?: boolean;
  hideSearch?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideDefaultCheckbox?: boolean;
  hideSelectedCount?: boolean;
  disableSort?: boolean;
  renderLeftActionBar?: () => ReactElement;
  showExpandAll?: boolean;
  showMiniSearch?: boolean;

  /** Action props */
  dynamicActions?: TableActionLink[];
  staticActions?: TableActionLink[];

  /** Selection props */
  hideSelectColumn?: boolean;
  onSelect?: (selected: D[]) => void;
  onSelectFilter?: (selectionFilter: MiterFilterArray | undefined) => void;
  clearSelection?: boolean;
  rowSelectDisabled?: (rowNode: IRowNode<D>) => boolean;

  /** Defaults */
  defaultSelectedRows?: D[];
  defaultFilters?: { [key: string]: $TSFixMe };
  showUndefinedValuesAs?: string;

  /** Context related data that we are passing in as props */
  showReportViews?: boolean;
  reApplyReportView?: boolean;

  /** On click props */
  onClick?: (data: D) => void;
  rowClickDisabled?: (data: D) => boolean | undefined;
  rowLinkBuilder?: (data: D | undefined) => string | undefined;

  /** On filter props */
  onFilter?: (data: D[]) => void;
  onSearchFilter?: (searchFilter: string) => void;
  getFilterModel?: (e: FilterChangedEvent) => void;

  /** Styles */
  gridWrapperStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  paginationPageSize?: number;
  wrapperClassName?: string;
  containerClassName?: string;
  rowHeight?: number;

  /** Empty state */
  customEmptyStateMessage?: string;

  /** Editing props */
  editable?: boolean;
  onEditMode?: (editMode: boolean) => void;
  onEditedNodesChanged?: (editedNodeIds: string[]) => void;
  onSave?: (data: D[]) => Promise<BulkUpdateResult | void>;
  alwaysEditable?: boolean;
  alwaysShowBulkEdit?: boolean;
  autoSave?: boolean;
  hideRowEditingStatus?: boolean;
  hideBulkEdit?: boolean;
  suppressEditableGreyOut?: boolean; // Suppresses the grey out effect when the field is readonly
  editableLabel?: string;
  editableIcon?: ReactElement;

  /**
   *  This setting is used when we want to control the editable state / data in the table outside this component
   *  and is primarily useful when we don't just want to edit table data but also want to add/remove rows from
   *  the table.
   *
   *  When this is set to true, the following will happen:
   *  1. Validations will be run on every change
   *  2. We will not store edits in the editedNodes state/ref
   *  3. onSave will send back all table rows
   *  4. We won't show the row editing status
   *  */
  editableControlled?: boolean;

  /** Grouping props */
  groupDisplayType?: AgGridReactProps["groupDisplayType"];
  groupRowRendererParams?: AgGridReactProps["groupRowRendererParams"];
  groupHideOpenParents?: AgGridReactProps["groupHideOpenParents"];
  groupDefaultExpanded?: AgGridReactProps["groupDefaultExpanded"];
  groupAllowUnbalanced?: AgGridReactProps["groupAllowUnbalanced"];
  groupExpandOnSelect?: boolean;
  isServerSideGroupOpenByDefault?: AgGridReactProps["isServerSideGroupOpenByDefault"];
  autoGroupColumnDef?: AgGridReactProps["autoGroupColumnDef"];

  /** Exporter props */
  defaultCsvExportParams?: CsvExportParams;
  defaultExcelExportParams?: ExcelExportParams;

  /** Custom filter props */
  customFilters?: TableCustomFilter[];
  groupAggFiltering?: boolean;

  /** Tree data props */
  treeData?: boolean;
  getDataPath?: (data: D) => string[];

  /** Other props */
  showTimezoneDropdown?: boolean;
  exportFileName?: string;
  disablePagination?: boolean;
  setGridApi?: (api: GridApi) => void;
  setColumnApi?: (api: ColumnApi) => void;
  gridOptions?: GridOptions;
};

/** SSR table props */
type SSRTableProps<D extends TData> = BaseTableProps<D> & {
  ssr: true;
  data?: undefined;
  getData: (query: ForageRequest) => Promise<ForageResponse>;
  refreshCount?: number;
  displayDateRange?: undefined;
  defaultDateRange?: undefined;
  onDateRangeChange?: undefined;
};

/** Client side table props */
type ClientTableProps<D extends TData> = BaseTableProps<D> & {
  ssr?: false;
  data?: D[] | undefined;
  getData?: undefined;
  refreshCount?: undefined;
  displayDateRange?: boolean;
  defaultDateRange?: DateRange;
  onDateRangeChange?: (range: DateRange | undefined) => void;
};

/** Table props */
export type TableProps<D extends TData> = SSRTableProps<D> | ClientTableProps<D>;

/*
  Table V2 is Miter's new table component. It allows you to create tables with a variety of features, including:
  server-side data, filtering, sorting, pagination, column selection, and more. It is built on top of AG Grid.
*/
export const TableV2 = <D extends TData>(props: TableProps<D>): React.ReactElement | null => {
  const {
    id,
    columns,
    hideSelectColumn,
    onSelect,
    onSelectFilter,
    onEditMode,
    onEditedNodesChanged,
    toggler,
    resource,
    dynamicActions,
    staticActions,
    defaultSelectedRows,
    showReportViews,
    reApplyReportView,
    showTotals,
    hideSecondaryActions,
    alwaysShowSecondaryActions,
    hideSearch,
    showMiniSearch,
    hideFilters,
    groupAggFiltering,
    hideColumns,
    hideExporter,
    hidePrinter,
    hideFooter,
    hideHeader,
    hideSelectedCount,
    hideDefaultCheckbox,
    showExpandAll,
    disableSort,
    onClick,
    rowClickDisabled,
    rowSelectDisabled,
    onFilter,
    onSearchFilter,
    paginationPageSize,
    showUndefinedValuesAs,
    isLoading,
    wrapperClassName,
    gridWrapperStyle,
    containerStyle,
    customEmptyStateMessage,
    editable,
    editableLabel,
    editableIcon,
    suppressEditableGreyOut,
    alwaysEditable,
    alwaysShowBulkEdit,
    autoSave,
    editableControlled,
    hideRowEditingStatus,
    hideBulkEdit,
    containerClassName,
    rowHeight,
    groupDisplayType,
    groupRowRendererParams,
    groupHideOpenParents,
    groupDefaultExpanded,
    groupAllowUnbalanced,
    groupExpandOnSelect,
    autoGroupColumnDef,
    isServerSideGroupOpenByDefault,
    onSave,
    exportFileName,
    disablePagination,
    showTimezoneDropdown,
    customFilters,
    treeData,
    getDataPath,
    renderLeftActionBar,
    setGridApi,
    setColumnApi,
    gridOptions,
    rowLinkBuilder,
  }: BaseTableProps<D> = props;

  const data = props.ssr ? undefined : props.data;

  const { ssr, getData, refreshCount, displayDateRange, onDateRangeChange, defaultDateRange } = props;

  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const form = useForm();
  const activeCompanyId = useActiveCompanyId();

  const tableRef = useRef<HTMLDivElement>(null);
  const reactPrint = useReactToPrint({ content: () => tableRef.current });
  const isLoadingRef = useRef<boolean | undefined>(isLoading);
  const isMountedRef = useRef<boolean>(false);

  const nextPageKey = useRef<string | null>(null);
  const lastRow = useRef<number>(0);
  const ssrSearch = useRef<string | null>(null);
  const localGridApiRef = useRef<GridApi | undefined>();
  const localColumnApiRef = useRef<ColumnApi | undefined>();
  const activeToggleRef = useRef<string | null | undefined>();
  const initialStoreLoadRef = useRef<boolean>(true);
  const editedNodesRef = useRef<Record<string, TableEditedNode<D>>>({});
  const loadingAllPagesRef = useRef<boolean>(false);
  const loadedAllPagesRef = useRef<boolean>(false);

  /**********************************************************************************************************
   * State variables
   **********************************************************************************************************/
  // Table states
  const [localGridApi, setLocalGridApi] = useState<GridApi>();
  const [localColumnApi, setLocalColumnApi] = useState<ColumnApi>();
  const [localRangeFilter, setLocalRangeFilter] = useState<DateRange | undefined>(defaultDateRange);
  const [noRowsFound, setNoRowsFound] = useState(false);
  const [_curPage, setCurPage] = useState(0);
  const [ssrLoadCount, setSSRLoadCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [timezone, setTimezone] = useState<string>();
  const [firstDataRendered, setFirstDataRendered] = useState(false);
  const [loadingAllPages, setLoadingAllPages] = useState(false);
  const [loadedAllPages, setLoadedAllPages] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [selectFilter, setSelectFilter] = useState<MiterFilterArray | undefined>();
  const [expandedAll, setExpandedAll] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  const [miniSearchOpened, setMiniSearchOpened] = useState(false);

  // Edit mode states
  const [editMode, setEditMode] = useState(alwaysEditable ?? false);
  const [editedNodes, setEditedNodes] = useState<Record<string, TableEditedNode<D>>>({});
  const [isBulkEditing, setIsBulkEditing] = useState(false);

  // Report view states
  const [selectedReportView, setSelectedReportView] = useState<SelectedReportView>();
  const [showSaveReportViewButton, setShowSaveReportViewButton] = useState(false);

  // Debounce set selected report view
  const debouncedSetSelectedReportView = useDebouncedCallback((reportView: SelectedReportView) => {
    setSelectedReportView(reportView);
  }, 300);

  // Table actions states
  const [selectedNodes, setSelectedNodes] = useState<IRowNode<D>[]>([]);
  const [showSecondaryActionBar, setShowSecondaryActionBar] = useState(false);

  // Non state variables
  const activeToggle = toggler ? parsedSearchParams[toggler.field] || toggler.config[0]?.path : null;
  const reportViewId = selectedReportView && "_id" in selectedReportView ? selectedReportView._id : undefined;

  // If we don't have an onSelectFilter passed in (i.e. we aren't using select filters, we don't want to include group nodes in the selected node list)
  const flatSelectedNodes = useMemo(
    () => (onSelectFilter ? selectedNodes : selectedNodes.filter((node) => !node.group)),
    [selectedNodes, onSelectFilter]
  );

  const selectedRows = useMemo(() => flatSelectedNodes.map((node) => node.data!), [flatSelectedNodes]);

  const visibleStaticActions = useMemo(() => {
    return staticActions?.filter((action) => {
      if (!("shouldShow" in action)) return true;
      return action.shouldShow?.();
    });
  }, [staticActions]);

  const visibleDynamicActions = useMemo(() => {
    return dynamicActions?.filter((action) => {
      if (editMode && !action.showInEditMode) return false;
      if (!("shouldShow" in action)) return true;

      return action.shouldShow?.(selectedRows);
    });
  }, [dynamicActions]);

  const visibleImportantDynamicActions = useMemo(() => {
    return visibleDynamicActions?.filter((action) => action.important);
  }, [visibleDynamicActions]);

  const visibleNotImportantDynamicActions = useMemo(() => {
    return visibleDynamicActions?.filter((action) => !action.important);
  }, [visibleDynamicActions]);

  // Export params
  const defaultCsvExportParams = useMemo(() => {
    const fileName = exportFileName || `${DateTime.now().toISODate()}-${id}-export.csv`;
    return {
      fileName,
      skipRowGroups: true,
      skipPinnedBottom: true,
      exportedRows: "filteredAndSorted" as const,
    };
  }, [id, exportFileName]);

  const defaultExcelExportParams = useMemo(() => {
    const fileName = exportFileName || `${DateTime.now().toISODate()}-${id}-export.xlsx`;
    return {
      fileName,
      skipRowGroups: true,
      skipPinnedBottom: true,
      exportedRows: "filteredAndSorted" as const,
    };
  }, [id, exportFileName]);

  // All child column configurations, unraveled
  const childColumns = useMemo(() => getAllChildColumnConfigs(columns), [columns]);

  /**********************************************************************************************************
   * Data / column generation helpers
   **********************************************************************************************************/

  /** Auto select the filter type based on the column type */
  const getFilterType = (col) => {
    if (hideFilters) return false;

    if (col.filter != null) return col.filter;
    if (col.dataType === "string") return "agTextColumnFilter";
    if (col.dataType === "number") return "agNumberColumnFilter";
    if (col.dataType === "date") return "agDateColumnFilter";
    if (col.dataType === "boolean") return "agSetColumnFilter";

    return false;
  };

  /** Custom cell render that we use to display all columns + special columns like badges */
  const cellRenderer = (col: ColumnConfig<D>, params: ICellRendererParams<D>) => {
    if (typeof col.cellRenderer === "function") return col.cellRenderer(params);

    if (col.displayType === "badge" && params.value) {
      const link = rowLinkBuilder ? rowLinkBuilder(params.data) : undefined;

      if (link && !editMode && params.data) {
        return (
          <Link to={link} style={{ color: "#333", width: "100%" }}>
            <Badge className="table-v2-badge" text={params.value} color={col.colors?.[params.value]} />
          </Link>
        );
      }

      return <Badge className="table-v2-badge" text={params.value} color={col.colors?.[params.value]} />;
    }

    if (col.displayType === "dropdown" && col.dropdownOptions) {
      return <TableDropdownCellRenderer options={col.dropdownOptions} />;
    }

    if (col.dataType === "boolean") {
      if (params.data?._id === "totals_row") return "-";
      const isTrue = params.value === "true" || params.value === true;
      const badgeElement = isTrue ? (
        <Check weight="bold" style={{ marginBottom: -2 }} />
      ) : (
        <X weight="bold" style={{ marginBottom: -2 }} />
      );
      const color = col.colors?.[(!!params.value).toString()] || (isTrue ? "green" : "gray");
      return <Badge className="table-v2-badge" element={badgeElement} color={color} />;
    }

    if (col.dataType === "component" && params.value) {
      return params.value;
    }

    return params?.value || showUndefinedValuesAs || "-";
  };

  /** Value formatter for reformatting value */
  const valueFormatter = (col: ColumnConfig<D>, params: ValueFormatterParams) => {
    if (col.dataType === "number" && col.unit === "dollar" && !isNaN(params?.value)) {
      if (params.value == null) return showUndefinedValuesAs || "-";
      return usdString(params.value);
    }

    if (col.dataType === "date" && params?.value) {
      if (col.isDateRange) {
        const [start, end] = params.value.split(" ");
        return formatDate(start, end, col.includeDateYear);
      } else if (typeof params.value === "number") {
        return DateTime.fromSeconds(params.value)
          .setZone(timezone)
          .toFormat(col.dateFormat || "LLL dd, yyyy");
      } else if (typeof params.value === "string") {
        return DateTime.fromISO(params.value)
          .setZone(timezone)
          .toFormat(col.dateFormat || "LLL dd, yyyy");
      } else {
        return showUndefinedValuesAs || "";
      }
    }

    return params.value ?? (showUndefinedValuesAs || "-");
  };

  /** Custom comparator (used primarily for converting date formats) */
  const datetimeComparator = (filterDate, cellValue: string) => {
    const luxonFilterDate = DateTime.fromJSDate(filterDate).setZone(timezone);

    let luxonCellValue: DateTime;
    if (typeof cellValue === "string") {
      luxonCellValue = DateTime.fromISO(cellValue).setZone(timezone);
    } else if (typeof cellValue === "number") {
      luxonCellValue = DateTime.fromSeconds(cellValue).setZone(timezone);
    } else {
      return 0;
    }

    return luxonCellValue.toMillis() - luxonFilterDate.toMillis();
  };

  /** Custom comparator to use for date ranges. Expects two ISO Dates with a space in the middle and filters on both ranges */
  const dateRangeComparator = (filterDate, cellValue: string) => {
    const [start, end] = cellValue.split(" ");
    if (!start || !end) return 0;

    const luxonFilterDate = DateTime.fromJSDate(filterDate).setZone(timezone); // 1-17-2023
    const luxonCellValueStart = DateTime.fromISO(start).setZone(timezone); // 1-16-2023
    const luxonCellValueEnd = DateTime.fromISO(end).setZone(timezone); //1-22-2023

    // If the the filter date is equal to start or end, return 0
    if (luxonCellValueStart === luxonFilterDate || luxonCellValueEnd === luxonFilterDate) {
      return 0;
    }

    if (luxonFilterDate >= luxonCellValueStart && luxonFilterDate <= luxonCellValueEnd) {
      return 0;
    }

    if (luxonFilterDate < luxonCellValueEnd) {
      return 1;
    }

    if (luxonFilterDate < luxonCellValueStart) {
      return 1;
    }

    if (luxonFilterDate > luxonCellValueStart) {
      return -1;
    }

    if (luxonFilterDate > luxonCellValueEnd) {
      return -1;
    }

    return 0;
  };

  /** Build the configuration for the AG Grid column filter */
  const buildFilterParams = (col: ColumnConfig<D>) => {
    return {
      ...(col.dataType === "date" && !col.isDateRange ? { comparator: datetimeComparator } : {}),
      ...(col.dataType === "date" && col.isDateRange ? { comparator: dateRangeComparator } : {}),
      ...(ssr && col.dataType === "boolean" ? { values: ["✓", "✕"] } : {}),
      ...col.filterParams,
      inRangeInclusive: true,
      buttons: ["clear"],
    };
  };

  const buildCellClassRules = (col: ColumnConfig<D>, params: CellClassParams<D>) => {
    let currentClasses: string[] = [];

    if (col.cellClass) {
      if (typeof col.cellClass === "string") {
        currentClasses.push(col.cellClass);
      } else if (Array.isArray(col.cellClass)) {
        currentClasses = currentClasses.concat(col.cellClass);
      } else if (typeof col.cellClass === "function") {
        const cellClass = col.cellClass(params);

        if (typeof cellClass === "string") {
          currentClasses.push(cellClass);
        } else if (Array.isArray(cellClass)) {
          currentClasses = currentClasses.concat(cellClass);
        }
      }
    }

    if (col.tooltipComponent && col.tooltipValueGetter?.(params as ITooltipParams<D>)) {
      currentClasses.push("tooltip-text");
    }

    const individualCellEditable = typeof col.editable === "function" ? col.editable(params) : col.editable;

    if (
      editMode &&
      !suppressEditableGreyOut &&
      ((!individualCellEditable && !col.allowClickInEditMode) || params.data?.readonly)
    ) {
      currentClasses.push("edit-disabled");
    }

    if (editMode && params.node.id) {
      const editField = col.editorField || col.field;
      const hasError = editField && editedNodesRef?.current[params.node.id]?.fieldErrors?.[editField];

      // or, if editable required, check if the field is not null
      if (col.isEditableRequired && params.data && col.isEditableRequired(params.data) === true) {
        if (!params.value) {
          currentClasses.push("edit-error");
          currentClasses.push("tooltip-text");
        }
      }

      if (editableControlled && validateValue(params.value, col.validations)) {
        currentClasses.push("edit-error");
        currentClasses.push("tooltip-text");
      }

      if (hasError) {
        currentClasses.push("edit-error");
      }
    }

    return currentClasses || [];
  };

  const buildHeaderClassRules = (col: ColumnConfig<D>, params: HeaderClassParams<D>) => {
    let currentClasses: string[] = [];

    if (col.headerClass) {
      if (typeof col.headerClass === "string") {
        currentClasses.push(col.headerClass);
      } else if (Array.isArray(col.headerClass)) {
        currentClasses = currentClasses.concat(col.headerClass);
      } else if (typeof col.headerClass === "function") {
        const headerClass = col.headerClass(params);

        if (typeof headerClass === "string") {
          currentClasses.push(headerClass);
        } else if (Array.isArray(headerClass)) {
          currentClasses = currentClasses.concat(headerClass);
        }
      }
    }

    if (editMode && !col.editable && !col.allowClickInEditMode) {
      currentClasses.push("edit-disabled");
    }

    return currentClasses || [];
  };

  const buildEditableCallback = (col: ColumnConfig<D>, params: EditableCallbackParams<D>): boolean => {
    if (!editMode) return false;
    if (params.node.data?.readonly) return false;

    return typeof col.editable === "function" ? col.editable(params) : col.editable || false;
  };

  /** Build the cellEditor and cellEditorParams for editable columns */
  const buildCellEditorProperties = (col: ColumnConfig<D>): Partial<ColumnConfig<D>> | undefined => {
    const editorConfig: Partial<ColumnConfig<D>> = {
      editable: (params) => buildEditableCallback(col, params),
      cellEditorSelector: (params) => {
        const editorType = typeof col.editorType === "function" ? col.editorType(params) : col.editorType;
        if (editorType === "select") {
          return {
            component: "reactSelectEditor",
            popup: true,
            params: col.cellEditorParams,
          };
        } else if (editorType === "multiselect") {
          return {
            component: "reactMultiSelectEditor",
            popup: true,
            params: col.cellEditorParams,
          };
        } else if (editorType === "date") {
          return {
            component: "datePickerEditor",
            popup: true,
            params: col.cellEditorParams,
          };
        } else if (editorType === "text" || (!editorType && col.editable)) {
          return {
            component: "agTextCellEditor",
            params: col.cellEditorParams,
          };
        } else if (editorType === "paragraph") {
          return {
            component: "agLargeTextCellEditor",
            params: col.cellEditorParams,
          };
        } else if (editorType === "number") {
          return {
            component: "agNumberCellEditor",
            params: col.cellEditorParams,
          };
        }
        // Checkbox is special because we want to be able to edit that directly when in edit mode instead of having to click into the cell
        else if (col.editorType === "checkbox") {
          return undefined;
        }
      },
    };

    if (col.editorType === "select" || col.editorType === "multiselect" || col.editorType === "date") {
      editorConfig.suppressKeyboardEvent = selectEditorSuppressKeyboardEvent;
    }

    return editorConfig;
  };

  /** Build's the editing status cell for a row */
  const buildRowEditingStatus = (params: ICellRendererParams<D>) => {
    const nodeId = params.node.id;
    if (!nodeId) return;

    const edited = editedNodesRef?.current[nodeId];
    const fieldErrors = Object.values(edited?.fieldErrors || {});
    const rowError = edited?.rowError;

    if (edited && !edited.saved) {
      if (fieldErrors.length > 0 || rowError) {
        return <Warning size={20} color="#d32f2f" weight="fill" />;
      } else {
        return <PencilSimpleLine size={20} color="#f57c00" weight="fill" />;
      }
    } else {
      return <Check size={20} color="#ccc" weight="fill" />;
    }
  };

  const buildCellErrorTooltip = (params: ITooltipParams<D>) => {
    const nodeId = params.node?.id;
    if (!nodeId) return;

    // If we are in editable controlled mode, we want to validate the value on the fly
    if (editableControlled) {
      const col = params.colDef as ColumnConfig<D>;
      return validateValue(params.value, col?.validations);
    }

    const colDef = params.colDef as ColumnConfig<D>;
    const field = colDef.editorField || colDef.field;
    const error = field && editedNodesRef?.current[nodeId]?.fieldErrors?.[field];

    return error;
  };

  const buildRowErrorTooltip = (params: ITooltipParams<D>) => {
    const nodeId = params.node?.id;
    if (!nodeId) return;

    const error = editedNodesRef?.current[nodeId]?.rowError;
    return error;
  };

  const buildColumn = (
    col: ColumnConfig<D> | ColumnGroupConfig<D>
  ): ColumnConfig<D> | ColumnGroupConfig<D> | void => {
    const isGroup = "children" in col;
    if (isGroup) {
      return { ...col, children: col.children.map(buildColumn).filter(notNullish) };
    }
    const filter = getFilterType(col);
    const hideFilter = !!(toggler?.field === col.field && activeToggle && activeToggle !== "all");

    if ((col.editableOnly && !editMode) || (col.editableHide && editMode)) {
      return;
    }

    const def: ColumnConfig<D> = {
      ...col,
      menuTabs: col.menuTabs || (!hideFilter ? ["filterMenuTab"] : []),
      filter,
      filterParams: buildFilterParams(col),
      suppressMenu: col.suppressMenu || !filter,
      sortable: disableSort ? false : col.sortable,
      headerTooltip: col.headerTooltip || col.headerName,
      suppressFiltersToolPanel: hideFilter,
      headerCheckboxSelection: !ssr ? col.headerCheckboxSelection : undefined,
      tooltipValueGetter: col.tooltipValueGetter || buildCellErrorTooltip,
      cellClass: (params) => buildCellClassRules(col, params),
      headerClass: (params) => buildHeaderClassRules(col, params),
      ...buildCellEditorProperties(col),
    };

    const showCustomCellRenderer =
      !!col.cellRenderer ||
      col.displayType === "badge" ||
      (col.dataType === "boolean" && (!editMode || !col.editable)) || // booleans have a special cellrenderer when in edit mode
      col.dataType === "component";

    if (showCustomCellRenderer) {
      def.cellRenderer =
        typeof col.cellRenderer === "string" ? col.cellRenderer : (params) => cellRenderer(col, params);
    } else if (col.dataType === "boolean" && editMode) {
      def.cellRenderer = "agCheckboxCellRenderer";
    } else if (rowLinkBuilder && !editMode) {
      def.cellRenderer = (params) => {
        const link = rowLinkBuilder(params.data);

        let valueDisplay: string | ReactElement;
        if (col.valueFormatter && typeof col.valueFormatter === "function") {
          valueDisplay = col.valueFormatter(params);
        } else {
          valueDisplay = valueFormatter(col, params);
        }

        return (
          <Link to={link || "#"} style={{ color: "#333", width: "100%" }}>
            {valueDisplay}
          </Link>
        );
      };
    } else {
      def.valueFormatter = col.valueFormatter ? col.valueFormatter : (params) => valueFormatter(col, params);
    }

    return def;
  };

  /**********************************************************************************************************
   * Table data / columns (memoized)
   **********************************************************************************************************/
  const finalData = useMemo(() => {
    if (!toggler || !activeToggle || activeToggle === "all") {
      if (editMode) return data?.filter((d) => !d.readonly);
      return data;
    }

    if (toggler.customFilter) {
      return data?.filter((d) => toggler.customFilter!(d, activeToggle) && (!editMode || !d.readonly));
    }

    const res = data?.filter((d) => d[toggler.field] === activeToggle && (!editMode || !d.readonly));
    return res;
  }, [data, activeToggle, editMode]);

  const finalColumns = useMemo(() => {
    const cols: ColumnConfig<D>[] = [];

    // If there is an onSelect prop, add a select checkbox column to the table
    if (!hideSelectColumn && ((onSelect && !hideDefaultCheckbox) || editMode)) {
      cols.push({
        headerName: "",
        field: "select",
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: !ssr,
        maxWidth: 55,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        pinned: "left",
        suppressExport: true,
        cellStyle: { cursor: "default" },
      });
    }

    cols.push(...columns.map(buildColumn).filter(notNullish));

    // If the table is in the editable state, add a column for the edit status
    if (editMode && !hideRowEditingStatus && !editableControlled) {
      cols.push({
        headerName: "",
        field: "editing-status",
        maxWidth: 55,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        suppressMenu: true,
        pinned: "right",
        cellRenderer: buildRowEditingStatus,
        tooltipValueGetter: buildRowErrorTooltip,
        sortable: true,
      });
    }

    // Add shadow columns for custom filters
    if (customFilters) {
      customFilters.forEach((filter) => {
        cols.push({
          headerName: filter.name,
          field: filter.name,
          hide: true,
          suppressColumnsToolPanel: true,
          suppressExport: true,
          suppressMenu: true,
          filterParams: {
            values: filter.options,
            suppressAndOrCondition: true,
            buttons: ["apply", "reset"],
          },
          comparator: filter.comparator,
          filter: "agSetColumnFilter",
        });
      });
    }

    return cols;
  }, [columns, activeToggle, toggler?.field, !!onSelect, editMode, customFilters, hideDefaultCheckbox]);

  const isRowSelectable = useCallback<IsRowSelectable<D>>(
    (params) => {
      if (rowSelectDisabled) {
        return !rowSelectDisabled(params);
      }
      return true;
    },
    [rowSelectDisabled]
  );

  /**********************************************************************************************************
   * useEffect functions
   **********************************************************************************************************/

  /** Load the initial report view into the table  */
  useEffect(() => {
    const hasReportView =
      selectedReportView && "_id" in selectedReportView && localGridApi && localColumnApi && (data || ssr);

    if (hasReportView) {
      // IF the selected report view exists, set AgGrid to use that's report views configuration.
      handleAgGridRendered({ api: localGridApi, columnApi: localColumnApi } as AgGridEvent<$TSFixMe>);

      // ELSE IF the selected report view is null, reset AgGrid's columns to the default columns.
    } else if (!selectedReportView?.config && localGridApi && localColumnApi && (data || ssr)) {
      localColumnApi.resetColumnState();
      localGridApi.setFilterModel(null);
    }
  }, [reportViewId, !!localGridApi, !!localColumnApi, ssr, editMode]);

  /** Reload the selected report view into the table if reApplyReportView is true */
  useEffect(() => {
    if (!reApplyReportView || !isMountedRef.current || !selectedReportView || !localGridApi) return;

    handleAgGridRendered({ api: localGridApi, columnApi: localColumnApi } as AgGridEvent<$TSFixMe>);

    // If we are reloading the report view that means we probably change the data that was passed in and thus we are reapplying the view
    // therefore if we have a onFilter function we should call it with the new data to update the parent component's data
    if (onFilter) {
      const filteredRowData: D[] = [];
      localGridApi.forEachNodeAfterFilter(
        (node: IRowNode<D>) => node.data && filteredRowData.push(node.data)
      );

      onFilter(filteredRowData);
    }
  }, [reApplyReportView]);

  /** Keeps the selected rows in the grid in sync with the defaultSelectedRows prop */
  useEffect(() => {
    if (!localGridApi || !defaultSelectedRows) return;

    const selectedRowIdsSet = new Set(selectedRows.map((row) => row._id));
    const hasSameLength = selectedRows.length === defaultSelectedRows.length;
    const hasSameIds = defaultSelectedRows.every((row) => selectedRowIdsSet.has(row._id));

    // If the selected rows are the same as the default selected rows, return so we don't reselect the same rows
    // Don't return if we are using tree data because we need to reselect the rows to make sure the children are selected
    if (hasSameLength && hasSameIds && !treeData) return;

    const ids = defaultSelectedRows.map((row) => row._id);

    const selected: IRowNode[] = [];
    const selectedGroupNodes: IRowNode[] = [];
    const unselected: IRowNode[] = [];

    localGridApi.forEachNode((node) => {
      if (node?.data?._id && ids.includes(node.data._id)) {
        selected.push(node);
      } else if (node.group && node.isSelected()) {
        selectedGroupNodes.push(node);
        // We don't want to manually unselect the group node as that will unselect all children
      } else if (!node.group) {
        unselected.push(node);
      }
    });

    localGridApi.setNodesSelected({ nodes: selected, newValue: true, source: "api" });

    // Don't deselect the nodes if we are using tree data because deselecting the nodes will unselect all children
    if (!treeData) {
      localGridApi.setNodesSelected({ nodes: unselected, newValue: false, source: "api" });
    }

    setSelectedNodes(selectedGroupNodes.concat(selected));
    onSelect?.(defaultSelectedRows);
  }, [defaultSelectedRows]);

  useEffect(() => {
    if (!localGridApi || !firstDataRendered || !defaultSelectedRows?.length) return;

    setTimeout(() => {
      const ids = defaultSelectedRows.map((row) => row._id);

      const selected: IRowNode[] = [];
      const selectedGroupNodes: IRowNode[] = [];
      const unselected: IRowNode[] = [];

      localGridApi.forEachNode((node) => {
        if (node?.data?._id && ids.includes(node.data._id)) {
          selected.push(node);
        } else if (node.group && node.isSelected()) {
          selectedGroupNodes.push(node);
          // We don't want to manually unselect the group node as that will unselect all children
        } else if (!node.group) {
          unselected.push(node);
        }
      });

      localGridApi.setNodesSelected({ nodes: selected, newValue: true, source: "api" });

      // Don't deselect the nodes if we are using tree data because deselecting the nodes will unselect all children
      if (!treeData) {
        localGridApi.setNodesSelected({ nodes: unselected, newValue: false, source: "api" });
      }
    }, 300);
  }, [firstDataRendered]);

  /** Logic to show loading overlay or no-rows overlay */
  useEffect(() => {
    if (!localGridApi) return;

    if (isLoading || (data == null && !ssr)) {
      localGridApi.showLoadingOverlay();
    } else if ((data?.length || ssr) && !noRowsFound) {
      localGridApi.hideOverlay();
    } else {
      localGridApi.showNoRowsOverlay();
    }
  }, [isLoading, !!localGridApi, data?.length, noRowsFound, ssr]);

  /** Pin the totals row to the bottom of the table */
  useEffect(() => {
    localGridApi?.setPinnedBottomRowData(getTotalsRowData());
  }, [!!localGridApi, showTotals, data]);

  /** Keep the active toggle ref in sync with the active toggle in the URL for SSR */
  useEffect(() => {
    if (!ssr) return;

    activeToggleRef.current = activeToggle;
    localGridApi?.refreshServerSide({ purge: true });
  }, [activeToggle]);

  /** When refreshCount is updated, we need to refresh the data */
  useEffect(() => {
    if (!localGridApi || !refreshCount) return;
    localGridApi.refreshServerSide({ purge: true });

    nextPageKey.current = null;
    lastRow.current = 0;
  }, [refreshCount]);

  /** Recreate data source if getData changes */
  useEffect(() => {
    if (ssr && localGridApi) {
      const datasource = createDatasource();
      localGridApi.setServerSideDatasource(datasource);
    }
  }, [getData, timezone]);

  /** Reset nextPageKey + lastRow when query params change */
  useEffect(() => {
    nextPageKey.current = null;
    lastRow.current = 0;
  }, [JSON.stringify(parsedSearchParams)]);

  /** Keep isLoading ref up to date */
  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  /** Stops editing when edit mode is set to false */
  useEffect(() => {
    if (!editMode) localGridApi?.stopEditing();
  }, [editMode]);

  /** Keep local edited nodes ref in sync with state */
  useEffect(() => {
    editedNodesRef.current = editedNodes;

    localGridApi?.refreshCells({ force: true });

    // If there are no edited nodes, return otherwise we will get an infinite loop
    if (Object.keys(editedNodes).length === 0) return;

    // If we are autosaving, save the node with onSave
    if (autoSave) {
      handleEditSaveClick();
    }
  }, [editedNodes, localGridApi]);

  /** Update the edited rows with the edited data anytime we refetch data so that refetching data doesn't override edits */
  useEffect(() => {
    if (!localGridApi || !editMode || !Object.keys(editedNodes).length || !ssr) return;

    localGridApi.forEachNode((node) => {
      if (!node.id || !editedNodes[node.id]) return;
      const edits = editedNodes[node.id]?.edits;

      const updatedFields = Object.keys(edits || {});
      if (!updatedFields.length) return;

      node.updateData({ ...node.data, ...edits });
    });
  }, [ssrLoadCount]);

  /** Deselect all if ssr and no rows */
  useEffect(() => {
    if (!ssr || !localGridApi || localGridApi.paginationGetRowCount()) return;

    localGridApi.deselectAll();
  }, [ssr, localGridApi, ssrLoadCount]);

  /** Keep edit mode in sync with alwaysEditable */
  useEffect(() => {
    setEditMode(alwaysEditable ?? false);
  }, [alwaysEditable]);

  /** Keep onEditMode in sync with edit mode changes */
  useEffect(() => {
    onEditMode?.(editMode);
  }, [editMode]);

  /** Make sure custom cell renderer data is refreshed, since it's often stale even when underlying data changes */
  useEffect(() => {
    const colsToUpdate = finalColumns
      .filter((c) => c.cellRenderer || c.cellRendererSelector)
      .map((c) => c.field || c.colId)
      .filter(notNullish);

    if (!localGridApi || colsToUpdate.length === 0) return;

    localGridApi.refreshCells({ columns: colsToUpdate, force: true, suppressFlash: true });
  }, [finalColumns, finalData, !!localGridApi]);

  /** AG Grid has a bug where row selectability uses a stale copy of `isRowSelectable` regardless of whether it's updated. While we wait for a fix, here's a workaround. */
  useEffect(() => {
    if (!localGridApi) return;
    localGridApi.forEachNode((node) => {
      node.selectable = isRowSelectable(node);
    });
  }, [localGridApi, isRowSelectable]);

  /** Keep the loadedAllPagesRef in sync with state - this ref is needed for use in setIntervals */
  useEffect(() => {
    loadedAllPagesRef.current = loadedAllPages;
  }, [loadedAllPages]);

  /** Keep loadingAllPagesRef in sync with state - this ref is needed for use in setIntervals */
  useEffect(() => {
    loadingAllPagesRef.current = loadingAllPages;
  }, [loadingAllPages]);

  /** If the query URL changes, we can assume that an external toggler made a change, so set loadedAllPages to false */
  useEffect(() => {
    if (!ssr) return;
    setLoadedAllPages(false);
  }, [parsedSearchParams, reportViewId]);

  /** Keep the outside onSelectFilter in sync with internal selectFilter */
  useEffect(() => {
    if (!isMountedRef.current) return;
    onSelectFilter?.(selectFilter);
  }, [selectFilter, onSelectFilter]);

  /** Set external state of daterange to default on component mount */
  useEffect(() => {
    onDateRangeChange?.(defaultDateRange);
  }, []);

  /** Keep track of component being mounted */
  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  /**********************************************************************************************************
   * Table action handlers
   **********************************************************************************************************/
  /** Search handler that either uses ag-grid quick filter (client side) or the server side search (server side) */
  const handleSearch = useDebouncedCallback((search: string) => {
    if (!ssr) {
      localGridApi?.setQuickFilter(search);
    } else {
      ssrSearch.current = search;
      localGridApi?.refreshServerSide({ purge: true });
    }

    onSearchFilter?.(search);
  }, 500);

  /** Handler used to expand all table groups */
  const handleExpandAll = () => {
    if (expandedAll) {
      localGridApi?.collapseAll();
      setExpandedAll(false);
    } else {
      localGridApi?.expandAll();
      setExpandedAll(true);
    }
  };

  /**********************************************************************************************************
   * AG Grid event handlers
   **********************************************************************************************************/

  /** Caches the current report view configuration into state anytime the grid columns / filters change */
  const handleAgGridChanged = <T,>(event: AgGridEvent<T>) => {
    const filterState = event.api.getFilterModel();
    const columnState = event.columnApi.getColumnState();
    const groupState = event.columnApi.getColumnGroupState();
    const pivotEnabled = event.columnApi.isPivotMode();

    const config: CreateReportViewParams["config"] = {
      filters: filterState || {},
      columns: columnState,
      group: groupState,
      pivot_enabled: pivotEnabled,
    };

    // If we have made a change to an existing saved report view, show the save button
    if (selectedReportView?.config) {
      const previousConfig = {
        ...selectedReportView?.config,
        columns: selectedReportView.config.columns.map((c) => ({ ...c, width: undefined })),
        filters: selectedReportView.config.filters || {},
      };

      const currentConfig = {
        ...config,
        columns: config.columns.map((c) => ({ ...c, width: undefined })),
        filters: config.filters || {},
      };

      if (!isEqual(previousConfig, currentConfig)) {
        setShowSaveReportViewButton(true);
      }
    }

    debouncedSetSelectedReportView({ ...selectedReportView, config } as SelectedReportView);
  };

  /** Loads the report view configuration into the grid when the grid is ready or the selected report view changes */
  const handleAgGridRendered = (params: GridReadyEvent, passedInReportView?: ReportView) => {
    if (!selectedReportView && !passedInReportView) return;

    const localSelectedReportView = passedInReportView || selectedReportView;

    const columnState = localSelectedReportView?.config?.columns;
    const columnGroupState = localSelectedReportView?.config?.group;
    const filterModel = localSelectedReportView?.config?.filters;
    const pivotEnabled = localSelectedReportView?.config?.pivot_enabled;

    if (columnState) {
      params.columnApi.applyColumnState({ state: columnState, applyOrder: true });
    }
    if (columnGroupState) {
      params.columnApi.setColumnGroupState(columnGroupState);
    }
    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }
    if (pivotEnabled) {
      params.columnApi.setPivotMode(true);
    }
  };

  /** Outline the custom aggregration functions that we want to give AG Grid to have access to */
  const aggFuncs = {
    sumValues,
    identityAggFunc,
    groupDedupedSumValues,
  };

  /** The AG Grid specific event handlers that we want to bind onto the grid */
  const gridEventHandlers = {
    /** When the grid's sort is updated, keep the view configuration state in sync  */
    onSortChanged: (params: SortChangedEvent) => {
      handleAgGridChanged(params);

      if (ssr && params.api.paginationGetCurrentPage() !== 0) {
        lastRow.current = 0;
        nextPageKey.current = null;

        params.api.paginationGoToPage(0);
        params.api.refreshServerSide({ purge: true });
      }
    },
    /** When the grid's filter are updated, keep the view configuration state in sync  */
    onFilterChanged: (params: FilterChangedEvent) => {
      handleAgGridChanged(params);

      if (ssr && params.api.paginationGetCurrentPage() !== 0) {
        lastRow.current = 0;
        nextPageKey.current = null;

        params.api.paginationGoToPage(0);
        params.api.refreshServerSide({ purge: true });
      }

      // We want to reset the loadedAllPages state when the filter changes
      if (ssr) setLoadedAllPages(false);

      const dateRangeFilterField = childColumns.find((col) => col.displayRangeFilter);
      if (dateRangeFilterField && "field" in dateRangeFilterField && dateRangeFilterField.field) {
        const dateRangeFilter = params.api.getFilterInstance(dateRangeFilterField.field);
        if (!dateRangeFilter) return;

        const dateFrom = dateRangeFilter?.getModel()?.dateFrom;
        const dateTo = dateRangeFilter?.getModel()?.dateTo;

        const dateRange = {
          start: dateFrom
            ? DateTime.fromFormat(dateRangeFilter?.getModel()?.dateFrom, AG_GRID_DATE_FORMAT).setZone(
                timezone
              )
            : undefined,

          end: dateTo
            ? DateTime.fromFormat(dateRangeFilter?.getModel()?.dateTo, AG_GRID_DATE_FORMAT).setZone(timezone)
            : undefined,
        };

        setLocalRangeFilter(dateRange);
      }

      // If we have a filter callback, call it with the filtered data
      if (onFilter) {
        const filteredRowData: D[] = [];
        params.api.forEachNodeAfterFilter(
          (node: IRowNode<D>) => node.data && filteredRowData.push(node.data)
        );

        onFilter(filteredRowData);
      }

      // If we have a select filter, deselect all selected nodes so that we can reset the select filter
      if (onSelectFilter && selectedNodes.length) {
        localGridApi?.deselectAll();
      }
    },
    /** When the column is moved around, keep the view configuration state in sync + autosize all columns */
    onColumnMoved: (params: ColumnMovedEvent) => {
      handleAgGridChanged(params);
      setTimeout(() => params.api.sizeColumnsToFit(), 0);
    },
    /** When the column becomes visible keep the view configuration state in sync + autosize all columns */
    onColumnVisible: (params: ColumnVisibleEvent) => {
      handleAgGridChanged(params);
      setTimeout(() => params.api.sizeColumnsToFit(), 0);

      if (ssr) {
        lastRow.current = 0;
        nextPageKey.current = null;

        params.api.paginationGoToPage(0);
        params.api.refreshServerSide();
      }
    },
    /** When the column's value is changed, keep the view configuration state in sync */
    onColumnValueChanged: (params: ColumnValueChangedEvent) => {
      handleAgGridChanged(params);
    },
    /** When the column's pivot is changed, keep the view configuration state in sync */
    onColumnPivotChanged: (params: ColumnPivotChangedEvent) => {
      handleAgGridChanged(params);
    },
    /** When the column group is opened, keep the view configuration state in sync */
    onColumnGroupOpened: (params: ColumnGroupOpenedEvent) => {
      handleAgGridChanged(params);
    },
    /** When the size of the grid is changed, automatically update column widths */
    onGridSizeChanged: (params: GridSizeChangedEvent) => {
      // ag-grid bug where we need to ensure the grid exists; otherwise we get an error if navigating directly to a page with a Table on it
      // Checking `params.api` doesn't work
      if (localGridApi) params.api.sizeColumnsToFit();
    },
    /** When the grid's row group, keep the view configuration state in sync. If changed to show row groups, hide select boxes*/
    onColumnRowGroupChanged: (params: ColumnRowGroupChangedEvent) => {
      handleAgGridChanged(params);

      if (ssr && params.api.paginationGetCurrentPage() !== 0) {
        params.api.paginationGoToPage(0);
      }

      // Deselect all selected nodes if we start grouping
      if (selectedNodes.length) {
        localGridApi?.deselectAll();
      }
    },
    /** When the grid's columns are resized, keep the view configuration state in sync */
    onColumnResized: (params: ColumnResizedEvent) => {
      handleAgGridChanged(params);
    },
    /** When the grid's columns are changed, keep the view configuration state in sync */
    onGridColumnsChanged: (params: GridColumnsChangedEvent) => {
      handleAgGridChanged(params);
    },
    /** When the column's pivot mode is changed, keep the view configuration state in sync */
    onColumnPivotModeChanged: (params: ColumnPivotModeChangedEvent) => {
      handleAgGridChanged(params);
    },
    /** When the AG Grid is first rendered, we want to load the report view configuration into the grid */
    onFirstDataRendered: (params: FirstDataRenderedEvent) => {
      // handleFirstDataRendered(params);
      setFirstDataRendered(true);
      setTimeout(() => handleAgGridRendered(params), 0);
    },
    /** When AG Grid cell is changed, we want to keep track of the changed cell in our editedNotes state */
    onCellEditRequest: (params: CellEditRequestEvent) => {
      handleCellEditRequest(params);
    },
    /** Save the AG Grid grid/column APIs into state for access across the component + autoresize columns*/
    onGridReady: (params: GridReadyEvent) => {
      setLocalGridApi(params.api);
      setLocalColumnApi(params.columnApi);
      setGridApi?.(params.api);
      setColumnApi?.(params.columnApi);

      localGridApiRef.current = params.api;
      localColumnApiRef.current = params.columnApi;

      params.api.sizeColumnsToFit();
      params.api.setSideBar(buildSidebar);
      params.api.setSideBarVisible(false);

      if (ssr) {
        const datasource = createDatasource();
        params.api.setServerSideDatasource(datasource);
      }
    },
    /** Sync changes to the AG Grid selected rows with our component state */
    onSelectionChanged: (params: SelectionChangedEvent) => {
      // Don't want to sync selection if the change was triggered by the API
      if (params.source === "api") return;

      // Get all selected nodes and put it into state
      const newSelectedNodes = params.api.getSelectedNodes();
      setSelectedNodes(newSelectedNodes);

      // Get the non grouped select nodes and put into state + send it to the onSelect callback
      const flatNewSelectedNodes = newSelectedNodes.filter((node) => !node.group);
      const flatNewSelectedRows = flatNewSelectedNodes.map((node) => node.data);
      onSelect?.(flatNewSelectedRows);

      // Update the selections for the select filter by rebuilding the default filter model
      handleSyncSelectFilter(params);
    },
    /** When new data is pulled from the server, if the pull is due to group expansion, sync selection state */
    onStoreUpdated: (params: StoreUpdatedEvent) => {
      if (initialStoreLoadRef.current) return;

      // Get all selected nodes and put it into state
      const newSelectedNodes = params.api.getSelectedNodes();
      setSelectedNodes(newSelectedNodes);

      // Get the non grouped select nodes and put into state + send it to the onSelect callback
      const flatNewSelectedNodes = newSelectedNodes.filter((node) => !node.group);
      const flatNewSelectedRows = flatNewSelectedNodes.map((node) => node.data);
      onSelect?.(flatNewSelectedRows);
    },
    /** When the tool panel is closed, hide the sidebar as well + autosize all columns*/
    onToolPanelVisibleChanged: (params: ToolPanelVisibleChangedEvent) => {
      if (params.api.isToolPanelShowing()) return;

      // Hide sidebar if tool panel is closed
      params.api.setSideBarVisible(false);
      params.api.sizeColumnsToFit();
    },
    /** When a cell is clicked */
    onCellClicked: (params: CellClickedEvent<D>) => {
      if (!onClick || !params.data) return;
      if (rowClickDisabled?.(params.data)) return;
      if (params.colDef.field === "select") return;
      if (params.node.group) return;
      if (params.node.rowPinned) return;
      if ((params.colDef as ColumnConfig<D>).disableCellClick) return;
      if (editMode) return;
      if (rowLinkBuilder) return;
      onClick(params.data);
    },
    /** When model is updated */
    onModelUpdated: (event: ModelUpdatedEvent) => {
      event.api.getDisplayedRowCount() === 0 ? setNoRowsFound(true) : setNoRowsFound(false);
    },
    /** When the row data is updated */
    onRowDataUpdated: (params: RowDataUpdatedEvent) => {
      params.api.sizeColumnsToFit();
    },
    /** When the page is changed */
    onPaginationChanged: (params: PaginationChangedEvent) => {
      // Set the header params of the select column to checked: false
      if (ssr && params.api && params.api.paginationGetCurrentPage() !== 0) {
        setCurPage(params.api.paginationGetCurrentPage());
      }
    },
    /** WHen a row is selected */
    onRowSelected: (params: RowSelectedEvent) => {
      if (!ssr || !groupExpandOnSelect) return;

      // We want to expand the node if it's a group node that is being selected
      const node = params.node;
      if (node.group) node.setExpanded(true);
    },
    /** Pass in the Miter-defined aggregation functions */
    aggFuncs: { ...aggFuncs },
  };

  /**********************************************************************************************************
   * AG Grid data functions
   **********************************************************************************************************/
  const getChildCount = useCallback((data: D & { count: number }) => data.count, []);

  const getRowClass = useCallback(
    (params: RowClassParams<D>) => {
      if (params.data && rowClickDisabled?.(params.data)) return "click-disabled";
      return "";
    },
    [rowClickDisabled]
  );

  const getTotalsRowData = useCallback(() => {
    if (!showTotals || !data?.length) return [];

    const filteredNodes: IRowNode<D>[] = [];
    localGridApi?.forEachNodeAfterFilter((node: IRowNode<D>) => filteredNodes.push(node));

    const totalsRow = filteredNodes.reduce((overall, node) => {
      const data = node.data || {};
      return Object.keys(data).reduce((acc, key) => {
        if (data[key] == null) return acc;

        // Only do something if sumRow is true
        const sumRow = childColumns.find((col) => col.field === key)?.sumRow;
        if (!sumRow) return acc;

        // Check if the column is a number or strip any non-numeric characters and convert it into a number if possible
        const isNumber = typeof data[key] === "number";
        const val = isNumber ? data[key] : Number(data[key].toString().replace(/[^0-9.-]+/g, ""));

        if (isNaN(val)) return acc;

        acc[key] = (acc[key] || 0) + val;
        return acc;
      }, overall);
    }, {});

    for (const key of Object.keys(totalsRow)) {
      if (typeof totalsRow[key] == "number") {
        const cellValue = cleanFloatingPointErrors(totalsRow[key]);

        const cellColumn = localColumnApi?.getColumn(key);
        if ((cellColumn?.getColDef() as ColumnConfig<D>)?.isCurrency) {
          totalsRow[key] = "$" + cellValue.toFixed(2);
        } else {
          totalsRow[key] = cellValue;
        }
      }
    }

    // @ts-expect-error generics!
    totalsRow._id = "totals_row";

    return [totalsRow];
  }, [data, localGridApi, showTotals, childColumns]);

  /**********************************************************************************************************
   * Event handlers for table actions (not for AG Grid itself)
   **********************************************************************************************************/
  const handleFilterClick = () => {
    const isOpen =
      localGridApi?.isSideBarVisible() && localGridApi?.getSideBar()?.defaultToolPanel === "filters";

    if (!isOpen) {
      localGridApi?.setSideBar({ ...buildSidebar, defaultToolPanel: "filters", hiddenByDefault: false });
    } else {
      localGridApi?.setSideBarVisible(false);
    }

    setShowSecondaryActionBar(false);
  };

  const handleColumnsClick = () => {
    const isOpen =
      localGridApi?.isSideBarVisible() && localGridApi?.getSideBar()?.defaultToolPanel === "columns";

    if (!isOpen) {
      localGridApi?.setSideBar({ ...buildSidebar, defaultToolPanel: "columns", hiddenByDefault: false });
    } else {
      localGridApi?.setSideBarVisible(false);
    }

    setShowSecondaryActionBar(false);
  };

  const handleClearSelection = () => {
    setSelectedNodes([]);
    onSelect?.([]);
    localGridApi?.deselectAll();
  };

  const handleEditModeClick = () => {
    if (!localGridApi) return;

    // Clear selection if we are exiting edit mode
    if (editMode) handleClearSelection();
    setEditMode(!editMode);
  };

  const handleEditSaveClick = async () => {
    if (!onSave) return;

    setSaving(true);

    // If the grid is managing the edited state, we need to check for errors before saving
    if (!editableControlled) {
      // If any of the nodes has a row error or field error, don't save
      const hasRowError = Object.values(editedNodes).some(
        (node) => node.rowError || Object.keys(node.fieldErrors || {}).length > 0
      );

      if (hasRowError) {
        Notifier.error("One or more rows have errors. Please correct them and try again.");
        return setSaving(false);
      }
    }

    const savingData: D[] = [];

    // If we are in editable controlled mode, we need to send all grid data because we are managing the edited state outside the table
    if (editableControlled) {
      localGridApi?.forEachNode((node) => {
        if (node.data) savingData.push(node.data);
      });
    } else {
      Object.values(editedNodes)
        .filter((node) => !node.saved && node.node.data)
        .forEach(({ node }) => savingData.push(node.data!));
    }

    // Call the onSave callback
    const saveResponse = await onSave(savingData);

    // If there is no save response, return and don't update the table
    if (!saveResponse) return setSaving(false);

    // If there was a save response, get the errors and successes
    const { errors, successes } = saveResponse;

    // If there no errors, reset the table and return
    if (!errors?.length) {
      if (successes.length) {
        Notifier.success("Successfully saved changes");
      }
      handleResetTable();
    } else {
      Notifier.error("One or more errors occurred while saving changes. Please correct them and try again.");

      // Clone the edited nodes and create a lookup for the errors so we can get O(1) lookup
      const updatedEditedNodes = cloneDeep(editedNodes);
      const errorLookup = keyBy(errors, "_id");

      // Get the errors from the data and update the edited nodes with the error
      Object.values(editedNodes).forEach(({ node }) => {
        const rowId = node.data?._id;
        if (!rowId || !updatedEditedNodes[rowId]) return;

        const error = errorLookup[rowId];

        // If there is an error, add the error to the node, if not, mark the node as saved
        if (error) {
          // Add row and field errors to the node
          let updatedNode = set(updatedEditedNodes[rowId]!, `rowError`, error.message);

          const fieldErrorsMap = (error.fieldErrors || []).reduce((acc, fieldError) => {
            acc[fieldError.field] = fieldError.message;
            return acc;
          }, {} as Record<string, string>);

          updatedNode = set(updatedNode, "fieldErrors", fieldErrorsMap);

          updatedEditedNodes[rowId] = updatedNode;
        } else {
          updatedEditedNodes[rowId] = set(updatedEditedNodes[rowId]!, "saved", true);
        }
      });

      // Update the edited nodes
      setEditedNodes(updatedEditedNodes);
      onEditedNodesChanged?.(Object.keys(updatedEditedNodes));
    }

    setSaving(false);
  };

  const handleEditCancelClick = () => {
    handleResetTable();
  };

  const handleResetTable = () => {
    if (!localGridApi) return;

    setEditedNodes({});
    onEditedNodesChanged?.([]);

    localGridApi.stopEditing(true);
    handleClearSelection();

    // Reset data source if SSR so we can fetch updated data
    if (ssr) {
      const datasource = createDatasource();
      localGridApi.setServerSideDatasource(datasource);
      setEditMode(false);
    }

    if (!alwaysEditable) {
      setEditMode(false);
    }
  };

  const handleLoadAllPages = () => {
    if (!localGridApi) return;

    setLoadingAllPages(true);

    // Recreate the data source so getData is called again with the new variables
    const datasource = createDatasource();
    localGridApi.setServerSideDatasource(datasource);

    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (loadedAllPagesRef.current) {
          clearInterval(interval);
          setLoadingAllPages(false);
          resolve(true);
        }
      }, 1000);
    });
  };

  const handleExportClick = async () => {
    if (!localColumnApi || !localGridApi) return;
    setExporting(true);
    setShowSecondaryActionBar(false);

    if (ssr) {
      localGridApi.expandAll();
      await sleep(100);
      loadedAllPagesRef.current = false;
    }

    // Wait till all pages are loaded if we are exporting SSR data
    if (ssr && !loadedAllPages) {
      await handleLoadAllPages();
    }

    const exportableColumns: string[] = [];

    for (const c of localColumnApi.getAllDisplayedColumns()) {
      const colDef = c.getColDef() as ColumnConfig<D>;
      if (colDef.suppressExport) continue;
      const field = colDef.field || colDef.colId;
      if (field) exportableColumns.push(field);
    }

    const params: CsvExportParams = {
      ...defaultCsvExportParams,
      ...props.defaultCsvExportParams,
      columnKeys: exportableColumns,
    };

    localGridApi.exportDataAsCsv(params);
    setExporting(false);
  };

  const handleExportExcelClick = async () => {
    if (!localColumnApi || !localGridApi) return;

    setExporting(true);
    setShowSecondaryActionBar(false);

    if (ssr) {
      localGridApi.expandAll();
      await sleep(100);
      loadedAllPagesRef.current = false;
    }

    // Wait till all pages are loaded if we are exporting SSR data
    if (ssr && !loadedAllPages) {
      await handleLoadAllPages();
    }

    const exportableColumns: string[] = [];

    for (const c of localColumnApi.getAllDisplayedColumns()) {
      const colDef = c.getColDef() as ColumnConfig<D>;
      if (colDef.suppressExport) continue;
      const field = colDef.field || colDef.colId;
      if (field) exportableColumns.push(field);
    }

    const params: ExcelExportParams = {
      ...defaultExcelExportParams,
      ...props.defaultExcelExportParams,
      columnKeys: exportableColumns,
    };

    localGridApi.exportDataAsExcel(params);
    setExporting(false);
  };

  const handlePrintClick = () => {
    if (!localColumnApi || !localGridApi || hidePrinter) return;

    const eGridDiv = document.getElementById("#table-v2");
    if (eGridDiv) {
      eGridDiv.setAttribute("height", "");
      eGridDiv.style.height = "";
      eGridDiv.style.width = "500px";
    }
    localGridApi?.setDomLayout("print");
    localGridApi?.sizeColumnsToFit();

    localGridApi.setSideBarVisible(false);

    setTimeout(() => {
      reactPrint();
      localGridApi.setDomLayout("autoHeight");
    }, 1000);

    setShowSecondaryActionBar(false);
  };

  const handleParseEditedValue = (params: CellEditRequestEvent<D>) => {
    const colDef = params.column.getColDef() as ColumnConfig<D>;
    const { editorType, editorDateType, dateType } = colDef;

    if (editorType === "date") {
      if (editorDateType === "iso") {
        if (dateType === "iso") {
          return params.newValue;
        } else if (dateType === "timestamp") {
          return DateTime.fromISO(params.newValue).setZone(timezone).toSeconds();
        }
      } else if (editorDateType === "timestamp") {
        if (dateType === "iso") {
          return DateTime.fromSeconds(params.newValue).setZone(timezone).toISODate();
        } else if (dateType === "timestamp") {
          return Number(params.newValue);
        }
      }
    }

    return params.newValue;
  };

  /**
   * Syncs the currently selected table items with the selectFilter
   * - Generates an or filter with filters for the selected groups and an $in filter for the ids of the selected individual nodes
   * */
  const handleSyncSelectFilter = (params: SelectionChangedEvent<D>): void => {
    if (!ssr || !activeCompanyId) return;

    let newSelectFilter: MiterFilterArray;

    // Get the row grouped columsn coldDefs
    const rowGroupCols = (localColumnApi?.getRowGroupColumns() || []).map((c) => c.getColDef());

    // Get visible columns
    const visibleColumns = params.columnApi
      .getAllGridColumns()
      .filter((c) => c.isVisible() || (c.getColDef() as ColumnConfig<D>).alwaysShow);

    // If we are selecting all, we want to use the current filter model as the select filter
    const selectAllEvents = ["uiSelectAll", "apiSelectAll", "uiSelectAllFiltered", "apiSelectAllFiltered"];
    if (selectAllEvents.includes(params.source)) {
      newSelectFilter = buildForageFilterFromAgGridFilter(
        params.api.getFilterModel(),
        params,
        columns,
        timezone
      );

      // Ensure that the select filter includes the active company always
      newSelectFilter.push({
        type: "or",
        value: [
          { field: "company", value: activeCompanyId },
          { field: "company_id", value: activeCompanyId },
        ],
      });

      if (ssrSearch.current && ssrSearch.current.length) {
        const searchFilter = buildForageSearchFilter(ssrSearch.current, visibleColumns, rowGroupCols, params);
        newSelectFilter.push(...searchFilter);
      }

      setSelectFilter(newSelectFilter);
    } else {
      // Iterate through the selected nodes
      const idFilter: MiterFilterField = { field: "_id", type: "_id", comparisonType: "in", value: [] };
      const groupFilters: MiterFilterArray = [];

      for (const node of params.api.getSelectedNodes()) {
        // If this is a grouped node, we need to build a filter for the group and add it to the groupFilters array
        if (node.group) {
          // Get's the path to the group - needed to build the filter
          const groupKeys = node.getRoute() || [];
          const groupFilter: MiterFilterField = {
            type: "and",
            value: buildForageGroupFilter(groupKeys, rowGroupCols, columns, timezone),
          };

          groupFilters.push(groupFilter);
        } else if (node.data) {
          // If this is a non-grouped node, we need to add the id to the id filter
          idFilter.value.push(node.data._id);
        }
      }

      // Get the table filters
      const baseFilters = buildForageFilterFromAgGridFilter(
        params.api.getFilterModel(),
        params,
        columns,
        timezone
      );

      // The new select filter is an 'or' of all the group filters and the id filter
      newSelectFilter = [{ type: "or", value: groupFilters }, ...baseFilters];

      // Only add the id filter if there are ids, otherwise an empty array will return no results
      if (idFilter.value.length) newSelectFilter[0]!.value.push(idFilter);

      // If the filters length is 0, set select filter to undefined
      if (groupFilters.length === 0) {
        setSelectFilter(undefined);
      } else {
        setSelectFilter(newSelectFilter);
      }
    }
  };
  /**********************************************************************************************************
   * Report view handlers
   **********************************************************************************************************/
  const handleViewSelect = (selectedView: SelectedReportView) => {
    setSelectedReportView(selectedView);
    setShowSaveReportViewButton(false);
  };

  const handleOnViewSave = () => {
    setShowSaveReportViewButton(false);
  };

  const handleViewReset = () => {
    if (!localColumnApi || !localGridApi) return;

    localColumnApi.resetColumnState();
    localGridApi.setFilterModel(null);

    setSelectedReportView(null);
    setShowSaveReportViewButton(false);
  };

  /**********************************************************************************************************
   * Toggler handlers
   **********************************************************************************************************/
  const handleToggle = (val: string) => {
    setLoadedAllPages(false);
    if (!toggler?.field || !localGridApi) return;

    setSearchParams({ [toggler.field]: val });
    toggler.onChange?.(val);

    setSelectedNodes([]);
    onSelect?.([]);

    setShowSecondaryActionBar(false);
    localGridApi.deselectAll();
  };

  /**********************************************************************************************************
   * Event handlers for editing related actions
   **********************************************************************************************************/
  const handleCellEditRequest = (params: CellEditRequestEvent<D>) => {
    // Don't call this if we make changes via API
    if (params.source === "api") return;

    // Make sure the node has an id and data
    if (!params.node || !params.node.id || !params.node.data) return;

    // Get column / field information
    const colDef = params.colDef as ColumnConfig<D>;
    const valueEditor = colDef.valueEditor;
    const editField = (colDef.editorField || colDef.field)!;
    const validations = colDef.validations;

    // Run validations
    const errors = handleValidations({
      editField,
      validations,
      cellEditRequest: params,
      existingErrors: editedNodes[params.node.id]?.fieldErrors,
    });

    // Initialize final value so we can add it in the edited nodes list later
    let finalValue: any;

    // If there is a custom valueEditor passed, let that handle all the generation of the final value
    if (valueEditor) {
      finalValue = valueEditor(params.newValue, params.data);
    } else {
      // Convert the value into the parsed state
      finalValue = handleParseEditedValue(params);
    }

    if (finalValue === IGNORE_EDIT_REQUEST_CHANGE) return;

    // Clone the data so we don't mutate the original
    const updatedData = cloneDeep(params.node.data);

    // This stages the change
    makeNestedUpdateUsingDotNotation(editField, finalValue, updatedData);

    params.node.updateData(updatedData);

    // Update the list of edited nodes
    setEditedNodes({
      ...editedNodes,
      [params.node.id]: {
        id: params.node.id,
        node: params.node,
        edits: { ...editedNodes[params.node.id]?.edits, [editField]: finalValue },
        fieldErrors: errors,
        saved: false,
      },
    });
    onEditedNodesChanged?.([...Object.keys(editedNodes), params.node.id]);
  };

  /**********************************************************************************************************
   * Server side functions
   **********************************************************************************************************/

  /**
   * Creates the data source that AG Grid will use to fetch and load data from the serfver
   * https://www.ag-grid.com/react-data-grid/server-side-model-datasource/
   * */
  const createDatasource = (): IServerSideDatasource => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        if (!getData) throw new Error("getData function is required for server side rendering");

        // Get important properties from the AG Grid getRows request
        const { startRow, endRow, filterModel, groupKeys, rowGroupCols } = params.request;

        // Get all grid columsn and filter out the ones that are not visible or not always shown
        const visibleColumns = params.columnApi
          .getAllGridColumns()
          .filter((c) => c.isVisible() || (c.getColDef() as ColumnConfig<D>).alwaysShow);

        // Build the sort, filter, and group objects for the forage query
        const sort = buildForageSortFromAgGridSort(params);
        const filter = buildForageFilterFromAgGridFilter(filterModel, params, columns, timezone);
        const group = buildForageGroup(params);
        const finalTimezone = timezone || DateTime.local().zoneName;

        // Only select columns if we're not grouping
        const select = !group.length ? buildForageSelectFromAgGridColumns(visibleColumns) : undefined;

        // Build the filter for the group (if a group is being expanded)
        if (groupKeys && groupKeys.length) {
          const groupFilter = buildForageGroupFilter(groupKeys, rowGroupCols, columns, timezone);
          filter.push(...groupFilter);
        }

        // Build the filter for the search bar (if a search query exists)
        if (ssrSearch.current && ssrSearch.current.length) {
          const searchFilter = buildForageSearchFilter(
            ssrSearch.current,
            visibleColumns,
            rowGroupCols,
            params
          );

          filter.push(...searchFilter);
        }

        // Add toggle filter
        const hasToggleFilter =
          toggler?.field &&
          activeToggleRef.current &&
          activeToggleRef.current != null &&
          activeToggleRef.current !== "all";

        if (hasToggleFilter) {
          filter.push({
            field: toggler.field,
            comparisonType: "=",
            value: activeToggleRef.current,
          });
        }

        /* Issue we need to solve is nextPage and lastRow are out of sync when we need to refresh all row data. To fix that, we will need to reset them whenever refreshCount happens */
        const starting_after = lastRow.current === startRow ? nextPageKey.current : null;
        const res = await getData({ sort, filter, select, group, starting_after, timezone: finalTimezone });
        if (!res) return;

        // If we are currently trying to load all pages, keep loading pages until we get to the end
        if (loadingAllPagesRef.current) {
          while (res.next_page) {
            const nextPage = await getData({
              sort,
              filter,
              select,
              group,
              starting_after: res.next_page,
              timezone: finalTimezone,
            });
            if (!nextPage) return;
            res.data = res.data.concat(nextPage.data);
            res.next_page = nextPage.next_page;
          }
        }

        // If a next page key is returned, set it as the next page key in ref otherwise set it to null
        if (res.next_page) {
          nextPageKey.current = res.next_page;
        } else {
          nextPageKey.current = null;
          setTimeout(() => setLoadedAllPages(true), 500);
        }

        // If the end row is greater than the last row, set the last row to the end row
        lastRow.current = Math.max(lastRow.current, endRow || 0);

        // Build the total row count for the grid
        const rowCount = nextPageKey.current ? undefined : (startRow || 0) + (res.data?.length || 0);

        // Keep track of initial data load (we need the timeout so that the pre-selected rows don't get cleared. The 500ms is a precaution)
        if (initialStoreLoadRef.current) {
          setTimeout(() => {
            initialStoreLoadRef.current = false;
          }, 500);
        }

        // Keep track of the edited rows so we can save them later (need the 100ms to wait for the data to be loaded)
        setTimeout(() => {
          setSSRLoadCount((prev) => prev + 1);
        }, 100);

        try {
          params.success({
            rowData: res.data,
            rowCount,
          });
        } catch (e) {
          console.warn("Error with setting data", e);
        }
      },
    };
  };

  /**********************************************************************************************************
   * Render functions
   **********************************************************************************************************/
  const renderReportViewDropdown = () => {
    if (!id || !localGridApi || !localColumnApi || !showReportViews || editMode) return;
    return (
      <div
        className="table-report-view-select"
        style={selectedRows.length ? { display: "none" } : { marginRight: 15 }}
      >
        <ReportViewSelect
          reportId={id}
          value={selectedReportView}
          onSelect={handleViewSelect}
          onSave={handleOnViewSave}
          showSaveButton={showSaveReportViewButton}
          onReset={handleViewReset}
        />
      </div>
    );
  };

  const renderSecondaryActionBar = () => {
    if (hideSecondaryActions) return <></>;

    const staticActionElements = (visibleStaticActions || [])
      .filter((action) => !action.important)
      .map((action) => {
        if ("component" in action) {
          return (
            <div key={action.key} onClick={() => !action.disableHide && setShowSecondaryActionBar(false)}>
              {action.component}
            </div>
          );
        }

        return (
          <Button
            className={"table-v2-secondary-action-bar-btn " + action.className}
            key={action.label}
            onClick={() => {
              action.action(selectedRows);
              setShowSecondaryActionBar(false);
            }}
            style={action.style}
            loading={action.loading}
            disabled={action.disabled}
            dropdownItems={action.dropdownItems}
          >
            {action.icon}
            {action.label}
          </Button>
        );
      });

    return (
      <div className="table-v2-secondary-action-bar">
        <div className="table-v2-secondary-action-bar-left">
          {!hideFilters && (
            <Button onClick={handleFilterClick} style={{ ...commonBtnStyles, marginLeft: 0 }}>
              <Funnel weight="bold" style={commonIconStyles} />
              Filters
            </Button>
          )}
          {!hideColumns && (
            <Button onClick={handleColumnsClick} style={{ ...commonBtnStyles, marginLeft: 0 }}>
              <Columns weight="bold" style={commonIconStyles} />
              Columns
            </Button>
          )}
          {showExpandAll && (
            <Button onClick={handleExpandAll} style={{ ...commonBtnStyles, marginLeft: 0 }}>
              <FrameCorners weight="bold" style={commonIconStyles} />
              {expandedAll ? "Collapse all" : "Expand all"}
            </Button>
          )}
        </div>
        <div className="table-v2-secondary-action-bar-right">
          {staticActionElements}

          {!hideExporter && (
            <>
              <Button onClick={handleExportClick} style={{ ...commonBtnStyles, marginLeft: 8 }}>
                <FileCsv weight="bold" style={commonIconStyles} />
                Export CSV
              </Button>
              <Button onClick={handleExportExcelClick} style={{ ...commonBtnStyles }}>
                <FileXls weight="bold" style={commonIconStyles} />
                Export Excel
              </Button>
            </>
          )}
          {!hidePrinter && (
            <Button onClick={handlePrintClick} style={{ ...commonBtnStyles, marginLeft: 0, marginRight: 0 }}>
              <Printer weight="bold" style={commonIconStyles} />
              Print
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderActionButtonElement = (action) => {
    if ("component" in action) {
      return <div key={action.key}>{action.component}</div>;
    }

    return (
      <Button
        className={"table-v2-secondary-action-bar-btn " + action.className}
        key={action.label}
        onClick={() => action.action(selectedRows)}
        style={action.style}
        loading={action.loading}
        disabled={action.disabled}
      >
        {action.icon}
        {action.label}
      </Button>
    );
  };

  /**
   *  If there are more than 4 dynamic actions, render only important actions and a dropdown for the rest
   *  If there are less than 4 dynamic actions, render all of them as normal buttons
   */
  const renderDynamicActions = () => {
    const dynamicButtonElements = visibleDynamicActions?.map(renderActionButtonElement);
    const dynamicImportantButtonElements = visibleImportantDynamicActions?.map(renderActionButtonElement);

    if (!dynamicButtonElements?.length) return <></>;

    return (
      <>
        <span className="table-v2-selected-count-text">&nbsp;|&nbsp;</span>{" "}
        {dynamicButtonElements.length <= 4 ? (
          dynamicButtonElements
        ) : (
          <>
            {dynamicImportantButtonElements}
            <DropdownButton
              className={"button-1 table-v2-secondary-action-bar-btn shift-icons"}
              shiftIcons={true}
              options={visibleNotImportantDynamicActions || []}
              closeOnClick={true}
            >
              Actions
              <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
            </DropdownButton>
          </>
        )}
      </>
    );
  };

  const renderActionBarRight = () => {
    const importantStaticActionElements = (visibleStaticActions || [])
      .filter((action) => action.important)
      .map(renderActionButtonElement);

    const importantStaticActionElementsInEditMode = (visibleStaticActions || [])
      .filter((action) => action.important && action.showInEditMode)
      .map(renderActionButtonElement);

    const noSecondaryActions =
      hideFilters &&
      hideExporter &&
      hideColumns &&
      hidePrinter &&
      !!visibleStaticActions?.every((action) => action.important);

    const shouldShowSecondaryActions =
      alwaysShowSecondaryActions ||
      (!editMode && (!hideSecondaryActions || noSecondaryActions) && selectedRows.length === 0);
    const idOfSecondaryActionsMenu = `secondary-action-bar-button_table_id_${id}`;

    return (
      <div className="table-v2-actions-right">
        {selectedRows.length > 0 && (
          <>
            {!hideSelectedCount && (
              <div className="table-v2-selected-count-text">{flatSelectedNodes.length} selected</div>
            )}
            {renderDynamicActions()}
          </>
        )}
        {showReportViews && (data || ssr) && renderReportViewDropdown()}
        {selectedRows.length === 0 && !editMode && (
          <div className="table-v2-important-static-actions">
            {importantStaticActionElements}{" "}
            {editable && !isLoading && (
              <Button onClick={handleEditModeClick} style={{ ...commonBtnStyles, marginLeft: 8 }}>
                {editableIcon || <Pencil weight="bold" style={commonIconStyles} />}
                {editableLabel || "Edit data"}
              </Button>
            )}
          </div>
        )}

        {(editMode || (editable && selectedRows.length > 0)) && renderBulkEditorButton()}
        {editMode && (
          <>
            {importantStaticActionElementsInEditMode}
            {!autoSave && !alwaysEditable && (
              <Button className="button-1 table-v2-secondary-action-bar-btn" onClick={handleEditCancelClick}>
                <X weight="bold" style={{ marginRight: 4 }} />
                Cancel
              </Button>
            )}
            {!autoSave && (
              <Button
                className="button-2 table-v2-secondary-action-bar-btn"
                onClick={handleEditSaveClick}
                style={{ marginRight: 0 }}
                loading={saving}
              >
                <FloppyDisk weight="bold" style={{ marginRight: 4 }} />
                Save
              </Button>
            )}
          </>
        )}
        {shouldShowSecondaryActions && (
          <Popover
            isOpen={showSecondaryActionBar}
            positions={["left", "bottom"]}
            containerStyle={{ zIndex: "5", top: "50px", left: "32px" }}
            content={renderSecondaryActionBar()}
            onClickOutside={() => setShowSecondaryActionBar(false)}
            parentElement={document.getElementById(idOfSecondaryActionsMenu) || undefined}
          >
            <button
              id={idOfSecondaryActionsMenu}
              className="table-v2-secondary-action-bar-button button-1"
              onClick={() => setShowSecondaryActionBar(!showSecondaryActionBar)}
            >
              <DotsThreeVertical className="table-v2-secondary-action-bar-button-icon" weight="bold" />
            </button>
          </Popover>
        )}
      </div>
    );
  };

  // Plug this directly into the filter model for the table
  const buildDateRangeFilter = (column?: ColumnConfig<D>) => {
    let onLocalRangeChange: (range?: DateRange) => void;
    if (column) {
      if (!localGridApiRef.current) return <></>;
      if (!column?.field) return <></>;
      const filterModel = localGridApiRef.current?.getFilterModel();
      if (!filterModel) return <></>;
      const filterComponent = localGridApiRef.current.getFilterInstance(column.field);
      if (!filterComponent) return <></>;
      onLocalRangeChange = (range?: DateRange) => {
        if (!range || !localGridApiRef.current || !column.field) return;
        const { start, end } = range;
        const filterInstance = localGridApiRef.current.getFilterInstance(column.field);
        if (!filterInstance) return;
        if (start && end) {
          filterInstance.setModel({
            filterType: "date",
            type: "inRange",
            dateFrom: start.toFormat(AG_GRID_DATE_FORMAT),
            dateTo: end.toFormat(AG_GRID_DATE_FORMAT),
          });
        } else if (start) {
          filterInstance.setModel({
            filterType: "date",
            type: "greaterThan",
            dateFrom: start.toFormat(AG_GRID_DATE_FORMAT),
          });
        } else if (end) {
          filterInstance.setModel({
            filterType: "date",
            type: "inRange",
            dateFrom: end.toFormat(AG_GRID_DATE_FORMAT),
            dateTo: end.toFormat(AG_GRID_DATE_FORMAT),
          });
        } else {
          filterInstance.setModel({});
        }
        localGridApi?.onFilterChanged();
        setLocalRangeFilter(range);
      };
    } else if (onDateRangeChange) {
      onLocalRangeChange = (range?: DateRange) => {
        onDateRangeChange(range);
        setLocalRangeFilter(range);
      };
    } else {
      onLocalRangeChange = setLocalRangeFilter;
    }

    return (
      <DateRangePicker
        className="table-v2-date-range-filter"
        value={localRangeFilter}
        startPlaceholder="Start date"
        endPlaceholder="End date"
        onChange={onLocalRangeChange}
      />
    );
  };

  // Build timezone dropdown
  const buildTimezoneDropdown = () => {
    return (
      <Formblock
        name={"timezone"}
        type="select"
        options={timezoneOptions}
        onChange={(o) => setTimezone(o?.value)}
        isClearable={true}
        editing={true}
        control={form.control}
        placeholder="Timezone"
        style={{ marginBottom: 0, width: 150, marginLeft: 15 }}
      />
    );
  };

  // Builds the row id
  const buildRowId = (params: GetRowIdParams<D>) => {
    if (params.parentKeys?.length && params.level > 0) {
      return params.parentKeys.join("-") + "-" + params.data?._id;
    } else {
      return params.data._id;
    }
  };

  const renderSearch = () => {
    if (showMiniSearch) {
      // Show a search icon that hides the rest of the action bar left
      return (
        <>
          {!miniSearchOpened && (
            <button
              className="button-1 tall-button no-margin"
              style={{ marginRight: 10 }}
              onClick={() => setMiniSearchOpened(true)}
            >
              <MagnifyingGlass weight="bold" style={{ marginBottom: -2 }} />
            </button>
          )}
          {miniSearchOpened && (
            <input
              type="text"
              className="table-v2-search-input"
              placeholder={"Search " + resource}
              onChange={(e) => {
                handleSearch(e.target.value);
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          )}
          {miniSearchOpened && (
            <button className="button-1 tall-button no-margin" onClick={() => setMiniSearchOpened(false)}>
              <X weight="bold" style={{ marginBottom: -2 }} />
            </button>
          )}
        </>
      );
    } else {
      return (
        <input
          type="text"
          className="table-v2-search-input"
          placeholder={"Search " + resource}
          onChange={(e) => handleSearch(e.target.value)}
        />
      );
    }
  };

  const renderActionBarLeft = () => {
    const colWithRangeFilter = childColumns.find((column) => column.displayRangeFilter);

    return (
      <div className="table-v2-actions-left">
        {!hideSearch && renderSearch()}
        {!miniSearchOpened && renderLeftActionBar?.()}
        {(colWithRangeFilter || displayDateRange) && buildDateRangeFilter(colWithRangeFilter)}
        {showTimezoneDropdown && buildTimezoneDropdown()}
      </div>
    );
  };

  const renderActionBar = () => {
    if (
      hideSearch &&
      hideSecondaryActions &&
      !visibleStaticActions?.filter((action) => action.important).length &&
      !visibleDynamicActions?.filter((action) => action.important).length
    )
      return <></>;

    return (
      <>
        <div className="table-v2-actions">
          {renderActionBarLeft()}
          {renderActionBarRight()}
        </div>
      </>
    );
  };

  const renderToggler = () => {
    if (!toggler) return;

    return (
      <div className={"table-v2-toggler"}>
        <Toggler
          config={toggler.config}
          active={activeToggle || null}
          toggle={handleToggle}
          secondary={toggler.secondary}
        />
      </div>
    );
  };

  const renderBulkEditorButton = () => {
    const isLoadingGrid = !localGridApi || !localColumnApi || !finalColumns;
    if (isLoadingGrid || hideBulkEdit || (!selectedRows.length && !alwaysShowBulkEdit)) {
      return <></>;
    }

    return (
      <TableBulkEditor
        gridApi={localGridApi}
        columnApi={localColumnApi}
        columns={childColumns}
        isBulkEditing={isBulkEditing}
        setIsBulkEditing={(newIsBulkEditing) => {
          setIsBulkEditing(newIsBulkEditing);

          // If we are bulk editing, set edit mode to true
          if (newIsBulkEditing) setEditMode(newIsBulkEditing);
        }}
        selectedRows={selectedRows || []}
        setEditedNodes={setEditedNodes}
        onEditedNodesChanged={onEditedNodesChanged}
        editedNodes={editedNodes}
        ssr={ssr}
        setSelectedNodes={setSelectedNodes}
        editableLabel={editableLabel}
        editableIcon={editableIcon}
      />
    );
  };

  const buildSidebar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressValues: false,
            suppressPivots: ssr ? true : false,
            suppressPivotMode: ssr ? true : false,
            suppressRowGroups: false,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "",
      hiddenByDefault: false,
    }),
    [ssr]
  );

  const renderLoaders = () => {
    return (
      <>
        <div className="table-loaders">{exporting && ssr && <LoadingModal text="Exporting data..." />}</div>
      </>
    );
  };

  /** Empty state component for the table. */
  const TableNoRowsOverlay = ({
    resource,
    customEmptyStateMessage,
  }: {
    resource: string;
    customEmptyStateMessage?: string;
  }) => {
    // AgGrid bug where specifying a noRowsOverlayComponent causes the loading overlay not to be shown, despite api.showLoadingOverlay() being called
    if (isLoadingRef.current) return TableLoadingOverlay();
    return (
      <div className="table-v2-no-rows">
        <div className="no-rows-overlay-content">
          <div className="no-rows-overlay-icon">
            <FolderNotchOpen className="table-v2-no-rows-icon" weight="bold" />
            {customEmptyStateMessage || `No ${resource} found`}
          </div>
        </div>
      </div>
    );
  };

  const defaultColDef = useMemo(
    () => ({ minWidth: 150, sortable: !disableSort, resizable: true }),
    [disableSort]
  );

  const nowRowsOverlayComponentParams = useMemo(
    () => ({ resource, customEmptyStateMessage }),
    [resource, customEmptyStateMessage]
  );

  const finalAutoGroupColDef = useMemo(() => {
    return { ...defaultAutoGroupColumnDef, ...autoGroupColumnDef };
  }, [autoGroupColumnDef]);

  const gridClassName =
    "ag-theme-alpine ag-theme-miter " +
    (data?.length && !noRowsFound ? "ag-theme-mini " : " ") +
    " " +
    (hideHeader ? "hide-header " : " ") +
    "" +
    (treeData ? "tree-data" : "base-data") +
    " " +
    id +
    " " +
    (editMode ? "edit-mode" : " ");

  const renderGrid = () => {
    return (
      <div id="table-v2" className={wrapperClassName} ref={tableRef} style={gridWrapperStyle}>
        <AgGridReact
          className={gridClassName}
          rowModelType={ssr ? "serverSide" : "clientSide"}
          rowData={!ssr ? finalData : undefined}
          domLayout={
            ssr || gridWrapperStyle?.height || containerStyle?.height || gridWrapperStyle?.minHeight
              ? "normal"
              : "autoHeight"
          }
          columnDefs={finalColumns}
          rowDragManaged={true}
          suppressMoveWhenRowDragging={true}
          animateRows={true}
          suppressColumnMoveAnimation={editMode}
          defaultColDef={defaultColDef}
          tooltipShowDelay={300}
          suppressAggFuncInHeader={true}
          containerStyle={{ overflow: "visible" }}
          pagination={!disablePagination}
          paginationPageSize={paginationPageSize || (editMode ? 50 : 100)}
          groupAggFiltering={groupAggFiltering}
          suppressAggFilteredOnly={false}
          paginationAutoPageSize={false}
          suppressPaginationPanel={hideFooter}
          rowSelection={"multiple"}
          groupDisplayType={groupDisplayType ? groupDisplayType : "multipleColumns"}
          groupRowRendererParams={groupRowRendererParams}
          groupAllowUnbalanced={groupAllowUnbalanced}
          groupSelectsChildren={!!onSelect}
          suppressRowClickSelection={true}
          groupHideOpenParents={groupHideOpenParents ?? true}
          groupDefaultExpanded={groupDefaultExpanded ?? (!ssr ? 1 : undefined)}
          showOpenedGroup={true}
          autoGroupColumnDef={finalAutoGroupColDef}
          noRowsOverlayComponent={memo(TableNoRowsOverlay)}
          noRowsOverlayComponentParams={nowRowsOverlayComponentParams}
          loadingOverlayComponent={TableLoadingOverlay}
          pinnedBottomRowData={getTotalsRowData()}
          suppressPropertyNamesCheck={true}
          defaultCsvExportParams={defaultCsvExportParams}
          defaultExcelExportParams={defaultExcelExportParams}
          cacheBlockSize={100}
          infiniteInitialRowCount={1}
          serverSideSortAllLevels={true}
          suppressServerSideInfiniteScroll={false}
          blockLoadDebounceMillis={100}
          enterNavigatesVerticallyAfterEdit={true}
          getRowId={buildRowId}
          loadingCellRenderer={memo(TableLoadingRow)}
          serverSideInitialRowCount={25}
          maxConcurrentDatasourceRequests={1}
          stopEditingWhenCellsLoseFocus={true}
          singleClickEdit={true}
          undoRedoCellEditing={true}
          components={components}
          readOnlyEdit={editable}
          {...(ssr ? { getChildCount } : {})}
          {...gridEventHandlers}
          rowHeight={rowHeight}
          isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
          enableCharts={true}
          getRowClass={getRowClass}
          isRowSelectable={isRowSelectable}
          {...(treeData
            ? {
                treeData: true,
                getDataPath: getDataPath ? (data) => getDataPath(data) : (data) => data.path?.split("."),
              }
            : {})}
          {...gridOptions}
        />
      </div>
    );
  };

  return (
    <div className={"table-v2-container " + containerClassName} style={containerStyle}>
      {renderToggler()}
      {renderActionBar()}
      {renderGrid()}
      {renderLoaders()}
    </div>
  );
};

/** Loading component for the table */
const TableLoadingOverlay = () => {
  return <Loader className="table-v2-loader" />;
};

/** Loading component for server side table rows */
const TableLoadingRow = () => {
  return (
    <div className="table-v2-loading-row">
      <ContentLoader
        speed={1}
        width={"100%"}
        height={32}
        viewBox={`0 0 ${window.innerWidth} 32`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="15" y="8.5" rx="3" ry="3" width="160" height="15" />
        <rect x="200" y="8.5" rx="3" ry="3" width="200" height="15" />
        <rect x="420" y="8.5" rx="3" ry="3" width="100" height="15" />
        <rect x="540" y="8.5" rx="3" ry="3" width="90" height="15" />
        <rect x="650" y="8.5" rx="3" ry="3" width="320" height="15" />
        <rect x="1000" y="8.5" rx="3" ry="3" width="320" height="15" />
        <rect x="1350" y="8.5" rx="3" ry="3" width="400" height="15" />
      </ContentLoader>
    </div>
  );
};

/** Table dropdown cell renderer component */
type TableDropdownCellRendererProps = {
  options: ActionLink[];
};

export const TableDropdownCellRenderer: FC<TableDropdownCellRendererProps> = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered] = useState(false);

  const filteredOptions = useMemo(
    () => options.filter((option) => !("shouldShow" in option) || option.shouldShow?.()),
    [options]
  );

  const actionsList = () => {
    return (
      <div className="table-v2-dropdown-menu" style={{ display: isOpen || isHovered ? "block" : "none" }}>
        {filteredOptions.map((action, index) => (
          <div key={index} className="dropdown-item" onClick={() => action.action()}>
            {action.icon}
            {action.label}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={["left", "bottom"]}
      containerStyle={{ zIndex: "5", top: "50px", left: "32px" }}
      content={actionsList}
      onClickOutside={() => setIsOpen(false)}
      parentElement={document.body}
    >
      <button
        id="table-v2-secondary-action-bar-button"
        className="button-text no-margin"
        onClick={() => setIsOpen(!isOpen)}
        style={{ marginLeft: -4 }}
      >
        <DotsThree className="table-v2-secondary-action-bar-button-icon" weight="bold" />
      </button>
    </Popover>
  );
};

type TableBulkEditorProps<D extends TData> = {
  columns: ColumnConfig<D>[];
  gridApi: GridApi<D>;
  columnApi: ColumnApi;
  isBulkEditing: boolean;
  selectedRows: D[];
  setEditedNodes: Dispatch<SetStateAction<Record<string, TableEditedNode<D>>>>;
  onEditedNodesChanged?: (editedNodeIds: string[]) => void;
  editedNodes: Record<string, TableEditedNode<D>>;
  setIsBulkEditing: (bulkEditing: boolean) => void;
  ssr?: boolean;
  setSelectedNodes: Dispatch<SetStateAction<IRowNode<D>[]>>;
  editableLabel?: string;
  editableIcon?: React.ReactElement;
};

/** Bulk editor component for TableV2 that allows editing multiple rows at once using a popover */
export const TableBulkEditor = <D extends TData>({
  columns,
  gridApi,
  selectedRows,
  isBulkEditing,
  setIsBulkEditing,
  setEditedNodes,
  onEditedNodesChanged,
  editedNodes,
  ssr,
  setSelectedNodes,
  editableLabel,
  editableIcon,
}: TableBulkEditorProps<D>): React.ReactElement | null => {
  /**********************************************************************************************************
   * State / hooks
   **********************************************************************************************************/
  const [data, setData] = useState<Record<string, string | boolean | number | undefined | DateTime | null>>(
    {}
  );
  // Dummy form for making formblock selects work
  const { control } = useForm();

  const editingColumns = useMemo(
    () =>
      columns.filter((column) => {
        if (!column.editable || column.editableHide) return false;
        if (typeof column.editable === "boolean") {
          return column.editable;
        } else if (typeof column.editable === "function" && column.editable != null) {
          // at least some rows must be editable

          // @ts-expect-error TS still doesn't know this is a function, not a boolean
          return selectedRows.some((row) => column.editable({ data: row }));
        }

        return false;
      }),
    [selectedRows, columns]
  );

  const editingColumnsMap = useMemo(() => keyBy(editingColumns, "field"), [editingColumns]);

  /**********************************************************************************************************
   * Helper functions
   **********************************************************************************************************/

  /** Force refresh of the selected rows data (otherwise selectedRows prop data will be stale until row selection changes) */
  const refreshSelectedRows = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedNodes(selectedNodes);
  };

  const onChange = (field: string, value: string | boolean | number | undefined | null) => {
    setData((prev) => ({ ...prev, [field]: value }));
    refreshSelectedRows();
  };

  const clearPending = (field: string) => {
    setData((prev) => {
      const cleared = { ...prev };
      delete cleared[field];
      return cleared;
    });
  };

  const buildValue = (
    column: ColumnConfig<D>,
    value: DateTime | string | boolean | number | null | undefined
  ) => {
    if (column.editorType === "number") {
      return Number(value);
    } else if (column.editorType === "date" && value instanceof DateTime) {
      if (column.editorDateType === "iso") {
        return value?.toISODate();
      } else if (column.editorDateType === "timestamp") {
        return value?.toSeconds();
      } else if (column.editorDateType === "time") {
        return value?.toFormat(DEFAULT_TIME_FORMAT);
      }
    } else {
      return value;
    }
  };

  type NodeRowUpdateInfo = {
    node: IRowNode<D>;
    updatedRow: D;
    fieldUpdates: Record<string, $TSFixMe>;
    fieldErrors: Record<string, $TSFixMe>;
  };

  /**
   * Processes a single field for all rows and gets the updates to make for each row
   * @param field - The field to process
   * @param applyImmediately - Whether to apply the updates immediately
   * @param editingNodes - The nodes to edit
   * @param updatesMap - The map of updates to make
   * @returns The updates to make
   */
  const getFieldUpdates = (
    field: string,
    applyImmediately: boolean,
    editingNodes: IRowNode<D>[],
    updatesMap: Map<string, NodeRowUpdateInfo>
  ): NodeRowUpdateInfo[] => {
    const column = editingColumnsMap[field];
    if (!column) return [];

    const editField = column.editorField || field;

    const valueEditor = column.valueEditor;
    const batchValue = buildValue(column, data[field]);

    const fieldUpdates: NodeRowUpdateInfo[] = [];

    for (const node of editingNodes) {
      if (!node.id || !node.data) continue;
      const id = node.id;

      const finalValue = valueEditor ? valueEditor(batchValue, node.data) : batchValue;
      if (finalValue === IGNORE_EDIT_REQUEST_CHANGE) continue;

      const updateToMake = updatesMap.get(id) || {
        node,
        updatedRow: cloneDeep(node.data),
        fieldUpdates: {},
        fieldErrors: editedNodes[node.id]?.fieldErrors || {},
      };

      makeNestedUpdateUsingDotNotation(editField, finalValue, updateToMake.updatedRow);

      const errors = handleValidations({
        editField: editField,
        validations: column.validations,
        rowData: { ...node, data: updateToMake.updatedRow },
        existingErrors: updateToMake.fieldErrors,
      });

      updateToMake.fieldUpdates[editField] = finalValue;
      updateToMake.fieldErrors = { ...errors };

      updatesMap.set(id, updateToMake);
      fieldUpdates.push(updateToMake);
    }

    if (applyImmediately) {
      applyUpdates(fieldUpdates);
    }

    return fieldUpdates;
  };

  /**
   * Applies the updates to the grid
   * @param updates - The updates to apply
   */
  const applyUpdates = (updates: NodeRowUpdateInfo[]) => {
    if (ssr) {
      // If this is SSR data, update rows one by one with updateData (can't use transactions for SSR tables)
      for (const updateToMake of updates) {
        // Need to get the full node from the grid api to update the data
        updateToMake.node.updateData(updateToMake.updatedRow);
      }
    } else {
      // Update the data in the grid via a transaction if this is client side data
      gridApi.applyTransaction({ update: updates.map((u) => u.updatedRow) });
    }
  };

  /**********************************************************************************************************
   * Event handlers
   **********************************************************************************************************/

  const onApplyToRows = () => {
    const updatesMap = new Map<string, NodeRowUpdateInfo>();
    const editingNodes = gridApi.getSelectedNodes();
    const allUpdatesToMake: NodeRowUpdateInfo[] = [];

    // First, filter for fields with the bulkEditPriority prop
    const fieldsWithOrder = Object.keys(data).filter(
      (field) => editingColumnsMap[field]?.bulkEditPriority !== undefined
    );

    // Sort these fields based on their bulkEditPriority
    const sortedOrderedFields = fieldsWithOrder.sort((a, b) => {
      const columnA = editingColumnsMap[a]!;
      const columnB = editingColumnsMap[b]!;
      return columnA.bulkEditPriority! - columnB.bulkEditPriority!;
    });

    // Get the remaining fields (those without bulkEditPriority)
    const remainingFields = Object.keys(data).filter((field) => !fieldsWithOrder.includes(field));

    // Process sorted ordered fields and apply updates immediately for each field
    for (const field of sortedOrderedFields) {
      const updates = getFieldUpdates(field, true, editingNodes, updatesMap);
      allUpdatesToMake.push(...updates);
    }

    // Process remaining fields
    const remainingUpdates: NodeRowUpdateInfo[] = [];
    for (const field of remainingFields) {
      const updates = getFieldUpdates(field, false, editingNodes, updatesMap);
      remainingUpdates.push(...updates);
    }

    // Apply all remaining updates
    applyUpdates(remainingUpdates);
    allUpdatesToMake.push(...remainingUpdates);

    // Update the edited nodes in state for tracking purposes
    setEditedNodes((prev) => {
      const editedNodesMap = allUpdatesToMake.reduce((acc, update) => {
        const id = update.node.id;
        if (!id) return acc;

        acc[id] = {
          id: id,
          node: update.node,
          edits: { ...prev[id]?.edits, ...update.fieldUpdates },
          fieldErrors: update.fieldErrors,
          saved: false,
        };
        return acc;
      }, {});

      onEditedNodesChanged?.(Object.keys({ ...prev, ...editedNodesMap }));
      return { ...prev, ...editedNodesMap };
    });

    refreshSelectedRows();
    onClose();
  };

  const onClose = () => {
    setData({});
    setIsBulkEditing(false);
  };

  const getCellEditorParamsValue = (column: ColumnConfig<D>, key: string) => {
    let val: $TSFixMe;
    switch (typeof column.cellEditorParams) {
      case "object":
        val = column.cellEditorParams?.[key];
        break;
      case "function":
        val = column.cellEditorParams()?.[key];
        break;
    }
    // For example, the options of a Select field might be given as a callback, so `val` will be a callback that we'll have to execute to actually generate the options
    // Unfortunately, we have no data to pass to the callback since this going to apply to multiple rows, so we'll just pass an empty object. If this breaks, let's figure out a better solution at that point
    if (typeof val === "function") {
      return val({ data: {} });
    }

    return val;
  };

  /**********************************************************************************************************
   * Rendering functions for the inputs
   **********************************************************************************************************/

  const renderEditor = (column: ColumnConfig<D>) => {
    const { field, editorType, parentHeaderName, headerName } = column;
    if (!field) return;

    // Useful for column definitions where there are groups and children and multiple children across different parents have the same headerName
    const finalLabel = parentHeaderName ? `${parentHeaderName}: ${headerName}` : headerName;

    if (editorType === "text") {
      const maxLength = getCellEditorParamsValue(column, "maxLength");

      return (
        <div className="table-v2-bulk-editor-input-container">
          <Formblock
            name={field}
            label={finalLabel}
            className="modal table-v2-bulk-editor-input"
            type="text"
            value={data[field]?.toString()}
            onChange={(e) => onChange(field, e.target.value)}
            maxLength={maxLength}
            editing={true}
          />
        </div>
      );
    } else if (editorType === "paragraph") {
      const minLength = getCellEditorParamsValue(column, "minLength");
      const maxLength = getCellEditorParamsValue(column, "maxLength");

      return (
        <div className="table-v2-bulk-editor-input-container">
          <Formblock
            name={field}
            label={finalLabel}
            className="modal table-v2-bulk-editor-input"
            type="paragraph"
            onChange={(e) => onChange(field, e.target.value)}
            maxLength={maxLength}
            minLength={minLength}
            editing={true}
          />
        </div>
      );
    } else if (editorType === "number") {
      const min = getCellEditorParamsValue(column, "min");
      const max = getCellEditorParamsValue(column, "max");
      return (
        <div className="table-v2-bulk-editor-input-container">
          <Formblock
            name={field}
            label={finalLabel}
            className="modal table-v2-bulk-editor-input"
            type="number"
            onChange={(e) => onChange(field, e.target.value)}
            min={min}
            max={max}
            editing={true}
          />
        </div>
      );
    } else if (editorType === "select") {
      const rawOptions = getCellEditorParamsValue(column, "options");
      if (
        !Array.isArray(rawOptions) ||
        rawOptions.some((o) => !o || typeof o !== "object" || !("label" in o) || !("value" in o))
      ) {
        return null;
      }

      const isClearable = getCellEditorParamsValue(column, "isClearable");
      const options = rawOptions.slice();
      if (isClearable && options.every((o) => o.value !== "")) {
        options.unshift({ label: "(remove selection)", value: "" });
      }

      return (
        <div className="table-v2-bulk-editor-input-container">
          <Formblock
            name={field}
            label={finalLabel}
            className="modal table-v2-bulk-editor-input"
            type="select"
            options={options}
            onChange={(value) =>
              value != null ? onChange(field, value?.value || null) : clearPending(field)
            }
            isClearable={true}
            editing={true}
            control={control}
          />
        </div>
      );
    } else if (editorType === "multiselect") {
      const options = getCellEditorParamsValue(column, "options");

      return (
        <div className="table-v2-bulk-editor-input-container">
          <Formblock
            name={field}
            label={finalLabel}
            className="modal table-v2-bulk-editor-input"
            type="multiselect"
            options={options}
            onChange={(value) => (value ? onChange(field, value) : clearPending(field))}
            isClearable={true}
            editing={true}
            control={control}
            height="unset"
          />
        </div>
      );
    } else if (editorType === "date") {
      const min = getCellEditorParamsValue(column, "min");
      const max = getCellEditorParamsValue(column, "max");
      const editorDateType = column.editorDateType || "iso";

      return (
        <div className="table-v2-bulk-editor-input-container">
          <Formblock
            name={field}
            label={finalLabel}
            className="modal table-v2-bulk-editor-input"
            type="datetime"
            dateOnly={editorDateType === "iso"}
            timeOnly={editorDateType === "time"}
            onChange={(value) => onChange(field, value)}
            min={min}
            max={max}
            editing={true}
            control={control}
          />
        </div>
      );
    } else if (editorType === "checkbox") {
      // Need to use a clearable yes-no select here so users can bulk edit and set to "unchecked". With a checkbox here, that would be less intuitive since you'd have to check and then uncheck the box to apply the change in bulk
      return (
        <div className="table-v2-bulk-editor-input-container">
          <Formblock
            name={field}
            label={finalLabel}
            className="modal table-v2-bulk-editor-input"
            type="select"
            options={yesNoOptions}
            onChange={(value) => (value ? onChange(field, value.value === "true") : clearPending(field))}
            isClearable={true}
            editing={true}
            control={control}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderBulkEditorContent = () => {
    return (
      <div className="table-v2-bulk-editor">
        <div className="table-v2-bulk-editor-title">Bulk Edit</div>
        <div className="table-v2-bulk-editor-subtitle">Edit multiple rows at once</div>
        <div className="table-v2-bulk-editor-fields">
          {editingColumns
            .map((col) => {
              const editorForCol = renderEditor(col);
              if (!editorForCol) return;
              return <div key={col.field}>{editorForCol}</div>;
            })
            .filter(notNullish)}
        </div>
        <div className="table-v2-bulk-editor-actions flex width-100-percent">
          <Button
            className="button-2 no-margin table-button width-100-percent"
            onClick={onApplyToRows}
            wrapperStyle={{ marginLeft: "auto" }}
          >
            Apply to {selectedRows.length} row{selectedRows.length > 1 ? "s" : ""}
          </Button>
          <Button className="button-1 table-button" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Popover
      isOpen={isBulkEditing}
      positions={["left"]}
      containerStyle={{ zIndex: "11", top: "50px", left: "32px" }}
      content={renderBulkEditorContent()}
      parentElement={document.body}
    >
      <button
        id="table-v2-secondary-bulk-edit-btn"
        className="button-1 table-button no-margin"
        onClick={() => {
          setIsBulkEditing(!isBulkEditing);
          setData({});
        }}
        style={{ marginLeft: 15 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginBottom: -2 }}>
            {editableIcon || <Pencil weight="bold" style={{ marginRight: 5 }} />}
          </div>
          {editableLabel || "Bulk edit"}
        </div>
      </button>
    </Popover>
  );
};

const defaultAutoGroupColumnDef: ColDef<any> = {
  cellRenderer: "agGroupCellRenderer",
  menuTabs: ["filterMenuTab"],
  minWidth: 200,
  filter: "agTextColumnFilter",
  filterValueGetter: (params) => {
    if (!params.colDef.field) return;
    return params.data[params.colDef.field];
  },
  sortable: true,
};

const components = {
  reactSelectEditor: AgGridSelectEditor,
  reactMultiSelectEditor: AgGridMultiSelectEditor,
  datePickerEditor: AgGridDateEditor,
  checkboxRenderer: AgGridCheckboxRenderer,
};

export const AG_GRID_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

const commonBtnStyles = { height: 32, marginTop: 0, marginBottom: 0 };
const commonIconStyles = { marginBottom: 0, marginRight: 4 };

/** Shared handlers */
const handleValidations = <D extends TData>(validationParams: {
  editField: string;
  validations: TableValidations<D> | undefined;
  cellEditRequest?: CellEditRequestEvent<D>;
  rowData?: IRowNode<D>;
  existingErrors?: TableNodeErrors;
}) => {
  const { editField, validations, cellEditRequest, rowData, existingErrors } = validationParams;
  // Get the edited node and initialize the errors object
  const errors: TableNodeErrors = existingErrors || {};
  // Clean out the error in the current field so we can re-validate
  delete errors[editField];

  // If there are no validations, return the existing errors
  if (!validations) return errors;

  let validationList: TableValidation<D>[] = [];

  // If validations prop is a function, use the function as the only validation
  if (validations && typeof validations === "function") {
    validationList = [validations];
    // If validations prop is an object, check if it is an array or an object and get the validations/add accordingly
  } else if (validations && typeof validations === "object") {
    if (Array.isArray(validations)) {
      validationList = validations;
    } else {
      validationList = Object.values(validations.validate || {});
    }
  }

  // Run the validations and added error messages / delete keys if there is no error for the field
  validationList.forEach((validation) => {
    const newVal = rowData?.data ? rowData?.data[editField] : cellEditRequest?.newValue;
    const validationRes = validation(newVal, cellEditRequest?.node || rowData);

    if (validationRes === false) {
      errors[editField] = "Error: Invalid value";
    } else if (typeof validationRes === "string") {
      errors[editField] = validationRes;
    } else {
      delete errors[editField];
    }
  });

  return errors;
};

const validateValue = <D extends TData>(value: any, validations: TableValidations<D> | undefined) => {
  let validationList: TableValidation<D>[] = [];

  // If validations prop is a function, use the function as the only validation
  if (validations && typeof validations === "function") {
    validationList = [validations];
    // If validations prop is an object, check if it is an array or an object and get the validations/add accordingly
  } else if (validations && typeof validations === "object") {
    if (Array.isArray(validations)) {
      validationList = validations;
    } else {
      validationList = Object.values(validations.validate || {});
    }
  }

  // Run the validations and added error messages / delete keys if there is no error for the field
  for (const validation of validationList) {
    const validationRes = validation(value);

    if (validationRes === false) {
      return "Error: Invalid value";
    } else if (typeof validationRes === "string") {
      return validationRes;
    }
  }

  return undefined;
};
