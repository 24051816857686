import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, DropdownButton, Toggler } from "ui";
import styles from "../../components/performance/Performance.module.css";
import Banner from "dashboard/components/shared/Banner";
import { generateBannerMessage } from "dashboard/utils/performance";
import { PerformanceReviewsTable } from "dashboard/components/performance/PerformanceReviewsTable";
import { UpdateReviewees } from "dashboard/components/performance/UpdateReviewees";
import { ReviewScheduleDetails } from "dashboard/components/performance/ReviewScheduleDetails";
import { DateTime } from "luxon";
import { CaretDown, PencilSimpleLine } from "phosphor-react";
import FormWizard from "dashboard/components/forms/FormWizard";
import { PerformanceReport } from "dashboard/components/performance/PerformanceReport";
import {
  useLookupPerformanceReviewSchedule,
  useRefetchPerformanceReviewSchedules,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import PerformanceReviewCycleWizard from "dashboard/components/performance/PerformanceReviewCycleWizard";

type PerformanceTab = {
  id: string;
  view?: string;
};

type ReviewCycleMode = "details" | "self-review-form" | "manager-review-form" | null;

export const PerformanceReviewCycle: FC = () => {
  const { id, view } = useParams<PerformanceTab>();
  const navigate = useNavigate();
  const { can } = useMiterAbilities();
  const [editingReviewCycle, setEditingReviewCycle] = useState<ReviewCycleMode>(null);
  const lookupReviewSchedule = useLookupPerformanceReviewSchedule();
  const refetchReviewSchedule = useRefetchPerformanceReviewSchedules();

  const handleToggle = (path) => {
    navigate(`/performance/review-schedules/${id}/${path}`);
  };

  const reviewSchedule = lookupReviewSchedule(id);

  const renderBreadcrumbs = () => {
    if (!reviewSchedule) return;

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Review schedules", path: "/performance/review-schedules" },
          { label: reviewSchedule.name, path: "/performance/review-schedules/" + reviewSchedule._id },
        ]}
      />
    );
  };

  useEffect(() => {
    if (!id) {
      navigate("/performance/review-schedules", { replace: true });
      return;
    }
  }, [id, editingReviewCycle]);

  const isPeriodic = ["anniversary", "start_of_cycle"].includes(reviewSchedule?.request_style || "");

  useEffect(() => {
    if (id && !view) {
      navigate(`/performance/review-schedules/${id}/${isPeriodic ? "current-cycle" : "reviews"}`, {
        replace: true,
      });
    }
  }, [isPeriodic, id, view]);

  const togglerConfig = [
    ...(isPeriodic ? [{ label: "Current cycle reviews", path: "current-cycle" }] : []),
    ...(isPeriodic ? [{ label: "Past cycle reviews", path: "past-cycles" }] : []),
    ...(!isPeriodic ? [{ label: "Reviews", path: "reviews" }] : []),
    { label: "Team members", path: "reviewees" },
    { label: "Details", path: "details" },
    { label: "Reports", path: "reports" },
  ];

  const renderView = () => {
    if (!reviewSchedule) return <></>;
    if (view === "current-cycle") {
      const customEmptyMessage =
        !reviewSchedule.previous_start_date && reviewSchedule.next_start_date
          ? `The first review cycle starts on ${DateTime.fromISO(reviewSchedule.next_start_date).toFormat(
              "DD"
            )}.`
          : undefined;
      return (
        <div style={{ paddingBottom: "50px" }}>
          <PerformanceReviewsTable
            additionalfilters={
              reviewSchedule.previous_start_date
                ? [
                    {
                      field: "review_period",
                      value: reviewSchedule.previous_start_date,
                      type: "string",
                      comparisonType: "contains",
                    },
                  ]
                : []
            }
            reviewCycle={reviewSchedule}
            customEmptyMessage={customEmptyMessage}
          />
        </div>
      );
    }
    if (view === "past-cycles") {
      return (
        <div style={{ paddingBottom: "50px" }}>
          <PerformanceReviewsTable
            additionalfilters={
              reviewSchedule.previous_start_date
                ? [
                    {
                      field: "review_period",
                      value: reviewSchedule.previous_start_date,
                      type: "string",
                      comparisonType: "not.contains",
                    },
                  ]
                : []
            }
            reviewCycle={reviewSchedule}
          />
        </div>
      );
    }

    if (view === "reviews") {
      return (
        <div style={{ paddingBottom: "50px" }}>
          <PerformanceReviewsTable reviewCycle={reviewSchedule} />
        </div>
      );
    }
    if (view === "reviewees") {
      return (
        <div>
          {!isPeriodic && (
            <Banner
              style={{ marginTop: "20px" }}
              content={"Performance reviews will be automatically created when reviewees are enrolled!"}
              type={"info"}
            />
          )}
          <UpdateReviewees performanceReviewCycle={reviewSchedule} />
        </div>
      );
    }
    if (view === "details") {
      return (
        <div style={{ marginTop: 20 }}>
          <ReviewScheduleDetails
            performanceReviewSchedule={reviewSchedule}
            onEdit={() => setEditingReviewCycle("details")}
          />
        </div>
      );
    }
    if (view === "reports") {
      return (
        <div style={{ marginTop: 20 }}>
          <PerformanceReport performanceReviewSchedule={reviewSchedule} />
        </div>
      );
    }
    return <></>;
  };

  const closeWizard = () => {
    setEditingReviewCycle(null);
    refetchReviewSchedule(id);
  };

  const renderWizard = () => {
    if (!reviewSchedule) return <></>;
    switch (editingReviewCycle) {
      case "details":
        return (
          <PerformanceReviewCycleWizard
            performance_review_cycle={reviewSchedule}
            self_review_form={reviewSchedule.self_review_form}
            direct_report_review_form={reviewSchedule.direct_report_review_form}
            onExit={closeWizard}
            onComplete={closeWizard}
            mode={"edit_schedule_details"}
          />
        );
      case "self-review-form":
        return (
          <FormWizard
            onExit={closeWizard}
            onComplete={closeWizard}
            mode="edit-questions"
            form={reviewSchedule.self_review_form}
            name="Self review form"
            parentType="performance_review_cycle"
          />
        );
      case "manager-review-form":
        return (
          <FormWizard
            onExit={closeWizard}
            onComplete={closeWizard}
            mode="edit-questions"
            form={reviewSchedule.direct_report_review_form}
            name="Manager review form"
            parentType="performance_review_cycle"
          />
        );
      default:
        return <></>;
    }
  };

  const renderActions = () => {
    if (!reviewSchedule?.self_review_form || !reviewSchedule.direct_report_review_form) return <></>;

    const actions = [
      {
        label: "Self review",
        action: () => setEditingReviewCycle("self-review-form"),
        icon: <PencilSimpleLine style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => reviewSchedule.self_review.enabled && can("performance:schedules:update"),
      },
      {
        label: "Manager review",
        action: () => setEditingReviewCycle("manager-review-form"),
        icon: <PencilSimpleLine style={{ marginBottom: -2, marginRight: 7 }} />,
        shouldShow: () => reviewSchedule.direct_report_review.enabled && can("performance:schedules:update"),
      },
    ];

    return (
      <div className={styles["actions"]}>
        <DropdownButton className={"button-1"} options={actions} closeOnClick={true}>
          Edit evaluation forms
          <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
        </DropdownButton>
      </div>
    );
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Performance Reviews | Miter</title>
      </Helmet>
      {reviewSchedule && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={"page-content-header " + styles["header"]}>
              {renderBreadcrumbs()}
              <h1>Performance Reviews</h1>
              <div className="flex-1"></div>
            </div>
            {renderActions()}
          </div>
          {isPeriodic ? <Banner content={generateBannerMessage(reviewSchedule)} type={"info"} /> : null}
          <Toggler
            secondary={false}
            active={view || "current-cycle"}
            config={togglerConfig}
            toggle={handleToggle}
          />
          {renderView()}
          {renderWizard()}
        </>
      )}
    </div>
  );
};
