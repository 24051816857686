import {
  useActiveCompany,
  useLookupPolicy,
  useLookupJob,
  useLookupExpenseReimbursementCategories,
  useLookupDepartment,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { AggregatedExpenseReimbursement, Policy, PolicyRule } from "dashboard/miter";
import { useCallback } from "react";
import { ExpenseReimbursementPolicyField, ExpenseReimbursementPolicyFields } from "backend/models/policy";
import { findItemPolicyRule } from "../approvals";
import { ExpenseReimbursementModalFormData } from "dashboard/pages/expenses/modals/ExpenseReimbursementModalForm";
import { DraftReimbursement } from "dashboard/components/expense-reimbursements/BulkCreateExpenseReimbursementPageModal";

type ExpenseReimbursementPolicyItem =
  | AggregatedExpenseReimbursement
  | ExpenseReimbursementModalFormData
  | DraftReimbursement
  | null;

type UseExpenseReimbursementPolicy = {
  meetsPolicyRequirements: () => boolean;
  matchingPolicyRule: PolicyRule | null | undefined;
  isFieldHidden: (field: ExpenseReimbursementPolicyField) => boolean;
  isFieldVisible: (field: ExpenseReimbursementPolicyField) => boolean;
  isFieldLocked: (field: ExpenseReimbursementPolicyField | "amount" | "merchant_name") => boolean;
  isFieldRequired: (field: ExpenseReimbursementPolicyField) => boolean;
  policy: Policy | undefined;
  needsAttentionMessages: string[];
};

type UseExpenseReimbursementPolicies = {
  buildPolicy: (item: ExpenseReimbursementPolicyItem) => UseExpenseReimbursementPolicy;
};

/** Reusable hook to get the policy and/or policy rule for an item */
export const useExpenseReimbursementPolicy = (
  item: ExpenseReimbursementPolicyItem
): UseExpenseReimbursementPolicy => {
  const { buildPolicy } = useExpenseReimbursementPolicies();
  return buildPolicy(item);
};

/** Reusable hook to get the policy and/or policy rule for an item */
export const useExpenseReimbursementPolicies = (): UseExpenseReimbursementPolicies => {
  /*********************************************************
   * Hooks
   **********************************************************/
  const company = useActiveCompany();
  const lookupPolicy = useLookupPolicy();
  const lookupTeamMember = useLookupTeam();
  const lookupJob = useLookupJob();
  const lookupDepartment = useLookupDepartment();
  const lookupCategory = useLookupExpenseReimbursementCategories();

  const buildPolicy = useCallback((item: ExpenseReimbursementPolicyItem): UseExpenseReimbursementPolicy => {
    // computed state
    const categoryId =
      typeof item?.expense_reimbursement_category_id === "string"
        ? item?.expense_reimbursement_category_id
        : item?.expense_reimbursement_category_id?.value;
    const category = lookupCategory(categoryId);

    const isMileage = category?.mileage_rate != null;

    /*********************************************************
     * Policy getters
     **********************************************************/
    const getTeamMemberPolicy = (): Policy | undefined => {
      const teamMemberId =
        typeof item?.team_member_id === "string" ? item?.team_member_id : item?.team_member_id?.value;
      const teamMember = lookupTeamMember(teamMemberId);
      return lookupPolicy(teamMember?.reimbursement_policy_id);
    };

    const getJobPolicy = (): Policy | undefined => {
      const jobId = typeof item?.job_id === "string" ? item?.job_id : item?.job_id?.value;
      const job = lookupJob(jobId);
      return lookupPolicy(job?.reimbursement_policy_id);
    };

    const getDepartmentPolicy = (): Policy | undefined => {
      const departmentId =
        typeof item?.department_id === "string" ? item?.department_id : item?.department_id?.value;
      const department = lookupDepartment(departmentId);
      return lookupPolicy(department?.reimbursement_policy_id);
    };

    const getCompanyPolicy = (): Policy | undefined => {
      return lookupPolicy(company?.settings?.reimbursements?.default_policy_id);
    };

    const policy = getTeamMemberPolicy() || getJobPolicy() || getDepartmentPolicy() || getCompanyPolicy();

    const matchingPolicyRule = findItemPolicyRule(item, policy);

    // Helper function to determine the field's policy status based on the new and old policy settings.
    const getFieldPolicyStatus = (
      field: ExpenseReimbursementPolicyField,
      status: "hidden" | "required"
    ): boolean => {
      if (!policy || !matchingPolicyRule?.fields) return false;
      const fields = matchingPolicyRule.fields as ExpenseReimbursementPolicyFields;

      // Only require attachments if the expense is an out of pocket expense
      if (field === "file_ids" || field === "attachments") {
        return fields[field] === status && !isMileage;
      }
      return fields[field] === status;
    };

    // Use the helper function to check if the field is hidden.
    const isFieldHidden = (field: ExpenseReimbursementPolicyField): boolean => {
      return getFieldPolicyStatus(field, "hidden");
    };

    // Use the helper function to check if the field is required.
    const isFieldRequired = (field: ExpenseReimbursementPolicyField): boolean => {
      return getFieldPolicyStatus(field, "required");
    };

    // Use the isFieldHidden function to check if the field is visible.
    const isFieldVisible = (field: ExpenseReimbursementPolicyField): boolean => {
      return !isFieldHidden(field);
    };

    // locked based on the category
    const isFieldLocked = (field: ExpenseReimbursementPolicyField | "amount" | "merchant_name"): boolean => {
      // map fields to category keys

      switch (field) {
        case "activity_id":
          return category?.activity?.is_locked || false;
        case "job_id":
          return category?.job?.is_locked || false;
        case "expense_account_id":
          return category?.expense_account?.is_locked || false;
        case "cost_type_id":
          return category?.cost_type?.is_locked || false;
        case "amount":
          return category?.amount?.is_locked || false;
        case "merchant_name":
          return category?.merchant_name?.is_locked || false;
        default:
          return false;
      }
    };

    const meetsPolicyRequirements = (): boolean => {
      if (!item) return true;

      // If this item has been kicked back, it doesn't meet the policy requirements
      if ("approval_stage" in item && item.approval_stage?.kick_back) return false;

      const hasReceipt =
        (item.attachments && item.attachments?.length > 0) || ("has_receipt" in item && item.has_receipt);

      const receiptFailed = isFieldRequired("file_ids") && !isMileage && !hasReceipt;

      const submitterMemoFailed = isFieldRequired("memo") && !item?.memo;

      const jobFailed = isFieldRequired("job_id") && !item?.job_id;

      const activityFailed = isFieldRequired("activity_id") && !item?.activity_id;

      const glAccountFailed = isFieldRequired("expense_account_id") && !item?.expense_account_id;

      return !(receiptFailed || submitterMemoFailed || jobFailed || activityFailed || glAccountFailed);
    };

    const getNeedsAttentionMessages = () => {
      const reasons: string[] = [];

      // If this item has been kicked back, it doesn't meet the policy requirements
      if (item && "approval_stage" in item && item.approval_stage?.kick_back) {
        reasons.push(
          "Item kicked back: " +
            (item.approval_stage.kick_back.notes
              ? item.approval_stage.kick_back.notes
              : " please fix or contact the your approver.")
        );
      }

      if (!item) return reasons;

      const hasReceipt =
        (item.attachments && item.attachments?.length > 0) || ("has_receipt" in item && item.has_receipt);

      const receiptFailed = isFieldRequired("file_ids") && !isMileage && !hasReceipt;
      if (receiptFailed) reasons.push("Receipt required");

      const submitterMemoFailed = isFieldRequired("memo") && !item?.memo;
      if (submitterMemoFailed) reasons.push("Memo required");

      const jobFailed = isFieldRequired("job_id") && !item?.job_id;
      if (jobFailed) reasons.push("Job required");

      const activityFailed = isFieldRequired("activity_id") && !item?.activity_id;
      if (activityFailed) reasons.push("Activity required");

      const glAccountFailed = isFieldRequired("expense_account_id") && !item?.expense_account_id;
      if (glAccountFailed) reasons.push("Expense account required");

      return reasons;
    };

    const needsAttentionMessages = getNeedsAttentionMessages();

    return {
      meetsPolicyRequirements,
      matchingPolicyRule,
      isFieldHidden,
      isFieldVisible,
      isFieldLocked,
      isFieldRequired,
      policy,
      needsAttentionMessages,
    };
  }, []);

  return { buildPolicy };
};
