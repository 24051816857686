import React, { useMemo } from "react";
import { Option } from "ui/form/Input";
import { Formblock, Loader } from "ui";
import { DeepPartial } from "utility-types";
import { UseFormMethods, FieldValues } from "react-hook-form";
import {
  AcumaticaConfigObject,
  AcumaticaConnectionMetadata,
} from "backend/services/acumatica/acumatica-types";
import { useFetchAcumaticaBranches } from "dashboard/hooks/integrations/acumatica/useFetchAcumaticaBranches";
import { DEFAULT_ACUMATICA_LABEL_STYLE, ACUMATICA_INPUT_LENGTH } from "./AcumaticaConfig";
import { MiterIntegrationForCompany } from "dashboard/miter";

interface DefaultBranchSelectionFormBlockProps {
  integration: MiterIntegrationForCompany;
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => Promise<void>;
  configOptions?: AcumaticaConfigObject;
  form: UseFormMethods<FieldValues>;
}

export const DefaultBranchSelection: React.FC<DefaultBranchSelectionFormBlockProps> = ({
  integration,
  updateAcumaticaMetadata,
  configOptions,
  form,
}) => {
  const { result: branches, loading, error } = useFetchAcumaticaBranches(integration.connection?._id);

  const branchOptions = useMemo(() => {
    return branches
      ? branches?.map((field) => ({ label: field.BranchName.value, value: field.BranchID.value }))
      : [];
  }, [branches]);

  const updateDefaultBranch = async (chosenBranch: Option<string> | null) => {
    const branch = branches?.find((field) => field.BranchID.value === chosenBranch?.value);

    await updateAcumaticaMetadata(
      { configObject: { journalTransactions: { defaultBranch: branch } } },
      { collapseCount: 2 }
    );
  };

  if (loading) return <Loader />;

  if (error) return null;

  const defaultBranch = configOptions?.journalTransactions?.defaultBranch?.BranchID.value;

  return (
    <Formblock
      form={form}
      options={branchOptions}
      name="defaultBranch"
      type="select"
      defaultValue={defaultBranch}
      onChange={(option: Option<string> | null) => updateDefaultBranch(option)}
      editing={true}
      labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
      label="Default Branch"
      labelInfo="The branch Miter will use by default when communicating with Acumatica."
      underlineTooltip
      inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
    />
  );
};
