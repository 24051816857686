import AppContext from "dashboard/contexts/app-context";
import { useActiveCompany, useActiveCompanyId, useCostTypeOptions } from "dashboard/hooks/atom-hooks";
import { Company, MiterAPI } from "dashboard/miter";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Formblock, Notifier } from "ui";
import { CostTypeLaborCode } from "backend/models/cost-type";
import { CompanySettings } from "backend/models/company";
import { buildAtomicMongoUpdateFromNested, convertUndefinedToMongoUnset } from "dashboard/utils";
import { DeepPartial } from "utility-types";
import { Option } from "ui/form/Input";

type CompanyCostTypeSettings = NonNullable<CompanySettings["cost_types"]>;

export const CostTypeSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const form = useForm();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const costTypeOptions = useCostTypeOptions();

  const settings: CompanyCostTypeSettings = activeCompany?.settings.cost_types || {};
  const [laborCostMapping, setLaborCostMapping] = useState(settings.labor_cost_mapping || {});

  useEffect(() => {
    setLaborCostMapping(settings.labor_cost_mapping || {});
  }, [activeCompanyId]);

  const updateCostTypeSettings = async (update: Partial<CompanyCostTypeSettings>) => {
    if (!activeCompanyId) return;

    const raw: DeepPartial<Company> = { settings: { cost_types: update } };
    const flattened = buildAtomicMongoUpdateFromNested(raw);
    const finalUpdate = convertUndefinedToMongoUnset(flattened);

    try {
      const response = await MiterAPI.companies.update(activeCompanyId, finalUpdate);
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
    fetchUserData();
  };

  const handleLaborMappingChange = (o: Option<string> | null, key: CostTypeLaborCode) => {
    setLaborCostMapping((prev) => ({ ...prev, [key]: o?.value || undefined }));
    updateCostTypeSettings({ labor_cost_mapping: { [key]: o?.value || undefined } });
  };

  return (
    <>
      <div className="vertical-spacer-small" />
      <div className="billing-card-wrapper">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Labor cost mapping</div>
        <div className="vertical-spacer"></div>
        <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
          {laborCodes.map(({ key, label }) => {
            let labelInfo: string | undefined;
            if (key === "indirect") {
              labelInfo = `Indirect labor expenses such as benefits, payroll taxes, and worker's compensation (unless specific components are otherwise overridden)`;
            } else if (key === "non_hourly") {
              labelInfo = `Non-hourly labor expenses such as bonuses, commissions, allowances, and severance`;
            } else if (key === "reimbursement") {
              labelInfo = `Default cost type for all reimbursements`;
            } else if (key === "benefits") {
              labelInfo = `Default cost type for all benefits`;
            } else if (key === "workers_compensation") {
              labelInfo = `Default cost type for all workers compensation`;
            }
            return (
              <Formblock
                form={form}
                options={costTypeOptions}
                labelInfo={labelInfo}
                name={"labor_cost_mapping." + key}
                type="select"
                label={label}
                inputProps={{ style: { width: 250 } }}
                value={costTypeOptions.find((o) => o.value === laborCostMapping[key])}
                onChange={(o) => handleLaborMappingChange(o, key)}
                editing={true}
                isClearable
                key={key}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const laborCodes: { key: CostTypeLaborCode; label: string }[] = [
  { key: "reg", label: "Salary/hourly regular" },
  { key: "ot", label: "Overtime" },
  { key: "dot", label: "Double overtime" },
  { key: "pto", label: "PTO" },
  { key: "non_hourly", label: "Non-hourly earnings" },
  { key: "payroll_tax", label: "Payroll tax" },
  { key: "fringe_expense", label: "Fringe expense" },
  { key: "benefits", label: "Benefits" },
  { key: "workers_compensation", label: "Workers compensation" },
  { key: "indirect", label: "Indirect" },
  { key: "reimbursement", label: "Reimbursement" },
];
