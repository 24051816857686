import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { downloadBlob, formatDate, Notifier, payrollTypeLookup } from "dashboard/utils";
import React, { useEffect, useState } from "react";
import { Badge, Loader, usdString } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { TablePayment } from "backend/utils/aggregations/payrollAggregations";
import PaymentModal from "../payrolls/viewPayroll/PaymentModal/PaymentModal";
import { IDetailCellRendererParams } from "ag-grid-community";
import { useHasAccessToMiterPaystubsAndChecks } from "dashboard/gating";

type Props = {
  tm: AggregatedTeamMember;
};

type PaymentTableEntry = TablePayment & {
  _id: string;
  pay_period: string;
  type_formatted: string;
  payday_formatted: string;
  net_pay_formatted: string;
};

export const TeamMemberPayments: React.FC<Props> = ({ tm }) => {
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [tableEntries, setTableEntries] = useState<PaymentTableEntry[]>();
  const [paymentModal, setPaymentModal] = useState<TablePayment>();
  const [selected, setSelected] = useState<PaymentTableEntry[]>([]);

  const hasAccessToMiterPayStubs = useHasAccessToMiterPaystubsAndChecks();

  const handleDownload = async () => {
    if (!selected.length || !tableEntries) return;
    setDownloading(true);
    try {
      const payrollIds = selected.map((t) => t.payroll_id);

      let response;

      if (hasAccessToMiterPayStubs) {
        response = await MiterAPI.team_member.miter_paystubs.retrieve(tm._id, payrollIds);
      } else {
        response = await MiterAPI.team_member.paystubs.retrieve(tm._id, payrollIds);
      }
      if (response.error) throw new Error(response.error);

      const blob = await response.blob();
      let fileNameDate: string;
      if (payrollIds.length > 1) {
        const firstPayday = selected.map((e) => e.payday).reduce((p, c) => (c < p ? c : p));
        const lastPayday = selected.map((e) => e.payday).reduce((p, c) => (c > p ? c : p));
        fileNameDate = `${firstPayday} - ${lastPayday}`;
      } else {
        fileNameDate = `${selected[0]?.payday}`;
      }
      const fileName = `${fileNameDate} ${tm.full_name} paystub${payrollIds.length > 1 ? "s" : ""}.pdf`;
      downloadBlob(blob, fileName);

      Notifier.success("Paystub downloaded successfully.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`There was an error downloading the paystub: ${e.message}`);
    }
    setDownloading(false);
  };

  const dynamicActions: TableActionLink[] = [
    {
      label: `Download paystub${selected.length > 1 ? "s" : ""}`,
      className: "button-1",
      action: handleDownload,
      loading: downloading,
    },
  ];

  useEffect(() => {
    const getTmPayments = async () => {
      setLoading(true);
      try {
        const response = await MiterAPI.team_member.get_payments(tm._id);
        if (response.error) throw new Error(response.error);

        const cleanedEntries = response.payments.map((p) => {
          return {
            _id: p.payroll_id + "-" + p.tm_id,
            ...p,
            pay_period: formatDate(p.period_start, p.period_end, true),
            type_formatted: payrollTypeLookup[p.type],
            payday_formatted: formatDate(p.payday, undefined, true),
            net_pay_formatted: usdString(p.net_pay),
          };
        });
        setTableEntries(cleanedEntries);
      } catch (e) {
        console.error(e);
        Notifier.error("There was an error retrieving payments. We're looking into it!");
      }
      setLoading(false);
    };
    getTmPayments();
  }, []);

  return (
    <div style={{ marginTop: -40 }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TableV2
            id="team-payments-table"
            resource="team member payments"
            data={tableEntries}
            columns={tmPaymentsColumns}
            onSelect={setSelected}
            onClick={setPaymentModal}
            dynamicActions={dynamicActions}
          />
          {paymentModal && (
            <PaymentModal
              tmId={tm._id}
              editing={false}
              hide={() => setPaymentModal(undefined)}
              payrollId={paymentModal.payroll_id}
            />
          )}
        </>
      )}
    </div>
  );
};

const tmPaymentsColumns: ColumnConfig<PaymentTableEntry>[] = [
  {
    headerName: "Pay period",
    dataType: "string",
    field: "pay_period",
  },
  {
    headerName: "Payday",
    dataType: "string",
    field: "payday_formatted",
  },
  {
    headerName: "Payroll type",
    dataType: "string",
    field: "type_formatted",
  },
  {
    headerName: "Status",
    field: "status",
    cellRenderer: (params: IDetailCellRendererParams<PaymentTableEntry>) => {
      const payment = params.data;
      if (!payment) return null;
      return (
        <div className="flex">
          <Badge table className="no-margin" text={payment.status} />
          {payment.is_void && <Badge text="void" color="light-gray" />}
        </div>
      );
    },
  },
  {
    headerName: "Net pay",
    dataType: "string",
    field: "net_pay_formatted",
  },
];
