import React, { useMemo, useState } from "react";
import { Loader, TableV2 } from "ui";
import { MiterAPI, UpdateTeamMemberResponse } from "dashboard/miter";
import { getFailuresFromPromiseAllSettled, HolidayTabProps } from "./utils";
import BulkTeamMemberSelect, {
  BulkTeamMemberSelectTeamMember,
} from "dashboard/components/team-members/BulkTeamMemberSelect";
import { matchSorter } from "match-sorter";
import { Notifier } from "dashboard/utils";
import { useActiveTeam, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { ColumnConfig } from "ui/table-v2/Table";

type Props = Omit<HolidayTabProps, "updateHolidaySchedule">;

type EnrolledTeamMember = {
  full_name: string;
  _id: string;
};

export const employeeColumns: ColumnConfig<EnrolledTeamMember>[] = [
  {
    field: "full_name",
    headerName: "Enrolled team members",
    dataType: "string",
  },
];

export const HolidayScheduleTeamMembers: React.FC<Props> = ({ originalHolidaySchedule }) => {
  const { can, cannot } = useMiterAbilities();

  const activeTeam = useActiveTeam();
  const refetchTeam = useRefetchTeam();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const enrolledTms = useMemo(() => {
    return activeTeam.filter((tm) => tm.holiday_schedule_id === originalHolidaySchedule._id);
  }, [activeTeam]);

  const onUpdateTeamMember = async (tms: BulkTeamMemberSelectTeamMember[]) => {
    if (cannot("time_off:holidays:manage")) return;

    setLoading(true);
    const promises: Promise<UpdateTeamMemberResponse>[] = [];
    const employeeBulkUpdateIds = tms.map((employee) => employee._id);
    const employeeCurrentEnrolledIds = enrolledTms.map((employee) => employee._id);
    const idsToRemove = employeeCurrentEnrolledIds.filter((id) => !employeeBulkUpdateIds.includes(id));
    const idsToAdd = employeeBulkUpdateIds.filter((id) => !employeeCurrentEnrolledIds.includes(id));

    for (const id of idsToRemove) {
      promises.push(MiterAPI.team_member.update(id, { holiday_schedule_id: null }));
    }
    for (const id of idsToAdd) {
      promises.push(MiterAPI.team_member.update(id, { holiday_schedule_id: originalHolidaySchedule._id }));
    }

    const responses = await Promise.allSettled(promises);
    const failures = getFailuresFromPromiseAllSettled(responses);
    await refetchTeam(idsToRemove.concat(idsToAdd));

    if (failures.length) {
      console.error(
        `Unable to edit enrolled team members on holiday schedule ${originalHolidaySchedule._id}: ${failures}`
      );
      if (failures.length === responses.length) {
        Notifier.error(
          "There was an issue updating enrolled team members. Please try again at another time!"
        );
      } else {
        Notifier.warning("Some team members were not successfully updated. Please check your changes!");
      }
    } else {
      Notifier.success("Successfully updated enrolled team members!");
    }

    setShowModal(false);
    setLoading(false);
    setSearchFilter("");
  };

  const filteredRows = useMemo(() => {
    if (searchFilter) {
      return matchSorter(enrolledTms, searchFilter, {
        keys: ["full_name"],
      });
    }
    return enrolledTms;
  }, [enrolledTms, searchFilter]);

  const defaultButtons = [
    {
      label: "Edit",
      className: "button-2 no-margin",
      action: () => setShowModal(true),
      shouldShow: () => can("time_off:holidays:manage"),
      important: true,
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <TableV2
          columns={employeeColumns}
          data={filteredRows}
          resource="team-members"
          id="holiday-schedule-team-members"
          staticActions={defaultButtons}
          paginationPageSize={5}
          customEmptyStateMessage={"No team members enrolled"}
        />
      )}
      {showModal && (
        <BulkTeamMemberSelect
          title={"Update enrollees"}
          submitting={loading}
          onHide={() => setShowModal(false)}
          defaultTeamMembers={enrolledTms}
          onSubmit={onUpdateTeamMember}
          wrapperStyle={{ width: "80vw" }}
        />
      )}
    </div>
  );
};
