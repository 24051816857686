import { MiterAPI } from "dashboard/miter";
import React, { useEffect, useRef, useState } from "react";
import { Props } from "./types";
import { Notifier } from "dashboard/utils";
import { Loader, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import useWizard from "ui/modal/useWizard";
import { isEmptyAddress } from "miter-utils";

type CheckComponentHandler = {
  handler: {
    close: () => void;
  };
};

export const WitholdInformationWizardScreen: React.FC<Props> = ({ onboardingChecklistItem, name, task }) => {
  const [componentURL, setComponentURL] = useState("");
  const { curIndex, handleComplete, screens, setCanNext } = useWizard();

  const addressCompleted = !isEmptyAddress(onboardingChecklistItem.new_hire.address);
  useEffect(() => {
    setCanNext(!addressCompleted);
  }, [componentURL, addressCompleted]);

  const getWitholdingLink = async () => {
    try {
      const res = await MiterAPI.team_member.retrieve_withholdings_setup_link(
        onboardingChecklistItem.new_hire._id
      );
      setComponentURL(res.url);
    } catch (e: $TSFixMe) {
      console.error("Error getting witholding setup link", e);
      Notifier.error("There was an error opening this page. Please contact support.");
    }
  };

  useEffect(() => {
    getWitholdingLink();
    return () => {
      checkComponentHandlerRef?.current?.handler.close();
    };
  }, []);

  const checkComponentHandlerRef = useRef<CheckComponentHandler>();

  const handleCheckModalEvent = (event: string, _data: $TSFixMe) => {
    if (event === "check-component-employee-withholdings-setup-complete") {
      setCanNext(true);
    }
  };

  const updateCheckComponentHandler = (handler?: CheckComponentHandler) => {
    checkComponentHandlerRef.current = handler;
  };

  useEffect(() => {
    if (componentURL) {
      loadCheckComponent(componentURL, "check-modal", updateCheckComponentHandler, handleCheckModalEvent);
    }
  }, [componentURL]);

  const onNext = async () => {
    if (!onboardingChecklistItem.new_hire?.check_id) {
      Notifier.error(
        "Team member has not been properly set up for withholdings. Please reach out to support."
      );
      return;
    }
    await MiterAPI.team_member
      .sync_with_check(onboardingChecklistItem.new_hire?.check_id)
      .catch((e) =>
        console.error(
          `Issue syncing TM ${onboardingChecklistItem.new_hire?.check_id} with Check upon Check Onboard modal close`,
          e
        )
      );
    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      {addressCompleted ? (
        !componentURL ? (
          <Loader />
        ) : (
          <div>
            <div
              id="check-modal"
              className={"modal-body check-modal-body"}
              style={{ height: 700, width: "75%", alignSelf: "center", margin: "auto" }}
            />
          </div>
        )
      ) : (
        <div className={styles["content"]}>
          <h3>{task.title}</h3>
          <div>{`${onboardingChecklistItem.new_hire.full_name}'s address needs to be specified before witholdings are completed.`}</div>
        </div>
      )}
    </WizardScreen>
  );
};

const loadCheckComponent = (
  componentLink: string,
  componentDivId: string,
  setCheckComponentHandler: (params: CheckComponentHandler) => void,
  handlerFunc: (event: string, data: $TSFixMe) => void
): void => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - CheckComponent is not defined
    const handler = window.CheckComponent.create({
      link: componentLink,
      onEvent: (event, data) => {
        handlerFunc?.(event, data);
      },
    });
    handler.open();
    const componentElement = document.getElementById("check-component-embedded-div");
    if (componentElement) {
      const componentHolder = document.getElementById(componentDivId);
      componentHolder?.appendChild(componentElement);
    }

    setCheckComponentHandler({ handler });
  } catch (err) {
    console.error("Error loading Check component", err);
  }
};
