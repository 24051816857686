import { ICellEditorParams, SuppressKeyboardEventParams } from "ag-grid-community";
import React, { useImperativeHandle, useMemo, useState } from "react";
import Select, { ValueType } from "react-select";
import { Option, selectFilterOption } from "ui/form/Input";
import { styles } from "ui/form/styles";

type SelectEditorProps = ICellEditorParams<$TSFixMe, unknown> & {
  options: Option<string>[] | ((params: ICellEditorParams<$TSFixMe, unknown>) => Option<string>[]);
  isClearable?: boolean;
};

export const AgGridSelectEditor = React.forwardRef(function RawSelectEditor(props: SelectEditorProps, ref) {
  const [value, setValue] = useState(props.value);

  const options = useMemo(() => {
    if (typeof props.options === "function") {
      return props.options(props);
    } else {
      return props.options;
    }
  }, [props.options]);

  const defaultOpt = useMemo(() => {
    let valueToUse = value;

    // If the value is an object (ex. field refers to an aggregated document), we want to use the `_id` or `id` property as the value
    if (value && typeof value === "object") {
      if ("_id" in value) {
        valueToUse = value._id;
      } else if ("id" in value) {
        valueToUse = value.id;
      }
    }

    // If we use a `valueGetter` that directly return the label, then we need to find the option by label (only after failing to find the option by the value)
    return options.find((o) => o.value === valueToUse) || options.find((o) => o.label === valueToUse);
  }, [value, options]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  const onChange = (o: ValueType<Option<string>, false>) => {
    // when clearing a selector, the value goes `undefined`, but we need it to be `null` if we expect to send it back to the server
    // But allow for o.value to literally be `undefined` itself
    setValue(o == null ? null : o.value);
    setTimeout(() => props.stopEditing(), 0);
  };

  return (
    <Select
      options={options}
      maxMenuHeight={175}
      width="200px"
      styles={styles}
      onChange={onChange}
      autoFocus={true}
      openMenuOnFocus={true}
      defaultValue={defaultOpt}
      isClearable={props.isClearable}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          // If "Enter" is hit, we want react-select to actually select the chosen option (default behavior), and once that happens we want AgGrid to stop editing the cell as well. If we call stopEditing() directly, then react-select won't actually select the chosen option, so let's wait for the next tick to call stopEditing().
          setTimeout(() => props.stopEditing(), 0);
        }
      }}
      filterOption={selectFilterOption}
    />
  );
});

/** In order for react-select to actually select the chosen option when Enter is hit, we need to suppress AgGrid keyboard navigation */
export const selectEditorSuppressKeyboardEvent = (params: SuppressKeyboardEventParams): boolean => {
  // If the user is editing the cell, and they hit "Enter", then we want to suppress AgGrid's default behavior of moving to the next cell.
  return params.editing && params.event.key === "Enter";
};
