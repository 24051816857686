import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import React, { useEffect, useState } from "react";
import { TeamPortalUser } from "team-portal/utils/miter";
import { ActionModal, Formblock, Notifier } from "ui";

import styles from "./TeamMemberProfilePicture.module.css";
import { formatFilesForUpload, getInitials } from "miter-utils";
import { useForm, useWatch } from "react-hook-form";
import { FilePickerFile } from "ui/form/FilePicker";
import { Pencil } from "phosphor-react";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";

type ProfilePictureForm = {
  profile_picture?: FilePickerFile[] | null;
};

type Props = {
  teamMember: AggregatedTeamMember | TeamPortalUser | WizardTeamMember;
  refetchTeam?: () => Promise<void>;
  readonly?: boolean;
  size?: number;
  // Direct URL to the profile picture (used for the org chart)
  directPictureURL?: string;
  rounded?: boolean;
};

export const TeamMemberProfilePicture: React.FC<Props> = ({
  teamMember,
  refetchTeam,
  size,
  readonly,
  directPictureURL,
  rounded,
}) => {
  const form = useForm<ProfilePictureForm>({ shouldUnregister: false, mode: "all" });
  const formData = useWatch({ control: form.control });

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>();

  const companyId = typeof teamMember.company === "string" ? teamMember.company : teamMember.company?._id;

  useEffect(() => {
    getProfilePicture();
  }, [teamMember]);

  /** Get the team member's profile picture file URL */
  const getProfilePicture = async () => {
    try {
      if (!teamMember.profile_picture_file_id) return setProfilePictureUrl(undefined);

      const file = await MiterAPI.files.retrieve(teamMember.profile_picture_file_id);
      setProfilePictureUrl(file.url);
    } catch (e) {
      console.log(e);
    }
  };

  const saveProfilePicture = async (): Promise<void> => {
    setSaving(true);
    try {
      const filePickerFiles = formData.profile_picture;

      if (teamMember.profile_picture_file_id && !filePickerFiles?.length) {
        await deleteProfilePicture();
      } else {
        await uploadProfilePicture();
      }
    } catch (e) {
      console.log(e);
      Notifier.error("Failed to save profile picture");
    }
    setSaving(false);
  };

  /** Uploads the profile picture as a file and updates the team member's profile picture file id */
  const uploadProfilePicture = async (): Promise<void> => {
    if (!companyId) throw new Error("No company id found");
    if (!teamMember?._id) throw new Error("No team member id found");

    const filePickerFiles = formData.profile_picture;
    const formattedFiles = formatFilesForUpload(filePickerFiles, companyId);
    const responseData = await MiterAPI.files.upload({ files: formattedFiles });
    if ("error" in responseData) throw new Error(responseData.error);

    const fileId = responseData[0]!.file._id.toString();
    await MiterAPI.team_member.update(teamMember._id, { profile_picture_file_id: fileId });
    await refetchTeam?.();

    Notifier.success("Profile picture saved");
    setEditing(false);
  };

  /** Delete the team member's profile picture file id */
  const deleteProfilePicture = async (): Promise<void> => {
    if (!companyId) throw new Error("No company id found");
    if (!teamMember?._id) throw new Error("No team member id found");

    if (!teamMember.profile_picture_file_id) throw new Error("No profile picture to delete");

    await Promise.all([
      MiterAPI.files.delete([teamMember.profile_picture_file_id]),
      MiterAPI.team_member.update(teamMember._id, { profile_picture_file_id: null }),
    ]);

    await refetchTeam?.();
    setEditing(false);
    Notifier.success("Profile picture deleted");
  };

  const renderOverlay = () => {
    return (
      <div
        className={styles["profile-picture-overlay"]}
        onClick={() => setEditing(true)}
        style={size ? { height: size, width: size } : {}}
      >
        <div className={styles["profile-picture-overlay-text"]}>Edit</div>
      </div>
    );
  };

  const renderPicture = () => {
    if (directPictureURL || profilePictureUrl) {
      return (
        <div className={styles["profile-picture-image-container"] + " " + (rounded ? styles["rounded"] : "")}>
          <img
            src={directPictureURL || profilePictureUrl}
            alt={teamMember.full_name}
            className={styles["profile-picture-image"]}
            style={size ? { height: size, width: size } : {}}
          />
          {!readonly && renderOverlay()}
        </div>
      );
    } else {
      const initials = getInitials(teamMember.full_name);

      return (
        <div
          className={
            styles["profile-picture-placeholder-container"] + " " + (rounded ? styles["rounded"] : "")
          }
          onClick={() => setEditing(true)}
        >
          <div
            className={styles["profile-picture-placeholder"]}
            style={size ? { height: size, width: size } : {}}
          >
            <div
              className={styles["profile-picture-placeholder-text"]}
              style={size ? { fontSize: size / 2 } : {}}
            >
              {initials}
            </div>
          </div>
          {!readonly && renderOverlay()}
        </div>
      );
    }
  };

  const renderEditModal = () => {
    return (
      <ActionModal
        headerText={"Edit profile picture"}
        onHide={() => setEditing(false)}
        loading={saving}
        onSubmit={saveProfilePicture}
        onDelete={deleteProfilePicture}
        showSubmit={true}
      >
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Formblock
            name={"profile_picture"}
            className="modal wizard"
            type="file"
            form={form}
            multiple={true}
            maxFilesAllowed={1}
            variant="dropzone"
            editing={true}
            labelStyle={{ marginBottom: 10, marginTop: 10 }}
            dropzoneLabel="Drag and drop or click to upload a picture"
            acceptedFileTypes={["image/jpeg", "image/png"]}
          />
        </div>
      </ActionModal>
    );
  };

  return (
    <div className={styles["profile-picture-wrapper"]} style={size ? { height: size, width: size } : {}}>
      {renderPicture()}
      {editing && renderEditModal()}
      {!readonly && <Pencil className={styles["edit-icon"]} onClick={() => setEditing(true)} />}
    </div>
  );
};
