import { FC, useEffect, useState } from "react";
import { BankAccountsTable } from "miter-components/bank-accounts/BankAccountsTable";
import { useActiveCompanyId, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { InlineNetPaySplits } from "miter-components/bank-accounts/InlineNetPaySplits";
import { AggregatedTeamMember } from "dashboard/miter";
import { PaymentInfoWarningBanners } from "miter-components/bank-accounts/PaymentInfoWarningBanners";
import { InlinePaymentMethodAndWithholdings } from "miter-components/onboarding/InlinePaymentMethodAndWithholdings";

type Props = {
  teamMember: AggregatedTeamMember;
};

export const TeamMemberPaymentInfo: FC<Props> = ({ teamMember }) => {
  const activeCompanyId = useActiveCompanyId();
  const refetchTeam = useRefetchTeam();
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    refetchTeam(teamMember._id);
  }, [refreshCount]);

  if (!activeCompanyId) {
    return null;
  }

  return (
    <>
      <PaymentInfoWarningBanners teamMember={teamMember} />
      <BankAccountsTable
        teamMember={teamMember}
        companyId={activeCompanyId}
        onUpdate={() => {
          setRefreshCount(refreshCount + 1);
        }}
      />
      {teamMember.check_tm && (
        <InlinePaymentMethodAndWithholdings
          teamMember={teamMember}
          onUpdate={() => {
            setRefreshCount(refreshCount + 1);
          }}
        />
      )}
      {/* only show for team members enrolled in payroll and selected direct deposit */}
      {teamMember.check_tm?.payment_method_preference === "direct_deposit" && (
        <div className="margin-top-50">
          <InlineNetPaySplits teamMember={teamMember} refreshCount={refreshCount} />
        </div>
      )}
    </>
  );
};
